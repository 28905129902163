import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ModalService {
  private modals: any[] = [];
  private modalReturnData: object = {};

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }

  returnDataOnClose(id: string, data: object) {
    var modalData = this.modalReturnData[id];
    if (modalData == null) {
      modalData = {};
    }
    modalData = data;
    this.modalReturnData[id] = modalData;
  }

  close(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.close();

    if (this.modalReturnData[id] != null) {
      return this.modalReturnData[id];
    }
    return true;
  }
}
