<div>
  <h3 mat-dialog-title>{{ data.title }}</h3>
  <mat-dialog-content>
    <div
      fxLayout="column wrap"
      fxLayoutAlign="start start"
      fxLayoutGap="16px grid"
    >
      <ng-container *ngFor="let input of data.inputs; let i = index">
        <mat-checkbox
          fxFlex="25"
          [id]="i"
          [checked]="input.isVisible"
          (change)="onCheckChange($event)"
        >
          {{ input.label }}
        </mat-checkbox>
      </ng-container>
      <!-- <div fxFlex="25" *ngFor="let i of Arr(num).fill(1)">Anotyher one</div> -->
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button color="primary" mat-raised-button [mat-dialog-close]="data">
      Done
    </button>
  </div>
</div>
