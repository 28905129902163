// specifically for ticket details

import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";

@Component({
  selector: "app-ticket-input-view",
  templateUrl: "./ticket-input-view.component.html",
  styles: [
    `
          .create-ticket-input-field {
            padding: 5px;
          }
        `,
  ],
})
export class TicketInputViewComponent
  extends BaseViewComponent
  implements OnInit
{
  className: string = "TicketInputViewComponent";

  @Input() form: FormGroup;
  @Input() dataArr: Object[];

  inputs: Object[];
  dropdowns: Object[];
  datepickers: Object[];

  ngOnInit() {
    try {
      // divide dataArr
      this.inputs = this.dataArr.filter((input) => {
        return input["type"] ? input["type"].indexOf("input") > -1 : false;
      });

      this.dropdowns = this.dataArr.filter((input) => {
        return input["type"] ? input["type"].indexOf("dropdown") > -1 : false;
      });

      this.datepickers = this.dataArr.filter((input) => {
        return input["type"] ? input["type"].indexOf("date") > -1 : false;
      });
    } catch (ex) {
      this.logger$.error(this.className, "ngOnInit", "could not init");
    }
  }

  setDataArr(data: Object[]) {
    this.dataArr = data;
    this.ngOnInit();
  }
}
