<div class="full-container">
  <div
    id="worksite-searchbar"
    class="cursor-text fixed z-10 box-border shadow flex bg-white flex-row justify-between items-center h-10 md:w-96 p-2 m-2 lg:w-[280px] rounded border-solid border-1 border-[rgba(0,0,0,0.12)]"
    (click)="addressText.focus()">
    <div class="h-full w-full flex flex-row justify-start items-center pl-1.5">
      <mat-icon class="search-icon" svgIcon="search" />
      <input
        #addressText
        [(ngModel)]="autocompleteInput"
        class="search-text box-border h-full w-full bg-transparent outline-none border-none"
        placeholder="Search..."
        autocomplete="off" />
    </div>
    <div class="h-full flex flex-row justify-end items-center w-fit">
      <button
        mat-icon-button
        aria-hidden="false"
        aria-label="Clear address search"
        class="bg-transparent"
        *ngIf="addressText.value.length > 0"
        (click)="addressText.value = ''">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="drawingToolbar">
    <app-pill-menu>
      @for (button of buttons; track $index) {
        <app-pill-menu-button
          [active]="toolbarSelectionModel.isSelected($index + 1)"
          (clicked)="button.action()"
          [iconName]="button.icon"
          matTooltip="{{ button.tooltip }}" />
      }
      <!-- spacer -->
      <div class="w-0.5 h-8 bg-[#EEEEEF] mx-2"></div>
      <app-pill-menu-button color="red" (clicked)="onResetDrawing()" iconName="trash" matTooltip="Reset Drawing" />
      <app-pill-menu-button
        (clicked)="onSaveDrawing()"
        iconName="yes-filled"
        matTooltip="Save Drawing"
        matBadge="!"
        matBadgeColor="warn"
        [matBadgeHidden]="getBadgeVisibility()" />
    </app-pill-menu>
  </div>

  <app-google-maps-drawing
    #cmpDrawShapesGoogleMaps
    (setBadgeVisibility)="setBadgeVisibility()"
    (setTextAddress)="setTextAddress($event)"
    (locationPinned)="locationPinned($event)"
    [googleMapsOptions]="options"
    [curTool]="(toolbarSelectionModel.changed | async)?.added[0] ?? -1"
    [props]="childProps"
    [markers]="markers"></app-google-maps-drawing>
</div>
