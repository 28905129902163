import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Logger } from "./logger";
import { MainError } from "./main-error";
const undefinedFunction = (): any => undefined;
@Injectable({
  providedIn: "root",
})
export class LoggerService implements Logger {
  mainError(mainErr: MainError, displayToUser: boolean = false) {
    try {
      if (mainErr != null) {
        if (mainErr.severity <= 1) {
          this.warn(mainErr);
        } else {
          this.error(mainErr);
        }

        if (displayToUser) {
          //show user the error
        }
      } else {
        throw new Error("LoggerService; sent null error");
      }
    } catch (ex) {
      this.error(ex);
    }
  }

  get log() {
    if (environment.enableDebug && !environment.production) {
      return console.info.bind(console);
    } else {
      return undefinedFunction;
    }
  }

  get info() {
    if (environment.enableDebug) {
      return console.info.bind(console);
    } else {
      return undefinedFunction;
    }
  }

  get warn() {
    return console.warn.bind(console);
  }

  get error() {
    return console.error.bind(console);
  }
}
