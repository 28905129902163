import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { AdminLookupService } from "../core/admin/admin-lookup.service";
import { ADMIN_TABLE_NAMES } from "../core/admin/tables";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { CacheWhereClauseType } from "../core/cache/cache.interface";
import { UtilocateAdminCacheService } from "../core/cache/utilocate-admin.service";
import { LoggerService } from "../core/services/logger/logger.service";
import { SettingID } from "../core/services/user/setting";

@Injectable({
  providedIn: "root",
})
export class UserManagerService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService,
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private adminLookup: AdminLookupService
  ) { }

  /**
   * return promise of getting all users
   * @returns {promise<any>}
   */
  getUsers() {
    const url = apiKeys.u2.userManagerGetUsers;
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  /**
   * Get a list of supervisors
   * @returns supervisors[]
   */
  async getSupervisors() {
    const supervisors = [];
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbLogin_Users,
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
      ]);

      // Get table rows
      const userCatResult: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbLogin_UserCategories
        );

      const userResult: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbLogin_Users,
          [
            {
              Column: "Archived",
              Value: 0,
              ValueType: CacheWhereClauseType.NUMBER,
            }
          ]
        );

      if (!(userCatResult instanceof Error) && !(userResult instanceof Error)) {
        const userCategories = {};

        for (const i in userCatResult) {
          const userCat = userCatResult[i];
          userCategories[userCat.UserCategoyID] = userCat.Description;
        }

        for (const i in userResult) {
          const user = userResult[i];
          if ((user.UserCategoryID != 1 && user.UserCategoryID != 5) || user.UserID == 0) {
            supervisors.push({
              UserID: user.UserID,
              Name:
                user.LastName +
                ", " +
                user.FirstName +
                " - " +
                userCategories[user.UserCategoryID],
            });
          }
        }
      } else if (userCatResult instanceof Error) {
        throw userCatResult;
      } else {
        throw userResult;
      }
    } catch (error) {
      console.log(error);
    }
    supervisors.sort((a, b) => {
      return a.Name < b.Name ? -1 : 1;
    });
    return supervisors;
  }

  /**
   * Get a list of all user categories with their description
   * @returns userCategories []
   */
  async getUsercategories() {
    const userCategories = [];
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
      ]);

      // Get table rows
      const userCatResult: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbLogin_UserCategories
        );

      if (!(userCatResult instanceof Error)) {
        for (const i in userCatResult) {
          const userCat = userCatResult[i];
          userCategories.push({
            UserCategoryID: userCat.UserCategoyID,
            Description: userCat.Title,
          });
        }
      } else {
        throw userCatResult;
      }
    } catch (error) {
      console.log(error);
    }
    return userCategories;
  }

  /**
   * Gets all users override settings
   * @returns userSettings{} dict of userID and their override settings
   */
  async getUserSettings() {
    const userSettings = {};
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
      ]);

      // Get table rows
      const userSetttingResults: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbUser_SettingOverrides
        );

      if (!(userSetttingResults instanceof Error)) {
        for (const i in userSetttingResults) {
          const userSetting = userSetttingResults[i];

          if (!userSettings[userSetting.UserID]) {
            userSettings[userSetting.UserID] = [];
          }
          userSettings[userSetting.UserID].push({
            SettingID: userSetting.SettingID,
            Active: userSetting.ActiveOverride,
            Value: userSetting.SettingID === SettingID.TICKET_ROUTING_CLUSTER_SIZE ? userSetting.ValueOverride * 2 : userSetting.ValueOverride ,
            SettingCategoryID: userSetting.SettingCategoryID,
          });
        }
      } else {
        throw userSetttingResults;
      }
    } catch (error) {
      console.log(error);
    }
    return userSettings;
  }

  async getAlgorithms() {
    let algorithms = [];
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbRouting_Algorithms,
      ]);

      // Get table rows
      const algorithmResults: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbRouting_Algorithms
        );

      if (!(algorithmResults instanceof Error)) {
        algorithms = algorithmResults;
      } else {
        throw algorithmResults;
      }
    } catch (error) {
      console.log(error);
    }
    return algorithms;
  }

  async getSettingValue(settingIDs) {
    const settingValues = {};
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbAdmin_Settings,
      ]);

      const setttingResults: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_Settings,
          [
            {
              Column: "SettingID",
              Value: settingIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            },
          ]
        );

      if (!(setttingResults instanceof Error)) {
        for (const i in setttingResults) {
          const settingRow = setttingResults[i];
          settingValues[settingRow.SettingID] = settingRow.Value;
        }
      } else {
        console.warn(`Unable to get setting value: `);
        console.warn(setttingResults);
      }
    } catch (error) {
      console.log(error);
    }
    return settingValues;
  }

  async getEditableSettings(userCategoryID) {
    const editableSettings = [];
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbUser_CategoryToSetting,
      ]);

      const setttingResults: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbUser_CategoryToSetting,
          [
            {
              Column: "UserCategoryID",
              Value: userCategoryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ]
        );

      for (const i in setttingResults) {
        editableSettings.push(setttingResults[i].SettingID);
      }
    } catch (error) {
      console.log(error);
    }
    return editableSettings;
  }

  async getAccessibleSettings(userCategoryID) {
    const editableSettings = [];
    try {
      await this.adminLookup.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbUser_CategoryAccessToSetting,
      ]);

      const setttingResults: any[] | Error =
        await this.utilocateAdminCacheService.queryTable(
          ADMIN_TABLE_NAMES.tbUser_CategoryAccessToSetting,
          [
            {
              Column: "UserCategoryID",
              Value: userCategoryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ]
        );

      for (const i in setttingResults) {
        editableSettings.push(setttingResults[i].SettingID);
      }
    } catch (error) {
      console.log(error);
    }
    return editableSettings;
  }

  updateUser(user) {
    const url = apiKeys.u2.userManagerUpdateUser;
    const type = api[url].type;
    const value = {
      User: user,
    };
    
    let userID = user.UserID;
    if (!userID && user.CloudUserID) {
      userID = user.CloudUserID
    }

    

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
      API_URL_DATA_PARAMS: {
        UserID: userID,
      },
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  createUser(user) {
    const url = apiKeys.u2.userManagerCreateUser;
    const type = api[url].type;
    const value = {
      User: user,
    };

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
      API_URL_DATA_PARAMS: {
        UserID: 0,
      },
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  archiveUsers(userIDs: number[], cloudUserIDs: number[] = []) {
    const url = apiKeys.u2.userManagerArchiveUsers;
    const type = api[url].type;
    const value = {
      UserIDs: userIDs,
    };
    if (cloudUserIDs && cloudUserIDs.length > 0) {
      value["CloudUserIDs"] = cloudUserIDs;
    }

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  unarchiveUsers(userIDs: number[], cloudUserIDs: number[] = []) {
    const url = apiKeys.u2.userManagerUnarchiveUsers;
    const type = api[url].type;
    const value = {
      UserIDs: userIDs,
    };
    if (cloudUserIDs && cloudUserIDs.length > 0) {
      value["CloudUserIDs"] = cloudUserIDs;
    }

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  associateUser(user) {
    const url = apiKeys.u2.userManagerAssociateUser;
    const type = api[url].type;
    const value = {
      User: user,
    };

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
      API_URL_DATA_PARAMS: {
        UserID: 0,
      },
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  validatePassword(password: string) {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let valid: boolean = false;
    if (passwordRegex.test(password)) {
      valid = true;
    }
    return valid;
  }
}
