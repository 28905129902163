import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import JSZip from "jszip";
import { DownloadDocumentService } from 'src/app/modules/core/services/document/download-document.service';
import { DocumentService } from 'src/app/modules/core/services/document/document.service';
import { UtilocateDocumentsCacheService } from '../services/cache/utilocate-documents.service';

@Injectable({
  providedIn: 'root'
})
export class TicketDocumentsService {

  private isHeldDownSubject = new BehaviorSubject<boolean>(false);
  isHeldDown$: any = this.isHeldDownSubject.asObservable();

  private docsSubject = new BehaviorSubject<any>([]);

  private reloadDocsSubject = new BehaviorSubject<boolean>(false);
  reloadTab$: any = this.reloadDocsSubject.asObservable();

  private disableSyncSubject = new BehaviorSubject<boolean>(true);
  disableSync$: any = this.disableSyncSubject.asObservable();

  combinedArrays: any = []

  constructor(
    private downloadDocService: DownloadDocumentService,
    private documentService: DocumentService,
    private documentCacheService: UtilocateDocumentsCacheService,
  ) { }

  /**
   * Sets docs list into select mode
   */
  setIsHeldDown(value: boolean) {
    this.isHeldDownSubject.next(value);
  }

  get docRows$(): Observable<any> {
    return this.docsSubject.asObservable();
  }

  setreloadTab(value: boolean) {
    this.reloadDocsSubject.next(value);
  }

  disableSync(value) {
    this.disableSyncSubject.next(value);
  }



  clearDocs() {
    this.docsSubject.next([]);
  }

  /**
   * Sets the document list observable
   */
  addToDocs(value) {
    this.docsSubject.next(value);
  }

  /**
  Combines two arrays by filename which is being used to combine S3 Docs files with there file names
   */

  combineArrays(array1: any[], array2: any[]): any[] {
    const mergedArray: any[] = [...array1];

    array2.forEach((obj) => {
      const existingObjIndex = mergedArray.findIndex((item) => item.FileName === obj.FileName);
      if (existingObjIndex !== -1) {
        // If the filename exists in array1, add the propertys from array2
        mergedArray[existingObjIndex] = { ...mergedArray[existingObjIndex], ...obj };
      } else {
        // If the filename does not exist in array1, add the object from array2 to mergedArray
        mergedArray.push(obj);
      }
    });
    return mergedArray;
  }

  /**
  Combines two arrays by documnet Id which is being used to combine S3 docs combined array with tbcompletions_documents and tbcompletions_S3documents
   */

  combineDocArrays(array1: any[], array2: any[]): any[] {
    const mergedArray: any[] = [...array1];

    array2.forEach((obj) => {
      const existingObjIndex = mergedArray.findIndex((item) => item.DocumentID === obj.DocumentID);
      const existingObjS3Index = mergedArray.findIndex((item) => item.DocumentID === obj.S3DocumentID);

      if (existingObjIndex !== -1 && existingObjIndex !== mergedArray.length) {
        mergedArray[existingObjIndex] = { ...mergedArray[existingObjIndex], ...obj, FileName: mergedArray[existingObjIndex].FileName };

      } else if (existingObjS3Index !== -1 && existingObjS3Index !== mergedArray.length) {
        // If the filename does not exist in array1, add the object from array2 to mergedArray
        mergedArray[existingObjS3Index] = { ...mergedArray[existingObjS3Index], ...obj, FileName: mergedArray[existingObjS3Index].FileName };;
      }

    });
    this.combinedArrays = mergedArray
    return mergedArray;
  }

  /**
  Adds a selected flag to the docs list so it is highlighted
   */
  addSelected(DocumentID: any, isS3: any, selected: any): any {
    const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID === DocumentID && item.isS3Document === isS3);
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].selected = selected;
    }
    this.addToDocs(this.combinedArrays);
  }


  addFile(DocumentID: any, isS3: any, url: any): any {
    const link = url.split(",");
    const modifiedUrl = "data:image/jpeg;base64," + link[1];

    const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID === DocumentID && item.isS3Document === isS3);
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].file = modifiedUrl;
    }

    this.addToDocs(this.combinedArrays);
  }


  /**
    Marks the doc item as sendable in the document list
  */
  markSendabale(DocumentID: any, isS3: any) {

    const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID === DocumentID && item.isS3Document === isS3);
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].isSendable = !this.combinedArrays[existingObjIndex].isSendable;
      this.addToDocs(this.combinedArrays);
    }
  }

  /**
    Deletes doc from doc list
   */
  deleteDoc(DocumentID: any, isS3: any): any {
    const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID === DocumentID && item.isS3Document === isS3);
    this.combinedArrays.splice(existingObjIndex, 1);
    this.addToDocs(this.combinedArrays)
  }


  /**
   * Updates the caption of a document
   * - Updates the local row value in memory 
   * - Updates the value in the DB 
   *
   * @param {number} DocumentID
   * @param {boolean} isS3Document
   * @param {string} caption
   * @return {Promise<any>} 
   * @memberof TicketDocumentsService
   */
  async updateCaption(AssignmentID: any, DocumentID: number, isS3Document: boolean, caption: string) {
    //update the document internally 

    //for some reason, saving with newline characters is not allowed. So, remove all special characters to be safe. 
    caption = caption ? caption.trim() : ''; //set to empty string if caption is null
    caption = caption.replace(/\s/g, " ");
    caption = caption.replace(/(\r\n|\n|\r)/gm, " ");


    const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID === DocumentID && item.isS3Document === isS3Document);
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].Caption = caption;
      this.addToDocs(this.combinedArrays);
    }

    //if our document is on the server, call the API to update the caption
    if (this.combinedArrays[existingObjIndex].bFieldAdded != 1) {
      return this.downloadDocService.updateDocumentRow(DocumentID, isS3Document, [{ 'key': 'Caption', 'value': caption.toString() }]);
    
    //else our document is local, so we need to update the metadata in the local db 
    } else {
      return this.documentCacheService.updateDocumentMetadata(AssignmentID, DocumentID, isS3Document, [{ 'key': 'Caption', 'value': caption.toString() }]);
    }

  }

  /**
  clears all selected flags 
   */

  clearSelected() {
    for (let i of this.combinedArrays) {
      i.selected = false
    }
    this.addToDocs(this.combinedArrays)
    this.setIsHeldDown(false)

  }

  /**
  Downloads documents from S3 and creates the combined document list
   */

  async createDocList(DocumentsList, AssignmentID) {

    let docZip = await this.downloadDocService.getDocumentAll(AssignmentID);
    if (docZip) {

      let docZipBuffer = await fetch(docZip["buffer"]).then((r) => r.blob());
      let docList = docZip["documentList"]
      let zip = await JSZip.loadAsync(docZipBuffer);
      let files = [];

      zip.forEach((relativePath, zipEntry) => {

        files.push({
          FileName: zipEntry["name"],
          file: "data:image/jpeg;base64," + this.documentService.arrayBufferToBase64(
            zipEntry["_data"]["compressedContent"]
          ),
          selected: false
        });
      });

      let unzippedFiles = files

      const mergedS3Docs = this.combineArrays(docList, unzippedFiles)
      let mergedDocsArrays = this.combineDocArrays(DocumentsList, mergedS3Docs)
      // mergedDocsArrays = mergedDocsArrays.filter(item => item.file);
      this.combinedArrays = mergedDocsArrays
      this.addToDocs(mergedDocsArrays)
      return mergedDocsArrays
    }
  }
  updateDocList(newDoc) {
    this.combinedArrays.push(newDoc)
    this.addToDocs(this.combinedArrays)

  }

}
