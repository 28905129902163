import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, CommonModule, NgClass } from '@angular/common';

@Component({
  selector: 'app-button-dropdown-select',
  standalone: true,
  imports: [MatIcon, AsyncPipe, NgClass, CommonModule],
  templateUrl: './button-dropdown-select-select.component.html',
})
export class ButtonDropdownSelectComponent<T extends { name: string; value: unknown }> implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;
  // IO
  @Input() title: string = '';
  @Input() classOverrides: string = '';
  @Input() isActive: boolean = false;
  @Input() items: Array<T> = [];
  @Input() selection: T = null;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() selectionChange = new EventEmitter<T>();

  // services
  private renderer: Renderer2 = inject(Renderer2);

  // members
  private listenerFn: () => void;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.items = changes.items.currentValue;
      //if the currently selected item does not exist in the item list anymore, set the currently selected
      // item to the first value in items 
      if (!this.items.find((item) => item.value === this.selection?.value)) {
        this.selection = this.items[0];
        this.selectionChange.emit(this.selection);
      }
    }
  }

  ngAfterViewInit() {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (e.composedPath().indexOf(this.container.nativeElement) === -1) {
        this.isActive = false;
        this.openChange.emit(this.isActive);
      }
    });
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  toggleDropdown() {
    this.isActive = !this.isActive;
    this.openChange.emit(this.isActive);
  }

  changeSelection(item: T) {
    this.selection = item;
    this.selectionChange.emit(item);
    this.isActive = false;
  }

  trackByFn(index: number, item: T) {
    return item.value;
  }
}
