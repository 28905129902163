import { Injectable } from "@angular/core";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { ApiService, UtilocateApiRequest } from "../../api/baseapi.service";
import { LoggerService } from "../logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class GeneratePDFService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {
    this.logger$.log("Created GeneratePDF Service");
  }

  generatePDF(
    primaryID: string | number,
    DocumentTypeID: number,
    docDesc: string,
    isSendable: number,
    actionID: number,
    documentID?: number
  ): Promise<any> {
    try {
      const url = apiKeys.u2.preparePDFGeneration;
      const type = api[url].type;

      let body = {
        PrimaryID: primaryID,
        DocumentTypeID: DocumentTypeID,
        DocumentDescription: docDesc,
        isSendable: isSendable,
        ActionID: actionID,
      };

      if (documentID) {
        body["DocumentID"] = documentID;
      }

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: body,
      };
      console.log("generatePDF Request: ", utilocateApiRequest);

      return this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
