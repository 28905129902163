import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ParameterType, Report} from '../report-generator.types';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-report-generator-query',
  templateUrl: './report-generator-query.component.html',
  styleUrl: './report-generator-query.component.scss'
})
export class ReportGeneratorQueryComponent implements OnChanges {
  @Input() report: Report;
  @Input() reportIsRunning: boolean = false;
  @Input() loading: boolean = false;
  @Input() dataExists: boolean = false;

  @Output() runReport = new EventEmitter<boolean>();
  @Output() downloadClicked = new EventEmitter<string>();

  dateRangeErrorMessage: string = 'Required';
  currentYear: number = new Date().getFullYear();

  constructor(private sanitizer: DomSanitizer) {
    this.currentYear = new Date().getFullYear();
    console.log(this.currentYear);
  }

  get ParameterType(): typeof ParameterType {
    return ParameterType;
  }

  ngOnChanges(): void {
    this.setDefaultFormValues();
  }

  setDefaultFormValues() {
    if (this.report.FormGroup.get('1')) { //start date
      //set the date to 2 weeks ago 
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      this.report.FormGroup.get('1').setValue(twoWeeksAgo);
    }
    if (this.report.FormGroup.get('2')) { //end date
      this.report.FormGroup.get('2').setValue(new Date());
    }
    if (this.report.FormGroup.get('6')) { //year 
      this.report.FormGroup.get('6').setValue(this.currentYear);
    }
  }

  /**
   * Submit the form (run the query)
   */
  onSubmit() {
    this.validateDateRange();

    //validate the form group 
    for (const field in this.report.FormGroup.controls) {
      this.report.FormGroup.controls[field].markAsTouched();
    }

    //emit the run report event
    if (this.report.FormGroup.valid) {
      this.reportIsRunning = true;
      this.runReport.emit(true);
    } else {
      this.runReport.emit(false);
    }
  }

  /**
   * Validate the date range
   */
  validateDateRange() {
    const startDateControl = this.report.FormGroup.get('1');
    const endDateControl = this.report.FormGroup.get('2');

    //if start date is after end date, set error message
    if (startDateControl?.value > endDateControl?.value) {
      this.dateRangeErrorMessage = 'End date must be after start date';
      this.report.FormGroup.controls['2'].setErrors({'error': true});
    } else {
      //reset the error string 
      this.dateRangeErrorMessage = 'Required';
    }
  }

  renderDescription(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

}
