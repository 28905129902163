export interface TableMetadata {
  EditTableID?: number;
  PermissionTypeID?: number;
  PrimaryFieldName?: string;
  VisibleName?: string;
  TableDescription?: string;
  TableName?: string;
  ArchiveFieldName?: string;
}

export interface TableHeaderProps {
  VisibleName?: string;
  TableDescription?: string;
  TableName?: string;
}

export interface TablePanelProps {
  EditTableID?: number;
  PermissionTypeID?: number;
  PrimaryFieldName?: string;
  ArchiveFieldName?: string;
}

export interface TableProps {
  EditTableID?: number;
  PermissionTypeID?: number;
  PrimaryFieldName?: string;
  ArchiveFieldName?: string;
  rows?: object[];
  columns?: any;
  onActionComplete?: any;
}

export interface FieldType {
  Boolean: 1;
  String: 2;
  Select: 3;
  MultiSelect: 4;
  Date: 5;
  Number: 6;
  Query: 7;
  ColourPicker: 8;
}

export interface modifyEditTablesBody {
  PrimaryKeyIDs: any[];
  Rows: object[];
}

export enum ModifyType {
  Delete = 1,
  Update = 2,
  Add = 3,
  View = 4
}

export interface AccessOptions {
  allowEditing: boolean;
  allowDeleting: boolean;
  allowAdding: boolean;
}

export const PermissionTypeIDs: Object = {
  1: {
    // PermissionTypeID=1
    allowEditing: true,
    allowDeleting: true,
    allowAdding: true,
  },
  2: {
    // PermissionTypeID=2
    allowEditing: true,
    allowDeleting: false,
    allowAdding: true,
  },
  3: {
    // PermissionTypeID=3
    allowEditing: false,
    allowDeleting: false,
    allowAdding: true,
  },
  4: {
    // PermissionTypeID=4
    allowEditing: false,
    allowDeleting: false,
    allowAdding: false,
  },
};

export interface FavouriteTable {
  id: number;
  isFavourite: boolean;
}

export interface EditTablesRow {
  EditTableID: number;
  TableName: string;
  VisibleName: string;
  TableDescription: string;
  PrimaryFieldName: string;
  ArchiveFieldName: string;
  EditType: number;
  WhereClause: string;
  isGlobal: 0 | 1;
  isArchived: 0 | 1;
  Data: [];
}
