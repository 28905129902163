<app-form-input-template
  [props]="formProps"
  (dropdownSelected)="onDropdownSelected($event)"
  (dateSelected)="onDateSelected($event)"
></app-form-input-template>
<div *ngIf="hasCommon" style="width: 100%">
  <app-form-input-template
    [props]="commonFormProps"
    fxFlex="100"
  ></app-form-input-template>
</div>
<ng-template #utilityDetails>
  <!-- <app-form-input-template [props]="tempformProps"></app-form-input-template> -->
</ng-template>
<div
  style="height: 48px; max-height: 48px; min-height: 48px; background: white"
></div>
<!-- <div style="padding-top: 1vh; background: white;"></div> -->
<!-- <ng-container *ngFor="let " -->
