import { Collection, Feature } from "ol";
import VectorSource from "ol/source/Vector";
import {
  CollectionFlattener,
  FeatureOrCollection,
} from "./collection-flattener";

export class MapFeatureChange<F extends FeatureOrCollection> {
  changeType: FeatureChangeType;
  feature: FeatureOrCollection | undefined;
  previousFeature: FeatureOrCollection | undefined;
  source: VectorSource;
  length: number;

  constructor(
    featureChangeType: FeatureChangeType,
    feature: F = undefined,
    previousFeature: F = undefined,
    source: VectorSource
  ) {
    this.changeType = featureChangeType;
    this.feature = feature;
    this.source = source;
    this.previousFeature = previousFeature;
    this.length =
      this.feature instanceof Collection ? this.feature.getLength() : 1;
    if (previousFeature) {
      if (
        this.previousFeature instanceof Collection &&
        this.feature instanceof Collection
      ) {
        if (this.previousFeature.getLength() !== this.feature.getLength()) {
          throw new Error(
            "Previous feature and feature must have the same length"
          );
        }
      }
      // objects are not the same type
      else if (
        (this.previousFeature instanceof Collection &&
          this.feature instanceof Feature) ||
        (this.previousFeature instanceof Feature &&
          this.feature instanceof Collection)
      ) {
        throw new Error(
          "Previous feature and feature must have the same constructor"
        );
      }
    }
  }

  flatten(): Feature[] {
    const flattener = new CollectionFlattener();
    return flattener.flattenFeature(this.feature);
  }
}

export enum FeatureChangeType {
  added = 0,
  updated,
  deleted,
}
