import { Component, Input, OnInit } from "@angular/core";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { LocaleService } from "src/app/modules/core/services/locale/locale.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ISO_LOCALE_LIST } from "src/assets/locale-files/languages";

@Component({
  selector: "app-french-english-option-view",
  templateUrl: "./french-english-option-view.component.html",
  styleUrls: ["./french-english-option-view.component.scss"],
})
export class FrenchEnglishOptionViewComponent {
  className: string = "FrenchEnglishOptionViewComponent";
  @Input() props: any;

  constructor(
    // error$: MainErrorService,
    loggerService: LoggerService,
    // alert$: NotificationService,
    private localeService: LocaleService
  ) {}

  /**
   * update locale service to french and send message
   */
  onClickFrench() {
    //set language service
    this.localeService.setLocale(ISO_LOCALE_LIST.fr);
    //send message back
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { language: true },
      senderID: "FrenchEnglishOptionViewComponent",
    };
    this.props.messageService.sendToMessageStream(message);
  }

  /**
   * update locale service to english and send message
   */
  onClickEnglish() {
    //set language service
    this.localeService.setLocale(ISO_LOCALE_LIST["en-CA"]);
    //send message back
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { language: true },
      senderID: "FrenchEnglishOptionViewComponent",
    };
    this.props.messageService.sendToMessageStream(message);
  }
}
