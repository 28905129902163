import { Validators } from "@angular/forms";
import { InputFactory, InputTypes } from "./input-factory";
import { CreateTicketFormTypes } from "./CreateTicketModel";

export class CreateTicket {
  EXCAVATOR_DETAILS = "ExcavatorDetails";
  TICKET_DETAILS = "TicketDetails";
  UTILITY_DETAILS = "UtilityDetails";
  DIGSITE_DETAILS = "DigsiteDetails";
  PRIMARY_DETAILS = "PrimaryDetails";
  PRIMARY_DETAILS_PREFILL = "PrimaryDetailsPrefill";

  // required API body keys
  ExcavatorDetailsInputs = new InputFactory().createInputs(
    [
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
    ],
    [
      "Code",
      "ExcavatorName",
      "ExcavatorAddress",
      "ExcavatorCity",
      "ExcavatorPostalCode",
      "PhoneNumber",
      "Email",
      "ContactName",
    ],
    [
      "OOC Excavator Code",
      "Company Name",
      "Company Address",
      "Company City",
      "Company Postal Code",
      "Company Phone Number",
      "Company Email",
      "Contact Name",
    ],
    [true, true, true, true, true, true, true, true]
  );
  TicketDetailsInputs = new InputFactory().createInputs(
    [
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.date,
      InputTypes.date,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
      InputTypes.string,
    ],
    [
      "LocateAddress",
      "LocateSubRegionName",
      "LocateCrossStreet",
      "ProjectNum",
      "ExcavationDate",
      "CallDate",
      "TypeOfWork",
      "WorkDoneFor",
      "CallerName",
      "PhoneNumber",
      "email",
    ],
    [
      "Excavation Address",
      "Excavation City",
      "Cross Street",
      "Project Number",
      "Excavation Date",
      "Call Date",
      "Type of Work",
      "Work Done For",
      "Caller Name",
      "Phone Number",
      "Email",
    ],
    [true, true, false, false, true, false, false, true, true, true, true]
  );

  TicketDetailsDropdowns = new InputFactory().createDropdowns(
    ["DigDetails"],
    ["Dig Details"],
    [
      [
        {
          text: "Cancelled Call",
          value: "bCancelledCall",
        },
        {
          text: "Marked",
          value: "bMarked",
        },
        {
          text: "Lighting",
          value: "bLighting",
        },
        {
          text: "No Hydro",
          value: "bNoHydro",
        },
        {
          text: "Public Property",
          value: "bPublicProperty",
        },
        {
          text: "Private Property",
          value: "bPrivateProperty",
        },
        {
          text: "Area Not Marked",
          value: "bAreaNotMarked",
        },
        {
          text: "Mark and Fax",
          value: "bMarkandFax",
        },
        {
          text: "Directional Drilling",
          value: "bDirectionalDrilling",
        },
        {
          text: "Site Meet Required",
          value: "bSiteMeetRequired",
        },
        {
          text: "Premarked",
          value: "bPremarked",
        },
        {
          text: "MachineDig",
          value: "bMachineDig",
        },
        {
          text: "Hand Dig",
          value: "bHandDig",
        },
        {
          text: "Private Residential",
          value: "bPrivateResidential",
        },
        {
          text: "Private Commercial",
          value: "bPrivateCommercial",
        },
        {
          text: "Vacant Lot",
          value: "bVacantLot",
        },
        {
          text: "Premarked Paint",
          value: "bPremarkedPaint",
        },
        {
          text: "Premarked Stake",
          value: "bPremarkedStake",
        },
        {
          text: "Restricted Access",
          value: "bRestrictedAccess",
        },
        {
          text: "Blasting",
          value: "bBlasting",
        },
        {
          text: "Boring",
          value: "bBoring",
        },
        {
          text: "Railroad",
          value: "bRailroad",
        },
        {
          text: "Revision Denied",
          value: "bRevisionDenied",
        },
      ],
    ]
  );

  generateFormConfig(formType: number) {
    let formConfig = {};

    let arrToReduce = null;
    if (formType == CreateTicketFormTypes.excavator) {
      arrToReduce = this.ExcavatorDetailsInputs;
    } else if (formType == CreateTicketFormTypes.ticket) {
      arrToReduce = [
        ...this.TicketDetailsInputs,
        ...this.TicketDetailsDropdowns,
        "PrimaryDetails",
      ];
    }

    if (arrToReduce) {
      formConfig = arrToReduce.reduce((obj, value) => {
        let inputName = value;

        if (
          typeof value !== "string" &&
          !(value instanceof String) &&
          value.dbName
        ) {
          inputName = value.dbName;
        }

        // create list of required validators
        let validators = [Validators.required];
        if (inputName.indexOf("PhoneNumber") > -1) {
          validators.push(Validators.minLength(10));
        } else if (inputName.indexOf("Email") > -1) {
          validators.push(Validators.email);
        } else if (!value.isRequired) {
          validators = [];
        }

        obj[inputName] = ["", validators];
        return obj;
      }, {});
    } else {
      // cannot resolve formGroupType
    }
    return formConfig;
  }

  generateInputs(formType: number) {
    let formInputs = [];

    if (formType == CreateTicketFormTypes.excavator) {
      formInputs = this.ExcavatorDetailsInputs;
    } else if (formType == CreateTicketFormTypes.ticket) {
      formInputs = this.TicketDetailsInputs;
    }
    return formInputs;
  }

  // todo: finish
  generateDropdowns(formType: number) {
    let formDropdowns = [];

    if (formType == CreateTicketFormTypes.excavator) {
      // arrToReduce = this.ExcavatorDetailsInputs;
    } else if (formType == CreateTicketFormTypes.ticket) {
      formDropdowns = [...this.TicketDetailsDropdowns];
    }
    return formDropdowns;
  }
}
