import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { UtilocateTokenPaths } from "../services/token/token.service";
import { UtilocateTokenService } from "../services/token/token.service";
import { UtilocateAdminCacheService } from "../cache/utilocate-admin.service";
import { AssignmentIDCacheResult, BaseCompletionCacheService, Table } from "../cache/cache.interface";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { api, apiKeys, apiStage, cacheableApi } from "../../../ENDPOINTS";
import { environment } from "../../../../environments/environment";
import { localStorageKeys } from "../../../LOCAL_STORAGE";


export class UtilocateApiRequest {
  API_TYPE: string;
  API_KEY: string;
  API_URL?: string = null;
  API_TOKEN?: string;
  API_BODY?: object = {};
  API_BODY_STRING?: string = null;
  API_URL_DATA_PARAMS?: object = {};
  API_URL_TOKEN_PARAMS?: object = {};
  API_HEADERS?: object = {};
}

export interface BaseApi {
  invokeApi(type, url, body?, headers?): any;
}

export abstract class BaseApiService implements BaseApi {
  readonly ERROR_API_FAILED_URL_BUILD =
    "Failed to update string with data params.";
  private httpClient: HttpClient;

  completionCacheAPIs = [apiKeys.u2.downloadLocatorTickets, apiKeys.u2.downloadTicketU2];

  constructor(
    protected tokenService: UtilocateTokenService,
    protected utilocateAdminCacheService: UtilocateAdminCacheService,
    protected handler: HttpBackend,
    protected baseCompletionCacheService: BaseCompletionCacheService,
    protected route: Router,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  invokeApi(type, url, body?, headers?): Promise<any> {
    try {
      // create Request with url, body, and generic headers
      const httpHeaders = new HttpHeaders({
        ...headers,
      });
      switch (type) {
        case "PUT":
          return this.put(url, body, httpHeaders);

        case "GET":
          return this.get(url, httpHeaders);

        case "POST":
          return this.post(url, body, httpHeaders);
      }
    } catch (error) {
      return error;
    }
  }

  private put(url, body, httpHeaders): Promise<any> {
    return this.httpClient
      .request(new HttpRequest("PUT", url, body, { headers: httpHeaders }))
      .toPromise();
  }

  private get(url, httpHeaders): Promise<any> {
    return this.httpClient
      .request(new HttpRequest("GET", url, { headers: httpHeaders }))
      .toPromise();
  }

  private post(url, body, httpHeaders): Promise<any> {
    return this.httpClient
      .request(new HttpRequest("POST", url, body, { headers: httpHeaders }))
      .toPromise();
  }
}

export class BaseUtilocateApiService extends BaseApiService {
  invokeUtilocateApi(request: UtilocateApiRequest, AssignmentID?: string, assignedTicket: boolean = false): Promise<any> {
    let newRequest = request;
    newRequest = this.createUtilocateApiURL(newRequest);
    newRequest = this.createUtilocateApiBody(newRequest);
    // console.log("newRequest: ", newRequest);

    if (
      newRequest.API_KEY == apiKeys.competers.generateAuthTokenU2 ||
      newRequest.API_KEY == apiKeys.competers.generateAuthTokenU4
    ) {
      return this.invokeApi(
        newRequest.API_TYPE,
        newRequest.API_URL,
        newRequest.API_BODY_STRING,
        newRequest.API_HEADERS,
      );
    }

    newRequest = this.addUtilocateAuthorizationToken(newRequest);
    return this.getCacheOrCacheApiResult(newRequest, AssignmentID, assignedTicket);
  }

  private createUtilocateApiURL(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;
    if (!newRequest.API_URL) {
      // API_URL hasnt been provided
      newRequest = this.getApiUrlFromApiKey(newRequest);
    }

    if (!newRequest.API_URL_TOKEN_PARAMS) {
      // API_URL_TOKEN_PARAMS hasnt been been provided
      newRequest = this.getTokenParamsFromApiKey(newRequest);
    }

    newRequest = this.updateRequestUrlWithParams(newRequest);
    newRequest = this.updateUrlWithStage(newRequest);
    return newRequest;
  }

  private createUtilocateApiBody(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;

    if (request.API_BODY && Object.keys(request.API_BODY).length > 0) {
      const value = JSON.stringify(request.API_BODY);
      const newBody = JSON.stringify({ value: value });
      newRequest = { ...request, API_BODY_STRING: newBody };
    }
    return newRequest;
  }

  private addUtilocateAuthorizationToken(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;
    let token = null;

    if (this.route.url.indexOf("document-viewer") >= 0) {
      token = this.tokenService.getToken(localStorageKeys.DOC_VIEWER_TOKEN_KEY);
    } else {
      token = this.tokenService.getToken();
    }

    if (token) {
      newRequest = { ...newRequest, API_HEADERS: { Authorization: token } };
    }
    return newRequest;
  }

  private getApiUrlFromApiKey(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;
    let VERSION = "";

    if (this.route.url.indexOf("document-viewer") >= 0) {
      VERSION = this.tokenService.getValueFromToken(
        UtilocateTokenPaths.VERSION,
        localStorageKeys.DOC_VIEWER_TOKEN_KEY,
      );
    } else {
      VERSION = this.tokenService.getValueFromToken(
        UtilocateTokenPaths.VERSION,
      );
    }

    let url = "";
    if (VERSION) {
      url = api[request.API_KEY]["versions"][VERSION].url;
    } else {
      url = api[request.API_KEY]["versions"]["0"].url;
    }
    newRequest = { ...newRequest, API_URL: url };
    return newRequest;
  }

  private getTokenParamsFromApiKey(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;
    let VERSION = "";

    if (request.API_KEY == apiKeys.u2.documentToAngularController) {
      VERSION = this.tokenService.getValueFromToken(
        UtilocateTokenPaths.VERSION,
        localStorageKeys.DOC_VIEWER_TOKEN_KEY,
      );
    } else {
      VERSION = this.tokenService.getValueFromToken(
        UtilocateTokenPaths.VERSION,
      );
    }

    let params = "";
    let endpoint = "";
    const tokenParams = {};

    if (VERSION) {
      params = api[newRequest.API_KEY]["versions"][VERSION].params;
      endpoint = api[newRequest.API_KEY]["versions"][VERSION].url;
    } else {
      params = api[newRequest.API_KEY]["versions"]["0"].params;
      endpoint = api[newRequest.API_KEY]["versions"]["0"].url;
    }

    for (let param of params) {
      param = param.replace("{", "").replace("}", "");

      tokenParams[param] = this.tokenService.getValueFromToken(
        param.toUpperCase(),
      );

      if (param.toUpperCase() == "USERID") {
        tokenParams[param] = this.setEndpointUserIDParam(endpoint);
      }
    }
    newRequest = { ...newRequest, API_URL_TOKEN_PARAMS: tokenParams };
    return newRequest;
  }

  setEndpointUserIDParam(endpoint) {
    let userid = this.tokenService.getValueFromToken(
      UtilocateTokenPaths.USERID,
    );

    try {
      //u3 needs to use u3userid else if localuserid exists then use userid.
      if (endpoint.indexOf("/u3/") > -1) {
        if (
          this.tokenService.getValueFromToken(UtilocateTokenPaths.LOCALUSERID)
        ) {
          userid = this.tokenService.getValueFromToken(
            UtilocateTokenPaths.USERID,
          );
        } else {
          userid = this.tokenService.getValueFromToken(
            UtilocateTokenPaths.U3USERID,
          );
        }
      } else {
        //u2 endpoints which need to use u2 user. if local then local else use userid.
        if (
          this.tokenService.getValueFromToken(UtilocateTokenPaths.LOCALUSERID)
        ) {
          userid = this.tokenService.getValueFromToken(
            UtilocateTokenPaths.LOCALUSERID,
          );
        } else {
          userid = this.tokenService.getValueFromToken(
            UtilocateTokenPaths.USERID,
          );
        }
      }
    } catch (error) {
      console.error(error);
    }

    return userid;
  }

  private updateRequestUrlWithParams(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;

    try {
      let newUrl = request.API_URL;
      const params = {
        ...request.API_URL_DATA_PARAMS,
        ...request.API_URL_TOKEN_PARAMS,
      };
      const paramKeys = Object.keys(params);
      for (let i = 0; i < paramKeys.length; i++) {
        const key = paramKeys[i];
        const value = params[key];

        // {key} is specific to UTILOCATE APIs
        const urlKeyToReplace = "{" + key + "}";
        newUrl = newUrl.replace(urlKeyToReplace, value);
      }

      newRequest = { ...newRequest, API_URL: newUrl }; // updated URL
      return newRequest;
    } catch (error) {
      console.error(error);
      return newRequest;
    }
  }

  private updateUrlWithStage(
    request: UtilocateApiRequest,
  ): UtilocateApiRequest {
    let newRequest = request;

    try {
      let newUrl = request.API_URL;
      let stage = "dev";

      if (environment.production) {
        const stages = Object.keys(apiStage);

        // default stage value is "live" unless apiKey is found in another stage
        for (let i = 0; i < stages.length; i++) {
          if (apiStage[stages[i]].indexOf(request.API_KEY) > -1) {
            stage = stages[i];
            break;
          } else {
            stage = "live";
          }
        }
      }

      newUrl = newUrl.replace("{stage}", stage);
      newRequest = { ...newRequest, API_URL: newUrl }; // updated URL

      return newRequest;
    } catch (error) {
      console.error(error);
      return newRequest;
    }
  }

  private async getCacheOrCacheApiResult(request: UtilocateApiRequest, AssignmentID?: string, assignedTicket: boolean = false) {
    try {
      const apiResult = await this.invokeApi(
        request.API_TYPE,
        request.API_URL,
        request.API_BODY_STRING,
        request.API_HEADERS,
      );
      if (apiResult.status == 200) {
        if (cacheableApi.includes(request.API_KEY)) {
          const promiseArr = [];
          const valueToParse = apiResult.body.value ?? apiResult.body;
          const apiResultBody = JSON.parse(valueToParse);

          let tableData = {};

          Object.entries(apiResultBody).forEach((arr) => {
            let cacheServiceToUse: BaseCompletionCacheService | UtilocateAdminCacheService = this.utilocateAdminCacheService;

            //need to know to store in admin or completions idbs 
            if (this.completionCacheAPIs.includes(request.API_KEY)) {
              cacheServiceToUse = this.baseCompletionCacheService;
            }
            // If AssignmentID is provided, store table data with AssignmentID as the key
            if (AssignmentID) {
              tableData[arr[0]] = { 'Columns': arr[1]['Columns'], 'Data': arr[1]['Data'], 'name': arr[0] };
              //set the table data to have the key of the table and the value be the 
              //data and columns
            } else {
              //if assignmentID is not provided, it might be for admin tables 
              tableData = null;
              promiseArr.push(
                cacheServiceToUse.insertTable(arr[0], {
                  ...(arr[1] as object),
                  name: arr[0],
                }, null), //null assignmentID, beacuse this might be an admin table 
              );
            }
          });
          if (tableData) {
            const tables: Table[] = [];

            for (const tableKey of Object.keys(tableData)) {
              const table = new Table(tableData[tableKey].name, tableData[tableKey].Columns, tableData[tableKey].Data);
              tables.push(table);
            }

            //create a new object 
            const assignmentIDCacheObject: AssignmentIDCacheResult =
              new AssignmentIDCacheResult(assignedTicket, false, new Date(), tables);

            promiseArr.push(
              this.baseCompletionCacheService.setKey(AssignmentID, assignmentIDCacheObject),
            );
          }
          await Promise.all(promiseArr);
          return apiResult;
        } else {
          return apiResult;
        }
      } else {
        console.error(request.API_KEY);
        return apiResult;
      }
    } catch (error) {
      return error;
    }
  }

}

@Injectable({
  providedIn: "root",
})
export class ApiService extends BaseUtilocateApiService {
  constructor(
    protected tokenService: UtilocateTokenService,
    protected utilocateAdminCacheService: UtilocateAdminCacheService,
    protected handler: HttpBackend,
    protected baseCompletionCacheService: BaseCompletionCacheService,
    protected route: Router,
  ) {
    super(
      tokenService,
      utilocateAdminCacheService,
      handler,
      baseCompletionCacheService,
      route,
    );
  }
}
