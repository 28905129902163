import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { distinctUntilChanged, EMPTY, merge, Observable, Subject } from 'rxjs';
import { Ticket } from '../../../../../modules/shared/ticket/ticket.service';
import { SnackbarService } from '../../../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../../../modules/shared/snackbar/snackbar/snackbar';
import { MapFeatureInspectorComponent } from '../map-feature-inspector/map-feature-inspector.component';
import { CommonModule } from '@angular/common';
import { FloatingMapMenuButton, FloatingMapMenuComponent } from './floating-map-menu/floating-map-menu.component';
import { LocatorID, TicketMapService } from './ticket-map.service';
import { MatDialog } from '@angular/material/dialog';
import { TicketPinLegendComponent } from './ticket-pin-legend/ticket-pin-legend.component';
import { SearchableDropdownComponent } from '../../../inputs/searchable-dropdown/searchable-dropdown.component';
import { FormsModule } from '@angular/forms';
import { DispatchAreaEditorComponent } from '../dispatch-area-editor/dispatch-area-editor.component';
import { DispatchAreaEditorService } from '../dispatch-area-editor/dispatch-area-editor.service';
import { UserService } from '../../../../../modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { sections } from './ticket-pin-legend/content';
import {
  PinColourPopupComponent,
} from '../../../../../routes/home/ticket-list-map/pin-colour-popup/pin-colour-popup.component';
import { isEqual } from 'lodash-es';
import { MapBodyComponent } from './map-body/map-body.component';

import { TicketSearchService } from '../../../../services/ticket-search/ticket-search.service';
import { PopupDatePipe } from '../../../../pipes/popup-date.pipe';
import { RouteUserEditorComponent } from "../route-user-editor/route-user-editor.component";

/**
 * @class TicketMapComponent
 * @description
 * The `TicketMapComponent` is an Angular component that provides an interactive map for displaying tickets.
 * It allows for various map interactions such as zooming in/out, flying to user location, and going full screen.
 * It also supports different map layers including ticket pins, heat map, dispatch areas, and dig sites.
 * The component can handle ticket selection and reassignment.
 * @property {Observable<Ticket[]>} ticketStream$ - An observable stream of tickets.
 * @property {Observable<Ticket[]>} showRouting$ - An observable of whether to show or hide routing pins.
 * @property {Observable<DispatchAreaMap>} dispatchAreaStream$ - An observable stream of dispatch areas.
 * @property {boolean} canReassignTickets - A flag indicating whether tickets can be reassigned.
 * @property {EventEmitter<Ticket['AssignmentID']>} openTicket - An event emitter for opening a ticket.
 * @property {EventEmitter<[Ticket['AssignmentID'], LocatorID]>} reassignTicket - An event emitter for reassigning a ticket.
 * @method ngOnInit - A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
 * @method ngAfterViewInit - A lifecycle hook that is called after Angular has fully initialized a component's view.
 * @method ngOnDestroy - A lifecycle hook that is called just before Angular destroys the directive/component.
 * @method zoomIn - A method to zoom in the map.
 * @method zoomOut - A method to zoom out the map.
 * @method flyToUserLocation - A method to fly the map to the user's location.
 * @method goFullScreen - A method to toggle full screen mode of the map.
 * @method closeTicketPreview - A method to close the ticket preview.
 * @method dragBoxSetup - A method to set up the drag box for ticket selection.
 * @method getPinStyle - A method to get the style for the ticket pin based on the call type.
 * @method setupTicketSelection - A method to set up the ticket selection on the map.
 */
@Component({
    selector: 'app-ticket-map',
    standalone: true,
    templateUrl: './ticket-map.component.html',
    styleUrl: './ticket-map.component.scss',
    imports: [
        CommonModule,
        MatIconModule,
        MapFeatureInspectorComponent,
        FloatingMapMenuComponent,
        SearchableDropdownComponent,
        FormsModule,
        DispatchAreaEditorComponent,
        PinColourPopupComponent,
        MapBodyComponent,
        MapFeatureInspectorComponent,
        PopupDatePipe,
        RouteUserEditorComponent
    ]
})
export class TicketMapComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('ticketPreview') private ticketPreview: ElementRef;
  @ViewChild('ticketInspector') private ticketInspector: ElementRef;
  @ViewChild('locatorCheckin') private locatorCheckin: ElementRef;

  @Input() ticketStream$: Observable<Ticket[]>;
  @Input() dispatchAreasEnabled = false;
  @Input() canReassignTickets = false;
  @Input() showLocatorCheckins = false;
  @Input() showMultiselect = false;
  @Input() showRouting$: Observable<boolean>;
  @Output() openTicket = new EventEmitter<{ AssignmentID: Ticket['AssignmentID']; PrimaryID: Ticket['SubNum'] }>();
  @Output() reassignTicket = new EventEmitter<[Ticket['AssignmentID'], LocatorID]>();

  @Input() selectedTickets: Array<number> = [];
  @Output() selectedTicketsChanged: EventEmitter<Array<number>> = new EventEmitter();

  // services
  private dialog = inject(MatDialog);
  protected userService = inject(UserService);
  private ticketSearchService = inject(TicketSearchService);
  protected myService = inject(TicketMapService);
  private snackBarService = inject(SnackbarService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private dispatchAreaEditorService = inject(DispatchAreaEditorService);
  protected selectedLocatorID: { name: string; value: LocatorID }[];

  // observables
  private destroy$ = new Subject<void>();

  protected menuButtons: Array<FloatingMapMenuButton> = [
    {
      index: 0,
      title: 'Dispatch Areas',
      icon: 'dispatchArea',
      action: () => this.myService.updateMenuSelection(0),
    },
    {
      index: 1,
      title: 'routing',
      icon: 'routing',
      action: () => this.myService.updateMenuSelection(1),
    },
    {
      index: 2,
      title: 'Dig Areas',
      icon: 'shovel',
      action: () => this.myService.updateMenuSelection(2),
    },
    {
      index: 3,
      title: 'Pin Colors',
      icon: 'colors',
      action: () => this.myService.updateMenuSelection(3),
      target: 'ticketPinLegend',
    },
    {
      index: 4,
      title: 'Locator Check-in',
      icon: 'locators',
      action: () => this.myService.updateMenuSelection(4),
    },
    {
      index: 5,
      title: 'Ticket Heat Map',
      icon: 'heatMap',
      action: () => this.myService.updateMenuSelection(5),
    },
    {
      index: 6,
      title: 'Ticket-select',
      icon: 'full_screen',
      // action: () => {
      //   this.goFullScreen.bind(this)();
      // },
      action: () => {
        this.myService.updateMenuSelection(6);
        this.myService.dragBoxSetup(this.myService.menuSelection.includes(6));
      },
    },
    { index: 7, title: 'Ticket Pins', icon: 'pin_black', action: () => this.myService.updateMenuSelection(7) },
    { index: 8, title: 'Settings', icon: 'settings', action: () => this.ticketSearchService.triggerSettingsDialog() },
  ];

  constructor() {
    this.openTicket.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (document.fullscreenElement) {
        document.exitFullscreen().then(() => undefined);
      }
    });
  }

  ngOnInit() {
    this.myService.initializeService();
    this.myService.selectedTicket$.pipe(takeUntil(this.destroy$)).subscribe((next) => {
      if (next && next['Assigned ID'] != undefined && this.myService.locatorOptions) {
        this.selectedLocatorID = [this.myService.locatorOptions.find((x) => x.value === next['Assigned ID'])];
      } else {
        this.selectedLocatorID = [];
      }
    });

    this.myService.ticketSelection$.pipe(takeUntil(this.destroy$)).subscribe((next) => {
      this.selectedTicketsChanged.emit(next);
    });

    this.menuButtons = this.menuButtons.filter((x) => {
      //dispatch areas 
      if (x.index === 0) {
        return this.dispatchAreaEditorService.userCanModifySetting[0] && this.dispatchAreasEnabled;
      }
      //route a user 
      if (x.index === 1) {
        return this.dispatchAreaEditorService.userCanModifySetting[0] && this.dispatchAreasEnabled && this.userService.isSettingActive(SettingID.DISPATCH_AREA_ROUTING_MANAGEMENT);
      }
      if (x.index === 4) {
        return this.showLocatorCheckins;
      }
      if (x.index === 6) {
        return this.showMultiselect;
      }
      return true;
    });

    merge(this.myService.locatorCheckin$, this.myService.selectedTicket$, this.myService.previewTicket$)
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.ticketStream$
      ?.pipe(
        tap(() => {
          this.myService.clearTickets();
          this.selectedLocatorID = [];
        }),
        filter((tickets) => tickets && tickets.length > 0),
        tap(() => {
          if (!(this.myService.menuSelection.includes(7) || this.myService.menuSelection.includes(5))) {
            this.myService.updateMenuSelection(7);
          }
        }),
        map((tickets: Ticket[]) => {
          return this.myService.addTickets(tickets);
        }),
        catchError(() => {
          this.snackBarService.openSnackbar('Error loading tickets to map', SnackbarType.error, 'ERROR');
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((isMissingLatLon) => {
        if (!this.myService.dontFly) {
          this.myService.zoomToAllTickets();
        } else {
          this.myService.dontFly = false;
        }
        if (isMissingLatLon) {
          this.snackBarService.openSnackbar(
            'Some tickets are missing Latitude and Longitude',
            SnackbarType.warning,
            'WARNING'
          );
        }
      });

    // whether or not to show the map pins 
    this.showRouting$?.pipe(takeUntil(this.destroy$)).subscribe((showRoutingPins) => {
      this.myService.showRoutingPins(showRoutingPins);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isEqual(changes?.selectedTickets?.currentValue, changes?.selectedTickets?.previousValue)) {
      return;
    }
    if (changes.selectedTickets && changes.selectedTickets.currentValue) {
      this.myService.ticketSelection = changes.selectedTickets.currentValue;
    }
  }

  mapReady(div: HTMLDivElement): void {
    this.myService.attachMap(div);
    this.myService.attachOverlay(
      this.ticketInspector.nativeElement,
      this.ticketPreview.nativeElement,
      this.locatorCheckin.nativeElement
    );
  }

  ngOnDestroy() {
    this.myService.tidy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected reassignTicketClick(evt: [number, number]) {
    this.myService.dontFly = true;
    this.reassignTicket.emit(evt);
  }

  protected openTicketPinLegend() {
    this.dialog.open(TicketPinLegendComponent);
  }

  protected readonly SettingID = SettingID;
  protected readonly sections = sections;
}
