import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { localStorageKeys } from "../../../../LOCAL_STORAGE";

export interface Registration {
  ClientID: string;
  Code: string;
  ClientDBName: string;
  isOld: string;
  ClientName: string;
}

interface UtilocateToken {
  APP: string;
  USERID: string;
  LOCALUSERID: string;
  CLIENTID: string;
  USERCATEGORYID: string;
  U3USER: {
    USERID: string;
    USERCATEGORYID: string;
    EMAIL: string;
  };
  UXUSER: {
    USERID: string;
    USERCATEGORYID: string;
    EMAIL: string;
  };
  access: string;
}

export const UtilocateTokenPaths = {
  APP: "APP",
  USERID: "USERID",
  LOCALUSERID: "LOCALUSERID",
  CLIENTID: "CLIENTID",
  USERCATEGORYID: "USERCATEGORYID",
  U3USERID: "U3USER.USERID",
  U3USERCATEGORYID: "U3USER.USERCATEGORYID",
  U3EMAIL: "U3USER.EMAIL",
  UXUSERID: "UXUSER.USERID",
  UXUSERCATEGORYID: "UXUSER.USERCATEGORYID",
  UXEMAIL: "UXUSER.EMAIL",
  access: "access",
  VERSION: "VERSION",
};

export const UtilocateTokenPathKeys = {
  APP: "APP",
  USERID: "USERID",
  LOCALUSERID: "LOCALUSERID",
  CLIENTID: "CLIENTID",
  USERCATEGORYID: "USERCATEGORYID",
  U3USERID: "U3USERID",
  U3USERCATEGORYID: "U3USERCATEGORYID",
  U3EMAIL: "U3EMAIL",
  UXUSERID: "UXUSERID",
  UXUSERCATEGORYID: "UXUSERCATEGORYID",
  UXEMAIL: "UXEMAIL",
  access: "access",
  VERSION: "VERSION",
};

@Injectable({
  providedIn: "root",
})
export class UtilocateTokenService {
  readonly ERROR_TOKEN_KEY_NOT_FOUND: string =
    "Error: key does not exist in token's keys";

  private jwtHelper: JwtHelperService = new JwtHelperService();
  private Token: UtilocateToken;
  private docViewerToken: UtilocateToken;

  /**
   * save registration info in sessionStorage and localStorage
   * @param registration Registration to be saved
   */
  setRegistration(registration: Registration) {
    sessionStorage.setItem(
      "LOCATOR_REGISTRATION",
      JSON.stringify(registration)
    );
    localStorage.setItem("LOCATOR_REGISTRATION", JSON.stringify(registration));
  }

  /**
   * checks session and local storage for registration info
   * @returns true if registered, false otherwise
   */
  isRegistered(): boolean {
    if (sessionStorage.getItem("LOCATOR_REGISTRATION")) {
      return true;
    }
    if (localStorage.getItem("LOCATOR_REGISTRATION")) {
      return true;
    }
    return false;
  }

  /**
   *
   * @returns registration info from localStorage ONLY
   */
  getRegistration() {
    return localStorage.getItem("LOCATOR_REGISTRATION");
  }

  /**
   * deletes saved registration info from session and local storage
   */
  destroyRegistration() {
    sessionStorage.removeItem("LOCATOR_REGISTRATION");
    localStorage.removeItem("LOCATOR_REGISTRATION");
  }

  /**
   * saves a token to sessionStorage
   * @param token token to be saved
   * @param tokenKey key to be paired with token value
   */
  setToken(token: string, tokenKey: string = localStorageKeys.TOKEN_KEY) {
    sessionStorage.setItem(tokenKey, token);
    if (tokenKey != localStorageKeys.DOC_VIEWER_TOKEN_KEY) {
      localStorage.setItem(tokenKey, token);
      this.getJwtPayload(token); // init local Token object
    }
  }

  /**
   * remove token from local/session storage and set token to null
   */
  destroyToken() {
    sessionStorage.removeItem(localStorageKeys.TOKEN_KEY);
    sessionStorage.removeItem(localStorageKeys.DOC_VIEWER_TOKEN_KEY);
    localStorage.removeItem(localStorageKeys.TOKEN_KEY);
    this.Token = null;
  }

  /**
   * gets token from storage
   * @param tokenKey key for finding token value
   * @returns token value from local/session storage
   */
  getToken(tokenKey: string = localStorageKeys.TOKEN_KEY): string {
    return localStorage.getItem(tokenKey)
      ? localStorage.getItem(tokenKey)
      : sessionStorage.getItem(tokenKey);
  }

  /**
   * parses specified field from token
   * @param key the name of desired value
   * @param tokenKey key for getting token from local storage
   * @returns value corresponding to key
   */
  getValueFromToken(
    key: string,
    tokenKey: string = localStorageKeys.TOKEN_KEY
  ): string {
    if (Object.values(UtilocateTokenPaths).includes(key)) {
      const tokenData: UtilocateToken = this.getJwtPayload(
        this.getToken(tokenKey)
      );
      if (key.includes(".")) {
        // nested key
        let pathArr: string[] = key.split(".");
        let value = null;
        for (let path in pathArr) {
          if (!value) {
            value = tokenData[pathArr[path]];
          } else {
            value = value[pathArr[path]];
          }
        }
        return value;
      } else {
        return tokenData[key];
      }
    } else {
      return this.ERROR_TOKEN_KEY_NOT_FOUND;
    }
  }

  /**
   * check if user is authorized via token (and optionally registration)
   * @param checkRegister whether or not to check registration info in localstorage
   * @returns true if token exists (and optionally also registration)
   */
  isAuthorized(checkRegister = true) {
    if (checkRegister) {
      if (this.isRegistered() && this.getToken()) {
        return true;
      }
      return false;
    } else {
      if (this.getToken()) {
        return true;
      }
      return false;
    }
  }

  /**
   * uses jwtHelper to decode token into UtilocateToken object
   * @param token encoded token to extract payload from
   * @returns decoded payload from token
   */
  getJwtPayload(token: string): UtilocateToken {
    if (!this.Token) {
      var payload: UtilocateToken = null;
      var decoded = null;

      if (token) {
        decoded = this.jwtHelper.decodeToken(token);
      }

      if (decoded) {
        payload = decoded[localStorageKeys.TOKEN_PAYLOAD_KEY];
      }

      this.Token = payload;
      return payload;
    } else {
      return this.Token;
    }
  }
}
