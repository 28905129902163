<mat-menu
  #columnSelectMenu="matMenu"
  class="mat-menu flex flex-col max-h-[65vh] pt-2 w-64 max-w-64 overflow-x-hidden"
  (closed)="clearSearch(); isMenuOpen = false">
  <div class="flex flex-col w-full h-full">
    <div class="h-9 px-3">
      <app-competers-search-bar
        (searchValueChange)="searchControl.setValue($event)"
        (click)="$event.stopPropagation()" />
    </div>
    <mat-selection-list class="pl-4" [(ngModel)]="selectedColumns" (ngModelChange)="columnSelectionChanged($event)">
      <mat-list-option
        *ngFor="let choice of filteredChoices"
        [value]="choice"
        [disabled]="!choice.Title.toLowerCase().includes(searchControl.value?.toLowerCase())"
        class="font-rajdhani w-[230px] p-0 uppercase font-semibold"
        (click)="$event.stopPropagation()">
        {{ choice.Title }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</mat-menu>

<button mat-icon-button [matMenuTriggerFor]="columnSelectMenu" aria-label="Add / remove columns" (click)="open()">
  <mat-icon svgIcon="visible" [ngClass]="{ 'text-primary': isMenuOpen }"></mat-icon>
</button>
