<mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    [backgroundColor]="navBackground"
    (selectedIndexChange)="indexChange($event)"
    [selectedIndex]="tabIndex"
    class="mat-tabs"
  >
    <mat-tab label="INFO">
      <div class="info-section" fxLayout='column' fxLayoutAlign="start start">
        <div fxLayout='row'>
          <div class="field user-field">
            <span class="mat-subheading-s field-header">User Type</span>
            <mat-form-field class="field-input">
              <!-- <mat-label>User Type</mat-label> -->
              <mat-select class="font-normal" [(ngModel)]='updatedUser.UserCategoryID' name='UserCategoryID' (selectionChange)='userCategoryChanged()'>
                <mat-option *ngFor="let userCategory of userCategories" [value]="userCategory.UserCategoryID">
                  {{userCategory.Title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field">
            <span class="mat-subheading-s field-header">Truck #</span>
            <mat-form-field class="field-input">
              <!-- <mat-label>Truck #</mat-label> -->
              <input class="font-normal" matInput autocomplete="off" [(ngModel)]='updatedUser.TruckNum' name='TruckNum' (change)='userInfoChanged()'>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout='row'>
          <div class="field">
            <div class="field user-field">
              <span class="mat-subheading-s field-header">Supervisor</span>
              <mat-form-field class="field-input">
                <!-- <mat-label>Supervisor</mat-label> -->
                <mat-select class="font-normal" [(ngModel)]='updatedUser.Supervisor' name='Supervisor' (selectionChange)='userInfoChanged()'>
                  <mat-option *ngFor="let supervisor of supervisors" [value]="supervisor.UserID">
                    {{supervisor.Name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="field">
            <span class="mat-subheading-s field-header">Hex Colour</span>
            <ngx-colors
                class="field-input"
                ngx-colors-trigger
                name="hexColour"
                [(ngModel)]="updatedUser.HexColour"
                (ngModelChange)="onHexColourChange()"
              >
              </ngx-colors>
          </div>
        </div>
        <div fxLayoutGap="24px" fxLayout='row wrap'>
          <mat-checkbox class="font-normal" [disabled]="updatedUser.UserID == 0 || updatedUser.Archived == 1 || clientUserCount == 1" style="margin: 0px;" #check ngDefaultControl [(ngModel)]="updatedUser.isCloudSettingsUser" (change)='userInfoChanged()'>Use for settings</mat-checkbox>
          <mat-checkbox class="font-normal" [disabled]="updatedUser.isCloudSettingsUser == 1 || (clientUserCount == 1 && updatedUser.Archived == 0)" *ngIf="updatedUser.UserID > 0" style="margin: 0px;" #check ngDefaultControl [(ngModel)]="updatedUser.Archived" (change)='userInfoChanged()'>Archived</mat-checkbox>
          <div *ngIf="updatedUser.UserID > 0" fxLayout="row wrap" fxLayoutGap="24px">
            <div *ngIf="resettingPassword" class="password-field" fxLayout="row">
              <span class="mat-subheading-s field-header" style="padding-right: 9px;">New Password *</span>
              <mat-form-field class="field-input">
                <input class="font-normal" [type]="hidePassword ? 'password' : 'text'" matInput autocomplete="new-password" [(ngModel)]="tempPassword" name="tempPassword" required (blur)="onPasswordBlur($event)"/>
                <mat-icon class="password-btn" matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? "visibility" : "visibility_off" }}</mat-icon>
              </mat-form-field>
            </div>
            <div *ngIf="canModifyPasswords" fxLayout="row" fxLayoutAlign="start center">
              <button mat-flat-button color="primary" (click)="onResetPasswordClick()">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </mat-tab>
    <mat-tab label="CATEGORY SETTINGS">
      <!-- category settings -->
      <!-- settingIDs that aren't in settingsForSettingEditor  -->
      <app-edit-settings-component *ngIf="getGlobalSettings().length > 0"
        [settingIDs]="settingsFromUserCategory"
        [adminSettings]="getGlobalSettings()"
        (settingsChangeEvent)="updateUserSettings($event, true)"
        [accessLevel]="2"
        />
        <!-- accessLevel 4 is view only, 1 is delete access.
        set this one to 2 so that the user can still edit the setting values and toggle them, 
        but they are not able to add/remove settings from the user  -->
    </mat-tab>
    <mat-tab label="USER SETTINGS">
      <!-- user-specific settings -->
      <app-edit-settings-component *ngIf="possibleUserSettings.length > 0"
        [settingIDs]="settingsForSettingEditor"
        [adminSettings]="possibleUserSettings"
        (settingsChangeEvent)="updateUserSettings($event)"
        [(editState)]="editState"
        [accessLevel]="1"
        />
    </mat-tab>
  </mat-tab-group>