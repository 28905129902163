import { Injectable } from "@angular/core";
import { api, apiKeys } from "src/app/ENDPOINTS";
import {
  ApiService,
  UtilocateApiRequest,
} from "src/app/modules/core/api/baseapi.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class ExcavatorContactsService {
  actions = {
    getExcavatorContacts: 5,
    saveExcavatorContact: 6,
    saveAssignmentToExcavatorContact: 7,
    getAssignmentExcavatorContacts: 8,
    resetAssignmentToExcavatorContact: 9,
    switchAssignmentCompany: 10,
  };
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {}

  async getExcavatorInfo(id: number) {
    // return new Promise((resolve) => {
    let excavatorInfo = {};
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: this.actions.getExcavatorContacts,
        ExcavatorID: id,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      let result = apiResult["body"];

      if (result && result["status"] === 1 && result["value"]) {
        if (result["value"].length > 0) {
          result["value"].forEach((queryResult) => {
            if (
              queryResult &&
              queryResult.length > 0 &&
              queryResult[0]["result"]
            ) {
              excavatorInfo[queryResult[0]["result"]] = queryResult;
            }
          });
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return excavatorInfo;
  }

  /**
   *
   * @param excavatorDetails
   * ExcavatorContactDetails = {}
   * ExcavatorContactID
   * ExcavatorID
   * ContactName
   * PhoneNumber
   * PhoneExtension
   * PhoneNumber2
   * Phone2Extension
   * FaxNumber
   * Email
   * WebSite
   * NotificationMethod
   * isArchived
   */
  async saveExcavatorContact(excavatorDetails: ExcavatorContactDetailsPayload) {
    // saveExcavatorContact
    let saveResult = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: this.actions.saveExcavatorContact,
        ExcavatorContactDetails: excavatorDetails,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;
      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      let result = apiResult["body"];

      if (result && result["status"] === 1) {
        saveResult = true;
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return saveResult;
  }

  async saveAssignmentToExcavatorContact(
    payload: AssignmentToExcavatorContactPayload
  ) {
    // saveAssignmentToExcavatorContact
    let saveResult = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      let apiValue = {
        action: this.actions.saveAssignmentToExcavatorContact,
      };
      apiValue = { ...payload, ...apiValue };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      let result = apiResult["body"];

      if (result && result["status"] === 1) {
        saveResult = true;
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return saveResult;
  }

  async resetAssignmentToExcavatorContact(
    payload: ResetAssignmentToExcavatorContactPayload
  ) {
    // resetAssignmentToExcavatorContact
    let saveResult = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      let apiValue = {
        action: this.actions.resetAssignmentToExcavatorContact,
      };
      apiValue = { ...payload, ...apiValue };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;
      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      let result = apiResult["body"];

      if (result && result["status"] === 1) {
        saveResult = true;
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return saveResult;
  }

  async switchAssignmentCompany(payload: SwitchAssignmentCompanyPayload) {
    // switchAssignmentCompany
    let saveResult = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      let apiValue = {
        action: this.actions.switchAssignmentCompany,
      };
      apiValue = { ...payload, ...apiValue };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;
      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      let result = apiResult["body"];
      if (result && result["status"] === 1) {
        saveResult = true;
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return saveResult;
  }

  async getAssignmentExcavatorContacts(payload: number) {
    // saveAssignmentToExcavatorContact
    let result: any = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      let apiValue = {
        action: this.actions.getAssignmentExcavatorContacts,
        AssignmentID: payload,
      };

      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      if (
        apiResult &&
        apiResult["body"]["value"] &&
        apiResult["body"]["value"].length > 0
      ) {
        result = apiResult["body"]["value"];
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return result;
  }
}

export interface ExcavatorContactDetailsPayload {
  ExcavatorContactID?: number;
  ExcavatorID: number;
  ContactName: string;
  PhoneNumber: string;
  PhoneExtension: string;
  PhoneNumber2: string;
  Phone2Extension: string;
  FaxNumber: string;
  Email: string;
  WebSite: string;
  NotificationMethod: number;
  isArchived: number;
}
export interface AssignmentToExcavatorContactPayload {
  AssignmentID: Number;
  ExcavatorContactID: number;
  bFieldAdded: number;
  bFieldRemoved: number;
}

export interface ResetAssignmentToExcavatorContactPayload {
  AssignmentID: Number;
}

export interface SwitchAssignmentCompanyPayload {
  AssignmentID: Number;
  ExcavatorID: Number;
}
