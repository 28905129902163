import {
  LineStyle,
  LineThickness,
  LineTools,
  ManipulationTools,
  SelectionTools,
  ShapeTools,
  ToolbarItemType,
  ToolType,
} from '../utilities/types';

export type MenuItem = {
  altText: string;
  imgSource?: string;
  iconName?: string;
  type: ToolbarItemType;
  name: LineTools | SelectionTools | ShapeTools | LineThickness | LineStyle | ManipulationTools;
};

const mainMenu = [
  {
    name: 'select tools',
    type: ToolType.selectionTool,
    iconName: 'reshape',
  },
  {
    name: 'line tools',
    type: ToolType.lineTool,
    iconName: 'line tools',
  },
  {
    name: 'shape tools',
    type: ToolType.shapeTool,
    iconName: 'shape tools',
  },
  {
    name: 'text tools',
    type: ToolType.textTool,
    iconName: 'text tools',
  },
  {
    name: 'symbol tools',
    type: ToolType.symbolTool,
    iconName: 'symbol tools',
  },
  {
    name: 'sticker tools',
    type: ToolType.stampTool,
    iconName: 'sticker',
  },
  {
    name: 'misc tools',
    type: ToolType.imageTool,
    iconName: 'misc tools',
  },
];

const lineTools: MenuItem[] = [
  {
    altText: 'solid line tool',
    imgSource: 'assets/icons/drawing%20toolbar%20icons/Line%20Tool-01.svg',
    name: LineTools.singleLine,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'multi-line line tool',
    imgSource: 'assets/icons/drawing%20toolbar%20icons/Right%20Angled%20Line%20Tool-01.svg',
    name: LineTools.multiLine,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'freehand line tool',
    imgSource: 'assets/icons/drawing%20toolbar%20icons/Squiggly%20Line%20Tool-01.svg',
    name: LineTools.freeHand,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'symbol-line tool',
    imgSource: 'assets/icons/drawing%20toolbar%20icons/Facing%20Arrow%20Tool-01.svg',
    name: LineTools.measureLine,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'arrow-line tool',
    imgSource: 'assets/icons/drawing%20toolbar%20icons/Arrow%20Tool-01.svg',
    name: LineTools.arrowLine,
    type: ToolbarItemType.Tool,
  },
];
const shapeTools: MenuItem[] = [
  {
    altText: 'circle tool',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Shapes.svg',
    name: ShapeTools.circle,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'square tool',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Square.svg',
    name: ShapeTools.square,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'polygon tool',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Irregular%20Polygon.svg',
    name: ShapeTools.polygon,
    type: ToolbarItemType.Tool,
  },
];
const textTools = [];
const lineTypes: MenuItem[] = [
  {
    altText: 'solid line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Solid%20Line.svg',
    name: LineStyle.solid,
    type: ToolbarItemType.StyleOption,
  },
  {
    altText: 'dashed line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Dotted%20Line.svg',
    name: LineStyle.dashed,
    type: ToolbarItemType.StyleOption,
  },
];
const lineThickness: MenuItem[] = [
  {
    altText: 'thinnest line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Line%20Thinnest.svg',
    name: LineThickness.thinnestLine,
    type: ToolbarItemType.StyleOption,
  },
  {
    altText: 'thin line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Line%20Thin.svg',
    name: LineThickness.thinLine,
    type: ToolbarItemType.StyleOption,
  },
  {
    altText: 'medium line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Line%20Medium.svg',
    name: LineThickness.mediumLine,
    type: ToolbarItemType.StyleOption,
  },
  {
    altText: 'bold line',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Line%20Bold.svg',
    name: LineThickness.boldLine,
    type: ToolbarItemType.StyleOption,
  },
];
const selectionTools: MenuItem[] = [
  {
    altText: 'point selection',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/reshape.svg',
    name: SelectionTools.pointSelect,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'box selection',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/Lasso.svg',
    name: SelectionTools.boxSelect,
    type: ToolbarItemType.Tool,
  },
];

const manipulationTools: MenuItem[] = [
  {
    altText: 'scale',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/SCALE-01.svg',
    name: ManipulationTools.scale,
    type: ToolbarItemType.Tool,
  },
  {
    altText: 'rotate',
    imgSource: '/assets/icons/drawing%20toolbar%20icons/ROTATE.svg',
    name: ManipulationTools.rotate,
    type: ToolbarItemType.Tool,
  },
];

export { mainMenu, lineTools, shapeTools, textTools, lineThickness, lineTypes, selectionTools, manipulationTools };
