import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Model } from '@swimlane/ngx-datatable';

@Directive({
  selector: '[appNgxDatatableDblClick]',
  standalone: true,
})
export class NgxDatatableDblClickDirective {
  @Output('appNgxDatatableDblClick') dblClick = new EventEmitter();

  constructor() {}

  @HostListener('activate', ['$event'])
  onActivate(event: Model): void {
    if (event.type === 'dblclick' && event.row) {
      const target = event.event.target;
      if (target instanceof HTMLInputElement && target.type === 'checkbox') {
        return;
      }
      this.dblClick.emit(event.row);
    }
  }
}
