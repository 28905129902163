<div style="margin-bottom: 10px">
  <button mat-raised-button color="primary" (click)="fileInput.click()">
    <span>Upload</span>
    <input
      #fileInput
      style="display: none"
      type="file"
      (change)="onSelectFile($event)"
      multiple
    />
  </button>

  <span *ngIf="numOfSavedFiles > 0">
    {{ numOfSavedFiles }} file(s) selected</span
  >

  <!-- <mat-list *ngIf='readonly'>
    <mat-list-item *ngFor='let file of selectedFiles'>{{file['name']}}</mat-list-item>
  </mat-list> -->
</div>
