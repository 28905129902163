import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule} from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {MatSnackBarConfig} from "@angular/material/snack-bar";
import {RouterModule} from "@angular/router";
import {Subscription} from "rxjs";
import {localStorageKeys} from "src/app/LOCAL_STORAGE";

import {
  ExcavatorContactsCardModule,
  ExcavatorContactsDialogComponent,
} from "../excavator-contacts-card/excavator-contacts-card.component";
import {SlideOverSidePanelModule} from "../slideover-side-panel/slideover-side-panel.component";
import {CreateTicketContentPanelComponent} from "./panels/create-ticket-content-panel/create-ticket-content-panel.component";
import {CreateTicketQuickPanelComponent} from "./panels/create-ticket-quick-panel/create-ticket-quick-panel.component";
import {CreateTicketCompanyViewComponent} from "./views/create-ticket-company-view/create-ticket-company-view.component";
import {CreateTicketDetailsViewComponent} from "./views/create-ticket-details-view/create-ticket-details-view.component";
import {CreateTicketDocsViewComponent} from "./views/create-ticket-docs-view/create-ticket-docs-view.component";
import {CreateTicketQuickViewComponent} from "./views/create-ticket-quick-view/create-ticket-quick-view.component";
import {CreateTicketScheduleViewComponent} from "./views/create-ticket-schedule-view/create-ticket-schedule-view.component";
import {CreateTicketWorksiteViewComponent} from "./views/create-ticket-worksite-view/create-ticket-worksite-view.component";
import JSZip from "jszip";
import {switchMap} from "rxjs/operators";
import {ComponentMessagingService} from "../../core/component-messaging/component-messaging.service";
import {BaseComponent} from "../../core/base-set/base-set.component";
import {LoggerService} from "../../core/services/logger/logger.service";
import {UploadService} from "../../digsite/upload.service";
import {CreateTicketComponentService} from "../create-ticket-component.service";
import {CompletionsStoreService} from "../../core/admin/completions-store.service";
import {ProgressBarService} from "../../shared/progress-bar/progress-bar.service";
import {SnackbarService} from "../../shared/snackbar/snackbar.service";
import {DownloadDocumentService} from "../../core/services/document/download-document.service";
import {UploadDocumentService} from "../../shared/upload-documents/upload-documents/upload-document.service";
import {ComponentMessage} from "../../core/component-messaging/component-message";
import {CreateTicketMessageActions} from "../CreateTicketInterface";
import {SnackbarType} from "../../shared/snackbar/snackbar/snackbar";
import {DocumentService} from "../../core/services/document/document.service";
import {
  ActionMessage,
  Actions,
} from "../../core/component-messaging/action-message";
import {
  FormInputTemplateModule,
  FormTemplateDialogComponent,
  FormTemplateField,
} from "../../shared/forms/form-input-template/form-input-template.component";
import {SettingID} from "../../core/services/user/setting";
import {
  ConfirmationDialogModule,
  DialogData,
} from "../../shared/confirmation-dialog/confirmation-dialog.component";
import {MaterialModule} from "../../shared/material.module";
import {GoogleMapsSetModule} from "../../shared/google-map/google-maps-set/google-maps-set.component";
import {FloatingTabSelectComponent} from "../../../shared/components/misc/selection/floating-tab-select/floating-tab-select.component";
import {FloatingActionMenuComponent} from "../../shared/fab/floating-action-menu/floating-action-menu.component";
import {PillMenuButtonComponent, PillMenuComponent} from "../../../shared/components/containers/pill-menu/pill-menu.component";

enum tabletScreenDimsWidth {
  start = 768,
  end = 992,
}

@Component({
  selector: "app-create-ticket-component",
  templateUrl: "./create-ticket.component.html",
  styles: [``],
  providers: [ComponentMessagingService],
})
export class CreateTicketComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy {
  className: string = "CreateTicketComponent";
  childProps = {};
  views = {};
  documentChangesSubscriber: Subscription;
  ticketChangesSubscriber: Subscription;
  parentSubscriber: Subscription;
  uploadSyncSubscription: Subscription;
  uploadTicketSubscription: Subscription;
  childMsgSubscriber: Subscription;
  scheduleChangesSubscriber: Subscription;
  dataChangesSubscriber: Subscription;
  documents = {};
  utilityViews = {};
  commonViews = {};
  ticketData = {};
  prefillFields = [];
  isMinified = false;
  showContentPanel = true;
  showQuickPanel = true;
  currentExcavatorID = -1;
  excavatorContactsSubscriber;
  onCreateBtnDisabled: any = false;

  @Output() syncFinished: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(CreateTicketQuickPanelComponent, {static: true})
  quickPanel: CreateTicketQuickPanelComponent;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private upload$: UploadService,
    private ticket$: CreateTicketComponentService,
    private completions$: CompletionsStoreService,
    private dialog: MatDialog,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private downloadDoc$: DownloadDocumentService,
    private uploadDoc$: UploadDocumentService,
    private documentService: DocumentService
  ) {
    super(logger$);
    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      documents: this.documents,
      utilityViews: this.utilityViews,
      commonViews: this.commonViews,
      prefillFields: this.prefillFields,
      onCreateBtnDisabled: this.onCreateBtnDisabled,
    };

    upload$.generateCreateTicketSessionID();

    this.childMsgSubscriber = this.compMsg$
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        this.logger$.log(this.className + "ChildListener:", nextMsg);
        this.onReceiveChildMessage(nextMsg);
      });
  }

  ngOnInit(): void {
    try {
      this.logger$.log(this.className + "init");
      this.listenForTicketDataChanges();
      this.listenForDocumentDataChanges();
      this.listenForScheduleDataChanges();
      this.listenForViewDataChanges();
      // this.cleanCompletionsIDB();
      this.handlePreviousSession();
    } catch (error) {
      this.logger$.error(this.className + "ngoninit: " + error);
    }
    //if we're on an ipad or our screen size is medium 
    //toggle the side panel 

  }

  private onReceiveChildMessage(nextMsg: ComponentMessage) {
    if (nextMsg.action) {
      if (nextMsg.action == CreateTicketMessageActions.SWITCH_CONTENT_VIEW) {
        if (nextMsg.message.togglePanel) {
          this.togglePanel();
        }
      }
    }
  }

  private async handlePreviousSession() {
    try {
      if (await this.hasPreviousSession()) {
        this.cleanCreateTicketSessions();
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  openCreateTicketPreviousSessionDialog(): void {
    const dialogRef = this.dialog.open(
      CreateTicketPreviousSessionDialogComponent,
      {
        width: "250px",
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBarService.openSnackbar(
          "Restoring Session...",
          SnackbarType.default
        );
        this.restoreCreateTicketSession();
      } else {
        this.cleanCreateTicketSessions();
      }
    });
  }

  private cleanCreateTicketSessions() {
    try {
      this.completions$.cleanCreateTicketRows().then((result) => {
        let snackbarConfig: MatSnackBarConfig = {
          duration: 2000,
          horizontalPosition: "center",
          verticalPosition: "top",
        };
        this.completions$.resetBody();
        this.snackBarService.openSnackbar(
          "New Session Started",
          SnackbarType.success,
          "Close"
        );
      });
    } catch (error) {
      this.logger$.error(error.message, "cleanCreateTicketSessions");
    }
  }

  private restoreCreateTicketSession() {
    try {
      this.completions$.getCreateTicketRow().then((row) => {
        if (row && row["key"]) {
          sessionStorage.setItem(
            localStorageKeys.CURRENT_CREATE_TICKET_KEY,
            row["key"]
          );
          //TODO patch values
        }
      });
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  private async hasPreviousSession(): Promise<boolean> {
    return this.completions$.hasCreateTicketRow();
  }

  updateAddress(address) {
    this.quickPanel.quickPanelUpdate(address);
  }

  togglePanel(input?: string) {
    if (input && input === 'close') {
      this.showContentPanel = false;
      this.showQuickPanel = true;
      return;
    }
    if (this.isMinified) {
      this.showQuickPanel = !this.showQuickPanel;
    } else {
      this.showContentPanel = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"]
      ) {
        this.views = changes["props"]["currentValue"]["views"];
        if (changes["props"]["currentValue"]["createTicketOptions"]) {
          if (
            changes["props"]["currentValue"]["createTicketOptions"][
            "prefillFields"
            ]
          ) {
            this.prefillFields =
              changes["props"]["currentValue"]["createTicketOptions"][
              "prefillFields"
              ];
            this.logger$.log(this.prefillFields);

            this.patchPrefill();
          }
          if (
            changes["props"]["currentValue"]["createTicketOptions"][
            "isMinified"
            ]
          ) {
            this.isMinified =
              changes["props"]["currentValue"]["createTicketOptions"][
              "isMinified"
              ];
          }
          if (
            changes["props"]["currentValue"]["createTicketOptions"]["documents"]
          ) {
            // this.addDocumentsFromCopyTicket(changes["props"]["currentValue"]["createTicketOptions"]["documents"]);
          }
        }

        if (changes["props"]["currentValue"]["commonViews"]) {
          this.commonViews = changes["props"]["currentValue"]["commonViews"];
        }

        this.childProps = {
          ...this.childProps,
          views: this.views,
          commonViews: this.commonViews,
          prefillFields: this.prefillFields,
        };
      }
    } catch (error) {
      this.logger$.error(this.className + "ngon" + error.message);
    }
  }

  ngOnDestroy() {
    try {
      if (this.ticketChangesSubscriber) {
        this.ticketChangesSubscriber.unsubscribe();
      }
      if (this.documentChangesSubscriber) {
        this.documentChangesSubscriber.unsubscribe();
      }
      if (this.parentSubscriber) {
        this.parentSubscriber.unsubscribe();
      }
      if (this.uploadSyncSubscription) {
        this.uploadSyncSubscription.unsubscribe();
      }
      if (this.uploadTicketSubscription) {
        this.uploadTicketSubscription.unsubscribe();
      }
      if (this.scheduleChangesSubscriber) {
        this.scheduleChangesSubscriber.unsubscribe();
      }
      if (this.dataChangesSubscriber) {
        this.dataChangesSubscriber.unsubscribe();
      }
      //Clean storage
      this.completions$.resetBody();
    } catch (error) {
      this.logger$.error(error);
    }
  }

  private async patchPrefill() {
    try {
      if (
        this.views &&
        this.prefillFields &&
        Object.keys(this.prefillFields).length > 0
      ) {
        for (let viewKey in this.views) {
          if (this.views[viewKey]["formGroup"]) {
            //patch value {key:val,key:val}
            this.views[viewKey]["formGroup"].patchValue(this.prefillFields);
          }
        }
        if (this.prefillFields["worksiteAddress"]) {
          let message = {
            action: 4,
            data: {
              LocateAddress: this.prefillFields["LocateAddress"],
              LocateSubRegionName: this.prefillFields["LocateSubRegionName"],
              StartHouseNumber: this.prefillFields["StartHouseNumber"],
            },
          };
          var msg: ComponentMessage = {
            action: CreateTicketMessageActions.ADDRESS_UPDATED,
            message: message.data,
            senderID: this.className,
          };
          this.compMsg$.sendToMessageStream(msg);
        }

        if (this.prefillFields["OriginalExcavationDate"]) {
          await this.ticket$.onDateSelected(
            "OriginalExcavationDate",
            this.prefillFields["OriginalExcavationDate"]
          );
        }

        if (this.prefillFields["OriginalExcavationDate"]) {
          await this.ticket$.onDateSelected(
            "OriginalExcavationDate",
            this.prefillFields["OriginalExcavationDate"]
          );
        }

        if (this.prefillFields["EventStartDateTime"]) {
          await this.ticket$.onDateSelected(
            "EventStartDateTime",
            this.prefillFields["EventStartDateTime"]
          );
        }

        if (this.prefillFields["EventEndDateTime"]) {
          await this.ticket$.onDateSelected(
            "EventEndDateTime",
            this.prefillFields["EventEndDateTime"]
          );
        }

        if (this.prefillFields["utilities"]) {
          this.ticket$.handleUpdateCompletionUtilities(
            this.prefillFields["utilities"]
          );
        }
        if (this.prefillFields["calltype"]) {
          this.handleCallTypeUpdate(this.prefillFields["calltype"].value);
          this.ticket$.handleUpdateCompletionsCallType(
            this.prefillFields["calltype"]
          );
        }
      }
    } catch (error) {
      this.logger$.error("patch prefill: " + error.message);
    }
  }

  private async addDocumentsFromCopyTicket(docs: any[]) {
    try {
      if (docs && docs.length > 0) {
        this.progressBarService.start();
        this.onCreateBtnWhileLoading(true);
        let assignmentID = docs[0]["AssignmentID"];

        let docZip = await this.downloadDoc$.getDocumentAll(assignmentID);
        let docZipBuffer = await fetch(docZip["buffer"]).then((r) => r.blob());
        let zip = await JSZip.loadAsync(docZipBuffer);
        let files = [];

        zip.forEach(function (relativePath, zipEntry) {
          // console.log("relativePath:", relativePath);
          files.push({
            name: zipEntry["name"],
            file: this.documentService.arrayBufferToBase64(
              zipEntry["_data"]["compressedContent"]
            ),
          });
        });

        let docsToAdd = [];

        for (let i = 0; i < files.length; i++) {
          docsToAdd.push({
            name: files[i]["name"],
            file: files[i]["file"],
            docTypeID: docs[i].DocumentTypeID,
            uploaded: false,
          });
        }

        this.uploadDoc$
          .prepareDocumentsForUpload(docsToAdd)
          .pipe(
            switchMap((zippedFiles) => {
              return this.uploadDoc$.updateDocumentData(
                new ActionMessage(Actions.UPDATE_DOCUMENT_DATA, {
                  value: zippedFiles,
                })
              );
            })
          )
          .subscribe((successfulCopy: boolean) => {
            console.log("successfulCopy: ", successfulCopy);
            if (successfulCopy) {
              this.snackBarService.openSnackbar(
                "Copied documents from original ticket",
                SnackbarType.success
              );
            } else {
              this.snackBarService.openSnackbar(
                "Failed to copy documents from original ticket",
                SnackbarType.error
              );
            }
            this.progressBarService.stop();
            this.onCreateBtnWhileLoading(false);
          });
      }
    } catch (error) {
      console.error(error);
      this.progressBarService.stop();
      this.onCreateBtnWhileLoading(false);
    }
  }

  /* Updates the schedule data. Currently in a switch but could
   * be simplified because there is only one case.
   */
  listenForScheduleDataChanges() {
    this.scheduleChangesSubscriber = this.ticket$
      .getOnScheduleDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_SCHEDULE_DATA:
            this.handleScheduleDataChange(message);
            break;
        }
      });
  }
  /* ?
   */
  listenForViewDataChanges() {
    try {
      this.dataChangesSubscriber = this.ticket$
        .getOnDataChange()
        .subscribe((message: ActionMessage) => {
          //TODO make case for each view change (ExcavatorContact, ExcavatorNames...etc)
          let formField: FormTemplateField =
            this.views["ticketSummary"]["groups"]["companyInfo"]["fields"][
            "ExcavatorContacts"
            ];
          formField.isVisible = true;
          formField.isRequired = true;
          this.ticket$
            .refreshExcavatorContacts(formField, message.data)
            .then(() => {
              this.childProps = {...this.childProps, views: this.views};
            });
        });
    } catch (error) {
      this.logger$.error("listenView:" + error.message);
    }
  }

  /* If the current ticket's data changes, then the appropriate
   * update is applied to the ticket. -> maybe all unique functions in refactor
   */
  listenForTicketDataChanges() {
    this.ticketChangesSubscriber = this.ticket$
      .getOnTicketDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_DIGSITE_DETAILS:
            // this.toggleCustomErrorStates("digsiteHeaderText", false);
            // this.userAddedPolygon = true;
            break;
          case Actions.UPDATE_TICKET_ADDRESS_DATA:
            this.logger$.log("Update/patch address");
            this.handleUpdateTicketAddress(message);
            break;
          case Actions.CLEAR_POLYGON_DATA:
            this.logger$.log("Cleared polygons");
            break;
          case Actions.RESET_DATA:
            this.logger$.log("reset data on create");
            break;
          case Actions.UPDATE_UTILITY_DATA:
            this.handleUtilityUpdate(message);
            break;
          case Actions.UPDATE_TICKET_EXCAVATION_DATE:
            this.handleTicketDateUpdate(message);
            break;
          case Actions.UPDATE_TICKET_CALLDATE:
            this.handleTicketDateUpdate(message);
            break;
          case Actions.UPDATE_EXCAVATOR_DATA:
            this.handleExcavatorUpdate(message);
            break;
          case Actions.UPDATE_TICKET_CALLTYPE:
            this.handleCallTypeUpdate(
              message.data.Ticket.TicketDetails.CallTypeID
            );
            break;
        }
      });
  }

  sendResetData() {
    try {
      let msg: ComponentMessage = {
        action: CreateTicketMessageActions.DATA_RESET,
        message: true,
        senderID: this.className,
      };
      if (this.compMsg$) {
        this.compMsg$.sendToMessageStream(msg);
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  /*
   *
   */
  handleScheduleDataChange(message) {
    try {
      if (Object.values(message.data).length > 0) {
        this.ticket$
          .getAvailableSchedulerResources()
          .then((resources) => {
            if (resources && resources.length > 0) {
              let availableResource: any = "";
              if (this.views) {
                for (let viewKey in this.views) {
                  if (
                    this.views[viewKey]["groups"]["schedule"] &&
                    this.views[viewKey]["groups"]["schedule"]["fields"][
                    "AvailableRigs"
                    ]
                  ) {
                    if (this.prefillFields["AvailableRigs"]) {
                      resources.forEach((resource) => {
                        if (
                          resource.value ==
                          this.prefillFields["AvailableRigs"][0].value
                        ) {
                          availableResource = [resource];
                        }
                      });
                    }
                    this.views[viewKey]["formGroup"].patchValue({
                      AvailableRigs: availableResource,
                    });
                    this.views[viewKey]["groups"]["schedule"]["fields"][
                      "AvailableRigs"
                    ]["selectOptions"] = resources;
                  }
                }

                this.childProps = {...this.childProps, views: this.views};
              }
            }
          })
          .catch((error) => {
            this.logger$.error("handleTicketDateUpdate:getA: " + error);
          });
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  handleExcavatorUpdate(message) {
    try {
      if (
        message.data["Ticket"] &&
        message.data["Ticket"]["ExcavatorDetails"]
      ) {
        //update list
        this.updateExcavatorList(
          message.data["Ticket"]["ExcavatorDetails"]
        ).then((isUpdated) => {
          if (isUpdated) {
            this.logger$.log("Updated excavator List");
          }
          let excavatorName: any = "";
          if (message.data["Ticket"]["ExcavatorDetails"]["ExcavatorName"]) {
            excavatorName =
              message.data["Ticket"]["ExcavatorDetails"][
                "ExcavatorName"
              ].trim();
            if (message.data["Ticket"]["ExcavatorDetails"]["ExcavatorID"]) {
              excavatorName = {
                text: excavatorName,
                value:
                  message.data["Ticket"]["ExcavatorDetails"]["ExcavatorID"],
              };
            }
          }
          //patch all views with ExcavatorName rn.
          for (let valueIndex in this.views) {
            if (this.views[valueIndex]["formGroup"]) {
              this.views[valueIndex]["formGroup"].patchValue({
                ExcavatorName: excavatorName,
                SearchExcavatorName: excavatorName,
              });
            }
          }
        });
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  async updateExcavatorList(excavatorDetails) {
    let isUpdated = false;
    try {
      if (this.views) {
        if (this.views["companyInfo"]) {
          this.views["companyInfo"] = await this.ticket$.refreshFilteredOptions(
            this.views["companyInfo"],
            "companyDetails",
            "ExcavatorName"
          );
        }
        if (this.views["ticketSummary"]) {
          this.views["ticketSummary"] =
            await this.ticket$.refreshFilteredOptions(
              this.views["ticketSummary"],
              "companyInfo",
              "ExcavatorName"
            );
        }

        //refresh contact list
        if (
          excavatorDetails["ExcavatorID"] &&
          excavatorDetails["contacts"] &&
          this.ticket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR]
        ) {
          if (this.currentExcavatorID != excavatorDetails["ExcavatorID"]) {
            // update select options
            let formField: FormTemplateField =
              this.views["ticketSummary"]["groups"]["companyInfo"]["fields"][
              "ExcavatorContacts"
              ];
            formField.isVisible = true;
            formField.isRequired = true;
            await this.ticket$.refreshExcavatorContacts(
              formField,
              excavatorDetails["ExcavatorID"]
            );
            this.currentExcavatorID = excavatorDetails["ExcavatorID"];
          }
          //update selection
          if (
            this.views["ticketSummary"]["formGroup"]["controls"][
            "ExcavatorContacts"
            ]
          ) {
            let control: FormControl =
              this.views["ticketSummary"]["formGroup"]["controls"][
              "ExcavatorContacts"
              ];
            //make sure control has subscriber
            if (!this.excavatorContactsSubscriber) {
              this.excavatorContactsSubscriber = control.valueChanges.subscribe(
                (msg) => {
                  if (msg) {
                    var compMsg: ComponentMessage = {
                      action:
                        CreateTicketMessageActions.QUICK_EXCAVATOR_CONTACTS_UPDATE,
                      message: msg,
                      senderID: this.className,
                    };
                    this.compMsg$.sendToMessageStream(compMsg);
                  }
                }
              );
            }

            let values = [];
            excavatorDetails["contacts"].forEach((contact) => {
              values.push({
                text: contact.ContactName,
                value: contact.ExcavatorContactID,
              });
            });
            control.reset();
            control.setValue(values);
          }
        }
      }

      this.childProps = {...this.childProps, views: this.views};
      isUpdated = true;
    } catch (error) {
      this.logger$.error(error.message);
    }
    return isUpdated;
  }

  /* The address of the ticket is properly parsed and set as the
   * worksite address. Currently sends a message into the stream
   * to update and say that the ticket address has been changed.
   */

  //LocateAddress: "56 Annie Craig Drive"
  //LocateSubRegionName: "Toronto"
  handleUpdateTicketAddress(message: ActionMessage) {
    try {
      let newValue = "";
      if (
        message.data["LocateAddress"] &&
        message.data["LocateSubRegionName"]
      ) {
        newValue =
          message.data["StartHouseNumber"] +
          " " +
          message.data["LocateAddress"] +
          ", " +
          message.data["LocateSubRegionName"];
      } else if (message.data["LocateAddress"]) {
        newValue =
          message.data["StartHouseNumber"] +
          " " +
          message.data["LocateAddress"];
      } else if (message.data["LocateSubRegionName"]) {
        newValue = message.data["LocateSubRegionName"];
      }
      // WorksitePatchValues
      if (this.views && Object.keys(this.views).length > 0) {
        for (let valueIndex in this.views) {
          if (this.views[valueIndex]["formGroup"]) {
            this.views[valueIndex]["formGroup"].patchValue({
              worksiteAddress: newValue,
              ...message.data,
            });
          }
        }
        var msg: ComponentMessage = {
          action: CreateTicketMessageActions.ADDRESS_UPDATED,
          message: message.data,
          senderID: this.className,
        };
        this.compMsg$.sendToMessageStream(msg);
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  resetUtilityDropdownList() {
    this.updateUtilityDropdownList();
  }

  updateUtilityDropdownList(visibleUtilities = []) {
    try {
      if (this.views && Object.keys(this.views).length > 0) {
        for (let valueIndex in this.views) {
          if (this.views[valueIndex]["groups"]) {
            let groups = this.views[valueIndex]["groups"];
            for (let groupIndex in groups) {
              if (
                groups[groupIndex]["fields"] &&
                groups[groupIndex]["fields"]["utilities"]
              ) {
                let utilities = groups[groupIndex]["fields"]["utilities"];
                if (visibleUtilities.length > 0) {
                  utilities["selectOptions"] =
                    this.getListOfUtilitiesForDropdown(visibleUtilities);
                } else {
                  utilities["selectOptions"] = this.ticket$.clientUtilities;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  getListOfUtilitiesForDropdown(utilityids): any[] {
    let filteredList = [];
    try {
      utilityids.forEach((utilityid) => {
        this.ticket$.clientUtilities.forEach((utilityRow) => {
          if (utilityid == utilityRow["value"]) {
            filteredList.push(utilityRow);
          }
        });
      });
    } catch (error) {
      this.logger$.error(error);
    }
    return filteredList;
  }

  handleUtilityUpdate(message: ActionMessage) {
    try {
      if (message.data["Ticket"] && message.data["Ticket"]["UtilityDetails"]) {
      } else if (message.data["utilityViews"]) {
        //pass this to childProps for ui changes.
        //need to add all missing utilityViews and remove any not in
        //add all to current views
        if (
          this.utilityViews &&
          Object.keys(this.utilityViews).length > 0 &&
          message.data["utilityViews"]["newViews"]
        ) {
          let currentViews = [];
          let activeViews = message.data["utilityViews"]["activeViews"];
          message.data["utilityViews"]["newViews"].forEach((newView) => {
            this.utilityViews["newViews"].push(newView);
          });
          this.utilityViews["newViews"].forEach((view) => {
            if (activeViews.includes(view.key)) {
              currentViews.push(view);
            }
          });
          this.utilityViews["activeViews"] = activeViews;
          this.utilityViews["newViews"] = currentViews;
        } else {
          //when empty
          this.utilityViews = message.data["utilityViews"];
        }
        this.patchUtilitiesSelected(message);
        this.childProps = {
          ...this.childProps,
          utilityViews: this.utilityViews,
        };
      }
    } catch (error) {
      this.logger$.error("handleUtilityUpdate" + error.message);
    }
  }

  handleCallTypeUpdate(callTypeID) {
    try {
      this.patchCallTypesSelected(callTypeID);
      this.ticket$
        .getAdminCallTypeToUtility(callTypeID)
        .subscribe((utilities) => {
          if (utilities && utilities[0]) {
            //patch utilities dropdown with first and limit list.
            let event = [
              {
                text: "",
                value: utilities[0]["UtilityID"],
              },
            ];

            let utilityList: any = utilities;
            let utilityIDs = utilityList.map((row) => row["UtilityID"]);

            this.updateUtilityDropdownList(utilityIDs);
            this.ticket$.handleUpdateCompletionUtilities(event);
          } else {
            this.updateUtilityDropdownList();
            this.ticket$.resetUtilities();
          }
        });
    } catch (error) {
      this.logger$.error("handleUtilityUpdate" + error.message);
    }
  }

  private patchUtilitiesSelected(msg) {
    try {
      let value = [];
      msg.data.value.forEach((key) => {
        value.push({value: key.UtilityID});
      });
      for (let valueIndex in this.views) {
        if (this.views[valueIndex]["formGroup"]) {
          this.views[valueIndex]["formGroup"].patchValue({utilities: value});
        }
      }
    } catch (error) {
      this.logger$.error("patchUtilitiesSelected" + error.message);
    }
  }

  private patchCallTypesSelected(callTypeID) {
    try {
      let value = {value: callTypeID};

      for (let valueIndex in this.views) {
        if (this.views[valueIndex]["formGroup"]) {
          this.views[valueIndex]["formGroup"].patchValue({calltype: value});
        }
      }
    } catch (error) {
      this.logger$.error("patchCallTypesSelected" + error.message);
    }
  }

  /**
   * Updates the date and times on the tickets in any form
   *
   */
  private handleTicketDateUpdate(msg) {
    try {
      if (
        msg.data &&
        msg.data["Ticket"] &&
        msg.data["Ticket"]["TicketDetails"]
      ) {
        if (msg.data["Ticket"]["TicketDetails"]["OriginalExcavationDate"]) {
          //get available rigs for that date.
          let date =
            msg.data["Ticket"]["TicketDetails"]["OriginalExcavationDate"];
          // update event values with date
          this.ticket$
            .onDateSelected("EventStartDateTime", date)
            .then((val) => {
              this.ticket$.onDateSelected("EventEndDateTime", date);
            });
        } else if (msg.data["Ticket"]["TicketDetails"]["CallDate"]) {
          // *take specific action for CallDate on scheduler
        }
      }
    } catch (error) {
      this.logger$.error("handleTicketDateUpdate: " + error.message);
    }
  }

  /** When the document data changes, adds the change to
   *  ticket itself
   */
  listenForDocumentDataChanges() {
    this.documentChangesSubscriber = this.ticket$
      .getOnDocumentDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_DOCUMENT_DATA:
            this.documents = message.data;
            this.childProps = {...this.childProps, documents: this.documents};
            break;
        }
      });
  }

  testOnSync() {
    //onsync function without the actual upload
    this.upload$.generateCreateTicketSessionID();
    this.progressBarService.start();
    this.snackBarService.openSnackbar(
      "Creating ticket...",
      SnackbarType.default
    );
    this.ticket$.resetBodyDefault();
    this.ticket$.resetUtilities().then((val) => {
      if (val) {
        this.resetCreateComponent();
        this.snackBarService.openSnackbar(
          "Created Ticket: Clearing Information",
          SnackbarType.success
        );
      } else {
        this.snackBarService.openSnackbar(
          "Created Ticket, Clear Information Failed",
          SnackbarType.warning
        );
      }
      this.syncFinished.emit("DONE");
      this.progressBarService.stop();
    });
  }

  onCreateBtnWhileLoading(event: any) {
    this.onCreateBtnDisabled = event;
    this.childProps = {
      ...this.childProps,
      onCreateBtnDisabled: this.onCreateBtnDisabled,
    };
  }

  onSync() {
    // this.resetCreateComponent();
    // this.testOnSync();
    this.progressBarService.start();
    this.snackBarService.openSnackbar(
      "Creating ticket...",
      SnackbarType.default
    );

    this.uploadSyncSubscription = this.upload$.sync().subscribe((msg) => {
      if (msg.ok) {
        this.ticket$.resetBodyDefault();
        this.ticket$.resetUtilities().then((val) => {
          if (val && msg.msg.indexOf("Failed to attach") < 0) {
            this.resetCreateComponent();
            this.snackBarService.openSnackbar(
              "Created ticket",
              SnackbarType.success
            );
          } else if (val && msg.msg.indexOf("Failed to attach") > -1) {
            this.resetCreateComponent();
            this.snackBarService.openSnackbar(
              "Created ticket but failed to attach documents",
              SnackbarType.warning
            );
          } else {
            this.snackBarService.openSnackbar(
              "Created ticket, clear information failed",
              SnackbarType.warning
            );
          }
          this.syncFinished.emit(msg.ok);
          this.progressBarService.stop();
        });
      } else {
        this.logger$.error("Issue with creating");
        this.snackBarService.openSnackbar(
          "Error Occurred During Create Ticket Process",
          SnackbarType.error
        );
        this.progressBarService.stop();
      }
    });
  }

  resetCreateComponent() {
    try {
      if (this.views) {
        for (let viewKey in this.views) {
          if (this.views[viewKey]["formGroup"]) {
            this.views[viewKey]["formGroup"].reset();
          }
        }
      }
      if (this.commonViews) {
        for (let viewKey in this.commonViews) {
          if (this.commonViews[viewKey]["formGroup"]) {
            this.commonViews[viewKey]["formGroup"].reset();
          }
        }
      }
      this.documents = {};
      this.utilityViews = {};
      this.currentExcavatorID = -1;
      //clean worksites after syunc clears idb
      // //clean utilitys
      // this.childProps["views"] = this.views;
      // this.childProps["documents"] = this.documents;
      // this.childProps["utilityViews"] = this.utilityViews;
      this.childProps = {
        ...this.childProps,
        views: this.views,
        documents: this.documents,
        utilityViews: this.utilityViews,
      };
      // this.ticket$.resetBody();

      //navigate to companyinfo.
      let msg: ComponentMessage = {
        action: CreateTicketMessageActions.SWITCH_CONTENT_VIEW,
        senderID: this.className,
        message: {
          groupKey: "companyInfo",
          togglePanel: false,
        },
      };
      this.completions$.cleanCreateTicketRows();
      if (this.childProps["messageService"]) {
        this.childProps["messageService"].sendToMessageStream(msg);
        this.sendResetData();
      }
    } catch (error) {
      this.logger$.error("resetCreateComponent: " + error.message);
    }
  }
}

@Component({
  selector: "app-create-ticket-previous-session-dialog",
  template: `
    <h3 mat-dialog-title>Previous Session Found</h3>
    <div mat-dialog-content>
      <p>Restore previous session or create a new session?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onRestoreClick()">Restore Session</button>
      <button mat-button (click)="onNewClick()" cdkFocusInitial>
        New Session
      </button>
    </div>
  `,
})
export class CreateTicketPreviousSessionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateTicketPreviousSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNewClick(): void {
    this.dialogRef.close(false);
  }

  onRestoreClick() {
    this.dialogRef.close(true);
  }
}

@NgModule({
  declarations: [
    CreateTicketComponent,
    CreateTicketContentPanelComponent,
    CreateTicketQuickPanelComponent,
    CreateTicketQuickViewComponent,
    CreateTicketCompanyViewComponent,
    CreateTicketWorksiteViewComponent,
    CreateTicketDocsViewComponent,
    CreateTicketDetailsViewComponent,
    CreateTicketScheduleViewComponent,
    FormTemplateDialogComponent,
    ExcavatorContactsDialogComponent,
    CreateTicketPreviousSessionDialogComponent,
  ],
  exports: [
    CreateTicketComponent,
    CreateTicketContentPanelComponent,
    CreateTicketQuickPanelComponent,
    CreateTicketQuickViewComponent,
    CreateTicketCompanyViewComponent,
    CreateTicketWorksiteViewComponent,
    CreateTicketDocsViewComponent,
    CreateTicketDetailsViewComponent,
    CreateTicketScheduleViewComponent,
    FormTemplateDialogComponent,
    ExcavatorContactsDialogComponent,
  ],
  providers: [ComponentMessagingService],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    FormInputTemplateModule,
    GoogleMapsSetModule,
    SlideOverSidePanelModule,
    ExcavatorContactsCardModule,
    ConfirmationDialogModule,
    ScrollingModule,
    FloatingTabSelectComponent,
    FloatingActionMenuComponent,
    PillMenuButtonComponent,
    PillMenuComponent
  ]
})
export class CreateTicketComponentModule { }
