<div
  #testers
  *ngFor="let item of longestValue | keyvalue"
  [id]="item.key"
  class="tester mat-body-s"
>
  {{ item.value }}
</div>
<div #tablePage class="whole-table">
  <div class="inner" fxLayout="column" fxLayoutGap="8px">
    <!-- Search Bar -->
    <mat-form-field class="search" *ngIf="showSearch" floatLabel="never">
      <button matPrefix mat-icon-button aria-label="Search">
        <mat-icon class="text-lg h-4 ">search</mat-icon>
      </button>
      <div class="search-input">
        <input
          matInput
          placeholder="Search"
          #search
          autocomplete="off"
          [(ngModel)]="searchValue"
        />
      </div>
      <button
        *ngIf="searchValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchValue = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Header -->
    <div #header class="header">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="header-wrapper"
        matSort
        (matSortChange)="sortData($event)"
      >
        <div *ngIf="enableSelect" style="padding-right: 8px">
          <mat-checkbox
            class="checkbox"
            color="primary"
            (change)="masterSelection($event.checked)"
            [checked]="allChecked()"
            [indeterminate]="someChecked()"
          >
          </mat-checkbox>
        </div>
        <ng-container *ngFor="let column of columns">
          <div
            class="table-cell header-text"
            [style.width.px]="columnSizes[column.ColumnName]"
            [mat-sort-header]="column.ColumnName"
            style="text-transform: uppercase"
          >
            {{ column.VisibleName }}
          </div>
        </ng-container>
      </div>
    </div>

    <!-- rows -->
    <div class="table">
      <mat-divider></mat-divider>

      <mat-list class="table-wrapper overflow-auto">
        <cdk-virtual-scroll-viewport
          [itemSize]="20"
          class="vs-viewport"
          (scroll)="onWindowScroll($event)"
        >
          <mat-list-item class="px-0"
            *ngFor="
              let dataRow of data | tableFilter : searchValue
            "
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="row"
              [ngClass]="{ 'row-highlight': dataRow.Highlighted }"
              (click)="enableSelect ? toggleSelection(dataRow.ROW_ID) : ''"
              (dblclick)="onRowClick(dataRow)"
            >
              <div *ngIf="enableSelect" style="padding-right: 8px">
                <mat-checkbox
                  class="checkbox"
                  [checked]="selection.values[dataRow.ROW_ID]"
                >
                </mat-checkbox>
              </div>
              <ng-container *ngFor="let column of columns">
                <div
                  class="table-cell mat-body-s"
                  [style.width.px]="columnSizes[column.ColumnName]"
                >
                  {{ dataRow[column.ColumnName] }}
                </div>
              </ng-container>
            </div>
          </mat-list-item>
        </cdk-virtual-scroll-viewport>
      </mat-list>
    </div>
  </div>
</div>
