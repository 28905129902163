<!-- <div id="QuickDialog" class="e-control e-dialog e-lib e-quick-dialog e-dlg-modal e-popup e-popup-open" role="dialog" style="max-height: 530px; z-index: 1002; left: 0px; top: 0px; position: relative;" >
  <div class="e-dlg-header-content" id="QuickDialog_dialog-header">
    <button class="e-dlg-closeicon-btn e-control e-btn e-lib e-flat e-icon-btn" type="button" title="Close" aria-label="Close">
      <span class="e-btn-icon e-icon-dlg-close e-icons"></span>
    </button>
      <div class="e-dlg-header" id="QuickDialog_title">Edit Event</div>
    </div>
    <div class="e-dlg-content" id="QuickDialog_dialog-content">How would you like to change the appointment in the series?</div>
    <div class="e-footer-content">
      <button type="button" class="e-control e-btn e-lib e-quick-alertok e-flat e-primary e-quick-dialog-occurrence-event" aria-label="Edit Event">Edit Event</button>
      <button type="button" class="e-control e-btn e-lib e-quick-alertcancel e-flat e-quick-dialog-series-event" aria-label="Entire Series">Entire Series</button>
      <button type="button" class="e-control e-btn e-lib e-quick-dialog-cancel e-disable e-flat"></button>
    </div>
    </div> -->

<!-- <div fxLayout='column'>
  <div fxLayout='row'>
    <div>Edit Event</div>
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  
</div> -->

<div fxLayout="row" fxLayoutAlign="space-between">
  <h1 mat-dialog-title>Edit Event</h1>
  <button mat-icon-button [mat-dialog-close]="close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p>How would you like to edit the event?</p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="editDay"
    cdkFocusInitial
  >
    SINGLE DAY
  </button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="editSeries"
    cdkFocusInitial
  >
    ENTIRE EVENT
  </button>
</div>
