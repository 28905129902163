<label class="flex flex-row items-center justify-between cursor-pointer">
  @if (text && textPosition === 'left') {
    <span class="me-3 text-md text-black font-rajdhani select-none uppercase font-semibold">{{ text }}</span>
  }
  <input type="checkbox" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" class="sr-only peer" />
  <div
    class="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>

  @if (text && textPosition === 'right') {
    <span class="ms-3 text-md text-black font-rajdhani select-none uppercase font-semibold">{{ text }}</span>
  }
</label>
