import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { TemplateColumn } from '../table.module';
import { TableService } from '../table.service';
import { SearchableDropdownCoreComponent } from '../../searchable-dropdown/searchable-dropdown-core/searchable-dropdown-core.component';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { CompetersSearchBarComponent } from 'src/app/shared/components/inputs/competers-search-bar/competers-search-bar.component';


@Component({
  selector: 'app-table-column-select',
  templateUrl: './table-column-select.component.html',
  styleUrls: ['./table-column-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SearchableDropdownCoreComponent,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatIconModule,
    MatListModule,
    CompetersSearchBarComponent
  ],
})
export class TableColumnSelectComponent implements OnInit, OnDestroy {
  @Input() public tableService: TableService<any>;
  columns: TemplateColumn[] = [];
  columnSubscription: Subscription;
  choices: TemplateColumn[] = [];
  filteredChoices: TemplateColumn[] = [];
  selectedColumns: TemplateColumn[] = [];
  searchControl: FormControl = new FormControl('');
  isMenuOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.columnSubscription = this.tableService.getColumns().subscribe((res) => {
      this.columns = [...res];
      this.choices = [...res];
      this.sortChoices();
      this.filteredChoices = [...this.choices];
      this.selectedColumns = this.choices.filter((col) => col.Visible === 1);
    });

    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this.filterChoices(searchTerm);
    });
  }

  ngOnDestroy() {
    this.columnSubscription.unsubscribe();
    this.tableService.updateColumns([]);
  }

  /**
   * Sorts the choices alphabetically by their Title 
   *
   * @memberof TableColumnSelectComponent
   */
  sortChoices() {
    this.choices.sort((a, b) => a.Title.localeCompare(b.Title));
  }

  /**
   * Filters the choices based on the search term.
   *
   * @param {string} searchTerm
   * @memberof TableColumnSelectComponent
   */
  filterChoices(searchTerm: string) {
    if (!searchTerm) {
      this.filteredChoices = [...this.choices];
    } else {
      const lowerSearchTerm = searchTerm.toLowerCase();
      this.filteredChoices = [
        ...this.choices.filter(choice => choice.Title.toLowerCase().includes(lowerSearchTerm)),
        ...this.choices.filter(choice => !choice.Title.toLowerCase().includes(lowerSearchTerm))
      ];
    }
  }

  /**
   * When the columns selected to show changes
   *
   * @param {*} $event
   * @memberof TableColumnSelectComponent
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  columnSelectionChanged($event: any): void {
    const selectedColumnTitles = this.selectedColumns.map((col) => col.Title);

    this.columns = this.columns.map((column) => {
      column.Visible = selectedColumnTitles.includes(column.Title) ? 1 : 0;
      return column;
    });

    this.tableService.updateColumns(this.columns);
  }

  /**
   * Clears the current search by setting the value to the empty string
   *
   * @memberof TableColumnSelectComponent
   */
  clearSearch() {
    this.searchControl.setValue('');
    console.log(this.isMenuOpen)
  }

  open() {
    console.log(this.isMenuOpen);
    this.isMenuOpen = true;
  }
}
