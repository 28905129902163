<h1 mat-dialog-title class="mat-headline-1 blue">Contacts on Ticket</h1>

<div mat-dialog-content *ngIf="doesHaveData; else doesNotHaveData">
  <div style="height: 100%; width: 100%; overflow-x: hidden" fxLayout="column">
    <div style="background-color: white" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
          <span>{{ callerName }} </span>
          <span>{{ phoneNumber }}</span>
        </div>
        <div>
          <a href="sms:{{ phoneNumber }}">
            <i
              style="font-size: 35px; padding-right: 6px"
              class="material-icons"
              >textsms</i
            ></a
          >
          <a href="tel:{{ phoneNumber }}">
            <i style="font-size: 35px" class="material-icons">phone</i></a
          >
        </div>
      </div>
      <div *ngIf="altNumber" style="padding-top: 12px">
        <span style="font-weight: 600">Alternate Contact</span>
        <div
          style="padding-top: 6px"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div fxLayout="column">
            <span>{{ altName }} </span>
            <span>{{ altNumber }}</span>
          </div>
          <div>
            <a href="sms:{{ altNumber }}">
              <i
                style="font-size: 35px; padding-right: 6px"
                class="material-icons"
                >textsms</i
              ></a
            >
            <a href="tel:{{ altNumber }}">
              <i style="font-size: 35px" class="material-icons">phone</i></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #doesNotHaveData>
  <div mat-dialog-content>
    <div
      style="height: 100%; width: 100%; overflow-x: hidden"
      fxLayout="column"
    >
      <div style="background-color: white" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>No Contact Info Found</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="onConfirm($event)" color="primary">
    Close
  </button>
</div>
