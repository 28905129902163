<div fxLayout="column" class="legend-panel">
  <div
    fxFlex="56px"
    class="panel-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span class="panel-header-text mat-subtitle-2">Legend</span>
    <button mat-icon-button (click)="panelClose.emit(true)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxFlex #legendPanel></div>
</div>
