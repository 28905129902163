// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HeaderOptions, RouteOptions } from "../page-nav-options";

@Component({
  selector: "app-page-navigation",
  templateUrl: "./page-navigation.component.html",
  styleUrls: ["./page-navigation.component.scss"],
})
export class PageNavigationComponent implements OnInit, OnChanges {
  @Input() header: HeaderOptions = {
    title: "Title",
  };
  @Input() routes: RouteOptions[];
  expandOptionsIndex: number | null = null;


  appVersion: string = environment.appVersion;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  /**
   * route to the first child and subscribe to changes
   */
  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.url),
      )
      .subscribe((url) => {
        // console.log(url[0]['path']);
        if (url && url[0] && url[0]["path"]) {
          for (const route of this.routes) {
            if (route.path == url[0]["path"]) {
              route["selected"] = true;
            } else {
              route["selected"] = false;
            }
          }
        }
      });
  }

  /**
   * extract child path and set the selected child
   * @param changes changes that triggered onChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    const pathParts = this.router.url.split("/");
    const childPath = pathParts[pathParts.length - 1];
    this.setSelected(childPath);
  }

  /**
   * navigate to a new path
   * @param path path to navigate to
   */
  changeRoute(path: string) {
    this.setSelected(path);
    this.router.navigate([path], { relativeTo: this.route });
  }

  /**
   * Toggles expansion for a route, if applicable 
   * @param index 
   */
  toggleExpandOptions(route: RouteOptions): void {
    this.expandedRoute = (this.expandedRoute === route) ? null : route;
  }


  isExpanded(route: RouteOptions): boolean {
    return this.expandedRoute === route;
  }

  /**
   * update the selected child route
   * @param path path of newly selected child
   */
  private setSelected(path: string) {
    for (const r of this.routes) {
      if (r.expandOptions) {
        for (const e of r.expandOptions) {
          if (e.path == path) {
            e.selected = true;
          } else {
            e.selected = false;
          }
        }
      } else {
        if (r.path == path) {
          r.selected = true;
        } else {
          r.selected = false;
        }
      }
    }
  }
}
