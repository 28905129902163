import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-drawing-category-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DrawingCategorySelectComponent,
    },
  ],
  template: `
    <div class="flex flex-row justify-start items-center gap-8">
      <div class="flex flex-row justify-start items-center gap-3.5">
        <label class="cursor-pointer text-white font-rajdhani font-semibold uppercase text-md" for="primary">
          primary
        </label>
        <input
          class="hidden"
          name="drawingClass"
          id="primary"
          [value]="'primary'"
          type="radio"
          [formControl]="drawingClass" />
        <button
          (click)="this.drawingClass.setValue('primary')"
          class="cursor-pointer box-border flex justify-center items-center size-5 rounded-full border-solid border-2 border-white {{
            drawingClass.value === 'primary' ? 'bg-white' : 'bg-transparent'
          }} p-0">
          @if (drawingClass.value === 'primary') {
            <svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="#000000" />
            </svg>
          }
        </button>
      </div>
      <div class="flex flex-row justify-start items-center gap-3.5">
        <label class="cursor-pointer text-white font-rajdhani font-semibold uppercase text-md" for="auxiliary">
          auxiliary
        </label>
        <input
          class="hidden"
          name="drawingClass"
          id="auxiliary"
          [value]="'auxiliary'"
          type="radio"
          [formControl]="drawingClass" />
        <button
          (click)="this.drawingClass.setValue('auxiliary')"
          class="cursor-pointer box-border flex justify-center items-center size-5 rounded-full border-solid border-2 border-white {{
            drawingClass.value === 'auxiliary' ? 'bg-white' : 'bg-transparent'
          }} p-0">
          @if (drawingClass.value === 'auxiliary') {
            <svg width="20px" height="20px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="#000000" />
            </svg>
          }
        </button>
      </div>
    </div>
  `,
})
export class DrawingCategorySelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // IO
  value: 'auxiliary' | 'primary' = 'auxiliary';

  protected drawingClass: FormControl = new FormControl({
    value: this.value,
    disabled: false,
  });

  private destroy$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (arg0: typeof this.value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  constructor() {}

  ngOnInit() {
    this.drawingClass.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
      this.value = val;
      this.onChange(val);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: () => never): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => never): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: 'primary' | 'auxiliary'): void {
    this.drawingClass.setValue(obj);
  }
}
