import { Injectable } from "@angular/core";
import { loadModules } from "esri-loader";
import {
  Graphic,
  GraphicInfo,
} from "src/app/modules/core/cache/GraphicsInterface";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { MarkerObject } from "src/app/modules/shared/google-map/google-maps-set/googleMapsOptions";
import { GraphicStatus, GraphicType } from "../../EsriMapModels";

@Injectable({
  providedIn: "root",
})
export class EsriMapDrawingService {
  private sketch = null;
  private action = "standby";
  private tool = null;
  private updateOptions = null;
  private lastToolEvent = null;

  private spatialReference;

  private originalGraphics: Graphic[] = []; // for the reset
  private graphicsObjs: Graphic[] = [];

  private graphicLayer = null;
  private markerLayer = null;

  constructor(private logger$: LoggerService) {}

  // ------ Functions for adding the graphics ------

  async addMarkers(markers: MarkerObject[], mapview, map) {
    if (!this.spatialReference) {
      this.spatialReference = mapview.spatialReference;
    }

    try {
      if (markers && markers.length > 0) {
        if (this.markerLayer != null) {
          this.markerLayer.removeAll();
        }
        const [GraphicsLayer] = await loadModules([
          "esri/layers/GraphicsLayer",
        ]);

        this.markerLayer = new GraphicsLayer();
        map.add(this.markerLayer);
        for (let i in markers) {
          let m = markers[i];
          let markerGraphic = await this.createMarker([
            m.position.lng,
            m.position.lat,
          ]);
          this.markerLayer.add(markerGraphic);
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  async createGraphics(graphicsToAdd: GraphicInfo[], mapview, map) {
    this.graphicsObjs = [];
    this.originalGraphics = [];
    this.spatialReference = mapview.spatialReference;

    const [GraphicsLayer] = await loadModules(["esri/layers/GraphicsLayer"]);
    this.graphicLayer = new GraphicsLayer();
    map.add(this.graphicLayer);

    if (graphicsToAdd) {
      for (let i = 0; i < graphicsToAdd.length; i++) {
        try {
          let coords = graphicsToAdd[i].coordinates;

          if (coords != null && coords.length > 0) {
            var graphic = null;
            let digsiteType = graphicsToAdd[i].type;

            if (digsiteType == GraphicType.POINT) {
              graphic = await this.createPoint([
                coords[0]["lng"],
                coords[0]["lat"],
              ]);
            } else if (digsiteType == GraphicType.LINE) {
              let path = [];
              for (let j = 0; j < coords.length; j++) {
                let coord = coords[j];
                path.push([coord["lng"], coord["lat"]]);
              }

              graphic = await this.createLine(path);
            } else if (digsiteType == GraphicType.POLYGON) {
              let path = [];
              for (let j = 0; j < coords.length; j++) {
                let coord = coords[j];
                path.push([coord["lng"], coord["lat"]]);
              }

              graphic = await this.createPolygon(path);
            }

            if (graphic != null) {
              let newGraphic: Graphic = {
                graphicID: graphicsToAdd[i].graphicID,
                graphic: graphic,
                statusID: GraphicStatus.CREATED,
              };

              let copyGraphic: Graphic = {
                graphicID: graphicsToAdd[i].graphicID,
                graphic: graphic.clone(),
                statusID: GraphicStatus.CREATED,
              };

              this.graphicsObjs.push(newGraphic);
              this.originalGraphics.push(copyGraphic);
              this.graphicLayer.add(graphic);
            }
          }
        } catch (error) {
          this.logger$.error(error);
        }
      }
    }
  }

  private async createMarker(coords) {
    let markerGraphic = null;
    try {
      if (coords) {
        const [Graphic, Point, PictureMarkerSymbol] = await loadModules([
          "esri/Graphic",
          "esri/geometry/Point",
          "esri/symbols/PictureMarkerSymbol",
        ]);

        let point = new Point({
          type: "point",
          longitude: coords[0],
          latitude: coords[1],
        });

        let markerSymbol = new PictureMarkerSymbol({
          url: "/assets/custom-icons/pin.svg",
          width: "48px",
          height: "48px",
        });

        var geometry = await this.convertGeometry(point);

        markerGraphic = new Graphic({
          geometry: geometry,
          symbol: markerSymbol,
        });
      }
    } catch (error) {
      this.logger$.log(error);
    }
    return markerGraphic;
  }

  // coords: [lng, lat]
  private async createPoint(coords) {
    let pointGraphic = null;
    try {
      if (coords) {
        const [Graphic, Point, SpatialReference] = await loadModules([
          "esri/Graphic",
          "esri/geometry/Point",
          "esri/geometry/SpatialReference",
        ]);

        let point = new Point({
          type: "point",
          longitude: coords[0],
          latitude: coords[1],
        });

        let markerSymbol = {
          type: "simple-marker",
          color: [120, 120, 120, 0.2],
          outline: {
            color: [120, 120, 120, 0.8],
            width: 2,
          },
        };

        var geometry = await this.convertGeometry(point);

        pointGraphic = new Graphic({
          geometry: geometry,
          symbol: markerSymbol,
        });
      }
    } catch (error) {
      this.logger$.log(error);
    }
    return pointGraphic;
  }

  // coords: [ [lng, lat], [lng, lat] ]
  private async createLine(coords) {
    let lineGraphic = null;
    try {
      if (coords) {
        const [Graphic, Polyline, SpatialReference] = await loadModules([
          "esri/Graphic",
          "esri/geometry/Polyline",
          "esri/geometry/SpatialReference",
        ]);

        let polylineSymbol = {
          type: "simple-line",
          color: [120, 120, 120, 0.8],
          width: 2,
        };

        let polyline = new Polyline({
          type: "polyline",
          paths: coords,
          spatialReference: new SpatialReference(4326),
        });

        var geometry = await this.convertGeometry(polyline);

        lineGraphic = new Graphic({
          geometry: geometry,
          symbol: polylineSymbol,
        });
      }
    } catch (error) {
      this.logger$.log(error);
    }
    return lineGraphic;
  }

  // coords: [ [lng, lat] , [lng, lat] ]
  private async createPolygon(coords) {
    let polygonGraphic = null;
    try {
      if (coords) {
        const [Graphic, SpatialReference, Polygon] = await loadModules([
          "esri/Graphic",
          "esri/geometry/SpatialReference",
          "esri/geometry/Polygon",
        ]);

        let polygon = new Polygon({
          type: "polygon",
          rings: coords,
          spatialReference: new SpatialReference(4326),
        });

        // convert the geometry to the same projection as the map
        var geometry = await this.convertGeometry(polygon);

        var simpleFillSymbol = {
          type: "simple-fill",
          color: [120, 120, 120, 0.2], // orange, opacity 80%
          outline: {
            color: [120, 120, 120, 0.8],
            width: 2,
          },
        };

        polygonGraphic = new Graphic({
          geometry: geometry,
          symbol: simpleFillSymbol,
        });
      }
    } catch (error) {
      this.logger$.log(error);
    }
    return polygonGraphic;
  }

  private async convertGeometry(geometry) {
    var newGeometry = null;
    try {
      const [webMercatorUtils] = await loadModules([
        "esri/geometry/support/webMercatorUtils",
      ]);

      let canProject = webMercatorUtils.canProject(
        { wkid: 4326 },
        this.spatialReference
      );
      if (canProject) {
        newGeometry = webMercatorUtils.project(geometry, this.spatialReference);
      }
    } catch (error) {
      this.logger$.error("convertGeometry: ", error);
    }
    return newGeometry;
  }

  // ------ Functions for getting the graphics ------

  async getGraphics(): Promise<GraphicInfo[]> {
    var graphics: GraphicInfo[] = [];
    try {
      for (let i = 0; i < this.graphicsObjs.length; i++) {
        const graphicObj: Graphic = this.graphicsObjs[i];
        let statusID = graphicObj.statusID;
        var coordinates = [];
        var type = -1;

        // convert the x/y coordinates to lat/long
        let geometry = await this.geometryToLatLng(graphicObj.graphic.geometry);

        // set the coordinates and type
        if (geometry.type == "polygon") {
          type = GraphicType.POLYGON;
          coordinates = this.geometryToCoordinates(geometry.rings[0]);
        } else if (geometry.type == "polyline") {
          type = GraphicType.LINE;
          coordinates = this.geometryToCoordinates(geometry.paths[0]);
        } else if (geometry.type == "rectangle") {
          type = GraphicType.POLYGON;
          coordinates = this.geometryToCoordinates(geometry.rings[0]);
        } else if (geometry.type == "point") {
          type = GraphicType.POINT;
          coordinates = this.geometryToCoordinates([[geometry.x, geometry.y]]);
        } else if (geometry.type == "circle") {
          type = GraphicType.POLYGON;
          coordinates = this.geometryToCoordinates(geometry.rings[0]);
        }

        // set up the GraphicInfo Object if the type is valid
        if (type != -1 && coordinates.length > 0) {
          var graphic: GraphicInfo = {
            status: statusID,
            coordinates: coordinates,
            type: type,
          };

          // add the ID if it exists
          if (graphicObj.graphicID) {
            graphic.graphicID = graphicObj.graphicID;
          }

          // Add the graphic to the list
          graphics.push(graphic);
        } else {
          this.logger$.error(
            "Unable to save graphic because of invaild graphic type: ",
            type,
            " or no coordinates: ",
            coordinates
          );
        }
      }
    } catch (error) {
      this.logger$.error("Error: getGraphics: ", error);
    }
    return graphics;
  }

  private geometryToCoordinates(geometry: number[][]) {
    var coordinates = [];
    try {
      for (let i = 0; i < geometry.length; i++) {
        const point = geometry[i];
        if (point.length == 2)
          coordinates.push({
            lat: point[1],
            lng: point[0],
          });
      }
    } catch (error) {
      this.logger$.error("Error: geometryToCoordinates: ", error);
    }
    return coordinates;
  }

  private async geometryToLatLng(geometry) {
    var newGeometry = null;
    try {
      const [webMercatorUtils] = await loadModules([
        "esri/geometry/support/webMercatorUtils",
      ]);

      let canPoject = webMercatorUtils.canProject(geometry.spatialReference, {
        wkid: 4326,
      });
      if (canPoject) {
        newGeometry = webMercatorUtils.project(geometry, { wkid: 4326 });
      }
    } catch (error) {
      this.logger$.error("geometryToLatLng: ", error);
    }
    return newGeometry;
  }

  // ------ Functions for Initializing Drawing ------

  async initDrawing(mapView) {
    // load the modules
    if (this.sketch == null) {
      const [SketchViewModel] = await loadModules([
        "esri/widgets/Sketch/SketchViewModel",
      ]);

      if (this.graphicLayer == null) {
        const [GraphicsLayer] = await loadModules([
          "esri/layers/GraphicsLayer",
        ]);
        this.graphicLayer = new GraphicsLayer();
        mapView.map.add(this.graphicLayer);
      }

      this.sketch = new SketchViewModel({
        layer: this.graphicLayer,
        view: mapView,
        updateOnGraphicClick: false,
      });

      this.sketch.on("update", (event) => {
        this.updateLastToolEvent(event.toolEventInfo);
        if (event.state === "complete") {
          this.logger$.log("update", event);
          for (let i = 0; i < event.graphics.length; i++) {
            const graphic = event.graphics[i];
            this.updateGraphicObj(graphic, 2);
          }
        }
      });

      this.sketch.on("create", (event) => {
        this.updateLastToolEvent(event.toolEventInfo);
        if (event.state === "complete") {
          this.logger$.log("create", event);
          this.graphicsObjs.push({
            graphic: event.graphic,
            statusID: 1,
          });
          this.sketch.create(this.tool);
        }
      });

      this.sketch.on("delete", (event) => {
        this.logger$.log("delete: ", event);
        this.updateLastToolEvent(event.toolEventInfo);
        for (let i = 0; i < event.graphics.length; i++) {
          const graphic = event.graphics[i];
          this.updateGraphicObj(graphic, 3);
        }
      });

      this.logger$.log("Drawing Initialized");
    }
  }

  private updateGraphicObj(graphic, statusID) {
    for (let i = 0; i < this.graphicsObjs.length; i++) {
      const graphicObj = this.graphicsObjs[i];
      if (graphicObj.graphic.uid == graphic.uid) {
        graphicObj.statusID = statusID;
      }
    }
  }

  private updateLastToolEvent(toolEventInfo) {
    if (toolEventInfo != null) {
      this.lastToolEvent = toolEventInfo.type;
    } else {
      this.lastToolEvent = null;
    }
  }

  // ------ Functions for Handling Drawing Actions ------

  stopAction() {
    this.action = "standby";
    this.tool = null;
    this.sketch.cancel();
  }

  startDrawing(shape) {
    if (this.action != "create" || this.tool != shape) {
      this.action = "create";
      this.tool = shape;
      this.sketch.create(shape);
    } else {
      this.stopAction();
    }
  }

  startModifying(type) {
    if (this.action != "update" || this.tool != type) {
      this.stopAction();
      this.action = "update";
      this.tool = type;
      if (type == "transform") {
        this.updateOptions = {
          tool: "transform",
          enableRotation: true,
          enableScaling: true,
          toggleToolOnClick: false,
        };
      } else if (type == "reshape") {
        this.updateOptions = {
          tool: "reshape",
          toggleToolOnClick: false,
        };
      }
    } else {
      this.stopAction();
    }
  }

  update(hitGraphics) {
    if (
      this.action == "update" &&
      this.lastToolEvent != "vertex-add" &&
      this.lastToolEvent != "selection-change"
    ) {
      this.sketch.update(hitGraphics, this.updateOptions);
    }
  }

  undo() {
    this.sketch.undo();
  }

  redo() {
    this.sketch.redo();
  }

  delete() {
    this.sketch.delete();
  }

  reset = () => {
    this.graphicsObjs = this.originalGraphics;
    this.originalGraphics = [];

    this.graphicLayer.removeAll();

    this.graphicsObjs.forEach((graphicObj) => {
      this.graphicLayer.add(graphicObj.graphic);

      let copyGraphic: Graphic = {
        graphicID: graphicObj.graphicID,
        graphic: graphicObj.graphic.clone(),
        statusID: graphicObj.statusID,
      };

      this.originalGraphics.push(copyGraphic);
    });
  };
}
