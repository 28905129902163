<div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="16px" style="width: 100%">
    <span class="mat-headline-1 blue">Edit Row</span>

    <form #myForm="ngForm" (ngSubmit)="onSaveClick()">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between stretch"
        style="height: 100%"
        fxLayoutGap="16px grid"
      >
        <ng-container *ngFor="let column of columns | keyvalue">
          <mat-form-field
            *ngIf="column.value.isVisible == 1 && column.value.DataTypeID == 2"
            fxFlexOrder="{{ column.value.ColumnOrder }}"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <mat-label>{{ column.value.VisibleName }} </mat-label>
            <input
              #formInput
              matInput
              type="text"
              [id]="column.key"
              [name]="column.key"
              [required]="column.value.required"
              [(ngModel)]="dataCopy[column.key]"
            />
          </mat-form-field>

          <mat-form-field
            *ngIf="column.value.isVisible == 1 && column.value.DataTypeID == 3"
            fxFlexOrder="{{ column.value.ColumnOrder }}"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <mat-label>{{ column.value.VisibleName }} </mat-label>
            <mat-select
              #formInput
              [id]="column.key"
              [name]="column.key"
              [required]="column.value.required"
              [(ngModel)]="dataCopy[column.key]"
            >
              <mat-option
                *ngFor="let option of column.value.JoinInstance.Data"
                [value]="option[column.value.JoinInstance.PrimaryIDColumn]"
              >
                {{ option[column.value.JoinInstance.DescColumn] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="column.value.isVisible == 1 && column.value.DataTypeID == 6"
            fxFlexOrder="{{ column.value.ColumnOrder }}"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <mat-label>{{ column.value.VisibleName }}</mat-label>
            <input
              #formInput
              matInput
              type="number"
              [id]="column.key"
              [name]="column.key"
              [required]="column.value.required"
              [(ngModel)]="dataCopy[column.key]"
            />
          </mat-form-field>

          <div
            *ngIf="column.value.isVisible == 1 && column.value.DataTypeID == 8"
            fxFlexOrder="{{ column.value.ColumnOrder }}"
            fxFlex="50"
            fxFlex.xs="100"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="16px"
              style="height: 100%"
            >
              <span
                style="line-height: 30px; padding-left: 4px; padding-right: 2px"
                >{{ column.value.VisibleName }}</span
              >
              <ngx-colors
                ngx-colors-trigger
                [id]="column.key"
                [name]="column.key"
                [hideColorPicker]="false"
                [hideTextInput]="true"
                [(ngModel)]="dataCopy[column.key]"
              >
              </ngx-colors>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>

  <!-- Bottom Buttons -->
  <div
    class="btn-section"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="16px"
  >
    <button class="btn-cancel" (click)="onCancelClick()">
      Cancel
    </button>
    <button
      class="btn-save"
      color="primary"
      (click)="onSaveClick()"
    >
      Save
    </button>
  </div>
</div>
