<div>
  <div class="top-section">
    <h3 class="date-text">{{currentDate}}</h3>
    <h3 class="day-text">{{currentDay}}</h3>
  <div class="btn-group" name="fontStyle" aria-label="Font Style">
    <button (click)="arrowClicked('back')"  class="arrows" mat-icon-button>
      <mat-icon class="arrow-img">arrow_back_ios</mat-icon>
    </button>
   <div class="day-btns" *ngFor="let day of days">
    <button mat-button (click)="dayClicked(day.fullDate)">
      <div class="day-btn">
      <span>{{day.day}}</span>
      <span [ngClass]="{'selected': day.dayNum === selectedDay}">{{day.dayNum}}</span>
    </div>
    </button>
  </div>
  <button (click)="arrowClicked('forward')" class="arrows" mat-icon-button>
    <mat-icon class="arrow-img">arrow_forward_ios</mat-icon>
  </button>
  </div>
</div>
<div class="all-tasks">
<div class="task-list" *ngFor="let entries of tbTimesheetEntries">
  <app-punch-clock-timesheet-item
    [StartTime]="entries.StartTime"
    [StopTime]="entries.StopTime"
    [Task]="entries.TimesheetTaskID"
    [EntryID]="entries.TimesheetEntryID"
    [TaskArray]="taskArray"
  >
</app-punch-clock-timesheet-item>
</div>
</div>
</div>