<h2 mat-dialog-title>Cancel Ticket</h2>
<div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="16px">
  <div mat-dialog-content fxLayout="column">
    <p>{{ message }}</p>

    <p>If yes, please explain why.</p>
    <mat-form-field fxFlex>
      <input matInput [(ngModel)]="explanation" />
    </mat-form-field>
    <p class="validation-text" [hidden]="!explanationError">
      *Explanation Required
    </p>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    style="width: 100%"
  >
    <button mat-button [mat-dialog-close]="noCancel">NO</button>
    <button mat-button (click)="clickCancel()" cdkFocusInitial>YES</button>
  </div>
</div>
