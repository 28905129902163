<div class="side-menu" fxFlex="240px" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="side-menu-header" tabindex="0">
    <span class="mat-subheading-s">{{ header.title }}</span>
    <button *ngIf="header.btnIcon" mat-icon-button (click)="header.btnAction()">
      <mat-icon>{{ header.btnIcon }}</mat-icon>
    </button>
  </div>

  <div fxLayout="column" fxFlex class="side-menu-item-list">
    <ng-container *ngFor="let r of routes">
      <div
        class="side-menu-item font-rajdhani font-semibold uppercase"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        [ngClass]="{ 'side-menu-item-selected': r.selected || isExpanded(r) }"
        (click)="r.expandOptions ? toggleExpandOptions(r) : changeRoute(r.path)"
        [attr.tabindex]="r.expandOptions ? 0 : null"
        [attr.aria-expanded]="isExpanded(r) ? true : null">
        {{ r.label }}
      </div>
      <div *ngIf="isExpanded(r)" class="sub-options">
        <ng-container *ngFor="let subRoute of r.expandOptions">
          <div
            class="w-full pl-8 h-9 pr-4 text-sm hover:cursor-pointer font-rajdhani font-semibold uppercase"
            [ngClass]="{ 'side-menu-sub-item-selected': subRoute.selected }"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            (click)="changeRoute(subRoute.path)"
            tabindex="0">
            {{ subRoute.label }}

          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
