export enum Actions {
  RESET_DATA = -1,
  UPDATE_EXCAVATOR_DATA = 0,
  UPDATE_TICKET_DATA = 1,
  UPDATE_PRIMARY_DETAILS = 2,
  UPDATE_DIGSITE_DETAILS = 3,
  UPDATE_TICKET_ADDRESS_DATA = 4,
  UPDATE_DOCUMENT_DATA = 5,
  CLEAR_POLYGON_DATA = 6,
  UPDATE_METADATA = 7,
  UPDATE_DOCUMENT_STATUS_DATA = 8,

  UPDATE_PRIMARY_DETAILS_PREFILL = 9,
  UPDATE_UTILITY_DATA = 10,
  UPDATE_TICKET_EXCAVATION_DATE = 11,
  UPDATE_SCHEDULE_DATA = 12,
  UPDATE_EXCAVATOR_CONTACTS = 13,
  UPDATE_TICKET_CALLTYPE = 14,
  UPDATE_TICKET_CALLDATE = 15,
}

export class ActionMessage {
  action: Actions;
  data: any;

  constructor(action: Actions, data) {
    this.action = action;
    this.data = data;
  }
}
