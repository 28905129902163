import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { RateNode, Value } from "../rate-node";

@Component({
  selector: "app-rate-node",
  templateUrl: "./rate-node.component.html",
  styleUrls: ["./rate-node.component.scss"],
})
export class RateNodeComponent {
  @Input() nodeID: number;
  @Input() parentNodeID: number;
  @Input() icon: string;
  @Input() readableValues: string[];
  @Input() criteriaName: string;
  @Input() rate: number;
  @Input() RateName: string;
  @Input() criteriaTypeID: number;
  @Input() childCriteriaTypeID: number;
  @Input() values: Value[];
  @Input() children: RateNode[];
  @Input() nodeTrace: any[];

  @Output() selected = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() copyEmitter = new EventEmitter<any>();
  @Output() pasteEmitter = new EventEmitter<any>();

  constructor() {}

  /**
   * returns classes for nodes
   * @param parentNodeID
   * @returns array of classes
   */
  getNodeClass(): string[] {
    let classes: string[] = [];
    try {
      if (this.parentNodeID == 0) {
        //root
        classes = ["mat-title"];
      } else if (this.rate > 0) {
        //leaf
        classes = ["mat-body-1"];
      } else {
        classes = ["mat-subheading-2"]; //branch
      }
    } catch (error) {
      console.error(error.message);
    }
    return classes;
  }
}
