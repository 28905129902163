/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { EmailerService } from "src/app/modules/core/services/emailer/emailer.service";

@Component({
  selector: "app-email-modal",
  templateUrl: "./email-modal.component.html",
  styleUrls: ["./email-modal.component.scss"],
})
export class EmailModalComponent {
  email = new FormControl("", [Validators.email]);
  disabled: boolean = true;
  users: any;
  error: any;
  config: any;
  btnDisabled: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EmailModalComponent>,
    private emailService: EmailerService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange() {
    if (this.getErrorMessage() == "valid") {
      this.btnDisabled = false;
    } else {
      this.btnDisabled = true;
    }
  }

  radioClicked() {
    this.btnDisabled = false;
    this.email.reset();
  }

  radioClicked2() {
    this.btnDisabled = true;
  }

  getErrorMessage() {
    return this.email.hasError("email") ? "Not a valid email" : "valid";
  }
}
