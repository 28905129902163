import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UtilocateApiService } from "src/app/modules/core/api/utilocateapi.service";
import { UtilocateTokenPaths } from "src/app/modules/core/services/token/token.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { environment } from "src/environments/environment";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { DocumentTypeID } from "src/app/modules/core/services/document/document.service";

@Injectable({
  providedIn: "root",
})
export class NodeDocumentService {
  IS_LIVE: boolean = false;
  IS_DEV: boolean = true;
  isLocalHost: boolean;

  constructor(
    private userService: UserService,
    private baseApiService: UtilocateApiService
  ) {
    this.IS_LIVE = environment.production == true;
    this.IS_DEV = environment.production == false && environment.url == null;
    this.isLocalHost = environment.localhost;
  }

  async getDocumentFromS3(key: string, bucket?: string): Promise<object | Error> {
    let url = "/api/get/image";
    if (!this.isLocalHost) {
      url = "/nodejs/api/get/image";
    }

    //default bucket if the bucket is null
    bucket = bucket ?? this.userService.getClientBucketFromSettings();
    
    //if using retrieveDocumentS3 (trying to get a tbCompletions_Documents document)
    if (key.startsWith('public')) { 
      bucket = "utilocatecompanion-userfiles-mobilehub-1835283454"; 
    }

    const s3ImageFile: HttpResponse<any> = await this.baseApiService.invokeApi(
      "PUT",
      url,
      { params: { key, bucket } }
    );
    if (s3ImageFile.ok && s3ImageFile.body["image"]) {
      return {
        image: s3ImageFile.body["image"]["data"],
        filename: s3ImageFile.body["filename"],
      };
    } else {
      console.error(s3ImageFile.body['error'] ? s3ImageFile.body['error'] : null);
      return new Error("Failed to run GET");
    }
  }

  /**
   * Uploads the document to S3. Uploads to mobilhub bucket where 
   * the UploadDocumentS3 lambda processes it and places it into the appropriate bucket 
   * @param data 
   * @param zipname 
   * @param metadata 
   * @returns 
   */
  async uploadDocToS3(data, zipname, metadata) {
    // then we upload to s3. otherwise, tbCompletions_Documents
    let url = "/api/locator/upload/image"; // localhost
    if (!this.isLocalHost) {
      url = "/nodejs/api/locator/upload/image";
    }

    //upload to s3 or not
    if (metadata.DocumentTypeID == DocumentTypeID.OldDocument || metadata.DocumentTypeID == DocumentTypeID["Auxiliary Image"]) { 
      url = url + "/S3";
    }

    let ClientID = await this.userService.getUserValueFromToken(
      UtilocateTokenPaths.CLIENTID
    );

    const fileData: FormData = new FormData();
    fileData.append("fileToUpload", data);
    fileData.append("zipname", zipname);
    fileData.append("metadata", JSON.stringify(metadata));
    fileData.append("isLive", this.IS_LIVE.toString());
    fileData.append("ClientID", ClientID);

    fileData.append("AssignmentID", metadata["AssignmentID"]);
    fileData.append("Description", metadata["Description"]);
    fileData.append("DocumentTypeID", metadata["DocumentTypeID"]);
    fileData.append("isSendable", metadata["isSendable"]);

    let result = await this.baseApiService.invokeApi("PUT", url, fileData);
    return result;
  }

  async uploadAuxImageToS3(data, zipname, metadata) {
    let url = "/api/upload/auxiliary"; // localhost
    let bucket = this.userService.getClientBucketFromSettings();

    let ClientID = await this.userService.getUserValueFromToken(
      UtilocateTokenPaths.CLIENTID
    );
    if (!this.isLocalHost) {
      url = "/nodejs/api/upload/auxiliary";
    }

    const fileData: FormData = new FormData();
    fileData.append("fileToUpload", data);
    fileData.append("zipname", zipname);
    fileData.append("metadata", JSON.stringify(metadata));
    fileData.append("isLive", this.IS_LIVE.toString());
    fileData.append("ClientID", ClientID);

    fileData.append("AssignmentID", metadata["AssignmentID"]);
    fileData.append("Description", metadata["Description"]);
    fileData.append("DocumentTypeID", metadata["DocumentTypeID"]);
    fileData.append("isSendable", metadata["isSendable"]);
    fileData.append("DocumentLocationID", "1");
    //add client specific bucket. Defaults to utilocate.client.docs if setting is off
    fileData.append("bucket", bucket); 

    let result = await this.baseApiService.invokeApi("PUT", url, fileData);
    return result;
  }
}
