import { Injectable } from '@angular/core';
import { TemplateColumn } from './table.module';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { isEqual } from 'lodash-es';
import { tap } from 'rxjs/operators';

@Injectable()
export class TableService<T> {
  private _columns$: BehaviorSubject<TemplateColumn[]> = new BehaviorSubject([]);

  private _columnUpdates$: BehaviorSubject<TemplateColumn[]> = new BehaviorSubject([]);

  private _rowFilterString$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _selectedRows$: BehaviorSubject<T[]> = new BehaviorSubject([]);

  constructor() {}

  get selectedRows$(): Observable<T[]> {
    return this._selectedRows$.asObservable();
  }

  get selectedRows(): T[] {
    return this._selectedRows$.getValue();
  }

  set selectedRows(rows: T[]) {
    this._selectedRows$.next(rows);
  }

  setColumns(cols: TemplateColumn[]): void {
    this._columns$.next(cols);
  }
  getColumns(): Observable<TemplateColumn[]> {
    return this._columns$.pipe(distinctUntilChanged((a, b) => isEqual(a, b)));
  }
  updateColumns(cols: TemplateColumn[]): void {
    this._columnUpdates$.next(cols);
  }
  getColumnUpdates(): Observable<TemplateColumn[]> {
    return this._columnUpdates$.pipe(distinctUntilChanged());
  }
  
  getRowFilterString(): Observable<string> {
    return this._rowFilterString$.pipe(distinctUntilChanged());
  }
  updateRowFilterString(str: string): void {
    this._rowFilterString$.next(str);
  }
}
