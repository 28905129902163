import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "tableFilter" })
export class TableFilterPipe implements PipeTransform {
  transform(rows: any[], filterText: string): any[] {
    if (!rows) {
      return [];
    }
    if (!filterText) {
      return rows;
    }

    return rows.filter((bookmark) => {
      return this.rowContainsFilterText(bookmark, filterText);
    });
  }

  private rowContainsFilterText(row: any, filterText): boolean {
    filterText = filterText.toLocaleLowerCase();
    const filterTerms = filterText.split(" ");
    for (const filterTerm of filterTerms) {
      const hasFilterTerm = this.rowContainsFilterTerm(row, filterTerm);
      if (hasFilterTerm === false) {
        return false;
      }
    }

    return true;
  }

  private rowContainsFilterTerm(row: any, filterTerm: string) {
    let hasTerm = false;
    for (let key in row) {
      if (
        key != "ROW_ID" &&
        row[key] != null &&
        row[key].toString().toLocaleLowerCase().includes(filterTerm)
      ) {
        hasTerm = true;
        break;
      }
    }
    return hasTerm;
  }
}
