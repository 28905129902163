import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseModalComponent } from "./base-modal.component";
import { SuccessModalComponent } from "./success-modal/success-modal.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../material.module";
import { PreviewModalComponent } from "../ticket/modals/preview-modal/preview-modal.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  declarations: [
    BaseModalComponent,
    SuccessModalComponent,
    PreviewModalComponent,
  ],
  exports: [BaseModalComponent, SuccessModalComponent],
})
export class BaseModalModule {}
