import { Injectable, OnDestroy } from "@angular/core";
import {
  Subject,
  BehaviorSubject,
  from,
  iif,
  ReplaySubject,
  of,
  combineLatest,
} from "rxjs";
import {
  concatMap,
  mergeMap,
  multicast,
  scan,
  skipUntil,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { StorageModalComponent } from "src/app/modules/shared/ticket/modals/storage-modal/storage-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { LoggerService } from "../../../../modules/core/services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class StorageCapacityService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private quota$ = new BehaviorSubject<number>(1073741824); // default to 1GB
  private used$ = new BehaviorSubject<number>(0);
  private storageLatest$ = combineLatest([this.quota$, this.used$]);
  private dataStore$ = new ReplaySubject<string>();

  public update$ = new Subject<void>();
  public updateComplete$ = new Subject<void>();

  public dataStores$ = this.dataStore$.pipe(
    scan((acc, value) => [...acc, value], [] as string[])
  );

  public totalUsed$ = this.used$.pipe();

  public totalRemaining$ = this.storageLatest$.pipe(
    switchMap(([quota, used]) => of(quota - used))
  );

  public totalUsedPercent$ = this.storageLatest$.pipe(
    switchMap(([quota, used]) => of(Math.round((used / quota) * 100)))
  );

  constructor(private logger: LoggerService, public dialog: MatDialog) {
    this.update$
      .pipe(
        switchMap(() => from(this.estimateRemainingStorageInBytes())),
        switchMap((val) =>
          iif(
            () => val,
            of(null),
            // @ts-ignore
            from(indexedDB.databases()).pipe(
              // @ts-ignore
              switchMap((res: string[]) =>
                from(res).pipe(
                  tap((name) => this.dataStore$.next(name)),
                  tap((dbName) => this.getDatabaseSize(dbName))
                )
              )
            )
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.updateComplete$.next());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getTableSize(db, dbName) {
    return new Promise((resolve, reject) => {
      if (db == null) {
        return reject();
      }
      let size = 0;
      const openCursor = db
        .transaction([dbName])
        .objectStore(dbName)
        .openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          if (dbName === "documents") {
            const value: Record<string, any> = cursor.value;
            Object.keys(value).forEach((k) => {
              if (value[k] && "Blob" in value[k]) {
                size += value[k].Blob.size;
              }
            });
          }
          const storedObject = cursor.value;
          const json = JSON.stringify(storedObject);
          size += json.length;
          cursor.continue();
        } else {
          resolve(size);
        }
      };
      openCursor.onerror = (err) => {
        reject("error in " + dbName + ": " + err);
      };
    });
  }

  private getDatabaseSize(dbName) {
    const request = indexedDB.open(dbName);
    let db;
    const dbSize = 0;
    request.onerror = (event) => {
      alert("IndexedDB is disabled. Please contact support.");
    };
    request.onsuccess = (event) => {
      db = request.result;
      const tableNames = [...db.objectStoreNames];
      ((tables, database) => {
        const tableSizeGetters = tableNames.reduce((acc, tableName) => {
          acc.push(this.getTableSize(request.result, tableName));
          return acc;
        }, []);

        from(db.objectStoreNames)
          .pipe(mergeMap((x) => from(this.getTableSize(request.result, x))))
          .subscribe((x: number) => this.used$.next(x));
      })(tableNames, db);
    };
  }

  // returns remaining storage in bytes, if available; returns -1 if not
  private async estimateRemainingStorageInBytes(): Promise<boolean> {
    try {
      if (navigator.storage && navigator.storage.estimate) {
        const result = await navigator.storage.estimate();
        this.quota$.next(result.quota);
        this.used$.next(result.usage);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.logger.error("cache$: insert: function error", error);
      return false;
    }
  }

  public storageModal() {
    this.dialog.open(StorageModalComponent, {});
  }

  public humanReadableSize(bytes) {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  }
}
