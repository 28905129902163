<div style="height: 100%" fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="50%" fxFlex.lt-md="100%" fxFlex.md="75%" class="container">
    <h2 class="mat-h2">Cannot Display Document</h2>
    <p class="mat-body">
      Since we did not come to an agreement you could acknowledge upon, please
      contact us.
    </p>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <button mat-raised-button color="primary" (click)="onClickReadAgain()">
        READ AGAIN
      </button>
    </div>
  </div>
</div>
