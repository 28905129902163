<div class="filter-main-div" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="16px grid">
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="16px grid">
    <div fxFlex="100" class="p-2">
      <div class="font-rajdhani text-headline-5 uppercase font-semibold">FILTER VISIBLE RESOURCES</div>
      <div class="font-montserrat text-lg">Toggle and sort resources</div>
      <br />

      <!-- Section of checkboxes -->
      <section [formGroup]="activeRigsFormGroup" class="overflow-y-auto overflow-x-hidden mr-3 pr-3 max-h-[800px]"> <!-- Adjust max-height as needed -->
        <!-- loop over my checkboxes -->
        <div class="list">
          @for (resource of rigResources; track resource) {
            <div class="box">
              <mat-checkbox (change)="toggleResource(resource, $event)" [formControlName]="resource.ResourceID">
                <div class="flex flex-row items-center justify-start">
                  <mat-icon class="w-5 h-5 pr-2" [style.color]="resource.HexColour" svgIcon="point"></mat-icon>
                  <div class="font-montserrat">{{ resource.ResourceName }}</div>
                </div>
              </mat-checkbox>
              <!-- Uncomment below line if implement reorder -->
              <!-- <mat-icon class="pr-2">drag_handle</mat-icon> -->
            </div>
          }
        </div>
      </section>

    </div>
  </div>
</div>