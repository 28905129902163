import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialog } from "@angular/material/dialog";
import { GraphicInfo } from "src/app/modules/core/cache/GraphicsInterface";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ConfirmationDialogComponent } from "../../../confirmation-dialog/confirmation-dialog.component";
import { MarkerObject } from "../../../google-map/google-maps-set/googleMapsOptions";
import { MaterialModule } from "../../../material.module";
import { ProgressBarService } from "../../../progress-bar/progress-bar.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { BaseEsriMapModule } from "../base-esri-map/base-esri-map.component";
import {
  EsriMapComponent,
  EsriMapModule,
} from "../esri-map/esri-map.component";
import { EsriMapLayerService } from "../esri-map/services/esri-map-layer.service";
import { EsriMapDrawingService } from "./services/esri-map-drawing.service";
import { ApiService } from "../../../../core/api/baseapi.service";

@Component({
  selector: "app-esri-map-simple-drawing",
  templateUrl: "../esri-map/esri-map.component.html",
  styleUrls: ["../esri-map/esri-map.component.css"],
  providers: [ComponentMessagingService],
})
export class EsriMapSimpleDrawingComponent
  extends EsriMapComponent
  implements OnChanges
{
  classname = "EsriMapSimpleDrawingComponent";
  @Input() graphics: GraphicInfo[];
  @Input() drawingEnabled: boolean;
  @Input() markers: MarkerObject[];

  constructor(
    logger$: LoggerService,
    layers$: EsriMapLayerService,
    private drawing$: EsriMapDrawingService,
    public dialog: MatDialog,
    progressBarService: ProgressBarService,
    snackBarService: SnackbarService,
    apiService: ApiService,
  ) {
    super(logger$, layers$, progressBarService, snackBarService, apiService);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.graphics && this.mapView) {
      this.drawing$.createGraphics(this.graphics, this.mapView, this.map);
    }

    if (
      changes.markers &&
      changes.markers.currentValue &&
      changes.markers.currentValue.length > 0 &&
      this.mapView
    ) {
      this.drawing$.addMarkers(this.markers, this.mapView, this.map);
    }
  }

  async afterLayersLoad() {
    if (this.graphics) {
      this.drawing$.createGraphics(this.graphics, this.mapView, this.map);
    }
    if (this.drawingEnabled) {
      await this.drawing$.initDrawing(this.mapView);
    }
    if (this.markers && this.markers.length > 0) {
      this.drawing$.addMarkers(this.markers, this.mapView, this.map);
    }
  }

  async getGraphics() {
    var graphics = await this.drawing$.getGraphics();
    return graphics;
  }

  onEsriViewEventHandler() {
    document.getElementById("mapViewContainer").style.cursor = "wait"; // make pointer loading
    try {
      if (this.mapView) {
        this.mapView.on("click", (event) => {
          // TODO: fix this
          if (this._identifyEnabled) {
            this.queryLayers(event);
          } else {
            this.mapView.hitTest(event).then((hitTestResult) => {
              const hitGraphics = hitTestResult.results
                .filter((x) => x.graphic.geometry != null)
                .map((x) => x.graphic);
              if (hitGraphics.length > 0) {
                this.drawing$.update(hitGraphics);
              }
            });
          }
        });
      }
    } catch (error) {
      // this.logger$.error("Error onEsriViewEventHandler: ", error)
    } finally {
      document.getElementById("mapViewContainer").style.cursor = "auto";
    }
  }

  openDialog(title: string, message: string, action: Function): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: {
        title: title,
        message: message,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
    });
  }

  // toggles
  startDrawingPolygon() {
    this.drawing$.startDrawing("polygon");
  }

  startDrawingLine() {
    this.drawing$.startDrawing("polyline");
  }

  startDrawingPoint() {
    this.drawing$.startDrawing("point");
  }

  startDrawingRectangle() {
    this.drawing$.startDrawing("rectangle");
  }

  undo() {
    this.drawing$.undo();
  }

  redo() {
    this.drawing$.redo();
  }

  reset() {
    this.openDialog(
      "Are you sure?",
      "You will lose any unsaved progress.",
      this.drawing$.reset,
    );
  }

  startReshape() {
    this.drawing$.startModifying("reshape");
  }

  startTransform() {
    this.drawing$.startModifying("transform");
  }
}

@NgModule({
  declarations: [EsriMapSimpleDrawingComponent],
  imports: [EsriMapModule, BaseEsriMapModule, FlexLayoutModule, MaterialModule],
  exports: [EsriMapSimpleDrawingComponent],
})
export class EsriMapSimpleDrawingModule {}
