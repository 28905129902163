import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Interaction } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  fromEvent,
  merge,
  Observable,
  of,
  pairwise,
  race,
  skip,
  Subject,
  timer,
} from 'rxjs';
import Map from 'ol/Map';
import { ManagedLayer } from '../classes/managedLayer';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import {
  InteractionParameters,
  LineTools,
  ManipulationTools,
  MapInteractionType,
  SelectionTools,
  StickerTools,
  SymbolTools,
  TextTools,
  ToolbarState,
  ToolType,
} from '../utilities/types';
import MapInteractions, { InteractionOptions } from '../utilities/mapInteractions';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { LayerManagerService } from './layer-manager.service';
import { OpenLayersService } from './open-layers.service';
import ToolOptions from '../utilities/tool-options';
import VectorLayer from 'ol/layer/Vector';
import { Collection, Feature, MapBrowserEvent } from 'ol';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DrawingToolbarService } from '../drawing-toolbar/drawing-toolbar.service';
import { defaults } from 'ol/interaction/defaults';
import { Manipulate } from '../utilities/tools/manipulate';
import { CollectionFlattener } from '../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';
import { OpenLayersUtilities } from '../classes/open-layers-utilities';
import { DrawingService, DrawingTypes } from '../drawing.service';
import { Geometry } from 'ol/geom';
import { StyleLike } from 'ol/style/Style';
import Styles from '../utilities/styles';
import { FeatureStyleService } from './feature-style.service';
import { StyleToolbox } from '../classes/style-toolbox';
import { deepDifference } from '../../../shared/functions/utilityFunctions/deepDifference';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class MapInteractionService implements OnDestroy {
  // interactions
  interactionSubject: Subject<InteractionParameters | null> = new Subject();
  interactionStack: {
    add: (val: Interaction | Interaction[]) => void;
    stash: (exceptions: any[]) => void;
    restoreLast: () => void;
    restoreDefault: () => void;
    report: () => { current: Interaction[]; stashed: Interaction[] };
  };
  // services
  private ngzone = inject(NgZone);
  private snackBar = inject(SnackbarService);
  private drawingService = inject(DrawingService);
  private mapService = inject(OpenLayersService);
  private layerManager = inject(LayerManagerService);
  private toolbarService = inject(DrawingToolbarService);
  private featureStyleService = inject(FeatureStyleService);

  // observables
  private newSelection$: Subject<void> = new Subject();
  private destroy$: Subject<void> = new Subject<void>();
  private endInteraction$: Subject<void> = new Subject<void>();
  private clearCollectionSignal$ = new Subject<void>();
  private featureChanges$: Subject<MapFeatureChange<Collection<Feature>>> = new Subject();
  private featureCollection$: BehaviorSubject<Collection<Feature>> = new BehaviorSubject(new Collection<Feature>());
  private interactionParameters$: Observable<InteractionParameters>;
  private toolbarState$ = toObservable(this.toolbarService.toolbarState$$);

  // members
  private readonly defaultInteractions = defaults({
    doubleClickZoom: false,
    altShiftDragRotate: false,
    pinchRotate: false,
  });
  private mapRef: Map;
  private readonly olUtilities: OpenLayersUtilities;
  private readonly collectionFlattener: CollectionFlattener;
  private longPressDelay = 250; // milliseconds
  private longPressTimer: any;
  private touchStartCoordinate: [number, number] | null = null;

  constructor() {
    // watch the toolbar changes for interaction parameters
    this.interactionParameters$ = toObservable(this.toolbarService.toolbarState$$).pipe(
      pairwise(),
      map(([prev, curr]) => {
        return this.interactionParametersFromFormData(curr, deepDifference(curr, prev));
      })
    );

    this.olUtilities = new OpenLayersUtilities();
    this.collectionFlattener = new CollectionFlattener();

    this.init();
    this.handleToolbarChanges();
  }

  private _viewLocked$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get viewLocked$() {
    return this._viewLocked$.asObservable();
  }

  private _contextEvent$ = new Subject<ContextEvent>();

  get contextEvent$() {
    return this._contextEvent$.pipe();
  }

  get featureChanges() {
    return this.featureChanges$.pipe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.interactionSubject.complete();
  }

  setupContextMenuEvents(olMap: Map) {
    // Right-click event (for non-touch devices)
    fromEvent(olMap, 'contextmenu')
      .pipe(takeUntil(merge(this.destroy$, this.mapService.map$.pipe(skip(1)))))
      .subscribe((event: MapBrowserEvent<UIEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        this.handleContextMenuEvent(event);
      });

    fromEvent(olMap.getViewport(), 'touchstart')
      .pipe(
        tap((event: TouchEvent) => {
          this.touchStartCoordinate = [event.touches[0].clientX, event.touches[0].clientY];
        }),
        switchMap((startEvent: TouchEvent) =>
          race([
            timer(this.longPressDelay).pipe(map(() => ({ type: 'longpress', event: startEvent }))),
            fromEvent(olMap.getViewport(), 'touchend').pipe(
              first(),
              map(() => ({ type: 'touchend', event: undefined }))
            ),
            fromEvent(olMap.getViewport(), 'touchmove').pipe(
              filter(() => !!this.touchStartCoordinate),
              tap((moveEvent: TouchEvent) => {
                const [startX, startY] = this.touchStartCoordinate!;
                const [currentX, currentY] = [moveEvent.touches[0].clientX, moveEvent.touches[0].clientY];
                const distance = Math.sqrt(Math.pow(currentX - startX, 2) + Math.pow(currentY - startY, 2));

                if (distance > 10) {
                  // Adjust this threshold as needed
                  this.touchStartCoordinate = null;
                }
              }),
              first(),
              map(() => ({ type: 'touchmove', event: undefined }))
            ),
          ])
        ),
        tap((result) => {
          if (result.type === 'longpress' && this.touchStartCoordinate) {
            this.handleLongPress(result.event, olMap);
          }
          this.touchStartCoordinate = null;
        }),
        takeUntil(merge(this.destroy$, this.mapService.map$.pipe(skip(1))))
      )
      .subscribe();

    fromEvent(olMap.getViewport(), 'touchmove')
      .pipe(
        filter(() => !!this.touchStartCoordinate),
        tap((event: TouchEvent) => {
          const [startX, startY] = this.touchStartCoordinate!;
          const [currentX, currentY] = [event.touches[0].clientX, event.touches[0].clientY];
          const distance = Math.sqrt(Math.pow(currentX - startX, 2) + Math.pow(currentY - startY, 2));

          if (distance > 10) {
            // Adjust this threshold as needed
            if (this.longPressTimer) {
              this.longPressTimer.unsubscribe();
            }
            this.touchStartCoordinate = null;
          }
        }),
        takeUntil(merge(this.destroy$, this.mapService.map$.pipe(skip(1))))
      )
      .subscribe();
  }

  deleteFeatures() {
    const selected = this.layerManager.selectedLayer.getLayer();
    if (!(selected instanceof VectorLayer) || !(selected.getSource() instanceof VectorSource)) {
      return;
    }
    this.featureCollection$.value.forEach((feature) => {
      selected.getSource().removeFeature(feature);
      this.featureChanges$.next(
        new MapFeatureChange(FeatureChangeType.deleted, feature, undefined, selected.getSource())
      );
    });

    this.endInteraction$.next();
  }

  endInteraction() {
    this.endInteraction$.next();
  }

  toggleViewLock() {
    this._viewLocked$.next(!this._viewLocked$.value);
  }

  featureUpdated(feature: Collection<Feature>, newFeature: Collection<Feature>, source: VectorSource) {
    const { addFeatureToSource, removeFeatureFromSource } = this.olUtilities;
    const { flattenFeature } = this.collectionFlattener;
    removeFeatureFromSource(flattenFeature(feature), source);
    addFeatureToSource(flattenFeature(newFeature), source);
    this.featureCollection$.next(newFeature);
    this.featureChanges$.next(new MapFeatureChange(FeatureChangeType.updated, newFeature, feature, source));
  }

  cloneCollection(collection: Collection<Feature>) {
    return this.collectionFlattener.deepClone(collection);
  }

  handleTextStyleChange(feature: Feature<Geometry>, params: InteractionParameters) {
    if (Object.keys(params.changes).length === 0) {
      return;
    }
    Object.entries(params.changes).forEach(([key, val]) => {
      if (key === 'strokeColour') {
        feature.set('text_colour', val);
      }
      if (key === 'fillColour') {
        feature.set('fill_colour', val);
      }
      if (key === 'opacity') {
        feature.set('fill_opacity', Number(val) / 100);
      }
    });
  }

  applyStyleToCollection(collection: Collection<Feature>, params: InteractionParameters) {
    collection.getArray().forEach((feature) => {
      if (![6, 7, 8, 9, 10, 11, 12].includes(feature.get('shape_type_id'))) {
        feature.setStyle(params.style);
      } else if (6 === feature.get('shape_type_id')) {
        this.handleTextStyleChange(feature, params);
      }
    });
  }

  private handleToolbarChanges() {
    combineLatest([this.interactionParameters$, this.layerManager.selectedLayer$, this.drawingService.drawingType$])
      .pipe(
        switchMap(([params, layer, drawingType]) => this.processToolbarChanges(params, layer, drawingType)),
        takeUntil(this.destroy$)
      )
      .subscribe(({ params, layer }) => this.addInteraction(params, layer));
  }

  private processToolbarChanges(params: InteractionParameters, layer: ManagedLayer, drawingType: DrawingTypes) {
    const changeSource = params.toolbarState._source;
    if (changeSource === 'interaction') {
      return EMPTY;
    }
    if (changeSource === 'initialization' && this.featureCollection$.value.getLength() > 0) {
      return EMPTY;
    }

    if (
      params.changes['manipulationTool'] !== undefined &&
      (Object.keys(params.changes).length === 1 ||
        (Object.keys(params.changes).length === 2 && params.changes['_source'] === null))
    ) {
      return EMPTY;
    }

    // if (changeSource !== 'initialization' && this.isSelectionToolUpdate(params)) {
    if (this.isSelectionToolUpdate(params)) {
      return this.handleSelectionToolUpdate(params, layer, drawingType);
    }

    return this.handleOtherToolUpdates(params, layer, drawingType);
  }

  private isSelectionToolUpdate(params: InteractionParameters): boolean {
    return (
      this.featureCollection$.value.getLength() > 0 &&
      params.toolType === ToolType.selectionTool &&
      params.changes['toolPage'] === undefined &&
      params.changes['selectionTool'] === undefined
    );
  }

  private handleSelectionToolUpdate(params: InteractionParameters, layer: ManagedLayer, drawingType: DrawingTypes) {
    const source = this.getVectorSource(layer, drawingType);
    if (!source) return EMPTY;

    const cloned = this.cloneCollection(this.featureCollection$.value);
    this.applyStyleToCollection(cloned, params);
    this.featureUpdated(this.featureCollection$.value, cloned, source);

    return EMPTY;
  }

  private getVectorSource(layer: ManagedLayer, drawingType: DrawingTypes): VectorSource<Feature> | null {
    if (drawingType === DrawingTypes.canvas) {
      return this.layerManager.canvasDrawingLayer.getSource();
    } else if (drawingType === DrawingTypes.map && layer !== null) {
      return (layer.getLayer() as VectorLayer<VectorSource>).getSource();
    }
    return null;
  }

  private handleOtherToolUpdates(params: InteractionParameters, layer: ManagedLayer, drawingType: DrawingTypes) {
    return of([params, layer]).pipe(
      tap(() => this.interactionStack?.restoreDefault()),
      tap(() => this.endInteraction$.next()),
      filter(([params, layer]: [InteractionParameters, ManagedLayer]) =>
        this.isValidInteraction(params, layer, drawingType)
      ),
      map(([params, layer]) => this.prepareInteractionData(params, layer, drawingType)),
      filter(() => this.drawingService.drawingModeEnabled)
    );
  }

  private isValidInteraction(params: InteractionParameters, layer: ManagedLayer, drawingType: DrawingTypes): boolean {
    if (layer !== null || drawingType === DrawingTypes.canvas) {
      if (params.interactionType === 2 && params.sticker) {
        return params.sticker.Name !== '';
      }
      return params.interactionType !== null;
    }
    return false;
  }

  private prepareInteractionData(params: InteractionParameters, layer: ManagedLayer, drawingType: DrawingTypes) {
    const interactionData = {
      params,
      layer: undefined as VectorLayer<VectorSource> | undefined,
    };

    if (drawingType === DrawingTypes.canvas) {
      interactionData.layer = this.layerManager.canvasDrawingLayer;
    } else {
      interactionData.layer = layer.getLayer() as VectorLayer<VectorSource>;
    }

    return interactionData;
  }

  private init() {
    this.mapService.map$.pipe(takeUntil(this.destroy$)).subscribe((olMap) => {
      // double
      if (olMap) {
        // double
        fromEvent(olMap, 'dblclick')
          .pipe(
            tap(() => this.endInteraction$.next()),
            tap(() => this._contextEvent$.next(null)),
            takeUntil(merge(this.destroy$, this.mapService.map$.pipe(skip(1))))
          )
          .subscribe((event: MapBrowserEvent<UIEvent>) => {
            const layer = this.layerManager.selectedLayer?.getLayer();
            if (
              layer.get('isHighlightLayer') ||
              !(layer instanceof VectorLayer) ||
              !(layer.getSource() instanceof VectorSource)
            ) {
              return;
            }
            this.ngzone.run(() => {
              try {
                const feats = olMap.getFeaturesAtPixel(event.pixel, {
                  hitTolerance: 5,
                  layerFilter: (l) => l === layer,
                });
                if (feats.length <= 0 || !(feats[0] instanceof Feature)) {
                  return;
                }
                if (!this.drawingService.drawingModeEnabled) {
                  this.drawingService.drawingModeEnabled = true;
                }
                this.featureCollection$.next(new Collection<Feature>([feats[0]]));
                this.newSelection$.next();

                this.toolbarService.updateToolbarState(
                  {
                    toolPage: ToolType.selectionTool,
                  },
                  'interaction'
                );
              } catch (e) {
                // internal error likely caused by the highlight layer detaching during the double click event
                console.log(e);
              }
            });
          });
        this.setupContextMenuEvents(olMap);
      }
    });

    this.drawingService.drawingModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (!status) {
        this.endInteraction$.next();
        this.interactionStack?.restoreDefault();
      }
    });

    this._viewLocked$.pipe(takeUntil(this.destroy$)).subscribe((locked) => {
      if (!locked) {
        this.defaultInteractions.forEach((x) => {
          x.setActive(true);
        });
      } else {
        this.defaultInteractions.forEach((x) => {
          x.setActive(false);
        });
      }
    });

    this.featureChanges$
      .pipe(
        filter((x) => x.changeType !== FeatureChangeType.deleted),
        takeUntil(this.destroy$)
      )
      .subscribe((change: MapFeatureChange<Collection<Feature>>) => {
        const flatten = new CollectionFlattener().flattenFeature;
        const arr = flatten(change.feature);
        this.featureCollection$.next(new Collection<Feature>(arr));
        if (change.changeType === FeatureChangeType.added) {
          const text_or_image = arr.reduce((acc, curr) => {
            const shape_type_id = curr.get('shape_type_id');
            if (acc === true) {
              return true;
            } else return shape_type_id === 6 || shape_type_id === 7;
          }, false);
          if (text_or_image) {
            this.newSelection$.next();
          }
        }
      });

    this.endInteraction$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.clearCollectionSignal$.next();
      if (this.toolbarService.toolbarState$$()._source !== 'interaction') {
        const hasModify =
          this.interactionStack?.report().current.reduce((acc, curr) => {
            if (acc === true) {
              return true;
            } else {
              return curr instanceof Manipulate;
            }
          }, false) ?? false;
        if (hasModify) {
          this.interactionStack.restoreLast();
        }
      } else {
        this.toolbarService.updateToolbarState({
          toolPage: ToolType.selectionTool,
          selectionTool: SelectionTools.pointSelect,
        });
      }
    });

    this.newSelection$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!(this.mapService.map instanceof Map)) {
        console.log('map not initialized');
        return;
      }
      let layer: VectorLayer<VectorSource>;
      if (this.drawingService.drawingType === DrawingTypes.canvas) {
        layer = this.layerManager.canvasDrawingLayer;
      } else {
        layer = this.layerManager.selectedLayer.getLayer() as VectorLayer<VectorSource>;
      }
      if (!layer || !(layer instanceof VectorLayer)) {
        console.log('layer not initialized');
        return;
      }
      if (this.featureCollection$.value.getLength() <= 0) {
        return;
      }
      this.interactionStack.stash([Manipulate]);
      const newTool = new Manipulate(
        this.mapService.map,
        layer,
        this.featureCollection$.value,
        this.featureCollection$,
        this.newSelection$,
        this.endInteraction$,
        this.featureChanges$
      );
      this.toolbarState$.pipe(map((x) => x.manipulationTool)).subscribe((mode) => {
        let update = null;
        if (mode === ManipulationTools.rotate) {
          update = 'rotate';
        } else if (mode === ManipulationTools.scale) {
          update = 'scale';
        }
        newTool.mode = update;
      });
      newTool.modeChange.subscribe((mode) => {
        this.toolbarService.updateToolbarState({
          manipulationTool: ManipulationTools[mode],
        });
      });
      this.interactionStack.add([newTool]);
    });

    this.mapService.map$
      .pipe(
        filter((x) => x !== null),
        tap((x) => (this.mapRef = x)),
        tap((x) => (this.interactionStack = this.interactionStateController(x))),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // clear the collection
    this.clearCollectionSignal$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.featureCollection$.next(new Collection<Feature>()));
  }

  private handleContextMenuEvent(event: MapBrowserEvent<UIEvent>) {
    this.ngzone.run(() => {
      try {
        this.endInteraction$.next();
        this._contextEvent$.next(null);

        const layer = this.layerManager.selectedLayer?.getLayer();
        if (
          layer.get('isHighlightLayer') ||
          !(layer instanceof VectorLayer) ||
          !(layer.getSource() instanceof VectorSource)
        ) {
          return;
        }

        const feats = event.map
          .getFeaturesAtPixel(event.pixel, {
            hitTolerance: 5,
            layerFilter: (l) => l === layer,
          })
          .filter((x) => x instanceof Feature) as Feature<Geometry>[];

        if (feats.length <= 0) {
          this.featureCollection$.next(new Collection<Feature>([]));
          this.newSelection$.next();
          return;
        }

        if (!this.drawingService.drawingModeEnabled) {
          this.drawingService.drawingModeEnabled = true;
        }

        this.featureCollection$.next(new Collection<Feature>([feats[0]]));
        this.newSelection$.next();
        this.toolbarService.updateToolbarState(
          {
            toolPage: ToolType.selectionTool,
          },
          'interaction'
        );
        this._contextEvent$.next({
          event,
          layer,
          feature: feats[0],
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  private handleLongPress(event: TouchEvent, olMap: Map) {
    const touch = event.touches[0];
    const pixel = this.mapService.map.getEventPixel({ clientX: touch.clientX, clientY: touch.clientY });
    const coordinate = this.mapService.map.getCoordinateFromPixel(pixel);

    const fakeEvent: Partial<MapBrowserEvent<UIEvent>> = {
      coordinate,
      pixel,
      preventDefault: () => {},
      stopPropagation: () => {},
      map: olMap,
    };

    this.handleContextMenuEvent(fakeEvent as MapBrowserEvent<UIEvent>);
  }

  private interactionStateController(map: Map) {
    let current = [];
    let stashed: Interaction[] = [];

    const remove = () => {
      current = [];
      stashed = [];
      const curr = [...map.getInteractions().getArray()];
      curr.forEach((x) => {
        map.removeInteraction(x);
      });
    };

    const add = (val: Interaction | Interaction[]) => {
      if (Array.isArray(val)) {
        val.forEach((i: Interaction) => {
          map.addInteraction(i);
          current.push(i);
        });
      } else {
        map.addInteraction(val);
        current.push(val);
      }
    };

    const stash = (exceptions: any[] = []) => {
      current.forEach((x) => map.removeInteraction(x));
      stashed = [
        ...current.filter((x) => {
          return !exceptions.includes(x.constructor);
        }),
      ];
      current = [];
    };

    const restoreLast = () => {
      const copy = [...stashed];
      copy.forEach((i: Interaction) => {
        map.addInteraction(i);
        current.push(i);
      });
    };

    const restoreDefault = () => {
      remove();
      this.defaultInteractions.forEach((x) => {
        map.addInteraction(x);
      });
    };

    const report = () => {
      return {
        current: current,
        stashed: stashed,
        attached: [...map.getInteractions().getArray()],
      };
    };

    remove();
    restoreDefault();
    return { add, stash, restoreLast, restoreDefault, report };
  }

  private addInteraction(interactionParams: InteractionParameters, layer: VectorLayer<VectorSource>) {
    const layerSource = layer.getSource();
    try {
      if (interactionParams.interactionType === null) {
        console.log('interaction type not available');
        return;
      }
      if (interactionParams.toolType === null) {
        console.log('tool type not available');
        return;
      }
      const interaction: (arg: Partial<InteractionOptions>) => Interaction[] =
        MapInteractions[interactionParams.interactionType];

      const toolOption: () => () => unknown = ToolOptions[interactionParams.toolType]();

      const interactionOptions: Record<symbol, (arg) => Record<string, any>> =
        toolOption[interactionParams.toolName](layerSource);

      const interactionsToAdd: Interaction[] = interaction({
        opts: interactionOptions,
        sticker: interactionParams.sticker,
        svgSymbol: interactionParams.svgSymbol,
        style: interactionParams.style,
        mapRef: this.mapRef,
        layerRef: layer,
        featureCollectionSubject: this.featureCollection$,
        newSelection: this.newSelection$,
        featureChanges: this.featureChanges$,
        endInteraction: this.endInteraction$,
        olUtilities: this.olUtilities,
        parameters: interactionParams,
      });
      this.interactionStack.add(interactionsToAdd);
    } catch (e) {
      console.log(e);
      this.snackBar.openSnackbar('Feature not available', SnackbarType.default, '');
    }
  }

  private interactionParametersFromFormData(data: ToolbarState, changes: Partial<ToolbarState>) {
    const styleToolbox = new StyleToolbox();
    let toolType = null;
    let interactionType = null;
    let toolName = null;
    let style: StyleLike = this.featureStyleService.buildStyleLike(
      data.fillColour,
      data.strokeColour,
      data.lineStyle,
      data.lineThickness,
      data.opacity
    );
    let sticker = undefined;
    const legacySymbol = undefined;
    let svgSymbol = undefined;
    const styleParams = undefined;

    switch (data.toolPage) {
      case undefined:
        toolType = null;
        break;
      case 0:
        {
          interactionType = MapInteractionType.draw;
          toolType = ToolType.lineTool;
          if (data.utilityLine !== null && data.utilityLine.LineID > -1) {
            const { LineColour, LineText, Dashed } = data.utilityLine;
            toolName = LineTools.customLine;
            style = this.featureStyleService.buildUtilityLineStyle(LineColour, LineText, Dashed);
          } else if (data.lineTool === LineTools.arrowLine) {
            style = Styles.arrowLine(data.lineThickness, styleToolbox.hexToRGBA(data.strokeColour, data.opacity));
            toolName = LineTools.arrowLine;
          } else if (data.lineTool === LineTools.measureLine) {
            style = Styles.measureLine(data.lineThickness, styleToolbox.hexToRGBA(data.strokeColour, data.opacity));
            toolName = LineTools.measureLine;
          } else {
            toolName = data.lineTool;
          }
        }
        break;
      case 1:
        interactionType = MapInteractionType.draw;
        toolType = ToolType.shapeTool;
        toolName = data.shapeTool;
        break;
      case 2:
        interactionType = MapInteractionType.addText;
        toolType = ToolType.textTool;
        toolName = TextTools.label;
        break;
      case 3:
        {
          if (data.svgSymbol) {
            interactionType = MapInteractionType.Symbol;
            toolType = ToolType.symbolTool;
            toolName = SymbolTools.svgSymbol;
            svgSymbol = data.svgSymbol;
          } else {
            toolType = null;
          }
        }
        break;
      case 4:
        {
          if (data.sticker) {
            interactionType = MapInteractionType.addSticker;
            sticker = data.sticker;
            toolType = ToolType.stampTool;
            toolName = StickerTools.stamp;
          }
        }
        break;
      case 6:
        {
          interactionType = MapInteractionType.select;
          toolType = ToolType.selectionTool;
          toolName = data.selectionTool;
        }
        break;
      default: {
        interactionType = MapInteractionType.draw;
      }
    }

    return {
      interactionType,
      toolName,
      toolType,
      style,
      sticker,
      legacySymbol,
      svgSymbol,
      styleParams,
      toolbarState: data,
      changes,
    } as InteractionParameters;
  }
}

export type ContextEvent = {
  layer: VectorLayer<VectorSource>;
  feature: Feature<Geometry>;
  event: MapBrowserEvent<UIEvent>;
};
