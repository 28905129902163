import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { loadModules } from "esri-loader";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { MapOptions } from "../EsriMapModels";

@Component({
  selector: "app-base-esri-map",
  template: `<div #mapViewContainer id="mapViewContainer"></div>`,
  styles: [
    `
          .esri-view {
            height: 100%;
          }
        `,
  ],
})
export class BaseEsriMapComponent implements OnChanges {
  private className = "BaseEsriMapComponent";

  @ViewChild("mapViewContainer", { static: true })
  private mapViewContainer: ElementRef;
  @Input() mapOptions: MapOptions;
  @Output() mapLoaded = new EventEmitter<Object>();

  private map;
  private mapView;
  private measurementWidget: any;

  constructor(private logger$: LoggerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.mapOptions.currentValue.center &&
      changes.mapOptions.currentValue.zoom
    ) {
      this.init();
    }
  }

  async init() {
    if (!this.map && !this.mapView) {
      await this.initializeMap();
      await this.initializeMapView();
      this.mapLoaded.emit({
        map: this.map,
        mapView: this.mapView,
      });
    }
  }

  setTool(tool) {
    this.measurementWidget.activeTool = tool;
  }
  async initializeMap() {
    try {
      let map;
      // if map is not already initialized then init
      if (!this.map) {
        const [Map] = await loadModules(["esri/Map"]);

        if (this.mapOptions.basemap) {
          map = new Map({
            basemap: this.mapOptions.basemap,
          });
        } else {
          map = new Map({
            basemap: "streets-vector",
          });
        }

        this.logger$.log("initMap done");
        this.map = map;
      }
    } catch (error) {
      this.logger$.log(this.className, error);
    }
  }

  async initializeMapView() {
    try {
      // if mapView is not already initialized then init
      if (!this.mapView) {
        if (this.map && this.mapOptions.center && this.mapOptions.zoom) {
          const [MapView, Locate, Zoom, Search, Measurement, BasemapToggle] =
            await loadModules([
              "esri/views/MapView",
              "esri/widgets/Locate",
              "esri/widgets/Zoom",
              "esri/widgets/Search",
              "esri/widgets/Measurement",
              "esri/widgets/BasemapToggle",
            ]);

          const mapViewProperties = {
            container: this.mapViewContainer.nativeElement,
            center: this.mapOptions.center,
            zoom: this.mapOptions.zoom,
            map: this.map,
            ui: {
              components: [], // remove the default zoom widget
            },
          };
          this.mapView = new MapView(mapViewProperties);
          await this.mapView.when(); // wait for map to load

          // additional widgets
          if (this.mapOptions.basemapToggle) {
            var basemapToggle = new BasemapToggle({
              view: this.mapView,
              nextBasemap: "hybrid",
            });

            this.mapView.ui.add(basemapToggle, "bottom-left");
          }

          let locateWidget = new Locate({
            view: this.mapView,
            useHeadingEnabled: false,
            goToOverride: function (view, options) {
              options.target.scale = 2500;
              return view.goTo(options.target);
            },
          });
          this.mapView.ui.add(locateWidget, "top-right");

          // make zoom widget
          var zoomWidget = new Zoom({
            view: this.mapView,
          });
          this.mapView.ui.add(zoomWidget, "top-right");

          // if (this.mapOptions.searchContainer) {
          var searchWidget = new Search({
            view: this.mapView,
            // container: this.mapOptions.searchContainer
          });
          // }
          this.mapView.ui.add(searchWidget, "bottom-left");

          // this.measurementWidget = new Measurement({
          //   view: this.mapView,
          //   activeTool: "none"
          // });
          // // }
          // this.mapView.ui.add(this.measurementWidget, "top-left");

          this.logger$.log("initMapView done");
        }
      }
    } catch (error) {
      this.logger$.error(this.className, error);
    }
  }
}

@NgModule({
  declarations: [BaseEsriMapComponent],
  imports: [],
  exports: [BaseEsriMapComponent],
})
export class BaseEsriMapModule {}
