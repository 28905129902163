<div
  fxLayout="column"
  fxLayoutAlign="start center"
  style="padding-bottom: 50px"
  (swiperight)="onSwipe($event)"
  (swipeleft)="onSwipe($event)"
>
  <div *ngIf="billingLoaded" style="width: 100%">
    <ng-container
      *ngFor="
        let billingFormProp of BillingDetailProps | keyvalue;
        trackBy: trackByFn
      "
    >
      <app-form-input-template
        [view]="billingFormProp.value.view"
        [views]="billingFormProp.value"
        [navLinks]="BillingNavLink"
        (checkboxChange)="handleCheckboxChange($event)"
      >
      </app-form-input-template>
    </ng-container>
  </div>



  <div *ngIf="isU2AuditTicket == false && commonLoaded" style="width: 100%">
    <ng-container
      *ngFor="
        let commonFormProp of CommonDetailProps | keyvalue;
        trackBy: trackByFn
      "
    >
      <app-form-input-template
        [view]="commonFormProp.value.view"
        [views]="commonFormProp.value"
        [navLinks]="CommonNavLink"
      >
      </app-form-input-template>
    </ng-container>
  </div>

    <div *ngIf="primaryLoaded" class="utility-details">
    <span class="header">Utility Details</span>
    <ng-container
      *ngFor="
        let primaryFormProp of PrimaryDetailProps | keyvalue;
        trackBy: trackByFn
      "
    >

      <app-form-input-template
        [view]="primaryFormProp.value.view"
        [views]="primaryFormProp.value"
        [navLinks]="PrimaryNavLink"
        [isPrimary]="isPrimary"
      >
      </app-form-input-template>
    </ng-container>
  </div>
</div>

<floating-action-menu
  *ngIf="completionsAllowed && fabMenu.length > 0"
  [hidden]="bottomMenuOpen"
  [menuItems]="fabMenu"
  type="fab"
>
</floating-action-menu>
