
<div fxLayout="row" style="height: 100%; width: 100%">
  <app-page-navigation
    [routes]="routes"
    [header]="header"
  ></app-page-navigation>
  <div fxFlex style="height: 100%">
    <router-outlet></router-outlet>
  </div>
</div>
