import { trigger, transition, style, animate } from "@angular/animations";
import { CommonModule } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  HostListener,
  NgModule,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { map, startWith } from "rxjs/operators";
import { BaseComponent } from "../../core/base-set/base-set.component";
import { LoggerService } from "../../core/services/logger/logger.service";
import {
  FormInputTemplateModule,
  FormTemplateView,
} from "../../shared/forms/form-input-template/form-input-template.component";
import { MaterialModule } from "../../shared/material.module";
import { CreateTicketComponentService } from "../create-ticket-component.service";
import { Observable } from "rxjs";

export const SlideInOut = trigger("slideInOut", [
  transition(":enter", [
    style({ transform: "translateX(+100%)" }),
    animate("250ms ease-in", style({ transform: "translateX(0%)" })),
  ]),
  transition(":leave", [
    animate("250ms ease-in", style({ transform: "translateX(+100%)" })),
  ]),
]);

export const FadeInOut = trigger("fadeInOut", [
  transition(":enter", [
    style({ opacity: "0" }),
    animate("250ms ease-in", style({ opacity: "0.5" })),
  ]),
  transition(":leave", [animate("250ms ease-in", style({ opacity: "0" }))]),
]);

@Component({
  selector: "app-slideover-side-panel",
  templateUrl: "./slideover-side-panel.component.html",
  styleUrls: ["./slideover-side-panel.component.scss"],
  animations: [SlideInOut, FadeInOut],
})
export class SlideOverSidePanelComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() props: SidePanelProps;
  myPanel: any;
  isOpen: boolean;
  screenHeight: number;
  screenWidth: number;
  loading: boolean = false;
  AngularButtonTypes = {
    defaultButton: AngularButtonTypes.defaultButton,
    raisedButton: AngularButtonTypes.raisedButton,
    flatButton: AngularButtonTypes.flatButton,
  };
  sidePanelTemplates: SidePanelTemplates;
  //switch company
  myControl = new FormControl();
  options: any = [];
  filteredOptions: Observable<any[]>;

  constructor(
    logger$: LoggerService,
    public dialog: MatDialog,
    private createTicket$: CreateTicketComponentService,
  ) {
    super(logger$);
  }

  ngOnInit(): void {
    if (this.props.isFullscreen) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    }
    this.myPanel = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      // }
      this.setupExcavatorSearch();
    } catch (error) {
      console.error(error.message);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.props.isFullscreen) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  enableCurrentFormGroup() {
    try {
      if (this.props && this.props.view && this.props.view["formGroup"]) {
        this.props.view["formGroup"].enable();
      }
      this.loading = false;
    } catch (error) {
      console.error(error.message);
    }
  }

  disableCurrentFormGroup() {
    try {
      if (this.props && this.props.view && this.props.view["formGroup"]) {
        this.props.view["formGroup"].disable();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  close() {
    try {
      this.clearForm();
      this.toggleOpen();
    } catch (error) {
      console.error(error.message);
    }
  }

  open() {
    try {
      // this.clearForm();
      this.enableCurrentFormGroup();
      this.toggleOpen();
    } catch (error) {
      console.error(error.message);
    }
  }

  clearForm() {
    try {
      if (this.props.view && this.props.view["formGroup"]) {
        let myFormGroup = this.props.view["formGroup"];
        myFormGroup.reset();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  addressSearched(event) {
    try {
      let companyValues = {
        ExcavatorName: "",
        ExcavatorAddress: "",
        ExcavatorCity: "",
      };
      if (event && event.place && event.place["name"]) {
        let address = this.parseAddressFromGoogleAddress(
          event.place["address_components"],
        );
        companyValues.ExcavatorName = event.place["name"];
        companyValues.ExcavatorAddress = address["streetAddress"];
        companyValues.ExcavatorCity = address["city"];
        this.patchValues(companyValues, event.field);
        this.props.view["formGroup"].markAllAsTouched();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  /**
   *
   * @param values obj {formgroupKey:val}
   * @param field field in view
   */
  patchValues(values, field) {
    try {
      if (values && field && field.options && field.options.patchValues) {
        let patchValues = field.options.patchValues;
        patchValues.forEach((patchValue) => {
          if (
            this.props.views[patchValue.viewKey] &&
            this.props.views[patchValue.viewKey]["formGroup"]
          ) {
            this.props.views[patchValue.viewKey]["formGroup"].patchValue(
              values,
            );
          }
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  private parseAddressFromGoogleAddress(address: any) {
    let addressInfo = {};
    try {
      let streetAddress = "";
      let city = "";
      let houseNumber = "";
      // let addressInfoKeys = ["street_number", "route", "locality", "intersection"];
      address.reduce((finalObj, curObj) => {
        if (curObj.types.indexOf("locality") > -1) {
          city = curObj.long_name;
        }
        if (
          curObj.types.indexOf("route") > -1 ||
          curObj.types.indexOf("intersection") > -1
        ) {
          streetAddress = curObj.long_name;
        }
        if (curObj.types.indexOf("street_number") > -1) {
          houseNumber = curObj.long_name;
        }
        if (curObj.types.indexOf("political") > -1 && city.length == 0) {
          city = curObj.long_name;
        }
        return finalObj;
      }, []);

      addressInfo = {
        city: city,
        streetAddress: streetAddress,
        houseNumber: houseNumber,
      };
    } catch (error) {
      console.error("parseAddressFromGoogleAddress: " + error.message);
    }
    return addressInfo;
  }

  //searchcompany template functions
  async setupExcavatorSearch() {
    try {
      let companies = await this.createTicket$.getAllCompanyInfo();
      if (companies && companies.length > 0) {
        this.options = companies;
      }
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value ? value.text : value,
        ),
        map((text) => this._filter(text)),
      );
    } catch (error) {
      console.error(error.message);
    }
  }

  //filter when type in autocomplete field
  private _filter(text: string): any {
    const filterValue = text.toLowerCase();
    return this.options.filter(
      (option) => option.text.toLowerCase().indexOf(filterValue) > -1,
    );
  }
  //13
  displayFn(option): string {
    if (typeof option === "object") {
      return option && option.text ? option.text : "";
    } else {
      return option;
    }
  }

  onAutoCompanyClick(optionValue) {
    try {
      this.logger$.log(optionValue);
    } catch (error) {
      console.error(error.message);
    }
  }
}

export interface SidePanelButtonAction {
  text: string;
  buttonType: AngularButtonTypes;
  color: string;
  action: any;
  width: number;
  order: number;
}

export enum SidePanelTemplates {
  "switchCompany" = 1,
}

export enum AngularButtonTypes {
  "defaultButton" = "mat-button",
  "raisedButton" = "mat-raised-button",
  "flatButton" = "mat-flat-button",
}

export interface SidePanelProps {
  title: string;
  isFullscreen: boolean;
  width: string | number;
  isForm: boolean;
  htmlContent?: string;
  template?: SidePanelTemplates; //would like this to load in different components or make new key for component passing
  view?: FormTemplateView;
  views?: any;
  actions: SidePanelButtonAction[];
}

@NgModule({
  declarations: [SlideOverSidePanelComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputTemplateModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  exports: [SlideOverSidePanelComponent],
})
export class SlideOverSidePanelModule {}
