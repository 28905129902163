import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DateTime } from "luxon";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";

@Component({
  selector: "app-datetime-selection-modal",
  templateUrl: "datetime-modal.component.html",
})
export class DatetimeSelectionModalComponent {
  data: any;
  header: string;
  date: Date;
  startTime: string;

  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#fff",
      buttonColor: "#0d66b1",
    },
    dial: {
      dialBackgroundColor: "#0d66b1",
    },
    clockFace: {
      clockFaceBackgroundColor: "#eee",
      clockHandColor: "#0d66b1",
      clockFaceTimeInactiveColor: "#222",
    },
  };

  dateTimePickerFormGroup: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: any,
    public dialogRef: MatDialogRef<DatetimeSelectionModalComponent>,
    private snackbar: SnackbarService,
    private datetimeService: DatetimeService,
    private formBuilder: FormBuilder
  ) {
    this.dateTimePickerFormGroup = formBuilder.group({
      timeIn: [""],
      dateIn: [""],
    });
    try {
      if (userData && userData["date"] != null) {
        this.date = userData["date"];
      }

      if (userData && userData["startTime"] != null) {
        this.startTime = userData["startTime"];
        const toDateObject =
          userData["startTime"] instanceof Date
            ? userData["startTime"]
            : typeof userData["startTime"] === "string"
            ? new Date(userData["startTime"])
            : new Date();
        const time = toDateObject.toLocaleTimeString("default", {
          hour: "2-digit",
          minute: "2-digit",
        });
        this.dateTimePickerFormGroup.patchValue({
          dateIn: this.startTime,
          timeIn: time,
        });
      }

      if (userData && userData["header"] != null) {
        this.header = userData["header"];
      }
    } catch (error) {
      console.error(error);
    }
  }

  onConfirm() {
    const { dateIn, timeIn } = this.dateTimePickerFormGroup.getRawValue();
    try {
      this.dialogRef.close({
        result: this.datetimeService.localDateToDBDateStr(
          this.dateTimePickerMerge(dateIn, timeIn)
        ),
      });
    } catch (error) {
      this.dialogRef.close({});
    }
  }

  dateTimePickerMerge(date: Date, time: string) {
    const dateString =
      DateTime.fromJSDate(date).toFormat("yyyy/LL/dd") + " " + time;
    return new Date(dateString.replace(/-/g, "/"));
  }

  dateTimePickerClear(field) {
    try {
      this.dateTimePickerFormGroup.patchValue({ timeIn: "", dateIn: "" });
    } catch (error) {
      console.error(error.message);
    }
  }
}
