import {Injectable} from '@angular/core';
import {GlobalSetting, UserCategory} from './user-category-models';
import {SnackbarService} from '../shared/snackbar/snackbar.service';
import {ProgressBarService} from '../shared/progress-bar/progress-bar.service';
import {ApiService, UtilocateApiRequest} from '../core/api/baseapi.service';
import {SnackbarType} from '../shared/snackbar/snackbar/snackbar';
import {api, apiKeys} from 'src/app/ENDPOINTS';
import {BehaviorSubject} from 'rxjs';
import {SettingID} from '../core/services/user/setting';
import {SettingGroup} from './settings-group/edit-settings.component';

@Injectable({
  providedIn: 'root'
})
export class UserCategoryEditorService {

  Tables: {
    GlobalSettings: GlobalSetting[],
    UserCategories: UserCategory[],
  };

  private tablesSubject = new BehaviorSubject<any>({GlobalSettings: [], UserCategories: []});
  tables$ = this.tablesSubject.asObservable();

  constructor(private utilocateAPIService: ApiService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService) { }

  isEmpty(obj: unknown) {
    if (!obj) return false;
    for (const key in obj as object) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key].length === 0) {
        return true;
      }
    }
    return false;
  }

  async getTables() {
    //call queryTables if this.tables is empty
    if (!this.Tables || this.isEmpty(this.Tables) || this.isEmpty(this.Tables.GlobalSettings) || this.isEmpty(this.Tables.UserCategories)) {
      await this.queryTables();
    }
    return this.Tables;
  }

  async queryTables() {
    const apiValue = {
      query: {
        UserCategories: {
          all: true
        },
        GlobalSettings: {
          all: true
        },
      }
    };

    //call the api 
    const tables = await this.invokeAPIWithPayload(apiValue);


    if (!tables) {
      this.snackBarService.openSnackbar(
        "Error getting User Category Tables",
        SnackbarType.warning
      );
    }


    //if SettingID.TICKET_ROUTING_CLUSTER_SIZE is found as a settingID in tables.UserCategories, multiply it's DefaultValue by 2
    if (tables.UserCategories) {
      tables.UserCategories.forEach(userCategory => {
        userCategory.Settings.forEach(setting => {
          if (setting.SettingID === SettingID.TICKET_ROUTING_CLUSTER_SIZE) {
            //Routing setting 163 is /2 in the db, but *2 to the user
            setting.DefaultValue *= 2;
          }
        });
      });
    }


    if (tables) this.Tables = {GlobalSettings: tables.GlobalSettings, UserCategories: tables.UserCategories};
    this.tablesSubject.next(tables); // Update the BehaviorSubject with new data
  }

  async updateUserCategory(userCategory: UserCategory, updateSettings: boolean) {

    //if SettingID.TICKET_ROUTING_CLUSTER_SIZE is found as a settingID in  userCategory.Settings, divide it's DefaultValue by 2
    userCategory.Settings.forEach(setting => {
      setting.SettingCategoryID = userCategory.UserCategoryID;
      if (setting.SettingID === SettingID.TICKET_ROUTING_CLUSTER_SIZE) {
        //Routing setting 163 is /2 in the db, but *2 to the user
        setting.DefaultValue = String(Number(setting.DefaultValue) / 2);
      }
    });

    //for every setting in this category, add a parameter 'SettingCategoryID' and set it to userCategory.UserCategoryID
    const apiValue = {
      mutation: {
        update: {
          UserCategoryID: userCategory.UserCategoryID,
          Title: userCategory.Title,
          Description: userCategory.Description,
          Settings: userCategory.Settings,
        },
        overrideValueActive: updateSettings
      }
    };

    //Use await here since we're updating the db 
    const result = await this.invokeAPIWithPayload(apiValue);
    if (result && result.success) {
      this.snackBarService.openSnackbar(
        "User Category Updated",
        SnackbarType.success
      );
    } else {
      this.snackBarService.openSnackbar(
        result.failMessage ?? "Error updating user category",
        SnackbarType.error
      );
    }

    await this.queryTables();
  }

  /**
   * Updates some settings for users for a user category. 
   *
   * @param {SettingGroup} settingGroup
   * @param {number} userCategoryID
   * @memberof UserCategoryEditorService
   */
  async updateSettingGroupForCategory(settingGroup: SettingGroup, userCategoryID: number) {

    //we make a deep copy of the settings since this one setting value is /2 from what the front-end shows 
    //this way, we don't update the UI with the /2 value
    const copyOfGroupSettings = [...JSON.parse(JSON.stringify(settingGroup.Settings))];
    copyOfGroupSettings.forEach(setting => {
      if (setting.SettingID === SettingID.TICKET_ROUTING_CLUSTER_SIZE) {
        //Routing setting 163 is /2 in the db, but *2 to the user
        setting.DefaultValue = String(Number(setting.DefaultValue) / 2);
      }
    });

    const apiValue = {
      mutation: {
        users: {
          UserCategoryID: userCategoryID,
          Settings: copyOfGroupSettings,
        },
        overrideValueActive: true
      }
    }

    //since we don't care about saving the category we can call this async 
    this.invokeAPIWithPayload(apiValue).then((result) => {
      if (result && result.success) {
        this.snackBarService.openSnackbar(
          "Updated users settings",
          SnackbarType.success
        );
      } else {
        this.snackBarService.openSnackbar(
          result.failMessage ?? "Error updating users settings",
          SnackbarType.error
        );
      }
    });

  }

  /**
   * gett for a categoryID
   *
   * @param {number} userCategoryID
   * @return {*} 
   * @memberof UserCategoryEditorService
   */
  async getUserCategory(userCategoryID: number) {
    await this.queryTables();
    return this.Tables.UserCategories?.find(
      (userCategory) => userCategory.UserCategoryID === userCategoryID
    );
  }

  /**
   * Queries the userCategory by ID
   * @param userCategoryID 
   * @returns 
   */
  async queryUserCategoryByID(userCategoryID: number) {
    const apiValue = {
      query: {
        UserCategories: {
          UserCategoryID: userCategoryID,
        },
      }
    };

    const userCategory = await this.invokeAPIWithPayload(apiValue);
    if (userCategory) {
      this.Tables.UserCategories.push(userCategory);
    }
    return userCategory.UserCategories[0];
  }

  /**
   * Calls the API 
   *
   * @private
   * @param {object} apiValue
   * @return {*} 
   * @memberof UserCategoryEditorService
   */
  private async invokeAPIWithPayload(apiValue: object) {
    this.progressBarService.start();
    let result = null;
    try {
      const apiKey = apiKeys.u2.userCategoryController;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      const apiResult = await this.utilocateAPIService.invokeUtilocateApi(
        utilocateApiRequest
      );
      if (apiResult.body) {
        result = apiResult.body;
      }
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
    return result;
  }
}
