<div fxLayout="column" class="nav-rail">
  <div style="height: 8px"></div>
  <div
    style="height: calc(100% - 8px)"
    fxLayout="column"
    fxLayoutAlign="space-between center"
  >
    <div class="nav-start-div" fxLayout="column" fxLayoutAlign="start center">
      <div
        *ngFor="let input of inputsStart; let i = index"
        (click)="getStartInput(i)"
        class="nav-button"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-icon id="{{ input.id }}-icon" class="icon" aria-hidden="false">
          {{ input.icon }}
        </mat-icon>
        <div
          id="{{ input.id }}-text"
          class="mat-body-s"
          style="text-align: center"
        >
          {{ input.label }}
        </div>
      </div>
    </div>

    <div class="nav-end-div" fxLayout="column" fxLayoutAlign="end center">
      <div
        *ngFor="let input of inputsEnd; let i = index"
        (click)="getEndInput(i)"
        class="nav-button"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-icon id="{{ input.id }}-icon" aria-hidden="false">
          {{ input.icon }}
        </mat-icon>
        <div
          id="{{ input.id }}-text"
          class="mat-body-s"
          style="text-align: center"
        >
          {{ input.label }}
        </div>
      </div>
    </div>
  </div>
</div>
