<button
  data-testid="search-tile"
  cdkOverlayOrigin
  #overlayOrigin="cdkOverlayOrigin"
  appClickThrottle
  (throttleClick)="fireSearch(search)"
  [allowPropagation]="true"
  [throttleTime]="2000"
  class="flex flex-row justify-center items-center appearance-none border-solid border-0 border-gray-400 border-opacity-30 {{
    isFirstChild ? 'rounded-t-md' : 'border-t-1'
  }} {{ isLastChild ? 'rounded-b-md' : '' }} cursor-pointer {{
    this.searchService.lastCaller === this ? 'selected' : ''
  }} p-0"
  [ngClass]="collectionTitle === 'Temporary Searches' ? 'search-tile temporary' : 'search-tile'">
  <div class="flex flex-row flex-grow justify-start items-center pl-3" style="height: 100%">
    @if (showCount) {
      <span class="count flex basis-[50px] justify-start items-center">
        {{ search.Count > 10000 ? '>10k' : (search.Count | truncate) }}
      </span>
    }
    <span
      *ngIf="collectionTitle === 'Saved Searches'; else tempTitle"
      class="flex {{
        showCount ? 'basis-[calc(100% - 79px)]' : ''
      }} justify-start items-center overflow-hidden overflow-ellipsis whitespace-nowrap">
      {{ search.SearchName }}
    </span>
    <ng-template #tempTitle>
      <div class="flex flex-col basis-[calc(100% - 79px)] justify-center items-start">
        <span class="flex justify-start items-center">{{ search.criteriaDescriptions[0].Field | abbreviate }}</span>
        <span class="category-value flex justify-start items-center">
          {{ search.criteriaDescriptions[0].Value | abbreviate }}
        </span>
      </div>
      <!-- overlay for temp search info-->
      <ng-template
        [cdkConnectedOverlayOrigin]="overlayOrigin"
        [cdkConnectedOverlayOpen]="searchDialogOpen"
        cdkConnectedOverlay
        cdkConnectedOverlayPush
        [cdkConnectedOverlayPositions]="positionPairs"
        [cdkConnectedOverlayHasBackdrop]="false"
        [cdkConnectedOverlayWidth]="206"
        (overlayOutsideClick)="handleBackdropClick()">
        <div class="temp-search-panel flex flex-col justify-start items-start">
          <span class="flex justify-between items-center">
            <span class="count flex justify-start items-start">{{ search.Count }}</span>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="flex justify-center items-center"
              aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="mat-menu-remove-padding">
              <button mat-menu-item [disableRipple]="true" (click)="deleteClick(search)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item [disableRipple]="true">
                <mat-icon>edit</mat-icon>
                <span class="uppercase font-rajdhani">Edit</span>
              </button>
            </mat-menu>
          </span>
          <ul>
            <li *ngFor="let crit of search.criteriaDescriptions">
              <span>{{ crit.Title }}: {{ crit.Value }}</span>
            </li>
          </ul>
          <span class="timestamp">Last Ran: {{ search.LastRun }}</span>
        </div>
      </ng-template>
      <!--^^ overlay for temp search info ^^-->
    </ng-template>
  </div>

  <div
    class="flex basis-[30px] justify-start items-center"
    style="width: 100%; height: 100%"
    appClickStopPropagation>
    <div>
      <button
        class="size-[32px] flex justify-center items-center appearance-none border-none bg-transparent cursor-pointer rounded-full hover:bg-gray-400 hover:bg-opacity-40 p-0 mr-1"
        [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="submenu_vertical" style="width: 24px; height: 24px"></mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="mat-menu-remove-padding">
        @if (showDelete) {
          <button mat-menu-item [disableRipple]="true" (click)="deleteClick(search)">
            <mat-icon>delete</mat-icon>
            <span class="uppercase font-rajdhani font-semibold">Delete</span>
          </button>
        }
        <button mat-menu-item [disableRipple]="true" (click)="editClick(search)">
          <mat-icon>edit</mat-icon>
          <span class="uppercase font-rajdhani font-semibold">Edit</span>
        </button>
      </mat-menu>
    </div>
  </div>
</button>
