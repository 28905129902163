import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TicketActionsService } from 'src/app/shared/services/ticket-actions/ticket-actions.service';
import { AsyncPipe } from '@angular/common';
import { ButtonDropdownMenuComponent } from '../../../../shared/components/menus/button-dropdown-menu/button-dropdown-menu.component';
import { TicketActionsMenuPipe } from './ticket-actions-menu.pipe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-actions-menu',
  standalone: true,
  imports: [MatIcon, AsyncPipe, ButtonDropdownMenuComponent, TicketActionsMenuPipe],
  template: `
    <app-button-dropdown-menu
      [title]="'actions'"
      [items]="filteredActions$ | async | ticketActionsMenu"
      (actionClick)="ticketActionEvent.emit($event)" />
  `,
})
export class TicketActionsMenuComponent {
  // IO
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() ticketActionEvent = new EventEmitter<{ name: string; value: number }>();

  // services
  protected ticketActionsService = inject(TicketActionsService);

  // Observable to hold filtered actions
  filteredActions$ = this.ticketActionsService.ticketActions$.pipe(
    map(actions => actions.filter(action => action.id !== 1100))
  );

  constructor() {}
}
