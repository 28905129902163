import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../material.module";

@NgModule({
  declarations: [SideNavigationComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [SideNavigationComponent],
})
export class SideNavigationModule {}
