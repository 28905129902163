import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketFormTypes } from "src/app/modules/create-ticket/CreateTicketModel";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { digsiteMessages } from "../../digsite.module";
import { DigsiteService } from "../../digsite.service";
// declare panel component
@Component({ selector: "app-digsite-panel", template: "" })
export class DigsitePanelComponent implements OnInit {
  className: string = "DigsitePanelComponent";
  @Input() props: any;
  @Input() viewOnly: boolean;
  @ViewChild("digsiteViewComponent", {}) digsiteViewComponent;

  childProps: {};

  constructor(
    private logger$: LoggerService,
    private messaging$: ComponentMessagingService,
    private digsite$: DigsiteService,
    private progressBarService: ProgressBarService
  ) {
    this.childProps = {
      messageService: messaging$,
    };
  }

  ngOnInit() {
    // child messaging
    this.messaging$
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        switch (nextMsg.message) {
          case digsiteMessages.FINISHED_DRAWING:
            this.uploadTicket();
            break;
          case digsiteMessages.UPDATE_TICKET_DATA:
            this.updateTicket();
            break;
          case digsiteMessages.GET_DIGSITES:
            this.getDigsites();
            break;
          default:
            break;
        }
      });

    // parent and sibling messaging
    this.props["messageService"]
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        // handle message
      });
  }

  error(ex: Error) {
    this.logger$.error(this.className, "error", "error was thrown", 2, ex);
  }

  uploadTicket() {
    this.progressBarService.start();

    this.digsite$.applyDigsite().subscribe((response: boolean) => {
      this.progressBarService.stop();
      if (response) {
        this.messaging$.sendToMessageStream(
          new ComponentMessage({
            message: digsiteMessages.UPLOAD_DRAWING_COMPLETE,
            senderID: this.className,
          })
        );
      } else {
        this.messaging$.sendToMessageStream(
          new ComponentMessage({
            message: digsiteMessages.UPLOAD_DRAWING_FAILED,
            senderID: this.className,
          })
        );
      }
    });
  }

  updateTicket() {
    this.progressBarService.start();

    // get data from view component then upload to IDB
    let digsiteDetails = this.digsiteViewComponent.getMyMessage();

    this.digsite$
      .updateTicketData(
        new ActionMessage(Actions.UPDATE_DIGSITE_DETAILS, {
          formType: CreateTicketFormTypes.digsite,
          value: digsiteDetails,
        })
      )
      .subscribe((successfulSave: boolean) => {
        this.progressBarService.stop();

        // send save result to child components
        if (successfulSave) {
          this.messaging$.sendToMessageStream(
            new ComponentMessage({
              message: digsiteMessages.UPDATE_DRAWING_COMPLETE,
              senderID: this.className,
            })
          );
        } else {
          this.messaging$.sendToMessageStream(
            new ComponentMessage({
              message: digsiteMessages.UPDATE_DRAWING_FAILED,
              senderID: this.className,
            })
          );
        }
      });
  }

  getDigsites() {
    // get existing shapes from server
    this.digsite$.gatherDigsites().subscribe((response: any) => {
      if (response.value) {
        // update props with shapes if any
        let existingShapes = JSON.parse(response.value);
        this.childProps["existingShapes"] = existingShapes;
        this.childProps = { ...this.childProps };
      }
      this.progressBarService.stop();
    });
  }
}
