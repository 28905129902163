<div
  *ngIf="isLoaded"
  style="overflow-x: hidden"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div
    style="overflow-y: auto; padding-bottom: 72px; width: 100%"
    (swiperight)="onSwipe($event)"
    (swipeleft)="onSwipe($event)"
  >
    <ng-container
      *ngFor="let formProp of TicketDetailFormProps | keyvalue"
      style="width: 100%"
    >
      <app-form-input-template
        [view]="formProp.value.view"
        [views]="formProp.value"
        [navLinks]="navLinks"
        [callType]="CallTypeID"
        (EmailChange)="onEmailChange($event)"
        (InputChange)="onInputChange($event)"
      >
      </app-form-input-template>
    </ng-container>
  </div>
</div>

<floating-action-menu
  [hidden]="bottomMenuOpen"
  [menuItems]="fabMenu"
  type="fab"
>
</floating-action-menu>
