import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { UtilocateApiRequest } from "src/app/modules/core/api/baseapi.service";
import { UtilocateApiService } from "src/app/modules/core/api/utilocateapi.service";

@Injectable({
  providedIn: "root",
})
export class ServerDocumentService {
  constructor(private utilocateApiService: UtilocateApiService) {}

  async downloadDocumentFromServer(assignmentID: string, documentID: string) {
    const url = apiKeys.u2.retrieveDocumentsS3;
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.retrieveDocumentsS3,
      API_TYPE: type,
      API_BODY: {
        AssignmentID: assignmentID,
      },
      API_URL_DATA_PARAMS: {
        DocumentID: documentID,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: HttpResponse<any> =
      await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
    if (result.ok) {
      return JSON.parse(result.body["value"]);
    } else {
      return new Error("Failed to get document from server");
    }
  }
}
