<div style="height: 100%" class="page-background">
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    class="create-ticket-dialog-top-bar"
  >
    <button mat-icon-button (click)="onClose()" style="color: white">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div style="height: calc(100% - 56px); margin: 8px; border-radius: 4px">
    <app-create-ticket-component
      (syncFinished)="onSyncFinished($event)"
      [props]="childProps"
    ></app-create-ticket-component>
  </div>
</div>
