import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Layer } from "../EsriMapModels";

@Component({
  selector: "app-esri-map-layers",
  templateUrl: "./esri-map-layers.component.html",
  styleUrls: ["./esri-map-layers.component.scss"],
})
export class EsriMapLayersComponent implements AfterViewInit {
  @Input() layers: Layer[];
  @Output() panelClose: EventEmitter<any> = new EventEmitter<any>();

  width: number;

  constructor() {}

  ngAfterViewInit() {
    this.width = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.width = window.innerWidth;
  }

  onCheckBoxChange(event, layer) {
    if (event.checked) {
      if (layer.layerContent != null) {
        layer.layerContent.visible = true;
        layer.isChecked = true;
        this.toggleSubLayers(layer, true);
      } else {
        // group
        this.toggleGroupOn(layer);
      }
    } else {
      if (layer.layerContent != null) {
        layer.layerContent.visible = false;
        layer.isChecked = false;
        this.toggleSubLayers(layer, false);
      } else {
        // group
        this.toggleGroupOff(layer);
      }
    }
  }

  toggleSubLayers(layer, isParentChecked) {
    if (layer.subLayers.length > 0) {
      for (let i = 0; i < layer.subLayers.length; i++) {
        layer.subLayers[i].isParentChecked = isParentChecked;
        this.toggleSubLayers(
          layer.subLayers[i],
          isParentChecked && layer.subLayers[i].isChecked
        );
      }
    }
  }

  toggleGroupOff(group: Layer) {
    group.subLayers.forEach((layer) => {
      layer.layerContent.visible = false;
      layer.isParentChecked = false;
      this.toggleSubLayers(layer, false);
    });
  }

  toggleGroupOn(group: Layer) {
    group.subLayers.forEach((layer) => {
      layer.layerContent.visible = layer.isChecked;
      layer.isParentChecked = true;
      this.toggleSubLayers(layer, true);
    });
  }
}
