import { NgModule } from '@angular/core';
import { FieldsideComponent } from './components/fieldside.component';
import { NavigationBarComponent } from './components/fieldside-navigation-bar/navigation-bar.component';
import { RegistrationModalComponent } from './components/registration-modal/registration-modal.component';
import { SelfAssignTicketListComponent } from './components/self-assign-ticket-list/self-assign-ticket-list.component';
import { FieldsideMapComponent } from './components/ticket-summary/ticket/(ID)/map/fieldside-map.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { TicketListModule } from '../../modules/shared/ticket-list/ticket-list.module';
import { MaterialModule } from '../../modules/shared/material.module';
import { FloatingActionMenuComponent } from '../../modules/shared/fab/floating-action-menu/floating-action-menu.component';
import { BaseModalModule } from '../../modules/shared/modals/base-modal.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TicketMapComponent } from 'src/app/shared/components/maps/open-layers/ticket-map/ticket-map.component';
import { VerticalSplitComponent } from 'src/app/shared/components/layouts/vertical-split/vertical-split.component';
import { TicketModule } from "../../modules/shared/ticket/fieldside-ticket.module";
import { TicketListMapComponent } from '../home/ticket-list-map/ticket-list-map.component';
import { FieldsideTicketListComponent } from './components/fieldside-ticket-list/fieldside-ticket-list.component';
import { DrawingMapComponent } from '../../modules/drawing-module/drawing-map/drawing-map.component';
import { FloatingTabSelectComponent } from "../../shared/components/misc/selection/floating-tab-select/floating-tab-select.component";

@NgModule({
    declarations: [
        FieldsideComponent,
        NavigationBarComponent,
        FieldsideTicketListComponent,
        RegistrationModalComponent,
        SelfAssignTicketListComponent,
        FieldsideMapComponent,
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        TicketListModule,
        MaterialModule,
        FloatingActionMenuComponent,
        BaseModalModule,
        FlexLayoutModule,
        DrawingMapComponent,
        TicketListMapComponent,
        TicketMapComponent,
        VerticalSplitComponent,
        TicketModule,
        FloatingTabSelectComponent
    ]
})
export class FieldsideModule {}
