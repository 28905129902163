<div
  class="w-full h-full pb-2 page-background"
  fxLayout="column"
  fxLayoutAlign="start center"
>

  <div
    class="overflow-auto pb-14 pr-4 w-full"
    fxLayout="row wrap"
    fxLayoutAlign="center start"
  >
    <app-excavator-contacts-card
      class="bg-white m-2 rounded shadow-md"
      fxFlex="100"
      #excavatorContacts
      [props]="excavatorContactProps"
      [multipleExcavatorContacts]="multipleExcavatorContacts"
    ></app-excavator-contacts-card>
    <app-form-input-template
      class="bg-white m-2 rounded shadow-md"
      [props]="formProps"
      fxFlex="100"
    ></app-form-input-template>
  </div>
</div>

<!-- FAB -->
<floating-action-menu
  *ngIf="TICKET_PROTECTION_MODE == false || isUtilityUser"
  [menuItems]="isU2TicketComplete ? completedFabMenu : fabMenu"
  type="fab"
>
</floating-action-menu>
