import { CommonModule } from "@angular/common";
import {
  NgModule,
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { GoogleMapsModule, GoogleMap } from "@angular/google-maps";
import { GraphicInfo } from "src/app/modules/core/cache/GraphicsInterface";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { digsiteMessages } from "src/app/modules/digsite/digsite.module";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { GoogleMapsShapeController } from "./google-maps-shape-controller";
import { googleMapsOptions, MarkerObject } from "./googleMapsOptions";

@Component({
  selector: "app-base-google-maps",
  template: `
    <google-map height="100%" width="100%" [zoom]="zoom" [center]="center">
    </google-map>
  `,
})
export class BaseGoogleMapsComponent implements OnInit {
  zoom = 19;
  center: google.maps.LatLngLiteral;
  className = "BaseGoogleMapsComponent";
  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }
}

@Component({
  selector: "app-google-maps-drawing",
  template: `
    <google-map
      height="100%"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="googleMapsOptions"
      (tilesloaded)="onMapReady()"
    >
      <map-marker
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="markerOptions"
      >
      </map-marker>
    </google-map>
  `,
})
export class GoogleMapsDrawingComponent implements OnInit, OnChanges {
  zoom = 19;
  center: google.maps.LatLngLiteral;
  className = "GoogleMapsDrawingComponent";

  @Input() googleMapsOptions: googleMapsOptions;
  @Input() props: any;
  @ViewChild(GoogleMap, { static: true }) map: GoogleMap;
  @Input() markers: MarkerObject[];
  @Input() curTool: number;
  @Input() viewOnly: boolean;
  @Input() graphics: GraphicInfo[];
  @Output() setBadgeVisibility: EventEmitter<any> = new EventEmitter();
  @Output() setTextAddress: EventEmitter<any> = new EventEmitter();
  @Output() locationPinned: EventEmitter<any> = new EventEmitter();

  drawingManager = null;
  gmShapeController: GoogleMapsShapeController = null;
  geocoder: any;

  mapLoaded: boolean = false;
  isDrawing: boolean = false;
  marker = null;

  label = {
    color: "red",
    text: "Marker label ",
  };

  markerOptions = {
    animation: google.maps.Animation.DROP,
  };

  constructor(
    private logger$: LoggerService,
    private snackbar$: SnackbarService
  ) {}

  ngOnInit() {
    if (this.props.TicketLatLng) {
      this.center = {
        lat: this.props.TicketLatLng.Latitude,
        lng: this.props.TicketLatLng.Longitude,
      };
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        (error) => {
          this.center = {
            lat: 43.533778,
            lng: -80.305128,
          };
        },
        { timeout: 3000 }
      );
    }
  }

  ngOnChanges(changes) {
    // console.log(changes);

    // Checking for changes to toolbar toggle buttons in digsite-view
    if (changes.curTool) {
      this.onToolChange();
    }
    if (
      changes.props &&
      changes.props.currentValue &&
      changes.props.currentValue.TicketLatLng
    ) {
      this.center = {
        lat: parseFloat(this.props.TicketLatLng.Latitude),
        lng: parseFloat(this.props.TicketLatLng.Longitude),
      };
    }
    if (
      changes.graphics &&
      changes.graphics.currentValue &&
      changes.graphics.currentValue.length > 0 &&
      this.mapLoaded
    ) {
      // console.log('got graphics');

      this.addGraphics();
    }
  }

  onMapReady() {
    if (this.mapLoaded) {
      return;
    }
    var self = this;
    google.maps.event.addListener(
      this.map.googleMap,
      "maptypeid_changed",
      function () {
        self.onMapTypeChanged();
      }
    );

    this.mapLoaded = true;

    this.gmShapeController = new GoogleMapsShapeController();
    this.geocoder = new google.maps.Geocoder();

    if (this.props && this.props["messageService"]) {
      this.props["messageService"].sendToMessageStream(
        new ComponentMessage({
          action: MessageAction.READY,
          message: digsiteMessages.ON_MAP_READY,
          senderID: this.className,
        })
      );
    }

    if (this.graphics && this.graphics.length > 0) {
      this.addGraphics();
    }

    this.gmShapeController
      .getUpdateShapeObservable()
      .subscribe((updateShapes: boolean) => {
        if (updateShapes) {
          this.saveShapes();
        }
      });

    this.map.googleMap.addListener("click", (mapsMouseEvent) => {
      if (this.curTool == 5) {
        // this.removeMarker();
        this.addMarker(mapsMouseEvent);
        let coords = {
          Latitude: this.getMarkerLatLng().lat(),
          Longitude: this.getMarkerLatLng().lng(),
        };
        // this.locationPinned.emit(coords);
        this.getMarkerAddressFromLatLng()
          .then((result) => {
            if (result) {
              this.snackbar$.openSnackbar("Pin marked to closest address");
              this.removeMarker();
              let response = {
                location: result,
                coordinates: coords,
              };
              this.setTextAddress.emit(response);
            }
          })
          .catch((error) => {
            this.logger$.warn(
              "getMarkerAddressFromLatLng: failed to find address from coords: ",
              error
            );
          });
      }
    });

    if (this.viewOnly) {
      this.turnDrawingOff();
    }
  }

  // Remove current marker if there is one
  removeMarker() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  onMapTypeChanged() {
    if (this.isDrawing) {
      this.logger$.log("turn on");
      this.turnDrawingOn();
    } else {
      this.logger$.log("turn off");
    }
    this.gmShapeController.refresh(this.map.googleMap, true);
  }

  saveShapes() {
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        action: MessageAction.READY,
        message: digsiteMessages.SAVE_SHAPES,
        senderID: this.className,
      })
    );
  }

  turnDrawingOn() {
    this.isDrawing = true;
    this.initDrawingManager(this.getDrawingManagerOptions(), true);
  }

  turnDrawingOff() {
    this.isDrawing = false;
    this.initDrawingManager(this.getDrawingManagerOptions());
    this.refreshShapes(false);
  }

  initDrawingManager(options: any, isEditing = false) {
    // this.gmPolygons.setPolygonEditMode(isEditing);
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
      this.drawingManager = null;
    }
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(this.map.googleMap);

    google.maps.event.clearListeners(this.map, "maptypeid_changed");
    google.maps.event.addListener(this.map, "maptypeid_changed", (event) => {
      // this.initDrawingManager(this.map, this.getDrawingManagerOptions(), this.isDrawing);
    });

    google.maps.event.clearListeners(this.drawingManager, "overlaycomplete");
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      (event) => {
        let shapeType = null;
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          shapeType = 3;
        } else if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
          shapeType = 2;
        } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
          shapeType = 1;
        }
        if (shapeType) {
          this.setBadgeVisibility.emit();
          this.gmShapeController.finishedDrawing(event, shapeType);
          this.saveShapes();
        }
      }
    );
  }

  getDrawingManagerOptions() {
    let returnOptions = {};

    if (this.isDrawing) {
      returnOptions = {
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.MARKER,
          ],
        },
        markerOptions: this.gmShapeController.getDrawingOptions(
          this.map,
          this.isDrawing,
          4
        ),
        polygonOptions: this.gmShapeController.getDrawingOptions(
          this.map,
          this.isDrawing,
          3
        ),
        polylineOptions: this.gmShapeController.getDrawingOptions(
          this.map,
          this.isDrawing,
          2
        ),
        circleOptions: this.gmShapeController.getDrawingOptions(
          this.map,
          this.isDrawing,
          1
        ),
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
      };
    } else {
      returnOptions = {
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: [],
        },
        drawingMode: null,
      };
    }
    return returnOptions;
  }

  getAddressFromLatLng(shapes) {
    return new Promise((resolve, reject) => {
      try {
        if (shapes && shapes[0] && shapes[0].center) {
          const handleGeocodeResponse = (results, status) => {
            if (status === "OK") {
              resolve(results[0].address_components);
            } else {
              resolve(false);
            }
          };
          this.geocoder.geocode(
            { location: shapes[0].center },
            handleGeocodeResponse
          );
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   *
   * @param location location has 2 functions lat and lng
   * @param zoom zoom level
   */
  // 20 : 1128.497220
  // 19 : 2256.994440
  // 18 : 4513.988880
  // 17 : 9027.977761
  // 16 : 18055.955520
  // 15 : 36111.911040
  // 14 : 72223.822090
  // 13 : 144447.644200
  // 12 : 288895.288400
  // 11 : 577790.576700
  // 10 : 1155581.153000
  // 9  : 2311162.307000
  // 8  : 4622324.614000
  // 7  : 9244649.227000
  // 6  : 18489298.450000
  // 5  : 36978596.910000
  // 4  : 73957193.820000
  // 3  : 147914387.600000
  // 2  : 295828775.300000
  // 1  : 591657550.500000
  centerByGoogleLocation(location, zoom = 19) {
    try {
      this.map.googleMap.setCenter(location);
      this.map.googleMap.setZoom(zoom);
    } catch (error) {
      this.logger$.error("Failed to centerByGoogleLocation");
    }
  }

  getLatLngFromAddress(address) {
    return new Promise((resolve, reject) => {
      try {
        if (address) {
          const handleGeocodeResponse = (results, status) => {
            if (status === "OK" && results && results.length > 0) {
              this.map.googleMap.setCenter(results[0].geometry.location);
              this.map.googleMap.setZoom(19);
              resolve({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              resolve(false);
            }
          };
          if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
          }
          this.geocoder.geocode(address, handleGeocodeResponse);
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  addGraphics() {
    for (let i in this.graphics) {
      let graphic = this.graphics[i];
      this.addShape(graphic.coordinates, graphic.type, graphic.graphicID);
    }
  }

  addShape(coords: any[], shapeType: number, digsiteShapeID: number) {
    this.gmShapeController.addShape(
      coords,
      shapeType,
      digsiteShapeID,
      this.map,
      this.isDrawing
    );
  }

  addLocalShape(coords: any[], shapeType: number) {
    this.gmShapeController.addLocalShape(
      coords,
      shapeType,
      this.map,
      this.isDrawing
    );
  }

  updateShapesAfterUpload() {
    this.gmShapeController.updateShapesAfterUpload();
  }

  zoomToPolygons() {
    let _shapes = this.gmShapeController.getShapes();
    if (_shapes && _shapes.length > 0) {
      let firstPolygonCenter = _shapes[0].center;

      this.map.googleMap.setCenter(firstPolygonCenter);
      this.map.googleMap.setZoom(19);
    }
  }

  clearDrawing(endIndex = 0) {
    this.gmShapeController.clear(endIndex);
    // this.map.googleMap.setZoom(13);
  }

  clearAllDrawings() {
    this.gmShapeController.clearAll();
  }

  getShapes(startIndex = 0) {
    return this.gmShapeController.getShapes(startIndex);
  }

  refreshShapes(isDrawing) {
    this.gmShapeController.refresh(this.map.googleMap, isDrawing);
  }

  // Sets the current drawing tool based on the digsite-view toggle button
  onToolChange() {
    if (this.curTool == 1) {
      this.drawingManager.setDrawingMode(null);
    } else if (this.curTool == 2) {
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYLINE
      );
    } else if (this.curTool == 3) {
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON
      );
    } else if (this.curTool == 4) {
      this.drawingManager.setDrawingMode(
        google.maps.drawing.OverlayType.CIRCLE
      );
    } else if (this.curTool == 5) {
      this.drawingManager.setDrawingMode(null);
    }
  }

  // Adds single marker
  addMarker(point) {
    if (point) {
      var coords;
      if (point.latLng) {
        coords = point.latLng;
      } else {
        coords = new google.maps.LatLng(point.lat, point.lng);
      }

      // Remove current marker if there is one
      if (this.marker) {
        this.marker.setMap(null);
      }

      // Create new marker
      this.marker = new google.maps.Marker({
        position: coords,
        animation: google.maps.Animation.DROP,
        // icon:'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
      });

      // Add marker to map
      this.marker.setMap(this.map.googleMap);
    }
  }

  // Returns lat lng coordinates of current marker on map
  getMarkerLatLng() {
    return this.marker.position;
  }

  getMarkerAddressFromLatLng(coords = this.getMarkerLatLng()) {
    return new Promise((resolve, reject) => {
      try {
        if (coords) {
          this.geocoder
            .geocode({ location: coords })
            .then((response) => {
              if (response.results[0]) {
                resolve(response.results[0].address_components);
              } else {
                resolve(false);
              }
            })
            .catch((e) => this.logger$.log("Geocoder failed due to: " + e));
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}

@NgModule({
  declarations: [BaseGoogleMapsComponent, GoogleMapsDrawingComponent],
  imports: [GoogleMapsModule, CommonModule],
  exports: [BaseGoogleMapsComponent, GoogleMapsDrawingComponent],
})
export class GoogleMapsSetModule {}
