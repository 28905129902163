/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import localforage from "localforage";
import { ErrorHandlingService } from "../services/error-handling/error-handling.service";

interface CacheService {
  Store: LocalForage;

  queryTableData(tablename: string, where: any): any;
  insertTableData(tablename: string, row: any): any;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface CacheResult {
  TableName: string;
  TableColumns: any[];
  TableData: any[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class CacheResult implements CacheResult {
  TableName = "";
  TableColumns: any[];
  TableData: any[];
}

export enum CacheWhereClauseType {
  STRING = 1,
  NUMBER,
  ARRAY,
}

export class CacheWhereClause {
  Column: string;
  Value: any;
  ValueType?: CacheWhereClauseType = 1;
}

export class AssignmentIDCacheResult {
  assigned: boolean;
  ticketChanged: boolean;
  insertTime: Date;
  tables: Table[];

  constructor(assigned, ticketChanged, insertTime, tables) {
    this.assigned = assigned;
    this.ticketChanged = ticketChanged;
    this.insertTime = insertTime;
    this.tables = tables;
  }

  getTableData(tableName: string): any[] {
    const table = this.tables.find((table) => table.name == tableName);
    return table.getData();
  }
  getTable(tableName: string): { Data: any[], Columns: any[] } {
    const table = this.tables.find((table) => table.name == tableName);
    if (!table.Data || table.Data === null) throw new Error(`${tableName} unable to get data`);
    return { Data: table.Data, Columns: table.Columns };
  }
}

export class Table {
  name: string;
  Columns: any[];
  Data: any[];

  constructor(name, columns, tables) {
    this.name = name;
    this.Columns = columns;
    this.Data = tables;
  }

  getData(): any[] {
    return this.Data;
  }
}

@Injectable({
  providedIn: "root",
}) // for injecting the errorHandlingService
export class BaseCacheService implements CacheService {
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) {
    localforage.config({
      driver: [localforage.INDEXEDDB],
    });
  }

  readonly ERROR_CACHE_QUERY_FALSEY = "Failed to find key in local database.";
  readonly ERROR_CACHE_QUERY_CONTENT_FALSEY =
    "Failed to read key's value in local database.";
  readonly ERROR_CACHE_COLUMNS_NOT_FOUND =
    "Failed to match requested columns with actual columns.";

    async queryTableData(
      tablename: string,
      whereClause: CacheWhereClause[] = [],
      withColumns: boolean = false,
      assignmentID?: string
    ): Promise<any | Error> {
      try {


        //if the cached where claus includes assignmentID, ignore it 
        for (const clause of whereClause) {
          if (clause.Column === "AssignmentID") {
            whereClause.splice(whereClause.indexOf(clause), 1);
            assignmentID = assignmentID ?? clause.Value;
          }
        }

        let cacheResult: CacheResult | Error;
    
        if (assignmentID !== undefined) {
          cacheResult = await this.queryTableByAssignmentID(assignmentID.toString(), tablename);
        } else {
          cacheResult = await this.query(tablename);
        }
    
        if (!(cacheResult instanceof Error) && cacheResult != null) {
          const tableData = cacheResult.TableData;
    
          const filteredResult: any[] = tableData.filter((row) => {
            return whereClause.every((where) => {
              if (where) {
                if (where.ValueType === CacheWhereClauseType.ARRAY) {
                  const rowValue = parseFloat(row[where.Column]);
                  const numericValues = where.Value.map((val) => parseFloat(val));
                  return numericValues.includes(rowValue);
                } else if (where.ValueType === CacheWhereClauseType.NUMBER) {
                  const rowValue = parseFloat(row[where.Column]);
                  const numericValue = parseFloat(where.Value);
                  return rowValue === numericValue;
                } else {
                  return row[where.Column]?.toString().toUpperCase().includes(where.Value.toString().toUpperCase());
                }
              }
              return true;
            });
          });
    
          if (withColumns) {
            return {
              Data: filteredResult,
              Columns: cacheResult.TableColumns,
            };
          } else {
            return filteredResult;
          }
        } else {
          throw cacheResult;
        }
      } catch (error) {
        return error;
      }
    }
    

  async getKey(key: string): Promise<AssignmentIDCacheResult> {
    return await this.Store.getItem(key);
  }

  async setKey(key: string, value: AssignmentIDCacheResult) {
    return await this.Store.setItem(key, value);
  }

  async listKeys() {
    return await this.Store.keys();
  }

  async removeKey(key: string) {
    return await this.Store.removeItem(key);
  }

  async clear() {
    await this.Store.clear();
  }


  async queryTableDataByColumns(
    tablename: string,
    columns: string[],
    where: CacheWhereClause[] = [],
    assignmentID: string
  ): Promise<any[] | Error> {
    // console.log(tablename, columns, where);
    const result = [];
    try {
      const tableData: any[] | Error = await this.queryTableData(
        tablename,
        where,
        false,
        assignmentID
      );
      if (!(tableData instanceof Error)) {
        for (let i = 0; i < tableData.length; i++) {
          const tableRow: any = tableData[i];

          const rowToReturn = {};
          for (let j = 0; j < columns.length; j++) {
            if (typeof tableRow[columns[j]] !== "undefined") {
              // column value found
              // add to row-value-array
              rowToReturn[columns[j]] = tableRow[columns[j]];
            }
          }

          if (Object.keys(rowToReturn).length > 0) {
            // add row-value-array to rows-value-array
            result.push(rowToReturn);
          }
        }

        return result;
      } else {
        throw tableData;
      }
    } catch (error) {
      return error;
    }
  }

  async insertTableData(
    tablename: string,
    rows: any[],
    columns: any[] = [],
    assignmentID?: string
  ): Promise<boolean | Error> {
    try {
      if (rows.length > 0) {
        const cacheResult: CacheResult | Error = await this.queryTableByAssignmentID(assignmentID, tablename);
        if (!(cacheResult instanceof Error)) {

          const tableData = [...rows];
          const newTable = {
            Data: tableData,
            Columns: columns ?? cacheResult.TableColumns,
            name: tablename,
          };

          await this.insertTable(tablename, newTable, assignmentID);
          return true;
        } else {
          throw cacheResult;
        }
      } else {
        return true;
      }
    } catch (error) {
      return error;
    }
  }

  async insertTableDataRow(
    tablename: string,
    row: any,
    assignmentID?: string
  ): Promise<boolean | Error> {
    try {
      let cacheResult: CacheResult | Error;

      if (assignmentID != null) {
        cacheResult = await this.queryTableByAssignmentID(assignmentID, tablename);
      } else {
        cacheResult = await this.query(tablename);
      }

      if (!(cacheResult instanceof Error)) {
        const tableData = [...cacheResult.TableData, row];

        const newTable = {
          Data: tableData,
          Columns: cacheResult.TableColumns,
          name: tablename,
        };

        await this.insertTable(tablename, newTable, assignmentID);
        return true;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  async removeTableDataRowHash(
    tablename: string,
    fileName: any,
    assignmentID: string
  ): Promise<boolean | Error> {
    try {
      let cacheResult: CacheResult | Error;

      if (assignmentID != null) {
        cacheResult = await this.queryTableByAssignmentID(assignmentID, tablename);
      } else {
        cacheResult = await this.query(tablename);
      }

      if (!(cacheResult instanceof Error)) {
        const tableData = [...cacheResult.TableData];

        const index = tableData.findIndex((obj) => obj.DocumentHash == fileName);
        if (index !== -1) {
          tableData.splice(index, 1); // Remove the object at index
          console.log("Object removed from the array");
        } else {
          console.log("Value not found in the array");
        }

        const newTable = {
          Data: tableData,
          Columns: cacheResult.TableColumns,
          name: tablename,
        };
        await this.insertTable(tablename, newTable, assignmentID);

        return true;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  async removeTableDataRowByWhereClaus(tableName, whereClaus, assignmentID?: string) {
    try {
      let cacheResult: CacheResult | Error;
      if (assignmentID != null) {
        cacheResult = await this.queryTableByAssignmentID(assignmentID, tableName);
      } else {
        cacheResult = await this.query(tableName);
      }

      if (!(cacheResult instanceof Error)) {
        const tableData = [...cacheResult.TableData];

        // Filter out rows based on whereClaus
        const filteredData = tableData.filter(row => {
          // Check if all conditions in whereClaus match
          return Object.keys(whereClaus).every(key => {
            return row[key] === whereClaus[key];
          });
        });

        // If any rows are filtered, remove them
        if (filteredData.length > 0) {
          filteredData.forEach(filteredRow => {
            const index = tableData.findIndex(row => row === filteredRow);
            if (index !== -1) {
              tableData.splice(index, 1);
              console.log("Row removed from the array");
            }
          });
        } else {
          console.log("No rows match the given conditions");
        }

        const newTable = {
          Data: tableData,
          Columns: cacheResult.TableColumns,
          name: tableName,
        };
        await this.insertTable(tableName, newTable, assignmentID);

        return true;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }


  async removeTableDataRow(
    tablename: string,
    documentID: any,
    assignmentID: string,
    isS3?: boolean,
  ): Promise<boolean | Error> {
    try {
      let cacheResult: CacheResult | Error;

      if (assignmentID != null) {
        cacheResult = await this.queryTableByAssignmentID(assignmentID, tablename);
      } else {
        cacheResult = await this.query(tablename);
      }

      if (!(cacheResult instanceof Error)) {
        const tableData = [...cacheResult.TableData];

        const index = isS3 ? tableData.findIndex((obj) => obj.S3DocumentID == documentID) : tableData.findIndex((obj) => obj.DocumentID == documentID)

        if (index !== -1) {
          tableData.splice(index, 1); // Remove the object at index
          console.log("Object removed from the array");
        } else {
          console.log("Value not found in the array");
        }

        const newTable = {
          Data: tableData,
          Columns: cacheResult.TableColumns,
          name: tablename,
        };
        await this.insertTable(tablename, newTable, assignmentID);

        return true;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  async removeTableDataRowByDocumentHash(
    tablename: string,
    documentHash: any,
    assignmentID: string
  ): Promise<boolean | Error> {
    try {
      const cacheResult: CacheResult | Error = await this.query(tablename);

      if (!(cacheResult instanceof Error)) {
        const tableData = [...cacheResult.TableData];

        const index = tableData.findIndex(obj => obj.DocumentHash == documentHash);
        if (index !== -1) {
          tableData.splice(index, 1); // Remove the object at index
        } else {
          console.warn('Value not found in the array');
        }

        const newTable = {
          Data: tableData,
          Columns: cacheResult.TableColumns,
          name: tablename,
        };
        await this.insertTable(tablename, newTable, assignmentID);

        return true;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  /**
   * Inserts a table to the IDB a specific assignmentID or for the entire cache
   * @param tableName
   * @param data
   * @param assignmentID
   * @returns
   */
  async insertTable(tableName: string, data: any, assignmentID?: string): Promise<boolean | Error> {
    try {
      let key = tableName;
      let newData = data;
      if (tableName && data) {

        //if we were given an assignmentID, the key is the assignmentID and the value is all the tables 
        if (assignmentID) {
          const result: AssignmentIDCacheResult = await this.Store.getItem(assignmentID);
          const tabeData: Table = result.tables.find((table) => table.name == tableName);
          tabeData.Data = data.Data;

          newData = result;
          key = assignmentID;
        }
        // make an object whose key is table.name and whose value is the other keys and values in table
        await this.insert(key, newData);
        return true;
      }
    } catch (error) {
      return error;
    }
  }

  async updateTableData(
    tablename: string,
    setClause: any,
    whereClause: CacheWhereClause[] = [],
    assignmentID: string
  ): Promise<boolean | Error> {
    try {

      const tableData: any[] | Error = await this.queryTableData(
        tablename,
        whereClause,
        false,
        assignmentID
      );

      if (!(tableData instanceof Error)) {
        // update each filtered row
        const columnsToUpdate = Object.keys(setClause);
        for (let i = 0; i < tableData.length; i++) {
          const row = tableData[i];

          for (let j = 0; j < columnsToUpdate.length; j++) {
            const column = columnsToUpdate[j];
            row[column] = setClause[column];
          }
        }

        // merge
        const filteredTable = await this.getTableDataWithout(
          tablename,
          whereClause,
          assignmentID
        );
        await this.insertTableData(tablename, [...filteredTable, ...tableData], [], assignmentID);
        return true;
      }
    } catch (error) {
      return error;
    }
  }

  async getTableDataWithout(
    tablename: string,
    whereClause: CacheWhereClause[],
    assignmentID: string
  ) {
    try {
      let cacheResult: CacheResult | Error;

      if (assignmentID != null) {
        cacheResult = await this.queryTableByAssignmentID(assignmentID, tablename);
      } else {
        cacheResult = await this.query(tablename);
      }
      if (!(cacheResult instanceof Error)) {
        const tableData = cacheResult.TableData;
        const filteredResult: any[] = tableData.filter((row) => {
          // verify WHERE NOT clause for each row
          for (let i = 0; i < whereClause.length; i++) {
            const where = whereClause[i];

            if (where.ValueType == CacheWhereClauseType.ARRAY) {
              if (!where.Value.includes(row[where.Column])) {
                return true; // row's column's value not found in array
              }
            } else {
              if (
                row[where.Column]
                  .toString()
                  .toUpperCase()
                  .indexOf(where.Value.toString().toUpperCase()) < 0
              ) {
                return true; // string values did not match
              }
            }
          }
          return false;
        });

        return filteredResult;
      } else {
        return cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  /**
   * Gets a table where the assignmentID is the key to the idb entry 
   * @param {string} assignmentID 
   * @param {string} tableName 
   * @returns {CacheResult}
   */
  private async queryTableByAssignmentID(assignmentID: string, tableName: string): Promise<CacheResult> {
    const cacheResult: CacheResult = new CacheResult();
    const assignmentObject: AssignmentIDCacheResult = await this.Store.getItem(assignmentID);

    //get the tables at the table name 
    if (!assignmentObject || !assignmentObject.tables) return;
    const table = assignmentObject.tables.find((table) => table.name == tableName);
    if (!table) return;
    cacheResult.TableColumns = table['Columns'];
    cacheResult.TableData = table['Data'];
    cacheResult.TableName = table['name'];

    return cacheResult;
  }

  private async query(key): Promise<CacheResult | Error> {
    const cacheResult: CacheResult = new CacheResult();
    try {
      const value: any = await this.Store.getItem(key);
      if (value) {
        if (value.Columns && value.Data) {
          cacheResult.TableName = key;
          cacheResult.TableColumns = value.Columns;
          cacheResult.TableData = value.Data;

          return cacheResult;
        } else {
          throw new Error(this.ERROR_CACHE_QUERY_CONTENT_FALSEY);
        }
      } else {
        cacheResult.TableName = key;
        cacheResult.TableColumns = [];
        cacheResult.TableData = [];
        return cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  private async insert(key, value) {
    try {
      await this.Store.setItem(key, value);
    } catch (error) {
      this.errorHandler.handleError(error);
      return error;
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class BaseDocumentsCacheService implements CacheService {
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) { }

  readonly ERROR_CACHE_QUERY_FALSEY = "Failed to find key in local database.";
  readonly ERROR_CACHE_QUERY_CONTENT_FALSEY =
    "Failed to read key's value in local database.";
  readonly ERROR_CACHE_COLUMNS_NOT_FOUND =
    "Failed to match requested columns with actual columns.";

  async queryTableData(assignmentID: string): Promise<any | Error> {
    try {
      const cacheResult: any = await this.Store.getItem(assignmentID);
      if (!(cacheResult instanceof Error)) {
        return cacheResult;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      console.log(assignmentID);
      return error;
    }
  }

  async insertTableData(key: string, table: any): Promise<boolean | Error> {
    try {
      if (key && table) {
        await this.Store.setItem(key, table);
        return true;
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      return error;
    }
  }

  async getAllKeys(): Promise<any> {
    try {
      const cacheResult: any = this.Store.keys();
      if (!(cacheResult instanceof Error)) {
        return cacheResult;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  async clear() {
    await this.Store.clear();
  }
}
@Injectable({
  providedIn: "root",
})
export class BaseS3DocumentsCacheService implements CacheService {
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) { }

  readonly ERROR_CACHE_QUERY_FALSEY = "Failed to find key in local database.";
  readonly ERROR_CACHE_QUERY_CONTENT_FALSEY =
    "Failed to read key's value in local database.";
  readonly ERROR_CACHE_COLUMNS_NOT_FOUND =
    "Failed to match requested columns with actual columns.";

  async queryTableData(assignmentID: string): Promise<any | Error> {
    try {
      const cacheResult: any = this.Store.getItem(assignmentID);
      if (!(cacheResult instanceof Error)) {
        return cacheResult;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      console.log(assignmentID);
      return error;
    }
  }

  async insertTableData(key: string, table: any): Promise<boolean | Error> {
    try {
      if (key && table) {
        await this.Store.setItem(key, table);
        return true;
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      return error;
    }
  }

  async getAllKeys(): Promise<any> {
    try {
      const cacheResult: any = this.Store.keys();
      if (!(cacheResult instanceof Error)) {
        return cacheResult;
      } else {
        throw cacheResult;
      }
    } catch (error) {
      return error;
    }
  }

  async clear() {
    await this.Store.clear();
  }
}
@Injectable({
  providedIn: "root",
})
export abstract class BaseAdminCacheService extends BaseCacheService {
  readonly INSTANCE_NAME = "admin";
  readonly STORE_NAME = "lookup";
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) {
    super(errorHandler);
    localforage.config({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    });

    this.Store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: this.INSTANCE_NAME,
      storeName: this.STORE_NAME,
    });
  }
}

@Injectable({
  providedIn: "root",
})
export abstract class BaseCompletionCacheService extends BaseCacheService {
  readonly INSTANCE_NAME = "fieldside-completions";
  readonly STORE_NAME = "assignments";
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) {
    super(errorHandler);
    localforage.config({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    });

    this.Store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: this.INSTANCE_NAME,
      storeName: this.STORE_NAME,
    });
  }
}

@Injectable({
  providedIn: "root",
})
export abstract class BaseDocumentCacheService extends BaseDocumentsCacheService {
  readonly INSTANCE_NAME = "documents";
  readonly STORE_NAME = "documents";
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) {
    super(errorHandler);
    localforage.config({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    });

    this.Store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: this.INSTANCE_NAME,
      storeName: this.STORE_NAME,
    });
  }
}

@Injectable({
  providedIn: "root",
})
export abstract class BaseS3DocumentCacheService extends BaseS3DocumentsCacheService {
  readonly INSTANCE_NAME = "documents";
  readonly STORE_NAME = "S3Documents";
  Store: LocalForage;

  constructor(public errorHandler: ErrorHandlingService) {
    super(errorHandler);
    localforage.config({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
    });

    this.Store = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: this.INSTANCE_NAME,
      storeName: this.STORE_NAME,
    });
  }
}
