import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AutomatedEngineModule } from '../automated-engine/automated-engine.module';
import { RouterModule } from '@angular/router';
import { UserCategoryEditorListComponent } from './user-category-editor-list/user-category-editor-list.component';
import { UserCategoryEditorListItemComponent } from './user-category-editor-list-item/user-category-editor-list-item.component';
import { AppRoutingModule } from "src/app/app-routing.module";
import { BaseModalModule } from "../shared/modals/base-modal.module";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { MaterialModule } from '../shared/material.module';
import { TextFieldWithEditComponent } from '../shared/text-field-edit/text-field-edit.component';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { EditSettingsComponent } from './settings-group/edit-settings.component';

@NgModule({
  declarations: [
    UserCategoryEditorListComponent,
    UserCategoryEditorListItemComponent,
  ],
  imports: [
    PageNavigationModule,
    BaseModalModule,
    AppRoutingModule,
    RouterModule,
    AutomatedEngineModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MaterialModule,
    TextFieldWithEditComponent,
    FormsModule,
    MatDialogModule,
    EditSettingsComponent
  ]
})
export class UserCategoryEditorModule { }
