import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { Setting, SettingID } from "src/app/modules/core/services/user/setting";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { isVaildEmail } from "src/app/modules/core/validators/validator";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { UserManagerService } from "../../user-manager.service";
import { UserEditor } from "../user-manager-editor.module";
import { GlobalSetting } from "src/app/modules/user-category-editor/user-category-models";
import { UserCategoryEditorService } from "src/app/modules/user-category-editor/user-category-editor.service";

@Component({
  selector: "app-user-manager-editor",
  templateUrl: "./user-manager-editor.component.html",
  styleUrls: ["./user-manager-editor.component.scss"],
})
export class UserManagerEditorComponent implements OnInit, OnChanges {
  @Input() user: UserEditor;
  @Input() supervisors: any[];
  @Input() userCategories: any[];
  @Input() cloudUserCategories: any[];
  @Output() cancel = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<boolean>();
  @ViewChild("myForm") myForm: NgForm;

  updatedUser: UserEditor;

  // eslint-disable-next-line @typescript-eslint/ban-types
  currentSettings: {} = {};

  routingAlgorithms = [];
  accessibleSettings = [];
  availableSettings = [];
  canModifyPasswords: any = true;
  saving: boolean = false;
  hidePassword: boolean = true;

  phoneRegex = new RegExp("^[0-9]{10,10}$");

  resettingPassword = false;
  tempPassword = "";
  emailPassword = false;

  userCentralizationEnabled: boolean = false;
  needsCloudAssociation: boolean = false;

  invalidMessage: string = "";

  globalSettings: GlobalSetting[] = [];
  userSettingsToShow: { settingID: number, active: number, value: any }[] = [];
  settingsEditState: boolean = false;

  clientUsersValid: boolean = true;

  constructor(
    private userManager$: UserManagerService,
    private snackBarService: SnackbarService,
    private userService: UserService,
    private logger: LoggerService,
    private userCategoryEditorService: UserCategoryEditorService
  ) { }

  async ngOnInit() {
    this.user = {
      UserID: 0,
      CloudUserID: 0,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      TwoWayNum: "",
      EmployeeID: 0,
      UserCategoryID: 8,
      Supervisor: 0,
      TruckNum: "",
      FirstLog: 0,
      Password: "",
      Archived: 0,
      HexColour: "",
      ClientUsers: [
        {
          UserID: 0,
          CloudUserID: 0,
          FirstName: "",
          LastName: "",
          EmailAddress: "",
          TwoWayNum: "",
          EmployeeID: 0,
          UserCategoryID: 1,
          Supervisor: 0,
          TruckNum: "",
          FirstLog: 0,
          Password: "",
          Archived: 0,
          HexColour: "",
        },
      ],
    };

    await this.getRoutingAlgorithms();
    await this.setupUserPermissions();
    const allSettings = await this.userCategoryEditorService.getTables(); // Trigger the initial data fetch
    this.globalSettings = allSettings.GlobalSettings;
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.updatedUser = { ...this.user };
      this.hidePassword = true;
      this.setupSettings();

      if (this.updatedUser.Settings) {
        this.userSettingsToShow = [];
        for (let i = 0; i < this.updatedUser.Settings.length; i++) {

          //convert types
          const setting = this.updatedUser.Settings[i];
          this.userSettingsToShow.push({
            settingID: setting.SettingID,
            active: setting.Active ? 1 : 0,
            value: setting.Value
          })
        }
      } else {
        this.userSettingsToShow = [];
      }

    }
  }

  /**
   * Returns a list of the routing algorithms
   */
  async getRoutingAlgorithms() {
    this.routingAlgorithms = await this.userManager$.getAlgorithms();
  }

  /**
   * Sets up edit permissions based on this user 
   */
  async setupUserPermissions() {
    try {
      this.accessibleSettings = await this.userManager$.getAccessibleSettings(
        this.userService.user.categoryID,
      );
      this.canModifyPasswords = await this.userService.isSettingActive(
        SettingID.MODIFY_PASSWORD,
      );

      this.currentSettings[SettingID.RENEGOTIATE_DATE] =
        await this.userService.isSettingActive(SettingID.RENEGOTIATE_DATE);
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * Sets needsCloudAssociation
   */
  async setupSettings() {
    if (this.userService.isSettingActive(SettingID.USER_CENTRALIZATION)) {
      this.userCentralizationEnabled = true;
      if (this.updatedUser.UserID != 0 && this.updatedUser.CloudUserID == 0) {
        this.needsCloudAssociation = true;
      } else {
        this.needsCloudAssociation = false;
      }
    }
  }

  async userCategoryChanged(event) {
    const editableSettings = await this.userManager$.getEditableSettings(
      event.value,
    );
    this.availableSettings = this.accessibleSettings.filter((value) =>
      editableSettings.includes(value),
    );
  }

  convertSettingToPayload(inputUser) {
    this.myForm.control.markAsUntouched();
    const settingsToUpdate = [];

    //if this user has settings, format them to match what the user manager needs 
    if (inputUser.Settings) {
      for (let i = 0; i < inputUser.Settings.length; i++) {
        const setting = inputUser.Settings[i];

        if (!setting.settingCategoryID || setting.settingCategoryID === inputUser.UserCategoryID) {
          settingsToUpdate.push({
            SettingID: setting.SettingID,
            ActiveOverride: setting.Active ? 1 : 0,
            ValueOverride: setting.SettingID === SettingID.TICKET_ROUTING_CLUSTER_SIZE ? setting.Value / 2 : setting.Value,
            SettingCategoryID: setting.SettingCategoryID,
          });
        }
      }

    }

    //if this user has other u2 users associated to it, update those user's settings 
    if (inputUser.ClientUsers && inputUser.ClientUsers.length > 0) {
      for (let i = 0; i < inputUser.ClientUsers.length; i++) {
        inputUser.ClientUsers[i] = {
          ...inputUser.ClientUsers[i],
          Settings: this.convertSettingToPayload(inputUser.ClientUsers[i]),
        };
      }
    }
    return settingsToUpdate;
  }

  isValid(): boolean {
    let isValid = true;
    // check required fields
    if (!this.updatedUser.FirstName) {
      isValid = false;
    }

    if (!this.updatedUser.LastName) {
      isValid = false;
    }

    if (
      this.updatedUser.TwoWayNum &&
      !this.phoneRegex.test(this.updatedUser.TwoWayNum)
    ) {
      isValid = false;
    }

    if (this.resettingPassword) {
      if (this.tempPassword == "" || this.userManager$.validatePassword(this.tempPassword) == false) {
        this.snackBarService.openSnackbar("Passwords must consist of numbers and letters, capital and lowercase, and must be at least 8 characters long", SnackbarType.error);
        isValid = false;
      }
    }

    if (this.user.UserID == -1 && this.tempPassword == "") {
      // isValid = false;
      this.tempPassword = "password";
    }

    // if email entered get format
    if (!isVaildEmail(this.updatedUser.EmailAddress)) {
      isValid = false;
    }

    if (this.userCentralizationEnabled) {
      if (
        !this.isSettingsUserValid(this.updatedUser.ClientUsers) &&
        !this.needsCloudAssociation
      ) {
        isValid = false;
      }

      if (!this.clientUsersValid) {
        isValid = false;
        this.snackBarService.openSnackbar("Passwords must consist of numbers and letters, capital and lowercase, and must be at least 8 characters long", SnackbarType.error);
      }
    }

    this.myForm.control.markAllAsTouched();
    return isValid;
  }

  // Button Actions

  async onSaveClick() {
    if (this.isValid()) {
      this.settingsEditState = false;
      this.saving = true;

      const userToSave = {
        ...this.updatedUser,
        Settings: this.convertSettingToPayload(this.updatedUser),
      };

      let result: any;
      let message: string;

      if (this.resettingPassword || this.user.UserID == -1) {
        userToSave.FirstLog = 1;
        userToSave.Password = this.tempPassword;
        if (this.emailPassword) {
          //TODO email password
        }
        this.resettingPassword = false;
      }

      if (this.userCentralizationEnabled && this.needsCloudAssociation) {
        message = "Associate";
        result = await this.userManager$.associateUser(userToSave).toPromise();
      } else if (this.user.UserID == -1) {
        message = "Create";
        result = await this.userManager$.createUser(userToSave).toPromise();
      } else {
        message = "Save";
        result = await this.userManager$.updateUser(userToSave).toPromise();
      }

      if (result && result.body) {
        if (result.body.Success) {
          this.snackBarService.openSnackbar(
            "User " + message + "d",
            SnackbarType.success,
          );
          this.save.emit(true);
        } else if (result.body.FailMessage) {
          this.snackBarService.openSnackbar(
            result.body.FailMessage,
            SnackbarType.error,
          );
        } else {
          this.snackBarService.openSnackbar(
            "Failed to " + message + " User",
            SnackbarType.error,
          );
        }
      }
      this.tempPassword = "";
      this.saving = false;
    } else {
      if (this.invalidMessage) {
        this.snackBarService.openSnackbar(
          this.invalidMessage,
          SnackbarType.error,
        );
      }
    }
  }

  updateUserColour(newColour) {
    console.log(newColour);
    this.updatedUser.HexColour = newColour;
  }

  isSettingsUserValid(clientUsers: any[]): boolean {
    let valid = true;
    let checkedCount = 0;
    for (const user of clientUsers) {
      if (user.isCloudSettingsUser == 1) {
        checkedCount = checkedCount + 1;
      }
    }
    if (checkedCount == 0) {
      valid = false;
      this.invalidMessage = "A U2 user must be selected for settings";
    } else if (checkedCount > 1) {
      valid = false;
      this.invalidMessage = "Only one U2 user may be selected for settings";
    }
    return valid;
  }

  onCancelClick() {
    this.resettingPassword = false;
    this.tempPassword = "";
    this.cancel.emit(true);
  }

  onResetPasswordClick() {
    this.resettingPassword = true;
    this.tempPassword = this.generateRandomPassword();
  }

  generateRandomPassword() {
    let password = "";
    const upperCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCharacters = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const allCharacters = `${upperCharacters}${lowerCharacters}${numbers}`;

    let upper = upperCharacters.charAt(Math.floor(Math.random() * upperCharacters.length));
    let lower = lowerCharacters.charAt(Math.floor(Math.random() * lowerCharacters.length));
    let number = numbers.charAt(Math.floor(Math.random() * numbers.length));

    const length = allCharacters.length;
    for (let i = 0; i < 5; i++) {
      password = password + allCharacters.charAt(Math.floor(Math.random() * length));
    }
    password = `${password}${upper}${lower}${number}`;

    return password;
  }

  // Functions for slide real ime value updates

  // functions for client user changes
  onUpdateClientUser(event) {
    const index = this.updatedUser.ClientUsers.findIndex(
      (user) => user.UserID == event.UserID,
    );
    if (index > -1) {
      const tmpUser = this.updatedUser.ClientUsers[index];
      tmpUser.UserCategoryID = event.UserCategoryID;
      tmpUser.TruckNum = event.TruckNum;
      tmpUser.Supervisor = event.Supervisor;
      tmpUser.isCloudSettingsUser = event.isCloudSettingsUser;
      tmpUser.Archived = event.Archived;
      tmpUser.Settings = event.Settings;
      tmpUser.HexColour = event.HexColour;
      if (event.Password) {
        if (this.userManager$.validatePassword(event.Password)) {
          tmpUser.FirstLog = 1;
          tmpUser.Password = event.Password;
          this.clientUsersValid = true;
        } else { 
          this.clientUsersValid = false;
        }
      }

      //TODO settings boolean
      this.updatedUser.ClientUsers[index] = tmpUser;
    }
  }

  updateUserSettings(event) {
    this.updatedUser.Settings = [];

    for (const setting of event) {
      this.updatedUser.Settings.push(new Setting(setting.settingID, setting.active === 1, setting.value));
    }
  }
}
