// @ts-nocheck
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { LoggerService } from "../../core/services/logger/logger.service";
import { ModalService } from "../../shared/modals/modal.service";
import { DocumentViewerService } from "../document-viewer.service";
import { pdfOptions } from "../pdfOptions";
import { Subject } from "rxjs";

enum SIGN_AND_VIEW_STAGES {
  Disclaimer = 1,
  Signature = 2,
  PDF = 3,
}

export enum DocumentViewerTypeID {
  basic_viewer = 0,
  transnorthern_signature = 1,
  view_sign_email = 2,
  sign_and_view = 3,
}
export abstract class DocumentViewer {
  public documentID;
  public assignmentID;
  public documentToAngularID;
}

@Component({
  selector: "app-document-viewer",
  templateUrl: "./document-viewer.component.html",
  styleUrls: ["./document-viewer.component.scss"],
})
export class DocumentViewerComponent implements OnInit {
  @Input() documentID;
  @Input() assignmentID;
  @Input() documentToAngularID;
  @Input() UseSessionStorage = true;

  @ViewChild("DisclaimerElement", { static: false }) DisclaimerElement: any;

  modalOpened$ = new Subject<boolean>();

  SIGN_AND_VIEW_STAGES = SIGN_AND_VIEW_STAGES;
  loaded = false;
  pdfBlob: any;
  pdfName: any;
  acknowledged = false;
  LastName: string = "";
  FirstName: string = "";
  rowDisclaimer: string = "";
  logo = {};
  signAndViewStage: SIGN_AND_VIEW_STAGES;

  SIGNATURE_MODAL_ID = "signature-modal-id";
  SUCCESS_MODAL_ID = "success-modal-id";

  options: pdfOptions = {
    isBase64: false,
    data: "/assets/pdf/SignatureAccessTroubleshootManual.pdf",
    showSidebarButton: false,
  };

  documentViewerTypeID: DocumentViewerTypeID =
    DocumentViewerTypeID.basic_viewer;

  constructor(
    private logger: LoggerService,
    private documentViewerService: DocumentViewerService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {}

  /**
   * store url parameters, navigate to updated path, call setupDocumentViewerPage
   */
  ngOnInit(): void {
    const queryParams: any = this.route.snapshot.queryParams;
    const URL_KEYS = localStorageKeys.URL_KEYS;
    const VISIBLE_URL_KEYS = localStorageKeys.VISIBLE_URL_KEYS;

    if (queryParams["AssignmentID"]) {
      this.assignmentID = queryParams["AssignmentID"];
      sessionStorage.setItem(
        URL_KEYS.assignmentid,
        queryParams["AssignmentID"],
      );
    } else {
      this.assignmentID = sessionStorage.getItem(URL_KEYS.assignmentid);
    }

    if (queryParams["DocumentID"]) {
      this.documentID = queryParams["DocumentID"];
      sessionStorage.setItem(URL_KEYS.documentid, queryParams["DocumentID"]);
    } else {
      this.documentID = sessionStorage.getItem(URL_KEYS.primaryid);
    }

    if (queryParams["DocumentToAngularID"]) {
      this.documentToAngularID = queryParams["DocumentToAngularID"];
      sessionStorage.setItem(
        VISIBLE_URL_KEYS.documenttoangularid,
        queryParams["DocumentToAngularID"],
      );
    } else {
      this.documentToAngularID = sessionStorage.getItem(
        URL_KEYS.documenttoangularid,
      );
    }

    let newQueryParams = this.cleanURLQueryParams();
    this.router.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });

    // if (this.UseSessionStorage) {
    //   this.populateFromSessionStorage();
    // }
    this.setupDocumentViewerPage();
  }

  /**
   * update this.DisclaimerElement
   */
  ngAfterContentChecked() {
    if (this.DisclaimerElement && this.rowDisclaimer) {
      this.DisclaimerElement.nativeElement.innerHTML = this.rowDisclaimer;
      this.rowDisclaimer = null;
    }
  }

  /**
   * set local class variables to data from session storage
   */
  private populateFromSessionStorage(): void {
    try {
      this.documentID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.documentid,
      );
      this.assignmentID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.assignmentid,
      );
      this.documentToAngularID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.documenttoangularid,
      );
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * get documentToAngularRow, call updateViews,
   * set class variables to row data, call setupDocumentViewerType
   */
  private async setupDocumentViewerPage() {
    try {
      let row = await this.documentViewerService.getDocumentToAngularRowByID(
        this.documentToAngularID,
      );
      console.log("row: ", row);
      await this.updateViews();
      this.documentViewerTypeID = row["DocumentViewerTypeID"];
      if (row["Acknowledged"] && parseInt(row["Acknowledged"]) > 0) {
        this.acknowledged = true;
        this.LastName = row["LastName"];
        this.FirstName = row["FirstName"];
      }
      if (row["Disclaimer"]) {
        this.rowDisclaimer = row["Disclaimer"];
      }
      if (row["Logo"]) {
        this.logo = row["Logo"]["data"];
      }
      await this.setupDocumentViewerType();
    } catch (error) {
      this.handleError(error);
    }
    this.loaded = true;
  }

  /**
   * call document viewer service updateviews function
   */
  private async updateViews() {
    try {
      await this.documentViewerService.updateViews();
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * call bypassSecurityTrustUrl
   * @param url trusted url
   * @returns result of sanitizer function
   */
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   *
   * @param buffer buffer to convert
   * @returns base64 value from converted buffer
   */
  arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  /**
   * call a setup function based on viewer type id
   */
  private async setupDocumentViewerType() {
    try {
      console.log("viewer type: ", this.documentViewerTypeID);
      if (this.documentViewerTypeID == DocumentViewerTypeID.basic_viewer) {
        await this.setupBasicViewer();
      } else if (
        this.documentViewerTypeID ==
        DocumentViewerTypeID.transnorthern_signature
      ) {
        await this.setupTransnorthernSig();
      } else if (
        this.documentViewerTypeID == DocumentViewerTypeID.view_sign_email
      ) {
        await this.setupViewSignEmail();
      } else if (
        this.documentViewerTypeID == DocumentViewerTypeID.sign_and_view
      ) {
        await this.setupSignAndView();
      } else {
        //TODO
        this.documentViewerTypeID = -1;
        this.logger.info("reach");
        this.handleError("Unable to gather doc viewer type");
      }
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * get the document and set this.options
   */
  async setupBasicViewer() {
    try {
      //get document
      await this.getPDF();
      //load component
      this.options = {
        isBase64: false,
        data: this.pdfBlob,
        showSidebarButton: true,
      };
      this.logger.info(this.options);
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * just a log, the html will handle the rest
   */
  async setupTransnorthernSig() {
    try {
      this.logger.log("opening tn sign and view");
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * similar to setup basic view, get doc and set this.options
   */
  async setupViewSignEmail() {
    try {
      this.logger.log("setupViewSignEmail");
      //get document
      await this.getPDF();
      this.options = {
        isBase64: false,
        data: this.pdfBlob,
        showSidebarButton: true,
      };
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * similar to basic view but with also set value of signAndViewStage
   */
  async setupSignAndView() {
    try {
      this.logger.log("setupSignAndView");
      await this.getPDF();
      this.options = {
        isBase64: false,
        data: this.pdfBlob,
        showSidebarButton: true,
        height: "100%",
      };
      if (this.acknowledged) {
        this.signAndViewStage = SIGN_AND_VIEW_STAGES.PDF;
      } else {
        this.signAndViewStage = SIGN_AND_VIEW_STAGES.Disclaimer;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * set signAndViewStage and acknowledged
   */
  signAndViewAcknowledged() {
    try {
      this.signAndViewStage = SIGN_AND_VIEW_STAGES.PDF;
      this.acknowledged = true;
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * open signature modal
   */
  signHereClick() {
    try {
      this.logger.log(this.modalService);
      this.modalService.open(this.SIGNATURE_MODAL_ID);
      this.modalOpened$.next(true);
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * open success modal and track who signed
   */
  signatureSuccess() {
    // console.log("signature success");
    let returnData = this.closeSignatureModal();
    this.openSuccessModal();
    this.acknowledged = true;

    if (returnData) {
      this.FirstName = returnData["FirstName"];
      this.LastName = returnData["LastName"];
    }
  }

  /**
   * open successModal
   */
  openSuccessModal() {
    this.modalService.open(this.SUCCESS_MODAL_ID);
  }

  /**
   *
   * @returns result of modalService.close
   */
  closeSignatureModal() {
    try {
      return this.modalService.close(this.SIGNATURE_MODAL_ID);
    } catch (error) {
      this.logger.error(error.message);
    }
  }

  /**
   * call service getPDF function and set class variables with data
   */
  async getPDF() {
    try {
      let result = await this.documentViewerService.getPDF();
      this.pdfBlob = result["data"];
      this.pdfName = result["filename"];
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * log an error using logger service, navigate to security troubleshoot page
   * @param error message to log
   */
  handleError(error) {
    this.logger.error(error);
    this.router.navigateByUrl("security-troubleshoot");
  }

  /**
   * format query parameters
   * @returns formatted parameters
   */
  private cleanURLQueryParams(): object {
    let queryParams: any = this.route.snapshot.queryParams;
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};

    try {
      let visisbleURLKeys: any =
        this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      let len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        let queryParamsKey = queryParamsKeys[i];
        if (
          visisbleURLKeys &&
          visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
        ) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }
      return newQueryParams;
    } catch (error) {
      console.log(error);
    }
    return newQueryParams;
  }
}
