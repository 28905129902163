{
  "views": {
    "ticketInfo": {
      "header": "Ticket Info",
      "showHeader": true,
      "key": "ticketInfo",
      "groups": {
        "ticketDetails": {
          "header": "Ticket Details",
          "key": "ticketDetails",
          "groupOrder": "1",
          "fields": {
            "LocateStatusID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Locate Status",
              "key": "LocateStatusID",
              "placeholder": "Locate Status",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "1",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "UpdateOf": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Update Of",
              "key": "UpdateOf",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "2",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "SubRequestNumber": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "WAP",
              "key": "SubRequestNumber",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "3",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "SubStatusID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "SubStatus",
              "key": "SubStatusID",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "4",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "LocateTypeID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Locate Type",
              "key": "LocateTypeID",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "5",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "CurrentLocatorID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Assigned To",
              "key": "CurrentLocatorID",
              "placeholder": "Unassigned",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "6",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "publicProperty": {
              "inputTypeID": "1",
              "appearance": "none",
              "label": "Public Property",
              "key": "bPublicProperty",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "7",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "privateProperty": {
              "inputTypeID": "1",
              "appearance": "none",
              "label": "Private Property",
              "key": "bPrivateProperty",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "8",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "ALPOption": {
              "inputTypeID": "1",
              "appearance": "none",
              "label": "ALPOption",
              "key": "ALPOption",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "9",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "CallTypeID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Call Type",
              "key": "CallTypeID",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "10",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            }
          }
        },
        "worksiteDetails": {
          "header": "Worksite Details",
          "key": "worksiteDetails",
          "groupOrder": "3",
          "fields": {
            "LocateSubDivision": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Community",
              "key": "LocateSubDivision",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "1",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "MapReference": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Map Ref",
              "key": "MapReference",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "2",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "UnitLot": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Unit/Lot",
              "key": "UnitLot",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "3",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "LocateCrossStreet": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Cross St",
              "key": "LocateCrossStreet",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "4",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "LocateSecondCrossStreet": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "2nd Cross St",
              "key": "LocateSecondCrossStreet",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "5",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "RegionID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "County",
              "key": "RegionDesc",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "6",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "Latitude": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Latitude",
              "key": "Latitude",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "7",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "Longitude": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Longitude",
              "key": "Longitude",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "8",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            }
          }
        },
        "requestDates": {
          "header": "Request Dates",
          "key": "requestDates",
          "groupOrder": "4",
          "fields": {
            "CallDate": {
              "inputTypeID": "10",
              "appearance": "none",
              "label": "Call Date",
              "key": "CallDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "1",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "ExcavationDate": {
              "inputTypeID": "10",
              "appearance": "standard",
              "label": "Excavation Date",
              "key": "ExcavationDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "2",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "TransmitDate": {
              "inputTypeID": "10",
              "appearance": "none",
              "label": "Transmit Date",
              "key": "TransmitDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "3",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "EntryDate": {
              "inputTypeID": "10",
              "appearance": "none",
              "label": "Entry Date",
              "key": "EntryDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "4",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "OriginalExcavationDate": {
              "inputTypeID": "10",
              "appearance": "none",
              "label": "Org. Excavation Date",
              "key": "OriginalExcavationDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "5",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "bRevisionDenied": {
              "inputTypeID": "1",
              "appearance": "standard",
              "label": "Excavator Denied Revision",
              "key": "bRevisionDenied",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "6",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "bSiteMeetRequired": {
              "inputTypeID": "1",
              "appearance": "standard",
              "label": "Site Meet Required",
              "key": "bSiteMeetRequired",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "7",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "AppointmentDate": {
              "inputTypeID": "10",
              "appearance": "standard",
              "label": "Appointment Date",
              "key": "AppointmentDate",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "8",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "AppointmentArea": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Appointment Area",
              "key": "AppointmentArea",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "9",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "MeetLocation": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Meet Location/Information",
              "key": "MeetLocation",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": false,
              "fieldOrder": "10",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            }
          }
        },
        "excavatorDetails": {
          "header": "Excavator Details",
          "key": "excavatorDetails",
          "groupOrder": "5",
          "fields": {
            "ExcavatorName": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Company",
              "key": "ExcavatorName",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "1",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbAdmin_Excavators",
              "options": {}
            },
            "CustomerID": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Cust.",
              "key": "CustomerID",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "2",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "CallerName": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Caller Name",
              "key": "CallerName",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "3",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "PhoneNumber": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Phone",
              "key": "PhoneNumber",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "4",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "AlternateContact": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Alt. Contact",
              "key": "AlternateContact",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "5",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "AlternativeNumber": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Alt #",
              "key": "AlternativeNumber",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "6",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "email": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Email",
              "key": "email",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "7",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "email2": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Secondary Email",
              "key": "email2",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": false,
              "fieldOrder": "8",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "ExcavatorAddress": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "Address",
              "key": "ExcavatorAddress",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "9",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbAdmin_Excavators",
              "options": {}
            },
            "ExcavatorCity": {
              "inputTypeID": "2",
              "appearance": "none",
              "label": "City",
              "key": "ExcavatorCity",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "10",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbAdmin_Excavators",
              "options": {}
            },
            "CellNumber": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Cell",
              "key": "CellNumber",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "11",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "FaxNumber": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Fax",
              "key": "FaxNumber",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "12",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "33",
              "width_lg": "33",
              "width_xl": "33",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            }
          }
        },
        "workDetails": {
          "header": "Work Details",
          "key": "workDetails",
          "groupOrder": "2",
          "fields": {
            "TypeOfWork": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Type of Work",
              "key": "TypeOfWork",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "1",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "DepthOfWork": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Depth of Work",
              "key": "DepthOfWork",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "2",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "50",
              "width_lg": "50",
              "width_xl": "50",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "WorkDoneFor": {
              "inputTypeID": "2",
              "appearance": "standard",
              "label": "Work Done For",
              "key": "WorkDoneFor",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "3",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "50",
              "width_md": "50",
              "width_lg": "50",
              "width_xl": "50",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "ExtentOfWork": {
              "inputTypeID": "18",
              "appearance": "standard",
              "label": "Extent of Work",
              "key": "ExtentOfWork",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "4",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "Remarks": {
              "inputTypeID": "28",
              "appearance": "standard",
              "label": "Remarks",
              "key": "Remarks",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": false,
              "fieldOrder": "5",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "DispatcherRemarks": {
              "inputTypeID": "18",
              "appearance": "standard",
              "label": "Internal Remarks",
              "key": "DispatcherRemarks",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": false,
              "fieldOrder": "6",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            },
            "TicketTags": {
              "inputTypeID": "12",
              "appearance": "standard",
              "label": "Ticket Tags",
              "key": "TicketTags",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": false,
              "fieldOrder": "7",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_AssignmentToTags",
              "options": {}
            },
            "UStatMemo": {
              "inputTypeID": "18",
              "appearance": "standard",
              "label": "Station Codes",
              "key": "UStatMemo",
              "placeholder": "",
              "matches": "",
              "isRequired": false,
              "isReadOnly": true,
              "fieldOrder": "8",
              "isVisible": true,
              "width_xs": "100",
              "width_sm": "100",
              "width_md": "100",
              "width_lg": "100",
              "width_xl": "100",
              "tableName": "tbCompletions_Assignments",
              "options": {}
            }
          }
        }
      }
    }
  }
}