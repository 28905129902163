import { EnglishCanadian } from "./en-CA";
import { French } from "./fr";

export const U4_LOCALE = {
  "en-CA": EnglishCanadian,
  "fr": French
}

export const enum ISO_LOCALE_LIST {
  "en-CA" = "en-CA",
  "fr" = "fr",
}