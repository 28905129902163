export class UploadProgress {
  body: any;
  ok: boolean;
  msg: string;
  ignoreStep: boolean;
  constructor(result: any, ok: boolean, msg: string, ignoreStep?: boolean) {
    this.body = result;
    this.ok = ok;
    this.msg = msg + "\n";
    this.ignoreStep = false;
    if (ignoreStep) {
      this.ignoreStep = ignoreStep;
    }
  }
}
