<div class="page" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-subheading-s">Emails</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <button class="circle-btn abandon-btn" matTooltip="Abandon" (click)="onMarkAbandoned()">
        <mat-icon class="icon">close</mat-icon>
      </button>
      <button class="circle-btn send-btn" matTooltip="Mark as Sent Manually" (click)="onMarkSentManually()">
        <mat-icon class="icon">done</mat-icon>
      </button>
      <button
        class="h-[36px] w-[83px] text-black bg-white font-rajdhani font-md uppercase font-semibold hover:cursor-pointer hover:bg-primary hover:text-white hover:border-none border border-solid border-black border-opacity-25 rounded"
        (click)="onResend()">
        Resend
      </button>

      <app-competers-search-bar
        class="h-9 md:w-96 lg:w-[600px]"
        (searchValueChange)="searchText = $event"></app-competers-search-bar>
    </div>
    <!-- Legend -->
    <!-- <div fxLayout='row' fxLayoutGap='24px'>
      <div *ngFor='let item of legendItems' fxLayout='row' fxLayoutAlign='end center' fxLayoutGap='16px'>
        <div class='legend-colour-sample' [style.background-color]="item.colour"></div>
        <span class="mat-subheading-xs">{{item.title}}</span>
      </div>
    </div> -->
  </div>
  <!-- Table -->
  <div class="flex flex-grow w-full h-full">
    @if (loadedData) {
      <app-tabulator-table
        class="h-full w-full"
        #tabulatorTable
        [tableData]="emails"
        [columns]="displayedColumns"
        (rowDoubleClick)="openTicket($event)"
        [searchString]="searchText"
        [showSelect]="true"
        [eagerLoading]="true"
        (selectedRowsChanged)="rowSelectionChanged($event)" />
    } @else if (failedToGatherEmails) {
      <div class="font-rajdhani uppercase font-semibold text-headline-5">Error loading
        emails
      </div>
    }
  </div>
</div>
