import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-text-field-edit',
  templateUrl: './text-field-edit.component.html',
  styleUrls: ['./text-field-edit.component.scss'],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  standalone: true,
})
export class TextFieldWithEditComponent implements OnInit, OnChanges {
  @Input() text: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() validate: ValidatorFn;
  @Input() leadingText: string = '';
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Output() textChange = new EventEmitter<string>();

  state = { readOnly: true, edit: false, warning: false };
  textFormControl: FormControl;
  originalText: string = '';
  validationError = '';

  /**
   * This ensures this component updates properly
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && !changes.text.firstChange) {
      this.textFormControl.setValue(this.text);
    }
  }

  ngOnInit(): void {
    this.originalText = this.text;
    const validators = [];

    //add validators if they aren't empty 
    this.validate ? validators.push(this.validate) : null;
    this.required ? validators.push(Validators.required) : null;

    this.textFormControl = new FormControl(this.text, validators);
    this.textFormControl.disable(); // Initially set to readOnly
    this.setState('readOnly');
  }

  toggleEdit() {
    this.setState(this.state.edit ? 'readOnly' : 'edit');
    this.updateErrorState();
    if (this.state.readOnly) {

      //only emit changes if the text has changed
      if (this.textFormControl.value != this.originalText) {
        this.textChange.emit(this.textFormControl.value);
      }
    }
  }


  /**
   * Saves form 
   */
  saveEdit() {
    this.updateErrorState();
    if (!this.state.warning) {
      this.toggleEdit();
    }
  }

  /**
   * validates form 
   */
  updateErrorState() {
    // Reset error state
    this.validationError = '';

    // Check for validation errors
    if (this.textFormControl.invalid && this.textFormControl.dirty) {
      const errors = this.textFormControl.errors;
      if (errors) {
        // Handle validation errors
        this.setState('warning');
        this.validationError = errors.empty ? 'Required' : 'Invalid input';
      } else {
        this.setState('edit');
      }
    }
  }

  /**
   * if we're in warning state and we type, go to edit. 
   * keep in edit state while typing 
   *  
  */
  handleInputChange() {
    this.setState('edit');
  }

  setState(state: string) {
    this.state = {
      readOnly: state === 'readOnly',
      edit: state === 'edit',
      warning: state === 'warning'
    };
    if (this.state.readOnly) {
      this.textFormControl.disable();
    } else {
      this.textFormControl.enable();
    }
  }
}
