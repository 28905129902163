import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { TicketProtectionOverrideDialogComponent } from "./ticket-protection-override-dialog.component";

@NgModule({
  declarations: [TicketProtectionOverrideDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, FlexLayoutModule],
  exports: [TicketProtectionOverrideDialogComponent],
})
export class TicketProtectionOverrideDialogModule {}
