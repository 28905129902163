import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SplitCamelCase",
})
export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string) {
    if (typeof value !== "string") {
      return value;
    }
    if (value === "SentToLSP") {
      return "Sent To LSP";
  }
    value = value.split(/(?=[A-Z])/).join(" ");
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
}
