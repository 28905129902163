import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dbOptionToSelectionOption',
  standalone: true,
})
export class DbOptionToSelectionOptionPipe implements PipeTransform {
  transform(value: any[]): unknown {
    return value.reduce((acc, x) => {
      if (x.id !== null && x.id !== undefined) {
        const { id, ...rest } = x;
        acc.push({
          value: x.id,
          name: (() => {
            const keys = Object.keys(rest);
            if (keys.length === 1 && typeof rest[keys[0]] === 'string') {
              return rest[keys[0]];
            } else {
              return '';
            }
          })(),
        });
      }
      return acc;
    }, []);
  }
}
