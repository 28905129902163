import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SchedulerCancelTicketDialogComponent } from "./scheduler-cancel-ticket-dialog.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [SchedulerCancelTicketDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, FlexLayoutModule],
  exports: [SchedulerCancelTicketDialogComponent],
})
export class SchedulerCancelTicketDialogModule {}
