import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { loadModules } from "esri-loader";

@Component({
  selector: "app-esri-map-legend",
  templateUrl: "./esri-map-legend.component.html",
  styleUrls: ["./esri-map-legend.component.scss"],
})
export class EsriMapLegendComponent implements OnChanges {
  @Input() mapView: any;
  @Output() panelClose: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("legendPanel") legendPanel;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapView && !changes.mapView.firstChange) {
      this.populateLegend(this.mapView);
    }
  }

  async populateLegend(mapView) {
    const [Legend] = await loadModules(["esri/widgets/Legend"]);

    new Legend({
      view: mapView,
      basemapLegendVisible: false,
      container: this.legendPanel.nativeElement,
      respectLayerVisibility: false,
    });
  }
}
