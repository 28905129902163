import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-row-editor",
  templateUrl: "./row-editor.component.html",
  styleUrls: ["./row-editor.component.scss"],
})
export class RowEditorComponent implements OnChanges {
  @Input() columns: any[] = [];
  @Input() dataRow: any = {};

  @Output() cancel = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<any>();

  @ViewChild("myForm") myForm: NgForm;

  dataCopy: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataRow) {
      this.dataCopy = { ...this.dataRow };
    }
  }

  onCancelClick() {
    this.cancel.emit(true);
  }

  onSaveClick() {
    this.myForm.control.markAllAsTouched();
    if (!this.myForm.invalid) {
      this.save.emit(this.myForm.value);
    }
  }
}
