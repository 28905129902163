<ng-template [ngIf]="isLoaded">
  <form [formGroup]="view.formGroup">
    <div
      #formContainer
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="form-main"
    >
      <!-- mat-subheading-2 default-header-2 -->
      <ng-container>
        <span
          *ngIf="view.showHeader !== false"
          [ngStyle]="!demoteHeader && { padding: '8px' }"
          [ngClass]=" demoteHeader === true ? 'text-lg font-rajdhani font-semibold uppercase m-0 pt-2 pl-2' : 
          'uppercase font-rajdhani text-headline-6 m-0 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-300'">
          {{ view.header }}</span
        >
      </ng-container>
      <ng-container *ngFor="let group of view.groups | keyvalue">
        <div
          *ngIf="group.key.indexOf('defaultGroup') == -1"
          fxFlexOrder="{{ group.value.groupOrder }}"
          class="form-container"
          (click)="onFormContainerClick(group.key)"
        >
          <div
            *ngIf="
              !readOnly && navLinks && navLinks.includes(group.key);
              else hiddenValueHeader
            "
          >
          <button
          mat-button
          type="button"
          class="w-full m-0 flex flex-row justify-between items-center"
          (click)="onHeaderClick(group.key, true)"
          >
            <span class="order-1 text-lg font-rajdhani font-semibold uppercase text-primary">{{ group.value.header }}</span>
            <mat-icon class="order-2" color="primary">keyboard_arrow_right</mat-icon>
          </button>
          
          </div>

          <ng-template #hiddenValueHeader>
            <ng-container
              *ngIf="
                !readOnly && group.value.hasHiddenValues;
                else defaultHeader
              "
            >

              <!-- Blue subhjeader such as "Customer Details" or "Work Site" that is clickable -->
              <button
                mat-button
                type="button"
                style="width: 100%"
                (click)="onEdit(group.value)"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="w-full text-right text-lg pt-2 pb-2"
                >
                  <span class="text-lg font-rajdhani font-semibold uppercase text-primary">{{ group.value.header }}</span>
                  <mat-icon color="primary">edit</mat-icon>
                </div>
              </button>
            </ng-container>
          </ng-template>

          <ng-template #defaultHeader>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="w-full text-lg"
            >
              <span class="text-lg font-rajdhani font-semibold uppercase text-primary px-2 pt-2 text-2xl"
                >{{ group.value.header
                }}<span
                  *ngIf="
                    group.value.fillStageID && group.value.fillStageID == 3
                  "
                  style="color: red"
                >
                  *
                </span>
              </span>
            </div>
          </ng-template>
          <div
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="grid"
            class="header-values"
          >
            <ng-container
              *ngIf="
                !group.value.fillStageID ||
                (group.value.fillStageID && group.value.fillStageID != 3)
              "
            >
              <ng-container *ngFor="let field of group.value.fields | keyvalue">
                <span
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 0"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="form-text"
                  >{{ field.value.label }}</span
                >

                <!-- Checkbox -->
                <mat-checkbox
                  *ngIf="
                    field.value.isVisible &&
                    (field.value.inputTypeID == 1 ||
                      field.value.inputTypeID == 5)
                  "
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  id="{{ field.value.key }}"
                  formControlName="{{ field.value.key }}"
                  [disabled]="field.value.isReadOnly"
                  class="m-0 font-rajdhani font-medium uppercase text-slate-600"
                  fxFlex="100"
                  >{{ field.value.label }}</mat-checkbox
                >

                <!-- String -->
                <mat-form-field
                  *ngIf="
                    field.value.isVisible &&
                    (field.value.inputTypeID == 2 ||
                      field.value.inputTypeID == 6)
                  "
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5 "
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <input
                    id="{{ field.value.key }}"
                    matInput
                    tabindex="{{ field.value.tabindex }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    formControlName="{{ field.value.key }}"
                    type="search"
                  />
                  <div *ngIf="getURLFromString(field.value) !== ''">
                    <a [href]="getURLFromString(field.value)" target="_blank">{{ getURLFromString(field.value) }}</a>
                  </div>
                </mat-form-field>

                <!-- Integer/Float -->
                <mat-form-field
                  *ngIf="
                    field.value.isVisible &&
                    (field.value.inputTypeID == 3 ||
                      field.value.inputTypeID == 4 ||
                      field.value.inputTypeID == 7 ||
                      field.value.inputTypeID == 8)
                  "
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <input
                    id="{{ field.value.key }}"
                    matInput
                    tabindex="{{ field.value.tabindex }}"
                    placeholder="0"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    formControlName="{{ field.value.key }}"
                    type="number"
                    step="any"
                  />
                </mat-form-field>

                <!-- Date Picker -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 10"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                  (click)="picker.open()"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <input
                    matInput
                    tabindex="{{ field.value.tabindex }}"
                    readonly="true"
                    [matDatepicker]="picker"
                    id="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    formControlName="{{ field.value.key }}"
                    (dateChange)="onDateChange(field, $event.value)"
                    type="search"
                    [min]="
                      field.value.minDateKey
                        ? view.formGroup.value[field.value.minDateKey]
                        : ''
                    "
                    [max]="
                      field.value.maxDateKey
                        ? view.formGroup.value[field.value.maxDateKey]
                        : ''
                    "
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker
                    #picker
                    [disabled]="
                      field.value.isReadOnly ||
                      TICKET_PROTECTION_MODE ||
                      props.isU2TicketComplete
                    "
                  ></mat-datepicker>
                </mat-form-field>

                <!-- Single Select -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 11"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <mat-select
                    tabindex="{{ field.value.tabindex }}"
                    [compareWith]="compareOptions"
                    id="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    formControlName="{{ field.value.key }}"
                    (selectionChange)="
                      onDropdownSelectionChange(field, $event.value)
                    "
                  >
                    <mat-option
                      *ngFor="let option of field.value.selectOptions"
                      [value]="option"
                    >
                      {{ option.text ? option.text : option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Multi Select -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 12"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <!-- <competers-searchable-dropdown
                    [compareOptions]="compareOptions"
                    [field]="field"
                    [parentFormGroup]="view.formGroup"
                    (selectValueChanged)="
                      onDropdownSelectionChange(field, $event)
                    "
                  ></competers-searchable-dropdown> -->

                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <mat-select
                    class="font-normal"
                    tabindex="{{ field.value.tabindex }}"
                    [compareWith]="compareOptions"
                    id="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    formControlName="{{ field.value.key }}"
                    (selectionChange)="
                      onDropdownSelectionChange(field, $event.value)
                    "
                    multiple
                  >
                    <mat-option
                      class="font-normal"
                      *ngFor="let option of field.value.selectOptions"
                      [value]="option"
                    >
                      {{ option.text }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Autocomplete String -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 13"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5 font-normal"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <input
                    class="font-normal"
                    type="search"
                    matInput
                    tabindex="{{ field.value.tabindex }}"
                    formControlName="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    [matAutocomplete]="auto"
                  />
                  <mat-icon matSuffix class="search-icon p-0 pr-2">search</mat-icon>
                  <mat-autocomplete
                    class="font-normal"
                    ref-auto="matAutocomplete"
                    [displayWith]="displayFn(field.value.key)"
                    (optionSelected)="
                      onAutoClick($event.option.value, field.value)
                    "
                  >
                    <cdk-virtual-scroll-viewport
                      itemSize="40"
                      [style.height.px]="4 * 40"
                    >
                      <mat-option
                        class="font-normal"
                        *cdkVirtualFor="
                          let option of field.value.filteredOptions
                        "
                        [value]="option"
                      >
                        {{ option.text }}
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-autocomplete>
                </mat-form-field>

                <!-- Addresspicker -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 14"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  (click)="onAddressPickerClick(field.value)"
                  class="p-1.5"
                  floatLabel="never"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <input
                    type="search"
                    tabindex="{{ field.value.tabindex }}"
                    matInput
                    formControlName="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    #addressPicker
                  />
                  <mat-icon matSuffix class="search-icon p-0 pr-2">search</mat-icon>
                </mat-form-field>

                <!-- Timepicker -->
                <mat-form-field
                  *ngIf="
                    field.value.isVisible &&
                    (field.value.inputTypeID == 15 ||
                      field.value.inputTypeID == 16)
                  "
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <input
                    matInput
                    [ngxTimepicker]="picker"
                    tabindex="{{ field.value.tabindex }}"
                    formControlName="{{ field.value.key }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                  />
                  <ngx-material-timepicker
                    #picker
                    [theme]="timePickerTheme"
                    [minutesGap]="field.value.inputTypeID == 16 ? 15 : 1"
                  >
                  </ngx-material-timepicker>
                </mat-form-field>

                <!-- Datetimepicker -->
                <div
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 17"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                >
                  <mat-form-field
                    fxFlex="45"
                    class="p-1.5"
                    [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                    (click)="picker.open()"
                  >
                    <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                    <input
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      readonly="true"
                      [matDatepicker]="picker"
                      id="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      formControlName="{{ field.value.key }}"
                      (dateChange)="dateTimePickerDateChanged($event, field)"
                      type="search"
                      [min]="
                        field.value.minDateKey
                          ? view.formGroup.value[field.value.minDateKey]
                          : ''
                      "
                      [max]="
                        field.value.maxDateKey
                          ? view.formGroup.value[field.value.maxDateKey]
                          : ''
                      "
                    />
                    <!-- add if for value and add click to clear -->
                    <!-- <button matSuffix mat-icon-button aria-label="Clear" (click)="dateTimePickerClear($event, field)">
                    <mat-icon class='form-icon'>close</mat-icon>
                  </button> -->
                    <!-- <mat-datepicker-toggle *ngIf="this.view.formGroup.controls[field.value.key].value && !(field.value.isReadOnly || TICKET_PROTECTION_MODE || props.isU2TicketComplete)" matSuffix (click)="dateTimePickerClear($event, field)">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                  </mat-datepicker-toggle> -->
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                      #picker
                      [disabled]="
                        field.value.isReadOnly ||
                        TICKET_PROTECTION_MODE ||
                        props.isU2TicketComplete
                      "
                    ></mat-datepicker>
                  </mat-form-field>
                  <!-- (dateChange)="onDateChange(field, $event.value)" -->
                  <mat-form-field
                    fxFlex="55"
                    class="p-1.5"
                    [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                  >
                    <mat-label class="text-sm">{{
                      field.value.label.toLowerCase().replace("date", "time")
                    }}</mat-label>
                    <input
                      matInput
                      [ngxTimepicker]="editableDial"
                      tabindex="{{ field.value.tabindex }}"
                      formControlName="{{ field.value.key + 'Time_Ignore_' }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="true"
                    />
                    <!-- add if for value and add click to clear -->

                    <ngx-material-timepicker-toggle
                      matSuffix
                      [for]="editableDial"
                    >
                      <mat-icon
                        class="form-icon"
                        style="line-height: 1.4"
                        ngxMaterialTimepickerToggleIcon
                        >access_time</mat-icon
                      >
                    </ngx-material-timepicker-toggle>

                    <!--                    <button-->
                    <!--                      matSuffix-->
                    <!--                      *ngIf="-->
                    <!--                        this.view.formGroup.controls[field.value.key].value &&-->
                    <!--                        !(-->
                    <!--                          field.value.isReadOnly ||-->
                    <!--                          TICKET_PROTECTION_MODE ||-->
                    <!--                          props.isU2TicketComplete-->
                    <!--                        )-->
                    <!--                      "-->
                    <!--                      mat-icon-button-->
                    <!--                      aria-label="Clear"-->
                    <!--                      (click)="dateTimePickerClear($event, field)"-->
                    <!--                    >-->
                    <!--                      <mat-icon class="form-icon">close</mat-icon>-->
                    <!--                    </button>-->

                    <ngx-material-timepicker
                      #editableDial
                      [theme]="timePickerTheme"
                      (timeSet)="dateTimePickerTimeChanged($event, field)"
                    >
                    </ngx-material-timepicker>
                  </mat-form-field>
                </div>
                <!-- Textarea -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 18"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    id="{{ field.value.key }}"
                    tabindex="{{ field.value.tabindex }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    formControlName="{{ field.value.key }}"
                    class="p-0"
                  ></textarea>
                  <div *ngIf="getURLFromString(field.value) !== ''">
                    <a [href]="getURLFromString(field.value)" target="_blank">{{ getURLFromString(field.value) }}</a>
                  </div>
                </mat-form-field>

                <!-- Remarks area with url -->
                <mat-form-field
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 28"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  class="p-1.5"
                  [appearance]="field.value.isReadOnly? 'fill' : 'outline'"
                >
                  <mat-label class="text-sm">{{ field.value.label }}</mat-label>
                  <textarea
                    matInput
                    cdkTextareaAutosize
                    id="{{ field.value.key }}"
                    tabindex="{{ field.value.tabindex }}"
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    formControlName="{{ field.value.key }}"
                  ></textarea>
                  <div *ngIf="getURLFromString(field.value) !== ''">
                    <a [href]="getURLFromString(field.value)" target="_blank">{{ getURLFromString(field.value) }}</a>
                  </div>
                </mat-form-field>

                <!-- Photo -->
                <div
                  *ngIf="field.value.isVisible && field.value.inputTypeID == 9"
                  fxFlex.xs="{{ field.value.width_xs }}"
                  fxFlex.sm="{{ field.value.width_sm }}"
                  fxFlex.md="{{ field.value.width_md }}"
                  fxFlex.lg="{{ field.value.width_lg }}"
                  fxFlex.xl="{{ field.value.width_xl }}"
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <p class="font-montserrat font-normal text-lg pl-2">
                    {{ field.value.label }}
                  </p>
                  <div matTooltip="Currently only availble in Android App">
                    <button mat-flat-button color="primary" [disabled]="true">
                      Take Photo
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="group.value.fillStageID && group.value.fillStageID == 3"
              formGroupName="{{ group.value.fillKey }}"
            >
              <ng-container *ngFor="let field of group.value.fields | keyvalue">
                <mat-checkbox
                  *ngIf="
                    field.value.isVisible &&
                    (field.value.inputTypeID == 1 ||
                      field.value.inputTypeID == 5)
                  "
                  fxFlexOrder="{{ field.value.fieldOrder }}"
                  id="{{ field.value.key }}"
                  formControlName="{{ field.value.key }}"
                  [disabled]="field.value.isReadOnly"
                  class="m-0 p-1.5"
                  fxFlex="100"
                  >{{ field.value.label }}</mat-checkbox
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>

<!-- can add an ng template for errors? -->

<ng-template #loading>
  <!-- <h4 class="pl-4">Loading...</h4> -->
  <!-- <mat-spinner [diameter]="30" color="accent"></mat-spinner> -->
</ng-template>
