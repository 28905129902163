import { FabOverlayComponent } from '../../../../shared/components/containers/fab-overlay/fab-overlay.component';
import {
  PillIndicatorComponent,
} from '../../../../shared/components/indicators/pill-indicator/pill-indicator.component';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  TabbedContainerComponent,
} from '../../../../shared/components/containers/tabbed-container/tabbed-container.component';
import {
  ContainerTabComponent,
} from '../../../../shared/components/containers/tabbed-container/container-tab/container-tab.component';
import { LayerListComponent } from '../../layer-switcher/layer-list/layer-list.component';
import { LegendComponent } from '../../layer-switcher/legend/legend.component';
import { AsyncPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { DrawingService } from '../../drawing.service';
import { DrawingListComponent } from './drawing-list/drawing-list.component';
import { Drawing, DrawingID } from '../../services/drawing-manager.service';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-drawing-switcher',
  standalone: true,
  imports: [
    PillIndicatorComponent,
    FabOverlayComponent,
    MatIconModule,
    TabbedContainerComponent,
    ContainerTabComponent,
    LayerListComponent,
    LegendComponent,
    AsyncPipe,
    DrawingListComponent,
  ],
  template: `
    <div class="flex flex-row justify-end items-center gap-3">
      <app-fab-overlay
        [classOverride]="(mapDrawingService.drawingModeEnabled$ | async) ? 'drawing-mode-enabled' : ''"
        [hasBackdrop]="false"
        [positionPairs]="positionPairs"
        [realign$]="positionChange$"
      >
        <mat-icon icon svgIcon="layers" />
        <app-tabbed-container class="tabbed-container" menu [tabs]="tabs">
          <app-container-tab [tab]="tabs[0]">
            <app-drawing-list [activeDrawing]="activeDrawing" (drawingSelectionChanged)="activeDrawingChanged.emit($event)"
                              (deleteClick)="deleteClick.emit($event)"
                              [drawings]="drawings">
            </app-drawing-list>
          </app-container-tab>
        </app-tabbed-container>
      </app-fab-overlay>
    </div>
  `,
})
export class DrawingSwitcherComponent implements OnInit, OnDestroy {
  // IO
  @ViewChild('tabby') tabbedContainer: ElementRef;
  @Input() drawings: Array<Drawing>;
  @Input() activeDrawing: DrawingID;
  @Output() activeDrawingChanged = new EventEmitter<string>()
  @Output() deleteClick = new EventEmitter<string>()

  // services
  protected mapDrawingService = inject(DrawingService);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  protected positionChange$: Subject<void> = new Subject<void>();


  // layout options
  tabs = [
    { title: 'drawings', index: 0 },
  ];
  positionPairs: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 24,
    },
  ];

  //members

  constructor() {
  }

  ngOnInit(): void {
    this.mapDrawingService.drawingModeEnabled$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.positionChange$.next()),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
