import { Component, OnInit, Input } from "@angular/core";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { Resource } from "../../syncfusion-scheduler/schedulerTypes";

@Component({
  selector: "app-scheduler-filter-panel",
  templateUrl: "./scheduler-filter-panel.component.html",
  styleUrls: ["./scheduler-filter-panel.component.css"],
  providers: [ComponentMessagingService],
})
export class SchedulerFilterPanelComponent
  extends BasePanelComponent
  implements OnInit
{
  className = "SchedulerFilterPanelComponent";

  @Input() props: any;
  @Input() peopleResources: Resource[];
  @Input() rigResources: Resource[];

  filterViewProps: any;

  constructor(
    logger$: LoggerService,
    private messaging$: ComponentMessagingService
  ) {
    super(logger$);
    this.filterViewProps = {
      messageService: messaging$,
    };
    // set up message receiver for receiving messages from child components
    messaging$.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessageChild(nextMsg);
    });

    this.peopleResources = [];
    this.rigResources = [];
  }

  ngOnInit(): void {
    // listen to parent
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {});
  }

  onReceivedMessageChild(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.filterEvents) {
        this.props.messageService.sendToMessageStream(compMessage);
      }
    }
  }
}
