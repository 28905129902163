import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DateTime } from "luxon";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";

@Component({
  selector: "app-edit-timerange-modal",
  templateUrl: "edit-timerange.component.html",
})
export class EditTimeRangeModalComponent {
  header: string;
  date: Date;
  startTime: string;
  endTime: string;

  newStartTime: any;
  newEndTime: any;
  datetimeEditor: boolean = false;
  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#fff",
      buttonColor: "#0d66b1",
    },
    dial: {
      dialBackgroundColor: "#0d66b1",
    },
    clockFace: {
      clockFaceBackgroundColor: "#eee",
      clockHandColor: "#0d66b1",
      clockFaceTimeInactiveColor: "#222",
    },
  };

  dateTimePickerFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: any,
    public dialogRef: MatDialogRef<EditTimeRangeModalComponent>,
    private snackbar: SnackbarService,
    private datetimeService: DatetimeService
  ) {
    try {
      console.log(userData);
      if (userData && userData["datetimeEditor"] != null) {
        this.datetimeEditor = userData["datetimeEditor"];
        //set form group?
        this.dateTimePickerFormGroup = new FormGroup({
          timeIn: new FormControl(""),
          timeOut: new FormControl(""),
          dateIn: new FormControl(""),
          dateOut: new FormControl(""),
        });
      }

      if (userData && userData["date"] != null) {
        this.date = userData["date"];
      }

      if (userData && userData["startTime"] != null) {
        this.startTime = userData["startTime"];
        this.newStartTime = this.startTime;
        if (this.datetimeEditor) {
          let time = this.newStartTime.toLocaleTimeString("default", {
            hour: "2-digit",
            minute: "2-digit",
          });
          this.dateTimePickerFormGroup.patchValue({
            dateIn: this.startTime,
            timeIn: time,
          });
        }
      }

      if (userData && userData["endTime"] != null) {
        this.endTime = userData["endTime"];
        this.newEndTime = this.endTime;
        if (this.datetimeEditor) {
          let time = this.newEndTime.toLocaleTimeString("default", {
            hour: "2-digit",
            minute: "2-digit",
          });
          this.dateTimePickerFormGroup.patchValue({
            dateOut: this.endTime,
            timeOut: time,
          });
        }
      }

      if (userData && userData["header"] != null) {
        this.header = userData["header"];
      }
    } catch (error) {
      console.error(error);
    }
  }
  onStartTimeChange(event) {
    this.newStartTime = event;
  }

  onEndTimeChange(event) {
    this.newEndTime = event;
  }

  onConfirm(event) {
    let result = {};
    try {
      if (this.datetimeEditor) {
        console.log(this.newStartTime, this.newEndTime);
        //compare dates and throw message if start time is greater than end time.
        if (this.newStartTime > this.newEndTime && this.newEndTime) {
          console.log("BAD");
          this.snackbar.openSnackbar(
            "Time In must be before Time Out.",
            SnackbarType.warning,
            "Close"
          );
        } else {
          console.log("Good");
          if (this.newEndTime && !this.newStartTime) {
            console.log("BAD");
            this.snackbar.openSnackbar(
              "Time In must exist if entered a Time Out.",
              SnackbarType.warning,
              "Close"
            );
          } else {
            console.log("Good");
            result = {
              TimeIn: this.newStartTime
                ? this.datetimeService.localDateToDBDateStr(this.newStartTime)
                : null,
              TimeOut: this.newEndTime
                ? this.datetimeService.localDateToDBDateStr(this.newEndTime)
                : null,
            };
            this.dialogRef.close({ result: result });
          }
        }
      } else {
        let tmpDate = new Date(this.date); // only need date, no time
        let newStartTimeHours = this.newStartTime.split(":")[0];
        let newStartTimeMins = this.newStartTime.split(":")[1];
        let newEndTimeHours = this.newEndTime.split(":")[0];
        let newEndTimeMins = this.newEndTime.split(":")[1];

        let newStartTime = new Date(
          tmpDate.getFullYear(),
          tmpDate.getMonth(),
          tmpDate.getDate(),
          newStartTimeHours,
          newStartTimeMins
        );
        let newEndTime = new Date(
          tmpDate.getFullYear(),
          tmpDate.getMonth(),
          tmpDate.getDate(),
          newEndTimeHours,
          newEndTimeMins
        );
        result = {
          TimeIn: newStartTime,
          TimeOut: newEndTime,
        };
        this.dialogRef.close({ result: result });
      }
    } catch (error) {
      console.error(error);
      this.dialogRef.close({});
    }
    // this.dialogRef.close({result:result});
  }

  //Event needs to be control
  dateTimePickerDateChanged(field) {
    try {
      let currentDate = this.dateTimePickerFormGroup.get("dateOut").value;
      let currentTime = this.dateTimePickerFormGroup.get("timeOut").value;
      if (field == "dateIn") {
        currentDate = this.dateTimePickerFormGroup.get("dateIn").value;
        currentTime = this.dateTimePickerFormGroup.get("timeIn").value;
      }

      if (!currentDate) {
        currentDate = new Date();
      }

      if (!currentTime) {
        currentTime = new Date().toLocaleTimeString("default", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      let dateTimeValue = this.dateTimePickerMerge(currentDate, currentTime);
      if (field == "dateIn") {
        this.newStartTime = dateTimeValue;
        this.dateTimePickerFormGroup.patchValue({
          dateIn: dateTimeValue,
          timeIn: currentTime,
        });
      } else {
        this.newEndTime = dateTimeValue;
        this.dateTimePickerFormGroup.patchValue({
          dateOut: dateTimeValue,
          timeOut: currentTime,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  dateTimePickerTimeChanged(value, field) {
    try {
      let currentDate = this.dateTimePickerFormGroup.get("dateOut").value;
      let currentTime = value;
      if (field == "timeIn") {
        currentDate = this.dateTimePickerFormGroup.get("dateIn").value;
        currentTime = value;
      }
      try {
        if (currentDate.toLowerCase().indexOf("t") > 0) {
          currentDate = DateTime.fromISO(currentDate).toJSDate();
        }
      } catch (error) {
        console.log("Date", currentDate);
      }
      if (!currentDate) {
        currentDate = new Date();
      }

      let dateTimeValue = this.dateTimePickerMerge(currentDate, currentTime);
      if (field == "timeIn") {
        this.newStartTime = dateTimeValue;
        this.dateTimePickerFormGroup.patchValue({ dateIn: dateTimeValue });
      } else {
        this.newEndTime = dateTimeValue;
        this.dateTimePickerFormGroup.patchValue({ dateOut: dateTimeValue });
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  dateTimePickerMerge(date, time) {
    try {
      return new Date(
        DateTime.fromJSDate(date).toFormat("yyyy LL dd") + " " + time
      );
    } catch (error) {
      console.error(error.message);
      return date;
    }
  }

  dateTimePickerClear(field) {
    try {
      if (field == "timeIn") {
        this.dateTimePickerFormGroup.patchValue({ timeIn: "", dateIn: "" });
        this.newStartTime = "";
      } else {
        this.dateTimePickerFormGroup.patchValue({ timeOut: "", dateOut: "" });
        this.newEndTime = "";
      }
    } catch (error) {
      console.error(error.message);
    }
  }
}
