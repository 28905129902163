<div class="flex flex-col justify-center items-center px-6 pb-6 pt-3 overflow-auto max-h-[90vh]">
  <div class="flex flex-row justify-between items-center w-full">
    <h3 class="text-headline-5 font-semibold font-rajdhani uppercase">Ticket Coloring Legend</h3>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="text-[#000000]">close</mat-icon>
    </button>
  </div>
  <div class="flex flex-col justify-start items-start overflow-auto max-h-full">
    @for (section of ticketLegend; track section.title) {
      <div class="mb-3">
        <h4 class="text-lg font-rajdhani font-semibold uppercase leading-2">{{ section.title }}</h4>
        @for (p of section.description; track p) {
          <p class="text-sm font-montserrat leading-6">
            {{ p }}
          </p>
        }
      </div>
      <div class="flex flex-row flex-wrap gap-3">
        @for (pin of section.pins; track pin.color) {
          <div class="flex flex-col justify-start items-center text-center w-24">
            <mat-icon style="color: {{ pin.color }}" svgIcon="pin_border" />
            <span class="font-montserrat text-sm capitalize">{{ pin.label }}</span>
          </div>
        }
      </div>
      <hr class="border-t-1 border-gray-500 border-opacity-20 w-11/12 my-6 last:hidden" />
    }
  </div>
</div>
