import { FormGroup } from "@angular/forms";

export enum InputTypes {
  Checkbox = 1,
  String = 2,
  Integer = 3,
  Float = 4,
  Photo = 9,
  Datepicker = 10,
  Singleselect = 11,
  Multiselect = 12,
  AutoCompleteString = 13,
  AddressPicker = 14,
  TimePicker = 15,
  IntervalTimePicker = 16,
}

export interface RecursiveFormField {
  appearance: string;
  inputTypeID: number;
  label: string;
  key: string;
  placeholder: string;
  isRequired: boolean;
  isReadOnly: boolean;
  width_xs: number | string;
  width_sm: number | string;
  width_md: number | string;
  width_lg: number | string;
  width_xl: number | string;
  fieldOrder: number;
  isVisible: boolean;
  isHideShow?: boolean;
  selectOptions?: any;
}

export interface RecursiveFormGroup {
  header: string;
  key: string;
  categoryID?: number | string;
  groupOrder: number;
  hasHiddenValues?: boolean;
  hasBeenRemoved: boolean;
  minInstances?: number;
  maxInstances?: number;
  numInstances?: number;
  formGroup: FormGroup;
  groups?: {
    [key: string]: RecursiveFormGroup;
  };
  fields?: {
    [key: string]: RecursiveFormField;
  };
}

export interface RecursiveForm {
  groups: {
    [key: string]: RecursiveFormGroup;
  };
  formGroups: {
    [key: string]: FormGroup;
  };
}

export interface PreCompletionsDetailRow {
  PreCompletionDetailID: number;
  PrimaryID: number;
  PreCompletionFieldID: number;
  PreCompletionCategoryInstanceID: number;
  FieldValue: string;
  bFieldHidden: 0 | 1;
  bFieldAdded: 0 | 1;
  bFieldRemoved: 0 | 1;
  bFieldModified: 0 | 1;
}

export interface PreCompletionCategoryRow {
  PreCompletionCategoryInstanceID: number;
  PrimaryID: number;
  ParentCategoryInstanceID: number;
  PreCompletionCategoryID: number;
  bFieldAdded: 0 | 1;
  bFieldRemoved: 0 | 1;
}
