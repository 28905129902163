import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "confirmation-modal.component.html",
})
export class ConfirmationModalComponent {
  data: any;
  isFail: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {
    this.data = Data;
    if (this.data.header != null) {
      this.data.header = this.data.header.toString().trim();
    }
    this.isFail = false;

    if (this.data && this.data["header"].toUpperCase().indexOf("FAILED") > -1) {
      this.isFail = true;
    }
  }

  onConfirm(event) {
    this.dialogRef.close({ event: "close", result: true });
  }
}
