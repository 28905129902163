import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageNavigationComponent } from "./page-navigation/page-navigation.component";
import { MaterialModule } from "../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [PageNavigationComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, RouterModule],
  exports: [PageNavigationComponent],
})
export class PageNavigationModule {}
