import { CommonModule } from "@angular/common";
import {
  Component,
  OnInit,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  Inject,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import { BaseComponent } from "../../core/base-set/base-set.component";
import { LoggerService } from "../../core/services/logger/logger.service";
import { FormInputTemplateModule } from "../../shared/forms/form-input-template/form-input-template.component";
import { MaterialModule } from "../../shared/material.module";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SnackbarType } from "../../shared/snackbar/snackbar/snackbar";
import { CreateTicketComponentService } from "../create-ticket-component.service";
import {
  AngularButtonTypes,
  SidePanelButtonAction,
  SidePanelProps,
  SidePanelTemplates,
  SlideOverSidePanelModule,
} from "../slideover-side-panel/slideover-side-panel.component";
import {
  AssignmentToExcavatorContactPayload,
  ExcavatorContactDetailsPayload,
  ExcavatorContactsService,
  SwitchAssignmentCompanyPayload,
} from "./services/excavator-contacts.service";

@Component({
  selector: "app-excavator-contacts-card",
  templateUrl: "./excavator-contacts-card.component.html",
  styleUrls: ["./excavator-contacts-card.component.scss"],
})
export class ExcavatorContactsCardComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() props: ExcavatorContactsCardProps;
  @Input() multipleExcavatorContacts: boolean;
  @Input() isCreateTicket: boolean = false;
  @ViewChild("contactList") contactList: MatSelectionList;
  @Output() excavatorSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() excavatorDetailsChanged: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() excavatorContactsChanged: EventEmitter<any> =
    new EventEmitter<any>();

  views: any;
  contactView: any;
  editCompanyView: any;

  viewReady = false;
  dataReady = false;
  loadingData = false;

  currentContactID = -1;
  excavator = [];
  defaultExcavator = {};
  contacts = [];
  defaultContact = {};
  selectedContacts = [];
  TICKET_PROTECTION_MODE: boolean = false;

  showAllContacts = false;
  toggleContactsBtnText = "Show All";

  slideoverProps: SidePanelProps = {
    title: "Create Company",
    isFullscreen: true,
    width: "528px",
    isForm: true,
    // htmlContent?:string
    // view:
    actions: [],
  };

  constructor(
    logger$: LoggerService,
    public dialog: MatDialog,
    private createTicket$: CreateTicketComponentService,
    private excavatorContact$: ExcavatorContactsService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);
  }

  ngOnInit(): void {
    try {
      this.createTicket$.getCreateTicketTemplate().subscribe((msg) => {
        if (msg) {
          this.views = msg["views"];
          // this.childProps = {... this.childProps};
          this.viewReady = true;
        }
      });
    } catch (error) {
      console.error("ngOnInit1" + error);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.isCreateTicket) {
        if (this.isCreateTicket) {
          this.showAllContacts = true;
        }
      }

      if (changes["props"] && changes["props"]["currentValue"]) {
        let currentValue = changes["props"]["currentValue"];

        if (currentValue && currentValue["TICKET_PROTECTION_MODE"] != null) {
          this.TICKET_PROTECTION_MODE = currentValue["TICKET_PROTECTION_MODE"];
        }

        if (
          currentValue &&
          currentValue["ExcavatorID"] &&
          currentValue["ExcavatorID"] > -1
        ) {
          this.getExcavatorInfo().then((res) => {
            if (res) {
              if (currentValue["AssignmentID"] > -1) {
                //ticket details
                this.setupSelectedContacts(currentValue["AssignmentID"]);
              } else {
                //create
                this.selectedContacts = [];
                if (
                  currentValue["prefillAssignmentID"] &&
                  currentValue["prefillAssignmentID"] > 0
                ) {
                  //prefill company info using assignmentID
                  this.setupSelectedContacts(
                    currentValue["prefillAssignmentID"]
                  );
                } else {
                  console.log("onExcavatorSaved");
                  this.excavatorSaved.emit(this.excavator[0]);
                }
              }
            }
          });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  reset() {
    try {
      this.dataReady = false;
      this.loadingData = false;
      this.excavator = [];
      this.contacts = [];
      this.selectedContacts = [];
    } catch (error) {
      console.error(error.message);
    }
  }

  async setupSelectedContacts(assignmentID) {
    try {
      let currentContacts = await this.getAssignmentExcavatorContacts(
        assignmentID
      );
      let selectedOptions = [];
      if (currentContacts && currentContacts.length > 0) {
        //setup options
        currentContacts.forEach((contact) => {
          this.contactList.options.forEach((option) => {
            if (
              option.value &&
              option.value.ExcavatorContactID == contact.ExcavatorContactID
            ) {
              selectedOptions.push(option.value);
              option.selected = true;
            }
          });
        });
      }
      this.selectedContacts = selectedOptions;
      this.excavatorDetailsChanged.emit(this.getExcavatorObject());
    } catch (error) {
      console.error(error.message);
    }
  }

  async updateSelectedContacts(updatedList) {
    try {
      let selectedOptions = [];
      this.contactList.deselectAll();

      updatedList.forEach((contact) => {
        this.contactList.options.forEach((option) => {
          if (
            option.value &&
            option.value.ExcavatorContactID == contact.value
          ) {
            selectedOptions.push(option.value);
            option.selected = true;
          }
        });
      });
      this.selectedContacts = selectedOptions;
      //need to emit to get changes to idb
      this.excavatorContactsChanged.emit(this.getExcavatorObject());
    } catch (error) {
      console.error(error.message);
    }
  }

  getExcavatorObject() {
    let excavatorObj = {
      excavator: [],
      contacts: [],
      selectedContacts: [],
    };
    try {
      excavatorObj = {
        excavator: this.excavator,
        contacts: this.contacts,
        selectedContacts: this.selectedContacts,
      };
    } catch (error) {
      console.error(error.message);
    }
    return excavatorObj;
  }

  onContactListChange(matSelectListEvent) {
    try {
      //update current selected list
      if (
        matSelectListEvent.source &&
        matSelectListEvent.source.selectedOptions.selected
      ) {
        let selectedOptions = [];
        matSelectListEvent.source.selectedOptions.selected.forEach(
          (matListOption) => {
            selectedOptions.push(matListOption.value);
          }
        );
        this.selectedContacts = selectedOptions;
      }

      //save to db if it is a ticket
      if (
        this.props.AssignmentID > -1 &&
        matSelectListEvent.options[0].value &&
        matSelectListEvent.options[0].value.ExcavatorContactID
      ) {
        //also save completion data.
        let bFieldAdded = 0;
        let bFieldRemoved = 0;
        if (matSelectListEvent.options[0].selected) {
          //false -> true
          bFieldAdded = 1;
        } else {
          //true -> false
          bFieldRemoved = 1;
        }
        const obj: AssignmentToExcavatorContactPayload = {
          AssignmentID: this.props.AssignmentID,
          ExcavatorContactID:
            matSelectListEvent.options[0].value.ExcavatorContactID,
          bFieldAdded: bFieldAdded,
          bFieldRemoved: bFieldRemoved,
        };
        if (this.saveAssignmentToExcavatorContact(obj)) {
          this.logger$.log("SAVED");
        } else {
          this.logger$.log("NOT SAVED");
        }
      }
      this.excavatorDetailsChanged.emit(this.getExcavatorObject());
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  editCompanyClick(event, panel) {
    try {
      let cancelButton: SidePanelButtonAction = {
        text: "Cancel",
        buttonType: AngularButtonTypes.defaultButton,
        color: "",
        action: this.onCancel,
        width: 50,
        order: 1,
      };
      let saveCompanyButton: SidePanelButtonAction = {
        text: "Save Changes",
        buttonType: AngularButtonTypes.raisedButton,
        color: "primary",
        action: this.onSaveEditCompany,
        width: 50,
        order: 2,
      };
      this.slideoverProps = {
        title: "Edit Company",
        isFullscreen: true,
        width: "528px",
        isForm: true,
        view: this.views["companyInfo"],
        views: this.views,
        actions: [cancelButton, saveCompanyButton],
      };
      if (this.excavator.length > 0 && this.views["companyInfo"]["formGroup"]) {
        let formGroup = this.views["companyInfo"]["formGroup"];
        formGroup.patchValue(this.excavator[0]);
      }
      panel.open();
    } catch (error) {
      console.error(error.message);
    }
  }

  switchCompanyClick(event, panel) {
    try {
      let cancelButton: SidePanelButtonAction = {
        text: "Cancel",
        buttonType: AngularButtonTypes.defaultButton,
        color: "",
        action: this.onCancel,
        width: 50,
        order: 1,
      };
      let switchCompanyButton: SidePanelButtonAction = {
        text: "Save",
        buttonType: AngularButtonTypes.raisedButton,
        color: "primary",
        action: this.onSwitchCompany,
        width: 50,
        order: 2,
      };
      this.slideoverProps = {
        title: "Switch Company",
        isFullscreen: true,
        width: "528px",
        isForm: false,
        template: SidePanelTemplates.switchCompany,
        actions: [cancelButton, switchCompanyButton],
      };
      if (panel.myControl && panel.myControl.value) {
        panel.myControl.value = "";
      }
      panel.open();
    } catch (error) {
      console.error(error.message);
    }
  }

  onCancel = (panel) => {
    try {
      panel.close();
    } catch (error) {
      console.error(error.message);
    }
  };

  onSaveEditCompany = (panel) => {
    try {
      //get data and update.
      // close panel
      if (
        this.views["companyInfo"]["formGroup"] &&
        this.views["companyInfo"]["formGroup"].valid
      ) {
        panel.toggleLoading();
        panel.disableCurrentFormGroup();
        let excavatorDetails = this.views["companyInfo"]["formGroup"].value;
        excavatorDetails["ExcavatorID"] = this.props.ExcavatorID;
        this.createTicket$.saveCompany(excavatorDetails).then((saveResult) => {
          if (saveResult) {
            this.updateExcavator(excavatorDetails);
            this.snackBarService.openSnackbar(
              "Saved Changes",
              SnackbarType.success
            );
            panel.toggleLoading();
            panel.close();
          } else {
            this.snackBarService.openSnackbar(
              "Unable to save changes",
              SnackbarType.error
            );
            panel.toggleLoading();
          }
        });
      } else {
        this.views["companyInfo"]["formGroup"].markAllAsTouched();
        this.snackBarService.openSnackbar(
          "Invalid input, please review fields",
          SnackbarType.error
        );
      }

      // panel.close();
    } catch (error) {
      console.error(error.message);
    }
  };

  onSwitchCompany = (panel) => {
    try {
      // //get information

      if (panel.myControl.value.value) {
        let payload: SwitchAssignmentCompanyPayload = {
          AssignmentID: this.props.AssignmentID,
          ExcavatorID: panel.myControl.value.value,
        };
        //this.excavatorContact$.resetAssignmentToExcavatorContact(payload) This one can just reset.
        //clear contacts
        //switch comapny
        panel.toggleLoading();
        this.excavatorContact$
          .switchAssignmentCompany(payload)
          .then((val) => {
            this.snackBarService.openSnackbar(
              "Saved Changes",
              SnackbarType.success
            );
            this.props.ExcavatorID = panel.myControl.value.value;
            this.getExcavatorInfo();
            panel.toggleLoading();
            panel.close();
          })
          .catch((ex) => {
            this.snackBarService.openSnackbar(
              "Unable to save changes",
              SnackbarType.error
            );
            panel.toggleLoading();
          });
      } else {
        this.snackBarService.openSnackbar(
          "Unable to save changes",
          SnackbarType.error
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  updateExcavator(excavatorDetails) {
    try {
      if (this.excavator && this.excavator[0]) {
        let excavatorKeys = Object.keys(this.excavator[0]);
        if (excavatorKeys.length > 0) {
          for (var key in excavatorDetails) {
            if (excavatorKeys.includes(key)) {
              this.excavator[0][key] = excavatorDetails[key];
            }
          }
        }
      }
      this.excavatorSaved.emit(this.excavator[0]);
    } catch (error) {
      console.error(error.message);
    }
  }

  toggleShowContacts() {
    if (this.showAllContacts) {
      this.showAllContacts = false;
      this.toggleContactsBtnText = "Show All";
    } else {
      this.showAllContacts = true;
      this.toggleContactsBtnText = "Show Selected";
    }
  }

  createContactClick(event, panel) {
    try {
      let cancelButton: SidePanelButtonAction = {
        text: "Cancel",
        buttonType: AngularButtonTypes.defaultButton,
        color: "",
        action: this.onCancel,
        width: 50,
        order: 1,
      };
      let saveContactButton: SidePanelButtonAction = {
        text: "Save Contact",
        buttonType: AngularButtonTypes.raisedButton,
        color: "primary",
        action: this.onCreateContact,
        width: 50,
        order: 2,
      };
      this.slideoverProps = {
        title: "Create Contact",
        isFullscreen: true,
        width: "528px",
        isForm: true,
        view: this.views["companyContactInfo"],
        views: this.views,
        actions: [cancelButton, saveContactButton],
      };
      panel.open();
    } catch (error) {
      console.error(error.message);
    }
  }

  onCreateContact = (panel) => {
    try {
      let excavatorContactDetails: ExcavatorContactDetailsPayload = {
        ExcavatorID: this.props.ExcavatorID,
        ContactName: "",
        PhoneNumber: "",
        PhoneExtension: "",
        PhoneNumber2: "",
        Phone2Extension: "",
        FaxNumber: "",
        Email: "",
        WebSite: "",
        NotificationMethod: 0,
        isArchived: 0,
      };
      if (
        this.views["companyContactInfo"]["formGroup"] &&
        this.views["companyContactInfo"]["formGroup"].valid
      ) {
        panel.toggleLoading();
        panel.disableCurrentFormGroup();
        let details = this.views["companyContactInfo"]["formGroup"].value;
        //Assign values to payload
        let formKeys = Object.keys(details);
        formKeys.forEach((key) => {
          excavatorContactDetails[key] =
            details[key] != null && details[key] != false ? details[key] : "";
        });
        if (excavatorContactDetails["ContactName"].trim().length > 0) {
          this.excavatorContact$
            .saveExcavatorContact(excavatorContactDetails)
            .then((saveResult) => {
              if (saveResult) {
                this.getExcavatorInfo();
                this.updateQuickPanelExcavatorContacts(this.props.ExcavatorID);
                this.snackBarService.openSnackbar(
                  "Saved Changes",
                  SnackbarType.success
                );
                panel.toggleLoading();
                panel.close();
              } else {
                this.snackBarService.openSnackbar(
                  "Unable to save changes",
                  SnackbarType.error
                );
                panel.toggleLoading();
              }
            });
        } else {
          this.views["companyContactInfo"]["formGroup"].markAllAsTouched();
          this.snackBarService.openSnackbar(
            "Invalid input, please review fields",
            SnackbarType.error
          );
        }
      } else {
        this.views["companyContactInfo"]["formGroup"].markAllAsTouched();
        this.snackBarService.openSnackbar(
          "Invalid input, please review fields",
          SnackbarType.error
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  private updateQuickPanelExcavatorContacts(ExcavatorID) {
    try {
      this.createTicket$.sendDataChange(ExcavatorID);
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  editContactClick(event, panel) {
    try {
      let cancelButton: SidePanelButtonAction = {
        text: "Cancel",
        buttonType: AngularButtonTypes.defaultButton,
        color: "",
        action: this.onCancel,
        width: 50,
        order: 1,
      };
      let saveContactButton: SidePanelButtonAction = {
        text: "Save Contact",
        buttonType: AngularButtonTypes.raisedButton,
        color: "primary",
        action: this.onSaveEditContact,
        width: 50,
        order: 2,
      };
      this.slideoverProps = {
        title: "Edit Contact",
        isFullscreen: true,
        width: "528px",
        isForm: true,
        view: this.views["companyContactInfo"],
        views: this.views,
        actions: [cancelButton, saveContactButton],
      };
      this.currentContactID = -1;
      if (
        event &&
        event.ExcavatorContactID &&
        this.views["companyContactInfo"]["formGroup"]
      ) {
        this.currentContactID = event.ExcavatorContactID;
        let formGroup = this.views["companyContactInfo"]["formGroup"];
        formGroup.patchValue(event);
      }
      panel.open();
    } catch (error) {
      console.error(error.message);
    }
  }

  removeContactClick(event) {
    try {
      this.openDialog(
        "Delete Contact",
        "Are you sure you want to delete this contact?",
        event.ContactName,
        this.onRemoveContact,
        event
      );
    } catch (error) {
      console.error(error.message);
    }
    //modal. say are u sure.
    //remove
  }

  onRemoveContact = (details) => {
    try {
      if (details && details["ExcavatorContactID"]) {
        //Assign values to payload
        details["isArchived"] = 1;
        this.excavatorContact$
          .saveExcavatorContact(details)
          .then((saveResult) => {
            if (saveResult) {
              this.getExcavatorInfo();
              this.createTicket$.sendDataChange(this.props.ExcavatorID);
              this.snackBarService.openSnackbar(
                "Contact Deleted",
                SnackbarType.success
              );
            } else {
              this.snackBarService.openSnackbar(
                "Unable to remove",
                SnackbarType.error
              );
            }
          });
      } else {
        this.views["companyContactInfo"]["formGroup"].markAllAsTouched();
        this.snackBarService.openSnackbar(
          "Invalid input, please review fields",
          SnackbarType.error
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  onSaveEditContact = (panel) => {
    try {
      let excavatorContactDetails: ExcavatorContactDetailsPayload = {
        ExcavatorContactID: this.currentContactID,
        ExcavatorID: this.props.ExcavatorID,
        ContactName: "",
        PhoneNumber: "",
        PhoneExtension: "",
        PhoneNumber2: "",
        Phone2Extension: "",
        FaxNumber: "",
        Email: "",
        WebSite: "",
        NotificationMethod: 0,
        isArchived: 0,
      };
      if (
        this.currentContactID > 0 &&
        this.views["companyContactInfo"]["formGroup"] &&
        this.views["companyContactInfo"]["formGroup"].valid
      ) {
        panel.toggleLoading();
        panel.disableCurrentFormGroup();
        let details = this.views["companyContactInfo"]["formGroup"].value;
        //Assign values to payload
        let formKeys = Object.keys(details);
        formKeys.forEach((key) => {
          excavatorContactDetails[key] =
            details[key] != null && details[key] != false ? details[key] : "";
        });
        if (excavatorContactDetails["ContactName"].trim().length > 0) {
          this.excavatorContact$
            .saveExcavatorContact(excavatorContactDetails)
            .then((saveResult) => {
              if (saveResult) {
                this.getExcavatorInfo().then(() => {
                  this.excavatorDetailsChanged.emit(this.getExcavatorObject());
                });
                this.snackBarService.openSnackbar(
                  "Saved Changes",
                  SnackbarType.success
                );
                panel.toggleLoading();
                panel.close();
              } else {
                this.snackBarService.openSnackbar(
                  "Unable to save changes",
                  SnackbarType.error
                );
                panel.toggleLoading();
              }
            });
        } else {
          this.views["companyContactInfo"]["formGroup"].markAllAsTouched();
          this.snackBarService.openSnackbar(
            "Invalid input, please review fields",
            SnackbarType.error
          );
        }
      } else {
        this.views["companyContactInfo"]["formGroup"].markAllAsTouched();
        this.snackBarService.openSnackbar(
          "Invalid input, please review fields",
          SnackbarType.error
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /**
   * get excavator and contact information
   * Use excavatorID from props
   */
  async getExcavatorInfo() {
    let result = false;
    try {
      if (this.props && this.props.ExcavatorID > -1) {
        this.loadingData = true;
        if (this.multipleExcavatorContacts) {
          let excavatorResult = await this.excavatorContact$.getExcavatorInfo(
            this.props.ExcavatorID
          );
          this.excavator = excavatorResult["excavator"];
          this.contacts = excavatorResult["contacts"];
        } else {
          let excavatorResult = await this.createTicket$.getCompanyInfoDB(
            this.props.ExcavatorID
          );
          this.excavator = excavatorResult;
          this.contacts = [];
        }
        this.dataReady = true;
        this.loadingData = true;
        result = true;
      }
    } catch (error) {
      console.error(error.message);
    }
    return result;
  }

  openDialog(title, text, boldText, action, details): void {
    try {
      const dialogRef = this.dialog.open(ExcavatorContactsDialogComponent, {
        data: {
          title: title,
          text: text,
          boldText: boldText,
          details: details,
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.logger$.log("The dialog was closed");
      });
    } catch (error) {}
  }

  async saveAssignmentToExcavatorContact(
    assignmentToExcavatorContactPayload: AssignmentToExcavatorContactPayload
  ) {
    let result = false;
    try {
      result = await this.excavatorContact$.saveAssignmentToExcavatorContact(
        assignmentToExcavatorContactPayload
      );
    } catch (error) {
      console.error(error.message);
    }
    return result;
  }

  async getAssignmentExcavatorContacts(AssignmentID) {
    let currentContacts = [];
    try {
      currentContacts =
        await this.excavatorContact$.getAssignmentExcavatorContacts(
          AssignmentID
        );
    } catch (error) {
      console.error(error.message);
    }
    return currentContacts;
  }
}

//dialog
@Component({
  selector: "app-excavator-contacts-dialog",
  templateUrl: "./excavator-contacts-dialog.component.html",
})
export class ExcavatorContactsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ExcavatorContactsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onDeleteClick(): void {
    this.data.action(this.data.details);

    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

/**
 * When Create
 * Use negatives for if null or empty.
 * ie. ExcavatorID: -1
 * AssignmentID is necessary to know which Contacts are selected
 */
export interface ExcavatorContactsCardProps {
  AssignmentID: number;
  ExcavatorID: number;
  prefillAssignmentID?: number;
  TICKET_PROTECTION_MODE?: boolean;
  isU2TicketComplete?: boolean;
}

@NgModule({
  declarations: [ExcavatorContactsCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputTemplateModule,
    MaterialModule,
    FlexLayoutModule,
    SlideOverSidePanelModule,
  ],
  exports: [ExcavatorContactsCardComponent],
})
export class ExcavatorContactsCardModule {}
