<!-- <div class="bg"> -->
<div
  fxLayout="column"
  fxLayoutAlign="center start"
  class="signature-view-container"
>
  <span class="mat-headline-2" style="padding-bottom: 24px">{{
    locale.header
  }}</span>
  <form
    style="width: 100%"
    [formGroup]="form"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap.lt-md="0px"
    fxLayoutGap="24px"
  >
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>{{ locale.firstName }}</mat-label>
      <input
        formControlName="FirstName"
        required
        type="text"
        matInput
        [placeholder]="locale.firstName"
      />
    </mat-form-field>
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>{{ locale.lastName }}</mat-label>
      <input
        formControlName="LastName"
        required
        type="text"
        matInput
        [placeholder]="locale.lastName"
      />
    </mat-form-field>
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>{{ locale.phoneNumber }}</mat-label>
      <input
        formControlName="PhoneNumber"
        required
        type="tel"
        matInput
        placeholder="XXX-XXX-XXXX"
      />
      <!-- 2894755369 -->
    </mat-form-field>
  </form>
  <span class="mat-subtitle-1" style="padding-bottom: 12px">
    {{ locale.signatureHeader }}
  </span>
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
    <div
      #sigBox
      class="sigBox flex"
      fxFlex="910px"
      fxFlex.xs="100%"
      fxFlex.sm="100%"
      fxFlex.md="910px"
    >
      <!--{http://localhost:4200/document-viewer?DocumentID=6524&AssignmentID=35&DocumentToAngularID=1160&Authorization=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MCwiVVNFUklEIjo4NDg3NzksIkNMSUVOVElEIjoiNTciLCJVU0VSQ0FURUdPUllJRCI6NSwiVTNVU0VSIjpmYWxzZSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4NTExODk3MDcsImlhdCI6MTY3ODM4OTcwNn0.7OzUmdpL-mzAKjtabia8KduY3mOC1lF_ygFIoT_KiZA}        -->
      <app-signature-pad
        #signaturePad
        [padOptions]="signaturePadOptions"
        [props]="childProps"
      >
      </app-signature-pad>
    </div>
  </div>
  <div
    fxLayout="row wrap"
    style="width: 100%"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="space-evenly center"
  >
    <div
      fxLayout="row wrap"
      fxFlex.lt-sm="100"
      fxFlex="50"
      fxLayoutAlign="start center"
      fxLayoutAlign.xs="space-evenly center"
      fxLayoutGap.gt-xs="12px"
    >
      <button fxFlex.lt-sm="50" mat-stroked-button (click)="onClickClear()">
        {{ locale.clearButton }}
      </button>
      <button
        fxFlex.lt-sm="50"
        mat-flat-button
        color="primary"
        (click)="onClickAccept()"
      >
        {{ locale.acceptButton }}
      </button>
    </div>
    <div
      fxLayout="row wrap"
      fxFlex.lt-sm="100"
      fxFlex="50"
      fxLayoutAlign="end center"
      fxLayoutAlign.xs="space-evenly center"
    >
      <button
        fxFlex="50"
        fxFlex.lt-sm="100"
        mat-stroked-button
        (click)="onClickReadAgain()"
      >
        {{ locale.readButton }}
      </button>
    </div>
  </div>
</div>
<!-- </div> -->
