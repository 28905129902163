<div class="competers-modal">
  <div
    class="competers-modal-body"
    [style.max-width]="maxwidth"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon
      style="font-size: 80px; width: 80px; height: 80px; padding-bottom: 24px"
      ><img id="yesGreenIcon"
    /></mat-icon>
    <h3 class="mat-headline-3" style="padding-bottom: 24px">success</h3>
    <p class="mat-body-2" style="text-align: center; padding-bottom: 48px">
      <ng-content class="competers-modal-content"></ng-content>
    </p>
    <button mat-flat-button color="primary" (click)="close()">close</button>
  </div>
</div>
<div class="competers-modal-background"></div>
