import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { U2_USER_CATEGORY_ID } from "src/app/modules/core/services/user/user";
import { UserService } from "src/app/modules/core/services/user/user.service";

@Component({
  selector: 'app-document-type-picker-modal',
  templateUrl: './document-type-picker-modal.component.html'
})
export class DocumentTypePickerModalComponent {

  data: any;
  isFail: boolean;
  selectedOption: any;
  enableConfirm: boolean = true;

  types: any
  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<DocumentTypePickerModalComponent>,
    private userService: UserService,

  ) {
    this.data = Data;
    this.types = this.data.docTypes    
  }

  ngOnInit() {    
    if (
      this.userService.getUserCategoryID() !=
      U2_USER_CATEGORY_ID.Locator.toString()) {
        this.types.push("OFFICE")
    } 
  }
  
  typeSelected(){    
    this.enableConfirm = false
  }

  onConfirm(event) {
    this.dialogRef.close({ event: "close", result: this.selectedOption });
  }
}
