import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from "@angular/core";

import { Subscription } from "rxjs";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketMessageActions } from "../../../CreateTicketInterface";
//import { EventEmitter } from "protractor";

@Component({
  selector: "app-create-ticket-content-panel",
  templateUrl: "./create-ticket-content-panel.component.html",
  styleUrls: ["./create-ticket-content-panel.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketContentPanelComponent
  extends BasePanelComponent
  implements OnInit, OnChanges, OnDestroy
{
  // @ViewChild('contentMatTabGroup') contentMatTabGroup:MatTabGroup;

  @Output() toggleCreateBtnWhileLoading: EventEmitter<any> = new EventEmitter();
  @Output() updateAddress: EventEmitter<any> = new EventEmitter();
  parentMsgSubscriber: Subscription;
  childMsgSubscriber: Subscription;
  className: string = "CreateTicketContentPanelComponent";
  childProps = {};
  views = {};
  // tabs = ["companyInfo", "worksite", "details", "docs", "schedule"]
  tabs = ["companyInfo", "worksite", "details", "docs"];
  navBackground = "";
  tabIndex = 0;
  documents = {};
  utilityViews = {};
  commonViews = {};

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService
  ) {
    super(logger$);

    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      documents: this.documents,
    };

    this.childMsgSubscriber = this.compMsg$
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        // this.onReceiveChildMessage(nextMsg);
      });
  }

  ngOnInit(): void {
    try {
      this.parentMsgSubscriber = this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          this.onReceiveParentMessage(nextMsg);
        });
    } catch (error) {
      this.logger$.log(this.className + " ngOnInit1 " + error.message);
    }
  }

  ngOnDestroy() {
    try {
      if (this.childMsgSubscriber) {
        this.childMsgSubscriber.unsubscribe();
      }
      if (this.parentMsgSubscriber) {
        this.parentMsgSubscriber.unsubscribe();
      }
    } catch (error) {
      this.logger$.error("qpOnDest: " + error.message);
    }
  }

  indexChange(index) {
    this.tabIndex = index;
  }

  updateAddressFieldInPanel(address) {
    this.updateAddress.emit(address);
  }

  goNext() {
    if (this.tabIndex + 1 > this.tabs.length) {
      this.tabIndex = 0;
    } else {
      this.tabIndex++;
    }
  }

  goToTabByLabel(label) {
    if (this.tabs.indexOf(label) > -1) {
      this.tabIndex = this.tabs.indexOf(label);
    }
  }

  private onReceiveParentMessage(nextMsg: ComponentMessage) {
    if (nextMsg.action) {
      switch (nextMsg.action) {
        case CreateTicketMessageActions.SWITCH_CONTENT_VIEW:
          if (nextMsg.message && nextMsg.message.groupKey) {
            this.goToTabByLabel(nextMsg.message.groupKey);
          }
          break;
        case CreateTicketMessageActions.EXCAVATOR_NAME_UPDATED:
          this.compMsg$.sendToMessageStream(nextMsg);
          break;
        case CreateTicketMessageActions.ADDRESS_UPDATED:
          //send to children
          this.compMsg$.sendToMessageStream(nextMsg);
          break;
        case CreateTicketMessageActions.DATA_RESET:
          if (this.compMsg$) {
            this.compMsg$.sendToMessageStream(nextMsg);
          }
          break;
        case CreateTicketMessageActions.QUICK_EXCAVATOR_CONTACTS_UPDATE:
          if (this.compMsg$) {
            this.compMsg$.sendToMessageStream(nextMsg);
          }
          break;
        default:
          break;
      }
    }
  }

  /**
   *  If receives views then it is ready to load
   */
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"]
      ) {
        this.views = changes["props"]["currentValue"]["views"];
        this.documents = changes["props"]["currentValue"]["documents"];
        this.utilityViews = changes["props"]["currentValue"]["utilityViews"];
        this.commonViews = changes["props"]["currentValue"]["commonViews"];

        this.childProps["views"] = this.views;
        this.childProps["documents"] = this.documents;
        this.childProps["utilityViews"] = this.utilityViews;
        this.childProps["commonViews"] = this.commonViews;
        if (changes["props"]["currentValue"]["prefillFields"]) {
          this.childProps["prefillFields"] =
            changes["props"]["currentValue"]["prefillFields"];
        }
        this.childProps = { ...this.childProps };
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  // event: boolean
  onCreateBtnWhileLoading(event: any) {
    this.toggleCreateBtnWhileLoading.emit(event);
  }
}
