import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LoggerService} from 'src/app/modules/core/services/logger/logger.service';
import {environment} from 'src/environments/environment';
import {NavigationBarService} from '../../navigation-bar/navigation-bar.service';
import {sideNavRoute} from '../side-nav-route';
import {SettingID} from 'src/app/modules/core/services/user/setting';
import {UserService} from 'src/app/modules/core/services/user/user.service';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {ProgressBarService} from '../../progress-bar/progress-bar.service';

export const onSideNavChange = trigger('onSideNavChange', [
  state(
    'mini',
    style({
      width: '72px',
    })
  ),
  state(
    'open',
    style({
      width: '240px',
    })
  ),
  transition('mini => open', animate('250ms ease-in-out')),
  transition('open => mini', animate('250ms ease-in-out')),
]);

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [onSideNavChange],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  @Input() sidenavState: string = 'mini';

  appVersion: string = environment.appVersion;
  navSubscriber: Subscription;
  userSubscriber: Subscription;
  @Output() titleChange = new EventEmitter<any>();



  public linkText: boolean = true;

  routes = [
    {
      title: 'Home',
      path: 'home',
      icon: 'home',
      selected: false,
    },
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationBar: NavigationBarService,
    private logger: LoggerService,
    private userService: UserService,
    private progressBarService: ProgressBarService
  ) { }

  /**
   * setup subscriptions
   */
  ngOnInit(): void {
    this.setupUserSubscription();
    this.setupRouteSubscription();
  }

  /**
   * unsubscribe subscribers on destroy
   */
  ngOnDestroy(): void {
    if (this.navSubscriber) {
      this.navSubscriber.unsubscribe();
    }
    if (this.userSubscriber) {
      this.userSubscriber.unsubscribe();
    }
  }

  /**
   * setupsubscriber to userService's current user in order to keep sidenav routes accurate
   */
  setupUserSubscription() {
    this.userSubscriber = this.userService.currentUser$.subscribe(() => {
      this.setupSideNavRoutes();
    });
  }

  /**
   * check settings and add available side nav routes to this.routes array
   */
  setupSideNavRoutes() {
    try {
      this.routes = [];

      //NOTE: Icons are from custom icons NOT built-in icons. See icons.json for more
      if (this.userService.isSettingActive(SettingID.HOME_WORKSPACE)) {
        this.routes.push({
          title: 'Home',
          path: 'home',
          icon: 'home',
          selected: true,
        });
      }

      if (this.userService.isSettingActive(SettingID.CREATE_TICKET)) {
        const createTicketRoute: sideNavRoute = {
          title: 'Create Ticket',
          path: 'create-ticket',
          icon: 'create ticket',
          selected: false,
        };
        this.routes.push(createTicketRoute);
      }
      if (this.userService.isSettingActive(SettingID.TICKET_SUMMARY)) {
        const ticketSummary: sideNavRoute = {
          title: 'Ticket Summary',
          path: 'ticket-summary',
          icon: 'list',
          selected: false,
        };
        this.routes.push(ticketSummary);
      }
      if (this.userService.isSettingActive(SettingID.SHOW_PUNCH_CLOCK)) {
        const punchClock: sideNavRoute = {
          title: 'Punch Clock',
          path: 'punch-clock',
          icon: 'stopwatch',
          selected: false,
        };
        this.routes.push(punchClock);
      }

      // Reports 
      if (this.userService.isSettingActive(SettingID.REPORTS_TAB_ACCESS)) {
        this.routes.push({
          title: 'Reports',
          path: 'reports',
          icon: 'report_tab',
          selected: false,
        });
      }

      if (this.userService.user.settings) {
        if (this.userService.isSettingActive(SettingID.SCHEDULER)) {
          const schedulerRoute: sideNavRoute = {
            title: 'Scheduler',
            path: 'scheduler',
            icon: 'calendar',
            selected: false,
          };
          this.routes.push(schedulerRoute);
        }

        if (this.userService.isSettingActive(SettingID.SYSTEM_MAINTENANCE)) {
          const systemMaintenanceRoute: sideNavRoute = {
            title: 'System Maintenance',
            path: 'system-maintenance',
            icon: 'tools',
            selected: false,
          };
          this.routes.push(systemMaintenanceRoute);
        }

      }

      //general settings is always available to the user 
      const generalSettingsRoute: sideNavRoute = {
        title: "Settings",
        path: "settings",
        icon: `settings`,
        selected: false,
      };
      this.routes.push(generalSettingsRoute);

    } catch (error) {
      this.logger.error(error);
    }
  }

  //For Testing purposes TODO refactor
  setupRouteSubscription() {
    try {
      this.progressBarService.start();
      const path = this.router.url;


      for (const route of this.routes) {
        if (path.indexOf(route.path) > 0) {
          route['selected'] = true;
          this.titleChange.emit(route['title'])
        } else {
          route['selected'] = false;
        }
      }
      this.progressBarService.stop();
      this.navSubscriber = this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((route) => {
            return route['url'];
          })
        )
        .subscribe((url: string) => {
          this.progressBarService.start();
          for (const route of this.routes) {
            if (url.indexOf(route.path) > 0) {
              route['selected'] = true;
            } else {
              route['selected'] = false;
            }
          }
          this.progressBarService.stop();
        });
    } catch (error) {
      console.error(error);
      this.progressBarService.stop();
    }
  }

  /**
   * navigate to routed path and close menu
   * @param route
   */
  handleRouteClick(route) {
    try {
      if (route.path) {
        this.titleChange.emit(route.title)

        this.changeRoute(route.path);
      }
      this.navigationBar.closeMenu();
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * calls router to navigate to given path relative to active path
   * @param path
   */
  changeRoute(path: string) {
    // this.menuToggle.nativeElement.checked = false;
    this.router.navigate([path], {relativeTo: this.activatedRoute});
  }

  reset() {
    this.router.navigate(['']);
  }

  isImageUrl(url: string): boolean {
    // Regular expression to match common image file extensions
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp|ico)$/i;

    // Test if the URL ends with a known image file extension
    return imageExtensions.test(url);
  }
}
