<div class="legend-list-wrapper">
  <ng-container *ngFor="let layer of legendData$ | async">
    <ng-container *ngIf="layer | isIterable; else notIterable">
      <span class="legend-list-item-header">
        {{ layer | iterableGroupName }}
      </span>
      <ng-container *ngFor="let subLayer of layer">
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ props: subLayer }"
        >
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #notIterable>
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ props: layer }"
      >
      </ng-container>
    </ng-template>
  </ng-container>
</div>

<ng-template #template let-props="props">
  <div
    class="legend-list-item"
    [ngClass]="props.groupName ? 'flex-row-reverse' : ''"
  >
    <span
      [ngClass]="
        props.groupName
          ? 'legend-list-item-subtitle'
          : 'legend-list-item-header'
      "
    >
      {{ props.name }}
    </span>
    <ng-container *ngIf="props.type === 'icon'">
      <img [src]="props.imageData | DataToUrl" alt="{{ props.name }}" />
    </ng-container>
    <ng-container *ngIf="props.type === 'line'"
      ><span
        style="
          width: 75px;
          height: 5px;
          background-color: #000000;
          margin: 0 1em 0 0;
        "
        [style.background-color]="'RGBA(' + props.color + ')'"
        [style.height]="props.height + 'px'"
      ></span
    ></ng-container>
  </div>
</ng-template>
