import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { SchedulerCreateTicketDialogComponent } from "./scheduler-create-ticket-dialog.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CreateTicketComponentModule } from "src/app/modules/create-ticket/create-ticket/create-ticket.component";

@NgModule({
  declarations: [SchedulerCreateTicketDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    CreateTicketComponentModule,
  ],
  exports: [SchedulerCreateTicketDialogComponent],
})
export class SchedulerCreateTicketDialogModule {}
