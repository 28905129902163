// import { google } from '@agm/core/services/google-maps-types';
// import { LatLng } from '@agm/core';
// import { LatLng } from '@agm/core/services/google-maps-types';

import { GoogleMap } from "@angular/google-maps";
import { Observable, from, of, Subject } from "rxjs";

export class GoogleMapsShapeController {
  agmShapes: any[] = [];
  className = "GoogleMapsShapeController";

  private updateShapeObservable: Subject<any>;

  constructor() {
    // create observable
    this.updateShapeObservable = new Subject<any>();
  }

  finishedDrawing(event: any, shapeType) {
    if (shapeType == 3) {
      // Polygon
      if (event.overlay.getPath().getArray().length >= 3) {
        let shapeObj = { shape: event.overlay, type: shapeType, statusID: 1 };
        this.addPolygonListeners(shapeObj);
        this.agmShapes.push(shapeObj);
      } else {
        event.overlay.setMap(null);
        event.overlay = null;
      }
    } else if (shapeType == 2) {
      // Line
      if (event.overlay.getPath().getArray().length >= 2) {
        let shapeObj = { shape: event.overlay, type: shapeType, statusID: 1 };
        this.addPolyLineListeners(shapeObj);
        this.agmShapes.push(shapeObj);
      } else {
        event.overlay.setMap(null);
        event.overlay = null;
      }
    } else if (shapeType == 1) {
      // Point
      let shapeObj = { shape: event.overlay, type: shapeType, statusID: 1 };
      this.addCircleListeners(shapeObj);
      this.agmShapes.push(shapeObj);
    } else {
      event.overlay.setMap(null);
      event.overlay = null;
    }
    //TODO: Fix bug with creating polygons; extra point is created when finishing polygon
    // if(event.overlay.getPath().getArray().length >= 3){
    //   let curPath = JSON.parse(JSON.stringify(event.overlay.getPath().getArray()));

    //   let curOptions = JSON.parse(JSON.stringify(this.polygonOptionsEditingOn));
    //   curOptions.path = curPath;

    //   this.log(event.overlay,curPath);
    //   let curPolygon = new google.maps.Polygon(curOptions);

    //   this.log(curPolygon.getPath().getArray());
    //   curPolygon.setMap(this.map);
    //   this.agmPolygons.push(curPolygon);
    // }
    // event.overlay.setMap(null);
  }

  addShape(
    coords: any[],
    shapeType: number,
    digsiteShapeID: number,
    map: GoogleMap,
    isDrawing: boolean
  ) {
    var shapeOptions: object = {
      ...this.getDrawingOptions(map, isDrawing, shapeType),
      map: map["_googleMap"],
    };
    if (shapeType == 3) {
      var polygon = new google.maps.Polygon({
        ...shapeOptions,
        paths: coords,
      });
      let shapeObj = {
        shape: polygon,
        type: shapeType,
        statusID: 4,
        digsiteShapeID: digsiteShapeID,
      };
      this.addPolygonListeners(shapeObj);
      this.agmShapes.push(shapeObj);
    } else if (shapeType == 2) {
      var polyline = new google.maps.Polyline({
        ...shapeOptions,
        path: coords,
      });
      let shapeObj = {
        shape: polyline,
        type: shapeType,
        statusID: 4,
        digsiteShapeID: digsiteShapeID,
      };
      this.addPolyLineListeners(shapeObj);
      this.agmShapes.push(shapeObj);
    } else if (shapeType == 1) {
      if (coords.length > 1) {
        var circle = new google.maps.Circle({
          ...shapeOptions,
          center: coords[0],
          radius: this.getDistanceBetweenPoints(
            coords[0].lat,
            coords[0].lng,
            coords[1].lat,
            coords[1].lng
          ),
        });

        let shapeObj = {
          shape: circle,
          type: shapeType,
          statusID: 4,
          digsiteShapeID: digsiteShapeID,
        };
        this.addCircleListeners(shapeObj);
        this.agmShapes.push(shapeObj);
      }
    } else {
      // invalid shape type
    }
  }

  addLocalShape(
    coords: any[],
    shapeType: number,
    map: GoogleMap,
    isDrawing: boolean
  ) {
    var shapeOptions: object = {
      ...this.getDrawingOptions(map, isDrawing, shapeType),
      map: map["_googleMap"],
    };
    if (shapeType == 3) {
      var polygon = new google.maps.Polygon({
        ...shapeOptions,
        paths: coords,
      });
      let shapeObj = { shape: polygon, type: shapeType, statusID: 1 };
      this.addPolygonListeners(shapeObj);
      this.agmShapes.push(shapeObj);
    } else if (shapeType == 2) {
      var polyline = new google.maps.Polyline({
        ...shapeOptions,
        path: coords,
      });
      let shapeObj = { shape: polyline, type: shapeType, statusID: 1 };
      this.addPolyLineListeners(shapeObj);
      this.agmShapes.push(shapeObj);
    } else if (shapeType == 1) {
      if (coords.length > 1) {
        var circle = new google.maps.Circle({
          ...shapeOptions,
          center: coords[0],
          radius: this.getDistanceBetweenPoints(
            coords[0].lat,
            coords[0].lng,
            coords[1].lat,
            coords[1].lng
          ),
        });

        let shapeObj = { shape: circle, type: shapeType, statusID: 1 };
        this.addCircleListeners(shapeObj);
        this.agmShapes.push(shapeObj);
      }
    } else {
      // invalid shape type
    }
  }

  updateShape(shapeObj: any, statusID: number) {
    shapeObj.statusID = statusID;
    this.updateShapeObservable.next(true);
  }

  updateShapes() {
    this.updateShapeObservable.next(true);
  }

  addPolygonListeners(shapeObj: any) {
    google.maps.event.addListener(shapeObj.shape.getPath(), "insert_at", () => {
      this.updateShape(shapeObj, 2);
    });
    google.maps.event.addListener(shapeObj.shape.getPath(), "set_at", () => {
      this.updateShape(shapeObj, 2);
    });
    google.maps.event.addListener(shapeObj.shape.getPath(), "remove_at", () => {
      this.updateShape(shapeObj, 2);
    });
  }

  addPolyLineListeners(shapeObj: any) {
    google.maps.event.addListener(shapeObj.shape.getPath(), "insert_at", () => {
      this.updateShape(shapeObj, 2);
    });
    google.maps.event.addListener(shapeObj.shape.getPath(), "set_at", () => {
      this.updateShape(shapeObj, 2);
    });
    google.maps.event.addListener(shapeObj.shape.getPath(), "remove_at", () => {
      this.updateShape(shapeObj, 2);
    });
  }

  addCircleListeners(shapeObj: any) {
    google.maps.event.addListener(shapeObj.shape, "radius_changed", () => {
      this.updateShape(shapeObj, 2);
    });
    google.maps.event.addListener(shapeObj.shape, "center_changed", () => {
      this.updateShape(shapeObj, 2);
    });
  }

  updateShapesAfterUpload() {
    Object.keys(this.agmShapes)
      .reverse()
      .forEach((i) => {
        if (this.agmShapes[i].statusID == 3) {
          this.agmShapes.pop();
        } else {
          this.agmShapes[i].statusID = 4;
        }
      });
  }

  clear(endIndex = 0) {
    // set the status's to 3 so it will be deleted in the lambda
    Object.keys(this.agmShapes)
      .reverse()
      .forEach((i) => {
        this.agmShapes[i].shape.setMap(null);
        this.agmShapes[i].statusID = 3;
      });
    this.updateShapes();
  }

  clearAll() {
    Object.keys(this.agmShapes)
      .reverse()
      .forEach((i) => {
        this.agmShapes[i].shape.setMap(null);
        this.agmShapes[i].statusID = 3;
      });
    this.updateShapes();
    this.agmShapes = [];
  }

  refresh(map, isDrawing) {
    for (var i = 0; i < this.agmShapes.length; i++) {
      this.agmShapes[i].shape.setOptions(
        this.getDrawingOptions(map, isDrawing, this.agmShapes[i].type)
      );
    }
  }

  getRAWShapes() {
    return this.agmShapes;
  }

  getShapes(startIndex = 0) {
    let returnShapes = null;

    if (this.agmShapes.length > 0) {
      returnShapes = [];

      for (let i = startIndex; i < this.agmShapes.length; i++) {
        let curPts = null;

        let shapeType = this.agmShapes[i].type;
        let center = this.getCenter(i);
        let statusID = this.agmShapes[i].statusID;

        if (shapeType == 2 || shapeType == 3) {
          curPts = this.agmShapes[i].shape.getPath().getArray();
        } else if (shapeType == 1) {
          let curRadius = this.agmShapes[i].shape.getRadius();
          let distanceCord = this.getDistanceFromCordinate(
            center.lat,
            center.lng,
            curRadius
          );
          curPts = [
            {
              lat: () => {
                return center.lat;
              },
              lng: () => {
                return center.lng;
              },
            },
            {
              lat: () => {
                return distanceCord.lat;
              },
              lng: () => {
                return distanceCord.lng;
              },
            },
          ];
          // let newRadius = this.getDistanceBetweenPoints(center.lat, center.lng, newCord.lat, newCord.lng);
        }

        let shapeObj = {
          points: curPts,
          type: shapeType,
          center: center,
          statusID: statusID,
        };

        if (this.agmShapes[i].digsiteShapeID != null) {
          shapeObj["digsiteShapeID"] = this.agmShapes[i].digsiteShapeID;
        }

        if (curPts) {
          returnShapes.push(shapeObj);
        }
      }
    }
    return returnShapes;
  }

  getDrawingOptions(map, isDrawing, shapeType) {
    let colour = "#666666";
    let strokeColour = "#666666";
    let opacity = 0.2;
    let strokeWeight = 4;
    let strokeOpacity = 0.8;

    if (map && map.getMapTypeId() == "hybrid") {
      colour = "#eeeeee";
      strokeColour = "#eeeeee";
    } else {
      colour = "#666666";
      strokeColour = "#666666";
    }
    // if (shapeType == 3) {
    return {
      // matches optionsEditingOn
      strokeColor: strokeColour,
      strokeOpacity: strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: colour,
      fillOpacity: opacity,
      draggable: false,
      editable: isDrawing,
      clickable: isDrawing,
    };
    // } else if (shapeType == 2) {
    //   return { // matches optionsEditingOn
    //     strokeColor: strokeColour,
    //     strokeOpacity: strokeOpacity,
    //     strokeWeight: strokeWeight,
    //     draggable: false,
    //     editable: isDrawing,
    //     clickable: isDrawing
    //   };
    // } else if (shapeType == 1) {
    //   return { // matches optionsEditingOn
    //     strokeColor: strokeColour,
    //     strokeOpacity: strokeOpacity,
    //     strokeWeight: strokeWeight,
    //     draggable: false,
    //     editable: isDrawing,
    //     clickable: isDrawing
    //   };
    // }else{
    //   return null;
    // }
  }

  getCenter(index) {
    let center = null;

    if (index >= 0 && index < this.agmShapes.length && this.agmShapes[index]) {
      let shapeType = this.agmShapes[index].type;

      if (shapeType == 3) {
        let curPts = this.agmShapes[index].shape.getPath().getArray();
        center = this.get_polygon_centroid(curPts);
      } else if (shapeType == 2) {
        let curPts = this.agmShapes[index].shape.getPath().getArray();
        center = { lat: curPts[0].lat(), lng: curPts[0].lng() };
      } else if (shapeType == 1) {
        let curCenter = this.agmShapes[index].shape.getCenter();
        center = { lat: curCenter.lat(), lng: curCenter.lng() };
      }
    }
    return center;
  }

  // https://stackoverflow.com/questions/7477003/calculating-new-longitude-latitude-from-old-n-meters
  // Modified so that it's not adding the distance to both lat and long
  getDistanceFromCordinate(lat, lng, meters) {
    // number of km per degree = ~111km (111.32 in google maps, but range varies
    // between 110.567km at the equator and 111.699km at the poles)
    // 1km in degree = 1 / 111.32km = 0.0089
    // 1m in degree = 0.0089 / 1000 = 0.000008983
    return { lat: lat + meters * 0.000008983, lng: lng };
  }

  // https://ourcodeworld.com/articles/read/1021/how-to-calculate-the-distance-between-2-markers-coordinates-in-google-maps-with-javascript
  getDistanceBetweenPoints(lat1, lng1, lat2, lng2) {
    // The radius of the planet earth in meters
    let R = 6399594; //6335439; //6399594;
    let dLat = this.degreesToRadians(lat2 - lat1);
    let dLong = this.degreesToRadians(lng2 - lng1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) *
        Math.cos(this.degreesToRadians(lat1)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);

    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    return distance;
  }
  degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  // stackoverflow.com/questions/9692448/how-can-you-find-the-centroid-of-a-concave-irregular-polygon-in-javascript
  // only returning the first point because algo is kinda buggy
  private get_polygon_centroid(pts) {
    return { lat: pts[0].lat(), lng: pts[0].lng() };
  }

  getUpdateShapeObservable() {
    return this.updateShapeObservable.asObservable();
  }
}
