import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-map-overlay-container',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
  template: `
    <div #container class="box-border flex flex-col p-3 pt-4">
      @if (selfClosing) {
        <div class="flex flex-row justify-end items-center absolute right-1 top-3">
          <button
            class="flex justify-center items-center appearance-none border-none p-0 pointer-events-auto hover:cursor-pointer bg-transparent"
            (mousedown)="handleClose()"
            (click)="handleClose()">
            <mat-icon style="font-size: 16px; height: 16px; width: 16px">close</mat-icon>
          </button>
        </div>
      }
      <div class="box-border flex flex-col justify-center items-center gap-1 h-full">
        @if (loading) {
          <mat-spinner></mat-spinner>
        } @else {
          <ng-content></ng-content>
        }
      </div>
    </div>
  `,
})
export class MapOverlayContainerComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;
  @Input() loading: boolean = false;
  @Input() selfClosing: boolean = false;
  @Output() closed = new EventEmitter<void>();

  // services
  private renderer = inject(Renderer2);

  //members
  private unlistenFn: () => void;

  constructor() {}

  ngOnInit() {
    this.unlistenFn = this.renderer.listen('document', 'click', (event) => {
      if (event.composedPath().indexOf(this.container.nativeElement) === -1) {
        this.closed.emit();
      }
    });
  }

  ngOnDestroy() {
    if (this.unlistenFn !== undefined) {
      this.unlistenFn();
    }
  }

  handleClose() {
    this.closed.emit();
  }
}
