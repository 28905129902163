<div class="sync-banner" *ngIf="ticketsToSync.length > 0">
  <span class="mat-subtitle-2">
    {{ ticketsToSync.length }} {{ ticketsToSync.length > 1 ? 'Tickets' : 'Ticket' }} To Sync
  </span>
</div>

<div
  class="h-full"
  fxHide
  fxShow.gt-xs
  *ngIf="isValidDownload; else invalidDownload"
  [ngClass]="{ 'h-[calc(100%-32px)]': ticketsToSync.length > 0 }">
  <div
    class="box-border p-6 absolute pointer-events-none w-full h-full flex justify-start items-start z-50"
    [ngClass]="{
      'pl-[425px]': selection.length === 2,
      'pl-12px': selection.length !== 2,
      'hidden': selection.includes(0) && selection.length !== 2
    }">
    <app-floating-tab-select class="ios-shadow-fix rounded-md" [tabs]="_tabs" [(activeTab)]="selection" [multiple]="true" />
  </div>
  <div class="flex flex-row justify-start items-start h-full w-full">
    <div
      #leftDiv
      class="h-full bg-cyan-50 overflow-hidden" 
      [style.width]="selection.length > 1 ? '403px' : selection.includes(0) ? '100%' : '0'">
      <app-ticket-list
        [TicketListItems]="ticketList"
        [CurrentlySelectedTicket]="currentlySelectedTicket$"
        (ticketListItemClick)="onTicketItemClick($event)"
        (syncEvent)="onSyncEvent()">
        <app-floating-tab-select
          [ngClass]="{
            'hidden': selection.length === 2 || selection.includes(1)
          }"
          [tabs]="_tabs"
          [(activeTab)]="selection"
          [multiple]="true" />
      </app-ticket-list>
      <ng-content select="[left]" />
    </div>
    <div #rightDiv class="flex-grow flex-1 h-full overflow-hidden">
      <app-ticket-map [ticketStream$]="ticketStream$" (openTicket)="onTicketItemClick($event)" [showRouting$]="showRoutingPins$"></app-ticket-map>
      <ng-content select="[right]" />
    </div>
  </div>
</div>

<div class="h-full" fxHide fxShow.xs *ngIf="isValidDownload; else invalidDownload">
  <mat-tab-group headerPosition="below" class="h-full">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex flex-col text-[black] items-center text-xs">
          <mat-icon svgIcon="ticket"></mat-icon>
        </div>
      </ng-template>
      <div *ngIf="isValidDownload; else invalidDownload">
        <app-ticket-list
          [TicketListItems]="ticketList"
          [CurrentlySelectedTicket]="currentlySelectedTicket$"
          (ticketListItemClick)="onTicketItemClick($event)"
          (syncEvent)="onSyncEvent()"></app-ticket-list>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="flex flex-col text-[black] items-center text-xs">
          <mat-icon svgIcon="map"></mat-icon>
        </div>
      </ng-template>
      <div *ngIf="this.screenWidth < 640" class="w-full h-full absolute bg-cyan-50">
        <app-ticket-map [ticketStream$]="ticketStream$" (openTicket)="onTicketItemClick($event)" />
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-template #invalidDownload>
  <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
    <mat-icon style="font-size: 150px; height: 150px; width: 150px"><img id="closeIcon" alt="Close" /></mat-icon>
    <p class="mat-headline-2">400</p>
    <p class="mat-headline-2">Mauvaise requête</p>
    <p class="mat-headline-2">BAD REQUEST</p>
  </div>
</ng-template>
