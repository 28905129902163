import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../../../../pipes/truncate.pipe';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-inspector-content',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule, TruncatePipe, MatButtonModule],
  template: `
    @if (data.length === 0) {
      <div class="empty">
        <mat-icon>info</mat-icon>
        <p>No features</p>
      </div>
    } @else {
      <div class="feature-data">
        @for (item of data[index] | keyvalue; track item.key) {
          <div>
            <b>{{ item.key | titlecase }}</b>
            : {{ item.value | truncate: 30 }}
        </div>
        }
      </div>
      @if (data.length > 1) {
        <div class="arrow-div">
          <button mat-icon-button (click)="decrementIndex()" [disabled]="index <= 0">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <button mat-icon-button (click)="incrementIndex()" [disabled]="index >= data.length - 1">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      }
    }
  `,
  styleUrls: ['./inspector-content.component.scss'],
})
export class InspectorContentComponent implements OnChanges {
  @Input() data: FeatureInspectorData = [];
  // services
  private cdr = inject(ChangeDetectorRef)

  // members
  protected index = 0;

  constructor() {}

  ngOnChanges(changes:SimpleChanges): void {
    if (changes.data) {
      this.index = 0;
    }
  }

  incrementIndex() {
    if (this.index < this.data.length) {
      this.index++;
      this.cdr.detectChanges()
    }
  }

  decrementIndex() {
    if (this.index > 0) {
      this.index--;
      this.cdr.detectChanges()
    }
  }
}

export type FeatureInspectorData = Array<Record<string, unknown>>;
