import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { LoggerService } from "../../ticket/services/logger/logger.service";
import { PrivacyPolicyDialogComponent } from '../login/login.component';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';

@Component({
  selector: 'app-authorized-user',
  templateUrl: './authorized-user.component.html',
  styleUrl: './authorized-user.component.scss'
})
export class AuthorizedUserComponent implements OnInit {

  showUserClients: boolean = false;
  user: any = {};
  clientList: any[] = [];
  tokens: any;
  userToken: string = "";
  showError: boolean = false;
  errorMessage: string = "authenticating...";

  constructor(
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private snackBarService: SnackbarService,
    private progressBarService: ProgressBarService,
  ) {

  }

  async ngOnInit() {
    try {
      let route = this.route.snapshot;
      if (route.queryParams["code"]) {
        let code = route.queryParams["code"];
        let tokens = await this.getCognitoToken(code);
        this.tokens = tokens.data
        await this.gatherAccounts();
      }
    } catch (error) {
      this.handleLoginError(error);
    }
  }

  getRedirectURI() {
    return window.location.href.split('authorized-user')[0] + "authorized-user";
  }

  async gatherAccounts() {
    let userToken = this.tokens.access_token;
    
    let username = localStorage.getItem("username")
    localStorage.removeItem("username");

    let data = await this.userService.gatherAccounts(userToken);
    if (data === 'error' || data.valid === false) {
      this.handleLoginError(data.message ?? 'An error occured while logging in.');
    } else if (data.user && data.user.accounts) {
      this.user = data.user;
      if ((username != null && username != "") && (this.user.UserEmail && username != this.user.UserEmail)) {
        this.handleLoginError('User email mismatch');
      } else {
        if (data.user.accounts.length === 1) {
          this.handleLogin(data.user.accounts[0])
        } else {
          this.showClients(data)
        }
      }
    }
  }

  async getCognitoToken(code: string) {
    try {
      let redirectURI = this.getRedirectURI();

      let cognitoClientID = localStorage.getItem("client_id");
      if (!cognitoClientID) {
        let state = this.route.snapshot.queryParams["state"];
        if (state != null) {
          cognitoClientID = state;
          localStorage.setItem("client_id", cognitoClientID);
        }
      }
      if (cognitoClientID) {
        let cognitoInfo = environment.cognito[cognitoClientID];
        let authToken = cognitoInfo.authToken;
        let url = `${cognitoInfo.domain}/oauth2/token`;
  
        const body = {
          "grant_type": "authorization_code",
          "code" : code,
          "redirect_uri" : redirectURI
        };
  
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": `Basic ${authToken}`
        }
  
        const result = await axios.post(url, body, {headers: headers});
        return result;
      } else {
        this.snackBarService.openSnackbar("Login Failed", SnackbarType.error)
        this.router.navigate(["app"]);
      }
    } catch (error) {
      let message = ""
      if (error.name === "AxiosError") {
        message = message + `${error.response.data.error}`;
      } else {
        message = error.message;
      }
      throw new Error(message);
    }
  }

  showClients(data: any) {
    this.errorMessage = "";
    this.showUserClients = true;
    this.clientList = data.user.accounts;
  }

  async handleLogin(account: any) {
    this.progressBarService.start();
    this.userService.setUserCentralization();
    this.userService.storeCognitoTokens(this.tokens);

    let loginUser = {
			email: this.user.UserEmail,
			clientID: account.ClientID,
			accessToken: this.tokens.access_token
    }

    let authToken = await this.userService.loginWithCognito(loginUser);
    if (authToken) {
      const loginResult = await this.userService.loginWithToken(authToken);
      if (loginResult) {
        this.progressBarService.stop();
        this.router.navigate(["app"]);
      } else {
        this.handleLoginError('login failed');
      }
    } else {
      this.snackBarService.openSnackbar(`Authorization Token Generation Failed`, SnackbarType.error);
      this.userService.logout();
      this.router.navigate([""]);
    }
  }
  
  handleLoginError(message) {
    this.showUserClients = false;
    this.showError = true;
    this.errorMessage = message;
    this.progressBarService.stop();
  }

  returnToLogin() {
    this.userService.logout();
    this.router.navigate(["login"]);
  }
  
  openPrivacyPolicy() {
    try {
      const dialogRef = this.dialog.open(PrivacyPolicyDialogComponent);

      dialogRef.afterClosed().subscribe();
    } catch (error) {
      this.loggerService.error(error);
    }
  }
}
