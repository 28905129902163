<div class="h-full">
  <div class="sys-info-page">
    <div class="white-background-for-table info-table">
      <div class="round-tables">
        <!-- Info table -->
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="header">
            <th mat-header-cell *matHeaderCellDef></th>
          </ng-container>
          <ng-container matColumnDef="label">
            <td
              class="label-cell info-item"
              mat-cell
              *matCellDef="let item"
              (click)="item.action && item.action()"
            >
              {{ item.label.toUpperCase() }}
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <td
              mat-cell
              *matCellDef="let item"
              class="value-cell info-item uppercase"
            >
              {{ item.value }}
              <button
                class="inline-button"
                mat-button
                (click)="item.button()"
                *ngIf="item.button"
              >
                <mat-icon svgIcon="{{ item.icon }}"></mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-row *matRowDef="let row; columns: ['label', 'value']"></tr>
        </table>
      </div>
    </div>
  
    <!-- Vesrion History Table -->
    <div class="white-background-for-table version-tables mb-12">
      <div *ngIf="versionInformationList" class="round-table">
        <table *ngIf="dataSource" mat-table [dataSource]="versionInformationList">
          <ng-container matColumnDef="column1">
            <td class="td date-version-item" mat-cell *matCellDef="let item">
              <div class="version-number">{{ item.VersionNumber }}</div>
              <div class="version-date">
                {{ item.VersionDate | date: "shortDate" }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="column2">
            <td class="td version-description" mat-cell *matCellDef="let item">
              <div class="item">
                <div class="title uppercase">{{ item.Title }}</div>
                <ul class="description box-border">
                  <li *ngFor="let desc of item.Description">{{ desc }}</li>
                </ul>
              </div>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['column1', 'column2']"></tr>
        </table>
      </div>
    </div>
  </div>
  
</div>