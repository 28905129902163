<div class="{{ theme }}">
  <div id="searchBackground">
    <input type="text" placeholder="Search Address" id="searchAddressBar" />

    <button mat-icon-button id="searchAddressBarSearchBtn">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<button color="primary" mat-primary mat-fab [matMenuTriggerFor]="menu">
  <mat-icon>menu</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item [disabled]="viewOnly" (click)="onResetDrawing()">
    <mat-icon>delete</mat-icon>
    <span>Reset Digsites</span>
  </button>
  <button
    mat-menu-item
    [disabled]="finishedDrawingDisabled || viewOnly"
    (click)="onFinishedDrawing()"
  >
    <mat-icon>check</mat-icon>
    <span>Confirm Drawing</span>
  </button>
</mat-menu>

<app-google-maps-drawing
  #cmpDrawShapesGoogleMaps
  [googleMapsOptions]="options"
  [props]="childProps"
></app-google-maps-drawing>
