// @ts-nocheck
import { CallTypeID } from "../../shared/ticket-details/ticket-details.module";

export function isVaildEmail(email: string) {
  let r = /([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})/;
  return r.test(email.toLowerCase());
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

// import { DateTime } from 'luxon'; // https://moment.github.io/luxon/#/
// import { CallTypeID, LocateStatusID, U2_USER_CATEGORY_ID } from "./global-variables";

// export function generateUID() {
//   let uuidv1 = require('uuid/v1');
//   return uuidv1();
// }

// // Date Functions

// export function isStringVaildDate(str: string): boolean {
//   let dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
//   let dateTimeRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
//   let dbDateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.000Z$/;

//   let isDate = false;

//   if (dateRegex.test(str) ||
//     dateTimeRegex.test(str) ||
//     dbDateRegex.test(str)
//   ) {
//     isDate = true;
//   }
//   return isDate;
// }

// export function dbDateToLocalDate(dbDate: string): Date {
//   if (dbDate) {
//     if (dbDate.includes('T')) {
//       let dateParts = dbDate.split('T');
//       dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
//     }
//     dbDate = dbDate + ' ' + 'America/New_York';
//     let date = DateTime.fromFormat(dbDate, "yyyy-MM-dd HH:mm:ss z", { setZone: true });

//     return new Date(date.toString());
//   } else {
//     throw null;
//   }
// }

// export function dbDateToFormattedLocalDate(dbDate: string, format: string = "yyyy-MM-dd HH:mm:ss"): string {
//   if (dbDate) {
//     if (dbDate.includes('T')) {
//       let dateParts = dbDate.split('T');
//       dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
//     }
//     dbDate = dbDate + ' ' + 'America/New_York';
//     let date = DateTime.fromFormat(dbDate, "yyyy-MM-dd HH:mm:ss z", { setZone: true });

//     return date.toLocal().toFormat(format);
//   } else {
//     throw null;
//   }
// }

// export function localDateToFormattedString(date: Date, format: string = "yyyy-MM-dd HH:mm:ss"): string {
//   if (date) {
//     return DateTime.fromISO(date.toISOString()).toLocal().toFormat(format);
//   } else {
//     throw null;
//   }
// }

// export function localDateToDBDateStr(date: Date): string {
//   if (date) {
//     return DateTime.fromISO(date.toISOString(), { zone: 'America/New_York'}).toFormat('yyyy-MM-dd HH:mm:ss');
//   } else {
//     throw null;
//   }
// }

// export function formatDateForFileName(curDate: Date, includeTime: boolean = true) {
//   let format = "yyyy-MM-dd";

//   if (includeTime) {
//     format += "-HH-mm-ss";
//   }
//   return localDateToFormattedString(curDate, format);
// }

// /// Old Date Functions, don't use

export function getDatabaseFormatedDate(curDate: Date, includeTime?: boolean) {
  var curDate = new Date(curDate);
  let returnDateStr =
    curDate.getUTCFullYear() +
    "-" +
    ("0" + (curDate.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + curDate.getUTCDate()).slice(-2);

  if (includeTime) {
    returnDateStr +=
      " " +
      ("0" + curDate.getHours()).slice(-2) +
      ":" +
      ("0" + curDate.getMinutes()).slice(-2) +
      ":" +
      ("0" + curDate.getSeconds()).slice(-2);
  }
  return returnDateStr;
}

// // Other Functions

// export function sortObjectArray(property) {
//   var sortOrder = 1;
//   if(property[0] === "-") { //reverse
//       sortOrder = -1;
//       property = property.substr(1);
//   }
//   return function (a,b) {
//       var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//       return result * sortOrder;
//   }
// }

// export function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

// export function getExtensionFromFilename(filename) {
//   return filename.split('.').pop();
// }

// /**
//  * @param locateStatusID (will convert to number if string given)
//  */
// export function isU2TicketComplete(locateStatusID: string | number) {
//   try {

//     var input = locateStatusID;
//     if(typeof locateStatusID == 'string') {
//       input = parseInt(locateStatusID, 10);
//     }

//     if(locateStatusID == LocateStatusID.LOCATE_COMPLETED || locateStatusID == LocateStatusID.OFFICE_CANCELLED || locateStatusID == LocateStatusID.ARCHIVED) {
//       return true;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// }

// export function getTextColour(colour): string {
//   let textColour = "#000000";
//   if (colour) {
//     let r = parseInt(colour.substring(1, 3), 16);
//     let g = parseInt(colour.substring(3, 5), 16) * 2;
//     let b = parseInt(colour.substring(5, 7), 16);
//     if (r + g + b <= 765 / 1.96) {
//       textColour = "#ffffff";
//     }
//   }
//   return textColour;
// }

// export function canUserCategoryIDCompleteTicket(U2UserCategoryID: number): boolean {
//   const canComplete = [U2_USER_CATEGORY_ID.Manager, U2_USER_CATEGORY_ID.SystemAdmin];
//   return canComplete.indexOf(U2UserCategoryID) > -1 ? true : false;
// }

export async function safeAwait(promise: Promise<any>): Promise<[any, any]> {
  try {
    const result = await promise;
    return [result, null];
  } catch (error) {
    console.log(error);
    return [null, error];
  }
}

// export function arrayBufferToBase64(buffer) {
//   var binary = '';
//   var bytes = new Uint8Array( buffer );
//   var len = bytes.byteLength;
//   for (var i = 0; i < len; i++) {
//      binary += String.fromCharCode( bytes[ i ] );
//   }
//   return window.btoa( binary );
// }

export function isU2AuditTicket(callTypeID: string | number) {
  try {
    var input = callTypeID;
    if (typeof callTypeID == "string") {
      input = parseInt(callTypeID, 10);
    }

    if (callTypeID >= CallTypeID.AUDIT) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}
