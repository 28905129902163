import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { RuleBuilderListItemComponent } from './rule-builder-list-item/rule-builder-list-item.component';
import { RuleBuilderListComponent } from './rule-builder-list/rule-builder-list.component';
import { RuleBuilderTypesComponent } from './rule-builder-types/rule-builder-types.component';
import { TableModule } from '../shared/table/table.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BaseModalModule } from '../shared/modals/base-modal.module';
import { PageNavigationModule } from '../shared/page-navigation/page-navigation.module';
import { CompetersSearchBarComponent } from '../../shared/components/inputs/competers-search-bar/competers-search-bar.component';
import { TabulatorTableComponent } from "../shared/table/tabulator-table/tabulator-table.component";

@NgModule({
    declarations: [RuleBuilderListComponent, RuleBuilderListItemComponent, RuleBuilderTypesComponent],
    exports: [RuleBuilderListComponent, RuleBuilderListItemComponent, RuleBuilderTypesComponent],
    imports: [
        CommonModule,
        MaterialModule,
        AppRoutingModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        TableModule,
        BaseModalModule,
        FormsModule,
        PageNavigationModule,
        CompetersSearchBarComponent,
        TabulatorTableComponent
    ]
})
export class AutomatedEngineModule {}
