import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { Circle as CircleGeom, GeometryCollection, LineString, MultiLineString, Point, Polygon } from 'ol/geom';
import { Feature, Map } from 'ol';
import { StyleFunction } from 'ol/style/Style';
import { OpenLayersUtilities } from '../classes/open-layers-utilities';
import { ShapeType, SymbolShape } from './types';
import { ColourFormatOption, StyleToolbox } from '../classes/style-toolbox';
import { createEmpty, extend, getCenter } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import { BehaviorSubject } from 'rxjs';

export type StyleGenerator = (...args: any) => StyleFunction | Style | Style[];

const olUtilities = new OpenLayersUtilities();
const styleToolbox = new StyleToolbox();

function calculatePolygonRotation(poly: Polygon): number {
  if (poly) {
    try {
      const coords = poly.getCoordinates()[0];
      return Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]) - Math.PI;
    } catch (error) {
      throw new Error('rotation failed');
    }
  }
  return undefined;
}

function calculateImageScale(poly: Polygon, img: HTMLImageElement, olMap: Map): number {
  let scale = 1;
  if (poly) {
    try {
      const { width: geomWidth } = olUtilities.measureRectangle(poly, olMap);
      scale = geomWidth / img.naturalWidth;
    } catch (error) {
      console.log(error)
      throw new Error('scale failed');
    }
  }
  return scale;
}

const currentUnits$ = new BehaviorSubject<MapUnits>('m');
const unitDict = {
  m: {
    name: 'metres',
    factor: 1,
  },
  km: {
    name: 'kilometres',
    factor: 0.001,
  },
  ft: {
    name: 'feet',
    factor: 3.28084,
  },
  yd: {
    name: 'yards',
    factor: 1.09361,
  },
  mi: {
    name: 'miles',
    factor: 0.000621371,
  },
};
export type MapUnits = keyof typeof unitDict;

const Styles: Record<string, StyleGenerator> = {
  invisible: () => {
    const fill = new Fill({
      color: [255, 255, 255, 0],
    });
    const stroke = new Stroke({
      color: [255, 255, 255, 0],
      width: 3,
    });
    return new Style({
      image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 5,
      }),
      fill: fill,
      stroke: stroke,
    });
  },
  default: (): Style => {
    const fill = new Fill({
      color: [255, 255, 255, 0.8],
    });
    const stroke = new Stroke({
      color: [51, 153, 204, 0.8],
      width: 3,
    });
    return new Style({
      image: new Circle({
        fill: fill,
        stroke: stroke,
        radius: 5,
      }),
      fill: fill,
      stroke: stroke,
    });
  },
  arrowLine: (width: number, color: number[]) => (feature: Feature<LineString>) => {
    const geom = feature.getGeometry();
    feature.set('shape_type_id', ShapeType.ArrowLine, true);
    const [start, end] = [geom.getFirstCoordinate(), geom.getLastCoordinate()];
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx) + 90 * (Math.PI / 180);
    const length = Math.sqrt(dx * dx + dy * dy);
    const arrowLength = length / 10;
    const mid = end;
    const arrowHead = new MultiLineString([
      [end, [end[0] - arrowLength / 2, end[1] + arrowLength]],
      [end, [end[0] + arrowLength / 2, end[1] + arrowLength]],
      [end, end],
    ]);

    arrowHead.rotate(rotation, mid);

    const stroke = new Stroke({ width, color });

    // linestring
    return [
      new Style({ stroke }),
      new Style({
        geometry: arrowHead,
        stroke,
      }),
    ];
  },
  measureLine: (width, color) => (feature: Feature, resolution: number) => {
    const geometry = feature.getGeometry();
    let lineString: LineString;
    if (geometry instanceof LineString) {
      lineString = geometry;
    } else if (geometry instanceof GeometryCollection) {
      lineString = geometry.getGeometries().filter((geom) => geom instanceof LineString)[0] as LineString;
    }

    feature.set('shape_type_id', ShapeType.MeasureLine, true);
    const [start, end] = [lineString.getFirstCoordinate(), lineString.getLastCoordinate()];
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx) + 90 * (Math.PI / 180);
    const arrowLength = 2;
    const xGain = 7;
    const yGain = 2;
    let pointX, pointY;
    if (start[0] > end[0]) {
      pointX = start[0] + xGain;
      pointY = start[1] - yGain;
    } else {
      pointX = end[0] + xGain;
      pointY = end[1] - yGain;
    }
    const point = new Point([pointX, pointY]); // use the new y-coordinate

    // full arrow shaped polygon 8 points
    const arrowEnd = new Polygon([
      [
        end,
        [end[0] - arrowLength / 2, end[1] - arrowLength],
        [end[0] - arrowLength / 6, end[1] - arrowLength],
        [end[0] - arrowLength / 6, end[1] - arrowLength * 2],
        [end[0] + arrowLength / 6, end[1] - arrowLength * 2],
        [end[0] + arrowLength / 6, end[1] - arrowLength],
        [end[0] + arrowLength / 2, end[1] - arrowLength],
        end,
      ],
    ]);
    arrowEnd.rotate(rotation, end);

    // full arrow shaped polygon 8 points
    const arrowStart = new Polygon([
      [
        start,
        [start[0] - arrowLength / 2, start[1] - arrowLength],
        [start[0] - arrowLength / 6, start[1] - arrowLength],
        [start[0] - arrowLength / 6, start[1] - arrowLength * 2],
        [start[0] + arrowLength / 6, start[1] - arrowLength * 2],
        [start[0] + arrowLength / 6, start[1] - arrowLength],
        [start[0] + arrowLength / 2, start[1] - arrowLength],
        start,
      ],
    ]);
    arrowStart.rotate(rotation + Math.PI, start);
    const stroke = new Stroke({ width, color });
    const fill = new Fill({
      color,
    });

    feature.setProperties(
      {
        shape_type_id: ShapeType.MeasureLine,
        stroke_colour: styleToolbox.convertColour(color, ColourFormatOption.hex),
        fill_colour: styleToolbox.convertColour(color, ColourFormatOption.hex),
        fill_opacity: Array.isArray(color) ? color[3] : 1,
      },
      true
    );

    // linestring
    return [
      new Style({ stroke: new Stroke({ width: 1, color: [0, 0, 0, 0] }) }),
      new Style({
        geometry: arrowStart,
        stroke,
        fill,
      }),
      new Style({
        geometry: arrowEnd,
        stroke,
        fill,
      }),
      new Style({
        geometry: point,
        text: new Text({
          text: String(Math.floor(lineString.getLength() * unitDict[currentUnits$.value].factor)) + currentUnits$.value,
          font: '2px Rajdhani',
          justify: 'center',
          fill: new Fill({
            color: [0, 0, 0, 1],
          }),
          backgroundFill: new Fill({
            color: [255, 255, 255, 1],
          }),
          padding: [0, 0, 0, 0],
          overflow: true,
          scale: 1 / resolution,
          placement: 'point',
        }),
      }),
    ];
  },

  text: (map: Map, styleToolbox: StyleToolbox) => {
    const textStroke = new Stroke({
      width: 0.5,
    });
    const textFill = new Fill({});
    return (feature: Feature, resolution): Style => {
      try {
        const strokeColour: string = feature.get('text_colour');
        const fillColour = feature.get('fill_colour');
        if (strokeColour) {
          textStroke.setColor(styleToolbox.hexToRGBA(strokeColour, feature.get('fill_opacity') * 100 || 100));
        } else {
          textStroke.setColor(null);
        }
        if (fillColour) {
          textFill.setColor(styleToolbox.hexToRGBA(fillColour, feature.get('fill_opacity') * 100 || 100));
        } else {
          textFill.setColor(null);
        }
        const padding = [0, 0, 0, 0];
        return new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
          text: new Text({
            font: '14px Calibri,sans-serif',
            justify: 'left',
            text: feature.get('text_label'),
            stroke: textStroke,
            fill: textFill,
            padding,
            overflow: true,
            rotation: ((): number => {
              const geometry = feature.getGeometry() as Polygon;
              let rot = 0;
              if (geometry) {
                try {
                  const coords = geometry?.getCoordinates()[0];
                  rot = Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]) - Math.PI;
                } catch (error) {
                  throw new Error('rotation failed');
                }
              }
              return rot;
            })(),
            scale: (() => {
              const geometry = feature.getGeometry() as Polygon;
              if (geometry) {
                let scale = 1;
                try {
                  // measure the text width in pixels
                  const { width: textWidth } = olUtilities.measureText(
                    feature.get('text_label'),
                    '14px Calibri,sans-serif',
                    padding
                  );
                  const { width: geomWidth } = olUtilities.measureRectangle(geometry, map);
                  scale = geomWidth / textWidth;
                } catch (error) {
                  throw new Error('scale failed');
                }
                return scale;
              } else {
                return 1;
              }
            })(),
          }),
        });
      } catch (error) {
        console.log(error);
        return new Style();
      }
    };
  },
  sticker:
    (img: HTMLImageElement, map: Map): ((feat: Feature, num: number) => Array<Style>) =>
    (feature: Feature): Array<Style> => {
      try {
        let geoms;
        const isCollection = feature.getGeometry() instanceof GeometryCollection;
        if (isCollection) {
          geoms = (feature.getGeometry() as GeometryCollection).getGeometries();
        } else {
          geoms = [feature.getGeometry()];
        }
        const poly = geoms.filter((geom) => geom instanceof Polygon)[0] as Polygon;
        const pointStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
          image: new Icon({
            crossOrigin: 'anonymous',
            src: img.src,
            rotation: calculatePolygonRotation(poly),
            scale: calculateImageScale(poly, img, map),
          }),
        });
        const polyStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
        });
        return [pointStyle, polyStyle];
      } catch (error) {
        console.log(error);
        return [];
      }
    },

  symbol: (symbol: Array<SymbolShape>, olMap: Map) => {
    // return the style function, which will be called for each feature / render
    return (feature: Feature, resolution: number): Array<Style> => {
      // pull this stuff out of the style function, so it only gets called once
      const fullExtent = createEmpty();
      symbol.forEach((shape) => {
        if (shape.geometry instanceof Point) {
          if (shape.radius && shape.radius !== 0) {
            const circle = new CircleGeom((shape.geometry as Point).getCoordinates(), shape.radius);
            extend(fullExtent, circle.getExtent());
          } else if (shape.text && shape.text !== '') {
            const { width, height } = olUtilities.measureText(shape.text, `${shape.width}px Arial`);
            const rect = olUtilities.createRectangleGeometry(olMap, shape.geometry.getCoordinates(), width, height);
            rect.scale(1 / resolution);
            extend(fullExtent, rect.getExtent());
          }
        } else {
          extend(fullExtent, shape.geometry.getExtent());
        }
      });
      const centroid = getCenter(fullExtent);
      const { width: extentWidth } = olUtilities.measureRectangle(fromExtent(fullExtent), olMap);
      const geometry = feature.getGeometry() as Polygon;
      const polyCenter = getCenter(geometry.getExtent());
      const diff: [number, number] = [polyCenter[0] - centroid[0], polyCenter[1] - centroid[1]];
      const scale = ((): number => {
        let scale = 1;
        if (geometry) {
          const { width: geomWidth } = olUtilities.measureRectangle(geometry, olMap);
          scale = geomWidth === 0 || extentWidth === 0 ? 1 : geomWidth / extentWidth;
        } else {
          // do nothing
        }
        return scale;
      })();

      const rotation = ((): number => {
        let rot = 0;
        if (geometry) {
          try {
            const coords = geometry?.getCoordinates()[0];
            rot = Math.atan2(coords[1][0] - coords[0][0], coords[1][1] - coords[0][1]);
          } catch (error) {
            throw new Error('rotation failed');
          }
        }
        return -rot;
      })();
      const styles: Style[] = [];
      try {
        styles.push(
          new Style({
            fill: new Fill({
              color: [153, 51, 255, 0],
            }),
            stroke: new Stroke({
              color: [153, 51, 255, 0],
            }),
          })
        );
        symbol.forEach((symbolShape: SymbolShape) => {
          switch (symbolShape.geojson.type) {
            case 'Point':
              if (symbolShape.text && symbolShape.text !== '') {
                styles.push(
                  new Style({
                    geometry: () => {
                      const poly = symbolShape.geometry.clone();
                      poly.translate(diff[0], diff[1]);
                      poly.rotate(rotation, polyCenter);
                      poly.scale(scale, scale, polyCenter);
                      return poly;
                    },
                    text: new Text({
                      text: symbolShape.text,
                      font: `${symbolShape.width}px Arial`,
                      fill: new Fill({
                        color: [0, 0, 0, 1],
                      }),
                      scale: scale / resolution,
                      rotation: -rotation,
                    }),
                  })
                );
              } else if (symbolShape.radius && symbolShape.radius !== 0) {
                styles.push(
                  new Style({
                    geometry: () => {
                      const poly = new CircleGeom((symbolShape.geometry as Point).getCoordinates(), symbolShape.radius);
                      poly.translate(diff[0], diff[1]);
                      poly.scale(scale, scale, polyCenter);
                      poly.rotate(rotation, polyCenter);
                      return poly;
                    },
                    fill: new Fill({
                      color: [125, 125, 125, 0.01],
                    }),
                    stroke: new Stroke({
                      color: [0, 0, 0, 1],
                      width: 1,
                    }),
                  })
                );
              }
              break;
            default:
              styles.push(
                new Style({
                  geometry: () => {
                    const poly = symbolShape.geometry.clone();
                    poly.translate(diff[0], diff[1]);
                    poly.rotate(rotation, polyCenter);
                    poly.scale(scale, scale, polyCenter);
                    return poly;
                  },
                  stroke: new Stroke({
                    color: [0, 0, 0, 1],
                    width: symbolShape.width,
                  }),
                  fill: new Fill({
                    color: [0, 0, 0, 0],
                  }),
                })
              );
              break;
          }
        });
      } catch (e) {
        console.log(e);
      }
      return styles;
    };
  },
  svgSymbol: (img: HTMLImageElement, olMap: Map) => {
    // return the style function, which will be called for each feature / render
    return (feature: Feature, resolution: number): Array<Style> => {
      try {
        let geoms;
        const isCollection = feature.getGeometry() instanceof GeometryCollection;
        if (isCollection) {
          geoms = (feature.getGeometry() as GeometryCollection).getGeometries();
        } else {
          geoms = [feature.getGeometry()];
        }
        const poly = geoms.filter((geom) => geom instanceof Polygon)[0] as Polygon;
        const pointStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
          image: new Icon({
            crossOrigin: 'anonymous',
            src: img.src,
            rotation: calculatePolygonRotation(poly),
            scale: calculateImageScale(poly, img, olMap),
          }),
        });
        const polyStyle = new Style({
          fill: new Fill({
            color: [0, 0, 0, 0],
          }),
          stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 1,
          }),
        });
        return [pointStyle, polyStyle];
      } catch (error) {
        console.log(error);
        return [];
      }
    };
  },

  selectedStyle: (): Style =>
    new Style({
      fill: new Fill({
        color: [153, 224, 255, 0],
      }),
      stroke: new Stroke({
        color: [13, 101, 176],
        width: 1.5,
        lineDash: [30, 10],
      }),
    }),
  digSiteShape: () => (): Style =>
    new Style({
      fill: new Fill({
        color: [255, 255, 255, 0.4],
      }),
      stroke: new Stroke({
        color: [65, 64, 66],
        width: 3,
      }),
    }),
  locateArea: () =>
    new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        width: 2,
      }),
    }),
};

const updateUnits = (unit: MapUnits) => {
  currentUnits$.next(unit);
  return currentUnits$.value;
};

const getUnits = () => Object.keys(unitDict);
const getCurrentUnits$ = () => currentUnits$;

export default Styles;
export { updateUnits, getUnits, getCurrentUnits$ };
