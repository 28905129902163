import { inject, Injectable } from '@angular/core';
import { ApiService, UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { BehaviorSubject, from, map, Observable, of } from 'rxjs';
import { DispatchAreaMap } from '../dispatch-area/dispatch-area.service';
import { api, apiKeys } from '../../../ENDPOINTS';
import { getRandomColour } from '../../functions/misc';

@Injectable({
  providedIn: 'root',
})
export class DigsiteAreaService {
  //services
  private apiService: ApiService = inject(ApiService);

  // members
  private _digSiteAreas$: BehaviorSubject<DispatchAreaMap> = new BehaviorSubject({});
  private _noDigSite: Set<number> = new Set();
  private _cachedDigsites: Record<number, null> = {};
  private _digsiteBucket = [];

  constructor() {}

  fetchDigSiteAreas(assignmentIDs: Array<number>): Observable<Array<unknown> | Error> {
    const toFetch = [];
    const digsites = this._digsiteBucket.filter((site) => assignmentIDs.includes(site.AssignmentID));
    assignmentIDs.forEach((id) => {
      if (this._cachedDigsites[id] === undefined) {
        toFetch.push(id);
      }
    });
    if (toFetch.length === 0) {
      return of(digsites);
    } else {
      const url = apiKeys.u2.gatherDigsites;
      const type = api[url].type;
      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.gatherDigsites,
        API_TYPE: type,
        API_BODY: { AssignmentIDs: toFetch },
      };
      return from(this.apiService.invokeUtilocateApi(utilocateApiRequest)).pipe(
        map((result) => {
          if (result) {
            try {
              if (result.ok) {
                const colorMap = {};
                JSON.parse(result.body.value)['DigsiteData'].forEach((site) => {
                  site.Coordinates = JSON.parse(site.Coordinates);
                  if (colorMap[site.AssignmentID] !== undefined) {
                    site.Colour = colorMap[site.AssignmentID];
                  } else {
                    site.Colour = getRandomColour();
                    colorMap[site.AssignmentID] = site.Colour;
                  }
                  this._cachedDigsites[site.AssignmentID] = null;
                  this._digsiteBucket.push(site);
                  digsites.push(site);
                });
                return digsites;
              } else {
                return digsites;
              }
            } catch (error) {
              return error;
            }
          }
        })
      );
    }
  }

  resetCache() {
    this._digsiteBucket = [];
    this._cachedDigsites = {};
  }
}

type Coordinate = {
  lat: number;
  lng: number;
};

export type Digsite = {
  DigsiteShapeID: number;
  DigsiteTypeID: number;
  RequestNumber: number;
  AssignmentID: number;
  Coordinates: Coordinate[];
  Colour?: string;
};
