import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { LoggerService } from "../core/services/logger/logger.service";
import { modifyEditTablesBody, ModifyType, TableMetadata } from "./TableModels";

@Injectable({
  providedIn: "root",
})
export class EditTablesService {
  private joinTables: any[];
  private permissionsTable: any[];
  private editTables: any[];

  constructor(
    private loggerService: LoggerService,
    private utilocateApiService: ApiService
  ) {
    this.loggerService.log("Created EditTable service");
  }

  getEditTablePermissions(): Observable<any> {
    return new Observable((subscriber) => {
      const url = apiKeys.u2.getEditTables;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.getEditTables,
        API_TYPE: type,
      };
      from(
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest))
      ).subscribe((nextVal: any) => {
        if (nextVal && nextVal.body && nextVal.body.value) {
          let result = JSON.parse(nextVal.body.value);
          this.permissionsTable = result["editTablePermissionList"];
          this.editTables = result["editTableList"];
          this.joinTables = result["adminTables"];
          subscriber.next(result);
        } else {
          subscriber.next(false);
        }
        subscriber.complete();
      });
    });
  }

  openEditTableById(id: number) {
    return new Observable((subscriber) => {
      const url = apiKeys.u2.openEditTables;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.openEditTables,
        API_TYPE: type,
        API_BODY: { EditTableIDs: id },
      };

      from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).subscribe((response) => {
        try {
          if (response.body.value) {
            subscriber.next(response.body.value);
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        } catch (error) {
          this.loggerService.error(error);
        }
      });
    });
  }

  modifyEditTables(
    tableID: number,
    permissionType: ModifyType,
    rowPrimaryKey: string,
    updateInfo: modifyEditTablesBody
  ) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.modifyEditTables;
        const type = api[url].type;

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.modifyEditTables,
          API_TYPE: type,
          API_URL_DATA_PARAMS: {
            EditTableID: tableID,
            ModifyType: permissionType,
            PrimaryFieldName: rowPrimaryKey,
          },
          API_BODY: updateInfo,
        };

        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((response) => {
          if (response) {
            subscriber.next(response);
            subscriber.complete();
          }
        });
      } catch (error) {
        this.loggerService.error(error);
      }
    });
  }

  shouldUpdateCache(): boolean {
    return !(this.joinTables && this.permissionsTable && this.editTables);
  }

  async getTableMetadata(tableID: number): Promise<TableMetadata> {
    let metadata: TableMetadata;
    try {
      let canCreateMetadata: boolean = true;
      if (this.shouldUpdateCache()) {
        canCreateMetadata = await this.getEditTablePermissions().toPromise();
      }

      if (
        canCreateMetadata &&
        this.editTables != null &&
        Object.keys(this.editTables).length > 0
      ) {
        let table = this.editTables[tableID];

        // find permission type from editTablePermissionList
        let permissionTypeID: number = 4;
        for (let i = 0; i < this.permissionsTable.length; i++) {
          let cur = this.permissionsTable[i];
          if (parseInt(cur["PermissionPrimaryKeyID"]) == tableID) {
            permissionTypeID = cur["PermissionTypeID"];
            break;
          }
        }

        metadata = {
          EditTableID: tableID,
          ArchiveFieldName: table["ArchiveFieldName"],
          PrimaryFieldName: table["PrimaryFieldName"],
          TableDescription: table["TableDescription"],
          VisibleName: table["VisibleName"],
          PermissionTypeID: permissionTypeID,
        };
      }
    } catch (error) {
      this.loggerService.error("getTableMetadata: ", error);
    }

    return metadata;
  }

  createColumns(tableID: number): Object {
    var columns: Object = {};
    try {
      if (tableID) {
        let table = this.editTables[tableID];

        table.Data.forEach((column) => {
          if (column.ColumnName) {
            columns[column.ColumnName] = {
              ColumnName: column.ColumnName,
              VisibleName: column.VisibleName,
              DataTypeID: column.DataTypeID,
              JoinInstanceID: column.JoinInstanceID,
              JoinInstance: this.getJoinInstances(column.JoinInstanceID),
              isVisible: column.isVisible,
              required: column.AllowNothing == 0,
              ColumnOrder: column.ColumnOrder,
            };
          }
        });
      } else {
        throw new Error("No tableID given");
      }
    } catch (error) {
      this.loggerService.error(error);
    }
    return columns;
  }

  getJoinInstances(joinID: number) {
    let join: any = null;
    try {
      if (joinID) {
        let joinTable = this.joinTables[joinID];
        let newJoin = {
          DescColumn: joinTable["DescColumn"],
          PrimaryIDColumn: joinTable["PrimaryIDColumn"],
          Data: joinTable["Data"], // array of options
        };
        join = newJoin;
      }
    } catch (error) {
      this.loggerService.error(error);
    }
    return join;
  }
}
