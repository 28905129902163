import { inject, Pipe, PipeTransform } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { LegendSymbol } from "./legend.service";

@Pipe({
  name: "DataToUrl",
  standalone: true,
})
export class LegendPipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value: string): SafeResourceUrl {
    if (!value || value === "") {
      return "";
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png;base64,${value}`,
      );
    }
  }
}

@Pipe({
  name: "iterableGroupName",
  standalone: true,
})
export class IterableGroupNamePipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value: LegendSymbol[]): String {
    return value[0]["groupName"];
  }
}

@Pipe({
  name: "isIterable",
  standalone: true,
})
export class IsIterablePipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value: any | any[]): Boolean {
    return Array.isArray(value);
  }
}
