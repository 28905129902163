<div class="flex flex-row justify-start space-x-2 items-center">
  <div
    class="flex justify-center items-center py-[4px] px-4 rounded-full"
    *ngFor="let item of tags"
    [ngStyle]="{ 'background-color': item.color, color: item.textColor }"
  >
    <span class="text-[9px] text-center uppercase font-rajdhani text-nowrap">
      {{ item.value }}
    </span>
  </div>
  <p *ngIf="remainingTags > 0" class="mat-body-xs">+{{ remainingTags }}</p>
</div>
