import { isEqual, isObject, transform } from 'lodash-es';

function deepDifference<T extends object>(object: T, base: T): Partial<T> {
  function changes(object: Partial<T>, base: T) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base) as T;
}

export { deepDifference };
