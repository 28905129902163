/**
 * takes a base64 encoded image (png/jpg) and returns it as a blob
 * @param data
 * @param target
 * @returns Blob
 */
export function dataURLToFile(data: string, target: 'blob' | 'file'): Blob | File {
  const parts = data.split(','); // Decode the base64 string
  const byteCharacters = atob(parts[1]); // Decode the base64 string
  const mime = parts[0].match(/:(.*?);/)[0];
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  if (target === 'blob') {
    return new Blob(byteArrays, { type: mime });
  } else if (target === 'file') {
    return new File(byteArrays, `uploaded_${crypto.randomUUID()}.${mime.split('/')[1]}`, { type: mime });
  }
}
