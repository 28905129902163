// rule-builder-list-item.component.ts
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Rule, Criteria, Action, AdminCriteria, AdminActions, AdminCriteria2Operators, AdminOperators, AdminRuleTypes, AdminTriggers, ActionValue } from '../rule-builder-models';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const DATA_TYPE_BOOL = 1;
const DATA_TYPE_STRING = 2;
const DATA_TYPE_SELECT = 3;
const DATA_TYPE_MULTI_SELECT = 4;
const DATA_TYPE_DATE = 5;
const DATA_TYPE_NUMBER = 6;
const DATA_TYPE_EMPTY = 9;


@Component({
  selector: 'app-rule-builder-list-item',
  templateUrl: './rule-builder-list-item.component.html',
  styleUrls: ['./rule-builder-list-item.component.scss'],
})
export class RuleBuilderListItemComponent {
  Rule: Rule;
  Criteria: AdminCriteria[];
  Actions: AdminActions[];
  AdminCriteria2Operators: AdminCriteria2Operators[];
  AdminOperators: AdminOperators[];
  AdminRuleTypes: AdminRuleTypes[];
  AdminTriggers: AdminTriggers[];

  ENABLED_TRIGGER_IDs = [2]; //TODO: Remove or modify this to enable/disable triggers

  AdminActionValues: any;
  AdminCriteriaValues: any;

  valueoperator: any;

  viewOnly: boolean = true;
  disableDelete: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RuleBuilderListItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackbarService,
    private cd: ChangeDetectorRef
  ) {
    this.Criteria = data.AdminCriteria.sort((a, b) => {
      return a?.CriteriaName?.localeCompare(b?.CriteriaName);
    });
    this.Actions = data.AdminActions.sort((a, b) => {
      return a?.ActionName?.localeCompare(b?.ActionName);
    });
    
    this.Rule = data.Rule;
    if (this.Rule.Criteria == null) {
      this.Rule.Criteria = [];
    }
    if (this.Rule.Actions == null) {
      this.Rule.Actions = [];
    }

    //for all criteria's "Values" property, convert to an array.
    for (const criteria of this.Rule.Criteria) {
      //if criteria.Values is an array
      if (criteria.Values instanceof Array) {
        continue;
      }
      criteria.Values = criteria.Values?.split(',');
    }


    
    let groupedActions: Action[] = [];
    for (const action of this.Rule.Actions) {
      if (action.ActionValue instanceof Array) {
        continue;
      }
      let adminActionValue = data.AdminActionValues.find(value => value.ActionValueID == action.ActionValueID);
      if (adminActionValue != null) {
        let dataType = adminActionValue.ValueDataTypeID;
        if (dataType == 4) {
          action.ActionValue = action.ActionValue?.split(',');
        }
      }
      
      let groupedActionIndex = groupedActions.findIndex(grouped => grouped.ActionID == action.ActionID && grouped.OrderNumber == action.OrderNumber);
      
      if (groupedActionIndex != -1) {
        groupedActions[groupedActionIndex].ActionValues.push({
          Rule2ActionsID: action.Rule2ActionsID,
          ActionValueID: action.ActionValueID,
          Value: action.ActionValue
        });
      } else {
        let valuesArr: ActionValue[] = [{
          Rule2ActionsID: action.Rule2ActionsID,
          ActionValueID: action.ActionValueID,
          Value: action.ActionValue
        }];
        groupedActions.push({
          Rule2ActionsID: null,
          RuleID: action.RuleID,
          ActionID: action.ActionID,
          OrderNumber: action.OrderNumber,
          ActionValue: null,
          ActionValueID: null,
          ActionValues: valuesArr
        })
      }
    }
    if (groupedActions != null && groupedActions.length > 0) {
      this.Rule.Actions = groupedActions;
    }

    this.AdminCriteria2Operators = data.AdminCriteria2Operators;
    this.AdminOperators = data.AdminOperators;
    this.AdminRuleTypes = data.AdminRuleTypes.sort((a, b) => {
      return a?.RuleTypeName?.localeCompare(b?.RuleTypeName);
    });

    this.AdminTriggers = data.AdminTriggers.sort((a, b) => {
      return a?.TriggerName?.localeCompare(b?.TriggerName);
    });
    this.AdminActionValues = data.AdminActionValues;
    this.AdminCriteriaValues = data.AdminCriteriaValues;
    /**
     * View only IF: 
     * Enabled Trigger IDs does not include the trigger type of this rule. 
     * ex: Enabled trigger types is [2]; notification, so all other types will be view-only
     * data.viewOnly comes from user permissions.
     * If you are viewing an enabled trigger type rule, it is then up to your individual permissions
     * whether you can edit the rule or not. 
     */
    this.viewOnly = this.ENABLED_TRIGGER_IDs.includes(data.Rule.TriggerID) ? data.viewOnly : true ;
    this.refreshActions();
  }

  /**
   * Called when the modal is closed. Passes the Rule back to the caller 
   */
  onCloseClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick() {
    this.Rule.delete = true;
    this.dialogRef.close(this.Rule);
  }

  onSaveClick() {
    const fieldsToTrim = ['RuleName', 'Description'];
    for (const field of fieldsToTrim) {
      this.Rule[field] = this.Rule[field]?.trim();
    }

    let invalidFields = this.getInvalidFields();
    if (invalidFields.length == 0) {
      this.dialogRef.close(this.Rule);
    } else {
      this.snackBarService.openSnackbar(
        invalidFields.join(' | '),
        SnackbarType.warning
      );
    }
  }

  /**
   * Returns a string of missing fields 
   * @returns 
   */
  getInvalidFields(): string[] {
    //must have name
    let invalid = [];

    if (this.Rule.RuleName == null || this.Rule.RuleName.trim() == "") {
      invalid.push("Missing Rule Name");
    }

    if (this.Rule.RuleTypeID == null) {
      invalid.push("Missing Rule Type");
    }

    if (this.Rule.TriggerID == null) {
      invalid.push("Missing Rule Trigger");
    }
    
    if (this.Rule.Criteria.length === 0) {
      invalid.push("Missing Rule Criteria");
    }
    
    if (this.Rule.Actions.length === 0) {
      invalid.push("Missing Rule Action");
    }

    //if any criteria is missing AdminCriteriaID, OperatorID, or Values
    for (const criteria of this.Rule.Criteria) {
      if (criteria.AdminCriteriaID == null) {
        invalid.push("Selected Criteria");
        break;
      }
      let criteriaType = this.Criteria.find(c => c.CriteriaID === criteria.AdminCriteriaID);
      if (criteria.OperatorID == null) {
        invalid.push(`${criteriaType.CriteriaName} is missing an operator`);
      }
      if (criteria.Values == null || criteria.Values.length === 0) {
        invalid.push(`${criteriaType.CriteriaName} is missing a Value`);
      } else {
        //if the CriteriaTypeID is boolean
        if (criteriaType.CriteriaDataTypeID === DATA_TYPE_BOOL) {
          if (criteria.Values.length > 1) {
            invalid.push(`Only yes or no allowed for ${criteriaType.CriteriaName}`);
          }
        }
      }
    }

    //if any Action is missing ActionID or ActionValue
    for (const action of this.Rule.Actions) {
      if (action.ActionID == null) {
        invalid.push("Selected Action");
      }
      for (let value of action.ActionValues) {
        let adminActionValue = this.AdminActionValues.find((adminValue: any) => adminValue.ActionValueID == value.ActionValueID);
        if (value.Value == null || value.Value.length === 0) {
          invalid.push(`${adminActionValue.DisplayName} is missing a Value`);
        }
      }
      
    }
    
    return invalid;
  }

  setupBoolCriteria() {
    for (const criteria of this.Criteria) {
      if (criteria.CriteriaDataTypeID === DATA_TYPE_BOOL) {
        let valsToUpdate = this.AdminCriteriaValues.find(c => c.CriteriaID === criteria.CriteriaID);
        if (valsToUpdate) {
          valsToUpdate.Value = [{name: "Yes", value: 1}, {name: "No", value: 0}];
        } else {
          this.AdminCriteriaValues.push({CriteriaID: criteria.CriteriaID, Value: [{name: "Yes", value: "NOT NULL"}, {name: "No", value: "NULL"}]});
          criteria.IDColumn = "value";
          criteria.NameColumn = "name";
        }
      }
    }

    if (this.Rule.RuleID == null) {
      this.disableDelete = true;
    }
  }

  /**
   * Compares the two criteriaIDs 
   * @param criteriaID1 
   * @param criteriaID2 
   * @returns {boolean}
   */
  CriteriaIDSame(criteriaID1, criteriaID2): boolean {
    return criteriaID1 == criteriaID2;
  }

  /**
   * Appends a blank criteria to the Rule 
   */
  addCriteria() {
    let newCriteria: Criteria = {
      Rule2CriteriaID: null,
      RuleID: this.Rule.RuleID,
      AdminCriteriaID: null,
      OperatorID: null,
      Values: null,
    }
    this.Rule.Criteria.push(newCriteria);
  }

  /**
   * Appends a blank Action to the Rule 
   */
  addAction() {
    //find largest orderNubmer from list of Actions where delete is false
    let largestOrderNumber = 0;
    for (let action of this.Rule.Actions) {
      //if it isn't an action to delete, and its order number is greater than the current count 
      if (!action.hasOwnProperty('delete') && action.OrderNumber > largestOrderNumber) {
        largestOrderNumber = action.OrderNumber;
      }
    }
    let newAction: Action = {
      Rule2ActionsID: null,
      RuleID: this.Rule.RuleID,
      ActionID: null,
      OrderNumber: largestOrderNumber+1,
      ActionValue: null,
      ActionValueID: null,
      ActionValues: null,
    }
    this.Rule.Actions.push(newAction);
    this.refreshActions();
  }

  /**
   * Removes the Criteria at this index from the Rule
   * @param index 
   */
  removeCriteria(index) { 
    this.Rule.Criteria.splice(index, 1);
  }

  /**
   * Removes the Action at this index from the Rule
   * @param index 
   */
  removeAction(index) {
    this.Rule.Actions.splice(index, 1);
    this.refreshActions();
  }

  refreshActions() {
    //sort the list of actions by OrderNumber
    let actions = this.Rule.Actions;
    if (actions != null && actions.length != 0) {
      // actions.sort((a, b) => a.OrderNumber - b.OrderNumber);
      
      // go through the list of actions and ensure that each valid action (i.e. not deleted)
      // has a OrderNumber of one after the other. 
      let newOrderNum = 1;
      for (let i = 0; i < actions.length; i++) {
        if (!actions[i].hasOwnProperty('delete')) { //if it isn't a delete 
          actions[i].OrderNumber = newOrderNum;
          newOrderNum++;
        }
      }
    }
    this.Rule.Actions = actions;
  }

  /**
   * Gest the operator for a given CriteriaID
   * @param CriteriaID 
   * @returns {[{AdminOperator}]}
   */
  getOperatorFromCriteriaID(CriteriaID): AdminOperators[] {
    return this.AdminCriteria2Operators
      .filter(criteria2Operator => criteria2Operator.CriteriaID === CriteriaID)
      .map(criteria2Operator => {
        return this.AdminOperators.find(operator => operator.OperatorID === criteria2Operator.OperatorID);
      })
      .filter(operator => operator !== undefined); // Remove undefined values
  }
  
  /**
   * Gets criteria values from their queries. 
   * Does not actually query the DB, just organizes the information
   * @param CriteriaID 
   * @returns {[{name: string, value: string}]}
   */
  getValuesFromQuery(CriteriaID) {
    let retObj = [];
    let criteriaValueObject = this.Criteria.find(criteria => criteria.CriteriaID === CriteriaID);
    let valArr = (this.AdminCriteriaValues?.find(criteria => criteria.CriteriaID === CriteriaID))?.Value;
    if (valArr === undefined) return [];
    let criteriaNameColumn = criteriaValueObject.NameColumn;
    let criteriaIDColumn = criteriaValueObject.IDColumn;

    // create an object with a value and name. The name is what the user sees, the value is what goes in the db
    // Ex: For utilities, {Value: <UtilityID>, Name: "BHC01"}
    for (const val of valArr) {
      retObj.push({name: val[criteriaNameColumn].toString(), value: val[criteriaIDColumn].toString()});
    }
    return retObj;
  }


  /**
   * Gets Action values from their queries. 
   * Does not actually query the DB, just organizes the information
   * @param ActionID 
   * @returns {[{name: string, value: string}]}
   */
  getAdminValuesFromActionID(ActionID) {
    let retObj = [];
    try {
      let actionValueObject = this.Actions?.find(action => action.ActionID === ActionID);
      let valArr = (this.AdminActionValues?.find(action => action.ActionID === ActionID))?.Value;
      if (valArr === undefined) return [];
      let actionNameColumn = actionValueObject.ActionValueNameColumn;
      let actionValueColumn = actionValueObject.ActionValueIDColumn;
  
      // create an object with a value and name. The name is what the user sees, the value is what goes in the db
      // Ex: For utilities, {Value: <UtilityID>, Name: "BHC01"}
      if (actionNameColumn && actionValueColumn) {
        for (const val of valArr) {
          retObj.push({name: val[actionNameColumn].toString(), value: val[actionValueColumn].toString()});
        }
      }
    } catch (error) {
      console.error(error);
    }
    return retObj;
  }

  getAdminValuesFromActionValueID(ActionValueID) {
    let retObj = [];
    try {
      let actionValueObject = this.AdminActionValues?.find(action => action.ActionValueID == ActionValueID);
      let valArr = actionValueObject.Value;
      if (valArr === undefined) return [];
      let actionNameColumn = actionValueObject.ValueName;
      let actionValueColumn = actionValueObject.ValueID;
  
      // create an object with a value and name. The name is what the user sees, the value is what goes in the db
      // Ex: For utilities, {Value: <UtilityID>, Name: "BHC01"}
      if (actionNameColumn && actionValueColumn) {
        for (const val of valArr) {
          retObj.push({name: val[actionNameColumn].toString(), value: val[actionValueColumn].toString()});
        }
      }
    } catch (error) {
      console.error(error);
    }
    return retObj;
  }

  getPlaceholderFromActionValueID(ActionValueID) {
    let placeholder = "";
    try {
      let actionValueObject = this.AdminActionValues?.find(action => action.ActionValueID == ActionValueID);
      let displayName = actionValueObject.DisplayName;
      if (displayName != null) {
        placeholder = displayName;
      }
      
    } catch (error) {
      console.error(error);
    }
    return placeholder;
  }

  getDataTypeFromActionValueID(ActionValueID) {
    let dataType = 0;
    try {
      let actionValueObject = this.AdminActionValues?.find(action => action.ActionValueID == ActionValueID);
      if (actionValueObject != null) {
        let valueDataTypeID = actionValueObject.ValueDataTypeID;
        if (valueDataTypeID != null) {
          dataType = valueDataTypeID;
        }
      }
      
    } catch (error) {
      console.error(error);
    }
    return dataType;
  }

  /**
   * Determines if you need to show the dropdown for a given criteria ID 
   * @param CriteriaID 
   * @returns {boolean}
   */
  showCriteriaDropdownByCriteriaID(CriteriaID) {
    const adminCriteria = this.Criteria.find((ac) => ac.CriteriaID == CriteriaID);
    return adminCriteria && (adminCriteria.CriteriaValueQuery != null || adminCriteria.CriteriaDataTypeID == DATA_TYPE_EMPTY);
  }

  /**
   * Determines if you need to show the date picker for a given criteria ID 
   * @param CriteriaID 
   * @returns {boolean}
   */
  showDatePickerByCriteriaID(CriteriaID) {
    const adminCriteria = this.Criteria.find((ac) => ac.CriteriaID == CriteriaID);
    return adminCriteria && adminCriteria.CriteriaDataTypeID == DATA_TYPE_DATE;
  }

  /**
 * Determines if you need to show the checkbox for a given criteria ID
 * @param CriteriaID 
 * @returns 
 */
  showTextFieldByCriteriaID(CriteriaID) {
    const adminCriteria = this.Criteria.find((ac) => ac.CriteriaID == CriteriaID);
    return adminCriteria && adminCriteria.CriteriaValueQuery == null && adminCriteria.CriteriaDataTypeID != DATA_TYPE_BOOL;
  }

  actionHasQuery(actionID) {
    const adminAction = this.Actions.find((ac) => ac.ActionID == actionID);
    return adminAction && adminAction.ActionValueQuery != null;
  }

  /**
   * 
   * @param index 
   * @param item 
   * @returns 
   */
  trackByFn(index, item) {
    return item.id; // Use a unique identifier property from your object
  }

  onDragStarted() {
    // Add any code you want to run when the drag operation starts.
  }
  
  drop(event: CdkDragDrop<Action[]>) {
    moveItemInArray(this.Rule.Actions, event.previousIndex, event.currentIndex);
    this.refreshActions();
    this.cd.detectChanges();
  }

  /**
   * Nullifies the value so old value doesn't carry over
   * @param action 
   */
  onActionChange(action: Action) {

    action.ActionValues = [];

    for (let adminValue of this.AdminActionValues) {
      if (adminValue.ActionID == action.ActionID) {
        action.ActionValues.push({
          Rule2ActionsID: action.Rule2ActionsID,
          ActionValueID: adminValue.ActionValueID,
          Value: null
        });
      }
    }

    
  }

  /**
   * Nullifies values so old values don't carry over, and operator 
   * @param criteria 
   */
  onCriteriaChange(criteria: Criteria) {
    criteria.Values = [];
    criteria.OperatorID = null;
  }
  
  
}
