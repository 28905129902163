<div
  style="height: 100%; width: 100%; overflow: auto"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign.gt-md="space-evenly center"
>
  <div
    fxFlex.gt-md="600px"
    fxLayout="column"
    fxLayoutAlign="center start"
    style="padding: 16px"
  >
    <mat-icon style="font-size: 80px; height: 80px; width: 80px"
      ><img id="warningIcon"
    /></mat-icon>
    <p class="mat-headline-2" style="padding-top: 8px; padding-bottom: 18px">
      Problèmes de sécurité
    </p>
    <p class="mat-subtitle-2" style="padding-bottom: 32px">
      Quelque chose s'est passé, essayez à nouveau le lien ou consultez nos
      solutions de dépannage ci-dessous.
      <br />
      <br />
      Si ce problème persiste, appelez l'assistance au 1-877-395-1122
    </p>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            SESSION INVALIDE/EXPIREE
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          Les informations d'authentification requises pour afficher la page
          demandée ne sont pas valides, veuillez essayer de sélectionner le lien
          à nouveau ou faites renvoyer l'e-mail et essayez le lien du nouvel
          e-mail pour afficher les documents.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            INTERNET EXPLORER PROBLÈMES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          Impossible d'accéder au document Pourquoi n'avez-vous pas accès pour
          visualiser le document ? Si vous utilisez Internet Explorer, cela peut
          être dû aux options Internet.
          <br />
          <br />
          Veuillez suivre le manuel pour résoudre ce problème causé par les
          options Internet. cliquez ici pour voir le manuel.
        </p>
        <a href="{{ frenchTroubleshootDocPath }}" target="_blank"
          >Document de dépannage</a
        >
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            SÉCURITÉ INTERNET
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          Des problèmes de sécurité du réseau nous ont empêchés de charger
          correctement la page à laquelle vous tentiez d'accéder. Veuillez
          essayer une autre connexion réseau ou demandez à l'assistance
          informatique de nous contacter pour résoudre le problème.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div
    fxFlex="1px"
    fxHide.lt-lg
    style="height: 90%; background-color: #707070"
  ></div>
  <div
    fxFlex.gt-md="600px"
    fxLayout="column"
    fxLayoutAlign="center start"
    style="padding: 16px"
  >
    <mat-icon style="font-size: 80px; height: 80px; width: 80px"
      ><img id="warningIcon"
    /></mat-icon>
    <p class="mat-headline-2" style="padding-top: 8px; padding-bottom: 18px">
      Security issues
    </p>
    <p class="mat-subtitle-2" style="padding-bottom: 32px">
      Something happened, try the link again or check out our troubleshooting
      solutions below.
      <br />
      <br />
      If this problem persists call support at 1-877-395-1122
    </p>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            INVALID/EXPIRED SESSION
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          The authentication information required to view the requested page is
          invalid, please try selecting the link again or have the email resent
          and try link from new email to view the documents.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            INTERNET EXPLORER ISSUES
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          Unable to Access Document Why do you not have access to view the
          document? If you are using Internet Explorer this could have been
          caused by internet options.
          <br />
          <br />
          Please follow along with the Manual to resolve this issue caused by
          internet options. click here to view manual.
        </p>
        <a href="{{ englishTroubleshootDocPath }}" target="_blank"
          >Troubleshooting Document</a
        >
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="mat-subtitle-2">
            NETWORK SECURITY
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-2">
          Network security issues prevented us from properly loading up page you
          were trying to access. Please try a different network connection or
          have IT support contact us to get it resolved.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
