import { Component, NgModule, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { AuthenticationService } from 'src/app/modules/core/authentication/authentication.service';
import { GraphicInfo } from 'src/app/modules/core/cache/GraphicsInterface';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { DownloadDocumentService } from 'src/app/modules/core/services/document/download-document.service';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { UploadAutologService } from 'src/app/modules/core/services/logger/upload-autolog.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { FAMItem, FloatingActionMenuComponent } from '../../fab/floating-action-menu/floating-action-menu.component';
import { MarkerObject } from '../../google-map/google-maps-set/googleMapsOptions';

import { MaterialModule } from '../../material.module';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';
import { EsriMapSimpleDrawingModule } from '../esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component';
import { EsriMapComponent, EsriMapModule } from '../esri-map-set/esri-map/esri-map.component';
import {
  DefaultSubLayer,
  EsriCredential,
  Group,
  LayerInfo,
  LayerOptions,
  MapOptions,
} from '../esri-map-set/EsriMapModels';
import { EsriMapService } from '../esri-map.service';
import { MapService } from 'src/app/modules/core/services/map/map.service';
import { TicketDetailsService } from '../../ticket-details/ticket-details.service';

@Component({
  selector: 'app-esri-map-viewer',
  templateUrl: './esri-map-viewer.component.html',
  styleUrls: ['./esri-map-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EsriMapViewerComponent implements OnInit, OnDestroy {
  graphics: GraphicInfo[];
  markers: MarkerObject[];
  @ViewChild('esriMap') esriMap: EsriMapComponent;

  private isLgScreen = true;
  layerOptions: LayerOptions;

  mapOptions: MapOptions = {
    zoom: 20,
    center: null,
  };

  mapFab: FAMItem[];

  assignmentID: number | string;

  bottomMenuOpen = false;
  identifyEnabled = false;
  viewingAddress = false;
  ticketLatLng: MarkerObject = null;
  ticketAddressLatLng: MarkerObject = null;

  userSubscriber: Subscription;

  constructor(
    public logger$: LoggerService,
    public esriMap$: EsriMapService,
    private route: ActivatedRoute,
    private routerService: Router,
    private userService: UserService,
    private progressBarService: ProgressBarService,
    public snackBarService: SnackbarService,
    private downloadDoc$: DownloadDocumentService,
    private autolog$: UploadAutologService,
    private datetime$: DatetimeService,
    private auth$: AuthenticationService,
    private mapService$: MapService,
    private ticketDetailsService$: TicketDetailsService
  ) {}

  ngOnInit(): void {
    const queryParams: any = this.route.snapshot.queryParams;
    const URL_KEYS = localStorageKeys.URL_KEYS;

    if (queryParams.AssignmentID) {
      sessionStorage.setItem(URL_KEYS.assignmentid, queryParams.AssignmentID);
      this.assignmentID = queryParams.AssignmentID;
    }

    if (queryParams.assignmentid) {
      sessionStorage.setItem(URL_KEYS.assignmentid, queryParams.assignmentid);
      this.assignmentID = queryParams.assignmentid;
    }

    if (this.route.snapshot.queryParams.Authorization) {
      if (this.route.snapshot.params.app) {
        this.routerService.navigate(
          [this.route.snapshot.routeConfig.path.replace(':app', this.route.snapshot.params.app)],
          {
            queryParams: {
              ...this.route.snapshot.queryParams,
              Authorization: null,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          }
        );
      } else {
        this.routerService.navigate([this.route.snapshot.routeConfig.path], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            Authorization: null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    }

    this.setupUserSubscription();
    // this.setupFAB();

    if (window.innerWidth < 599) {
      this.isLgScreen = false;
    }
    this.esriMap$.getRequiredTables().then(() => {
      forkJoin([
        this.mapService$.getURLs(),
        this.mapService$.getCredentials(),
        this.mapService$.getLayers(),
        this.mapService$.getSubLayers(),
        this.mapService$.getLayerGroups(),
        this.mapService$.getUtilityToEsriLayer(),
        this.getAssignmentUtilities()
      ]).subscribe((results) => {

        const filterByUtilityGroup: Boolean = this.userService.isSettingActive(SettingID.UTILITIES_DETERMINE_ESRI_LAYERS);

        let utilities = results[6];
        const groupIDs = results[5].reduce((acc, { UtilityID, EsriLayerGroupID }) => {
          if (utilities.includes(UtilityID) && !acc.includes(EsriLayerGroupID)) {
            acc.push(EsriLayerGroupID);
          }
          return acc;
        }, []);
        let credentials: EsriCredential[] = this.mapService$.formatCredentials(results[0], results[1]);
        let groups: Group[] = this.mapService$.formatGroups(results[4]);
        if (filterByUtilityGroup) {
          groups = groups.filter((group) => groupIDs.includes(group.groupID));
        }
        let layers: LayerInfo[] = this.mapService$.formatLayers(results[2]).filter(({ groupID }) => {
          if (groupID) {
            if (filterByUtilityGroup) {
              return groupIDs.includes(groupID);
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
        let defaultSubLayers: DefaultSubLayer[] = this.mapService$.formatDefaultSubLayers(results[3]).filter(({ layerID }) => {
          if (layerID) {
            return layers.reduce((acc, { layerID: id }) => {
              if (id === layerID) {
                acc = true;
              }
              return acc;
            }, false);
          } else {
            return true;
          }
        });

        this.layerOptions = {
          layers: layers,
          defaultSubLayers: defaultSubLayers,
          credentials: credentials,
          groups: groups,
        };

      });
    });

    this.getMapCenter();
    this.getDigsite();
  }

  /**
   * unsubscribe subscribers on destroy
   */
  ngOnDestroy(): void {
    if (this.userSubscriber) {
      this.userSubscriber.unsubscribe();
    }
  }

  setupUserSubscription() {
    this.userSubscriber = this.userService.currentUser$.subscribe((currentUser) => {
      this.setupFAB();
    });
  }

  async setupFAB() {
    this.mapFab = [
      {
        label: 'Take Screenshot',
        icon: 'photo_camera',
        action: this.takeScreenshot,
      },
    ];
    if (this.userService.isSettingActive(SettingID.ESRI_SHOW_AUX_FAB_OPTIONS)) {
      const settingValue = this.userService.getSettingValue(SettingID.ESRI_SHOW_AUX_FAB_OPTIONS);
      if (settingValue == '0') {
        this.mapFab = [
          {
            label: 'Take Auxiliary Screenshot',
            icon: 'photo_camera',
            action: this.takeAuxiliaryScreenshot,
          },
        ];
      } else {
        this.mapFab.push({
          label: 'Take Auxiliary Screenshot',
          icon: 'photo_camera',
          action: this.takeAuxiliaryScreenshot,
        });
      }
    }
  }

  getMapCenter() {
    this.esriMap$.gatherTicketLatLng().subscribe((results: any) => {
      try {
        // get ticket coordinates or current location
        const latLng = JSON.parse(results.value);
        if (latLng.AddressLocation || latLng.Location) {
          let startLocation = [];
          if (latLng.AddressLocation) {
            this.ticketAddressLatLng = {
              position: {
                lat: Number(latLng.AddressLocation.Latitude),
                lng: Number(latLng.AddressLocation.Longitude),
              },
            };
            startLocation = [this.ticketAddressLatLng.position.lng, this.ticketAddressLatLng.position.lat];
            this.markers = [this.ticketAddressLatLng];
          }

          if (latLng.Location) {
            this.ticketLatLng = {
              position: {
                lat: Number(latLng.Location.Latitude),
                lng: Number(latLng.Location.Longitude),
              },
            };
            startLocation = [this.ticketLatLng.position.lng, this.ticketLatLng.position.lat];
            this.markers = [this.ticketLatLng];
          }

          this.mapOptions = { ...this.mapOptions, center: startLocation };
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.mapOptions = {
                ...this.mapOptions,
                center: [position.coords.longitude, position.coords.latitude],
              };
              this.snackBarService.openSnackbar('Failed to get Ticket Location', SnackbarType.default);
            },
            (error) => {
              this.snackBarService.openSnackbar(
                'Failed to get Ticket Location and Current Location',
                SnackbarType.default
              );
            }
          );
        }
      } catch (error) {
        this.logger$.log(error);
      }
    });
  }

  getDigsite() {
    this.esriMap$.gatherDigsites().subscribe((results: any) => {
      const digsites = JSON.parse(results.value);
      const graphics: GraphicInfo[] = [];

      digsites.DigsiteData.forEach((digsite) => {
        const newGraphic: GraphicInfo = {
          graphicID: digsite.DigsiteShapeID,
          type: digsite.DigsiteTypeID,
          coordinates: JSON.parse(digsite.Coordinates),
        };
        graphics.push(newGraphic);
      });

      this.graphics = graphics;
    });
  }

  async getAssignmentUtilities() {
    let utilities = [];
    try {
      let result = await this.ticketDetailsService$.downloadTicket(
        this.assignmentID
      );
      if (result && result["tbCompletions_AuxiliaryDetails"] && result["tbCompletions_AuxiliaryDetails"]["Data"]) {
        let auxRows = result["tbCompletions_AuxiliaryDetails"]["Data"];
        auxRows.forEach((auxRow) => {
          utilities.push(auxRow["UtilityID"]);
        });
      }
    } catch (error) {
      console.error(error.messag);
    }
    return utilities;
  }

  // Layer functions

  setCredentials(urlRows, credRows): EsriCredential[] {
    const credentials: EsriCredential[] = [];
    if (urlRows != false && credRows != false) {
      urlRows.forEach((urlRow) => {
        if (urlRow.EsriTokenGenerationURL != null && urlRow.EsriServicesURL != null) {
          credRows.forEach((credRow) => {
            if (credRow.EsriCredentialID == urlRow.EsriCredentialID) {
              credentials.push({
                username: credRow.Username,
                password: credRow.Password,
                serviceUrl: urlRow.EsriServicesURL,
                tokenGenerationUrl: urlRow.EsriTokenGenerationURL,
              });
            }
          });
        }
      });
    }
    return credentials;
  }

  getLayers(layerRows): LayerInfo[] {
    const layers: LayerInfo[] = [];
    if (layerRows != false) {
      layerRows.forEach((layerRow) => {
        const layer: LayerInfo = {
          layerID: layerRow.EsriLayerID,
          name: layerRow.LayerName,
          url: layerRow.LayerURL,
          layerTypeID: layerRow.LayerType,
          isVisible: layerRow.isDefaultOn,
        };
        if (layerRow.EsriLayerGroupID > 0) {
          layer.groupID = layerRow.EsriLayerGroupID;
        }
        layers.push(layer);
      });
    }
    return layers;
  }

  getDefaultSubLayers(subLayerRows): DefaultSubLayer[] {
    const defaultSubLayers: DefaultSubLayer[] = [];
    if (subLayerRows != false) {
      subLayerRows.forEach((subLayerRow) => {
        let isVisible;
        if (this.isLgScreen && subLayerRow.onForLargeScreen != null) {
          isVisible = subLayerRow.onForLargeScreen;
        } else {
          isVisible = subLayerRow.isDefaultOn;
        }

        defaultSubLayers.push({
          layerID: subLayerRow.EsriLayerID,
          subLayerID: subLayerRow.FeatureLayerID,
          isVisible,
        });
      });
    }
    return defaultSubLayers;
  }

  getGroups(groupRows): Group[] {
    const groups: Group[] = [];
    if (groupRows != false) {
      groupRows.forEach((groupRow) => {
        groups.push({
          groupID: groupRow.EsriLayerGroupID,
          name: groupRow.GroupName,
        });
      });
    }
    return groups;
  }

  // Screenshot functions

  async handleTakeScreenshot() {
    const userID = this.auth$.getNestedValueFromPayload('USERID');
    const clientID = this.auth$.getNestedValueFromPayload('CLIENTID');

    const filename =
      this.assignmentID +
      ' - ' +
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-SSS') +
      '.jpg';
    const zipname =
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-S') +
      '_' +
      userID +
      '_' +
      this.assignmentID +
      '.zip';

    const result = await this.esriMap.takeScreenshot();
    const screenshot = this.dataURLtoFile(result.dataUrl, filename);

    this.progressBarService.start();

    const date = this.datetime$.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: this.assignmentID.toString(),
      CreationDate: date,
      Description: 'ESRI Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '',
      isSendable: '' + 0,
    };

    await this.autolog$.uploadAutolog(clientID, this.assignmentID, userID, 4, 'Added new document');

    const addDocResult = await this.downloadDoc$.uploadDocument(screenshot, zipname, metadata, clientID);

    if (addDocResult) {
      this.snackBarService.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackBarService.openSnackbar('Failed to upload', SnackbarType.error);
    }

    this.progressBarService.stop();
  }

  async handleTakeAuxScreenshot() {
    const userID = this.auth$.getNestedValueFromPayload('USERID');
    const clientID = this.auth$.getNestedValueFromPayload('CLIENTID');

    const filename =
      this.assignmentID +
      ' - ' +
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-SSS') +
      '.jpg';
    const zipname =
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-S') +
      '_' +
      userID +
      '_' +
      this.assignmentID +
      '.zip';

    const result = await this.esriMap.takeScreenshot();
    const screenshot = this.dataURLtoFile(result.dataUrl, filename);

    this.progressBarService.start();

    const date = this.datetime$.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: this.assignmentID.toString(),
      CreationDate: date,
      Description: 'ESRI Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '',
      isSendable: '' + 0,
    };

    await this.autolog$.uploadAutolog(clientID, this.assignmentID, userID, 4, 'Added new document');

    const addDocResult = await this.downloadDoc$.uploadAuxiliaryImage(
      screenshot,
      zipname,
      metadata,
      clientID,
      this.assignmentID
    );

    if (addDocResult) {
      this.snackBarService.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackBarService.openSnackbar('Failed to upload', SnackbarType.error);
    }

    this.progressBarService.stop();
  }

  private dataURLtoFile(dataUrl, fileName) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  // Toggle Functions

  toggleBottomMenu() {
    this.bottomMenuOpen = !this.bottomMenuOpen;
  }

  toggleIdentify() {
    this.identifyEnabled = this.esriMap.toggleIdentify();
  }

  toggleLayers() {
    this.esriMap.toggleLayers();
  }

  toggleLegend() {
    this.esriMap.toggleLegend();
  }

  toggleMeasurement() {
    this.esriMap.toggleMeasurement();
  }

  toggleBasemap() {
    this.esriMap.toggleBasemap();
  }

  takeScreenshot = () => {
    this.handleTakeScreenshot();
  };

  takeAuxiliaryScreenshot = () => {
    this.handleTakeAuxScreenshot();
  };

  getDirections = () => {
    this.esriMap.getDirections();
  };

  toggleCoordinates() {
    if (this.viewingAddress) {
      this.markers = [this.ticketLatLng];
      this.esriMap.goTo(this.ticketLatLng.position.lat, this.ticketLatLng.position.lng);
    } else {
      this.markers = [this.ticketAddressLatLng];
      this.esriMap.goTo(this.ticketAddressLatLng.position.lat, this.ticketAddressLatLng.position.lng);
    }
    this.viewingAddress = !this.viewingAddress;
  }
}

@NgModule({
  declarations: [EsriMapViewerComponent],
  imports: [MaterialModule, FlexLayoutModule, EsriMapModule, EsriMapSimpleDrawingModule, FloatingActionMenuComponent],
  exports: [EsriMapViewerComponent],
})
export class EsriMapViewerModule {}
