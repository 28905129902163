import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Feature, MapBrowserEvent } from 'ol';
import { Circle, Point, Polygon } from 'ol/geom';
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { FeatureOrCollection } from '../../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../../classes/map-feature-change';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';
import { Interaction } from 'ol/interaction';
import { ShapeType, SymbolShape } from '../types';
import Styles from '../styles';
import { fromExtent } from 'ol/geom/Polygon';
import { boundingExtent, createEmpty, extend, getCenter } from 'ol/extent';

export class LegacySymbolTool extends Interaction {
  private readonly sourceRef: VectorSource;
  private readonly symbol: Array<SymbolShape>;
  private readonly mapRef: Map;
  private featureChanges$: Subject<MapFeatureChange<FeatureOrCollection>>;
  private utilities: OpenLayersUtilities;

  cursor = 'pointer';
  previousCursor = undefined;

  interactionStart: () => void;
  interactionEnd: (p: { originalFeatures: any; features: any }) => void;

  constructor({
    mapRef,
    sourceRef,
    symbol,
    featureChanges,
  }: {
    mapRef: Map;
    sourceRef: VectorSource;
    symbol: Array<SymbolShape>;
    featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
  }) {
    super({
      handleEvent,
    });
    this.utilities = new OpenLayersUtilities();
    this.sourceRef = sourceRef;
    this.symbol = symbol;
    this.featureChanges$ = featureChanges;
    this.mapRef = mapRef;
  }

  setOnInteractionStart(interactionStart: () => void) {
    this.interactionStart = interactionStart;
  }

  setOnInteractionEnd(interactionEnd: () => void) {
    this.interactionEnd = interactionEnd;
  }

  public addSymbol(coords: Coordinate) {
    const feature = new Feature();
    const featureStyle = Styles.symbol(this.symbol, this.mapRef);
    feature.setGeometry(this.buildGeometry(coords));
    feature.setStyle(featureStyle);
    feature.setProperties(
      {
        shape_type_id: ShapeType.LegacySymbol,
        symbol_id: this.symbol[0].symbol_id,
      },
      true
    );
    this.sourceRef.addFeature(feature);
    this.featureChanges$.next(new MapFeatureChange(FeatureChangeType.added, feature, undefined, this.sourceRef));
  }

  private buildGeometry(coords: Coordinate): Polygon {
    const extents = this.symbol.map((s) => {
      const geom = (() => {
        if (s.geojson.type === 'Point') {
          if (s.radius) {
            return fromExtent(new Circle((s.geometry as Point).getCoordinates(), s.radius).getExtent());
          } else if (s.text && s.text !== '') {
            // get extent of text
            const { width, height } = this.utilities.measureText(s.text, `${s.width}px Arial`);
            const center = this.mapRef.getPixelFromCoordinate(s.geojson.coordinates);
            const halfWidth = width / 2;
            const halfHeight = height / 2;
            const extent = [
              [center[0] - halfWidth, center[1] - halfHeight],
              [center[0] + halfWidth, center[1] + halfHeight],
            ].map((x) => this.mapRef.getCoordinateFromPixel(x));
            return fromExtent(boundingExtent(extent));
          }
        }
        return s.geometry;
      })();
      return geom.getExtent();
    });
    const extent = createEmpty();
    extents.forEach((e) => {
      extend(extent, e);
    });
    const poly = fromExtent(extent);
    const center = getCenter(extent);
    const diff = [coords[0] - center[0], coords[1] - center[1]];
    poly.translate(diff[0], diff[1]);
    const point = new Point(coords);
    return poly;
  }
}

function handleEvent(evt: MapBrowserEvent<UIEvent>): boolean {
  if (evt.type === "singleclick") {
    this.addSymbol(evt.coordinate);
    return false;
  }
  return true;
}
