import { Component, ViewChild, ElementRef, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, ControlContainer, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchField } from '../../../../shared/components/misc/quick-ticket-search/quick-ticket-search.component';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-simple-searchable-dropdown',
  templateUrl: 'simple-searchable-dropdown.component.html',
  styleUrls: ['simple-searchable-dropdown.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SimpleSearchableDropdownComponent implements OnInit {
  @ViewChild('search') searchTextBox: ElementRef;
  @ViewChild('select') select: MatSelect;
  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues = [];

  @Input() field: SearchField;
  @Input() dropDownFormControl: FormControl;
  @Input() preSelectedFields: unknown[];
  @Output() selectValueChanged: EventEmitter<any> = new EventEmitter();

  data: Point[];

  filteredOptions: Observable<any[]>;

  ngOnInit() {
    this.data = this.field.choices.filter((choice) => {
      return choice.name && choice.value;
    });

    this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
      startWith<string>(''),
      map((name) => this._filter(name))
    );
    this.setSelection(this.preSelectedFields);
  }
  /**
   * Used to filter data based on search input
   */
  private _filter(name: string): Point[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    return this.data.filter((option) => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  setSelection(inputSelection: unknown[]) {
    this.selectedValues = inputSelection;
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected === false) {
      const index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
    this.selectValueChanged.emit({
      field: this.field,
      values: this.selectedValues,
    });
  }

  openedChange(e) {
    this.searchTextboxControl.patchValue('');
    if (e === true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues() {
    if (this.selectFormControl.value) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) === -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}

type Point = { name: string; value: number | string };
