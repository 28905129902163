export enum NavAction {
  logout = 1,
  reset = 2,
  ticketSummary = 3,
  map = 4,
  punchClock = 5,
  versionInformation = 6,
}

export const NAVIGATION_SETTINGS = [216, 248, 260, 267, 268, 269, 292];

export enum NavSettings {
  scheduler = "216",
  createTicket = "248",
  generalSettings = "260",
  routingPresets = "267",
  userManager = "268",
  systemMaintenance = "269",
  ruleBuilder = "292",
}
export interface SideNavRoute {
  icon?: string;
  route: string;
  title?: string;
  action?: NavAction;
  children?: SideNavRoute[];
  navButton?: SideNavButton;
  checked?: boolean;
  selected?: boolean;
}

export interface SideNavButton {
  action: any;
  title: string;
}

export interface ToolPaletteItem {
  id?: string;
  name?: string;
  component?: any;
  icon?: string;
}
