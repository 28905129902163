import {Component, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {U2User} from '../../core/services/user/user';
import {ModalService} from '../../shared/modals/modal.service';
import {ProgressBarService} from '../../shared/progress-bar/progress-bar.service';
import {SnackbarService} from '../../shared/snackbar/snackbar.service';
import {SnackbarType} from '../../shared/snackbar/snackbar/snackbar';
import {TemplateColumn} from '../../shared/table/table.module';
import {UserManagerService} from '../user-manager.service';
import {TableService} from '../../shared/table/table.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import {UserService} from '../../core/services/user/user.service';
import {SettingID} from '../../core/services/user/setting';
import {MatDialog} from '@angular/material/dialog';
import {ResetPasswordDialogComponent} from '../reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss'],
  providers: [TableService],
})
export class UserManagerComponent implements OnInit {
  selectionType: SelectionType = SelectionType.multi;
  USER_MODAL_ID = 'id_user_dialog';
  showArchived = false;
  allUsers: any[] = [];
  displayedUsers: any[] = [];
  activeUsers: any[] = [];
  selected: any[] = [];

  displayedColumnNames: string[] = [
    'Employee ID',
    'User Category',
    'Supervisor',
    'First Name',
    'Last Name',
    'Email',
    'Truck Number',
    'Phone',
    'First Log',
  ];

  displayedColumns: TemplateColumn[];

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  tbLogin_Users = [];
  supervisors = [];
  userCategories = [];
  cloudUserCategories = [];
  userSettings = {};
  searchTerm: string = '';
  openUser: U2User;
  color: ThemePalette = 'primary';

  userCentralizationEnabled: boolean = false;

  constructor(
    private modalService: ModalService,
    private userManger$: UserManagerService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private routerService: Router,
    private route: ActivatedRoute,
    public tableService: TableService<any>,
    private userService: UserService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    const newQueryParams = this.cleanURLQueryParams();
    this.routerService.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    if (this.userService.isSettingActive(SettingID.USER_CENTRALIZATION)) {
      this.userCentralizationEnabled = true;
    }
    if (this.userCentralizationEnabled) {
      this.displayedColumnNames = ['First Name', 'Last Name', 'User Category', 'Email', 'Phone', 'Needs Attention'];
    }

    this.displayedColumns = this.displayedColumnNames.map((col, index) => {
      return {
        TemplateColumnID: 0,
        ColumnOrder: index,
        TemplateID: 0,
        Field: 0,
        Visible: 1,
        Width: 200,
        Title: col,
      } as TemplateColumn;
    });
    this.refreshUsers();
  }

  private cleanURLQueryParams(): object {
    const queryParams: any = this.route.snapshot.queryParams;
    const queryParamsKeys: any = Object.keys(queryParams);
    const newQueryParams = {};

    try {
      const visisbleURLKeys: any = this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      const len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        const queryParamsKey = queryParamsKeys[i];
        if (visisbleURLKeys && visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }
      return newQueryParams;
    } catch (error) {
      console.error(error);
    }
    return newQueryParams;
  }

  /**
   * Gather Users
   * Gather Supervisors
   * Gather Usercategories
   * Gather all user settings
   *
   */
  async refreshUsers() {
    this.progressBarService.start();
    try {
      const dontShowTheseUserIDs = [0, 848779];
      // Get list of all users
      const result = await this.userManger$.getUsers().toPromise();
      const users = result.body.Result.allUsers;

      //remove certain users who are competers specific, such as the unassigned user 
      this.tbLogin_Users = users.filter((row) => !dontShowTheseUserIDs.includes(row.UserID));

      // Gets a list of Supervisors for dropdown list
      this.supervisors = await this.userManger$.getSupervisors();

      // Get a list of user categories
      this.userCategories = result.body.Result.clientUserCategories; //await this.userManger$.getUsercategories();
      this.cloudUserCategories = result.body.Result.cloudUserCategories;

      // Get a list of all User Setting Overrides
      this.userSettings = await this.userManger$.getUserSettings();

      this.formatUserData(result.body.Result);
      this.clearUser();
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
  }

  // Functions for formatting the data rows

  formatUserData(userData) {
    const tmpUsers = [];
    const tmpActiveUsers = [];
    const tmpDisplayedUsers = [];
    // console.log(userData.allUsers);
    for (const i of userData.allUsers) {
      const user = i;
      let formattedUser = {};
      if (!this.userCentralizationEnabled) {
        formattedUser = {
          UserID: user.UserID,
          'Employee ID': user.EmployeeID,
          'User Category': this.getUserCategory(user.UserCategoryID),
          Supervisor: this.getSupervisor(user.Supervisor),
          'First Name': user.FirstName,
          'Last Name': user.LastName,
          Email: user.EmailAddress,
          Password: user.Password,
          'Truck Number': user.TruckNum,
          Phone: user.TwoWayNum,
          'First Log': user.FirstLog,
          Archived: user.Archived,
          Highlighted: user.Archived === 1,
          HexColour: user.HexColour,
        };

        tmpUsers.push(formattedUser);
        if (user.Archived === 0) {
          tmpActiveUsers.push(formattedUser);
        }
      } else {
        formattedUser = {
          UserID: user.UserID,
          CloudUserID: user.CloudUserID,
          'Employee ID': user.EmployeeID,
          UserCategoryID: user.UserCategoryID,
          'User Category': this.getUserCategory(user.UserCategoryID, user.Source),
          Supervisor: this.getSupervisor(user.Supervisor),
          'First Name': user.FirstName,
          'Last Name': user.LastName,
          Email: user.EmailAddress,
          Password: '', //  user.Password,
          'Truck Number': user.TruckNum,
          Phone: user.TwoWayNum,
          'First Log': '', //  user.FirstLog,
          Archived: user.Archived,
          Highlighted: user.Archived === 1,
          Source: user.Source,
          isCloudSettingsUser: user.isCloudSettingsUser,
          'Needs Attention': user.Source == 'client' && user.CloudUserID == 0 ? true : '',
          CognitoUserID: user.CognitoUserID,
          HexColour: user.HexColour,
        };

        tmpUsers.push(formattedUser);
        if ((user.Source == 'client' && user.CloudUserID == 0) || user.Source == 'cloud') {
          tmpDisplayedUsers.push(formattedUser);
          if (user.Archived === 0) {
            tmpActiveUsers.push(formattedUser);
          }
        }
      }
    }

    this.allUsers = tmpUsers;
    if (this.userCentralizationEnabled) {
      this.displayedUsers = tmpDisplayedUsers;
    } else {
      this.displayedUsers = tmpUsers;
    }
    this.activeUsers = tmpActiveUsers;
  }

  getUserCategory(userCategoryID: number, userSource: string = 'client') {
    let userCat = '';
    if (this.userCentralizationEnabled && userSource == 'cloud') {
      for (const cat of this.cloudUserCategories) {
        if (cat.UserCategoryID === userCategoryID) {
          userCat = cat.Title;
          break;
        }
      }
    } else {
      for (const i in this.userCategories) {
        if (this.userCategories[i].UserCategoryID === userCategoryID) {
          userCat = this.userCategories[i].Title;
          break;
        }
      }
    }
    return userCat;
  }

  getSupervisor(userID: number) {
    let supervisor = '';
    for (const i in this.supervisors) {
      if (this.supervisors[i].UserID === userID) {
        supervisor = this.supervisors[i].UserID;
        break;
      }
    }
    return supervisor;
  }

  /**
   * Creates a new user 
   */
  addUser() {
    this.clearUser();
    this.setUserHexColour(); // generate a new ramdom colour for this new user 
    this.modalService.open(this.USER_MODAL_ID);
  }

  /**
   * Arches a batch of selected users 
   */
  async batchArchive() {
    if (this.selected.length > 0) {
      const userIDs = [];
      const cloudUserIDs = [];
      if (this.userCentralizationEnabled) {
        let clientUsers = [];
        for (const user of this.selected) {
          if (user.Source == 'cloud') {
            cloudUserIDs.push(user.CloudUserID);
            clientUsers = this.getClientUsers(user.CloudUserID);
            for (const clientUser of clientUsers) {
              userIDs.push(clientUser.UserID);
            }
          } else if (user.Source == 'client' && user.CloudUserID == 0) {
            this.snackBarService.openSnackbar("Can't archive Client users with no Cloud Account", SnackbarType.error);
          }
        }
      } else {
        for (const i in this.selected) {
          userIDs.push(this.selected[i].UserID);
        }
      }

      if (userIDs.length > 0 || cloudUserIDs.length > 0) {
        const result = await this.userManger$.archiveUsers(userIDs, cloudUserIDs).toPromise();
        if (result && result.body) {
          if (result.body.Success) {
            this.snackBarService.openSnackbar('Successfully Archived Users', SnackbarType.success);
            this.refreshUsers();
          } else if (result.body.FailMessage) {
            this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
          } else {
            this.snackBarService.openSnackbar('Failed to Archive Users', SnackbarType.error);
          }
        }
      }
    }
  }

  /**
   * Batch unarchives a selection of users 
   */
  async batchUnarchive() {
    if (this.selected.length > 0) {
      const userIDs = [];
      const cloudUserIDs = [];
      const cloudUserIDsNeedPwReset = [];
      if (this.userCentralizationEnabled) {
        let clientUsers = [];
        for (const user of this.selected) {
          if (user.Source == 'cloud') {
            cloudUserIDs.push(user.CloudUserID);
            // if they don't have a cognito user id, that means the cloud account comes from us not another identity provider 
            // so, in this case we have to reset their password too 
            if (!user.CognitoUserID) cloudUserIDsNeedPwReset.push(user.CloudUserID);

            clientUsers = this.getClientUsers(user.CloudUserID);
            for (const clientUser of clientUsers) {
              userIDs.push(clientUser.UserID);
            }
          }
        }
      } else {
        for (const i in this.selected) {
          userIDs.push(this.selected[i].UserID);
        }
      }

      if (userIDs.length > 0 || cloudUserIDs.length > 0) {
        const result = await this.userManger$.unarchiveUsers(userIDs, cloudUserIDs).toPromise();
        if (result && result.body) {
          if (result.body.Success) {
            await this.refreshUsers();
            this.promptPwResetForUsers(cloudUserIDsNeedPwReset);
            this.snackBarService.openSnackbar(`Successfully Unrchived Users ${cloudUserIDsNeedPwReset.length > 0 ? ': Please reset user(s) password' : ''}`, SnackbarType.success);
          } else if (result.body.FailMessage) {
            this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
          } else {
            this.snackBarService.openSnackbar('Failed to Unarchive Users', SnackbarType.error);
          }
        }
      }
    }
  }

  promptPwResetForUsers(cloudUserIDsToReset) {
    if (!cloudUserIDsToReset || cloudUserIDsToReset.length == 0) return;

    for (const userID of cloudUserIDsToReset) {
      const user = this.allUsers.find((user) => user.CloudUserID === userID);
      if (user) {
        const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
          width: '400px',

          data: {
            header: `Reset password for account ${user.Email}`,
            userID: user.CloudUserID,
          },
        });
        dialogRef.afterClosed().subscribe(async (result: {userID: string, password: string}) => {
          if (result) {
            //find the user 
            const userToUpdate = this.allUsers.find((user) => user.CloudUserID === result.userID);
            //set current user (format user object)
            this.setCurrentUser(userToUpdate.CloudUserID);

            //set password and first log (for reset on login)
            this.openUser.Password = result.password;
            this.openUser.FirstLog = 1;

            //update the user's password 
            await this.userManger$.updateUser(this.openUser).toPromise();
            this.snackBarService.openSnackbar(`Updated password for ${this.openUser.EmailAddress}`, SnackbarType.success);
            this.clearUser();
          }
        });
      }
    }
  }

  // Functions for editing a user
  setCurrentUser(userID: number) {
    let user = null;
    if (this.userCentralizationEnabled) {
      let tmpUser = this.allUsers.find((user) => user.CloudUserID === userID && user.Source == 'cloud');
      if (!tmpUser) {
        tmpUser = this.allUsers.find((user) => user.UserID === userID);
      }
      const clientUsers = this.getClientUsers(userID);
      console.log("tmpUser.HexColour: ", tmpUser.HexColour);
      user = {
        UserID: tmpUser.UserID,
        CloudUserID: tmpUser.CloudUserID,
        FirstName: tmpUser['First Name'],
        LastName: tmpUser['Last Name'],
        EmailAddress: tmpUser.Email,
        TwoWayNum: tmpUser.Phone,
        EmployeeID: tmpUser['Employee ID'],
        UserCategoryID: tmpUser.UserCategoryID,
        Supervisor: tmpUser.Supervisor,
        TruckNum: tmpUser['Truck Number'],
        FirstLog: tmpUser['First Log'],
        Password: tmpUser.Password,
        Archived: tmpUser.Archived,
        ClientUsers: clientUsers,
        HexColour: tmpUser.HexColour,
      };
    } else {
      for (const i in this.tbLogin_Users) {
        if (this.tbLogin_Users[i].UserID === userID) {
          user = this.tbLogin_Users[i];
          break;
        }
      }
    }
    if (user && this.userSettings[user.UserID]) {
      user.Settings = this.userSettings[user.UserID];
    }
    this.openUser = user;
  }

  /**
   * 
   * @param userID 
   * @returns 
   */
  getClientUsers(userID: number) {
    const clientUsers = [];
    if (userID > 0) {
      const tmpUsers = this.allUsers.filter((user) => user.CloudUserID == userID);
      for (const tmpUser of tmpUsers) {
        if (tmpUser.UserID) {
          const user = {
            UserID: tmpUser.UserID,
            CloudUserID: tmpUser.CloudUserID,
            FirstName: tmpUser['First Name'],
            LastName: tmpUser['Last Name'],
            EmailAddress: tmpUser.Email,
            TwoWayNum: tmpUser.Phone,
            EmployeeID: tmpUser['Employee ID'],
            UserCategoryID: tmpUser.UserCategoryID,
            Supervisor: tmpUser.Supervisor,
            TruckNum: tmpUser['Truck Number'],
            FirstLog: tmpUser['First Log'],
            Password: tmpUser.Password,
            isCloudSettingsUser: tmpUser.isCloudSettingsUser,
            Archived: tmpUser.Archived,
            Settings: [],
            CognitoUserID: tmpUser.CognitoUserID,
            HexColour: tmpUser.HexColour,
          };

          if (user && this.userSettings[user.UserID]) {
            const settings = this.userSettings[user.UserID];
            if (settings && settings.length > 0) {
              for (const setting of settings) {
                user.Settings.push({
                  "SettingID": setting.SettingID,
                  "Active": setting.Active,
                  "Value": setting.Value,
                  "ActiveOverride": setting.Active,
                  "ValueOverride": setting.Value,
                  "SettingCategoryID": setting.SettingCategoryID
                });
              }
            }
          }
          if (user.Archived) {
            if (this.showArchived) {
              clientUsers.push(user);
            }
          } else {
            clientUsers.push(user);
          }
        }
      }
    }
    return clientUsers;
  }

  rowSelectionChanged(e: any[]) {
    this.selected = e;
  }

  onRowClicked(e) {
    if (this.userCentralizationEnabled) {
      if (e.Source == 'cloud') {
        this.setCurrentUser(e.CloudUserID);
      } else {
        if (e.CloudUserID) {
          this.setCurrentUser(e.CloudUserID);
        } else {
          this.setCurrentUser(e.UserID);
        }
      }
    } else {
      this.setCurrentUser(e.UserID);
    }
    this.modalService.open(this.USER_MODAL_ID);
  }

  getRowClass(row) {
    const data = {...row.getData()};
    if (data.Archived && data.Archived === 1) {
      row.getElement().classList.add('tabulator-table-user-archived');
    } else if (data['Needs Attention'] && data['Needs Attention'] === true) {
      row.getElement().classList.add('tabulator-table-user-attention');
    }
  }

  userSaved() {
    this.refreshUsers();
    this.modalService.close(this.USER_MODAL_ID);
  }

  closeUserModal() {
    this.modalService.close(this.USER_MODAL_ID);
    this.clearUser();
  }

  setUserHexColour() {
    this.openUser["HexColour"] = this.generateRandomColor('');
    this.openUser["ClientUsers"][0]["HexColour"] = this.generateRandomColor('');
  }

  /**
 * Generates a random colour if the input colour is not acceptable 
 *
 * @param {string} inputColour
 * @return {string} 
 * @memberof TicketMapService
 */
  generateRandomColor(inputColour: string): string {
    if (inputColour && inputColour != '' && inputColour != "#000000" && inputColour.toLowerCase() != "#ffffff") return inputColour;
    let returnedColor = "#";

    try {
      const letters = "0123456789ABC" // left out lighter colours (DEF) 
      for (let i = 0; i < 6; i++) {
        returnedColor += letters[Math.floor(Math.random() * letters.length)];
      }
    } catch (error) {
      console.error(error);
    }
    return returnedColor;
  }

  clearUser() {
    this.openUser = {
      UserID: -1,
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      TwoWayNum: '',
      EmployeeID: 0,
      UserCategoryID: 8,
      Supervisor: 0,
      TruckNum: '',
      FirstLog: 0,
      Password: '',
      Archived: 0,
      Settings: [],
      HexColour: "",
      ClientUsers: [
        {
          UserID: 0,
          CloudUserID: 0,
          FirstName: '',
          LastName: '',
          EmailAddress: '',
          TwoWayNum: '',
          EmployeeID: 0,
          UserCategoryID: 1,
          Supervisor: 0,
          TruckNum: '',
          FirstLog: 0,
          Password: '',
          Archived: 0,
          isCloudSettingsUser: 0,
          HexColour: "",
          Settings: [],
        },
      ],
    };
  }
}
