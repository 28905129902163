import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "truncate",
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, characters: number): SafeHtml {
    let result = "";

    if (value !== null && value !== undefined) {
      if (typeof value === "string") {
        result =
          this.truncate(value, characters) +
          (value.length > characters ? "..." : "");
      } else if (typeof value === "number") {
        result =
          this.truncate(value.toString(), characters) +
          (value.toString().length > characters ? "..." : "");
      } else {
        result = value;
      }
    }

    return this.sanitizer.sanitize(SecurityContext.HTML, result);
  }

  private truncate(text: string, characters: number): string {
    return text.slice(0, characters);
  }
}
