import {Component, Input, Output, EventEmitter, OnInit, OnChanges} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {UserManagerService} from '../../user-manager/user-manager.service';

//json files for setting groups and types
import settingGroups from '../settingGroups.json';
import settingTypes from '../settingTypes.json';
import {GlobalSetting} from '../user-category-models';
import {SettingID} from '../../core/services/user/setting';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {CompetersSearchBarComponent} from 'src/app/shared/components/inputs/competers-search-bar/competers-search-bar.component';
import {ModifyType} from '../../edit-tables/TableModels';
import {MaterialModule} from '../../shared/material.module';

export interface SettingGroup {
  GroupID: number;
  Name?: string;
  Active: number;
  Editable: number;
  Settings: DisplaySetting[]; // Optional for nested settings
  DisplaySettings?: DisplaySetting[]; //settings to show 
}

export interface SettingChangeEventType {
  settingID: number,
  active: number,
  value: string | number | string[]
}

export class DisplaySetting {
  SettingID: number;
  DefaultValue: null | string | string[]; // Adjust the type accordingly
  DefaultActive: number;
  Description: string;
  CanEdit: number; 
  CanView: number;
  Name: string;
  Type?: SettingType;
  SettingCategoryID?: number;

  constructor(settingID: number, defaultValue: string, defaultActive: number, description: string, name: string, type?: SettingType, settingCategoryID?: number, canEdit = 0, canView = 0) {
    this.SettingID = settingID;
    this.DefaultValue = type.Multiselect ? defaultValue.split(',') : defaultValue;
    this.DefaultActive = defaultActive;
    this.Description = description;
    this.Name = name;
    this.Type = type;
    this.SettingCategoryID = settingCategoryID;
    this.CanEdit = canEdit;
    this.CanView = canView;

    if (this.DefaultValue === 'null') {
      this.DefaultValue = null;
    }
  }
}

export interface SettingType {
  Type: string;
  SettingID: string | number;
  Options?: {value: string | number, name: string}[];
  Default?: string;
  Multiple?: number;
  Multiselect?: boolean;
}

export interface MenuOption {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  function: Function;
  tooltip: string;
}

@Component({
  selector: 'app-edit-settings-component',
  templateUrl: "edit-settings.component.html",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    CompetersSearchBarComponent,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class EditSettingsComponent implements OnInit, OnChanges {
  @Input() settingIDs: {settingID: number, active: number, value: string | number | string[], settingCategoryID?: number}[];
  @Input() adminSettings: GlobalSetting[];
  @Input() accessLevel: ModifyType = ModifyType.View;
  @Input() menuOptions: MenuOption[] = [];


  // Edit state 
  @Input() editState: boolean; // Input for two-way binding
  @Output() editStateChange = new EventEmitter<boolean>(); // Output for two-way binding


  @Output() settingsChangeEvent:
    EventEmitter<SettingChangeEventType[]>
    = new EventEmitter<SettingChangeEventType[]>();

  allSettings: SettingGroup[] = [];
  displayGroups: SettingGroup[] = [];
  fileSettingGroups = settingGroups;
  routingAlgorithms: {value: string, name: string}[] = [];

  displayedColumns: string[] = ['name', 'value'];

  searchTerm: string = '';
  ModifyType = ModifyType;
  showTable: boolean = false;


  constructor(private userManger$: UserManagerService) { }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }


  get numColumns(): number {
    return 2;
  }


  ngOnInit(): void {
    if (this.adminSettings) this.ngOnChanges();
  }


  ngOnChanges(): void {
    this.mapSettingsToGlobalSettings();
    this.showTable = true;
    // wait for some time before showing the table 
    // this avoids weird ui bugs 
    // setTimeout(() => {
    //   this.showTable = true;
    // }, 100);
  }


  /**
   * Edit mode  
   */
  toggleEditMode() {
    this.editState = !this.editState;
    this.editStateChange.emit(this.editState);
  }


  /**
   * Toggles a group of settings 
   * @param groupIDToToggle 
   */
  toggleGroupActive(groupIDToToggle) {
    //toggle all the settings for the groupID
    const groupToUpdate = this.displayGroups.find(group => group.GroupID === groupIDToToggle);
    groupToUpdate.Active = groupToUpdate.Active == 1 ? 0 : 1;
    this.toggleSettingsForGroup(groupToUpdate);
    this.updateSettingIDsFromDisplatSettings();
  }


  /**
   * Updates this.displayGroups with the new values from this setting 
   * Calls the settingChange event
   * @param setting 
   */
  updateSettings(setting) {
    const settingIDToUpdate = setting.SettingID;
    const newValue = setting.DefaultValue;
    //find the setting in this.displayGroups and update its value
    this.displayGroups.forEach(group => {
      group.Settings.forEach(setting => {
        if (setting.SettingID === settingIDToUpdate) {
          setting.DefaultValue = newValue;
        }
      });
    });
    this.updateSettingIDsFromDisplatSettings();
  }


  /**
   * Toggles a setting group
   * triggers settingsChangeEvent
   * @param group 
   */
  toggleSettingsForGroup(group: SettingGroup) {
    for (const setting of group.Settings) {
      setting.DefaultActive = group.Active;
      if (setting.SettingID === SettingID.ENABLE_LOCATOR_ROUTING) {
        //this setting is the opposite of routing
        setting.DefaultActive = group.Active === 1 ? 0 : 1;
      }
    }
  }


  /**
   * Adds a setting to this.displayGroups 
   * triggers settingsChangeEvent
   * @param groupIDToAdd
   */
  async addSettingGroup(groupIDToAdd) {
    //add a group to this.displayGroups from this.allSettings by the GroupID
    const groupToAdd = this.allSettings.find(group => group.GroupID === groupIDToAdd);

    groupToAdd.Active = 1;
    for (const setting of groupToAdd.Settings) {
      setting.DefaultActive = 1;
      setting.DefaultValue = setting.Type.Multiselect ? [setting.Type.Default] : setting.Type.Default ?? null;
    }

    this.displayGroups.push(groupToAdd);
    await this.setupAllSettingsList(this.adminSettings, this.displayGroups);
    this.updateSettingIDsFromDisplatSettings();
    this.filterSettingsToDisplay(this.displayGroups);
  }


  /**
   * Removes a setting from this.displayGroups 
   * triggers settingsChangeEvent
   * @param groupIDToRemove
   */
  async removeSettingGroup(groupIDToRemove) {
    this.displayGroups = this.displayGroups.filter(group => group.GroupID !== groupIDToRemove);
    await this.setupAllSettingsList(this.adminSettings, this.displayGroups);
    this.updateSettingIDsFromDisplatSettings();
  }


  /**
   * extract single settings from this.displayGroups and update this.settingIDs
   * call settingsChangeEvent event
   */
  updateSettingIDsFromDisplatSettings() {
    this.settingIDs = [];
    for (const group of this.displayGroups) {
      for (const setting of group.Settings) {
        this.settingIDs.push({
          settingID: setting.SettingID,
          active: setting.DefaultActive,
          value: setting.DefaultValue,
          settingCategoryID: setting.SettingCategoryID
        });
      }
    }
    this.settingsChangeEvent.emit(this.settingIDs);
  }

  /**
   * Formats the user's settings according to the groups and admin settings 
   *
   * @memberof EditSettingsComponent
   */
  async mapSettingsToGlobalSettings() {
    try {
      const formatSettings = this.formatSettingsToDisplay(this.adminSettings, this.settingIDs);
      const x = await this.groupSettings(formatSettings);
      //sort x by name alphabetically
      // x.sort((a, b) => a.Name.localeCompare(b.Name));

      await this.setupAllSettingsList(this.adminSettings, x);
      this.filterSettingsToDisplay(x);
      this.displayGroups = x;
    } catch (error) {
      console.error(error);
    }
  }


  /**
   * Ensures each setting group only shows settings you can't toggle. Ex: 
   * You only show sliders and dropdowns,  use the group toggle to turn the setting off 
   *
   * @param {*} groups
   * @memberof EditSettingsComponent
   */
  filterSettingsToDisplay(groups) {
    for (const group of groups) {
      //get the settings which have a type and whose type is not 'bool' 
      group.DisplaySetting = group.Settings.filter(setting => setting.Type && setting.Type.Type !== 'bool');
    }
  }

  /**
   * Sets the assSettings variable
   *
   * @param {GlobalSetting[]} AdminSettings
   * @param {SettingGroup[]} omitSettings
   * @memberof EditSettingsComponent
   */
  async setupAllSettingsList(AdminSettings: GlobalSetting[], omitSettings: SettingGroup[]) {
    const allSettingIDs = [];

    for (const adminSetting of AdminSettings) {
      allSettingIDs.push({
        settingID: adminSetting.SettingID,
        active: adminSetting.Active,
        value: adminSetting.Value,
      })
    }

    const formatAllSettings = this.formatSettingsToDisplay(AdminSettings, allSettingIDs);
    const allAdminSettings = await this.groupSettings(formatAllSettings);

    //get the settings from allAdminSettings that don't exist in omitSettings
    this.allSettings = allAdminSettings.filter(setting => !omitSettings.some(omitSetting => omitSetting.GroupID === setting.GroupID));
  }


  /**
 * Groups settings from input SettingGroup[]
 * @param formatSettings 
 * @returns 
 */
  async groupSettings(formatSettings: DisplaySetting[]): Promise<SettingGroup[]> {
    const displaySettings = [];
    const groupedSettings = this.groupSettingsByID(formatSettings);
    await this.processGroupSettings(groupedSettings, displaySettings, formatSettings);
    return displaySettings;
  }


  /**
   * Groups settings from input SettingGroup[] by their SettingID.
   * @param formatSettings 
   * @returns 
   */
  private groupSettingsByID(formatSettings: DisplaySetting[]): Map<number, DisplaySetting[]> {
    const groupedSettings = new Map<number, DisplaySetting[]>();
    const alreadyInGroup = [];

    for (const group of this.fileSettingGroups) {
      const groupSettingIDs = group.SettingIDs;
      if (!Array.isArray(groupSettingIDs)) {
        console.error("Setting IDs for group", group.Name, "are not in an array format.");
        continue;
      }
      groupSettingIDs.sort((a, b) => a - b);
      const groupSettingsFound = formatSettings.filter(setting =>
        groupSettingIDs.some(settingID => setting.SettingID === settingID)
      );
      if (groupSettingsFound.length > 0) {
        groupedSettings.set(groupSettingIDs[0], groupSettingsFound);
        groupSettingsFound.forEach(element => {
          alreadyInGroup.push(element.SettingID);
        });
      }
    }

    // find settings from formatSettings that are not in groupedSettings
    for (const setting of formatSettings) {
      if (!alreadyInGroup.includes(setting.SettingID)) {
        groupedSettings.set(setting.SettingID, [setting]);
      }
    }
    return groupedSettings;
  }


  /**
   * Processes grouped settings and adds them to displaySettings.
   * @param groupedSettings 
   * @param displaySettings 
   * @param formatSettings 
   */
  private async processGroupSettings(groupedSettings: Map<number, DisplaySetting[]>, displaySettings: SettingGroup[], formatSettings: DisplaySetting[]): Promise<void> {
    for (const [groupID, groupSettingsFound] of groupedSettings.entries()) {

      let isGroupActive = 0;
      let isGroupEditable = 0;
      for (const groupSetting of groupSettingsFound) {
        if (groupSetting.DefaultActive == 1) {
          isGroupActive++;
        }

        if (groupSetting.CanEdit === 1) {
          isGroupEditable = 1;
        }

        //special cases
        if (groupSetting.SettingID === 160) {
          groupSetting.Type.Options = await this.getRoutingAlgorithms();
        }
      }

      // if the average number of settings are active, set the group to active 
      // ( some setting groups require mismatched settings like routing )
      if (isGroupActive >= groupSettingsFound.length / 2) {
        isGroupActive = 1;
      } else {
        isGroupActive = 0;
      }

      displaySettings.push({
        GroupID: groupID,
        Name: this.fileSettingGroups.find(group => group.SettingIDs[0] === groupID)?.Name || groupSettingsFound[0].Name,
        Active: isGroupActive,
        Editable: isGroupEditable,
        Settings: groupSettingsFound
      });
      // Remove grouped settings from formatSettings
      formatSettings = formatSettings.filter(setting => !groupSettingsFound.includes(setting));
    }
  }


  /**
   * Gets the routing algorithms and formats it
   * @returns 
   */
  async getRoutingAlgorithms() {
    if (this.routingAlgorithms.length > 0) return this.routingAlgorithms;
    const result = await this.userManger$.getAlgorithms();

    const formatAlgorithms: {value: string, name: string}[] = [];

    for (const algorithm of result) {
      formatAlgorithms.push({
        value: algorithm.AlgorithmID,
        name: algorithm.AlgorithmName
      });
    }
    this.routingAlgorithms = formatAlgorithms;
    return formatAlgorithms;
  }


  /**
   * Formats settings to show to the user 
   * @param AdminSettings 
   * @param settingsToFormat 
   * @returns 
   */
  formatSettingsToDisplay(AdminSettings: GlobalSetting[], settingsToFormat: SettingChangeEventType[]): DisplaySetting[] {
    const formatSettings = [];
    for (const setting of settingsToFormat) {
      const settingID = setting.settingID;
      const settingValue = setting.value;
      const settingActive = setting.active;

      const adminSetting = AdminSettings.find(adminSetting => adminSetting.SettingID == settingID);
      if (adminSetting) {
        adminSetting.Value = String(settingValue);
        adminSetting.Active = settingActive ? 1 : 0;

        let type: SettingType = {
          SettingID: '',
          Type: 'bool',
        }; // Default type is 'bool'

        // Check if the setting ID exists in settingTypes
        const matchedSettingType = settingTypes.find(type => Number(type.SettingID) === settingID);
        if (matchedSettingType) {
          type = matchedSettingType as unknown as SettingType;
        }

        const newFormatSetting: DisplaySetting = new DisplaySetting(
          settingID, 
          String(settingValue), 
          settingActive, 
          adminSetting.Description, 
          adminSetting.Name, 
          type, 
          null,
          adminSetting.CanEdit, 
          adminSetting.CanView);

        formatSettings.push(newFormatSetting);

        //if it is not routing algorithm
      } else if (settingID != 160) {
        //else if it is the algorithm
        // console.warn("Unable to find setting: " + settingID);
      }
    }
    return formatSettings;
  }


  /**
   * Filters settings based off search term
   */
  get filteredSettings() {
    return this.allSettings.filter(setting => setting.Name.toLowerCase().includes(this.searchTerm.toLowerCase()));
  }


  /**
   * Updates the search term
   */
  searchChangedEvent(event) {
    this.searchTerm = event;
  }

}
