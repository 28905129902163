export enum FilterOperator {
  contains = "contains",
  equal = "equal",
  notEqual = "notEqual",
  lessThanOrEqual = "lessThanOrEqual",
  greaterThanOrEqual = "greaterThanOrEqual",
}

export enum EventType {
  STANDARD = 1,
  PARENT_CHILD = 2,
  NOTE = 3,
}

export interface FilterOption {
  field: string;
  value: string | number;
  operator: FilterOperator;
}

export interface EventObject {
  EventID: number;
  Title: string;
  StartTime: Date;
  EndTime: Date;
  bAllDay?: boolean;
  Location?: string;
  Note?: string;
  Status?: string;
  ResourceId?: number;
  AssignmentID?: number;
  PrimaryID?: number;
}

export interface Event {
  EventID: number;

  StartTime: Date;
  EndTime: Date;

  ResourceID: number;

  EventStart?: Date;
  EventEnd?: Date;

  ParentEventID?: number;
  // TentativeEndTime?: Date;
  Location?: string;
  City?: string;
  Tags?: number[];
  PeopleResourceIDs?: number[];
  ResourceEquipmentIDs?: number[];
  // isTentative?: boolean;
  EventName?: string;
  EventNotes?: string;
  // FailedToUpdate?: boolean;
  AssignmentID?: number;
  PrimaryID?: number;
  EventTypeID?: number;
  EventDesc?: string;
  UserResponsibleID?: number;
  UserResponsibleResourceID?: number;
  // RecurrenceRule?: string;
  // RecurrenceException?: string;
  bIncludeWeekends?: boolean;
  EventColour?: string;

  ContactName?: string;
  ExtentOfWork?: string;
  UserResponsible?: string;
  JobNumber?: string;
}

export interface Resource {
  ResourceID: number;
  ResourceName: string;
  ResourceTypeID: number;
  ResourceDesc?: string;
  HexColour?: string;
  UserID?: number;
  Fullname?: string;
  bSingleton?: number;
}

export interface Ticket {
  Location?: string;
  AssignmentID: string | number;
  RequestNumber: string | number;
  PrimaryID: string | number;
  Tags?: number[];
  ExcavationDate?: Date;
  CallDate?: Date;
  EntryDate?: Date;
  LocateStatusDesc?: string;
  LocateStatusID?: number;
  Address?: string;
  City?: string;
  Comments?: string;
  ExcavatorCompany?: string;
  Remarks?: string;
  ContactName?: string;
  ExtentOfWork?: string;
  Identifier?: string;
  UserResponsible?: string;
  JobNumber?: string;
  isScheduled?: number;
  PONumber?: number;
}

export interface TicketTag {
  TagID: number;
  TagName: string;
  TagColour: string;
}

/**
 * Event
 */

export enum SchedulerEventActions {
  CreateEvent = 1,
  DeleteEvent = 2,
  GetEventObj = 3,
  GetEventByField = 5,
  UpdateEvent = 6,
}

export interface GetEventObjVal {
  action: SchedulerEventActions.GetEventObj;
  EventIDArr?: any[];
  AllEvents?: boolean;
  DateRange?: {
    Start: any,
    End: any
  }
}

export interface GetEventByField {
  action: SchedulerEventActions.GetEventByField;
  Fields?: string[];
  DateField?: string;
  StartDate?: string;
  EndDate?: string;
}

export interface CreateEventVal {
  action: SchedulerEventActions.CreateEvent;
  Events: DBEvent[];
}

export interface DeleteEventVal {
  action: SchedulerEventActions.DeleteEvent;
  EventIDArr: any[];
}

export interface UpdateEventVal {
  action: SchedulerEventActions.UpdateEvent;
  Events: any;
}

export interface DBEvent {
  EventID?: number;
  AssignmentID?: number;
  PrimaryID?: number;
  EventTypeID?: number;
  EventName?: string;
  EventLocation?: string;
  EventDesc?: string;
  EventNotes?: string;
  EventStartDateTime?: string;
  EventEndDateTime?: string;
  ProjectEventEndDate?: string;
  UserResponsibleID?: number;
  ParentEventID?: number;
  bAllDay?: number;
  Resources?: DBResource[];
  ResourceIDArr?: any[];
  Ticket?: DBTicket;
  ChildrenEvents?: DBEvent[];
  bIncludeWeekends?: boolean;
  EventColour?: string;
}

/**
 * Resource
 */
export enum SchedulerResourceActions {
  CreateResource = 1,
  DeleteResource = 2,
  GetResourceObj = 3,
  GetAllResources = 4,
  GetResourcesByField = 5,
  UpdateResource = 6,
}

export enum SchedulerResourceTypes {
  user = 1,
  rig = 2,
  truck = 3,
}

export interface GetResourceObjVal {
  action: SchedulerResourceActions.GetResourceObj;
  ResourceIDs?: number[];
}

export interface GetAllResourcesVal {
  action: SchedulerResourceActions.GetAllResources;
}

export interface GetResourcesByFieldVal {
  action: SchedulerResourceActions.GetResourcesByField;
  Field: string;
  Values: any[];
}

export interface CreateResourceVal {
  action: SchedulerResourceActions.CreateResource;
  Resource: DBResource;
}

export interface DBResource {
  ResourceID?: number;
  ResourceTypeID?: number;
  ResourceName?: string;
  ResourceDesc?: string;
  HexColour?: string;
  UserID?: number;
  bSingleton?: number;
}

export interface DeleteResourceVal {
  action: SchedulerResourceActions.DeleteResource;
  ResourceID: number;
}

export interface UpdateResourceVal {
  action: SchedulerResourceActions.UpdateResource;
  Resource: DBResource;
}

/**
 * Schedule
 */

export enum SchedulerScheduleActions {
  CreateSchedule = 1,
  GetEventCardsByScheduleID = 7,
}

export interface GetEventCardsByScheduleIDVal {
  action: SchedulerScheduleActions.GetEventCardsByScheduleID;
  ScheduleID: number;
}

export interface CreateScheduleVal {
  action: SchedulerScheduleActions.CreateSchedule;
  ScheduleName: string;
  ScheduleDesc: string;
  CardEventFields: string[];
}

/**
 * Ticket
 */

export enum SchedulerTicketActions {
  GetTickets = 3,
  GetTicketByAssignmentID = 5,
  GetTicketTags = 7,
  GetTicketByPrimaryID = 9,
}

export interface SchedulerGetTicketsVal {
  action: SchedulerTicketActions.GetTickets;
  DateField?: string;
  StartDate?: string;
  EndDate?: string;
}

export interface SchedulerGetTicketByAssignmentIDVal {
  action: SchedulerTicketActions.GetTicketByAssignmentID;
  AssignmentID: number;
}

export interface SchedulerGetTicketByPrimaryIDVal {
  action: SchedulerTicketActions.GetTicketByPrimaryID;
  PrimaryID: number;
}

export interface SchedulerGetTicketTagsVal {
  action: SchedulerTicketActions.GetTicketTags;
}

export interface DBTicket {
  AssignmentID: number;
  PrimaryID: number;
  TicketTags?: DBTicketTag[];
}
export interface DBTicketTag {
  TagID: number;
  bFieldAdded: number;
  bFieldRemoved: number;
}
