import { Injectable } from '@angular/core';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { ApiService, UtilocateApiRequest } from '../core/api/baseapi.service';
import { from } from 'rxjs';
import { ProgressBarService } from '../shared/progress-bar/progress-bar.service';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { UserService } from '../core/services/user/user.service';
import { UtilocateTokenPaths } from '../core/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicingManagerService {

  reports: any[] = [];

  reportIDs: number[];

  constructor(
    private utilocateAPIService: ApiService, 
    private progressBarService: ProgressBarService,
    private userService: UserService
    ) { }

  /**
 * Getter for reportIDs
 * @returns reportIDs i.e. [1,2]
 */
  getReportIDs(): number[] {
    return this.reportIDs;
  }

  async getInvoiceReports(reportTypeID) {
    let invoiceReports = [];
    try {
      let value = {
        query: {
          reportTypeID: reportTypeID
        }
      }
      let response = await this.invokeReportControllerWithPayload(value);
      invoiceReports = response.reports;
      this.reportIDs = invoiceReports.map(report => report.ReportID)
    } catch (error) {
      console.error(`Error getting invoiceReports: ${error.message}`);
    }
    return invoiceReports;
  }

  async getInvoiceReportByID(reportID: number) {
    let value = {
      query: {
        reportID: reportID
      }
    }
    let report = await this.invokeReportControllerWithPayload(value);
    return report;
  }

  async runInvoiceReport(reportID, reportParameters) {
    try {
      let apiKey = apiKeys.u2.generateAdvancedReport;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_URL_DATA_PARAMS: {
          ReportID: reportID
        },
        API_BODY: {
          reportParameters: JSON.stringify(reportParameters),
          // emailAddress: "daniel@competers.com"
        },
      };

      let apiResult = await this.utilocateAPIService.invokeUtilocateApi(
        utilocateApiRequest
      );
    } catch (error) {
      console.error(error);
    }
  }

    /**
   * Calls the API with specific payload 
   */
  async invokeReportControllerWithPayload(apiValue: any) {
    this.progressBarService.start();
    let result = null;
      try {
        let apiKey = apiKeys.u2.reportController;
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        let apiResult = await this.utilocateAPIService.invokeUtilocateApi(
          utilocateApiRequest
        );
        if (apiResult.body) {
          result = apiResult.body;
        }
      } catch (error) {
        console.error(error);
      }
      this.progressBarService.stop();
    return result;
  }

  async archiveGeneratedReports(toArchive: any[]) {
    this.progressBarService.start();
    let result = null;
    try {
      let value = {
        mutation: {
          update: {
            generatedReportsToArchive: toArchive
          }
        }
      }
      let response = await this.invokeReportControllerWithPayload(value);
      result = response.result;
    } catch (error) {
      console.error(`Error archiving GeneratedReports: ${error.message}`);
    }
    this.progressBarService.stop();
    return result;
  }

  async unarchiveGeneratedReports(toUnarchive: any[]) {
    this.progressBarService.start();
    let result = null;
    try {
      let value = {
        mutation: {
          update: {
            generatedReportsToUnarchive: toUnarchive
          }
        }
      }
      let response = await this.invokeReportControllerWithPayload(value);
      result = response.result;
    } catch (error) {
      console.error(`Error unarchiving GeneratedReports: ${error.message}`);
    }
    this.progressBarService.stop();
    return result;
  }

  async invoiceGeneratedReports(toInvoice: any[]) {
    this.progressBarService.start();
    let result = null;
    try {
      let value = {
        mutation: {
          update: {
            generatedReportsToInvoice: toInvoice
          }
        }
      }
      let response = await this.invokeReportControllerWithPayload(value);
      console.log("response:", response);
      result = response
    } catch (error) {
      console.error(`Error invoicing GeneratedReports: ${error.message}`);
      result = error.message;
    }
    this.progressBarService.stop();
    return result;
  }

  async uninvoiceGeneratedReports(toUninvoice: any[]) {
    this.progressBarService.start();
    let result = null;
    try {
      let value = {
        mutation: {
          update: {
            generatedReportsToUninvoice: toUninvoice
          }
        }
      }
      let response = await this.invokeReportControllerWithPayload(value);
      console.log("response:", response);
      result = response;
    } catch (error) {
      console.error(`Error invoicing GeneratedReports: ${error.message}`);
      result = error.message;
    }
    this.progressBarService.stop();
    return result;
  }


  async downloadFile(filename: string, userID: number, generatedReportID: number) {
    this.progressBarService.start();
    try {
      let clientID = this.userService.getUserValueFromToken(
        UtilocateTokenPaths.CLIENTID
      );
      
      const isLiveSite = environment.production == true;
      let stage = 'old'
      if (isLiveSite) {
        stage = 'live'
      }

      let key = `${stage}/${clientID}/reports/${userID}/${generatedReportID}/${filename}`;
      
      let s3ImageObj = await this.retrieveDocFromS3(key);
      let buffer = null;    
      let type = null;

      buffer =
        "data:text/csv;base64," +
        this.arrayBufferToBase64(s3ImageObj["data"]["file"]["data"]);
      type = "csv";

      // *this only downloads the doc
      // *the user will open their doc in their default Windows application
      const linkSource = buffer;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
    this.progressBarService.stop();
  }

  private arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private retrieveDocFromS3(key: string) {
    let url = "/api/get/file"; // localhost
    let bucket = null;

    // update url to include nodejs for non-localhost sites
    const isLocalHost = environment.localhost;
    if (!isLocalHost) {
      url = "/nodejs/api/get/file";
    }

    bucket = 'utilocate.client.docs';
    
    return axios.get(url, {
      //remove /nodejs for dev
      // to send information in a get use
      params: {
        key: key,
        bucket: bucket,
      },
    });
  }
}
