<mat-progress-bar mode="indeterminate" *ngIf="loading || reportIsRunning; else sizedBox" [value]="0" />
<ng-template #sizedBox>
  <div class="h-1 bg-white"></div>
</ng-template>

<div class="border border-gray-300 border-x-0 border-t-0 p-4 pt-6 bg-white">
  <!-- Header Section -->
  <div>
    <div *ngIf="report" class="uppercase font-rajdhani font-semibold text-headline-6 pb-1">{{ report.Name }}</div>
    <pre><div *ngIf="report" class="font-montserrat text-md" [innerHTML]="renderDescription(report.Description)"></div></pre>
  </div>

  <!-- Parameters section -->
  <div class="py-1">
    <!-- Form for parameters -->
    <form [formGroup]="report.FormGroup" class="pt-1">
      <div *ngFor="let parameter of report.ReportParameters">
        <div>
          <!-- Date Picker -->
          <div *ngIf="parameter.Type === ParameterType.Date">
            <mat-form-field appearance="outline" class="pb-[2px] dense-6">
              <mat-label class="uppercase font-rajdhani text-bold">{{ parameter.Prompt }}</mat-label>
              <input matInput [matDatepicker]="picker" [formControlName]="parameter.ParameterID" [required]="true" />
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error class="font-montserrat text-[8px]">{{ dateRangeErrorMessage }}</mat-error>
            </mat-form-field>
          </div>

          <!-- Numbers -->
          <div *ngIf="parameter.Type === ParameterType.Number">
            <mat-form-field appearance="outline" class="pb-[2px] dense-6">
              <mat-label class="uppercase font-rajdhani text-bold">{{ parameter.Prompt }}</mat-label>
              <input matInput [formControlName]="parameter.ParameterID" type="number" [required]="true" />
              <mat-error>Required</mat-error>
            </mat-form-field>
          </div>

          <!-- Strings -->
          <div *ngIf="parameter.Type === ParameterType.String">
            <mat-form-field appearance="outline" class="pb-[2px] dense-6">
              <mat-label class="uppercase font-rajdhani text-bold">{{ parameter.Prompt }}</mat-label>
              <input matInput [formControlName]="parameter.ParameterID" type="text" [required]="true" />
              <mat-error>Required</mat-error>
            </mat-form-field>
          </div>

          <!-- Select Dropdown -->
          <div *ngIf="parameter.Type === ParameterType.Select">
            <mat-form-field appearance="outline" class="pb-[2px] dense-6">
              <mat-label class="uppercase font-rajdhani text-bold">{{ parameter.Prompt }}</mat-label>
              <mat-select [formControlName]="parameter.ParameterID" [required]="true">
                <mat-option *ngFor="let option of parameter.Options" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>Required</mat-error>
            </mat-form-field>
          </div>

          <!-- Multi-Select Dropdown -->
          <div *ngIf="parameter.Type === ParameterType.MultiSelect">
            <mat-form-field appearance="outline" class="pb-[2px] dense-6">
              <mat-label class="uppercase font-rajdhani text-bold">{{ parameter.Prompt }}</mat-label>
              <mat-select [formControlName]="parameter.ParameterID" [required]="true" multiple>
                <mat-option *ngFor="let option of parameter.Options" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <mat-error>Required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Run Report -->
    </form>

    <!-- Buttons -->
    <div class="flex flex-row justify-between">
      <button
        mat-flat-button
        (click)="onSubmit()"
        [disabled]="reportIsRunning"
        type="submit"
        class="text-white uppercase font-rajdhani font-semibold text-lg w-36"
        [ngClass]="{ 'bg-success': !reportIsRunning, 'bg-success-darker': !reportIsRunning }">
        <div class="flex flex-row-reverse justify-between items-start w-32">
          <div class="h-5 w-5 white-icon">
            <mat-icon svgIcon="right_small"/>
          </div>
          <div class="pt-[1px]">Run Report</div>
        </div>
      </button>

      <!-- Download buttons -->
      <div class="flex flex-row items-center justify-around">
        <button
          mat-stroked-button
          [disabled]="!dataExists"
          (click)="downloadClicked.emit('CSV')"
          class="uppercase font-rajdhani font-semibold text-lg flex flex-row justify-between">
          <mat-icon svgIcon="download" />
          CSV
        </button>
        <!-- <button
          mat-stroked-button
          (click)="downloadClicked.emit('XLSX')"
          class="uppercase font-rajdhani font-semibold text-lg flex flex-row justify-between">
          <mat-icon svgIcon="download" />
          XLSX
        </button>
        <button
          mat-stroked-button
          (click)="downloadClicked.emit('PDF')"
          class="uppercase font-rajdhani font-semibold text-lg flex flex-row justify-between">
          <mat-icon svgIcon="download" />
          PDF
        </button> -->
      </div>
    </div>
  </div>
</div>
