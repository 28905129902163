<div fxLayout="row" style="height: 100%; width: 100%">
  <app-page-navigation [routes]="routes" [header]="header"></app-page-navigation>
  <div *ngIf="!noRoute; else showNoRoute" fxFlex style="height: 100%">
    <router-outlet></router-outlet>
  </div>
  <ng-template #showNoRoute>
    <div class="font-rajdhani uppercase text-headline-5 font-semibold p-4">
      select a report to run
    </div>
  </ng-template>
</div>
