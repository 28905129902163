// @ts-nocheck
import { effect, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { createStyleFunction } from 'ol-esri-style';
import { sortBy } from 'lodash-es/collection';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';
import { apiKeys } from '../../../ENDPOINTS';
import { BehaviorSubject, concat, EMPTY, firstValueFrom, from, iif, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, finalize, first, map, switchMap, tap, toArray } from 'rxjs/operators';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { ImageWMS, Source, TileArcGISRest, TileWMS } from 'ol/source';
import { ManagedLayer } from '../classes/managedLayer';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { EsriJSON, GeoJSON } from 'ol/format';
import { createXYZ } from 'ol/tilegrid';
import { bbox as bbox_strategy, tile } from 'ol/loadingstrategy';
import LayerGroup from 'ol/layer/Group';
import { LayerQueryFilters } from '../utilities/types';
import Style, { StyleLike } from 'ol/style/Style';
import { isEqual } from 'lodash-es';
import Styles from '../utilities/styles';
import { Circle, Geometry, LineString, Polygon, SimpleGeometry } from 'ol/geom';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import TileState from 'ol/TileState.js';
import ImageLayer from 'ol/layer/Image';
import { environment } from '../../../../environments/environment';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';
import { CollectionFlattener, FeatureOrCollection } from '../classes/collection-flattener';
import { FeatureStyleService } from './feature-style.service';
import { UserService } from '../../core/services/user/user.service';
import { SettingID } from '../../core/services/user/setting';
import Static from 'ol/source/ImageStatic';
import Icon from 'ol/style/Icon';
import { getLength } from 'ol/sphere';
import { Extent } from 'ol/extent';
import { Mutex } from '../../../shared/classes/Mutex';

@Injectable({
  providedIn: 'root',
})
export class LayerManagerService implements OnDestroy {
  // services
  private apiService = inject(ApiService);
  private userService = inject(UserService);
  private featureStyleService = inject(FeatureStyleService);

  // observables
  private databaseLayerArray: DatabaseLayer[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private refreshLayers$: Subject<void> = new Subject();
  private layerStreamEnd$: Subject<void> = new Subject<void>();
  private layerStreamStart$: Subject<void> = new Subject<void>();
  private databaseLayers$: BehaviorSubject<DatabaseLayer[]> = new BehaviorSubject([]);
  private _managedLayers$: BehaviorSubject<ManagedLayer[]> = new BehaviorSubject<ManagedLayer[]>([]);
  private _selectedLayer$: BehaviorSubject<ManagedLayer | null> = new BehaviorSubject<ManagedLayer | null>(null);
  private _miscLayer$ = new BehaviorSubject<VectorLayer<VectorSource<Feature<SimpleGeometry>>>>(null);
  private _digSites$$ = signal([]);
  private _filters$$ = signal(null);

  // members
  private _checkChanges: () => MapFeatureChange<FeatureOrCollection>[];
  private _geoserverCredentials: { username: string; password: string };
  private _locateBoundingBox = null;
  private jsonFormatter: GeoJSON = new GeoJSON();

  private _canvasLayers: [ImageLayer<Static>, VectorLayer<VectorSource>] = null;

  private OSMLayer: ManagedLayer;
  private satLayer: ManagedLayer;
  private digSiteLayer: ManagedLayer;
  private _locateAreaLayer: ManagedLayer = null;
  private _builtLayers = {
    value: [],
    mu: new Mutex(),
  };

  constructor() {
    this.setupLayerEffect();
    this.init();
  }

  ngOnDestroy() {
    this._managedLayers$.complete();
    this.destroy$.next();
  }

  compileLayers() {
    const temp = [];
    temp.push(this.OSMLayer);
    temp.push(this.satLayer);
    for (let i = 0; i < this._builtLayers.value.length; i++) {
      temp.push(this._builtLayers.value[i]);
    }
    if (this.digSiteLayer) {
      temp.push(this.digSiteLayer);
    }
    if (this._locateAreaLayer) {
      temp.push(this._locateAreaLayer);
    }
    this._managedLayers$.next(temp);
  }

  setupLayerEffect() {
    effect(() => {
      const digSites = this._digSites$$();
      if (digSites.length > 0) {
        this.digSiteLayer = this.buildDigSiteLayer(digSites);
      } else {
        this.digSiteLayer = null;
      }
      this.compileLayers();
    });
    effect(async () => {
      const filters = this._filters$$();
      await this._builtLayers.mu.acquire();
      await this.destroyLayers(this._builtLayers.value);
      this._managedLayers$.next([]);
      this._builtLayers.value = [];
      try {
        if (filters) {
          const { requestNumber } = filters;
          if (requestNumber) {
            await this.updateLocateAreaLayer(filters.requestNumber);
            if (this.userService.isSettingActive(SettingID.LOCATE_AREA_BBOX) && this._locateAreaLayer) {
              this._locateBoundingBox = (await this.setupLocateAreaBoundingBox(this._locateAreaLayer)) ?? undefined;
            }
          } else {
            this._locateAreaLayer = null;
          }
        }

        this._builtLayers.value = await this.setupMapLayers(this._filters$$());
        this._selectedLayer$.next(this._builtLayers.value.find((x) => x.getProperties().EsriLayerID === 0) ?? null);

        this.compileLayers();
      } finally {
        this._builtLayers.mu.release();
      }
    });
  }

  setupCanvasLayers(img: HTMLImageElement): [ImageLayer<Static>, VectorLayer<VectorSource>] {
    if (this._canvasLayers) {
      this._canvasLayers.forEach((x: Layer) => x.dispose());
    }
    this._canvasLayers = [
      new ImageLayer({
        source: new Static({
          url: img.src,
          projection: 'image',
          imageExtent: [0, 0, img.naturalWidth, img.naturalHeight],
        }),
      }),
      new VectorLayer({
        source: new VectorSource(),
        style: this.featureStyleService.renderFeature.bind(this.featureStyleService),
      }),
    ];
    return this._canvasLayers;
  }

  public async setupMapLayers(filters: Partial<LayerQueryFilters>) {
    return firstValueFrom(
      concat(
        this.gatherLayers(filters).pipe(
          switchMap((layerData) =>
            from(layerData.layers).pipe(
              filter(
                (layer: DatabaseLayer) => layer.LayerURL !== '' && layer.isArchived !== 1 && layer.bIsShownInApp !== -1
              ),
              concatMap((layer) =>
                iif(
                  () => layer.EsriCredentialID > 0,
                  this.getEsriToken(layer.EsriLayerID).pipe(
                    switchMap(({ token }) =>
                      of(layer).pipe(
                        map(() => {
                          layer.Token = token;
                          return layer;
                        })
                      )
                    ),
                    catchError(() => {
                      return of(layer);
                    })
                  ),
                  of(layer)
                )
              ),
              tap((layer) => this.databaseLayerArray.push(layer)),
              concatMap((layer: DatabaseLayer) => this.buildLayer(filters, layer)),
              filter((g) => g !== null),
              toArray(),
              map((layerArray: Layer[]) => {
                // hash the groups from the table and insert grouped layers
                const layerMap = {};
                layerData.groups.forEach((x) => {
                  layerMap[x.EsriLayerGroupID] = {
                    esriLayerGroupID: x.EsriLayerGroupID,
                    groupName: x.GroupName,
                    groupID: x.GroupID,
                    layers: [],
                  };
                });
                layerArray.forEach((layer: Layer) => {
                  layerMap[layer.getProperties().EsriLayerGroupID]?.layers.push(layer);
                });
                return {
                  layerArray: layerArray.filter((layer) => layer.getProperties().EsriLayerGroupID === -1),
                  layerGroups: sortBy(layerMap, [(x) => x.groupID]),
                };
              }),
              map(({ layerGroups, layerArray }) => {
                // nest the layer groups
                while (layerGroups.length > 0 && layerGroups[layerGroups.length - 1].groupID > 0) {
                  const popped = layerGroups.pop();
                  layerGroups.forEach((x) => {
                    if (x.esriLayerGroupID === popped.groupID) {
                      x.layers.push(
                        new LayerGroup({
                          layers: popped.layers,
                          properties: { LayerName: popped.groupName },
                        })
                      );
                    }
                  });
                }
                return { layerGroups, layerArray };
              }),
              concatMap(({ layerGroups, layerArray }) =>
                // combine the iterables
                from([...layerGroups, ...layerArray])
              ),
              map((preLayerGroup) => {
                // create layer group objects
                if (!(preLayerGroup instanceof Layer)) {
                  return new LayerGroup({
                    layers: preLayerGroup.layers,
                    properties: { LayerName: preLayerGroup.groupName },
                  });
                } else {
                  return preLayerGroup;
                }
              }),
              map((group) => {
                return this.buildManagedLayers(group);
              })
            )
          )
        )
      ).pipe(
        filter((x) => x !== null && !isEqual(x, {})),
        toArray(),
        finalize(() => {
          this.layerStreamEnd$.next();
          this.databaseLayers$.next(this.databaseLayerArray);
        })
      )
    );
  }

  private init() {
    this.OSMLayer = this.getOSMBaseLayer();
    this.satLayer = this.getSatelliteLayer();

    // setup misc layer
    this._miscLayer$.next(
      new VectorLayer({
        source: new VectorSource(),
        style: (feature) => {
          return new Style({
            image: new Icon({
              crossOrigin: 'anonymous',
              src: 'assets/custom-icons/pin_border.svg',
              width: 32,
              height: 32,
              opacity: 1,
              color: feature.get('colour'),
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              declutterMode: 'declutter',
            }),
          });
        },
      })
    );
  }

  private gatherLayers(filters: Partial<LayerQueryFilters>): Observable<any> {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { layer: filters ? { ...filters } : { all: true } } },
    };
    return from(this.apiService.invokeUtilocateApi(request)).pipe(
      first(),
      map((response) => response.body.result),
      switchMap((layerData) => iif(() => layerData.layers && layerData.layers.length > 0, of(layerData), EMPTY))
    );
  }

  private addEsriStyle(layer: VectorLayer<VectorSource>) {
    fetch(`${layer.get('LayerURL')}?f=json${layer.get('Token') ? `&token=${layer.get('Token')}` : ''}`)
      .then((res) => res.json())
      .then((json) => createStyleFunction(json, layer.getSource().getProjection()))
      .then((styleFunc: StyleLike) => layer.setStyle(styleFunc))
      .catch(() => {
        // console.error('Caught: Layer likely cleaned up before style was fetched');
        // do nothing
      });
  }

  private buildLayer(filters: LayerQueryFilters, layer: DatabaseLayer) {
    try {
      // builds the layer objects
      let newLayer: Layer = null;
      switch (layer.LayerType) {
        case LayerType.EsriMapServer:
          newLayer = this.layerFactory(
            {
              url: layer.LayerURL,
              params: layer.Token
                ? {
                    TOKEN: layer.Token,
                  }
                : undefined,
              tileLoadFunction: (tile, src) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.addEventListener('loadend', function () {
                  const data = this.response;
                  if (data !== undefined) {
                    tile.getImage().src = URL.createObjectURL(data);
                  } else {
                    tile.setState(TileState.ERROR);
                  }
                });
                xhr.addEventListener('error', function () {
                  tile.setState(TileState.ERROR);
                });
                xhr.open('GET', src);
                xhr.send();
              },
            },
            null,
            TileLayer,
            TileArcGISRest
          );
          break;
        case LayerType.WMSLayerGeoServer:
          return from(fetch(layer.LayerURL + '?service=WMS&version=1.3.0&request=GetCapabilities')).pipe(
            first(),
            switchMap((response) => from(response.text())),
            map((text) => {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(text, 'text/xml');
              const layerNames = Array.from(xmlDoc.getElementsByTagName('Layer'))
                .map((layerNode) => layerNode.getElementsByTagName('Name')[0].textContent)
                .join(',');
              const tileWMS = new TileWMS({
                url: layer.LayerURL,
                params: {
                  tiled: true,
                  layers: layerNames,
                },
                serverType: 'geoserver',
                transition: 0,
              });
              const newWMSLayer = new TileLayer({
                source: tileWMS,
              });
              newWMSLayer.setProperties({ ...layer });
              newWMSLayer.set('LayerName', layer.LayerName);
              newWMSLayer.set('sub_layer_list', layerNames);
              newWMSLayer.setExtent(this._locateBoundingBox);
              return newWMSLayer;
            })
          );
        case LayerType.EsriFeatureServer:
          newLayer = this.buildEsriFeatureServerLayer(layer, this._locateBoundingBox);
          newLayer.setProperties({ ...layer } as Record<string, any>);
          this.addEsriStyle(newLayer);
          newLayer.setExtent(this._locateBoundingBox);
          return of(newLayer);
        case LayerType.WFSLayer:
          return from(this.buildWFSLayer(layer, this._locateBoundingBox));
        case LayerType.TileLayer:
          newLayer = this.layerFactory(
            {
              url: layer.LayerURL,
              params: {
                request: 'GetMap',
                layers: `${layer.LayerName}`,
                TILED: true,
                version: '1.1.0',
                format: 'image/png',
              },
              serverType: 'geoserver',
              transition: 0,
            },
            null,
            TileLayer,
            TileWMS
          );
          break;
        case LayerType.ImageLayer:
          newLayer = this.layerFactory(
            {
              url: layer.LayerURL,
              ratio: 1,
              serverType: 'geoserver',
            },
            null,
            ImageLayer,
            ImageWMS
          );
          break;
        default:
          return EMPTY;
      }
      newLayer?.setProperties({ ...layer } as Record<string, any>);
      newLayer.setExtent(this._locateBoundingBox ?? undefined);
      return of(newLayer);
    } catch (error) {
      console.error(error);
      return EMPTY;
    }
  }

  private buildEsriFeatureServerLayer(layer: DatabaseLayer, bbox: Extent): VectorLayer<VectorSource> {
    return new VectorLayer({
      source: new VectorSource({
        format: new EsriJSON(),
        url: (extent, resolution, projection) => {
          const srid = projection
            .getCode()
            .split(/:(?=\d+$)/)
            .pop();
          return (
            layer.LayerURL +
            '/query/?f=json&' +
            'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
            encodeURIComponent(
              (bbox && this.userService.isSettingActive(SettingID.LOCATE_AREA_BBOX)
                ? `{"xmin":${bbox[0]},"ymin":${bbox[1]},"xmax":${bbox[2]},"ymax":${bbox[3]}`
                : `{"xmin":${extent[0]},"ymin":${extent[1]},"xmax":${extent[2]},"ymax":${extent[3]}`) +
                `,"spatialReference":{"wkid":${srid}}}`
            ) +
            '&geometryType=esriGeometryEnvelope&inSR=' +
            srid +
            '&outFields=*' +
            '&outSR=' +
            srid +
            `${layer.Token && layer.Token !== '' ? '&token=' + layer.Token : ''}`
          );
        },
        strategy: tile(
          createXYZ({
            tileSize: 512,
          })
        ),
      }),
    });
  }

  private async buildWFSLayer(layer: DatabaseLayer, bbox: Extent = undefined) {
    const credentials = await this.getGeoserverCredentials();
    const { username, password } = credentials;
    const flatten: (prop: FeatureOrCollection) => Feature<Geometry>[] = new CollectionFlattener().flattenFeature;
    const checkChanges: () => MapFeatureChange<FeatureOrCollection>[] = this._checkChanges.bind(this);
    const vectorSource = new VectorSource({
      loader: (extent, resolution, projection, success, failure) => {
        const proj = projection.getCode();
        const urlObj = new URL(layer.LayerURL);
        [
          ['service', 'WFS'],
          ['version', '1.1.0'],
          ['request', 'GetFeature'],
          ['outputFormat', 'application/json'],
          ['srsname', proj],
          ['maxFeatures', '10000'],
        ].forEach(([key, val]) => urlObj.searchParams.set(key, val));
        // use proxy if localhost
        const url = (() => {
          const isLocalHost = environment.localhost;
          if (!isLocalHost) {
            return urlObj.toString();
          } else {
            return 'http://' + location.hostname + ':3000/geoserver' + urlObj.toString().split('geoserver')[1];
          }
        })();
        const onError = () => {
          vectorSource.removeLoadedExtent(extent);
          failure();
        };
        fetch(url, {
          method: 'GET',
          headers: {
            Authorization: 'Basic ' + btoa(`${username}:${password}`),
          },
        })
          .then(async (response) => {
            const JSONFormat = new GeoJSON();
            if (response.status == 200) {
              const features = [];
              try {
                JSONFormat.readFeatures(await response.json()).forEach((feature) => features.push(feature));
              } catch (error) {
                console.error('Failed to parse JSON');
                onError();
              }
              if (features.length > 0 && checkChanges !== undefined) {
                const changes: MapFeatureChange<FeatureOrCollection>[] = checkChanges();
                const rawFeatures = changes
                  .map((x) => {
                    const feats = flatten(x.feature);
                    feats.map((y) => y.set('changeType', x.changeType));
                    return feats;
                  })
                  .flat();
                vectorSource.addFeatures(features);
                rawFeatures.forEach((feature) => {
                  if (feature.get('changeType') === FeatureChangeType.deleted) {
                    vectorSource.removeFeature(vectorSource.getFeatureById(feature.getId()));
                  }
                });
              }
              success(features);
            } else {
              onError();
            }
          })
          .catch(() => {
            console.error('Get features failed for:' + url);
            onError();
          });
      },
      format: new GeoJSON(),
      strategy: bbox_strategy,
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: this.featureStyleService.renderFeature.bind(this.featureStyleService),
    });
    vectorLayer.setProperties({ ...layer } as Record<string, any>);
    return vectorLayer;
  }

  private layerFactory<T extends Layer, S extends Source>(
    options: Record<string, any> | null,
    style: StyleLike | null,
    layerType: new (args: Record<string, any>) => T,
    layerSourceType: new (args: Record<string, any>) => S
  ): T {
    const opts = {
      source: new layerSourceType(options),
    } as Partial<{ source: Source; style: StyleLike }>;

    if (style !== null) {
      opts.style = style;
    }
    return new layerType(opts);
  }

  private async setupLocateAreaBoundingBox(layer: ManagedLayer) {
    try {
      const { username, password } = await this.getGeoserverCredentials();
      const url = (() => {
        const inner_url = layer.getProperties().LayerURL;
        const isLocalHost = environment.localhost;
        if (!isLocalHost) {
          return inner_url;
        } else {
          return 'http://' + location.hostname + ':3000/geoserver' + inner_url.split('geoserver')[1];
        }
      })();
      const response = await fetch(url + '&f=json', {
        mode: 'cors',
        headers: {
          Authorization: 'Basic ' + btoa(`${username}:${password}`),
        },
      });
      if (response.status !== 200) {
        return null;
      } else if (!response.headers.get('content-type').includes('application/json')) {
        return null;
      } else {
        const { features } = await response.json();
        if (features.length > 0 && features[0]['geometry'].type.toLowerCase() === 'polygon') {
          const feature: Polygon = this.jsonFormatter.readFeature(features[0], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          return feature.getGeometry().getExtent();
        }
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private getOSMBaseLayer(): ManagedLayer {
    const baseLayer = this.layerFactory(null, null, TileLayer, OSM);
    baseLayer.setProperties({ LayerName: 'Street Map', isDefaultOn: 1, index: 0 });
    return new ManagedLayer(baseLayer, null);
  }

  private buildManagedLayers(layerGroup: Layer | LayerGroup) {
    if (layerGroup instanceof Layer) {
      return new ManagedLayer(layerGroup);
    } else if (layerGroup instanceof LayerGroup) {
      const subLayers = layerGroup
        .getLayers()
        .getArray()
        .map((subLayer: Layer) => this.buildManagedLayers(subLayer));
      return new ManagedLayer(layerGroup, subLayers);
    }
  }

  private buildDigSiteLayer(digSites: DigsiteShape[]): ManagedLayer {
    const layer = new VectorLayer();
    const source = new VectorSource({
      loader: () => {
        const feats = digSites.map((site) => {
          const coords = site.Coordinates.map(({ lat, lng }) => fromLonLat([lng, lat]));
          let poly: Geometry;
          const feat = new Feature();
          feat.setStyle(Styles.digSiteShape());
          try {
            if (site.type === 1 && coords.length == 2) {
              poly = new Circle(coords[0], getLength(new LineString(coords)));
            } else {
              poly = new Polygon([coords]);
            }
            feat.setProperties({
              Feature: 'Dig Area',
              'graphic ID': site.graphicID,
              type: site.type,
              index: 2,
            });
            if (site.type == 1) {
              feat.set(
                'area',
                Math.round(Math.pow(Math.PI * getLength(new LineString(coords)), 2) * 100) / 100 + ' m^2'
              );
            } else {
              feat.set('area', Math.round(poly.getArea() * 100) / 100 + ' m^2');
            }
          } catch {
            console.log('Build digsite failed: ', site.coordinates);
          }
          feat.setGeometry(poly);
          return feat;
        });
        source.addFeatures(feats);
      },
    });
    layer.setSource(source);
    layer.setStyle(Styles.digSiteShape());
    layer.setProperties({ LayerName: 'Dig Sites', isDefaultOn: 1 });
    return new ManagedLayer(layer, null);
  }

  getSatelliteLayer(): ManagedLayer {
    const layer = new TileLayer();
    const source = new TileArcGISRest({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
      attributions: [
        'Powered by Esri',
        'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
      ],
      crossOrigin: 'anonymous',
    });
    layer.setSource(source);
    layer.setProperties({
      LayerName: 'Satellite View',
      isDefaultOn: this.userService.isSettingActive(SettingID.SATELLITE_LAYER_ON) ? 1 : -1,
      index: 1,
    });
    return new ManagedLayer(layer, null);
  }

  private async updateLocateAreaLayer(requestNumber: number = undefined) {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { layer: { locateAreas: true, requestNumber } } },
    };
    const response = await this.apiService.invokeUtilocateApi(request);
    const { locateAreaLayer } = response.body.result;
    if (locateAreaLayer && !isEqual(locateAreaLayer, {})) {
      this._locateAreaLayer?.getLayer()?.dispose();
      const newLayer = await this.buildWFSLayer(locateAreaLayer);
      newLayer.set('EsriLayerID', crypto.randomUUID());
      this._locateAreaLayer = new ManagedLayer(newLayer, null);
    }
  }

  private getEsriToken(layerNumber: number) {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { auth: { layerNumber } } },
    };
    return from(this.apiService.invokeUtilocateApi(request)).pipe(
      first(),
      switchMap((apiResponse) => {
        const { Username, Password, EsriTokenGenerationURL } = apiResponse.body.result;
        const formData = new URLSearchParams();
        if (Username === undefined || Password === undefined || EsriTokenGenerationURL === undefined) {
          throw new Error('Esri Token Generation URL, Username, or Password is undefined');
        }

        const formFields = {
          username: Username,
          password: Password,
          referer: 'www.u4ia.cloud',
          // client: 'referer',
          // ip: '',
          // referer:
          // window.location.protocol + '//' + window.location.host + '/' + window.location.pathname.split('/')[1],
          f: 'json',
        };
        for (const [key, value] of Object.entries(formFields)) {
          formData.append(key, value);
        }
        return from(
          fetch(EsriTokenGenerationURL, {
            method: 'POST',
            body: formData,
          })
        );
      }),
      switchMap((response) => from(response.json()))
    );
  }

  private async getGeoserverCredentials() {
    if (this._geoserverCredentials) {
      return this._geoserverCredentials;
    }
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { auth: { geoserver: { group: 0 } } } },
    };
    const response = await this.apiService.invokeUtilocateApi(request);
    return response.body.result;
  }

  public async destroyLayers(layersToDestroy: ManagedLayer[]) {
    const layers: Layer = [];

    function recurse(managedLayer: ManagedLayer) {
      layers.push(managedLayer.getLayer());
      if (managedLayer.getSubLayers()) {
        managedLayer.getSubLayers().forEach(recurse);
      }
    }

    layersToDestroy.forEach(recurse);
    layers.forEach((layer: Layer) => {
      if (layer instanceof VectorLayer && layer.getSource() instanceof VectorSource) {
        layer.getSource().clear();
        layer.getSource().dispose();
      }
      layer.dispose();
    });
    return [];
  }

  // getters & setters

  get layerStreamStart(): Observable<void> {
    return this.layerStreamStart$.pipe();
  }

  get layerStreamEnd(): Observable<void> {
    return this.layerStreamEnd$.pipe();
  }

  get databaseLayerStream(): Observable<DatabaseLayer[]> {
    return this.databaseLayers$.pipe();
  }

  get managedLayers$(): BehaviorSubject<ManagedLayer[]> {
    return this._managedLayers$;
  }

  getDatabaseLayers(): Observable<DatabaseLayer[]> {
    return this.databaseLayers$.pipe();
  }

  get refreshLayersSubject() {
    return this.refreshLayers$;
  }

  get selectedLayer$(): Observable<ManagedLayer> {
    return this._selectedLayer$.pipe();
  }

  get selectedLayer(): ManagedLayer {
    return this._selectedLayer$.value;
  }

  set selectedLayer(layer: ManagedLayer) {
    this._selectedLayer$.next(layer);
  }

  set checkChanges(changes: () => MapFeatureChange<FeatureOrCollection>[]) {
    this._checkChanges = changes;
  }

  get miscLayer() {
    return this._miscLayer$.value;
  }

  get miscLayer$(): Observable<VectorLayer<VectorSource>> {
    return this._miscLayer$.pipe();
  }

  set digSites(digSites: Array<unknown>) {
    this._digSites$$.set(digSites);
  }

  set filters(filters: Partial<LayerQueryFilters>) {
    this._filters$$.set(filters);
  }

  get canvasDrawingLayer() {
    if (this._canvasLayers) {
      return this._canvasLayers[1];
    } else {
      return null;
    }
  }

  get canvasImageLayer() {
    if (this._canvasLayers) {
      return this._canvasLayers[0];
    } else {
      return null;
    }
  }

  public refreshLayers() {
    this.refreshLayers$.next();
  }

  public clearLayerSelection() {
    this._selectedLayer$.next(null);
  }
}

export type LayerData = {
  layers: DatabaseLayer[];
  groups: DatabaseLayerGroup[];
};

export enum SourceType {
  ImageSource,
  TileSource,
  VectorSource,
}

export interface DatabaseLayerGroup {
  EsriLayerGroupID: number;
  GroupName: string;
  GroupID: number;
  UtilityToEsriLayerID: number;
  UtilityID: number;
}

export interface DatabaseLayer {
  EsriLayerID: number;
  LayerName: string;
  LayerURL: string;
  LayerType: number;
  LayerList: string;
  bIsShownInApp: number;
  EsriCredentialID: number;
  EsriServiceID: number;
  bIsEditable: number;
  EsriLayerGroupID: number;
  UtilityID: number;
  UtilityName: string;
  isDefaultOn: number;
  isArchived: number;
  Token?: string;
  RequestParams?: Record<string, any>;
  isLocateAreaLayer?: boolean;
  index?: number;
}

export type DigsiteShape = {
  graphicID: number;
  type: number;
  coordinates: {
    lat: number;
    lng: number;
  }[];
};

export enum LayerType {
  EsriMapServer = 1,
  WMSLayerGeoServer,
  EsriFeatureServer,
  EsriBasemap,
  EsriWFSLayer,
  EsriTileLayer,
  WFSLayer,
  TileLayer,
  ImageLayer,
  GroupLayer,
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}
