import {
  Component,
  ComponentRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { BaseTemplateFormComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { FormInputTemplateComponent } from "src/app/modules/shared/forms/form-input-template/form-input-template.component";
import { CreateTicketComponentService } from "../../../create-ticket-component.service";

@Component({
  selector: "app-create-ticket-details-view",
  templateUrl: "./create-ticket-details-view.component.html",
  styleUrls: ["./create-ticket-details-view.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketDetailsViewComponent
  extends BaseTemplateFormComponent
  implements OnInit, OnChanges
{
  utilityViews = [];
  tempformProps = {};
  commonFormProps = {};
  hasCommon = false;
  utilityFormGroups = {};
  formFactory: ComponentRef<FormInputTemplateComponent>;
  myUtilityViewKeys = {};

  @ViewChild("utilityDetails", { read: ViewContainerRef }) utilityAnchor;
  constructor(
    logger$: LoggerService,
    compMsg$: ComponentMessagingService,
    private viewContainerRef: ViewContainerRef,
    private ticket$: CreateTicketComponentService
  ) {
    super(logger$, compMsg$);
  }

  init() {
    this.className = "CreateTicketDetailsViewComponent";
    this.categoryLinks = [];
    this.myViewName = "details";
    super.init();
  }

  ngOnInit(): void {
    try {
      if (this.props && this.props["messageService"]) {
        this.parentMsgSubscriber = this.props["messageService"]
          .getMessageStream()
          .subscribe((nextMsg: ComponentMessage) => {
            this.logger$.log(this.className + " : ParentListener");
          });
      }
      this.formFactory = this.viewContainerRef.createComponent(
        FormInputTemplateComponent
      );
    } catch (error) {
      this.logger$.error(this.className + " : error" + error.message);
    }
  }

  ngAfterViewInit() {
    this.updateUtilityViews();
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"] &&
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"]
      ) {
        if (
          changes["props"]["currentValue"]["commonViews"] &&
          changes["props"]["currentValue"]["commonViews"].length > 0
        ) {

          if(Object.keys(this.commonFormProps).length !== 0){
               this.hasCommon = true;
          }
          this.commonFormProps = {
            ...this.commonFormProps,
            categoryLinks: [0],
            view: changes.props.currentValue.commonViews[0],
            views: changes.props.currentValue.commonViews,
          };
        }

        this.views = changes["props"]["currentValue"]["views"];
        this.myView =
          changes["props"]["currentValue"]["views"][this.myViewName];

        if (changes["props"]["currentValue"]["utilityViews"]) {
          this.utilityViews = changes["props"]["currentValue"]["utilityViews"];
          this.updateUtilityViews();
        }

        if (
          changes.props.currentValue.prefillFields &&
          changes.props.currentValue.prefillFields.primaryDetailsHideShow
        ) {
          this.updatePrimaryDetailPreFill();
        }

        this.refresh();
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  /**
   *
   * @param event {field, values}
   */
  onDropdownSelected(event) {
    try {
      if (event["field"] && event["field"]["key"]) {
        if (
          event["field"]["key"].toLowerCase().indexOf("utilities") > -1 ||
          event["field"]["key"].toLowerCase().indexOf("calltype") > -1
        ) {
          this.ticket$.updateCompletionsData(event);
        }
      }
    } catch (error) {
      this.logger$.error("onDropdownSelected: " + error.message);
    }
  }

  /**
   *
   * @param event {field, date}
   */
  onDateSelected(event) {
    try {
      if (event["field"]["key"] && event["date"]) {
        super.onDateSelected(event);
        this.ticket$.onDateSelected(event["field"]["key"], event["date"]);
      }
    } catch (error) {
      this.logger$.error("onDropdownSelected: " + error.message);
    }
  }

  //TODO
  //make function to hanlde utility form groups
  //if it does not exist, create/
  //if it does exist, update and trigger changes.
  //if it does exists and it shouldnt, destroy
  // this.formProps = {
  //   messageService:this.myCompMsg,
  //   categoryLinks: this.categoryLinks,
  //   view: this.myView,
  //   views: this.views
  // };
  updateUtilityViews() {
    try {
      if (this.formFactory && this.utilityAnchor) {
        let activeUtilityViews = this.utilityViews["activeViews"];
        let views = this.utilityViews["newViews"];

        if (views && views.length > 0) {
          views.forEach((view) => {
            view["showHeader"] = true;
            if (Object.keys(this.myUtilityViewKeys).includes(view.key)) {
              //means already here so just trigger changes
              let tempProps = {
                messageService: this.myCompMsg,
                categoryLinks: [],
                view: view,
                views: views,
              };
              this.utilityFormGroups[view.key] = view["formGroup"];
              this.myUtilityViewKeys[view.key].instance["props"] = tempProps;
              this.myUtilityViewKeys[view.key].instance.manualChange();
            } else {
              //create the individual
              this.myUtilityViewKeys[view.key] =
                this.utilityAnchor.createComponent(this.formFactory);
              let tempProps = {
                messageService: this.myCompMsg,
                categoryLinks: [],
                view: view,
                views: views,
                TICKET_PROTECTION_MODE: false,
              };
              this.utilityFormGroups[view.key] = view["formGroup"];
              this.myUtilityViewKeys[view.key].instance["props"] = tempProps;
              this.myUtilityViewKeys[view.key].instance.manualChange();
            }
          });
        }

        //remove all unused views
        if (activeUtilityViews && activeUtilityViews.length > 0) {
          if (
            this.myUtilityViewKeys &&
            Object.keys(this.myUtilityViewKeys).length > 0
          ) {
            for (let obj in this.myUtilityViewKeys) {
              if (!activeUtilityViews.includes(obj)) {
                this.myUtilityViewKeys[obj].destroy();
                delete this.myUtilityViewKeys[obj];
                delete this.utilityFormGroups[obj];
              }
            }
          }
        } else {
          if (
            this.myUtilityViewKeys &&
            Object.keys(this.myUtilityViewKeys).length > 0
          ) {
            for (let obj in this.myUtilityViewKeys) {
              this.myUtilityViewKeys[obj].destroy();
              delete this.myUtilityViewKeys[obj];
              delete this.utilityFormGroups[obj];
            }
          }
        }
      } //
    } catch (error) {
      this.logger$.error("updateUtilityViews: " + error.message);
    }
  }

  updatePrimaryDetailPreFill() {
    if (this.utilityViews["newViews"]) {
      let primaryDetailsToShow =
        this.props.prefillFields.primaryDetailsHideShow;
      for (let i in this.utilityViews["newViews"]) {
        let groups = this.utilityViews["newViews"][i].groups;
        for (let key in groups) {
          if (groups[key].fields) {
            let fields = groups[key].fields;
            for (let fieldKey in fields) {
              if (primaryDetailsToShow.indexOf(fieldKey) > -1) {
                fields[fieldKey].isVisible = true;
              }
            }
          }
        }
      }
    }
  }
}
