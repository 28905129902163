import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MapArrayToSelectionOption',
  standalone: true,
})
export class MapArrayToSelectionOptionPipe implements PipeTransform {
  transform(value: any[], nameKey: string, valueKey: string): Array<{ name: string; value: number }> {
    try {
      return value.reduce((acc, x) => {
        if (x[nameKey] !== null && x[nameKey] !== undefined && x[valueKey] !== null && x[valueKey] !== undefined) {
          acc.push({
            name: x[nameKey],
            value: x[valueKey],
          });
        }
        return acc;
      }, []);
    } catch {
      return [];
    }
  }
}
