import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

// custom
import { Observable, Subject } from "rxjs";
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  // possible to have more
  private syncUploadStatuses: Subject<any>;
  private syncDownloadStatuses: Subject<any>;

  constructor(private logger$: LoggerService, private snackBar: MatSnackBar) {
    // create instance
    this.syncUploadStatuses = new Subject<any>();
    this.syncDownloadStatuses = new Subject<any>();
  }

  // ticket-details sync modal methods
  getSyncUpload(): Observable<any> {
    return this.syncUploadStatuses.asObservable();
  }
  setSyncUploadFlat(key, textToDisplay, status) {
    this.syncUploadStatuses.next({
      [key]: {
        status: status,
        textToDisplay: textToDisplay,
      },
    });
  }
  setSyncUpload(event) {
    this.syncUploadStatuses.next(event);
  }

  getSyncDownload(): Observable<any> {
    return this.syncDownloadStatuses.asObservable();
  }
  setSyncDownloadFlat(key, textToDisplay, status) {
    this.syncDownloadStatuses.next({
      [key]: {
        status: status,
        textToDisplay: textToDisplay,
      },
    });
  }
  setSyncDownload(event) {
    this.syncDownloadStatuses.next(event);
  }
}
