import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ColumnComponent,
  ColumnDefinition,
  DownloadType,
  RowComponent,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { distinctUntilChanged, firstValueFrom, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { TemplateColumn } from '../table.module';

@Component({
  selector: 'app-tabulator-table',
  templateUrl: './tabulator-table.component.html',
  styleUrls: ['./tabulator-table.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [ScrollingModule],
})
export class TabulatorTableComponent<A, T extends Record<string, unknown>>
  implements AfterViewInit, OnChanges, OnDestroy
{
  //inputs
  @Input() eagerLoading: boolean = false;
  @Input() tableData: T[] = [];
  @Input() columns: TemplateColumn[] = [];
  @Input() columnFormatterFunctionList: any[];
  @Input() tableOptions: any;
  @Input() rowFormatter: (any) => any; // Input function for row formatter
  @Input() showColumnSearch: boolean = false;
  @Input() showSelect: boolean = false;
  @Input() selectedRows: Array<A> = [];
  @Input() rowIndex: keyof T;
  @Input() searchString: string;
  @Input() searchColumns: { name: string; value: unknown }[] = [];
  @Input() persistSelectedRows: boolean = false;

  //outputs
  @Output() rowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowSingleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnReorderEvent: EventEmitter<any[]> = new EventEmitter<[]>();
  @Output() columnWidthChangeEvent: EventEmitter<{ title: string; width: number }> = new EventEmitter<{
    title: string;
    width: number;
  }>();
  @Output() selectedRowsChanged = new EventEmitter<Array<A>>();

  // observables
  private destroy$ = new Subject<void>();
  private tableBuilt: boolean = false;
  tabulatorColumns: ColumnDefinition[] = [];

  //class variables
  private tableTarget = document.createElement('div');
  table: Tabulator;

  constructor() {}

  ngAfterViewInit() {
    if (this.eagerLoading) {
      this.drawTable();
    }
    this.initTable()
      .then(() => {
        if (!this.eagerLoading) {
          this.drawTable();
        }
      })
      .catch(() => {
        console.error('Error creating table');
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // exit if table is not initialized,
    if (!this.table) {
      return;
    }

    if (changes.columns) {
      this.updateColumns();
    }

    if (changes.tableData) {
      this.updateRowData();
    }

    //update the search
    if (changes.searchString) {
      this.updateFilter();
    }

    //update the selected rows
    if (
      changes.selectedRows &&
      !isEqual(changes.selectedRows.currentValue, changes.selectedRows.previousValue) &&
      !changes.selectedRows.isFirstChange()
    ) {
      this.table.deselectRow(changes.selectedRows.previousValue);
      this.table.selectRow(changes.selectedRows.currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.table.destroy();
    this.table = undefined;
  }

  updateFilter(): void {
    if (!this.table) {
      return;
    }

    if (this.searchString) {
      // Apply filter if search string is not empty
      const filters = [];
      let cols: ColumnComponent[] = this.getColumnsToSearchByName(
        this.tabulatorColumns.map((each) => {
          return { name: each.field };
        })
      );
      if (this.searchColumns?.length > 0 && this.searchColumns[0].name?.toLowerCase() !== 'all') {
        cols = this.getColumnsToSearchByName(this.searchColumns);
      }
      for (const col of cols) {
        filters.push({ field: col.getField(), type: 'like', value: this.searchString });
      }
      this.table.setFilter([filters]);
    } else {
      if (this.table && this.tableBuilt) this.table.clearFilter(true);
    }
  }

  /**
   * Returns a list of ColumnComponent from an input list of {name: string}[] where name
   * matches the name of the column in the table
   *
   * @param {{ name: string }[]} inputCols
   * @return {*}  {ColumnComponent[]}
   * @memberof TabulatorTableComponent
   */
  getColumnsToSearchByName(inputCols: { name: string }[]): ColumnComponent[] {
    const cols: ColumnComponent[] = [];
    for (const col of inputCols) {
      const column = this.table.getColumn(col.name);
      if (column) {
        cols.push(column);
      }
    }
    return cols;
  }

  addFormattersToColumns(cols) {
    for (const col of cols) {
      //only show if you want it to be searchable
      if (this.showColumnSearch) col.headerFilter = 'input';
      //if there is a column formatter function for this column, set it
      if (this.columnFormatterFunctionList && this.columnFormatterFunctionList[col.title])
        col.formatter = this.columnFormatterFunctionList[col.title];
    }
  }

  private setupEvents() {
    //setup events for the table
    this.table.on('rowDblClick', (e: MouseEvent, row: RowComponent) => {
      const isShiftPressed = e.shiftKey;
      if (!isShiftPressed && !e.target['classList'].contains('custom-checkbox-cell')) {
        this.rowDoubleClick.emit(row.getData());
        return;
      }
    });

    this.table.on('rowClick', (e: MouseEvent, row: RowComponent) => {
      // Check if the Shift key is pressed
      const isShiftPressed = e.shiftKey;
      if (!isShiftPressed && !e.target['classList'].contains('custom-checkbox-cell')) {
        this.rowSingleClick.emit(row.getData());
        return;
      }
      this.handleRowSelection(row);
    });

    this.table.on('columnMoved', (_column: ColumnComponent, columns: ColumnComponent[]) => {
      //column - column component of the moved column
      //columns- array of columns in new order
      this.columnReorderEvent.emit(columns);
    });
    this.table.on('columnResized', (column) => {
      //column - column component of the resized column
      this.columnWidthChangeEvent.emit({ title: column.getField(), width: column.getWidth() });
    });

    fromEvent(this.table, 'rowSelectionChanged')
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe(([data]) => {
        if (this.rowIndex) {
          const selected = data.map((x: T) => x[this.rowIndex]);
          if (isEqual(selected, this.selectedRows)) {
            return;
          }
          this.selectedRows = selected;
        } else {
          const rows = [];
          for (const row of data) {
            rows.push({ ...row });
          }
          this.selectedRows = rows;
        }

        this.selectedRowsChanged.emit(this.selectedRows);
      });

    this.table.on('dataChanged', (data) => {
      console.log(data);
    });
  }

  /**
   * Initializes the table
   */
  private initTable(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.table !== undefined) {
        this.table.destroy();
        this.table = undefined;
      }
      this.tabulatorColumns = this.convertColObjectNamesToLowerCase(this.columns);
      this.addFormattersToColumns(this.tabulatorColumns);

      const options = {
        data: this.tableData,
        reactiveData: false,
        columns: this.tabulatorColumns,
        layout: 'fitDataStretch',
        rowHeight: 30,
        // minHeight: '411px',
        height: '100%',
        movableColumns: true,
        placeholder: 'No data to show', //display message to user on empty table
        rowSelected: (row) => {
          // Add the custom selected class
          row.getElement().classList.add('tabulator-selected');
        },
        rowDeselected: (row) => {
          // Remove the custom selected class
          row.getElement().classList.remove('tabulator-selected');
        },
        rowHeader: this.showSelect
          ? {
              headerSort: false,
              resizable: false,
              headerHozAlign: 'center',
              hozAlign: 'center',
              formatter: 'rowSelection',
              titleFormatter: 'rowSelection',
              cssClass: 'custom-checkbox-cell',
              titleFormatterParams: {
                rowRange: 'active', //only toggle the values of the active filtered rows
              },
            }
          : null,
        selectableRows: 'highlight',
        downloadConfig: {
          columnHeaders: true, //include column headers in downloaded table
        },
        rowFormatter: this.rowFormatter,
        index: this.rowIndex,
      };
      //create the table
      this.table = new Tabulator(this.tableTarget, this.tableOptions ?? options);

      // Wait for the table to be built before resolving
      firstValueFrom(fromEvent(this.table, 'tableBuilt'))
        .then(() => {
          this.setupEvents();
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  private handleRowSelection(row: RowComponent) {
    if (row.isSelected()) {
      row.deselect();
    } else {
      row.select();
    }
  }

  private drawTable() {
    document.getElementById('my-tabular-table').appendChild(this.tableTarget);
  }

  private updateRowData(): void {
    // If persistSelectedRows is true, we want to keep the selected rows after updating the table data
    if (this.persistSelectedRows) {
      const selectedIDs = this.table.getSelectedData().map((row) => row[this.rowIndex]);
      this.table.setData(this.tableData).then(() => {
        this.table.selectRow(selectedIDs);
      });
      return;
    }
    this.table.setData(this.tableData);
  }

  private updateColumns(): void {
    this.tabulatorColumns = this.convertColObjectNamesToLowerCase(this.columns);
    this.addFormattersToColumns(this.tabulatorColumns);
    this.setColumns();
  }

  private setColumns(): void {
    this.table.setColumns(this.tabulatorColumns);
  }

  /**
   * Converts the TemplateColumn object to a ColumnDefinition for tabulator tables
   * @param {TemplateColumn[]} columns
   * @returns {ColumnDefinition[]}
   */
  private convertColObjectNamesToLowerCase(columns: TemplateColumn[]): ColumnDefinition[] {
    if (!columns) return [];
    if (columns.length === 0) return [];
    const newCols: ColumnDefinition[] = [];
    for (const col of columns) {
      const newCol: ColumnDefinition = {
        title: col['Title'],
        field: col['Title'],
        visible: col['Visible'] === 1,
      };
      if (col['Width']) newCol.width = col['Width'];
      newCols.push(newCol);
    }
    return newCols;
  }

  /**
   * Downloads the table
   * @param {string} tableNameWithExtension name of the file with extension
   * @param {string} fileType type of the file
   */
  downloadTable(tableNameWithExtension: string = 'data.csv', fileType: DownloadType = 'csv') {
    this.table.download(fileType, tableNameWithExtension, {}, 'active'); // include rows that have passed filtering https://tabulator.info/docs/6.2/download#:~:text=active%20rows%20(rows%20that%20have%20passed%20filtering)%20will%20be%20included%20in%20the%20download
  }

  /**
   * Gets the currently selected rows
   * @returns {any[]}
   */
  getSelectedRows(): A[] {
    return this.table.getSelectedData();
  }
}
