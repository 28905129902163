// required modules
import { Component, OnInit, ViewChild, Input } from "@angular/core";

import { forkJoin } from "rxjs";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketFormTypes } from "src/app/modules/create-ticket/CreateTicketModel";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { CreateTicketService } from "../../services/create-ticket.service";

@Component({
  selector: "app-create-main-panel",
  template:
    '<app-create-map-view #createMapView [props]="mapViewProps"></app-create-map-view>',
  providers: [ComponentMessagingService],
})
export class CreateMainPanelComponent
  extends BasePanelComponent
  implements OnInit
{
  readonly myName: string = "CreateMainPanelComponent";
  @Input() props: any;
  @ViewChild("createMapView", {}) createMapView;

  mapViewProps: any;

  constructor(
    logger$: LoggerService,
    private messaging$: ComponentMessagingService,
    private createTicket$: CreateTicketService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);

    this.mapViewProps = {
      messageService: messaging$,
      name: "createTicketMapView",
    };

    // listen for child messages
    this.messaging$
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        if (nextMsg.message["onFinishedDrawing"]) {
          this.finishDrawing();
        } else if (nextMsg.message["onResetDrawing"]) {
          this.resetDrawing();
        }
      });
  }

  ngOnInit() {
    // listen for messages from parent or panel-siblings

    this.props["messageService"]
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        // filter out self messages
        if (nextMsg.senderID.indexOf(this.myName) < 0) {
          // this.createMapView.ngOnInit();
          this.messaging$.sendToMessageStream({
            ...nextMsg,
            senderID: this.myName,
          });
        }
      });
  }

  finishDrawing() {
    this.progressBarService.start();

    // get data from child view
    this.createMapView.getData().subscribe((data) => {
      if (data) {
        const { centroid, digsiteDetails, addressDetails } = data;

        // update idb with user map data
        forkJoin([
          this.createTicket$.updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_DATA, {
              formType: CreateTicketFormTypes.ticket,
              value: centroid,
            })
          ),
          this.createTicket$.updateTicketData(
            new ActionMessage(Actions.UPDATE_DIGSITE_DETAILS, {
              formType: CreateTicketFormTypes.digsite,
              value: digsiteDetails,
            })
          ),
          this.createTicket$.updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, {
              formType: CreateTicketFormTypes.ticket,
              value: addressDetails,
            })
          ),
        ]).subscribe((successfulSave: boolean[]) => {
          // notify user of result
          if (!successfulSave) {
            this.snackBarService.openSnackbar(
              "Failed to fill information, Enter information manually",
              SnackbarType.error
            );
          } else {
            this.snackBarService.openSnackbar(
              "Saved successfully",
              SnackbarType.success
            );
          }
          this.progressBarService.stop();
        });
      } else {
        this.snackBarService.openSnackbar(
          "Failed to save drawing, Try erasing and redrawing",
          SnackbarType.error
        );
      }
    });
  }

  resetDrawing() {
    this.progressBarService.start();

    // resetting data
    const digsiteDetails = [];
    const ticketDetails = {
      LocateAddress: "",
      LocateSubRegionName: "",
      Latitude: null,
      Longitude: null,
    };

    // reset idb with default data
    forkJoin([
      this.createTicket$.updateTicketData(
        new ActionMessage(Actions.CLEAR_POLYGON_DATA, {
          formType: CreateTicketFormTypes.digsite,
          value: digsiteDetails,
        })
      ),
      this.createTicket$.updateTicketData(
        new ActionMessage(Actions.CLEAR_POLYGON_DATA, {
          formType: CreateTicketFormTypes.ticket,
          value: ticketDetails,
        })
      ),
    ]).subscribe((successfulSave: boolean[]) => {
      if (successfulSave && successfulSave[0] && successfulSave[1]) {
        this.snackBarService.openSnackbar(
          "Successfully reset digsite",
          SnackbarType.success
        );
      } else {
        this.snackBarService.openSnackbar(
          "Failed to reset digsite - try again",
          SnackbarType.error
        );
      }
      this.progressBarService.stop();
    });
  }
}
