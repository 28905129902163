import { Output, SimpleChanges, EventEmitter } from "@angular/core";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { AdminLookupService } from "src/app/modules/core/admin/admin-lookup.service";
import { ADMIN_TABLE_NAMES } from "src/app/modules/core/admin/tables";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { U2_USER_CATEGORY_ID } from "src/app/modules/core/services/user/user";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { ModalService } from "../../../modals/modal.service";
import { EditTimeRangeModalComponent } from "../../../ticket/modals/edit-timerange/edit-timerange.component";
import { TicketDetailsService } from "../../ticket-details.service";
import { TicketTagBuilderService } from "../ticket-tags/ticket-tags-builder.service";

@Component({
  selector: "app-ticket-summary",
  templateUrl: "./ticket-summary.component.html",
  styleUrls: ["./ticket-summary.component.css"],
})
export class TicketSummaryComponent
  extends BasePageComponent
  implements OnInit, OnChanges
{
  className = "TicketSummaryComponent";

  @Input() props;
  @Output() toggleStartEndTime = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  tagProps: any;
  isLocator: boolean = false;

  CALL_TYPE_DESC: any;
  TYPE_OF_WORK: any;
  EXCAVATION_DATE: any;
  WORK_DONE_FOR: any;
  LOCATE_ADDRESS: any;

  CLEAR_TICKET_MODAL_ID: string = "id_clear_ticket_dialog";
  // clear options
  clearOptions: any[];
  formControl = new FormControl("", Validators.required);
  showClearOptions: boolean = false;
  constructor(
    logger$: LoggerService,
    private ticketDetails$: TicketDetailsService,
    private tagsBuilder: TicketTagBuilderService,
    private admin$: AdminLookupService,
    private modalService: ModalService,
    private userService: UserService,
    private datetimeService: DatetimeService,
    public dialog: MatDialog
  ) {
    super(logger$);
  }

  ngOnInit() {
    try {
      this.setupSettings();
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  private async setupSettings() {
    try {
      if (
        await this.userService.isSettingActive(
          SettingID.CLEAR_LSP_DROPDOWN_LIST
        )
      ) {
        this.showClearOptions = true;
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange) {
      if (changes.props.currentValue) {
        if (
          changes.props.currentValue.ticket &&
          changes.props.currentValue.tbCompletions_AssignmentToTags
        ) {
          let tbCompletions_AssignmentToTags =
            changes.props.currentValue.tbCompletions_AssignmentToTags;
          let tbAdmin_TicketTags = await this.admin$.getLookupTables([
            ADMIN_TABLE_NAMES.tbAdmin_TicketTags,
          ]);
          let tags = [
            ...this.tagsBuilder.createTicketTagsFromTable(
              tbCompletions_AssignmentToTags,
              tbAdmin_TicketTags[0]
            ),
          ];

          // update props for children
          this.tagProps = {
            ...this.tagProps,
            tags,
          };

          let {
            TypeOfWork,
            ExcavationDate,
            WorkDoneFor,
            StartHouseNumber,
            LocateAddress,
            LocateSubRegionName,
          } = changes.props.currentValue.ticket;
          this.TYPE_OF_WORK = TypeOfWork;

          this.EXCAVATION_DATE =
            this.datetimeService.dbDateToFormattedLocalDate(ExcavationDate);
          this.WORK_DONE_FOR = WorkDoneFor;
          this.LOCATE_ADDRESS = [
            StartHouseNumber,
            LocateAddress,
            LocateSubRegionName,
          ].join(", ");
        }

        if (changes.props.currentValue.UserCategoryID) {
          this.isLocator =
            changes.props.currentValue.UserCategoryID ==
            U2_USER_CATEGORY_ID.Locator;
          this.setupClearOptions();
        }

        if (changes.props.currentValue.CallTypeDesc != null) {
          this.CALL_TYPE_DESC = changes.props.currentValue.CallTypeDesc;
        }
      }
    }
  }

  async setupClearOptions() {
    let utilityIDs = this.ticketDetails$.getUtilityIDsFromAux(
      this.props.tbCompletions_AuxiliaryDetails,
      this.props.PrimaryID
    );

    this.clearOptions = await this.ticketDetails$.getClearOptions(
      this.props.UserCategoryID,
      utilityIDs
    );
  }

  openDialog(id) {
    this.modalService.open(id);
  }

  closeDialog(id) {
    this.modalService.close(id);
  }

  async clearTicket() {
    let clearTypeID = this.formControl.value;
    this.modalService.close(this.CLEAR_TICKET_MODAL_ID);
    this.clear.emit(clearTypeID);
  }

  onTimeToggle() {
    try {
      let primary = this.props.tbCompletions_Primary[0];
      let cleanTimeIn: Date = new Date(); //= this.datetimeService.dbDateToLocalDate(this.TicketDetailSummary["TimeIn"]);

      let timeIn: Date = primary.TimeIn
        ? this.datetimeService.dbDateToLocalDate(primary.TimeIn)
        : null;
      let timeOut: Date = primary.TimeOut
        ? this.datetimeService.dbDateToLocalDate(primary.TimeOut)
        : null;

      let dialogObservable = this.dialog
        .open(EditTimeRangeModalComponent, {
          width: "380px",
          data: {
            header: "Edit Time In/Out",
            startTime: timeIn,
            endTime: timeOut,
            date: cleanTimeIn,
            datetimeEditor: true,
          },
        })
        .afterClosed();
      let dialogSub = dialogObservable.pipe(take(1)).subscribe((next) => {
        if (next && next["result"]) {
          this.props.tbCompletions_Primary[0].TimeIn = next["result"]["TimeIn"];
          this.props.tbCompletions_Primary[0].TimeOut =
            next["result"]["TimeOut"];
          this.toggleStartEndTime.emit(next);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}
