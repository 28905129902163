import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DrawingID } from '../services/drawing-manager.service';
import { LayerManagerService } from '../services/layer-manager.service';
import { OpenLayersService } from '../services/open-layers.service';
import Feature from 'ol/Feature';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root',
})
export class DrawingCanvasService implements OnDestroy {
  // services
  private mapService = inject(OpenLayersService);
  private layerManager = inject(LayerManagerService);

  // members
  private layers: [ImageLayer<Static>, VectorLayer<VectorSource>] = null;

  // observables
  private _activeDrawingID$ = new BehaviorSubject<DrawingID>(null);
  private destroy$: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupDrawing(img: HTMLImageElement, drawing: Feature[]) {
    this.mapService.canvasExtent = [0, 0, img.naturalWidth, img.naturalHeight];
    this.layers = this.layerManager.setupCanvasLayers(img);
    this.mapService.layers = this.layers;
    this.layers[1].getSource().addFeatures(drawing);
    this.mapService.useCanvasView();
    this.layerManager.selectedLayer = null;
  }

  tidy() {
    this._activeDrawingID$.next(null);
  }

  get drawing(): Feature[] {
    return this.layers[1].getSource().getFeatures();
  }

  set activeDrawingID(val: DrawingID) {
    this._activeDrawingID$.next(val);
  }
}
