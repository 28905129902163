import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HeaderOptions,
  RouteOptions,
} from "../../shared/page-navigation/page-nav-options";
import { RateType } from "../rate-type";

@Component({
  selector: "app-rate-list",
  templateUrl: "./rate-list.component.html",
  styleUrls: ["./rate-list.component.scss"],
})
export class RateListComponent implements OnInit {
  rateTypes: RateType[] = [
    {
      RateTypeID: 1,
      RateTypeName: "Billing",
    },
    {
      RateTypeID: 2,
      RateTypeName: "Incentive",
    },
  ];

  routes: RouteOptions[] = [];
  header: HeaderOptions;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    let newQueryParams = this.cleanURLQueryParams();
    this.router.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });

    this.header = {
      title: "Rates",
    };

    this.setupSideNav();
  }

  /**
   * if visible url keys isn't empty, update params
   * @returns updated query paramters
   */
  private cleanURLQueryParams(): object {
    let queryParams: any = this.route.snapshot.queryParams;
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};
    try {
      let visisbleURLKeys: any =
        this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      let len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        let queryParamsKey = queryParamsKeys[i];
        if (
          visisbleURLKeys &&
          visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
        ) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }

      return newQueryParams;
    } catch (error) {
      console.error(error);
    }
    return newQueryParams;
  }

  setupSideNav() {
    this.routes = [];
    for (let rateType of this.rateTypes) {
      this.routes.push({
        path: rateType.RateTypeID.toString(),
        label: rateType.RateTypeName,
      });
    }
  }
}
