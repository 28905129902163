import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppNavComponent } from "./app-nav/app-nav.component";

import { AppRoutingModule } from "src/app/app-routing.module";
import { NavigationBarModule } from "../shared/navigation-bar/navigation-bar.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SideNavigationModule } from "../shared/side-navigation/side-navigation.module";
import { UserManagerModule } from "../user-manager/user-manager.module";
import { MatSidenavModule } from "@angular/material/sidenav";
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SchedulerModule } from '../scheduler/scheduler.component';

@NgModule({
  declarations: [AppNavComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    NavigationBarModule,
    SideNavigationModule,
    // SchedulerModule,
    UserManagerModule,
    MatSidenavModule,
  ],
})
export class AppNavModule {}
