import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { MarkerObject } from "../../google-map/google-maps-set/googleMapsOptions";
import { GoogleMapsOptions } from "../models/google-maps/google-maps";

@Component({
  selector: "app-u4-google-maps-basic",
  template: `
    <google-map
      height="100%"
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="googleMapsOptions"
      (tilesloaded)="onMapReady()"
    >
      <map-marker
        *ngFor="let marker of markers"
        [position]="marker.position"
        [options]="markerOptions"
      ></map-marker>
    </google-map>
  `,
})
export class U4GoogleMapsDrawingComponent implements OnInit {
  @Input() googleMapsOptions: GoogleMapsOptions;
  @Input() markers: MarkerObject;
  @Output() mapReadyEvent = new EventEmitter<any>();
  @ViewChild(GoogleMap, { static: true }) map: GoogleMap;

  readonly markerOptions = {
    animation: google.maps.Animation.DROP,
  };

  zoom: number;
  center: google.maps.LatLngLiteral;

  constructor() {}

  ngOnInit() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        (error) => {
          console.log(error);
          this.center = {
            lat: 43.533778,
            lng: -80.305128,
          };
        },
        { timeout: 3000 }
      );
    }
  }

  onMapReady() {
    this.mapReadyEvent.emit(true);
  }
}

@NgModule({
  declarations: [U4GoogleMapsDrawingComponent],
  imports: [CommonModule, GoogleMapsModule],
  exports: [U4GoogleMapsDrawingComponent],
  providers: [],
})
export class U4GoogleMapsDrawingModule {}
