import { Component, Inject, NgModule, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketComponentService } from "src/app/modules/create-ticket/create-ticket-component.service";
import { CreateTicketComponentModule } from "src/app/modules/create-ticket/create-ticket/create-ticket.component";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { TicketDetailsService } from "src/app/modules/shared/ticket-details/ticket-details.service";
import { SchedulerOptionsDialogComponent } from "../scheduler-options-dialog/scheduler-options-dialog.component";

@Component({
  selector: "app-scheduler-create-ticket-dialog",
  templateUrl: "./scheduler-create-ticket-dialog.component.html",
  styleUrls: ["./scheduler-create-ticket-dialog.component.css"],
})
export class SchedulerCreateTicketDialogComponent implements OnInit {
  views = {};
  createTicketOptions = {
    isMinified: false,
    prefillFields: {},
    documents: [],
  };

  childProps = {};

  assignmentID;
  primaryID;

  constructor(
    public dialogRef: MatDialogRef<SchedulerOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private createTicket$: CreateTicketComponentService,
    private ticketDetailsService$: TicketDetailsService
  ) {
    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      createTicketOptions: this.createTicketOptions,
    };

    var resource = data.resource;
    var startDate = data.startDate;

    this.assignmentID = data.assignmentID;
    this.primaryID = data.primaryID;

    this.createTicketOptions.prefillFields["AvailableRigs"] = [
      { value: resource["ResourceID"] },
    ];

    this.createTicketOptions.prefillFields["OriginalExcavationDate"] =
      startDate;
    this.createTicketOptions.prefillFields["EventStartDateTime"] = startDate;
    this.createTicketOptions.prefillFields["EventEndDateTime"] = startDate;
  }

  async setupCreateTicketSettings() {
    try {
      await this.createTicket$.setupCreateTicketSettings();
    } catch (error) {
      console.error(error.message);
    }
    return true;
  }

  ngOnInit(): void {
    try {
      //get the stuff needed already.
      this.setupCreateTicketSettings()
        .then((val) => {
          if (this.assignmentID && this.primaryID) {
            return this.ticketDetailsService$.downloadTicket(
              this.assignmentID,
              this.primaryID
            );
          } else {
            //run to set defaults?
            return true;
          }
        })
        .then((result) => {
          // console.log(result);

          if (
            result["tbCompletions_Assignments"] &&
            result["tbCompletions_AuxiliaryDetails"]
          ) {
            let assignmentsRows =
              result["tbCompletions_Assignments"]["Data"][0];
            let keys = Object.keys(assignmentsRows);
            keys.forEach((key) => {
              if (key == "CallTypeID") {
                this.createTicketOptions.prefillFields["calltype"] = {
                  value: assignmentsRows[key],
                };
              } else if (key == "RequestNumber") {
                console.log("ignore", key);
              } else {
                this.createTicketOptions.prefillFields[key] =
                  assignmentsRows[key];
              }
            });

            this.createTicketOptions.prefillFields["UpdateOf"] =
              assignmentsRows["RequestNumber"];

            let auxRows = result["tbCompletions_AuxiliaryDetails"]["Data"];
            let utilityIDs = [];
            auxRows.forEach((auxRow) => {
              utilityIDs.push({ value: auxRow["UtilityID"] });
            });

            this.createTicketOptions.prefillFields["utilities"] = utilityIDs;
          }
          if (
            result.tbCompletions_PrimaryDetails &&
            result.tbCompletions_PrimaryDetails.Data &&
            result.tbCompletions_PrimaryDetails.Data.length > 0
          ) {
            let preFillHideShow = [];
            // console.log(result['tbCompletions_PrimaryDetails']);
            result.tbCompletions_PrimaryDetails.Data.forEach((row) => {
              if (row["isUpdated"] == 1) {
                preFillHideShow.push(String(row.PrimaryDetailsFieldID));
              }
            });
            this.createTicketOptions.prefillFields["primaryDetailsHideShow"] =
              preFillHideShow;
          }

          if (
            result.tbCompletions_Documents &&
            result.tbCompletions_Documents.Data &&
            result.tbCompletions_Documents.Data.length > 0
          ) {
            this.createTicketOptions.documents =
              result.tbCompletions_Documents.Data;
          }

          return this.createTicket$.getCreateTicketTemplate().toPromise();
        })
        .then((msg) => {
          if (msg) {
            this.childProps["views"] = msg["views"];
            this.childProps["commonViews"] = msg["commonViews"];
            this.childProps = { ...this.childProps };
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      this.logger$.log("ngOnInit1" + error);
    }
  }

  onClose() {
    if (window.confirm("Are you sure you want to close ticket creation?")) {
      this.dialogRef.close(false);
    }
  }

  onSyncFinished(synced) {
    if (synced) {
      this.dialogRef.close(true);
    }
  }
}
