import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionInformationComponent } from './version-information/version-information.component';
import { TableModule } from "../shared/table/table.module";
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    VersionInformationComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    MatTableModule,
    MatIconModule
  ],
  exports: [VersionInformationComponent]
})
export class VersionInformationModule { }