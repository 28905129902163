import { Component, OnInit } from "@angular/core";
import { LoggerService } from "../../core/services/logger/logger.service";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";

@Component({
  selector: "app-pdf-acknowledgement",
  templateUrl: "./pdf-acknowledgement.component.html",
  styleUrls: ["./pdf-acknowledgement.component.scss"],
})
export class PdfAcknowledgementComponent {
  className: string = "PDFAcknowledgementPageComponent";
  panelLoaded: boolean = false;

  constructor(
    // error$: MainErrorService,
    logger$: LoggerService,
    // messaging$: ComponentMessagingService,
    // alert$: NotificationService,
    private progressBarService: ProgressBarService
  ) {
    progressBarService.start();
  }
  /**
   * update this.panelLoaded
   */
  onContentLoad() {
    this.panelLoaded = true;
  }
}
