<div class="page-container-full flex flex-row">
  <!-- Quick panel -->
  <div fxHide.lt-sm="{{ showContentPanel }}">
    <app-create-ticket-quick-panel
      class="w-full"
      fxHide="{{ !showQuickPanel }}"
      [props]="childProps"
      (createTicketReady)="onSync()"
    ></app-create-ticket-quick-panel>
    <div
      *ngIf="isMinified"
      fxHide="{{ showQuickPanel }}"
      class="full-container"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <mat-toolbar color="primary" class="mat-elevation-z6" style="z-index: 4; min-height: 48px">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" style="height: 48px">
          <button mat-icon-button (click)="togglePanel('close')">
            <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
      <app-create-ticket-content-panel
        class="full-container"
        [props]="childProps"
        (toggleCreateBtnWhileLoading)="onCreateBtnWhileLoading($event)"
      ></app-create-ticket-content-panel>
    </div>
  </div>

  <!-- Desktop and iPad view -->
  <div *ngIf="!isMinified" fxFlex="100%" fxShow="true" fxHide.lt-md="{{ !showContentPanel }}">
    <div class="full-container" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-toolbar
        fxHide="true"
        fxShow.lt-md="true"
        color="primary"
        class="mat-elevation-z6"
        style="z-index: 4; min-height: 48px"
      >
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" style="height: 48px">
          <button mat-icon-button (click)="togglePanel('close')">
            <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
      <app-create-ticket-content-panel
        class="full-container"
        [props]="childProps"
        (updateAddress)="updateAddress($event)"
        (toggleCreateBtnWhileLoading)="onCreateBtnWhileLoading($event)"
      ></app-create-ticket-content-panel>
    </div>
  </div>
</div>
