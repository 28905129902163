<form [formGroup]="form">
  <mat-form-field
    *ngFor="let input of inputs"
    class="create-ticket-input-field"
  >
    <input
      matInput
      placeholder="{{ input.placeholder }}"
      formControlName="{{ input.dbName }}"
      required="{{ input.isRequired }}"
    />
  </mat-form-field>

  <mat-form-field
    *ngFor="let dropdown of dropdowns"
    class="create-ticket-input-field"
  >
    <mat-select
      placeholder="{{ dropdown.placeholder }}"
      formControlName="{{ dropdown.dbName }}"
      required="{{ dropdown.isRequired }}"
      matTooltip="{{ dropdown.placeholder }}"
      matTooltipPosition="above"
      multiple
    >
      <mat-option
        *ngFor="let option of dropdown['options']"
        [value]="option['value']"
        matTooltip="{{ option.text }}"
        matTooltipPosition="right"
        >{{ option.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngFor="let datepicker of datepickers"
    class="create-ticket-input-field"
  >
    <input
      matInput
      formControlName="{{ datepicker.dbName }}"
      placeholder="{{ datepicker.placeholder }}"
      required="{{ datepicker.isRequired }}"
      [matDatepicker]="ticketDatePicker"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="ticketDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #ticketDatePicker
      [disabled]="form.disabled"
    ></mat-datepicker>
  </mat-form-field>
</form>
