import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { SnackbarType } from '../../../snackbar/snackbar/snackbar';
import { AutologExplanationModalComponent } from '../autolog-explaination-modal/autolog-explanation-modal.component';

@Component({
  selector: 'app-gallery-modal',
  styleUrls: ['gallery-modal.component.scss'],
  templateUrl: './gallery-modal.component.html',
})
export class CameraGalleryModalComponent implements OnInit {
  imagesTaken: GalleryItem[];
  indicesToRemove: any[];
  imagesToRemove: GalleryItem[];
  galleryView: GalleryModelViews.SMALL;
  photoLimit: number;

  GalleryViewMap = {
    0: 'small',
    1: 'full',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CameraGalleryModalComponent>,
    private gallery: Gallery,
    private lightbox: Lightbox,
    private snackBarService: SnackbarService,
    private dialog: MatDialog
  ) {
    this.imagesTaken = data.images.map(
      (item) => new ImageItem({ src: item.imageAsDataUrl, thumb: item.imageAsDataUrl })
    );
    this.imagesToRemove = [];
    this.indicesToRemove = [];
    this.galleryView = data.galleryView ? data.galleryView : GalleryModelViews.SMALL;
    this.photoLimit = data.photoLimit;
  }

  ngOnInit() {
    if (this.photoLimit > 0 && this.imagesTaken.length >= this.photoLimit) {
      this.snackBarService.openSnackbar('Photo limit reached', SnackbarType.warning, 'OK');
    }
  }

  onFinish() {
    const preFilter = this.imagesTaken.length;
    this.imagesTaken = this.imagesTaken.filter((x) => !this.imagesToRemove.includes(x));
    const postFilter = this.imagesTaken.length;

    const msg = 'Saving ' + postFilter + '/' + preFilter + ' images';
    this.snackBarService.openSnackbar(msg, SnackbarType.success);

    this.dialogRef.close({
      result: this.imagesTaken,
    });
  }

  toggleImageClick(event) {
    event.preventDefault();
    const id = event.target.id.toString();
    if (this.indicesToRemove.includes(id)) {
      this.indicesToRemove.splice(this.indicesToRemove.indexOf(id), 1);
      this.imagesToRemove.splice(this.imagesToRemove.indexOf(this.imagesTaken[id]), 1);
      document.getElementById(id).style.opacity = '1';
      // document.getElementById("delete-icon-"+id).style.visibility = "hidden"; comment out because html element is commented
    } else {
      //the ID looked to be img-0, img-1, img-2, etc. but we need to index imagesTaken with an integer.
      let formatID = id.split('-')[1];
      formatID = formatID ?? id;
      formatID = parseInt(formatID);
      this.indicesToRemove.push(id);
      this.imagesToRemove.push(this.imagesTaken[formatID]);
      document.getElementById(id).style.opacity = '0.5';
      // document.getElementById("delete-icon-"+id).style.visibility = "visible"; //ERROR HERE Style is not defined.
    }
  }

  openCaptionDialog(event) {
    this.dialog
      .open(AutologExplanationModalComponent, {
        width: '380px',
        data: { header: 'Add a caption', placeholder: 'Enter a caption', value: event.caption, maxLength: 60 },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (closeValue) => {
        if (closeValue && closeValue.result) {
          event.caption = closeValue.result;
        }
      });
  }
}

// const data = {images: [
//   {
//     imageAsDataUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
//   },
//   {
//     imageAsDataUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
//   },
//   {
//     imageAsDataUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
//   },
//   {
//     imageAsDataUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
//   }
// ]};

export enum GalleryModelViews {
  SMALL,
  FULL,
  LIST,
}
