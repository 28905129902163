import {
  Component,
  HostListener,
  Inject,
  NgModule,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl } from "@angular/forms";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { TicketDetailsLibModule } from "src/app/modules/shared/ticket-details/ticket-details/ticket-details.component";
// import { TicketDetailsLibModule } from 'src/app/modules/shared/ticket-details/ticket-details/ticket-details.component';

@Component({
  selector: "app-scheduler-ticket-dialog",
  templateUrl: "./scheduler-ticket-dialog.component.html",
  styleUrls: ["./scheduler-ticket-dialog.component.css"],
})
export class SchedulerTicketDialogComponent {
  @ViewChild("appTicketDetails") appTicketDetails;

  form: FormGroup;
  getTicketData: Function;
  assignmentID: number;

  ticketData: any;
  childProps = {};

  ticketDetailValuesChanged: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SchedulerTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.childProps = {
      messageService: false,
      AssignmentID: data.ticketData.AssignmentID,
      isModal: true,
    };
    // this.getTicketData = data.getTicketData;
    // this.assignmentID = data.AssignmentID;
    this.ticketData = data.ticketData;
    // this.form = new FormGroup(
    //   {
    //     requestNumber: new FormControl(''),
    //     address: new FormControl(''),
    //     excavationData: new FormControl(''),
    //     callDate: new FormControl(''),
    //     // callType: new FormControl(''),
    //     locateStatus: new FormControl(''),
    //     comments: new FormControl(''),
    //   }
    // );
    // this.loadTicket();
  }

  async onModalClose() {
    this.dialogRef.close();
  }

  close(): void {}

  onSyncFinished(synced) {
    this.ticketDetailValuesChanged = false;
  }

  formGroupChanged(event) {
    this.ticketDetailValuesChanged = true;
  }

  loadTicket() {
    this.form.patchValue({
      requestNumber: this.ticketData["RequestNumber"],
      address: this.ticketData["Address"],
      excavationData: new Date(this.ticketData["ExcavationDate"]),
      callDate: new Date(this.ticketData["CallDate"]),
      // callType: new Date(this.ticketData['CallTypeID']),
      locateStatus: this.ticketData["LocateStatusDesc"],
      comments: this.ticketData["Comments"],
    });
  }
}

@NgModule({
  declarations: [SchedulerTicketDialogComponent],
  imports: [TicketDetailsLibModule, MaterialModule, FlexLayoutModule],
  exports: [SchedulerTicketDialogComponent],
  providers: [ComponentMessagingService],
})
export class SchedulerTicketDialogModule {}
