import {
  Component,
  ElementRef,
  Inject,
  inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { from, Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";

@Component({
  selector: "app-camera-modal",
  styleUrls: ["./camera-modal.component.scss"],
  templateUrl: "./camera-modal.component.html",
})
export class CameraModalComponent {
  @ViewChild("flash", { static: false }) input: ElementRef;

  isCameraExist = true;
  videoOptions: MediaTrackConstraints = {
    facingMode: { ideal: "environment" },
    height: {
      min: 480,
      ideal: 720,
    },
  };

  errors: WebcamInitError[] = [];

  private trigger: Subject<void>;
  private nextWebcam: Subject<boolean | string>;
  private callerOnPictureSubject: Subject<any>;
  photoLimit = -1;

  width: number;
  height: number;
  state: string;
  count: number;
  active: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<CameraModalComponent>,
    private snackbarService: SnackbarService
  ) {
    this.trigger = new Subject<void>();
    this.nextWebcam = new Subject<boolean | string>();
    this.callerOnPictureSubject = _data["newPhotoSubject"];
    this.photoLimit = _data["photoLimit"] ? _data["photoLimit"] : -1;
    this.width = window.innerWidth;
    this.height =  window.innerHeight;
    this.state = "rotated";
    this.count = 0;
    this.active = false;
  }

  rotate() {
    this.state = this.state === "default" ? "rotated" : "default";
  }

  onTakePicture(): void {
    this.active === true;

    setTimeout(() => {
      this.active === false;
    }, 100);
    this.count++;
    this.trigger.next();
  }

  onFinish() {
    this.dialogRef.close();
  }

  handleInitError(error: WebcamInitError) {
    if (error.message && error.message.length > 0) {
      this.snackbarService.openSnackbar(error.message, SnackbarType.error);
    } else {
      this.snackbarService.openSnackbar(
        "Error: Unable to open camera",
        SnackbarType.error
      );
    }
    this.errors.push(error);
  }

  handleImage(webcamImage: WebcamImage) {
    this.callerOnPictureSubject.next(webcamImage);
    if (this.photoLimit > 0 && this.count >= this.photoLimit) {
      this.dialogRef.close(); //finish photo taking if met or surpassed limit.
    }
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
