import {METERS_PER_UNIT as $5OpyM$METERS_PER_UNIT} from "ol/proj/Units.js";
import $5OpyM$olstyleStylejs from "ol/style/Style.js";
import $5OpyM$olstyleStrokejs from "ol/style/Stroke.js";
import $5OpyM$olstyleFilljs from "ol/style/Fill.js";
import $5OpyM$olstyleCirclejs from "ol/style/Circle.js";
import $5OpyM$olstyleIconjs from "ol/style/Icon.js";
import $5OpyM$olstyleTextjs from "ol/style/Text.js";
import {toRadians as $5OpyM$toRadians} from "ol/math.js";










const $5a67534a0415f909$export$eed06ece7a8e469d = async (styleData)=>{
    if (!styleData.icon) styleData.icon = null;
    if (!styleData.font) styleData.font = null;
    if (!styleData.fill) styleData.fill = null;
    if (!styleData.stroke) styleData.stroke = null;
    if (!styleData.circle) styleData.circle = null;
    const fill = styleData.fill ? new (0, $5OpyM$olstyleFilljs)(styleData.fill) : null, stroke = styleData.stroke ? new (0, $5OpyM$olstyleStrokejs)(styleData.stroke) : null, fontSymbol = styleData.font ? $5a67534a0415f909$export$a3432c36f8652b6d(styleData.font) : null;
    let image = null;
    if (styleData.icon) {
        const img = await $5a67534a0415f909$var$loadImage(styleData.icon.src);
        const imgSize = [
            img.width,
            img.height
        ];
        const scale = styleData.icon.size[0] / img.width;
        const rotation = (0, $5OpyM$toRadians)(styleData.icon.rotation || 0);
        image = new (0, $5OpyM$olstyleIconjs)(Object.assign({}, {
            img: img,
            imgSize: imgSize,
            scale: scale,
            rotation: rotation
        }));
    }
    if (styleData.circle) {
        const radius = styleData.circle.radius;
        const circleFill = styleData.circle.fill ? new (0, $5OpyM$olstyleFilljs)(styleData.circle.fill) : null;
        const circleStroke = styleData.circle.stroke ? new (0, $5OpyM$olstyleStrokejs)(styleData.circle.stroke) : null;
        image = new (0, $5OpyM$olstyleCirclejs)({
            radius: radius,
            fill: circleFill,
            stroke: circleStroke
        });
    }
    return new (0, $5OpyM$olstyleStylejs)({
        stroke: stroke,
        fill: fill,
        image: image,
        text: fontSymbol
    });
};
const $5a67534a0415f909$export$a3432c36f8652b6d = (labelData)=>{
    const rotation = (0, $5OpyM$toRadians)(labelData.rotation || 0);
    const fill = labelData.fill ? new (0, $5OpyM$olstyleFilljs)(labelData.fill) : null;
    const stroke = labelData.stroke ? new (0, $5OpyM$olstyleStrokejs)(labelData.stroke) : null;
    const backgroundFill = labelData.backgroundFill ? new (0, $5OpyM$olstyleFilljs)(labelData.backgroundFill) : null;
    const backgroundStroke = labelData.backgroundStroke ? new (0, $5OpyM$olstyleStrokejs)(labelData.backgroundStroke) : null;
    // text will be a template - can include: $id, {ATTRIBUTE_NAME}...
    // before the feature is drawn on the map it will be formatted based of feature attribute values
    return new (0, $5OpyM$olstyleTextjs)(Object.assign({}, labelData, {
        rotation: rotation,
        fill: fill,
        stroke: stroke,
        backgroundFill: backgroundFill,
        backgroundStroke: backgroundStroke
    }));
};
const $5a67534a0415f909$var$loadImage = (imgSrc)=>{
    return new Promise((resolve, reject)=>{
        const image = new Image();
        image.onload = ()=>resolve(image);
        image.src = imgSrc;
    });
};


/**
 * Formats the label of a feature. The label can contain $id, {ATTRIBUTE_NAME}...
 * @param {!import('ol/Feature').default} feature
 * @param {!String} mask
 * @return {String}
 */ const $b26ee7291d7e5be0$export$73d65be4271316b7 = (feature, mask)=>{
    if (mask.includes("$id")) mask = mask.replace("$id", feature.getId().toString());
    if (mask.includes("{")) return $b26ee7291d7e5be0$export$992c430bd39b93b4(mask, feature.getProperties());
    else return mask;
};
const $b26ee7291d7e5be0$export$992c430bd39b93b4 = function(mask, object, removeLeftovers = true) {
    let result = mask;
    for(let name in object){
        const regEx = new RegExp("\\{" + name + "\\}", "gm");
        result = result.replace(regEx, object[name]);
    }
    if (removeLeftovers) {
        const regex = new RegExp("{([a-zA-Z]*?)}");
        let match = null;
        //remove any '{someText}' strings left within the returned string
        while((match = regex.exec(result)) !== null)result = result.replace(regex, "");
    }
    return result;
};


/**
 * // https://developers.arcgis.com/documentation/common-data-types/symbol-objects.htm
 * // https://developers.arcgis.com/javascript/latest/api-reference/esri-symbols-SimpleLineSymbol.html#style
 */ const $cf838c15c8b009ba$var$lineDashPattern = {
    esriSLSDash: [
        10
    ],
    esriSLSDashDot: [
        10,
        10,
        1,
        10
    ],
    esriSLSDot: [
        1,
        10,
        1,
        10
    ],
    esriSLSDashDotDot: [
        10,
        10,
        1,
        10,
        1,
        10
    ],
    esriSLSSolid: []
};
const $cf838c15c8b009ba$export$c56e6f6ad0707742 = async (layerUrl, mapProjection)=>{
    const response = await fetch(`${layerUrl}?f=json`);
    const esriStyleDefinition = await response.json();
    return await $cf838c15c8b009ba$export$bb06ddfd5d66e02e(esriStyleDefinition, mapProjection);
};
const $cf838c15c8b009ba$export$bb06ddfd5d66e02e = async (esriLayerInfoJson, mapProjection)=>{
    let { featureStyles: featureStyles, labelStyles: labelStyles } = $cf838c15c8b009ba$export$ab7b36f75c2ecef2(esriLayerInfoJson.drawingInfo);
    for(let i = 0; i < featureStyles.length; i++)featureStyles[i].style = await (0, $5a67534a0415f909$export$eed06ece7a8e469d)(featureStyles[i]);
    for(let i = 0; i < labelStyles.length; i++){
        labelStyles[i].maxResolution = $cf838c15c8b009ba$var$getMapResolutionFromScale(labelStyles[i].maxScale || Infinity, mapProjection);
        labelStyles[i].minResolution = $cf838c15c8b009ba$var$getMapResolutionFromScale(labelStyles[i].minScale || 1, mapProjection);
        labelStyles[i].label = labelStyles[i].text;
        labelStyles[i].style = new (0, $5OpyM$olstyleStylejs)({
            text: (0, $5a67534a0415f909$export$a3432c36f8652b6d)(labelStyles[i])
        });
    }
    return (feature, resolution)=>{
        let styles = [];
        const featureStyle = featureStyles.find(({ filters: filters })=>{
            if (filters) return filters.every(({ field: field, value: value, operator: operator })=>{
                const currentValue = feature.get(field) || "";
                switch(operator){
                    case "in":
                        const valuesIn = value.split(",").map((value)=>value.toString());
                        return valuesIn.indexOf(currentValue.toString()) > -1;
                    case "between":
                        return value.lowerBound <= currentValue && currentValue <= value.upperBound;
                    default:
                        throw "Invalid operator " + operator;
                }
            });
            else // will return the first style (default one)
            return true;
        });
        if (featureStyle) styles.push(featureStyle.style);
        const labelStyle = labelStyles.find((label)=>{
            return label.maxResolution >= resolution && resolution >= label.minResolution;
        });
        if (labelStyle && labelStyle.style) {
            if (labelStyle.hasExpression) labelStyle.style.getText().setText($cf838c15c8b009ba$var$getLabelFromFeature(feature, esriLayerInfoJson));
            else {
                const text = (0, $b26ee7291d7e5be0$export$73d65be4271316b7)(feature, labelStyle.label);
                labelStyle.style.getText().setText(text);
            }
            styles.push(labelStyle.style);
        }
        // push labels!
        return styles.length > 0 ? styles : null;
    };
};
function $cf838c15c8b009ba$var$getLabelFromFeature(feature, layerDescriptor) {
    try {
        // Ensure we have a valid feature and layer descriptor
        if (!feature || !layerDescriptor) return "";
        const labelingInfo = layerDescriptor?.drawingInfo?.labelingInfo;
        if (!labelingInfo || labelingInfo.length <= 0) return "";
        const labelExpressionInfo = labelingInfo[0].labelExpressionInfo;
        if (!labelExpressionInfo) return "";
        // Parse the expression
        const expressionParts = labelExpressionInfo.expression.split(".");
        if (expressionParts.length !== 2 || expressionParts[0] !== "$feature") return "";
        const propertyName = expressionParts[1];
        // Get the property value from the feature
        const properties = feature.getProperties();
        if (propertyName in properties) return properties[propertyName].toString();
        else return "";
    } catch  {
        return "";
    }
}
const $cf838c15c8b009ba$export$ab7b36f75c2ecef2 = ({ renderer: renderer, labelingInfo: labelingInfo })=>{
    if (!renderer) throw "renderer is not defined";
    /**
     * @type {Array<import('./types').StyleType>}
     */ let featureStyles = [];
    /**
     * @type {Array<import('./types').LabelType>}
     */ let labelStyles = labelingInfo ? $cf838c15c8b009ba$export$c53a6129b3ed3ca2(labelingInfo) : [];
    switch(renderer.type){
        case "simple":
            featureStyles.push($cf838c15c8b009ba$export$3db12bac5969e4a4(renderer.symbol));
            break;
        case "uniqueValue":
            const uniqueFieldValues = $cf838c15c8b009ba$export$b9f02502a872e7d9(renderer.uniqueValueInfos, renderer.fieldDelimiter);
            for(let i = 0; i < uniqueFieldValues.length; i++){
                const uniqueField = uniqueFieldValues[i];
                /**
                 * @type {Array<import('./types').FilterType>}
                 */ let filters = [];
                if (renderer.field1) filters.push({
                    field: renderer.field1,
                    operator: "in",
                    value: uniqueField.field1Values
                });
                if (renderer.field2) filters.push({
                    field: renderer.field2,
                    operator: "in",
                    value: uniqueField.field2Values
                });
                if (renderer.field3) filters.push({
                    field: renderer.field3,
                    operator: "in",
                    value: uniqueField.field3Values
                });
                const style = $cf838c15c8b009ba$export$3db12bac5969e4a4(uniqueField.symbol);
                featureStyles.push({
                    filters: filters,
                    title: uniqueField.title,
                    ...style
                });
            }
            if (renderer.defaultSymbol) featureStyles.push($cf838c15c8b009ba$export$3db12bac5969e4a4(renderer.defaultSymbol));
            break;
        case "classBreaks":
            const classBreakField = renderer.field;
            const classBreakMinValue = renderer.minValue;
            const classBreakInfos = renderer.classBreakInfos;
            for(let i = 0; i < classBreakInfos.length; ++i){
                const classBreakInfo = classBreakInfos[i];
                const style = $cf838c15c8b009ba$export$3db12bac5969e4a4(classBreakInfo.symbol);
                /**
                 * @type {Array<import('./types').FilterType>}
                 */ const filters = [
                    {
                        field: classBreakField,
                        operator: "between",
                        value: {
                            lowerBound: classBreakInfo.hasOwnProperty("classMinValue") ? classBreakInfo.classMinValue : classBreakMinValue,
                            upperBound: classBreakInfo.classMaxValue
                        }
                    }
                ];
                featureStyles.push({
                    filters: filters,
                    ...style
                });
            }
            if (renderer.defaultSymbol) featureStyles.push($cf838c15c8b009ba$export$3db12bac5969e4a4(renderer.defaultSymbol));
            break;
        default:
            throw `"Renderer type "${renderer.type}" is not implemented yet`;
    }
    return {
        featureStyles: featureStyles,
        labelStyles: labelStyles
    };
};
const $cf838c15c8b009ba$export$c53a6129b3ed3ca2 = (labelingInfo)=>{
    return labelingInfo.map((labelDefinition)=>{
        let labelStyle = $cf838c15c8b009ba$export$3db12bac5969e4a4(labelDefinition.symbol);
        labelStyle.maxScale = labelDefinition.minScale || 1000;
        labelStyle.minScale = labelDefinition.maxScale || 0;
        labelStyle.hasExpression = !!labelDefinition.labelExpressionInfo;
        if (!labelStyle.hasExpression) labelStyle.text = (labelDefinition.labelExpression || "").replace(/\[/g, "{").replace(/]/g, "}").replace(/ CONCAT  NEWLINE  CONCAT /g, "\n").replace(/ CONCAT /g, " ");
        return labelStyle;
    });
};
const $cf838c15c8b009ba$export$3db12bac5969e4a4 = (symbol)=>{
    switch(symbol.type){
        case "esriSMS":
            return {
                circle: {
                    radius: symbol.size,
                    fill: symbol.color ? {
                        color: `rgba(${symbol.color.join(",")})`
                    } : null,
                    stroke: symbol.outline ? {
                        color: `rgba(${symbol.outline.color.join(",")})`,
                        width: symbol.outline.width
                    } : null
                }
            };
        case "esriSLS":
            return {
                stroke: {
                    color: `rgba(${symbol.color.join(",")})`,
                    width: symbol.width,
                    lineDash: $cf838c15c8b009ba$var$lineDashPattern[symbol.style]
                }
            };
        case "esriSFS":
            let style = symbol.outline ? $cf838c15c8b009ba$export$3db12bac5969e4a4(symbol.outline) : {};
            style.fill = {
                color: `rgba(${symbol.color.join(",")})`
            };
            return style;
        case "esriPMS":
            return {
                icon: {
                    src: `data:image/png;base64,${symbol.imageData}`,
                    size: [
                        symbol.width * 1.333,
                        symbol.height * 1.333
                    ],
                    rotation: symbol.angle
                }
            };
        case "esriTS":
            return {
                text: symbol.text,
                font: symbol.font ? `${symbol.font.style} ${symbol.font.weight} ${symbol.font.size}pt ${symbol.font.family}` : "20px Calibri,sans-serif",
                offsetX: symbol.xoffset,
                offsetY: symbol.yoffset,
                textAlign: symbol.horizontalAlignment,
                textBaseline: symbol.verticalAlignment,
                padding: [
                    5,
                    5,
                    5,
                    5
                ],
                angle: symbol.angle,
                fill: symbol.color ? {
                    color: `rgba(${symbol.color.join(",")})`
                } : null,
                stroke: symbol.haloColor ? {
                    color: `rgba(${symbol.haloColor.join(",")}`,
                    width: symbol.haloSize ? symbol.haloSize : null
                } : null,
                backgroundFill: symbol.backgroundColor ? {
                    fill: {
                        color: `rgba(${symbol.backgroundColor.join(",")})`
                    }
                } : null,
                backgroundStroke: symbol.borderLineColor ? {
                    stroke: {
                        color: `rgba(${symbol.borderLineColor.join(",")})`,
                        width: symbol.borderLineSize || null
                    }
                } : null
            };
        default:
            throw `Symbol type "${symbol.type}" is not implemented yet`;
    }
};
const $cf838c15c8b009ba$export$b9f02502a872e7d9 = (styles, delimiter)=>{
    let uniqueSymbols = new Map();
    styles.forEach((s)=>{
        if (!uniqueSymbols.has(s.label)) uniqueSymbols.set(s.label, s.symbol);
    });
    let result = [];
    uniqueSymbols.forEach((symbol, label)=>{
        const uniqueStyles = styles.filter((s)=>{
            return s.label === label;
        });
        let field1Values = new Set();
        let field2Values = new Set();
        let field3Values = new Set();
        uniqueStyles.forEach((s)=>{
            field1Values.add(s.value.split(delimiter)[0]);
            field2Values.add(s.value.split(delimiter)[1]);
            field3Values.add(s.value.split(delimiter)[2]);
        });
        result.push({
            title: label,
            symbol: symbol,
            field1Values: [
                ...field1Values
            ].join(),
            field2Values: [
                ...field2Values
            ].join(),
            field3Values: [
                ...field3Values
            ].join()
        });
    });
    return result;
};
/**
 * @param {!Number} scale
 * @param {import('ol/proj/Projection')} projection
 * @return {Number}
 */ const $cf838c15c8b009ba$var$getMapResolutionFromScale = (scale, mapProjection)=>{
    const mpu = mapProjection ? (0, $5OpyM$METERS_PER_UNIT)[mapProjection.getUnits()] : 1;
    return scale / (mpu * 39.37 * (25.4 / 0.28));
};


export {$cf838c15c8b009ba$export$c56e6f6ad0707742 as createStyleFunctionFromUrl, $cf838c15c8b009ba$export$bb06ddfd5d66e02e as createStyleFunction, $cf838c15c8b009ba$export$ab7b36f75c2ecef2 as readEsriStyleDefinitions, $cf838c15c8b009ba$export$c53a6129b3ed3ca2 as readLabels, $cf838c15c8b009ba$export$3db12bac5969e4a4 as readSymbol, $cf838c15c8b009ba$export$b9f02502a872e7d9 as filterUniqueValues};

