<div class="page-container-full" fxLayout="column">
  <app-esri-map-simple-drawing
    #esriDrawing
    fxFlex="100"
    [graphics]="graphics"
    [mapOptions]="mapOptions"
    [layerOptions]="layerOptions"
  >
  </app-esri-map-simple-drawing>

  <div
    fxHide
    [fxShow.lt-md]="!esriDrawing.layersOpen && !esriDrawing.legendOpen"
    fxFlex.lt-md="56px"
    class="bottom-bar"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    (click)="toggleBottomMenu()"
  >
    <mat-icon>expand_less</mat-icon>
    <span class="mat-subheading-s">Map Options</span>
  </div>
</div>

<!-- Bottom Sheet -->
<div
  class="bottom-sheet"
  *ngIf="bottomMenuOpen && !esriDrawing.layersOpen && !esriDrawing.legendOpen"
>
  <div
    class="bottom-bar"
    style="height: 56px"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    (click)="toggleBottomMenu()"
  >
    <mat-icon class="arrow-icon">expand_more</mat-icon>
    <span class="mat-subheading-s">Map Options</span>
  </div>

  <div fxLayout="column" class="bottom-sheet-menu">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleLayers()"
    >
      <mat-icon>layers</mat-icon>
      <span class="mat-subheading-xs blue">Layers</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleLegend()"
    >
      <mat-icon>list</mat-icon>
      <span class="mat-subheading-xs blue">Legend</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleIdentify()"
    >
      <mat-icon><img class="identifyIcon" id="identifyLayerBlue" /></mat-icon>
      <span class="mat-subheading-xs blue">Identify</span>
    </div>
  </div>
</div>

<!-- Toolbar -->
<div class="mapToolbar" fxHide.lt-md="true">
  <mat-button-toggle-group
    multiple
    aria-label="Google Map Drawing Tools"
    variant="contained"
  >
    <mat-button-toggle
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Layers"
      aria-label="Open Layers"
      (click)="toggleLayers()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>layers</mat-icon>
        <span class="label">Layers</span>
      </div>
    </mat-button-toggle>

    <mat-button-toggle
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Legend"
      aria-label="Open Legend"
      (click)="toggleLegend()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>list</mat-icon>
        <span class="label">Legend</span>
      </div>
    </mat-button-toggle>

    <mat-button-toggle
      mat-flat-button
      color="primary"
      class="tool-toggle btn bg-primary text-white"
      matTooltip="Identify"
      aria-label="Identify"
      (click)="toggleIdentify()"
    >
    <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon svgIcon="ESRI_IDENTIFY"></mat-icon>
      <span class="label">Identify</span>
    </div>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
