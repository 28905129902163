import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { TicketDetailsService } from "../../ticket-details/ticket-details.service";
import { TicketTag, TicketTagBuilderService } from "./ticket-tags-builder.service";
import { TicketService } from "../../ticket/ticket.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-ticket-list-tags",
  templateUrl: "./ticket-list-tags.component.html",
  styleUrls: ["./ticket-list-tags.component.scss"],
})
export class TicketListTagsComponent implements OnInit, OnChanges, OnDestroy {
  private ticketTagsShowLimit: number = 2;
  @Input() AssignmentID;
  @Input() LocateStatusID;
  @Input() CallTypeID;

  @Input() Tags: TicketTag[];
  @Input() showAll: boolean;
  tags: TicketTag[];
  remainingTags: number = 0;
  private tagChangeSubscription: Subscription;

  constructor(private ticketService: TicketService, private ticketTagsService: TicketTagBuilderService
  ) {}

  ngOnInit() {
    this.tagChangeSubscription = this.ticketTagsService.tagChanged.subscribe(data => {
      this.handleTagChange();
    });
  }

  async ngOnChanges() {    
    await this.getTags()
  }
  
  async getTags(){
    if (this.AssignmentID) {
      this.tags = await this.ticketService.getTicketTags(
        this.AssignmentID.toString(),
        this.LocateStatusID
      );
    }

    if (this.Tags) {
      this.tags = this.Tags;
    }
    
    if (this.showAll == false && this.tags && this.tags.length > 0) {
      if (this.tags.length > this.ticketTagsShowLimit) {
        this.remainingTags = this.tags.length - this.ticketTagsShowLimit;
        this.tags = this.tags.slice(0, this.ticketTagsShowLimit);
      }
    }
  }

   async handleTagChange() {    
     await this.getTags()
  }
  
  ngOnDestroy() {
    if (this.tagChangeSubscription) {
      this.tagChangeSubscription.unsubscribe();
    }
  }

}
