import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-system-maintenance-home",
  templateUrl: "./system-maintenance-home.component.html",
  styleUrls: ["./system-maintenance-home.component.scss"],
})
export class SystemMaintenanceHomeComponent {
  constructor() {}
}
