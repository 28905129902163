<div class="audit-body">
<h2 class="title font-rajdhani mt-[-20px]" mat-dialog-title>Create Ticket</h2>
<span class="header font-rajdhani">Type:</span>
<div class="type-drop" mat-dialog-content>
  <mat-form-field>
    <mat-select [(ngModel)]="selectedType" (selectionChange)="getFilteredUtilitesTypes()">
      <mat-option
        *ngFor="let type of data.type"
        [value]="type"
        >{{ type.CallTypeDesc }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<span class="header font-rajdhani">Task:</span>
<div class="type-drop" mat-dialog-content>
  <mat-form-field>
    <mat-select [(ngModel)]="selectedTask">
      <mat-option
        *ngFor="let task of utilityArray"
        [value]="task"
        >{{ task.UtilityName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="!data.showRequestField">
    <span class="disclaimer font-rajdhani"><br>*A sync wil be triggered when you create a ticket</span>
  </ng-container>
</div>
<ng-container *ngIf="data.showRequestField">
<span class="header font-rajdhani">Request Number:</span>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput type="number" [formControl]="requestNumberFormControl" placeholder="Ex. 123456">
    <mat-error class="error-text font-rajdhani" *ngIf="requestNumberFormControl.hasError('required')">
      Request number <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <span class="disclaimer font-rajdhani"><br>*A sync wil be triggered when you create a ticket</span>
</div>
</ng-container>
<div class="buttons" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="font-rajdhani font-semibold">Cancel</button>
  <button mat-flat-button class="font-rajdhani font-semibold" (click)="onConfirm($event)" color="primary">
    Confirm
  </button>
</div>
</div>