<div>
  <h3 mat-dialog-title style="margin: 0px">{{ data.title }}</h3>
  <mat-dialog-content>
    <ng-container *ngIf="hasFields">
      <p>Fields</p>
      <div
        fxLayout="column wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="16px grid"
        class="dialog-container"
      >
        <ng-container *ngFor="let input of data.fields; let i = index">
          <mat-checkbox
            fxFlex="25"
            [id]="i"
            [checked]="input.isVisible"
            (change)="onFieldCheckChange($event)"
          >
            {{ input.label }}
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="hasCategories">
      <p>Categories</p>
      <div
        fxLayout="column wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="16px grid"
        class="dialog-container"
      >
        <ng-container *ngFor="let category of data.categories | keyvalue">
          <mat-checkbox
            fxFlex="25"
            [id]="category.key"
            [checked]="false"
            (change)="onCategoryCheckChange($event)"
          >
            {{ category.value.CategoryName }}
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </mat-dialog-content>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button color="primary" mat-raised-button [mat-dialog-close]="data">
      Done
    </button>
  </div>
</div>
