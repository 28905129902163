import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-security-troubleshoot",
  templateUrl: "./security-troubleshoot.component.html",
  styleUrls: ["./security-troubleshoot.component.scss"],
})
export class SecurityTroubleshootComponent {
  englishTroubleshootDocPath =
    "/assets/pdf/SignatureAccessTroubleshootManual.pdf";
  frenchTroubleshootDocPath =
    "/assets/pdf/Signature Access Troubleshoot Manual-FRENCH.pdf";

  //TODO clear cache
  constructor() {}
}
