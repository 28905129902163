import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { PadOptions } from "./padOptions";
import SignaturePad from "signature_pad";

@Component({
  selector: "app-signature-pad",
  templateUrl: "./signature-pad.component.html",
  styleUrls: ["./signature-pad.component.scss"],
})
export class SignaturePadComponent implements AfterViewInit {
  @Input() props: any;
  className = "SignaturePadComponent";
  @ViewChild("signatureCanvas") canvasRef: ElementRef;
  @Input() padOptions: PadOptions;

  signaturePad: SignaturePad;
  signatureValue: string = "";

  defaultPadOptions: PadOptions = {
    backgroundColor: "rgba(255,255,255,1)",
    penColor: "rgba(0,0,0,1)",
  };

  constructor() {}

  /**
   * setup padoptions and clear the pad
   */
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(
      this.canvasRef.nativeElement,
      this.padOptions ?? this.defaultPadOptions,
    );
    window.addEventListener("resize", () =>
      this._resizeCanvas(this.canvasRef.nativeElement, this.signaturePad),
    );
    this._resizeCanvas(this.canvasRef.nativeElement, this.signaturePad);
    this.signaturePad.clear();
  }

  /**
   * clear the current signature
   */
  clearPad() {
    this.signaturePad.clear();
  }

  /**
   * @returns signaturePad.toData()
   */
  getRawData() {
    if (!this.signaturePad.isEmpty()) {
      return this.signaturePad.toData();
    } else {
      return "";
    }
  }

  /**
   * get dataUrl for specific image types
   * @param type type of image data desired
   * @returns dataUrl from signaturePad
   */
  getDataURL(type: string) {
    if (!this.signaturePad.isEmpty()) {
      type = type.toLocaleLowerCase();
      var result = "";
      switch (type) {
        case "png":
          result = this.signaturePad.toDataURL();
          break;
        case "jpeg":
          result = this.signaturePad.toDataURL("image/jpeg");
          break;
        case "svg":
          result = this.signaturePad.toDataURL("image/svg+xml");
          break;
        default:
          result = this.signaturePad.toDataURL();
          break;
      }
      return result;
    } else {
      return "";
    }
  }

  private _resizeCanvas(canvas: HTMLCanvasElement, signaturePad: SignaturePad) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  public resizeCanvas() {
    const canvas = this.canvasRef.nativeElement;
    this._resizeCanvas(canvas, this.signaturePad);
  }
}
