<div class="panel-container view-content-background-color">
  <mat-tab-group
    mat-stretch-tabs
    [backgroundColor]="navBackground"
    (selectedIndexChange)="indexChange($event)"
    [selectedIndex]="tabIndex"
    style="height: 100%"
  >
    <mat-tab *ngIf="tabs.includes('companyInfo')">
    <ng-template mat-tab-label class="border-solid border border-black">
      <div class="w-11 h-8 text-blue flex justify-center items-center"
        [ngClass]="{'bg-primary rounded-md text-white': tabIndex === 0, 'text-[#414042]': tabIndex !== 0}">
        <mat-icon svgIcon="search" />
      </div>
    </ng-template>
      <!-- <ng-template matTabContent> -->
      <app-create-ticket-company-view
        [props]="childProps"
      ></app-create-ticket-company-view>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab *ngIf="tabs.includes('worksite')">
      <ng-template mat-tab-label class="border-solid border border-black">
        <div class="w-11 h-8 text-blue flex justify-center items-center"
          [ngClass]="{'bg-primary rounded-md text-white': tabIndex === 1, 'text-[#414042]': tabIndex !== 1}">
          <mat-icon svgIcon="map" />
        </div>
      </ng-template>
      <!-- <ng-template matTabContent> -->
      <app-create-ticket-worksite-view
        [props]="childProps"
        (updateAddressFieldInPanel)="updateAddressFieldInPanel($event)"
      ></app-create-ticket-worksite-view>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab *ngIf="tabs.includes('details')" >
      <ng-template mat-tab-label class="border-solid border border-black">
        <div class="w-11 h-8 text-blue flex justify-center items-center"
          [ngClass]="{'bg-primary rounded-md text-white': tabIndex === 2, 'text-[#414042]': tabIndex !== 2}">
          <mat-icon svgIcon="info" />
        </div>
      </ng-template>
      <!-- <ng-template matTabContent> -->
      <app-create-ticket-details-view
        [props]="childProps"
      ></app-create-ticket-details-view>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab *ngIf="tabs.includes('docs')" >
      <ng-template mat-tab-label class="border-solid border border-black">
        <div class="w-11 h-8 text-blue flex justify-center items-center"
          [ngClass]="{'bg-primary rounded-md text-white': tabIndex === 3, 'text-[#414042]': tabIndex !== 3}">
          <mat-icon svgIcon="report" />
        </div>
      </ng-template>
      <!-- <ng-template matTabContent> -->
      <app-create-ticket-docs-view
        [props]="childProps"
        (toggleCreateBtnWhileLoading)="onCreateBtnWhileLoading($event)"
      ></app-create-ticket-docs-view>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab *ngIf="tabs.includes('schedule')" label="SCHEDULE">
      <ng-template mat-tab-label class="border-solid border border-black">
        <div class="w-11 h-8 text-blue flex justify-center items-center"
          [ngClass]="{'bg-primary rounded-md text-white': tabIndex === 4, 'text-[#414042]': tabIndex !== 4}">
          <mat-icon svgIcon="calendar" />
        </div>
      </ng-template>
      <!-- <ng-template matTabContent> -->
      <app-create-ticket-schedule-view
        [props]="childProps"
      ></app-create-ticket-schedule-view>
      Schedule
      <!-- </ng-template> -->
    </mat-tab>
  </mat-tab-group>
</div>

<!-- if Minimized then have this? -->
<!-- it will actually go back to summary screen? -->
<!-- <div
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    style="height: 100%"
    >
    <div fxFlex="calc(100% - 56px)">
      <mat-tab-group
      mat-stretch-tabs
      [backgroundColor]="navBackground"
      (selectedIndexChange)="indexChange($event)"
      [selectedIndex]="tabIndex"
      >
      <div fxFlex="56px">
        <button class="full-button-footer" (click)="goNext()" mat-flat-button color="warn">
          Continue
        </button>
      </div>
    </div>
  </div> -->
