import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { CacheService, StoreType } from 'src/app/modules/core/cache/cache.service';
import { ConfirmationModalComponent } from '../../modals/generic-confirmation-modal/confirmation-modal.component';
import { TicketService } from '../../ticket.service';
import { Subscription } from 'rxjs';
import { TicketDocumentsService } from '../ticket-documents.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { AutologExplanationModalComponent } from '../../modals/autolog-explaination-modal/autolog-explanation-modal.component';

export interface DocumentListItem {
  DocumentID: string;
  FileName: string;
  DocumentTypeID: string;
  CreationDate: string;
  isSendable: boolean;
  isS3Document: boolean;
  S3DocumentID?: string;
  isDownloaded?: boolean;
  bFieldAdded?: string;
  selected?: boolean;
  Caption?: string;
}

@Component({
  selector: "app-ticket-documents-item",
  templateUrl: "ticket-documents-item.component.html",
  styleUrls: ["./ticket-documents-item.component.scss"],
})
export class TicketDocumentListItemComponent implements OnInit {
  @Input() DocumentID: string;
  @Input() FileName: string;
  @Input() DocumentTypeID: string;
  @Input() CreationDate: string;
  @Input() isSendable: any;
  @Input() isDownloaded: boolean;
  @Input() AssignmentID: string;
  @Input() isS3Document: boolean;
  @Input() bFieldAdded: string;
  @Input() File: any;
  @Input() Selected: boolean;
  @Input() Caption: string;


  @Output() documentSelect = new EventEmitter<any>();
  @Output() documentDelete = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() markSendable = new EventEmitter<any>();
  @Output() captionChange = new EventEmitter<any>();



  // @HostListener("click") onClick() {
  //   this.documentSelect.emit(this.DocumentID);
  // }

  formattedDate: any;
  sendable: any = 'No'
  selectMode: boolean = false
  holdTimer: any;
  isHeldDown: boolean = false;
  deletableDocs: any
  allowDelete: any
  private heldDownSubscription: Subscription;

  constructor(
    private datetimeService: DatetimeService,
    public dialog: MatDialog,
    private idb: CacheService,
    private ticketDocumentService: TicketDocumentsService,
    private userService: UserService,
    private ticketService: TicketService
  ) { }

  ngOnInit(): void {
    this.formattedDate = this.datetimeService.dbDateToFormattedLocalDate(
      this.CreationDate
    );

    if (this.isSendable == 1) {
      this.sendable = 'Yes'
    }
    this.deletableDocs = this.userService.getSettingValue(SettingID.DELETE_DOCUMENTS);
    this.allowDelete = this.userService.isSettingActive(SettingID.DELETE_DOCUMENTS);
    if (this.allowDelete) {
      const docTypesAllowedToDelete = this.userService.getSettingValue(SettingID.DELETE_DOCUMENTS);
      if (docTypesAllowedToDelete.length > 0) {
        if (docTypesAllowedToDelete.indexOf(this.DocumentTypeID.toString()) < 0) {
          this.allowDelete = false;
        }
      }
    }

    this.heldDownSubscription = this.ticketDocumentService.isHeldDown$.subscribe(isHeldDown => {
      this.isHeldDown = isHeldDown;
    });

  }

  documentSelected() {
    this.documentSelect.emit({ FileName: this.FileName, DocumentID: this.DocumentID, isS3Document: this.isS3Document, file: this.File, Caption: this.Caption });
  }

  async markedSendable() {
    this.markSendable.emit({ FileName: this.FileName, DocumentID: this.DocumentID, isS3Document: this.isS3Document, isSendable: this.isSendable, bFieldAdded: this.bFieldAdded });
  }

  editCaption() {
    this.dialog
    .open(AutologExplanationModalComponent, {
      width: '380px',
      data: { header: 'Edit Caption', placeholder: 'Enter a caption', value: this.Caption, maxLength: 60 },
      disableClose: true,
    })
    .afterClosed()
    .subscribe(async (nextVal) => {
      if (nextVal) {
        this.Caption = nextVal.result;
        this.captionChange.emit({ AssignmentID: this.AssignmentID, DocumentID: this.DocumentID, isS3Document: this.isS3Document, Caption: this.Caption });
      }
    });
  }

  onMouseDown() {
    this.holdTimer = setTimeout(() => {
      this.multiSelect();
    }, 2000);
  }

  onMouseUp() {
    clearTimeout(this.holdTimer);
  }

  isPDF(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.pdf');
  }

  isDBFile(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.dbf') || fileName.toLowerCase().endsWith('.geojson') ;
  }



  multiSelect() {
    this.ticketDocumentService.setIsHeldDown(true)
  }

  selectedMode() {
    this.selectMode = !this.selectMode
    if (!this.Selected) {
      console.log("add");
      this.ticketDocumentService.addSelected(this.DocumentID, this.isS3Document, true)
      this.rowSelected.emit({ FileName: this.FileName, DocumentID: this.DocumentID, isS3Document: this.isS3Document, isSendable: this.isSendable, DocumentTypeID: this.DocumentTypeID, isDownloaded: this.isDownloaded, bFieldAdded: this.bFieldAdded, Removed: false })
      return
    }
    console.log("remove");

    this.ticketDocumentService.addSelected(this.DocumentID, this.isS3Document, false)
    this.rowSelected.emit({ FileName: this.FileName, DocumentID: this.DocumentID, isS3Document: this.isS3Document, isSendable: this.isSendable, DocumentTypeID: this.DocumentTypeID, isDownloaded: this.isDownloaded, bFieldAdded: this.bFieldAdded, Removed: true })
  }

  /**
 * Deletes the document from S3
 */
  deleteUploadedDoc() {
    let modalData: object;
    const tableName = this.DocumentID;
    //get the store type. This is the "database number"
    const storeType: StoreType = this.isS3Document ? StoreType.S3DOCUMENTS : StoreType.DOCUMENT;
    modalData = {
      header: "Delete Document",
      message: "Are you sure you want to delete this document?",
    };

    this.dialog
      .open(ConfirmationModalComponent, {
        width: "380px",
        data: modalData,
      })
      .afterClosed()
      .subscribe(async (nextVal) => {
        if (nextVal) {
          this.documentDelete.emit({ FileName: this.FileName, DocumentID: this.DocumentID, isS3Document: this.isS3Document, bFieldAdded: this.bFieldAdded, isDownloaded: this.isDownloaded });
        }
      });
  }


  ngOnDestroy() {
    if (this.heldDownSubscription) {
      this.heldDownSubscription.unsubscribe();
    }
  }

}
