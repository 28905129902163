<div class="punch-clock">
  <div class="clock-drop">
    <span class="top-label" *ngIf="!showDrop">Task Started At</span>
    <div class="clock-container">
      <div class="time">{{ timer }}</div>
    </div>
    <div *ngIf="showDrop">
      <mat-form-field appearance="outline">
        <mat-label class="font-rajdhani uppercase">Select task</mat-label>
        <mat-select [(value)]="selectedOption">
          <mat-option
            (click)="taskCompleted()"
            *ngFor="let task of tasks"
            [value]="task"
            >{{ task }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <span class="task-label" *ngIf="!showDrop">{{ selectedOption }}</span>
  </div>
  <div class="buttons">
    <button
      mat-flat-button
      [disabled]="!selectedOption"
      [color]="buttonColor"
      class="start-btn"
      (click)="toggleTime()"
    >
      <mat-icon>access_alarm</mat-icon>
      {{ buttonText }}
    </button>

    <button
      mat-stroked-button
      [disabled]="!selectedOption"
      color="primary"
      class="switch-btn"
      (click)="showDrop = !showDrop"
    >
      <mat-icon>edit</mat-icon>
      SWITCH TASK
    </button>
  </div>
</div>
