<div style="width: 100%; height: 100%" fxLayout="row">
  <div class="page" fxFlex style="width: 100%; height: 100%; overflow: auto">
    <div fxLayout="column" fxLayoutGap="0px" style="height: 100%">
      <!--made fxLayoutGap 0px for UI changes-->
        <div class="utility-select">
          <div class="max-w-[66%]" fxLayout="row" fxLayoutAlign="flex-start center">
            <span class="text-headline-5 font-rajdhani blue mr-12 w-40 font-semibold uppercase">Edit Rate</span>
            <form class="w-full" style="margin-right: 17.25px;">
              <mat-form-field id="utilityAutocomplete" appearance="outline">
                <mat-label>Utility</mat-label>
                <input
                  type="text"
                  placeholder="Search Utility List..."
                  matInput
                  [formControl]="myControl"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="utilitySelected($event)"
                  [displayWith]="getOptionText"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option.text }}
                  </mat-option>
                </mat-autocomplete>
                <button
                  *ngIf="myControl.value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="utilityCleared()"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </form>
          </div>
        </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="0px"
        style="height: 100%"
      >
        <mat-tree
          fxFlex="66"
          class="example-tree"
          [dataSource]="nestedDataSource"
          [treeControl]="nestedTreeControl"
        >
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <div [ngClass]="{
              'utility-node-container': node.ParentNodeID === 0,
              'parents-nodes-container': node.ParentNodeID !== 0
              }" fxFlex="fill">
              <!--Added an extra div to containerize-->
              <div
                class="mat-tree-node competers-tree-node"
                [ngClass]="{
                  selectedNode: selectedNode
                    ? node.NodeID == selectedNode.NodeID
                    : false
                }"
              >
                <button mat-icon-button disabled>
                  <mat-icon> </mat-icon>
                </button>
                <app-rate-node
                  style="width: 100%"
                  [nodeID]="node.NodeID"
                  [parentNodeID]="node.ParentNodeID"
                  [criteriaTypeID]="node.CriteriaTypeID"
                  [values]="node.Values"
                  [icon]="node.icon"
                  [readableValues]="node.readableValues"
                  [criteriaName]="node.criteriaName"
                  [rate]="node.Rate"
                  [RateName]="node.RateName"
                  [childCriteriaTypeID]="node.ChildCriteriaTypeID"
                  [children]="node.Children"
                  [nodeTrace]="node.nodeTrace"
                  (selected)="nodeSelected($event)"
                  (delete)="nodeDeleted($event)"
                  (copyEmitter)="nodeCopied($event)"
                  (pasteEmitter)="nodePasted($event)"
                  (add)="nodeAdded($event)"
                >
                </app-rate-node>
              </div>
            </div>
          </mat-tree-node>
          <mat-nested-tree-node
          *matTreeNodeDef="let node; when: hasNestedChild"
          >
            <div [ngClass]="{
            'utility-node-container': node.ParentNodeID === 0,
            'nested-nodes-container': node.ParentNodeID !== 0
            }">
              <div
                class="mat-tree-node competers-tree-node"
                [ngClass]="{
                  selectedNode: selectedNode
                    ? node.NodeID == selectedNode.NodeID
                    : false
                }"
              >
                <button mat-icon-button matTreeNodeToggle>
                  <mat-icon>
                    {{
                      nestedTreeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                  </mat-icon>
                </button>
                <app-rate-node
                  style="width: 100%"
                  [nodeID]="node.NodeID"
                  [parentNodeID]="node.ParentNodeID"
                  [criteriaTypeID]="node.CriteriaTypeID"
                  [values]="node.Values"
                  [icon]="node.icon"
                  [readableValues]="node.readableValues"
                  [criteriaName]="node.criteriaName"
                  [rate]="node.Rate"
                  [RateName]="node.RateName"
                  [childCriteriaTypeID]="node.ChildCriteriaTypeID"
                  [children]="node.Children"
                  [nodeTrace]="node.nodeTrace"
                  (selected)="nodeSelected($event)"
                  (delete)="nodeDeleted($event)"
                  (copyEmitter)="nodeCopied($event)"
                  (pasteEmitter)="nodePasted($event)"
                  (add)="nodeAdded($event)"
                >
                </app-rate-node>
              </div>

              <div
                class="nested-node"
                [class.example-tree-invisible]="
                  !nestedTreeControl.isExpanded(node)
                "
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
        <div fxFlex="34" style="height: 100%">
          <app-rate-node-editor
            *ngIf="selectedNode"
            [nodeID]="selectedNode.NodeID"
            [parentNodeID]="selectedNode.ParentNodeID"
            [nodeCriteriaTypeID]="selectedNode.CriteriaTypeID"
            [childCriteriaTypeID]="selectedNode.ChildCriteriaTypeID"
            [nodeValues]="selectedNode.Values"
            [nodeRate]="selectedNode.Rate"
            [nodeRateName]="selectedNode.RateName"
            [criterias]="criterias"
            [children]="selectedNode.Children"
            [nodeTrace]="selectedNode.nodeTrace"
            [siblingValues]="selectedNode.siblingValues"
            (saveNode)="nodeSaved($event)"
            (cancelEdit)="cancelEdit()"
          >
          </app-rate-node-editor>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Paste Modal -->
<app-base-modal #userDialog [id]="PASTE_MODAL_ID" maxwidth="25%">
  <ng-container class="competers-modal-content">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="16px"
    >
      <div fxLayout="column" fxLayoutGap="16px" style="width: 100%">
        <span class="mat-subtitle-2">Copy Utility</span>
        <span
          >Copying
          <strong>{{
            this.copiedNode && this.copiedNode.readableValues
              ? this.copiedNode.readableValues[0]
              : ""
          }}</strong>
          to
          <strong>{{
            this.selectedNode && this.selectedNode.readableValues
              ? this.selectedNode.readableValues[0]
              : ""
          }}</strong></span
        >
        <span>Are you sure you want to continue?</span>
      </div>

      <!-- Bottom Buttons -->
      <div
        class="btn-section"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button class="blue" mat-flat-button (click)="onPasteCancelClick()">
          Cancel
        </button>
        <button
          disable-on-submit
          mat-flat-button
          color="primary"
          (click)="onPasteConfirmClick()"
        >
          Confirm
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>
