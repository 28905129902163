<!-- class={{theme}} -->
<div *ngIf="!viewOnly">
  <div id="searchBackground">
    <input type="text" placeholder="Search Address" id="searchAddressBar" />

    <button mat-icon-button id="searchAddressBarSearchBtn">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<!-- <button color="primary" mat-primary mat-fab [matMenuTriggerFor]="menu">
  <mat-icon>menu</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item [disabled]="viewOnly" (click)="onResetDrawing()">
    <mat-icon>delete</mat-icon>
    <span>Reset Digsites</span>
  </button>
  <button mat-menu-item [disabled]="finishedDrawingDisabled || viewOnly" (click)="onFinishedDrawing()">
    <mat-icon>check</mat-icon>
    <span>Confirm Drawing</span>
  </button>
</mat-menu> -->

<div *ngIf="!viewOnly" class="drawingToolbar">
  <mat-button-toggle-group
    aria-label="Google Map Drawing Tools"
    variant="contained"
  >
    <mat-button-toggle
      checked
      class="tool-toggle"
      (click)="onPanTool()"
      matTooltip="Pan Tool"
      aria-label="Pan Tool"
      color="primary"
    >
      <mat-icon><img id="handIcon" /></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      class="tool-toggle"
      (click)="onLineTool()"
      matTooltip="Line Tool"
      aria-label="Line Tool"
    >
      <mat-icon><img id="lineIcon" /></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      class="tool-toggle"
      (click)="onPolygonTool()"
      matTooltip="Polygon Tool"
      aria-label="Polygon Tool"
    >
      <mat-icon><img id="polyIcon" /></mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      (click)="onCircleTool()"
      matTooltip="Radius Tool"
      aria-label="Radius Tool"
    >
      <mat-icon>radio_button_unchecked</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      (click)="onPinTool()"
      matTooltip="Marker Tool"
      aria-label="Marker Tool"
    >
      <mat-icon>place</mat-icon>
    </mat-button-toggle>
    <button
      mat-flat-button
      color="primary"
      [disabled]="viewOnly"
      class="tool-toggle delete-button"
      (click)="onResetDrawing()"
      matTooltip="Reset Drawing"
      aria-label="Reset Drawing"
    >
      <mat-icon><img id="trashIcon" /></mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      class="tool-toggle save-button"
      [disabled]="finishedDrawingDisabled || viewOnly"
      (click)="onSaveDrawing()"
      matTooltip="Save Drawing"
      aria-label="Save Drawing"
    >
      <mat-icon
        matBadge="!"
        matBadgeColor="warn"
        [matBadgeHidden]="getBadgeVisibility()"
        matBadgeDescription="Badge with exclamation mark to indicate unsaved changes"
        >check</mat-icon
      >
    </button>
  </mat-button-toggle-group>
</div>

<app-google-maps-drawing
  #cmpGoogleMapsDrawing
  (setBadgeVisibility)="setBadgeVisibility()"
  [googleMapsOptions]="options"
  [curTool]="currentTool"
  [props]="childProps"
  [viewOnly]="viewOnly"
  [markers]="markers"
>
</app-google-maps-drawing>
