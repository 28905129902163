import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-error404",
  templateUrl: "./error404.component.html",
  styleUrls: ["./error404.component.scss"],
})
export class Error404Component {
  errorCode = "404";
  iconID = "closeIcon";
  frenchText = "Page non trouvée";
  englishText = "page not found";

  constructor() {}
}
