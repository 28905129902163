<app-syncfusion-scheduler
  id="scheduler-component"
  #scheduler
  [xsScreenHeightMod]="heightMod"
  [views]="views"
  [resourcesDataSource]="resourcesDataSource"
  [events]="events"
  [timeScale]="timeScale"
  [actions]="actions"
  [fields]="fields"
  [weekFirstDay]="weekFirstDay"
  [allowResize]="false"
  [allowDragAndDrop]="true"
  [readonly]="readonly"
  (emitDeleteEvent)="emitDeleteEvent($event)"
>
</app-syncfusion-scheduler>

<ng-template #itemTemplate="" let-data>
  <div>
    {{ data.AssignmentID }}
  </div>
</ng-template>
