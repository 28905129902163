import { ChangeDetectionStrategy, Component, Inject, inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, Observable, Subject } from 'rxjs';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';
import { Feature, Map } from 'ol';
import { OpenLayersService } from './open-layers.service';
import { ScaleLine } from 'ol/control';
import { MapPreviewModalComponent } from '../../shared/ticket/modals/map-preview-modal/map-preview-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { apiKeys } from '../../../ENDPOINTS';
import { DrawingAPIService } from './drawing-api.service';
import { environment } from '../../../../environments/environment';
import axios from 'axios';
import { GeoJSON } from 'ol/format';
import {
  Drawing,
  DrawingCategory,
  DrawingDetails,
  DrawingID,
  DrawingManagerService,
  DrawingType,
  MapData,
} from './drawing-manager.service';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonDropdownSelectComponent } from '../../../shared/components/inputs/button-dropdown-select/button-dropdown-select-select.component';
import { AsyncPipe } from '@angular/common';
import JSZip from 'jszip';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { MapFeatureService } from './map-feature.service';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { MatTooltip } from '@angular/material/tooltip';
import { UserService } from '../../core/services/user/user.service';
import { SettingID } from '../../core/services/user/setting';
import { CallTypeID } from '../../shared/ticket-details/ticket-details.module';
import { JsonFormGroup } from '~lib/types/jsonFormRecursive';

const SCALE_LINE_TARGET = 'scale-line';
const SCALE_BAR_TARGET = 'scale-bar';
const SCALE_BAR_LENGTH = 100;

export type CaptureDimensions = {
  width: number;
  height: number;
};

type Float = number;
type ClientCaptureDimensions = {
  ID: number;
  width: number;
  height: number;
  ratio: Float;
};

@Injectable({
  providedIn: 'root',
})
export class DrawingCaptureService implements OnDestroy {
  // services
  private dialog: MatDialog = inject(MatDialog);
  private userService = inject(UserService);
  private progressBar = inject(ProgressBarService);
  private mapService = inject(OpenLayersService);
  private utilocateApiService = inject(ApiService);
  private drawingApiService = inject(DrawingAPIService);
  private featureService = inject(MapFeatureService);
  private drawingManagerService = inject(DrawingManagerService);

  // observables
  private destroy$ = new Subject<void>();
  private _defaultCaptureDimensions: CaptureDimensions = {
    width: 600,
    height: 600,
  };
  private _captureDimensions$: BehaviorSubject<CaptureDimensions> = new BehaviorSubject<CaptureDimensions>(
    this._defaultCaptureDimensions
  );
  private _clientCaptureDimensions$ = new BehaviorSubject<Record<string, ClientCaptureDimensions>>(null);

  // members
  private featureReader = new GeoJSON();
  private isLocalhost: boolean;

  constructor() {
    this.isLocalhost = environment.localhost;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async gatherClientCaptureDimensions(): Promise<Record<string, ClientCaptureDimensions>> {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapDrawing,
      API_BODY: { query: { screenshotDims: { all: true } } },
    };
    const { body } = await this.utilocateApiService.invokeUtilocateApi(request);
    const { result } = body;
    this._clientCaptureDimensions$.next(result);
    return result;
  }

  // take an aspect ratio and return the max dimensions that fit the viewport while maintaining the provided aspect ratio
  ratioToMaxDimension(
    ratio: number,
    map: Map,
    padding: boolean = true,
    trimY: number = 0,
    trimX: number = 0
  ): CaptureDimensions {
    let dims: CaptureDimensions;
    const [mapWidth, mapHeight] = (map.getSize()?.map((x: number) => {
      return padding ? x * 0.9 : x;
    }) as [number, number]) ?? [0, 0];
    const height = mapHeight - trimY;
    const width = mapWidth - trimX;
    const viewportRatio = width / height;
    if (ratio !== undefined && !isNaN(viewportRatio)) {
      if (ratio > viewportRatio) {
        dims = {
          width: width,
          height: width / ratio,
        };
      } else {
        dims = {
          width: height * ratio,
          height: height,
        };
      }
      return dims;
    } else return this._defaultCaptureDimensions;
  }

  async promptUserForDetails(drawing: Drawing): Promise<void> {
    const data = {
      title: drawing.title ?? drawing.drawingID,
      description: drawing.description ?? '',
      settingValue: Number(this.userService.getSettingValue(SettingID.CAPTURE_REQUIRES_TITLE_OR_DESCRIPTION)),
    };

    // If the setting is not active, update the drawing with default data and return
    if (!this.userService.isSettingActive(SettingID.CAPTURE_REQUIRES_TITLE_OR_DESCRIPTION)) {
      drawing.title = data.title;
      drawing.description = data.description;
      return;
    }

    // Open the dialog for user input
    const dialogRef = this.dialog.open(MapCaptureDialogComponent, {
      width: '427px',
      data,
    });

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    // Handle user cancellation
    if (dialogResult === false) {
      throw new CaptureError('User cancelled capture', CaptureErrorReason.UserCancelled);
    }

    // Update drawing title and description with user input
    drawing.title = data.title || drawing.drawingID;
    drawing.description = data.description;
  }

  promptToOverwritePrimary(): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Overwrite?',
        message: 'Primary drawing already exists. Would you like to overwrite?',
        confirmText: 'Overwrite',
      },
    });
    return firstValueFrom(dialogRef.afterClosed());
  }

  private async insertCapture(
    drawingID: DrawingID,
    assignmentID: number,
    primaryID: number,
    drawing: Omit<Drawing, 'backgroundIMG'>
  ) {
    const query = {
      insert: { drawingID, assignmentID, primaryID, drawing },
    };
    const { ok } = await this.drawingApiService.sendRequest(query);
    if (!ok) {
      return null;
    }
    return drawingID;
  }

  private async deleteDrawingEntry(drawingID: DrawingID) {
    return this.drawingApiService.sendRequest({
      delete: { drawingID },
    });
  }

  public async deleteCapture(
    clientID: number,
    assignmentID: number,
    primaryID: number,
    drawingID: DrawingID,
    fileNames: Array<string> = []
  ): Promise<'success' | 'failed' | 'cancelled'> {
    let status: 'success' | 'failed' | 'cancelled' = 'success';

    try {
      this.progressBar.start();

      // Delete drawing entry
      const { ok: drawingOk } = await this.deleteDrawingEntry(drawingID);
      if (!drawingOk) {
        return 'failed';
      }

      // Delete files if fileNames is provided
      if (fileNames.length > 0) {
        const prefixSegment = this.isLocalhost ? '' : '/nodejs';
        const promises = fileNames.map((fileName) => {
          const url = `${prefixSegment}/api/v1/clients/${clientID}/assignments/${assignmentID}/primaries/${primaryID}/drawings?file_name=${fileName}&document_id=${drawingID}`;
          return fetch(url, { method: 'DELETE' }).then((response) => {
            if (!response.ok) {
              throw new Error('failed');
            }
          });
        });
        await Promise.all(promises);
      }
    } catch (error) {
      status = 'failed';
    } finally {
      this.progressBar.stop();
    }
    return status;
  }

  deletePrompt(): Promise<boolean> {
    const dialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Delete Drawing',
        message: 'Do you want to delete?',
      },
    });

    return firstValueFrom(dialog.afterClosed());
  }

  public async uploadFile(
    clientID: number,
    assignmentID: number,
    primaryID: number,
    file: File,
    queryParams: Record<string, string> = {}
  ): Promise<void> {
    const isLocalHost = environment.localhost;
    let url = `/api/v1/clients/${clientID}/assignments/${assignmentID}/primaries/${primaryID}/drawings`;

    // Append query parameters if any
    const params = new URLSearchParams(queryParams).toString();
    if (params) {
      url += `?${params}`;
    }

    // Prefix with '/nodejs' if not on localhost
    if (!isLocalHost) {
      url = `/nodejs${url}`;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status !== 200) {
        throw new CaptureError('unable to upload background', CaptureErrorReason.Unknown);
      }
    } catch (e) {
      if (e.response && e.response.status === 409) {
        throw new CaptureError('primary already exists', CaptureErrorReason.PrimaryExists);
      }
      throw e;
    }
  }

  private async zipFile(fileNameIn: string, fileNameOut: string, file: File): Promise<File> {
    const zip = new JSZip();
    zip.file(fileNameIn, await file.arrayBuffer());
    const blob = await zip.generateAsync({ type: 'blob' });
    return new File([blob], `${fileNameOut}.zip`);
  }

  private async uploadCanvasBackgroundImage(
    drawing: Drawing,
    clientID: number,
    assignmentID: number,
    primaryID: number
  ): Promise<string> {
    const zipped = await this.zipFile(drawing.backgroundIMG.name, drawing.drawingID, drawing.backgroundIMG);
    try {
      await this.uploadFile(clientID, assignmentID, primaryID, zipped, { path_prefix: 'drawing-backgrounds' });
      return `drawing-backgrounds/${drawing.drawingID}.zip`;
    } catch (e) {
      console.log(e);
      return '';
    }
  }

  async saveCanvasDrawing(
    clientID: number,
    assignmentID: number,
    primaryID: number,
    features: GeoJSONFeatureCollection,
    background: File = undefined
  ) {
    const drawing: Drawing = {
      drawingID: crypto.randomUUID(),
      type: 'canvas',
      drawingCategory: undefined,
      title: undefined,
      description: undefined,
      captureURL: undefined,
      backgroundURL: undefined,
      drawingDetails: undefined,
      geoJSON: features,
      mapData: undefined,
      metadata: undefined,
      backgroundIMG: background,
    };

    try {
      await this.promptUserForDetails(drawing);
      if (drawing.backgroundIMG) {
        drawing.backgroundURL = await this.uploadCanvasBackgroundImage(drawing, clientID, assignmentID, primaryID);
      }
      drawing.backgroundIMG = undefined;
      return this.insertCapture(drawing.drawingID, assignmentID, primaryID, drawing);
    } catch (e) {
      if (e instanceof CaptureError) {
        return undefined;
      }
      console.log(e);
      return undefined;
    }
  }

  checkCaptureConflicts(drawingCategory: DrawingCategory) {
    if (drawingCategory === 'primary' && this.drawingManagerService.primaryDrawing !== undefined) {
      throw new CaptureError('Primary drawing already exists', CaptureErrorReason.PrimaryExists);
    }
  }

  private getMapData(): MapData {
    return {
      zoomLevel: this.mapService.zoom,
      centerCoordinates: this.mapService.center,
      extent: this.mapService.extent,
      layers: this.mapService.layers.map((x) => ({
        type: x.get('LayerType'),
        name: x.get('LayerName'),
        id: x.get('EsriLayerID') ?? x.get('layerID'),
        visible: x.getVisible(),
        url: x.get('LayerURL'),
        opacity: x.getOpacity(),
      })),
      resolution: this.mapService.resolution,
      rotation: this.mapService.rotation,
      selectedFeatures: [],
      projection: this.mapService.projection.getCode(),
    };
  }

  async takeCapture(
    drawingType: DrawingType,
    clientID: number,
    assignmentID: number,
    primaryID: number,
    drawingCategory: DrawingCategory,
    drawingDetails: DrawingDetails,
    metadata: Record<string, string>,
    geoJson: GeoJSONFeatureCollection = undefined,
    background: File = undefined
  ) {
    let deleteCurrentPrimary = false;
    try {
      this.checkCaptureConflicts(drawingCategory);
    } catch (e) {
      if (e instanceof CaptureError && e.reason === CaptureErrorReason.PrimaryExists) {
        const res = await this.promptToOverwritePrimary();
        if (!res) {
          return undefined;
        }
        deleteCurrentPrimary = true;
      } else {
        throw e;
      }
    }
    const { data, width, height } = this.captureMapCanvas();
    const { canvas } = await this.cropMapImage(data, width, height);
    const drawingID = crypto.randomUUID();
    const drawing = {
      drawingID,
      type: drawingType,
      drawingCategory,
      title: undefined,
      description: undefined,
      drawingDetails,
      metadata: { ...metadata, width: width.toString(), height: height.toString() },
    } as Drawing;

    try {
      await this.promptUserForDetails(drawing);
    } catch (e) {
      if (e instanceof CaptureError && e.reason === CaptureErrorReason.UserCancelled) {
        return undefined;
      }
    }

    this.progressBar.start();

    if (deleteCurrentPrimary) {
      const { drawingID, backgroundURL, captureURL } = this.drawingManagerService.primaryDrawing;
      const urls = [];
      if (backgroundURL !== undefined && backgroundURL !== null) {
        urls.push(backgroundURL);
      }
      if (captureURL !== undefined && captureURL !== null) {
        urls.push(captureURL);
      }
      await this.deleteCapture(clientID, assignmentID, primaryID, drawingID, urls);
    }

    if (drawing.type === 'map') {
      drawing.mapData = this.getMapData();
    } else if (drawingType === 'canvas') {
      if (background) {
        drawing.backgroundIMG = background;
        drawing.backgroundURL = await this.uploadCanvasBackgroundImage(drawing, clientID, assignmentID, primaryID);
        drawing.backgroundIMG = undefined;
      }
      if (geoJson !== undefined) {
        drawing.geoJSON = geoJson;
      }
    }

    if (drawingCategory === 'primary') {
      drawing.drawingDetails = undefined;
    }

    const res = await this.handleScreenshot(canvas, drawingID, clientID, assignmentID, primaryID, drawingCategory);
    if (res === '') {
      throw new Error('failed to upload document');
    } else if (res instanceof Error) {
      throw res;
    }

    drawing.captureURL = res;
    const insertRes = await this.insertCapture(drawingID, assignmentID, primaryID, drawing);
    this.progressBar.stop();
    return insertRes;
  }

  async handleScreenshot(
    canvas: HTMLCanvasElement,
    drawingID: string,
    clientID: number,
    assignmentID: number,
    primaryID: number,
    drawingCategory: DrawingCategory
  ): Promise<string | Error> {
    const url = canvas.toDataURL('image/png', 1);
    const data = url.split(',')[1];
    const buffer = await (await fetch('data:application/octet;base64,' + data)).arrayBuffer();
    const file = new File([buffer], drawingID + '.png');
    const zipped = await this.zipFile(file.name, drawingID, file);

    const query = {
      add_docs_entry: 'true',
      description: drawingCategory === 'primary' ? 'Primary' : 'Auxiliary',
      document_type_id: drawingCategory === 'primary' ? '108' : '106',
      file_name: file.name,
      uuid: drawingID,
    };

    try {
      await this.uploadFile(clientID, assignmentID, primaryID, zipped, query);
      this.dialog.open(MapPreviewModalComponent, {
        panelClass: 'map-preview',
        data: { url },
      });
      return drawingID + '.zip';
    } catch (e) {
      if (e instanceof CaptureError) {
        return e;
      }
      throw e;
    }
  }

  captureMapCanvas() {
    if (this.mapService.map === null) {
      console.log('map is not initialized');
      return;
    }
    const mapCanvas = document.createElement('canvas');
    const size = this.mapService.map.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    const mapContext = mapCanvas.getContext('2d');

    Array.from(this.mapService.map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer')).forEach(
      (canvas: HTMLCanvasElement) => {
        if (canvas.width > 0) {
          mapContext.globalAlpha = 1;
          let matrix: number[];
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
          mapContext.fillStyle = '#FFFFFF'; // White color
          mapContext.fillRect(0, 0, mapCanvas.width, mapCanvas.height);
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);

    return {
      data: mapCanvas.toDataURL(),
      width: mapCanvas.width,
      height: mapCanvas.height,
    };
  }

  cropMapImage(
    dataURL: string,
    sourceWidth: number,
    sourceHeight: number
  ): Promise<{
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
  }> {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const imageObj = new Image();
    imageObj.crossOrigin = 'anonymous';

    return new Promise((resolve, reject) => {
      imageObj.onload = () => {
        const destWidth = this._captureDimensions$.value.width;
        const destHeight = this._captureDimensions$.value.height;
        const destX = (sourceWidth - destWidth) / 2;
        const destY = (sourceHeight - destHeight) / 2;
        canvas.width = destWidth;
        canvas.height = destHeight;
        context.drawImage(imageObj, destX, destY, destWidth, destHeight, 0, 0, destWidth, destHeight);

        resolve({ canvas, context });
      };
      imageObj.onerror = reject;
      imageObj.src = dataURL;
    });
  }

  addScaleToCanvas(
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    scaleBarLength: string,
    scaleBarValue: string,
    scaleLineValue: string
  ) {
    const scaleBarValueFormat = '1 : ' + scaleBarValue;
    const line = 6;
    const x_offset = 10;
    const y_offset = 30;
    const fontsize1 = 20;
    const font = fontsize1 + 'px Arial';
    const multiplier = 2;
    const scalewidth = parseInt(scaleBarLength, 10) * multiplier;

    //Scale Text
    ctx.beginPath();
    ctx.textAlign = 'left';
    ctx.strokeStyle = '#ffffff';
    ctx.fillStyle = '#000000';
    ctx.lineWidth = 5;
    ctx.font = font;
    ctx.strokeText(scaleBarValueFormat, 20 + x_offset + fontsize1 / 2, canvas.height - y_offset - fontsize1 / 2);
    ctx.fillText(scaleBarValueFormat, 20 + x_offset + fontsize1 / 2, canvas.height - y_offset - fontsize1 / 2);

    //Scale Dimensions
    const xZero = scalewidth + x_offset;
    const yZero = canvas.height - y_offset;
    const xFirst = x_offset + scalewidth / 4;
    const xSecond = xFirst + scalewidth / 4;
    const xThird = xSecond + scalewidth / 4;
    const xFourth = xThird + scalewidth / 4;

    // Stroke
    ctx.beginPath();
    ctx.lineWidth = line + 2;
    ctx.strokeStyle = '#000000';
    ctx.fillStyle = '#ffffff';
    ctx.moveTo(x_offset, yZero);
    ctx.lineTo(xZero + 1, yZero);
    ctx.stroke();

    //sections black/white
    this.drawScaleBar(ctx, line, '#000000', x_offset, yZero, xFirst, yZero);
    this.drawScaleBar(ctx, line, '#FFFFFF', xFirst, yZero, xSecond, yZero);
    this.drawScaleBar(ctx, line, '#000000', xSecond, yZero, xThird, yZero);
    this.drawScaleBar(ctx, line, '#FFFFFF', xThird, yZero, xFourth, yZero);

    ctx.beginPath();
    ctx.textAlign = 'left';
    ctx.strokeStyle = '#ffffff';
    ctx.fillStyle = '#000000';
    ctx.lineWidth = 5;
    ctx.font = font;
    ctx.strokeText(scaleLineValue, xFourth + 5, yZero + 20);
    ctx.fillText(scaleLineValue, xFourth + 5, yZero + 20);
  }

  drawScaleBar(
    ctx: CanvasRenderingContext2D,
    line: number,
    color: string,
    movePoint1: number,
    movePoint2: number,
    linePoint1: number,
    linePoint2: number
  ) {
    ctx.beginPath();
    ctx.lineWidth = line;
    ctx.strokeStyle = color;
    ctx.moveTo(movePoint1, movePoint2);
    ctx.lineTo(linePoint1, linePoint2);
    ctx.stroke();
  }

  createScaleMap(
    map: Map,
    mapContext: CanvasRenderingContext2D,
    mapCanvas: HTMLCanvasElement,
    dinWidth: string,
    isVisible: boolean
  ) {
    this.createScaleContainer();
    const _scaleLine = this.getScaleControl(false, SCALE_LINE_TARGET);
    const _scaleBar = this.getScaleControl(true, SCALE_BAR_TARGET);
    map.addControl(_scaleLine);
    map.addControl(_scaleBar);
    map.renderSync();
    const scaleBarValue = Math.round(_scaleBar.getScaleForResolution()).toLocaleString();
    const scaleLineValue = document.getElementsByClassName('ol-scale-line-inner')[0].innerHTML;
    if (isVisible) this.addScaleToCanvas(mapContext, mapCanvas, dinWidth, scaleBarValue, scaleLineValue);

    return { scaleBarValue: scaleBarValue, scaleLineValue: scaleLineValue };
  }

  createScaleContainer() {
    const scaleElement = document.createElement('div');
    scaleElement.style.display = 'none';
    const scaleBarElement = document.createElement('div');
    scaleBarElement.setAttribute('id', SCALE_BAR_TARGET);
    const scaleLineElement = document.createElement('div');
    scaleLineElement.setAttribute('id', SCALE_LINE_TARGET);

    scaleElement.appendChild(scaleBarElement);
    scaleElement.appendChild(scaleLineElement);
    document.body.appendChild(scaleElement);
  }

  getScaleControl(isBar: boolean, target: string) {
    return new ScaleLine({
      units: 'metric',
      bar: isBar,
      steps: 4,
      text: true,
      minWidth: SCALE_BAR_LENGTH,
      target: target,
    });
  }

  canvasDrawingFromFeatures(features: Array<Feature>): GeoJSONFeatureCollection {
    return this.featureReader.writeFeaturesObject(this.featureService.prepFeaturesForWrite(features));
  }

  async getCallTypeFormControls(callTypeID: CallTypeID): Promise<DrawingFormResponse> {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.drawingForms,
      API_BODY: { callTypeID },
    };
    const response = await this.utilocateApiService.invokeUtilocateApi(request);
    if (!response.ok) {
      throw new Error('failed to get form for call type');
    }

    return response.body.data;
  }

  get captureDimensions$(): Observable<CaptureDimensions> {
    return this._captureDimensions$.pipe(distinctUntilChanged());
  }

  set captureDimensions(drawingCategory: DrawingCategory) {
    firstValueFrom(this._clientCaptureDimensions$.pipe(filter((x) => ![null, undefined].includes(x)))).then(
      (dimensions) => {
        this._captureDimensions$.next(
          this.ratioToMaxDimension(dimensions[drawingCategory].ratio, this.mapService.map, true, 90)
        );
      }
    );
    // if ([null, undefined].includes(dimensions) || dimensions.height === 0 || dimensions.width === 0) {
    //   this._captureDimensions$.next(this._defaultCaptureDimensions);
    // } else {
    //   this._captureDimensions$.next(dimensions);
    // }
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, ButtonDropdownSelectComponent, AsyncPipe, MatTooltip],
  template: `
    <div class="flex flex-col p-6 justify-start items-start m-0">
      <h2 class="text-headline-6 font-rajdhani font-semibold uppercase p-0 m-0 mb-9">Save Drawing</h2>
      <div class="size-full flex flex-col gap-14 justify-start items-start">
        <div class="size-full flex flex-col justify-center items-center m-0">
          <div class="size-full flex flex-col gap-6">
            <div class="flex flex-col gap-1.5">
              <div class="flex flex-row gap-4 justify-start items-center">
                <label for="title" class="block text-lg text-accent font-rajdhani font-semibold uppercase">title</label>
                @if (!title.valid && title.touched) {
                  <span class="text-warn font-rajdhani text-sm">title is required</span>
                }
              </div>
              <input
                [formControl]="title"
                type="text"
                id="title"
                class="appearance-none box-border bg-white border-solid border-[#00000029] border-1 text-gray-900 text-sm rounded-md block w-full p-2.5 m-0 {{
                  title.touched && !title.valid ? 'ring-2 ring-red-200' : ''
                }}"
                placeholder="new drawing" />
            </div>
            <div class="flex flex-col gap-1.5">
              <div class="flex flex-row gap-4 justify-start items-center">
                <label for="description" class="block text-lg text-accent font-rajdhani font-semibold uppercase">
                  description
                </label>
                @if (!description.valid && description.touched) {
                  <span class="text-warn font-rajdhani text-sm">description is required</span>
                }
              </div>
              <textarea
                [formControl]="description"
                id="description"
                class="h-44 w-full appearance-none box-border bg-white border-solid border-[#00000029] border-1 text-gray-900 text-sm rounded-md block p-2.5 m-0 {{
                  description.touched && !description.valid ? 'ring-2 ring-red-200' : ''
                }}"
                placeholder="..."></textarea>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-row gap-3 justify-end items-center">
          <button
            type="button"
            class="h-9 w-[104px] flex justify-center items-center appearance-none border-none bg-transparent py-2 px-4 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase"
            (click)="dialogRef.close(false)">
            cancel
          </button>
          <button
            type="button"
            [disabled]="!title.valid || !description.valid"
            class="h-9 w-[104px] flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase disabled:cursor-not-allowed disabled:bg-gray-500 disabled:border-gray-500"
            (click)="submit()"
            matTooltip="Complete the form to continue"
            [matTooltipDisabled]="title.valid && description.valid">
            save
          </button>
        </div>
      </div>
    </div>
  `,
})
class MapCaptureDialogComponent implements OnInit {
  protected title: FormControl;
  protected description: FormControl;

  constructor(
    public dialogRef: MatDialogRef<MapCaptureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; settingValue: number },
    private fb: FormBuilder
  ) {
    this.title = this.fb.control('', []);
    this.description = this.fb.control('', []);
  }

  ngOnInit(): void {
    if ([0, 2].includes(this.data.settingValue)) {
      this.title.addValidators(Validators.required);
    }
    if ([1, 2].includes(this.data.settingValue)) {
      this.description.addValidators(Validators.required);
    }

    this.title.setValue(this.data.title);
    this.description.setValue(this.data.description);
  }

  submit(): void {
    if (this.title.valid && this.description.valid) {
      this.data.title = this.title.value;
      this.data.description = this.description.value;
      this.dialogRef.close(true);
    } else {
      // Handle form invalid case (do nothing)
    }
  }
}

export enum DrawingCategories {
  primary,
  auxiliary = 2,
}

export enum CaptureErrorReason {
  PrimaryExists,
  UserCancelled,
  Unknown,
}

export class CaptureError extends Error {
  private readonly _reason: CaptureErrorReason;

  constructor(message: string, reason: CaptureErrorReason) {
    super(message);
    this.name = 'Capture Error';
    this._reason = reason;
  }

  get reason() {
    return this._reason;
  }
}

type DrawingFormResponse = {
  id: number;
  title: string;
  groups: Array<JsonFormGroup>
};
