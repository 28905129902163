import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSettingsItemComponent } from './account-settings-item/account-settings-item.component';
import { AccountSettingsPageComponent } from './account-settings-page/account-settings-page.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { AutomatedEngineModule } from '../automated-engine/automated-engine.module';
import { RouterModule } from '@angular/router';
import { SettingID } from '../core/services/user/setting';
import {ScrollingModule} from '@angular/cdk/scrolling';

export class SettingTile {
  title: string;
  icon: string;
  description: string;
  path: string;
  settingID: SettingID;

  constructor(title: string, icon: string, description: string, path: string, settingID: SettingID) {
    this.title = title;
    this.icon = icon;
    this.description = description;
    this.path = path;
    this.settingID = settingID;
  }
}

@NgModule({
  declarations: [
    AccountSettingsItemComponent,
    AccountSettingsPageComponent
  ],
  imports: [
    RouterModule,
    AutomatedEngineModule,
    CommonModule,
    BrowserModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule
  ]
})
export class AccountSettingsModule { }
