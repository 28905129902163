import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagerComponent } from './user-manager/user-manager.component';
import { MaterialModule } from '../shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BaseModalModule } from '../shared/modals/base-modal.module';
import { TableModule } from '../shared/table/table.module';
import { UserManagerEditorModule } from './user-manager-editor/user-manager-editor.module';
import { CompetersSearchBarComponent } from '../../shared/components/inputs/competers-search-bar/competers-search-bar.component';
import { TabulatorTableComponent } from '../shared/table/tabulator-table/tabulator-table.component';
import { NgxColorsModule } from "ngx-colors";
@NgModule({
  declarations: [UserManagerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    BaseModalModule,
    TableModule,
    UserManagerEditorModule,
    CompetersSearchBarComponent,
    TabulatorTableComponent,
    NgxColorsModule
  ],
})
export class UserManagerModule {}
