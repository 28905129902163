import { Component, OnInit, NgModule, Input } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../shared/material.module";

@Component({
  selector: "app-navigation-rail",
  templateUrl: "./navigation-rail.component.html",
  styleUrls: ["./navigation-rail.component.css"],
})
export class NavigationRailComponent {
  @Input() inputsStart: any;
  @Input() inputsEnd: any;

  constructor() {}

  getStartInput(i) {
    var input = this.inputsStart[i];
    input.action();
  }

  getEndInput(i) {
    var input = this.inputsEnd[i];
    input.action();
  }
}

export interface navRailOptions {
  id: string;
  icon: string;
  label: string;
  action: any;
}

@NgModule({
  declarations: [NavigationRailComponent],
  imports: [MaterialModule, FlexLayoutModule],
  exports: [NavigationRailComponent],
})
export class NavigationRailModule {}
