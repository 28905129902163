<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav opened mode="side" class="app-sidenav">
    <div fxLayout="column" fxLayoutAlign="start" class="menu">
      <ng-container>
        <div class="measurement-toolbar" fxLayout="column">
          <span (click)="areaMeasurement()" class="text-btn">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-icon> crop_square</mat-icon>
              <span>Area</span>
            </div>
          </span>

          <span (click)="distanceMeasurement()" class="text-btn">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-icon> settings_ethernet</mat-icon>
              <span>Distance</span>
            </div>
          </span>

          <span (click)="selectUnits()" class="text-btn">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-icon> compare_arrows</mat-icon>
              <span>Units</span>
            </div>
          </span>

          <span (click)="clearMeasurements()" class="text-btn">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-icon> delete</mat-icon>
              <span>Clear</span>
            </div>
          </span>

          <span (click)="closeTool()" class="text-btn">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-icon> clear</mat-icon>
              <span>Close</span>
            </div>
          </span>
        </div>
      </ng-container>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
