import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './competers-slide-toggle.component.html',
  standalone: true, 
  imports: [FormsModule]
})
export class CompetersSlideToggleComponent {
  @Input() value: boolean = false;
  @Input() text: string | null = null;
  @Input() textPosition: 'left' | 'right' = 'right';

  @Output() valueChange = new EventEmitter<boolean>();

  onValueChange(newValue: boolean) {
    this.valueChange.emit(newValue);
  }
}
