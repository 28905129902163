//A user categolry object from the controller
export class UserCategory {
  UserCategoryID: number;
  Title: string;
  Description: string;
  Settings: UserCategorySettings[];
}

//a setting object from the controller
export class UserCategorySettings {
  CategoryToSettingID: number;
  SettingCategoryID: number;
  SettingID: number;
  DefaultActive: number;
  DefaultValue: string | null;

  constructor(categoryToSettingID: number, settingCategoryID: number, settingID: number, defaultActive: number, defaultValue: string | null) {
    this.CategoryToSettingID = categoryToSettingID;
    this.SettingCategoryID = settingCategoryID;
    this.SettingID = settingID;
    this.DefaultActive = defaultActive;
    this.DefaultValue = defaultValue;
  }
}

//a setting object from tbAdmin_Settings
export class GlobalSetting {
  SettingID: number;
  Active: number;
  Value: string;
  Description: string;
  Name: string;
  CanEdit: number;
  CanView: number;
}