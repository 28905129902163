export class Notification {
  constructor(
    public type: NotificationType,
    public message: string,
    public title?: string
  ) {}
}

export enum NotificationLoadingType {
  loading = 0,
  success = 1,
  fail = 2,
}

export enum NotificationType {
  success = 0,
  warning = 1,
  error = 2,
  loading = 3,
}
