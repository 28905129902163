import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { MaterialModule } from "../material.module";

@NgModule({
  declarations: [ProgressBarComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
