import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { TicketDocumentsService } from "../../ticket-documents/ticket-documents.service";

//these are file extensions that correspond to the type 
// if you find other image types, add to imageTypes, etc.. 
const imageTypes = ['jpg', 'jpeg', 'png'];
const pdfTypes = ['pdf'];
const plaintextTypes = ['txt'];
const dbFileTypes = ['dbf', 'geojson'];

@Component({
  selector: "app-preview-modal",
  templateUrl: "./preview-modal.component.html",
  styleUrls: ["./preview-modal.component.scss"],
})
export class PreviewModalComponent implements OnInit {
  data: any;
  isFail: boolean;
  url: string;
  message: string;
  isImage: boolean;
  isPlaintext: boolean;
  isPdf: boolean;
  urls;
  docs: any
  index: any
  fileExt: any
  dbFile: any

  private docsSubject: Subscription;

  public safeUrl: SafeResourceUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<PreviewModalComponent>,
    private sanitizer: DomSanitizer,
    private ticketDocumentService: TicketDocumentsService
  ) {
    this.data = Data;
  }

  ngOnInit(): void {
    this.docsSubject = this.ticketDocumentService.docRows$.subscribe(docRows => {
      this.docs = docRows;            
    });

    this.index = this.docs.findIndex(doc => doc.FileName === this.data.name);

    //setup variables
    let fileExt = this.data.name.split('.').pop().toLowerCase();
    
    this.isFail = false;
    this.url = null;
    this.urls = this.data.url?.split(",");

    //determine if it is an image, pdf, or text
    if (this.urls && this.urls[1]) {
      //image
      if (imageTypes.includes(fileExt)) {
        this.isImage = true;
        this.url = "data:image/jpeg;base64," + this.urls[1];
      //pdfs
      } else if (pdfTypes.includes(fileExt)) {
        const blob = this.convertBase64ToBlob(this.urls[1], 'application/pdf');
        const url = URL.createObjectURL(blob);
        const unsafeUrl = url;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
        this.isPdf = true;
        
      //plaintext
      } else if (plaintextTypes.includes(fileExt)) {
        this.isPlaintext = true;
        this.message = atob(this.urls[1]); //convert from base64 to plaintext 
      }
    //we didn't get a base64 string 
    } else if (this.data.url && this.data.url.startsWith("blob")) {      

      this.isPdf = false;
      this.isPlaintext = true;
      this.message = "No Preview Available";

    } else {
      this.message = "No Preview Available";
    }
  }

  convertBase64ToBlob(base64: string, contentType: string): Blob {
    const sliceSize = 512;
    const byteCharacters = atob(base64); // Decode base64 string
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onConfirm(event) {
    const downloadLink = document.createElement("a");
    downloadLink.href = this.data.url;
    downloadLink.download = this.data.name;
    downloadLink.click();
  }
  

  nextImg() {
    this.isPdf = false;
    this.dbFile = false
    this.isImage = true
    this.isPlaintext = false
    this.index++;
  
    if (this.index >= this.docs.length) {
      this.index = 0;
    }
    let i = this.index;

    this.data.name = this.docs[i].FileName;
    this.data.url = this.docs[i].file;
    this.url = this.docs[i].file;
    this.data.caption = this.docs[i].Caption;
    this.fileExt = this.data.name.split('.').pop().toLowerCase();
    
    if (pdfTypes.includes(this.fileExt)) {
      this.isImage = false;
      this.convertToPDF()
    }
    if(dbFileTypes.includes(this.fileExt)){
      this.isImage = false;
      this.dbFile = true
    }
    if(plaintextTypes.includes(this.fileExt)){
      this.isImage = false;
      this.isPlaintext = true
    }
  }

  convertToPDF(){
      this.urls = this.data.url?.split(",");
      
      const blob = this.convertBase64ToBlob(this.urls[1], 'application/pdf');
      const url = URL.createObjectURL(blob);
      const unsafeUrl = url;
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
      
      this.isPdf = true;
  }

  prevImg() {
    this.isPdf = false;
    this.dbFile = false
    this.isPlaintext = false
    this.isImage = true
    this.index--;
  
    if (this.index < 0) {
      this.index = this.docs.length - 1;
    }
  
    let i = this.index;
  
    this.data.name = this.docs[i].FileName;
    this.data.url = this.docs[i].file;
    this.url = this.docs[i].file;
    this.data.caption = this.docs[i].Caption;
    this.fileExt = this.data.name.split('.').pop().toLowerCase();
    if (pdfTypes.includes(this.fileExt)) {
      this.isImage = false;
      this.convertToPDF()
    }
    if(this.fileExt.includes('dbf') || this.fileExt.includes('geojson')){
      this.isImage = false;
      this.dbFile = true
    }
    if(plaintextTypes.includes(this.fileExt)){
      this.isImage = false;
      this.isPlaintext = true
    }
  }

  ngOnDestroy() {
    if (this.docsSubject) {
     this.docsSubject.unsubscribe();
   }
 }
}
