import { ErrorHandler, Injectable } from "@angular/core";
// import { BehaviorSubject } from 'rxjs';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class ErrorHandlingService implements ErrorHandler {
  // private errorMessageSource = new BehaviorSubject('default message');
  // currentMessage = this.errorMessageSource.asObservable();

  constructor() {}

  // changeMessage(message: string) {
  //   this.errorMessageSource.next(message)
  // }

  debounceAlert(): Function {
    let timeRef;
    let delay = 1000;
    return function (msg): void {
      console.log("function called");
      clearTimeout(timeRef);
      timeRef = setTimeout(() => {
        alert(msg);
      }, delay);
    };
  }

  debounced = this.debounceAlert();

  handleError(error: any): void {
    switch (error.code) {
      case 22:
        this.debounced(
          "Device storage limits exceeded. Please perform a sync to avoid data loss."
        );
        break;
      default:
        console.error(error);
    }
  }
}
