// @ts-nocheck
import Popup from 'ol-popup';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Feature, MapBrowserEvent } from 'ol';
import { Polygon } from 'ol/geom';
import Style, { StyleFunction } from 'ol/style/Style';
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { FeatureOrCollection } from '../../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../../classes/map-feature-change';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';
import { Interaction } from 'ol/interaction';
import { first } from 'rxjs/operators';
import { InteractionParameters } from '../types';
import Styles from '../styles';
import { StyleToolbox } from '../../classes/style-toolbox';

export class TextTool extends Interaction {
  private readonly mapRef: Map;
  private readonly sourceRef: VectorSource;
  private readonly style: StyleFunction;
  private readonly coords: Coordinate;
  private popup = new Popup();
  private readonly template: HTMLElement;
  private readonly featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
  private readonly olUtilities: OpenLayersUtilities;
  private focus: () => void;
  private parameters: InteractionParameters;
  private toolbox: StyleToolbox;

  div: HTMLDivElement;
  inputDiv: HTMLDivElement;
  inputLabel: HTMLLabelElement;
  input: HTMLInputElement;
  submitTextButton: HTMLButtonElement;
  buttonDiv: HTMLDivElement;

  cursor = 'pointer';
  previousCursor = undefined;

  constructor({
    mapRef,
    sourceRef,
    featureChanges,
    olUtilities,
    endInteraction,
    parameters,
  }: {
    mapRef: Map;
    sourceRef: VectorSource;
    featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
    olUtilities: OpenLayersUtilities;
    endInteraction: Subject<void>;
    parameters: InteractionParameters;
  }) {
    super({
      handleEvent,
    });
    this.sourceRef = sourceRef;
    this.mapRef = mapRef;
    this.featureChanges = featureChanges;
    this.olUtilities = olUtilities;
    this.popup.setProperties({
      type: 'textInput',
      pointSource: sourceRef,
      completed: false,
    });
    this.template = this.initPopup();
    endInteraction.pipe(first()).subscribe(() => {
      this.input.value = '';
      this.popup.hide();
      this.mapRef.removeOverlay(this.popup);
    });
    this.parameters = parameters;
    this.toolbox = new StyleToolbox(mapRef);
    this.style = Styles.text(mapRef, this.toolbox);
  }

  private initPopup() {
    this.div = document.createElement('div');
    this.inputDiv = document.createElement('div');
    this.inputLabel = document.createElement('label');
    this.input = document.createElement('input');
    this.submitTextButton = document.createElement('button');
    this.buttonDiv = document.createElement('div');
    this.inputDiv.classList.add('map-label-input-container');
    this.inputLabel.innerText = 'Enter your text';
    this.inputLabel.htmlFor = 'label';
    this.input.type = 'text';
    this.input.name = 'label';
    this.buttonDiv.classList.add('map-label-button-container');
    this.submitTextButton.innerText = 'enter';
    this.submitTextButton.classList.add('map-label-submit');

    this.buttonDiv.append(this.submitTextButton);
    this.inputDiv.append(this.inputLabel, this.input, this.buttonDiv);
    this.div.append(this.inputDiv);
    this.focus = () => {
      this.input.focus();
    };
    const listenerCallback = () => {
      this.addPointText(this.input.value);
      this.popup.hide();
      this.mapRef.removeOverlay(this.popup);
      this.input.value = '';
    };
    this.submitTextButton.addEventListener('click', (e) => {
      e.preventDefault();
      listenerCallback();
    });
    this.input.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        listenerCallback();
      }
    });
    return this.div;
  }

  private addPointText(text: string) {
    const { fillColour, opacity, strokeColour } = this.parameters.toolbarState;
    const feature = new Feature();
    feature.setProperties(
      {
        text_label: text,
        shape_type_id: 6,
        fill_colour: fillColour,
        fill_opacity: opacity / 100,
        stroke_colour: strokeColour,
        text_colour: strokeColour,
      },
      true
    );
    feature.setStyle(this.style);
    feature.setGeometry(this.buildGeom(feature));
    this.sourceRef.addFeature(feature);
    this.featureChanges.next(new MapFeatureChange(FeatureChangeType.added, feature, undefined, this.sourceRef));
  }

  private buildGeom(feature): Polygon {
    const style: Style = this.style(feature, this.mapRef.getView().getResolution()) as Style;
    const textObject = style.getText();
    const textContent = textObject.getText() as string;
    const padding = textObject.getPadding();
    const font = textObject.getFont();
    const measurement = this.olUtilities.measureText(textContent, font, padding);
    // coordinates for polygon
    return this.olUtilities.createRectangleGeometry(this.mapRef, this.coords, measurement.width, measurement.height);
  }
}

function handleEvent(evt: MapBrowserEvent<UIEvent>): boolean {
  if (evt.type === 'singleclick' && this.popup) {
    this.popup.show(evt.coordinate, this.template);
    this.coords = evt.coordinate;
    this.mapRef.addOverlay(this.popup);
    this.focus();
    return false;
  }
  return true;
}
