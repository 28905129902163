<mat-card class="border border-solid border-gray-300 mt-2 mx-2 shadow-none">
  <mat-card-content class="smmry-card-content p-0 m-0 w-full">
    <div
      class="h-6 p-0 flex flex-row items-center justify-start border-solid border-0 border-b-1 border-gray-300 text-primary text-sm uppercase font-rajdhani font-semibold">
      <!-- TODO: Add functionality for adding / removing from search -->
      <!-- <mat-icon class="hover:cursor-pointer scale-75 pr-1 text-success" (click)="updateButtonState()">
        {{ iconName }}
      </mat-icon> -->
      <div class="truncate pl-2">{{ data.position }}, {{ data.name }}</div>
    </div>
    <table mat-table [dataSource]="rows" class="smmry-card-ticketinfo-table p-0">
      <ng-container matColumnDef="value" class="py-2">
        <td mat-cell *matCellDef="let row" class="text-right p-0 pr-2 py-[2px]">
          <span class="" [ngClass]="{ 'text-warn': row.key === 'Lates' && row.value > 0 }" *ngIf="row.value !== null">
            {{ row.value }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="key">
        <td mat-cell *matCellDef="let row" class="p-0 pl-2 py-[2px]">{{ row.key | titlecase }}</td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="h-4 font-rajdhani font-semibold uppercase"></tr>
    </table>
  </mat-card-content>
</mat-card>
