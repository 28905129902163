<app-scheduler-view
  [props]="schedulerViewProps"
  [events]="events"
  [ticketTags]="ticketTags"
  [resources]="resources"
  [resourcesDataSource]="rigResources"
  [peopleResources]="peopleResources"
  [drillerResources]="drillerResources"
  [helperResources]="helperResources"
  [resourceEquipmentResources]="resourceEquipmentResources"
  [tickets]="tickets"
  [parentEvents]="parentEvents"
  [readonly]="readonly"
>
</app-scheduler-view>
