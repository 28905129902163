import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { apiKeys, api } from "src/app/ENDPOINTS";
import { environment } from "src/environments/environment";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import { UtilocateApiService } from "../../core/api/utilocateapi.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { FormattedEmailRow } from "../failed-emails/failed-emails.component";
import { Formatted360Row } from "../failed-360/failed-360.component";

export enum Action {
  GetEmails = 1,
  Resend = 2,
  SendManually = 3,
  Abandon = 4,
  GetThirdPartyJobs = 5,
  RetryThirdPartyJobs = 6,
  AbondonThirdPartyJobs = 7,
  Get360Errors = 8,
  Retry360 = 9,
  SendManually360 = 10,
  Abandon360 = 11
}

@Injectable({
  providedIn: "root",
})
export class SystemMaintenanceService {
  IS_LIVE: boolean = false;
  IS_DEV: boolean = true;
  isLocalHost: boolean;

  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService,
    private baseApiService: UtilocateApiService
  ) {
    this.logger$.log("Created Routing Preset Service");

    this.IS_LIVE = environment.production == true;
    this.IS_DEV = environment.production == false && environment.url == null;
    this.isLocalHost = environment.localhost;
  }

  getFailedEmails() {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.GetEmails,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  markResend(emails: FormattedEmailRow[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.Resend,
      SelectedEmails: emails,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  markSendManually(emails: FormattedEmailRow[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.SendManually,
      SelectedEmails: emails,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  markAbandon(emails: FormattedEmailRow[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.Abandon,
      SelectedEmails: emails,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  getFailedThirdPartyJobs() {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.GetThirdPartyJobs,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  retryThirdPartyJobs(jobsToRetry: number[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.RetryThirdPartyJobs,
      SelectedJobs: jobsToRetry,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  abandonThirdPartyJobs(jobsToRetry: Object[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.AbondonThirdPartyJobs,
      SelectedJobs: jobsToRetry,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  async gatherCustomers(clientID, userID) {
    try {
      let url = "/api/syncCustomers";
      if (!this.isLocalHost) {
        url = "/nodejs/api/syncCustomers";
      }
      let arn =
        "arn:aws:lambda:ca-central-1:642224146577:function:sls-g-ulocate-NetSuiteGatherCustomers:";

      if (this.IS_LIVE) {
        arn += "liveV0api2";
      } else {
        arn += "dev";
      }

      let params = {
        clientID: clientID,
        userID: userID,
        stage: this.IS_LIVE ? "liveV0api2" : "dev",
        arn: arn,
      };

      let result: HttpResponse<any> = await this.baseApiService.invokeApi(
        "PUT",
        url,
        { params: params }
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  get360Errors() {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.Get360Errors,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  retry360(rows: Formatted360Row[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.Retry360,
      SelectedRows: rows,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  markSendManually360(rows: Formatted360Row[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.SendManually360,
      SelectedRows: rows,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  markAbandon360(rows: Formatted360Row[]) {
    const url = apiKeys.u2.systemMaintenanceController;
    const type = api[url].type;
    let value = {
      ActionID: Action.Abandon360,
      SelectedRows: rows,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }
}
