import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-capture-frame',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: `
      <div class="size-fit map-capture-frame border-solid border-[3px] border-white rounded-sm">
        <ng-content></ng-content>
      </div>
  `,
  styles: `
      .map-capture-frame {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          text-align: center;
          box-shadow: 0 0 1px 100vmax rgba(65, 64, 66, 0.9);
          z-index: 1;
          pointer-events: none;
      }
  `,
})
export class CaptureFrameComponent {
}
