import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-map-preview-modal",
  templateUrl: "./map-preview-modal.component.html",
  styleUrls: ["./map-preview-modal.component.scss"],
})
export class MapPreviewModalComponent implements OnInit {
  data: any;
  isFail: boolean;
  url: string;
  urls: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<MapPreviewModalComponent>,
  ) {
    this.data = Data;
    this.isFail = false;
    this.urls = this.data.url.split(",");

    this.url = "data:image/jpeg;base64," + this.urls[1];
  }

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 5000);
  }
}
