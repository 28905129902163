import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { PdfViewerComponent } from "../../../pdf-viewer/pdf-viewer.component";
import { pdfOptions } from "../../../pdfOptions";

@Component({
  selector: "app-pdf-view",
  templateUrl: "./pdf-view.component.html",
  styleUrls: ["./pdf-view.component.scss"],
})
export class PdfViewComponent implements OnInit {
  className = "PdfViewComponent";
  @ViewChild("pdfViewer") pdfViewer: PdfViewerComponent;
  @Input() props: any;

  options: pdfOptions;

  constructor(
    // error$: MainErrorService,
    logger$: LoggerService
    // alert$: NotificationService
  ) {}

  /**
   * subscribe to message service and send ready message
   */
  ngOnInit(): void {
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });

    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { pdfViewerReady: true },
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(message);
  }

  /**
   * if message has pdfReady key, update options
   * @param compMessage message that was received
   */
  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.pdfReady) {
        this.options = {
          isBase64: false,
          data: compMessage.message.pdf,
          showSidebarButton: true,
        };
      }
    }
  }
}
