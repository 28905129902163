import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SecurityTroubleshootComponent } from "./security-troubleshoot/security-troubleshoot.component";
import { MaterialModule } from "../shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [SecurityTroubleshootComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule],
})
export class SecurityTroubleshootModule {}
