import { Component, Input, OnInit } from '@angular/core';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { PunchClockService } from '../../punch-clock.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: 'app-punch-clock-timesheet-item',
  templateUrl: './punch-clock-timesheet-item.component.html',
  styleUrls: ['./punch-clock-timesheet-item.component.scss']
})
export class PunchClockTimesheetItemComponent implements OnInit {

  
  @Input() StartTime: any;
  @Input() StopTime: any;
  @Input() Task: any;
  @Input() TaskArray: any;
  @Input() EntryID: any;

  formattedStartTime: any
  formattedStopTime: any
  convertedTask :any
  editClicked: boolean = false
  selectedOption: string
  chosenStartTime: any
  chosenStopTime: any

  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#fff",
      buttonColor: "#0d66b1",
    },
    dial: {
      dialBackgroundColor: "#0d66b1",
    },
    clockFace: {
      clockFaceBackgroundColor: "#eee",
      clockHandColor: "#0d66b1",
      clockFaceTimeInactiveColor: "#222",
    },
  };

  constructor(  
     private datetimeService: DatetimeService,
     private punchClockService: PunchClockService

    ) { }

  ngOnInit(): void {
    this.formattedStartTime = this.formatTimes(this.StartTime)
    this.formattedStopTime = this.formatTimes(this.StopTime)
    if(this.TaskArray){
      for(let i of this.TaskArray[1].tbTimesheet_Tasks.Data){
        if(i.TimesheetTaskID == this.Task){
          this.convertedTask = i.TimesheetTaskName 
        }
    }
  }
    this.selectedOption = this.convertedTask
    this.chosenStartTime = this.formattedStartTime
    this.chosenStopTime = this.formattedStopTime
  }

  formatTimes(dateTimeString){    
    const dateTime = new Date(dateTimeString);

    const hours = dateTime.getUTCHours().toString().padStart(2, '0');
    const minutes = dateTime.getUTCMinutes().toString().padStart(2, '0');
    
    const timeIn24HourFormat = `${hours}:${minutes}`;
    return timeIn24HourFormat
  }

  formatTimeForUpload(timeString,time){
    const [hours, minutes] = timeString.split(":").map(Number);
    let dateTime = new Date(this.StartTime);
    if(time == "stop"){
      dateTime = new Date(this.StopTime);
    }
    dateTime.setHours(hours);
    dateTime.setMinutes(minutes);
    const dbDate = this.datetimeService.localDateToDBDateStr(
      dateTime
    );
    return dbDate
  }

  editTask(){
    this.editClicked = true
  }

  

  async saveTask(){
    this.editClicked = true
   let dbStartTime = this.formatTimeForUpload(this.chosenStartTime, "start")
   let dbStopTime = this.formatTimeForUpload(this.chosenStopTime, "stop")
   this.convertedTask = this.selectedOption
   let taskID
   if(this.TaskArray){
    for(let i of this.TaskArray[1].tbTimesheet_Tasks.Data){
      if(i.TimesheetTaskName == this.selectedOption){
        taskID = i.TimesheetTaskID 
      }
    }
  }

    let editedTask = {
      entryID: this.EntryID ,
      startTime: dbStartTime,
      endTime: dbStopTime,
      taskID: taskID
    }
    let upload = await this.punchClockService.uploadEditedTask(editedTask)
    this.editClicked = false

    
  }
}
