import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProgressBarService } from 'src/app/modules/shared/progress-bar/progress-bar.service';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { AutologExplanationModalComponent } from '../../autolog-explaination-modal/autolog-explanation-modal.component';
import { SnackbarType } from 'src/app/modules/shared/snackbar/snackbar/snackbar';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/confirmation-dialog/confirmation-dialog.component';
import { SearchField } from 'src/app/modules/core/models/SearchField.model';
import { SearchableDropdownComponent } from 'src/app/shared/components/inputs/searchable-dropdown/searchable-dropdown.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-edit-utilities-modal',
  templateUrl: './edit-utilities-modal.component.html',
  imports: [SearchableDropdownComponent, CommonModule, FormsModule, MatDialogModule],
  standalone: true,
})
export class EditUtilitiesModalComponent implements OnInit {
  data: object;
  options: object[];
  searchField: SearchField;
  selectedUtilities: unknown[];
  dropdownOption: unknown;
  formController: FormControl = new FormControl(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: object,
    private progressBarService: ProgressBarService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<EditUtilitiesModalComponent>
  ) {
    this.data = Data;
  }
  ngOnInit(): void {
    const options = this.data['dropdownOptions'];
    this.options = options;
    this.searchField = {
      title: 'Utilities',
      type: 'select',
      choices: options,
      dataTypeID: 4,
      filterID: 1,
    };
    this.selectedUtilities = this.data['selectedUtilities'];
  }

  onConfirm() {
    if (this.selectedUtilities.length == 0) {
      this.snackBarService.openSnackbar('You must select at least one utility', SnackbarType.error);
      return;
    }

    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '450px',
        data: {
          title: 'Sync',
          message: 'By editing the utilities, a sync will be performed.',
        },
      })
      .afterClosed()
      .subscribe((nextVal) => {
        if (nextVal) {
          this.dialog
            .open(AutologExplanationModalComponent, {
              width: '450px',
              data: { header: 'Explain why you are editing the utilities' },
            })
            .afterClosed()
            .subscribe((nextVal) => {
              if (nextVal && nextVal.result.length > 0) {
                this.dialogRef.close({
                  event: 'close',
                  result: { utilities: this.selectedUtilities, explanation: nextVal },
                });
              } else {
                this.snackBarService.openSnackbar('No changes made', SnackbarType.warning);
              }
            });
        } else {
          this.snackBarService.openSnackbar('No changes made', SnackbarType.warning);
        }
      });
  }

  onClose() {
    this.progressBarService.stop();
    this.dialogRef.close();
  }
}
