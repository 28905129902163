import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomIconService } from './shared/services/custom-icon/custom-icon.service';
import { UpdateService } from './modules/core/services/update.service';
// app.js
import { setDefaultOptions } from 'esri-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  routerSubscriber: Subscription;
  userServiceSubscriber: Subscription;
  // progress bar variables
  STR_INPROGRESS = 'indeterminate';
  STR_FINISHED = 'determinate';
  LOADING_BAR_STATUS: string = this.STR_FINISHED;
  LOADING_BAR_COLOR = 'primary';
  theme = '';
  navDisplayStyle = 'none';

  showTopNav: boolean = false;
  showSideNav: boolean = false;

  constructor(
    private swUpdate: UpdateService,
    private iconService: CustomIconService
  ) {
    // Always register icons and set default options, regardless of iframe status
    this.iconService.registerIcons().subscribe();
    setDefaultOptions({ version: '4.30' });
  }
}
