import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HeaderOptions,
  RouteOptions,
} from "src/app/modules/shared/page-navigation/page-nav-options";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { Algorithm, RuleType } from "../../algorithm";
import { RoutingPresetService } from "../../routing-preset.service";

@Component({
  selector: "app-routing-preset-page",
  templateUrl: "./routing-preset-page.component.html",
})
export class RoutingPresetPageComponent implements OnInit {
  algorithms: Algorithm[] = [];
  ruleTypes: RuleType[] = [];

  routes: RouteOptions[] = [];

  header: HeaderOptions;

  reloadAlgorithmsSubscription;

  constructor(
    private snackBarService: SnackbarService,
    private presetService: RoutingPresetService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  /**
   * redirect to default algorithm
   */
  ngOnInit(): void {
    let newQueryParams = this.cleanURLQueryParams();
    this.router.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });

    this.header = {
      title: "Algorithms",
      btnIcon: "add",
      btnAction: this.createAlgorithm,
    };

    this.reloadAlgorithmsSubscription = this.presetService
      .getReloadAlgorithms()
      .subscribe((algorithmID) => {
        this.reloadAlgorithms();
      });
  }

  /**
   * unsubscribe from algorithm subscription
   */
  ngOnDestroy() {
    this.reloadAlgorithmsSubscription.unsubscribe();
  }

  /**
   * if visible url keys isn't empty, update params
   * @returns updated query paramters
   */
  private cleanURLQueryParams(): object {
    let queryParams: any = this.route.snapshot.queryParams;
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};
    try {
      let visisbleURLKeys: any =
        this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      let len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        let queryParamsKey = queryParamsKeys[i];
        if (
          visisbleURLKeys &&
          visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
        ) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }

      return newQueryParams;
    } catch (error) {
      console.error(error);
    }
    return newQueryParams;
  }

  /**
   * set up list of algorithms available to navigate to
   */
  setupSideNav() {
    this.routes = [];
    for (let algorithm of this.algorithms) {
      this.routes.push({
        path: algorithm.AlgorithmID.toString(),
        label: algorithm.AlgorithmName,
        checked: algorithm.isDefault == 1,
      });
    }
    // console.log("routes:", this.routes);
  }

  /**
   * call getAlgorithms and navigate to default
   */
  async reloadAlgorithms() {
    await this.getAlgorithms();
    let defaultAlgoithmID = this.getDefaultAlgorithm();
    if (defaultAlgoithmID) {
      this.router.navigate([defaultAlgoithmID], { relativeTo: this.route });
    }
  }

  /**
   * call preset service to create a new algorithm and then navigate to them
   */
  createAlgorithm = () => {
    this.presetService.createAlgorithm().subscribe((result) => {
      if (result && result.body) {
        if (result.body.Error) {
          this.snackBarService.openSnackbar(
            result.body.Error,
            SnackbarType.error
          );
        } else if (result.body.Value && result.body.Value.AlgorithmID) {
          this.getAlgorithms();
          this.router.navigate([result.body.Value.AlgorithmID], {
            relativeTo: this.route,
          });
        } else {
          this.snackBarService.openSnackbar(
            "Failed to Create Algorithm",
            SnackbarType.error
          );
        }
      }
    });
  };

  /**
   *  call preset service to load all algorithms, then call to setup nav list
   */
  async getAlgorithms() {
    let result = await this.presetService.getAlgorithms().toPromise();
    if (result && result.body) {
      if (result.body.Error) {
        this.snackBarService.openSnackbar(
          result.body.Error,
          SnackbarType.error
        );
      } else if (result.body.Value) {
        this.ruleTypes = result.body.Value.RuleTypes;
        this.algorithms = result.body.Value.Algorithms;
        // console.log(this.algorithms);

        this.setupSideNav();
      } else {
        this.snackBarService.openSnackbar(
          "Failed to get Algorithm",
          SnackbarType.error
        );
      }
    }
  }

  /**
   * get the default algorithm
   * @returns id of default algorithm
   */
  getDefaultAlgorithm(): number {
    if (this.algorithms.length > 0) {
      let defaultAlgoithmID = this.algorithms[0].AlgorithmID;
      for (let i in this.algorithms) {
        if (this.algorithms[i].isDefault) {
          defaultAlgoithmID = this.algorithms[i].AlgorithmID;
        }
      }
      return defaultAlgoithmID;
    }
    return null;
  }
}
