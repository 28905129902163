import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ISO_LOCALE_LIST } from "src/assets/locale-files/languages";
import { LocaleService } from "../../core/services/locale/locale.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { pdfOptions } from "../pdfOptions";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  className = "PdfViewerComponent";
  constructor(private logger: LoggerService, private locale$: LocaleService) {}

  @Input() pdfOptions: pdfOptions;
  //defaults
  isBase64 = false;
  data = "";
  pdfHeight = "100%";
  showHandToolButton = false;
  showOpenFileButton = false;
  showBookmarkButton = false;
  showSidebarButton = false;
  showFindButton = false;
  language: ISO_LOCALE_LIST = ISO_LOCALE_LIST["en-CA"];

  /**
   * set up locale subscription and class variables using pdfOptions
   */
  ngOnInit(): void {
    this.locale$.getLocaleSubscription().subscribe((newLocale) => {
      if (newLocale.localeName) {
        this.language = newLocale.localeName;
      }
    });

    this.isBase64 = this.pdfOptions.isBase64;
    this.data = this.pdfOptions.data;
    this.pdfHeight = this.pdfOptions.height;
    this.showHandToolButton =
      typeof this.pdfOptions.showHandToolButton !== "undefined"
        ? this.pdfOptions.showHandToolButton
        : this.showHandToolButton;
    this.showOpenFileButton =
      typeof this.pdfOptions.showOpenFileButton !== "undefined"
        ? this.pdfOptions.showOpenFileButton
        : this.showOpenFileButton;
    this.showBookmarkButton =
      typeof this.pdfOptions.showBookmarkButton !== "undefined"
        ? this.pdfOptions.showBookmarkButton
        : this.showBookmarkButton;
    this.showFindButton =
      typeof this.pdfOptions.showFindButton !== "undefined"
        ? this.pdfOptions.showFindButton
        : this.showFindButton;
    this.showSidebarButton =
      typeof this.pdfOptions.showSidebarButton !== "undefined"
        ? this.pdfOptions.showSidebarButton
        : this.showSidebarButton;
  }

  /**
   * update all class varaibles based on pdfOptions input
   * @param changes changes to data
   */
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["pdfOptions"] &&
        changes["pdfOptions"]["currentValue"] &&
        changes["pdfOptions"]["currentValue"]
      ) {
        this.pdfOptions = changes["pdfOptions"]["currentValue"];
        this.isBase64 = this.pdfOptions.isBase64;
        this.data = this.pdfOptions.data;
        this.pdfHeight = this.pdfOptions.height;
        this.showHandToolButton =
          typeof this.pdfOptions.showHandToolButton !== "undefined"
            ? this.pdfOptions.showHandToolButton
            : this.showHandToolButton;
        this.showOpenFileButton =
          typeof this.pdfOptions.showOpenFileButton !== "undefined"
            ? this.pdfOptions.showOpenFileButton
            : this.showOpenFileButton;
        this.showBookmarkButton =
          typeof this.pdfOptions.showBookmarkButton !== "undefined"
            ? this.pdfOptions.showBookmarkButton
            : this.showBookmarkButton;
        this.showFindButton =
          typeof this.pdfOptions.showFindButton !== "undefined"
            ? this.pdfOptions.showFindButton
            : this.showFindButton;
        this.showSidebarButton =
          typeof this.pdfOptions.showSidebarButton !== "undefined"
            ? this.pdfOptions.showSidebarButton
            : this.showSidebarButton;
      }
    } catch (error) {
      this.logger.error(error);
    }
  }
}
