export class Setting {
  SettingID: number;
  Active: boolean;
  Value: string;
  SettingCategoryID?: number;

  constructor(SettingID: number, Active: boolean, Value: string, SettingCategoryID: number = null) {
    this.SettingID = SettingID;
    this.Active = Active;
    this.Value = Value;
    if (SettingCategoryID) this.SettingCategoryID = SettingCategoryID;
  }

  isActive(): boolean {
    return this.Active;
  }

  getValue(): any {
    return this.Value;
  }
}

export const AutologID = {
  LocateOngoing: '14',
  MarkedAsCompleted: '20',
  ASSISTANCE_NEEDED: '12',
  UnCompleted: '36',
  Unlocatable: '61',
  UnlocatableVerified: '73',
  RemovedDoc: '5',
  AddedDoc: '4',
};

export enum SettingID {
  SHOW_DRAWING_FORM = 8,
  LSP_SETTING_ID = 24,
  CLEAR_LSP_DROPDOWN_LIST = 35,
  ADD_REMOVE_UTILITIES = 38,
  RENEGOTIATE_DATE = 50,
  ENABLE_LOCATOR_ROUTING = 107,
  DISABLE_LATE_COLOURING_FOR_LOCATORS = 110,
  ALLOW_COMPLETE = 116,
  VIEW_AUTOLOGS_UTILITY_USER = 127,
  ALLOW_COMPLETE_UNLOCATABLE_VERIFY = 135,
  PHOTO_REQUIRED = 129,
  ALLOW_CREATE_AUDIT_TICKETS = 112,
  ENABLE_QUICK_TICKET_ACTIONS = 139,
  ROUTING_TICKET_CLUSTER_PRIORITY  = 160,
  TICKET_ROUTING_CLUSTER_SIZE = 163,
  TICKET_ROUTING_DAILY_LIMIT = 164,
  ESRI_MAP_AVAILABLE = 181,
  ESRI_MAP_DEFAULT = 182,
  HIGH_PROFILE = 183,
  UTILITIES_DETERMINE_ESRI_LAYERS = 190,
  LOADING_DOCUMENT_FROM_S3 = 194,
  ESRI_MAP_LINK_TO_U4_AVAILABLE = 200,
  EDIT_TICKET_ADDITIONAL = 227,
  EDIT_PRIVATE_TICKET = 214,
  REASSIGN_TICKET = 212,
  SCHEDULER = 216,
  CALL_EXCAVATOR = 217,
  SHOW_PUNCH_CLOCK = 228,
  MULTI_CONTACT_EXCAVATOR = 231,
  ENABLES_SLS_EMAIL_SYSTEM = 154,
  KM_FOR_PROXIMITY = 234,
  APP_FORM_TABS_SHOWING = 246,
  CREATE_TICKET = 248,
  GENERAL_SETTINGS = 260,
  ESRI_SHOW_AUX_FAB_OPTIONS = 266,
  ROUTING_PRESETS = 267,
  USER_MANAGER = 268,
  SYSTEM_MAINTENANCE = 269,
  MODIFY_PASSWORD = 272,
  RATES = 273,
  EDIT_COMPLETED = 278,
  S3_BUCKET_NAME = 286,
  S3_REGION_NAME = 287,
  TICKET_MAP_SERVICE_OPENLAYERS = 288,
  SHOW_TAKE_AUX_PHOTO_BUTTON = 284,
  SATELLITE_LAYER_ON = 290,
  USER_CENTRALIZATION = 291,
  RULE_BUILDER = 292,
  TICKET_SUMMARY = 300,
  INVOICING_MANAGER = 301,
  CATEGORY_MANAGER_ACCESS = 304,
  MODIFY_DISPATCH_AREAS = 306,
  HOME_WORKSPACE = 308,
  DELETE_DOCUMENTS = 309,
  LOCATE_AREA_BBOX = 311,
  LEADHAND_PANEL = 315,
  DISPATCH_AREA_ROUTING_MANAGEMENT = 316,
  CANCEL_TICKET = 318,
  COPY_TICKET = 319,
  SET_APPOINTMENT_DATE = 320,
  DOWNLOAD_ALL_DOCS = 321,
  EMAIL_DOCS = 322,
  TAKE_PHOTO = 323,
  ADD_DOC = 324,
  UNCOMPLETE_TICKET = 325,
  REPORTS_TAB_ACCESS = 326,
  CAPTURE_REQUIRES_TITLE_OR_DESCRIPTION = 329,
  TICKET_TAGS = 330

}
