<div class="w-full h-full select-none">
  <div class="grid grid-cols-2 sm:grid-cols-3">
    <div *ngFor="let doc of docs; let i = index" class="p-[12px] relative">
      <mat-icon *ngIf="doc.selected" class="mat-medium-icons blue absolute bottom-3 right-1">check_circle</mat-icon>
    
      <ng-container *ngIf="doc.file; else noFile">
        <ng-container *ngIf="isPDF(doc.FileName); else showImage">
          <mat-icon class="w-full h-auto red-color" svgIcon="pdf"></mat-icon>
        </ng-container>
        
        <ng-template #showImage>
          <ng-container *ngIf="isDBFile(doc.FileName); else image">
            <mat-icon class="w-full h-auto blue" svgIcon="reports"></mat-icon>
          </ng-container>
          <ng-template #image> 
            <img
            class="w-full h-auto"
            tabindex="{{ i }}"
            alt="{{ doc.FileName }}"
            [src]="doc.file"
            (mousedown)="onMouseDown()"
            (mouseup)="onMouseUp()"
            (click)="isHeldDown ? selectedMode(doc) : documentSelected(doc)" />           
          </ng-template>
         
        </ng-template>
      </ng-container>
    
      <ng-template #noFile>
        <ng-container *ngIf="isPDF(doc.FileName); else showNoImage">
          <mat-icon class="w-full h-auto red-color" svgIcon="pdf"></mat-icon>
        </ng-container>
        
        <ng-template #showNoImage>
        <mat-icon class="w-full h-auto blue" svgIcon="photo"></mat-icon>
      </ng-template>
      </ng-template>
    </div>
  </div>
</div>
