import { Component, Inject } from "@angular/core";
import { SnackbarType } from "./snackbar";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }
  


  getIcon(): string {
    switch (this.data.snackType) {
      case SnackbarType.success:
        return 'yes-filled';
      case SnackbarType.error:
        return 'lightning-bolt';
      case SnackbarType.warning:
        return 'warning';
      case SnackbarType.info:
        return 'info';
        case SnackbarType.default:
      return '';
    };
  }

  handleActionClick(): void {
    // Implement your action click logic here
    console.log('Action clicked!');
  }

  getButtonTextColor(): string {
    return this.data.actionLabelColour || '#000000'; // Use the color of data.message or a default color
  }
}
