<div class="flex flex-col items-start justify-between">
  <div class="h-12 flex flex-row justify-between items-center text-headline-6 uppercase font-rajdhani font-semibold">
    <div class="pr-2">Settings</div>
    <!-- Add/Remove settings only if they can remove settings -->
    <button *ngIf="accessLevel <= ModifyType.Delete" mat-icon-button (click)="toggleEditMode()">
      <mat-icon class="text-sm" svgIcon="edit" [class.text-success]="editState"></mat-icon>
    </button>
  </div>

  <div class="flex flex-row items-start justify-start w-full space-x-14 dense-6">
    <div class="w-96" *ngIf="displayGroups.length > 0 && showTable; else noSettings">
      <ng-container *ngFor="let group of displayGroups">
        <!-- If this group has settings to show -->

        <!-- Settings Table for the Group -->
        <table
          mat-table
          [dataSource]="group.DisplaySetting"
          class="mb-4 border border-divider border-solid rounded-md overflow-hidden sm:rounded-lg">
          <!-- Setting Name Column -->
          <ng-container matColumnDef="settingName">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="font-semibold text-lg uppercase font-rajdhani"
              [ngClass]="{ 'border-b-0': group.DisplaySetting && group.DisplaySetting.length === 0 }">
              {{ group.Name }}
            </th>
            <td mat-cell class="font-rajdhani uppercase font-semibold text-sm" *matCellDef="let setting">
              {{ setting.Name }}
            </td>
          </ng-container>

          <!-- Setting Value Column -->
          <ng-container matColumnDef="settingValue">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text-right"
              [ngClass]="{ 'border-b-0': group.DisplaySetting && group.DisplaySetting.length === 0 }">
              <div *ngIf="group.Editable; else showActiveState">
                <div class="flex flex-row items-center justify-end" *ngIf="!editState; else editButtons">
                  <label class="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      [checked]="group.Active === 1 ? true : false"
                      (change)="toggleGroupActive(group.GroupID)"
                      class="sr-only peer" />
                    <div
                      class="relative w-11 h-6 bg-gray-200 outline-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
                  </label>

                  <!-- Button for more menu options-->
                  <div *ngIf="menuOptions && menuOptions.length > 0">
                    <!-- more icoin -->
                    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <!-- iterate over the possible functions -->
                      <button
                        mat-menu-item
                        matTooltip="{{ option.tooltip }}"
                        [disabled]="option.disabled"
                        *ngFor="let option of menuOptions"
                        (click)="option.function ? option.function(group) : null">
                        {{ option.title }}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </div>

              <!-- Just state whether the setting is enabled for the user or not  -->
              <ng-template #showActiveState>
                {{group.Active ? 'Enabled' : 'Disabled'}}
              </ng-template>
              

              <!-- Remove Setting Button (conditional) -->
              <ng-template #editButtons>
                <button
                  mat-icon-button
                  (click)="removeSettingGroup(group.GroupID)"
                  *ngIf="accessLevel <= ModifyType.Add">
                  <mat-icon class="text-warn" svgIcon="minus"></mat-icon>
                </button>
              </ng-template>
            </th>
            <td
              mat-cell
              class="h-12 font-rajdhani uppercase font-semibold text-sm"
              *matCellDef="let setting"
              class="text-right">
              <!-- If the setting is a slider  -->
              <ng-container *ngIf="setting.Type?.Type === 'slider'">
                <div
                  class="flex flex-row-reverse items-center justify-start font-rajdhani text-sm font-semibold uppercase">
                  <div class="pl-2">{{ setting.DefaultValue }}</div>
                  <mat-slider
                    [max]="setting.Type.Max"
                    [min]="0"
                    [step]="setting.Type.Step"
                    [disabled]="!(accessLevel <= ModifyType.Add) || !group.Editable"
                    (change)="updateSettings(setting)">
                    <input matSliderThumb [(ngModel)]="setting.DefaultValue" [name]="setting.Name" />
                  </mat-slider>
                </div>
              </ng-container>

              <!-- if the setting is a drop down select  -->
              <ng-container *ngIf="setting.Type?.Type === 'select'">
                <mat-form-field
                  class="my-1 font-rajdhani text-sm font-semibold uppercase"
                  appearance="outline"
                  subscriptSizing="dynamic">
                  <mat-select
                    [(ngModel)]="setting.DefaultValue"
                    [name]="setting.Name"
                    [multiple]="setting.Type.Multiselect ?? false"
                    [disabled]="!(accessLevel <= ModifyType.Add) || !group.Editable"
                    (selectionChange)="updateSettings(setting)">
                    <mat-option
                      class="font-rajdhani text-sm font-semibold uppercase"
                      *ngFor="let option of setting.Type.Options"
                      [value]="option.value.toString()">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="setting.Type?.Type !== 'slider' && setting.Type?.Type !== 'select'">
                {{ setting.DefaultValue }}
              </ng-container>
            </td>
          </ng-container>

          <!-- Table Rows -->
          <tr mat-header-row *matHeaderRowDef="['settingName', 'settingValue']"></tr>
          <div *ngIf="group.DisplaySetting && group.DisplaySetting && group.DisplaySetting.length > 0">
            <tr mat-row *matRowDef="let setting; columns: ['settingName', 'settingValue']; let i = index"></tr>
          </div>
        </table>
      </ng-container>
    </div>
    <ng-template #noSettings>
      <div class="font-montserrat text-sm w-96">No Settings</div>
    </ng-template>

    <!-- user can only edit settings if they can remove -->
    <div class="w-96" *ngIf="editState && accessLevel <= ModifyType.Delete">
      <!-- All settings table -->
      <table
        mat-table
        [dataSource]="filteredSettings"
        class="mb-4 border border-solid border-divider rounded-md overflow-hidden">
        <!-- Header row -->
        <ng-container matColumnDef="header">
          <th mat-header-cell *matHeaderCellDef colspan="2" class="font-semibold text-lg uppercase font-rajdhani">
            <div class="items-start flex flex-col py-2">
              <div class="pb-2">ALL SETTINGS</div>
              <app-competers-search-bar
                class="h-9 font-normal"
                (searchValueChange)="searchChangedEvent($event)"></app-competers-search-bar>
            </div>
          </th>
        </ng-container>

        <!-- Data row -->
        <ng-container matColumnDef="settingName">
          <td mat-cell class="font-rajdhani uppercase font-semibold text-lg" *matCellDef="let setting">
            {{ setting.Name }}
          </td>
        </ng-container>

        <!-- Add Setting Column -->
        <ng-container matColumnDef="addSetting">
          <td mat-cell *matCellDef="let setting" class="text-right">
            <button mat-icon-button (click)="addSettingGroup(setting.GroupID)">
              <mat-icon class="text-success" svgIcon="add"></mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Define header row -->
        <tr mat-header-row *matHeaderRowDef="['header']"></tr>
        <!-- Define data rows -->
        <tr mat-row *matRowDef="let setting; columns: ['settingName', 'addSetting']; let i = index"></tr>
      </table>
    </div>
  </div>
</div>
