import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { NavBarConfig } from "src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component";


@Injectable({
  providedIn: "root",
})
export class NavBarService {
  // private currentURL: BehaviorSubject<boolean>;
  //TODO ADD CLOSE NAV HERE
  // https://stackblitz.com/edit/dynamic-nested-sidenav-menu?file=app%2Fmenu-list-item%2Fmenu-list-item.component.ts
  private navBarPageChanges: Subject<any>;
  private innerNavChanges: Subject<any>;
  private currentNavBar: NavBarConfig;
  filterBtnClickSubject: Subject<any> = new BehaviorSubject(null);
  filterBtnClick$: Observable<any> = this.filterBtnClickSubject.asObservable();

  constructor() {
    this.navBarPageChanges = new Subject<any>();
    this.innerNavChanges = new Subject<any>();
  }

  // progress bar methods
  getNavBarChanges(): Observable<any> {
    return this.navBarPageChanges.asObservable();
  }

  /**
   * Updates the navbar's current configuration.
   * Retains the old sync if no new sync is given
   * @param newConfig 
   * @returns 
   */
  updateNavBar(newConfig: NavBarConfig) {

    //retain the old sync 
    if (!newConfig.onSyncClick) {
      newConfig.onSyncClick = this.currentNavBar.onSyncClick;
    }
    this.currentNavBar = newConfig;

    this.navBarPageChanges.next(newConfig);
    return this;
  }

  getCurrentNavBar(): NavBarConfig {
    return this.currentNavBar;
  }

  updateInnerNavTitles(titles: any) {
    this.innerNavChanges.next(titles);
    return true;
  }

  getInnerNavChanges(): Observable<any> {
    return this.innerNavChanges.asObservable();
  }

  // getCurrentURL() {
  //   this.currentURL.next("urlcurrent");
  // }
}
