import { inject, Injectable } from '@angular/core';
import { api, apiKeys } from '../../../ENDPOINTS';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class DrawingAPIService {

  // services
  private apiService = inject(ApiService);

  constructor() {}

  public async sendRequest(query: Record<string, any>) {
    const url = apiKeys.u2.mapCaptures;
    const type = api[url].type;
    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.mapCaptures,
      API_TYPE: type,
      API_BODY: query,
    };
    return await this.apiService.invokeUtilocateApi(utilocateApiRequest);
  }
}
