import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger/logger.service';
import { User } from '../services/user/user';
import { UtilocateApiService } from '../api/utilocateapi.service';
import { UtilocateTokenService } from '../services/token/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilocateAdminCacheService } from '../cache/utilocate-admin.service';
import { ADMIN_TABLE_NAMES } from '../admin/tables';
import { AdminLookupService } from '../admin/admin-lookup.service';
import { CacheWhereClauseType } from '../cache/cache.interface';
import { localStorageKeys } from '../../../LOCAL_STORAGE';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

/**
 * AuthenticationService
 * authenticates login information
 * sets and gets tokens
 */
export class AuthenticationService {
  private jwtHelper: JwtHelperService = new JwtHelperService();
  tokenPayload: any = null;
  private _isAuthenticated$: BehaviorSubject<boolean>;

  constructor(
    private logger: LoggerService,
    private tokenService: UtilocateTokenService,
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private adminLookup: AdminLookupService,
    private utilocateApiService: UtilocateApiService
  ) {
    this._isAuthenticated$ = new BehaviorSubject<boolean>(this.isAuthenticated());
  }

  /**
   * call api to generate auth token for login
   * @param username email associated with user
   * @param password password field
   * @returns true if token is successfully generated, else false
   */
  async authenticate(username: string, password: string): Promise<boolean> {
    const apiResult: any = await this.utilocateApiService.generateTokenU4OfficeSide(username, password);

    if (
      apiResult.status == 200 &&
      JSON.parse(apiResult.body.value)['result'][0].toLowerCase().indexOf('failed') == -1
    ) {
      const token = JSON.parse(apiResult.body.value)['result'][0];
      this.tokenService.setToken(token);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Logs current user out and resets local storage/cache
   */
  logout() {
    this._isAuthenticated$.next(false);
    try {
      this.tokenService.destroyToken();
      // sessionStorage.clear();
      // localStorage.clear();
      // below call clears session/local storage and indexeddb but cache service will change
      this.utilocateAdminCacheService.clear();
    } catch (error) {
      this.logger.error(error);
    }
  }

  clearAdminCache() {
    this.utilocateAdminCacheService.clear();
  }

  /**
   * Checks if user is authenticated
   * @returns boolean
   */
  isAuthenticated(token?: string): boolean {
    let isAuthenticated: boolean = true;
    try {
      if (token) {
        isAuthenticated = !this.jwtHelper.isTokenExpired(token);
      } else {
        const token: any = localStorage.getItem(localStorageKeys.TOKEN_KEY);
        isAuthenticated = !this.jwtHelper.isTokenExpired(token);
      }
      return isAuthenticated;
    } catch (error) {
      this.logger.error(error);
    }
    return isAuthenticated;
  }

  /**
   * get user info from admin tables using token
   * @returns a User variable with readable info
   */
  async getReadableUserInfo(): Promise<User> {
    const readableUserData: User = {
      id: '-1',
      firstName: 'Utilocate',
      lastName: 'ComPeters',
      category: 'Manager',
      categoryID: -1,
    };

    try {
      if (
        !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbLogin_Users)) ||
        !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbLogin_UserCategories))
      ) {
        await this.adminLookup.refreshLookupTables([
          ADMIN_TABLE_NAMES.tbLogin_Users,
          ADMIN_TABLE_NAMES.tbLogin_UserCategories,
        ]);
      }
      const tokenU2UserID = this.tokenService.getValueFromToken('LOCALUSERID')
        ? this.tokenService.getValueFromToken('LOCALUSERID')
        : this.tokenService.getValueFromToken('USERID');

      const userResult: object[] | Error = await this.utilocateAdminCacheService.queryTable(
        ADMIN_TABLE_NAMES.tbLogin_Users,
        [
          {
            Column: 'UserID',
            Value: tokenU2UserID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ]
      );
      const userCatIDResult: object[] | Error = await this.utilocateAdminCacheService.queryTable(
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
        [
          {
            Column: 'UserCategoyID',
            Value: this.tokenService.getValueFromToken('USERCATEGORYID'),
          },
        ]
      );

      if (!(userResult instanceof Error) && !(userCatIDResult instanceof Error)) {
        const user = userResult[0];
        const userCatID = userCatIDResult[0];

        const firstName = user['FirstName'];
        const lastName = user['LastName'];
        const userCatIDDesc = userCatID['Description'];

        readableUserData.id = tokenU2UserID;
        readableUserData.firstName = firstName;
        readableUserData.lastName = lastName;
        readableUserData.category = userCatIDDesc;
        readableUserData.categoryID = userCatID['UserCategoyID'];
        // readableUserData.UserCategoryDesc = userCatIDDesc;

        return readableUserData;
      } else {
        throw userResult instanceof Error ? userResult : userCatIDResult;
      }
    } catch (error) {
      return error;
    }
  }

  /**
   * calls token service to get token from session storage
   * @returns token from token service
   */
  getToken() {
    return this.tokenService.getToken();
  }

  /**
   * decode the token via token service and get version value from payload
   * @returns version value
   */
  getVersion() {
    try {
      const payload = this.tokenService.getJwtPayload(this.getToken());
      const versionKey = 'VERSION';
      if (payload) {
        if (payload[versionKey]) {
          return payload[versionKey];
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error('get Version Failed: ' + error.message);
      return false;
    }
  }

  /**
   * get a value from the token with a specified key
   * @param key desired value
   * @returns value if key is found, else null
   */
  getValueFromPayload(key: string) {
    let value = null;
    const payload = this.tokenService.getJwtPayload(this.getToken());
    if (payload && payload[key.toUpperCase()] != null) {
      value = payload[key.toUpperCase()];
    } else if (payload && payload[key] != null) {
      value = payload[key];
    }
    return value;
  }

  /**
   * get a nested value from the token with a specified key
   * @param key desired value
   * @returns value if key is found, else null
   */
  getNestedValueFromPayload(key: string) {
    let value = null;
    const payload = this.tokenService.getJwtPayload(this.getToken());
    let curValue = null;

    if (key) {
      key = key.toUpperCase();
      const path = key.split('.');
      let tempPayload = payload;
      for (let i = 0; i < path.length; i++) {
        curValue = tempPayload[path[i]];
        tempPayload = tempPayload[path[i]];
      }
      if (curValue) {
        value = curValue;
      }
    }
    return value;
  }

  getValueFromUser(key: string) {
    let value = null;
    const _user = this.tokenService.getJwtPayload(this.getToken());
    if (_user && _user[key.toUpperCase()] != null) {
      value = _user[key.toUpperCase()];
    } else if (_user && _user[key] != null) {
      value = _user[key];
    }
    return value;
  }

  get isAuthenticated$() {
    return this._isAuthenticated$.pipe();
  }
}
