import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FloatingActionMenuComponent } from '../../shared/fab/floating-action-menu/floating-action-menu.component';
import { CaptureFrameComponent } from '../drawing-capture/capture-frame.component';
import { DrawingToolbarComponent } from '../drawing-toolbar/drawing-toolbar.component';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatIconButton } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchableDropdownComponent } from '../../../shared/components/inputs/searchable-dropdown/searchable-dropdown.component';
import { DrawingCanvasService } from './drawing-canvas.service';
import { Feature } from 'ol';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { DrawingSwitcherComponent } from './drawing-switcher/drawing-switcher.component';

@Component({
  selector: 'app-drawing-canvas',
  standalone: true,
  imports: [
    CommonModule,
    CaptureFrameComponent,
    DrawingToolbarComponent,
    FloatingActionMenuComponent,
    MatDivider,
    MatIcon,
    MatIconButton,
    ReactiveFormsModule,
    FormsModule,
    SearchableDropdownComponent,
    DrawingSwitcherComponent,
  ],
  template: `
    <div class="relative flex h-full w-full">
      <div #map class="map h-full w-full"></div>
    </div>
  `,
})
export class DrawingCanvasComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('map') mapTarget: ElementRef;
  // IO
  @Input() isActive: boolean = true;
  @Input() drawing: Feature[];
  @Input() image: HTMLImageElement;
  @Output() mapTargetRendered = new EventEmitter<HTMLDivElement>();

  // services
  protected myService = inject(DrawingCanvasService);

  // observables
  private destroy$ = new Subject<void>();

  // members
  constructor() {}

  ngOnInit() {
    if (this.image.complete) {
      this.myService.setupDrawing(this.image, this.drawing ?? []);
    }
    fromEvent(this.image, 'load')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.myService.setupDrawing(this.image, this.drawing ?? []);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticket && changes.ticket.currentValue) {
      this.myService.activeDrawingID = null;
    }
    if (changes.drawing || changes.image) {
      this.myService.setupDrawing(this.image, this.drawing ?? []);
    }
  }

  ngAfterViewInit() {
    this.mapTargetRendered.emit(this.mapTarget.nativeElement);
  }

  ngOnDestroy() {
    this.myService.tidy();
  }

  get features(): Array<Feature> {
    return this.myService.drawing;
  }
}
