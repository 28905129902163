<h1 mat-dialog-title class="font-rajdhani uppercase font-semibold text-headline-5">Email Sendable Document</h1>
<div mat-dialog-content fxLayout="column" class="body">
  <mat-radio-group [(ngModel)]="disabled" fxLayout="column" class="radiobtns" aria-label="Select an option">
    <mat-radio-button (click)="radioClicked()" class="radiobtn1" value="false">Use current email</mat-radio-button>
    <mat-radio-button (click)="radioClicked2()" value="true">Use new email</mat-radio-button>
  </mat-radio-group>

  <mat-form-field appearance="fill">
    <mat-label>Enter the email</mat-label>
    <input matInput (keyup)="onChange()" placeholder="pat@example.com" [formControl]="email" />
    <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex flex-row justify-between">
  <button class="btn-cancel" (click)="onNoClick()">Cancel</button>
  <button class="btn-save" [disabled]="btnDisabled" [mat-dialog-close]="{value: email.value}" cdkFocusInitial>Email</button>
</div>
