import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "../../shared/modals/modal.service";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SnackbarType } from "../../shared/snackbar/snackbar/snackbar";
import { ColumnObject } from "../../shared/table/table.module";
import { EditableTableComponent as TableComponent } from "../../shared/table/editable-table/editable-table.component";
import { EditTablesService } from "../edit-tables.service";
import {
  AccessOptions,
  modifyEditTablesBody,
  ModifyType,
  PermissionTypeIDs,
  TableMetadata,
} from "../TableModels";

@Component({
  selector: "app-edit-table",
  templateUrl: "./edit-table.component.html",
  styleUrls: ["./edit-table.component.scss"],
})
export class EditTableComponent implements OnInit {
  @ViewChild("table") table: TableComponent;
  tableID: number;

  visibleName: string;
  tableDescription: string;

  tableArchiveKey: string;
  tablePrimaryKey: string;

  tableData: any[] = [];
  formattedData: any[] = [];
  tableColumns: object = [];
  tableDisplayColumns: ColumnObject[];
  ROW_MODAL_ID = "row-editor-modal-id";

  selectedRow = {};

  accessLevel: AccessOptions = PermissionTypeIDs[4];

  constructor(
    private editTable$: EditTablesService,
    private modalService: ModalService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.tableID = params.tableID;
      // console.log(this.tableID);

      this.setupTable();
    });
  }

  async setupTable() {
    const metadata: TableMetadata = await this.editTable$.getTableMetadata(
      this.tableID
    );
    if (metadata) {
      // console.log('Metadata: ', metadata);
      this.tableArchiveKey = metadata.ArchiveFieldName;
      this.tablePrimaryKey = metadata.PrimaryFieldName;
      this.accessLevel = PermissionTypeIDs[metadata.PermissionTypeID];

      // Set Header values
      this.visibleName = metadata.VisibleName;
      this.tableDescription = metadata.TableDescription;

      // set table values
      await this.refreshTable();
    } else {
      this.snackBarService.openSnackbar(
        "Failed to Get Table Data",
        SnackbarType.error
      );
    }
  }

  async refreshTable() {
    this.progressBarService.start();
    const result: any = await this.editTable$
      .openEditTableById(this.tableID)
      .toPromise();
    const table = JSON.parse(result)[this.tableID];

    // set up done
    this.tableDisplayColumns = this.formatColumns();
    // console.log('Columns: ',this.tableColumns);
    this.formattedData = this.formatData(table.Data);

    this.tableData = table.Data;
    this.progressBarService.stop();
  }

  formatColumns() {
    const formattedColumns = [];
    this.tableColumns = this.editTable$.createColumns(this.tableID);

    for (const key in this.tableColumns) {
      const col = this.tableColumns[key];
      if (col.isVisible == 1) {
        formattedColumns.push({
          VisibleName: col.VisibleName,
          ColumnName: col.ColumnName,
        });
      }
    }
    return formattedColumns;
  }

  formatData(data: any[]): any[] {
    const formattedData = [];

    for (const i in data) {
      const row = {};
      for (const key in data[i]) {
        let value = data[i][key];
        if (
          this.tableColumns[key] &&
          this.tableColumns[key].JoinInstance != null
        ) {
          const options = this.tableColumns[key].JoinInstance.Data;
          const primaryKey =
            this.tableColumns[key].JoinInstance.PrimaryIDColumn;
          const descColumn = this.tableColumns[key].JoinInstance.DescColumn;
          for (const ii in options) {
            if (options[ii][primaryKey] == value) {
              value = options[ii][descColumn];
              break;
            }
          }
        }
        row[key] = value;
      }
      formattedData.push(row);
    }
    return formattedData;
  }

  async saveRow(values: object) {
    this.progressBarService.start();
    const fieldsToSave = {};

    for (const key in this.selectedRow) {
      if (values[key] != null) {
        if (values[key] != this.selectedRow[key]) {
          fieldsToSave[key] = values[key];
        }
      }
    }

    if (Object.keys(fieldsToSave).length > 0) {
      fieldsToSave[this.tablePrimaryKey] =
        this.selectedRow[this.tablePrimaryKey];

      const body: modifyEditTablesBody = {
        PrimaryKeyIDs: [fieldsToSave[this.tablePrimaryKey]],
        Rows: [fieldsToSave],
      };

      const result: any = await this.editTable$
        .modifyEditTables(
          this.tableID,
          ModifyType.Update,
          this.tablePrimaryKey,
          body
        )
        .toPromise();

      const response = JSON.parse(result.body.value);
      if (response.Error == null) {
        this.snackBarService.openSnackbar(
          "Successfully Updated Row",
          SnackbarType.success
        );
      } else {
        this.snackBarService.openSnackbar(
          "Failed to Update Row",
          SnackbarType.error
        );
      }

      await this.refreshTable();
    }

    this.modalService.close(this.ROW_MODAL_ID);
    this.progressBarService.stop();
  }

  async addRow(values: object) {
    this.progressBarService.start();
    const body: modifyEditTablesBody = {
      PrimaryKeyIDs: [],
      Rows: [values],
    };

    const result: any = await this.editTable$
      .modifyEditTables(
        this.tableID,
        ModifyType.Add,
        this.tablePrimaryKey,
        body
      )
      .toPromise();

    const response = JSON.parse(result.body.value);
    console.log(response);

    if (response.Error == null) {
      this.snackBarService.openSnackbar(
        "Successfully Added Row",
        SnackbarType.success
      );
    } else {
      this.snackBarService.openSnackbar(
        "Failed to Add Row",
        SnackbarType.error
      );
    }

    await this.refreshTable();
    this.modalService.close(this.ROW_MODAL_ID);
    this.progressBarService.stop();
  }

  getRow(id: number) {
    for (const i in this.tableData) {
      if (this.tableData[i][this.tablePrimaryKey] == id) {
        return this.tableData[i];
      }
    }
  }

  // onClick Functions

  onEditRow(row: object) {
    if (this.accessLevel.allowEditing) {
      this.selectedRow = this.getRow(row[this.tablePrimaryKey]);
      this.modalService.open(this.ROW_MODAL_ID);
    } else {
      this.snackBarService.openSnackbar(
        "You do not have permission to preform this action",
        SnackbarType.error
      );
    }
  }

  onAddRow() {
    if (this.accessLevel.allowAdding) {
      // make default row
      this.selectedRow = {};
      this.selectedRow[this.tablePrimaryKey] = -1;
      for (const key in this.tableColumns) {
        if (this.tableColumns[key].isVisible) {
          let value = null;
          if (this.tableColumns[key].DataTypeID == 2) {
            value = "";
          } else if (this.tableColumns[key].DataTypeID == 6) {
            value = 0;
          }
          this.selectedRow[key] = value;
        }
      }
      this.modalService.open(this.ROW_MODAL_ID);
    } else {
      this.snackBarService.openSnackbar(
        "You do not have permission to preform this action",
        SnackbarType.error
      );
    }
  }

  async onDeleteRow() {
    if (this.accessLevel.allowDeleting) {
      this.progressBarService.start();
      const selected = this.table.getSelected();
      if (selected.length > 0) {
        const primaryKeyIDs = [];
        for (const i in selected) {
          primaryKeyIDs.push(selected[i][this.tablePrimaryKey]);
        }

        const body: modifyEditTablesBody = {
          PrimaryKeyIDs: primaryKeyIDs,
          Rows: selected,
        };

        const result: any = await this.editTable$
          .modifyEditTables(
            this.tableID,
            ModifyType.Delete,
            this.tablePrimaryKey,
            body
          )
          .toPromise();

        const response = JSON.parse(result.body.value);
        if (response.Error == null) {
          this.snackBarService.openSnackbar(
            "Successfully Deleted Row",
            SnackbarType.success
          );
        } else {
          this.snackBarService.openSnackbar(
            "Failed to Delete Row",
            SnackbarType.error
          );
        }
        await this.refreshTable();
        this.progressBarService.stop();
      }
    } else {
      this.snackBarService.openSnackbar(
        "You do not have permission to preform this action",
        SnackbarType.error
      );
    }
  }

  onCloseRowModal() {
    this.modalService.close(this.ROW_MODAL_ID);
  }

  onSaveRow(values: Object) {
    if (this.selectedRow[this.tablePrimaryKey] > 0) {
      this.saveRow(values);
    } else {
      this.addRow(values);
    }
  }
}
