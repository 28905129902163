import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-scheduler-options-dialog",
  templateUrl: "./scheduler-options-dialog.component.html",
  styleUrls: ["./scheduler-options-dialog.component.css"],
})
export class SchedulerOptionsDialogComponent {
  assignmentID;
  value = null;

  constructor(
    public dialogRef: MatDialogRef<SchedulerOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assignmentID = data.copiedAssignmentID;
  }

  close(): void {
    this.dialogRef.close();
  }

  onSelectionChange(event) {
    this.value = event.value;
  }
}
