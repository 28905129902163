import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

const enum PROGRESS_BAR_STATES {
  start = "indeterminate",
  stop = "determinate",
}

@Injectable({
  providedIn: "root",
})
export class ProgressBarService {
  private subject = new BehaviorSubject<string>(PROGRESS_BAR_STATES.stop);
  mode$: Observable<string> = this.subject.asObservable();

  constructor() {}

  /**
   * Initial is stopped
   */
  initProgressbar() {
    this.stop();
  }

  /**
   * Start progress bar animation
   */
  start() {
    this.subject.next(PROGRESS_BAR_STATES.start);
  }

  /**
   * Stop progress bar animation
   */
  stop() {
    this.subject.next(PROGRESS_BAR_STATES.stop);
  }
}
