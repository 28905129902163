<div
  mat-dialog-content
  style="
    margin: 0;
    padding: 0;
    border: 12px solid white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 177px;
    max-height: 210px;
  "
>
  <div
    class="box-border flex flex-col justify-center items-center max-w-full max-h-full m-0 p-0"
  >
    <img class="max-w-full max-h-full" [src]="url" alt="preview" />
  </div>
</div>
