<div fxFlex #content fxLayout="row" style="height: 100%; width:100%">
  <app-esri-map-measurement
    [hidden]="!measurementOpen"
    [panelOpen]="measurementOpen"
    [mapView]="mapView"
    (panelClose)="toggleMeasurement()"
  >
  </app-esri-map-measurement>

  <app-esri-map-layers
    [hidden]="!layersOpen"
    class="panel"
    [layers]="layerGroups"
    (panelClose)="toggleLayers()"
  >
  </app-esri-map-layers>

  <app-esri-map-legend
    [hidden]="!legendOpen"
    fxFlex="288px"
    fxFlex.lt-sm="100"
    [mapView]="mapView"
    (panelClose)="toggleLegend()"
  >
  </app-esri-map-legend>

  <app-esri-map-basemap
    [hidden]="!basemapOpen"
    class="panel"
    fxFlex="288px"
    fxFlex.lt-sm="100"
    [mapView]="mapView"
  >
  </app-esri-map-basemap>

  <app-base-esri-map
    fxFlex
    [mapOptions]="mapOptions"
    (mapLoaded)="mapLoaded($event)"
  >
  </app-base-esri-map>
</div>
