import Layer from 'ol/layer/Layer';
import LayerGroup from 'ol/layer/Group';
import { BehaviorSubject } from 'rxjs';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { DatabaseLayer } from '../services/layer-manager.service';
import { View } from 'ol';

export class ManagedLayer {
  private readonly layer: Layer | LayerGroup;
  private readonly subLayers: ManagedLayer[];
  private readonly isSelected$: BehaviorSubject<boolean>;
  private view: View;

  constructor(
    layer: Layer | LayerGroup,
    subLayers: ManagedLayer[] | null = [],
  ) {
    this.layer = layer;
    this.subLayers = subLayers || [];
    this.isSelected$ = new BehaviorSubject<boolean>(false);
  }

  getProperties<T extends DatabaseLayer>(): T {
    return this.layer.getProperties() as T;
  }

  getLayer() {
    return this.layer;
  }

  getSource(): VectorSource | Error {
    if (this.layer instanceof VectorLayer) {
      return this.layer.getSource();
    } else {
      return new Error('Cannot get source of non-vector layer');
    }
  }

  getSubLayers(): ManagedLayer[] {
    return this.subLayers;
  }

  setView(view) {
    this.view = view;
    if (this.subLayers) {
      this.subLayers.forEach((layer) => {
        layer.setView(view);
      });
    }
  }

  set visible(visible: boolean) {
    this.layer.setVisible(visible);
  }

  isVisible() {
    return this.layer.getVisible();
  }

  get isSelected(): BehaviorSubject<boolean> {
    return this.isSelected$;
  }
}
