import { Injectable } from '@angular/core';
import { UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { from } from 'rxjs';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { CacheService, StoreType } from 'src/app/modules/core/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class PunchClockService {


  constructor(
    private datetimeService: DatetimeService, 
    private utilocateApiService: UtilocateApiService,
    private userService: UserService,
    private idb: CacheService, 
    ) { }

  async gatherEntries(startTime, endtime) {
    let tbTimesheetEntries
    try {
      const url = apiKeys.u2.getTimesheetEntries
      const type = api[url].type;
      const values = { "StartDate": startTime,"EndDate": endtime  }

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: values
      };

      const response = await from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).toPromise();
      let value = JSON.parse(response.body.value);
      tbTimesheetEntries = value.tbTimesheet_Entries

      return tbTimesheetEntries

  } catch (error) {
      console.error(error);
    }
  }

  async gatherTasks() {
    let value
    let tasks = []
    try {
      const url = apiKeys.u2.timesheetSync;
      const type = api[url].type;
      const values = { "tbTimesheet_Entries": []}

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: values
      };

      const response = await from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).toPromise();
      value = JSON.parse(response.body.value);
      for(let i of value.tbTimesheet_Tasks.Data){
      tasks.push(i.TimesheetTaskName)
      }
      return [tasks, value]
  } catch (error) {
      console.error(error);
    }
  }

  async uploadTask(body) {
    try {
      const url = apiKeys.u2.timesheetSync;
      const type = api[url].type;
      const userID = this.userService.getUserID()
      let completedTaskInfo = {
        TimesheetEntryID: body.entryID,
        StartTime: body.startTime.toString(),
        StopTime: body.endTime.toString(),
        TimesheetTaskID: body.taskID,
        DateCreated: body.endTime.toString(),
        UserID: userID,
        isApproved: 0,
        bFieldAdded: 1,
        bFieldUpdated: 0,
        bFieldRemoved: 0,
        isArchived: 0
      }
      
      const values = { "tbTimesheet_Entries": [completedTaskInfo]}

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: values
      };

      const response = await from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).toPromise();
      let value = JSON.parse(response.body.value);
        
  } catch (error) {
      console.error(error);
    }
  }

  async uploadEditedTask(body) {
    try {
      const url = apiKeys.u2.timesheetSync;
      const type = api[url].type;
      const userID = this.userService.getUserID()
      let completedTaskInfo = {
        TimesheetEntryID: body.entryID,
        StartTime: body.startTime.toString(),
        StopTime: body.endTime.toString(),
        TimesheetTaskID: body.taskID,
        DateCreated: body.endTime.toString(),
        LastModified: this.datetimeService.localDateToDBDateStr(new Date()),
        UserID: userID,
        isApproved: 0,
        bFieldAdded: 0,
        bFieldUpdated: 1,
        bFieldRemoved: 0,
        isArchived: 0
      }
      
      const values = { "tbTimesheet_Entries": [completedTaskInfo]}

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: values
      };

      const response = await from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).toPromise();
      let value = JSON.parse(response.body.value);
      return value  
  } catch (error) {
      console.error(error);
      return error
    }
  }

  async insertPunchTimesToIDB(task) {
    try {
      let insertResult;
      let queryResult = await this.idb.query(
        StoreType.PUNCH_CLOCK,
        "PunchTimes"
      );
      queryResult.push(task)
   
      
        insertResult = await this.idb.insert(
          StoreType.PUNCH_CLOCK,
          "PunchTimes",
          queryResult
        );
      }
     catch (error) {
      console.error(error);
      return false;
    }
  }

  async insertRunningClockToIDB(time) {
    try {
      let insertResult;
      let queryResult = time
        insertResult = await this.idb.insert(
          StoreType.PUNCH_CLOCK,
          "RunningClock",
          queryResult
        );
      }
     catch (error) {
      console.error(error);
      return false;
    }
  }

  
  async clearPunchTimes() {
    try {
      let insertResult;
      insertResult = await this.idb.insert(
        StoreType.PUNCH_CLOCK,
        "PunchTimes",
        []
      );

      return insertResult;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async clearRunningClock() {
    try {
      let insertResult;
      insertResult = await this.idb.clearKey(
        StoreType.PUNCH_CLOCK,
        "RunningClock"
      );
      return insertResult;
     
      
    } catch (error) {
      console.error(error);
      return false;
    }
  }

}
