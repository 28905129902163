<div class="flex flex-grow">
  <!--  sidebar w/ saved searches  -->
  <div class="flex">
    <app-saved-search-drawer data-testid="app-saved-search-drawer"></app-saved-search-drawer>
  </div>
  <!--  adjustable viewport  -->
  <div [ngClass]="{'w-full': !sidebarExpanded, 'w-auto': sidebarExpanded}" class="flex-grow">
    <app-vertical-split class="h-full" [tabs]="_tabs" [selection]="[0]" (resize)="resizeTrigger.next()"
                        [currentTabs]="currentTabView">
      <app-ticket-list-map left></app-ticket-list-map>
      <app-ticket-list-table
        class="z-10 relative"
        right
        [currentTabView]="currentTabView"
        [resizeTrigger]="resizeTrigger"
        [persistSelectionThroughDataChange]="true"
        data-testid="app-ticket-list-table"
        (actionTriggered)="myService.triggerAction($event)">
      </app-ticket-list-table>
    </app-vertical-split>
  </div>
  <!--  overlay and sidebar handling  -->
  <ng-container *ngIf="ticketSearchService.advancedSearchIsOpen$ | async">
    <app-advanced-ticket-search />
  </ng-container>
  <ng-container *ngIf="settingsService.isSettingActive(SettingID.LEADHAND_PANEL)">
    <div [ngClass]="{'absolute right-12 top-9 z-9': !sidebarExpanded, 'relative right-0': sidebarExpanded}">
      <app-sidebar-list (expanded)="sidebarExpanded = $event"></app-sidebar-list>
    </div>

  </ng-container>
</div>
