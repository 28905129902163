export const French = {
  "AcknowledgementViewComponent": {
    "header": "Acceptez-vous ce message?",
    "body1a": "Le titulaire sera :",
    "body1b": "tenu responsable auprès de Pipelines Trans-Nord Inc. de toutes pertes, coûts, dommages et frais que Pipelines Trans-Nord Inc. pourrait subir, assumer, payer ou engager; et :",
    "body1c": "Indemniser et dégager Pipelines Trans-Nord Inc. de toutes actions, procédures, réclamations, demandes, pertes et dépenses ou autres coûts de quelque nature que ce soit qui ont été encourus ou subis par Pipelines Trans-Nord Inc., ou que la société pourrait payer, assumer ou engager à la suite de quelconques raisons, objets ou actions attribuables aux activités du titulaire, de ses sociétés affiliées, administrateurs, dirigeants, employés, agents, invités, entrepreneurs ou sous-traitants dans le cadre de l’exécution des travaux sur l’emprise de Pipelines Trans-Nord Inc. sauf en raison de négligence ou d’une inconduite délibérée de la part de Pipelines Trans-Nord Inc. Ni Pipelines Trans-Nord Inc. ni le titulaire ne sont tenus responsables, qu’ils soient délictuels, contractuels ou non, de tout dommage indirect ou consécutif, y compris sans restreindre la généralité de ce qui précède, les pertes de profits découlant de cette entente, subies par Pipelines Trans-Nord Inc., ou du titulaire. Pipelines Trans-Nord Inc., inclut ses sociétés affiliées, administrateurs, dirigeants, employés et mandataires.",
    "body2":"Le titulaire reconnaît que lui et ses entrepreneurs ont examiné les modalités de cette entente avec le représentant de Pipelines Trans-Nord Inc. et, en accord avec ceux-ci, il convient qu’ils comprennent pleinement toutes les exigences et les graves conséquences d’une infraction sur la sécurité, la réglementation et l’intégrité des pipelines qui pourraient résulter d’une non-conformité et qu’ils acceptent d’adhérer aux politiques de perturbation du sol de Pipelines Trans-Nord Inc.",
    "disclaimer":`
    <p>Le titulaire est responsable envers Pipelines Trans-Nord Inc. de l’ensemble des pertes, dommages, dépenses et coûts, quels qu’ils soient, que Pipelines Trans-Nord Inc. peut subir ou engager en raison d’un acte ou d’une omission du titulaire de ses sociétés affiliées, administrateurs, invités, entrepreneurs ou sous-traitants (le <b>personnel du titulaire</b>) dans l’exécution des travaux visés par le présent formulaire (les <b>travaux</b>), sauf dans la mesure où ces pertes, dommages, dépenses et coûts ont été causés par la négligence ou l’inconduite délibérée de Pipelines Trans-Nord Inc.</p>
    <p>Le titulaire doit indemniser et dégager de toute responsabilité le concédant (Pipelines Trans-Nord Inc.), ses sociétés affiliées, administrateurs, dirigeants, employés, agents, invités, entrepreneurs et sous-traitants (<b> personnel du concédant </b>) pour et contre tous les dommages, pertes, dépenses, actions, procédures, réclamations et coûts qui peuvent être subis par ou intentés contre le concédant ou le personnel du concédant, en rapport avec les travaux envisagés dans le présent formulaire (les <b> travaux </b>), y compris ceux causés ou engagés par le titulaire, sauf dans la mesure où ces pertes, dommages, dépenses, actions, procédures, réclamations et coûts ont été causés par la négligence ou l’inconduite délibérée du concédant.</p>
    <p>Nonobstant ce qui précède, aucune des parties n'est responsable envers l'autre des dommages indirects ou consécutifs, du manque à gagner, de la perte d'opportunité, de la perte d'activité, de la perte d'utilisation ou de la perte de la bienveillance découlant des travaux ou s'y rapportant.</p>
    <p>Le titulaire reconnaît qu'il a examiné toutes les lois applicables, y compris, mais sans s'y limiter, la Loi de la Régie de l'énergie du Canada et le Règlement sur la prévention des dommages aux pipelines - Autorisations de la Régie de l’énergie du Canada, ainsi que les lignes directrices sur les croisements de Pipelines Trans-Nord Inc. Le titulaire s'engage à respecter et à faire respecter par son personnel toutes les lois applicables, les directives de croisement et les conditions du présent formulaire.</p>
    <p>Si le concédant et le titulaire concluent un accord de croisement concernant les travaux, les termes de cet accord de croisement remplacent le présent accord en cas de conflit entre l'accord de croisement et le présent formulaire.</p>
    `,
    "ackButton": "JE RECONNAIS",
  },
  "PDFAcknowledgementPanelComponent": {
    "issueContact": "VISUALISATION DES PROBLÈMES",
    "logo":"tn-logo-fr",
    "pdfPath":"/assets/pdf/Signature Access Troubleshoot Manual-FRENCH.pdf",
    "errorHeaderH2":"Impossible d'accéder au document",
    "errorHeaderH3":"Pourquoi n'avez-vous pas accès pour afficher le document?",
    "errorBody1a":"Si vous utilisez",
    "errorBody1b":"cela pourrait avoir été causé par les options Internet.",
    "errorBody2a":"Veuillez suivre avec le",
    "errorBody2b":"Manuel",
    "errorBody2c":"pour résoudre ce problème causé par les options Internet.",
    "404ErrorHeader":"Impossible de trouver le document"
  },
  "SignatureViewComponent": {
    "header":"Informations et signature requises pour afficher le document",
    "firstName":"Prénom",
    "lastName":"Nom de famille",
    "phoneNumber":"Téléphone",
    "readButton":"AVERTISSEMENT",
    "clearButton":"DÉGAGER",
    "acceptButton":"JE RECONNAIS",
    "signatureHeader":"Signature ci-dessous",
    "signatureMissingResponse":"Signature requise",
  }
}

// Le titulaire sera : a) tenu responsable auprès de Pipelines Trans-Nord Inc. de toutes pertes, coûts, dommages et frais que Pipelines Trans-Nord Inc. pourrait subir, assumer, payer ou engager; et b): Indemniser et dégager Pipelines Trans-Nord Inc. de toutes actions, procédures, réclamations, demandes, pertes et dépenses ou autres coûts de quelque nature que ce soit qui ont été encourus ou subis par Pipelines Trans-Nord Inc., ou que la société pourrait payer, assumer ou engager à la suite de quelconques raisons, objets ou actions attribuables aux activités du titulaire, de ses sociétés affiliées, administrateurs, dirigeants, employés, agents, invités, entrepreneurs ou sous-traitants dans le cadre de l’exécution des travaux sur l’emprise de Pipelines Trans-Nord Inc. sauf en raison de négligence ou d’une inconduite délibérée de la part de Pipelines Trans-Nord Inc. Ni Pipelines Trans-Nord Inc. ni le titulaire ne sont tenus responsables, qu’ils soient délictuels, contractuels ou non, de tout dommage indirect ou consécutif, y compris sans restreindre la généralité de ce qui précède, les pertes de profits découlant de cette entente, subies par Pipelines Trans-Nord Inc., ou du titulaire. Pipelines Trans-Nord Inc., inclut ses sociétés affiliées, administrateurs, dirigeants, employés et mandataires. 
// Le titulaire reconnaît que lui et ses entrepreneurs ont examiné les modalités de cette entente avec le représentant de Pipelines Trans-Nord Inc. et, en accord avec ceux-ci, il convient qu’ils comprennent pleinement toutes les exigences et les graves conséquences d’une infraction sur la sécurité, la réglementation et l’intégrité des pipelines qui pourraient résulter d’une non-conformité et qu’ils acceptent d’adhérer aux politiques de perturbation du sol de Pipelines Trans-Nord Inc.