import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { TicketListItemObject } from "../ticket-list-item/ticket-list-item";
import { NavBarService } from "../../ticket/services/router/nav-bar.service";
import { NavBarConfig } from "src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component";
import { TicketService } from "../../ticket/ticket.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketSyncService } from "../../ticket/services/ticket-sync/ticket-sync.service";
import { TicketDocumentsService } from "../../ticket/ticket-documents/ticket-documents.service";
@Component({
  selector: "app-ticket-list",
  templateUrl: "./ticket-list.component.html",
  styleUrls: ["./ticket-list.component.scss"],
})
export class TicketListComponent implements OnInit, OnChanges {
  @Input() TicketListItems: TicketListItemObject[];
  @Input() CurrentlySelectedTicket: BehaviorSubject<any>;
  @Output() ticketListItemClick = new EventEmitter<string>();
  @Output() syncEvent = new EventEmitter<null>();

  @ViewChild('searchbar') searchbar: ElementRef;
  toggleSearch: boolean = false;
  searchText: any = '';
  listFilter = "type";
  routeFilter = "route";
  allowCreateAudit: any;
  showSyncBadge: boolean = false
  onSyncClick: Subject<any>;
  isOfficeSide: boolean = false
  fabMenu: any;
  disableSync: boolean
  selectedFilter: any;

  public showFilter = true;
  public showRouting = true;
  private destroy$: Subject<void> = new Subject<void>();
  private disableSyncSubject: Subscription;

  constructor(
    private navBarChanges: NavBarService,
    private ticketSyncService: TicketSyncService,
    private navBarService: NavBarService,
    private ticketService: TicketService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketDocumentService: TicketDocumentsService
  ) {
  }

  async ngOnInit() {
    this.navBarChanges.getNavBarChanges().subscribe((nextVal: NavBarConfig) => {
      this.showFilter = nextVal.showFilter;
      this.showRouting = nextVal.showRouting ?? this.showRouting;
      this.onSyncClick = nextVal.onSyncClick;
      this.showSyncBadge = nextVal.showSyncBadge;
    });
    this.allowCreateAudit = this.userService.isSettingActive(
      SettingID.ALLOW_CREATE_AUDIT_TICKETS,
    );
    if (this.route.snapshot.data.SettingID == 300) {

      this.isOfficeSide = true
    }
    this.disableSyncSubject = this.ticketDocumentService.disableSync$.pipe(takeUntil(this.destroy$)).subscribe(disable => {
      this.disableSync = disable;
    });

    if (this.CurrentlySelectedTicket) {
      this.CurrentlySelectedTicket.pipe(takeUntil(this.destroy$)).subscribe(event => {
        for (const ticket of this.TicketListItems) {
          if (ticket.AssignmentID === event.AssignmentID) {
            ticket.IsSelected = true;
          } else {
            ticket.IsSelected = false;
          }
        }
      });
    }
  }

  ngOnChanges() {
    this.navBarChanges.getNavBarChanges().subscribe((nextVal: NavBarConfig) => {
      this.showFilter = nextVal.showFilter;
      this.showRouting = nextVal.showRouting;
      this.onSyncClick = nextVal.onSyncClick;
      this.showSyncBadge = nextVal.showSyncBadge;
    });
  }

  openSelfAssign = () => {
    this.router.navigate(["fieldside/self-assign"]);
  };



  createTicket() {
    this.ticketService.createAuditTicketModal(this.showFilter);
  }

  openTicket(event) {
    this.CurrentlySelectedTicket.next(event);
  }

  public onFilter(order) {
    this.selectedFilter = order;
    this.navBarChanges.filterBtnClickSubject.next(order);
  }

  public async onSync() {
    const assignmentIDs = await this.ticketSyncService.getAssignmentIDToUpload(await this.ticketSyncService.getAssignedTickets());

    this.ticketSyncService.startSync(true, true, assignmentIDs, true).subscribe({
      complete: () => {
        this.syncEvent.emit();
        this.ticketSyncService.closeModal();
      },
    });
    // this.onSyncClick.next(this.onSyncClick);
  }

  openSearch() {
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
