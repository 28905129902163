<mat-horizontal-stepper
  [linear]="isLinear"
  #createTicketStepper
  style="height: 100%; overflow: auto"
>
  <mat-step [stepControl]="excavatorDetailProp['infoFormGroup']">
    <ng-template matStepLabel>Excavator Details</ng-template>
    <mat-divider></mat-divider>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      style="height: 100%"
    >
      <div fxFlex class="form-group">
        <h3>Fill Excavator Details</h3>
        <div [formGroup]="excavatorDetailProp['infoFormGroup']">
          <app-create-input-view
            #excavatorDetailView
            [props]="excavatorDetailProp"
          >
          </app-create-input-view>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxFlex="80px" class="panel-footer" fxLayoutAlign="end center">
        <div>
          <button
            color="primary"
            mat-raised-button
            mat-primary
            [disabled]="isLoadingAsync"
            (click)="gatherExcavatorDetails()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="ticketDetailProp['infoFormGroup']">
    <ng-template matStepLabel>Ticket Details</ng-template>

    <mat-divider></mat-divider>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      style="height: 100%"
    >
      <div fxFlex class="form-group">
        <h3 id="digsiteHeaderText">Choose your digsite</h3>
        <button
          color="primary"
          mat-raised-button
          mat-primary
          (click)="onEditDigsite()"
          style="margin-bottom: 10px"
        >
          Edit Digsite
        </button>

        <mat-divider></mat-divider>
        <h3>Fill Ticket Details</h3>
        <div [formGroup]="ticketDetailProp['infoFormGroup']">
          <app-create-input-view #ticketDetailView [props]="ticketDetailProp">
          </app-create-input-view>
        </div>

        <mat-divider></mat-divider>
        <h3>Completion Details</h3>
        <app-form-group-view
          #primaryDetailsView
          [props]="primaryDetailsProp"
        ></app-form-group-view>

        <mat-divider></mat-divider>
        <h3 id="uploadDocHeaderText">Upload Auxillary Documents</h3>
        <app-upload-documents
          #uploadDocs
          [readonly]="false"
        ></app-upload-documents>

        <mat-divider></mat-divider>
        <h3 id="utilityMapHeaderText">
          Do you have a utility map for this request? *
        </h3>
        <div
          *ngIf="showUtilityMapCheckbox"
          style="margin-bottom: 10px; margin-top: 10px"
        >
          <mat-radio-group
            [(ngModel)]="utilityMapResult"
            [ngModelOptions]="{ standalone: true }"
          >
            <mat-radio-button
              color="primary"
              value="1"
              style="white-space: pre-wrap"
            >
              Yes, and I have uploaded it to this request.
            </mat-radio-button>
            <mat-radio-button
              color="primary"
              value="0"
              style="white-space: pre-wrap"
            >
              No, I do not have a utility map for this request.
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div
        fxFlex="80px"
        class="panel-footer"
        fxLayoutAlign="space-between center"
      >
        <button
          color="primary"
          mat-raised-button
          mat-primary
          matStepperPrevious
          [disabled]="isLoadingAsync"
        >
          Back
        </button>
        <button
          color="primary"
          mat-raised-button
          mat-primary
          [disabled]="isLoadingAsync"
          (click)="gatherTicketDetails()"
        >
          Next
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Review</ng-template>
    <mat-divider></mat-divider>

    <div
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      style="height: 100%"
    >
      <div fxFlex class="form-group">
        <h3>Review and Confirm</h3>
        <div [formGroup]="excavatorDetailReviewProp['infoFormGroup']">
          <app-create-input-view
            #excavatorDetailReviewView
            [props]="excavatorDetailReviewProp"
          >
          </app-create-input-view>
        </div>

        <div [formGroup]="ticketDetailReviewProp['infoFormGroup']">
          <app-create-input-view
            #ticketDetailReviewView
            [props]="ticketDetailReviewProp"
          >
          </app-create-input-view>
        </div>

        <h3>Completion Details</h3>
        <app-form-group-view
          #primaryDetailsReviewView
          [props]="primaryDetailsReviewProp"
        ></app-form-group-view>
      </div>

      <mat-divider></mat-divider>
      <div
        fxFlex="80px"
        class="panel-footer"
        fxLayoutAlign="space-between center"
      >
        <button
          color="primary"
          mat-raised-button
          mat-primary
          matStepperPrevious
          [disabled]="isLoadingAsync"
          (click)="onLeaveReviewMode()"
        >
          Back
        </button>
        <button
          color="primary"
          mat-raised-button
          mat-primary
          [disabled]="isLoadingAsync"
          (click)="submitForm()"
        >
          Confirm
        </button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
