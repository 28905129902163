import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { BottomNavigationComponent } from "./bottom-navigation/bottom-navigation.component";

@NgModule({
  declarations: [BottomNavigationComponent],
  imports: [CommonModule, MaterialModule],
  exports: [BottomNavigationComponent],
})
export class BottomNavigationModule {}
