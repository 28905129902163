import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger/logger.service';
import { CacheService, StoreType } from '../cache/cache.service';
import { apiKeys, api } from '../../../ENDPOINTS';
import { localStorageKeys } from '../../../LOCAL_STORAGE';
import { ApiService, UtilocateApiRequest } from '../api/baseapi.service';

@Injectable({
  providedIn: 'root',
})
export class AdminLookupService {
  private refreshCacheData: boolean; // determine if api calls needs to be made

  constructor(
    private logger$: LoggerService,
    private cache$: CacheService,

    private utilocateApiService: ApiService
  ) {
    // super(http$, logger$, mainError$);
    this.updateRefreshCacheData();
  }

  // upate local variable refreshCachceData
  private async updateRefreshCacheData(tableNames = []) {
    this.refreshCacheData = false;
    const lastUpdate = sessionStorage.getItem(
      localStorageKeys.CACHE_DATE_ADMIN_LOOKUP_KEY,
    );
    const keys = await this.cache$.getKeys(StoreType.ADMIN);

    if (tableNames && tableNames.length == 1 && tableNames[0] == 'tbAdmin_Settings') {
      this.refreshCacheData = true;
      return;
    }

    // let bClean = sessionStorage.getItem(localStorageKeys.B_CLEAN);
    // if (bClean && bClean ==  "1") {
    //   await this.cache$.clear(StoreType.ADMIN);
    //   this.refreshCacheData = true;
    //   sessionStorage.setItem(localStorageKeys.B_CLEAN, "0");
    //   return;
    // }

    // table not found locally -> get from server
    if (tableNames && tableNames.length > 0) {
      for (let i = 0; i < tableNames.length; i++) {
        // table not found
        if (keys.indexOf(tableNames[i]) < 0) {
          this.refreshCacheData = true;
          return;
        }
      }
    }

    // if no lastUpdate time found or cache has been stale for an hour -> get from server
    if (lastUpdate) {
      const lastUpdateTime = parseInt(lastUpdate, 10);
      const curDate = new Date();
      curDate.setHours(curDate.getHours() - 1);

      if (lastUpdateTime < curDate.getTime()) {
        this.refreshCacheData = true;
      } else {
        this.refreshCacheData = false;
      }
    } else {
      this.refreshCacheData = true;
    }
    return;
  }

  // get requested tables
  // [] to get all tables
  async getLookupTables(tableNames: string[], options: unknown = {}) {
    let tables = [];

    try {
      await this.updateRefreshCacheData(tableNames);

      // update cache if required
      let result: boolean = true;
      if (this.refreshCacheData) {
        result = await this.refreshLookupTables(tableNames, options);
      }

      // get requested tables or all
      if (result) {
        tables = await this.queryCache(StoreType.ADMIN, tableNames);
      } else {
        this.logger$.warn('AdminLookup$: getLookupTable: cannot refresh admin tables');
      }
    } catch (error) {
      this.logger$.error('AdminLookup$: getLookupTable: function error: ', error);
    }

    return tables;
  }

  async getLookupTablesFromServer(tableNames: string[], options: unknown = {}) {
    let tables = [];

    try {
      let result: boolean = true;
      result = await this.refreshLookupTables(tableNames, options);

      // get requested tables or all
      if (result) {
        tables = await this.queryCache(StoreType.ADMIN, tableNames);
      } else {
        this.logger$.warn('AdminLookup$: getLookupTable: cannot refresh admin tables');
      }
    } catch (error) {
      this.logger$.error('AdminLookup$: getLookupTable: function error: ', error);
    }

    return tables;
  }

  // get table column info
  async getTableColumnDetails(store, tableNames: string[]) {
    let result = null;

    try {
      let queryResult = null;

      if (tableNames && tableNames.length > 0) {
        queryResult = await Promise.all(tableNames.map(async (name) => await this.cache$.query(store, name)));
      } else {
        // get all
        queryResult = await this.cache$.queryAll(store);
      }

      if (queryResult) {
        // iterate through each table
        const mappingResult = queryResult.map((tableObj) => {
          const queryResultColumns = tableObj["Columns"];

          // iterate through each column
          const Columns = queryResultColumns.reduce((total, columnObj) => {
            // create {columnName: columnValue}
            for (const key in columnObj) {
              total[key] = this.getReadableColumnType(columnObj[key]);
            }
            return total;
          }, {});

          // return {name: tableName, columns: { columnName: columnValue} }
          return { Columns, name: tableObj['name'] };
        });

        result = mappingResult;
      } else {
        this.logger$.warn('AdminLookup$: getTableColumnDetails: fail');
      }
    } catch (error) {
      this.logger$.error('AdminLookup$: getTableColumnDetails: function error: ', error);
    }
    return result;
  }

  // get value of [columns] from row id
  // async getLookupTableValues(tableName: string, rowID: number, columns: string[]) {}

  // get id of row from column value
  async getLookupTableRows(tableNames: string[], params: any) {
    const tableData: any = [];

    try {
      const tables = await this.getLookupTables(tableNames);
      if (tables && tables.length > 0) {
        // iterate table data rows
        for (let i = 0; i < tables.length; i++) {
          const filterResult = tables[i]['Data'].filter((row) => {
            // iterate WHERE clause
            for (const [key, value] of Object.entries(params)) {
              if (row[key] == null) {
                //null
                return false;
              } else {
                if (Array.isArray(value)) {
                  // !in (numbers)
                  if (!value.includes(row[key])) {
                    return false;
                  }
                } else {
                  // != value
                  if (row[key].toString().toUpperCase() != value.toString().toUpperCase()) {
                    return false;
                  }
                }
              }
            }
            return true;
          });

          if (filterResult) {
            const result = {
              name: tables[i]['name'],
              rows: filterResult,
            };
            tableData.push(result);
          }
        }
      } else {
        this.logger$.warn('AdminLookup$: getLookupTableRows: cannot get table');
      }
    } catch (error) {
      this.logger$.warn('AdminLookup$: getLookupTableRows: function failed');
    }
    return tableData;
  }

  async getAdminTableDescFromID(tableName: string, params: any) {
    try {
      const rowsToReturn = [];

      const adminTableResult = await this.getLookupTables([tableName]);
      if (adminTableResult && adminTableResult[0]) {
        const adminTableRows = adminTableResult[0]["Data"];
        for (let i = 0; i < adminTableRows.length; i++) {
          const curRow = adminTableRows[i];
          for (const [key, value] of Object.entries(params)) {
            if (curRow && curRow[key] != null) {
              if (Array.isArray(value) && value.includes(curRow[key])) {
                rowsToReturn.push(curRow);
              } else if (curRow[key] == value) {
                rowsToReturn.push(curRow);
              }
            }
          }
        }

        return rowsToReturn;
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  // refresh lookup tables in cache using api

  async refreshLookupTables(tableNames?: string[], options: unknown = {}) {
    let success: boolean = false;

    try {
      const url = apiKeys.u2.refreshAdminTables;
      const type = api[url].type;
      let apiBody = {};

      if (tableNames) {
        const requestedTables = tableNames;
        const body = {
          tablenames: requestedTables,
          options: options,
        };
        //Add excavatorID as new override to grab specific excavator
        apiBody = body;
      }

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.refreshAdminTables,
        API_TYPE: type,
        API_BODY: apiBody,
      };

      // update cache using api (done via interceptor)
      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);

      if (result && result.ok) {
        await this.updateRefreshCacheData();
        success = true;
      } else {
        this.logger$.warn('AdminLookup$: refreshLookupTables: fail');
      }
    } catch (error) {
      console.error(error);
      this.logger$.error('AdminLookup$: refreshLookupTables: function error');
    }

    return success;
  }

  // query cache for requested tables
  private async queryCache(store, tableNames: string[]) {
    let tables = null;

    try {
      let result = null;

      if (tableNames && tableNames.length > 0) {
        // create + execute promise array of all requested tables
        result = await Promise.all(tableNames.map(async (name) => await this.cache$.query(store, name)));
      } else {
        // get all
        this.logger$.warn('AdminLookup$: getLookupTable: getting all tables');
        result = await this.cache$.queryAll(store);
      }

      if (result) {
        tables = result;
      } else {
        this.logger$.warn('AdminLookup$: getLookupTable: fail');
      }
    } catch (error) {
      this.logger$.error('AdminLookup$: getLookupTable: function error: ', error);
    }

    return tables;
  }

  // get column data types
  private getReadableColumnType(columnType: number) {
    let type = null;

    // map between string / number
    switch (columnType) {
      case 1:
        type = 'number';
        break;
      case 3:
        type = 'string';
        break;
      default:
        break;
    }

    return type;
  }
}
