import {Component} from '@angular/core';
import {SettingTile} from '../account-settings.module';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../core/services/user/user.service';
import settingTiles from './account-setting-tiles.json';

@Component({
  selector: 'app-general-settings-item',
  templateUrl: './account-settings-item.component.html',
})
export class AccountSettingsItemComponent {
  /**
     * ~~~~~~~~~~~~~~~~~~~~~~
    * README 
    * To add a setting tile, simply add it to the json 'account-setting-tiles.json'
    * Make sure it has these properties, you just copy pasta the json
    *  - title: The name of the setting tile
    *  - icon: The font-awesome icon name
    *  - description: A description of the setting tile
    *  - path: The path to navigate to when the tile is clicked
    *  - settingID: The ID of the setting (if it has one)
    *
    *  NOTE: All SettingTile parameters are required.
    *  NOTE: If you want a setting tile to be available to every user, set the settingID to null.
     * ~~~~~~~~~~~~~~~~~~~~~~
  */
  settingCategoryGroups: SettingTile[][] = [];

  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router,) {
    this.setupRouteLinks();
  }

  navigate(path) {
    this.router.navigate([`../${path}`], {relativeTo: this.activatedRoute});
  }

  /**
   * Sets up the router links for this component from the json file 
   * @memberof AccountSettingsItemComponent
   */
  setupRouteLinks() {
    const allGroups = [
      settingTiles.userSettings,
      settingTiles.adminSettings,
      settingTiles.miscSettings
    ];

    for (const group of allGroups) {
      const filteredGroup = group.filter(route => {
        return !(route.settingID && !this.userService.isSettingActive(route.settingID));
      });
      if (filteredGroup.length > 0) this.settingCategoryGroups.push(filteredGroup);
    }
  }
}
