import { Component, HostListener, Input, OnInit } from "@angular/core";
import { CardActionStatus } from "../cardOptions";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  @Input() cardOptions: any;
  public width: string;

  constructor() {}

  ngOnInit(): void {
    const w = window.innerWidth - 40;
    this.width = w + "px";
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    const w = window.innerWidth - 40;
    this.width = w + "px";
  }

  isActionDisabled(index: number) {
    let isDisabled = false;
    if (this.cardOptions.actions[index].status != null) {
      isDisabled =
        this.cardOptions.actions[index].status ==
        CardActionStatus.NOT_ACCESSIBLE;
    }
    return isDisabled;
  }

  actionOnClick(index: number, id: number) {
    this.cardOptions.actions[index].action(id, index);
  }

  iconOnClick(index: number, id: number) {
    this.cardOptions.icons[index].action(id, index);
  }
}
