export const TOKEN_PAYLOADS = {
  app: "app",

  // Keys are all lower case to test against similar terms easier
  "0": {
    // U2
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
  },
  "1": {
    // U3
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
    localuserid: {
      name: "localUserID",
      optional: false,
    },
    uxuserid: {
      name: "UXUserID",
      optional: true,
    },
  },
  "2": {
    // App
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
  },
  "3": {
    // UX
    userid: {
      name: "userID",
      optional: false,
    },
    clientID: {
      name: "companyID",
      optional: false,
    },
    clientid: {
      name: "companyID",
      optional: false,
    },
    companyid: {
      name: "companyID",
      optional: false,
    },
  },
};

export const AUTH_TOKEN_PAYLOADS = {
  app: "app",
  "0": {
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
    u3user: {
      name: "U3USER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
      usercategoryid: {
        name: "userCategoryID",
        optional: false,
      },
    },
    uxuser: {
      name: "UXUSER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
    },
    version: {
      name: "clientID",
      optional: true,
    },
  },
  "1": {
    userid: {
      name: "userID",
      optional: true,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: true,
    },
    u3user: {
      name: "U3USER",
      optional: false,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
      usercategoryid: {
        name: "userCategoryID",
        optional: false,
      },
    },
    uxuser: {
      name: "UXUSER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
    },
    version: {
      name: "clientID",
      optional: true,
    },
  },
  "2": {
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
    u3user: {
      name: "U3USER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
      usercategoryid: {
        name: "userCategoryID",
        optional: false,
      },
    },
    uxuser: {
      name: "UXUSER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
    },
    version: {
      name: "clientID",
      optional: true,
    },
  },
  "3": {
    userid: {
      name: "userID",
      optional: false,
    },
    Companyid: {
      name: "companyID",
      optional: false,
    },
    companyid: {
      name: "companyID",
      optional: false,
    },
    ClientID: {
      name: "companyID",
      optional: false,
    },
    clientid: {
      name: "companyID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: true,
    },
    version: {
      name: "clientID",
      optional: true,
    },
  },
  "5": {
    userid: {
      name: "userID",
      optional: false,
    },
    clientid: {
      name: "clientID",
      optional: false,
    },
    usercategoryid: {
      name: "userCategoryID",
      optional: false,
    },
    u3user: {
      name: "U3USER",
      optional: true,
      nested: true,
      userid: {
        name: "userID",
        optional: false,
      },
      usercategoryid: {
        name: "userCategoryID",
        optional: false,
      },
    },
    uxuser: {
      name: "UXUSER",
      optional: true,
    },
    version: {
      name: "clientID",
      optional: true,
    },
  },
};

export const PRODUCT_ID_TO_NAME = {
  0: "u2",
  1: "u3",
  2: "app",
  3: "ux",
  5: "u4",
};
