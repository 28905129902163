<div style="width: 100%; height: 100%" fxLayout="row">
  <div class="rule-builder-list-container" fxFlex style="width: 100%; height: 100%; overflow: auto">
    <div fxLayout="column" fxLayoutGap="0px" style="height: 100%">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-headline-2">{{ ruleTypeName }}</span>
        <button *ngIf="!viewOnly" mat-flat-button color="primary" class="add-rule-button" (click)="addRule()">
          <mat-icon>add</mat-icon>
          Add Rule
        </button>
        <button *ngIf="viewOnly" mat-flat-button color="primary" class="add-rule-button rajdhani" disabled="true">
          View Only
        </button>
      </div>

      <!-- Add some space between (rule type name && add button) and (search bar, table, etc...) -->
      <br />

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="12px">
          <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
            <app-competers-search-bar
              class="h-9 md:w-96 lg:w-[600px]"
              (searchValueChange)="searchTerm = $event"></app-competers-search-bar>
          </div>
        </div>
      </div>

      <br />

      <div *ngIf="rulesForDisplaying" class="flex flex-grow w-full h-full">
        <app-tabulator-table
          class="h-full w-full"
          #tabulatorTable
          [tableData]="rulesForDisplaying"
          [columns]="displayedColumns"
          [searchString]="searchTerm"
          (rowDoubleClick)="onRowClicked($event)"
          (selectedRowsChanged)="rowSelectionChanged($event)" />
      </div>
    </div>
  </div>
</div>
