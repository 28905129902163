export class Snackbar {
  constructor(
    public type: SnackbarType,
    public message: string,
    public title?: string
  ) {}
}


export enum SnackbarType {
  default = 0,
  success = 1,
  warning = 2,
  error = 3,
  info = 4,
}
