import { inject, Injectable } from '@angular/core';
import { api, apiKeys } from '../../../ENDPOINTS';
import { UtilocateApiRequest } from '../../../modules/core/api/baseapi.service';
import { UtilocateApiService } from '../../../modules/core/api/utilocateapi.service';
import { SnackbarService } from '../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../modules/shared/snackbar/snackbar/snackbar';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { AdminLookupService } from '../../../modules/core/admin/admin-lookup.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsonFormComponent } from '../../components/misc/json-form/json-form.component';
import { UsersService } from '../users/users.service';
import { TicketActionIDs } from './TicketActionIDs';
import uniq from 'lodash-es/uniq';
import { ADMIN_TABLE_NAMES } from '../../../modules/core/admin/tables';
import { UserService } from '../../../modules/core/services/user/user.service';
import { JsonForm, JsonFormData } from '~lib/types/jsonForm';
import { TicketService } from 'src/app/modules/shared/ticket/ticket.service';
import { AssignLocatorModalComponent } from 'src/app/modules/shared/ticket-assignment/assignLocatorModal/assign-locator-modal.component';
import { TicketDetailsService } from 'src/app/modules/shared/ticket-details/ticket-details.service';
import { TicketSyncService } from 'src/app/modules/shared/ticket/services/ticket-sync/ticket-sync.service';

@Injectable({
  providedIn: 'root',
})
export class TicketActionsService {
  private utilocateApiService = inject(UtilocateApiService);
  private adminLookupService = inject(AdminLookupService);
  private snackBarService = inject(SnackbarService);
  private settingsService = inject(UserService);
  private userService = inject(UsersService);
  private dialogService = inject(MatDialog);
  private httpClient = inject(HttpClient);
  private ticketService = inject(TicketService);
  private ticketDetailsService = inject(TicketDetailsService)
  private ticketSyncService = inject(TicketSyncService)


  // observables
  private _ticketActions$: BehaviorSubject<Array<TicketAction>> = new BehaviorSubject([]);
  private _formData$: BehaviorSubject<JsonFormData> = new BehaviorSubject([]);
  private _clearOptions$: BehaviorSubject<Array<unknown>> = new BehaviorSubject([]);

  // members
  private TICKET_ACTIONS_SETTING_ID = 139;
  private TICKET_ACTIONS_SETTING_ACTIVE = false;
  private dialog: MatDialogRef<JsonFormComponent> | null = null;
  public assignUserModal: any
  public failedPrimaries: any

  private sourceMap = {
    locators: {
      getter: () => this.userService.locators,
      nameKeys: ['LastName', 'FirstName'],
      value: 'UserID',
    },
    users: {
      getter: () => {
        const categories = this.userService.userCategories;
        return this.userService.users.map((user) => ({
          ...user,
          name: `${user.LastName}, ${user.FirstName}`,
          categoryTitle: `(${categories.find(cat => cat.UserCategoryID === user.UserCategoryID).Title})`,
        }));
      },
      nameKeys: ['name', 'categoryTitle'],
      value: 'UserID',
    },
    subStatuses: {
      getter: () => [],
      nameKeys: ['SubStatusName'],
      value: 'SubStatusID',
    },
    lsp: {
      getter: () => [],
      nameKeys: ['LSPName'],
      value: 'LSPID',
    },
    clearOptions: {
      getter: () => this._clearOptions$.value,
      nameKeys: ['Description'],
      value: 'ClearTypeID',
    },
  };

  constructor() {
    this.update();
    this.fetchFormData();
    this.updateClearOptions();
  }

  public update() {
    if (this.settingsService.isSettingActive(this.TICKET_ACTIONS_SETTING_ID)) {
      this.TICKET_ACTIONS_SETTING_ACTIVE = true;
    }
    this.fetchActions();
  }

  private fetchFormData() {
    // exit out if the setting is not active
    if (!this.TICKET_ACTIONS_SETTING_ACTIVE) {
      return;
    }
    firstValueFrom(this.httpClient.get('assets/form-templates/ticket-actions.json'))
      .then((formData: JsonFormData) => {
        this._formData$.next(formData.filter((x) => x['$schema'] === undefined)); // remove the schema entry
      })
      .catch(() => {
        this.snackBarService.openSnackbar('Error gathering Ticket Actions', SnackbarType.error);
        this._formData$.next([]);
      });
  }

  private fetchActions() {
    // exit out if the setting is not active
    if (!this.TICKET_ACTIONS_SETTING_ACTIVE) {
      return;
    }
    const apiKey = apiKeys.u2.gatherTicketActions;
    const url = apiKeys.u2[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKey,
      API_TYPE: type,
      API_BODY: { empty: 'empty' },
    };

    this.utilocateApiService
      .invokeUtilocateApi(utilocateApiRequest)
      .then((result) => {
        this._ticketActions$.next(
          JSON.parse(result.body.value)
            .filter(({ TicketActionID }) =>
              [
                TicketActionIDs.SEND_TO_LSP_ACTION_ID,
                TicketActionIDs.UNASSIGN_ACTION_ID,
                TicketActionIDs.REASSIGN_ACTION_ID,
                TicketActionIDs.UNGROUP_UTILITY_ACTION_ID,
                TicketActionIDs.GROUP_UTILITY_ACTION_ID,
                TicketActionIDs.CANCEL_TICKET_ACTION_ID,
                TicketActionIDs.CLEAR_ACTION_ID,
                TicketActionIDs.CHANGE_EXCAVATION_DATE_ACTION_ID,
              ].includes(TicketActionID),
            )
            .map((action: Record<string, unknown>) => {
              return {
                id: action['TicketActionID'],
                actionName: action['TicketActionName'],
                visibleName: action['TicketActionVisibleName'],
                actionVisible: action['TicketActionVisible'],
              };
            }),
        );
      })
      .catch(() => {
        this.snackBarService.openSnackbar('Error gathering Ticket Actions', SnackbarType.error);
        this._ticketActions$.next([]);
      });
  }

  private updateClearOptions() {
    // exit out if the setting is not active
    if (!this.TICKET_ACTIONS_SETTING_ACTIVE) {
      return;
    }
    this.adminLookupService.getLookupTables([ADMIN_TABLE_NAMES.tbAdmin_ClearOptions]).then(([{ Data }]) => {
      this._clearOptions$.next(Data);
    });
  }

  public async dispatchBulkAction(actionID: number, ticketPrimaryIDs: Array<number>, assignmentIDs: Array<number>): Promise<unknown> {
    // if no tickets are selected, show an error and return out
    if (ticketPrimaryIDs.length === 0) {
      this.snackBarService.openSnackbar('Error: No tickets selected', SnackbarType.error);
      return null;
    }


    const jsonForm = this._formData$.value.find((x) => x.id === actionID);
    
    const ticketAction = this._ticketActions$.value.find((x) => x.id === actionID);
    jsonForm.controls.forEach((x) => {
      if (x.type === 'select' && x.source !== undefined) {
        const map = this.sourceMap[x.source];
        x.dataSource = map.getter().map((entry: Record<string, unknown>) => {
          return {
            name: map.nameKeys.map((key: string) => entry[key]).join(' - '),
            value: entry[map.value],
          };
        });
      }
    });
    if (actionID === 1100 || actionID === 1000) {
      const userList = jsonForm.controls[0]?.dataSource
      let lsps = await this.ticketService.getAllLSPs()
        
      for (let i = 0; i < ticketPrimaryIDs.length; i++) {
        let assignment =  assignmentIDs[i]
        let primary = ticketPrimaryIDs[i]
      }
     let result = await this.openBulkAssignModal(userList, lsps, ticketPrimaryIDs, assignmentIDs)

      return result;
    }
    this.dialog = this.dialogService.open(JsonFormComponent, {
      data: jsonForm,
      autoFocus: false,
      panelClass: ['mat-dialog-overflow'],
      disableClose: true,
    });

    const dialogResult = await firstValueFrom(this.dialog.afterClosed());
    if (dialogResult === false) {
      return null;
    }

    const valueArr = [];
    const successfulTickets = [];
    const failedTickets = [];

    const ticketPrimaryIDsChunks = [];

    if ([TicketActionIDs.GROUP_UTILITY_ACTION_ID, TicketActionIDs.UNGROUP_UTILITY_ACTION_ID].includes(actionID)) {
      valueArr.push(...(await this.executeActionRequest(dialogResult, ticketPrimaryIDs, ticketAction, jsonForm)));
    } else {
      for (let i = 0; i < ticketPrimaryIDs.length; i += 10) {
        console.log(ticketPrimaryIDs.slice(i, i + 10));
        ticketPrimaryIDsChunks.push(ticketPrimaryIDs.slice(i, i + 10));
      }
      valueArr.concat(
        (
          await Promise.all(
            ticketPrimaryIDsChunks.map(async (chunk) =>
              this.executeActionRequest(dialogResult, chunk, ticketAction, jsonForm),
            ),
          )
        ).reduce((acc, x) => acc.concat(x), []),
      );
    }

    successfulTickets.push(...valueArr.filter((x) => x['Response'] == 'Success').map((x) => x['Options']['PrimaryID']));
    failedTickets.push(...valueArr.filter((x) => x['Error'] !== undefined).map((x) => x['Error']));

    if (failedTickets.length > 0 && successfulTickets.length > 0) {
      this.snackBarService.openSnackbar(
        `Successful: ${successfulTickets.join(', ')}, Errored: ${uniq(failedTickets).join(', ')}`,
        SnackbarType.warning,
      );
    } else if (failedTickets.length > 0) {
      this.snackBarService.openSnackbar(`Errored': ${uniq(failedTickets).join(', ')}`, SnackbarType.error);
    } else if (successfulTickets.length > 0) {
      this.snackBarService.openSnackbar(`Successful: ${successfulTickets.join(', ')}`, SnackbarType.success);
    }
    return [successfulTickets, failedTickets];
  }

  openAssignModal(userList, lsps, primaryID, assignmentID){
    this.assignUserModal = this.dialogService.open(AssignLocatorModalComponent, {
      width: "560px",
      // height: '250px',
      data: {
        dataKey: userList,
        lsps: lsps,
      },
    });
    this.assignUserModal.afterClosed().subscribe(async (nextValue) => {
      
      if (nextValue) {
        if (nextValue["UserID"] != null) {
          this.onAssignTicketToUser(nextValue, primaryID);
        } else if (nextValue["LSPID"]) {
          this.sendToLSP(nextValue, primaryID, assignmentID);
        }
      }
    });
    
  }

  failedLsps(){
    return this.failedPrimaries
  }

  async openBulkAssignModal(userList, lsps, primaryIDs, assignmentIDs){
    let result
    this.assignUserModal = this.dialogService.open(AssignLocatorModalComponent, {
      width: "560px",
      // height: '250px',
      data: {
        dataKey: userList,
        lsps: lsps,
      },
    });
    const nextValue = await firstValueFrom(this.assignUserModal.afterClosed());
  
    if (nextValue) {
      if (nextValue["UserID"] != null) {
        this.onAssignTicketToUser(nextValue, primaryIDs);
      } else if (nextValue["LSPID"]) {
        result = await this.sendToLSP(nextValue, primaryIDs, assignmentIDs);
        
        this.failedPrimaries = result;
      }
    }
  
    return result; 

  }

  async onAssignTicketToUser(result, primaryID) {
    let errored = 0
    let succeeded = 0
    try {
      if(primaryID.length > 1 && result['UserID'] != null){
        for (let i = 0; i < primaryID.length; i++) {
          const assignResult = await this.ticketService.reassignTicketToUser(
            primaryID[i],
            result['UserID'],
          );
          if (assignResult && assignResult[0] && assignResult[0]['Error']) {
            const errorMsg = assignResult[0]['Error'].split(':')[1];
            console.log(errorMsg);
            errored++
          } else {
            succeeded++
          }
        }
        if(errored > 0){
          this.snackBarService.openSnackbar(errored + ' errored and ' + succeeded + ' succeeded', SnackbarType.error);
        }
        else {
          this.snackBarService.openSnackbar('Successfully assigned all tickets', SnackbarType.success);

        }
      }
      else if (result['UserID'] != null) {

        const assignResult = await this.ticketService.reassignTicketToUser(
          primaryID,
          result['UserID'],
        );
        if (assignResult && assignResult[0] && assignResult[0]['Error']) {
          const errorMsg = assignResult[0]['Error'].split(':')[1];
          this.snackBarService.openSnackbar(errorMsg.toString().trim(), SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Success', SnackbarType.success);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  async sendToLSP(result, primaryID, assignmentID) {
    let errored = 0
    let erroredPrimaries = []
    let succeeded = 0
    try {
      if (result.LSPID != null && Array.isArray(primaryID)) {
        for (let i = 0; i < primaryID.length; i++) {
          let lsps = await this.ticketService.getLSPs(
            assignmentID[i],
            primaryID[i].toString());
          if (lsps['utilityLSPs']?.length) {
            const valueExists = lsps['utilityLSPs'].some(obj => obj.LSPID === result.LSPID);
            if(valueExists){
              const toLSPResult = await this.ticketDetailsService.sendToLSP(
                primaryID[i],
                result.LSPID,
              );
              if (toLSPResult && toLSPResult[0] && toLSPResult[0]["Error"]) {
              const errorMsg = toLSPResult[0]['Error'].split(':')[1];
                  console.log(errorMsg);
                  erroredPrimaries.push(Number(primaryID[i]))
                  errored++
              } else {
                succeeded++
              }
            }
        } else {
          erroredPrimaries.push(Number(primaryID[i]))
          errored++
        }
      }
      if(errored > 0){
        this.snackBarService.openSnackbar(errored + ' errored and ' + succeeded + ' succeeded', SnackbarType.error);
      }
      else {
        this.snackBarService.openSnackbar('Successfully assigned all tickets', SnackbarType.success);
      }
      //on success, upload and close
      this.ticketSyncService.startSync(true, true).subscribe({
        complete: () => {
          this.ticketSyncService.closeModal();
        },
      });
      }
      else if (result.LSPID != null) {

        const toLSPResult = await this.ticketDetailsService.sendToLSP(
          primaryID,
          result.LSPID,
        );
        if (toLSPResult && toLSPResult[0] && toLSPResult[0]["Error"]) {
          const errorMsg = toLSPResult[0]["Error"].split(":")[1];
          this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar("Success", SnackbarType.success);
          //on success, upload and close
          this.ticketSyncService.startSync(true, true).subscribe({
            complete: () => {
              this.ticketSyncService.closeModal();
            },
          });
        }

      }
      return erroredPrimaries
    } catch (error) {
      console.error(error);
    }
  }

  public async reassignTicketToUser(primaryIDs: Array<number>, userIdToAssign: number) {
    try {
      const apiKey = apiKeys.u2.reassignTicketAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      primaryIDs.forEach((primaryID) => {
        value[primaryID] = { Value: userIdToAssign };
      });

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      return JSON.parse(result.body.value);
    } catch {
      this.snackBarService.openSnackbar('Error reassigning ticket', SnackbarType.error);
      return null;
    }
  }

  private async executeActionRequest(
    formData: Record<string, unknown>,
    ticketPrimaryIDs: Array<number>,
    ticketAction: TicketAction,
    jsonForm: JsonForm,
  ): Promise<Array<unknown>> {
    // exit out if the setting is not active
    if (!this.TICKET_ACTIONS_SETTING_ACTIVE) {
      return [];
    }
    const promises: Array<Promise<HttpResponse<unknown>>> = [];
    const actionValueMap = jsonForm.meta['ActionValueMap'];
    if (actionValueMap === undefined && jsonForm.controls.length > 0) {
      this.snackBarService.openSnackbar('Error: ActionValueMap not found', SnackbarType.error);
      return;
    }
    const keyAndType: [string, string] = [undefined, undefined];
    let apiBody: Record<string, unknown> = {};
    let request: UtilocateApiRequest;
    switch (ticketAction.id) {
      case TicketActionIDs.REASSIGN_ACTION_ID:
      case TicketActionIDs.UNASSIGN_ACTION_ID:
      case TicketActionIDs.ASSIGN_TO_SPECIFIC_USER_ACTION_ID:
        keyAndType[0] = apiKeys.u2.quickTicketActions;
        keyAndType[1] = api[keyAndType[0]].type;
        apiBody.ActionList = this.buildActionList(ticketPrimaryIDs, ticketAction, jsonForm, formData, actionValueMap);
        promises.push(
          this.utilocateApiService.invokeUtilocateApi({
            API_KEY: keyAndType[0],
            API_TYPE: keyAndType[1],
            API_BODY: apiBody,
          }),
        );
        break;
      case TicketActionIDs.CANCEL_TICKET_ACTION_ID:
        keyAndType[0] = apiKeys.u2.cancelTicketAction;
        keyAndType[1] = api[keyAndType[0]].type;
        apiBody = this.buildPrimaryActionDict(
          ticketPrimaryIDs,
          jsonForm,
          formData,
          actionValueMap,
          typeof jsonForm.meta['Check'] === 'boolean' ? jsonForm.meta['Check'] : true,
        );
        promises.push(
          this.utilocateApiService.invokeUtilocateApi({
            API_KEY: keyAndType[0],
            API_TYPE: keyAndType[1],
            API_BODY: apiBody,
          }),
        );
        break;
      case TicketActionIDs.CLEAR_ACTION_ID:
        keyAndType[0] = apiKeys.u2.clearTicketBulk;
        keyAndType[1] = api[keyAndType[0]].type;
        ticketPrimaryIDs.forEach((primaryID) => {
          promises.push(
            this.utilocateApiService.invokeUtilocateApi({
              API_KEY: keyAndType[0],
              API_TYPE: keyAndType[1],
              API_BODY: {
                [primaryID]: {
                  Check: true,
                  CompletionActionID: formData['clearTypeID'],
                },
              },
            }),
          );
        });
        break;
      case TicketActionIDs.CHANGE_EXCAVATION_DATE_ACTION_ID:
        keyAndType[0] = apiKeys.u2.changeExcavationDate;
        keyAndType[1] = api[keyAndType[0]].type;
        request = {
          API_KEY: keyAndType[0],
          API_TYPE: keyAndType[1],
          API_BODY: {
            [ticketPrimaryIDs[0]]: {
              Check: true,
              Value: ticketPrimaryIDs,
              NewDate: formData['excavationDate'],
              Explanation: formData['reason'],
            },
          },
        };
        promises.push(this.utilocateApiService.invokeUtilocateApi(request));
        break;
      case TicketActionIDs.GROUP_UTILITY_ACTION_ID:
        keyAndType[0] = apiKeys.u2.groupTickets;
        keyAndType[1] = api[keyAndType[0]].type;
        promises.push(
          this.utilocateApiService.invokeUtilocateApi({
            API_KEY: keyAndType[0],
            API_TYPE: keyAndType[1],
            API_BODY: {
              [ticketPrimaryIDs[0]]: {
                Check: true,
                Value: ticketPrimaryIDs,
              },
            },
          }),
        );
        break;
      case TicketActionIDs.UNGROUP_UTILITY_ACTION_ID:
        keyAndType[0] = apiKeys.u2.ungroupTickets;
        keyAndType[1] = api[keyAndType[0]].type;
        promises.push(
          this.utilocateApiService.invokeUtilocateApi({
            API_KEY: keyAndType[0],
            API_TYPE: keyAndType[1],
            API_BODY: {
              [ticketPrimaryIDs[0]]: {
                Check: true,
                Value: ticketPrimaryIDs,
              },
            },
          }),
        );
        break;
      case TicketActionIDs.SEND_TO_LSP_ACTION_ID:
      case TicketActionIDs.OFFICE_COMPLETE_TICKET_ACTION_ID:
      case TicketActionIDs.OFFICE_ONGOING_TICKET_ACTION_ID:
      case TicketActionIDs.SEND_TO_LSP_DEFAULT1_ACTION_ID:
      case TicketActionIDs.SEND_TO_LSP_DEFAULT2_ACTION_ID:
      case TicketActionIDs.UPDATE_SUBSTATUS_ACTION_ID:
      case TicketActionIDs.ESCALATE_TICKET_ACTION_ID:
      case TicketActionIDs.COMMENTS_TO_EXCAVATOR_ACTION_ID:
      case TicketActionIDs.DISPATCHER_REMARKS_ACTION_ID:
      case TicketActionIDs.SPLIT_AND_CLEAR_UTILITY_ACTION_ID:
      case TicketActionIDs.ADD_UTILITY_ACTION_ID:
      case TicketActionIDs.REMOVE_UTILITY_ACTION_ID:
      case TicketActionIDs.SPLIT_UTILITY_ACTION_ID:
      case TicketActionIDs.DUPLICATE_TICKET_ACTION_ID:
      case TicketActionIDs.UNCOMPLETE_TICKET_ACTION_ID:
        console.error('Action not implemented:', ticketAction.id);
        return [];
      default:
        break;
    }

    const responseArray: Array<PromiseSettledResult<HttpResponse<unknown>>> = await Promise.allSettled(promises);
    const [failed, success] = responseArray.reduce(
      (acc, response) => {
        if (response.status === 'fulfilled') {
          acc[1].push(response);
        } else {
          acc[0].push(response);
        }
        return acc;
      },
      [[], []],
    );
    const results = success.map(({ value: response }) => JSON.parse(response.body.value ?? response.body));

    // if there are errors, add to the error array 
    // Some lambdas might have succeeded, but they errored 
    // therefore, the promise is fulfilled but we should actually error 
    for (const r of results[0]) {
      if (r.Error) {
        failed.push({ reason: r.Error });
      }
    }

    if (failed.length > 0) {
      this.snackBarService.openSnackbar('Error: ' + failed.map((x) => x.reason).join(', '), SnackbarType.error);
    }
    return results.reduce((acc, result) => {
      if (Array.isArray(result)) {
        acc.push(...result);
      } else {
        acc.push(result);
      }
      return acc;
    }, []);
  }

  buildActionList(
    ticketPrimaryIDs: Array<number>,
    ticketAction: TicketAction,
    jsonForm: JsonForm,
    formData: Record<string, unknown>,
    actionValueMap: unknown,
  ) {
    const actionList = [];
    ticketPrimaryIDs.forEach((PrimaryID) => {
      const action: Record<string, unknown> = {
        ActionID: ticketAction.id,
        Action: ticketAction.actionName,
        PrimaryID,
      };

      if (jsonForm.controls.length > 0 && actionValueMap['Value'] !== undefined) {
        const val = formData[actionValueMap['Value']];
        if (val === undefined) {
          this.snackBarService.openSnackbar('Error: Value not found', SnackbarType.error);
          return;
        } else if (Array.isArray(val)) {
          action.Value = val.map((x) => x.value).join(',');
        } else {
          action.Value = val;
        }
      }
      actionList.push(action);
    });
    return actionList;
  }

  buildPrimaryActionDict(
    ticketPrimaryIDs: Array<number>,
    jsonForm: JsonForm,
    formData: Record<string, unknown>,
    actionValueMap: unknown,
    checkValue?: unknown,
  ): Record<number, unknown> {
    const actionDict = {};
    ticketPrimaryIDs.forEach((PrimaryID) => {
      actionDict[PrimaryID] = {
        Check: checkValue,
      };
      if (jsonForm.controls.length > 0 && Object.keys(actionValueMap).length > 0) {
        Object.keys(actionValueMap).forEach((key) => {
          const val = formData[actionValueMap[key]];
          if (val === undefined) {
            this.snackBarService.openSnackbar('Error: Value not found', SnackbarType.error);
            return;
          } else if (Array.isArray(val)) {
            actionDict[PrimaryID][key] = val.map((x) => x.value).join(',');
          } else {
            actionDict[PrimaryID][key] = val;
          }
        });
      }
    });
    return actionDict;
  }

  get ticketActions$() {
    return this._ticketActions$.pipe(distinctUntilChanged());
  }

  get ticketActions() {
    return this._ticketActions$.value;
  }

  getDataSourceFromName<T>(sourceName: string): Array<{ name: string; value: T }> {
    return [{ name: sourceName, value: null }];
  }
}

export type TicketAction = {
  id: number;
  actionName: string;
  visibleName: string;
  actionVisible: number;
};


