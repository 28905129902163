import { Component, Inject, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../material.module';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirmationAction: Function;
  confirmColor: string = 'primary';
  confirmText: string = 'Continue';
  cancelText: string = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.title = data.title;
    this.message = data.message;
    this.confirmationAction = data.confirmationAction;
    if (data.confirmColor) {
      this.confirmColor = data.confirmColor;
    }
    if (data.confirmText) {
      this.confirmText = data.confirmText;
    }
    if (data.cancelText) {
      this.cancelText = data.cancelText;
    }
  }
}

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [MaterialModule, FlexLayoutModule],
  exports: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}

export interface DialogData {
  title: string;
  message: string;
  confirmationAction: Function;
  confirmColor?: string;
  confirmText?: string;
  cancelText?: string;
}
