import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: number = 0): string {
    const str = value.toString();
    const len = str.length;

    if (len <= 4) {
      return str;
    } else if (len < 7) {
      return `${(Math.round(value / 1000) * 1000)
        .toString()
        .slice(0, len - 3)}K`;
    } else if (len < 10) {
      return `${(Math.round(value / 1000000) * 1000000)
        .toString()
        .slice(0, len - 6)}M`;
    } else {
      return "o/l";
    }
  }
}

@Pipe({
  name: "abbreviate",
  standalone: true,
})
export class AbbreviatePipe implements PipeTransform {
  transform(value: string = ""): string {
    const len = value?.length;
    if (len && len >= 12) {
      return `${value.slice(0, 11)}...`;
    } else {
      return value;
    }
  }
}
