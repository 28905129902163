import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

interface IconData {
  id: number;
  label: string;
  path: string;
}

@Injectable({
  providedIn: "root",
})
export class CustomIconService {
  icons: IconData[];
  private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private http: HttpClient = inject(HttpClient);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);
  constructor() {}

  public registerIcons(): Observable<unknown> {
    return this.http.get("/assets/icons/icons.json").pipe(
      switchMap((res: IconData[]) => from(res)),
      map((icon: IconData) =>
        this.matIconRegistry.addSvgIcon(
          icon.label,
          this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path),
        ),
      ),
    );
  }
}
