import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingComponent } from "./landing/landing.component";
import { LoginModule } from "src/app/modules/shared/login/login.module";

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, LoginModule],
  exports: [LandingComponent],
})
export class LandingModule {}
