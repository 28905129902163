<div class="flex items-center justify-between">
  <div class="flex items-center">
    <mat-icon *ngIf="getIcon() !== ''" icon class="h-5 w-5" [svgIcon]="getIcon()"></mat-icon>
    <span class="font-montserrat text-md ml-2 max-w-xs">{{ data.message }}</span>
  </div>
  <!-- Removed the action button as this is not set up yet  -->
  <!-- <button 
  [style.color]="getButtonTextColor()"
  class="h-5 w-5 font-montserrat text-md font-normal" 
  gIf="data.actionLabel" 
  [color]="data.actionLabelColour"
  mat-button 
  (click)="handleActionClick()">
    {{ data.actionLabel }}
  </button> -->
</div>
