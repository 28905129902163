import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-unit-modal",
  templateUrl: "./unit-modal.component.html",
})
export class UnitModalComponent {
  unitOptionsMetric: string[] = [
    "square-inches",
    "square-feet",
    "square-us-feet",
    "square-yards",
    "square-miles",
    "square-meters",
    "square-kilometers",
    "acres",
    "hectares",
  ];

  unitOptionsImperial: string[] = [
    "inches",
    "feet",
    "us-feet",
    "yards",
    "miles",
    "meters",
    "kilometers",
  ];

  selectedUnit: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UnitModalComponent>
  ) {}

  onUnitSelected(): void {
    this.dialogRef.close(this.selectedUnit);
  }
}
