import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ADMIN_TABLE_NAMES } from "src/app/modules/core/admin/tables";
import { CacheWhereClause, CacheWhereClauseType } from "src/app/modules/core/cache/cache.interface";
import { UtilocateAdminCacheService } from "src/app/modules/core/cache/utilocate-admin.service";
import { TicketService } from "../../ticket.service";
import { FormControl, Validators } from "@angular/forms";
@Component({
  selector: 'app-create-audit-modal',
  templateUrl: './create-audit-modal.component.html',
  styleUrls: ['./create-audit-modal.component.scss']
})
export class CreateAuditModalComponent implements OnInit {

  selectedTask: string;
  selectedType: string;
  requestNumber: string;
  utilityArray: any
  data: any
  requestNumberFormControl = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private utilocateAdminService: UtilocateAdminCacheService,
    private ticketService: TicketService,
    public dialogRef: MatDialogRef<CreateAuditModalComponent>
  ) {
    this.data = Data;
  }
  ngOnInit() {    
    if(this.data){
      this.selectedType = this.data.type[0]
  }
  this.getFilteredUtilitesTypes()
  }
  
  /**
 * Gets request number if in ticket or entered
 * @returns Request Number
 */

  async getRequestNumber(){
    if(!this.data.showRequestField){
      let AssignmentID = sessionStorage.getItem("AssignmentID");
     let ticket = await this.ticketService.getTicketAssignment(AssignmentID)
     return ticket["RequestNumber"]
    }
    return this.requestNumberFormControl.value
  }

/**
 * Gets utility ids associated with call type id when calltype is selected in dropdown
 */

  async getFilteredUtilitesTypes(){
    let callType = this.selectedType["CallTypeID"]
    let whereClause: CacheWhereClause = {
      Column: "CallTypeID",
      Value: callType,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    let CallTypeDescResult: object[] | Error =
      await this.utilocateAdminService.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_UtilityToCallType,
        [whereClause]
      );

    if (!(CallTypeDescResult instanceof Error)) {
      const utilityIDs = CallTypeDescResult.map(item => item["UtilityID"]);
      this.utilityArray = this.data.task.filter(item => utilityIDs.includes(item.UtilityID));
      this.selectedTask = this.utilityArray[0]
    } else {
      throw CallTypeDescResult;
    }
  }

  /**
 * Checks if request number is filled before closing modal and sends object to ticket service
 */

  async onConfirm(event) {
    let requestNum = await this.getRequestNumber()
    let result = {requestNumber: requestNum, utilityID: this.selectedTask["UtilityID"] }
    this.requestNumberFormControl.markAsTouched();
    if(!this.requestNumberFormControl.errors || !this.data.showRequestField){  
      this.dialogRef.close({ event: "close", result: result });
    }
  }
}

 