import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AutologGroup} from './ticket-autologs-item/ticket-autologs-item.component';

@Pipe({
  name: 'filterAutolog'
})
export class FilterAutologPipe implements PipeTransform {
  transform(groups: AutologGroup[], searchText: string): AutologGroup[] {
    if (!groups) return [];
    if (!searchText) return groups;
    searchText = searchText.toLowerCase().trim();

    return groups.filter(group => {
      // Check if any item in the group's list matches the searchText
      return group.list.some(item => {
        const formattedDate = new DatePipe('en-US').transform(item.logDate, 'EEEE dd MMM yyyy').toLowerCase();
        return formattedDate.includes(searchText) ||
          item.DescIDDesc.toLowerCase().includes(searchText)// Include Explanation in the search
      });
    });
  }
}
