<div class="tasks-body">
  <ng-container *ngIf="!editClicked">
  <div class="tasks-container">
    <div class="task">
    <span>{{convertedTask}}</span>
    <div class="times">
    <span class="start-time">Start: {{chosenStartTime}}</span>
    <span>End: {{chosenStopTime}}</span>
    </div>
  </div>
</div>
<button mat-icon-button (click)="editTask()" class="edit-btn">
  <mat-icon>edit</mat-icon>
</button>
  </ng-container>
  <ng-container *ngIf="editClicked">
    <div class="tasks-container">
    <div class="task-edit">
      <mat-form-field class="dropdown" appearance="outline">
        <mat-select [(value)]="selectedOption">
          <mat-option
            *ngFor="let task of TaskArray[0]"
            [value]="task"
            >{{ task }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div class="times-edit">
      <span>Start:&nbsp;</span>
      <span [ngxTimepicker]="startTime" [(ngModel)]="chosenStartTime" [format]="24" [max]="chosenStopTime" class="start-time-edit">{{chosenStartTime}} </span>
      <ngx-material-timepicker [theme]="timePickerTheme" #startTime></ngx-material-timepicker>
      <span>End:&nbsp;</span>
      <span [ngxTimepicker]="stopTime" [(ngModel)]="chosenStopTime" [format]="24" [min]="chosenStartTime" class="stop-time-edit">{{chosenStopTime}}</span>
      <ngx-material-timepicker [theme]="timePickerTheme" #stopTime></ngx-material-timepicker>
      </div>
    </div>
  </div>
  <button mat-icon-button (click)="saveTask()" class="edit-btn">
    <mat-icon>save</mat-icon>
  </button>
  </ng-container>
 

</div>