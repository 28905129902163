<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  style="height: 100%; padding-bottom: 72px; overflow-x: hidden"
  (swiperight)="onSwipe($event)"
  (swipeleft)="onSwipe($event)">
  <!-- This line shows if there are documents on the server. It calculates this based off
  the difference between the count of documents in the list and the number of entries in 
  tbSync_ExpectedDocumentHash. May need a refactor. Removing for now 2023-09-29 -->
  <!-- <div class="sync-banner ng-star-inserted" *ngIf="ExpectedDocHashDifference > 0">
    <span class="mat-subtitle-2">{{ExpectedDocHashDifference}} document{{ExpectedDocHashDifference > 1 ? 's' : ''}} on server </span>
  </div> -->

  <div class="h-9 p-3 absolute w-full bg-[white] z-[5]">
    <div class="flex items-baseline" *ngIf="isHeldDown">
      <button class="mr-[6px] p-0 h-6 w-6" mat-icon-button (click)="clearSelected()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <button class="mr-[6px]" mat-button *ngIf="userCanDeleteSelection()" (click)="multiDelete()" color="warn">
        <mat-icon class="h-6 w-6" svgIcon="trash"></mat-icon>
        DELETE
      </button>
      <button mat-stroked-button (click)="onMultiMarkSendableClick()">
        <mat-icon svgIcon="sendable" class="h-6 w-6">send</mat-icon>
        MARK AS SENDABLE
      </button>
    </div>
  </div>
  <div class="pt-3 pr-3 absolute pointer-events-none w-full flex justify-end items-start z-50">
    <app-floating-tab-select class="ios-shadow-fix mr-3 rounded-md" [tabs]="tabs" [(activeTab)]="selection" [multiple]="false" />
  </div>

  <mat-tab-group [selectedIndex]="selection[0]" (selectedIndexChange)="selected.setValue($event)" class="pt-[60px]">
    <mat-tab>
      <div *ngFor="let document of DocumentsList" class="overflow-x-hidden">
        <app-ticket-documents-item
          [DocumentID]="document.DocumentID"
          [FileName]="document.FileName"
          [DocumentTypeID]="document.DocumentTypeID"
          [CreationDate]="document.CreationDate"
          [isSendable]="document.isSendable"
          [isS3Document]="document.isS3Document"
          [isDownloaded]="document.isDownloaded"
          [bFieldAdded]="document.bFieldAdded"
          [AssignmentID]="AssignmentID"
          [Caption]="document.Caption"
          [File]="document.file"
          [Selected]="document.selected"
          (documentSelect)="onDocumentSelect($event)"
          (documentDelete)="onDocumentDelete($event)"
          (rowSelected)="selectedRows($event)"
          (captionChange)="onCaptionChangeEvent($event)"
          (markSendable)="OnMarkSendable($event)"></app-ticket-documents-item>
      </div>
    </mat-tab>
    <mat-tab>
      <app-ticket-documents-gallery
        [imageUrls]="DocumentsList"
        (rowSelected)="selectedRows($event)"
        (documentSelect)="onDocumentSelect($event)"></app-ticket-documents-gallery>
    </mat-tab>
  </mat-tab-group>
</div>

<input
  #fileInputInternal
  style="display: none"
  type="file"
  accept=".pdf,.txt,.eml,.jpg,.jpeg,.png"
  (change)="openFile($event)"
  multiple />

  <!-- Only accept jpg and jpeg doc types for aux images for now -->
  <!-- PDF Generator can't handle other doc types  -->
<input
  #fileInputAux
  style="display: none"
  type="file"
  accept=".jpg,.jpeg,"
  (change)="openAuxDocument($event)"
 />

<floating-action-menu [hidden]="bottomMenuOpen" [menuItems]="fabMenu" type="fab"></floating-action-menu>

<!-- <input
  #fileInputInternal
  style="display: none"
  type="file"
  (change)="handleFileInput($event.target.files, 4)"
/>
<input
  #fileInputOffice
  style="display: none"
  type="file"
  (change)="handleFileInput($event.target.files, 10)"
/> -->
