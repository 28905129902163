import { Injectable } from "@angular/core";
import { forkJoin, from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { UploadProgress } from "./upload-progress";

export interface UploadDocumentOptions {
  AssignmentID: number;
  Description: string;
  FileName: string;
  DocumentTypeID: number;
  isSendable: number;
  File: any;
  isUnZipped: number;
  RequestNumber: string;
}

@Injectable({
  providedIn: "root",
})
export class UploadDocumentsService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {
    this.logger$.log("Created Upload Documents Service");
  }

  uploadDocuments(
    documentUploadObj: UploadProgress
  ): Observable<UploadProgress> {
    return new Observable((subscriber) => {
      let { AssignmentID } = JSON.parse(documentUploadObj.body);
      let { Documents } = JSON.parse(documentUploadObj.body)["row"];

      if (!Documents || Documents.length == 0) {
        subscriber.next(
          new UploadProgress(
            documentUploadObj.body,
            true,
            documentUploadObj.msg + "Documents API Response: No Docs to Upload",
            true
          )
        );
        subscriber.complete();
      }

      if (AssignmentID && Documents && Documents.length > 0) {
        let documentsToUpload = Documents.reduce((arr, document) => {
          if (!document["uploaded"]) {
            arr.push(
              this.uploadDocument(JSON.stringify({ AssignmentID, document }))
            );
          }
          return arr;
        }, []);

        forkJoin(...documentsToUpload).subscribe(
          (documentsApiResults: any) => {
            for (let i = 0; i < documentsApiResults.length; i++) {
              if (!documentsApiResults[i].ok) {
                let progress = {
                  ...JSON.parse(documentUploadObj.body),
                  DocumentResult: false,
                };
                subscriber.next(
                  new UploadProgress(
                    JSON.stringify(progress),
                    true,
                    documentUploadObj.msg +
                      "Documents API Response: Failed to attach",
                    true
                  )
                );
                subscriber.complete();
              }
            }

            let progress = {
              ...JSON.parse(documentUploadObj.body),
              DocumentResult: documentsApiResults,
            };
            subscriber.next(
              new UploadProgress(
                JSON.stringify(progress),
                true,
                documentUploadObj.msg + "Documents API Response: Success"
              )
            );
            subscriber.complete();
          },
          (error) => {
            this.logger$.error(error);
            let progress = {
              ...JSON.parse(documentUploadObj.body),
              DocumentResult: false,
            };
            subscriber.next(
              new UploadProgress(
                JSON.stringify(progress),
                true,
                documentUploadObj.msg +
                  "Documents API Response: Failed to attach",
                true
              )
            );
            subscriber.complete();
          }
        );
      } else {
        subscriber.next(
          new UploadProgress(
            documentUploadObj.body,
            true,
            documentUploadObj.msg +
              "Documents API Response: No Docs found to Upload",
            true
          )
        );
        subscriber.complete();
      }
    });
  }

  uploadDocument(uploadDocInfo): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const { AssignmentID, document } = JSON.parse(uploadDocInfo);

        const url = apiKeys.u2.uploadDocument;
        const type = api[url].type;
        const body = {
          AssignmentID,
          Description: "Uploaded from U4",
          FileName: document["name"],
          DocumentTypeID: document["docTypeID"],
          isSendable: "0",
          File: document["file"],
          isUnZipped: "0",
          RequestNumber: "",
        };

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.uploadDocument,
          API_TYPE: type,
          API_BODY: body,
        };
        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((apiResult) => {
          subscriber.next(apiResult);
          subscriber.complete();
        });
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  uploadDocumentWithOptions(options: UploadDocumentOptions): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.uploadDocument;
        const type = api[url].type;
        const body = options;

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.uploadDocument,
          API_TYPE: type,
          API_BODY: body,
        };
        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((apiResult) => {
          subscriber.next(apiResult);
          subscriber.complete();
        });
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }
}
