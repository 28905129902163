import { Component, inject } from '@angular/core';
import { SavedSearchDrawerComponent } from './saved-search-drawer/saved-search-drawer.component';
import { TicketListTableComponent } from './ticket-list-table/ticket-list-table.component';
import { FloatingTabSelectComponent } from '../../shared/components/misc/selection/floating-tab-select/floating-tab-select.component';
import { MatIconModule } from '@angular/material/icon';
import { MapFeatureInspectorComponent } from '../../shared/components/maps/open-layers/map-feature-inspector/map-feature-inspector.component';
import { TicketListMapComponent } from './ticket-list-map/ticket-list-map.component';
import { AdvancedTicketSearchComponent } from '../../shared/components/misc/advanced-ticket-search/advanced-ticket-search.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragHandleComponent } from '../../shared/components/layouts/vertical-split/drag-handle/drag-handle.component';
import { VerticalSplitComponent } from '../../shared/components/layouts/vertical-split/vertical-split.component';
import { Subject } from 'rxjs';
import { HomeWorkspaceService } from './home-workspace.service';
import { TicketSearchService } from '../../shared/services/ticket-search/ticket-search.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SidebarListComponent } from './sidebar-list/sidebar-list.component';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { UserService } from 'src/app/modules/core/services/user/user.service';

@Component({
  selector: 'app-home-workspace',
  templateUrl: './home-workspace.component.html',
  styleUrls: ['./home-workspace.component.scss'],
  standalone: true,
  providers: [],
  imports: [
    SavedSearchDrawerComponent,
    TicketListTableComponent,
    FloatingTabSelectComponent,
    MatIconModule,
    MapFeatureInspectorComponent,
    TicketListMapComponent,
    AdvancedTicketSearchComponent,
    DragDropModule,
    DragHandleComponent,
    VerticalSplitComponent,
    AsyncPipe,
    SidebarListComponent,
    CommonModule,
  ],
})
export class HomeWorkspaceComponent {
  // services
  protected myService = inject(HomeWorkspaceService);
  protected ticketSearchService: TicketSearchService = inject(TicketSearchService);
  protected settingsService = inject(UserService);
  protected _tabs = [
    { index: 0, title: 'map', icon: 'map' },
    { index: 1, title: 'list', icon: 'list' },
  ];
  protected resizeTrigger = new Subject<void>();
  protected readonly SettingID = SettingID;
  protected currentTabView = new Subject<number[]>();

  sidebarExpanded: boolean = false;
}
