import { CommonModule } from "@angular/common";
import { Component, NgModule, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { ComponentMessagingService } from "../core/component-messaging/component-messaging.service";
import { LoggerService } from "../core/services/logger/logger.service";
import { MaterialModule } from "../shared/material.module";
import { TicketDetailsService } from "../shared/ticket-details/ticket-details.service";
import { CreateTicketComponentService } from "./create-ticket-component.service";
import { CreateTicketComponentModule } from "./create-ticket/create-ticket.component";
import { DatetimeService } from "../core/services/datetime/datetime.service";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.scss"],
})
export class CreateTicketPageComponent implements OnInit {
  className: string = "CreateTicketPageComponent";
  ticketTemplateViews: {};
  createTicketOptions = {
    isMinified: false,
    prefillFields: {},
    documents: [],
  };
  childProps = {};

  constructor(
    private logger$: LoggerService,
    private datetime: DatetimeService,
    private routerService: Router,
    private route: ActivatedRoute,
    private createTicket$: CreateTicketComponentService,
    private ticketDetailsService$: TicketDetailsService
  ) {}

  ngOnInit(): void {
    try {
      this.handleRouteQueryParams();
      this.childProps["createTicketOptions"] = this.createTicketOptions;
      this.setupCreateTicketSettings()
        .then((val) => {
          return this.createTicket$.getCreateTicketTemplate().toPromise();
        })
        .then((msg) => {
          if (msg) {
            this.childProps["views"] = msg["views"];
            this.childProps["commonViews"] = msg["commonViews"];
            this.setupOptions().then(() => {
              this.childProps["createTicketOptions"]["prefillFields"] =
                this.createTicketOptions.prefillFields;
              this.childProps["createTicketOptions"]["isMinified"] =
                this.createTicketOptions.isMinified;
              this.childProps["createTicketOptions"]["documents"] =
                this.createTicketOptions.documents;
              this.childProps = { ...this.childProps };
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      this.logger$.error(this.className + "ngoninit: " + error);
    }
  }

  private handleRouteQueryParams() {
    const queryParams: any = this.route.snapshot.queryParams;
    const URL_KEYS = localStorageKeys.URL_KEYS;

    if (queryParams["AssignmentID"]) {
      sessionStorage.setItem(
        URL_KEYS.assignmentid,
        queryParams["AssignmentID"]
      );
    }

    if (queryParams["PrimaryID"]) {
      sessionStorage.setItem(URL_KEYS.primaryid, queryParams["PrimaryID"]);
    }

    if (this.route.snapshot.queryParams["Authorization"]) {
      if (this.route.snapshot.params["app"]) {
        this.routerService.navigate(
          [
            this.route.snapshot.routeConfig.path.replace(
              ":app",
              this.route.snapshot.params["app"]
            ),
          ],
          {
            queryParams: {
              ...this.route.snapshot.queryParams,
              Authorization: null,
            },
            queryParamsHandling: "merge",
            replaceUrl: true,
          }
        );
      } else {
        this.routerService.navigate([this.route.snapshot.routeConfig.path], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            Authorization: null,
          },
          queryParamsHandling: "merge",
          replaceUrl: true,
        });
      }
    }
  }

  async setupCreateTicketSettings() {
    try {
      await this.createTicket$.setupCreateTicketSettings();
    } catch (error) {
      console.error(error.message);
    }
    return true;
  }

  async setupOptions() {
    try {
      let AssignmentID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.assignmentid
      );
      let PrimaryID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.primaryid
      );

      if (AssignmentID && PrimaryID) {
        await this.generatePrefillFieldsFromTicket(AssignmentID, PrimaryID);
        // this.clearLocalStorageFields()
      }
    } catch (error) {
      this.logger$.error("setup setupOptions: " + error.message);
    }
  }

  async generatePrefillFieldsFromTicket(AssignmentID, PrimaryID) {
    try {
      //TODO Setting 187 I believe is custom setting for transnorthern where RequestNumber
      let result = await this.ticketDetailsService$.downloadTicket(
        AssignmentID,
        PrimaryID
      );
      if (
        result["tbCompletions_Assignments"] &&
        result["tbCompletions_AuxiliaryDetails"]
      ) {
        let assignmentsRows = result["tbCompletions_Assignments"]["Data"][0];
        let keys = Object.keys(assignmentsRows);
        keys.forEach((key) => {
          if (key == "CallTypeID") {
            this.createTicketOptions.prefillFields["calltype"] = {
              value: assignmentsRows[key],
            };
          } else if (key == "RequestNumber") {
            console.log("ignore", key);
          } else {
            this.createTicketOptions.prefillFields[key] = assignmentsRows[key];
          }
        });

        this.createTicketOptions.prefillFields["CallDate"] = "";
        this.createTicketOptions.prefillFields["UpdateOf"] =
          assignmentsRows["RequestNumber"];
        this.createTicketOptions.prefillFields["worksiteAddress"] =
          assignmentsRows["StartHouseNumber"] +
          " " +
          assignmentsRows["LocateAddress"] +
          " " +
          assignmentsRows["LocateSubRegionName"];
        // this.createTicketOptions.prefillFields["OriginalExcavationDate"] = (() => {
        //   if (this.datetime.isStringVaildDate(assignmentsRows["ExcavationDate"])) {
        //       return this.datetime.dbDateToLocalDate(assignmentsRows["ExcavationDate"]).toISOString();
        //     } else {
        //       return "";
        //     }
        //   })();

        let auxRows = result["tbCompletions_AuxiliaryDetails"]["Data"];
        let utilityIDs = [];
        auxRows.forEach((auxRow) => {
          utilityIDs.push({ value: auxRow["UtilityID"] });
        });
        //TODO fix bug utilities not being set properly.
        this.createTicketOptions.prefillFields["utilities"] = utilityIDs;

        if (
          result.tbCompletions_PrimaryDetails &&
          result.tbCompletions_PrimaryDetails.Data &&
          result.tbCompletions_PrimaryDetails.Data.length > 0
        ) {
          let preFillHideShow = [];
          // console.log(result['tbCompletions_PrimaryDetails']);
          result.tbCompletions_PrimaryDetails.Data.forEach((row) => {
            if (row["isUpdated"] == 1) {
              preFillHideShow.push(String(row.PrimaryDetailsFieldID));
            }
          });
          this.createTicketOptions.prefillFields["primaryDetailsHideShow"] =
            preFillHideShow;
        }
      }

      if (
        result.tbCompletions_Documents &&
        result.tbCompletions_Documents.Data &&
        result.tbCompletions_Documents.Data.length > 0
      ) {
        this.createTicketOptions.documents =
          result.tbCompletions_Documents.Data;
      }
    } catch (error) {
      this.logger$.error("Unable to gather ticket info for prefilling");
      this.logger$.error(error.message);
    }
  }
}

@NgModule({
  declarations: [CreateTicketPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    CreateTicketComponentModule,
  ],
  exports: [CreateTicketPageComponent],
  providers: [ComponentMessagingService],
})
export class CreateTicketModule {}
