import { Component, NgModule, OnInit, ViewChild } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { Router, ActivatedRoute } from "@angular/router";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { AuthenticationService } from "src/app/modules/core/authentication/authentication.service";
import { GraphicInfo } from "src/app/modules/core/cache/GraphicsInterface";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { DownloadDocumentService } from "src/app/modules/core/services/document/download-document.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { UploadAutologService } from "src/app/modules/core/services/logger/upload-autolog.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { FloatingActionMenuComponent } from "../../fab/floating-action-menu/floating-action-menu.component";
import { MaterialModule } from "../../material.module";
import { ProgressBarService } from "../../progress-bar/progress-bar.service";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { SnackbarType } from "../../snackbar/snackbar/snackbar";
import {
  EsriMapSimpleDrawingComponent,
  EsriMapSimpleDrawingModule,
} from "../esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component";
import { EsriMapModule } from "../esri-map-set/esri-map/esri-map.component";
import { EsriMapViewerComponent } from "../esri-map-viewer/esri-map-viewer.component";
import { EsriMapService } from "../esri-map.service";
import { MapService } from "src/app/modules/core/services/map/map.service";
import { TicketDetailsService } from "../../ticket-details/ticket-details.service";

@Component({
  selector: "app-esri-map-digsite",
  templateUrl: "./esri-map-digsite.component.html",
  styleUrls: ["./esri-map-digsite.component.scss"],
})
export class EsriMapDigsiteComponent
  extends EsriMapViewerComponent
  implements OnInit
{
  className = "EsriMapDigsiteComponent";
  graphics: GraphicInfo[];
  @ViewChild("esriDrawing") esriDrawing: EsriMapSimpleDrawingComponent;

  constructor(
    logger$: LoggerService,
    esriMap$: EsriMapService,
    private _route: ActivatedRoute,
    private _routerService: Router,
    userService: UserService,
    progressBarService: ProgressBarService,
    snackBarService: SnackbarService,
    downloadDoc$: DownloadDocumentService,
    autolog$: UploadAutologService,
    datetime$: DatetimeService,
    auth$: AuthenticationService,
    mapService$: MapService,
    ticketDetailsService$:TicketDetailsService
  ) {
    super(
      logger$,
      esriMap$,
      _route,
      _routerService,
      userService,
      progressBarService,
      snackBarService,
      downloadDoc$,
      autolog$,
      datetime$,
      auth$,
      mapService$,
      ticketDetailsService$
    );
  }

  test = () => {
    console.log("test");
  };

  ngOnInit(): void {
    const queryParams: any = this._route.snapshot.queryParams;
    const URL_KEYS = localStorageKeys.URL_KEYS;

    if (queryParams["AssignmentID"]) {
      sessionStorage.setItem(
        URL_KEYS.assignmentid,
        queryParams["AssignmentID"],
      );
    }

    if (queryParams["assignmentid"]) {
      sessionStorage.setItem(
        URL_KEYS.assignmentid,
        queryParams["assignmentid"],
      );
    }

    if (this._route.snapshot.queryParams["Authorization"]) {
      if (this._route.snapshot.params["app"]) {
        this._routerService.navigate(
          [
            this._route.snapshot.routeConfig.path.replace(
              ":app",
              this._route.snapshot.params["app"],
            ),
          ],
          {
            queryParams: {
              ...this._route.snapshot.queryParams,
              Authorization: null,
            },
            queryParamsHandling: "merge",
            replaceUrl: true,
          },
        );
      } else {
        this._routerService.navigate([this._route.snapshot.routeConfig.path], {
          queryParams: {
            ...this._route.snapshot.queryParams,
            Authorization: null,
          },
          queryParamsHandling: "merge",
          replaceUrl: true,
        });
      }
    }

    super.ngOnInit();
    // this.getDigsite();
  }

  async saveDigsitePressed() {
    let graphics = await this.esriDrawing.getGraphics();

    let digsites = this.setDigsiteObjs(graphics);
    this.saveDigsite(digsites);
  }

  setDigsiteObjs(graphics: GraphicInfo[]) {
    var digsites = [];

    for (let i = 0; i < graphics.length; i++) {
      let graphic: GraphicInfo = graphics[i];

      var coordinates = [];

      for (let ii = 0; ii < graphic.coordinates.length; ii++) {
        let coord = graphic.coordinates[ii];
        let digsiteCoord = {
          Latitude: coord["lat"],
          Longitude: coord["lng"],
        };
        coordinates.push(digsiteCoord);
      }

      var digsiteObj = {
        StatusID: graphic.status,
        Coordinates: coordinates,
        DigsiteTypeID: graphic.type,
      };

      if (graphic.graphicID) {
        digsiteObj["DigsiteShapeID"] = graphic.graphicID;
      }

      digsites.push(digsiteObj);
    }

    return digsites;
  }

  getDigsite() {
    this.esriMap$.gatherDigsites().subscribe((results) => {
      var digsites = JSON.parse(results["value"]);
      var graphics: GraphicInfo[] = [];

      digsites.DigsiteData.forEach((digsite) => {
        var newGraphic: GraphicInfo = {
          graphicID: digsite["DigsiteShapeID"],
          type: digsite["DigsiteTypeID"],
          coordinates: JSON.parse(digsite["Coordinates"]),
        };
        graphics.push(newGraphic);
      });

      console.log("graphics: ", graphics);

      this.graphics = graphics;
    });
  }

  saveDigsite(digsites) {
    try {
      this.esriMap$.saveDigsite(digsites).subscribe((result) => {
        if (result && result["PossibleError"] == null) {
          this.getDigsite();
          this.snackBarService.openSnackbar(
            "Successfully Saved Digsites",
            SnackbarType.success,
          );
        }
      });
    } catch (error) {
      this.logger$.error(this.className, ", saveDigsite: ", error);
    }
  }

  toggleBottomMenu() {
    super.toggleBottomMenu();
  }

  toggleIdentify() {
    this.esriDrawing.toggleIdentify();
  }

  toggleLayers() {
    this.esriDrawing.toggleLayers();
  }

  toggleLegend() {
    this.esriDrawing.toggleLegend();
  }

  toggleBasemap() {
    this.esriDrawing.toggleBasemap();
  }

  // takeScreenshot() {
  //   this.esriDrawing.takeScreenshot();
  // }

  getDirections = () => {
    this.esriDrawing.getDirections();
  };
}

@NgModule({
  declarations: [EsriMapDigsiteComponent],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    EsriMapModule,
    EsriMapSimpleDrawingModule,
    FloatingActionMenuComponent,
  ],
  exports: [EsriMapDigsiteComponent],
})
export class EsriMapDigsiteModule {}
