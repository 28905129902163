import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SyncModalService } from "../../services/notification/sync-modal.service";

@Component({
  selector: "app-ticket-sync-modal",
  templateUrl: "./ticket-sync-modal.component.html",
  styleUrls: [],
})
export class TicketSyncModalComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  uploadStatuses: any;
  downloadStatuses: any;
  errorInSync: boolean;

  constructor(private syncModalService: SyncModalService) {
    this.uploadStatuses = {};
    this.downloadStatuses = {};
    this.errorInSync = false;
  }

  ngOnInit() {
    this.syncModalService
      .getSyncDownload()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event["key"]) {
          let key = event["key"].toString();
          this.downloadStatuses[key] = event;
        }

        if (event["progress"] == 2) {
          this.errorInSync = true;
        }
      });

    this.syncModalService
      .getSyncUpload()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event["key"]) {
          let key = event["key"].toString();
          this.uploadStatuses[key] = event;
        }

        if (event["progress"] == 2) {
          this.errorInSync = true;
        }
      });
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.uploadStatuses = {};
    this.downloadStatuses = {};
    this.errorInSync = false;
  }
}
