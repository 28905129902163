import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutomatedEngineService } from '../automated-engine.service';
import { TableService } from '../../shared/table/table.service';
import { TemplateColumn, ColumnObject, TableModule } from '../../shared/table/table.module';
import { TableComponent } from '../../shared/table/table/table.component';
import { DataTableBodyCellComponent, DataTableRowWrapperComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ModalService } from '../../shared/modals/modal.service';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { RuleBuilderListItemComponent } from '../rule-builder-list-item/rule-builder-list-item.component';
import { Action, AdminTriggers, Criteria, Rule } from '../rule-builder-models';
import { SettingID } from '../../core/services/user/setting';
import { UserService } from '../../core/services/user/user.service';
import { trigger, transition, style, animate } from '@angular/animations';

export const slideInAnimation = trigger('slideIn', [
  transition('void => *', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms ease-in', style({ transform: 'translateX(0)' })),
  ]),
]);

const PERM_TYPES = {
  Delete: 1,
  Update: 2,
  Add: 3,
  View: 4,
};
@Component({
  selector: 'app-rule-builder-list',
  templateUrl: './rule-builder-list.component.html',
  styleUrls: ['./rule-builder-list.component.scss'],
  providers: [TableService],
})
export class RuleBuilderListComponent implements OnInit {
  selectionType: SelectionType = SelectionType.multi;
  showArchived = false;
  USER_MODAL_ID = 'id_user_dialog';
  ruleTypeID: number; // Declare a property to hold the ruleTypeID
  rules: Rule[];
  ruleTypeName: string;
  adminTriggers: AdminTriggers[];
  rulesForDisplaying: any;
  permissionType: number;
  viewOnly: any;
  displayedColumnNames: string[] = ['Name', 'Description', 'Trigger', 'Active'];
  invisibleColumnNames: string[] = ['RuleID'];
  searchTerm: string;

  displayedColumns: TemplateColumn[] = this.displayedColumnNames.map((col, index) => {
    return {
      TemplateColumnID: 0,
      ColumnOrder: index,
      TemplateID: 0,
      Field: 0,
      Visible: 1,
      Width: 300,
      Title: col,
    } as TemplateColumn;
  });

  constructor(
    private route: ActivatedRoute,
    private automatedEngineService: AutomatedEngineService,
    private modalService: ModalService,
    public dialog: MatDialog,
    private userService: UserService,
    public tableService: TableService<any>
  ) {
    this.rulesForDisplaying = [];
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit(): Promise<void> {
    this.permissionType = Number(this.userService.getSettingValue(SettingID.RULE_BUILDER));
    this.viewOnly = this.permissionType == PERM_TYPES['Delete'] ? false : true;
    this.route.params.subscribe((params) => {
      this.ruleTypeID = +params['ruleTypeID'];
      try {
        //have to use .then since rulesByRuleType needs some admin tables
        this.getAdminRuleName().then((params) => {
          return this.getRulesByRuleType();
        });
      } catch (error) {
        console.error(error);
      }
    });
  }

  /**
   * Sets ruleTypeName variable to db name
   */
  async getAdminRuleName() {
    const getAdminTables = await this.automatedEngineService.getAdminTables();
    const adminRule = getAdminTables.AdminRuleTypes.find(
      (adminRuleTypesTable) => adminRuleTypesTable.AdminRuleTypeID === this.ruleTypeID
    );
    if (adminRule) {
      this.ruleTypeName = adminRule.RuleTypeName;
    } else {
      this.ruleTypeName = 'All Rules';
    }
    this.adminTriggers = getAdminTables.AdminTriggers;
  }

  /**
   * Sets rule variable to list of rules for this rule type
   */
  async getRulesByRuleType() {
    try {
      //ruleTypeID 0 means all rules
      const formattedListOfRules = [];
      const query = this.ruleTypeID === 0 ? { all: true } : { RuleTypeID: this.ruleTypeID };
      const getRuleByTypeID = await this.automatedEngineService.getRules(query); //gets 'simple' rules (just the Rule object, not the criteria or other things)

      //if there are no rules, set the rule array to an empty array
      if (!getRuleByTypeID.Rules) {
        this.rules = [];
        return;
      }

      //make objects for displaying the rules
      for (const rule of getRuleByTypeID.Rules) {
        formattedListOfRules.push({
          icon: this.viewOnly ? 'visible' : 'edit',
          RuleID: rule.RuleID,
          Name: rule.RuleName,
          Description: rule.Description,
          Trigger: this.adminTriggers.find((trigger) => trigger.TriggerID === rule.TriggerID)?.TriggerName,
          Active: rule.isActive == 1 ? 'True' : 'False',
        });
      }

      this.rules = getRuleByTypeID.Rules;
      this.rulesForDisplaying = formattedListOfRules.sort((a, b) => {
        return a?.Name.localeCompare(b?.Name);
      });
    } catch (error) {
      console.error(error);
    }
  }

  toggleRuleActive(ruleId: number) {
    // Update the isActive property (1 becomes 0, and vice versa)
    this.rules[ruleId].isActive = this.rules[ruleId].isActive === 1 ? 0 : 1;

    // Call another function with the rule.RuleID
    this.updateRuleStatus(ruleId, this.rules[ruleId].isActive);
  }

  updateRuleStatus(ruleID, isActive) {
    console.log(ruleID, isActive);
  }

  addRule() {
    const newRule = new Rule();
    newRule.RuleID = null;
    newRule.RuleName = 'New Rule';
    newRule.Description = '';
    newRule.RuleTypeID = null;
    newRule.isActive = 0;
    newRule.TriggerID = 2;

    // Create Criteria and Actions arrays and populate them if needed
    newRule.Criteria = [];
    newRule.Actions = [];
    this.rules.push(newRule);
    this.onRowClicked(newRule);
  }

  async onRowClicked(rule: Rule) {
    try {
      const getAdminTables = await this.automatedEngineService.getAdminTables();
      // if RuleID is null, it is creating a new rule
      rule = rule.RuleID ? await this.automatedEngineService.getRuleByID(rule.RuleID) : rule;
      const curOpenRule = JSON.parse(JSON.stringify(rule));
      // ^ Simple way of cloning an object in TS

      const data = {
        ...getAdminTables,
        Rule: rule,
        viewOnly: this.viewOnly,
      };
      const dialogRef = this.dialog.open(RuleBuilderListItemComponent, {
        data: data,
        width: '1190px',
        height: 'calc(100vh - 76px)',
        panelClass: 'rule-builder-dialog-container',
        backdropClass: 'rule-builder-dialog-backdrop',
        position: {
          bottom: '0',
          right: '0',
        },
      });

      dialogRef.afterClosed().subscribe(async (returnRule: Rule) => {
        if (returnRule) {
          //updaet the rule's actions and values to be strings instead of arrays
          let splitActions = [];
          if (returnRule.Actions?.length > 0) {
            for (const action of returnRule.Actions) {
              if (action.ActionValues != null && action.ActionValues.length > 0) { 
                for (const value of action.ActionValues) {
                  if (Array.isArray(value.Value)) {
                    value.Value = value.Value?.join(',');
                  }
                  splitActions.push({
                    Rule2ActionsID: value.Rule2ActionsID,
                    RuleID: action.RuleID,
                    ActionID: action.ActionID,
                    OrderNumber: action.OrderNumber,
                    ActionValue: value.Value,
                    ActionValueID: value.ActionValueID
                  });
                }
              } else {
                splitActions.push({
                  Rule2ActionsID: action.Rule2ActionsID,
                  RuleID: action.RuleID,
                  ActionID: action.ActionID,
                  OrderNumber: action.OrderNumber,
                  ActionValue: action.ActionValue,
                  ActionValueID: action.ActionValueID
                });
              }
            }
          }
          if (splitActions != null && splitActions.length) {
            returnRule.Actions = splitActions;
          }

          //convert Rule.criteria from an array to a string
          if (returnRule.Criteria?.length > 0) {
            for (const criteria of returnRule.Criteria) {
              //if criteria.Values is an array, convert it to a string
              if (Array.isArray(criteria.Values)) {
                criteria.Values = criteria.Values?.join(',');
              }
            }
          }
          //rules differ
          if (JSON.stringify(curOpenRule) !== JSON.stringify(returnRule)) {
            if (returnRule.RuleID) {
              //if it has a rule ID
              if (returnRule.delete) {
                //if you are deleting the rule
                await this.automatedEngineService.deleteRule(returnRule);
              } else {
                //you are modifying the rule
                const differences = this.getRuleDifference(curOpenRule, returnRule);
                await this.automatedEngineService.updateRule(differences);
              }
            } else {
              //you are creating the rule
              await this.automatedEngineService.createRule(returnRule);
            }
          }
        }

        try {
          await this.getRulesByRuleType();
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Returns a new rule object with only the differences between the old rule and the new rule
   * Does not use the type "Rule" since I only want the properties that are different
   * @param {Rule} oldRule
   * @param {Rule} newRule
   * @returns {any} rule
   */
  compareObjects(obj1, obj2) {
    const differences = {};
    for (const key in obj2) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    }
    if (obj2.hasOwnProperty('delete')) {
      differences['delete'] = obj2.delete;
    }
    return differences;
  }

  getRuleDifference(oldRule, newRule) {
    const ruleDifferences = this.compareObjects(oldRule, newRule);
    // Sort Actions and Criteria by respective IDs
    const sortAndCompare = (property, idKey, differencesArray) => {
      oldRule[property]?.sort((a, b) => {
        if (a[idKey] === null && b[idKey] === null) {
          return 0; // Both items are null, no change in order
        } else if (a[idKey] === null) {
          return 1; // Place 'a' (null) after 'b' (non-null)
        } else if (b[idKey] === null) {
          return -1; // Place 'a' (non-null) before 'b' (null)
        } else {
          // Compare based on idKey for both non-null items
          return a[idKey] - b[idKey];
        }
      });

      newRule[property]?.sort((a, b) => {
        if (a[idKey] === null && b[idKey] === null) {
          return 0; // Both items are null, no change in order
        } else if (a[idKey] === null) {
          return 1; // Place 'a' (null) after 'b' (non-null)
        } else if (b[idKey] === null) {
          return -1; // Place 'a' (non-null) before 'b' (null)
        } else {
          // Compare based on idKey for both non-null items
          return a[idKey] - b[idKey];
        }
      });

      const maxLength = Math.max(oldRule[property]?.length || 0, newRule[property]?.length || 0);

      for (let i = 0; i < maxLength; i++) {
        const oldItem = oldRule[property][i];
        const newItem = newRule[property][i];
        if (!oldItem || !newItem) {
          // Handle the case where one array is shorter
          if (newItem) {
            // New item exists, consider it as a difference
            differencesArray.push(newItem);
          } else if (oldItem) {
            const deleteItem = {};
            deleteItem[idKey] = oldItem[idKey];
            deleteItem['delete'] = true;
            differencesArray.push(deleteItem);
          }
          //this is an update technically, so don't need to do anything else
        } else if (newItem[idKey] == null && oldItem[idKey] != null) {
          newItem[idKey] = oldItem[idKey];
          differencesArray.push(newItem);
        } else {
          const differences = this.compareObjects(oldItem, newItem);
          if (Object.keys(differences).length > 0) {
            differences[idKey] = oldItem[idKey];
            differencesArray.push(differences);
          }
        }
      }
    };

    const ruleDifferencesCriteria = [];
    const ruleDifferencesActions = [];

    sortAndCompare('Actions', 'Rule2ActionsID', ruleDifferencesActions);
    sortAndCompare('Criteria', 'Rule2CriteriaID', ruleDifferencesCriteria);

    return {
      ...ruleDifferences,
      Criteria: ruleDifferencesCriteria,
      Actions: ruleDifferencesActions,
      RuleID: oldRule.RuleID,
    };
  }

  rowSelectionChanged(e) {}
}
