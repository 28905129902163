import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatFormField } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  imports: [MatFormField, MatDialogModule, MatInputModule, MatButtonModule, FormsModule], 
  standalone: true,
  selector: "app-reset-password-component",
  templateUrl: "reset-password-dialog.component.html",
})
export class ResetPasswordDialogComponent {
  data: any;
  password: string = "";
  UserID: string = "";
  @ViewChild("newPassword") newPassword: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>
  ) {
    this.data = Data;
    this.UserID = this.data.userID;
  }

  onConfirm() {
    this.dialogRef.close({'password': this.password, 'userID': this.UserID});
  }

  onClose() {
    this.dialogRef.close();
  }
}
