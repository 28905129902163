import { inject, Injectable } from '@angular/core';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';
import { apiKeys } from '../../../ENDPOINTS';
import { BehaviorSubject, from, map, Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SymbolShape } from '../utilities/types';
import { OpenLayersService } from './open-layers.service';
import { GeoJSON } from 'ol/format';

@Injectable({
  providedIn: 'root',
})
export class MapSymbolsService {
  // services
  private apiService = inject(ApiService);
  private mapService = inject(OpenLayersService);

  // observables
  private destroy$ = new Subject<void>();
  private _ready$ = new BehaviorSubject<boolean>(false);

  private legacySymbolIconsSubject$ = new BehaviorSubject<Array<unknown>>([]);
  private _svgSymbols$ = new BehaviorSubject<Array<SvgSymbol>>([]);

  // members
  private symbolGeometries: Array<SymbolShape> = [];
  private geoJSON: GeoJSON;

  constructor() {
    this.geoJSON = new GeoJSON();
    this.gatherLegacySymbols();
    this.gatherSymbols();
  }

  async gatherSymbols() {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapDrawing,
      API_BODY: { query: { svgSymbols: { byClientID: true } } },
    };
    try {
      const response = await this.apiService.invokeUtilocateApi(request);
      if (response.status === 200) {
        const svgSymbolResult: SvgSymbolResult = response.body.result;
        const svgSymbols = svgSymbolResult.map((x) => {
          return {
            ...x,
            id: x.id,
            name: x.name,
            is_global: x.is_global,
            src: x.source_url,
            archived: x.archived,
          };
        });
        this._svgSymbols$.next(svgSymbols);
      }
    } catch (error) {
      console.log(error);
    }
  }

  gatherLegacySymbols() {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapDrawing,
      API_BODY: { query: { symbols: { byClientID: true } } },
    };
    from(this.apiService.invokeUtilocateApi(request))
      .pipe(first())
      .subscribe((response) => {
        try {
          if (response.status === 200) {
            const symbolsData = response.body.result;
            this.symbolGeometries = symbolsData?.geometries.map((x: any) => {
              return {
                ...x,
                geometry: this.geoJSON.readGeometry(x.geojson, {
                  dataProjection: 'EPSG:4326',
                }),
                geojson: JSON.parse(x.geojson),
              };
            });
          }
          this._ready$.next(true);
        } catch (error) {
          console.error('Failed to gather symbols');
        }
      });
  }

  get legacySymbolIcons$(): Observable<any> {
    return this.legacySymbolIconsSubject$.asObservable();
  }

  getLegacySymbolByID(id: number): Array<SymbolShape> {
    return this.symbolGeometries.filter((x) => x.symbol_id === id);
  }

  getSvgSymbolByID(id: number): SvgSymbol {
    return this._svgSymbols$.value.find((x) => x.id === id);
  }

  get ready$(): Observable<boolean> {
    return this._ready$.pipe(takeUntil(this.destroy$));
  }

  get svgSymbols$(): Observable<Array<SvgSymbol>> {
    return this._svgSymbols$.pipe(map((x) => x.filter((y) => !y.archived)));
  }
}

type SvgSymbolResult = Array<DBSvgSymbol>;
type DBSvgSymbol = {
  id: number;
  name: string;
  source_url: string;
  is_global: boolean;
  archived: boolean;
};
export type SvgSymbol = Omit<DBSvgSymbol, 'source_url'> & {
  src: string;
};
