import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ThrottleClickDirective } from '../../core/directives/click-throttle.directive';

@Component({
  selector: 'app-map-capture-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ThrottleClickDirective,
  ],
  template: `
    <button
      mat-fab
      style="width: 60px; height: 60px; background-color: #FFFFFF"
      aria-label="Screenshot map button"
      appClickThrottle
      [throttleTime]="4500"
      (click)="clicked.emit()"
    >
      <mat-icon
        style="width: 30px; height: 30px; font-size: 30px; color: black"
        svgIcon="capture"
      ></mat-icon>
    </button>
  `,
})
export class MapCaptureButtonComponent {
  // IO
  @Output() clicked = new EventEmitter<void>()
}
