export interface googleMapsOptions {
  zoomControl: boolean;
  scrollwheel: boolean;
  zoomControlOptions?: any;
  mapTypeControl: boolean;
  mapTypeControlOptions?: any;
  streetViewControl: boolean;
  scaleControl?: boolean;
  rotateControl?: boolean;
  fullScreenControl?: boolean;
  fullscreenControl: boolean;
  controlSize?: number;
  tilt?: number;
  draggable?: boolean;
}

export enum googleMapTypeControlStyle {
  DEFAULT = 0,
  HORIZONTAL_BAR = 1,
  DROPDOWN_MENU = 2,
}

export enum googleMapControlPosition {
  RIGHT_BOTTOM = 0,
  TOP_LEFT = 1,
  TOP_CENTER = 2,
  TOP_RIGHT = 3,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  LEFT_BOTTOM = 6,
  RIGHT_TOP = 7,
  RIGHT_CENTER = 8,
  BOTTOM_RIGHT = 9,
  BOTTOM_LEFT = 10,
  BOTTOM_CENTER = 11,
}

export interface MarkerObject {
  position: MarkerPosition;
}

export interface MarkerPosition {
  lat: number;
  lng: number;
}
