import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgClass } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-button-dropdown-menu',
  standalone: true,
  imports: [MatIcon, AsyncPipe, NgClass],
  template: `
    <div #container class="relative w-32">
      <button
        (click)="_isActive$.next(!_isActive$.value)"
        [ngClass]="classOverrides"
        class="z-20 relative {{
          (_isActive$|async) ? 'shadow-[0_10px_8px_rgba(0,0,0,0.1)]' : ''
        }} appearance-none border-none cursor-pointer flex flex-row justify-between items-center box-border w-full h-[36px] rounded-md bg-primary text-left font-rajdhani font-semibold text-white pr-0.5 pl-2">
        <span class="uppercase font-rajdhani font-semibold text-lg">{{ title }}</span>
        <mat-icon style="width: 24px">arrow_drop_down</mat-icon>
      </button>
      <div
        class="absolute box-border -translate-y-2 z-10 {{
          (_isActive$ | async) ? 'pt-5' : 'overflow-hidden h-0 w-0'
        }} w-full bg-white rounded-md shadow-md transition-all ease-in-out">
        <div>
          @for (item of items; track $index) {
            <button
              (click)="changeSelection(item)"
              class="cursor-pointer appearance-none border-none bg-transparent w-full flex justify-start items-center text-left text-md font-semibold font-rajdhani text-primary uppercase p-2 hover:bg-primary hover:text-white transition-all last:rounded-b-md">
              {{ item.name }}
            </button>
          }
        </div>
      </div>
    </div>
  `,
})
export class ButtonDropdownMenuComponent<T extends { name: string }> implements AfterViewInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement>;
  // IO
  @Input() title: string = '';
  @Input() classOverrides: string = '';
  @Input() items: Array<T> = [];
  @Output() openChange = new EventEmitter<boolean>();
  @Output() actionClick = new EventEmitter<T>();

  // services
  private renderer: Renderer2 = inject(Renderer2);
  // observable
  protected _isActive$ =  new BehaviorSubject(false);

  // members
  private listenerFn: () => void;

  constructor() {}

  ngAfterViewInit() {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (e.composedPath().indexOf(this.container.nativeElement) === -1) {
        this._isActive$.next(false);
      }
    });
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  changeSelection(item: T) {
    this._isActive$.next(false);
    this.actionClick.emit(item);
  }
}
