import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ProgressBarService } from "../progress-bar.service";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit {
  mode$: Observable<string>;
  color = "primary";

  constructor(private progressBarService: ProgressBarService) {}

  ngOnInit(): void {
    this.mode$ = this.progressBarService.mode$;
  }
}
