<div style="width: 100%; height: 100%" fxLayout="row">
  <div fxFlex style="height: 100%; overflow: auto">
    <div class="page" fxLayout="column" fxLayoutGap="32px">
      <!-- Title and buttons -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
      >
        <span class="mat-headline-2 text-primary semibold uppercase"
          >Edit Algorithm</span
        >
        <div fxLayout="row wrap" fxLayoutGap="24px">
          <button
            class="b"
            mat-flat-button
            (click)="setAsDefault()"
            [disabled]="algorithm.isDefault == 1"
          >
            Set As Default
          </button>
          <button mat-flat-button class="b" (click)="setAll()">
            Assign For All
          </button>
          <button
            class="btn font-rajdhani"
            mat-flat-button
            color="primary"
            (click)="saveAlgorithm()"
          >
            SAVE
          </button>
          <button
            class="btn font-rajdhani"
            mat-flat-button
            color="warn"
            (click)="onDeleteClick()"
            [disabled]="algorithm.isDefault == 1"
          >
            DELETE
          </button>
        </div>
      </div>
      <!-- Name and description -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex="24">
          <mat-label>Algorithm Name</mat-label>
          <input
            matInput
            autocomplete="off"
            [(ngModel)]="algorithm.AlgorithmName"
            (change)="inputChanged()"
          />
        </mat-form-field>

        <mat-form-field fxFlex="54">
          <mat-label>Algorithm Description</mat-label>
          <input
            matInput
            autocomplete="off"
            [(ngModel)]="algorithm.AlgorithmDesc"
            (change)="inputChanged()"
          />
        </mat-form-field>
        <div
          fxFlex="16"
          style="width: 100%"
          fxLayout="row"
          fxLayoutAlign="end center"
        >
          <ngx-colors
            ngx-colors-trigger
            [palette]="colorPalette"
            [hideColorPicker]="true"
            [hideTextInput]="true"
            [(ngModel)]="algorithm.AlgorithmColour"
            (change)="colourChanged()"
          >
          </ngx-colors>
          <span
            class="mat-subtitle-1"
            style="line-height: 30px; padding-left: 4px; padding-right: 2px; margin: 0px;"
            >Edit Color</span
          >
        </div>
      </div>
      <!-- Rules -->
      <div fxLayout="column" fxLayoutGap="6px">
        <span class="mat-headline-1" style="padding-bottom: 16px">Rules</span>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
          <div fxFlex="30" class="mat-subtitle-2 rule-column-header">Type</div>
          <div fxFlex="30" class="mat-subtitle-2 rule-column-header">Name</div>
          <div fxFlex="30" class="mat-subtitle-2 rule-column-header">
            Distance (M)
          </div>
        </div>
        <div style="height: 1px; width: 100%; background-color: #999999" class="underline"></div>
        <div>
          <ng-container *ngFor="let rule of algorithm.Rules">
            <app-routing-preset-rule
              *ngIf="rule.ActionID != 3"
              [rule]="rule"
              [ruleTypes]="ruleTypes"
              (distanceModified)="orderRules()"
            >
            </app-routing-preset-rule>
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-fab style="background-color: #2abc17" (click)="addRule()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODALS -->

<app-base-modal #deleteDialog [id]="DELETE_MODAL_ID" maxwidth="360px">
  <ng-container class="competers-modal-content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <span class="mat-subtitle-1">Delete Algorithm</span>
      <span>{{ numUsersOnAlgorithmMessage }}</span>
      <span>Are you sure you want to delete this Algorithm?</span>
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button class="blue" mat-flat-button (click)="deleteDialog.close()">
          Cancel
        </button>
        <button mat-flat-button color="primary" (click)="deleteAlgorithm()">
          Confirm
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>

<app-base-modal #saveDialog [id]="SAVE_MODAL_ID" maxwidth="360px">
  <ng-container class="competers-modal-content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <span class="mat-subtitle-1">Unsaved Changes</span>
      <span>Would you like to save your changes?</span>
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button class="blue" mat-flat-button (click)="changeWithoutSaving()">
          Discard
        </button>
        <button mat-flat-button color="primary" (click)="changeWithSaving()">
          Save
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>
