/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ADMIN_TABLE_NAMES,
  COMPLETION_TABLE_NAMES,
} from "src/app/modules/core/admin/tables";
import {
  BaseAdminCacheService,
  BaseCompletionCacheService,
  CacheWhereClause,
  CacheWhereClauseType,
} from "src/app/modules/core/cache/cache.interface";

export enum VerifyDetailsType {
  Billing = 0,
  PrimaryDetails,
  PreCompletions,
  Primary,
}

export class VerifyDetails {
  Type: VerifyDetailsType;
  ErrorMap: object;

  constructor(type: VerifyDetailsType, ErrorMap: object) {
    this.Type = type;
    this.ErrorMap = ErrorMap;
  }
}

interface CompletionsVerify {
  HasPassed: boolean;
  FailedReason: string;
  FailedID: string;
}

export class CompletionsVerifyResult implements CompletionsVerify {
  HasPassed: boolean;
  FailedReason: string;
  FailedID: string;

  constructor(HasPassed: boolean, FailedReason: string, FailedIDs: string) {
    this.HasPassed = HasPassed;
    this.FailedReason = FailedReason;
    this.FailedID = FailedIDs;
  }
}

export class UtilocateCompletionsVerify {
  private BillingRules = {
    ONLY_ONE: 1,
    ONLY_ONE_PRIMARY_DETAIL: 100,
    MORE_THAN_ONE_LESS_THAN_N: 105,
    NO_OVERLAPPING_PRIMARY_DETAILS: 106,
    VERIFY_IF_FILLED_THEN_ANOTHER_FILLED: 108,
    VERIFY_IF_FILLED_THEN_N_EXCEPTIONS_FILLED: 109,
    MORE_THAN_ONE_LESS_THAN_N_PRE_COMPLETIONS: 205,
  };

  constructor(
    private baseCompletionsCacheService: BaseCompletionCacheService,
    private baseAdminCacheService: BaseAdminCacheService
  ) { }

  async onlyOneSelectedDamageInvestigation(
    primaryID: string,
    utilityID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.VERIFY_IF_FILLED_THEN_ANOTHER_FILLED,
          ValueType: CacheWhereClauseType.NUMBER,
        },
        {
          Column: "UtilityID",
          Value: utilityID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ];
      const tbAdmin_BillingRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(tbAdmin_BillingRules instanceof Error)) {
        for (let i = 0; i < tbAdmin_BillingRules.length; i++) {
          const tbAdmin_BillingRule: any = tbAdmin_BillingRules[i];
          const Param: string = tbAdmin_BillingRule["Param"];
          const Exceptions: string = tbAdmin_BillingRule["Exceptions"];

          const whereClause: CacheWhereClause[] = [];
          if (Param.toString().indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }

          const adminPrimaryDetails: object[] | Error =
            await this.baseAdminCacheService.queryTableData(
              ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailFields,
              whereClause
            );

          whereClause.push({
            Column: "PrimaryID",
            Value: primaryID,
            ValueType: CacheWhereClauseType.NUMBER,
          });
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause
            );
          if (
            !(completionsPrimaryDetails instanceof Error) &&
            !(adminPrimaryDetails instanceof Error)
          ) {
            let fieldValueFilled = 0;
            let PrimaryDetailCategoryID: string;
            let FieldValue: any;
            for (let j = 0; j < completionsPrimaryDetails.length; j++) {
              const completionsPrimaryDetail: any = completionsPrimaryDetails[j];
              const PrimaryDetailsFieldID =
                completionsPrimaryDetail["PrimaryDetailsFieldID"];
              const adminPrimaryDetail: any = adminPrimaryDetails.find(
                (row) => (row["PrimaryDetailsFieldID"] = PrimaryDetailsFieldID)
              );

              PrimaryDetailCategoryID =
                adminPrimaryDetail["PrimaryDetailCategoryID"];
              FieldValue = completionsPrimaryDetail["FieldValue"];
              if (
                FieldValue != null &&
                (FieldValue != "0" ||
                  FieldValue > 0 ||
                  FieldValue == true ||
                  (FieldValue instanceof String && FieldValue.length > 0))
              ) {
                fieldValueFilled++;
              }
            }

            if (fieldValueFilled > parseInt(Exceptions.toString())) {
              failedArr.push(
                new CompletionsVerifyResult(
                  false,
                  "Too many items filled",
                  PrimaryDetailCategoryID.toString()
                )
              );
            } else if (fieldValueFilled == 0) {
              failedArr.push(
                new CompletionsVerifyResult(
                  false,
                  "Too few items filled",
                  PrimaryDetailCategoryID.toString()
                )
              );
            }
          } else {
            console.log("throwing");
            throw completionsPrimaryDetails;
          }
        }

        return failedArr;
      } else {
        throw tbAdmin_BillingRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async onlyOneSelectedManual(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult | Error> {
    const defaultVerifyResult = new CompletionsVerifyResult(true, "", "");
    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "PrimaryID",
          Value: primaryID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
        {
          Column: "UtilityID",
          Value: utilityID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ];
      const primaryUtilityAuxDetailID: any[] | Error =
        await this.baseCompletionsCacheService.queryTableDataByColumns(
          COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
          ["AuxiliaryDetailID"],
          whereClause,
          assignmentID
        );
      if (
        !(primaryUtilityAuxDetailID instanceof Error) &&
        primaryUtilityAuxDetailID[0]
      ) {
        const { AuxiliaryDetailID } = primaryUtilityAuxDetailID[0];

        const whereClause1: CacheWhereClause = {
          Column: "AuxiliaryDetailID",
          Value: AuxiliaryDetailID,
          ValueType: CacheWhereClauseType.NUMBER,
        };
        const primaryUtilityBillingRow: any[] | Error =
          await this.baseCompletionsCacheService.queryTableData(
            COMPLETION_TABLE_NAMES.tbCompletions_Billing,
            [whereClause1],
            false,
            assignmentID
          );
        if (!(primaryUtilityBillingRow instanceof Error)) {
          let totalFilled = 0;
          for (let i = 0; i < primaryUtilityBillingRow.length; i++) {
            // eslint-disable-next-line prefer-const
            let billingRow = primaryUtilityBillingRow[i];
            if (
              billingRow &&
              (billingRow["FieldValue"] == "1" ||
                billingRow["FieldValue"] >= 1 ||
                billingRow["FieldValue"] == true)
            ) {
              totalFilled++;
            }
          }

          if (totalFilled > 1) {
            const completionsVerifyResult = new CompletionsVerifyResult(
              false,
              "Too many billing items filled",
              AuxiliaryDetailID.toString()
            );
            return completionsVerifyResult;
          } else if (totalFilled == 0) {
            const completionsVerifyResult = new CompletionsVerifyResult(
              false,
              "Too few billing items filled",
              AuxiliaryDetailID.toString()
            );
            return completionsVerifyResult;
          }
          return defaultVerifyResult;
        } else {
          throw new Error("Failed to get ticket billing rows");
        }
      } else {
        throw primaryUtilityAuxDetailID;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async onlyOneSelectedAudit(
    primaryID: string,
    utilityID: string,
    cacheService = this.baseCompletionsCacheService,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const resultsArr: CompletionsVerifyResult[] = [];

    const defaultVerifyResult = new CompletionsVerifyResult(true, "", "");
    try {
      const whereClause: CacheWhereClause = {
        Column: "UtilityType",
        Value: utilityID,
        ValueType: CacheWhereClauseType.NUMBER,
      };
      const primaryCatRowResult: any[] | Error =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailsCategories,
          [whereClause]
        );

      if (!(primaryCatRowResult instanceof Error)) {
        for (let i = 0; i < primaryCatRowResult.length; i++) {
          const curRow = primaryCatRowResult[i];
          const { PrimaryDetailCategoryID } = curRow;
          const whereClause1: CacheWhereClause[] = [
            {
              Column: "PrimaryDetailCategoryID",
              Value: PrimaryDetailCategoryID,
            },
          ];
          const primaryDetailsRowsResult: any[] | Error =
            await this.baseAdminCacheService.queryTableData(
              ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailFields,
              whereClause1
            );

          if (!(primaryDetailsRowsResult instanceof Error)) {
            const primaryDetailsFieldIDs = [];
            for (let j = 0; j < primaryDetailsRowsResult.length; j++) {
              const curPrimaryAdminRow = primaryDetailsRowsResult[j];
              const { PrimaryDetailsFieldID } = curPrimaryAdminRow;
              primaryDetailsFieldIDs.push(PrimaryDetailsFieldID);
            }
            const whereClause2: CacheWhereClause[] = [
              {
                Column: "PrimaryID",
                Value: primaryID,
                ValueType: CacheWhereClauseType.NUMBER,
              },
              {
                Column: "PrimaryDetailsFieldID",
                Value: primaryDetailsFieldIDs,
                ValueType: CacheWhereClauseType.ARRAY,
              },
            ];
            const completionsPrimaryDetailsRows: any[] | Error =
              await cacheService.queryTableData(
                COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
                whereClause2,
                false,
                assignmentID
              );

            let totalFilled = 0;
            if (!(completionsPrimaryDetailsRows instanceof Error)) {
              for (let j = 0; j < completionsPrimaryDetailsRows.length; j++) {
                const curPrimaryRow = completionsPrimaryDetailsRows[j];

                if (curPrimaryRow["FieldValue"] == 1) {
                  totalFilled++;
                }
              }

              if (totalFilled > 1) {
                const completionsVerifyResult = new CompletionsVerifyResult(
                  false,
                  "Too many items filled",
                  PrimaryDetailCategoryID.toString()
                );
                resultsArr.push(completionsVerifyResult);
              } else if (totalFilled == 0) {
                const completionsVerifyResult = new CompletionsVerifyResult(
                  false,
                  "Too few items filled",
                  PrimaryDetailCategoryID.toString()
                );
                resultsArr.push(completionsVerifyResult);
              }
            } else {
              throw completionsPrimaryDetailsRows;
            }
          } else {
            throw primaryDetailsRowsResult;
          }
        }

        resultsArr.push(defaultVerifyResult);
        return resultsArr;
      } else {
        throw primaryCatRowResult;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async VerifyIfFilledThenAnotherFilled(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.VERIFY_IF_FILLED_THEN_ANOTHER_FILLED,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const utilityPrimaryDetailsRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(utilityPrimaryDetailsRules instanceof Error)) {
        for (let i = 0; i < utilityPrimaryDetailsRules.length; i++) {
          const whereClause: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const whereClauseException: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const utilityPrimaryDetailsRule: any = utilityPrimaryDetailsRules[i];
          const Param: string = utilityPrimaryDetailsRule["Param"];
          const Exceptions: string = utilityPrimaryDetailsRule["Exceptions"];

          if (Param.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }

          if (Exceptions.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Exceptions.toString().split(",");
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: Exceptions.toString(),
            });
          }

          
          
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause,
              false,
              assignmentID
            );

            const completionsPrimaryDetailsException: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClauseException,
              false,
              assignmentID
            );

            
          if (!(completionsPrimaryDetails instanceof Error) && !(completionsPrimaryDetailsException instanceof Error)) {
            if (completionsPrimaryDetails.length > 0) {
              const fieldsFilled = this.calculateFieldValueFilled(completionsPrimaryDetails, completionsPrimaryDetailsException)
              const PrimaryDetailsFieldID  = completionsPrimaryDetails[0]["PrimaryDetailsFieldID"]
            
              if (fieldsFilled == 2) { 
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too many items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              } else if (fieldsFilled == 3) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too few items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              }
            }
          } else {
            throw completionsPrimaryDetails;
          }
        }

        if (failedArr.length == 0) {
          failedArr.push(new CompletionsVerifyResult(true, "", ""));
        }
        return failedArr;
      } else {
        throw utilityPrimaryDetailsRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }


  async VerifyIfFilledNExceptionsFilled(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.VERIFY_IF_FILLED_THEN_N_EXCEPTIONS_FILLED,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const utilityPrimaryDetailsRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(utilityPrimaryDetailsRules instanceof Error)) {
        for (let i = 0; i < utilityPrimaryDetailsRules.length; i++) {
          const whereClause: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const whereClauseException: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const utilityPrimaryDetailsRule: any = utilityPrimaryDetailsRules[i];
          const Param: string = utilityPrimaryDetailsRule["Param"];
          const Exceptions: string = utilityPrimaryDetailsRule["Exceptions"];

          if (Param.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }

          if (Exceptions.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Exceptions.toString().split(",");
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: Exceptions.toString(),
            });
          }

          
          
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause,
              false,
              assignmentID
            );

            const completionsPrimaryDetailsException: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClauseException,
              false,
              assignmentID
            );

            
          if (!(completionsPrimaryDetails instanceof Error) && !(completionsPrimaryDetailsException instanceof Error)) {
            if (completionsPrimaryDetails.length > 0) {
              const countFieldValue = (details: object[]) => {
                return details.filter(detail => {
                  const { FieldValue } = detail as any;
                  return FieldValue != 0 && FieldValue != "" && FieldValue != null;
                }).length;
              };
            
              const filledCount = countFieldValue(completionsPrimaryDetails);
              const exceptionCount = countFieldValue(completionsPrimaryDetailsException);
              
              const PrimaryDetailsFieldID  = completionsPrimaryDetails[0]["PrimaryDetailsFieldID"]
            
              if (filledCount > 0 && exceptionCount == 0) { 
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too few items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              }
            }
          } else {
            throw completionsPrimaryDetails;
          }
        }

        if (failedArr.length == 0) {
          failedArr.push(new CompletionsVerifyResult(true, "", ""));
        }
        return failedArr;
      } else {
        throw utilityPrimaryDetailsRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async moreThanOneLessThanNPrimaryDetailsSelected(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.MORE_THAN_ONE_LESS_THAN_N,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const utilityPrimaryDetailsRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(utilityPrimaryDetailsRules instanceof Error)) {
        for (let i = 0; i < utilityPrimaryDetailsRules.length; i++) {
          const whereClause: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const whereClauseException: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const utilityPrimaryDetailsRule: any = utilityPrimaryDetailsRules[i];
          const Param: string = utilityPrimaryDetailsRule["Param"];
          const Exceptions: number = utilityPrimaryDetailsRule["Exceptions"];

          if (Param.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }
          
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause,
              false,
              assignmentID
            );
            
          if (!(completionsPrimaryDetails instanceof Error)) {
            if (completionsPrimaryDetails.length > 0) {
              const countFieldValue = (details: object[]) => {
                return details.filter(detail => {
                  const { FieldValue } = detail as any;
                  return FieldValue != 0 && FieldValue != "" && FieldValue != null;
                }).length;
              };

              const fieldsFilled = countFieldValue(completionsPrimaryDetails);
              const PrimaryDetailsFieldID = completionsPrimaryDetails[0]["PrimaryDetailsFieldID"]
              
              if (!fieldsFilled) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too few items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              } else if (fieldsFilled > Exceptions) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too many items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              }
            }
          } else {
            throw completionsPrimaryDetails;
          }
        }

        if (failedArr.length == 0) {
          failedArr.push(new CompletionsVerifyResult(true, "", ""));
        }
        return failedArr;
      } else {
        throw utilityPrimaryDetailsRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  calculateFieldValueFilled(completionsPrimaryDetails: object[], completionsPrimaryDetailsException: object[]): number {
    const countFieldValue = (details: object[]) => {
      return details.filter(detail => {
        const { FieldValue } = detail as any;
        return FieldValue != 0 && FieldValue != "" && FieldValue != null;
      }).length;
    };
  
    const filledCount = countFieldValue(completionsPrimaryDetails);
    const exceptionCount = countFieldValue(completionsPrimaryDetailsException);
  
    if (filledCount === 1 && exceptionCount === 1) {
      return 1;
    } else if ((filledCount > 1 && exceptionCount > 1)  || (filledCount === 1 && exceptionCount > 1) || (filledCount > 1 && exceptionCount === 1) || (filledCount == 0 && exceptionCount > 1) || (filledCount > 1 && exceptionCount == 0)) {
      return 2;
    } else if ((filledCount === 1 && exceptionCount == 0) || (filledCount == 0 && exceptionCount === 1)) {
      return 3;
    } else {
      return 0;
    }
  }

  async moreThanOneLessThanNPreCompletionDetailsSelected(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ) {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const billingRulesWhereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.MORE_THAN_ONE_LESS_THAN_N_PRE_COMPLETIONS,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const billingRulesResult = await this.baseAdminCacheService.queryTableData(
        ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
        billingRulesWhereClause
      );
      if (!(billingRulesResult instanceof Error)) {
        if (billingRulesResult.length > 0) {
          for (let i = 0; i < billingRulesResult.length; i++) {
            const preCompDetailsWhereClause: CacheWhereClause[] = [
              {
                Column: "PrimaryID",
                Value: primaryID,
                ValueType: CacheWhereClauseType.NUMBER,
              },
            ];

            const billingRow = billingRulesResult[i];
            const maxNumFilled = billingRow["Exceptions"];
            const preCompFieldAdminIDs: any = billingRow["Params"].contains(",")
              ? billingRow["Params"].split(",")
              : billingRow["Params"];
            if (preCompFieldAdminIDs instanceof Array) {
              preCompDetailsWhereClause.push({
                Column: "PreCompletionFieldID",
                Value: preCompFieldAdminIDs,
                ValueType: CacheWhereClauseType.ARRAY,
              });
            } else {
              preCompDetailsWhereClause.push({
                Column: "PreCompletionFieldID",
                Value: preCompFieldAdminIDs,
              });
            }

            const pCompFields: object[] | Error =
              await this.baseCompletionsCacheService.queryTableData(
                COMPLETION_TABLE_NAMES.tbCompletions_PreCompletionDetails,
                preCompDetailsWhereClause,
                false,
                assignmentID
              );
            if (!(pCompFields instanceof Error)) {
              let curNumFilled = 0;
              for (const row in pCompFields) {
                if (row["FieldValue"] > "0" || row["FieldValue"] > 0) {
                  curNumFilled++;
                }
              }

              if (curNumFilled > maxNumFilled) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too many fields in category selected",
                    billingRow["Params"]
                  )
                );
              } else if (curNumFilled < 1) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too few fields in category selected",
                    billingRow["Params"]
                  )
                );
              }
            }
          }

          return failedArr;
        } else {
          return failedArr;
        }
      } else {
        return failedArr;
      }
    } catch (error) {
      return error;
    }
  }
  
  async onlyOnePrimaryDetailSelected(
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.ONLY_ONE_PRIMARY_DETAIL,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const utilityPrimaryDetailsRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(utilityPrimaryDetailsRules instanceof Error)) {
        for (let i = 0; i < utilityPrimaryDetailsRules.length; i++) {
          const whereClause: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const utilityPrimaryDetailsRule: any = utilityPrimaryDetailsRules[i];
          const Param: string = utilityPrimaryDetailsRule["Param"];
          const Exceptions: number = utilityPrimaryDetailsRule["Exceptions"];

          if (Param.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }
          
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause,
              false,
              assignmentID
            );
            
          if (!(completionsPrimaryDetails instanceof Error)) {
            if (completionsPrimaryDetails.length > 0) {
              const countFieldValue = (details: object[]) => {
                return details.filter(detail => {
                  const { FieldValue } = detail as any;
                  return FieldValue != 0 && FieldValue != "" && FieldValue != null;
                }).length;
              };

              const fieldsFilled = countFieldValue(completionsPrimaryDetails);
              const PrimaryDetailsFieldID = completionsPrimaryDetails[0]["PrimaryDetailsFieldID"]
              
              if (!fieldsFilled) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too few items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              } else if (fieldsFilled > 1) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too many items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              }
            }
          } else {
            throw completionsPrimaryDetails;
          }
        }

        if (failedArr.length == 0) {
          failedArr.push(new CompletionsVerifyResult(true, "", ""));
        }
        return failedArr;
      } else {
        throw utilityPrimaryDetailsRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async verifyNoOverlappingPrimaryDetails( //106
    primaryID: string,
    utilityID: string,
    assignmentID: string
  ): Promise<CompletionsVerifyResult[] | Error> {
    const failedArr: CompletionsVerifyResult[] = [];

    try {
      const whereClause: CacheWhereClause[] = [
        {
          Column: "BillingRuleID",
          Value: this.BillingRules.NO_OVERLAPPING_PRIMARY_DETAILS,
        },
        { Column: "UtilityID", Value: utilityID },
      ];
      const utilityPrimaryDetailsRules =
        await this.baseAdminCacheService.queryTableData(
          ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
          whereClause
        );
      if (!(utilityPrimaryDetailsRules instanceof Error)) {
        for (let i = 0; i < utilityPrimaryDetailsRules.length; i++) {
          const whereClause: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const whereClauseException: CacheWhereClause[] = [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ];
          const utilityPrimaryDetailsRule: any = utilityPrimaryDetailsRules[i];
          const Param: string = utilityPrimaryDetailsRule["Param"];
          const Exceptions: string = utilityPrimaryDetailsRule["Exceptions"];

          if (Param.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Param.toString().split(",");
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClause.push({
              Column: "PrimaryDetailsFieldID",
              Value: Param.toString(),
            });
          }

          if (Exceptions.indexOf(",") > -1) {
            const primaryDetailsFieldIDs = Exceptions.toString().split(",");
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: primaryDetailsFieldIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            });
          } else {
            whereClauseException.push({
              Column: "PrimaryDetailsFieldID",
              Value: Exceptions.toString(),
            });
          }

          
          
          const completionsPrimaryDetails: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClause,
              false,
              assignmentID
            );

            const completionsPrimaryDetailsException: object[] | Error =
            await this.baseCompletionsCacheService.queryTableData(
              COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
              whereClauseException,
              false,
              assignmentID
            );

            
          if (!(completionsPrimaryDetails instanceof Error) && !(completionsPrimaryDetailsException instanceof Error)) {
            if (completionsPrimaryDetails.length > 0) {
              const countFieldValue = (details: object[]) => {
                return details.filter(detail => {
                  const { FieldValue } = detail as any;
                  return FieldValue != 0 && FieldValue != "" && FieldValue != null;
                }).length;
              };
            
              const filledCount = countFieldValue(completionsPrimaryDetails);
              const exceptionCount = countFieldValue(completionsPrimaryDetailsException);

              const PrimaryDetailsFieldID  = completionsPrimaryDetails[0]["PrimaryDetailsFieldID"]
            
              if (filledCount > 0 && exceptionCount > 0) {
                failedArr.push(
                  new CompletionsVerifyResult(
                    false,
                    "Too many items filled",
                    PrimaryDetailsFieldID.toString()
                  )
                );
              }
            }
          } else {
            throw completionsPrimaryDetails;
          }
        }

        if (failedArr.length == 0) {
          failedArr.push(new CompletionsVerifyResult(true, "", ""));
        }
        return failedArr;
      } else {
        throw utilityPrimaryDetailsRules;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}
