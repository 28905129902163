import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TicketListComponent } from "./ticket-list/ticket-list.component";
import { TicketListItemComponent } from "./ticket-list-item/ticket-list-item.component";
import { MaterialModule } from "../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TicketTagsModule } from "../ticket-tags/ticket-tags.module";
import { TicketModule } from "../ticket/fieldside-ticket.module";
import { FilterPipe } from "./filter.pipe";
import { FormsModule } from "@angular/forms";
import { FloatingActionMenuComponent } from "../fab/floating-action-menu/floating-action-menu.component";
import { FloatingTabSelectComponent } from "../../../shared/components/misc/selection/floating-tab-select/floating-tab-select.component";

@NgModule({
    declarations: [TicketListComponent, TicketListItemComponent, FilterPipe],
    exports: [TicketListComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        TicketTagsModule,
        TicketModule,
        FormsModule,
        FloatingActionMenuComponent,
        FloatingTabSelectComponent
    ]
})
export class TicketListModule {}
