import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ReportCategory, Report, ReportParameter} from './report-generator.types';
import {ApiService, UtilocateApiRequest} from '../core/api/baseapi.service';
import {ProgressBarService} from '../shared/progress-bar/progress-bar.service';
import {api, apiKeys} from 'src/app/ENDPOINTS';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';


export enum ReportType {
  NormalReport = 1,
  InvoiceReportType = 3,
}

@Injectable({
  providedIn: 'root'
})
export class ReportGeneratorService {
  reportCategories$: BehaviorSubject<ReportCategory[]> = new BehaviorSubject<ReportCategory[]>([]);
  allReports$: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([]);
  selectedReport$: BehaviorSubject<Report> = new BehaviorSubject<Report>(null);
  adminParameters$: BehaviorSubject<ReportParameter[]> = new BehaviorSubject<ReportParameter[]>([]);


  constructor(private utilocateAPIService: ApiService, private progressBarService: ProgressBarService, private formBuilder: FormBuilder) {
    this.refreshReports();
    this.refreshAdminParameters();
  }

  /**
   * gets the current reports 
   * @returns {BehaviorSubject<Report[]>}
   */
  getReports$(): BehaviorSubject<ReportCategory[]> {
    return this.reportCategories$;
  }

  /**
   * Returns a report category
   * @param id 
   * @returns {BehaviorSubject<ReportCategory>}
   */
  getCategoryByID(id: number) {
    return this.reportCategories$.value.find(report => report.CategoryID === id);
  }


  getReportByID(id: number) {
    return this.allReports$.value.find(report => report.ReportID === id);
  }

  /**
   * Returns a report by its ID
   * @returns {BehaviorSubject<Report>}
   */
  getCurrentReport() {
    return this.selectedReport$;
  }

  /**
   * Returns all parameters
   * @returns {BehaviorSubject<ReportParameter[]>}
   */
  getAdminParameters() {
    return this.adminParameters$;
  }

  /**
   * Returns a report by its ID
   * @param id 
   * @returns 
   */
  refreshCurrentReport(id: number) {
    this.queryReportByID(id).then((result) => {
      this.adminParameters$.subscribe((adminParams) => {
        const params = this.formatParameters(result.parameters, adminParams);
        const report: Report = {
          ReportID: result.report.ReportID,
          Name: result.report.Name,
          Description: result.report.Description,
          SubTitle: result.report.SubTitle,
          AccessLevel: result.report.AccessLevel,
          ReportTypeID: result.report.ReportTypeID,
          ReportParameters: params,
          FormGroup: this.groupParamsForForm(params),
        };
        this.selectedReport$.next(report);
      });
    });
  }

  /**
   * Creates a form group for the parameters for a report 
   * @param params 
   * @returns 
   */
  groupParamsForForm(params: ReportParameter[]): FormGroup {
    const formControls: {[key: string]: FormControl} = {};
    for (const param of params) {
      const formControl = this.getFormControlFromType(param.Type);
      formControls[param.ParameterID] = formControl;
    }
    const formGroup = this.formBuilder.group(formControls);
    return formGroup;
  }

  /**
   * Formats parameters into a format that can be used by the report generator
   * @param parameters 
   * @returns 
   */
  formatParameters(parameters: {ParameterID: number, Values: any[]}[], adminParams: ReportParameter[]): ReportParameter[] {
    const formattedParams: ReportParameter[] = [];
    
    for (const param of parameters) {
      
      const matchingAdminParameter = adminParams.find(parameter => parameter.ParameterID === param.ParameterID);
      if (!matchingAdminParameter) continue;
      const formattedParameter: ReportParameter = {
        ParameterID: param.ParameterID,
        Type: matchingAdminParameter.Type,
        Name: matchingAdminParameter.Name,
        Prompt: matchingAdminParameter.Prompt,
        IDColumn: matchingAdminParameter.IDColumn,
        NameColumn: matchingAdminParameter.NameColumn,
        Form: this.getFormControlFromType(matchingAdminParameter.Type), //create a new form for this parameter
      };
      if (param.Values.length > 0) {
        formattedParameter.Options = this.formatOptions(param.Values, matchingAdminParameter.IDColumn, matchingAdminParameter.NameColumn);
      }

      formattedParams.push(formattedParameter);
    }
    return formattedParams;
  }

  /**
   * Formats options into a format that can be used by the report generator
   * @param values 
   * @param IDColumn 
   * @param nameColumn 
   * @returns 
   */
  formatOptions(values: any[], IDColumn: string, nameColumn: string) {
    const formattedOptions = [];
    for (const value of values) {
      formattedOptions.push({
        value: value[IDColumn],
        name: value[nameColumn],
      });
    }
    return formattedOptions;
  }

  /**
   * Refreshes the reports by calling API and updating the reports observable
   */
  refreshReports(): void {
    const value: object = {
      query: {
        reportTypeID: ReportType.NormalReport
      }
    }
    this.invokeAPIWithPayload(value).then((result: {reports: Report[]}) => {
      this.allReports$.next(result.reports);
      this.refreshReportCategories(result.reports);
    });
  }


  /**
   * Refreshes the report categories by calling API and updating the reports observable 
   * @param reports 
   */
  refreshReportCategories(reports: Report[]) {
    const value: object = {
      query: {
        categories: {
          all: true
        }
      }
    }
    this.invokeAPIWithPayload(value).then((result: any) => {
      const reportsInCategories: ReportCategory[] = this.groupReports(result.categories, reports);
      this.reportCategories$.next(reportsInCategories);
    });
  }


  async refreshAdminParameters() {
    const value: object = {
      query: {
        parameters: {
          all: true
        }
      }
    }
    const result = await this.invokeAPIWithPayload(value);
    const params: ReportParameter[] = [];
    for (const param of result) {
      params.push({
        ParameterID: param.ParameterID,
        Type: param.Type,
        Name: param.Name,
        Prompt: param.Prompt,
        IDColumn: param.IDColumn,
        NameColumn: param.NameColumn,
        Form: null,
      });
    }
    this.adminParameters$.next(params);
  }


  getFormControlFromType(type: number) {
    switch (type) {
      case 1: //Date
        return new FormControl<Date | null>(null);
      case 2: //Number
        return new FormControl<number | null>(null);
      case 3: //String
      case 4: //Select
        return new FormControl<string | null>(null);
      case 5: //Multiselect
        return new FormControl<string[] | null>(null);
      default:
        return new FormControl<any>(null);
    }
  }


  async queryReportByID(id: number) {
    const value: object = {
      query: {
        reportID: id
      }
    }
    const result = await this.invokeAPIWithPayload(value);
    return result;
  }

  /**
   * Groups the reports by categories
   * @param categories 
   * @param reports 
   * @returns {ReportCategory[]}
   */
  groupReports(categories: any[], reports: Report[]): ReportCategory[] {
    const groups: ReportCategory[] = [];
    for (const category of categories) {
      if (!category.CategoryName) continue;
      groups.push({
        CategoryID: category.CategoryID,
        CategoryName: category.CategoryName,
        Description: category.Description,
        Reports: reports.filter(report => category.ReportIDs.includes(report.ReportID))
      });
    }
    return groups;
  }


  runReport(reportID: number, values: object): Observable<any | any[]> {
    //TODO: Implement report run
    return new Observable((subscriber) => {
      this.queryReport(reportID, values)
        .then((result) => {
          if (result) {
            subscriber.next(result);
            subscriber.complete();
          } else {
            subscriber.error('Error running report ' + reportID);
          }
        });
    });
  }


  async queryReport(reportID: number, values: object) {
    return await this.invokeAPIWithPayload({
      run: {
        reportID: reportID,
        parameters: values
      }
    });
  }

  /**
 * Calls the API with specific payload 
 */
  async invokeAPIWithPayload(apiValue: object) {
    this.progressBarService.start();
    let result = null;
    try {
      const apiKey = apiKeys.u2.reportController;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      const apiResult = await this.utilocateAPIService.invokeUtilocateApi(
        utilocateApiRequest
      );
      if (apiResult.body) {
        result = apiResult.body;
      }
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
    return result;
  }
}
