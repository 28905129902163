import { Component, OnInit, Input, NgModule } from "@angular/core";

@Component({
  selector: "app-bottom-navigation",
  templateUrl: "./bottom-navigation.component.html",
  styleUrls: ["./bottom-navigation.component.css"],
})
export class BottomNavigationComponent {
  @Input() inputs: any;

  constructor() {}

  getInput(i) {
    const input = this.inputs[i];
    input.action();
  }
}

export interface bottomNavOptions {
  id: string;
  icon: string;
  label: string;
  action: any;
}
