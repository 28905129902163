import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { BaseModalComponent } from "../base-modal.component";
import { ModalService } from "../modal.service";

@Component({
  selector: "app-success-modal",
  templateUrl: "success-modal.component.html",
  styleUrls: ["success-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SuccessModalComponent extends BaseModalComponent {
  constructor(modalService: ModalService, el: ElementRef) {
    super(modalService, el);
  }
}
