import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { CompletionsStoreService } from "../core/admin/completions-store.service";
import { SchedulerService } from "../scheduler/services/scheduler.service";
import { UploadTicketService } from "../shared/ticket-details/upload-ticket.service";
import { UploadDocumentsService } from "../shared/upload-documents/upload-documents.service";
import { switchMap } from "rxjs/operators";
import { UploadProgress } from "../shared/upload-documents/upload-progress";
import {
  ActionMessage,
  Actions,
} from "../core/component-messaging/action-message";
import {
  CREATE_TICKET_UID_IDENTIFIER,
  generateUID,
} from "../create-ticket/create-ticket-component.service";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  curCreateTicketUid: string;
  constructor(
    private completions$: CompletionsStoreService,
    private uploadTicket$: UploadTicketService,
    private uploadDocument$: UploadDocumentsService,
    private scheduler$: SchedulerService
  ) {
    // this.generateCreateTicketSessionID();
  }

  generateCreateTicketSessionID() {
    let tmpUid = CREATE_TICKET_UID_IDENTIFIER + generateUID();
    // assign and save uid
    this.curCreateTicketUid = tmpUid;
    sessionStorage.setItem(
      localStorageKeys.CURRENT_CREATE_TICKET_KEY,
      this.curCreateTicketUid
    );
  }

  sync(): Observable<any> {
    return new Observable((subscriber) => {
      from(this.completions$.getRow(this.curCreateTicketUid))
        .pipe(
          switchMap((ticketRow) => {
            // get ticket data from local IDB
            if (ticketRow) {
              let progress = JSON.stringify({ row: ticketRow });
              return of(
                new UploadProgress(
                  progress,
                  true,
                  "Gather Local Tickets: Success"
                )
              );
            } else {
              return of(
                new UploadProgress(null, false, "Gather Local Tickets: Failed")
              );
            }
          }),
          switchMap((getRowResult: UploadProgress) => {
            // upload ticket data to API
            if (getRowResult && getRowResult.ok) {
              return this.uploadTicket$.uploadTicket(getRowResult);
            } else {
              return of(
                new UploadProgress(
                  getRowResult.body,
                  false,
                  getRowResult.msg + "Upload API Response: Function Error"
                )
              );
            }
          }),
          switchMap((uploadTicketResult: UploadProgress) => {
            // update ticket upload progress locally in IDB
            if (uploadTicketResult && uploadTicketResult.ok) {
              if (!uploadTicketResult.ignoreStep) {
                let AssignmentID = JSON.parse(uploadTicketResult.body)[
                  "AssignmentID"
                ];
                let metadata = { AssignmentID };
                return this.completions$
                  .updateRowMetadata(
                    new ActionMessage(Actions.UPDATE_METADATA, {
                      rowKey: this.curCreateTicketUid,
                      value: metadata,
                    })
                  )
                  .pipe(
                    switchMap((successfulSave: boolean) => {
                      if (successfulSave) {
                        return of(
                          new UploadProgress(
                            uploadTicketResult.body,
                            true,
                            uploadTicketResult.msg +
                              "Update Local Progress: Success"
                          )
                        );
                      } else {
                        return of(
                          new UploadProgress(
                            uploadTicketResult.body,
                            false,
                            uploadTicketResult.msg +
                              "Update Local Progress: Failed to add AssignmentID"
                          )
                        );
                      }
                    })
                  );
              } else {
                // already uploaded this ticket
                return of(
                  new UploadProgress(
                    uploadTicketResult.body,
                    true,
                    uploadTicketResult.msg +
                      "Update Locate Progress: Ignoring Step"
                  )
                );
              }
            } else {
              // no metadata indicates a failed or yet-to-upload ticket
              return of(
                new UploadProgress(
                  uploadTicketResult.body,
                  false,
                  uploadTicketResult.msg +
                    "Update Local Progress: Function Error"
                )
              );
            }
          }),
          switchMap((localSaveResult: UploadProgress) => {
            // schedule ticket now that ticket has assignmentid.
            if (localSaveResult && localSaveResult.ok) {
              // return this.uploadDocument$.uploadDocuments(localSaveResult);
              return this.scheduler$.createTicketWithEvent(localSaveResult);
            } else {
              return of(
                new UploadProgress(
                  localSaveResult.body,
                  false,
                  localSaveResult.msg + "Update Local Progress: Error"
                )
              );
            }
          }),
          switchMap((localSaveResult: UploadProgress) => {
            // upload document data to API
            if (localSaveResult && localSaveResult.ok) {
              return this.uploadDocument$.uploadDocuments(localSaveResult);
            } else {
              return of(
                new UploadProgress(
                  localSaveResult.body,
                  false,
                  localSaveResult.msg + "Update Local Progress: Error"
                )
              );
            }
          }),
          switchMap((uploadDocumentsResult) => {
            // update document upload progress locally in IDB
            if (uploadDocumentsResult && uploadDocumentsResult.ok) {
              if (!uploadDocumentsResult.ignoreStep) {
                // gather filenames of documents to update progress of
                let docsResult = JSON.parse(uploadDocumentsResult.body)[
                  "DocumentResult"
                ];
                let documentsToUpdate = docsResult.reduce(
                  (arr, documentResult) => {
                    if (documentResult.ok) {
                      let filename = JSON.parse(documentResult.body)[
                        "FileName"
                      ];
                      arr.push(filename);
                    }
                    return arr;
                  },
                  []
                );

                return this.completions$
                  .updateDocumentsUploadStatus(
                    new ActionMessage(Actions.UPDATE_DOCUMENT_STATUS_DATA, {
                      rowKey: this.curCreateTicketUid,
                      value: documentsToUpdate,
                    })
                  )
                  .pipe(
                    switchMap((result) =>
                      of(
                        new UploadProgress(
                          uploadDocumentsResult.body,
                          result,
                          uploadDocumentsResult.msg +
                          "Update Local Progress: " +
                          result
                            ? "Success"
                            : "False"
                        )
                      )
                    )
                  );
              } else {
                return of(
                  new UploadProgress(
                    uploadDocumentsResult.body,
                    true,
                    uploadDocumentsResult.msg +
                      "Update Local Progress: Ignoring Step",
                    true
                  )
                );
              }
            } else {
              return of(
                new UploadProgress(
                  uploadDocumentsResult.body,
                  false,
                  uploadDocumentsResult.msg + "Update Local Progress: Error"
                )
              );
            }
          }),
          switchMap((updateDocumentsResult: UploadProgress) => {
            if (updateDocumentsResult && updateDocumentsResult.ok) {
              return this.completions$
                .removeRow(
                  new ActionMessage(Actions.RESET_DATA, {
                    rowKey: this.curCreateTicketUid,
                  })
                )
                .pipe(
                  switchMap((successfulSave: boolean) =>
                    of(
                      new UploadProgress(
                        updateDocumentsResult.body,
                        successfulSave,
                        updateDocumentsResult.msg +
                          "Update Local Progress: Success"
                      )
                    )
                  )
                );
            } else {
              return of(
                new UploadProgress(
                  updateDocumentsResult.body,
                  false,
                  updateDocumentsResult.msg + "Update Locate Progress: Failed"
                )
              );
            }
          })
        )
        .subscribe((finalResult: UploadProgress) => {
          console.log(finalResult);
          if (finalResult && finalResult.ok) {
            subscriber.next(finalResult);
            this.generateCreateTicketSessionID();
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
    });
  }
}
