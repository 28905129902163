import {
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BaseComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-recursive-form-input-template",
  templateUrl: "./recursive-form-input-template.component.html",
  styleUrls: ["../recursive-form-template.scss"],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class RecursiveFormInputTemplateComponent
  extends BaseComponent
  implements OnChanges
{
  fields: any;
  formGroup: FormGroup;

  currentAddressPickerField: any;

  @Input() readOnly: boolean;
  @Output() dropdownSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(logger$: LoggerService) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["props"] && changes["props"]["currentValue"]) {
      // if(changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
      //   this.TICKET_PROTECTION_MODE = changes.props.currentValue.TICKET_PROTECTION_MODE;
      // }

      if (
        changes["props"]["currentValue"]["fields"] &&
        changes["props"]["currentValue"]["formGroup"]
      ) {
        this.fields = changes["props"]["currentValue"]["fields"];
        this.formGroup = changes["props"]["currentValue"]["formGroup"];
      }
    }
  }

  //12 & 11
  onDropdownSelectionChange(field, event) {
    try {
      let emitValue = {
        field: field,
        event: event,
      };
      this.dropdownSelected.emit(emitValue);
    } catch (error) {
      this.logger$.error("onDropdownselectionChange: " + error.message);
    }
  }

  //need to do this because dropdown value is not primitive type
  compareOptions(option: any, selected: any): boolean {
    try {
      //return true when found
      return option && selected
        ? option.toString().toLowerCase() === selected.toString().toLowerCase()
        : option === selected;
    } catch (error) {
      this.logger$.error("compareOptions: " + error.message);
      return false;
    }
  }
}
