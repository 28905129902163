import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNavigationModule} from '../shared/page-navigation/page-navigation.module';
import {ReportGeneratorItemComponent} from './report-generator-item/report-generator-item.component';
import {ReportGeneratorTypesComponent} from './report-generator-types/report-generator-types.component';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../shared/material.module';
import {ProgressBarModule} from '../shared/progress-bar/progress-bar.module';
import {SnackbarModule} from '../shared/snackbar/snackbar.module';
import {TableModule} from '../shared/table/table.module';
import {TabulatorTableComponent} from '../shared/table/tabulator-table/tabulator-table.component';
import {ReportGeneratorQueryComponent} from './report-generator-query/report-generator-query.component';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [
    ReportGeneratorItemComponent,
    ReportGeneratorTypesComponent,
    ReportGeneratorQueryComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    PageNavigationModule,
    ProgressBarModule,
    SnackbarModule,
    MaterialModule,
    TableModule,
    TabulatorTableComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    TabulatorTableComponent
  ]
})
export class ReportGeneratorModule { }
