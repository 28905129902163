import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-floating-tab-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
  templateUrl: './floating-tab-select.component.html',
  styleUrls: ['./floating-tab-select.component.scss'],
})
export class FloatingTabSelectComponent {
  @Input() tabs: { index: number; title: string; icon: string }[] = [];
  @Input() multiple = false;
  @Input() showLabel = false;
  @Input() activeTab: Array<number> = [];
  @Output() activeTabChange: EventEmitter<Array<number>> = new EventEmitter();

  constructor() {}

  handleTabClick(index: number) {
    const currentIndex = this.activeTab.indexOf(index);
    if (this.multiple) {
      if (currentIndex === -1) {
        // Add to selections if not currently selected
        this.activeTab = [...this.activeTab, index];
      } else {
        // Remove from selections if currently selected
        this.activeTab.splice(currentIndex, 1);
        // Select the next tab automatically
        if (this.activeTab.length === 0) {
          const nextIndex = (index + 1) % this.tabs.length;
          this.activeTab.push(nextIndex);
        }
      }
    } else {
      if (currentIndex !== -1 && this.activeTab.length === 1) {
        // If trying to deselect the only selected tab
        this.activeTab.pop();
        const nextIndex = (index + 1) % this.tabs.length;
        this.activeTab.push(nextIndex);
      } else {
        this.activeTab = [index];
      }
    }
    this.activeTabChange.emit(this.activeTab);
  }

  isSelected(index: number): boolean {
    return this.activeTab.includes(index);
  }
}
