<div>
  <h3 mat-dialog-title style="margin: 0">{{ data.title }}</h3>
  <mat-dialog-content>
    <p>{{ data.text }}</p>
    <p style="font-weight: 600">{{ data.boldText }}</p>
  </mat-dialog-content>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
    <button color="warn" mat-raised-button (click)="onDeleteClick()">
      Delete
    </button>
  </div>
</div>
