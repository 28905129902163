import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationBarService {
  private navBarPageChanges: Subject<any>;
  private menuSubject = new BehaviorSubject<boolean>(false);
  menuToggled$: Observable<boolean> = this.menuSubject.asObservable();

  constructor() {
    this.navBarPageChanges = new Subject<any>();
    console.log("nav bar service");
  }

  getNavBarChanges(): Observable<any> {
    return this.navBarPageChanges.asObservable();
  }

  updateNavBar(newTitle: any) {
    this.navBarPageChanges.next(newTitle);
    return this;
  }

  getMenuValue(): boolean {
    return this.menuSubject.getValue();
  }

  openMenu() {
    this.menuSubject.next(true);
  }

  closeMenu() {
    this.menuSubject.next(false);
  }

  toggleMenu() {
    this.menuSubject.next(!this.getMenuValue());
  }
}
