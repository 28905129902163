import {Component, OnInit} from '@angular/core';
import {HeaderOptions, RouteOptions} from '../../shared/page-navigation/page-nav-options';
import {ReportGeneratorService} from '../report-generator.service';
import {ReportCategory} from '../report-generator.types';
import {Router} from '@angular/router';

@Component({
  selector: 'app-report-generator-types',
  templateUrl: './report-generator-types.component.html',
  styleUrl: './report-generator-types.component.scss',
})
export class ReportGeneratorTypesComponent implements OnInit {
  routes: RouteOptions[];
  header: HeaderOptions = {
    title: "Report Categories",
  };

  routeToReport: boolean = false;
  noRoute: boolean = false;


  constructor(private reportsService: ReportGeneratorService, private router: Router) { }

  ngOnInit(): void {
    this.routes = [];

    this.router.events.subscribe(() => {
      // Check if the current route is empty
      if (this.router.url.split('reports')[1]?.length === 0) {
        this.noRoute = true;
        // Do something if the router outlet is empty
      } else {
        this.noRoute = false;
      }
    });

    this.reportsService.getReports$().subscribe((value: ReportCategory[]) => {
      const reports = value;
      for (const report of reports) {
        const subroutes = [];
        //"Sub Routes" are the reports in the category
        report.Reports.forEach((r) => {
          subroutes.push({
            path: r.ReportID.toString(),
            label: r.Name,
            selected: false
          });
        });
        //"Main Routes" are the categories
        this.routes.push({
          // we need to give path a unique name so there's no overlap between the cateogry 
          // being selected and the report being selected
          path: `${report.CategoryName}-${report.CategoryID.toString()}`,
          label: report.CategoryName,
          selected: false,
          expandOptions: subroutes
        });
      }
    });
  }
}
