import { Feature } from 'ol';
import { Style } from 'ol/style';
import { StyleLike } from 'ol/style/Style';
import { Color } from 'ol/color';
import { ColorLike } from 'ol/colorlike';

type HexString = string;
export type RGBA = number[];
type RGB = number[];
type ColourFormat = HexString | RGBA;

export class StyleToolbox {
  public convertColour(colour: Color | ColorLike, outputFormat: ColourFormatOption): ColourFormat {
    switch (outputFormat) {
      case ColourFormatOption.hex:
        return this.colorLikeToHex(colour);
      case ColourFormatOption.rgba:

      default:
        return '#000000';
    }
  }

  private colorLikeToHex(colour: Color | ColorLike): HexString {
    if (Array.isArray(colour)) {
      return this.RGBAToHex(colour);
    } else if (typeof colour === 'string') {
      if (colour[0] === '#') {
        return colour;
      } else if (colour.includes(',') && colour.split(',').length >= 3) {
        return this.RGBAToHex(colour.split(',').map((x) => parseInt(x)));
      }
    } else return '#000000';
  }
  //
  // private anyToRGBA(colour: Color | ColorLike): RGBA {}
  //
  // private hexToRGB(colour: HexString): RGBA {}

  /**
   * Converts a hex string to an RGBA array
   * @param colorString - a hex string
   * @param opacityVal - a number between 0 and 100
   */
  public hexToRGBA(colorString: string, opacityVal: Percentage): RGBA {
    try {
      const colorClone = colorString.replace('#', '');
      const formatted = [];
      for (let i = 0; i < 6; i += 2) {
        formatted.push(parseInt(colorClone.slice(i, i + 2), 16));
      }
      formatted.push(opacityVal / 100);
      return formatted;
    } catch (e) {
      return [150, 150, 150, 0.8];
    }
  }

  /**
   *
   * @param colourArray - an array of 3 or 4 numbers
   * @constructor
   */
  public RGBAToHex(colourArray): string {
    try {
      const hex = colourArray.slice(0, 3).map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      });
      return '#' + hex.join('');
    } catch (e) {
      return '#969696';
    }
  }

  public getStyleFromStyleLike(feature: Feature, resolution: number): Style {
    const styleLike: StyleLike = feature.getStyle();
    let style: Style;

    if (styleLike && Array.isArray(styleLike)) {
      style = styleLike[0];
    } else if (styleLike instanceof Style) {
      style = styleLike;
    } else if (typeof styleLike === 'function') {
      const temp = styleLike(feature, resolution);
      style = temp instanceof Style ? temp : temp[0];
    }

    return style;
  }
}

export enum ColourFormatOption {
  hex,
  rgb,
  rgba,
}

export type Percentage = number;
