<div fxlayout="column" class="layer-panel" style="width: '100%'">
  <div class="panel-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="panel-header-text mat-subtitle-2">Layers</span>
    <button mat-icon-button (click)="this.panelClose.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="layer-content">
    <mat-accordion>
      <ng-container
        *ngTemplateOutlet="recursiveListTmpl; context: { list: layers }"
      ></ng-container>
    </mat-accordion>
  </div>
  <ng-template #recursiveListTmpl let-list="list">
    <div *ngFor="let item of list" style="margin-left: 16px">
      <span *ngIf="item.subLayers.length > 0; else noSubLayers">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header class="header">
            <mat-panel-title [matTooltip]="item.name">
              <mat-checkbox
                style="line-height: 24px"
                (click)="$event.stopPropagation()"
                (change)="onCheckBoxChange($event, item)"
                [checked]="item.isChecked"
                [disabled]="!item.isParentChecked"
              >
                {{ item.name }}
              </mat-checkbox>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container
            *ngTemplateOutlet="
              recursiveListTmpl;
              context: { list: item.subLayers }
            "
          ></ng-container>
        </mat-expansion-panel>
      </span>

      <ng-template #noSubLayers>
        <div
          class="header mat-expansion-panel-content"
          fxLayout="row"
          fxLayoutAlign="start center"
          [matTooltip]="item.name"
        >
          <mat-checkbox
            style="line-height: 24px"
            (change)="onCheckBoxChange($event, item)"
            [checked]="item.isChecked"
            [disabled]="!item.isParentChecked"
          >
            {{ item.name }}
          </mat-checkbox>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
