<div class="box-border flex flex-col w-full h-full p-3 bg-white">
  <div class="flex flex-col justify-start w-full py-3">
    <div class="flex flex-row basis-[calc(100% - 57px)] justify-between items-start"
         [ngClass]="{'pl-28': leftPaddingForTableButtons === true}">
      <div class="left-button-container flex flex-row justify-between items-center gap-[6px]">
        <button
          class="flex justify-center items-center"
          mat-icon-button
          aria-label="Example icon-button with a menu"
          appClickThrottle
          [throttleTime]="5000"
          (throttleClick)="handleRefreshClick()">
          <mat-icon svgIcon="sync" />
        </button>
        <app-table-column-select [tableService]="tableService"></app-table-column-select>
        <button
          class="flex justify-center items-center"
          mat-icon-button
          [matMenuTriggerFor]="exportMenu"
          aria-label="Data export menu trigger">
          <mat-icon svgIcon="download"></mat-icon>
        </button>
        <mat-menu #exportMenu="matMenu" class="mat-menu-remove-padding flex flex-col">
          <button
            class="flex basis-[36px] justify-start items-center"
            mat-menu-item
            [disableRipple]="true"
            (click)="downloadTable()">
            <span class="font-rajdhani text-md uppercase font-semibold">EXPORT CSV</span>
          </button>
          <!-- <button
            class="flex basis-[36px] justify-start items-center"
            mat-menu-item
            [disableRipple]="true"
            [disabled]="true">
            <span style="text-decoration: line-through">Export all pdfs</span>
          </button> -->
        </mat-menu>

        <app-button-dropdown-select
          class="h-9 -mr-6 mb-[1px]"
          title="Select a column to search"
          [selection]="selectedColumnToSearch"
          [items]="searchableColumnList"
          (selectionChange)="onSearchColumnSelectChange($event)" />
        <app-competers-search-bar
          class="h-9 md:w-32 lg:w-64 pb-1"
          [clickToSearch]="true"
          trailing="filter"
          [addLeftSpacing]="true"
          (searchValueChange)="searchTerm = $event"></app-competers-search-bar>
      </div>
      @if (settingsService.isSettingActive(SettingID.ENABLE_QUICK_TICKET_ACTIONS)) {
        <app-ticket-actions-menu (ticketActionEvent)="triggerAction($event)" />
      }
    </div>
  </div>

  <!-- Table -->

  <app-tabulator-table
    class="h-full w-full"
    #tabulatorTable
    [tableData]="searchService.searchResults$ | async"
    [columns]="tableColumns"
    [columnFormatterFunctionList]="cellFormatters"
    [rowFormatter]="rowFormatter"
    (rowDoubleClick)="openTicket($event)"
    (rowSingleClick)="openTicket($event)"
    (columnReorderEvent)="colReorder($event)"
    (columnWidthChangeEvent)="colWidthChange($event)"
    [showSelect]="true"
    [searchString]="searchTerm"
    [searchColumns]="[selectedColumnToSearch]"
    [rowIndex]="'SubNum'"
    [selectedRows]="homeWorkspaceService.ticketSelection$ | async"
    (selectedRowsChanged)="homeWorkspaceService.ticketSelection = $event"
    [persistSelectedRows]="persistSelectionThroughDataChange"/>
</div>
