{
  "userSettings": [
    {
      "title": "User Manager",
      "icon": "users",
      "description": "Create, Edit & Manage Individual Users",
      "path": "user-manager",
      "settingID": "268"
    },
    {
      "title": "User Categories",
      "icon": "worker",
      "description": "Create, Edit & Manage User Categories",
      "path": "user-category-editor",
      "settingID": "304"
    }
  ],
  "adminSettings": [
    {
      "title": "Rule Builder",
      "icon": "rule builder",
      "description": "Build, Edit & Manage Rules for Business Intelligence",
      "path": "rule-builder",
      "settingID": "292"
    },
    {
      "title": "Table Editor",
      "icon": "table-editor",
      "description": "Add, Remove & Edit Scheduler Items",
      "path": "general",
      "settingID": "260"
    },
    {
      "title": "Rate Editor",
      "icon": "rate-editor",
      "description": "Create, Edit & Manage Rates",
      "path": "rates",
      "settingID": "273"
    },
    {
      "title": "Routing Manager",
      "icon": "routing",
      "description": "Create, Edit & Manage Routes for Field Staff",
      "path": "routing-preset",
      "settingID": "267"
    },
    {
      "title": "Invoicing Manager",
      "icon": "invoice",
      "description": "Create, Edit & Manage Invoices",
      "path": "invoicing-manager",
      "settingID": "301"
    }
  ],
  "miscSettings": [
    {
      "title": "Version Information",
      "icon": "info",
      "description": "Version Information",
      "path": "version-information",
      "settingID": null
    }
  ]
}
