import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SearchableDropdownComponent } from "./searchable-dropdown/searchable-dropdown.component";
import { SimpleSearchableDropdownComponent } from "./simple-searchable-dropdown/simple-searchable-dropdown.component";
import { SearchableDropdownCoreComponent } from "./searchable-dropdown-core/searchable-dropdown-core.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    SearchableDropdownComponent,
    SimpleSearchableDropdownComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SearchableDropdownCoreComponent,
  ],
  exports: [
    SearchableDropdownComponent,
    SimpleSearchableDropdownComponent,
    SearchableDropdownCoreComponent,
  ],
})
export class SearchableDropdownModule {}
