import {CommonModule} from '@angular/common';
import {Component, NgModule, OnInit} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatDialog} from '@angular/material/dialog';
import {SchedulerTicketDialogComponent} from '../../scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component';
import {MaterialModule} from '../../shared/material.module';
import {ProgressBarService} from '../../shared/progress-bar/progress-bar.service';
import {SnackbarService} from '../../shared/snackbar/snackbar.service';
import {SnackbarType} from '../../shared/snackbar/snackbar/snackbar';
import {TableModule, TemplateColumn} from '../../shared/table/table.module';
import {SystemMaintenanceService} from '../services/system-maintenance.service';
import {TableService} from '../../shared/table/table.service';
import {SelectionType} from '@swimlane/ngx-datatable';
import {CompetersSearchBarComponent} from '../../../shared/components/inputs/competers-search-bar/competers-search-bar.component';
import {TabulatorTableComponent} from "../../shared/table/tabulator-table/tabulator-table.component";

@Component({
  selector: 'app-failed-360',
  templateUrl: 'failed-360.component.html',
  styleUrls: ['failed-360.component.scss'],
  providers: [TableService],
})
export class Failed360Component implements OnInit {
  // table config
  selectionType: SelectionType = SelectionType.multi;
  searchString: string = '';

  // component
  errorRows: any[] = [];
  failedToLoadData: boolean = false;
  loadedData: boolean = false;
  displayedColumns: TemplateColumn[] = [];
  displayedColumnNames: string[] = [
    'Request Number',
    'Station Code',
    'Error Description',
    '360 Response Type',
    'Response Center',
    'Third Party ID',
    'Response Notes',
    'Response Payload',
  ];
  selected: any[] = [];

  ResponseCenter = {
    0: 'None',
    1: 'Ontario One Call',
    2: 'Korterra Indiana',
    3: 'KorTerra Kentucky',
    4: 'IN811',
    5: 'Enbridge (SFTP)',
    6: 'Vectren',
    7: 'Kentucky',
    8: 'Boss 811',
    9: 'Telus IRTH',
    10: 'Bell IRTH',
    11: 'Quebec 360',
    12: 'Utilocate 360',
    13: 'Korterra Minnesota',
    14: 'Boss 811 Minnesota',
    15: 'Kentucky Boss 811',
    16: 'Indiana Boss 811',
    17: 'Enbridge IRTH',
    18: 'AEP Michigan 811 IRTH',
    19: 'Michigan 811 Pelican',
    20: 'Ohio 811',
    21: 'GPIRTH',
    22: 'AEP Transmission IRTH',
    23: 'Duke IRTH',
    24: 'Minnesota Call Center',
    25: 'South Carolina IRTH',
  };

  ThreeSixtyResponseType = {
    1: 'Completed Ticket',
    2: 'Date Changed',
    3: 'Send Notes',
    4: 'Cancel Ticket',
    5: 'Contact Member',
    6: 'Meeting Scheduled',
    7: 'Assistance Needed',
    8: 'Ongoing',
    9: 'Unlocatable',
    10: 'Reopen',
    11: 'Manually Cleared',
  };

  constructor(
    private systemMaintenance$: SystemMaintenanceService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    public tableService: TableService<unknown>
  ) { }

  ngOnInit(): void {
    this.refreshView();
  }

  async refreshView() {
    this.progressBarService.start();
    const result = await this.systemMaintenance$.get360Errors().toPromise();

    if (result.body.Result) {
      this.errorRows = this.formatRows(result.body.Result.Data);
      this.formatColumns(result.body.Result.Columns);
      this.loadedData = true; 
    }

    if (result.body.FailMessage) {
      this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      this.failedToLoadData = true;
    }
    this.errorRows = [...this.errorRows];
    this.progressBarService.stop();
  }

  formatRows(rows: any[]) {
    let returnRows = [];
    returnRows = rows.map((row) => {
      return {
        ErrorID: row.ErrorID,
        AssignmentID: row.AssignmentID,
        ResponseCenterID: row.ResponseCenterID,
        ThreeSixtyResponseType: row.ThreeSixtyResponseType,
        'Request Number': row.RequestNumber,
        'Station Code': row.StationCode,
        'Error Description': row.ErrorDescription,
        '360 Response Type': this.ThreeSixtyResponseType[row.ThreeSixtyResponseType],
        'Response Center': this.ResponseCenter[row.ResponseCenterID],
        'Third Party ID': row.PolygonThirdPartyID ? row.PolygonThirdPartyID : '',
        'Response Notes': row.ResponseNotes,
        'Response Payload': row.ResponsePayload,
      };
    });
    return returnRows;
  }

  formatColumns(columns: string[]) {
    this.displayedColumns = this.displayedColumnNames.map((col, index) => {
      return {
        TemplateColumnID: 0,
        ColumnOrder: index,
        TemplateID: 0,
        Field: 0,
        Visible: 1,
        Width: 200,
        Title: col,
      } as TemplateColumn;
    });
  }

  async onResend() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.retry360(this.createLambdaBody(this.selected)).toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Retried 360 Response', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Retry 360 Response', SnackbarType.error);
      }
    }
  }

  async onMarkSentManually() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$
        .markSendManually360(this.createLambdaBody(this.selected))
        .toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Marked 360 Error Row as Sent Manually', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Mark 360 Error Row as Sent Manually', SnackbarType.error);
      }
    }
  }

  async onMarkAbandoned() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.markAbandon360(this.createLambdaBody(this.selected)).toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Marked 360 Error Row as Abandoned', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Mark 360 Error Row as Abandoned', SnackbarType.error);
      }
    }
  }

  createLambdaBody(selectedRows: any[]) {
    const formattedRows: Formatted360Row[] = [];
    selectedRows.forEach((selectedRow) => {
      formattedRows.push({
        ErrorID: selectedRow.ErrorID,
        AssignmentID: selectedRow.AssignmentID,
        ThreeSixtyResponseType: selectedRow.ThreeSixtyResponseType,
        PolygonCode: selectedRow['Station Code'],
        ResponseCenterID: selectedRow.ResponseCenterID,
        Notes: selectedRow['Response Notes'],
      });
    });
    return formattedRows;
  }

  openTicket(row) {
    const ticketData = {
      AssignmentID: row.AssignmentID,
      isModal: true,
    };

    const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
      width: window.innerWidth.toString() + 'px',
      maxWidth: '100%',
      height: window.innerHeight.toString() + 'px', // if small screen make full screen
      panelClass: 'margin-dialog-container',
      data: {
        ticketData,
      },
    });
  }
}

export interface Formatted360Row {
  ErrorID: number;
  AssignmentID: number;
  ThreeSixtyResponseType: number;
  PolygonCode: number;
  ResponseCenterID: number;
  Notes: string;
}

@NgModule({
  declarations: [Failed360Component],
  exports: [Failed360Component],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TableModule, CompetersSearchBarComponent, TabulatorTableComponent]
})
export class SystemMaintenanceModule { }
