import { Component, OnInit, ViewChild } from "@angular/core";

import { from, fromEvent, Observable } from "rxjs";
import { switchMap, filter } from "rxjs/operators";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import {
  agmMapControlPosition,
  agmMapTypeControlStyle,
  agmOptions,
} from "src/app/modules/shared/google-map/google-maps-set/agmOptions";
import { googleMapsOptions } from "src/app/modules/shared/google-map/google-maps-set/googleMapsOptions";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";

@Component({
  selector: "app-create-map-view",
  templateUrl: "./create-map-view.component.html",
  styleUrls: ["./create-map-view.component.scss"],
  providers: [ComponentMessagingService],
})
export class CreateMapViewComponent
  extends BaseViewComponent
  implements OnInit
{
  readonly className: string = "CreateMapViewComponent";
  // @Input() props: any;
  @ViewChild("cmpDrawShapesGoogleMaps", {}) cmpDrawShapesGoogleMaps;

  options: agmOptions;
  childProps: any;
  finishedDrawingDisabled: boolean = true;
  viewOnly: boolean = false;
  theme = "";

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private progressBarService: ProgressBarService
  ) {
    super(logger$);
  }

  ngOnInit() {
    try {
      // this.theme$.getTheme().subscribe((nextMsg) => this.theme = nextMsg.themeName);
      let options: googleMapsOptions = {
        zoomControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: agmMapTypeControlStyle.DROPDOWN_MENU,
          position: agmMapControlPosition.TOP_RIGHT,
        },
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: true,
      };

      this.options = options;
      this.childProps = {
        messageService: this.compMsg$,
      };

      // listen to panel and view-siblings
      this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          // filter self messages
          if (nextMsg.senderID.indexOf(this.className) < 0) {
            if (nextMsg.message["onEditDigsite"]) {
              this.cmpDrawShapesGoogleMaps.turnDrawingOn();
              this.finishedDrawingDisabled = false;
              this.viewOnly = false;
              // this.snackBarService.setToast(NotificationType.loading, "We recommend only drawing up to 5 polygons", "Warning:");
            } else if (nextMsg.message["onClearDrawing"]) {
              this.cmpDrawShapesGoogleMaps.clearDrawing();
            } else if (nextMsg.message["onReviewToggle"]) {
              this.viewOnly = !this.viewOnly;
              if (this.viewOnly) {
                this.cmpDrawShapesGoogleMaps.zoomToPolygons();
              }
            } else if (nextMsg.message["dbSearchAddress"]) {
              this.searchAddress(nextMsg.message["address"]);
            }
          }
        });

      this.onSearchAddress();
    } catch (ex) {
      this.logger$.error(this.className, "ngOnInit", "could not init");
    }
  }

  onSearchAddress = () => {
    const searchBar = document.getElementById("searchAddressBar");
    const searchBarBtn = document.getElementById("searchAddressBarSearchBtn");

    const search = () =>
      new Observable((subscriber) => {
        let address = searchBar["value"];
        let requestBody = { address };

        this.progressBarService.start();
        this.cmpDrawShapesGoogleMaps
          .getLatLngFromAddress(requestBody)
          .then((result) => {
            this.progressBarService.stop();
            subscriber.complete();
          })
          .catch((error) => {
            this.logger$.warn(
              "onSearchAddress: failed to find address: ",
              error
            );
            this.progressBarService.stop();
            subscriber.complete();
          });
      });

    fromEvent(searchBar, "keyup")
      .pipe(
        filter((event) => event["keyCode"] === 13),
        switchMap(() => search())
      )
      .subscribe();
    fromEvent(searchBarBtn, "click")
      .pipe(switchMap(() => search()))
      .subscribe();
  };

  searchAddress(address) {
    const searchBar = document.getElementById("searchAddressBar");
    searchBar["value"] = address;

    let requestBody = { address };
    this.cmpDrawShapesGoogleMaps
      .getLatLngFromAddress(requestBody)
      .then((result) => {
        this.progressBarService.stop();
      })
      .catch((error) => {
        this.logger$.warn("onSearchAddress: failed to find address: ", error);
        this.progressBarService.stop();
      });
  }

  getData(): Observable<any> {
    return new Observable((subscriber) => {
      const Shapes = this.cmpDrawShapesGoogleMaps.getShapes();
      if (Shapes) {
        var digsiteDetails = this.createDigsiteDetails(Shapes);
        var centroid = this.parseCentroid(Shapes[0]);

        from(
          this.cmpDrawShapesGoogleMaps.getAddressFromLatLng(Shapes)
        ).subscribe((address: any) => {
          if (address) {
            var addressDetails = this.parseAddressFromGeocode(address);
            subscriber.next({ digsiteDetails, centroid, addressDetails });
          } else {
            subscriber.next({ digsiteDetails, centroid });
          }
          subscriber.complete();
        });
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  onFinishedDrawing = () => {
    this.cmpDrawShapesGoogleMaps.turnDrawingOff();
    this.finishedDrawingDisabled = true;

    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        message: { onFinishedDrawing: true },
        senderID: this.className,
      })
    );
  };

  onResetDrawing = () => {
    this.cmpDrawShapesGoogleMaps.clearDrawing();
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        message: { onResetDrawing: true },
        senderID: this.className,
      })
    );

    this.cmpDrawShapesGoogleMaps.turnDrawingOff();
    this.finishedDrawingDisabled = true;
  };

  private createDigsiteDetails = (Shapes: any): Object => {
    // create dig site details obj
    let digsiteDetails = Shapes.reduce((digsites, gmShape) => {
      this.logger$.log(gmShape);
      let coords = gmShape.points;
      let formattedCoords = coords.reduce((coords, key) => {
        let coord = {
          Latitude: key.lat(),
          Longitude: key.lng(),
        };
        coords.push(coord);
        return coords;
      }, []);

      let digsite = {
        DigsiteTypeID: gmShape.type,
        Coordinates: formattedCoords,
      };

      digsites.push(digsite);
      return digsites;
    }, []);

    return digsiteDetails;
  };

  private parseCentroid = (shape: any): Object => {
    let centroid = {
      Latitude: shape.center.lat,
      Longitude: shape.center.lng,
    };
    return centroid;
  };

  private parseAddressFromGeocode = (address: any): Object => {
    let streetAddress = "";
    let city = "";

    // parse address data
    let addressInfoKeys = ["street_number", "route", "locality"];
    let addressInfoValues = address.reduce((finalObj, curObj) => {
      if (curObj.types.indexOf("locality") > -1) {
        city = curObj.long_name;
      } else if (addressInfoKeys.indexOf(curObj.types[0]) > -1) {
        finalObj.push(curObj.long_name);
      }
      return finalObj;
    }, []);

    // create readable address
    streetAddress = addressInfoValues.join(" ");

    // update input fields
    const newDetails = {
      LocateSubRegionName: city,
      LocateAddress: streetAddress,
    };
    return newDetails;
  };
}
