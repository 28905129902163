import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Logger } from "./logger";

const undefinedFunction = (): any => undefined;
@Injectable({
  providedIn: "root",
})
export class LoggerService implements Logger {
  constructor() {
    if (environment.enableDebug) {
      console.log("Console Logger Service registered");
    }
  }

  get log() {
    if (environment.enableDebug) {
      return console.info.bind(console);
    } else {
      return undefinedFunction;
    }
  }

  get info() {
    if (environment.enableDebug) {
      return console.info.bind(console);
    } else {
      return undefinedFunction;
    }
  }

  get warn() {
    if (environment.enableDebug) {
      return console.warn.bind(console);
    } else {
      return undefinedFunction;
    }
  }

  get error() {
    if (environment.enableDebug) {
      return console.error.bind(console);
    } else {
      return undefinedFunction;
    }
  }
}
