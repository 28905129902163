export const CALL_TYPE = {
  // 1
  EMERGENCY: {
    color: "#ffee58",
    textColor: "#212121",
  },

  // 7
  STANDARD: {
    color: "#212121",
    textColor: "#fff",
  },

  // 8
  PROJECT: {
    color: "#323232",
    textColor: "#fff",
  },

  // 9
  PRIVATE: {
    color: "#323232",
    textColor: "#fff",
  },

  // 10
  PLANNING: {
    color: "#EE82EE",
    textColor: "#fff",
  },

  // 20
  DAMAGE_INVESTIGATION: {
    color: "#ddd",
    textColor: "#fff",
  },

  // 21
  MASTER: {
    color: "#FF8000",
    textColor: "#fff",
  },

  // 25
  TRAINING: {
    color: "#aaa",
    textColor: "#fff",
  },

  // 1002
  AUDIT: {
    color: "#0065B3",
    textColor: "#fff",
  },

  // 1003
  UNAUTH_DIG: {
    color: "#ed2828",
    textColor: "#fff",
  },
  PRIORITY0: {
    color: "#FFA500",
    textColor: "#fff",
  },
  PRIORITY1: {
    color: "#FFA500",
    textColor: "#fff",
  },
  PRIORITY2: {
    color: "#FFA500",
    textColor: "#fff",
  },
  PRIORITY3: {
    color: "#FFA500",
    textColor: "#fff",
  },
  PRIORITY4: {
    color: "#FFA500",
    textColor: "#fff",
  },
};

export const LOCATE_STATUS_STYPE = {
  ASSISTANCE_NEEDED: {
    color: "#8B008B",
    textColor: "#fff",
  },

  LOCATE_COMPLETED: {
    color: "0000ff",
    textColor: "#fff",
  },

  UNLOCATABLE: {
    color: "#888888",
    textColor: "#fff",
  },

  LOCATE_ONGOING: {
    color: "#888888",
    textColor: "#fff",
  },

  LOCATE_CANCELLED: {
    color: "#FF1493",
    textColor: "#fff",
  },

  LOCATE_TENTATIVELY_COMPLETE: {
    color: "#2E8C00",
    textColor: "#AAFF7F",
  },
};
