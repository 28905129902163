import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {UserEditor} from "../user-manager-editor.module";
import {UserManagerService} from "../../user-manager.service";
import {Setting} from "src/app/modules/core/services/user/setting";
import {NgForm} from "@angular/forms";
import {UserCategoryEditorService} from "src/app/modules/user-category-editor/user-category-editor.service";
import {GlobalSetting} from "src/app/modules/user-category-editor/user-category-models";
import {SnackbarService} from "src/app/modules/shared/snackbar/snackbar.service";
import {SnackbarType} from "src/app/modules/shared/snackbar/snackbar/snackbar";


@Component({
  selector: "app-client-user-editor",
  templateUrl: "./client-user-editor.component.html",
  styleUrls: ["./client-user-editor.component.scss"],
})
export class ClientUserEditorComponent implements OnInit, OnChanges {
  @Input() user: UserEditor;
  @Input() supervisors: any[];
  @Input() userCategories: any[];
  @Input() accessibleSettings: any[];
  @Input() clientUserCount: number = 0;
  @Input() canModifyPasswords: any = false;
  @Input() editState: boolean = false;
  @Output() updated = new EventEmitter<UserEditor>();
  @ViewChild("myForm") myForm: NgForm;

  updatedUser: UserEditor;
  routingAlgorithms = [];
  navBackground = "";

  tabs = ["info", "settings"];
  tabIndex = 0;

  hidePassword: boolean = true;
  resettingPassword = false;
  tempPassword = "";
  emailPassword = false;

  globalSettings: GlobalSetting[] = [];
  possibleUserSettings: GlobalSetting[] = [];
  settingsForSettingEditor: {settingID: number, active: number, value: any, settingCategoryID?: number}[] = [];
  settingsFromUserCategory: {settingID: number, active: number, value: any, settingCategoryID: number}[] = [];


  constructor(
    private userManager$: UserManagerService,
    private userCategoryEditorService: UserCategoryEditorService,
    private snackBarService: SnackbarService
  ) { }

  async ngOnInit() {
    const allSettings = await this.userCategoryEditorService.getTables(); // Trigger the initial data fetch
    this.globalSettings = allSettings.GlobalSettings;
    await this.updateSettingsForUserCategory();
    await this.updatePossibleUserSettings();

    this.user = {
      UserID: 0,
      CloudUserID: 0,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      TwoWayNum: "",
      EmployeeID: 0,
      UserCategoryID: 1,
      Supervisor: 0,
      TruckNum: "",
      FirstLog: 0,
      Password: "",
      Archived: 0,
      isCloudSettingsUser: 0,
      HexColour: "",
    };
    await this.getRoutingAlgorithms();
  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.user) {
      this.updatedUser = {...this.user};
      this.hidePassword = true;
      if (
        this.clientUserCount == 1 &&
        this.updatedUser.isCloudSettingsUser == 0
      ) {
        this.updatedUser.isCloudSettingsUser = 1;
        this.updated.emit(this.updatedUser);
      }

      this.updateSettingsForEditor();

      if (this.globalSettings.length == 0) {
        const allSettings = await this.userCategoryEditorService.getTables();
        this.globalSettings = allSettings.GlobalSettings;
      }

      await this.updatePossibleUserSettings();

    }
  }

  /**
   * Updates the possible user settings based off the user category settings 
   */
  async updatePossibleUserSettings() {
    const possiblySettings = this.globalSettings.filter(setting => {
      const settingFromUserCategory = this.settingsFromUserCategory.find(settingFromUserCategory => settingFromUserCategory.settingID === setting.SettingID);
      return !settingFromUserCategory;
    });

    //invoke change detection
    this.possibleUserSettings = [...possiblySettings];
  }

  /**
   * Updates settings for the user category with values from the user 
   */
  async updateSettingsForUserCategory(): Promise<void> {
    if (this.updatedUser.UserCategoryID) {
      const tmpSettings = [];
      const settings = await this.userCategoryEditorService.getUserCategory(this.updatedUser.UserCategoryID);
      settings?.Settings.forEach((setting) => {
        const existingSettingIndex = this.settingsFromUserCategory.findIndex(existingSetting => existingSetting.settingID === setting.SettingID);
        if (existingSettingIndex === -1) {
          tmpSettings.push({
            settingID: setting.SettingID,
            active: setting.DefaultActive ? 1 : 0,
            value: setting.DefaultValue,
            settingCategoryID: this.updatedUser.UserCategoryID,
          });
        } else {
          tmpSettings.push({
            settingID: setting.SettingID,
            active: this.settingsFromUserCategory[existingSettingIndex].active,
            value: this.settingsFromUserCategory[existingSettingIndex].value,
            settingCategoryID: this.updatedUser.UserCategoryID,
          });
        }
      });
      //invoke change detection
      this.settingsFromUserCategory = [];
      this.settingsFromUserCategory = tmpSettings;
    }
  }

  /**
   * Updates settings for this user 
   */
  updateSettingsForEditor(): void {
    if (this.updatedUser.Settings) {
      this.settingsForSettingEditor = [];
      for (let i = 0; i < this.updatedUser.Settings.length; i++) {
        const setting = this.updatedUser.Settings[i];
        const settingToAdd = {
          settingID: setting.SettingID,
          active: setting.Active ? 1 : 0,
          value: setting.Value,
          settingCategoryID: setting.SettingCategoryID,
        };
        if (!setting.SettingCategoryID) {
          this.settingsForSettingEditor.push(settingToAdd);
        } else {
          const existingSettingIndex = this.settingsFromUserCategory.findIndex(existingSetting => existingSetting.settingID === setting.SettingID);
          if (existingSettingIndex === -1) {
            this.settingsFromUserCategory.push(settingToAdd);
          }
        }
      }
    } else {
      this.settingsForSettingEditor = [];
    }
  }



  getGlobalSettings() {
    return this.globalSettings;
  }

  async getRoutingAlgorithms() {
    this.routingAlgorithms = await this.userManager$.getAlgorithms();
  }

  /**
   * Event when user category changes 
   */
  async userCategoryChanged() {
    this.settingsFromUserCategory = [];

    //get the category settings 
    const categoryTemplate = await this.userCategoryEditorService.getUserCategory(this.updatedUser.UserCategoryID);

    //format into function-readable format
    const usersNewCategorySettings = [];
    if (categoryTemplate && categoryTemplate.Settings) {
      categoryTemplate.Settings.forEach((element) => {
        usersNewCategorySettings.push({
          settingID: element.SettingID,
          active: element.DefaultActive,
          value: element.DefaultValue,
          settingCategoryID: this.updatedUser.UserCategoryID
        })
      });
    }
    //update the user's category settings - also emit a change 
    this.updateUserSettings(usersNewCategorySettings, true);

    //update the displays 
    await this.updateSettingsForUserCategory();
    this.updateSettingsForEditor();

    //update the user's possible settings for addition in individual settings 
    await this.updatePossibleUserSettings();
    this.updated.emit(this.updatedUser);
  }

  /**
   * Updates the users colour and calls the change emitter
   *
   * @memberof ClientUserEditorComponent
   */
  onHexColourChange() {
    this.updated.emit(this.updatedUser);
  }

  userInfoChanged() {
    this.updated.emit(this.updatedUser);
  }

  indexChange(index) {
    this.tabIndex = index;
  }

  goNext() {
    if (this.tabIndex + 1 > this.tabs.length) {
      this.tabIndex = 0;
    } else {
      this.tabIndex++;
    }
  }

  goToTabByLabel(label) {
    if (this.tabs.indexOf(label) > -1) {
      this.tabIndex = this.tabs.indexOf(label);
    }
  }

  onResetPasswordClick() {
    this.resettingPassword = true;
    this.tempPassword = this.generateRandomPassword();
    this.updatedUser.Password = this.tempPassword;
    this.updated.emit(this.updatedUser);
  }

  generateRandomPassword() {
    let password = "";
    const upperCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCharacters = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const allCharacters = `${upperCharacters}${lowerCharacters}${numbers}`;

    let upper = upperCharacters.charAt(Math.floor(Math.random() * upperCharacters.length));
    let lower = lowerCharacters.charAt(Math.floor(Math.random() * lowerCharacters.length));
    let number = numbers.charAt(Math.floor(Math.random() * numbers.length));

    const length = allCharacters.length;
    for (let i = 0; i < 5; i++) {
      password = password + allCharacters.charAt(Math.floor(Math.random() * length));
    }
    password = `${password}${upper}${lower}${number}`;

    return password;
  }

  // Updates a user's settings
  updateUserSettings(event, isCategorySetting = false) {
    let tmp_settingsFromUserCategory = this.settingsFromUserCategory;
    let tmp_settingsForSettingEditor = this.settingsForSettingEditor;
    if (isCategorySetting) {
      tmp_settingsFromUserCategory = [...event];
      // tmp_settingsForSettingEditor.forEach((value) => {
      //   value.settingCategoryID = this.updatedUser.UserCategoryID;
      // });
    } else {
      tmp_settingsForSettingEditor = [...event];
    }

    // check settingsFromUserCategory and settingsForSettingEditor for duplicate settingIDs 
    // error if you found a duplicate 
    const duplicateSettingIDs = tmp_settingsFromUserCategory.filter(setting => tmp_settingsForSettingEditor.some(otherSetting => otherSetting.settingID === setting.settingID));
    
    //if a setting exists for the user both in the category and individual setting, remove it from the individual setting 
    //this is to prevent duplicate settings
    if (duplicateSettingIDs.length > 0) {
      this.settingsForSettingEditor = this.settingsForSettingEditor.filter(setting => !duplicateSettingIDs.some(otherSetting => otherSetting.settingID === setting.settingID));
    }
    if (isCategorySetting) {
      this.settingsFromUserCategory = [...event];
    } else {
      //make sure there is no settingCategoryID since these 
      //are individual settings
      event.forEach(element => {
        element.settingCategoryID = null;
      })
      this.settingsForSettingEditor = [...event];
    }
    //combine the two arrays
    const settingsToCombine = [...this.settingsForSettingEditor, ...this.settingsFromUserCategory];

    for (const setting of settingsToCombine) {
      const existingSettingIndex = this.updatedUser.Settings.findIndex(userSetting => userSetting.SettingID === setting.settingID);
      if (existingSettingIndex !== -1) {
        // Update existing setting
        this.updatedUser.Settings[existingSettingIndex].Active = setting.active === 1;
        this.updatedUser.Settings[existingSettingIndex].Value = setting.value ? setting.value.toString() : setting.value;
      } else {
        // Add new setting
        const settingCategoryID = isCategorySetting ? this.updatedUser.UserCategoryID ?? setting.settingCategoryID : setting.settingCategoryID;
        this.updatedUser.Settings.push(new Setting(setting.settingID, setting.active === 1, setting.value, settingCategoryID));
      }
    }

    // Remove settings that are not present in the combined settings
    this.updatedUser.Settings = this.updatedUser.Settings.filter(userSetting =>
      settingsToCombine.some(eventSetting => eventSetting.settingID === userSetting.SettingID)
    );

    this.updated.emit(this.updatedUser);

  }

  onPasswordBlur(event: any) {
    console.log("event", event.target.value);
    this.updatedUser.Password = event.target.value;
    this.updated.emit(this.updatedUser);
  }

}
