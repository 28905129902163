import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-map-body',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [],
    template:
        `
            <div #map class="h-full w-full relative z-0"></div>
        `,
})
export class MapBodyComponent implements AfterViewInit {
    @ViewChild('map', {static: true}) private mapParent: ElementRef;
    @Output() mapReady: EventEmitter<HTMLDivElement> = new EventEmitter<HTMLDivElement>();

    ngAfterViewInit() {
        this.mapReady.emit(this.mapParent.nativeElement);
    }

}
