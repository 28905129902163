import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";

import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { FormBuilder, Validators } from "@angular/forms";
import { CreateTicket } from "../../create-ticket/CreateTicket";
import { LoggerService } from "../../core/services/logger/logger.service";
import { AdminLookupService } from "../../core/admin/admin-lookup.service";
import { CompletionsStoreService } from "../../core/admin/completions-store.service";
import { ApiService } from "../../core/api/baseapi.service";
import { ActionMessage } from "../../core/component-messaging/action-message";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { InputFactory } from "../../create-ticket/input-factory";
import { CreateTicketFormTypes } from "../../create-ticket/CreateTicketModel";

export interface GroupFormField {
  dbName: string;
  placeholder: string;
  isRequired: boolean;
  options: any;
  canEdit: boolean;
  isDropdown: boolean;
}
export interface SingleFormField {
  type: string;
  key: string;
  label: string;
  placeholder?: string;
  value?: string;
  isRequired?: boolean;
  matches?: string;
  isVisible: boolean;
}

export var HiddenFieldTypeID = [5, 6, 7, 8];

export enum FillStageID {
  Default = 1,
  PrefillOptional = 2,
  PrefillRequired = 3,
}

export enum TicketDetailInputType {
  Checkbox = 1,
  String = 2,
  Integer = 3,
  Float = 4,
  CheckboxHS = 5,
  StringHS = 6,
  IntegerHS = 7,
  FloatHS = 8,
  Photo = 9,
  Datepicker = 10,
}

// 1	Checkbox
// 2	String
// 3	Integer
// 4	Float
// 5	Hide/Show Checkbox
// 6	Hide/Show String
// 7	Hide/Show Integerr
// 8	Hide/Show Float
// 9	Photo
// 10	Datepicker

@Injectable({
  providedIn: "root",
})
export class CreateTicketService {
  createFormTicketObj: CreateTicket;
  curUID: string;

  constructor(
    private logger$: LoggerService,
    private lookup$: AdminLookupService,
    private completions$: CompletionsStoreService,
    private formBuilder: FormBuilder,
    private utilocateApiService: ApiService
  ) {
    this.createFormTicketObj = new CreateTicket(); // todo: make into form builder class
    // this.logger$.log("Created CreateTicket service");
  }

  getOnTicketDataChange(): Observable<any> {
    return this.completions$.getOnTicketDataChange();
  }

  getOnDocumentDataChange(): Observable<any> {
    return this.completions$.getOnDocumentDataChange();
  }

  resetBody() {
    this.completions$.resetBody();
  }

  updateTicketData(message: ActionMessage): Observable<any> {
    this.curUID = sessionStorage.getItem(
      localStorageKeys.CURRENT_CREATE_TICKET_KEY
    );
    return this.completions$.updateTicketData(
      new ActionMessage(message.action, {
        rowKey: this.curUID,
        ...message.data,
      })
    );
  }

  setPrevExcavatorCode(code) {
    sessionStorage.setItem("PREV_EXCAVATOR_CODE", code);
  }

  setPrevExcavator(prevCode): Observable<any> {
    return new Observable((subscriber) => {
      this.getExcavatorDetails(prevCode).subscribe((excavatorRow) => {
        subscriber.next(excavatorRow);
        subscriber.complete();
      });
    });
  }

  getUexcavateTicketDetails() {
    const rid = sessionStorage.getItem(localStorageKeys.URL_KEYS.requestid);

    if (rid) {
      const columns = [
        "request.ExcavationDate",
        "request.LocateSubRegionName",
        "request.LocateCrossStreet",
        "request.TypeOfWork",
        "request.LocateAddress",
      ];
      const url = apiKeys.uexcavate.downloadTicket;
      const type = api[url].type;
      let value = { [rid]: { columns, DigDetails: 1 } };

      return new Observable((subscriber) => {
        from(
          this.utilocateApiService.invokeApi(
            type,
            url,
            JSON.stringify({ value })
          )
        ).subscribe((response) => {
          // break {k:v, k:v} into [[k,v], [k,v]]
          let filteredDigDetails = Object.entries(
            response.body["result"][rid]["DigDetails"]
          ).reduce((total, cur) => {
            // if the DigDetails value is 1, then append DigDetails key (field name) into array
            if (cur[1]) {
              total.push(cur[0]);
            }
            return total;
          }, []);
          let newResponse = {
            ...response.body["result"][rid],
            DigDetails: filteredDigDetails,
          };

          subscriber.next(newResponse);
          subscriber.complete();
        });
      });
    } else {
      return of(false);
    }
  }

  getPrevExcavatorCode() {
    let returnCode = sessionStorage.getItem("PREV_EXCAVATOR_CODE");
    if (!returnCode) {
      returnCode = sessionStorage.getItem("excavatorcode");
    }
    return returnCode;
  }

  private getExcavatorDetails(code) {
    return new Observable((subscriber) => {
      const where = { Code: code };

      // promise to observable
      from(
        this.lookup$.getLookupTableRows(["tbAdmin_Excavators"], where)
      ).subscribe((result) => {
        if (result && result[0] && result[0]["rows"] && result[0]["rows"][0]) {
          subscriber.next(result[0]["rows"][0]);
        } else {
          subscriber.next(false);
        }
        subscriber.complete();
      });
    });
  }

  generateFormGroup(inputs: any): any {
    //simple form generation based on inputs received from db
    //right now no validators.
    let formConfig = {};
    try {
      for (var i in inputs) {
        if (inputs[i] && inputs[i]["options"]) {
          if (inputs[i]["isDropdown"]) {
            formConfig[inputs[i]["dbName"]] = [""];
          } else {
            for (var index in inputs[i]["options"]) {
              var formControlName =
                inputs[i]["dbName"] + "." + inputs[i]["options"][index]["key"];
              if (
                inputs[i]["options"][index]["type"] ==
                  TicketDetailInputType.Integer ||
                inputs[i]["options"][index]["type"] ==
                  TicketDetailInputType.IntegerHS
              ) {
                formConfig[formControlName] = [
                  "",
                  Validators.pattern(/^-?[0-9]\d*$/),
                ];
              } else {
                formConfig[formControlName] = [""];
              }
            }
          }
        }
      }
      return this.formBuilder.group(formConfig);
    } catch (error) {
      this.logger$.error(
        "Create-ticket.service",
        "generateFormGroup",
        "generateFormGroup"
      );
      formConfig = {};
      return this.formBuilder.group(formConfig);
    }
  }

  // CreateTicket functions
  generateFormConfig(formType: number) {
    return this.createFormTicketObj.generateFormConfig(formType);
  }

  generateFormInputs(formType: number): Observable<any> {
    return new Observable((subscriber) => {
      let inputs = this.createFormTicketObj.generateInputs(formType);

      let dontAddDropdowns = false;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i]["type"].indexOf("dropdown") > -1) {
          dontAddDropdowns = true;
          break;
        }
      }
      if (!dontAddDropdowns) {
        inputs.push(...this.createFormTicketObj.generateDropdowns(formType));
      }

      // if (formType == forms.ticket && !dontAddDropdowns) {
      //   this.getPrimaryDetails().subscribe(nextVal => {
      //     if (nextVal) {
      //       inputs.push(nextVal);
      //     }
      //     subscriber.next(inputs);
      //     subscriber.complete();
      //   });
      // } else
      if (formType == CreateTicketFormTypes.primaryDetailsPrefill) {
        this.getPrimaryDetailPrefill().subscribe(
          (nextVal) => {
            if (nextVal) {
              subscriber.next(nextVal);
              subscriber.complete();
            } else {
              subscriber.next(inputs);
              subscriber.complete();
            }
          },
          (error) => {
            subscriber.next(false);
            subscriber.complete();
          }
        );
      } else {
        subscriber.next(inputs);
        subscriber.complete();
      }
    });
  }

  getPrimaryDetails() {
    return new Observable((subscriber) => {
      const where = { PrimaryDetailCategoryID: 19 };

      this.lookup$
        .getLookupTableRows(
          [
            "tbAdmin_PrimaryDetailFields",
            "tbAdmin_PrimaryDetailsCategories",
            "tbAdmin_Excavators",
          ],
          where
        )
        .then((result) => {
          if (result && result.length > 0) {
            let primaryDetailsIds = result[0];
            let primaryDetailsCats = result[1];

            let placeholder = primaryDetailsCats["rows"][0]["Title"];
            let dbName = "PrimaryDetails";
            let isRequired = true;
            let options = primaryDetailsIds["rows"].reduce((total, key) => {
              let obj = {
                text: key["DisplayText"],
                value: key["PrimaryDetailsFieldID"],
              };
              total.push(obj);
              return total;
            }, []);

            let obj = new InputFactory().createDropdown(
              dbName,
              placeholder,
              isRequired,
              options
            );
            subscriber.next(obj);
            subscriber.complete();
          } else {
            subscriber.next(false);
            subscriber.complete();
          }
        })
        .catch((error) => {
          this.logger$.warn(
            "CreateTicket$: getPrimaryDetails: function failure: ",
            error
          );
          subscriber.next(false);
          subscriber.complete();
        });
    });
  }

  getPrimaryDetailPrefill() {
    return new Observable((subscriber) => {
      try {
        // get category data before creating prefill obj
        this._getPrimaryDetailCategories().subscribe(
          (nextVal: any) => {
            if (nextVal) {
              let { primaryDetailObj, primaryDetailsCatIDArr } = nextVal;

              this.lookup$
                .getLookupTableRows(["tbAdmin_PrimaryDetailFields"], {
                  PrimaryDetailCategoryID: primaryDetailsCatIDArr,
                })
                .then((res) => {
                  if (res && res.length > 0) {
                    var primaryDetailFields = res[0];

                    primaryDetailFields["rows"].forEach((row) => {
                      let isVisible = true;
                      let isRequired = false;
                      if (
                        row["FieldTypeID"] != TicketDetailInputType.Checkbox
                      ) {
                        primaryDetailObj[row["PrimaryDetailCategoryID"]][
                          "isDropdown"
                        ] = false;
                      }

                      if (HiddenFieldTypeID.includes(row["FieldTypeID"])) {
                        primaryDetailObj[row["PrimaryDetailCategoryID"]][
                          "canEdit"
                        ] = true;
                        isVisible = false;
                      }

                      if (
                        primaryDetailObj[row["PrimaryDetailCategoryID"]][
                          "isRequired"
                        ]
                      ) {
                        isRequired = true;
                      }

                      let fieldObj: SingleFormField = {
                        type: row["FieldTypeID"],
                        key: row["PrimaryDetailsFieldID"],
                        label: row["DisplayText"],
                        isRequired: isRequired,
                        isVisible: isVisible,
                      };
                      primaryDetailObj[row["PrimaryDetailCategoryID"]][
                        "options"
                      ].push(fieldObj);
                    });

                    var returnArr = [];
                    for (var obj in primaryDetailObj) {
                      returnArr.push(primaryDetailObj[obj]);
                    }
                    subscriber.next(returnArr);
                    subscriber.complete();
                  } else {
                    this.logger$.error(
                      "CreateTicketService",
                      "getPrimaryDetailPrefill",
                      "Failed to read from res"
                    );
                    subscriber.next(false);
                    subscriber.complete();
                  }
                })
                .catch((error) => {
                  this.logger$.error(
                    "CreateTicketService",
                    "getPrimaryDetailPrefill",
                    error
                  );
                  subscriber.next(false);
                  subscriber.complete();
                });
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          },
          (error) => {
            this.logger$.error(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
      } catch (error) {
        this.logger$.error(
          "CreateTicketService",
          "getPrimaryDetailPrefill",
          error
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  _getPrimaryDetailCategories(utilities = [0, 1]) {
    return new Observable((subscriber) => {
      const where = {
        UtilityType: utilities,
        FillStageID: [FillStageID.PrefillOptional, FillStageID.PrefillRequired],
      };

      try {
        this.lookup$
          .getLookupTableRows(["tbAdmin_PrimaryDetailsCategories"], where)
          .then((result) => {
            if (result && result.length > 0) {
              var primaryDetailsCats = result[0];
              var primaryDetailsCatIDArr = [];
              var primaryDetailObj = {};
              if (primaryDetailsCats["rows"].length > 0) {
                primaryDetailsCats["rows"].forEach((row) => {
                  let isRequired = false;
                  if (row["FillStageID"] == FillStageID.PrefillRequired) {
                    isRequired = true;
                  }
                  primaryDetailsCatIDArr.push(row["PrimaryDetailCategoryID"]);
                  let obj: GroupFormField = {
                    dbName: row["PrimaryDetailCategoryID"],
                    placeholder: row["Title"],
                    isRequired: isRequired,
                    canEdit: false,
                    isDropdown: true,
                    options: [],
                  };
                  primaryDetailObj[row["PrimaryDetailCategoryID"]] = obj;
                  subscriber.next({ primaryDetailObj, primaryDetailsCatIDArr });
                  subscriber.complete();
                });
              } else {
                subscriber.next(false);
                subscriber.complete();
              }
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          })
          .catch((error) => {
            this.logger$.error(
              "CreateTicketService",
              "_getPrimaryDetailCategories",
              error
            );
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error(
          "CreateTicketService",
          "_getPrimaryDetailCategories",
          error
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }
}
