// required modules
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";

// required custom services

// required custom modules
import {
  switchMap,
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { of, fromEvent, forkJoin } from "rxjs";

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketService } from "../../services/create-ticket.service";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { CreateTicketFormTypes } from "src/app/modules/create-ticket/CreateTicketModel";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { getDatabaseFormatedDate } from "src/app/modules/core/validators/validator";

// side panel to step through the Create Ticket views
@Component({
  selector: "app-create-side-panel",
  templateUrl: "./create-side-panel.component.html",
  styleUrls: ["./create-side-panel.component.scss"],
  providers: [ComponentMessagingService],
})
export class CreateSidePanelComponent
  extends BasePanelComponent
  implements OnInit
{
  readonly className: string = "CreateSidePanelComponent";

  @Input() props: any;
  @ViewChild("createTicketStepper", {}) createTicketStepper;
  @ViewChild("excavatorDetailView", {}) excavatorDetailView;
  @ViewChild("excavatorDetailReviewView", {}) excavatorDetailReviewView;
  @ViewChild("ticketDetailView", {}) ticketDetailView;
  @ViewChild("ticketDetailReviewView", {}) ticketDetailReviewView;
  @ViewChild("primaryDetailsView", {}) primaryDetailsView;
  @ViewChild("primaryDetailsReviewView", {}) primaryDetailsReviewView;

  excavatorDetailProp: {} = {};
  excavatorDetailReviewProp: {} = {};
  ticketDetailProp: {} = {};
  ticketDetailReviewProp: {} = {};
  primaryDetailsProp: {} = {};
  primaryDetailsReviewProp: {} = {};

  btnEditDigsiteDisabled: boolean;
  userRequiresPolygon: boolean;
  userAddedPolygon: boolean;

  isLinear: boolean = true; // prevent user from jumping ahead
  isLoadingAsync: boolean;

  // create ticket model
  excavatorInputs: any = [];
  ticketInputs: any = [];

  // special case for utility map
  showUtilityMapCheckbox: boolean = false;
  utilityMapResult: string;
  numOfSavedFiles: number;

  primaryDetailCatArr: any;

  constructor(
    logger$: LoggerService,
    private messaging$: ComponentMessagingService,
    private createTicket$: CreateTicketService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);

    // create props for child components
    this.excavatorDetailProp = {
      messageService: messaging$,
      name: "excavatorDetailsUID",
    };
    this.excavatorDetailReviewProp = {
      ...this.excavatorDetailProp,
      name: "excavatorDetailsReviewUID",
    };

    this.ticketDetailProp = {
      messageService: messaging$,
      name: "ticketDetailsUID",
    };
    this.ticketDetailReviewProp = {
      ...this.ticketDetailProp,
      name: "ticketDetailsReviewUID",
    };

    this.primaryDetailsProp = {
      messageService: messaging$,
      name: "primaryDetailsProp",
    };

    this.primaryDetailsReviewProp = {
      ...this.primaryDetailsProp,
      name: "primaryDetailsReview",
      isReadOnly: true,
    };
    // listen to children messages
    this.messaging$
      .getMessageStream()
      .subscribe((nextMsg) => this.logger$.log("from side panel: ", nextMsg));
  }

  // set up component-messaging and input forms
  ngOnInit() {
    try {
      this.listenForTicketDataChanges();
      this.listenForDocumentDataChanges();
      this.listenForComponentMsgs();
      this.initForms();

      // listen to page and panel-siblings
      this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          // log msg
        });
    } catch (error) {
      this.error(error);
    }
  }

  listenForTicketDataChanges() {
    this.createTicket$
      .getOnTicketDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_DIGSITE_DETAILS:
            this.toggleCustomErrorStates("digsiteHeaderText", false);
            this.userAddedPolygon = true;
            break;
          case Actions.UPDATE_TICKET_ADDRESS_DATA:
            this.fillTicketMapDetails(message.data);
            break;
          case Actions.CLEAR_POLYGON_DATA:
            this.userAddedPolygon = false;
            this.fillTicketMapDetails(message.data);
            break;
          case Actions.RESET_DATA:
            this.initForms();
            this.onClearDrawing();
            this.createTicketStepper.reset();
            break;
        }
      });
  }

  listenForDocumentDataChanges() {
    this.createTicket$
      .getOnDocumentDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_DOCUMENT_DATA:
            this.toggleCustomErrorStates("uploadDocHeaderText", false);
            this.numOfSavedFiles = message.data["DocumentsLen"];
            break;
        }
      });
  }

  listenForComponentMsgs() {
    this.props["messageService"]
      .getMessageStream()
      .subscribe((nextMsg) => this.logger$.log("from side panel: ", nextMsg));
  }

  initForms() {
    try {
      this.btnEditDigsiteDisabled = false;
      this.userRequiresPolygon = true;
      this.userAddedPolygon = false;
      this.isLoadingAsync = true; // disable buttons until content is loaded
      this.createTicket$.resetBody();

      const prevExcavatorCode = this.createTicket$.getPrevExcavatorCode();

      // if no requestID is found, then user did not create private locate
      if (sessionStorage.getItem(localStorageKeys.URL_KEYS.requestid)) {
        this.userRequiresPolygon = false;
      }

      if (
        !this.excavatorDetailProp["infoFormGroup"] ||
        !this.ticketDetailProp["infoFormGroup"]
      ) {
        const excavatorFormConfig = this.createTicket$.generateFormConfig(
          CreateTicketFormTypes.excavator
        );
        const ticketFormConfig = this.createTicket$.generateFormConfig(
          CreateTicketFormTypes.ticket
        );

        this.excavatorDetailProp["infoFormGroup"] =
          this.formBuilder.group(excavatorFormConfig);
        this.excavatorDetailReviewProp["infoFormGroup"] =
          this.formBuilder.group(excavatorFormConfig);
        this.ticketDetailProp["infoFormGroup"] =
          this.formBuilder.group(ticketFormConfig);
        this.ticketDetailReviewProp["infoFormGroup"] =
          this.formBuilder.group(ticketFormConfig);
      }

      this.excavatorDetailProp["infoFormGroup"].disable();
      this.ticketDetailProp["infoFormGroup"].disable();

      // init forms and validators
      this.progressBarService.start();
      forkJoin([
        this.createTicket$.generateFormInputs(CreateTicketFormTypes.excavator),
        this.createTicket$.generateFormInputs(CreateTicketFormTypes.ticket),
        this.createTicket$.generateFormInputs(
          CreateTicketFormTypes.primaryDetailsPrefill
        ),
      ])
        .pipe(
          switchMap((forkJoinResult) => {
            if (forkJoinResult) {
              // init excavator form config
              this.excavatorDetailProp["inputs"] = forkJoinResult[0];
              this.excavatorDetailReviewProp["inputs"] = forkJoinResult[0];

              // init ticket form config
              this.ticketDetailProp["inputs"] = forkJoinResult[1];
              this.ticketDetailReviewProp["inputs"] = forkJoinResult[1];

              // condition for hiding and showing utility maps
              this.showUtilityMapCheckbox = true;
              this.utilityMapResult = "";

              this.excavatorDetailProp = { ...this.excavatorDetailProp };
              this.ticketDetailProp = { ...this.ticketDetailProp };

              this.primaryDetailsProp["inputs"] = forkJoinResult[2];
              this.primaryDetailsProp["infoFormGroup"] =
                this.createTicket$.generateFormGroup(forkJoinResult[2]);
              this.primaryDetailsReviewProp["inputs"] = forkJoinResult[2];
              this.primaryDetailsReviewProp["infoFormGroup"] =
                this.createTicket$.generateFormGroup(forkJoinResult[2]);

              this.primaryDetailsProp = { ...this.primaryDetailsProp };
              // this.primaryDetailsProp = { ...}
              // auto fill excavator + ticket forms
              return forkJoin([
                this.createTicket$.setPrevExcavator(prevExcavatorCode),
                this.createTicket$.getUexcavateTicketDetails(),
              ]);
            } else {
              return of(false);
            }
          })
        )
        .subscribe((autofillResult) => {
          this.searchExcavatorCode(); // active listener for maps search bar
          if (autofillResult) {
            this.excavatorDetailProp["infoFormGroup"].enable();
            this.ticketDetailProp["infoFormGroup"].enable();

            this.fillExcavatorForm(autofillResult[0]);
            this.fillTicketForm(autofillResult[1]);
            this.isLoadingAsync = false; // enable buttons after content is loaded

            this.progressBarService.stop();
          } else {
            this.fillExcavatorForm({ Code: prevExcavatorCode });
            this.snackBarService.openSnackbar(
              "Failed to auto fill form, Please fill manually",
              SnackbarType.error
            );
          }
        });
    } catch (error) {
      this.error(error);
    }
  }

  onEditDigsite() {
    this.btnEditDigsiteDisabled = true;
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        message: { onEditDigsite: true },
        senderID: this.className,
      })
    );
  }
  onClearDrawing() {
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        action: MessageAction.UPDATE,
        message: { onClearDrawing: true },
        senderID: this.className,
      })
    );
  }
  onLeaveReviewMode() {
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        action: MessageAction.UPDATE,
        message: { onReviewToggle: true },
        senderID: this.className,
      })
    );
  }

  // onclick handler for Search Code button
  searchExcavatorCode() {
    const excavatorCodeField = document.getElementById("Code");
    const typeahead = fromEvent(excavatorCodeField, "input").pipe(
      map((e: KeyboardEvent) => e.target["value"]),
      filter((text) => text.length > 2),
      tap(() => this.progressBarService.start()),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((userCode) => this.createTicket$.setPrevExcavator(userCode))
    );

    typeahead.subscribe((data) => {
      this.progressBarService.stop();
      if (data) {
        this.fillExcavatorForm(data);
      }
    });
  }

  fillExcavatorForm(excavatorRow) {
    const {
      Code,
      ExcavatorName,
      ContactName,
      Email,
      PhoneNumber,
      ExcavatorAddress,
      ExcavatorPostalCode,
      ExcavatorCity,
    } = excavatorRow;
    this.excavatorDetailProp["infoFormGroup"].patchValue({
      Code,
      ExcavatorName,
      ContactName,
      Email,
      PhoneNumber,
      ExcavatorAddress,
      ExcavatorPostalCode,
      ExcavatorCity,
    });
    this.excavatorDetailProp = { ...this.excavatorDetailProp };
  }

  // onclick handler for Excavator form Next button
  gatherExcavatorDetails() {
    try {
      if (this.excavatorDetailProp["infoFormGroup"].valid) {
        this.progressBarService.start();

        // update ticket form with some excavator data
        let excavatorDetails = this.excavatorDetailView.getMyMessage();
        // this.ticketDetailProp["infoFormGroup"].patchValue({ WorkDoneFor: excavatorDetails["ExcavatorName"] });
        this.ticketDetailProp = { ...this.ticketDetailProp };

        // update ticket body in IDB
        forkJoin([
          this.createTicket$.updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_DATA, {
              formType: CreateTicketFormTypes.excavator,
              value: excavatorDetails,
            })
          ),
          // this.createTicket$.updateTicketData(new ActionMessage(Actions.UPDATE_TICKET_DATA, { formType: forms.ticket, value: { WorkDoneFor: excavatorDetails["ExcavatorName"] } }))
        ]).subscribe((successfulSave: boolean[]) => {
          if (successfulSave) {
            this.progressBarService.stop();
            this.createTicketStepper.selectedIndex += 1;
          } else {
            this.snackBarService.openSnackbar(
              "Failed to Save Changes, Review and try again",
              SnackbarType.error
            );
          }
        });
      } else {
        this.snackBarService.openSnackbar(
          "Fill all required fields before continuing",
          SnackbarType.error
        );
        this.excavatorDetailProp["infoFormGroup"].markAllAsTouched();
      }
    } catch (error) {
      this.logger$.warn(
        this.className + ": gatherExcavatorDetails: function failure: ",
        error
      );
    }
  }

  fillTicketForm(ticketDetails) {
    try {
      this.progressBarService.start();

      // auto search address if available
      if (
        ticketDetails["LocateAddress"] &&
        ticketDetails["LocateSubRegionName"]
      ) {
        let address =
          ticketDetails["LocateAddress"] +
          ", " +
          ticketDetails["LocateSubRegionName"];
        this.props["messageService"].sendToMessageStream(
          new ComponentMessage({
            message: {
              dbSearchAddress: true,
              address,
            },
            senderID: this.className,
          })
        );
      }
      // this.ticketDetailProp["infoFormGroup"].patchValue({ ...ticketDetails, PrimaryDetails: [88] });
      this.ticketDetailProp["infoFormGroup"].patchValue({ ...ticketDetails });
      this.ticketDetailProp = { ...this.ticketDetailProp };

      this.progressBarService.stop();
    } catch (error) {
      this.logger$.warn(
        this.className + ": gatherExcavatorDetails: function failure: ",
        error
      );
    }
  }

  // onclick handler for Ticket form Next button
  gatherTicketDetails() {
    try {
      if (this.ticketDetailProp["infoFormGroup"].valid) {
        if (this.primaryDetailsProp["infoFormGroup"].valid) {
          let ticketDetails = this.ticketDetailView.getMyMessage();

          // isolate and format data before saving

          // let primaryDetails = [...ticketDetails["PrimaryDetails"]].reduce((total, curVal) => {
          //   if(!total[curVal]) {
          //     total[curVal] = 1;
          //   };
          //   return total;
          // }, {});
          // delete ticketDetails["PrimaryDetails"];

          let primaryDetailsPrefill = this.primaryDetailsView.getMyMessage();
          // format dig details
          let digDetails = {};
          if (ticketDetails["DigDetails"]) {
            digDetails = ticketDetails["DigDetails"].reduce(
              (total, element) => {
                total[element] = 1;
                return total;
              },
              {}
            );
            delete ticketDetails["DigDetails"];
          }

          if (
            ticketDetails["ExcavationDate"] &&
            ticketDetails["ExcavationDate"] instanceof Date
          ) {
            ticketDetails["ExcavationDate"] = getDatabaseFormatedDate(
              ticketDetails["ExcavationDate"],
              true
            );
          }

          if (
            ticketDetails["CallDate"] &&
            ticketDetails["CallDate"] instanceof Date
          ) {
            ticketDetails["CallDate"] = getDatabaseFormatedDate(
              ticketDetails["CallDate"],
              true
            );
          }

          if (ticketDetails.LocateSubRegionName) {
            ticketDetails = {
              ...ticketDetails,
              SubRegionDesc: ticketDetails.LocateSubRegionName,
              RegionDesc: ticketDetails.LocateSubRegionName,
            };
          }

          ticketDetails = {
            ...ticketDetails,
            ...digDetails,
            OriginalExcavationDate: ticketDetails.ExcavationDate,
          };

          forkJoin([
            // this.createTicket$.updateTicketData(new ActionMessage(Actions.UPDATE_PRIMARY_DETAILS, { formType: forms.primaryDetails, value: primaryDetails })),
            this.createTicket$.updateTicketData(
              new ActionMessage(Actions.UPDATE_PRIMARY_DETAILS_PREFILL, {
                formType: CreateTicketFormTypes.primaryDetailsPrefill,
                value: primaryDetailsPrefill,
              })
            ),
            this.createTicket$.updateTicketData(
              new ActionMessage(Actions.UPDATE_TICKET_DATA, {
                formType: CreateTicketFormTypes.ticket,
                value: ticketDetails,
              })
            ),
          ]).subscribe((successfulSaves: boolean[]) => {
            if (
              !this.userRequiresPolygon ||
              (this.userRequiresPolygon && this.userAddedPolygon)
            ) {
              this.toggleCustomErrorStates("digsiteHeaderText", false);

              if (this.utilityMapResult) {
                this.toggleCustomErrorStates("utilityMapHeaderText", false);
                if (parseInt(this.utilityMapResult, 10)) {
                  this.toggleCustomErrorStates("utilityMapHeaderText", false);

                  // check if user has upload atleast 1 file if they answered true to utility maps question
                  if (this.numOfSavedFiles > 0) {
                    this.toggleCustomErrorStates("uploadDocHeaderText", false);
                    this.createReviewStep();
                    this.createTicketStepper.selectedIndex += 1;
                  } else {
                    this.toggleCustomErrorStates("uploadDocHeaderText", true);
                    this.snackBarService.openSnackbar(
                      "No utility map uploaded",
                      SnackbarType.default
                    );
                  }
                } else {
                  this.createReviewStep();
                  this.createTicketStepper.selectedIndex += 1;
                }
              } else {
                this.toggleCustomErrorStates("utilityMapHeaderText", true);
                this.snackBarService.openSnackbar(
                  "Must answer utility maps question",
                  SnackbarType.error
                );
              }
            } else {
              this.toggleCustomErrorStates("digsiteHeaderText", true);
              this.snackBarService.openSnackbar(
                "Must draw polygon before continuing",
                SnackbarType.error
              );
            }
          });
        } else {
          this.snackBarService.openSnackbar(
            "Invalid input in Completion Detail field",
            SnackbarType.error
          );
          this.primaryDetailsProp["infoFormGroup"].markAllAsTouched();
        }
      } else {
        this.snackBarService.openSnackbar(
          "Must complete all sections before continuing",
          SnackbarType.error
        );
        this.ticketDetailProp["infoFormGroup"].markAllAsTouched();
      }
    } catch (error) {
      this.logger$.warn(
        this.className + ": gatherTicketDetails: function failure: ",
        error
      );
    }
  }

  createReviewStep() {
    // fill forms in review mode
    this.excavatorDetailReviewProp["infoFormGroup"].disable();
    this.ticketDetailReviewProp["infoFormGroup"].disable();
    this.primaryDetailsReviewProp["infoFormGroup"].disable();

    const excavatorFormData = this.excavatorDetailView.getMyMessage();
    const ticketFormData = this.ticketDetailView.getMyMessage();
    const primaryFormData = this.primaryDetailsView.getRawValue();

    this.excavatorDetailReviewProp["infoFormGroup"].patchValue(
      excavatorFormData
    );
    this.ticketDetailReviewProp["infoFormGroup"].patchValue(ticketFormData);
    this.primaryDetailsReviewProp["infoFormGroup"].patchValue(primaryFormData);

    this.excavatorDetailReviewProp = { ...this.excavatorDetailReviewProp };
    this.ticketDetailReviewProp = { ...this.ticketDetailReviewProp };
    this.primaryDetailsReviewProp = { ...this.primaryDetailsReviewProp };

    // apply view only flags
    this.messaging$.sendToMessageStream(
      new ComponentMessage({
        message: { onReviewToggle: true },
        senderID: "createSidePanel",
      })
    );
  }

  fillTicketMapDetails(address) {
    if (this.userRequiresPolygon && address) {
      this.ticketDetailProp["infoFormGroup"].patchValue(address);
      this.ticketDetailProp = { ...this.ticketDetailProp };
    } else {
      this.snackBarService.openSnackbar(
        "Failed to Fill Address Details, Add Address Manually",
        SnackbarType.error
      );
    }
  }

  toggleCustomErrorStates(elementID, isError) {
    const element = document.getElementById(elementID);
    if (isError) {
      if (!element.classList.contains("accent")) {
        element.classList.add("accent");
      }
    } else {
      if (element.classList.contains("accent")) {
        element.classList.remove("accent");
      }
    }
    // element.setAttribute("color", "primary");
    // element.classList.add("primary");
  }

  submitForm() {
    if (!parseInt(this.utilityMapResult, 10)) {
      // must answer utility maps disclaimer
      let accepted = false;
      const dialogRef = this.dialog.open(UtilityMapDisclaimerDialogComponent, {
        width: "560px",
        height: "182px",
      });

      // subscribe to modal value
      dialogRef.afterClosed().subscribe((result) => {
        accepted = result;

        if (accepted) {
          this.sync();
        }
      });
    } else {
      this.sync();
    }
  }

  sync() {
    this.isLoadingAsync = true; // disable buttons until important async action is done
    this.progressBarService.start();
    this.props["onSubmit"].subscribe((successfulUpload: boolean) => {
      if (successfulUpload) {
        this.snackBarService.openSnackbar(
          "Successfully Uploaded",
          SnackbarType.success
        );
      } else {
        this.snackBarService.openSnackbar(
          "Failed to Upload - Contact Support Line",
          SnackbarType.error
        );
      }

      const dialogRef = this.dialog.open(CreateAnotherTicketDialogComponent);

      // subscribe to modal value
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          window.close();
        }
      });

      this.isLoadingAsync = false; // enable buttons after sync complete
      this.onLeaveReviewMode();
      this.progressBarService.stop();
    });
  }

  error(ex: Error) {
    this.logger$.error(ex);
  }
}

@Component({
  selector: "app-utility-map-disclaimer-dialog",
  template: `
    <div>
      <h1 mat-dialog-title>Utility Map Disclaimer</h1>
      <div mat-dialog-content class="disclaimer-content">
        <span
          >By clicking Accept, I acknowledge that I have not attached a utility
          map to this request and will accept any and all concequences.</span
        >
      </div>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button
          color="primary"
          mat-raised-button
          mat-primary
          [mat-dialog-close]="accept"
          cdkFocusInitial
        >
          Accept
        </button>
      </div>
    </div>
  `,
})
export class UtilityMapDisclaimerDialogComponent {
  accept: boolean;
  constructor(
    public dialogRef: MatDialogRef<UtilityMapDisclaimerDialogComponent>
  ) {
    this.accept = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "app-create-another-dialog",
  template: `
    <div>
      <div mat-dialog-content class="disclaimer-content">
        <span>Would you like to create another ticket?</span>
      </div>
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">No, and close tab</button>
        <button
          color="primary"
          mat-raised-button
          mat-primary
          [mat-dialog-close]="accept"
          cdkFocusInitial
        >
          Yes
        </button>
      </div>
    </div>
  `,
})
export class CreateAnotherTicketDialogComponent {
  accept: boolean;
  constructor(
    public dialogRef: MatDialogRef<CreateAnotherTicketDialogComponent>
  ) {
    this.accept = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
