import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { loadModules } from "esri-loader";

@Component({
  selector: "app-esri-map-basemap",
  templateUrl: "./esri-map-basemaps.component.html",
  styleUrls: ["./esri-map-basemaps.component.css"],
})
export class EsriMapBasemapsComponent implements OnChanges {
  @Input() mapView: any;
  @ViewChild("basemapPanel") basemapPanel;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapView && !changes.mapView.firstChange) {
      this.createBasemapGallery(this.mapView);
    }
  }

  async createBasemapGallery(mapView) {
    const [BasemapGallery] = await loadModules(["esri/widgets/BasemapGallery"]);

    var gallery = new BasemapGallery({
      view: mapView,
      container: this.basemapPanel.nativeElement,
    });

    // to unset
    // gallery.activeBasemap = null;
  }
}
