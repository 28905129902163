<app-ticket-map
  [canReassignTickets]="true"
  [ticketStream$]="searchService.searchResults$"
  [dispatchAreasEnabled]="true"
  [showRouting$]="_showRouting$"
  [showLocatorCheckins]="true"
  [showMultiselect]="true"
  [selectedTickets]="homeWorkspaceService.ticketSelection$ | async"
  (selectedTicketsChanged)="homeWorkspaceService.ticketSelection = $event"
  (openTicket)="homeWorkspaceService.openTicket($event)"
  (reassignTicket)="homeWorkspaceService.reassignTicket($event)" />
