import { Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { isEqual } from 'lodash-es';
import { MatIcon } from '@angular/material/icon';

export interface InputData<T> {
  value: T;
  altText: string;
  imgSource: string;
}

@Component({
  selector: 'app-toolbar-radio-group',
  standalone: true,
  imports: [MatIcon],
  template: `
    <div aria-labelledby="group_heading" class="grid grid-cols-2 gap-1.5 w-fit" role="radiogroup">
      @for (item of items$$(); track $index) {
        <button
          (click)="onClick(item)"
          [disabled]="disabled$$()"
          class="cursor-pointer size-fit p-1.5 flex justify-center items-center {{
            isSelected(item) ? 'bg-primary-hover' : 'bg-transparent  hover:bg-primary-hover'
          }} rounded-md text-white border-0 transition-all ease-in-out">
          @if (item.imgSource !== '') {
            <mat-icon class="flex justify-center items-center size-[{{ iconSize$$() }}] text-[{{ iconSize$$() }}]">
              <img class="size-[{{ iconSize$$() }}] invert" alt="{{ item.altText }}" src="{{ item.imgSource }}" />
            </mat-icon>
          } @else {
            <span class="whitespace-normal leading-4 tracking-wide text-sm font-rajdhani font-semibold uppercase">
              {{ item.altText }}
            </span>
          }
        </button>
      }
    </div>
  `,
  styleUrls: ['./toolbar-radio-group.component.scss'],
})
export class ToolbarRadioGroupComponent<T> {
  @Input() set items(value: InputData<T>[]) { this._items$$.set(value); }
  @Input() set value(value: T) { this._value$$.set(value); }
  @Input() set iconSize(value: string) { this._iconSize$$.set(value); }
  @Input() set disabled(value: boolean) { this._disabled$$.set(value); }
  @Output() valueChange = new EventEmitter<T>();

  private _items$$ = signal<InputData<T>[]>([]);
  private _value$$ = signal<T | null>(null);
  private _iconSize$$ = signal('24px');
  private _disabled$$ = signal(false);

  items$$ = computed(() => this._items$$());
  value$$ = computed(() => this._value$$());
  iconSize$$ = computed(() => this._iconSize$$());
  disabled$$ = computed(() => this._disabled$$());

  private selectionModel$$ = computed(() => {
    const model = new SelectionModel<InputData<T>>(false, [], true, isEqual);
    const currentValue = this.value$$();
    if (currentValue !== null) {
      const selectedItem = this.items$$().find(item => isEqual(item.value, currentValue));
      if (selectedItem) {
        model.select(selectedItem);
      }
    }
    return model;
  });

  onClick(item: InputData<T>): void {
    if (!this.disabled$$()) {
      this.selectionModel$$().select(item);
      this.valueChange.emit(item.value);
    }
  }

  isSelected(item: InputData<T>): boolean {
    return this.selectionModel$$().isSelected(item);
  }
}
