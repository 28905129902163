import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DatetimeService {
  constructor() {}

  localDateToFormattedString(date: Date, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    if (date) {
      return DateTime.fromISO(date.toISOString()).toLocal().toFormat(format);
    } else {
      throw null;
    }
  }

  localDateToDBDateStr(date: unknown): string {
    const checkedDate: Date | null =
      date instanceof Date ? date : typeof date === 'string' && this.isStringVaildDate(date) ? new Date(date) : null;

    if (checkedDate instanceof Date) {
      return DateTime.fromISO(checkedDate.toISOString(), {
        zone: 'America/New_York',
      }).toFormat('yyyy-MM-dd HH:mm:ss');
    } else {
      throw null;
    }
  }

  isStringVaildDate(str: string): boolean {
    const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    const dateTimeRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
    const dbDateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.000Z$/;

    let isDate = false;

    if (dateRegex.test(str) || dateTimeRegex.test(str) || dbDateRegex.test(str)) {
      isDate = true;
    }
    return isDate;
  }

  dbDateToFormattedLocalDate(dbDate: string, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      dbDate = dbDate + ' ' + 'America/New_York';
      const date = DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });

      return date.toLocal().toFormat(format);
    } else {
      throw null;
    }
  }

  dbDateToLocalDate(dbDate: string): Date {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      dbDate = dbDate + ' ' + 'America/New_York';
      const date = DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });

      return new Date(date.toString());
    } else {
      throw null;
    }
  }

  dateTimeFromDbDate(dbDate: string): DateTime {
    if (dbDate) {
      if (dbDate.includes('T')) {
        const dateParts = dbDate.split('T');
        dbDate = dateParts[0] + ' ' + dateParts[1].split('.')[0];
      }
      dbDate = dbDate + ' ' + 'America/New_York';
      return DateTime.fromFormat(dbDate, 'yyyy-MM-dd HH:mm:ss z', {
        setZone: true,
      });
    } else {
      throw null;
    }
  }

  getDateWindow(unit: 'years' | 'months' | 'days', quantity: number): { lower: Date; upper: Date } {
    const lower = DateTime.local()
      .minus({ [unit]: quantity })
      .toJSDate();
    const upper = DateTime.local()
      .plus({ [unit]: quantity })
      .toJSDate();
    return {
      lower,
      upper,
    };
  }

  getStartOfWeek() {
    return DateTime.local().startOf('week');
  }

  // returns dateA > dateB
  compareDates(dateA: string, dateB: string): boolean {
    if (this.isStringVaildDate(dateA) && this.isStringVaildDate(dateB)) {
      return this.dateTimeFromDbDate(dateA).startOf('day') > this.dateTimeFromDbDate(dateB).startOf('day');
    } else {
      return false;
    }
  }

  /**
   * adds minutes to date and returns the ISO string of the new date
   *
   * @param {string} date
   * @param {number} minutes
   * @return {*}
   * @memberof DatetimeService
   */
  addMinutesToDate(date: string, minutes: number): string {
    const newDate = DateTime.fromISO(date);
    newDate.plus({ minutes: minutes });
    return newDate.toISO();
  }

  isValidDateRange(lower: DateLike, upper: DateLike): boolean {
    try {
      if (lower !== null && upper !== null) {
        return new Date(lower) <= new Date(upper);
      } else if (lower === null || upper === null) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  /**
   * Takes an isoDate and returns based on options passed
   * @param date - ISO string
   * @param options - formatting options as per https://moment.github.io/luxon/#/formatting
   */
  format(date: DateLike, options: Record<string, string> | string): string {
    let dt;
    if (typeof date === 'string') {
      dt = DateTime.fromISO(date);
    } else if (date instanceof Date) {
      dt = DateTime.fromJSDate(date);
    }
    if (typeof options === 'string') {
      options = DateTime[options];
    }
    return dt.toLocaleString(options);
  }
}

export type DateLike = string | Date;
