<div class="tabbed-container rounded-md">
  <div class="tab-row flex flex-row justify-start items-center">
    <ng-container *ngFor="let tab of tabs"
      ><div
        #ref
        id="container-tab-{{ tab.index }}"
        class="tab-selector flex flex-row justify-center items-center"
        (click)="onTabClick(ref)"
      >
        <span class="tab-text">{{ tab.title }}</span>
      </div></ng-container
    >
  </div>

  <div #tabContent class="tab-content">
    <ng-content></ng-content>
  </div>
</div>
