<div
  *ngIf="type === 'fab'"
  class="fam-container"
  fxLayout="column"
  fxLayoutAlign="end end"
  fxLayoutGap="12px"
>
  <input
    *ngIf="menuItems && menuItems.length > 0"
    type="checkbox"
    class="fab-input"
  />

  <div
    fxLayout="column-reverse"
    fxLayoutAlign="end end"
    fxLayoutGap="12px"
    class="fab-item-container"
  >
    <ng-container *ngFor="let item of menuItems">
      <div
        [className]="
          (disabled ? 'fab-disabled' : '') +
          (!isTouchScreen ? 'fab-no-touch ' : '') +
          'base-fab-item fab-item item-transition items' +
          menuItems.length
        "
        fxLayout="row"
        fxLayoutAlign="end center"
        (click)="menuItemClicked(item)"
      >
        <span
          class="fab-label"
          [ngClass]="{ 'fab-no-touch-label': !isTouchScreen }"
          >{{ item.label }}</span
        >
        <div class="fab-spot" fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="item.iconType === 'svg'; else matIcon">
            <mat-icon
              class="fab-menu-icon"
              svgIcon="{{ item.icon }}"
            ></mat-icon>
          </ng-container>
          <ng-template #matIcon>
            <mat-icon class="fab-icon">{{ item.icon }}</mat-icon>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="base-fab fab"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="menuFabClicked($event)"
  >
    <mat-icon class="fab-icon">add</mat-icon>
  </div>
</div>

<!-- Mini Fab -->
<div
  *ngIf="type === 'mini-fab'"
  class="fam-container"
  fxLayout="column"
  fxLayoutAlign="end end"
  fxLayoutGap="12px"
>
  <input
    *ngIf="menuItems && menuItems.length > 0"
    type="checkbox"
    class="mini-fab-input"
  />

  <div
    fxLayout="column-reverse"
    fxLayoutAlign="end end"
    fxLayoutGap="12px"
    class="fab-item-container"
  >
    <ng-container *ngFor="let item of menuItems">
      <div
        [className]="
          (disabled ? 'fab-disabled' : '') +
          (!isTouchScreen ? 'mini-fab-no-touch ' : '') +
          'base-fab-item mini-fab-item item-transition items' +
          menuItems.length
        "
        fxLayout="row"
        fxLayoutAlign="end center"
        (click)="menuItemClicked(item)"
      >
        <span
          class="fab-label"
          [ngClass]="{ 'fab-no-touch-label': !isTouchScreen }"
          >{{ item.label }}</span
        >
        <div class="mini-fab-spot" fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="item.iconType === 'svg'; else matIcon">
            <mat-icon class="fab-icon" svgIcon="{{ item.icon }}"></mat-icon>
          </ng-container>
          <ng-template #matIcon>
            <mat-icon class="fab-icon">{{ item.icon }}</mat-icon>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="base-fab mini-fab"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="menuFabClicked($event)"
  >
    <mat-icon class="fab-icon">add</mat-icon>
  </div>
</div>

<!-- Extended Fab -->
<div
  *ngIf="type === 'extended-fab'"
  class="fam-container"
  fxLayout="column"
  fxLayoutAlign="end end"
  fxLayoutGap="12px"
>
  <input
    *ngIf="menuItems && menuItems.length > 0"
    type="checkbox"
    class="extended-fab-input"
  />

  <div
    fxLayout="column-reverse"
    fxLayoutAlign="end end"
    fxLayoutGap="12px"
    class="fab-item-container"
  >
    <ng-container *ngFor="let item of menuItems">
      <div
        [className]="
          (disabled ? 'fab-disabled' : '') +
          (!isTouchScreen ? 'fab-no-touch ' : '') +
          'base-fab-item fab-item item-transition items' +
          menuItems.length
        "
        fxLayout="row"
        fxLayoutAlign="end center"
        (click)="menuItemClicked(item)"
      >
        <span
          class="fab-label"
          [ngClass]="{ 'fab-no-touch-label': !isTouchScreen }"
        >
          {{ item.label }}
        </span>
        <div class="fab-spot" fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="item.iconType === 'svg'; else matIcon">
            <mat-icon class="fab-icon" svgIcon="{{ item.icon }}"></mat-icon>
          </ng-container>
          <ng-template #matIcon>
            <mat-icon class="fab-icon">{{ item.icon }}</mat-icon>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="extended-fab"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="12px"
    (click)="menuFabClicked($event)"
  >
    <mat-icon class="fab-icon">add</mat-icon>
    <span>{{ extendedFabLabel }}</span>
  </div>
</div>
