import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EditTablesComponent } from "./edit-tables/edit-tables.component";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { MaterialModule } from "../shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { EditTableComponent } from "./edit-table/edit-table.component";
import { EditTablesCardViewComponent } from "./edit-tables-card-view/edit-tables-card-view.component";
import { RowEditorComponent } from "./edit-table/row-editor/row-editor.component";
import { TableModule } from "../shared/table/table.module";
import { BaseModalModule } from "../shared/modals/base-modal.module";
import { FormsModule } from "@angular/forms";
import { NgxColorsModule } from "ngx-colors";
import { CardModule } from "../shared/card/card/card.module";

@NgModule({
  declarations: [
    EditTablesComponent,
    EditTableComponent,
    EditTablesCardViewComponent,
    RowEditorComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    PageNavigationModule,
    TableModule,
    FormsModule,
    NgxColorsModule,
    CardModule,
    BaseModalModule,
  ],
})
export class EditTablesModule {}
