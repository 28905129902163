<!-- <div *ngIf="!panelLoaded" style="padding-left:16px">
  <h2>
    Document Viewer
  </h2>
  <p>
    Getting document...
  </p>
</div> -->
<!-- 960 width max -->
<app-french-english-option-view
  *ngIf="localeStage"
  [props]="localeProps"
></app-french-english-option-view>

<app-pdf-view
  *ngIf="pdfStage && !gatherDocError && !localeStage"
  [props]="pdfProps"
></app-pdf-view>

<div
  *ngIf="!localeStage && !pdfStage"
  class="pdf-ack-panel-container"
  fxLayout="row"
  fxLayoutAlign="center start"
>
  <div
    class="pdf-ack-panel-inner-container"
    fxFlex="960px"
    fxFlex.lt-md="100"
    fxLayout="column"
    style="height: 100%"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="
        padding-top: 84px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 48px;
      "
    >
      <div
        id="logo-id"
        fxFlex="168px"
        fxFlex.lt-md="168px"
        [class]="locale.logo"
      ></div>
      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="space-between end"
        style="font-size: 15px; min-height: 65px"
      >
        <div fxLayout="row wrap" fxLayoutAlign="end start">
          <div
            fxLayout="column"
            fxLayoutAlign="start end"
            style="margin-left: 16px; text-align: right"
          >
            <h6 class="mat-headline-1">
              {{ locale.issueContact }}
            </h6>
            <h6 class="mat-headline-1">+1 877-395-1122</h6>
          </div>
        </div>
        <a class="u4-link-text mat-headline-1" (click)="toggleFrenchEnglish()">
          {{ languageName }}
        </a>
      </div>
    </div>

    <app-acknowledgement-view
      *ngIf="ackStage && !localeStage && !gatherDocError"
      [props]="ackProps"
    >
    </app-acknowledgement-view>

    <app-decline-ack-view
      *ngIf="decStage && !localeStage && !gatherDocError"
      [props]="decProps"
    >
    </app-decline-ack-view>

    <app-signature-view
      *ngIf="sigStage && !localeStage && !gatherDocError"
      [props]="sigProps"
    >
    </app-signature-view>

    <div
      *ngIf="gatherDocError && !localeStage"
      style="height: 100%; width: 100%"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between start"
        style="height: 100%; width: 100%"
      >
        <div fxFlex="100" fxFlex.lt-md="100">
          <h2>
            {{ locale.errorHeaderH2 }}
          </h2>
          <h3>
            {{ locale.errorHeaderH3 }}
          </h3>
          <p>
            {{ locale.errorBody1a }} <b>Internet Explorer</b>
            {{ locale.errorBody1b }}
          </p>
          <p>
            {{ locale.errorBody2a }}
            <b>
              <a href="{{ locale.pdfPath }}" target="_blank">{{
                locale.errorBody2b
              }}</a></b
            >
            {{ locale.errorBody2c }}
          </p>
        </div>
        <div fxFlex="100" fxFlex.lt-md="100" style="height: 100%">
          <div style="height: 100%; width: 100%">
            <app-pdf-viewer
              #pdfViewer
              [pdfOptions]="pdfOptions"
            ></app-pdf-viewer>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="error404" style="height: 100%">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between start"
        style="height: 100%; width: 100vw; overflow: auto"
      >
        <div fxFlex="100" fxFlex.lt-md="100" style="padding-left: 16px">
          <h2>Unable to find</h2>
          <p>
            {{ message404 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
