export const EsriMessageAction = {
  BUTTON_PRESSED: 1,
  MAP_LOADED: 2,
  TOGGLE_PANEL: 3,
  SET_SEARCH: 4,
  SAVE_DIGSITES: 5,
  DRAWING_READY: 6,
};

export const ButtonAction = {
  DETAILS_PANEL: 1,
  BASEMAP_PANEL: 2,
  MEASURE_PANEL: 3,
  IDENTIFY: 4,

  HAND: 5,
  POINTER: 6,
  DRAW_POLYGON: 7,
  DRAW_LINE: 8,
  DRAW_POINT: 9,
  DRAW_RECTANGLE: 10,
  UNDO: 11,
  REDO: 12,
  DELETE: 13,
  RESET: 14,
};

export const GraphicType = {
  POINT: 1,
  LINE: 2,
  POLYGON: 3,
};

export const GraphicStatus = {
  DRAWN: 1,
  MODIFIED: 2,
  DELETED: 3,
  CREATED: 4,
};

export const LayerType = {
  MapServer: 1,
  WMSLayer: 2,
  FeatureLayer: 3,
  Basemap: 4,
  WFS: 5,
  TileLayer: 6,
};

// options for Esri Map Component

export interface MapOptions {
  basemap?: any;
  zoom: number;
  center: number[]; // longitude, latitude
  searchContainer?: any;
  measurementContainer?: any;
  basemapToggle?: boolean;
}

export interface ToolbarOptions {
  hasDetails: boolean;
  hasMeasure: boolean;
  hasBasemaps: boolean;
  hasIdentify: boolean;
  toolbarType?: number;
}

export interface LayerOptions {
  layers: LayerInfo[];
  credentials: EsriCredential[];
  defaultSubLayers: DefaultSubLayer[];
  groups: Group[];
}

// Layer objects

export interface LayerInfo {
  layerID: number;
  name: string;
  url: string;
  isVisible: boolean;
  groupID?: number;
  layerTypeID: number;
}

export interface DefaultSubLayer {
  layerID: number;
  subLayerID: number;
  isVisible: boolean;
}

export interface Group {
  groupID: number;
  name: string;
}

export interface EsriCredential {
  username: string;
  password: string;
  serviceUrl: string;
  tokenGenerationUrl: string;
}

export interface Layer {
  layerID?: number;
  name: string;
  subLayers: Layer[];
  isChecked: boolean;
  isParentChecked: boolean;
  groupID?: number;
  layerContent: any;
}
