<div class="view-container">
  <div
    class="create-ticket-docs-container mat-elevation-z4"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    style="padding: 8px"
  >
    <span fxFlex="49px" class="mat-subtitle-2 default-header-2 pt-6"
      >Documents</span
    >
    <!-- <div fxFlex="41px" class="list-checkbox" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-checkbox #docsSelectBox (change)="onDocsCheckChange($event)" matTooltip="Select/Deselect all"></mat-checkbox>
      <div class="vert-divider"></div>
    </div> -->
    <div class="box-border pt-2 pr-3 absolute pointer-events-none w-full  flex justify-end items-start z-50">
      <app-floating-tab-select [tabs]="tabs" [(activeTab)]="selection" [multiple]="false" />
      </div>
    
      <mat-tab-group
      [selectedIndex]="selection[0]"
      (selectedIndexChange)="selected.setValue($event)"
    >
        <mat-tab>
          <mat-list class="content-overflow" fxFlex="100%" #files>
            <mat-list-item *ngFor="let file of addedFiles" class="h-[60px]">
              <div class="flex flex-row">
              <div class="pr-3">
                <img class="w-[60px] h-[60px]" [src]="file.img">
              </div>
              <div
                style="width: 100%"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <div>
                  <div class="documents-list-card-filename">{{ file.name }}</div>
                  <div class="documents-list-card-value">{{ file.desc }}</div>
                </div>
                <button mat-icon-button (click)="onDeleteDoc(file)">
                  <mat-icon mat-list-icon class="warn">close</mat-icon>
                </button>
              </div>
            </div>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
      </mat-tab>
      <mat-tab > 
    
          <div *ngFor="let file of addedFiles" class="p-[6px] relative">
            <div class="flex flex-row flex-wrap">
              <img class="w-[300px] h-[300px]" [src]="file.img">
            </div>
          </div>
    </mat-tab>
      </mat-tab-group>
  </div>
</div>


  <input
  #fileInput10
  style="display: none"
  type="file"
  (change)="handleFileInput($event.target.files, 10)"
  />
  <input
  #fileInput4
  style="display: none"
  type="file"
  (change)="handleFileInput($event.target.files, 4)"
  />

  <floating-action-menu
  [hidden]="bottomMenuOpen"
  [menuItems]="fabMenu"
  type="fab"
>
</floating-action-menu>


  <!-- <button mat-menu-item (click)="onDeleteDocs(files.selectedOptions.selected)">
    <mat-icon matTooltip="Delete selected documents">delete</mat-icon>
    <span>Delete Selected Documents</span>
  </button> -->
