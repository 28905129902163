<form
  [formGroup]="infoFormGroup"
  fxLayout="row wrap"
  fxLayoutAlign="space-between start"
  fxLayoutGap="16px grid"
>
  <mat-form-field
    fxFlex="50"
    *ngFor="let input of inputs"
    class="create-ticket-input-field"
  >
    <mat-label>{{ input.placeholder }}</mat-label>
    <input
      matInput
      placeholder="{{ input.placeholder }}"
      formControlName="{{ input.dbName }}"
      required="{{ input.isRequired }}"
      id="{{ input.dbName }}"
    />
  </mat-form-field>

  <mat-form-field
    fxFlex="50"
    *ngFor="let dropdown of dropdowns"
    class="create-ticket-input-field"
  >
    <mat-label>{{ dropdown.placeholder }}</mat-label>
    <mat-select
      placeholder="{{ dropdown.placeholder }}"
      formControlName="{{ dropdown.dbName }}"
      required="{{ dropdown.isRequired }}"
      matTooltip="{{ dropdown.placeholder }}"
      matTooltipPosition="above"
      multiple
    >
      <mat-option
        *ngFor="let option of dropdown['options']"
        [value]="option['value']"
        matTooltip="{{ option.text }}"
        matTooltipPosition="right"
        >{{ option.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    fxFlex="50"
    *ngFor="let datepicker of datepickers"
    class="create-ticket-input-field"
  >
    <mat-label>{{ datepicker.placeholder }}</mat-label>
    <input
      matInput
      formControlName="{{ datepicker.dbName }}"
      placeholder="{{ datepicker.placeholder }}"
      required="{{ datepicker.isRequired }}"
      [matDatepicker]="ticketDatePicker"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="ticketDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker
      #ticketDatePicker
      [disabled]="infoFormGroup.disabled"
    ></mat-datepicker>
  </mat-form-field>
</form>
