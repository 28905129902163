import {Component, Input, OnInit} from "@angular/core";
import {DatetimeService} from "src/app/modules/core/services/datetime/datetime.service";
import {UserService} from "src/app/modules/core/services/user/user.service";

export interface AutologListItem {
  DescIDDesc: string;
  Explaination: string;
  logDate: string;
  UserID: number;
}

export interface AutologGroup {
  date: string;
  list: AutologListItem[];
}

@Component({
  selector: "app-ticket-autologs-item",
  templateUrl: "ticket-autologs-item.component.html",
  styleUrls: ["./ticket-autologs-item.component.scss"],
})
export class TicketAutologListItemComponent implements OnInit {
  @Input() autolog: AutologListItem;

  userName: string
  formattedDate: string;

  constructor(private datetimeService: DatetimeService, private userService: UserService) { }

  async ngOnInit(): Promise<void> {
    this.formattedDate = this.datetimeService.dbDateToFormattedLocalDate(
      this.autolog.logDate
    );
    if (this.autolog.UserID) {
      this.userService.getReadableUserInfo(this.autolog.UserID.toString()).then(user => {
        this.userName = user.Username ?? '';
      });
    }
  }
}
