export interface agmOptions {
  zoomControl: boolean;
  zoomControlOptions?: any;
  mapTypeControl: boolean;
  mapTypeControlOptions?: any;
  streetViewControl: boolean;
  scaleControl?: boolean;
  rotateControl?: boolean;
  fullScreenControl?: boolean;
  controlSize?: number;
}

export enum agmMapTypeControlStyle {
  DEFAULT = 0,
  HORIZONTAL_BAR = 1,
  DROPDOWN_MENU = 2,
}

export enum agmMapControlPosition {
  RIGHT_BOTTOM = 0,
  TOP_LEFT = 1,
  TOP_CENTER = 2,
  TOP_RIGHT = 3,
  LEFT_CENTER = 4,
  LEFT_TOP = 5,
  LEFT_BOTTOM = 6,
  RIGHT_TOP = 7,
  RIGHT_CENTER = 8,
  BOTTOM_RIGHT = 9,
  BOTTOM_LEFT = 10,
  BOTTOM_CENTER = 11,
}

/*
original agm options

  // agmOptions = {
  //   streetViewControl: false,

  //   mapTypeControl: true,
  //   mapTypeControlOptions: {
  //     style: 2, // google.maps.MapTypeControlStyle.HORIZONTAL_BAR
  //     position: 3 // google.maps.ControlPosition.RIGHT_CENTER
  //   },

  //   zoomControl: true,
  //   zoomControlOptions: {
  //     position: 8 // google.maps.ControlPosition.LEFT_CENTER
  //   },
  // };

*/
