import { SimpleChanges } from "@angular/core";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-ticket-digsite-details",
  template: `<app-form-input-template
    fxFlex
    fxFlexOrder="1"
    [props]="formProps"
    class="content-overflow"
  ></app-form-input-template>`,
  styles: [""],
})
export class TicketDigsiteDetailsComponent
  extends BasePageComponent
  implements OnChanges
{
  className = "TicketDigsiteDetailsComponent";

  @Input() props;
  formProps: any;

  constructor(logger$: LoggerService) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange) {
      if (changes.props.currentValue && changes.props.currentValue.views) {
        this.formProps = {
          ...this.formProps,
          categoryLinks: ["digsiteDetails"],
          view: changes.props.currentValue.views.digsiteDetails,
          views: changes.props.currentValue.views,
        };
      }
    }
  }
}
