<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-template [ngIf]="isLoaded" [ngIfElse]="loading">
  <form [formGroup]="view.formGroup" autocomplete="off">
    <div #formContainer fxLayout="column" fxLayoutAlign="start stretch" class="form-template-view">
      <ng-container>
        <span
          *ngIf="view.showHeader != 'false' && !isPrimary"
          [style.padding]="view.showHeader != 'false' ? '16px 0px 0px 16px' : '0px 0px 0px 16px'"
          class="mat-headline-1 black">
          {{ view.header }}
        </span>
      </ng-container>

      <ng-container>
        <span
          *ngIf="view.showHeader != 'false' && isPrimary"
          [style.padding]="view.showHeader != 'false' ? '8px 0px 0px 16px' : '0px 0px 0px 16px'"
          class="mat-subtitle-1 blue primary-sub-heading">
          {{ view.header }}
        </span>
      </ng-container>

      <ng-container *ngFor="let group of view.groups | keyvalue; trackBy: trackByFn">
        <div
          *ngIf="group.key.indexOf('defaultGroup') == -1"
          fxFlexOrder="{{ group.value.groupOrder }}"
          class="form-template-view-group-container">
          <div class="form-template-view-group" [style.border-color]="group.value.isErrorEnabled ? '#FF2306' : 'white'">
            <div *ngIf="navLinks && navLinks.includes(group.key); else hiddenValueHeader">
              <button mat-button type="button" class="header-button">
                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                  <span class="mat-subtitle-1">{{ group.value.header }}</span>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </button>
            </div>

            <ng-template #hiddenValueHeader>
              <ng-container *ngIf="group.value.hasHiddenValues; else defaultHeader">
                <button mat-button type="button" style="width: 100%">
                  <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    style="width: 100%; text-align: right; font-size: 16px; padding-top: 8px; padding-bottom: 8px">
                    <span class="mat-subtitle-1">{{ group.value.header }}</span>
                    <mat-icon>edit</mat-icon>
                  </div>
                </button>
              </ng-container>
            </ng-template>

            <ng-template #defaultHeader>
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center none"
                class="form-template-view-group-header"
                [style.color]="group.value.isErrorEnabled ? '#FF2306' : '#58595B'">
                <div
                fxLayout="column"
                fxLayoutAlign="space-between start none"
                class="form-template-view-group-header">
                  <span
                    class="mat-subtitle-1 default-header-2"
                    [style.color]="isPrimary ? 'black' : '#58595B'"
                    [style.font-size]="isPrimary ? '14px' : '18px'"
                    fxFlex="95">
                    {{ group.value.header }}
                    <span *ngIf="group.value.fillStageID && group.value.fillStageID == 3" style="color: red">*</span>
                  </span>
                  <span
                    *ngIf="group.value.subtitle != null"
                    class="mat-subtitle-1 default-header-2"
                    [style.color]="isPrimary ? 'black' : '#58595B'"
                    [style.font-size]="isPrimary ? '12px' : '14px'"
                    fxFlex="95">
                    {{ group.value.subtitle }}
                    <span *ngIf="group.value.fillStageID && group.value.fillStageID == 3" style="color: red">*</span>
                  </span>
                </div>

                <button
                  *ngIf="group.value.isErrorEnabled"
                  fxFlex="5"
                  mat-button
                  color="warn"
                  class="header-button"
                  disableRipple="true"
                  (click)="showErrorMessage(group.value.ErrorMessage)">
                  <mat-icon class="mat-small-icons" style="vertical-align: top">warning</mat-icon>
                </button>
              </div>
            </ng-template>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="grid" class="header-values">
              <ng-container
                *ngIf="!group.value.fillStageID || (group.value.fillStageID && group.value.fillStageID != 3)">
                <ng-container *ngFor="let field of group.value.fields | keyvalue; trackBy: trackByFn">
                  <span
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 0"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-text">
                    {{ field.value.label }}
                  </span>

                  <mat-checkbox
                    *ngIf="field.value.isVisible && (field.value.inputTypeID == 1 || field.value.inputTypeID == 5)"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    id="{{ field.value.key }}"
                    formControlName="{{ field.value.key }}"
                    class="form-check mat-body-2 max-w-[50%] normal-case"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    style="padding: 8px 0px 8px 0px; width: 50%"
                    labelPosition="after"
                    (change)="emitChecked($event.checked, field.value.label)">
                    {{ field.value.label }}
                  </mat-checkbox>

                  <mat-form-field
                    *ngIf="
                      field.value.isVisible &&
                      (field.value.inputTypeID == 2 ||
                        field.value.inputTypeID == 6 ||
                        field.value.inputTypeID == 15 ||
                        field.value.inputTypeID == 16)
                    "
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <!-- floatLabel="always" -->
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      id="{{ field.value.key }}"
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                      type="search"
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)" />
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 25"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <!-- floatLabel="always" -->
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      id="{{ field.value.key }}"
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                      type="email"
                      class="mat-body-1"
                      (blur)="onInputBlur($event, field.value.key)" />
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 18"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label>{{ field.value.label }}</mat-label>
                    <textarea
                      matInput
                      cdkTextareaAutosize
                      id="{{ field.value.key }}"
                      tabindex="{{ field.value.tabindex }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                      (blur)="onInputBlur($event, field.value.key)"></textarea>
                  </mat-form-field>
                  <!-- Remarks area with url -->
                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 28"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label>{{ field.value.label }}</mat-label>
                    <textarea
                      matInput
                      cdkTextareaAutosize
                      id="{{ field.value.key }}"
                      tabindex="{{ field.value.tabindex }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                      (blur)="onInputBlur($event, field.value.key)"></textarea>
                    <div *ngIf="url">
                      <a [href]="sanitizedUrl" target="_blank">{{ this.url }}</a>
                    </div>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="
                      field.value.isVisible &&
                      (field.value.inputTypeID == 3 ||
                        field.value.inputTypeID == 4 ||
                        field.value.inputTypeID == 7 ||
                        field.value.inputTypeID == 8)
                    "
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      id="{{ field.value.key }}"
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      placeholder="0"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                      type="number"
                      step="any"
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)" />
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 10"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      [readonly]="field.value.isReadOnly"
                      id="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      formControlName="{{ field.value.key }}"
                      type="datetime"
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)" />
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 11"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <mat-select
                      tabindex="{{ field.value.tabindex }}"
                      id="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      formControlName="{{ field.value.key }}"
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)">
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let option of field.value.selectOptions; trackBy: trackByFn" [value]="option">
                        {{ option.text }}
                      </mat-option>
                    </mat-select>
                    <!-- <input
                    id="{{ field.value.key }}"
                    matInput
                    placeholder="{{ field.value.placeholder }}"
                    required="{{ field.value.isRequired }}"
                    [readonly]="field.value.isReadOnly"
                    formControlName="{{ field.value.key }}"
                  /> -->
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 12 && ticketTagsSetting"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <mat-select
                      tabindex="{{ field.value.tabindex }}"
                      id="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      formControlName="TicketTags"
                      [(ngModel)]="selected"
                      multiple
                      class="mat-body-2"
                      >
                      <mat-option *ngFor="let option of field.value.options trackBy: trackByFn" [value]="option">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                    <!-- <input
                      id="{{ field.value.key }}"
                      matInput
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      formControlName="{{ field.value.key }}"
                    /> -->
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 13"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      type="search"
                      matInput
                      tabindex="{{ field.value.tabindex }}"
                      formControlName="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      [matAutocomplete]="auto"
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)" />
                    <mat-autocomplete ref-auto="matAutocomplete">
                      <cdk-virtual-scroll-viewport itemSize="40" [style.height.px]="4 * 40">
                        <mat-option *cdkVirtualFor="let option of field.value.filteredOptions" [value]="option">
                          {{ option.name }}
                        </mat-option>
                      </cdk-virtual-scroll-viewport>
                    </mat-autocomplete>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="field.value.isVisible && field.value.inputTypeID == 14"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    class="form-input"
                    floatLabel="auto"
                    [appearance]="field.value.isReadOnly ? 'outline' : 'fill'">
                    <mat-label class="mat-overline">{{ field.value.label }}</mat-label>
                    <input
                      type="search"
                      tabindex="{{ field.value.tabindex }}"
                      matInput
                      formControlName="{{ field.value.key }}"
                      placeholder="{{ field.value.placeholder }}"
                      required="{{ field.value.isRequired }}"
                      [readonly]="field.value.isReadOnly"
                      #addressPicker
                      class="mat-body-2"
                      (blur)="onInputBlur($event, field.value.key)" />
                  </mat-form-field>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="group.value.fillStageID && group.value.fillStageID == 3"
                formGroupName="{{ group.value.fillKey }}">
                <ng-container *ngFor="let field of group.value.fields | keyvalue; trackBy: trackByFn">
                  <mat-checkbox
                    *ngIf="field.value.isVisible && (field.value.inputTypeID == 1 || field.value.inputTypeID == 5)"
                    fxFlexOrder="{{ field.value.fieldOrder }}"
                    id="{{ field.value.key }}"
                    formControlName="{{ field.value.key }}"
                    class="form-check mat-body-2"
                    fxFlex.xs="{{ field.value.width_xs }}"
                    fxFlex.sm="{{ field.value.width_sm }}"
                    fxFlex.md="{{ field.value.width_md }}"
                    fxFlex.lg="{{ field.value.width_lg }}"
                    fxFlex.xl="{{ field.value.width_xl }}"
                    style="padding: 8px 0px 8px 0px"
                    labelPosition="before">
                    {{ field.value.label }}
                  </mat-checkbox>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</ng-template>

<!-- can add an ng template for errors? -->

<ng-template #loading>
  <h4 class="pl-4">Loading...</h4>
  <!-- <mat-spinner [diameter]="30" color="accent"></mat-spinner> -->
</ng-template>
