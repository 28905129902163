import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { SchedulerTicketDialogComponent } from '../../scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component';
import { MaterialModule } from '../../shared/material.module';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { TableModule, TemplateColumn } from '../../shared/table/table.module';
import { SystemMaintenanceService } from '../services/system-maintenance.service';
import { TableService } from '../../shared/table/table.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CompetersSearchBarComponent } from '../../../shared/components/inputs/competers-search-bar/competers-search-bar.component';
import { TabulatorTableComponent } from "../../shared/table/tabulator-table/tabulator-table.component";

@Component({
  selector: 'app-failed-emails',
  templateUrl: 'failed-emails.component.html',
  styleUrls: ['failed-emails.component.scss'],
  providers: [TableService],
})
export class FailedEmailsComponent implements OnInit {
  // table config
  selectionType: SelectionType = SelectionType.multi;
  rowClickedCallback;
  searchText: string = '';

  // component
  emails: any[] = [];
  failedToGatherEmails: boolean = false;
  loadedData: boolean = false;
  displayedColumns: TemplateColumn[] = [];
  selected: any[] = [];

  legendItems = [
    {
      title: 'Excavator Email',
      colour: '#FDC33D',
    },
    {
      title: 'OOC Email',
      colour: '#0D66B1',
    },
    {
      title: '3rd Party Email',
      colour: '#E62525',
    },
  ];

  constructor(
    private systemMaintenance$: SystemMaintenanceService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    public tableService: TableService<any>
  ) {
    this.rowClickedCallback = this.onRowClicked.bind(this);
  }

  ngOnInit(): void {
    this.refreshView();
  }

  async refreshView() {
    this.progressBarService.start();
    const result = await this.systemMaintenance$.getFailedEmails().toPromise();

    if (result.body.Result) {
      this.emails = result.body.Result.Data;
      this.formatColumns(result.body.Result.Columns);
      this.loadedData = true;
    }

    if (result.body.FailMessage) {
      this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      this.failedToGatherEmails = true;
    }
    this.emails = [...this.emails];
    this.progressBarService.stop();
  }

  formatColumns(columns: string[]) {
    this.displayedColumns = columns
      .filter((col) => {
        if (col !== 'AssignmentID' && col !== 'EmailID' && col !== 'FormatID') {
          return true;
        } else {
          return false;
        }
      })
      .map((col, index) => {
        return {
          TemplateColumnID: 0,
          ColumnOrder: index,
          TemplateID: 0,
          Field: 0,
          Visible: 1,
          Width: 300,
          Title: col,
        } as TemplateColumn;
      });
  }

  async onResend() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.markResend(this.createLambdaBody(this.selected)).toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Marked Email for Resend', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Mark Email for Resend', SnackbarType.error);
      }
    }
  }

  async onMarkSentManually() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.markSendManually(this.createLambdaBody(this.selected)).toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Marked Email as Sent Manually', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Mark Email as Sent Manually', SnackbarType.error);
      }
    }
  }

  async onMarkAbandoned() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.markAbandon(this.createLambdaBody(this.selected)).toPromise();
      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Marked Email as Abandoned', SnackbarType.success);
        this.refreshView();
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Mark Email as Abandoned', SnackbarType.error);
      }
    }
  }

  createLambdaBody(selectedEmails: any[]) {
    const formattedEmails: FormattedEmailRow[] = [];
    selectedEmails.forEach((selectedEmail) => {
      formattedEmails.push({
        EmailID: selectedEmail.EmailID,
        FormatID: selectedEmail.FormatID,
        AssignmentID: selectedEmail.AssignmentID,
        PrimaryID: selectedEmail['Sub Num'],
      });
    });
    return formattedEmails;
  }

  openTicket(row) {
    const ticketData = {
      AssignmentID: row.AssignmentID,
      isModal: true,
    };

    const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
      width: window.innerWidth.toString() + 'px',
      maxWidth: '100%',
      height: window.innerHeight.toString() + 'px', // if small screen make full screen
      panelClass: 'margin-dialog-container',
      data: {
        ticketData,
      },
    });
  }

  rowSelectionChanged(e) {
    console.log(e);
    this.selected = e;
  }
  templateChanged(e) {}

  onRowClicked(e) {
    this.openTicket(e);
  }
}

export interface FormattedEmailRow {
  EmailID: number;
  FormatID: number;
  AssignmentID: number;
  PrimaryID: number;
}

@NgModule({
    declarations: [FailedEmailsComponent],
    exports: [FailedEmailsComponent],
    imports: [CommonModule, MaterialModule, FlexLayoutModule, TableModule, CompetersSearchBarComponent, TabulatorTableComponent]
})
export class SystemMaintenanceModule {}
