import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
searchText = searchText.toLowerCase();

return items.filter( it => {
      return it.Address.toLowerCase().includes(searchText) || it.City.toLowerCase().includes(searchText) || it.TypeOfWork.toLowerCase().includes(searchText) || it.RequestNumber.toString().includes(searchText);
    });
   }
}
