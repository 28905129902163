<mat-chip-listbox>
  <mat-chip-option
    *ngFor="let item of tags"
    [style.background-color]="item.color"
    [style.color]="item.textColor"
    disableRipple="false"
    selectable="false"
  >
    {{ item.value }}
  </mat-chip-option>
</mat-chip-listbox>
