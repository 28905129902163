export const TicketActionIDs = {
  REASSIGN_ACTION_ID: 1000,
  UNASSIGN_ACTION_ID: 1001,
  ASSIGN_TO_SPECIFIC_USER_ACTION_ID: 1002,

  SEND_TO_LSP_ACTION_ID: 1100,
  CANCEL_TICKET_ACTION_ID: 1101,
  UNCOMPLETE_TICKET_ACTION_ID: 1102,
  OFFICE_COMPLETE_TICKET_ACTION_ID: 1103,
  OFFICE_ONGOING_TICKET_ACTION_ID: 1104,
  SEND_TO_LSP_DEFAULT1_ACTION_ID: 1105,
  SEND_TO_LSP_DEFAULT2_ACTION_ID: 1106,

  UPDATE_SUBSTATUS_ACTION_ID: 1200,
  ESCALATE_TICKET_ACTION_ID: 1201,

  COMMENTS_TO_EXCAVATOR_ACTION_ID: 1300,
  DISPATCHER_REMARKS_ACTION_ID: 1301,
  CHANGE_EXCAVATION_DATE_ACTION_ID: 1302,

  CLEAR_ACTION_ID: 1400,
  SPLIT_AND_CLEAR_UTILITY_ACTION_ID: 1401,
  ADD_UTILITY_ACTION_ID: 1402,
  REMOVE_UTILITY_ACTION_ID: 1403,
  SPLIT_UTILITY_ACTION_ID: 1404,
  GROUP_UTILITY_ACTION_ID: 1405,
  UNGROUP_UTILITY_ACTION_ID: 1406,

  DUPLICATE_TICKET_ACTION_ID: 1500,
};
