import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { ApiService, UtilocateApiRequest } from "../../api/baseapi.service";
import { UtilocateApiService } from "../../api/utilocateapi.service";

@Injectable({
  providedIn: "root",
})
export class EmailerService {
  constructor(
    private http: HttpClient,
    private baseApiService: UtilocateApiService,
    private utilocateApiService: ApiService
  ) {}

  // url = 'http://ptsv2.com/t/2jh62-1666366204/post';

  // async sendEmail() {
  //  let keys = {"name":"John", "age":30, "city":"New York"}
  // let key = JSON.stringify(keys);
  //   let returns: HttpResponse<any> = await this.baseApiService.invokeApi("POST", this.url, key);
  //   // return this.http.get(this.rootURL);
  // }

  sendEmail(strBody): Observable<any> {
    if (strBody) {
      const url = apiKeys.u2.queueEmails;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.queueEmails,
        API_TYPE: type,
        API_BODY: strBody,
      };

      return from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      );
    }

    return of({ ok: false });
  }
}
