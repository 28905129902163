<!-- background fade in -->
<!-- side slide over -->

<!-- <div *ngIf='isOpen' [@slideInOut] class="slideover-side-panel-container" [ngClass]="{'slideover-side-panel-fullscreen':isFullscreen}"
  [ngStyle]="{'width':isFullscreen ? screenWidth+'px':'320px', 'height':screenHeight+'px'}" dir="rtl"> -->
<div
  *ngIf="isOpen"
  [@fadeInOut]
  style="z-index: 900"
  class="slideover-side-panel-container"
  [ngClass]="{ 'slideover-side-panel-fullscreen': props.isFullscreen }"
  [ngStyle]="{
    width: props.isFullscreen ? screenWidth + 'px' : '320px',
    height: screenHeight + 'px'
  }"
></div>
<div
  #mySidePanel
  *ngIf="isOpen"
  [@slideInOut]
  style="z-index: 1000"
  class="slideover-side-panel-background"
  [ngClass]="{ 'slideover-side-panel-fullscreen': props.isFullscreen }"
  [fxFlex.lt-md]="screenWidth + 'px'"
  [fxFlex.gt-sm]="props.width"
  dir="ltr"
>
  <div
    fxLayout="column"
    fxLayoutAlign="space-between center"
    style="width: 100%"
    [ngStyle]="{ height: screenHeight + 'px' }"
  >
    <div
      fxFlex="72px"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="primary-background primary-color"
      style="width: 100%; padding: 8px"
    >
      <div class="mat-headline-1" style="color: white">{{ props.title }}</div>
      <button mat-icon-button (click)="close()" aria-label="close panel">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div fxFlex="4px" style="width: 100%">
      <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>
    </div>
    <div
      fxFlex
      style="padding-left: 8px; padding-right: 8px; width: 100%"
      class="content-overflow"
    >
      <app-form-input-template
        *ngIf="props.isForm"
        [props]="props"
        (addressSelected)="addressSearched($event)"
        class="content-overflow"
      ></app-form-input-template>
      <div *ngIf="props.template">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="grid"
        >
          <mat-form-field fxFlex="100" fxFlexOrder="1" class="form-input">
            <mat-label>Choose Company</mat-label>
            <input
              type="text"
              tabindex="1"
              placeholder="Enter Company Name"
              aria-label="companyName"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="onAutoCompanyClick($event.option.value)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option.text }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      fxFlex="72px"
      fxLayout="row wrap"
      fxLayoutAlign="end center"
      fxLayoutGap="8px"
      style="padding-left: 8px; padding-right: 8px; width: 100%"
    >
      <ng-container *ngFor="let buttonAction of props.actions">
        <div>
          <button
            *ngIf="buttonAction.buttonType == AngularButtonTypes.defaultButton"
            fxFlexOrder="{{ buttonAction.order }}"
            mat-button
            [color]="buttonAction.color"
            (click)="buttonAction.action(myPanel)"
          >
            {{ buttonAction.text }}
          </button>
          <button
            *ngIf="buttonAction.buttonType == AngularButtonTypes.raisedButton"
            fxFlexOrder="{{ buttonAction.order }}"
            mat-raised-button
            [color]="buttonAction.color"
            (click)="buttonAction.action(myPanel)"
          >
            {{ buttonAction.text }}
          </button>
          <button
            *ngIf="buttonAction.buttonType == AngularButtonTypes.flatButton"
            fxFlexOrder="{{ buttonAction.order }}"
            mat-flat-button
            [color]="buttonAction.color"
            (click)="buttonAction.action(myPanel)"
          >
            {{ buttonAction.text }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- </div> -->
