import { Component, Input, OnInit } from "@angular/core";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-decline-ack-view",
  templateUrl: "./decline-ack-view.component.html",
  styleUrls: ["./decline-ack-view.component.scss"],
})
export class DeclineAckViewComponent {
  @Input() props: any;

  constructor(
    // error$: MainErrorService,
    logger$: LoggerService
    // alert$: NotificationService,
  ) {}
  /**
   * send a message with readAgain: true
   */
  onClickReadAgain() {
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { readAgain: true },
      senderID: "DeclineAckViewComponent",
    };

    this.props.messageService.sendToMessageStream(message);
  }
}
