import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatChipsModule} from '@angular/material/chips';

export type ChipSelectComponentInput = {
  value: string;
  name: string;
}

@Component({
  selector: 'app-competers-chip-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatChipsModule],
  template: `
    <mat-chip-set class="mat-mdc-chip-set" aria-label="Toggle group selection">
      <!-- 
        Checkbox option. 
          if this is not a multiselect and the checkbox is selected, disable the 
          ability to deselect it (pointer-events-none) so that one value is always 
          selected. 
      -->
      <mat-chip-row
        *ngFor="let option of options"
        [color]="!multiselect && isSelected(option.value) ? 'primary' : '' "
        (click)="toggleOption(option)"
        class="font-rajdhani font-semibold uppercase max-h-4 text-[10px]"
        [ngClass]="{ 'bg-primary pointer-events-none': !multiselect && isSelected(option.value) }"
      >
      <div [ngClass]="{ 'text-white': !multiselect && isSelected(option.value) }">
        {{ option.name }}
      </div>

      </mat-chip-row>
    </mat-chip-set>
  `,
})
export class ChipSelectComponent implements OnInit {
  @Input() options: ChipSelectComponentInput[] = [];
  @Input() multiselect: boolean = false;
  @Input() selectedValue: any;
  @Output() newValueEvent = new EventEmitter<any>();

  ngOnInit() {
    // If initial value is set, update the selectedValue
    if (this.options && this.options.length > 0) {
      this.selectedValue = this.options[0].value;
      this.emitValue();
    }
  }

  toggleOption(option: any) {
    if (this.multiselect) {
      // For multiselect, toggle the selection
      if (this.isSelected(option.value)) {
        this.selectedValue = this.selectedValue.filter((val: any) => val !== option.value);
      } else {
        this.selectedValue.push(option.value);
      }
    } else {
      // For single select, simply set the value
      this.selectedValue = option.value;
    }
    this.emitValue();
  }

  isSelected(value: any): boolean {
    if (!this.selectedValue) return false;

    if (this.multiselect) {
      return this.selectedValue.includes(value);
    } else {
      return this.selectedValue === value;
    }
  }

  emitValue() {
    this.newValueEvent.emit(this.selectedValue);
  }
}
