import { BreakpointObserver } from "@angular/cdk/layout";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { zipFile } from "../../core/services/document/zip-helper";
import { GeneratePDFService } from "../../core/services/generatePDF/generate-pdf.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { ModalService } from "../../shared/modals/modal.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SnackbarType } from "../../shared/snackbar/snackbar/snackbar";
import { DocumentViewerService } from "../document-viewer.service";
import { PadOptions } from "../signature-pad/padOptions";
import { SignaturePadComponent } from "../signature-pad/signature-pad.component";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-document-viewer-signature-form",
  templateUrl: "./document-viewer-signature-form.component.html",
  styleUrls: ["./document-viewer-signature-form.component.scss"],
})
export class DocumentViewerSignatureFormComponent implements OnInit, OnDestroy {
  className: string = "DocumentViewerSignatureForm";

  @ViewChild("signaturePad") signaturePadComponent: SignaturePadComponent;
  @Input() documentSignatureModalID: string;
  @Input() disclaimer: string = "";
  @Input() isModal: boolean = true;
  @Input() modalOpened$: Subject<boolean>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() successEmitter: EventEmitter<any> = new EventEmitter<any>();
  documentName: string = null;
  progressBarMode = "determinate";
  signaturePadOptions: PadOptions = {
    minWidth: 2,
    backgroundColor: "rgba(255,255,255,1)",
    penColor: "rgba(0,0,0,1)",
  };

  acknowledgeDisabled = false;
  form: FormGroup;
  locale: any;

  constructor(
    private logger: LoggerService,
    private breakpoint$: BreakpointObserver,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private documentViewerService: DocumentViewerService,
    private snackBarService: SnackbarService,
    private generatePDFService: GeneratePDFService,
  ) {}

  /**
   * check fields, setup filename using session storage, initForms
   */
  ngOnInit(): void {
    this.checkRequiredFields();
    //setup filename
    const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);
    this.documentName = aid + "_sig.png";
    this.initForms();
    this.modalOpened$?.pipe(takeUntil(this.destroy$)).subscribe((isOpen) => {
      if (isOpen) {
        this.signaturePadComponent.resizeCanvas();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * throw an error if the modal id is null and isModal is true
   */
  private checkRequiredFields() {
    if (this.documentSignatureModalID == null && this.isModal) {
      throw new Error(
        "Document signature form missing required field: documentSignatureModalID",
      );
    }
  }

  /**
   * create form for signature modal
   */
  initForms() {
    this.form = this.formBuilder.group(
      {
        FirstName: ["", [Validators.required, Validators.maxLength(45)]], //new FormControl('', [Validators.required, Validators.email])
        LastName: ["", [Validators.required, Validators.maxLength(45)]],
        PhoneNumber: [
          "",
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(20),
          ],
        ],
      },
      {},
    );
  }

  // this.documentName = parsedName[0] + "_sig.png";

  /**
   * call clear pad function to erase signature
   */
  onClickClear() {
    // this.form.reset();
    this.signaturePadComponent.clearPad();
  }

  /**
   * validate form, save signature and upload signature document
   */
  async onClickAccept() {
    this.acknowledgeDisabled = true;
    this.progressBarMode = "indeterminate";
    try {
      if (this.validateSignatureForm()) {
        this.updateDocumentName();
        let formValues = this.form.getRawValue();
        if (this.isModal) {
          this.modalService.returnDataOnClose(
            this.documentSignatureModalID,
            formValues,
          );
        }
        let signature = await this.getZippedSignature();
        if (signature["fileName"]) {
          await this.uploadSignatureDocument(
            signature["fileName"],
            signature["file"],
            formValues,
          );
        }
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    this.progressBarMode = "determinate";
    this.acknowledgeDisabled = false;
  }

  /**
   * add name of signee to documentname
   */
  updateDocumentName() {
    try {
      let form = this.form.getRawValue();
      this.documentName =
        form.FirstName.trim() + "." + form.LastName.trim() + this.documentName;
    } catch (error) {
      const aid = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.assignmentid,
      );
      this.documentName = aid + "_sig.png";
      this.logger.error(error.message);
    }
  }

  /**
   * use documentViewerService to upload file, updateSignature
   * generate completed locate doc
   * @param fileName name of signature document
   * @param file file data
   * @param formValues values from signature modal, used for desc
   */
  async uploadSignatureDocument(fileName, file, formValues) {
    try {
      let desc = this.createDescription(formValues);
      let result = await this.documentViewerService
        .uploadSignatureDocument(fileName, file, desc)
        .toPromise();
      if (result) {
        var row = JSON.parse(result.body);
        try {
          let res = await this.documentViewerService.updateSignature(
            row["DocumentID"],
            formValues,
          );
          this.successEmitter.emit();
          this.form.reset();
          this.signaturePadComponent.clearPad();

          let primary = await this.documentViewerService.getPrimaryID();
          console.log(primary);

          if (primary.PrimaryID) {
            let updatePDF = await this.generatePDFService.generatePDF(
              primary.PrimaryID,
              3,
              "Completed Locate Document",
              1,
              12,
              Number.parseInt(
                sessionStorage.getItem(localStorageKeys.URL_KEYS.documentid),
              ),
            );
          } else {
            this.snackBarService.openSnackbar(
              "Failed to add Signature to Document: Unable to get Primary",
              SnackbarType.error,
            );
          }
        } catch (ex) {
          throw new Error(ex);
        }
      }
    } catch (error) {
      this.logger.error(error);
      let snackbarConfig: MatSnackBarConfig = {
        duration: 2500,
        horizontalPosition: "center",
        verticalPosition: "top",
      };
      this.snackBarService.openSnackbar(
        "Unable To Save Information, please try again.",
        SnackbarType.error,
        "close",
        snackbarConfig,
      );
    }
  }

  /**
   * creates a file description for signature doc
   * @param formvalues values from signature modal
   * @returns string with values in a readable format
   */
  createDescription(formvalues) {
    let desc = "Signature Document";
    try {
      desc =
        "Name: " +
        formvalues.FirstName +
        " " +
        formvalues.LastName +
        ", #:" +
        formvalues.PhoneNumber +
        ", signature document";
    } catch (error) {}
    return desc;
  }

  /**
   * check if signature modal form is filled out
   * @returns true if form is valid and data exists
   */
  validateSignatureForm(): boolean {
    let isValid = false;
    if (this.form.valid && this.signaturePadComponent.getRawData().length > 0) {
      isValid = true;
    } else {
      this.form.markAllAsTouched();
      let snackbarConfig: MatSnackBarConfig = {
        duration: 2500,
        horizontalPosition: "center",
        verticalPosition: "top",
      };
      this.snackBarService.openSnackbar(
        "Please fill all fields and sign in box",
        SnackbarType.warning,
        "close",
        snackbarConfig,
      );
    }
    return isValid;
  }

  /**
   * compress document and return
   * @returns object containing zipped file and doc name
   */
  async getZippedSignature(): Promise<any> {
    let signature = {};
    if (this.signaturePadComponent.getRawData().length > 0) {
      let url = this.signaturePadComponent.getDataURL("png");
      let base64Str = url.split(",")[1];
      let zippedFile = await zipFile(this.documentName, base64Str);
      signature = {
        fileName: this.documentName,
        file: zippedFile,
      };
    }
    return signature;
  }

  /**
   * close sig modal with modal service
   */
  closeSignatureModal() {
    try {
      if (this.isModal) {
        this.modalService.close(this.documentSignatureModalID);
        this.modalOpened$.next(false);
      }
    } catch (error) {
      this.logger.error(error.message);
    }
  }
}
