/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import JSZip from "jszip";
import { DatetimeService } from "../datetime/datetime.service";
import { LoggerService } from "../logger/logger.service";

export interface UnzippedDocument {
  filename: string;
  data: any;
}

export const DocumentTypeID = {
  "OOC Sketches": 1,
  "Map Discrepancy": 2,
  PDF: 3,
  Internal: 4,
  Drawing: 5,
  OldDocument: 6,
  DBF: 7,
  GML: 8,
  "Blank Locate": 9,
  "Office Internal": 10,
  "Original Email as Text file": 11,
  "Pre Completions PDF": 12,
  "Well Record XML": 13,
  "Sewer Lateral Auxiliary PDF": 14,
  "Basemap Drawing": 100,
  "Primary Drawing": 101,
  "Auxiliary Drawing": 102,
  "Internal Drawing": 103,
  "Presketch Drawing": 104,
  "PDF is S3": 105,
  "Auxiliary Image": 106,
};

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(
    private logger: LoggerService,
    private dateTimeService: DatetimeService
  ) {}

  /**
   * Zip Multiple files
   * @param {string[]} fileNames list of file names
   * @param {string[]} bufferDataSets list of array buffers
   * @returns {Promise<any>} Zipped file
   */
  async zipFiles(fileNames: string[], bufferDataSets: string[]): Promise<any> {
    let zippedFile = null;
    try {
      if (
        fileNames != null &&
        bufferDataSets != null &&
        fileNames.length == bufferDataSets.length
      ) {
        const zip = new JSZip();
        for (let i = 0; i < fileNames.length; i++) {
          zip.file(fileNames[i], bufferDataSets[i]);
        }

        zippedFile = await zip.generateAsync({ type: "blob" });
      } else {
        throw new Error(
          "failed to zipFile: fileNames or bufferDataSets are null"
        );
      }
    } catch (ex) {
      this.logger.error(ex);
    }

    return zippedFile;
  }

  /**
   * Zip a single file
   * @param {string} fileName
   * @param {string} bufferData
   * @return {any} zipped file
   */
  async zipFile(fileName: string, bufferData: string): Promise<any> {
    let zippedData = null;

    try {
      if (bufferData != null) {
        const zip = new JSZip();
        zip.file(fileName, bufferData, { base64: true });
        zippedData = await zip.generateAsync({
          type: "base64",
          compression: "DEFLATE",
          compressionOptions: { level: 9 },
        });
      } else {
        throw new Error("failed to zipFile: bufferData is null");
      }
    } catch (ex) {
      this.logger.error(ex);
    }

    return zippedData;
  }

  /**
   * unzip a file, include name
   * @param zippedData
   * @return unzipped doc object
   */
  async unzipFileIncludeName(zippedData): Promise<UnzippedDocument> {
    let unzippedDoc: UnzippedDocument = null;
    try {
      if (zippedData != null) {
        const zip = new JSZip();
        const contents = await zip.loadAsync(zippedData);
        try {
          if (
            contents != null &&
            contents.files != null &&
            Object.keys(contents.files).length > 0 &&
            Object.keys(contents.files)[0] != null
          ) {
            if (zip.file(Object.keys(contents.files)[0]) != null) {
              const bufferData = await zip
                .file(Object.keys(contents.files)[0])
                .async("uint8array");
              unzippedDoc = {
                filename: Object.keys(zip.files)[0],
                data: bufferData,
              };
            } else {
              unzippedDoc = {
                filename: Object.keys(zip.files)[0],
                data: null,
              };
            }
          } else {
            throw new Error("failed to unzipFile: contents is null");
          }
        } catch (ex) {
          throw new Error(ex);
        }
      } else {
        throw new Error("failed to unzipFile: zippedData is null");
      }
    } catch (ex) {
      this.logger.error(ex);
    }

    return unzippedDoc;
  }

  getExtensionFromFilename(filename) {
    return filename.split(".").pop();
  }

  arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  formatDateForFileName(curDate: Date, includeTime: boolean = true) {
    let format = "yyyy-MM-dd";

    if (includeTime) {
      format += "-HH-mm-ss";
    }
    return this.dateTimeService.localDateToFormattedString(curDate, format);
  }
}
