import {
  NgModule,
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import { Subscription } from "rxjs";
import { Inject } from "@angular/core";
import { LoggerService } from "../services/logger/logger.service";
import { ComponentMessagingService } from "../component-messaging/component-messaging.service";
import { ComponentMessage } from "../component-messaging/component-message";

// Add any services or methods to the base components you want all components to have access to
// When adding a new base component type, make sure to add it to the NgModule like the others

@Component({
  selector: "app-base-component",
  template: "<p>base-component!</p>",
})
export class BaseComponent {
  @Input() props: any;

  constructor(public logger$: LoggerService) {}
}

@Component({ selector: "app-base-view", template: "<p>extend base-view!</p>" })
export class BaseViewComponent extends BaseComponent {}

// Base Panel Component
@Component({
  selector: "app-base-panel",
  template: "<p>extend base-panel!</p>",
})
export class BasePanelComponent extends BaseComponent {}

// Base Page Component
@Component({
  selector: "app-base-page",
  template: "<p>extend base-page!</p>",
})
export class BasePageComponent {
  public TICKET_PROTECTION_MODE: boolean;

  constructor(public loggerService: LoggerService) {}
}

// Base Tabs Page Component
@Component({
  selector: "app-base-page",
  template: "<p>extend base-page!</p>",
})
export class BaseTabsPageComponent extends BasePageComponent {
  tabIndex: any;
  tabs: any;
  constructor(public loggerService: LoggerService) {
    super(loggerService);
  }
  indexChange(index) {
    this.tabIndex = index;
  }

  goNext() {
    if (this.tabIndex + 1 > this.tabs.length) {
      this.tabIndex = 0;
    } else {
      this.tabIndex++;
    }
  }

  goToTabByLabel(label) {
    if (this.tabs.indexOf(label) > -1) {
      this.tabIndex = this.tabs.indexOf(label);
    }
  }
}

@Component({
  selector: "app-base-template-form-view",
  template: "<p>extend base-view!</p>",
})
export class BaseTemplateFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() props: any;
  className: string;
  myViewName: string;
  parentMsgSubscriber: Subscription;
  childMsgSubscriber: Subscription;
  myCompMsg: ComponentMessagingService;
  myView: any = {};
  views = {};
  formProps = {};
  categoryLinks: string[];

  constructor(
    public logger$: LoggerService,
    compMsg$: ComponentMessagingService
  ) {
    this.myCompMsg = compMsg$;
    this.init();
  }

  init() {
    this.formProps = {
      messageService: this.myCompMsg,
      categoryLinks: this.categoryLinks,
      view: this.myView,
      views: this.views,
      TICKET_PROTECTION_MODE: false,
    };

    //Listen to child
    this.childMsgSubscriber = this.myCompMsg
      .getMessageStream()
      .subscribe((nextMsg) => {
        this.logger$.log(this.className + " : childListener");
      });
  }

  ngOnInit(): void {
    try {
      if (this.props && this.props["messageService"]) {
        this.parentMsgSubscriber = this.props["messageService"]
          .getMessageStream()
          .subscribe((nextMsg: ComponentMessage) => {
            this.logger$.log(this.className + " : ParentListener");
          });
      }
    } catch (error) {
      this.logger$.error(this.className + " : error" + error.message);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"] &&
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"] &&
        changes["props"]["currentValue"]["views"][this.myViewName]
      ) {
        this.myView =
          changes["props"]["currentValue"]["views"][this.myViewName];
        this.views = changes["props"]["currentValue"]["views"];
        this.refresh();
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  refresh() {
    try {
      this.formProps["views"] = this.views;
      this.formProps["view"] = this.myView;
      this.formProps = { ...this.formProps };
    } catch (error) {
      this.logger$.error(this.className + " : refresh Error" + error.message);
    }
  }

  ngOnDestroy() {
    try {
      if (this.parentMsgSubscriber) {
        this.parentMsgSubscriber.unsubscribe();
      }
      if (this.childMsgSubscriber) {
        this.childMsgSubscriber.unsubscribe();
      }
    } catch (error) {
      this.logger$.error(this.className + " : Destroyed Error" + error.message);
    }
    this.logger$.log(this.className + " : Destroyed");
  }

  /**
   *
   * @param event
   */
  onDateSelected(event) {
    try {
      if (event && event.date && event.field) {
        this.patchFieldValues(event.date, event.field.value);
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  /**
   *
   * @param value value
   * @param field field in view
   */
  patchFieldValues(value, field) {
    try {
      if (value && field && field.options && field.options.patchValues) {
        let viewKeys = field.options.patchValues;
        let values = {};
        viewKeys.forEach((viewKey) => {
          if (viewKey.controlKeys) {
            viewKey.controlKeys.forEach((controlKey) => {
              values[controlKey] = value;
            });
          }
        });
        if (this.views) {
          for (let valueIndex in this.views) {
            if (this.views[valueIndex]["formGroup"]) {
              this.views[valueIndex]["formGroup"].patchValue(values);
            }
          }
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }
}

@NgModule({
  declarations: [
    BaseComponent,
    BasePageComponent,
    BaseTabsPageComponent,
    BasePanelComponent,
    BaseViewComponent,
    BaseTemplateFormComponent,
  ],
  exports: [
    BaseComponent,
    BasePageComponent,
    BaseTabsPageComponent,
    BasePanelComponent,
    BaseViewComponent,
    BaseTemplateFormComponent,
  ],
  providers: [ComponentMessagingService],
})
export class BaseSetModule {}
