import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DragHandleComponent } from './drag-handle/drag-handle.component';
import { FloatingTabSelectComponent } from '../../misc/selection/floating-tab-select/floating-tab-select.component';
import { TicketListMapComponent } from '../../../../routes/home/ticket-list-map/ticket-list-map.component';
import { TicketListTableComponent } from '../../../../routes/home/ticket-list-table/ticket-list-table.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vertical-split',
  standalone: true,
  imports: [
    CommonModule,
    DragHandleComponent,
    FloatingTabSelectComponent,
    TicketListMapComponent,
    TicketListTableComponent,
    FormsModule,
  ],
  template: `
    <div class="w-full h-full relative">
      <div class="p-6 absolute pointer-events-none w-full h-full flex justify-start items-start z-50"  [ngClass]="{'justify-end': this.tabs[0].title == 'list'}">
        <app-floating-tab-select class="ios-shadow-fix rounded-md" [tabs]="tabs" [(activeTab)]="selection" [multiple]="true" (activeTabChange)="handleChangeEvent($event)" />
      </div>
      <div class="flex flex-row justify-start items-start h-full w-full">
        <div
          #leftDiv
          class="h-full bg-cyan-50 overflow-hidden"
          [style.width]="
            selection.length > 1 ? 'calc(50% + ' + _leftModifier + 'px)' : selection.includes(0) ? '100%' : '0'
          ">
            <ng-content select="[left]" />
        </div>
        @if (selection.includes(0) && selection.includes(1)) {
          <app-drag-handle [(relativePosition)]="_leftModifier" (resize)="finishResize()" class="h-0 w-0" />
        }
        <div #rightDiv class="flex-grow flex-1 h-full overflow-hidden">
            <ng-content select="[right]" />
        </div>
      </div>
    </div>
  `,
})
export class VerticalSplitComponent {
  // IO
  @Input() tabs: Array<{ index: number; title: string; icon: string; }>;
  @Input() selection: Array<number> = [0, 1];
  @Input() currentTabs: Subject<number[]>;
  @Output() resize = new EventEmitter<void>();
  @ViewChild('leftDiv', { static: true }) leftDiv: ElementRef<HTMLDivElement>;
  @ViewChild('rightDiv', { static: true }) rightDiv: ElementRef<HTMLDivElement>;
  protected _leftModifier: number = 0;

  finishResize() {
    this.resize.emit();
    if (this.leftDiv.nativeElement.clientWidth <= 0) {
      this.selection = [1];
      this._leftModifier = 0;
    } else if (this.rightDiv.nativeElement.clientWidth <= 0) {
      this.selection = [0];
      this._leftModifier = 0;
    }
  }

  handleChangeEvent(event: number[]) {
    if (this.currentTabs) this.currentTabs.next(event);
  }
}
