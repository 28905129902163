<h1 mat-dialog-title>Advanced Ticket Search</h1>
<div mat-dialog-content fxLayout="column">
  <form id="search-form" [formGroup]="form">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="8px grid"
    >
      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="ExcavatorCompany"
          id="ExcavatorCompany"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">Excavator Company</label>
      </div>

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="Address"
          id="Address"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">Address</label>
      </div>

      <div class="e-float-input" fxFlex="100">
        <input
          class="e-input"
          formControlName="City"
          id="City"
          type="text"
          value=""
          required
        />
        <span class="e-float-line"></span>
        <label class="e-float-text">City</label>
      </div>

      <div class="form-group" fxFlex="100">
        <ejs-dropdownlist
          id="dateField"
          formControlName="dateField"
          [dataSource]="dateDataSource"
          [fields]="dateFields"
          placeholder="Select Date Field"
          floatLabelType="Auto"
        ></ejs-dropdownlist>
      </div>

      <div class="form-group" fxFlex="50" fxFlex.xs="100">
        <ejs-datepicker
          formControlName="startDate"
          id="startDate"
          placeholder="Start Time"
          floatLabelType="Auto"
        >
        </ejs-datepicker>
      </div>

      <div class="form-group" fxFlex="50" fxFlex.xs="100">
        <ejs-datepicker
          formControlName="endDate"
          id="endDate"
          placeholder="End Time"
          floatLabelType="Auto"
        >
        </ejs-datepicker>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-button color="primary" (click)="cancel()">Cancel</button>
  <button mat-button color="primary" (click)="advancedSearch()">Search</button>
</div>
