import { Component, Inject, inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, firstValueFrom, Observable, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { DispatchAreaService } from '../../../../services/dispatch-area/dispatch-area.service';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { toLonLat } from 'ol/proj';
import { SnackbarService } from '../../../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../../../modules/shared/snackbar/snackbar/snackbar';
import { ProgressBarService } from 'src/app/modules/shared/progress-bar/progress-bar.service';
import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DispatchAreaEditorService implements OnDestroy {
  //services
  private dispatchAreaService = inject(DispatchAreaService);
  private progressBarService = inject(ProgressBarService);
  private snackbarService = inject(SnackbarService);
  private dialogService = inject(MatDialog);

  // Observable
  private _destroy$ = new Subject<void>();

  // members
  private _isOpen$ = new BehaviorSubject(true);
  private _areaQuery$ = new BehaviorSubject([]);
  private _selectedArea$ = new BehaviorSubject([]);
  private _selectedLayer$ = new BehaviorSubject([]);
  private _addingArea$ = new BehaviorSubject<boolean>(false);
  private _modifyingArea$ = new BehaviorSubject<boolean>(false);
  private _snappingEnabled$ = new BehaviorSubject<boolean>(true);
  private _snapTolerance$ = new BehaviorSubject<Array<{ name: string; value: number }>>([{ name: '10', value: 10 }]);
  private _feature$ = new BehaviorSubject<Feature<Polygon> | null>(null);

  constructor() {
    this._addingArea$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._selectedArea$.next([]);
      this._feature$.next(null);
    });
    this._modifyingArea$.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      if (!val) {
        this._selectedArea$.next([]);
        this._feature$.next(null);
      }
    });
  }

  submitChanges(formData: Record<string, any>) {
    if (this.userCanModifySetting[0] && this.userCanModifySetting[1] != '1') {
      this.snackbarService.openSnackbar('You do not have permission to modify dispatch areas', SnackbarType.error);
      throw new Error('You do not have permission to modify dispatch areas');
    }
    if (this._feature$.value === null) {
      this.snackbarService.openSnackbar('No area selected', SnackbarType.warning);
      return;
    }
    const dialog = this.dialogService.open(ConfirmComponent, {
      data: { title: 'Are you sure you want to submit changes?' },
      width: 'auto',
      height: 'auto',
    });
    const { name, user, managerArea, routing, areaID } = formData;
    const { UnionGas3rdParty: email, TagID } = this._feature$.value.getProperties()['props'] ?? {};
    const coords = this._feature$.value
      .getGeometry()
      .getCoordinates()[0]
      .map((x) => toLonLat(x))
      .map(([a, b]) => [b, a].join(','));
    if (coords.length < 3) {
      this.snackbarService.openSnackbar('Area must have at least 3 points', SnackbarType.warning);
      return;
    }
    firstValueFrom(
      dialog.afterClosed().pipe(
        switchMap((val) => {
          if (val) {
            this.progressBarService.start();
            return this.dispatchAreaService.mutateArea(
              name,
              user[0].value,
              managerArea[0].value,
              routing && routing.length > 0 ? routing[0].value : 0,
              this.selectedLayer[0].value,
              coords,
              areaID,
              email,
              TagID
            );
          } else {
            return EMPTY;
          }
        }),
        tap(() => {
          this.selectedArea = [];
          this.addingArea = false;
          this.modifyingArea = false;
        }),
        finalize(() => this.progressBarService.stop())
      )
    ).catch((e) => {
      if (e.name !== 'EmptyError') {
        this.snackbarService.openSnackbar('Failed to submit changes', SnackbarType.error);
      }
    });
  }

  deleteArea() {
    if (this.userCanModifySetting[0] && this.userCanModifySetting[1] != '1') {
      this.snackbarService.openSnackbar('You do not have permission to modify dispatch areas', SnackbarType.error);
      throw new Error('You do not have permission to modify dispatch areas');
    }
    this.progressBarService.start();
    if (this._selectedArea$.value.length === 0) {
      this.snackbarService.openSnackbar('No area selected', SnackbarType.warning);
      return;
    } else {
      const dialog = this.dialogService.open(ConfirmComponent, {
        data: { title: 'Are you sure you want to submit changes?' },
        width: 'auto',
        height: 'auto',
      });
      firstValueFrom(
        dialog.afterClosed().pipe(
          switchMap((val) => {
            if (val) {
              this.progressBarService.start();
              return this.dispatchAreaService.deleteArea(this._selectedArea$.value[0].value);
            } else {
              return EMPTY;
            }
          }),
          tap(() => {
            this.selectedArea = [];
            this.addingArea = false;
            this.modifyingArea = false;
          }),
          finalize(() => this.progressBarService.stop())
        )
      ).catch((e) => {
        if (e.name !== 'EmptyError') {
          this.snackbarService.openSnackbar('Failed to delete area', SnackbarType.error);
        }
      });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // Selected Area
  set selectedArea(val: Array<{ name: string; value: number }>) {
    if (!isEqual(this._selectedArea$, val)) {
      this._selectedArea$.next(val);
    }
  }

  get selectedArea$() {
    return this._selectedArea$.pipe(distinctUntilChanged());
  }

  get selectedArea() {
    return this._selectedArea$.value;
  }

  get areaQuery$() {
    return this._areaQuery$.pipe(distinctUntilChanged());
  }

  set areaQuery(val: Array<{ name: string; value: number }>) {
    if (!isEqual(this._areaQuery$, val)) {
      this._areaQuery$.next(val);
    }
  }

  // Selected Layer
  set selectedLayer(value: Array<{ name: string; value: number }>) {
    if (!isEqual(this._selectedLayer$.value, value)) {
      this._selectedLayer$.next(value);
    }
    if (this._selectedLayer$.value.length === 0) {
      this._addingArea$.next(false);
    }
  }

  get selectedLayer$() {
    return this._selectedLayer$.pipe(distinctUntilChanged());
  }

  get selectedLayer() {
    return this._selectedLayer$.value;
  }

  // modes
  // addingArea
  get addingArea$() {
    return this._addingArea$.pipe(distinctUntilChanged());
  }

  get addingArea() {
    return this._addingArea$.value;
  }

  set addingArea(val: boolean) {
    if (val && this._selectedLayer$.value.length === 0) {
      this.snackbarService.openSnackbar('Please select a layer before adding an area', SnackbarType.warning);
      this._addingArea$.next(false);
    } else {
      if (!isEqual(this._addingArea$.value, val)) {
        this._addingArea$.next(val);
      }
    }
  }

  get modifyingArea$() {
    return this._modifyingArea$.pipe(distinctUntilChanged());
  }

  get modifyingArea() {
    return this._modifyingArea$.value;
  }

  set modifyingArea(val: boolean) {
    if (this._selectedArea$.value.length === 0 && val) {
      this.snackbarService.openSnackbar('Please select an area before modifying', SnackbarType.warning);
      this._modifyingArea$.next(false);
      return;
    }
    if (!isEqual(this._modifyingArea$.value, val)) {
      this._modifyingArea$.next(val);
    }
  }

  set snapTolerance(val: Array<{ name: string; value: number }>) {
    if (!isEqual(this._snapTolerance$.value, val)) {
      this._snapTolerance$.next(val);
    }
  }

  get snapTolerance$() {
    return this._snapTolerance$.pipe(distinctUntilChanged());
  }

  get snapTolerance() {
    return this._snapTolerance$.value;
  }

  set snappingEnabled(val: boolean) {
    if (!isEqual(this._snappingEnabled$.value, val)) {
      this._snappingEnabled$.next(val);
    }
  }

  get snappingEnabled$() {
    return this._snappingEnabled$.pipe(distinctUntilChanged());
  }

  get snappingEnabled() {
    return this._snappingEnabled$.value;
  }

  get feature$() {
    return this._feature$.pipe(distinctUntilChanged());
  }

  get feature() {
    return this._feature$.value;
  }

  set feature(val: Feature<Polygon> | null) {
    console.log('setting feature');
    if (!isEqual(this._feature$.value, val)) {
      this._feature$.next(val);
    }
  }

  get userCanModifySetting() {
    return this.dispatchAreaService.userCanModifySetting$$();
  }

  get isOpen$(): Observable<boolean> {
    return this._isOpen$.pipe();
  }

  set isOpen$(val: boolean) {
    if (val !== this._isOpen$.value) {
      this._isOpen$.next(val);
    }
  }

  toggleOpen() {
    this._isOpen$.next(!this._isOpen$.value);
  }
}

// component for a confimation dialog
@Component({
  standalone: true,
  template: `
    <div class="flex gap-2 p-4">
      <div class="flex flex-col justify-start items-center gap-6">
        <h2 class="text-2xl font-rajdhani font-semibold uppercase">Accept Changes?</h2>
        <div class="flex flex-row justify-center items-center gap-4">
          <button
            (click)="dialogRef.close(false)"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase">
            Cancel
          </button>
          <button
            (click)="dialogRef.close(true)"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
            confirm
          </button>
        </div>
      </div>
    </div>
  `,
  imports: [MatIcon],
})
class ConfirmComponent {
  title = '';
  close = () => undefined;

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, boolean>
  ) {}
}
