const cities = [
  [-79.3832, 43.6532],  // Toronto, Canada
  [-123.1207, 49.2827],  // Vancouver, Canada
  [-73.9866, 40.7306],  // New York City, United States
  [-77.0369, 38.9072],  // Washington D.C., United States
  [-84.388, 33.749],  // Atlanta, United States
  [-118.2437, 34.0522],  // Los Angeles, United States
  [-87.6298, 41.8781],  // Chicago, United States
  [-95.3698, 29.7604],  // Houston, United States
  [-79.9311, 32.7765],  // Charleston, United States
  [-122.3301, 47.6062],  // Seattle, United States
  [-87.9073, 41.8919],  // Milwaukee, United States
  [-84.2941, 30.4383],  // Pensacola, United States
  [-97.7431, 30.2672],  // Austin, United States
  [-73.5673, 45.5017],  // Montreal, Canada
  [-87.6298, 41.8781],  // Indianapolis, United States
  [-96.7969, 32.7767],  // Dallas, United States
  [-121.4944, 38.5816],  // Sacramento, United States
  [-75.1652, 39.9526],  // Philadelphia, United States
  [-118.2437, 34.0522],  // San Diego, United States
  [-84.512, 39.1031],  // Cincinnati, United States
  [-81.6557, 30.3322],  // Jacksonville, United States
  [-76.6122, 39.2904],  // Baltimore, United States
  [-80.1917902, 25.7616798],  // Miami, United States
  [-122.4194, 37.7749],  // San Francisco, United States
  [-106.6504, 35.0844], // Albuquerque, United States
  [-90.1994, 38.627],  // St. Louis, United States
  [-75.1636, 39.7392],  // Wilmington, United States
  [-112.074, 33.4484],  // Phoenix, United States
  [-76.6147, 39.2909],  // Washington County, United States
  [-75.5268, 39.1653],  // Dover, United States
  [-122.6819, 45.5202],  // Portland, United States
  [-94.5786, 39.0997],  // Kansas City, United States
  [-106.4773, 31.7652],  // El Paso, United States
  [-90.1994, 38.627],  // St. Louis, United States
  [-84.38798, 33.749],  // Atlanta, United States
  [-95.7129, 37.0902],  // Wichita, United States
  [-86.7816, 36.1627],  // Nashville, United States
  [-80.1917902, 25.7616798],  // Miami, United States
  [-118.8139, 34.1179],  // Santa Clarita, United States
  [-77.0251, 38.8951],  // Arlington, United States
  [-111.891, 40.7608],  // Salt Lake City, United States
  [-76.7744, 39.9626],  // Columbia, United States
  [-95.3698, 29.7604],  // Houston, United States
  [-73.5673, 45.5017],  // Montreal, Canada
  [-73.8173, 42.6526],  // Albany, United States
  [-71.0589, 42.3601],  // Boston, United States
  [-122.3321, 47.6062],  // Seattle, United States
  [-112.074, 33.4484],  // Phoenix, United States
  [-97.7431, 30.2672]  // Austin, United States
];

function randomCity() {
  return cities[Math.floor(Math.random() * cities.length)];
}
export default randomCity;
