import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, Observable } from "rxjs";

export enum SyncProgressText {
  UPLOAD_GATHER_LOCAL_TICKETS = "Gather all local tickets",
  UPLOAD_GATHER_UPLOAD_TICKETS = "Gather tickets to upload",
  UPLOAD_DOCUMENTS = "Uploading documents...",
  UPLOAD_API = "Uploaded...",
  UPLOAD_ERROR = "Error Uploading",
  UPLOAD_CLEAN_LOCAL_DATABASE = "Clean local database",

  DOWNLOAD_PREPARE = "Prepare for download",
  DOWNLOAD_ADMIN_TABLES = "Download admin tables",
  DOWNLOAD_TICKETS = "Downloading your tickets",
  DOWNLOAD_TICKETS_ERROR = "Error Downloading ticket",
  DOWNLOAD_BUILDING_TICKETS = "Preparing your tickets",
}

export enum SyncProgressID {
  LOADING = 1,
  ERROR,
  DONE,
}

@Injectable({
  providedIn: "root",
})
export class SyncModalService {
  private syncModalDownload: Subject<any>;
  private syncModalUpload: Subject<any>;

  constructor(public dialog: MatDialog) {
    this.syncModalDownload = new Subject<any>();
    this.syncModalUpload = new Subject<any>();
  }

  // progress bar methods
  getSyncDownload(): Observable<any> {
    return this.syncModalDownload.asObservable();
  }

  getSyncUpload(): Observable<any> {
    return this.syncModalUpload.asObservable();
  }

  updateSyncDownload(
    key: SyncProgressText,
    progress: SyncProgressID,
    message: string
  ) {
    this.syncModalDownload.next({ key, progress, message });
    return this;
  }

  updateSyncUpload(
    key: SyncProgressText,
    progress: SyncProgressID,
    message: string
  ) {
    this.syncModalUpload.next({ key, progress, message });
    return this;
  }
}
