// @ts-nocheck
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Subscription } from "rxjs";

import { Sort } from "@angular/material/sort";
import { ColumnObject } from "../table.module";

@Component({
  selector: "app-editable-table",
  templateUrl: "./editable-table.component.html",
  styleUrls: ["./editable-table.component.scss"],
})
export class EditableTableComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() columns: ColumnObject[] = [];
  @Input() showSearch = true;
  @Input() enableSelect = true;
  @Output() rowClicked = new EventEmitter<any>();

  sortedData = [];

  selection = {
    values: {},
    selected: [],
  };
  columnSizes = {};
  longestValue = {};
  @ViewChildren("testers") testers: QueryList<ElementRef>;
  @ViewChild("tablePage") tableDiv: ElementRef;
  @ViewChild("header") headerDiv: ElementRef;

  setColumnSizes = false;

  testerSub: Subscription;

  searchValue = "";

  initColumns = false;

  constructor() {}

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event: any) {
    this.headerDiv.nativeElement.scrollLeft = $event.target.scrollLeft;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      (changes.data || changes.columns) &&
      this.data &&
      this.columns &&
      this.data.length >= 0 &&
      this.columns.length > 0
    ) {
      if (changes.data) {
        this.selection = {
          values: {},
          selected: [],
        };
      }

      this.setupColumns();
      this.sortedData = this.data;
      this.setColumnSizes = false;
      if (this.testers) {
        if (this.initColumns) {
          this.setColumnsWidths();
        } else {
          this.testerSub = this.testers.changes.subscribe((next) => {
            if (
              this.data.length >= 0 &&
              this.columns.length > 0 &&
              !this.setColumnSizes
            ) {
              this.setColumnsWidths();
              this.initColumns = true;
              this.testerSub.unsubscribe();
            }
          });
        }
      }
    }
  }

  setupColumns() {
    try {
      this.longestValue = {};
      this.columnSizes = {};
      for (const i in this.columns) {
        this.longestValue[this.columns[i].ColumnName] =
          this.columns[i].VisibleName;
        this.columnSizes[this.columns[i].ColumnName] = 100;
      }
      for (const i in this.data) {
        const dataObj = this.data[i];
        this.selection.values[i] = false;
        for (const col in dataObj) {
          if (
            this.longestValue[col] &&
            dataObj[col] &&
            dataObj[col].length > this.longestValue[col].length
          ) {
            this.longestValue[col] = dataObj[col];
            this.columnSizes[col] = dataObj[col].length;
          }
        }
        this.data[i] = { ROW_ID: i, ...this.data[i] };
      }
      for (const i in this.columns) {
        /**
         * replace some of the thinner letters with M, the widest letter
         * to account for values of the same length but differnt letters
         */
        this.longestValue[this.columns[i].ColumnName] = this.longestValue[
          this.columns[i].ColumnName
        ].replace(/(1|2|3|7|5)/g, "M");
      }
    } catch (error) {
      console.log(error);
    }
  }

  setColumnsWidths() {
    const paddingOffset = 16 * this.columns.length;
    const scrollbarOffset = 17;
    const checkboxOffset = 26;
    let width =
      this.tableDiv.nativeElement.offsetWidth - paddingOffset - scrollbarOffset;
    if (this.enableSelect) {
      width = width - checkboxOffset;
    }
    let totaledWidth = 0;

    this.testers.forEach((test) => {
      const colWidth = Math.ceil(test.nativeElement.offsetWidth) + 1;
      totaledWidth += colWidth;
      this.columnSizes[test.nativeElement.id] = colWidth;
    });
    if (totaledWidth < width) {
      const toAdd = (width - totaledWidth) / this.columns.length;
      for (const col in this.columnSizes) {
        this.columnSizes[col] += toAdd;
      }
    } else {
      for (const col in this.columnSizes) {
        this.columnSizes[col] += 200;
      }
    }

    this.setColumnSizes = true;
  }

  onRowClick(row) {
    console.log(row);

    const rowCopy = { ...row };
    delete rowCopy.ROW_ID;
    this.rowClicked.emit(rowCopy);
  }

  // selection functions

  getSelected() {
    const selectedRows = [];

    for (const i in this.data) {
      const row = this.data[i];
      if (this.selection.values[row.ROW_ID]) {
        const rowCopy = { ...row };
        delete rowCopy.ROW_ID;
        selectedRows.push(rowCopy);
      }
    }

    return selectedRows;
  }

  clearSelection() {
    this.selection.selected = [];
    for (const i in this.data) {
      const row = this.data[i];
      this.selection.values[row.ROW_ID] = false;
    }
  }

  toggleSelection(rowID: number) {
    this.selection.values[rowID] = !this.selection.values[rowID];
    if (this.selection.values[rowID]) {
      this.selection.selected.push(rowID);
    } else {
      const index = this.selection.selected.indexOf(rowID);
      if (index > -1) {
        this.selection.selected.splice(index, 1);
      }
    }
  }

  masterSelection(isSelected: boolean) {
    this.selection.selected = [];
    if (isSelected) {
      for (const i in this.data) {
        const row = this.data[i];
        this.selection.selected.push(row.ROW_ID);
        this.selection.values[row.ROW_ID] = 1;
      }
    } else {
      for (const i in this.data) {
        const row = this.data[i];
        this.selection.values[row.ROW_ID] = 0;
      }
      this.clearSelection()
    }
  }

  allChecked() {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.length == this.data.length
    );
  }

  someChecked() {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.length < this.data.length
    );
  }

  // Sort Functions

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
