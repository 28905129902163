<ng-template [ngIf]="isLoaded" [ngIfElse]="loading">
  <div fxLayout="column">
    <ng-container *ngFor="let group of groups | keyvalue">
      <div fxFlexOrder="{{ group.value.groupOrder }}" class="form-container">
        <app-recursive-form-group-template
          [props]="group.value"
          [readOnly]="readOnly"
          (addCategories)="addCategories.emit($event)"
          (removeCategory)="removeCategory.emit($event)"
          (isHideShowToggle)="isHideShowToggle.emit($event)"
        ></app-recursive-form-group-template>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <h4 class="pl-4">Loading...</h4>
  <!-- <mat-spinner [diameter]="30" color="accent"></mat-spinner> -->
</ng-template>
