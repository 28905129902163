import { Component, OnInit, SimpleChanges, Inject, Input } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import {
  HiddenFieldTypeID,
  TicketDetailInputType,
} from "src/app/modules/create/services/create-ticket.service";
@Component({
  selector: "app-form-group-view",
  templateUrl: "./form-group-view.component.html",
  styleUrls: ["./form-group-view.component.css"],
})
export class FormGroupViewComponent
  extends BaseViewComponent
  implements OnInit
{
  className = "FormGroupViewComponent";
  infoFormGroup: FormGroup;
  myName: string = "FormGroupViewComponent";
  // allFields:any;
  // inputs:any;
  isLoaded: boolean = false;
  ticketDetailInputType = TicketDetailInputType;
  isReadOnly = false;
  categories: any;

  constructor(
    logger$: LoggerService,
    compMsg$: ComponentMessagingService,
    public dialog: MatDialog
  ) {
    super(logger$);
  }

  ngOnInit(): void {
    try {
      this.isLoaded = false;
      this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {});
    } catch (error) {
      this.logger$.error(
        this.className,
        "ngOnInit",
        "could not init",
        2,
        error,
        true
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["inputs"] &&
        changes["props"]["currentValue"]["inputs"].length > 0
      ) {
        this.categories = changes["props"]["currentValue"]["inputs"];

        // this.allFields = changes["props"]["currentValue"];
        // this.category = this.allFields["inputs"][0];
        // this.inputs = this.category["options"];
        this.isLoaded = true;
      }
      this.infoFormGroup = changes["props"]["currentValue"]["infoFormGroup"];
      if (changes["props"]["currentValue"]["isReadOnly"]) {
        this.isReadOnly = true;
      } else {
        this.isReadOnly = false;
      }
    } catch (error) {
      this.logger$.error(
        this.className,
        "ngOnChanges",
        "ngOnChanges",
        2,
        error,
        true
      );
    }
  }

  getMyMessage() {
    //currently focuses on primary details. Might have to add switch to return
    //different information
    //hidden only visible
    let details = [];
    try {
      const fieldValues = this.infoFormGroup.getRawValue();

      if (this.categories && this.categories.length > 0) {
        this.categories.forEach((category) => {
          if (category["isDropdown"]) {
            //values are an array where key is CatID
            if (
              fieldValues[category["dbName"]] &&
              fieldValues[category["dbName"]].length > 0
            ) {
              let detailArr = fieldValues[category["dbName"]];
              if (detailArr.length > 0) {
                detailArr.forEach((detail) => {
                  let currentEl = {};
                  currentEl["detailID"] = detail;
                  currentEl["value"] = 1;
                  currentEl["isVisible"] = 1;
                  details.push(currentEl);
                });
              }
            }
          } else {
            //values are CatID.FieldID
            if (category["options"] && category["options"].length > 0) {
              category["options"].forEach((field) => {
                if (field["isVisible"]) {
                  let currentEl = {};
                  currentEl["detailID"] = field["key"];
                  if (
                    field["type"] == TicketDetailInputType.Checkbox ||
                    field["type"] == TicketDetailInputType.CheckboxHS
                  ) {
                    if (fieldValues[category.dbName + "." + field["key"]]) {
                      currentEl["value"] = 1;
                    } else {
                      currentEl["value"] = 0;
                    }
                  } else {
                    currentEl["value"] =
                      fieldValues[category.dbName + "." + field["key"]];
                  }
                  currentEl["isVisible"] = 1; //(element["isVisible"]) ? 1 : 0;
                  details.push(currentEl);
                }
              });
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    return details;
  }

  getRawValue() {
    return this.infoFormGroup.getRawValue();
  }

  onEdit(category) {
    if (category && category["options"] && category["options"].length > 0) {
      this.openDialog(category);
    } else {
      this.logger$.log("no fields");
    }
  }

  openDialog(category): void {
    var self = this;
    try {
      var hiddenInputs = category["options"].filter((input) => {
        return input ? HiddenFieldTypeID.includes(input["type"]) : false;
      });

      const dialogRef = this.dialog.open(FormDialogComponent, {
        data: {
          inputs: hiddenInputs,
          title: category["placeholder"],
        },
      });
      // width: '250px',

      dialogRef.afterClosed().subscribe((result) => {
        // if (result && result["inputs"]) {
        //   this.inputs = result["inputs"];
        //   // this.allFields["inputs"][0]
        // }
        this.logger$.log("The dialog was closed");
      });
    } catch (error) {}
  }
}

// color
// date
// datetime-local
// email
// month
// number
// password
// search
// tel
// text
// time
// url
// week

@Component({
  selector: "app-example-dialog",
  templateUrl: "./form-group-dialog.component.html",
})
export class FormDialogComponent {
  // @Input() props: any;
  accept: boolean;
  originalData: any;
  // Arr = Array;
  // num:number = 100;
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.originalData = data;
  }

  onCheckChange(event) {
    if (this.data["inputs"]) {
      if (this.data["inputs"][event.source.id]["isVisible"]) {
        this.data["inputs"][event.source.id]["isVisible"] = false;
      } else {
        this.data["inputs"][event.source.id]["isVisible"] = true;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    // this.data = this.originalData;
  }
}
