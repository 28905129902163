import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

export interface Row {
  key: string;
  value: number;
};

@Component({
  selector: 'app-sidebar-item',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIcon,
    MatIconButton,
    MatTableModule
  ],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss'
})
export class SidebarItemComponent implements OnInit, OnChanges {
  @Input() data: any;
  
  @Output() updateButtons = new EventEmitter<Object>();
  
  @ViewChild('addUser', {read: ElementRef}) elementAddUser: ElementRef;
  @ViewChild('removeUser', {read: ElementRef}) elementRemoveUser: ElementRef;

  displayedColumns: string[] = ['key', 'value'];
  // fieldsToNotShow: string[] = ["SupervisorID"];
  rows: Row[] = [];
  total: number = 0;
  addBtnVisibility: boolean = true;
  removeBtnVisibility: boolean = false;
  iconName = 'add_circle'
  
  constructor() {
    console.log("Created LocatorSummaryComponent");
  }

  ngOnInit() {
    try{
      this.initRows();
    } catch(ex) {
      console.log("failed to run ngOnInit:", ex);
    }
  }
  
  ngAfterViewInit(){
    try{
      this.initRows();
    } catch(ex) {
      console.log("failed to run ngAfterViewInit:", ex);
    }
  }
  
  updateButtonState(){
    try{
      // console.log("starting to updateButtonState");
      this.data.isAdded = !this.data.isAdded;
      this.updateButtons.emit({
        data: this.data
      });
      this.initRows();
    } catch(ex) {
      console.log("failed to run ngAfterViewInit:", ex);
    }
  }

  ngOnChanges() {
    try{
      this.initRows();
    } catch(ex) {
      console.log("failed to run ngOnChanges:", ex);
    }
  }


  // preprocessing for mat-table rows
  initRows() {
    let newRows: Row[] = [];
    try {
      if(this.data != null) {
        newRows.push({key: "Supervisor", value: this.data['SupervisorName']});
        for(let field in this.data['ticketInfo']) {
          // if(this.fieldsToNotShow.indexOf(field) == -1){
          newRows.push({key: field, value: this.data['ticketInfo'][field]});
          // }
        }
        if (this.data.isAdded) {
          this.iconName = "remove_circle";
        } else {
          this.iconName = "add_circle";
        }
        // this.updateButtonState(this.data["states"]["addBtnVisibility"], this.data["states"]["removeBtnVisibility"], true);
      }
    } catch(ex) {
      console.log("sidebar-ist-component: initRows: ", ex);
    }
    this.rows = newRows;
  }
}
