<app-fieldside-navigation-bar
  (toggleSidenav)="sidenav.toggle()"
></app-fieldside-navigation-bar>
<mat-drawer-container class="drawer-container" style="height: calc(100% - 64px)" [hasBackdrop]="false">
  <mat-drawer #sidenav class="side-nav shadow rounded-r-xl" mode="push">
    <div fxFlex class="hide-functional-scrollbar menu-item-list">
      <ng-container>
        <div class="menu-header">
          <p
            class="font-rajdhani font-semibold text-[20px] my-0 mb-[4px] uppercase tracking-[0]"
          >
            {{ sidenavTitle }}
          </p>
          <p class="font-montserrat text-[16px] leading-[24px] pb-[10px] m-0">
            {{ sidenavSubTitle }}
          </p>
        </div>
      </ng-container>
      <mat-divider class="divider"></mat-divider>

      <div fxLayout="column" class="menu-item-list">
        <ng-container *ngFor="let route of myWorkRoutes">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="menu-item"
            [ngClass]="{ 'menu-item-selected': route.selected }"
            (click)="handleRouteClick(route)"
          >
            <span>
              <mat-icon class="menu-item-icon" [svgIcon]="route.icon"></mat-icon>
            </span>
  
            <span
              class="menu-item-text mat-subtitle-2"
              >{{ route.title }}</span
            >
          </div>
        </ng-container>
      </div>
    </div>
    
  </mat-drawer>
  <mat-drawer-content>
    <div style="height: 100%; overflow-y: auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- <input #menuToggle type="checkbox" class="menu-toggle-fieldside"/>
<div  class="menu-toggle-hover-fieldside"></div>
<div class="overlay-fieldside"
  (click)='menuToggle'>
</div>

<div fxLayout='row' class="top-nav-showing">
  <div class="content-container-small-screen">
    <router-outlet></router-outlet>
  </div>
</div>

<div fxLayout='column' class="menu-fieldside" >

  <div fxFlex fxLayout='column' fxLayoutGap='24px' style="padding-top: 24px;">

    <ng-container>
        <div class="menu-header">
          <p class="mat-headline app-bottom-4-margin">{{sidenavTitle}}</p>
          <p class="mat-body-2">{{sidenavSubTitle}}</p>
        </div>
        <mat-divider class="app-sidenav-width"></mat-divider>
    </ng-container>

    <div fxLayout='column' class="menu-item-list">
      <ng-container *ngFor="let item of myWorkRoutes">
        <div
          fxLayout='row'
          fxLayoutAlign='start center'
          class="menu-item"
          [ngClass]="{'menu-item-selected': item.selected}"
          (click)="handleRouteClick(item)"
        >
          <mat-icon *ngIf="item.icon" class="menu-item-icon">{{item.icon}}</mat-icon>
          <span  class="menu-item-text mat-subtitle-2">{{item.title}}</span>
        </div>
      </ng-container>
    </div>
  </div>


  <div fxFlex='42px' class="copyright" fxLayout='row' fxLayoutAlign='space-between center'>
    <span>© COMPETERS 2021</span>

    <span>{{appVersion}}</span>
  </div>
</div> -->
