import { FeatureChangeType, MapFeatureChange } from './map-feature-change';
import VectorSource from 'ol/source/Vector';
import { Collection, Feature } from 'ol';
import {
  CollectionFlattener,
  FeatureOrCollection,
} from './collection-flattener';
import _ from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OpenLayersUtilities } from './open-layers-utilities';
import { map } from 'rxjs/operators';

export class UndoRedo {
  private undoStack: MapFeatureChange<FeatureOrCollection>[] = [];
  private redoStack: MapFeatureChange<FeatureOrCollection>[] = [];
  private stackLengths$ = new Subject<void>();

  private flattener = new CollectionFlattener();
  private openLayersUtilities = new OpenLayersUtilities();

  public addChange(change: MapFeatureChange<Collection<Feature>>) {
    if (change.changeType === FeatureChangeType.added) {
      const features = this.flattener.flattenFeature(change.feature);
      features.forEach((f) => {
        f.setId(_.uniqueId('new_'));
      });
    }
    this.redoStack = [];
    this.undoStack.push(change);
    this.stackLengths$.next();
  }

  public undo() {
    if (this.undoStack.length > 0) {
      this.redoStack.push(this.handleUndoRedo(this.undoStack.pop()));
    } else {
      console.log('nothing to undo');
    }
    this.stackLengths$.next();
  }

  public redo() {
    if (this.redoStack.length > 0) {
      this.undoStack.push(this.handleUndoRedo(this.redoStack.pop()));
    } else {
      console.log('nothing to redo');
    }
    this.stackLengths$.next();
  }

  public clear() {
    while (this.undoStack.length > 0) {
      this.undoStack.pop();
    }
    while (this.redoStack.length > 0) {
      this.redoStack.pop();
    }
    this.stackLengths$.next();
  }

  public hasChanges() {
    return this.undoStack.length > 0 || this.redoStack.length > 0;
  }

  public empty(): MapFeatureChange<FeatureOrCollection>[] {
    const copy = [...this.undoStack];
    this.clear();
    this.stackLengths$.next();
    return copy;
  }

  public nukeChanges() {
    this.redoStack = [];
    this.undoStack = [];
    this.stackLengths$.next();
  }

  public rollback() {
    this.redoStack = [];
    while(this.undoStack.length > 0) {
      this.handleUndoRedo(this.undoStack.pop());
    }
    this.undoStack = [];
    this.stackLengths$.next();
  }

  public reportChanges() {
    return {
      undoStack: [...this.undoStack],
      redoStack: [...this.redoStack],
    };
  }

  private handleUndoRedo(
    change: MapFeatureChange<FeatureOrCollection>,
  ): MapFeatureChange<FeatureOrCollection> {
    const { addFeatureToSource, removeFeatureFromSource } =
      this.openLayersUtilities;
    const { feature, previousFeature, source, changeType } = change;
    const { flattenFeature: flatten } = this.flattener;
    try {
      switch (changeType) {
        case FeatureChangeType.added:
          removeFeatureFromSource(flatten(feature), source);
          return new MapFeatureChange(
            FeatureChangeType.deleted,
            feature,
            undefined,
            source,
          );
        case FeatureChangeType.updated:
          removeFeatureFromSource(flatten(feature), source);
          addFeatureToSource(flatten(previousFeature), source);
          return new MapFeatureChange(
            FeatureChangeType.updated,
            previousFeature,
            feature,
            source,
          );
        case FeatureChangeType.deleted:
          addFeatureToSource(flatten(feature), source);
          return new MapFeatureChange(
            FeatureChangeType.added,
            feature,
            undefined,
            source,
          );
        default:
          break;
      }
    } catch (e) {
      console.error(e);
      console.log('failed to undo/redo change');
    }
  }

  public get stackLengths(): Observable<[UndoLength, RedoLength]> {
    return this.stackLengths$.pipe(
      map(() => [this.undoStack.length, this.redoStack.length]),
    );
  }
}

export type UndoLength = number;
export type RedoLength = number;
