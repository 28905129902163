import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { loadModules } from "esri-loader";
import {
  DefaultSubLayer,
  EsriCredential,
  Group,
  Layer,
} from "../../EsriMapModels";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class EsriMapLayerService {
  public customLayerObjs: Layer[] = [];
  private defaultOnLayers = {};
  private layerGroups: Layer[] = [];

  constructor(private logger$: LoggerService) {}

  resetService() {
    this.customLayerObjs = [];
    this.defaultOnLayers = {};
    this.layerGroups = [];
  }

  // functions for authenticating layers

  generateEsriTokens(credentials: EsriCredential[]) {
    return new Observable((subscriber) => {
      try {
        var promiseArr = [];

        for (let i = 0; i < credentials.length; i++) {
          promiseArr.push(
            this.generateEsriToken(
              credentials[i].serviceUrl,
              credentials[i].tokenGenerationUrl,
              credentials[i].username,
              credentials[i].password
            )
          );
        }

        Promise.all(promiseArr).then(function (value) {
          subscriber.next(value);
          subscriber.complete();
        });
      } catch (ex) {
        this.logger$.error("error:", ex);
      }
    });
  }

  private generateEsriToken(serverUrl, tokenAuthUrl, username, password) {
    return new Promise(function (resolve, reject) {
      try {
        var identifyManager = null;

        loadModules([
          "esri/identity/IdentityManager",
          "esri/identity/ServerInfo",
        ])
          .then(function (value) {
            identifyManager = value[0];
            let ServerInfo = value[1];

            var serverInfo = new ServerInfo();
            serverInfo.server = serverUrl;
            serverInfo.tokenServiceUrl = tokenAuthUrl;
            var userInfo = {
              username: username,
              password: password,
            };

            // Get a token
            return identifyManager
              .generateToken(serverInfo, userInfo)
              .then(function (value) {
                var tokenValue = value.token;

                // Register the token
                identifyManager.registerToken({
                  server: serverUrl,
                  token: tokenValue,
                });
                resolve(1);
              })
              .catch(function (error) {
                console.error("Failed to generate token: ", error);
                resolve(0);
              });
          })
          .catch(function (error) {
            console.error("Failed to generate token: ", error);
            resolve(0);
          });
      } catch (error) {
        console.error("Failed to generate token: ", error);
        resolve(0);
      }
    });
  }

  // functions for making the layer

  createLayerGroups(groups: Group[]) {
    try {
      groups.forEach((group) => {
        var newGroup: Layer = {
          name: group.name,
          subLayers: [],
          isChecked: true,
          isParentChecked: true,
          layerContent: null,
          groupID: group.groupID,
        };
        this.layerGroups.push(newGroup);
      });
    } catch (error) {
      this.logger$.error(error);
    }
  }

  createDefaultOnLayersObj(defaultOnSubLayers: DefaultSubLayer[]) {
    try {
      defaultOnSubLayers.forEach((subLayer) => {
        if (!this.defaultOnLayers.hasOwnProperty(subLayer.layerID)) {
          this.defaultOnLayers[subLayer.layerID] = {};
        }
        this.defaultOnLayers[subLayer.layerID][subLayer.subLayerID] =
          subLayer.isVisible;
      });
    } catch (error) {
      this.logger$.error(error);
    }
  }

  toggleDefaultOnLayers(layers) {
    layers.forEach((layer) => {
      if (
        layer.dbLayerID &&
        this.defaultOnLayers.hasOwnProperty(layer.dbLayerID)
      ) {
        this.toggleDefaultOnLayer(layer, layer.dbLayerID);
      }
    });
  }

  toggleDefaultOnLayer(layer, dbLayerID) {
    if (this.defaultOnLayers[dbLayerID].hasOwnProperty(layer.id)) {
      layer.visible = this.defaultOnLayers[dbLayerID][layer.id] == 1;
    }
    if (layer.sublayers) {
      layer.sublayers.forEach((sublayer) => {
        this.toggleDefaultOnLayer(sublayer, dbLayerID);
      });
    }
  }

  createLayerTree(layers) {
    try {
      layers.forEach((layer) => {
        var newLayer = this.traverseLayers(layer, true);
        this.customLayerObjs.push(newLayer);
      });
    } catch (error) {
      this.logger$.error(error);
    }
  }

  traverseLayers(layer, isParentChecked): Layer {
    var name = layer.title;
    if (layer.dbLayerName) {
      name = layer.dbLayerName;
    }
    var newLayer: Layer = {
      name: name,
      isChecked: layer.visible,
      isParentChecked: isParentChecked,
      subLayers: [],
      layerContent: layer,
    };
    if (layer.dbLayerID) {
      newLayer["layerID"] = layer.dbLayerID;
    }
    if (layer.groupID) {
      newLayer["groupID"] = layer.groupID;
    }

    if (layer.sublayers != null && layer.sublayers.items != null) {
      layer.sublayers.items.forEach((subLayer) => {
        var newSubLayer = this.traverseLayers(
          subLayer,
          newLayer.isChecked && newLayer.isParentChecked
        );
        newLayer.subLayers.push(newSubLayer);
      });
    }
    return newLayer;
  }

  addLayersToGroup() {
    try {
      this.customLayerObjs.forEach((layer) => {
        if (layer.groupID && layer.groupID > 0) {
          for (let i = 0; i < this.layerGroups.length; i++) {
            if (this.layerGroups[i].groupID == layer.groupID) {
              layer.isParentChecked = this.layerGroups[i].isChecked;
              this.layerGroups[i].subLayers.push(layer);
              break;
            }
          }
        } else {
          this.layerGroups.push(layer);
        }
      });
    } catch (error) {
      this.logger$.error(error);
    }
    return this.layerGroups;
  }

  applyLayersToMap(map, layers) {
    let newMap = map;
    try {
      if (map && layers) {
        for (let i = 0; i < layers.length; i++) {
          newMap.add(layers[i]);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return newMap;
  }

  // other functions

  getVisibleLayers(layer, isParentVisible, visibleLayerIDs) {
    if (layer.sublayers != null && layer.sublayers.items != null) {
      layer.sublayers.items.forEach((subLayer) => {
        visibleLayerIDs = this.getVisibleLayers(
          subLayer,
          layer.visible && isParentVisible,
          visibleLayerIDs
        );
      });
    } else {
      if (isParentVisible && layer.visible) {
        visibleLayerIDs.push(layer.id);
      }
    }
    return visibleLayerIDs;
  }
}
