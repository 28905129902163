import { Injectable } from "@angular/core";
import { from, Observable, of, Subject } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { api, apiKeys } from "src/app/ENDPOINTS";
import {
  FormTemplateField,
  FormTemplateGroup,
  FormTemplateOptionValues,
  FormTemplateView,
  FormTemplateViews,
} from "../shared/forms/form-input-template/form-input-template.component";
import { CreateTicket } from "./CreateTicket";
import {
  ActionMessage,
  Actions,
} from "../core/component-messaging/action-message";
import { LoggerService } from "../core/services/logger/logger.service";
import { AdminLookupService } from "../core/admin/admin-lookup.service";
import { CompletionsStoreService } from "../core/admin/completions-store.service";
import { ProgressBarService } from "../shared/progress-bar/progress-bar.service";
import { SnackbarService } from "../shared/snackbar/snackbar.service";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { CreateTicketFormTypes } from "./CreateTicketModel";
import { DEFAULT_FORM_TEMPLATE_OBJECT } from "../shared/forms/form-input-template/defaultFormTemplate";
import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { SnackbarType } from "../shared/snackbar/snackbar/snackbar";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { SettingID } from "../core/services/user/setting";
import { UserService } from "../core/services/user/user.service";
import { formatDate } from "@angular/common";
import { DatetimeService } from "../core/services/datetime/datetime.service";
import { v4 as uuidv4 } from "uuid";

export interface GroupTemplate {
  dbName: string;
  placeholder: string;
  isRequired: boolean;
  options: any;
  canEdit: boolean;
  isDropdown: boolean;
}
export interface FieldTempalte {
  type: string;
  key: string;
  label: string;
  placeholder?: string;
  value?: string;
  isRequired?: boolean;
  matches?: string;
  isVisible: boolean;
}

export enum OptionsFillID {
  companyName = 1,
  utilities = 2,
  typeOfWork = 3,
  availableSchedulerResources = 4,
  primaryDetailFieldRules = 5,
  excavatorContacts = 6,
  ticketTags = 7,
  calltype = 8,
  primaryDetailFieldOptions = 9,
}

export var HiddenFieldTypeID = [5, 6, 7, 8];

export enum FillStageID {
  Default = 1,
  PrefillOptional = 2,
  PrefillRequired = 3,
}

export enum TicketDetailInputType {
  Checkbox = 1,
  String = 2,
  Integer = 3,
  Float = 4,
  CheckboxHS = 5,
  StringHS = 6,
  IntegerHS = 7,
  FloatHS = 8,
  Photo = 9,
  Datepicker = 10,
  Singleselect = 11,
  Multiselect = 12,
  Autocomplete = 13,
  DateTimePicker = 17,
}

const DEFAULT_FIELD: FormTemplateField = {
  key: "noFieldDefault",
  appearance: "fill",
  label: " ",
  placeholder: " ",
  inputTypeID: 0,
  fieldOrder: 1,
  matches: "",
  isReadOnly: false,
  isRequired: false,
  width_xs: 100,
  width_sm: 100,
  width_md: 50,
  width_lg: 50,
  width_xl: 50,
  isVisible: true,
};

const DEFAULT_GROUP: FormTemplateGroup = {
  key: "noGroupsDefault",
  header: "Added",
  groupOrder: 1, //TODO needs design.
  fields: {
    defaultField: DEFAULT_FIELD,
  },
};

export const CREATE_TICKET_UID_IDENTIFIER = "create-ticket-";

export function generateUID() {
  return uuidv4();
}

@Injectable({
  providedIn: "root",
})
export class CreateTicketComponentService {
  className = "CreateTicketComponentService";
  createFormTicketObj: CreateTicket;
  settings = {};
  private onDataChange: Subject<ActionMessage>;
  clientUtilities = [];

  constructor(
    private logger$: LoggerService,
    private lookup$: AdminLookupService,
    private completions$: CompletionsStoreService,
    private formbuilder$: FormBuilder,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private utilocateApiService: ApiService,
    private userService: UserService,
    private datetime: DatetimeService,
  ) {
    this.onDataChange = new Subject<ActionMessage>();
    this.createFormTicketObj = new CreateTicket(); // todo: make into form builder class
  }
  /*Returns an observable (any) of the data change*/
  getOnDataChange(): Observable<any> {
    return this.onDataChange.asObservable();
  }
  /*accepts data as a parameter and adds it to the current object*/
  sendDataChange(data: any) {
    this.onDataChange.next(new ActionMessage(Actions.UPDATE_METADATA, data));
  }

  /*Returns an observable(any) of ticket data change*/
  getOnTicketDataChange(): Observable<any> {
    return this.completions$.getOnTicketDataChange();
  }
  /*Returns an observable(any) about schedule data changes*/
  getOnScheduleDataChange(): Observable<any> {
    return this.completions$.getOnScheduleDataChange();
  }
  /*Returns an observable (any) for document data changes*/
  getOnDocumentDataChange(): Observable<any> {
    return this.completions$.getOnDocumentDataChange();
  }
  /*Resets the body to the default settings*/
  resetBodyDefault() {
    this.completions$.resetBodyDefault();
  }

  /*Tries to reset the utilities for the ticket, if successful, returns a response
If catches, returns false.*/
  async resetUtilities() {
    let response = false;
    try {
      let rowKey = this.getCurrentCreateTicketID();
      let utiltyData = {
        formType: CreateTicketFormTypes.utilities,
        value: [],
        utilityViews: {},
        rowKey: rowKey,
      };
      let action = new ActionMessage(Actions.UPDATE_UTILITY_DATA, utiltyData);
      response = await this.completions$.updateTicketData(action).toPromise();
    } catch (error) {
      this.logger$.error(error.message);
      response = false;
    }
    return response;
  }

  /*Returns a promise that cleans rows */
  cleanRows() {
    return new Promise((resolve) => {
      this.completions$.cleanRows().then((val) => {
        resolve(val);
      });
    });
  }

  //validators.required|pattern|email|minlength|maxlength
  //check phonenumber, email
  //generates a form group based on view object

  /*Generates a form group based on view object ->
   *there are many things happening in this function.
   *Should be broken down into smaller functions.
   *(currently about 142 lines of code) */
  private async generateFormGroup(view) {
    try {
      let formGroup = {};
      let result = {};
      let filteredOptionsArr = [];
      let selectOptionsArr = [];
      let generatedGroup: FormGroup;

      if (view && view["groups"] && Object.keys(view["groups"]).length > 0) {
        let i: number = 1;
        for (let groupkey in view["groups"]) {
          if (
            view["groups"][groupkey] &&
            view["groups"][groupkey]["fields"] &&
            Object.keys(view["groups"][groupkey]["fields"]).length > 0
          ) {
            let fillStageID = 0;
            let groupFormGroup = {};
            if (view["groups"][groupkey]["fillStageID"]) {
              fillStageID = view["groups"][groupkey]["fillStageID"];
            }
            let fields: FormTemplateField = view["groups"][groupkey]["fields"];
            for (let fieldKey in fields) {
              let controlKey: string = fields[fieldKey]["key"];
              let controlKeyToLower: string = controlKey.toLowerCase();
              let inputTypeID: number = fields[fieldKey]["inputTypeID"];
              // set tabindex
              if (view.key == "ticketSummary") {
                view["groups"][groupkey]["fields"][fieldKey]["tabindex"] = -1;
              } else {
                view["groups"][groupkey]["fields"][fieldKey]["tabindex"] = i++;
              }

              // autocomplete 13
              if (inputTypeID == TicketDetailInputType.Autocomplete) {
                //add to controls that are added to.
                //add observable to auto complete.
                let path = {
                  controlKey: controlKey,
                  groupkey: groupkey,
                  fieldKey: fieldKey,
                };

                filteredOptionsArr.push(path);
              }
              if (
                inputTypeID == TicketDetailInputType.Multiselect ||
                inputTypeID == TicketDetailInputType.Singleselect
              ) {
                //add to controls that are added to.
                //add observable to auto complete.
                let path = {
                  controlKey: controlKey,
                  groupkey: groupkey,
                  fieldKey: fieldKey,
                };
                selectOptionsArr.push(path);
              }

              if (inputTypeID == TicketDetailInputType.DateTimePicker) {
                formGroup[controlKey + "Time_Ignore_"] = ["", []];
              }

              // create list of validators
              let validators = [];

              //custom based on key
              if (controlKeyToLower.indexOf("phone") > -1) {
                validators.push(Validators.minLength(10));
              } else if (controlKeyToLower.indexOf("email") > -1) {
                validators.push(Validators.email);
              }

              if (controlKeyToLower == "eventstartdatetime") {
                fields[fieldKey]["maxDateKey"] = "EventEndDateTime";
              }
              if (controlKeyToLower == "eventenddatetime") {
                fields[fieldKey]["minDateKey"] = "EventStartDateTime";
              }

              //generic
              if (fields[fieldKey]["isRequired"]) {
                validators.push(Validators.required);
                validators.push(this.noWhitespaceValidator);
              }

              //regex
              if (fields[fieldKey]["matches"]) {
                validators.push(
                  Validators.pattern('fields[fieldKey]["matches"]'),
                );
              }

              //add to group instead of overall
              if (fillStageID == 3) {
                groupFormGroup[controlKey] = ["", validators];
              }
              formGroup[controlKey] = ["", validators];
            }

            if (fillStageID == 3 && groupFormGroup) {
              //fill stage 3 means required 1 minimum field
              let key = "FSID-3-" + view["groups"][groupkey].key;
              let groupFormGroupBuild = this.formbuilder$.group(groupFormGroup);
              groupFormGroupBuild.setValidators([this.hasOneFieldRequired()]);
              formGroup[key] = groupFormGroupBuild;
            }
          } else {
            result["error"] = true;
            this.logger$.log("no fields");
          }
        }

        // result = this.formbuilder$.group(formGroup);
        generatedGroup = this.formbuilder$.group(formGroup);

        //for autocomplete
        //now that form group is created. Setup observables for data
        //formcontrol value changes = filteroptions observable
        //map it to a function that returns results
        //use id to get list of stuff to filter during each filter
        view = await this.addFilteredOptions(
          view,
          filteredOptionsArr,
          generatedGroup,
        );
        view = await this.addselectOptions(view, selectOptionsArr);

        view["formGroup"] = generatedGroup;
      } else {
        result["error"] = true;
        this.logger$.log("no groups or no view");
      }

      return view;
      // return result;
    } catch (error) {
      this.logger$.error(error);
      return false;
    }
  }

  /*Validates that one of the values has been filled in, as that is the minimum.
   *If it exists, return, if not, return an error*/
  public hasOneFieldRequired(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      let valid = false;
      if (group && group.controls && Object.keys(group.controls).length > 0) {
        for (var control in group.controls) {
          if (
            group.controls[control].value &&
            group.controls[control].value != ""
          ) {
            valid = true;
          }
        }
      }
      if (valid) {
        return;
      } else {
        return { Invalid: "Requires min. 1 value to be filled" };
      }
    };
  }

  /*Validates that the value is not whitespace, if it is, returns an error, otherwise, returns.*/
  noWhitespaceValidator(control: FormControl) {
    let isWhitespace: boolean = false;
    try {
      if (control && control.value) {
        if (!Array.isArray(control.value)) {
          if (typeof control.value === "object" && control.value["text"]) {
            isWhitespace = control.value["text"].trim().length === 0;
          } else if (typeof control.value === "string") {
            isWhitespace = (control.value || "").trim().length === 0;
          }
        }
      }
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    } catch (error) {
      this.logger$.log("no white spce val");
      return false;
    }
  }

  /*Adds options to auto complete and returns the view,
   * Catches an error if something goes wrong*/

  //add options to auto complete
  private async addFilteredOptions(
    view,
    filteredOptionsArr,
    generatedGroup: FormGroup,
  ) {
    try {
      for (let control of filteredOptionsArr) {
        let field: FormTemplateField =
          view["groups"][control["groupkey"]]["fields"][control["fieldKey"]];
        let options: any = await this.getFilterOptions(field);
        field["filteredOptions"] = generatedGroup.controls[
          control["controlKey"]
        ].valueChanges.pipe(
          startWith(""),
          map((value) =>
            typeof value === "string" ? value : value ? value.text : value,
          ),
          map((text) => (text ? this._filter(text, options) : options.slice())),
        );
      }
    } catch (error) {
      this.logger$.error("addFilteredOptions: " + error.message);
    }
    return view;
  }

  async refreshFilteredOptions(view, groupKey, fieldKey) {
    try {
      let field: FormTemplateField =
        view["groups"][groupKey]["fields"][fieldKey];
      let options: any = await this.getFilterOptions(field);
      //required if want to clear and still have autocomplete
      field["filteredOptions"] = view["formGroup"].controls[
        fieldKey
      ].valueChanges.pipe(
        startWith(""),
        map((value: any) =>
          typeof value === "string" ? value : value ? value.text : value,
        ),
        map((text: any) =>
          text ? this._filter(text, options) : options.slice(),
        ),
      );
    } catch (error) {
      this.logger$.error("refreshFilteredOptions: " + error.message);
    }
    return view;
  }

  //add options to select
  async refreshExcavatorContacts(formField: FormTemplateField, excavatorID) {
    try {
      formField["selectOptions"] = await this.getExcavatorContacts(
        formField.options.optionsFillID,
        excavatorID,
      );
    } catch (error) {
      this.logger$.error("addSelectOptions: " + error.message);
    }
  }
  private async getExcavatorContacts(id: number, excavatorID: number) {
    // return new Promise((resolve) => {
    let options = [];
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: 1,
        fillOptionID: id,
        ExcavatorID: excavatorID,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      let result = apiResult["body"];

      if (result && result["status"] === 1 && result["value"]) {
        if (result["value"].length > 0) {
          for (let optionObj of result["value"]) {
            options.push({
              text: optionObj["text"],
              value: optionObj["value"],
            });
          }
        }
      }
    } catch (error) {
      this.logger$.log("No/invalid excavator contacts");
    }
    return options;
  }

  //add options to select
  private async addselectOptions(view, selectOptionsArr) {
    try {
      for (let control of selectOptionsArr) {
        let field: FormTemplateField =
          view["groups"][control["groupkey"]]["fields"][control["fieldKey"]];
        let options: any = await this.getFilterOptions(field);
        field["selectOptions"] = options;
      }
    } catch (error) {
      this.logger$.error("addSelectOptions: " + error.message);
    }
    return view;
  }

  //filter when type in autocomplete field
  private _filter(text: string, values: any[]): any {
    const filterValue = text.toLowerCase();
    return values.filter(
      (option) => option.text.toLowerCase().indexOf(filterValue) > -1,
    );
  }

  //generates views and formroups and returns views
  getCreateTicketTemplate(): Observable<any> {
    return new Observable((subscriber) => {
      //get clientID
      //collect each promise.
      var views = {};
      var viewPromiseArr = [];
      try {
        this.getTicketTemplateRow()
          .then((template) => {
            if (template && template.views) {
              for (let viewKey in template.views) {
                let view: FormTemplateView = template.views[viewKey];
                viewPromiseArr.push(this.generateFormGroup(view));
              }
            } else {
              for (let viewKey in DEFAULT_FORM_TEMPLATE_OBJECT.views) {
                let view: FormTemplateView =
                  DEFAULT_FORM_TEMPLATE_OBJECT.views[viewKey];
                viewPromiseArr.push(this.generateFormGroup(view));
              }
            }
            Promise.all(viewPromiseArr)
              .then((results) => {
                if (results && results.length > 0) {
                  for (let i in results) {
                    views[results[i]["key"]] = results[i];
                  }
                }
                return this.getCommonDetailViews(); //get common details
              })
              .then((commonViews) => {
                let msg = {
                  views: views,
                  commonViews: commonViews,
                };
                subscriber.next(msg);
                subscriber.complete();
              })
              .catch((ex) => {
                subscriber.next(false);
                subscriber.complete();
              });
          })
          .catch((error) => {
            this.logger$.error(error.message);
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error("getCreateTicketTemplate" + error.message);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private async getCommonDetailViews() {
    try {
      //add common utility type
      let utilityRows = [
        {
          Archived: 0,
          CompanyID: null,
          FooterText: "",
          HeaderText: "",
          LocatingForGroupID: 0,
          LogoFile: "",
          UtilityDesc: "Ticket Details",
          UtilityID: 0,
          UtilityName: "Ticket Details",
          UtilityType: 0,
          usesPrimary: 0,
        },
      ];

      let utilityTypes = [utilityRows[0].UtilityType];
      let primaryCatRows =
        await this.getPrimaryDetailCategories(utilityTypes).toPromise();
      //get primarycatids
      let primaryCatIDs = [];
      if (primaryCatRows && primaryCatRows.length > 0) {
        primaryCatRows.forEach((row) => {
          primaryCatIDs.push(row["PrimaryDetailCategoryID"]);
        });
      }
      let primaryFieldRows = [];
      if (primaryCatIDs && primaryCatIDs.length > 0) {
        primaryFieldRows =
          await this.getPrimaryDetailFields(primaryCatIDs).toPromise();
      }
      let fieldRules = await this.getPrimaryDetailFieldRules(primaryCatIDs);
      let fieldOptions =
        await this.getPrimaryDetailFieldOptions(primaryFieldRows);

      //common
      let utilityDetailViews = await this.convertUtilityToFormTemplate(
        utilityRows,
        primaryCatRows,
        primaryFieldRows,
        fieldRules,
        fieldOptions,
      );

      let utilityViewsArr = [];
      if (Object.keys(utilityDetailViews.views).length > 0) {
        for (let viewKey in utilityDetailViews.views) {
          let view: FormTemplateView = utilityDetailViews.views[viewKey];
          let viewsObj = await this.generateFormGroup(view);
          if (viewsObj) {
            utilityViewsArr.push(viewsObj);
          }
        }
      }
      return utilityViewsArr;
    } catch (error) {
      this.logger$.error(error.message);
      return [];
    }
  }
  /* gets the row from the ticket 
  if successful, it parses it and returns the template,
  if not sucessful, it returns the value false.
  Duplicated- used also for ticket-details*/
  private async getTicketTemplateRow() {
    let template: any = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: 3,
        FormInputTemplateTypeID: 1,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      let result = apiResult["body"];
      if (result.value && result.value[0] && result.value[0]["Template"]) {
        template = JSON.parse(result.value[0]["Template"]);
      }
    } catch (error) {
      this.logger$.error(error);
      template = false;
    }
    return template;
  }

  //get filter options from template or db
  private async getFilterOptions(field: FormTemplateField) {
    let options = [];
    try {
      if (field && field["options"]) {
        //if has values use values in options
        if (field["options"]["hasValues"]) {
          let result = field["options"]["values"];
          return result;
        } else {
          return await this.getFilterOptionsDB(
            field["options"]["optionsFillID"],
          );
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return options;
  }

  //can .toPromise() an observable run as .then/await
  //get filter options from db
  async getFilterOptionsDB(id: number) {
    // return new Promise((resolve) => {
    let options = [];
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: 1,
        fillOptionID: id,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      //if i get a lastValue back, there is more data
      if (apiResult["body"].lastValue) {
        apiResult = await this.getNextDataSet(id, apiResult, apiKey);
      }
      let result = apiResult["body"];

      try {
        if (!apiResult["ok"]) {
          let snackbarConfig: MatSnackBarConfig = {
            duration: 2500,
            horizontalPosition: "center",
            verticalPosition: "top",
          };
          this.snackBarService.openSnackbar(
            "Unable To Load All Information.",
            SnackbarType.error,
            "close",
          );
        }
      } catch (error) {}

      if (result && result["status"] === 1 && result["value"]) {
        if (result["value"].length > 0) {
          for (let optionObj of result["value"]) {
            options.push({
              text: optionObj["text"],
              value: optionObj["value"],
            });
          }
        }
      }
      if (id == 2) {
        this.clientUtilities = options;
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return options;
  }

  /**
   * A recursive function to get more excavators from the api
   * @param id
   * @param apiResult
   * @param apiKey
   * @returns
   */
  async getNextDataSet(id, apiResult, apiKey) {
    const apiValue = {
      action: 1,
      fillOptionID: id,
      startName: apiResult["body"].lastValue,
    };

    const url = apiKeys.u2[apiKey];
    const type = api[url].type;

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKey,
      API_TYPE: type,
      API_BODY: apiValue,
    };

    let newAPIResult =
      await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
    newAPIResult["body"]["value"] = [
      ...apiResult["body"]["value"],
      ...newAPIResult["body"]["value"],
    ];
    if (newAPIResult["body"].lastValue) {
      newAPIResult = await this.getNextDataSet(id, newAPIResult, apiKey);
    }
    return newAPIResult;
  }

  async getAllCompanyInfo() {
    //1 is option to get company information
    return await this.getFilterOptionsDB(1);
  }

  updateTicketData(message: ActionMessage): Observable<any> {
    try {
      let curUID = sessionStorage.getItem(
        localStorageKeys.CURRENT_CREATE_TICKET_KEY,
      );
      return this.completions$.updateTicketData(
        new ActionMessage(message.action, { rowKey: curUID, ...message.data }),
      );
    } catch (error) {
      this.logger$.error(error.message);
      return of(false);
    }
  }

  async saveCompany(excavatorDetails) {
    let result = false;
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: 4,
        ExcavatorDetails: excavatorDetails,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      let apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      if (
        apiResult["body"] &&
        apiResult["body"]["status"] &&
        parseInt(apiResult["body"]["status"]) == 1
      ) {
        result = apiResult["body"];
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  //get admin settings.
  //pass in settingID want to get.
  async getAdminSettings(settingID) {
    let row = {};
    try {
      const where = { SettingID: settingID };
      let result = await this.lookup$.getLookupTableRows(
        ["tbAdmin_Settings"],
        where,
      );
      if (result && result[0] && result[0]["rows"] && result[0]["rows"][0]) {
        row = result[0]["rows"][0];
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return row;
  }

  getAdminCallTypeToUtility(callTypeID) {
    return new Observable((subscriber) => {
      const where = { CallTypeID: callTypeID };
      from(
        this.lookup$.getLookupTableRows(["tbAdmin_UtilityToCallType"], where),
      ).subscribe((result) => {
        if (
          result &&
          result[0] &&
          result[0]["rows"] &&
          result[0]["rows"].length > 0
        ) {
          subscriber.next(result[0]["rows"]);
        } else {
          subscriber.next(false);
        }
        subscriber.complete();
      });
    });
  }

  async setupCreateTicketSettings() {
    try {
      this.settings[SettingID.MULTI_CONTACT_EXCAVATOR] =
        await this.userService.isSettingActive(
          SettingID.MULTI_CONTACT_EXCAVATOR,
        );
    } catch (error) {
      console.error(error.message);
    }
  }

  //choose how to handle updating completion data
  updateCompletionsData(data) {
    try {
      if (data && data["event"] && data["field"] && data["field"]["key"]) {
        let fieldValue = data["field"]["value"];
        let event = data["event"];
        if (
          fieldValue["inputTypeID"] == TicketDetailInputType.Singleselect ||
          fieldValue["inputTypeID"] == TicketDetailInputType.Multiselect
        ) {
          if (fieldValue["options"] && fieldValue["options"]["optionsFillID"]) {
            let fillID = fieldValue["options"]["optionsFillID"];
            if (fillID == OptionsFillID.utilities) {
              this.handleUpdateCompletionUtilities(event);
            } else if (fillID == OptionsFillID.calltype) {
              this.handleUpdateCompletionsCallType(event);
            }
          }
        }
      }
    } catch (error) {
      this.logger$.error("updateCompletionsData: " + error.message);
    }
  }

  //handle utilities updated
  async handleUpdateCompletionUtilities(event) {
    try {
      this.progressBarService.start();
      let rowKey = this.getCurrentCreateTicketID();

      //get current utilities
      let currentUtilities = await this.getCurrentUtilities();
      let currentUtilityIDArr = [];
      if (
        currentUtilities &&
        Array.isArray(currentUtilities) &&
        currentUtilities.length > 0
      ) {
        currentUtilities.forEach((utility) => {
          currentUtilityIDArr.push(utility["UtilityID"].toString());
        });
      }

      if (event.length > 0) {
        let newUtilityIDArr = [];
        let utilityIDArr = [];
        event.forEach((utility) => {
          if (this.doesUtilityExistsInClientUtilities(utility["value"])) {
            //only add new utilities not in list.
            if (!currentUtilityIDArr.includes(utility["value"].toString())) {
              newUtilityIDArr.push(utility["value"]);
            }
            utilityIDArr.push(utility["value"].toString());
          }
        });
        //with utilityID get their values needed
        //name, id, polygon code //primarycat //primary fields
        let utilityViews = await this.getUtilityViews(newUtilityIDArr);

        let value = [];
        if (utilityIDArr.length > 0) {
          utilityIDArr.forEach((uid) => {
            value.push({
              UtilityID: uid,
              UtilityType: uid,
            });
          });
        }

        let utiltyData = {
          formType: CreateTicketFormTypes.utilities,
          value: value,
          utilityViews: {
            newViews: utilityViews,
            activeViews: utilityIDArr,
          },
          rowKey: rowKey,
        };
        let action = new ActionMessage(Actions.UPDATE_UTILITY_DATA, utiltyData);
        //this will trigger change detection for components listening
        //components listening will update their views accordingly
        this.completions$.updateTicketData(action).toPromise();
      } else {
        //what to do if all deselected.
        let utiltyData = {
          formType: CreateTicketFormTypes.utilities,
          value: [],
          utilityViews: {},
          rowKey: rowKey,
        };
        let action = new ActionMessage(Actions.UPDATE_UTILITY_DATA, utiltyData);
        this.completions$.updateTicketData(action).toPromise();
      }
      this.progressBarService.stop();
    } catch (error) {
      this.logger$.error("handleUpdateCompletionUtilities: " + error.message);
      this.progressBarService.stop();
    }
  }

  doesUtilityExistsInClientUtilities(utilityID): boolean {
    let exists = false;
    try {
      if (this.clientUtilities.length > 0) {
        for (let index = 0; index < this.clientUtilities.length; index++) {
          if (this.clientUtilities[index]["value"] == utilityID) {
            exists = true;
            break;
          }
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return exists;
  }

  //handle calltype updated
  async handleUpdateCompletionsCallType(event) {
    try {
      this.progressBarService.start();
      let rowKey = this.getCurrentCreateTicketID();
      let callTypeData = {
        formType: CreateTicketFormTypes.ticket,
        value: { CallTypeID: event.value },
        rowKey: rowKey,
      };
      let action = new ActionMessage(
        Actions.UPDATE_TICKET_CALLTYPE,
        callTypeData,
      );
      await this.completions$.updateTicketData(action).toPromise();
      this.progressBarService.stop();
    } catch (error) {
      this.logger$.error("handleUpdateCompletionsCallType: " + error.message);
      this.progressBarService.stop();
    }
  }

  async getCompanyInfoDB(id: number) {
    // return new Promise((resolve) => {
    let excavatorInfo = [];
    try {
      let apiKey = apiKeys.u2.createTicketController;
      // var apiValue = apiValue;
      const apiValue = {
        action: 2,
        ExcavatorID: id,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      let apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      let result = apiResult["body"];

      if (result && result["status"] === 1 && result["value"]) {
        excavatorInfo = result["value"];
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return excavatorInfo;
  }

  getCurrentCreateTicketID() {
    try {
      return sessionStorage.getItem(localStorageKeys.CURRENT_CREATE_TICKET_KEY);
    } catch (error) {
      this.logger$.error("getCurrentCreateTicketID" + error.message);
      return false;
    }
  }

  removeCurrentCreateTicketID() {
    try {
      return sessionStorage.removeItem(
        localStorageKeys.CURRENT_CREATE_TICKET_KEY,
      );
    } catch (error) {
      this.logger$.error("removeCurrentCreateTicketID" + error.message);
      return false;
    }
  }

  async getCurrentDigsite() {
    return await this.getCurrentTicketInfo("DigsiteDetails");
  }

  async getCurrentUtilities() {
    return await this.getCurrentTicketInfo("UtilityDetails");
  }

  async getCurrentExcavatorDetails() {
    return await this.getCurrentTicketInfo("ExcavatorDetails");
  }

  async getCurrentTicketDetails() {
    return await this.getCurrentTicketInfo("TicketDetails");
  }

  async getCurrentDocuments() {
    return await this.getCurrentTicketInfo("Documents");
  }

  async getCurrentSchedule() {
    return await this.getCurrentTicketInfo("Schedule");
  }

  private async getCurrentTicketInfo(detail: string) {
    let result: any;
    try {
      let currentTicketID = this.getCurrentCreateTicketID();
      let currentRow = await this.completions$.getRow(currentTicketID);
      if (currentRow) {
        if (detail.indexOf("Documents") > -1) {
          if (currentRow["Documents"]) {
            result = currentRow[detail];
          }
        } else if (detail.indexOf("Schedule") > -1) {
          if (currentRow["Schedule"]) {
            result = currentRow[detail];
          }
        } else {
          if (currentRow["Ticket"] && currentRow["Ticket"][detail]) {
            result = currentRow["Ticket"][detail];
          }
        }
      }
    } catch (error) {
      this.logger$.error("getCurrentTicketInfo" + error.message);
    }
    return result;
  }

  //get full utility details with pcats and pfields
  async getUtilityViews(utilities: any[]) {
    try {
      let utilityViewsArr: FormTemplateViews[] = [];
      let utlityDetailViews: FormTemplateViews;
      let viewPromiseArr = [];
      //get utilities, pricatid and prifieldid and make utility object for each utility.
      let utilityRows = await this.getUtilityInfo(utilities).toPromise();
      let primaryCatRows =
        await this.getPrimaryDetailCategories(utilities).toPromise();
      let primaryFieldRows = [];
      //get primarycatids
      let primaryCatIDs = [];
      if (primaryCatRows && primaryCatRows.length > 0) {
        primaryCatRows.forEach((row) => {
          primaryCatIDs.push(row["PrimaryDetailCategoryID"]);
        });
      }
      if (primaryCatIDs && primaryCatIDs.length > 0) {
        primaryFieldRows =
          await this.getPrimaryDetailFields(primaryCatIDs).toPromise();
      }

      let fieldRules = await this.getPrimaryDetailFieldRules(primaryCatIDs);
      let fieldOptions =
        await this.getPrimaryDetailFieldOptions(primaryFieldRows);

      utlityDetailViews = await this.convertUtilityToFormTemplate(
        utilityRows,
        primaryCatRows,
        primaryFieldRows,
        fieldRules,
        fieldOptions,
      );
      if (Object.keys(utlityDetailViews.views).length > 0) {
        for (let viewKey in utlityDetailViews.views) {
          let view: FormTemplateView = utlityDetailViews.views[viewKey];
          viewPromiseArr.push(this.generateFormGroup(view));
        }
      }
      let promiseResult = await Promise.all(viewPromiseArr);
      if (promiseResult && promiseResult.length > 0) {
        promiseResult.forEach((viewsObj) => {
          utilityViewsArr.push(viewsObj);
        });
      }
      return utilityViewsArr;
    } catch (error) {
      this.logger$.error("getUtilityDetails: " + error.message);
    }
  }
  async getPrimaryDetailFieldOptions(primaryFieldRows: any[]) {
    let result = {};
    try {
      if (primaryFieldRows && primaryFieldRows.length > 0) {
        let primaryFieldIDs = primaryFieldRows.reduce((ids, obj) => {
          if (obj["FieldTypeID"] == 11 || obj["FieldTypeID"] == 12) {
            ids.push(obj["PrimaryDetailsFieldID"]);
          }
          return ids;
        }, []);

        if (primaryFieldIDs && primaryFieldIDs.length > 0) {
          let apiKey = apiKeys.u2.createTicketController;
          const apiValue = {
            action: 1,
            fillOptionID: OptionsFillID.primaryDetailFieldOptions,
            PrimaryDetailFieldID: primaryFieldIDs,
          };
          const url = apiKeys.u2[apiKey];
          const type = api[url].type;

          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKey,
            API_TYPE: type,
            API_BODY: apiValue,
          };

          let apiResult =
            await this.utilocateApiService.invokeUtilocateApi(
              utilocateApiRequest,
            );
          if (
            apiResult["body"] &&
            apiResult["body"].value &&
            apiResult["body"].value.length > 0
          ) {
            let val = apiResult["body"].value;
            let len = val.length;
            for (var i = 0; i < len; i++) {
              if (!result[val[i]["PrimaryDetailsFieldID"]]) {
                result[val[i]["PrimaryDetailsFieldID"]] = [];
              }
              result[val[i]["PrimaryDetailsFieldID"]].push({
                text: val[i]["text"],
                value: val[i]["value"],
              });
            }
          }
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  updateScheduleData(message: ActionMessage): Observable<any> {
    return this.completions$.updateScheduleData(message);
  }

  async getAvailableSchedulerResources(date = false) {
    let resources = [];
    let eventStartDateTime;
    let eventEndDateTime;
    try {
      if (date) {
        eventStartDateTime = date;
        eventEndDateTime = formatDate(
          eventStartDateTime,
          "yyyy-MM-d 23:59:59",
          "en-US",
        );
      } else {
        let currentScheduleDetails = await this.getCurrentSchedule();
        if (currentScheduleDetails) {
          if (currentScheduleDetails["EventStartDateTime"]) {
            eventStartDateTime = currentScheduleDetails["EventStartDateTime"];
            if (currentScheduleDetails["EventEndDateTime"]) {
              eventEndDateTime = currentScheduleDetails["EventEndDateTime"];
            } else {
              eventEndDateTime = formatDate(
                eventStartDateTime,
                "yyyy-MM-d 23:59:59",
                "en-US",
              );
            }
          }
        }
      }
      if (eventStartDateTime && eventEndDateTime) {
        let apiKey = apiKeys.u2.createTicketController;
        const apiValue = {
          action: 1,
          fillOptionID: OptionsFillID.availableSchedulerResources,
          EventStartDateTime: eventStartDateTime,
          EventEndDateTime: eventEndDateTime,
        };
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;
        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        let apiResult =
          await this.utilocateApiService.invokeUtilocateApi(
            utilocateApiRequest,
          );
        let result = apiResult["body"];
        if (result && result["value"] && result["value"].length > 0) {
          resources = result["value"];
        }
      }
    } catch (error) {
      this.logger$.error("getAvailableSchedulerResources: " + error.message);
    }
    return resources;
  }

  // each utility treated as a view
  //primary cat are groups
  //fields are fields.
  //each utility will have its own form group.
  async convertUtilityToFormTemplate(
    utilityRows,
    primaryCatRows,
    primaryFieldRows,
    fieldRules,
    fieldOptions,
  ) {
    let utilityViews: FormTemplateViews = {
      views: {},
    };
    try {
      if (utilityRows && utilityRows.length > 0) {
        utilityRows.forEach((utilityRow) => {
          let currentView: FormTemplateView = {
            header: utilityRow["UtilityName"],
            key: utilityRow["UtilityID"].toString(),
            groups: {
              defaultGroup: DEFAULT_GROUP,
            },
          };
          if (primaryCatRows && primaryCatRows.length > 0) {
            //has categories
            primaryCatRows.forEach((cateogryRow) => {
              if (cateogryRow["UtilityType"] == currentView.key) {
                if (currentView.groups["defaultGroup"]) {
                  delete currentView.groups["defaultGroup"];
                }
                let groupView: FormTemplateGroup = {
                  key: cateogryRow["PrimaryDetailCategoryID"].toString(),
                  header: cateogryRow["Title"],
                  groupOrder: 1, //TODO needs design.
                  hasHiddenValues: false,
                  fillStageID: cateogryRow["FillStageID"],
                  fillKey: "FSID-3-" + cateogryRow["PrimaryDetailCategoryID"],
                  fields: {
                    defaultField: DEFAULT_FIELD,
                  },
                };
                currentView.groups[cateogryRow["PrimaryDetailCategoryID"]] =
                  groupView;
                if (primaryFieldRows && primaryFieldRows.length > 0) {
                  // has fields
                  let hasHiddenValues = false;
                  primaryFieldRows.forEach((fieldRow) => {
                    if (fieldRow["PrimaryDetailCategoryID"] == groupView.key) {
                      let isVisible = true;
                      let isHideShow = false;
                      if (groupView.fields["defaultField"]) {
                        delete groupView.fields["defaultField"];
                      }

                      if (
                        fieldRules &&
                        fieldRules[fieldRow["PrimaryDetailsFieldID"]]
                      ) {
                        if (
                          fieldRules[fieldRow["PrimaryDetailsFieldID"]][
                            "bHideShow"
                          ]
                        ) {
                          hasHiddenValues = true;
                          isVisible = false;
                          isHideShow = true;
                        }
                      }

                      //check for select/multiselect and create options for them.
                      let optionValues: FormTemplateOptionValues[] = [];
                      if (
                        fieldRow["FieldTypeID"] == 11 ||
                        fieldRow["FieldTypeID"] == 12
                      ) {
                        if (fieldOptions[fieldRow["PrimaryDetailsFieldID"]]) {
                          optionValues =
                            fieldOptions[fieldRow["PrimaryDetailsFieldID"]];
                        }
                      }

                      let fieldView: FormTemplateField = {
                        key: fieldRow["PrimaryDetailsFieldID"].toString(),
                        appearance: "outline",
                        label: fieldRow["DisplayText"],
                        placeholder: fieldRow["DisplayText"],
                        inputTypeID: fieldRow["FieldTypeID"],
                        fieldOrder: fieldRow["FieldOrder"],
                        matches: "",
                        isReadOnly: false,
                        isRequired: false,
                        width_xs: 100,
                        width_sm: 100,
                        width_md: 100,
                        width_lg: 100,
                        width_xl: 100,
                        isHideShow: isHideShow,
                        isVisible: isVisible,
                        options: {
                          hasValues: true,
                          optionsFillID: 0,
                          values: optionValues,
                        },
                      };
                      groupView.fields[fieldRow["PrimaryDetailsFieldID"]] =
                        fieldView;
                    }
                  });
                  if (hasHiddenValues) {
                    groupView["hasHiddenValues"] = hasHiddenValues;
                  }
                }
              }
            });
          }
          utilityViews.views[currentView.key] = currentView;
        });
      }
    } catch (error) {
      this.logger$.error("convertUtilityToFormTemplate: " + error.message);
    }
    return utilityViews;
  }

  getPrimaryDetailFields(primaryCategories: any[]) {
    return new Observable<any>((subscriber) => {
      try {
        this.lookup$
          .getLookupTableRows(["tbAdmin_PrimaryDetailFields"], {
            PrimaryDetailCategoryID: primaryCategories,
          })
          .then((res) => {
            if (res && res[0] && res[0]["rows"]) {
              subscriber.next(res[0]["rows"]);
              subscriber.complete();
            } else {
              this.logger$.error(
                "CreateTicketService",
                "getPrimaryDetailPrefill",
                "Failed to read from res",
              );
              subscriber.next(false);
              subscriber.complete();
            }
          })
          .catch((error) => {
            this.logger$.error(
              "CreateTicketService",
              "getPrimaryDetailPrefill",
              error,
            );
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error(
          "CreateTicketService",
          "_getPrimaryDetailCategories",
          error,
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  getPrimaryDetailCategories(utilities: any[]) {
    return new Observable<any>((subscriber) => {
      const where = {
        UtilityType: utilities,
        FillStageID: [FillStageID.PrefillOptional, FillStageID.PrefillRequired],
      };
      try {
        this.lookup$
          .getLookupTableRows(["tbAdmin_PrimaryDetailsCategories"], where)
          .then((result) => {
            if (result && result[0] && result[0]["rows"]) {
              subscriber.next(result[0]["rows"]);
              subscriber.complete();
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          })
          .catch((error) => {
            this.logger$.error(
              "CreateTicketService",
              "_getPrimaryDetailCategories",
              error,
            );
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error(
          "CreateTicketService",
          "_getPrimaryDetailCategories",
          error,
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  async getPrimaryDetailFieldRules(primaryCategories: any[]) {
    let result = {};
    try {
      if (primaryCategories && primaryCategories.length > 0) {
        let apiKey = apiKeys.u2.createTicketController;
        const apiValue = {
          action: 1,
          fillOptionID: OptionsFillID.primaryDetailFieldRules,
          PrimaryDetailCategoryID: primaryCategories,
        };
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;
        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };
        let apiResult =
          await this.utilocateApiService.invokeUtilocateApi(
            utilocateApiRequest,
          );
        if (
          apiResult["body"] &&
          apiResult["body"].value &&
          apiResult["body"].value.length > 0
        ) {
          let val = apiResult["body"].value;
          let len = val.length;
          for (var i = 0; i < len; i++) {
            result[val[i]["PrimaryDetailsFieldID"]] = val[i];
          }
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  getUtilityInfo(utilityIDs) {
    return new Observable<any>((subscriber) => {
      const where = { UtilityType: utilityIDs };
      try {
        this.lookup$
          .getLookupTableRows(["tbAdmin_Utilities"], where)
          .then((result) => {
            //rows is arr of each utility row
            if (
              result &&
              result[0] &&
              result[0]["rows"] &&
              result[0]["rows"].length > 0
            ) {
              subscriber.next(result[0]["rows"]);
              subscriber.complete();
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          })
          .catch((error) => {
            this.logger$.error(
              "CreateTicketService",
              "_getPrimaryDetailCategories",
              error,
            );
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error(
          "CreateTicketService",
          "_getPrimaryDetailCategories",
          error,
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  async onDateSelected(eventKey, eventDate) {
    try {
      eventDate = new Date(eventDate);
      if (eventKey && eventKey) {
        if (eventKey.indexOf("OriginalExcavationDate") == 0) {
          //update ticket data with this date.
          let date = this.datetime.localDateToDBDateStr(eventDate);
          await this.updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_EXCAVATION_DATE, {
              formType: CreateTicketFormTypes.ticket,
              value: { OriginalExcavationDate: date },
            }),
          ).toPromise();
        } else if (eventKey.indexOf("EventStartDateTime") == 0) {
          let date = this.datetime.localDateToDBDateStr(eventDate);
          let curUID = this.getCurrentCreateTicketID();
          await this.updateScheduleData(
            new ActionMessage(Actions.UPDATE_SCHEDULE_DATA, {
              rowKey: curUID,
              value: {
                EventStartDateTime: formatDate(
                  date,
                  "yyyy-MM-d 08:00:00",
                  "en-US",
                ),
              },
            }),
          ).toPromise();
        } else if (eventKey.indexOf("EventEndDateTime") == 0) {
          let date = this.datetime.localDateToDBDateStr(eventDate);
          let curUID = this.getCurrentCreateTicketID();
          await this.updateScheduleData(
            new ActionMessage(Actions.UPDATE_SCHEDULE_DATA, {
              rowKey: curUID,
              value: {
                EventEndDateTime: formatDate(
                  date,
                  "yyyy-MM-d 17:00:00",
                  "en-US",
                ),
              },
            }),
          ).toPromise();
        } else if (eventKey.indexOf("CallDate") == 0) {
          //update ticket data with this date.
          let date = this.datetime.localDateToDBDateStr(eventDate);
          await this.updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_CALLDATE, {
              formType: CreateTicketFormTypes.ticket,
              value: { CallDate: date },
            }),
          ).toPromise();
        }
      }
    } catch (error) {
      this.logger$.error("onDropdownSelected: " + error.message);
    }
  }
}
