import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-container-tab",
  templateUrl: "./container-tab.component.html",
  styleUrls: ["./container-tab.component.scss"],
  standalone: true,
})
export class ContainerTabComponent implements AfterViewInit {
  @ViewChild("wrapper") wrapperRef: ElementRef;
  @Input() tab: {
    title: string;
    index: number;
  };
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  wrapper: HTMLElement;
  private index: number;
  constructor() {}

  ngAfterViewInit() {
    this.wrapper = this.wrapperRef.nativeElement;
  }

  hide() {
    if (!this.wrapper.className.includes("hidden")) {
      this.wrapper.classList.add("hidden");
    }
  }

  show() {
    this.wrapper.classList.remove("hidden");
  }

  getTitle(): string {
    return this.tab.title;
  }
  getIndex() {
    return this.tab.index;
  }

  onClick(event) {
    this.clicked.emit(event);
  }
}
