import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { NavBarService } from '../../ticket/services/router/nav-bar.service';
import { NavigationBarService } from '../navigation-bar.service';
import { SettingID } from '../../../core/services/user/setting';
import { environment } from "src/environments/environment";
import { TicketSearchService } from 'src/app/shared/services/ticket-search/ticket-search.service';

export class NavBarConfig {
  showSyncBadge: boolean;
  onSyncClick: Subject<any>;

  constructor(showSyncBadge: boolean, onSyncClick?: Subject<any>) {
    this.showSyncBadge = showSyncBadge;
    this.onSyncClick = onSyncClick;
  }
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  hasMenuItems: boolean = false;
  showSyncBadge: boolean;
  onSyncClick: Subject<any>;
  onCreateAuditClick: Subject<any>;
  onResetDBClick: Subject<any>;
  onSignOutClick: Subject<any>;
  currentRoute: any;
  @Input() pageTitle: string;

  name$: Observable<string>;
  userCategory$: Observable<string>;
  initials$: Observable<string>;
  appVersion: string = environment.appVersion;

  private ticketSearchService = inject(TicketSearchService);


  constructor(
    private router: Router,
    private navigationBar: NavigationBarService,
    private logger: LoggerService,
    private navBarChanges: NavBarService,
    private route: ActivatedRoute,
    protected userService: UserService
  ) {
    this.currentRoute = this.route.snapshot.firstChild.routeConfig.path;
  }

  ngOnInit(): void {
    this.navBarChanges.getNavBarChanges().subscribe((nextVal: NavBarConfig) => {
      this.onSyncClick = nextVal.onSyncClick;
      this.showSyncBadge = nextVal.showSyncBadge;
    });    
    this.name$ = this.userService.currentUser$.pipe(map((val) => this.getName(val)));
    this.userCategory$ = this.userService.currentUser$.pipe(map((val) => this.getUserCategory(val)));
    this.initials$ = this.userService.currentUser$.pipe(map((val) => this.getInitials(val)));
  }

  async logoutClicked() {
    // this.name$ = this.userService.currentUser$.pipe(map(val => this.getName(val)));
    // this.userCategory$ = this.userService.currentUser$.pipe(map(val => this.getUserCategory(val)));
    // this.initials$ = this.userService.currentUser$.pipe(map(val => this.getInitials(val)));
    await this.userService.logout();
    this.ticketSearchService.clearLastSearch();
    this.router.navigate(['']);
  }

  getName(user) {
    let name = '';
    try {
      if (user && user.firstName) {
        name = `${user.firstName} ${user.lastName}`;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return name;
  }

  getUserCategory(user) {
    let category = '';
    try {
      if (user && user.category) {
        category = user.category;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return category;
  }

  getInitials(user) {
    let initials = '';
    try {
      if (user && user.firstName) {
        initials = `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return initials;
  }

  public onSync() {
    this.onSyncClick.next(null);
    this.showSyncBadge = false;
  }

  menuClicked() {
    try {
      this.navigationBar.toggleMenu();
    } catch (error) {
      this.logger.error(error);
    }
  }

  protected readonly SettingID = SettingID;
}
