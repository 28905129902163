import { Component, OnInit, Input, Output } from "@angular/core";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { SchedulerService } from "../../services/scheduler.service";
import {
  SchedulerGetTicketByAssignmentIDVal,
  SchedulerTicketActions,
  TicketTag,
  Event,
  Resource,
  Ticket,
  SchedulerGetTicketByPrimaryIDVal,
  SchedulerEventActions,
} from "../../syncfusion-scheduler/schedulerTypes";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-scheduler-main-panel",
  templateUrl: "./scheduler-main-panel.component.html",
  styleUrls: ["./scheduler-main-panel.component.css"],
  providers: [ComponentMessagingService],
})
export class SchedulerMainPanelComponent
  extends BasePanelComponent
  implements OnInit {
  className = "SchedulerMainPanelComponent";

  @Input() props: any;
  @Input() events: Event[];
  @Input() ticketTags: TicketTag[];
  @Input() resources: Resource[];
  @Input() rigResources: Resource[];
  @Input() peopleResources: Resource[];
  @Input() drillerResources: Resource[];
  @Input() helperResources: Resource[];
  @Input() resourceEquipmentResources: Resource[];
  @Input() tickets: Ticket[];
  @Input() parentEvents: Event[];
  @Input() readonly: boolean;

  @Output() dateChangedEvent: EventEmitter<any> = new EventEmitter();

  schedulerViewProps: any;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private schedulerService$: SchedulerService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);

    this.schedulerViewProps = {
      messageService: compMsg$,
    };

    this.compMsg$.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessageChild(nextMsg);
    });
  }

  ngOnInit(): void {
    this.props.messageService
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        this.onReceivedMessageParent(nextMsg);
      });
  }

  /* -------- Functions for handling received messages -------- */

  onReceivedMessageParent(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.filterEvents) {
        this.schedulerViewProps.messageService.sendToMessageStream(compMessage);
      }

      if (compMessage.message.queryEvents) {
        this.schedulerViewProps.messageService.sendToMessageStream(compMessage);
      }

      if (compMessage.message.ticketDropped) {
        this.schedulerViewProps.messageService.sendToMessageStream(compMessage);
      }

      if (compMessage.message.refresh || compMessage.message.refreshSchedule) {
        this.schedulerViewProps.messageService.sendToMessageStream(compMessage);
      }
    }
  }

  onReceivedMessageChild(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.eventCreated) {
        this.createEvent(compMessage);
      }

      if (compMessage.message.eventRemoved) {
        this.removeEvent(compMessage);
      }

      if (compMessage.message.eventUpdated) {
        this.updateEvent(compMessage);
      }

      if (compMessage.message.getTicketByAssignmentID) {
        this.getTicketByAssignmentID(compMessage.message.assignmentID);
      }

      if (compMessage.message.getTicketByPrimaryID) {
        this.getTicketByPrimaryID(compMessage.message.primaryID);
      }

      if (compMessage.message.reloadEvents) {
        this.props.messageService.sendToMessageStream(compMessage);
      }

      if (compMessage.message.cancelTicket) {
        this.cancelTicket(compMessage);
      }

      if (compMessage.message.dateViewChanged) {
        this.updateEventList(compMessage)
      }
    }
  }

  /* -------- Functions for cancelling tickets -------- */

  async cancelTicket(compMessage: ComponentMessage) {
    // make api value
    let apiValue = {};
    apiValue[compMessage.message.PrimaryID] = {
      Explanation: compMessage.message.Explanation,
    };

    this.schedulerService$.cancelTicket(apiValue).subscribe((response) => {
      this.progressBarService.stop();
      if (response && response[0] && response[0]["Error"]) {
        let errorMsg = response[0]["Error"].split(":")[1];
        this.snackBarService.openSnackbar(errorMsg, SnackbarType.error, "OK");
      } else {
        this.snackBarService.openSnackbar(
          "Success",
          SnackbarType.success,
          "OK"
        );

        this.compMsg$.sendToMessageStream({
          action: MessageAction.READY,
          message: {
            cancelTicketComplete: true,
            EventIDs: compMessage.message.EventIDs,
          },
          senderID: this.className,
        });
      }
    });
  }

  /* -------- Functions for saving events -------- */

  createEvent(compMessage: ComponentMessage) {
    this.schedulerService$.createEvent(compMessage).subscribe((response) => {
      this.logger$.log("createEvent response:", response);
      if (response.new) {
        this.switchEventIDs(compMessage.message.Events, response.new);
        this.events = [...this.events];
        this.compMsg$.sendToMessageStream({
          action: MessageAction.UPDATE,
          message: {
            scrollToResource: true,
          },
          senderID: this.className,
        });
      } else {
        // this.eventFailed(compMessage.message.Event.EventID);
      }
    });

    this.props.messageService.sendToMessageStream(compMessage);
  }

  updateEvent(compMessage: ComponentMessage) {
    this.logger$.log("eventUpdated: ", compMessage.message.Events);
    this.schedulerService$.updateEvent(compMessage).subscribe((response) => {
      this.logger$.log("eventUpdated response: ", response);
      if (response.status == 0) {
        // this.eventFailed(compMessage.message.Event.EventID);
      }
      this.compMsg$.sendToMessageStream({
        action: MessageAction.UPDATE,
        message: {
          scrollToResource: true,
        },
        senderID: this.className,
      });
    });
  }

  removeEvent(compMessage: ComponentMessage) {
    this.schedulerService$.deleteEvent(compMessage).subscribe((response) => {
      // this.logger$.log('eventRemoved response: ', response);
      if (response.status == 0) {
        // this.eventFailed(compMessage.message.Event.EventID);
      }

      this.compMsg$.sendToMessageStream({
        action: MessageAction.UPDATE,
        message: {
          scrollToResource: true,
          removeEventComplete: true,
          EventIDs: compMessage.message.EventIDs,
        },
        senderID: this.className,
      });
    });
  }

  /* -------- Utility Functions -------- */

  switchEventIDs(createdEvents, newEventIDs) {
    if (newEventIDs && Array.isArray(newEventIDs) && newEventIDs.length > 0) {
      if (newEventIDs[0]["eventIDs"] != null) {
        // children format
        // loop through the createdEvents to get the old IDs
        for (let i in createdEvents) {
          let oldEventID = createdEvents[i].EventID;
          let newEventID = newEventIDs[i]["parentEventID"];

          this.updateEventID(oldEventID, newEventID);
          if (
            createdEvents[i].ChildrenEvents != null &&
            Array.isArray(createdEvents[i].ChildrenEvents)
          ) {
            for (let ii in createdEvents[i].ChildrenEvents) {
              let child = createdEvents[i].ChildrenEvents[ii];
              let oldChildEventID = child.EventID;
              let newChildEventID = newEventIDs[i]["eventIDs"][ii];

              this.updateEventID(oldChildEventID, newChildEventID);
              this.updateParentEventID(newChildEventID, newEventID);
            }
          }
        }
      } else {
        // no children were created
        for (let i in createdEvents) {
          let oldEventID = createdEvents[i].EventID;
          let newEventID = newEventIDs[i];

          this.updateEventID(oldEventID, newEventID);
        }
      }
    }
  }

  updateEventID(oldEventID, newEventID) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == oldEventID) {
        var event = { ...this.events[i], EventID: newEventID };
        this.events.splice(i, 1);
        this.events.push(event);
        break;
      }
    }
  }

  updateParentEventID(eventID, newParentEventID) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == eventID) {
        var event = { ...this.events[i], ParentEventID: newParentEventID };
        this.events.splice(i, 1);
        this.events.push(event);
        break;
      }
    }

    for (let i = 0; i < this.parentEvents.length; i++) {
      if (this.parentEvents[i].EventID == eventID) {
        var event = {
          ...this.parentEvents[i],
          ParentEventID: newParentEventID,
        };
        this.parentEvents.splice(i, 1);
        this.parentEvents.push(event);
        break;
      }
    }
  }

  eventFailed(eventID) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == eventID) {
        var event = { ...this.events[i], FailedToUpdate: true };
        this.events.splice(i, 1);
        this.events.push(event);
        this.events = [...this.events];
        break;
      }
    }
  }

  /* -------- Function for getting a ticket using AssignmentID -------- */

  getTicketByAssignmentID(assignmentID: number) {
    try {
      const apiBody: SchedulerGetTicketByAssignmentIDVal = {
        action: SchedulerTicketActions.GetTicketByAssignmentID,
        AssignmentID: assignmentID,
      };

      this.schedulerService$
        .getTicketByAssignmentID(apiBody)
        .subscribe((result) => {
          if (
            result["status"] == 1 &&
            result["value"] &&
            result["value"]["Tickets"] &&
            result["value"]["Tickets"].length > 0
          ) {
            const messageToSend: ComponentMessage = {
              action: MessageAction.READY,
              message: {
                openTicketDialog: true,
                ticketData: {
                  ...result["value"]["Tickets"][0],
                  Comments: result["value"]["Tickets"][0]["DispatcherRemarks"],
                  Address:
                    result["value"]["Tickets"][0]["StartHouseNumber"] +
                    " " +
                    result["value"]["Tickets"][0]["LocateAddress"],
                },
              },
              senderID: this.className,
            };
            this.compMsg$.sendToMessageStream(messageToSend);
          }
        });
    } catch (error) {
      console.error("getTicketByAssignmentID: ", error);
    }
  }

  /* -------- Function for getting a ticket using PrimaryID -------- */

  getTicketByPrimaryID(primaryID: number) {
    try {
      let apiBody: SchedulerGetTicketByPrimaryIDVal = {
        action: SchedulerTicketActions.GetTicketByPrimaryID,
        PrimaryID: primaryID,
      };

      this.schedulerService$
        .getTicketByPrimaryID(apiBody)
        .subscribe((result) => {
          if (
            result["status"] == 1 &&
            result["value"] &&
            result["value"]["Tickets"] &&
            result["value"]["Tickets"].length > 0
          ) {
            const messageToSend: ComponentMessage = {
              action: MessageAction.READY,
              message: {
                openTicketDialog: true,
                ticketData: {
                  ...result["value"]["Tickets"][0],
                  Comments: result["value"]["Tickets"][0]["DispatcherRemarks"],
                  Address:
                    result["value"]["Tickets"][0]["StartHouseNumber"] +
                    " " +
                    result["value"]["Tickets"][0]["LocateAddress"],
                },
              },
              senderID: this.className,
            };
            this.compMsg$.sendToMessageStream(messageToSend);
          }
        });
    } catch (error) {
      console.error("getTicketByPrimaryID: ", error);
    }
  }

  /* -------- Function for reloading events  -------- */

  updateEventList(compMessage: ComponentMessage) {
    try {
      this.dateChangedEvent.emit(compMessage);
    } catch (error) {
      console.error(error);
    }
  }
}
