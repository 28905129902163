import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AsyncPipe, KeyValuePipe, NgForOf } from '@angular/common';
import { DrawingSlotComponent } from './drawing-slot/drawing-slot.component';
import { Drawing, DrawingID } from '../../../services/drawing-manager.service';

@Component({
  selector: 'app-drawing-list',
  standalone: true,
  imports: [CdkAccordionModule, AsyncPipe, NgForOf, DrawingSlotComponent, KeyValuePipe],
  template: `
    <div>
      @for (drawing of drawings; track drawing.drawingID) {
        <app-drawing-slot [selected]="activeDrawing === drawing.drawingID" (drawingClick)="selectionChanged($event)"
                          (deleteClick)="deleteClick.emit($event)"
                          [drawing]="drawing"></app-drawing-slot>
      }
    </div>
  `,
})
export class DrawingListComponent {
  // IO
  @Input() drawings: Array<Drawing> = [];
  @Input() activeDrawing: DrawingID = "";
  @Output() drawingSelectionChanged = new EventEmitter<DrawingID>();
  @Output() deleteClick = new EventEmitter<DrawingID>();

  constructor() {
  }

  protected selectionChanged(event: DrawingID) {
    if (this.activeDrawing === event) {
      this.activeDrawing = null;
      this.drawingSelectionChanged.emit(null);
    } else {
      this.activeDrawing = event;
      this.drawingSelectionChanged.emit(event);
    }
  }
}
