// @ts-nocheck
import VectorSource from 'ol/source/Vector';
import { Feature, Map } from 'ol';
import { Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';

export type Measurement = {
  height: number;
  width: number;
};

export class OpenLayersUtilities {
  public removeFeatureFromSource(features: Feature[], source: VectorSource) {
    features.forEach((f) => {
      const id = f.getId();
      const feature = source.getFeatureById(id);
      if (feature) {
        source.removeFeature(feature);
      } else {
        throw new Error('feature not found');
      }
    });
  }

  public addFeatureToSource(features: Feature[], source: VectorSource) {
    features.forEach((f) => {
      source.addFeature(f);
    });
  }

  public measureText(string: string, font: string = undefined, padding: number[] = undefined): Measurement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      if (font) {
        context.font = font;
      }
      const metrics = context.measureText(string);
      const measurement = {
        height: metrics.actualBoundingBoxAscent,
        width: metrics.width,
      };
      if (padding) {
        measurement.height += padding[0] + padding[2];
        measurement.width += padding[1] + padding[3];
      }
      return measurement;
    } else {
      throw new Error('unable to create context');
    }
  }

  public measureImage(image: string): Measurement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const img = new Image();
      img.src = image;
      context.drawImage(img, 0, 0);
      return {
        height: img.height,
        width: img.width,
      };
    } else {
      throw new Error('unable to create context');
    }
  }

  public measureRectangle(poly: Polygon, map: Map): Measurement {
    const coords = poly.getCoordinates();
    if (coords.length !== 1 || coords[0].length !== 5) {
      throw new Error('invalid polygon');
    } else {
      const pixelCoords = coords[0].map((coord) => {
        return map.getPixelFromCoordinate(coord);
      });
      const width = Math.sqrt(
        Math.pow(pixelCoords[1][0] - pixelCoords[0][0], 2) + Math.pow(pixelCoords[1][1] - pixelCoords[0][1], 2)
      );
      const height = Math.sqrt(
        Math.pow(pixelCoords[2][0] - pixelCoords[1][0], 2) + Math.pow(pixelCoords[2][1] - pixelCoords[1][1], 2)
      );
      if (width >= height) {
        return {
          width,
          height,
        };
      } else {
        return {
          width: height,
          height: width,
        };
      }
    }
  }

  public createRectangleGeometry(map: Map, center: Coordinate, width: number, height: number): Polygon {
    const [x, y] = map.getPixelFromCoordinate(center);
    const coordinates = [
      [x - width / 2, y - height / 2],
      [x - width / 2, y + height / 2],
      [x + width / 2, y + height / 2],
      [x + width / 2, y - height / 2],
      [x - width / 2, y - height / 2],
    ].map((pixelPair) => map.getCoordinateFromPixel(pixelPair));
    return new Polygon([coordinates]);
  }
}
