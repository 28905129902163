// @ts-nocheck
import { Component, Inject, NgModule, ViewChild } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  ChangedEventArgs,
  DatePicker,
  DatePickerAllModule,
} from "@syncfusion/ej2-angular-calendars";
import {
  DropDownListAllModule,
  DropDownListComponent,
  MultiSelectAllModule,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { FormsModule } from "@angular/forms";
import { CheckBox, CheckBoxModule } from "@syncfusion/ej2-angular-buttons";
import { NgxColorsModule } from "ngx-colors";
import { MaterialModule } from "src/app/modules/shared/material.module";

@Component({
  selector: "app-scheduler-move-event-dialog",
  templateUrl: "./scheduler-move-event-dialog.component.html",
  styleUrls: ["./scheduler-move-event-dialog.component.css"],
})
export class SchedulerMoveEventDialogComponent {
  getHelpers: any;
  getDrillers: any;
  getResources: any;
  getAllPeople: any;
  resources: {}[];
  drillerResources: {}[];
  helperResources: {}[];
  equipmentResources: {}[];
  event: any;
  eventNotes: string = "";
  resourceFields = { text: "ResourceName", value: "ResourceID" };
  drillerFields = { text: "ResourceName", value: "UserID" };

  public currentEventColour = "";

  @ViewChild("ResourceID") resourceDropDown: DropDownListComponent;
  @ViewChild("Driller") drillerDropDown: DropDownListComponent;
  @ViewChild("Helpers") helperDropDown: MultiSelectComponent;
  @ViewChild("Equipment") equipmentDropDown: MultiSelectComponent;
  @ViewChild("Start") start: DatePicker;
  @ViewChild("End") end: DatePicker;

  constructor(
    public dialogRef: MatDialogRef<SchedulerMoveEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.resources = data.resources;
    this.event = data.event;
    this.drillerResources = data.drillers;
    this.helperResources = data.helpers;
    this.equipmentResources = data.equipment;

    this.getDrillers = data.getDrillers;
    this.getHelpers = data.getHelpers;
    this.getResources = data.getResources;
    this.getAllPeople = data.getAllPeople;
  }

  ngAfterViewInit() {
    this.updateResources();
  }

  updateResources() {
    let resourceQuery = this.getQueryForResources(
      this.getResources(this.start.value, this.end.value, this.event.EventID)
    );
    this.resourceDropDown.query = resourceQuery;
    this.resourceDropDown.dataBind();

    let helperQuery = this.getQueryForResources(
      this.getHelpers(this.start.value, this.end.value, this.event)
    );
    this.helperDropDown.query = helperQuery;
    this.helperDropDown.dataBind();
    this.helperDropDown.addItem(
      {
        HexColour: "#000000",
        ResourceDesc: "",
        ResourceID: 0,
        ResourceName: "Unassigned",
        ResourceTypeID: 3,
        UserID: 0,
        bSingleton: 0,
      },
      0
    );

    let drillerQuery = this.getQueryForResources(
      this.getDrillers(this.start.value, this.end.value, this.event)
    );
    this.drillerDropDown.query = drillerQuery;
    this.drillerDropDown.dataBind();
    this.drillerDropDown.addItem(
      {
        HexColour: "#000000",
        ResourceDesc: "",
        ResourceID: 0,
        ResourceName: "Unassigned",
        ResourceTypeID: 4,
        UserID: 0,
        bSingleton: 0,
      },
      0
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    let result = {};
    if (this.resourceDropDown.value) {
      result["resourceID"] = this.resourceDropDown.value;
    }

    if (this.start.value) {
      result["start"] = this.start.value;
    }

    if (this.end.value) {
      result["end"] = this.end.value;
    }

    if (this.drillerDropDown.value != null) {
      result["drillerID"] = this.drillerDropDown.value;
    }

    if (
      this.helperDropDown.value != null &&
      this.helperDropDown.value.length > 0
    ) {
      result["helperIDs"] = this.helperDropDown.value;
    }

    if (this.equipmentDropDown.value) {
      result["equipmentIDs"] = this.equipmentDropDown.value;
    }
    console.log(this.currentEventColour);
    if (this.currentEventColour) {
      result["eventColour"] = this.currentEventColour;
    }

    if (this.eventNotes && this.eventNotes.trim().length > 0) {
      result["eventNotes"] = this.eventNotes;
    }

    this.dialogRef.close(result);
  }

  getQueryForResources(resourceList) {
    var predicate;
    var field = "ResourceID";
    for (var i = 0; i < resourceList.length; i++) {
      if (i === 0) {
        predicate = new Predicate(field, "equal", resourceList[i].ResourceID);
      } else {
        predicate = predicate.or(field, "equal", resourceList[i].ResourceID);
      }
    }
    return new Query().where(predicate);
  }

  colorPickerChange(event) {
    if (event) {
      this.currentEventColour = event;
    }
  }

  onCheckChange(args) {
    try {
      this.filterResourcesOnChange(args);
    } catch (error) {
      console.error(error.message);
    }
  }

  filterResourcesOnChange(args) {
    if (this.drillerDropDown) {
      this.drillerDropDown.query = null;
      if (args.checked) {
        this.drillerDropDown.query = this.getQueryForResources(
          this.getDrillers(this.start.value, this.end.value, this.event)
        );
      } else {
        this.drillerDropDown.query = this.getQueryForResources(
          this.getAllPeople()
        );
      }
      this.drillerDropDown.dataBind();
    }

    if (this.helperDropDown) {
      this.helperDropDown.query = null;
      if (args.checked) {
        this.helperDropDown.query = this.getQueryForResources(
          this.getHelpers(this.start.value, this.end.value, this.event)
        );
      } else {
        this.helperDropDown.query = this.getQueryForResources(
          this.getAllPeople()
        );
      }
      this.helperDropDown.dataBind();
    }
  }
}

@NgModule({
  declarations: [SchedulerMoveEventDialogComponent],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    DatePickerAllModule,
    FormsModule,
    CheckBoxModule,
    NgxColorsModule,
  ],
})
export class SchedulerMoveEventDialoggModule {}
