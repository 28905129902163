import { inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MapFeatureService } from '../services/map-feature.service';
import { DrawingService } from '../drawing.service';

@Injectable({
  providedIn: 'root',
})
export class DrawingMapService implements OnDestroy {
  // services
  private mapDrawingService = inject(DrawingService);
  public dialog = inject(MatDialog);
  private mapFeatureService = inject(MapFeatureService);
  // observables
  private destroy$: Subject<void> = new Subject<void>();

  // members
  endDrawingMode: () => void;

  constructor() {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get featureManager(): MapFeatureService {
    return this.mapFeatureService;
  }
}
