<form [formGroup]="form">
  <mat-form-field
    *ngFor="let input of inputs"
    class="create-ticket-input-field"
  >
    <input
      matInput
      placeholder="{{ input.placeholder }}"
      formControlName="{{ input.dbName }}"
      required="{{ input.isRequired }}"
      id="{{ input.dbName }}"
    />
  </mat-form-field>
</form>
