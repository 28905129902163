import { Injectable } from "@angular/core";
import { CALL_TYPE } from "./ticket-tags-styles";

@Injectable({
  providedIn: "root",
})
export class TicketTagBuilderService {
  constructor() {}

  createTicketTagsFromTable(
    tbCompletions_AssignmentToTags,
    tbAdmin_TicketTags
  ) {
    try {
      let tags = [];

      let AssignmentTagIDs = tbCompletions_AssignmentToTags.reduce(
        (total, current) => {
          if (current && current.TagID) {
            total.push(current.TagID);
          }
          return total;
        },
        []
      );

      for (let i = 0; i < tbAdmin_TicketTags.Data.length; i++) {
        let curAdminTag = tbAdmin_TicketTags.Data[i];

        if (AssignmentTagIDs.indexOf(curAdminTag["TagID"]) > -1) {
          // get text colour given background colour
          let textColour = this.getTextColour(curAdminTag["HexColour"]);

          tags.push({
            value: curAdminTag["TagName"],
            color: curAdminTag["HexColour"],
            textColor: textColour,
          });
        }
      }

      return tags;
    } catch (error) {
      console.error(error);
    }
  }

  getTextColour(colour): string {
    let textColour = "#000000";
    if (colour) {
      let r = parseInt(colour.substring(1, 3), 16);
      let g = parseInt(colour.substring(3, 5), 16) * 2;
      let b = parseInt(colour.substring(5, 7), 16);
      if (r + g + b <= 765 / 1.96) {
        textColour = "#ffffff";
      }
    }
    return textColour;
  }
}
