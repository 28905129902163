import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { DrawingAPIService } from './drawing-api.service';
import GeoJSON, { GeoJSONFeatureCollection } from 'ol/format/GeoJSON';
import { Feature } from 'ol';
import JSZip from 'jszip';
import { LoggerService } from '../../core/services/logger/logger.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DrawingManagerService {
  // services
  private logger = inject(LoggerService);
  private drawingAPIService = inject(DrawingAPIService);

  // observables
  private destroy$ = new Subject();
  private _drawings$ = new BehaviorSubject<Array<Drawing>>([]);

  // members
  private featureReader = new GeoJSON();
  private backgroundCache: Record<string, File> = {};
  private isLocalhost: boolean = false;

  constructor() {
    this.isLocalhost = environment.localhost;
  }

  fetchDrawings(primaryID: number) {
    if (!primaryID) {
      return;
    }
    try {
      this.getAllDrawings(primaryID).then((res) => {
        this._drawings$.next(res.map((x) => x.drawingData));
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  private async getAllDrawings(primaryID: number) {
    const query = {
      get: { primaryID },
    };
    const { ok, body } = await this.drawingAPIService.sendRequest(query);
    if (!ok) {
      return [];
    }
    const { data }: { data: GetDrawingsResponse } = body;
    if (!data) {
      throw new Error('no data returned');
    }
    return data.map((x) => this.dbDrawingToDrawing(x)) ?? [];
  }

  getParsedDrawing(val: DrawingID): Feature[] {
    const drawing = this.drawings.find((drawing) => drawing.drawingID === val);
    if (drawing.type !== 'canvas') {
      throw new Error('not a canvas drawing');
    }
    return this.featureReader.readFeatures(drawing.geoJSON) as Feature[];
  }

  async getDrawingBackground(
    clientID: number,
    assignmentID: number,
    primaryID: number,
    drawingID: DrawingID
  ): Promise<File> {
    const drawing = this.drawings.find((drawing) => drawing.drawingID === drawingID);
    if (!drawing.backgroundURL) {
      return undefined;
    }
    if (!this.backgroundCache[drawingID]) {
      const buff = await this.fetchBackgroundImage(clientID, assignmentID, primaryID, drawingID);
      this.backgroundCache[drawingID] = await this.getFileFromBuffer(buff);
    }
    return this.backgroundCache[drawingID];
  }

  private async fetchBackgroundImage(
    clientID: number,
    assignmentID: number,
    primaryID: number,
    drawingID: DrawingID
  ): Promise<ArrayBuffer> {
    let url = `/api/v1/clients/${clientID}/assignments/${assignmentID}/primaries/${primaryID}/documents?document_name=drawing-backgrounds/${drawingID}.zip`;
    if (!this.isLocalhost) {
      url = '/nodejs' + url;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`failed to fetch background for: ${drawingID}`);
    }
    return await response.arrayBuffer();
  }

  private async getFileFromBuffer(buff: ArrayBuffer) {
    const { files } = await new JSZip().loadAsync(buff);
    const [fileName, data] = Object.entries(files)[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const blob = await data.async('blob');
    return new File([blob], fileName);
  }

  tidy() {
    this._drawings$.next([]);
  }

  private dbDrawingToDrawing(d: DatabaseDrawingData): DrawingData {
    return {
      id: d.id,
      drawingID: d.drawing_id,
      drawingData: d.drawing_data,
      createdAt: d.created_at,
      assignmentID: d.assignment_id,
      primaryID: d.primary_id,
      updatedAt: d.updated_at,
    };
  }

  getDrawingByID(drawingID: DrawingID) {
    return this.drawings.find((drawing) => drawing.drawingID === drawingID) as Drawing;
  }

  get drawings() {
    return this._drawings$.value;
  }

  get canvasDrawings$(): Observable<Drawing[]> {
    return this._drawings$.pipe(
      map((arr) => {
        return arr.filter((drawing) => drawing.type === 'canvas');
      })
    );
  }

  get mapDrawings$(): Observable<Drawing[]> {
    return this._drawings$.pipe(
      map((arr) => {
        return arr.filter((drawing) => drawing.type === 'map');
      })
    );
  }

  get primaryDrawing() {
    return this._drawings$.value.find((drawing) => drawing.drawingCategory === 'primary');
  }
}

export type GetDrawingsResponse = Array<DatabaseDrawingData>;

export type DatabaseDrawingData = {
  id: string;
  drawing_id: DrawingID;
  assignment_id: number;
  primary_id: number;
  created_at: Date;
  updated_at: Date;
  drawing_data: Drawing;
};

export type DrawingData = {
  id: string;
  drawingID: DrawingID;
  assignmentID: number;
  primaryID: number;
  createdAt: Date;
  updatedAt: Date;
  drawingData: Drawing;
};

export type DrawingID = string;

export type DrawingType = 'canvas' | 'map';

export type DrawingCategory = 'primary' | 'auxiliary';

export type Drawing = {
  drawingID: DrawingID;
  type: DrawingType;
  drawingCategory: DrawingCategory;
  title: string;
  description: string;
  mapData?: MapData;
  drawingDetails?: DrawingDetails;
  captureURL?: string;
  geoJSON?: GeoJSONFeatureCollection;
  backgroundURL?: string;
  backgroundIMG?: File;
  metadata?: Record<string, string>;
};

export type MapData = {
  resolution: number;
  zoomLevel: number;
  centerCoordinates: [number, number];
  extent: [number, number, number, number];
  layers: Array<LayerData>;
  rotation: number;
  selectedFeatures: SelectedFeature[];
  projection: string;
};

export type DrawingDetails = Record<string, string | number>;

export type LayerData = {
  id: string;
  name: string;
  visible: boolean;
  opacity: number;
  type: string;
  url?: string; // Optional as not all layers may have a URL (e.g., local vector layers)
};

type SelectedFeature = {
  featureId: string;
};
