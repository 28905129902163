<div style="height: 100%; width: 100%" fxLayout="row" fxLayout.xs="column">


  <div fxLayout="row" fxFlex="calc(100% - 73px)" fxFlex.xs="calc(100%-56px)">
    <app-scheduler-main-panel
      fxFlex
      [props]="childProps"
      [events]="events"
      [ticketTags]="ticketTags"
      [resources]="resources"
      [rigResources]="rigResources"
      [drillerResources]="drillerResources"
      [helperResources]="helperResources"
      [peopleResources]="peopleResources"
      [resourceEquipmentResources]="resourceEquipmentResources"
      [tickets]="tickets"
      [parentEvents]="parentEvents"v
      [readonly]="readonly"
      (dateChangedEvent)="getEventsByDateRange($event)"></app-scheduler-main-panel>

    <!-- <app-scheduler-create-ticket-panel fxFlex="456px" *ngIf='showCreateTicketPanel' [props]='childProps' [tickets]='tickets'>
    </app-scheduler-create-ticket-panel> -->

    <app-scheduler-filter-panel
      fxFlex="320px"
      *ngIf="showFilterPanel"
      [props]="childProps"
      [peopleResources]="peopleResources"
      [rigResources]="rigResources"></app-scheduler-filter-panel>

    <app-scheduler-show-hide-resources-panel
      fxFlex="320px"
      *ngIf="showShowHideResourcesPanel"
      [props]="childProps"
      [rigResources]="rigResources"
      [activeResources]="activeRigResources"
      (activeResourcesChange)="updateActiveRigs($event)"></app-scheduler-show-hide-resources-panel>

    <app-scheduler-ticket-panel
      fxFlex="320px"
      *ngIf="showTicketPanel"
      [props]="childProps"
      [tickets]="tickets"></app-scheduler-ticket-panel>
  </div>

  <app-navigation-rail
    [inputsStart]="topNav"
    [inputsEnd]="bottomNav"
    fxShow="true"
    fxShow.xs="false"></app-navigation-rail>
  <app-bottom-navigation [inputs]="allNav" fxShow="false" fxShow.xs="true"></app-bottom-navigation>
</div>
