import {Injectable} from "@angular/core";
import {BehaviorSubject, from, Observable} from "rxjs";
import {api, apiKeys} from "src/app/ENDPOINTS";
import {UtilocateApiService} from "../../modules/core/api/utilocateapi.service";
import {UtilocateApiRequest} from "../../modules/core/api/baseapi.service";
import {UserService} from "src/app/modules/core/services/user/user.service";
import {SettingID} from "src/app/modules/core/services/user/setting";
import { LocateStatusID } from "src/app/modules/shared/ticket-details/ticket-details.module";

@Injectable({
  providedIn: "root",
})
export class FieldsideService {
  constructor(private utilocateApiService: UtilocateApiService, private userService: UserService) { }

  Latitude: any
  Longitude: any
  /**
   * call api to gather the list of tickets that can be self assigned
   * @returns result of api call
   */
  async gatherSelfAssignTickets() {
    try {
      const url = apiKeys.u2.gatherSelfAssignTickets;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: null,
      };

      const response = await from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).toPromise();
      const value = JSON.parse(response.body.value);
      const tickets = value.tickets;
      return tickets;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * 
   * @param lat 
   * @param long
   * Takes the users location and the location of the ticket and calculates there proximity 
   * @returns proximity 
   */

  async calculateProximity(lat: string, long: string): Promise<number> {
    if (lat == null || long == null || lat.length === 0 || long.length === 0) {
      return null;
    }
    try {
      return new Promise<number>((resolve, reject) => {
        const useKm = this.userService.isSettingActive(SettingID.KM_FOR_PROXIMITY);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.Latitude = position.coords.latitude;
              this.Longitude = position.coords.longitude;
              const distance = this.calculateDistance(parseFloat(lat), parseFloat(long), this.Latitude, this.Longitude, useKm);
              const formattedDistance: any = useKm ? `${distance.toFixed(2)} km` : `${distance.toFixed(2)} mi`
              resolve(formattedDistance);
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
        } else {
          reject(new Error('Geolocation not supported'));
        }
      });
    } catch (error) {
      console.error(error.message);
      throw error;
    }
  }

  /**
   * Calculates the distance between two points on a map in MILES or KILOMETERS
   * @param {number} lat1 coordinate point
   * @param {number} lon1 coordinate point
   * @param {number} lat2 coordinate point
   * @param {number} lon2 coordinate point
   * @param {boolean} isMiles whether to use miles or not 
   * @returns 
   */
  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number, isMiles: boolean): number {
    const R = isMiles ? 3958.8 : 6371; // Earth radius in miles or kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in miles or kilometers
    return distance;
  }


  /**
 * Takes a date and figures out the color associated to its lateness
 * @returns lateness color
 */

  getColorBasedOnDate(dateString, locateStatusID) {
    const millisecondsInDay: number = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const currentDate: Date = new Date();
    const targetDate: Date = new Date(dateString);
    let completeColor = '#0000FF'
    let completed = false
    
    // Calculate the difference in days
    let daysDifference: number = Math.floor((targetDate.getTime() - currentDate.getTime()) / millisecondsInDay);

    const colors: string[] = [
      '#FF0000',  // Red
      '#FF6E00',
      '#FFC800',
      '#FFFF00',
      '#C8FF00',
      '#00FF00', // Green
    ];

    // const colors: string[] = [
    //   'red',
    //   'orangered',
    //   'darkorange',
    //   'gold',
    //   'limegreen',
    //   'green',
    // ];
    daysDifference++
    // Calculate the color index based on the days difference
    let colorIndex = 0;
    if (daysDifference >= 5) {
      colorIndex = 5;
    } else if (daysDifference <= 0) {
      colorIndex = 0;
    } else {
      colorIndex = Math.min(daysDifference, colors.length - 1);
    }
    
    if(locateStatusID == LocateStatusID.LOCATE_COMPLETED){  
    completed = true
  }

    return completed ? completeColor : colors[colorIndex];
  }

  /**
   * call ticket action api to reassign ticket to self
   * @param PrimaryID PrimaryID of ticket to be assigned
   * @param userIdToAssign userID to reassign ticket to
   * @returns result of api call
   */
  async selfAssignTicket(PrimaryID: string, userIdToAssign: string) {
    try {
      const apiKey = apiKeys.u2.reassignTicketAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      value[PrimaryID] = {Value: userIdToAssign};

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      return result;
    } catch (error) {
      console.error(error);
    }
  }
}
