import { Injectable, NgZone } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { SnackbarType } from "./snackbar/snackbar";
import { SnackbarComponent } from "./snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  defaultConfig: MatSnackBarConfig = {
    duration: 3000,
  };

  constructor(private _snackBar: MatSnackBar, private zone: NgZone) {}

  //TODO design to replace or add on top?

  /**
   * Opens a snackbar
   * @param message
   * @param snackbarType
   * @param actionLabel
   * @param customConfig
   * @returns snack bar reference
   */
  openSnackbar(
    message: string,
    snackbarType: SnackbarType = SnackbarType.default,
    actionLabel?: string,
    customConfig?: MatSnackBarConfig
  ) {
    let config = this.defaultConfig;
    let actionLabelColour = '#FFFFFF';

    switch (snackbarType) {
      case SnackbarType.default:
        config.duration = 3000;
        config.panelClass = ["mat-snack-bar-container-default"];
        break;
      case SnackbarType.success:
        config.duration = 3000;
        config.panelClass = ["mat-snack-bar-container-success"];
        break;
      case SnackbarType.warning:
        config.duration = 5000;
        config.panelClass = ["mat-snack-bar-container-warn"];
        actionLabelColour = '#000000';
        break;
      case SnackbarType.error:
        config.duration = 5000;
        config.panelClass = ["mat-snack-bar-container-error"];
        actionLabelColour = '#000000';
        break;
      case SnackbarType.info:
        config.duration = 10000;
        config.panelClass = ["mat-snack-bar-container-info"];
        break;
      default:
        config.duration = 3000;
        config.panelClass = ["mat-snack-bar-container-default"];
        break;
    }
    if (customConfig) {
      config = customConfig;
    }
    this.openSnackbarWithIcon(message, snackbarType, actionLabel, config, actionLabelColour);
  }

  /**
   * Opens a snackbar with icon
   * @param message 
   * @param snackbarType 
   * @param actionLabel 
   * @param config 
   * @param actionMsg
   */
  public openSnackbarWithIcon(
    message: string,
    snackbarType: SnackbarType = SnackbarType.default,
    actionLabel?: string,
    config?: MatSnackBarConfig, 
    actionLabelColour?: string
  ) {
    const _snackType: SnackbarType = snackbarType !== undefined ? snackbarType : SnackbarType.success;
    const snackBar = this._snackBar.openFromComponent(SnackbarComponent, {
      duration: config.duration, 
      horizontalPosition: "center", 
      verticalPosition: "bottom",
      panelClass: config.panelClass,
      data: {message: message, snackType: _snackType, actionLabel: actionLabel, actionLabelColour: actionLabelColour}
    });
    snackBar.onAction().subscribe(() => {
      snackBar.dismiss();
    });
  }
}
