import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserManagerEditorComponent } from "./user-manager-editor/user-manager-editor.component";
import { MaterialModule } from "../../shared/material.module";
import { Setting } from "../../core/services/user/setting";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { ClientUserEditorComponent } from './client-user-editor/client-user-editor.component';
import { EditSettingsComponent } from "../../user-category-editor/settings-group/edit-settings.component";
import { NgxColorsModule } from "ngx-colors";

export interface UserEditor {
  HexColour: any;
  UserID: number;
  CloudUserID: number;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  TwoWayNum: string;
  EmployeeID: number;
  UserCategoryID: number;
  Supervisor: number;
  TruckNum: string;
  FirstLog: number;
  Password: string;
  Archived: number;
  Settings?: Setting[];
  ClientUsers?: any[];
  isCloudSettingsUser?: number;
}

@NgModule({
  declarations: [UserManagerEditorComponent, ClientUserEditorComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, FormsModule, EditSettingsComponent, NgxColorsModule],
  exports: [UserManagerEditorComponent],
})
export class UserManagerEditorModule {}
