/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { CreateTicketComponentService, OptionsFillID } from "src/app/modules/create-ticket/create-ticket-component.service";
import { FAMItem } from "../../fab/floating-action-menu/floating-action-menu.component";
import { CallModalComponent } from "../modals/call-modal/call-modal.component";
import { DatetimeSelectionModalComponent } from "../modals/datetime-selection-modal/datetime-modal.component";
import { EditTimeRangeModalComponent } from "../modals/edit-timerange/edit-timerange.component";
import { TicketService } from "../ticket.service";
import { ProgressBarService } from "../../progress-bar/progress-bar.service";
import { AdminLookupService } from "src/app/modules/core/admin/admin-lookup.service";
import { ADMIN_TABLE_NAMES, COMPLETION_TABLE_NAMES } from "src/app/modules/core/admin/tables";
import { AssignLocatorModalComponent } from "../../ticket-assignment/assignLocatorModal/assign-locator-modal.component";
import { CompletedLocateStatusIDs, LocateStatusID } from "../../ticket-details/ticket-details.module";
import { CreateTicketDialogComponent } from "src/app/modules/create-ticket/create-ticket-dialog/create-ticket-dialog.component";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { UtilocateAdminCacheService } from "src/app/modules/core/cache/utilocate-admin.service";
import { safeAwait } from "src/app/modules/core/validators/validator";
import { debounceTime } from "rxjs";
import { UtilocateCompletionCacheService } from "../services/cache/utilocate-completions.service";
import { ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";
import { SnackbarType } from "../../snackbar/snackbar/snackbar";
import { SnackbarService } from "../../snackbar/snackbar.service";

@Component({
  selector: "app-ticket-details",
  templateUrl: "ticket-details.component.html",
  styleUrls: ["./ticket-details.component.scss"],
})
export class TicketDetailsComponent implements OnInit, OnChanges {
  @Input() TicketDetails: any;
  @Input() PrimaryID: any;
  @Input() TicketDetailSummary: any;
  @Input() props;

  TicketDetailFormProps: any = {};
  navLinks: any = [];

  dateControl: FormControl;
  @Output() TicketDetailsUpdate = new EventEmitter<any>();
  @Output() TimeInOutToggle = new EventEmitter<any>();
  @Output() ExcavationDateChange = new EventEmitter<any>();
  @Output() EmailChange = new EventEmitter<any>();
  @Output() InputChange = new EventEmitter<any>();
  @Output() TicketTagsChange = new EventEmitter<any>();
  @Output() AppointmentDateChange = new EventEmitter<any>();
  @Output() OpenGoogleMapsDirections = new EventEmitter<any>();
  @Output() Swipe = new EventEmitter<any>();
  @Output() finishAssignUserToTicket = new EventEmitter<any>();
  @Output() finishSendToLSP = new EventEmitter<any>();
  @Output() cancelTicketEvent = new EventEmitter<any>();
  @Output() uncompleteTicketEvent = new EventEmitter<any>();


  isLoaded = false;

  fabMenu: FAMItem[];
  bottomMenuOpen: boolean = false;
  timeFormatted: any;
  lsps: any;
  assignToUserModal: any;
  CallTypeID: any
  UserID: any
  LocateStatusID: any

  constructor(
    public dialog: MatDialog,
    private datetimeService: DatetimeService,
    private ticketService: TicketService,
    private userService: UserService,
    private progressBarService: ProgressBarService,
    private admin$: AdminLookupService,
    public assignUserModal: MatDialog,
    public createTicketDialog: MatDialog,
    private utilocateAdminService: UtilocateAdminCacheService,
    private createticketService: CreateTicketComponentService,
    private utilocateCompletionService: UtilocateCompletionCacheService,
    private snackBarService: SnackbarService,

  ) { }

  async ngOnInit() {
    this.fabMenu = this.getFabForUser();
    this.setExcavationDateActive();
    const options = { dateStyle: "full", timeStyle: "medium" };
    this.timeFormatted = this.TicketDetailSummary.DigDate.toLocaleString(
      undefined,
      options
    );
    
    const ticket = await this.ticketService.getTicketAssignment(this.TicketDetailSummary.AssignmentID);
    this.CallTypeID = ticket["CallTypeID"];
    this.UserID = this.userService.getUserID();
    this.LocateStatusID = ticket["LocateStatusID"]

    this.lsps = await this.ticketService.getLSPs(
      this.TicketDetailSummary.AssignmentID,
      this.PrimaryID.toString());
  }

  getFabForUser() {
    const fabMenu:FAMItem[] = [
      {
        label: "Get Directions",
        icon: "direction",
        action: this.openGoogleMapsDirections,
        iconType:'svg'
      }
    ];

    if (this.userService.isSettingActive(SettingID.CALL_EXCAVATOR)) {
      fabMenu.push({
        label: "Call Excavator",
        icon: "phone",
        action: this.callExcavator,
        iconType:'svg'
      })
    }
    
    //if this user has this setting    
    this.getLocateStatusID().then((locateStatusID) => {
      if (this.userService.isSettingActive(SettingID.ENABLE_QUICK_TICKET_ACTIONS)) {
        if (this.userService.isSettingActive(SettingID.COPY_TICKET)) {
        fabMenu.push({
          label: "Copy Ticket",
          icon: "copy",
          action: this.onCopyTicket,
          iconType: 'svg'
        });
      }
        //if the locate is completed, you can uncomplete the ticket 
        if (CompletedLocateStatusIDs.includes(locateStatusID)) {
          if (this.userService.isSettingActive(SettingID.UNCOMPLETE_TICKET)) {
          fabMenu.push({
            label: "Uncomplete Ticket",
            icon: "undo",
            action: this.onUncompleteTicket,
          });
        }
          //else if the locate is not sent to lsp or completed by lsp, you can cancel and assign the ticket 
        } else if (locateStatusID != LocateStatusID.SENT_TO_LSP && locateStatusID != LocateStatusID.COMPLETED_BY_LSP) {
          if (this.userService.isSettingActive(SettingID.CANCEL_TICKET)) {
            fabMenu.push({
              label: "Cancel Ticket",
              icon: "close_circle",
              action: this.onCancelTicket,
              iconType: 'svg'
            })
          }
          if (this.userService.isSettingActive(SettingID.REASSIGN_TICKET)) {
            fabMenu.push({
              label: "Assign Ticket",
              icon: "sendable",
              action: this.onAssignTicket,
              iconType: 'svg'
            })
          }
          if (this.userService.isSettingActive(SettingID.SET_APPOINTMENT_DATE)) {
            fabMenu.push({
              label: "Set Appointment",
              icon: "calendar",
              action: this.openSetAppointmentModal,
              iconType:'svg'
            })
          }
        }
      }
    });
    return fabMenu;
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.fabMenu = this.getFabForUser();
    if (this.TicketDetailSummary) {
      this.dateControl = new FormControl(
        new Date(this.TicketDetailSummary["DigDate"])
      );
      this.dateControl.valueChanges.subscribe((newDate) =>
        this.ExcavationDateChange.emit(new Date(newDate).toISOString())
      );
    }
    
    let alreadySaving = false
   
    if (this.TicketDetails) {
      const viewsKeys = Object.keys(this.TicketDetails);
      for (let i = 0; i < viewsKeys.length; i++) {
        const viewsKey = viewsKeys[i];

        this.TicketDetailFormProps[viewsKey] = {
          view: this.TicketDetails[viewsKey],
          views: this.TicketDetails,
        };

       const tags = await this.getTicketTagOptions()
       this.TicketDetails[viewsKey].groups.workDetails.fields.TicketTags.options = tags
        if (this.TicketDetails[viewsKey].formGroup.get("ExcavationDate")) {
          this.TicketDetails[viewsKey].formGroup
            .get("ExcavationDate")
            .valueChanges.subscribe((newExcavationDate) => {
              this.ExcavationDateChange.emit(
                new Date(newExcavationDate).toISOString()
              );
            });
        }
        let tbCompletions_AssignmentToTags = await this.ticketService.getAssignmentToTags(this.TicketDetailSummary.AssignmentID)
        
        if (this.TicketDetails[viewsKey].formGroup.get("TicketTags")) {
          this.TicketDetails[viewsKey].formGroup
          .get('TicketTags')
          .valueChanges.pipe(debounceTime(300))
          .subscribe(async (data) => {
            const isPristine = this.TicketDetails[viewsKey].formGroup.pristine;
            if (!isPristine && !alreadySaving) {
              alreadySaving = true;
              if (tbCompletions_AssignmentToTags) {
                const updatedTags = data.reduce((total, current) => {
                  total.push(current['value']);
                  return total;
                }, []);
  
                const existingTags = tbCompletions_AssignmentToTags.reduce((total, current) => {
                  total.push(current['TagID']);
                  return total;
                }, []);
              
                const newTicketTags = [];
                for (let i = 0; i < tbCompletions_AssignmentToTags.length; i++) {
                  const curExistingTicketTag = tbCompletions_AssignmentToTags[i];
                  
                  if (updatedTags.indexOf(curExistingTicketTag['TagID']) > -1) {
                    // keep existing tags
                    
                    newTicketTags.push({
                      ...curExistingTicketTag,
                      bFieldRemoved: 0,
                      bFieldAdded: 1,
                      bUpdated: true,
                    });
                  } else if (updatedTags.indexOf(curExistingTicketTag['TagID']) == -1) {
                    // remove de-selected tags
                    newTicketTags.push({
                      ...curExistingTicketTag,
                      bFieldRemoved: 1,
                      bFieldAdded: 0,
                      bUpdated: true,
                    });
                  }
                }
  
                for (let i = 0; i < data.length; i++) {
                  if (existingTags.indexOf(data[i]['value']) == -1) {
                    newTicketTags.push({
                      AssignmentID: this.TicketDetailSummary.AssignmentID,
                      TagID: data[i]['value'],
                      bFieldAdded: 1,
                      bFieldRemoved: 0,
                      bUpdated: true,
                    });
                  }
                }
                
                tbCompletions_AssignmentToTags = [...newTicketTags];
               await this.utilocateCompletionService.addTicketTagsToAssignment(tbCompletions_AssignmentToTags, this.TicketDetailSummary.AssignmentID)
        
                alreadySaving = false;
                this.TicketTagsChange.emit()
              }
            }
          });
    
           
            
        }
      }

      this.navLinks = viewsKeys;
      this.isLoaded = true;
    }
  }

  async getTicketTagOptions(){
  const ticketTagsList = [];
  const ticketTagsResult = await this.createticketService.getFilterOptionsDB(
    OptionsFillID.ticketTags
  );
  ticketTagsResult.forEach(element => {
    ticketTagsList.push({name: element.text, value: element.value});
  });

    return ticketTagsList
  }

  async getLocateStatusID() {
    const locateStatusDesc = this.TicketDetails.ticketInfo.formGroup.get("LocateStatusID").value;
    const [tbAdmin_LocateStatus, error] = await safeAwait(
      this.utilocateAdminService.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_LocateStatus
      )
    );
    return tbAdmin_LocateStatus.find(
      (row) => row["LocateStatusDesc"] == locateStatusDesc
    )["LocateStatusID"];
  }


  onEmailChange(event) {
    this.EmailChange.emit(event)
  }

  onInputChange(event) {
    this.InputChange.emit(event)
  }


  onTimeToggle(event) {
    const AssignmentID = sessionStorage.getItem("AssignmentID");
    this.ticketService.insertTicketChangedToIDB(AssignmentID);
    if (this.TicketDetailSummary.timeInOutStatus == "Edit Time") {
      const cleanTimeIn: Date = this.datetimeService.dbDateToLocalDate(
        this.TicketDetailSummary["TimeIn"]
      );
      const timeIn: string = this.datetimeService.dbDateToFormattedLocalDate(
        this.TicketDetailSummary["TimeIn"],
        "HH:mm"
      );
      const timeOut: string = this.datetimeService.dbDateToFormattedLocalDate(
        this.TicketDetailSummary["TimeOut"],
        "HH:mm"
      );

      this.dialog
        .open(EditTimeRangeModalComponent, {
          width: "380px",
          data: {
            header: "Edit Time In/Out",
            startTime: timeIn,
            endTime: timeOut,
            date: cleanTimeIn,
          },
        })
        .afterClosed()
        .subscribe((next) => {
          if (
            next &&
            next["result"] &&
            next["result"]["TimeIn"] &&
            next["result"]["TimeOut"]
          ) {
            this.TimeInOutToggle.emit({
              TimeIn: this.datetimeService.localDateToDBDateStr(
                next["result"]["TimeIn"]
              ),
              TimeOut: this.datetimeService.localDateToDBDateStr(
                next["result"]["TimeOut"]
              ),
            });
          }
        });
    } else {
      const toggleTime = this.datetimeService.localDateToDBDateStr(new Date());
      if (this.TicketDetailSummary.timeInOutStatus == "Start Time") {
        this.TimeInOutToggle.emit({ TimeIn: toggleTime });
      } else {
        this.TimeInOutToggle.emit({ TimeOut: toggleTime });
      }
    }
  }

  setExcavationDateActive = async () => {
    let locateStatusID = await this.getLocateStatusID();
    if (!this.userService.isSettingActive(50) && !CompletedLocateStatusIDs.includes(locateStatusID) && locateStatusID != LocateStatusID.COMPLETED_BY_LSP) {
      this.fabMenu.push({
        label: "Set Excavation Date",
        icon: "excavation date",
        action: this.openSetExcavationModal,
        iconType: 'svg'
      });
    }
  };

  openSetExcavationModal = () => {
    try {
      const ticketTime = this.TicketDetailSummary.DigDate;

      const dialogObservable = this.dialog
        .open(DatetimeSelectionModalComponent, {
          width: "450px",
          data: {
            header: "Excavation Date",
            startTime: ticketTime,
            date: ticketTime,
            datetimeEditor: true,
          },
        })
        .afterClosed()
        .subscribe((next) => {
          if (next) {
            const AssignmentID = sessionStorage.getItem("AssignmentID");
            this.ticketService.insertTicketChangedToIDB(AssignmentID);
            this.ExcavationDateChange.emit(next.result);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  callExcavator = () => {
    this.dialog.open(CallModalComponent, {
      width: "380px",
      data: {
        alternateName: this.TicketDetails.ticketInfo.formGroup.value.AlternateContact,
        alternateNumber: this.TicketDetails.ticketInfo.formGroup.value.AlternativeNumber,
        name: this.TicketDetails.ticketInfo.formGroup.value.CallerName,
        number: this.TicketDetails.ticketInfo.formGroup.value.PhoneNumber,
      },
    });
  };

  openSetAppointmentModal = () => {
    try {
      const ticketTime = this.TicketDetailSummary.AppointmentDate;
      const dialogObservable = this.dialog
        .open(DatetimeSelectionModalComponent, {
          width: "450px",
          data: {
            header: "Appointment Date",
            startTime: ticketTime,
            date: ticketTime,
            datetimeEditor: true,
          },
        })
        .afterClosed()
        .subscribe((next) => {
          if (next) {
            const AssignmentID = sessionStorage.getItem("AssignmentID");
            this.ticketService.insertTicketChangedToIDB(AssignmentID);
            this.AppointmentDateChange.emit(next.result);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  openGoogleMapsDirections = () => {
    this.OpenGoogleMapsDirections.emit();
  };

  trackByFn(index, item) {
    return index;
  }

  onSwipe(event) {
    this.Swipe.emit(event);
  }

  onAssignTicket = () => {
    this.initAssignTicketToUser();
  };

  async initAssignTicketToUser() {
    try {
      this.progressBarService.start();
      const queryResult = await this.admin$.getLookupTables([
        ADMIN_TABLE_NAMES.tbLogin_Users,
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
      ]);
      this.progressBarService.stop();

      if (queryResult && queryResult[0] && queryResult[1]) {
        const userList = queryResult[0]["Data"]; // tbLogin_Users rows
        const userCategoryList = queryResult[1]["Data"]; // tbLogin_UserCategories rows

        const formattedData = this.formatUserList(userList, userCategoryList);
        this.assignToUserModal = this.openAssignToUserModal(formattedData);
        this.assignToUserModal.afterClosed().subscribe(async (nextValue) => {
          // this.workDetails.patchValue({ CurrentLocatorID: nextValue["UserID"] });
          // await this.ticket$.updateSavedDataToLocalDb(this.AssignmentID, this.workDetails, this.workDetailsTemplateGroup);
          if (nextValue) {
            if (nextValue["UserID"] != null) {
              this.finishAssignUserToTicket.emit(nextValue);
            } else if (nextValue["LSPID"]) {
              this.finishSendToLSP.emit(nextValue);
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  formatUserList(userList: [], userCategoryList: []) {
    try {
      const formattedUserList = userList.reduce((total, current) => {
        // non-archived users only
        if (current["Archived"] != null && current["Archived"] == 0) {
          const name = current["FirstName"] + " " + current["LastName"];
          const userCategoryID = current["UserCategoryID"];
          const UserID = current["UserID"];
          let UserCategoryDesc = "";

          for (let i = 0; i < userCategoryList.length; i++) {
            if (userCategoryList[i]["UserCategoyID"] == userCategoryID) {
              UserCategoryDesc = userCategoryList[i]["Description"];
              break;
            }
          }

          const formattedName = `${name} (${UserCategoryDesc})`;
          total.push({
            name: formattedName,
            value: UserID,
          });
        }

        return total;
      }, []);

      return formattedUserList;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  openAssignToUserModal(listData) {
    return this.assignUserModal.open(AssignLocatorModalComponent, {
      width: "560px",
      // height: '250px',
      data: {
        dataKey: listData,
        lsps: this.lsps,
      },
    });
  }

  onCancelTicket = () => {
    this.cancelTicketEvent.emit(LocateStatusID.OFFICE_CANCELLED);
  };

  onUncompleteTicket = () => {
    this.onUncompleteTicketEvent();
  }


  async onUncompleteTicketEvent() {
    let locateStatus = this.getLocateStatusID()
    const apiValue = {};
    apiValue[this.PrimaryID] = {
      Check: false,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: 'Warning: Invalidating Completion Package',
        message: `You have requested to uncomplete a ticket, this will invalidate previous completion package. 
        Are you sure you wish to proceed with this operation?`,
        confirmText: 'Yes, invalidate completion package',
        cancelText: 'No, cancel',
        confirmColor: 'primary',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ticketService
          .unCompleteTicket(apiValue, this.TicketDetailSummary.AssignmentID.toString(), this.UserID)
          .subscribe(async (response) => {
            if (response && response[0] && response[0]['Error']) {
              const errorMsg = response[0]['Error'].split(':')[1];
              this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
            } else {
              if (this.LocateStatusID == LocateStatusID.SENT_TO_LSP) {
               await this.initAssignTicketToUser()
              }
              this.snackBarService.openSnackbar('Ticket \'uncompleted\' successfully', SnackbarType.success);
              this.uncompleteTicketEvent.emit();
            }
           return true
          });
      } else {
        this.snackBarService.openSnackbar('Did not uncomplete ticket', SnackbarType.info);
      }
    });
  }

  onCopyTicket = () => {
    console.log("copy");

    const ticketDialog = this.createTicketDialog.open(
      CreateTicketDialogComponent,
      {
        width: window.innerWidth.toString() + "px",
        maxWidth: "100%",
        height: window.innerHeight.toString() + "px", //TODO: if small screen make full screen
        panelClass: "margin-dialog-container",
        data: {
          AssignmentID: this.TicketDetailSummary.AssignmentID,
          PrimaryID: this.PrimaryID.toString(),
        },
      }
    );

    ticketDialog.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  };
}
