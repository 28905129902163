export const FormTemplateValidators = {
  noWhitespaceValidator: (noWhitespaceValidator: any) => {
    let isWhitespace: boolean = false;
    try {
      if (noWhitespaceValidator && noWhitespaceValidator.value) {
        if (!Array.isArray(noWhitespaceValidator.value)) {
          if (
            typeof noWhitespaceValidator.value === "object" &&
            noWhitespaceValidator.value["text"]
          ) {
            isWhitespace =
              noWhitespaceValidator.value["text"].trim().length === 0;
          } else if (typeof noWhitespaceValidator.value === "string") {
            isWhitespace =
              (noWhitespaceValidator.value || "").trim().length === 0;
          }
        }
      }
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    } catch (error) {
      return false;
    }
  },
};
