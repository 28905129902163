<h2 mat-dialog-title>Create Options</h2>
<div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
    <mat-radio-group
      color="primary"
      aria-label="Create Options"
      (change)="onSelectionChange($event)"
    >
      <div fxLayout="column" fxLayoutGap="8px" class="p-4">
        <mat-radio-button *ngIf="assignmentID != null" value="paste"
          ><span style="font-weight: 500"
            >Create From Copied Ticket</span
          ></mat-radio-button
        >
        <mat-radio-button value="ticket">Create Ticket</mat-radio-button>
        <mat-radio-button value="event">Create Event</mat-radio-button>
        <mat-radio-button value="note">Create Note</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    style="width: 100%"
  >
    <button mat-button (click)="close()">CANCEL</button>
    <button mat-button color="primary" [mat-dialog-close]="value">
      SELECT
    </button>
  </div>
</div>
