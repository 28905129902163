import { Component, OnInit, Input } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { BaseComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ProgressBarService } from "../../progress-bar/progress-bar.service";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { SnackbarType } from "../../snackbar/snackbar/snackbar";

import { UploadDocumentService } from "./upload-document.service";

@Component({
  selector: "app-upload-documents",
  templateUrl: "./upload-documents.component.html",
})
export class UploadDocumentsComponent extends BaseComponent implements OnInit {
  className = "UploadDocumentsComponent";

  selectedFiles;
  numOfSavedFiles: number;
  @Input() readonly: boolean;

  constructor(
    logger$: LoggerService,
    messaging$: ComponentMessagingService,
    private uploadDoc$: UploadDocumentService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);
  }

  ngOnInit() {
    this.selectedFiles = []; // {name, base64}
    this.numOfSavedFiles = 0;
    this.listenForDocumentChanges();
  }

  listenForDocumentChanges() {
    this.uploadDoc$
      .getOnDocumentDataChange()
      .subscribe((message: ActionMessage) => {
        switch (message.action) {
          case Actions.UPDATE_DOCUMENT_DATA:
            this.numOfSavedFiles = message.data["DocumentsLen"];
            break;
          case Actions.RESET_DATA:
            this.selectedFiles = [];
            this.numOfSavedFiles = 0;
            break;
        }
      });
  }

  onSelectFile(event) {
    try {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0
      ) {
        let numSelectedFiles = event.target.files.length;
        this.progressBarService.start();

        let loadedSuccessfully = 0;
        for (let i = 0; i < numSelectedFiles; i++) {
          let fileReader = new FileReader();

          // callback when file is read
          fileReader.onload = (evt: any) => {
            let base64Img: string = evt.target.result.split(",")[1]; // remove mime data
            this.selectedFiles.push({
              name: event.target.files[i].name,
              file: base64Img,
            });
            loadedSuccessfully++;

            // update progress bar for each upload
            if (loadedSuccessfully == numSelectedFiles) {
              this.uploadDoc$
                .prepareDocumentsForUpload(this.selectedFiles)
                .pipe(
                  switchMap((zippedFiles) => {
                    return this.uploadDoc$.updateDocumentData(
                      new ActionMessage(Actions.UPDATE_DOCUMENT_DATA, {
                        value: zippedFiles,
                      })
                    );
                  })
                )
                .subscribe((successfulSave: boolean) => {
                  if (successfulSave) {
                    this.selectedFiles = [];
                    this.snackBarService.openSnackbar(
                      "Success: Saved all documents",
                      SnackbarType.success
                    );
                  } else {
                    this.snackBarService.openSnackbar(
                      "Try again: Failed to save documents",
                      SnackbarType.error
                    );
                  }
                  this.progressBarService.stop();
                });
            }
          };
          // read file
          fileReader.readAsDataURL(event.target.files[i]);
        }
      } else {
        // no files selected or cannot be loaded
      }
    } catch (error) {
      this.logger$.error("UploadDocumentsComponent", "onSelectFile", error);
      this.progressBarService.stop();
    }
  }
}
