<div class="main-div">
  <h1 class="mat-h1">Edit Tables</h1>
  <p class="mat-body">
    Manage your company's employees, utilities, and work flow.
  </p>
  <h1 class="mat-h2">Favourites</h1>
  <mat-divider></mat-divider>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="card-holder"
    fxLayoutGap="16px"
  >
    <div *ngFor="let card of favouriteCards" class="card-padding">
      <app-card [cardOptions]="card"></app-card>
    </div>
  </div>
  <h1 class="mat-h2">All</h1>
  <mat-divider></mat-divider>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="card-holder"
    fxLayoutGap="16px"
  >
    <div *ngFor="let card of cards" class="card-padding">
      <app-card [cardOptions]="card"></app-card>
    </div>
  </div>
</div>
