import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import { CompletionsStoreService } from "src/app/modules/core/admin/completions-store.service";
import { BaseTemplateFormComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { UploadDocumentService } from "src/app/modules/shared/upload-documents/upload-documents/upload-document.service";
import { CreateTicketComponentService } from "../../../create-ticket-component.service";
import { switchMap } from "rxjs/operators";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { ConfirmationDialogComponent } from "src/app/modules/shared/confirmation-dialog/confirmation-dialog.component";
import { FormControl } from "@angular/forms";
import { FAMItem } from "src/app/modules/shared/fab/floating-action-menu/floating-action-menu.component";
@Component({
  selector: "app-create-ticket-docs-view",
  templateUrl: "./create-ticket-docs-view.component.html",
  styleUrls: ["./create-ticket-docs-view.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketDocsViewComponent
  extends BaseTemplateFormComponent
  implements OnDestroy
{
  @Output() toggleCreateBtnWhileLoading = new EventEmitter<any>();

  @ViewChild("docsSelectBox") docsSelectBox: MatCheckbox;
  @ViewChild("files") fileSelectionList: MatSelectionList;
  @ViewChild("fileInput4") fileInput4: ElementRef;
  @ViewChild("fileInput10") fileInput10: ElementRef;

  selectedFiles = [];
  addedFiles = [];
  numOfSavedFiles: number;
  fileToUpload: File = null;
  documents = {};
  img: any
  selection: any = [0];
  selected = new FormControl(0);
  fabMenu: FAMItem[];
  bottomMenuOpen = false;


  protected tabs = [
    { index: 0, title: 'list', icon: 'list' },
    { index: 1, title: 'gallery', icon: 'photo' },
  ];

  constructor(
    logger$: LoggerService,
    compMsg$: ComponentMessagingService,
    private uploadDoc$: UploadDocumentService,
    private ticket$: CreateTicketComponentService,
    private completions$: CompletionsStoreService,
    public dialog$: MatDialog,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$, compMsg$);
  }

  init() {
    this.className = "CreateTicketDocsViewComponent";
    this.categoryLinks = [];
    this.myViewName = "docs";
    super.init();
  }

  ngOnInit(): void {
    try {
      if (this.props && this.props["messageService"]) {
        this.parentMsgSubscriber = this.props["messageService"]
          .getMessageStream()
          .subscribe((nextMsg: ComponentMessage) => {
            this.logger$.log(this.className + " : ParentListener");
          });
      }
    } catch (error) {
      this.logger$.error(this.className + " : error " + error.message);
    }
    this.fabMenu = [
      {
        label: "Add Internal Document",
        icon: "note_add",
        action: this.addInternalFileClicked,
      },
      {
        label: 'Add Office Document',
        icon: 'note_add',
        action: this.addOfficeFileClicked
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"] &&
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"]
      ) {
        this.views = changes["props"]["currentValue"]["views"];
        this.myView =
          changes["props"]["currentValue"]["views"][this.myViewName];
        if (changes["props"]["currentValue"]["documents"]) {
          this.documents = changes["props"]["currentValue"]["documents"];
          if (
            this.documents["Documents"] &&
            this.documents["Documents"].length > 0
          ) {
            for (const [index, docObj] of this.documents["Documents"].entries()) {
              this.addedFiles[index].name = docObj.name;
              this.addedFiles[index].desc = this.getDocDesc(docObj.docTypeID);
            }
          } else {
            this.addedFiles = [];
          }
        } else {
          this.addedFiles = [];
        }
        this.refresh();
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  /**
   *
   * @param event source, checked:bool
   */
  onDocsCheckChange(event) {
    try {
      if (event) {
        if (event.checked) {
          this.fileSelectionList.selectAll();
        } else {
          this.fileSelectionList.deselectAll();
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  addInternalFileClicked = () => {
    this.fileInput4.nativeElement.click();
  };

  addOfficeFileClicked = () => {
    this.fileInput10.nativeElement.click();
  };

  handleFileInput(files: FileList, docTypeID = 6) {
    try {
      this.progressBarService.start();
      this.toggleCreateBtnWhileLoading.emit(true);

      if (files && files.length > 0) {
        let numOfFiles = files.length;
        let loadedSuccessfully = 0;
        for (let i = 0; i < numOfFiles; i++) {
          let fileReader = new FileReader();
          fileReader.onload = (evt: any) => {            
            let base64: string = evt.target.result.split(",")[1];
            this.selectedFiles.push({
              name: files[i].name,
              file: base64,
              docTypeID: docTypeID,
            });
            loadedSuccessfully++;
            let typeDesc = this.getDocDesc(docTypeID);
            
            this.addedFiles.push({ img: evt.target.result, name: files[i].name, desc: typeDesc });
            
            // update progress bar for each upload
            if (loadedSuccessfully == numOfFiles) {
              this.uploadDoc$
                .prepareDocumentsForUpload(this.selectedFiles)
                .pipe(
                  switchMap((zippedFiles) => {
                    return this.uploadDoc$.updateDocumentData(
                      new ActionMessage(Actions.UPDATE_DOCUMENT_DATA, {
                        value: zippedFiles,
                      })
                    );
                  })
                )
                .subscribe((successfulSave: boolean) => {
                  if (successfulSave) {
                    this.selectedFiles = [];
                    this.snackBarService.openSnackbar(
                      "Saved all documents",
                      SnackbarType.success
                    );
                  } else {
                    this.snackBarService.openSnackbar(
                      "Failed to save documents",
                      SnackbarType.error
                    );
                  }
                  this.toggleCreateBtnWhileLoading.emit(false);
                  this.progressBarService.stop();
                });
            }
          };
          fileReader.readAsDataURL(files[i]);
        }
      } else {
        this.progressBarService.stop();
      }
      // this.fileSelectionList.deselectAll();
      // this.docsSelectBox.checked = false;
    } catch (error) {
      this.progressBarService.stop();
      this.logger$.error(this.className + " : error " + error.message);
    }
  }

  getDocDesc(docTypeID) {
    let typeDesc = "Document";
    if (docTypeID == 4) {
      typeDesc = "Internal";
    } else if (docTypeID == 10) {
      typeDesc = "Office";
    }
    return typeDesc;
  }

  onDeleteDocs(event) {
    try {
      if (event && event.length > 0) {
        let filenames = [];
        for (let file of event) {
          filenames.push(file.value.name);
        }
        if (filenames.length > 0) {
          //Remove the filenames in IDB
          let ticketID = this.ticket$.getCurrentCreateTicketID();
          if (ticketID) {
            //props change when changes. so done here
            this.completions$
              .removeDocumentData(ticketID, filenames)
              .toPromise();
          }
        }
      }
      this.fileSelectionList.deselectAll();
      this.docsSelectBox.checked = false;
    } catch (error) {
      this.logger$.error("onDeleteDocs: " + error.message);
    }
  }

  onDeleteDoc(file) {
    try {
      // if (file && file.name) {
      //   let filename = file.name;
      //   //Remove the filename in IDB
      //   let ticketID = this.ticket$.getCurrentCreateTicketID();
      //   if (ticketID) {
      //     //props change when changes. so done here
      //     this.completions$.removeDocumentData(ticketID, filename).toPromise();
      //   }
      // }
      this.openDialog(
        "Delete Document",
        "Are you sure you want to delete document?",
        () => {},
        "warn",
        "Delete",
        file
      );
    } catch (error) {
      this.logger$.error("onDeleteDocs: " + error.message);
    }
  }

  deleteDocument(file) {
    try {
      if (file && file.name) {
        let filename = file.name;
        //Remove the filename in IDB
        let ticketID = this.ticket$.getCurrentCreateTicketID();
        if (ticketID) {
          //props change when changes. so done here
          this.completions$.removeDocumentData(ticketID, filename).toPromise();
        }
      }
    } catch (error) {
      this.logger$.error("onDeleteDocs: " + error.message);
    }
  }

  openDialog(
    title: string,
    message: string,
    action: Function,
    confirmColor: string,
    confirmText: string,
    file
  ): void {
    const dialogRef = this.dialog$.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: title,
        message: message,
        confirmColor: confirmColor,
        confirmText: confirmText,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDocument(file);
      }
    });
  }
}
