import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";

import { apiKeys, api } from "src/app/ENDPOINTS";
import { switchMap } from "rxjs/operators";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { UploadProgress } from "../upload-documents/upload-progress";

@Injectable({
  providedIn: "root",
})
export class UploadTicketService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {
    this.logger$.log("Created Upload Ticket Service");
  }

  // input: stringify ticket row, ticket row's ID
  uploadTicket(data: UploadProgress): Observable<UploadProgress> {
    return new Observable((subscriber) => {
      if (data && data.body) {
        let progress = JSON.parse(data.body);
        if (!progress["row"]["metadata"]) {
          this.uploadTicketDataToServer(
            JSON.stringify(progress["row"]["Ticket"])
          )
            .pipe(
              switchMap((apiResponse: any) => {
                if (apiResponse && apiResponse) {
                  if (this.validateServerResponse(apiResponse.body)) {
                    let AssignmentID = JSON.parse(apiResponse.body)[
                      "AssignmentID"
                    ];
                    progress = { ...progress, AssignmentID };
                    return of(
                      new UploadProgress(
                        JSON.stringify(progress),
                        true,
                        data.msg + "Ticket API Response: Successfully created"
                      )
                    );
                  } else {
                    // lambda error
                    return of(
                      new UploadProgress(
                        apiResponse.body,
                        false,
                        data.msg +
                          "Ticket API Response: Lambda Failed to create ticket"
                      )
                    );
                  }
                } else {
                  // api error
                  return of(
                    new UploadProgress(
                      apiResponse.body,
                      false,
                      data.msg +
                        "Ticket API Response: API Failed to create ticket"
                    )
                  );
                }
              })
            )
            .subscribe((uploadTicketResponse: UploadProgress) => {
              subscriber.next(uploadTicketResponse);
              subscriber.complete();
            });
        } else {
          // already uploaded this ticket
          subscriber.next(
            new UploadProgress(
              data.body,
              true,
              data.msg + "Function Error: Already Uploaded this Ticket",
              true
            )
          );
          subscriber.complete();
        }
      } else {
        // failed to access ticket info
        subscriber.next(
          new UploadProgress(
            null,
            false,
            data.msg + "Function Error: Failed to Gather Information"
          )
        );
        subscriber.complete();
      }
    });
  }

  private uploadTicketDataToServer(strBody: string): Observable<any> {
    if (strBody) {
      const url = apiKeys.u2.createTicket;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.createTicket,
        API_TYPE: type,
        API_BODY: JSON.parse(strBody),
      };

      return from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      );
    }

    return of({ ok: false });
  }

  private validateServerResponse(strResult: string): boolean {
    let success: boolean = false;

    try {
      let jsonResult = JSON.parse(strResult);
      if (
        jsonResult &&
        jsonResult["TicketCreated"] &&
        jsonResult["TicketCreated"] === "1"
      ) {
        success = true;
      }
    } catch (error) {
      this.logger$.warn(
        "UploadTicket$: validateTicketResult: function fail: ",
        error
      );
    }
    return success;
  }
}
