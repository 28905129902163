<div
  style="height: 100%; width: 100%"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <img style="padding: 0 0 30px 0" src="/assets/png/tn_logo.png" alt="image" />
  <h4 class="mat-headline-4" style="text-align: center">
    Visionneuse de documents /
  </h4>
  <h4 class="mat-headline-4" style="padding: 0 0 32px 0; text-align: center">
    Document Viewer
  </h4>
  <div style="width: 100%" fxLayout="row" fxLayoutAlign="center center">
    <button
      fxFlex="426px"
      fxFlex.lt-sm="80"
      mat-flat-button
      color="primary"
      style="margin-bottom: 12px"
      (click)="onClickFrench()"
    >
      Français
    </button>
  </div>
  <div style="width: 100%" fxLayout="row" fxLayoutAlign="center center">
    <button
      fxFlex="426px"
      fxFlex.lt-sm="80"
      mat-flat-button
      color="primary"
      (click)="onClickEnglish()"
    >
      English
    </button>
  </div>
</div>
