export const localStorageKeys = {
  TOKEN_KEY: "COMPETERS_TOKEN",
  DOC_VIEWER_TOKEN_KEY: "DOC_VIEWER_TOKEN_KEY",
  TOKEN_PAYLOAD_KEY: "data",
  CACHE_DATE_ADMIN_LOOKUP_KEY: "CACHE_DATE_ADMIN_LOOKUP",
  CURRENT_CREATE_TICKET_KEY: "CURRENT_CREATE_TICKET_KEY",
  B_CLEAN: "B_CLEAN",
  USER_SESSION: "USER_SESSION",
  SCHEDULER_DATE: "SCHEDULER_DATE",
  TICKET_CHANGED: "TICKET_CHANGED",

  // key/value pair of url params
  // values MUST MATCH param keys EXACTLY
  URL_KEYS: {
    page: "page",
    authorization: "authorization",
    requestnumber: "requestnumber",
    requestid: "requestid",
    excavatorcode: "excavatorcode",
    assignmentid: "assignmentid",
    documentid: "documentid",
    documenttoangularid: "documenttoangularid",
    resourceid: "resourceid",
    eventdate: "eventdate",
    primaryid: "primaryid",
    iframemodal: "iframemodal",
    AssignmentID: "AssignmentID",
  },

  VISIBLE_URL_KEYS: {
    requestnumber: "requestnumber",
    requestid: "requestid",
    excavatorcode: "excavatorcode",
    documentid: "documentid",
    documenttoangularid: "documenttoangularid",
  },
};
