import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GraphicInfo } from 'src/app/modules/core/cache/GraphicsInterface';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { DocumentService } from 'src/app/modules/core/services/document/document.service';
import { DownloadDocumentService } from 'src/app/modules/core/services/document/download-document.service';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { NotificationType } from 'src/app/modules/core/services/logger/Notification';
import { MapService } from 'src/app/modules/core/services/map/map.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { U3_USER_CATEGORY_ID } from 'src/app/modules/core/services/user/user';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { EsriMapSimpleDrawingComponent } from '../../../esri-map/esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component';
import { LayerOptions, MapOptions } from '../../../esri-map/esri-map-set/EsriMapModels';
import { FAMItem } from '../../../fab/floating-action-menu/floating-action-menu.component';
import {
  agmMapControlPosition as position,
  agmMapTypeControlStyle as style,
} from '../../../google-map/google-maps-set/agmOptions';
import { GoogleMapsDrawingComponent } from '../../../google-map/google-maps-set/google-maps-set.component';
import { googleMapsOptions, MarkerObject } from '../../../google-map/google-maps-set/googleMapsOptions';
import { ProgressBarService } from '../../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { SnackbarType } from '../../../snackbar/snackbar/snackbar';
import { TicketDetailsService } from '../../ticket-details.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() props: any;

  // Esri Maps
  @ViewChild('esriDrawing') esriDrawing: EsriMapSimpleDrawingComponent;

  mapOptions: MapOptions = {
    zoom: 18,
    center: null,
  };
  layerOptions: LayerOptions;
  identifyEnabled: boolean = false;

  // Google Maps
  @ViewChild('cmpGoogleMapsDrawing') googleMaps: GoogleMapsDrawingComponent;
  googleMapsProps: {} = {};
  readonly options: googleMapsOptions = {
    zoomControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: style.DROPDOWN_MENU,
      position: position.TOP_RIGHT,
    },
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
  };

  // Shared
  graphics: GraphicInfo[];
  markers: MarkerObject[];

  // Menus
  googleMapsFab: FAMItem[];
  esriMapFab: FAMItem[];

  // Other
  isEsriVisibile: boolean = false;
  bottomMenuOpen: boolean = false;

  esriAvailableSetting: boolean = false;
  esriFirstSetting: boolean = false;

  viewingAddress: boolean = false;
  ticketLatLng: MarkerObject;
  ticketAddressLatLng: MarkerObject;

  isUtilityUser: boolean = false;

  @Output() newAutologEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDocumentEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private logger$: LoggerService,
    private map$: MapService,
    private ticketDetails$: TicketDetailsService,
    private downloadDoc$: DownloadDocumentService,
    private userService: UserService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private documentService: DocumentService,
    private datetimeService: DatetimeService
  ) {}

  ngOnInit(): void {
    this.esriMapFab = [
      {
        label: 'Take Screenshot',
        icon: 'photo_camera',
        action: this.takeScreenshot,
      },
    ];

    this.googleMapsFab = [
      {
        label: 'Get Directions',
        icon: 'directions',
        action: this.getDirections,
      },
    ];

    this.map$.getEsriData(true).subscribe((result: LayerOptions) => {
      this.layerOptions = result;
    });

    this.setSettings();
    this.getDigsite();
  }

  async setSettings() {
    this.esriAvailableSetting = this.userService.isSettingActive(SettingID.ESRI_MAP_AVAILABLE);
    this.esriFirstSetting = this.userService.isSettingActive(SettingID.ESRI_MAP_DEFAULT);
    if (this.esriAvailableSetting && this.esriFirstSetting) {
      this.isEsriVisibile = true;
    }
  }

  getMapCenter() {}

  async ngOnChanges(changes: SimpleChanges) {
    // console.log('map changes: ', changes);

    if (changes.props && changes.props.currentValue && changes.props.currentValue.AssignmentID) {
      await this.setupLocation();
    }
    if (!changes.firstChange && changes.props.currentValue) {
      if (
        changes.props.currentValue.U3UserCategoryID != null &&
        changes.props.currentValue.U3UserCategoryID == U3_USER_CATEGORY_ID.Utility
      ) {
        this.isUtilityUser = true;
        // this.bottomMenuOpen = false;
      }
    }
  }

  async setupLocation() {
    try {
      let ticketLocation = await this.ticketDetails$.getTicketLatLong(this.props.AssignmentID);
      let ticketAddress = await this.ticketDetails$.getTicketAddress(this.props.AssignmentID);
      let addressLatLng: any = null;
      try {
        addressLatLng = await this.googleMaps.getLatLngFromAddress({
          address: ticketAddress,
        });
      } catch (error) {
        console.log('Failed to ge the lat long from the address: ', ticketAddress);
      }

      if (ticketLocation.lat && ticketLocation.lng) {
        this.mapOptions = {
          ...this.mapOptions,
          center: [ticketLocation.lng, ticketLocation.lat],
        };
        this.googleMapsProps['TicketLatLng'] = {
          Latitude: ticketLocation.lat,
          Longitude: ticketLocation.lng,
        };
        this.ticketLatLng = {
          position: {
            lat: Number(ticketLocation.lat),
            lng: Number(ticketLocation.lng),
          },
        };
        this.markers = [this.ticketLatLng];
        this.googleMapsProps = { ...this.googleMapsProps };
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.mapOptions = {
              ...this.mapOptions,
              center: [position.coords.longitude, position.coords.latitude],
            };
            this.googleMapsProps['TicketLatLng'] = {
              Latitude: position.coords.latitude,
              Longitude: position.coords.longitude,
            };
            this.googleMapsProps = { ...this.googleMapsProps };
            this.snackBarService.openSnackbar('Failed to get Ticket Location', SnackbarType.default);
          },
          (error) => {
            this.snackBarService.openSnackbar(
              'Failed to get Ticket Location and Current Location',
              SnackbarType.default
            );
          }
        );
      }

      if (addressLatLng && addressLatLng.lat && addressLatLng.lng) {
        this.ticketAddressLatLng = {
          position: {
            lat: addressLatLng.lat,
            lng: addressLatLng.lng,
          },
        };
      } else if (ticketLocation.lat && ticketLocation.lng) {
        this.ticketAddressLatLng = this.ticketLatLng;
      }
    } catch (error) {
      this.logger$.error('MapComponent, setupLocation: ', error);
    }
  }

  getDigsite() {
    this.map$.gatherDigsites().subscribe((results) => {
      if (results && results['value']) {
        var digsites = JSON.parse(results['value']);
        var graphics: GraphicInfo[] = [];

        digsites.DigsiteData.forEach((digsite) => {
          var newGraphic: GraphicInfo = {
            graphicID: digsite['DigsiteShapeID'],
            type: digsite['DigsiteTypeID'],
            coordinates: JSON.parse(digsite['Coordinates']),
          };
          graphics.push(newGraphic);
        });

        this.logger$.log('graphics: ', graphics);
        this.graphics = graphics;
      }
    });
  }

  saveDigsite(digsites) {
    try {
      this.map$.saveDigsite(digsites).subscribe((result) => {
        if (result && result['PossibleError'] == null) {
          this.getDigsite();
          this.snackBarService.openSnackbar('Successfully Saved Digsites', SnackbarType.success);
        }
      });
    } catch (error) {
      this.logger$.error('MapComponent, saveDigsite: ', error);
    }
  }

  async handleTakeScreenshot() {
    let filename = this.props.RequestNumber + ' - ' + this.documentService.formatDateForFileName(new Date()) + '.jpg';
    let type = this.documentService.getExtensionFromFilename(filename);
    let zipname =
      this.documentService.formatDateForFileName(new Date()) +
      '_' +
      this.props.U2UserID +
      '_' +
      this.props.AssignmentID +
      '.zip';

    let result = await this.esriDrawing.takeScreenshot();
    let screenshot = this.dataURLtoFile(result.dataUrl, filename);

    this.progressBarService.start();

    let date = this.datetimeService.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: this.props.AssignmentID.toString(),
      CreationDate: date,
      Description: 'ESRI Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '' + this.props.RequestNumber,
      isSendable: '' + 0,
    };

    // add new autolog for new doc
    const newAutolog = {
      AssignmentID: this.props.AssignmentID,
      DescID: 4, // custom autolog desc id
      UserID: this.props.U2UserID,
      Explaination: 'Added new document',
      logDate: date,
      bFieldAdded: 1,
    };

    // add new TEMP doc row
    let tmpDocRowID = await this.downloadDoc$.getHighestLocalDocumentId(this.props.AssignmentID);
    const tmpDocRow = {
      DocumentID: tmpDocRowID,
      AssignmentID: this.props.AssignmentID,
      PrimaryID: this.props.PrimaryID,
      CreationDate: date,
      Description: 'ESRI Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '' + this.props.RequestNumber,
      isSendable: '' + 0,
    };

    let addDocLocallyResult = await Promise.all([
      this.downloadDoc$.addDocumentLocally(this.props.AssignmentID, tmpDocRowID, {
        FileName: filename,
        Blob: new Blob([screenshot], { type: screenshot.type }),
        type: type,
      }),
      this.downloadDoc$.addLocalDocumentRow(this.props.AssignmentID, tmpDocRow),
      this.downloadDoc$.uploadDocument(screenshot, zipname, metadata, this.props.ClientID),
    ]);

    this.newAutologEvent.emit({ data: newAutolog });
    this.newDocumentEvent.emit({ data: tmpDocRow });

    if (addDocLocallyResult) {
      this.snackBarService.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackBarService.openSnackbar('Failed to upload', SnackbarType.success);
    }

    this.progressBarService.stop();
  }

  private dataURLtoFile(dataUrl, fileName) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  toggleBottomMenu() {
    this.bottomMenuOpen = !this.bottomMenuOpen;
  }

  toggleLayers() {
    this.esriDrawing.toggleLayers();
  }

  toggleLegend() {
    this.esriDrawing.toggleLegend();
  }

  toggleBasemap() {
    this.esriDrawing.toggleBasemap();
  }

  toggleMap() {
    this.isEsriVisibile = !this.isEsriVisibile;
  }

  toggleCoordinates() {
    if (this.viewingAddress) {
      this.markers = [this.ticketLatLng];
      this.esriDrawing.goTo(this.ticketLatLng.position.lat, this.ticketLatLng.position.lng);
      this.googleMaps.centerByGoogleLocation(this.ticketLatLng.position);
    } else {
      this.markers = [this.ticketAddressLatLng];
      this.esriDrawing.goTo(this.ticketAddressLatLng.position.lat, this.ticketAddressLatLng.position.lng);
      this.googleMaps.centerByGoogleLocation(this.ticketAddressLatLng.position);
    }
    this.viewingAddress = !this.viewingAddress;
  }

  toggleIdentify() {
    this.esriDrawing.toggleIdentify();
    this.identifyEnabled = this.esriDrawing.identifyEnabled;
  }

  takeScreenshot = () => {
    this.handleTakeScreenshot();
  };

  getDirections = () => {
    this.esriDrawing.getDirections();
  };
}
