import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  effect,
  signal,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-competers-search-bar',
  providers: [],
  standalone: true,
  imports: [MatIconModule, FormsModule, MatButtonModule, CommonModule],
  template: `
    <div
      class="flex flex-row justify-between items-center h-full w-full rounded-md border-solid
  {{ search === document.activeElement ? 'border-2 border-primary m-0' : 'border-1 border-[rgba(0,0,0,0.12)] m-[1px]' }}
  {{ search !== document.activeElement ? 'hover:border-[rgba(0,0,0,0.36)] hover:border-2 hover:m-0' : '' }}"
      (click)="focusSearch()">
      <div class="h-full flex flex-row flex-grow justify-start items-center pl-1.5">
      @if(addLeftSpacing) {
        <div class="h-full w-8"></div>
      }
      <mat-icon svgIcon="search" class="fill-[rgba(0,0,0,0.36)]" />
        <input
          #search
          class="search-text box-border h-full w-full bg-transparent outline-none border-none font-normal"
          autocomplete="off"
          [placeholder]="placeHolderText"
          [ngModel]="_searchValue$$()"
          (ngModelChange)="onSearchValueChange($event)"
          (keyup.enter)="emitSearchValue()" />
      </div>
      <div class="h-full flex flex-row justify-end items-center w-fit">
        @if (search.value !== '') {
          <button mat-icon-button aria-label="clear" (click)="clearSearch()" class="bg-transparent">
            <mat-icon>close</mat-icon>
          </button>
        }
      </div>
      @if(trailing && trailing.length > 0) {
        <mat-icon svgIcon={{trailing}} class="px-2 fill-[rgba(0,0,0,0.36)] text-lg" />
      }
    </div>
  `,
  styleUrls: ['./competers-search-bar.component.scss'],
})
export class CompetersSearchBarComponent implements OnDestroy {
  @ViewChild('search') search: ElementRef<HTMLInputElement>;
  @Output() public searchValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeHolderText: string = 'Search...';
  @Input() clickToSearch: boolean = false;
  @Input() trailing: string;
  @Input() addLeftSpacing: boolean;

  protected _searchValue$$ = signal('');
  inputSubscription: Subscription;

  constructor() {
    effect(() => {
      if (!this.clickToSearch) {
        this.searchValueChange.emit(this._searchValue$$());
      }
    });
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  focusSearch() {
    this.search.nativeElement.focus();
  }

  clearSearch() {
    this._searchValue$$.set('');
    this.search.nativeElement.focus();
    this.emitSearchValue();
  }

  onSearchValueChange(value: string) {
    this._searchValue$$.set(value);
    // if we aren't in click-to-search mode, OR 
    // we've just cleared the search term (either by button click or backspace)
    if (!this.clickToSearch || value.length === 0) {
      this.searchValueChange.emit(value);
    }
  }

  emitSearchValue() {
    this.searchValueChange.emit(this._searchValue$$());
  }

  protected readonly focus = focus;
  protected readonly document = document;
}
