import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { MapLegendService } from "./legend.service";
import {
  IsIterablePipe,
  IterableGroupNamePipe,
  LegendPipe,
} from "./legend.pipe";
import {
  AsyncPipe,
  CommonModule,
  NgClass,
  NgTemplateOutlet,
} from "@angular/common";

@Component({
  selector: "app-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    LegendPipe,
    NgClass,
    NgTemplateOutlet,
    IterableGroupNamePipe,
    IsIterablePipe,
    AsyncPipe,
  ],
})
export class LegendComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();

  legendData$;

  constructor(private myService: MapLegendService) {}

  ngOnInit(): void {
    this.legendData$ = this.myService.featureJson;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
