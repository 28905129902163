<h2
  mat-dialog-title
  class="font-rajdhani uppercase font-semibold text-headline-5 pl-4">
 Document Type
</h2>
<mat-dialog-content style="overflow-y: hidden" class="font-montserrat text-lg">
  What type of document is this?

  <mat-form-field appearance="outline" class="mt-3 font-rajdhani uppercase font-semibold">
    <mat-select class="font-rajdhani font-semibold" [(value)]="selectedOption" placeholder="Select type">
      <mat-option
      class="font-rajdhani font-medium"
        (click)="typeSelected()"
        *ngFor="let type of types"
        [value]="type"
        >{{ type }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions class="flex flex-row items-center justify-end space-x-2 pt-0">
  <button class="btn-cancel" mat-dialog-close>Cancel</button>
  <button mat-button class="btn-save bg-primary text-[white]" disabled="{{enableConfirm}}" (click)="onConfirm($event)">Confirm</button>
</div>
  