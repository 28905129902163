import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-create-input-view",
  templateUrl: "./create-input-view.component.html",
  styleUrls: ["./create-input-view.component.scss"],
  providers: [ComponentMessagingService],
})
export class CreateInputViewComponent
  extends BaseViewComponent
  implements OnInit, OnChanges
{
  className = "CreateInputViewComponent";

  // receive all required info through props
  @Input() props: {};

  infoFormGroup: FormGroup;
  allFields: Object[];

  inputs: Object[];
  dropdowns: Object[];
  datepickers: Object[];

  myName: string;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService
  ) {
    super(logger$);
  }

  // happens once
  ngOnInit() {
    try {
      // these values never change
      this.myName = this.props["name"];
      this.infoFormGroup = this.props["infoFormGroup"];
    } catch (error) {
      this.logger$.error(this.className, "ngOnInit", "could not init");
    }
  }

  // happens every time @Input changes
  ngOnChanges(changes: SimpleChanges) {
    // this value can change often
    if (
      changes["props"]["currentValue"] &&
      changes["props"]["currentValue"]["inputs"]
    ) {
      this.allFields = changes["props"]["currentValue"]["inputs"];
    }
    this.infoFormGroup = changes["props"]["currentValue"]["infoFormGroup"];

    try {
      if (this.allFields) {
        this.inputs = this.allFields.filter((input) => {
          return input["type"] ? input["type"].indexOf("input") > -1 : false;
        });

        this.dropdowns = this.allFields.filter((input) => {
          return input["type"] ? input["type"].indexOf("dropdown") > -1 : false;
        });

        this.datepickers = this.allFields.filter((input) => {
          return input["type"] ? input["type"].indexOf("date") > -1 : false;
        });
      } else {
        // this.logger$.log("No inputs");
      }
    } catch (error) {
      this.logger$.error(this.className, "ngOnInit", "could not init");
    }
  }

  getMyMessage() {
    this.logger$.log(this.infoFormGroup.getRawValue());
    const rawDetails = this.infoFormGroup.getRawValue();
    let details = { ...rawDetails, Province: "ON" };
    return details;
  }
}
