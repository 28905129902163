<div class="autolog-item-container">
  <div
    class="autolog-item-content-container"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      class="app-bottom-8-margin"
    >
      <div>
        <p class="mat-subtitle-1 mb-1 blue">{{ autolog.DescIDDesc | SplitCamelCase }}</p>
        <p class="mat-body-xs app-bottom-4-margin font-medium">{{ userName }}</p>

      </div>
      <div>
        <p class="mat-body-xs ">{{ formattedDate }}</p>
      </div>
    </div>

    <div>

      <span class="mat-body-xs app-bottom-4-margin font-medium lowercase">{{ autolog.Explaination }}</span>
    </div>
  </div>
</div>
