import { computed, inject, Injectable, Signal, signal } from '@angular/core';
import { AdminLookupService } from 'src/app/modules/core/admin/admin-lookup.service';
import { ADMIN_TABLE_NAMES } from '../../../modules/core/admin/tables';
import { SnackbarService } from '../../../modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../modules/shared/snackbar/snackbar/snackbar';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';
import { UtilocateApiService } from '../../../modules/core/api/utilocateapi.service';
import { isEqual } from 'lodash-es';
import { LatLon } from '../../../modules/drawing-module/services/open-layers.service';
import { api, apiKeys } from '../../../ENDPOINTS';
import { UtilocateApiRequest } from '../../../modules/core/api/baseapi.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  //services
  private apiService = inject(UtilocateApiService);
  private snackBarService = inject(SnackbarService);
  private adminLookupService = inject(AdminLookupService);
  // members
  private _users: Array<User> = [];
  private _users$ = new BehaviorSubject<Array<User>>([]);
  private _users$$ = signal<Array<User>>([]);
  private _userCategories: Array<UserCategory> = [];
  private _userCategories$$ = signal<Array<UserCategory>>([]);
  private _locatorCheckins$ = new BehaviorSubject<LocatorCheckins>(null);

  constructor(private router: Router) {
    this.setupUsers();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // Call your function here
      if (event.url.includes('home')) {
        this.setupUsers();
      }
    });
  }

  /**
   * Sets up the users by pulling from tbLogin_Users local table
   *
   * @memberof UsersService
   */
  setupUsers() {
    this.adminLookupService
      .getLookupTables([ADMIN_TABLE_NAMES.tbLogin_Users, ADMIN_TABLE_NAMES.tbLogin_UserCategories])
      .then(([{ Data: userData }, { Data: userCategories }]) => {
        this.updateUserHexColours(userData);
        this._users = userData;
        this._users$.next(this._users);
        this._users$$.set(this._users);
        this._userCategories = userCategories.map((x) => {
          return {
            UserCategoryID: x.UserCategoyID,
            Title: x.Title,
            AccessLevel: x.AccessLevel,
            Description: x.Description,
          };
        });
        this._userCategories$$.set(this._userCategories);
      })
      .catch(() => this.snackBarService.openSnackbar('Failed to load users', SnackbarType.error));
  }

  /**
   * Updates the users from tbLogin_Users table to have a hex colour if they don't have one already
   *
   * @param {*} userData
   * @memberof UsersService
   */
  updateUserHexColours(userData: any) {
    userData.forEach((element) => {
      if (this._users && this._users.length > 0) {
        //if we can find the user using the UserID in this._users
        const user = this._users.find((x) => x.UserID === element.UserID);
        element.HexColour = user.HexColour ?? null;
      }
      if (element.HexColour === null || element.HexColour === '' || element.HexColour.length < 6) {
        element.HexColour = this.generateRandomColor(element.HexColour);
      }
    });
  }

  /**
   * Generates a random colour if the input colour is not acceptable
   *
   * @param {string} inputColour
   * @return {string}
   * @memberof TicketMapService
   */
  generateRandomColor(inputColour: string): string {
    if (inputColour && inputColour != '' && inputColour != '#000000' && inputColour.toLowerCase() != '#ffffff')
      return inputColour;
    let returnedColor = '#';

    try {
      const letters = '0123456789ABC'; // left out lighter colours (DEF)
      for (let i = 0; i < 6; i++) {
        returnedColor += letters[Math.floor(Math.random() * letters.length)];
      }
    } catch (error) {
      console.error(error);
    }
    return returnedColor;
  }

  fetchLocatorCheckins() {
    const apiKey = apiKeys.u2.getUserCheckins;
    const url = apiKeys.u2[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKey,
      API_TYPE: type,
      API_BODY: { value: 'val' },
    };
    this.apiService.invokeUtilocateApi(utilocateApiRequest).then(({ ok, status, body }) => {
      if (ok && status == 200) {
        try {
          const value = JSON.parse(body.value);
          const { error, message, data } = value ?? {};
          if (data?.UserCoords != null) {
            const { UserCoords } = JSON.parse(data['UserCoords']);
            //give each user a random colour if they don't have a hex colour
            this.updateUserHexColours(UserCoords);
            this._locatorCheckins$.next(UserCoords);
          } else {
            if (error && message) {
              console.error('Users Service:', message);
            }
            this._locatorCheckins$.next([]);
          }
        } catch (e) {
          console.error('Users Service:', e);
        }
      } else {
        console.error('Users Service: Failed to fetch locator checkins');
      }
    });
  }

  get users() {
    return this._users;
  }

  get users$() {
    return this._users$.pipe();
  }

  get users$$() {
    return this._users$$.asReadonly();
  }

  get userCategories() {
    return this._userCategories;
  }

  getUserCategoriesByID(id: number) {
    return this._userCategories.find((x) => x.UserCategoryID === id);
  }

  get locatorCheckins$() {
    return this._locatorCheckins$.pipe(distinctUntilChanged((a, b) => isEqual(a, b)));
  }

  get locatorCheckins() {
    return this._locatorCheckins$.value;
  }

  /**
   * Returns locators
   */
  get locators(): Array<any> {
    return this.filterUsersByCategory(this._users, 'Locator');
  }

  /**
   * Returns a computed 'locators' signal
   */
  get locators$$(): Signal<Array<any>> {
    return computed(() => {
      return this.filterUsersByCategory(this._users$$(), 'Locator');
    });
  }

  /**
   * Returns the manager users
   * @returns {Array<any>} - the user
   */
  get managers() {
    return this.filterUsersByCategory(this.users, 'Manager');
  }

  get managers$$() {
    return computed(() => {
      return this.filterUsersByCategory(this._users$$(), 'Manager');
    });
  }

  getUserByID(userID: number) {
    return this._users.find((x) => x.UserID === userID);
  }

  private filterUsersByCategory(users, category: string) {
    try {
      const { UserCategoryID } = this._userCategories.find((x) => x.Title === category);
      if (!UserCategoryID) {
        return [];
      }
      return users.reduce((acc, user) => {
        if (user.UserCategoryID === UserCategoryID && user.Archived === 0) {
          acc.push(user);
        }
        return acc;
      }, []);
    } catch {
      return [];
    }
  }
}

export type User = {
  UserID: number;
  EmployeeID: number;
  UserCategoryID: number;
  Supervisor: number;
  FirstName: string;
  MiddleName: string | null;
  LastName: string;
  TruckNum: string;
  TwoWayNum: string;
  EmailAddress: string;
  Archived: number;
  FirstLog: number;
  SystemAccount: number;
  HexColour?: string;
};

export type UserData = Array<User>;

export type UserCategory = {
  UserCategoryID: number;
  Title: string;
  AccessLevel: number;
  Description: string;
};

export type UserCategoryData = Array<UserCategory>;

export type LocatorCheckin = {
  UserID: number;
  UserName: string;
  ActiveTimeInTicket: string;
  LastCreatedDate: string;
  Coords: Array<LatLon>;
  HexColour?: string;
};

export type LocatorCheckins = Array<LocatorCheckin>;
