<!-- <as-split unit="pixel" restrictMove="true" gutterSize="0">
  <as-split-area size="500" minSize="500" maxSize="500" lockSize="true">
    <app-create-side-panel [props]="sidePanelProps"></app-create-side-panel>
  </as-split-area>
  <as-split-area size="*">
    <app-create-main-panel [props]="mainPanelProps"></app-create-main-panel>
  </as-split-area>
</as-split> -->

<div style="height: 100%" fxLayout="row" fxLayoutAlign="start none">
  <app-create-side-panel
    class="side-panel"
    fxFlex.gt-sm="500px"
    fxFlex.lt-md="100"
    [props]="sidePanelProps"
  ></app-create-side-panel>
  <app-create-main-panel
    fxFlex
    fxHide.lt-md
    [props]="mainPanelProps"
  ></app-create-main-panel>
</div>
