import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { UtilocateSettingService } from "src/app/modules/core/cache/utilocate-settings.service";
import { CreateXMLService } from "src/app/modules/core/services/document/create-xml.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { isNumber } from "src/app/modules/core/validators/validator";
import { FAMItem } from "../../../fab/floating-action-menu/floating-action-menu.component";
import { InputTypes } from "../../../forms/recursiveFormOptions";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";
import { TicketDetailsService } from "../../ticket-details.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-ticket-pre-completions-details",
  templateUrl: "./ticket-detail-pre-completions.component.html",
  styleUrls: ["./ticket-detail-pre-completions.component.scss"],
})
export class TicketPreCompletionsDetaisComponent
  extends BasePageComponent
  implements OnChanges
{
  @Input() props;
  @Input() ticketProtectionMode = false;
  @Output() syncAfterAction = new EventEmitter<any>();
  @Output() formGroupChanged = new EventEmitter<any>();

  ticket: any = null;
  PrimaryID: any = null;
  AssignmentID: any = null;

  tbCompletions_PreCompletionDetails: any;
  tbCompletions_PreCompletionCategories: any;

  preCompletionFormObject: any;
  formGroups: any;

  categoryProps: any = {};

  fabMenu: FAMItem[] = [];
  fabDisabled: boolean = false;

  formChangeSubscription: Subscription;

  SHOW_CREATE_XML_SETTING_ID: string = "262";

  constructor(
    logger$: LoggerService,
    private ticketDetails$: TicketDetailsService,
    private xmlController$: CreateXMLService,
    private settingService$: UtilocateSettingService,
    private snackBarService: SnackbarService
  ) {
    super(logger$);
  }

  async ngOnInit() {
    let setting = await this.settingService$.getSetting(
      this.SHOW_CREATE_XML_SETTING_ID
    );
    if (setting && setting["Active"] == 1 && setting["Value"]) {
      let callTypeIDs = setting["Value"].split(",");
      let assignmentRow = await this.ticketDetails$.getAssignmentRow(
        this.props.AssignmentID
      );
      if (
        assignmentRow &&
        assignmentRow.CallTypeID &&
        callTypeIDs.indexOf(assignmentRow.CallTypeID.toString()) > -1
      ) {
        this.fabMenu.push({
          label: "Create Well Record XML",
          icon: "note_add",
          action: this.createXML,
        });
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.props && changes.props.currentValue) {
      if (changes.props.currentValue.PrimaryID != null) {
        this.PrimaryID = changes.props.currentValue.PrimaryID;
      }

      if (changes.props.currentValue.AssignmentID) {
        this.AssignmentID = changes.props.currentValue.AssignmentID;
      }

      if (
        changes.props.currentValue.tbCompletions_PreCompletionDetails &&
        changes.props.currentValue.tbCompletions_PreCompletionCategories &&
        changes.props.currentValue.ticket
      ) {
        this.ticket = changes.props.currentValue.ticket;
        this.tbCompletions_PreCompletionCategories =
          changes.props.currentValue.tbCompletions_PreCompletionCategories;
        this.tbCompletions_PreCompletionDetails =
          changes.props.currentValue.tbCompletions_PreCompletionDetails;

        await this.setupPreCompletions();
        this.checkTicketProtection();
      }
    }

    if (
      changes.ticketProtectionMode != null ||
      changes.props.currentValue.isU2TicketComplete != null
    ) {
      this.checkTicketProtection();
    }
  }

  createXML = () => {
    try {
      this.snackBarService.openSnackbar(
        "Creating Well Record XML",
        SnackbarType.default
      );
      this.fabDisabled = true;
      var self = this;
      this.xmlController$
        .createWellRecordXML(this.PrimaryID)
        .then(function (result) {
          // console.log(result);
          self.fabDisabled = false;
          self.snackBarService.openSnackbar(
            "Well record XML created, sync to get document",
            SnackbarType.success
          );
        })
        .catch(function (error) {
          console.log(error);
          self.fabDisabled = false;
          self.snackBarService.openSnackbar(
            "Well record XML faild",
            SnackbarType.error
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  checkTicketProtection() {
    if (this.formGroups) {
      if (this.ticketProtectionMode || this.props.isU2TicketComplete) {
        this.disableFormGroups();
      } else {
        this.enableFormGroups();
      }
    }
  }

  enableFormGroups() {
    for (let key in this.formGroups) {
      this.formGroups[key].enable();
    }
  }

  disableFormGroups() {
    for (let key in this.formGroups) {
      this.formGroups[key].disable();
    }
  }

  async setupPreCompletions() {
    let preCompletions: any = await this.createPreCompletionsFormObject();

    this.formGroups = preCompletions.formGroups;
    this.preCompletionFormObject = preCompletions.groups;

    this.updateFormValues();

    for (let categoryID in this.formGroups) {
      let formGroup = this.formGroups[categoryID];
      this.startListenForFormChanges(formGroup);
    }

    this.categoryProps = {
      view: preCompletions,
      ticket: this.ticket,
    };
  }

  async createPreCompletionsFormObject() {
    try {
      let categories = await this.ticketDetails$.getPreCompletionCategories(
        this.ticket.CallTypeID
      );
      let fields = await this.ticketDetails$.getPreCompletionFields(
        Object.keys(categories).map(Number)
      );

      let holding = {};
      let categoryTree = {};
      let fieldDictionary = {};

      // Add all the fields to the fieldDictionary, the key is the ParentCategoryInstanceID and the value is an array or field rows
      this.tbCompletions_PreCompletionDetails.forEach((row) => {
        if (!fieldDictionary[row.PreCompletionCategoryInstanceID]) {
          fieldDictionary[row.PreCompletionCategoryInstanceID] = {};
        }
        fieldDictionary[row.PreCompletionCategoryInstanceID][
          row.PreCompletionDetailID
        ] = { ...row, ...fields[row.PreCompletionFieldID] };
      });

      this.tbCompletions_PreCompletionCategories.forEach((row) => {
        // if the category does not have parentID then it is top level so add it to the dict
        if (row.ParentCategoryInstanceID == 0) {
          categoryTree[row.PreCompletionCategoryInstanceID] = {
            ...row,
            ...categories[row.PreCompletionCategoryID],
          };
          if (holding[row.PreCompletionCategoryInstanceID]) {
            categoryTree[row.PreCompletionCategoryInstanceID]["SubCategories"] =
              holding[row.PreCompletionCategoryInstanceID];
          }
          if (fieldDictionary[row.PreCompletionCategoryInstanceID]) {
            categoryTree[row.PreCompletionCategoryInstanceID]["Fields"] =
              fieldDictionary[row.PreCompletionCategoryInstanceID];
          }
        } else {
          // if the category is not a parent add it to holding
          if (!holding[row.ParentCategoryInstanceID]) {
            holding[row.ParentCategoryInstanceID] = {};
          }
          holding[row.ParentCategoryInstanceID][
            row.PreCompletionCategoryInstanceID
          ] = { ...row, ...categories[row.PreCompletionCategoryID] };

          if (fieldDictionary[row.PreCompletionCategoryInstanceID]) {
            holding[row.ParentCategoryInstanceID][
              row.PreCompletionCategoryInstanceID
            ]["Fields"] = fieldDictionary[row.PreCompletionCategoryInstanceID];
          }
        }
      });

      // The categories are in two objects, categoryTree has the the top level categories
      // and holding has the rest
      let categoryObj = this.linkCategories(categoryTree, holding);

      let result =
        await this.ticketDetails$.convertPreCompletionsToFormTemplate(
          categoryObj,
          this.tbCompletions_PreCompletionDetails
        );

      return result;
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  linkCategories(parentCategories, holding) {
    for (let key in parentCategories) {
      if (holding[key]) {
        parentCategories[key]["SubCategories"] = this.linkCategories(
          holding[key],
          holding
        );
      }
    }
    return parentCategories;
  }

  updateFormValues() {
    try {
      if (this.PrimaryID) {
        let formattedData = {};

        this.tbCompletions_PreCompletionDetails.forEach((row) => {
          if (row["PrimaryID"] == this.PrimaryID) {
            let categoryID = row["PreCompletionCategoryInstanceID"];
            let detailID = row["PreCompletionDetailID"];

            if (!formattedData[categoryID]) {
              formattedData[categoryID] = {};
            }
            let inputTypeID = this.getInputType(
              categoryID,
              detailID,
              this.preCompletionFormObject
            );

            // if a value already exists, format it before displaying
            if (row["FieldValue"]) {
              if (inputTypeID == InputTypes.Multiselect) {
                let tmpValues = row["FieldValue"].split(",");
                for (let i in tmpValues) {
                  tmpValues[i] = tmpValues[i].trim();
                }

                formattedData[categoryID][detailID] = tmpValues;
              } else if (inputTypeID == InputTypes.Datepicker) {
                console.log(row["FieldValue"]);

                let tmp = row["FieldValue"].split("T")[0];
                let dateParts = tmp.split("-");
                let year = Number(dateParts[0]);
                let month = Number(dateParts[1]) - 1;
                console.log(month);

                let day = Number(dateParts[2]);

                let date: Date = new Date();
                date.setMonth(month);
                date.setDate(day);
                date.setFullYear(year);

                console.log(date);

                formattedData[categoryID][detailID] = date;
              } else if (isNumber(row["FieldValue"])) {
                formattedData[categoryID][detailID] = parseFloat(
                  row["FieldValue"]
                );
              } else {
                formattedData[categoryID][detailID] = row["FieldValue"];
              }
            } else {
              formattedData[categoryID][detailID] = row["FieldValue"];
            }
          }
        });

        for (let key in this.formGroups) {
          this.formGroups[key].patchValue(formattedData[key]);
        }
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  getInputType(categoryID, detailID, categories): number {
    let inputTypeID = 0;

    if (categories[categoryID]) {
      if (
        categories[categoryID].fields &&
        categories[categoryID].fields[detailID]
      ) {
        inputTypeID = categories[categoryID].fields[detailID].inputTypeID;
      }
    } else {
      for (let key in categories) {
        if (categories[key].groups) {
          inputTypeID = this.getInputType(
            categoryID,
            detailID,
            categories[key].groups
          );
          break;
        }
      }
    }

    return inputTypeID;
  }

  findCategory(categoryID) {
    for (let key in this.preCompletionFormObject) {
      let category = this.preCompletionFormObject[key];
      if (category.key == categoryID) {
      }
    }
  }

  startListenForFormChanges(formGroup: any) {
    try {
      this.formChangeSubscription = formGroup.valueChanges
        .pipe(debounceTime(2000))
        .subscribe(() => {
          let isPristine = formGroup.pristine;
          if (!isPristine) {
            // only update when not first load
            this.formGroupChanged.emit({ saved: true });
            this.updatePreCompletionData();
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async preCompletionsUpdate(event) {
    try {
      let affectedGroupID = event[0];
      let visiblePrimaryDetailIDs = event[1][0];
      let hiddenPrimaryDetailIDs = event[1][1];
      let preCompletionCategory = this.getAffectedGroup(
        affectedGroupID,
        this.preCompletionFormObject
      );

      if (preCompletionCategory && preCompletionCategory.fields) {
        let preCompletionDetailIDs = Object.keys(preCompletionCategory.fields);

        for (
          let i = 0;
          i < this.tbCompletions_PreCompletionDetails.length;
          i++
        ) {
          let curPreCompletionsDetailID =
            this.tbCompletions_PreCompletionDetails[i]["PreCompletionDetailID"];
          if (
            preCompletionDetailIDs.includes(
              curPreCompletionsDetailID.toString()
            )
          ) {
            if (visiblePrimaryDetailIDs.includes(curPreCompletionsDetailID)) {
              // if field is already visible + has a value, do not reset
              if (
                this.formGroups[affectedGroupID].value[
                  curPreCompletionsDetailID
                ] == null ||
                this.formGroups[affectedGroupID].value[
                  curPreCompletionsDetailID
                ] == undefined
              ) {
                this.formGroups[affectedGroupID].patchValue({
                  [curPreCompletionsDetailID]: "",
                });
              }

              this.tbCompletions_PreCompletionDetails[i]["bFieldHidden"] = 1;
            } else if (
              hiddenPrimaryDetailIDs.includes(curPreCompletionsDetailID)
            ) {
              // hide and reset value
              this.formGroups[affectedGroupID].patchValue({
                [curPreCompletionsDetailID]: "",
              });
              this.tbCompletions_PreCompletionDetails[i]["bFieldHidden"] = 0;
            }
          }
        }
      }

      await this.updatePreCompletionData();
      this.formGroupChanged.emit({ saved: true });
    } catch (error) {
      console.error(error);
    }
  }

  getAffectedGroup(preCompletionCategoryInstanceID, groups) {
    let preCompletionCategory = null;
    try {
      if (groups[preCompletionCategoryInstanceID]) {
        preCompletionCategory = groups[preCompletionCategoryInstanceID];
      } else {
        for (let key in groups) {
          if (groups[key].groups) {
            preCompletionCategory = this.getAffectedGroup(
              preCompletionCategoryInstanceID,
              groups[key].groups
            );
            if (preCompletionCategory) {
              break;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return preCompletionCategory;
  }

  async updatePreCompletionData() {
    await this.ticketDetails$.updateSavedPreCompletionsDataToLocalDB(
      this.AssignmentID,
      this.formGroups,
      this.tbCompletions_PreCompletionDetails,
      this.tbCompletions_PreCompletionCategories
    );
  }

  async addCategories(event) {
    let rows = await this.ticketDetails$.addPreCompletionCategory(
      this.PrimaryID,
      event[0],
      event[1],
      this.tbCompletions_PreCompletionDetails,
      this.tbCompletions_PreCompletionCategories
    );

    this.tbCompletions_PreCompletionCategories = [
      ...this.tbCompletions_PreCompletionCategories,
      ...rows.tbCompletions_PreCompletionCategories,
    ];

    this.tbCompletions_PreCompletionDetails = [
      ...this.tbCompletions_PreCompletionDetails,
      ...rows.tbCompletions_PreCompletionDetails,
    ];

    await this.setupPreCompletions();
    await this.updatePreCompletionData();
    this.formGroupChanged.emit({ saved: true });
  }

  async removeCategory(categoryInstanceID) {
    for (let i in this.tbCompletions_PreCompletionCategories) {
      let row = this.tbCompletions_PreCompletionCategories[i];
      if (row["PreCompletionCategoryInstanceID"] == categoryInstanceID) {
        row["bFieldRemoved"] = 1;
        break;
      }
    }

    this.removeSubCategories(categoryInstanceID);
    this.removeFields(categoryInstanceID);

    await this.setupPreCompletions();
    await this.updatePreCompletionData();
    this.formGroupChanged.emit({ saved: true });
  }

  removeSubCategories(parentInstanceID) {
    this.tbCompletions_PreCompletionCategories.forEach((row) => {
      if (row["ParentCategoryInstanceID"] == parentInstanceID) {
        row["bFieldRemoved"] = 1;
        this.removeSubCategories(row["PreCompletionCategoryInstanceID"]);
        this.removeFields(row["PreCompletionCategoryInstanceID"]);
      }
    });
  }

  removeFields(parentInstanceID) {
    this.tbCompletions_PreCompletionDetails.forEach((row) => {
      if (row["PreCompletionCategoryInstanceID"] == parentInstanceID) {
        row["bFieldRemoved"] = 1;
      }
    });
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
      console.log("Destroying... unsubscribe");
    }
  }
}
