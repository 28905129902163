import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import {
  googleMapControlPosition as position,
  googleMapsOptions,
  googleMapTypeControlStyle as style,
} from "src/app/modules/shared/google-map/google-maps-set/googleMapsOptions";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { digsiteMessages } from "../../digsite.module";

// declare view component
@Component({
  selector: "app-digsite-view",
  templateUrl: "./digsite-view.component.html",
  styleUrls: ["./digsite-view.component.scss"],
  providers: [ComponentMessagingService],
})
export class DigsiteViewComponent implements OnInit, OnChanges {
  className = "DigsiteViewComponent";
  @Input() props: any;
  @Input() viewOnly: boolean;
  markers: any;
  @ViewChild("cmpGoogleMapsDrawing", {}) cmpGoogleMapsDrawing;

  finishedDrawingDisabled: boolean = true;
  existingShapeLength: number = 0;
  currentShapeIndex: number = 0;
  theme = "";
  childProps: {};
  currentTool: number = -1;
  snack = null;
  isBadgeHidden: boolean = true;

  readonly options: googleMapsOptions = {
    zoomControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: style.DROPDOWN_MENU,
      position: position.TOP_RIGHT,
    },
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
  };

  constructor(
    private logger$: LoggerService,
    private messaging$: ComponentMessagingService,
    private _snackBar: MatSnackBar,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    // super(error$, logger$, alert$);
    // this.theme$.getTheme().subscribe((nextMsg) => this.theme = nextMsg.themeName);
    this.childProps = {
      messageService: messaging$,
    };
  }

  ngOnChanges(state: SimpleChanges) {
    // listen for data changes and handle
    if (state && state["props"]["currentValue"]["existingShapes"]) {
      this.drawExistingShapes();
    }
  }

  ngOnInit() {
    this.props["messageService"].getMessageStream().subscribe((nextMsg) => {
      switch (nextMsg.message) {
        case digsiteMessages.UPDATE_DRAWING_COMPLETE:
          break;
        case digsiteMessages.UPDATE_DRAWING_FAILED:
          break;
        case digsiteMessages.UPLOAD_DRAWING_COMPLETE:
          this.cmpGoogleMapsDrawing.updateShapesAfterUpload();
          this.finishedDrawingDisabled = false;
          this.cmpGoogleMapsDrawing.turnDrawingOn();
          this.snackBarService.openSnackbar(
            "Uploaded to cloud.",
            SnackbarType.success
          );
          break;
        case digsiteMessages.UPLOAD_DRAWING_FAILED:
          this.snackBarService.openSnackbar(
            "Failed uploading to cloud. Try again.",
            SnackbarType.error
          );
          break;
      }
    });

    this.messaging$.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });
    // this.onSearchAddress();
  }

  onReceivedMessage(message: ComponentMessage) {
    try {
      switch (message.message) {
        case digsiteMessages.ON_MAP_READY:
          this.props["messageService"].sendToMessageStream(
            new ComponentMessage({
              ...message,
              message: digsiteMessages.GET_DIGSITES,
              senderID: this.className,
            })
          );
          if (this.viewOnly) {
            // this.cmpGoogleMapsDrawing.turnDrawingOn();
            this.finishedDrawingDisabled = true;
          } else {
            this.cmpGoogleMapsDrawing.turnDrawingOn();
            this.finishedDrawingDisabled = false;
            this.snackBarService.openSnackbar(
              "We recommend only drawing up to 5 polygons"
            );
          }

          break;
        case digsiteMessages.SAVE_SHAPES:
          this.saveShapes();
          break;
        default:
          break;
      }
    } catch (error) {
      this.logger$.log(error);
    }
  }

  onResetDrawing() {
    if (this.isBadgeHidden == false) {
      this.setBadgeVisibility();
    }
    this.progressBarService.start();
    this.cmpGoogleMapsDrawing.clearDrawing(this.existingShapeLength - 1);
    this.progressBarService.stop();
  }

  onFinishedDrawing() {
    // turn controls off while uploading
    const Shapes = this.cmpGoogleMapsDrawing.getShapes();
    this.currentShapeIndex += Shapes.length;
    this.cmpGoogleMapsDrawing.turnDrawingOff();
    // this.finishedDrawingDisabled = true;

    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        message: digsiteMessages.FINISHED_DRAWING,
        senderID: this.className,
      })
    );
  }

  // set tool to be pan
  onPanTool() {
    this.currentTool = 1;
  }
  // set tool to be line
  onLineTool() {
    this.currentTool = 2;
  }
  // set tool to be polygon
  onPolygonTool() {
    this.currentTool = 3;
  }
  // set tool to be polygon
  onCircleTool() {
    this.currentTool = 4;
  }
  // set tool to be pin
  onPinTool() {
    this.currentTool = 5;
  }

  openSnackBar() {
    if (this.snack == null) {
      this.snack = this._snackBar.open("You have unsaved changes.", "Save", {
        horizontalPosition: "left",
        verticalPosition: "bottom",
        panelClass: ["white-snackbar"],
      });
      this.snack.onAction().subscribe(() => {
        this.onFinishedDrawing();
        this.snack = null;
      });
    } else {
      // Snackbar is already opened so we don't need to add another
    }
  }

  onSaveDrawing() {
    this.onFinishedDrawing();
    this.setBadgeVisibility();
  }

  getBadgeVisibility() {
    return this.isBadgeHidden;
  }

  setBadgeVisibility() {
    this.isBadgeHidden = !this.isBadgeHidden;
  }

  drawExistingShapes() {
    if (this.props["existingShapes"]) {
      let existingShapes = this.props["existingShapes"];
      this.existingShapeLength = existingShapes.DigsiteData.length;
      this.currentShapeIndex = existingShapes.DigsiteData.length - 1;
      if (existingShapes.TicketLatLng) {
        this.childProps["TicketLatLng"] = existingShapes.TicketLatLng;
        this.childProps = { ...this.childProps };

        this.markers = [
          {
            position: {
              lat: parseFloat(existingShapes.TicketLatLng.Latitude),
              lng: parseFloat(existingShapes.TicketLatLng.Longitude),
            },
          },
        ];
      }

      if (existingShapes.DigsiteData.length > 0) {
        let firstDigsite = JSON.parse(
          existingShapes.DigsiteData[0].Coordinates
        )[0];

        if (!existingShapes.TicketLatLng) {
          this.childProps["TicketLatLng"] = {
            Latitude: parseFloat(firstDigsite.lat),
            Longitude: parseFloat(firstDigsite.lng),
          };
          this.childProps = { ...this.childProps };
        }

        for (let i = 0; i < existingShapes.DigsiteData.length; i++) {
          try {
            let coords = JSON.parse(existingShapes.DigsiteData[i].Coordinates);
            this.cmpGoogleMapsDrawing.addShape(
              coords,
              existingShapes.DigsiteData[i].DigsiteTypeID,
              existingShapes.DigsiteData[i].DigsiteShapeID
            );
          } catch (error) {
            console.error("failedto loadDigsites: ", error);
          }
        }
      }
    }
  }

  saveShapes() {
    this.props["messageService"].sendToMessageStream(
      new ComponentMessage({
        action: MessageAction.UPDATE,
        message: digsiteMessages.UPDATE_TICKET_DATA,
        senderID: this.className,
      })
    );
  }

  private createDigsiteDetails = (Shapes: any): Object => {
    // create dig site details obj
    // this.logger$.error("createDigsiteDetails")
    let digsiteDetails = Shapes.reduce((digsites, gmShape) => {
      // this.log("createDigsiteDetails", gmShape);
      let coords = gmShape.points;
      let formattedCoords = coords.reduce((coords, key) => {
        let coord = {
          Latitude: key.lat(),
          Longitude: key.lng(),
        };
        coords.push(coord);
        return coords;
      }, []);

      let digsite = {
        DigsiteTypeID: gmShape.type,
        Coordinates: formattedCoords,
        StatusID: gmShape.statusID,
      };
      if (gmShape.digsiteShapeID != null) {
        digsite["DigsiteShapeID"] = gmShape.digsiteShapeID;
      }

      digsites.push(digsite);
      return digsites;
    }, []);

    return digsiteDetails;
  };

  getMyMessage() {
    // return data
    var data = {};
    try {
      const Shapes = this.cmpGoogleMapsDrawing.getShapes();
      if (Shapes) {
        let digsiteDetails = this.createDigsiteDetails(Shapes);
        data = digsiteDetails;
      }
    } catch (error) {
      this.logger$.error("error: ", error);
    }
    return data;
  }
}
