import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-ticket-tags",
  templateUrl: "./ticket-tags.component.html",
  styleUrls: [],
})
export class TicketTagsComponent
  extends BasePageComponent
  implements OnChanges
{
  @Input() props;
  tags: any;

  constructor(logger$: LoggerService) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange) {
      if (changes.props.currentValue && changes.props.currentValue.tags) {
        this.tags = changes.props.currentValue.tags;
      }
    }
  }
}
