import {FormControl, FormGroup} from "@angular/forms";

export type ReportCategory = {
  CategoryID: number;
  CategoryName: string;
  Description: string;
  Reports: Report[];
}

export type Report = {
  ReportID: number;
  Name: string;
  Description?: string;
  SubTitle?: string;
  AccessLevel?: number;
  ReportTypeID?: number;
  ReportParameters?: ReportParameter[];
  FormGroup?: FormGroup;
}


export type ReportParameter = {
  ParameterID: number;
  Type: number;
  Name: string;
  Prompt: string;
  IDColumn: string;
  NameColumn: string;
  Form: FormControl;
  Options?: any[];
}

export enum ParameterType {
  Date = 1,
  Number = 2,
  String = 3,
  Select = 4,
  MultiSelect = 5,
}