import { JsonFormControl, Meta } from '~lib/types/jsonForm';

export type JsonFormRecursive = {
  controls: JsonControlLike[];
  name: string;
  id?: number;
  meta?: Meta;
};

export type JsonControlLike = JsonFormGroup | JsonFormControl;

export type JsonFormGroup = {
  controls: JsonControlLike[];
  name: string;
  id?: number | string;
  meta?: Meta;
};

export function isJsonFormGroup(control: JsonControlLike): control is JsonFormGroup {
  return 'controls' in control && Array.isArray(control.controls);
}

export function isJsonFormControl(control: JsonControlLike): control is JsonFormControl {
  return 'type' in control && 'name' in control && 'validations' in control;
}
