<div
  *ngIf="!regClicked && !emailClicked"
  fxLayout="row"
  fxLayoutAlign="center center"
  style="width: 100%; height: 100%"
  class="background"
>
<span class="text-[20px] font-rajdhani font-semibold text-login">SMARTER, BETTER, FASTER, SAFER LOCATES</span>

  <div
    class="app-register-content-container"
    fxFlex.xs="100"
    fxFlex="390px"
    fxLayout="column"
  >
    <img class="h-[78px] w-[72px] pt-7" src="assets/icons/branding/U Icon_512.svg" alt="Utilocate logo" />
    <img class="h-[60px] w-[140px] pb-2" src="assets/svg/utilocate word-01.svg" alt="Utilocate word" />

    <!-- <h2 class="font-rajdhani text-[32px] mt-[12.5px] mb-[16px]">utilocate</h2> -->

    <div class="flex flex-col justify-center items-left w-full">
      <span class="font-semibold font-rajdhani text-[20px] uppercase">Sign in or Register</span>
      <span class="font-montserrat text-md font-normal">Login in to utilocate using your work email</span>
    </div>

    <div class="flex flex-col justify-left items-center w-full">
      <button
        (click)="loginClick('email')"
        mat-flat-button
        color="primary"
        class="mat-button app-full-width uppercase text-md font-semibold blue font-rajdhani"
        style="width: 100%; margin-bottom: 25px; margin-top: 26px"
      >
        Login
      </button>
      <button
        (click)="loginClick('reg')"
        mat-stroked-button
        
        class="mat-button app-full-width uppercase text-md font-semibold font-rajdhani"
        style="width: 100%; margin-bottom: 20px"
      >
        Register
      </button>

      <p class="mat-body-xs">
        By logging in you agree to our
        <a style="cursor: pointer; color: primary" (click)="openPrivacyPolicy()"
          >Privacy Policy</a
        >
      </p>
    </div>
  </div>
</div>

<button mat-icon-button class="update-btn text-[10px] h-12 w-24 font-rajdhani" (click)="resetApp()">
  <mat-icon class="h-3 w-[14px] text-md"> refresh</mat-icon>
  REFRESH
</button>

<ng-container *ngIf="emailClicked">

  <span class="text-[20px] font-rajdhani font-semibold text">SMARTER, BETTER, FASTER, SAFER LOCATES</span>

  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    style="width: 100%; height: 100%"
    class="background"
  >
    <div
      class="flex"
      fxFlex.xs="100"
      fxFlex="390px"
      fxLayout="column"
      class="{{ enterPassword ? 'app-email-content-container' : 'app-emails-content-container' }}"
    >

    <div class="flex" style="width: 100%;" fxLayout="row">
    <button class="ml-[-16px]" mat-icon-button (click)="backBtn()">
      <mat-icon style="font-size: 24px;">arrow_back_ios_new</mat-icon>
    </button>
    </div>


    <img class="h-[72px] w-[72px]" src="assets/icons/branding/U Icon_512.svg" alt="Utilocate logo" />
    <img class="h-[66px] w-[140px] pb-5" src="assets/svg/utilocate word-01.svg" alt="Utilocate word" />

      <div class="flex flex-col justify-center items-left w-full mb-[31px]">
        <span class="font-semibold font-rajdhani text-xl uppercase">Sign in</span>
        <span class="font-montserrat font-normal text-sm">Login in to utilocate using your work email</span>
      </div>

      <div class="flex flex-col justify-center items-center w-full">
        <form [formGroup]="loginForm" class="w-full" (ngSubmit)="onSubmit()">
          <div
            class="flex flex-col justify-center items-left gap-[5px] w-full"
          >
            <mat-form-field class="example-full-width" appearance="outline">
              <input type="text" placeholder="Email ID*" matInput formControlName="username" required />
            </mat-form-field>

            <mat-form-field *ngIf="enterPassword" class="example-full-width mb-[11px]" appearance="outline">
              <input
                [type]="showPassword ? 'text' : 'password'"
                matInput
                (input)="isPasswordEmpty = passwordInput.value.length === 0"
                #passwordInput
                formControlName="password"
                placeholder="Password*"
                required
                autocomplete="off"
              />
              <button
                type="button"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                [disabled]="isPasswordEmpty"
                (click)="togglePasswordVisibility()"
                class="px-0 pb-0 pt-[6px] h-[35px]"
              >
                <mat-icon class="text-[22px] h-[22px] w-[22px]">visibility</mat-icon>
              </button>
            </mat-form-field>
            <div class="flex justify-between">
            <button
              *ngIf="enterPassword"
              type="submit"
              mat-flat-button
              color="primary"
              class="mat-button w-[120px] uppercase text-md font-semibold rounded-md blue font-rajdhani"
              style="width: 100%"
            >
              Login
            </button>
            <button
              *ngIf="enterPassword"
              type="button"
              (click)="onPasswordCancel()"
              mat-stroked-button
              class="mat-button w-[120px] uppercase text-md font-semibold rounded-md font-rajdhani"
              style="width: 100%"
            >
              Cancel
            </button>
            <button
              *ngIf="!enterPassword"
              type="button"
              (click)="onContinue()"
              mat-flat-button
              color="primary"
              class="mat-button w-[120px] uppercase text-md font-semibold rounded-md blue font-rajdhani"
              style="width: 100%"
              [disabled]="checkingEmail"
            >
              Continue
            </button>
            <!-- <button
            type="submit"
            mat-button
            color="primary"
            class="mat-button text-md forgot-btn pr-0"
          >
            Forgot Password?
          </button> -->
        </div>
          </div>
        </form>
        <!-- <p class="mat-body-xs">
          By logging in you agree to our
          <a
            style="cursor: pointer; color: primary"
            (click)="openPrivacyPolicy()"
            >Privacy Policy</a
          >
        </p> -->
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="regClicked">

  <span class="text-[20px] font-rajdhani font-semibold text">SMARTER, BETTER, FASTER, SAFER LOCATES</span>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="isRegistered; else isNotRegistered"
    class="app-register-container background"
  >
    <div
      class="app-register-content-container-login"
      fxFlex.xs="100"
      fxFlex="390px"
      fxLayout="column"
      fxLayoutAlign="start center"
    >

    <div class="flex" style="width: 100%;" fxLayout="row">
      <button class="ml-[-16px]" mat-icon-button (click)="backBtn()">
        <mat-icon style="font-size: 24px;">arrow_back_ios_new</mat-icon>
      </button>
    </div>

    <img class="h-[72px] w-[72px]" src="assets/icons/branding/U Icon_512.svg" alt="Utilocate logo" />
    <img class="h-[140px] w-[140px]" src="assets/svg/utilocate word-01.svg" alt="Utilocate word" />
  
        <div class="flex flex-col justify-center items-left w-full mb-[18px]">
          <span class="font-semibold font-rajdhani text-xl uppercase">Welcome, {{ ClientName }}</span>
          <span class="font-montserrat font-medium text-sm">Login to continue</span>
        </div>

      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmits()"
        class="app-full-width app-bottom-default-margin"
      >
        <div class="login-form flex flex-col justify-center items-start gap-[5px]">
          <mat-form-field class="example-full-width" appearance="outline">
            <input
              type="text"
              matInput
              placeholder="Username*"
              formControlName="username"
              [matAutocomplete]="auto"
              required
              autocomplete="username"
            />
            <button
              type="button"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              class="px-0 pb-0 pt-[6px] h-[35px]"
              (click)="resetUserField()"
            >
            <mat-icon class="text-[22px] h-[22px] w-[22px]">close</mat-icon>
          </button>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option.UserDetails }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="example-full-width mb-[11px]" appearance="outline">
            <input
              [type]="showPassword ? 'text' : 'password'"
              matInput
              (input)="isPasswordEmptyReg = passwordInputReg.value.length === 0"
              #passwordInputReg
              formControlName="password"
              placeholder="Password*"
              required
              autocomplete="off"
            />
            <button
              type="button"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              [disabled]="isPasswordEmptyReg"
              (click)="togglePasswordVisibility()"
              class="px-0 pb-0 pt-[6px] h-[35px]"
            >
              <mat-icon class="text-[22px] h-[22px] w-[22px]">visibility</mat-icon>
            </button>
          </mat-form-field>

          <button
              type="submit"
              mat-flat-button
              color="primary"
              class="mat-button w-[120px] uppercase text-md font-semibold rounded-md blue"
              style="width: 100%"
            >
              Login
            </button>
        </div>
      </form>
      <!-- <p class="mat-body-xs">
        By logging in you agree to our
        <a
          class="text-primary"
          style="cursor: pointer"
          (click)="openPrivacyPolicy()"
          >Privacy Policy</a
        >
      </p> -->
    </div>
  </div>

  <ng-template #isNotRegistered>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="app-register-container background"
    >
      <div
        class="app-register-content-container"
        fxFlex.xs="100"
        fxFlex="390px"
        fxLayout="column"
        fxLayoutAlign="start center"
      >

      <div class="flex" style="width: 100%;" fxLayout="row">
        <button class="ml-[-16px]" mat-icon-button (click)="backBtn()">
          <mat-icon style="font-size: 24px;">arrow_back_ios_new</mat-icon>
        </button>
        </div>

      <img class="h-[72px] w-[72px] mb-[-15px]" src="assets/icons/branding/U Icon_512.svg" alt="Utilocate logo" />
      <img class="h-[140px] w-[140px]" src="assets/svg/utilocate word-01.svg" alt="Utilocate word" />

        <form
          [formGroup]="registerForm"
          (ngSubmit)="onRegister()"
          class="app-full-width app-bottom-default-margin"
        >
          <div class="flex flex-col justify-center items-left">
            <span class="font-semibold font-rajdhani text-xl uppercase">Register</span>
            <span class="font-montserrat text-sm mb-[30px]">Register using the code we sent you</span>
          </div>
          <div
            class="login-form flex flex-col justify-center items-left gap-2"
          >
            <mat-form-field class="app-full-width" appearance="outline">
              <input
                type="text"
                matInput
                placeholder="Enter registration code"
                formControlName="companyCode"
                required
              />
            </mat-form-field>
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="loading"
              class="mat-button w-[120px] uppercase text-md font-semibold rounded-md blue font-rajdhani"
            >
              Register
            </button>
          </div>
        </form>
        <!-- <p class="mat-body-xs">
          By registering you agree to our
          <a class="text-primary" style="cursor: pointer" (click)="openPrivacyPolicy()"
            >Privacy Policy</a
          >
        </p> -->
      </div>
    </div>
  </ng-template>
</ng-container>

<div class="app-login-container"></div>
