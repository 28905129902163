<div
  class="min-h-full w-full pb-2 page-background"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div class="m-2 bg-white width-[calc(100%-16px)] mat-elevation-z4">
    <div class="overflow-auto w-full h-full overflow-hidden">
      <app-recursive-form-template
        [props]="categoryProps"
        [readOnly]="ticketProtectionMode || props.isU2TicketComplete"
        (addCategories)="addCategories($event)"
        (removeCategory)="removeCategory($event)"
        (isHideShowToggle)="preCompletionsUpdate($event)"
      >
      </app-recursive-form-template>
    </div>
  </div>
  <div style="height: 64px; min-height: 64px; width: 100%"></div>
</div>

<!-- FAB -->
<floating-action-menu
  *ngIf="
    !TICKET_PROTECTION_MODE &&
    fabMenu.length > 0 &&
    props.isU2TicketComplete == false
  "
  [menuItems]="fabMenu"
  [disabled]="fabDisabled"
  type="fab"
>
</floating-action-menu>
