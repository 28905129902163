<div class="flex flex-row h-full w-full">
  <app-page-navigation
    [routes]="routes"
    [header]="header"
    class="flex max-w-1/5"
  ></app-page-navigation>
  <div class="flex flex-grow" style="max-width: calc(100% - 240px)">
    <router-outlet></router-outlet>
  </div>
</div>
