<div class="min-h-[156px] w-full p-4" fxLayout="row" fxLayoutAlign="start stretch">
  <div fxFlex="75" fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="start stretch">
      <span class="mat-headline-2" *ngIf="CALL_TYPE_DESC">{{
        CALL_TYPE_DESC | uppercase
      }}</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="start">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="grid">
        <div class="form-input" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>work</mat-icon>
          <span class="text-lg">{{ TYPE_OF_WORK }}</span>
        </div>

        <div class="form-input" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>location_on</mat-icon>
          <span class="text-lg">{{ LOCATE_ADDRESS }}</span>
        </div>

        <div class="form-input" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>calendar_today</mat-icon>
          <span class="text-lg">{{ EXCAVATION_DATE }}</span>
        </div>
      </div>
      <div class="h-8 w-full mt-4">
        <app-ticket-tags [props]="tagProps"></app-ticket-tags>
      </div>
    </div>
  </div>

  <div fxFlex="25" fxLayout="column" fxLayoutAlign="start end">
    <button
      style="width: 100%"
      mat-flat-button
      color="primary"
      (click)="onTimeToggle()"
    >
      Edit Time In/Out
    </button>
    <div
      *ngIf="
        this.props.TICKET_PROTECTION_MODE === false &&
        this.props.isU2TicketComplete === false &&
        this.showClearOptions
      "
      style="width: 100%"
      class="form-input"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="16px"
    >
      <mat-form-field fxFlex="80">
        <mat-label>Clear Ticket</mat-label>
        <mat-select [formControl]="formControl" name="option" required>
          <mat-option></mat-option>
          <mat-option
            *ngFor="let option of clearOptions"
            [value]="option.ClearTypeID"
          >
            {{ option.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        fxFlex="50px"
        mat-flat-button
        color="primary"
        [disabled]="!formControl.valid"
        (click)="openDialog(CLEAR_TICKET_MODAL_ID)"
      >
        GO
      </button>
    </div>
  </div>
</div>

<app-base-modal [id]="CLEAR_TICKET_MODAL_ID" maxwidth="360px">
  <ng-container class="competers-modal-content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <span class="mat-subtitle-1">Clear Ticket</span>
      <span>Are you sure you want to clear this ticket?</span>
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="16px"
      >
        <button
          class="blue"
          mat-flat-button
          (click)="closeDialog(CLEAR_TICKET_MODAL_ID)"
        >
          Cancel
        </button>
        <button mat-flat-button color="primary" (click)="clearTicket()">
          Confirm
        </button>
      </div>
    </div>
  </ng-container>
</app-base-modal>
