import { Injectable } from "@angular/core";
import axios from "axios";
import { from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { environment } from "src/environments/environment";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { DocumentService } from "../core/services/document/document.service";
import { LoggerService } from "../core/services/logger/logger.service";
import {
  UploadDocumentOptions,
  UploadDocumentsService,
} from "../shared/upload-documents/upload-documents.service";
import { UserService } from "../core/services/user/user.service";

export enum DocToAngAPIAction {
  UPDATE = 1,
  SELECT = 2,
}

export enum DocToAngAPISubAction {
  VIEWS = 1,
  ACKNOWLEDGEMENT = 2,
  SIGNATURE = 3,
  GET_PRIMARY = 4,
}

export enum AcknowledgementStatus {
  DECLINED = -1,
  ACKNOWLEDGED = 1,
}

@Injectable({
  providedIn: "root",
})
export class DocumentViewerService {
  constructor(
    private logger$: LoggerService,
    private uploadDocumentService$: UploadDocumentsService,
    private utilocateApiService: ApiService,
    private documentService: DocumentService,
    private userService: UserService
  ) {
    this.logger$.log("Created Digsite service");
  }

  /**
   * download s3 object
   * @param key identifier for s3 object
   * @returns response from s3
   */
  retrieveDocFromS3(key: string) {
    let url = "/api/get/file"; // localhost

    // update url to include nodejs for non-localhost sites
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/get/file";
    }

    return axios.get(url, {
      // to send information in a get use
      params: {
        key: key,
        bucket: this.userService.getClientBucketFromSettings(),
      },
    });
  }

  /**
   * call api to download a document from s3
   * @param assignmentID assignmentID of ticket
   * @param docID specific document ID
   * @returns observable that calls retrieveDocumentsS3 api
   */
  downloadDocument(assignmentID, docID): Observable<any> {
    return new Observable((subscriber) => {
      const url = apiKeys.u2.retrieveDocumentsS3;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.retrieveDocumentsS3,
        API_TYPE: type,
        API_BODY: {
          AssignmentID: assignmentID,
        },
        API_URL_DATA_PARAMS: {
          DocumentID: docID,
        },
      };
      from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).subscribe((apiResult) => {
        console.log(apiResult);

        subscriber.next(apiResult);
        subscriber.complete();
      });
    });
  }

  /**
   * get ids from session storage, get the document from s3 and unzip
   * @returns unzipped pdf file dadta
   */
  getPDF() {
    var self = this;
    return new Promise(function (resolve, reject) {
      const aid = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.assignmentid
      );
      const docID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.documentid
      );
      // get the location of the doc in the bucket
      self.downloadDocument(aid, docID).subscribe((res) => {
        if (res && res.ok && res.body != null) {
          // parse out the location
          var key = res.body.value.split('"')[1];
          // get the doc from S3
          self
            .retrieveDocFromS3(key)
            .then(function (response) {
              if (response != null && response.data != null) {
                var data = response.data;
                if (data.file != null) {
                  // got the file need to unzip
                  return self.documentService.unzipFileIncludeName(
                    data.file.data
                  );
                } else if (data.error != null) {
                  throw data.error;
                } else {
                  throw Error("Error returned from retrieveDocFromS3");
                }
              } else {
                throw Error("retrieveDocFromS3 response data null");
              }
            })
            .then(function (value) {
              // handle unzip return
              if (value != null) {
                resolve(value);
              }
            })
            .catch(function (error) {
              reject(error);
            });
        } else {
          if (res.error == null) {
            reject("Could Not Retrieve Document Location");
          } else {
            reject(res.error);
          }
        }
      });
    });
  }

  /**
   * upload the signature image using uploadDocumentService
   * @param fileName name of file to upload
   * @param file file data to upload
   * @param desc description of file (likely containing name of person who signed)
   * @returns result of uploadDocumentService upload function
   */
  uploadSignatureDocument(
    fileName: string,
    file: string,
    desc: string = "Signature Document"
  ): Observable<any> {
    var AssignmentID = Number.parseInt(sessionStorage.getItem("assignmentid"));
    let documentOptions: UploadDocumentOptions = {
      AssignmentID: AssignmentID,
      Description: desc,
      FileName: fileName,
      DocumentTypeID: 15,
      isSendable: 0,
      File: file,
      isUnZipped: 0,
      RequestNumber: "",
    };
    return this.uploadDocumentService$.uploadDocumentWithOptions(
      documentOptions
    );
  }

  /**
   * set up api body to update the number of views of doc
   */
  async updateViews() {
    try {
      const rowID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
      );
      var data = {
        DocumentToAngularID: rowID,
        SubAction: DocToAngAPISubAction.VIEWS,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
        await this.callDocToAngularController(apiBody);
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (ex) {
      this.logger$.error("updateViews: fail: ", ex);
    }
  }

  /**
   * set up api body to update the Acknowldged status of doc as Acknowldged
   */
  async updateAcknowledged() {
    try {
      const rowID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
      );
      var data = {
        DocumentToAngularID: rowID,
        SubAction: DocToAngAPISubAction.ACKNOWLEDGEMENT,
        Acknowledgement: AcknowledgementStatus.ACKNOWLEDGED,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
        await this.callDocToAngularController(apiBody);
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (ex) {
      this.logger$.error("updateAcknowledgemed: fail: ", ex);
    }
  }

  /**
   * set up api body to update the Acknowldged status of doc as Declined
   */
  async updateDeclined() {
    try {
      const rowID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
      );
      var data = {
        DocumentToAngularID: rowID,
        SubAction: DocToAngAPISubAction.ACKNOWLEDGEMENT,
        Acknowledgement: AcknowledgementStatus.DECLINED,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
        await this.callDocToAngularController(apiBody);
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (ex) {
      this.logger$.error("updateDeclined: fail: ", ex);
    }
  }

  /**
   *  set up api body and call api to update the signature of a doc
   * @param sigDocID id of signature image
   * @param values values from signature form
   * @returns result of api call
   */
  async updateSignature(sigDocID, values) {
    let apiResult: any;
    try {
      const rowID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
      );
      var data = {
        DocumentToAngularID: rowID,
        SubAction: DocToAngAPISubAction.SIGNATURE,
        SignatureDocumentID: sigDocID,
        FormValues: values,
        Acknowledgement: AcknowledgementStatus.ACKNOWLEDGED,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
        apiResult = await this.callDocToAngularController(apiBody);
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (ex) {
      this.logger$.error("updateSignature: fail: ", ex);
    }
    return apiResult;
  }

  /**
   *
   * @param documentToAngularID id of row in DocumentToAngular table
   * @returns databse row
   */
  async getDocumentToAngularRowByID(documentToAngularID): Promise<{}> {
    let row = {};
    try {
      const rowID = Number.parseInt(documentToAngularID);
      var data = {
        DocumentToAngularID: rowID,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.SELECT, Data: data };
        console.log("apiBody:", apiBody);
        let response = await this.callDocToAngularController(apiBody);
        if (response && response[0]) {
          row = response[0];
        }
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (error) {
      this.logger$.error("getDocumentToAngularRowByID: fail: ", error.message);
    }
    return row;
  }

  /**
   * set up api body to get primary id from api call
   * @returns api result containing primary ID
   */
  async getPrimaryID() {
    let apiResult: any;
    try {
      const rowID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
      );
      const documentID = Number.parseInt(
        sessionStorage.getItem(localStorageKeys.URL_KEYS.documentid)
      );
      var data = {
        DocumentToAngularID: rowID,
        SubAction: DocToAngAPISubAction.GET_PRIMARY,
        DocumentID: documentID,
      };
      if (rowID) {
        const apiBody = { Action: DocToAngAPIAction.SELECT, Data: data };
        apiResult = await this.callDocToAngularController(apiBody);
      } else {
        throw new Error("Failed to GET rowID from storage");
      }
    } catch (ex) {
      this.logger$.error("getPrimaryID: fail: ", ex);
    }
    return apiResult;
  }

  /**
   * make an api call to the DocumentToAngularController lambda
   * @param apiBody body for api call to be made
   * @returns api reuslt body
   */
  async callDocToAngularController(apiBody: any): Promise<any> {
    let response = false;
    try {
      const url = apiKeys.u2.documentToAngularController;
      const type = api[url].type;
      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.documentToAngularController,
        API_TYPE: type,
        API_BODY: apiBody,
      };
      let apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      if (apiResult && apiResult["ok"]) {
        response = apiResult["body"];
      }
    } catch (ex) {
      this.logger$.error("callDocToAngularController: fail: ", ex);
    }
    return response;
  }
}
