import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { ApiService, UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { SnackbarType } from 'src/app/modules/shared/snackbar/snackbar/snackbar';
import { TicketService } from 'src/app/modules/shared/ticket/ticket.service';
import { SearchCriteria, TicketSearchService } from 'src/app/shared/services/ticket-search/ticket-search.service';
import { TicketMapService } from '../ticket-map/ticket-map.service';

interface SelectedUser {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouteUserEditorService {
  //services
  private ticketService = inject(TicketService);
  protected searchService = inject(TicketSearchService);
  private snackBarService = inject(SnackbarService);
  private utilocateAPIService = inject(ApiService);
  private ticketMapService = inject(TicketMapService);

  //members
  private _isOpen$ = new BehaviorSubject(true);
  private lastSelectedUser: SelectedUser = null;
  private currentRouteOrder = new BehaviorSubject<string[]>([]);

  //observables 
  public showRouting$ = new BehaviorSubject<boolean>(true);

  /**
   * called when the user selects a user from the dropdown in the route-user-editor 
   *
   * @param {SelectedUser} selectedUser
   * @memberof RouteUserEditorService
   */
  async setSelectedUser(selectedUser: SelectedUser) {
    let success = false;
    try {
      this.lastSelectedUser = selectedUser;
      await this.searchForTickets(selectedUser);
      await this.getRouteOrder(selectedUser);
      if (selectedUser) this.snackBarService.openSnackbar("Got tickets for user", SnackbarType.success);

      success = true;
    } catch (error) {
      this.snackBarService.openSnackbar(error.message, SnackbarType.error);
      console.error(error);
    }
    return success;
  }

  /**
   * Search for the selected user's tickets
   *
   * @param {SelectedUser} selectedUser
   * @return {*} 
   * @memberof RouteUserEditorService
   */
  async searchForTickets(selectedUser: SelectedUser): Promise<any> {
    if (!selectedUser || !selectedUser.value || selectedUser.value.length === 0) {
      //if no user is selected, clear the tickets 
      this.ticketMapService.clearTickets();
    } else {
      const searchCriteria: SearchCriteria = {
        FilterID: 12,
        DataTypeID: 4,
        FilterName: "Assigned To",
        Value: [selectedUser.value],
        isExcluded: 0,
        ValueDescription: selectedUser.name,
      }
      await this.searchService.fetchNewSearchResults(this, [searchCriteria]);
    }
  }

  /**
   * Gets the route order for the selected user 
   *
   * @param {SelectedUser} selectedUser
   * @return {*}  {Promise<void>}
   * @memberof RouteUserEditorService
   */
  async getRouteOrder(selectedUser: SelectedUser): Promise<void> {
    if (!selectedUser || !selectedUser.value || selectedUser.value.length === 0) {
      return;
    }
    const routes = await this.ticketService.getRouting(selectedUser.value);
    const newOrder = routes[0]?.AssignmentIDList?.split(",");
    this.currentRouteOrder.next(newOrder);
    //trigger change detection for the map pins to update the route order number 
    this.ticketMapService.triggerChangeForTicketSourceFeautureLayer();
  }


  clearRouteOrder(): void {
    this.currentRouteOrder.next([]);
    this.ticketMapService.triggerChangeForTicketSourceFeautureLayer();
  }


  /**
   * Refreshes the route order for `this.lastSelectedUser`
   * @param {String} explanation - explanation for the refresh
   * @return {*}  {Promise<void>}
   * @memberof RouteUserEditorService
   */
  async refreshRouteOrder(explanation: string): Promise<void> {
    try {
      if (!this.lastSelectedUser) throw new Error("No selected user");
      let newOrder = await this.callRefreshRouteForUser(explanation);
      newOrder = newOrder[this.lastSelectedUser.value];

      //ensure every value in newOrder is a string
      newOrder = newOrder ? newOrder.map((x) => x.toString()) : [];
      this.currentRouteOrder.next(newOrder); //update the new route order

      this.ticketMapService.triggerChangeForTicketSourceFeautureLayer();
      if (newOrder.length === 0) {
        this.snackBarService.openSnackbar("No new route was generated", SnackbarType.warning);
      } else {
        this.snackBarService.openSnackbar("Refreshed route for selected user", SnackbarType.success);
      }
    } catch (error) {
      console.error(error);
      this.snackBarService.openSnackbar(error.message, SnackbarType.error);
    }
  }

  /**
   * call /updateTicketRoutes for the selected user
   *
   * @memberof RouteUserEditorService
   */
  async callRefreshRouteForUser(explaination: string) {
    const url = apiKeys.u2.updateTicketRoutes;
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.updateTicketRoutes,
      API_TYPE: type,
      API_BODY: {
        UserID: this.lastSelectedUser.value.toString(),
        OverrideRoute: 1,
        Explanation: explaination
      },
    };

    const result = await this.utilocateAPIService.invokeUtilocateApi(utilocateApiRequest);
    if (result.error) throw new Error(result.error.message);

    const resultObj = JSON.parse(result.body);
    if (resultObj && result.status === 200) {
      //success
      return JSON.parse(result.body);
    }
  }

  /**
   * Refreshes `this.lastSelectedUser` user's tickets 
   *
   * @return {*}  {Promise<void>}
   * @memberof RouteUserEditorService
   */
  async refreshLastSelectedUserTickets(): Promise<void> {
    try {
      if (!this.lastSelectedUser) throw new Error("No selected user");
      await this.searchForTickets(this.lastSelectedUser);
      await this.getRouteOrder(this.lastSelectedUser);
      this.snackBarService.openSnackbar("Refreshed Tickets for selected user", SnackbarType.success);
    } catch (error) {
      console.error(error);
      this.snackBarService.openSnackbar(error.message, SnackbarType.error);
    }
  }

  // setters ~~~~~~~~~~~~~~~~~~~~~~~~~~~

  set isOpen$(val: boolean) {
    if (val !== this._isOpen$.value) {
      this._isOpen$.next(val);
    }
  }

  //getters ~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  get currentRoute$(): Observable<string[]> {
    return this.currentRouteOrder.pipe();
  }


  get isOpen$(): Observable<boolean> {
    return this._isOpen$.pipe();
  }


  toggleOpen() {
    this._isOpen$.next(!this._isOpen$.value);
  }

  get lastUser() {
    return this.lastSelectedUser;
  }
}
