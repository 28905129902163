import { Component, effect, Input, OnDestroy, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-competers-date-picker',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatDatepickerModule, MatInputModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersDatePickerComponent,
    },
  ],

  template: `
    <div
      class="box-border flex flex-col {{
        isSelected ? 'pb-3' : ''
      }} gap-3 justify-start items-start bg-transparent w-[300px]">
      <div class="flex flex-row gap-3">
        <div class="flex flex-col justify-start items-center">
          <div
            class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
            <button
              (click)="startDatePicker.open()"
              class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
              <mat-icon style="width:20px; height:20px; " svgIcon="calendar" />
            </button>
            <p class="text-md text-gray-600 font-rajdhani font-semibold">
              {{ (startDate.value | date: 'dd-MM-YYYY') ?? 'DD-MM-YYYY' }}
            </p>
          </div>
          <div class="overflow-hidden h-0 w-36">
            <mat-datepicker #startDatePicker></mat-datepicker>
            <input
              #startDate
              matInput
              [matDatepicker]="startDatePicker"
              [ngModel]="date$$()"
              (ngModelChange)="date$$.set($event)" />
            />
          </div>
        </div>
      </div>
    </div>
  `,
})
export class CompetersDatePickerComponent implements OnDestroy, ControlValueAccessor {
  @Input() disabled = false;

  // observables and signals
  private destroy$ = new Subject<void>();
  protected date$$ = signal<Date>(new Date());
  protected val: Date = new Date();

  protected touched = false;
  protected isSelected: boolean = false;
  protected defaultDate: Date = new Date();

  constructor() {
    effect(() => {
      if (this.onChange !== undefined) {
        this.onChange(this.date$$());
        this.val = this.date$$();
        this.isSelected = this.val !== null;
      }
    });
  }

  onChange: (data: Date) => void;

  onTouched: () => void;

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: Date) {
    if (!isEqual(this.val, data)) {
      this.date$$.set(data ?? null);
    }
    if (data === null) {
      this.isSelected = false;
    }
  }

  registerOnChange(onChange: () => Date) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
    if (!this.isSelected) {
      this.date$$.set(null);
    }
  }
}

export type DateRange = { upper: Date; lower: Date };
