import { Pipe, PipeTransform } from '@angular/core';
import { TicketAction } from '../../../../shared/services/ticket-actions/ticket-actions.service';

@Pipe({
  name: 'ticketActionsMenu',
  standalone: true,
})
export class TicketActionsMenuPipe implements PipeTransform {
  transform(value: Array<TicketAction>): Array<{ name: string; value: number }> {
    if (value) {
      return value.map((action) => {
        return { name: action.visibleName, value: action.id };
      });
    }
    return null;
  }
}
