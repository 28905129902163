import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LocaleService } from "src/app/modules/core/services/locale/locale.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { ISO_LOCALE_LIST } from "src/assets/locale-files/languages";
import { DocumentViewerService } from "../../document-viewer.service";
import { PdfAcknowledgementService } from "../pdf-acknowledgement.service";

@Component({
  selector: "app-pdf-acknowledgement-panel",
  templateUrl: "./pdf-acknowledgement-panel.component.html",
  styleUrls: ["./pdf-acknowledgement-panel.component.scss"],
})
export class PdfAcknowledgementPanelComponent implements OnInit {
  className: string = "PDFAcknowledgementPanelComponent";
  @Output() contentLoad: EventEmitter<any> = new EventEmitter<any>();

  localeStage: boolean = true;
  ackStage: boolean = false;
  decStage: boolean = false;
  sigStage: boolean = false;
  pdfStage: boolean = false;
  gatherDocError: boolean = false;
  error404: boolean = false;

  panelLoaded: boolean = false;

  localeProps: any;
  sigProps: any;
  ackProps: any;
  pdfProps: any;
  decProps: any;
  pdfBlob: any = null;
  pdfName: string = null;

  message404 = "Request failed with status code 404";

  pdfOptions = {
    isBase64: false,
    data: "/assets/pdf/SignatureAccessTroubleshootManual.pdf",
    showSidebarButton: false,
  };

  locale: any;
  languageName = "ENGLISH";

  constructor(
    // error$: MainErrorService,
    private logger$: LoggerService,
    messaging$: ComponentMessagingService,
    // alert$: NotificationService,
    private pdfAckService$: PdfAcknowledgementService,
    private locale$: LocaleService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    // set up the child props
    this.localeProps = {
      messageService: messaging$,
      name: "localeViewID",
    };
    this.ackProps = {
      messageService: messaging$,
      name: "acknowledgementViewID",
    };
    this.sigProps = {
      messageService: messaging$,
      name: "signatureViewID",
    };
    this.pdfProps = {
      messageService: messaging$,
      name: "pdfViewID",
    };
    this.decProps = {
      messageService: messaging$,
      name: "pdfViewID",
    };

    // set up message receiver for receiving messages from child components
    messaging$.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });
  }

  /**
   * setup locale and pdfOptions
   * load the pdf using getDocumentToAngularRow
   * send message
   * updateViews
   */
  ngOnInit(): void {
    var self = this;
    //setup locale
    this.locale$.getLocaleSubscription().subscribe((newLocale) => {
      if (newLocale.localeName == ISO_LOCALE_LIST["en-CA"]) {
        this.languageName = "FRANÇAIS";
      } else if (newLocale.localeName == ISO_LOCALE_LIST.fr) {
        this.languageName = "ENGLISH";
      }
      if (newLocale.locale && newLocale.locale[this.className]) {
        this.locale = newLocale.locale[this.className];
      } else {
        //if reach then locale files setup incorrectly.
        this.logger$.log("Component language not supported");
      }
      this.pdfOptions = {
        isBase64: false,
        data: this.locale.pdfPath,
        showSidebarButton: false,
      };
    });
    // get the row using the DocumentToAngularID
    this.pdfAckService$.getDocumentToAngularRow().subscribe((res) => {
      if (res != null && Array.isArray(res) && res.length > 0) {
        var row = res[0];
        if (row.Acknowledged == 1) {
          self.pdfStage = true;
        } else {
          self.ackStage = true;
        }

        this.pdfAckService$
          .getPDF()
          .then(function (value) {
            self.contentLoad.emit();
            self.panelLoaded = true;
            self.pdfBlob = value["data"];
            self.pdfName = value["filename"]; // send the file name to the signature view when it is initialized

            //send message
            const message: ComponentMessage = {
              action: MessageAction.READY,
              message: {
                pdfReady: true,
                pdf: self.pdfBlob,
              },
              senderID: "PDFAcknowledgementPanelComponent",
            };
            self.pdfProps.messageService.sendToMessageStream(message);

            self.progressBarService.stop();
          })
          .catch(function (ex) {
            self.contentLoad.emit();
            self.panelLoaded = true;
            if (ex && ex.message && ex.message == self.message404) {
              self.error404 = true;
            } else {
              self.gatherDocError = true;
            }
            // self.progressBarService.updateProgressBar(NotificationType.error);
            self.progressBarService.stop();
            // self.snackBarService.setToast(NotificationType.error, "Failed to get the pdf");
            self.pdfStage = false;
            // this.log("getPDF failed", ex);
          });
      }
    });

    this.pdfAckService$.updateViews().subscribe((result) => {});
  }

  /**
   * swap language selection
   */
  toggleFrenchEnglish() {
    if (this.languageName == "ENGLISH") {
      this.locale$.setLocale(ISO_LOCALE_LIST["en-CA"]);
    } else {
      this.locale$.setLocale(ISO_LOCALE_LIST.fr);
    }
  }

  /**
   * scroll to the logo element
   */
  private scrollToLogo() {
    try {
      let element = document.getElementById("logo-id");
      element.scrollIntoView();
    } catch (error) {}
  }

  // handles all messages received
  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.acknowledged) {
        // Accept btn clicked on Acknowledgement view
        // this.updateAcknowledged();
        this.ackStage = false;
        this.sigStage = true;
        this.scrollToLogo();
      } else if (compMessage.message.signed) {
        // Signature as been submitted in Signature view
        this.uploadSignatureDoc(
          compMessage.message.fileName,
          compMessage.message.file,
          compMessage.message.formValues
        );
      } else if (compMessage.message.declined) {
        // Decline btn clicked on Acknowledgement view
        this.ackStage = false;
        this.decStage = true;
        this.updateDeclined();
      } else if (compMessage.message.readAgain) {
        // Read Again btn clicked on Decline Acknowledgement view
        this.decStage = false;
        this.sigStage = false;
        this.ackStage = true;
        this.scrollToLogo();
      } else if (compMessage.message.pdfViewerReady) {
        // pdf View is initialized
        const message: ComponentMessage = {
          action: MessageAction.READY,
          message: {
            pdfReady: true,
            pdf: this.pdfBlob,
          },
          senderID: "PDFAcknowledgementPanelComponent",
        };
        this.pdfProps.messageService.sendToMessageStream(message);
      } else if (compMessage.message.sigViewReady) {
        // sig View is initialized
        const message: ComponentMessage = {
          action: MessageAction.READY,
          message: {
            pdfNameReady: true,
            filename: this.pdfName,
          },
          senderID: "PDFAcknowledgementPanelComponent",
        };
        this.pdfProps.messageService.sendToMessageStream(message);
      } else if (compMessage.message.language) {
        this.localeStage = false;
      }
    }
  }

  /**
   * call uploadDocument an updateSignature, inform user of any errors
   * @param fileName name of file to upload
   * @param file file content to upload
   * @param formValues values from signature fields
   */
  uploadSignatureDoc(fileName: string, file: string, formValues: any) {
    try {
      this.pdfAckService$.uploadDocument(fileName, file).subscribe((result) => {
        if (result) {
          var row = JSON.parse(result.body);
          //if already has a number then mess up
          try {
            this.pdfAckService$
              .updateSignature(row["DocumentID"], formValues)
              .subscribe((res) => {
                if (res["affectedRows"] > 0) {
                  this.sigStage = false;
                  this.pdfStage = true;
                  this.progressBarService.stop();
                } else {
                  this.snackBarService.openSnackbar(
                    "Unable To Save Information: API Error",
                    SnackbarType.error
                  );
                  this.progressBarService.stop();
                }
              });
          } catch (ex) {
            this.logger$.error(ex);
            this.snackBarService.openSnackbar(
              "Unable To Save Information",
              SnackbarType.error
            );
            this.progressBarService.stop();
          }
        }
      });
    } catch (ex) {
      this.logger$.error(ex);
      this.snackBarService.openSnackbar(
        "Unable To Save Information",
        SnackbarType.error
      );
      this.progressBarService.stop();
    }
  }

  /**
   * call updateAcknowledged and inform user of any errors
   */
  updateAcknowledged() {
    try {
      this.pdfAckService$.updateAcknowledged().subscribe((result) => {
        // do something after lambda returns
        if (result["affectedRows"] > 0) {
          this.ackStage = false;
          this.sigStage = true;
        } else {
          this.snackBarService.openSnackbar(
            "Unable To Upload Acknowledgement",
            SnackbarType.error
          );
        }
      });
    } catch (ex) {
      this.logger$.error("updateAcknowledged error: ", ex);
      this.snackBarService.openSnackbar(
        "Unable To Upload Acknowledgement",
        SnackbarType.error
      );
    }
  }

  /**
   * call updateDeclined via service
   */
  updateDeclined() {
    this.pdfAckService$.updateDeclined().subscribe((result) => {
      // do something after lambda returns
    });
  }
}
