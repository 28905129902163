import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { apiKeys } from '../../../ENDPOINTS';
import {
  CustomLine,
  LineStyle,
  LineThickness,
  LineTools,
  ManipulationTools,
  SelectionTools,
  ShapeTools,
  ToolbarState,
  ToolbarStateKey,
  ToolType,
} from '../utilities/types';
import { first } from 'rxjs/operators';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';

@Injectable({
  providedIn: 'root',
})
export class DrawingToolbarService implements OnDestroy {
  // services
  private _toolbarState$$ = signal<ToolbarState>({
    toolPage: ToolType.selectionTool,
    selectionTool: SelectionTools.pointSelect,
    manipulationTool: ManipulationTools.rotate,
    lineTool: LineTools.singleLine,
    shapeTool: ShapeTools.square,
    lineStyle: LineStyle.solid,
    lineThickness: LineThickness.mediumLine,
    utilityLine: null,
    sticker: null,
    svgSymbol: null,
    strokeColour: '#000000',
    fillColour: '#ffffff',
    opacity: 100,
    _source: null,
  });

  toolbarState$$ = computed(() => this._toolbarState$$());

  private apiService = inject(ApiService);

  // observables
  private destroy$ = new Subject<void>();
  private stickersSubject$ = new BehaviorSubject<any>([]);
  private UtilityLinesSubject$ = new BehaviorSubject<CustomLine[]>([]);
  private _uploadedImage$ = new BehaviorSubject<File>(null);

  constructor() {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  gatherStickers() {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapDrawing,
      API_BODY: { query: { stickers: { all: true } } },
    };
    from(this.apiService.invokeUtilocateApi(request))
      .pipe(first())
      .subscribe((response) => {
        const stickersData = response?.body?.result;
        if (stickersData) {
          this.stickersSubject$.next(stickersData);
        } else {
          console.error('No stickers found');
        }
      });
  }

  gatherUtilityLines(): void {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapDrawing,
      API_BODY: { query: { utilityLines: { all: true } } },
    };
    from(this.apiService.invokeUtilocateApi(request))
      .pipe(first())
      .subscribe((response) => {
        try {
          const UtilityLinesData = response.body.result;
          this.UtilityLinesSubject$.next(UtilityLinesData);
        } catch {
          console.log('No utility lines found');
        }
      });
  }

  // getters
  getUtilityLines(): Observable<CustomLine[]> {
    return this.UtilityLinesSubject$.asObservable();
  }

  getStickers(): Observable<any> {
    return this.stickersSubject$.asObservable();
  }

  useDefaultBackdrop() {
    this._uploadedImage$.next(null);
  }

  updateToolbarState(updates: Partial<ToolbarState>, source = null): void {
    this._toolbarState$$.update((state) => ({
      ...state,
      ...updates,
      _source: source,
    }));
  }

  getToolbarStateValue<K extends ToolbarStateKey>(key: K): ToolbarState[K] {
    return this.toolbarState$$()[key];
  }
}
