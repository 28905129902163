import { Component, OnInit } from '@angular/core';
import { UpdateService } from '../../core/services/update.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../../core/services/user/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import historyFromJSON from '../version-information-history.json';
import { VersionInformationHistory } from '../version-information-template';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { UtilocateTokenService } from '../../core/services/token/token.service';

const DEFAULT_TAP_COUNT = 4;
const OMIT_FIELD_NAMES = [
  'Database',
  'ClientID',
  'Code',
  'ClientDBName',
  'isOld',
  'enableDebug',
  'localhost',
  'url',
  'appVersion',
  'cognito',
];

@Component({
  selector: 'app-version-information',
  templateUrl: './version-information.component.html',
  styleUrls: ['./version-information.component.scss'],
})
export class VersionInformationComponent implements OnInit {
  version: string;
  dataSource: MatTableDataSource<any>;
  deviceInfo = null;
  clientInfo = null;
  otherClientInfo = null;
  clientDbName = null;
  basicInformation: any[] = [];
  showMoreInfoTapCount = DEFAULT_TAP_COUNT;
  versionInformationList: VersionInformationHistory[] = [];

  constructor(
    private updateService: UpdateService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private progressBarService: ProgressBarService,
    private tokenService: UtilocateTokenService,
    private snackBarService: SnackbarService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.versionInformationList = [];
      this.version = environment.appVersion;
      this.clientInfo = await this.userService.getRegistration();
      this.otherClientInfo = await this.tokenService.getValueFromToken('CLIENTID');
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.getVersionInformationListFromFile();
    } catch (error) {
      console.error(error);
    }

    if (this.clientInfo) {
      this.clientDbName = this.clientInfo.ClientDBName;
    }

    this.basicInformation = [
      { label: 'App Version', value: this.version, action: () => this.showMoreInfo() },
      { label: 'Available Storage', value: await this.estimateStorage() },
      { label: 'Device Info', value: `${this.deviceInfo.deviceType}: ${this.deviceInfo.device}` },
      { label: 'User Service Reg', value: this.clientDbName },
      { label: 'Software Update', value: '', button: () => this.syncVersion(), icon: 'download' },
    ];
    this.basicInformation = this.basicInformation.filter(
      (info) => (info.value != '' && info.value != null) || (info.button && info.icon)
    );
    this.dataSource = new MatTableDataSource(this.basicInformation);
  }

  /**
   * Forces sync of version
   */
  async syncVersion() {
    this.progressBarService.start();
    try {
      if (confirm(`Are you sure you want to force and update?`)) {
        await this.clearBrowserCachesUpdate();
        await this.updateService.activateUpdate();
      }
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
    window.location.reload();
  }

  /**
   * Dynamically add more data to the table
   * You can add items to the table. They must have a label and either a value or button.
   */
  showMoreInfo() {
    this.showMoreInfoTapCount--;
    if (this.showMoreInfoTapCount == 0) {
      const extraDataToShow = [this.clientInfo, this.deviceInfo, environment];

      const advancedInformation = [...this.basicInformation];
      advancedInformation.push({
        label: 'Clear Completions',
        button: () => this.clearCompletionsAdminCacheService(),
        icon: 'reset',
      });
      advancedInformation.push({ label: 'Clear IDB', button: () => this.clearIDBCache(), icon: 'reset_db' });
      advancedInformation.push({ label: 'Clear Caches', button: () => this.clearBrowserCaches(), icon: 'reset' });
      advancedInformation.push({ label: 'Refresh Window', button: () => window.location.reload(), icon: 'sync' });
      advancedInformation.push({ label: 'Unregister SW', button: () => this.unregisterAndReloadSW(), icon: 'logout' });
      advancedInformation.push({
        label: 'Token ClientID',
        value: this.otherClientInfo.toString().includes('ERROR') ? '' : this.otherClientInfo,
      });
      advancedInformation.push({ label: 'SW Enabled', value: this.updateService.getSWEnabledAsBool() });

      for (const data of extraDataToShow) {
        if (data) advancedInformation.push(...this.appendDataToDisplay(data));
      }
      advancedInformation.unshift({
        label: 'Remove extra info',
        button: () => this.removeExtraInfo(),
        icon: 'close 2',
      });
      this.dataSource = new MatTableDataSource(advancedInformation);
    }
  }

  /**
   * Sets the info to the old info
   */
  removeExtraInfo() {
    this.dataSource = new MatTableDataSource(this.basicInformation);
    this.showMoreInfoTapCount = DEFAULT_TAP_COUNT; //reset count
  }

  unregisterAndReloadSW() {
    if (confirm(`Are you sure you want to unregister and reload the service worker?`)) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
      window.location.reload();
    }
  }

  /**
   * Adds the input data to the display table
   * @param inputObject
   * @returns
   */
  appendDataToDisplay(inputObject: any) {
    const advancedInformation = [];
    if (inputObject) {
      const keys = Object.keys(inputObject);
      for (const key of keys) {
        if (OMIT_FIELD_NAMES.includes(key)) continue;
        advancedInformation.push({ label: key, value: inputObject[key] ? inputObject[key] : null });
      }
      this.snackBarService.openSnackbar(
        'Warning: These are admin controls. Do not use unless instructed to.',
        SnackbarType.warning
      );
      return advancedInformation;
    }
  }

  getVersionInformationListFromFile() {
    if (this.versionInformationList) {
      for (const unit of historyFromJSON.versionHistory) {
        const newHistory = new VersionInformationHistory();
        newHistory.VersionNumber = unit.VersionNumber;
        newHistory.VersionDate = unit.VersionDate;
        newHistory.Title = unit.Title;
        newHistory.Description = unit.Description;

        this.versionInformationList.push(newHistory);
      }
      this.versionInformationList.sort((a, b) => {
        return Number(b.VersionNumber) - Number(a.VersionNumber);
      });
    }
  }

  clearIDBCache() {
    this.progressBarService.start();
    if (confirm(`Are you sure you want to clear the IDB cache? This could cause the program to break.`)) {
      this.userService.clearIDBCache();
    }
    this.progressBarService.stop();
  }

  clearCompletionsAdminCacheService() {
    this.progressBarService.start();
    if (confirm(`Are you sure you want to clear the completion and admin cache services?`)) {
      this.userService.clearCompletionsAdminCacheService();
    }
    this.progressBarService.stop();
  }

  clearBrowserCaches() {
    if (confirm(`Are you sure you want to clear all caches?`)) {
      caches.keys().then(function (cacheNames) {
        return Promise.all(
          cacheNames
            .filter(function (cacheName) {
              console.log(`Clearing ${cacheName}`);
              return true;
            })
            .map(function (cacheName) {
              return caches.delete(cacheName);
            })
        );
      });
    }
    this.snackBarService.openSnackbar('Caches cleared', SnackbarType.success);
  }

  clearBrowserCachesUpdate() {
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames
          .filter(function (cacheName) {
            console.log(`Clearing ${cacheName}`);
            return true;
          })
          .map(function (cacheName) {
            return caches.delete(cacheName);
          })
      );
    });
    this.snackBarService.openSnackbar('Caches cleared', SnackbarType.success);
  }

  async estimateStorage() {
    const mb = 1024 * 1024;
    const bytes = await navigator.storage.estimate();
    return `${(bytes.usage / mb).toFixed(2)} / ${(bytes.quota / mb).toFixed(2)} MB`;
  }
}
