import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableComponent } from './table/table.component';
import { TableColumnFilterPipe, TableRowFilterPipe } from './table/table-filter.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TableColumnSelectComponent } from './table-column-select/table-column-select.component';
import { SearchableDropdownModule } from '../searchable-dropdown/searchable-dropdown.module';
import { NgxDatatableDblClickDirective } from './table/row-double-click.directive';
import { EditableTableComponent } from './editable-table/editable-table.component';
import { TableFilterPipe } from './editable-table/table-filter.pipe';
import { CompetersSearchBarComponent } from '../../../shared/components/inputs/competers-search-bar/competers-search-bar.component';

@NgModule({
  declarations: [EditableTableComponent, TableFilterPipe],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ScrollingModule,
    NgxDatatableModule,
    SearchableDropdownModule,
    TableComponent,
    TableColumnFilterPipe,
    TableColumnSelectComponent,
    TableRowFilterPipe,
    NgxDatatableDblClickDirective,
    CompetersSearchBarComponent,
  ],
  exports: [
    TableComponent,
    TableColumnFilterPipe,
    TableColumnSelectComponent,
    TableRowFilterPipe,
    TableColumnSelectComponent,
    EditableTableComponent,
    NgxDatatableDblClickDirective,
  ],
})
export class TableModule {}

export interface TemplateColumn {
  TemplateColumnID: number;
  TemplateID: number;
  Title: string;
  Field: number;
  Width: number;
  Visible: 0 | 1;
  ColumnOrder: number;
}

export interface ColumnObject {
  ColumnName: string;
  VisibleName: string;
}
