// @ts-nocheck
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { env } from "process";
import { CUSTOM_API_HEADERS } from "./InterceptorKeys";
import { AuthenticationService } from "../authentication/authentication.service";
import { api, apiStage } from "src/app/ENDPOINTS";
import { AUTH_TOKEN_PAYLOADS, TOKEN_PAYLOADS } from "./PAYLOAD_KEYS";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  // private readonly DEV_PROXY = "https://cors-anywhere.herokuapp.com/"; // allows us to make HTTPS calls
  constructor(private auth$: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.startsWith("http")) {
      return next.handle(req);
    }

    console.log("UrlInterceptor: ", req.url);

    // update URL
    const apiKey = req.url;
    let newReq: HttpRequest<any>;

    // check token version
    const VERSION = this.auth$.getVersion();

    const App = this.getAppID(VERSION);
    const TOKEN_KEYS = this.getTokenKeys(VERSION, App);

    let params = [];
    let url = "";

    let newUrl: string;
    if (VERSION) {
      params = api[apiKey].versions[VERSION].params;
      url = api[apiKey].versions[VERSION].url;

      // apply generic url keys
      newUrl = this.generateUrl(url, params, apiKey, TOKEN_KEYS);
    } else {
      params = api[apiKey].versions["0"].params;
      url = api[apiKey].versions["0"].url;

      newUrl = this.generateUrlOLD(url, params, apiKey, TOKEN_KEYS, App);
    }

    newReq = req.clone({ url: newUrl });

    // apply custom url params from header
    if (newReq.headers.has(CUSTOM_API_HEADERS.EXTRA_URL_PARAMS)) {
      const stringifiedHeaderValue = newReq.headers.get(
        CUSTOM_API_HEADERS.EXTRA_URL_PARAMS,
      );

      const urlWithCustomParams = this.applyCustomUrlParams(
        newReq.url,
        JSON.parse(stringifiedHeaderValue),
      );

      newReq.headers.delete(CUSTOM_API_HEADERS.EXTRA_URL_PARAMS);

      newReq = newReq.clone({ url: urlWithCustomParams });
    }

    console.log("newReq.url: ", newReq.url);
    if (
      newReq.url &&
      newReq.url.indexOf("null") == -1 &&
      newReq.url.indexOf("{") == -1
    ) {
      return next.handle(newReq).pipe(
        catchError((error) => {
          return of(error);
        }),
      );
    } else {
      throw new HttpErrorResponse({
        error: "Bad Request - cannot proceed",
        headers: req.headers,
        status: 500,
        url: req.url,
      });
    }
  }

  /**
   * uses auth service to get the app id from user token
   * @param version api version - determines if old or new token style
   * @returns id of app from user token
   */
  private getAppID(version: any): string | number {
    let App: string | number;
    try {
      const upperApp = this.auth$.getValueFromPayload(
        TOKEN_PAYLOADS.app.toUpperCase(),
      );
      const lowerApp = this.auth$.getValueFromPayload(TOKEN_PAYLOADS.app);

      if (version) {
        App = upperApp;
      } else {
        // old token ways - can be APP or app check for both
        App = lowerApp ? lowerApp : upperApp;
      }
    } catch (error) {
      console.error(error);
    }

    return App;
  }

  /**
   * gets app specific token keys
   * @param version api version - determines if old or new token style
   * @param App id to determine which list of tokens is needed
   * @returns list of token keys for that app id, in an object
   */
  private getTokenKeys(version: any, App: string | number) {
    let tokenKeys: any;
    if (version) {
      tokenKeys = AUTH_TOKEN_PAYLOADS[App];
    } else {
      tokenKeys = TOKEN_PAYLOADS[App];
    }
    return tokenKeys;
  }

  /**
   * uses url and params to add values to the request url before it continues with the request
   * @param url the urlto be modified
   * @param params array of strings stating which params to modify in url
   * @param apiKey the name of the api to be called, used for determing stage in this function
   * @returns newUrl created from url with params replaced by values
   */
  private generateUrl(
    url: string,
    params: string[],
    apiKey: string,
    TOKEN_KEYS,
  ): string {
    let newUrl = "";
    try {
      if (url && params) {
        let tmpUrl = url;
        const paramLen = params.length;
        for (let i = 0; i < paramLen; i++) {
          const curParam = params[i];
          let curParamKey = curParam
            .substring(1, curParam.length - 1)
            .toLowerCase();
          let curParamVal = null;

          // if (curParamKey === "userid" && TOKEN_KEYS.userid && TOKEN_KEYS.userid.name) {
          //   curParamVal = this.auth$.getValueFromPayload(TOKEN_KEYS.userid.name);
          // } else
          if (curParamKey === "stage") {
            curParamVal = this.getStage(apiKey);
          } else if (curParamKey === "documentid") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.documentid,
            );
          } else if (
            curParamKey === "assignmentid" ||
            curParamKey === "AssignmentID"
          ) {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.assignmentid,
            );
          } else if (
            curParamKey === "primaryid" ||
            curParamKey === "PrimaryID"
          ) {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.primaryid,
            );
          } else if (curParamKey === "requestnumber") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.requestnumber,
            );
          } else if (curParamKey.indexOf(".") > -1) {
            curParamKey = curParamKey.toUpperCase();
            curParamVal = this.auth$.getNestedValueFromPayload(curParamKey);
          } else {
            if (TOKEN_KEYS[curParamKey] && TOKEN_KEYS[curParamKey].name) {
              curParamVal = this.auth$.getValueFromPayload(
                TOKEN_KEYS[curParamKey].name,
              );
            }
          }

          if (curParamVal != null) {
            tmpUrl = tmpUrl.replace(curParam, curParamVal);
          }
        }

        newUrl = tmpUrl;
      }
    } catch (error) {
      console.error(error);
    }
    return newUrl;
  }

  /**
   * uses url and params to add values to the request url (similar to generateUrl but also sets userid )
   * @param url the url to be modified
   * @param params array of strings stating which params to modify in url
   * @param apiKey the name of the api to be called, used for determing stage in this function
   * @returns the modified url
   */
  private generateUrlOLD(
    url: string,
    params: string[],
    apiKey: string,
    TOKEN_KEYS,
    App,
  ) {
    let newUrl = "";
    try {
      if (url && params) {
        let tmpUrl = url;

        // If url contains /u3/ use u3 userid (App 1, userID)
        // else use u2 userid (app 0, userid) (app 1, localuserid)
        let isU3Lambda = false;
        if (url && (url.indexOf("/u3/") > -1 || url.indexOf("/U3/") > -1)) {
          isU3Lambda = true;
        }

        const paramLen = params.length;
        let u2UserID = -1;
        let u3UserID = -1;

        for (let i = 0; i < paramLen; i++) {
          if (App == 1) {
            u2UserID = this.auth$.getValueFromPayload(
              TOKEN_KEYS.localuserid.name,
            );
            u3UserID = this.auth$.getValueFromPayload(TOKEN_KEYS.userid.name);
          } else {
            u2UserID = this.auth$.getValueFromPayload(TOKEN_KEYS.userid.name);
          }

          const curParam = params[i];
          const curParamKey = curParam
            .substring(1, curParam.length - 1)
            .toLowerCase();
          let curParamVal = null;

          // if (curParamKey === "userid" && TOKEN_KEYS.localuserid && TOKEN_KEYS.localuserid.name) {
          // curParamVal = this.auth$.getValueFromPayload(TOKEN_KEYS.userid.name);
          if (
            curParamKey === "userid" &&
            TOKEN_KEYS.localuserid &&
            TOKEN_KEYS.localuserid.name
          ) {
            if (isU3Lambda) {
              curParamVal = u3UserID;
            } else {
              curParamVal = u2UserID;
            }
          } else if (curParamKey === "stage") {
            curParamVal = this.getStage(apiKey);
          } else if (curParamKey === "documentid") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.documentid,
            );
          } else if (curParamKey === "primaryid") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.primaryid,
            );
          } else if (curParamKey === "assignmentid") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.assignmentid,
            );
          } else if (curParamKey === "requestnumber") {
            curParamVal = sessionStorage.getItem(
              localStorageKeys.URL_KEYS.requestnumber,
            );
          } else {
            if (TOKEN_KEYS[curParamKey] && TOKEN_KEYS[curParamKey].name) {
              curParamVal = this.auth$.getValueFromPayload(
                TOKEN_KEYS[curParamKey].name,
              );
            }
          }

          if (curParamVal != null) {
            tmpUrl = tmpUrl.replace(curParam, curParamVal);
          }
        }

        newUrl = tmpUrl;
      }
    } catch (error) {
      console.error(error);
    }
    return newUrl;
    newUrl = url;
  }

  /**
   * This function updates the url with custom parameters
   * @param url the request url
   * @param dataObj the parsed value of your stringified custom header
   * @returns modified url string
   */
  private applyCustomUrlParams(url: string, dataObj: { [x: string]: any }) {
    let newUrl = "";
    try {
      if (url && dataObj) {
        newUrl = url;

        // update url with custom params
        // key-to-upate has to match exactly
        const keys = Object.keys(dataObj);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = dataObj[key];
          const keyInUrl = "{" + key + "}";

          newUrl = newUrl.replace(keyInUrl, value);
        }
      } else {
        throw new Error("URL or dataobj are invalid");
      }
    } catch (error) {
      console.error(error);
      newUrl = url;
    }
    return newUrl;
  }

  /**
   * a function that uses apiKey and apiStages (from ENDPOINTS.ts) to find correct API stage name
   * @param apiKey the name of the api to be called
   * @returns stage - live (or another live stage name ie liveV0api2) or dev
   */
  private getStage(apiKey: string): string {
    let stage = "dev";

    // verify prod mode then find correct API stage name
    if (environment.production) {
      const stages = Object.keys(apiStage);

      // default stage value is "live" unless apiKey is found in another stage
      for (let i = 0; i < stages.length; i++) {
        if (apiStage[stages[i]].indexOf(apiKey) > -1) {
          stage = stages[i];
          break;
        } else {
          stage = "live";
        }
      }
    }

    return stage;
  }
}
