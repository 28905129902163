import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../../core/services/user/user.service";
import {
  PassRegisterResult,
  RegisterResult,
  UtilocateApiService,
} from "../../core/api/utilocateapi.service";
import { UtilocateAdminCacheService } from "../../core/cache/utilocate-admin.service";
import { Registration } from "../../core/services/token/token.service";

@Injectable({
  providedIn: "root",
})
export class UserPassLoginService {
  IS_LIVE: boolean;
  IS_DEV: boolean;
  isLocalHost: boolean;

  private userPassAuthorizedSubject: BehaviorSubject<boolean>;

  userPassAuthorizedChanges(): Observable<boolean> {
    return this.userPassAuthorizedSubject.asObservable();
  }

  sendUserPassAuthorizedChanges() {
    this.userPassAuthorizedSubject.next(this.userService.isAuthorized());
  }

  constructor(
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private utilocateApiService: UtilocateApiService,
    private userService: UserService,
  ) {
    this.userPassAuthorizedSubject = new BehaviorSubject<boolean>(
      this.userService.isAuthorized(),
    );
    this.IS_LIVE = environment.production == true;
    this.IS_DEV = environment.production == false && environment.url == null;
    this.isLocalHost = environment.localhost;
  }

  clearCache() {
    try {
      let registration = this.getRegistration();
      sessionStorage.clear();
      if (registration) {
        this.userService.registerUser(registration);
      }
    } catch (error) {
      sessionStorage.clear();
    }
  }

  isRegistered(): boolean {
    return this.userService.isRegistered();
  }

  getRegistration(): Registration {
    return this.userService.getRegistration();
  }

  async verifyClientRegistrationCode(
    registrationCode: string,
  ): Promise<RegisterResult | boolean> {
    try {
      // verify registration code
      let result: RegisterResult =
        await this.utilocateApiService.verifyRegistrationCode(registrationCode);
      if (result instanceof PassRegisterResult) {
        let registration: Registration = {
          ClientID: result.CompanyInfo["ClientID"],
          Code: registrationCode,
          ClientDBName: result.CompanyInfo["ClientDBName"],
          isOld: result.CompanyInfo["isOld"],
          ClientName: result.CompanyInfo["ClientName"],
        };

        this.userService.registerUser(registration);
        return result;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getListOfUsers(ClientID, isOld): Promise<any> {
    try {
      let url = "/api/get/loginTable"; // localhost

      if (!this.isLocalHost) {
        url = "/nodejs/api/get/loginTable";
      }

      let usersResult = await this.utilocateApiService.invokeApi("PUT", url, {
        ClientID: ClientID,
        isOld: isOld,
      });
      if (usersResult.status == 200) {
        let users: [] = usersResult.body.results;

        let formattedUsers = [];
        users.forEach((element) => {
          formattedUsers.push({
            UserID: element["UserID"],
            UserDetails:
              element["FirstName"] +
              " " +
              element["LastName"] +
              " (" +
              element["Title"] +
              ")",
          });
        });
        return formattedUsers;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async authenticate(userID, password, clientID): Promise<boolean> {
    try {
      let tokenResult = await this.utilocateApiService.generateTokenFieldSide(
        userID,
        password,
        clientID,
      );
      if (
        tokenResult.status == 200 &&
        JSON.parse(tokenResult.body.value)
          ["result"][0].toLowerCase()
          .indexOf("failed") == -1
      ) {
        let token = JSON.parse(tokenResult.body.value)["result"][0];
        await this.userService.loginUserWithToken(token);
        this.sendUserPassAuthorizedChanges();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return error;
    }
  }
}
