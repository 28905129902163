import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import {AutologGroup, AutologListItem} from "./ticket-autologs-item/ticket-autologs-item.component";

@Component({
  selector: "app-ticket-autologs",
  templateUrl: "ticket-autologs.component.html",
  styleUrls: ["./ticket-autologs.component.scss"],
})
export class TicketAutologsComponent implements OnChanges {
  @Input() AutologList: AutologListItem[];
  @Output() newAutolog = new EventEmitter<any>();
  @Output() Swipe = new EventEmitter<any>();
  @ViewChild('searchbar') searchbar: Element;

  showDate: any;
  userInfo: any;
  searchText: any = '';
  listFilter = "type";
  routeFilter = "route";
  orderedAutologList: AutologGroup[];

  protected filters = [false, false, false]

  ngOnChanges(): void {
    if (this.AutologList && this.AutologList.length > 0) {
      this.orderedAutologList = this.orderAutologList(this.AutologList);
    }
  }

  getLogDateOnly(logDateWithTime: string): string {
    // Create a new Date object from the input string
    const dateObj = new Date(logDateWithTime);

    // Extract the year, month, and day from the Date object
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // getMonth returns 0-11
    const day = dateObj.getDate();

    // Format month and day to ensure two digits (e.g., '04' instead of '4')
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // Return the formatted date string in 'YYYY-MM-DD' format
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  orderAutologList(autologList: AutologListItem[]): AutologGroup[] {
    const groups: Record<string, AutologListItem[]> = {};

    for (const item of autologList) {
      const dateOnly = this.getLogDateOnly(item.logDate);
      if (!groups[dateOnly]) {
        groups[dateOnly] = [];
      }
      groups[dateOnly].push(item);
    }

    // Sort each group's items by logDate descending, comparing Date objects
    for (const date in groups) {
      groups[date].sort((a, b) => new Date(b.logDate).getTime() - new Date(a.logDate).getTime());
    }

    // Convert the groups object into an array and sort by date descending, also using Date objects
    const result: {date: string, list: AutologListItem[]}[] = Object.keys(groups)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime()) // Sort dates in descending order
      .map(date => ({date: date, list: groups[date]}));

    return result;
  }

  openCreateNewAutolog() {
    this.newAutolog.emit();
  }

  public onFilter() {
    //TODO: Filter autologs
  }

  onSwipe(event) {
    this.Swipe.emit(event);
  }
}


