import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  selector: "app-call-modal",
  templateUrl: "./call-modal.component.html",
})
export class CallModalComponent implements OnInit {
  dateControl: FormControl;
  data: any;
  phoneNumber: any;
  callerName: any;
  alternateName: any;
  altName: any;
  altNumber: any;
  doesHaveData: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<CallModalComponent>
  ) {
    this.data = _data;
    this.callerName = this.data.name;
    this.phoneNumber = this.data.number;
    this.altName = this.data.alternateName
    this.altNumber = this.data.alternateNumber;
  }

  ngOnInit() {
    if (this.phoneNumber == null && !this.altNumber) {
      this.doesHaveData = false;
    }
  }

  onConfirm(event) {
    this.dialogRef.close({ event: "close", result: true });
  }
}
