import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TicketDocumentsService } from '../ticket-documents.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';

@Component({
  selector: 'app-ticket-documents-gallery',
  templateUrl: './ticket-documents-gallery.component.html',
  styleUrl: './ticket-documents-gallery.component.scss'
})
export class TicketDocumentsGalleryComponent implements OnInit {
  @Input() imageUrls: any;
  @Output() rowSelected = new EventEmitter<any>();
  @Output() documentSelect = new EventEmitter<any>();


  private docsSubject: Subscription;

  docs: any
  url: any
  selectMode: boolean = false
  holdTimer: any;
  isHeldDown: boolean = false;
  deletableDocs: any
  private heldDownSubscription: Subscription;

  constructor(
    private userService: UserService,
    private ticketDocumentService: TicketDocumentsService
  ) {}
  
  ngOnInit(): void {
    this.docsSubject = this.ticketDocumentService.docRows$.subscribe(docRows => {
      this.docs = docRows;            
    });

    this.heldDownSubscription = this.ticketDocumentService.isHeldDown$.subscribe(isHeldDown => {
      this.isHeldDown = isHeldDown;
    });
  
    this.deletableDocs = this.userService.getSettingValue(SettingID.DELETE_DOCUMENTS)

  }

  onMouseDown() {
    this.holdTimer = setTimeout(() => {
        this.multiSelect();
    }, 2000);
  }

  onMouseUp() {
    clearTimeout(this.holdTimer);
  }

  isPDF(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.pdf');
  }

  isDBFile(fileName: string): boolean {
    return fileName.toLowerCase().endsWith('.dbf') || fileName.toLowerCase().endsWith('.geojson') ;
  }


  multiSelect(){
    this.ticketDocumentService.setIsHeldDown(true)    
  }

  documentSelected(event) {    
    this.documentSelect.emit({FileName: event.FileName, DocumentID: event.DocumentID, isS3Document: event.isS3Document,});
  }


  selectedMode(event){
    this.selectMode = !this.selectMode
    if(event.selected){
      this.ticketDocumentService.addSelected(event.DocumentID, event.isS3Document, false)
      this.rowSelected.emit({FileName: event.FileName, DocumentID: event.DocumentID, isS3Document: event.isS3Document, isSendable: event.isSendable, DocumentTypeID: event.DocumentTypeID,  isDownloaded: event.isDownloaded, bFieldAdded: event.bFieldAdded, Removed: true})
      return
    }
      this.ticketDocumentService.addSelected(event.DocumentID, event.isS3Document, true)

      this.rowSelected.emit({FileName: event.FileName, DocumentID: event.DocumentID, isS3Document: event.isS3Document, isSendable: event.isSendable, DocumentTypeID: event.DocumentTypeID,  isDownloaded: event.isDownloaded, bFieldAdded: event.bFieldAdded, Removed: false})
  }

  ngOnDestroy() {
     if (this.docsSubject) {
      this.docsSubject.unsubscribe();
    }
    if (this.heldDownSubscription) {
      this.heldDownSubscription.unsubscribe();
    }
  }

}
