import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Rule, RuleType } from "../algorithm";
import { ActionID } from "../routing-preset.service";

@Component({
  selector: "app-routing-preset-rule",
  templateUrl: "routing-preset-rule.component.html",
  styleUrls: ["routing-preset-rule.component.scss"],
})
export class RoutingPresetRuleComponent {
  constructor() {}

  @Input() rule: Rule;
  @Input() ruleTypes: RuleType[];
  @Output() distanceModified = new EventEmitter<any>();

  /**
   * remove a rule from the list
   */
  delete() {
    console.log(this.rule);
    this.rule.ActionID = ActionID.DELETE;
  }

  /**
   * update the rule, if newType than change how the rule is setup
   * @param newType type of the rule after the input changed, optional
   */
  inputChanged(newType?: number) {
    if (newType) {
      for (let i in this.ruleTypes) {
        if (this.ruleTypes[i].RuleTypeID == newType) {
          this.rule.RuleType = this.ruleTypes[i];
          this.rule.RuleValue = null;
          this.rule.RuleValue2 = null;
        }
      }
    }

    if (this.rule.ActionID == ActionID.NOTHING) {
      this.rule.ActionID = ActionID.UPDATE;
    }
  }
}
