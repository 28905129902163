import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentViewerComponent } from "./document-viewer/document-viewer.component";
import { PdfAcknowledgementComponent } from "./pdf-acknowledgement/pdf-acknowledgement.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DocumentViewerSignatureFormComponent } from "./document-viewer-signature-form/document-viewer-signature-form.component";
import { SignaturePadComponent } from "./signature-pad/signature-pad.component";
import { BaseModalModule } from "../shared/modals/base-modal.module";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { PdfAcknowledgementPanelComponent } from "./pdf-acknowledgement/pdf-acknowledgement-panel/pdf-acknowledgement-panel.component";
import { AcknowledgementViewComponent } from "./pdf-acknowledgement/views/acknowledgement-view/acknowledgement-view.component";
import { DeclineAckViewComponent } from "./pdf-acknowledgement/views/decline-ack-view/decline-ack-view.component";
import { FrenchEnglishOptionViewComponent } from "./pdf-acknowledgement/views/french-english-option-view/french-english-option-view.component";
import { PdfViewComponent } from "./pdf-acknowledgement/views/pdf-view/pdf-view.component";
import { SignatureViewComponent } from "./pdf-acknowledgement/views/signature-view/signature-view.component";

@NgModule({
  declarations: [
    DocumentViewerComponent,
    PdfAcknowledgementComponent,
    DocumentViewerSignatureFormComponent,
    SignaturePadComponent,
    PdfViewerComponent,
    PdfAcknowledgementPanelComponent,
    AcknowledgementViewComponent,
    DeclineAckViewComponent,
    FrenchEnglishOptionViewComponent,
    PdfViewComponent,
    SignatureViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    BaseModalModule,
    NgxExtendedPdfViewerModule,
    // SigAreaModule
  ],
})
export class DocumentViewerModule {}
