<h2 mat-dialog-title>Ticket Protection Issue</h2>
<div fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutGap="16px">
  <div mat-dialog-content fxLayout="column">
    <p>{{ message }}</p>
    <!-- <br> -->
    <p *ngIf="protectionExpired">{{ protectedMessage }}</p>
  </div>
  <div
    *ngIf="protectionExpired"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    style="width: 100%"
  >
    <button
      mat-button
      color="primary"
      (click)="clickOverride()"
      cdkFocusInitial
    >
      OVERRIDE
    </button>
    <button mat-button [mat-dialog-close]="cancel">CANCEL</button>
  </div>
  <div
    *ngIf="!protectionExpired"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    style="width: 100%"
  >
    <button mat-button [mat-dialog-close]="cancel">OK</button>
  </div>
</div>
