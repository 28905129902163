import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  LoginComponent,
  PrivacyPolicyDialogComponent,
} from "./login/login.component";
import { MaterialModule } from "../material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AuthorizedUserComponent } from "./authorized-user/authorized-user.component";

@NgModule({
  declarations: [LoginComponent, PrivacyPolicyDialogComponent, AuthorizedUserComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  exports: [LoginComponent, AuthorizedUserComponent],
})
export class LoginModule {}
