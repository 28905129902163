// disable ts for this file
// @ts-nocheck
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
  Output,
} from "@angular/core";
import {
  TimeScaleModel,
  CellClickEventArgs,
  ResourceDetails,
  ActionEventArgs,
  PopupOpenEventArgs,
  DragEventArgs,
  EJ2Instance,
} from "@syncfusion/ej2-angular-schedule";
import { formatDate } from "@angular/common";
import { DragAndDropEventArgs } from "@syncfusion/ej2-angular-navigations";
import { closest } from "@syncfusion/ej2-base";
import { MatDialog } from "@angular/material/dialog";
import { SchedulerTicketDialogComponent } from "../../dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component";
import { DateTimePicker } from "@syncfusion/ej2-angular-calendars";
import {
  DropDownList,
  MultiSelectModule,
  MultiSelect,
  ChangeEventArgs,
} from "@syncfusion/ej2-angular-dropdowns";
import { SchedulerOptionsDialogComponent } from "../../dialogs/scheduler-options-dialog/scheduler-options-dialog.component";
import { SchedulerCreateTicketDialogComponent } from "../../dialogs/scheduler-create-ticket-dialog/scheduler-create-ticket-dialog.component";
import { SchedulerCancelTicketDialogComponent } from "../../dialogs/scheduler-cancel-ticket-dialog/scheduler-cancel-ticket-dialog.component";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { SchedulerMoveEventDialogComponent } from "../../dialogs/scheduler-move-event-dialog/scheduler-move-event-dialog.component";
import { FormValidator } from "@syncfusion/ej2-angular-inputs";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  Resource,
  Event,
  DBEvent,
  FilterOption,
  TicketTag,
  Ticket,
} from "../../syncfusion-scheduler/schedulerTypes";
import { SyncfusionSchedulerComponent } from "../../syncfusion-scheduler/syncfusion-scheduler.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { UploadAutologService } from "src/app/modules/core/services/logger/upload-autolog.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { AuthenticationService } from "src/app/modules/core/authentication/authentication.service";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-scheduler-view",
  templateUrl: "./scheduler-view.component.html",
  styleUrls: ["./scheduler-view.component.css"],
  providers: [ComponentMessagingService],
})
export class SchedulerViewComponent
  extends BaseViewComponent
  implements OnInit {
  className = "SchedulerViewComponent";

  @Input() props: any;
  @Input() events: Event[];
  @Input() ticketTags: any[];
  @Input() peopleResources: any[];
  @Input() drillerResources: any[];
  @Input() helperResources: any[];
  @Input() resourceEquipmentResources: any[];
  @Input() resources: Resource[];
  @Input() resourcesDataSource: {}[];
  @Input() tickets: any[];
  @Input() parentEvents: Event[];
  @Input() readonly: boolean;

  @Output() dateViewChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("scheduler") scheduler: SyncfusionSchedulerComponent;
  @ViewChild("itemTemplate") itemTemplate;

  heightMod: number = 56;
  views: Array<string> = ["TimelineWeek", "TimelineMonth"];
  timeScale: TimeScaleModel = { enable: true, interval: 1440, slotCount: 1 };
  actions: any;
  fields = {
    id: "EventID",
    location: { name: "Location" },
    startTime: { name: "StartTime", validation: { required: true } },
    endTime: { name: "EndTime" },
    assignmentID: { name: "AssignmentID", validation: { required: true } },
    subject: { name: "EventName" },
    bIncludeWeekends: { name: "bIncludeWeekends" },
    EventTypeID: { name: "EventTypeID" },
    EventNotes: { name: "EventNotes" },
    EventStart: { name: "EventStart" },
    EventEnd: { name: "EventEnd" },
    EventColour: { name: "EventColour" },
    // UserResponsibleID: { name: 'Driller'}
  };
  weekFirstDay = 0;

  private lastEditedEvent: Event;
  private wasDragged: boolean = false;

  private scrollTop = 0;
  private copiedAssignmentID = null;

  private cancelQuickInfo = false;
  private gototResourceID = -1;

  private peopleMultiListObject: MultiSelect;
  private drillerDropdown: DropDownList;

  constructor(
    logger$: LoggerService,
    private autolog$: UploadAutologService,
    private auth$: AuthenticationService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService
  ) {
    super(logger$);
  }

  /* -------- Lifecycle Functions -------- */

  ngOnInit(): void {
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });

    this.actions = {
      getResourceName: this.getResourceName,
      getResourceColour: this.getResourceColour,
      getTagName: this.getTagName,
      getTagColour: this.getTagColour,
      getPeople: this.getPeople,
      getResourceEquipment: this.getResourceEquipment,
      getTimeRangeString: this.getTimeRangeString,
      onActionBegin: this.onActionBegin,
      viewTicket: this.openTicketDialog,
      popupOpen: this.onPopupOpen,
      nodeDrag: this.nodeDrag,
      popupClose: this.popupClose,
      cellClick: this.cellClicked,
      getCallerName: this.getCallerName,
      getExtentOfWork: this.getExtentOfWork,
      actionComplete: this.actionComplete,
      getTagsFromTicket: this.getTagsFromTicket,
      getNumberOfSchuduledRigs: this.getNumberOfSchuduledRigs,
      getTotalRigs: this.getTotalRigs,
      getSchduledDrillers: this.getSchduledDrillers,
      getDisplayTicketString: this.getDisplayTicketString,
      getAssignedUser: this.getAssignedUser,
      getEventName: this.getEventName,
      dragStop: this.dragStop,
      onCopyClick: this.onCopyClick,
      onMoveToClick: this.onMoveToClick,
      navigatePrevEvent: this.navigatePrevEvent,
      navigateNextEvent: this.navigateNextEvent,
      filterResourcesOnChange: this.filterResourcesOnChange,
    };
  }

  /* -------- Functions for getting and sending messages -------- */

  sendMessage(action: MessageAction, message: {}) {
    const messageToSend: ComponentMessage = {
      action: action,
      message: message,
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(messageToSend);
  }

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.filterEvents) {
        this.scheduler.filterEvents(compMessage.message.filterOptions);
      }

      if (compMessage.message.queryEvents) {
        this.scheduler.queryEvents(compMessage.message.queryEvents);
      }

      if (compMessage.message.ticketDropped) {
        this.onDropTicket(
          compMessage.message.onDropEvent,
          compMessage.message.ticketData
        );
      }

      if (compMessage.message.refresh) {
        this.refresh();
      }

      if (compMessage.message.refreshSchedule) {
        this.refreshSchedule();
      }

      if (compMessage.message.scrollToResource) {
        this.scrollToResource();
      }

      if (compMessage.message.cancelTicketComplete) {
        this.sendMessage(MessageAction.READY, {
          eventRemoved: true,
          EventIDs: compMessage.message.EventIDs,
        });
      }

      if (compMessage.message.removeEventComplete) {
        this.scheduler.deleteEvents(compMessage.message.EventIDs);
        this.refreshSchedule();
      }

      if (compMessage.message.openTicketDialog) {
        const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
          width: window.innerWidth.toString() + "px",
          maxWidth: "100%",
          height: window.innerHeight.toString() + "px", // if small screen make full screen
          panelClass: "margin-dialog-container",
          data: {
            ticketData: compMessage.message.ticketData,
          },
        });
      }
    }
  }

  /* -------- Event Functions -------- */

  public nodeDrag(args: DragAndDropEventArgs): void {
    if (
      args.droppedNode != null &&
      args.droppedNode.getElementsByClassName("folder") &&
      args.droppedNode.getElementsByClassName("folder").length === 0
    ) {
      args.dropIndicator = "e-no-drop";
    }
  }

  onDropTicket(args: DragAndDropEventArgs, ticketData) {
    // prevents users from moving tickets to be sub tickets in treeview
    if (
      args.droppedNode != null &&
      args.droppedNode.getElementsByClassName("folder") &&
      args.droppedNode.getElementsByClassName("folder").length === 0
    ) {
      args.cancel = true;
      return;
    }

    let treeElement: Element = <Element>closest(args.target, ".e-treeview");
    let classElement: HTMLElement =
      this.scheduler.getElement(".e-device-hover");
    if (classElement) {
      classElement.classList.remove("e-device-hover");
    }

    if (!treeElement) {
      args.cancel = true;
      let scheduleElement: Element = <Element>(
        closest(args.target, ".e-content-wrap")
      );

      if (scheduleElement) {
        if (args.target.classList.contains("e-work-cells")) {
          try {
            let cellData: CellClickEventArgs = this.scheduler.getCellDetails(
              args.target
            );
            let resourceDetails: ResourceDetails =
              this.scheduler.getResourceByIndex(cellData);
            // add the time to the date from the cell
            var startTime: Date = new Date(cellData.startTime);
            startTime.setHours(8);
            var endTime: Date = new Date(cellData.startTime);
            endTime.setHours(17);

            let eventData: Event = {
              EventID: this.getMaxEventID(this.events),
              AssignmentID: ticketData.AssignmentID,
              StartTime: startTime,
              EndTime: endTime,
              EventStart: new Date(startTime),
              EventEnd: new Date(endTime),
              EventName: ticketData.ExcavatorCompany,
              ResourceID: resourceDetails.resourceData.ResourceID as number,
              Location: ticketData.Location,
              ParentEventID: null,
              EventTypeID: 2,
            };

            // open the event editor
            this.scheduler.openEditor(eventData, "Add");
          } catch (ex) {
            this.logger$.error("SchedulerViewComponent: onDropTicket: ", ex);
          }
        }
      }
    }
  }

  dragStop = (args) => {
    if (args.name == "dragStop") {
      let cellData = this.scheduler.getCellDetails(args.target);

      if (
        cellData &&
        args.data &&
        this.lastEditedEvent &&
        this.removeTimeFromDate(args.data.StartTime).getTime() ==
        this.removeTimeFromDate(this.lastEditedEvent.StartTime).getTime()
      ) {
        let resourceID: number = this.scheduler.getResourceByIndex(cellData)
          .resourceData.ResourceID as number;

        if (this.lastEditedEvent.ResourceID != resourceID) {
          this.wasDragged = true;
        }
      } else {
        args.cancel = true;
      }
    } else if (args.name == "dragStart") {
      this.lastEditedEvent = args.data;
    }
  };

  onActionBegin = (args: ActionEventArgs): void => {
    if (args.requestType === "eventCreate") {
      this.createEvent(args.data[0]);
    } else if (args.requestType === "eventRemove") {
      // if (Array.isArray(args.deletedRecords)) {
      //   // this.removeEvent(args.deletedRecords);
      // }
    } else if (args.requestType === "eventChange") {
      this.updateEvent(args.data as Event);
    }
  };

  emitDeleteEvent(event) {
    try {
      let eventIDs = [];

      // find my event from EventID
      eventIDs.push(event);
      let curEvent = this.events.find((cur, index) => cur["EventID"] == event);

      if (curEvent["EventTypeID"] == 2) {
        if (curEvent["ParentEventID"] == null) {
          for (let i = this.events.length - 1; i >= 0; i--) {
            if (
              this.events[i].ParentEventID != null &&
              this.events[i].ParentEventID == curEvent["EventID"]
            ) {
              eventIDs.push(this.events[i].EventID);
              this.events.splice(i, 1);
            }
          }
        } else if (curEvent["ParentEventID"] != null) {
          for (let i = this.events.length - 1; i >= 0; i--) {
            if (
              (this.events[i].ParentEventID != null &&
                this.events[i].ParentEventID == curEvent["ParentEventID"] &&
                this.events[i].EventID != curEvent["EventID"]) ||
              (this.events[i].ParentEventID == null &&
                this.events[i].EventID == curEvent["ParentEventID"])
            ) {
              eventIDs.push(this.events[i].EventID);
              this.events.splice(i, 1);
            }
          }
        }

        // display a dialog to ask if user would like to cancel ticket
        const dialogRef = this.dialog.open(
          SchedulerCancelTicketDialogComponent,
          {
            width: "400px",
            data: {
              message:
                "Would you like to cancel the ticket associated with this event?",
            },
          }
        );

        dialogRef.afterClosed().subscribe((result) => {
          if (result != "noCancel") {
            let primaryID = this.getPrimaryID(curEvent["AssignmentID"]);

            if (primaryID > 0) {
              // start cancel and remove processs
              this.sendMessage(MessageAction.READY, {
                cancelTicket: true,
                PrimaryID: primaryID,
                Explanation: result,
                EventIDs: eventIDs,
              });
            } else {
              // failed to find primaryID
              this.logger$.error("failed to get primaryID"); // todo: add toast
            }
          }
        });
      } else if (curEvent["EventTypeID"] == 3) {
        this.sendMessage(MessageAction.READY, {
          eventRemoved: true,
          EventIDs: eventIDs,
        });
      }
    } catch (ex) {
      this.logger$.error(
        "SchedulerViewComponent: onActionBegin: eventRemove: ",
        ex
      );
    }
  }

  actionComplete = (args: ActionEventArgs) => {
    // dateNavigate is the event after the date view has changed
    if (args.requestType == "dateNavigate") {
      const curViewDates = this.scheduler.getCurrentViewDates();
      if (curViewDates) {
        const lastDay = new Date(curViewDates[curViewDates.length - 1]);
        const firstDay = new Date(curViewDates[0]);

        const startDate = firstDay.toISOString().split('T')[0];
        const endDate = lastDay.toISOString().split('T')[0];
        this.sendMessage(MessageAction.READY, {
          dateViewChanged: true,
          endDate,
          startDate,
          curViewDates,
        });
      }
      if (this.gototResourceID > -1) {
        // console.log("gototResourceID > -1: ", this.gototResourceID);
        this.scheduler.scrollToResource(this.gototResourceID, true);
        this.gototResourceID = -1;
      }
      // console.log("currentViewDates: ", curViewDates[0]);
      // console.log("selectedDate", this.scheduler.selectedDate);
      localStorage.setItem(localStorageKeys.SCHEDULER_DATE, curViewDates[0]);
    }
  };

  onPopupOpen = (args: PopupOpenEventArgs) => {
    // event editor
    if (args.type === "Editor") {
      // console.log(args);

      this.scrollTop = document.querySelector(
        ".e-schedule-table .e-content-wrap"
      ).scrollTop;

      // do not open if the event it doesn't have an eventID
      // which prevents user from making events by clicking on the calendar
      if (!args.data.hasOwnProperty("EventID")) {
        args.cancel = true;
        return;
      }

      let eventData: any = args.data;
      this.lastEditedEvent = { ...eventData };

      if (!eventData.EventColour) {
        if (eventData.EventTypeID == 3) {
          eventData.EventColour = "#fff0ab";
        } else {
          eventData.EventColour = "#ffffff";
        }
      }

      // the EventStart and EventEnd will not have initial values if a new event is made
      let eventStart: Date = eventData["StartTime"];
      let eventEnd: Date = eventData["EndTime"];
      if (eventData["EventStart"]) {
        eventStart = eventData["EventStart"];
      }
      if (eventData["EventEnd"]) {
        eventEnd = eventData["EventEnd"];
      }

      // create the dateTime pickers
      // Start Time
      let startElement: HTMLInputElement = args.element.querySelector(
        "#startTime"
      ) as HTMLInputElement;
      if (!startElement.classList.contains("e-datetimepicker")) {
        let startTimeDP: DateTimePicker = new DateTimePicker(
          { value: eventData["StartTime"], floatLabelType: "Auto" },
          startElement
        );
        startTimeDP.max = new Date(eventEnd);
        startTimeDP.change = () => {
          let helperQuery = this.getQueryForResources(
            this.getListOfAvailablePeople(
              new Date(eventStartElement.value),
              new Date(eventEndElement.value),
              eventData
            )
          );
          this.peopleMultiListObject.query = helperQuery;
          this.peopleMultiListObject.dataBind();

          let drillerQuery = this.getQueryForResources(
            this.getListOfAvailablePeople(
              new Date(eventStartElement.value),
              new Date(eventEndElement.value),
              eventData
            )
          );
          this.drillerDropdown.query = drillerQuery;
          this.drillerDropdown.dataBind();
        };
      }

      // End Time
      var endElement: HTMLInputElement = args.element.querySelector(
        "#endTime"
      ) as HTMLInputElement;
      if (!endElement.classList.contains("e-datetimepicker")) {
        let endTimeDP: DateTimePicker = new DateTimePicker(
          { value: eventData["EndTime"], floatLabelType: "Auto" },
          endElement
        );
        endTimeDP.min = new Date(eventEnd);
        endTimeDP.change = () => {
          let helperQuery = this.getQueryForResources(
            this.getListOfAvailablePeople(
              new Date(eventStartElement.value),
              new Date(eventEndElement.value),
              eventData
            )
          );
          this.peopleMultiListObject.query = helperQuery;
          this.peopleMultiListObject.dataBind();

          let drillerQuery = this.getQueryForResources(
            this.getListOfAvailablePeople(
              new Date(eventStartElement.value),
              new Date(eventEndElement.value),
              eventData
            )
          );
          this.drillerDropdown.query = drillerQuery;
          this.drillerDropdown.dataBind();
        };
      }

      // Event Series Start Date
      var eventStartElement: HTMLInputElement = args.element.querySelector(
        "#eventStart"
      ) as HTMLInputElement;
      if (!eventStartElement.classList.contains("e-datetimepicker")) {
        var eventStartDP = new DateTimePicker(
          { value: eventStart, floatLabelType: "Auto" },
          eventStartElement
        );
        eventStartDP.max = new Date(eventEnd);
        eventStartDP.change = () => {
          if (eventData.EventTypeID == 2 && !eventData.AssignmentID) {
            let helperQuery = this.getQueryForResources(
              this.getListOfAvailablePeople(
                new Date(eventStartElement.value),
                new Date(eventEndElement.value),
                eventData
              )
            );
            this.peopleMultiListObject.query = helperQuery;
            this.peopleMultiListObject.dataBind();

            let drillerQuery = this.getQueryForResources(
              this.getListOfAvailablePeople(
                new Date(eventStartElement.value),
                new Date(eventEndElement.value),
                eventData
              )
            );
            this.drillerDropdown.query = drillerQuery;
            this.drillerDropdown.dataBind();
          }

          eventEndDP.min = new Date(eventStartElement.value);
        };
      }

      // Event Series End Date
      var eventEndElement: HTMLInputElement = args.element.querySelector(
        "#eventEnd"
      ) as HTMLInputElement;
      if (!eventEndElement.classList.contains("e-datetimepicker")) {
        var eventEndDP = new DateTimePicker(
          { value: eventEnd, floatLabelType: "Auto" },
          eventEndElement
        );
        eventEndDP.min = new Date(eventStart);
        eventEndDP.change = () => {
          if (eventData.EventTypeID == 2 && !eventData.AssignmentID) {
            let helperQuery = this.getQueryForResources(
              this.getListOfAvailablePeople(
                new Date(eventStartElement.value),
                new Date(eventEndElement.value),
                eventData
              )
            );
            this.peopleMultiListObject.query = helperQuery;
            this.peopleMultiListObject.dataBind();

            let drillerQuery = this.getQueryForResources(
              this.getListOfAvailablePeople(
                new Date(eventStartElement.value),
                new Date(eventEndElement.value),
                eventData
              )
            );

            this.drillerDropdown.query = drillerQuery;
            this.drillerDropdown.dataBind();
          }

          eventStartDP.max = new Date(eventEndElement.value);
        };
      }

      // create the resource dropdown
      // Rig
      let groupElement: HTMLInputElement = args.element.querySelector(
        "#resourceInput"
      ) as HTMLInputElement;
      if (!groupElement.classList.contains("e-dropdownlist")) {
        var dropDownListObject: DropDownList = new DropDownList({
          placeholder: "Choose group",
          value: eventData.ResourceID,
          dataSource: this.resourcesDataSource,
          fields: { text: "ResourceName", value: "ResourceID" },
          floatLabelType: "Auto",
        });

        dropDownListObject.appendTo(groupElement);
        groupElement.setAttribute("name", "ResourceID");
      }

      // Driller
      let drillerElement: HTMLInputElement = args.element.querySelector(
        "#drillerInput"
      ) as HTMLInputElement;
      if (!drillerElement.classList.contains("e-multiselect")) {
        this.drillerDropdown = new DropDownList({
          placeholder: "Choose driller",
          value: eventData.UserResponsibleID,
          dataSource: this.peopleResources,
          fields: { text: "ResourceName", value: "UserID" },
          floatLabelType: "Auto",
          showClearButton: true,
        });
        this.drillerDropdown.query = this.getQueryForResources(
          this.getListOfAvailablePeople(
            eventData["StartTime"],
            eventData["EndTime"],
            eventData
          )
        );
        this.drillerDropdown.dataBind();
        this.drillerDropdown.appendTo(drillerElement);
        drillerElement.setAttribute("name", "UserResponsibleID");
      }

      // Helper
      let peopleValue: any = eventData.PeopleResourceIDs
        ? eventData.PeopleResourceIDs
        : [];
      let peopleElement: HTMLInputElement = args.element.querySelector(
        "#helperInput"
      ) as HTMLInputElement;

      if (!peopleElement.classList.contains("e-multiselect")) {
        var options: MultiSelectModule = {
          placeholder: "Helpers",
          value: peopleValue,
          dataSource: this.peopleResources,
          fields: { text: "ResourceName", value: "ResourceID" },
          floatLabelType: "Auto",
        };
        this.peopleMultiListObject = new MultiSelect(options);
        this.peopleMultiListObject.query = this.getQueryForResources(
          this.getListOfAvailablePeople(
            eventData["StartTime"],
            eventData["EndTime"],
            eventData
          )
        );
        this.peopleMultiListObject.dataBind();
        this.peopleMultiListObject.appendTo(peopleElement);
        peopleElement.setAttribute("name", "PeopleResourceIDs");
      }

      // Equipment
      let resourceEquipmentValue: any = eventData.ResourceEquipmentIDs
        ? eventData.ResourceEquipmentIDs
        : [];
      let resourceEquipmentElement: HTMLInputElement =
        args.element.querySelector(
          "#resourceEquipmentInput"
        ) as HTMLInputElement;

      if (!resourceEquipmentElement.classList.contains("e-multiselect")) {
        var options: MultiSelectModule = {
          placeholder: "Resources and Equipment",
          value: resourceEquipmentValue,
          dataSource: this.resourceEquipmentResources,
          fields: { text: "ResourceName", value: "ResourceID" },
          floatLabelType: "Auto",
        };
        var resourceMultiListObject: MultiSelect = new MultiSelect(options);
        resourceMultiListObject.query = this.getQueryForResources(
          this.getListOfAvailableResources(
            eventData["StartTime"],
            eventData["EndTime"],
            eventData["EventID"]
          )
        );
        resourceMultiListObject.dataBind();
        resourceMultiListObject.appendTo(resourceEquipmentElement);
        resourceEquipmentElement.setAttribute("name", "ResourceEquipmentIDs");
      }

      // Ticket
      let ticketElement: HTMLInputElement = args.element.querySelector(
        "#ticketInput"
      ) as HTMLInputElement;
      let element = args.element;
      if (ticketElement) {
        if (!ticketElement.classList.contains("e-dropdownlist")) {
          let dropDownListObject: DropDownList = new DropDownList({
            placeholder: "Choose ticket",
            value: eventData.AssignmentID,
            dataSource: this.tickets,
            fields: { text: "Identifier", value: "AssignmentID" },
            floatLabelType: "Auto",
            allowFiltering: true,
          });
          dropDownListObject.appendTo(ticketElement);
          ticketElement.setAttribute("name", "AssignmentID");

          let formElement: HTMLElement = <HTMLElement>(
            args.element.querySelector(".e-schedule-form")
          );
          let validator = (formElement as EJ2Instance)
            .ej2_instances[0] as FormValidator;

          if (eventData.EventTypeID == 2) {
            //Add rules for event ticket
            console.log("eventData");
            validator.addRules("AssignmentID", {
              required: [true, "This field is required."],
            });
          } else {
            //remove rules for notes
            validator.removeRules("AssignmentID", ["required"]);
          }

          dropDownListObject.filtering = (e) => {
            let query = new Query();
            //frame the query based on search string with filter type.
            query =
              e.text != ""
                ? query.where("Identifier", "contains", e.text, true)
                : query;
            //pass the filter data source, filter query to updateData method.
            e.updateData(this.tickets, query);
          };

          // dropdown select event
          dropDownListObject.change = (args: ChangeEventArgs) => {
            if (args.e && args.item) {
              let ticket = args.itemData;

              // set the EventName
              let requestNumElement: HTMLInputElement = element.querySelector(
                "#eventName"
              ) as HTMLInputElement;
              let eventName = ticket["ExcavatorCompany"];
              requestNumElement.value = eventName;

              // set the location
              let LocationElement: HTMLInputElement = element.querySelector(
                "#location"
              ) as HTMLInputElement;
              LocationElement.value = ticket["Location"];

              // set the assignmentID
              this.lastEditedEvent.AssignmentID = ticket["AssignmentID"];
            }
          };
        }
      }
    } else if (args.type === "QuickInfo") {
      // prevents user from making events by clicking on the calendar and opening tentative events
      if (!args.data.hasOwnProperty("EventID") || this.cancelQuickInfo) {
        args.cancel = true;
        this.cancelQuickInfo = false;
      }
    }
  };

  filterResourcesOnChange = (args, eventData) => {
    // console.log(args);
    // console.log(eventData);

    if (this.drillerDropdown) {
      this.drillerDropdown.query = null;
      if (args.checked) {
        this.drillerDropdown.query = this.getQueryForResources(
          this.getListOfAvailablePeople(
            eventData["StartTime"],
            eventData["EndTime"],
            eventData
          )
        );
      } else {
        this.drillerDropdown.query = this.getQueryForResources(
          this.getListOfAllPeople()
        );
      }
      this.drillerDropdown.dataBind();
    }

    if (this.peopleMultiListObject) {
      this.peopleMultiListObject.query = null;
      if (args.checked) {
        this.peopleMultiListObject.query = this.getQueryForResources(
          this.getListOfAvailablePeople(
            eventData["StartTime"],
            eventData["EndTime"],
            eventData
          )
        );
      } else {
        this.peopleMultiListObject.query = this.getQueryForResources(
          this.getListOfAllPeople()
        );
      }
      this.peopleMultiListObject.dataBind();
    }
  };

  getQueryForResources(resourceList) {
    var predicate;
    var field = "ResourceID";
    for (var i = 0; i < resourceList.length; i++) {
      if (i === 0) {
        predicate = new Predicate(field, "equal", resourceList[i].ResourceID);
      } else {
        predicate = predicate.or(field, "equal", resourceList[i].ResourceID);
      }
    }
    return new Query().where(predicate);
  }

  popupClose = (args) => {
    this.cancelQuickInfo = false;
  };

  cellClicked = (args: CellClickEventArgs) => {
    // get the starDate and the rig from the args
    let startDate = args.startTime;
    let resource = this.resourcesDataSource[args.groupIndex];

    if (resource["ResourceTypeID"] != 4) {
      // open the options dialog
      const dialogRef = this.dialog.open(SchedulerOptionsDialogComponent, {
        width: "400px",
        data: {
          startDate: startDate,
          resource: resource,
          copiedAssignmentID: this.copiedAssignmentID,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "ticket") {
          // open create ticket dialog
          const ticketDialog = this.dialog.open(
            SchedulerCreateTicketDialogComponent,
            {
              width: window.innerWidth.toString() + "px",
              maxWidth: "100%",
              height: window.innerHeight.toString() + "px", //TODO: if small screen make full screen
              panelClass: "margin-dialog-container",
              data: {
                startDate: startDate,
                resource: resource,
              },
            }
          );

          ticketDialog.afterClosed().subscribe((result) => {
            if (result) {
              let message: ComponentMessage = {
                senderID: this.className,
                message: {
                  reloadEvents: true,
                },
                action: MessageAction.UPDATE,
              };
              this.props.messageService.sendToMessageStream(message);
            }
          });
        } else if (result == "event") {
          // Open event editor to create a new event
          let startTime = new Date(startDate);
          startTime.setHours(8);
          let endTime = new Date(startDate);
          endTime.setHours(17);

          var eventData: Event = {
            EventID: this.getMaxEventID(this.events),
            StartTime: startTime,
            EndTime: endTime,
            ResourceID: resource["ResourceID"],
            ParentEventID: null,
            EventTypeID: 2,
          };

          this.scheduler.openEditor(eventData, "Add");
        } else if (result == "note") {
          // open event editor to create a note
          let startTime = new Date(startDate);
          startTime.setHours(8);
          let endTime = new Date(startDate);
          endTime.setHours(17);

          var eventData: Event = {
            EventID: this.getMaxEventID(this.events),
            StartTime: startTime,
            EndTime: endTime,
            ResourceID: resource["ResourceID"],
            ParentEventID: null,
            EventTypeID: 3,
          };

          this.scheduler.openEditor(eventData, "Add");
        } else if (result == "paste") {
          // open create ticket dialog with the assignmentID from the copied ticket
          const ticketDialog = this.dialog.open(
            SchedulerCreateTicketDialogComponent,
            {
              width: window.innerWidth.toString() + "px",
              maxWidth: "100%",
              height: window.innerHeight.toString() + "px", // TODO: if small screen make full screen
              panelClass: "margin-dialog-container",
              data: {
                startDate: startDate,
                resource: resource,
                assignmentID: this.copiedAssignmentID,
                primaryID: this.getPrimaryID(this.copiedAssignmentID),
              },
            }
          );

          ticketDialog.afterClosed().subscribe((result) => {
            if (result) {
              let message: ComponentMessage = {
                senderID: this.className,
                message: {
                  reloadEvents: true,
                },
                action: MessageAction.UPDATE,
              };
              this.props.messageService.sendToMessageStream(message);
            }
          });
        }
      });
    } else {
      // open event editor to create a note
      let startTime = new Date(startDate);
      startTime.setHours(8);
      let endTime = new Date(startDate);
      endTime.setHours(17);

      var eventData: Event = {
        EventID: this.getMaxEventID(this.events),
        StartTime: startTime,
        EndTime: endTime,
        ResourceID: resource["ResourceID"],
        ParentEventID: null,
        EventTypeID: 3,
      };

      this.scheduler.openEditor(eventData, "Add");
    }
  };

  onCopyClick = (assignmentID) => {
    this.copiedAssignmentID = assignmentID;
    this.snackBarService.openSnackbar("Event Copied", SnackbarType.default);
  };

  onMoveToClick = (event) => {
    this.lastEditedEvent = event;

    const dialogRef = this.dialog.open(SchedulerMoveEventDialogComponent, {
      width: "400px",
      height: "75%",
      data: {
        event: event,
        resources: this.resourcesDataSource,
        drillers: this.peopleResources,
        helpers: this.peopleResources,
        equipment: this.resourceEquipmentResources,
        getHelpers: this.getListOfAvailablePeople,
        getDrillers: this.getListOfAvailablePeople,
        getResources: this.getListOfAvailableRigs,
        getAllPeople: this.getListOfAllPeople,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateEvent(event, result.start, result.end, result);
      }
    });
  };

  navigateNextEvent = (event) => {
    this.cancelQuickInfo = true;
    try {
      //assume children array is in order by dates
      let nextEventIndex = -1;
      let events: Event[];
      if (event.EventTypeID == 2) {
        if (event.ParentEventID) {
          events = this.getChildrenEvents(event.ParentEventID);
        } else {
          events = [event];
          events = events.concat(this.getChildrenEvents(event.EventID));
        }

        for (var i = 0; i < events.length; i++) {
          if (events[i].EventID == event.EventID) {
            nextEventIndex = i + 1;
            break;
          }
        }
        if (events[nextEventIndex]) {
          //check exists else nothing.
          //get Resource
          let dateFound = false;
          let nextResource = events[nextEventIndex].ResourceID;
          //get date
          let nextDate = events[nextEventIndex].StartTime;
          //get current view
          let nextEventDate: Date = events[nextEventIndex].StartTime;
          nextEventDate.setHours(0);

          let currentView = this.scheduler.getCurrentViewDates();
          if (currentView && currentView.length > 0) {
            for (let index = 0; index < currentView.length; index++) {
              let date = new Date(currentView[index].toString());
              if (date.getTime() === nextEventDate.getTime()) {
                dateFound = true;
                break;
              }
            }
            if (dateFound) {
              //scrollToResource
              this.scheduler.scrollToResource(nextResource);
            } else {
              //go to date then scroll
              this.scheduler.selectedDate = nextDate;
              //scroll needs to happen post navigation
              this.gototResourceID = nextResource;
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  navigatePrevEvent = (event) => {
    this.cancelQuickInfo = true;
    try {
      //assume children array is in order by dates
      let prevEventIndex = -1;
      let events: Event[];
      if (event.EventTypeID == 2) {
        if (event.ParentEventID) {
          events = [this.getParentEvent(event.ParentEventID)];
          events = events.concat(this.getChildrenEvents(event.ParentEventID));
        } else {
          events = [event];
          events = events.concat(this.getChildrenEvents(event.EventID));
        }

        for (var i = 0; i < events.length; i++) {
          if (events[i].EventID == event.EventID) {
            prevEventIndex = i - 1;
            break;
          }
        }
        if (events[prevEventIndex]) {
          //check exists else nothing.
          //get Resource
          let dateFound = false;
          let prevResource = events[prevEventIndex].ResourceID;
          //get date
          let prevDate = events[prevEventIndex].StartTime;
          //get current view
          let prevEventDate: Date = events[prevEventIndex].StartTime;
          prevEventDate.setHours(0);

          let currentView = this.scheduler.getCurrentViewDates();
          if (currentView && currentView.length > 0) {
            for (let index = 0; index < currentView.length; index++) {
              let date = new Date(currentView[index].toString());
              if (date.getTime() === prevEventDate.getTime()) {
                dateFound = true;
                break;
              }
            }
            if (dateFound) {
              //scrollToResource
              this.scheduler.scrollToResource(prevResource);
            } else {
              //go to date then scroll
              this.scheduler.selectedDate = prevDate;
              //scroll needs to happen post navigation
              this.gototResourceID = prevResource;
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* -------- Functions other -------- */

  openTicketDialog = (data: Event, editor: boolean): void => {
    var ticketData = this.getTicketData(data.AssignmentID);

    if (ticketData == null) {
      this.sendMessage(MessageAction.READY, {
        assignmentID: data.AssignmentID,
        getTicketByAssignmentID: true,
      });
    } else {
      const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
        width: window.innerWidth.toString() + "px",
        maxWidth: "100%",
        height: window.innerHeight.toString() + "px", // if small screen make full screen
        panelClass: "margin-dialog-container",
        data: {
          ticketData: ticketData,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        let message: ComponentMessage = {
          senderID: this.className,
          message: {
            reloadEvents: true,
          },
          action: MessageAction.UPDATE,
        };
        this.props.messageService.sendToMessageStream(message);
      });
    }
  };

  removeTimeFromDate(date: Date): Date {
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  scrollToResource() {
    // setTimeout(() => {
    //   document.querySelector(".e-schedule-table .e-content-wrap").scrollTop = this.scrollTop;
    // }, 200);
  }

  /* -------- Functions for acting on events -------- */

  createEvent(eventData) {
    try {
      var eventsToCreate: DBEvent[] = [];

      if (!eventData.ParentEventID && eventData.EventTypeID == 2) {
        if (eventData.AssignmentID) {
          let ticket = this.getTicketData(eventData.AssignmentID);
          eventData["ContactName"] = ticket.ContactName;
          eventData["ExtentOfWork"] = ticket.ExtentOfWork;
          eventData["UserResponsible"] = ticket.UserResponsible;
          eventData["JobNumber"] = ticket.JobNumber;
          eventData["City"] = ticket.City ? ticket.City : "N/A";
        }

        let childDBEvents: DBEvent[] = [];

        if (eventData["EventStart"] && eventData["EventEnd"]) {
          // make sure the parent is the start on the event
          if (eventData["StartTime"] != eventData["EventStart"]) {
            eventData["StartTime"] = new Date(eventData["EventStart"]);
            eventData["EndTime"] = new Date(eventData["EventStart"]);
            eventData["EndTime"].setHours(17);
          }

          eventData["ParentEventID"] = null;

          let eventStart: Date = new Date(eventData["EventStart"]);
          eventStart.setDate(eventStart.getDate() + 1);
          let includeWeekends: boolean = !(
            eventData["bIncludeWeekends"] == false ||
            eventData["bIncludeWeekends"] == null
          );
          let childEvents: Event[] = this.makeChildEventsBetween(
            eventStart,
            eventData["EventEnd"],
            eventData,
            includeWeekends
          );

          for (let i in childEvents) {
            childDBEvents.push(this.createInsertDBEventObject(childEvents[i]));
          }
        }
        this.parentEvents.push(eventData);
        eventsToCreate.push(
          this.createInsertDBEventObject(eventData, childDBEvents)
        );
      } else if (eventData.EventTypeID == 3) {
        eventData["EventName"] = "Note";
        eventsToCreate.push(this.createInsertDBEventObject(eventData));
      }

      if (eventData.EventNotes) {
        eventData.EventNotes = eventData.EventNotes.replaceAll("&amp;", "&");
      }
      // send message to panel to save the event
      if (eventsToCreate.length > 0) {
        this.sendMessage(MessageAction.READY, {
          eventCreated: true,
          Events: eventsToCreate,
        });
      }
    } catch (ex) {
      this.logger$.error("onActionBegin: eventCreate: ", ex);
    }
  }

  makeChildEventsBetween(
    startDate,
    endDate,
    parentEvent,
    includeWeekends: boolean
  ): Event[] {
    let events: Event[] = [];
    var curDate = new Date(startDate);
    let i = 1;

    while (curDate <= endDate) {
      var curEndDate: Date = new Date(curDate);

      if (
        includeWeekends ||
        (!includeWeekends && curDate.getDay() != 0 && curDate.getDay() != 6)
      ) {
        curEndDate.setHours(17);

        let jobNumber =
          parentEvent.JobNumber +
          this.getJobNumberModifier(parentEvent.EventStart, curDate);

        var newEvent: Event = {
          ...parentEvent,
          JobNumber: jobNumber,
          EventID: this.getMaxEventID(this.events) + i,
          ParentEventID: parentEvent.EventID,
          ResourceID: parentEvent.ResourceID,
          StartTime: new Date(curDate),
          EndTime: curEndDate,
          EventTypeID: 2,
        };
        events.push(newEvent);
        this.events.push(newEvent);
      }

      i++;
      curDate.setDate(curDate.getDate() + 1);
    }

    return events;
  }

  updateEvent(
    event,
    startRange = null,
    endRange = null,
    editSeriesData = null
  ) {
    // console.log(event, startRange, endRange, editSeriesData)
    let cancelUpdate: boolean = false;
    try {
      this.scrollTop = document.querySelector(
        ".e-schedule-table .e-content-wrap"
      ).scrollTop;

      var eventsToUpdate: DBEvent[] = [];
      var eventsToCreate: DBEvent[] = [];
      var eventsToDelete: number[] = [];
      if (event.EventNotes) {
        event.EventNotes = event.EventNotes.replaceAll("&amp;", "&");
      }

      if (
        event.EventTypeID == 2 &&
        !this.wasDragged &&
        startRange == null &&
        endRange == null
      ) {
        let parentEvent: Event;
        let beforeUpdateParentEvent: Event;
        let bIncludeWeekends: boolean = false;
        if (event.bIncludeWeekends) {
          bIncludeWeekends = true;
        }

        // setup dates for comparing
        let oldStart = this.removeTimeFromDate(this.lastEditedEvent.EventStart);
        let oldEnd = this.removeTimeFromDate(this.lastEditedEvent.EventEnd);
        let newStart = this.removeTimeFromDate(event.EventStart);
        let newEnd = this.removeTimeFromDate(event.EventEnd);

        let datesChanged: boolean =
          oldStart.getTime() != newStart.getTime() ||
          oldEnd.getTime() != newEnd.getTime();
        let rigChanged: boolean =
          this.lastEditedEvent.ResourceID != event.ResourceID;

        if (datesChanged || rigChanged) {
          if (event.ParentEventID == null) {
            parentEvent = event;
            beforeUpdateParentEvent = this.lastEditedEvent;
          } else {
            // if the parent wasn't the one changed change it
            parentEvent = this.getParentEvent(event.ParentEventID);
            beforeUpdateParentEvent = { ...parentEvent };

            if (parentEvent.ResourceID == this.lastEditedEvent.ResourceID) {
              parentEvent.ResourceID = event.ResourceID;
            }
            parentEvent.EventStart = event.EventStart;
            parentEvent.EventEnd = event.EventEnd;
          }

          var children: Event[] = this.getChildrenEvents(parentEvent.EventID);

          // update child resourceIDs, EventStart and EventEnd
          // and delete children outside of the range
          children.forEach((childEvent) => {
            let oldChild: Event = { ...childEvent };

            if (childEvent.ResourceID == this.lastEditedEvent.ResourceID) {
              childEvent.ResourceID = event.ResourceID;
            }
            childEvent.EventStart = event.EventStart;
            childEvent.EventEnd = event.EventEnd;

            if (event.EventID == childEvent.EventID) {
              childEvent.EventNotes = event.EventNotes;
              childEvent.PeopleResourceIDs = event.PeopleResourceIDs;
              childEvent.ResourceEquipmentIDs = event.ResourceEquipmentIDs;
            }

            let childDate = this.removeTimeFromDate(childEvent.StartTime);
            // if the child is outside of the new range delete or on the new start
            if (childDate <= newStart || childDate > newEnd) {
              eventsToDelete.push(childEvent.EventID);
            } else {
              eventsToUpdate.push(
                this.createUpdateDBEventObject(childEvent, oldChild)
              );
            }
          });

          // set the date of the parent if the EventStart was changed
          // make any missing children
          if (datesChanged) {
            var newChildrenEvents: Event[] = [];

            // set the parents date
            parentEvent.StartTime = new Date(event.EventStart);
            parentEvent.EndTime = new Date(event.EventStart);
            parentEvent.EndTime.setHours(17);

            if (oldStart > newStart) {
              let rangeStart = new Date(newStart);
              rangeStart.setHours(8);
              rangeStart.setDate(rangeStart.getDate() + 1); // don't need to make one for the first day
              let rangeEnd: Date;
              if (oldStart > newEnd) {
                rangeEnd = new Date(newEnd);
              } else {
                rangeEnd = new Date(oldStart);
              }
              rangeEnd.setHours(17);
              newChildrenEvents = this.makeChildEventsBetween(
                rangeStart,
                rangeEnd,
                parentEvent,
                bIncludeWeekends
              );
            }

            if (oldEnd < newEnd) {
              let rangeStart: Date;
              if (oldEnd < newStart) {
                rangeStart = new Date(newStart);
              } else {
                rangeStart = new Date(oldEnd);
              }
              rangeStart.setDate(rangeStart.getDate() + 1); // don't need to make one for old End day /new start day
              rangeStart.setHours(8);
              let rangeEnd = new Date(newEnd);
              rangeEnd.setHours(17);
              newChildrenEvents = [
                ...newChildrenEvents,
                ...this.makeChildEventsBetween(
                  rangeStart,
                  rangeEnd,
                  { ...parentEvent, ResourceID: event.ResourceID },
                  bIncludeWeekends
                ),
              ];
            }

            newChildrenEvents.forEach((childEvent) => {
              eventsToCreate.push(this.createInsertDBEventObject(childEvent));
            });
            //insert autolog to server
            this.uploadEventDateChangeAutolog(this.lastEditedEvent, event);
          }

          // format the event so it can be send to the api
          var dbEvent = this.createUpdateDBEventObject(
            parentEvent,
            beforeUpdateParentEvent
          );

          eventsToUpdate.push(dbEvent);
        } else {
          // format the event so it can be send to the api
          var dbEvent = this.createUpdateDBEventObject(
            event,
            this.lastEditedEvent
          );
          eventsToUpdate.push(dbEvent);
        }
      } else if (startRange && endRange) {
        startRange = this.removeTimeFromDate(startRange);
        endRange = this.removeTimeFromDate(endRange);

        // get the parentEventID of the event that is being changed
        let parentEventID = event.EventID;
        if (event.ParentEventID) {
          parentEventID = event.ParentEventID;
        }

        // get the parent event
        let parent: Event = this.getParentEvent(parentEventID);
        let oldParent: Event = { ...parent };
        let parentDate = this.removeTimeFromDate(parent.StartTime);

        if (
          parentDate >= startRange &&
          parentDate <= endRange
          // && parent.ResourceID == this.lastEditedEvent.ResourceID
        ) {
          if (editSeriesData.resourceID) {
            parent.ResourceID = editSeriesData.resourceID;
          }
          if (editSeriesData.drillerID != null) {
            parent.UserResponsibleID = editSeriesData.drillerID;
          }
          if (
            editSeriesData.helperIDs != null &&
            editSeriesData.helperIDs.length > 0
          ) {
            parent.PeopleResourceIDs = editSeriesData.helperIDs;
          }
          if (editSeriesData.equipmentIDs) {
            parent.ResourceEquipmentIDs = editSeriesData.equipmentIDs;
          }
          if (editSeriesData.eventColour) {
            parent.EventColour = editSeriesData.eventColour;
          }
          if (editSeriesData.eventNotes) {
            parent.EventNotes = editSeriesData.eventNotes;
          }
          eventsToUpdate.push(
            this.createUpdateDBEventObject(parent, oldParent)
          );
        }

        // get the children events
        let children: Event[] = this.getChildrenEvents(parentEventID);
        children.forEach((childEvent) => {
          let oldChild: Event = { ...childEvent };
          let childDate = this.removeTimeFromDate(childEvent.StartTime);
          if (
            childDate >= startRange &&
            childDate <= endRange
            // && childEvent.ResourceID == this.lastEditedEvent.ResourceID
          ) {
            if (editSeriesData.resourceID) {
              childEvent.ResourceID = editSeriesData.resourceID;
            }
            if (editSeriesData.drillerID != null) {
              childEvent.UserResponsibleID = editSeriesData.drillerID;
            }
            if (
              editSeriesData.helperIDs != null &&
              editSeriesData.helperIDs.length > 0
            ) {
              childEvent.PeopleResourceIDs = editSeriesData.helperIDs;
            }
            if (editSeriesData.equipmentIDs) {
              childEvent.ResourceEquipmentIDs = editSeriesData.equipmentIDs;
            }
            if (editSeriesData.eventColour) {
              childEvent.EventColour = editSeriesData.eventColour;
            }
            if (editSeriesData.eventNotes) {
              childEvent.EventNotes = editSeriesData.eventNotes;
            }
            eventsToUpdate.push(
              this.createUpdateDBEventObject(childEvent, oldChild)
            );
          }
        });

        this.refresh();
      } else {
        // format the event so it can be send to the api
        var dbEvent = this.createUpdateDBEventObject(
          event,
          this.lastEditedEvent
        );
        eventsToUpdate.push(dbEvent);
        this.wasDragged = false;
      }

      if (eventsToDelete.length > 0) {
        // remove the events from the event list
        for (let i = this.events.length - 1; i >= 0; i--) {
          if (eventsToDelete.indexOf(this.events[i].EventID) >= 0) {
            this.events.splice(i, 1);
          }
        }

        // send message to panel to delete events
        this.sendMessage(MessageAction.READY, {
          eventRemoved: true,
          EventIDs: eventsToDelete,
        });
      }

      // send message to panel to create new events
      if (eventsToCreate.length > 0) {
        this.sendMessage(MessageAction.READY, {
          eventCreated: true,
          Events: eventsToCreate,
        });
      }

      // send message to panel to update the event
      this.sendMessage(MessageAction.READY, {
        eventUpdated: true,
        Events: eventsToUpdate,
      });
    } catch (ex) {
      this.logger$.error(
        "SchedulerViewComponent: onActionBegin: eventChange: ",
        ex
      );
    }
  }

  async uploadEventDateChangeAutolog(oldEvent, event: any) {
    try {
      let clientID = this.auth$.getNestedValueFromPayload("CLIENTID");
      let userID = this.auth$.getNestedValueFromPayload("USERID");

      let assignmentID = oldEvent.AssignmentID;

      let oldStart = oldEvent.EventStart;
      let oldEnd = oldEvent.EventEnd;

      let newStart = event.EventStart;
      let newEnd = event.EventEnd;

      let explanation = `Scheduler event date change from: ${oldStart.toLocaleString()} - ${oldEnd.toLocaleString()} to: ${newStart.toLocaleString()} - ${newEnd.toLocaleString()}`;
      // console.log(`clientID: ${clientID}, userID: ${userID}, assignmentID: ${assignmentID} explanation: ${explanation}`)

      this.autolog$.uploadAutolog(
        clientID,
        assignmentID,
        userID,
        91,
        explanation
      );
    } catch (ex) {
      this.logger$.error(
        "ShedulerViewComponent: uploadEventDateCangeAutolog: ",
        ex
      );
    }
  }

  refresh() {
    this.scheduler.refreshEvents();
  }

  refreshSchedule() {
    this.scheduler.refresh();
  }

  filterEvents(filters: FilterOption[]) {
    console.log("REACH", filters);
    this.scheduler.filterEvents(filters);
  }

  /* -------- Functions for getting stuff -------- */

  getJobNumberModifier(date1: Date, date2: Date) {
    let d1: Date = new Date(date1);
    let d2: Date = new Date(date2);
    let mod = "";
    let dayOfWeek = d1.getDay();
    d1.setDate(d1.getDate() - dayOfWeek);
    let weeks = Math.floor(
      (d2.getTime() - d1.getTime()) / (7 * 24 * 60 * 60 * 1000)
    );
    if (weeks > 0) {
      mod = " - " + String.fromCharCode(weeks + "A".charCodeAt(0));
    }
    return mod;
  }

  getDisplayTicketString = (assignmentID: number): string => {
    let displayString: string = "";
    for (const ticket of this.tickets) {
      if (ticket.AssignmentID == assignmentID) {
        displayString = ticket.Identifier ?? this.constructIdentifierFromTicket(ticket);
        break;
      }
    }
    return displayString;
  }

  constructIdentifierFromTicket(newTicket) {
    let identifierStr = "";

    if (newTicket.ExcavatorCompany || newTicket.ExcavatorName) {
      identifierStr = newTicket.ExcavatorCompany || newTicket.ExcavatorName;
    }

    if (newTicket.Location) {
      identifierStr += " - " + newTicket.Location;
    } else if (newTicket.LocateSubRegionName && newTicket.StartHouseNumber && newTicket.LocateAddress) {
      identifierStr += ' ' + newTicket.LocateSubRegionName +
        ", " +
        newTicket.StartHouseNumber +
        " " +
        newTicket.LocateAddress;
    }

    return identifierStr;
  }


  getListOfAvailableRigs = (startDate, endDate, eventID) => {
    let availableRigs: Resource[] = [];
    let unavailableRigs = [];

    let tempStart = this.removeTimeFromDate(startDate);
    let tempEnd = this.removeTimeFromDate(endDate);

    let tmpEvent = this.getEvent(eventID);
    let parentEventID = 0;
    if (tmpEvent != null && tmpEvent.ParentEventID != null) {
      parentEventID = tmpEvent.ParentEventID;
    }

    // get unavailable rigs
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let eventStart = this.removeTimeFromDate(event.StartTime);
      let eventEnd = this.removeTimeFromDate(event.EndTime);

      if (
        ((tempStart <= eventStart && tempEnd >= eventStart) ||
          (tempStart <= eventEnd && tempEnd >= eventEnd)) &&
        event.EventID != eventID &&
        event.ParentEventID != eventID &&
        event.ParentEventID != parentEventID &&
        event.EventID != parentEventID
      ) {
        if (event.ResourceID && unavailableRigs.indexOf(event.ResourceID) < 0) {
          unavailableRigs.push(event.ResourceID);
        }
      }
    }

    for (let i = 0; i < this.resources.length; i++) {
      const rig = this.resources[i];
      if (unavailableRigs.indexOf(rig.ResourceID) < 0) {
        availableRigs.push(rig);
      }
    }

    return availableRigs;
  };

  getListOfAvailablePeople = (startDate, endDate, eventData): any[] => {
    let availablePeople: Resource[] = [];
    let unavailablePeople = [];

    let tempStart = this.removeTimeFromDate(startDate);
    let tempEnd = this.removeTimeFromDate(endDate);

    let parentID = eventData["EventID"];
    if (eventData["ParentEventID"]) {
      parentID = eventData["ParentEventID"];
    }

    // setup array of driller and helpers already on event
    let existingDrillerAndHelpers = [];
    if (eventData && eventData["PeopleResourceIDs"]) {
      let curHelpers = [...eventData["PeopleResourceIDs"]];
      existingDrillerAndHelpers = curHelpers;
    }

    if (eventData && eventData["UserResponsibleID"] != null) {
      let driller: Resource = this.getUserResponsibleResource(
        eventData.UserResponsibleID
      );
      if (driller) {
        existingDrillerAndHelpers.push(driller.ResourceID);
      }
    }

    // get unavailable people
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let eventStart = this.removeTimeFromDate(event.StartTime);
      let eventEnd = this.removeTimeFromDate(event.EndTime);

      if (
        ((tempStart <= eventStart && tempEnd >= eventStart) ||
          (tempStart <= eventEnd && tempEnd >= eventEnd)) &&
        event.EventID != parentID &&
        event.ParentEventID != parentID
      ) {
        // add this event's Driller to unavailable
        let driller: Resource = this.getUserResponsibleResource(
          event.UserResponsibleID
        );
        if (
          driller &&
          existingDrillerAndHelpers.indexOf(driller.ResourceID) < 0
        ) {
          unavailablePeople.push(driller.ResourceID);
        }

        // add this event's Helpers to unavailable
        for (let ii = 0; ii < event.PeopleResourceIDs.length; ii++) {
          const personID = event.PeopleResourceIDs[ii];
          if (
            unavailablePeople.indexOf(personID) < 0 &&
            existingDrillerAndHelpers.indexOf(personID) < 0
          ) {
            unavailablePeople.push(personID);
          }
        }
      }
    }

    for (let i = 0; i < this.peopleResources.length; i++) {
      const person = this.peopleResources[i];
      if (unavailablePeople.indexOf(person.ResourceID) < 0) {
        availablePeople.push(person);
      }
    }

    return availablePeople;
  };

  getListOfAllPeople = (): any[] => {
    let availablePeople: Resource[] = [];
    for (let i = 0; i < this.peopleResources.length; i++) {
      const person = this.peopleResources[i];
      availablePeople.push(person);
    }

    return availablePeople;
  };

  getListOfAvailableDrillers = (startDate, endDate, eventData): any[] => {
    let availablePeople: Resource[] = [];
    let unavailablePeople = [];

    let tempStart = this.removeTimeFromDate(startDate);
    let tempEnd = this.removeTimeFromDate(endDate);

    let parentID = eventData["EventID"];
    if (eventData["ParentEventID"] != null) {
      parentID = eventData["ParentEventID"];
    }

    // get unavailable people
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let eventStart = this.removeTimeFromDate(event.StartTime);
      let eventEnd = this.removeTimeFromDate(event.EndTime);

      if (
        ((tempStart <= eventStart && tempEnd >= eventStart) ||
          (tempStart <= eventEnd && tempEnd >= eventEnd)) &&
        event.EventID != parentID &&
        event.ParentEventID != parentID
      ) {
        let driller: Resource = this.getUserResponsibleResource(
          event.UserResponsibleID
        );
        if (driller) {
          unavailablePeople.push(driller.ResourceID);
        }
      }
    }

    for (let i = 0; i < this.peopleResources.length; i++) {
      const person = this.peopleResources[i];
      if (unavailablePeople.indexOf(person.ResourceID) < 0) {
        availablePeople.push(person);
      }
    }

    return availablePeople;
  };

  getListOfAvailableHelpers = (startDate, endDate, eventData): any[] => {
    let availablePeople: Resource[] = [];
    let unavailablePeople = [];

    let tempStart = this.removeTimeFromDate(startDate);
    let tempEnd = this.removeTimeFromDate(endDate);

    let parentID = eventData["EventID"];
    if (eventData["ParentEventID"] != null) {
      parentID = eventData["ParentEventID"];
    }

    // get unavailable people
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let eventStart = this.removeTimeFromDate(event.StartTime);
      let eventEnd = this.removeTimeFromDate(event.EndTime);

      if (
        ((tempStart <= eventStart && tempEnd >= eventStart) ||
          (tempStart <= eventEnd && tempEnd >= eventEnd)) &&
        event.EventID != parentID &&
        event.ParentEventID != parentID
      ) {
        for (let ii = 0; ii < event.PeopleResourceIDs.length; ii++) {
          const personID = event.PeopleResourceIDs[ii];
          if (unavailablePeople.indexOf(personID) < 0) {
            unavailablePeople.push(personID);
          }
        }
      }
    }

    for (let i = 0; i < this.peopleResources.length; i++) {
      const person = this.peopleResources[i];
      if (unavailablePeople.indexOf(person.ResourceID) < 0) {
        availablePeople.push(person);
      }
    }

    return availablePeople;
  };

  getListOfAvailableResources = (startDate, endDate, eventID): any[] => {
    let available: Resource[] = [];
    let unavailable = [];

    let tempStart = this.removeTimeFromDate(startDate);
    let tempEnd = this.removeTimeFromDate(endDate);

    // get unavailable people
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let eventStart = this.removeTimeFromDate(event.StartTime);
      let eventEnd = this.removeTimeFromDate(event.EndTime);

      if (
        ((tempStart <= eventStart && tempEnd >= eventStart) ||
          (tempStart <= eventEnd && tempEnd >= eventEnd)) &&
        event.EventID != eventID &&
        event.ResourceEquipmentIDs
      ) {
        for (let ii = 0; ii < event.ResourceEquipmentIDs.length; ii++) {
          const id = event.ResourceEquipmentIDs[ii];
          if (unavailable.indexOf(id) < 0) {
            unavailable.push(id);
          }
        }
      }
    }

    for (let i = 0; i < this.resourceEquipmentResources.length; i++) {
      const resource = this.resourceEquipmentResources[i];
      if (unavailable.indexOf(resource.ResourceID) < 0) {
        available.push(resource);
      }
    }

    return available;
  };

  getTotalRigs = () => {
    return this.resourcesDataSource.length;
  };

  getSchduledDrillers = (date) => {
    let count: number = 0;
    let scheduledDrillers = [];
    for (let i = 0; i < this.events.length; i++) {
      let event: Event = this.events[i];
      let tempStart = this.removeTimeFromDate(event.StartTime);
      let tempEnd = this.removeTimeFromDate(event.EndTime);
      if (date >= tempStart && date <= tempEnd) {
        for (let ii = 0; ii < event.PeopleResourceIDs.length; ii++) {
          const personID = event.PeopleResourceIDs[ii];
          for (let iii = 0; iii < this.drillerResources.length; iii++) {
            const person: Resource = this.drillerResources[iii];
            if (person.ResourceID == personID) {
              if (scheduledDrillers.indexOf(person.ResourceID) < 0) {
                scheduledDrillers.push(person.ResourceID);
                count++;
              }
            }
          }
        }
      }
    }
    let totalDrillers = 0;
    for (let i = 0; i < this.drillerResources.length; i++) {
      const person = this.drillerResources[i];
      totalDrillers++;
    }
    return count + " / " + totalDrillers;
  };

  getNumberOfSchuduledRigs = (date) => {
    let count: number = 0;
    let rigsScheduled = [];
    for (let i in this.events) {
      let event: Event = this.events[i];
      if (event.EventTypeID == 2) {
        let tempStart = this.removeTimeFromDate(event.StartTime);
        let tempEnd = this.removeTimeFromDate(event.EndTime);
        if (date >= tempStart && date <= tempEnd) {
          if (rigsScheduled.indexOf(event.ResourceID) < 0) {
            rigsScheduled.push(event.ResourceID);
            count++;
          }
        }
      }
    }
    return count;
  };

  getPrimaryID(assignmentID: number): number {
    let primaryID = -1;
    for (let i in this.tickets) {
      if (
        this.tickets[i]["AssignmentID"] == assignmentID &&
        this.tickets[i].PrimaryID != null
      ) {
        primaryID = this.tickets[i].PrimaryID;
        break;
      }
    }
    return primaryID;
  }

  createEventName(assignmentID: number): string {
    let eventName: string = "";
    for (let i in this.tickets) {
      if (assignmentID == this.tickets[i].AssignmentID) {
        eventName =
          this.tickets[i].ExcavatorCompany + " - " + this.tickets[i].JobNumber;
        break;
      }
    }
    return eventName;
  }

  getEvent(eventID: number): Event {
    var event: Event = null;

    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]["EventID"] == eventID) {
        event = this.events[i];
        break;
      }
    }
    return event;
  }

  getParentEvent(parentEventID: number): Event {
    var parentEvent: Event = null;

    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]["EventID"] == parentEventID) {
        parentEvent = this.events[i];
        break;
      }
    }
    return parentEvent;
  }

  getChildrenEvents(childEventID: number): Event[] {
    var children: Event[] = [];
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]["ParentEventID"] == childEventID) {
        children.push(this.events[i]);
      }
    }
    return children;
  }

  getAssignedUser = (assignmentID): string => {
    var assignedUser: string = "";
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        assignedUser = this.tickets[i]["UserResponsible"];
        break;
      }
    }
    return assignedUser;
  };

  getCallerName = (assignmentID): string => {
    var callerName: string = "";
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        callerName = this.tickets[i]["ContactName"];
        break;
      }
    }
    return callerName;
  };

  getExtentOfWork = (assignmentID): string => {
    var extentOfWork: string = "";
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        extentOfWork = this.tickets[i]["ExtentOfWork"];
        break;
      }
    }
    return extentOfWork;
  };

  getEventByAssignmentID(assignmentID) {
    var event = null;
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].AssignmentID == assignmentID) {
        event = this.events[i];
      }
    }
    return event;
  }

  getAssignmentIDOfEvent(eventID: number) {
    var assignmentID = null;
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == eventID) {
        assignmentID = this.events[i].AssignmentID;
      }
    }
    return assignmentID;
  }

  getTentativeEventIndex(eventID): number {
    var index = -1;
    for (let i = 0; i < this.events.length; i++) {
      if (
        this.events[i].ParentEventID !== null &&
        this.events[i].ParentEventID == eventID
      ) {
        index = i;
        break;
      }
    }
    return index;
  }

  getTimeRangeString = (startDate: Date, endDate: Date): string => {
    return (
      formatDate(startDate, "MMM d h:mm a", "en-US") +
      " - " +
      formatDate(endDate, "MMM d h:mm a", "en-US")
    );
  };

  getPeople = (eventID: number): string => {
    var people: string = "";
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == eventID) {
        let peopleIDs = this.events[i].PeopleResourceIDs;
        if (peopleIDs != null && Array.isArray(peopleIDs)) {
          peopleIDs.forEach((peopleID) => {
            if (people != "") {
              people = people + "/";
            }
            people = people + this.getResourceName(peopleID);
          });
        }
        people =
          this.getUserResponsibleName(this.events[i].UserResponsibleID) +
          "/" +
          people;
        break;
      }
    }
    return people;
  };

  getUserResponsibleResource(userID: number): Resource {
    let resource: Resource = null;

    for (let i in this.peopleResources) {
      if (this.peopleResources[i].UserID == userID) {
        resource = this.peopleResources[i];
        break;
      }
    }

    return resource;
  }

  getUserResponsibleName(userID: number): string {
    let name = "";
    for (let i in this.peopleResources) {
      if (this.peopleResources[i].UserID == userID) {
        name = this.peopleResources[i].ResourceName;
        break;
      }
    }
    return name;
  }

  getResourceEquipment = (eventID: number): string => {
    var re: string = "";
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].EventID == eventID) {
        let reIDs = this.events[i].ResourceEquipmentIDs;
        if (reIDs != null && Array.isArray(reIDs)) {
          reIDs.forEach((reID) => {
            if (re != "") {
              re = re + ", ";
            }
            re = re + this.getResourceName(reID);
          });
        }
        break;
      }
    }
    return re;
  };

  getResourceName = (resourceID: number): string => {
    var resourceName = "";
    for (let i = 0; i < this.resources.length; i++) {
      if (this.resources[i]["ResourceID"] == resourceID) {
        resourceName = this.resources[i]["ResourceName"];
        break;
      }
    }
    return resourceName;
  };

  getResourceColour = (resourceID: number) => {
    var resourceColour = "";
    for (let i = 0; i < this.resources.length; i++) {
      if (this.resources[i]["ResourceID"] == resourceID) {
        resourceColour = this.resources[i]["HexColour"];
        break;
      }
    }
    return resourceColour;
  };

  getTagsFromTicket = (assignmentID: number): TicketTag[] => {
    let tags: TicketTag[] = [];
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        if (this.tickets[i]["Tags"]) {
          tags = this.tickets[i]["Tags"];
        }
        break;
      }
    }
    return tags;
  };

  getTagName = (tagID: number) => {
    var tagName = "";
    for (let i = 0; i < this.ticketTags.length; i++) {
      if (this.ticketTags[i]["TagID"] == tagID) {
        tagName = this.ticketTags[i]["TagName"];
        break;
      }
    }
    return tagName;
  };

  getTagColour = (tagID: number) => {
    var tagColour = "";
    for (let i = 0; i < this.ticketTags.length; i++) {
      if (this.ticketTags[i]["TagID"] == tagID) {
        tagColour = this.ticketTags[i]["TagColour"];
        break;
      }
    }
    return tagColour;
  };

  getMaxEventID(events): number {
    var eventID = 0;
    for (let i = 0; i < events.length; i++) {
      if (events[i].EventID > eventID) {
        eventID = events[i].EventID;
      }
    }
    return eventID + 1;
  }

  getTicketData = (assignmentID): Ticket => {
    var ticket: Ticket = null;
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        ticket = this.tickets[i];
        break;
      }
    }
    return ticket;
  };

  getEventName = (assignmentID) => {
    var eventName: string = "";
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        let ticket: Ticket = this.tickets[i];
        if (ticket.ExcavatorCompany) {
          eventName = ticket.ExcavatorCompany;
        }
        if (ticket.JobNumber) {
          eventName += " - " + ticket.JobNumber;
        }
        break;
      }
    }
    return eventName;
  };

  /* -------- Functions for formatting event objects so they can be saved -------- */

  createInsertDBEventObject(event: Event, children: DBEvent[] = null): DBEvent {
    var dbEvent: DBEvent = null;
    try {
      var dbResources: number[] = [];
      dbResources.push(event.ResourceID);
      event.PeopleResourceIDs.forEach((resourceID) => {
        dbResources.push(resourceID);
      });

      event.ResourceEquipmentIDs.forEach((resourceID) => {
        dbResources.push(resourceID);
      });

      let eventNotes = event.EventNotes;
      var dbTicket: any = {
        AssignmentID: event.AssignmentID,
        TicketTags: [], // tags are gotten right from the ticket
        DispatcherRemarks: eventNotes,
      };

      var eventEnd: string = null;
      if (event.EventEnd) {
        eventEnd = formatDate(event.EventEnd, "yyyy-MM-d HH:mm:ss", "en-US");
      }

      let bIncludeWeekends: boolean = false;
      if (event.bIncludeWeekends) {
        bIncludeWeekends = true;
      }

      dbEvent = {
        EventID: event.EventID,
        AssignmentID: event.AssignmentID,
        EventLocation: event.Location,
        EventStartDateTime: formatDate(
          event.StartTime,
          "yyyy-MM-d HH:mm:ss",
          "en-US"
        ),
        EventEndDateTime: formatDate(
          event.EndTime,
          "yyyy-MM-d HH:mm:ss",
          "en-US"
        ),
        ProjectEventEndDate: eventEnd,
        ResourceIDArr: dbResources,
        Ticket: dbTicket,
        ParentEventID: event.ParentEventID,
        EventTypeID: event.EventTypeID,
        EventNotes: eventNotes,
        EventName: event.EventName,
        bIncludeWeekends: bIncludeWeekends,
      };

      if (event.EventColour) {
        dbEvent.EventColour = event.EventColour;
      }

      if (children != null) {
        dbEvent["ChildrenEvents"] = children;
      }
    } catch (ex) {
      this.logger$.error(
        "SchedulerViewComponent: createInsertDBEventObject: ",
        ex
      );
    }
    return dbEvent;
  }

  createUpdateDBEventObject(newEvent: Event, oldEvent: Event): DBEvent {
    var dbEvent: DBEvent = null;
    try {
      var eventEnd: string = null;
      if (newEvent.EventEnd) {
        eventEnd = formatDate(newEvent.EventEnd, "yyyy-MM-d HH:mm:ss", "en-US");
      }

      let eventNotes = newEvent.EventNotes;
      dbEvent = {
        EventID: newEvent.EventID,
        AssignmentID: newEvent.AssignmentID,
        EventLocation: newEvent.Location,
        EventStartDateTime: formatDate(
          newEvent.StartTime,
          "yyyy-MM-d HH:mm:ss",
          "en-US"
        ),
        EventEndDateTime: formatDate(
          newEvent.EndTime,
          "yyyy-MM-d HH:mm:ss",
          "en-US"
        ),
        ProjectEventEndDate: eventEnd,
        ParentEventID: newEvent.ParentEventID,
        EventTypeID: newEvent.EventTypeID,
        EventNotes: eventNotes,
      };

      if (newEvent.bIncludeWeekends != oldEvent.bIncludeWeekends) {
        dbEvent.bIncludeWeekends = newEvent.bIncludeWeekends = true;
      }

      if (newEvent.EventColour != oldEvent.EventColour) {
        dbEvent.EventColour = newEvent.EventColour;
      }

      let dbResources: any[] = [];
      if (newEvent.ResourceID != oldEvent.ResourceID) {
        dbResources.push({
          ResourceID: newEvent.ResourceID,
          bFieldAdded: 1,
          bFieldRemoved: 0,
        });
        dbResources.push({
          ResourceID: oldEvent.ResourceID,
          bFieldAdded: 0,
          bFieldRemoved: 1,
        });
      }

      if (newEvent.UserResponsibleID != oldEvent.UserResponsibleID) {
        dbEvent.UserResponsibleID = newEvent.UserResponsibleID;
      }

      newEvent.PeopleResourceIDs.forEach((resourceID) => {
        if (!oldEvent.PeopleResourceIDs.includes(resourceID)) {
          dbResources.push({
            ResourceID: resourceID,
            bFieldAdded: 1,
            bFieldRemoved: 0,
          });
        }
      });

      oldEvent.PeopleResourceIDs.forEach((resourceID) => {
        if (!newEvent.PeopleResourceIDs.includes(resourceID)) {
          dbResources.push({
            ResourceID: resourceID,
            bFieldAdded: 0,
            bFieldRemoved: 1,
          });
        }
      });

      newEvent.ResourceEquipmentIDs.forEach((resourceID) => {
        if (!oldEvent.ResourceEquipmentIDs.includes(resourceID)) {
          dbResources.push({
            ResourceID: resourceID,
            bFieldAdded: 1,
            bFieldRemoved: 0,
          });
        }
      });

      oldEvent.ResourceEquipmentIDs.forEach((resourceID) => {
        if (!newEvent.ResourceEquipmentIDs.includes(resourceID)) {
          dbResources.push({
            ResourceID: resourceID,
            bFieldAdded: 0,
            bFieldRemoved: 1,
          });
        }
      });

      dbEvent.Resources = dbResources;
    } catch (ex) {
      this.logger$.error(
        "SchedulerViewComponent: createUpdateDBEventObject: ",
        ex
      );
    }
    return dbEvent;
  }
}
