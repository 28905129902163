import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin, from, Observable, Subscription } from "rxjs";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { digsiteMessages } from "src/app/modules/digsite/digsite.module";
import { GoogleMapsDrawingComponent } from "src/app/modules/shared/google-map/google-maps-set/google-maps-set.component";
import { googleMapsOptions } from "src/app/modules/shared/google-map/google-maps-set/googleMapsOptions";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { CreateTicketComponentService } from "../../../create-ticket-component.service";
import { CreateTicketMessageActions } from "../../../CreateTicketInterface";
import { CreateTicketFormTypes } from "../../../CreateTicketModel";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-create-ticket-worksite-view",
  templateUrl: "./create-ticket-worksite-view.component.html",
  styleUrls: ["./create-ticket-worksite-view.component.scss"],
  providers: [ComponentMessagingService],
})
export class CreateTicketWorksiteViewComponent
  extends BaseViewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  className: string = "CreateTicketWorksiteViewComponent";
  @ViewChild(GoogleMapsDrawingComponent)
  cmpDrawShapesGoogleMaps: GoogleMapsDrawingComponent;
  @ViewChild("addressText") addresstext: any;
  @Output()
  updateAddressFieldInPanel: EventEmitter<any> = new EventEmitter();
  childrenDetector: Observable<any>;
  parentMsgSubscription: Subscription;
  childMsgSubscription: Subscription;

  childProps: any;
  formProps = {};
  options: googleMapsOptions;

  autocompleteInput: string;
  myViewName = "worksite";
  theme = "";

  finishedDrawingDisabled: boolean = true;
  viewOnly: boolean = false;
  isDrawing: boolean = false;
  isMapLoaded: boolean = false;
  isBadgeHidden: boolean = true;

  currentTool: number = -1;
  snack = null;

  markers: any[] = [];

  toolbarSelectionModel = new SelectionModel(
    false, // multiple selection or not
    [3], // initial selected values
    true,
  );
  buttons: {
    icon: string;
    tooltip: string;
    action: () => void;
  }[] = [
    {
      icon: "hand",
      tooltip: "Pan Tool",
      action: () => this.toolbarSelectionModel.select(1),
    },
    {
      icon: "legacy-polyline",
      tooltip: "Line Tool",
      action: () => this.toolbarSelectionModel.select(2),
    },
    {
      icon: "legacy-polygon",
      tooltip: "Polygon Tool",
      action: () => this.toolbarSelectionModel.select(3),
    },
    {
      icon: "circle",
      tooltip: "Radius Tool",
      action: () => this.toolbarSelectionModel.select(4),
    },
    {
      icon: "pin_black",
      tooltip: "Marker Tool - Pin and set closest address",
      action: () => this.toolbarSelectionModel.select(5),
    },
  ];

  sub = this.toolbarSelectionModel.changed.subscribe(console.log);

  prefillAddress: boolean = false;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private createTicket$: CreateTicketComponentService,
    // private theme$: ThemeService,
    private _snackBar: MatSnackBar,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private cdr: ChangeDetectorRef,
  ) {
    super(logger$);

    this.childMsgSubscription = this.compMsg$
      .getMessageStream()
      .subscribe((nextMsg) => {
        this.onReceiveChildMessage(nextMsg);
      });
  }
  ngOnInit(): void {
    try {
      this.logger$.log("CreateTicketWorksiteViewComponent init");
      // this.theme$.getTheme().subscribe((nextMsg) => this.theme = nextMsg.themeName);
      this.parentMsgSubscription = this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          this.onReceiveParentMessage(nextMsg);
        });

      this.options = {
        zoomControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControl: false,
        fullscreenControl: false,
        rotateControl: false,
        scrollwheel: true,
        tilt: 0,
      };
      this.childProps = {
        messageService: this.compMsg$,
      };
    } catch (error) {
      this.logger$.error(
        "CreateTicketWorksiteViewComponent init: " + error.message,
      );
    }
  }

  ngAfterViewInit() {
    try {
      this.getPlaceAutocomplete();
    } catch (error) {
      this.logger$.error(
        "CreateTicketWorksiteViewComponent ngAfterViewInit: " + error.message,
      );
    }
  }

  ngOnDestroy() {
    try {
      if (this.parentMsgSubscription) {
        this.parentMsgSubscription.unsubscribe();
      }
      if (this.childMsgSubscription) {
        this.childMsgSubscription.unsubscribe();
      }
    } catch (error) {
      this.logger$.error(error);
    }
    this.logger$.log("CreateTicketWorksiteViewComponent Destroyed");
  }

  onReceiveChildMessage(msg: ComponentMessage) {
    try {
      if (msg.action == MessageAction.READY) {
        if (msg.message == digsiteMessages.ON_MAP_READY) {
          this.isMapLoaded = true;
          this.setupCurrentDigsites();
          this.onStartDrawing();
          if (this.prefillAddress && this.addresstext) {
            this.searchAddress(this.addresstext.nativeElement.value);
          }

          // const customDivs = document.createElement("div");
          // let ccd = this.CenterControl(customDivs, this.cmpDrawShapesGoogleMaps.map);
          // this.cmpDrawShapesGoogleMaps.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(customDivs);
          // GoogleMap
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  onReceiveParentMessage(nextMsg: ComponentMessage) {
    try {
      switch (nextMsg.action) {
        case CreateTicketMessageActions.ADDRESS_UPDATED:
          console.log("ADDRESS UPDATE", this.isMapLoaded, this.addresstext);

          //setup prefill
          this.prefillAutocomplete(nextMsg.message);
          //if map loaded search that prefill
          if (this.isMapLoaded && this.addresstext) {
            this.searchAddress(this.addresstext.nativeElement.value);
          } else {
            this.prefillAddress = true;
          }
          break;
        case CreateTicketMessageActions.DATA_RESET:
          if (this.cmpDrawShapesGoogleMaps) {
            this.onResetDrawing();
          }
          break;
        default:
          break;
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  private setupCurrentDigsites() {
    try {
      this.createTicket$.getCurrentDigsite().then((val) => {
        if (val && val.length > 0) {
          this.initAutocomplete();
          this.drawExistingShapes(val);
          this.cmpDrawShapesGoogleMaps.zoomToPolygons();
        } else {
          this.initAutocomplete(true);
        }
      });
    } catch (error) {
      this.logger$.error("setupCurrentDigsites" + error.message);
    }
  }

  //setup autofill address serach bar
  private getPlaceAutocomplete() {
    try {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addresstext.nativeElement,
        {
          componentRestrictions: { country: ["CA", "US"] },
          types: [], // 'establishment' / 'address' / 'geocode'
        },
      );
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        const place = autocomplete.getPlace();
        this.onPlaceSelected(place);
      });
    } catch (error) {
      console.error(error);
    }
  }

  onPlaceSelected(place) {
    try {
      if (this.cmpDrawShapesGoogleMaps && place) {
        if (place && place["geometry"] && place["geometry"]["location"]) {
          this.markers = [];
          this.markers.push({
            position: {
              lat: place["geometry"]["location"].lat(),
              lng: place["geometry"]["location"].lng(),
            },
          });
          //this.cmpDrawShapesGoogleMaps.addMarker(this.markers[this.markers.length - 1].position);
          this.cmpDrawShapesGoogleMaps.centerByGoogleLocation(
            place["geometry"]["location"],
          );
        }
      }
    } catch (error) {
      this.logger$.error("onPlaceSelected: " + error.message);
    }
  }

  //get from idb if has address then autofill search
  //trigger when init and new data.
  initAutocomplete(search: boolean = false) {
    this.getAddressFields()
      .then((address) => {
        try {
          this.prefillAutocomplete(address);
          if (search) {
            //search using lat long of address.
            if (
              this.cmpDrawShapesGoogleMaps &&
              address["Latitude"] &&
              address["Longitude"]
            ) {
              let location = new google.maps.LatLng(
                address["Latitude"],
                address["Longitude"],
              );
              this.cmpDrawShapesGoogleMaps.centerByGoogleLocation(location);
            }
          }
        } catch (error) {
          this.logger$.error("initAutocomplete: " + error);
        }
      })
      .catch((error) => {
        this.logger$.error("initAutocomplete: " + error);
      });
  }

  /**
   *
   * @param addressFields {LocateAddress, LocateSubRegionName, StartHouseNumber}
   */
  prefillAutocomplete(addressFields) {
    try {
      if (this.addresstext && Object.values(addressFields).length > 0) {
        let LocateAddress = addressFields["LocateAddress"]
          ? addressFields["LocateAddress"]
          : "";
        let LocateSubRegionName = addressFields["LocateSubRegionName"]
          ? addressFields["LocateSubRegionName"]
          : "";
        let StartHouseNumber = addressFields["StartHouseNumber"]
          ? addressFields["StartHouseNumber"]
          : "";
        if (LocateAddress.length > 0) {
          this.addresstext.nativeElement.value =
            StartHouseNumber + " " + LocateAddress + ", " + LocateSubRegionName;
        } else {
          this.addresstext.nativeElement.value = LocateSubRegionName;
        }
      }
    } catch (error) {
      this.logger$.error("prefillAutocomplete: " + error);
    }
  }

  async getAddressFields() {
    let address = {};
    try {
      let ticketDetails = await this.createTicket$.getCurrentTicketDetails();
      if (
        ticketDetails &&
        ticketDetails["LocateAddress"] &&
        ticketDetails["LocateSubRegionName"] &&
        ticketDetails["StartHouseNumber"] &&
        ticketDetails["Latitude"] &&
        ticketDetails["Longitude"]
      ) {
        address = {
          LocateAddress: ticketDetails["LocateAddress"],
          LocateSubRegionName: ticketDetails["LocateSubRegionName"],
          StartHouseNumber: ticketDetails["StartHouseNumber"],
          Latitude: ticketDetails["Latitude"],
          Longitude: ticketDetails["Longitude"],
        };
      }
    } catch (error) {
      this.logger$.error("getAddressFields: " + error.message);
    }
    return address;
  }

  /**
   *
   * @param coordinates
   */
  locationPinned(coordinates) {
    try {
      // update idb with user map data
      // console.log(coordinates);
      this.progressBarService.start();
      forkJoin([
        this.createTicket$.updateTicketData(
          new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, {
            formType: CreateTicketFormTypes.ticket,
            value: coordinates,
          }),
        ),
      ]).subscribe((successfulSave: boolean[]) => {
        // notify user of result
        if (!successfulSave) {
          this.snackBarService.openSnackbar(
            "Failed to fill information",
            SnackbarType.error,
          );
        } else {
          this.snackBarService.openSnackbar(
            "Saved marked coordinates",
            SnackbarType.success,
          );
          // this.cdr.detectChanges();
        }
        this.progressBarService.stop();
      });
    } catch (error) {
      console.log(error);
      this.progressBarService.stop();
    }
  }

  // Sets address text to pin address
  setTextAddress(address) {
    if (address) {
      // this.addresstext.nativeElement.value = address;
      this.updateAddressFieldInPanel.emit(address);
    }
  }

  onStartDrawing() {
    try {
      this.cmpDrawShapesGoogleMaps.turnDrawingOn();
      this.finishedDrawingDisabled = false;
      // this.snackBarService.setToast(NotificationType.loading, "We recommend only drawing up to 5 polygons", "FOR BETA TESTING ONLY");
    } catch (error) {
      this.logger$.error(error);
    }
  }

  onSearchAddress(address) {
    try {
      this.searchAddress(address);
    } catch (error) {
      this.logger$.error(error);
    }
  }

  private searchAddress(address) {
    let requestBody = { address };
    this.cmpDrawShapesGoogleMaps
      .getLatLngFromAddress(requestBody)
      .then((result) => {
        this.markers = [];
        if (result) {
          this.markers.push({
            position: result,
          });
          //this.cmpDrawShapesGoogleMaps.addMarker(result);
        }
        this.progressBarService.stop();
      })
      .catch((error) => {
        this.logger$.warn("onSearchAddress: failed to find address: ", error);
        this.progressBarService.stop();
      });
  }

  getData(): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const Shapes = this.cmpDrawShapesGoogleMaps.getShapes();
        if (Shapes) {
          let digsiteDetails = this.createDigsiteDetails(Shapes);
          let centroid = this.parseCentroid(Shapes[0]);
          from(
            this.cmpDrawShapesGoogleMaps.getAddressFromLatLng(Shapes),
          ).subscribe((address: any) => {
            if (address) {
              var addressDetails = this.parseAddressFromGeocode(address);
              subscriber.next({ digsiteDetails, centroid, addressDetails });
            } else {
              subscriber.next({ digsiteDetails, centroid });
            }
            subscriber.complete();
          });
        } else {
          subscriber.next(false);
          subscriber.complete();
        }
      } catch (error) {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  onFinishedDrawing() {
    // this.cmpDrawShapesGoogleMaps.turnDrawingOff();
    // this.finishedDrawingDisabled = true;
    this.progressBarService.start();

    // get data from child view
    this.getData().subscribe((data) => {
      try {
        if (data) {
          const { centroid, digsiteDetails, addressDetails } = data;

          // update idb with user map data
          forkJoin([
            this.createTicket$.updateTicketData(
              new ActionMessage(Actions.UPDATE_TICKET_DATA, {
                formType: CreateTicketFormTypes.ticket,
                value: centroid,
              }),
            ),
            this.createTicket$.updateTicketData(
              new ActionMessage(Actions.UPDATE_DIGSITE_DETAILS, {
                formType: CreateTicketFormTypes.digsite,
                value: digsiteDetails,
              }),
            ),
            // this.createTicket$.updateTicketData(new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, { formType: CreateTicketFormTypes.ticket, value: addressDetails }))
          ]).subscribe((successfulSave: boolean[]) => {
            // notify user of result
            if (!successfulSave) {
              this.snackBarService.openSnackbar(
                "Failed to fill information",
                SnackbarType.error,
              );
            } else {
              this.snackBarService.openSnackbar(
                "Saved successfully",
                SnackbarType.success,
              );
            }
            this.progressBarService.stop();
          });
        } else {
          this.snackBarService.openSnackbar(
            "Failed to save drawing",
            SnackbarType.error,
          );
          this.progressBarService.stop();
        }
      } catch (error) {
        this.logger$.error(error.message);
        this.progressBarService.stop();
      }
    });
  }

  onResetDrawing() {
    try {
      if (this.isBadgeHidden == false) {
        this.isBadgeHidden = true;
      }

      this.progressBarService.start();
      this.cmpDrawShapesGoogleMaps.clearAllDrawings();
      // resetting data
      const digsiteDetails = [];
      const ticketDetails = {
        LocateAddress: "",
        LocateSubRegionName: "",
        StartHouseNumber: "",
        Latitude: null,
        Longitude: null,
      };

      // reset idb with default data
      forkJoin([
        this.createTicket$.updateTicketData(
          new ActionMessage(Actions.CLEAR_POLYGON_DATA, {
            formType: CreateTicketFormTypes.digsite,
            value: digsiteDetails,
          }),
        ),
        this.createTicket$.updateTicketData(
          new ActionMessage(Actions.CLEAR_POLYGON_DATA, {
            formType: CreateTicketFormTypes.ticket,
            value: ticketDetails,
          }),
        ),
      ]).subscribe((successfulSave: boolean[]) => {
        if (successfulSave && successfulSave[0] && successfulSave[1]) {
          // this.snackBarService.setToast(NotificationType.success, "Successfully reset digsite");
        } else {
          console.error("Failed to reset digsite - try again");
          // this.snackBarService.setToast(NotificationType.error, "Failed to reset digsite - try again");
        }
        this.progressBarService.stop();
      });
    } catch (error) {
      this.logger$.error("onresetdrawing: " + error.message);
      this.progressBarService.stop();
    }
  }

  private createDigsiteDetails = (Shapes: any): Object => {
    // create dig site details obj
    let digsiteDetails = Shapes.reduce((digsites, gmShape) => {
      let coords = gmShape.points;
      let formattedCoords = coords.reduce((coords, key) => {
        let coord = {
          Latitude: key.lat(),
          Longitude: key.lng(),
        };
        coords.push(coord);
        return coords;
      }, []);

      let digsite = {
        DigsiteTypeID: gmShape.type,
        Coordinates: formattedCoords,
        StatusID: gmShape.statusID,
      };
      if (gmShape.digsiteShapeID != null) {
        digsite["DigsiteShapeID"] = gmShape.digsiteShapeID;
      }

      digsites.push(digsite);
      return digsites;
    }, []);

    return digsiteDetails;
  };

  private parseCentroid = (shape: any): Object => {
    let centroid = {
      Latitude: shape.center.lat,
      Longitude: shape.center.lng,
    };
    return centroid;
  };

  private parseAddressFromGeocode = (address: any): Object => {
    let streetAddress = "";
    let city = "";
    let startHouseNumber = "";

    // parse address data
    let addressInfoKeys = ["street_number", "route", "locality"];
    let addressInfoValues = address.reduce((finalObj, curObj) => {
      if (curObj.types.indexOf("locality") > -1) {
        city = curObj.long_name;
      } else if (
        curObj.types.indexOf("route") > -1 ||
        curObj.types.indexOf("intersection") > -1
      ) {
        streetAddress = curObj.long_name;
      } else if (curObj.types.indexOf("street_number") > -1) {
        startHouseNumber = curObj.long_name;
      } else if (curObj.types.indexOf("political") > -1 && city.length == 0) {
        city = curObj.long_name;
      }
      return finalObj;
    }, []);

    // update input fields
    const newDetails = {
      LocateSubRegionName: city,
      LocateAddress: streetAddress,
      StartHouseNumber: startHouseNumber,
    };
    return newDetails;
  };

  private drawExistingShapes(digsiteData) {
    try {
      if (digsiteData && digsiteData.length > 0) {
        for (let i = 0; i < digsiteData.length; i++) {
          try {
            if (digsiteData[i].StatusID != 3) {
              let coords = digsiteData[i].Coordinates;
              let formattedCoords = [];
              coords.forEach((coord) => {
                formattedCoords.push({
                  lat: coord.Latitude,
                  lng: coord.Longitude,
                });
              });
              this.cmpDrawShapesGoogleMaps.addLocalShape(
                formattedCoords,
                digsiteData[i].DigsiteTypeID,
              );
            }
          } catch (error) {
            this.logger$.error("failedto loadDigsites: ", error.message);
          }
        }
      }
    } catch (error) {
      this.logger$.error("drawExistingShapes: " + error.message);
    }
  }

  // Opens snackbar when drawing changes occur so user can save
  openSnackBar() {
    this.snack = this._snackBar.open("You have unsaved changes.", "Save", {
      horizontalPosition: "right",
      verticalPosition: "bottom",
      panelClass: ["white-snackbar"],
    });
    this.snack.onAction().subscribe(() => {
      this.onFinishedDrawing();
    });
  }

  onSaveDrawing() {
    this.onFinishedDrawing();
    this.isBadgeHidden = true;
  }

  getBadgeVisibility() {
    return this.isBadgeHidden;
  }

  setBadgeVisibility() {
    this.isBadgeHidden = false;
  }
}
