import { Subject, Observable } from "rxjs";
import { ComponentMessage } from "./component-message";
import { Injectable } from "@angular/core";

@Injectable()
export class ComponentMessagingService {
  componentSubjects = {};

  wantedSubjectLinks = {}; // Dictionary of String Arrays
  subjectLinks = {}; // Dictionary of String Arrays

  private messageStream: Subject<ComponentMessage>;

  constructor() {
    this.messageStream = new Subject<ComponentMessage>();
  }

  getMessageStream(): Observable<ComponentMessage> {
    return this.messageStream.asObservable();
  }

  sendToMessageStream(msg: ComponentMessage) {
    this.messageStream.next(msg);
  }
}
