<div *ngIf="!dataReady">
  <h4 class="pl-4 w-full">Loading...</h4>
</div>
<div
  *ngIf="dataReady"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  class="view-container view-container-background"
  style="padding-top: 4px"
>
  <!-- <app-form-input-template *ngIf="traditionalCustomer" fxFlex fxFlexOrder="1" [props]="formProps" class="content-overflow" style="height: 100%;width: 100%;"></app-form-input-template>  -->
  <div fxLayout="column" fxLayoutAlign="start stretch" class="form-main">
    <!-- <div fxFlex="72px" fxLayout="row" fxLayoutAlign="start center" class="border-bottom">
      <span style="padding:8px;" fxFlex="100" class="company-title">Customer Details</span>
    </div> -->
    <form
      class="mx-2 border-solid border-[1px] border-[#dadce0] border-x-0 border-t-0"
    >
      <div
        class="w-full text-lg flex flex-row place-content-between items-baseline pt-1"
      >
        <span class="m-0 mx-2 mt-2">Search Customer</span>
        <!-- <button mat-raised-button color="primary" class="mx-2 mt-2-button" (click)="getExcavatorContacts()">PRINT</button> -->
        <button
          mat-raised-button
          color="primary"
          class="mx-2 mt-2 font-rajdhani uppercase font-semibold"
          (click)="createCompanyClick($event, slideOverPanel)"
        >
          Create Customer
        </button>
      </div>
      <div
        class="m-0 flex flex-row flex-wrap place-items-star items-center"
      >
        <mat-form-field
          fxFlex="100"
          fxFlexOrder="1"
          class="form-input"
        >
          <mat-label>Customer</mat-label>
          <input
            type="text"
            tabindex="1"
            placeholder="Enter Customer Name"
            aria-label="companyName"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
            class="font-normal"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onAutoCompanyClick($event.option.value)"
          >
            <cdk-virtual-scroll-viewport
              itemSize="40"
              [style.height.px]="4 * 40"
            >
              <mat-option
                *cdkVirtualFor="let option of filteredOptions | async"
                [value]="option"
              >
                {{ option.text }}
              </mat-option>
            </cdk-virtual-scroll-viewport>
            <!-- <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.text}}
            </mat-option> -->
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <app-excavator-contacts-card
        #excavatorContacts
        [props]="excavatorContactsProp"
        (excavatorSaved)="handleExcavatorSaved($event)"
        (excavatorDetailsChanged)="handleExcavatorDetailsChanged($event)"
        (excavatorContactsChanged)="handleExcavatorContactsChanged($event)"
        [multipleExcavatorContacts]="multipleExcavatorContacts"
        [isCreateTicket]="true"
      ></app-excavator-contacts-card>
    </form>
    <div
      style="
        height: 48px;
        max-height: 48px;
        min-height: 48px;
        background: white;
      "
    ></div>
  </div>

  <!-- <div fxFlex="56px" fxFlexOrder="2" fxLayout="row" fxLayoutAlign.lt-md="space-between center" fxLayoutAlign="end center" class="view-container" fxLayoutGap="16px" style="padding:0 16px 0 16px;background-color: rgba(218, 220, 224, 0.25);">
    <button mat-raised-button (click)="onClearCompany()" >Clear</button>
    <button mat-raised-button color="accent" (click)="onSaveCompany()" >Save Company</button>
  </div> -->
</div>

<app-slideover-side-panel
  #slideOverPanel
  [props]="slideoverProps"
></app-slideover-side-panel>
