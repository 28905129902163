<h1 mat-dialog-title style="padding-bottom: 24px;
font-size: 20px;" class="mat-headline-1 blue">Sync Progress</h1>

<div
  mat-dialog-content
  fxLayout="column"
  fxLayoutAlign="start stretch"
  style="margin: 0; height: 420px"
>
  <h4 class="blue">Upload Progress</h4>
  <mat-list-item style="height: 60px;"
    *ngFor="let upload of uploadStatuses | keyvalue; trackBy: trackByFn"
  >
    <span mat-line>{{ upload.value.message }}</span>
    <div *ngIf="upload.value.progress == 1" class="header-button">
      <mat-icon mat-list-icon>access_time</mat-icon>
    </div>
    <div *ngIf="upload.value.progress == 2" class="header-button">
      <mat-icon mat-list-icon color="warn">close</mat-icon>
    </div>
    <div *ngIf="upload.value.progress == 3" class="header-button">
      <mat-icon mat-list-icon color="primary">check</mat-icon>
    </div>
  </mat-list-item>

  <h4 class="blue">Download Progress</h4>
  <mat-list-item style="height: 60px;"
    *ngFor="let download of downloadStatuses | keyvalue; trackBy: trackByFn"
  >
    <span mat-line>{{ download.value.message }}</span>
    <div *ngIf="download.value.progress == 1" class="header-button">
      <mat-icon mat-list-icon>access_time</mat-icon>
    </div>
    <div *ngIf="download.value.progress == 2" class="header-button">
      <mat-icon mat-list-icon color="warn">close</mat-icon>
    </div>
    <div *ngIf="download.value.progress == 3" class="header-button">
      <mat-icon mat-list-icon color="primary">check</mat-icon>
    </div>
  </mat-list-item>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="primary">Close</button>
</div>
