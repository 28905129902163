import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";

import { ISO_LOCALE_LIST, U4_LOCALE } from "src/assets/locale-files/languages";

export interface LocaleInfo {
  locale: {};
  localeName: ISO_LOCALE_LIST;
}

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  currentU4Locale = U4_LOCALE["en-CA"];
  currentU4LocaleName = ISO_LOCALE_LIST["en-CA"];
  private localeSubscription: BehaviorSubject<LocaleInfo>;

  constructor() {
    this.localeSubscription = new BehaviorSubject({
      locale: U4_LOCALE["en-CA"],
      localeName: ISO_LOCALE_LIST["en-CA"],
    });
  }

  //set and send message
  setLocale(isoName: ISO_LOCALE_LIST) {
    try {
      if (isoName && U4_LOCALE[isoName]) {
        this.currentU4Locale = U4_LOCALE[isoName];
        this.currentU4LocaleName = isoName;
      }
      //when success change send message
      this.sendLocale();
    } catch (error) {
      console.error("Failed to set locale: " + error.message);
    }
  }

  //returns current, english by default/fail
  getLocale() {
    //default english
    let currentLocale: LocaleInfo = {
      locale: U4_LOCALE["en-CA"],
      localeName: ISO_LOCALE_LIST["en-CA"],
    };

    try {
      currentLocale = {
        locale: this.currentU4Locale,
        localeName: this.currentU4LocaleName,
      };
    } catch (error) {
      console.error(
        "Failed to set locale - Defaulting to English: " + error.message
      );
      //failed so default to english
    }
    return currentLocale;
  }

  //what to subcribe to
  getLocaleSubscription(): Observable<LocaleInfo> {
    return this.localeSubscription.asObservable();
  }

  //Made private because only should be called
  //After set. Use get otherwise
  private sendLocale() {
    this.localeSubscription.next(this.getLocale());
  }
}
