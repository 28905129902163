import { CallTypeID } from '../../../../../../modules/shared/ticket-details/ticket-details.module';

const CallTypeColours = {
  [CallTypeID.EMERGENCY]: '#ffff00',
  [CallTypeID.PRIORITY0]: '#ffa500',
  [CallTypeID.PRIORITY1]: '#ffa500',
  [CallTypeID.PRIORITY2]: '#ffa500',
  [CallTypeID.PRIORITY3]: '#ffa500',
  [CallTypeID.PRIORITY4]: '#ffa500',
  [CallTypeID.STANDARD]: '#858585',
  [CallTypeID.PROJECT_WORK]: '#d3d3d3',
  [CallTypeID.PRIVATE]: '#323232',
  [CallTypeID.PLANNING]: '#ee82ee',
  [CallTypeID.SEWER_LATERAL]: '#00bfff',

};

export default CallTypeColours;
