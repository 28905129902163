import {Component, OnInit, Input, EventEmitter, Output} from "@angular/core";
import {BasePanelComponent} from "src/app/modules/core/base-set/base-set.component";
import {ComponentMessagingService} from "src/app/modules/core/component-messaging/component-messaging.service";
import {LoggerService} from "src/app/modules/core/services/logger/logger.service";
import {Resource} from "../../syncfusion-scheduler/schedulerTypes";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ComponentMessage, MessageAction} from "src/app/modules/core/component-messaging/component-message";
import {Predicate, Query} from "@syncfusion/ej2-data";




/**
 * Shows the panel which lets users toggle resources and reorder them too 
 *
 * @export
 * @class SchedulerShowHideRigsPanelComponent
 * @extends {BasePanelComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-scheduler-show-hide-resources-panel',
  templateUrl: './scheduler-show-hide-resources-panel.component.html',
  styleUrl: './scheduler-show-hide-resources-panel.component.css',
  providers: [ComponentMessagingService],
})
export class SchedulerShowHideRigsPanelComponent extends BasePanelComponent
  implements OnInit {
  className = "SchedulerShowHideRigsPanelComponent";
  @Input() props: any;
  @Input() rigResources: Resource[];
  @Input() activeResources: Resource[]

  @Output() activeResourcesChange: EventEmitter<Resource[]> = new EventEmitter<Resource[]>();

  activeRigsFormGroup: FormGroup;

  constructor(
    logger$: LoggerService,
    private messaging$: ComponentMessagingService,
    private _formBuilder: FormBuilder
  ) {
    super(logger$);
  }

  /**
   * @memberof SchedulerShowHideRigsPanelComponent
   */
  ngOnInit(): void {
    // listen to parent
    this.props.messageService.getMessageStream().subscribe(() => { });
    const forms = this.getFormGroupObject();
    this.activeRigsFormGroup = this._formBuilder.group(forms);
  }

  /**
   *Gets the form group object
   *
   * @return {*} 
   * @memberof SchedulerShowHideRigsPanelComponent
   */
  getFormGroupObject(): any[] {
    const formGroupObject = [];
    for (const resource of this.rigResources) {
      formGroupObject[resource.ResourceID] = this.isResourceActive(resource);
    }
    return formGroupObject;
  }

  foo() {
    this.toggleResource(this.activeResources[0], true);
  }


  /**
   * Toggles a resource on off
   * @param {Resource} resourceToToggle the resource to toggle
   * @param {boolean} newActiveValue whether to toggle on or off
   * @memberof SchedulerShowHideRigsPanelComponent
   */
  toggleResource(resourceToToggle: Resource, newActiveValue: any): void {
    if (!newActiveValue.checked) {
      // turn it off
      this.activeResources = this.activeResources.filter(r => r.ResourceID != resourceToToggle.ResourceID);
    } else {
      //turn it on  
      this.activeResources.push(resourceToToggle);
    }

    let resourcePredicate = null;
    for (const resource of this.activeResources) {
      const predicate = new Predicate("ResourceID", "equal", resource['ResourceID']);
      resourcePredicate = resourcePredicate ? resourcePredicate.or(predicate) : predicate;
    }

    const resourceQuery = resourcePredicate ? new Query().where(resourcePredicate) : new Query();
    const messageToSend: ComponentMessage = {
      action: MessageAction.UPDATE,
      message: {
        queryEvents: resourceQuery,
      },
      senderID: this.className,
    };

    this.props.messageService.sendToMessageStream(messageToSend);
    this.activeResourcesChange.emit(this.activeResources);
  }

  /**
   * Whether a resource is active (i.e., it is in the activeResource list)
   * @param resource 
   * @returns {boolean}
   * @memberof SchedulerShowHideRigsPanelComponent
   */
  isResourceActive(resource: Resource): boolean {
    return this.activeResources.find(r => r.ResourceID == resource.ResourceID) != null;
  }

  // // Use the below function if you want to use drag and drop
  // /**
  //  * Drag and drop function
  //  *
  //  * @param {CdkDragDrop<string[]>} event
  //  * @memberof SchedulerShowHideRigsPanelComponent
  //  */
  // drop(event: CdkDragDrop<string[]>): void {
  //   moveItemInArray(this.activeResources, event.previousIndex, event.currentIndex);
  //   this.activeResourceEventChange.emit(this.activeResources);
  // }
}
