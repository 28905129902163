import {
  Component,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  inject,
  Renderer2,
  OnDestroy,
  AfterViewInit,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-drag-handle',
  standalone: true,
  imports: [MatIconModule],
  template: `
    <div
      #dragHandle
      class="flex justify-center items-center relative w-8 h-8 top-10 rounded-full bg-gray-200 z-20 shadow-black shadow cursor-ew-resize"
      style="transform: translate(-1rem)">
      <div class="flex flex-row justify-center items-center">
        <mat-icon style="font-size:16px; width:16px; height:16px">keyboard_arrow_left</mat-icon>
        <mat-icon style="font-size:16px; width:16px; height:16px">keyboard_arrow_right</mat-icon>
      </div>
    </div>
  `,
})
export class DragHandleComponent implements AfterViewInit, OnDestroy {
  // IO
  @ViewChild('dragHandle', { static: true }) dragHandle: ElementRef<HTMLDivElement>;
  @Output() relativePositionChange = new EventEmitter<number>();
  @Output() resize = new EventEmitter<void>();
  @Input() relativePosition = 0;

  // services
  private renderer: Renderer2 = inject(Renderer2);

  // members
  protected _draggingStart = 0;
  protected _dragging = false;
  protected mouseDownListener: () => void;
  protected startTouchListener: () => void;

  constructor() {}

  ngAfterViewInit() {
    this.mouseDownListener = this.renderer.listen(this.dragHandle.nativeElement, 'mousedown', this.mouseDown);
    this.startTouchListener = this.renderer.listen(this.dragHandle.nativeElement, 'touchstart', this.touchStart);
    this.resize.emit();
  }

  ngOnDestroy() {
    this.resize.emit();
    this.mouseDownListener();
  }

  mouseDown = (event: MouseEvent) => {
    this._dragging = true;
    this._draggingStart = event.screenX;
    const mouseMove = this.renderer.listen('document', 'mousemove', (event: MouseEvent) => {
      event.preventDefault();
      const delta = event.screenX - this._draggingStart;
      this.relativePosition += delta;
      this._draggingStart = event.screenX;
      this.relativePositionChange.emit(this.relativePosition);
    });
    const mouseUp = this.renderer.listen('document', 'mouseup', () => {
      this._dragging = false;
      mouseMove();
      mouseUp();
      this.resize.emit();
    });
  };

  touchStart = (event: TouchEvent) => {
    this._dragging = true;
    this._draggingStart = event.touches[0].screenX;
    const touchMove = this.renderer.listen('document', 'touchmove', (event: TouchEvent) => {
      const delta = event.touches[0].screenX - this._draggingStart;
      this.relativePosition += delta;
      this._draggingStart = event.touches[0].screenX;
      this.relativePositionChange.emit(this.relativePosition);
    });
    const touchEnd = this.renderer.listen('document', 'touchend', () => {
      this._dragging = false;
      touchMove();
      touchEnd();
      this.resize.emit();
    });
  };
}
