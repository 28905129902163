export class InputFactory {
  createInputs(
    types: string[],
    names: string[],
    placeholders: string[],
    required: boolean[]
  ) {
    let returnInputs = [];
    if (
      types &&
      names &&
      placeholders &&
      types.length == names.length &&
      names.length == placeholders.length
    ) {
      for (let i = 0; i < types.length; i++) {
        let newInput = this.createInput(
          types[i],
          names[i],
          required[i],
          placeholders[i]
        );

        if (newInput) {
          returnInputs.push(newInput);
        }
      }
    }
    return returnInputs;
  }

  createInput(
    type: string,
    dbName: string,
    isRequired: boolean,
    placeholder: string
  ) {
    let returnInput = null;
    if (type && dbName && isRequired != null && placeholder) {
      returnInput = {
        type: type,
        dbName: dbName,
        isRequired: isRequired,
        placeholder: placeholder,
      };
    }
    return returnInput;
  }

  createDropdowns(
    names: string[],
    placeholders: string[],
    options: object[][]
  ) {
    let returnDropdowns = [];
    if (
      names &&
      placeholders &&
      options &&
      names.length == placeholders.length &&
      placeholders.length == options.length
    ) {
      for (let i = 0; i < names.length; i++) {
        let newDropdown = this.createDropdown(
          names[i],
          placeholders[i],
          true,
          options[i]
        );

        if (newDropdown) {
          returnDropdowns.push(newDropdown);
        }
      }
    }
    return returnDropdowns;
  }

  createDropdown(
    dbName: string,
    placeholder: string,
    isRequired: boolean,
    options: object[]
  ) {
    let returnDropdown = null;
    if (dbName && placeholder && isRequired != null && options) {
      returnDropdown = {
        type: "dropdown",
        dbName: dbName,
        isRequired: isRequired,
        placeholder: placeholder,
        options: options,
      };
    }
    return returnDropdown;
  }
}

export const InputTypes = {
  string: "input",
  date: "date",
};
