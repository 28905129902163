// @ts-nocheck
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { formatDate } from "@angular/common";
import { CreateTicketQuickViewComponent } from "../../views/create-ticket-quick-view/create-ticket-quick-view.component";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import {
  CreateTicketComponentService,
  TicketDetailInputType,
} from "../../../create-ticket-component.service";
import { CompletionsStoreService } from "src/app/modules/core/admin/completions-store.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { CreateTicketMessageActions } from "../../../CreateTicketInterface";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { CreateTicketFormTypes } from "../../../CreateTicketModel";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { MatSnackBarConfig } from "@angular/material/snack-bar";

@Component({
  selector: "app-create-ticket-quick-panel",
  templateUrl: "./create-ticket-quick-panel.component.html",
  styleUrls: ["./create-ticket-quick-panel.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketQuickPanelComponent
  extends BasePanelComponent
  implements OnInit, OnDestroy
{
  @Output() createTicketReady: EventEmitter<any> = new EventEmitter<any>();
  parentMsgSubscriber: Subscription;
  childMsgSubscriber: Subscription;
  className: string = "CreateTicketQuickPanelComponent";
  childProps = {};
  scheduleSettingID = 213;
  views = {};
  documents = {};
  utilityViews = {};
  commonViews = [];
  multipleExcavatorContacts = false;
  traditionalCustomer = false;
  optionalViews = ["companyContactInfo", "companyInfo"];
  multiContactViews = ["companyContactInfo", "companyInfo"];

  @ViewChild("quickView") quickView: CreateTicketQuickViewComponent;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private createTicket$: CreateTicketComponentService,
    private completions$: CompletionsStoreService,
    private snackBarService: SnackbarService,
    private datetime: DatetimeService,
    private userService: UserService
  ) {
    super(logger$);
    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      documents: this.documents,
    };

    this.childMsgSubscriber = this.compMsg$
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        this.onReceiveChildMessage(nextMsg);
      });
  }

  /**
   * Get initial setup from the page.
   */

  ngOnInit(): void {
    try {
      this.parentMsgSubscriber = this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          this.onReceiveParentMessage(nextMsg);
        });
      this.views = this.props["views"];
    } catch (error) {
      this.logger$.error(this.className + " ngOnInit1 " + error.message);
    }
  }

  /**
   * If receives views then it is ready to load
   */
  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"]
      ) {
        this.views = changes["props"]["currentValue"]["views"];
        this.commonViews = changes["props"]["currentValue"]["commonViews"];
        this.documents = changes["props"]["currentValue"]["documents"];
        this.utilityViews = changes["props"]["currentValue"]["utilityViews"];
        this.childProps["views"] = this.views;
        this.childProps["documents"] = this.documents;
        this.childProps["utilityViews"] = this.utilityViews;
        this.childProps["onCreateBtnDisabled"] =
          changes["props"]["currentValue"]["onCreateBtnDisabled"];
        this.childProps = { ...this.childProps };
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.childMsgSubscriber) {
        this.childMsgSubscriber.unsubscribe();
      }
      if (this.parentMsgSubscriber) {
        this.parentMsgSubscriber.unsubscribe();
      }
    } catch (error) {
      this.logger$.error("qpOnDest: " + error.message);
    }
  }

  private onReceiveChildMessage(nextMsg: ComponentMessage) {
    if (nextMsg.action) {
      if (nextMsg.action == CreateTicketMessageActions.SWITCH_CONTENT_VIEW) {
        if (this.props["messageService"]) {
          this.props["messageService"].sendToMessageStream(nextMsg);
        }
      } else if (
        nextMsg.action == CreateTicketMessageActions.EXCAVATOR_NAME_UPDATED
      ) {
        if (this.props["messageService"]) {
          this.props["messageService"].sendToMessageStream(nextMsg);
        }
      }
    }
  }

  private onReceiveParentMessage(nextMsg: ComponentMessage) {
    try {
      switch (nextMsg.action) {
        case CreateTicketMessageActions.DATA_RESET:
          if (this.compMsg$) {
            this.compMsg$.sendToMessageStream(nextMsg);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      this.logger$.error("onReceiveParentMessage: " + error.message);
    }
  }

  onCreateTicket() {
    this.isCreateTicketSessionValid()
      .then((result) => {
        if (result) {
          try {
            let isValid: boolean = true;
            if (
              this.createTicket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR]
            ) {
              if (
                this.createTicket$.settings[
                  SettingID.MULTI_CONTACT_EXCAVATOR
                ] == true
              ) {
                this.multipleExcavatorContacts = true;
                this.traditionalCustomer = false;
              }
            } else if (
              this.createTicket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR] ==
              false
            ) {
              this.multipleExcavatorContacts = false;
              this.traditionalCustomer = true;
            }

            if (this.views) {
              for (var viewKey in this.views) {
                var view = this.views[viewKey];
                var viewFormGroup: FormGroup = view["formGroup"];
                if (this.traditionalCustomer) {
                  //any views specific to multiContactViews ignored
                  if (
                    viewFormGroup.valid ||
                    this.multiContactViews.indexOf(viewKey) > -1
                  ) {
                    this.logger$.log(viewKey + " : VALID");
                  } else {
                    this.logger$.log(viewKey + " : INVALID");
                    isValid = false;
                    viewFormGroup.markAllAsTouched();
                  }
                }
                if (this.multipleExcavatorContacts) {
                  if (
                    viewFormGroup.valid ||
                    this.optionalViews.indexOf(viewKey) > -1
                  ) {
                    this.logger$.log(viewKey + " : VALID");
                  } else {
                    this.logger$.log(viewKey + " : INVALID");
                    isValid = false;
                    viewFormGroup.markAllAsTouched();
                  }
                }
              }
              //check has common and if valid, if no common continue
              if (
                this.commonViews &&
                this.commonViews[0] &&
                this.commonViews[0].formGroup
              ) {
                if (!this.commonViews[0].formGroup.valid) {
                  isValid = false;
                }
              }

              if (isValid) {
                //coontinue with creation
                this.handleValidViews();
              } else {
                this.snackBarService.openSnackbar(
                  "Validation Failed: May be missing required fields",
                  SnackbarType.error
                );
              }
            }
          } catch (error) {
            this.logger$.error(error);
          }
        }
      })
      .catch((error) => {
        this.logger$.error(error.message);
      });
  }

  async isCreateTicketSessionValid(): Promise<boolean> {
    let isSessionValid = true;
    try {
      //get current session
      let currentSession = this.createTicket$.getCurrentCreateTicketID();
      let row = await this.completions$.hasCreateTicketRowByKey(currentSession);

      if (!row) {
        this.createTicket$.removeCurrentCreateTicketID();
        let snackbarConfig: MatSnackBarConfig = {
          duration: undefined,
          horizontalPosition: "center",
          verticalPosition: "top",
        };
        this.snackBarService.openSnackbar(
          "Session Expired - Page Refresh Required",
          SnackbarType.error
        );
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return isSessionValid;
  }

  quickPanelUpdate(location) {
    try {
      let address = location["location"];
      let coordinates = location["coordinates"];
      var formattedAddress = {
        LocateAddress: address[1].long_name,
        LocateSubRegionName: address[3].long_name,
        StartHouseNumber: address[0].long_name,
      };
      if (coordinates) {
        formattedAddress["Latitude"] = coordinates["Latitude"];
        formattedAddress["Longitude"] = coordinates["Longitude"];
      }
      this.createTicket$
        .updateTicketData(
          new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, {
            formType: CreateTicketFormTypes.ticket,
            value: formattedAddress,
          })
        )
        .toPromise();
    } catch (error) {
      this.logger$.error(error);
    }
  }

  //function to handle each page.
  //once done emit a message to the main component. Up 1 level.
  //main component will reset everything.
  handleValidViews() {
    try {
      this.handleTicketSummary()
        .then((ticketSummaryResult) => {
          if (ticketSummaryResult) {
            return this.handleCompanyInfo();
          } else {
            throw new Error("ticket summary failed");
          }
        })
        .then((companyInfoResult) => {
          if (companyInfoResult) {
            return this.handleDetails();
          } else {
            this.logger$.log("Customer Info Invalid");
            this.snackBarService.openSnackbar(
              "Missing or invalid customer information",
              SnackbarType.error
            );
            throw new Error("Missing or invalid customer information");
          }
        })
        .then((detailsResult) => {
          if (detailsResult) {
            return this.handleScheduleDetails();
          } else {
            throw new Error("Details failed");
          }
        })
        .then((scheduleResults) => {
          if (scheduleResults) {
            this.createTicketReady.emit();
          } else {
            throw new Error("ScheduleDetails failed");
          }
        })
        .catch((error) => {
          this.logger$.error("handleValidViewPC :" + error);
        });
    } catch (error) {
      this.logger$.error("handleValidView :" + error);
      this.snackBarService.openSnackbar(error.message, SnackbarType.error);
    }
  }

  private async handleTicketSummary() {
    let result = false;
    try {
      if (this.views["ticketSummary"]) {
        let ticketSummary = this.views["ticketSummary"];
        let formattedAddress = {};
        if (
          ticketSummary.groups &&
          ticketSummary.groups["worksite"] &&
          ticketSummary.groups["worksite"].fields
        ) {
          let fields = ticketSummary.groups["worksite"].fields;

          //create address object
          for (let fieldKey in fields) {
            formattedAddress[fieldKey] =
              ticketSummary["formGroup"].value[fieldKey];
          }

          let ticketDetails =
            await this.createTicket$.getCurrentTicketDetails();
          formattedAddress["Latitude"] = ticketDetails["Latitude"]
            ? ticketDetails["Latitude"]
            : "";
          formattedAddress["Longitude"] = ticketDetails["Longitude"]
            ? ticketDetails["Longitude"]
            : "";

          //With scheduler can create without lat/lng
          if (!this.userService.isSettingActive(SettingID.SCHEDULER)) {
            if (
              ticketDetails &&
              (!ticketDetails["Latitude"] || !ticketDetails["Longitude"])
            ) {
              this.snackBarService.openSnackbar(
                "No Lat/Lng coordinates set. Pin location in worksite tab or search address",
                SnackbarType.warning,
                "Close"
              );
              return false;
            }
          }
        }
        result = await this.createTicket$
          .updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, {
              formType: CreateTicketFormTypes.ticket,
              value: formattedAddress,
            })
          )
          .toPromise();
      }
    } catch (error) {
      this.logger$.error("handleTicketSummary :" + error);
    }
    return result;
  }

  /**
   *   Required
      "ExcavatorName",
      "ExcavatorAddress",
      "ExcavatorCity",
      "ExcavatorPostalCode",
      "Province",
      "PhoneNumber",
      "Email",
      "ContactName",
      "Code",
      "FaxNumebr",
   */
  private async handleCompanyInfo() {
    let result: any = false;
    try {
      let excavatorDetails =
        await this.createTicket$.getCurrentExcavatorDetails();
      if (this.multipleExcavatorContacts) {
        if (
          excavatorDetails &&
          excavatorDetails["contacts"] &&
          excavatorDetails["contacts"].length > 0
        ) {
          result = true;
        }
      } else {
        result = true;
      }
    } catch (error) {
      console.error(error.message);
    }

    return result;
  }

  /**
   * Required
      "ExcavationDate",
      "OriginalExcavationDate",

      "RegionID", //
      "SubRegionID", //
      "LocateSubRegionName",
      "LocateAddress",

      "TypeOfWork",
      "WorkDoneFor",
      "ExcavatorID",
      "CallerName",
      "PhoneNumber",
      "email"
   */
  private async handleDetails() {
    let result: any = false;
    try {
      let promiseArr = [];

      if (this.views["details"]) {
        let detailView = this.views["details"];

        let excavatorName = "";
        let excavatorInfo = {
          CallerName: "",
          PhoneNumber: "",
          email: "",
          FaxNumber: "",
        };

        let excavatorDetails =
          await this.createTicket$.getCurrentExcavatorDetails();
        if (excavatorDetails && excavatorDetails["contacts"]) {
          if (excavatorDetails["contacts"][0]) {
            excavatorInfo["CallerName"] = excavatorDetails["contacts"][0][
              "ContactName"
            ]
              ? excavatorDetails["contacts"][0]["ContactName"]
              : "";
            excavatorInfo["PhoneNumber"] = excavatorDetails["contacts"][0][
              "PhoneNumber"
            ]
              ? excavatorDetails["contacts"][0]["PhoneNumber"]
              : "";
            excavatorInfo["email"] = excavatorDetails["contacts"][0]["Email"]
              ? excavatorDetails["contacts"][0]["Email"]
              : "";
            excavatorInfo["FaxNumber"] = excavatorDetails["contacts"][0][
              "FaxNumber"
            ]
              ? excavatorDetails["contacts"][0]["FaxNumber"]
              : "";
          } else {
            excavatorInfo["CallerName"] = excavatorDetails["ContactName"]
              ? excavatorDetails["ContactName"]
              : "";
            excavatorInfo["PhoneNumber"] = excavatorDetails["PhoneNumber"]
              ? excavatorDetails["PhoneNumber"]
              : "";
            excavatorInfo["email"] = excavatorDetails["Email"]
              ? excavatorDetails["Email"]
              : "";
            excavatorInfo["FaxNumber"] = excavatorDetails["FaxNumber"]
              ? excavatorDetails["FaxNumber"]
              : "";
          }
          excavatorInfo["ExcavatorID"] = excavatorDetails["ExcavatorID"];
        } else {
          excavatorName =
            this.views["companyInfo"]["formGroup"].value["ExcavatorName"];
          if (typeof excavatorName === "object") {
            excavatorInfo["ExcavatorID"] = excavatorName["value"];
          }
          excavatorInfo["CallerName"] = excavatorDetails["ContactName"]
            ? excavatorDetails["ContactName"]
            : "";
          excavatorInfo["PhoneNumber"] = excavatorDetails["PhoneNumber"]
            ? excavatorDetails["PhoneNumber"]
            : "";
          excavatorInfo["email"] = excavatorDetails["Email"]
            ? excavatorDetails["Email"]
            : "";
          excavatorInfo["FaxNumber"] = excavatorDetails["FaxNumber"]
            ? excavatorDetails["FaxNumber"]
            : "";
        }
        // CallerName; user set
        // PhoneNumber; user set
        // email; user set
        // get what is already there.
        let ticketDetails = await this.createTicket$.getCurrentTicketDetails();
        //required
        ticketDetails["WorkDoneFor"] = "";
        // create object map dbkey to value
        if (detailView["formGroup"].value) {
          let formValues = detailView["formGroup"].value;
          for (let key in formValues) {
            if (formValues[key]) {
              if (key.toLowerCase().indexOf("utilities") == 0) {
                //[{value:utilityid}] get formgroup by uid.
                await this.handlePrimaryPrefill();
              } else if (key.toLocaleLowerCase().indexOf("tickettags") == 0) {
                //ticket tags array of obj of TagID:
                if (formValues[key] && formValues[key].length > 0) {
                  let tags = [];
                  formValues[key].forEach((tag) => {
                    tags.push({ TagID: tag.value });
                  });
                  ticketDetails[key] = tags;
                }
              } else if (key.toLocaleLowerCase().indexOf("calltype") == 0) {
                //TODO make array of keys to skip
              } else if (key.indexOf("_Ignore_") > 0) {
                console.log("Ignored: ", key);
              } else {
                ticketDetails[key] = formValues[key];
              }
            }
          }
        }

        if (
          ticketDetails["OriginalExcavationDate"] &&
          ticketDetails["OriginalExcavationDate"] instanceof Date
        ) {
          ticketDetails["OriginalExcavationDate"] =
            this.datetime.localDateToDBDateStr(
              ticketDetails["OriginalExcavationDate"]
            );
        }

        if (
          ticketDetails["AppointmentDate"] &&
          ticketDetails["AppointmentDate"] instanceof Date
        ) {
          ticketDetails["AppointmentDate"] = this.datetime.localDateToDBDateStr(
            ticketDetails["AppointmentDate"]
          );
        }

        if (ticketDetails["JobNumber"]) {
          ticketDetails["PrimaryJobNumber"] = ticketDetails["JobNumber"];
        }

        try {
          let typeOfWorkField =
            detailView["groups"]["ticketDetails"]["fields"]["TypeOfWork"];
          if (
            !typeOfWorkField.isVisible ||
            !ticketDetails["TypeOfWork"] ||
            ticketDetails["TypeOfWork"].trim().length == 0
          ) {
            ticketDetails["TypeOfWork"] = typeOfWorkField.placeholder;
          }
        } catch (error) {
          this.logger$.log("Failed to get default Typeofwork");
        }

        let regionDesc = ticketDetails.LocateSubRegionName
          ? ticketDetails.LocateSubRegionName
          : "";

        ticketDetails = {
          ...ticketDetails,
          SubRegionDesc: regionDesc,
          RegionDesc: regionDesc,
        };

        ticketDetails = {
          ...ticketDetails,
          ExcavationDate: ticketDetails["OriginalExcavationDate"],
          ...excavatorInfo,
        };

        console.log(ticketDetails);
        result = await this.createTicket$
          .updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_DATA, {
              formType: CreateTicketFormTypes.ticket,
              value: ticketDetails,
            })
          )
          .toPromise();
      }
    } catch (error) {
      this.logger$.error("handleDetails :" + error);
      result = false;
    }
    return result;
  }

  /**
   * [{
   * detailID = primarydetailfieldid
   * value = value //if checkbox then 1=t 0=f
   * isVisible = 1 : 0
   * }]
   */
  private async handlePrimaryPrefill() {
    let result: any = false;
    try {
      let details = [];
      if (this.utilityViews && this.utilityViews["newViews"]) {
        this.utilityViews["newViews"].forEach((utilityView) => {
          if (utilityView["formGroup"] && utilityView["groups"]) {
            let utilityForm = utilityView["formGroup"];
            for (let groupKey in utilityView["groups"]) {
              if (utilityView["groups"][groupKey]["fields"]) {
                let fields = utilityView["groups"][groupKey]["fields"];
                for (let fieldKey in fields) {
                  let curValue =
                    utilityForm.controls[fields[fieldKey]["key"]].value;
                  if (
                    fields[fieldKey]["isVisible"] &&
                    fields[fieldKey]["inputTypeID"] > 0
                  ) {
                    let currentDetail = {};
                    currentDetail["detailID"] = fieldKey;
                    if (
                      fields[fieldKey]["inputTypeID"] ==
                        TicketDetailInputType.Checkbox ||
                      fields[fieldKey]["inputTypeID"] ==
                        TicketDetailInputType.CheckboxHS
                    ) {
                      if (curValue) {
                        currentDetail["value"] = 1;
                      } else {
                        currentDetail["value"] = 0;
                      }
                    } else if (Array.isArray(curValue)) {
                      let tmpVal = "";
                      for (let i in curValue) {
                        if (tmpVal.length > 0) {
                          tmpVal += ", ";
                        }
                        tmpVal += curValue[i].text;
                      }
                      currentDetail["value"] = tmpVal;
                    } else if (curValue instanceof Object) {
                      currentDetail["value"] = curValue.text;
                    } else {
                      currentDetail["value"] = curValue;
                    }
                    currentDetail["isVisible"] = 1; //(element["isVisible"]) ? 1 : 0;
                    details.push(currentDetail);
                  }
                }
              }
            }
          }
        });
      }
      // this.logger$.log(details);

      //handle common
      let commonDetails = [];
      if (
        this.commonViews &&
        this.commonViews[0] &&
        this.commonViews[0].formGroup
      ) {
        commonDetails = this.handleCommonPrefill();
      }

      if (commonDetails && commonDetails.length > 0) {
        details = [...details, ...commonDetails];
      }

      result = await this.createTicket$
        .updateTicketData(
          new ActionMessage(Actions.UPDATE_PRIMARY_DETAILS_PREFILL, {
            formType: CreateTicketFormTypes.primaryDetailsPrefill,
            value: details,
          })
        )
        .toPromise();
    } catch (error) {
      this.logger$.error("handlePrimaryPrefill :" + error);
      result = false;
    }
    return result;
  }

  /**
   * [{
   * detailID = primarydetailfieldid
   * value = value //if checkbox then 1=t 0=f
   * isVisible = 1 : 0
   * }]
   */
  private handleCommonPrefill() {
    let result: any = false;
    try {
      let details = [];
      if (this.commonViews && this.commonViews.length > 0) {
        this.commonViews.forEach((commonView) => {
          if (commonView["formGroup"] && commonView["groups"]) {
            for (let groupKey in commonView["groups"]) {
              let utilityForm = commonView["formGroup"];
              if (
                commonView["groups"][groupKey]["fillStageID"] &&
                commonView["groups"][groupKey]["fillStageID"] == 3
              ) {
                utilityForm =
                  commonView["formGroup"]["controls"][
                    commonView["groups"][groupKey]["fillKey"]
                  ];
              }

              if (commonView["groups"][groupKey]["fields"]) {
                let fields = commonView["groups"][groupKey]["fields"];
                for (let fieldKey in fields) {
                  if (
                    fields[fieldKey]["isVisible"] &&
                    fields[fieldKey]["inputTypeID"] > 0
                  ) {
                    let currentDetail = {};
                    currentDetail["detailID"] = fieldKey;
                    if (
                      fields[fieldKey]["inputTypeID"] ==
                        TicketDetailInputType.Checkbox ||
                      fields[fieldKey]["inputTypeID"] ==
                        TicketDetailInputType.CheckboxHS
                    ) {
                      if (utilityForm.controls[fields[fieldKey]["key"]].value) {
                        currentDetail["value"] = 1;
                      } else {
                        currentDetail["value"] = 0;
                      }
                    } else {
                      currentDetail["value"] =
                        utilityForm.controls[fields[fieldKey]["key"]].value;
                    }
                    currentDetail["isVisible"] = 1; //(element["isVisible"]) ? 1 : 0;
                    details.push(currentDetail);
                  }
                }
              }
            }
          }
        });
      }
      this.logger$.log(details);
      result = details;

      // await this.createTicket$
      //   .updateTicketData(
      //     new ActionMessage(Actions.UPDATE_PRIMARY_DETAILS_PREFILL, {
      //       formType: CreateTicketFormTypes.primaryDetailsPrefill,
      //       value: details,
      //     })
      //   )
      //   .toPromise();
    } catch (error) {
      this.logger$.error("handlePrimaryPrefill :" + error);
      result = false;
    }
    return result;
  }

  private async handleScheduleDetails() {
    let result: any = false;
    try {
      //check setting. If can schedule then get schedule data.
      let schedulerSettingRow = await this.createTicket$.getAdminSettings(
        this.scheduleSettingID
      );
      if (
        schedulerSettingRow &&
        schedulerSettingRow["Active"] &&
        schedulerSettingRow["Active"] == 1 &&
        this.views["ticketSummary"] &&
        this.views["ticketSummary"]["formGroup"].value["AvailableRigs"] &&
        (this.views["ticketSummary"]["formGroup"].value["AvailableRigs"]
          .value ||
          (Array.isArray(
            this.views["ticketSummary"]["formGroup"].value["AvailableRigs"]
          ) &&
            this.views["ticketSummary"]["formGroup"].value["AvailableRigs"]
              .length > 0)) &&
        this.views["ticketSummary"].groups["schedule"].fields
      ) {
        let summaryFormGroup = this.views["ticketSummary"]["formGroup"];
        let scheduleObj = {
          EventStartDateTime: "",
          EventEndDateTime: "",
          ProjectEventEndDate: "",
          ResourceIDArr: [],
          EventTypeID: 2,
        };

        //checks if NA is selected because cannot schedule without rig
        if (!this.hasRigSelected(summaryFormGroup)) {
          this.logger$.log("No rig to schedule");
          result = true;
          return result;
        }

        let fields = this.views["ticketSummary"].groups["schedule"].fields;
        let fieldKeys = Object.keys(fields);
        fieldKeys.forEach((fieldKey) => {
          if (fieldKey.toLowerCase().includes("date")) {
            if (
              fieldKey == "EventStartDateTime" &&
              summaryFormGroup.value[fieldKey]
            ) {
              scheduleObj[fieldKey] = formatDate(
                summaryFormGroup.value[fieldKey],
                "yyyy-MM-d 08:00:00",
                "en-US"
              );
            } else if (fieldKey == "EventEndDateTime") {
              if (summaryFormGroup.value[fieldKey]) {
                // scheduleObj[fieldKey] = formatDate(summaryFormGroup.value[fieldKey], "yyyy-MM-d 17:00:00", "en-US")
                //New events parent child structure
                //enddate is always same as current, project is whole
                scheduleObj[fieldKey] = formatDate(
                  summaryFormGroup.value["EventStartDateTime"],
                  "yyyy-MM-d 17:00:00",
                  "en-US"
                );
                scheduleObj["ProjectEventEndDate"] = formatDate(
                  summaryFormGroup.value["EventEndDateTime"],
                  "yyyy-MM-d 17:00:00",
                  "en-US"
                );
              } else if (summaryFormGroup.value["EventStartDateTime"]) {
                scheduleObj[fieldKey] = formatDate(
                  summaryFormGroup.value["EventStartDateTime"],
                  "yyyy-MM-d 17:00:00",
                  "en-US"
                );
                scheduleObj["ProjectEventEndDate"] = formatDate(
                  summaryFormGroup.value["EventEndDateTime"],
                  "yyyy-MM-d 17:00:00",
                  "en-US"
                );
              }
            } else {
              scheduleObj[fieldKey] = formatDate(
                summaryFormGroup.value[fieldKey],
                "yyyy-MM-d 00:00:00",
                "en-US"
              );
            }
            //if array assume add to resounceIDarr.
          } else if (Array.isArray(summaryFormGroup.value[fieldKey])) {
            summaryFormGroup.value[fieldKey].forEach((obj) => {
              scheduleObj["ResourceIDArr"].push(obj.value);
            });
          } else if (
            typeof summaryFormGroup.value[fieldKey] === "object" &&
            summaryFormGroup.value[fieldKey].value
          ) {
            scheduleObj["ResourceIDArr"].push(
              summaryFormGroup.value[fieldKey].value
            );
          } else if (fields[fieldKey].inputTypeID == 1) {
            scheduleObj[fieldKey] = summaryFormGroup.value[fieldKey] ? 1 : 0;
          } else {
            scheduleObj[fieldKey] = summaryFormGroup.value[fieldKey]
              ? summaryFormGroup.value[fieldKey]
              : "";
          }
        });

        this.logger$.log(scheduleObj);

        //set as
        let curUID = this.createTicket$.getCurrentCreateTicketID();
        result = await this.completions$
          .updateScheduleData(
            new ActionMessage(Actions.UPDATE_SCHEDULE_DATA, {
              rowKey: curUID,
              value: scheduleObj,
            })
          )
          .toPromise();
      } else {
        this.logger$.log("Nothing to schedule");
        result = true;
      }
    } catch (error) {
      this.logger$.error("handleScheduleDetails " + error.message);
      result = false;
    }
    return result;
  }

  hasRigSelected(summaryFormGroup: any) {
    let result = true;
    let valueOfNA = -1;
    try {
      if (Array.isArray(summaryFormGroup.value["AvailableRigs"])) {
        let rigs = summaryFormGroup.value["AvailableRigs"];
        if (rigs[0].value == valueOfNA) {
          result = false;
        }
      } else {
        if (
          summaryFormGroup.value["AvailableRigs"]["value"].value == valueOfNA
        ) {
          result = false;
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  // {\\\"AssignmentID\\\":21,
  // \\\"EventStartDateTime\\\":\\\"2020-08-19 09:23:00\\\",
  // formatDate(event.StartTime, 'yyyy-MM-d HH:mm:ss', 'en-US'),
  // \\\"EventEndDateTime\\\":\\\"2020-08-19 19:23:00\\\", \
  // \\\"ResourceIDArr\\\":[1],\\\

  //examples
  // "{\"value\":\"
  // {\\\"ExcavatorDetails\\\":{\\\"Code\\\":93191,\\\"ExcavatorName\\\":\\\"Competers Excavating Company\\\",\\\"ExcavatorAddress\\\":\\\"355 Elmira\\\",\\\"ExcavatorCity\\\":\\\"Guelph\\\",\\\"ExcavatorPostalCode\\\":\\\"N1N 1N1\\\",\\\"PhoneNumber\\\":\\\"2263388906\\\",\\\"Email\\\":\\\"shaneh@competers.com\\\",\\\"ContactName\\\":\\\"Shane Hart\\\",\\\"Province\\\":\\\"ON\\\"},
  // \\\"TicketDetails\\\":{\\\"WorkDoneFor\\\":\\\"Competers Excavating Company\\\",\\\"Latitude\\\":43.552258644966656,\\\"Longitude\\\":-80.24973844495163,\\\"LocateSubRegionName\\\":\\\"CLARINGTON\\\",\\\"LocateAddress\\\":\\\"SCUGOG ST\\\",\\\"LocateCrossStreet\\\":\\\"GOODWIN AVE\\\",\\\"ExcavationDate\\\":\\\"2019-08-23T00:00:00.000Z\\\",\\\"TypeOfWork\\\":\\\"DIRECTIONAL BORING\\\",\\\"CallerName\\\":\\\"Cathy Murphy\\\",\\\"PhoneNumber\\\":\\\"(705) 790-3681\\\",\\\"email\\\":\\\"905e.locates@telecon.ca\\\",\\\"Province\\\":\\\"ON\\\",\\\"bPublicProperty\\\":1,\\\"bPrivateProperty\\\":1,\\\"bAreaNotMarked\\\":1,\\\"bDirectionalDrilling\\\":1,\\\"bMachineDig\\\":1,\\\"bHandDig\\\":1,\\\"OriginalExcavationDate\\\":\\\"2019-08-23T00:00:00.000Z\\\",\\\"SubRegionDesc\\\":\\\"CLARINGTON\\\",\\\"RegionDesc\\\":\\\"CLARINGTON\\\"},
  // \\\"PrimaryDetails\\\":[88],
  // \\\"DigsiteDetails\\\":[{\\\"DigsiteTypeID\\\":3,\\\"Coordinates\\\":[{\\\"Latitude\\\":43.552258644966656,\\\"Longitude\\\":-80.24973844495163},{\\\"Latitude\\\":43.552211990847596,\\\"Longitude\\\":-80.2497867247139},{\\\"Latitude\\\":43.55218866377453,\\\"Longitude\\\":-80.24974917378769},{\\\"Latitude\\\":43.55213034605233,\\\"Longitude\\\":-80.24965797868118},{\\\"Latitude\\\":43.552165336692426,\\\"Longitude\\\":-80.24963652100907}]}]
  // ,\\\"UtilityDetails\\\":{\\\"UtilityID\\\":\\\"1\\\",\\\"UtilityType\\\":\\\"1\\\",\\\"PolygonCode\\\":\\\"PRIVATE\\\"},
  // \\\"PrimaryDetailsPrefill\\\":[{\\\"detailID\\\":311,\\\"value\\\":1,\\\"isVisible\\\":1},{\\\"detailID\\\":312,\\\"value\\\":\\\"\\\",\\\"isVisible\\\":0},{\\\"detailID\\\":313,\\\"value\\\":1,\\\"isVisible\\\":1},{\\\"detailID\\\":314,\\\"value\\\":\\\"\\\",\\\"isVisible\\\":0},{\\\"detailID\\\":315,\\\"value\\\":\\\"\\\",\\\"isVisible\\\":1},{\\\"detailID\\\":316,\\\"value\\\":\\\"\\\",\\\"isVisible\\\":1}]}\"}"
}
