//Module Imports
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component, OnInit } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { ExcavatorInputViewComponent } from "./views/create-input-view/excavator-input-view.component";
import { TicketInputViewComponent } from "./views/create-input-view/ticket-input-view.component";
import { CreateMapViewComponent } from "./views/create-map-view/create-map-view.component";
import { CreateInputViewComponent } from "./views/create-inputs-view/create-input-view.component";

import {
  CreateAnotherTicketDialogComponent,
  CreateSidePanelComponent,
  UtilityMapDisclaimerDialogComponent,
} from "./panels/create-side-panel/create-side-panel.component";
import { CreateMainPanelComponent } from "./panels/create-side-panel/create-main-panel.component";
import { ComponentMessagingService } from "../core/component-messaging/component-messaging.service";
import { LoggerService } from "../core/services/logger/logger.service";
import { BasePageComponent } from "../core/base-set/base-set.component";
import { UploadService } from "../digsite/upload.service";
import { ComponentMessage } from "../core/component-messaging/component-message";
import { UploadDocumentsComponent } from "../shared/upload-documents/upload-documents/upload-documents.component";
import {
  FormDialogComponent,
  FormGroupViewComponent,
} from "../shared/forms/form-group-view/form-group-view.component";
import { MaterialModule } from "../shared/material.module";
import { GoogleMapsSetModule } from "../shared/google-map/google-maps-set/google-maps-set.component";
// Create Page
@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styles: [
    "as-split {height: 100%;} .side-panel {box-shadow:5px 5px 10px rgba(0, 0, 0, 0.25); z-index:1;} ",
  ],
  providers: [ComponentMessagingService],
})
export class CreatePageComponent extends BasePageComponent implements OnInit {
  className: string = "CreatePageComponent";
  mainPanelProps: {};
  sidePanelProps: {};

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private upload$: UploadService
  ) {
    super(logger$);

    this.sidePanelProps = {
      messageService: compMsg$,
      onSubmit: this.startTicketCreationUpload.apply(this),
    };

    this.mainPanelProps = {
      messageService: compMsg$,
    };

    this.compMsg$.getMessageStream().subscribe((nextMsg: ComponentMessage) => {
      // log
    });
  }

  ngOnInit() {
    try {
      // create new UID
      this.upload$.generateCreateTicketSessionID();
    } catch (ex) {
      this.loggerService.error(this.className, "ngOnInit", "could not init");
    }
  }

  // onclick handler to submit create ticket
  startTicketCreationUpload = () => {
    return this.upload$.sync();
  };
}

@NgModule({
    declarations: [
        CreatePageComponent,
        CreateSidePanelComponent,
        CreateMainPanelComponent,
        ExcavatorInputViewComponent,
        TicketInputViewComponent,
        CreateMapViewComponent,
        UtilityMapDisclaimerDialogComponent,
        CreateInputViewComponent,
        UploadDocumentsComponent,
        FormGroupViewComponent,
        FormDialogComponent,
        CreateAnotherTicketDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        GoogleMapsSetModule,
    ],
    exports: [
        CreatePageComponent,
        CreateSidePanelComponent,
        CreateMainPanelComponent,
        ExcavatorInputViewComponent,
        TicketInputViewComponent,
        CreateMapViewComponent,
        CreateInputViewComponent,
    ],
    providers: [ComponentMessagingService]
})
export class CreateModule {}
