import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  CardActionStatus,
  CardIcon,
  CardOptions,
} from "../../shared/card/cardOptions";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";
import { EditTablesService } from "../edit-tables.service";
import { EditTablesRow, FavouriteTable } from "../TableModels";

@Component({
  selector: "app-edit-tables-card-view",
  templateUrl: "./edit-tables-card-view.component.html",
  styleUrls: ["./edit-tables-card-view.component.scss"],
})
export class EditTablesCardViewComponent implements OnInit {
  FAVOURITES_LOCAL_STORAGE_NAME = "Favourites";

  cards: CardOptions[] = [];
  favouriteCards: CardOptions[] = [];

  constructor(
    private progressBarService: ProgressBarService,
    private router: Router,
    private editTablesService: EditTablesService
  ) {}

  async ngOnInit() {
    this.progressBarService.start();

    // Get the tables the user can view
    let result: any = await this.editTablesService
      .getEditTablePermissions()
      .toPromise();
    console.log(result);

    this.setTableAsCards(result.editTableList);
    this.progressBarService.stop();
  }

  setTableAsCards(data: any) {
    try {
      // create list of cards in form of cardOptions from API data
      this.cards = [];
      this.favouriteCards = [];
      let tables: EditTablesRow[] = Object.values(data);

      tables.forEach((table) => {
        let isFavourite = this.isFavourite(table.EditTableID);
        let card: CardOptions = {
          id: table.EditTableID,
          title: table.VisibleName,
          content: table.TableDescription,
          icons: [
            {
              name: isFavourite ? CardIcon.FAVOURITE : CardIcon.NOT_FAVOURITE,
              action: this.favouriteTable,
            },
          ],
          actions: [
            {
              name: "View",
              action: this.viewTable,
              status: CardActionStatus.ACCESSIBLE,
            },
          ],
        };
        this.cards.push(card);
        if (isFavourite) {
          this.favouriteCards.push(card);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  viewTable = (tableID: number, btnIndex: number) => {
    try {
      this.router.navigate(["tables", tableID]);
    } catch (error) {
      console.log(error);
    }
  };

  // Favourites
  getFavourites(): FavouriteTable[] {
    var favRtn: FavouriteTable[] = [];
    var favStr = sessionStorage.getItem(this.FAVOURITES_LOCAL_STORAGE_NAME);
    if (favStr != null) {
      favRtn = JSON.parse(favStr);
    }
    return favRtn;
  }

  saveFavourites(favs: FavouriteTable[]) {
    var value: string = JSON.stringify(favs);
    sessionStorage.setItem(this.FAVOURITES_LOCAL_STORAGE_NAME, value);
  }

  isFavourite(tableID: number): boolean {
    let isFavourite = false;
    let favourites = this.getFavourites();
    favourites.forEach((fav) => {
      if (fav.id == tableID) {
        isFavourite = fav.isFavourite;
        return;
      }
    });
    return isFavourite;
  }

  favouriteTable = (id: number, iconIndex: number) => {
    if (this.cards) {
      let tableCard = null;
      this.cards.forEach((table) => {
        if (table.id == id) {
          tableCard = table;
          return;
        }
      });
      if (tableCard != null) {
        if (tableCard.icons[iconIndex].name == CardIcon.FAVOURITE) {
          tableCard.icons[iconIndex].name = CardIcon.NOT_FAVOURITE;
          this.removeFromFavourites(id);
        } else {
          tableCard.icons[iconIndex].name = CardIcon.FAVOURITE;
          this.favouriteCards.push(tableCard);
        }
        var favourites: FavouriteTable[] = [];
        this.cards.forEach((table) => {
          var isFavourite = false;
          if (table.icons[0].name == CardIcon.FAVOURITE) {
            isFavourite = true;
          }
          favourites.push({ id: table.id, isFavourite: isFavourite });
        });
        this.saveFavourites(favourites);
        // this.sendMessage(MessageAction.UPDATE, { saveFavourites: true, favourites: favourites });
      } else {
        console.log("Error: favouriteTable - could not find table");
      }
    } else {
      console.log("Error: favouriteTable - tables list undefined");
    }
  };

  removeFromFavourites(idToRemove: number) {
    var index = -1;
    var len = this.favouriteCards.length;
    if (this.favouriteCards) {
      for (let i = 0; i < len; i++) {
        if (this.favouriteCards[i].id == idToRemove) {
          index = i;
          break;
        }
      }
    }
    if (index >= 0) {
      var tmp: CardOptions[];
      if (index == 0) {
        tmp = this.favouriteCards.slice(1, len);
      } else if (index == len - 1) {
        tmp = this.favouriteCards.slice(0, index);
      } else {
        tmp = this.favouriteCards.slice(0, index);
        tmp = tmp.concat(this.favouriteCards.slice(index + 1, len));
      }
      this.favouriteCards = tmp;
    }
  }
}
