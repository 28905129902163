import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-scroller',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './scroller.component.html',
  styleUrls: ['./scroller.component.scss'],
})
export class AppScrollerComponent implements AfterViewInit {
  @Input() height: string = '300px';
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('scrollbar') scrollbar!: ElementRef;
  @ViewChild('thumb') thumb!: ElementRef;

  private isDragging = false;
  private startY = 0;
  private startTop = 0;

  ngAfterViewInit() {
    this.updateScrollbarVisibility();
    this.updateThumbPosition();
  }

  onScroll() {
    this.updateThumbPosition();
  }

  updateScrollbarVisibility() {
    const { scrollHeight, clientHeight } = this.content.nativeElement;
    this.scrollbar.nativeElement.style.display = scrollHeight > clientHeight ? 'block' : 'none';
  }

  updateThumbPosition() {
    const { scrollTop, scrollHeight, clientHeight } = this.content.nativeElement;
    const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
    const thumbTop = (scrollTop / scrollHeight) * clientHeight;

    this.thumb.nativeElement.style.height = `${thumbHeight}px`;
    this.thumb.nativeElement.style.top = `${thumbTop}px`;
  }

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startY = event.clientY;
    this.startTop = parseFloat(this.thumb.nativeElement.style.top);
    event.preventDefault();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;

    const deltaY = event.clientY - this.startY;
    const newTop = this.startTop + deltaY;
    const maxTop = this.scrollContainer.nativeElement.clientHeight - this.thumb.nativeElement.clientHeight;

    const boundedTop = Math.max(0, Math.min(newTop, maxTop));
    this.thumb.nativeElement.style.top = `${boundedTop}px`;

    const scrollRatio = boundedTop / maxTop;
    this.content.nativeElement.scrollTop =
      scrollRatio * (this.content.nativeElement.scrollHeight - this.content.nativeElement.clientHeight);
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }

  @HostListener('window:resize')
  onResize() {
    this.updateScrollbarVisibility();
    this.updateThumbPosition();
  }
}
