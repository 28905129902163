<div fxLayout="column" fxLayoutAlign="center start">
  <span *ngIf="isModal" class="mat-headline-2" style="padding-bottom: 24px"
    >information and signature required</span
  >
  <form
    style="width: 100%"
    [formGroup]="form"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap.lt-md="0px"
    fxLayoutGap="24px"
  >
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>First Name</mat-label>
      <input
        formControlName="FirstName"
        required
        type="text"
        matInput
        placeholder="First Name"
      />
    </mat-form-field>
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>Last Name</mat-label>
      <input
        formControlName="LastName"
        required
        type="text"
        matInput
        placeholder="Last Name"
      />
    </mat-form-field>
    <mat-form-field fxFlex="426px" fxFlex.lt-md="100" appearance="outline">
      <mat-label>Phone Number</mat-label>
      <input
        formControlName="PhoneNumber"
        required
        type="tel"
        matInput
        placeholder="XXX-XXX-XXXX"
      />
    </mat-form-field>
  </form>
  <span class="mat-subtitle-1" style="padding-bottom: 12px">
    sign in box below
  </span>
  <div class="flex flex-row justify-center items-center w-full">
    <div
      class="sigBox flex h-[160px] w-[910px] sm:w-[500px] md:w-[810px] border-2 border-black"
    >
      <!--{http://localhost:4200/document-viewer?DocumentID=130&AssignmentID=158&DocumentToAngularID=11&Authorization=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MCwiVVNFUklEIjo4NDg3NzksIkNMSUVOVElEIjoiNjIiLCJVU0VSQ0FURUdPUllJRCI6NSwiVTNVU0VSIjpmYWxzZSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4NTAwNTA5OTksImlhdCI6MTY3NzI1MDk5OH0.BLk2R6GMjlfU6-V0oO7bLsZd2uMUc_pVX3a7OJOVkOA}        -->
      <app-signature-pad #signaturePad [padOptions]="signaturePadOptions">
      </app-signature-pad>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
    <p class="mat-subtitle-2">{{ disclaimer }}</p>
  </div>

  <div
    fxLayout="row wrap"
    style="width: 100%"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="start center"
  >
    <div
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxFlex.lt-sm="100"
      fxFlex="50"
      fxLayoutAlign="start center"
      fxLayoutGap.gt-sm="12px"
      fxLayoutGap.lt-md="8px"
    >
      <button mat-stroked-button (click)="onClickClear()">
        Clear Signature
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="acknowledgeDisabled"
        (click)="onClickAccept()"
      >
        i acknowledge
      </button>
    </div>
    <div
      *ngIf="isModal"
      fxLayout="row wrap"
      fxFlex.lt-sm="100"
      fxFlex="50"
      fxLayoutAlign="end center"
      fxLayoutAlign.xs="start center"
    >
      <button mat-stroked-button (click)="closeSignatureModal()">cancel</button>
    </div>
  </div>
</div>

<mat-progress-bar
  style="width: 100%; margin-top: 16px"
  value="100"
  mode="{{ progressBarMode }}"
></mat-progress-bar>
