import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { CreateTicketComponentService } from "src/app/modules/create-ticket/create-ticket-component.service";
import { TicketDetailsService } from "src/app/modules/shared/ticket-details/ticket-details.service";
import { Ticket } from "../../syncfusion-scheduler/schedulerTypes";

@Component({
  selector: "app-scheduler-create-ticket-view",
  templateUrl: "./scheduler-create-ticket-view.component.html",
  styleUrls: ["./scheduler-create-ticket-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SchedulerCreateTicketViewComponent
  extends BaseViewComponent
  implements OnInit
{
  className = "SchedulerCreateTicketViewComponent";

  @Input() props: any;
  @Input() tickets: Ticket[];

  views = {};
  createTicketOptions = {
    isMinified: true,
    prefillFields: {},
    documents: [],
  };

  childProps = {};
  assignmentID;
  primaryID;

  constructor(
    logger$: LoggerService,
    public dialog: MatDialog,
    private compMsg$: ComponentMessagingService,
    private createTicket$: CreateTicketComponentService,
    private ticketDetailsService$: TicketDetailsService
  ) {
    super(logger$);
    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      createTicketOptions: this.createTicketOptions,
    };
  }

  ngOnInit(): void {
    try {
      this.props.messageService.getMessageStream().subscribe((nextMsg) => {
        this.onReceivedMessage(nextMsg);
      });
      //get the stuff needed already.
      this.setupCreateTicketSettings()
        .then((val) => {
          if (this.assignmentID && this.primaryID) {
            return this.ticketDetailsService$.downloadTicket(
              this.assignmentID,
              this.primaryID
            );
          } else {
            //run to set defaults?
            return true;
          }
        })
        .then((result) => {
          if (
            result["tbCompletions_Assignments"] &&
            result["tbCompletions_AuxiliaryDetails"]
          ) {
            let assignmentsRows =
              result["tbCompletions_Assignments"]["Data"][0];
            let keys = Object.keys(assignmentsRows);
            keys.forEach((key) => {
              this.createTicketOptions.prefillFields[key] =
                assignmentsRows[key];
            });

            let auxRows = result["tbCompletions_AuxiliaryDetails"]["Data"];
            let utilityIDs = [];
            auxRows.forEach((auxRow) => {
              utilityIDs.push({ value: auxRow["UtilityID"] });
            });

            this.createTicketOptions.prefillFields["utilities"] = utilityIDs;
          }

          if (
            result.tbCompletions_Documents &&
            result.tbCompletions_Documents.Data &&
            result.tbCompletions_Documents.Data.length > 0
          ) {
            this.createTicketOptions.documents =
              result.tbCompletions_Documents.Data;
          }
          return this.createTicket$.getCreateTicketTemplate().toPromise();
        })
        .then((msg) => {
          if (msg) {
            this.childProps["views"] = msg["views"];
            this.childProps["commonViews"] = msg["commonViews"];
            this.childProps = { ...this.childProps };
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      this.logger$.log("ngOnInit1" + error);
    }
  }

  sendMessage(action: MessageAction, message: {}) {
    const messageToSend: ComponentMessage = {
      action: action,
      message: message,
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(messageToSend);
  }

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.eventCreated) {
        // todo: uncomment if want to delete tickets from list have creating events
        // this.removeTicket(compMessage.message.assignmentID)
      }
    }
  }

  async setupCreateTicketSettings() {
    try {
      await this.createTicket$.setupCreateTicketSettings();
    } catch (error) {
      console.error(error.message);
    }
    return true;
  }

  onSyncFinished(synced) {
    if (synced) {
      // close side panel.
    }
  }
}
