import { Component, OnInit } from '@angular/core';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { DateTime } from "luxon";
import { PunchClockService } from '../punch-clock.service';


@Component({
  selector: "app-punch-clock-timesheet",
  templateUrl: "./punch-clock-timesheet.component.html",
  styleUrls: ["./punch-clock-timesheet.component.scss"],
})
export class PunchClockTimesheetComponent implements OnInit {

  days: any = []
  weekStart: any
  currentDate: any
  currentDay: any
  value: any
  dateTemplate: any = {month: 'long', day: 'numeric', year: 'numeric'}
  dayTemplate: any = {day: 'numeric'}
  tbTimesheetEntries: any 
  selectedDay: any;
  taskArray: any;
  currentWeek: any;

  constructor(
    private datetimeService: DatetimeService, 
    private punchClockService: PunchClockService
    ) { }

  async ngOnInit(): Promise<void> { 
    this.taskArray = await this.punchClockService.gatherTasks()
    this.weekStart =  this.datetimeService.getStartOfWeek()
    this.currentWeek =  this.datetimeService.getStartOfWeek()
   
    let initDate = DateTime.now()
    this.setDates(initDate)
    this.buildDateObj()
    this.dayClicked(initDate)    
  }


  buildDateObj(){
    this.days = [
      {day: "S", dayNum: 14, fullDate: this.weekStart},
      {day: "M", dayNum: 15, fullDate: this.weekStart},
      {day: "T", dayNum: 16, fullDate: this.weekStart},
      {day: "W", dayNum: 17, fullDate: this.weekStart},
      {day: "T", dayNum: 18, fullDate: this.weekStart},
      {day: "F", dayNum: 19, fullDate: this.weekStart},
      {day: "S", dayNum: 20, fullDate: this.weekStart},
    
    ]    
    let weekObj = this.weekStart.minus({ days: 1 });
    this.weekStart.setLocale('en-US').toLocaleString(this.dayTemplate) 
    
    for(let i of this.days) {
     i.dayNum = weekObj.setLocale('en-US').toLocaleString(this.dayTemplate)  
     i.fullDate = weekObj
     weekObj = weekObj.plus({ days: 1 });    
    }
    

  }

  setDates(date){
    let dayObj = {weekday: 'long'}
    this.currentDay =  date.setLocale('en-US').toLocaleString(dayObj)

    this.currentDate = date.setLocale('en-US').toLocaleString(this.dateTemplate)
  }


  async dayClicked(dateObj){
    
    this.selectedDay = dateObj.setLocale('en-US').toLocaleString(this.dayTemplate);
    
    this.setDates(dateObj)
    let startTime = dateObj.toFormat('yyyy-MM-dd') + ' 00:00:00'
    let endTime = dateObj.toFormat('yyyy-MM-dd') + ' 23:59:59'
    this.tbTimesheetEntries =  await this.punchClockService.gatherEntries(startTime,endTime)    
  }

  arrowClicked(direction){
    if(direction == "back"){
      this.weekStart = this.weekStart.minus({ days: 7 });
    }
    else{
      this.weekStart = this.weekStart.plus({ days: 7 });
    }
    let initDate = this.weekStart.minus({ days: 1 });    
    if(this.currentWeek.toFormat('yyyy-MM-dd') == this.weekStart.toFormat('yyyy-MM-dd')){
      initDate = DateTime.now()
    }    
    this.buildDateObj()
    this.dayClicked(initDate)
  }


}


