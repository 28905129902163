import { MatIconModule } from '@angular/material/icon';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TicketMapService } from '../ticket-map.service';

export type FloatingMapMenuButton = { index: number; title: string; icon: string; action: () => void; target?: string };

/**
 * Floating map menu component
 * @param options list of buttons to display
 * @returns floating map menu
 * @example
 * <app-floating-map-menu [options]="options">
 *   <!--- sub menu content can be inserted here
 *        keep sub-menu items in order relative to their trigger --->
 *   </app-floating-map-menu>
 */
@Component({
  selector: 'app-floating-map-menu',
  standalone: true,
  imports: [MatIconModule],
  template: `
    <div
      #outerDiv
      class="flex flex-col gap-1 px-0 justify-center items-center rounded-md drop-shadow-xl bg-primary bg-opacity-50 lg:flex-row p-0">
      @for (tab of options; track tab.index) {
        <div class="group relative">
          <button
            #button
            title="{{ tab.title }}"
            (click)="tab.action()"
            class="size-[48px] peer pointer-events-auto flex justify-center items-center gap-1 appearance-none border-none p-2 {{
              ticketMapService.menuSelection.includes(tab.index) === true
                ? tab.target
                  ? 'bg-primary rounded-l-md lg:rounded-tl-none lg:rounded-b-md'
                  : 'bg-primary rounded-md'
                : 'bg-transparent group-hover:bg-primary hover:rounded-md'
            }} hover:cursor-pointer transition-all">
            <mat-icon
              style="width: 24px; height: 24px; font-size: 24px"
              class="text-white px-0"
              svgIcon="{{ tab.icon }}"></mat-icon>
          </button>
          @if (tab.target) {
            <ng-content />
          }
        </div>
      }
    </div>
  `,
  styleUrl: './floating-map-menu.component.scss',
})
export class FloatingMapMenuComponent {
  @Input() options: FloatingMapMenuButton[] = [];
  ticketMapService = inject(TicketMapService);

  constructor() {}
}
