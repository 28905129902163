<!-- <h1  mat-dialog-title class="mat-title blue">Storage Error</h1> -->
<h1 mat-dialog-title class="mat-headline-1" style="color: #e62525">
  Storage Error
</h1>

<div mat-dialog-content style="width: 100%; overflow-y: hidden">
  <span class="mat-body-2">To continue uploading information please sync</span
  ><br />
  <span class="mat-body-2"
    >your data as the storage capacity has been reached</span
  >
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">Cancel</button>
  <button mat-flat-button (click)="onConfirm($event)" color="primary">
    Sync
  </button>
</div>
