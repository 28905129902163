<mat-form-field fxFlex appearance="fill" class="form-input">
  <mat-label>{{ field.value.label }}</mat-label>
  <mat-select
    (openedChange)="openedChange($event)"
    placeholder="Select"
    formControlName="{{ field.value.key }}"
    [formGroup]="parentFormGroup"
    [compareWith]="compareOptions"
    required="{{ field.value.isRequired }}"
    multiple
  >
    <div class="select-container">
      <mat-optgroup>
        <mat-form-field style="width: 100%">
          <input
            #search
            placeholder="{{ field.value.placeholder }}"
            tabindex="{{ field.value.tabindex }}"
            id="{{ field.value.key }}"
            aria-label="Search"
            matInput
            [formControl]="searchTextboxControl"
            (keyup)="handleInput($event)"
            (keydown)="handleInput($event)"
          />
          <button
            [disableRipple]="true"
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearch($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="(filteredOptions | async)?.length === 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-option
        (onSelectionChange)="selectionChange($event)"
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.text }}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
