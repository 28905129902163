import { Component } from '@angular/core';
import { HeaderOptions, RouteOptions } from '../../shared/page-navigation/page-nav-options';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { InvoicingManagerService } from '../invoicing-manager.service';
import {ReportType} from '../../report-generator/report-generator.service';

@Component({
  selector: 'app-invoicing-manager',
  templateUrl: './invoicing-manager.component.html',
  styleUrl: './invoicing-manager.component.scss'
})
export class InvoicingManagerComponent {
  reports: any[] = [
    {
      ReportID: 1,
      ReportName: "Generic",
      ReportDesc: "Generic Report"
    },
  ];

  routes: RouteOptions[] = [];
  header: HeaderOptions;
  INVOICE_REPORT_TYPE_ID: number = ReportType.InvoiceReportType;

  constructor(private route: ActivatedRoute, private router: Router,
    private invoicingService: InvoicingManagerService) {}

  ngOnInit(): void {
    let newQueryParams = this.cleanURLQueryParams();
    this.router.navigate([], {
      queryParams: newQueryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });

    this.header = {
      title: "Reports",
    };
    
    this.setupSideNav();
  }

  /**
   * if visible url keys isn't empty, update params
   * @returns updated query paramters
   */
  private cleanURLQueryParams(): object {
    let queryParams: any = this.route.snapshot.queryParams;
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};
    try {
      let visisbleURLKeys: any =
        this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      let len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        let queryParamsKey = queryParamsKeys[i];
        if (
          visisbleURLKeys &&
          visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1
        ) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }

      return newQueryParams;
    } catch (error) {
      console.error(error);
    }
    return newQueryParams;
  }

  async setupSideNav() {
    this.routes = [];
    let reports = await this.invoicingService.getInvoiceReports(this.INVOICE_REPORT_TYPE_ID);
    for (let report of reports) {
      this.routes.push({
        path: report.ReportID.toString(),
        label: report.Name,
        selected: false
      });
    }
    console.log("routes:", this.routes);
  }
}
