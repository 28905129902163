import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, from, Subject } from 'rxjs';
import { ApiService, UtilocateApiRequest } from '../core/api/baseapi.service';
import { apiKeys } from '../../ENDPOINTS';
import { first, map, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export type NamespaceInfo = {
  tableName: string;
  namespace: string;
  url: string;
  username: string;
  password: string;
};

enum TableNames {
  baseMap = 'baseMap',
  drawing = 'drawing',
  locateArea = 'locateArea',
}

export enum DrawingTypes {
  canvas = 0,
  map = 1,
}

export type FeatureTable = {
  [key in TableNames]: NamespaceInfo;
};

@Injectable({
  providedIn: 'root',
})
export class DrawingService implements OnDestroy {
  // services
  private apiService = inject(ApiService);
  private dialog = inject(MatDialog);

  // observables
  private _drawingModeEnabled$ = new BehaviorSubject<boolean>(false);
  private _inspectModeEnabled$ = new BehaviorSubject<boolean>(false);
  private _captureModeEnabled$ = new BehaviorSubject<boolean>(false);
  private _clientNamespaces$ = new BehaviorSubject<FeatureTable>(null);
  private _drawingType$ = new BehaviorSubject<DrawingTypes>(DrawingTypes.map);
  private destroy$: Subject<void> = new Subject<void>();

  // members

  constructor() {
    // get the namespace data for the clients layers
    firstValueFrom(this.fetchNamespaceData()).then((result) => {
      this._clientNamespaces$.next(result);
    });

    // interlock drawing modes so only one can be active at a time -- start
    this._drawingType$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.endAllModes()
    })
    this.inspectModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (status) {
        this.drawingModeEnabled = false;
      }
    });
    this.drawingModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (status) {
        this.inspectModeEnabled = false;
        this.captureModeEnabled = false;
      }
    });
    this.captureModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (status) {
        this.inspectModeEnabled = false;
        this.drawingModeEnabled = false;
      }
    });
    // interlock drawing modes so only one can be active at a time -- end
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // public methods
  public openConfirmationDialog(title: string, message: string, action: () => void, confirmText: string): MatDialogRef<ConfirmationDialogComponent> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: title,
        message: message,
        confirmText: confirmText,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        action();
      }
    });

    return dialogRef;
  }

  set drawingModeEnabled(value: boolean) {
    this._drawingModeEnabled$.next(value);
  }

  get drawingModeEnabled$() {
    return this._drawingModeEnabled$.pipe(distinctUntilChanged());
  }

  get drawingModeEnabled() {
    return this._drawingModeEnabled$.value;
  }

  set drawingType(value: DrawingTypes) {
    this._drawingType$.next(value);
  }

  get drawingType$() {
    return this._drawingType$.pipe(distinctUntilChanged());
  }

  get drawingType() {
    return this._drawingType$.value;
  }

  set inspectModeEnabled(value: boolean) {
    this._inspectModeEnabled$.next(value);
  }

  get inspectModeEnabled$() {
    return this._inspectModeEnabled$.pipe(distinctUntilChanged());
  }

  get inspectModeEnabled() {
    return this._inspectModeEnabled$.value;
  }

  set captureModeEnabled(value: boolean) {
    this._captureModeEnabled$.next(value);
  }

  get captureModeEnabled$() {
    return this._captureModeEnabled$.pipe(distinctUntilChanged());
  }

  get captureModeEnabled() {
    return this._captureModeEnabled$.value;
  }

  get clientNamespace() {
    return this._clientNamespaces$.value;
  }

  public endAllModes() {
    this.drawingModeEnabled = false;
    this.inspectModeEnabled = false;
    this.captureModeEnabled = false;
  }

  private fetchNamespaceData() {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { featureServiceTransactionInfo: true } },
    };
    return from(this.apiService.invokeUtilocateApi(request)).pipe(
      first(),
      map((response) => response.body.result),
    );
  }
}
