import { Component, effect, inject, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  SavedSearch,
  SearchCriteria,
  TicketSearchService,
} from '../../../../shared/services/ticket-search/ticket-search.service';
import { CommonModule, DatePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ClickStopPropagationDirective } from '../../../../modules/core/directives/click-stop-propagation.directive';

@Component({
  selector: 'app-search-history-tile',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule, DatePipe, ClickStopPropagationDirective],
  template: `
    <div
      class="box-border flex flex-row justify-between rounded-md bg-white w-[216px] pt-3 pb-2 pl-3 border-1 border-solid border-gray-400 border-opacity-30">
      <div class="w-full flex flex-col justify-start items-start">
        <div
          class="w-full flex flex-row justify-between items-center font-rajdhani uppercase font-semibold text-lg text-primary mb-2.5">
          <div class="flex flex-row justify-start items-center gap-2.5">
            @if (true) {
              <mat-icon class="text-success" style="width:16px; height:16px; font-size:16px" svgIcon="toggle_on" />
            } @else {
              <mat-icon style="width:16px; height:16px; font-size:16px" svgIcon="toggle_off" />
            }
            <span
              class="flex flex-row justify-start items-center font-rajdhani uppercase font-semibold text-lg text-primary leading-5">
            {{ searchService.lastSearch$$().found }} Records Found
          </span>
          </div>
          <div
            class="flex basis-[30px] justify-start items-center"
            style="width: 100%; height: 100%"
            appClickStopPropagation>
            <button
              class="size-[32px] flex justify-center items-center appearance-none border-none bg-transparent cursor-pointer rounded-full hover:bg-gray-400 hover:bg-opacity-40 p-0 mr-1"
              [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="submenu_vertical" style="width: 24px; height: 24px"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="mat-menu-remove-padding">
              <button mat-menu-item [disableRipple]="true" (click)="searchService.editLast()">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="flex flex-col">
          @for (x of formattedCriteria; track $index) {
            <div>
              <span class="flex justify-start items-center font-rajdhani font-semibold uppercase text-md">
                {{ x.FilterName }}
                {{ [1, 7].includes(x.DataTypeID) ? '' : ':' }}
                {{ x.isExcluded ? '(NOT) ' : '' }}
                {{
                  x.DataTypeID === 5
                    ? (x.Value.lower | date) + ' - ' + (x.Value.upper | date)
                    : x.ValueDescription
                      ? '"' + x.ValueDescription.trim() + '"'
                      : '"' + x.Value + '"'
                }}
              </span>
            </div>
          }
        </div>
      </div>

    </div>
  `,
})
export class SearchHistoryTileComponent {
  // services
  searchService = inject(TicketSearchService);
  // members
  formattedCriteria: Array<any> = [];
  constructor() {
    effect(() => {
      this.formattedCriteria = this.formatCriteria(this.searchService.lastSearch$$().criteria);
    });
  }

  formatCriteria(criteria: Array<SearchCriteria>) {
    return criteria.reduce((acc, x) => {
      if ([5].includes(x.DataTypeID)) {
        // date
        const [lower, upper] = (x.Value as string).split(',');
        acc.push({
          FilterName: x.FilterName,
          DataTypeID: x.DataTypeID,
          Value: {
            lower: new Date(lower),
            upper: new Date(upper),
          },
          isExcluded: x.isExcluded,
          ValueDescription: x.ValueDescription ?? '',
        });
      } else if ([1, 7].includes(x.DataTypeID)) {
        // boolean or query
        acc.push({
          FilterName: x.FilterName,
          DataTypeID: x.DataTypeID,
          Value: '',
          isExcluded: x.isExcluded,
          ValueDescription: x.ValueDescription ?? '',
        });
      } else if ([2, 6, 10].includes(x.DataTypeID)) {
        // String, number
        acc.push({
          FilterName: x.FilterName,
          DataTypeID: x.DataTypeID,
          Value: x.Value,
          isExcluded: x.isExcluded,
          ValueDescription: x.ValueDescription ?? '',
        });
      } else if ([3, 4].includes(x.DataTypeID)) {
        // select
        acc.push({
          FilterName: x.FilterName,
          DataTypeID: x.DataTypeID,
          Value: Array.isArray(x.Value) ? x.Value.join(', ') : x.Value,
          isExcluded: x.isExcluded,
          ValueDescription: x.ValueDescription ?? '',
        });
      }

      return acc;
    }, []);
  }
}
