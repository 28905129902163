export interface Autolog {
  AssignmentID: string;
  UserID: string;
  logDate: string;
  DescID: string;
  Explaination: string;
  bFieldAdded: string;
}

export class AutologRow implements Autolog {
  AssignmentID: string;
  UserID: string;
  logDate: string;
  DescID: string;
  Explaination: string;
  bFieldAdded: string;

  constructor(
    AssignmentID: string,
    UserID: string,
    DescID: string,
    Explaination: string,
    logDate: string
  ) {
    this.AssignmentID = AssignmentID;
    this.UserID = UserID;
    this.DescID = DescID;
    this.Explaination = Explaination;
    this.logDate = logDate;
    this.bFieldAdded = "1";
  }
}
