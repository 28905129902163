import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "app-scheduler-advanced-search-dialog",
  templateUrl: "./scheduler-advanced-search-dialog.component.html",
  styleUrls: ["./scheduler-advanced-search-dialog.component.scss"],
})
export class SchedulerAdvancedSearchDialogComponent {
  form: FormGroup;

  dateDataSource = [
    { dateFieldID: "ExcavationDate", dateFieldDesc: "Excavation Date" },
    { dateFieldID: "EntryDate", dateFieldDesc: "Entry Date" },
    { dateFieldID: "CallDate", dateFieldDesc: "Call Date" },
  ];

  dateFields = {
    text: "dateFieldDesc",
    value: "dateFieldID",
  };

  constructor(
    public dialogRef: MatDialogRef<SchedulerAdvancedSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = new FormGroup({
      ExcavatorCompany: new FormControl(""),
      City: new FormControl(""),
      Address: new FormControl(""),
      dateField: new FormControl(""),
      startDate: new FormControl(""),
      endDate: new FormControl(""),
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  advancedSearch() {
    this.dialogRef.close(this.form.value);
  }
}
