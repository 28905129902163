import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

export const LocateStatusID = {
  NO_SHOW: 12,
  NOT_STARTED: 14,
  READY_FOR_DISPATCH: 15,
  DISPATCHED_TO_LEADHAND: 16,
  DISPATCHED_TO_LOCATOR: 17,
  DIG_COMPLETED: 18,
  PROJECT_FINISHED: 19,
  LOCATE_COMPLETED: 20,
  ON_HOLD: 21,
  IN_THE_FIELD: 22,
  PROJECT_ON_GOING: 23,
  LOCATE_ON_GOING: 24,
  OFFICE_CANCELLED: 25,
  ASSISTANCE_NEEDED: 26,
  ARCHIVED: 27,
  SENT_TO_LSP: 28,
  OFFICE_ASSIGNED: 29,
  UNASSIGNED: 30,
  SYSTEM_ANALYZING: 31,
  LOCATE_UNLOCATABLE: 32,
  LOCATE_ONGOING_CARRY_OVER: 33,
  COMPLETED_BY_LSP: 34,
  SENT_TO_SUPERVISOR: 35,
  PRE_SKETCHED: 36,
  REVIEW_REQUIRED: 37,
  LOCATE_UNLOCATABLE_VERIFIED: 38
};

export const CompletedLocateStatusIDs = [
  LocateStatusID.DIG_COMPLETED, 
  LocateStatusID.PROJECT_FINISHED, 
  LocateStatusID.LOCATE_COMPLETED, 
  LocateStatusID.OFFICE_CANCELLED, 
  LocateStatusID.SENT_TO_LSP
]

export const enum CallTypeID {
  EMERGENCY = 1,
  PRIORITY0 = 2,
  PRIORITY1 = 3,
  PRIORITY2 = 4,
  PRIORITY3 = 5,
  PRIORITY4 = 6,
  STANDARD = 7,
  PROJECT_WORK = 8,
  PRIVATE = 9,
  PLANNING = 10,
  DAMAGE_REPORT = 11,
  SEWER_LATERAL = 12,
  OAKVILLE_HYDRO = 13,
  BELL_SURVEY = 14,
  CPRRECTION = 15,
  DEDICATED_LOCATOR = 16,
  MISSING_DIGSITE = 17,
  IN_PROGRESS = 18,
  CONSTRUCTION = 19,
  DAMAGE = 20,
  MASTER_PROJECT_WORK = 21,
  LARGE_PROJECT = 22,
  TRAINING = 25,
  REVALIDATION_WITHOUT_MARKING = 26,
  REVALIDATION_WITH_MARKING = 27,
  INSPECTION = 28,
  CROSSING_INSPECTION = 29,
  PSLL_PLANNED = 30,
  PSLL_PROJECT = 31,
  PSLL_EMERGENCY = 32,
  PSLL = 33,
  PRE_CONSTRUCTION = 34,
  PSLL_POST_INSPECTION = 35,

  DEFAULT_AUDIT = 1000,
  AUDIT = 1002,
  UNAUTHORIZED_DIGGIN = 1003,

  DAMAGE_INVESTIGATION = 2000,
  VEHICHLE_AUDIT = 2001,
}

export const TicketProtectionActionTypeID = {
  CHECK_TICKET_PROTECTION: 0,
  ADD_TICKET_PROTECTION: 1,
  REMOVE_TICKET_PROTECTION: 2,
};

export function sortObjectArray(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    //reverse
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}
@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class TicketDetailsModule {}
