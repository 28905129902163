<div
  class="filter-main-div"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px grid"
>
  <div>
    <div class="e-float-input e-input-group">
      <input
        type="text"
        required
        (input)="search(searchValue)"
        [(ngModel)]="searchValue"
        (keyup.enter)="search(searchValue)"
      />
      <span class="e-float-line"></span>
      <label class="e-float-text">Search Tickets by Company</label>
      <span
        class="e-input-group-icon e-clear"
        (click)="clear()"
        *ngIf="searchValue"
      ></span>
      <span
        class="e-input-group-icon e-spin-down"
        (click)="openDialog()"
      ></span>
    </div>
  </div>
  <mat-checkbox
    style="margin: 0px"
    #check
    ngDefaultControl
    [(ngModel)]="onlyUnScheduled"
    (change)="search(searchValue)"
    >Unscheduled</mat-checkbox
  >

  <ejs-treeview
    #treeObject
    [fields]="field"
    class="treeview"
    [allowDragAndDrop]="true"
    (nodeDragStop)="drop($event)"
    [cssClass]="this.cssClass"
    (nodeClicked)="ticketClicked($event)"
  >
    <ng-template #nodeTemplate="" let-data="">
      <div class="custom-main">
        <div class="ticket-card-header mat-subtitle-2">
          {{ data.ExcavatorCompany }}
        </div>

        <div
          class="ticket-card-body mat-body-2"
          [matTooltip]="data.Location"
          fxLayout="column"
        >
          <span class="ticket-card-address">{{ data.Location }}</span>
          <span>{{ data.LocateStatusDesc }}</span>
          <span>Job#: {{ data.JobNumber }}</span>
          <span>PO#: {{ data.PONumber }}</span>
          <span>{{ data.isScheduled ? "Scheduled" : "Unscheduled" }}</span>
        </div>
      </div>
    </ng-template>
  </ejs-treeview>
</div>
