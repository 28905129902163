<ngx-datatable
  #table
  
  [columnMode]="ColumnMode.force"
  [headerHeight]="40"
  [rows]="rows | rowFilter: rowFilterString"
  [rowHeight]="40"
  [rowClass]="getRowClass"
  [trackByProp]="trackBy"
  [scrollbarH]="true"
  [scrollbarV]="true"
  [selected]="tableService.selectedRows$ | async"
  (select)="selectionChanged($event)"
  [selectionType]="selectionType"
  (resize)="onResize($event)"
  (reorder)="onReorder($event)"
  (appNgxDatatableDblClick)="doubleClickCallBack($event)">
  <ngx-datatable-column
    *ngIf="enableSelect"
    class="unselectable"
    style="background-color: #fff"
    [width]="30"
    [sortable]="false"
    [canAutoResize]="false"
    [draggable]="false"
    [resizeable]="false"
    [headerCheckboxable]="true"
    [checkboxable]="true"
    [frozenLeft]="true"></ngx-datatable-column>

  <!-- Conditionally display icons in rows -->
  <ngx-datatable-column *ngIf="hasIcons" width="40" name="" prop="icon">
    <!-- This is the "Icon" part. Give your row a .icon property that is a string of the *custom icon you want. This is optional-->
    <ng-template let-row="row" ngx-datatable-cell-template>
      <ng-container *ngIf="row.icon">
        <mat-icon class="table-icon" [svgIcon]="row.icon"></mat-icon>
      </ng-container>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    *ngFor="let column of displayedColumns | columnFilter"
    [cellClass]="getCellClass"
    [width]="column.Width"
    name="{{ column.Title }}"
    prop="{{ column.Title }}">
    <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
      <span class="datatable-header-cell-wrapper flex flex-row justify-between items-center" style="height: 24px">
        <span class="datatable-header-cell-label draggable" (click)="sort()">{{ column.name }}</span>
        <mat-icon class="table-sort-icon">filter_list</mat-icon>
      </span>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
