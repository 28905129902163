import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

import { NavBarConfig } from "../fieldside-navigation-bar/navigation-bar.component";
import { FieldsideService } from "../../fieldside.service";
import { TicketListItemObject } from "../../../../modules/shared/ticket-list/ticket-list-item/ticket-list-item";
import { TicketSyncService } from "../../../../modules/shared/ticket/services/ticket-sync/ticket-sync.service";
import { NavBarService } from "../../../../modules/shared/ticket/services/router/nav-bar.service";
import { UserService } from "../../../../modules/core/services/user/user.service";
import { ProgressBarService } from "../../../../modules/shared/progress-bar/progress-bar.service";
import { ModalService } from "../../../../modules/shared/modals/modal.service";
import { SnackbarService } from "../../../../modules/shared/snackbar/snackbar.service";
import { SnackbarType } from "../../../../modules/shared/snackbar/snackbar/snackbar";
import { TicketTagBuilderService } from "src/app/modules/shared/ticket-tags/ticket-list-tags/ticket-tags-builder.service";
import { U2_USER_CATEGORY_ID } from "src/app/modules/core/services/user/user";

@Component({
  selector: "app-self-assign-ticket-list",
  templateUrl: "./self-assign-ticket-list.component.html",
  styleUrls: ["./self-assign-ticket-list.component.scss"],
})
export class SelfAssignTicketListComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  ticketList: TicketListItemObject[] = [];
  isValidDownload = true;
  selectedTicket: any;
  CONFIRM_SELF_ASSIGN_MODAL = "confirm-self-assign-modal-id";
  route: any ='ticket-summary'
  currentlySelectedTicket$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private ticketSyncService: TicketSyncService,
    private navBarService: NavBarService,
    private userService: UserService,
    private fieldsideService: FieldsideService,
    private progressBarService: ProgressBarService,
    private modalService: ModalService,
    private snackBarService: SnackbarService,
    private ticketTagsService: TicketTagBuilderService,
  ) {}

  ngOnInit(): void {
     if (this.userService.getUserCategoryID() == U2_USER_CATEGORY_ID.Manager.toString()) {
      this.route = '../app/ticket-summary'
     }
    this.navBarService.updateNavBar(
      new NavBarConfig(
        "Self Assign",
        this.route,
        false,
        null, //null for sync to retain old sync 
        null,
        true
      )
    );
    this.createTicketList();
    this.currentlySelectedTicket$.subscribe((event) => {
      this.onTicketItemClick(event);
    })
  }

  /**
   * call api to gather tickets for self-assign
   */
  async createTicketList() {
    try {
      this.progressBarService.start();
      const tickets: any =
        await this.fieldsideService.gatherSelfAssignTickets();
      if (tickets != null) {
        this.isValidDownload = true;
        if (tickets.length > 0) {
          
          for (const ticket of tickets) {
            const AssignmentID = ticket.AssignmentID;
            const digDate = new Date(ticket.ExcavationDate);
            let formattedDigDate = digDate.toUTCString();
            const CallTypeDesc = await this.ticketTagsService.createTagsFromCallTypeID(ticket.CallTypeID)
            const color = this.fieldsideService.getColorBasedOnDate(digDate,ticket.LocateStatusID);
            formattedDigDate = formattedDigDate.replace("GMT", "");
            const item: TicketListItemObject = {
              AssignmentID,
              Address: `${ticket.StartHouseNumber} ${ticket.LocateAddress}`,
              City: ticket.LocateSubRegionName,
              Proximity: {lat: ticket.Latitude,long: ticket.Longitude},
              TypeOfWork: ticket.TypeOfWork,
              RequestNumber: ticket.RequestNumber,
              DigDate: formattedDigDate,
              NumberOfDocs: "0",
              DistanceToSite: "",
              CallTypeDesc: CallTypeDesc,
              PrimaryID: ticket.PrimaryID,
              LocateStatusID: ticket.LocateStatusID,
              CallTypeID: ticket.CallTypeID,
              LateColor: color,
            };

            this.ticketList.push(item);
          }
        } else {
          this.snackBarService.openSnackbar(
            "No Tickets Available to Self Assign",
            SnackbarType.warning
          );
        }
      } else {
        this.isValidDownload = false;
      }
      this.progressBarService.stop();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * set class variable and open confirmation modal
   * @param event list item (ticket) that was clicked
   */
  async onTicketItemClick(event: any) {
    this.selectedTicket = event;
    this.modalService.open(this.CONFIRM_SELF_ASSIGN_MODAL);
  }

  /**
   * get the current userID and reassign the selected ticket to that userID
   * then return to ticket-summary and sync
   * @param selected selected ticket object
   */
  async selfAssignSelected(selected: any) {
    try {
      this.progressBarService.start();
      const primaryID = selected.PrimaryID;
      const userID = this.userService.getUserID();

      const assign = await this.fieldsideService.selfAssignTicket(
        primaryID,
        userID
      );
      if (assign && assign.body && assign.body.value) {
        const message = JSON.parse(assign.body.value);

        if (message && message.Error) {
          this.snackBarService.openSnackbar(message.Error, SnackbarType.error);
        } else {
            //remove selected from this.ticketList 
            this.ticketList = this.ticketList.filter((tick) => tick.AssignmentID != selected.AssignmentID);

          this.ticketSyncService.startSync(true, true, [selected.AssignmentID.toString()], true).subscribe({
            complete: () => {
              this.ticketSyncService.closeModal();
            },
          });
        }
      }
      this.progressBarService.stop();
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
  }

  /**
   * close the modal, start the assigning process
   */
  async onSelfAssignConfirmClick() {
    try {
      this.modalService.close(this.CONFIRM_SELF_ASSIGN_MODAL);
      await this.selfAssignSelected(this.selectedTicket);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * close the modal and don't self assign anything
   */
  onSelfAssignCancelClick() {
    this.currentlySelectedTicket$.next(null);
    this.modalService.close(this.CONFIRM_SELF_ASSIGN_MODAL);
  }
}
