import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  HeaderOptions,
  RouteOptions,
} from "../../shared/page-navigation/page-nav-options";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";
import { EditTablesService } from "../edit-tables.service";
import { EditTablesRow } from "../TableModels";

@Component({
  selector: "app-edit-tables",
  templateUrl: "./edit-tables.component.html",
  styleUrls: ["./edit-tables.component.scss"],
})
export class EditTablesComponent implements OnInit {
  routes: RouteOptions[] = [];
  header: HeaderOptions;

  constructor(
    private progressBarService: ProgressBarService,
    private router: Router,
    private editTables$: EditTablesService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.header = {
      title: "General Settings",
    };

    this.progressBarService.start();

    // Get the tables the user can view
    let result: any = await this.editTables$
      .getEditTablePermissions()
      .toPromise();

    this.setRoutingOptions(result.editTableList);

    // Setup Selection
    if (this.router.url == "/table") {
      this.routes[0]["selected"] = true;
      this.router.navigate([this.routes[0].path], { relativeTo: this.route });
    } else {
      let pathParts = this.router.url.split("/");
      for (const route of this.routes) {
        if (route.path == pathParts[2]) {
          route["selected"] = true;
        }
      }
    }

    this.progressBarService.stop();
  }

  setRoutingOptions(data: any) {
    let tables: EditTablesRow[] = Object.values(data);
    tables.forEach((table) => {
      this.routes.push({
        path: table.EditTableID.toString(),
        label: table.VisibleName,
      });
    });
  }

  viewTable = (tableID: number, btnIndex: number) => {
    try {
      this.router.navigate(["tables", tableID]);
    } catch (error) {
      console.log(error);
    }
  };
}
