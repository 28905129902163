// @ts-nocheck
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { DeactivateComponent } from "./deactivate-component";

@Injectable({
  providedIn: "root",
})
export class ConfirmDeactivateGuard
  implements CanDeactivate<DeactivateComponent>
{
  canDeactivate(
    component: DeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //have component handle message in their canExit function
    return component.canExit()
      ? true
      : window.confirm(
          "Unsaved changes. If you leave, your changes will be lost.",
        );
  }
}
