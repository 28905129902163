import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { CompletionsStoreService } from "../core/admin/completions-store.service";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { ActionMessage } from "../core/component-messaging/action-message";
import { LoggerService } from "../core/services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class DigsiteService {
  curUID: string;

  constructor(
    private completions$: CompletionsStoreService,
    private utilocateApiService: ApiService,
    private logger$: LoggerService
  ) {
    this.logger$.log("Created Digsite service");
  }

  updateTicketData(message: ActionMessage): Observable<any> {
    this.curUID = sessionStorage.getItem(
      localStorageKeys.CURRENT_CREATE_TICKET_KEY
    );
    return this.completions$.updateTicketData(
      new ActionMessage(message.action, {
        rowKey: this.curUID,
        ...message.data,
      })
    );
  }

  applyDigsite() {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.addDigsiteToTicket;
        const type = api[url].type;
        const aid = sessionStorage.getItem(
          localStorageKeys.URL_KEYS.assignmentid
        );

        if (aid) {
          from(this.completions$.getRow(this.curUID))
            .pipe(
              switchMap((row: any) => {
                let { Ticket } = row;
                let digsiteDetails = Ticket["DigsiteDetails"];

                let utilocateApiRequest: UtilocateApiRequest = {
                  API_KEY: apiKeys.u2.addDigsiteToTicket,
                  API_TYPE: type,
                  API_BODY: {
                    DigsiteDetails: digsiteDetails,
                    AssignmentID: aid,
                  },
                };

                return from(
                  this.utilocateApiService.invokeUtilocateApi(
                    utilocateApiRequest
                  )
                );
              })
            )
            .subscribe((response: any) => {
              if (response.ok) {
                subscriber.next(response.body);
              } else {
                subscriber.next(false);
              }
              subscriber.complete();
            });
        } else {
          throw new Error("Failed to GET AssignmentID from storage");
        }
      } catch (error) {
        this.logger$.warn("Digsite$: applyDigsite: function failure: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  // change to be primary id
  gatherDigsites() {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.gatherDigsites;
        const type = api[url].type;
        const aid = sessionStorage.getItem(
          localStorageKeys.URL_KEYS.assignmentid
        );

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.gatherDigsites,
            API_TYPE: type,
            API_BODY: { AssignmentIDs: [aid] },
          };

          from(
            this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
          ).subscribe((response) => {
            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error("Failed to GET AssignmentID from storage");
        }
      } catch (error) {
        this.logger$.warn("Digsite$: applyDigsite: function failure: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  getAssignmentID(): string {
    const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);
    if (aid) {
      return aid;
    } else {
      return "0";
    }
  }
}
