import { Injectable } from "@angular/core";
import axios from "axios";
import { CacheService, StoreType } from "../../cache/cache.service";
import { ApiService, UtilocateApiRequest } from "../../api/baseapi.service";
import { DocumentService, DocumentTypeID } from "./document.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import {
  UtilocateTokenPaths,
} from "../token/token.service";
import * as CryptoJS from 'crypto-js';
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";

import { environment } from "src/environments/environment";
import { api, apiKeys } from "../../../../ENDPOINTS";

@Injectable({
  providedIn: "root",
})
export class DownloadDocumentService {
  IS_LIVE: boolean = false;
  constructor(
    private cache$: CacheService,
    private utilocateApiService: ApiService,
    private document$: DocumentService,
    private userService: UserService,
    private datetimeService: DatetimeService,
  ) {
    this.IS_LIVE = environment.production == true;
  }

  private retrieveDocFromS3(key: string) {
    let url = "/api/get/image"; // localhost
    let bucket = null;

    // update url to include nodejs for non-localhost sites
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/get/image";
    }
    if (key.includes("public/")) {
      bucket = "utilocatecompanion-userfiles-mobilehub-1835283454";
    } else {
      bucket = this.userService.getClientBucketFromSettings();
    }

    return axios.put(url, {
      //remove /nodejs for dev
      // to send information in a get use
      params: {
        key: key,
        bucket: bucket,
      },
    });
  }

  private retrieveDocFromS3All(key: string) {
    let url = "/api/get/image/all"; // localhost
    let bucket = null;

    // update url to include nodejs for non-localhost sites
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/get/image/all";
    }

    if (key.includes("public/")) {
      bucket = "utilocatecompanion-userfiles-mobilehub-1835283454";
    } else {
      bucket = this.userService.getClientBucketFromSettings();
    }

    return axios.put(url, {
      //remove /nodejs for dev
      // to send information in a get use
      params: {
        bucket: bucket,
        key: key,
      },
    });
  }

  private uploadDocToCompletions(data) {
    let url = "/api/upload/image"; // localhost
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/upload/image";
    }
    return axios.put(url, data);
  }

  private uploadDocToS3(data) {
    let url = "/api/upload/image/S3"; // localhost
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/upload/image/S3";
    }
    return axios.put(url, data);
  }

  private uploadAuxImageToS3(data) {
    let url = "/api/upload/auxiliary"; // localhost
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/upload/auxiliary";
    }
    return axios.put(url, data);
  }

  private async downloadDocument(assignmentID, documentID) {
    try {
      const url = apiKeys.u2.retrieveDocumentsS3;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.retrieveDocumentsS3,
        API_TYPE: type,
        API_BODY: {
          AssignmentID: assignmentID,
        },
        API_URL_DATA_PARAMS: {
          DocumentID: documentID,
        },
      };
      const result = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private async downloadDocumentAll(assignmentID, documentID = 0) {
    try {
      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.retrieveDocumentsS3,
        API_TYPE: "PUT",
        API_BODY: {
          AssignmentID: assignmentID,
          ListDocuments: "true"
        },
        API_URL_DATA_PARAMS: {
          DocumentID: documentID
        },
      };
      const result = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );      
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private async findDocumentLocally(assignmentID, documentID, isS3Document) {
    let foundDocument = null;
    const storeType = isS3Document ? StoreType.S3DOCUMENTS : StoreType.DOCUMENTS;
    try {
      const queryResult = await this.cache$.query(
        storeType,
        assignmentID
      );
      if (queryResult && queryResult[documentID]) {
        foundDocument = queryResult[documentID];
      }
    } catch (error) {
      console.error(error);
    }
    return foundDocument;
  }

  async addDocumentLocally(assignmentID, documentID, docParams) {
    try {
      // prepare for adding locally
      const doc = {
        bFieldAdded: 1, // true when uploading new doc
        FileName: docParams.FileName,
        Blob: docParams.Blob,
        type: docParams.type,
      };
      // update existing data
      let queryResult = await this.cache$.query(
        StoreType.DOCUMENTS,
        assignmentID
      );
      if (!queryResult) {
        queryResult = {};
      }
      queryResult[documentID] = doc;

      const insertResult = await this.cache$.insert(
        StoreType.DOCUMENTS,
        assignmentID,
        queryResult
      );

      return insertResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getDocument(assignmentID, documentID, isS3Document) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const local = await this.findDocumentLocally(assignmentID, documentID, isS3Document);
        if (local && local.Blob) {
          let buffer = null;
          let type = null;

          if (local.type == "pdf") {
            buffer = URL.createObjectURL(local.Blob);
            type = "pdf";

            resolve({ buffer: buffer, FileName: local.FileName, type: type });
          } else if (
            local.type == "img" ||
            local.type == "png" ||
            local.type == "jpg" ||
            local.type == "jpeg" ||
            local.type == "text"
          ) {
            // concert blob to base64 for download and viewing
            const fileReader = new FileReader();
            fileReader.readAsDataURL(local.Blob);
            fileReader.onload = function () {
              buffer = fileReader.result;

              type = "img";
              if (local.type == "text") {
                type = "text";
              }

              resolve({ buffer: buffer, FileName: local.FileName, type: type });
            };
          }
        } else {
          let s3Path = null;
          const docRow = await this.getDocumentRow(assignmentID, documentID, isS3Document);
          const fileExtension = this.document$
            .getExtensionFromFilename(docRow["FileName"])
            .toLowerCase();

          // get path to s3 file
          
          

          if (isS3Document && docRow.S3DocumentID) {
            //<STAGE>/<CLIENTID>/<ASSIGNMENTID>/<ZIP>
            const ClientID = await this.userService.getUserValueFromToken(
              UtilocateTokenPaths.CLIENTID
            );
            s3Path = `${this.IS_LIVE.toString() == 'false' ? 'old' : 'live'}/${ClientID}/${assignmentID}/${docRow.S3Path.toString()}`;
          } else {
            const s3PathRes = await this.downloadDocument(assignmentID, documentID);
            s3Path = s3PathRes.body?.value.split('"')[1];
          }

          if (!s3Path) throw new Error("Unable to S3 Path");

          // get file from s3
          const s3ImageObj = await this.retrieveDocFromS3(s3Path);
          let blob = null;
          let buffer = null;
          let type = null;

          if (fileExtension == "pdf") {
            blob = new Blob(
              [new Uint8Array(s3ImageObj["data"]["image"]["data"])],
              { type: "application/pdf" }
            );
            buffer = URL.createObjectURL(blob);
            type = "pdf";
          } else if (
            fileExtension == "png" ||
            fileExtension == "jpg" ||
            fileExtension == "jpeg"
          ) {
            blob = new Blob([
              new Uint8Array(s3ImageObj["data"]["image"]["data"]),
            ]);
            buffer =
              "data:image/jpg;base64," +
              this.arrayBufferToBase64(s3ImageObj["data"]["image"]["data"]);
            type = "img";
          } else {
            blob = new Blob([
              new Uint8Array(s3ImageObj["data"]["image"]["data"]),
            ]);
            buffer =
              "data:text/plain;base64," +
              this.arrayBufferToBase64(s3ImageObj["data"]["image"]["data"]);
            type = "text";
          }

          await this.addDocumentLocally(assignmentID, documentID, {
            Blob: blob,
            FileName: docRow["FileName"],
            type: type,
          });
          resolve({ buffer: buffer, FileName: docRow["FileName"], type });
        }
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });
  }

  getDocumentAll(assignmentID) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        // get path to s3 file
        const s3PathRes = await this.downloadDocumentAll(assignmentID, 0);
        if (s3PathRes && s3PathRes.body && s3PathRes.body.value) {
          const s3Path = JSON.parse(s3PathRes.body.value)
                    
          // get file from s3
          const s3ImageObj = await this.retrieveDocFromS3All(s3Path[0].ZipFilePath);
          if (
            s3ImageObj &&
            s3ImageObj["data"] &&
            s3ImageObj["data"]["body"] &&
            s3ImageObj["data"]["body"]["data"]
          ) {
            const blob = new Blob(
              [new Uint8Array(s3ImageObj["data"]["body"]["data"])],
              {
                type: "application/zip",
              }
            );
            const buffer = window.URL.createObjectURL(blob);
            const tmpArray = s3Path[0].ZipFilePath.split("/");
            const FileName: string = tmpArray[tmpArray.length - 1];
            const documentList = s3Path[0].DocumentList
            resolve({ buffer, FileName, documentList });
          } else {
            throw new Error("node api returned error");
          }
        } else {
          throw new Error("RetrieveS3 doc api returned error");
        }
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });
  }

  private arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  async uploadDocument(file, zipname, metadata, ClientID) {
    try {
      const isLiveSite = environment.production == true;
      const fileData: FormData = new FormData();
      fileData.append("fileToUpload", file);
      fileData.append("zipname", zipname);
      fileData.append("metadata", JSON.stringify(metadata));
      fileData.append("isLive", isLiveSite.toString());
      fileData.append("ClientID", ClientID);

      fileData.append("AssignmentID", metadata["AssignmentID"]);
      fileData.append("Description", metadata["Description"]);
      fileData.append("DocumentTypeID", metadata["DocumentTypeID"]);
      fileData.append("isSendable", metadata["isSendable"]);

      //type 6 is photo, so upload to s3 instead 
      if (metadata["DocumentTypeID"] == "6") {
        return this.uploadDocToS3(fileData);
      } else {
        return this.uploadDocToCompletions(fileData);
      }
    } catch (error) {
      console.error(error);
    }
  }


  async uploadAuxiliaryImage(file, zipname, metadata, ClientID, AssignmentID) {
    try {
      const isLiveSite = environment.production == true;
      const fileData: FormData = new FormData();
      const bucket = this.userService.getClientBucketFromSettings();

      fileData.append("fileToUpload", file);
      fileData.append("zipname", zipname);
      fileData.append("metadata", JSON.stringify(metadata));
      fileData.append("isLive", isLiveSite.toString());
      fileData.append("ClientID", ClientID);
      fileData.append("AssignmentID", AssignmentID);
      fileData.append("Description", "ESRI Maps Screenshot Auxiliary");
      fileData.append(
        "DocumentTypeID",
        DocumentTypeID["Auxiliary Image"].toString()
      );
      fileData.append("isSendable", "0");
      fileData.append("DocumentLocationID", "1");
      fileData.append("bucket", bucket); 

      return this.uploadAuxImageToS3(fileData);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Gets MD5 filehash from BLOB 
   * @param blob 
   * @returns promise<string>
   */
  private getMD5FileHash(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
  
      fileReader.onload = (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
        const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
        resolve(md5Hash);
      };
  
      fileReader.onerror = (event) => {
        reject(event.target?.error);
      };
  
      fileReader.readAsArrayBuffer(blob);
    });
  }

  async addLocalDocumentRow(AssignmentID, docRow) {
    try {
      // update existing data
      const queryResult = await this.cache$.query(
        StoreType.COMPLETIONS,
        AssignmentID
      );
      if (
        queryResult &&
        queryResult["tbCompletions_S3Documents"] &&
        queryResult["tbCompletions_S3Documents"]["Data"] 
      ) {
        const tbCompletions_S3DocumentsData = queryResult["tbCompletions_S3Documents"]["Data"];
        tbCompletions_S3DocumentsData.push(docRow);
        queryResult["tbCompletions_S3Documents"]["Data"] = tbCompletions_S3DocumentsData

        // apply changes
        const insertResult = await this.cache$.insert(
          StoreType.COMPLETIONS,
          AssignmentID,
          queryResult
        );
        return insertResult;
      } else {
        throw new Error("CANNOT FIND TICKET");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getDocumentRow(AssignmentID, DocumentID, isS3Document) {
    const tableName = isS3Document ? "tbCompletions_S3Documents" : "tbCompletions_Documents";
    const documentIdentifier = isS3Document ? "S3DocumentID" : "DocumentID";

    try {
      // eslint-disable-next-line no-var
      var allTables = await this.cache$.query(
        StoreType.COMPLETIONS,
        AssignmentID
      );
    } catch (error) {
      console.error(error);
    }

    const docsTable = allTables[tableName]["Data"];
    for (let i = 0; i < docsTable.length; i++) {
      const docRow = docsTable[i];
      if (docRow[documentIdentifier] == DocumentID) {
        return docRow;
      }
    }
    return null;
  }

  async removeDocumentRow(DocumentID,isS3Document?) {
    let S3DocID = null
    let docID = DocumentID
    if(isS3Document){
      S3DocID = DocumentID
      docID = null
    }
    
    try {
      const url = apiKeys.u2.DocumentController;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.DocumentController,
        API_TYPE: type,
        API_BODY: {
          ActionID: 1,
          DocumentID: docID,
          S3DocumentID: S3DocID
        },
      };
      const result = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      return result;
   
      
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async updateDocumentRow(DocumentID, isS3Document, arrayOfUpdates) {
    let S3DocID = null
    let docID = DocumentID
    if(isS3Document){
      S3DocID = DocumentID
      docID = null
    }    
    try {
      const url = apiKeys.u2.DocumentController;
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.DocumentController,
        API_TYPE: type,
        API_BODY: {
          ActionID: 2,
          DocumentID: docID,
          S3DocumentID: S3DocID,
          UPDATE_DATA: arrayOfUpdates,
        },
      };
      const result = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getHighestLocalDocumentId(AssignmentID) {
    try {
      // update existing data
      const queryResult = await this.cache$.query(
        StoreType.COMPLETIONS,
        AssignmentID
      );

      if (
        queryResult &&
        queryResult["tbCompletions_Documents"] &&
        queryResult["tbCompletions_Documents"]["Data"]
      ) {
        const docIDs = queryResult["tbCompletions_Documents"]["Data"].reduce(
          (total, current) => {
            if (current && current["DocumentID"]) {
              total.push(current["DocumentID"]);
            }
            return total;
          },
          []
        );

        let highestDocID = -1;
        for (let i = 0; i < docIDs.length; i++) {
          if (parseInt(docIDs[i], 10) > highestDocID) {
            highestDocID = parseInt(docIDs[i], 10);
          }
        }

        return highestDocID + 1;
      } else {
        throw new Error("CANNOT FIND TICKET");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
