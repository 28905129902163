<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
  <div fxFlex="30" class="rule-input">
    <mat-form-field appearance="outline" style="width: 100%">
      <!-- <mat-label>Utility</mat-label> -->
      <mat-select
        #typeInput
        [value]="rule.RuleType.RuleTypeID"
        (selectionChange)="inputChanged($event.value)"
      >
        <mat-option
          *ngFor="let ruleType of ruleTypes"
          [value]="ruleType.RuleTypeID"
        >
          {{ ruleType.RuleTypeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxFlex="29.5" class="rule-input">
    <ng-container *ngIf="rule.RuleType.RuleTypeInputID == 1; else rangeInput">
      <mat-form-field appearance="outline" style="width: 100%">
        <!-- <mat-label>Utility</mat-label> -->
        <mat-select
          [(value)]="rule.RuleValue"
          (selectionChange)="inputChanged()"
        >
          <mat-option
            *ngFor="let option of rule.RuleType.Options"
            [value]="option.Value"
          >
            {{ option.Text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <div fxFlex="30" class="rule-input">
    <mat-form-field style="width: 100%">
      <!-- <mat-label>Algorithm Description</mat-label> -->
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="rule.RuleDistance"
        (change)="distanceModified.emit(true); inputChanged()"
      />
    </mat-form-field>
  </div>

  <button mat-icon-button (click)="delete()">
    <mat-icon>delete</mat-icon>
  </button>
</div>

<ng-template #rangeInput>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="rule.RuleType.RuleTypeInputID == 2; else textInput"
  >
    <mat-form-field fxFlex="30">
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="rule.RuleValue"
        (change)="inputChanged()"
      />
    </mat-form-field>
    <span>to</span>
    <mat-form-field fxFlex="30">
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="rule.RuleValue2"
        (change)="inputChanged()"
      />
    </mat-form-field>
    <span>days late</span>
  </div>
</ng-template>

<ng-template #textInput>
  <div>
    <mat-form-field fxFlex="100">
      <input
        matInput
        autocomplete="off"
        [(ngModel)]="rule.RuleValue"
        (change)="inputChanged()"
      />
    </mat-form-field>
  </div>
</ng-template>
