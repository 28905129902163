import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { loadModules } from "esri-loader";

import { UnitModalComponent } from "../../../ticket/modals/unit-modal/unit-modal.component";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-esri-map-measurement",
  templateUrl: "./esri-map-measurement.component.html",
  styleUrls: ["./esri-map-measurement.component.scss"],
})
export class EsriMapMeasurementComponent implements OnChanges {
  @Input() mapView: any;
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @Output() panelClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() panelOpen: any;
  measurementWidget: any;
  activeWidget: any;
  dotGraphic: any
  isFirstPointTouched: boolean = false
  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.panelOpen) {
      this.addMeasurementTool(this.mapView);
    }
  }

  async addMeasurementTool(mapView) {
    const [Measurement] = await loadModules(["esri/widgets/Measurement"]);
    this.measurementWidget = new Measurement({
      view: mapView,
    });
   this.addPoint()
  }

  async distanceMeasurement() {
    this.measurementWidget.activeTool = "distance";
  }

  async addPoint(){
    const [Graphic] = await loadModules(["esri/Graphic"]);
    this.mapView.on("click", (event) => {
  // Check if the measurement widget is active and if the first point hasn't been touched yet
  if (
    this.measurementWidget.activeTool === "distance" ||
    this.measurementWidget.activeTool === "area"
  ) {
    if (!this.isFirstPointTouched) {
      this.isFirstPointTouched = true;
      
      // Get the clicked map point
      const clickedPoint = event.mapPoint;

      // Create a graphic (dot) at the clicked location
     this.dotGraphic = new Graphic({
        geometry: clickedPoint,
        symbol: {
          type: "simple-marker",
          style: "circle",
          size: 10,
          color: [255, 165, 0, 0.5],
          outline: null 
        },
      });

      // Add the graphic to the map
      this.mapView.graphics.add(this.dotGraphic);
    }
  }
});
  }

  areaMeasurement() {
    this.measurementWidget.activeTool = "area";
  }

  clearMeasurements() {
    this.mapView.graphics.remove(this.dotGraphic);
    this.dotGraphic = null;
    this.isFirstPointTouched = false
    this.measurementWidget.clear();
  }
  async selectUnits() {
    let units = true;
    if (this.measurementWidget.activeTool == "distance") {
      units = false;
    }

    const dialogRef = this.dialog.open(UnitModalComponent, {
      width: "400px",
      data: { units },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.measurementWidget[
          this.measurementWidget.activeTool === "area"
            ? "areaUnit"
            : "linearUnit"
        ] = result;
      }
    });
  }

  closeTool() {
    this.clearMeasurements();
    this.panelClose.emit(true);
  }
}
