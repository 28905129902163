import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { RateNode, Value } from "../rate-node";

@Component({
  selector: "app-rate-node-editor",
  templateUrl: "./rate-node-editor.component.html",
  styleUrls: ["./rate-node-editor.component.scss"],
})
export class RateNodeEditorComponent implements OnChanges {
  @Input() nodeID: number;
  @Input() parentNodeID: number;
  @Input() nodeCriteriaTypeID: number;
  @Input() childCriteriaTypeID: number;
  @Input() nodeValues: Value[];
  @Input() nodeRate: number;
  @Input() nodeRateName: string;
  @Input() criterias: any[];
  @Input() children: RateNode[];
  @Input() nodeTrace: any[];
  @Input() siblingValues: any[];

  @Output() saveNode = new EventEmitter<any>();
  @Output() cancelEdit = new EventEmitter<any>();

  @ViewChild("editorForm") editorForm: NgForm;

  currentCriteriaID: number;
  currentCriteriaValues: any[] = [];
  currentChildCriteria: number;
  currentValues: number[];
  currentRate: number;
  currentRateName: string;
  filteredCriterias: any[];
  filteredValues: any[];

  constructor() {}

  /**
   * update all the "current" variables, and filter criterias and values
   */
  ngOnChanges(): void {
    this.currentCriteriaID = this.nodeCriteriaTypeID;
    this.filteredCriterias = this.filterCriterias();
    if (this.currentCriteriaID) {
      this.currentCriteriaValues = this.filteredCriterias.find(
        (criteria) => criteria.id == this.currentCriteriaID
      ).values;
    } else {
      this.currentCriteriaValues = [];
    }
    this.currentChildCriteria = this.childCriteriaTypeID;
    this.filteredValues = this.filterValues();
    this.currentValues = this.nodeValues.map((value) => value.Value);
    this.currentRate = this.nodeRate;
    this.currentRateName = this.nodeRateName;
  }

  /**
   * use array functions to remove criterias that shouldn't be available to this node
   * @returns array of criterias not already in use on this branch
   */
  filterCriterias() {
    let filteredCriterias = [];
    try {
      let preTrace = this.nodeTrace.filter(
        (step) => step.criteriaTypeID != this.currentCriteriaID
      );
      let toFilterOut = preTrace.map((step) => step.criteria);
      filteredCriterias = this.criterias.filter(
        (criteria) => !toFilterOut.includes(criteria.name)
      );
    } catch (error) {
      console.error(error);
    }
    return filteredCriterias;
  }

  /**
   * use array functions to filter out already selected values
   * @returns an array of integers that represent values not already selected by this node's siblings
   */
  filterValues() {
    let filteredValues = [];
    try {
      if (this.siblingValues.length > 0) {
        let siblingValueValues = this.siblingValues.map((value) => value.Value);
        filteredValues = this.currentCriteriaValues.filter(
          (value) => !siblingValueValues.includes(value.id)
        );
      } else {
        filteredValues = this.currentCriteriaValues;
      }
    } catch (error) {
      console.error(error);
    }
    return filteredValues;
  }

  /**
   * update selected criteria and available values(and clear selected values)
   * @param value integer - new selected criteria value
   */
  criteriaChanged(value: number) {
    this.currentCriteriaID = value;
    this.currentCriteriaValues = this.criterias.find(
      (criteria) => criteria.id == this.currentCriteriaID
    ).values;
    this.filteredValues = this.filterValues();
    this.currentValues = [];
  }

  //handle adds, updates, and deletes
  valuesChanged(newValues: number[]) {
    this.currentValues = newValues;
  }

  rateChanged(value: number) {
    this.currentRate = value;
  }

  rateNameChanged(value: string) {
    this.currentRateName = value;
  }

  /**
   * set up changed value objects and emit saveNode output
   */
  saveCurrentNode() {
    let updatedValues: Value[] = [];
    let oldValues = this.nodeValues;
    let newValues = this.currentValues;
    //find new values
    for (let newValue of newValues) {
      let foundValue = oldValues.find((oldValue) => oldValue.Value == newValue);
      if (foundValue == null) {
        updatedValues.push({
          NodeID: this.nodeID,
          Value: newValue,
          valueAction: 1,
        });
      }
    }
    for (let oldValue of oldValues) {
      let foundValue = newValues.find((newValue) => newValue == oldValue.Value);
      if (foundValue == null) {
        updatedValues.push({
          ValueID: oldValue.ValueID,
          NodeID: this.nodeID,
          Value: oldValue.Value,
          valueAction: 3,
        });
      }
    }

    this.saveNode.emit({
      NodeID: this.nodeID,
      ParentNodeID: this.parentNodeID,
      CriteriaTypeID: this.currentCriteriaID,
      ChildCriteriaTypeID: this.currentChildCriteria,
      Rate: this.currentRate,
      RateName: this.currentRateName,
      Values: updatedValues,
    });
  }

  formatNodeTrace(nodeTrace) {
    const formattedArray = nodeTrace.map(item => `${item.criteria}: ${Array.isArray(item.values) ? item.values.join(', ') : item.values}`);
    return formattedArray.join(' / ');
  }
}
