import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RoutingPresetComponent } from "./routing-preset/routing-preset.component";
import { MaterialModule } from "../shared/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxColorsModule } from "ngx-colors";
import { RoutingPresetRuleComponent } from "./routing-preset-rule/routing-preset-rule.component";
import { RouterModule } from "@angular/router";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { RoutingPresetPageComponent } from "./routing-preset/routing-preset-page/routing-preset-page.component";
import { BaseModalModule } from "../shared/modals/base-modal.module";

@NgModule({
  declarations: [
    RoutingPresetComponent,
    RoutingPresetRuleComponent,
    RoutingPresetPageComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxColorsModule,
    RouterModule,
    PageNavigationModule,
    BaseModalModule,
  ],
  exports: [
    RoutingPresetComponent,
    RoutingPresetRuleComponent,
    RoutingPresetPageComponent,
  ],
})
export class RoutingPresetModule {}
