import {Component} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-account-settings-page',
  templateUrl: './account-settings-page.component.html',
})
export class AccountSettingsPageComponent {
  routeTitle: string = "Settings";
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

    //Set the title here for when a user reloads the page 
    this.updateTitle(this.router.url);

    //every time the route changes, update the title
    this.router.events.pipe().subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // const url = event.url;
        this.updateTitle(event.url);
      }
    });
  }

  /**
   * Updates the title of the current URl 
   * @param url  
   * @returns 
   */
  private updateTitle(url: string): void {
    const urlArray = url.split('/');
    if (urlArray.length < 4) {
      return;
    }
    let myRoute = urlArray[3].replaceAll('-', ' ');
    console.log(myRoute);
    if(myRoute == 'routing preset'){
      myRoute =  'routing manager'
    }
    this.routeTitle = myRoute.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Checks if the current route is not the list route
   * @returns 
   */
  isNotListRoute(): boolean {
    return !this.router.url.includes('/list');
  }

  /**
   *  Navigates back to the list route
   */
  navigateBack(): void {
    this.routeTitle = "Settings";
    this.router.navigate([`./list`], {relativeTo: this.activatedRoute});
  }
}
