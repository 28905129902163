<div
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  style="height: 100%; max-width: 240px; border-right: 1px solid #F1F3F4; border-top: 1px solid #F1F3F4; border-bottom: 1px solid #F1F3F4; border-radius: 6px;"
>
  <div
    #quickView
    fxFlex
    class="content-overflow form-container"
    style="padding-right: 6px; padding-left: 6px; background-color: white"
  >
  <br>
    <app-form-input-template
      #formInput
      class="dense-8 text-sm"
      [props]="formProps"
      (headerClicked)="onFormHeaderClicked($event)"
      (dropdownSelected)="onDropdownSelected($event)"
      (addressSelected)="onAddressSelected($event)"
      (dateSelected)="onDateSelected($event)"
    ></app-form-input-template>
    <div style="width: 100%">
      <button
        mat-button
        style="width: 100%; text-align: right; font-size: 16px; display: flow"
        class=""
        type="button"
        (click)="onFormHeaderClicked('docs')"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          class="w-full p-2"
        >
          <span class="font-semibold pl-2 font-rajdhani uppercase text-lg text-primary">Docs</span>
          <mat-icon color="primary">keyboard_arrow_right</mat-icon>
        </div>
      </button>
      <div class="mat-body-s" style="padding-left: 16px">
        Number of Files: {{ numAddedFiles }}
      </div>
    </div>
  </div>
  <!-- <button (click)="test()">Test</button> -->
  <div fxFlex="72px" class="mt-4 px-[6px] pb-[6px]">
    <button
      type="submit"
      class="full-button-footer h-[72px] rounded-md font-rajdhani"
      mat-raised-button
      color="primary"
      [disabled]="isCreateBtnDisabled"
      (click)="onSubmitTicket()"
    >
      <mat-icon class="h-7 w-7" svgIcon="create ticket"></mat-icon>
      Create Ticket
    </button>
  </div>
</div>
