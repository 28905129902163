import { Output, SimpleChanges, EventEmitter } from "@angular/core";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { inputs } from "@syncfusion/ej2-angular-schedule/src/schedule/schedule.component";
import { Subscription } from "rxjs";
import { debounceTime, min } from "rxjs/operators";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { UtilocateTokenService } from "src/app/modules/core/services/token/token.service";
import { isNumber } from "src/app/modules/core/validators/validator";
import { SchedulerTicketDialogComponent } from "src/app/modules/scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component";
import { Ticket } from "src/app/modules/scheduler/syncfusion-scheduler/schedulerTypes";
import { FAMItem } from "../../../fab/floating-action-menu/floating-action-menu.component";
import { FormTemplateView } from "../../../forms/form-input-template/form-input-template.component";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";
import { ConfirmationModalComponent } from "../../../ticket/modals/generic-confirmation-modal/confirmation-modal.component";
import { LocateStatusID } from "../../ticket-details.module";
import { TicketDetailsService } from "../../ticket-details.service";

@Component({
  selector: "app-ticket-completions-details",
  templateUrl: "./ticket-detail-completions.component.html",
  styleUrls: ["./ticket-detail-completions.component.scss"],
})
export class TicketCompletionsDetailsComponent
  extends BasePageComponent
  implements OnInit, OnChanges
{
  className = "TicketCompletionsDetailsComponent";
  subscription = Subscription;

  @Input() props;
  @Input() ticketProtectionMode = false;
  @Input() allowEditingCompletionDetails: boolean;
  @Output() syncAfterAction = new EventEmitter<any>();
  @Output() formGroupChanged = new EventEmitter<any>();
  @Output() markAsCompleteEvent = new EventEmitter<any>();

  ticket: any = null;
  PrimaryID: any = null;
  AssignmentID: any = null;

  tbCompletions_AuxiliaryDetails: any;
  tbCompletions_Billing: any;
  tbCompletions_PrimaryDetails: any;
  tbCompletions_Primary: any;
  tbCompletions_CommonDetails: any;

  primaryViews: any;
  commonViews: any;
  billingViews: any;

  disableFabMenu: boolean = false;
  hasCommon: boolean = false;
  hasPrimary: boolean = false;
  hasBilling: boolean = false;

  primaryFormProps: any = {};
  commonFormProps: any;
  billingFormProps: any;

  isU2TicketComplete: any;
  canCompleteTicket: any;

  fabMenu: FAMItem[];
  completedfabMenu: FAMItem[];

  watch: boolean = false;

  formChangeSubscription: Subscription;

  constructor(
    logger$: LoggerService,
    private ticketDetails$: TicketDetailsService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private routerService: Router,
    private tokenService: UtilocateTokenService
  ) {
    super(logger$);
    this.routerService.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    //Removed ongoing as few issues with it
    // {
    //   label: 'Mark as Ongoing',
    //   icon: 'date_range',
    //   action: this.markOngoing
    // },

    this.fabMenu = [
      {
        label: "Mark as Complete",
        icon: "check",
        action: this.markCompleteClicked,
      },
    ];

    this.completedfabMenu = [];
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.props && changes.props.currentValue) {
      if (changes.props.currentValue.PrimaryID != null) {
        this.PrimaryID = changes.props.currentValue.PrimaryID;
      }

      if (changes.props.currentValue.AssignmentID) {
        this.AssignmentID = changes.props.currentValue.AssignmentID;
      }

      if (changes.props.currentValue.canCompleteTicket != null) {
        this.canCompleteTicket = changes.props.currentValue.canCompleteTicket;
      }

      if (changes.props.currentValue.isU2TicketComplete != null) {
        this.isU2TicketComplete = changes.props.currentValue.isU2TicketComplete;
      }

      if (
        changes.props.currentValue.tbCompletions_AuxiliaryDetails &&
        changes.props.currentValue.tbCompletions_Billing &&
        changes.props.currentValue.tbCompletions_PrimaryDetails &&
        changes.props.currentValue.tbCompletions_Primary &&
        changes.props.currentValue.tbCompletions_CommonDetails &&
        changes.props.currentValue.ticket
      ) {
        this.tbCompletions_AuxiliaryDetails =
          changes.props.currentValue.tbCompletions_AuxiliaryDetails;
        this.tbCompletions_Billing =
          changes.props.currentValue.tbCompletions_Billing;
        this.tbCompletions_PrimaryDetails =
          changes.props.currentValue.tbCompletions_PrimaryDetails;
        this.tbCompletions_Primary =
          changes.props.currentValue.tbCompletions_Primary;
        this.tbCompletions_CommonDetails =
          changes.props.currentValue.tbCompletions_CommonDetails;
        this.ticket = changes.props.currentValue.ticket;

        this.setupCompletionDetails();
        this.disableFab();
      }
    }

    if (changes.ticketProtectionMode != null) {
      this.checkTicketProtection();
    }
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
      console.log("Destroying... unsubscribe");
    }
  }

  // disables fab if viewing non active ticket
  // Show if review required status
  disableFab() {
    if (
      this.tbCompletions_Primary[0]["DateCompleted"] &&
      !this.isU2TicketComplete
    ) {
      if (this.ticket["LocateStatusID"] != LocateStatusID.REVIEW_REQUIRED) {
        this.disableFabMenu = true;
      }
    }
  }

  async setupCompletionDetails() {
    try {
      // get the utilities
      let d1 = new Date();
      let utilities = this.ticketDetails$.getUtilityIDsFromAux(
        this.tbCompletions_AuxiliaryDetails,
        this.PrimaryID
      );
      let utilityRows = await this.ticketDetails$.getUtilityInfo(utilities);
      let primaryCatRows = await this.ticketDetails$.getPrimaryDetailCategories(
        utilityRows
      );
      let primaryFields = await this.ticketDetails$.getPrimaryDetailFields();
      let primaryFieldOptions =
        await this.ticketDetails$.getPrimaryFieldOptions();

      this.primaryViews = await this.setupPrimaryCompletionDetails(
        utilityRows,
        primaryCatRows.Primary,
        primaryFields,
        primaryFieldOptions
      );

      if (this.primaryViews && this.primaryViews.length > 0) {
        for (var viewIndex in this.primaryViews) {
          this.startListenForFormChanges(
            this.primaryViews[viewIndex]["formGroup"]
          );
        }
      }

      this.billingViews = await this.setupBillingCompletionDetails(utilityRows);
      if (this.billingViews && this.billingViews.length > 0) {
        this.startListenForFormChanges(this.billingViews[0]["formGroup"]);

        this.hasBilling = true;
        this.billingFormProps = {
          ...this.billingFormProps,
          categoryLinks: [0],
          view: this.billingViews[0],
          views: this.billingViews,
          ticket: this.ticket,
        };
        this.createFormattedBillingFormData(
          this.billingFormProps.view.formGroup,
          this.tbCompletions_Billing
        );
      }

      if (this.tbCompletions_CommonDetails.length > 0) {
        this.commonViews = await this.setupCommonCompletionDetails(
          primaryCatRows.Common,
          primaryFields,
          primaryFieldOptions
        );
        console.log(this.commonViews);

        if (this.commonViews && this.commonViews.length > 0) {
          this.startListenForFormChanges(this.commonViews[0]["formGroup"]);

          this.hasCommon = true;
          this.commonFormProps = {
            ...this.commonFormProps,
            categoryLinks: [0],
            view: this.commonViews[0],
            views: this.commonViews,
            ticket: this.ticket,
          };

          this.createFormattedFormData(
            this.commonFormProps.view.formGroup,
            this.tbCompletions_CommonDetails,
            "CommonDetailsFieldID"
          );
        }
      }

      for (var viewIndex in this.primaryViews) {
        if (this.checkFormGroupValidity(this.primaryViews[viewIndex])) {
          this.hasPrimary = true;

          this.primaryFormProps[this.primaryViews[viewIndex].key] = {
            categoryLinks: [0],
            view: this.primaryViews[viewIndex],
            views: this.primaryViews,
            ticket: this.ticket,
          };
        }
        this.createFormattedFormData(
          this.primaryViews[viewIndex].formGroup,
          this.tbCompletions_PrimaryDetails,
          "PrimaryDetailsFieldID"
        );
        console.log(this.primaryViews[viewIndex].formGroup);
      }

      this.checkTicketProtection();
    } catch (error) {}
  }

  checkTicketProtection() {
    if (
      this.commonFormProps &&
      this.commonFormProps.view &&
      this.commonFormProps.view.formGroup
    ) {
      if (
        this.ticketProtectionMode ||
        (this.isU2TicketComplete && !this.allowEditingCompletionDetails)
      ) {
        this.commonFormProps.view.formGroup.disable();
      } else {
        this.commonFormProps.view.formGroup.enable();
      }
    }

    if (
      this.billingFormProps &&
      this.billingFormProps.view &&
      this.billingFormProps.view.formGroup
    ) {
      if (
        this.ticketProtectionMode ||
        (this.isU2TicketComplete && !this.allowEditingCompletionDetails)
      ) {
        this.billingFormProps.view.formGroup.disable();
      } else {
        this.billingFormProps.view.formGroup.enable();
      }
    }

    if (
      this.primaryFormProps &&
      Object.keys(this.primaryFormProps).length > 0
    ) {
      for (const key in this.primaryFormProps) {
        if (
          this.ticketProtectionMode ||
          (this.isU2TicketComplete && !this.allowEditingCompletionDetails)
        ) {
          this.primaryFormProps[key].view.formGroup.disable();
        } else {
          this.primaryFormProps[key].view.formGroup.enable();
        }
      }
    }
  }

  async setupPrimaryCompletionDetails(
    utilityRows,
    primaryCatRows,
    primaryFields,
    primaryFieldOptions
  ) {
    try {
      let primaryCatIDs = [];
      let primaryFieldRows = [];
      if (primaryCatRows && primaryCatRows.length > 0) {
        primaryCatRows.forEach((row) => {
          // console.log(primaryFieldRows);
          // console.log(primaryFields[row["PrimaryDetailCategoryID"]]);

          primaryFieldRows = [
            ...primaryFieldRows,
            ...primaryFields[row["PrimaryDetailCategoryID"]],
          ];
          primaryCatIDs.push(row["PrimaryDetailCategoryID"]);
        });
      }

      let fieldRules = await this.ticketDetails$.getPrimaryDetailFieldRules(
        primaryCatIDs
      );

      let utilityDetailViews = this.ticketDetails$.convertUtilityToFormTemplate(
        utilityRows,
        primaryCatRows,
        primaryFieldRows,
        fieldRules,
        this.tbCompletions_PrimaryDetails,
        "tbCompletions_PrimaryDetails",
        primaryFieldOptions
      );

      let utilityViewsArr = [];
      if (Object.keys(utilityDetailViews.views).length > 0) {
        for (let viewKey in utilityDetailViews.views) {
          let view: FormTemplateView = utilityDetailViews.views[viewKey];
          let viewsObj = await this.ticketDetails$.generateFormGroup(view);
          if (viewsObj) {
            utilityViewsArr.push(viewsObj);
          }
        }
      }

      return utilityViewsArr;
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  async setupCommonCompletionDetails(
    primaryCatRows,
    primaryFields,
    primaryFieldOptions
  ) {
    try {
      //add common utility type
      let utilityRows = [
        {
          Archived: 0,
          CompanyID: null,
          FooterText: "",
          HeaderText: "",
          LocatingForGroupID: 0,
          LogoFile: "",
          UtilityDesc: "Ticket Details",
          UtilityID: 0,
          UtilityName: "Ticket Details",
          UtilityType: 0,
          usesPrimary: 0,
        },
      ];

      let primaryCatIDs = [];
      let primaryFieldRows = [];
      if (primaryCatRows && primaryCatRows.length > 0) {
        primaryCatRows.forEach((row) => {
          primaryFieldRows = [
            ...primaryFieldRows,
            ...primaryFields[row["PrimaryDetailCategoryID"]],
          ];
          primaryCatIDs.push(row["PrimaryDetailCategoryID"]);
        });
      }

      let fieldRules = await this.ticketDetails$.getPrimaryDetailFieldRules(
        primaryCatIDs
      );

      let utilityDetailViews = this.ticketDetails$.convertUtilityToFormTemplate(
        utilityRows,
        primaryCatRows,
        primaryFieldRows,
        fieldRules,
        this.tbCompletions_PrimaryDetails,
        "tbCompletions_CommonDetails",
        primaryFieldOptions
      );

      let utilityViewsArr = [];
      if (Object.keys(utilityDetailViews.views).length > 0) {
        for (let viewKey in utilityDetailViews.views) {
          let view: FormTemplateView = utilityDetailViews.views[viewKey];
          let viewsObj = await this.ticketDetails$.generateFormGroup(view);
          if (viewsObj) {
            utilityViewsArr.push(viewsObj);
          }
        }
      }
      return utilityViewsArr;
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  async setupBillingCompletionDetails(utilityRows) {
    try {
      let billingCatIDs = [];

      // go through billing and get all UtilityBillingCatID
      if (this.tbCompletions_Billing) {
        this.tbCompletions_Billing.forEach((billingRow) => {
          billingCatIDs.push(billingRow["UtilityBillingCatID"]);
        });
      }

      // console.log(this.tbCompletions_Billing)
      let utilityBillingCatRows =
        await this.ticketDetails$.getUtilityBilingCategories(billingCatIDs);

      //use catids to get field name and type.
      let utilityDetailViews = this.ticketDetails$.convertBillingToFormTemplate(
        utilityRows,
        utilityBillingCatRows,
        this.tbCompletions_AuxiliaryDetails,
        this.tbCompletions_Billing,
        this.PrimaryID
      );

      let billingViewsArr = [];
      if (Object.keys(utilityDetailViews.views).length > 0) {
        for (let viewKey in utilityDetailViews.views) {
          let view: FormTemplateView = utilityDetailViews.views[viewKey];
          let viewsObj = await this.ticketDetails$.generateFormGroup(view);
          if (viewsObj) {
            billingViewsArr.push(viewsObj);
          }
        }
      }

      return billingViewsArr;
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  startListenForFormChanges(formGroup: any) {
    try {
      this.formChangeSubscription = formGroup.valueChanges
        .pipe(debounceTime(2000))
        .subscribe(() => {
          // check if first load
          let isPristine = formGroup.pristine;
          if (!isPristine) {
            // only update when not first load
            this.formGroupChanged.emit({ saved: true });
            this.updateCompletionsData();
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async completionsUpdate(event) {
    try {
      let affectedGroupID = event[0];
      let visiblePrimaryDetailIDs = event[1][0];
      let hiddenPrimaryDetailIDs = event[1][1];

      // only search through detailIDs from same group
      // this prevents overwriting in other groups
      let affectedGroup = this.primaryViews[0]["groups"][affectedGroupID];

      if (affectedGroup && affectedGroup["fields"]) {
        let groupFieldKeys = Object.keys(affectedGroup["fields"]);
        for (let i = 0; i < this.tbCompletions_PrimaryDetails.length; i++) {
          // double check to prevent overwrite
          let curPrimaryDetailsID =
            this.tbCompletions_PrimaryDetails[i]["PrimaryDetailsFieldID"];
          if (groupFieldKeys.includes(curPrimaryDetailsID.toString())) {
            if (visiblePrimaryDetailIDs.includes(curPrimaryDetailsID)) {
              // if field is already visible + has a value, do not reset
              if (
                this.primaryViews[0].formGroup.value[
                  this.tbCompletions_PrimaryDetails[i]["PrimaryDetailsFieldID"]
                ] == null ||
                this.primaryViews[0].formGroup.value[
                  this.tbCompletions_PrimaryDetails[i]["PrimaryDetailsFieldID"]
                ] == undefined
              ) {
                this.primaryViews[0].formGroup.patchValue({
                  [this.tbCompletions_PrimaryDetails[i][
                    "PrimaryDetailsFieldID"
                  ]]: "",
                });
              }

              this.tbCompletions_PrimaryDetails[i]["isUpdated"] = 1;
            } else if (hiddenPrimaryDetailIDs.includes(curPrimaryDetailsID)) {
              // hide and reset value
              this.primaryViews[0].formGroup.patchValue({
                [this.tbCompletions_PrimaryDetails[i]["PrimaryDetailsFieldID"]]:
                  "",
              });
              this.tbCompletions_PrimaryDetails[i]["isUpdated"] = 0;
            }
          }
        }

        await this.updateCompletionsData();
        this.formGroupChanged.emit({ saved: true });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async updateCompletionsData() {
    if (this.commonViews && this.commonViews.length > 0) {
      try {
        await this.ticketDetails$.updateSavedCompletionsDataToLocalDb(
          this.commonViews[0],
          this.tbCompletions_CommonDetails,
          "CommonDetailsFieldID",
          "tbCompletions_CommonDetails",
          this.AssignmentID
        );
      } catch (error) {
        console.error(error);
      }
    }

    // primary
    if (this.primaryViews && this.primaryViews.length > 0) {
      // eslint-disable-next-line guard-for-in
      for (const primaryIndex in this.primaryViews) {
        try {
          await this.ticketDetails$.updateSavedCompletionsDataToLocalDb(
            this.primaryViews[primaryIndex],
            this.tbCompletions_PrimaryDetails,
            "PrimaryDetailsFieldID",
            "tbCompletions_PrimaryDetails",
            this.AssignmentID
          );
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (this.billingViews && this.billingViews.length > 0) {
      try {
        await this.ticketDetails$.updateSavedCompletionsDataToLocalDb(
          this.billingViews[0],
          this.tbCompletions_Billing,
          "BillingID",
          "tbCompletions_Billing",
          this.AssignmentID
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  //this will validate whether the form group can load based on its group
  //should not have default field.
  //field count should == control count.
  checkFormGroupValidity(view) {
    // return true;
    let isValid = true;
    try {
      let controlsLen = Object.keys(view.formGroup.controls).length;
      let fieldLen = 0;
      if (view.groups["defaultGroup"]) {
        isValid = false;
        if (view.groups["defaultGroup"]["isEmpty"]) {
          //empty so dont error
          fieldLen = 1;
        } else {
          throw new Error(
            view.header + ": Missing Categories. Unable to load all fields"
          );
        }
      } else {
        for (let groupKey in view.groups) {
          fieldLen =
            fieldLen + Object.keys(view.groups[groupKey].fields).length;
        }
      }

      if (controlsLen != fieldLen) {
        isValid = false;
        throw new Error(
          controlsLen +
            "," +
            fieldLen +
            "," +
            view.header +
            ": Missing Fields. Unable to load all fields"
        );
      }
    } catch (error) {
      this.loggerService.error(error);
      this.snackBarService.openSnackbar(error, SnackbarType.error);
    }
    return isValid;
  }

  //patch values.
  createFormattedBillingFormData(formGroup, data) {
    try {
      let auxIDs = [];
      auxIDs = this.getAuxIDsFromAux();

      if (this.PrimaryID) {
        let formattedData = {};
        data.forEach((row) => {
          if (auxIDs.includes(row["AuxiliaryDetailID"])) {
            if (row["FieldValue"]) {
              if (isNumber(row["FieldValue"])) {
                formattedData[row["BillingID"]] = parseFloat(row["FieldValue"]);
              } else {
                formattedData[row["BillingID"]] = row["FieldValue"];
              }
            } else {
              formattedData[row["BillingID"]] = row["FieldValue"];
            }
          }
        });
        formGroup.patchValue(formattedData);
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  getAuxIDsFromAux() {
    let response = [];
    try {
      if (
        this.tbCompletions_AuxiliaryDetails &&
        this.tbCompletions_AuxiliaryDetails.length > 0
      ) {
        this.tbCompletions_AuxiliaryDetails.sort((a, b) => {
          return b["UtilityID"] - a["UtilityID"];
        });

        this.tbCompletions_AuxiliaryDetails.forEach((auxRow) => {
          if (auxRow["PrimaryID"] == this.PrimaryID) {
            response.push(auxRow.AuxiliaryDetailID);
          }
        });
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
    return response;
  }

  // patch values.
  createFormattedFormData(formGroup, data, key) {
    try {
      // get current primary fields
      if (this.PrimaryID) {
        let formattedData = {};

        data.forEach((row) => {
          if (row["PrimaryID"] == this.PrimaryID) {
            // if a value already exists, format it before displaying
            if (row["FieldValue"]) {
              if (/^\d\d:\d\d$/.test(row["FieldValue"])) {
                formattedData[row[key]] = this.formatTime(row["FieldValue"]);
              } else if (isNumber(row["FieldValue"])) {
                formattedData[row[key]] = parseFloat(row["FieldValue"]);
              } else {
                formattedData[row[key]] = row["FieldValue"];
              }
            } else {
              formattedData[row[key]] = row["FieldValue"];
            }
          }
        });

        formGroup.patchValue(formattedData);
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  formatTime(time: string) {
    let formattedTime = time;
    let timeParts = time.split(":");

    if (timeParts.length == 2) {
      let hours = parseInt(timeParts[0]);
      let minutes: number | string = parseInt(timeParts[1]);

      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        let timeOfDay = "AM";

        if (hours >= 12) {
          timeOfDay = "PM";
          if (hours > 12) {
            hours = hours - 12;
          }
        } else if (hours == 0) {
          hours = 12;
        }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }

        formattedTime = hours + ":" + minutes + " " + timeOfDay;
      }
    }

    return formattedTime;
  }

  // Actions

  markCompleteClicked = () => {
    this.onMarkAsComplete(20);
  };

  onMarkAsComplete(locateStatusID: any) {
    this.markAsCompleteEvent.emit(locateStatusID);
  }
}
