import { Component, Inject, NgModule, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MaterialModule } from "src/app/modules/shared/material.module";
import { SchedulerOptionsDialogComponent } from "../scheduler-options-dialog/scheduler-options-dialog.component";

@Component({
  selector: "app-scheduler-edit-event-options",
  templateUrl: "./scheduler-edit-event-options.component.html",
  styleUrls: ["./scheduler-edit-event-options.component.css"],
})
export class SchedulerEditEventOptionsComponent {
  close = 0;
  editDay = 1;
  editSeries = 2;

  constructor(
    public dialogRef: MatDialogRef<SchedulerOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

@NgModule({
  declarations: [SchedulerEditEventOptionsComponent],
  imports: [MaterialModule, FlexLayoutModule],
  exports: [],
})
export class SchedulerEditEventOptionsModule {}
