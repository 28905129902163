import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { ApiService, UtilocateApiRequest } from "../core/api/baseapi.service";
import { LoggerService } from "../core/services/logger/logger.service";
import {
  CreateTicketComponentService,
  OptionsFillID,
} from "../create-ticket/create-ticket-component.service";
import { SnackbarService } from "../shared/snackbar/snackbar.service";
import { SnackbarType } from "../shared/snackbar/snackbar/snackbar";
import { RateNode, SelectedNode } from "./rate-node";
import { RateType } from "./rate-type";

export enum ActionID {
  LOAD = 1,
  SAVE = 2,
}

export enum NodeActionID {
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
  COPY = 4,
}
@Injectable({
  providedIn: "root",
})
export class RatesService {
  private subject = new BehaviorSubject<RateNode[]>([]);
  treeNodes$: Observable<RateNode[]> = this.subject.asObservable();

  criterias: any[];
  ratesNodes: RateNode[];
  treeNodes: RateNode[];

  //defining rateTypes with ID and Names:
  rateTypes: RateType[] = [
    {
      RateTypeID: 1,
      RateTypeName: "Billing",
    },
    {
      RateTypeID: 2,
      RateTypeName: "Incentive",
    },
  ];

  constructor(
    private loggerService: LoggerService,
    private snackbarService: SnackbarService,
    private utilocateApiService: ApiService,
    private createticketService: CreateTicketComponentService
  ) {}

  callRatesNodesAPI(value: any) {
    const url = apiKeys.u2.ratesNodes;
    const type = api[url].type;

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  async refreshNodes(rateTypeID: number, utilityIDs = []) {
    await this.getRatesNodesAndCriterias(rateTypeID, utilityIDs);
  }

  clearTree() {
    this.subject.next(null);
  }

  /**
   * Getter for rateTypeIDs
   * @returns rateTypeIDs i.e. [1,2]
   */
  getRateTypeIDs(): number[] {
    let rateTypeIDs: number[] = [];
    try {
      rateTypeIDs.push(this.rateTypes[0]["RateTypeID"]);
      rateTypeIDs.push(this.rateTypes[1]["RateTypeID"]);
    } catch (error) {
      console.error(`Error getting rateTypeIDs: ${error.message}`);
    }
    return rateTypeIDs;
  }

  //TODO: refactor API and this function to separte nodes and criterias
  /**
   * call the api and parse the result into treeNodes and criterias
   * @param rateTypeID the ID of the rate type to load (Billing, Incentive, etc)
   * @returns the criterias array from the api result (for now)
   */
  async getRatesNodesAndCriterias(rateTypeID: number, utilityIDs = []) {
    let result = await this.getRatesNodesAndCriteriasFromAPI(
      rateTypeID,
      utilityIDs
    ).toPromise();
    if (result && result.body) {
      // console.log("result:", result)
      if (result.body.Error) {
        this.snackbarService.openSnackbar(
          result.body.Error,
          SnackbarType.error
        );
      } else if (result.body.value) {
        let value = JSON.parse(result.body.value);
        this.criterias = value.criterias;
        this.ratesNodes = value.rateNodes;
        this.formatTreeNodes();
        //(a: RateNode, b: RateNode) => (a.readableValues > b.readableValues) ? 1 : -1// inline soln for sorting
        // this.treeNodes.sort(this.sortTreeNodesAlphabeticallyComparison);
        this.subject.next(this.treeNodes);
        return value.criterias;
      } else {
        this.snackbarService.openSnackbar(
          "Failed to get RatesNodes",
          SnackbarType.error
        );
      }
    }
  }

  /**
   * call the RatesNodes api to run the lambda with a load action
   * @param rateTypeID the ID of the rate type to load (Billing, Incentive, etc)
   * @returns api result from RatesNodes lambda
   */
  getRatesNodesAndCriteriasFromAPI(rateTypeID: number, utilityIDs = []) {
    const url = apiKeys.u2.ratesNodes;
    const type = api[url].type;

    let value = {
      action: ActionID.LOAD,
      rateTypeID: rateTypeID,
      utilityIDs: utilityIDs,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(
      this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
    );
  }

  /**
   * loop through loaded RatesNodes and add extra data including children and readable values
   */
  formatTreeNodes() {
    try {
      if (this.ratesNodes) {
        let rootNodes: RateNode[] = [];
        for (let node of this.ratesNodes) {
          if (node.ParentNodeID == 0) {
            let treeNode: RateNode = {
              NodeID: node.NodeID,
              RateTypeID: node.RateTypeID,
              ParentNodeID: node.ParentNodeID,
              CriteriaTypeID: 1,
              ChildCriteriaTypeID: node.ChildCriteriaTypeID,
              Rate: node.Rate,
              RateName: node.RateName,
              Values: node.Values,
              Children: [],
              nodeTrace: [],
            };
            treeNode = this.addNodeDisplayData(treeNode);
            treeNode.nodeTrace = [
              {
                criteriaTypeID: treeNode.CriteriaTypeID,
                criteria: treeNode.criteriaName,
                values: treeNode.readableValues,
              },
            ];
            treeNode.Children = this.findAndAddChildren(
              node,
              this.ratesNodes,
              treeNode.nodeTrace
            );
            rootNodes.push(treeNode);
          }
        }
        // console.log("rootNodes:", rootNodes);
        this.treeNodes = rootNodes;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * recursively search and return child nodes formatted for tree
   * @param parentNode the parent node that children are being added to
   * @param nodes the full array of nodes to search through
   * @returns an array of child nodes
   */
  findAndAddChildren(parentNode, nodes, trace) {
    let childNodes: RateNode[] = [];
    try {
      let children = nodes.filter(
        (node) => node.ParentNodeID == parentNode.NodeID
      );
      if (children && children.length > 0) {
        for (let child of children) {
          let treeNode: RateNode = {
            NodeID: child.NodeID,
            RateTypeID: child.RateTypeID,
            ParentNodeID: parentNode.NodeID,
            CriteriaTypeID: parentNode.ChildCriteriaTypeID,
            ChildCriteriaTypeID: child.ChildCriteriaTypeID,
            Rate: child.Rate,
            RateName: child.RateName,
            Values: child.Values,
            Children: [],
            nodeTrace: [],
          };
          treeNode = this.addNodeDisplayData(treeNode);
          treeNode.nodeTrace = trace.concat({
            criteriaTypeID: treeNode.CriteriaTypeID,
            criteria: treeNode.criteriaName,
            values: treeNode.readableValues,
          });
          treeNode.Children = this.findAndAddChildren(
            child,
            nodes,
            treeNode.nodeTrace
          );
          childNodes.push(treeNode);
        }
      } else {
        childNodes = [];
      }
    } catch (error) {
      throw new Error(error);
    }
    return childNodes;
  }

  /**
   * add readable values and an icon name to a rate node to be displayed in the tree
   * @param node a single Rate Node to be updated
   * @returns updated rate node object
   */
  addNodeDisplayData(node: RateNode) {
    let updatedNode = node;
    try {
      let criteria = this.criterias.find(
        (criteriaObj) => criteriaObj.id == node.CriteriaTypeID
      );
      updatedNode.icon = "";
      updatedNode.readableValues = [];
      if (criteria) {
        if (criteria.icon) {
          updatedNode.icon = criteria.icon;
        }
        if (criteria.name) {
          updatedNode.criteriaName = criteria.name;
        }
        for (let value of node.Values) {
          let criteriaValue = criteria.values.find(
            (valueObj) => valueObj.id == value.Value
          );
          if (criteriaValue) {
            updatedNode.readableValues.push(criteriaValue.name);
          }
        }
      }
    } catch (error) {
      throw new Error(error);
    }
    return updatedNode;
  }

  /**
   * Helps to compare two rateNode Objects based on their readableValues property
   * @param treeNodeObjA :RateNode
   * @param treeNodeObjB :RateNode
   * @returns a key: number
   */
  sortTreeNodesAlphabeticallyComparison(
    treeNodeObjA: RateNode,
    treeNodeObjB: RateNode
  ): number {
    try {
      let SortKey: number = 0;
      if (treeNodeObjA.readableValues < treeNodeObjB.readableValues) {
        SortKey = -1;
      }
      if (treeNodeObjA.readableValues > treeNodeObjB.readableValues) {
        SortKey = 1;
      }
      return SortKey;
    } catch (error) {
      console.error(`Sorting Problem ${error.message}`);
    }
  }

  /**
   *
   * @param nodes array of rate nodes to search
   * @param nodeID id of node being searched for
   * @returns foundNode
   */
  findNestedNode(nodeID: number, nodes: RateNode[] = this.treeNodes) {
    try {
      if (nodes) {
        let foundNode = nodes.find((node) => node.NodeID == nodeID);
        if (foundNode) {
          return foundNode;
        } else {
          for (let node of nodes) {
            if (node.Children && node.Children.length > 0) {
              let foundChild = this.findNestedNode(nodeID, node.Children);
              if (foundChild) {
                return foundChild;
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getNodeSiblingValues(node) {
    let siblingValues = [];
    try {
      let parentNode = this.findNestedNode(node.ParentNodeID);
      if (parentNode) {
        let siblings = parentNode.Children.filter(
          (child) => child.NodeID != node.NodeID
        );
        for (let sibling of siblings) {
          siblingValues = siblingValues.concat(sibling.Values);
        }
      }
    } catch (error) {
      console.error("getNodeSiblingValues:", error);
    }
    return siblingValues;
  }

  /**
   * insert a new node via api call to RatesNodes lambda
   * @param parentNode the parent of the new node
   * @param rateTypeID the rate type ID that is currently active
   * @returns api result containing the inserted node's info
   */
  async insertNewNode(
    parentNode: SelectedNode,
    rateTypeID: number,
    utilityIDs = []
  ) {
    try {
      let newNode = {
        nodeAction: NodeActionID.CREATE,
        RateTypeID: rateTypeID,
        ParentNodeID: parentNode.NodeID,
        ChildCriteriaTypeID: 0,
        Rate: 0,
        RateName: '',
        Values: [],
      };
      let apiValue = {
        action: ActionID.SAVE,
        nodes: [newNode],
      };
      let apiResult = await this.callRatesNodesAPI(apiValue).toPromise();
      if (apiResult) {
        // console.log("insertNewNode: apiResult", apiResult);
        await this.refreshNodes(rateTypeID, utilityIDs);
        return JSON.parse(apiResult.body.value).nodes;
      } else {
        throw new Error("insertNewNode: apiResult null");
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * setup an API call to paste a group of child nodes to the specified parentNode
   * @param parentNode node that children are being pasted to
   * @param copiedNode the copied node with children to be pasted
   * @param rateTypeID current rateTypeID for all the nodes
   * @param utilityIDs utlityIDs used for refreshing the loaded nodes and keep the same utility selection
   * @returns the nodes returned by RatesNodes API call
   */
  async pasteCopiedNode(
    parentNode: SelectedNode,
    copiedNode: SelectedNode,
    rateTypeID: number,
    utilityIDs = []
  ) {
    try {
      let newNode = {
        nodeAction: NodeActionID.COPY,
        NodeID: parentNode.NodeID,
        RateTypeID: rateTypeID,
        ParentNodeID: parentNode.ParentNodeID,
        ChildCriteriaTypeID: copiedNode.ChildCriteriaTypeID,
        Rate: parentNode.Rate,
        RateName: parentNode.RateName,
        Values: [],
        Children: copiedNode.Children,
      };
      let apiValue = {
        action: ActionID.SAVE,
        nodes: [newNode],
      };
      let apiResult = await this.callRatesNodesAPI(apiValue).toPromise();
      if (apiResult) {
        // console.log("pasteCopiedNode: apiResult", apiResult);
        await this.refreshNodes(rateTypeID, utilityIDs);
        return JSON.parse(apiResult.body.value).nodes;
      } else {
        throw new Error("pasteCopiedNode: apiResult null");
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * update a node (and its parent if necessary) via api call to RatesNodes lambda
   * @param node the node being updated
   * @param rateTypeID the rate type ID that is currently active
   * @returns api result containing the updated node(s) info
   */
  async saveNode(node: SelectedNode, rateTypeID: number, utilityIDs = []) {
    try {
      let nodesToSave = [];
      let nodeToSave = {
        nodeAction: NodeActionID.UPDATE,
        NodeID: node.NodeID,
        RateTypeID: rateTypeID,
        ParentNodeID: node.ParentNodeID,
        ChildCriteriaTypeID: node.ChildCriteriaTypeID,
        Rate: node.Rate,
        RateName: node.RateName,
        Values: node.Values,
      };
      nodesToSave.push(nodeToSave);
      if (node.CriteriaTypeID) {
        let parentNode = this.ratesNodes.find(
          (rateNode) => rateNode.NodeID == node.ParentNodeID
        );
        if (parentNode.ChildCriteriaTypeID != node.CriteriaTypeID) {
          let parentNodeToUpdate = {
            nodeAction: NodeActionID.UPDATE,
            NodeID: parentNode.NodeID,
            RateTypeID: rateTypeID,
            ParentNodeID: parentNode.ParentNodeID,
            ChildCriteriaTypeID: node.CriteriaTypeID,
            Rate: parentNode.Rate,
            RateName: parentNode.RateName,
            Values: [],
          };
          nodesToSave.push(parentNodeToUpdate);
        }
      }
      let apiValue = {
        action: ActionID.SAVE,
        nodes: nodesToSave,
      };
      let apiResult = await this.callRatesNodesAPI(apiValue).toPromise();
      if (apiResult) {
        // console.log("apiResult", apiResult);
        await this.refreshNodes(rateTypeID, utilityIDs);
        return JSON.parse(apiResult.body.value).nodes;
      } else {
        throw new Error("saveNode - apiResult null");
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * delete a node and its children via API call to RatesNodes lambda
   * @param node node to delete
   * @param rateTypeID the rate type ID that is currently active
   * @returns deleted node info
   */
  async deleteNode(node: SelectedNode, rateTypeID: number, utilityIDs = []) {
    try {
      let nodeChanges = [];
      let nodeToDelete = {
        nodeAction: NodeActionID.DELETE,
        NodeID: node.NodeID,
      };
      nodeChanges.push(nodeToDelete);

      let childrenIDs = this.getChildrenIDs(node.NodeID);
      if (childrenIDs) {
        for (let childID of childrenIDs) {
          nodeChanges.push({
            nodeAction: NodeActionID.DELETE,
            NodeID: childID,
          });
        }
      }

      let parentNode = this.findNestedNode(node.ParentNodeID);
      if (parentNode.Children.length == 1) {
        nodeChanges.push({
          nodeAction: NodeActionID.UPDATE,
          NodeID: parentNode.NodeID,
          RateTypeID: rateTypeID,
          ParentNodeID: parentNode.ParentNodeID,
          ChildCriteriaTypeID: 0,
          Rate: parentNode.Rate,
          RateName: parentNode.RateName,
          Values: [],
        });
      }
      let apiValue = {
        action: ActionID.SAVE,
        nodes: nodeChanges,
      };
      let apiResult = await this.callRatesNodesAPI(apiValue).toPromise();
      if (apiResult) {
        // console.log("apiResult", apiResult);
        await this.refreshNodes(rateTypeID, utilityIDs);
        return JSON.parse(apiResult.body.value).nodes;
      } else {
        throw new Error("saveNode - apiResult null");
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * recursively search children of a node and return all the ids
   * @param parentNodeID ID of node whose children we are looking for
   * @returns array of child nodeIDs
   */
  getChildrenIDs(parentNodeID: number) {
    let childrenIDs = [];
    try {
      let children = this.ratesNodes.filter(
        (node) => node.ParentNodeID == parentNodeID
      );
      for (let child of children) {
        childrenIDs.push(child.NodeID);
        childrenIDs = childrenIDs.concat(this.getChildrenIDs(child.NodeID));
      }
    } catch (error) {
      throw new Error(error);
    }
    return childrenIDs;
  }

  async getUtilities() {
    let utilities = [];
    try {
      let utilityResult = await this.createticketService.getFilterOptionsDB(
        OptionsFillID.utilities
      );
      utilities = utilityResult;
    } catch (error) {
      console.error(error);
    }
    return utilities;
  }
  // //need to get UtilityID but return utility type because that is what links to primaryCat
  // async getUtilityInfo(utilityID) {
  //   const where = { UtilityID: utilityID };

  //   let tbAdmin_UtilityResult = null;
  //   try {
  //     tbAdmin_UtilityResult = await this.admin$.getLookupTableRows(["tbAdmin_Utilities"], where);
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }

  //   if (tbAdmin_UtilityResult && tbAdmin_UtilityResult[0] && tbAdmin_UtilityResult[0]["rows"]) {
  //     return tbAdmin_UtilityResult[0]["rows"];
  //   } else {
  //     return false;
  //   }
  // }
}
