export interface CardActionOptions {
  name: string;
  action: Function;
  status?: CardActionStatus;
}

export interface CardIconOptions {
  name: CardIcon;
  action: Function;
}

export interface CardOptions {
  id: number;
  title: string;
  content: string;
  actions?: CardActionOptions[];
  icons?: CardIconOptions[];
}

export enum CardIcon {
  FAVOURITE = "star",
  NOT_FAVOURITE = "star_outline",
}

export enum CardActionStatus {
  LOADING = 1,
  ACCESSIBLE = 2,
  NOT_ACCESSIBLE = 3,
}
