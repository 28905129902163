import { Animation, Browser, ChildProperty, Collection, Complex, Component, Draggable, Event, EventHandler, HijriParser, Internationalization, KeyboardEvents, L10n, NotifyPropertyChanges, Property, SanitizeHtmlHelper, Touch, addClass, append, classList, cldrData, closest, compile, createElement, detach, extend, formatUnit, getDefaultDateObject, getElement, getValue, initializeCSPTemplate, isNullOrUndefined, prepend, print, remove, removeClass, setStyleAttribute } from '@syncfusion/ej2-base';
import { Dialog, Popup, Tooltip, createSpinner, hideSpinner, isCollide, showSpinner } from '@syncfusion/ej2-popups';
import { Toolbar, TreeView } from '@syncfusion/ej2-navigations';
import { Calendar, DatePicker, DateTimePicker } from '@syncfusion/ej2-calendars';
import { DataManager, Deferred, Predicate, Query } from '@syncfusion/ej2-data';
import { Button, CheckBox, RadioButton } from '@syncfusion/ej2-buttons';
import { FormValidator, Input, NumericTextBox } from '@syncfusion/ej2-inputs';
import { DropDownList, MultiSelect } from '@syncfusion/ej2-dropdowns';
import { ListBase } from '@syncfusion/ej2-lists';
import { Workbook } from '@syncfusion/ej2-excel-export';

/**
 * Constants
 */
/** @private */
var cellClick = 'cellClick';
/** @private */
var cellDoubleClick = 'cellDoubleClick';
/** @private */
var moreEventsClick = 'moreEventsClick';
/** @private */
var select = 'select';
/** @private */
var hover = 'hover';
/** @private */
var actionBegin = 'actionBegin';
/** @private */
var actionComplete = 'actionComplete';
/** @private */
var actionFailure = 'actionFailure';
/** @private */
var navigating = 'navigating';
/** @private */
var renderCell = 'renderCell';
/** @private */
var eventClick = 'eventClick';
/** @private */
var eventDoubleClick = 'eventDoubleClick';
/** @private */
var eventRendered = 'eventRendered';
/** @private */
var dataBinding = 'dataBinding';
/** @private */
var dataBound = 'dataBound';
/** @private */
var popupOpen = 'popupOpen';
/** @private */
var popupClose = 'popupClose';
/** @private */
var dragStart = 'dragStart';
/** @private */
var drag = 'drag';
/** @private */
var dragStop = 'dragStop';
/** @private */
var resizeStart = 'resizeStart';
/** @private */
var resizing = 'resizing';
/** @private */
var resizeStop = 'resizeStop';
/** @private */
var inlineClick = 'inlineClick';
/** @private */
var cellSelect = 'cellSelect';
/** @private */
var virtualScrollStart = 'virtualScrollStart';
/** @private */
var virtualScrollStop = 'virtualScrollStop';
/**
 * Specifies schedule internal events
 */
/** @private */
var initialLoad = 'initial-load';
/** @private */
var initialEnd = 'initial-end';
/** @private */
var print$1 = 'print';
/** @private */
var dataReady = 'data-ready';
/** @private */
var eventsLoaded = 'events-loaded';
/** @private */
var contentReady = 'content-ready';
/** @private */
var scroll = 'scroll';
/** @private */
var virtualScroll = 'virtual-scroll';
/** @private */
var scrollUiUpdate = 'scroll-ui-update';
/** @private */
var uiUpdate = 'ui-update';
/** @private */
var documentClick = 'document-click';
/** @private */
var cellMouseDown = 'cell-mouse-down';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Schedule common utilities
 */
var WEEK_LENGTH = 7;
var DEFAULT_WEEKS = 6;
var MS_PER_DAY = 86400000;
var MS_PER_MINUTE = 60000;
/**
 * Method to get height from element
 *
 * @param {Element} container Accepts the DOM element
 * @param {string} elementClass Accepts the element class
 * @returns {number} Returns the height of the element
 */
function getElementHeightFromClass(container, elementClass) {
    var height = 0;
    var el = createElement('div', { className: elementClass }).cloneNode();
    el.style.visibility = 'hidden';
    el.style.position = 'absolute';
    container.appendChild(el);
    height = el.getBoundingClientRect().height;
    remove(el);
    return height;
}
/**
 * Method to get width from element
 *
 * @param {Element} container Accepts the DOM element
 * @param {string} elementClass Accepts the element class
 * @returns {number} Returns the width of the element
 */
function getElementWidthFromClass(container, elementClass) {
    var width = 0;
    var el = createElement('div', { className: elementClass }).cloneNode();
    el.style.visibility = 'hidden';
    el.style.position = 'absolute';
    container.appendChild(el);
    width = el.getBoundingClientRect().width;
    remove(el);
    return width;
}
/**
 * Method to get translateY value
 *
 * @param {HTMLElement | Element} element Accepts the DOM element
 * @returns {number} Returns the translateY value of given element
 */
function getTranslateY(element) {
    var style = getComputedStyle(element);
    return window.WebKitCSSMatrix ?
        new WebKitCSSMatrix(style.webkitTransform).m42 : 0;
}
/**
 * Method to get translateX value
 *
 * @param {HTMLElement | Element} element Accepts the DOM element
 * @returns {number} Returns the translateX value of given element
 */
function getTranslateX(element) {
    var style = getComputedStyle(element);
    return window.WebKitCSSMatrix ?
        new WebKitCSSMatrix(style.webkitTransform).m41 : 0;
}
/**
 * Method to get week first date
 *
 * @param {Date} date Accepts the date object
 * @param {number} firstDayOfWeek Accepts the first day of week number
 * @returns {Date} Returns the date object
 */
function getWeekFirstDate(date, firstDayOfWeek) {
    var date1 = new Date(date.getTime());
    firstDayOfWeek = (firstDayOfWeek - date1.getDay() + 7 * (-1)) % 7;
    return new Date(date1.setDate(date1.getDate() + firstDayOfWeek));
}
/**
 * Method to get week last date
 *
 * @param {Date} date Accepts the date object
 * @param {number} firstDayOfWeek Accepts the first day of week number
 * @returns {Date} Returns the date object
 */
function getWeekLastDate(date, firstDayOfWeek) {
    var weekFirst = getWeekFirstDate(date, firstDayOfWeek);
    var weekLast = new Date(weekFirst.getFullYear(), weekFirst.getMonth(), weekFirst.getDate() + 6);
    return new Date(weekLast.getTime());
}
/**
 * Method to get first date of month
 *
 * @param {Date} date Accepts the date object
 * @returns {Date} Returns the date object
 */
function firstDateOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}
/**
 * Method to get last date of month
 *
 * @param {Date} date Accepts the date object
 * @returns {Date} Returns the date object
 */
function lastDateOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
/**
 * Method to get week number
 *
 * @param {Date} date Accepts the date object
 * @returns {number} Returns the week number
 */
function getWeekNumber(date) {
    var date1 = new Date(date.getFullYear(), 0, 1).valueOf();
    var currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();
    var dayOfYear = ((currentDate - date1 + MS_PER_DAY) / MS_PER_DAY);
    return Math.ceil(dayOfYear / 7);
}
/**
 * Method to get week middle date
 *
 * @param {Date} weekFirst Accepts the week first date object
 * @param {Date} weekLast Accepts the week last date object
 * @returns {Date} Returns the date object
 */
function getWeekMiddleDate(weekFirst, weekLast) {
    return new Date(weekLast.valueOf() - ((weekLast.valueOf() - weekFirst.valueOf()) / 2));
}
/**
 * Method to set time to date object
 *
 * @param {Date} date Accepts the date object
 * @param {number} time Accepts the milliseconds
 * @returns {Date} Returns the date object
 */
function setTime(date, time) {
    var tzOffsetBefore = date.getTimezoneOffset();
    var d = new Date(date.getTime() + time);
    var tzOffsetDiff = d.getTimezoneOffset() - tzOffsetBefore;
    date.setTime(d.getTime() + tzOffsetDiff * MS_PER_MINUTE);
    return date;
}
/**
 * Method the reset hours in date object
 *
 * @param {Date} date Accepts the date object
 * @returns {Date} Returns the date object
 */
function resetTime(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
/**
 * Method to get milliseconds from date object
 *
 * @param {Date} date Accepts the date object
 * @returns {number} Returns the milliseconds from date object
 */
function getDateInMs(date) {
    var localOffset = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTimezoneOffset();
    var dateOffset = date.getTimezoneOffset();
    var timezoneOffset = dateOffset - localOffset;
    return ((date.getTime() - new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime())
        - (timezoneOffset * 60 * 1000));
}
/**
 * Method to get date count between two dates
 *
 * @param {Date} startDate Accepts the date object
 * @param {Date} endDate Accepts the date object
 * @returns {number} Returns the date count
 */
function getDateCount(startDate, endDate) {
    return Math.ceil((endDate.getTime() - startDate.getTime()) / MS_PER_DAY);
}
/**
 * Method to add no of days in date object
 *
 * @param {Date} date Accepts the date object
 * @param {number} noOfDays Accepts the number of days count
 * @returns {Date} Returns the date object
 */
function addDays(date, noOfDays) {
    date = new Date('' + date);
    return new Date(date.setDate(date.getDate() + noOfDays));
}
/**
 * Method to add no of months in date object
 *
 * @param {Date} date Accepts the date object
 * @param {number} noOfMonths Accepts the number of month count
 * @returns {Date} Returns the date object
 */
function addMonths(date, noOfMonths) {
    date = new Date('' + date);
    var day = date.getDate();
    date.setDate(1);
    date.setMonth(date.getMonth() + noOfMonths);
    date.setDate(Math.min(day, getMaxDays(date)));
    return date;
}
/**
 * Method to add no of years in date object
 *
 * @param {Date} date Accepts the date object
 * @param {number} noOfYears Accepts the number of month count
 * @returns {Date} Returns the date object
 */
function addYears(date, noOfYears) {
    date = new Date('' + date);
    var day = date.getDate();
    date.setDate(1);
    date.setFullYear(date.getFullYear() + noOfYears);
    date.setDate(Math.min(day, getMaxDays(date)));
    return date;
}
/**
 * Method to get start and end hours
 *
 * @param {Date} date Accepts the date object
 * @param {Date} startHour Accepts the start hour date object
 * @param {Date} endHour Accepts the end hour date object
 * @returns {Object} Returns the start and end hour date objects
 */
function getStartEndHours(date, startHour, endHour) {
    var date1 = new Date(date.getTime());
    date1.setHours(startHour.getHours());
    date1.setMinutes(startHour.getMinutes());
    date1.setSeconds(startHour.getSeconds());
    var date2 = new Date(date.getTime());
    if (endHour.getHours() === 0) {
        date2 = addDays(date2, 1);
    }
    else {
        date2.setHours(endHour.getHours());
        date2.setMinutes(endHour.getMinutes());
        date2.setSeconds(endHour.getSeconds());
    }
    return { startHour: date1, endHour: date2 };
}
/**
 * Method to get month last date
 *
 * @param {Date} date Accepts the date object
 * @returns {number} Returns the month last date
 */
function getMaxDays(date) {
    return lastDateOfMonth(date).getDate();
}
/**
 * Method to get days count between two dates
 *
 * @param {Date} startDate Accepts the date object
 * @param {Date} endDate Accepts the date object
 * @returns {number} Returns the days count
 */
function getDaysCount(startDate, endDate) {
    var strTime = resetTime(new Date(startDate));
    var endTime = resetTime(new Date(endDate));
    return Math.round((endTime.getTime() - strTime.getTime()) / MS_PER_DAY);
}
/**
 * Method to get date object from date string
 *
 * @param {string} date Accepts the date string
 * @returns {Date} Returns the date object
 */
function getDateFromString(date) {
    return date.indexOf('Date') !== -1 ? new Date(parseInt(date.match(/\d+/g).toString(), 10)) :
        date.indexOf('T') !== -1 ? new Date(date) : new Date(date.replace(/-/g, '/'));
}
/** @private */
var scrollWidth = null;
/** @private */
var pixelRatio = null;
/**
 * Method to get scrollbar width
 *
 * @returns {number} Returns the scrollbar width
 * @private
 */
function getScrollBarWidth() {
    if (scrollWidth !== null) {
        return scrollWidth;
    }
    if (pixelRatio === null) {
        pixelRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    }
    var divNode = createElement('div');
    var value = 0;
    divNode.style.cssText = 'width:100px;height: 100px;overflow: scroll;position: absolute;top: -9999px;';
    document.body.appendChild(divNode);
    var ratio = (devicePixelRatio) ? (devicePixelRatio.toFixed(2) === '1.10' || devicePixelRatio <= 1) ?
        Math.ceil(devicePixelRatio % 1) : Math.floor(devicePixelRatio % 1) : 0;
    value = (divNode.offsetWidth - divNode.clientWidth - ratio) | 0;
    document.body.removeChild(divNode);
    return scrollWidth = value;
}
/**
 * Method to reset scrollbar width
 *
 * @private
 * @returns {void}
 */
function resetScrollbarWidth() {
    var zoomPixelRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (pixelRatio && pixelRatio !== zoomPixelRatio) {
        scrollWidth = null;
        pixelRatio = zoomPixelRatio;
    }
}
/**
 * Method to find the index from data collection
 *
 * @param {Object} data Accepts the data as object
 * @param {string} field Accepts the field name
 * @param {string} value Accepts the value name
 * @param {Object} event Accepts the data as object
 * @param {Object[]} resourceCollection Accepts the data collections
 * @returns {number} Returns the index number
 */
// eslint-disable-next-line max-len
function findIndexInData(data, field, value, event, resourceCollection) {
    for (var i = 0, length_1 = data.length; i < length_1; i++) {
        if (data[parseInt(i.toString(), 10)]["" + field] === value) {
            if (event) {
                var field_1 = resourceCollection.slice(-2)[0].field;
                var res = (event["" + field_1] instanceof Array ? event["" + field_1] : [event["" + field_1]]);
                var resData = res.join(',');
                if (resData.includes(data[parseInt(i.toString(), 10)][resourceCollection.slice(-1)[0].groupIDField])) {
                    return i;
                }
            }
            else {
                return i;
            }
        }
    }
    return -1;
}
/**
 * Method to get element outer height
 *
 * @param {HTMLElement} element Accepts the DOM element
 * @returns {number} Returns the outer height of the given element
 */
function getOuterHeight(element) {
    var style = getComputedStyle(element);
    return element.offsetHeight + (parseInt(style.marginTop, 10) || 0) + (parseInt(style.marginBottom, 10) || 0);
}
/**
 * Method to remove child elements
 *
 * @param {HTMLElement | Element} element Accepts the DOM element
 * @returns {void}
 */
function removeChildren(element) {
    var elementChildren = [].slice.call(element.children);
    for (var _i = 0, elementChildren_1 = elementChildren; _i < elementChildren_1.length; _i++) {
        var elementChild = elementChildren_1[_i];
        element.removeChild(elementChild);
    }
}
/**
 * Method to check DST is present or not in date object
 *
 * @param {Date} date Accepts the date object
 * @returns {boolean} Returns the boolean value for either DST is present or not
 */
function isDaylightSavingTime(date) {
    var jan = new Date(date.getFullYear(), 0, 1);
    var jul = new Date(date.getFullYear(), 6, 1);
    return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}
/**
 * Method to get UTC time value from date
 *
 * @param {Date} date Accepts the date
 * @returns {number} Returns the UTC time value
 */
function getUniversalTime(date) {
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var milliseconds = date.getMilliseconds();
    return Date.UTC(year, month, day, hours, minutes, seconds, milliseconds);
}
/**
 * Method to check the device
 *
 * @returns {boolean} Returns the boolean value for either device is present or not.
 */
function isMobile() {
    return window.navigator.userAgent.toLowerCase().indexOf('mobi') > -1;
}
/**
 * Method to check the IPad device
 *
 * @returns {boolean} Returns the boolean value for either IPad device is present or not.
 */
function isIPadDevice() {
    return window.navigator.userAgent.toLowerCase().indexOf('ipad') > -1;
}
/**
 * Method to capitalize the first word in string
 *
 * @param {string} inputString Accepts the string value
 * @param {string} type Accepts the string type
 * @returns {string} Returns the output string
 */
function capitalizeFirstWord(inputString, type) {
    if (type === 'multiple') {
        inputString = inputString.split(' ').map(function (e) { return e.charAt(0).toLocaleUpperCase() + e.substring(1); }).join(' ');
    }
    else if (type === 'single') {
        if (inputString[0] >= '0' && inputString[0] <= '9') {
            var array = inputString.match(/[a-zA-Z]/);
            inputString = isNullOrUndefined(array) ? inputString :
                inputString.slice(0, array.index) + inputString[array.index].toLocaleUpperCase() + inputString.slice(array.index + 1);
        }
        inputString = inputString[0].toLocaleUpperCase() + inputString.slice(1);
    }
    return inputString;
}

/**
 * CSS Constants
 */
/** @private */
var ROOT = 'e-schedule';
/** @private */
var RTL = 'e-rtl';
/** @private */
var DEVICE_CLASS = 'e-device';
/** @private */
var MULTI_DRAG = 'e-multi-drag';
/** @private */
var ICON = 'e-icons';
/** @private */
var ENABLE_CLASS = 'e-enable';
/** @private */
var DISABLE_CLASS = 'e-disable';
/** @private */
var TABLE_CONTAINER_CLASS = 'e-table-container';
/** @private */
var SCHEDULE_TABLE_CLASS = 'e-schedule-table';
/** @private */
var ALLDAY_CELLS_CLASS = 'e-all-day-cells';
/** @private */
var HEADER_POPUP_CLASS = 'e-header-popup';
/** @private */
var HEADER_CALENDAR_CLASS = 'e-header-calendar';
/** @private */
var ALLDAY_ROW_CLASS = 'e-all-day-row';
/** @private */
var CONTENT_TABLE_CLASS = 'e-content-table';
/** @private */
var WORK_CELLS_CLASS = 'e-work-cells';
/** @private */
var WORK_HOURS_CLASS = 'e-work-hours';
/** @private */
var POPUP_OPEN = 'e-popup-open';
/** @private */
var POPUP_CLOSE = 'e-popup-close';
/** @private */
var DATE_HEADER_WRAP_CLASS = 'e-date-header-wrap';
/** @private */
var DATE_HEADER_CONTAINER_CLASS = 'e-date-header-container';
/** @private */
var HEADER_CELLS_CLASS = 'e-header-cells';
/** @private */
var HEADER_WEEK_CELLS_CLASS = 'e-header-week-cell';
/** @private */
var HEADER_MONTH_CELLS_CLASS = 'e-header-month-cell';
/** @private */
var HEADER_YEAR_CELLS_CLASS = 'e-header-year-cell';
/** @private */
var WORKDAY_CLASS = 'e-work-days';
/** @private */
var OTHERMONTH_CLASS = 'e-other-month';
/** @private */
var CURRENT_DAY_CLASS = 'e-current-day';
/** @private */
var CURRENTDATE_CLASS = 'e-current-date';
/** @private */
var CURRENT_PANEL_CLASS = 'e-current-panel';
/** @private */
var PREVIOUS_PANEL_CLASS = 'e-previous-panel';
/** @private */
var NEXT_PANEL_CLASS = 'e-next-panel';
/** @private */
var PREVIOUS_DATE_CLASS = 'e-prev';
/** @private */
var NEXT_DATE_CLASS = 'e-next';
/** @private */
var TRANSLATE_CLASS = 'e-translate';
/** @private */
var LEFT_INDENT_CLASS = 'e-left-indent';
/** @private */
var LEFT_INDENT_WRAP_CLASS = 'e-left-indent-wrap';
/** @private */
var EVENT_TABLE_CLASS = 'e-event-table';
/** @private */
var RESOURCE_LEFT_TD_CLASS = 'e-resource-left-td';
/** @private */
var RESOURCE_GROUP_CELLS_CLASS = 'e-resource-group-cells';
/** @private */
var RESOURCE_TEXT_CLASS = 'e-resource-text';
/** @private */
var RESOURCE_COLUMN_WRAP_CLASS = 'e-resource-column-wrap';
/** @private */
var RESOURCE_COLUMN_TABLE_CLASS = 'e-resource-column-table';
/** @private */
var RESOURCE_CHILD_CLASS = 'e-child-node';
/** @private */
var RESOURCE_PARENT_CLASS = 'e-parent-node';
/** @private */
var RESOURCE_EXPAND_CLASS = 'e-resource-expand';
/** @private */
var RESOURCE_COLLAPSE_CLASS = 'e-resource-collapse';
/** @private */
var RESOURCE_TREE_ICON_CLASS = 'e-resource-tree-icon';
/** @private */
var RESOURCE_CELLS_CLASS = 'e-resource-cells';
/** @private */
var TIME_CELLS_WRAP_CLASS = 'e-time-cells-wrap';
/** @private */
var TIME_CELLS_CLASS = 'e-time-cells';
/** @private */
var TIME_SLOT_CLASS = 'e-time-slots';
/** @private */
var ALTERNATE_CELLS_CLASS = 'e-alternate-cells';
/** @private */
var CURRENT_TIME_CLASS = 'e-current-time';
/** @private */
var CURRENT_TIMELINE_CLASS = 'e-current-timeline';
/** @private */
var PREVIOUS_TIMELINE_CLASS = 'e-previous-timeline';
/** @private */
var HIDE_CHILDS_CLASS = 'e-hide-childs';
/** @private */
var SCROLL_CONTAINER_CLASS = 'e-scroll-container';
/** @private */

/** @private */
var TIMELINE_WRAPPER_CLASS = 'e-timeline-wrapper';
/** @private */
var APPOINTMENT_WRAPPER_CLASS = 'e-appointment-wrapper';
/** @private */
var DAY_WRAPPER_CLASS = 'e-day-wrapper';
/** @private */
var TOOLBAR_CONTAINER = 'e-schedule-toolbar-container';
/** @private */
var RESOURCE_TOOLBAR_CONTAINER = 'e-schedule-resource-toolbar-container';
/** @private */
var HEADER_TOOLBAR = 'e-schedule-toolbar';
/** @private */
var RESOURCE_HEADER_TOOLBAR = 'e-schedule-resource-toolbar';
/** @private */
var SELECTED_CELL_CLASS = 'e-selected-cell';
/** @private */
var WEEK_NUMBER_WRAPPER_CLASS = 'e-week-number-wrapper';
/** @private */
var WEEK_NUMBER_CLASS = 'e-week-number';
/** @private */
var APPOINTMENT_WRAP_CLASS = 'e-appointment-wrap';
/** @private */
var WRAPPER_CONTAINER_CLASS = 'e-wrapper-container';
/** @private */
var APPOINTMENT_CONTAINER_CLASS = 'e-appointment-container';
/** @private */
var APPOINTMENT_CLASS = 'e-appointment';
/** @private */
var BLOCK_APPOINTMENT_CLASS = 'e-block-appointment';
/** @private */
var BLOCK_INDICATOR_CLASS = 'e-block-indicator';
/** @private */
var APPOINTMENT_BORDER = 'e-appointment-border';
/** @private */
var APPOINTMENT_DETAILS = 'e-appointment-details';
/** @private */
var SUBJECT_WRAP = 'e-subject-wrap';
/** @private */
var RESOURCE_NAME = 'e-resource-name';
/** @private */
var APPOINTMENT_TIME = 'e-time';
/** @private */
var TABLE_WRAP_CLASS = 'e-table-wrap';
/** @private */
var OUTER_TABLE_CLASS = 'e-outer-table';
/** @private */
var CONTENT_WRAP_CLASS = 'e-content-wrap';
/** @private */
var VIRTUAL_TRACK_CLASS = 'e-virtual-track';
/** @private */
var AGENDA_CELLS_CLASS = 'e-agenda-cells';
/** @private */
var AGENDA_CURRENT_DAY_CLASS = 'e-current-day';
/** @private */
var AGENDA_SELECTED_CELL = 'e-active-appointment-agenda';
/** @private */
var MONTH_HEADER_CLASS = 'e-month-header';
/** @private */
var AGENDA_HEADER_CLASS = 'e-day-date-header';
/** @private */
var AGENDA_RESOURCE_CLASS = 'e-resource-column';
/** @private */
var AGENDA_DATE_CLASS = 'e-date-column';
/** @private */
var NAVIGATE_CLASS = 'e-navigate';
/** @private */
var DATE_HEADER_CLASS = 'e-date-header';
/** @private */
var AGENDA_DAY_BORDER_CLASS = 'e-day-border';
/** @private */
var DATE_BORDER_CLASS = 'e-date-border';
/** @private */
var AGENDA_DAY_PADDING_CLASS = 'e-day-padding';
/** @private */
var DATE_TIME_CLASS = 'e-date-time';
/** @private */
var DATE_TIME_WRAPPER_CLASS = 'e-date-time-wrapper';
/** @private */
var AGENDA_EMPTY_EVENT_CLASS = 'e-empty-event';
/** @private */
var AGENDA_NO_EVENT_CLASS = 'e-no-event';
/** @private */
var APPOINTMENT_INDICATOR_CLASS = 'e-appointment-indicator';
/** @private */
var EVENT_INDICATOR_CLASS = 'e-indicator';
/** @private */
var EVENT_ICON_UP_CLASS = 'e-up-icon';
/** @private */
var EVENT_ICON_DOWN_CLASS = 'e-down-icon';
/** @private */
var EVENT_ICON_LEFT_CLASS = 'e-left-icon';
/** @private */
var EVENT_ICON_RIGHT_CLASS = 'e-right-icon';
/** @private */
var EVENT_ACTION_CLASS = 'e-event-action';
/** @private */
var NEW_EVENT_CLASS = 'e-new-event';
/** @private */
var CLONE_ELEMENT_CLASS = 'e-schedule-event-clone';
/** @private */
var MONTH_CLONE_ELEMENT_CLASS = 'e-month-event';
/** @private */
var CLONE_TIME_INDICATOR_CLASS = 'e-clone-time-indicator';
/** @private */
var DRAG_CLONE_CLASS = 'e-drag-clone';
/** @private */
var EVENT_RESIZE_CLASS = 'e-event-resize';
/** @private */
var RESIZE_CLONE_CLASS = 'e-resize-clone';
/** @private */
var LEFT_RESIZE_HANDLER = 'e-left-handler';
/** @private */
var RIGHT_RESIZE_HANDLER = 'e-right-handler';
/** @private */
var TOP_RESIZE_HANDLER = 'e-top-handler';
/** @private */
var BOTTOM_RESIZE_HANDLER = 'e-bottom-handler';
/** @private */
var EVENT_RECURRENCE_ICON_CLASS = 'e-recurrence-icon';
/** @private */
var EVENT_RECURRENCE_EDIT_ICON_CLASS = 'e-recurrence-edit-icon';
/** @private */
var HEADER_ROW_CLASS = 'e-header-row';
/** @private */
var ALLDAY_APPOINTMENT_WRAPPER_CLASS = 'e-all-day-appointment-wrapper';
/** @private */
var ALLDAY_APPOINTMENT_CLASS = 'e-all-day-appointment';
/** @private */
var EVENT_COUNT_CLASS = 'e-appointment-hide';
/** @private */
var ROW_COUNT_WRAPPER_CLASS = 'e-row-count-wrapper';
/** @private */
var ALLDAY_APPOINTMENT_SECTION_CLASS = 'e-all-day-appointment-section';
/** @private */
var APPOINTMENT_ROW_EXPAND_CLASS = 'e-appointment-expand';
/** @private */
var APPOINTMENT_ROW_COLLAPSE_CLASS = 'e-appointment-collapse';
/** @private */
var MORE_INDICATOR_CLASS = 'e-more-indicator';
/** @private */
var CELL_POPUP_CLASS = 'e-cell-popup';
/** @private */
var EVENT_POPUP_CLASS = 'e-event-popup';
/** @private */
var MULTIPLE_EVENT_POPUP_CLASS = 'e-multiple-event-popup';
/** @private */
var POPUP_HEADER_CLASS = 'e-popup-header';
/** @private */
var POPUP_HEADER_ICON_WRAPPER = 'e-header-icon-wrapper';
/** @private */
var POPUP_CONTENT_CLASS = 'e-popup-content';
/** @private */
var POPUP_FOOTER_CLASS = 'e-popup-footer';
/** @private */
var DATE_TIME_DETAILS_CLASS = 'e-date-time-details';
/** @private */
var RECURRENCE_SUMMARY_CLASS = 'e-recurrence-summary';
/** @private */
var QUICK_POPUP_EVENT_DETAILS_CLASS = 'e-event-details';
/** @private */
var EVENT_CREATE_CLASS = 'e-event-create';
/** @private */
var EDIT_EVENT_CLASS = 'e-event-edit';
/** @private */
var DELETE_EVENT_CLASS = 'e-event-delete';
/** @private */
var TEXT_ELLIPSIS = 'e-text-ellipsis';
/** @private */
var MORE_POPUP_WRAPPER_CLASS = 'e-more-popup-wrapper';
/** @private */
var MORE_EVENT_POPUP_CLASS = 'e-more-event-popup';
/** @private */
var MORE_EVENT_HEADER_CLASS = 'e-more-event-header';
/** @private */
var MORE_EVENT_DATE_HEADER_CLASS = 'e-more-event-date-header';
/** @private */
var MORE_EVENT_HEADER_DAY_CLASS = 'e-header-day';
/** @private */
var MORE_EVENT_HEADER_DATE_CLASS = 'e-header-date';
/** @private */
var MORE_EVENT_CLOSE_CLASS = 'e-more-event-close';
/** @private */
var MORE_EVENT_CONTENT_CLASS = 'e-more-event-content';
/** @private */
var MORE_EVENT_WRAPPER_CLASS = 'e-more-appointment-wrapper';
/** @private */
var QUICK_DIALOG_CLASS = 'e-quick-dialog';
/** @private */
var QUICK_DIALOG_OCCURRENCE_CLASS = 'e-quick-dialog-occurrence-event';
/** @private */
var QUICK_DIALOG_SERIES_CLASS = 'e-quick-dialog-series-event';
/** @private */
var QUICK_DIALOG_FOLLOWING_EVENTS_CLASS = 'e-quick-dialog-following-events';
/** @private */
var FOLLOWING_EVENTS_DIALOG = 'e-following-events-dialog';
/** @private */
var QUICK_DIALOG_DELETE_CLASS = 'e-quick-dialog-delete';
/** @private */
var QUICK_DIALOG_CANCEL_CLASS = 'e-quick-dialog-cancel';
/** @private */
var QUICK_DIALOG_ALERT_OK = 'e-quick-alertok';
/** @private */
var QUICK_DIALOG_ALERT_CANCEL = 'e-quick-alertcancel';
/** @private */
var QUICK_DIALOG_ALERT_FOLLOWING = 'e-quick-alertfollowing';
/** @private */
var QUICK_DIALOG_ALERT_BTN_CLASS = 'e-quick-dialog-alert-btn';
/** @private */
var EVENT_WINDOW_DIALOG_CLASS = 'e-schedule-dialog';
/** @private */
var FORM_CONTAINER_CLASS = 'e-form-container';
/** @private */
var FORM_CLASS = 'e-schedule-form';
/** @private */
var EVENT_WINDOW_ALLDAY_TZ_DIV_CLASS = 'e-all-day-time-zone-row';
/** @private */
var EVENT_WINDOW_ALL_DAY_CLASS = 'e-all-day';
/** @private */
var TIME_ZONE_CLASS = 'e-time-zone';
/** @private */
var TIME_ZONE_ICON_CLASS = 'e-time-zone-icon';
/** @private */
var TIME_ZONE_DETAILS_CLASS = 'e-time-zone-details';
/** @private */
var EVENT_WINDOW_REPEAT_DIV_CLASS = 'e-repeat-parent-row';
/** @private */
var EVENT_WINDOW_REPEAT_CLASS = 'e-repeat';
/** @private */
var EVENT_WINDOW_TITLE_LOCATION_DIV_CLASS = 'e-title-location-row';
/** @private */
var SUBJECT_CLASS = 'e-subject';
/** @private */
var LOCATION_CLASS = 'e-location';
/** @private */
var LOCATION_ICON_CLASS = 'e-location-icon';
/** @private */
var LOCATION_DETAILS_CLASS = 'e-location-details';
/** @private */
var EVENT_WINDOW_START_END_DIV_CLASS = 'e-start-end-row';
/** @private */
var EVENT_WINDOW_START_CLASS = 'e-start';
/** @private */
var EVENT_WINDOW_END_CLASS = 'e-end';
/** @private */
var EVENT_WINDOW_RESOURCES_DIV_CLASS = 'e-resources-row';
/** @private */
var DESCRIPTION_CLASS = 'e-description';
/** @private */
var DESCRIPTION_ICON_CLASS = 'e-description-icon';
/** @private */
var DESCRIPTION_DETAILS_CLASS = 'e-description-details';
/** @private */
var EVENT_WINDOW_TIME_ZONE_DIV_CLASS = 'e-time-zone-row';
/** @private */
var EVENT_WINDOW_START_TZ_CLASS = 'e-start-time-zone';
/** @private */
var EVENT_WINDOW_END_TZ_CLASS = 'e-end-time-zone';
/** @private */
var EVENT_WINDOW_BACK_ICON_CLASS = 'e-back-icon';
/** @private */
var EVENT_WINDOW_SAVE_ICON_CLASS = 'e-save-icon';
/** @private */
var EVENT_WINDOW_CANCEL_BUTTON_CLASS = 'e-event-cancel';
/** @private */
var EVENT_WINDOW_SAVE_BUTTON_CLASS = 'e-event-save';
/** @private */
var EVENT_WINDOW_DIALOG_PARENT_CLASS = 'e-dialog-parent';
/** @private */
var EVENT_WINDOW_TITLE_TEXT_CLASS = 'e-title-text';
/** @private */
var EVENT_WINDOW_ICON_DISABLE_CLASS = 'e-icon-disable';
/** @private */
var EDIT_CLASS = 'e-edit';
/** @private */
var EDIT_ICON_CLASS = 'e-edit-icon';
/** @private */
var DELETE_CLASS = 'e-delete';
/** @private */
var DELETE_ICON_CLASS = 'e-delete-icon';
/** @private */
var CLOSE_CLASS = 'e-close';
/** @private */
var CLOSE_ICON_CLASS = 'e-close-icon';
/** @private */
var ERROR_VALIDATION_CLASS = 'e-schedule-error';
/** @private */
var EVENT_TOOLTIP_ROOT_CLASS = 'e-schedule-event-tooltip';
/** @private */
var ALLDAY_ROW_ANIMATE_CLASS = 'e-animate';
/** @private */
var TIMESCALE_DISABLE = 'e-timescale-disable';
/** @private */
var DISABLE_DATE = 'e-disable-date';
/** @private */
var HIDDEN_CLASS = 'e-hidden';
/** @private */
var DISABLE_DATES = 'e-disable-dates';
/** @private */
var POPUP_WRAPPER_CLASS = 'e-quick-popup-wrapper';
/** @private */
var POPUP_TABLE_CLASS = 'e-popup-table';
/** @private */
var RESOURCE_MENU = 'e-resource-menu';
/** @private */
var RESOURCE_MENU_ICON = 'e-icon-menu';
/** @private */
var RESOURCE_LEVEL_TITLE = 'e-resource-level-title';
/** @private */
var RESOURCE_TREE = 'e-resource-tree';
/** @private */
var RESOURCE_TREE_POPUP_OVERLAY = 'e-resource-tree-popup-overlay';
/** @private */
var RESOURCE_TREE_POPUP = 'e-resource-tree-popup';
/** @private */
var RESOURCE_CLASS = 'e-resource';
/** @private */
var RESOURCE_ICON_CLASS = 'e-resource-icon';
/** @private */
var RESOURCE_DETAILS_CLASS = 'e-resource-details';
/** @private */
var DATE_TIME_ICON_CLASS = 'e-date-time-icon';
/** @private */
var VIRTUAL_SCROLL_CLASS = 'e-virtual-scroll';
/** @private */
var ICON_DISABLE_CLASS = 'e-icon-disable';
/** @private */
var AUTO_HEIGHT = 'e-auto-height';
/** @private */
var IGNORE_WHITESPACE = 'e-ignore-whitespace';
/** @private */
var EVENT_TEMPLATE = 'e-template';
/** @private */
var READ_ONLY = 'e-read-only';
/** @private */
var MONTH_HEADER_WRAPPER = 'e-month-header-wrapper';
/** @private */
var INLINE_SUBJECT_CLASS = 'e-inline-subject';
/** @private */
var INLINE_APPOINTMENT_CLASS = 'e-inline-appointment';
/** @hidden */
var SCROLL_HIDDEN = 'e-scroll-hidden';
/** @private */
var ALLDAY_APPOINTMENT_SCROLL = 'e-all-day-scroll';
/** @private */
var ALLDAY_APPOINTMENT_AUTO = 'e-all-day-auto';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Header module
 */
var HeaderRenderer = /** @__PURE__ @class */ (function () {
    function HeaderRenderer(parent) {
        this.parent = parent;
        this.l10n = this.parent.localeObj;
        this.renderHeader();
        this.addEventListener();
    }
    HeaderRenderer.prototype.addEventListener = function () {
        this.parent.on(documentClick, this.closeHeaderPopup, this);
    };
    HeaderRenderer.prototype.removeEventListener = function () {
        this.parent.off(documentClick, this.closeHeaderPopup);
    };
    HeaderRenderer.prototype.closeHeaderPopup = function (e) {
        var closestEle = closest(e.event.target, '.e-date-range,.e-header-popup,.e-day,.e-selected');
        var closestPop = closest(e.event.target, '.e-hor-nav,.e-toolbar-pop');
        var contentWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (this.parent.isAdaptive) {
            if (!isNullOrUndefined(closestPop) && (closestPop.classList.contains('e-toolbar-pop') ||
                closestPop.classList.contains('e-hor-nav')) && !(closestPop.classList.contains('e-hor-nav') &&
                this.element.querySelector('.e-toolbar-pop').classList.contains(POPUP_OPEN))) {
                addClass([contentWrap], SCROLL_HIDDEN);
            }
            else {
                removeClass([contentWrap], SCROLL_HIDDEN);
                var popupEle = this.element.querySelector('.e-toolbar-pop');
                if (!isNullOrUndefined(popupEle)) {
                    var popupObj = popupEle.ej2_instances[0];
                    if (popupObj && !(!isNullOrUndefined(closestPop) && closestPop.classList.contains('e-hor-nav') &&
                        popupEle.classList.contains(POPUP_OPEN))) {
                        popupObj.hide();
                    }
                }
            }
        }
        if (!isNullOrUndefined(closestEle)) {
            return;
        }
        this.hideHeaderPopup();
    };
    HeaderRenderer.prototype.hideHeaderPopup = function () {
        if (this.headerPopup) {
            this.headerPopup.hide();
        }
    };
    HeaderRenderer.prototype.renderHeader = function () {
        this.element = createElement('div', { className: TOOLBAR_CONTAINER });
        var toolbarEle = createElement('div', { className: HEADER_TOOLBAR });
        this.element.appendChild(toolbarEle);
        this.parent.element.insertBefore(this.element, this.parent.element.firstElementChild);
        this.renderToolbar();
    };
    HeaderRenderer.prototype.renderToolbar = function () {
        var _this = this;
        var items = this.getItems();
        this.parent.trigger(actionBegin, { requestType: 'toolbarItemRendering', items: items }, function (args) {
            _this.toolbarObj = new Toolbar({
                items: args.items,
                overflowMode: 'Popup',
                clicked: _this.toolbarClickHandler.bind(_this),
                enableRtl: _this.parent.enableRtl,
                enableHtmlSanitizer: _this.parent.enableHtmlSanitizer,
                locale: _this.parent.locale
            });
            _this.toolbarObj.appendTo(_this.parent.element.querySelector('.' + HEADER_TOOLBAR));
            var prevNavEle = _this.toolbarObj.element.querySelector('.e-prev');
            if (prevNavEle) {
                prevNavEle.firstElementChild.setAttribute('title', _this.l10n.getConstant('previous'));
            }
            var nextNavEle = _this.toolbarObj.element.querySelector('.e-next');
            if (nextNavEle) {
                nextNavEle.firstElementChild.setAttribute('title', _this.l10n.getConstant('next'));
            }
            _this.updateAddIcon();
            _this.updateActiveView();
            _this.parent.trigger(actionComplete, { requestType: 'toolBarItemRendered', items: _this.toolbarObj.items });
        });
    };
    HeaderRenderer.prototype.updateItems = function () {
        var _this = this;
        if (this.toolbarObj) {
            var items = this.getItems();
            this.parent.trigger(actionBegin, { requestType: 'toolbarItemRendering', items: items }, function (args) {
                _this.toolbarObj.items = args.items;
                _this.toolbarObj.dataBind();
                _this.parent.trigger(actionComplete, { requestType: 'toolBarItemRendered', items: _this.toolbarObj.items });
            });
        }
    };
    HeaderRenderer.prototype.getPopUpRelativeElement = function () {
        if (this.parent.isAdaptive) {
            return this.toolbarObj.element;
        }
        return this.element.querySelector('.e-date-range');
    };
    HeaderRenderer.prototype.setDayOfWeek = function (index) {
        if (this.headerCalendar) {
            this.headerCalendar.firstDayOfWeek = index;
            this.headerCalendar.dataBind();
        }
    };
    HeaderRenderer.prototype.setCalendarDate = function (date) {
        if (this.headerCalendar) {
            this.headerCalendar.value = date;
            this.headerCalendar.dataBind();
        }
    };
    HeaderRenderer.prototype.setCalendarMinMaxDate = function () {
        if (this.headerCalendar) {
            this.headerCalendar.min = this.parent.minDate;
            this.headerCalendar.max = this.parent.maxDate;
            this.headerCalendar.dataBind();
        }
    };
    HeaderRenderer.prototype.getCalendarView = function () {
        if (['Month', 'MonthAgenda', 'TimelineMonth'].indexOf(this.parent.currentView) > -1) {
            return 'Year';
        }
        else if (['Year', 'TimelineYear'].indexOf(this.parent.currentView) > -1) {
            return 'Decade';
        }
        else {
            return 'Month';
        }
    };
    HeaderRenderer.prototype.setCalendarView = function () {
        if (this.headerCalendar) {
            var calendarView = this.getCalendarView();
            this.headerCalendar.depth = calendarView;
            this.headerCalendar.start = calendarView;
            this.headerCalendar.refresh();
        }
    };
    HeaderRenderer.prototype.updateActiveView = function () {
        var selEle = [].slice.call(this.toolbarObj.element.querySelectorAll('.e-views'));
        removeClass(selEle, ['e-active-view']);
        if (selEle.length > 0 && selEle[this.parent.viewIndex]) {
            addClass([selEle[this.parent.viewIndex]], ['e-active-view']);
        }
    };
    HeaderRenderer.prototype.updateDateRange = function (date) {
        var selEle = this.toolbarObj.element.querySelector('.e-date-range');
        if (!selEle) {
            return;
        }
        var textEle = selEle.querySelector('.e-tbar-btn-text');
        if (this.parent.activeViewOptions.dateRangeTemplate) {
            textEle.textContent = '';
            var args = {
                startDate: this.parent.activeView.getStartDate(),
                endDate: this.parent.activeView.getEndDate(), currentView: this.parent.currentView
            };
            var viewName = this.parent.activeViewOptions.dateRangeTemplateName;
            var templateId = this.parent.element.id + '_' + viewName + 'dateRangeTemplate';
            var dateTemplate = [].slice.call(this.parent.getDateRangeTemplate()(args, this.parent, 'dateRangeTemplate', templateId, false));
            append(dateTemplate, textEle);
        }
        else {
            var text = this.parent.activeView.getDateRangeText(date);
            selEle.firstElementChild.setAttribute('aria-label', text);
            textEle.textContent = text;
        }
        this.refresh();
    };
    HeaderRenderer.prototype.refresh = function () {
        if (this.toolbarObj) {
            this.toolbarObj.refreshOverflow();
        }
    };
    HeaderRenderer.prototype.updateAddIcon = function () {
        var addEle = this.toolbarObj.element.querySelector('.e-add');
        if (addEle) {
            if (!this.parent.eventSettings.allowAdding) {
                addClass([addEle], HIDDEN_CLASS);
            }
            else {
                removeClass([addEle], HIDDEN_CLASS);
            }
        }
    };
    HeaderRenderer.prototype.getDateRangeText = function () {
        var dateString = this.parent.globalize.formatDate(this.parent.selectedDate, {
            format: 'MMMM y', calendar: this.parent.getCalendarMode()
        });
        return capitalizeFirstWord(dateString, 'single');
    };
    HeaderRenderer.prototype.getItems = function () {
        var items = [];
        items.push({
            align: 'Left', prefixIcon: 'e-icon-prev', tooltipText: 'Previous', overflow: 'Show',
            cssClass: 'e-prev', htmlAttributes: { 'role': 'navigation' }
        });
        items.push({
            align: 'Left', prefixIcon: 'e-icon-next', tooltipText: 'Next', overflow: 'Show',
            cssClass: 'e-next', htmlAttributes: { 'role': 'navigation' }
        });
        items.push({
            align: 'Left', text: this.getDateRangeText(), suffixIcon: 'e-icon-down-arrow', cssClass: 'e-date-range',
            overflow: 'Show',
            htmlAttributes: { 'aria-atomic': 'true', 'aria-live': 'assertive', 'role': 'navigation' }
        });
        if (this.parent.isAdaptive || this.parent.enableAdaptiveUI) {
            items.push({
                align: 'Right', showAlwaysInPopup: (this.parent.isAdaptive || this.parent.enableAdaptiveUI), prefixIcon: 'e-icon-add',
                text: this.l10n.getConstant('newEvent'), cssClass: 'e-add', overflow: 'Show'
            });
            items.push({
                align: 'Right', showAlwaysInPopup: (this.parent.isAdaptive || this.parent.enableAdaptiveUI), prefixIcon: 'e-icon-today',
                text: this.l10n.getConstant('today'), cssClass: 'e-today', overflow: 'Show'
            });
        }
        else {
            items.push({
                align: 'Right', showAlwaysInPopup: (this.parent.isAdaptive || this.parent.enableAdaptiveUI), prefixIcon: 'e-icon-day',
                text: this.l10n.getConstant('today'), cssClass: 'e-today', overflow: 'Show'
            });
            if (this.parent.views.length > 1) {
                items.push({ align: 'Right', type: 'Separator', cssClass: 'e-schedule-seperator' });
            }
        }
        if (this.parent.views.length > 1) {
            for (var _i = 0, _a = this.parent.views; _i < _a.length; _i++) {
                var item = _a[_i];
                items.push(this.getItemObject(item));
            }
        }
        return items;
    };
    HeaderRenderer.prototype.getItemObject = function (item) {
        var viewName;
        var displayName;
        if (typeof (item) === 'string') {
            viewName = item.toLowerCase();
            displayName = null;
        }
        else {
            viewName = item.option.toLowerCase();
            displayName = item.displayName;
        }
        var view;
        var orientationClass;
        var isItemInsidePopup = this.parent.isAdaptive || this.parent.enableAdaptiveUI;
        switch (viewName) {
            case 'day':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-day',
                    text: displayName || this.l10n.getConstant('day'), cssClass: 'e-views e-day'
                };
                break;
            case 'week':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-week',
                    text: displayName || this.l10n.getConstant('week'), cssClass: 'e-views e-week'
                };
                break;
            case 'workweek':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-workweek',
                    text: displayName || this.l10n.getConstant('workWeek'), cssClass: 'e-views e-work-week'
                };
                break;
            case 'month':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-month',
                    text: displayName || this.l10n.getConstant('month'), cssClass: 'e-views e-month'
                };
                break;
            case 'year':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-year',
                    text: displayName || this.l10n.getConstant('year'), cssClass: 'e-views e-year'
                };
                break;
            case 'agenda':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-agenda',
                    text: displayName || this.l10n.getConstant('agenda'), cssClass: 'e-views e-agenda'
                };
                break;
            case 'monthagenda':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-month-agenda',
                    text: displayName || this.l10n.getConstant('monthAgenda'), cssClass: 'e-views e-month-agenda'
                };
                break;
            case 'timelineday':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-timeline-day',
                    text: displayName || this.l10n.getConstant('timelineDay'), cssClass: 'e-views e-timeline-day'
                };
                break;
            case 'timelineweek':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-timeline-week',
                    text: displayName || this.l10n.getConstant('timelineWeek'), cssClass: 'e-views e-timeline-week'
                };
                break;
            case 'timelineworkweek':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-timeline-workweek',
                    text: displayName || this.l10n.getConstant('timelineWorkWeek'), cssClass: 'e-views e-timeline-work-week'
                };
                break;
            case 'timelinemonth':
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-timeline-month',
                    text: displayName || this.l10n.getConstant('timelineMonth'), cssClass: 'e-views e-timeline-month'
                };
                break;
            case 'timelineyear':
                orientationClass = (item.orientation === 'Vertical') ? 'vertical' : 'horizontal';
                view = {
                    align: 'Right', showAlwaysInPopup: isItemInsidePopup, prefixIcon: 'e-icon-timeline-year-' + orientationClass,
                    text: displayName || this.l10n.getConstant('timelineYear'), cssClass: 'e-views e-timeline-year'
                };
                break;
        }
        return view;
    };
    HeaderRenderer.prototype.renderHeaderPopup = function () {
        var headerPopupEle = createElement('div', { className: HEADER_POPUP_CLASS });
        var headerCalendarEle = createElement('div', { className: HEADER_CALENDAR_CLASS });
        headerPopupEle.appendChild(headerCalendarEle);
        this.element.appendChild(headerPopupEle);
        this.headerPopup = new Popup(headerPopupEle, {
            actionOnScroll: 'hide',
            targetType: 'relative',
            relateTo: this.getPopUpRelativeElement(),
            position: { X: 'left', Y: 'bottom' },
            enableRtl: this.parent.enableRtl
        });
        var calendarView = this.getCalendarView();
        var isDisplayDate = this.parent.currentView === 'Month' &&
            !isNullOrUndefined(this.parent.activeViewOptions.displayDate) && !this.hasSelectedDate();
        this.headerCalendar = new Calendar({
            value: isDisplayDate ? this.parent.activeViewOptions.displayDate : this.parent.selectedDate,
            min: this.parent.minDate,
            max: this.parent.maxDate,
            firstDayOfWeek: this.parent.activeViewOptions.firstDayOfWeek,
            enableRtl: this.parent.enableRtl,
            locale: this.parent.locale,
            depth: calendarView,
            start: calendarView,
            calendarMode: this.parent.calendarMode,
            change: this.calendarChange.bind(this)
        });
        this.setCalendarTimezone();
        this.headerCalendar.appendTo(headerCalendarEle);
        this.headerPopup.hide();
    };
    HeaderRenderer.prototype.calendarChange = function (args) {
        if (args.value.getTime() !== this.parent.selectedDate.getTime()) {
            var calendarDate = resetTime(new Date(args.value));
            this.parent.changeDate(calendarDate);
        }
        this.headerPopup.hide();
    };
    HeaderRenderer.prototype.setCalendarTimezone = function () {
        if (this.headerCalendar) {
            this.headerCalendar.timezone = this.parent.timezone || this.parent.tzModule.getLocalTimezoneName();
        }
    };
    HeaderRenderer.prototype.calculateViewIndex = function (args) {
        var target = closest(args.originalEvent.target, '.e-views');
        var views = [].slice.call(this.element.querySelectorAll('.e-views'));
        return views.indexOf(target);
    };
    HeaderRenderer.prototype.toolbarClickHandler = function (args) {
        if (!args.item) {
            return;
        }
        var strClass = args.item.cssClass.replace('e-views ', '');
        var data;
        var isSameTime;
        var currentTime;
        switch (strClass) {
            case 'e-date-range':
                if (!this.headerPopup) {
                    this.renderHeaderPopup();
                }
                if (this.headerPopup.element.classList.contains(POPUP_OPEN)) {
                    this.headerPopup.hide();
                }
                else {
                    this.headerPopup.show();
                }
                break;
            case 'e-day':
                this.parent.changeView('Day', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-week':
                this.parent.changeView('Week', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-work-week':
                this.parent.changeView('WorkWeek', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-month':
                this.parent.changeView('Month', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-year':
                this.parent.changeView('Year', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-agenda':
                this.parent.changeView('Agenda', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-month-agenda':
                this.parent.changeView('MonthAgenda', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-timeline-day':
                this.parent.changeView('TimelineDay', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-timeline-week':
                this.parent.changeView('TimelineWeek', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-timeline-work-week':
                this.parent.changeView('TimelineWorkWeek', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-timeline-month':
                this.parent.changeView('TimelineMonth', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-timeline-year':
                this.parent.changeView('TimelineYear', args.originalEvent, undefined, this.calculateViewIndex(args));
                break;
            case 'e-today':
                currentTime = resetTime(this.parent.getCurrentTime());
                if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda' || !this.parent.isSelectedDate(currentTime) ||
                    this.parent.currentView === 'Month' && this.parent.activeViewOptions.displayDate && !this.hasSelectedDate() &&
                        resetTime(this.parent.activeViewOptions.displayDate) !== currentTime || this.parent.currentView === 'Month' &&
                    this.parent.activeViewOptions.numberOfWeeks > 0 && !this.hasSelectedDate()
                    && resetTime(firstDateOfMonth(this.parent.selectedDate)) !== currentTime) {
                    this.parent.changeDate(currentTime, args.originalEvent);
                }
                break;
            case 'e-prev':
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('previous'), args.originalEvent);
                break;
            case 'e-next':
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('next'), args.originalEvent);
                break;
            case 'e-add':
                isSameTime = this.parent.activeCellsData.startTime.getTime() === this.parent.activeCellsData.endTime.getTime();
                if (this.parent.activeCellsData && !isSameTime) {
                    data = this.parent.activeCellsData;
                }
                else {
                    var interval = this.parent.activeViewOptions.timeScale.interval;
                    var slotCount = this.parent.activeViewOptions.timeScale.slotCount;
                    var msInterval = (interval * MS_PER_MINUTE) / slotCount;
                    var startTime = new Date(this.parent.selectedDate.getTime());
                    var currentTime_1 = this.parent.getCurrentTime();
                    startTime.setHours(currentTime_1.getHours(), (Math.round(startTime.getMinutes() / msInterval) * msInterval), 0);
                    var endTime = new Date(new Date(startTime.getTime()).setMilliseconds(startTime.getMilliseconds() + msInterval));
                    data = { startTime: startTime, endTime: endTime, isAllDay: false };
                }
                this.parent.eventWindow.openEditor(extend(data, { cancel: false, event: args.originalEvent }), 'Add');
                break;
        }
        if (isNullOrUndefined(this.toolbarObj)) {
            return;
        }
        var toolbarPopUp = this.toolbarObj.element.querySelector('.e-toolbar-pop');
        if (toolbarPopUp && args.item.type !== 'Input') {
            toolbarPopUp.ej2_instances[0].hide({ name: 'SlideUp', duration: 100 });
        }
    };
    HeaderRenderer.prototype.hasSelectedDate = function () {
        var selectedTime = resetTime(this.parent.selectedDate).getTime();
        return selectedTime >= this.parent.activeView.getStartDate().getTime() &&
            selectedTime <= this.parent.activeView.getEndDate().getTime();
    };
    HeaderRenderer.prototype.getHeaderElement = function () {
        return this.toolbarObj.element;
    };
    HeaderRenderer.prototype.updateHeaderItems = function (classType) {
        var prevNavEle = this.toolbarObj.element.querySelector('.e-prev');
        var nextNavEle = this.toolbarObj.element.querySelector('.e-next');
        var dateRangeEle = this.toolbarObj.element.querySelector('.e-date-range');
        if (prevNavEle) {
            if (classType === 'add') {
                addClass([prevNavEle], HIDDEN_CLASS);
            }
            else {
                removeClass([prevNavEle], HIDDEN_CLASS);
            }
        }
        if (nextNavEle) {
            if (classType === 'add') {
                addClass([nextNavEle], HIDDEN_CLASS);
            }
            else {
                removeClass([nextNavEle], HIDDEN_CLASS);
            }
        }
        if (dateRangeEle) {
            if (classType === 'add') {
                addClass([dateRangeEle], TEXT_ELLIPSIS);
            }
            else {
                removeClass([dateRangeEle], TEXT_ELLIPSIS);
            }
        }
    };
    HeaderRenderer.prototype.previousNextIconHandler = function () {
        var dates = (this.parent.currentView === 'Agenda' ?
            [this.parent.getCurrentViewDates()[0]] : this.parent.getCurrentViewDates());
        var prevNavEle = this.toolbarObj.element.querySelector('.' + PREVIOUS_DATE_CLASS);
        var nextNavEle = this.toolbarObj.element.querySelector('.' + NEXT_DATE_CLASS);
        var firstDate = new Date(dates[0].getTime());
        var lastDate = new Date(dates[dates.length - 1].getTime());
        if (this.parent.currentView === 'WorkWeek' || this.parent.currentView === 'TimelineWorkWeek') {
            firstDate = getWeekFirstDate(resetTime(this.parent.selectedDate), this.parent.firstDayOfWeek);
            lastDate = addDays(firstDate, 7 * this.parent.activeViewOptions.interval);
        }
        else if (this.parent.currentView === 'Month') {
            var isCustomMonth = !isNullOrUndefined(this.parent.activeViewOptions.displayDate) ||
                this.parent.activeViewOptions.numberOfWeeks > 0;
            firstDate = isCustomMonth ? this.parent.activeView.getStartDate() : firstDateOfMonth(this.parent.selectedDate);
            lastDate = isCustomMonth ? this.parent.activeView.getEndDate() :
                lastDateOfMonth(addMonths(firstDate, this.parent.activeViewOptions.interval - 1));
        }
        if (!isNullOrUndefined(prevNavEle)) {
            this.toolbarObj.enableItems(prevNavEle, firstDate > resetTime(this.parent.minDate));
        }
        if (!isNullOrUndefined(nextNavEle)) {
            this.toolbarObj.enableItems(nextNavEle, lastDate < resetTime(this.parent.maxDate));
        }
        this.setCalendarMinMaxDate();
    };
    HeaderRenderer.prototype.getModuleName = function () {
        return 'headerbar';
    };
    HeaderRenderer.prototype.destroy = function () {
        if (this.headerPopup && !this.headerPopup.isDestroyed) {
            this.headerPopup.destroy();
            this.headerPopup = null;
        }
        if (this.headerCalendar && !this.headerCalendar.isDestroyed) {
            this.headerCalendar.destroy();
            this.headerCalendar = null;
        }
        if (this.toolbarObj && !this.toolbarObj.isDestroyed) {
            this.toolbarObj.destroy();
            this.removeEventListener();
            remove(this.element);
            this.toolbarObj = null;
        }
        this.element = null;
        this.parent = null;
        this.l10n = null;
    };
    return HeaderRenderer;
}());

/**
 * `Scroll` module
 */
var Scroll = /** @__PURE__ @class */ (function () {
    /**
     * Constructor for the scrolling.
     *
     * @param {Schedule} parent Accepts the Schedule instance
     */
    function Scroll(parent) {
        this.parent = parent;
        this.addEventListener();
    }
    /**
     * For internal use only - Get the module name.
     *
     * @returns {string} Returns the module name.
     * @private
     */
    Scroll.prototype.getModuleName = function () {
        return 'scroll';
    };
    /**
     * Internal method to set the element width
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.setWidth = function () {
        this.parent.element.style.width = formatUnit(this.parent.width);
    };
    /**
     * Internal method to set the element height
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.setHeight = function () {
        this.parent.element.style.height = formatUnit(this.parent.height);
    };
    /**
     * Internal method to bind events
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.addEventListener = function () {
        this.parent.on(contentReady, this.setDimensions, this);
        this.parent.on(uiUpdate, this.onPropertyChanged, this);
    };
    /**
     * Internal method to unbind events
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.removeEventListener = function () {
        this.parent.off(contentReady, this.setDimensions);
        this.parent.off(uiUpdate, this.onPropertyChanged);
    };
    /**
     * Internal method to set the dimensions
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.setDimensions = function () {
        this.setWidth();
        this.setHeight();
        var data = { cssProperties: this.parent.getCssProperties(), module: this.getModuleName() };
        this.parent.notify(scrollUiUpdate, data);
    };
    /**
     * Internal method to set the dimensions dynamically
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.onPropertyChanged = function () {
        this.setDimensions();
    };
    /**
     * Destroy the scroll module
     *
     * @returns {void}
     * @private
     */
    Scroll.prototype.destroy = function () {
        this.removeEventListener();
    };
    return Scroll;
}());

/**
 * `touch` module is used to handle touch interactions.
 */
var ScheduleTouch = /** @__PURE__ @class */ (function () {
    function ScheduleTouch(parent) {
        this.parent = parent;
        this.element = this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS);
        this.touchObj = new Touch(this.element, {
            scroll: this.scrollHandler.bind(this),
            swipe: this.swipeHandler.bind(this),
            tapHold: this.tapHoldHandler.bind(this),
            swipeSettings: { swipeThresholdDistance: 1 }
        });
        EventHandler.add(this.element, 'transitionend', this.onTransitionEnd, this);
        this.touchLeftDirection = this.parent.enableRtl ? 'Right' : 'Left';
        this.touchRightDirection = this.parent.enableRtl ? 'Left' : 'Right';
    }
    ScheduleTouch.prototype.scrollHandler = function (e) {
        if (this.parent.currentView === 'Agenda' || this.parent.uiStateValues.action || !this.parent.allowSwiping ||
            (e.originalEvent && e.originalEvent.target &&
                (e.originalEvent.target.classList.contains(APPOINTMENT_CLASS) ||
                    closest(e.originalEvent.target, '.' + APPOINTMENT_CLASS)) && !this.parent.isAdaptive)) {
            return;
        }
        if (!this.timeStampStart) {
            this.timeStampStart = Date.now();
        }
        if (this.element.classList.contains(TRANSLATE_CLASS)) {
            this.onTransitionEnd();
        }
        if (e.scrollDirection === 'Left' || e.scrollDirection === 'Right') {
            var args = { requestType: 'dateNavigate', cancel: false, event: e.originalEvent };
            this.parent.trigger(actionBegin, args);
            if (args.cancel) {
                return;
            }
            var scrollDiv = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
            if (scrollDiv && scrollDiv.scrollWidth > scrollDiv.clientWidth) {
                return;
            }
            else {
                this.isScrollTriggered = true;
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
            }
        }
        if (e.scrollDirection === this.touchLeftDirection) {
            if (!this.nextPanel) {
                this.renderPanel(NEXT_PANEL_CLASS, 'next');
                this.nextPanel = {
                    element: this.parent.activeView.getPanel(),
                    selectedDate: new Date(this.parent.selectedDate.getTime()),
                    renderDates: this.parent.activeView.renderDates,
                    colLevels: this.parent.activeView.colLevels
                };
                this.setDimensions(this.nextPanel.element);
            }
            var x = this.parent.enableRtl ? e.distanceX : -e.distanceX;
            this.element.style.transform = 'translatex(' + (this.getTranslateX(this.element) + x) + 'px)';
        }
        else if (e.scrollDirection === this.touchRightDirection) {
            var prevWidth = 0;
            if (!this.previousPanel) {
                this.renderPanel(PREVIOUS_PANEL_CLASS, 'previous');
                this.previousPanel = {
                    element: this.parent.activeView.getPanel(),
                    selectedDate: new Date(this.parent.selectedDate.getTime()),
                    renderDates: this.parent.activeView.renderDates,
                    colLevels: this.parent.activeView.colLevels
                };
                this.setDimensions(this.previousPanel.element);
                prevWidth = this.previousPanel.element.offsetWidth;
            }
            var x = this.parent.enableRtl ? prevWidth - e.distanceX : -prevWidth + e.distanceX;
            this.element.style.transform = 'translatex(' + (this.getTranslateX(this.element) + x) + 'px)';
        }
    };
    ScheduleTouch.prototype.swipeHandler = function (e) {
        if (!this.isScrollTriggered || this.parent.uiStateValues.action || !this.parent.allowSwiping) {
            return;
        }
        this.isScrollTriggered = false;
        var swipeDate = e.swipeDirection === 'Left' ?
            this.parent.activeView.renderDates[0] : this.parent.activeView.renderDates.slice(-1)[0];
        if ((e.swipeDirection === 'Left' && swipeDate < this.parent.maxDate) ||
            (e.swipeDirection === 'Right' && swipeDate >= this.parent.minDate)) {
            var time = Date.now() - this.timeStampStart;
            var offsetDist = (e.distanceX * (Browser.isDevice ? 6 : 1.66));
            if (offsetDist > time || (e.distanceX > (this.parent.element.offsetWidth / 2))) {
                this.swapPanels(e.swipeDirection);
                if (offsetDist > time && (e.distanceX > (this.parent.element.offsetWidth / 2))) {
                    this.element.style.transitionDuration = (((Browser.isDevice ? e.distanceX : offsetDist) / time) / 10) + 's';
                }
                this.confirmSwipe(e.swipeDirection);
            }
            else {
                this.cancelSwipe();
            }
            var args = { requestType: 'dateNavigate', cancel: false, event: e.originalEvent };
            this.parent.trigger(actionComplete, args);
        }
        else {
            this.cancelSwipe();
        }
        this.timeStampStart = null;
    };
    ScheduleTouch.prototype.tapHoldHandler = function (e) {
        var target = closest(e.originalEvent.target, '.' + APPOINTMENT_CLASS);
        if (!isNullOrUndefined(target) && this.parent.isAdaptive) {
            this.parent.quickPopup.tapHoldEventPopup(e.originalEvent);
            return;
        }
    };
    ScheduleTouch.prototype.renderPanel = function (clsName, nextPrevType) {
        if (!this.currentPanel) {
            this.currentPanel = {
                element: this.parent.activeView.getPanel(),
                selectedDate: new Date(this.parent.selectedDate.getTime()),
                renderDates: this.parent.activeView.renderDates,
                colLevels: this.parent.activeView.colLevels
            };
            this.setDimensions(this.currentPanel.element);
        }
        else {
            this.parent.setProperties({ selectedDate: this.currentPanel.selectedDate }, true);
        }
        this.parent.setProperties({ selectedDate: this.parent.activeView.getNextPreviousDate(nextPrevType) }, true);
        if (this.parent.headerModule) {
            this.parent.headerModule.setCalendarDate(this.parent.selectedDate);
        }
        this.parent.activeView.getRenderDates();
        this.parent.activeView.renderLayout(clsName);
    };
    ScheduleTouch.prototype.swapPanels = function (direction) {
        if (direction === this.touchLeftDirection) {
            var temp = this.nextPanel;
            this.nextPanel = this.currentPanel;
            this.currentPanel = temp;
        }
        else {
            var temp = this.previousPanel;
            this.previousPanel = this.currentPanel;
            this.currentPanel = temp;
        }
    };
    ScheduleTouch.prototype.confirmSwipe = function (swipeDirection) {
        var _this = this;
        var previousDate = swipeDirection === this.touchLeftDirection ?
            this.nextPanel.selectedDate : this.previousPanel.selectedDate;
        var args = {
            action: 'date', cancel: false, previousDate: previousDate, currentDate: this.currentPanel.selectedDate
        };
        this.parent.trigger(navigating, args, function (navArgs) {
            if (navArgs.cancel) {
                _this.swapPanels(swipeDirection);
                _this.cancelSwipe();
            }
            else {
                _this.parent.activeView.setPanel(_this.currentPanel.element);
                _this.parent.setProperties({ selectedDate: _this.currentPanel.selectedDate }, true);
                var translateX = void 0;
                if (_this.parent.enableRtl) {
                    translateX = swipeDirection === _this.touchLeftDirection ?
                        (_this.previousPanel ? _this.previousPanel.element.offsetLeft : _this.currentPanel.element.offsetWidth) : 0;
                }
                else {
                    translateX = swipeDirection === _this.touchLeftDirection ? -_this.currentPanel.element.offsetLeft : 0;
                }
                _this.parent.activeView.renderDates = _this.currentPanel.renderDates;
                _this.parent.activeView.colLevels = _this.currentPanel.colLevels;
                addClass([_this.element], TRANSLATE_CLASS);
                _this.element.style.transform = 'translatex(' + translateX + 'px)';
                if (_this.parent.headerModule) {
                    _this.parent.headerModule.updateDateRange();
                }
                _this.parent.renderTemplates();
                _this.parent.crudModule.refreshDataManager();
            }
        });
    };
    ScheduleTouch.prototype.cancelSwipe = function () {
        this.parent.activeView.setPanel(this.currentPanel.element);
        this.parent.setProperties({ selectedDate: this.currentPanel.selectedDate }, true);
        this.parent.activeView.renderDates = this.currentPanel.renderDates;
        this.parent.activeView.colLevels = this.currentPanel.colLevels;
        addClass([this.element], TRANSLATE_CLASS);
        var prevWidth = this.previousPanel ? this.previousPanel.element.offsetWidth : 0;
        this.element.style.transform = 'translatex(' + (this.parent.enableRtl ? prevWidth : -this.currentPanel.element.offsetLeft) + 'px)';
    };
    ScheduleTouch.prototype.onTransitionEnd = function () {
        removeClass([this.element], TRANSLATE_CLASS);
        this.element.style.transitionDuration = '';
        this.element.style.transform = '';
        if (this.previousPanel) {
            remove(this.previousPanel.element);
            this.previousPanel = null;
            removeClass([this.currentPanel.element], PREVIOUS_PANEL_CLASS);
            addClass([this.currentPanel.element], CURRENT_PANEL_CLASS);
        }
        if (this.nextPanel) {
            remove(this.nextPanel.element);
            this.nextPanel = null;
            removeClass([this.currentPanel.element], NEXT_PANEL_CLASS);
            addClass([this.currentPanel.element], CURRENT_PANEL_CLASS);
        }
        this.currentPanel = null;
        this.parent.activeView.getPanel().style.width = '';
    };
    ScheduleTouch.prototype.getTranslateX = function (element) {
        var style = window.getComputedStyle(element);
        return new WebKitCSSMatrix(style.webkitTransform).m41;
    };
    ScheduleTouch.prototype.setDimensions = function (element) {
        element.style.width = (this.parent.element.clientWidth) + 'px';
    };
    ScheduleTouch.prototype.resetValues = function () {
        this.currentPanel = null;
        this.previousPanel = null;
        this.nextPanel = null;
        this.timeStampStart = null;
        this.element.style.transform = '';
        this.element.style.transitionDuration = '';
        removeChildren(this.element);
        removeClass([this.element], TRANSLATE_CLASS);
    };
    ScheduleTouch.prototype.destroy = function () {
        if (this.touchObj) {
            this.touchObj.destroy();
            this.touchObj = null;
        }
        EventHandler.remove(this.element, 'transitionend', this.onTransitionEnd);
        this.resetValues();
    };
    return ScheduleTouch;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Keyboard interaction
 */
var KeyboardInteraction = /** @__PURE__ @class */ (function () {
    function KeyboardInteraction(parent) {
        this.selectedCells = [];
        this.keyConfigs = {
            downArrow: 'downarrow',
            upArrow: 'uparrow',
            rightArrow: 'rightarrow',
            leftArrow: 'leftarrow',
            shiftDownArrow: 'shift+downarrow',
            shiftUpArrow: 'shift+uparrow',
            shiftRightArrow: 'shift+rightarrow',
            shiftLeftArrow: 'shift+leftarrow',
            ctrlLeftArrow: 'ctrl+leftarrow',
            ctrlRightArrow: 'ctrl+rightarrow',
            altOne: 'alt+1',
            altTwo: 'alt+2',
            altThree: 'alt+3',
            altFour: 'alt+4',
            altFive: 'alt+5',
            altSix: 'alt+6',
            altSeven: 'alt+7',
            altEight: 'alt+8',
            altNine: 'alt+9',
            enter: 'enter',
            escape: 'escape',
            delete: 'delete',
            backspace: 'backspace',
            home: 'home',
            pageUp: 'pageup',
            pageDown: 'pagedown',
            tab: 'tab',
            shiftTab: 'shift+tab',
            ctrlShiftUpArrow: 'ctrl+shift+uparrow',
            ctrlShiftDownArrow: 'ctrl+shift+downarrow',
            ctrlShiftLeftArrow: 'ctrl+shift+leftarrow',
            ctrlShiftRightArrow: 'ctrl+shift+rightarrow'
        };
        this.parent = parent;
        this.parent.element.tabIndex = this.parent.element.tabIndex === -1 ? 0 : this.parent.element.tabIndex;
        this.keyboardModule = new KeyboardEvents(this.parent.element, {
            keyAction: this.keyActionHandler.bind(this),
            keyConfigs: this.keyConfigs,
            eventName: 'keydown'
        });
        this.addEventListener();
    }
    KeyboardInteraction.prototype.keyActionHandler = function (e) {
        switch (e.action) {
            case 'downArrow':
            case 'shiftDownArrow':
                this.processDown(e, e.shiftKey);
                break;
            case 'upArrow':
            case 'shiftUpArrow':
                this.processUp(e, e.shiftKey);
                break;
            case 'leftArrow':
            case 'shiftLeftArrow':
                this.processLeft(e, e.shiftKey);
                break;
            case 'rightArrow':
            case 'shiftRightArrow':
                this.processRight(e, e.shiftKey);
                break;
            case 'ctrlLeftArrow':
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('previous'), e);
                if (this.parent.headerModule) {
                    this.parent.headerModule.element.querySelector('.e-prev button').focus();
                }
                break;
            case 'ctrlRightArrow':
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('next'), e);
                if (this.parent.headerModule) {
                    this.parent.headerModule.element.querySelector('.e-next button').focus();
                }
                break;
            case 'altOne':
            case 'altTwo':
            case 'altThree':
            case 'altFour':
            case 'altFive':
            case 'altSix':
            case 'altSeven':
            case 'altEight':
            case 'altNine':
                this.processViewNavigation(e);
                break;
            case 'enter':
                this.processEnter(e);
                break;
            case 'home':
                this.focusFirstCell();
                break;
            case 'tab':
            case 'shiftTab':
                this.processTab(e, e.shiftKey);
                break;
            case 'delete':
            case 'backspace':
                this.processDelete(e);
                break;
            case 'ctrlShiftUpArrow':
            case 'ctrlShiftDownArrow':
            case 'ctrlShiftLeftArrow':
            case 'ctrlShiftRightArrow':
                this.processCtrlShiftNavigationArrows(e);
                break;
            case 'escape':
                this.processEscape(e);
                break;
            case 'fTwelve':
                if (this.parent.allowInline && this.parent.inlineModule) {
                    e.preventDefault();
                    this.processFTwelve(e);
                }
                break;
        }
    };
    KeyboardInteraction.prototype.processFTwelve = function (e) {
        var target = e.target;
        if (target.classList.contains(WORK_CELLS_CLASS) || target.classList.contains(ALLDAY_CELLS_CLASS)) {
            this.parent.activeCellsData = this.getSelectedElements(target);
            var args = extend(this.parent.activeCellsData, { cancel: false, event: e });
            var inlineArgs = {
                element: args.element,
                groupIndex: args.groupIndex, type: 'Cell'
            };
            this.parent.notify(inlineClick, inlineArgs);
        }
        if (target.classList.contains(APPOINTMENT_CLASS)) {
            target.click();
            return;
        }
    };
    KeyboardInteraction.prototype.addEventListener = function () {
        this.parent.on(cellMouseDown, this.onCellMouseDown, this);
    };
    KeyboardInteraction.prototype.removeEventListener = function () {
        this.parent.off(cellMouseDown, this.onCellMouseDown);
    };
    KeyboardInteraction.prototype.onCellMouseDown = function (e) {
        if (e.event.shiftKey) {
            return;
        }
        this.initialTarget = this.getClosestCell(e.event);
        if (this.parent.activeViewOptions.readonly || this.parent.currentView === 'MonthAgenda' || !this.initialTarget) {
            return;
        }
        if (e.event.target.classList.contains(WORK_CELLS_CLASS) && e.event.which !== 3) {
            this.parent.removeSelectedClass();
            EventHandler.add(this.parent.getContentTable(), 'mousemove', this.onMouseSelection, this);
            EventHandler.add(this.parent.getContentTable(), 'mouseup', this.onMoveUp, this);
        }
        if (e.event.target.classList.contains(ALLDAY_CELLS_CLASS) && e.event.which !== 3) {
            this.parent.removeSelectedClass();
            var allDayRow = this.parent.getAllDayRow();
            EventHandler.add(allDayRow, 'mousemove', this.onMouseSelection, this);
            EventHandler.add(allDayRow, 'mouseup', this.onMoveUp, this);
        }
    };
    KeyboardInteraction.prototype.onMouseSelection = function (e) {
        var appointments = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
        addClass(appointments, 'e-allow-select');
        var selectionEdges = this.parent.boundaryValidation(e.pageY, e.pageX);
        if (selectionEdges.bottom || selectionEdges.top || selectionEdges.left || selectionEdges.right) {
            var parent_1 = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
            var yInBounds = parent_1.offsetHeight <= parent_1.scrollHeight && parent_1.scrollTop >= 0 &&
                parent_1.scrollTop + parent_1.offsetHeight <= parent_1.scrollHeight;
            var xInBounds = parent_1.offsetWidth <= parent_1.scrollWidth && parent_1.scrollLeft >= 0 &&
                parent_1.scrollLeft + parent_1.offsetWidth <= parent_1.scrollWidth;
            if (yInBounds && (selectionEdges.top || selectionEdges.bottom)) {
                parent_1.scrollTop += selectionEdges.top ? -e.target.offsetHeight : e.target.offsetHeight;
            }
            if (xInBounds && (selectionEdges.left || selectionEdges.right)) {
                parent_1.scrollLeft += selectionEdges.left ? -e.target.offsetWidth : e.target.offsetWidth;
            }
        }
        var target = this.getClosestCell(e);
        if (target) {
            this.selectCells(true, target);
        }
    };
    KeyboardInteraction.prototype.getClosestCell = function (e) {
        return closest(e.target, '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS);
    };
    KeyboardInteraction.prototype.onMoveUp = function (e) {
        var _this = this;
        var appointments = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
        removeClass(appointments, 'e-allow-select');
        if (e.target.classList.contains(WORK_CELLS_CLASS)) {
            EventHandler.remove(this.parent.getContentTable(), 'mousemove', this.onMouseSelection);
            EventHandler.remove(this.parent.getContentTable(), 'mouseup', this.onMoveUp);
        }
        if (e.target.classList.contains(ALLDAY_CELLS_CLASS)) {
            var allDayRow = this.parent.getAllDayRow();
            EventHandler.remove(allDayRow, 'mousemove', this.onMouseSelection);
            EventHandler.remove(allDayRow, 'mouseup', this.onMoveUp);
        }
        if (this.isPreventAction(e)) {
            return;
        }
        var queryStr = '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS + ',.' + HEADER_CELLS_CLASS;
        var target = closest(e.target, queryStr);
        var selectedCells = this.parent.getSelectedCells();
        if (selectedCells.length > 0 && selectedCells.indexOf(target) === -1) {
            target = selectedCells[selectedCells.length - 1];
        }
        if (this.parent.currentView === 'TimelineYear' && target.classList.contains(OTHERMONTH_CLASS)) {
            return;
        }
        this.parent.activeCellsData = this.getSelectedElements(target);
        var cellData = {};
        if (this.parent.eventWindow) {
            this.parent.eventWindow.convertToEventData(this.parent.activeCellsData, cellData);
        }
        var args = {
            data: cellData, element: this.parent.activeCellsData.element, event: e,
            requestType: cellSelect, showQuickPopup: false
        };
        this.parent.trigger(select, args, function (selectArgs) {
            var isPopupShow = selectArgs.showQuickPopup || _this.parent.quickInfoOnSelectionEnd;
            if (isPopupShow && selectedCells.length > 1) {
                var cellArgs = extend(_this.parent.activeCellsData, { cancel: false, event: e, name: 'cellClick' });
                _this.parent.notify(cellClick, cellArgs);
            }
        });
    };
    KeyboardInteraction.prototype.processEnter = function (e) {
        if ((this.parent.activeViewOptions.readonly && !e.target.classList.contains(APPOINTMENT_CLASS))
            || this.isPreventAction(e)) {
            return;
        }
        if (this.parent.currentView === 'TimelineYear' && e.target.classList.contains(OTHERMONTH_CLASS)) {
            return;
        }
        var target = e.target;
        if (closest(target, '.' + POPUP_WRAPPER_CLASS)) {
            if (target.classList.contains(QUICK_POPUP_EVENT_DETAILS_CLASS) ||
                target.classList.contains(EVENT_CREATE_CLASS) ||
                target.classList.contains(EDIT_EVENT_CLASS) ||
                target.classList.contains(DELETE_EVENT_CLASS) ||
                target.classList.contains(CLOSE_CLASS)) {
                target.click();
                e.preventDefault();
            }
            else if (target.classList.contains(SUBJECT_CLASS)) {
                this.parent.element.querySelector('.' + EVENT_CREATE_CLASS).click();
                e.preventDefault();
            }
            return;
        }
        if (target.classList.contains(RESOURCE_CELLS_CLASS) && target.classList.contains(RESOURCE_PARENT_CLASS)) {
            var resourceIcon = target.querySelector('.' + RESOURCE_TREE_ICON_CLASS);
            if (resourceIcon) {
                resourceIcon.click();
            }
            return;
        }
        if (target.classList.contains(WORK_CELLS_CLASS) || target.classList.contains(ALLDAY_CELLS_CLASS)) {
            this.parent.activeCellsData = this.getSelectedElements(target);
            var args = extend(this.parent.activeCellsData, { cancel: false, event: e });
            if (this.parent.allowInline) {
                var inlineArgs = {
                    element: args.element,
                    groupIndex: args.groupIndex, type: 'Cell'
                };
                this.parent.notify(inlineClick, inlineArgs);
            }
            else {
                if (this.parent.currentView === 'Year') {
                    target.click();
                }
                else {
                    this.parent.notify(cellClick, args);
                }
            }
            return;
        }
        if (target.classList.contains(INLINE_SUBJECT_CLASS) && this.parent.inlineModule) {
            this.parent.inlineModule.inlineCrudActions(target);
            return;
        }
        if (target.classList.contains(APPOINTMENT_CLASS) || target.classList.contains(MORE_EVENT_CLOSE_CLASS) ||
            target.classList.contains(ALLDAY_APPOINTMENT_SECTION_CLASS) || target.classList.contains(MORE_INDICATOR_CLASS)) {
            target.click();
            return;
        }
        if (target.classList.contains(MORE_EVENT_HEADER_DATE_CLASS)) {
            this.parent.setProperties({ selectedDate: this.parent.getDateFromElement(target) }, true);
            this.parent.changeView(this.parent.getNavigateView(), e);
            this.processEscape(e);
            return;
        }
    };
    KeyboardInteraction.prototype.getSelectedElements = function (target) {
        var cellDetails;
        if (this.selectedCells.length > 1 && target.classList.contains(SELECTED_CELL_CLASS)) {
            var start = this.parent.getCellDetails(this.selectedCells[0]);
            var end = this.parent.getCellDetails(this.selectedCells.slice(-1)[0]);
            start.endTime = end.endTime;
            start.element = target;
            cellDetails = start;
        }
        else {
            cellDetails = this.parent.getCellDetails(target);
        }
        return cellDetails;
    };
    KeyboardInteraction.prototype.getCells = function (isInverseTable, start, end) {
        var tableEle = this.parent.getContentTable();
        var isTimelineYear = this.parent.currentView === 'TimelineYear';
        var query = isTimelineYear && !isInverseTable ? '.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')' : 'td';
        var cells = [].slice.call(tableEle.querySelectorAll(query));
        var maxRow = tableEle.rows.length;
        var maxColumn = tableEle.rows[0].cells.length;
        if (start && start.classList.contains(ALLDAY_CELLS_CLASS)) {
            var allDayRow = this.parent.getAllDayRow();
            cells = [].slice.call(allDayRow.cells);
            maxRow = 1;
            maxColumn = allDayRow.cells.length;
        }
        var startIndex = cells.indexOf(start);
        var endIndex = cells.indexOf(end);
        var inverseCells = [];
        if (isInverseTable) {
            for (var i = 0; i < maxColumn; i++) {
                for (var j = 0; j < maxRow; j++) {
                    var cell = cells[maxColumn * j + i];
                    if (isTimelineYear && cell.classList.contains(OTHERMONTH_CLASS)) {
                        continue;
                    }
                    inverseCells.push(cell);
                }
            }
            startIndex = inverseCells.indexOf(start);
            endIndex = inverseCells.indexOf(end);
        }
        if (startIndex > endIndex) {
            var temp = startIndex;
            startIndex = endIndex;
            endIndex = temp;
        }
        var sCells = isInverseTable ? inverseCells : cells;
        return sCells.slice(startIndex, endIndex + 1);
    };
    KeyboardInteraction.prototype.focusFirstCell = function () {
        if (this.parent.currentView === 'Agenda') {
            var focusCell = this.parent.getContentTable().querySelector('.' + AGENDA_CELLS_CLASS);
            focusCell.setAttribute('tabindex', '0');
            focusCell.focus();
            return;
        }
        this.parent.eventBase.removeSelectedAppointmentClass();
        if (this.parent.activeView.isTimelineView() && this.parent.currentView !== 'TimelineYear') {
            var cell = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS +
                ' tr:not(.' + HIDDEN_CLASS + ') .' + WORK_CELLS_CLASS + ':not(.' + RESOURCE_GROUP_CELLS_CLASS + ')');
            this.selectCells(false, cell);
        }
        else if (this.parent.currentView.indexOf('Year') > -1) {
            var query = '.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')' + ':not(.' + RESOURCE_GROUP_CELLS_CLASS + ')';
            var isVerticalYear = this.parent.currentView === 'TimelineYear' && this.parent.activeViewOptions.orientation === 'Vertical';
            query += isVerticalYear ? '[data-date="' + this.parent.activeView.startDate().getTime() + '"]' : '';
            this.selectCells(false, this.parent.element.querySelector(query));
        }
        else {
            this.selectCells(false, this.parent.getWorkCellElements()[0]);
        }
    };
    KeyboardInteraction.prototype.isInverseTableSelect = function () {
        return this.parent.activeView.isInverseTableSelect;
    };
    /**
     * Internal method to select cells
     *
     * @param {boolean} isMultiple Accepts to select multiple cells or not
     * @param {HTMLTableCellElement} targetCell Accepts the target cells
     * @returns {void}
     * @private
     */
    KeyboardInteraction.prototype.selectCells = function (isMultiple, targetCell) {
        var _this = this;
        this.parent.removeSelectedClass();
        var target = (targetCell instanceof Array) ? targetCell.slice(-1)[0] : targetCell;
        if (isMultiple) {
            var initialId_1;
            var views_1 = ['Day', 'Week', 'WorkWeek', 'TimelineDay', 'TimelineWeek', 'TimelineWorkWeek', 'TimelineMonth', 'TimelineYear'];
            var args = { element: targetCell, requestType: 'mousemove', allowMultipleRow: true };
            this.parent.inlineModule.removeInlineAppointmentElement();
            this.parent.trigger(select, args, function (selectArgs) {
                var allowMultipleRow = (!selectArgs.allowMultipleRow) || (!_this.parent.allowMultiRowSelection);
                if (allowMultipleRow) {
                    var isTimelineYear = _this.parent.currentView === 'TimelineYear';
                    if (isTimelineYear && _this.parent.activeViewOptions.orientation === 'Horizontal' || _this.parent.currentView === 'Month') {
                        var isGroupYear = isTimelineYear && _this.parent.activeViewOptions.group.resources.length > 0;
                        target = isGroupYear ? _this.initialTarget :
                            _this.initialTarget.parentElement.children[target.cellIndex];
                    }
                    else if (views_1.indexOf(_this.parent.currentView) > -1) {
                        target = target.parentElement.children[_this.initialTarget.cellIndex];
                    }
                }
                var selectedCells = _this.getCells(_this.isInverseTableSelect(), _this.initialTarget, target);
                if (_this.parent.activeViewOptions.group.resources.length > 0) {
                    initialId_1 = _this.initialTarget.getAttribute('data-group-index');
                    var resourceSelectedCells = [];
                    for (var _i = 0, selectedCells_1 = selectedCells; _i < selectedCells_1.length; _i++) {
                        var cell = selectedCells_1[_i];
                        if (cell.getAttribute('data-group-index') === initialId_1) {
                            resourceSelectedCells.push(cell);
                        }
                    }
                    selectedCells = resourceSelectedCells;
                }
                if (!_this.parent.allowMultiCellSelection) {
                    selectedCells = [_this.initialTarget];
                }
                _this.selectedCells = selectedCells;
                if (selectedCells.length > 2 && !target.classList.contains(ALLDAY_CELLS_CLASS)) {
                    var allDayCells = _this.getAllDayCells(selectedCells);
                    if (_this.parent.activeViewOptions.group.resources.length > 0) {
                        var resourceAllDayCells = [];
                        for (var _a = 0, allDayCells_1 = allDayCells; _a < allDayCells_1.length; _a++) {
                            var cell = allDayCells_1[_a];
                            if (cell.getAttribute('data-group-index') === initialId_1) {
                                resourceAllDayCells.push(cell);
                            }
                        }
                        allDayCells = resourceAllDayCells;
                    }
                    selectedCells = selectedCells.concat(allDayCells);
                }
                if ((target.getAttribute('data-group-index') !== initialId_1) && _this.parent.activeViewOptions.group.resources.length > 0) {
                    target = _this.selectedCells[_this.selectedCells.length - 1];
                }
                _this.parent.addSelectedClass(selectedCells, target);
            });
        }
        else {
            var args = {
                element: target, requestType: cellSelect
            };
            // activeCellsData is not reset on schedule property changed(group properties)
            // const cellData: Record<string, any> = {};
            // const cellDetails: CellClickEventArgs = this.parent.getCellDetails(target);
            // if (this.parent.eventWindow && cellDetails) {
            //     if (this.parent.activeCellsData.element !== cellDetails.element) {
            //         this.parent.activeCellsData = cellDetails;
            //     }
            //     this.parent.eventWindow.convertToEventData(this.parent.activeCellsData as unknown as Record<string, any>, cellData);
            //     args.data = cellData;
            // }
            this.parent.trigger(select, args, function () {
                _this.initialTarget = target;
                _this.selectedCells = [target];
                _this.parent.addSelectedClass([target], target);
            });
        }
    };
    KeyboardInteraction.prototype.selectAppointment = function (isReverse, target) {
        var appointments = this.getAppointmentElements();
        if (appointments.length < 0) {
            return;
        }
        this.parent.eventBase.removeSelectedAppointmentClass();
        var nextAppEle;
        if (target.classList.contains(APPOINTMENT_CLASS)) {
            var targetIndex = appointments.indexOf(target);
            nextAppEle = appointments[(isReverse ? targetIndex - 1 : targetIndex + 1)];
        }
        else {
            nextAppEle = isReverse ? appointments[appointments.length - 1] : appointments[0];
        }
        if (nextAppEle) {
            this.parent.eventBase.addSelectedAppointments([nextAppEle], true);
            nextAppEle.focus();
            addClass([nextAppEle], AGENDA_SELECTED_CELL);
        }
    };
    KeyboardInteraction.prototype.selectAppointmentElementFromWorkCell = function (isReverse, target) {
        var _this = this;
        this.parent.eventBase.removeSelectedAppointmentClass();
        this.parent.removeSelectedClass();
        if (target.classList.contains(WORK_CELLS_CLASS) || target.classList.contains(ALLDAY_CELLS_CLASS)) {
            var appointmentElements_1 = this.getUniqueAppointmentElements();
            var filteredElements_1 = [];
            var selectedDate_1 = this.parent.getDateFromElement(target).getTime();
            var selectedSeriesEvents = this.parent.eventsProcessed.filter(function (eventObject) {
                return (!isReverse ? (eventObject[_this.parent.eventFields.startTime].getTime() >= selectedDate_1) :
                    (eventObject[_this.parent.eventFields.startTime].getTime() <= selectedDate_1));
            });
            selectedSeriesEvents.filter(function (event) {
                appointmentElements_1.filter(function (element) {
                    if (JSON.stringify(event.Guid) === JSON.stringify(element.getAttribute('data-guid'))) {
                        filteredElements_1.push(element);
                    }
                });
            });
            if (filteredElements_1.length > 0) {
                var selectedElement = isReverse ? filteredElements_1[filteredElements_1.length - 1] : filteredElements_1[0];
                var focusElements = this.getAppointmentElementsByGuid(selectedElement.getAttribute('data-guid'));
                this.parent.eventBase.addSelectedAppointments(focusElements, true);
                (focusElements[focusElements.length - 1]).focus();
            }
        }
    };
    KeyboardInteraction.prototype.getAllDayCells = function (cells) {
        var allDayRow = this.parent.getAllDayRow();
        if (!allDayRow) {
            return [];
        }
        var startCell = cells[0];
        var endCell = cells[cells.length - 1];
        var start = this.parent.getCellDetails(startCell);
        var end = this.parent.getCellDetails(endCell);
        if (end.endTime.getTime() - start.startTime.getTime() >= MS_PER_DAY) {
            var allDayCells = [].slice.call(allDayRow.cells);
            return allDayCells.slice(startCell.cellIndex, endCell.cellIndex + 1);
        }
        return [];
    };
    KeyboardInteraction.prototype.getAppointmentElements = function () {
        return [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
    };
    KeyboardInteraction.prototype.getAppointmentElementsByGuid = function (guid) {
        return [].slice.call(this.parent.element.querySelectorAll('div[data-guid="' + guid + '"]'));
    };
    KeyboardInteraction.prototype.getUniqueAppointmentElements = function () {
        var appointments = this.getAppointmentElements();
        var appointmentElements = [];
        appointments.map(function (value) { return value.getAttribute('data-guid'); }).filter(function (value, index, self) {
            if (self.indexOf(value) === index) {
                appointmentElements.push(appointments[parseInt(index.toString(), 10)]);
            }
        });
        return appointmentElements;
    };
    KeyboardInteraction.prototype.getWorkCellFromAppointmentElement = function (target) {
        var selectedObject = this.parent.eventBase.getEventByGuid(target.getAttribute('data-guid'));
        return this.parent.eventBase.selectWorkCellByTime([selectedObject]);
    };
    KeyboardInteraction.prototype.processViewNavigation = function (e) {
        var index = parseInt(e.key, 10) - 1;
        if (index < this.parent.views.length) {
            var view = this.parent.viewCollections[parseInt(index.toString(), 10)].option;
            this.parent.changeView(view, e, undefined, index);
            if (this.parent.headerModule) {
                this.parent.headerModule.element.querySelector('.e-active-view button').focus();
            }
        }
    };
    KeyboardInteraction.prototype.cancelUpDownAction = function (isTimelineYear) {
        var isVerticalYear = isTimelineYear && this.parent.activeViewOptions.orientation === 'Vertical';
        var isGroup = this.parent.activeViewOptions.group.resources.length > 0;
        if (isVerticalYear && isGroup || isTimelineYear && this.initialTarget.classList.contains(OTHERMONTH_CLASS)) {
            return true;
        }
        if (this.parent.activeView.isTimelineView() && !isTimelineYear || this.parent.currentView === 'MonthAgenda') {
            return true;
        }
        return false;
    };
    KeyboardInteraction.prototype.processUp = function (e, isMultiple) {
        var isTimelineYear = this.parent.currentView === 'TimelineYear';
        if (isMultiple && this.cancelUpDownAction(isTimelineYear)) {
            return;
        }
        var target = (e.target);
        var selectedElements = this.parent.getSelectedCells();
        var selectedEventElements = this.parent.eventBase.getSelectedAppointments();
        var moreEventWrapper = this.parent.element.querySelector('.' + MORE_POPUP_WRAPPER_CLASS);
        var quickPopupWrapper = this.getQuickPopupElement();
        if (selectedElements.length > 0 && !e.target.classList.contains(WORK_CELLS_CLASS)) {
            target = selectedElements[selectedElements.length - 1];
        }
        if (selectedEventElements.length > 0 && !moreEventWrapper.classList.contains(POPUP_OPEN) &&
            !quickPopupWrapper.classList.contains(POPUP_OPEN) &&
            ['Day', 'Week', 'WorkWeek', 'Month'].indexOf(this.parent.currentView) !== -1) {
            target = this.getWorkCellFromAppointmentElement(selectedEventElements[selectedEventElements.length - 1]);
            this.parent.eventBase.removeSelectedAppointmentClass();
        }
        if (!target) {
            return;
        }
        if (target.classList.contains(WORK_CELLS_CLASS) && !this.parent.element.querySelector('.' + POPUP_OPEN)) {
            var tableRows = this.parent.getTableRows();
            var curRowIndex = tableRows.indexOf(target.parentElement);
            var targetCell = void 0;
            if (isTimelineYear && isMultiple && this.parent.activeViewOptions.group.resources.length === 0) {
                targetCell = this.isInverseTableSelect() ? this.getVerticalUpDownCell(tableRows, target, curRowIndex, true) :
                    this.getHorizontalUpDownCell(tableRows, target, curRowIndex, true);
            }
            if ((curRowIndex > 0 || targetCell) && curRowIndex < tableRows.length) {
                targetCell = targetCell ? targetCell : (tableRows[curRowIndex - 1]).cells[target.cellIndex];
                if (this.parent.currentView === 'Year' && targetCell.classList.contains(OTHERMONTH_CLASS)) {
                    if (this.parent.activeView.getStartDate().getTime() < +targetCell.getAttribute('data-date')) {
                        targetCell = this.getYearUpDownCell(tableRows, curRowIndex - 1, target.cellIndex, true);
                    }
                    else {
                        return;
                    }
                }
                e.preventDefault();
                this.selectCells(isMultiple, targetCell);
            }
        }
        else if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
            this.selectAppointment(true, target);
        }
    };
    KeyboardInteraction.prototype.processDown = function (e, isMultiple) {
        var isTimelineYear = this.parent.currentView === 'TimelineYear';
        if (isMultiple && this.cancelUpDownAction(isTimelineYear)) {
            return;
        }
        var target = (e.target);
        var selectedCells = this.parent.getSelectedCells();
        var selectedElements = this.parent.eventBase.getSelectedAppointments();
        var moreEventWrapper = this.parent.element.querySelector('.' + MORE_POPUP_WRAPPER_CLASS);
        var quickPopupWrapper = this.getQuickPopupElement();
        if (selectedCells.length > 0 && !e.target.classList.contains(WORK_CELLS_CLASS)) {
            target = selectedCells[selectedCells.length - 1];
        }
        if (selectedElements.length > 0 && !moreEventWrapper.classList.contains(POPUP_OPEN) &&
            !quickPopupWrapper.classList.contains(POPUP_OPEN) &&
            ['Day', 'Week', 'WorkWeek', 'Month'].indexOf(this.parent.currentView) !== -1) {
            target = this.getWorkCellFromAppointmentElement(selectedElements[selectedElements.length - 1]);
            this.parent.eventBase.removeSelectedAppointmentClass();
        }
        var tableRows = this.parent.getTableRows();
        if (!target) {
            return;
        }
        if (target.classList.contains(WORK_CELLS_CLASS) && !this.parent.element.querySelector('.' + POPUP_OPEN)) {
            var curRowIndex = tableRows.indexOf(target.parentElement);
            var targetCell = void 0;
            if (isTimelineYear && isMultiple && this.parent.activeViewOptions.group.resources.length === 0) {
                targetCell = this.isInverseTableSelect() ? this.getVerticalUpDownCell(tableRows, target, curRowIndex, false)
                    : this.getHorizontalUpDownCell(tableRows, target, curRowIndex, false);
            }
            if (curRowIndex >= 0 && ((curRowIndex < tableRows.length - 1) || targetCell)) {
                targetCell = targetCell ? targetCell : (tableRows[curRowIndex + 1]).cells[target.cellIndex];
                if (this.parent.currentView === 'Year' && targetCell.classList.contains(OTHERMONTH_CLASS)) {
                    if (this.parent.activeView.getEndDate().getTime() > +targetCell.getAttribute('data-date')) {
                        targetCell = this.getYearUpDownCell(tableRows, curRowIndex + 1, target.cellIndex, false);
                    }
                    else {
                        return;
                    }
                }
                e.preventDefault();
                this.selectCells(isMultiple, targetCell);
            }
        }
        else if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
            this.selectAppointment(false, target);
        }
    };
    KeyboardInteraction.prototype.getYearUpDownCell = function (tableRows, rowIndex, cellIndex, isUp) {
        while (tableRows[parseInt(rowIndex.toString(), 10)] &&
            tableRows[parseInt(rowIndex.toString(), 10)].cells[parseInt(cellIndex.toString(), 10)].classList.contains(OTHERMONTH_CLASS)) {
            rowIndex = rowIndex + (isUp ? -1 : 1);
        }
        return tableRows[parseInt(rowIndex.toString(), 10)].cells[parseInt(cellIndex.toString(), 10)];
    };
    // eslint-disable-next-line max-len
    KeyboardInteraction.prototype.getHorizontalUpDownCell = function (tableRows, target, curRowIndex, isUp) {
        var row = tableRows[curRowIndex + (isUp ? -1 : 1)];
        var cell = row ? row.cells[target.cellIndex] : target;
        if (cell.classList.contains(OTHERMONTH_CLASS)) {
            var workCell = row.querySelector('.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')');
            var date = new Date(+workCell.getAttribute('data-date'));
            var query = '[data-date="' + new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime() + '"]';
            cell = cell.cellIndex < workCell.cellIndex ? workCell : row.querySelector(query);
        }
        return cell;
    };
    // eslint-disable-next-line max-len
    KeyboardInteraction.prototype.getVerticalUpDownCell = function (tableRows, target, curRowIndex, isUp) {
        var hasRow = isUp && curRowIndex > 0 || !isUp && curRowIndex < tableRows.length - 1;
        var targetCell = hasRow ? tableRows[curRowIndex + (isUp ? -1 : 1)].cells[target.cellIndex] : undefined;
        if (!targetCell || targetCell.classList.contains(OTHERMONTH_CLASS)) {
            var column = tableRows[parseInt(curRowIndex.toString(), 10)].cells[target.cellIndex - (isUp ? 1 : -1)];
            if (column) {
                var dateAttr = +target.getAttribute('data-date') - (isUp ? MS_PER_DAY : -MS_PER_DAY);
                return this.parent.getContentTable().querySelector('.' + WORK_CELLS_CLASS + '[data-date="' + dateAttr + '"]');
            }
            targetCell = target;
        }
        return targetCell;
    };
    KeyboardInteraction.prototype.processLeftRight = function (target) {
        var tableEle = (this.parent.currentView === 'Year' ? target.closest('tbody') : this.parent.getContentTable());
        var curRowIndex = target.parentNode.sectionRowIndex;
        var key = {
            element: tableEle,
            rowIndex: curRowIndex,
            columnIndex: target.cellIndex,
            maxIndex: tableEle.rows[parseInt(curRowIndex.toString(), 10)].cells.length
        };
        return key;
    };
    KeyboardInteraction.prototype.getQuickPopupElement = function () {
        return (this.parent.isAdaptive ? document.body : this.parent.element).querySelector('.' + POPUP_WRAPPER_CLASS);
    };
    KeyboardInteraction.prototype.isCancelLeftRightAction = function (e, isMultiple, isTimelineYear) {
        var prevent = this.parent.currentView === 'MonthAgenda' || isTimelineYear && this.initialTarget.classList.contains(OTHERMONTH_CLASS);
        if (this.parent.currentView === 'Agenda' || (isMultiple && prevent)) {
            return true;
        }
        if (this.isPreventAction(e) && isMultiple) {
            return true;
        }
        var moreEventWrapper = this.parent.element.querySelector('.' + MORE_POPUP_WRAPPER_CLASS);
        var quickPopupWrapper = this.getQuickPopupElement();
        if (moreEventWrapper.classList.contains(POPUP_OPEN) || quickPopupWrapper.classList.contains(POPUP_OPEN)) {
            return true;
        }
        return false;
    };
    KeyboardInteraction.prototype.processRight = function (e, isMultiple) {
        var isTimelineYear = this.parent.currentView === 'TimelineYear';
        if (this.isCancelLeftRightAction(e, isMultiple, isTimelineYear)) {
            return;
        }
        var selectedCells = this.parent.getSelectedCells();
        var targetCell;
        var selectedAppointments = this.parent.eventBase.getSelectedAppointments();
        var target = (e.target);
        if (selectedCells.length > 0 && !target.classList.contains(WORK_CELLS_CLASS) &&
            !target.classList.contains(ALLDAY_CELLS_CLASS)) {
            target = selectedCells[selectedCells.length - 1];
        }
        if (selectedAppointments.length > 0) {
            target = this.getWorkCellFromAppointmentElement(selectedAppointments[selectedAppointments.length - 1]);
            this.parent.eventBase.removeSelectedAppointmentClass();
            if (!target) {
                return;
            }
        }
        if (target.classList.contains(WORK_CELLS_CLASS) &&
            (e.target).classList.contains(WORK_CELLS_CLASS)) {
            var key = this.processLeftRight(target);
            var targetDate = new Date(+target.getAttribute('data-date'));
            var isMonthEnd = this.parent.currentView === 'Year' && targetDate.getTime() === lastDateOfMonth(targetDate).getTime();
            if (key.columnIndex >= 0 && key.columnIndex < key.maxIndex - 1 && !isMonthEnd) {
                targetCell = this.calculateNextPrevDate(target, key.element.rows[key.rowIndex].cells[target.cellIndex + 1], 'right');
                if (isTimelineYear && isMultiple && targetCell.classList.contains(OTHERMONTH_CLASS)) {
                    targetCell = this.getTimelineYearTargetCell(key, target, true);
                }
                if (!isNullOrUndefined(targetCell)) {
                    this.selectCells(isMultiple, targetCell);
                }
            }
            else if (key.columnIndex === key.maxIndex - 1 || isMonthEnd) {
                if (!this.isInverseTableSelect() && key.rowIndex < key.element.rows.length - 1 && !isMonthEnd) {
                    targetCell = this.calculateNextPrevDate(target, key.element.rows[key.rowIndex + 1].cells[0], 'right');
                    var changeTargetCell = isTimelineYear && isMultiple && targetCell.classList.contains(OTHERMONTH_CLASS);
                    targetCell = changeTargetCell ? this.getHorizontalLeftRightCell(key, target, true) : targetCell;
                    if (!isNullOrUndefined(targetCell)) {
                        this.selectCells(isMultiple, targetCell);
                    }
                }
                else if (!isMultiple) {
                    if (isMonthEnd && targetDate.getTime() !== this.parent.activeView.getEndDate().getTime()) {
                        this.selectCells(isMultiple, this.parent.element.querySelector(':not(.' + OTHERMONTH_CLASS + ')[data-date="' + (targetDate.getTime() + MS_PER_DAY) + '"]'));
                        return;
                    }
                    var rowIndex = this.isInverseTableSelect() ? key.rowIndex : 0;
                    this.parent.changeDate(this.parent.activeView.getNextPreviousDate('next'), e);
                    var tableEle = this.parent.getContentTable();
                    var cell = isMonthEnd ? tableEle.rows[parseInt(rowIndex.toString(), 10)].querySelector('.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')')
                        : tableEle.rows[parseInt(rowIndex.toString(), 10)].cells[0];
                    this.selectCells(false, cell);
                }
            }
        }
        else if (target.classList.contains(ALLDAY_CELLS_CLASS)) {
            var curColIndex = target.cellIndex;
            var allDayRow = this.parent.getAllDayRow();
            var maxColIndex = allDayRow.cells.length;
            if (curColIndex >= 0 && curColIndex < maxColIndex - 1) {
                this.selectCells(isMultiple, allDayRow.cells[curColIndex + 1]);
            }
            else if (curColIndex === maxColIndex - 1 && !isMultiple) {
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('next'), e);
                var allDayRow_1 = this.parent.getAllDayRow();
                this.selectCells(false, allDayRow_1.cells[0]);
            }
        }
    };
    KeyboardInteraction.prototype.processLeft = function (e, isMultiple) {
        var isTimelineYear = this.parent.currentView === 'TimelineYear';
        if (this.isCancelLeftRightAction(e, isMultiple, isTimelineYear)) {
            return;
        }
        var target = (e.target);
        var selectedCells = this.parent.getSelectedCells();
        var targetCell;
        if (selectedCells.length > 0 && !target.classList.contains(WORK_CELLS_CLASS) &&
            !target.classList.contains(ALLDAY_CELLS_CLASS)) {
            target = selectedCells[selectedCells.length - 1];
        }
        var selectedElements = this.parent.eventBase.getSelectedAppointments();
        if (selectedElements.length > 0) {
            target = this.getWorkCellFromAppointmentElement(selectedElements[selectedElements.length - 1]);
            this.parent.eventBase.removeSelectedAppointmentClass();
            if (!target) {
                return;
            }
        }
        if ((e.target).classList.contains(WORK_CELLS_CLASS) &&
            target.classList.contains(WORK_CELLS_CLASS)) {
            var key = this.processLeftRight(target);
            var targetDate = new Date(+target.getAttribute('data-date'));
            var isMonthStart = this.parent.currentView === 'Year' && targetDate.getTime() === firstDateOfMonth(targetDate).getTime();
            if (key.columnIndex > 0 && key.columnIndex < key.maxIndex && !isMonthStart) {
                targetCell = this.calculateNextPrevDate(target, key.element.rows[key.rowIndex].cells[target.cellIndex - 1], 'left');
                if (isTimelineYear && isMultiple && targetCell.classList.contains(OTHERMONTH_CLASS)) {
                    targetCell = this.getTimelineYearTargetCell(key, target, false);
                }
                if (!isNullOrUndefined(targetCell)) {
                    this.selectCells(isMultiple, targetCell);
                }
            }
            else if (key.columnIndex === 0 || isMonthStart) {
                if (!this.isInverseTableSelect() && key.rowIndex > 0) {
                    targetCell = this.calculateNextPrevDate(target, key.element.rows[key.rowIndex - 1].cells[key.maxIndex - 1], 'left');
                    var otherMonthCell = isTimelineYear && isMultiple && targetCell.classList.contains(OTHERMONTH_CLASS);
                    targetCell = otherMonthCell ? this.getHorizontalLeftRightCell(key, target, false) : targetCell;
                    if (!isNullOrUndefined(targetCell)) {
                        this.selectCells(isMultiple, targetCell);
                    }
                }
                else if (!isMultiple) {
                    if (isMonthStart && targetDate.getTime() !== this.parent.activeView.getStartDate().getTime()) {
                        this.selectCells(isMultiple, this.parent.element.querySelector('[data-date="' + (targetDate.getTime() - MS_PER_DAY) + '"]'));
                        return;
                    }
                    this.parent.changeDate(this.parent.activeView.getNextPreviousDate('previous'), e);
                    var tableEle = this.parent.getContentTable();
                    var rowIndex = this.isInverseTableSelect() ? key.rowIndex : tableEle.rows.length - 1;
                    var cell = tableEle.rows[parseInt(rowIndex.toString(), 10)].cells[key.maxIndex - 1];
                    if (isMonthStart) {
                        var tbody = this.parent.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody');
                        cell = tbody.item(tbody.length - 1).querySelector(':not(.' + OTHERMONTH_CLASS + ')[data-date="' + this.parent.activeView.getEndDate().getTime() + '"]');
                    }
                    this.selectCells(false, cell);
                }
            }
        }
        else if (target.classList.contains(ALLDAY_CELLS_CLASS)) {
            var curColIndex = target.cellIndex;
            var allDayRow = this.parent.getAllDayRow();
            var maxColIndex = allDayRow.cells.length;
            if (curColIndex > 0 && curColIndex < maxColIndex) {
                this.selectCells(isMultiple, allDayRow.cells[curColIndex - 1]);
            }
            else if (curColIndex === 0 && !isMultiple) {
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate('previous'), e);
                var allDayRow_2 = this.parent.getAllDayRow();
                this.selectCells(false, allDayRow_2.cells[maxColIndex - 1]);
            }
        }
    };
    KeyboardInteraction.prototype.getTimelineYearTargetCell = function (key, target, isRight) {
        return this.isInverseTableSelect() ? this.getVerticalLeftRightCell(target, isRight) :
            this.getHorizontalLeftRightCell(key, target, isRight);
    };
    KeyboardInteraction.prototype.getHorizontalLeftRightCell = function (key, target, isRight) {
        var row = key.element.rows[target.parentNode.sectionRowIndex + (isRight ? 1 : -1)];
        if (row) {
            var query = isRight ? '.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')'
                : '[data-date="' + ((+target.getAttribute('data-date')) - MS_PER_DAY) + '"]';
            return row.querySelector(query);
        }
        return target;
    };
    KeyboardInteraction.prototype.getVerticalLeftRightCell = function (target, isRight) {
        var date = new Date(+target.getAttribute('data-date'));
        var start = new Date(date.getFullYear(), date.getMonth() + (isRight ? 1 : -1), 1);
        var tableEle = this.parent.getContentTable();
        var targetCell = tableEle.querySelector('[data-date="' + start.getTime() + '"]');
        if (targetCell.parentNode.sectionRowIndex > target.parentNode.sectionRowIndex) {
            return targetCell;
        }
        return tableEle.querySelector('[data-date="' + new Date(start.getFullYear(), start.getMonth() + 1, 0).getTime() + '"]');
    };
    KeyboardInteraction.prototype.calculateNextPrevDate = function (currentCell, target, type) {
        var initialId = this.initialTarget.getAttribute('data-group-index');
        if (this.parent.activeViewOptions.group.resources.length > 0 && this.parent.currentView === 'Month') {
            if (currentCell && target && target.getAttribute('data-group-index') !== initialId) {
                var currentDate = this.parent.getDateFromElement(currentCell);
                var nextPrevDate = (type === 'right') ? new Date(currentDate.setDate(currentDate.getDate() + 1))
                    : new Date(currentDate.setDate(currentDate.getDate() - 1));
                target = [].slice.call(this.parent.element.querySelectorAll('td[data-date="'
                    + nextPrevDate.getTime().toString() + '"]' + '[data-group-index="' + initialId + '"]'))[0];
            }
        }
        return target;
    };
    KeyboardInteraction.prototype.getFocusableElements = function (container) {
        var queryString = 'a[href]:not([tabindex="-1"]),input:not([disabled]):not([tabindex="-1"]),' +
            'textarea:not([disabled]):not([tabindex="-1"]),button:not([disabled]):not([tabindex="-1"]),' +
            'select:not([disabled]):not([tabindex="-1"]),[tabindex]:not([tabindex="-1"]),[contentEditable=true]:not([tabindex="-1"])';
        return [].slice.call(container.querySelectorAll(queryString));
    };
    KeyboardInteraction.prototype.processTabOnPopup = function (e, popupElement) {
        var _this = this;
        var focusableElements = this.getFocusableElements(popupElement);
        focusableElements = focusableElements.filter(function (element) {
            var footerEle = _this.parent.element.querySelector('.' + POPUP_FOOTER_CLASS);
            if (footerEle && footerEle.offsetParent) {
                return !(element.classList.contains(EDIT_CLASS) || element.classList.contains(DELETE_CLASS));
            }
            else {
                return !(element.classList.contains(EDIT_EVENT_CLASS) || element.classList.contains(DELETE_EVENT_CLASS));
            }
        });
        var firstEle = focusableElements[0];
        var lastEle = focusableElements[focusableElements.length - 1];
        if (!isNullOrUndefined(lastEle) && document.activeElement === lastEle && !e.shiftKey) {
            e.preventDefault();
            firstEle.focus();
        }
        if (!isNullOrUndefined(firstEle) && document.activeElement === firstEle && e.shiftKey) {
            e.preventDefault();
            lastEle.focus();
        }
    };
    KeyboardInteraction.prototype.processTab = function (e, isReverse) {
        var target = e.target;
        if (target.classList.contains(INLINE_SUBJECT_CLASS) && this.parent.inlineModule) {
            target = target.closest('.e-appointment');
            this.parent.inlineModule.inlineCrudActions(e.target);
        }
        if (this.parent.currentView === 'TimelineYear' && target.classList.contains(OTHERMONTH_CLASS)) {
            if (target.classList.contains(SELECTED_CELL_CLASS)) {
                this.parent.removeSelectedClass();
            }
            return;
        }
        var popupWrapper = closest(target, '.' + POPUP_WRAPPER_CLASS + ',.' + MORE_POPUP_WRAPPER_CLASS);
        if (popupWrapper && popupWrapper.classList.contains(POPUP_OPEN)) {
            if (popupWrapper.classList.contains(MORE_POPUP_WRAPPER_CLASS)) {
                this.parent.eventBase.removeSelectedAppointmentClass();
            }
            this.processTabOnPopup(e, popupWrapper);
            return;
        }
        if (target.classList.contains(ROOT)) {
            this.parent.eventBase.removeSelectedAppointmentClass();
            return;
        }
        if (target.classList.contains(RESOURCE_CELLS_CLASS) && this.parent.activeView.isTimelineView()
            && this.parent.activeViewOptions.group.resources.length > 0) {
            var index = parseInt(target.getAttribute('data-group-index'), 10);
            var appSelector = "." + APPOINTMENT_CLASS + "[data-group-index=\"" + (isReverse ? index - 1 : index) + "\"]";
            var appElements = [].slice.call(this.parent.element.querySelectorAll(appSelector));
            if (appElements.length > 0) {
                this.parent.eventBase.removeSelectedAppointmentClass();
                var focusAppointment = isReverse ? appElements.slice(-1)[0] : appElements[0];
                this.parent.eventBase.addSelectedAppointments([focusAppointment], true);
                focusAppointment.focus();
                e.preventDefault();
            }
            else if (index + 1 === this.parent.resourceBase.lastResourceLevel.length) {
                this.parent.element.focus();
                e.preventDefault();
            }
            else if (this.parent.virtualScrollModule) {
                var virtual = this.parent.element.querySelector('.' + VIRTUAL_TRACK_CLASS);
                var averageRowHeight = Math.round(virtual.offsetHeight / this.parent.resourceBase.expandedResources.length);
                this.parent.element.querySelector('.e-content-wrap').scrollTop = ((isReverse ? index - 1 : index + 1) * averageRowHeight);
                this.parent.virtualScrollModule.virtualScrolling();
            }
            else {
                this.setScrollPosition(index);
            }
            return;
        }
        if (target.classList.contains(APPOINTMENT_CLASS)) {
            var appElements = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
            var isTimelineYear = this.parent.currentView === 'TimelineYear';
            var isTimeline = this.parent.activeView.isTimelineView() && !isTimelineYear;
            if ((isTimeline || isTimelineYear && this.parent.activeViewOptions.orientation === 'Vertical') && this.parent.activeViewOptions.group.resources.length > 0) {
                var index = parseInt(target.getAttribute('data-group-index'), 10);
                appElements = [].slice.call(this.parent.element.querySelectorAll("." + APPOINTMENT_CLASS + "[data-group-index=\"" + index + "\"]"));
                var resCellSelector = "." + RESOURCE_CELLS_CLASS + "[data-group-index=\"" + (isReverse ? index : index + 1) + "\"]";
                var resourceCell = this.parent.element.querySelector(resCellSelector);
                if (resourceCell && (isReverse && target.getAttribute('data-guid') === appElements[0].getAttribute('data-guid') ||
                    !isReverse && target.getAttribute('data-guid') === appElements.slice(-1)[0].getAttribute('data-guid'))) {
                    this.parent.eventBase.removeSelectedAppointmentClass();
                    if (this.parent.virtualScrollModule) {
                        resourceCell.focus({ preventScroll: true });
                    }
                    else {
                        resourceCell.focus();
                    }
                    if (this.parent.activeView.isTimelineView() && this.parent.activeViewOptions.group.resources.length > 0 &&
                        isNullOrUndefined(this.parent.virtualScrollModule)) {
                        this.setScrollPosition(index);
                    }
                    e.preventDefault();
                    return;
                }
            }
            var selectedAppointments = this.parent.eventBase.getSelectedAppointments();
            if (selectedAppointments.length > 0) {
                target = selectedAppointments[selectedAppointments.length - 1];
            }
            this.parent.eventBase.removeSelectedAppointmentClass();
            if (!isReverse && target.getAttribute('data-guid') === appElements[appElements.length - 1].getAttribute('data-guid') ||
                isReverse && target.getAttribute('data-guid') === appElements[0].getAttribute('data-guid')) {
                return;
            }
            if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
                this.selectAppointment(isReverse, target);
                e.preventDefault();
            }
            return;
        }
        var selectedCells = this.parent.getSelectedCells();
        if (selectedCells.length > 0 && !target.classList.contains(APPOINTMENT_CLASS)) {
            target = selectedCells[selectedCells.length - 1];
            this.selectAppointmentElementFromWorkCell(isReverse, target);
            e.preventDefault();
            return;
        }
        if (target && !target.classList.contains(RESOURCE_CELLS_CLASS) && this.parent.activeView.isTimelineView()
            && this.parent.activeViewOptions.group.resources.length > 0) {
            this.processTabOnResourceCells(target, isReverse);
        }
    };
    KeyboardInteraction.prototype.processDelete = function (e) {
        var activeEle = document.activeElement;
        if (this.parent.currentView === 'MonthAgenda') {
            var selectedEle = this.parent.eventBase.getSelectedEvents().element;
            activeEle = ((selectedEle && isNullOrUndefined(selectedEle.length)) ? selectedEle : selectedEle[0]);
        }
        if (activeEle && activeEle.classList.contains(APPOINTMENT_CLASS)) {
            addClass([activeEle], APPOINTMENT_BORDER);
            this.parent.activeEventData = this.parent.eventBase.getSelectedEvents();
            if (this.parent.activeViewOptions.readonly || activeEle.classList.contains(READ_ONLY) ||
                !this.parent.eventSettings.allowDeleting) {
                return;
            }
            this.parent.quickPopup.deleteClick(e);
        }
    };
    KeyboardInteraction.prototype.processCtrlShiftNavigationArrows = function (e) {
        if (this.parent.activeViewOptions.group.resources.length > 0 && document.activeElement.classList.contains(APPOINTMENT_CLASS)) {
            var groupIndex = parseInt(document.activeElement.getAttribute('data-group-index'), 10);
            var index = (e.action === 'ctrlShiftLeftArrow' || e.action === 'ctrlShiftUpArrow') ? groupIndex - 1 : groupIndex + 1;
            index = index < 0 ? 0 : index > this.parent.resourceBase.lastResourceLevel.length ?
                this.parent.resourceBase.lastResourceLevel.length : index;
            var eventEle = [];
            while (eventEle.length === 0 && index >= 0 && index <= this.parent.resourceBase.lastResourceLevel.length) {
                eventEle = [].slice.call(this.parent.element.querySelectorAll("." + APPOINTMENT_CLASS + "[data-group-index=\"" + index + "\"]"));
                index = (e.action === 'ctrlShiftLeftArrow' || e.action === 'ctrlShiftUpArrow') ? index - 1 : index + 1;
            }
            var nextAppEle = eventEle[0];
            if (nextAppEle) {
                this.parent.eventBase.removeSelectedAppointmentClass();
                this.parent.eventBase.addSelectedAppointments([nextAppEle], true);
                nextAppEle.focus();
            }
        }
        else if (this.parent.activeViewOptions.group.resources.length > 0 &&
            !document.activeElement.classList.contains(APPOINTMENT_CLASS)) {
            var index = this.parent.resourceBase.renderedResources[0].groupIndex;
            var appSelector = "." + APPOINTMENT_CLASS + "[data-group-index=\"" + index + "\"]";
            var appElements = [].slice.call(this.parent.element.querySelectorAll(appSelector));
            if (appElements.length > 0) {
                this.parent.eventBase.removeSelectedAppointmentClass();
                var focusAppointment = appElements[0];
                this.parent.eventBase.addSelectedAppointments([focusAppointment], true);
                focusAppointment.focus();
                e.preventDefault();
            }
        }
    };
    KeyboardInteraction.prototype.processEscape = function (event) {
        this.parent.quickPopup.onClosePopup(event);
        this.parent.quickPopup.morePopup.hide();
        if (this.parent.headerModule) {
            this.parent.headerModule.hideHeaderPopup();
        }
        if (this.parent.inlineModule) {
            this.parent.inlineModule.removeInlineAppointmentElement();
        }
    };
    KeyboardInteraction.prototype.isPreventAction = function (e) {
        var target = closest(e.target, '.' + RESOURCE_GROUP_CELLS_CLASS);
        if (this.parent.activeView.isTimelineView() && !isNullOrUndefined(target)) {
            return true;
        }
        return false;
    };
    KeyboardInteraction.prototype.processTabOnResourceCells = function (target, isReverse) {
        var tabElements = [].slice.call(this.parent.element.querySelectorAll('[tabIndex="0"]'));
        var currentTabIndex = tabElements.indexOf(target);
        var nextTabElement = !isReverse ? tabElements[currentTabIndex + 1] : tabElements[currentTabIndex - 1];
        if (nextTabElement && nextTabElement.classList.contains(RESOURCE_CELLS_CLASS)) {
            var groupIndex = parseInt(nextTabElement.getAttribute('data-group-index'), 10);
            if (this.parent.virtualScrollModule) {
                var resColWrap_1 = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
                var resCells = [].slice.call(this.parent.element.querySelectorAll('.' + RESOURCE_CELLS_CLASS));
                resCells.forEach(function (element) {
                    if (element.getBoundingClientRect().top < resColWrap_1.getBoundingClientRect().top) {
                        element.setAttribute('tabindex', '-1');
                    }
                });
            }
            else {
                this.setScrollPosition(groupIndex);
            }
        }
    };
    KeyboardInteraction.prototype.setScrollPosition = function (index) {
        var workCell = this.parent.element.querySelector("." + WORK_CELLS_CLASS + "[data-group-index=\"" + index + "\"]");
        if (workCell) {
            this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS).scrollTop = workCell.offsetTop;
        }
    };
    /**
     * Get module name.
     *
     * @returns {string} Returns the module name.
     */
    KeyboardInteraction.prototype.getModuleName = function () {
        return 'keyboard';
    };
    /**
     * To destroy the keyboard module.
     *
     * @returns {void}
     * @private
     */
    KeyboardInteraction.prototype.destroy = function () {
        this.removeEventListener();
        this.selectedCells = [];
        this.keyboardModule.destroy();
    };
    return KeyboardInteraction;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * data module is used to generate query and data source.
 *
 * @private
 */
var Data = /** @__PURE__ @class */ (function () {
    /**
     * Constructor for data module
     *
     * @param {Schedule} parent Accepts the schedule element instance
     * @param {Object | DataManager} dataSource Accepts the datasource as JSON objects or DataManager
     * @param {Query} query Accepts the query to process the data
     * @private
     */
    function Data(parent, dataSource, query) {
        this.parent = parent;
        this.initDataManager(dataSource, query);
    }
    /**
     * The function used to initialize dataManager and query
     *
     * @param {Object | DataManager} dataSource Accepts the datasource as JSON objects or DataManager
     * @param {Query} query Accepts the query to process the data
     * @returns {void}
     * @private
     */
    Data.prototype.initDataManager = function (dataSource, query) {
        this.dataManager = dataSource instanceof DataManager ? dataSource : new DataManager(dataSource);
        this.query = query instanceof Query ? query : new Query();
    };
    /**
     * The function used to generate updated Query from schedule model
     *
     * @param {Date} startDate Accepts the start date
     * @param {Date} endDate Accepts the end date
     * @returns {void}
     * @private
     */
    Data.prototype.generateQuery = function (startDate, endDate) {
        var query = this.query.clone();
        if (this.parent && startDate && endDate) {
            if (this.parent.activeViewOptions && this.parent.activeViewOptions.enableLazyLoading &&
                !isNullOrUndefined(this.parent.activeViewOptions.group.resources) &&
                this.parent.activeViewOptions.group.resources.length > 0 && this.parent.resourceBase &&
                this.parent.resourceBase.resourceCollection.length > 0 && this.parent.resourceBase.renderedResources.length > 0) {
                var resIdCollection_1 = [];
                this.parent.resourceBase.resourceCollection.forEach(function () { return resIdCollection_1.push([]); });
                this.parent.resourceBase.renderedResources.forEach(function (resource) {
                    resIdCollection_1.forEach(function (resId, index) {
                        var groupId = resource.groupOrder[parseInt(index.toString(), 10)];
                        if (groupId && resId.indexOf(groupId) < 0) {
                            resId.push(groupId);
                        }
                    });
                });
                this.parent.resourceBase.resourceCollection.forEach(function (resource, index) {
                    query.addParams(resource.field, resIdCollection_1[parseInt(index.toString(), 10)].toString());
                });
            }
            if (this.parent.timezone) {
                startDate = this.parent.tzModule.remove(new Date(+startDate.getTime()), this.parent.timezone);
                endDate = this.parent.tzModule.remove(new Date(+endDate.getTime()), this.parent.timezone);
            }
            if (this.parent.eventSettings.includeFiltersInQuery) {
                var dateQuery = this.getStartEndQuery(startDate, endDate);
                var recurrenceQuery = new Predicate(this.parent.eventFields.recurrenceRule, 'notequal', null).and(new Predicate(this.parent.eventFields.recurrenceRule, 'notequal', ''));
                return query.where(dateQuery.or(recurrenceQuery));
            }
            query.addParams('StartDate', startDate.toISOString());
            query.addParams('EndDate', endDate.toISOString());
        }
        return query;
    };
    /**
     * The function used to generate updated Query from schedule model
     *
     * @param {Date} startDate Accepts the start date
     * @param {Date} endDate Accepts the end date
     * @returns {void}
     * @private
     */
    Data.prototype.getStartEndQuery = function (startDate, endDate) {
        var fieldMapping = this.parent.eventFields;
        var dateQuery = new Predicate(fieldMapping.startTime, 'greaterthanorequal', startDate)
            .and(new Predicate(fieldMapping.endTime, 'greaterthanorequal', startDate))
            .and(new Predicate(fieldMapping.startTime, 'lessthan', endDate))
            .or(new Predicate(fieldMapping.startTime, 'lessthanorequal', startDate)
            .and(new Predicate(fieldMapping.endTime, 'greaterthan', startDate)));
        return dateQuery;
    };
    /**
     * The function used to get dataSource by executing given Query
     *
     * @param  {Query} query - A Query that specifies to generate dataSource
     * @returns {void}
     * @private
     */
    Data.prototype.getData = function (query) {
        return this.dataManager.executeQuery(query);
    };
    /**
     * To destroy the crud module.
     *
     * @returns {void}
     * @private
     */
    Data.prototype.destroy = function () {
        this.dataManager = null;
        this.query = null;
    };
    return Data;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/** @private */
var Gregorian = /** @__PURE__ @class */ (function () {
    function Gregorian() {
    }
    Gregorian.prototype.firstDateOfMonth = function (date) {
        return new Date(date.getFullYear(), date.getMonth());
    };
    Gregorian.prototype.lastDateOfMonth = function (dt) {
        return new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    };
    Gregorian.prototype.isMonthStart = function (date) {
        return (date.getDate() === 1);
    };
    Gregorian.prototype.getLeapYearDaysCount = function () {
        return 366;
    };
    Gregorian.prototype.getYearDaysCount = function (date, interval) {
        return ((date.getFullYear() + interval) % 4 === 0) ? 366 : 365;
    };
    Gregorian.prototype.getDate = function (date) {
        return date.getDate();
    };
    Gregorian.prototype.getMonth = function (date) {
        return (date.getMonth() + 1);
    };
    Gregorian.prototype.getFullYear = function (date) {
        return date.getFullYear();
    };
    Gregorian.prototype.getYearLastDate = function (date, interval) {
        return new Date(date.getFullYear() + interval, 0, 0);
    };
    Gregorian.prototype.getMonthDaysCount = function (date) {
        return this.lastDateOfMonth(date).getDate();
    };
    Gregorian.prototype.getMonthStartDate = function (date) {
        return new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes());
    };
    Gregorian.prototype.getMonthEndDate = function (date) {
        date.setDate(1);
        return new Date(date.setMonth(date.getMonth() + 1));
    };
    Gregorian.prototype.getExpectedDays = function (date, days) {
        return days;
    };
    Gregorian.prototype.setDate = function (dateObj, date) {
        dateObj.setDate(date);
    };
    Gregorian.prototype.setValidDate = function (date, interval, startDate, monthValue, beginDate) {
        if (!isNullOrUndefined(beginDate)) {
            date.setMonth((beginDate ? monthValue : date.getMonth()) + interval);
        }
        else {
            date.setMonth(date.getMonth() + interval, startDate);
        }
    };
    Gregorian.prototype.setMonth = function (date, interval, startDate) {
        date.setDate(1);
        date.setFullYear(date.getFullYear());
        date.setMonth(interval - 1);
        var maxDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        date.setDate(Math.min(startDate, maxDay));
    };
    Gregorian.prototype.addYears = function (date, interval) {
        date.setFullYear(date.getFullYear() + interval);
    };
    Gregorian.prototype.isSameMonth = function (date1, date2) {
        return (date1.getMonth() === date2.getMonth());
    };
    Gregorian.prototype.checkMonth = function (date, months) {
        return (months.indexOf(date.getMonth() + 1) === -1);
    };
    Gregorian.prototype.compareMonth = function (date1, date2) {
        return (date1.getMonth() > date2.getMonth());
    };
    Gregorian.prototype.isSameYear = function (date1, date2) {
        return (date1.getFullYear() === date2.getFullYear());
    };
    Gregorian.prototype.isLastMonth = function (date) {
        return (date.getMonth() === 11);
    };
    Gregorian.prototype.isLeapYear = function (year, interval) {
        return ((year + interval) % 4 === 0);
    };
    return Gregorian;
}());
/** @private */
var Islamic = /** @__PURE__ @class */ (function () {
    function Islamic() {
    }
    Islamic.prototype.firstDateOfMonth = function (date) {
        var hDate = this.getHijriDate(date);
        var gDate = HijriParser.toGregorian(hDate.year, hDate.month, 1);
        return gDate;
    };
    Islamic.prototype.lastDateOfMonth = function (date) {
        var hDate = this.getHijriDate(date);
        var daysInMonth = this.getDaysInMonth(hDate.month, hDate.year);
        var gDate = HijriParser.toGregorian(hDate.year, hDate.month, daysInMonth);
        var finalGDate = new Date(gDate.getTime());
        finalGDate = new Date(finalGDate.setDate(finalGDate.getDate() + 1));
        var finalHDate = this.getHijriDate(finalGDate);
        if (hDate.month === finalHDate.month) {
            return finalGDate;
        }
        finalHDate = this.getHijriDate(gDate);
        if (hDate.month === finalHDate.month) {
            return gDate;
        }
        return new Date(gDate.setDate(gDate.getDate() - 1));
    };
    Islamic.prototype.isMonthStart = function (date) {
        var hijriDate = this.getHijriDate(date);
        return (hijriDate.date === 1);
    };
    Islamic.prototype.getLeapYearDaysCount = function () {
        return 355;
    };
    Islamic.prototype.getYearDaysCount = function (date, interval) {
        var hDate = this.getHijriDate(date);
        return this.isLeapYear(hDate.year, interval) ? 355 : 354;
    };
    Islamic.prototype.getDate = function (date) {
        var hijriDate = this.getHijriDate(date);
        return hijriDate.date;
    };
    Islamic.prototype.getMonth = function (date) {
        var hDate = this.getHijriDate(date);
        return hDate.month;
    };
    Islamic.prototype.getFullYear = function (date) {
        var hDate = this.getHijriDate(date);
        return hDate.year;
    };
    Islamic.prototype.getYearLastDate = function (date, interval) {
        var hDate = this.getHijriDate(date);
        var gDate = HijriParser.toGregorian(hDate.year + interval, 1, 0);
        return gDate;
    };
    Islamic.prototype.getMonthDaysCount = function (date) {
        var maxDate = this.lastDateOfMonth(date);
        var hijriDate = this.getHijriDate(maxDate);
        return hijriDate.date;
    };
    Islamic.prototype.getMonthStartDate = function (date) {
        var firstDate = this.firstDateOfMonth(date);
        return new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate(), date.getHours(), date.getMinutes());
    };
    Islamic.prototype.getMonthEndDate = function (date) {
        var lastDate = this.lastDateOfMonth(date);
        lastDate.setDate(lastDate.getDate() + 1);
        return new Date(lastDate.setMonth(lastDate.getMonth()));
    };
    Islamic.prototype.getExpectedDays = function (date, days) {
        var hDate = this.getHijriDate(date);
        var day = [];
        for (var i = 0; i < days.length; i++) {
            var gDate = HijriParser.toGregorian(hDate.year, hDate.month, days[parseInt(i.toString(), 10)]);
            day.push(gDate.getDate());
        }
        return day;
    };
    Islamic.prototype.setDate = function (dateObj, date) {
        var hDate = this.getHijriDate(dateObj);
        var gDate = HijriParser.toGregorian(hDate.year, hDate.month, date);
        this.updateDateObj(dateObj, gDate);
    };
    Islamic.prototype.setValidDate = function (date, interval, startDate, monthValue, beginDate) {
        var firstDate = (!isNullOrUndefined(beginDate)) ? this.firstDateOfMonth(beginDate) : date;
        var hDate = this.getHijriDate(firstDate);
        var gDate = HijriParser.toGregorian(hDate.year, hDate.month + interval, startDate);
        this.updateDateObj(date, gDate);
    };
    Islamic.prototype.setMonth = function (date, interval, startDate) {
        var hDate = this.getHijriDate(date);
        var gDate = HijriParser.toGregorian(hDate.year, interval, startDate);
        this.updateDateObj(date, gDate);
    };
    Islamic.prototype.addYears = function (date, interval, monthValue) {
        var hDate = this.getHijriDate(date);
        var gDate = HijriParser.toGregorian(hDate.year + interval, monthValue, 1);
        this.updateDateObj(date, gDate);
    };
    Islamic.prototype.isSameMonth = function (date1, date2) {
        var currentHijri = this.getHijriDate(date1);
        var tempHijri = this.getHijriDate(date2);
        return (currentHijri.month === tempHijri.month);
    };
    Islamic.prototype.checkMonth = function (date, months) {
        var hDate = this.getHijriDate(date);
        return (months.indexOf(hDate.month) === -1);
    };
    Islamic.prototype.compareMonth = function (date1, date2) {
        var hDate = this.getHijriDate(date1);
        var hDate1 = this.getHijriDate(date2);
        return (hDate.month > hDate1.month);
    };
    Islamic.prototype.isSameYear = function (date1, date2) {
        var hDate = this.getHijriDate(date1);
        var hDate1 = this.getHijriDate(date2);
        return (hDate.year === hDate1.year);
    };
    Islamic.prototype.isLastMonth = function (date) {
        var hDate = this.getHijriDate(date);
        return (hDate.month === 12);
    };
    Islamic.prototype.updateDateObj = function (date, gDate) {
        date.setFullYear(gDate.getFullYear(), gDate.getMonth(), gDate.getDate());
    };
    Islamic.prototype.isLeapYear = function (year, interval) {
        return (14 + 11 * (year + interval)) % 30 < 11;
    };
    Islamic.prototype.getDaysInMonth = function (month, year) {
        var length = 0;
        length = 29 + ((month + 1) % 2);
        if (month === 11 && this.isLeapYear(year, 0)) {
            length++;
        }
        return length;
    };
    Islamic.prototype.getHijriDate = function (date) {
        return HijriParser.getHijriDate(date);
    };
    return Islamic;
}());

/**
 * Time zone
 */
var Timezone = /** @__PURE__ @class */ (function () {
    function Timezone() {
        this.timezoneData = [];
        this.timezoneData = this.getTimezoneData();
    }
    Timezone.prototype.offset = function (date, timezone) {
        var localOffset = date.getTimezoneOffset();
        try {
            var convertedDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
            if (!isNaN(convertedDate.getTime())) {
                return ((date.getTime() - convertedDate.getTime()) / 60000) + localOffset;
            }
            return 0;
        }
        catch (error) {
            return 0;
        }
    };
    Timezone.prototype.convert = function (date, fromOffset, toOffset) {
        if (typeof fromOffset === 'string') {
            fromOffset = this.offset(date, fromOffset);
        }
        if (typeof toOffset === 'string') {
            toOffset = this.offset(date, toOffset);
        }
        var fromLocalOffset = date.getTimezoneOffset();
        date = new Date(date.getTime() + (fromOffset - toOffset) * 60000);
        var toLocalOffset = date.getTimezoneOffset();
        return new Date(date.getTime() + (toLocalOffset - fromLocalOffset) * 60000);
    };
    Timezone.prototype.add = function (date, timezone) {
        return this.convert(date, date.getTimezoneOffset(), timezone);
    };
    Timezone.prototype.remove = function (date, timezone) {
        return this.convert(date, timezone, date.getTimezoneOffset());
    };
    Timezone.prototype.removeLocalOffset = function (date) {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    };
    Timezone.prototype.getLocalTimezoneName = function () {
        return window.Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC' : 'UTC';
    };
    Timezone.prototype.getTimezoneData = function () {
        return timezoneData;
    };
    return Timezone;
}());
var timezoneData = [
    { Value: 'Pacific/Niue', Text: '(UTC-11:00) Niue' },
    { Value: 'Pacific/Pago_Pago', Text: '(UTC-11:00) Pago Pago' },
    { Value: 'Pacific/Honolulu', Text: '(UTC-10:00) Hawaii Time' },
    { Value: 'Pacific/Rarotonga', Text: '(UTC-10:00) Rarotonga' },
    { Value: 'Pacific/Tahiti', Text: '(UTC-10:00) Tahiti' },
    { Value: 'Pacific/Marquesas', Text: '(UTC-09:30) Marquesas' },
    { Value: 'America/Anchorage', Text: '(UTC-09:00) Alaska Time' },
    { Value: 'Pacific/Gambier', Text: '(UTC-09:00) Gambier' },
    { Value: 'America/Los_Angeles', Text: '(UTC-08:00) Pacific Time' },
    { Value: 'America/Tijuana', Text: '(UTC-08:00) Pacific Time - Tijuana' },
    { Value: 'America/Vancouver', Text: '(UTC-08:00) Pacific Time - Vancouver' },
    { Value: 'America/Whitehorse', Text: '(UTC-08:00) Pacific Time - Whitehorse' },
    { Value: 'Pacific/Pitcairn', Text: '(UTC-08:00) Pitcairn' },
    { Value: 'America/Denver', Text: '(UTC-07:00) Mountain Time' },
    { Value: 'America/Phoenix', Text: '(UTC-07:00) Mountain Time - Arizona' },
    { Value: 'America/Mazatlan', Text: '(UTC-07:00) Mountain Time - Chihuahua, Mazatlan' },
    { Value: 'America/Dawson_Creek', Text: '(UTC-07:00) Mountain Time - Dawson Creek' },
    { Value: 'America/Edmonton', Text: '(UTC-07:00) Mountain Time - Edmonton' },
    { Value: 'America/Hermosillo', Text: '(UTC-07:00) Mountain Time - Hermosillo' },
    { Value: 'America/Yellowknife', Text: '(UTC-07:00) Mountain Time - Yellowknife' },
    { Value: 'America/Belize', Text: '(UTC-06:00) Belize' },
    { Value: 'America/Chicago', Text: '(UTC-06:00) Central Time' },
    { Value: 'America/Mexico_City', Text: '(UTC-06:00) Central Time - Mexico City' },
    { Value: 'America/Regina', Text: '(UTC-06:00) Central Time - Regina' },
    { Value: 'America/Tegucigalpa', Text: '(UTC-06:00) Central Time - Tegucigalpa' },
    { Value: 'America/Winnipeg', Text: '(UTC-06:00) Central Time - Winnipeg' },
    { Value: 'America/Costa_Rica', Text: '(UTC-06:00) Costa Rica' },
    { Value: 'America/El_Salvador', Text: '(UTC-06:00) El Salvador' },
    { Value: 'Pacific/Galapagos', Text: '(UTC-06:00) Galapagos' },
    { Value: 'America/Guatemala', Text: '(UTC-06:00) Guatemala' },
    { Value: 'America/Managua', Text: '(UTC-06:00) Managua' },
    { Value: 'America/Cancun', Text: '(UTC-05:00) America Cancun' },
    { Value: 'America/Bogota', Text: '(UTC-05:00) Bogota' },
    { Value: 'Pacific/Easter', Text: '(UTC-05:00) Easter Island' },
    { Value: 'America/New_York', Text: '(UTC-05:00) Eastern Time' },
    { Value: 'America/Iqaluit', Text: '(UTC-05:00) Eastern Time - Iqaluit' },
    { Value: 'America/Toronto', Text: '(UTC-05:00) Eastern Time - Toronto' },
    { Value: 'America/Guayaquil', Text: '(UTC-05:00) Guayaquil' },
    { Value: 'America/Havana', Text: '(UTC-05:00) Havana' },
    { Value: 'America/Jamaica', Text: '(UTC-05:00) Jamaica' },
    { Value: 'America/Lima', Text: '(UTC-05:00) Lima' },
    { Value: 'America/Nassau', Text: '(UTC-05:00) Nassau' },
    { Value: 'America/Panama', Text: '(UTC-05:00) Panama' },
    { Value: 'America/Port-au-Prince', Text: '(UTC-05:00) Port-au-Prince' },
    { Value: 'America/Rio_Branco', Text: '(UTC-05:00) Rio Branco' },
    { Value: 'America/Halifax', Text: '(UTC-04:00) Atlantic Time - Halifax' },
    { Value: 'America/Barbados', Text: '(UTC-04:00) Barbados' },
    { Value: 'Atlantic/Bermuda', Text: '(UTC-04:00) Bermuda' },
    { Value: 'America/Boa_Vista', Text: '(UTC-04:00) Boa Vista' },
    { Value: 'America/Caracas', Text: '(UTC-04:00) Caracas' },
    { Value: 'America/Curacao', Text: '(UTC-04:00) Curacao' },
    { Value: 'America/Grand_Turk', Text: '(UTC-04:00) Grand Turk' },
    { Value: 'America/Guyana', Text: '(UTC-04:00) Guyana' },
    { Value: 'America/La_Paz', Text: '(UTC-04:00) La Paz' },
    { Value: 'America/Manaus', Text: '(UTC-04:00) Manaus' },
    { Value: 'America/Martinique', Text: '(UTC-04:00) Martinique' },
    { Value: 'America/Port_of_Spain', Text: '(UTC-04:00) Port of Spain' },
    { Value: 'America/Porto_Velho', Text: '(UTC-04:00) Porto Velho' },
    { Value: 'America/Puerto_Rico', Text: '(UTC-04:00) Puerto Rico' },
    { Value: 'America/Santo_Domingo', Text: '(UTC-04:00) Santo Domingo' },
    { Value: 'America/Thule', Text: '(UTC-04:00) Thule' },
    { Value: 'America/St_Johns', Text: '(UTC-03:30) Newfoundland Time - St. Johns' },
    { Value: 'America/Araguaina', Text: '(UTC-03:00) Araguaina' },
    { Value: 'America/Asuncion', Text: '(UTC-03:00) Asuncion' },
    { Value: 'America/Belem', Text: '(UTC-03:00) Belem' },
    { Value: 'America/Argentina/Buenos_Aires', Text: '(UTC-03:00) Buenos Aires' },
    { Value: 'America/Campo_Grande', Text: '(UTC-03:00) Campo Grande' },
    { Value: 'America/Cayenne', Text: '(UTC-03:00) Cayenne' },
    { Value: 'America/Cuiaba', Text: '(UTC-03:00) Cuiaba' },
    { Value: 'America/Fortaleza', Text: '(UTC-03:00) Fortaleza' },
    { Value: 'America/Godthab', Text: '(UTC-03:00) Godthab' },
    { Value: 'America/Maceio', Text: '(UTC-03:00) Maceio' },
    { Value: 'America/Miquelon', Text: '(UTC-03:00) Miquelon' },
    { Value: 'America/Montevideo', Text: '(UTC-03:00) Montevideo' },
    { Value: 'Antarctica/Palmer', Text: '(UTC-03:00) Palmer' },
    { Value: 'America/Paramaribo', Text: '(UTC-03:00) Paramaribo' },
    { Value: 'America/Punta_Arenas', Text: '(UTC-03:00) Punta Arenas' },
    { Value: 'America/Recife', Text: '(UTC-03:00) Recife' },
    { Value: 'Antarctica/Rothera', Text: '(UTC-03:00) Rothera' },
    { Value: 'America/Bahia', Text: '(UTC-03:00) Salvador' },
    { Value: 'America/Santiago', Text: '(UTC-03:00) Santiago' },
    { Value: 'Atlantic/Stanley', Text: '(UTC-03:00) Stanley' },
    { Value: 'America/Noronha', Text: '(UTC-02:00) Noronha' },
    { Value: 'America/Sao_Paulo', Text: '(UTC-02:00) Sao Paulo' },
    { Value: 'Atlantic/South_Georgia', Text: '(UTC-02:00) South Georgia' },
    { Value: 'Atlantic/Azores', Text: '(UTC-01:00) Azores' },
    { Value: 'Atlantic/Cape_Verde', Text: '(UTC-01:00) Cape Verde' },
    { Value: 'America/Scoresbysund', Text: '(UTC-01:00) Scoresbysund' },
    { Value: 'Africa/Abidjan', Text: '(UTC+00:00) Abidjan' },
    { Value: 'Africa/Accra', Text: '(UTC+00:00) Accra' },
    { Value: 'Africa/Bissau', Text: '(UTC+00:00) Bissau' },
    { Value: 'Atlantic/Canary', Text: '(UTC+00:00) Canary Islands' },
    { Value: 'Africa/Casablanca', Text: '(UTC+00:00) Casablanca' },
    { Value: 'America/Danmarkshavn', Text: '(UTC+00:00) Danmarkshavn' },
    { Value: 'Europe/Dublin', Text: '(UTC+00:00) Dublin' },
    { Value: 'Africa/El_Aaiun', Text: '(UTC+00:00) El Aaiun' },
    { Value: 'Atlantic/Faroe', Text: '(UTC+00:00) Faeroe' },
    { Value: 'Etc/UTC', Text: '(UTC+00:00) UTC (no daylight saving)' },
    { Value: 'Europe/Lisbon', Text: '(UTC+00:00) Lisbon' },
    { Value: 'Europe/London', Text: '(UTC+00:00) London' },
    { Value: 'Africa/Monrovia', Text: '(UTC+00:00) Monrovia' },
    { Value: 'Atlantic/Reykjavik', Text: '(UTC+00:00) Reykjavik' },
    { Value: 'UTC', Text: 'UTC' },
    { Value: 'Africa/Algiers', Text: '(UTC+01:00) Algiers' },
    { Value: 'Europe/Amsterdam', Text: '(UTC+01:00) Amsterdam' },
    { Value: 'Europe/Andorra', Text: '(UTC+01:00) Andorra' },
    { Value: 'Europe/Berlin', Text: '(UTC+01:00) Berlin' },
    { Value: 'Europe/Brussels', Text: '(UTC+01:00) Brussels' },
    { Value: 'Europe/Budapest', Text: '(UTC+01:00) Budapest' },
    { Value: 'Europe/Belgrade', Text: '(UTC+01:00) Central European Time - Belgrade' },
    { Value: 'Europe/Prague', Text: '(UTC+01:00) Central European Time - Prague' },
    { Value: 'Africa/Ceuta', Text: '(UTC+01:00) Ceuta' },
    { Value: 'Europe/Copenhagen', Text: '(UTC+01:00) Copenhagen' },
    { Value: 'Europe/Gibraltar', Text: '(UTC+01:00) Gibraltar' },
    { Value: 'Africa/Lagos', Text: '(UTC+01:00) Lagos' },
    { Value: 'Europe/Luxembourg', Text: '(UTC+01:00) Luxembourg' },
    { Value: 'Europe/Madrid', Text: '(UTC+01:00) Madrid' },
    { Value: 'Europe/Malta', Text: '(UTC+01:00) Malta' },
    { Value: 'Europe/Monaco', Text: '(UTC+01:00) Monaco' },
    { Value: 'Africa/Ndjamena', Text: '(UTC+01:00) Ndjamena' },
    { Value: 'Europe/Oslo', Text: '(UTC+01:00) Oslo' },
    { Value: 'Europe/Paris', Text: '(UTC+01:00) Paris' },
    { Value: 'Europe/Rome', Text: '(UTC+01:00) Rome' },
    { Value: 'Europe/Stockholm', Text: '(UTC+01:00) Stockholm' },
    { Value: 'Europe/Tirane', Text: '(UTC+01:00) Tirane' },
    { Value: 'Africa/Tunis', Text: '(UTC+01:00) Tunis' },
    { Value: 'Europe/Vienna', Text: '(UTC+01:00) Vienna' },
    { Value: 'Europe/Warsaw', Text: '(UTC+01:00) Warsaw' },
    { Value: 'Europe/Zurich', Text: '(UTC+01:00) Zurich' },
    { Value: 'Asia/Amman', Text: '(UTC+02:00) Amman' },
    { Value: 'Europe/Athens', Text: '(UTC+02:00) Athens' },
    { Value: 'Asia/Beirut', Text: '(UTC+02:00) Beirut' },
    { Value: 'Europe/Bucharest', Text: '(UTC+02:00) Bucharest' },
    { Value: 'Africa/Cairo', Text: '(UTC+02:00) Cairo' },
    { Value: 'Europe/Chisinau', Text: '(UTC+02:00) Chisinau' },
    { Value: 'Asia/Damascus', Text: '(UTC+02:00) Damascus' },
    { Value: 'Asia/Gaza', Text: '(UTC+02:00) Gaza' },
    { Value: 'Europe/Helsinki', Text: '(UTC+02:00) Helsinki' },
    { Value: 'Asia/Jerusalem', Text: '(UTC+02:00) Jerusalem' },
    { Value: 'Africa/Johannesburg', Text: '(UTC+02:00) Johannesburg' },
    { Value: 'Africa/Khartoum', Text: '(UTC+02:00) Khartoum' },
    { Value: 'Europe/Kiev', Text: '(UTC+02:00) Kiev' },
    { Value: 'Africa/Maputo', Text: '(UTC+02:00) Maputo' },
    { Value: 'Europe/Kaliningrad', Text: '(UTC+02:00) Moscow-01 - Kaliningrad' },
    { Value: 'Asia/Nicosia', Text: '(UTC+02:00) Nicosia' },
    { Value: 'Europe/Riga', Text: '(UTC+02:00) Riga' },
    { Value: 'Europe/Sofia', Text: '(UTC+02:00) Sofia' },
    { Value: 'Europe/Tallinn', Text: '(UTC+02:00) Tallinn' },
    { Value: 'Africa/Tripoli', Text: '(UTC+02:00) Tripoli' },
    { Value: 'Europe/Vilnius', Text: '(UTC+02:00) Vilnius' },
    { Value: 'Africa/Windhoek', Text: '(UTC+02:00) Windhoek' },
    { Value: 'Asia/Baghdad', Text: '(UTC+03:00) Baghdad' },
    { Value: 'Europe/Istanbul', Text: '(UTC+03:00) Istanbul' },
    { Value: 'Europe/Minsk', Text: '(UTC+03:00) Minsk' },
    { Value: 'Europe/Moscow', Text: '(UTC+03:00) Moscow+00 - Moscow' },
    { Value: 'Africa/Nairobi', Text: '(UTC+03:00) Nairobi' },
    { Value: 'Asia/Qatar', Text: '(UTC+03:00) Qatar' },
    { Value: 'Asia/Riyadh', Text: '(UTC+03:00) Riyadh' },
    { Value: 'Antarctica/Syowa', Text: '(UTC+03:00) Syowa' },
    { Value: 'Asia/Tehran', Text: '(UTC+03:30) Tehran' },
    { Value: 'Asia/Baku', Text: '(UTC+04:00) Baku' },
    { Value: 'Asia/Dubai', Text: '(UTC+04:00) Dubai' },
    { Value: 'Indian/Mahe', Text: '(UTC+04:00) Mahe' },
    { Value: 'Indian/Mauritius', Text: '(UTC+04:00) Mauritius' },
    { Value: 'Europe/Samara', Text: '(UTC+04:00) Moscow+01 - Samara' },
    { Value: 'Indian/Reunion', Text: '(UTC+04:00) Reunion' },
    { Value: 'Asia/Tbilisi', Text: '(UTC+04:00) Tbilisi' },
    { Value: 'Asia/Yerevan', Text: '(UTC+04:00) Yerevan' },
    { Value: 'Asia/Kabul', Text: '(UTC+04:30) Kabul' },
    { Value: 'Asia/Aqtau', Text: '(UTC+05:00) Aqtau' },
    { Value: 'Asia/Aqtobe', Text: '(UTC+05:00) Aqtobe' },
    { Value: 'Asia/Ashgabat', Text: '(UTC+05:00) Ashgabat' },
    { Value: 'Asia/Dushanbe', Text: '(UTC+05:00) Dushanbe' },
    { Value: 'Asia/Karachi', Text: '(UTC+05:00) Karachi' },
    { Value: 'Indian/Kerguelen', Text: '(UTC+05:00) Kerguelen' },
    { Value: 'Indian/Maldives', Text: '(UTC+05:00) Maldives' },
    { Value: 'Antarctica/Mawson', Text: '(UTC+05:00) Mawson' },
    { Value: 'Asia/Yekaterinburg', Text: '(UTC+05:00) Moscow+02 - Yekaterinburg' },
    { Value: 'Asia/Tashkent', Text: '(UTC+05:00) Tashkent' },
    { Value: 'Asia/Colombo', Text: '(UTC+05:30) Colombo' },
    { Value: 'Asia/Kolkata', Text: '(UTC+05:30) India Standard Time' },
    { Value: 'Asia/Katmandu', Text: '(UTC+05:45) Katmandu' },
    { Value: 'Asia/Almaty', Text: '(UTC+06:00) Almaty' },
    { Value: 'Asia/Bishkek', Text: '(UTC+06:00) Bishkek' },
    { Value: 'Indian/Chagos', Text: '(UTC+06:00) Chagos' },
    { Value: 'Asia/Dhaka', Text: '(UTC+06:00) Dhaka' },
    { Value: 'Asia/Omsk', Text: '(UTC+06:00) Moscow+03 - Omsk' },
    { Value: 'Asia/Thimphu', Text: '(UTC+06:00) Thimphu' },
    { Value: 'Antarctica/Vostok', Text: '(UTC+06:00) Vostok' },
    { Value: 'Indian/Cocos', Text: '(UTC+06:30) Cocos' },
    { Value: 'Asia/Yangon', Text: '(UTC+06:30) Rangoon' },
    { Value: 'Asia/Bangkok', Text: '(UTC+07:00) Bangkok' },
    { Value: 'Indian/Christmas', Text: '(UTC+07:00) Christmas' },
    { Value: 'Antarctica/Davis', Text: '(UTC+07:00) Davis' },
    { Value: 'Asia/Saigon', Text: '(UTC+07:00) Hanoi' },
    { Value: 'Asia/Hovd', Text: '(UTC+07:00) Hovd' },
    { Value: 'Asia/Jakarta', Text: '(UTC+07:00) Jakarta' },
    { Value: 'Asia/Krasnoyarsk', Text: '(UTC+07:00) Moscow+04 - Krasnoyarsk' },
    { Value: 'Asia/Brunei', Text: '(UTC+08:00) Brunei' },
    { Value: 'Asia/Shanghai', Text: '(UTC+08:00) China Time - Beijing' },
    { Value: 'Asia/Choibalsan', Text: '(UTC+08:00) Choibalsan' },
    { Value: 'Asia/Hong_Kong', Text: '(UTC+08:00) Hong Kong' },
    { Value: 'Asia/Kuala_Lumpur', Text: '(UTC+08:00) Kuala Lumpur' },
    { Value: 'Asia/Macau', Text: '(UTC+08:00) Macau' },
    { Value: 'Asia/Makassar', Text: '(UTC+08:00) Makassar' },
    { Value: 'Asia/Manila', Text: '(UTC+08:00) Manila' },
    { Value: 'Asia/Irkutsk', Text: '(UTC+08:00) Moscow+05 - Irkutsk' },
    { Value: 'Asia/Singapore', Text: '(UTC+08:00) Singapore' },
    { Value: 'Asia/Taipei', Text: '(UTC+08:00) Taipei' },
    { Value: 'Asia/Ulaanbaatar', Text: '(UTC+08:00) Ulaanbaatar' },
    { Value: 'Australia/Perth', Text: '(UTC+08:00) Western Time - Perth' },
    { Value: 'Asia/Pyongyang', Text: '(UTC+08:30) Pyongyang' },
    { Value: 'Asia/Dili', Text: '(UTC+09:00) Dili' },
    { Value: 'Asia/Jayapura', Text: '(UTC+09:00) Jayapura' },
    { Value: 'Asia/Yakutsk', Text: '(UTC+09:00) Moscow+06 - Yakutsk' },
    { Value: 'Pacific/Palau', Text: '(UTC+09:00) Palau' },
    { Value: 'Asia/Seoul', Text: '(UTC+09:00) Seoul' },
    { Value: 'Asia/Tokyo', Text: '(UTC+09:00) Tokyo' },
    { Value: 'Australia/Darwin', Text: '(UTC+09:30) Central Time - Darwin' },
    { Value: 'Antarctica/DumontDUrville', Text: '(UTC+10:00) Dumont D"Urville' },
    { Value: 'Australia/Brisbane', Text: '(UTC+10:00) Eastern Time - Brisbane' },
    { Value: 'Pacific/Guam', Text: '(UTC+10:00) Guam' },
    { Value: 'Asia/Vladivostok', Text: '(UTC+10:00) Moscow+07 - Vladivostok' },
    { Value: 'Pacific/Port_Moresby', Text: '(UTC+10:00) Port Moresby' },
    { Value: 'Pacific / Chuuk', Text: '(UTC+10:00) Truk' },
    { Value: 'Australia/Adelaide', Text: '(UTC+10:30) Central Time - Adelaide' },
    { Value: 'Antarctica/Casey', Text: '(UTC+11:00) Casey' },
    { Value: 'Australia/Hobart', Text: '(UTC+11:00) Eastern Time - Hobart' },
    { Value: 'Australia/Sydney', Text: '(UTC+11:00) Eastern Time - Melbourne, Sydney' },
    { Value: 'Pacific/Efate', Text: '(UTC+11:00) Efate' },
    { Value: 'Pacific/Guadalcanal', Text: '(UTC+11:00) Guadalcanal' },
    { Value: 'Pacific/Kosrae', Text: '(UTC+11:00) Kosrae' },
    { Value: 'Asia/Magadan', Text: '(UTC+11:00) Moscow+08 - Magadan' },
    { Value: 'Pacific / Norfolk', Text: '(UTC+11:00) Norfolk' },
    { Value: 'Pacific/Noumea', Text: '(UTC+11:00) Noumea' },
    { Value: 'Pacific/Pohnpei', Text: '(UTC+11:00) Ponape' },
    { Value: 'Pacific/Funafuti', Text: '(UTC+12:00) Funafuti' },
    { Value: 'Pacific/Kwajalein', Text: '(UTC+12:00) Kwajalein' },
    { Value: 'Pacific/Majuro', Text: '(UTC+12:00) Majuro' },
    { Value: 'Asia/Kamchatka', Text: '(UTC+12:00) Moscow+09 - Petropavlovsk - Kamchatskiy' },
    { Value: 'Pacific / Nauru', Text: '(UTC+12:00) Nauru' },
    { Value: 'Pacific/Tarawa', Text: '(UTC+12:00) Tarawa' },
    { Value: 'Pacific/Wake', Text: '(UTC+12:00) Wake' },
    { Value: 'Pacific/Wallis', Text: '(UTC+12:00) Wallis' },
    { Value: 'Pacific/Auckland', Text: '(UTC+13:00) Auckland' },
    { Value: 'Pacific/Enderbury', Text: '(UTC+13:00) Enderbury' },
    { Value: 'Pacific/Fakaofo', Text: '(UTC+13:00) Fakaofo' },
    { Value: 'Pacific/Fiji', Text: '(UTC+13:00) Fiji' },
    { Value: 'Pacific/Tongatapu', Text: '(UTC+13:00) Tongatapu' },
    { Value: 'Pacific/Apia', Text: '(UTC+14:00) Apia' },
    { Value: 'Pacific/Kiritimati', Text: '(UTC+14:00) Kiritimati' }
];

/* eslint-disable max-len */
/**
 * Date Generator from Recurrence Rule
 */
/**
 * Generate Summary from Recurrence Rule
 *
 * @param {string} rule Accepts the Recurrence rule
 * @param {L10n} localeObject Accepts the locale object
 * @param {string} locale Accepts the locale name
 * @param {CalendarType} calendarType Accepts the calendar type
 * @returns {string} Returns the summary string from given recurrence rule
 */
function generateSummary(rule, localeObject, locale, calendarType) {
    if (calendarType === void 0) { calendarType = 'Gregorian'; }
    var ruleObject = extractObjectFromRule(rule);
    var summary = localeObject.getConstant(EVERY) + ' ';
    var cldrObj;
    var cldrObj1;
    var calendarMode = calendarType.toLowerCase();
    if (locale === 'en' || locale === 'en-US') {
        var nameSpace1 = 'months.stand-alone.abbreviated';
        var nameSpace = 'days.stand-alone.abbreviated';
        cldrObj1 = (getValue(nameSpace1, getDefaultDateObject(calendarMode)));
        cldrObj = (getValue(nameSpace, getDefaultDateObject(calendarMode)));
    }
    else {
        var nameSpace1 = 'main.' + locale + '.dates.calendars.' + calendarMode + '.months.stand-alone.abbreviated';
        var nameSpace = 'main.' + locale + '.dates.calendars.' + calendarMode + '.days.stand-alone.abbreviated';
        cldrObj1 =
            (getValue(nameSpace1, cldrData));
        cldrObj =
            (getValue(nameSpace, cldrData));
    }
    if (ruleObject.interval > 1) {
        summary += ruleObject.interval + ' ';
    }
    switch (ruleObject.freq) {
        case 'DAILY':
            summary += localeObject.getConstant(DAYS);
            break;
        case 'WEEKLY':
            summary += localeObject.getConstant(WEEKS) + ' ' + localeObject.getConstant(ON) + ' ';
            ruleObject.day.forEach(function (day, index) {
                summary += capitalizeFirstWord(getValue(DAYINDEXOBJECT["" + day], cldrObj), 'single');
                summary += (((ruleObject.day.length - 1) === index) ? '' : ', ');
            });
            break;
        case 'MONTHLY':
            summary += localeObject.getConstant(MONTHS) + ' ' + localeObject.getConstant(ON) + ' ';
            summary += getMonthSummary(ruleObject, cldrObj, localeObject);
            break;
        case 'YEARLY':
            summary += localeObject.getConstant(YEARS) + ' ' + localeObject.getConstant(ON) + ' ';
            summary += capitalizeFirstWord(getValue((ruleObject.month[0]).toString(), cldrObj1), 'single') + ' ';
            summary += getMonthSummary(ruleObject, cldrObj, localeObject);
            break;
    }
    if (ruleObject.count) {
        summary += ', ' + (ruleObject.count) + ' ' + localeObject.getConstant(TIMES);
    }
    else if (ruleObject.until) {
        var tempDate = ruleObject.until;
        summary += ', ' + localeObject.getConstant(UNTIL)
            + ' ' + tempDate.getDate()
            + ' ' + capitalizeFirstWord(getValue((tempDate.getMonth() + 1).toString(), cldrObj1), 'single')
            + ' ' + tempDate.getFullYear();
    }
    return summary;
}
/**
 * Generates Month summary
 *
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @param {string[]} cldrObj Accepts the collections of month name from calendar
 * @param {L10n} localeObj Accepts the locale object
 * @returns {string} Returns the month summary string from given recurrence rule object
 * @private
 */
function getMonthSummary(ruleObject, cldrObj, localeObj) {
    var summary = '';
    if (ruleObject.monthDay.length) {
        summary += ruleObject.monthDay[0];
    }
    else if (ruleObject.day) {
        var pos = ruleObject.setPosition - 1;
        summary += localeObj.getConstant(WEEKPOS[pos > -1 ? pos : (WEEKPOS.length - 1)])
            + ' ' + capitalizeFirstWord(getValue(DAYINDEXOBJECT[ruleObject.day[0]], cldrObj), 'single');
    }
    return summary;
}
/**
 * Generates the date collections from the given recurrence rule
 *
 * @param {Date} startDate Accepts the rule start date
 * @param {string} rule Accepts the recurrence rule
 * @param {string} excludeDate Accepts the exception dates in string format
 * @param {number} startDayOfWeek Accepts the start day index of week
 * @param {number} maximumCount Accepts the maximum number count to generate date collections
 * @param {Date} viewDate Accepts the current date instead of start date
 * @param {CalendarType} calendarMode Accepts the calendar type
 * @param {string} newTimezone Accepts the timezone name
 * @returns {number[]} Returns the collection of dates
 */
function generate(startDate, rule, excludeDate, startDayOfWeek, maximumCount, viewDate, calendarMode, newTimezone) {
    if (maximumCount === void 0) { maximumCount = MAXOCCURRENCE; }
    if (viewDate === void 0) { viewDate = null; }
    if (calendarMode === void 0) { calendarMode = 'Gregorian'; }
    if (newTimezone === void 0) { newTimezone = null; }
    var ruleObject = extractObjectFromRule(rule);
    var cacheDate;
    calendarUtil = getCalendarUtil(calendarMode);
    var data = [];
    var modifiedDate = new Date(startDate.getTime());
    tempExcludeDate = [];
    var tempDate = isNullOrUndefined(excludeDate) ? [] : excludeDate.split(',');
    var tz = new Timezone();
    tempDate.forEach(function (content) {
        var parsedDate = getDateFromRecurrenceDateString(content);
        if (newTimezone) {
            parsedDate = tz.add(new Date(parsedDate.getTime()), newTimezone);
        }
        tempExcludeDate.push(new Date(parsedDate.getTime()).setHours(0, 0, 0, 0));
    });
    ruleObject.recExceptionCount = !isNullOrUndefined(ruleObject.count) ? tempExcludeDate.length : 0;
    if (viewDate && viewDate > startDate && !ruleObject.count) {
        tempViewDate = new Date(new Date(viewDate.getTime()).setHours(0, 0, 0));
    }
    else {
        tempViewDate = null;
    }
    if (!ruleObject.until && tempViewDate) {
        cacheDate = new Date(tempViewDate.getTime());
        cacheDate.setDate(tempViewDate.getDate() + maximumCount * (ruleObject.interval));
        ruleObject.until = cacheDate;
    }
    if (ruleObject.until && startDate > ruleObject.until) {
        return data;
    }
    maxOccurrence = maximumCount;
    setFirstDayOfWeek(DAYINDEX[parseInt(startDayOfWeek.toString(), 10)]);
    if (ruleObject.until) {
        var end = resetTime(ruleObject.until);
        ruleObject.until = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
    }
    switch (ruleObject.freq) {
        case 'DAILY':
            dailyType(modifiedDate, ruleObject.until, data, ruleObject);
            break;
        case 'WEEKLY':
            weeklyType(modifiedDate, ruleObject.until, data, ruleObject);
            break;
        case 'MONTHLY':
            monthlyType(modifiedDate, ruleObject.until, data, ruleObject);
            break;
        case 'YEARLY':
            yearlyType(modifiedDate, ruleObject.until, data, ruleObject);
    }
    return data;
}
/**
 * Generate date object from given date string
 *
 * @param {string} recDateString Accepts the exception date as string
 * @returns {Date} Returns the date from exception date string
 */
function getDateFromRecurrenceDateString(recDateString) {
    return new Date(recDateString.substr(0, 4) +
        '-' + recDateString.substr(4, 2) +
        '-' + recDateString.substr(6, 5) +
        ':' + recDateString.substr(11, 2) +
        ':' + recDateString.substr(13));
}
/**
 * Internal method to handle exclude date
 *
 * @param {number[]} data Accepts the exception date collections
 * @param {number} date Accepts the new exclude date
 * @returns {void}
 * @private
 */
function excludeDateHandler(data, date) {
    var zeroIndex = new Date(date).setHours(0, 0, 0, 0);
    if (tempExcludeDate.indexOf(zeroIndex) === -1 && (!tempViewDate || zeroIndex >= tempViewDate.getTime())) {
        data.push(date);
    }
}
/**
 * Internal method for get date count
 *
 * @param {Date} startDate Accepts the date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {number} Returns the number of date count
 * @private
 */
function getDateCount$1(startDate, ruleObject) {
    var count = maxOccurrence;
    if (ruleObject.count) {
        count = ruleObject.count;
    }
    else if (ruleObject.until) {
        if (ruleObject.freq === 'DAILY' || ruleObject.freq === 'WEEKLY') {
            count = Math.floor((ruleObject.until.getTime() - startDate.getTime()) / MS_PER_DAY) + 1;
        }
        else if (ruleObject.freq === 'MONTHLY' || ruleObject.freq === 'YEARLY') {
            count = Math.floor(((ruleObject.until.getMonth() + 12 * ruleObject.until.getFullYear()) -
                (startDate.getMonth() + 12 * startDate.getFullYear())) / ruleObject.interval) +
                (ruleObject.day.length > 1 ? (Math.floor((ruleObject.until.getTime() - startDate.getTime()) / MS_PER_DAY) + 1) : 1);
            if (ruleObject.freq === 'YEARLY') {
                count = ruleObject.month.length > 1 ? (count * ruleObject.month.length) : count;
            }
        }
    }
    return count;
}
/**
 *  Internal method for daily type recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function dailyType(startDate, endDate, data, ruleObject) {
    var tempDate = new Date(startDate.getTime());
    var interval = ruleObject.interval;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var state;
    var expectedDays = ruleObject.day;
    while (compareDates(tempDate, endDate)) {
        state = true;
        state = validateRules(tempDate, ruleObject);
        if (state && (expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1 || expectedDays.length === 0)) {
            excludeDateHandler(data, tempDate.getTime());
            if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                break;
            }
        }
        tempDate.setDate(tempDate.getDate() + interval);
        if (tempDate.getHours() !== startDate.getHours()) {
            tempDate.setHours(startDate.getHours());
        }
    }
}
/**
 * Internal method for weekly type recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function weeklyType(startDate, endDate, data, ruleObject) {
    var tempDate = new Date(startDate.getTime());
    if (!ruleObject.day.length) {
        ruleObject.day.push(DAYINDEX[startDate.getDay()]);
    }
    var interval = ruleObject.interval;
    var expectedDays = ruleObject.day;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var weekState = true;
    var wkstIndex;
    var weekCollection = [];
    if (expectedDays.length > 1) {
        if (isNullOrUndefined(ruleObject.wkst) || ruleObject.wkst === '') {
            ruleObject.wkst = dayIndex[0];
        }
        wkstIndex = DAYINDEX.indexOf(ruleObject.wkst);
        while (compareDates(tempDate, endDate)) {
            var startDateDiff = DAYINDEX.indexOf(DAYINDEX[tempDate.getDay()]) - wkstIndex;
            startDateDiff = startDateDiff === -1 ? 6 : startDateDiff;
            var weekstartDate = addDays(tempDate, -startDateDiff);
            var weekendDate = addDays(weekstartDate, 6);
            var compareTempDate = new Date(tempDate.getTime());
            weekendDate = resetTime(weekendDate);
            compareTempDate = resetTime(compareTempDate);
            while (weekendDate >= compareTempDate) {
                if (expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1) {
                    weekCollection.push([tempDate.getTime()]);
                }
                if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                    break;
                }
                tempDate.setDate(tempDate.getDate() + 1);
                if (tempDate.getHours() !== startDate.getHours()) {
                    tempDate.setHours(startDate.getHours());
                }
                compareTempDate = new Date(tempDate.getTime());
                compareTempDate = resetTime(compareTempDate);
            }
            tempDate.setDate(tempDate.getDate() - 1);
            if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                break;
            }
            tempDate.setDate((tempDate.getDate()) + 1 + ((interval - 1) * 7));
            insertDataCollection(weekCollection, weekState, startDate, endDate, data, ruleObject);
            weekCollection = [];
        }
    }
    else {
        tempDate = getStartDateForWeek(startDate, ruleObject.day);
        while (compareDates(tempDate, endDate)) {
            weekState = validateRules(tempDate, ruleObject);
            if (weekState && (expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1)) {
                excludeDateHandler(data, tempDate.getTime());
            }
            if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                break;
            }
            tempDate.setDate(tempDate.getDate() + (interval * 7));
        }
        insertDataCollection(weekCollection, weekState, startDate, endDate, data, ruleObject);
        weekCollection = [];
    }
}
/**
 *  Internal method for monthly type recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function monthlyType(startDate, endDate, data, ruleObject) {
    // Set monthday value if BYDAY, BYMONTH and Month day property is not set based on start date
    if (!ruleObject.month.length && !ruleObject.day.length && !ruleObject.monthDay.length) {
        ruleObject.monthDay.push(startDate.getDate());
        if (ruleObject.freq === 'YEARLY') {
            ruleObject.month.push(startDate.getMonth() + 1);
        }
    }
    else if (ruleObject.month.length > 0 && !ruleObject.day.length && !ruleObject.monthDay.length) {
        ruleObject.monthDay.push(startDate.getDate());
    }
    var ruleType = validateMonthlyRuleType(ruleObject);
    switch (ruleType) {
        case 'day':
            switch (ruleObject.freq) {
                case 'MONTHLY':
                    monthlyDayTypeProcessforMonthFreq(startDate, endDate, data, ruleObject);
                    break;
                case 'YEARLY':
                    monthlyDayTypeProcess(startDate, endDate, data, ruleObject);
                    break;
            }
            break;
        case 'both':
        case 'date':
            switch (ruleObject.freq) {
                case 'MONTHLY':
                    monthlyDateTypeProcessforMonthFreq(startDate, endDate, data, ruleObject);
                    break;
                case 'YEARLY':
                    monthlyDateTypeProcess(startDate, endDate, data, ruleObject);
                    break;
            }
            break;
    }
}
/**
 * Internal method for yearly type recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function yearlyType(startDate, endDate, data, ruleObject) {
    var typeValue = checkYearlyType(ruleObject);
    switch (typeValue) {
        case 'MONTH':
            monthlyType(startDate, endDate, data, ruleObject);
            break;
        case 'WEEKNO':
            processWeekNo(startDate, endDate, data, ruleObject);
            break;
        case 'YEARDAY':
            processYearDay(startDate, endDate, data, ruleObject);
            break;
    }
}
/**
 * Internal method for process week no
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function processWeekNo(startDate, endDate, data, ruleObject) {
    var stDate = calendarUtil.getYearLastDate(startDate, 0);
    var tempDate;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var state;
    var startDay;
    var firstWeekSpan;
    var weekNos = ruleObject.weekNo;
    var weekNo;
    var maxDate;
    var minDate;
    var weekCollection = [];
    var expectedDays = ruleObject.day;
    while (compareDates(stDate, endDate)) {
        startDay = dayIndex.indexOf(DAYINDEX[stDate.getDay()]);
        firstWeekSpan = (6 - startDay) + 1;
        for (var index = 0; index < weekNos.length; index++) {
            weekNo = weekNos[parseInt(index.toString(), 10)];
            weekNo = (weekNo > 0) ? weekNo : 53 + weekNo + 1;
            maxDate = (weekNo === 1) ? firstWeekSpan : firstWeekSpan + ((weekNo - 1) * 7);
            minDate = (weekNo === 1) ? firstWeekSpan - 7 : firstWeekSpan + ((weekNo - 2) * 7);
            while (minDate < maxDate) {
                tempDate = new Date(stDate.getTime() + (MS_PER_DAY * minDate));
                if (expectedDays.length === 0 || expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1) {
                    if (isNullOrUndefined(ruleObject.setPosition)) {
                        insertDateCollection(state, startDate, endDate, data, ruleObject, tempDate.getTime());
                    }
                    else {
                        weekCollection.push([tempDate.getTime()]);
                    }
                }
                minDate++;
            }
        }
        if (!isNullOrUndefined(ruleObject.setPosition)) {
            insertDatasIntoExistingCollection(weekCollection, state, startDate, endDate, data, ruleObject);
        }
        if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
            return;
        }
        stDate = calendarUtil.getYearLastDate(tempDate, ruleObject.interval);
        weekCollection = [];
    }
}
/**
 * Internal method for process year day
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function processYearDay(startDate, endDate, data, ruleObject) {
    var stDate = calendarUtil.getYearLastDate(startDate, 0);
    var tempDate;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var state;
    var dateCollection = [];
    var date;
    var expectedDays = ruleObject.day;
    while (compareDates(stDate, endDate)) {
        for (var index = 0; index < ruleObject.yearDay.length; index++) {
            date = ruleObject.yearDay[parseInt(index.toString(), 10)];
            tempDate = new Date(stDate.getTime());
            if ((date === calendarUtil.getLeapYearDaysCount() || date === -calendarUtil.getLeapYearDaysCount()) &&
                (!calendarUtil.isLeapYear(calendarUtil.getFullYear(tempDate), 1))) {
                tempDate.setDate(tempDate.getDate() + 1);
                continue;
            }
            tempDate.setDate(tempDate.getDate() + ((date < 0) ?
                calendarUtil.getYearDaysCount(tempDate, 1) + 1 + date : date));
            if (expectedDays.length === 0 || expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1) {
                if (ruleObject.setPosition == null) {
                    insertDateCollection(state, startDate, endDate, data, ruleObject, tempDate.getTime());
                }
                else {
                    dateCollection.push([tempDate.getTime()]);
                }
            }
        }
        if (!isNullOrUndefined(ruleObject.setPosition)) {
            insertDatasIntoExistingCollection(dateCollection, state, startDate, endDate, data, ruleObject);
        }
        if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
            return;
        }
        stDate = calendarUtil.getYearLastDate(tempDate, ruleObject.interval);
        dateCollection = [];
    }
}
/**
 * Internal method to check yearly type
 *
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {YearRuleType} Returns the Yearly rule type object
 * @private
 */
function checkYearlyType(ruleObject) {
    if (ruleObject.yearDay.length) {
        return 'YEARDAY';
    }
    else if (ruleObject.weekNo.length) {
        return 'WEEKNO';
    }
    return 'MONTH';
}
/**
 * Internal method to initialize recurrence rule variables
 *
 * @param {Date} startDate Accepts the start date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {RuleData} Return the rule data object
 * @private
 */
function initializeRecRuleVariables(startDate, ruleObject) {
    var ruleData = {
        monthCollection: [],
        index: 0,
        tempDate: new Date(startDate.getTime()),
        mainDate: new Date(startDate.getTime()),
        expectedCount: getDateCount$1(startDate, ruleObject),
        monthInit: 0,
        dateCollection: []
    };
    if (ruleObject.month.length) {
        calendarUtil.setMonth(ruleData.tempDate, ruleObject.month[0], ruleData.tempDate.getDate());
    }
    return ruleData;
}
/**
 * Internal method for process monthly date type recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function monthlyDateTypeProcess(startDate, endDate, data, ruleObject) {
    if (ruleObject.month.length) {
        monthlyDateTypeProcessforMonthFreq(startDate, endDate, data, ruleObject);
        return;
    }
    var ruleData = initializeRecRuleVariables(startDate, ruleObject);
    var currentMonthDate;
    ruleData.tempDate = ruleData.mainDate = calendarUtil.getMonthStartDate(ruleData.tempDate);
    while (compareDates(ruleData.tempDate, endDate)) {
        currentMonthDate = new Date(ruleData.tempDate.getTime());
        while (calendarUtil.isSameYear(currentMonthDate, ruleData.tempDate) &&
            (ruleData.expectedCount && (data.length + ruleObject.recExceptionCount) <= ruleData.expectedCount)) {
            if (ruleObject.month.length === 0 || (ruleObject.month.length > 0
                && !calendarUtil.checkMonth(ruleData.tempDate, ruleObject.month))) {
                processDateCollectionForByMonthDay(ruleObject, ruleData, endDate, false);
                ruleData.beginDate = new Date(ruleData.tempDate.getTime());
                ruleData.monthInit = setNextValidDate(ruleData.tempDate, ruleObject, ruleData.monthInit, ruleData.beginDate);
            }
            else {
                calendarUtil.setValidDate(ruleData.tempDate, 1, 1);
                ruleData.tempDate = getStartDateForWeek(ruleData.tempDate, ruleObject.day);
                break;
            }
        }
        ruleData.tempDate.setFullYear(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), currentMonthDate.getDate());
        insertDataCollection(ruleData.dateCollection, ruleData.state, startDate, endDate, data, ruleObject);
        if (calendarUtil.isLastMonth(ruleData.tempDate)) {
            calendarUtil.setValidDate(ruleData.tempDate, 1, 1);
            ruleData.tempDate = getStartDateForWeek(ruleData.tempDate, ruleObject.day);
        }
        if (ruleData.expectedCount && (data.length + ruleObject.recExceptionCount) >= ruleData.expectedCount) {
            return;
        }
        ruleData.tempDate.setFullYear(ruleData.tempDate.getFullYear() + ruleObject.interval - 1);
        ruleData.tempDate = getStartDateForWeek(ruleData.tempDate, ruleObject.day);
        ruleData.monthInit = setNextValidDate(ruleData.tempDate, ruleObject, ruleData.monthInit, ruleData.beginDate);
        ruleData.dateCollection = [];
    }
}
/**
 * Internal method for process monthly date type with month frequency from recurrence rule
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function monthlyDateTypeProcessforMonthFreq(startDate, endDate, data, ruleObject) {
    var ruleData = initializeRecRuleVariables(startDate, ruleObject);
    ruleData.tempDate = ruleData.mainDate = calendarUtil.getMonthStartDate(ruleData.tempDate);
    if (((ruleObject.freq === 'MONTHLY' && ruleObject.interval === 12) || (ruleObject.freq === 'YEARLY')) &&
        calendarUtil.getMonthDaysCount(startDate) < ruleObject.monthDay[0]) {
        return;
    }
    while (compareDates(ruleData.tempDate, endDate)) {
        ruleData.beginDate = new Date(ruleData.tempDate.getTime());
        processDateCollectionForByMonthDay(ruleObject, ruleData, endDate, true, startDate, data);
        if (!isNullOrUndefined(ruleObject.setPosition)) {
            insertDatasIntoExistingCollection(ruleData.dateCollection, ruleData.state, startDate, endDate, data, ruleObject);
        }
        if (ruleData.expectedCount && (data.length + ruleObject.recExceptionCount) >= ruleData.expectedCount) {
            return;
        }
        ruleData.monthInit = setNextValidDate(ruleData.tempDate, ruleObject, ruleData.monthInit, ruleData.beginDate);
        ruleData.dateCollection = [];
    }
}
/**
 * To process date collection for Monthly & Yearly based on BYMONTH Day property
 *
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @param {RuleData} recRuleVariables Accepts the rule data
 * @param {Date} endDate Accepts the end date
 * @param {boolean} isByMonth Accepts the boolean to validate either month or not
 * @param {Date} startDate Accepts the start date
 * @param {number[]} data Accepts the collection of dates
 * @returns {void}
 * @private
 */
function processDateCollectionForByMonthDay(ruleObject, recRuleVariables, endDate, isByMonth, startDate, data) {
    for (var index = 0; index < ruleObject.monthDay.length; index++) {
        recRuleVariables.date = ruleObject.monthDay[parseInt(index.toString(), 10)];
        recRuleVariables.tempDate = calendarUtil.getMonthStartDate(recRuleVariables.tempDate);
        var maxDate = calendarUtil.getMonthDaysCount(recRuleVariables.tempDate);
        recRuleVariables.date = recRuleVariables.date > 0 ? recRuleVariables.date : (maxDate + recRuleVariables.date + 1);
        if (validateProperDate(recRuleVariables.tempDate, recRuleVariables.date, recRuleVariables.mainDate)
            && (recRuleVariables.date > 0)) {
            calendarUtil.setDate(recRuleVariables.tempDate, recRuleVariables.date);
            if (endDate && recRuleVariables.tempDate > endDate) {
                return;
            }
            if (ruleObject.day.length === 0 || ruleObject.day.indexOf(DAYINDEX[recRuleVariables.tempDate.getDay()]) > -1) {
                if (isByMonth && isNullOrUndefined(ruleObject.setPosition) && (recRuleVariables.expectedCount
                    && (data.length + ruleObject.recExceptionCount) < recRuleVariables.expectedCount)) {
                    insertDateCollection(recRuleVariables.state, startDate, endDate, data, ruleObject, recRuleVariables.tempDate.getTime());
                }
                else {
                    recRuleVariables.dateCollection.push([recRuleVariables.tempDate.getTime()]);
                }
            }
        }
    }
}
/**
 * Internal method to set next valid date
 *
 * @param {Date} tempDate Accepts the date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @param {number} monthInit Accepts the initial month
 * @param {Date} beginDate Accepts the initial date
 * @param {number} interval Accepts the interval duration
 * @returns {number} Returnx the next valid date
 * @private
 */
function setNextValidDate(tempDate, ruleObject, monthInit, beginDate, interval) {
    if (beginDate === void 0) { beginDate = null; }
    var monthData = beginDate ? beginDate.getMonth() : 0;
    var startDate = calendarUtil.getMonthStartDate(tempDate);
    interval = isNullOrUndefined(interval) ? ruleObject.interval : interval;
    tempDate.setFullYear(startDate.getFullYear());
    tempDate.setMonth(startDate.getMonth());
    tempDate.setDate(startDate.getDate());
    if (ruleObject.month.length) {
        monthInit++;
        monthInit = monthInit % ruleObject.month.length;
        calendarUtil.setMonth(tempDate, ruleObject.month[parseInt(monthInit.toString(), 10)], 1);
        if (monthInit === 0) {
            calendarUtil.addYears(tempDate, interval, ruleObject.month[0]);
        }
    }
    else {
        if (beginDate && (beginDate.getFullYear() < tempDate.getFullYear())) {
            monthData = tempDate.getMonth() - 1;
        }
        calendarUtil.setValidDate(tempDate, interval, 1, monthData, beginDate);
    }
    return monthInit;
}
/**
 * To get month collection when BYDAY property having more than one value in list.
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function getMonthCollection(startDate, endDate, data, ruleObject) {
    var expectedDays = ruleObject.day;
    var tempDate = new Date(startDate.getTime());
    tempDate = calendarUtil.getMonthStartDate(tempDate);
    var monthCollection = [];
    var dateCollection = [];
    var dates = [];
    var index;
    var state;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var monthInit = 0;
    var beginDate;
    if (ruleObject.month.length) {
        calendarUtil.setMonth(tempDate, ruleObject.month[0], 1);
    }
    tempDate = getStartDateForWeek(tempDate, ruleObject.day);
    while (compareDates(tempDate, endDate)
        && (expectedCount && (data.length + ruleObject.recExceptionCount) < expectedCount)) {
        var currentMonthDate = new Date(tempDate.getTime());
        var isHavingNumber = expectedDays.map(function (item) { return HASNUMBER.test(item); });
        if (isHavingNumber.indexOf(true) > -1) {
            for (var j = 0; j <= expectedDays.length - 1; j++) {
                var expectedDaysArray = expectedDays[parseInt(j.toString(), 10)].match(SPLITNUMBERANDSTRING);
                var position = parseInt(expectedDaysArray[0], 10);
                tempDate = new Date(tempDate.getTime());
                tempDate = calendarUtil.getMonthStartDate(tempDate);
                tempDate = getStartDateForWeek(tempDate, expectedDays);
                currentMonthDate.setFullYear(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
                while (calendarUtil.isSameYear(currentMonthDate, tempDate) && calendarUtil.isSameMonth(currentMonthDate, tempDate)) {
                    if (expectedDaysArray[expectedDaysArray.length - 1] === DAYINDEX[currentMonthDate.getDay()]) {
                        monthCollection.push([currentMonthDate.getTime()]);
                    }
                    currentMonthDate.setDate(currentMonthDate.getDate() + (1));
                }
                currentMonthDate.setDate(currentMonthDate.getDate() - (1));
                if (expectedDaysArray[0].indexOf('-') > -1) {
                    index = monthCollection.length - (-1 * position);
                }
                else {
                    index = position - 1;
                }
                index = isNaN(index) ? 0 : index;
                if (monthCollection.length > 0) {
                    if (isNullOrUndefined(ruleObject.setPosition)) {
                        insertDatasIntoExistingCollection(monthCollection, state, startDate, endDate, data, ruleObject, index);
                    }
                    else {
                        dateCollection = [(filterDateCollectionByIndex(monthCollection, index, dates))];
                    }
                }
                if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                    return;
                }
                monthCollection = [];
            }
            if (!isNullOrUndefined(ruleObject.setPosition)) {
                insertDateCollectionBasedonBySetPos(dateCollection, state, startDate, endDate, data, ruleObject);
                dates = [];
            }
            monthInit = setNextValidDate(tempDate, ruleObject, monthInit, beginDate);
            tempDate = getStartDateForWeek(tempDate, ruleObject.day);
            monthCollection = [];
        }
        else {
            var weekCollection = [];
            var dayCycleData = processWeekDays(expectedDays);
            currentMonthDate.setFullYear(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
            var initialDate = new Date(tempDate.getTime());
            beginDate = new Date(tempDate.getTime());
            while (calendarUtil.isSameMonth(initialDate, tempDate)) {
                weekCollection.push(tempDate.getTime());
                if (expectedDays.indexOf(DAYINDEX[tempDate.getDay()]) > -1) {
                    monthCollection.push(weekCollection);
                    weekCollection = [];
                }
                tempDate.setDate(tempDate.getDate()
                    + dayCycleData[DAYINDEX[tempDate.getDay()]]);
            }
            index = ((ruleObject.setPosition < 1) ? (monthCollection.length + ruleObject.setPosition) : ruleObject.setPosition - 1);
            if (isNullOrUndefined(ruleObject.setPosition)) {
                index = 0;
                var datas = [];
                for (var week = 0; week < monthCollection.length; week++) {
                    for (var row = 0; row < monthCollection[parseInt(week.toString(), 10)].length; row++) {
                        datas.push(monthCollection[parseInt(week.toString(), 10)][parseInt(row.toString(), 10)]);
                    }
                }
                monthCollection = [datas];
            }
            if (monthCollection.length > 0) {
                insertDatasIntoExistingCollection(monthCollection, state, startDate, endDate, data, ruleObject, index);
            }
            if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                return;
            }
            monthInit = setNextValidDate(tempDate, ruleObject, monthInit, beginDate);
            tempDate = getStartDateForWeek(tempDate, ruleObject.day);
            monthCollection = [];
        }
    }
}
/**
 * To process monday day type for FREQ=MONTHLY
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function monthlyDayTypeProcessforMonthFreq(startDate, endDate, data, ruleObject) {
    var expectedDays = ruleObject.day;
    // When BYDAY property having more than 1 value.
    if (expectedDays.length > 1) {
        getMonthCollection(startDate, endDate, data, ruleObject);
        return;
    }
    var tempDate = new Date(startDate.getTime());
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var monthCollection = [];
    var beginDate;
    var monthInit = 0;
    tempDate = calendarUtil.getMonthStartDate(tempDate);
    if (ruleObject.month.length) {
        calendarUtil.setMonth(tempDate, ruleObject.month[0], 1);
    }
    tempDate = getStartDateForWeek(tempDate, ruleObject.day);
    while (compareDates(tempDate, endDate) && (expectedCount && (data.length + ruleObject.recExceptionCount) < expectedCount)) {
        beginDate = new Date(tempDate.getTime());
        var currentMonthDate = new Date(tempDate.getTime());
        while (calendarUtil.isSameMonth(tempDate, currentMonthDate)) {
            monthCollection.push([currentMonthDate.getTime()]);
            currentMonthDate.setDate(currentMonthDate.getDate() + (7));
        }
        // To filter date collection based on BYDAY Index, then BYSETPOS and to insert datas into existing collection
        insertDateCollectionBasedonIndex(monthCollection, startDate, endDate, data, ruleObject);
        monthInit = setNextValidDate(tempDate, ruleObject, monthInit, beginDate);
        tempDate = getStartDateForWeek(tempDate, ruleObject.day);
        monthCollection = [];
    }
}
/**
 * To process monday day type for FREQ=YEARLY
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function monthlyDayTypeProcess(startDate, endDate, data, ruleObject) {
    var expectedDays = ruleObject.day;
    var isHavingNumber = expectedDays.map(function (item) { return HASNUMBER.test(item); });
    // If BYDAY property having more than 1 value in list
    if (expectedDays.length > 1 && isHavingNumber.indexOf(true) > -1) {
        processDateCollectionforByDayWithInteger(startDate, endDate, data, ruleObject);
        return;
    }
    else if (ruleObject.month.length && expectedDays.length === 1 && isHavingNumber.indexOf(true) > -1) {
        monthlyDayTypeProcessforMonthFreq(startDate, endDate, data, ruleObject);
        return;
    }
    var tempDate = new Date(startDate.getTime());
    var currentMonthDate;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var interval = ruleObject.interval;
    var monthCollection = [];
    if (ruleObject.month.length) {
        calendarUtil.setMonth(tempDate, ruleObject.month[0], tempDate.getDate());
    }
    // Set the date as start date of the yeear if yearly freq having ByDay property alone
    if (isNullOrUndefined(ruleObject.setPosition) && ruleObject.month.length === 0 && ruleObject.weekNo.length === 0) {
        tempDate.setFullYear(startDate.getFullYear(), 0, 1);
    }
    tempDate = calendarUtil.getMonthStartDate(tempDate);
    tempDate = getStartDateForWeek(tempDate, ruleObject.day);
    while (compareDates(tempDate, endDate)) {
        currentMonthDate = new Date(tempDate.getTime());
        while (calendarUtil.isSameYear(currentMonthDate, tempDate) &&
            (expectedCount && (data.length + ruleObject.recExceptionCount) <= expectedCount)) {
            currentMonthDate = new Date(tempDate.getTime());
            while (calendarUtil.isSameYear(currentMonthDate, tempDate)) {
                if (ruleObject.month.length === 0 || (ruleObject.month.length > 0
                    && !calendarUtil.checkMonth(tempDate, ruleObject.month))) {
                    if (expectedDays.length > 1) {
                        if (calendarUtil.compareMonth(currentMonthDate, tempDate)) {
                            calendarUtil.setValidDate(tempDate, 1, 1);
                            tempDate = getStartDateForWeek(tempDate, ruleObject.day);
                            break;
                        }
                        if (expectedDays.indexOf(DAYINDEX[currentMonthDate.getDay()]) > -1) {
                            monthCollection.push([currentMonthDate.getTime()]);
                        }
                        currentMonthDate.setDate(currentMonthDate.getDate() + (1));
                    }
                    else {
                        // If BYDAY property having 1 value in list
                        if (currentMonthDate.getFullYear() > tempDate.getFullYear()) {
                            calendarUtil.setValidDate(tempDate, 1, 1);
                            tempDate = getStartDateForWeek(tempDate, ruleObject.day);
                            break;
                        }
                        var newstr = getDayString(expectedDays[0]);
                        if (DAYINDEX[currentMonthDate.getDay()] === newstr
                            && new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), 0)
                                > new Date(startDate.getFullYear())) {
                            monthCollection.push([currentMonthDate.getTime()]);
                        }
                        currentMonthDate.setDate(currentMonthDate.getDate() + (7));
                    }
                }
                else {
                    calendarUtil.setValidDate(tempDate, 1, 1);
                    tempDate = getStartDateForWeek(tempDate, ruleObject.day);
                    break;
                }
            }
        }
        tempDate.setFullYear(currentMonthDate.getFullYear(), currentMonthDate.getMonth(), currentMonthDate.getDate());
        // To filter date collection based on BYDAY Index, then BYSETPOS and to insert datas into existing collection
        insertDateCollectionBasedonIndex(monthCollection, startDate, endDate, data, ruleObject);
        if (calendarUtil.isLastMonth(tempDate)) {
            calendarUtil.setValidDate(tempDate, 1, 1);
            tempDate = getStartDateForWeek(tempDate, ruleObject.day);
        }
        tempDate.setFullYear(tempDate.getFullYear() + interval - 1);
        if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
            return;
        }
        tempDate = getStartDateForWeek(tempDate, ruleObject.day);
        monthCollection = [];
    }
}
/**
 * To process the recurrence rule when BYDAY property having values with integer
 *
 * @param {Date} startDate Accepts the strat date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of dates
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function processDateCollectionforByDayWithInteger(startDate, endDate, data, ruleObject) {
    var expectedDays = ruleObject.day;
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var tempDate = new Date(startDate.getTime());
    var interval = ruleObject.interval;
    var monthCollection = [];
    var dateCollection = [];
    var index;
    var state;
    var monthInit = 0;
    var currentMonthDate;
    var currentDate;
    var beginDate;
    tempDate = calendarUtil.getMonthStartDate(tempDate);
    var datas = [];
    if (ruleObject.month.length) {
        calendarUtil.setMonth(tempDate, ruleObject.month[0], 1);
    }
    tempDate = getStartDateForWeek(tempDate, ruleObject.day);
    while (compareDates(tempDate, endDate)) {
        currentMonthDate = new Date(tempDate.getTime());
        for (var i = 0; i <= ruleObject.month.length; i++) {
            for (var j = 0; j <= expectedDays.length - 1; j++) {
                tempDate = calendarUtil.getMonthStartDate(tempDate);
                tempDate = getStartDateForWeek(tempDate, ruleObject.day);
                monthCollection = [];
                while (calendarUtil.isSameYear(currentMonthDate, tempDate) &&
                    (expectedCount && (data.length + ruleObject.recExceptionCount) <= expectedCount)) {
                    while (calendarUtil.isSameYear(currentMonthDate, tempDate)) {
                        currentMonthDate = new Date(tempDate.getTime());
                        if (ruleObject.month.length === 0 ||
                            (ruleObject.month.length > 0 && ruleObject.month[parseInt(i.toString(), 10)] === calendarUtil.getMonth(currentMonthDate))) {
                            var expectedDaysArray = expectedDays[parseInt(j.toString(), 10)].match(SPLITNUMBERANDSTRING);
                            var position = parseInt(expectedDaysArray[0], 10);
                            currentDate = new Date(tempDate.getTime());
                            while (calendarUtil.isSameYear(currentDate, tempDate)
                                && calendarUtil.isSameMonth(currentDate, tempDate)) {
                                if (expectedDaysArray[expectedDaysArray.length - 1] === DAYINDEX[currentDate.getDay()]) {
                                    monthCollection.push([currentDate.getTime()]);
                                }
                                currentDate.setDate(currentDate.getDate() + (1));
                            }
                            currentDate.setDate(currentDate.getDate() - (1));
                            if (expectedDaysArray[0].indexOf('-') > -1) {
                                index = monthCollection.length - (-1 * position);
                            }
                            else {
                                index = position - 1;
                            }
                            index = isNaN(index) ? 0 : index;
                        }
                        monthInit = setNextValidDate(tempDate, ruleObject, monthInit, beginDate, 1);
                        tempDate = getStartDateForWeek(tempDate, ruleObject.day);
                    }
                }
                tempDate = j === 0 && currentDate ? new Date(currentDate.getTime()) : new Date(currentMonthDate.getTime());
                if (monthCollection.length > 0) {
                    if (isNullOrUndefined(ruleObject.setPosition)) {
                        insertDatasIntoExistingCollection(monthCollection, state, startDate, endDate, data, ruleObject, index);
                    }
                    else {
                        dateCollection = [(filterDateCollectionByIndex(monthCollection, index, datas))];
                    }
                }
                if (expectedCount && (data.length + ruleObject.recExceptionCount) >= expectedCount) {
                    return;
                }
            }
        }
        if (!isNullOrUndefined(ruleObject.setPosition)) {
            insertDateCollectionBasedonBySetPos(dateCollection, state, startDate, endDate, data, ruleObject);
            datas = [];
        }
        if (calendarUtil.isLastMonth(tempDate)) {
            calendarUtil.setValidDate(tempDate, 1, 1);
            tempDate.setFullYear(tempDate.getFullYear() + interval - 1);
        }
        else {
            tempDate.setFullYear(tempDate.getFullYear() + interval);
        }
        tempDate = getStartDateForWeek(tempDate, ruleObject.day);
        if (ruleObject.month.length) {
            calendarUtil.setMonth(tempDate, ruleObject.month[0], tempDate.getDate());
        }
    }
}
/**
 * To get recurrence collection if BYSETPOS is null
 *
 * @param {number[]} monthCollection Accepts the month collection dates
 * @param {string[]} expectedDays Accepts the exception dates
 * @returns {RuleData} Returns the rule data object
 * @private
 */
function getRecurrenceCollection(monthCollection, expectedDays) {
    var index;
    var recurrenceCollectionObject = { monthCollection: [], index: 0 };
    if (expectedDays.length === 1) {
        // To split numeric value from BYDAY property value
        var expectedDaysArrays = expectedDays[0].match(SPLITNUMBERANDSTRING);
        var arrPosition = void 0;
        if (expectedDaysArrays.length > 1) {
            arrPosition = parseInt(expectedDaysArrays[0], 10);
            index = ((arrPosition < 1) ? (monthCollection.length + arrPosition) : arrPosition - 1);
        }
        else {
            index = 0;
            monthCollection = getDateCollectionforBySetPosNull(monthCollection);
        }
    }
    else {
        index = 0;
        monthCollection = getDateCollectionforBySetPosNull(monthCollection);
    }
    recurrenceCollectionObject.monthCollection = monthCollection;
    recurrenceCollectionObject.index = index;
    return recurrenceCollectionObject;
}
/**
 * Internal method to process the data collections
 *
 * @param {number[]} dateCollection Accepts the date collections
 * @param {boolean} state Accepts the state
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of numbers
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function insertDataCollection(dateCollection, state, startDate, endDate, data, ruleObject) {
    var index = ((ruleObject.setPosition < 1) ?
        (dateCollection.length + ruleObject.setPosition) : ruleObject.setPosition - 1);
    if (isNullOrUndefined(ruleObject.setPosition)) {
        index = 0;
        dateCollection = getDateCollectionforBySetPosNull(dateCollection);
    }
    if (dateCollection.length > 0) {
        insertDatasIntoExistingCollection(dateCollection, state, startDate, endDate, data, ruleObject, index);
    }
}
/**
 * To process month collection if BYSETPOS is null
 *
 * @param {number[]} monthCollection Accepts the month date collections
 * @returns {number[]} Returns the month date collections
 * @private
 */
function getDateCollectionforBySetPosNull(monthCollection) {
    var datas = [];
    for (var week = 0; week < monthCollection.length; week++) {
        for (var row = 0; row < monthCollection[parseInt(week.toString(), 10)].length; row++) {
            datas.push(new Date(monthCollection[parseInt(week.toString(), 10)][parseInt(row.toString(), 10)]).getTime());
        }
    }
    monthCollection = datas.length > 0 ? [datas] : [];
    return monthCollection;
}
/**
 * To filter date collection based on BYDAY Index, then BYSETPOS and to insert datas into existing collection
 *
 * @param {number[]} monthCollection Accepts the month date collections
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the date collections
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function insertDateCollectionBasedonIndex(monthCollection, startDate, endDate, data, ruleObject) {
    var expectedDays = ruleObject.day;
    var state;
    var datas = [];
    var dateCollection = [];
    var recurrenceCollections = getRecurrenceCollection(monthCollection, expectedDays);
    monthCollection = recurrenceCollections.monthCollection;
    var index = recurrenceCollections.index;
    if (ruleObject.setPosition != null) {
        dateCollection = [(filterDateCollectionByIndex(monthCollection, index, datas))];
        insertDateCollectionBasedonBySetPos(dateCollection, state, startDate, endDate, data, ruleObject);
    }
    else {
        if (monthCollection.length > 0) {
            insertDatasIntoExistingCollection(monthCollection, state, startDate, endDate, data, ruleObject, index);
        }
    }
    datas = [];
}
/**
 * To filter date collection when BYDAY property having values with number
 *
 * @param {number[]} monthCollection Accepts the date collections
 * @param {number} index Accepts the index of date collections
 * @param {number[]} datas Accepts the collection of dates
 * @returns {number[]} Returns the collection of dates
 * @private
 */
function filterDateCollectionByIndex(monthCollection, index, datas) {
    for (var week = 0; week < monthCollection[parseInt(index.toString(), 10)].length; week++) {
        datas.push(monthCollection[parseInt(index.toString(), 10)][parseInt(week.toString(), 10)]);
    }
    return datas;
}
/**
 * To insert processed date collection in final array element
 *
 * @param {boolean} state Accepts the state of the recurrence rule
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @param {number} dayData Accepts the date index
 * @returns {void}
 * @private
 */
function insertDateCollection(state, startDate, endDate, data, ruleObject, dayData) {
    var expectedCount = getDateCount$1(startDate, ruleObject);
    var chDate = new Date(dayData);
    state = validateRules(chDate, ruleObject);
    if ((chDate >= startDate) && compareDates(chDate, endDate) && state
        && expectedCount && (data.length + ruleObject.recExceptionCount) < expectedCount) {
        excludeDateHandler(data, dayData);
    }
}
/**
 * Return the last week number of given month and year.
 *
 * @param {number} year Accepts the Year in number format
 * @param {number} startDayOfWeek Accepts the start date
 * @param {number[]} monthCollection Accepts the collection of dates
 * @param {number} week Accepts the week in number format
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {number} returns week number
 * @private
 */
function weekCount(year, startDayOfWeek, monthCollection, week, ruleObject) {
    var firstDayOfWeek = startDayOfWeek || 0;
    var firstOfMonth = new Date(year, ruleObject.month[0] - 1, 1);
    var lastOfMonth = new Date(year, ruleObject.month[0], 0);
    var numberOfDaysInMonth = lastOfMonth.getDate();
    var firstWeekDay = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;
    var used = firstWeekDay + numberOfDaysInMonth;
    var count = Math.ceil(used / 7) - 1;
    var dayData = monthCollection[parseInt(week.toString(), 10)][parseInt(count.toString(), 10)];
    var chDate = new Date(dayData);
    var state = validateRules(chDate, ruleObject);
    return (state) ? count : count - 1;
}
/**
 * To process date collection based on Byset position after process the collection based on BYDAY property value index: EX:BYDAY=1SUm-1SU
 *
 * @param {number[]} monthCollection Accepts the collection of dates
 * @param {boolean} state Accepts the state of the recurrence rule
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {void}
 * @private
 */
function insertDateCollectionBasedonBySetPos(monthCollection, state, startDate, endDate, data, ruleObject) {
    if (monthCollection.length > 0) {
        for (var week = 0; week < monthCollection.length; week++) {
            monthCollection[parseInt(week.toString(), 10)].sort();
            var expectedDays = ruleObject.day;
            var isHavingNumber = expectedDays.map(function (item) { return HASNUMBER.test(item); });
            var weekIndex = (ruleObject.freq === 'YEARLY' && (ruleObject.validRules.indexOf('BYMONTH') > -1) &&
                !(isHavingNumber.indexOf(true) > -1)) ?
                weekCount(new Date(monthCollection[0][0]).getFullYear(), 0, monthCollection, week, ruleObject)
                : (monthCollection[parseInt(week.toString(), 10)].length + ruleObject.setPosition);
            var index = ((ruleObject.setPosition < 1) ? weekIndex : ruleObject.setPosition - 1);
            var dayData = monthCollection[parseInt(week.toString(), 10)][parseInt(index.toString(), 10)];
            insertDateCollection(state, startDate, endDate, data, ruleObject, dayData);
        }
    }
}
/**
 * To insert datas into existing collection which is processed from previous loop.
 *
 * @param {number[]} monthCollection Accepts the collection of dates
 * @param {boolean} state Accepts the state of the recurrence rule
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @param {number[]} data Accepts the collection of date
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @param {number} index Accepts the index value
 * @returns {void}
 * @private
 */
function insertDatasIntoExistingCollection(monthCollection, state, startDate, endDate, data, ruleObject, index) {
    if (monthCollection.length > 0) {
        index = !isNullOrUndefined(index) ? index :
            ((ruleObject.setPosition < 1)
                ? (monthCollection.length + ruleObject.setPosition) : ruleObject.setPosition - 1);
        monthCollection[parseInt(index.toString(), 10)].sort();
        for (var week = 0; week < monthCollection[parseInt(index.toString(), 10)].length; week++) {
            var dayData = monthCollection[parseInt(index.toString(), 10)][parseInt(week.toString(), 10)];
            insertDateCollection(state, startDate, endDate, data, ruleObject, dayData);
        }
    }
}
/**
 * Internal method to compare dates
 *
 * @param {Date} startDate Accepts the start date
 * @param {Date} endDate Accepts the end date
 * @returns {boolean} Returns the result of checking start and end dates
 * @private
 */
function compareDates(startDate, endDate) {
    return endDate ? (startDate <= endDate) : true;
}
/**
 * Internal method to get day string
 *
 * @param {string} expectedDays Accepts the exception date string
 * @returns {string} Returns the valid string
 * @private
 */
function getDayString(expectedDays) {
    // To get BYDAY value without numeric value
    var newstr = expectedDays.replace(REMOVENUMBERINSTRING, '');
    return newstr;
}
/**
 * Internal method to check day index
 *
 * @param {number} day Accepts the day index
 * @param {string[]} expectedDays Accepts the exception dates
 * @returns {boolean} Returns the index date
 * @private
 */
function checkDayIndex(day, expectedDays) {
    var sortedExpectedDays = [];
    expectedDays.forEach(function (element) {
        var expectedDaysNumberSplit = element.match(SPLITNUMBERANDSTRING);
        if (expectedDaysNumberSplit.length === 2) {
            sortedExpectedDays.push(expectedDaysNumberSplit[1]);
        }
        else {
            sortedExpectedDays.push(expectedDaysNumberSplit[0]);
        }
    });
    return (sortedExpectedDays.indexOf(DAYINDEX[parseInt(day.toString(), 10)]) === -1);
}
/**
 * Internal method to get start date of week
 *
 * @param {Date} startDate Accepts the start date
 * @param {string[]} expectedDays Accepts the exception dates
 * @returns {Date} Return the week start date
 * @private
 */
function getStartDateForWeek(startDate, expectedDays) {
    var tempDate = new Date(startDate.getTime());
    var newstr;
    if (expectedDays.length > 0) {
        var expectedDaysArr = [];
        for (var i = 0; i <= expectedDays.length - 1; i++) {
            newstr = getDayString(expectedDays[parseInt(i.toString(), 10)]);
            expectedDaysArr.push(newstr);
        }
        if (expectedDaysArr.indexOf(DAYINDEX[tempDate.getDay()]) === -1) {
            do {
                tempDate.setDate(tempDate.getDate() + 1);
            } while (expectedDaysArr.indexOf(DAYINDEX[tempDate.getDay()]) === -1);
        }
    }
    return tempDate;
}
/**
 * Method to generate recurrence rule object from given rule
 *
 * @param {string} rules Accepts the recurrence rule
 * @returns {RecRule} Returns the recurrence rule object
 */
function extractObjectFromRule(rules) {
    var ruleObject = {
        freq: null,
        interval: 1,
        count: null,
        until: null,
        day: [],
        wkst: null,
        month: [],
        weekNo: [],
        monthDay: [],
        yearDay: [],
        setPosition: null,
        validRules: []
    };
    var rulesList = rules.split(';');
    var splitData = [];
    var temp;
    rulesList.forEach(function (data) {
        splitData = data.split('=');
        switch (splitData[0]) {
            case 'UNTIL':
                temp = splitData[1];
                ruleObject.until = getDateFromRecurrenceDateString(temp);
                break;
            case 'BYDAY':
                ruleObject.day = splitData[1].split(',');
                ruleObject.validRules.push(splitData[0]);
                break;
            case 'BYMONTHDAY':
                ruleObject.monthDay = splitData[1].split(',').map(Number);
                ruleObject.validRules.push(splitData[0]);
                break;
            case 'BYMONTH':
                ruleObject.month = splitData[1].split(',').map(Number);
                ruleObject.validRules.push(splitData[0]);
                break;
            case 'BYYEARDAY':
                ruleObject.yearDay = splitData[1].split(',').map(Number);
                ruleObject.validRules.push(splitData[0]);
                break;
            case 'BYWEEKNO':
                ruleObject.weekNo = splitData[1].split(',').map(Number);
                ruleObject.validRules.push(splitData[0]);
                break;
            case 'INTERVAL':
                ruleObject.interval = parseInt(splitData[1], 10);
                break;
            case 'COUNT':
                ruleObject.count = parseInt(splitData[1], 10);
                break;
            case 'BYSETPOS':
                ruleObject.setPosition = parseInt(splitData[1], 10) > 4 ? -1 : parseInt(splitData[1], 10);
                break;
            case 'FREQ':
                ruleObject.freq = splitData[1];
                break;
            case 'WKST':
                ruleObject.wkst = splitData[1];
                break;
        }
    });
    if ((ruleObject.freq === 'MONTHLY') && (ruleObject.monthDay.length === 0)) {
        var index = ruleObject.validRules.indexOf('BYDAY');
        ruleObject.validRules.splice(index, 1);
    }
    return ruleObject;
}
/**
 * Internal method to validate proper date
 *
 * @param {Date} tempDate Accepts the date value
 * @param {number} data Accepts the data value
 * @param {Date} startDate Accepts the start date
 * @returns {boolean} Returns the result of date validate
 * @private
 */
function validateProperDate(tempDate, data, startDate) {
    var maxDate = calendarUtil.getMonthDaysCount(tempDate);
    return (data <= maxDate) && (tempDate >= startDate);
}
/**
 * Internal method to process week days
 *
 * @param {string[]} expectedDays Accepts the expection dates
 * @returns {Object} Returns the weekdays object
 * @private
 */
function processWeekDays(expectedDays) {
    var dayCycle = {};
    expectedDays.forEach(function (element, index) {
        if (index === expectedDays.length - 1) {
            var startIndex = dayIndex.indexOf(element);
            var temp = startIndex;
            while (temp % 7 !== dayIndex.indexOf(expectedDays[0])) {
                temp++;
            }
            dayCycle["" + element] = temp - startIndex;
        }
        else {
            dayCycle["" + element] = dayIndex.indexOf(expectedDays[(index + 1)]) - dayIndex.indexOf(element);
        }
    });
    return dayCycle;
}
/**
 * Internal method to check date
 *
 * @param {Date} tempDate Accepts the date value
 * @param {number[]} expectedDate Accepts the exception dates
 * @returns {boolean} Returns the boolean value
 * @private
 */
function checkDate(tempDate, expectedDate) {
    var temp = expectedDate.slice(0);
    var data;
    var maxDate = calendarUtil.getMonthDaysCount(tempDate);
    data = temp.shift();
    while (data) {
        if (data < 0) {
            data = data + maxDate + 1;
        }
        if (data === tempDate.getDate()) {
            return false;
        }
        data = temp.shift();
    }
    return true;
}
/**
 * Internal method to check the year value
 *
 * @param {Date} tempDate Accepts the date value
 * @param {number[]} expectedyearDay Accepts the exception dates in year
 * @returns {boolean} Returns the boolean value
 * @private
 */
function checkYear(tempDate, expectedyearDay) {
    var temp = expectedyearDay.slice(0);
    var data;
    var yearDay = getYearDay(tempDate);
    data = temp.shift();
    while (data) {
        if (data < 0) {
            data = data + calendarUtil.getYearDaysCount(tempDate, 0) + 1;
        }
        if (data === yearDay) {
            return false;
        }
        data = temp.shift();
    }
    return true;
}
/**
 * Internal method to get the year day
 *
 * @param {Date} currentDate Accepts the date value
 * @returns {number} Returns the boolean value
 * @private
 */
function getYearDay(currentDate) {
    if (!startDateCollection[calendarUtil.getFullYear(currentDate)]) {
        startDateCollection[calendarUtil.getFullYear(currentDate)] = calendarUtil.getYearLastDate(currentDate, 0);
    }
    var tempDate = startDateCollection[calendarUtil.getFullYear(currentDate)];
    var diff = currentDate.getTime() - tempDate.getTime();
    return Math.ceil(diff / MS_PER_DAY);
}
/**
 * Internal method to validate monthly rule type
 *
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {MonthlyType} Returns the monthly type object
 * @private
 */
function validateMonthlyRuleType(ruleObject) {
    if (ruleObject.monthDay.length && !ruleObject.day.length) {
        return 'date';
    }
    else if (!ruleObject.monthDay.length && ruleObject.day.length) {
        return 'day';
    }
    return 'both';
}
/**
 * Internal method to re-order the week days based on first day of week
 *
 * @param {string[]} days Accepts the week days value
 * @returns {void}
 * @private
 */
function rotate(days) {
    var data = days.shift();
    days.push(data);
}
/**
 * Internal method to set first day of week
 *
 * @param {string} day Accepts the first day string
 * @returns {void}
 * @private
 */
function setFirstDayOfWeek(day) {
    while (dayIndex[0] !== day) {
        rotate(dayIndex);
    }
}
/**
 * Internal method to validate recurrence rule
 *
 * @param {Date} tempDate Accepts the date value
 * @param {RecRule} ruleObject Accepts the recurrence rule object
 * @returns {boolean} Returns the boolean value
 * @private
 */
function validateRules(tempDate, ruleObject) {
    var state = true;
    var expectedDays = ruleObject.day;
    var expectedMonth = ruleObject.month;
    var expectedDate = calendarUtil.getExpectedDays(tempDate, ruleObject.monthDay);
    var expectedyearDay = ruleObject.yearDay;
    ruleObject.validRules.forEach(function (rule) {
        switch (rule) {
            case 'BYDAY':
                if (checkDayIndex(tempDate.getDay(), expectedDays)) {
                    state = false;
                }
                break;
            case 'BYMONTH':
                if (calendarUtil.checkMonth(tempDate, expectedMonth)) {
                    state = false;
                }
                break;
            case 'BYMONTHDAY':
                if (checkDate(tempDate, expectedDate)) {
                    state = false;
                }
                break;
            case 'BYYEARDAY':
                if (checkYear(tempDate, expectedyearDay)) {
                    state = false;
                }
                break;
        }
    });
    return state;
}
/**
 * Internal method to get calendar util
 *
 * @param {CalendarType} calendarMode Accepts the calendar type object
 * @returns {CalendarUtil} Returns the calendar util object
 * @private
 */
function getCalendarUtil(calendarMode) {
    if (calendarMode === 'Islamic') {
        return new Islamic();
    }
    return new Gregorian();
}
var startDateCollection = {};
var tempExcludeDate;
var dayIndex = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
var maxOccurrence;
var tempViewDate;
var calendarUtil;
var DAYINDEX = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
var MAXOCCURRENCE = 43;
var WEEKPOS = ['first', 'second', 'third', 'fourth', 'last'];
var TIMES = 'summaryTimes';
var ON = 'summaryOn';
var EVERY = 'every';
var UNTIL = 'summaryUntil';
var DAYS = 'summaryDay';
var WEEKS = 'summaryWeek';
var MONTHS = 'summaryMonth';
var YEARS = 'summaryYear';
var DAYINDEXOBJECT = {
    SU: 'sun',
    MO: 'mon',
    TU: 'tue',
    WE: 'wed',
    TH: 'thu',
    FR: 'fri',
    SA: 'sat'
};
// To check string has number
var HASNUMBER = /\d/;
// To find the numbers in string
var REMOVENUMBERINSTRING = /[^A-Z]+/;
// To split number and string
var SPLITNUMBERANDSTRING = /[a-z]+|[^a-z]+/gi;
/**
 * Method to generate string from date
 *
 * @param {Date} date Accepts the date value
 * @returns {string} Returns the string value
 */
function getRecurrenceStringFromDate(date) {
    return [date.getUTCFullYear(),
        roundDateValues(date.getUTCMonth() + 1),
        roundDateValues(date.getUTCDate()),
        'T',
        roundDateValues(date.getUTCHours()),
        roundDateValues(date.getUTCMinutes()),
        roundDateValues(date.getUTCSeconds()),
        'Z'].join('');
}
/**
 * Internal method to round the date values
 *
 * @param {string | number} date Accepts the date value in either string or number format
 * @returns {string} Returns the date value in string format
 * @private
 */
function roundDateValues(date) {
    return ('0' + date).slice(-2);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/**
 * EventBase for appointment rendering
 */
var EventBase = /** @__PURE__ @class */ (function () {
    /**
     * Constructor for EventBase
     *
     * @param {Schedule} parent Accepts the schedule instance
     */
    function EventBase(parent) {
        this.slots = [];
        this.isDoubleTapped = false;
        this.parent = parent;
    }
    EventBase.prototype.processData = function (events, timeZonePropChanged, oldTimezone) {
        var _this = this;
        var start = this.parent.activeView.startDate();
        var end = this.parent.activeView.endDate();
        var fields = this.parent.eventFields;
        var processed = [];
        var temp = 1;
        var generateID = false;
        var resourceCollection = this.parent.resourceBase ? this.parent.resourceBase.resourceCollection : [];
        if (events.length > 0 && isNullOrUndefined(events[0][fields.id])) {
            generateID = true;
        }
        var _loop_1 = function (event_1) {
            if (generateID) {
                event_1[fields.id] = temp++;
            }
            event_1 = this_1.updateEventDateTime(event_1);
            if (timeZonePropChanged) {
                this_1.processTimezoneChange(event_1, oldTimezone);
            }
            else if (!this_1.parent.isPrinting && !this_1.parent.uiStateValues.isPreventTimezone) {
                event_1 = this_1.processTimezone(event_1);
            }
            for (var level = 0; level < resourceCollection.length; level++) {
                if (event_1[resourceCollection[parseInt(level.toString(), 10)].field] === null || event_1[resourceCollection[parseInt(level.toString(), 10)].field] === 0) {
                    event_1[resourceCollection[parseInt(level.toString(), 10)].field] = undefined;
                }
            }
            if (!isNullOrUndefined(event_1[fields.recurrenceRule]) && event_1[fields.recurrenceRule] === '') {
                event_1[fields.recurrenceRule] = null;
            }
            if (!isNullOrUndefined(event_1[fields.recurrenceRule]) && isNullOrUndefined(event_1[fields.recurrenceID]) &&
                !(this_1.parent.crudModule && this_1.parent.crudModule.crudObj.isCrudAction)) {
                processed = processed.concat(this_1.generateOccurrence(event_1, null, true));
            }
            else {
                if (this_1.parent.crudModule && this_1.parent.crudModule.crudObj.isCrudAction) {
                    if (!isNullOrUndefined(event_1[fields.recurrenceRule]) && isNullOrUndefined(event_1[fields.recurrenceID])) {
                        var recurrenceEvent = this_1.generateOccurrence(event_1, null, true);
                        var _loop_2 = function (occurrence) {
                            var app = this_1.parent.eventsProcessed.filter(function (data) {
                                return data[fields.startTime].getTime() - occurrence[fields.startTime].getTime() === 0 &&
                                    data[fields.id] === occurrence[fields.id];
                            });
                            occurrence.Guid = (app.length > 0) ? app[0].Guid : this_1.generateGuid();
                            processed.push(occurrence);
                        };
                        for (var _i = 0, recurrenceEvent_1 = recurrenceEvent; _i < recurrenceEvent_1.length; _i++) {
                            var occurrence = recurrenceEvent_1[_i];
                            _loop_2(occurrence);
                        }
                    }
                    else {
                        var app = this_1.parent.eventsProcessed.filter(function (data) {
                            return data[_this.parent.eventFields.id] === event_1[_this.parent.eventFields.id];
                        });
                        event_1.Guid = (app.length > 0) ? app[0].Guid : this_1.generateGuid();
                        processed.push(event_1);
                    }
                }
                else {
                    event_1.Guid = this_1.generateGuid();
                    processed.push(event_1);
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
            var event_1 = events_1[_i];
            _loop_1(event_1);
        }
        this.parent.eventsProcessed = [];
        var eventData = processed.filter(function (data) {
            return !data[_this.parent.eventFields.isBlock];
        });
        this.parent.eventsProcessed = this.filterEvents(start, end, eventData);
        var blockData = processed.filter(function (data) {
            return data[_this.parent.eventFields.isBlock];
        });
        for (var _a = 0, blockData_1 = blockData; _a < blockData_1.length; _a++) {
            var eventObj = blockData_1[_a];
            if (eventObj[fields.isAllDay]) {
                var isDifferentDate = resetTime(new Date(eventObj[fields.startTime].getTime())) <
                    resetTime(new Date(eventObj[fields.endTime].getTime()));
                if (!isDifferentDate) {
                    eventObj[fields.startTime] = resetTime(eventObj[fields.startTime]);
                    eventObj[fields.endTime] = addDays(resetTime(eventObj[fields.endTime]), 1);
                }
            }
        }
        this.parent.blockProcessed = blockData;
        return eventData;
    };
    EventBase.prototype.updateEventDateTime = function (eventData) {
        if (typeof eventData[this.parent.eventFields.startTime] === 'string') {
            eventData[this.parent.eventFields.startTime] = getDateFromString(eventData[this.parent.eventFields.startTime]);
        }
        if (typeof eventData[this.parent.eventFields.endTime] === 'string') {
            eventData[this.parent.eventFields.endTime] = getDateFromString(eventData[this.parent.eventFields.endTime]);
        }
        return eventData;
    };
    EventBase.prototype.getProcessedEvents = function (eventCollection) {
        if (eventCollection === void 0) { eventCollection = this.parent.eventsData; }
        var processed = [];
        for (var _i = 0, eventCollection_1 = eventCollection; _i < eventCollection_1.length; _i++) {
            var event_2 = eventCollection_1[_i];
            if (!isNullOrUndefined(event_2[this.parent.eventFields.recurrenceRule]) &&
                isNullOrUndefined(event_2[this.parent.eventFields.recurrenceID])) {
                processed = processed.concat(this.generateOccurrence(event_2));
            }
            else {
                processed.push(event_2);
            }
        }
        return processed;
    };
    EventBase.prototype.timezonePropertyChange = function (oldTimezone) {
        var data = this.parent.eventsData.concat(this.parent.blockData);
        var processed = this.processData(data, true, oldTimezone);
        this.parent.notify(dataReady, { processedData: processed });
    };
    EventBase.prototype.timezoneConvert = function (eventData) {
        var fields = this.parent.eventFields;
        eventData[fields.startTimezone] = eventData[fields.startTimezone] || eventData[fields.endTimezone];
        eventData[fields.endTimezone] = eventData[fields.endTimezone] || eventData[fields.startTimezone];
        if (this.parent.timezone) {
            var startTz = eventData[fields.startTimezone];
            var endTz = eventData[fields.endTimezone];
            eventData[fields.startTime] = this.parent.tzModule.convert(eventData[fields.startTime], this.parent.timezone, startTz);
            eventData[fields.endTime] = this.parent.tzModule.convert(eventData[fields.endTime], this.parent.timezone, endTz);
        }
    };
    EventBase.prototype.processTimezoneChange = function (event, oldTimezone) {
        var fields = this.parent.eventFields;
        if (event[fields.isAllDay]) {
            return;
        }
        if (oldTimezone && this.parent.timezone) {
            event[fields.startTime] = this.parent.tzModule.convert(event[fields.startTime], oldTimezone, this.parent.timezone);
            event[fields.endTime] = this.parent.tzModule.convert(event[fields.endTime], oldTimezone, this.parent.timezone);
        }
        else if (!oldTimezone && this.parent.timezone) {
            event[fields.startTime] = this.parent.tzModule.add(event[fields.startTime], this.parent.timezone);
            event[fields.endTime] = this.parent.tzModule.add(event[fields.endTime], this.parent.timezone);
        }
        else if (oldTimezone && !this.parent.timezone) {
            event[fields.startTime] = this.parent.tzModule.remove(event[fields.startTime], oldTimezone);
            event[fields.endTime] = this.parent.tzModule.remove(event[fields.endTime], oldTimezone);
        }
    };
    EventBase.prototype.processTimezone = function (event, isReverse) {
        if (isReverse === void 0) { isReverse = false; }
        var fields = this.parent.eventFields;
        if (event[fields.startTimezone] || event[fields.endTimezone]) {
            var startTimezone = event[fields.startTimezone] || event[fields.endTimezone];
            var endTimezone = event[fields.endTimezone] || event[fields.startTimezone];
            if (isReverse) {
                if (this.parent.timezone) {
                    event[fields.startTime] = this.parent.tzModule.convert(event[fields.startTime], startTimezone, this.parent.timezone);
                    event[fields.endTime] = this.parent.tzModule.convert(event[fields.endTime], endTimezone, this.parent.timezone);
                    event[fields.startTime] = this.parent.tzModule.remove(event[fields.startTime], this.parent.timezone);
                    event[fields.endTime] = this.parent.tzModule.remove(event[fields.endTime], this.parent.timezone);
                }
                else {
                    event[fields.startTime] = this.parent.tzModule.remove(event[fields.startTime], startTimezone);
                    event[fields.endTime] = this.parent.tzModule.remove(event[fields.endTime], endTimezone);
                }
            }
            else {
                event[fields.startTime] = this.parent.tzModule.add(event[fields.startTime], startTimezone);
                event[fields.endTime] = this.parent.tzModule.add(event[fields.endTime], endTimezone);
                if (this.parent.timezone) {
                    event[fields.startTime] = this.parent.tzModule.convert(event[fields.startTime], startTimezone, this.parent.timezone);
                    event[fields.endTime] = this.parent.tzModule.convert(event[fields.endTime], endTimezone, this.parent.timezone);
                }
            }
        }
        else if (this.parent.timezone) {
            if (isReverse) {
                event[fields.startTime] = this.parent.tzModule.remove(event[fields.startTime], this.parent.timezone);
                event[fields.endTime] = this.parent.tzModule.remove(event[fields.endTime], this.parent.timezone);
            }
            else {
                event[fields.startTime] = this.parent.tzModule.add(event[fields.startTime], this.parent.timezone);
                event[fields.endTime] = this.parent.tzModule.add(event[fields.endTime], this.parent.timezone);
            }
        }
        return event;
    };
    EventBase.prototype.filterBlockEvents = function (eventObj) {
        var fields = this.parent.eventFields;
        var eStart = eventObj[fields.startTime];
        var eEnd = eventObj[fields.endTime];
        var resourceData;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var data = this.getGroupIndexFromEvent(eventObj);
            resourceData = this.parent.resourceBase.lastResourceLevel[parseInt(data.toString(), 10)];
        }
        var blockEvents = extend([], this.parent.blockProcessed, null, true);
        for (var _i = 0, blockEvents_1 = blockEvents; _i < blockEvents_1.length; _i++) {
            var eventObj_1 = blockEvents_1[_i];
            if (eventObj_1[fields.isAllDay]) {
                var isDifferentTime = eventObj_1[fields.endTime].getTime() >
                    resetTime(new Date(eventObj_1[fields.endTime].getTime())).getTime();
                if (isDifferentTime) {
                    eventObj_1[fields.startTime] = resetTime(eventObj_1[fields.startTime]);
                    eventObj_1[fields.endTime] = addDays(resetTime(eventObj_1[fields.endTime]), 1);
                }
            }
        }
        return this.filterEvents(eStart, eEnd, blockEvents, resourceData);
    };
    EventBase.prototype.filterEvents = function (startDate, endDate, appointments, resourceTdData) {
        if (appointments === void 0) { appointments = this.parent.eventsProcessed; }
        var predicate = this.parent.dataModule.getStartEndQuery(startDate, endDate);
        var filter = new DataManager({ json: appointments }).executeLocal(new Query().where(predicate));
        if (resourceTdData) {
            filter = this.filterEventsByResource(resourceTdData, filter);
        }
        return this.sortByTime(filter);
    };
    EventBase.prototype.filterEventsByRange = function (eventCollection, startDate, endDate) {
        var _this = this;
        var filteredEvents = [];
        if (startDate && endDate) {
            filteredEvents = this.filterEvents(startDate, endDate, eventCollection);
        }
        else if (startDate && !endDate) {
            filteredEvents = eventCollection.filter(function (e) { return e[_this.parent.eventFields.startTime] >= startDate; });
        }
        else if (!startDate && endDate) {
            filteredEvents = eventCollection.filter(function (e) { return e[_this.parent.eventFields.endTime] <= endDate; });
        }
        else {
            filteredEvents = eventCollection;
        }
        return this.sortByTime(filteredEvents);
    };
    EventBase.prototype.filterEventsByResource = function (resourceTdData, appointments) {
        if (appointments === void 0) { appointments = this.parent.eventsProcessed; }
        var predicate = {};
        var resourceCollection = this.parent.resourceBase.resourceCollection;
        for (var level = 0; level < resourceCollection.length; level++) {
            predicate[resourceCollection[parseInt(level.toString(), 10)].field] = resourceTdData.groupOrder[parseInt(level.toString(), 10)];
        }
        var keys = Object.keys(predicate);
        var filteredCollection = appointments.filter(function (eventObj) { return keys.every(function (key) {
            if (eventObj["" + key] instanceof Array) {
                return eventObj["" + key].indexOf(predicate["" + key]) > -1;
            }
            else {
                return eventObj["" + key] === predicate["" + key];
            }
        }); });
        return filteredCollection;
    };
    EventBase.prototype.sortByTime = function (appointmentsCollection) {
        if (this.parent.eventSettings.sortComparer && (typeof (this.parent.eventSettings.sortComparer) === 'function' || typeof (this.parent.eventSettings.sortComparer) === 'string')) {
            appointmentsCollection = this.customSorting(appointmentsCollection);
        }
        else {
            var fieldMappings_1 = this.parent.eventFields;
            appointmentsCollection.sort(function (a, b) {
                var d1 = a[fieldMappings_1.startTime];
                var d2 = b[fieldMappings_1.startTime];
                return d1.getTime() - d2.getTime();
            });
        }
        return appointmentsCollection;
    };
    EventBase.prototype.sortByDateTime = function (appointments) {
        if (this.parent.eventSettings.sortComparer && (typeof (this.parent.eventSettings.sortComparer) === 'function' || typeof (this.parent.eventSettings.sortComparer) === 'string')) {
            appointments = this.customSorting(appointments);
        }
        else {
            var fieldMapping_1 = this.parent.eventFields;
            appointments.sort(function (object1, object2) {
                var d3 = object1[fieldMapping_1.startTime];
                var d4 = object2[fieldMapping_1.startTime];
                var d5 = object1[fieldMapping_1.endTime];
                var d6 = object2[fieldMapping_1.endTime];
                var d1 = d5.getTime() - d3.getTime();
                var d2 = d6.getTime() - d4.getTime();
                return (d3.getTime() - d4.getTime() || d2 - d1);
            });
        }
        return appointments;
    };
    EventBase.prototype.customSorting = function (appointments) {
        if (typeof (this.parent.eventSettings.sortComparer) === 'function') {
            return this.parent.eventSettings.sortComparer.call(this.parent, appointments);
        }
        else if (typeof (this.parent.eventSettings.sortComparer) === 'string') {
            var splits = this.parent.eventSettings.sortComparer.split('.');
            var sortFn = void 0;
            if (!isNullOrUndefined(window)) {
                sortFn = window[splits[splits.length - 1]];
            }
            if (sortFn) {
                return sortFn(appointments);
            }
        }
        return appointments;
    };
    EventBase.prototype.getSmallestMissingNumber = function (array) {
        var large = Math.max.apply(Math, array);
        for (var i = 0; i < large; i++) {
            if (array.indexOf(i) === -1) {
                return i;
            }
        }
        return large + 1;
    };
    EventBase.prototype.splitEventByDay = function (event) {
        var eventFields = this.parent.eventFields;
        var data = [];
        var eventStartTime = event[eventFields.startTime];
        var eventEndTime = event[eventFields.endTime];
        var isDifferentDate = resetTime(new Date(eventStartTime.getTime())) <
            resetTime(new Date(eventEndTime.getTime()));
        if (isDifferentDate) {
            var start = new Date(eventStartTime.getTime());
            var end = addDays(resetTime(new Date(eventStartTime.getTime())), 1);
            var endDate = (eventEndTime.getHours() === 0 && eventEndTime.getMinutes() === 0) ?
                eventEndTime : addDays(eventEndTime, 1);
            var index = 1;
            var eventLength = getDaysCount(eventStartTime.getTime(), endDate.getTime());
            while (end <= eventEndTime && start.getTime() !== end.getTime()) {
                var app = extend({}, event);
                app[eventFields.startTime] = start;
                app[eventFields.endTime] = end;
                app.data = { index: index, count: eventLength };
                app.Guid = this.generateGuid();
                app.isSpanned = true;
                data.push(app);
                start = end;
                if ((resetTime(new Date(start.getTime())).getTime() === resetTime(new Date(eventEndTime.getTime())).getTime())
                    && !(end.getTime() === eventEndTime.getTime())) {
                    end = new Date(start.getTime());
                    end = new Date(end.setHours(eventEndTime.getHours(), eventEndTime.getMinutes(), eventEndTime.getSeconds()));
                }
                else {
                    end = addDays(resetTime(new Date(start.getTime())), 1);
                }
                index++;
            }
        }
        else {
            data.push(event);
        }
        return data;
    };
    EventBase.prototype.splitEvent = function (event, dateRender) {
        var fields = this.parent.eventFields;
        var start = resetTime(event[fields.startTime]).getTime();
        var end = resetTime(event[fields.endTime]).getTime();
        if (getDateInMs(event[fields.endTime]) <= 0) {
            var temp = addDays(resetTime(event[fields.endTime]), -1).getTime();
            end = start > temp ? start : temp;
        }
        var orgStart = start;
        var orgEnd = end;
        var ranges = [];
        if (start !== end) {
            if (start < dateRender[0].getTime()) {
                start = dateRender[0].getTime();
            }
            if (end > dateRender[dateRender.length - 1].getTime()) {
                end = dateRender[dateRender.length - 1].getTime();
            }
            var cStart = start;
            for (var level = 0; level < this.slots.length; level++) {
                var slot = this.slots[parseInt(level.toString(), 10)];
                if (this.parent.currentView === 'WorkWeek' || this.parent.currentView === 'TimelineWorkWeek'
                    || this.parent.activeViewOptions.group.byDate || this.parent.activeViewOptions.showWeekend) {
                    var slotDates = [];
                    for (var _i = 0, slot_1 = slot; _i < slot_1.length; _i++) {
                        var s = slot_1[_i];
                        slotDates.push(new Date(s));
                    }
                    var renderedDates = this.getRenderedDates(slotDates);
                    if (!isNullOrUndefined(renderedDates) && renderedDates.length > 0) {
                        slot = [];
                        for (var _a = 0, renderedDates_1 = renderedDates; _a < renderedDates_1.length; _a++) {
                            var date = renderedDates_1[_a];
                            slot.push(date.getTime());
                        }
                    }
                }
                if (typeof (slot) === 'number') {
                    var temp = slot;
                    slot = [];
                    slot.push(temp);
                }
                var firstSlot = slot[0];
                cStart = (cStart <= firstSlot && end >= firstSlot) ? firstSlot : cStart;
                if (cStart > end || firstSlot > end) {
                    break;
                }
                if (!this.parent.activeViewOptions.group.byDate && this.parent.activeViewOptions.showWeekend &&
                    this.parent.currentView !== 'WorkWeek' && this.parent.currentView !== 'TimelineWorkWeek') {
                    var startIndex = slot.indexOf(cStart);
                    if (startIndex !== -1) {
                        var endIndex = slot.indexOf(end);
                        var hasBreak = endIndex !== -1;
                        endIndex = hasBreak ? endIndex : slot.length - 1;
                        var count = ((endIndex - startIndex) + 1);
                        var isLeft = (slot[parseInt(startIndex.toString(), 10)] !== orgStart);
                        var isRight = (slot[parseInt(endIndex.toString(), 10)] !== orgEnd);
                        ranges.push(this.cloneEventObject(event, slot[parseInt(startIndex.toString(), 10)], slot[parseInt(endIndex.toString(), 10)], count, isLeft, isRight));
                        if (hasBreak) {
                            break;
                        }
                    }
                }
                else {
                    if (this.dateInRange(cStart, slot[0], slot[slot.length - 1])) {
                        var availSlot = [];
                        for (var i = 0; i < slot.length; i++) {
                            if (this.dateInRange(slot[parseInt(i.toString(), 10)], orgStart, orgEnd)) {
                                availSlot.push(slot[parseInt(i.toString(), 10)]);
                            }
                        }
                        if (availSlot.length > 0) {
                            if (!this.parent.activeViewOptions.group.byDate) {
                                var isLeft = (availSlot[0] !== orgStart);
                                var isRight = (availSlot[availSlot.length - 1] !== orgEnd);
                                ranges.push(this.cloneEventObject(event, availSlot[0], availSlot[availSlot.length - 1], availSlot.length, isLeft, isRight));
                            }
                            else {
                                for (var _b = 0, availSlot_1 = availSlot; _b < availSlot_1.length; _b++) {
                                    var slot_2 = availSlot_1[_b];
                                    ranges.push(this.cloneEventObject(event, slot_2, slot_2, 1, (slot_2 !== orgStart), (slot_2 !== orgEnd)));
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            ranges.push(this.cloneEventObject(event, start, end, 1, false, false));
        }
        return ranges;
    };
    EventBase.prototype.cloneEventObject = function (event, start, end, count, isLeft, isRight) {
        var fields = this.parent.eventFields;
        var e = extend({}, event, null, true);
        var data = { count: count, isLeft: isLeft, isRight: isRight };
        data[fields.startTime] = event[fields.startTime];
        data[fields.endTime] = event[fields.endTime];
        e.data = data;
        e[fields.startTime] = new Date(start);
        e[fields.endTime] = new Date(end);
        return e;
    };
    EventBase.prototype.dateInRange = function (date, start, end) {
        return start <= date && date <= end;
    };
    EventBase.prototype.getSelectedEventElements = function (target) {
        this.removeSelectedAppointmentClass();
        if (this.parent.selectedElements.length <= 0) {
            this.parent.selectedElements.push(target);
        }
        else {
            var isAlreadySelected = this.parent.selectedElements.filter(function (element) {
                return element.getAttribute('data-guid') === target.getAttribute('data-guid');
            });
            if (isAlreadySelected.length <= 0) {
                var elementSelector = 'div[data-guid="' + target.getAttribute('data-guid') + '"]';
                var focusElements = [].slice.call(this.parent.element.querySelectorAll(elementSelector));
                for (var _i = 0, focusElements_1 = focusElements; _i < focusElements_1.length; _i++) {
                    var element = focusElements_1[_i];
                    this.parent.selectedElements.push(element);
                }
            }
            else {
                var selectedElements = this.parent.selectedElements.filter(function (element) {
                    return element.getAttribute('data-guid') !== target.getAttribute('data-guid');
                });
                this.parent.selectedElements = selectedElements;
            }
        }
        if (target && this.parent.selectedElements.length > 0) {
            this.addSelectedAppointments(this.parent.selectedElements, false);
        }
        return this.parent.selectedElements;
    };
    EventBase.prototype.getSelectedEvents = function () {
        var _this = this;
        var eventSelect = [];
        var elementSelect = [];
        var selectAppointments = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_BORDER));
        selectAppointments.filter(function (element) {
            var isAlreadyAdded = eventSelect.filter(function (event) {
                return event.Guid === element.getAttribute('data-guid');
            });
            if (isAlreadyAdded.length === 0) {
                eventSelect.push(_this.getEventByGuid(element.getAttribute('data-guid')));
            }
            elementSelect.push(element);
        });
        return {
            event: eventSelect.length > 1 ? eventSelect : eventSelect[0],
            element: elementSelect.length > 1 ? elementSelect : elementSelect[0]
        };
    };
    EventBase.prototype.removeSelectedAppointmentClass = function () {
        var selectedAppointments = this.getSelectedAppointments();
        removeClass(selectedAppointments, APPOINTMENT_BORDER);
        if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
            removeClass(selectedAppointments, AGENDA_SELECTED_CELL);
        }
    };
    EventBase.prototype.addSelectedAppointments = function (cells, preventFocus) {
        if (this.parent.currentView !== 'MonthAgenda') {
            this.parent.removeSelectedClass();
        }
        addClass(cells, APPOINTMENT_BORDER);
        if (cells.length > 0 && !preventFocus) {
            cells[cells.length - 1].focus();
        }
    };
    EventBase.prototype.getSelectedAppointments = function () {
        return [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_BORDER + ',.' + APPOINTMENT_CLASS + ':focus'));
    };
    EventBase.prototype.focusElement = function (isFocused) {
        if (this.parent.eventWindow.dialogObject && this.parent.eventWindow.dialogObject.visible) {
            return;
        }
        var activeEle = document.activeElement;
        var selectedCell = this.parent.getSelectedCells();
        if (selectedCell.length > 0 && ((activeEle && (this.parent.element.contains(activeEle) ||
            selectedCell.indexOf(activeEle) !== -1)) || isFocused)) {
            if (this.parent.keyboardInteractionModule) {
                var target = ((!isNullOrUndefined(this.parent.activeCellsData) &&
                    this.parent.activeCellsData.element) || selectedCell[selectedCell.length - 1]);
                this.parent.keyboardInteractionModule.selectCells(target instanceof Array, target);
            }
            return;
        }
        var selectedAppointments = this.getSelectedAppointments();
        if (selectedAppointments.length > 0) {
            if (this.parent.activeEventData && this.parent.activeEventData.element && selectedAppointments.indexOf(this.parent.activeEventData.element) > -1) {
                this.parent.activeEventData.element.focus();
                return;
            }
            selectedAppointments[selectedAppointments.length - 1].focus();
            return;
        }
    };
    EventBase.prototype.selectWorkCellByTime = function (eventsData) {
        var target;
        if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
            return target;
        }
        if (eventsData.length > 0) {
            var selectedObject = eventsData[eventsData.length - 1];
            var eventStartTime = selectedObject[this.parent.eventFields.startTime];
            var nearestTime = new Date(+eventStartTime).setMinutes(0, 0, 0);
            var isAllDay = this.isAllDayAppointment(selectedObject);
            if (this.parent.currentView === 'Month' || isAllDay) {
                nearestTime = new Date(+eventStartTime).setHours(0, 0, 0, 0);
            }
            var targetArea = void 0;
            if (isAllDay && ['Day', 'Week', 'WorkWeek'].indexOf(this.parent.currentView) !== -1) {
                targetArea = this.parent.getAllDayRow();
            }
            else {
                targetArea = this.parent.getContentTable();
            }
            var queryString = '[data-date="' + new Date(nearestTime).getTime() + '"]';
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                queryString += '[data-group-index="' + this.getGroupIndexFromEvent(selectedObject) + '"]';
            }
            target = targetArea.querySelector(queryString);
            if (target) {
                this.parent.activeCellsData = this.parent.getCellDetails(target);
                if (this.parent.keyboardInteractionModule) {
                    this.parent.keyboardInteractionModule.selectCells(false, target);
                }
                return target;
            }
        }
        return target;
    };
    EventBase.prototype.getGroupIndexFromEvent = function (eventData) {
        var levelIndex;
        var resource;
        var levelName;
        var idField;
        for (var i = this.parent.resourceBase.resourceCollection.length - 1; i >= 0; i--) {
            var resourceData = eventData[this.parent.resourceBase.resourceCollection[parseInt(i.toString(), 10)].field];
            if (!isNullOrUndefined(resourceData)) {
                resource = this.parent.resourceBase.resourceCollection[parseInt(i.toString(), 10)];
                levelIndex = i;
                levelName = resource.name;
                idField = resource.field;
                break;
            }
        }
        if (isNullOrUndefined(levelName) && isNullOrUndefined(levelIndex)) {
            levelName = this.parent.resourceCollection.slice(-1)[0].name;
            levelIndex = this.parent.resourceCollection.length - 1;
            idField = this.parent.resourceCollection.slice(-1)[0].field;
            resource = this.parent.resourceCollection.filter(function (e, index) {
                if (e.name === levelName) {
                    levelIndex = index;
                    return e;
                }
                return null;
            })[0];
        }
        var id = ((eventData["" + idField] instanceof Array) ?
            eventData["" + idField][0] : eventData["" + idField]);
        if (levelIndex > 0) {
            var parentField = this.parent.resourceCollection[levelIndex - 1].field;
            return this.parent.resourceBase.getIndexFromResourceId(id, levelName, resource, eventData, parentField);
        }
        else {
            return this.parent.resourceBase.getIndexFromResourceId(id, levelName, resource);
        }
    };
    EventBase.prototype.isAllDayAppointment = function (event) {
        var fieldMapping = this.parent.eventFields;
        var isAllDay = event[fieldMapping.isAllDay];
        var isFullDay = ((getUniversalTime(event[fieldMapping.endTime]) - getUniversalTime(event[fieldMapping.startTime]))
            / MS_PER_DAY) >= 1;
        return (isAllDay || (this.parent.eventSettings.spannedEventPlacement !== 'TimeSlot' && isFullDay)) ? true : false;
    };
    EventBase.prototype.addEventListener = function () {
        this.parent.on(documentClick, this.appointmentBorderRemove, this);
    };
    EventBase.prototype.removeEventListener = function () {
        this.parent.off(documentClick, this.appointmentBorderRemove);
    };
    EventBase.prototype.appointmentBorderRemove = function (event) {
        var element = event.event.target;
        if (closest(element, '.' + APPOINTMENT_CLASS)) {
            if (this.parent.currentView !== 'MonthAgenda') {
                this.parent.removeSelectedClass();
            }
        }
        else if (!closest(element, '.' + POPUP_OPEN)) {
            this.removeSelectedAppointmentClass();
            this.parent.selectedElements = [];
        }
    };
    EventBase.prototype.wireAppointmentEvents = function (element, event, isPreventCrud) {
        if (isPreventCrud === void 0) { isPreventCrud = false; }
        var isReadOnly = (!isNullOrUndefined(event)) ? event[this.parent.eventFields.isReadonly] : false;
        EventHandler.add(element, 'click', this.eventClick, this);
        if (!this.parent.isAdaptive && !this.parent.activeViewOptions.readonly && !isReadOnly) {
            EventHandler.add(element, 'touchstart', this.eventTouchClick, this);
            EventHandler.add(element, 'dblclick', this.eventDoubleClick, this);
        }
        if (!this.parent.activeViewOptions.readonly && !isReadOnly && !isPreventCrud) {
            if (this.parent.resizeModule) {
                this.parent.resizeModule.wireResizeEvent(element);
            }
            if (this.parent.dragAndDropModule) {
                this.parent.dragAndDropModule.wireDragEvent(element);
            }
        }
    };
    EventBase.prototype.eventTouchClick = function (e) {
        var _this = this;
        setTimeout(function () { return _this.isDoubleTapped = false; }, 250);
        e.preventDefault();
        if (this.isDoubleTapped) {
            this.eventDoubleClick(e);
        }
        else if (!this.isDoubleTapped) {
            this.isDoubleTapped = true;
            this.eventClick(e);
        }
    };
    EventBase.prototype.renderResizeHandler = function (element, spanEvent, isReadOnly) {
        if (!this.parent.resizeModule || !this.parent.allowResizing || this.parent.activeViewOptions.readonly || isReadOnly) {
            return;
        }
        for (var _i = 0, _a = Object.keys(spanEvent); _i < _a.length; _i++) {
            var resizeEdge = _a[_i];
            var resizeHandler = createElement('div', { className: EVENT_RESIZE_CLASS });
            switch (resizeEdge) {
                case 'isLeft':
                    if (!spanEvent.isLeft) {
                        resizeHandler.appendChild(createElement('div', { className: 'e-left-right-resize' }));
                        addClass([resizeHandler], this.parent.enableRtl ? RIGHT_RESIZE_HANDLER : LEFT_RESIZE_HANDLER);
                        prepend([resizeHandler], element);
                    }
                    break;
                case 'isRight':
                    if (!spanEvent.isRight) {
                        resizeHandler.appendChild(createElement('div', { className: 'e-left-right-resize' }));
                        addClass([resizeHandler], this.parent.enableRtl ? LEFT_RESIZE_HANDLER : RIGHT_RESIZE_HANDLER);
                        append([resizeHandler], element);
                    }
                    break;
                case 'isTop':
                    if (!spanEvent.isTop) {
                        resizeHandler.appendChild(createElement('div', { className: 'e-top-bottom-resize' }));
                        addClass([resizeHandler], TOP_RESIZE_HANDLER);
                        prepend([resizeHandler], element);
                    }
                    break;
                case 'isBottom':
                    if (!spanEvent.isBottom) {
                        resizeHandler.appendChild(createElement('div', { className: 'e-top-bottom-resize' }));
                        addClass([resizeHandler], BOTTOM_RESIZE_HANDLER);
                        append([resizeHandler], element);
                    }
                    break;
            }
        }
    };
    EventBase.prototype.eventClick = function (eventData) {
        var _this = this;
        var target = eventData.target;
        if (target.classList.contains(DRAG_CLONE_CLASS) || target.classList.contains(RESIZE_CLONE_CLASS) ||
            target.classList.contains(INLINE_SUBJECT_CLASS)) {
            return;
        }
        if ((eventData.ctrlKey || eventData.metaKey) && eventData.which === 1 && this.parent.keyboardInteractionModule) {
            this.parent.quickPopup.quickPopup.hide();
            this.parent.selectedElements = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_BORDER));
            var target_1 = closest(eventData.target, '.' + APPOINTMENT_CLASS);
            this.getSelectedEventElements(target_1);
            this.activeEventData(eventData, false);
            var selectArgs = {
                data: this.parent.activeEventData.event,
                element: this.parent.activeEventData.element,
                event: eventData, requestType: 'eventSelect'
            };
            this.parent.trigger(select, selectArgs);
            var args = extend(this.parent.activeEventData, { cancel: false, originalEvent: eventData });
            this.parent.trigger(eventClick, args);
        }
        else {
            this.removeSelectedAppointmentClass();
            this.activeEventData(eventData, true);
            var selectEventArgs = {
                data: this.parent.activeEventData.event,
                element: this.parent.activeEventData.element,
                event: eventData, requestType: 'eventSelect'
            };
            this.parent.trigger(select, selectEventArgs);
            var args = extend(this.parent.activeEventData, { cancel: false, originalEvent: eventData });
            this.parent.trigger(eventClick, args, function (eventClickArgs) {
                if (eventClickArgs.cancel) {
                    _this.removeSelectedAppointmentClass();
                    _this.parent.selectedElements = [];
                    if (_this.parent.quickPopup) {
                        _this.parent.quickPopup.quickPopupHide();
                    }
                }
                else {
                    if (_this.parent.currentView === 'Agenda' || _this.parent.currentView === 'MonthAgenda') {
                        addClass([_this.parent.activeEventData.element], AGENDA_SELECTED_CELL);
                    }
                    if (_this.parent.allowInline) {
                        var inlineArgs = {
                            data: eventClickArgs.event,
                            element: eventClickArgs.element,
                            type: 'Event'
                        };
                        _this.parent.notify(inlineClick, inlineArgs);
                    }
                    else {
                        _this.parent.notify(eventClick, eventClickArgs);
                    }
                }
            });
        }
    };
    EventBase.prototype.eventDoubleClick = function (eventData) {
        var _this = this;
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide(true);
        }
        if (eventData.type === 'touchstart') {
            this.activeEventData(eventData, true);
        }
        this.removeSelectedAppointmentClass();
        this.parent.selectedElements = [];
        if (this.parent.activeEventData.element.classList.contains(INLINE_APPOINTMENT_CLASS) ||
            this.parent.activeEventData.element.querySelector('.' + INLINE_SUBJECT_CLASS)) {
            return;
        }
        var args = extend(this.parent.activeEventData, { cancel: false, originalEvent: eventData });
        this.parent.trigger(eventDoubleClick, args, function (eventDoubleClickArgs) {
            if (!eventDoubleClickArgs.cancel) {
                if (!isNullOrUndefined(_this.parent.activeEventData.event) &&
                    isNullOrUndefined(_this.parent.activeEventData.event[_this.parent.eventFields.recurrenceID])) {
                    _this.parent.eventWindow.openEditor(_this.parent.activeEventData.event, 'Save');
                }
                else {
                    _this.parent.currentAction = 'EditOccurrence';
                    _this.parent.quickPopup.openRecurrenceAlert();
                }
            }
        });
    };
    EventBase.prototype.getEventByGuid = function (guid) {
        return this.parent.eventsProcessed.filter(function (data) { return data.Guid === guid; })[0];
    };
    EventBase.prototype.getEventById = function (id) {
        var _this = this;
        return this.parent.eventsData.filter(function (data) {
            return data[_this.parent.eventFields.id] === id;
        })[0];
    };
    EventBase.prototype.generateGuid = function () {
        return 'xyxxxxyx-xxxy-yxxx-xyxx-xxyxxxxyyxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = (c === 'x') ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    EventBase.prototype.getEventIDType = function () {
        if (this.parent.eventsData.length !== 0) {
            return typeof (this.parent.eventsData[0][this.parent.eventFields.id]);
        }
        if (this.parent.blockData.length !== 0) {
            return typeof (this.parent.blockData[0][this.parent.eventFields.id]);
        }
        return 'string';
    };
    EventBase.prototype.getEventMaxID = function (resourceId) {
        var _this = this;
        if (this.parent.eventsData.length < 1 && this.parent.blockData.length < 1) {
            return 1;
        }
        var eventId;
        var idType = this.getEventIDType();
        if (idType === 'string') {
            eventId = this.generateGuid();
        }
        if (idType === 'number') {
            var datas = this.parent.eventsData.concat(this.parent.blockData);
            var appIds = datas.map(function (event) { return event[_this.parent.eventFields.id]; });
            var maxId = appIds.reduce(function (a, b) { return Math.max(a, b); });
            maxId = isNullOrUndefined(resourceId) ? maxId : maxId + resourceId;
            eventId = maxId + 1;
        }
        return eventId;
    };
    EventBase.prototype.activeEventData = function (eventData, isMultiple) {
        var _this = this;
        var target = closest(eventData.target, '.' + APPOINTMENT_CLASS);
        var guid = target.getAttribute('data-guid');
        if (isMultiple) {
            this.addSelectedAppointments([].slice.call(this.parent.element.querySelectorAll('div[data-guid="' + guid + '"]')), true);
            target.focus();
        }
        var eventObject = this.getEventByGuid(guid);
        if (eventObject && eventObject.isSpanned) {
            eventObject = this.parent.eventsData.filter(function (obj) {
                return obj[_this.parent.eventFields.id] === eventObject[_this.parent.eventFields.id];
            })[0];
        }
        this.parent.activeEventData = { event: eventObject, element: target };
    };
    EventBase.prototype.generateOccurrence = function (event, viewDate, isMaxCount) {
        var startDate = event[this.parent.eventFields.startTime];
        var endDate = event[this.parent.eventFields.endTime];
        var eventRule = event[this.parent.eventFields.recurrenceRule];
        var timeZoneDiff = endDate.getTimezoneOffset() - startDate.getTimezoneOffset();
        var duration = (endDate.getTime() - startDate.getTime()) - (timeZoneDiff * 60000);
        viewDate = new Date((viewDate || this.parent.activeView.startDate()).getTime() - duration);
        var exception = event[this.parent.eventFields.recurrenceException];
        var maxCount;
        if (this.parent.currentView !== 'Agenda' && isMaxCount) {
            maxCount = getDateCount(viewDate, this.parent.activeView.endDate()) + 1;
        }
        var newTimezone = this.parent.timezone || this.parent.tzModule.getLocalTimezoneName();
        var firstDay = this.parent.activeViewOptions.firstDayOfWeek;
        var calendarMode = this.parent.calendarMode;
        var dates = generate(startDate, eventRule, exception, firstDay, maxCount, viewDate, calendarMode, newTimezone);
        if (this.parent.currentView === 'Agenda' && eventRule.indexOf('COUNT') === -1 && eventRule.indexOf('UNTIL') === -1) {
            if (isNullOrUndefined(event.generatedDates)) {
                event.generatedDates = { start: new Date(dates[0]), end: new Date(dates[dates.length - 1]) };
            }
            else {
                if (dates[0] < event.generatedDates.start.getTime()) {
                    event.generatedDates.start = new Date(dates[0]);
                }
                if (dates[dates.length - 1] > event.generatedDates.end.getTime()) {
                    event.generatedDates.end = new Date(dates[dates.length - 1]);
                }
            }
        }
        var occurrenceCollection = [];
        for (var _i = 0, dates_1 = dates; _i < dates_1.length; _i++) {
            var date = dates_1[_i];
            var clonedObject = extend({}, event, null, true);
            date = this.getDSTAdjustedTime(date, clonedObject);
            clonedObject[this.parent.eventFields.startTime] = new Date(date);
            clonedObject[this.parent.eventFields.endTime] = new Date(new Date(date).setMilliseconds(duration));
            clonedObject[this.parent.eventFields.recurrenceID] = clonedObject[this.parent.eventFields.id];
            delete clonedObject[this.parent.eventFields.recurrenceException];
            delete clonedObject[this.parent.eventFields.followingID];
            clonedObject.Guid = this.generateGuid();
            occurrenceCollection.push(clonedObject);
        }
        return occurrenceCollection;
    };
    EventBase.prototype.getDSTAdjustedTime = function (date, event) {
        var occurDate = date;
        if (this.parent.timezone &&
            (event[this.parent.eventFields.startTimezone] || event[this.parent.eventFields.endTimezone])) {
            var eventOffset = this.getDSTDiff(event[this.parent.eventFields.startTime], new Date(date), event[this.parent.eventFields.startTimezone]);
            var schOffset = this.getDSTDiff(event[this.parent.eventFields.startTime], new Date(date), this.parent.timezone);
            occurDate = (new Date(date).getTime() - (eventOffset - schOffset) * 60000);
        }
        return occurDate;
    };
    EventBase.prototype.getDSTDiff = function (startDate, occurDate, timezone) {
        var startOffset = this.parent.tzModule.offset(new Date(startDate), timezone);
        var occurOffset = this.parent.tzModule.offset(new Date(occurDate), timezone);
        return startOffset - occurOffset;
    };
    EventBase.prototype.getParentEvent = function (eventObj, isParent) {
        if (isParent === void 0) { isParent = false; }
        var parentEvent;
        do {
            eventObj = this.getFollowingEvent(eventObj);
            if (eventObj) {
                parentEvent = extend({}, eventObj, null, true);
            }
        } while (eventObj && isParent);
        if (isParent && parentEvent) {
            var collection = this.getEventCollections(parentEvent);
            var followObj = collection.follow.slice(-1)[0];
            if (collection.occurrence.length > 0 && !parentEvent[this.parent.eventFields.recurrenceException]) {
                followObj = collection.occurrence.slice(-1)[0];
            }
            if (followObj) {
                parentEvent[this.parent.eventFields.recurrenceRule] = followObj[this.parent.eventFields.recurrenceRule];
            }
        }
        return parentEvent;
    };
    EventBase.prototype.getEventCollections = function (parentObj, childObj) {
        var followingCollection = [];
        var occurrenceCollection = [];
        var followingEvent = parentObj;
        do {
            followingEvent = this.getFollowingEvent(followingEvent, true);
            if (followingEvent) {
                followingCollection.push(followingEvent);
            }
            occurrenceCollection = occurrenceCollection.concat(this.getOccurrenceEvent(followingEvent || parentObj));
        } while (followingEvent);
        var collections = {};
        if (childObj) {
            var fields_1 = this.parent.eventFields;
            collections = {
                follow: followingCollection.filter(function (eventData) {
                    return eventData[fields_1.startTime] >= childObj[fields_1.startTime];
                }),
                occurrence: occurrenceCollection.filter(function (eventData) {
                    return eventData[fields_1.startTime] >= childObj[fields_1.startTime];
                })
            };
        }
        else {
            collections = { follow: followingCollection, occurrence: occurrenceCollection };
        }
        return collections;
    };
    EventBase.prototype.getFollowingEvent = function (parentObj, isReverse) {
        var fields = this.parent.eventFields;
        var fieldValue;
        if (isReverse) {
            fieldValue = parentObj[fields.id];
        }
        else {
            fieldValue = (parentObj[fields.recurrenceID] || parentObj[fields.followingID]);
        }
        var parentApp = this.parent.eventsData.filter(function (data) {
            return data[isReverse ? fields.followingID : fields.id] === fieldValue;
        });
        return parentApp.shift();
    };
    EventBase.prototype.isFollowingEvent = function (parentObj, childObj) {
        var parentStart = parentObj[this.parent.eventFields.startTime];
        var childStart = childObj[this.parent.eventFields.startTime];
        return parentStart.getHours() === childStart.getHours() && parentStart.getMinutes() === childStart.getMinutes() &&
            parentStart.getSeconds() === childStart.getSeconds();
    };
    EventBase.prototype.getOccurrenceEvent = function (eventObj, isGuid, isFollowing) {
        if (isGuid === void 0) { isGuid = false; }
        if (isFollowing === void 0) { isFollowing = false; }
        var idField = isGuid ? 'Guid' : (isFollowing) ? this.parent.eventFields.followingID : this.parent.eventFields.recurrenceID;
        var fieldKey = isGuid ? 'Guid' : this.parent.eventFields.id;
        var dataSource = isGuid ? this.parent.eventsProcessed : this.parent.eventsData;
        return dataSource.filter(function (data) { return data["" + idField] === eventObj["" + fieldKey]; });
    };
    EventBase.prototype.getOccurrencesByID = function (id) {
        var fields = this.parent.eventFields;
        var occurrenceCollection = [];
        var parentObject = this.parent.eventsData.filter(function (obj) { return obj[fields.id] === id; });
        for (var _i = 0, parentObject_1 = parentObject; _i < parentObject_1.length; _i++) {
            var event_3 = parentObject_1[_i];
            if (!isNullOrUndefined(event_3[fields.recurrenceRule])) {
                occurrenceCollection = occurrenceCollection.concat(this.generateOccurrence(event_3));
            }
        }
        return occurrenceCollection;
    };
    EventBase.prototype.getOccurrencesByRange = function (startTime, endTime) {
        var fields = this.parent.eventFields;
        var occurrenceCollection = [];
        for (var _i = 0, _a = this.parent.eventsData; _i < _a.length; _i++) {
            var event_4 = _a[_i];
            if (!isNullOrUndefined(event_4[fields.recurrenceRule])) {
                occurrenceCollection = occurrenceCollection.concat(this.generateOccurrence(event_4));
            }
        }
        var filter = occurrenceCollection.filter(function (obj) {
            return obj[fields.startTime] >= startTime && obj[fields.endTime] <= endTime && !isNullOrUndefined(obj[fields.recurrenceID]);
        });
        return filter;
    };
    EventBase.prototype.getDeletedOccurrences = function (recurrenceData) {
        var fields = this.parent.eventFields;
        var parentObject;
        var deletedOccurrences = [];
        if (typeof recurrenceData === 'string' || typeof recurrenceData === 'number') {
            parentObject = this.parent.eventsData.filter(function (obj) {
                return obj[fields.id] === recurrenceData;
            })[0];
        }
        else {
            parentObject = extend({}, recurrenceData, null, true);
        }
        if (parentObject[fields.recurrenceException]) {
            var exDateString_1 = parentObject[fields.recurrenceException].split(',');
            var _loop_3 = function (i, len) {
                var edited = this_2.parent.eventsData.filter(function (eventObj) {
                    return eventObj[fields.recurrenceID] === parentObject[fields.id] && eventObj[fields.recurrenceException] === exDateString_1[parseInt(i.toString(), 10)];
                });
                if (edited.length === 0) {
                    var exDate = getDateFromRecurrenceDateString(exDateString_1[parseInt(i.toString(), 10)]);
                    var childObject = extend({}, recurrenceData, null, true);
                    childObject[fields.recurrenceID] = parentObject[fields.id];
                    delete childObject[fields.followingID];
                    childObject[fields.recurrenceException] = exDateString_1[parseInt(i.toString(), 10)];
                    var startDate = new Date(exDate.getTime());
                    var time = parentObject[fields.endTime].getTime() - parentObject[fields.startTime].getTime();
                    var endDate = new Date(startDate.getTime());
                    endDate.setMilliseconds(time);
                    childObject[fields.startTime] = new Date(startDate.getTime());
                    childObject[fields.endTime] = new Date(endDate.getTime());
                    deletedOccurrences.push(childObject);
                }
            };
            var this_2 = this;
            for (var i = 0, len = exDateString_1.length; i < len; i++) {
                _loop_3(i, len);
            }
        }
        return deletedOccurrences;
    };
    EventBase.prototype.applyResourceColor = function (element, data, type, index, alpha) {
        if (!this.parent.resourceBase) {
            return;
        }
        var alphaColor = function (color, alpha) {
            color = color.replace('#', '');
            var r = parseInt(color.substring(0, color.length / 3), 16);
            var g = parseInt(color.substring(color.length / 3, 2 * color.length / 3), 16);
            var b = parseInt(color.substring(2 * color.length / 3, 3 * color.length / 3), 16);
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        };
        // index refers groupOrder
        var color = this.parent.resourceBase.getResourceColor(data, index);
        if (color) {
            element.style[type] = !isNullOrUndefined(alpha) ? alphaColor(color, alpha) : color;
        }
    };
    EventBase.prototype.createBlockAppointmentElement = function (record, resIndex, isResourceEventTemplate) {
        var eventSubject = (record[this.parent.eventFields.subject] || this.parent.eventSettings.fields.subject.default);
        var appointmentWrapper = createElement('div', {
            className: BLOCK_APPOINTMENT_CLASS,
            attrs: {
                'data-id': 'Appointment_' + record[this.parent.eventFields.id],
                'aria-disabled': 'true'
            }
        });
        var templateElement;
        if (!isNullOrUndefined(this.parent.activeViewOptions.eventTemplate)) {
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.eventTemplateName;
            var templateId = scheduleId + viewName + 'eventTemplate';
            var templateName = 'eventTemplate' + (isResourceEventTemplate &&
                this.parent.currentView.indexOf('Year') === -1 ? '_' + resIndex : '');
            templateElement = this.parent.getAppointmentTemplate()(record, this.parent, templateName, templateId, false);
        }
        else {
            var appointmentSubject = createElement('div', { className: SUBJECT_CLASS });
            this.parent.sanitize(eventSubject, appointmentSubject);
            templateElement = [appointmentSubject];
        }
        append(templateElement, appointmentWrapper);
        this.setWrapperAttributes(appointmentWrapper, resIndex);
        return appointmentWrapper;
    };
    EventBase.prototype.setWrapperAttributes = function (appointmentWrapper, resIndex) {
        if (!isNullOrUndefined(this.cssClass)) {
            addClass([appointmentWrapper], this.cssClass);
        }
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            appointmentWrapper.setAttribute('data-group-index', resIndex.toString());
        }
    };
    EventBase.prototype.getReadonlyAttribute = function (event) {
        return (event[this.parent.eventFields.isReadonly] || this.parent.readonly).toString();
    };
    EventBase.prototype.isBlockRange = function (eventData) {
        var eventCollection = (eventData instanceof Array) ? eventData : [eventData];
        var isBlockAlert = false;
        var fields = this.parent.eventFields;
        for (var _i = 0, _a = eventCollection; _i < _a.length; _i++) {
            var event_5 = _a[_i];
            var dataCol = [];
            if (!isNullOrUndefined(event_5[fields.recurrenceRule]) &&
                (isNullOrUndefined(event_5[fields.recurrenceID]) || event_5[fields.id] === event_5[fields.recurrenceID])) {
                dataCol = this.generateOccurrence(event_5);
            }
            else {
                dataCol.push(event_5);
            }
            for (var _b = 0, dataCol_1 = dataCol; _b < dataCol_1.length; _b++) {
                var data = dataCol_1[_b];
                var filterBlockEvents = this.filterBlockEvents(data);
                if (filterBlockEvents.length > 0) {
                    isBlockAlert = true;
                    break;
                }
            }
        }
        this.parent.uiStateValues.isBlock = isBlockAlert;
        return isBlockAlert;
    };
    EventBase.prototype.getFilterEventsList = function (dataSource, query) {
        return new DataManager(dataSource).executeLocal(new Query().where(query));
    };
    EventBase.prototype.getSeriesEvents = function (parentEvent, startTime) {
        var fields = this.parent.eventFields;
        startTime = isNullOrUndefined(startTime) ? parentEvent[fields.startTime] : startTime;
        var deleteFutureEditEvents;
        var futureEvents;
        var deleteFutureEditEventList = [];
        var delId = parentEvent[fields.id];
        var followingId = parentEvent[fields.followingID];
        var deleteFutureEvent;
        var startTimeQuery = this.parent.currentAction === 'EditSeries' ? 'greaterthan' : 'greaterthanorequal';
        do {
            deleteFutureEvent = ((new Predicate(fields.followingID, 'equal', delId))).
                and(new Predicate(fields.startTime, startTimeQuery, startTime));
            futureEvents = this.getFilterEventsList(this.parent.eventsData, deleteFutureEvent);
            deleteFutureEditEvents = futureEvents.slice(-1)[0];
            if (!isNullOrUndefined(deleteFutureEditEvents) && deleteFutureEditEvents[fields.id] !== followingId) {
                deleteFutureEditEventList.push(deleteFutureEditEvents);
                delId = deleteFutureEditEvents[fields.id];
                followingId = deleteFutureEditEvents[fields.followingID];
            }
            else {
                followingId = null;
            }
        } while (futureEvents.length === 1 && !isNullOrUndefined(deleteFutureEditEvents[fields.followingID]));
        return deleteFutureEditEventList;
    };
    EventBase.prototype.getEditedOccurrences = function (deleteFutureEditEventList, startTime) {
        var fields = this.parent.eventFields;
        var deleteRecurrenceEventList = [];
        var delEditedEvents;
        for (var _i = 0, deleteFutureEditEventList_1 = deleteFutureEditEventList; _i < deleteFutureEditEventList_1.length; _i++) {
            var event_6 = deleteFutureEditEventList_1[_i];
            var delEventQuery = new Predicate(fields.recurrenceID, 'equal', event_6[fields.id]).
                or(new Predicate(fields.recurrenceID, 'equal', event_6[fields.followingID]).
                and(new Predicate(fields.recurrenceID, 'notequal', undefined)).
                and(new Predicate(fields.recurrenceID, 'notequal', null)));
            if (this.parent.currentAction === 'EditFollowingEvents' || this.parent.currentAction === 'DeleteFollowingEvents') {
                delEventQuery = delEventQuery.and(new Predicate(fields.startTime, 'greaterthanorequal', startTime));
            }
            delEditedEvents = this.getFilterEventsList(this.parent.eventsData, delEventQuery);
            deleteRecurrenceEventList = deleteRecurrenceEventList.concat(delEditedEvents);
        }
        return deleteRecurrenceEventList;
    };
    EventBase.prototype.getRenderedDates = function (dateRender) {
        var firstDate = 0;
        var lastDate = dateRender.length;
        var filteredDates;
        if (dateRender[0] < this.parent.minDate && dateRender[dateRender.length - 1] > this.parent.maxDate) {
            for (var i = 0; i < dateRender.length; i++) {
                if (resetTime(dateRender[parseInt(i.toString(), 10)]).getTime() === resetTime(new Date(this.parent.minDate)).getTime()) {
                    firstDate = i;
                }
                if (resetTime(dateRender[parseInt(i.toString(), 10)]).getTime() === resetTime(new Date(this.parent.maxDate)).getTime()) {
                    lastDate = i;
                }
            }
            filteredDates = dateRender.filter(function (date) { return date >= dateRender[parseInt(firstDate.toString(), 10)] && date <= dateRender[parseInt(lastDate.toString(), 10)]; });
        }
        return filteredDates;
    };
    EventBase.prototype.isValidEvent = function (eventObj, start, end, schedule) {
        var isHourRange = end.getTime() > schedule.startHour.getTime() && start.getTime() < schedule.endHour.getTime();
        var isSameRange = schedule.startHour.getTime() <= start.getTime() &&
            eventObj[this.parent.eventFields.startTime].getTime() >= schedule.startHour.getTime() &&
            eventObj[this.parent.eventFields.endTime].getTime() < schedule.endHour.getTime() && start.getTime() === end.getTime();
        return isHourRange || isSameRange;
    };
    EventBase.prototype.allDayExpandScroll = function (dateHeader) {
        var indentHeight = 0;
        var headerRows = [].slice.call(this.parent.element.querySelectorAll('.' + HEADER_ROW_CLASS));
        headerRows.forEach(function (element) {
            var tdEle = [].slice.call(element.children);
            tdEle.forEach(function (ele) { ele.style.top = indentHeight + 'px'; });
            indentHeight += element.offsetHeight;
        });
        indentHeight = dateHeader.offsetHeight - indentHeight;
        this.parent.element.querySelector('.' + ALLDAY_CELLS_CLASS).style.height = (indentHeight / 12) + 'em';
        if (this.parent.uiStateValues.expand) {
            addClass([dateHeader], ALLDAY_APPOINTMENT_SCROLL);
        }
        else {
            removeClass([dateHeader], ALLDAY_APPOINTMENT_SCROLL);
        }
    };
    EventBase.prototype.updateEventMinimumDuration = function (startEndHours, startTime, endTime) {
        if (startTime.getTime() < endTime.getTime()) {
            var eventDuration = (getUniversalTime(endTime) - getUniversalTime(startTime)) / MS_PER_MINUTE;
            if (eventDuration < this.parent.eventSettings.minimumEventDuration) {
                var tempEnd = new Date(startTime);
                tempEnd.setMinutes(tempEnd.getMinutes() + this.parent.eventSettings.minimumEventDuration);
                endTime = tempEnd;
                if (endTime.getTime() > startEndHours.endHour.getTime()) {
                    var tempStart = new Date(startEndHours.endHour.getTime());
                    tempStart.setMinutes(tempStart.getMinutes() - this.parent.eventSettings.minimumEventDuration);
                    startTime = tempStart;
                    endTime = startEndHours.endHour;
                }
            }
        }
        return { startDate: startTime, endDate: endTime };
    };
    EventBase.prototype.createEventWrapper = function (type, index) {
        if (type === void 0) { type = ''; }
        if (index === void 0) { index = 0; }
        var tr = createElement('tr');
        var levels = this.parent.activeView.colLevels.slice(-1)[0];
        for (var i = 0, len = levels.length; i < len; i++) {
            var col = levels[parseInt(i.toString(), 10)];
            var appointmentWrap = createElement('td', {
                className: (type === 'allDay') ? ALLDAY_APPOINTMENT_WRAPPER_CLASS : (type === 'timeIndicator') ?
                    TIMELINE_WRAPPER_CLASS : DAY_WRAPPER_CLASS, attrs: { 'data-date': col.date.getTime().toString() }
            });
            if (!isNullOrUndefined(col.groupIndex)) {
                appointmentWrap.setAttribute('data-group-index', col.groupIndex.toString());
            }
            if (type === '') {
                var innerWrapper = createElement('div', {
                    id: APPOINTMENT_WRAPPER_CLASS + '-' + index.toString(),
                    className: APPOINTMENT_WRAPPER_CLASS
                });
                appointmentWrap.appendChild(innerWrapper);
            }
            tr.appendChild(appointmentWrap);
            index = index + 1;
        }
        return tr;
    };
    EventBase.prototype.getPageCoordinates = function (e) {
        if (isNullOrUndefined(e)) {
            return e;
        }
        var eventArgs = e.event;
        return eventArgs && eventArgs.changedTouches ? eventArgs.changedTouches[0] : e.changedTouches ? e.changedTouches[0] :
            eventArgs || e;
    };
    EventBase.prototype.renderSpannedIcon = function (element, spanEvent) {
        var iconElement = createElement('div', { className: EVENT_INDICATOR_CLASS + ' ' + ICON });
        if (spanEvent.isLeft) {
            var iconLeft = iconElement.cloneNode();
            addClass([iconLeft], EVENT_ICON_LEFT_CLASS);
            prepend([iconLeft], element);
        }
        if (spanEvent.isRight) {
            var iconRight = iconElement.cloneNode();
            addClass([iconRight], EVENT_ICON_RIGHT_CLASS);
            append([iconRight], element);
        }
        if (spanEvent.isTop) {
            var iconTop = iconElement.cloneNode();
            addClass([iconTop], EVENT_ICON_UP_CLASS);
            prepend([iconTop], element);
        }
        if (spanEvent.isBottom) {
            var iconBottom = iconElement.cloneNode();
            addClass([iconBottom], EVENT_ICON_DOWN_CLASS);
            append([iconBottom], element);
        }
    };
    EventBase.prototype.unWireEvents = function () {
        var appElements = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
        for (var _i = 0, appElements_1 = appElements; _i < appElements_1.length; _i++) {
            var element = appElements_1[_i];
            EventHandler.clearEvents(element);
        }
    };
    EventBase.prototype.destroy = function () {
        this.unWireEvents();
        this.parent = null;
    };
    return EventBase;
}());

var __extends$1 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Vertical view appointment rendering
 */
var VerticalEvent = /** @__PURE__ @class */ (function (_super) {
    __extends$1(VerticalEvent, _super);
    function VerticalEvent(parent) {
        var _this = _super.call(this, parent) || this;
        _this.dateRender = [];
        _this.renderedEvents = [];
        _this.renderedAllDayEvents = [];
        _this.overlapEvents = [];
        _this.moreEvents = [];
        _this.overlapList = [];
        _this.allDayEvents = [];
        _this.slotCount = _this.parent.activeViewOptions.timeScale.slotCount;
        _this.interval = _this.parent.activeViewOptions.timeScale.interval;
        _this.allDayLevel = 0;
        _this.startHour = _this.parent.activeView.getStartHour();
        _this.endHour = _this.parent.activeView.getEndHour();
        _this.element = _this.parent.activeView.getPanel();
        _this.fields = _this.parent.eventFields;
        _this.animation = new Animation({ progress: _this.animationUiUpdate.bind(_this) });
        _this.addEventListener();
        return _this;
    }
    VerticalEvent.prototype.renderAppointments = function () {
        if (isNullOrUndefined(this.parent)) {
            return;
        }
        if (this.parent.dragAndDropModule) {
            this.parent.dragAndDropModule.setDragArea();
        }
        this.isResourceEventTemplate = this.parent.isSpecificResourceEvents();
        var wrapperElements = [].slice.call(this.parent.element.querySelectorAll('.' + BLOCK_APPOINTMENT_CLASS +
            ',.' + APPOINTMENT_CLASS + ',.' + ROW_COUNT_WRAPPER_CLASS));
        var isDragging = (this.parent.crudModule && this.parent.crudModule.crudObj.isCrudAction) ? true : false;
        for (var _i = 0, wrapperElements_1 = wrapperElements; _i < wrapperElements_1.length; _i++) {
            var wrapper = wrapperElements_1[_i];
            if (isDragging && !(wrapper.classList.contains(ALLDAY_APPOINTMENT_CLASS) ||
                wrapper.classList.contains(ROW_COUNT_WRAPPER_CLASS))) {
                var groupIndex = parseInt(wrapper.getAttribute('data-group-index'), 10);
                for (var j = 0, len = this.parent.crudModule.crudObj.sourceEvent.length; j < len; j++) {
                    if (groupIndex === this.parent.crudModule.crudObj.sourceEvent[parseInt(j.toString(), 10)].groupIndex ||
                        groupIndex === this.parent.crudModule.crudObj.targetEvent[parseInt(j.toString(), 10)].groupIndex) {
                        remove(wrapper);
                    }
                }
            }
            else {
                remove(wrapper);
            }
        }
        if (!this.element.querySelector('.' + WORK_CELLS_CLASS)) {
            return;
        }
        if (this.parent.virtualScrollModule) {
            this.parent.virtualScrollModule.updateFocusedWorkCell();
        }
        this.allDayElement = [].slice.call(this.element.querySelectorAll('.' + ALLDAY_CELLS_CLASS));
        this.setAllDayRowHeight(0);
        if (this.parent.eventsProcessed.length === 0 && this.parent.blockProcessed.length === 0) {
            return;
        }
        var expandCollapse = this.element.querySelector('.' + ALLDAY_APPOINTMENT_SECTION_CLASS);
        EventHandler.remove(expandCollapse, 'click', this.rowExpandCollapse);
        EventHandler.add(expandCollapse, 'click', this.rowExpandCollapse, this);
        this.renderedEvents = [];
        this.renderedAllDayEvents = [];
        this.initializeValues();
        this.processBlockEvents();
        this.renderEvents('normalEvents');
        if (this.allDayEvents.length > 0) {
            this.allDayEvents = this.allDayEvents.filter(function (item, index, arr) {
                return index === arr.map(function (item) { return item.Guid; }).indexOf(item.Guid);
            });
            removeClass(this.allDayElement, ALLDAY_ROW_ANIMATE_CLASS);
            this.slots.push(this.parent.activeView.renderDates.map(function (date) { return +date; }));
            this.renderEvents('allDayEvents');
            this.animation.animate(this.allDayElement[0]);
        }
        this.parent.notify(contentReady, {});
        addClass(this.allDayElement, ALLDAY_ROW_ANIMATE_CLASS);
        if (isDragging) {
            this.parent.crudModule.crudObj.isCrudAction = false;
        }
        this.parent.renderTemplates();
    };
    VerticalEvent.prototype.initializeValues = function () {
        this.resources = (this.parent.activeViewOptions.group.resources.length > 0) ? this.parent.uiStateValues.isGroupAdaptive ?
            [this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex]] :
            this.parent.resourceBase.lastResourceLevel : [];
        if (this.resources.length > 0 && this.parent.activeViewOptions.allowVirtualScrolling && this.parent.virtualScrollModule) {
            this.resources = this.parent.resourceBase.renderedResources;
        }
        this.cellHeight =
            parseFloat(this.parent.element.querySelector('.e-content-wrap tbody tr').getBoundingClientRect().height.toFixed(2));
        this.dateRender[0] = this.parent.activeView.renderDates;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            for (var i = 0, len = this.resources.length; i < len; i++) {
                this.dateRender[parseInt(i.toString(), 10)] = this.resources[parseInt(i.toString(), 10)].renderDates;
            }
        }
    };
    VerticalEvent.prototype.getHeight = function (start, end) {
        var appHeight = (getUniversalTime(end) - getUniversalTime(start)) /
            MS_PER_MINUTE * (this.cellHeight * this.slotCount) / this.interval;
        appHeight = (appHeight <= 0) ? this.cellHeight : appHeight;
        return appHeight;
    };
    VerticalEvent.prototype.appendEvent = function (eventObj, appointmentElement, index, appLeft) {
        var appointmentWrap = this.element.querySelector('.' + APPOINTMENT_WRAPPER_CLASS + '[id="' + APPOINTMENT_WRAPPER_CLASS + '-' + index + '"]');
        if (this.parent.enableRtl) {
            setStyleAttribute(appointmentElement, { 'right': appLeft });
        }
        else {
            setStyleAttribute(appointmentElement, { 'left': appLeft });
        }
        var eventType = appointmentElement.classList.contains(BLOCK_APPOINTMENT_CLASS) ? 'blockEvent' : 'event';
        var args = {
            data: extend({}, eventObj, null, true),
            element: appointmentElement, cancel: false, type: eventType
        };
        this.parent.trigger(eventRendered, args, function (eventArgs) {
            if (!eventArgs.cancel) {
                appointmentWrap.appendChild(appointmentElement);
            }
        });
    };
    VerticalEvent.prototype.processBlockEvents = function () {
        var resources = this.getResourceList();
        var dateCount = this.getStartCount();
        for (var _i = 0, resources_1 = resources; _i < resources_1.length; _i++) {
            var resource = resources_1[_i];
            var renderDates = this.dateRender[parseInt(resource.toString(), 10)];
            for (var day = 0, length_1 = renderDates.length; day < length_1; day++) {
                var startDate = new Date(renderDates[parseInt(day.toString(), 10)].getTime());
                var endDate = addDays(renderDates[parseInt(day.toString(), 10)], 1);
                var filterEvents = this.filterEvents(startDate, endDate, this.parent.blockProcessed, this.resources[parseInt(resource.toString(), 10)]);
                for (var _a = 0, filterEvents_1 = filterEvents; _a < filterEvents_1.length; _a++) {
                    var event_1 = filterEvents_1[_a];
                    if (this.parent.resourceBase) {
                        this.setValues(event_1, resource);
                    }
                    this.renderBlockEvents(event_1, day, resource, dateCount);
                    this.cssClass = null;
                    this.groupOrder = null;
                }
                dateCount += 1;
            }
        }
    };
    VerticalEvent.prototype.renderBlockEvents = function (eventObj, dayIndex, resource, dayCount) {
        var spannedData = this.isSpannedEvent(eventObj, dayIndex, resource);
        var eStart = spannedData[this.fields.startTime];
        var eEnd = spannedData[this.fields.endTime];
        var currentDate = resetTime(new Date(this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)].getTime()));
        var schedule = getStartEndHours(currentDate, this.startHour, this.endHour);
        if (eStart <= eEnd && this.isValidEvent(eventObj, eStart, eEnd, schedule) && this.isWorkDayAvailable(resource, eStart)) {
            var blockTop = void 0;
            var blockHeight = void 0;
            if (spannedData[this.fields.isAllDay]) {
                var contentWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS + ' table');
                blockHeight = formatUnit(contentWrap.offsetHeight);
                blockTop = formatUnit(0);
            }
            else {
                blockHeight = formatUnit(this.getHeight(eStart, eEnd));
                blockTop = formatUnit(this.getTopValue(eStart, dayIndex, resource));
            }
            var appointmentElement = this.createBlockAppointmentElement(eventObj, resource, this.isResourceEventTemplate);
            setStyleAttribute(appointmentElement, { 'width': '100%', 'height': blockHeight, 'top': blockTop });
            var index = this.getDayIndex(dayIndex, resource, dayCount);
            this.appendEvent(eventObj, appointmentElement, index, '0px');
        }
    };
    VerticalEvent.prototype.renderEvents = function (eventType) {
        removeClass(this.allDayElement, ALLDAY_ROW_ANIMATE_CLASS);
        var eventCollection = (eventType === 'allDayEvents') ? this.sortByDateTime(this.allDayEvents) : undefined;
        var resources = this.getResourceList();
        var dateCount = this.getStartCount();
        var isRender;
        var appHeight = eventType === 'allDayEvents' ? getElementHeightFromClass(this.element.querySelector('.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS), APPOINTMENT_CLASS) : 0;
        var allDayRowTop = eventType === 'allDayEvents' && this.allDayElement.length > 0 ? this.allDayElement[0].offsetTop : 0;
        var _loop_1 = function (resource) {
            isRender = true;
            if (this_1.parent.crudModule && this_1.parent.crudModule.crudObj.isCrudAction && eventType !== 'allDayEvents'
                && !this_1.parent.uiStateValues.isGroupAdaptive) {
                if (this_1.parent.crudModule.crudObj.sourceEvent.filter(function (data) { return data.groupIndex === resource; }).length === 0 &&
                    this_1.parent.crudModule.crudObj.targetEvent.filter(function (data) { return data.groupIndex === resource; }).length === 0) {
                    isRender = false;
                }
            }
            this_1.slots = [];
            var renderDates = this_1.dateRender[parseInt(resource.toString(), 10)];
            var renderedDate = this_1.getRenderedDates(renderDates) || renderDates;
            this_1.slots.push(renderDates.map(function (date) { return +date; }));
            for (var day = 0, length_2 = renderDates.length; day < length_2 &&
                renderDates[parseInt(day.toString(), 10)] <= renderedDate[renderedDate.length - 1]; day++) {
                this_1.renderedEvents = [];
                var startDate = new Date(renderDates[parseInt(day.toString(), 10)].getTime());
                var endDate = addDays(renderDates[parseInt(day.toString(), 10)], 1);
                var filterEvents = this_1.filterEvents(startDate, endDate, eventCollection, this_1.resources[parseInt(resource.toString(), 10)]);
                if (isRender) {
                    for (var _i = 0, filterEvents_2 = filterEvents; _i < filterEvents_2.length; _i++) {
                        var event_2 = filterEvents_2[_i];
                        if (this_1.parent.resourceBase) {
                            this_1.setValues(event_2, resource);
                        }
                        if (eventType === 'allDayEvents') {
                            this_1.renderAllDayEvents(event_2, day, resource, dateCount, false, allDayRowTop, appHeight);
                        }
                        else {
                            if (this_1.isAllDayAppointment(event_2)) {
                                this_1.allDayEvents.push(extend({}, event_2, null, true));
                            }
                            else {
                                if (this_1.parent.eventSettings.enableMaxHeight) {
                                    if (this_1.getOverlapIndex(event_2, day, false, resource) > 0) {
                                        continue;
                                    }
                                }
                                this_1.renderNormalEvents(event_2, day, resource, dateCount);
                            }
                        }
                        this_1.cssClass = null;
                        this_1.groupOrder = null;
                    }
                }
                else {
                    for (var _a = 0, filterEvents_3 = filterEvents; _a < filterEvents_3.length; _a++) {
                        var event_3 = filterEvents_3[_a];
                        if (this_1.isAllDayAppointment(event_3)) {
                            this_1.allDayEvents.push(extend({}, event_3, null, true));
                        }
                    }
                }
                dateCount += 1;
            }
        };
        var this_1 = this;
        for (var _i = 0, resources_2 = resources; _i < resources_2.length; _i++) {
            var resource = resources_2[_i];
            _loop_1(resource);
        }
    };
    VerticalEvent.prototype.getStartCount = function () {
        return this.parent.virtualScrollModule && this.parent.activeViewOptions.allowVirtualScrolling && this.parent.timeScale.enable ?
            parseInt(this.element.querySelector('.' + APPOINTMENT_WRAPPER_CLASS).getAttribute('id').split('-').slice(-1)[0], 10) : 0;
    };
    VerticalEvent.prototype.getDayIndex = function (dayIndex, resource, dayCount) {
        var _this = this;
        if (!this.parent.activeViewOptions.group.byDate) {
            return dayCount;
        }
        if (this.parent.activeViewOptions.group.byDate && !this.parent.activeViewOptions.group.hideNonWorkingDays) {
            var renderedIndex = this.parent.resourceBase.lastResourceLevel[0].renderDates.indexOf(this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)]);
            return (this.resources.length * renderedIndex) + resource;
        }
        var dateIndex = 0;
        var firstColumn = this.parent.activeView.colLevels[0];
        var currentDate = this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)].getTime();
        var currentResources = [];
        for (var i = 0; i < firstColumn.length; i++) {
            currentResources = this.parent.resourceBase.resourceDateTree[parseInt(i.toString(), 10)];
            if (currentDate === firstColumn[parseInt(i.toString(), 10)].date.getTime()) {
                break;
            }
            dateIndex = dateIndex + firstColumn[parseInt(i.toString(), 10)].colSpan;
        }
        var resIndex = currentResources.findIndex(function (x) { return x.groupOrder.toString() ===
            _this.resources[parseInt(resource.toString(), 10)].groupOrder.toString(); });
        if (resIndex < 0) {
            return dateIndex;
        }
        return dateIndex + resIndex;
    };
    VerticalEvent.prototype.setValues = function (event, resourceIndex) {
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.cssClass = this.resources[parseInt(resourceIndex.toString(), 10)].cssClass;
            this.groupOrder = this.resources[parseInt(resourceIndex.toString(), 10)].groupOrder;
        }
        else {
            this.cssClass = this.parent.resourceBase.getCssClass(event);
        }
    };
    VerticalEvent.prototype.getResourceList = function () {
        // eslint-disable-next-line prefer-spread
        var resources = Array.apply(null, {
            length: (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) ?
                this.resources.length : 1
        }).map(function (value, index) { return index; });
        return resources;
    };
    // eslint-disable-next-line max-len
    VerticalEvent.prototype.createAppointmentElement = function (record, isAllDay, data, resource) {
        var fieldMapping = this.parent.eventFields;
        var recordSubject = (record[fieldMapping.subject] || this.parent.eventSettings.fields.subject.default
            || this.parent.localeObj.getConstant('addTitle'));
        var appointmentWrapper = createElement('div', {
            className: APPOINTMENT_CLASS,
            attrs: {
                'data-id': 'Appointment_' + record[fieldMapping.id],
                'data-guid': record.Guid,
                'role': 'button',
                'tabindex': '0',
                'aria-disabled': this.parent.eventBase.getReadonlyAttribute(record),
                'aria-label': this.parent.getAnnouncementString(record)
            }
        });
        if (record[this.fields.isReadonly]) {
            addClass([appointmentWrapper], 'e-read-only');
        }
        var appointmentDetails = createElement('div', { className: APPOINTMENT_DETAILS });
        appointmentWrapper.appendChild(appointmentDetails);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var resourceIndex = this.parent.uiStateValues.isGroupAdaptive ? this.parent.uiStateValues.groupIndex : resource;
            appointmentWrapper.setAttribute('data-group-index', resourceIndex.toString());
        }
        var templateElement;
        var eventData = data;
        if (!isNullOrUndefined(this.parent.activeViewOptions.eventTemplate)) {
            var elementId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.eventTemplateName;
            var templateId = elementId + viewName + 'eventTemplate';
            var resIndex = this.parent.uiStateValues.isGroupAdaptive ? this.parent.uiStateValues.groupIndex : resource;
            var templateName = 'eventTemplate' + (this.isResourceEventTemplate ? '_' + resIndex : '');
            templateElement = this.parent.getAppointmentTemplate()(record, this.parent, templateName, templateId, false);
        }
        else {
            var appointmentSubject = createElement('div', { className: SUBJECT_CLASS });
            this.parent.sanitize(recordSubject, appointmentSubject);
            if (isAllDay) {
                if (record[fieldMapping.isAllDay]) {
                    templateElement = [appointmentSubject];
                }
                else {
                    templateElement = [];
                    var appointmentStartTime = createElement('div', {
                        className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                        innerHTML: this.parent.getTimeString(record[fieldMapping.startTime])
                    });
                    var appointmentEndTime = createElement('div', {
                        className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                        innerHTML: this.parent.getTimeString(record[fieldMapping.endTime])
                    });
                    addClass([appointmentSubject], 'e-text-center');
                    if (!eventData.isLeft) {
                        templateElement.push(appointmentStartTime);
                    }
                    templateElement.push(appointmentSubject);
                    if (!eventData.isRight) {
                        templateElement.push(appointmentEndTime);
                    }
                }
            }
            else {
                var timeStr = this.parent.getTimeString(record[fieldMapping.startTime]) + ' - ' +
                    this.parent.getTimeString(record[fieldMapping.endTime]);
                var appointmentTime = createElement('div', {
                    className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                    innerHTML: timeStr
                });
                var appointmentLocation = createElement('div', { className: LOCATION_CLASS });
                this.parent.sanitize((record[fieldMapping.location] || this.parent.eventSettings.fields.location.default || ''), appointmentLocation);
                templateElement = [appointmentSubject, appointmentTime, appointmentLocation];
            }
        }
        append(templateElement, appointmentDetails);
        if (!this.parent.isAdaptive &&
            (!isNullOrUndefined(record[fieldMapping.recurrenceRule]) || !isNullOrUndefined(record[fieldMapping.recurrenceID]))) {
            var iconClass = (record[fieldMapping.id] === record[fieldMapping.recurrenceID]) ?
                EVENT_RECURRENCE_ICON_CLASS : EVENT_RECURRENCE_EDIT_ICON_CLASS;
            var recurrenceIcon = createElement('div', { className: ICON + ' ' + iconClass });
            if (isAllDay) {
                appointmentDetails.appendChild(recurrenceIcon);
            }
            else {
                appointmentWrapper.appendChild(recurrenceIcon);
            }
        }
        this.parent.eventBase.renderSpannedIcon(isAllDay ? appointmentDetails : appointmentWrapper, eventData);
        if (!isNullOrUndefined(this.cssClass)) {
            addClass([appointmentWrapper], this.cssClass);
        }
        this.applyResourceColor(appointmentWrapper, record, 'backgroundColor', this.groupOrder);
        this.renderResizeHandler(appointmentWrapper, eventData, record[this.fields.isReadonly]);
        return appointmentWrapper;
    };
    VerticalEvent.prototype.createMoreIndicator = function (allDayRow, count, currentDay) {
        var index = currentDay + count;
        var countWrapper = allDayRow[parseInt(index.toString(), 10)];
        if (countWrapper.childElementCount <= 0) {
            var innerCountWrap = createElement('div', {
                className: ROW_COUNT_WRAPPER_CLASS,
                id: ROW_COUNT_WRAPPER_CLASS + '-' + index.toString()
            });
            var moreIndicatorElement = createElement('div', {
                className: MORE_INDICATOR_CLASS,
                attrs: { 'tabindex': '0', 'data-index': index.toString(), 'data-count': '1' },
                innerHTML: '+1&nbsp;' + (this.parent.isAdaptive ? '' : this.parent.localeObj.getConstant('more'))
            });
            innerCountWrap.appendChild(moreIndicatorElement);
            countWrapper.appendChild(innerCountWrap);
            EventHandler.add(moreIndicatorElement, 'click', this.rowExpandCollapse, this);
        }
        else {
            var countCell = countWrapper.querySelector('.' + MORE_INDICATOR_CLASS);
            var moreCount = parseInt(countCell.getAttribute('data-count'), 10) + 1;
            countCell.setAttribute('data-count', moreCount.toString());
            countCell.innerHTML = '+' + this.parent.globalize.formatNumber(moreCount) + '&nbsp;' + (this.parent.isAdaptive ? '' : this.parent.localeObj.getConstant('more'));
        }
    };
    VerticalEvent.prototype.isSpannedEvent = function (record, day, resource) {
        var currentDate = resetTime(this.dateRender[parseInt(resource.toString(), 10)][parseInt(day.toString(), 10)]);
        var renderedDate = this.getRenderedDates(this.dateRender[parseInt(resource.toString(), 10)]) || [currentDate];
        var currentDay = renderedDate.filter(function (date) { return date.getDay() === day; });
        if (currentDay.length === 0) {
            currentDate = resetTime(renderedDate[0]);
        }
        var field = this.parent.eventFields;
        var schedule = getStartEndHours(currentDate, this.startHour, this.endHour);
        var event = extend({}, record, null, true);
        event.isSpanned = { isBottom: false, isTop: false };
        if (record[field.startTime].getTime() < schedule.startHour.getTime()) {
            event[field.startTime] = schedule.startHour;
            event.isSpanned.isTop = true;
        }
        if (record[field.endTime].getTime() > schedule.endHour.getTime()) {
            event[field.endTime] = schedule.endHour;
            event.isSpanned.isBottom = true;
        }
        var eventDates = this.updateEventMinimumDuration(schedule, event[field.startTime], event[field.endTime]);
        event[field.startTime] = eventDates.startDate;
        event[field.endTime] = eventDates.endDate;
        return event;
    };
    VerticalEvent.prototype.isWorkDayAvailable = function (resource, start) {
        if (this.parent.activeViewOptions.group.hideNonWorkingDays && this.resources.length > 0) {
            var workDays = this.resources[parseInt(resource.toString(), 10)].
                resourceData[this.resources[parseInt(resource.toString(), 10)].resource.workDaysField] ||
                this.parent.activeViewOptions.workDays;
            return workDays && workDays.indexOf(start.getDay()) >= 0;
        }
        return true;
    };
    // eslint-disable-next-line max-len
    VerticalEvent.prototype.renderAllDayEvents = function (eventObj, dayIndex, resource, dayCount, inline, cellTop, eventHeight) {
        var _this = this;
        var currentDates = this.getRenderedDates(this.dateRender[parseInt(resource.toString(), 10)]) ||
            this.dateRender[parseInt(resource.toString(), 10)];
        if (this.parent.activeViewOptions.group.byDate) {
            this.slots[0] = [this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)].getTime()];
            currentDates = [this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)]];
        }
        var record = this.splitEvent(eventObj, currentDates)[0];
        var eStart = new Date(record[this.parent.eventFields.startTime].getTime());
        var eEnd = new Date(record[this.parent.eventFields.endTime].getTime());
        var appWidth = 0;
        var topValue = 1;
        var isDateRange = currentDates[0].getTime() <= eStart.getTime() &&
            addDays(currentDates.slice(-1)[0], 1).getTime() >= eStart.getTime();
        if (eStart <= eEnd && isDateRange && this.isWorkDayAvailable(resource, eStart)) {
            var isAlreadyRendered = [];
            if (this.renderedAllDayEvents[parseInt(resource.toString(), 10)]) {
                isAlreadyRendered = this.renderedAllDayEvents[parseInt(resource.toString(), 10)].filter(function (event) {
                    return event.Guid === eventObj.Guid;
                });
                if (this.parent.activeViewOptions.group.byDate) {
                    isAlreadyRendered = isAlreadyRendered.filter(function (event) {
                        return event[_this.parent.eventFields.startTime] >= currentDates[parseInt(dayIndex.toString(), 10)] &&
                            event[_this.parent.eventFields.endTime] <=
                                addDays(new Date(+currentDates[parseInt(dayIndex.toString(), 10)]), 1);
                    });
                }
            }
            if (isAlreadyRendered.length === 0) {
                var allDayDifference_1 = record.data.count;
                var allDayIndex_1 = this.getOverlapIndex(record, dayIndex, true, resource);
                record.Index = allDayIndex_1;
                this.allDayLevel = (this.allDayLevel < allDayIndex_1) ? allDayIndex_1 : this.allDayLevel;
                var widthAdjustment = record.data.isRight ? 0 :
                    this.parent.currentView === 'Day' ? 4 : 7;
                if (allDayDifference_1 >= 0) {
                    appWidth = (allDayDifference_1 * 100) - widthAdjustment;
                }
                if (isNullOrUndefined(this.renderedAllDayEvents[parseInt(resource.toString(), 10)])) {
                    this.renderedAllDayEvents[parseInt(resource.toString(), 10)] = [];
                }
                this.renderedAllDayEvents[parseInt(resource.toString(), 10)].push(extend({}, record, null, true));
                var allDayRow_1 = [].slice.call(this.element.querySelector('.' + ALLDAY_ROW_CLASS).children);
                var wIndex_1 = this.getDayIndex(dayIndex, resource, dayCount);
                var eventWrapper_1 = this.element.querySelector('.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS +
                    ':nth-child(' + (wIndex_1 + 1) + ')');
                var appointmentElement_1;
                if (inline) {
                    appointmentElement_1 = this.parent.inlineModule.createInlineAppointmentElement(eventObj);
                }
                else {
                    appointmentElement_1 = this.createAppointmentElement(eventObj, true, record.data, resource);
                }
                addClass([appointmentElement_1], ALLDAY_APPOINTMENT_CLASS);
                var eventData = extend({}, record.data, null, true);
                eventObj.data = eventData;
                var args = { data: eventObj, element: appointmentElement_1, cancel: false };
                this.parent.trigger(eventRendered, args, function (eventArgs) {
                    if (!eventArgs.cancel) {
                        eventWrapper_1.appendChild(appointmentElement_1);
                        topValue += (allDayIndex_1 === 0 ? cellTop : (cellTop + (allDayIndex_1 * eventHeight))) + 1;
                        setStyleAttribute(appointmentElement_1, { 'width': appWidth + '%', 'top': formatUnit(topValue) });
                        if (allDayIndex_1 > 1) {
                            _this.moreEvents.push(appointmentElement_1);
                            for (var count = 0, length_3 = allDayDifference_1; count < length_3; count++) {
                                _this.createMoreIndicator(allDayRow_1, count, wIndex_1);
                            }
                        }
                        _this.allDayElement[0].setAttribute('data-count', _this.allDayLevel.toString());
                        var allDayRowHeight = ((!_this.parent.uiStateValues.expand && _this.allDayLevel > 2) ?
                            (3 * eventHeight) : ((_this.allDayLevel + 1) * eventHeight)) + 4;
                        _this.setAllDayRowHeight(allDayRowHeight);
                        _this.addOrRemoveClass();
                        _this.wireAppointmentEvents(appointmentElement_1, eventObj);
                    }
                });
            }
        }
    };
    VerticalEvent.prototype.renderNormalEvents = function (eventObj, dayIndex, resource, dayCount, inline) {
        var record = this.isSpannedEvent(eventObj, dayIndex, resource);
        var eStart = record[this.fields.startTime];
        var eEnd = record[this.fields.endTime];
        var appWidth = '0%';
        var appLeft = '0%';
        var topValue = 0;
        var currentDate = resetTime(new Date(this.dateRender[parseInt(resource.toString(), 10)][parseInt(dayIndex.toString(), 10)].getTime()));
        var schedule = getStartEndHours(currentDate, this.startHour, this.endHour);
        var isValidEvent = this.isValidEvent(eventObj, eStart, eEnd, schedule);
        if ((eStart.getTime() < this.parent.minDate.getTime()) || (eEnd.getTime() > this.parent.maxDate.getTime())) {
            return;
        }
        if (eStart <= eEnd && isValidEvent && this.isWorkDayAvailable(resource, eStart)) {
            var appHeight = this.getHeight(eStart, eEnd);
            if (eStart.getTime() > schedule.startHour.getTime()) {
                topValue = this.getTopValue(eStart, dayIndex, resource);
            }
            var appIndex = this.getOverlapIndex(record, dayIndex, false, resource);
            record.Index = appIndex;
            this.overlapList.push(record);
            if (this.overlapList.length > 1) {
                if (isNullOrUndefined(this.overlapEvents[parseInt(appIndex.toString(), 10)])) {
                    this.overlapEvents[parseInt(appIndex.toString(), 10)] = [];
                }
                this.overlapEvents[parseInt(appIndex.toString(), 10)].push(record);
            }
            else {
                this.overlapEvents = [];
                this.overlapEvents.push([record]);
            }
            appWidth = this.getEventWidth();
            var argsData = {
                index: appIndex, left: appLeft, width: appWidth,
                day: dayCount, dayIndex: dayIndex, record: record, resource: resource
            };
            var tempData = this.adjustOverlapElements(argsData);
            appWidth = (tempData.appWidth);
            if (isNullOrUndefined(this.renderedEvents[parseInt(resource.toString(), 10)])) {
                this.renderedEvents[parseInt(resource.toString(), 10)] = [];
            }
            this.renderedEvents[parseInt(resource.toString(), 10)].push(extend({}, record, null, true));
            var appointmentElement = void 0;
            if (inline) {
                appointmentElement = this.parent.inlineModule.createInlineAppointmentElement(eventObj);
            }
            else {
                appointmentElement = this.createAppointmentElement(eventObj, false, record.isSpanned, resource);
            }
            setStyleAttribute(appointmentElement, {
                'width': (this.parent.eventSettings.enableMaxHeight ? '100%' : tempData.appWidth),
                'height': appHeight + 'px', 'top': topValue + 'px'
            });
            var iconHeight = appointmentElement.querySelectorAll('.' + EVENT_INDICATOR_CLASS).length * 15;
            var maxHeight = appHeight - 40 - iconHeight;
            var subjectElement = appointmentElement.querySelector('.' + SUBJECT_CLASS);
            if (!this.parent.isAdaptive && subjectElement) {
                subjectElement.style.maxHeight = formatUnit(maxHeight);
            }
            var index = this.getDayIndex(dayIndex, resource, dayCount);
            var eventData = {};
            eventData[this.fields.startTime] = eventObj[this.fields.startTime];
            eventData[this.fields.endTime] = eventObj[this.fields.endTime];
            record.data = eventData;
            this.appendEvent(record, appointmentElement, index, tempData.appLeft);
            this.wireAppointmentEvents(appointmentElement, eventObj);
        }
    };
    VerticalEvent.prototype.getEventWidth = function () {
        var width = this.parent.currentView === 'Day' ? 97 : 94;
        var tempWidth = ((width - this.overlapEvents.length) / this.overlapEvents.length);
        return (tempWidth < 0 ? 0 : tempWidth) + '%';
    };
    VerticalEvent.prototype.getEventLeft = function (appWidth, index) {
        var tempLeft = (parseFloat(appWidth) + 1) * index;
        return (tempLeft > 99 ? 99 : tempLeft) + '%';
    };
    VerticalEvent.prototype.getTopValue = function (date, day, resource) {
        var startEndHours = getStartEndHours(resetTime(this.dateRender[parseInt(resource.toString(), 10)][parseInt(day.toString(), 10)]), this.startHour, this.endHour);
        var startHour = startEndHours.startHour;
        var diffInMinutes = ((date.getHours() - startHour.getHours()) * 60) + (date.getMinutes() - startHour.getMinutes());
        return (this.parent.activeViewOptions.timeScale.enable) ? ((diffInMinutes * this.cellHeight * this.slotCount) / this.interval) : 0;
    };
    VerticalEvent.prototype.getOverlapIndex = function (record, day, isAllDay, resource) {
        var _this = this;
        var fieldMapping = this.parent.eventFields;
        var eventsList = [];
        var appIndex = -1;
        this.overlapEvents = [];
        if (isAllDay) {
            if (!isNullOrUndefined(this.renderedAllDayEvents[parseInt(resource.toString(), 10)])) {
                var date_1 = resetTime(new Date(this.dateRender[parseInt(resource.toString(), 10)][parseInt(day.toString(), 10)].getTime()));
                eventsList = this.renderedAllDayEvents[parseInt(resource.toString(), 10)].filter(function (app) {
                    return resetTime(app[fieldMapping.startTime]).getTime() <= date_1.getTime() &&
                        resetTime(app[fieldMapping.endTime]).getTime() >= date_1.getTime();
                });
                if (this.parent.activeViewOptions.group.resources.length > 0) {
                    eventsList = this.filterEventsByResource(this.resources[parseInt(resource.toString(), 10)], eventsList);
                }
            }
        }
        else {
            var appointmentList_1 = !isNullOrUndefined(this.renderedEvents[parseInt(resource.toString(), 10)]) ?
                this.renderedEvents[parseInt(resource.toString(), 10)] : [];
            var appointment_1 = [];
            var recordStart_1 = record[fieldMapping.startTime];
            var recordEnd_1 = record[fieldMapping.endTime];
            this.overlapList = appointmentList_1.filter(function (data) {
                return (data[fieldMapping.endTime] > recordStart_1 && data[fieldMapping.startTime] <= recordEnd_1) ||
                    (data[fieldMapping.startTime] >= recordEnd_1 && data[fieldMapping.endTime] <= recordStart_1) ||
                    (data[fieldMapping.endTime].getTime() === data[fieldMapping.startTime].getTime() &&
                        data[fieldMapping.startTime].getTime() === recordStart_1.getTime() && data[fieldMapping.endTime] < recordEnd_1);
            });
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                this.overlapList = this.filterEventsByResource(this.resources[parseInt(resource.toString(), 10)], this.overlapList);
            }
            this.overlapList.forEach(function (obj) {
                var filterList = appointmentList_1.filter(function (data) {
                    return data[fieldMapping.endTime] > obj[fieldMapping.startTime] && data[fieldMapping.startTime] <= obj[fieldMapping.endTime];
                });
                if (_this.parent.activeViewOptions.group.resources.length > 0) {
                    filterList = _this.filterEventsByResource(_this.resources[parseInt(resource.toString(), 10)], filterList);
                }
                var collection = filterList.filter(function (val) {
                    return _this.overlapList.indexOf(val) === -1;
                });
                if (collection.length > 0) {
                    appointment_1 = appointment_1.concat(collection);
                }
            });
            for (var i = 0; i < appointment_1.length - 1; i++) {
                for (var j = i + 1; j < appointment_1.length; j++) {
                    if (appointment_1[parseInt(i.toString(), 10)][fieldMapping.id] ===
                        appointment_1[parseInt(j.toString(), 10)][fieldMapping.id]) {
                        appointment_1.splice(j, 1);
                        j--;
                    }
                }
            }
            this.overlapList = this.overlapList.concat(appointment_1);
            eventsList = this.overlapList;
            for (var _i = 0, eventsList_1 = eventsList; _i < eventsList_1.length; _i++) {
                var event_4 = eventsList_1[_i];
                var record_1 = event_4;
                var index = record_1.Index;
                if (isNullOrUndefined(this.overlapEvents[parseInt(index.toString(), 10)])) {
                    this.overlapEvents[parseInt(index.toString(), 10)] = [event_4];
                }
                else {
                    this.overlapEvents[parseInt(index.toString(), 10)].push(event_4);
                }
            }
        }
        if (!isAllDay) {
            eventsList = eventsList.filter(function (obj) { return (obj[fieldMapping.startTime] === record[fieldMapping.startTime] &&
                obj[fieldMapping.endTime] > record[fieldMapping.endTime] || obj[fieldMapping.endTime] > record[fieldMapping.startTime] &&
                obj[fieldMapping.startTime] < record[fieldMapping.endTime] || obj[fieldMapping.endTime] === record[fieldMapping.startTime]
                && obj[fieldMapping.startTime] === record[fieldMapping.endTime]) ||
                ((obj[fieldMapping.startTime].getTime() === record[fieldMapping.startTime].getTime() &&
                    obj[fieldMapping.endTime].getTime() === record[fieldMapping.endTime].getTime())
                    || (obj[fieldMapping.startTime].getTime() === record[fieldMapping.startTime].getTime() &&
                        obj[fieldMapping.endTime].getTime() < record[fieldMapping.endTime].getTime() ||
                        obj[fieldMapping.endTime].getTime() > record[fieldMapping.endTime].getTime())); });
        }
        if (eventsList.length > 0) {
            var appLevel = eventsList.map(function (obj) { return obj.Index; });
            appIndex = (appLevel.length > 0) ? this.getSmallestMissingNumber(appLevel) : 0;
        }
        return (appIndex === -1) ? 0 : appIndex;
    };
    VerticalEvent.prototype.adjustOverlapElements = function (args) {
        var data = { appWidth: args.width, appLeft: args.left };
        for (var i = 0, length1 = this.overlapEvents.length; i < length1; i++) {
            if (!isNullOrUndefined(this.overlapEvents[parseInt(i.toString(), 10)])) {
                for (var j = 0, length2 = this.overlapEvents[parseInt(i.toString(), 10)].length; j < length2; j++) {
                    var dayCount = this.getDayIndex(args.dayIndex, args.resource, args.day);
                    var element = this.element.querySelector('#e-appointment-wrapper-' + dayCount);
                    if (element && element.childElementCount > 0) {
                        var eleGuid = this.overlapEvents[parseInt(i.toString(), 10)][parseInt(j.toString(), 10)].Guid;
                        if (element.querySelectorAll('div[data-guid="' + eleGuid + '"]').length > 0 && eleGuid !== args.record.Guid) {
                            var apps = element.querySelector('div[data-guid="' + eleGuid + '"]');
                            if (parseFloat(args.width) <= parseFloat(apps.style.width)) {
                                if (this.parent.enableRtl) {
                                    apps.style.right = this.getEventLeft(args.width, i);
                                }
                                else {
                                    apps.style.left = this.getEventLeft(args.width, i);
                                }
                                apps.style.width = ((parseFloat(args.width))) + '%';
                                data.appWidth = apps.style.width;
                            }
                            else {
                                data.appWidth = apps.style.width;
                            }
                        }
                        else {
                            var appWidth = args.width;
                            if (isNullOrUndefined(this.overlapEvents[i - 1])) {
                                appWidth = this.getEventWidth();
                            }
                            data.appWidth = appWidth;
                            data.appLeft = this.getEventLeft(appWidth, args.index);
                        }
                    }
                }
            }
        }
        return data;
    };
    VerticalEvent.prototype.setAllDayRowHeight = function (height) {
        var dateHeader = this.parent.element.querySelector('.' + DATE_HEADER_WRAP_CLASS);
        if (this.parent.height === 'auto' || !this.parent.enableAllDayScroll) {
            addClass([dateHeader], ALLDAY_APPOINTMENT_AUTO);
        }
        var allDayRow = this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS);
        allDayRow.style.height = '';
        if (this.parent.uiStateValues.expand && this.parent.height !== 'auto' && this.parent.enableAllDayScroll) {
            allDayRow.style.height = (height / 12) + 'em';
            this.parent.eventBase.allDayExpandScroll(dateHeader);
        }
        else {
            for (var _i = 0, _a = this.allDayElement; _i < _a.length; _i++) {
                var element = _a[_i];
                element.style.height = (height / 12) + 'em';
            }
            removeClass([dateHeader], ALLDAY_APPOINTMENT_SCROLL);
        }
    };
    VerticalEvent.prototype.addOrRemoveClass = function () {
        var _this = this;
        this.moreEvents.filter(function (element) {
            if (!_this.parent.uiStateValues.expand && _this.allDayLevel > 2) {
                addClass([element], EVENT_COUNT_CLASS);
                element.setAttribute('tabindex', '-1');
            }
            else {
                removeClass([element], EVENT_COUNT_CLASS);
                element.setAttribute('tabindex', '0');
            }
        });
        var moreEventCount = this.element.querySelector('.' + ALLDAY_APPOINTMENT_SECTION_CLASS);
        if (this.parent.uiStateValues.expand) {
            removeClass([moreEventCount], APPOINTMENT_ROW_EXPAND_CLASS);
            addClass([moreEventCount], APPOINTMENT_ROW_COLLAPSE_CLASS);
        }
        else {
            removeClass([moreEventCount], APPOINTMENT_ROW_COLLAPSE_CLASS);
            addClass([moreEventCount], APPOINTMENT_ROW_EXPAND_CLASS);
        }
        if (this.allDayLevel > 2) {
            removeClass([moreEventCount], DISABLE_CLASS);
        }
        else {
            addClass([moreEventCount], DISABLE_CLASS);
        }
        var countCell = [].slice.call(this.element.querySelectorAll('.' + ROW_COUNT_WRAPPER_CLASS));
        countCell.forEach(function (element) {
            if (!_this.parent.uiStateValues.expand && _this.allDayLevel > 2) {
                removeClass([element], DISABLE_CLASS);
            }
            else {
                addClass([element], DISABLE_CLASS);
            }
        });
    };
    VerticalEvent.prototype.getEventHeight = function () {
        var eventElement = createElement('div', { className: APPOINTMENT_CLASS, styles: 'visibility:hidden' });
        var eventWrapper = this.element.querySelector('.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS + ':first-child');
        eventWrapper.appendChild(eventElement);
        var height = eventElement.offsetHeight;
        remove(eventElement);
        return height;
    };
    VerticalEvent.prototype.rowExpandCollapse = function () {
        var target = this.element.querySelector('.' + ALLDAY_APPOINTMENT_SECTION_CLASS);
        this.parent.uiStateValues.expand = target.classList.contains(APPOINTMENT_ROW_EXPAND_CLASS);
        var rowHeight;
        if (this.parent.uiStateValues.expand) {
            target.setAttribute('title', this.parent.localeObj.getConstant('collapseAllDaySection'));
            target.setAttribute('aria-label', 'Collapse section');
            rowHeight = ((this.allDayLevel + 1) * this.getEventHeight()) + 4;
        }
        else {
            target.setAttribute('title', this.parent.localeObj.getConstant('expandAllDaySection'));
            target.setAttribute('aria-label', 'Expand section');
            rowHeight = (3 * this.getEventHeight()) + 4;
            this.parent.element.querySelector('.' + DATE_HEADER_WRAP_CLASS).scrollTop = 0;
        }
        this.setAllDayRowHeight(rowHeight);
        this.animation.animate(this.allDayElement[0]);
        this.addOrRemoveClass();
        this.animation.animate(target);
    };
    VerticalEvent.prototype.animationUiUpdate = function () {
        this.parent.notify(contentReady, {});
    };
    VerticalEvent.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.removeEventListener();
        this.allDayElement = null;
        this.renderedAllDayEvents = null;
        this.renderedEvents = null;
        this.slotCount = null;
        this.interval = null;
        this.startHour = null;
        this.endHour = null;
        this.element = null;
        this.fields = null;
        this.animation = null;
        _super.prototype.destroy.call(this);
    };
    return VerticalEvent;
}(EventBase));

var __extends$2 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
var EVENT_GAP = 0;
/**
 * Month view events render
 */
var MonthEvent = /** @__PURE__ @class */ (function (_super) {
    __extends$2(MonthEvent, _super);
    function MonthEvent(parent) {
        var _this = _super.call(this, parent) || this;
        _this.renderedEvents = [];
        _this.monthHeaderHeight = 0;
        _this.moreIndicatorHeight = 19;
        _this.renderType = 'day';
        _this.element = _this.parent.activeView.getPanel();
        _this.fields = _this.parent.eventFields;
        _this.maxHeight = _this.parent.eventSettings.enableMaxHeight && !_this.parent.eventSettings.enableIndicator
            && !_this.parent.rowAutoHeight;
        _this.withIndicator = _this.parent.eventSettings.enableMaxHeight && _this.parent.eventSettings.enableIndicator
            && !_this.parent.rowAutoHeight;
        _this.maxOrIndicator = (_this.maxHeight || _this.withIndicator);
        _this.moreIndicatorHeight =
            (_this.parent.rowAutoHeight && _this.parent.eventSettings.ignoreWhitespace) ? 0 : _this.moreIndicatorHeight;
        _this.addEventListener();
        return _this;
    }
    MonthEvent.prototype.removeEventWrapper = function (appElement) {
        if (appElement.length > 0) {
            appElement = (this.parent.currentView === 'Month' || (!this.parent.activeView.isTimelineView() &&
                !this.parent.activeViewOptions.timeScale.enable)) ? appElement : [appElement[0]];
            for (var _i = 0, appElement_1 = appElement; _i < appElement_1.length; _i++) {
                var wrap = appElement_1[_i];
                if (wrap.parentElement && wrap.parentElement.parentNode) {
                    remove(wrap.parentElement);
                }
            }
        }
    };
    MonthEvent.prototype.renderAppointments = function () {
        if (this.parent.dragAndDropModule) {
            this.parent.dragAndDropModule.setDragArea();
        }
        this.isResourceEventTemplate = this.parent.isSpecificResourceEvents();
        var conWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (this.parent.rowAutoHeight) {
            this.parent.uiStateValues.top = conWrap.scrollTop;
            this.parent.uiStateValues.left = conWrap.scrollLeft;
        }
        var appointmentWrapper = [].slice.call(this.element.querySelectorAll('.' + APPOINTMENT_WRAPPER_CLASS));
        if (this.parent.crudModule && this.parent.crudModule.crudObj.isCrudAction) {
            for (var i = 0, len = this.parent.crudModule.crudObj.sourceEvent.length; i < len; i++) {
                var appElement = [].slice.call(this.element.querySelectorAll('.e-appointment-wrapper ' + '[data-group-index="' +
                    this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex + '"]'));
                this.removeEventWrapper(appElement);
                if (this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)] &&
                    this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex !==
                        this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex) {
                    var ele = [].slice.call(this.element.querySelectorAll('.e-appointment-wrapper ' + '[data-group-index="' +
                        this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex + '"]'));
                    this.removeEventWrapper(ele);
                }
            }
        }
        else {
            for (var _i = 0, appointmentWrapper_1 = appointmentWrapper; _i < appointmentWrapper_1.length; _i++) {
                var wrap = appointmentWrapper_1[_i];
                remove(wrap);
            }
        }
        this.removeHeightProperty(CONTENT_TABLE_CLASS);
        if (!this.element.querySelector('.' + WORK_CELLS_CLASS)) {
            return;
        }
        if (this.parent.currentView === 'Month') {
            var wrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            var cellTd = this.parent.element.querySelector('.' + WORK_CELLS_CLASS);
            cellTd.appendChild(wrapper);
            this.monthHeaderHeight = wrapper.offsetTop - cellTd.offsetTop;
            cellTd.removeChild(wrapper);
        }
        this.eventHeight = getElementHeightFromClass(this.element, APPOINTMENT_CLASS);
        var scrollTop = conWrap.scrollTop;
        if (this.parent.rowAutoHeight && this.parent.virtualScrollModule && !this.parent.virtualScrollModule.isHorizontalScroll
            && !isNullOrUndefined(this.parent.currentAction)) {
            conWrap.scrollTop = conWrap.scrollTop - 1;
        }
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.renderResourceEvents();
            if (this.parent.virtualScrollModule) {
                this.parent.virtualScrollModule.updateFocusedWorkCell();
            }
        }
        else {
            this.renderEventsHandler(this.parent.activeView.renderDates, this.parent.activeViewOptions.workDays);
        }
        if (this.parent.rowAutoHeight) {
            this.updateBlockElements();
            var data = {
                cssProperties: this.parent.getCssProperties(),
                module: this.parent.getModuleName(),
                isPreventScrollUpdate: true,
                scrollPosition: { left: this.parent.uiStateValues.left, top: this.parent.uiStateValues.top }
            };
            if (this.parent.virtualScrollModule && !this.parent.virtualScrollModule.isHorizontalScroll) {
                if (this.parent.currentAction) {
                    conWrap.scrollTop = scrollTop;
                    this.parent.currentAction = null;
                }
                else {
                    this.parent.virtualScrollModule.updateVirtualScrollHeight();
                }
            }
            if (!this.parent.enablePersistence) {
                this.parent.notify(contentReady, {});
            }
            this.parent.notify(scrollUiUpdate, data);
            if (this.parent.currentView === 'Month' && this.parent.showWeekNumber) {
                var totalCells_1 = [].slice.call(this.parent.element.querySelectorAll('.e-content-wrap table tr td:first-child'));
                var weekNumberCells = [].slice.call(this.parent.element.querySelectorAll('.' + WEEK_NUMBER_CLASS));
                weekNumberCells.forEach(function (cell, i) {
                    var height = totalCells_1[parseInt(i.toString(), 10)].offsetHeight;
                    setStyleAttribute(cell, { 'height': height + 'px' });
                });
                this.parent.element.querySelector('.' + WEEK_NUMBER_WRAPPER_CLASS).scrollTop =
                    this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS).scrollTop;
            }
        }
        this.parent.renderTemplates();
    };
    MonthEvent.prototype.renderEventsHandler = function (dateRender, workDays, resData) {
        this.renderedEvents = [];
        var eventsList;
        var blockList;
        var resIndex = 0;
        if (resData) {
            resIndex = resData.groupIndex;
            this.cssClass = resData.cssClass;
            this.groupOrder = resData.groupOrder;
            eventsList = this.parent.eventBase.filterEventsByResource(resData, this.parent.eventsProcessed);
            blockList = this.parent.eventBase.filterEventsByResource(resData, this.parent.blockProcessed);
            this.workCells = [].slice.call(this.element.querySelectorAll('.' + WORK_CELLS_CLASS + '[data-group-index="' + resIndex + '"]'));
        }
        else {
            eventsList = this.parent.eventsProcessed;
            blockList = this.parent.blockProcessed;
            this.workCells = [].slice.call(this.element.querySelectorAll('.' + WORK_CELLS_CLASS));
        }
        this.sortByDateTime(eventsList);
        this.sortByDateTime(blockList);
        if (this.parent.currentView === 'Month' && this.parent.rowAutoHeight && this.parent.activeViewOptions.group.resources.length === 0) {
            var totalCells = [].slice.call(this.parent.element.querySelectorAll('.e-content-wrap table tr td:first-child'));
            var height_1 = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS).clientHeight / totalCells.length;
            totalCells.forEach(function (cell) {
                setStyleAttribute(cell, { 'height': height_1 + 'px' });
            });
        }
        var cellDetail = this.workCells[this.parent.activeView.isTimelineView() ?
            0 : this.workCells.length - 1].getBoundingClientRect();
        this.cellWidth = cellDetail.width;
        this.cellHeight = cellDetail.height;
        this.dateRender = dateRender;
        var filteredDates = this.getRenderedDates(dateRender);
        this.getSlotDates(workDays || this.parent.activeViewOptions.workDays);
        this.processBlockEvents(blockList, resIndex, resData);
        var events = [];
        for (var _i = 0, eventsList_1 = eventsList; _i < eventsList_1.length; _i++) {
            var event_1 = eventsList_1[_i];
            if (this.parent.resourceBase && !resData) {
                this.cssClass = this.parent.resourceBase.getCssClass(event_1);
            }
            events = events.concat(this.splitEvent(event_1, filteredDates || this.dateRender));
        }
        for (var level = 0; level < this.slots.length; level++) {
            this.renderedEvents = [];
            var slot = this.slots[parseInt(level.toString(), 10)];
            var endDate = addDays(new Date(slot[slot.length - 1]), 1);
            var spannedEvents = this.filterEvents(new Date(slot[0]), endDate, events);
            for (var _a = 0, spannedEvents_1 = spannedEvents; _a < spannedEvents_1.length; _a++) {
                var event_2 = spannedEvents_1[_a];
                if (this.maxHeight) {
                    var sDate = this.parent.currentView === 'Month' ? event_2[this.fields.startTime] :
                        this.getStartTime(event_2, event_2.data);
                    if (this.getIndex(sDate) > 0) {
                        continue;
                    }
                }
                this.updateIndicatorIcon(event_2);
                this.renderEvents(event_2, resIndex, eventsList);
            }
        }
        this.cssClass = null;
        this.groupOrder = null;
    };
    MonthEvent.prototype.processBlockEvents = function (blockEvents, resIndex, resData) {
        for (var _i = 0, blockEvents_1 = blockEvents; _i < blockEvents_1.length; _i++) {
            var event_3 = blockEvents_1[_i];
            if (this.parent.resourceBase && !resData) {
                this.cssClass = this.parent.resourceBase.getCssClass(event_3);
            }
            var blockSpannedList = [];
            if (this.renderType === 'day' && !event_3[this.fields.isAllDay]) {
                var temp = extend({}, event_3, null, true);
                var isSameDate = this.isSameDate(temp[this.fields.startTime], temp[this.fields.endTime]);
                temp.isBlockIcon = isSameDate;
                if (!isSameDate && getDateInMs(temp[this.fields.startTime]) > 0) {
                    var e = extend({}, event_3, null, true);
                    e[this.fields.endTime] = addDays(resetTime(new Date(event_3[this.fields.startTime] + '')), 1);
                    e.isBlockIcon = true;
                    temp[this.fields.startTime] = e[this.fields.endTime];
                    blockSpannedList.push(e);
                }
                isSameDate = this.isSameDate(temp[this.fields.startTime], temp[this.fields.endTime]);
                if (!isSameDate && getDateInMs(temp[this.fields.endTime]) > 0) {
                    var e = extend({}, event_3, null, true);
                    e[this.fields.startTime] = resetTime(new Date(event_3[this.fields.endTime] + ''));
                    e.isBlockIcon = true;
                    blockSpannedList.push(e);
                    temp[this.fields.endTime] = e[this.fields.startTime];
                }
                blockSpannedList.push(temp);
            }
            else {
                blockSpannedList.push(event_3);
            }
            for (var _a = 0, blockSpannedList_1 = blockSpannedList; _a < blockSpannedList_1.length; _a++) {
                var blockEvent = blockSpannedList_1[_a];
                var spannedEvents = this.splitEvent(blockEvent, this.dateRender);
                for (var _b = 0, spannedEvents_2 = spannedEvents; _b < spannedEvents_2.length; _b++) {
                    var event_4 = spannedEvents_2[_b];
                    this.renderBlockEvents(event_4, resIndex, !!blockEvent.isBlockIcon);
                }
            }
        }
    };
    MonthEvent.prototype.isSameDate = function (start, end) {
        return new Date(+start).setHours(0, 0, 0, 0) === new Date(+end).setHours(0, 0, 0, 0);
    };
    MonthEvent.prototype.renderBlockEvents = function (event, resIndex, isIcon) {
        var eventData = event.data;
        var startTime = this.getStartTime(event, eventData);
        var endTime = this.getEndTime(event, eventData);
        var day = this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(startTime.getTime())));
        if (day < 0 || startTime > endTime) {
            return;
        }
        var cellTd = this.getCellTd(day);
        var position = this.getPosition(startTime, endTime, event[this.fields.isAllDay], day);
        if (!isIcon) {
            var diffInDays = eventData.count;
            var appWidth = this.getEventWidth(startTime, endTime, event[this.fields.isAllDay], diffInDays);
            appWidth = (appWidth <= 0) ? this.cellWidth : appWidth;
            var appLeft = (this.parent.enableRtl) ? 0 : position;
            var appRight = (this.parent.enableRtl) ? position : 0;
            var appHeight = this.cellHeight - this.monthHeaderHeight;
            var appTop = this.getRowTop(resIndex);
            var blockElement = this.createBlockAppointmentElement(event, resIndex, this.isResourceEventTemplate);
            setStyleAttribute(blockElement, {
                'width': appWidth + 'px', 'height': appHeight + 'px', 'left': appLeft + 'px',
                'right': appRight + 'px', 'top': appTop + 'px'
            });
            this.renderEventElement(event, blockElement, cellTd);
        }
        else {
            this.renderBlockIndicator(cellTd, position, resIndex);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.renderBlockIndicator = function (cellTd, position, resIndex) {
        var blockIndicator = createElement('div', { className: 'e-icons ' + BLOCK_INDICATOR_CLASS });
        if (isNullOrUndefined(cellTd.querySelector('.' + BLOCK_INDICATOR_CLASS))) {
            cellTd.appendChild(blockIndicator);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.getStartTime = function (event, eventData) {
        return event[this.fields.startTime];
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.getEndTime = function (event, eventData) {
        return event[this.fields.endTime];
    };
    MonthEvent.prototype.getCellTd = function (day) {
        return this.workCells[parseInt(day.toString(), 10)];
    };
    MonthEvent.prototype.getEventWidth = function (startDate, endDate, isAllDay, count) {
        return count * this.cellWidth - 1;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.getPosition = function (startTime, endTime, isAllDay, day) {
        return 0;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.getRowTop = function (resIndex) {
        return 0;
    };
    MonthEvent.prototype.updateIndicatorIcon = function (event) {
        if (this.parent.currentView.indexOf('Timeline') === -1 || this.parent.currentView === 'TimelineMonth'
            || event[this.fields.isAllDay]) {
            return;
        }
        var cloneData = event.data;
        var start = this.parent.activeView.getStartHour();
        var end = this.parent.activeView.getEndHour();
        var startHour = getStartEndHours(event[this.fields.startTime], start, end);
        var endHour = getStartEndHours(event[this.fields.endTime], start, end);
        var actualStartTime = cloneData[this.fields.startTime];
        var actualEndTime = cloneData[this.fields.endTime];
        cloneData.isLeft = cloneData.isLeft || actualStartTime.getTime() < startHour.startHour.getTime();
        cloneData.isRight = cloneData.isRight || actualEndTime.getTime() > endHour.endHour.getTime();
        if (resetTime(actualStartTime).getTime() !== resetTime(actualEndTime).getTime()) {
            var actualStartHour = startHour.startHour;
            var actualEndHour = endHour.endHour;
            var startTime = new Date(resetTime(actualStartTime));
            startTime.setHours(actualEndHour.getHours(), actualEndHour.getMinutes(), actualEndHour.getSeconds());
            cloneData.isLeft = cloneData.isLeft || actualStartTime.getTime() >= (actualStartHour.getDate() === startHour.endHour.getDate() ?
                startTime.getTime() : addDays(startTime, 1).getTime());
            if (actualEndTime.getTime() !== resetTime(actualEndTime).getTime()) {
                var endTime = new Date(resetTime(actualEndTime));
                cloneData.isRight = cloneData.isRight || actualEndTime.getTime() <=
                    endTime.setHours(actualStartHour.getHours(), actualStartHour.getMinutes(), actualStartHour.getSeconds());
            }
        }
    };
    MonthEvent.prototype.renderResourceEvents = function () {
        var resources = this.parent.uiStateValues.isGroupAdaptive ?
            [this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex]] :
            this.parent.resourceBase.renderedResources;
        if (this.parent.crudModule && this.parent.crudModule.crudObj.isCrudAction) {
            for (var i = 0, len = this.parent.crudModule.crudObj.sourceEvent.length; i < len; i++) {
                var sourceRes = this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)];
                this.renderEventsHandler(sourceRes.renderDates, sourceRes.workDays, sourceRes);
                if (this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex !==
                    this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex) {
                    var target = this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)];
                    this.renderEventsHandler(target.renderDates, target.workDays, target);
                }
            }
            this.parent.crudModule.crudObj.isCrudAction = false;
        }
        else {
            for (var _i = 0, resources_1 = resources; _i < resources_1.length; _i++) {
                var slotData = resources_1[_i];
                this.renderEventsHandler(slotData.renderDates, slotData.workDays, slotData);
            }
        }
    };
    MonthEvent.prototype.getSlotDates = function (workDays) {
        this.slots = [];
        var dates = this.dateRender.map(function (date) { return +date; });
        var noOfDays = !this.parent.activeViewOptions.showWeekend || (this.parent.activeViewOptions.group.byDate &&
            this.parent.activeViewOptions.group.hideNonWorkingDays) ? workDays.length : WEEK_LENGTH;
        while (dates.length > 0) {
            this.slots.push(dates.splice(0, noOfDays));
        }
    };
    MonthEvent.prototype.createAppointmentElement = function (record, resIndex, isCloneElement) {
        if (isCloneElement === void 0) { isCloneElement = false; }
        var eventSubject = (record[this.fields.subject] || this.parent.eventSettings.fields.subject.default ||
            this.parent.localeObj.getConstant('addTitle'));
        var newRecord = extend({}, record, record.data, true);
        var attrs = {
            'data-id': 'Appointment_' + record[this.fields.id],
            'role': 'button', 'tabindex': '0',
            'aria-disabled': this.parent.eventBase.getReadonlyAttribute(record),
            'aria-label': this.parent.getAnnouncementString(newRecord, eventSubject)
        };
        if (!isCloneElement) {
            attrs['data-guid'] = record.Guid;
        }
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            attrs['data-group-index'] = resIndex.toString();
        }
        var appointmentWrapper = createElement('div', { className: APPOINTMENT_CLASS, attrs: attrs });
        if (!isNullOrUndefined(this.cssClass)) {
            addClass([appointmentWrapper], this.cssClass);
        }
        if (record[this.fields.isReadonly]) {
            addClass([appointmentWrapper], 'e-read-only');
        }
        var appointmentDetails = createElement('div', { className: APPOINTMENT_DETAILS });
        appointmentWrapper.appendChild(appointmentDetails);
        var templateElement;
        var eventData = record.data;
        var eventObj = this.getEventData(record);
        if (!isNullOrUndefined(this.parent.activeViewOptions.eventTemplate)) {
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.eventTemplateName;
            var templateId = scheduleId + viewName + 'eventTemplate';
            var eventTemplate = 'eventTemplate' + (this.isResourceEventTemplate ? '_' + resIndex : '');
            templateElement = this.parent.getAppointmentTemplate()(eventObj, this.parent, eventTemplate, templateId, false);
        }
        else {
            var eventLocation = (record[this.fields.location] || this.parent.eventSettings.fields.location.default || '');
            var appointmentSubject = createElement('div', { className: SUBJECT_CLASS });
            this.parent.sanitize((eventSubject + (eventLocation ? '; ' + eventLocation : '')), appointmentSubject);
            var appointmentStartTime = createElement('div', {
                className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                innerHTML: this.parent.getTimeString(eventData[this.fields.startTime])
            });
            var appointmentEndTime = createElement('div', {
                className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                innerHTML: this.parent.getTimeString(eventData[this.fields.endTime])
            });
            if (this.parent.currentView === 'Month') {
                if (record[this.fields.isAllDay]) {
                    templateElement = [appointmentSubject];
                    addClass([appointmentSubject], 'e-text-center');
                }
                else if (eventData.count <= 1 && !eventData.isLeft && !eventData.isRight) {
                    templateElement = [appointmentStartTime, appointmentSubject];
                }
                else {
                    templateElement = [];
                    addClass([appointmentSubject], 'e-text-center');
                    if (!eventData.isLeft) {
                        templateElement.push(appointmentStartTime);
                    }
                    templateElement.push(appointmentSubject);
                    if (!eventData.isRight) {
                        templateElement.push(appointmentEndTime);
                    }
                }
            }
            else {
                var innerElement = void 0;
                if (record[this.fields.isAllDay]) {
                    var allDayString = createElement('div', {
                        className: APPOINTMENT_TIME, innerHTML: this.parent.localeObj.getConstant('allDay')
                    });
                    innerElement = [appointmentSubject, allDayString];
                }
                else {
                    var timeString = this.parent.getTimeString(eventData[this.fields.startTime])
                        + ' - ' + this.parent.getTimeString(eventData[this.fields.endTime]);
                    var appTime = createElement('div', {
                        className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''), innerHTML: timeString
                    });
                    var appLocation = createElement('div', { className: LOCATION_CLASS });
                    this.parent.sanitize(eventLocation, appLocation);
                    innerElement = [appointmentSubject, appTime, appLocation];
                }
                var wrap = createElement('div', { className: 'e-inner-wrap' });
                append(innerElement, wrap);
                templateElement = [wrap];
            }
        }
        append(templateElement, appointmentDetails);
        this.appendEventIcons(record, appointmentDetails);
        this.renderResizeHandler(appointmentWrapper, record.data, record[this.fields.isReadonly]);
        return appointmentWrapper;
    };
    MonthEvent.prototype.appendEventIcons = function (record, appointmentDetails) {
        var eventData = record.data;
        if (!isNullOrUndefined(record[this.fields.recurrenceRule]) || !isNullOrUndefined(record[this.fields.recurrenceID])) {
            var iconClass = (record[this.fields.id] === record[this.fields.recurrenceID]) ?
                EVENT_RECURRENCE_ICON_CLASS : EVENT_RECURRENCE_EDIT_ICON_CLASS;
            appointmentDetails.appendChild(createElement('div', {
                className: ICON + ' ' + iconClass + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : '')
            }));
        }
        if (eventData.isLeft) {
            var iconLeft = createElement('div', {
                className: EVENT_INDICATOR_CLASS + ' ' + ICON + ' ' + EVENT_ICON_LEFT_CLASS
            });
            prepend([iconLeft], appointmentDetails);
        }
        if (eventData.isRight) {
            var iconRight = createElement('div', {
                className: EVENT_INDICATOR_CLASS + ' ' + ICON + ' ' + EVENT_ICON_RIGHT_CLASS
            });
            append([iconRight], appointmentDetails);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    MonthEvent.prototype.renderEvents = function (event, resIndex, eventsList) {
        var startTime = event[this.fields.startTime];
        var endTime = event[this.fields.endTime];
        var day = this.parent.getIndexOfDate(this.dateRender, resetTime(startTime));
        if ((day < 0) || (startTime.getTime() < this.parent.minDate.getTime()) || (endTime.getTime() > this.parent.maxDate.getTime())) {
            return;
        }
        var overlapCount = this.getIndex(startTime);
        event.Index = overlapCount;
        var diffInDays = event.data.count;
        if (startTime.getTime() <= endTime.getTime()) {
            var appWidth = (diffInDays * this.cellWidth) - 5;
            var cellTd = this.workCells[parseInt(day.toString(), 10)];
            var appTop = (overlapCount * (this.eventHeight + EVENT_GAP));
            var height = this.monthHeaderHeight + ((overlapCount + 1) * (this.eventHeight + EVENT_GAP)) + this.moreIndicatorHeight;
            var enableAppRender = this.maxOrIndicator ? overlapCount < 1 ? true : false : this.cellHeight > height;
            if (this.parent.rowAutoHeight || enableAppRender) {
                this.renderedEvents.push(extend({}, event, null, true));
                var appointmentElement = void 0;
                if (this.inlineValue) {
                    appointmentElement = this.parent.inlineModule.createInlineAppointmentElement();
                }
                else {
                    appointmentElement = this.createAppointmentElement(event, resIndex);
                }
                this.applyResourceColor(appointmentElement, event, 'backgroundColor', this.groupOrder);
                this.wireAppointmentEvents(appointmentElement, event);
                setStyleAttribute(appointmentElement, { 'width': appWidth + 'px', 'top': appTop + 'px' });
                this.renderEventElement(event, appointmentElement, cellTd);
                if (this.parent.rowAutoHeight) {
                    var firstChild = cellTd.parentElement.firstElementChild;
                    this.updateCellHeight(firstChild, height);
                }
            }
            else {
                for (var i = 0; i < diffInDays; i++) {
                    if (this.workCells[day + i]) {
                        var indicator = this.workCells[day + i].querySelector('.' + MORE_INDICATOR_CLASS);
                        if (indicator) {
                            var count = parseInt(indicator.getAttribute('data-count'), 10) + 1;
                            indicator.setAttribute('data-count', count.toString());
                            indicator.innerHTML = this.getMoreIndicatorText(count);
                        }
                        else {
                            var startDate = new Date(this.dateRender[day + i].getTime());
                            var endDate = addDays(this.dateRender[day + i], 1);
                            var groupIndex = this.workCells[day + i].getAttribute('data-group-index');
                            var moreIndicatorElement = this.getMoreIndicatorElement(1, startDate, endDate);
                            if (!isNullOrUndefined(groupIndex)) {
                                moreIndicatorElement.setAttribute('data-group-index', groupIndex);
                            }
                            moreIndicatorElement.style.top = (this.cellHeight - this.monthHeaderHeight - this.moreIndicatorHeight) + 'px';
                            moreIndicatorElement.style.width = this.cellWidth - 2 + 'px';
                            this.renderElement(this.workCells[day + i], moreIndicatorElement);
                            EventHandler.add(moreIndicatorElement, 'click', this.moreIndicatorClick, this);
                        }
                    }
                }
            }
        }
    };
    MonthEvent.prototype.updateCellHeight = function (cell, height) {
        if ((height > cell.offsetHeight)) {
            setStyleAttribute(cell, { 'height': height + 'px' });
        }
    };
    MonthEvent.prototype.updateBlockElements = function () {
        var blockElement = [].slice.call(this.element.querySelectorAll('.' + BLOCK_APPOINTMENT_CLASS));
        for (var _i = 0, blockElement_1 = blockElement; _i < blockElement_1.length; _i++) {
            var element = blockElement_1[_i];
            var target = closest(element, 'tr');
            this.monthHeaderHeight = element.offsetParent.offsetTop - target.offsetTop;
            element.style.height = ((target.offsetHeight - 1) - this.monthHeaderHeight) + 'px';
            var firstChild = target.firstElementChild;
            var width = Math.round(element.offsetWidth / firstChild.offsetWidth);
            element.style.width = (firstChild.offsetWidth * width) + 'px';
        }
    };
    // eslint-disable-next-line max-len
    MonthEvent.prototype.getFilteredEvents = function (startDate, endDate, groupIndex, eventsList) {
        var filteredEvents;
        if (isNullOrUndefined(groupIndex)) {
            filteredEvents = this.filterEvents(startDate, endDate);
        }
        else {
            var data = this.parent.resourceBase.lastResourceLevel[parseInt(groupIndex, 10)];
            filteredEvents = this.filterEvents(startDate, endDate, isNullOrUndefined(eventsList) ? undefined : eventsList, data);
        }
        return filteredEvents;
    };
    MonthEvent.prototype.getOverlapEvents = function (date, appointments) {
        var appointmentsList = [];
        var dateTime = resetTime(date).getTime();
        for (var _i = 0, appointments_1 = appointments; _i < appointments_1.length; _i++) {
            var app = appointments_1[_i];
            if ((resetTime(app[this.fields.startTime]).getTime() <= dateTime) &&
                (resetTime(app[this.fields.endTime]).getTime() >= dateTime)) {
                appointmentsList.push(app);
            }
        }
        return appointmentsList;
    };
    MonthEvent.prototype.getIndex = function (date) {
        var appIndex = -1;
        var appointments = this.renderedEvents;
        if (appointments.length > 0) {
            var appointmentsList = this.getOverlapEvents(date, appointments);
            var appLevel = appointmentsList.map(function (obj) { return obj.Index; });
            appIndex = (appLevel.length > 0) ? this.getSmallestMissingNumber(appLevel) : 0;
        }
        return (appIndex === -1) ? 0 : appIndex;
    };
    MonthEvent.prototype.moreIndicatorClick = function (event) {
        var _this = this;
        var target = closest(event.target, '.' + MORE_INDICATOR_CLASS);
        var startDate = new Date(parseInt(target.getAttribute('data-start-date'), 10));
        var endDate = new Date(parseInt(target.getAttribute('data-end-date'), 10));
        var groupIndex = target.getAttribute('data-group-index');
        var moreArgs = {
            cancel: false, event: event, element: target, isPopupOpen: true,
            startTime: startDate, endTime: endDate, viewName: this.parent.getNavigateView()
        };
        if (groupIndex) {
            moreArgs.groupIndex = parseInt(groupIndex, 10);
        }
        this.parent.trigger(moreEventsClick, moreArgs, function (clickArgs) {
            if (!clickArgs.cancel) {
                if (clickArgs.isPopupOpen) {
                    var filteredEvents = _this.getFilteredEvents(startDate, endDate, groupIndex);
                    var moreArgs_1 = { date: startDate, event: filteredEvents, element: event.target };
                    _this.parent.quickPopup.moreEventClick(moreArgs_1, endDate, groupIndex);
                }
                else {
                    _this.parent.setProperties({ selectedDate: startDate }, true);
                    _this.parent.changeView(clickArgs.viewName, event);
                }
            }
        });
    };
    MonthEvent.prototype.renderEventElement = function (event, appointmentElement, cellTd) {
        var _this = this;
        var eventType = appointmentElement.classList.contains(BLOCK_APPOINTMENT_CLASS) ? 'blockEvent' : 'event';
        var isAppointment = appointmentElement.classList.contains(APPOINTMENT_CLASS);
        var eventObj = this.getEventData(event);
        var args = { data: eventObj, element: appointmentElement, cancel: false, type: eventType };
        this.parent.trigger(eventRendered, args, function (eventArgs) {
            if (eventArgs.cancel) {
                _this.renderedEvents.pop();
            }
            else {
                _this.renderElement(cellTd, appointmentElement, isAppointment);
            }
        });
    };
    MonthEvent.prototype.getEventData = function (event) {
        var eventObj = extend({}, event, null, true);
        eventObj[this.fields.startTime] = event.data[this.fields.startTime];
        eventObj[this.fields.endTime] = event.data[this.fields.endTime];
        return eventObj;
    };
    MonthEvent.prototype.renderElement = function (cellTd, element, isAppointment) {
        if (isAppointment === void 0) { isAppointment = false; }
        if (this.maxOrIndicator && isAppointment) {
            this.setMaxEventHeight(element, cellTd);
        }
        var wrapperEle = cellTd.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
        if (wrapperEle) {
            wrapperEle.appendChild(element);
        }
        else {
            var wrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            wrapper.appendChild(element);
            cellTd.appendChild(wrapper);
        }
    };
    MonthEvent.prototype.getMoreIndicatorElement = function (count, startDate, endDate) {
        var moreIndicatorElement = createElement('div', {
            className: MORE_INDICATOR_CLASS,
            innerHTML: this.getMoreIndicatorText(count),
            attrs: {
                'tabindex': '0',
                'data-count': count.toString(),
                'data-start-date': startDate.getTime().toString(),
                'data-end-date': endDate.getTime().toString()
            }
        });
        return moreIndicatorElement;
    };
    MonthEvent.prototype.getMoreIndicatorText = function (count) {
        return '+' + this.parent.globalize.formatNumber(count) + '&nbsp;' + (this.parent.isAdaptive ? '' : this.parent.localeObj.getConstant('more'));
    };
    MonthEvent.prototype.removeHeightProperty = function (selector) {
        var rows = [].slice.call(this.element.querySelectorAll('.' + selector + ' tbody tr'));
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            row.firstElementChild.style.height = '';
        }
    };
    MonthEvent.prototype.setMaxEventHeight = function (event, cell) {
        var headerHeight = getOuterHeight(cell.querySelector('.' + DATE_HEADER_CLASS));
        var height = (cell.offsetHeight - headerHeight) - (this.maxHeight ? 0 : this.moreIndicatorHeight);
        setStyleAttribute(event, { 'height': height + 'px', 'align-items': 'center' });
    };
    MonthEvent.prototype.destroy = function () {
        this.element = null;
        this.fields = null;
        this.maxHeight = null;
        this.withIndicator = null;
        this.maxOrIndicator = null;
        this.moreIndicatorHeight = null;
        this.removeEventListener();
        _super.prototype.destroy.call(this);
    };
    return MonthEvent;
}(EventBase));

var __extends$3 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
var EVENT_GAP$1 = 2;
var BLOCK_INDICATOR_WIDTH = 22;
var BLOCK_INDICATOR_HEIGHT = 18;
/**
 * Timeline view events render
 */
var TimelineEvent = /** @__PURE__ @class */ (function (_super) {
    __extends$3(TimelineEvent, _super);
    function TimelineEvent(parent, type) {
        var _this = _super.call(this, parent) || this;
        _this.startHour = _this.parent.activeView.getStartHour();
        _this.endHour = _this.parent.activeView.getEndHour();
        _this.slotCount = _this.parent.activeViewOptions.timeScale.slotCount;
        _this.interval = _this.parent.activeViewOptions.timeScale.interval;
        _this.day = 0;
        _this.rowIndex = 0;
        _this.cellTops = [];
        _this.renderType = type;
        _this.eventContainers = [].slice.call(_this.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
        var tr = [].slice.call(_this.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody tr'));
        _this.dayLength = tr.length === 0 ? 0 : tr[0].children.length;
        _this.content = _this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS);
        return _this;
    }
    TimelineEvent.prototype.getSlotDates = function () {
        this.slots = [];
        this.slots.push(this.parent.activeView.renderDates.map(function (date) { return +date; }));
        if (this.parent.activeViewOptions.headerRows.length > 0 &&
            this.parent.activeViewOptions.headerRows.slice(-1)[0].option !== 'Hour') {
            this.renderType = 'day';
            var workCell = this.content.querySelector('.' + WORK_CELLS_CLASS);
            this.cellWidth = workCell.getBoundingClientRect().width / +(workCell.getAttribute('colspan') || 1);
            this.slotsPerDay = 1;
        }
        else {
            this.slotsPerDay = (this.dayLength / this.dateRender.length);
        }
    };
    TimelineEvent.prototype.getOverlapEvents = function (date, appointments) {
        var appointmentsList = [];
        if (this.renderType === 'day') {
            for (var _i = 0, appointments_1 = appointments; _i < appointments_1.length; _i++) {
                var app = appointments_1[_i];
                if ((resetTime(app[this.fields.startTime]).getTime() <= resetTime(new Date(date.getTime())).getTime()) &&
                    (resetTime(app[this.fields.endTime]).getTime() >= resetTime(new Date(date.getTime())).getTime())) {
                    appointmentsList.push(app);
                }
            }
        }
        else {
            for (var _a = 0, appointments_2 = appointments; _a < appointments_2.length; _a++) {
                var app = appointments_2[_a];
                var eventData = app.data;
                if (eventData.trimStartTime.getTime() <= date.getTime() &&
                    eventData.trimEndTime.getTime() > date.getTime()) {
                    appointmentsList.push(app);
                }
            }
        }
        return appointmentsList;
    };
    TimelineEvent.prototype.getSortComparerIndex = function (startDate, endDate) {
        var appIndex = -1;
        var appointments = this.renderedEvents;
        if (appointments.length > 0) {
            var appointmentsList = this.getOverlapSortComparerEvents(startDate, endDate, appointments);
            var appLevel = appointmentsList.map(function (obj) { return obj.Index; });
            appIndex = (appLevel.length > 0) ? this.getSmallestMissingNumber(appLevel) : 0;
        }
        return (appIndex === -1) ? 0 : appIndex;
    };
    TimelineEvent.prototype.getOverlapSortComparerEvents = function (startDate, endDate, appointmentsCollection) {
        var appointments = [];
        for (var _i = 0, appointmentsCollection_1 = appointmentsCollection; _i < appointmentsCollection_1.length; _i++) {
            var app = appointmentsCollection_1[_i];
            if (this.renderType === 'day') {
                if ((resetTime(app[this.fields.startTime]).getTime() <= resetTime(new Date(startDate.getTime())).getTime()) &&
                    (resetTime(app[this.fields.endTime]).getTime() >= resetTime(new Date(startDate.getTime())).getTime())) {
                    appointments.push(app);
                }
            }
            else {
                var eventData = app.data;
                if (((eventData.trimStartTime.getTime() <= startDate.getTime()) && (startDate.getTime() < eventData.trimEndTime.getTime())) ||
                    ((startDate.getTime() <= eventData.trimStartTime.getTime()) && (eventData.trimStartTime.getTime() < endDate.getTime()))) {
                    appointments.push(app);
                }
            }
        }
        return appointments;
    };
    TimelineEvent.prototype.renderResourceEvents = function () {
        this.removeHeightProperty(RESOURCE_COLUMN_TABLE_CLASS);
        var resources = this.parent.uiStateValues.isGroupAdaptive ?
            [this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex]] :
            this.parent.resourceBase.renderedResources;
        if (this.parent.crudModule && this.parent.crudModule.crudObj.isCrudAction) {
            for (var i = 0, len = this.parent.crudModule.crudObj.sourceEvent.length; i < len; i++) {
                var source = this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)];
                this.rowIndex = source.groupIndex;
                this.renderEventsHandler(this.parent.activeView.renderDates, this.parent.activeViewOptions.workDays, source);
                if (this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)] && this.parent.crudModule.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex !==
                    this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex) {
                    var target = this.parent.crudModule.crudObj.targetEvent[parseInt(i.toString(), 10)];
                    this.rowIndex = target.groupIndex;
                    this.renderEventsHandler(this.parent.activeView.renderDates, this.parent.activeViewOptions.workDays, target);
                }
            }
            this.parent.crudModule.crudObj.isCrudAction = false;
        }
        else {
            for (var i = 0; i < resources.length; i++) {
                this.rowIndex = i;
                this.renderEventsHandler(this.parent.activeView.renderDates, this.parent.activeViewOptions.workDays, resources[parseInt(i.toString(), 10)]);
            }
        }
    };
    TimelineEvent.prototype.renderEvents = function (event, resIndex, appointmentsList) {
        var startTime = event[this.fields.startTime];
        var endTime = event[this.fields.endTime];
        if ((startTime.getTime() < this.parent.minDate.getTime()) || (endTime.getTime() > this.parent.maxDate.getTime())) {
            return;
        }
        var eventData = event.data;
        startTime = this.getStartTime(event, eventData);
        endTime = this.getEndTime(event, eventData);
        var startEndHours = getStartEndHours(event[this.fields.startTime], this.startHour, this.endHour);
        var eventDates = this.updateEventMinimumDuration(startEndHours, startTime, endTime);
        startTime = eventDates.startDate;
        endTime = eventDates.endDate;
        this.day = this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(startTime.getTime())));
        if (this.day < 0) {
            return;
        }
        var cellTd = this.getCellTd();
        var overlapCount = (isNullOrUndefined(this.parent.eventSettings.sortComparer)) ? this.getIndex(startTime) : this.getSortComparerIndex(startTime, endTime);
        event.Index = overlapCount;
        var appHeight = this.eventHeight;
        var diffInDays = eventData.count;
        var eventObj = extend({}, event, null, true);
        eventObj[this.fields.startTime] = eventData[this.fields.startTime];
        eventObj[this.fields.endTime] = eventData[this.fields.endTime];
        var currentDate = resetTime(new Date(this.dateRender[this.day].getTime()));
        var schedule = getStartEndHours(currentDate, this.startHour, this.endHour);
        var isValidEvent = true;
        if (this.isDayProcess() || eventObj[this.fields.isAllDay]) {
            isValidEvent = true;
        }
        else {
            isValidEvent = this.isValidEvent(eventObj, startTime, endTime, schedule);
        }
        if (startTime <= endTime && isValidEvent) {
            var appWidth = this.getEventWidth(startTime, endTime, event[this.fields.isAllDay], diffInDays);
            appWidth = this.renderType === 'day' ? appWidth - 2 : appWidth;
            var appLeft = 0;
            var appRight = 0;
            var position = this.getPosition(startTime, endTime, event[this.fields.isAllDay], this.day);
            appWidth = (appWidth <= 0) ? this.cellWidth : appWidth; // appWidth 0 when start and end time as same
            this.renderedEvents.push(extend({}, event, null, true));
            if (isNullOrUndefined(this.cellTops[parseInt(resIndex.toString(), 10)])) {
                this.cellTops[parseInt(resIndex.toString(), 10)] = this.getRowTop(resIndex);
            }
            var top_1 = this.cellTops[parseInt(resIndex.toString(), 10)];
            var appTop = (top_1 + (this.maxHeight ? 0 : EVENT_GAP$1)) + (overlapCount * (appHeight + EVENT_GAP$1));
            appLeft = (this.parent.enableRtl) ? 0 : position;
            appRight = (this.parent.enableRtl) ? position : 0;
            var height = ((overlapCount + 1) * (appHeight + EVENT_GAP$1)) + this.moreIndicatorHeight;
            var renderApp = this.maxOrIndicator ? overlapCount < 1 ? true : false : this.cellHeight > height;
            if (this.parent.rowAutoHeight || renderApp) {
                var appointmentElement = void 0;
                if (isNullOrUndefined(this.inlineValue)) {
                    appointmentElement = this.createAppointmentElement(event, resIndex);
                }
                else {
                    appointmentElement = this.parent.inlineModule.createInlineAppointmentElement();
                }
                this.applyResourceColor(appointmentElement, event, 'backgroundColor', this.groupOrder);
                setStyleAttribute(appointmentElement, {
                    'width': appWidth + 'px', 'left': appLeft + 'px', 'right': appRight + 'px', 'top': appTop + 'px'
                });
                this.wireAppointmentEvents(appointmentElement, event);
                if (this.parent.rowAutoHeight) {
                    var conWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
                    var conWidth = conWrap.getBoundingClientRect().width;
                    var isWithoutScroll = conWrap.offsetHeight === conWrap.clientHeight &&
                        conWrap.offsetWidth === conWrap.clientWidth;
                    this.renderEventElement(event, appointmentElement, cellTd);
                    var firstChild = this.getFirstChild(resIndex);
                    this.updateCellHeight(firstChild, height);
                    if (isWithoutScroll &&
                        (conWrap.offsetWidth > conWrap.clientWidth || conWidth !== conWrap.getBoundingClientRect().width)) {
                        this.adjustAppointments(conWidth);
                    }
                }
                else {
                    this.renderEventElement(event, appointmentElement, cellTd);
                }
            }
            else {
                for (var i = 0; i < diffInDays; i++) {
                    var moreIndicator = cellTd.querySelector('.' + MORE_INDICATOR_CLASS);
                    var appPos = (this.parent.enableRtl) ? appRight : appLeft;
                    appPos = (Math.floor(appPos / this.cellWidth) * this.cellWidth);
                    var interval = this.interval / this.slotCount;
                    var startDate = (this.parent.activeViewOptions.option === 'TimelineMonth' || this.renderType === 'day' || i !== 0) ?
                        new Date(this.dateRender[this.day + i].getTime()) : new Date(startTime);
                    var endDate = addDays(this.dateRender[this.day + i], 1);
                    if (this.parent.activeViewOptions.option === 'TimelineMonth' || this.renderType === 'day') {
                        var position_1 = this.getPosition(startDate, endDate, event[this.fields.isAllDay], (this.day + i));
                        this.renderTimelineMoreIndicator(startTime, startDate, endDate, appHeight, interval, resIndex, appointmentsList, top_1, appLeft, appRight, cellTd, moreIndicator, appPos, position_1);
                    }
                    else {
                        var slotCount = (getUniversalTime(endTime) - getUniversalTime(startTime)) / MS_PER_MINUTE *
                            this.slotCount / this.interval;
                        for (var k = 0; k < slotCount; k++) {
                            startDate = (k === 0) ? new Date(startDate.getTime()) : new Date(startDate.getTime() + (60000 * interval));
                            endDate = new Date(startDate.getTime() + (60000 * interval));
                            if (endDate.getTime() > endTime.getTime()) {
                                break;
                            }
                            var position_2 = this.getPosition(startDate, endDate, false, (this.day + i));
                            if (appPos > position_2) {
                                break;
                            }
                            appPos = position_2;
                            this.renderTimelineMoreIndicator(startTime, startDate, endDate, appHeight, interval, resIndex, appointmentsList, top_1, appLeft, appRight, cellTd, moreIndicator, appPos, position_2);
                        }
                    }
                }
            }
        }
        this.parent.renderTemplates();
    };
    TimelineEvent.prototype.renderTimelineMoreIndicator = function (startTime, startDate, endDate, appHeight, interval, resIndex, appointmentsList, top, appLeft, appRight, cellTd, moreIndicator, appPos, position) {
        appLeft = (this.parent.enableRtl) ? appRight = position : position;
        appPos = (this.parent.enableRtl) ? appRight : appLeft;
        appPos = (Math.floor(appPos / this.cellWidth) * this.cellWidth);
        if ((cellTd && isNullOrUndefined(moreIndicator)) ||
            (!this.isAlreadyAvail(appPos, cellTd))) {
            var startDateTime = (this.parent.activeViewOptions.option === 'TimelineMonth' || this.renderType === 'day') ? new Date(+startTime) : startDate;
            var slotStartTime = (new Date(startDateTime.setMinutes(Math.floor(startDateTime.getMinutes() / interval) * interval)));
            var slotEndTime = new Date(slotStartTime.getTime() + (60000 * interval));
            var groupIndex = void 0;
            if (this.parent.activeViewOptions.group.resources.length > 0 && !isNullOrUndefined(resIndex)) {
                groupIndex = resIndex.toString();
            }
            var filterEvents = this.getFilterEvents(startDate, endDate, slotStartTime, slotEndTime, groupIndex, appointmentsList);
            var appArea = this.cellHeight - this.moreIndicatorHeight;
            appHeight = this.withIndicator ? appArea - EVENT_GAP$1 : appHeight;
            var renderedAppCount = Math.floor(appArea / (appHeight + EVENT_GAP$1));
            var count = (filterEvents.length - renderedAppCount) <= 0 ? 1 : (filterEvents.length - renderedAppCount);
            var moreIndicatorElement = void 0;
            if (this.renderType === 'day') {
                moreIndicatorElement = this.getMoreIndicatorElement(count, startDate, endDate);
            }
            else {
                moreIndicatorElement = this.getMoreIndicatorElement(count, slotStartTime, slotEndTime);
            }
            if (!isNullOrUndefined(groupIndex)) {
                moreIndicatorElement.setAttribute('data-group-index', groupIndex);
            }
            moreIndicatorElement.style.top = top + appArea + 'px';
            moreIndicatorElement.style.width = this.cellWidth + 'px';
            moreIndicatorElement.style.left = (Math.floor(appLeft / this.cellWidth) * this.cellWidth) + 'px';
            moreIndicatorElement.style.right = (Math.floor(appRight / this.cellWidth) * this.cellWidth) + 'px';
            this.renderElement(cellTd, moreIndicatorElement);
            EventHandler.add(moreIndicatorElement, 'click', this.moreIndicatorClick, this);
        }
    };
    TimelineEvent.prototype.updateCellHeight = function (cell, height) {
        var cellHeight = cell.style.height === '' ? this.cellHeight : parseInt(cell.style.height, 10);
        if (height > cellHeight) {
            setStyleAttribute(cell, { 'height': height + 'px' });
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                var resourceCell = this.parent.element.querySelector('.' + RESOURCE_COLUMN_TABLE_CLASS + ' ' + 'tbody td[data-group-index="' +
                    cell.getAttribute('data-group-index') + '"]');
                if (resourceCell) {
                    setStyleAttribute(resourceCell, { 'height': height + 'px' });
                }
            }
            var monthHeader = this.parent.element.querySelector('.e-month-header-wrapper table tr:nth-child(' +
                (cell.parentElement.rowIndex + 1) + ') td');
            if (monthHeader) {
                setStyleAttribute(monthHeader, { 'height': height + 'px' });
            }
        }
    };
    TimelineEvent.prototype.adjustAppointments = function (conWidth) {
        var _this = this;
        var tr = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS + ' tbody tr');
        this.cellWidth = this.workCells[0].getBoundingClientRect().width;
        var currentPercentage = (this.cellWidth * tr.children.length) / (conWidth / 100);
        var apps = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
        apps.forEach(function (app) {
            if (_this.parent.enableRtl && app.style.right !== '0px') {
                app.style.right = ((parseFloat(app.style.right) / 100) * currentPercentage) + 'px';
            }
            else if (app.style.left !== '0px') {
                app.style.left = ((parseFloat(app.style.left) / 100) * currentPercentage) + 'px';
            }
            app.style.width = ((parseFloat(app.style.width) / 100) * currentPercentage) + 'px';
        });
    };
    TimelineEvent.prototype.getFirstChild = function (index) {
        var query = '.' + CONTENT_TABLE_CLASS + ' tbody td';
        var groupIndex = '';
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            groupIndex = '[data-group-index="' + index.toString() + '"]';
        }
        var td = this.parent.element.querySelector(query + groupIndex);
        return td;
    };
    TimelineEvent.prototype.updateBlockElements = function () {
        var blockElement = [].slice.call(this.element.querySelectorAll('.' + BLOCK_APPOINTMENT_CLASS));
        for (var _i = 0, blockElement_1 = blockElement; _i < blockElement_1.length; _i++) {
            var element = blockElement_1[_i];
            var resIndex = parseInt(element.getAttribute('data-group-index'), 10);
            var firstChild = this.getFirstChild(resIndex);
            element.style.height = firstChild.offsetHeight + 'px';
            var width = Math.round(element.offsetWidth / firstChild.offsetWidth);
            element.style.width = (firstChild.offsetWidth * width) + 'px';
        }
        var blockIndicator = [].slice.call(this.element.querySelectorAll('.' + BLOCK_INDICATOR_CLASS));
        for (var _a = 0, blockIndicator_1 = blockIndicator; _a < blockIndicator_1.length; _a++) {
            var element = blockIndicator_1[_a];
            var resIndex = parseInt(element.getAttribute('data-group-index'), 10);
            element.style.top = this.getRowTop(resIndex) +
                this.getFirstChild(resIndex).offsetHeight - BLOCK_INDICATOR_HEIGHT + 'px';
        }
    };
    TimelineEvent.prototype.getStartTime = function (event, eventData) {
        var startTime = event[this.fields.startTime];
        var schedule = getStartEndHours(startTime, this.startHour, this.endHour);
        if (this.isDayProcess()) {
            startTime = event[this.fields.startTime];
        }
        else {
            if (schedule.startHour.getTime() >= eventData[this.fields.startTime]) {
                startTime = schedule.startHour;
            }
            else if (schedule.endHour.getTime() <= eventData[this.fields.startTime]) {
                startTime = this.getNextDay(schedule.startHour, eventData);
            }
            else {
                startTime = eventData[this.fields.startTime];
            }
        }
        // To overcome the overflow
        eventData.trimStartTime = (event[this.fields.isAllDay]) ? schedule.startHour : eventData[this.fields.startTime];
        return startTime;
    };
    TimelineEvent.prototype.getNextDay = function (startTime, eventData) {
        var startDate;
        for (var i = 1; i <= this.dateRender.length; i++) {
            startDate = addDays(startTime, i);
            if (this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(startTime.getTime()))) !== -1) {
                eventData.count = eventData.count - 1;
                return startDate;
            }
        }
        return startDate;
    };
    TimelineEvent.prototype.getEndTime = function (event, eventData) {
        var endTime = event[this.fields.endTime];
        var schedule = getStartEndHours(endTime, this.startHour, this.endHour);
        if (this.isDayProcess()) {
            endTime = eventData[this.fields.endTime];
        }
        else {
            endTime = eventData[this.fields.endTime];
            if (schedule.endHour.getTime() <= eventData[this.fields.endTime] || event[this.fields.isAllDay]) {
                endTime = schedule.endHour;
            }
            if (schedule.startHour.getTime() >= eventData[this.fields.endTime].getTime() && !event[this.fields.isAllDay]) {
                endTime = this.getPreviousDay(schedule.startHour, schedule.endHour, eventData);
            }
        }
        // To overcome the overflow
        eventData.trimEndTime = (event[this.fields.isAllDay]) ? schedule.endHour : eventData[this.fields.endTime];
        return endTime;
    };
    TimelineEvent.prototype.getPreviousDay = function (startTime, endTime, eventData) {
        for (var i = 1; i <= this.dateRender.length; i++) {
            var endDate = addDays(endTime, -i);
            if (this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(startTime.getTime()))) !== -1) {
                endDate = resetTime(new Date(endDate.getTime()));
                endDate.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
                var count = eventData.count;
                var actualEndTime = eventData[this.fields.endTime];
                eventData.count = actualEndTime.getHours() !== 0 || actualEndTime.getMinutes() !== 0 ? count - 1 : count;
                return endDate;
            }
        }
        return eventData[this.fields.endTime];
    };
    TimelineEvent.prototype.getEventWidth = function (startDate, endDate, isAllDay, count) {
        if (this.renderType === 'day' || isAllDay) {
            return (count * this.slotsPerDay) * this.cellWidth;
        }
        if (this.isSameDay(startDate, endDate)) {
            return this.getSameDayEventsWidth(startDate, endDate);
        }
        else {
            return this.getSpannedEventsWidth(startDate, endDate, count);
        }
    };
    TimelineEvent.prototype.getSameDayEventsWidth = function (startDate, endDate) {
        return ((getUniversalTime(endDate) - getUniversalTime(startDate)) /
            MS_PER_MINUTE * (this.cellWidth * this.slotCount) / this.interval);
    };
    TimelineEvent.prototype.getSpannedEventsWidth = function (startDate, endDate, diffInDays) {
        var width = (diffInDays * this.slotsPerDay) * this.cellWidth;
        var endWidth;
        var start = getStartEndHours(resetTime(new Date(startDate.getTime())), this.startHour, this.endHour);
        var startWidth = this.getSameDayEventsWidth(start.startHour, startDate);
        if (this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(endDate.getTime()))) === -1) {
            endWidth = 0;
        }
        else {
            var end = getStartEndHours(resetTime(new Date(endDate.getTime())), this.startHour, this.endHour);
            endWidth = this.getSameDayEventsWidth(endDate, end.endHour);
            endWidth = ((this.slotsPerDay * this.cellWidth) === endWidth) ? 0 : endWidth;
        }
        var spannedWidth = startWidth + endWidth;
        return (width > spannedWidth) ? width - spannedWidth : width - startWidth;
    };
    TimelineEvent.prototype.isSameDay = function (startTime, endTime) {
        var startDay = this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(startTime.getTime())));
        var endDay = this.parent.getIndexOfDate(this.dateRender, resetTime(new Date(endTime.getTime())));
        return (startDay === endDay);
    };
    TimelineEvent.prototype.getAppointmentLeft = function (schedule, startTime, day) {
        var slotTd = (this.isSameDay(startTime, schedule.startHour)) ?
            ((getUniversalTime(startTime) - getUniversalTime(schedule.startHour)) /
                (MS_PER_MINUTE * this.interval)) * this.slotCount : 0;
        if (day === 0) {
            return slotTd;
        }
        else {
            var daySlot = Math.round((((getUniversalTime(schedule.endHour) - getUniversalTime(schedule.startHour)) /
                MS_PER_MINUTE) / this.interval) * this.slotCount);
            return (daySlot * day) + slotTd;
        }
    };
    TimelineEvent.prototype.getPosition = function (startTime, endTime, isAllDay, day) {
        if (this.renderType === 'day' || isAllDay) {
            return (day * this.slotsPerDay) * this.cellWidth;
        }
        var currentDate = resetTime(new Date(this.dateRender[parseInt(day.toString(), 10)].getTime()));
        var schedule = getStartEndHours(currentDate, this.startHour, this.endHour);
        var cellIndex;
        if (schedule.endHour.getTime() <= endTime.getTime() && schedule.startHour.getTime() >= startTime.getTime()) {
            cellIndex = this.getAppointmentLeft(schedule, schedule.startHour, day);
        }
        else if (schedule.endHour.getTime() <= endTime.getTime()) {
            cellIndex = this.getAppointmentLeft(schedule, startTime, day);
        }
        else if (schedule.startHour.getTime() >= startTime.getTime()) {
            cellIndex = this.getAppointmentLeft(schedule, schedule.startHour, day);
        }
        else {
            cellIndex = this.getAppointmentLeft(schedule, startTime, day);
        }
        return cellIndex * this.cellWidth;
    };
    TimelineEvent.prototype.getFilterEvents = function (startDate, endDate, startTime, endTime, gIndex, eventsList) {
        if (this.renderType === 'day') {
            return this.getFilteredEvents(startDate, endDate, gIndex, eventsList);
        }
        else {
            return this.getFilteredEvents(startTime, endTime, gIndex, eventsList);
        }
    };
    TimelineEvent.prototype.isAlreadyAvail = function (appPos, cellTd) {
        var moreIndicator = [].slice.call(cellTd.querySelectorAll('.' + MORE_INDICATOR_CLASS));
        for (var i = 0; i < moreIndicator.length; i++) {
            var indicatorPos = void 0;
            if (moreIndicator) {
                indicatorPos = (this.parent.enableRtl) ? moreIndicator[parseInt(i.toString(), 10)].style.right : moreIndicator[parseInt(i.toString(), 10)].style.left;
            }
            if (parseInt(indicatorPos, 10) === Math.floor(appPos)) {
                return true;
            }
        }
        return false;
    };
    TimelineEvent.prototype.getRowTop = function (resIndex) {
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            return this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS +
                ' ' + 'tbody td[data-group-index="' + resIndex.toString() + '"]').offsetTop;
        }
        return 0;
    };
    TimelineEvent.prototype.getCellTd = function () {
        var wrapIndex = this.parent.uiStateValues.isGroupAdaptive ? 0 : this.rowIndex;
        return this.eventContainers[parseInt(wrapIndex.toString(), 10)];
    };
    TimelineEvent.prototype.renderBlockIndicator = function (cellTd, position, resIndex) {
        // No need to render block icon for Year, Month and Week header rows
        if (this.parent.headerRows.length > 0 &&
            (this.parent.headerRows[this.parent.headerRows.length - 1].option !== 'Hour' ||
                this.parent.headerRows[this.parent.headerRows.length - 1].option !== 'Date')) {
            return;
        }
        position = (Math.floor(position / this.cellWidth) * this.cellWidth) + this.cellWidth - BLOCK_INDICATOR_WIDTH;
        if (!this.isAlreadyAvail(position, cellTd)) {
            var blockIndicator = createElement('div', { className: 'e-icons ' + BLOCK_INDICATOR_CLASS });
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                blockIndicator.setAttribute('data-group-index', resIndex.toString());
            }
            if (this.parent.enableRtl) {
                blockIndicator.style.right = position + 'px';
            }
            else {
                blockIndicator.style.left = position + 'px';
            }
            blockIndicator.style.top = this.getRowTop(resIndex) + this.cellHeight - BLOCK_INDICATOR_HEIGHT + 'px';
            this.renderElement(cellTd, blockIndicator);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TimelineEvent.prototype.setMaxEventHeight = function (event, cell) {
        setStyleAttribute(event, {
            'height': (this.cellHeight - (this.maxHeight ? 0 : EVENT_GAP$1) - (this.maxHeight ? 0 : this.moreIndicatorHeight)) + 'px'
        });
    };
    TimelineEvent.prototype.isDayProcess = function () {
        if (this.parent.currentView === 'TimelineMonth' || !this.parent.activeViewOptions.timeScale.enable ||
            (this.parent.activeViewOptions.headerRows.length > 0 &&
                this.parent.activeViewOptions.headerRows.slice(-1)[0].option !== 'Hour')) {
            return true;
        }
        return false;
    };
    TimelineEvent.prototype.destroy = function () {
        this.renderType = null;
        this.eventContainers = null;
        this.dayLength = null;
        this.content = null;
        _super.prototype.destroy.call(this);
        this.parent = null;
    };
    return TimelineEvent;
}(MonthEvent));

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Inline Edit interactions
 */
var InlineEdit = /** @__PURE__ @class */ (function () {
    function InlineEdit(parent) {
        this.parent = parent;
        this.parent.on(inlineClick, this.inlineEdit, this);
    }
    InlineEdit.prototype.inlineEdit = function (args) {
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide();
        }
        if (args.type === 'Cell') {
            var moreWrapper = this.parent.element.querySelector('.' + MORE_POPUP_WRAPPER_CLASS);
            if (moreWrapper && moreWrapper.classList.contains(POPUP_OPEN)) {
                this.parent.quickPopup.morePopup.hide();
            }
            this.removeInlineAppointmentElement();
            this.cellEdit(args);
        }
        else {
            if (this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS) !==
                args.element.querySelector('.' + INLINE_SUBJECT_CLASS)) {
                this.removeInlineAppointmentElement();
            }
            this.eventEdit(args);
        }
    };
    InlineEdit.prototype.cellEdit = function (args) {
        var saveObj = this.generateEventData();
        var cellIndex = args.element.cellIndex;
        var count = this.getEventDaysCount(saveObj);
        if (count > 1) {
            count = Math.round(count);
            count--;
            cellIndex = cellIndex - count;
        }
        var start = resetTime(new Date('' + saveObj[this.parent.eventFields.startTime])).getTime();
        var end = resetTime(new Date('' + saveObj[this.parent.eventFields.endTime])).getTime();
        var resIndex = args.groupIndex || 0;
        var isVertical = this.parent.currentView === 'Day' || this.parent.currentView === 'Week' || this.parent.currentView === 'WorkWeek';
        if (this.parent.activeViewOptions.timeScale.enable && isVertical) {
            var dayIndex = saveObj[this.parent.eventFields.startTime].getDay();
            this.createVerticalViewInline(saveObj, dayIndex, resIndex, cellIndex);
        }
        else if (this.parent.currentView === 'Month' || (!this.parent.activeViewOptions.timeScale.enable && isVertical)) {
            this.createMonthViewInline(saveObj, resIndex, start, end);
        }
        else {
            this.createTimelineViewInline(saveObj, start, end, resIndex);
        }
        var inlineSubject = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        if (inlineSubject) {
            inlineSubject.focus();
        }
    };
    InlineEdit.prototype.eventEdit = function (args) {
        var inlineSubject = args.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        var subject;
        if (inlineSubject) {
            subject = inlineSubject.value;
        }
        else {
            var subEle = args.element.querySelector('.' + SUBJECT_CLASS);
            if (!isNullOrUndefined(subEle)) {
                addClass([subEle], DISABLE_CLASS);
                subject = subEle.innerText;
            }
            else {
                subject = args.data[this.parent.eventFields.subject];
            }
            inlineSubject = createElement('input', { className: INLINE_SUBJECT_CLASS, attrs: { value: subject } });
            if (closest(args.element, '.' + MORE_POPUP_WRAPPER_CLASS)) {
                args.element.insertBefore(inlineSubject, subEle);
            }
            else if (['Agenda', 'MonthAgenda'].indexOf(this.parent.currentView) > -1) {
                var subjectWrap = args.element.querySelector('.' + SUBJECT_WRAP);
                if (isNullOrUndefined(subjectWrap)) {
                    subjectWrap = createElement('div', { className: SUBJECT_WRAP });
                    args.element.prepend(subjectWrap);
                }
                subjectWrap.insertBefore(inlineSubject, subjectWrap.firstChild);
            }
            else {
                args.element.querySelector('.e-appointment-details').prepend(inlineSubject);
            }
            inlineSubject.focus();
        }
        inlineSubject.setSelectionRange(subject.length, subject.length);
    };
    InlineEdit.prototype.createVerticalViewInline = function (saveObj, dayIndex, resIndex, daysCount) {
        var _a;
        var count = this.getEventDaysCount(saveObj);
        var verticalEvent = new VerticalEvent(this.parent);
        verticalEvent.initializeValues();
        var index = verticalEvent.dateRender[parseInt(resIndex.toString(), 10)].map(function (date) { return date.getDay(); }).indexOf(dayIndex);
        if (count >= 1) {
            verticalEvent.allDayElement = [].slice.call(this.parent.element.querySelectorAll('.' + ALLDAY_CELLS_CLASS));
            (_a = verticalEvent.slots).push.apply(_a, this.parent.activeView.renderDates.map(function (date) { return +date; }));
            var allDayElements = [].slice.call(this.parent.element.querySelectorAll('.' + ALLDAY_APPOINTMENT_CLASS));
            var allDayLevel = 0;
            if (allDayElements.length > 0) {
                allDayLevel = Math.floor(this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS).getBoundingClientRect().height /
                    allDayElements[0].offsetHeight) - 1;
            }
            verticalEvent.allDayLevel = allDayLevel;
            var appHeight = getElementHeightFromClass(this.parent.element.querySelector('.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS), APPOINTMENT_CLASS);
            var cellTop = verticalEvent.allDayElement.length > 0 ? verticalEvent.allDayElement[0].offsetTop : 0;
            verticalEvent.renderAllDayEvents(saveObj, index, resIndex, daysCount, this.parent.allowInline, cellTop, appHeight);
        }
        else {
            verticalEvent.renderNormalEvents(saveObj, index, resIndex, daysCount, this.parent.allowInline);
        }
    };
    InlineEdit.prototype.createMonthViewInline = function (saveObj, index, start, end) {
        var count = this.getEventDaysCount(saveObj);
        var saveObject = this.parent.eventBase.cloneEventObject(saveObj, start, end, count, false, false);
        var monthEvent = new MonthEvent(this.parent);
        monthEvent.dateRender = this.parent.activeView.renderDates;
        monthEvent.inlineValue = this.parent.allowInline;
        var renderDates = this.parent.activeView.renderDates;
        var workDays = this.parent.activeViewOptions.workDays;
        var monthCellSelector = '.' + WORK_CELLS_CLASS;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            monthCellSelector += '[data-group-index="' + index + '"]';
            var resourceData = this.parent.resourceBase.lastResourceLevel[parseInt(index.toString(), 10)];
            renderDates = resourceData.renderDates;
            workDays = resourceData.workDays;
        }
        monthEvent.dateRender = renderDates;
        monthEvent.workCells = [].slice.call(this.parent.element.querySelectorAll(monthCellSelector));
        monthEvent.cellWidth = monthEvent.workCells[0].offsetWidth;
        monthEvent.cellHeight = monthEvent.workCells[0].offsetHeight;
        monthEvent.eventHeight =
            getElementHeightFromClass(this.parent.monthModule.element || monthEvent.element, APPOINTMENT_CLASS);
        monthEvent.getSlotDates(workDays);
        var filteredDates = monthEvent.getRenderedDates(renderDates);
        var spannedEvents = monthEvent.splitEvent(saveObject, filteredDates || renderDates);
        for (var _i = 0, spannedEvents_1 = spannedEvents; _i < spannedEvents_1.length; _i++) {
            var eventData = spannedEvents_1[_i];
            monthEvent.renderEvents(eventData, index);
        }
        var inlineSubject = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        inlineSubject.focus();
    };
    InlineEdit.prototype.createTimelineViewInline = function (saveObj, start, end, resIndex) {
        var count = this.getEventDaysCount(saveObj);
        var saveObject = this.parent.eventBase.cloneEventObject(saveObj, start, end, count, false, false);
        var timelineView = new TimelineEvent(this.parent, this.parent.activeViewOptions.timeScale.enable ? 'hour' : 'day');
        timelineView.dateRender = this.parent.activeView.renderDates;
        timelineView.eventContainers = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
        var workCell = this.parent.element.querySelector('.' + WORK_CELLS_CLASS);
        timelineView.inlineValue = this.parent.allowInline;
        timelineView.cellWidth = workCell.offsetWidth;
        timelineView.cellHeight = workCell.offsetHeight;
        var dayLength = this.parent.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody tr').length === 0 ?
            0 : this.parent.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody tr')[0].children.length;
        timelineView.slotsPerDay = dayLength / timelineView.dateRender.length;
        timelineView.eventHeight = getElementHeightFromClass(timelineView.element, APPOINTMENT_CLASS);
        timelineView.renderEvents(saveObject, resIndex);
    };
    InlineEdit.prototype.getEventDaysCount = function (saveObj) {
        var startDate = saveObj[this.parent.eventFields.startTime];
        var endDate = saveObj[this.parent.eventFields.endTime];
        var daysCount = Math.abs(endDate.getTime() - startDate.getTime()) / MS_PER_DAY;
        return daysCount;
    };
    InlineEdit.prototype.generateEventData = function (target) {
        var inlineElement = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        var subject = inlineElement ? inlineElement.value : target ? target.innerHTML : '';
        var saveObj = {};
        saveObj[this.parent.eventFields.id] = this.parent.eventBase.getEventMaxID();
        saveObj[this.parent.eventFields.subject] = subject;
        saveObj[this.parent.eventFields.startTime] = this.parent.activeCellsData.startTime;
        saveObj[this.parent.eventFields.endTime] = this.parent.activeCellsData.endTime;
        saveObj[this.parent.eventFields.isAllDay] = this.parent.activeCellsData.isAllDay;
        this.parent.eventWindow.setDefaultValueToObject(saveObj);
        if (this.parent.resourceBase) {
            this.parent.resourceBase.setResourceValues(saveObj, this.parent.activeCellsData.groupIndex);
        }
        return saveObj;
    };
    InlineEdit.prototype.documentClick = function () {
        var target = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        if (target && target.value !== '') {
            this.inlineCrudActions(target);
        }
        else {
            this.removeInlineAppointmentElement();
        }
    };
    InlineEdit.prototype.inlineCrudActions = function (target) {
        if (closest(target, '.' + INLINE_APPOINTMENT_CLASS)) {
            var saveObj = this.generateEventData(target);
            this.parent.addEvent(saveObj);
        }
        else {
            var eventTarget = closest(target, '.' + APPOINTMENT_CLASS);
            var eventDetails = this.parent.getEventDetails(eventTarget);
            eventDetails[this.parent.eventFields.subject] = target.value;
            var currentAction = void 0;
            if (eventDetails[this.parent.eventFields.id] === eventDetails[this.parent.eventFields.recurrenceID]) {
                currentAction = 'EditOccurrence';
                eventDetails[this.parent.eventFields.id] = this.parent.eventBase.getEventMaxID();
            }
            this.parent.saveEvent(eventDetails, currentAction);
        }
        this.removeInlineAppointmentElement();
    };
    InlineEdit.prototype.createInlineAppointmentElement = function (inlineData) {
        var inlineAppointmentElement = createElement('div', {
            className: APPOINTMENT_CLASS + ' ' + INLINE_APPOINTMENT_CLASS
        });
        var inlineDetails = createElement('div', { className: APPOINTMENT_DETAILS });
        inlineAppointmentElement.appendChild(inlineDetails);
        var inline = createElement('input', { className: INLINE_SUBJECT_CLASS });
        inlineDetails.appendChild(inline);
        if (inlineData) {
            this.parent.eventBase.applyResourceColor(inlineAppointmentElement, inlineData, 'backgroundColor');
        }
        return inlineAppointmentElement;
    };
    InlineEdit.prototype.removeInlineAppointmentElement = function () {
        var inlineAppointment = [].slice.call(this.parent.element.querySelectorAll('.' + INLINE_APPOINTMENT_CLASS));
        if (inlineAppointment.length > 0) {
            inlineAppointment.forEach(function (node) { return remove(node); });
        }
        var inlineSubject = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        if (inlineSubject) {
            var appointmentSubject = closest(inlineSubject, '.' + APPOINTMENT_CLASS);
            var subject = appointmentSubject.querySelector('.' + SUBJECT_CLASS);
            if (!isNullOrUndefined(subject)) {
                removeClass([subject], DISABLE_CLASS);
            }
            remove(inlineSubject);
        }
    };
    InlineEdit.prototype.destroy = function () {
        this.parent.off(inlineClick, this.inlineEdit);
    };
    return InlineEdit;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Appointment window field validation
 */
var FieldValidator = /** @__PURE__ @class */ (function () {
    function FieldValidator() {
    }
    FieldValidator.prototype.renderFormValidator = function (form, rules, element, locale) {
        var _this = this;
        this.element = element;
        this.formObj = new FormValidator(form, {
            locale: locale,
            customPlacement: function (inputElement, error) {
                _this.errorPlacement(inputElement, error);
            },
            rules: rules,
            validationComplete: function (args) {
                _this.validationComplete(args);
            },
            focusout: function (args) {
                _this.focusOut(args);
            }
        });
    };
    FieldValidator.prototype.focusOut = function (args) {
        var target = args.relatedTarget;
        if (target && (target.classList.contains('e-dlg-closeicon-btn') || target.classList.contains('e-close')
            || target.classList.contains(ALLDAY_CELLS_CLASS) || target.classList.contains(HEADER_CELLS_CLASS)
            || target.classList.contains(QUICK_POPUP_EVENT_DETAILS_CLASS) || target.classList.contains(WORK_CELLS_CLASS)
            || target.classList.contains(EVENT_WINDOW_CANCEL_BUTTON_CLASS))) {
            this.ignoreError = true;
        }
        else {
            this.ignoreError = false;
        }
    };
    FieldValidator.prototype.validationComplete = function (args) {
        var elem = this.element.querySelector('#' + args.inputName + '_Error');
        if (elem) {
            elem.style.display = (args.status === 'failure') ? '' : 'none';
        }
    };
    FieldValidator.prototype.errorPlacement = function (inputElement, error) {
        var id = error.getAttribute('for');
        var elem = this.element.querySelector('#' + id + '_Error');
        if (!elem && !this.ignoreError) {
            this.createTooltip(inputElement, error, id, '');
        }
        if (!isNullOrUndefined(elem)) {
            elem.querySelector('.e-error').innerHTML = error.innerHTML;
        }
    };
    FieldValidator.prototype.createTooltip = function (element, error, name, display) {
        var dlgContent;
        var client;
        var inputClient = element.getBoundingClientRect();
        if (this.element.classList.contains(POPUP_WRAPPER_CLASS)) {
            dlgContent = this.element;
            client = this.element.getBoundingClientRect();
        }
        else {
            dlgContent = this.element.querySelector('.e-schedule-dialog .e-dlg-content');
            client = dlgContent.getBoundingClientRect();
        }
        var div = createElement('div', {
            className: 'e-tooltip-wrap e-popup ' + ERROR_VALIDATION_CLASS,
            id: name + '_Error',
            styles: 'display:' + display + ';top:' +
                (inputClient.bottom - client.top + dlgContent.scrollTop + 9) + 'px;left:' +
                (inputClient.left - client.left + dlgContent.scrollLeft + inputClient.width / 2) + 'px;'
        });
        var content = createElement('div', { className: 'e-tip-content' });
        content.appendChild(error);
        var arrow = createElement('div', { className: 'e-arrow-tip e-tip-top' });
        arrow.appendChild(createElement('div', { className: 'e-arrow-tip-outer e-tip-top' }));
        arrow.appendChild(createElement('div', { className: 'e-arrow-tip-inner e-tip-top' }));
        div.appendChild(content);
        div.appendChild(arrow);
        dlgContent.appendChild(div);
        div.style.left = (parseInt(div.style.left, 10) - div.offsetWidth / 2) + 'px';
    };
    FieldValidator.prototype.destroyToolTip = function () {
        if (this.element) {
            var elements = [].slice.call(this.element.querySelectorAll('.' + ERROR_VALIDATION_CLASS));
            for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
                var elem = elements_1[_i];
                remove(elem);
            }
        }
        if (this.formObj && this.formObj.element) {
            this.formObj.reset();
        }
    };
    FieldValidator.prototype.destroy = function () {
        if (this.formObj && this.formObj.element && !this.formObj.isDestroyed) {
            this.formObj.destroy();
        }
        this.formObj = null;
        this.element = null;
    };
    return FieldValidator;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
var EVENT_FIELD = 'e-field';
/**
 * Quick Popups interactions
 */
var QuickPopups = /** @__PURE__ @class */ (function () {
    function QuickPopups(parent) {
        this.isMultipleEventSelect = false;
        this.isCrudAction = false;
        this.parent = parent;
        this.l10n = this.parent.localeObj;
        this.fieldValidator = new FieldValidator();
        this.render();
        this.addEventListener();
    }
    QuickPopups.prototype.render = function () {
        this.renderQuickPopup();
        this.renderMorePopup();
        this.renderQuickDialog();
    };
    QuickPopups.prototype.renderQuickPopup = function () {
        var quickPopupWrapper = createElement('div', { className: POPUP_WRAPPER_CLASS + ' e-popup-close', attrs: { role: 'dialog' } });
        if (this.parent.isAdaptive) {
            document.body.appendChild(quickPopupWrapper);
            addClass([quickPopupWrapper], DEVICE_CLASS);
        }
        else {
            this.parent.element.appendChild(quickPopupWrapper);
        }
        this.quickPopup = new Popup(quickPopupWrapper, {
            targetType: (this.parent.isAdaptive ? 'container' : 'relative'),
            enableRtl: this.parent.enableRtl,
            open: this.quickPopupOpen.bind(this),
            close: this.quickPopupClose.bind(this),
            hideAnimation: (this.parent.isAdaptive ? { name: 'ZoomOut' } : { name: 'FadeOut', duration: 150 }),
            showAnimation: (this.parent.isAdaptive ? { name: 'ZoomIn' } : { name: 'FadeIn', duration: 150 }),
            collision: (this.parent.isAdaptive ? { X: 'fit', Y: 'fit' } :
                (this.parent.enableRtl ? { X: 'flip', Y: 'fit' } : { X: 'none', Y: 'fit' })),
            position: (this.parent.isAdaptive || this.parent.enableRtl ? { X: 'left', Y: 'top' } : { X: 'right', Y: 'top' }),
            viewPortElement: (this.parent.isAdaptive ? document.body : this.parent.element),
            zIndex: (this.parent.isAdaptive ? 1004 : 3)
        });
    };
    QuickPopups.prototype.renderMorePopup = function () {
        var moreEventPopup = "<div class=\"" + MORE_EVENT_POPUP_CLASS + "\"><div class=\"" + MORE_EVENT_HEADER_CLASS + "\">" +
            ("<div class=\"" + MORE_EVENT_CLOSE_CLASS + "\" title=\"" + this.l10n.getConstant('close') + "\" tabindex=\"0\"></div>") +
            ("<div class=\"" + MORE_EVENT_DATE_HEADER_CLASS + "\"><div class=\"" + MORE_EVENT_HEADER_DAY_CLASS + "\"></div>") +
            ("<div class=\"" + MORE_EVENT_HEADER_DATE_CLASS + " " + NAVIGATE_CLASS + "\" tabindex=\"0\"></div></div></div></div>");
        var moreEventWrapper = createElement('div', {
            className: MORE_POPUP_WRAPPER_CLASS + ' e-popup-close',
            innerHTML: moreEventPopup
        });
        if (this.parent.isAdaptive) {
            document.body.appendChild(moreEventWrapper);
            addClass([moreEventWrapper], DEVICE_CLASS);
        }
        else {
            this.parent.element.appendChild(moreEventWrapper);
        }
        this.morePopup = new Popup(moreEventWrapper, {
            targetType: (this.parent.isAdaptive ? 'container' : 'relative'),
            enableRtl: this.parent.enableRtl,
            hideAnimation: { name: 'ZoomOut', duration: 300 },
            showAnimation: { name: 'ZoomIn', duration: 300 },
            open: this.morePopupOpen.bind(this),
            close: this.morePopupClose.bind(this),
            collision: (this.parent.isAdaptive ? { X: 'fit', Y: 'fit' } :
                (this.parent.enableRtl ? { X: 'flip', Y: 'fit' } : { X: 'flip', Y: 'flip' })),
            viewPortElement: (this.parent.isAdaptive ? document.body : this.parent.element),
            zIndex: (this.parent.isAdaptive ? 1002 : 2)
        });
        var closeButton = this.morePopup.element.querySelector('.' + MORE_EVENT_CLOSE_CLASS);
        this.renderButton('e-round', ICON + ' ' + CLOSE_ICON_CLASS, false, closeButton, this.closeClick);
        EventHandler.add(this.morePopup.element.querySelector('.' + MORE_EVENT_HEADER_DATE_CLASS), 'click', this.navigationClick, this);
    };
    QuickPopups.prototype.renderQuickDialog = function () {
        var buttonModel = [
            { buttonModel: { cssClass: 'e-quick-alertok e-flat', isPrimary: true }, click: this.dialogButtonClick.bind(this) },
            { buttonModel: { cssClass: 'e-quick-alertcancel e-flat', isPrimary: false }, click: this.dialogButtonClick.bind(this) },
            {
                buttonModel: { cssClass: 'e-quick-dialog-cancel e-disable e-flat', isPrimary: false },
                click: this.dialogButtonClick.bind(this)
            }
        ];
        if (this.parent.eventSettings.editFollowingEvents) {
            var followingSeriesButton = {
                buttonModel: { cssClass: 'e-quick-alertfollowing e-flat', isPrimary: false }, click: this.dialogButtonClick.bind(this)
            };
            buttonModel.splice(1, 0, followingSeriesButton);
        }
        this.quickDialog = new Dialog({
            animationSettings: { effect: 'Zoom' },
            buttons: buttonModel,
            cssClass: QUICK_DIALOG_CLASS,
            closeOnEscape: true,
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            beforeClose: this.beforeQuickDialogClose.bind(this),
            isModal: true,
            position: { X: 'center', Y: 'center' },
            showCloseIcon: true,
            target: document.body,
            visible: false,
            width: 'auto'
        });
        var dialogElement = createElement('div', { id: this.parent.element.id + 'QuickDialog' });
        this.parent.element.appendChild(dialogElement);
        this.quickDialog.appendTo(dialogElement);
        var okButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        if (okButton) {
            okButton.setAttribute('aria-label', this.l10n.getConstant('occurrence'));
            okButton.setAttribute('aria-label', okButton.innerHTML);
        }
        var cancelButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        if (cancelButton) {
            cancelButton.setAttribute('aria-label', this.l10n.getConstant('series'));
            cancelButton.setAttribute('aria-label', cancelButton.innerHTML);
        }
        if (this.quickDialog.element.querySelector('.e-dlg-closeicon-btn')) {
            this.quickDialog.element.querySelector('.e-dlg-closeicon-btn').setAttribute('title', this.l10n.getConstant('close'));
        }
    };
    // eslint-disable-next-line max-len
    QuickPopups.prototype.renderButton = function (className, iconName, isDisabled, element, clickEvent) {
        var buttonObj = new Button({
            cssClass: className,
            disabled: isDisabled,
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            iconCss: iconName
        });
        buttonObj.appendTo(element);
        EventHandler.add(element, 'click', clickEvent, this);
        removeClass([element], ICON);
    };
    QuickPopups.prototype.quickDialogClass = function (action) {
        var classList$$1 = [
            QUICK_DIALOG_OCCURRENCE_CLASS, QUICK_DIALOG_SERIES_CLASS, QUICK_DIALOG_DELETE_CLASS,
            QUICK_DIALOG_CANCEL_CLASS, QUICK_DIALOG_ALERT_BTN_CLASS, DISABLE_CLASS
        ];
        var okButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        var cancelButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        var followingEventButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_FOLLOWING);
        removeClass([okButton, cancelButton], classList$$1);
        addClass([this.quickDialog.element.querySelector('.' + QUICK_DIALOG_CANCEL_CLASS)], DISABLE_CLASS);
        if (this.parent.eventSettings.editFollowingEvents) {
            addClass([followingEventButton], DISABLE_CLASS);
            removeClass([this.quickDialog.element], FOLLOWING_EVENTS_DIALOG);
        }
        switch (action) {
            case 'Recurrence':
                addClass([okButton], QUICK_DIALOG_OCCURRENCE_CLASS);
                addClass([cancelButton], QUICK_DIALOG_SERIES_CLASS);
                if (this.parent.eventSettings.editFollowingEvents) {
                    removeClass([followingEventButton], DISABLE_CLASS);
                    addClass([this.quickDialog.element], FOLLOWING_EVENTS_DIALOG);
                    addClass([followingEventButton], QUICK_DIALOG_FOLLOWING_EVENTS_CLASS);
                }
                break;
            case 'Delete':
                addClass([okButton], QUICK_DIALOG_DELETE_CLASS);
                addClass([cancelButton], QUICK_DIALOG_CANCEL_CLASS);
                break;
            case 'Alert':
                addClass([okButton], [QUICK_DIALOG_ALERT_OK, QUICK_DIALOG_ALERT_BTN_CLASS]);
                addClass([cancelButton], [QUICK_DIALOG_ALERT_CANCEL, DISABLE_CLASS]);
                break;
        }
    };
    QuickPopups.prototype.applyFormValidation = function () {
        var form = this.quickPopup.element.querySelector('.' + FORM_CLASS);
        var rules = {};
        rules[this.parent.eventSettings.fields.subject.name] = this.parent.eventSettings.fields.subject.validation;
        this.fieldValidator.renderFormValidator(form, rules, this.quickPopup.element, this.parent.locale);
    };
    QuickPopups.prototype.openRecurrenceAlert = function () {
        var editDeleteOnly = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        if (editDeleteOnly) {
            editDeleteOnly.innerHTML = this.l10n.getConstant(this.parent.currentAction === 'Delete' ? 'deleteEvent' : 'editEvent');
            editDeleteOnly.setAttribute('aria-label', editDeleteOnly.innerHTML);
        }
        var editFollowingEventsOnly = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_FOLLOWING);
        if (editFollowingEventsOnly) {
            editFollowingEventsOnly.innerHTML = this.l10n.getConstant('editFollowingEvent');
            editFollowingEventsOnly.setAttribute('aria-label', editFollowingEventsOnly.innerHTML);
        }
        var editDeleteSeries = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        if (editDeleteSeries) {
            editDeleteSeries.innerHTML = this.l10n.getConstant(this.parent.currentAction === 'Delete' ? 'deleteSeries' : 'editSeries');
            editDeleteSeries.setAttribute('aria-label', editDeleteSeries.innerHTML);
        }
        this.quickDialog.content = this.l10n.getConstant('editContent');
        this.quickDialog.header = this.l10n.getConstant(this.parent.currentAction === 'Delete' ? 'deleteTitle' : 'editTitle');
        this.quickDialogClass('Recurrence');
        this.showQuickDialog('RecurrenceAlert');
    };
    QuickPopups.prototype.openRecurrenceValidationAlert = function (type) {
        this.quickDialogClass('Alert');
        var okButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        okButton.innerHTML = this.l10n.getConstant('ok');
        okButton.setAttribute('aria-label', okButton.innerHTML);
        var cancelButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        cancelButton.innerHTML = this.l10n.getConstant('cancel');
        cancelButton.setAttribute('aria-label', cancelButton.innerHTML);
        this.quickDialog.header = this.l10n.getConstant('alert');
        var dialogCancel;
        switch (type) {
            case 'wrongPattern':
                addClass([cancelButton], DISABLE_CLASS);
                this.quickDialog.content = this.l10n.getConstant('wrongPattern');
                break;
            case 'createError':
                addClass([cancelButton], DISABLE_CLASS);
                this.quickDialog.content = this.l10n.getConstant('createError');
                break;
            case 'sameDayAlert':
                addClass([cancelButton], DISABLE_CLASS);
                this.quickDialog.content = this.l10n.getConstant('sameDayAlert');
                break;
            case 'seriesChangeAlert':
                dialogCancel = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_CANCEL_CLASS);
                removeClass([cancelButton, dialogCancel], DISABLE_CLASS);
                this.quickDialog.content = this.l10n.getConstant('seriesChangeAlert');
                okButton.innerHTML = this.l10n.getConstant('yes');
                cancelButton.innerHTML = this.l10n.getConstant('no');
                dialogCancel.innerHTML = this.l10n.getConstant('cancel');
                break;
            case 'occurrenceAlert':
                addClass([cancelButton], DISABLE_CLASS);
                this.quickDialog.content = this.l10n.getConstant('occurenceAlert');
                break;
        }
        if ((!this.parent.enableRecurrenceValidation && type === 'wrongPattern') || this.parent.enableRecurrenceValidation) {
            this.showQuickDialog('RecurrenceValidationAlert');
        }
    };
    QuickPopups.prototype.openDeleteAlert = function () {
        if (this.parent.activeViewOptions.readonly) {
            return;
        }
        var okButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        if (okButton) {
            okButton.innerHTML = this.l10n.getConstant('delete');
            okButton.setAttribute('aria-label', okButton.innerHTML);
        }
        var cancelButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        if (cancelButton) {
            cancelButton.innerHTML = this.l10n.getConstant('cancel');
            cancelButton.setAttribute('aria-label', cancelButton.innerHTML);
        }
        this.quickDialog.content = (this.parent.activeEventData.event.length > 1) ?
            this.l10n.getConstant('deleteMultipleContent') : this.l10n.getConstant('deleteContent');
        this.quickDialog.header = (this.parent.activeEventData.event.length > 1) ?
            this.l10n.getConstant('deleteMultipleEvent') : this.l10n.getConstant('deleteEvent');
        this.quickDialogClass('Delete');
        this.showQuickDialog('DeleteAlert');
    };
    QuickPopups.prototype.openValidationError = function (type, eventData) {
        this.quickDialog.header = this.l10n.getConstant('alert');
        this.quickDialog.content = this.l10n.getConstant(type);
        var okButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_OK);
        if (okButton) {
            okButton.innerHTML = this.l10n.getConstant('ok');
            okButton.setAttribute('aria-label', okButton.innerHTML);
        }
        var cancelButton = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        if (cancelButton) {
            cancelButton.innerHTML = this.l10n.getConstant('cancel');
            okButton.setAttribute('aria-label', cancelButton.innerHTML);
        }
        this.quickDialogClass('Alert');
        this.showQuickDialog('ValidationAlert', eventData);
    };
    QuickPopups.prototype.showQuickDialog = function (popupType, eventData) {
        var _this = this;
        this.quickDialog.dataBind();
        var eventProp = {
            type: popupType, cancel: false, element: this.quickDialog.element,
            data: extend({}, (eventData || this.parent.activeEventData.event), null, true)
        };
        this.parent.trigger(popupOpen, eventProp, function (popupArgs) {
            if (!popupArgs.cancel) {
                _this.quickDialog.show();
            }
        });
    };
    QuickPopups.prototype.createMoreEventList = function (eventCollection, groupOrder, groupIndex) {
        var _this = this;
        var fields = this.parent.eventFields;
        var moreEventContentEle = createElement('div', { className: MORE_EVENT_CONTENT_CLASS });
        var moreEventWrapperEle = createElement('div', { className: MORE_EVENT_WRAPPER_CLASS });
        if (eventCollection.length === 0) {
            moreEventWrapperEle = createElement('div', {
                className: MORE_EVENT_CONTENT_CLASS,
                innerHTML: this.l10n.getConstant('emptyContainer')
            });
        }
        else {
            var _loop_1 = function (eventData) {
                var eventText = (eventData[fields.subject] || this_1.parent.eventSettings.fields.subject.default
                    || this_1.parent.localeObj.getConstant('addTitle'));
                var appointmentElement = createElement('div', {
                    className: APPOINTMENT_CLASS,
                    attrs: {
                        'data-id': '' + eventData[fields.id],
                        'data-guid': eventData.Guid, 'role': 'button', 'tabindex': '0',
                        'aria-disabled': this_1.parent.eventBase.getReadonlyAttribute(eventData),
                        'aria-label': this_1.parent.getAnnouncementString(eventData)
                    }
                });
                if (eventData[fields.isReadonly]) {
                    addClass([appointmentElement], 'e-read-only');
                }
                var templateElement = void 0;
                if (!isNullOrUndefined(this_1.parent.activeViewOptions.eventTemplate)) {
                    var tempId = this_1.parent.element.id + '_' + this_1.parent.activeViewOptions.eventTemplateName + 'eventTemplate';
                    templateElement = this_1.parent.getAppointmentTemplate()(eventData, this_1.parent, 'eventTemplate', tempId, false);
                    append(templateElement, appointmentElement);
                }
                else {
                    appointmentElement.appendChild(createElement('div', { className: SUBJECT_CLASS }));
                    this_1.parent.sanitize(eventText, appointmentElement.firstElementChild);
                }
                if (!isNullOrUndefined(groupIndex)) {
                    appointmentElement.setAttribute('data-group-index', groupIndex);
                }
                if (!isNullOrUndefined(eventData[fields.recurrenceRule])) {
                    var iconClass = (eventData[fields.id] === eventData[fields.recurrenceID]) ?
                        EVENT_RECURRENCE_ICON_CLASS : EVENT_RECURRENCE_EDIT_ICON_CLASS;
                    appointmentElement.appendChild(createElement('div', { className: ICON + ' ' + iconClass }));
                }
                var args = {
                    data: extend({}, eventData, null, true),
                    element: appointmentElement, cancel: false
                };
                this_1.parent.trigger(eventRendered, args, function (eventArgs) {
                    if (!eventArgs.cancel) {
                        moreEventWrapperEle.appendChild(appointmentElement);
                        var isPreventCrud = _this.parent.isAdaptive || _this.parent.currentView === 'Year';
                        _this.parent.eventBase.wireAppointmentEvents(appointmentElement, eventData, isPreventCrud);
                        _this.parent.eventBase.applyResourceColor(appointmentElement, eventData, 'backgroundColor', groupOrder);
                    }
                });
            };
            var this_1 = this;
            for (var _i = 0, eventCollection_1 = eventCollection; _i < eventCollection_1.length; _i++) {
                var eventData = eventCollection_1[_i];
                _loop_1(eventData);
            }
        }
        moreEventContentEle.appendChild(moreEventWrapperEle);
        return moreEventContentEle;
    };
    QuickPopups.prototype.tapHoldEventPopup = function (e) {
        var target = closest(e.target, '.' + APPOINTMENT_CLASS);
        this.isMultipleEventSelect = false;
        this.parent.selectedElements = [];
        this.isMultipleEventSelect = true;
        this.parent.eventBase.getSelectedEventElements(target);
        this.parent.activeEventData = this.parent.eventBase.getSelectedEvents();
        var guid = target.getAttribute('data-guid');
        var eventObj = this.parent.eventBase.getEventByGuid(guid);
        if (isNullOrUndefined(eventObj)) {
            return;
        }
        var eventTitle = (eventObj[this.parent.eventFields.subject] || this.l10n.getConstant('noTitle'));
        var eventTemplate = "<div class=\"" + MULTIPLE_EVENT_POPUP_CLASS + "\"><div class=\"" + POPUP_HEADER_CLASS + "\">" +
            ("<button class=\"" + CLOSE_CLASS + "\" title=\"" + this.l10n.getConstant('close') + "\"></button>") +
            ("<div class=\"" + SUBJECT_CLASS + "\">" + eventTitle + "</div>") +
            ("<button class=\"" + EDIT_CLASS + "\" title=\"" + this.l10n.getConstant('edit') + "\"></button>") +
            ("<button class=\"" + DELETE_CLASS + "\" title=\"" + this.l10n.getConstant('delete') + "\"></button></div></div>");
        this.quickPopup.element.innerHTML = eventTemplate;
        var closeIcon = this.quickPopup.element.querySelector('.' + CLOSE_CLASS);
        this.renderButton('e-flat e-round e-small', ICON + ' ' + CLOSE_ICON_CLASS, false, closeIcon, this.closeClick);
        var readonly = this.parent.activeViewOptions.readonly || eventObj[this.parent.eventFields.isReadonly];
        var editAction = !this.parent.eventSettings.allowEditing || readonly;
        var deleteAction = !this.parent.eventSettings.allowDeleting || readonly;
        var editIcon = this.quickPopup.element.querySelector('.' + EDIT_CLASS);
        if (editIcon) {
            this.renderButton('e-flat e-round e-small', ICON + ' ' + EDIT_ICON_CLASS, editAction, editIcon, this.editClick);
        }
        var deleteIcon = this.quickPopup.element.querySelector('.' + DELETE_CLASS);
        if (deleteIcon) {
            this.renderButton('e-flat e-round e-small', ICON + ' ' + DELETE_ICON_CLASS, deleteAction, deleteIcon, this.deleteClick);
        }
        this.beforeQuickPopupOpen(target, this.parent.eventBase.getPageCoordinates(e));
    };
    QuickPopups.prototype.isCellBlocked = function (args) {
        var tempObj = {};
        tempObj[this.parent.eventFields.startTime] = this.parent.activeCellsData.startTime;
        tempObj[this.parent.eventFields.endTime] = this.parent.activeCellsData.endTime;
        tempObj[this.parent.eventFields.isAllDay] = this.parent.activeCellsData.isAllDay;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var targetCell = args.element instanceof Array ? args.element[0] : args.element;
            var groupIndex = parseInt(targetCell.getAttribute('data-group-index'), 10);
            this.parent.resourceBase.setResourceValues(tempObj, isNaN(groupIndex) ? null : groupIndex);
        }
        return this.parent.eventBase.isBlockRange(tempObj);
    };
    QuickPopups.prototype.cellClick = function (args) {
        var date = new Date(args.startTime.getTime());
        if (!this.parent.showQuickInfo || !this.parent.eventSettings.allowAdding ||
            this.parent.currentView === 'MonthAgenda' || this.isCellBlocked(args) ||
            !this.parent.isMinMaxDate(new Date(date.setHours(0, 0, 0, 0)))) {
            this.quickPopupHide();
            return;
        }
        var targetEle = !isNullOrUndefined(args.event) ? args.event.target : args.element;
        if (this.parent.isAdaptive) {
            this.quickPopupHide();
            var newEventClone = this.parent.element.querySelector('.' + NEW_EVENT_CLASS);
            if (isNullOrUndefined(newEventClone)) {
                newEventClone = createElement('div', {
                    className: NEW_EVENT_CLASS,
                    innerHTML: "<div class=\"e-title\">+ " + this.l10n.getConstant('newEvent') + "</div>"
                });
            }
            var targetCell = closest(targetEle, '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS);
            if (targetCell) {
                targetCell.appendChild(newEventClone);
            }
            return;
        }
        var target = closest(targetEle, '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS + ',.' +
            HEADER_CELLS_CLASS);
        if (isNullOrUndefined(target) || targetEle.classList.contains(MORE_INDICATOR_CLASS)) {
            return;
        }
        var isSameTarget = this.quickPopup.relateTo === target;
        if (isSameTarget && this.quickPopup.element.classList.contains(POPUP_OPEN)) {
            var subjectElement_1 = this.quickPopup.element.querySelector('.' + SUBJECT_CLASS);
            if (subjectElement_1) {
                subjectElement_1.focus();
            }
            return;
        }
        else if (this.quickPopup.element) {
            this.destroyPopupButtons('quickPopup');
        }
        var temp = {};
        temp[this.parent.eventFields.startTime] = this.parent.activeCellsData.startTime;
        temp[this.parent.eventFields.endTime] = this.parent.activeCellsData.endTime;
        temp[this.parent.eventFields.isAllDay] = this.parent.activeCellsData.isAllDay;
        var quickCellPopup = createElement('div', { className: CELL_POPUP_CLASS });
        quickCellPopup.appendChild(this.getPopupHeader('Cell', temp));
        quickCellPopup.appendChild(this.getPopupContent('Cell', args, temp));
        quickCellPopup.appendChild(this.getPopupFooter('Cell', temp));
        this.quickPopup.element.setAttribute('aria-label', this.l10n.getConstant('newEvent'));
        var subjectElement = quickCellPopup.querySelector('.' + SUBJECT_CLASS);
        if (subjectElement) {
            Input.createInput({ element: subjectElement, properties: { placeholder: this.l10n.getConstant('addTitle') } });
        }
        if (!isNullOrUndefined(this.parent.eventSettings.fields.subject.default)) {
            subjectElement.value = this.parent.eventSettings.fields.subject.default;
        }
        var closeIcon = quickCellPopup.querySelector('.' + CLOSE_CLASS);
        if (closeIcon) {
            this.renderButton('e-flat e-round e-small', ICON + ' ' + CLOSE_ICON_CLASS, false, closeIcon, this.popupClose);
        }
        var moreButton = quickCellPopup.querySelector('.' + QUICK_POPUP_EVENT_DETAILS_CLASS);
        if (moreButton) {
            this.renderButton('e-flat', '', false, moreButton, this.detailsClick);
        }
        var saveButton = quickCellPopup.querySelector('.' + EVENT_CREATE_CLASS);
        if (saveButton) {
            this.renderButton('e-flat e-primary', '', this.parent.activeViewOptions.readonly, saveButton, this.saveClick);
        }
        if (this.morePopup) {
            this.morePopup.hide();
        }
        this.quickPopup.content = quickCellPopup;
        this.quickPopup.relateTo = target;
        this.quickPopup.dataBind();
        this.beforeQuickPopupOpen(target, this.parent.eventBase.getPageCoordinates(args.event));
    };
    QuickPopups.prototype.isSameEventClick = function (events) {
        var isSameTarget = this.quickPopup.relateTo === closest(events.element, '.' + APPOINTMENT_CLASS);
        if (isSameTarget && this.quickPopup.element.classList.contains(POPUP_OPEN)) {
            var editIcon = this.quickPopup.element.querySelector('.' + EDIT_CLASS);
            if (editIcon) {
                editIcon.focus();
            }
            if (!this.parent.isAdaptive) {
                var editButton = this.quickPopup.element.querySelector('.' + EDIT_EVENT_CLASS);
                if (editButton) {
                    editButton.focus();
                }
            }
            return true;
        }
        return false;
    };
    QuickPopups.prototype.isQuickTemplate = function (type) {
        return this.parent.quickInfoTemplates.templateType === 'Both' || this.parent.quickInfoTemplates.templateType === type;
    };
    QuickPopups.prototype.eventClick = function (events) {
        if (this.parent.eventTooltip) {
            this.parent.eventTooltip.close();
        }
        if (!this.parent.showQuickInfo) {
            return;
        }
        if (this.parent.isAdaptive && this.isMultipleEventSelect) {
            this.updateTapHoldEventPopup(closest(events.element, '.' + APPOINTMENT_CLASS));
        }
        else {
            var isSameTarget = this.isSameEventClick(events);
            this.parent.selectedElements = [];
            if (isSameTarget) {
                return;
            }
            else if (this.quickPopup.element) {
                this.destroyPopupButtons('quickPopup');
            }
            var eventData = events.event;
            var quickEventPopup = createElement('div', { className: EVENT_POPUP_CLASS });
            quickEventPopup.appendChild(this.getPopupHeader('Event', eventData));
            quickEventPopup.appendChild(this.getPopupContent('Event', events, eventData));
            quickEventPopup.appendChild(this.getPopupFooter('Event', eventData));
            this.quickPopup.element.setAttribute('aria-label', this.l10n.getConstant('editEvent'));
            var readonly = this.parent.activeViewOptions.readonly || eventData[this.parent.eventFields.isReadonly];
            var editAction = !this.parent.eventSettings.allowEditing || readonly;
            var deleteAction = !this.parent.eventSettings.allowDeleting || readonly;
            var editIcon = quickEventPopup.querySelector('.' + EDIT_CLASS);
            var buttonClass = 'e-flat e-round e-small';
            if (editIcon) {
                this.renderButton(buttonClass, ICON + ' ' + EDIT_ICON_CLASS, editAction, editIcon, this.editClick);
            }
            var deleteIcon = quickEventPopup.querySelector('.' + DELETE_CLASS);
            if (deleteIcon) {
                this.renderButton(buttonClass, ICON + ' ' + DELETE_ICON_CLASS, deleteAction, deleteIcon, this.deleteClick);
            }
            var closeIcon = quickEventPopup.querySelector('.' + CLOSE_CLASS);
            if (closeIcon) {
                this.renderButton(buttonClass, ICON + ' ' + CLOSE_ICON_CLASS, false, closeIcon, this.popupClose);
            }
            var editButton = quickEventPopup.querySelector('.' + EDIT_EVENT_CLASS);
            if (editButton) {
                this.renderButton('e-flat e-primary', '', editAction, editButton, this.editClick);
            }
            var deleteButton = quickEventPopup.querySelector('.' + DELETE_EVENT_CLASS);
            if (deleteButton) {
                this.renderButton('e-flat', '', deleteAction, deleteButton, this.deleteClick);
            }
            if (this.morePopup && !closest(events.element, '.' + MORE_EVENT_WRAPPER_CLASS)) {
                this.morePopup.hide();
            }
            this.quickPopup.content = quickEventPopup;
            this.quickPopup.relateTo = this.parent.isAdaptive ? document.body :
                closest(events.element, '.' + APPOINTMENT_CLASS);
            this.quickPopup.dataBind();
            this.beforeQuickPopupOpen(events.element, this.parent.eventBase.getPageCoordinates(events.originalEvent));
        }
    };
    QuickPopups.prototype.getPopupHeader = function (headerType, headerData) {
        var headerTemplate = createElement('div', { className: POPUP_HEADER_CLASS });
        if (this.isQuickTemplate(headerType) && this.parent.quickInfoTemplates.header) {
            var headerArgs = extend({}, headerData, { elementType: headerType.toLowerCase() }, true);
            var templateId = this.parent.element.id;
            var headerTemp = [].slice.call(this.parent.getQuickInfoTemplatesHeader()(headerArgs, this.parent, 'header', templateId + '_headerTemplate', false));
            append([].slice.call(headerTemp), headerTemplate);
        }
        else {
            var header = void 0;
            var args = void 0;
            switch (headerType) {
                case 'Cell':
                    header = "<div class=\"" + POPUP_HEADER_ICON_WRAPPER + "\"><button class=\"" + CLOSE_CLASS + "\" title=" +
                        ("\"" + this.l10n.getConstant('close') + "\"></button></div>");
                    break;
                case 'Event':
                    args = this.getFormattedString(headerData);
                    header = "<div class=\"" + POPUP_HEADER_ICON_WRAPPER + "\">" +
                        ("<button class=\"" + (EDIT_CLASS + ' ' + ICON) + "\" title=\"" + this.l10n.getConstant('edit') + "\"></button>") +
                        ("<button class=\"" + (DELETE_CLASS + ' ' + ICON) + "\" title=\"" + this.l10n.getConstant('delete') + "\"></button>") +
                        ("<button class=\"" + CLOSE_CLASS + "\" title=\"" + this.l10n.getConstant('close') + "\"></button></div>") +
                        ("<div class=\"" + SUBJECT_WRAP + "\"><div class=\"" + SUBJECT_CLASS + " " + TEXT_ELLIPSIS + "\" ") +
                        ("title=\"" + (args.eventSubject ? args.eventSubject.replaceAll('"', '\'') : args.eventSubject) + "\"></div></div >");
                    break;
            }
            var templateWrapper = createElement('div', { innerHTML: header });
            if (headerType === 'Event') {
                var subjectText = templateWrapper.querySelector('.' + SUBJECT_CLASS);
                this.parent.sanitize(args.eventSubject, subjectText);
            }
            append([].slice.call(templateWrapper.childNodes), headerTemplate);
        }
        return headerTemplate;
    };
    QuickPopups.prototype.getPopupContent = function (type, args, data) {
        var contentTemplate = createElement('div', { className: POPUP_CONTENT_CLASS });
        if (this.isQuickTemplate(type) && this.parent.quickInfoTemplates.content) {
            var contentArgs = extend({}, data, { elementType: type.toLowerCase() }, true);
            var templateId = this.parent.element.id;
            var contentTemp = [].slice.call(this.parent.getQuickInfoTemplatesContent()(contentArgs, this.parent, 'content', templateId + '_contentTemplate', false));
            append([].slice.call(contentTemp), contentTemplate);
        }
        else {
            var content = void 0;
            var cellDetails = void 0;
            var argsData = void 0;
            var resourceText = this.getResourceText(args, type.toLowerCase());
            switch (type) {
                case 'Cell':
                    cellDetails = this.getFormattedString(data);
                    content = "<table class=\"" + POPUP_TABLE_CLASS + "\"><tbody><tr><td><form class=\"" + FORM_CLASS + "\" onsubmit=" +
                        ("\"return false;\"><input class=\"" + SUBJECT_CLASS + " " + EVENT_FIELD + "\" type=\"text\" name=") +
                        ("\"" + this.parent.eventFields.subject + "\" /></form></td></tr><tr><td><div class=\"" + DATE_TIME_CLASS + "\">") +
                        ("<div class=\"" + DATE_TIME_ICON_CLASS + " " + ICON + "\"></div><div class=\"" + DATE_TIME_DETAILS_CLASS + " ") +
                        (TEXT_ELLIPSIS + "\">" + cellDetails.details + "</div></div>") +
                        ((this.parent.activeViewOptions.group.resources.length > 0 ? "<div class=\"" + RESOURCE_CLASS + "\">" +
                            ("<div class=\"" + RESOURCE_ICON_CLASS + " " + ICON + " \"></div><div class=\"" + RESOURCE_DETAILS_CLASS + " ") +
                            (TEXT_ELLIPSIS + "\"></div></div>") : '') + "</td></tr></tbody></table>");
                    break;
                case 'Event':
                    argsData = this.getFormattedString(data);
                    content = '<div class="' + DATE_TIME_CLASS + '"><div class="' + DATE_TIME_ICON_CLASS + ' ' + ICON +
                        '"></div><div class="' + DATE_TIME_WRAPPER_CLASS + ' ' + TEXT_ELLIPSIS + '"><div class="' +
                        DATE_TIME_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS + '">' + argsData.details + '</div>';
                    if (data[this.parent.eventFields.recurrenceRule]) {
                        content += '<div class="' + RECURRENCE_SUMMARY_CLASS + ' ' + TEXT_ELLIPSIS + '">' +
                            this.getRecurrenceSummary(data) + '</div>';
                    }
                    content += '</div></div>';
                    if (data[this.parent.eventFields.location]) {
                        content += '<div class="' + LOCATION_CLASS + '"><div class="' + LOCATION_ICON_CLASS + ' ' +
                            ICON + '"></div><div class="' + LOCATION_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS + '"></div></div>';
                    }
                    if (data[this.parent.eventFields.startTimezone] || data[this.parent.eventFields.endTimezone]) {
                        content += '<div class="' + TIME_ZONE_CLASS + '"><div class="' + TIME_ZONE_ICON_CLASS + ' ' + ICON +
                            '"></div><div class="' + TIME_ZONE_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS + '">' +
                            this.getTimezone(data) + '</div></div>';
                    }
                    if (data[this.parent.eventFields.description]) {
                        content += '<div class="' + DESCRIPTION_CLASS + '"><div class="' + DESCRIPTION_ICON_CLASS + ' ' + ICON +
                            '"></div><div class="' + DESCRIPTION_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS + '"></div></div>';
                    }
                    if (this.parent.resourceCollection.length > 0) {
                        content += '<div class="' + RESOURCE_CLASS + '"><div class="' + RESOURCE_ICON_CLASS + ' ' + ICON +
                            '"></div><div class="' + RESOURCE_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS + '"></div></div>';
                    }
                    break;
            }
            var templateWrapper = createElement('div', { innerHTML: content });
            if (data[this.parent.eventFields.location]) {
                var locationDetails = templateWrapper.querySelector('.' + LOCATION_DETAILS_CLASS);
                if (!isNullOrUndefined(locationDetails)) {
                    this.parent.sanitize(data[this.parent.eventFields.location], locationDetails);
                }
            }
            if (data[this.parent.eventFields.description]) {
                var descriptionDetails = templateWrapper.querySelector('.' + DESCRIPTION_DETAILS_CLASS);
                if (!isNullOrUndefined(descriptionDetails)) {
                    this.parent.sanitize(data[this.parent.eventFields.description], descriptionDetails);
                }
            }
            if (resourceText) {
                var resourceDetails = templateWrapper.querySelector('.' + RESOURCE_DETAILS_CLASS);
                if (!isNullOrUndefined(resourceDetails)) {
                    this.parent.sanitize(resourceText, resourceDetails);
                }
            }
            append([].slice.call(templateWrapper.childNodes), contentTemplate);
        }
        return contentTemplate;
    };
    QuickPopups.prototype.getPopupFooter = function (footerType, footerData) {
        var footerTemplate = createElement('div', { className: POPUP_FOOTER_CLASS });
        if (this.isQuickTemplate(footerType) && this.parent.quickInfoTemplates.footer) {
            var footerArgs = extend({}, footerData, { elementType: footerType.toLowerCase() }, true);
            var templateId = this.parent.element.id;
            var footerTemp = [].slice.call(this.parent.getQuickInfoTemplatesFooter()(footerArgs, this.parent, 'footer', templateId + '_footerTemplate', false));
            append([].slice.call(footerTemp), footerTemplate);
        }
        else {
            var footer = void 0;
            switch (footerType) {
                case 'Cell':
                    footer = "<button class=\"" + (QUICK_POPUP_EVENT_DETAILS_CLASS + ' ' + TEXT_ELLIPSIS) + "\" title=" +
                        ("\"" + this.l10n.getConstant('moreDetails') + "\">" + this.l10n.getConstant('moreDetails') + "</button>") +
                        ("<button class=\"" + EVENT_CREATE_CLASS + " " + TEXT_ELLIPSIS + "\" title=\"" + this.l10n.getConstant('save') + "\">") +
                        (this.l10n.getConstant('save') + "</button>");
                    break;
                case 'Event':
                    footer = "" + (this.parent.isAdaptive ? '' : "<button class=\"" + EDIT_EVENT_CLASS + " " +
                        (TEXT_ELLIPSIS + "\" title=\"" + this.l10n.getConstant('edit') + "\">" + this.l10n.getConstant('edit') + "</button>") +
                        ("<button class=\"" + DELETE_EVENT_CLASS + " " + TEXT_ELLIPSIS + "\" title=\"" + this.l10n.getConstant('delete') + "\">") +
                        (this.l10n.getConstant('delete') + "</button>"));
                    break;
            }
            var templateWrapper = createElement('div', { innerHTML: footer });
            append([].slice.call(templateWrapper.childNodes), footerTemplate);
        }
        return footerTemplate;
    };
    QuickPopups.prototype.getResourceText = function (args, type) {
        if (this.parent.resourceCollection.length === 0) {
            return null;
        }
        var resourceValue = '';
        if (this.parent.activeViewOptions.group.resources.length === 0) {
            var resourceCollection_1 = this.parent.resourceBase.resourceCollection.slice(-1)[0];
            var resourceData = resourceCollection_1.dataSource;
            if (type === 'event') {
                var eventData = args.event;
                var _loop_2 = function (data) {
                    var resourceId = eventData[resourceCollection_1.field];
                    if (resourceId instanceof Array) {
                        if (resourceId.indexOf(data[resourceCollection_1.idField]) > -1) {
                            var id_1 = resourceId[resourceId.indexOf(data[resourceCollection_1.idField])];
                            var resource = resourceData.filter(function (e) {
                                return e[resourceCollection_1.idField] === id_1;
                            })[0];
                            resourceValue += (resourceValue === '') ? resource[resourceCollection_1.textField] :
                                ', ' + resource[resourceCollection_1.textField];
                        }
                    }
                    else if (data[resourceCollection_1.idField] === resourceId) {
                        resourceValue = data[resourceCollection_1.textField].toString();
                    }
                };
                for (var _i = 0, resourceData_1 = resourceData; _i < resourceData_1.length; _i++) {
                    var data = resourceData_1[_i];
                    _loop_2(data);
                }
            }
            else {
                resourceValue = resourceData[0][resourceCollection_1.textField].toString();
            }
        }
        else {
            if (type === 'event') {
                var eventData = args.event;
                var resourceData = void 0;
                var lastResource_1;
                for (var i = this.parent.resourceBase.resourceCollection.length - 1; i >= 0; i--) {
                    resourceData = eventData[this.parent.resourceBase.resourceCollection[parseInt(i.toString(), 10)].field];
                    if (!isNullOrUndefined(resourceData)) {
                        lastResource_1 = this.parent.resourceBase.resourceCollection[parseInt(i.toString(), 10)];
                        break;
                    }
                }
                if (!Array.isArray(resourceData)) {
                    resourceData = [resourceData];
                }
                var resNames_1 = [];
                var lastResourceData_1 = lastResource_1.dataSource;
                resourceData.forEach(function (value) {
                    var text;
                    var i = findIndexInData(lastResourceData_1, lastResource_1.idField, value);
                    if (i > -1) {
                        text = lastResourceData_1[parseInt(i.toString(), 10)][lastResource_1.textField];
                    }
                    if (text) {
                        resNames_1.push(text);
                    }
                });
                resourceValue = resNames_1.join(', ');
            }
            else {
                var argsData = args;
                var groupIndex = !isNullOrUndefined(argsData.groupIndex) ? argsData.groupIndex : 0;
                var resourceDetails = this.parent.resourceBase.lastResourceLevel[parseInt(groupIndex.toString(), 10)];
                resourceValue = resourceDetails.resourceData[resourceDetails.resource.textField];
            }
        }
        return resourceValue;
    };
    QuickPopups.prototype.getFormattedString = function (eventData) {
        var fields = this.parent.eventFields;
        var eventSubject = (eventData[fields.subject] || this.l10n.getConstant('noTitle'));
        var startDate = eventData[fields.startTime];
        var endDate = eventData[fields.endTime];
        var startDateDetails = this.getDateFormat(startDate, 'long');
        var endDateDetails = (eventData[fields.isAllDay] && endDate.getHours() === 0 && endDate.getMinutes() === 0) ?
            this.getDateFormat(addDays(new Date(endDate.getTime()), -1), 'long') :
            this.getDateFormat(endDate, 'long');
        var startTimeDetail = this.parent.getTimeString(startDate);
        var endTimeDetail = this.parent.getTimeString(endDate);
        var details = '';
        var spanLength = endDate.getDate() !== startDate.getDate() &&
            (endDate.getTime() - startDate.getTime()) / (60 * 60 * 1000) < 24 ? 1 : 0;
        if (eventData[fields.isAllDay]) {
            details = startDateDetails + ' (' + this.l10n.getConstant('allDay') + ')';
            if (((getUniversalTime(endDate) - getUniversalTime(startDate)) / MS_PER_DAY) > 1) {
                details += '&nbsp;-&nbsp;' + endDateDetails + ' (' + this.l10n.getConstant('allDay') + ')';
            }
        }
        else if ((((getUniversalTime(endDate) - getUniversalTime(startDate)) / MS_PER_DAY) >= 1) || spanLength > 0) {
            details = startDateDetails + ' (' + startTimeDetail + ')' + '&nbsp;-&nbsp;' + endDateDetails + ' (' + endTimeDetail + ')';
        }
        else {
            details = startDateDetails + ' (' + (startTimeDetail + '&nbsp;-&nbsp;' + endTimeDetail) + ')';
        }
        return { eventSubject: eventSubject, details: details };
    };
    QuickPopups.prototype.moreEventClick = function (data, endDate, groupIndex) {
        var _this = this;
        this.quickPopupHide(true);
        var moreEventContentEle = this.morePopup.element.querySelector('.' + MORE_EVENT_CONTENT_CLASS);
        if (moreEventContentEle) {
            remove(moreEventContentEle);
        }
        var selectedDate = ((data.date).getTime()).toString();
        var target = closest(data.element, '.' + MORE_INDICATOR_CLASS + ',.' + WORK_CELLS_CLASS);
        var day = this.parent.globalize.formatDate(data.date, { format: 'E', calendar: this.parent.getCalendarMode() });
        this.morePopup.element.querySelector('.' + MORE_EVENT_HEADER_DAY_CLASS).innerHTML = capitalizeFirstWord(day, 'single');
        var dateElement = this.morePopup.element.querySelector('.' + MORE_EVENT_HEADER_DATE_CLASS);
        dateElement.innerHTML = this.getDateFormat(data.date, 'd');
        dateElement.setAttribute('data-date', selectedDate);
        dateElement.setAttribute('data-end-date', endDate.getTime().toString());
        var groupOrder;
        if (!isNullOrUndefined(groupIndex)) {
            dateElement.setAttribute('data-group-index', groupIndex);
            groupOrder = this.parent.resourceBase.lastResourceLevel[parseInt(groupIndex, 10)].groupOrder;
        }
        var moreEventElements = this.createMoreEventList(data.event, groupOrder, groupIndex);
        this.morePopup.element.querySelector('.' + MORE_EVENT_POPUP_CLASS).appendChild(moreEventElements);
        removeClass(this.morePopup.element.querySelector('.' + MORE_EVENT_DATE_HEADER_CLASS).childNodes, CURRENTDATE_CLASS);
        if (resetTime(data.date).getTime() === resetTime(this.parent.getCurrentTime()).getTime()) {
            addClass(this.morePopup.element.querySelector('.' + MORE_EVENT_DATE_HEADER_CLASS).childNodes, CURRENTDATE_CLASS);
        }
        if (!this.parent.isAdaptive) {
            if (this.parent.currentView.indexOf('Timeline') !== -1) {
                var gIndex = target.getAttribute('data-group-index');
                var startDate = new Date(parseInt(target.getAttribute('data-start-date'), 10));
                startDate.setHours(startDate.getHours(), startDate.getMinutes(), 0);
                var tdDate = startDate.getTime().toString();
                if (isNullOrUndefined(gIndex)) {
                    this.morePopup.relateTo = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS +
                        ' tbody tr td[data-date="' + tdDate + '"]');
                }
                else {
                    this.morePopup.relateTo = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS +
                        ' tbody tr td[data-group-index="' + gIndex + '"][data-date="' + tdDate + '"]');
                    if (isNullOrUndefined(this.morePopup.relateTo)) {
                        var workCells = [].slice.call(this.parent.element.querySelectorAll('.' + CONTENT_WRAP_CLASS +
                            ' tbody tr td[data-group-index="' + gIndex + '"]'));
                        for (var i = 0; i < workCells.length; i++) {
                            var date = workCells[parseInt(i.toString(), 10)].getAttribute('data-date');
                            if (date < tdDate) {
                                this.morePopup.relateTo = workCells[parseInt(i.toString(), 10)];
                            }
                        }
                    }
                }
            }
            else {
                this.morePopup.relateTo = closest(target, '.' + WORK_CELLS_CLASS);
            }
        }
        this.parent.renderTemplates(function () {
            var eventProp = {
                type: 'EventContainer', cancel: false,
                element: _this.morePopup.element, data: data
            };
            _this.parent.trigger(popupOpen, eventProp, function (popupArgs) {
                if (!popupArgs.cancel) {
                    _this.morePopup.show();
                }
            });
        });
    };
    QuickPopups.prototype.saveClick = function (event) {
        this.applyFormValidation();
        this.dialogEvent = event;
        this.isCrudAction = true;
        this.quickPopupHide();
    };
    QuickPopups.prototype.detailsClick = function (event) {
        var subjectEle = this.quickPopup.element.querySelector('.' + SUBJECT_CLASS);
        if (subjectEle && subjectEle.value !== '') {
            extend(this.parent.activeCellsData, { subject: subjectEle.value });
        }
        this.dialogEvent = event;
        this.isCrudAction = false;
        this.fieldValidator.destroyToolTip();
        this.quickPopupHide();
        this.parent.eventWindow.openEditor(this.parent.activeCellsData, 'Add');
    };
    QuickPopups.prototype.editClick = function (event) {
        this.dialogEvent = event;
        this.quickPopupHide(true);
        var data = this.parent.activeEventData.event;
        this.parent.currentAction = 'EditSeries';
        if (!isNullOrUndefined(data[this.parent.eventFields.recurrenceRule])) {
            this.parent.currentAction = 'EditOccurrence';
            this.openRecurrenceAlert();
        }
        else {
            this.parent.eventWindow.openEditor(data, this.parent.currentAction);
        }
    };
    QuickPopups.prototype.deleteClick = function (event) {
        this.dialogEvent = event;
        this.quickPopupHide(true);
        this.parent.currentAction = 'Delete';
        if (this.parent.activeEventData.event[this.parent.eventFields.recurrenceRule]) {
            this.openRecurrenceAlert();
        }
        else {
            this.openDeleteAlert();
        }
    };
    QuickPopups.prototype.updateMoreEventContent = function () {
        if (this.morePopup.element.classList.contains('e-popup-close')) {
            return;
        }
        var moreEventContentEle = this.morePopup.element.querySelector('.' + MORE_EVENT_CONTENT_CLASS);
        if (moreEventContentEle) {
            remove(moreEventContentEle);
        }
        var dateElement = this.morePopup.element.querySelector('.' + MORE_EVENT_HEADER_DATE_CLASS);
        var startDate = new Date(parseInt(dateElement.getAttribute('data-date'), 10));
        var endDate = new Date(parseInt(dateElement.getAttribute('data-end-date'), 10));
        var groupIndex = dateElement.getAttribute('data-group-index');
        var data;
        var groupOrder;
        if (!isNullOrUndefined(groupIndex)) {
            data = this.parent.resourceBase.lastResourceLevel[parseInt(groupIndex, 10)];
            groupOrder = data.groupOrder;
        }
        var events = this.parent.eventBase.filterEvents(startDate, endDate, this.parent.eventsProcessed, data);
        var moreElement = this.createMoreEventList(events, groupOrder, groupIndex);
        this.morePopup.element.querySelector('.' + MORE_EVENT_POPUP_CLASS).appendChild(moreElement);
    };
    QuickPopups.prototype.closeClick = function (event) {
        this.dialogEvent = event;
        if (this.parent.currentView === 'Year' && this.parent.activeCellsData && this.parent.activeCellsData.element) {
            this.parent.selectCell(this.parent.activeCellsData.element);
        }
        this.quickPopupHide();
        this.morePopup.hide();
    };
    QuickPopups.prototype.dialogButtonClick = function (event) {
        this.dialogEvent = event;
        this.quickDialog.hide();
        var target = event.target;
        var cancelBtn = this.quickDialog.element.querySelector('.' + QUICK_DIALOG_ALERT_CANCEL);
        var eventData = this.parent.activeEventData.event;
        if (target.classList.contains(QUICK_DIALOG_OCCURRENCE_CLASS)) {
            this.parent.currentAction = (this.parent.currentAction === 'Delete') ? 'DeleteOccurrence' : 'EditOccurrence';
            switch (this.parent.currentAction) {
                case 'EditOccurrence':
                    this.parent.eventWindow.openEditor(eventData, this.parent.currentAction);
                    break;
                case 'DeleteOccurrence':
                    this.parent.crudModule.deleteEvent(eventData, this.parent.currentAction);
                    break;
            }
        }
        else if (target.classList.contains(QUICK_DIALOG_FOLLOWING_EVENTS_CLASS)) {
            this.parent.currentAction = (this.parent.currentAction === 'Delete') ? 'DeleteFollowingEvents' : 'EditFollowingEvents';
            switch (this.parent.currentAction) {
                case 'EditFollowingEvents':
                    this.parent.eventWindow.openEditor(eventData, this.parent.currentAction);
                    break;
                case 'DeleteFollowingEvents':
                    this.parent.crudModule.deleteEvent(eventData, this.parent.currentAction);
                    break;
            }
        }
        else if (target.classList.contains(QUICK_DIALOG_SERIES_CLASS)) {
            this.parent.currentAction = (this.parent.currentAction === 'Delete') ? 'DeleteSeries' : 'EditSeries';
            switch (this.parent.currentAction) {
                case 'EditSeries':
                    this.parent.eventWindow.openEditor(this.parent.eventBase.getParentEvent(eventData, true), this.parent.currentAction);
                    break;
                case 'DeleteSeries':
                    this.parent.crudModule.deleteEvent(eventData, this.parent.currentAction);
                    break;
            }
        }
        else if (target.classList.contains(QUICK_DIALOG_DELETE_CLASS)) {
            this.parent.crudModule.deleteEvent(eventData, this.parent.currentAction);
        }
        else if (!cancelBtn.classList.contains(DISABLE_CLASS) && (target.classList.contains(QUICK_DIALOG_ALERT_OK) ||
            (target.classList.contains(QUICK_DIALOG_ALERT_CANCEL) && !cancelBtn.classList.contains(QUICK_DIALOG_CANCEL_CLASS)))) {
            this.parent.uiStateValues.isIgnoreOccurrence = target.classList.contains(QUICK_DIALOG_ALERT_CANCEL);
            this.parent.eventWindow.eventSave(event, this.l10n.getConstant('ok'));
        }
    };
    QuickPopups.prototype.updateTapHoldEventPopup = function (target) {
        var selectedElements = this.parent.eventBase.getSelectedEventElements(target);
        this.parent.activeEventData = this.parent.eventBase.getSelectedEvents();
        if (selectedElements.length > 0) {
            var eventObj = this.parent.eventBase.getEventByGuid(selectedElements[0].getAttribute('data-guid'));
            var titleContent = (selectedElements.length === 1) ?
                (eventObj[this.parent.eventFields.subject] || this.l10n.getConstant('noTitle')) :
                '(' + selectedElements.length + ')' + '&nbsp;' + this.l10n.getConstant('selectedItems');
            this.quickPopup.element.querySelector('.' + SUBJECT_CLASS).innerHTML = titleContent;
            if (selectedElements.length > 1) {
                addClass([this.quickPopup.element.querySelector('.' + EDIT_ICON_CLASS)], HIDDEN_CLASS);
            }
            else {
                removeClass([this.quickPopup.element.querySelector('.' + EDIT_ICON_CLASS)], HIDDEN_CLASS);
            }
        }
        else {
            this.parent.selectedElements = [];
            this.quickPopupHide();
        }
    };
    QuickPopups.prototype.getTimezone = function (event) {
        var zoneDetails = '';
        zoneDetails += event[this.parent.eventFields.startTimezone] || '';
        zoneDetails += zoneDetails === '' ? '' : ' - ';
        zoneDetails += event[this.parent.eventFields.endTimezone] || '';
        return zoneDetails;
    };
    QuickPopups.prototype.getRecurrenceSummary = function (event) {
        var recurrenceEditor = this.parent.eventWindow.getRecurrenceEditorInstance();
        if (recurrenceEditor) {
            var ruleSummary = recurrenceEditor.getRuleSummary(event[this.parent.eventFields.recurrenceRule]);
            return ruleSummary.charAt(0).toUpperCase() + ruleSummary.slice(1);
        }
        return '';
    };
    QuickPopups.prototype.getDateFormat = function (date, skeletonString) {
        return capitalizeFirstWord(this.parent.globalize.formatDate(date, { skeleton: skeletonString, calendar: this.parent.getCalendarMode() }), 'single');
    };
    QuickPopups.prototype.getDataFromTarget = function (target) {
        if (target.classList.contains(APPOINTMENT_CLASS)) {
            return this.parent.activeEventData.event;
        }
        // Deprecated cells data in quick popups
        var eventObj = {
            startTime: this.parent.activeCellsData.startTime,
            endTime: this.parent.activeCellsData.endTime,
            isAllDay: this.parent.activeCellsData.isAllDay,
            groupIndex: this.parent.activeCellsData.groupIndex
        };
        var cellsData = this.parent.activeCellsData;
        this.parent.eventWindow.convertToEventData(cellsData, eventObj);
        return eventObj;
    };
    QuickPopups.prototype.beforeQuickDialogClose = function (e) {
        var _this = this;
        var args = {
            event: e.event || this.dialogEvent,
            type: (isNullOrUndefined(this.parent.activeEventData.event)) ? 'ValidationAlert' :
                !isNullOrUndefined(this.parent.activeEventData.event[this.parent.eventFields.recurrenceRule])
                    ? 'RecurrenceAlert' : 'DeleteAlert', cancel: false, data: this.parent.activeEventData.event,
            element: this.quickDialog.element
        };
        this.parent.trigger(popupClose, args, function (popupCloseArgs) {
            if (!popupCloseArgs.cancel) {
                _this.parent.eventBase.focusElement(true);
            }
        });
    };
    QuickPopups.prototype.beforeQuickPopupOpen = function (target, originalEvent) {
        var _this = this;
        this.parent.renderTemplates(function () {
            var isEventPopup = _this.quickPopup.element.querySelector('.' + EVENT_POPUP_CLASS);
            var popupType = _this.parent.isAdaptive ? isEventPopup ? 'ViewEventInfo' : 'EditEventInfo' : 'QuickInfo';
            var eventProp = {
                type: popupType, cancel: false, data: extend({}, _this.getDataFromTarget(target), null, true),
                target: target, element: _this.quickPopup.element
            };
            _this.parent.trigger(popupOpen, eventProp, function (popupArgs) {
                if (popupArgs.cancel) {
                    _this.quickPopupHide();
                    _this.destroyPopupButtons('quickPopup');
                    if (popupArgs.element.classList.contains(POPUP_OPEN)) {
                        _this.quickPopupClose();
                    }
                    removeChildren(_this.quickPopup.element);
                    _this.isMultipleEventSelect = false;
                }
                else {
                    var display = _this.quickPopup.element.style.display;
                    _this.quickPopup.element.style.display = 'block';
                    if (_this.parent.isAdaptive) {
                        _this.quickPopup.element.removeAttribute('style');
                        _this.quickPopup.element.style.display = 'block';
                        _this.quickPopup.element.style.height = formatUnit((popupType === 'EditEventInfo') ? 65 : window.innerHeight);
                    }
                    else {
                        var isVirtualScroll = _this.parent.virtualScrollModule && _this.parent.virtualScrollModule.isHorizontalScroll
                            && !isNullOrUndefined(closest(target, '.' + CONTENT_TABLE_CLASS));
                        var conTable = _this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS + ' table');
                        _this.quickPopup.offsetX = isVirtualScroll && !_this.parent.enableRtl ? (getTranslateX(conTable) + 10) : 10;
                        _this.quickPopup.offsetY = _this.parent.virtualScrollModule && !_this.parent.virtualScrollModule.isHorizontalScroll ?
                            _this.quickPopup.offsetY : 0;
                        _this.quickPopup.collision = { X: _this.parent.enableRtl ? 'flip' : 'none', Y: 'fit' };
                        _this.quickPopup.position = { X: _this.parent.enableRtl ? 'left' : 'right', Y: _this.parent.enableRtl ? 'bottom' : 'top' };
                        _this.quickPopup.dataBind();
                        _this.quickPopup.refreshPosition(null, true);
                        var collide = isCollide(_this.quickPopup.element, _this.parent.element);
                        if (collide.indexOf(_this.parent.enableRtl ? 'left' : 'right') > -1) {
                            _this.quickPopup.offsetX = -target.offsetWidth - 10 - _this.quickPopup.element.offsetWidth;
                            if (isVirtualScroll && !_this.parent.enableRtl) {
                                _this.quickPopup.offsetX = getTranslateX(conTable) + _this.quickPopup.offsetX;
                            }
                            _this.quickPopup.dataBind();
                            _this.quickPopup.refreshPosition(null, true);
                            var leftCollide = isCollide(_this.quickPopup.element, _this.parent.element);
                            if (leftCollide.indexOf('left') > -1) {
                                _this.quickPopup.position = { X: 'center', Y: 'center' };
                                _this.quickPopup.collision = { X: 'fit', Y: 'fit' };
                                _this.quickPopup.offsetX = -(_this.quickPopup.element.offsetWidth / 2);
                                _this.quickPopup.dataBind();
                            }
                        }
                        if (_this.parent.virtualScrollModule && !_this.parent.virtualScrollModule.isHorizontalScroll && (collide.indexOf('top') > -1 || collide.indexOf('bottom') > -1)) {
                            var translateY = getTranslateY(conTable);
                            _this.quickPopup.offsetY = translateY;
                            _this.quickPopup.dataBind();
                            _this.quickPopup.refreshPosition(null, true);
                        }
                        if (_this.quickPopup.position.X === 'center' && _this.quickPopup.position.Y === 'center' && !isNullOrUndefined(originalEvent) &&
                            originalEvent.clientX && originalEvent.clientY) {
                            var clientX = originalEvent.clientX;
                            var clientY = originalEvent.clientY;
                            var targetRect = target.getBoundingClientRect();
                            var offsetY = originalEvent.offsetY || Math.ceil(clientY - targetRect.y);
                            var previousOffset = _this.quickPopup.offsetY;
                            var collision = isCollide(_this.quickPopup.element, target);
                            var popupRect = _this.quickPopup.element.getBoundingClientRect();
                            var targetEle = document.elementFromPoint(clientX, clientY);
                            if (collision.indexOf('top') > -1 || collision.indexOf('bottom') > -1 || closest(targetEle, '.' + POPUP_WRAPPER_CLASS)) {
                                if (popupRect.top <= clientY &&
                                    clientY <= popupRect.top + popupRect.height) {
                                    _this.quickPopup.offsetY = previousOffset - popupRect.height - 10;
                                    _this.quickPopup.dataBind();
                                    collision = isCollide(_this.quickPopup.element, _this.parent.element);
                                    if (collision.indexOf('top') > -1) {
                                        _this.quickPopup.offsetY = previousOffset + offsetY + 10;
                                        _this.quickPopup.dataBind();
                                    }
                                }
                                else if (isCollide(_this.quickPopup.element, _this.parent.element).indexOf('bottom') > -1) {
                                    _this.quickPopup.offsetY =
                                        previousOffset - offsetY - Math.ceil(popupRect.height) - 10;
                                    _this.quickPopup.dataBind();
                                }
                            }
                        }
                    }
                    if (isEventPopup) {
                        _this.applyEventColor();
                    }
                    _this.quickPopup.element.style.display = display;
                    _this.quickPopup.dataBind();
                    _this.quickPopup.show();
                }
            });
        });
    };
    QuickPopups.prototype.applyEventColor = function () {
        var colorField = '';
        if (this.parent.currentView === 'Agenda' || this.parent.currentView === 'MonthAgenda') {
            colorField = this.parent.enableRtl ? 'border-right-color' : 'border-left-color';
        }
        else {
            colorField = 'background-color';
        }
        var color = this.parent.activeEventData.element.style[colorField];
        if (color === '') {
            return;
        }
        var colorEle = this.quickPopup.element.querySelector('.' + POPUP_HEADER_CLASS);
        var footerEle = this.quickPopup.element.querySelector('.' + POPUP_FOOTER_CLASS);
        if (footerEle && footerEle.offsetParent) {
            colorEle = this.quickPopup.element.querySelector('.' + SUBJECT_CLASS);
            if (colorEle) {
                colorEle.style.borderLeftColor = color;
                color = "rgba(" + color.match(/\d+/g).join() + ",0.3)";
            }
        }
        if (colorEle) {
            colorEle.style.backgroundColor = color;
        }
    };
    QuickPopups.prototype.quickPopupOpen = function () {
        if (this.parent.isAdaptive) {
            this.quickPopup.element.style.top = '0px';
            return;
        }
        if (this.quickPopup.element.querySelector('.' + CELL_POPUP_CLASS)) {
            var subjectElement = this.quickPopup.element.querySelector('.' + SUBJECT_CLASS);
            if (subjectElement) {
                subjectElement.focus();
            }
        }
        else {
            var editElement = this.quickPopup.element.querySelector('.' + EDIT_EVENT_CLASS);
            if (editElement) {
                editElement.focus();
            }
            var editIcon = this.quickPopup.element.querySelector('.' + EDIT_CLASS);
            if (editIcon) {
                editIcon.focus();
            }
        }
    };
    QuickPopups.prototype.quickPopupClose = function () {
        this.parent.eventBase.focusElement();
        this.quickPopup.relateTo = '.' + WORK_CELLS_CLASS;
        this.fieldValidator.destroyToolTip();
        if (this.quickPopup.element.querySelectorAll('.e-formvalidator').length) {
            this.fieldValidator.destroy();
        }
        this.destroyPopupButtons('quickPopup');
        removeChildren(this.quickPopup.element);
    };
    QuickPopups.prototype.morePopupOpen = function () {
        if (this.parent.isAdaptive) {
            this.morePopup.element.style.top = '0px';
            this.morePopup.element.style.left = '0px';
            this.morePopup.element.style.height = formatUnit(window.innerHeight);
            return;
        }
        this.morePopup.element.querySelector('.' + MORE_EVENT_HEADER_DATE_CLASS).focus();
        this.morePopup.refreshPosition();
    };
    QuickPopups.prototype.morePopupClose = function () {
        var moreWrapper = this.parent.element.querySelector('.' + MORE_EVENT_WRAPPER_CLASS);
        if (moreWrapper) {
            remove(moreWrapper);
        }
    };
    QuickPopups.prototype.popupClose = function (event) {
        this.dialogEvent = event;
        this.isCrudAction = false;
        this.quickPopupHide(true);
    };
    QuickPopups.prototype.quickPopupHide = function (hideAnimation) {
        var _this = this;
        if (!this.quickPopup.element.classList.contains(POPUP_OPEN)) {
            return;
        }
        var isCellPopup = this.quickPopup.element.querySelector('.' + CELL_POPUP_CLASS);
        var popupData;
        if (isCellPopup) {
            var formvalidator = this.quickPopup.element.querySelector('.e-formvalidator');
            if (this.isCrudAction && formvalidator &&
                !formvalidator.ej2_instances[0].validate()) {
                return;
            }
            var fields = this.parent.eventFields;
            var saveObj = this.parent.eventWindow.getObjectFromFormData(POPUP_WRAPPER_CLASS);
            this.parent.eventWindow.setDefaultValueToObject(saveObj);
            saveObj[fields.id] = this.parent.eventBase.getEventMaxID();
            saveObj[fields.startTime] = this.parent.activeCellsData.startTime;
            saveObj[fields.endTime] = this.parent.activeCellsData.endTime;
            saveObj[fields.isAllDay] = this.parent.activeCellsData.isAllDay;
            if (this.parent.resourceBase) {
                this.parent.resourceBase.setResourceValues(saveObj);
            }
            popupData = saveObj;
        }
        else {
            popupData = this.parent.activeEventData.event;
        }
        var isEventPopup = this.quickPopup.element.querySelector('.' + EVENT_POPUP_CLASS);
        var args = {
            event: this.dialogEvent,
            type: this.parent.isAdaptive ? isEventPopup ? 'ViewEventInfo' : 'EditEventInfo' : 'QuickInfo',
            cancel: false, data: popupData, element: this.quickPopup.element,
            target: (isCellPopup ? this.parent.activeCellsData.element : this.parent.activeEventData.element)
        };
        this.parent.trigger(popupClose, args, function (popupCloseArgs) {
            if (!popupCloseArgs.cancel) {
                if (_this.quickPopup.element.classList.contains('e-popup-open')) {
                    if (isCellPopup && _this.isCrudAction) {
                        _this.parent.currentAction = 'Add';
                        _this.parent.crudModule.addEvent(popupCloseArgs.data);
                    }
                    if (hideAnimation) {
                        var animation = _this.quickPopup.hideAnimation;
                        _this.quickPopup.hideAnimation = null;
                        _this.quickPopup.hide();
                        _this.quickPopup.hideAnimation = animation;
                    }
                    else {
                        _this.quickPopup.hide();
                    }
                    _this.isMultipleEventSelect = false;
                    _this.isCrudAction = false;
                }
            }
        });
    };
    QuickPopups.prototype.navigationClick = function (e) {
        var navigateEle = closest(e.target, '.' + NAVIGATE_CLASS);
        if (!isNullOrUndefined(navigateEle)) {
            var date = this.parent.getDateFromElement(e.currentTarget);
            if (!isNullOrUndefined(date)) {
                this.closeClick(e);
                this.parent.setProperties({ selectedDate: date }, true);
                this.parent.changeView(this.parent.getNavigateView(), e);
            }
        }
    };
    QuickPopups.prototype.documentClick = function (e) {
        var target = e.event.target;
        var classNames = '.' + POPUP_WRAPPER_CLASS + ',.' + HEADER_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS +
            ',.' + WORK_CELLS_CLASS + ',.' + APPOINTMENT_CLASS + ',.e-popup';
        var popupWrap = this.parent.element.querySelector('.' + POPUP_WRAPPER_CLASS);
        if ((popupWrap && popupWrap.childElementCount > 0 && !closest(target, classNames)) || !closest(target, classNames)) {
            this.quickPopupHide();
            this.parent.removeNewEventElement();
        }
        var tar = this.parent.element.querySelector('.' + INLINE_SUBJECT_CLASS);
        if (tar && tar !== target && this.parent.allowInline) {
            this.parent.inlineModule.documentClick();
        }
        if (closest(target, '.' + APPOINTMENT_CLASS + ',.' + HEADER_CELLS_CLASS)) {
            this.parent.removeNewEventElement();
        }
        if (!closest(target, '.' + MORE_POPUP_WRAPPER_CLASS) && (target.classList &&
            !target.classList.contains(MORE_INDICATOR_CLASS))
            && (!closest(target, '.' + POPUP_OPEN)) && !closest(target, '.' + WORK_CELLS_CLASS)) {
            this.morePopup.hide();
        }
    };
    QuickPopups.prototype.onClosePopup = function (event) {
        if (!isNullOrUndefined(event)) {
            this.dialogEvent = event;
        }
        this.quickPopupHide();
        if (isNullOrUndefined(event) || (!isNullOrUndefined(event) && event.action !== 'escape') ||
            (this.parent.inlineModule && this.parent.element.querySelector('.' + INLINE_APPOINTMENT_CLASS))) {
            this.parent.eventBase.focusElement();
        }
    };
    QuickPopups.prototype.addEventListener = function () {
        this.parent.on(cellClick, this.cellClick, this);
        this.parent.on(eventClick, this.eventClick, this);
        this.parent.on(documentClick, this.documentClick, this);
        this.parent.on(dataReady, this.updateMoreEventContent, this);
    };
    QuickPopups.prototype.removeEventListener = function () {
        this.parent.off(cellClick, this.cellClick);
        this.parent.off(eventClick, this.eventClick);
        this.parent.off(documentClick, this.documentClick);
        this.parent.off(dataReady, this.updateMoreEventContent);
    };
    QuickPopups.prototype.destroyPopupButtons = function (popupName) {
        var popup = popupName === 'quickPopup' ? this.quickPopup : this.morePopup;
        var buttonCollections = [].slice.call(popup.element.querySelectorAll('.e-control.e-btn'));
        for (var _i = 0, buttonCollections_1 = buttonCollections; _i < buttonCollections_1.length; _i++) {
            var button = buttonCollections_1[_i];
            var instance = button.ej2_instances[0];
            if (instance) {
                instance.destroy();
            }
        }
    };
    QuickPopups.prototype.refreshQuickDialog = function () {
        this.destroyQuickDialog();
        this.renderQuickDialog();
    };
    QuickPopups.prototype.refreshQuickPopup = function () {
        this.destroyQuickPopup();
        this.renderQuickPopup();
    };
    QuickPopups.prototype.refreshMorePopup = function () {
        this.destroyMorePopup();
        this.renderMorePopup();
    };
    QuickPopups.prototype.destroyQuickDialog = function () {
        if (this.quickDialog.element) {
            this.quickDialog.destroy();
            remove(this.quickDialog.element);
            this.quickDialog = null;
        }
    };
    QuickPopups.prototype.destroyQuickPopup = function () {
        if (this.quickPopup.element) {
            this.destroyPopupButtons('quickPopup');
            this.quickPopup.destroy();
            remove(this.quickPopup.element);
            this.quickPopup = null;
        }
    };
    QuickPopups.prototype.destroyMorePopup = function () {
        if (this.morePopup.element) {
            this.destroyPopupButtons('morePopup');
            this.morePopup.destroy();
            remove(this.morePopup.element);
            this.morePopup = null;
        }
    };
    QuickPopups.prototype.destroy = function () {
        if (this.quickPopup.element.querySelectorAll('.e-formvalidator').length) {
            this.fieldValidator.destroy();
        }
        this.removeEventListener();
        this.destroyQuickPopup();
        this.destroyMorePopup();
        this.destroyQuickDialog();
        this.dialogEvent = null;
        this.parent = null;
        this.l10n = null;
        this.isCrudAction = null;
        this.fieldValidator = null;
        this.isMultipleEventSelect = null;
    };
    return QuickPopups;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Tooltip for Schedule
 */
var EventTooltip = /** @__PURE__ @class */ (function () {
    function EventTooltip(parent) {
        this.parent = parent;
        this.tooltipObj = new Tooltip({
            animation: { close: { effect: 'FadeOut' } },
            content: 'No title',
            position: 'BottomRight',
            offsetY: 10,
            mouseTrail: this.parent.isAdaptive ? false : true,
            showTipPointer: false,
            cssClass: this.parent.cssClass + ' ' + EVENT_TOOLTIP_ROOT_CLASS,
            target: this.getTargets(),
            beforeRender: this.onBeforeRender.bind(this),
            beforeClose: this.onTooltipClose.bind(this),
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer
        });
        this.tooltipObj.appendTo(this.parent.element);
    }
    EventTooltip.prototype.getTargets = function () {
        var targets = [];
        if (this.parent.activeViewOptions.group.headerTooltipTemplate) {
            targets.push('.' + RESOURCE_CELLS_CLASS);
        }
        if (this.parent.eventSettings.enableTooltip) {
            targets.push('.' + APPOINTMENT_CLASS);
        }
        return targets.join(',');
    };
    EventTooltip.prototype.onBeforeRender = function (args) {
        if (!isNullOrUndefined(args.target.getAttribute('data-tooltip-id'))) {
            return;
        }
        if (args.target.classList.contains(RESOURCE_CELLS_CLASS) && this.parent.activeViewOptions.group.resources.length > 0) {
            var resCollection = void 0;
            if (this.parent.activeView.isTimelineView()) {
                var index = parseInt(args.target.getAttribute('data-group-index'), 10);
                resCollection = this.parent.resourceBase.lastResourceLevel[parseInt(index.toString(), 10)];
            }
            else {
                var rowIndex = args.target.parentNode.sectionRowIndex;
                var cellIndex = args.target.cellIndex;
                resCollection =
                    this.parent.activeView.getColumnLevels()[parseInt(rowIndex.toString(), 10)][parseInt(cellIndex.toString(), 10)];
            }
            var data = {
                resource: resCollection.resource,
                resourceData: resCollection.resourceData
            };
            var contentContainer = createElement('div');
            var templateId = this.parent.element.id + '_headerTooltipTemplate';
            var tooltipTemplate = [].slice.call(this.parent.getHeaderTooltipTemplate()(data, this.parent, 'headerTooltipTemplate', templateId, false));
            append(tooltipTemplate, contentContainer);
            this.setContent(contentContainer);
            this.parent.renderTemplates();
            return;
        }
        var record = this.parent.eventBase.getEventByGuid(args.target.getAttribute('data-guid'));
        if (isNullOrUndefined(record)) {
            return;
        }
        if (!isNullOrUndefined(this.parent.eventSettings.tooltipTemplate)) {
            var contentContainer = createElement('div');
            var templateId = this.parent.element.id + '_tooltipTemplate';
            var tooltipTemplate = [].slice.call(this.parent.getEventTooltipTemplate()(record, this.parent, 'tooltipTemplate', templateId, false));
            append(tooltipTemplate, contentContainer);
            this.setContent(contentContainer);
        }
        else {
            var globalize = this.parent.globalize;
            var fields = this.parent.eventFields;
            var eventStart = new Date('' + record[fields.startTime]);
            var eventEnd = new Date('' + record[fields.endTime]);
            eventEnd = (eventEnd.getHours() === 0 && eventEnd.getMinutes() === 0) ? new Date(eventEnd.setMilliseconds(-1000)) : eventEnd;
            var startDate = resetTime(new Date('' + eventStart));
            var endDate = resetTime(new Date('' + eventEnd));
            var tooltipSubject = (record[fields.subject] || this.parent.eventSettings.fields.subject.default
                || this.parent.localeObj.getConstant('addTitle'));
            var tooltipLocation = !isNullOrUndefined(record[fields.location]) ? record[fields.location] : '';
            var startMonthDate = '';
            var startMonthYearDate = '';
            var endMonthYearDate = '';
            startMonthDate = globalize.formatDate(eventStart, {
                type: 'date', skeleton: 'MMMd', calendar: this.parent.getCalendarMode()
            });
            startMonthYearDate = globalize.formatDate(eventStart, {
                type: 'date', skeleton: 'medium', calendar: this.parent.getCalendarMode()
            });
            endMonthYearDate = globalize.formatDate(eventEnd, {
                type: 'date', skeleton: 'medium', calendar: this.parent.getCalendarMode()
            });
            startMonthDate = capitalizeFirstWord(startMonthDate, 'single');
            startMonthYearDate = capitalizeFirstWord(startMonthYearDate, 'single');
            endMonthYearDate = capitalizeFirstWord(endMonthYearDate, 'single');
            var startTime = this.parent.getTimeString(eventStart);
            var endTime = this.parent.getTimeString(eventEnd);
            var tooltipDetails = void 0;
            if (startDate.getTime() === endDate.getTime()) {
                tooltipDetails =
                    globalize.formatDate(eventStart, {
                        type: 'date', skeleton: 'long', calendar: this.parent.getCalendarMode()
                    });
                tooltipDetails = capitalizeFirstWord(tooltipDetails, 'single');
            }
            else {
                tooltipDetails = (startDate.getFullYear() === endDate.getFullYear()) ? (startMonthDate + ' - ' + endMonthYearDate) :
                    (startMonthYearDate + ' - ' + endMonthYearDate);
            }
            var tooltipTime = (record[fields.isAllDay]) ? this.parent.localeObj.getConstant('allDay') :
                (startTime + ' - ' + endTime);
            var content_1 = '<div><div class="e-subject">' + tooltipSubject + '</div>' +
                '<div class="e-location">' + tooltipLocation + '</div>' +
                '<div class="e-details">' + tooltipDetails + '</div>' +
                '<div class="e-all-day">' + tooltipTime + '</div></div>';
            var contentTemp = function () {
                return content_1;
            };
            this.setContent(initializeCSPTemplate(contentTemp));
        }
        this.parent.renderTemplates();
    };
    EventTooltip.prototype.onTooltipClose = function (args) {
        if (args.element) {
            removeClass([args.element], POPUP_OPEN);
            addClass([args.element], POPUP_CLOSE);
        }
        this.parent.resetTemplates(['tooltipTemplate', 'headerTooltipTemplate']);
    };
    EventTooltip.prototype.setContent = function (content) {
        this.tooltipObj.setProperties({ content: content }, true);
    };
    EventTooltip.prototype.close = function () {
        this.tooltipObj.close();
    };
    EventTooltip.prototype.destroy = function () {
        this.tooltipObj.destroy();
        addClass([this.parent.element], 'e-control');
        this.tooltipObj = null;
        this.parent = null;
    };
    return EventTooltip;
}());

var __extends$4 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$1 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var HEADER = 'e-editor';
var INPUTWARAPPER = 'e-input-wrapper';
var INPUTWARAPPERSIDE = 'e-input-wrapper-side';
var REPEATELEMENT = 'e-repeat-element';
var REPEATINTERVAL = 'e-repeat-interval';
var INTERVALCLASS = 'e-interval';
var DAYWRAPPER = 'e-days';
var WEEKWRAPPER = 'e-non-week';
var WEEKPOSITION = 'e-week-position';
var DAYPOSITION = 'e-day-position';
var YEAREXPANDERWRAPPER = 'e-year-expander';
var YEAREXPANDERELEMENT = 'e-year-expander-element';
var MONETHEXPANDERWRAPPER = 'e-month-expander';
var MONETHEXPANDWRAPPER = 'e-month-expand-wrapper';
var MONTHEXPANDERELEMENT = 'e-month-expander-element';
var MONTHEXPANDERCHECKBOXWRAPPER = 'e-month-expander-checkbox-wrapper';
var FORMLEFT = 'e-form-left';
var FORMRIGHT = 'e-form-right';
var MONTHDAYWRAPPER = 'e-month-day';
var MONTHEXPANNDERELEM = 'e-month-expander-wrapper';
var MONTHPOS = 'e-month-pos';
var MONTHWEEK = 'e-month-week';
var ENDON = 'e-end-on';
var MONTHEXPANDERLABEL = 'e-month-expander-label';
var WEEKEXPANDERLABEL = 'e-week-expander-label';
var ENDONLABEL = 'e-end-on-label';
var ENDONLEFT = 'e-end-on-left';
var MONTHDAYELEMENT = 'e-monthday-element';
var ENDONELEMENT = 'e-end-on-element';
var ENDONDATE = 'e-end-on-date';
var UNTILDATE = 'e-until-date';
var ENDONCOUNTWRAPPER = 'e-end-on-count';
var ENDONCOUNT = 'e-recurrence-count';
var HIDEWRAPPER = 'e-hide-recurrence-element';
var RTLCLASS = 'e-rtl';
var PRIMARY = 'e-primary';
var ACTIVE = 'e-active';
var RECURRENCETABLE = 'e-recurrence-table';
var REPEATCONTENT = 'e-repeat-content';
var REPEATCONTENTWRAPPER = 'e-repeat-content-wrapper';
var NONE = 'none';
var DAILY = 'daily';
var WEEKLY = 'weekly';
var MONTHLY = 'monthly';
var YEARLY = 'yearly';
var NEVER = 'never';
var UNTIL$1 = 'until';
var COUNT = 'count';
var TEXTFIELD = 'text';
var VALUEFIELD = 'value';
var LAST = 'last';
var REPEAT = 'repeat';
var REPEATEVERY = 'repeatEvery';
var ON$1 = 'on';
var END = 'end';
var RADIOLABEL = 'onDay';
var RULEUNTIL = 'UNTIL';
var RULEBYDAY = 'BYDAY';
var RULEBYMONTHDAY = 'BYMONTHDAY';
var RULEBYMONTH = 'BYMONTH';
var RULEINTERVAL = 'INTERVAL';
var RULECOUNT = 'COUNT';
var RULESETPOS = 'BYSETPOS';
var RULEFREQ = 'FREQ';
var RULEDAILY = 'DAILY';
var RULEWEEKLY = 'WEEKLY';
var RULEMONTHLY = 'MONTHLY';
var RULEYEARLY = 'YEARLY';
var RULESUNDAY = 'SU';
var RULEMONDAY = 'MO';
var RULETUESDAY = 'TU';
var RULEWEDNESDAY = 'WE';
var RULETHURSDAY = 'TH';
var RULEFRIDAY = 'FR';
var RULESATURDAY = 'SA';
var KEYSUNDAY = 'sun';
var KEYMONDAY = 'mon';
var KEYTUESDAY = 'tue';
var KEYWEDNESDAY = 'wed';
var KEYTHURSDAY = 'thu';
var KEYFRIDAY = 'fri';
var KEYSATURDAY = 'sat';
var EQUAL = '=';
var SEMICOLON = ';';
var COMMA = ',';
var FIRST = 'first';
var SECOND = 'second';
var THIRD = 'third';
var FOURTH = 'fourth';
var contentType = {
    none: '',
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
    yearly: 'years'
};
var valueData = {
    'sun': RULESUNDAY,
    'mon': RULEMONDAY,
    'tue': RULETUESDAY,
    'wed': RULEWEDNESDAY,
    'thu': RULETHURSDAY,
    'fri': RULEFRIDAY,
    'sat': RULESATURDAY
};
var neverClassList = [DAYWRAPPER, WEEKWRAPPER, ENDON, INTERVALCLASS, YEAREXPANDERWRAPPER, MONETHEXPANDERWRAPPER];
var weekClassList = [WEEKWRAPPER];
var monthClassList = [DAYWRAPPER, YEAREXPANDERWRAPPER];
var yearClassList = [DAYWRAPPER];
var dailyClassList = [DAYWRAPPER, WEEKWRAPPER, YEAREXPANDERWRAPPER, MONETHEXPANDERWRAPPER];
var noEndClassList = [ENDONDATE, ENDONCOUNTWRAPPER];
var endOnCountClassList = [ENDONDATE];
var endOnDateClassList = [ENDONCOUNTWRAPPER];
/**
 * Represents the RecurrenceEditor component.
 * ```html
 * <div id="recurrence"></div>
 * ```
 * ```typescript
 * <script>
 *   var recObj = new RecurrenceEditor();
 *   recObj.appendTo("#recurrence");
 * </script>
 * ```
 */
var RecurrenceEditor = /** @__PURE__ @class */ (function (_super) {
    __extends$4(RecurrenceEditor, _super);
    /**
     * Constructor for creating the widget
     *
     * @param {RecurrenceEditorModel} options Accepts the recurrence editor model properties to initiate the rendering
     * @param {string | HTMLElement} element Accepts the DOM element reference
     */
    function RecurrenceEditor(options, element) {
        var _this = _super.call(this, options, element) || this;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this.defaultLocale = {
            none: 'None',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            month: 'Month',
            yearly: 'Yearly',
            never: 'Never',
            until: 'Until',
            count: 'Count',
            first: 'First',
            second: 'Second',
            third: 'Third',
            fourth: 'Fourth',
            last: 'Last',
            repeat: 'Repeat',
            repeatEvery: 'Repeat every',
            on: 'Repeat On',
            end: 'End',
            onDay: 'Day',
            days: 'Day(s)',
            weeks: 'Week(s)',
            months: 'Month(s)',
            years: 'Year(s)',
            every: 'every',
            summaryTimes: 'time(s)',
            summaryOn: 'on',
            summaryUntil: 'until',
            summaryRepeat: 'Repeats',
            summaryDay: 'day(s)',
            summaryWeek: 'week(s)',
            summaryMonth: 'month(s)',
            summaryYear: 'year(s)',
            monthWeek: 'Month Week',
            monthPosition: 'Month Position',
            monthExpander: 'Month Expander',
            yearExpander: 'Year Expander',
            repeatInterval: 'Repeat Interval'
        };
        _this.renderStatus = false;
        _this.dayButtons = [];
        _this.monthButtons = [];
        return _this;
    }
    RecurrenceEditor.prototype.startState = function (freq, endOn, startDate) {
        this.showFormElement();
        this.updateForm(freq);
        this.freshOnEndForm();
        this.updateEndOnForm(endOn);
        this.selectMonthDay(startDate);
        this.updateUntilDate(startDate);
        this.onMonthDay.setProperties({ checked: true });
    };
    RecurrenceEditor.prototype.preRender = function () {
        this.localeObj = new L10n(this.getModuleName(), this.defaultLocale, this.locale);
        this.calendarUtil = getCalendarUtil(this.calendarMode);
    };
    RecurrenceEditor.prototype.applyCustomClass = function (cssClass) {
        if (cssClass) {
            addClass([this.element], cssClass.split(' '));
        }
    };
    RecurrenceEditor.prototype.initialize = function () {
        addClass([this.element], 'e-' + this.getModuleName());
        this.renderComponent();
        if (!isNullOrUndefined(this.value) && this.value !== '') {
            this.setRecurrenceRule(this.value);
        }
        else {
            if (!isNullOrUndefined(this.repeatType.value)) {
                this.startState(this.repeatType.value.toString().toUpperCase(), this.endTypes[0], this.startDate);
                this.updateForm(this.repeatType.value.toString());
            }
            if (this.selectedType > 0) {
                this.setProperties({ value: this.getRecurrenceRule() }, false);
            }
        }
        this.applyCustomClass(this.cssClass);
    };
    RecurrenceEditor.prototype.triggerChangeEvent = function () {
        var _this = this;
        if (this.renderStatus) {
            var value = this.getRecurrenceRule();
            this.trigger('change', { value: value }, function (args) { return _this.setProperties({ value: args.value }, false); });
        }
    };
    RecurrenceEditor.prototype.resetDayButton = function () {
        var elements = [].slice.call(this.element.querySelectorAll('.' + DAYWRAPPER + ' button'));
        elements.forEach(function (element) { return removeClass([element], [ACTIVE, PRIMARY]); });
    };
    RecurrenceEditor.prototype.daySelection = function (dayIndex) {
        this.resetDayButton();
        var days = [0, 1, 2, 3, 4, 5, 6];
        this.rotateArray(days, this.firstDayOfWeek);
        var element = this.element.querySelector('.' + DAYWRAPPER + ' button[data-index="' + days.indexOf(dayIndex) + '"]');
        if (element) {
            addClass([element], [ACTIVE, PRIMARY]);
        }
    };
    RecurrenceEditor.prototype.rtlClass = function (status) {
        if (status) {
            addClass([this.element], RTLCLASS);
        }
        else {
            removeClass([this.element], RTLCLASS);
        }
    };
    RecurrenceEditor.prototype.updateUntilDate = function (date) {
        var tempDate = new Date(date.getTime());
        tempDate.setDate(tempDate.getDate() + 60);
        this.untilDateObj.setProperties({ value: tempDate });
    };
    RecurrenceEditor.prototype.selectMonthDay = function (date) {
        var weekday = [KEYSUNDAY, KEYMONDAY, KEYTUESDAY, KEYWEDNESDAY, KEYTHURSDAY, KEYFRIDAY, KEYSATURDAY];
        this.monthDate.setProperties({ value: this.calendarUtil.getDate(date) });
        this.monthWeekDays.setProperties({ value: valueData[weekday[date.getDay()]] });
        this.monthValue.setProperties({ value: '' + this.calendarUtil.getMonth(date) });
        this.monthWeekPos.setProperties({ value: this.getDayPosition(date) });
        this.daySelection(date.getDay());
    };
    RecurrenceEditor.prototype.updateForm = function (state) {
        var _this = this;
        this.repeatType.setProperties({ value: state });
        var end = this.element.querySelector('.' + ENDON);
        if (state === DAILY) {
            classList(end, [FORMLEFT], [FORMRIGHT]);
        }
        else {
            classList(end, [FORMRIGHT], [FORMLEFT]);
        }
        switch (state) {
            case NONE:
                neverClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case WEEKLY:
                weekClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case MONTHLY:
                monthClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case YEARLY:
                yearClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case DAILY:
                dailyClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
        }
    };
    RecurrenceEditor.prototype.updateEndOnForm = function (state) {
        var _this = this;
        this.endType.setProperties({ value: state });
        switch (state) {
            case NEVER:
                noEndClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case UNTIL$1:
                endOnDateClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
            case COUNT:
                endOnCountClassList.forEach(function (className) { return addClass([_this.element.querySelector('.' + className)], HIDEWRAPPER); });
                break;
        }
    };
    RecurrenceEditor.prototype.freshOnEndForm = function () {
        var _this = this;
        noEndClassList.forEach(function (className) {
            var element = _this.element.querySelector('.' + className);
            if (element) {
                removeClass([element], HIDEWRAPPER);
            }
        });
    };
    RecurrenceEditor.prototype.showFormElement = function () {
        var _this = this;
        neverClassList.forEach(function (className) {
            var hideElement = _this.element.querySelector('.' + className);
            if (hideElement) {
                removeClass([hideElement], HIDEWRAPPER);
            }
        });
    };
    RecurrenceEditor.prototype.renderDropdowns = function () {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        this.repeatType = new DropDownList({
            //set the data to dataSource property
            dataSource: this.getRepeatData(),
            floatLabelType: 'Always',
            enableRtl: this.enableRtl,
            index: this.selectedType,
            fields: {
                text: TEXTFIELD,
                value: VALUEFIELD
            },
            placeholder: this.localeObj.getConstant(REPEAT),
            htmlAttributes: { 'title': this.localeObj.getConstant(REPEAT) },
            change: function (args) {
                self.setProperties({ selectedType: _this.frequencies.indexOf(args.value) }, false);
                self.element.querySelector('.' + REPEATCONTENT).innerHTML =
                    self.localeObj.getConstant(contentType[args.value]);
                self.showFormElement();
                self.updateForm(args.value);
                self.resetFormValues();
                self.triggerChangeEvent();
            }
        });
        // set placeholder to DropDownList input element
        this.repeatType.appendTo(this.element.querySelector('.' + REPEATELEMENT));
        this.endType = new DropDownList({
            dataSource: this.getEndData(),
            popupWidth: this.getPopupWidth(),
            enableRtl: this.enableRtl,
            index: 1,
            fields: {
                text: TEXTFIELD,
                value: VALUEFIELD
            },
            change: function (args) {
                self.freshOnEndForm();
                self.updateEndOnForm(args.value);
                self.resetFormValues();
                self.triggerChangeEvent();
            }
        });
        this.endType.appendTo(this.element.querySelector('.' + ENDONELEMENT));
        var renderDropDownList = function (dropDownData) {
            return new DropDownList({
                dataSource: dropDownData,
                popupWidth: _this.getPopupWidth(),
                enableRtl: _this.enableRtl,
                fields: {
                    text: TEXTFIELD,
                    value: VALUEFIELD
                },
                index: 1,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                change: function (args) {
                    self.onWeekDay.setProperties({ checked: true });
                    self.resetFormValues();
                    self.triggerChangeEvent();
                }
            });
        };
        this.monthWeekPos = renderDropDownList(this.getMonthPosData());
        this.monthWeekPos.appendTo(this.element.querySelector('.' + MONTHPOS));
        this.monthWeekDays = renderDropDownList(this.getDayData('wide'));
        this.monthWeekDays.appendTo(this.element.querySelector('.' + MONTHWEEK));
        this.monthValue = new DropDownList({
            dataSource: this.getMonthData(),
            fields: {
                text: TEXTFIELD,
                value: VALUEFIELD
            },
            enableRtl: this.enableRtl,
            index: 7,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            change: function (args) {
                self.resetFormValues();
                self.triggerChangeEvent();
            }
        });
        this.monthValue.appendTo(this.element.querySelector('.' + YEAREXPANDERELEMENT));
    };
    RecurrenceEditor.prototype.setDefaultValue = function () {
        var formelement = [].slice.call(this.element.querySelectorAll('.e-control .e-numerictextbox'));
        for (var _i = 0, formelement_1 = formelement; _i < formelement_1.length; _i++) {
            var element = formelement_1[_i];
            var instance = element.ej2_instances[0];
            if (instance.element.classList.contains(REPEATINTERVAL)) {
                instance.value = 1;
                instance.dataBind();
            }
            else if (instance.element.classList.contains(ENDONCOUNT)) {
                instance.value = 10;
                instance.dataBind();
            }
        }
    };
    RecurrenceEditor.prototype.resetFormValues = function () {
        var recurreneElement = [].slice.call(this.element.querySelectorAll('.e-control [type="text"]'));
        for (var _i = 0, recurreneElement_1 = recurreneElement; _i < recurreneElement_1.length; _i++) {
            var element = recurreneElement_1[_i];
            var instance = void 0;
            if (element.classList.contains('e-datepicker')) {
                instance = element.ej2_instances[0];
                if (instance.value) {
                    // eslint-disable-next-line no-self-assign
                    instance.value = instance.value;
                    instance.dataBind();
                }
                else {
                    this.updateUntilDate(this.startDate);
                }
            }
            else if (element.classList.contains('e-dropdownlist')) {
                instance = element.ej2_instances[0];
                instance.index = instance.index || 0;
                instance.dataBind();
            }
            else if (element.classList.contains('e-numerictextbox')) {
                instance = element.ej2_instances[0];
                var value = void 0;
                if (instance.element.classList.contains(REPEATINTERVAL)) {
                    value = 1;
                }
                else if (instance.element.classList.contains(ENDONCOUNT)) {
                    value = 10;
                }
                else {
                    value = this.startDate.getDate();
                }
                instance.value = instance.value || value;
                instance.dataBind();
            }
        }
    };
    RecurrenceEditor.prototype.getPopupWidth = function () {
        return Browser.isDevice ? '100%' : 'auto';
    };
    RecurrenceEditor.prototype.renderDatePickers = function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        this.untilDateObj = new DatePicker({
            firstDayOfWeek: this.firstDayOfWeek,
            enableRtl: this.enableRtl,
            locale: this.locale,
            min: this.minDate,
            max: this.maxDate,
            format: (isNullOrUndefined(this.dateFormat) ? this.getFormat('dateFormats') : this.dateFormat),
            change: function (args) {
                if (args.value) {
                    self.triggerChangeEvent();
                }
            }
        });
        this.untilDateObj.appendTo(this.element.querySelector('.' + UNTILDATE));
    };
    RecurrenceEditor.prototype.getFormat = function (formatType) {
        var format;
        if (this.locale === 'en' || this.locale === 'en-US') {
            format = getValue(formatType + '.short', getDefaultDateObject(this.getCalendarMode()));
        }
        else {
            format = getValue('main.' + '' + this.locale + '.dates.calendars.' + this.getCalendarMode() + '.' + formatType + '.short', cldrData);
        }
        return format;
    };
    RecurrenceEditor.prototype.dayButtonRender = function () {
        var _this = this;
        var btns = [].slice.call(this.element.querySelectorAll('.' + DAYWRAPPER + ' button'));
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        for (var _i = 0, btns_1 = btns; _i < btns_1.length; _i++) {
            var btn = btns_1[_i];
            var button = new Button({ isToggle: true, enableRtl: this.enableRtl }, btn);
            this.dayButtons.push(button);
            EventHandler.add(btn, 'click', function (args) {
                var btns = [].slice.call(_this.element.querySelectorAll('.' + DAYWRAPPER + ' button.' + PRIMARY));
                var element = args.target;
                if (!element.classList.contains(PRIMARY)) {
                    addClass([element], PRIMARY);
                    self.triggerChangeEvent();
                }
                else if (btns.length > 1) {
                    removeClass([element], PRIMARY);
                    self.triggerChangeEvent();
                }
            });
        }
    };
    RecurrenceEditor.prototype.radioButtonRender = function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        this.onMonthDay = new RadioButton({
            label: this.localeObj.getConstant(RADIOLABEL),
            enableRtl: this.enableRtl,
            name: 'monthType',
            value: 'day',
            change: function () {
                self.resetFormValues();
                self.triggerChangeEvent();
            }
        });
        this.onMonthDay.appendTo(this.element.querySelector('.' + MONTHEXPANDERELEMENT));
        this.monthButtons.push(this.onMonthDay);
        this.onWeekDay = new RadioButton({
            label: this.localeObj.getConstant('monthExpander'),
            cssClass: 'e-month-type',
            name: 'monthType',
            enableRtl: this.enableRtl,
            value: 'daypos',
            change: function () {
                self.resetFormValues();
                self.triggerChangeEvent();
            }
        });
        this.onWeekDay.appendTo(this.element.querySelector('.' + MONTHEXPANNDERELEM));
        this.monthButtons.push(this.onWeekDay);
    };
    RecurrenceEditor.prototype.numericTextboxRender = function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        this.recurrenceCount = new NumericTextBox({
            value: 10,
            format: '#',
            enableRtl: this.enableRtl,
            min: 1,
            max: 999,
            change: function () {
                self.triggerChangeEvent();
            }
        });
        this.recurrenceCount.appendTo(this.element.querySelector('.' + ENDONCOUNT));
        this.monthDate = new NumericTextBox({
            value: 1,
            format: '#',
            enableRtl: this.enableRtl,
            min: 1,
            max: 31,
            change: function () {
                self.onMonthDay.setProperties({ checked: true });
                self.triggerChangeEvent();
            }
        });
        this.monthDate.appendTo(this.element.querySelector('.' + MONTHDAYWRAPPER));
        this.repeatInterval = new NumericTextBox({
            value: 1,
            format: '#',
            min: 1,
            max: 999,
            enableRtl: this.enableRtl,
            floatLabelType: 'Always',
            placeholder: this.localeObj.getConstant(REPEATEVERY),
            change: function () {
                self.triggerChangeEvent();
            }
        });
        this.repeatInterval.appendTo(this.element.querySelector('.' + REPEATINTERVAL));
    };
    RecurrenceEditor.prototype.renderComponent = function () {
        this.setTemplate();
        this.renderDropdowns();
        this.renderDatePickers();
        this.dayButtonRender();
        this.radioButtonRender();
        this.numericTextboxRender();
    };
    RecurrenceEditor.prototype.rotateArray = function (data, count) {
        var temp;
        for (var index = 0; index < count; index++) {
            temp = data.shift();
            data.push(temp);
        }
    };
    RecurrenceEditor.prototype.getEndData = function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        var dataSource = [];
        this.endTypes.forEach(function (data) {
            dataSource.push({ text: self.localeObj.getConstant(data), value: data });
        });
        return dataSource;
    };
    RecurrenceEditor.prototype.getDayPosition = function (date) {
        var temp = new Date(date.getTime());
        var endDate = new Date(date.getTime());
        var day = date.getDay();
        var positionCollection = [];
        temp = this.calendarUtil.getMonthStartDate(temp);
        endDate = this.calendarUtil.getMonthEndDate(endDate);
        while (temp < endDate) {
            if (temp.getDay() === day) {
                positionCollection.push(temp.getTime());
            }
            temp.setDate(temp.getDate() + 1);
        }
        if (positionCollection.indexOf(date.getTime()) === positionCollection.length - 1) {
            return -1;
        }
        return (positionCollection.indexOf(date.getTime()) + 1);
    };
    RecurrenceEditor.prototype.getRepeatData = function () {
        var data = [];
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        this.frequencies.forEach(function (element) {
            var textValue = (element === NONE) ? NEVER : element;
            data.push({ text: self.localeObj.getConstant(textValue), value: element });
        });
        return data;
    };
    RecurrenceEditor.prototype.getMonthPosData = function () {
        var monthpos = [FIRST, SECOND, THIRD, FOURTH, LAST];
        var monthposValue = {
            first: 1,
            second: 2,
            third: 3,
            fourth: 4,
            last: -1
        };
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var self = this;
        var dataSource = [];
        monthpos.forEach(function (data) {
            dataSource.push({ text: self.localeObj.getConstant(data), value: monthposValue["" + data] });
        });
        return dataSource;
    };
    RecurrenceEditor.prototype.getDayData = function (format) {
        var weekday = [KEYSUNDAY, KEYMONDAY, KEYTUESDAY, KEYWEDNESDAY, KEYTHURSDAY, KEYFRIDAY, KEYSATURDAY];
        var dayData = [];
        var cldrObj;
        this.rotateArray(weekday, this.firstDayOfWeek);
        if (this.locale === 'en' || this.locale === 'en-US') {
            var nameSpaceString = 'days.stand-alone.';
            cldrObj = (getValue(nameSpaceString + format, getDefaultDateObject(this.getCalendarMode())));
        }
        else {
            var nameSpaceString = 'main.' + '' + this.locale + '.dates.calendars.' + this.getCalendarMode() + '.days.stand-alone.' + format;
            cldrObj = (getValue(nameSpaceString, cldrData));
        }
        for (var _i = 0, weekday_1 = weekday; _i < weekday_1.length; _i++) {
            var obj = weekday_1[_i];
            var day = getValue(obj, cldrObj);
            dayData.push({ text: format === 'narrow' ? day : capitalizeFirstWord(day, 'single'), value: valueData["" + obj] });
        }
        return dayData;
    };
    RecurrenceEditor.prototype.getMonthData = function () {
        var monthData = [];
        var cldrObj;
        if (this.locale === 'en' || this.locale === 'en-US') {
            var nameSpaceString = 'months.stand-alone.wide';
            cldrObj = (getValue(nameSpaceString, getDefaultDateObject(this.getCalendarMode())));
        }
        else {
            var nameSpaceString = 'main.' + '' + this.locale + '.dates.calendars.' + this.getCalendarMode() + '.months.stand-alone.wide';
            cldrObj = (getValue(nameSpaceString, cldrData));
        }
        for (var _i = 0, _a = Object.keys(cldrObj); _i < _a.length; _i++) {
            var obj = _a[_i];
            monthData.push({
                text: capitalizeFirstWord(getValue(obj, cldrObj), 'single'),
                value: obj
            });
        }
        return monthData;
    };
    RecurrenceEditor.prototype.setTemplate = function () {
        var dayData = this.getDayData('narrow');
        var fullDay = this.getDayData('wide');
        this.element.innerHTML = '<div class="' + HEADER + '">' +
            '<div class="' + INPUTWARAPPER + ' ' + FORMLEFT + '">' +
            '<input type="text" tabindex="0" class="' + REPEATELEMENT +
            '"label="' + REPEATELEMENT.substr(2) + '" />' +
            '</div><div class="' + INPUTWARAPPER + ' ' +
            INTERVALCLASS + ' ' + FORMRIGHT + '"><table  class="' + RECURRENCETABLE + ' ' + REPEATCONTENTWRAPPER + '"  role="none"><tr>' +
            '<td><input type="text" tabindex="0" id="' + this.element.id + '_' + REPEATINTERVAL + '" class="' + REPEATINTERVAL +
            '"title="' + this.localeObj.getConstant('repeatEvery') + '" /></td>' +
            '<td><span class="' + REPEATCONTENT + '"></span></td>' +
            '</tr></table></div><div class="' + INPUTWARAPPERSIDE + ' ' + DAYWRAPPER + ' ' + FORMLEFT + '">' +
            '<div class=' + WEEKEXPANDERLABEL + '>' + this.localeObj.getConstant(ON$1) + '</div>' +
            '<button type="button" class="e-round" data-index="0" title="' + fullDay[0].text + '">' + dayData[0].text + '</button>' +
            '<button type="button" class="e-round" data-index="1" title="' + fullDay[1].text + '">' + dayData[1].text + '</button>' +
            '<button type="button" class="e-round" data-index="2" title="' + fullDay[2].text + '">' + dayData[2].text + '</button>' +
            '<button type="button" class="e-round" data-index="3" title="' + fullDay[3].text + '">' + dayData[3].text + '</button>' +
            '<button type="button" class="e-round" data-index="4" title="' + fullDay[4].text + '">' + dayData[4].text + '</button>' +
            '<button type="button" class="e-round" data-index="5" title="' + fullDay[5].text + '">' + dayData[5].text + '</button>' +
            '<button type="button" class="e-round" data-index="6" title="' + fullDay[6].text + '">' + dayData[6].text + '</button></div>' +
            '<div class="' + INPUTWARAPPERSIDE + ' ' + WEEKWRAPPER + ' ' + FORMLEFT + '">' +
            '<div class=' + MONTHEXPANDERLABEL + '>' + this.localeObj.getConstant(ON$1) + '</div>' +
            '<div class="' + YEAREXPANDERWRAPPER + '">' +
            '<input class="' + YEAREXPANDERELEMENT + '" type="text" tabindex="0" title="' +
            this.localeObj.getConstant('yearExpander') + '"/>' +
            '</div>' +
            '<div class="' + MONETHEXPANDERWRAPPER + '">' +
            '<table class="' + RECURRENCETABLE + ' ' + MONETHEXPANDWRAPPER + '" role="none"><tr><td>' +
            '<div class="' + INPUTWARAPPER + ' ' + MONTHEXPANDERCHECKBOXWRAPPER + '">' +
            '<input class="' + MONTHEXPANDERELEMENT + '"title="' + this.localeObj.getConstant('monthExpander') + '" type="radio">' +
            '</div></td>' +
            '<td colspan="2"><div class="' + INPUTWARAPPER + ' ' + MONTHDAYELEMENT + '">' +
            '<input type="text" tabindex="0" id="' + this.element.id + '_' + MONTHDAYWRAPPER + '" class="' + MONTHDAYWRAPPER + '"title="' +
            this.localeObj.getConstant('on') + '" />' +
            '</div></td></tr>' +
            '<tr><td>' +
            '<div class="' + INPUTWARAPPER + ' ' + MONTHEXPANDERCHECKBOXWRAPPER + '" style="min-width: 30px;margin-bottom:18px;">' +
            '<input class="' + MONTHEXPANNDERELEM + '"title="' + this.localeObj.getConstant('monthExpander') + '" type="radio">' +
            '</div></td>' +
            '<td><div class="' + INPUTWARAPPER + ' ' + WEEKPOSITION + '" >' +
            '<input type="text" tabindex="0" class="' + MONTHPOS + '"title="' + this.localeObj.getConstant('monthPosition') + '" />' +
            '</div></td>' +
            '<td><div class="' + INPUTWARAPPER + ' ' + DAYPOSITION + '">' +
            '<input type="text" tabindex="0" class="' + MONTHWEEK + '"title="' + this.localeObj.getConstant('monthWeek') + '" />' +
            '</div></td></tr></table>' +
            '</div></div>' +
            '<div class="' + INPUTWARAPPERSIDE + ' ' + ENDON + ' ' + FORMRIGHT + '">' +
            '<div class=' + ENDONLABEL + '>' + this.localeObj.getConstant(END) + '</div>' +
            '<div class="' + INPUTWARAPPER + ' ' + ENDONLEFT + '">' +
            '<input type="text" tabindex="0" class="' + ENDONELEMENT + '"title="' + this.localeObj.getConstant(END) + '" />' +
            '</div>' +
            '<div class="' + INPUTWARAPPER + ' ' + ENDONDATE + '" >' +
            '<input type="text" tabindex="0" class="' + UNTILDATE + '"title="' + this.localeObj.getConstant(UNTIL$1) + '" />' +
            '</div>' +
            '<div class="' + INPUTWARAPPER + ' ' + ENDONCOUNTWRAPPER + '">' +
            '<input type="text" tabindex="0" id="' + this.element.id + '_' + ENDONCOUNT + '" class="' + ENDONCOUNT + '"title="' + this.localeObj.getConstant(COUNT) + '" />' +
            '</div></div>' +
            '</div></div>';
    };
    RecurrenceEditor.prototype.getSelectedDaysData = function () {
        var ruleData = RULEBYDAY + EQUAL;
        var elements = [].slice.call(this.element.querySelectorAll('.' + DAYWRAPPER + ' button.' + PRIMARY));
        var weekday = [RULESUNDAY, RULEMONDAY, RULETUESDAY, RULEWEDNESDAY, RULETHURSDAY, RULEFRIDAY, RULESATURDAY];
        this.rotateArray(weekday, this.firstDayOfWeek);
        for (var index = 0; index < elements.length; index++) {
            ruleData += weekday[parseInt(elements[parseInt(index.toString(), 10)].getAttribute('data-index'), 10)] + (index === (elements.length - 1) ? '' : COMMA);
        }
        return ruleData + SEMICOLON;
    };
    RecurrenceEditor.prototype.getSelectedMonthData = function () {
        var ruleData;
        if (this.onWeekDay.checked) {
            ruleData = RULEBYDAY + EQUAL + this.monthWeekDays.value + SEMICOLON
                + RULESETPOS + EQUAL + this.monthWeekPos.value + SEMICOLON;
        }
        else {
            ruleData = RULEBYMONTHDAY + EQUAL + this.monthDate.value + SEMICOLON;
        }
        return ruleData;
    };
    RecurrenceEditor.prototype.getIntervalData = function () {
        return RULEINTERVAL + EQUAL + this.repeatInterval.value + SEMICOLON;
    };
    RecurrenceEditor.prototype.getEndOnCount = function () {
        return RULECOUNT + EQUAL + this.recurrenceCount.value + SEMICOLON;
    };
    RecurrenceEditor.prototype.getYearMonthRuleData = function () {
        return RULEBYMONTH + EQUAL + this.monthValue.value + SEMICOLON;
    };
    RecurrenceEditor.prototype.updateWeekButton = function (keys) {
        var weekday = [RULESUNDAY, RULEMONDAY, RULETUESDAY, RULEWEDNESDAY, RULETHURSDAY, RULEFRIDAY, RULESATURDAY];
        this.rotateArray(weekday, this.firstDayOfWeek);
        for (var _i = 0, _a = this.dayButtons; _i < _a.length; _i++) {
            var obj = _a[_i];
            var index = parseInt(obj.element.getAttribute('data-index'), 10);
            if (keys.indexOf(weekday[parseInt(index.toString(), 10)]) !== -1) {
                obj.setProperties({ isPrimary: true });
            }
            else {
                obj.setProperties({ isPrimary: false });
            }
        }
    };
    RecurrenceEditor.prototype.updateMonthUI = function () {
        if (this.ruleObject.monthDay.length) {
            this.monthDate.setProperties({ value: this.ruleObject.monthDay[0] });
            this.onMonthDay.setProperties({ checked: true });
        }
        else {
            this.onWeekDay.setProperties({ checked: true });
            this.monthWeekPos.setProperties({ value: this.ruleObject.setPosition });
            for (var _i = 0, _a = Object.keys(valueData); _i < _a.length; _i++) {
                var key = _a[_i];
                if (valueData["" + key] === this.ruleObject.day[0]) {
                    this.monthWeekDays.setProperties({ value: this.ruleObject.day[0] });
                    break;
                }
            }
        }
    };
    RecurrenceEditor.prototype.updateUI = function (repeat, state) {
        this.repeatInterval.setProperties({ value: this.ruleObject.interval });
        switch (state) {
            case UNTIL$1:
                this.untilDateObj.setProperties({ value: this.ruleObject.until });
                break;
            case COUNT:
                this.recurrenceCount.setProperties({ value: this.ruleObject.count });
                break;
        }
        switch (repeat) {
            case WEEKLY:
                this.updateWeekButton(this.ruleObject.day);
                break;
            case YEARLY:
                this.monthValue.setProperties({ index: (this.ruleObject.month[0] - 1) });
                this.updateMonthUI();
                break;
            case MONTHLY:
                this.updateMonthUI();
                break;
        }
    };
    RecurrenceEditor.prototype.getUntilData = function () {
        if (!this.untilDateObj.value) {
            return '';
        }
        var tempStr = getRecurrenceStringFromDate(this.untilDateObj.value);
        return RULEUNTIL + EQUAL + tempStr + SEMICOLON;
    };
    RecurrenceEditor.prototype.destroyComponents = function () {
        if (!this.recurrenceCount.isDestroyed) {
            this.recurrenceCount.destroy();
            this.recurrenceCount = null;
        }
        if (!this.monthDate.isDestroyed) {
            this.monthDate.destroy();
            this.monthDate = null;
        }
        if (!this.repeatInterval.isDestroyed) {
            this.repeatInterval.destroy();
            this.repeatInterval = null;
        }
        if (!this.untilDateObj.isDestroyed) {
            this.untilDateObj.destroy();
            this.untilDateObj = null;
        }
        if (!this.repeatType.isDestroyed) {
            this.repeatType.destroy();
            this.repeatType = null;
        }
        if (!this.endType.isDestroyed) {
            this.endType.destroy();
            this.endType = null;
        }
        if (!this.monthWeekPos.isDestroyed) {
            this.monthWeekPos.destroy();
            this.monthWeekPos = null;
        }
        if (!this.monthWeekDays.isDestroyed) {
            this.monthWeekDays.destroy();
            this.monthWeekDays = null;
        }
        if (!this.monthValue.isDestroyed) {
            this.monthValue.destroy();
            this.monthValue = null;
        }
        if (!this.onMonthDay.isDestroyed) {
            this.onMonthDay.destroy();
            this.onMonthDay = null;
        }
        if (!this.onWeekDay.isDestroyed) {
            this.onWeekDay.destroy();
            this.onWeekDay = null;
        }
        this.dayButtons.forEach(function (element) {
            if (!element.isDestroyed) {
                element.destroy();
            }
        });
        this.dayButtons = [];
        this.monthButtons.forEach(function (element) {
            if (!element.isDestroyed) {
                element.destroy();
            }
        });
        this.monthButtons = [];
    };
    RecurrenceEditor.prototype.resetFields = function () {
        this.startState(NONE, this.endTypes[0], this.startDate);
        this.setDefaultValue();
    };
    RecurrenceEditor.prototype.updateRuleUntilDate = function (startDate) {
        if (this.untilDateObj.value && startDate) {
            var untilDate = this.untilDateObj.value;
            var newUntilDate = new Date(untilDate.getFullYear(), untilDate.getMonth(), untilDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getMilliseconds());
            this.untilDateObj.setProperties({ value: newUntilDate });
        }
    };
    RecurrenceEditor.prototype.getCalendarMode = function () {
        return this.calendarMode.toLowerCase();
    };
    RecurrenceEditor.prototype.getRuleSummary = function (rule) {
        if (rule === void 0) { rule = this.getRecurrenceRule(); }
        return generateSummary(rule, this.localeObj, this.locale, this.calendarMode);
    };
    RecurrenceEditor.prototype.getRecurrenceDates = function (startDate, rule, excludeDate, maximumCount, viewDate) {
        viewDate = isNullOrUndefined(viewDate) ? this.startDate : viewDate;
        return generate(startDate, rule, excludeDate, this.firstDayOfWeek, maximumCount, viewDate, this.calendarMode);
    };
    RecurrenceEditor.prototype.getRecurrenceRule = function () {
        var ruleData = RULEFREQ + EQUAL;
        switch (this.repeatType.value) {
            case DAILY:
                ruleData += RULEDAILY + SEMICOLON;
                break;
            case WEEKLY:
                ruleData += RULEWEEKLY + SEMICOLON + this.getSelectedDaysData();
                break;
            case MONTHLY:
                ruleData += RULEMONTHLY + SEMICOLON + this.getSelectedMonthData();
                break;
            case YEARLY:
                ruleData += RULEYEARLY + SEMICOLON + this.getSelectedMonthData() + this.getYearMonthRuleData();
                break;
            case NONE:
                return '';
        }
        ruleData += this.getIntervalData();
        switch (this.endType.value) {
            case UNTIL$1:
                ruleData += this.getUntilData();
                break;
            case COUNT:
                ruleData += this.getEndOnCount();
                break;
        }
        return ruleData;
    };
    RecurrenceEditor.prototype.setRecurrenceRule = function (rule, startDate) {
        if (startDate === void 0) { startDate = this.startDate; }
        if (!rule) {
            this.repeatType.setProperties({ value: NONE });
            return;
        }
        this.renderStatus = false;
        this.ruleObject = extractObjectFromRule(rule);
        var endon = this.ruleObject.count ? COUNT : (this.ruleObject.until ? UNTIL$1 : NEVER);
        switch (this.ruleObject.freq) {
            case RULEDAILY:
                this.startState(DAILY, endon, startDate);
                this.updateUI(DAILY, endon);
                break;
            case RULEWEEKLY:
                this.startState(WEEKLY, endon, startDate);
                this.updateUI(WEEKLY, endon);
                break;
            case RULEMONTHLY:
                this.startState(MONTHLY, endon, startDate);
                this.updateUI(MONTHLY, endon);
                break;
            case RULEYEARLY:
                this.startState(YEARLY, endon, startDate);
                this.updateUI(YEARLY, endon);
                break;
        }
        this.renderStatus = true;
        this.triggerChangeEvent();
    };
    RecurrenceEditor.prototype.detachInputs = function () {
        var inputElements = [].slice.call(this.element.querySelectorAll('input'));
        for (var _i = 0, inputElements_1 = inputElements; _i < inputElements_1.length; _i++) {
            var element = inputElements_1[_i];
            detach(element);
        }
    };
    /**
     * Destroys the widget.
     *
     * @returns {void}
     */
    RecurrenceEditor.prototype.destroy = function () {
        if (!this.isDestroyed) {
            this.destroyComponents();
            _super.prototype.destroy.call(this);
            var removeClasses = ['e-' + this.getModuleName()];
            if (this.cssClass) {
                removeClasses = removeClasses.concat(this.cssClass.split(' '));
            }
            removeClass([this.element], removeClasses);
            this.detachInputs();
            while (this.element.firstElementChild) {
                this.element.removeChild(this.element.firstElementChild);
            }
        }
    };
    /**
     * Get component name.
     *
     * @returns {string} Returns the module name
     * @private
     */
    RecurrenceEditor.prototype.getModuleName = function () {
        return 'recurrenceeditor';
    };
    /**
     * Get the properties to be maintained in the persisted state.
     *
     * @returns {string} Returns the persisted state
     */
    RecurrenceEditor.prototype.getPersistData = function () {
        return this.addOnPersist([]);
    };
    /**
     * Initialize the control rendering
     *
     * @returns {void}
     * @private
     */
    RecurrenceEditor.prototype.render = function () {
        this.initialize();
        this.rtlClass(this.enableRtl);
        this.renderStatus = true;
        this.renderComplete();
    };
    /**
     * Called internally, if any of the property value changed.
     *
     * @param {RecurrenceEditorModel} newProp Accepts the changed properties new values
     * @param {RecurrenceEditorModel} oldProp Accepts the changed properties old values
     * @returns {void}
     * @private
     */
    RecurrenceEditor.prototype.onPropertyChanged = function (newProp, oldProp) {
        for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
            var prop = _a[_i];
            switch (prop) {
                case 'startDate':
                    this.selectMonthDay(newProp.startDate);
                    this.updateUntilDate(newProp.startDate);
                    this.endType.setProperties({ index: 0 });
                    break;
                case 'enableRtl':
                    this.rtlClass(newProp.enableRtl);
                    break;
                case 'cssClass':
                    if (oldProp.cssClass) {
                        removeClass([this.element], oldProp.cssClass.split(' '));
                    }
                    if (newProp.cssClass) {
                        addClass([this.element], newProp.cssClass.split(' '));
                    }
                    break;
                case 'selectedType':
                    this.repeatType.setProperties({ index: this.selectedType });
                    break;
                case 'minDate':
                    this.untilDateObj.setProperties({ minDate: this.minDate });
                    break;
                case 'maxDate':
                    this.untilDateObj.setProperties({ maxDate: this.maxDate });
                    break;
                case 'value':
                    if (this.getRecurrenceRule() !== this.value) {
                        this.setRecurrenceRule(this.value);
                    }
                    break;
                case 'calendarMode':
                    this.calendarMode = newProp.calendarMode;
                    this.calendarUtil = getCalendarUtil(newProp.calendarMode);
                    break;
                case 'locale':
                case 'frequencies':
                case 'firstDayOfWeek':
                case 'endTypes':
                    this.refresh();
                    break;
                case 'dateFormat':
                    this.untilDateObj.setProperties({ format: newProp.dateFormat });
                    break;
            }
        }
    };
    __decorate$1([
        Property(['none', 'daily', 'weekly', 'monthly', 'yearly'])
    ], RecurrenceEditor.prototype, "frequencies", void 0);
    __decorate$1([
        Property(['never', 'until', 'count'])
    ], RecurrenceEditor.prototype, "endTypes", void 0);
    __decorate$1([
        Property(0)
    ], RecurrenceEditor.prototype, "firstDayOfWeek", void 0);
    __decorate$1([
        Property(new Date())
    ], RecurrenceEditor.prototype, "startDate", void 0);
    __decorate$1([
        Property()
    ], RecurrenceEditor.prototype, "dateFormat", void 0);
    __decorate$1([
        Property('Gregorian')
    ], RecurrenceEditor.prototype, "calendarMode", void 0);
    __decorate$1([
        Property()
    ], RecurrenceEditor.prototype, "cssClass", void 0);
    __decorate$1([
        Property()
    ], RecurrenceEditor.prototype, "value", void 0);
    __decorate$1([
        Property(new Date(1900, 0, 1))
    ], RecurrenceEditor.prototype, "minDate", void 0);
    __decorate$1([
        Property(new Date(2099, 11, 31))
    ], RecurrenceEditor.prototype, "maxDate", void 0);
    __decorate$1([
        Property(0)
    ], RecurrenceEditor.prototype, "selectedType", void 0);
    __decorate$1([
        Event()
    ], RecurrenceEditor.prototype, "change", void 0);
    RecurrenceEditor = __decorate$1([
        NotifyPropertyChanges
    ], RecurrenceEditor);
    return RecurrenceEditor;
}(Component));

/* eslint-disable @typescript-eslint/no-explicit-any */
var EVENT_FIELD$1 = 'e-field';
var REPEAT_CONTAINER_CLASS = 'e-recurrence-container';
var REPEAT_BUTTON_ICON_CLASS = 'e-recurrence-edit';
var REPEAT_BUTTON_CLASS = 'e-recurrence-edit-button';
var REPEAT_DIALOG_CLASS = 'e-recurrence-dialog';
var HIDE_STYLE_CLASS = 'e-hide';
/**
 * Event editor window
 */
var EventWindow = /** @__PURE__ @class */ (function () {
    function EventWindow(parent) {
        this.parent = parent;
        this.l10n = this.parent.localeObj;
        this.fields = this.parent.eventFields;
        this.eventWindowTime = { startTime: new Date(), endTime: new Date() };
        this.renderEventWindow();
    }
    EventWindow.prototype.renderEventWindow = function () {
        this.element = createElement('div', { id: this.parent.element.id + '_dialog_wrapper' });
        this.parent.element.appendChild(this.element);
        var dialogModel = {
            animationSettings: { effect: 'Zoom' },
            content: this.getEventWindowContent(),
            cssClass: EVENT_WINDOW_DIALOG_CLASS,
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            height: this.parent.isAdaptive ? '100%' : 'auto',
            minHeight: '300px',
            isModal: true,
            showCloseIcon: this.parent.isAdaptive ? false : true,
            target: document.body,
            visible: false,
            width: '500px',
            beforeOpen: this.onBeforeOpen.bind(this),
            beforeClose: this.onBeforeClose.bind(this)
        };
        if (this.parent.isAdaptive) {
            dialogModel.cssClass = EVENT_WINDOW_DIALOG_CLASS + ' ' + DEVICE_CLASS;
            if (!this.parent.editorHeaderTemplate) {
                dialogModel.header = '<div class="e-title-header"><div class="e-back-icon e-icons"></div><div class="e-title-text">' +
                    this.l10n.getConstant('newEvent') + '</div><div class="e-save-icon e-icons"></div></div>';
            }
        }
        else {
            if (!this.parent.editorFooterTemplate) {
                this.renderDialogButtons(dialogModel);
            }
            if (!this.parent.editorHeaderTemplate) {
                dialogModel.header = '<div class="e-title-text">' + this.l10n.getConstant('newEvent') + '</div>';
            }
        }
        this.dialogObject = new Dialog(dialogModel, this.element);
        if (this.dialogObject.element.querySelector('.e-dlg-closeicon-btn')) {
            this.dialogObject.element.querySelector('.e-dlg-closeicon-btn').setAttribute('title', this.l10n.getConstant('close'));
        }
        this.addEventHandlers();
        addClass([this.element.parentElement], EVENT_WINDOW_DIALOG_CLASS + '-container');
        EventHandler.add(this.dialogObject.element, 'keydown', this.preventEventSave, this);
        this.applyFormValidation();
    };
    EventWindow.prototype.renderDialogButtons = function (dialogButton) {
        dialogButton.buttons = [{
                buttonModel: {
                    content: this.l10n.getConstant('deleteButton'), cssClass: DELETE_EVENT_CLASS,
                    disabled: !this.parent.eventSettings.allowDeleting || this.parent.readonly
                },
                click: this.eventDelete.bind(this)
            }, {
                buttonModel: {
                    content: this.l10n.getConstant('saveButton'), cssClass: 'e-primary ' + EVENT_WINDOW_SAVE_BUTTON_CLASS,
                    isPrimary: true, disabled: !this.parent.eventSettings.allowAdding || this.parent.readonly
                },
                click: this.eventSave.bind(this)
            }, {
                buttonModel: { cssClass: EVENT_WINDOW_CANCEL_BUTTON_CLASS, content: this.l10n.getConstant('cancelButton') },
                click: this.dialogClose.bind(this)
            }];
    };
    EventWindow.prototype.addEventHandlers = function () {
        var backIcon = this.element.querySelector('.' + EVENT_WINDOW_BACK_ICON_CLASS);
        var saveIcon = this.element.querySelector('.' + EVENT_WINDOW_SAVE_ICON_CLASS);
        if (this.parent.isAdaptive && !isNullOrUndefined(backIcon) && !isNullOrUndefined(saveIcon)) {
            EventHandler.add(backIcon, 'click', this.dialogClose, this);
            EventHandler.add(saveIcon, 'click', this.eventSave, this);
        }
    };
    EventWindow.prototype.refresh = function () {
        this.destroy(true);
        this.renderEventWindow();
    };
    EventWindow.prototype.refreshRecurrenceEditor = function () {
        if (this.recurrenceEditor) {
            var recurrenceEditor = this.recurrenceEditor.element;
            this.recurrenceEditor.destroy();
            this.createRecurrenceEditor(recurrenceEditor);
        }
    };
    EventWindow.prototype.setRecurrenceEditor = function (recurrenceEditor) {
        if (this.parent.editorTemplate) {
            this.recurrenceEditor = recurrenceEditor;
        }
    };
    EventWindow.prototype.openEditor = function (data, type, isEventData, repeatType) {
        this.parent.currentAction = type;
        this.parent.removeNewEventElement();
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide(true);
        }
        this.parent.inlineModule.removeInlineAppointmentElement();
        if (type === 'Add') {
            var eventObj = {};
            this.cellClickAction = !isEventData;
            this.parent.activeCellsData = data;
            var event_1 = data;
            if (this.cellClickAction) {
                this.convertToEventData(event_1, eventObj);
            }
            else {
                this.parent.activeCellsData = {
                    startTime: (event_1.startTime || event_1[this.fields.startTime]),
                    endTime: (event_1.endTime || event_1[this.fields.endTime]),
                    isAllDay: (event_1.isAllDay || event_1[this.fields.isAllDay]),
                    element: event_1.element,
                    groupIndex: event_1.groupIndex
                };
                eventObj = event_1;
            }
            data = eventObj;
        }
        if (!isNullOrUndefined(this.parent.editorHeaderTemplate)) {
            this.parent.resetTemplates(['editorHeaderTemplate']);
            if (this.parent.isAdaptive && !this.parent.editorFooterTemplate) {
                this.dialogObject.header = this.createAdaptiveHeaderElement(data);
            }
            else {
                this.dialogObject.header = this.getDialogHeader(data);
            }
        }
        if (!isNullOrUndefined(this.parent.editorFooterTemplate)) {
            this.parent.resetTemplates(['editorFooterTemplate']);
            this.dialogObject.footerTemplate = this.getDialogFooter(data);
        }
        if (!isNullOrUndefined(this.parent.editorHeaderTemplate) || !isNullOrUndefined(this.parent.editorFooterTemplate)) {
            this.dialogObject.dataBind();
            this.addEventHandlers();
        }
        if (!isNullOrUndefined(this.parent.editorTemplate)) {
            this.renderFormElements(this.element.querySelector('.e-schedule-form'), data);
        }
        if (!this.parent.isAdaptive && isNullOrUndefined(this.parent.editorTemplate)) {
            removeClass([this.dialogObject.element.querySelector('.e-recurrenceeditor')], DISABLE_CLASS);
        }
        if (this.recurrenceEditor) {
            this.recurrenceEditor.firstDayOfWeek = this.parent.activeViewOptions.firstDayOfWeek;
        }
        switch (type) {
            case 'Add':
                this.onCellDetailsUpdate(data, repeatType);
                break;
            case 'Save':
            case 'EditOccurrence':
            case 'EditSeries':
            case 'EditFollowingEvents':
                if (type === 'EditOccurrence' && !this.parent.isAdaptive && isNullOrUndefined(this.parent.editorTemplate)) {
                    addClass([this.dialogObject.element.querySelector('.e-recurrenceeditor')], DISABLE_CLASS);
                }
                this.cellClickAction = false;
                this.onEventDetailsUpdate(data);
                break;
        }
    };
    EventWindow.prototype.setDialogContent = function () {
        this.dialogObject.content = this.getEventWindowContent();
        this.dialogObject.dataBind();
        this.applyFormValidation();
    };
    EventWindow.prototype.setDialogHeader = function () {
        if (!isNullOrUndefined(this.parent.editorHeaderTemplate)) {
            this.parent.resetTemplates(['editorHeaderTemplate']);
            if (this.parent.isAdaptive && !this.parent.editorFooterTemplate) {
                this.dialogObject.header = this.createAdaptiveHeaderElement();
            }
            else {
                this.dialogObject.header = this.getDialogHeader();
            }
        }
        else if (this.parent.isAdaptive) {
            this.dialogObject.header = '<div class="e-title-header"><div class="e-back-icon e-icons"></div><div class="e-title-text">' +
                this.l10n.getConstant('newEvent') + '</div><div class="e-save-icon e-icons"></div></div>';
        }
        else {
            this.dialogObject.header = '<div class="e-title-text">' + this.l10n.getConstant('newEvent') + '</div>';
        }
        this.dialogObject.dataBind();
        this.addEventHandlers();
    };
    EventWindow.prototype.setDialogFooter = function () {
        if (!isNullOrUndefined(this.parent.editorFooterTemplate)) {
            this.parent.resetTemplates(['editorFooterTemplate']);
            this.dialogObject.footerTemplate = this.getDialogFooter();
        }
        else if (!this.parent.isAdaptive && isNullOrUndefined(this.parent.editorFooterTemplate)) {
            this.renderDialogButtons(this.dialogObject);
        }
        else if (this.parent.isAdaptive && isNullOrUndefined(this.parent.editorFooterTemplate)) {
            this.dialogObject.footerTemplate = null;
        }
        this.dialogObject.dataBind();
    };
    EventWindow.prototype.createAdaptiveHeaderElement = function (data) {
        var header = createElement('div', { className: 'e-title-header' });
        var headerBackIcon = createElement('div', { className: 'e-back-icon e-icons' });
        header.appendChild(headerBackIcon);
        var headerTemplate = this.getDialogHeader(data);
        header.appendChild(headerTemplate);
        var headerSaveIcon = createElement('div', { className: 'e-save-icon e-icons' });
        header.appendChild(headerSaveIcon);
        return header;
    };
    EventWindow.prototype.getDialogHeader = function (args) {
        var headerTemplate = [];
        var headerTemplateId = this.parent.element.id + '_editorHeaderTemplate';
        var temHeaderDiv = document.createElement('div');
        headerTemplate = [].slice.call(this.parent.getEditorHeaderTemplate()(args || {}, this.parent, 'editorHeaderTemplate', headerTemplateId, false));
        append(headerTemplate, temHeaderDiv);
        return temHeaderDiv;
    };
    EventWindow.prototype.getDialogFooter = function (args) {
        var footerTemplate = [];
        var footerTemplateId = this.parent.element.id + '_editorFooterTemplate';
        var temFooterDiv = document.createElement('div');
        footerTemplate = [].slice.call(this.parent.getEditorFooterTemplate()(args || {}, this.parent, 'editorFooterTemplate', footerTemplateId, false));
        append(footerTemplate, temFooterDiv);
        return temFooterDiv;
    };
    EventWindow.prototype.preventEventSave = function (e) {
        if (this.parent && !this.parent.allowKeyboardInteraction && e.code === 'Enter') {
            this.isEnterKey = true;
        }
    };
    EventWindow.prototype.onBeforeOpen = function (args) {
        var _this = this;
        var endTime = this.eventData[this.fields.endTime].getTime();
        var eventProp = {
            type: 'Editor',
            data: this.eventData,
            cancel: false,
            element: this.element,
            target: (this.cellClickAction ? this.parent.activeCellsData.element : this.parent.activeEventData.element)
        };
        if (this.cellClickAction) {
            eventProp.duration = this.getSlotDuration();
        }
        var saveObj = this.getInstance(EVENT_WINDOW_SAVE_BUTTON_CLASS);
        if (saveObj) {
            saveObj.disabled = !(this.cellClickAction ? this.parent.eventSettings.allowAdding : this.parent.eventSettings.allowEditing);
            saveObj.dataBind();
        }
        var deleteObj = this.getInstance(DELETE_EVENT_CLASS);
        if (deleteObj) {
            deleteObj.disabled = !this.parent.eventSettings.allowDeleting;
            deleteObj.dataBind();
        }
        var callBackPromise = new Deferred();
        this.parent.trigger(popupOpen, eventProp, function (popupArgs) {
            args.cancel = popupArgs.cancel;
            _this.duration = _this.cellClickAction ? popupArgs.duration : null;
            if (_this.eventData[_this.fields.endTime].getTime() === endTime && !_this.cellClickAction &&
                _this.eventData[_this.fields.endTime].getHours() === 0 &&
                _this.eventData[_this.fields.endTime].getMinutes() === 0) {
                _this.eventData = extend({}, _this.eventData, null, true);
                _this.trimAllDay(_this.eventData);
            }
            _this.refreshDateTimePicker(_this.duration);
            if (_this.cellClickAction && popupArgs.duration !== _this.getSlotDuration() && isNullOrUndefined(_this.parent.editorTemplate)) {
                var startObj = _this.getInstance(EVENT_WINDOW_START_CLASS);
                var endObj = _this.getInstance(EVENT_WINDOW_END_CLASS);
                endObj.value = new Date(startObj.value.getTime() + (MS_PER_MINUTE * popupArgs.duration));
                endObj.dataBind();
            }
            if (_this.parent.editorTemplate && _this.element.querySelector('.e-recurrenceeditor') && !_this.recurrenceEditor) {
                _this.recurrenceEditor = _this.getInstance('e-recurrenceeditor');
            }
            callBackPromise.resolve(args);
        });
        return callBackPromise;
    };
    EventWindow.prototype.onBeforeClose = function (args) {
        var _this = this;
        if (args.isInteracted) {
            this.isCrudAction = false;
        }
        var eventProp = {
            type: 'Editor',
            event: args.event || this.dialogEvent,
            data: this.eventCrudData,
            cancel: false,
            element: this.element,
            target: (this.cellClickAction ? this.parent.activeCellsData.element : this.parent.activeEventData.element)
        };
        var callBackPromise = new Deferred();
        this.parent.trigger(popupClose, eventProp, function (popupArgs) {
            args.cancel = popupArgs.cancel;
            if (!popupArgs.cancel) {
                if (_this.isCrudAction) {
                    args.cancel = _this.processCrudActions(popupArgs.data);
                    _this.isCrudAction = args.cancel;
                }
                if (!_this.isCrudAction) {
                    _this.resetForm();
                    _this.parent.eventBase.focusElement(true);
                    _this.eventCrudData = null;
                }
            }
            callBackPromise.resolve(args);
        });
        return callBackPromise;
    };
    EventWindow.prototype.getEventWindowContent = function () {
        var container = createElement('div', { className: FORM_CONTAINER_CLASS });
        var form = createElement('form', {
            id: this.parent.element.id + 'EditForm',
            className: FORM_CLASS,
            attrs: { onsubmit: 'return false;' }
        });
        this.renderFormElements(form);
        container.appendChild(form);
        return container;
    };
    EventWindow.prototype.renderFormElements = function (form, args) {
        var _this = this;
        if (!isNullOrUndefined(this.parent.editorTemplate)) {
            if (args) {
                if (this.fieldValidator) {
                    this.fieldValidator.destroy();
                    this.fieldValidator = null;
                }
                if (this.recurrenceEditor) {
                    this.recurrenceEditor.destroy();
                    this.recurrenceEditor = null;
                }
                this.destroyComponents();
                var formElements = [].slice.call(form.children);
                for (var _i = 0, formElements_1 = formElements; _i < formElements_1.length; _i++) {
                    var element = formElements_1[_i];
                    remove(element);
                }
                this.parent.resetTemplates(['editorTemplate']);
            }
            var templateId = this.parent.element.id + '_editorTemplate';
            var tempEle = [].slice.call(this.parent.getEditorTemplate()(args || {}, this.parent, 'editorTemplate', templateId, false));
            append(tempEle, form);
            this.parent.renderTemplates(function () {
                if (_this.element) {
                    _this.applyFormValidation();
                    if (_this.eventCrudData) {
                        _this.showDetails(_this.eventCrudData);
                        _this.eventCrudData = null;
                    }
                }
            });
        }
        else {
            form.appendChild(this.getDefaultEventWindowContent());
        }
    };
    EventWindow.prototype.getDefaultEventWindowContent = function () {
        var parentDiv = this.createDivElement(EVENT_WINDOW_DIALOG_PARENT_CLASS);
        var titleLocationDiv = this.createDivElement(EVENT_WINDOW_TITLE_LOCATION_DIV_CLASS);
        parentDiv.appendChild(titleLocationDiv);
        titleLocationDiv.appendChild(this.renderTextBox(SUBJECT_CLASS));
        titleLocationDiv.querySelector('.' + SUBJECT_CLASS).setAttribute('title', this.parent.editorTitles.subject);
        titleLocationDiv.appendChild(this.renderTextBox(LOCATION_CLASS));
        titleLocationDiv.querySelector('.' + LOCATION_CLASS).setAttribute('title', this.parent.editorTitles.location);
        var startEndDateTimeDiv = this.createDivElement(EVENT_WINDOW_START_END_DIV_CLASS);
        parentDiv.appendChild(startEndDateTimeDiv);
        startEndDateTimeDiv.appendChild(this.renderDateTimePicker(EVENT_WINDOW_START_CLASS, this.onTimeChange.bind(this)));
        startEndDateTimeDiv.querySelector('.' + EVENT_WINDOW_START_CLASS).setAttribute('title', this.parent.editorTitles.startTime);
        startEndDateTimeDiv.appendChild(this.renderDateTimePicker(EVENT_WINDOW_END_CLASS));
        startEndDateTimeDiv.querySelector('.' + EVENT_WINDOW_END_CLASS).setAttribute('title', this.parent.editorTitles.endTime);
        var allDayTimezoneDiv = this.createDivElement(EVENT_WINDOW_ALLDAY_TZ_DIV_CLASS);
        parentDiv.appendChild(allDayTimezoneDiv);
        allDayTimezoneDiv.appendChild(this.renderCheckBox(EVENT_WINDOW_ALL_DAY_CLASS));
        allDayTimezoneDiv.appendChild(this.renderCheckBox(TIME_ZONE_CLASS));
        var timezoneParentDiv = this.createDivElement(EVENT_WINDOW_TIME_ZONE_DIV_CLASS);
        parentDiv.appendChild(timezoneParentDiv);
        timezoneParentDiv.appendChild(this.renderDropDown(EVENT_WINDOW_START_TZ_CLASS));
        timezoneParentDiv.appendChild(this.renderDropDown(EVENT_WINDOW_END_TZ_CLASS));
        var repeatParentDiv = this.createDivElement(EVENT_WINDOW_REPEAT_DIV_CLASS);
        parentDiv.appendChild(repeatParentDiv);
        var repeatDiv = this.renderCheckBox(EVENT_WINDOW_REPEAT_CLASS);
        var repeatEditContainer = createElement('span', { className: REPEAT_CONTAINER_CLASS });
        var button = createElement('button', {
            className: REPEAT_BUTTON_CLASS,
            attrs: { type: 'button', 'title': this.l10n.getConstant('editRecurrence') }
        });
        this.buttonObj = new Button({ iconCss: REPEAT_BUTTON_ICON_CLASS + ' e-icons', cssClass: 'e-medium ' + this.parent.cssClass });
        repeatEditContainer.appendChild(button);
        this.buttonObj.appendTo(button);
        repeatDiv.appendChild(repeatEditContainer);
        repeatParentDiv.appendChild(repeatDiv);
        if (this.parent.isAdaptive) {
            EventHandler.add(button, 'click', this.loadRecurrenceEditor, this);
        }
        else {
            this.createRecurrenceEditor(parentDiv);
        }
        if (this.parent.resourceCollection.length > 0) {
            var resourceParentDiv = this.createDivElement(EVENT_WINDOW_RESOURCES_DIV_CLASS);
            for (var _i = 0, _a = this.parent.resourceBase.resourceCollection; _i < _a.length; _i++) {
                var resource = _a[_i];
                resourceParentDiv.appendChild(this.renderResourceDetails(resource));
            }
            parentDiv.appendChild(resourceParentDiv);
        }
        var description = this.createDivElement(DESCRIPTION_CLASS + '-row');
        description.appendChild(this.renderTextBox(DESCRIPTION_CLASS));
        description.querySelector('.' + DESCRIPTION_CLASS).setAttribute('title', this.parent.editorTitles.description);
        parentDiv.appendChild(description);
        var submit = createElement('button', { attrs: { type: 'hidden', title: 'submit', style: 'display:none' } });
        parentDiv.appendChild(submit);
        return parentDiv;
    };
    EventWindow.prototype.createRecurrenceEditor = function (parentDiv) {
        var recurrenceEditor = createElement('div', { id: this.parent.element.id + '_recurrence_editor' });
        parentDiv.appendChild(recurrenceEditor);
        this.recurrenceEditor = this.renderRecurrenceEditor();
        this.recurrenceEditor.appendTo(recurrenceEditor);
        this.updateMinMaxDateToEditor();
    };
    EventWindow.prototype.createDivElement = function (className) {
        return createElement('div', { className: className });
    };
    EventWindow.prototype.createInputElement = function (className, fieldName, type) {
        return createElement(type || 'input', {
            className: className, attrs: {
                type: 'text', name: fieldName, value: '', id: fieldName,
                title: ((this.l10n.getConstant(fieldName.charAt(0).toLowerCase() + fieldName.slice(1))) === '') ?
                    fieldName : this.l10n.getConstant(fieldName.charAt(0).toLowerCase() + fieldName.slice(1))
            }
        });
    };
    EventWindow.prototype.getSlotDuration = function () {
        return this.parent.activeViewOptions.timeScale.interval / this.parent.activeViewOptions.timeScale.slotCount;
    };
    EventWindow.prototype.renderDateTimePicker = function (value, changeEvent) {
        var dateTimeDiv = this.createDivElement(value + '-container');
        var fieldName = this.getFieldName(value);
        var dateTimeInput = this.createInputElement(value + ' ' + EVENT_FIELD$1, fieldName);
        dateTimeDiv.appendChild(dateTimeInput);
        var dateTimePicker = new DateTimePicker({
            change: changeEvent,
            firstDayOfWeek: this.parent.activeViewOptions.firstDayOfWeek,
            calendarMode: this.parent.calendarMode,
            min: this.parent.minDate,
            max: new Date(new Date(+this.parent.maxDate).setHours(23, 59, 59)),
            cssClass: this.parent.cssClass,
            enableRtl: this.parent.enableRtl,
            locale: this.parent.locale,
            floatLabelType: 'Always',
            timeFormat: this.parent.activeViewOptions.timeFormat,
            format: (isNullOrUndefined(this.parent.dateFormat) ?
                this.getFormat('dateFormats') : this.parent.dateFormat) + ' ' + this.parent.activeViewOptions.timeFormat,
            placeholder: this.getFieldLabel(value),
            step: this.getSlotDuration(),
            width: '100%'
        });
        dateTimePicker.appendTo(dateTimeInput);
        return dateTimeDiv;
    };
    EventWindow.prototype.refreshDateTimePicker = function (duration) {
        var elementSelector = '.' + EVENT_WINDOW_START_CLASS + ',.' + EVENT_WINDOW_END_CLASS;
        var startEndElement = [].slice.call(this.element.querySelectorAll(elementSelector));
        for (var _i = 0, startEndElement_1 = startEndElement; _i < startEndElement_1.length; _i++) {
            var element = startEndElement_1[_i];
            var instance = element.ej2_instances[0];
            instance.firstDayOfWeek = this.parent.activeViewOptions.firstDayOfWeek;
            instance.timeFormat = this.parent.activeViewOptions.timeFormat;
            instance.step = duration || this.getSlotDuration();
            instance.dataBind();
        }
    };
    EventWindow.prototype.onTimeChange = function () {
        var startObj = this.getInstance(EVENT_WINDOW_START_CLASS);
        if (startObj.element.parentElement.classList.contains('e-input-focus')) {
            var endObj = this.getInstance(EVENT_WINDOW_END_CLASS);
            var duration = 0;
            if (this.cellClickAction) {
                duration = MS_PER_MINUTE * this.duration;
                this.eventWindowTime.startTime = startObj.value;
            }
            else {
                duration = this.eventData[this.fields.endTime].getTime() - this.eventData[this.fields.startTime].getTime();
            }
            var endDate = (isNullOrUndefined(startObj.value)) ? null : new Date(startObj.value.getTime() + duration);
            if (this.cellClickAction) {
                this.eventWindowTime.endTime = endDate;
            }
            endObj.value = endDate;
            endObj.dataBind();
        }
        if (this.recurrenceEditor) {
            this.recurrenceEditor.updateRuleUntilDate(this.eventWindowTime.startTime);
        }
    };
    EventWindow.prototype.renderResourceDetails = function (resourceData) {
        var fieldName = resourceData.field;
        var value = 'e-' + fieldName;
        var labelValue = resourceData.title;
        var resourceDiv = this.createDivElement(value + '-container' + ' ' + 'e-resources');
        var resourceInput = this.createInputElement(value + ' ' + EVENT_FIELD$1, fieldName);
        resourceDiv.appendChild(resourceInput);
        var resourceTemplate = function (data) {
            return "<div class=\"e-resource-template\"><div class=\"e-resource-color\" style=\"background-color:" + data[resourceData.colorField] + "\"></div><div class=\"e-resource-text\">" + data[resourceData.textField] + "</div></div>";
        };
        initializeCSPTemplate(resourceTemplate, resourceData);
        if (resourceData.allowMultiple) {
            var listObj = new MultiSelect({
                enableRtl: this.parent.enableRtl,
                enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
                cssClass: this.parent.cssClass || '',
                dataSource: resourceData.dataSource,
                change: this.onMultiselectResourceChange.bind(this),
                itemTemplate: resourceTemplate,
                fields: {
                    text: resourceData.textField,
                    value: resourceData.idField
                },
                htmlAttributes: { 'title': labelValue, 'name': fieldName },
                floatLabelType: 'Always',
                placeholder: labelValue,
                popupHeight: '230px',
                popupWidth: '447px',
                mode: 'Box'
            });
            listObj.appendTo(resourceInput);
        }
        else {
            var dropDownList = new DropDownList({
                cssClass: this.parent.cssClass || '',
                change: this.onDropdownResourceChange.bind(this),
                dataSource: resourceData.dataSource,
                enableRtl: this.parent.enableRtl,
                fields: {
                    text: resourceData.textField,
                    value: resourceData.idField
                },
                htmlAttributes: { 'title': labelValue, 'name': fieldName },
                floatLabelType: 'Always',
                placeholder: labelValue,
                popupHeight: '230px',
                popupWidth: '447px',
                itemTemplate: resourceTemplate
            });
            dropDownList.appendTo(resourceInput);
        }
        return resourceDiv;
    };
    EventWindow.prototype.renderDropDown = function (value) {
        var _this = this;
        var fieldName = this.getFieldName(value);
        var timezoneDiv = this.createDivElement(value + '-container');
        var timezoneInput = this.createInputElement(value + ' ' + EVENT_FIELD$1, fieldName);
        timezoneDiv.appendChild(timezoneInput);
        var dropDownList = new DropDownList({
            allowFiltering: true,
            change: this.onTimezoneChange.bind(this),
            cssClass: this.parent.cssClass || '',
            dataSource: this.parent.timezoneDataSource,
            enableRtl: this.parent.enableRtl,
            fields: { text: 'Text', value: 'Value' },
            filterBarPlaceholder: this.parent.localeObj.getConstant('searchTimezone'),
            noRecordsTemplate: this.parent.localeObj.getConstant('noRecords'),
            filtering: function (e) {
                var query = new Query();
                query = (e.text !== '') ? query.where('Text', 'contains', e.text, true) : query;
                e.updateData(_this.parent.timezoneDataSource, query);
            },
            htmlAttributes: { 'title': this.getFieldLabel(value), 'name': fieldName },
            floatLabelType: 'Always',
            placeholder: this.getFieldLabel(value),
            popupHeight: '230px'
        });
        dropDownList.appendTo(timezoneInput);
        return timezoneDiv;
    };
    EventWindow.prototype.onMultiselectResourceChange = function (args) {
        if (!args.value || !this.parent.activeViewOptions.group.byGroupID || this.parent.resourceCollection.length <= 1) {
            return;
        }
        var resourceCollection = this.parent.resourceBase.resourceCollection;
        var fieldName = args.element.getAttribute('name') || this.getColumnName(args.element);
        for (var i = 0; i < resourceCollection.length; i++) {
            if (resourceCollection[parseInt(i.toString(), 10)].field === fieldName && i < resourceCollection.length - 1) {
                var resObject = this.createInstance(i);
                var datasource = [];
                var _loop_1 = function (j) {
                    var resourceModel = resourceCollection[i + 1];
                    // eslint-disable-next-line max-len
                    var filter = resourceModel.dataSource.filter(function (data) {
                        return data[resourceModel.groupIDField] === args.value[parseInt(j.toString(), 10)];
                    })[0];
                    var groupId = (!isNullOrUndefined(filter)) ?
                        filter[resourceCollection[i + 1].groupIDField] : null;
                    var filterRes = this_1.filterDatasource(i, groupId);
                    datasource = datasource.concat(filterRes);
                };
                var this_1 = this;
                for (var j = 0; j < args.value.length; j++) {
                    _loop_1(j);
                }
                resObject.dataSource = datasource;
                resObject.dataBind();
            }
        }
    };
    EventWindow.prototype.createInstance = function (index) {
        var resourceData = this.parent.resourceBase.resourceCollection[index + 1];
        var resObject = this.element.querySelector('.e-' + resourceData.field).
            ej2_instances[0];
        resObject.clear();
        return resObject;
    };
    EventWindow.prototype.onDropdownResourceChange = function (args) {
        if (!args.value || this.parent.resourceCollection.length <= 1 || !this.parent.activeViewOptions.group.byGroupID) {
            return;
        }
        var fieldName = args.element.getAttribute('name') || this.getColumnName(args.element);
        var resourceCollection = this.parent.resourceBase.resourceCollection;
        for (var i = 0; i < resourceCollection.length; i++) {
            if ((i < resourceCollection.length - 1) && resourceCollection[parseInt(i.toString(), 10)].field === fieldName) {
                var resObj = this.createInstance(i);
                var groupId = args.itemData[resourceCollection[parseInt(i.toString(), 10)].idField];
                resObj.dataSource = this.filterDatasource(i, groupId);
                resObj.dataBind();
                var resValue = (resObj.dataSource.length > 0) ?
                    resObj.dataSource[0][resourceCollection[i + 1].idField] : null;
                resObj.value = (resourceCollection[i + 1].allowMultiple) ? [resValue] : resValue;
                resObj.dataBind();
            }
        }
    };
    EventWindow.prototype.filterDatasource = function (index, groupId) {
        var resourceData = this.parent.resourceBase.resourceCollection[index + 1];
        return resourceData.dataSource.filter(function (data) {
            return data[resourceData.groupIDField] === groupId;
        });
    };
    EventWindow.prototype.onTimezoneChange = function (args) {
        var fieldName = args.element.getAttribute('name') || this.getColumnName(args.element);
        if (fieldName === this.parent.eventFields.startTimezone) {
            var startTimezoneObj = this.getInstance(EVENT_WINDOW_START_TZ_CLASS);
            var endTimezoneObj = this.getInstance(EVENT_WINDOW_END_TZ_CLASS);
            endTimezoneObj.value = startTimezoneObj.value;
            endTimezoneObj.dataBind();
        }
    };
    EventWindow.prototype.renderCheckBox = function (value) {
        var checkBoxDiv = this.createDivElement(value + '-container');
        var fieldName = this.getFieldName(value);
        var checkBoxInput = this.createInputElement(value + ' ' + EVENT_FIELD$1, fieldName);
        checkBoxDiv.appendChild(checkBoxInput);
        var checkBox = new CheckBox({
            change: this.onChange.bind(this),
            cssClass: value + ' ' + this.parent.cssClass,
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            label: this.getFieldLabel(value)
        });
        checkBox.appendTo(checkBoxInput);
        checkBoxInput.setAttribute('name', fieldName);
        if (fieldName === 'Repeat') {
            this.repeatStatus = checkBox;
        }
        return checkBoxDiv;
    };
    EventWindow.prototype.renderTextBox = function (value) {
        var textBoxDiv = this.createDivElement(value + '-container');
        var fieldName = this.getFieldName(value);
        var elementType = (value === DESCRIPTION_CLASS) ? 'textarea' : 'input';
        var textBoxInput = this.createInputElement(value + ' ' + EVENT_FIELD$1, fieldName, elementType);
        textBoxDiv.appendChild(textBoxInput);
        Input.createInput({
            element: textBoxInput,
            floatLabelType: 'Always',
            properties: {
                enableRtl: this.parent.enableRtl,
                placeholder: this.getFieldLabel(value)
            }
        });
        return textBoxDiv;
    };
    EventWindow.prototype.getFieldName = function (name) {
        var fieldName = '';
        switch (name) {
            case SUBJECT_CLASS:
                fieldName = this.fields.subject;
                break;
            case LOCATION_CLASS:
                fieldName = this.fields.location;
                break;
            case EVENT_WINDOW_START_CLASS:
                fieldName = this.fields.startTime;
                break;
            case EVENT_WINDOW_END_CLASS:
                fieldName = this.fields.endTime;
                break;
            case DESCRIPTION_CLASS:
                fieldName = this.fields.description;
                break;
            case EVENT_WINDOW_ALL_DAY_CLASS:
                fieldName = this.fields.isAllDay;
                break;
            case EVENT_WINDOW_START_TZ_CLASS:
                fieldName = this.fields.startTimezone;
                break;
            case EVENT_WINDOW_END_TZ_CLASS:
                fieldName = this.fields.endTimezone;
                break;
            case TIME_ZONE_CLASS:
                fieldName = 'Timezone';
                break;
            case EVENT_WINDOW_REPEAT_CLASS:
                fieldName = 'Repeat';
                break;
        }
        return fieldName;
    };
    EventWindow.prototype.getFieldLabel = function (fieldName) {
        var labelText = '';
        switch (fieldName) {
            case SUBJECT_CLASS:
                labelText = this.parent.editorTitles.subject;
                break;
            case LOCATION_CLASS:
                labelText = this.parent.editorTitles.location;
                break;
            case DESCRIPTION_CLASS:
                labelText = this.parent.editorTitles.description;
                break;
            case EVENT_WINDOW_START_CLASS:
                labelText = this.parent.editorTitles.startTime;
                break;
            case EVENT_WINDOW_END_CLASS:
                labelText = this.parent.editorTitles.endTime;
                break;
            case EVENT_WINDOW_START_TZ_CLASS:
                labelText = this.parent.editorTitles.startTimezone;
                break;
            case EVENT_WINDOW_END_TZ_CLASS:
                labelText = this.parent.editorTitles.endTimezone;
                break;
            case EVENT_WINDOW_REPEAT_CLASS:
                labelText = this.parent.editorTitles.recurrenceRule;
                break;
            case EVENT_WINDOW_ALL_DAY_CLASS:
                labelText = this.parent.editorTitles.isAllDay;
                break;
            case TIME_ZONE_CLASS:
                labelText = this.l10n.getConstant('timezone');
                break;
        }
        return labelText;
    };
    EventWindow.prototype.onChange = function (args) {
        if (args.event && args.event.target) {
            var targetSelector = "." + EVENT_WINDOW_ALL_DAY_CLASS + ",." + TIME_ZONE_CLASS + ",." + EVENT_WINDOW_REPEAT_CLASS;
            var target = closest(args.event.target, targetSelector);
            if (target.classList.contains(EVENT_WINDOW_ALL_DAY_CLASS)) {
                this.onAllDayChange(args.checked);
            }
            else if (target.classList.contains(TIME_ZONE_CLASS)) {
                this.timezoneChangeStyle(args.checked);
            }
            else if (target.classList.contains(EVENT_WINDOW_REPEAT_CLASS)) {
                this.onRepeatChange(args.checked);
            }
        }
    };
    EventWindow.prototype.renderRepeatDialog = function () {
        var element = createElement('div');
        this.repeatDialogObject = new Dialog({
            header: this.l10n.getConstant('recurrence'),
            visible: false,
            content: '<div class="e-rec-editor"></div>',
            closeOnEscape: true,
            width: '90%',
            buttons: [{
                    click: this.repeatSaveDialog.bind(this),
                    buttonModel: { content: this.l10n.getConstant('save'), cssClass: 'e-save', isPrimary: true }
                },
                { click: this.repeatCancelDialog.bind(this), buttonModel: { cssClass: 'e-cancel', content: this.l10n.getConstant('cancel') } }],
            target: this.element,
            animationSettings: { effect: 'Zoom' },
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            isModal: true,
            cssClass: REPEAT_DIALOG_CLASS,
            open: this.repeatOpenDialog.bind(this)
        });
        this.element.appendChild(element);
        this.repeatDialogObject.appendTo(element);
        this.createRecurrenceEditor(this.repeatDialogObject.element.querySelector('.e-rec-editor'));
    };
    EventWindow.prototype.loadRecurrenceEditor = function () {
        this.repeatDialogObject.show();
        if (this.recurrenceEditor && this.repeatRule) {
            this.recurrenceEditor.setRecurrenceRule(this.repeatRule);
        }
    };
    EventWindow.prototype.onRepeatChange = function (state) {
        if (state) {
            if (!this.repeatDialogObject) {
                this.renderRepeatDialog();
            }
            this.recurrenceEditor.setProperties({ startDate: this.repeatStartDate, selectedType: 0 });
            this.loadRecurrenceEditor();
        }
        else {
            if (this.repeatDialogObject) {
                this.repeatDialogObject.hide();
            }
            this.repeatRule = '';
            if (this.recurrenceEditor) {
                this.recurrenceEditor.setRecurrenceRule(this.repeatRule);
                this.updateRepeatLabel(this.repeatRule);
            }
            var element = this.element.querySelector('.' + REPEAT_CONTAINER_CLASS);
            addClass([element], HIDE_STYLE_CLASS);
        }
    };
    EventWindow.prototype.repeatSaveDialog = function () {
        this.repeatRule = this.recurrenceEditor.getRecurrenceRule();
        var element = this.element.querySelector('.' + REPEAT_CONTAINER_CLASS);
        if (this.recurrenceEditor.getRecurrenceRule()) {
            removeClass([element], HIDE_STYLE_CLASS);
        }
        else {
            addClass([element], HIDE_STYLE_CLASS);
            this.repeatStatus.setProperties({ checked: false });
        }
        this.updateRepeatLabel(this.repeatRule);
        this.closeRepeatDialog();
    };
    EventWindow.prototype.closeRepeatDialog = function () {
        this.repeatDialogObject.hide();
    };
    EventWindow.prototype.repeatCancelDialog = function () {
        this.closeRepeatDialog();
        if (this.recurrenceEditor) {
            this.recurrenceEditor.setRecurrenceRule(this.repeatTempRule);
        }
        if (!this.repeatTempRule) {
            this.repeatStatus.setProperties({ checked: false });
        }
    };
    EventWindow.prototype.repeatOpenDialog = function () {
        this.repeatTempRule = this.recurrenceEditor.getRecurrenceRule();
    };
    EventWindow.prototype.onCellDetailsUpdate = function (eventObj, repeatType) {
        if (!this.parent.eventSettings.allowAdding) {
            return;
        }
        if (this.parent.isAdaptive && repeatType && !this.repeatDialogObject) {
            this.renderRepeatDialog();
        }
        this.element.querySelector('.' + FORM_CLASS).removeAttribute('data-id');
        if (isNullOrUndefined(this.parent.editorHeaderTemplate)) {
            this.element.querySelector('.' + EVENT_WINDOW_TITLE_TEXT_CLASS).innerHTML = this.l10n.getConstant('newEvent');
        }
        eventObj.Timezone = false;
        this.repeatStartDate = eventObj[this.fields.startTime];
        this.repeatRule = '';
        if (!isNullOrUndefined(this.parent.eventSettings.fields.subject.default)) {
            eventObj[this.fields.subject] = this.parent.eventSettings.fields.subject.default;
        }
        if (!isNullOrUndefined(this.parent.eventSettings.fields.location.default)) {
            eventObj[this.fields.location] = this.parent.eventSettings.fields.location.default;
        }
        if (!isNullOrUndefined(this.parent.eventSettings.fields.description.default)) {
            eventObj[this.fields.description] = this.parent.eventSettings.fields.description.default;
        }
        this.showDetails(eventObj);
        if (eventObj[this.fields.recurrenceRule] && this.recurrenceEditor) {
            this.recurrenceEditor.setRecurrenceRule(eventObj[this.fields.recurrenceRule], eventObj[this.fields.startTime]);
            this.repeatRule = eventObj[this.fields.recurrenceRule];
        }
        var deleteButton = this.element.querySelector('.' + DELETE_EVENT_CLASS);
        if (deleteButton) {
            addClass([deleteButton], DISABLE_CLASS);
        }
        if (this.recurrenceEditor) {
            this.recurrenceEditor.setProperties({
                startDate: eventObj[this.fields.startTime],
                selectedType: !isNullOrUndefined(repeatType) ? repeatType : !isNullOrUndefined(eventObj[this.fields.recurrenceRule]) ?
                    this.recurrenceEditor.selectedType : 0
            });
            this.repeatRule = this.recurrenceEditor.value;
        }
        if (this.parent.isAdaptive && isNullOrUndefined(this.parent.editorTemplate)) {
            var element = this.element.querySelector('.' + REPEAT_CONTAINER_CLASS);
            if (eventObj[this.fields.recurrenceRule] || repeatType) {
                removeClass([element], HIDE_STYLE_CLASS);
                this.repeatStatus.setProperties({ checked: true });
            }
            else {
                addClass([element], HIDE_STYLE_CLASS);
                this.repeatStatus.setProperties({ checked: false });
            }
            this.updateRepeatLabel(this.repeatRule);
        }
        else {
            var saveButton = this.element.querySelector('.' + EVENT_WINDOW_SAVE_BUTTON_CLASS);
            this.disableButton(saveButton, false);
        }
        this.dialogObject.show();
    };
    EventWindow.prototype.convertToEventData = function (cellsData, eventObj) {
        if (cellsData.subject) {
            eventObj[this.fields.subject] = cellsData.subject;
        }
        eventObj[this.fields.startTime] = cellsData.startTime;
        eventObj[this.fields.endTime] = cellsData.endTime;
        eventObj[this.fields.isAllDay] = cellsData.isAllDay;
        if (cellsData.RecurrenceRule) {
            eventObj[this.fields.recurrenceRule] = cellsData.RecurrenceRule;
        }
        if (this.parent.resourceCollection.length > 0 || this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.resourceBase.setResourceValues(eventObj);
        }
    };
    EventWindow.prototype.applyFormValidation = function () {
        var form = this.element.querySelector('.' + FORM_CLASS);
        if (!form) {
            return;
        }
        var getValidationRule = function (rules) {
            return (rules && Object.keys(rules).length > 0) ? rules : undefined;
        };
        var rules = {};
        var subjectRule = getValidationRule(this.parent.eventSettings.fields.subject.validation);
        if (!isNullOrUndefined(subjectRule)) {
            rules[this.parent.eventSettings.fields.subject.name] = subjectRule;
        }
        var locationRule = getValidationRule(this.parent.eventSettings.fields.location.validation);
        if (!isNullOrUndefined(locationRule)) {
            rules[this.parent.eventSettings.fields.location.name] = locationRule;
        }
        var startTimeRule = getValidationRule(this.parent.eventSettings.fields.startTime.validation);
        if (!isNullOrUndefined(startTimeRule)) {
            rules[this.parent.eventSettings.fields.startTime.name] = startTimeRule;
        }
        var endTimeRule = getValidationRule(this.parent.eventSettings.fields.endTime.validation);
        if (!isNullOrUndefined(endTimeRule)) {
            rules[this.parent.eventSettings.fields.endTime.name] = endTimeRule;
        }
        var descriptionRule = getValidationRule(this.parent.eventSettings.fields.description.validation);
        if (!isNullOrUndefined(descriptionRule)) {
            rules[this.parent.eventSettings.fields.description.name] = descriptionRule;
        }
        this.fieldValidator = new FieldValidator();
        this.fieldValidator.renderFormValidator(form, rules, this.element, this.parent.locale);
    };
    EventWindow.prototype.showDetails = function (eventData) {
        this.eventData = this.eventCrudData ? this.eventData : eventData;
        var eventObj = extend({}, eventData, null, true);
        var formElements = this.getFormElements(EVENT_WINDOW_DIALOG_CLASS);
        if (this.parent.isReact && formElements.length < 1) {
            this.eventCrudData = eventObj;
            return;
        }
        if ((!this.cellClickAction || this.cellClickAction && !isNullOrUndefined(this.parent.editorTemplate)) &&
            eventObj[this.fields.endTime].getHours() === 0 && eventObj[this.fields.endTime].getMinutes() === 0) {
            this.trimAllDay(eventObj);
        }
        var keyNames = Object.keys(eventObj);
        for (var _i = 0, formElements_2 = formElements; _i < formElements_2.length; _i++) {
            var curElement = formElements_2[_i];
            var columnName = curElement.name || this.getColumnName(curElement);
            if (!isNullOrUndefined(columnName) && columnName !== '') {
                if (keyNames.indexOf(columnName) !== -1) {
                    this.setValueToElement(curElement, eventObj["" + columnName]);
                }
                else {
                    this.setDefaultValueToElement(curElement);
                }
            }
        }
        if (isNullOrUndefined(this.parent.editorTemplate)) {
            this.onAllDayChange(eventObj[this.fields.isAllDay]);
            var timezoneObj = this.getInstance(TIME_ZONE_CLASS + '.' + EVENT_FIELD$1);
            if (!(isNullOrUndefined(eventObj[this.fields.startTimezone]) && isNullOrUndefined(eventObj[this.fields.endTimezone]))) {
                timezoneObj.checked = true;
                timezoneObj.dataBind();
            }
            this.timezoneChangeStyle(timezoneObj.checked);
            delete eventObj.Timezone;
        }
    };
    EventWindow.prototype.getColumnName = function (element) {
        var attrName = element.getAttribute('data-name') || '';
        if (attrName === '') {
            var isDropDowns = false;
            var fieldSelector = '';
            if (element.classList.contains('e-dropdownlist')) {
                fieldSelector = 'e-ddl';
                isDropDowns = true;
            }
            else if (element.classList.contains('e-multiselect')) {
                fieldSelector = 'e-multiselect';
                isDropDowns = true;
            }
            else if (element.classList.contains('e-datetimepicker')) {
                fieldSelector = 'e-datetimepicker';
            }
            else if (element.classList.contains('e-datepicker')) {
                fieldSelector = 'e-datepicker';
            }
            else if (element.classList.contains('e-checkbox')) {
                fieldSelector = 'e-checkbox';
            }
            var classSelector = isDropDowns ? "." + fieldSelector + ":not(.e-control)" : "." + fieldSelector;
            var control = closest(element, classSelector) || element.querySelector("." + fieldSelector);
            if (control) {
                var attrEle = control.querySelector('[name]');
                if (attrEle) {
                    attrName = attrEle.name;
                }
            }
        }
        return attrName;
    };
    EventWindow.prototype.onAllDayChange = function (allDayStatus) {
        var startObj = this.getInstance(EVENT_WINDOW_START_CLASS);
        var endObj = this.getInstance(EVENT_WINDOW_END_CLASS);
        var timezoneDiv = this.element.querySelector('.e-time-zone-container');
        var format;
        if (allDayStatus) {
            format = (isNullOrUndefined(this.parent.dateFormat)) ? this.getFormat('dateFormats') : this.parent.dateFormat;
            addClass(this.element.querySelectorAll('.e-time-icon'), EVENT_WINDOW_ICON_DISABLE_CLASS);
            addClass([timezoneDiv], DISABLE_CLASS);
            if (this.element.querySelector('.' + EVENT_WINDOW_TIME_ZONE_DIV_CLASS)) {
                removeClass([this.element.querySelector('.' + EVENT_WINDOW_TIME_ZONE_DIV_CLASS)], ENABLE_CLASS);
            }
            startObj.format = endObj.format = format;
        }
        else {
            format = (isNullOrUndefined(this.parent.dateFormat)) ? this.getFormat('dateFormats') + ' ' +
                this.parent.activeViewOptions.timeFormat : this.parent.dateFormat + ' ' + this.parent.activeViewOptions.timeFormat;
            removeClass(this.element.querySelectorAll('.e-time-icon'), EVENT_WINDOW_ICON_DISABLE_CLASS);
            removeClass([timezoneDiv], DISABLE_CLASS);
            if (this.element.querySelector('.e-checkbox-wrapper .e-time-zone').checked) {
                addClass([this.element.querySelector('.' + EVENT_WINDOW_TIME_ZONE_DIV_CLASS)], ENABLE_CLASS);
            }
            startObj.format = endObj.format = format;
        }
        if (this.cellClickAction) {
            this.updateDateTime(allDayStatus, startObj, endObj);
        }
        startObj.dataBind();
        endObj.dataBind();
        if (!isNullOrUndefined(this.recurrenceEditor)) {
            this.recurrenceEditor.updateRuleUntilDate(startObj.value);
        }
    };
    EventWindow.prototype.updateDateTime = function (allDayStatus, startObj, endObj) {
        var startDate;
        var endDate;
        if (allDayStatus) {
            startDate = resetTime(new Date(this.eventWindowTime.startTime.getTime()));
            if (this.parent.activeCellsData.isAllDay) {
                var temp = addDays(new Date(this.eventWindowTime.endTime.getTime()), -1).getTime();
                endDate = (+this.eventWindowTime.startTime > temp) ? this.eventWindowTime.endTime : new Date(temp);
            }
            else {
                endDate = resetTime(new Date(this.eventWindowTime.endTime.getTime()));
            }
        }
        else {
            var start = this.parent.activeCellsData.startTime;
            startDate = new Date(this.eventWindowTime.startTime.getTime());
            startDate.setHours(start.getHours(), start.getMinutes(), start.getSeconds());
            if (this.parent.activeCellsData.isAllDay) {
                var startHour = this.parent.getStartEndTime(this.parent.workHours.start);
                startDate.setHours(startHour.getHours(), startHour.getMinutes(), startHour.getSeconds());
                endDate = new Date(startDate.getTime());
                endDate.setMilliseconds(MS_PER_MINUTE * this.getSlotDuration());
            }
            else {
                endDate = new Date(startDate.getTime());
                endDate.setMilliseconds(this.parent.activeCellsData.endTime.getTime() - this.parent.activeCellsData.startTime.getTime());
            }
        }
        this.eventWindowTime = { startTime: new Date(startDate.getTime()), endTime: new Date(endDate.getTime()) };
        startObj.value = startDate;
        endObj.value = endDate;
        startObj.dataBind();
        endObj.dataBind();
    };
    EventWindow.prototype.getFormat = function (formatType) {
        var format;
        if (this.parent.locale === 'en' || this.parent.locale === 'en-US') {
            format = getValue(formatType + '.short', getDefaultDateObject(this.parent.getCalendarMode()));
        }
        else {
            format = getValue("main." + this.parent.locale + ".dates.calendars." + this.parent.getCalendarMode() + "." + formatType + ".short", cldrData);
        }
        return format;
    };
    EventWindow.prototype.onEventDetailsUpdate = function (eventObj) {
        if (!this.parent.eventSettings.allowEditing) {
            return;
        }
        if (!this.parent.isAdaptive && isNullOrUndefined(this.parent.editorFooterTemplate)) {
            removeClass([this.element.querySelector('.' + DELETE_EVENT_CLASS)], DISABLE_CLASS);
        }
        if (isNullOrUndefined(this.parent.editorHeaderTemplate)) {
            this.element.querySelector('.' + EVENT_WINDOW_TITLE_TEXT_CLASS).innerHTML = this.l10n.getConstant('editEvent');
        }
        this.element.querySelector('.' + FORM_CLASS).setAttribute('data-id', eventObj[this.fields.id].toString());
        if (isNullOrUndefined(this.parent.editorTemplate)) {
            eventObj = extend({}, eventObj, null, true);
            var timezoneObj = this.getInstance(TIME_ZONE_CLASS + '.' + EVENT_FIELD$1);
            var timezoneValue = void 0;
            if (eventObj[this.fields.startTimezone] || eventObj[this.fields.endTimezone]) {
                timezoneValue = true;
                this.parent.eventBase.timezoneConvert(eventObj);
            }
            else {
                timezoneValue = false;
            }
            eventObj.Timezone = timezoneValue;
            timezoneObj.checked = timezoneValue;
            timezoneObj.dataBind();
        }
        this.showDetails(eventObj);
        if (eventObj[this.fields.recurrenceRule] && this.recurrenceEditor) {
            this.recurrenceEditor.setRecurrenceRule(eventObj[this.fields.recurrenceRule], eventObj[this.fields.startTime]);
        }
        else if (!this.parent.isAdaptive && this.recurrenceEditor) {
            this.recurrenceEditor.setProperties({ startDate: eventObj[this.fields.startTime] });
            this.recurrenceEditor.setRecurrenceRule('');
        }
        this.repeatStartDate = eventObj[this.fields.startTime];
        this.repeatRule = '';
        if (eventObj[this.fields.recurrenceRule]) {
            if (this.recurrenceEditor) {
                this.recurrenceEditor.setRecurrenceRule(eventObj[this.fields.recurrenceRule], eventObj[this.fields.startTime]);
            }
            this.repeatRule = eventObj[this.fields.recurrenceRule];
        }
        if (this.parent.isAdaptive && isNullOrUndefined(this.parent.editorTemplate)) {
            var element = this.element.querySelector('.' + REPEAT_CONTAINER_CLASS);
            if (eventObj[this.fields.recurrenceRule]) {
                removeClass([element], HIDE_STYLE_CLASS);
                this.repeatStatus.setProperties({ checked: true });
            }
            else {
                addClass([element], HIDE_STYLE_CLASS);
                this.repeatStatus.setProperties({ checked: false });
            }
            this.updateRepeatLabel(this.repeatRule);
        }
        var isDisable = (this.parent.readonly || eventObj[this.fields.isReadonly]);
        if (!this.parent.isAdaptive) {
            var saveButton = this.element.querySelector('.' + EVENT_WINDOW_SAVE_BUTTON_CLASS);
            var deleteButton = this.element.querySelector('.' + DELETE_EVENT_CLASS);
            this.disableButton(saveButton, isDisable);
            this.disableButton(deleteButton, isDisable);
        }
        else {
            var saveIcon = this.element.querySelector('.' + EVENT_WINDOW_SAVE_ICON_CLASS);
            if (saveIcon) {
                if (isDisable) {
                    addClass([saveIcon], ICON_DISABLE_CLASS);
                }
                else {
                    removeClass([saveIcon], ICON_DISABLE_CLASS);
                }
            }
        }
        this.dialogObject.show();
    };
    EventWindow.prototype.disableButton = function (element, value) {
        if (element) {
            element.ej2_instances[0].disabled = value;
        }
    };
    EventWindow.prototype.renderRecurrenceEditor = function () {
        return new RecurrenceEditor({
            calendarMode: this.parent.calendarMode,
            cssClass: this.parent.cssClass,
            dateFormat: this.parent.dateFormat,
            enableRtl: this.parent.enableRtl,
            firstDayOfWeek: this.parent.activeViewOptions.firstDayOfWeek,
            locale: this.parent.locale
        });
    };
    EventWindow.prototype.updateMinMaxDateToEditor = function () {
        var startDate = this.element.querySelector('.e-start');
        var endDate = this.element.querySelector('.e-end');
        if (startDate && endDate) {
            var startObj = startDate.ej2_instances[0];
            var endObj = endDate.ej2_instances[0];
            startObj.min = this.parent.minDate;
            startObj.max = this.parent.maxDate;
            endObj.min = this.parent.minDate;
            endObj.max = this.parent.maxDate;
            startObj.dataBind();
            endObj.dataBind();
        }
        if (this.recurrenceEditor) {
            var untilDate = this.recurrenceEditor.element.querySelector('.e-until-date');
            if (untilDate) {
                var untilObj = untilDate.ej2_instances[0];
                untilObj.min = this.parent.minDate;
                untilObj.max = this.parent.maxDate;
                untilObj.dataBind();
            }
        }
    };
    EventWindow.prototype.updateRepeatLabel = function (repeatRule) {
        if (this.parent.isAdaptive && !this.repeatDialogObject) {
            this.renderRepeatDialog();
        }
        var data = repeatRule ?
            (this.l10n.getConstant('repeats') + ' ' + this.recurrenceEditor.getRuleSummary(repeatRule)) : this.l10n.getConstant('repeat');
        this.repeatStatus.setProperties({ label: data });
    };
    EventWindow.prototype.dialogClose = function (event) {
        if (this.isEnterKey) {
            this.isEnterKey = false;
            return;
        }
        this.dialogEvent = event;
        this.isCrudAction = false;
        this.parent.activeEventData = { event: undefined, element: undefined };
        this.parent.currentAction = null;
        this.dialogObject.hide();
    };
    EventWindow.prototype.resetForm = function () {
        this.fieldValidator.destroyToolTip();
        this.resetFormFields();
        if (!this.parent.isAdaptive && this.recurrenceEditor && !this.recurrenceEditor.isDestroyed) {
            this.recurrenceEditor.resetFields();
        }
    };
    EventWindow.prototype.timezoneChangeStyle = function (value) {
        var timezoneDiv = this.element.querySelector('.' + EVENT_WINDOW_TIME_ZONE_DIV_CLASS);
        var localTimezoneName = this.parent.tzModule.getLocalTimezoneName();
        if (value) {
            addClass([timezoneDiv], ENABLE_CLASS);
            var startTimezoneObj = this.getInstance(EVENT_WINDOW_START_TZ_CLASS);
            var endTimezoneObj = this.getInstance(EVENT_WINDOW_END_TZ_CLASS);
            var timezone = startTimezoneObj.dataSource;
            if (!startTimezoneObj.value || !this.parent.timezone) {
                var found = timezone.some(function (tz) { return tz.Value === localTimezoneName; });
                if (!found) {
                    timezone.push({ Value: localTimezoneName, Text: localTimezoneName });
                    startTimezoneObj.dataSource = timezone;
                    endTimezoneObj.dataSource = timezone;
                    startTimezoneObj.dataBind();
                    endTimezoneObj.dataBind();
                }
            }
            startTimezoneObj.value = startTimezoneObj.value || this.parent.timezone || localTimezoneName;
            endTimezoneObj.value = endTimezoneObj.value || this.parent.timezone || localTimezoneName;
            startTimezoneObj.dataBind();
            endTimezoneObj.dataBind();
        }
        else {
            removeClass([timezoneDiv], ENABLE_CLASS);
        }
    };
    EventWindow.prototype.resetFormFields = function () {
        var formElement = this.getFormElements(EVENT_WINDOW_DIALOG_CLASS);
        for (var _i = 0, formElement_1 = formElement; _i < formElement_1.length; _i++) {
            var currentElement = formElement_1[_i];
            var columnName = currentElement.name || this.getColumnName(currentElement);
            if (!isNullOrUndefined(columnName) && columnName !== '') {
                this.setDefaultValueToElement(currentElement);
            }
        }
    };
    EventWindow.prototype.eventSave = function (event, alert) {
        if (this.isEnterKey) {
            this.isEnterKey = false;
            return;
        }
        var formElement = this.element.querySelector('.' + FORM_CLASS);
        if (formElement && formElement.classList.contains('e-formvalidator') &&
            !formElement.ej2_instances[0].validate()) {
            return;
        }
        var dataCollection = this.getEventDataFromEditor();
        if (this.processEventValidation(dataCollection.tempData, alert)) {
            return;
        }
        this.eventCrudData = dataCollection.eventData;
        this.dialogEvent = event;
        this.isCrudAction = true;
        this.dialogObject.hide();
    };
    EventWindow.prototype.getEventDataFromEditor = function () {
        var eventObj = extend({}, this.getObjectFromFormData(EVENT_WINDOW_DIALOG_CLASS));
        if (!eventObj.Timezone) {
            eventObj[this.fields.startTimezone] = null;
            eventObj[this.fields.endTimezone] = null;
        }
        delete eventObj.Timezone;
        delete eventObj.Repeat;
        this.setDefaultValueToObject(eventObj);
        eventObj[this.fields.recurrenceRule] = this.recurrenceEditor ? this.recurrenceEditor.getRecurrenceRule() || null : undefined;
        var tempObj = extend({}, eventObj, null, true);
        if (eventObj[this.fields.isAllDay]) {
            eventObj[this.fields.startTime] = (isNullOrUndefined(eventObj[this.fields.startTime])) ? null
                : resetTime(new Date(eventObj[this.fields.startTime].getTime()));
            eventObj[this.fields.endTime] = (isNullOrUndefined(eventObj[this.fields.endTime])) ? null
                : addDays(resetTime(new Date(eventObj[this.fields.endTime].getTime())), 1);
        }
        return { eventData: eventObj, tempData: tempObj };
    };
    EventWindow.prototype.processEventValidation = function (eventObj, alert) {
        var alertType;
        if (isNullOrUndefined(this.parent.editorTemplate)) {
            if (!eventObj[this.fields.startTime] || !eventObj[this.fields.endTime]) {
                this.parent.quickPopup.openValidationError('invalidDateError');
                return true;
            }
            if (eventObj[this.fields.startTime] > eventObj[this.fields.endTime]) {
                this.parent.quickPopup.openValidationError('startEndError');
                return true;
            }
        }
        if (this.recurrenceEditor && this.recurrenceEditor.value && this.recurrenceEditor.value !== '') {
            if (this.parent.currentAction !== 'EditOccurrence') {
                alertType = this.recurrenceValidation(eventObj[this.fields.startTime], eventObj[this.fields.endTime], alert);
            }
            var isShowAlert = true;
            if (alertType === 'seriesChangeAlert' && this.parent.uiStateValues.isIgnoreOccurrence) {
                isShowAlert = false;
            }
            if (!isNullOrUndefined(alertType) && isShowAlert
                && ((!this.parent.enableRecurrenceValidation && alertType === 'wrongPattern') || this.parent.enableRecurrenceValidation)) {
                this.parent.quickPopup.openRecurrenceValidationAlert(alertType);
                return true;
            }
        }
        return false;
    };
    EventWindow.prototype.processCrudActions = function (eventObj) {
        var _this = this;
        this.parent.uiStateValues.isBlock = false;
        var resourceData = this.getResourceData(eventObj);
        var isResourceEventExpand = (this.parent.activeViewOptions.group.resources.length > 0 ||
            this.parent.resourceCollection.length > 0) && !this.parent.activeViewOptions.group.allowGroupEdit
            && !isNullOrUndefined(resourceData);
        var eventId = this.getEventIdFromForm();
        if (!isNullOrUndefined(eventId)) {
            var eveId_1 = this.parent.eventBase.getEventIDType() === 'string' ? eventId : parseInt(eventId, 10);
            var editedData = this.parent.eventsData.filter(function (data) {
                return data[_this.fields.id] === eveId_1;
            })[0];
            if (isNullOrUndefined(editedData)) {
                editedData = this.parent.blockData.filter(function (data) {
                    return data[_this.fields.id] === eveId_1;
                })[0];
            }
            eventObj = extend({}, editedData, eventObj);
            if (eventObj[this.fields.isReadonly]) {
                return false;
            }
            var currentAction = void 0;
            if (!isNullOrUndefined(editedData[this.fields.recurrenceRule])) {
                currentAction = this.parent.currentAction;
                eventObj.Guid = this.parent.activeEventData.event.Guid;
                if (this.parent.currentAction === 'EditOccurrence') {
                    if (!eventObj[this.fields.recurrenceID]) {
                        eventObj[this.fields.id] = this.parent.eventBase.getEventMaxID();
                        eventObj.Guid = this.parent.activeEventData.event.Guid;
                    }
                    else {
                        eveId_1 = eventObj[this.fields.recurrenceID];
                        currentAction = null;
                    }
                    if (this.parent.enableRecurrenceValidation && this.editOccurrenceValidation(eveId_1, eventObj)) {
                        return true;
                    }
                }
                if (this.parent.currentAction === 'EditSeries' || eventObj[this.fields.id] !== editedData[this.fields.id]) {
                    eventObj[this.fields.recurrenceID] = editedData[this.fields.id];
                }
                else if (this.parent.currentAction === 'EditFollowingEvents') {
                    eventObj[this.fields.id] = this.parent.eventBase.getEventMaxID();
                    eventObj[this.fields.followingID] = editedData[this.fields.id];
                }
            }
            if (isResourceEventExpand) {
                this.resourceSaveEvent(eventObj, 'Save', currentAction);
            }
            else {
                this.parent.saveEvent(eventObj, currentAction);
            }
        }
        else {
            this.parent.currentAction = 'Add';
            if (isResourceEventExpand) {
                this.resourceSaveEvent(eventObj, this.parent.currentAction);
            }
            else {
                eventObj[this.fields.id] = this.parent.eventBase.getEventMaxID();
                this.parent.addEvent(eventObj);
            }
        }
        return this.parent.uiStateValues.isBlock;
    };
    EventWindow.prototype.getResourceData = function (eventObj) {
        var resourceData = null;
        if (!isNullOrUndefined(this.parent.resourceBase) && !isNullOrUndefined(this.parent.resourceBase.resourceCollection)
            && this.parent.resourceBase.resourceCollection.length > 0) {
            var lastResourceData = this.parent.resourceBase.resourceCollection.slice(-1)[0];
            resourceData = eventObj[lastResourceData.field];
        }
        return resourceData;
    };
    EventWindow.prototype.getObjectFromFormData = function (className) {
        var formElement = this.getFormElements(className);
        var eventObj = {};
        for (var _i = 0, formElement_2 = formElement; _i < formElement_2.length; _i++) {
            var currentElement = formElement_2[_i];
            var columnName = currentElement.name || this.getColumnName(currentElement);
            if (!isNullOrUndefined(columnName) && columnName !== '') {
                eventObj["" + columnName] = this.getValueFromElement(currentElement);
            }
        }
        return eventObj;
    };
    EventWindow.prototype.setDefaultValueToObject = function (eventObj) {
        if (!isNullOrUndefined(eventObj[this.fields.subject])) {
            eventObj[this.fields.subject] = eventObj[this.fields.subject] || this.parent.eventSettings.fields.subject.default
                || this.l10n.getConstant('addTitle');
        }
        if (!isNullOrUndefined(eventObj[this.fields.location])) {
            eventObj[this.fields.location] = eventObj[this.fields.location] || this.parent.eventSettings.fields.location.default;
        }
        if (!isNullOrUndefined(eventObj[this.fields.description])) {
            eventObj[this.fields.description] = eventObj[this.fields.description] || this.parent.eventSettings.fields.description.default;
        }
    };
    EventWindow.prototype.recurrenceValidation = function (startDate, endDate, alert) {
        var alertMessage;
        var recEditor = this.recurrenceEditor;
        var interval = this.getInstance('e-repeat-interval.e-numerictextbox').value;
        if (alert !== this.l10n.getConstant('ok')) {
            var activeEvent = this.parent.activeEventData.event;
            var excludedEvents = [];
            if ((this.parent.currentAction === 'EditSeries' || this.parent.currentAction === 'EditFollowingEvents')
                && !isNullOrUndefined(activeEvent)) {
                var eventStartTime = activeEvent[this.parent.eventFields.startTime];
                var seriesEvents = this.parent.eventBase.getSeriesEvents(this.eventData, eventStartTime);
                if (seriesEvents.length > 0) {
                    excludedEvents = this.parent.eventBase.getEditedOccurrences(seriesEvents, eventStartTime);
                }
                else {
                    var event_2 = this.parent.eventBase.getEventById(activeEvent[this.parent.eventFields.id]);
                    excludedEvents = this.parent.eventBase.getEditedOccurrences([event_2], eventStartTime);
                }
                if (this.parent.currentAction === 'EditSeries'
                    && !isNullOrUndefined(this.eventData[this.parent.eventFields.recurrenceException])) {
                    excludedEvents.push(this.eventData);
                }
            }
            if (excludedEvents.length > 0) {
                alertMessage = 'seriesChangeAlert';
            }
            if (this.getInstance('e-end-on-left .e-ddl .e-dropdownlist').value === 'until' &&
                this.getInstance('e-end-on-date .e-datepicker').value < startDate) {
                alertMessage = 'wrongPattern';
            }
            if (isNullOrUndefined(alertMessage)) {
                var types = recEditor.value.split(';')[1].split('=')[1].split(',');
                var obj = { 'SU': 0, 'MO': 1, 'TU': 2, 'WE': 3, 'TH': 4, 'FR': 5, 'SA': 6 };
                var temp = [];
                var tempDiff = [];
                var tempValue = void 0;
                switch (recEditor.value.split(';')[0].split('=')[1]) {
                    case 'DAILY':
                        if ((((endDate.getTime() - startDate.getTime()) / (1000 * 3600)) > (interval * 24))) {
                            alertMessage = 'createError';
                        }
                        break;
                    case 'WEEKLY':
                        types = recEditor.value.split(';')[1].split('=')[1].split(',');
                        for (var index = 0; index < types.length * (interval + 1); index++) {
                            temp[parseInt(index.toString(), 10)] =
                                (types.length > index) ? obj[types[parseInt(index.toString(), 10)]] :
                                    temp[index - types.length] + (7 * interval);
                        }
                        tempValue = temp.sort(function (a, b) { return a - b; });
                        for (var index = 1; index < tempValue.length; index++) {
                            tempDiff.push(tempValue[parseInt(index.toString(), 10)] - tempValue[index - 1]);
                        }
                        if ((((endDate.getTime() - startDate.getTime()) / (1000 * 3600)) >= Math.min.apply(Math, tempDiff) * 24)
                            || isNullOrUndefined(interval)) {
                            alertMessage = 'createError';
                        }
                        break;
                    case 'MONTHLY':
                        if (endDate.getTime() >= new Date(+startDate).setMonth(startDate.getMonth() + interval)) {
                            alertMessage = 'createError';
                        }
                        break;
                    case 'YEARLY':
                        if (endDate.getTime() >= new Date(+startDate).setFullYear(startDate.getFullYear() + interval)) {
                            alertMessage = 'createError';
                        }
                        break;
                }
            }
        }
        else {
            if (endDate.getTime() >= new Date(+startDate).setMonth(startDate.getMonth() + interval)) {
                alertMessage = 'createError';
            }
            if (isNullOrUndefined(alertMessage)) {
                this.parent.quickPopup.quickDialog.hide();
            }
        }
        if (isNullOrUndefined(interval)) {
            alertMessage = 'createError';
        }
        return alertMessage;
    };
    EventWindow.prototype.getRecurrenceIndex = function (recColl, event) {
        var recIndex;
        for (var index = 0; index < recColl.length; index++) {
            if (event[this.fields.startTime].valueOf() === recColl[parseInt(index.toString(), 10)][this.fields.startTime].valueOf()) {
                recIndex = index;
                break;
            }
        }
        return recIndex;
    };
    EventWindow.prototype.trimAllDay = function (data) {
        if (data[this.fields.isAllDay]) {
            var temp = addDays(new Date(+data[this.fields.endTime]), -1).getTime();
            data[this.fields.endTime] = (+data[this.fields.startTime] > temp) ? data[this.fields.endTime] : new Date(temp);
        }
    };
    EventWindow.prototype.editOccurrenceValidation = function (eventId, currentData, editData) {
        var _this = this;
        if (editData === void 0) {
            editData = this.eventData;
        }
        var recurColl = this.parent.getOccurrencesByID(eventId);
        var excludedDatas = this.parent.eventsData.filter(function (data) {
            return data[_this.fields.recurrenceID] === eventId;
        });
        excludedDatas.map(function (data) { return recurColl.push(extend({}, data)); });
        currentData = extend({}, currentData);
        this.trimAllDay(currentData);
        for (var _i = 0, recurColl_1 = recurColl; _i < recurColl_1.length; _i++) {
            var data = recurColl_1[_i];
            this.trimAllDay(data);
        }
        this.parent.eventBase.sortByTime(recurColl);
        var index = this.getRecurrenceIndex(recurColl, editData);
        if (isNullOrUndefined(index)) {
            return false;
        }
        var currentStartTime = new Date(+currentData[this.fields.startTime]);
        var currentEndTime = new Date(+currentData[this.fields.endTime]);
        var nextStartTime;
        var nextEndTime;
        if (index !== recurColl.length - 1) {
            nextStartTime = new Date(+recurColl[index + 1][this.fields.startTime]);
            nextEndTime = new Date(+recurColl[index + 1][this.fields.endTime]);
        }
        var lastEndTime = new Date(+recurColl[recurColl.length - 1][this.fields.endTime]);
        if (index === 0) {
            if (!isNullOrUndefined(recurColl[index + 1])) {
                if (!(nextStartTime.getTime() >= currentEndTime.getTime()) &&
                    (resetTime(lastEndTime).getTime() >=
                        resetTime(currentStartTime).getTime()) ||
                    resetTime(lastEndTime).getTime() < resetTime(currentStartTime).getTime()) {
                    this.parent.quickPopup.openRecurrenceValidationAlert('occurrenceAlert');
                    return true;
                }
                else if (!(resetTime(currentStartTime).getTime() <
                    resetTime(nextStartTime).getTime())) {
                    this.parent.quickPopup.openRecurrenceValidationAlert('sameDayAlert');
                    return true;
                }
            }
            return false;
        }
        else {
            var previousStartTime = new Date(+recurColl[index - 1][this.fields.startTime]);
            var previousEndTime = new Date(+recurColl[index - 1][this.fields.endTime]);
            if (index === recurColl.length - 1) {
                if (resetTime(new Date(+recurColl[(recurColl.length - 1) - index][this.fields.startTime])).getTime() >
                    resetTime(currentStartTime).getTime()) {
                    this.parent.quickPopup.openRecurrenceValidationAlert('occurrenceAlert');
                    return true;
                }
                else if (!((previousEndTime.getTime() <= currentStartTime.getTime()) &&
                    (resetTime(currentStartTime).getTime() > resetTime(previousStartTime).getTime()))) {
                    this.parent.quickPopup.openRecurrenceValidationAlert('sameDayAlert');
                    return true;
                }
            }
            else if (!(((resetTime(previousStartTime).getTime() < resetTime(currentStartTime).getTime()) ||
                resetTime(new Date(+recurColl[0][this.fields.startTime])).getTime() >
                    resetTime(currentStartTime).getTime()) &&
                ((resetTime(nextStartTime).getTime() > resetTime(currentStartTime).getTime()) ||
                    (lastEndTime.getTime() < currentStartTime.getTime())))) {
                this.parent.quickPopup.openRecurrenceValidationAlert('sameDayAlert');
                return true;
            }
            else if (!(previousEndTime.getTime() <= currentStartTime.getTime() && nextStartTime.getTime() >=
                currentEndTime.getTime()) || (resetTime(nextEndTime).getTime() <
                resetTime(currentStartTime).getTime()) ||
                (resetTime(previousStartTime).getTime() > resetTime(currentEndTime).getTime()) ||
                !(resetTime(currentStartTime).getTime() < resetTime(nextStartTime).getTime())) {
                this.parent.quickPopup.openRecurrenceValidationAlert('occurrenceAlert');
                return true;
            }
        }
        return false;
    };
    EventWindow.prototype.resourceSaveEvent = function (eventObj, action, currentAction) {
        var _this = this;
        var lastResourceData = this.parent.resourceBase.resourceCollection.slice(-1)[0];
        var resourceData = eventObj[lastResourceData.field];
        resourceData = (resourceData instanceof Array) ? resourceData.reverse() : [resourceData].reverse();
        var lastLevel = this.parent.resourceBase.lastResourceLevel;
        var eventList = [];
        var _loop_2 = function (i) {
            var events = extend({}, eventObj, null, true);
            events[this_2.fields.id] = this_2.parent.eventBase.getEventMaxID();
            var temp = [];
            var addValues = function () {
                if (action === 'Save' && i === resourceData.length - 1) {
                    if (temp.length > 0) {
                        temp[0][_this.fields.id] = eventObj[_this.fields.id];
                        for (var k = 1; k < temp.length; k++) {
                            temp[parseInt(k.toString(), 10)][_this.fields.id] = _this.parent.eventBase.getEventMaxID(i);
                            eventList.push(temp[parseInt(k.toString(), 10)]);
                            _this.parent.saveEvent(temp[0], currentAction);
                        }
                    }
                    else {
                        events[_this.fields.id] = eventObj[_this.fields.id];
                        _this.parent.saveEvent(events, currentAction);
                    }
                }
                else {
                    if (temp.length > 0) {
                        for (var j = 0; j < temp.length; j++) {
                            temp[parseInt(j.toString(), 10)][_this.fields.id] = _this.parent.eventBase.getEventMaxID(j);
                            eventList.push(temp[parseInt(j.toString(), 10)]);
                        }
                    }
                    else {
                        events[_this.fields.id] = _this.parent.eventBase.getEventMaxID(i);
                        eventList.push(events);
                    }
                }
            };
            if (this_2.parent.activeViewOptions.group.byGroupID && (!isNullOrUndefined(lastLevel))) {
                var lastResource = lastResourceData.dataSource;
                var resCol = this_2.parent.resourceCollection;
                var index = void 0;
                if (resCol.length > 1) {
                    index =
                        findIndexInData(lastResource, lastResourceData.idField, resourceData[parseInt(i.toString(), 10)], events, resCol);
                }
                else {
                    index =
                        findIndexInData(lastResource, lastResourceData.idField, resourceData[parseInt(i.toString(), 10)]);
                }
                if (index < 0) {
                    return { value: void 0 };
                }
                var groupId_1 = lastResource[parseInt(index.toString(), 10)][lastResourceData.groupIDField];
                var filter = lastLevel.filter(function (obj) { return obj.resourceData[lastResourceData.idField] ===
                    resourceData[parseInt(i.toString(), 10)]; }).
                    filter(function (obj) { return obj.resourceData[lastResourceData.groupIDField] === groupId_1; })[0];
                var groupOrder = filter.groupOrder;
                for (var index_1 = 0; index_1 < this_2.parent.resourceBase.resourceCollection.length; index_1++) {
                    var field = this_2.parent.resourceBase.resourceCollection[parseInt(index_1.toString(), 10)].field;
                    events["" + field] = (groupOrder[parseInt(index_1.toString(), 10)] instanceof Array) ? groupOrder[parseInt(index_1.toString(), 10)][0] :
                        groupOrder[parseInt(index_1.toString(), 10)];
                }
                addValues();
            }
            else {
                for (var index = 0; index < this_2.parent.resourceBase.resourceCollection.length - 1; index++) {
                    var field = this_2.parent.resourceBase.resourceCollection[parseInt(index.toString(), 10)].field;
                    if (events["" + field] instanceof Array && events["" + field].length > 1) {
                        for (var k = 0; k < events["" + field].length; k++) {
                            var event_3 = extend({}, events, null, true);
                            event_3["" + field] = eventObj["" + field][parseInt(k.toString(), 10)];
                            event_3[lastResourceData.field] = resourceData[parseInt(i.toString(), 10)];
                            temp.push(event_3);
                        }
                    }
                    else {
                        if (temp.length === 0) {
                            events["" + field] = (eventObj["" + field] instanceof Array) ?
                                eventObj["" + field][0] : eventObj["" + field];
                            events[lastResourceData.field] = resourceData[parseInt(i.toString(), 10)];
                        }
                        else {
                            for (var l = 0; l < temp.length; l++) {
                                temp[parseInt(l.toString(), 10)]["" + field] = (eventObj["" + field] instanceof Array) ?
                                    eventObj["" + field][0] : eventObj["" + field];
                            }
                        }
                    }
                }
                events[lastResourceData.field] = resourceData[parseInt(i.toString(), 10)];
                addValues();
            }
        };
        var this_2 = this;
        for (var i = 0; i < resourceData.length; i++) {
            var state_1 = _loop_2(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        if (eventList.length > 0) {
            for (var _i = 0, eventList_1 = eventList; _i < eventList_1.length; _i++) {
                var event_4 = eventList_1[_i];
                event_4[this.fields.recurrenceException] = null;
                event_4[this.fields.recurrenceID] = null;
            }
            this.parent.addEvent(eventList);
        }
    };
    EventWindow.prototype.getEventIdFromForm = function () {
        return this.element.querySelector('.' + FORM_CLASS).getAttribute('data-id');
    };
    EventWindow.prototype.getFormElements = function (className) {
        var elements = [];
        if (className === EVENT_WINDOW_DIALOG_CLASS) {
            elements = [].slice.call(this.element.querySelectorAll('.' + EVENT_FIELD$1));
        }
        else {
            elements = [].slice.call(this.parent.element.querySelectorAll('.' + className + ' .' + EVENT_FIELD$1));
        }
        return elements;
    };
    EventWindow.prototype.getValueFromElement = function (element) {
        var value;
        if (element.classList.contains('e-datepicker')) {
            value = element.ej2_instances[0].value;
        }
        else if (element.classList.contains('e-datetimepicker')) {
            value = element.ej2_instances[0].value;
        }
        else if (element.classList.contains('e-dropdownlist')) {
            value = element.ej2_instances[0].value;
        }
        else if (element.classList.contains('e-multiselect')) {
            value = element.ej2_instances[0].value;
        }
        else if (element.classList.contains('e-checkbox')) {
            value = element.ej2_instances[0].checked;
        }
        else {
            if (element.type === 'checkbox') {
                value = element.checked;
            }
            else {
                value = this.parent.enableHtmlSanitizer ?
                    SanitizeHtmlHelper.sanitize(element.value) : element.value;
            }
        }
        return value;
    };
    EventWindow.prototype.setValueToElement = function (element, value) {
        if (element.classList.contains('e-datepicker')) {
            var instance = element.ej2_instances[0];
            instance.value = value;
            instance.dataBind();
        }
        else if (element.classList.contains('e-datetimepicker')) {
            var instance = element.ej2_instances[0];
            if (instance.element.classList.contains(EVENT_WINDOW_START_CLASS)) {
                this.eventWindowTime.startTime = new Date('' + value);
            }
            else {
                this.eventWindowTime.endTime = new Date('' + value);
            }
            instance.value = value;
            instance.dataBind();
        }
        else if (element.classList.contains('e-dropdownlist')) {
            var instance = element.ej2_instances[0];
            instance.value = value;
            instance.dataBind();
        }
        else if (element.classList.contains('e-multiselect')) {
            var instance = element.ej2_instances[0];
            instance.value = [];
            instance.value = ((value instanceof Array) ? value : [value]);
            instance.dataBind();
        }
        else if (element.classList.contains('e-checkbox')) {
            var instance = element.ej2_instances[0];
            instance.checked = value;
            instance.dataBind();
        }
        else {
            if (element.type !== 'checkbox') {
                element.value = value || '';
            }
            else {
                element.checked = value;
            }
        }
    };
    EventWindow.prototype.setDefaultValueToElement = function (element) {
        if (element.classList.contains('e-datepicker')) {
            var instance = element.ej2_instances[0];
            instance.value = this.parent.getCurrentTime();
            instance.dataBind();
        }
        else if (element.classList.contains('e-datetimepicker')) {
            var instance = element.ej2_instances[0];
            var dateValue = this.parent.getCurrentTime();
            this.eventWindowTime = { startTime: dateValue, endTime: dateValue };
            instance.value = dateValue;
            instance.dataBind();
        }
        else if (element.classList.contains('e-dropdownlist')) {
            var instance = element.ej2_instances[0];
            instance.value = null;
            instance.dataBind();
        }
        else if (element.classList.contains('e-multiselect')) {
            var instance = element.ej2_instances[0];
            instance.value = [];
            instance.dataBind();
        }
        else if (element.classList.contains('e-checkbox')) {
            var instance = element.ej2_instances[0];
            instance.checked = false;
            instance.dataBind();
        }
        else {
            if (element.type === 'checkbox') {
                element.checked = false;
            }
            else {
                element.value = '';
            }
        }
    };
    EventWindow.prototype.getInstance = function (className) {
        var element = this.element.querySelector('.' + className);
        return element ? element.ej2_instances[0] : null;
    };
    EventWindow.prototype.eventDelete = function (event) {
        if (this.isEnterKey) {
            this.isEnterKey = false;
            return;
        }
        switch (this.parent.currentAction) {
            case 'EditOccurrence':
                if (!isNullOrUndefined(this.parent.activeEventData.event[this.parent.eventFields.recurrenceRule])) {
                    this.parent.currentAction = 'DeleteOccurrence';
                }
                else {
                    this.parent.currentAction = 'Delete';
                }
                break;
            case 'EditSeries':
                this.parent.currentAction = 'DeleteSeries';
                break;
            case 'Save':
                this.parent.currentAction = 'Delete';
                break;
            case 'EditFollowingEvents':
                if (!isNullOrUndefined(this.parent.activeEventData.event[this.parent.eventFields.recurrenceRule])) {
                    this.parent.currentAction = 'DeleteFollowingEvents';
                }
                break;
        }
        this.dialogEvent = event;
        this.isCrudAction = false;
        this.dialogObject.hide();
        this.parent.quickPopup.openDeleteAlert();
    };
    EventWindow.prototype.getRecurrenceEditorInstance = function () {
        if (this.parent.isAdaptive && !this.repeatDialogObject) {
            this.renderRepeatDialog();
        }
        return this.recurrenceEditor;
    };
    EventWindow.prototype.destroyComponents = function () {
        var formElements = this.getFormElements(EVENT_WINDOW_DIALOG_CLASS);
        for (var _i = 0, formElements_3 = formElements; _i < formElements_3.length; _i++) {
            var element = formElements_3[_i];
            var instance = void 0;
            if (element.classList.contains('e-datetimepicker')) {
                instance = element.ej2_instances;
            }
            else if (element.classList.contains('e-datepicker')) {
                instance = element.ej2_instances;
            }
            else if (element.classList.contains('e-checkbox')) {
                instance = element.ej2_instances;
            }
            else if (element.classList.contains('e-dropdownlist')) {
                instance = element.ej2_instances;
            }
            else if (element.classList.contains('e-multiselect')) {
                instance = element.ej2_instances;
            }
            else if (element.classList.contains('e-numerictextbox')) {
                instance = element.ej2_instances;
            }
            if (instance && instance[0]) {
                instance[0].destroy();
            }
        }
        if (this.buttonObj) {
            this.buttonObj.destroy();
        }
    };
    EventWindow.prototype.detachComponents = function () {
        var formElements = this.getFormElements(EVENT_WINDOW_DIALOG_CLASS);
        for (var _i = 0, formElements_4 = formElements; _i < formElements_4.length; _i++) {
            var element = formElements_4[_i];
            detach(element);
        }
    };
    EventWindow.prototype.destroy = function (isIgnore) {
        if (this.parent && !this.parent.isDestroyed) {
            this.parent.resetTemplates(['editorTemplate', 'editorHeaderTemplate', 'editorFooterTemplate']);
        }
        this.destroyComponents();
        if (this.recurrenceEditor) {
            this.recurrenceEditor.destroy();
            detach(this.recurrenceEditor.element);
            this.recurrenceEditor = null;
        }
        if (this.fieldValidator) {
            this.fieldValidator.destroy();
            this.fieldValidator = null;
        }
        if (this.repeatDialogObject) {
            this.repeatDialogObject.destroy();
            this.repeatDialogObject = null;
        }
        this.detachComponents();
        if (this.dialogObject) {
            if (this.dialogObject.element) {
                var form = this.dialogObject.element.querySelector('form');
                removeChildren(form);
                detach(form);
                EventHandler.remove(this.dialogObject.element, 'keydown', this.preventEventSave);
            }
            this.dialogObject.destroy();
            this.dialogObject = null;
        }
        if (this.element) {
            remove(this.element);
            this.element = null;
        }
        if (!isIgnore) {
            this.l10n = null;
            this.parent = null;
            this.fields = null;
            this.buttonObj = null;
            this.repeatStatus = null;
            this.eventWindowTime = null;
            this.dialogEvent = null;
        }
    };
    return EventWindow;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Virtual Scroll
 */
var VirtualScroll = /** @__PURE__ @class */ (function () {
    function VirtualScroll(parent) {
        this.translateY = 0;
        this.itemSize = 60;
        this.bufferCount = 3;
        this.renderedLength = 0;
        this.averageRowHeight = 0;
        this.startIndex = 0;
        this.parent = parent;
        this.addEventListener();
    }
    VirtualScroll.prototype.addEventListener = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent.on(virtualScroll, this.virtualScrolling, this);
    };
    VirtualScroll.prototype.removeEventListener = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent.off(virtualScroll, this.virtualScrolling);
    };
    VirtualScroll.prototype.getRenderedCount = function () {
        this.setItemSize();
        var containerSize = this.isHorizontalScroll ? this.parent.element.clientWidth : this.parent.element.clientHeight;
        this.renderedLength = Math.ceil(containerSize / this.itemSize) + this.bufferCount;
        return this.renderedLength;
    };
    VirtualScroll.prototype.renderVirtualTrack = function (contentWrap) {
        var wrap = createElement('div', { className: VIRTUAL_TRACK_CLASS });
        if (this.isHorizontalScroll) {
            var colCount = this.parent.activeView.colLevels[this.parent.activeView.colLevels.length - 1].length;
            wrap.style.width = (colCount * this.itemSize) + 'px';
        }
        else {
            wrap.style.height = (this.parent.resourceBase.expandedResources.length * this.itemSize) + 'px';
        }
        contentWrap.appendChild(wrap);
    };
    VirtualScroll.prototype.updateVirtualScrollHeight = function () {
        var virtual = this.parent.element.querySelector('.' + VIRTUAL_TRACK_CLASS);
        var lastResourceIndex = this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1].groupIndex;
        var lastRenderIndex = this.parent.resourceBase.renderedResources[this.parent.resourceBase.renderedResources.length - 1].groupIndex;
        if (lastRenderIndex !== lastResourceIndex) {
            var conTable = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS);
            this.renderedLength = conTable.querySelector('tbody').children.length;
            virtual.style.height = (conTable.offsetHeight + (this.parent.resourceBase.expandedResources.length - (this.renderedLength)) *
                conTable.offsetHeight / this.renderedLength) + 'px';
        }
        else {
            virtual.style.height = '';
        }
        this.averageRowHeight = virtual.offsetHeight / this.parent.resourceBase.expandedResources.length;
    };
    VirtualScroll.prototype.updateVirtualTrackHeight = function (wrap) {
        var resourceCount = this.parent.resourceBase.renderedResources.length;
        if (resourceCount !== this.getRenderedCount()) {
            wrap.style.height = this.parent.element.querySelector('.e-content-wrap').clientHeight + 'px';
            var resWrap = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
            var conWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
            var eventWrap = this.parent.element.querySelector('.' + EVENT_TABLE_CLASS);
            this.translateY = 0;
            this.setTranslate(resWrap, conWrap, eventWrap);
        }
        else {
            var lastRenderIndex = this.parent.resourceBase.renderedResources[resourceCount - 1].groupIndex;
            var lastCollIndex = this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1].groupIndex;
            var renderedResCount = resourceCount + (lastCollIndex - lastRenderIndex);
            renderedResCount = (renderedResCount > this.parent.resourceBase.expandedResources.length) ?
                this.parent.resourceBase.expandedResources.length : renderedResCount;
            wrap.style.height = (renderedResCount * this.itemSize) + 'px';
        }
    };
    VirtualScroll.prototype.setItemSize = function () {
        if (this.isHorizontalScroll) {
            this.itemSize = getElementWidthFromClass(this.parent.activeView.element, WORK_CELLS_CLASS) || this.itemSize;
        }
        else {
            this.itemSize = getElementHeightFromClass(this.parent.activeView.element, WORK_CELLS_CLASS) || this.itemSize;
        }
    };
    VirtualScroll.prototype.renderEvents = function () {
        this.setTabIndex();
        var dynamicData = this.triggerScrollEvent(virtualScrollStop);
        if (this.parent.activeViewOptions && this.parent.activeViewOptions.enableLazyLoading && this.parent.crudModule) {
            if (dynamicData.length > 0) {
                this.parent.crudModule.refreshProcessedData(true, dynamicData);
                this.parent.hideSpinner();
                return;
            }
            this.parent.crudModule.refreshDataManager();
            return;
        }
        if (this.parent.crudModule) {
            this.parent.crudModule.refreshProcessedData(true);
        }
        if (this.parent.currentView !== 'Month') {
            this.parent.notify(contentReady, {});
        }
        this.parent.hideSpinner();
    };
    VirtualScroll.prototype.virtualScrolling = function () {
        var _this = this;
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide();
            this.parent.quickPopup.morePopup.hide();
        }
        var conWrap = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (this.isHorizontalScroll) {
            this.horizontalScrolling(conWrap);
        }
        else {
            var resWrap = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
            var eventWrap = this.parent.element.querySelector('.' + EVENT_TABLE_CLASS);
            var timeIndicator = this.parent.element.querySelector('.' + CURRENT_TIMELINE_CLASS);
            var conTable = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS);
            addClass([conWrap], 'e-transition');
            this.renderedLength = resWrap.querySelector('tbody').children.length;
            var firstTDIndex = parseInt(resWrap.querySelector('tbody td').getAttribute('data-group-index'), 10);
            var scrollHeight = this.parent.rowAutoHeight ?
                (conTable.offsetHeight - conWrap.offsetHeight) : this.bufferCount * this.itemSize;
            var resCollection = [];
            if ((conWrap.scrollTop) - this.translateY < 0) {
                resCollection = this.upScroll(conWrap, firstTDIndex);
            }
            else if (conWrap.scrollTop - this.translateY > scrollHeight) {
                resCollection = this.downScroll(conWrap, firstTDIndex);
            }
            if (!isNullOrUndefined(resCollection) && resCollection.length > 0) {
                this.triggerScrollEvent(virtualScrollStart);
                var selectedEle = this.parent.getSelectedCells();
                this.focusedEle = selectedEle[selectedEle.length - 1] || this.focusedEle;
                this.updateContent(resWrap, conWrap, eventWrap, resCollection);
                this.setTranslate(resWrap, conWrap, eventWrap, timeIndicator);
                if (this.parent.dragAndDropModule && this.parent.dragAndDropModule.actionObj.action === 'drag') {
                    this.parent.dragAndDropModule.navigationWrapper();
                }
                window.clearTimeout(this.timeValue);
                this.timeValue = window.setTimeout(function () { _this.renderEvents(); }, 250);
            }
        }
    };
    VirtualScroll.prototype.horizontalScrolling = function (conWrap) {
        var _this = this;
        var resCollection = [];
        var scrollWidth = this.bufferCount * this.itemSize;
        if (Math.abs(conWrap.scrollLeft) - Math.abs(this.translateY) < 0) {
            resCollection = this.leftScroll(conWrap);
        }
        else if (Math.abs(conWrap.scrollLeft) - Math.abs(this.translateY) > scrollWidth) {
            resCollection = this.rightScroll(conWrap);
        }
        if (!isNullOrUndefined(resCollection) && resCollection.length > 0) {
            if (this.parent.resourceBase.expandedResources.length !== resCollection.length ||
                this.parent.resourceBase.expandedResources[0] !== resCollection[0] ||
                this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1] !==
                    resCollection[resCollection.length - 1]) {
                this.triggerScrollEvent(virtualScrollStart);
                var colLevels = this.parent.activeView.colLevels.slice(0);
                this.updateHorizontalContent(conWrap, resCollection);
                setStyleAttribute(conWrap.querySelector('table'), { transform: "translateX(" + this.translateY + "px)" });
                this.parent.activeView.colLevels = colLevels;
                if (this.parent.dragAndDropModule && this.parent.dragAndDropModule.actionObj.action === 'drag') {
                    this.parent.dragAndDropModule.navigationWrapper();
                }
            }
            window.clearTimeout(this.timeValue);
            this.timeValue = window.setTimeout(function () { _this.renderEvents(); }, 250);
        }
    };
    VirtualScroll.prototype.triggerScrollEvent = function (action) {
        var _this = this;
        var dynamicData = [];
        if (!this.parent.activeView) {
            return dynamicData;
        }
        var eventArgs = {
            startDate: this.parent.activeView.startDate(),
            endDate: this.parent.activeView.endDate(),
            startIndex: this.parent.resourceBase.renderedResources[0].groupIndex,
            endIndex: this.parent.resourceBase.renderedResources[this.parent.resourceBase.renderedResources.length - 1].groupIndex,
            resourceData: this.parent.resourceBase.renderedResources.map(function (x) { return x.resourceData; }),
            name: action
        };
        this.parent.trigger(action, eventArgs, function (args) {
            if (action === virtualScrollStart) {
                _this.parent.showSpinner();
            }
            else if (action === virtualScrollStop && !isNullOrUndefined(args.eventData) && args.eventData.length > 0) {
                dynamicData = args.eventData;
            }
        });
        return dynamicData;
    };
    VirtualScroll.prototype.upScroll = function (conWrap, firstTDIndex) {
        var index = 0;
        index = (~~(conWrap.scrollTop / this.itemSize) + Math.ceil(conWrap.clientHeight / this.itemSize)) - this.renderedLength;
        if (this.parent.rowAutoHeight) {
            index = (index > firstTDIndex) ? firstTDIndex - this.bufferCount : index;
        }
        index = (index > 0) ? index : 0;
        var prevSetCollection = this.getBufferCollection(index, index + this.renderedLength);
        this.parent.resourceBase.renderedResources = prevSetCollection;
        if (firstTDIndex === 0) {
            this.translateY = conWrap.scrollTop;
        }
        else {
            var height = (this.parent.rowAutoHeight) ? this.averageRowHeight : this.itemSize;
            height = (height > 0) ? height : this.itemSize;
            this.translateY = (conWrap.scrollTop - (this.bufferCount * height) > 0) ?
                conWrap.scrollTop - (this.bufferCount * height) : 0;
        }
        return prevSetCollection;
    };
    VirtualScroll.prototype.downScroll = function (conWrap, firstTDIndex) {
        var lastResource = this.parent.resourceBase.
            renderedResources[this.parent.resourceBase.renderedResources.length - 1].groupIndex;
        var lastResourceIndex = this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1].groupIndex;
        if (lastResource === lastResourceIndex) {
            return null;
        }
        var nextSetResIndex = 0;
        nextSetResIndex = ~~(conWrap.scrollTop / this.itemSize);
        if (this.parent.rowAutoHeight) {
            nextSetResIndex = ~~((conWrap.scrollTop - this.translateY) / this.averageRowHeight) + firstTDIndex;
            nextSetResIndex = (nextSetResIndex > firstTDIndex + this.bufferCount) ? nextSetResIndex : firstTDIndex + this.bufferCount;
        }
        var lastIndex = nextSetResIndex + this.renderedLength;
        lastIndex = (lastIndex > this.parent.resourceBase.expandedResources.length) ?
            nextSetResIndex + (this.parent.resourceBase.expandedResources.length - nextSetResIndex) : lastIndex;
        var nextSetCollection = this.getBufferCollection(lastIndex - this.renderedLength, lastIndex);
        this.translateY = conWrap.scrollTop;
        return nextSetCollection;
    };
    VirtualScroll.prototype.leftScroll = function (conWrap) {
        var index = 0;
        index = (~~(Math.abs(conWrap.scrollLeft) / this.itemSize) + Math.ceil(conWrap.clientWidth / this.itemSize)) - this.renderedLength;
        index = (index > 0) ? index : 0;
        return this.getCollection(index, index + this.renderedLength);
    };
    VirtualScroll.prototype.rightScroll = function (conWrap) {
        var lastLevel = this.parent.activeView.colLevels[this.parent.activeView.colLevels.length - 1];
        var nextSetIndex = 0;
        nextSetIndex = ~~(Math.abs(conWrap.scrollLeft) / this.itemSize);
        var lastIndex = nextSetIndex + this.renderedLength;
        lastIndex = (lastIndex > lastLevel.length - 1) ? lastLevel.length - 1 : lastIndex;
        return this.getCollection(lastIndex - this.renderedLength, lastIndex);
    };
    VirtualScroll.prototype.getCollection = function (startIndex, endIndex) {
        this.translateY = startIndex * this.itemSize;
        var lastLevel = this.getResCollection(startIndex, endIndex);
        if (this.parent.enableRtl) {
            this.translateY = -this.translateY;
        }
        return lastLevel;
    };
    VirtualScroll.prototype.getResCollection = function (startIndex, endIndex) {
        var lastLevel = this.parent.activeView.colLevels[this.parent.activeView.colLevels.length - 1];
        var resCollection = [];
        var index = { startIndex: 0, endIndex: 0 };
        if (this.parent.activeViewOptions.group.byDate) {
            if (lastLevel[parseInt(startIndex.toString(), 10)].date.getTime() ===
                this.parent.resourceBase.expandedResources[0].date.getTime() &&
                lastLevel[parseInt(endIndex.toString(), 10)].date.getTime() ===
                    this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1].date.getTime()) {
                return this.parent.resourceBase.expandedResources;
            }
            resCollection =
                this.getByDateCollection(lastLevel[parseInt(startIndex.toString(), 10)], lastLevel[parseInt(endIndex.toString(), 10)], index);
            this.setRenderedDates(resCollection);
        }
        else {
            if (lastLevel[parseInt(startIndex.toString(), 10)].groupIndex === this.parent.resourceBase.expandedResources[0].groupIndex &&
                lastLevel[parseInt(endIndex.toString(), 10)].groupIndex ===
                    this.parent.resourceBase.expandedResources[this.parent.resourceBase.expandedResources.length - 1].groupIndex) {
                return this.parent.resourceBase.expandedResources;
            }
            resCollection =
                this.getByIdCollection(lastLevel[parseInt(startIndex.toString(), 10)], lastLevel[parseInt(endIndex.toString(), 10)], index);
        }
        if (this.parent.currentView !== 'Month') {
            this.startIndex = index.startIndex;
            resCollection = lastLevel.slice(index.startIndex, index.endIndex);
        }
        this.translateY = index.startIndex * this.itemSize;
        return resCollection;
    };
    VirtualScroll.prototype.getByDateCollection = function (firstItem, lastItem, index) {
        var resCollection = this.parent.activeView.colLevels[0].filter(function (data) {
            return firstItem.date.getTime() <= data.date.getTime() &&
                data.date.getTime() <= lastItem.date.getTime();
        });
        this.setStartEndIndex(this.parent.activeView.colLevels[0], resCollection[0], resCollection[resCollection.length - 1], index);
        return resCollection;
    };
    VirtualScroll.prototype.getByIdCollection = function (firstItem, lastItem, index) {
        var resCollection = this.parent.resourceBase.lastResourceLevel.filter(function (data) {
            return firstItem.groupIndex <= data.groupIndex && data.groupIndex <= lastItem.groupIndex;
        });
        this.parent.resourceBase.renderedResources = resCollection;
        this.setStartEndIndex(this.parent.resourceBase.lastResourceLevel, resCollection[0], resCollection[resCollection.length - 1], index);
        return resCollection;
    };
    VirtualScroll.prototype.setStartEndIndex = function (data, firstItem, lastItem, colIndex) {
        var index = 0;
        data.filter(function (data) {
            if (firstItem === data) {
                colIndex.startIndex = index;
            }
            else if (lastItem === data) {
                colIndex.endIndex = index + data.colSpan;
            }
            index += data.colSpan;
        });
        if (firstItem === lastItem) {
            colIndex.endIndex = colIndex.startIndex + lastItem.colSpan;
        }
    };
    VirtualScroll.prototype.updateContent = function (resWrap, conWrap, eventWrap, resCollection) {
        var renderedLength = resWrap.querySelector('tbody').children.length;
        if (document.activeElement && document.activeElement.classList.contains(RESOURCE_CELLS_CLASS)) {
            this.isResourceCell = true;
            this.parent.element.focus();
        }
        for (var i = 0; i < renderedLength; i++) {
            remove(resWrap.querySelector('tbody tr'));
            remove(conWrap.querySelector('tbody tr'));
            remove(eventWrap.querySelector('div'));
        }
        this.parent.resourceBase.renderedResources = resCollection;
        var resourceRows = this.parent.resourceBase.getContentRows(resCollection, true);
        var contentRows = this.parent.activeView.getContentRows();
        var eventRows = this.parent.activeView.getEventRows(resCollection.length);
        append(resourceRows, resWrap.querySelector('tbody'));
        append(contentRows, conWrap.querySelector('tbody'));
        append(eventRows, eventWrap);
    };
    VirtualScroll.prototype.updateHorizontalContent = function (conWrap, resCollection) {
        this.parent.resourceBase.expandedResources = resCollection;
        var selectedEle = this.parent.getSelectedCells();
        this.focusedEle = selectedEle[selectedEle.length - 1] || this.focusedEle;
        var renderedLength = conWrap.querySelectorAll('tbody tr').length;
        for (var i = 0; i < renderedLength; i++) {
            remove(conWrap.querySelector('tbody tr'));
        }
        if (this.parent.currentView === 'Month') {
            if (this.parent.activeViewOptions.group.byDate) {
                this.parent.activeView.colLevels[0] = resCollection;
            }
            else {
                this.parent.activeView.colLevels[this.parent.activeView.colLevels.length - 2] = resCollection;
            }
            var contentRows = this.parent.activeView.getContentRows();
            append(contentRows, conWrap.querySelector('tbody'));
        }
        else {
            var col = [].slice.call(conWrap.querySelector('colgroup').children);
            for (var i = 0; i < col.length; i++) {
                remove(col[parseInt(i.toString(), 10)]);
            }
            this.parent.activeView.colLevels[this.parent.activeView.colLevels.length - 1] = resCollection;
            var contentRows = this.parent.activeView.getContentRows();
            var table = conWrap.querySelector('table');
            var thead = conWrap.querySelector('thead');
            var colGroupEle_1 = conWrap.querySelector('colgroup');
            resCollection.forEach(function () {
                colGroupEle_1.appendChild(createElement('col'));
            });
            thead.appendChild(this.parent.eventBase.createEventWrapper('', this.startIndex > 0 ? this.startIndex : 0));
            if (this.parent.activeViewOptions.timeScale.enable) {
                thead.appendChild(this.parent.eventBase.createEventWrapper('timeIndicator'));
            }
            prepend([thead], table);
            append(contentRows, conWrap.querySelector('tbody'));
        }
    };
    VirtualScroll.prototype.getBufferCollection = function (startIndex, endIndex) {
        return this.parent.resourceBase.expandedResources.slice(startIndex, endIndex);
    };
    VirtualScroll.prototype.setTranslate = function (resWrap, conWrap, eventWrap, timeIndicator) {
        setStyleAttribute(resWrap.querySelector('table'), { transform: "translateY(" + this.translateY + "px)" });
        setStyleAttribute(conWrap.querySelector('table'), { transform: "translateY(" + this.translateY + "px)" });
        setStyleAttribute(eventWrap, { transform: "translateY(" + this.translateY + "px)" });
        if (!isNullOrUndefined(timeIndicator)) {
            setStyleAttribute(timeIndicator, { transform: "translateY(" + this.translateY + "px)" });
        }
    };
    VirtualScroll.prototype.updateFocusedWorkCell = function () {
        if (this.focusedEle) {
            var date = parseInt(this.focusedEle.getAttribute('data-date'), 10);
            var groupIndex = parseInt(this.focusedEle.getAttribute('data-group-index'), 10);
            var ele = this.parent.element.querySelector("." + WORK_CELLS_CLASS + "[data-date=\"" + date + "\"][data-group-index=\"" + groupIndex + "\"]");
            if (ele) {
                this.parent.addSelectedClass([ele], ele, true);
            }
            this.focusedEle = null;
        }
    };
    VirtualScroll.prototype.setRenderedDates = function (resCollection) {
        if (this.parent.currentView !== 'Month') {
            var dateCol_1 = resCollection.map(function (x) { return x.date; });
            this.parent.resourceBase.renderedResources.forEach(function (x) { return x.renderDates = dateCol_1; });
        }
        else {
            var dateCol_2 = resCollection.map(function (x) { return x.date.getDay(); });
            var renderDates_1 = this.parent.activeView.renderDates.filter(function (x) { return dateCol_2.indexOf(x.getDay()) >= 0; });
            this.parent.resourceBase.renderedResources.forEach(function (x) { return x.renderDates = renderDates_1; });
        }
    };
    VirtualScroll.prototype.setTabIndex = function () {
        var resColWrap = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
        var resCells = [].slice.call(this.parent.element.querySelectorAll('.' + RESOURCE_CELLS_CLASS));
        if (resCells && resColWrap) {
            resCells.forEach(function (element) {
                if (element.getBoundingClientRect().top >= resColWrap.getBoundingClientRect().top) {
                    element.setAttribute('tabindex', '0');
                }
            });
        }
        var focusResCell = this.parent.element.querySelector("." + RESOURCE_CELLS_CLASS + "[tabindex=\"" + 0 + "\"]");
        if (this.isResourceCell && focusResCell) {
            focusResCell.focus();
            this.isResourceCell = false;
        }
    };
    VirtualScroll.prototype.destroy = function () {
        this.removeEventListener();
        this.focusedEle = null;
    };
    return VirtualScroll;
}());

/**
 * Schedule DOM rendering
 */
var Render = /** @__PURE__ @class */ (function () {
    function Render(parent) {
        this.parent = parent;
    }
    Render.prototype.render = function (viewName, isDataRefresh) {
        if (isDataRefresh === void 0) { isDataRefresh = true; }
        this.initializeLayout(viewName);
        if (this.parent.activeView && isDataRefresh) {
            this.parent.crudModule.refreshDataManager();
        }
    };
    Render.prototype.initializeLayout = function (viewName) {
        if (this.parent.activeView) {
            var templates = [
                'cellTemplate', 'eventTemplate', 'tooltipTemplate', 'majorSlotTemplate', 'minorSlotTemplate',
                'headerTooltipTemplate', 'dateHeaderTemplate', 'dayHeaderTemplate', 'monthHeaderTemplate',
                'headerIndentTemplate', 'resourceHeaderTemplate', 'cellHeaderTemplate', 'dateRangeTemplate'
            ];
            this.parent.resetTemplates(templates);
            this.parent.activeView.removeEventListener();
            this.parent.activeView.destroy();
        }
        switch (viewName) {
            case 'Day':
                this.parent.activeView = this.parent.dayModule;
                break;
            case 'Week':
                this.parent.activeView = this.parent.weekModule;
                break;
            case 'WorkWeek':
                this.parent.activeView = this.parent.workWeekModule;
                break;
            case 'Month':
                this.parent.activeView = this.parent.monthModule;
                break;
            case 'Year':
                this.parent.activeView = this.parent.yearModule;
                break;
            case 'Agenda':
                this.parent.activeView = this.parent.agendaModule;
                break;
            case 'MonthAgenda':
                this.parent.activeView = this.parent.monthAgendaModule;
                break;
            case 'TimelineDay':
                this.parent.activeView = this.parent.timelineViewsModule;
                this.parent.activeView.viewClass = 'e-timeline-day-view';
                break;
            case 'TimelineWorkWeek':
                this.parent.activeView = this.parent.timelineViewsModule;
                this.parent.activeView.viewClass = 'e-timeline-work-week-view';
                break;
            case 'TimelineWeek':
                this.parent.activeView = this.parent.timelineViewsModule;
                this.parent.activeView.viewClass = 'e-timeline-week-view';
                break;
            case 'TimelineMonth':
                this.parent.activeView = this.parent.timelineMonthModule;
                break;
            case 'TimelineYear':
                this.parent.activeView = this.parent.timelineYearModule;
                break;
        }
        if (isNullOrUndefined(this.parent.activeView)) {
            var firstView = this.parent.viewCollections[0].option;
            if (firstView) {
                this.parent.setProperties({ currentView: firstView }, true);
                if (this.parent.headerModule) {
                    this.parent.headerModule.updateActiveView();
                    this.parent.headerModule.setCalendarView();
                }
                return this.initializeLayout(firstView);
            }
            throw Error('Inject required modules');
        }
        this.parent.activeView.viewIndex = this.parent.viewIndex;
        this.updateLabelText(viewName);
        this.parent.activeView.addEventListener();
        this.parent.activeView.getRenderDates();
        this.parent.uiStateValues.isGroupAdaptive = this.parent.activeViewOptions.group.resources.length > 0 &&
            (this.parent.enableAdaptiveUI && !this.parent.isAdaptive ||
                this.parent.isAdaptive && this.parent.activeViewOptions.group.enableCompactView);
        if (this.parent.virtualScrollModule) {
            this.parent.virtualScrollModule.destroy();
            this.parent.virtualScrollModule = null;
        }
        if ((['Agenda', 'MonthAgenda', 'Year', 'TimelineYear'].indexOf(this.parent.currentView) === -1 ||
            (this.parent.currentView === 'TimelineYear' && this.parent.activeViewOptions.orientation === 'Vertical'))
            && this.parent.activeViewOptions.allowVirtualScrolling
            && this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            this.parent.virtualScrollModule = new VirtualScroll(this.parent);
            if (this.parent.activeView.isTimelineView()) {
                this.parent.uiStateValues.top = 0;
            }
            else {
                this.parent.virtualScrollModule.isHorizontalScroll = true;
                this.parent.uiStateValues.left = 0;
            }
        }
        this.updateHeader();
        this.parent.currentTimezoneDate = this.parent.getCurrentTime();
        this.parent.activeView.renderLayout(CURRENT_PANEL_CLASS);
        this.parent.renderTemplates();
        if (this.parent.eventTooltip) {
            this.parent.eventTooltip.destroy();
            this.parent.eventTooltip = null;
        }
        if (this.parent.eventSettings.enableTooltip || (this.parent.activeViewOptions.group.resources.length > 0
            && this.parent.activeViewOptions.group.headerTooltipTemplate)) {
            this.parent.eventTooltip = new EventTooltip(this.parent);
        }
    };
    Render.prototype.updateHeader = function () {
        if (this.parent.headerModule) {
            this.parent.headerModule.setDayOfWeek(this.parent.activeViewOptions.firstDayOfWeek);
            if (this.parent.activeViewOptions.readonly) {
                addClass([this.parent.element], READ_ONLY);
            }
            else if (this.parent.element.classList.contains(READ_ONLY)) {
                removeClass([this.parent.element], READ_ONLY);
            }
            this.parent.headerModule.updateDateRange();
            this.parent.headerModule.updateHeaderItems('remove');
        }
    };
    Render.prototype.updateLabelText = function (view) {
        var content = this.parent.activeView.getLabelText(view);
        this.parent.element.setAttribute('role', 'main');
        this.parent.element.setAttribute('aria-label', content);
    };
    return Render;
}());

var __extends$5 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$2 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that represents the configuration of working hours related options of scheduler.
 */
var WorkHours = /** @__PURE__ @class */ (function (_super) {
    __extends$5(WorkHours, _super);
    function WorkHours() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$2([
        Property(true)
    ], WorkHours.prototype, "highlight", void 0);
    __decorate$2([
        Property('09:00')
    ], WorkHours.prototype, "start", void 0);
    __decorate$2([
        Property('18:00')
    ], WorkHours.prototype, "end", void 0);
    return WorkHours;
}(ChildProperty));

var __extends$6 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$3 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that represents the configuration of options related to timescale on scheduler.
 */
var TimeScale = /** @__PURE__ @class */ (function (_super) {
    __extends$6(TimeScale, _super);
    function TimeScale() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$3([
        Property(true)
    ], TimeScale.prototype, "enable", void 0);
    __decorate$3([
        Property(60)
    ], TimeScale.prototype, "interval", void 0);
    __decorate$3([
        Property(2)
    ], TimeScale.prototype, "slotCount", void 0);
    __decorate$3([
        Property()
    ], TimeScale.prototype, "minorSlotTemplate", void 0);
    __decorate$3([
        Property()
    ], TimeScale.prototype, "majorSlotTemplate", void 0);
    return TimeScale;
}(ChildProperty));

var __extends$7 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$4 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that defines the template options available to customize the quick popup of scheduler.
 */
var QuickInfoTemplates = /** @__PURE__ @class */ (function (_super) {
    __extends$7(QuickInfoTemplates, _super);
    function QuickInfoTemplates() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$4([
        Property('Both')
    ], QuickInfoTemplates.prototype, "templateType", void 0);
    __decorate$4([
        Property()
    ], QuickInfoTemplates.prototype, "header", void 0);
    __decorate$4([
        Property()
    ], QuickInfoTemplates.prototype, "content", void 0);
    __decorate$4([
        Property()
    ], QuickInfoTemplates.prototype, "footer", void 0);
    return QuickInfoTemplates;
}(ChildProperty));

var __extends$8 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$5 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that represents the header rows related configurations on timeline views.
 */
var HeaderRows = /** @__PURE__ @class */ (function (_super) {
    __extends$8(HeaderRows, _super);
    function HeaderRows() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$5([
        Property()
    ], HeaderRows.prototype, "option", void 0);
    __decorate$5([
        Property()
    ], HeaderRows.prototype, "template", void 0);
    return HeaderRows;
}(ChildProperty));

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/**
 * Schedule CRUD operations
 */
var Crud = /** @__PURE__ @class */ (function () {
    function Crud(parent) {
        this.parent = parent;
        this.crudObj = { sourceEvent: null, targetEvent: null, isCrudAction: false };
    }
    Crud.prototype.getQuery = function () {
        var start = this.parent.activeView.startDate();
        var end = this.parent.activeView.endDate();
        return this.parent.dataModule.generateQuery(start, end);
    };
    Crud.prototype.getTable = function () {
        if (this.parent.eventSettings.query) {
            var query = this.parent.eventSettings.query.clone();
            return query.fromTable;
        }
        return null;
    };
    Crud.prototype.refreshDataManager = function () {
        var _this = this;
        if (!this.parent.activeView) {
            return;
        }
        var start = this.parent.activeView.startDate();
        var end = this.parent.activeView.endDate();
        var dataManager = this.parent.dataModule.getData(this.parent.dataModule.generateQuery(start, end));
        dataManager.then(function (e) { return _this.dataManagerSuccess(e); }).catch(function (e) { return _this.dataManagerFailure(e); });
    };
    Crud.prototype.dataManagerSuccess = function (e) {
        var _this = this;
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent.trigger(dataBinding, e, function (args) {
            var resultData = extend([], args.result, null, true);
            _this.parent.eventsData = resultData.filter(function (data) { return !data[_this.parent.eventFields.isBlock]; });
            _this.parent.blockData = resultData.filter(function (data) { return data[_this.parent.eventFields.isBlock]; });
            _this.refreshProcessedData();
            if (_this.parent.dragAndDropModule && _this.parent.dragAndDropModule.actionObj.action === 'drag') {
                _this.parent.dragAndDropModule.navigationWrapper();
            }
            _this.parent.trigger(dataBound, null, function () {
                _this.parent.hideSpinner();
                if (_this.parent.isPrinting) {
                    _this.parent.notify(print$1, {});
                }
            });
        });
    };
    Crud.prototype.dataManagerFailure = function (e) {
        var _this = this;
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent.trigger(actionFailure, { error: e }, function () { return _this.parent.hideSpinner(); });
    };
    Crud.prototype.refreshProcessedData = function (isVirtualScrollAction, dynamicEvents) {
        if (isVirtualScrollAction === void 0) { isVirtualScrollAction = false; }
        if (this.parent.dragAndDropModule) {
            this.parent.dragAndDropModule.actionObj.action = '';
            removeClass([this.parent.element], 'e-event-action');
        }
        if (this.parent.activeViewOptions && this.parent.activeViewOptions.eventTemplate) {
            var templateNames = ['eventTemplate'];
            if (this.crudObj.isCrudAction &&
                ['Agenda', 'MonthAgenda', 'Year', 'TimelineYear'].indexOf(this.parent.currentView) === -1) {
                templateNames = [];
                for (var i = 0, len = this.crudObj.sourceEvent.length; i < len; i++) {
                    templateNames.push('eventTemplate_' + this.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex);
                    if (this.crudObj.targetEvent[parseInt(i.toString(), 10)] && this.crudObj.sourceEvent[parseInt(i.toString(), 10)].groupIndex !==
                        this.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex) {
                        templateNames.push('eventTemplate_' + this.crudObj.targetEvent[parseInt(i.toString(), 10)].groupIndex);
                    }
                }
            }
            if (templateNames.length > 0) {
                this.parent.resetTemplates(templateNames);
            }
        }
        if (isVirtualScrollAction) {
            this.parent.notify(dataReady, { processedData: dynamicEvents ? this.parent.eventBase.processData(dynamicEvents) : this.parent.eventsProcessed });
            return;
        }
        var eventsData = this.parent.eventsData || [];
        var blockData = this.parent.blockData || [];
        var data = eventsData.concat(blockData);
        this.parent.notify(dataReady, { processedData: this.parent.eventBase ? this.parent.eventBase.processData(data) : [] });
    };
    Crud.prototype.refreshData = function (args) {
        var _this = this;
        var actionArgs = {
            requestType: args.requestType, cancel: false, data: args.data,
            addedRecords: args.editParams.addedRecords, changedRecords: args.editParams.changedRecords,
            deletedRecords: args.editParams.deletedRecords
        };
        if (this.parent.dragAndDropModule && this.parent.dragAndDropModule.actionObj && this.parent.dragAndDropModule.actionObj.element) {
            this.parent.dragAndDropModule.actionObj.element.style.display = 'none';
        }
        if (this.parent.resizeModule && this.parent.resizeModule.actionObj && this.parent.resizeModule.actionObj.element) {
            this.parent.resizeModule.actionObj.element.style.display = 'none';
        }
        if (this.parent.isSpecificResourceEvents()) {
            if (args.requestType === 'eventCreated' || args.requestType === 'eventRemoved') {
                this.crudObj.isCrudAction = true;
                this.crudObj.sourceEvent = [];
                var crudData = args.data instanceof Array ? (args.data.length === 0 &&
                    args.requestType === 'eventRemoved' ? args.editParams.deletedRecords : args.data) :
                    ((typeof args.data === 'string' || typeof args.data === 'number') && args.requestType === 'eventRemoved') ?
                        args.editParams.deletedRecords : [args.data];
                var _loop_1 = function (data) {
                    this_1.crudObj.isCrudAction = !(args.requestType === 'eventRemoved' && !isNullOrUndefined(data.parent));
                    var groupIndex = this_1.parent.eventBase.getGroupIndexFromEvent(data);
                    if (groupIndex > -1 && this_1.parent.crudModule.crudObj.sourceEvent.filter(function (tdData) { return tdData.groupIndex === groupIndex; }).length === 0
                        && this_1.crudObj.isCrudAction) {
                        this_1.crudObj.sourceEvent.push(this_1.parent.resourceBase.lastResourceLevel[parseInt(groupIndex.toString(), 10)]);
                    }
                };
                var this_1 = this;
                for (var _i = 0, crudData_1 = crudData; _i < crudData_1.length; _i++) {
                    var data = crudData_1[_i];
                    _loop_1(data);
                }
                this.crudObj.targetEvent = this.crudObj.sourceEvent;
            }
        }
        if (this.parent.dataModule.dataManager.dataSource.offline) {
            this.parent.trigger(actionComplete, actionArgs, function (offlineArgs) {
                if (!offlineArgs.cancel) {
                    _this.refreshDataManager();
                }
            });
        }
        else {
            args.promise.then(function () {
                if (!_this.parent || _this.parent && _this.parent.isDestroyed) {
                    return;
                }
                _this.parent.trigger(actionComplete, actionArgs, function (onlineArgs) {
                    if (!onlineArgs.cancel) {
                        _this.refreshDataManager();
                    }
                });
            }).catch(function (e) {
                if (!_this.parent || _this.parent && _this.parent.isDestroyed) {
                    return;
                }
                _this.parent.trigger(actionFailure, { error: e });
            });
        }
    };
    Crud.prototype.addEvent = function (eventData) {
        var _this = this;
        if (this.parent.eventSettings.allowAdding && !this.parent.activeViewOptions.readonly) {
            if (!this.isBlockEvent(eventData) && this.parent.eventBase.isBlockRange(eventData)) {
                this.parent.quickPopup.openValidationError('blockAlert', eventData);
                return;
            }
            var addEvents = (eventData instanceof Array) ? eventData : [eventData];
            if (addEvents.length === 0) {
                return;
            }
            var args = {
                requestType: 'eventCreate', cancel: false, data: addEvents,
                addedRecords: addEvents, changedRecords: [], deletedRecords: []
            };
            this.parent.trigger(actionBegin, args, function (addArgs) {
                if (!addArgs.cancel) {
                    var fields = _this.parent.eventFields;
                    var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                    var promise = void 0;
                    if (addArgs.addedRecords instanceof Array) {
                        for (var _i = 0, _a = addArgs.addedRecords; _i < _a.length; _i++) {
                            var event_1 = _a[_i];
                            event_1 = _this.parent.eventBase.updateEventDateTime(event_1);
                            var eventData_1 = extend({}, _this.parent.eventBase.processTimezone(event_1, true), null, true);
                            editParams.addedRecords.push(eventData_1);
                        }
                        promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields.id, _this.getTable(), _this.getQuery());
                    }
                    else {
                        var event_2 = _this.parent.eventBase.processTimezone(addArgs.addedRecords, true);
                        editParams.addedRecords.push(event_2);
                        promise = _this.parent.dataModule.dataManager.insert(event_2, _this.getTable(), _this.getQuery());
                    }
                    var crudArgs = {
                        requestType: 'eventCreated', cancel: false, data: addArgs.addedRecords, promise: promise, editParams: editParams
                    };
                    _this.refreshData(crudArgs);
                }
            });
        }
    };
    Crud.prototype.saveEvent = function (eventData, action) {
        var _this = this;
        if (this.parent.eventSettings.allowEditing && !this.parent.activeViewOptions.readonly) {
            if (this.parent.currentAction !== 'EditFollowingEvents' && !this.isBlockEvent(eventData)
                && this.parent.eventBase.isBlockRange(eventData)) {
                this.parent.quickPopup.openValidationError('blockAlert', eventData);
                this.parent.crudModule.crudObj.isCrudAction = false;
                return;
            }
            var updateEvents = (eventData instanceof Array) ? eventData : [eventData];
            if (updateEvents.length === 0) {
                return;
            }
            this.parent.currentAction = action;
            if (action) {
                switch (action) {
                    case 'Save':
                        this.processSave(eventData);
                        break;
                    case 'EditOccurrence':
                        this.processOccurrences(eventData, action);
                        break;
                    case 'EditFollowingEvents':
                        this.processFollowSeries(eventData, action);
                        break;
                    case 'EditSeries':
                        this.processEntireSeries(eventData, action);
                        break;
                }
            }
            else {
                var args = {
                    requestType: 'eventChange', cancel: false, data: eventData,
                    addedRecords: [], changedRecords: updateEvents, deletedRecords: []
                };
                this.parent.trigger(actionBegin, args, function (saveArgs) {
                    if (!saveArgs.cancel) {
                        var promise = void 0;
                        var fields = _this.parent.eventFields;
                        var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                        if (saveArgs.changedRecords instanceof Array) {
                            for (var _i = 0, _a = saveArgs.changedRecords; _i < _a.length; _i++) {
                                var event_3 = _a[_i];
                                event_3 = _this.parent.eventBase.updateEventDateTime(event_3);
                                var eventData_2 = extend({}, _this.parent.eventBase.processTimezone(event_3, true), null, true);
                                editParams.changedRecords.push(eventData_2);
                            }
                            promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields.id, _this.getTable(), _this.getQuery());
                        }
                        else {
                            var event_4 = _this.parent.eventBase.processTimezone(saveArgs.changedRecords, true);
                            editParams.changedRecords.push(event_4);
                            promise = _this.parent.dataModule.dataManager.update(fields.id, event_4, _this.getTable(), _this.getQuery());
                        }
                        var cloneEvent = extend({}, saveArgs.changedRecords[saveArgs.changedRecords.length - 1], null, true);
                        _this.parent.eventBase.selectWorkCellByTime([_this.parent.eventBase.processTimezone(cloneEvent)]);
                        var crudArgs = {
                            requestType: 'eventChanged', cancel: false,
                            data: saveArgs.changedRecords, promise: promise, editParams: editParams
                        };
                        _this.refreshData(crudArgs);
                    }
                });
            }
        }
    };
    Crud.prototype.deleteEvent = function (eventData, action) {
        var _this = this;
        if (this.parent.eventSettings.allowDeleting && !this.parent.activeViewOptions.readonly) {
            this.parent.currentAction = action;
            var deleteEvents = [];
            if (typeof eventData === 'string' || typeof eventData === 'number') {
                deleteEvents = this.parent.eventsData.filter(function (eventObj) {
                    return eventObj[_this.parent.eventFields.id] === eventData;
                });
            }
            else {
                deleteEvents = (eventData instanceof Array ? eventData : [eventData]);
            }
            if (deleteEvents.length === 0) {
                return;
            }
            if (action) {
                switch (action) {
                    case 'Delete':
                        this.processDelete(deleteEvents);
                        break;
                    case 'DeleteOccurrence':
                        this.processOccurrences(deleteEvents, action);
                        break;
                    case 'DeleteFollowingEvents':
                        this.processFollowSeries(deleteEvents, action);
                        break;
                    case 'DeleteSeries':
                        this.processEntireSeries(deleteEvents, action);
                        break;
                }
            }
            else {
                var args = {
                    requestType: 'eventRemove', cancel: false, data: eventData,
                    addedRecords: [], changedRecords: [], deletedRecords: deleteEvents
                };
                this.parent.trigger(actionBegin, args, function (deleteArgs) {
                    if (!deleteArgs.cancel) {
                        var promise = void 0;
                        var fields = _this.parent.eventFields;
                        var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                        if (deleteArgs.deletedRecords.length > 1) {
                            editParams.deletedRecords = editParams.deletedRecords.concat(deleteArgs.deletedRecords);
                            promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields.id, _this.getTable(), _this.getQuery());
                        }
                        else {
                            editParams.deletedRecords.push(deleteArgs.deletedRecords[0]);
                            promise = _this.parent.dataModule.dataManager.remove(fields.id, deleteArgs.deletedRecords[0], _this.getTable(), _this.getQuery());
                        }
                        _this.parent.eventBase.selectWorkCellByTime(deleteArgs.deletedRecords);
                        var crudArgs = {
                            requestType: 'eventRemoved', cancel: false,
                            data: deleteArgs.deletedRecords, promise: promise, editParams: editParams
                        };
                        _this.refreshData(crudArgs);
                    }
                });
            }
        }
    };
    Crud.prototype.processOccurrences = function (eventData, action) {
        var _this = this;
        var occurrenceData = [];
        var isDeletedRecords = false;
        if (eventData instanceof Array) {
            for (var _i = 0, eventData_3 = eventData; _i < eventData_3.length; _i++) {
                var event_5 = eventData_3[_i];
                occurrenceData.push({ occurrence: event_5, parent: this.getParentEvent(event_5) });
            }
        }
        else {
            occurrenceData = { occurrence: eventData, parent: this.getParentEvent(eventData) };
        }
        var updateEvents = (eventData instanceof Array) ? eventData : [eventData];
        var args = {
            requestType: action === 'EditOccurrence' ? 'eventChange' : 'eventRemove',
            cancel: false,
            addedRecords: [], changedRecords: updateEvents, deletedRecords: []
        };
        args.data = occurrenceData;
        this.parent.trigger(actionBegin, args, function (occurrenceArgs) {
            if (!occurrenceArgs.cancel) {
                var fields = _this.parent.eventFields;
                var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                var occurrenceEvents = (occurrenceData instanceof Array ? occurrenceData : [occurrenceData]);
                var _loop_2 = function (a, count) {
                    var childEvent = occurrenceArgs.changedRecords[parseInt(a.toString(), 10)];
                    var parentEvent = occurrenceEvents[parseInt(a.toString(), 10)].parent;
                    var parentException = parentEvent[fields.recurrenceException];
                    var editedData = void 0;
                    var exceptionDate = void 0;
                    switch (action) {
                        case 'EditOccurrence':
                            editedData = _this.parent.eventsProcessed.filter(function (event) { return event.Guid === childEvent.Guid; })[0];
                            exceptionDate = _this.excludeDateCheck(editedData[fields.startTime], parentException);
                            if (exceptionDate !== parentEvent[fields.recurrenceException]) {
                                parentEvent[fields.recurrenceException] = exceptionDate;
                                childEvent[fields.recurrenceException] = getRecurrenceStringFromDate(editedData[fields.startTime]);
                                childEvent[fields.recurrenceID] = parentEvent[fields.id];
                                childEvent[fields.followingID] = null;
                                editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEvent, true));
                                editParams.addedRecords.push(_this.parent.eventBase.processTimezone(childEvent, true));
                            }
                            else {
                                editParams.changedRecords.push(_this.parent.eventBase.processTimezone(childEvent, true));
                            }
                            break;
                        case 'DeleteOccurrence':
                            if (!childEvent[fields.recurrenceException]) {
                                parentEvent[fields.recurrenceException] =
                                    _this.excludeDateCheck(childEvent[fields.startTime], parentException);
                                editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEvent, true));
                            }
                            if (childEvent[fields.id] !== parentEvent[fields.id]) {
                                editParams.deletedRecords.push(childEvent);
                                isDeletedRecords = true;
                            }
                            break;
                    }
                };
                for (var a = 0, count = occurrenceArgs.changedRecords.length; a < count; a++) {
                    _loop_2(a, count);
                }
                var promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields.id, _this.getTable(), _this.getQuery());
                var cloneEvent = extend({}, occurrenceArgs.changedRecords[occurrenceArgs.changedRecords.length - 1], null, true);
                _this.parent.eventBase.selectWorkCellByTime(action === 'EditOccurrence' ? [_this.parent.eventBase.processTimezone(cloneEvent)] : [cloneEvent]);
                var crudArgs = {
                    requestType: action === 'EditOccurrence' ? 'eventChanged' : 'eventRemoved',
                    cancel: false, data: isDeletedRecords ? occurrenceArgs.deletedRecords : occurrenceArgs.changedRecords,
                    promise: promise, editParams: editParams
                };
                _this.refreshData(crudArgs);
            }
        });
    };
    Crud.prototype.processFollowSeries = function (eventData, action) {
        var _this = this;
        var followData = [];
        if (eventData instanceof Array) {
            for (var _i = 0, eventData_4 = eventData; _i < eventData_4.length; _i++) {
                var event_6 = eventData_4[_i];
                followData.push({ occurrence: event_6, parent: this.getParentEvent(event_6) });
            }
        }
        else {
            followData = { occurrence: eventData, parent: this.getParentEvent(eventData) };
        }
        var updateFollowEvents = (eventData instanceof Array) ? eventData : [eventData];
        var args = {
            requestType: action === 'EditFollowingEvents' ? 'eventChange' : 'eventRemove', cancel: false,
            addedRecords: [], changedRecords: updateFollowEvents, deletedRecords: []
        };
        args.data = followData;
        this.parent.trigger(actionBegin, args, function (followArgs) {
            if (!followArgs.cancel) {
                var fields_1 = _this.parent.eventFields;
                var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                var followEvents = followData instanceof Array ? followData : [followData];
                var _loop_3 = function (a, count) {
                    var childEvent = followArgs.changedRecords[parseInt(a.toString(), 10)];
                    var parentEvent = followEvents[parseInt(a.toString(), 10)].parent;
                    var followData_1 = _this.parent.eventBase.getEventCollections(parentEvent, childEvent);
                    var isSpanned = void 0;
                    switch (action) {
                        case 'EditFollowingEvents':
                            _this.processRecurrenceRule(parentEvent, childEvent);
                            isSpanned = !_this.parent.eventBase.isFollowingEvent(parentEvent, childEvent);
                            childEvent[fields_1.followingID] = isSpanned ? null : parentEvent[fields_1.id];
                            childEvent[fields_1.recurrenceID] = null;
                            editParams.addedRecords.push(_this.parent.eventBase.processTimezone(childEvent, true));
                            editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEvent, true));
                            if (!_this.parent.uiStateValues.isIgnoreOccurrence) {
                                childEvent[fields_1.recurrenceException] = null;
                                if (followData_1.occurrence.length > 0) {
                                    var rule = followData_1.occurrence.slice(-1)[0][fields_1.recurrenceRule];
                                    if (rule.indexOf('COUNT') === -1) {
                                        childEvent[fields_1.recurrenceRule] = rule;
                                    }
                                }
                                if (followData_1.follow.length > 0) {
                                    childEvent[fields_1.recurrenceRule] = followData_1.follow.slice(-1)[0][fields_1.recurrenceRule];
                                    editParams.deletedRecords = editParams.deletedRecords.concat(followData_1.follow);
                                }
                                if (isSpanned) {
                                    followData_1.occurrence = followData_1.occurrence.filter(function (eventObj) {
                                        return eventObj[fields_1.recurrenceID] === parentEvent[fields_1.id];
                                    });
                                }
                                editParams.deletedRecords = editParams.deletedRecords.concat(followData_1.occurrence);
                            }
                            break;
                        case 'DeleteFollowingEvents':
                            _this.processRecurrenceRule(parentEvent, childEvent[fields_1.startTime]);
                            editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEvent, true));
                            editParams.deletedRecords = editParams.deletedRecords.concat(followData_1.occurrence).concat(followData_1.follow);
                            break;
                    }
                };
                for (var a = 0, count = followArgs.changedRecords.length; a < count; a++) {
                    _loop_3(a, count);
                }
                var promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields_1.id, _this.getTable(), _this.getQuery());
                var cloneEvent = extend({}, followArgs.changedRecords[followArgs.changedRecords.length - 1], null, true);
                _this.parent.eventBase.selectWorkCellByTime(action === 'EditFollowingEvents' ? [_this.parent.eventBase.processTimezone(cloneEvent)] : [cloneEvent]);
                var crudArgs = {
                    requestType: action === 'EditFollowingEvents' ? 'eventChanged' : 'eventRemoved',
                    cancel: false, data: followArgs.changedRecords, promise: promise, editParams: editParams
                };
                _this.refreshData(crudArgs);
            }
        });
    };
    Crud.prototype.processEntireSeries = function (eventData, action) {
        var _this = this;
        var seriesData = [];
        var isDeletedRecords = false;
        if (eventData instanceof Array) {
            for (var _i = 0, eventData_5 = eventData; _i < eventData_5.length; _i++) {
                var event_7 = eventData_5[_i];
                seriesData.push(this.getParentEvent(event_7, true));
            }
        }
        else {
            seriesData = this.getParentEvent(eventData, true);
        }
        var updateSeriesEvents = (eventData instanceof Array) ? eventData : [eventData];
        var args = {
            requestType: action === 'EditSeries' ? 'eventChange' : 'eventRemove', cancel: false,
            addedRecords: [], changedRecords: updateSeriesEvents, deletedRecords: []
        };
        args.data = seriesData;
        this.parent.trigger(actionBegin, args, function (seriesArgs) {
            if (!seriesArgs.cancel) {
                var fields_2 = _this.parent.eventFields;
                var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                var seriesEvents = seriesData instanceof Array ? seriesData : [seriesData];
                var _loop_4 = function (a, count) {
                    var childEvent = seriesArgs.changedRecords[parseInt(a.toString(), 10)];
                    var parentEvent = seriesEvents[parseInt(a.toString(), 10)];
                    var eventCollections = _this.parent.eventBase.getEventCollections(parentEvent);
                    var deletedEvents = eventCollections.follow.concat(eventCollections.occurrence);
                    switch (action) {
                        case 'EditSeries':
                            childEvent[fields_2.id] = parentEvent[fields_2.id];
                            childEvent[fields_2.recurrenceID] = null;
                            childEvent[fields_2.followingID] = null;
                            if (_this.parent.uiStateValues.isIgnoreOccurrence && childEvent[fields_2.recurrenceException]) {
                                var originalParent = _this.parent.eventsData.filter(function (eventObj) {
                                    return eventObj[fields_2.id] === childEvent[fields_2.id];
                                });
                                if (originalParent.length > 0) {
                                    childEvent[fields_2.recurrenceRule] = originalParent[0][fields_2.recurrenceRule];
                                }
                            }
                            else {
                                childEvent[fields_2.recurrenceException] = null;
                                editParams.deletedRecords = editParams.deletedRecords.concat(deletedEvents);
                            }
                            editParams.changedRecords.push(_this.parent.eventBase.processTimezone(childEvent, true));
                            _this.parent.uiStateValues.isIgnoreOccurrence = false;
                            break;
                        case 'DeleteSeries':
                            editParams.deletedRecords = editParams.deletedRecords.concat(deletedEvents.concat(parentEvent));
                            isDeletedRecords = true;
                            break;
                    }
                };
                for (var a = 0, count = seriesArgs.changedRecords.length; a < count; a++) {
                    _loop_4(a, count);
                }
                var promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields_2.id, _this.getTable(), _this.getQuery());
                var cloneEvent = extend({}, seriesArgs.changedRecords[seriesArgs.changedRecords.length - 1], null, true);
                _this.parent.eventBase.selectWorkCellByTime(action === 'EditSeries' ? [_this.parent.eventBase.processTimezone(cloneEvent)] : [cloneEvent]);
                var crudArgs = {
                    requestType: action === 'EditSeries' ? 'eventChanged' : 'eventRemoved',
                    cancel: false, data: isDeletedRecords ? seriesArgs.deletedRecords : seriesArgs.changedRecords,
                    promise: promise, editParams: editParams
                };
                _this.refreshData(crudArgs);
            }
        });
    };
    Crud.prototype.processDelete = function (eventData) {
        var _this = this;
        var deleteData = [];
        for (var _i = 0, eventData_6 = eventData; _i < eventData_6.length; _i++) {
            var eventObj = eventData_6[_i];
            if (eventObj[this.parent.eventFields.recurrenceRule]) {
                deleteData.push({ occurrence: eventObj, parent: this.getParentEvent(eventObj) });
            }
            else {
                deleteData.push(eventObj);
            }
        }
        var args = {
            requestType: 'eventRemove', cancel: false,
            addedRecords: [], changedRecords: [], deletedRecords: eventData
        };
        args.data = deleteData;
        this.parent.trigger(actionBegin, args, function (deleteArgs) {
            if (!deleteArgs.cancel) {
                var fields_3 = _this.parent.eventFields;
                var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                var _loop_5 = function (a, count) {
                    var isDelete = isNullOrUndefined(deleteArgs.deletedRecords[parseInt(a.toString(), 10)][_this.parent.eventFields.recurrenceRule]);
                    if (!isDelete) {
                        var parentEvent_1 = deleteData[parseInt(a.toString(), 10)].parent;
                        var isEdited = editParams.changedRecords.filter(function (obj) {
                            return obj[fields_3.id] === parentEvent_1[fields_3.id];
                        });
                        var editedDate = deleteArgs.deletedRecords[parseInt(a.toString(), 10)][fields_3.startTime];
                        if (isEdited.length > 0) {
                            var editedData = isEdited[0];
                            editedData[fields_3.recurrenceException] =
                                _this.excludeDateCheck(editedDate, editedData[fields_3.recurrenceException]);
                        }
                        else {
                            parentEvent_1[fields_3.recurrenceException] =
                                _this.excludeDateCheck(editedDate, parentEvent_1[fields_3.recurrenceException]);
                        }
                        if (isEdited.length === 0) {
                            editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEvent_1, true));
                        }
                        isDelete = deleteArgs.deletedRecords[parseInt(a.toString(), 10)][fields_3.id] !== parentEvent_1[fields_3.id];
                    }
                    if (isDelete) {
                        editParams.deletedRecords.push(deleteArgs.deletedRecords[parseInt(a.toString(), 10)]);
                    }
                };
                for (var a = 0, count = deleteArgs.deletedRecords.length; a < count; a++) {
                    _loop_5(a, count);
                }
                var promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields_3.id, _this.getTable(), _this.getQuery());
                _this.parent.eventBase.selectWorkCellByTime(deleteArgs.deletedRecords);
                var crudArgs = {
                    requestType: 'eventRemoved', cancel: false, data: deleteArgs.deletedRecords, promise: promise, editParams: editParams
                };
                _this.refreshData(crudArgs);
            }
        });
    };
    Crud.prototype.processSave = function (data) {
        var _this = this;
        var eventData = (data instanceof Array) ? data : [data];
        var editData = [];
        for (var _i = 0, eventData_7 = eventData; _i < eventData_7.length; _i++) {
            var eventObj = eventData_7[_i];
            if (eventObj[this.parent.eventFields.recurrenceRule]) {
                editData.push({ occurrence: eventObj, parent: this.getParentEvent(eventObj) });
            }
            else {
                editData.push(eventObj);
            }
        }
        var args = { requestType: 'eventChange', cancel: false, addedRecords: [], changedRecords: eventData, deletedRecords: [] };
        args.data = editData;
        this.parent.trigger(actionBegin, args, function (editArgs) {
            if (!editArgs.cancel) {
                var fields = _this.parent.eventFields;
                var editParams = { addedRecords: [], changedRecords: [], deletedRecords: [] };
                for (var _i = 0, _a = editArgs.changedRecords; _i < _a.length; _i++) {
                    var record = _a[_i];
                    if (!isNullOrUndefined(record[fields.recurrenceRule]) && isNullOrUndefined(record[fields.recurrenceException])) {
                        var exceptionString = getRecurrenceStringFromDate(record[fields.startTime]);
                        var parentEle = _this.getParentEvent(record);
                        parentEle[fields.recurrenceException] = isNullOrUndefined(parentEle[fields.recurrenceException]) ?
                            exceptionString : parentEle[fields.recurrenceException].concat(',' + exceptionString);
                        record[fields.id] = _this.parent.getEventMaxID();
                        record[fields.recurrenceException] = exceptionString;
                        editParams.addedRecords.push(_this.parent.eventBase.processTimezone(record, true));
                        editParams.changedRecords.push(_this.parent.eventBase.processTimezone(parentEle, true));
                    }
                    else {
                        editParams.changedRecords.push(_this.parent.eventBase.processTimezone(record, true));
                    }
                }
                var promise = _this.parent.dataModule.dataManager.saveChanges(editParams, fields.id, _this.getTable(), _this.getQuery());
                var cloneEvent = extend({}, editArgs.changedRecords[editArgs.changedRecords.length - 1], null, true);
                _this.parent.eventBase.selectWorkCellByTime([_this.parent.eventBase.processTimezone(cloneEvent)]);
                var crudArgs = { requestType: 'eventChanged', cancel: false, data: editArgs.changedRecords, promise: promise, editParams: editParams };
                _this.refreshData(crudArgs);
            }
        });
    };
    Crud.prototype.getParentEvent = function (event, isParent) {
        if (isParent === void 0) { isParent = false; }
        var parentEvent = this.parent.eventBase.getParentEvent(event, isParent) || event;
        if (parentEvent[this.parent.eventFields.startTimezone] || parentEvent[this.parent.eventFields.endTimezone]) {
            this.parent.eventBase.timezoneConvert(parentEvent);
        }
        return parentEvent;
    };
    Crud.prototype.excludeDateCheck = function (eventStartTime, exceptionDateList) {
        var timezone = this.parent.timezone || this.parent.tzModule.getLocalTimezoneName();
        if (timezone) {
            eventStartTime = this.parent.tzModule.remove(new Date(+eventStartTime.getTime()), timezone);
        }
        var exDate = getRecurrenceStringFromDate(eventStartTime);
        if (!isNullOrUndefined(exceptionDateList)) {
            if (exceptionDateList.indexOf(exDate) === -1) {
                exceptionDateList = !(isNullOrUndefined(exceptionDateList)) ? exceptionDateList + ',' + exDate : exDate;
            }
        }
        else {
            exceptionDateList = exDate;
        }
        return exceptionDateList;
    };
    Crud.prototype.processRecurrenceRule = function (parentEvent, followEvent) {
        var fields = this.parent.eventFields;
        var recurrenceRule = parentEvent[fields.recurrenceRule];
        var endDate;
        if (followEvent instanceof Date) {
            endDate = new Date(+followEvent);
        }
        else {
            endDate = new Date(+followEvent[fields.startTime]);
            var newRecurrenceRule = followEvent[fields.recurrenceRule];
            if (newRecurrenceRule) {
                var startDate = parentEvent[fields.startTime];
                var ruleException = (this.parent.currentAction === 'DeleteFollowingEvents') ? followEvent[fields.recurrenceException] : null;
                var dateCollection = generate(startDate, newRecurrenceRule, ruleException, this.parent.activeViewOptions.firstDayOfWeek);
                var untilDate = new Date(dateCollection.slice(-1)[0]);
                untilDate.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
                endDate.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
                followEvent[fields.recurrenceRule] = this.getUpdatedRecurrenceRule(newRecurrenceRule, new Date(+untilDate), false);
            }
        }
        parentEvent[fields.recurrenceRule] = this.getUpdatedRecurrenceRule(recurrenceRule, addDays(new Date(endDate.getTime()), -1), true);
    };
    Crud.prototype.getUpdatedRecurrenceRule = function (recurrenceRule, untilDate, isParent) {
        var splitRule = recurrenceRule.split(';');
        var updatedRule = '';
        for (var _i = 0, splitRule_1 = splitRule; _i < splitRule_1.length; _i++) {
            var rule = splitRule_1[_i];
            if (rule !== '') {
                var ruleKey = rule.split('=')[0];
                var ruleValue = rule.split('=')[1];
                if (ruleKey === 'COUNT' || ruleKey === 'UNTIL') {
                    ruleValue = getRecurrenceStringFromDate(untilDate);
                    rule = rule.replace(rule, 'UNTIL=' + ruleValue);
                }
                updatedRule += rule + ';';
            }
        }
        if (isParent && updatedRule.indexOf('UNTIL') === -1) {
            updatedRule += 'UNTIL=' + getRecurrenceStringFromDate(untilDate);
        }
        return updatedRule;
    };
    Crud.prototype.isBlockEvent = function (eventData) {
        var eventCollection = (eventData instanceof Array) ? eventData : [eventData];
        var value = false;
        for (var _i = 0, eventCollection_1 = eventCollection; _i < eventCollection_1.length; _i++) {
            var event_8 = eventCollection_1[_i];
            value = event_8[this.parent.eventFields.isBlock] || false;
        }
        return value;
    };
    /**
     * To destroy the crud module.
     *
     * @returns {void}
     * @private
     */
    Crud.prototype.destroy = function () {
        this.crudObj = null;
        this.parent = null;
    };
    return Crud;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Work cell interactions
 */
var WorkCellInteraction = /** @__PURE__ @class */ (function () {
    function WorkCellInteraction(parent) {
        this.parent = parent;
        EventHandler.add(this.parent.element, 'mouseover', this.onHover, this);
    }
    WorkCellInteraction.prototype.cellMouseDown = function (e) {
        if (this.isPreventAction(e)) {
            return;
        }
        this.parent.notify(cellMouseDown, { event: e });
    };
    WorkCellInteraction.prototype.cellClick = function (e) {
        var _this = this;
        if (this.isPreventAction(e)) {
            return;
        }
        var queryStr = '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS + ',.' + HEADER_CELLS_CLASS;
        var target = closest(e.target, queryStr);
        if (isNullOrUndefined(target)) {
            return;
        }
        if (!isNullOrUndefined(closest(e.target, '.' + NEW_EVENT_CLASS))) {
            this.parent.eventWindow.openEditor(this.parent.activeCellsData, 'Add');
            return;
        }
        var navigateEle = closest(e.target, '.' + NAVIGATE_CLASS);
        var navigateView = this.parent.getNavigateView();
        var sameView = this.parent.currentView === navigateView;
        if (isNullOrUndefined(navigateEle) || sameView ||
            isNullOrUndefined(this.parent.viewOptions[navigateView.charAt(0).toLowerCase() + navigateView.slice(1)])) {
            if (this.parent.activeViewOptions.readonly && this.parent.currentView !== 'MonthAgenda') {
                if (this.parent.quickPopup) {
                    this.parent.quickPopup.quickPopupHide();
                }
                return;
            }
            if (this.parent.isAdaptive && (e.target.classList.contains(MORE_INDICATOR_CLASS) ||
                closest(e.target, '.' + MORE_INDICATOR_CLASS))) {
                return;
            }
            var isWorkCell_1 = target.classList.contains(WORK_CELLS_CLASS) ||
                target.classList.contains(ALLDAY_CELLS_CLASS);
            if (isWorkCell_1 && e.shiftKey && e.which === 1 && this.parent.keyboardInteractionModule) {
                this.parent.keyboardInteractionModule.onMouseSelection(e);
                return;
            }
            this.parent.activeCellsData = this.parent.getCellDetails(target);
            var args = extend(this.parent.activeCellsData, { cancel: false, event: e, name: 'cellClick' });
            this.parent.trigger(cellClick, args, function (clickArgs) {
                if (!clickArgs.cancel) {
                    if (isWorkCell_1) {
                        _this.parent.selectCell(target);
                    }
                    if (_this.parent.allowInline) {
                        var inlineArgs = {
                            element: clickArgs.element,
                            groupIndex: clickArgs.groupIndex, type: 'Cell'
                        };
                        _this.parent.notify(inlineClick, inlineArgs);
                    }
                    else {
                        _this.parent.notify(cellClick, clickArgs);
                    }
                }
                else {
                    if (_this.parent.quickPopup) {
                        _this.parent.quickPopup.quickPopupHide();
                    }
                }
            });
        }
        else {
            var date = this.parent.getDateFromElement(target);
            if (!isNullOrUndefined(date) && this.parent.isMinMaxDate(date)) {
                this.parent.setProperties({ selectedDate: date }, true);
                this.parent.changeView(this.parent.getNavigateView(), e);
            }
        }
    };
    WorkCellInteraction.prototype.cellDblClick = function (e) {
        var _this = this;
        var queryStr = '.' + WORK_CELLS_CLASS + ',.' + ALLDAY_CELLS_CLASS + ',.' + HEADER_CELLS_CLASS;
        var target = closest(e.target, queryStr);
        if (this.parent.activeViewOptions.readonly || this.isPreventAction(e) || isNullOrUndefined(target)) {
            return;
        }
        var args = extend(this.parent.activeCellsData, { cancel: false, event: e, name: 'cellDoubleClick' });
        this.parent.trigger(cellDoubleClick, args, function (clickArgs) {
            var date = new Date(clickArgs.startTime.getTime());
            if (!_this.parent.isMinMaxDate(new Date(date.setHours(0, 0, 0, 0)))) {
                return;
            }
            if (!clickArgs.cancel) {
                _this.parent.eventWindow.openEditor(_this.parent.activeCellsData, 'Add');
            }
        });
    };
    WorkCellInteraction.prototype.onHover = function (e) {
        var targetSelector = '.' + WORK_CELLS_CLASS + ',.' + TIME_SLOT_CLASS + ',.' + ALLDAY_CELLS_CLASS + ',.' +
            HEADER_CELLS_CLASS + ',.' + RESOURCE_CELLS_CLASS + ',.' + APPOINTMENT_CLASS + ',.' + WEEK_NUMBER_CLASS +
            ',.' + MONTH_HEADER_CLASS;
        var hoverTarget = closest(e.target, targetSelector);
        if (hoverTarget) {
            var hoverArgs = { element: hoverTarget, event: e };
            this.parent.trigger(hover, hoverArgs);
        }
    };
    WorkCellInteraction.prototype.isPreventAction = function (e) {
        if (closest(e.target, '.' + NAVIGATE_CLASS)) {
            return false;
        }
        if (closest(e.target, '.' + APPOINTMENT_WRAPPER_CLASS) &&
            !closest(e.target, '.' + MORE_INDICATOR_CLASS)) {
            return true;
        }
        var target = closest(e.target, '.' + APPOINTMENT_CLASS + ',.' + RESOURCE_GROUP_CELLS_CLASS);
        if (!isNullOrUndefined(target)) {
            return true;
        }
        target = closest(e.target, '.' + HEADER_CELLS_CLASS);
        if (this.parent.activeView.isTimelineView() && !isNullOrUndefined(target)) {
            return true;
        }
        return false;
    };
    WorkCellInteraction.prototype.destroy = function () {
        EventHandler.remove(this.parent.element, 'mouseover', this.onHover);
    };
    return WorkCellInteraction;
}());

var __extends$11 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$8 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Configuration that applies on each appointment field options of scheduler.
 */
var FieldOptions = /** @__PURE__ @class */ (function (_super) {
    __extends$11(FieldOptions, _super);
    function FieldOptions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$8([
        Property()
    ], FieldOptions.prototype, "name", void 0);
    __decorate$8([
        Property()
    ], FieldOptions.prototype, "default", void 0);
    __decorate$8([
        Property()
    ], FieldOptions.prototype, "title", void 0);
    __decorate$8([
        Property({})
    ], FieldOptions.prototype, "validation", void 0);
    return FieldOptions;
}(ChildProperty));

var __extends$10 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$7 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that holds the collection of event fields that requires to be mapped with the dataSource
 * fields along with its available configuration settings. Each field in it accepts both string and object
 *  data type. When each of the field is assigned with simple `string` value, it is assumed that the dataSource field
 *  name is mapped with it. If the `object` type is defined on each fields, then the validation related settings and mapping of
 *  those fields with dataSource can be given altogether within it.
 */
var Field = /** @__PURE__ @class */ (function (_super) {
    __extends$10(Field, _super);
    function Field() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$7([
        Property('Id')
    ], Field.prototype, "id", void 0);
    __decorate$7([
        Property('IsBlock')
    ], Field.prototype, "isBlock", void 0);
    __decorate$7([
        Complex({ name: 'Subject' }, FieldOptions)
    ], Field.prototype, "subject", void 0);
    __decorate$7([
        Complex({ name: 'StartTime' }, FieldOptions)
    ], Field.prototype, "startTime", void 0);
    __decorate$7([
        Complex({ name: 'EndTime' }, FieldOptions)
    ], Field.prototype, "endTime", void 0);
    __decorate$7([
        Complex({ name: 'StartTimezone' }, FieldOptions)
    ], Field.prototype, "startTimezone", void 0);
    __decorate$7([
        Complex({ name: 'EndTimezone' }, FieldOptions)
    ], Field.prototype, "endTimezone", void 0);
    __decorate$7([
        Complex({ name: 'Location' }, FieldOptions)
    ], Field.prototype, "location", void 0);
    __decorate$7([
        Complex({ name: 'Description' }, FieldOptions)
    ], Field.prototype, "description", void 0);
    __decorate$7([
        Complex({ name: 'IsAllDay' }, FieldOptions)
    ], Field.prototype, "isAllDay", void 0);
    __decorate$7([
        Complex({ name: 'RecurrenceID' }, FieldOptions)
    ], Field.prototype, "recurrenceID", void 0);
    __decorate$7([
        Complex({ name: 'RecurrenceRule' }, FieldOptions)
    ], Field.prototype, "recurrenceRule", void 0);
    __decorate$7([
        Complex({ name: 'RecurrenceException' }, FieldOptions)
    ], Field.prototype, "recurrenceException", void 0);
    __decorate$7([
        Property('IsReadonly')
    ], Field.prototype, "isReadonly", void 0);
    __decorate$7([
        Property('FollowingID')
    ], Field.prototype, "followingID", void 0);
    return Field;
}(ChildProperty));

var __extends$9 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$6 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Holds the configuration of event related options and dataSource binding to Schedule.
 */
var EventSettings = /** @__PURE__ @class */ (function (_super) {
    __extends$9(EventSettings, _super);
    function EventSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$6([
        Property()
    ], EventSettings.prototype, "template", void 0);
    __decorate$6([
        Property([])
    ], EventSettings.prototype, "dataSource", void 0);
    __decorate$6([
        Property()
    ], EventSettings.prototype, "query", void 0);
    __decorate$6([
        Complex({}, Field)
    ], EventSettings.prototype, "fields", void 0);
    __decorate$6([
        Property(false)
    ], EventSettings.prototype, "enableTooltip", void 0);
    __decorate$6([
        Property('AllDayRow')
    ], EventSettings.prototype, "spannedEventPlacement", void 0);
    __decorate$6([
        Property(1)
    ], EventSettings.prototype, "minimumEventDuration", void 0);
    __decorate$6([
        Property()
    ], EventSettings.prototype, "tooltipTemplate", void 0);
    __decorate$6([
        Property()
    ], EventSettings.prototype, "resourceColorField", void 0);
    __decorate$6([
        Property(false)
    ], EventSettings.prototype, "editFollowingEvents", void 0);
    __decorate$6([
        Property(true)
    ], EventSettings.prototype, "allowAdding", void 0);
    __decorate$6([
        Property(true)
    ], EventSettings.prototype, "allowEditing", void 0);
    __decorate$6([
        Property(true)
    ], EventSettings.prototype, "allowDeleting", void 0);
    __decorate$6([
        Property(false)
    ], EventSettings.prototype, "enableMaxHeight", void 0);
    __decorate$6([
        Property(false)
    ], EventSettings.prototype, "enableIndicator", void 0);
    __decorate$6([
        Property(false)
    ], EventSettings.prototype, "ignoreWhitespace", void 0);
    __decorate$6([
        Property()
    ], EventSettings.prototype, "sortComparer", void 0);
    __decorate$6([
        Property()
    ], EventSettings.prototype, "includeFiltersInQuery", void 0);
    return EventSettings;
}(ChildProperty));

var __extends$12 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$9 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/**
 * A class that holds the resource grouping related configurations on Schedule.
 */
var Group = /** @__PURE__ @class */ (function (_super) {
    __extends$12(Group, _super);
    function Group() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$9([
        Property(false)
    ], Group.prototype, "byDate", void 0);
    __decorate$9([
        Property(true)
    ], Group.prototype, "byGroupID", void 0);
    __decorate$9([
        Property(false)
    ], Group.prototype, "allowGroupEdit", void 0);
    __decorate$9([
        Property([])
    ], Group.prototype, "resources", void 0);
    __decorate$9([
        Property(true)
    ], Group.prototype, "enableCompactView", void 0);
    __decorate$9([
        Property()
    ], Group.prototype, "headerTooltipTemplate", void 0);
    __decorate$9([
        Property(false)
    ], Group.prototype, "hideNonWorkingDays", void 0);
    return Group;
}(ChildProperty));

var __extends$13 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate$10 = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * A class that represents the resource related configurations and its data binding options.
 */
var Resources = /** @__PURE__ @class */ (function (_super) {
    __extends$13(Resources, _super);
    function Resources() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate$10([
        Property()
    ], Resources.prototype, "field", void 0);
    __decorate$10([
        Property()
    ], Resources.prototype, "title", void 0);
    __decorate$10([
        Property()
    ], Resources.prototype, "name", void 0);
    __decorate$10([
        Property(false)
    ], Resources.prototype, "allowMultiple", void 0);
    __decorate$10([
        Property([])
    ], Resources.prototype, "dataSource", void 0);
    __decorate$10([
        Property()
    ], Resources.prototype, "query", void 0);
    __decorate$10([
        Property('Id')
    ], Resources.prototype, "idField", void 0);
    __decorate$10([
        Property('Text')
    ], Resources.prototype, "textField", void 0);
    __decorate$10([
        Property('Expanded')
    ], Resources.prototype, "expandedField", void 0);
    __decorate$10([
        Property('GroupID')
    ], Resources.prototype, "groupIDField", void 0);
    __decorate$10([
        Property('Color')
    ], Resources.prototype, "colorField", void 0);
    __decorate$10([
        Property('StartHour')
    ], Resources.prototype, "startHourField", void 0);
    __decorate$10([
        Property('EndHour')
    ], Resources.prototype, "endHourField", void 0);
    __decorate$10([
        Property('WorkDays')
    ], Resources.prototype, "workDaysField", void 0);
    __decorate$10([
        Property('CssClass')
    ], Resources.prototype, "cssClassField", void 0);
    return Resources;
}(ChildProperty));

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
var ResourceBase = /** @__PURE__ @class */ (function () {
    function ResourceBase(parent) {
        this.resourceCollection = [];
        this.leftPixel = 25;
        this.resourceDateTree = [];
        this.parent = parent;
    }
    ResourceBase.prototype.renderResourceHeaderIndent = function (tr) {
        var resColTd = createElement('td', { className: RESOURCE_LEFT_TD_CLASS });
        var resColDiv = createElement('div', { className: RESOURCE_TEXT_CLASS });
        if (this.parent.activeViewOptions.headerIndentTemplate) {
            var data = { className: [resColTd.className], type: 'emptyCells' };
            this.parent.renderHeaderIndentTemplate(data, resColTd);
        }
        else {
            resColTd.appendChild(resColDiv);
        }
        var args = { elementType: 'emptyCells', element: resColTd };
        this.parent.trigger(renderCell, args);
        tr.appendChild(resColTd);
    };
    ResourceBase.prototype.hideResourceRows = function (tBody) {
        if (this.resourceCollection.length <= 1 || this.parent.virtualScrollModule) {
            return;
        }
        var trCount = this.lastResourceLevel.length;
        for (var i = 0; i < trCount; i++) {
            var resData = this.lastResourceLevel[parseInt(i.toString(), 10)].resourceData;
            var res = this.lastResourceLevel[parseInt(i.toString(), 10)].resource;
            if (resData.ClassName === RESOURCE_PARENT_CLASS && !resData[res.expandedField] &&
                !isNullOrUndefined(resData[res.expandedField])) {
                var trCollection = [].slice.call(tBody.children);
                var slicedCollection = trCollection.slice(i + 1, i + (parseInt(resData.Count, 10) + 1));
                addClass(slicedCollection, HIDDEN_CLASS);
            }
        }
    };
    ResourceBase.prototype.createResourceColumn = function () {
        var resColl = this.resourceCollection;
        var resDiv = createElement('div', { className: RESOURCE_COLUMN_WRAP_CLASS });
        var tbl = this.parent.activeView.createTableLayout(RESOURCE_COLUMN_TABLE_CLASS);
        if (!this.parent.uiStateValues.isGroupAdaptive && this.parent.rowAutoHeight && this.parent.activeView.isTimelineView()
            && this.parent.activeViewOptions.group.resources.length > 0) {
            addClass([tbl], AUTO_HEIGHT);
        }
        var tBody = tbl.querySelector('tbody');
        var resData = this.generateTreeData(true);
        this.countCalculation(resColl.slice(0, -2), resColl.slice(0, -1));
        this.renderedResources = this.lastResourceLevel;
        if (this.parent.virtualScrollModule) {
            var resourceCount = this.parent.virtualScrollModule.getRenderedCount();
            this.setExpandedResources();
            resData = this.expandedResources.slice(0, resourceCount);
            this.renderedResources = resData;
        }
        append(this.getContentRows(resData), tBody);
        this.hideResourceRows(tBody);
        tbl.appendChild(tBody);
        resDiv.appendChild(tbl);
        return resDiv;
    };
    ResourceBase.prototype.setRenderedResources = function () {
        var resColl = this.resourceCollection;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var temp = this.generateTreeData(true);
        this.countCalculation(resColl.slice(0, -2), resColl.slice(0, -1));
        this.renderedResources = this.lastResourceLevel;
    };
    ResourceBase.prototype.setExpandedResources = function () {
        var resources = [];
        for (var i = 0; i < this.lastResourceLevel.length; i++) {
            var resource = this.lastResourceLevel[parseInt(i.toString(), 10)].resourceData;
            var count = resource.Count;
            resources.push(this.lastResourceLevel[parseInt(i.toString(), 10)]);
            var isExpanded = resource[this.lastResourceLevel[parseInt(i.toString(), 10)].resource.expandedField];
            if (!isNullOrUndefined(isExpanded) && !isExpanded && count > 0) {
                i = i + count;
            }
        }
        this.expandedResources = resources;
    };
    ResourceBase.prototype.getContentRows = function (resData, isVirtualScroll) {
        var resRows = [];
        var left;
        var rIndex;
        var resColl = this.resourceCollection;
        var tr = createElement('tr');
        var td = createElement('td', { attrs: { tabindex: isVirtualScroll ? '-1' : '0' } });
        for (var i = 0; i < resData.length; i++) {
            var ntd = td.cloneNode();
            rIndex = findIndexInData(resColl, 'name', resData[parseInt(i.toString(), 10)].resource.name);
            if (rIndex === resColl.length - 1) {
                extend(resData[parseInt(i.toString(), 10)].resourceData, { ClassName: RESOURCE_CHILD_CLASS });
                this.renderedResources[parseInt(i.toString(), 10)].className = [RESOURCE_CHILD_CLASS];
            }
            else {
                extend(resData[parseInt(i.toString(), 10)].resourceData, { ClassName: RESOURCE_PARENT_CLASS });
                this.renderedResources[parseInt(i.toString(), 10)].className = [RESOURCE_PARENT_CLASS];
            }
            left = (rIndex * this.leftPixel) + 'px';
            if (resData[parseInt(i.toString(), 10)].resourceData.ClassName === RESOURCE_PARENT_CLASS
                && !isNullOrUndefined(resData[parseInt(i.toString(), 10)].resourceData.Count) && (resData[parseInt(i.toString(), 10)].resourceData.Count > 0)) {
                var iconClass = void 0;
                if (resData[parseInt(i.toString(), 10)].resourceData[resColl[parseInt(rIndex.toString(), 10)].expandedField] ||
                    isNullOrUndefined(resData[parseInt(i.toString(), 10)].resourceData[resColl[parseInt(rIndex.toString(), 10)].expandedField])) {
                    iconClass = RESOURCE_COLLAPSE_CLASS;
                }
                else {
                    iconClass = RESOURCE_EXPAND_CLASS;
                }
                var iconDiv = createElement('div');
                addClass([iconDiv], [RESOURCE_TREE_ICON_CLASS, iconClass]);
                this.setMargin(iconDiv, left);
                ntd.appendChild(iconDiv);
                if (this.resourceCollection.length > 1) {
                    EventHandler.add(iconDiv, 'click', this.onTreeIconClick, this);
                }
            }
            this.parent.activeView.setResourceHeaderContent(ntd, resData[parseInt(i.toString(), 10)], RESOURCE_TEXT_CLASS);
            ntd.setAttribute('data-group-index', resData[parseInt(i.toString(), 10)].groupIndex.toString());
            ntd.setAttribute('aria-label', resData[parseInt(i.toString(), 10)].resourceData[resData[parseInt(i.toString(), 10)].resource.textField] + ' resource');
            if (!this.parent.activeViewOptions.resourceHeaderTemplate) {
                this.setMargin(ntd.querySelector('.' + RESOURCE_TEXT_CLASS), left);
            }
            var classCollection = [RESOURCE_CELLS_CLASS, resData[parseInt(i.toString(), 10)].resourceData.ClassName];
            addClass([ntd], classCollection);
            var args = { elementType: 'resourceHeader', element: ntd, groupIndex: resData[parseInt(i.toString(), 10)].groupIndex };
            this.parent.trigger(renderCell, args);
            var ntr = tr.cloneNode();
            ntr.appendChild(ntd);
            resRows.push(ntr);
        }
        return resRows;
    };
    ResourceBase.prototype.setMargin = function (element, value) {
        if (!this.parent.enableRtl) {
            element.style.marginLeft = value;
        }
        else {
            element.style.marginRight = value;
        }
    };
    ResourceBase.prototype.countCalculation = function (parentCollection, wholeCollection) {
        var collection;
        for (var y = 0; y < parentCollection.length; y++) {
            var data = parentCollection[parentCollection.length - (y + 1)].dataSource;
            for (var x = 0; x < data.length; x++) {
                var totalCount = 0;
                if (this.parent.activeViewOptions.group.byGroupID) {
                    var query = new Query().where(wholeCollection[wholeCollection.length - 1].groupIDField, 'equal', data[parseInt(x.toString(), 10)][parentCollection[parentCollection.length - (y + 1)].idField]);
                    collection = new DataManager(wholeCollection[wholeCollection.length - 1].dataSource).executeLocal(query);
                }
                else {
                    collection = wholeCollection[wholeCollection.length - 1].dataSource;
                }
                for (var z = 0; z < collection.length; z++) {
                    totalCount = totalCount + parseInt(collection[parseInt(z.toString(), 10)].Count, 10);
                }
                totalCount = totalCount + parseInt(data[parseInt(x.toString(), 10)].Count, 10);
                extend(data[parseInt(x.toString(), 10)], { Count: totalCount });
            }
            wholeCollection = wholeCollection.slice(0, -1);
        }
    };
    ResourceBase.prototype.onTreeIconClick = function (e) {
        var _this = this;
        if (this.parent.eventTooltip) {
            this.parent.eventTooltip.close();
        }
        var target = e.target;
        var hide;
        var trElement = closest(target, '.' + RESOURCE_PARENT_CLASS)
            .parentElement;
        var index = parseInt(trElement.children[0].getAttribute('data-group-index'), 10);
        var args = {
            cancel: false, event: e, groupIndex: index,
            requestType: !target.classList.contains(RESOURCE_COLLAPSE_CLASS) ? 'resourceExpand' : 'resourceCollapse'
        };
        this.parent.trigger(actionBegin, args, function (actionArgs) {
            if (!actionArgs.cancel) {
                if (target.classList.contains(RESOURCE_COLLAPSE_CLASS)) {
                    classList(target, [RESOURCE_EXPAND_CLASS], [RESOURCE_COLLAPSE_CLASS]);
                    hide = true;
                }
                else {
                    classList(target, [RESOURCE_COLLAPSE_CLASS], [RESOURCE_EXPAND_CLASS]);
                    hide = false;
                }
                var eventElements = [].slice.call(_this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS + ',.' + MORE_INDICATOR_CLASS));
                for (var _i = 0, eventElements_1 = eventElements; _i < eventElements_1.length; _i++) {
                    var element = eventElements_1[_i];
                    remove(element);
                }
                if (_this.parent.virtualScrollModule) {
                    _this.updateVirtualContent(index, hide, e, target);
                }
                else {
                    _this.updateContent(index, hide);
                }
                var data = { cssProperties: _this.parent.getCssProperties(), module: 'scroll' };
                _this.parent.notify(scrollUiUpdate, data);
                args = {
                    cancel: false, event: e, groupIndex: index,
                    requestType: target.classList.contains(RESOURCE_COLLAPSE_CLASS) ? 'resourceExpanded' : 'resourceCollapsed'
                };
                _this.parent.refreshEvents(false);
                _this.parent.trigger(actionComplete, args);
            }
        });
    };
    ResourceBase.prototype.updateContent = function (index, hide) {
        var rowCollection = [];
        var workCellCollection = [];
        var headerRowCollection = [];
        var pNode;
        var clickedRes = this.lastResourceLevel[parseInt(index.toString(), 10)].resourceData;
        var resRows = [].slice.call(this.parent.element.querySelectorAll('.' + RESOURCE_COLUMN_WRAP_CLASS + ' ' + 'tr'));
        var contentRows = [].slice.call(this.parent.element.querySelectorAll('.' + CONTENT_WRAP_CLASS + ' ' + 'tbody tr'));
        var eventRows = [].slice.call(this.parent.element.querySelectorAll('.' + CONTENT_WRAP_CLASS + ' .' + APPOINTMENT_CONTAINER_CLASS));
        for (var j = 0; j < clickedRes.Count; j++) {
            rowCollection.push(resRows[index + j + 1]);
            workCellCollection.push(contentRows[index + j + 1]);
            headerRowCollection.push(eventRows[index + j + 1]);
        }
        var clonedCollection = this.lastResourceLevel;
        for (var i = 0; i < rowCollection.length; i++) {
            var expanded = true;
            pNode = rowCollection[parseInt(i.toString(), 10)].children[0].classList.contains(RESOURCE_PARENT_CLASS);
            clonedCollection[parseInt(index.toString(), 10)].resourceData[clonedCollection[parseInt(index.toString(), 10)].resource.expandedField] = !hide;
            if (hide) {
                if (pNode) {
                    var trElem = rowCollection[parseInt(i.toString(), 10)].querySelector('.' + RESOURCE_TREE_ICON_CLASS);
                    if (trElem) {
                        classList(trElem, [RESOURCE_EXPAND_CLASS], [RESOURCE_COLLAPSE_CLASS]);
                    }
                }
                if (!rowCollection[parseInt(i.toString(), 10)].classList.contains(HIDDEN_CLASS)) {
                    addClass([rowCollection[parseInt(i.toString(), 10)], workCellCollection[parseInt(i.toString(), 10)], headerRowCollection[parseInt(i.toString(), 10)]], HIDDEN_CLASS);
                }
            }
            else {
                if (pNode) {
                    var rowIndex = rowCollection[parseInt(i.toString(), 10)].rowIndex;
                    if (!clonedCollection[parseInt(rowIndex.toString(), 10)].resourceData[clonedCollection[parseInt(rowIndex.toString(), 10)].resource.expandedField]
                        && !isNullOrUndefined(clonedCollection[parseInt(rowIndex.toString(), 10)].resourceData[clonedCollection[parseInt(rowIndex.toString(), 10)].resource.expandedField])) {
                        rowCollection.splice(i + 1, (parseInt(clonedCollection[parseInt(rowIndex.toString(), 10)].resourceData.Count, 10)));
                        workCellCollection.splice(i + 1, (parseInt(clonedCollection[parseInt(rowIndex.toString(), 10)].resourceData.Count, 10)));
                        headerRowCollection.splice(i + 1, (parseInt(clonedCollection[parseInt(rowIndex.toString(), 10)].resourceData.Count, 10)));
                        expanded = false;
                    }
                    if (expanded) {
                        var trElem = rowCollection[parseInt(i.toString(), 10)].querySelector('.' + RESOURCE_TREE_ICON_CLASS);
                        if (trElem) {
                            classList(trElem, [RESOURCE_COLLAPSE_CLASS], [RESOURCE_EXPAND_CLASS]);
                        }
                    }
                }
                if (rowCollection[parseInt(i.toString(), 10)].classList.contains(HIDDEN_CLASS)) {
                    removeClass([rowCollection[parseInt(i.toString(), 10)], workCellCollection[parseInt(i.toString(), 10)], headerRowCollection[parseInt(i.toString(), 10)]], HIDDEN_CLASS);
                }
            }
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ResourceBase.prototype.updateVirtualContent = function (index, expand, e, target) {
        this.lastResourceLevel[parseInt(index.toString(), 10)].resourceData[this.lastResourceLevel[parseInt(index.toString(), 10)].resource.expandedField] = !expand;
        this.setExpandedResources();
        var resourceCount = this.parent.virtualScrollModule.getRenderedCount();
        var startIndex = this.expandedResources.indexOf(this.renderedResources[0]);
        this.renderedResources = this.expandedResources.slice(startIndex, startIndex + resourceCount);
        if (this.renderedResources.length < resourceCount) {
            var sIndex = this.expandedResources.length - resourceCount;
            sIndex = (sIndex > 0) ? sIndex : 0;
            this.renderedResources = this.expandedResources.slice(sIndex, this.expandedResources.length);
        }
        var virtualTrack = this.parent.element.querySelector('.' + VIRTUAL_TRACK_CLASS);
        this.parent.virtualScrollModule.updateVirtualTrackHeight(virtualTrack);
        var resTable = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS + ' ' + 'table');
        var contentTable = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS + ' ' + 'table');
        var eventTable = this.parent.element.querySelector('.' + EVENT_TABLE_CLASS);
        this.parent.virtualScrollModule.updateContent(resTable, contentTable, eventTable, this.renderedResources);
        var timeIndicator = this.parent.element.querySelector('.' + CURRENT_TIMELINE_CLASS);
        if (!isNullOrUndefined(timeIndicator)) {
            timeIndicator.style.height =
                this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS).offsetHeight + 'px';
        }
    };
    ResourceBase.prototype.renderResourceHeader = function () {
        var resourceWrapper = createElement('div', { className: RESOURCE_TOOLBAR_CONTAINER });
        resourceWrapper.innerHTML = '<div class="' + RESOURCE_HEADER_TOOLBAR + '"><div class="' + RESOURCE_MENU + '">' +
            '<div class="e-icons ' + RESOURCE_MENU_ICON + '"></div></div><div class="' + RESOURCE_LEVEL_TITLE + '"></div></div>';
        if (this.parent.currentView === 'MonthAgenda') {
            var target = this.parent.activeView.getPanel().querySelector('.' + CONTENT_WRAP_CLASS);
            target.insertBefore(resourceWrapper, target.querySelector('.' + WRAPPER_CONTAINER_CLASS));
        }
        else {
            this.parent.element.insertBefore(resourceWrapper, this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS));
        }
        this.renderResourceHeaderText();
        EventHandler.add(resourceWrapper.querySelector('.' + RESOURCE_MENU_ICON), 'click', this.menuClick, this);
    };
    ResourceBase.prototype.renderResourceTree = function () {
        this.popupOverlay = createElement('div', { className: RESOURCE_TREE_POPUP_OVERLAY });
        var treeWrapper = createElement('div', { className: RESOURCE_TREE_POPUP + ' e-popup-close' });
        if (this.parent.currentView === 'MonthAgenda') {
            var target = this.parent.activeView.getPanel().querySelector('.' + WRAPPER_CONTAINER_CLASS);
            target.insertBefore(treeWrapper, target.children[0]);
            target.appendChild(this.popupOverlay);
        }
        else {
            this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS).appendChild(treeWrapper);
            this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS).appendChild(this.popupOverlay);
        }
        var resourceTree = createElement('div', { className: RESOURCE_TREE });
        treeWrapper.appendChild(resourceTree);
        this.treeViewObj = new TreeView({
            cssClass: this.parent.cssClass,
            enableRtl: this.parent.enableRtl,
            enableHtmlSanitizer: this.parent.enableHtmlSanitizer,
            fields: {
                dataSource: [].slice.call(this.generateTreeData()),
                id: 'resourceId',
                text: 'resourceName',
                child: 'resourceChild'
            },
            nodeTemplate: this.parent.resourceHeaderTemplate,
            nodeClicked: this.resourceClick.bind(this),
            created: this.resourceTreeCreated.bind(this)
        });
        this.treeViewObj.root = this.parent.root ? this.parent.root : this.parent;
        this.treeViewObj.appendTo(resourceTree);
        this.treeViewObj.expandAll();
        this.treePopup = new Popup(treeWrapper, {
            targetType: 'relative',
            actionOnScroll: 'none',
            content: this.treeViewObj.element,
            relateTo: this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS),
            enableRtl: this.parent.enableRtl,
            hideAnimation: { name: 'SlideLeftOut', duration: 500 },
            showAnimation: { name: 'SlideLeftIn', duration: 500 },
            viewPortElement: this.parent.element.querySelector('.' + (this.parent.currentView === 'MonthAgenda' ?
                WRAPPER_CONTAINER_CLASS : TABLE_CONTAINER_CLASS))
        });
        this.parent.on(documentClick, this.documentClick, this);
    };
    ResourceBase.prototype.resourceTreeCreated = function () {
        if (this.parent.activeViewOptions.resourceHeaderTemplate && this.parent.portals && this.treeViewObj.portals) {
            this.parent.portals = this.parent.portals.concat(this.treeViewObj.portals);
            this.parent.renderTemplates();
        }
    };
    ResourceBase.prototype.generateTreeData = function (isTimeLine) {
        var _this = this;
        var treeCollection = [];
        var resTreeColl = [];
        var groupIndex = 0;
        var _loop_1 = function (i, len) {
            var treeHandler = function (treeLevel, index, levelId) {
                var resource = _this.resourceCollection[parseInt(index.toString(), 10)];
                var treeArgs;
                var resObj;
                if (!isTimeLine) {
                    treeArgs = {
                        resourceId: levelId,
                        resourceName: treeLevel.resourceData[resource.textField],
                        resource: treeLevel.resource,
                        resourceData: treeLevel.resourceData
                    };
                }
                else {
                    resObj = {
                        type: 'resourceHeader', resource: treeLevel.resource,
                        resourceData: treeLevel.resourceData, groupIndex: groupIndex,
                        groupOrder: treeLevel.groupOrder
                    };
                    resTreeColl.push(resObj);
                    groupIndex++;
                }
                if (treeLevel.child.length > 0 && !isTimeLine) {
                    treeArgs.resourceChild = [];
                }
                var count = 1;
                for (var _i = 0, _a = treeLevel.child; _i < _a.length; _i++) {
                    var tree = _a[_i];
                    if (!isTimeLine) {
                        treeArgs.resourceChild.push(treeHandler(tree, index + 1, levelId + '-' + count));
                    }
                    else {
                        treeHandler(tree, index + 1, levelId + '-' + count);
                    }
                    count += 1;
                }
                if (isTimeLine) {
                    extend(resObj.resourceData, { Count: count - 1 });
                }
                return treeArgs;
            };
            if (!isTimeLine) {
                treeCollection.push(treeHandler(this_1.resourceTreeLevel[parseInt(i.toString(), 10)], 0, (i + 1).toString()));
            }
            else {
                treeHandler(this_1.resourceTreeLevel[parseInt(i.toString(), 10)], 0, (i + 1).toString());
            }
        };
        var this_1 = this;
        for (var i = 0, len = this.resourceTreeLevel.length; i < len; i++) {
            _loop_1(i, len);
        }
        if (isTimeLine) {
            this.lastResourceLevel = resTreeColl;
            return resTreeColl;
        }
        else {
            return treeCollection;
        }
    };
    ResourceBase.prototype.renderResourceHeaderText = function () {
        var resource = this.lastResourceLevel[this.parent.uiStateValues.groupIndex];
        var headerCollection = [];
        var _loop_2 = function (i, len) {
            var resourceLevel = this_2.resourceCollection[parseInt(i.toString(), 10)];
            var resourceText = resourceLevel.dataSource.filter(function (resData) {
                return resData[resourceLevel.idField] === resource.groupOrder[parseInt(i.toString(), 10)];
            });
            var resourceName = createElement('div', { className: RESOURCE_NAME });
            this_2.parent.sanitize(resourceText[0][resourceLevel.textField], resourceName);
            headerCollection.push(resourceName);
            var levelIcon = createElement('div', { className: 'e-icons e-icon-next' });
            headerCollection.push(levelIcon);
        };
        var this_2 = this;
        for (var i = 0, len = resource.groupOrder.length; i < len; i++) {
            _loop_2(i, len);
        }
        headerCollection.pop();
        var target = (this.parent.currentView === 'MonthAgenda') ? this.parent.activeView.getPanel() : this.parent.element;
        var headerWrapper = target.querySelector('.' + RESOURCE_LEVEL_TITLE);
        removeChildren(headerWrapper);
        for (var _i = 0, headerCollection_1 = headerCollection; _i < headerCollection_1.length; _i++) {
            var header = headerCollection_1[_i];
            headerWrapper.appendChild(header);
        }
        if (this.lastResourceLevel.length === 1) {
            addClass([this.parent.element.querySelector('.' + RESOURCE_MENU)], DISABLE_CLASS);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ResourceBase.prototype.menuClick = function (event) {
        if (this.parent.element.querySelector('.' + RESOURCE_TREE_POPUP).classList.contains(POPUP_OPEN)) {
            this.treePopup.hide();
            removeClass([this.popupOverlay], ENABLE_CLASS);
        }
        else {
            var treeNodes = [].slice.call(this.treeViewObj.element.querySelectorAll('.e-list-item:not(.e-has-child)'));
            removeClass(treeNodes, 'e-active');
            addClass([treeNodes[this.parent.uiStateValues.groupIndex]], 'e-active');
            this.treePopup.show();
            addClass([this.popupOverlay], ENABLE_CLASS);
        }
    };
    ResourceBase.prototype.selectResourceByIndex = function (groupIndex) {
        if (this.lastResourceLevel && groupIndex > -1 && groupIndex < this.lastResourceLevel.length) {
            this.triggerEvents(groupIndex);
        }
    };
    ResourceBase.prototype.resourceClick = function (event) {
        if (!event.node.classList.contains('e-has-child')) {
            this.treePopup.hide();
            removeClass([this.popupOverlay], ENABLE_CLASS);
            var treeNodes = [].slice.call(this.treeViewObj.element.querySelectorAll('.e-list-item:not(.e-has-child)'));
            var groupIndex = treeNodes.indexOf(event.node);
            this.triggerEvents(groupIndex, event);
            event.event.preventDefault();
        }
    };
    ResourceBase.prototype.triggerEvents = function (groupIndex, event) {
        var _this = this;
        var args = { cancel: false, event: (event) ? event.event : null, groupIndex: groupIndex, requestType: 'resourceChange' };
        this.parent.trigger(actionBegin, args, function (actionArgs) {
            if (!actionArgs.cancel) {
                _this.parent.uiStateValues.groupIndex = actionArgs.groupIndex;
                _this.parent.renderModule.render(_this.parent.currentView);
                args = {
                    cancel: false, event: (event) ? event.event : null, groupIndex: _this.parent.uiStateValues.groupIndex, requestType: 'resourceChanged'
                };
                _this.parent.trigger(actionComplete, args);
            }
        });
    };
    ResourceBase.prototype.documentClick = function (args) {
        if (closest(args.event.target, '.' + RESOURCE_TREE_POPUP)) {
            return;
        }
        var treeWrapper = this.parent.element.querySelector('.' + RESOURCE_TREE_POPUP);
        if (treeWrapper && treeWrapper.classList.contains(POPUP_OPEN)) {
            this.treePopup.hide();
            removeClass([this.popupOverlay], ENABLE_CLASS);
        }
    };
    ResourceBase.prototype.bindResourcesData = function (isSetModel) {
        var _this = this;
        this.parent.showSpinner();
        var promises = [];
        for (var _i = 0, _a = this.parent.resources; _i < _a.length; _i++) {
            var resource = _a[_i];
            var dataModule = new Data(this.parent, resource.dataSource, resource.query);
            promises.push(dataModule.getData(dataModule.generateQuery()));
        }
        Promise.all(promises).then(function (e) { return _this.dataManagerSuccess(e, isSetModel); })
            .catch(function (e) { return _this.parent.crudModule.dataManagerFailure(e); });
    };
    ResourceBase.prototype.dataManagerSuccess = function (e, isSetModel) {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent.resourceCollection = [];
        for (var i = 0, length_1 = e.length; i < length_1; i++) {
            var resource = this.parent.resources[parseInt(i.toString(), 10)];
            var resourceObj = this.getResourceModel(resource, e[parseInt(i.toString(), 10)].result);
            this.parent.resourceCollection.push(resourceObj);
        }
        this.refreshLayout(isSetModel);
    };
    ResourceBase.prototype.getResourceModel = function (resource, resourceData) {
        var resourceObj = {
            field: resource.field,
            title: resource.title,
            name: resource.name,
            allowMultiple: resource.allowMultiple,
            dataSource: resourceData || resource.dataSource,
            idField: resource.idField,
            textField: resource.textField,
            groupIDField: resource.groupIDField,
            colorField: resource.colorField,
            startHourField: resource.startHourField,
            endHourField: resource.endHourField,
            workDaysField: resource.workDaysField,
            expandedField: resource.expandedField,
            cssClassField: resource.cssClassField
        };
        return resourceObj;
    };
    ResourceBase.prototype.refreshLayout = function (isSetModel) {
        if (isNullOrUndefined(this.parent.uiStateValues.groupIndex) || !(this.parent.enablePersistence)) {
            this.parent.uiStateValues.groupIndex = 0;
        }
        this.parent.renderElements(isSetModel);
    };
    ResourceBase.prototype.setResourceCollection = function () {
        var requiredResources = [];
        this.resourceCollection = [];
        this.colorIndex = null;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            for (var _i = 0, _a = this.parent.activeViewOptions.group.resources; _i < _a.length; _i++) {
                var resource = _a[_i];
                var index_1 = findIndexInData(this.parent.resourceCollection, 'name', resource);
                if (index_1 >= 0) {
                    requiredResources.push(this.parent.resourceCollection[parseInt(index_1.toString(), 10)]);
                }
            }
        }
        else if (this.parent.resourceCollection.length > 0) {
            requiredResources = this.parent.resourceCollection;
        }
        var index = 0;
        for (var _b = 0, requiredResources_1 = requiredResources; _b < requiredResources_1.length; _b++) {
            var resource = requiredResources_1[_b];
            var resources = this.getResourceModel(resource);
            if (resource.name === this.parent.eventSettings.resourceColorField) {
                this.colorIndex = index;
            }
            index++;
            this.resourceCollection.push(resources);
        }
        if (isNullOrUndefined(this.colorIndex)) {
            this.colorIndex = this.resourceCollection.length - 1;
        }
    };
    ResourceBase.prototype.generateResourceLevels = function (innerDates, isTimeLine) {
        var _this = this;
        var resources = this.resourceCollection;
        var resTreeGroup = [];
        var lastColumnDates = [];
        var group = function (resources, index, prevResource, prevResourceData, prevOrder) {
            var resTree = [];
            var resource = resources[0];
            if (resource) {
                var data = void 0;
                if (prevResourceData && _this.parent.activeViewOptions.group.byGroupID) {
                    var id_1 = prevResourceData[prevResource.idField];
                    data = resource.dataSource.filter(function (e) {
                        return e[resource.groupIDField] === id_1;
                    });
                }
                else {
                    data = resource.dataSource;
                }
                for (var i = 0; i < data.length; i++) {
                    var groupOrder = [];
                    if (prevOrder && prevOrder.length > 0) {
                        groupOrder = groupOrder.concat(prevOrder);
                    }
                    groupOrder.push(data[parseInt(i.toString(), 10)][resource.idField]);
                    var items = group(resources.slice(1), index + 1, resource, data[parseInt(i.toString(), 10)], groupOrder);
                    // Here validate child item empty top level resource only
                    if (index === 0 && items.length === 0 && _this.resourceCollection.length > 1) {
                        continue;
                    }
                    var dateCol = [];
                    var renderDates = _this.parent.activeView.renderDates;
                    var resWorkDays = void 0;
                    if (!_this.parent.activeViewOptions.group.byDate && index + 1 === _this.resourceCollection.length) {
                        var workDays = data[parseInt(i.toString(), 10)][resource.workDaysField];
                        var resStartHour = data[parseInt(i.toString(), 10)][resource.startHourField];
                        var resEndHour = data[parseInt(i.toString(), 10)][resource.endHourField];
                        if (workDays && workDays.length > 0) {
                            renderDates = _this.parent.activeView.getRenderDates(workDays);
                            resWorkDays = workDays;
                            dateCol = _this.parent.activeView.getDateSlots(renderDates, workDays);
                        }
                        else {
                            resWorkDays = _this.parent.activeViewOptions.workDays;
                            dateCol = innerDates;
                        }
                        var dateSlots = _this.generateCustomHours(dateCol, resStartHour, resEndHour, groupOrder);
                        lastColumnDates = lastColumnDates.concat(dateSlots);
                    }
                    var resCssClass = data[parseInt(i.toString(), 10)][resource.cssClassField];
                    var slotData = {
                        type: 'resourceHeader', className: ['e-resource-cells'],
                        resourceLevelIndex: index, groupOrder: groupOrder,
                        resource: resource, resourceData: data[parseInt(i.toString(), 10)],
                        colSpan: _this.parent.activeViewOptions.group.byDate ? 1 : dateCol.length,
                        renderDates: renderDates, workDays: resWorkDays, cssClass: resCssClass,
                        child: items
                    };
                    resTree.push(slotData);
                }
                if (!resTreeGroup[parseInt(index.toString(), 10)]) {
                    resTreeGroup[parseInt(index.toString(), 10)] = [];
                }
                if (resTree.length > 0) {
                    resTreeGroup[parseInt(index.toString(), 10)].push(resTree);
                }
                return resTree;
            }
            return [];
        };
        this.resourceTreeLevel = group(resources, 0);
        return (isTimeLine) ? [] : this.generateHeaderLevels(resTreeGroup, lastColumnDates, innerDates);
    };
    ResourceBase.prototype.generateCustomHours = function (renderDates, startHour, endHour, groupOrder) {
        var dateSlots = extend([], renderDates, null, true);
        for (var _i = 0, dateSlots_1 = dateSlots; _i < dateSlots_1.length; _i++) {
            var dateSlot = dateSlots_1[_i];
            if (startHour) {
                dateSlot.startHour = this.parent.getStartEndTime(startHour);
            }
            if (endHour) {
                dateSlot.endHour = this.parent.getStartEndTime(endHour);
            }
            if (groupOrder) {
                dateSlot.groupOrder = groupOrder;
            }
        }
        return dateSlots;
    };
    ResourceBase.prototype.generateHeaderLevels = function (resTreeGroup, lastColumnDates, headerDates) {
        var headerLevels = [];
        for (var i = resTreeGroup.length - 1; i >= 0; i--) {
            var temp = 0;
            for (var _i = 0, _a = resTreeGroup[parseInt(i.toString(), 10)]; _i < _a.length; _i++) {
                var currentLevelChilds = _a[_i];
                for (var _b = 0, currentLevelChilds_1 = currentLevelChilds; _b < currentLevelChilds_1.length; _b++) {
                    var currentLevelChild = currentLevelChilds_1[_b];
                    if (resTreeGroup[i + 1] && resTreeGroup[i + 1].length > 0) {
                        var nextLevelChilds = resTreeGroup[parseInt((i + 1).toString(), 10)][parseInt(temp.toString(), 10)];
                        if (!nextLevelChilds) {
                            continue;
                        }
                        var colSpan = 0;
                        for (var _c = 0, nextLevelChilds_1 = nextLevelChilds; _c < nextLevelChilds_1.length; _c++) {
                            var nextLevelChild = nextLevelChilds_1[_c];
                            if (!this.parent.activeViewOptions.group.byGroupID || (this.parent.activeViewOptions.group.byGroupID &&
                                nextLevelChild.resourceData[nextLevelChild.resource.groupIDField] ===
                                    currentLevelChild.resourceData[currentLevelChild.resource.idField])) {
                                colSpan += nextLevelChild.colSpan;
                            }
                        }
                        currentLevelChild.colSpan = colSpan;
                    }
                    currentLevelChild.groupIndex = temp;
                    temp++;
                    headerLevels[currentLevelChild.resourceLevelIndex] = headerLevels[currentLevelChild.resourceLevelIndex] || [];
                    headerLevels[currentLevelChild.resourceLevelIndex].push(currentLevelChild);
                }
            }
        }
        this.lastResourceLevel = headerLevels.slice(-1)[0] || [];
        if (!this.parent.activeViewOptions.group.byDate) {
            var index = 0;
            for (var _d = 0, _e = this.lastResourceLevel; _d < _e.length; _d++) {
                var lastLevelResource = _e[_d];
                for (var i = 0; i < lastLevelResource.colSpan; i++) {
                    lastColumnDates[parseInt(index.toString(), 10)].groupIndex = lastLevelResource.groupIndex;
                    index++;
                }
            }
            headerLevels.push(lastColumnDates);
            return headerLevels;
        }
        var dateHeaderLevels = [];
        var levels = extend([], headerLevels, null, true);
        var datesColumn = [];
        if (this.parent.activeViewOptions.group.hideNonWorkingDays) {
            var renderDates_1 = [];
            var dateIndex = 0;
            var _loop_3 = function (headerDate) {
                this_3.resourceDateTree[parseInt(dateIndex.toString(), 10)] = [];
                var currentDateLevels = [];
                var _loop_4 = function (j) {
                    var workDays = this_3.lastResourceLevel[parseInt(j.toString(), 10)].resourceData[this_3.lastResourceLevel[parseInt(j.toString(), 10)].resource.workDaysField];
                    if (!workDays) {
                        workDays = this_3.parent.activeViewOptions.workDays;
                    }
                    if (workDays.indexOf(headerDate.date.getDay()) !== -1) {
                        var resTd_1 = extend({}, this_3.lastResourceLevel[parseInt(j.toString(), 10)], null, true);
                        resTd_1.date = headerDate.date;
                        this_3.lastResourceLevel[parseInt(j.toString(), 10)].workDays = workDays;
                        resTd_1.startHour = this_3.parent.getStartEndTime(resTd_1.resourceData[resTd_1.resource.startHourField]) ||
                            headerDate.startHour;
                        resTd_1.endHour = this_3.parent.getStartEndTime(resTd_1.resourceData[resTd_1.resource.endHourField]) ||
                            headerDate.endHour;
                        this_3.resourceDateTree[parseInt(dateIndex.toString(), 10)].push(resTd_1);
                        var _loop_5 = function (k) {
                            if (!currentDateLevels[parseInt(k.toString(), 10)]) {
                                currentDateLevels[parseInt(k.toString(), 10)] = [];
                            }
                            if (k === resTd_1.groupOrder.length - 1) {
                                if (!renderDates_1[parseInt(j.toString(), 10)]) {
                                    renderDates_1[parseInt(j.toString(), 10)] = [];
                                }
                                var filterDates = resTd_1.renderDates.filter(function (x) { return x.getDay() === headerDate.date.getDay(); });
                                renderDates_1[parseInt(j.toString(), 10)] = renderDates_1[parseInt(j.toString(), 10)].concat(filterDates);
                                currentDateLevels[parseInt(k.toString(), 10)].push(resTd_1);
                                return "continue";
                            }
                            var currentLevel = levels[parseInt(k.toString(), 10)];
                            var filteredResource = currentLevel.filter(function (data) {
                                return data.resourceData[data.resource.idField] === resTd_1.groupOrder[parseInt(k.toString(), 10)];
                            });
                            if (filteredResource && filteredResource.length > 0) {
                                var existedResource = currentDateLevels[parseInt(k.toString(), 10)].filter(function (data) {
                                    return data.resourceData[data.resource.idField] === resTd_1.groupOrder[parseInt(k.toString(), 10)];
                                });
                                if (existedResource && existedResource.length > 0) {
                                    existedResource[0].colSpan += 1;
                                }
                                else {
                                    var filteredTd = extend({}, filteredResource[0], null, true);
                                    filteredTd.colSpan = 1;
                                    currentDateLevels[parseInt(k.toString(), 10)].push(filteredTd);
                                }
                            }
                        };
                        for (var k = 0; k < resTd_1.groupOrder.length; k++) {
                            _loop_5(k);
                        }
                    }
                };
                for (var j = 0; j < this_3.lastResourceLevel.length; j++) {
                    _loop_4(j);
                }
                if (currentDateLevels.length > 0) {
                    for (var l = 0; l < levels.length; l++) {
                        if (!dateHeaderLevels[parseInt(l.toString(), 10)]) {
                            dateHeaderLevels[parseInt(l.toString(), 10)] = [];
                        }
                        dateHeaderLevels[parseInt(l.toString(), 10)] = dateHeaderLevels[parseInt(l.toString(), 10)].concat(currentDateLevels[parseInt(l.toString(), 10)]);
                    }
                    headerDate.colSpan = currentDateLevels[currentDateLevels.length - 1].length;
                    datesColumn.push(headerDate);
                }
                dateIndex++;
            };
            var this_3 = this;
            for (var _f = 0, headerDates_1 = headerDates; _f < headerDates_1.length; _f++) {
                var headerDate = headerDates_1[_f];
                _loop_3(headerDate);
            }
            this.resourceDateTree = this.resourceDateTree.filter(function (data) { return data.length > 0; });
            this.lastResourceLevel.forEach(function (x, index) {
                if (renderDates_1[parseInt(index.toString(), 10)]) {
                    x.renderDates = renderDates_1[parseInt(index.toString(), 10)].sort(function (a, b) { return a.getTime() - b.getTime(); });
                }
            });
            dateHeaderLevels.unshift(datesColumn);
            return dateHeaderLevels;
        }
        var dateColSpan = 0;
        for (var _g = 0, _h = levels[0]; _g < _h.length; _g++) {
            var firstRowTd = _h[_g];
            dateColSpan += firstRowTd.colSpan;
        }
        for (var _j = 0, headerDates_2 = headerDates; _j < headerDates_2.length; _j++) {
            var headerDate = headerDates_2[_j];
            headerDate.colSpan = dateColSpan;
            datesColumn.push(headerDate);
            var resGroup = extend([], levels, null, true);
            for (var k = 0, length_2 = resGroup.length; k < length_2; k++) {
                if (k === resGroup.length - 1) {
                    for (var _k = 0, _l = resGroup[parseInt(k.toString(), 10)]; _k < _l.length; _k++) {
                        var resTd = _l[_k];
                        resTd.date = headerDate.date;
                        resTd.workDays = headerDate.workDays;
                        resTd.startHour = this.parent.getStartEndTime(resTd.resourceData[resTd.resource.startHourField]) ||
                            headerDate.startHour;
                        resTd.endHour = this.parent.getStartEndTime(resTd.resourceData[resTd.resource.endHourField]) ||
                            headerDate.endHour;
                    }
                }
                if (!dateHeaderLevels[parseInt(k.toString(), 10)]) {
                    dateHeaderLevels[parseInt(k.toString(), 10)] = [];
                }
                dateHeaderLevels[parseInt(k.toString(), 10)] = dateHeaderLevels[parseInt(k.toString(), 10)].concat(resGroup[parseInt(k.toString(), 10)]);
            }
        }
        dateHeaderLevels.unshift(datesColumn);
        return dateHeaderLevels;
    };
    ResourceBase.prototype.setResourceValues = function (eventObj, groupIndex) {
        var _this = this;
        var setValues = function (index, field, value) {
            if (_this.resourceCollection[parseInt(index.toString(), 10)].allowMultiple && _this.parent.activeViewOptions.group.allowGroupEdit) {
                eventObj["" + field] = [value];
            }
            else {
                eventObj["" + field] = value;
            }
        };
        if (groupIndex === void 0) {
            groupIndex = this.parent.uiStateValues.isGroupAdaptive ? this.parent.uiStateValues.groupIndex :
                this.parent.activeCellsData.groupIndex;
        }
        if (this.parent.activeViewOptions.group.resources.length > 0 && !isNullOrUndefined(groupIndex)) {
            var groupOrder = this.lastResourceLevel[parseInt(groupIndex.toString(), 10)].groupOrder;
            for (var index = 0; index < this.resourceCollection.length; index++) {
                setValues(index, this.resourceCollection[parseInt(index.toString(), 10)].field, groupOrder[parseInt(index.toString(), 10)]);
            }
        }
        else if (this.parent.resourceCollection.length > 0) {
            for (var index = 0; index < this.resourceCollection.length; index++) {
                var data = this.resourceCollection[parseInt(index.toString(), 10)].dataSource[0];
                if (data) {
                    setValues(index, this.resourceCollection[parseInt(index.toString(), 10)].field, data[this.resourceCollection[parseInt(index.toString(), 10)].idField]);
                }
            }
        }
    };
    ResourceBase.prototype.getResourceColor = function (eventObj, groupOrder) {
        var colorFieldIndex = (!isNullOrUndefined(groupOrder) &&
            this.colorIndex > groupOrder.length - 1) ? groupOrder.length - 1 : this.colorIndex;
        var resource = this.resourceCollection[parseInt(colorFieldIndex.toString(), 10)];
        if (isNullOrUndefined(groupOrder) && this.parent.activeViewOptions.group.allowGroupEdit && resource.allowMultiple) {
            return undefined;
        }
        var id = isNullOrUndefined(groupOrder) ? eventObj[resource.field] : groupOrder[parseInt(colorFieldIndex.toString(), 10)];
        var data = this.filterData(resource.dataSource, resource.idField, id);
        if (data.length > 0) {
            return data[0][resource.colorField];
        }
        return undefined;
    };
    ResourceBase.prototype.getCssClass = function (eventObj) {
        var resource = this.resourceCollection.slice(-1)[0];
        if (this.parent.activeViewOptions.group.allowGroupEdit && resource.allowMultiple) {
            return undefined;
        }
        var data = this.filterData(resource.dataSource, resource.idField, eventObj[resource.field]);
        if (data.length > 0) {
            return data[0][resource.cssClassField];
        }
        return undefined;
    };
    ResourceBase.prototype.getResourceRenderDates = function () {
        // eslint-disable-next-line prefer-spread
        var resourceDates = [].concat.apply([], this.lastResourceLevel.map(function (e) { return e.renderDates; }));
        var removeDuplicateDates = function (dateColl) { return dateColl.filter(function (date, index, dates) {
            return dates.map(function (dateObj) { return dateObj.getTime(); }).indexOf(date.getTime()) === index;
        }); };
        var renderDates = removeDuplicateDates(resourceDates);
        renderDates.sort(function (a, b) { return a.getTime() - b.getTime(); });
        return renderDates;
    };
    ResourceBase.prototype.filterData = function (dataSource, field, value) {
        return dataSource.filter(function (data) { return data["" + field] === value; });
    };
    ResourceBase.prototype.getResourceData = function (eventObj, index, groupEditIndex) {
        if (this.parent.activeViewOptions.group.allowGroupEdit) {
            var resourceObj = {};
            for (var _i = 0, groupEditIndex_1 = groupEditIndex; _i < groupEditIndex_1.length; _i++) {
                var groupIndex = groupEditIndex_1[_i];
                var resourceLevel = this.lastResourceLevel[parseInt(groupIndex.toString(), 10)].groupOrder;
                for (var level = 0, length_3 = resourceLevel.length; level < length_3; level++) {
                    var fieldName = this.resourceCollection[parseInt(level.toString(), 10)].field;
                    if (isNullOrUndefined(resourceObj["" + fieldName])) {
                        resourceObj["" + fieldName] = [];
                    }
                    resourceObj["" + fieldName].push(resourceLevel[parseInt(level.toString(), 10)]);
                }
            }
            eventObj = extend(eventObj, resourceObj);
        }
        else {
            for (var level = 0, length_4 = this.resourceCollection.length; level < length_4; level++) {
                if (this.lastResourceLevel[parseInt(index.toString(), 10)]) {
                    eventObj[this.resourceCollection[parseInt(level.toString(), 10)].field] = this.lastResourceLevel[parseInt(index.toString(), 10)].groupOrder[parseInt(level.toString(), 10)];
                }
            }
        }
    };
    ResourceBase.prototype.addResource = function (resources, name, index) {
        var resourceCollection = (resources instanceof Array) ? resources : [resources];
        var _loop_6 = function (resource) {
            if (resource.name === name) {
                resourceCollection.forEach(function (addObj, i) {
                    return new DataManager({ json: resource.dataSource }).insert(addObj, null, null, index + i);
                });
                return "break";
            }
        };
        for (var _i = 0, _a = this.parent.resourceCollection; _i < _a.length; _i++) {
            var resource = _a[_i];
            var state_1 = _loop_6(resource);
            if (state_1 === "break")
                break;
        }
        this.refreshLayout(true);
    };
    ResourceBase.prototype.removeResource = function (resourceId, name) {
        var resourceCollection = (resourceId instanceof Array) ? resourceId : [resourceId];
        var _loop_7 = function (resource) {
            if (resource.name === name) {
                resourceCollection.forEach(function (removeObj) {
                    return new DataManager({ json: resource.dataSource }).remove(resource.idField, removeObj);
                });
                return "break";
            }
        };
        for (var _i = 0, _a = this.parent.resourceCollection; _i < _a.length; _i++) {
            var resource = _a[_i];
            var state_2 = _loop_7(resource);
            if (state_2 === "break")
                break;
        }
        this.refreshLayout(true);
    };
    ResourceBase.prototype.getIndexFromResourceId = function (id, name, resourceData, event, parentField) {
        name = name || this.parent.resourceCollection.slice(-1)[0].name;
        if (isNullOrUndefined(resourceData)) {
            resourceData = this.resourceCollection.filter(function (e) { return e.name === name; })[0];
            if (isNullOrUndefined(resourceData)) {
                return null;
            }
        }
        var resource = resourceData.dataSource.filter(function (e) {
            if (event && e[resourceData.idField] === id) {
                if (e[resourceData.groupIDField] === event["" + parentField]) {
                    return e[resourceData.idField] === id;
                }
                return null;
            }
            else {
                return e[resourceData.idField] === id;
            }
        })[0];
        return (this.lastResourceLevel.map(function (e) { return e.resourceData; }).indexOf(resource));
    };
    ResourceBase.prototype.resourceExpand = function (id, name, hide) {
        var resource = this.parent.resourceCollection.filter(function (e) {
            if (e.name === name) {
                return e;
            }
            return null;
        })[0];
        var index = 0;
        var resourceData = resource.dataSource.filter(function (e) { return e[resource.idField] === id; })[0];
        if (!this.parent.activeViewOptions.group.byGroupID) {
            index = this.getIndexFromResourceId(id, name, resource);
        }
        else {
            index = this.lastResourceLevel.map(function (e) { return e.resourceData; }).indexOf(resourceData);
        }
        var target = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS + ' ' + ("[data-group-index=\"" + index + "\"]") +
            ' ' + '.' + RESOURCE_TREE_ICON_CLASS);
        if (target) {
            if (target.classList.contains(RESOURCE_EXPAND_CLASS) && !hide) {
                target.click();
            }
            else if (target.classList.contains(RESOURCE_COLLAPSE_CLASS) && hide) {
                target.click();
            }
        }
    };
    ResourceBase.prototype.resourceScroll = function (id, name) {
        if (this.parent.isAdaptive || ['Agenda', 'MonthAgenda'].indexOf(this.parent.currentView) > -1) {
            return;
        }
        var levelName = name || this.parent.resourceCollection.slice(-1)[0].name;
        var levelIndex = this.parent.resourceCollection.length - 1;
        var resource = this.parent.resourceCollection.filter(function (e, index) {
            if (e.name === levelName) {
                levelIndex = index;
                return e;
            }
            return null;
        })[0];
        var scrollElement = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var index = 0;
        if (this.parent.activeView.isTimelineView()) {
            if (!this.parent.activeViewOptions.group.byGroupID) {
                index = this.getIndexFromResourceId(id, levelName, resource);
            }
            else {
                var resourceData = resource.dataSource.filter(function (e) {
                    return e[resource.idField] === id;
                })[0];
                index = this.lastResourceLevel.map(function (e) { return e.resourceData; }).indexOf(resourceData);
            }
            if (this.parent.virtualScrollModule) {
                var virtual = this.parent.element.querySelector('.' + VIRTUAL_TRACK_CLASS);
                var averageRowHeight = Math.round(virtual.offsetHeight / this.expandedResources.length);
                if (this.parent.rowAutoHeight) {
                    scrollElement.scrollTop = 0;
                    this.parent.virtualScrollModule.virtualScrolling();
                }
                scrollElement.scrollTop = (index * averageRowHeight) - (((this.parent.virtualScrollModule.bufferCount - 1) * averageRowHeight));
                this.parent.virtualScrollModule.virtualScrolling();
                if (this.parent.rowAutoHeight) {
                    var td = this.parent.element.querySelector("." + WORK_CELLS_CLASS + "[data-group-index=\"" + index + "\"]");
                    if (td && !td.parentElement.classList.contains(HIDDEN_CLASS)) {
                        scrollElement.scrollTop =
                            (scrollElement.scrollTop < td.offsetTop) ? td.offsetTop : scrollElement.scrollTop + td.offsetTop;
                    }
                }
                else {
                    scrollElement.scrollTop = (index * averageRowHeight);
                }
            }
            else {
                var td = this.parent.element.querySelector("." + WORK_CELLS_CLASS + "[data-group-index=\"" + index + "\"]");
                if (td && !td.parentElement.classList.contains(HIDDEN_CLASS)) {
                    scrollElement.scrollTop = td.offsetTop;
                }
            }
        }
        else {
            if (!this.parent.activeViewOptions.group.byGroupID) {
                index = this.getIndexFromResourceId(id, levelName, resource);
            }
            else {
                if (levelName === this.parent.resourceCollection.slice(-1)[0].name) {
                    index = this.lastResourceLevel.map(function (e) { return e.resourceData[resource.idField]; }).indexOf(id);
                }
                else {
                    index = resource.dataSource.map(function (e) { return e[resource.idField]; }).indexOf(id);
                }
            }
            var offsetTarget = this.parent.element.querySelector("." + HEADER_ROW_CLASS + ":nth-child(" + (levelIndex + 1) + ")");
            var offset = [].slice.call(offsetTarget.children).map(function (node) { return node.offsetLeft; });
            scrollElement.scrollLeft = offset[parseInt(index.toString(), 10)];
        }
    };
    ResourceBase.prototype.destroy = function () {
        this.parent.off(documentClick, this.documentClick);
        if (this.treeViewObj) {
            if (this.treeViewObj.portals && this.treeViewObj.portals.length > 0) {
                var treeViewTemplates = this.treeViewObj.portals.map(function (x) { return x.propName; });
                if (treeViewTemplates.length > 0) {
                    this.parent.resetTemplates(treeViewTemplates);
                }
            }
            this.treeViewObj.destroy();
            this.treeViewObj = null;
        }
        if (this.treePopup) {
            this.treePopup.destroy();
            this.treePopup = null;
            remove(this.parent.element.querySelector('.' + RESOURCE_TREE_POPUP));
            remove(this.parent.element.querySelector('.' + RESOURCE_TREE_POPUP_OVERLAY));
        }
        var resToolBarEle = this.parent.element.querySelector('.' + RESOURCE_TOOLBAR_CONTAINER);
        if (resToolBarEle) {
            remove(resToolBarEle);
        }
    };
    return ResourceBase;
}());

var __extends = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (undefined && undefined.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Represents the Schedule component that displays a list of events scheduled against specific date and timings,
 * thus helping us to plan and manage it properly.
 * ```html
 * <div id="schedule"></div>
 * ```
 * ```typescript
 * <script>
 *   var scheduleObj = new Schedule();
 *   scheduleObj.appendTo("#schedule");
 * </script>
 * ```
 */
var Schedule = /** @__PURE__ @class */ (function (_super) {
    __extends(Schedule, _super);
    /**
     * Constructor for creating the Schedule widget
     *
     * @param {ScheduleModel} options Accepts the schedule model properties to initiate the rendering
     * @param {string | HTMLElement} element Accepts the DOM element reference
     */
    function Schedule(options, element) {
        return _super.call(this, options, element) || this;
    }
    /**
     * Core method that initializes the control rendering.
     *
     * @returns {void}
     * @private
     */
    Schedule.prototype.render = function () {
        var addClasses = [];
        var removeClasses = [];
        addClasses.push(ROOT);
        if (this.enableRtl) {
            addClasses.push(RTL);
        }
        else {
            removeClasses.push(RTL);
        }
        if (this.isAdaptive) {
            addClasses.push(DEVICE_CLASS);
        }
        else {
            removeClasses.push(DEVICE_CLASS);
        }
        if (this.allowMultiDrag) {
            addClasses.push(MULTI_DRAG);
        }
        else {
            removeClasses.push(MULTI_DRAG);
        }
        if (this.cssClass) {
            var cssClass = this.cssClass.split(' ');
            for (var _i = 0, cssClass_1 = cssClass; _i < cssClass_1.length; _i++) {
                var cls_1 = cssClass_1[_i];
                addClasses.push(cls_1);
            }
        }
        classList(this.element, addClasses, removeClasses);
        this.validateDate();
        createSpinner({ target: this.element });
        this.scrollModule = new Scroll(this);
        this.scrollModule.setWidth();
        this.scrollModule.setHeight();
        this.renderModule = new Render(this);
        this.eventBase = new EventBase(this);
        this.workCellAction = new WorkCellInteraction(this);
        if (this.allowKeyboardInteraction) {
            this.keyboardInteractionModule = new KeyboardInteraction(this);
        }
        this.inlineModule = new InlineEdit(this);
        this.initializeDataModule();
        this.renderTableContainer();
        this.activeViewOptions = this.getActiveViewOptions();
        this.initializeResources();
        this.wireEvents();
    };
    Schedule.prototype.renderTableContainer = function () {
        if (!this.element.querySelector('.' + TABLE_CONTAINER_CLASS)) {
            this.element.appendChild(this.createElement('div', { className: TABLE_CONTAINER_CLASS }));
        }
    };
    /**
     * Method to render react templates
     *
     * @param {Function} callback - Specifies the callBack method
     * @returns {void}
     * @private
     */
    Schedule.prototype.renderTemplates = function (callback) {
        if (this.isReact) {
            this.renderReactTemplates(callback);
        }
        else if (callback) {
            callback();
        }
    };
    /**
     * Method to reset react templates
     *
     * @param {string[]} templates Accepts the template ID
     * @returns {void}
     * @private
     */
    Schedule.prototype.resetTemplates = function (templates) {
        if (this.isAngular || this.isReact) {
            this.clearTemplate(templates);
        }
    };
    /**
     * This method renders untrusted strings and scripts securely by sanitizing them first.
     *
     * @param {string} value - A string value representing the HTML string value to be sanitized.
     * @param {HTMLElement} element - An HTML element to which the sanitized or unsanitized HTML string will be assigned.
     * @returns {void}
     * @private
     */
    Schedule.prototype.sanitize = function (value, element) {
        if (this.enableHtmlSanitizer) {
            element.innerText = SanitizeHtmlHelper.sanitize(value);
        }
        else {
            element.innerHTML = value;
        }
    };
    Schedule.prototype.initializeResources = function (isSetModel) {
        if (isSetModel === void 0) { isSetModel = false; }
        if (this.resources.length > 0) {
            this.resourceBase = new ResourceBase(this);
            this.resourceBase.bindResourcesData(isSetModel);
        }
        else {
            this.resourceBase = null;
            this.resourceCollection = [];
            this.renderElements(isSetModel);
        }
    };
    Schedule.prototype.destroyEditorWindow = function () {
        if (this.eventWindow) {
            this.eventWindow.destroy();
            this.eventWindow = null;
        }
        this.eventWindow = new EventWindow(this);
    };
    /**
     * Method to render the layout elements
     *
     * @param {boolean} isLayoutOnly Accepts the boolean value to render layout or not
     * @returns {void}
     * @private
     */
    Schedule.prototype.renderElements = function (isLayoutOnly) {
        if (isLayoutOnly) {
            this.initializeView(this.currentView);
            this.eventWindow.refresh();
            return;
        }
        this.destroyHeaderModule();
        if (this.showHeaderBar) {
            this.headerModule = new HeaderRenderer(this);
        }
        this.renderTableContainer();
        if (Browser.isDevice || Browser.isTouch) {
            this.scheduleTouchModule = new ScheduleTouch(this);
        }
        this.initializeView(this.currentView);
        this.destroyPopups();
        if (!this.isPrinting) {
            this.initializePopups();
        }
    };
    Schedule.prototype.validateDate = function (selectedDate) {
        if (selectedDate === void 0) { selectedDate = this.selectedDate; }
        // persist the selected date value
        var date = selectedDate instanceof Date ? new Date(selectedDate.getTime()) : new Date(selectedDate);
        var minDate = this.minDate instanceof Date ? new Date(this.minDate.getTime()) : new Date(this.minDate);
        var maxDate = this.maxDate instanceof Date ? new Date(this.maxDate.getTime()) : new Date(this.maxDate);
        if (minDate <= maxDate) {
            if (date < minDate) {
                date = minDate;
            }
            if (date > maxDate) {
                date = maxDate;
            }
            this.setProperties({ selectedDate: new Date('' + date), minDate: new Date('' + minDate), maxDate: new Date('' + maxDate) }, true);
            if (this.eventWindow) {
                this.eventWindow.updateMinMaxDateToEditor();
            }
        }
        else {
            throw Error('minDate should be equal or less than maxDate');
        }
    };
    Schedule.prototype.getViewIndex = function (viewName) {
        for (var item = 0; item < this.viewCollections.length; item++) {
            var checkIndex = this.viewCollections[parseInt(item.toString(), 10)].option;
            if (checkIndex === viewName) {
                return item;
            }
        }
        return -1;
    };
    Schedule.prototype.setViewOptions = function (isModuleLoad) {
        if (isModuleLoad === void 0) { isModuleLoad = false; }
        this.viewOptions = {};
        this.viewCollections = [];
        var viewName;
        var selectedView;
        var prevIndex = this.viewIndex;
        var count = 0;
        this.viewIndex = -1;
        for (var _i = 0, _a = this.views; _i < _a.length; _i++) {
            var view = _a[_i];
            var isOptions = (typeof view === 'string') ? false : true;
            if (typeof view === 'string') {
                viewName = view;
                if (this.currentView === viewName) {
                    selectedView = viewName;
                    this.viewIndex = count;
                }
            }
            else {
                viewName = view.option;
                if (view.isSelected) {
                    selectedView = viewName;
                    this.viewIndex = count;
                }
            }
            var obj = extend({ option: viewName }, isOptions ? view : {});
            var fieldViewName = viewName.charAt(0).toLowerCase() + viewName.slice(1);
            obj.cellHeaderTemplateName = obj.cellHeaderTemplate ? obj.option : '';
            obj.dateHeaderTemplateName = obj.dateHeaderTemplate ? obj.option : '';
            obj.dateRangeTemplateName = obj.dateRangeTemplate ? obj.option : '';
            obj.cellTemplateName = obj.cellTemplate ? obj.option : '';
            obj.dayHeaderTemplateName = obj.dayHeaderTemplate ? obj.option : '';
            obj.monthHeaderTemplateName = obj.monthHeaderTemplate ? obj.option : '';
            obj.resourceHeaderTemplateName = obj.resourceHeaderTemplate ? obj.option : '';
            obj.headerIndentTemplateName = obj.headerIndentTemplate ? obj.option : '';
            obj.eventTemplateName = obj.eventTemplate ? obj.option : '';
            if (!isNullOrUndefined(obj.firstDayOfWeek) && obj.firstDayOfWeek === 0) {
                delete obj.firstDayOfWeek;
            }
            if (!isNullOrUndefined(obj.interval) && obj.interval === 1) {
                delete obj.interval;
            }
            this.viewCollections.push(obj);
            if (isNullOrUndefined(this.viewOptions["" + fieldViewName])) {
                this.viewOptions["" + fieldViewName] = [obj];
            }
            else {
                this.viewOptions["" + fieldViewName].push(obj);
            }
            count++;
        }
        if (!isModuleLoad && selectedView) {
            this.setProperties({ currentView: selectedView }, true);
        }
        if (this.viewIndex === -1) {
            var currentIndex = this.getViewIndex(this.currentView);
            this.viewIndex = ((typeof this.views[0] !== 'string') && (!isNullOrUndefined(prevIndex) && prevIndex !== -1)) ? prevIndex :
                (currentIndex === -1) ? 0 : currentIndex;
        }
    };
    Schedule.prototype.getActiveViewOptions = function () {
        var timeScale = {
            enable: this.timeScale.enable,
            interval: this.timeScale.interval,
            slotCount: this.timeScale.slotCount,
            majorSlotTemplate: this.timeScale.majorSlotTemplate,
            minorSlotTemplate: this.timeScale.minorSlotTemplate
        };
        var isYearView = this.viewCollections[this.viewIndex].option.indexOf('Year') > -1;
        var group = {
            byDate: isYearView ? false : this.group.byDate,
            byGroupID: this.group.byGroupID,
            allowGroupEdit: this.group.allowGroupEdit,
            resources: this.group.resources,
            headerTooltipTemplate: this.group.headerTooltipTemplate,
            enableCompactView: this.group.enableCompactView,
            hideNonWorkingDays: ['Day', 'Week', 'WorkWeek', 'Month'].indexOf(this.currentView) > -1 ? this.group.hideNonWorkingDays : false
        };
        var workDays = this.viewCollections[this.viewIndex].workDays ? [] : this.workDays;
        var scheduleOptions = {
            dateFormat: this.dateFormat,
            endHour: this.endHour,
            isSelected: false,
            option: null,
            readonly: this.readonly,
            startHour: this.startHour,
            allowVirtualScrolling: false,
            cellHeaderTemplate: this.cellHeaderTemplate,
            dayHeaderTemplate: this.dayHeaderTemplate,
            monthHeaderTemplate: this.monthHeaderTemplate,
            cellTemplate: this.cellTemplate,
            eventTemplate: this.eventSettings.template,
            dateHeaderTemplate: this.dateHeaderTemplate,
            dateRangeTemplate: this.dateRangeTemplate,
            resourceHeaderTemplate: this.resourceHeaderTemplate,
            headerIndentTemplate: this.headerIndentTemplate,
            firstMonthOfYear: this.firstMonthOfYear,
            firstDayOfWeek: this.firstDayOfWeek,
            workDays: workDays,
            monthsCount: this.monthsCount,
            showWeekend: this.showWeekend,
            showWeekNumber: this.showWeekNumber,
            displayName: null,
            interval: 1,
            timeScale: timeScale,
            timeFormat: this.internalTimeFormat,
            group: group,
            headerRows: this.headerRows,
            orientation: 'Horizontal',
            numberOfWeeks: 0,
            displayDate: null,
            enableLazyLoading: false
        };
        var viewOptions = this.viewCollections[this.viewIndex];
        var viewsData = extend(scheduleOptions, viewOptions, undefined, true);
        if (this.firstDayOfWeek !== 0 && viewOptions.firstDayOfWeek && this.firstDayOfWeek !== viewOptions.firstDayOfWeek) {
            viewsData.firstDayOfWeek = this.firstDayOfWeek;
        }
        if (viewsData.displayDate) {
            viewsData.displayDate = viewsData.displayDate instanceof Date ? new Date(viewsData.displayDate.getTime()) :
                new Date(viewsData.displayDate);
        }
        if (viewsData.enableLazyLoading && !isNullOrUndefined(viewsData.group.resources) && viewsData.group.resources.length > 0 &&
            (['Agenda', 'MonthAgenda', 'Year', 'TimelineYear'].indexOf(viewsData.option) === -1 ||
                (viewsData.option === 'TimelineYear' && viewsData.orientation === 'Vertical'))) {
            viewsData.allowVirtualScrolling = true;
        }
        return viewsData;
    };
    Schedule.prototype.initializeDataModule = function () {
        this.eventFields = {
            id: this.eventSettings.fields.id,
            isBlock: this.eventSettings.fields.isBlock,
            subject: this.eventSettings.fields.subject.name,
            startTime: this.eventSettings.fields.startTime.name,
            endTime: this.eventSettings.fields.endTime.name,
            startTimezone: this.eventSettings.fields.startTimezone.name,
            endTimezone: this.eventSettings.fields.endTimezone.name,
            location: this.eventSettings.fields.location.name,
            description: this.eventSettings.fields.description.name,
            isAllDay: this.eventSettings.fields.isAllDay.name,
            recurrenceID: this.eventSettings.fields.recurrenceID.name,
            recurrenceRule: this.eventSettings.fields.recurrenceRule.name,
            recurrenceException: this.eventSettings.fields.recurrenceException.name,
            isReadonly: this.eventSettings.fields.isReadonly,
            followingID: this.eventSettings.fields.followingID
        };
        this.setEditorTitles();
        this.dataModule = new Data(this, this.eventSettings.dataSource, this.eventSettings.query);
        this.crudModule = new Crud(this);
    };
    Schedule.prototype.setEditorTitles = function () {
        this.editorTitles = {
            subject: this.eventSettings.fields.subject.title || this.localeObj.getConstant('title'),
            startTime: this.eventSettings.fields.startTime.title || this.localeObj.getConstant('start'),
            endTime: this.eventSettings.fields.endTime.title || this.localeObj.getConstant('end'),
            isAllDay: this.eventSettings.fields.isAllDay.title || this.localeObj.getConstant('allDay'),
            startTimezone: this.eventSettings.fields.startTimezone.title || this.localeObj.getConstant('startTimezone'),
            endTimezone: this.eventSettings.fields.endTimezone.title || this.localeObj.getConstant('endTimezone'),
            location: this.eventSettings.fields.location.title || this.localeObj.getConstant('location'),
            description: this.eventSettings.fields.description.title || this.localeObj.getConstant('description'),
            recurrenceRule: this.eventSettings.fields.recurrenceRule.title || this.localeObj.getConstant('repeat')
        };
    };
    Schedule.prototype.initializeView = function (viewName) {
        this.showSpinner();
        this.activeViewOptions = this.getActiveViewOptions();
        if (this.resourceBase) {
            this.resourceBase.setResourceCollection();
        }
        this.initializeTemplates();
        this.renderModule.render(viewName);
    };
    Schedule.prototype.initializeTemplates = function () {
        this.cellHeaderTemplateFn = this.templateParser(this.activeViewOptions.cellHeaderTemplate);
        this.dayHeaderTemplateFn = this.templateParser(this.activeViewOptions.dayHeaderTemplate);
        this.monthHeaderTemplateFn = this.templateParser(this.activeViewOptions.monthHeaderTemplate);
        this.cellTemplateFn = this.templateParser(this.activeViewOptions.cellTemplate);
        this.dateHeaderTemplateFn = this.templateParser(this.activeViewOptions.dateHeaderTemplate);
        this.dateRangeTemplateFn = this.templateParser(this.activeViewOptions.dateRangeTemplate);
        this.majorSlotTemplateFn = this.templateParser(this.activeViewOptions.timeScale.majorSlotTemplate);
        this.minorSlotTemplateFn = this.templateParser(this.activeViewOptions.timeScale.minorSlotTemplate);
        this.appointmentTemplateFn = this.templateParser(this.activeViewOptions.eventTemplate);
        this.resourceHeaderTemplateFn = this.templateParser(this.activeViewOptions.resourceHeaderTemplate);
        this.headerIndentTemplateFn = this.templateParser(this.activeViewOptions.headerIndentTemplate);
        this.headerTooltipTemplateFn = this.templateParser(this.activeViewOptions.group.headerTooltipTemplate);
        this.eventTooltipTemplateFn = this.templateParser(this.eventSettings.tooltipTemplate);
        this.editorTemplateFn = this.templateParser(this.editorTemplate);
        this.editorHeaderTemplateFn = this.templateParser(this.editorHeaderTemplate);
        this.editorFooterTemplateFn = this.templateParser(this.editorFooterTemplate);
        this.quickInfoTemplatesHeaderFn = this.templateParser(this.quickInfoTemplates.header);
        this.quickInfoTemplatesContentFn = this.templateParser(this.quickInfoTemplates.content);
        this.quickInfoTemplatesFooterFn = this.templateParser(this.quickInfoTemplates.footer);
    };
    Schedule.prototype.initializePopups = function () {
        this.eventWindow = new EventWindow(this);
        this.quickPopup = new QuickPopups(this);
    };
    /**
     * Method to get day names
     *
     * @param {string} type Accepts the day name
     * @returns {string[]} Returns the collection of day names
     * @private
     */
    Schedule.prototype.getDayNames = function (type) {
        var culShortNames = [];
        var cldrObj;
        var nameSpace = '';
        if (this.locale === 'en' || this.locale === 'en-US') {
            nameSpace = 'days.stand-alone.';
            cldrObj = (getValue(nameSpace + type, getDefaultDateObject(this.getCalendarMode())));
        }
        else {
            nameSpace = 'main.' + '' + this.locale + '.dates.calendars.' + this.getCalendarMode() + '.days.format.' + type;
            cldrObj = (getValue(nameSpace, cldrData));
        }
        for (var _i = 0, _a = Object.keys(cldrObj); _i < _a.length; _i++) {
            var obj = _a[_i];
            culShortNames.push(getValue(obj, cldrObj));
        }
        return culShortNames;
    };
    Schedule.prototype.setCldrTimeFormat = function () {
        if (!isNullOrUndefined(this.timeFormat)) {
            this.internalTimeFormat = this.timeFormat;
            return;
        }
        if (this.locale === 'en' || this.locale === 'en-US') {
            this.internalTimeFormat = (getValue('timeFormats.short', getDefaultDateObject(this.getCalendarMode())));
        }
        else {
            this.internalTimeFormat = (getValue('main.' + '' + this.locale + '.dates.calendars.' + this.getCalendarMode() + '.timeFormats.short', cldrData));
        }
    };
    /**
     * Method to get calendar mode
     *
     * @returns {string} Returns the calendar mode
     * @private
     */
    Schedule.prototype.getCalendarMode = function () {
        return this.calendarMode.toLowerCase();
    };
    /**
     * Method to get time in string
     *
     * @param {Date} date Accepts the date object
     * @returns {string} Returns the time in string
     * @private
     */
    Schedule.prototype.getTimeString = function (date) {
        var time = this.globalize.formatDate(date, {
            format: this.activeViewOptions.timeFormat,
            type: 'time', calendar: this.getCalendarMode()
        });
        return time.toLocaleUpperCase();
    };
    /**
     * Method to get  date object
     *
     * @param {Date} date Accepts the date object
     * @returns {Date} Returns the date object
     * @private
     */
    Schedule.prototype.getDateTime = function (date) {
        return date instanceof Date ? new Date(date.getTime()) : new Date(date);
    };
    Schedule.prototype.setCalendarMode = function () {
        if (this.calendarMode === 'Islamic') {
            this.calendarUtil = new Islamic();
        }
        else {
            this.calendarUtil = new Gregorian();
        }
    };
    /**
     * Method to change the current view
     *
     * @param {View} view Accepts the view name
     * @param {Event} event Accepts the event object
     * @param {boolean} muteOnChange Accepts the value to enable or disable mute on change
     * @param {number} index Accepts the index value
     * @returns {void}
     * @private
     */
    Schedule.prototype.changeView = function (view, event, muteOnChange, index) {
        var _this = this;
        if (isNullOrUndefined(index)) {
            index = this.getViewIndex(view);
        }
        if (!muteOnChange && index === this.viewIndex && this.currentView === view || index < 0) {
            return;
        }
        var previousView = this.activeViewOptions ? this.activeViewOptions.option : this.currentView;
        var args = { requestType: 'viewNavigate', cancel: false, event: event };
        this.trigger(actionBegin, args, function (actionArgs) {
            if (!actionArgs.cancel) {
                var navArgs = {
                    action: 'view', cancel: false, currentDate: _this.selectedDate, previousView: previousView, currentView: view, viewIndex: index
                };
                _this.trigger(navigating, navArgs, function (navigationArgs) {
                    if (!navigationArgs.cancel) {
                        var isVertical = ['Day', 'Week', 'WorkWeek'].indexOf(view) > -1 && ['Day', 'Week', 'WorkWeek'].indexOf(previousView) < 0;
                        _this.uiStateValues.isInitial = isVertical || view.indexOf('Timeline') > -1 || view.indexOf('Year') > -1;
                        _this.uiStateValues.top = view.indexOf('Timeline') > -1 && previousView.indexOf('Timeline') < 0 ? 0 : _this.uiStateValues.top;
                        _this.viewIndex = navigationArgs.viewIndex;
                        _this.setProperties({ currentView: view }, true);
                        if (_this.headerModule) {
                            _this.headerModule.updateActiveView();
                            _this.headerModule.setCalendarDate(_this.selectedDate);
                            _this.headerModule.setCalendarView();
                        }
                        _this.initializeView(_this.currentView);
                        _this.animateLayout();
                        args = { requestType: 'viewNavigate', cancel: false, event: event };
                        _this.trigger(actionComplete, args);
                    }
                    else {
                        _this.currentView = previousView;
                    }
                });
            }
            else {
                _this.currentView = previousView;
            }
        });
    };
    /**
     * Method to change the view date
     *
     * @param {Date} selectedDate Accepts the selected date
     * @param {Event} event Accepts the event object
     * @returns {void}
     * @private
     */
    Schedule.prototype.changeDate = function (selectedDate, event) {
        var _this = this;
        var args = { requestType: 'dateNavigate', cancel: false, event: event };
        this.trigger(actionBegin, args, function (actionArgs) {
            if (!actionArgs.cancel) {
                var navArgs = {
                    action: 'date', cancel: false, previousDate: _this.selectedDate, currentDate: selectedDate
                };
                _this.trigger(navigating, navArgs, function (navigationArgs) {
                    if (!navigationArgs.cancel) {
                        _this.uiStateValues.isInitial = _this.activeView.isTimelineView() && _this.currentView !== 'TimelineYear';
                        _this.validateDate(navigationArgs.currentDate);
                        if (_this.headerModule) {
                            _this.headerModule.setCalendarDate(navigationArgs.currentDate);
                        }
                        if (_this.currentView === 'MonthAgenda' && _this.monthAgendaModule) {
                            _this.monthAgendaModule.monthAgendaDate = new Date('' + _this.selectedDate);
                        }
                        _this.initializeView(_this.currentView);
                        _this.animateLayout();
                        args = { requestType: 'dateNavigate', cancel: false, event: event };
                        _this.trigger(actionComplete, args);
                    }
                });
            }
        });
    };
    /**
     * Method to validate min and max date
     *
     * @param {Date} date Accepts the date object
     * @returns {boolean} Returns the boolean result to validate the min and max date
     * @private
     */
    Schedule.prototype.isMinMaxDate = function (date) {
        if (date === void 0) { date = this.selectedDate; }
        return ((date.getTime() >= this.minDate.getTime()) && (date.getTime() <= this.maxDate.getTime()));
    };
    /**
     * Method to validate the selected date
     *
     * @param {Date} date Accepts the date object
     * @returns {boolean} Returns the boolean value for given date is selected date or not
     * @private
     */
    Schedule.prototype.isSelectedDate = function (date) {
        return date.setHours(0, 0, 0, 0) === new Date('' + this.selectedDate).setHours(0, 0, 0, 0);
    };
    /**
     * Method to get the current time
     *
     * @param {Date} date Accepts the date object
     * @returns {Date} Returns the date object after performing the timezone conversion
     * @private
     */
    Schedule.prototype.getCurrentTime = function (date) {
        if (date === void 0) { date = new Date(); }
        if (this.timezone) {
            return this.tzModule.convert(date, this.tzModule.getLocalTimezoneName(), this.timezone);
        }
        return date;
    };
    /** Method to get navigate view
     *
     * @returns {View} Return the navigate view name
     * @private
     */
    Schedule.prototype.getNavigateView = function () {
        if (this.activeView.isTimelineView()) {
            return this.currentView === 'TimelineMonth' || this.currentView === 'TimelineYear' ? 'TimelineDay' : 'Agenda';
        }
        return 'Day';
    };
    Schedule.prototype.animateLayout = function () {
        if (!this.activeView.element) {
            return;
        }
        new Animation({ duration: 600, name: 'FadeIn', timingFunction: 'easeIn' }).animate(this.activeView.element);
    };
    /**
     * To provide the array of modules needed for control rendering
     *
     * @returns {ModuleDeclaration[]} Returns the declared modules
     * @private
     */
    Schedule.prototype.requiredModules = function () {
        var modules = [];
        this.setViewOptions(true);
        for (var _i = 0, _a = Object.keys(this.viewOptions); _i < _a.length; _i++) {
            var view = _a[_i];
            view = (view === 'timelineDay' || view === 'timelineWeek' || view === 'timelineWorkWeek') ? 'timelineViews' : view;
            modules.push({ member: view, args: [this] });
        }
        if (this.allowDragAndDrop) {
            modules.push({ member: 'dragAndDrop', args: [this] });
        }
        if (this.allowResizing) {
            modules.push({ member: 'resize', args: [this] });
        }
        modules.push({ member: 'excelExport', args: [this] });
        modules.push({ member: 'iCalendarExport', args: [this] });
        modules.push({ member: 'iCalendarImport', args: [this] });
        modules.push({ member: 'print', args: [this] });
        return modules;
    };
    /**
     * Initializes the values of private members.
     *
     * @returns {void}
     * @private
     */
    Schedule.prototype.preRender = function () {
        this.isAdaptive = Browser.isDevice || isIPadDevice();
        this.globalize = new Internationalization(this.locale);
        this.tzModule = new Timezone();
        if (this && isNullOrUndefined(this.uiStateValues) || !(this.enablePersistence)) {
            this.uiStateValues = {
                expand: false, isInitial: true, left: 0, top: 0, isGroupAdaptive: false,
                isIgnoreOccurrence: false, groupIndex: 0, action: false, isBlock: false, isCustomMonth: true, isPreventTimezone: false
            };
        }
        this.currentTimezoneDate = this.getCurrentTime();
        this.activeCellsData = {
            startTime: new Date(this.currentTimezoneDate),
            endTime: new Date(this.currentTimezoneDate),
            isAllDay: false
        };
        this.activeEventData = { event: undefined, element: undefined };
        this.getDefaultLocale();
        this.localeObj = new L10n(this.getModuleName(), this.defaultLocale, this.locale);
        this.setCldrTimeFormat();
        this.setCalendarMode();
        this.eventsData = [];
        this.eventsProcessed = [];
        this.blockData = [];
        this.blockProcessed = [];
        this.resourceCollection = [];
        this.currentAction = null;
        this.selectedElements = [];
        this.setViewOptions();
    };
    Schedule.prototype.getDefaultLocale = function () {
        this.defaultLocale = {
            day: 'Day',
            week: 'Week',
            workWeek: 'Work Week',
            month: 'Month',
            year: 'Year',
            agenda: 'Agenda',
            weekAgenda: 'Week Agenda',
            workWeekAgenda: 'Work Week Agenda',
            monthAgenda: 'Month Agenda',
            today: 'Today',
            noEvents: 'No events',
            emptyContainer: 'There are no events scheduled on this day.',
            allDay: 'All day',
            start: 'Start',
            end: 'End',
            more: 'more',
            close: 'Close',
            cancel: 'Cancel',
            noTitle: '(No Title)',
            delete: 'Delete',
            deleteEvent: 'Delete Event',
            deleteMultipleEvent: 'Delete Multiple Events',
            selectedItems: 'Items selected',
            deleteSeries: 'Entire Series',
            edit: 'Edit',
            editSeries: 'Entire Series',
            editEvent: 'Edit Event',
            createEvent: 'Create',
            subject: 'Subject',
            addTitle: 'Add title',
            moreDetails: 'More Details',
            save: 'Save',
            editContent: 'How would you like to change the appointment in the series?',
            deleteContent: 'Are you sure you want to delete this event?',
            deleteMultipleContent: 'Are you sure you want to delete the selected events?',
            newEvent: 'New Event',
            title: 'Title',
            location: 'Location',
            description: 'Description',
            timezone: 'Timezone',
            startTimezone: 'Start Timezone',
            endTimezone: 'End Timezone',
            repeat: 'Repeat',
            saveButton: 'Save',
            cancelButton: 'Cancel',
            deleteButton: 'Delete',
            recurrence: 'Recurrence',
            wrongPattern: 'The recurrence pattern is not valid.',
            seriesChangeAlert: 'Do you want to cancel the changes made to specific ' +
                'instances of this series and match it to the whole series again?',
            createError: 'The duration of the event must be shorter than how frequently it occurs. ' +
                'Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
            sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
            occurenceAlert: 'Cannot reschedule an occurrence of the recurring appointment if it skips over ' +
                'a later occurrence of the same appointment.',
            editRecurrence: 'Edit Recurrence',
            recurringEvent: 'Recurring Event',
            repeats: 'Repeats',
            alert: 'Alert',
            startEndError: 'The selected end date occurs before the start date.',
            invalidDateError: 'The entered date value is invalid.',
            blockAlert: 'Events cannot be scheduled within the blocked time range.',
            ok: 'Ok',
            yes: 'Yes',
            no: 'No',
            occurrence: 'Occurrence',
            series: 'Series',
            previous: 'Previous',
            next: 'Next',
            timelineDay: 'Timeline Day',
            timelineWeek: 'Timeline Week',
            timelineWorkWeek: 'Timeline Work Week',
            timelineMonth: 'Timeline Month',
            timelineYear: 'Timeline Year',
            editFollowingEvent: 'Following Events',
            deleteTitle: 'Delete Event',
            editTitle: 'Edit Event',
            beginFrom: 'Begin From',
            endAt: 'Ends At',
            expandAllDaySection: 'Expand-all-day-section',
            collapseAllDaySection: 'Collapse-all-day-section',
            searchTimezone: 'Search Timezone',
            noRecords: 'No records found'
        };
    };
    Schedule.prototype.wireEvents = function () {
        EventHandler.add(window, 'resize', this.onScheduleResize, this);
        EventHandler.add(window, 'orientationchange', this.onScheduleResize, this);
        EventHandler.add(document, Browser.touchStartEvent, this.onDocumentClick, this);
    };
    /**
     * Method to remove selected class
     *
     * @returns {void}
     * @private
     */
    Schedule.prototype.removeSelectedClass = function () {
        var selectedCells = this.getSelectedCells();
        for (var _i = 0, selectedCells_1 = selectedCells; _i < selectedCells_1.length; _i++) {
            var cell = selectedCells_1[_i];
            if (this.currentView !== 'Year') {
                cell.setAttribute('aria-selected', 'false');
            }
            cell.removeAttribute('tabindex');
        }
        removeClass(selectedCells, SELECTED_CELL_CLASS);
        if (this.keyboardInteractionModule && this.keyboardInteractionModule.selectedCells.length > 0) {
            this.keyboardInteractionModule.selectedCells = [];
        }
    };
    /**
     * Method to add selected class
     *
     * @param {HTMLTableCellElement[]} cells Accepts the collection of elements
     * @param {HTMLTableCellElement} focusCell Accepts the focus element
     * @param {boolean} isPreventScroll Accepts the boolean value to prevent scroll
     * @returns {void}
     * @private
     */
    Schedule.prototype.addSelectedClass = function (cells, focusCell, isPreventScroll) {
        if (this.currentView !== 'Year') {
            for (var _i = 0, cells_1 = cells; _i < cells_1.length; _i++) {
                var cell = cells_1[_i];
                cell.setAttribute('aria-selected', 'true');
            }
        }
        addClass(cells, SELECTED_CELL_CLASS);
        if (focusCell) {
            focusCell.setAttribute('tabindex', '0');
            focusCell.focus({ preventScroll: isPreventScroll || false });
        }
    };
    /**
     * Method to select cell
     *
     * @param {HTMLElement | HTMLTableCellElement} element Accepts the select element
     * @returns {void}
     * @private
     */
    Schedule.prototype.selectCell = function (element) {
        this.removeSelectedClass();
        this.addSelectedClass([element], element);
    };
    /**
     * Method to get all day row element
     *
     * @returns {Element} Returns the all day row element
     * @private
     */
    Schedule.prototype.getAllDayRow = function () {
        return this.element.querySelector('.' + ALLDAY_ROW_CLASS);
    };
    /**
     * Method to get content table element
     *
     * @returns {HTMLElement} Returns the content table element
     * @private
     */
    Schedule.prototype.getContentTable = function () {
        return this.activeView.element.querySelector('.' + CONTENT_TABLE_CLASS + ' tbody');
    };
    /**
     * Method to get all content table rows
     *
     * @returns {HTMLElement[]} Returns the content table rows
     * @private
     */
    Schedule.prototype.getTableRows = function () {
        return [].slice.call(this.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody tr:not(.' + HIDDEN_CLASS + ')'));
    };
    /**
     * Method to get work cell elements
     *
     * @returns {Element[]} Returns the all work cell elements
     * @private
     */
    Schedule.prototype.getWorkCellElements = function () {
        return [].slice.call(this.element.querySelectorAll('.' + WORK_CELLS_CLASS));
    };
    /**
     * Method to get the index from date collection
     *
     * @param {Date[]} collection Accepts the collections of date
     * @param {Date} date Accepts the date object
     * @returns {number} Returns the index compared date with date collections
     * @private
     */
    Schedule.prototype.getIndexOfDate = function (collection, date) {
        return collection.map(Number).indexOf(+date);
    };
    /**
     * Method to find all day cell
     *
     * @param {Element} td Accepts the DOM Element
     * @returns {boolean} Returns the boolean value
     * @private
     */
    Schedule.prototype.isAllDayCell = function (td) {
        if (['Month', 'TimelineMonth', 'TimelineYear', 'MonthAgenda'].indexOf(this.currentView) > -1 ||
            td.classList.contains(ALLDAY_CELLS_CLASS) ||
            td.classList.contains(HEADER_CELLS_CLASS) || !this.activeViewOptions.timeScale.enable) {
            return true;
        }
        if (this.activeView.isTimelineView() && this.activeViewOptions.headerRows.length > 0 &&
            this.activeViewOptions.headerRows.slice(-1)[0].option !== 'Hour') {
            return true;
        }
        return false;
    };
    /**
     * Method to get date from element
     *
     * @param {Element} td Accepts the DOM element
     * @returns {Date} Returns the date object
     * @private
     */
    Schedule.prototype.getDateFromElement = function (td) {
        var dateString;
        if (!isNullOrUndefined(td)) {
            dateString = td.getAttribute('data-date');
        }
        if (!isNullOrUndefined(dateString)) {
            var dateInMS = parseInt(dateString, 10);
            var date = new Date(dateInMS);
            return date;
        }
        return undefined;
    };
    /**
     * Method to get target element from given selector
     *
     * @param {string} selector Accepts the element selector
     * @param {number} left Accepts the pageX value
     * @param {number} top Accepts the pageY value
     * @returns {Element[]} Returns the collection of elements based on the given selector
     * @private
     */
    Schedule.prototype.getTargetElement = function (selector, left, top) {
        var element = document.elementFromPoint(left, top);
        var targetElement;
        if (element) {
            targetElement = element.closest(selector);
        }
        return (targetElement) ? [targetElement] : null;
    };
    /**
     * Method to process cell header template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getCellHeaderTemplate = function () {
        return this.cellHeaderTemplateFn;
    };
    /**
     * Method to process cell header template in year view.
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getDayHeaderTemplate = function () {
        return this.dayHeaderTemplateFn;
    };
    /**
     * Method to process cell header template in year view.
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getMonthHeaderTemplate = function () {
        return this.monthHeaderTemplateFn;
    };
    /**
     * Method to process cell template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getCellTemplate = function () {
        return this.cellTemplateFn;
    };
    /**
     * Method to process date header template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getDateHeaderTemplate = function () {
        return this.dateHeaderTemplateFn;
    };
    /**
     * Method to process date range template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getDateRangeTemplate = function () {
        return this.dateRangeTemplateFn;
    };
    /**
     * Method to process major slot template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getMajorSlotTemplate = function () {
        return this.majorSlotTemplateFn;
    };
    /**
     * Method to process minor slot template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getMinorSlotTemplate = function () {
        return this.minorSlotTemplateFn;
    };
    /**
     * Method to process appointment template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getAppointmentTemplate = function () {
        return this.appointmentTemplateFn;
    };
    /**
     * Method to process appointment tooltip template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getEventTooltipTemplate = function () {
        return this.eventTooltipTemplateFn;
    };
    /**
     * Method to process header tooltip template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getHeaderTooltipTemplate = function () {
        return this.headerTooltipTemplateFn;
    };
    /**
     * Method to process editor template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getEditorTemplate = function () {
        return this.editorTemplateFn;
    };
    /**
     * Method to process editor header template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getEditorHeaderTemplate = function () {
        return this.editorHeaderTemplateFn;
    };
    /**
     * Method to process editor footer template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getEditorFooterTemplate = function () {
        return this.editorFooterTemplateFn;
    };
    /**
     * Method to process quick info header template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getQuickInfoTemplatesHeader = function () {
        return this.quickInfoTemplatesHeaderFn;
    };
    /**
     * Method to process quick info content template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getQuickInfoTemplatesContent = function () {
        return this.quickInfoTemplatesContentFn;
    };
    /**
     * Method to process quick info footer template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getQuickInfoTemplatesFooter = function () {
        return this.quickInfoTemplatesFooterFn;
    };
    /**
     * Method to process resource header template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getResourceHeaderTemplate = function () {
        return this.resourceHeaderTemplateFn;
    };
    /**
     * Method to process indent template
     *
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.getHeaderIndentTemplate = function () {
        return this.headerIndentTemplateFn;
    };
    /**
     * Method to get dynamic CSS properties
     *
     * @returns {ScrollCss} Returns the CSS properties dynamically
     * @private
     */
    Schedule.prototype.getCssProperties = function () {
        var cssProps = {
            border: this.enableRtl ? 'borderLeftWidth' : 'borderRightWidth',
            padding: this.enableRtl ? 'paddingLeft' : 'paddingRight',
            rtlBorder: this.enableRtl ? 'borderRightWidth' : 'borderLeftWidth',
            rtlPadding: this.enableRtl ? 'paddingRight' : 'paddingLeft'
        };
        return cssProps;
    };
    /**
     * Method to remove new event element in adaptive mode
     *
     * @returns {void}
     * @private
     */
    Schedule.prototype.removeNewEventElement = function () {
        var eventClone = this.element.querySelector('.' + NEW_EVENT_CLASS);
        if (!isNullOrUndefined(eventClone)) {
            remove(eventClone);
        }
    };
    /**
     * Method to get start end time from string
     *
     * @param {string} startEndTime Accepts the start end time string value
     * @returns {Date} Returns the date object
     * @private
     */
    Schedule.prototype.getStartEndTime = function (startEndTime) {
        if (!isNullOrUndefined(startEndTime) && startEndTime !== '') {
            var startEndDate = resetTime(new Date(this.currentTimezoneDate) || this.getCurrentTime());
            var timeString = startEndTime.split(':');
            if (timeString.length === 2) {
                startEndDate.setHours(parseInt(timeString[0], 10), parseInt(timeString[1], 10), 0);
            }
            return startEndDate;
        }
        return null;
    };
    Schedule.prototype.onDocumentClick = function (args) {
        this.notify(documentClick, { event: args });
    };
    Schedule.prototype.onScheduleResize = function () {
        if (isNullOrUndefined(this.activeView) || ((this.isAdaptive || isMobile()) && document.activeElement
            && document.activeElement.classList.contains(SUBJECT_CLASS))) {
            return;
        }
        if (this.activeViewOptions.timeScale.enable && this.activeView) {
            this.activeView.highlightCurrentTime();
        }
        if (this.quickPopup) {
            this.quickPopup.onClosePopup();
        }
        resetScrollbarWidth();
        if (this.currentView === 'Month' || ((this.currentView !== 'Agenda' && this.currentView !== 'MonthAgenda')
            && !this.activeViewOptions.timeScale.enable) || this.activeView.isTimelineView()) {
            this.activeView.resetColWidth();
            this.notify(scrollUiUpdate, { cssProperties: this.getCssProperties(), isPreventScrollUpdate: true });
            this.refreshEvents(false);
        }
        else {
            this.notify(contentReady, {});
        }
    };
    /**
     * Method to process the templates
     *
     * @param {string | Function} template Accepts the template in string
     * @returns {CallbackFunction} Returns the callback function
     * @private
     */
    Schedule.prototype.templateParser = function (template) {
        if (template) {
            try {
                if (typeof template === 'function') {
                    return compile(template);
                }
                else {
                    if (document.querySelectorAll(template).length) {
                        return compile(document.querySelector(template).innerHTML.trim());
                    }
                    else {
                        return compile(template);
                    }
                }
            }
            catch (error) {
                return compile(template);
            }
        }
        return undefined;
    };
    /**
     * Retrieves the selected cells.
     *
     * @returns {Element[]} The elements of currently selected cells will be returned.
     * @private
     */
    Schedule.prototype.getSelectedCells = function () {
        return [].slice.call(this.element.querySelectorAll('.' + SELECTED_CELL_CLASS));
    };
    /**
     * Method to generate the announcement string
     *
     * @param {Object} event Accepts the event object
     * @param {string} subject Accepts the subject text
     * @returns {string} Returns the announcement string
     * @private
     */
    Schedule.prototype.getAnnouncementString = function (event, subject) {
        var resourceName;
        if (this.quickPopup && this.activeViewOptions.group.resources.length > 0) {
            var constantText = '"s event - ';
            resourceName = this.quickPopup.getResourceText({ event: event }, 'event') + constantText;
        }
        var recordSubject = (subject || (event[this.eventFields.subject] || this.eventSettings.fields.subject.default));
        var recordLocation = (event[this.eventFields.location] || this.eventSettings.fields.location.default);
        var skeleton = 'full';
        var startDateText = this.globalize.formatDate(event[this.eventFields.startTime], {
            type: 'dateTime', skeleton: skeleton, calendar: this.getCalendarMode()
        });
        var endDateText = this.globalize.formatDate(event[this.eventFields.endTime], {
            type: 'dateTime', skeleton: skeleton, calendar: this.getCalendarMode()
        });
        var announcementString = recordSubject + ' ' + this.localeObj.getConstant('beginFrom') + ' '
            + startDateText + ' ' + this.localeObj.getConstant('endAt') + ' ' + endDateText;
        if (resourceName) {
            announcementString = resourceName + ' ' + announcementString;
        }
        if (recordLocation && recordLocation !== '') {
            announcementString = announcementString + ' ' + this.localeObj.getConstant('location') + ' ' + recordLocation;
        }
        if (event[this.eventFields.recurrenceRule] && event[this.eventFields.recurrenceRule] !== ''
            && event[this.eventFields.id] === event[this.eventFields.recurrenceID]) {
            announcementString = announcementString + ' ' + this.localeObj.getConstant('recurringEvent');
        }
        return announcementString;
    };
    /**
     * Method to process the element boundary validation
     *
     * @param {number} pageY Accepts the pageY value
     * @param {number} pageX Accepts the pageX value
     * @returns {ResizeEdges} Returns the boundary validation object
     * @private
     */
    Schedule.prototype.boundaryValidation = function (pageY, pageX) {
        var autoScrollDistance = 30;
        var scrollEdges = { left: false, right: false, top: false, bottom: false };
        var viewBoundaries = this.element.querySelector('.' + CONTENT_WRAP_CLASS).getBoundingClientRect();
        if ((pageY < viewBoundaries.top + autoScrollDistance + window.pageYOffset) &&
            (pageY > viewBoundaries.top + window.pageYOffset)) {
            scrollEdges.top = true;
        }
        if ((pageY > (viewBoundaries.bottom - autoScrollDistance) + window.pageYOffset) &&
            (pageY < viewBoundaries.bottom + window.pageYOffset)) {
            scrollEdges.bottom = true;
        }
        if ((pageX < viewBoundaries.left + autoScrollDistance + window.pageXOffset) &&
            (pageX > viewBoundaries.left + window.pageXOffset)) {
            scrollEdges.left = true;
        }
        if ((pageX > (viewBoundaries.right - autoScrollDistance) + window.pageXOffset) &&
            (pageX < viewBoundaries.right + window.pageXOffset)) {
            scrollEdges.right = true;
        }
        return scrollEdges;
    };
    /**
     * Method to get the week number.
     *
     * @param {Date[]} dates Accepts the date collections
     * @returns {number} Returns the week number
     * @private
     */
    Schedule.prototype.getWeekNumberContent = function (dates) {
        var weekNumber;
        if (this.weekRule === 'FirstDay') {
            var weekNumberDate = getWeekLastDate(dates.slice(-1)[0], this.firstDayOfWeek);
            weekNumber = this.globalize.formatNumber(getWeekNumber(weekNumberDate));
        }
        else if (this.weekRule === 'FirstFourDayWeek') {
            var weekFirstDate = getWeekFirstDate(dates.slice(-1)[0], this.firstDayOfWeek);
            var weekLastDate = getWeekLastDate(dates.slice(-1)[0], this.firstDayOfWeek);
            var weekMidDate = getWeekMiddleDate(weekFirstDate, weekLastDate);
            weekNumber = this.globalize.formatNumber(getWeekNumber(weekMidDate));
        }
        else if (this.weekRule === 'FirstFullWeek') {
            var weekFirstDate = getWeekFirstDate(dates.slice(-1)[0], this.firstDayOfWeek);
            weekNumber = this.globalize.formatNumber(getWeekNumber(weekFirstDate));
        }
        return weekNumber;
    };
    /**
     * Method to render the header indent template.
     *
     * @param {TdData} data Accepts the td data
     * @param {Element} td Accepts the td element
     * @returns {void}
     * @private
     */
    Schedule.prototype.renderHeaderIndentTemplate = function (data, td) {
        if (this.activeViewOptions.headerIndentTemplate) {
            var scheduleId = this.element.id + '_';
            var viewName = this.activeViewOptions.headerIndentTemplateName;
            var templateId = scheduleId + viewName + 'headerIndentTemplate';
            var indentTemplate = [].slice.call(this.getHeaderIndentTemplate()(data, this, 'headerIndentTemplate', templateId, false));
            append(indentTemplate, td);
        }
    };
    /**
     * Method to check for refreshing the targeted resource row events.
     *
     * @returns {boolean} Returns the boolean value
     * @private
     */
    Schedule.prototype.isSpecificResourceEvents = function () {
        return this.activeViewOptions.group.resources.length > 0 && !this.activeViewOptions.group.allowGroupEdit &&
            !this.rowAutoHeight && !this.virtualScrollModule && this.activeViewOptions.group.byGroupID;
    };
    Schedule.prototype.unWireEvents = function () {
        EventHandler.remove(window, 'resize', this.onScheduleResize);
        EventHandler.remove(window, 'orientationchange', this.onScheduleResize);
        EventHandler.remove(document, Browser.touchStartEvent, this.onDocumentClick);
    };
    /**
     * Core method to return the component name.
     *
     * @returns {string} Returns the module name
     * @private
     */
    Schedule.prototype.getModuleName = function () {
        return 'schedule';
    };
    /**
     * Returns the properties to be maintained in the persisted state.
     *
     * @returns {string} Returns the persistance data
     * @private
     */
    Schedule.prototype.getPersistData = function () {
        return this.addOnPersist(['currentView', 'selectedDate', 'scrollTop', 'scrollLeft']);
    };
    /**
     * Called internally, if any of the property value changed.
     *
     * @returns {void}
     * @private
     */
    Schedule.prototype.onPropertyChanged = function (newProp, oldProp) {
        if (this.isReact && isNullOrUndefined(this.activeView)) {
            return;
        }
        var state = {
            isRefresh: false, isResource: false, isDate: false, isView: false, isLayout: false, isDataManager: false
        };
        for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
            var prop = _a[_i];
            switch (prop) {
                case 'views':
                    this.setViewOptions();
                    if (this.headerModule) {
                        this.headerModule.updateItems();
                    }
                    state.isView = true;
                    break;
                case 'currentView':
                    state.isView = true;
                    break;
                case 'minDate':
                case 'maxDate':
                case 'selectedDate':
                    state.isDate = true;
                    break;
                case 'dateFormat':
                    this.activeViewOptions = this.getActiveViewOptions();
                    if (this.headerModule) {
                        this.headerModule.updateDateRange();
                    }
                    break;
                case 'showHeaderBar':
                    this.destroyHeaderModule();
                    if (newProp.showHeaderBar) {
                        this.headerModule = new HeaderRenderer(this);
                        this.headerModule.updateDateRange();
                    }
                    this.notify(scrollUiUpdate, { cssProperties: this.getCssProperties() });
                    if (this.activeView.isTimelineView()) {
                        this.refreshEvents(false);
                    }
                    break;
                case 'workDays':
                    if (JSON.stringify(oldProp.workDays) !== JSON.stringify(newProp.workDays)) {
                        state.isLayout = true;
                    }
                    break;
                case 'showWeekend':
                case 'startHour':
                case 'endHour':
                case 'workHours':
                case 'readonly':
                case 'headerRows':
                case 'showWeekNumber':
                case 'rowAutoHeight':
                    state.isLayout = true;
                    break;
                case 'locale':
                case 'calendarMode':
                    this.globalize = new Internationalization(this.locale);
                    this.localeObj = new L10n(this.getModuleName(), this.defaultLocale, this.locale);
                    this.setCldrTimeFormat();
                    this.setCalendarMode();
                    state.isRefresh = true;
                    break;
                case 'firstDayOfWeek':
                    this.activeViewOptions.firstDayOfWeek = newProp.firstDayOfWeek;
                    if (this.eventWindow) {
                        this.eventWindow.refreshRecurrenceEditor();
                    }
                    state.isLayout = true;
                    break;
                case 'showTimeIndicator':
                    if (this.activeViewOptions.timeScale.enable && this.activeView) {
                        this.activeView.highlightCurrentTime();
                    }
                    break;
                case 'cellHeaderTemplate':
                    this.activeViewOptions.cellHeaderTemplate = newProp.cellHeaderTemplate;
                    this.cellHeaderTemplateFn = this.templateParser(this.activeViewOptions.cellHeaderTemplate);
                    state.isLayout = true;
                    break;
                case 'cellTemplate':
                    this.activeViewOptions.cellTemplate = newProp.cellTemplate;
                    this.cellTemplateFn = this.templateParser(this.activeViewOptions.cellTemplate);
                    state.isLayout = true;
                    break;
                case 'dateHeaderTemplate':
                    this.activeViewOptions.dateHeaderTemplate = newProp.dateHeaderTemplate;
                    this.dateHeaderTemplateFn = this.templateParser(this.activeViewOptions.dateHeaderTemplate);
                    state.isLayout = true;
                    break;
                case 'dateRangeTemplate':
                    this.activeViewOptions.dateRangeTemplate = newProp.dateRangeTemplate;
                    this.dateRangeTemplateFn = this.templateParser(this.activeViewOptions.dateRangeTemplate);
                    if (this.headerModule) {
                        this.headerModule.updateDateRange();
                    }
                    break;
                case 'dayHeaderTemplate':
                    this.activeViewOptions.dayHeaderTemplate = newProp.dayHeaderTemplate;
                    this.dayHeaderTemplateFn = this.templateParser(this.activeViewOptions.dayHeaderTemplate);
                    state.isLayout = true;
                    break;
                case 'monthHeaderTemplate':
                    this.activeViewOptions.monthHeaderTemplate = newProp.monthHeaderTemplate;
                    this.monthHeaderTemplateFn = this.templateParser(this.activeViewOptions.monthHeaderTemplate);
                    state.isLayout = true;
                    break;
                case 'resourceHeaderTemplate':
                    this.activeViewOptions.resourceHeaderTemplate = newProp.resourceHeaderTemplate;
                    this.resourceHeaderTemplateFn = this.templateParser(this.activeViewOptions.resourceHeaderTemplate);
                    state.isLayout = true;
                    break;
                case 'timezone':
                    this.eventBase.timezonePropertyChange(oldProp.timezone);
                    this.headerModule.setCalendarTimezone();
                    break;
                case 'enableRtl':
                    this.setRtlClass();
                    state.isRefresh = true;
                    break;
                default:
                    this.extendedPropertyChange(prop, newProp, oldProp, state);
                    break;
            }
        }
        this.propertyChangeAction(state);
    };
    Schedule.prototype.propertyChangeAction = function (state) {
        if (state.isRefresh) {
            this.refresh();
        }
        else if (state.isResource) {
            this.initializeResources(true);
        }
        else if (state.isView) {
            this.changeView(this.currentView, null, true);
        }
        else if (state.isDate) {
            this.changeDate(this.selectedDate);
        }
        else if (state.isLayout) {
            this.activeCellsData = null;
            this.initializeView(this.currentView);
        }
        else if (state.isDataManager && this.crudModule) {
            if (this.dragAndDropModule) {
                this.dragAndDropModule.actionObj.action = '';
                removeClass([this.element], EVENT_ACTION_CLASS);
            }
            this.crudModule.refreshDataManager();
        }
    };
    Schedule.prototype.allDayRowScrollUpdate = function () {
        var dateHeader = this.element.querySelector('.' + DATE_HEADER_WRAP_CLASS);
        var allDayRow = this.element.querySelector('.' + ALLDAY_ROW_CLASS);
        if (this.height === 'auto' || !this.enableAllDayScroll) {
            addClass([dateHeader], ALLDAY_APPOINTMENT_AUTO);
            if (dateHeader.classList.contains(ALLDAY_APPOINTMENT_SCROLL)) {
                removeClass([dateHeader], ALLDAY_APPOINTMENT_SCROLL);
            }
            if (this.uiStateValues.expand) {
                var allDayCells = [].slice.call(this.element.querySelectorAll('.' + ALLDAY_CELLS_CLASS));
                allDayCells[0].style.height = (allDayRow.style.height) ? allDayRow.style.height : allDayCells[1].style.height;
            }
        }
        else {
            if (dateHeader.classList.contains(ALLDAY_APPOINTMENT_AUTO)) {
                removeClass([dateHeader], ALLDAY_APPOINTMENT_AUTO);
            }
            this.eventBase.allDayExpandScroll(dateHeader);
        }
        if (!this.uiStateValues.expand) {
            allDayRow.style.height = '';
        }
    };
    Schedule.prototype.extendedPropertyChange = function (prop, newProp, oldProp, state) {
        switch (prop) {
            case 'width':
            case 'height':
            case 'enableAllDayScroll':
                if (['Day', 'Week', 'WorkWeek'].indexOf(this.currentView) > -1) {
                    this.allDayRowScrollUpdate();
                }
                this.notify(uiUpdate, { module: 'scroll', properties: { width: newProp.width, height: newProp.height } });
                break;
            case 'cssClass':
                if (oldProp.cssClass) {
                    removeClass([this.element], oldProp.cssClass.split(' '));
                }
                if (newProp.cssClass) {
                    addClass([this.element], newProp.cssClass.split(' '));
                }
                break;
            case 'hideEmptyAgendaDays':
            case 'agendaDaysCount':
                this.activeViewOptions = this.getActiveViewOptions();
                state.isView = true;
                break;
            case 'eventSettings':
                this.onEventSettingsPropertyChanged(newProp.eventSettings, oldProp.eventSettings, state);
                break;
            case 'allowKeyboardInteraction':
                if (this.keyboardInteractionModule) {
                    this.keyboardInteractionModule.destroy();
                    this.keyboardInteractionModule = null;
                }
                if (newProp.allowKeyboardInteraction) {
                    this.keyboardInteractionModule = new KeyboardInteraction(this);
                }
                break;
            case 'timezoneDataSource':
                if (this.eventWindow) {
                    this.eventWindow.refresh();
                }
                break;
            case 'editorTemplate':
                if (!isNullOrUndefined(this.editorTemplate)) {
                    this.editorTemplateFn = this.templateParser(this.editorTemplate);
                }
                if (this.eventWindow) {
                    this.eventWindow.setDialogContent();
                }
                break;
            case 'editorHeaderTemplate':
                if (!isNullOrUndefined(this.editorHeaderTemplate)) {
                    this.editorHeaderTemplateFn = this.templateParser(this.editorHeaderTemplate);
                }
                if (this.eventWindow) {
                    this.eventWindow.setDialogHeader();
                }
                break;
            case 'editorFooterTemplate':
                if (!isNullOrUndefined(this.editorFooterTemplate)) {
                    this.editorFooterTemplateFn = this.templateParser(this.editorFooterTemplate);
                }
                if (this.eventWindow) {
                    this.eventWindow.setDialogFooter();
                }
                break;
            case 'quickInfoTemplates':
                if (this.quickInfoTemplates.header) {
                    this.quickInfoTemplatesHeaderFn = this.templateParser(this.quickInfoTemplates.header);
                }
                if (this.quickInfoTemplates.content) {
                    this.quickInfoTemplatesContentFn = this.templateParser(this.quickInfoTemplates.content);
                }
                if (this.quickInfoTemplates.footer) {
                    this.quickInfoTemplatesFooterFn = this.templateParser(this.quickInfoTemplates.footer);
                }
                break;
            case 'group':
                this.onGroupSettingsPropertyChanged(newProp.group, oldProp.group, state);
                break;
            case 'resources':
                state.isResource = true;
                break;
            case 'timeScale':
                this.activeViewOptions.timeScale.interval = newProp.timeScale.interval || this.activeViewOptions.timeScale.interval;
                this.activeViewOptions.timeScale.slotCount = newProp.timeScale.slotCount || this.activeViewOptions.timeScale.slotCount;
                if (this.eventWindow) {
                    this.eventWindow.refreshDateTimePicker();
                }
                state.isLayout = true;
                break;
            case 'allowDragAndDrop':
            case 'allowResizing':
            case 'eventDragArea':
                this.refreshEvents(false);
                break;
            case 'weekRule':
                state.isLayout = true;
                break;
            case 'firstMonthOfYear':
                this.activeViewOptions.firstMonthOfYear = newProp.firstMonthOfYear;
                this.viewIndex = this.activeView.viewIndex;
                state.isLayout = true;
                break;
            case 'monthsCount':
                this.activeViewOptions.monthsCount = newProp.monthsCount;
                this.viewIndex = this.activeView.viewIndex;
                state.isLayout = true;
                break;
            case 'timeFormat':
                this.internalTimeFormat = newProp.timeFormat || this.activeViewOptions.timeFormat;
                if (this.eventWindow) {
                    this.eventWindow.refreshDateTimePicker();
                }
                state.isLayout = true;
                break;
            case 'enableAdaptiveUI':
                if (this.showHeaderBar && this.headerModule) {
                    this.destroyHeaderModule();
                    this.headerModule = new HeaderRenderer(this);
                    this.headerModule.updateDateRange();
                }
                state.isLayout = true;
                break;
            case 'headerIndentTemplate':
                this.activeViewOptions.headerIndentTemplate = newProp.headerIndentTemplate;
                this.headerIndentTemplateFn = this.templateParser(this.activeViewOptions.headerIndentTemplate);
                state.isLayout = true;
                break;
        }
    };
    Schedule.prototype.setRtlClass = function () {
        if (this.enableRtl) {
            addClass([this.element], 'e-rtl');
        }
        else {
            removeClass([this.element], 'e-rtl');
        }
    };
    Schedule.prototype.onGroupSettingsPropertyChanged = function (newProp, oldProp, state) {
        for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
            var prop = _a[_i];
            if (prop === 'headerTooltipTemplate') {
                this.headerTooltipTemplateFn = this.templateParser(newProp.headerTooltipTemplate);
            }
            else {
                state.isLayout = true;
                if (this.eventWindow) {
                    this.eventWindow.refresh();
                }
            }
        }
    };
    Schedule.prototype.onEventSettingsPropertyChanged = function (newProp, oldProp, state) {
        for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
            var prop = _a[_i];
            switch (prop) {
                case 'dataSource':
                case 'query':
                case 'fields':
                case 'includeFiltersInQuery':
                    this.initializeDataModule();
                    state.isDataManager = true;
                    break;
                case 'template':
                    this.activeViewOptions.eventTemplate = newProp.template;
                    this.appointmentTemplateFn = this.templateParser(this.activeViewOptions.eventTemplate);
                    state.isDataManager = true;
                    break;
                case 'enableTooltip':
                    if (this.eventTooltip) {
                        this.eventTooltip.destroy();
                        this.eventTooltip = null;
                    }
                    if (newProp.enableTooltip) {
                        this.eventTooltip = new EventTooltip(this);
                    }
                    break;
                case 'tooltipTemplate':
                    this.eventTooltipTemplateFn = this.templateParser(this.eventSettings.tooltipTemplate);
                    break;
                case 'resourceColorField':
                    if (this.resourceBase) {
                        this.resourceBase.setResourceCollection();
                    }
                    state.isDataManager = true;
                    break;
                case 'editFollowingEvents':
                    if (this.quickPopup) {
                        this.quickPopup.refreshQuickDialog();
                    }
                    break;
                case 'allowAdding':
                case 'allowEditing':
                case 'allowDeleting':
                    if (this.showHeaderBar && this.headerModule) {
                        this.headerModule.updateAddIcon();
                    }
                    if (this.eventWindow) {
                        this.eventWindow.refresh();
                    }
                    break;
                case 'spannedEventPlacement':
                case 'minimumEventDuration':
                case 'enableMaxHeight':
                case 'enableIndicator':
                    this.refreshEvents(false);
                    break;
                case 'ignoreWhitespace':
                    state.isLayout = true;
                    break;
            }
        }
    };
    Schedule.prototype.destroyHeaderModule = function () {
        if (this.headerModule) {
            this.headerModule.destroy();
            this.headerModule = null;
        }
    };
    Schedule.prototype.destroyPopups = function () {
        if (this.quickPopup) {
            this.quickPopup.destroy();
            this.quickPopup = null;
        }
        if (this.eventWindow) {
            this.eventWindow.destroy();
            this.eventWindow = null;
        }
    };
    /**
     * Allows to show the spinner on schedule at the required scenarios.
     *
     * @function showSpinner
     * @returns {void}
     */
    Schedule.prototype.showSpinner = function () {
        showSpinner(this.element);
    };
    /**
     * When the spinner is shown manually using `showSpinner` method, it can be hidden using this `hideSpinner` method.
     *
     * @function hideSpinner
     * @returns {void}
     */
    Schedule.prototype.hideSpinner = function () {
        hideSpinner(this.element);
    };
    /**
     * Sets different working hours on the required working days by accepting the required start and end time as well as the date collection
     *  as its parameters.
     *
     * @function setWorkHours
     * @param {Date} dates Collection of dates on which the given start and end hour range needs to be applied.
     * @param {string} start Defines the work start hour.
     * @param {string} end Defines the work end hour.
     * @param {number} groupIndex Defines the resource index from last level.
     * @returns {void}
     */
    Schedule.prototype.setWorkHours = function (dates, start, end, groupIndex) {
        var cells = [];
        cells = this.getWorkHourCells(dates, start, end, groupIndex);
        addClass(cells, WORK_HOURS_CLASS);
    };
    /**
     * Removes or resets different working hours on the required working days by accepting the required start and end time as well as the
     * date collection as its parameters.
     * if no parameters has been passed to this function, it will remove all the work hours.
     *
     * @param {Date} dates Collection of dates on which the given start and end hour range need to be applied.
     * @param {string} start Defines the work start hour.
     * @param {string} end Defines the work end hour.
     * @param {number} groupIndex Defines the resource index from last level.
     * @returns {void}
     */
    Schedule.prototype.resetWorkHours = function (dates, start, end, groupIndex) {
        if (dates === void 0) { dates = this.activeView.renderDates; }
        if (dates && start && end) {
            var cells = this.getWorkHourCells(dates, start, end, groupIndex);
            removeClass(cells, WORK_HOURS_CLASS);
        }
        else {
            var workHourCells = [].slice.call(this.element.querySelectorAll('.' + WORK_HOURS_CLASS));
            removeClass(workHourCells, WORK_HOURS_CLASS);
        }
    };
    Schedule.prototype.getWorkHourCells = function (dates, start, end, groupIndex) {
        if (['Agenda', 'MonthAgenda', 'Month', 'TimelineMonth'].indexOf(this.currentView) > -1) {
            return [];
        }
        var startHour = this.getStartEndTime(start);
        var endHour = this.getStartEndTime(end);
        var tableEle = this.getContentTable();
        if (isNullOrUndefined(startHour) || isNullOrUndefined(endHour) || !tableEle) {
            return [];
        }
        startHour.setMilliseconds(0);
        endHour.setMilliseconds(0);
        var viewStartHour = this.activeView.getStartHour();
        if (startHour < viewStartHour) {
            startHour = viewStartHour;
        }
        var viewEndHour = this.activeView.getEndHour();
        if (endHour > viewEndHour) {
            endHour = viewEndHour;
        }
        var msMajorInterval = this.activeViewOptions.timeScale.interval * MS_PER_MINUTE;
        var msInterval = msMajorInterval / this.activeViewOptions.timeScale.slotCount;
        var startIndex = Math.round((startHour.getTime() - viewStartHour.getTime()) / msInterval);
        var endIndex = Math.ceil((endHour.getTime() - viewStartHour.getTime()) / msInterval);
        var tempStartIndex = startIndex;
        var tempEndIndex = endIndex;
        var cells = [];
        for (var _i = 0, dates_1 = dates; _i < dates_1.length; _i++) {
            var date = dates_1[_i];
            date = this.getDateTime(date);
            resetTime(date);
            var renderDates = this.activeView.renderDates;
            if (!isNullOrUndefined(groupIndex) && this.resourceBase && !this.activeView.isTimelineView()) {
                renderDates = this.resourceBase.lastResourceLevel[parseInt(groupIndex.toString(), 10)].renderDates;
            }
            var colIndex = this.getIndexOfDate(renderDates, date);
            if (colIndex >= 0) {
                if (this.activeView.isTimelineView()) {
                    var slotsPerDay = Math.round((viewEndHour.getTime() - viewStartHour.getTime()) / msInterval);
                    startIndex = tempStartIndex + (colIndex * slotsPerDay);
                    endIndex = tempEndIndex + (colIndex * slotsPerDay);
                }
                for (var i = startIndex; i < endIndex; i++) {
                    if (this.activeView.isTimelineView()) {
                        var rowIndex = (!isNullOrUndefined(groupIndex)) ? groupIndex : 0;
                        cells.push(tableEle.rows[parseInt(rowIndex.toString(), 10)].cells[parseInt(i.toString(), 10)]);
                    }
                    else {
                        if (!isNullOrUndefined(groupIndex)) {
                            var selector = '.' + WORK_CELLS_CLASS + '[data-group-index="' + groupIndex + '"]';
                            var tds = [].slice.call(tableEle.rows[parseInt(i.toString(), 10)].querySelectorAll(selector));
                            cells.push(tds[parseInt(colIndex.toString(), 10)]);
                        }
                        else {
                            cells.push(tableEle.rows[parseInt(i.toString(), 10)].cells[parseInt(colIndex.toString(), 10)]);
                        }
                    }
                }
            }
        }
        return cells;
    };
    /**
     * Retrieves the start and end time information of the specific cell element.
     *
     * @function getCellDetails
     * @param {Element | Element[]} tdCol Accepts the single or collection of elements.
     * @returns {CellClickEventArgs} Object An object holding the startTime, endTime and all-day information along with the target HTML element will be returned.
     */
    Schedule.prototype.getCellDetails = function (tdCol) {
        var td = (tdCol instanceof Array) ? tdCol : [tdCol];
        var firstTd = getElement(td[0]);
        var lastTd = getElement(td.slice(-1)[0]);
        var startTime = this.getDateFromElement(firstTd);
        var endTime = this.getDateFromElement(lastTd);
        if (isNullOrUndefined(startTime) || isNullOrUndefined(endTime)) {
            return undefined;
        }
        var endDateFromColSpan = this.activeView.isTimelineView() && !isNullOrUndefined(lastTd.getAttribute('colSpan')) &&
            this.headerRows.length > 0;
        var duration = endDateFromColSpan ? parseInt(lastTd.getAttribute('colSpan'), 10) : 1;
        if (!this.activeViewOptions.timeScale.enable || endDateFromColSpan || lastTd.classList.contains(ALLDAY_CELLS_CLASS) ||
            lastTd.classList.contains(HEADER_CELLS_CLASS)) {
            endTime = addDays(new Date(endTime.getTime()), duration);
        }
        else {
            endTime = this.activeView.getEndDateFromStartDate(endTime);
        }
        var data = {
            startTime: startTime,
            endTime: endTime,
            isAllDay: this.isAllDayCell(firstTd),
            element: tdCol
        };
        var groupIndex = firstTd.getAttribute('data-group-index');
        if (!isNullOrUndefined(groupIndex)) {
            data.groupIndex = parseInt(groupIndex, 10);
        }
        return data;
    };
    /**
     * Retrieves the selected cell elements.
     *
     * @function getSelectedElements
     * @returns {Element[]} The elements of currently selected cells will be returned.
     */
    Schedule.prototype.getSelectedElements = function () {
        if (this.keyboardInteractionModule && this.keyboardInteractionModule.selectedCells.length > 0) {
            return this.keyboardInteractionModule.selectedCells;
        }
        return this.getSelectedCells();
    };
    /**
     * To get the resource collection
     *
     * @function getResourceCollections
     * @returns {ResourcesModel[]} Returns the resource collections
     */
    Schedule.prototype.getResourceCollections = function () {
        return this.resourceCollection;
    };
    /**
     * To set the resource collection
     *
     * @function setResourceCollections
     * @param {ResourcesModel[]} resourceCol Accepts the resource collections in ResourcesModel type
     * @returns {void}
     */
    Schedule.prototype.setResourceCollections = function (resourceCol) {
        this.setProperties({ resources: resourceCol }, false);
    };
    /**
     * Current View could be change based on the provided parameters.
     *
     * @function changeCurrentView
     * @param {View} viewName Accept the view in the viewCollections.
     * @param {number} viewIndex Accept the viewIndex in the viewCollections.
     * @returns {void}
     */
    Schedule.prototype.changeCurrentView = function (viewName, viewIndex) {
        var index = this.getViewIndex(viewName);
        var view = viewName.charAt(0).toLowerCase() + viewName.slice(1);
        var viewOptions = this.viewOptions["" + view];
        if (viewOptions) {
            index = this.viewCollections.indexOf(viewOptions[viewIndex || 0]);
        }
        if (index === -1 || index === this.viewIndex) {
            return;
        }
        this.changeView(viewName, null, null, index);
    };
    /**
     * Return the current view Index.
     *
     * @function getCurrentViewIndex
     * @returns {number} Returns the view index
     */
    Schedule.prototype.getCurrentViewIndex = function () {
        return this.viewIndex;
    };
    /**
     * Retrieves the resource details based on the provided resource index.
     *
     * @param {number} index index of the resources at the last level.
     * @returns {ResourceDetails} Object An object holding the details of resource and resourceData.
     */
    Schedule.prototype.getResourcesByIndex = function (index) {
        if (this.resourceBase && this.resourceBase.lastResourceLevel) {
            if (index < 0 || index >= this.resourceBase.lastResourceLevel.length) {
                return undefined;
            }
            var data = this.resourceBase.lastResourceLevel[parseInt(index.toString(), 10)];
            var groupData = {};
            this.resourceBase.setResourceValues(groupData, index);
            return { resource: data.resource, resourceData: data.resourceData, groupData: groupData };
        }
        return undefined;
    };
    /**
     * This method allows to expand the resource that available on the scheduler.
     *
     * @function expandResource
     * @param {string | number} resourceId Accepts the resource id in either string or number type
     * @param {string} name Accepts the name of the resource collection
     * @returns {void}
     */
    Schedule.prototype.expandResource = function (resourceId, name) {
        if (this.activeView.isTimelineView() && this.resourceBase && this.resourceCollection.length > 1) {
            this.resourceBase.resourceExpand(resourceId, name, false);
        }
    };
    /**
     * This method allows to collapse the resource that available on the scheduler.
     *
     * @function collapseResource
     * @param {string | number} resourceId Accepts the resource id in either string or number type
     * @param {string} name Accepts the name of the resource collection
     * @returns {void}
     */
    Schedule.prototype.collapseResource = function (resourceId, name) {
        if (this.activeView.isTimelineView() && this.resourceBase && this.resourceCollection.length > 1) {
            this.resourceBase.resourceExpand(resourceId, name, true);
        }
    };
    /**
     * Scrolls the Schedule content area to the specified time.
     *
     * @function scrollTo
     * @param {string} hour Accepts the time value in the skeleton format of 'Hm'.
     * @param {Date} scrollDate Accepts the date object value.
     * @returns {void}
     */
    Schedule.prototype.scrollTo = function (hour, scrollDate) {
        if (this.activeView.scrollToDate && isNullOrUndefined(hour) && scrollDate) {
            this.activeView.scrollToDate(scrollDate);
        }
        else if (this.activeView.scrollToHour) {
            this.activeView.scrollToHour(hour, scrollDate);
        }
    };
    /**
     * This method allows scroll to the position of the any resources that available on the scheduler.
     * This method is applicable for without Agenda and Month agenda views of the schedule.
     *
     * @function scrollToResource
     * @param {string | number} resourceId Accepts the resource id in either string or number type
     * @param {string} groupName Accepts the name of the resource collection
     * @returns {void}
     */
    Schedule.prototype.scrollToResource = function (resourceId, groupName) {
        if (this.resourceBase && this.resourceBase.lastResourceLevel) {
            this.resourceBase.resourceScroll(resourceId, groupName);
        }
    };
    /**
     * Exports the Scheduler events to a calendar (.ics) file. By default, the calendar is exported with a file name `Calendar.ics`.
     * To change this file name on export, pass the custom string value as `fileName` to get the file downloaded with this provided name.
     *
     * @function exportToICalendar
     * @param {string} fileName Accepts the string value.
     * @param {Object[]} customData Accepts the collection of objects.
     * @returns {void}
     */
    Schedule.prototype.exportToICalendar = function (fileName, customData) {
        if (this.iCalendarExportModule) {
            this.iCalendarExportModule.initializeCalendarExport(fileName, customData);
        }
        else {
            throw Error('Inject ICalendarExport module');
        }
    };
    /**
     * Imports the events from an .ics file downloaded from any of the calendars like Google or Outlook into the Scheduler.
     * This method accepts the blob object or string format of an .ics file to be imported as a mandatory argument.
     *
     * @function importICalendar
     * @param {Blob | string} fileContent Accepts the file object or string format of an .ics file.
     * @returns {void}
     */
    Schedule.prototype.importICalendar = function (fileContent) {
        if (this.iCalendarImportModule) {
            this.iCalendarImportModule.initializeCalendarImport(fileContent);
        }
        else {
            throw Error('Inject ICalendarImport module');
        }
    };
    /**
     * Adds the newly created event into the Schedule dataSource.
     *
     * @function addEvent
     * @param {Object | Object[]} data Single or collection of event objects to be added into Schedule.
     * @returns {void}
     */
    Schedule.prototype.addEvent = function (data) {
        this.crudModule.addEvent(data);
    };
    /**
     * Generates the occurrences of a single recurrence event based on the provided event.
     *
     * @function generateEventOccurrences
     * @param {Object} event Accepts the parent recurrence event from which the occurrences are generated.
     * @param {Date} startDate Accepts the start date for the event occurrences. If not provided, the event's start date will be used.
     * @returns {Object[]} Returns the collection of occurrence event objects.
     */
    Schedule.prototype.generateEventOccurrences = function (event, startDate) {
        return (this.eventBase) ? this.eventBase.generateOccurrence(event, startDate) : [];
    };
    /**
     * Allows the Scheduler events data to be exported as an Excel file either in .xlsx or .csv file formats.
     * By default, the whole event collection bound to the Scheduler gets exported as an Excel file.
     * To export only the specific events of Scheduler, you need to pass the custom data collection as
     * a parameter to this `exportToExcel` method. This method accepts the export options as arguments such as fileName,
     * exportType, fields, customData, and includeOccurrences. The `fileName` denotes the name to be given for the exported
     * file and the `exportType` allows you to set the format of an Excel file to be exported either as .xlsx or .csv.
     * The custom or specific field collection of event dataSource to be exported can be provided through `fields` option
     * and the custom data collection can be exported by passing them through the `customData` option. There also exists
     * option to export each individual instances of the recurring events to an Excel file, by setting true or false to the
     * `includeOccurrences` option, denoting either to include or exclude the occurrences as separate instances on an exported Excel file.
     *
     * @function exportToExcel
     * @param {ExportOptions} excelExportOptions The export options to be set before start with exporting the Scheduler events to an Excel file.
     * @returns {void}
     */
    Schedule.prototype.exportToExcel = function (excelExportOptions) {
        if (this.excelExportModule) {
            this.excelExportModule.initializeExcelExport(excelExportOptions);
        }
        else {
            throw Error('Inject ExcelExport module');
        }
    };
    /**
     * Method allows to print the scheduler.
     *
     * @function print
     * @param {ScheduleModel} printOptions The export options to be set before start with exporting
     * the Scheduler events to the print window.
     * @returns {void}
     */
    Schedule.prototype.print = function (printOptions) {
        if (this.printModule) {
            this.printModule.print(printOptions);
        }
        else {
            throw Error('Inject Print module');
        }
    };
    /**
     * Updates the changes made in the event object by passing it as an parameter into the dataSource.
     *
     * @function saveEvent
     * @param {Object | Object[]} data Single or collection of event objects to be saved into Schedule.
     * @param {CurrentAction} currentAction Denotes the action that takes place either for editing occurrence or series.
     *  The valid current action names are `EditOccurrence` or `EditSeries`.
     * @returns {void}
     */
    Schedule.prototype.saveEvent = function (data, currentAction) {
        this.crudModule.saveEvent(data, currentAction);
    };
    /**
     * Deletes the events based on the provided ID or event collection in the argument list.
     *
     * @function deleteEvent
     * @param {string | number | Object | Object[]} id Accepts the ID as string or number type or single or collection of the event object which needs to be removed from the Schedule.
     * @param {CurrentAction} currentAction Denotes the delete action that takes place either on occurrence or series events.
     *  The valid current action names are `Delete`, `DeleteOccurrence` or `DeleteSeries`.
     * @returns {void}
     */
    Schedule.prototype.deleteEvent = function (id, currentAction) {
        this.crudModule.deleteEvent(id, currentAction);
    };
    /**
     * Retrieves the entire collection of events bound to the Schedule.
     *
     * @function getEvents
     * @param {Date} startDate Accepts the start date.
     * @param {Date} endDate Accepts te end date.
     * @param {boolean} includeOccurrences Accepts the boolean value to process the occurrence from recurrence series.
     * @returns {Object[]} Returns the collection of event objects from the Schedule.
     */
    Schedule.prototype.getEvents = function (startDate, endDate, includeOccurrences) {
        var eventCollections = [];
        if (includeOccurrences) {
            eventCollections = this.eventBase.getProcessedEvents();
        }
        else {
            eventCollections = this.eventsData;
        }
        if (startDate) {
            startDate = this.getDateTime(startDate);
        }
        if (endDate) {
            endDate = this.getDateTime(endDate);
        }
        eventCollections = this.eventBase.filterEventsByRange(eventCollections, startDate, endDate);
        return eventCollections;
    };
    /**
     * Retrieves the entire collection of block events bound to the Schedule.
     *
     * @function getBlockEvents
     * @param {Date} startDate Accepts the start date.
     * @param {Date} endDate Accepts te end date.
     * @param {boolean} includeOccurrences Accepts the boolean value to process the occurrence from recurrence series.
     * @returns {Object[]} Returns the collection of block event objects from the Schedule.
     */
    Schedule.prototype.getBlockEvents = function (startDate, endDate, includeOccurrences) {
        var eventCollections = [];
        if (includeOccurrences) {
            eventCollections = this.eventBase.getProcessedEvents(this.blockData);
        }
        else {
            eventCollections = this.blockData;
        }
        if (startDate) {
            startDate = this.getDateTime(startDate);
        }
        if (endDate) {
            endDate = this.getDateTime(endDate);
        }
        eventCollections = this.eventBase.filterEventsByRange(eventCollections, startDate, endDate);
        return eventCollections;
    };
    /**
     * Retrieves the occurrences of a single recurrence event based on the provided parent ID.
     *
     * @function getOccurrencesByID
     * @param {number} eventID ID of the parent recurrence data from which the occurrences are fetched.
     * @returns {Object[]} Returns the collection of occurrence event objects.
     */
    Schedule.prototype.getOccurrencesByID = function (eventID) {
        return this.eventBase.getOccurrencesByID(eventID);
    };
    /**
     * Retrieves all the occurrences that lies between the specific start and end time range.
     *
     * @function getOccurrencesByRange
     * @param {Date} startTime Denotes the start time range.
     * @param {Date} endTime Denotes the end time range.
     * @returns {Object[]} Returns the collection of occurrence event objects that lies between the provided start and end time.
     */
    Schedule.prototype.getOccurrencesByRange = function (startTime, endTime) {
        startTime = this.getDateTime(startTime);
        endTime = this.getDateTime(endTime);
        return this.eventBase.getOccurrencesByRange(startTime, endTime);
    };
    /**
     * Retrieves the dates that lies on active view of Schedule.
     *
     * @function getCurrentViewDates
     * @returns {Date[]} Returns the collection of dates.
     */
    Schedule.prototype.getCurrentViewDates = function () {
        return this.activeView ? this.activeView.renderDates : [];
    };
    /**
     * Set the recurrence editor instance from custom editor template.
     *
     * @function setRecurrenceEditor
     * @param {RecurrenceEditor} recurrenceEditor instance has passed to fetch the instance in event window.
     * @returns {void}
     */
    Schedule.prototype.setRecurrenceEditor = function (recurrenceEditor) {
        this.eventWindow.setRecurrenceEditor(recurrenceEditor);
    };
    /**
     * Get the maximum id of an event.
     *
     * @function getEventMaxID
     * @returns {number | string} Returns the maximum ID from scheduler data collections.
     */
    Schedule.prototype.getEventMaxID = function () {
        return this.eventBase.getEventMaxID();
    };
    /**
     * Get deleted occurrences from given recurrence series.
     *
     * @function getDeletedOccurrences
     * @param {string | number | Object} recurrenceData Accepts the parent ID of the event object or parent event object
     * @returns {Object[]} Returns the collection of deleted occurrence events.
     */
    Schedule.prototype.getDeletedOccurrences = function (recurrenceData) {
        return this.eventBase.getDeletedOccurrences(recurrenceData);
    };
    /**
     * Retrieves the events that lies on the current date range of the active view of Schedule.
     *
     * @function getCurrentViewEvents
     * @returns {Object[]} Returns the collection of events.
     */
    Schedule.prototype.getCurrentViewEvents = function () {
        return this.eventsProcessed;
    };
    /**
     * Refreshes the event dataSource. This method may be useful when the events alone in the schedule needs to be re-rendered.
     *
     * @function refreshEvents
     * @param {boolean} isRemoteRefresh Accepts the boolean to refresh data from remote or local
     * @returns {void}
     */
    Schedule.prototype.refreshEvents = function (isRemoteRefresh) {
        if (isRemoteRefresh === void 0) { isRemoteRefresh = true; }
        if (isRemoteRefresh) {
            if (this.dragAndDropModule) {
                this.dragAndDropModule.actionObj.action = '';
                removeClass([this.element], EVENT_ACTION_CLASS);
            }
            this.crudModule.refreshDataManager();
        }
        else {
            if (this.uiStateValues) {
                this.uiStateValues.isPreventTimezone = true;
            }
            if (this.crudModule) {
                this.crudModule.refreshProcessedData();
            }
            if (this.uiStateValues) {
                this.uiStateValues.isPreventTimezone = false;
            }
        }
    };
    /**
     * Method to refresh the given Schedule templates
     *
     * @param {string} templateName Accepts the template name
     * @returns {void}
     */
    Schedule.prototype.refreshTemplates = function (templateName) {
        if (templateName) {
            this.resetTemplates([templateName]);
        }
        else {
            this.resetTemplates();
        }
        switch (templateName) {
            case 'eventTemplate':
                this.appointmentTemplateFn = this.templateParser(this.activeViewOptions.eventTemplate);
                this.refreshEvents(false);
                break;
            case 'dateHeaderTemplate':
                this.dateHeaderTemplateFn = this.templateParser(this.activeViewOptions.dateHeaderTemplate);
                this.activeView.refreshHeader();
                break;
            case 'dateRangeTemplate':
                this.dateRangeTemplateFn = this.templateParser(this.activeViewOptions.dateRangeTemplate);
                if (this.headerModule) {
                    this.headerModule.refresh();
                }
                break;
            case 'resourceHeaderTemplate':
                this.resourceHeaderTemplateFn = this.templateParser(this.activeViewOptions.resourceHeaderTemplate);
                if (this.activeView.isTimelineView()) {
                    this.activeView.refreshResourceHeader();
                }
                else {
                    this.activeView.refreshHeader();
                }
                break;
            case 'quickInfoTemplates':
                if (this.quickPopup) {
                    this.quickPopup.destroy();
                    this.quickPopup = null;
                }
                this.quickPopup = new QuickPopups(this);
                this.quickInfoTemplatesHeaderFn = this.templateParser(this.quickInfoTemplates.header);
                this.quickInfoTemplatesContentFn = this.templateParser(this.quickInfoTemplates.content);
                this.quickInfoTemplatesFooterFn = this.templateParser(this.quickInfoTemplates.footer);
                break;
            case 'editorTemplate':
                this.destroyEditorWindow();
                this.editorTemplateFn = this.templateParser(this.editorTemplate);
                break;
            case 'editorHeaderTemplate':
                this.destroyEditorWindow();
                this.editorHeaderTemplateFn = this.templateParser(this.editorHeaderTemplate);
                break;
            case 'editorFooterTemplate':
                this.destroyEditorWindow();
                this.editorFooterTemplateFn = this.templateParser(this.editorFooterTemplate);
                break;
            case 'tooltipTemplate':
            case 'headerTooltipTemplate':
                if (this.eventTooltip) {
                    this.eventTooltip.destroy();
                    this.eventTooltip = null;
                }
                this.eventTooltip = new EventTooltip(this);
                this.eventTooltipTemplateFn = this.templateParser(this.eventSettings.tooltipTemplate);
                this.headerTooltipTemplateFn = this.templateParser(this.activeViewOptions.group.headerTooltipTemplate);
                break;
            default:
                this.initializeView(this.currentView);
                break;
        }
    };
    /**
     * Refreshes the Schedule layout without re-render.
     *
     * @function refreshLayout
     * @returns {void}
     */
    Schedule.prototype.refreshLayout = function () {
        this.onScheduleResize();
        if (this.headerModule) {
            this.headerModule.refresh();
        }
        if (this.eventWindow) {
            this.eventWindow.refresh();
        }
    };
    /**
     * To retrieve the appointment object from element.
     *
     * @function getEventDetails
     * @param {Element} element Denotes the event UI element on the Schedule.
     * @returns {Object} Returns the event details.
     */
    Schedule.prototype.getEventDetails = function (element) {
        element = getElement(element);
        var guid = element.getAttribute('data-guid');
        if (guid) {
            return this.eventBase.getEventByGuid(guid);
        }
        return {};
    };
    /**
     * To check whether the given time range slots are available for event creation or already occupied by other events.
     *
     * @function isSlotAvailable
     * @param {Date | Object} startTime Denotes the start time of the slot.
     * @param {Date} endTime Denotes the end time of the slot.
     * @param {number} groupIndex Defines the resource index from last level.
     * @returns {boolean} Returns true, if the slot that lies in the provided time range does not contain any other events.
     */
    Schedule.prototype.isSlotAvailable = function (startTime, endTime, groupIndex) {
        var _this = this;
        var eventStart;
        var eventEnd;
        var eventObj = this.activeEventData.event;
        if (startTime instanceof Date || typeof (startTime) === 'string') {
            eventStart = startTime;
            eventEnd = endTime;
        }
        else {
            eventObj = startTime;
            eventStart = startTime[this.eventFields.startTime];
            eventEnd = startTime[this.eventFields.endTime];
            if (this.resourceBase) {
                groupIndex = this.eventBase.getGroupIndexFromEvent(startTime);
            }
        }
        if (isNullOrUndefined(eventStart) || isNullOrUndefined(eventEnd)) {
            return true;
        }
        eventStart = this.getDateTime(eventStart);
        eventEnd = this.getDateTime(eventEnd);
        var eventCollection = this.eventBase.filterEvents(eventStart, eventEnd);
        if (!isNullOrUndefined(groupIndex) && this.resourceBase && this.resourceBase.lastResourceLevel.length > 0) {
            eventCollection =
                this.eventBase.filterEventsByResource(this.resourceBase.lastResourceLevel[parseInt(groupIndex.toString(), 10)], eventCollection);
        }
        if (eventObj) {
            if (eventObj.Guid) {
                eventCollection = eventCollection.filter(function (event) { return event.Guid !== eventObj.Guid; });
            }
            else {
                eventCollection = eventCollection.filter(function (event) {
                    return event[_this.eventFields.id] !== eventObj[_this.eventFields.id];
                });
            }
        }
        return (eventCollection.length > 0) ? false : true;
    };
    /**
     * To manually open the event editor on specific time or on certain events.
     *
     * @function openEditor
     * @param {Object} data It can be either cell data or event data.
     * @param {CurrentAction} action Defines the action for which the editor needs to be opened such as either for new event creation or
     *  for editing of existing events. The applicable action names that can be used here are `Add`, `Save`, `EditOccurrence`
     *  and `EditSeries`.
     * @param {boolean} isEventData It allows to decide whether the editor needs to be opened with the clicked cell details or with the
     *  passed event details.
     * @param {number} repeatType It opens the editor with the recurrence options based on the provided repeat type.
     * @returns {void}
     */
    Schedule.prototype.openEditor = function (data, action, isEventData, repeatType) {
        if (action === 'Add' && !isEventData) {
            data.startTime = this.getDateTime(data.startTime);
            data.endTime = this.getDateTime(data.endTime);
            if (!isNullOrUndefined(data.element)) {
                data.element = getElement(data.element);
            }
        }
        else {
            data[this.eventFields.startTime] = this.getDateTime(data[this.eventFields.startTime]);
            data[this.eventFields.endTime] = this.getDateTime(data[this.eventFields.endTime]);
        }
        this.currentAction = action;
        if (action !== 'Add') {
            this.activeEventData.event = data;
        }
        this.eventWindow.openEditor(data, action, isEventData, repeatType);
    };
    /**
     * To manually close the event editor window
     *
     * @function closeEditor
     * @returns {void}
     */
    Schedule.prototype.closeEditor = function () {
        if (this.eventWindow) {
            this.eventWindow.dialogClose();
        }
    };
    /**
     * To manually open the quick info popup based on cell or event details.
     *
     * @param {object} data Defines the cell or event data. If the data contains valid ID, it will open event quick info popup,
     * otherwise cell quick info popup displayed.
     * @returns {void}
     */
    Schedule.prototype.openQuickInfoPopup = function (data) {
        var _this = this;
        if (this.currentView === 'Year' || isNullOrUndefined(data)) {
            return;
        }
        if (isNullOrUndefined(data[this.eventFields.id])) {
            if (this.currentView === 'Agenda' || this.currentView === 'MonthAgenda' || isNullOrUndefined(this.activeView)) {
                return;
            }
            var cellData = {
                startTime: this.activeCellsData.startTime = this.getDateTime(data[this.eventFields.startTime]),
                endTime: this.activeCellsData.endTime = this.getDateTime(data[this.eventFields.endTime]),
                isAllDay: this.activeCellsData.isAllDay =
                    !isNullOrUndefined(data[this.eventFields.isAllDay]) ? data[this.eventFields.isAllDay] : false
            };
            var startTime = this.activeView.getAdjustedDate(new Date(cellData.startTime));
            if (startTime) {
                var query = '.' + WORK_CELLS_CLASS + '[data-date="' + startTime.getTime() + '"]';
                if (this.activeViewOptions.group.resources.length > 0 && !this.uiStateValues.isGroupAdaptive
                    && this.resourceBase && this.eventBase) {
                    cellData.groupIndex = this.eventBase.getGroupIndexFromEvent(data);
                    query = '.' + WORK_CELLS_CLASS + '[data-date="' + startTime.getTime() + '"][data-group-index="' + cellData.groupIndex + '"]';
                }
                var workCell = this.element.querySelector(query);
                if (workCell) {
                    workCell.focus();
                    cellData.element = workCell;
                    this.notify(cellClick, cellData);
                }
            }
        }
        else {
            var app = this.getCurrentViewEvents().filter(function (item) {
                return data[_this.eventFields.id] === item[_this.eventFields.id];
            });
            if (app.length <= 0) {
                return;
            }
            var selectEvent = app[0];
            if (data[this.eventFields.recurrenceRule]) {
                var occurence = app.filter(function (x) {
                    return x[_this.eventFields.startTime].getTime() === data[_this.eventFields.startTime].getTime();
                });
                if (occurence.length > 0) {
                    selectEvent = occurence[0];
                }
            }
            var element = this.element.querySelector('div[data-guid="' + selectEvent.Guid + '"]');
            if (element) {
                this.eventBase.removeSelectedAppointmentClass();
                this.eventBase.addSelectedAppointments([element], false);
                this.activeEventData = { event: selectEvent, element: element };
                if (this.currentView === 'Agenda' || this.currentView === 'MonthAgenda') {
                    addClass([this.activeEventData.element], AGENDA_SELECTED_CELL);
                }
                this.notify(eventClick, this.activeEventData);
            }
        }
    };
    /**
     * To manually close the quick info popup
     *
     * @function closeQuickInfoPopup
     * @returns {void}
     */
    Schedule.prototype.closeQuickInfoPopup = function () {
        if (this.quickPopup) {
            this.quickPopup.quickPopupHide(true);
        }
    };
    /**
     * Closes the tooltip.
     * For example, when the context menu is opened for an event,
     * the tooltip can be closed by calling this method.
     *
     * @function closeTooltip
     * @returns {void}
     */
    Schedule.prototype.closeTooltip = function () {
        if (this.eventTooltip) {
            this.eventTooltip.close();
        }
    };
    /**
     * Select the resource based on group index in mobile mode.
     *
     * @param {number} groupIndex Defines the resource index based on last level.
     * @returns {void}
     */
    Schedule.prototype.selectResourceByIndex = function (groupIndex) {
        if (this.resourceBase && this.uiStateValues.isGroupAdaptive) {
            this.resourceBase.selectResourceByIndex(groupIndex);
        }
    };
    /**
     * Select the resources to the based on id.
     *
     * @param {string | number} id id of the resource defined in resources collection.
     * @param {string} name Name of the resource defined in resources collection.
     * @returns {number} Returns the group index
     */
    Schedule.prototype.getIndexFromResourceId = function (id, name) {
        if (this.resourceBase) {
            return this.resourceBase.getIndexFromResourceId(id, name);
        }
        return null;
    };
    /**
     * Adds the resources to the specified index.
     *
     * @param {Object | Object[]} resources Accepts the resource data in single or collection of data.
     * @param {string} name Name of the resource defined in resources collection.
     * @param {number} index Index or position where the resource should be added.
     * @returns {void}
     */
    Schedule.prototype.addResource = function (resources, name, index) {
        this.resourceBase.addResource(resources, name, index);
    };
    /**
     * Removes the specified resource.
     *
     * @param {string | string[] | number | number[]} resourceId Specifies the resource id to be removed.
     * @param {string} name Specifies the resource name from which the id should be referred.
     * @returns {void}
     */
    Schedule.prototype.removeResource = function (resourceId, name) {
        this.resourceBase.removeResource(resourceId, name);
    };
    /**
     * Destroys the Schedule component.
     *
     * @function destroy
     * @returns {void}
     */
    Schedule.prototype.destroy = function () {
        if (this.eventTooltip) {
            this.eventTooltip.destroy();
            this.eventTooltip = null;
        }
        this.destroyPopups();
        this.hideSpinner();
        this.unWireEvents();
        this.destroyHeaderModule();
        if (this.eventBase) {
            this.eventBase.destroy();
            this.eventBase = null;
        }
        if (this.workCellAction) {
            this.workCellAction.destroy();
            this.workCellAction = null;
        }
        if (this.inlineModule) {
            this.inlineModule.destroy();
            this.inlineModule = null;
        }
        if (this.keyboardInteractionModule) {
            this.keyboardInteractionModule.destroy();
            this.keyboardInteractionModule = null;
        }
        if (this.scrollModule) {
            this.scrollModule.destroy();
            this.scrollModule = null;
        }
        if (this.printModule) {
            this.printModule.destroy();
        }
        if (this.activeView) {
            this.resetTemplates();
            this.activeView.removeEventListener();
            this.activeView.destroy();
            this.activeView = null;
        }
        if (this.scheduleTouchModule) {
            this.scheduleTouchModule.destroy();
            this.scheduleTouchModule = null;
        }
        if (this.crudModule) {
            this.crudModule.destroy();
            this.crudModule = null;
        }
        if (this.dataModule) {
            this.dataModule.destroy();
            this.dataModule = null;
        }
        _super.prototype.destroy.call(this);
        var modules = [
            'dayModule', 'weekModule', 'workWeekModule', 'monthModule', 'monthAgendaModule', 'yearModule', 'agendaModule',
            'timelineViewsModule', 'timelineMonthModule', 'timelineYearModule', 'resizeModule', 'dragAndDropModule',
            'excelExportModule', 'printModule', 'iCalendarExportModule', 'iCalendarImportModule', 'tzModule', 'eventsData',
            'eventsProcessed', 'blockData', 'blockProcessed', 'uiStateValues', 'viewCollections', 'viewOptions', 'defaultLocale',
            'localeObj', 'selectedElements', 'resourceCollection', 'editorTitles', 'eventFields', 'activeViewOptions',
            'activeEventData', 'activeCellsData', 'renderModule'
        ];
        for (var _i = 0, modules_1 = modules; _i < modules_1.length; _i++) {
            var module = modules_1[_i];
            this["" + module] = null;
        }
        removeChildren(this.element);
        var removeClasses = [ROOT, RTL, DEVICE_CLASS, MULTI_DRAG];
        if (this.cssClass) {
            removeClasses = removeClasses.concat(this.cssClass.split(' '));
        }
        removeClass([this.element], removeClasses);
    };
    __decorate([
        Property('auto')
    ], Schedule.prototype, "width", void 0);
    __decorate([
        Property('auto')
    ], Schedule.prototype, "height", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "showHeaderBar", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "showTimeIndicator", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowSwiping", void 0);
    __decorate([
        Property('Week')
    ], Schedule.prototype, "currentView", void 0);
    __decorate([
        Property(['Day', 'Week', 'WorkWeek', 'Month', 'Agenda'])
    ], Schedule.prototype, "views", void 0);
    __decorate([
        Property(new Date())
    ], Schedule.prototype, "selectedDate", void 0);
    __decorate([
        Property(new Date(1900, 0, 1))
    ], Schedule.prototype, "minDate", void 0);
    __decorate([
        Property(new Date(2099, 11, 31))
    ], Schedule.prototype, "maxDate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "dateFormat", void 0);
    __decorate([
        Property('Gregorian')
    ], Schedule.prototype, "calendarMode", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "showWeekend", void 0);
    __decorate([
        Property(0)
    ], Schedule.prototype, "firstDayOfWeek", void 0);
    __decorate([
        Property('FirstDay')
    ], Schedule.prototype, "weekRule", void 0);
    __decorate([
        Property([1, 2, 3, 4, 5])
    ], Schedule.prototype, "workDays", void 0);
    __decorate([
        Property(12)
    ], Schedule.prototype, "monthsCount", void 0);
    __decorate([
        Property('00:00')
    ], Schedule.prototype, "startHour", void 0);
    __decorate([
        Property('24:00')
    ], Schedule.prototype, "endHour", void 0);
    __decorate([
        Property(null)
    ], Schedule.prototype, "timeFormat", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "enableHtmlSanitizer", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "enableAllDayScroll", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "enableAdaptiveUI", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowResizing", void 0);
    __decorate([
        Complex({}, WorkHours)
    ], Schedule.prototype, "workHours", void 0);
    __decorate([
        Complex({}, TimeScale)
    ], Schedule.prototype, "timeScale", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowKeyboardInteraction", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowDragAndDrop", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "dateHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "dateRangeTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "cellHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "dayHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "monthHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "cellTemplate", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "readonly", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "showQuickInfo", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "allowInline", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowMultiCellSelection", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "allowMultiRowSelection", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "quickInfoOnSelectionEnd", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "showWeekNumber", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "rowAutoHeight", void 0);
    __decorate([
        Property(false)
    ], Schedule.prototype, "allowMultiDrag", void 0);
    __decorate([
        Property(0)
    ], Schedule.prototype, "firstMonthOfYear", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "editorTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "editorHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "editorFooterTemplate", void 0);
    __decorate([
        Complex({}, QuickInfoTemplates)
    ], Schedule.prototype, "quickInfoTemplates", void 0);
    __decorate([
        Property(7)
    ], Schedule.prototype, "agendaDaysCount", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "hideEmptyAgendaDays", void 0);
    __decorate([
        Property(true)
    ], Schedule.prototype, "enableRecurrenceValidation", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "timezone", void 0);
    __decorate([
        Complex({}, EventSettings)
    ], Schedule.prototype, "eventSettings", void 0);
    __decorate([
        Property(timezoneData)
    ], Schedule.prototype, "timezoneDataSource", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "resourceHeaderTemplate", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "headerIndentTemplate", void 0);
    __decorate([
        Complex({}, Group)
    ], Schedule.prototype, "group", void 0);
    __decorate([
        Collection([], Resources)
    ], Schedule.prototype, "resources", void 0);
    __decorate([
        Collection([], HeaderRows)
    ], Schedule.prototype, "headerRows", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "cssClass", void 0);
    __decorate([
        Property()
    ], Schedule.prototype, "eventDragArea", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "created", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "destroyed", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "cellClick", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "cellDoubleClick", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "moreEventsClick", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "hover", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "select", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "actionBegin", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "actionComplete", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "actionFailure", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "navigating", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "renderCell", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "eventClick", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "eventDoubleClick", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "eventRendered", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "dataBinding", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "popupOpen", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "popupClose", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "dragStart", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "drag", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "dragStop", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "resizeStart", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "resizing", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "resizeStop", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "virtualScrollStart", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "virtualScrollStop", void 0);
    __decorate([
        Event()
    ], Schedule.prototype, "dataBound", void 0);
    Schedule = __decorate([
        NotifyPropertyChanges
    ], Schedule);
    return Schedule;
}(Component));

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Base class for the common drag and resize related actions
 */
var ActionBase = /** @__PURE__ @class */ (function () {
    function ActionBase(parent) {
        this.daysVariation = 0;
        this.parent = parent;
        this.actionObj = {
            X: 0, Y: 0, groupIndex: 0, cellWidth: 0, cellHeight: 0, slotInterval: 0, interval: 0, actionIndex: 0,
            cloneElement: [], originalElement: [], action: null, isAllDay: null, excludeSelectors: null,
            index: 0, navigationInterval: null, scrollInterval: null
        };
        this.scrollArgs = { element: null, width: 0, height: 0 };
        this.resizeEdges = { left: false, right: false, top: false, bottom: false };
        this.scrollEdges = { left: false, right: false, top: false, bottom: false };
    }
    ActionBase.prototype.getChangedData = function (multiData) {
        var _this = this;
        var eventObj = extend({}, this.actionObj.event, null, true);
        eventObj[this.parent.eventFields.startTime] = this.actionObj.start;
        eventObj[this.parent.eventFields.endTime] = this.actionObj.end;
        if (!isNullOrUndefined(this.actionObj.isAllDay)) {
            eventObj[this.parent.eventFields.isAllDay] = this.actionObj.isAllDay;
        }
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var originalElement = this.getOriginalElement(this.actionObj.element);
            if (originalElement) {
                var indexCol_1 = originalElement.map(function (element) { return parseInt(element.getAttribute('data-group-index'), 10); });
                if (indexCol_1.indexOf(this.actionObj.groupIndex) === -1 || (!isNullOrUndefined(multiData) && multiData.length > 0)) {
                    var cloneIndex_1 = parseInt(this.actionObj.clone.getAttribute('data-group-index'), 10);
                    indexCol_1 = indexCol_1.filter(function (index) { return index !== cloneIndex_1; });
                    indexCol_1.push(this.actionObj.groupIndex);
                    if (multiData && multiData.length > 0) {
                        multiData.forEach(function (data) {
                            _this.parent.resourceBase.getResourceData(data, _this.actionObj.groupIndex, indexCol_1);
                        });
                    }
                    else {
                        this.parent.resourceBase.getResourceData(eventObj, this.actionObj.groupIndex, indexCol_1);
                    }
                }
            }
        }
        return eventObj;
    };
    ActionBase.prototype.saveChangedData = function (eventArgs, isMultiSelect) {
        if (isMultiSelect === void 0) { isMultiSelect = false; }
        this.parent.activeEventData.event = this.actionObj.event;
        this.parent.currentAction = 'Save';
        var currentAction;
        var eventsCollection = [eventArgs.data];
        if (isMultiSelect) {
            eventsCollection = eventArgs.selectedData;
        }
        for (var _i = 0, eventsCollection_1 = eventsCollection; _i < eventsCollection_1.length; _i++) {
            var eventObj = eventsCollection_1[_i];
            var isSameResource = (this.parent.activeViewOptions.group.resources.length > 0) ?
                parseInt(this.actionObj.element.getAttribute('data-group-index'), 10) === this.actionObj.groupIndex : true;
            if (+eventObj[this.parent.eventFields.startTime] === +this.actionObj.event[this.parent.eventFields.startTime] &&
                +eventObj[this.parent.eventFields.endTime] === +this.actionObj.event[this.parent.eventFields.endTime] && isSameResource) {
                this.parent.crudModule.crudObj.isCrudAction = false;
                return;
            }
            if (eventObj[this.parent.eventFields.recurrenceRule]) {
                var eveId = (eventObj[this.parent.eventFields.recurrenceID] || eventObj[this.parent.eventFields.id]);
                if (eventObj[this.parent.eventFields.id] === eventObj[this.parent.eventFields.recurrenceID]) {
                    eventObj[this.parent.eventFields.id] = this.parent.eventBase.getEventMaxID();
                    currentAction = 'EditOccurrence';
                }
                if (this.parent.enableRecurrenceValidation
                    && this.parent.eventWindow.editOccurrenceValidation(eveId, eventObj, this.actionObj.event)) {
                    return;
                }
            }
            else {
                currentAction = null;
            }
            if (eventObj[this.parent.eventFields.startTimezone] || eventObj[this.parent.eventFields.endTimezone]) {
                this.parent.eventBase.timezoneConvert(eventObj);
            }
            this.parent.crudModule.saveEvent(eventObj, currentAction);
        }
    };
    ActionBase.prototype.calculateIntervalTime = function (date) {
        var intervalTime = new Date(+date);
        intervalTime.setMinutes(Math.floor(intervalTime.getMinutes() / this.actionObj.interval) * this.actionObj.interval);
        return intervalTime;
    };
    ActionBase.prototype.getContentAreaDimension = function () {
        var viewElement = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var trElement = [].slice.call(viewElement.querySelector('tr').children);
        if (!this.parent.activeView.isTimelineView() && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.isAdaptive && !this.parent.enableAdaptiveUI && !this.parent.virtualScrollModule) {
            trElement = this.getResourceElements(trElement);
        }
        var leftOffset = trElement[0].getBoundingClientRect();
        var rightOffset = trElement.slice(-1)[0].getBoundingClientRect();
        var viewDimension = {
            bottom: viewElement.scrollHeight - 5,
            left: this.parent.enableRtl ? rightOffset.left : leftOffset.left,
            right: this.parent.enableRtl ? leftOffset.right : rightOffset.right,
            top: 0,
            leftOffset: this.parent.enableRtl ? rightOffset.right : leftOffset.right,
            rightOffset: this.parent.enableRtl ? leftOffset.left : rightOffset.left
        };
        return viewDimension;
    };
    ActionBase.prototype.getIndex = function (index) {
        var contentElements = [].slice.call(this.parent.getContentTable().querySelector('tr').children);
        var indexes = { minIndex: 0, maxIndex: contentElements.length - 1 };
        if (this.actionObj.action === 'resize' && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.uiStateValues.isGroupAdaptive && !this.parent.activeView.isTimelineView()) {
            var groupElements = this.getResourceElements(contentElements);
            indexes.minIndex = groupElements[0].cellIndex;
            indexes.maxIndex = groupElements.slice(-1)[0].cellIndex;
        }
        if (index < indexes.minIndex) {
            index = indexes.minIndex;
        }
        if (index > indexes.maxIndex) {
            index = indexes.maxIndex;
        }
        return index;
    };
    ActionBase.prototype.updateTimePosition = function (date, multiData) {
        var index = 0;
        for (var _i = 0, _a = this.actionObj.cloneElement; _i < _a.length; _i++) {
            var cloneElement = _a[_i];
            var timeElement = cloneElement.querySelector('.' + APPOINTMENT_TIME);
            if (timeElement) {
                var startTime = this.actionObj.start;
                var endTime = this.actionObj.end;
                if (multiData && multiData.length > 0) {
                    startTime = multiData[parseInt(index.toString(), 10)][this.parent.eventFields.startTime];
                    endTime = multiData[parseInt(index.toString(), 10)][this.parent.eventFields.endTime];
                }
                timeElement.innerHTML = this.parent.getTimeString(startTime) + ' - ' +
                    this.parent.getTimeString(endTime);
            }
            index++;
        }
        if (!this.parent.activeViewOptions.timeScale.enable || !this.parent.isAdaptive || this.parent.currentView === 'Month' ||
            this.parent.currentView === 'TimelineMonth') {
            return;
        }
        var timeIndicator = this.parent.element.querySelector('.' + CLONE_TIME_INDICATOR_CLASS);
        if (!timeIndicator) {
            timeIndicator = createElement('div', { className: CLONE_TIME_INDICATOR_CLASS });
            var wrapperClass = this.parent.activeView.isTimelineView() ? DATE_HEADER_WRAP_CLASS : TIME_CELLS_WRAP_CLASS;
            this.parent.element.querySelector('.' + wrapperClass).appendChild(timeIndicator);
        }
        timeIndicator.innerHTML = this.parent.getTimeString(date);
        var offsetValue = 0;
        if (this.parent.activeView.isTimelineView()) {
            if (this.parent.enableRtl) {
                var rightValue = parseInt(this.actionObj.clone.style.right, 10);
                offsetValue = this.actionObj.action === 'drag' || this.resizeEdges.left ?
                    rightValue + this.actionObj.clone.offsetWidth : rightValue;
                timeIndicator.style.right = formatUnit(offsetValue);
            }
            else {
                var leftValue = parseInt(this.actionObj.clone.style.left, 10);
                offsetValue = this.actionObj.action === 'drag' || this.resizeEdges.left ?
                    leftValue : leftValue + this.actionObj.clone.offsetWidth;
                timeIndicator.style.left = formatUnit(offsetValue);
            }
        }
        else {
            offsetValue = this.actionObj.action === 'drag' || this.resizeEdges.top ? this.actionObj.clone.offsetTop :
                this.actionObj.clone.offsetTop + this.actionObj.clone.offsetHeight;
            timeIndicator.style.top = formatUnit(offsetValue);
        }
    };
    ActionBase.prototype.getResourceElements = function (table) {
        var _this = this;
        return table.filter(function (element) {
            return parseInt(element.getAttribute('data-group-index'), 10) === _this.actionObj.groupIndex;
        });
    };
    ActionBase.prototype.getOriginalElement = function (element) {
        var originalElement;
        var guid = element.getAttribute('data-guid');
        var isMorePopup = element.offsetParent && element.offsetParent.classList.contains(MORE_EVENT_POPUP_CLASS);
        if (isMorePopup || this.parent.activeView.isTimelineView() || (this.actionObj.action !== 'resize' && this.parent.virtualScrollModule)) {
            originalElement = [].slice.call(this.parent.element.querySelectorAll('[data-guid="' + guid + '"]'));
        }
        else {
            var tr = closest(element, 'tr');
            if (tr) {
                originalElement = [].slice.call(tr.querySelectorAll('[data-guid="' + guid + '"]'));
            }
        }
        return originalElement;
    };
    ActionBase.prototype.createCloneElement = function (element) {
        var cloneWrapper = createElement('div', { innerHTML: element.outerHTML });
        var cloneElement = cloneWrapper.children[0];
        var cloneClassLists = [CLONE_ELEMENT_CLASS];
        cloneClassLists.push((this.actionObj.action === 'drag') ? DRAG_CLONE_CLASS : RESIZE_CLONE_CLASS);
        if (this.parent.currentView === 'Month' || this.parent.currentView === 'TimelineMonth') {
            cloneClassLists.push(MONTH_CLONE_ELEMENT_CLASS);
        }
        addClass([cloneElement], cloneClassLists);
        addClass([element], EVENT_ACTION_CLASS);
        if (!isNullOrUndefined(element.parentElement)) {
            element.parentElement.appendChild(cloneElement);
        }
        cloneElement.style.width = formatUnit(cloneElement.offsetWidth - 2);
        var dragElement = document.querySelector(this.parent.eventDragArea);
        if (this.parent.eventDragArea && this.actionObj.action === 'drag' && dragElement) {
            dragElement.appendChild(cloneElement);
        }
        setStyleAttribute(cloneElement, { border: '0px' });
        return cloneElement;
    };
    ActionBase.prototype.removeCloneElementClasses = function () {
        var elements = this.actionObj.originalElement;
        if (this.parent.currentView === 'Month' || this.parent.currentView === 'TimelineYear' ||
            this.parent.currentView === 'Day' || this.parent.currentView === 'Week' || this.parent.currentView === 'WorkWeek') {
            elements = [].slice.call(this.parent.element.querySelectorAll('.' + EVENT_ACTION_CLASS));
        }
        removeClass(elements, EVENT_ACTION_CLASS);
    };
    ActionBase.prototype.removeCloneElement = function () {
        this.actionObj.originalElement = [];
        var dynamicEle = [].slice.call(this.parent.element.querySelectorAll('.e-dynamic-clone'));
        for (var _i = 0, dynamicEle_1 = dynamicEle; _i < dynamicEle_1.length; _i++) {
            var cloneEle = dynamicEle_1[_i];
            remove(cloneEle);
        }
        for (var _a = 0, _b = this.actionObj.cloneElement; _a < _b.length; _a++) {
            var cloneElement = _b[_a];
            if (!isNullOrUndefined(cloneElement.parentNode)) {
                remove(cloneElement);
            }
        }
        this.actionObj.cloneElement = [];
        var timeIndicator = this.parent.element.querySelector('.' + CLONE_TIME_INDICATOR_CLASS);
        if (timeIndicator) {
            remove(timeIndicator);
        }
    };
    ActionBase.prototype.getCursorElement = function (e) {
        var pages = this.parent.eventBase.getPageCoordinates(e);
        return document.elementFromPoint(pages.clientX, pages.clientY);
    };
    ActionBase.prototype.autoScroll = function () {
        var parent = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var yIsScrollable = parent.offsetHeight <= parent.scrollHeight;
        var xIsScrollable = parent.offsetWidth <= parent.scrollWidth;
        var yInBounds = yIsScrollable && parent.scrollTop >= 0 && parent.scrollTop + parent.offsetHeight <= parent.scrollHeight;
        var xInBounds = xIsScrollable && parent.scrollLeft >= 0 && parent.scrollLeft + parent.offsetWidth <= parent.scrollWidth;
        if (this.actionObj.action === 'resize' && this.scrollEdges.right && (parent.scrollLeft + parent.offsetWidth) > parent.scrollWidth) {
            var tdCollections = ([].slice.call(this.parent.getContentTable().querySelector('tr').children)).length - 1;
            var cellIndex = Math.ceil((this.actionObj.clone.offsetLeft + (this.actionObj.clone.offsetWidth)) /
                this.actionObj.cellWidth);
            xInBounds = cellIndex === tdCollections;
        }
        if (yInBounds && (this.scrollEdges.top || this.scrollEdges.bottom)) {
            parent.scrollTop += this.scrollEdges.top ? -this.actionObj.scroll.scrollBy : this.actionObj.scroll.scrollBy;
            if (this.actionObj.action === 'resize') {
                if (parent.scrollHeight !== parent.offsetHeight + parent.scrollTop && parent.scrollTop > 0) {
                    this.actionObj.Y += this.scrollEdges.top ? this.actionObj.scroll.scrollBy : -this.actionObj.scroll.scrollBy;
                }
            }
        }
        if (xInBounds && (this.scrollEdges.left || this.scrollEdges.right)) {
            parent.scrollLeft += this.scrollEdges.left ? -this.actionObj.scroll.scrollBy : this.actionObj.scroll.scrollBy;
            if (this.actionObj.action === 'resize') {
                if (parent.scrollWidth !== parent.offsetWidth + parent.scrollLeft && parent.scrollLeft > 0) {
                    this.actionObj.X += this.scrollEdges.left ? this.actionObj.scroll.scrollBy : -this.actionObj.scroll.scrollBy;
                }
            }
        }
    };
    ActionBase.prototype.autoScrollValidation = function () {
        if (!this.actionObj.scroll.enable) {
            return false;
        }
        var res = this.parent.boundaryValidation(this.actionObj.pageY, this.actionObj.pageX);
        this.scrollEdges = res;
        return res.bottom || res.top || res.left || res.right;
    };
    ActionBase.prototype.actionClass = function (type) {
        if (type === 'addClass') {
            addClass([this.parent.element], EVENT_ACTION_CLASS);
        }
        else {
            removeClass([this.parent.element], EVENT_ACTION_CLASS);
        }
    };
    ActionBase.prototype.updateScrollPosition = function (e) {
        var _this = this;
        this.scrollEventArgs = e;
        if (this.actionObj.scroll.enable && isNullOrUndefined(this.actionObj.scrollInterval)) {
            this.actionObj.scrollInterval = window.setInterval(function () {
                if (_this.autoScrollValidation() && !_this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
                    if (_this.parent.activeView.isTimelineView() && _this.parent.activeViewOptions.group.resources.length > 0
                        && _this.actionObj.groupIndex < 0) {
                        return;
                    }
                    _this.autoScroll();
                    if (_this.actionObj.action === 'drag') {
                        _this.parent.dragAndDropModule.updateDraggingDateTime(_this.scrollEventArgs);
                    }
                    else {
                        _this.parent.resizeModule.updateResizingDirection(_this.scrollEventArgs);
                    }
                }
            }, this.actionObj.scroll.timeDelay);
        }
    };
    ActionBase.prototype.updateOriginalElement = function (cloneElement) {
        var query = '[data-id="' + cloneElement.getAttribute('data-id') + '"]';
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            query = query.concat('[data-group-index = "' + cloneElement.getAttribute('data-group-index') + '"]');
        }
        var elements = [].slice.call(this.parent.element.querySelectorAll(query));
        addClass(elements, EVENT_ACTION_CLASS);
        var eventWrappers = [].slice.call(this.parent.element.querySelectorAll('.' + CLONE_ELEMENT_CLASS));
        removeClass(eventWrappers, EVENT_ACTION_CLASS);
    };
    ActionBase.prototype.getUpdatedEvent = function (startTime, endTime, eventObj) {
        var event = JSON.parse(JSON.stringify(eventObj));
        event[this.parent.eventFields.startTime] = startTime;
        event[this.parent.eventFields.endTime] = endTime;
        return event;
    };
    ActionBase.prototype.dynamicYearlyEventsRendering = function (event, isResize) {
        if (isResize === void 0) { isResize = false; }
        if (!isNullOrUndefined(this.parent.eventDragArea)) {
            return;
        }
        var appWidth = this.actionObj.cellWidth - 7;
        if (isResize && (this.resizeEdges.left || this.resizeEdges.right)) {
            appWidth = this.actionObj.cellWidth * event.count;
        }
        if (!isResize && (this.parent.activeViewOptions.orientation === 'Horizontal' && this.parent.activeViewOptions.group.resources.length === 0)) {
            var eventObj = this.yearEvent.isSpannedEvent(event, event[this.parent.eventFields.startTime]);
            if (eventObj[this.parent.eventFields.startTime].getTime() ===
                eventObj[this.parent.eventFields.endTime].getTime()) {
                eventObj.isSpanned.count = 1;
            }
            appWidth = eventObj.isSpanned.count * this.actionObj.cellWidth;
        }
        if (!isResize && this.parent.activeViewOptions.orientation === 'Vertical' && this.parent.activeViewOptions.group.resources.length !== 0) {
            var eventObj = this.yearEvent.isSpannedEvent(event, event[this.parent.eventFields.startTime]);
            appWidth = eventObj.isSpanned.count * this.actionObj.cellWidth;
        }
        var appointmentElement = this.createAppointmentElement(this.actionObj.groupIndex, event[this.parent.eventFields.subject]);
        appointmentElement.setAttribute('drag', 'true');
        addClass([appointmentElement], CLONE_ELEMENT_CLASS);
        setStyleAttribute(appointmentElement, {
            'width': appWidth + 'px', 'border': '0px', 'pointer-events': 'none',
            'position': 'absolute', 'overflow': 'hidden', 'padding': '3px'
        });
        if (this.actionObj.clone.style.backgroundColor !== '') {
            setStyleAttribute(appointmentElement, { 'backgroundColor': this.actionObj.clone.style.backgroundColor });
        }
        var date = resetTime(event[this.parent.eventFields.startTime]).getTime();
        var query = '.' + WORK_CELLS_CLASS + '[data-date="' + date + '"]';
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            query = '.' + WORK_CELLS_CLASS + '[data-date="' + date + '"][data-group-index="' + this.actionObj.groupIndex + '"]';
        }
        var cellTd = this.parent.element.querySelector(query);
        if (isNullOrUndefined(cellTd)) {
            return;
        }
        if (isResize) {
            var dateHeader = cellTd.querySelector('.' + DATE_HEADER_CLASS);
            var appHeight = this.actionObj.cellHeight * event.count -
                (dateHeader ? dateHeader.offsetHeight : 0) - 7;
            if (this.resizeEdges.right || this.resizeEdges.left) {
                appHeight = parseInt(this.actionObj.clone.style.height, 10);
            }
            setStyleAttribute(appointmentElement, { 'height': appHeight + 'px' });
        }
        this.renderDynamicElement(cellTd, appointmentElement, true);
        this.actionObj.cloneElement.push(appointmentElement);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ActionBase.prototype.renderDynamicElement = function (cellTd, element, isAppointment) {
        if (isAppointment === void 0) { isAppointment = false; }
        if (cellTd.querySelector('.' + APPOINTMENT_WRAPPER_CLASS)) {
            cellTd.querySelector('.' + APPOINTMENT_WRAPPER_CLASS).appendChild(element);
        }
        else {
            var wrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            wrapper.appendChild(element);
            cellTd.appendChild(wrapper);
        }
    };
    ActionBase.prototype.createAppointmentElement = function (resIndex, innerText) {
        var appointmentWrapper = createElement('div', {
            className: APPOINTMENT_CLASS,
            innerHTML: innerText
        });
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            appointmentWrapper.setAttribute('data-group-index', resIndex.toString());
        }
        return appointmentWrapper;
    };
    ActionBase.prototype.dynamicEventsRendering = function (event) {
        var _this = this;
        if (!isNullOrUndefined(this.parent.eventDragArea)) {
            return;
        }
        var dateRender = this.parent.activeView.renderDates;
        var workCells = [].slice.call(this.parent.element.querySelectorAll('.' + WORK_CELLS_CLASS));
        var workDays = this.parent.activeViewOptions.workDays;
        var groupOrder;
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var renderedResource = this.parent.virtualScrollModule && this.parent.virtualScrollModule.isHorizontalScroll ?
                this.parent.resourceBase.renderedResources : this.parent.resourceBase.lastResourceLevel;
            var resources = renderedResource.
                filter(function (res) { return res.groupIndex === _this.actionObj.groupIndex; });
            dateRender = resources[0].renderDates;
            var elementSelector = "." + WORK_CELLS_CLASS + "[data-group-index=\"" + this.actionObj.groupIndex + "\"]";
            workCells = [].slice.call(this.parent.element.querySelectorAll(elementSelector));
            workDays = resources[0].workDays;
            groupOrder = resources[0].groupOrder;
        }
        this.monthEvent.dateRender = dateRender;
        this.monthEvent.getSlotDates(workDays);
        if (this.resizeEdges.left || this.resizeEdges.right) {
            var eventWrappers = [].slice.call(this.parent.element.querySelectorAll('.' + CLONE_ELEMENT_CLASS));
            for (var _i = 0, eventWrappers_1 = eventWrappers; _i < eventWrappers_1.length; _i++) {
                var wrapper = eventWrappers_1[_i];
                remove(wrapper);
            }
        }
        var spannedEvents = this.monthEvent.splitEvent(event, dateRender);
        for (var _a = 0, spannedEvents_1 = spannedEvents; _a < spannedEvents_1.length; _a++) {
            var event_1 = spannedEvents_1[_a];
            var day = this.parent.getIndexOfDate(dateRender, resetTime(event_1[this.monthEvent.fields.startTime]));
            var diffInDays = event_1.data.count;
            var appWidth = (diffInDays * this.actionObj.cellWidth) - 7;
            var appointmentElement = this.monthEvent.createAppointmentElement(event_1, this.actionObj.groupIndex, true);
            appointmentElement.setAttribute('drag', 'true');
            addClass([appointmentElement], CLONE_ELEMENT_CLASS);
            this.monthEvent.applyResourceColor(appointmentElement, event_1, 'backgroundColor', groupOrder);
            setStyleAttribute(appointmentElement, { 'width': appWidth + 'px', 'border': '0px', 'pointer-events': 'none' });
            var cellTd = workCells[parseInt(day.toString(), 10)];
            if (cellTd) {
                this.monthEvent.renderElement(cellTd, appointmentElement, true);
                this.actionObj.cloneElement.push(appointmentElement);
            }
        }
    };
    ActionBase.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.actionObj = {};
        this.scrollArgs = {};
        this.resizeEdges = { left: false, right: false, top: false, bottom: false };
        this.scrollEdges = { left: false, right: false, top: false, bottom: false };
    };
    return ActionBase;
}());

var __extends$14 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Schedule events resize actions
 */
var Resize = /** @__PURE__ @class */ (function (_super) {
    __extends$14(Resize, _super);
    function Resize() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Resize.prototype.wireResizeEvent = function (element) {
        var resizeElement = [].slice.call(element.querySelectorAll('.' + EVENT_RESIZE_CLASS));
        for (var _i = 0, resizeElement_1 = resizeElement; _i < resizeElement_1.length; _i++) {
            var element_1 = resizeElement_1[_i];
            EventHandler.add(element_1, Browser.touchStartEvent, this.resizeStart, this);
        }
    };
    Resize.prototype.resizeHelper = function () {
        if (this.parent.activeViewOptions.group.resources.length > 0 && this.parent.activeViewOptions.group.allowGroupEdit) {
            for (var i = 0, len = this.actionObj.originalElement.length; i < len; i++) {
                var cloneElement = this.createCloneElement(this.actionObj.originalElement[parseInt(i.toString(), 10)]);
                this.actionObj.cloneElement[parseInt(i.toString(), 10)] = cloneElement;
                if (this.actionObj.element === this.actionObj.originalElement[parseInt(i.toString(), 10)]) {
                    this.actionObj.clone = cloneElement;
                }
            }
        }
        else {
            if (this.actionObj.element) {
                this.actionObj.clone = this.createCloneElement(this.actionObj.element);
            }
            this.actionObj.cloneElement = [this.actionObj.clone];
            this.actionObj.originalElement = [this.actionObj.element];
        }
    };
    Resize.prototype.resizeStart = function (e) {
        var _this = this;
        this.actionObj.action = 'resize';
        this.actionObj.slotInterval = this.parent.activeViewOptions.timeScale.interval / this.parent.activeViewOptions.timeScale.slotCount;
        this.actionObj.interval = this.actionObj.slotInterval;
        var resizeTarget = closest(e.target, '.' + EVENT_RESIZE_CLASS);
        this.actionObj.element = closest(resizeTarget, '.' + APPOINTMENT_CLASS);
        this.actionObj.event = this.parent.eventBase.getEventByGuid(this.actionObj.element.getAttribute('data-guid'));
        var eventObj = extend({}, this.actionObj.event, null, true);
        var resizeArgs = {
            cancel: false,
            data: eventObj,
            element: this.actionObj.element,
            event: e,
            interval: this.actionObj.interval,
            scroll: { enable: true, scrollBy: 30, timeDelay: 100 }
        };
        this.parent.trigger(resizeStart, resizeArgs, function (resizeEventArgs) {
            if (resizeEventArgs.cancel) {
                return;
            }
            _this.actionClass('addClass');
            _this.parent.uiStateValues.action = true;
            _this.resizeEdges = {
                left: resizeTarget.classList.contains(LEFT_RESIZE_HANDLER),
                right: resizeTarget.classList.contains(RIGHT_RESIZE_HANDLER),
                top: resizeTarget.classList.contains(TOP_RESIZE_HANDLER),
                bottom: resizeTarget.classList.contains(BOTTOM_RESIZE_HANDLER)
            };
            _this.actionObj.groupIndex = _this.parent.uiStateValues.isGroupAdaptive ? _this.parent.uiStateValues.groupIndex : 0;
            var workCell = _this.parent.element.querySelector('.' + WORK_CELLS_CLASS).getBoundingClientRect();
            _this.actionObj.cellWidth = workCell.width;
            _this.actionObj.cellHeight = workCell.height;
            var hRows = _this.parent.activeViewOptions.headerRows.map(function (row) { return row.option; });
            if (_this.parent.activeView.isTimelineView() && hRows.length > 0 && ['Date', 'Hour'].indexOf(hRows.slice(-1)[0]) < 0) {
                var tr = _this.parent.getContentTable().querySelector('tr');
                var noOfDays = 0;
                var tdCollections = [].slice.call(tr.children);
                for (var _i = 0, tdCollections_1 = tdCollections; _i < tdCollections_1.length; _i++) {
                    var td = tdCollections_1[_i];
                    noOfDays += parseInt(td.getAttribute('colspan'), 10);
                }
                var trRect = tr.getBoundingClientRect();
                _this.actionObj.cellWidth = trRect.width / noOfDays;
                _this.actionObj.cellHeight = trRect.height;
            }
            var pages = _this.parent.eventBase.getPageCoordinates(e);
            _this.actionObj.X = pages.pageX;
            _this.actionObj.Y = pages.pageY;
            _this.actionObj.groupIndex = parseInt(_this.actionObj.element.getAttribute('data-group-index') || '0', 10);
            _this.actionObj.interval = resizeEventArgs.interval;
            _this.actionObj.scroll = resizeEventArgs.scroll;
            _this.actionObj.start = new Date(eventObj[_this.parent.eventFields.startTime].getTime());
            _this.actionObj.end = new Date(eventObj[_this.parent.eventFields.endTime].getTime());
            _this.actionObj.originalElement = _this.getOriginalElement(_this.actionObj.element);
            if (_this.parent.currentView === 'Month') {
                _this.daysVariation = -1;
                _this.monthEvent = new MonthEvent(_this.parent);
            }
            var viewElement = _this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
            _this.scrollArgs = { element: viewElement, width: viewElement.scrollWidth, height: viewElement.scrollHeight };
            EventHandler.add(document, Browser.touchMoveEvent, _this.resizing, _this);
            EventHandler.add(document, Browser.touchEndEvent, _this.resizeStop, _this);
        });
    };
    Resize.prototype.resizing = function (e) {
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide();
        }
        if (this.parent.element.querySelectorAll('.' + RESIZE_CLONE_CLASS).length === 0) {
            this.resizeHelper();
        }
        if ((!isNullOrUndefined(e.target)) && e.target.classList.contains(DISABLE_DATES)) {
            return;
        }
        var pages = this.parent.eventBase.getPageCoordinates(e);
        if (this.parent.currentView === 'Month' || this.parent.currentView === 'TimelineYear') {
            var doc = document.documentElement;
            var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
            var top_1 = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            this.actionObj.pageX = pages.pageX - left;
            this.actionObj.pageY = pages.pageY - top_1;
        }
        else {
            this.actionObj.pageX = pages.pageX;
            this.actionObj.pageY = pages.pageY;
        }
        this.updateScrollPosition(e);
        this.updateResizingDirection(e);
        var eventObj = extend({}, this.actionObj.event, null, true);
        var resizeArgs = {
            cancel: false,
            data: eventObj,
            element: this.actionObj.element,
            event: e,
            startTime: this.actionObj.start,
            endTime: this.actionObj.end
        };
        if (this.parent.group.resources.length > 0) {
            resizeArgs.groupIndex = this.actionObj.groupIndex;
        }
        this.parent.trigger(resizing, resizeArgs);
    };
    Resize.prototype.updateResizingDirection = function (e) {
        if (this.parent.currentView === 'Month' || this.parent.currentView === 'TimelineYear') {
            this.monthResizing();
            return;
        }
        var resizeValidation = this.resizeValidation(e);
        if (this.resizeEdges.left) {
            if (resizeValidation) {
                var leftStyles = this.getLeftRightStyles(e, true);
                if (parseInt(leftStyles.width, 10) < 1) {
                    return;
                }
                for (var _i = 0, _a = this.actionObj.cloneElement; _i < _a.length; _i++) {
                    var cloneElement = _a[_i];
                    setStyleAttribute(cloneElement, leftStyles);
                    addClass([cloneElement], LEFT_RESIZE_HANDLER);
                }
            }
            this.horizontalResizing(!this.parent.enableRtl);
        }
        if (this.resizeEdges.right) {
            if (resizeValidation) {
                var rightStyles = this.getLeftRightStyles(e, false);
                if (parseInt(rightStyles.width, 10) < 1) {
                    return;
                }
                for (var _b = 0, _c = this.actionObj.cloneElement; _b < _c.length; _b++) {
                    var cloneElement = _c[_b];
                    setStyleAttribute(cloneElement, rightStyles);
                    addClass([cloneElement], RIGHT_RESIZE_HANDLER);
                }
            }
            this.horizontalResizing(this.parent.enableRtl);
        }
        if (this.resizeEdges.top) {
            if (resizeValidation) {
                var topStyles = this.getTopBottomStyles(e, true);
                if (parseInt(topStyles.height, 10) < 1) {
                    return;
                }
                for (var _d = 0, _e = this.actionObj.cloneElement; _d < _e.length; _d++) {
                    var cloneElement = _e[_d];
                    setStyleAttribute(cloneElement, topStyles);
                    addClass([cloneElement], TOP_RESIZE_HANDLER);
                }
            }
            this.verticalResizing(true);
        }
        if (this.resizeEdges.bottom) {
            if (resizeValidation) {
                var bottomStyles = this.getTopBottomStyles(e, false);
                if (parseInt(bottomStyles.height, 10) < 1) {
                    return;
                }
                for (var _f = 0, _g = this.actionObj.cloneElement; _f < _g.length; _f++) {
                    var cloneElement = _g[_f];
                    setStyleAttribute(cloneElement, bottomStyles);
                    addClass([cloneElement], BOTTOM_RESIZE_HANDLER);
                }
            }
            this.verticalResizing(false);
        }
    };
    Resize.prototype.monthResizing = function () {
        this.removeCloneElement();
        if (isNullOrUndefined(this.actionObj.pageX) || isNullOrUndefined(this.actionObj.pageY)) {
            return;
        }
        var td = document.elementFromPoint(this.actionObj.pageX, this.actionObj.pageY);
        if (isNullOrUndefined(td)) {
            return;
        }
        var resizeTime = this.parent.getDateFromElement(td);
        var isSameCell = this.parent.activeViewOptions.group.resources.length > 0 ?
            parseInt(td.getAttribute('data-group-index'), 10) === this.actionObj.groupIndex : true;
        var startTime = new Date(this.actionObj.event[this.parent.eventFields.startTime].getTime());
        var endTime = new Date(this.actionObj.event[this.parent.eventFields.endTime].getTime());
        if ((!this.parent.enableRtl && this.resizeEdges.left) || (this.parent.enableRtl && this.resizeEdges.right)
            || this.resizeEdges.top) {
            startTime = resizeTime;
        }
        else if ((!this.parent.enableRtl && this.resizeEdges.right) || (this.parent.enableRtl && this.resizeEdges.left)
            || this.resizeEdges.bottom) {
            endTime = addDays(resizeTime, 1);
        }
        if (isSameCell && startTime < endTime) {
            this.actionObj.start = startTime;
            this.actionObj.end = endTime;
            var event_1 = this.getUpdatedEvent(this.actionObj.start, this.actionObj.end, this.actionObj.event);
            if (this.parent.currentView === 'TimelineYear') {
                this.yearEventsRendering(event_1);
            }
            else {
                this.dynamicEventsRendering(event_1);
            }
            this.updateOriginalElement(this.actionObj.clone);
        }
    };
    Resize.prototype.yearEventsRendering = function (event) {
        var eventWrappers = [].slice.call(this.parent.element.querySelectorAll('.' + CLONE_ELEMENT_CLASS));
        for (var _i = 0, eventWrappers_1 = eventWrappers; _i < eventWrappers_1.length; _i++) {
            var wrapper = eventWrappers_1[_i];
            remove(wrapper);
        }
        var endDate = new Date(event[this.parent.eventFields.endTime]);
        var monthDiff = 0;
        if (this.parent.activeViewOptions.group.resources.length === 0) {
            monthDiff = this.getMonthDiff(event[this.parent.eventFields.startTime], addDays(endDate, -1));
        }
        for (var i = 0; i <= monthDiff; i++) {
            var eventObj = void 0;
            if (this.parent.activeViewOptions.group.resources.length === 0) {
                eventObj = this.getEventCount(event, this.actionObj.start.getMonth() + i);
            }
            else {
                eventObj = extend({}, event, null, true);
                endDate = this.resizeEdges.left || this.resizeEdges.right ? addDays(endDate, -1) : endDate;
                eventObj.count = this.getMonthDiff(event[this.parent.eventFields.startTime], endDate) + 1;
            }
            this.dynamicYearlyEventsRendering(eventObj, true);
        }
    };
    Resize.prototype.getMonthDiff = function (startDate, endDate) {
        var months;
        months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth();
        months += endDate.getMonth();
        return months <= 0 ? 0 : months;
    };
    Resize.prototype.getEventCount = function (eventObj, month) {
        var eventData = extend({}, eventObj, null, true);
        var eventStart = eventData[this.parent.eventFields.startTime];
        var eventEnd = eventData[this.parent.eventFields.endTime];
        var monthStart = new Date(this.parent.selectedDate.getFullYear(), month, 1);
        var monthEnd = addDays(new Date(this.parent.selectedDate.getFullYear(), month + 1, 0), 1);
        var count = 1;
        if (eventStart.getTime() < monthStart.getTime()) {
            eventData[this.parent.eventFields.startTime] = monthStart;
        }
        if (eventEnd.getTime() > monthEnd.getTime()) {
            eventData[this.parent.eventFields.endTime] = monthEnd;
        }
        if (this.parent.activeViewOptions.group.resources.length === 0) {
            count = Math.ceil((eventData[this.parent.eventFields.endTime].getTime() -
                eventData[this.parent.eventFields.startTime].getTime()) / MS_PER_DAY);
        }
        eventData.count = count;
        return eventData;
    };
    Resize.prototype.resizeStop = function (e) {
        var _this = this;
        EventHandler.remove(document, Browser.touchMoveEvent, this.resizing);
        EventHandler.remove(document, Browser.touchEndEvent, this.resizeStop);
        clearInterval(this.actionObj.scrollInterval);
        this.actionObj.scrollInterval = null;
        this.removeCloneElementClasses();
        this.removeCloneElement();
        this.actionClass('removeClass');
        this.parent.uiStateValues.action = false;
        var resizeArgs = { cancel: false, data: this.getChangedData(), element: this.actionObj.element, event: e };
        this.parent.trigger(resizeStop, resizeArgs, function (resizeEventArgs) {
            if (resizeEventArgs.cancel) {
                return;
            }
            if (_this.parent.isSpecificResourceEvents()) {
                _this.parent.crudModule.crudObj.sourceEvent =
                    [_this.parent.resourceBase.lastResourceLevel[parseInt(resizeEventArgs.element.getAttribute('data-group-index'), 10)]];
                _this.parent.crudModule.crudObj.targetEvent = _this.parent.crudModule.crudObj.sourceEvent;
                _this.parent.crudModule.crudObj.isCrudAction = true;
            }
            _this.saveChangedData(resizeEventArgs);
        });
    };
    Resize.prototype.verticalResizing = function (isTop) {
        var offsetValue = this.actionObj.clone.offsetTop;
        if (!isTop) {
            offsetValue += this.actionObj.clone.offsetHeight;
        }
        var minutes = (offsetValue / this.actionObj.cellHeight) * this.actionObj.slotInterval;
        var element = this.actionObj.clone.offsetParent;
        if (isNullOrUndefined(element)) {
            return;
        }
        var resizeTime = resetTime(this.parent.getDateFromElement(element));
        resizeTime.setHours(this.parent.activeView.getStartHour().getHours());
        resizeTime.setMinutes(minutes + this.parent.activeView.getStartHour().getMinutes());
        if (isTop) {
            this.actionObj.start = this.calculateIntervalTime(resizeTime);
        }
        else {
            this.actionObj.end = this.calculateIntervalTime(resizeTime);
        }
        this.updateTimePosition(resizeTime);
    };
    Resize.prototype.horizontalResizing = function (isLeft) {
        var eventStart = new Date(this.actionObj.event[this.parent.eventFields.startTime].getTime());
        var eventEnd = new Date(this.actionObj.event[this.parent.eventFields.endTime].getTime());
        var resizeTime;
        var isDateHeader = false;
        var headerName = this.parent.currentView;
        var isTimeViews = ['TimelineDay', 'TimelineWeek', 'TimelineWorkWeek'].indexOf(this.parent.currentView) > -1;
        var isTimelineMonth = this.parent.currentView === 'TimelineMonth';
        var isWithoutScale = isTimelineMonth || isTimeViews && !this.parent.activeViewOptions.timeScale.enable;
        if (this.parent.activeView.isTimelineView()) {
            var tr = this.parent.getContentTable().querySelector('tr');
            if (this.parent.activeViewOptions.headerRows.length > 0) {
                var rows = this.parent.activeViewOptions.headerRows.map(function (row) { return row.option; });
                headerName = rows.slice(-1)[0];
                if (isTimelineMonth && headerName === 'Hour') {
                    headerName = rows.slice(-2)[0] || 'Month';
                }
            }
            resizeTime = isLeft ? eventStart : eventEnd;
            var cellIndex = 0;
            var tdCollections = [].slice.call(tr.children);
            var isLastCell = false;
            var pixelsPerMinute = this.actionObj.cellWidth / (this.parent.activeViewOptions.timeScale.interval /
                this.parent.activeViewOptions.timeScale.slotCount);
            var offset = parseFloat(this.parent.enableRtl ? this.actionObj.clone.style.right :
                this.actionObj.clone.style.left);
            offset = Math.round(offset / pixelsPerMinute) * pixelsPerMinute;
            if (['Year', 'Month', 'Week', 'Date'].indexOf(headerName) !== -1) {
                var noOfDays = 0;
                for (var _i = 0, tdCollections_2 = tdCollections; _i < tdCollections_2.length; _i++) {
                    var td = tdCollections_2[_i];
                    noOfDays += parseInt(td.getAttribute('colspan'), 10);
                }
                var offsetValue = this.parent.enableRtl ? parseInt(this.actionObj.clone.style.right, 10) :
                    parseInt(this.actionObj.clone.style.left, 10);
                offsetValue = Math.round(offsetValue / this.actionObj.cellWidth) * this.actionObj.cellWidth;
                if (!isLeft) {
                    offsetValue += (this.actionObj.clone.getBoundingClientRect().width - this.actionObj.cellWidth);
                }
                cellIndex = Math.floor(offsetValue / Math.floor(tr.getBoundingClientRect().width / noOfDays));
                isDateHeader = isTimeViews && headerName === 'Date';
                cellIndex = isLeft ? cellIndex : isTimelineMonth ? cellIndex + 1 : cellIndex;
                isLastCell = cellIndex === tdCollections.length;
                cellIndex = (cellIndex < 0) ? 0 : (cellIndex >= noOfDays) ? noOfDays - 1 : cellIndex;
            }
            else {
                var cellWidth = this.actionObj.cellWidth;
                cellIndex = isLeft ? Math.floor(offset / this.actionObj.cellWidth) :
                    Math.ceil((offset + (this.actionObj.clone.getBoundingClientRect().width - cellWidth)) / this.actionObj.cellWidth);
                if (this.parent.enableRtl) {
                    var cellOffsetWidth = 0;
                    if (headerName === 'TimelineMonth' || (!this.parent.activeViewOptions.timeScale.enable &&
                        !isTimelineMonth)) {
                        cellOffsetWidth = this.actionObj.cellWidth;
                    }
                    var offsetWidth = (Math.floor(offset / this.actionObj.cellWidth) *
                        this.actionObj.cellWidth) + (isLeft ? 0 : this.actionObj.clone.getBoundingClientRect().width - cellOffsetWidth);
                    cellIndex = Math.floor(offsetWidth / this.actionObj.cellWidth);
                }
                isLastCell = cellIndex === tdCollections.length;
                cellIndex = this.getIndex(cellIndex);
            }
            var resizeDate = void 0;
            if (['Year', 'Month', 'Week', 'Date'].indexOf(headerName) !== -1) {
                resizeDate = new Date(this.parent.activeView.renderDates[parseInt(cellIndex.toString(), 10)].getTime());
            }
            else {
                resizeDate = this.parent.getDateFromElement(tr.children[parseInt(cellIndex.toString(), 10)]);
            }
            if (['TimelineMonth', 'Year', 'Month', 'Week', 'Date'].indexOf(headerName) !== -1 ||
                !this.parent.activeViewOptions.timeScale.enable) {
                resizeTime = new Date(resizeDate.setHours(resizeTime.getHours(), resizeTime.getMinutes(), resizeTime.getSeconds()));
            }
            else {
                if (!isLeft) {
                    offset += this.actionObj.clone.getBoundingClientRect().width;
                }
                var spanMinutes = Math.ceil((this.actionObj.slotInterval / this.actionObj.cellWidth) *
                    (offset - Math.floor(offset / this.actionObj.cellWidth) * this.actionObj.cellWidth));
                spanMinutes = (isLastCell || (!isLeft && spanMinutes === 0)) ? this.actionObj.slotInterval : spanMinutes;
                resizeTime = new Date(resizeDate.getTime());
                resizeTime.setMinutes(resizeTime.getMinutes() + spanMinutes);
                this.updateTimePosition(resizeTime);
            }
        }
        else {
            var cloneIndex = closest(this.actionObj.clone, 'td').cellIndex;
            var originalWidth = Math.ceil((isLeft ? this.actionObj.element.getBoundingClientRect().width : 0) /
                this.actionObj.cellWidth) * this.actionObj.cellWidth;
            var noOfDays = Math.ceil((this.actionObj.clone.getBoundingClientRect().width - originalWidth) /
                this.actionObj.cellWidth);
            var tr = closest(this.actionObj.clone, 'tr');
            var dayIndex = isLeft ? cloneIndex - noOfDays : cloneIndex + noOfDays - 1;
            dayIndex = this.getIndex(dayIndex);
            resizeTime = this.parent.getDateFromElement(tr.children[parseInt(dayIndex.toString(), 10)]);
            if (isLeft) {
                resizeTime.setHours(eventStart.getHours(), eventStart.getMinutes(), eventStart.getSeconds());
            }
            else {
                resizeTime.setHours(eventEnd.getHours(), eventEnd.getMinutes(), eventEnd.getSeconds());
            }
        }
        var isNotHourSlot = ['TimelineMonth', 'Year', 'Month', 'Week', 'Date'].indexOf(headerName) !== -1 ||
            !this.parent.activeViewOptions.timeScale.enable;
        if (isLeft) {
            if ((eventEnd.getTime() - resizeTime.getTime()) <= 0) {
                resizeTime = isWithoutScale ? resetTime(eventEnd) : eventStart;
            }
            this.actionObj.start = !isNotHourSlot ? this.calculateIntervalTime(resizeTime) : resizeTime;
        }
        else {
            var isTimeScaleViews = isTimeViews && this.parent.activeViewOptions.timeScale.enable;
            var resizeEnd = ((!isTimeScaleViews || isDateHeader || isTimeViews && ['Week', 'Month', 'Year'].indexOf(headerName) > -1)
                && resizeTime.getHours() === 0 && resizeTime.getMinutes() === 0) ? addDays(resizeTime, 1) : resizeTime;
            if (isWithoutScale && (resizeEnd.getTime() - eventStart.getTime()) <= 0) {
                resizeEnd = addDays(resetTime(eventStart), 1);
            }
            this.actionObj.end = !isNotHourSlot ? this.calculateIntervalTime(resizeEnd) : resizeEnd;
        }
    };
    Resize.prototype.getTopBottomStyles = function (e, isTop) {
        var viewElement = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var slotInterval = (this.actionObj.cellHeight / this.actionObj.slotInterval) * this.actionObj.interval;
        var clnHeight = isTop ? this.actionObj.element.offsetHeight + (this.actionObj.Y - this.actionObj.pageY) :
            this.actionObj.element.offsetHeight + (this.actionObj.pageY - this.actionObj.Y);
        var clnTop = isTop ? this.actionObj.element.offsetTop -
            (this.actionObj.Y - this.actionObj.pageY) : this.actionObj.clone.offsetTop;
        clnHeight = (clnTop < 0) ? this.actionObj.clone.offsetHeight :
            (this.actionObj.clone.offsetTop + this.actionObj.clone.offsetHeight) > this.scrollArgs.height ?
                this.actionObj.clone.offsetHeight : clnHeight;
        clnTop = (clnTop < 0) ? 0 : clnTop;
        clnTop = isTop ? Math.floor(clnTop / slotInterval) * slotInterval : clnTop;
        clnHeight = clnTop + clnHeight >= viewElement.scrollHeight ? viewElement.scrollHeight - clnTop :
            Math.ceil(clnHeight / slotInterval) * slotInterval;
        if (!isTop && this.actionObj.clone.offsetTop + clnHeight >= this.parent.getContentTable().offsetHeight) {
            clnHeight = this.parent.getContentTable().offsetHeight - this.actionObj.clone.offsetTop;
        }
        var styles = {
            height: formatUnit(clnHeight < this.actionObj.cellHeight ? Math.floor(clnHeight / slotInterval) * slotInterval : clnHeight),
            top: formatUnit((clnHeight < this.actionObj.cellHeight && isTop) ? Math.ceil(clnTop / slotInterval) * slotInterval : clnTop),
            left: '0px', right: '0px', width: '100%'
        };
        return styles;
    };
    Resize.prototype.getLeftRightStyles = function (e, isLeft) {
        var styles = {};
        var isTimelineView = this.parent.activeView.isTimelineView();
        var isTimeViews = ['TimelineDay', 'TimelineWeek', 'TimelineWorkWeek'].indexOf(this.parent.currentView) > -1 &&
            this.parent.activeViewOptions.timeScale.enable;
        var slotInterval = (this.actionObj.cellWidth / this.actionObj.slotInterval) * this.actionObj.interval;
        var pageWidth = isLeft ? (this.actionObj.X - this.actionObj.pageX) : (this.actionObj.pageX - this.actionObj.X);
        var targetWidth = isTimelineView ?
            (this.actionObj.element.getBoundingClientRect().width / this.actionObj.cellWidth) * this.actionObj.cellWidth :
            this.parent.currentView === 'Month' ? this.actionObj.element.getBoundingClientRect().width :
                Math.ceil(this.actionObj.element.getBoundingClientRect().width / this.actionObj.cellWidth) * this.actionObj.cellWidth;
        var offsetWidth = targetWidth + (Math.ceil(pageWidth / this.actionObj.cellWidth) * this.actionObj.cellWidth);
        var left = (this.parent.enableRtl) ? parseInt(this.actionObj.element.style.right, 10) : this.actionObj.clone.offsetLeft;
        if (isTimeViews) {
            offsetWidth = targetWidth + (Math.ceil(pageWidth / slotInterval) * slotInterval);
            offsetWidth = (Math.round((left + offsetWidth) / slotInterval) * slotInterval) - left;
            this.actionObj.event[this.parent.eventFields.isAllDay] = false;
        }
        var width = !isLeft && ((offsetWidth + this.actionObj.clone.offsetLeft > this.scrollArgs.width)) ?
            this.actionObj.clone.getBoundingClientRect().width : (offsetWidth < this.actionObj.cellWidth) ? offsetWidth : offsetWidth;
        if (this.parent.enableRtl) {
            var rightValue = isTimelineView ? parseInt(this.actionObj.element.style.right, 10) :
                -(offsetWidth - this.actionObj.cellWidth);
            rightValue = isTimelineView ? rightValue : isLeft ? 0 : rightValue > 0 ? 0 : rightValue;
            if (isTimelineView && !isLeft) {
                rightValue = Math.ceil((this.actionObj.element.offsetLeft + (this.actionObj.element.getBoundingClientRect().width +
                    (this.actionObj.pageX - this.actionObj.X))) / slotInterval) * slotInterval;
                rightValue = rightValue < 0 ? Math.abs(rightValue) : -rightValue;
            }
            rightValue = rightValue >= this.scrollArgs.width ? this.scrollArgs.width - this.actionObj.cellWidth : rightValue;
            styles.right = formatUnit(rightValue);
            width = width + rightValue > this.scrollArgs.width ? this.actionObj.clone.getBoundingClientRect().width : width;
        }
        else {
            var offsetLeft = isLeft ? this.actionObj.element.offsetLeft - (this.actionObj.X - this.actionObj.pageX) :
                this.parent.enableRtl ? this.actionObj.element.offsetLeft : 0;
            if (isTimelineView) {
                offsetLeft = isLeft ? offsetLeft : parseInt(this.actionObj.clone.style.left, 10);
                if (this.parent.enableRtl) {
                    offsetLeft = !isLeft ? (this.actionObj.pageX < this.actionObj.X - this.actionObj.clone.getBoundingClientRect().width)
                        ? parseInt(this.actionObj.clone.style.right, 10) : offsetLeft : offsetLeft;
                }
                else {
                    offsetLeft = isLeft ? (this.actionObj.pageX > this.actionObj.X + this.actionObj.clone.getBoundingClientRect().width &&
                        this.actionObj.clone.getBoundingClientRect().width === this.actionObj.cellWidth) ?
                        parseInt(this.actionObj.clone.style.left, 10) : offsetLeft : offsetLeft;
                }
            }
            var leftValue = offsetLeft;
            offsetLeft = isTimelineView ? isTimeViews ? isLeft ? Math.floor(offsetLeft / slotInterval) * slotInterval : offsetLeft :
                Math.floor(offsetLeft / this.actionObj.cellWidth) * this.actionObj.cellWidth :
                Math.ceil(Math.abs(offsetLeft) / this.actionObj.cellWidth) * this.actionObj.cellWidth;
            if (offsetLeft < 0) {
                offsetLeft = 0;
                width = this.actionObj.clone.getBoundingClientRect().width;
            }
            var cloneWidth = Math.ceil(this.actionObj.clone.getBoundingClientRect().width / this.actionObj.cellWidth) *
                this.actionObj.cellWidth;
            if (isLeft) {
                styles.left = formatUnit(isTimelineView ? offsetLeft : isLeft ? leftValue < 0 ? -offsetLeft :
                    (Math.ceil((targetWidth - cloneWidth) / this.actionObj.cellWidth) * this.actionObj.cellWidth) : offsetLeft);
            }
        }
        styles.width = formatUnit(width);
        return styles;
    };
    Resize.prototype.resizeValidation = function (e) {
        var pages = this.parent.eventBase.getPageCoordinates(e);
        var viewDimension = this.getContentAreaDimension();
        var isTimeScale = this.parent.activeView.isTimelineView() && this.parent.activeViewOptions.timeScale.enable;
        var cellWidth = this.actionObj.cellWidth;
        var resizeValidation = false;
        if (this.resizeEdges.left) {
            if (pages.pageX < viewDimension.leftOffset && pages.pageX >= viewDimension.left && isTimeScale) {
                cellWidth = 0;
            }
            resizeValidation = (pages.pageX - cellWidth) >= viewDimension.left;
        }
        if (this.resizeEdges.right) {
            if (pages.pageX > viewDimension.rightOffset && pages.pageX <= viewDimension.right && isTimeScale) {
                cellWidth = 0;
            }
            resizeValidation = (pages.pageX + cellWidth) <= viewDimension.right;
        }
        if (this.resizeEdges.top) {
            resizeValidation = this.actionObj.clone.offsetTop >= viewDimension.top;
        }
        if (this.resizeEdges.bottom) {
            resizeValidation = (this.actionObj.clone.offsetTop + this.actionObj.clone.offsetHeight) <= this.scrollArgs.height;
        }
        return resizeValidation;
    };
    /**
     * Get module name
     *
     * @returns {string} Returns the module name..
     */
    Resize.prototype.getModuleName = function () {
        return 'resize';
    };
    return Resize;
}(ActionBase));

var __extends$16 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
var EVENT_GAP$2 = 2;
/**
 * Year view events render
 */
var YearEvent = /** @__PURE__ @class */ (function (_super) {
    __extends$16(YearEvent, _super);
    function YearEvent(parent) {
        var _this = _super.call(this, parent, 'day') || this;
        _this.isResource = false;
        return _this;
    }
    YearEvent.prototype.renderAppointments = function () {
        if (this.parent.dragAndDropModule) {
            this.parent.dragAndDropModule.setDragArea();
        }
        this.fields = this.parent.eventFields;
        var elementSelector = (this.parent.currentView === 'Year') ? '.' + APPOINTMENT_CLASS :
            '.' + APPOINTMENT_WRAPPER_CLASS + ',.' + MORE_INDICATOR_CLASS;
        var eventWrappers = [].slice.call(this.parent.element.querySelectorAll(elementSelector));
        for (var _i = 0, eventWrappers_1 = eventWrappers; _i < eventWrappers_1.length; _i++) {
            var wrapper = eventWrappers_1[_i];
            remove(wrapper);
        }
        this.renderedEvents = [];
        if (this.parent.currentView === 'Year') {
            this.yearViewEvents();
        }
        else {
            this.removeCellHeight();
            if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                this.isResource = true;
                this.timelineResourceEvents();
            }
            else {
                this.timelineYearViewEvents();
            }
        }
        this.parent.renderTemplates();
        this.parent.notify(contentReady, {});
    };
    YearEvent.prototype.yearViewEvents = function () {
        var months = this.getMonths();
        for (var _i = 0, months_1 = months; _i < months_1.length; _i++) {
            var month = months_1[_i];
            var queryString = ".e-month-calendar:nth-child(" + (months.indexOf(month) + 1) + ") td.e-work-cells";
            var workCells = [].slice.call(this.parent.element.querySelectorAll(queryString));
            var monthDate = new Date(this.parent.selectedDate.getFullYear(), month, 1);
            var monthStart = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
            var monthEnd = this.parent.calendarUtil.getMonthEndDate(new Date(monthDate.getTime()));
            var startDate = getWeekFirstDate(monthStart, this.parent.firstDayOfWeek);
            var endDate = addDays(getWeekLastDate(monthEnd, this.parent.firstDayOfWeek), 1);
            for (var index = 0; startDate.getTime() < endDate.getTime(); index++) {
                var start = resetTime(new Date(startDate.getTime()));
                var end = addDays(new Date(start.getTime()), 1);
                startDate = addDays(new Date(startDate.getTime()), 1);
                if (!this.parent.isMinMaxDate(start)) {
                    continue;
                }
                var filterEvents = this.parent.eventBase.filterEvents(start, end);
                if (filterEvents.length > 0) {
                    var workCell = workCells[parseInt(index.toString(), 10)];
                    if (workCell) {
                        workCell.appendChild(createElement('div', { className: APPOINTMENT_CLASS }));
                    }
                }
            }
        }
    };
    YearEvent.prototype.timelineYearViewEvents = function () {
        var _this = this;
        var workCell = this.parent.element.querySelector('.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')');
        this.cellWidth = workCell.getBoundingClientRect().width;
        this.cellHeader = getOuterHeight(workCell.querySelector('.' + DATE_HEADER_CLASS));
        var eventTable = this.parent.element.querySelector('.' + EVENT_TABLE_CLASS);
        this.eventHeight = getElementHeightFromClass(eventTable, APPOINTMENT_CLASS);
        var wrapperCollection = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
        var months = this.getMonths();
        var groupIndex = (this.parent.activeViewOptions.group.resources.length > 0 && this.parent.uiStateValues.isGroupAdaptive) ?
            this.parent.uiStateValues.groupIndex : undefined;
        for (var row = 0; row < months.length; row++) {
            var wrapper = wrapperCollection[parseInt(row.toString(), 10)];
            var td = row + 1;
            var eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            wrapper.appendChild(eventWrapper);
            var monthStart = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(row.toString(), 10)], 1);
            var monthEnd = new Date(monthStart.getFullYear(), monthStart.getMonth() + 1, 0);
            var dayIndex = monthStart.getDay();
            var isSpannedCollection = [];
            if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                this.renderedEvents = [];
            }
            while (monthStart.getTime() <= monthEnd.getTime()) {
                var leftValue = void 0;
                var rightValue = void 0;
                if (this.parent.activeViewOptions.orientation === 'Vertical') {
                    var wrapper_1 = wrapperCollection[parseInt(dayIndex.toString(), 10)];
                    td = dayIndex + 1;
                    eventWrapper = wrapper_1.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
                    if (!eventWrapper) {
                        eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
                        wrapper_1.appendChild(eventWrapper);
                    }
                    if (this.parent.enableRtl) {
                        rightValue = row * this.cellWidth;
                    }
                    else {
                        leftValue = row * this.cellWidth;
                    }
                }
                else {
                    if (this.parent.enableRtl) {
                        rightValue = ((dayIndex + monthStart.getDate()) - 1) * this.cellWidth;
                    }
                    else {
                        leftValue = ((dayIndex + monthStart.getDate()) - 1) * this.cellWidth;
                    }
                }
                var rowTd = this.parent.element.querySelector(".e-content-wrap tr:nth-child(" + td + ") td");
                this.cellHeight = rowTd.offsetHeight;
                var dayStart = resetTime(new Date(monthStart.getTime()));
                var dayEnd = addDays(new Date(dayStart.getTime()), 1);
                var resource = void 0;
                if (this.parent.uiStateValues.isGroupAdaptive) {
                    resource = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
                }
                var dayEvents = this.parent.eventBase.filterEvents(dayStart, dayEnd, undefined, resource);
                dayEvents = this.parent.eventBase.sortByDateTime(dayEvents);
                var _loop_1 = function (index, count) {
                    var eventData = extend({}, dayEvents[parseInt(index.toString(), 10)], null, true);
                    this_1.updateSpannedEvents(eventData, dayStart, dayEnd);
                    var overlapIndex = this_1.getIndex(dayStart);
                    eventData.Index = overlapIndex;
                    var availedHeight = this_1.cellHeader + (this_1.eventHeight * (overlapIndex + 1)) + EVENT_GAP$2 +
                        this_1.moreIndicatorHeight;
                    var appArea = this_1.cellHeight - this_1.cellHeader - this_1.moreIndicatorHeight;
                    var renderedAppCount = Math.floor(appArea / (this_1.eventHeight + EVENT_GAP$2));
                    var moreIndicatorCount = (count - renderedAppCount) <= 0 ? 1 : (count - renderedAppCount);
                    if (this_1.parent.activeViewOptions.orientation === 'Horizontal') {
                        var isRendered = this_1.renderedEvents.filter(function (eventObj) {
                            return eventObj.Guid === eventData.Guid;
                        });
                        var isSpanned = isSpannedCollection.filter(function (eventObj) {
                            return eventObj.Guid === eventData.Guid;
                        });
                        if (isRendered.length > 0 && isRendered[0].MoreIndicator || isSpanned.length > 0 && isSpanned[0].MoreIndicator) {
                            var moreIndex = this_1.parent.activeViewOptions.orientation === 'Horizontal' ? row : dayIndex;
                            this_1.renderMoreIndicator(eventWrapper, moreIndicatorCount, dayStart, moreIndex, leftValue, rightValue, groupIndex);
                            return "continue";
                        }
                        else if (isRendered.length > 0 || isSpanned.length > 0) {
                            return "continue";
                        }
                    }
                    if (this_1.parent.rowAutoHeight || this_1.cellHeight > availedHeight) {
                        this_1.renderEvent(eventWrapper, eventData, row, leftValue, rightValue, monthStart, dayIndex);
                        this_1.updateCellHeight(rowTd, availedHeight);
                        isSpannedCollection.push(eventData);
                    }
                    else {
                        var moreIndex = this_1.parent.activeViewOptions.orientation === 'Horizontal' ? row : dayIndex;
                        this_1.renderMoreIndicator(eventWrapper, moreIndicatorCount, dayStart, moreIndex, leftValue, rightValue, groupIndex);
                        if (this_1.parent.activeViewOptions.orientation === 'Horizontal') {
                            eventData.MoreIndicator = true;
                            this_1.renderedEvents.push(eventData);
                            isSpannedCollection.push(eventData);
                        }
                    }
                };
                var this_1 = this;
                for (var index = 0, count = dayEvents.length; index < count; index++) {
                    _loop_1(index, count);
                }
                monthStart = addDays(new Date(monthStart.getTime()), 1);
                if (this.parent.activeViewOptions.orientation === 'Vertical') {
                    dayIndex++;
                    this.renderedEvents = [];
                }
            }
        }
        if (this.parent.rowAutoHeight && this.parent.activeViewOptions.orientation === 'Vertical') {
            var appContainer = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
            var tr_1 = [].slice.call(this.parent.element.querySelectorAll('.' + CONTENT_TABLE_CLASS + ' tbody tr'));
            appContainer.forEach(function (ele, index) {
                var app = [].slice.call(ele.querySelectorAll('.' + APPOINTMENT_CLASS));
                var appTop = tr_1[parseInt(index.toString(), 10)].offsetTop + _this.cellHeader + EVENT_GAP$2;
                app.forEach(function (app) {
                    var overlap = parseInt(app.getAttribute('data-index'), 10);
                    app.style.top = appTop + (overlap * _this.eventHeight) + 'px';
                    app.removeAttribute('data-index');
                });
            });
        }
    };
    YearEvent.prototype.updateSpannedEvents = function (eventObj, dayStart, dayEnd) {
        var isLeftRightResize = (this.isResource && this.parent.activeViewOptions.orientation === 'Vertical') ||
            (!this.isResource && this.parent.activeViewOptions.orientation === 'Horizontal');
        var data = { isLeft: true, isRight: true, isBottom: true, isTop: true };
        if (dayStart.getTime() <= eventObj[this.fields.startTime].getTime()) {
            if (isLeftRightResize) {
                data.isLeft = false;
            }
            else {
                data.isTop = false;
            }
        }
        if ((dayEnd.getTime() >= eventObj[this.fields.endTime].getTime()) || (isLeftRightResize && !this.isResource &&
            addDays(dayEnd, -1).getMonth() === eventObj[this.fields.endTime].getMonth()) ||
            (isLeftRightResize && this.isResource && (dayEnd.getTime() <= eventObj[this.fields.endTime].getTime()))) {
            if (isLeftRightResize) {
                data.isRight = false;
            }
            else {
                data.isBottom = false;
            }
        }
        eventObj.data = data;
    };
    YearEvent.prototype.timelineResourceEvents = function () {
        var _this = this;
        var contentTable = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var isVerticalScrollbarAvail = contentTable.offsetWidth > contentTable.clientWidth;
        var workCell = this.parent.element.querySelector('.' + WORK_CELLS_CLASS);
        this.cellWidth = workCell.getBoundingClientRect().width;
        this.cellHeader = 0;
        var eventTable = this.parent.element.querySelector('.' + EVENT_TABLE_CLASS);
        this.eventHeight = getElementHeightFromClass(eventTable, APPOINTMENT_CLASS);
        var wrapperCollection = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
        var resources = this.parent.uiStateValues.isGroupAdaptive ?
            [this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex]] :
            this.parent.activeViewOptions.allowVirtualScrolling ? this.parent.resourceBase.renderedResources :
                this.parent.resourceBase.lastResourceLevel;
        var months = this.getMonths();
        if (this.parent.activeViewOptions.orientation === 'Horizontal') {
            for (var month = 0; month < months.length; month++) {
                var monthStart = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(month.toString(), 10)], 1);
                for (var i = 0, len = resources.length; i < len; i++) {
                    this.renderedEvents = [];
                    this.renderResourceEvent(wrapperCollection[parseInt(month.toString(), 10)], resources[parseInt(i.toString(), 10)], month, i, monthStart);
                }
            }
        }
        else {
            for (var i = 0, len = resources.length; i < len; i++) {
                this.renderedEvents = [];
                for (var month = 0; month < months.length; month++) {
                    var monthStart = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(month.toString(), 10)], 1);
                    this.renderResourceEvent(wrapperCollection[parseInt(i.toString(), 10)], resources[parseInt(i.toString(), 10)], month, i, monthStart);
                }
            }
        }
        if (this.parent.rowAutoHeight && !isVerticalScrollbarAvail && contentTable.offsetWidth > contentTable.clientWidth) {
            var appointments = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
            appointments.forEach(function (ele) {
                ele.style.removeProperty('left');
                ele.style.removeProperty('right');
            });
            var appContainer = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CONTAINER_CLASS));
            var conTable_1 = this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS);
            var tr_2 = [].slice.call(conTable_1.querySelectorAll('tbody tr'));
            appContainer.forEach(function (ele, index) {
                var appWrapper = [].slice.call(ele.children);
                var row = tr_2[parseInt(index.toString(), 10)];
                appWrapper.forEach(function (appWrap, cellIndex) {
                    var td = row.querySelector("td:nth-child(" + (cellIndex + 1) + ")");
                    var app = [].slice.call(appWrap.children);
                    var width = td.getBoundingClientRect().width;
                    var left = td.offsetLeft;
                    if (_this.parent.enableRtl) {
                        var right_1 = conTable_1.offsetWidth - left - td.offsetWidth;
                        app.forEach(function (app) {
                            app.style.width = Math.floor(parseInt(app.style.width, 10) / width) * width + 'px';
                            app.style.right = right_1 + 'px';
                        });
                    }
                    else {
                        app.forEach(function (app) {
                            app.style.width = Math.floor(parseInt(app.style.width, 10) / width) * width + 'px';
                            app.style.left = left + 'px';
                        });
                    }
                });
            });
        }
    };
    YearEvent.prototype.renderResourceEvent = function (wrapper, resource, month, index, monthStart) {
        var eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
        wrapper.appendChild(eventWrapper);
        var monthEnd = addDays(lastDateOfMonth(new Date(monthStart.getTime())), 1);
        var eventDatas = this.parent.eventBase.filterEvents(monthStart, monthEnd, undefined, resource);
        var rowIndex = this.parent.activeViewOptions.orientation === 'Vertical' ? index : month;
        var td = this.parent.element.querySelector(".e-content-wrap tr:nth-child(" + (rowIndex + 1) + ") td");
        this.cellHeight = td.offsetHeight;
        this.groupOrder = resource.groupOrder;
        var isSpannedCollection = [];
        var _loop_2 = function (a) {
            var data = eventDatas[parseInt(a.toString(), 10)];
            var overlapIndex = void 0;
            var eventData = extend({}, data, null, true);
            if (this_2.parent.activeViewOptions.group.resources.length > 0) {
                var eventObj = this_2.isSpannedEvent(eventData, monthStart);
                overlapIndex = this_2.getIndex(eventObj[this_2.fields.startTime]);
                eventData.Index = overlapIndex;
            }
            else {
                overlapIndex = this_2.getIndex(eventData[this_2.fields.startTime]);
                eventData.Index = overlapIndex;
            }
            var availedHeight = this_2.cellHeader + (this_2.eventHeight * (a + 1)) + EVENT_GAP$2 + this_2.moreIndicatorHeight;
            var leftValue = (this_2.parent.activeViewOptions.orientation === 'Vertical') ?
                month * this_2.cellWidth : index * this_2.cellWidth;
            if (!this_2.parent.isMinMaxDate(eventData[this_2.fields.startTime])) {
                return { value: void 0 };
            }
            if (this_2.parent.activeViewOptions.orientation === 'Vertical' && this_2.parent.activeViewOptions.group.resources.length > 0) {
                var isRendered = this_2.renderedEvents.filter(function (eventObj) {
                    return eventObj.Guid === eventData.Guid;
                });
                var isSpanned = isSpannedCollection.filter(function (eventObj) {
                    return eventObj.Guid === eventData.Guid;
                });
                if (isRendered.length > 0 || isSpanned.length > 0) {
                    return "continue";
                }
            }
            if (this_2.parent.rowAutoHeight || this_2.cellHeight > availedHeight) {
                this_2.renderEvent(eventWrapper, eventData, month, leftValue, leftValue, monthStart, index);
                this_2.updateCellHeight(td, availedHeight);
                isSpannedCollection.push(eventData);
            }
            else {
                var moreIndex = this_2.parent.activeViewOptions.orientation === 'Horizontal' ? month : index;
                this_2.renderMoreIndicator(eventWrapper, eventDatas.length - a, monthStart, moreIndex, leftValue, leftValue, index);
                if (this_2.parent.activeViewOptions.orientation === 'Horizontal') {
                    for (var i = index; i < eventDatas.length; i++) {
                        this_2.renderedEvents.push(extend({}, eventDatas[parseInt(i.toString(), 10)], { Index: overlapIndex + i }, true));
                    }
                }
                return "break";
            }
        };
        var this_2 = this;
        for (var a = 0; a < eventDatas.length; a++) {
            var state_1 = _loop_2(a);
            if (typeof state_1 === "object")
                return state_1.value;
            if (state_1 === "break")
                break;
        }
    };
    // eslint-disable-next-line max-len
    YearEvent.prototype.renderEvent = function (wrapper, eventData, row, left, right, monthDate, rowIndex) {
        var _this = this;
        var eventObj = this.isSpannedEvent(eventData, monthDate);
        var wrap = this.createEventElement(eventObj);
        var width;
        var index;
        if (eventObj.isSpanned.count === 1) {
            var endTime = addDays(eventObj[this.fields.endTime], -1);
            eventObj[this.fields.endTime] = (endTime > eventObj[this.fields.startTime]) ? endTime : eventObj[this.fields.endTime];
        }
        if (eventObj[this.fields.startTime] > eventObj[this.fields.endTime]) {
            return;
        }
        if (this.parent.activeViewOptions.orientation === 'Horizontal') {
            index = row + 1;
            if (eventObj[this.fields.startTime].getTime() === eventObj[this.fields.endTime].getTime()) {
                eventObj.isSpanned.count = 1;
            }
            width = eventObj.isSpanned.count * this.cellWidth;
        }
        else {
            index = rowIndex + 1;
            width = this.isResource ? eventObj.isSpanned.count * this.cellWidth : this.cellWidth;
        }
        var rowTd = this.parent.element.querySelector(".e-content-wrap tr:nth-child(" + index + ") td");
        var top = rowTd.offsetTop + this.cellHeader + (this.eventHeight * eventObj.Index) + EVENT_GAP$2;
        setStyleAttribute(wrap, {
            'width': width + 'px', 'height': this.eventHeight + 'px', 'left': left + 'px', 'right': right + 'px', 'top': top + 'px'
        });
        if (!this.isResource && this.parent.rowAutoHeight && this.parent.activeViewOptions.orientation === 'Vertical') {
            wrap.setAttribute('data-index', eventObj.Index.toString());
        }
        var args = { data: eventObj, element: wrap, cancel: false, type: 'event' };
        this.parent.trigger(eventRendered, args, function (eventArgs) {
            if (!eventArgs.cancel) {
                wrapper.appendChild(wrap);
                _this.wireAppointmentEvents(wrap, eventObj);
                if (_this.parent.activeViewOptions.group.resources.length > 0) {
                    _this.renderedEvents.push(extend({}, eventObj, null, true));
                }
                else if (_this.parent.activeViewOptions.orientation === 'Vertical') {
                    _this.renderedEvents.push(extend({}, eventObj, null, true));
                }
                else if ((eventObj.isSpanned.isRight || eventObj.isSpanned.isLeft) && _this.parent.activeViewOptions.orientation === 'Horizontal'
                    || !eventObj.isSpanned.isRight) {
                    _this.renderedEvents.push(extend({}, eventObj, null, true));
                }
            }
        });
    };
    // eslint-disable-next-line max-len
    YearEvent.prototype.renderMoreIndicator = function (wrapper, count, startDate, row, left, right, index) {
        if (this.parent.activeViewOptions.group.resources.length === 0 && wrapper.querySelector('[data-start-date="' + startDate.getTime() + '"]')) {
            return;
        }
        var endDate;
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            endDate = addDays(lastDateOfMonth(new Date(startDate.getTime())), 1);
        }
        else {
            endDate = addDays(new Date(startDate.getTime()), 1);
        }
        var moreIndicator = this.getMoreIndicatorElement(count, startDate, endDate);
        var rowTr = this.parent.element.querySelector(".e-content-wrap tr:nth-child(" + (row + 1) + ")");
        var top = rowTr.offsetTop + (this.cellHeight - this.moreIndicatorHeight);
        left = (Math.floor(left / this.cellWidth) * this.cellWidth);
        right = (Math.floor(right / this.cellWidth) * this.cellWidth);
        setStyleAttribute(moreIndicator, { 'width': this.cellWidth + 'px', 'left': left + 'px', 'right': right + 'px', 'top': top + 'px' });
        if (!isNullOrUndefined(index)) {
            moreIndicator.setAttribute('data-group-index', index.toString());
        }
        wrapper.appendChild(moreIndicator);
        EventHandler.add(moreIndicator, 'click', this.moreIndicatorClick, this);
    };
    YearEvent.prototype.createEventElement = function (record) {
        var eventSubject = (record[this.fields.subject] || this.parent.eventSettings.fields.subject.default
            || this.parent.localeObj.getConstant('addTitle'));
        var eventWrapper = createElement('div', {
            className: APPOINTMENT_CLASS,
            attrs: {
                'data-id': 'Appointment_' + record[this.fields.id],
                'data-guid': record.Guid,
                'role': 'button', 'tabindex': '0',
                'aria-disabled': this.parent.eventBase.getReadonlyAttribute(record),
                'aria-label': this.parent.getAnnouncementString(record)
            }
        });
        if (this.cssClass) {
            addClass([eventWrapper], this.cssClass);
        }
        if (record[this.fields.isReadonly]) {
            addClass([eventWrapper], READ_ONLY);
        }
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            var resIndex = this.getGroupIndexFromEvent(record);
            eventWrapper.setAttribute('data-group-index', resIndex.toString());
        }
        var templateElement = [];
        var eventObj = extend({}, record, null, true);
        if (this.parent.activeViewOptions.eventTemplate) {
            var templateId = this.parent.element.id + '_' + this.parent.activeViewOptions.eventTemplateName + 'eventTemplate';
            templateElement = this.parent.getAppointmentTemplate()(eventObj, this.parent, 'eventTemplate', templateId, false);
        }
        else {
            var locationEle = (record[this.fields.location] || this.parent.eventSettings.fields.location.default || '');
            var subjectEle = createElement('div', {
                className: SUBJECT_CLASS,
                innerHTML: (eventSubject + (locationEle ? ';&nbsp' + locationEle : ''))
            });
            var startTimeEle = createElement('div', {
                className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                innerHTML: this.parent.getTimeString(eventObj[this.fields.startTime])
            });
            var endTimeEle = createElement('div', {
                className: APPOINTMENT_TIME + (this.parent.isAdaptive ? ' ' + DISABLE_CLASS : ''),
                innerHTML: this.parent.getTimeString(eventObj[this.fields.endTime])
            });
            addClass([subjectEle], 'e-text-center');
            if (record[this.fields.isAllDay]) {
                templateElement = [subjectEle];
            }
            else if (!eventObj.isLeft && !eventObj.isRight) {
                templateElement = [startTimeEle, subjectEle, endTimeEle];
            }
            else {
                if (!eventObj.isLeft) {
                    templateElement.push(startTimeEle);
                }
                templateElement.push(subjectEle);
                if (!eventObj.isRight) {
                    templateElement.push(endTimeEle);
                }
            }
        }
        var appointmentDetails = createElement('div', { className: APPOINTMENT_DETAILS });
        append(templateElement, appointmentDetails);
        eventWrapper.appendChild(appointmentDetails);
        this.parent.eventBase.renderSpannedIcon(eventWrapper, record.isSpanned);
        this.renderResizeHandler(eventWrapper, record.data, record[this.fields.isReadonly]);
        this.applyResourceColor(eventWrapper, eventObj, 'backgroundColor', this.groupOrder);
        return eventWrapper;
    };
    YearEvent.prototype.isSpannedEvent = function (eventObj, monthDate) {
        var monthStart = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
        var monthEnd = addDays(new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0), 1);
        var eventData = extend({}, eventObj, null, true);
        var eventStart = eventData[this.fields.startTime];
        var eventEnd = eventData[this.fields.endTime];
        var isSpanned = { isLeft: false, isRight: false, count: 1 };
        var yearStart = new Date(this.parent.selectedDate.getFullYear(), this.parent.firstMonthOfYear, 1);
        var yearEnd = addMonths(yearStart, this.parent.monthsCount);
        if (this.isResource) {
            this.updateSpannedEvents(eventObj, monthStart, monthEnd);
        }
        if (this.parent.activeViewOptions.orientation === 'Vertical' && this.parent.activeViewOptions.group.resources.length > 0) {
            this.updateSpannedEventDetails(eventStart, eventEnd, yearStart, yearEnd, eventData, isSpanned);
            var originalStartTime = eventData[this.fields.startTime];
            var originalEndTime = new Date(eventData[this.fields.endTime] - 1);
            isSpanned.count = (originalEndTime.getMonth() - originalStartTime.getMonth()) +
                (this.parent.monthsCount * (originalEndTime.getFullYear() - originalStartTime.getFullYear())) + 1;
        }
        else {
            this.updateSpannedEventDetails(eventStart, eventEnd, monthStart, monthEnd, eventData, isSpanned);
            if (this.parent.activeViewOptions.group.resources.length === 0 || this.parent.uiStateValues.isGroupAdaptive) {
                var end = resetTime(eventData[this.fields.endTime]).getTime();
                var start = resetTime(eventData[this.fields.startTime]).getTime();
                if (eventObj[this.fields.isAllDay] && end === eventObj[this.fields.endTime].getTime() || isSpanned.isRight) {
                    end = addDays(new Date(end), -1).getTime();
                }
                isSpanned.count = Math.ceil((end - start) / MS_PER_DAY) + 1;
            }
        }
        eventData.isSpanned = isSpanned;
        if (resetTime(eventStart).getTime() < resetTime(this.parent.minDate).getTime()) {
            eventData[this.fields.startTime] = this.parent.minDate;
        }
        if (resetTime(eventEnd).getTime() > resetTime(this.parent.maxDate).getTime()) {
            eventData[this.fields.endTime] = this.parent.maxDate;
        }
        return eventData;
    };
    YearEvent.prototype.updateSpannedEventDetails = function (eventStart, eventEnd, viewStart, viewEnd, eventObj, isSpanned) {
        if (eventStart.getTime() < viewStart.getTime()) {
            eventObj[this.fields.startTime] = viewStart;
            isSpanned.isLeft = true;
        }
        if (eventEnd.getTime() > viewEnd.getTime()) {
            eventObj[this.fields.endTime] = viewEnd;
            isSpanned.isRight = true;
        }
    };
    YearEvent.prototype.getOverlapEvents = function (date, appointments) {
        var appointmentsList = [];
        var dateStart;
        var dateEnd;
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            var monthStart = this.parent.calendarUtil.getMonthStartDate(new Date(date.getTime()));
            var monthEnd = addDays(this.parent.calendarUtil.getMonthEndDate(new Date(date.getTime())), -1);
            dateStart = resetTime(new Date(monthStart.getTime())).getTime();
            dateEnd = resetTime(new Date(monthEnd.getTime())).getTime();
        }
        else {
            if (this.parent.rowAutoHeight) {
                dateStart = resetTime(new Date(date.getTime())).getTime();
                dateEnd = addDays(resetTime(new Date(date.getTime())), 1).getTime();
            }
            else {
                dateStart = dateEnd = resetTime(new Date(date.getTime())).getTime();
            }
        }
        for (var _i = 0, appointments_1 = appointments; _i < appointments_1.length; _i++) {
            var app = appointments_1[_i];
            var appStart = new Date(app[this.fields.startTime].getTime());
            var appEnd = new Date(app[this.fields.endTime].getTime());
            var timeCondition = app[this.fields.isAllDay] ? resetTime(appEnd).getTime() > dateStart :
                resetTime(appEnd).getTime() >= dateStart;
            if (((resetTime(appStart).getTime() <= dateStart) && (timeCondition)) ||
                (resetTime(appStart).getTime() >= dateStart) && (resetTime(appEnd).getTime() <= dateEnd)) {
                appointmentsList.push(app);
            }
        }
        return appointmentsList;
    };
    YearEvent.prototype.getMonths = function () {
        var _this = this;
        // eslint-disable-next-line prefer-spread
        return Array.apply(null, { length: this.parent.monthsCount }).map(function (value, index) {
            return _this.parent.firstMonthOfYear + index;
        });
    };
    YearEvent.prototype.removeCellHeight = function () {
        var elementSelector = "." + MONTH_HEADER_WRAPPER + " tbody tr,." + RESOURCE_COLUMN_TABLE_CLASS + " tbody tr,." + CONTENT_TABLE_CLASS + " tbody tr";
        var rows = [].slice.call(this.element.querySelectorAll(elementSelector));
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            row.firstElementChild.style.height = '';
        }
    };
    YearEvent.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        this.parent = null;
    };
    return YearEvent;
}(TimelineEvent));

var __extends$15 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
var MINUTES_PER_DAY = 1440;
/**
 * Schedule events drag actions
 */
var DragAndDrop = /** @__PURE__ @class */ (function (_super) {
    __extends$15(DragAndDrop, _super);
    function DragAndDrop() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.widthUptoCursorPoint = 0;
        _this.heightUptoCursorPoint = 0;
        _this.cursorPointIndex = 0;
        _this.isHeaderRows = false;
        _this.isTimelineDayProcess = false;
        _this.widthPerMinute = 0;
        _this.heightPerMinute = 0;
        _this.minDiff = 0;
        _this.isStepDragging = false;
        _this.isMorePopupOpened = false;
        _this.isAllDayDrag = false;
        _this.isMultiSelect = false;
        _this.multiData = [];
        _this.updatedData = [];
        _this.swagData = [];
        _this.startTime = 0;
        _this.isAllDayTarget = false;
        _this.targetTd = null;
        _this.isCursorAhead = false;
        return _this;
    }
    DragAndDrop.prototype.wireDragEvent = function (element) {
        new Draggable(element, {
            abort: '.' + EVENT_RESIZE_CLASS,
            clone: true,
            isDragScroll: true,
            enableTapHold: this.parent.isAdaptive,
            enableTailMode: (this.parent.eventDragArea) ? true : false,
            cursorAt: (this.parent.eventDragArea) ? { left: -20, top: -20 } : { left: 0, top: 0 },
            dragArea: this.dragArea,
            dragStart: this.dragStart.bind(this),
            drag: this.drag.bind(this),
            dragStop: this.dragStop.bind(this),
            enableAutoScroll: false,
            helper: this.dragHelper.bind(this),
            queryPositionInfo: this.dragPosition.bind(this)
        });
    };
    DragAndDrop.prototype.setDragArea = function () {
        var dragElement = document.querySelector(this.parent.eventDragArea);
        this.dragArea = this.parent.eventDragArea && dragElement ? dragElement :
            this.parent.element.querySelector('.' + CONTENT_TABLE_CLASS);
    };
    DragAndDrop.prototype.dragHelper = function (e) {
        var _this = this;
        this.setDragActionDefaultValues();
        this.actionObj.element = e.element;
        this.actionObj.action = 'drag';
        var elements = [];
        if (!this.parent.allowMultiDrag || isNullOrUndefined(this.parent.selectedElements) || this.parent.selectedElements.length === 0 ||
            (this.parent.selectedElements.length > 0 && this.parent.selectedElements.indexOf(this.actionObj.element) === -1)) {
            elements = [e.element];
        }
        else {
            elements = this.parent.selectedElements;
            this.isMultiSelect = true;
        }
        elements.forEach(function (ele) {
            var cloneElement = _this.createCloneElement(ele);
            if (ele.getAttribute('data-guid') === _this.actionObj.element.getAttribute('data-guid')) {
                _this.actionObj.clone = cloneElement;
                if (!_this.parent.eventDragArea && _this.parent.currentView !== 'Month' &&
                    _this.parent.activeViewOptions.timeScale.enable && !_this.parent.activeView.isTimelineView() &&
                    !_this.actionObj.element.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
                    setStyleAttribute(_this.actionObj.clone, { cursor: 'move', left: '0%', right: '0%', width: '100%' });
                }
                _this.actionObj.clone.style.top = formatUnit(_this.actionObj.element.offsetTop);
            }
            _this.actionObj.cloneElement.push(cloneElement);
            _this.actionObj.originalElement.push(ele);
        });
        return this.actionObj.clone;
    };
    DragAndDrop.prototype.dragPosition = function (e) {
        if (this.parent.eventDragArea) {
            return { left: e.left, top: e.top };
        }
        var cellHeight = (this.actionObj.cellHeight / this.actionObj.slotInterval) * this.actionObj.interval;
        var leftValue = formatUnit(0);
        if (this.parent.currentView === 'Month') {
            leftValue = e.left;
        }
        var cloneRight;
        if (this.isStepDragging) {
            cloneRight = Math.ceil(this.actionObj.clone.getBoundingClientRect().right) + this.actionObj.interval;
        }
        else {
            cloneRight = this.actionObj.clone.getBoundingClientRect().right;
        }
        var dragArea = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var contentWrapRight = dragArea.getBoundingClientRect().right;
        if (this.parent.activeView.isTimelineView() && this.parent.currentView !== 'TimelineYear' && !this.parent.enableRtl &&
            this.actionObj.pageX > cloneRight && !this.isMorePopupOpened && !(this.actionObj.pageX > contentWrapRight)) {
            this.isCursorAhead = true;
        }
        if (this.parent.activeView.isTimelineView()) {
            leftValue = formatUnit(this.actionObj.clone.offsetLeft);
        }
        var topValue;
        if ((this.parent.activeView.isTimelineView() || !this.parent.timeScale.enable ||
            (!isNullOrUndefined(this.actionObj.clone.offsetParent) &&
                this.actionObj.clone.offsetParent.classList.contains(MORE_EVENT_POPUP_CLASS)))) {
            topValue = formatUnit(this.actionObj.clone.offsetTop);
        }
        else if (this.parent.currentView === 'Month') {
            topValue = formatUnit(0);
        }
        else if (this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
            topValue = formatUnit(this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS).offsetTop);
            setStyleAttribute(this.actionObj.clone, {
                width: formatUnit(Math.ceil(this.actionObj.clone.offsetWidth / this.actionObj.cellWidth) * this.actionObj.cellWidth),
                right: this.parent.enableRtl && formatUnit(0)
            });
        }
        else {
            if (this.actionObj.element.classList.contains(ALLDAY_APPOINTMENT_CLASS) &&
                !this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
                setStyleAttribute(this.actionObj.clone, {
                    height: formatUnit(this.actionObj.cellHeight),
                    width: formatUnit(this.actionObj.cellWidth - 1),
                    pointerEvents: 'none'
                });
            }
            var top_1 = parseInt(e.top, 10);
            top_1 = top_1 < 0 ? 0 : top_1;
            topValue = formatUnit(Math.ceil(top_1 / cellHeight) * cellHeight);
            var scrollHeight = this.parent.element.querySelector('.e-content-wrap').scrollHeight;
            var cloneBottom = parseInt(topValue, 10) + this.actionObj.clone.offsetHeight;
            if (cloneBottom > scrollHeight) {
                topValue = (parseInt(topValue, 10) - (cloneBottom - scrollHeight)) + 'px';
            }
        }
        return { left: leftValue, top: topValue };
    };
    DragAndDrop.prototype.setDragActionDefaultValues = function () {
        this.actionObj.action = 'drag';
        this.actionObj.isAllDay = null;
        this.actionObj.slotInterval = this.parent.activeViewOptions.timeScale.interval / this.parent.activeViewOptions.timeScale.slotCount;
        this.actionObj.interval = this.actionObj.slotInterval;
        var workCell = this.parent.element.querySelector('.' + WORK_CELLS_CLASS);
        this.actionObj.cellWidth = workCell.offsetWidth;
        this.actionObj.cellHeight = workCell.offsetHeight;
    };
    DragAndDrop.prototype.dragStart = function (e) {
        var _this = this;
        var eventGuid = this.actionObj.element.getAttribute('data-guid');
        this.actionObj.event = this.parent.eventBase.getEventByGuid(eventGuid);
        var eventObj = extend({}, this.actionObj.event, null, true);
        if (!isNullOrUndefined(eventObj)) {
            this.startTime = eventObj[this.parent.eventFields.startTime].getTime();
        }
        var dragArgs = {
            cancel: false,
            data: eventObj,
            selectedData: this.getSelectedData(),
            event: e,
            excludeSelectors: null,
            element: this.actionObj.element,
            interval: this.actionObj.interval,
            navigation: { enable: false, timeDelay: 2000 },
            scroll: { enable: true, scrollBy: 30, timeDelay: 100 }
        };
        this.parent.trigger(dragStart, dragArgs, function (dragEventArgs) {
            if (dragEventArgs.cancel || (!isNullOrUndefined(_this.actionObj.element) &&
                isNullOrUndefined(_this.actionObj.element.parentElement))) {
                var dragObj = _this.actionObj.element.ej2_instances[0];
                if (!isNullOrUndefined(dragObj)) {
                    dragObj.intDestroy(e.event);
                }
                _this.actionObj.action = '';
                _this.removeCloneElementClasses();
                _this.removeCloneElement();
                return;
            }
            _this.actionClass('addClass');
            _this.parent.uiStateValues.action = true;
            _this.actionObj.start = eventObj[_this.parent.eventFields.startTime];
            _this.actionObj.end = eventObj[_this.parent.eventFields.endTime];
            _this.actionObj.groupIndex = parseInt(_this.actionObj.element.getAttribute('data-group-index') || '0', 10);
            _this.actionObj.interval = dragEventArgs.interval;
            _this.actionObj.navigation = dragEventArgs.navigation;
            _this.actionObj.scroll = dragEventArgs.scroll;
            _this.actionObj.excludeSelectors = dragEventArgs.excludeSelectors;
            var viewElement = _this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
            _this.scrollArgs = { element: viewElement, width: viewElement.scrollWidth, height: viewElement.scrollHeight };
            _this.widthPerMinute = (_this.actionObj.cellWidth / _this.actionObj.slotInterval) * _this.actionObj.interval;
            _this.heightPerMinute = (_this.actionObj.cellHeight / _this.actionObj.slotInterval) * _this.actionObj.interval;
            _this.widthUptoCursorPoint = 0;
            _this.heightUptoCursorPoint = 0;
            _this.cursorPointIndex = -1;
            _this.isHeaderRows = false;
            _this.isTimelineDayProcess = false;
            _this.minDiff = 0;
            _this.isMorePopupOpened = false;
            _this.daysVariation = -1;
            _this.isAllDayTarget = _this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS);
            if ((_this.parent.activeView.isTimelineView() || !_this.parent.timeScale.enable) && _this.parent.currentView !== 'TimelineYear') {
                if (!isNullOrUndefined(_this.actionObj.clone.offsetParent) &&
                    _this.actionObj.clone.offsetParent.classList.contains(MORE_EVENT_POPUP_CLASS)) {
                    _this.isMorePopupOpened = true;
                }
                _this.actionObj.pageX = e.event.pageX;
                var rows = _this.parent.activeViewOptions.headerRows;
                _this.isHeaderRows = rows.length > 0 && rows[rows.length - 1].option !== 'Hour' &&
                    rows[rows.length - 1].option !== 'Date';
                _this.isTimelineDayProcess = !_this.parent.activeViewOptions.timeScale.enable || _this.isHeaderRows ||
                    _this.parent.currentView === 'TimelineMonth' || (rows.length > 0 && rows[rows.length - 1].option === 'Date');
                _this.isStepDragging = !_this.isTimelineDayProcess && (_this.actionObj.slotInterval !== _this.actionObj.interval);
                if (_this.isTimelineDayProcess) {
                    _this.timelineEventModule = new TimelineEvent(_this.parent, 'day');
                }
                else {
                    _this.timelineEventModule = new TimelineEvent(_this.parent, 'hour');
                }
            }
            if (_this.parent.currentView === 'TimelineYear') {
                _this.yearEvent = new YearEvent(_this.parent);
            }
            if (_this.parent.currentView === 'Month') {
                _this.startTime = resetTime(new Date(_this.startTime)).getTime();
                _this.updateOriginalElement(_this.actionObj.clone);
                _this.monthEvent = new MonthEvent(_this.parent);
            }
            if (_this.parent.currentView === 'Day' || _this.parent.currentView === 'Week' || _this.parent.currentView === 'WorkWeek') {
                _this.verticalEvent = new VerticalEvent(_this.parent);
                _this.verticalEvent.initializeValues();
                var splitEvents = _this.splitEvent(_this.actionObj.event);
                splitEvents.forEach(function (event) {
                    var query = ".e-day-wrapper[data-date=\"" + resetTime(event[_this.parent.eventFields.startTime]).getTime() + "\"]";
                    if (_this.parent.activeViewOptions.group.resources.length > 0) {
                        query = query.concat('[data-group-index = "' + _this.actionObj.groupIndex + '"]');
                    }
                    var appWrap = _this.parent.element.querySelector(query);
                    if (appWrap) {
                        var appEle = appWrap.querySelector('[data-id="' + _this.actionObj.clone.getAttribute('data-id') + '"]');
                        if (appEle) {
                            addClass([appEle], EVENT_ACTION_CLASS);
                        }
                    }
                });
            }
        });
    };
    DragAndDrop.prototype.getSelectedData = function () {
        if (this.isMultiSelect && this.multiData.length === 0 && this.parent.selectedElements.length > 0) {
            for (var _i = 0, _a = this.parent.selectedElements; _i < _a.length; _i++) {
                var element = _a[_i];
                var eventGuid = element.getAttribute('data-guid');
                var data = this.parent.eventBase.getEventByGuid(eventGuid);
                this.multiData.push(extend({}, data, null, true));
            }
        }
        return this.multiData;
    };
    DragAndDrop.prototype.drag = function (e) {
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide(true);
        }
        if ((!isNullOrUndefined(e.target)) && e.target.classList && e.target.classList.contains(DISABLE_DATES)) {
            return;
        }
        var eventObj = extend({}, this.actionObj.event, null, true);
        var eventArgs = this.parent.eventBase.getPageCoordinates(e);
        this.actionObj.Y = this.actionObj.pageY = eventArgs.pageY;
        this.actionObj.X = this.actionObj.pageX = eventArgs.pageX;
        this.actionObj.target = e.target;
        this.widthUptoCursorPoint = (this.widthUptoCursorPoint === 0) ?
            Math.ceil((Math.abs(this.actionObj.clone.getBoundingClientRect().left - this.actionObj.X) / this.widthPerMinute)) *
                this.widthPerMinute : this.widthUptoCursorPoint;
        this.widthUptoCursorPoint = this.isMorePopupOpened ? this.actionObj.cellWidth : this.widthUptoCursorPoint;
        this.heightUptoCursorPoint = (this.heightUptoCursorPoint === 0) ?
            Math.ceil((Math.abs(this.actionObj.clone.getBoundingClientRect().top - this.actionObj.Y) / this.heightPerMinute)) *
                this.heightPerMinute : this.heightUptoCursorPoint;
        this.isAllDayDrag = (this.parent.activeViewOptions.timeScale.enable) ?
            this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS) :
            this.actionObj.event[this.parent.eventFields.isAllDay];
        if (this.isStepDragging && this.minDiff === 0) {
            this.calculateMinutesDiff(eventObj);
        }
        if ((this.parent.currentView === 'Month' || this.isAllDayDrag) && this.daysVariation < 0) {
            var date = this.parent.getDateFromElement(this.actionObj.target);
            if (!isNullOrUndefined(date)) {
                var currentDate = resetTime(date);
                var startDate = resetTime(new Date(eventObj[this.parent.eventFields.startTime].getTime()));
                this.daysVariation = (currentDate.getTime() - startDate.getTime()) / MS_PER_DAY;
            }
            else {
                this.daysVariation = 0;
            }
        }
        else {
            this.daysVariation = 0;
        }
        if (this.parent.eventDragArea) {
            var targetElement = eventArgs.target;
            this.actionObj.clone.style.top = formatUnit(targetElement.offsetTop);
            this.actionObj.clone.style.left = formatUnit(targetElement.offsetLeft);
            var currentTarget = closest(targetElement, '.' + ROOT);
            if (!currentTarget) {
                this.actionObj.clone.style.height = '';
                this.actionObj.clone.style.width = '';
            }
            else {
                if (!(this.parent.currentView === 'Week' || this.parent.currentView === 'WorkWeek' || this.parent.currentView === 'Day')) {
                    this.actionObj.clone.style.width = formatUnit(this.actionObj.element.offsetWidth);
                }
            }
        }
        this.updateScrollPosition(e);
        this.updateNavigatingPosition(e);
        this.updateDraggingDateTime(e);
        var dragArgs = {
            data: eventObj, event: e, element: this.actionObj.element, startTime: this.actionObj.start,
            endTime: this.actionObj.end, selectedData: this.updatedData
        };
        if (this.parent.group.resources.length > 0) {
            dragArgs.groupIndex = this.actionObj.groupIndex;
        }
        this.parent.trigger(drag, dragArgs);
    };
    DragAndDrop.prototype.calculateMinutesDiff = function (eventObj) {
        if (this.parent.enableRtl) {
            this.minDiff =
                ((this.actionObj.clone.offsetWidth - this.widthUptoCursorPoint) / this.widthPerMinute) * this.actionObj.interval;
        }
        else {
            this.minDiff = (this.widthUptoCursorPoint / this.widthPerMinute) * this.actionObj.interval;
        }
        var startDate = eventObj[this.parent.eventFields.startTime];
        var startTime = this.parent.activeView.renderDates[0];
        var startEndHours = getStartEndHours(startTime, this.parent.activeView.getStartHour(), this.parent.activeView.getEndHour());
        if (startEndHours.startHour.getTime() > startDate.getTime()) {
            this.minDiff = this.minDiff + ((startEndHours.startHour.getTime() - startDate.getTime()) / MS_PER_MINUTE);
        }
    };
    DragAndDrop.prototype.dragStop = function (e) {
        var _this = this;
        this.isCursorAhead = false;
        this.removeCloneElementClasses();
        this.removeCloneElement();
        clearInterval(this.actionObj.navigationInterval);
        this.actionObj.navigationInterval = null;
        clearInterval(this.actionObj.scrollInterval);
        this.actionObj.scrollInterval = null;
        this.actionClass('removeClass');
        this.parent.uiStateValues.action = false;
        if (this.isAllowDrop(e)) {
            return;
        }
        var target = (e.target.classList && (!e.target.classList.contains('e-work-cells') && this.parent.cellTemplate) ?
            closest(e.target, '.e-work-cells') : e.target);
        var dragArgs = {
            cancel: false, data: this.getChangedData(this.updatedData), selectedData: this.updatedData,
            event: e, element: this.actionObj.element, target: target
        };
        this.actionObj.action = null;
        this.parent.trigger(dragStop, dragArgs, function (dragEventArgs) {
            if (dragEventArgs.cancel) {
                return;
            }
            if (_this.parent.isSpecificResourceEvents()) {
                _this.parent.crudModule.crudObj.isCrudAction = true;
                _this.parent.crudModule.crudObj.sourceEvent =
                    [_this.parent.resourceBase.lastResourceLevel[parseInt(dragArgs.element.getAttribute('data-group-index'), 10)]];
                var currentGroupIndex = parseInt(dragArgs.target.getAttribute('data-group-index'), 10) || _this.actionObj.groupIndex;
                _this.parent.crudModule.crudObj.targetEvent =
                    [_this.parent.resourceBase.lastResourceLevel[parseInt(currentGroupIndex.toString(), 10)]];
            }
            _this.saveChangedData(dragEventArgs, _this.isMultiSelect);
        });
        this.updatedData = [];
        this.multiData = [];
        this.isMultiSelect = false;
        this.parent.selectedElements = [];
    };
    DragAndDrop.prototype.updateNavigatingPosition = function (e) {
        var _this = this;
        if (this.actionObj.navigation.enable) {
            var currentDate_1 = this.parent.getCurrentTime();
            if (isNullOrUndefined(this.actionObj.navigationInterval)) {
                this.actionObj.navigationInterval = window.setInterval(function () {
                    if (currentDate_1) {
                        var crtDate = _this.parent.getCurrentTime();
                        var end = crtDate.getSeconds();
                        var start = currentDate_1.getSeconds() + (_this.actionObj.navigation.timeDelay / 1000);
                        start = (start >= 60) ? start - 60 : start;
                        if (start === end) {
                            currentDate_1 = _this.parent.getCurrentTime();
                            _this.viewNavigation(e);
                            _this.updateDraggingDateTime(e);
                        }
                    }
                }, this.actionObj.navigation.timeDelay);
            }
        }
    };
    DragAndDrop.prototype.updateDraggingDateTime = function (e) {
        if (!isNullOrUndefined(this.actionObj.clone.offsetParent) &&
            this.actionObj.clone.offsetParent.classList.contains(MORE_EVENT_POPUP_CLASS)) {
            this.morePopupEventDragging(e);
        }
        else if (this.parent.activeView.isTimelineView() && this.parent.currentView !== 'TimelineYear') {
            this.timelineEventModule.dateRender = this.parent.activeView.renderDates;
            this.timelineEventModule.cellWidth = this.actionObj.cellWidth;
            this.timelineEventModule.getSlotDates();
            this.actionObj.cellWidth = this.isHeaderRows ? this.timelineEventModule.cellWidth :
                this.parent.element.querySelector('.' + WORK_CELLS_CLASS).getBoundingClientRect().width;
            this.calculateTimelineTime(e);
        }
        else {
            if (this.parent.currentView === 'Month' || this.parent.currentView === 'TimelineYear') {
                this.calculateVerticalDate(e);
            }
            else {
                this.calculateVerticalTime(e);
            }
        }
    };
    DragAndDrop.prototype.navigationWrapper = function () {
        if (!this.parent.activeView.isTimelineView()) {
            if (this.parent.currentView === 'Month' || !this.parent.timeScale.enable) {
                var outerWrapperCls = [].slice.call(this.parent.element.querySelectorAll('.' + WORK_CELLS_CLASS));
                this.actionObj.index = (this.parent.activeView.renderDates.length < this.actionObj.index) ?
                    this.parent.activeView.renderDates.length - 1 : this.actionObj.index;
                var targetWrapper = outerWrapperCls[this.actionObj.index].querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
                if (!targetWrapper) {
                    targetWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
                    outerWrapperCls[this.actionObj.index].appendChild(targetWrapper);
                }
                targetWrapper.appendChild(this.actionObj.clone);
            }
            else {
                var wrapperClass = this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS) ?
                    '.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS : '.' + APPOINTMENT_WRAPPER_CLASS;
                this.parent.element.querySelectorAll(wrapperClass)
                    .item(this.actionObj.index).appendChild(this.actionObj.clone);
                if (wrapperClass === '.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS) {
                    var elementHeight = this.getAllDayEventHeight();
                    var event_2 = [].slice.call(this.parent.element.querySelectorAll('.' + ALLDAY_CELLS_CLASS + ':first-child'));
                    if (event_2[0].offsetHeight < elementHeight) {
                        for (var _i = 0, event_1 = event_2; _i < event_1.length; _i++) {
                            var e = event_1[_i];
                            e.style.height = ((elementHeight + 2) / 12) + 'em';
                        }
                    }
                    this.actionObj.clone.style.height = formatUnit(elementHeight);
                }
                this.actionObj.height = parseInt(this.actionObj.clone.style.height, 10);
            }
        }
        else {
            var outWrapper = void 0;
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                outWrapper = this.parent.element.querySelectorAll('.e-appointment-container:not(.e-hidden)').item(this.actionObj.index);
            }
            else {
                outWrapper = this.parent.element.querySelector('.' + APPOINTMENT_CONTAINER_CLASS);
            }
            if (!isNullOrUndefined(outWrapper)) {
                var tarWrapper_1 = outWrapper.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
                if (!tarWrapper_1) {
                    tarWrapper_1 = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
                    outWrapper.appendChild(tarWrapper_1);
                }
                this.actionObj.cloneElement.forEach(function (ele) {
                    tarWrapper_1.appendChild(ele);
                });
            }
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    DragAndDrop.prototype.viewNavigation = function (e) {
        var navigationType;
        var dragArea = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (dragArea && ((!this.scrollEdges.top && !this.scrollEdges.bottom) ||
            closest(this.actionObj.clone, '.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS))) {
            if ((dragArea.scrollLeft === 0) &&
                (Math.round(this.actionObj.X) <=
                    Math.round(dragArea.getBoundingClientRect().left + this.actionObj.cellWidth + window.pageXOffset))) {
                navigationType = this.parent.enableRtl ? 'next' : 'previous';
            }
            else if ((Math.round(dragArea.scrollLeft) + dragArea.clientWidth === dragArea.scrollWidth) &&
                (Math.round(this.actionObj.X) >=
                    Math.round(dragArea.getBoundingClientRect().right - this.actionObj.cellWidth + window.pageXOffset))) {
                navigationType = this.parent.enableRtl ? 'previous' : 'next';
            }
            if (navigationType) {
                this.parent.changeDate(this.parent.activeView.getNextPreviousDate(navigationType));
            }
        }
    };
    DragAndDrop.prototype.morePopupEventDragging = function (e) {
        if (isNullOrUndefined(e.target) || (e.target && isNullOrUndefined(closest(e.target, 'td')))) {
            return;
        }
        var eventObj = extend({}, this.actionObj.event, null, true);
        var eventDuration = eventObj[this.parent.eventFields.endTime].getTime() -
            eventObj[this.parent.eventFields.startTime].getTime();
        var td = closest(e.target, 'td');
        if (this.parent.currentView === 'TimelineYear' && (!td.classList.contains(WORK_CELLS_CLASS) || td.classList.contains(OTHERMONTH_CLASS))) {
            return;
        }
        var dragStart$$1 = this.parent.getDateFromElement(td);
        var dragEnd = new Date(dragStart$$1.getTime());
        dragEnd.setMilliseconds(eventDuration);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.actionObj.groupIndex = parseInt(td.getAttribute('data-group-index'), 10);
        }
        this.actionObj.start = new Date(dragStart$$1.getTime());
        this.actionObj.end = new Date(dragEnd.getTime());
        this.actionObj.clone.style.top = formatUnit(td.offsetParent.offsetTop);
        this.actionObj.clone.style.left = formatUnit(td.offsetLeft);
        this.actionObj.clone.style.width = formatUnit(td.offsetWidth);
        var eventContainer = td;
        var eventWrapper;
        if (this.parent.activeView.isTimelineView()) {
            var rowIndex = closest(td, 'tr').rowIndex;
            eventContainer = this.parent.element.querySelectorAll('.e-appointment-container').item(rowIndex);
        }
        eventWrapper = eventContainer.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
        if (!eventWrapper) {
            eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            eventContainer.appendChild(eventWrapper);
        }
        this.appendCloneElement(eventWrapper);
    };
    DragAndDrop.prototype.calculateVerticalTime = function (e) {
        var _this = this;
        if (isNullOrUndefined(this.actionObj.target) ||
            (this.actionObj.target && isNullOrUndefined(closest(this.actionObj.target, 'tr'))) ||
            (!(closest(this.actionObj.target, 'td').classList.contains(WORK_CELLS_CLASS)) &&
                !(closest(this.actionObj.target, 'td').classList.contains(ALLDAY_CELLS_CLASS)))) {
            return;
        }
        if (this.parent.activeViewOptions.timeScale.enable) {
            this.swapDragging(e);
        }
        var dragArea = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var eventObj = extend({}, this.actionObj.event, null, true);
        var eventStart = eventObj[this.parent.eventFields.startTime];
        var eventEnd = eventObj[this.parent.eventFields.endTime];
        var eventDuration = getUniversalTime(eventEnd) - getUniversalTime(eventStart);
        var offsetTop = Math.floor(parseInt(this.actionObj.clone.style.top, 10) / this.actionObj.cellHeight)
            * this.actionObj.cellHeight;
        offsetTop = offsetTop < 0 ? 0 : offsetTop;
        if (this.scrollEdges.top || this.scrollEdges.bottom) {
            offsetTop = this.scrollEdges.top ? dragArea.scrollTop - this.heightUptoCursorPoint +
                this.actionObj.cellHeight + window.pageYOffset :
                (dragArea.scrollTop + dragArea.offsetHeight - this.actionObj.clone.offsetHeight + window.pageYOffset) +
                    (this.actionObj.clone.offsetHeight - this.heightUptoCursorPoint);
            offsetTop = Math.round(offsetTop / this.actionObj.cellHeight) * this.actionObj.cellHeight;
            if (dragArea.scrollTop > 0) {
                this.actionObj.clone.style.top = formatUnit(offsetTop);
            }
        }
        var rowIndex = (this.parent.activeViewOptions.timeScale.enable) ? (offsetTop / this.actionObj.cellHeight) : 0;
        var heightPerMinute = this.actionObj.cellHeight / this.actionObj.slotInterval;
        var diffInMinutes = parseInt(this.actionObj.clone.style.top, 10) - offsetTop;
        var tr;
        if (this.isAllDayDrag) {
            tr = this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS);
        }
        else {
            var trCollections = [].slice.call(this.parent.getContentTable().querySelectorAll('tr'));
            tr = trCollections[parseInt(rowIndex.toString(), 10)];
        }
        var index;
        if (closest(this.actionObj.target, 'td').classList.contains(WORK_CELLS_CLASS) ||
            closest(this.actionObj.target, 'td').classList.contains(ALLDAY_CELLS_CLASS)) {
            index = closest(this.actionObj.target, 'td').cellIndex;
        }
        var colIndex = isNullOrUndefined(index) ? closest(this.actionObj.clone, 'td').cellIndex : index;
        this.actionObj.index = colIndex;
        if (isNullOrUndefined(tr)) {
            return;
        }
        var td = tr.children[parseInt(colIndex.toString(), 10)];
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            this.actionObj.groupIndex = parseInt(td.getAttribute('data-group-index'), 10);
        }
        var dragStart$$1;
        var dragEnd;
        if (this.parent.activeViewOptions.timeScale.enable && !this.isAllDayDrag) {
            this.appendCloneElement(this.getEventWrapper(colIndex));
            dragStart$$1 = this.parent.getDateFromElement(td);
            dragStart$$1.setMinutes(dragStart$$1.getMinutes() + (diffInMinutes / heightPerMinute));
            dragEnd = new Date(dragStart$$1.getTime());
            if (this.actionObj.element.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
                dragEnd.setMinutes(dragEnd.getMinutes() + this.actionObj.slotInterval);
            }
            else {
                dragEnd.setMilliseconds(eventDuration);
            }
        }
        else {
            dragStart$$1 = this.parent.getDateFromElement(td);
            dragStart$$1.setDate(dragStart$$1.getDate() - this.daysVariation);
            dragStart$$1.setHours(eventStart.getHours(), eventStart.getMinutes(), eventStart.getSeconds());
            dragEnd = new Date(dragStart$$1.getTime());
            dragEnd.setMilliseconds(eventDuration);
            if (!this.actionObj.element.classList.contains(ALLDAY_APPOINTMENT_CLASS) &&
                this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS)) {
                dragEnd = addDays(resetTime(dragEnd), 1);
            }
            var index_1 = this.parent.activeViewOptions.group.byDate || (this.parent.virtualScrollModule &&
                !this.parent.activeViewOptions.timeScale.enable) ? colIndex : undefined;
            this.updateAllDayEvents(dragStart$$1, dragEnd, index_1);
        }
        this.actionObj.start = new Date(+dragStart$$1);
        this.actionObj.end = new Date(+dragEnd);
        var event = this.getUpdatedEvent(this.actionObj.start, this.actionObj.end, this.actionObj.event);
        var dynamicWrappers = [].slice.call(this.parent.element.querySelectorAll('.e-dynamic-clone'));
        for (var _i = 0, dynamicWrappers_1 = dynamicWrappers; _i < dynamicWrappers_1.length; _i++) {
            var wrapper = dynamicWrappers_1[_i];
            remove(wrapper);
        }
        if (this.multiData.length > 0) {
            if (this.isAllDayTarget && this.isAllDayDrag && !isNullOrUndefined(this.actionObj.isAllDay) && !this.actionObj.isAllDay) {
                var targetCellTime_1 = parseInt((closest(this.actionObj.target, 'td')).getAttribute('data-date'), 10);
                this.multiData.forEach(function (data) {
                    _this.swagData.push(extend({}, data, null, true));
                    if (data[_this.parent.eventFields.isAllDay]) {
                        data[_this.parent.eventFields.startTime] =
                            new Date(data[_this.parent.eventFields.startTime].getTime() + (targetCellTime_1 - _this.startTime));
                        var startTime = new Date(data[_this.parent.eventFields.startTime]);
                        var endTime = new Date(startTime.setMinutes(startTime.getMinutes() + _this.actionObj.slotInterval));
                        data[_this.parent.eventFields.endTime] = endTime;
                        data[_this.parent.eventFields.isAllDay] = false;
                    }
                });
                this.startTime = targetCellTime_1;
            }
            if (this.isAllDayTarget && this.isAllDayDrag &&
                !isNullOrUndefined(this.actionObj.isAllDay) && this.actionObj.isAllDay && this.swagData.length > 0) {
                this.multiData = this.swagData;
                this.swagData = [];
                var eventObj_1 = extend({}, this.actionObj.event, null, true);
                this.startTime = eventObj_1[this.parent.eventFields.startTime].getTime();
            }
            var startTimeDiff = event[this.parent.eventFields.startTime].getTime() - this.startTime;
            for (var index_2 = 0; index_2 < this.multiData.length; index_2++) {
                this.updatedData[parseInt(index_2.toString(), 10)] =
                    this.updateMultipleData(this.multiData[parseInt(index_2.toString(), 10)], startTimeDiff);
                var dayIndex = this.getDayIndex(this.updatedData[parseInt(index_2.toString(), 10)]);
                if (dayIndex >= 0) {
                    var wrapper = this.getEventWrapper(dayIndex, this.updatedData[parseInt(index_2.toString(), 10)][this.parent.eventFields.isAllDay]);
                    this.appendCloneElement(wrapper, this.actionObj.cloneElement[parseInt(index_2.toString(), 10)]);
                    this.updateEventHeight(this.updatedData[parseInt(index_2.toString(), 10)], index_2, dayIndex);
                }
                else {
                    if (!isNullOrUndefined(this.actionObj.cloneElement[parseInt(index_2.toString(), 10)].parentNode)) {
                        remove(this.actionObj.cloneElement[parseInt(index_2.toString(), 10)]);
                    }
                }
            }
        }
        else {
            this.updateEventHeight(event);
        }
        this.updateTimePosition(this.actionObj.start, this.updatedData);
    };
    DragAndDrop.prototype.splitEvent = function (event) {
        var eventFields = this.parent.eventFields;
        var eventData = [];
        var startTime = event[eventFields.startTime];
        var endTime = event[eventFields.endTime];
        if (resetTime(new Date(startTime.getTime())) < resetTime(new Date(endTime.getTime()))) {
            var startReferenceDate = resetTime(new Date(startTime.getTime()));
            var endReferenceDate = new Date(startReferenceDate.getTime());
            for (var i = 0; startReferenceDate < new Date(endTime.getTime()); i++) {
                endReferenceDate = new Date(endReferenceDate.setDate(startReferenceDate.getDate() + 1));
                var eventObj = extend({}, event, null, true);
                eventObj[eventFields.startTime] = new Date(startReferenceDate);
                eventObj[eventFields.endTime] = new Date(endReferenceDate);
                startReferenceDate = new Date(startReferenceDate.setDate(startReferenceDate.getDate() + 1));
                eventData.push(eventObj);
            }
            var index = eventData.length - 1;
            eventData[0][eventFields.startTime] = startTime;
            eventData[parseInt(index.toString(), 10)][eventFields.endTime] = endTime;
        }
        else {
            eventData.push(event);
        }
        return eventData;
    };
    DragAndDrop.prototype.updateMultipleData = function (data, timeDifference) {
        var eventObj = extend({}, data, null, true);
        if (!isNullOrUndefined(this.actionObj.isAllDay) && this.parent.activeViewOptions.timeScale.enable &&
            ((this.isAllDayTarget && eventObj[this.parent.eventFields.isAllDay]) ||
                (!this.isAllDayTarget && !eventObj[this.parent.eventFields.isAllDay]))) {
            eventObj[this.parent.eventFields.isAllDay] = this.actionObj.isAllDay;
        }
        var endTimeDiff = eventObj[this.parent.eventFields.endTime].getTime() -
            eventObj[this.parent.eventFields.startTime].getTime();
        if (eventObj[this.parent.eventFields.isAllDay]) {
            var differInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
            var day = Math.ceil(endTimeDiff / (1000 * 3600 * 24));
            var startTime = new Date(eventObj[this.parent.eventFields.startTime]);
            eventObj[this.parent.eventFields.startTime] = resetTime(new Date(startTime.setDate(startTime.getDate() + differInDays)));
            eventObj[this.parent.eventFields.endTime] = addDays(eventObj[this.parent.eventFields.startTime], day);
        }
        else {
            eventObj[this.parent.eventFields.startTime] =
                new Date(eventObj[this.parent.eventFields.startTime].getTime() + timeDifference);
            eventObj[this.parent.eventFields.endTime] =
                new Date(eventObj[this.parent.eventFields.startTime].getTime() + endTimeDiff);
        }
        return eventObj;
    };
    DragAndDrop.prototype.getDayIndex = function (event) {
        var eventObj = extend({}, event, null, true);
        var startDate = resetTime(eventObj[this.parent.eventFields.startTime]);
        if (this.parent.activeViewOptions.timeScale.enable && !eventObj[this.parent.eventFields.isAllDay]) {
            var startHour = this.parent.activeView.getStartHour();
            startDate.setMilliseconds(startHour.getTime() - resetTime(startHour).getTime());
        }
        var startTime = startDate.getTime();
        var query = '';
        var wrapper = DAY_WRAPPER_CLASS;
        if (this.parent.activeViewOptions.timeScale.enable && (eventObj[this.parent.eventFields.isAllDay])) {
            wrapper = ALLDAY_APPOINTMENT_WRAPPER_CLASS;
        }
        else {
            wrapper = WORK_CELLS_CLASS;
        }
        query = '.' + wrapper + '[data-date="' + startTime + '"]';
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            query = query + '[data-group-index="' + this.actionObj.groupIndex + '"]';
        }
        this.targetTd = this.parent.element.querySelector(query);
        if (isNullOrUndefined(this.targetTd)) {
            return -1;
        }
        return this.targetTd.cellIndex;
    };
    DragAndDrop.prototype.updateEventHeight = function (event, index, colIndex) {
        this.verticalEvent.initializeValues();
        var datesCount = this.verticalEvent.getStartCount();
        if (!this.parent.uiStateValues.isGroupAdaptive) {
            for (var i = 0; i < this.actionObj.groupIndex; i++) {
                if (this.verticalEvent.dateRender[parseInt(i.toString(), 10)]) {
                    datesCount = datesCount + this.verticalEvent.dateRender[parseInt(i.toString(), 10)].length;
                }
            }
        }
        var indexGroup = this.parent.uiStateValues.isGroupAdaptive ? datesCount : this.actionObj.groupIndex;
        var target = (this.parent.activeViewOptions.group.byDate &&
            !isNullOrUndefined(this.parent.getDateFromElement(this.actionObj.target))) ? true : false;
        if (target || !this.parent.activeViewOptions.group.byDate) {
            var dynamicIndex = -1;
            var dayIndex = !this.parent.activeViewOptions.group.byDate ?
                isNullOrUndefined(index) ? this.actionObj.index - datesCount : colIndex - datesCount
                : this.parent.getIndexOfDate(this.verticalEvent.dateRender[parseInt(indexGroup.toString(), 10)], resetTime(
                // eslint-disable-next-line max-len
                this.parent.getDateFromElement(isNullOrUndefined(index) ? this.actionObj.target : this.targetTd)));
            var splitEvents = this.splitEvent(event);
            var events_1 = this.parent.eventBase.isAllDayAppointment(event) || splitEvents.length > 2 ||
                this.parent.eventSettings.spannedEventPlacement !== 'TimeSlot' ? [event] : splitEvents;
            for (var i = 0; i < events_1.length; i++) {
                if (i > 0) {
                    var filterQuery = ".e-day-wrapper[data-date=\"" + resetTime(events_1[parseInt(i.toString(), 10)][this.parent.eventFields.startTime]).getTime() + "\"]";
                    if (this.parent.activeViewOptions.group.resources.length > 0) {
                        filterQuery = filterQuery.concat('[data-group-index = "' + this.actionObj.groupIndex + '"]');
                    }
                    var appWrap = this.parent.element.querySelector(filterQuery);
                    if (appWrap) {
                        dayIndex = dayIndex + 1;
                        dynamicIndex = appWrap.cellIndex;
                    }
                    else {
                        dayIndex = -1;
                    }
                }
                if (dayIndex >= 0) {
                    var record = this.verticalEvent.isSpannedEvent(events_1[parseInt(i.toString(), 10)], dayIndex, indexGroup);
                    var eStart = record[this.verticalEvent.fields.startTime];
                    var eEnd = record[this.verticalEvent.fields.endTime];
                    var appHeight = this.parent.activeViewOptions.timeScale.enable ? this.verticalEvent.getHeight(eStart, eEnd) :
                        this.actionObj.element.offsetHeight;
                    var topValue = this.parent.activeViewOptions.timeScale.enable ?
                        this.verticalEvent.getTopValue(eStart, dayIndex, indexGroup) : this.actionObj.element.offsetTop;
                    if (isNullOrUndefined(index)) {
                        if (i === 0) {
                            this.actionObj.clone.style.top = formatUnit(topValue);
                            this.actionObj.clone.style.height = formatUnit(appHeight);
                        }
                        else {
                            this.renderSpannedEvents(record, dynamicIndex, topValue, appHeight);
                        }
                    }
                    else {
                        var appWidth = this.actionObj.cellWidth;
                        if (event[this.parent.eventFields.isAllDay]) {
                            topValue = this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS).offsetTop;
                            appHeight = this.getAllDayEventHeight();
                            var timeDiff = event[this.parent.eventFields.endTime].getTime() -
                                event[this.parent.eventFields.startTime].getTime();
                            var allDayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            if (allDayDifference >= 0) {
                                appWidth = (allDayDifference * this.actionObj.cellWidth);
                            }
                        }
                        if (this.actionObj.cloneElement[parseInt(index.toString(), 10)]) {
                            if (i === 0) {
                                this.actionObj.cloneElement[parseInt(index.toString(), 10)].style.top = formatUnit(topValue);
                                this.actionObj.cloneElement[parseInt(index.toString(), 10)].style.height = formatUnit(appHeight);
                                this.actionObj.cloneElement[parseInt(index.toString(), 10)].style.width = formatUnit(appWidth);
                                this.actionObj.cloneElement[parseInt(index.toString(), 10)].style.left = formatUnit(0);
                            }
                            else {
                                this.renderSpannedEvents(record, dynamicIndex, topValue, appHeight);
                            }
                        }
                    }
                }
            }
        }
    };
    DragAndDrop.prototype.renderSpannedEvents = function (record, index, top, height) {
        var startTime = record[this.parent.eventFields.startTime].getTime();
        var endTime = record[this.parent.eventFields.endTime].getTime();
        if (startTime !== endTime) {
            var appointmentElement = this.verticalEvent.
                createAppointmentElement(record, false, record.isSpanned, this.actionObj.groupIndex);
            addClass([appointmentElement], [CLONE_ELEMENT_CLASS, 'e-dynamic-clone']);
            setStyleAttribute(appointmentElement, {
                'width': '100%',
                'height': height + 'px',
                'top': top + 'px',
                'border': '0px'
            });
            var appointmentWrap = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_WRAPPER_CLASS));
            appointmentWrap[parseInt(index.toString(), 10)].appendChild(appointmentElement);
        }
    };
    DragAndDrop.prototype.getRenderedDates = function () {
        var _this = this;
        var _a, _b;
        var renderDates = this.parent.activeView.renderDates;
        (_a = this.parent.eventBase.slots).push.apply(_a, this.parent.activeView.renderDates.map(function (date) { return +date; }));
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.eventBase.slots = [];
            var resources = this.parent.resourceBase.lastResourceLevel.
                filter(function (res) { return res.groupIndex === _this.actionObj.groupIndex; });
            renderDates = resources[0].renderDates;
            (_b = this.parent.eventBase.slots).push.apply(_b, renderDates.map(function (date) { return +date; }));
        }
        return renderDates;
    };
    DragAndDrop.prototype.updateAllDayEvents = function (startDate, endDate, colIndex) {
        this.parent.eventBase.slots = [];
        var event = this.getUpdatedEvent(startDate, endDate, this.actionObj.event);
        var renderDates = this.getRenderedDates();
        var events = this.parent.eventBase.splitEvent(event, renderDates);
        var query = ".e-all-day-cells[data-date=\"" + events[0][this.parent.eventFields.startTime].getTime() + "\"]";
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            query = query.concat('[data-group-index = "' + this.actionObj.groupIndex + '"]');
        }
        var cell = [].slice.call(this.parent.element.querySelectorAll(query));
        if (cell.length > 0 || !isNullOrUndefined(colIndex)) {
            var cellIndex = !isNullOrUndefined(colIndex) ? colIndex : cell[0].cellIndex;
            this.appendCloneElement(this.getEventWrapper(cellIndex));
            // eslint-disable-next-line max-len
            this.actionObj.clone.style.width = formatUnit(events[0].data.count * this.actionObj.cellWidth);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    DragAndDrop.prototype.swapDragging = function (e) {
        var colIndex = closest(this.actionObj.target, 'td').cellIndex;
        if (closest(this.actionObj.target, '.' + DATE_HEADER_WRAP_CLASS) &&
            !closest(this.actionObj.clone, '.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS)) {
            addClass([this.actionObj.clone], ALLDAY_APPOINTMENT_CLASS);
            this.appendCloneElement(this.getEventWrapper(colIndex));
            this.actionObj.isAllDay = true;
            var eventHeight = this.getAllDayEventHeight();
            var allDayElement = [].slice.call(this.parent.element.querySelectorAll('.' + ALLDAY_CELLS_CLASS + ':first-child'));
            if (allDayElement[0].offsetHeight < eventHeight) {
                for (var _i = 0, allDayElement_1 = allDayElement; _i < allDayElement_1.length; _i++) {
                    var element = allDayElement_1[_i];
                    element.style.height = ((eventHeight + 2) / 12) + 'em';
                }
            }
            setStyleAttribute(this.actionObj.clone, {
                width: formatUnit(this.actionObj.cellWidth),
                height: formatUnit(eventHeight),
                top: formatUnit(this.parent.element.querySelector('.' + ALLDAY_ROW_CLASS).offsetTop)
            });
        }
        if (closest(this.actionObj.target, '.' + WORK_CELLS_CLASS) &&
            !closest(this.actionObj.clone, '.' + DAY_WRAPPER_CLASS)) {
            removeClass([this.actionObj.clone], ALLDAY_APPOINTMENT_CLASS);
            this.appendCloneElement(this.getEventWrapper(colIndex));
            this.actionObj.isAllDay = false;
            // eslint-disable-next-line max-len
            var height = (this.actionObj.element.offsetHeight === 0) ? this.actionObj.height : this.actionObj.element.offsetHeight;
            setStyleAttribute(this.actionObj.clone, {
                left: formatUnit(0),
                height: formatUnit(height),
                width: formatUnit(this.actionObj.cellWidth)
            });
        }
    };
    DragAndDrop.prototype.calculateVerticalDate = function (e) {
        if (isNullOrUndefined(e.target) || (e.target && isNullOrUndefined(closest(e.target, 'tr'))) ||
            (e.target && e.target.tagName === 'DIV')) {
            return;
        }
        var eventObj = extend({}, this.actionObj.event, null, true);
        if (isNullOrUndefined(this.parent.eventDragArea)) {
            this.removeCloneElement();
        }
        var eventDuration = getUniversalTime(eventObj[this.parent.eventFields.endTime]) -
            getUniversalTime(eventObj[this.parent.eventFields.startTime]);
        var td = closest(this.actionObj.target, 'td');
        if (!isNullOrUndefined(td)) {
            var tr = td.parentElement;
            this.actionObj.index = (tr.rowIndex * tr.children.length) + td.cellIndex;
            var workCells = [].slice.call(this.parent.element.querySelectorAll('.' + WORK_CELLS_CLASS));
            td = workCells[this.actionObj.index];
            var currentDate = this.parent.getDateFromElement(td);
            if (!isNullOrUndefined(currentDate)) {
                if (this.parent.activeViewOptions.group.resources.length > 0) {
                    this.actionObj.groupIndex = parseInt(td.getAttribute('data-group-index'), 10);
                }
                var timeString = new Date(currentDate.setDate(currentDate.getDate() - this.daysVariation));
                var dragStart$$1 = new Date(timeString.getTime());
                var startTimeDiff = getUniversalTime(eventObj[this.parent.eventFields.startTime]) -
                    getUniversalTime(resetTime(new Date(+eventObj[this.parent.eventFields.startTime])));
                dragStart$$1.setMilliseconds(startTimeDiff);
                var dragEnd = new Date(dragStart$$1.getTime());
                dragEnd.setMilliseconds(eventDuration);
                this.actionObj.start = new Date(dragStart$$1.getTime());
                this.actionObj.end = new Date(dragEnd.getTime());
            }
        }
        var event = this.getUpdatedEvent(this.actionObj.start, this.actionObj.end, this.actionObj.event);
        if (isNullOrUndefined(this.parent.eventDragArea)) {
            var eventWrappers = [].slice.call(this.parent.element.querySelectorAll('.' + CLONE_ELEMENT_CLASS));
            for (var _i = 0, eventWrappers_1 = eventWrappers; _i < eventWrappers_1.length; _i++) {
                var wrapper = eventWrappers_1[_i];
                remove(wrapper);
            }
        }
        if (this.multiData && this.multiData.length > 0) {
            var startTime = resetTime(new Date(event[this.parent.eventFields.startTime]));
            var startTimeDiff = startTime.getTime() - this.startTime;
            if (this.parent.currentView === 'TimelineYear' && this.parent.group.resources.length > 0) {
                startTimeDiff = (startTime.getFullYear() - new Date(this.startTime).getFullYear()) * 12;
                startTimeDiff -= new Date(this.startTime).getMonth();
                startTimeDiff += startTime.getMonth();
            }
            for (var index = 0; index < this.multiData.length; index++) {
                this.updatedData[parseInt(index.toString(), 10)] =
                    this.updateMultipleVerticalDate(this.multiData[parseInt(index.toString(), 10)], startTimeDiff);
                if (this.parent.currentView === 'TimelineYear') {
                    this.dynamicYearlyEventsRendering(this.updatedData[parseInt(index.toString(), 10)]);
                }
                else {
                    this.dynamicEventsRendering(this.updatedData[parseInt(index.toString(), 10)]);
                }
            }
        }
        else {
            if (this.parent.currentView === 'TimelineYear') {
                this.dynamicYearlyEventsRendering(event);
            }
            else {
                this.dynamicEventsRendering(event);
            }
        }
    };
    DragAndDrop.prototype.updateMultipleVerticalDate = function (data, timeDifference) {
        var eventObj = extend({}, data, null, true);
        var eventDuration = eventObj[this.parent.eventFields.endTime].getTime() -
            eventObj[this.parent.eventFields.startTime].getTime();
        var startDate = new Date(eventObj[this.parent.eventFields.startTime]);
        if (this.parent.currentView === 'TimelineYear' && this.parent.group.resources.length > 0) {
            eventObj[this.parent.eventFields.startTime] = new Date(startDate.setMonth(startDate.getMonth() + timeDifference));
        }
        else {
            var differInDays = Math.ceil(timeDifference / MS_PER_DAY);
            eventObj[this.parent.eventFields.startTime] = new Date(startDate.setDate(startDate.getDate() + differInDays));
        }
        eventObj[this.parent.eventFields.endTime] =
            new Date(eventObj[this.parent.eventFields.startTime].getTime() + eventDuration);
        return eventObj;
    };
    DragAndDrop.prototype.calculateTimelineTime = function (e) {
        var eventObj = extend({}, this.actionObj.event, null, true);
        var eventDuration = getUniversalTime(eventObj[this.parent.eventFields.endTime]) -
            getUniversalTime(eventObj[this.parent.eventFields.startTime]);
        var offsetLeft = this.parent.enableRtl ? Math.abs(this.actionObj.clone.offsetLeft) - this.actionObj.clone.offsetWidth :
            parseInt(this.actionObj.clone.style.left, 10);
        offsetLeft = Math.floor(offsetLeft / Math.trunc(this.actionObj.cellWidth)) * this.actionObj.cellWidth;
        var rightOffset;
        if (this.parent.enableRtl) {
            rightOffset = Math.abs(parseInt(this.actionObj.clone.style.right, 10));
            this.actionObj.clone.style.right = formatUnit(rightOffset);
        }
        offsetLeft = this.getOffsetValue(offsetLeft, rightOffset);
        var colIndex = this.getColumnIndex(offsetLeft);
        var dragArea = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var contentWrapRight = dragArea.getBoundingClientRect().right;
        var cursorDrag = this.parent.activeView.isTimelineView() && !this.parent.enableRtl &&
            this.actionObj.pageX > this.actionObj.clone.getBoundingClientRect().right &&
            !this.isMorePopupOpened && !(this.actionObj.pageX > contentWrapRight);
        var leftVal = (this.parent.eventDragArea) ? dragArea.scrollLeft - dragArea.offsetLeft : 0;
        if ((this.isCursorAhead || cursorDrag) && !this.isStepDragging) {
            this.isCursorAhead = true;
        }
        var cloneIndex = Math.floor((this.actionObj.pageX - this.actionObj.clone.getBoundingClientRect().left + leftVal) / this.actionObj.cellWidth);
        if (this.parent.enableRtl) {
            cloneIndex = Math.abs(Math.floor((this.actionObj.pageX - this.actionObj.clone.getBoundingClientRect().right) /
                this.actionObj.cellWidth)) - 1;
        }
        if (this.cursorPointIndex < 0) {
            this.cursorIndex(e, eventObj, offsetLeft, cloneIndex);
        }
        var tr = this.parent.getContentTable().querySelector('tr');
        var index = this.getCursorCurrentIndex(colIndex, cloneIndex, tr);
        index = index < 0 ? 0 : index;
        var eventStart = this.isHeaderRows ? new Date(this.timelineEventModule.dateRender[parseInt(index.toString(), 10)].getTime()) :
            this.parent.getDateFromElement(tr.children[parseInt(index.toString(), 10)]);
        if (this.isStepDragging) {
            var widthDiff = this.getWidthDiff(tr, index);
            if (widthDiff !== 0) {
                var timeDiff = Math.round(widthDiff / this.widthPerMinute);
                eventStart.setMinutes(eventStart.getMinutes() + (timeDiff * this.actionObj.interval));
                if (this.isCursorAhead || cursorDrag) {
                    eventStart.setMilliseconds(-(eventDuration));
                }
                else {
                    eventStart.setMinutes(eventStart.getMinutes() - this.minDiff);
                }
            }
            else {
                eventStart = this.actionObj.start;
            }
        }
        else {
            if (this.isCursorAhead || cursorDrag) {
                var minutes = this.isTimelineDayProcess ? MINUTES_PER_DAY : this.actionObj.slotInterval;
                eventStart.setMinutes(eventStart.getMinutes() + minutes);
                eventStart.setMilliseconds(-(eventDuration));
                if (eventStart.getTime() === resetTime(eventStart).getTime() && eventStart.getMinutes() === 0 && eventDuration === 0) {
                    eventStart.setMinutes(-minutes);
                }
            }
            else {
                eventStart.setMinutes(eventStart.getMinutes() -
                    (this.cursorPointIndex * (this.isTimelineDayProcess ? MINUTES_PER_DAY : this.actionObj.slotInterval)));
            }
        }
        eventStart = this.calculateIntervalTime(eventStart);
        if (this.isTimelineDayProcess) {
            var eventSrt = eventObj[this.parent.eventFields.startTime];
            eventStart.setHours(eventSrt.getHours(), eventSrt.getMinutes(), eventSrt.getSeconds());
        }
        if (this.parent.eventDragArea) {
            var targetDate = this.parent.getDateFromElement(e.target);
            if (!isNullOrUndefined(targetDate)) {
                if (!this.parent.activeViewOptions.timeScale.enable || (this.parent.currentView === 'TimelineMonth')) {
                    var eventSrt = eventObj[this.parent.eventFields.startTime];
                    eventStart = new Date(targetDate.setHours(eventSrt.getHours(), eventSrt.getMinutes(), eventSrt.getSeconds()));
                }
                else {
                    eventStart = targetDate;
                }
            }
        }
        var eventEnd = new Date(eventStart.getTime());
        eventEnd.setMilliseconds(eventDuration);
        var eventsData = [this.getUpdatedEvent(eventStart, eventEnd, this.actionObj.event)];
        if (this.multiData.length > 0) {
            var startTimeDiff = eventsData[0][this.parent.eventFields.startTime].getTime() - this.startTime;
            for (var i = 0; i < this.multiData.length; i++) {
                this.updatedData[parseInt(i.toString(), 10)] =
                    this.updateMultipleData(this.multiData[parseInt(i.toString(), 10)], startTimeDiff);
            }
            eventsData = this.updatedData;
        }
        for (var dataIndex = 0; dataIndex < eventsData.length; dataIndex++) {
            var cloneElement = this.multiData.length > 0 ? this.actionObj.cloneElement[parseInt(dataIndex.toString(), 10)] : this.actionObj.clone;
            if (isNullOrUndefined(this.parent.eventDragArea)) {
                var events_2 = this.timelineEventModule.splitEvent(eventsData[parseInt(dataIndex.toString(), 10)], this.timelineEventModule.dateRender);
                var eventData = events_2[0].data;
                var startTime = this.timelineEventModule.getStartTime(events_2[0], eventData);
                var endTime = this.timelineEventModule.getEndTime(events_2[0], eventData);
                // eslint-disable-next-line max-len
                var width = this.timelineEventModule.getEventWidth(startTime, endTime, eventObj[this.parent.eventFields.isAllDay], eventData.count);
                // eslint-disable-next-line max-len
                var day = this.parent.getIndexOfDate(this.timelineEventModule.dateRender, resetTime(new Date(startTime.getTime())));
                day = day < 0 ? 0 : day;
                var left = this.timelineEventModule.getPosition(startTime, endTime, eventObj[this.parent.eventFields.isAllDay], day);
                if (this.parent.enableRtl) {
                    cloneElement.style.right = formatUnit(left);
                }
                else {
                    cloneElement.style.left = formatUnit(left);
                }
                if (!this.isMorePopupOpened) {
                    cloneElement.style.width = formatUnit(width);
                }
            }
            if (this.parent.activeViewOptions.group.resources.length > 0) {
                this.calculateResourceGroupingPosition(e, cloneElement);
            }
            this.actionObj.start = new Date(eventStart.getTime());
            this.actionObj.end = new Date(eventEnd.getTime());
            this.updateTimePosition(this.actionObj.start, this.updatedData);
        }
    };
    DragAndDrop.prototype.getOffsetValue = function (offsetLeft, rightOffset) {
        if (this.scrollEdges.left || this.scrollEdges.right) {
            var viewEle = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
            if (this.parent.enableRtl) {
                rightOffset = viewEle.offsetWidth - viewEle.scrollLeft;
                if (this.scrollEdges.right) {
                    rightOffset = (rightOffset - viewEle.offsetWidth + this.actionObj.clone.offsetWidth) -
                        (this.actionObj.clone.offsetWidth - this.widthUptoCursorPoint);
                }
                else {
                    rightOffset = rightOffset + this.widthUptoCursorPoint;
                    if (rightOffset - this.widthUptoCursorPoint >= viewEle.scrollWidth) {
                        this.actionObj.clone.style.width =
                            formatUnit(this.actionObj.clone.offsetWidth - this.widthUptoCursorPoint + this.actionObj.cellWidth);
                        rightOffset = (viewEle.scrollLeft - viewEle.scrollWidth);
                    }
                }
                if (isNullOrUndefined(this.parent.eventDragArea)) {
                    this.actionObj.clone.style.left = formatUnit(rightOffset);
                }
            }
            else {
                if (this.scrollEdges.left) {
                    offsetLeft = viewEle.scrollLeft - this.widthUptoCursorPoint + this.actionObj.cellWidth;
                    if (viewEle.scrollLeft + viewEle.offsetWidth >= viewEle.offsetWidth) {
                        viewEle.scrollLeft = viewEle.scrollLeft - 1;
                    }
                    else if (this.actionObj.clone.offsetLeft === 0) {
                        offsetLeft = viewEle.scrollLeft;
                    }
                }
                else {
                    offsetLeft = (viewEle.scrollLeft + viewEle.offsetWidth -
                        this.actionObj.clone.offsetWidth) + (this.actionObj.clone.offsetWidth - this.widthUptoCursorPoint);
                }
                offsetLeft = offsetLeft < 0 ? 0 : offsetLeft;
                if (isNullOrUndefined(this.parent.eventDragArea)) {
                    this.actionObj.clone.style.left = formatUnit(offsetLeft);
                }
            }
        }
        return offsetLeft;
    };
    DragAndDrop.prototype.getWidthDiff = function (tr, index) {
        var pages = this.scrollArgs.element.getBoundingClientRect();
        if (pages.left <= this.actionObj.pageX && pages.right >= this.actionObj.pageX) {
            var targetLeft = tr.children[parseInt(index.toString(), 10)].offsetLeft;
            var pageX = this.actionObj.pageX - pages.left;
            if (this.parent.enableRtl) {
                return (targetLeft + this.actionObj.cellWidth) - (this.scrollArgs.element.scrollLeft + pageX);
            }
            else {
                return (this.scrollArgs.element.scrollLeft + pageX) - targetLeft;
            }
        }
        return 0;
    };
    DragAndDrop.prototype.getColumnIndex = function (offsetLeft) {
        var index = Math.floor(offsetLeft / Math.trunc(this.actionObj.cellWidth));
        if (this.isHeaderRows) {
            return index;
        }
        return this.getIndex(index);
    };
    DragAndDrop.prototype.getCursorCurrentIndex = function (colIndex, cloneIndex, tr) {
        var index = colIndex + cloneIndex;
        if (this.isHeaderRows) {
            var dateLength = Math.floor(tr.offsetWidth / this.actionObj.cellWidth);
            return (index > dateLength - 1) ? dateLength - 1 : index;
        }
        return (index > tr.children.length - 1) ? tr.children.length - 1 : index;
    };
    DragAndDrop.prototype.cursorIndex = function (e, event, left, index) {
        var td = closest(e.target, '.e-work-cells');
        if (!isNullOrUndefined(td) && !this.isMorePopupOpened) {
            var targetDate = this.parent.getDateFromElement(td);
            if (this.isHeaderRows) {
                var currentIndex = Math.floor(left / this.actionObj.cellWidth);
                targetDate = new Date(this.timelineEventModule.dateRender[currentIndex + index].getTime());
            }
            var timeDiff = targetDate.getTime() - event[this.parent.eventFields.startTime].getTime();
            if (this.isTimelineDayProcess) {
                this.cursorPointIndex = Math.abs(Math.ceil(timeDiff / (MS_PER_DAY)));
            }
            else {
                var widthDiff = Math.floor((timeDiff / MS_PER_MINUTE) / (this.actionObj.slotInterval / this.actionObj.cellWidth));
                this.cursorPointIndex = Math.floor(widthDiff / this.actionObj.cellWidth);
                this.cursorPointIndex = this.cursorPointIndex < 0 ? 0 : this.cursorPointIndex;
            }
        }
        else {
            this.cursorPointIndex = 0;
        }
    };
    DragAndDrop.prototype.calculateResourceGroupingPosition = function (e, cloneElement) {
        var dragArea = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS);
        var trCollection = [].slice.call(this.parent.element.querySelectorAll('.e-content-wrap .e-content-table tr:not(.e-hidden)'));
        var translateY = getTranslateY(dragArea.querySelector('table'));
        translateY = (isNullOrUndefined(translateY)) ? 0 : translateY;
        var rowHeight = (this.parent.rowAutoHeight) ?
            ~~(dragArea.querySelector('table').offsetHeight / trCollection.length) : this.actionObj.cellHeight;
        var rowIndex = Math.floor(Math.floor((this.actionObj.Y +
            (dragArea.scrollTop - translateY - (window.scrollY || window.pageYOffset))) -
            dragArea.getBoundingClientRect().top) / rowHeight);
        rowIndex = (rowIndex < 0) ? 0 : (rowIndex > trCollection.length - 1) ? trCollection.length - 1 : rowIndex;
        this.actionObj.index = rowIndex;
        var eventContainer = this.parent.element.querySelectorAll('.e-appointment-container:not(.e-hidden)').item(rowIndex);
        var eventWrapper = eventContainer.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
        if (!eventWrapper) {
            eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
            eventContainer.appendChild(eventWrapper);
        }
        this.appendCloneElement(eventWrapper, cloneElement);
        var td = closest(this.actionObj.target, 'td');
        this.actionObj.groupIndex = (td && !isNaN(parseInt(td.getAttribute('data-group-index'), 10)))
            ? parseInt(td.getAttribute('data-group-index'), 10) : this.actionObj.groupIndex;
        if (!isNullOrUndefined(this.parent.eventDragArea)) {
            return;
        }
        var top = trCollection[parseInt(rowIndex.toString(), 10)].getBoundingClientRect().height * rowIndex;
        if (this.parent.rowAutoHeight) {
            var cursorElement = this.getCursorElement(e);
            if (cursorElement) {
                top = cursorElement.classList.contains(WORK_CELLS_CLASS) ? cursorElement.offsetTop :
                    (cursorElement.offsetParent && cursorElement.offsetParent.classList.contains(APPOINTMENT_CLASS)) ?
                        cursorElement.offsetParent.offsetTop : top;
            }
        }
        cloneElement.style.top = formatUnit(top);
    };
    DragAndDrop.prototype.appendCloneElement = function (element, cloneElement) {
        if (cloneElement === void 0) { cloneElement = null; }
        cloneElement = isNullOrUndefined(cloneElement) ? this.actionObj.clone : cloneElement;
        var dragElement = document.querySelector(this.parent.eventDragArea);
        if (this.parent.eventDragArea && dragElement) {
            dragElement.appendChild(cloneElement);
        }
        else {
            element.appendChild(cloneElement);
        }
    };
    DragAndDrop.prototype.getEventWrapper = function (index, isAllDayDrag) {
        var eventWrapper;
        if (isNullOrUndefined(isAllDayDrag)) {
            isAllDayDrag = this.actionObj.clone.classList.contains(ALLDAY_APPOINTMENT_CLASS);
        }
        if (this.parent.activeViewOptions.timeScale.enable) {
            var wrapperClass = isAllDayDrag ? '.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS : '.' + APPOINTMENT_WRAPPER_CLASS;
            eventWrapper = this.parent.element.querySelectorAll(wrapperClass).item(index);
        }
        else {
            var targetWrapper = this.parent.element.querySelectorAll('.' + WORK_CELLS_CLASS).item(index);
            eventWrapper = targetWrapper.querySelector('.' + APPOINTMENT_WRAPPER_CLASS);
            if (!eventWrapper) {
                eventWrapper = createElement('div', { className: APPOINTMENT_WRAPPER_CLASS });
                targetWrapper.appendChild(eventWrapper);
            }
        }
        return eventWrapper;
    };
    DragAndDrop.prototype.getAllDayEventHeight = function () {
        var eventWrapper = createElement('div', { className: APPOINTMENT_CLASS });
        this.parent.element.querySelector('.' + ALLDAY_APPOINTMENT_WRAPPER_CLASS).appendChild(eventWrapper);
        var eventHeight = eventWrapper.offsetHeight;
        remove(eventWrapper);
        return eventHeight;
    };
    DragAndDrop.prototype.isAllowDrop = function (e) {
        if (!this.actionObj.excludeSelectors) {
            return false;
        }
        var dropSelectors = this.actionObj.excludeSelectors.split(',');
        var isAllowDrop = false;
        for (var _i = 0, dropSelectors_1 = dropSelectors; _i < dropSelectors_1.length; _i++) {
            var selector = dropSelectors_1[_i];
            if (e.target.classList.contains(selector)) {
                isAllowDrop = true;
                break;
            }
        }
        return isAllowDrop;
    };
    /**
     * Get module name.
     *
     * @returns {string} Returns the module name
     */
    DragAndDrop.prototype.getModuleName = function () {
        return 'dragAndDrop';
    };
    return DragAndDrop;
}(ActionBase));

/**
 * view base
 */
var ViewBase = /** @__PURE__ @class */ (function () {
    /**
     * Constructor
     *
     * @param {Schedule} parent Accepts the schedule instance
     */
    function ViewBase(parent) {
        this.previousNextAction = 'next';
        this.parent = parent;
    }
    ViewBase.prototype.isTimelineView = function () {
        return this.parent.currentView.indexOf('Timeline') !== -1;
    };
    ViewBase.prototype.getContentRows = function () {
        return [];
    };
    ViewBase.prototype.refreshHeader = function () {
        // Method to refresh the date header
    };
    ViewBase.prototype.refreshResourceHeader = function () {
        remove(this.element.querySelector('tbody').lastElementChild.firstElementChild);
        var resTd = createElement('td');
        resTd.appendChild(this.parent.resourceBase.createResourceColumn());
        prepend([resTd], this.element.querySelector('tbody').lastElementChild);
        this.parent.notify(contentReady, {});
    };
    ViewBase.prototype.getDayName = function (date) {
        return this.parent.getDayNames('abbreviated')[date.getDay()];
    };
    ViewBase.prototype.getDate = function (date) {
        return this.parent.globalize.formatDate(date, { format: 'd', calendar: this.parent.getCalendarMode() });
    };
    ViewBase.prototype.getTime = function (date) {
        if (this.parent.isAdaptive) {
            if (this.parent.activeViewOptions.timeFormat === 'HH:mm' || this.parent.activeViewOptions.timeFormat === 'HH.mm') {
                return this.parent.globalize.formatDate(date, { format: 'H', calendar: this.parent.getCalendarMode() });
            }
            return this.parent.globalize.formatDate(date, { skeleton: 'h', calendar: this.parent.getCalendarMode() });
        }
        return this.parent.getTimeString(date);
    };
    ViewBase.prototype.getTimelineDate = function (date) {
        var text = this.parent.globalize.formatDate(date, { skeleton: 'MMMd', calendar: this.parent.getCalendarMode() }) + ', ' +
            this.parent.getDayNames('wide')[date.getDay()];
        return capitalizeFirstWord(text, 'multiple');
    };
    ViewBase.prototype.createEventTable = function (trCount) {
        var eventTable = createElement('div', { className: EVENT_TABLE_CLASS });
        append(this.getEventRows(trCount), eventTable);
        return eventTable;
    };
    ViewBase.prototype.getEventRows = function (trCount) {
        var eventRows = [];
        var eventContainer;
        for (var row = 0; row < trCount; row++) {
            eventContainer = createElement('div', { className: APPOINTMENT_CONTAINER_CLASS });
            if (this.parent.resourceBase && !this.parent.uiStateValues.isGroupAdaptive && this.parent.resourceBase.renderedResources) {
                eventContainer.setAttribute('data-group-index', this.parent.resourceBase.renderedResources[parseInt(row.toString(), 10)].groupIndex.toString());
            }
            eventRows.push(eventContainer);
        }
        return eventRows;
    };
    ViewBase.prototype.collapseRows = function (wrap) {
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            this.parent.resourceBase.hideResourceRows(wrap.querySelector('tbody'));
            this.parent.resourceBase.hideResourceRows(wrap.querySelector('.' + EVENT_TABLE_CLASS));
        }
    };
    ViewBase.prototype.createTableLayout = function (className) {
        var clsName = className || '';
        var table = createElement('table', { className: SCHEDULE_TABLE_CLASS + ' ' + clsName });
        var tbody = createElement('tbody');
        table.appendChild(tbody);
        return table;
    };
    ViewBase.prototype.setAriaAttributes = function (table) {
        table.setAttribute('role', 'grid');
        table.setAttribute('aria-label', this.getLabelText(this.parent.currentView));
    };
    ViewBase.prototype.createColGroup = function (table, lastRow) {
        var length = lastRow.length;
        if (lastRow[0] && lastRow[0].colSpan) {
            length = lastRow.map(function (value) { return value.colSpan; }).reduce(function (prev, next) { return prev + next; });
        }
        var colGroupEle = createElement('colgroup');
        for (var i = 0; i < length; i++) {
            colGroupEle.appendChild(createElement('col'));
        }
        prepend([colGroupEle], table);
    };
    ViewBase.prototype.getScrollXIndent = function (content) {
        return content.offsetHeight - content.clientHeight > 0 ? getScrollBarWidth() : 0;
    };
    ViewBase.prototype.scrollTopPanel = function (target) {
        this.getDatesHeaderElement().firstElementChild.scrollLeft = target.scrollLeft;
    };
    ViewBase.prototype.scrollHeaderLabels = function (target) {
        var headerTable = this.element.querySelector('.e-date-header-wrap table');
        var colWidth = headerTable.offsetWidth / headerTable.querySelectorAll('colgroup col').length;
        var applyLeft = function (headerCells, isRtl) {
            var currentCell;
            var tdLeft = 0;
            var colSpan = 0;
            var hiddenLeft = isRtl ? -(target.scrollLeft) : target.scrollLeft;
            for (var _i = 0, headerCells_2 = headerCells; _i < headerCells_2.length; _i++) {
                var cell = headerCells_2[_i];
                colSpan += parseInt(cell.getAttribute('colSpan'), 10);
                if (colSpan > Math.floor(hiddenLeft / colWidth)) {
                    currentCell = cell;
                    break;
                }
                tdLeft += cell.offsetWidth;
            }
            if (!isNullOrUndefined(currentCell)) {
                currentCell.children[0].style[isRtl ? 'right' : 'left'] = (hiddenLeft - tdLeft) + 'px';
            }
        };
        var classNames = ['.e-header-year-cell', '.e-header-month-cell', '.e-header-week-cell', '.e-header-cells'];
        for (var _i = 0, classNames_1 = classNames; _i < classNames_1.length; _i++) {
            var className = classNames_1[_i];
            var headerCells = [].slice.call(this.element.querySelectorAll(className));
            if (headerCells.length > 0) {
                for (var _a = 0, headerCells_1 = headerCells; _a < headerCells_1.length; _a++) {
                    var element = headerCells_1[_a];
                    element.children[0].style[this.parent.enableRtl ? 'right' : 'left'] = '';
                }
                applyLeft(headerCells, this.parent.enableRtl);
            }
        }
    };
    ViewBase.prototype.addAttributes = function (td, element) {
        if (td.template) {
            append(td.template, element);
        }
        if (td.colSpan) {
            element.setAttribute('colspan', td.colSpan.toString());
        }
        if (td.className) {
            addClass([element], td.className);
        }
    };
    ViewBase.prototype.getHeaderBarHeight = function () {
        var headerBarHeight = 2;
        if (this.parent.headerModule) {
            headerBarHeight += getOuterHeight(this.parent.headerModule.getHeaderElement());
        }
        if (this.parent.uiStateValues.isGroupAdaptive) {
            var resHeader = this.parent.element.querySelector('.' + RESOURCE_HEADER_TOOLBAR);
            if (resHeader) {
                headerBarHeight += resHeader.offsetHeight;
            }
        }
        return headerBarHeight;
    };
    ViewBase.prototype.renderPanel = function (type) {
        if (type === PREVIOUS_PANEL_CLASS) {
            prepend([this.element], this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS));
        }
        else {
            this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS).appendChild(this.element);
        }
    };
    ViewBase.prototype.setPanel = function (panel) {
        this.element = panel;
    };
    ViewBase.prototype.getPanel = function () {
        return this.element;
    };
    ViewBase.prototype.getDatesHeaderElement = function () {
        return this.element.querySelector('.' + DATE_HEADER_CONTAINER_CLASS);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ViewBase.prototype.getDateSlots = function (renderDates, workDays) {
        return []; // Here getDateSlots only need in vertical and month views
    };
    ViewBase.prototype.generateColumnLevels = function () {
        return []; // Here generateColumnLevels only need in vertical and month views
    };
    ViewBase.prototype.getColumnLevels = function () {
        return this.colLevels;
    };
    ViewBase.prototype.highlightCurrentTime = function () {
        // Here showTimeIndicator functionalities
    };
    ViewBase.prototype.getStartDate = function () {
        return this.renderDates[0];
    };
    ViewBase.prototype.getEndDate = function () {
        return this.renderDates[this.renderDates.length - 1];
    };
    ViewBase.prototype.startDate = function () {
        return this.renderDates[0];
    };
    ViewBase.prototype.endDate = function () {
        return addDays(this.renderDates[this.renderDates.length - 1], 1);
    };
    ViewBase.prototype.getStartHour = function () {
        var startHour = this.parent.getStartEndTime(this.parent.activeViewOptions.startHour);
        if (isNullOrUndefined(startHour)) {
            startHour = new Date(2000, 0, 0, 0);
        }
        return startHour;
    };
    ViewBase.prototype.getEndHour = function () {
        var endHour = this.parent.getStartEndTime(this.parent.activeViewOptions.endHour);
        if (isNullOrUndefined(endHour)) {
            endHour = new Date(2000, 0, 0, 0);
        }
        return endHour;
    };
    ViewBase.prototype.isCurrentDate = function (date) {
        return date.setHours(0, 0, 0, 0) === new Date(this.parent.currentTimezoneDate).setHours(0, 0, 0, 0);
    };
    ViewBase.prototype.isCurrentMonth = function (date) {
        if (this.parent.activeViewOptions.displayDate || this.parent.activeViewOptions.numberOfWeeks > 0) {
            return this.parent.activeView.getStartDate().getTime() <= this.parent.getCurrentTime().getTime() &&
                this.parent.activeView.getEndDate().getTime() >= this.parent.getCurrentTime().getTime();
        }
        return date.getFullYear() ===
            this.parent.getCurrentTime().getFullYear() && date.getMonth() === this.parent.getCurrentTime().getMonth();
    };
    ViewBase.prototype.isWorkDay = function (date, workDays) {
        if (workDays === void 0) { workDays = this.parent.activeViewOptions.workDays; }
        if (workDays.indexOf(date.getDay()) >= 0) {
            return true;
        }
        return false;
    };
    ViewBase.prototype.isWorkHour = function (date, startHour, endHour, workDays) {
        if (isNullOrUndefined(startHour) || isNullOrUndefined(endHour)) {
            return false;
        }
        startHour.setMilliseconds(0);
        endHour.setMilliseconds(0);
        return !(getDateInMs(date) < getDateInMs(startHour) || getDateInMs(date) >= getDateInMs(endHour) ||
            !this.isWorkDay(date, workDays));
    };
    ViewBase.prototype.getRenderDates = function (workDays) {
        var renderDates = [];
        // Due to same code for vertical and time line, week & work week views, if condition has used
        if (this.parent.currentView === 'Week' || this.parent.currentView === 'TimelineWeek') {
            var selectedDate = resetTime(this.parent.selectedDate);
            var start = getWeekFirstDate(selectedDate, this.parent.activeViewOptions.firstDayOfWeek);
            for (var i = 0, length_1 = WEEK_LENGTH * this.parent.activeViewOptions.interval; i < length_1; i++) {
                if (this.parent.activeViewOptions.showWeekend) {
                    renderDates.push(start);
                }
                else {
                    if (this.isWorkDay(start, workDays)) {
                        renderDates.push(start);
                    }
                }
                start = addDays(start, 1);
            }
        }
        else if (this.parent.currentView === 'WorkWeek' || this.parent.currentView === 'TimelineWorkWeek') {
            var start = getWeekFirstDate(resetTime(this.parent.selectedDate), this.parent.activeViewOptions.firstDayOfWeek);
            for (var i = 0, length_2 = WEEK_LENGTH * this.parent.activeViewOptions.interval; i < length_2; i++) {
                if (this.isWorkDay(start, workDays)) {
                    renderDates.push(start);
                }
                start = addDays(start, 1);
            }
        }
        else {
            var dayCount = this.parent.currentView === 'Agenda' ? this.parent.agendaDaysCount :
                this.parent.activeViewOptions.interval;
            var start = resetTime(this.parent.selectedDate);
            do {
                if (this.parent.activeViewOptions.showWeekend) {
                    renderDates.push(start);
                }
                else {
                    if (this.isWorkDay(start, workDays)) {
                        renderDates.push(start);
                    }
                }
                start = addDays(start, 1);
            } while (dayCount !== renderDates.length);
        }
        if (!workDays) {
            this.renderDates = renderDates;
        }
        if (this.parent.headerModule) {
            this.parent.headerModule.previousNextIconHandler();
        }
        return renderDates;
    };
    ViewBase.prototype.getNextPreviousDate = function (type) {
        this.previousNextAction = type;
        if (this.parent.currentView === 'Day' || this.parent.currentView === 'TimelineDay') {
            if (this.parent.activeViewOptions.showWeekend) {
                var daysCount = this.parent.activeViewOptions.interval;
                return addDays(this.parent.selectedDate, type === 'next' ? daysCount : -daysCount);
            }
            else {
                var date = void 0;
                if (type === 'next') {
                    date = addDays(this.renderDates.slice(-1)[0], 1);
                    while (!this.isWorkDay(date)) {
                        date = addDays(date, 1);
                    }
                }
                else {
                    date = addDays(this.renderDates[0], -1);
                    var count = 0;
                    do {
                        if (this.isWorkDay(date)) {
                            count += 1;
                        }
                        if (this.parent.activeViewOptions.interval !== count) {
                            date = addDays(date, -1);
                        }
                    } while (this.parent.activeViewOptions.interval !== count);
                }
                return date;
            }
        }
        var weekLength = type === 'next' ? WEEK_LENGTH : -WEEK_LENGTH;
        return addDays(this.parent.selectedDate, weekLength * this.parent.activeViewOptions.interval);
    };
    ViewBase.prototype.getLabelText = function (view) {
        var viewStr = view.charAt(0).toLowerCase() + view.substring(1);
        return this.parent.localeObj.getConstant(viewStr) + ' of ' + capitalizeFirstWord(this.parent.globalize.formatDate(this.parent.selectedDate, { skeleton: 'long', calendar: this.parent.getCalendarMode() }), 'single');
    };
    ViewBase.prototype.getDateRangeText = function () {
        if (this.parent.isAdaptive) {
            var formatDate = (this.parent.activeViewOptions.dateFormat) ? this.parent.activeViewOptions.dateFormat : 'MMMM y';
            return capitalizeFirstWord(this.parent.globalize.formatDate(this.parent.selectedDate, { format: formatDate, calendar: this.parent.getCalendarMode() }), 'single');
        }
        return this.formatDateRange(this.renderDates[0], this.renderDates[this.renderDates.length - 1]);
    };
    ViewBase.prototype.formatDateRange = function (startDate, endDate) {
        var globalize = this.parent.globalize;
        var mode = this.parent.getCalendarMode();
        if (startDate === endDate) {
            endDate = null;
        }
        if (!isNullOrUndefined(this.parent.activeViewOptions.dateFormat)) {
            var text = '';
            if (!endDate) {
                text = globalize.formatDate(startDate, { format: this.parent.activeViewOptions.dateFormat, calendar: mode });
                return capitalizeFirstWord(text, 'multiple');
            }
            text = (globalize.formatDate(startDate, { format: this.parent.activeViewOptions.dateFormat, calendar: mode }) +
                ' - ' + globalize.formatDate(endDate, { format: this.parent.activeViewOptions.dateFormat, calendar: mode }));
            return capitalizeFirstWord(text, 'multiple');
        }
        var formattedStr;
        var longDateFormat;
        if (this.parent.locale === 'en' || this.parent.locale === 'en-US') {
            longDateFormat = getValue('dateFormats.long', getDefaultDateObject(mode));
        }
        else {
            longDateFormat = getValue('main.' + '' + this.parent.locale + '.dates.calendars.' + mode + '.dateFormats.long', cldrData);
        }
        if (!endDate) {
            return capitalizeFirstWord(globalize.formatDate(startDate, { format: longDateFormat, calendar: mode }), 'single');
        }
        var dateFormat = longDateFormat.trim().toLocaleLowerCase();
        if (dateFormat.substr(0, 1) === 'd') {
            if (startDate.getFullYear() === endDate.getFullYear()) {
                if (startDate.getMonth() === endDate.getMonth()) {
                    formattedStr = globalize.formatDate(startDate, { format: 'dd', calendar: mode }) + ' - ' +
                        globalize.formatDate(endDate, { format: 'dd MMMM yyyy', calendar: mode });
                }
                else {
                    formattedStr = globalize.formatDate(startDate, { format: 'dd MMM', calendar: mode }) + ' - ' +
                        globalize.formatDate(endDate, { format: 'dd MMM yyyy', calendar: mode });
                }
            }
            else {
                formattedStr = globalize.formatDate(startDate, { format: 'dd MMM yyyy', calendar: mode }) + ' - ' +
                    globalize.formatDate(endDate, { format: 'dd MMM yyyy', calendar: mode });
            }
        }
        else if (dateFormat.substr(0, 1) === 'm') {
            if (startDate.getFullYear() === endDate.getFullYear()) {
                if (startDate.getMonth() === endDate.getMonth()) {
                    formattedStr = globalize.formatDate(startDate, { format: 'MMMM dd', calendar: mode }) + ' - ' +
                        globalize.formatDate(endDate, { format: 'dd, yyyy', calendar: mode });
                }
                else {
                    formattedStr = globalize.formatDate(startDate, { format: 'MMM dd', calendar: mode }) + ' - ' +
                        globalize.formatDate(endDate, { format: 'MMM dd, yyyy', calendar: mode });
                }
            }
            else {
                formattedStr = globalize.
                    formatDate(startDate, { format: 'MMM dd, yyyy', calendar: mode }) + ' - ' +
                    globalize.formatDate(endDate, { format: 'MMM dd, yyyy', calendar: mode });
            }
        }
        else {
            formattedStr = globalize.formatDate(startDate, { format: longDateFormat, calendar: mode }) + ' - ' +
                globalize.formatDate(endDate, { format: longDateFormat, calendar: mode });
        }
        return capitalizeFirstWord(formattedStr, 'multiple');
    };
    ViewBase.prototype.getMobileDateElement = function (date, className) {
        var wrap = createElement('div', {
            className: className,
            innerHTML: '<div class="e-m-date">' + this.parent.globalize.formatDate(date, { format: 'd', calendar: this.parent.getCalendarMode() }) + '</div>' + '<div class="e-m-day">' +
                capitalizeFirstWord(this.parent.globalize.formatDate(date, { format: 'E', calendar: this.parent.getCalendarMode() }), 'single') + '</div>'
        });
        return wrap;
    };
    ViewBase.prototype.setResourceHeaderContent = function (tdElement, tdData, className) {
        if (className === void 0) { className = TEXT_ELLIPSIS; }
        if (this.parent.activeViewOptions.resourceHeaderTemplate) {
            var data = { resource: tdData.resource, resourceData: tdData.resourceData };
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.resourceHeaderTemplateName;
            var templateId = scheduleId + viewName + 'resourceHeaderTemplate';
            var quickTemplate = [].slice.call(this.parent.getResourceHeaderTemplate()(data, this.parent, 'resourceHeaderTemplate', templateId, false));
            append(quickTemplate, tdElement);
        }
        else {
            var resourceText = createElement('div', { className: className });
            this.parent.sanitize(tdData.resourceData[tdData.resource.textField], resourceText);
            tdElement.appendChild(resourceText);
        }
    };
    ViewBase.prototype.renderResourceMobileLayout = function () {
        if (this.parent.resourceBase.lastResourceLevel && this.parent.resourceBase.lastResourceLevel.length <= 0) {
            return;
        }
        this.parent.resourceBase.renderResourceHeader();
        this.parent.resourceBase.renderResourceTree();
    };
    ViewBase.prototype.addAutoHeightClass = function (element) {
        if (!this.parent.uiStateValues.isGroupAdaptive && this.parent.rowAutoHeight && this.isTimelineView()
            && this.parent.activeViewOptions.group.resources.length > 0) {
            addClass([element], AUTO_HEIGHT);
        }
    };
    ViewBase.prototype.getColElements = function () {
        return [].slice.call(this.element.querySelectorAll('.' + CONTENT_WRAP_CLASS + ' col, .' + DATE_HEADER_WRAP_CLASS + ' col'));
    };
    ViewBase.prototype.setColWidth = function (content) {
        if (this.isTimelineView()) {
            var colElements = this.getColElements();
            var contentBody = this.element.querySelector('.' + CONTENT_TABLE_CLASS + ' tbody');
            var colWidth_1 = (contentBody.getBoundingClientRect().width / (colElements.length / 2));
            if (content.offsetHeight !== content.clientHeight) {
                var resourceColumn = this.parent.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
                if (!isNullOrUndefined(resourceColumn)) {
                    setStyleAttribute(resourceColumn, { 'height': formatUnit(content.clientHeight) });
                }
            }
            var cssClass = "." + HEADER_CELLS_CLASS + ",." + TIME_SLOT_CLASS + ",." + HEADER_WEEK_CELLS_CLASS + ",." + HEADER_MONTH_CELLS_CLASS + ",." + HEADER_YEAR_CELLS_CLASS;
            var headerCellElements = [].slice.call(this.element.querySelectorAll(cssClass));
            headerCellElements.forEach(function (ele) {
                var colSpan = isNullOrUndefined(ele.getAttribute('colspan')) ? '1' : ele.getAttribute('colspan');
                var headerCellColSpan = parseInt(colSpan, 10);
                setStyleAttribute(ele, { 'width': formatUnit(colWidth_1 * headerCellColSpan) });
            });
        }
    };
    ViewBase.prototype.resetColWidth = function () {
        var colElements = this.getColElements();
        for (var _i = 0, colElements_1 = colElements; _i < colElements_1.length; _i++) {
            var col = colElements_1[_i];
            col.style.width = '';
        }
    };
    ViewBase.prototype.getContentAreaElement = function () {
        return this.element.querySelector('.' + CONTENT_WRAP_CLASS);
    };
    ViewBase.prototype.wireExpandCollapseIconEvents = function () {
        if (this.parent.resourceBase && this.parent.resourceBase.resourceCollection.length > 1) {
            var treeIcons = [].slice.call(this.element.querySelectorAll('.' + RESOURCE_TREE_ICON_CLASS));
            for (var _i = 0, treeIcons_1 = treeIcons; _i < treeIcons_1.length; _i++) {
                var icon = treeIcons_1[_i];
                EventHandler.clearEvents(icon);
                EventHandler.add(icon, 'click', this.parent.resourceBase.onTreeIconClick, this.parent.resourceBase);
            }
        }
    };
    ViewBase.prototype.scrollToDate = function (scrollDate) {
        if (['Month', 'TimelineMonth'].indexOf(this.parent.currentView) === -1 || isNullOrUndefined(scrollDate)) {
            return;
        }
        var scrollWrap = this.getContentAreaElement();
        var tdDate = new Date(resetTime(new Date(+scrollDate)).getTime()).getTime();
        var dateElement = scrollWrap.querySelector("." + WORK_CELLS_CLASS + "[data-date=\"" + tdDate + "\"]");
        if (this.parent.currentView === 'Month' && dateElement) {
            if (scrollWrap.scrollWidth > scrollWrap.clientWidth) {
                if (!this.parent.enableRtl) {
                    scrollWrap.scrollLeft = dateElement.offsetLeft;
                }
                else {
                    scrollWrap.scrollLeft = -(this.parent.getContentTable().offsetWidth - dateElement.offsetLeft - dateElement.offsetWidth);
                }
            }
            scrollWrap.scrollTop = dateElement.offsetTop;
        }
        if (this.parent.currentView === 'TimelineMonth' && dateElement) {
            if (!this.parent.enableRtl) {
                scrollWrap.scrollLeft = dateElement.offsetLeft;
            }
            else {
                scrollWrap.scrollLeft = -(this.parent.getContentTable().offsetWidth - dateElement.offsetLeft - dateElement.offsetWidth);
            }
        }
    };
    ViewBase.prototype.setPersistence = function () {
        if (this.parent.enablePersistence) {
            var contentWrap = this.element.querySelector('.e-content-wrap');
            if (!isNullOrUndefined(contentWrap)) {
                this.parent.scrollLeft = contentWrap.scrollLeft;
                this.parent.scrollTop = contentWrap.scrollTop;
            }
        }
    };
    ViewBase.prototype.retainScrollPosition = function () {
        if (this.parent.enablePersistence) {
            var conWrap = this.parent.element.querySelector('.e-content-wrap');
            if (!isNullOrUndefined(conWrap) && !isNullOrUndefined(this.parent.scrollLeft) && !isNullOrUndefined(this.parent.scrollTop)) {
                conWrap.scrollTop = this.parent.scrollTop;
                conWrap.scrollLeft = this.parent.scrollLeft;
            }
        }
    };
    ViewBase.prototype.getViewStartDate = function () {
        var startDate = this.renderDates[0];
        if (this.parent.activeViewOptions.group.resources.length > 0 && this.parent.resourceBase.lastResourceLevel.length > 0) {
            startDate = this.parent.resourceBase.getResourceRenderDates()[0];
        }
        return startDate;
    };
    ViewBase.prototype.getViewEndDate = function () {
        var endDate = addDays(this.renderDates[this.renderDates.length - 1], 1);
        if (this.parent.activeViewOptions.group.resources.length > 0 && this.parent.resourceBase.lastResourceLevel.length > 0) {
            endDate = addDays(this.parent.resourceBase.getResourceRenderDates().slice(-1)[0], 1);
        }
        return endDate;
    };
    ViewBase.prototype.getAdjustedDate = function (startTime) {
        if (!this.parent.activeViewOptions.timeScale.enable || this.parent.currentView === 'Month' ||
            (this.parent.currentView === 'TimelineYear' && this.parent.activeViewOptions.group.resources.length === 0)) {
            return new Date(startTime.setHours(0, 0, 0, 0));
        }
        else if (this.parent.currentView === 'TimelineYear' && this.parent.activeViewOptions.group.resources.length > 0) {
            startTime.setHours(0, 0, 0, 0);
            return new Date(startTime.setDate(1));
        }
        return null;
    };
    ViewBase.prototype.resetColLevels = function () {
        this.parent.resourceBase.expandedResources = [];
        var renderedCount = this.parent.virtualScrollModule.getRenderedCount();
        var lastLevel = this.parent.activeViewOptions.group.byDate ? this.colLevels[0] :
            this.parent.resourceBase.renderedResources;
        var index = 0;
        for (var i = 0; i < lastLevel.length; i++) {
            if (index >= renderedCount) {
                break;
            }
            index += lastLevel[parseInt(i.toString(), 10)].colSpan;
            this.parent.resourceBase.expandedResources.push(lastLevel[parseInt(i.toString(), 10)]);
        }
        if (this.parent.activeViewOptions.group.byDate) {
            this.colLevels[0] = this.parent.resourceBase.expandedResources;
            this.parent.virtualScrollModule.setRenderedDates(this.parent.resourceBase.expandedResources);
        }
        else {
            this.colLevels[this.colLevels.length - 2] = this.parent.resourceBase.expandedResources;
            this.parent.resourceBase.renderedResources = this.parent.resourceBase.expandedResources;
        }
        if (this.parent.currentView !== 'Month') {
            this.colLevels[this.colLevels.length - 1] = this.colLevels[this.colLevels.length - 1].slice(0, index);
            this.parent.resourceBase.expandedResources = this.colLevels[this.colLevels.length - 1];
        }
    };
    ViewBase.prototype.destroy = function () {
        if (this.element && this.element.parentNode) {
            remove(this.element);
        }
        this.element = null;
        this.renderDates = null;
        this.colLevels = null;
    };
    return ViewBase;
}());

var __extends$18 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * vertical view
 */
var VerticalView = /** @__PURE__ @class */ (function (_super) {
    __extends$18(VerticalView, _super);
    function VerticalView(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-day-view';
        _this.isInverseTableSelect = true;
        _this.baseCssClass = 'e-vertical-view';
        _this.appointment = null;
        return _this;
    }
    VerticalView.prototype.getModuleName = function () {
        return 'verticalView';
    };
    VerticalView.prototype.addEventListener = function () {
        this.parent.on(scrollUiUpdate, this.scrollUiUpdate, this);
        this.parent.on(dataReady, this.renderEvents, this);
    };
    VerticalView.prototype.removeEventListener = function () {
        if (this.parent) {
            this.parent.off(scrollUiUpdate, this.scrollUiUpdate);
            this.parent.off(dataReady, this.renderEvents);
        }
    };
    VerticalView.prototype.renderEvents = function () {
        this.appointment = this.parent.activeViewOptions.timeScale.enable ?
            new VerticalEvent(this.parent) : new MonthEvent(this.parent);
        this.appointment.renderAppointments();
        this.parent.notify(eventsLoaded, {});
    };
    VerticalView.prototype.onContentScroll = function (e) {
        this.parent.removeNewEventElement();
        var target = e.target;
        this.parent.notify(virtualScroll, e);
        this.scrollLeftPanel(target);
        this.scrollTopPanel(target);
        if (!this.parent.isAdaptive) {
            this.parent.uiStateValues.top = target.scrollTop;
        }
        this.parent.uiStateValues.left = target.scrollLeft;
        if (!isNullOrUndefined(this.parent.quickPopup)) {
            this.parent.quickPopup.quickPopupHide();
        }
        this.setPersistence();
    };
    VerticalView.prototype.onAdaptiveMove = function (e) {
        if (this.parent.uiStateValues.action) {
            e.preventDefault();
        }
    };
    VerticalView.prototype.onAdaptiveScroll = function (e) {
        if (this.parent && !this.parent.isDestroyed) {
            this.parent.removeNewEventElement();
            this.parent.uiStateValues.top = e.target.scrollTop;
        }
    };
    VerticalView.prototype.scrollLeftPanel = function (target) {
        var leftPanel = this.getLeftPanelElement();
        if (!isNullOrUndefined(leftPanel)) {
            leftPanel.scrollTop = target.scrollTop;
        }
    };
    VerticalView.prototype.scrollUiUpdate = function (args) {
        if (!this.parent) {
            return;
        }
        var dateHeader = this.parent.element.querySelector('.' + DATE_HEADER_WRAP_CLASS);
        var headerBarHeight = this.getHeaderBarHeight();
        var timeCells = this.getLeftPanelElement();
        var content = this.getScrollableElement();
        var header = this.getDatesHeaderElement();
        var scrollerHeight = this.parent.element.offsetHeight - headerBarHeight - header.offsetHeight;
        this.setContentHeight(content, timeCells, scrollerHeight);
        this.setColWidth(content);
        var scrollBarWidth = getScrollBarWidth();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header.firstElementChild.style[args.cssProperties.rtlBorder] = '';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header.style[args.cssProperties.rtlPadding] = '';
        var isDateHeaderScroll = this.parent.enableAllDayScroll ? !((content.offsetWidth - content.clientWidth) <=
            (dateHeader.offsetWidth - dateHeader.clientWidth) && dateHeader.classList.contains('e-all-day-scroll')) : true;
        if (content.offsetWidth - content.clientWidth > 0 && isDateHeaderScroll) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            header.firstElementChild.style[args.cssProperties.border] = scrollBarWidth > 0 ? '1px' : '0px';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            header.style[args.cssProperties.padding] = scrollBarWidth > 0 ? scrollBarWidth - 1 + 'px' : '0px';
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            header.firstElementChild.style[args.cssProperties.border] = '';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            header.style[args.cssProperties.padding] = '';
        }
        if (!args.isPreventScrollUpdate) {
            if (this.parent.uiStateValues.isInitial) {
                if (this.parent.currentView.indexOf('Timeline') > -1) {
                    content.scrollTop = this.parent.uiStateValues.top;
                }
                this.scrollToWorkHour();
                this.parent.uiStateValues.isInitial = false;
            }
            else {
                if (timeCells) {
                    timeCells.scrollTop = this.parent.uiStateValues.top;
                }
                content.scrollTop = this.parent.uiStateValues.top;
                content.scrollLeft = this.parent.uiStateValues.left;
            }
        }
        if (this.parent.activeViewOptions.timeScale.enable) {
            this.highlightCurrentTime();
        }
        this.retainScrollPosition();
    };
    VerticalView.prototype.setContentHeight = function (element, leftPanelElement, height) {
        if (this.parent.isAdaptive && !this.isTimelineView()) {
            element.style.height = (this.parent.height === 'auto') ? 'auto' : formatUnit(height);
        }
        else {
            if (!isNullOrUndefined(leftPanelElement)) {
                leftPanelElement.style.height = (this.parent.height === 'auto') ? 'auto'
                    : formatUnit(height - this.getScrollXIndent(element));
            }
            element.style.height = (this.parent.height === 'auto') ? 'auto' : formatUnit(height);
        }
    };
    VerticalView.prototype.scrollToWorkHour = function () {
        if (this.parent.workHours.highlight) {
            var firstWorkHourCell = this.element.querySelector('.' + WORK_HOURS_CLASS);
            if (firstWorkHourCell) {
                this.getScrollableElement().scrollTop = firstWorkHourCell.offsetTop;
                this.parent.uiStateValues.top = firstWorkHourCell.offsetTop;
                this.parent.uiStateValues.left = 0;
            }
        }
    };
    VerticalView.prototype.scrollToHour = function (hour, scrollDate) {
        var date = this.parent.getStartEndTime(hour);
        if (!isNullOrUndefined(scrollDate)) {
            var headerElement = this.element.querySelector('.' + HEADER_CELLS_CLASS + '[data-date="' + new Date(resetTime(scrollDate)).getTime() + '"]');
            if (headerElement) {
                if (this.parent.enableRtl) {
                    var conWrap = this.element.querySelector('.' + CONTENT_TABLE_CLASS);
                    this.getScrollableElement().scrollLeft = -(conWrap.offsetWidth - headerElement.offsetLeft - headerElement.offsetWidth);
                }
                else {
                    this.getScrollableElement().scrollLeft = headerElement.offsetLeft;
                }
            }
        }
        if (isNullOrUndefined(date)) {
            return;
        }
        this.getScrollableElement().scrollTop = this.getTopFromDateTime(date);
    };
    VerticalView.prototype.scrollToDate = function (scrollDate) {
        this.scrollToHour(null, scrollDate);
    };
    VerticalView.prototype.generateColumnLevels = function () {
        var level = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
        var columnLevels = [];
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            columnLevels = this.parent.resourceBase.generateResourceLevels(level);
            if (this.parent.activeViewOptions.group.hideNonWorkingDays) {
                while (columnLevels[0].length === 0) {
                    this.parent.setProperties({ selectedDate: this.parent.activeView.getNextPreviousDate(this.previousNextAction) }, true);
                    this.parent.activeView.getRenderDates();
                    if (this.parent.headerModule) {
                        this.parent.headerModule.setCalendarDate(this.parent.selectedDate);
                        this.parent.headerModule.updateDateRange();
                    }
                    level = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
                    columnLevels = this.parent.resourceBase.generateResourceLevels(level);
                }
            }
            if (this.parent.uiStateValues.isGroupAdaptive && this.parent.resourceBase.lastResourceLevel.length > 0) {
                var resourceLevel = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
                var resStartHour = resourceLevel.resourceData[resourceLevel.resource.startHourField];
                var resEndHour = resourceLevel.resourceData[resourceLevel.resource.endHourField];
                var dateSlots = this.getDateSlots(resourceLevel.renderDates, resourceLevel.workDays, resStartHour, resEndHour);
                columnLevels = [dateSlots];
            }
        }
        else {
            columnLevels.push(level);
        }
        this.colLevels = columnLevels;
        return columnLevels;
    };
    // eslint-disable-next-line max-len
    VerticalView.prototype.getDateSlots = function (renderDates, workDays, workStartHour, workEndHour) {
        if (workStartHour === void 0) { workStartHour = this.parent.workHours.start; }
        if (workEndHour === void 0) { workEndHour = this.parent.workHours.end; }
        var dateCol = [];
        var start = this.parent.getStartEndTime(workStartHour);
        var end = this.parent.getStartEndTime(workEndHour);
        for (var _i = 0, renderDates_1 = renderDates; _i < renderDates_1.length; _i++) {
            var col = renderDates_1[_i];
            var classList$$1 = [HEADER_CELLS_CLASS];
            if (this.isCurrentDate(col)) {
                classList$$1.push(CURRENT_DAY_CLASS);
            }
            dateCol.push({
                date: col, type: 'dateHeader', className: classList$$1, colSpan: 1,
                workDays: workDays, startHour: new Date(+start), endHour: new Date(+end)
            });
        }
        return dateCol;
    };
    VerticalView.prototype.isWorkHourRange = function (date) {
        return (this.getStartHour().getTime() <= date.getTime()) && (this.getEndHour().getTime() >= date.getTime());
    };
    VerticalView.prototype.highlightCurrentTime = function () {
        var _this = this;
        if (this.parent.activeViewOptions.headerRows.length > 0 &&
            this.parent.activeViewOptions.headerRows.slice(-1)[0].option !== 'Hour') {
            return;
        }
        var currentDate = this.parent.getCurrentTime();
        if (this.parent.showTimeIndicator && this.isWorkHourRange(currentDate)) {
            var currentDateIndex = this.getCurrentTimeIndicatorIndex();
            if (currentDateIndex.length > 0) {
                var workCells = [].slice.call(this.element.querySelectorAll('.' + WORK_CELLS_CLASS));
                if (workCells.length > 0) {
                    this.changeCurrentTimePosition();
                }
                if (isNullOrUndefined(this.currentTimeIndicatorTimer)) {
                    var interval = MS_PER_MINUTE - ((currentDate.getSeconds() * 1000) + currentDate.getMilliseconds());
                    if (interval <= (MS_PER_MINUTE - 1000)) {
                        window.setTimeout(function () {
                            if (!isNullOrUndefined(_this.currentTimeIndicatorTimer)) {
                                _this.clearCurrentTimeIndicatorTimer();
                                _this.changeCurrentTimePosition();
                                _this.updateCurrentTimeIndicatorTimer();
                            }
                        }, interval);
                    }
                    this.updateCurrentTimeIndicatorTimer();
                }
            }
            else {
                this.clearCurrentTimeIndicatorTimer();
            }
        }
        else {
            this.clearCurrentTimeIndicatorTimer();
        }
    };
    VerticalView.prototype.getCurrentTimeIndicatorIndex = function () {
        var currentDateIndex = [];
        if (!isNullOrUndefined(this.parent.resourceBase) && (this.parent.activeViewOptions.group.resources.length > 0) &&
            !this.parent.uiStateValues.isGroupAdaptive) {
            var count = 0;
            var currentDate = resetTime(this.parent.getCurrentTime());
            if (this.parent.virtualScrollModule && this.parent.activeViewOptions.allowVirtualScrolling &&
                this.parent.activeViewOptions.group.byDate) {
                for (var _i = 0, _a = this.parent.resourceBase.expandedResources; _i < _a.length; _i++) {
                    var resource = _a[_i];
                    if (resetTime(resource.date).getTime() === currentDate.getTime()) {
                        currentDateIndex.push(count);
                    }
                    count += 1;
                }
            }
            else {
                for (var _b = 0, _c = this.parent.resourceBase.renderedResources; _b < _c.length; _b++) {
                    var resource = _c[_b];
                    var index = this.parent.getIndexOfDate(resource.renderDates, currentDate);
                    if (index >= 0) {
                        var resIndex = this.parent.activeViewOptions.group.byDate ?
                            (this.parent.resourceBase.lastResourceLevel.length * index) + count : count + index;
                        currentDateIndex.push(resIndex);
                    }
                    count += this.parent.activeViewOptions.group.byDate ? 1 : resource.renderDates.length;
                }
            }
        }
        else {
            var renderDates = (this.parent.uiStateValues.isGroupAdaptive && this.parent.resourceBase.lastResourceLevel.length > 0)
                ? this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex].renderDates : this.renderDates;
            var index = this.parent.getIndexOfDate(renderDates, resetTime(this.parent.getCurrentTime()));
            if (index >= 0) {
                currentDateIndex.push(index);
            }
        }
        return currentDateIndex;
    };
    VerticalView.prototype.clearCurrentTimeIndicatorTimer = function () {
        if (!isNullOrUndefined(this.currentTimeIndicatorTimer)) {
            window.clearInterval(this.currentTimeIndicatorTimer);
            this.currentTimeIndicatorTimer = null;
            this.removeCurrentTimeIndicatorElements();
        }
    };
    VerticalView.prototype.updateCurrentTimeIndicatorTimer = function () {
        var _this = this;
        this.currentTimeIndicatorTimer = window.setInterval(function () { _this.changeCurrentTimePosition(); }, MS_PER_MINUTE);
    };
    VerticalView.prototype.removeCurrentTimeIndicatorElements = function () {
        var queryString = '.' + PREVIOUS_TIMELINE_CLASS + ',.' + CURRENT_TIMELINE_CLASS + ',.' + CURRENT_TIME_CLASS;
        var timeIndicator = [].slice.call(this.element.querySelectorAll(queryString));
        for (var _i = 0, timeIndicator_1 = timeIndicator; _i < timeIndicator_1.length; _i++) {
            var indicator = timeIndicator_1[_i];
            remove(indicator);
        }
    };
    VerticalView.prototype.changeCurrentTimePosition = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.removeCurrentTimeIndicatorElements();
        var currentDateIndex = this.getCurrentTimeIndicatorIndex();
        var firstRow = this.parent.getContentTable().rows[0];
        var top = this.getTopFromDateTime(this.parent.getCurrentTime());
        var topInPx = formatUnit(top);
        var rowIndex = Math.floor(top / firstRow.cells[0].offsetHeight);
        var timeCellsWrap = this.getLeftPanelElement();
        var timeTrs = [].slice.call(timeCellsWrap.querySelectorAll('tr'));
        if (isNullOrUndefined(rowIndex) || isNaN(rowIndex) || rowIndex === timeTrs.length) {
            return;
        }
        var curTimeWrap = [].slice.call(this.element.querySelectorAll('.' + TIMELINE_WRAPPER_CLASS));
        for (var i = 0, length_1 = currentDateIndex[0]; i < length_1; i++) {
            curTimeWrap[parseInt(i.toString(), 10)].appendChild(createElement('div', { className: PREVIOUS_TIMELINE_CLASS, styles: 'top:' + topInPx }));
        }
        for (var _i = 0, currentDateIndex_1 = currentDateIndex; _i < currentDateIndex_1.length; _i++) {
            var day = currentDateIndex_1[_i];
            curTimeWrap[parseInt(day.toString(), 10)].appendChild(createElement('div', { className: CURRENT_TIMELINE_CLASS, styles: 'top:' + topInPx }));
        }
        var currentTimeEle = createElement('div', {
            innerHTML: this.parent.getTimeString(this.parent.getCurrentTime()),
            className: CURRENT_TIME_CLASS,
            styles: 'top:' + topInPx
        });
        if (rowIndex <= timeTrs.length) {
            removeClass(timeCellsWrap.querySelectorAll('.' + HIDE_CHILDS_CLASS), HIDE_CHILDS_CLASS);
            if (timeTrs[parseInt(rowIndex.toString(), 10)]) {
                addClass([timeTrs[parseInt(rowIndex.toString(), 10)].lastElementChild], HIDE_CHILDS_CLASS);
            }
            prepend([currentTimeEle], timeCellsWrap);
            currentTimeEle.style.top = formatUnit(currentTimeEle.offsetTop - (currentTimeEle.offsetHeight / 2));
        }
    };
    VerticalView.prototype.getTopFromDateTime = function (date) {
        var startHour = this.getStartHour();
        var diffInMinutes = ((date.getHours() - startHour.getHours()) * 60) + (date.getMinutes() - startHour.getMinutes());
        return (diffInMinutes * this.getWorkCellHeight() * this.parent.activeViewOptions.timeScale.slotCount) /
            this.parent.activeViewOptions.timeScale.interval;
    };
    VerticalView.prototype.getWorkCellHeight = function () {
        return parseFloat(this.element.querySelector('.' + WORK_CELLS_CLASS).getBoundingClientRect().height.toFixed(2));
    };
    VerticalView.prototype.getTdContent = function (date, type, groupIndex) {
        var cntEle;
        var wrapper = createElement('div');
        var templateName = '';
        var templateId = this.parent.element.id + '_';
        switch (type) {
            case 'dateHeader':
                if (this.parent.activeViewOptions.dateHeaderTemplate) {
                    templateName = 'dateHeaderTemplate';
                    var args = { date: date, type: type };
                    var viewName = this.parent.activeViewOptions.dateHeaderTemplateName;
                    cntEle = [].slice.call(this.parent.getDateHeaderTemplate()(args, this.parent, templateName, templateId + viewName + templateName, false));
                }
                else {
                    wrapper.innerHTML = this.parent.activeView.isTimelineView() ?
                        "<span class=\"e-header-date e-navigate\">" + this.getTimelineDate(date) + "</span>" :
                        "<div class=\"e-header-day\">" + capitalizeFirstWord(this.getDayName(date), 'single') + "</div>" +
                            ("<div class=\"e-header-date e-navigate\" role=\"link\">" + this.getDate(date) + "</div>");
                    cntEle = [].slice.call(wrapper.childNodes);
                }
                break;
            case 'majorSlot':
                if (this.parent.activeViewOptions.timeScale.majorSlotTemplate) {
                    templateName = 'majorSlotTemplate';
                    var args = { date: date, type: type };
                    cntEle = [].slice.call(this.parent.getMajorSlotTemplate()(args, this.parent, templateName, templateId + templateName, false));
                }
                else {
                    wrapper.innerHTML = "<span>" + this.getTime(date) + "</span>";
                    cntEle = [].slice.call(wrapper.childNodes);
                }
                break;
            case 'minorSlot':
                if (this.parent.activeViewOptions.timeScale.minorSlotTemplate) {
                    templateName = 'minorSlotTemplate';
                    var args = { date: date, type: type };
                    cntEle = [].slice.call(this.parent.getMinorSlotTemplate()(args, this.parent, templateName, templateId + templateName, false));
                }
                else {
                    cntEle = [].slice.call(wrapper.childNodes);
                }
                break;
            case 'alldayCells':
                if (this.parent.activeViewOptions.cellTemplate) {
                    var viewName = this.parent.activeViewOptions.cellTemplateName;
                    templateName = 'cellTemplate';
                    var args = { date: date, type: type, groupIndex: groupIndex };
                    cntEle = [].slice.call(this.parent.getCellTemplate()(args, this.parent, templateName, templateId + viewName + templateName, false));
                }
                break;
        }
        return cntEle;
    };
    VerticalView.prototype.refreshHeader = function () {
        remove(this.element.querySelector('tbody tr'));
        this.renderHeader();
        this.parent.notify(contentReady, {});
    };
    VerticalView.prototype.renderLayout = function (type) {
        this.setPanel(createElement('div', { className: TABLE_WRAP_CLASS }));
        var clsList = [this.baseCssClass, this.viewClass];
        clsList.push(type);
        if (this.parent.activeViewOptions.group.byDate) {
            clsList.push('e-by-date');
        }
        if (!this.parent.activeViewOptions.timeScale.enable) {
            addClass([this.element], [TIMESCALE_DISABLE, this.viewClass]);
        }
        if (this.parent.activeViewOptions.allowVirtualScrolling && !this.parent.uiStateValues.isGroupAdaptive) {
            clsList.push(VIRTUAL_SCROLL_CLASS);
        }
        if (this.parent.rowAutoHeight && this.parent.eventSettings.ignoreWhitespace) {
            clsList.push(IGNORE_WHITESPACE);
        }
        this.renderPanel(type);
        addClass([this.element], clsList);
        this.element.appendChild(this.createTableLayout(OUTER_TABLE_CLASS));
        this.element.querySelector('table').setAttribute('role', 'presentation');
        this.colLevels = this.generateColumnLevels();
        this.renderHeader();
        this.renderContent();
        if (this.parent.uiStateValues.isGroupAdaptive && !this.parent.element.querySelector('.' + RESOURCE_TOOLBAR_CONTAINER)) {
            this.renderResourceMobileLayout();
        }
        this.parent.notify(contentReady, {});
    };
    VerticalView.prototype.renderHeader = function () {
        var tr = createElement('tr');
        var dateTd = createElement('td');
        dateTd.appendChild(this.renderDatesHeader());
        if (this.parent.activeViewOptions.timeScale.enable) {
            var indentTd = createElement('td', { className: LEFT_INDENT_CLASS });
            indentTd.appendChild(this.renderLeftIndent());
            tr.appendChild(indentTd);
        }
        tr.appendChild(dateTd);
        prepend([tr], this.element.querySelector('tbody'));
    };
    VerticalView.prototype.renderContent = function () {
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.resourceBase.renderedResources = extend([], this.parent.resourceBase.lastResourceLevel, null, true);
        }
        var tr = createElement('tr');
        var workTd = createElement('td');
        if (this.parent.isAdaptive) {
            workTd.setAttribute('colspan', (this.parent.activeViewOptions.timeScale.enable ? '2' : '1'));
            var scrollContainer = createElement('div', { className: SCROLL_CONTAINER_CLASS });
            if (this.parent.activeViewOptions.timeScale.enable) {
                scrollContainer.appendChild(this.renderTimeCells());
            }
            scrollContainer.appendChild(this.renderContentArea());
            workTd.appendChild(scrollContainer);
            EventHandler.add(scrollContainer, 'scroll', this.onAdaptiveScroll, this);
            EventHandler.add(scrollContainer, Browser.touchMoveEvent, this.onAdaptiveMove, this);
            tr.appendChild(workTd);
        }
        else {
            var levels = this.colLevels.slice(0);
            if (this.parent.virtualScrollModule) {
                this.resetColLevels();
            }
            var wrap = this.renderContentArea();
            workTd.appendChild(wrap);
            if (this.parent.activeViewOptions.timeScale.enable) {
                var timesTd = createElement('td');
                timesTd.appendChild(this.renderTimeCells());
                tr.appendChild(timesTd);
            }
            tr.appendChild(workTd);
            if (this.parent.virtualScrollModule) {
                this.colLevels = levels;
                this.parent.virtualScrollModule.renderVirtualTrack(wrap);
            }
        }
        this.element.querySelector('tbody').appendChild(tr);
    };
    VerticalView.prototype.renderLeftIndent = function () {
        var wrap = createElement('div', { className: LEFT_INDENT_WRAP_CLASS });
        var tbl = this.createTableLayout();
        var trEle = createElement('tr');
        var rowCount = this.colLevels.length;
        var nth;
        for (var i = 0; i < rowCount; i++) {
            var ntr_1 = trEle.cloneNode();
            var data_1 = { className: [(this.colLevels[parseInt(i.toString(), 10)][0] && this.colLevels[parseInt(i.toString(), 10)][0].className[0])], type: 'emptyCells' };
            if (this.parent.activeViewOptions.showWeekNumber && data_1.className.indexOf(HEADER_CELLS_CLASS) !== -1) {
                data_1.className.push(WEEK_NUMBER_CLASS);
                var weekNo = this.parent.getWeekNumberContent(this.renderDates);
                data_1.template = [createElement('span', {
                        innerHTML: '' + weekNo,
                        attrs: { title: this.parent.localeObj.getConstant('week') + ' ' + weekNo }
                    })];
            }
            nth = this.createTd(data_1);
            this.parent.renderHeaderIndentTemplate(data_1, nth);
            ntr_1.appendChild(nth);
            tbl.querySelector('tbody').appendChild(ntr_1);
        }
        var ntr = trEle.cloneNode();
        var appointmentExpandCollapse = createElement('div', {
            attrs: {
                'tabindex': '0', 'role': 'list',
                title: this.parent.localeObj.getConstant('expandAllDaySection'), 'aria-disabled': 'false', 'aria-label': 'Expand section'
            },
            className: ALLDAY_APPOINTMENT_SECTION_CLASS + ' ' + APPOINTMENT_ROW_EXPAND_CLASS + ' ' +
                ICON + ' ' + DISABLE_CLASS
        });
        var data = { className: [ALLDAY_CELLS_CLASS], type: 'emptyCells' };
        nth = this.createTd(data);
        nth.appendChild(appointmentExpandCollapse);
        this.parent.renderHeaderIndentTemplate(data, nth);
        ntr.appendChild(nth);
        tbl.querySelector('tbody').appendChild(ntr);
        wrap.appendChild(tbl);
        return wrap;
    };
    VerticalView.prototype.renderDatesHeader = function () {
        var container = createElement('div', { className: DATE_HEADER_CONTAINER_CLASS });
        var wrap = createElement('div', { className: DATE_HEADER_WRAP_CLASS });
        container.appendChild(wrap);
        var tbl = this.createTableLayout();
        var trEle = createElement('tr', { className: HEADER_ROW_CLASS });
        var rowCount = this.colLevels.length;
        var lastLevel = this.colLevels[rowCount - 1];
        for (var i = 0; i < rowCount; i++) {
            var ntr = trEle.cloneNode();
            var level = this.colLevels[parseInt(i.toString(), 10)];
            for (var j = 0; j < level.length; j++) {
                ntr.appendChild(this.createTd(level[parseInt(j.toString(), 10)]));
            }
            tbl.querySelector('tbody').appendChild(ntr);
        }
        this.createAllDayRow(tbl, lastLevel);
        this.createColGroup(tbl, lastLevel);
        wrap.appendChild(tbl);
        return container;
    };
    VerticalView.prototype.createAllDayRow = function (table, tdData) {
        var ntr = createElement('tr', { className: ALLDAY_ROW_CLASS });
        for (var j = 0; j < tdData.length; j++) {
            var td = extend({}, tdData[parseInt(j.toString(), 10)]);
            td.className = [ALLDAY_CELLS_CLASS];
            td.type = 'alldayCells';
            var ntd = this.createTd(td);
            ntd.setAttribute('data-date', td.date.getTime().toString());
            if (!isNullOrUndefined(td.groupIndex)) {
                ntd.setAttribute('data-group-index', '' + td.groupIndex);
            }
            else if (this.parent.uiStateValues.isGroupAdaptive) {
                ntd.setAttribute('data-group-index', '' + this.parent.uiStateValues.groupIndex);
            }
            this.wireCellEvents(ntd);
            ntr.appendChild(ntd);
        }
        table.querySelector('tbody').appendChild(ntr);
        var thead = createElement('thead');
        thead.appendChild(this.parent.eventBase.createEventWrapper('allDay'));
        prepend([thead], table);
    };
    VerticalView.prototype.createTd = function (td) {
        var tdEle = createElement('td');
        this.addAttributes(td, tdEle);
        if (td.date && td.type) {
            var ele = this.getTdContent(td.date, td.type, td.groupIndex);
            if (ele && ele.length) {
                append(ele, tdEle);
            }
        }
        if (!this.parent.isMinMaxDate(resetTime(new Date('' + td.date)))) {
            addClass([tdEle], DISABLE_DATES);
        }
        if (td.type === 'resourceHeader') {
            this.setResourceHeaderContent(tdEle, td);
        }
        if (td.type === 'dateHeader' && td.className.indexOf(HEADER_CELLS_CLASS) >= 0) {
            tdEle.setAttribute('data-date', td.date.getTime().toString());
            if (!isNullOrUndefined(td.groupIndex)) {
                tdEle.setAttribute('data-group-index', '' + td.groupIndex);
            }
            this.wireMouseEvents(tdEle);
        }
        var args = { elementType: td.type, element: tdEle, date: td.date, groupIndex: td.groupIndex };
        this.parent.trigger(renderCell, args);
        return tdEle;
    };
    VerticalView.prototype.wireCellEvents = function (element) {
        EventHandler.add(element, 'mousedown', this.parent.workCellAction.cellMouseDown, this.parent.workCellAction);
        this.wireMouseEvents(element);
    };
    VerticalView.prototype.wireMouseEvents = function (element) {
        EventHandler.add(element, 'click', this.parent.workCellAction.cellClick, this.parent.workCellAction);
        if (!this.parent.isAdaptive) {
            EventHandler.add(element, 'dblclick', this.parent.workCellAction.cellDblClick, this.parent.workCellAction);
        }
    };
    VerticalView.prototype.renderTimeCells = function () {
        var _this = this;
        var wrap = createElement('div', { className: TIME_CELLS_WRAP_CLASS });
        var tbl = this.createTableLayout();
        var trEle = createElement('tr');
        var handler = function (r) {
            r.type = r.first ? 'majorSlot' : 'minorSlot';
            r.className = r.last ? [TIME_CELLS_CLASS, TIME_SLOT_CLASS] : [TIME_SLOT_CLASS];
            var ntr = trEle.cloneNode();
            var data = { date: r.date, type: r.type, className: r.className };
            ntr.appendChild(_this.createTd(data));
            tbl.querySelector('tbody').appendChild(ntr);
            return r;
        };
        this.getTimeSlotRows(handler);
        wrap.appendChild(tbl);
        return wrap;
    };
    VerticalView.prototype.renderContentArea = function () {
        var wrap = createElement('div', { className: CONTENT_WRAP_CLASS });
        var tbl = this.createTableLayout(CONTENT_TABLE_CLASS);
        this.setAriaAttributes(tbl);
        this.addAutoHeightClass(tbl);
        this.renderContentTable(tbl);
        this.createColGroup(tbl, this.colLevels.slice(-1)[0]);
        wrap.appendChild(tbl);
        this.wireCellEvents(tbl.querySelector('tbody'));
        EventHandler.add(wrap, 'scroll', this.onContentScroll, this);
        EventHandler.add(wrap, Browser.touchMoveEvent, this.onAdaptiveMove, this);
        return wrap;
    };
    VerticalView.prototype.renderContentTable = function (table) {
        var tBody = table.querySelector('tbody');
        append(this.getContentRows(), tBody);
        this.renderContentTableHeader(table);
    };
    VerticalView.prototype.getContentRows = function () {
        var _this = this;
        var rows = [];
        var tr = createElement('tr');
        var td = createElement('td', { attrs: { 'aria-selected': 'false' } });
        var handler = function (r) {
            var ntr = tr.cloneNode();
            for (var _i = 0, _a = _this.colLevels[_this.colLevels.length - 1]; _i < _a.length; _i++) {
                var tdData = _a[_i];
                var ntd = _this.createContentTd(tdData, r, td);
                ntr.appendChild(ntd);
            }
            rows.push(ntr);
            return r;
        };
        this.getTimeSlotRows(handler);
        return rows;
    };
    VerticalView.prototype.createContentTd = function (tdData, r, td) {
        var ntd = td.cloneNode();
        if (tdData.colSpan) {
            ntd.setAttribute('colspan', tdData.colSpan.toString());
        }
        var clsName = this.getContentTdClass(r);
        var cellDate = resetTime(tdData.date);
        if (!this.parent.isMinMaxDate(cellDate)) {
            clsName.push(DISABLE_DATES);
        }
        setTime(cellDate, getDateInMs(r.date));
        var type = 'workCells';
        if (tdData.className.indexOf(RESOURCE_PARENT_CLASS) !== -1) {
            clsName.push(RESOURCE_GROUP_CELLS_CLASS);
            type = 'resourceGroupCells';
        }
        if (this.parent.workHours.highlight && ((this.parent.activeViewOptions.timeScale.enable &&
            this.isWorkHour(cellDate, tdData.startHour, tdData.endHour, tdData.workDays)) ||
            (!this.parent.activeViewOptions.timeScale.enable && this.isWorkDay(cellDate, tdData.workDays)))) {
            clsName.push(WORK_HOURS_CLASS);
        }
        addClass([ntd], clsName);
        if (this.parent.activeViewOptions.cellTemplate) {
            var args_1 = { date: cellDate, type: type, groupIndex: tdData.groupIndex };
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.cellTemplateName;
            var templateId = scheduleId + viewName + 'cellTemplate';
            var tooltipTemplate = [].slice.call(this.parent.getCellTemplate()(args_1, this.parent, 'cellTemplate', templateId, false));
            append(tooltipTemplate, ntd);
        }
        ntd.setAttribute('data-date', cellDate.getTime().toString());
        if (!isNullOrUndefined(tdData.groupIndex) || this.parent.uiStateValues.isGroupAdaptive) {
            var groupIndex = this.parent.uiStateValues.isGroupAdaptive ? this.parent.uiStateValues.groupIndex :
                tdData.groupIndex;
            ntd.setAttribute('data-group-index', '' + groupIndex);
        }
        var args = { elementType: type, element: ntd, date: cellDate, groupIndex: tdData.groupIndex };
        this.parent.trigger(renderCell, args);
        return ntd;
    };
    VerticalView.prototype.getContentTdClass = function (r) {
        return r.last ? [WORK_CELLS_CLASS] : [WORK_CELLS_CLASS, ALTERNATE_CELLS_CLASS];
    };
    VerticalView.prototype.renderContentTableHeader = function (table) {
        var thead = createElement('thead');
        thead.appendChild(this.parent.eventBase.createEventWrapper());
        if (this.parent.activeViewOptions.timeScale.enable) {
            thead.appendChild(this.parent.eventBase.createEventWrapper('timeIndicator'));
        }
        prepend([thead], table);
    };
    VerticalView.prototype.getScrollableElement = function () {
        if (this.parent.isAdaptive && !this.isTimelineView()) {
            return this.element.querySelector('.' + SCROLL_CONTAINER_CLASS);
        }
        else {
            return this.getContentAreaElement();
        }
    };
    VerticalView.prototype.getLeftPanelElement = function () {
        return this.element.querySelector('.' + TIME_CELLS_WRAP_CLASS);
    };
    VerticalView.prototype.getEndDateFromStartDate = function (start) {
        var msMajorInterval = this.parent.activeViewOptions.timeScale.interval * MS_PER_MINUTE;
        var msInterval = msMajorInterval / this.parent.activeViewOptions.timeScale.slotCount;
        var end = new Date(start.getTime());
        end.setMilliseconds(end.getMilliseconds() + msInterval);
        return end;
    };
    VerticalView.prototype.getTimeSlotRows = function (handler) {
        var rows = [];
        var startHour = this.getStartHour();
        var endHour = this.getEndHour();
        var msMajorInterval = this.parent.activeViewOptions.timeScale.interval * MS_PER_MINUTE;
        var msInterval = msMajorInterval / this.parent.activeViewOptions.timeScale.slotCount;
        var length = Math.round(MS_PER_DAY / msInterval);
        var msStartHour = startHour.getTime();
        var msEndHour = endHour.getTime();
        if (msStartHour !== msEndHour) {
            var duration = this.parent.activeViewOptions.timeScale.interval / this.parent.activeViewOptions.timeScale.slotCount;
            length = (Math.abs(msEndHour - msStartHour) / msInterval) - ((new Date(msEndHour).getTimezoneOffset()
                - new Date(msStartHour).getTimezoneOffset()) / duration);
        }
        if (!this.parent.activeViewOptions.timeScale.enable) {
            length = 1;
        }
        var start = this.parent.getStartEndTime(this.parent.workHours.start);
        var end = this.parent.getStartEndTime(this.parent.workHours.end);
        for (var i = 0; i < length; i++) {
            var dt = new Date(msStartHour + (msInterval * i));
            if (isDaylightSavingTime(dt) || new Date(msStartHour).getTimezoneOffset() !== dt.getTimezoneOffset()) {
                var timeOffset = new Date(msStartHour).getTimezoneOffset() - dt.getTimezoneOffset();
                dt = new Date(dt.getTime() - (1000 * 60 * timeOffset));
            }
            var majorTickDivider = i % (msMajorInterval / msInterval);
            var row = {
                date: new Date('' + dt),
                startHour: start,
                endHour: end,
                first: (majorTickDivider === 0),
                middle: (majorTickDivider < this.parent.activeViewOptions.timeScale.slotCount - 1),
                last: (majorTickDivider === this.parent.activeViewOptions.timeScale.slotCount - 1),
                type: ''
            };
            if (handler) {
                handler(row);
            }
            rows.push(row);
        }
        return rows;
    };
    VerticalView.prototype.getAdjustedDate = function (startTime) {
        if (!this.parent.activeViewOptions.timeScale.enable) {
            return new Date(startTime.setHours(0, 0, 0, 0));
        }
        else {
            var timeSlots = this.getTimeSlotRows();
            var startDate = new Date(new Date(timeSlots[0].date.getTime()).
                setHours(startTime.getHours(), startTime.getMinutes(), startTime.getMilliseconds()));
            for (var i = 0; i < timeSlots.length; i++) {
                if (timeSlots[parseInt(i.toString(), 10)].date.getTime() > startDate.getTime()) {
                    startTime.setHours(timeSlots[i - 1].date.getHours(), timeSlots[i - 1].date.getMinutes(), timeSlots[i - 1].date.getMilliseconds());
                    return new Date(startTime);
                }
            }
        }
        return null;
    };
    VerticalView.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.clearCurrentTimeIndicatorTimer();
        if (this.element) {
            if (this.appointment) {
                this.appointment.destroy();
                this.appointment = null;
            }
            var contentScrollableEle = this.getContentAreaElement();
            if (contentScrollableEle) {
                EventHandler.remove(contentScrollableEle, 'scroll', this.onContentScroll);
                EventHandler.remove(contentScrollableEle, Browser.touchMoveEvent, this.onAdaptiveMove);
            }
            if (this.parent.resourceBase) {
                this.parent.resourceBase.destroy();
            }
            if (this.parent.scheduleTouchModule) {
                this.parent.scheduleTouchModule.resetValues();
            }
            _super.prototype.destroy.call(this);
        }
    };
    return VerticalView;
}(ViewBase));

var __extends$17 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * day view
 */
var Day = /** @__PURE__ @class */ (function (_super) {
    __extends$17(Day, _super);
    /**
     * Constructor for day view
     *
     * @param {Schedule} parent Accepts the schedule instance
     */
    function Day(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-day-view';
        return _this;
    }
    /**
     * Get module name.
     *
     * @returns {string} Returns the module name.
     */
    Day.prototype.getModuleName = function () {
        return 'day';
    };
    return Day;
}(VerticalView));

var __extends$19 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * week view
 */
var Week = /** @__PURE__ @class */ (function (_super) {
    __extends$19(Week, _super);
    /**
     * Constructor for week view
     *
     * @param {Schedule} parent Accepts the schedule instance
     */
    function Week(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-week-view';
        return _this;
    }
    Week.prototype.startDate = function () {
        return this.getViewStartDate();
    };
    Week.prototype.endDate = function () {
        return this.getViewEndDate();
    };
    /**
     * Get module name.
     *
     * @returns {string} Returns the module name.
     */
    Week.prototype.getModuleName = function () {
        return 'week';
    };
    return Week;
}(VerticalView));

var __extends$20 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * work week view
 */
var WorkWeek = /** @__PURE__ @class */ (function (_super) {
    __extends$20(WorkWeek, _super);
    /**
     * Constructor for work week view
     *
     * @param {Schedule} parent Accepts the schedule instance
     */
    function WorkWeek(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-work-week-view';
        return _this;
    }
    WorkWeek.prototype.startDate = function () {
        return this.getViewStartDate();
    };
    WorkWeek.prototype.endDate = function () {
        return this.getViewEndDate();
    };
    /**
     * Get module name.
     *
     * @returns {string} Returns the module name.
     */
    WorkWeek.prototype.getModuleName = function () {
        return 'workWeek';
    };
    return WorkWeek;
}(VerticalView));

var __extends$21 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * month view
 */
var Month = /** @__PURE__ @class */ (function (_super) {
    __extends$21(Month, _super);
    function Month(parent) {
        var _this = _super.call(this, parent) || this;
        _this.dayNameFormat = 'wide';
        _this.viewClass = 'e-month-view';
        _this.isInverseTableSelect = false;
        _this.monthEvent = null;
        _this.monthDates = {};
        return _this;
    }
    Month.prototype.addEventListener = function () {
        this.parent.on(scrollUiUpdate, this.onScrollUIUpdate, this);
        this.parent.on(dataReady, this.onDataReady, this);
        this.parent.on(cellClick, this.onCellClick, this);
    };
    Month.prototype.removeEventListener = function () {
        if (this.parent) {
            this.parent.off(scrollUiUpdate, this.onScrollUIUpdate);
            this.parent.off(dataReady, this.onDataReady);
            this.parent.off(cellClick, this.onCellClick);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Month.prototype.onDataReady = function (args) {
        this.monthEvent = new MonthEvent(this.parent);
        this.monthEvent.renderAppointments();
        this.parent.notify(eventsLoaded, {});
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Month.prototype.onCellClick = function (event) { };
    Month.prototype.onContentScroll = function (e) {
        this.parent.removeNewEventElement();
        this.parent.notify(virtualScroll, e);
        this.scrollTopPanel(e.target);
        this.scrollLeftPanel(e.target);
        this.parent.uiStateValues.top = e.target.scrollTop;
        this.parent.uiStateValues.left = e.target.scrollLeft;
        this.setPersistence();
    };
    Month.prototype.scrollLeftPanel = function (target) {
        var leftPanel = this.getLeftPanelElement();
        if (leftPanel) {
            leftPanel.scrollTop = target.scrollTop;
        }
    };
    Month.prototype.getLeftPanelElement = function () {
        return this.element.querySelector('.' + WEEK_NUMBER_WRAPPER_CLASS);
    };
    Month.prototype.onScrollUIUpdate = function (args) {
        var headerHeight = this.getHeaderBarHeight();
        var header = this.getDatesHeaderElement();
        var content = this.getContentAreaElement();
        var height = this.parent.element.offsetHeight - headerHeight - header.offsetHeight;
        var leftPanel = this.getLeftPanelElement();
        if (this.parent.height !== 'auto') {
            this.setContentHeight(content, leftPanel, height);
        }
        var scrollBarWidth = getScrollBarWidth();
        header.firstElementChild.style[args.cssProperties.rtlBorder] = '';
        header.style[args.cssProperties.rtlPadding] = '';
        if (content.offsetWidth - content.clientWidth > 0) {
            header.firstElementChild.style[args.cssProperties.border] = scrollBarWidth > 0 ? '1px' : '0px';
            header.style[args.cssProperties.padding] = scrollBarWidth > 0 ? scrollBarWidth - 1 + 'px' : '0px';
        }
        else {
            header.firstElementChild.style[args.cssProperties.border] = '';
            header.style[args.cssProperties.padding] = '';
        }
        this.setColWidth(content);
        if (args.scrollPosition || !args.isPreventScrollUpdate && this.parent.currentView === 'TimelineMonth') {
            var top_1 = this.parent.currentView === 'TimelineMonth' ? this.parent.uiStateValues.top : args.scrollPosition.top;
            if (leftPanel) {
                leftPanel.scrollTop = top_1;
            }
            content.scrollTop = top_1;
            if (this.parent.uiStateValues.isInitial) {
                this.scrollToSelectedDate();
                this.parent.uiStateValues.isInitial = false;
            }
            else {
                content.scrollLeft = this.parent.currentView === 'TimelineMonth' ? this.parent.uiStateValues.left :
                    args.scrollPosition.left;
            }
        }
        else {
            this.scrollToSelectedDate();
        }
        this.retainScrollPosition();
    };
    Month.prototype.scrollToSelectedDate = function () {
        var headerCell = this.element.querySelector('.' + HEADER_CELLS_CLASS + '[data-date="'
            + this.parent.selectedDate.getTime().toString() + '"]');
        var content = this.getContentAreaElement();
        if (!isNullOrUndefined(headerCell)) {
            content.scrollLeft = !this.parent.enableRtl ?
                headerCell.offsetLeft : -(this.parent.getContentTable().offsetWidth - headerCell.offsetLeft - headerCell.offsetWidth);
        }
        else {
            content.scrollLeft = 0;
        }
        if (content.scrollLeft === 0 && this.parent.uiStateValues.isInitial) {
            this.parent.uiStateValues.left = 0;
        }
    };
    Month.prototype.setContentHeight = function (content, leftPanelElement, height) {
        content.style.height = 'auto';
        if (this.parent.currentView === 'Month') {
            content.style.height = formatUnit(height);
        }
        if (leftPanelElement) {
            if (this.parent.currentView === 'MonthAgenda') {
                height = (this.parent.activeViewOptions.interval > 1) ?
                    this.getContentAreaElement().firstElementChild.offsetHeight :
                    this.element.querySelector('.' + CONTENT_TABLE_CLASS).offsetHeight;
            }
            leftPanelElement.style.height = 'auto';
            leftPanelElement.style.height = formatUnit(height - this.getScrollXIndent(content));
        }
    };
    Month.prototype.generateColumnLevels = function () {
        var colLevels = [];
        var level = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            colLevels = this.parent.resourceBase.generateResourceLevels(level);
            if (this.parent.currentView === 'MonthAgenda') {
                colLevels = [level];
            }
            if (this.parent.uiStateValues.isGroupAdaptive && this.parent.resourceBase.lastResourceLevel.length > 0) {
                var resourceLevel = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
                var levels_1 = this.getDateSlots(resourceLevel.renderDates, resourceLevel.workDays);
                if (this.parent.activeViewOptions.group.byDate && this.parent.activeViewOptions.group.hideNonWorkingDays) {
                    var index = levels_1.findIndex(function (x, index) {
                        return index !== 0 && x.date.getDay() === levels_1[0].date.getDay();
                    });
                    levels_1 = index > -1 ? levels_1.slice(0, index) : levels_1;
                }
                colLevels = [levels_1];
            }
        }
        else {
            colLevels.push(level);
        }
        this.colLevels = colLevels;
        return colLevels;
    };
    Month.prototype.getDateSlots = function (renderDates, workDays) {
        var count = this.parent.activeViewOptions.showWeekend ? WEEK_LENGTH : workDays.length;
        var dateSlots = [];
        var isCurrentMonth = this.isCurrentMonth(this.parent.selectedDate);
        for (var col = 0; col < count; col++) {
            var classList$$1 = [HEADER_CELLS_CLASS];
            var currentDateIndex = renderDates.slice(0, count).map(function (date) { return date.getDay(); });
            if (isCurrentMonth && currentDateIndex.indexOf(this.parent.currentTimezoneDate.getDay()) === col) {
                classList$$1.push(CURRENT_DAY_CLASS);
            }
            dateSlots.push({ date: renderDates[parseInt(col.toString(), 10)], type: 'monthDay', className: classList$$1, colSpan: 1, workDays: workDays });
        }
        return dateSlots;
    };
    Month.prototype.getDayNameFormat = function () {
        if (this.parent.isAdaptive || this.parent.activeViewOptions.group.resources.length > 0) {
            return 'abbreviated';
        }
        return 'wide';
    };
    Month.prototype.renderLayout = function (type) {
        this.dayNameFormat = this.getDayNameFormat();
        this.setPanel(createElement('div', { className: TABLE_WRAP_CLASS }));
        var clsList = [this.viewClass];
        clsList.push(type);
        if (this.parent.activeViewOptions.group.byDate) {
            clsList.push('e-by-date');
            if (this.parent.currentView !== 'Month') {
                this.parent.activeViewOptions.group.hideNonWorkingDays = false;
            }
        }
        if (this.parent.activeViewOptions.allowVirtualScrolling && !this.parent.uiStateValues.isGroupAdaptive) {
            clsList.push(VIRTUAL_SCROLL_CLASS);
        }
        if (this.parent.rowAutoHeight && this.parent.eventSettings.ignoreWhitespace) {
            clsList.push(IGNORE_WHITESPACE);
        }
        addClass([this.element], clsList);
        this.renderPanel(type);
        this.element.appendChild(this.createTableLayout(OUTER_TABLE_CLASS));
        this.element.querySelector('table').setAttribute('role', 'presentation');
        this.colLevels = this.generateColumnLevels();
        this.renderHeader();
        this.renderContent();
        var target = (this.parent.currentView === 'MonthAgenda') ? this.parent.activeView.getPanel() : this.parent.element;
        if (this.parent.uiStateValues.isGroupAdaptive && !target.querySelector('.' + RESOURCE_TOOLBAR_CONTAINER)) {
            this.renderResourceMobileLayout();
        }
        this.parent.notify(contentReady, {});
        if (this.parent.uiStateValues.isCustomMonth) {
            this.parent.uiStateValues.isCustomMonth = false;
        }
    };
    Month.prototype.refreshHeader = function () {
        remove(this.element.querySelector('tbody tr'));
        this.renderHeader();
        this.parent.notify(contentReady, {});
    };
    Month.prototype.wireCellEvents = function (element) {
        EventHandler.add(element, 'mousedown', this.parent.workCellAction.cellMouseDown, this.parent.workCellAction);
        EventHandler.add(element, 'click', this.parent.workCellAction.cellClick, this.parent.workCellAction);
        if (!this.parent.isAdaptive) {
            EventHandler.add(element, 'dblclick', this.parent.workCellAction.cellDblClick, this.parent.workCellAction);
        }
    };
    Month.prototype.renderHeader = function () {
        var tr = createElement('tr');
        this.renderLeftIndent(tr);
        var dateTd = createElement('td');
        dateTd.appendChild(this.renderDatesHeader());
        tr.appendChild(dateTd);
        prepend([tr], this.element.querySelector('tbody'));
    };
    Month.prototype.renderLeftIndent = function (tr) {
        if (this.parent.activeViewOptions.showWeekNumber) {
            tr.appendChild(createElement('td', { className: 'e-left-indent' }));
        }
    };
    Month.prototype.renderContent = function () {
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.resourceBase.renderedResources = extend([], this.parent.resourceBase.lastResourceLevel, null, true);
        }
        var tr = createElement('tr');
        if (this.parent.activeViewOptions.showWeekNumber) {
            tr.appendChild(this.renderWeekNumberContent());
        }
        var workTd = createElement('td');
        var levels = this.colLevels.slice(0);
        if (this.parent.virtualScrollModule) {
            this.resetColLevels();
        }
        var wrap = createElement('div', { className: CONTENT_WRAP_CLASS });
        var contentArea = this.renderContentArea();
        if (this.parent.currentView === 'Month') {
            wrap.appendChild(contentArea);
        }
        else {
            var monthAgendaWrapper = createElement('div', { className: TABLE_CONTAINER_CLASS });
            monthAgendaWrapper.appendChild(contentArea);
            wrap.appendChild(monthAgendaWrapper);
        }
        EventHandler.add(wrap, 'scroll', this.onContentScroll, this);
        workTd.appendChild(wrap);
        tr.appendChild(workTd);
        if (this.parent.virtualScrollModule) {
            this.colLevels = levels;
            this.parent.virtualScrollModule.renderVirtualTrack(wrap);
        }
        this.element.querySelector('tbody').appendChild(tr);
        this.renderAppointmentContainer();
    };
    Month.prototype.renderWeekNumberContent = function () {
        var dateCol = this.renderDates.map(function (date) { return new Date(+date); });
        var td = createElement('td');
        var contentWrapper = createElement('div', { className: WEEK_NUMBER_WRAPPER_CLASS });
        td.appendChild(contentWrapper);
        var contentWrapTable = this.createTableLayout();
        contentWrapper.appendChild(contentWrapTable);
        var noOfDays = this.parent.activeViewOptions.showWeekend ? WEEK_LENGTH :
            this.parent.activeViewOptions.workDays.length;
        for (var i = 0, length_1 = (this.renderDates.length / noOfDays); i < length_1; i++) {
            var dates = dateCol.splice(0, noOfDays);
            var weekNumber = this.parent.getWeekNumberContent(dates);
            contentWrapTable.querySelector('tbody').appendChild(this.createWeekNumberElement(weekNumber));
        }
        return td;
    };
    Month.prototype.renderAppointmentContainer = function () {
        //Here needs to render mobile view appointment details on selected date
    };
    Month.prototype.renderDatesHeader = function () {
        var container = createElement('div', { className: DATE_HEADER_CONTAINER_CLASS });
        var wrap = createElement('div', { className: DATE_HEADER_WRAP_CLASS });
        container.appendChild(wrap);
        var table = this.createTableLayout();
        if (this.parent.currentView === 'Month') {
            var thead = createElement('thead');
            thead.appendChild(createElement('tr'));
            prepend([thead], table);
        }
        this.createColGroup(table, this.colLevels[this.colLevels.length - 1]);
        var trEle = createElement('tr');
        for (var i = 0; i < this.colLevels.length; i++) {
            var level = this.colLevels[parseInt(i.toString(), 10)];
            var ntr = trEle.cloneNode();
            for (var j = 0; j < level.length; j++) {
                var td = level[parseInt(j.toString(), 10)];
                ntr.appendChild(this.createHeaderCell(td));
            }
            table.querySelector('tbody').appendChild(ntr);
        }
        wrap.appendChild(table);
        return container;
    };
    Month.prototype.createHeaderCell = function (td) {
        var tdEle = createElement('td');
        this.addAttributes(td, tdEle);
        if (td.type === 'monthDay') {
            var ele = createElement('span', { innerHTML: capitalizeFirstWord(this.parent.getDayNames(this.dayNameFormat)[td.date.getDay()], 'single') });
            tdEle.appendChild(ele);
        }
        if (td.type === 'resourceHeader') {
            this.setResourceHeaderContent(tdEle, td);
        }
        if (td.type === 'dateHeader') {
            addClass([tdEle], DATE_HEADER_CLASS);
            tdEle.setAttribute('data-date', td.date.getTime().toString());
            if (this.parent.activeViewOptions.dateHeaderTemplate) {
                var cellArgs = { date: td.date, type: td.type };
                var elementId = this.parent.element.id + '_';
                var viewName = this.parent.activeViewOptions.dateHeaderTemplateName;
                var templateId = elementId + viewName + 'dateHeaderTemplate';
                var dateTemplate = [].slice.call(this.parent.getDateHeaderTemplate()(cellArgs, this.parent, 'dateHeaderTemplate', templateId, false));
                if (dateTemplate && dateTemplate.length) {
                    append(dateTemplate, tdEle);
                }
            }
            else {
                var ele = createElement('span', { className: NAVIGATE_CLASS });
                var skeleton = 'full';
                var title = this.parent.globalize.formatDate(td.date, { skeleton: skeleton, calendar: this.parent.getCalendarMode() });
                ele.setAttribute('title', capitalizeFirstWord(title, 'multiple'));
                var innerText = (this.parent.calendarUtil.isMonthStart(td.date) && !this.isCurrentDate(td.date) && !this.parent.isAdaptive) ?
                    this.parent.globalize.formatDate(td.date, { format: 'MMM d', calendar: this.parent.getCalendarMode() }) :
                    this.parent.globalize.formatDate(td.date, { skeleton: 'd', calendar: this.parent.getCalendarMode() });
                ele.innerHTML = capitalizeFirstWord(innerText, 'single');
                tdEle.appendChild(ele);
            }
            this.wireCellEvents(tdEle);
        }
        var args = { elementType: td.type, element: tdEle, date: td.date, groupIndex: td.groupIndex };
        this.parent.trigger(renderCell, args);
        return tdEle;
    };
    Month.prototype.getContentSlots = function () {
        if (!(this.colLevels[this.colLevels.length - 1] && this.colLevels[this.colLevels.length - 1][0])) {
            return [];
        }
        var slotDatas = [];
        var prepareSlots = function (rowIndex, renderDate, resData, classList$$1) {
            var data = {
                date: new Date(+renderDate), groupIndex: resData.groupIndex, workDays: resData.workDays,
                type: 'monthCells', className: classList$$1 || [WORK_CELLS_CLASS]
            };
            if (!slotDatas[parseInt(rowIndex.toString(), 10)]) {
                slotDatas[parseInt(rowIndex.toString(), 10)] = [];
            }
            slotDatas[parseInt(rowIndex.toString(), 10)].push(data);
        };
        var includeResource = this.parent.currentView !== 'MonthAgenda' &&
            this.parent.activeViewOptions.group.resources.length > 0;
        if (includeResource && !this.parent.uiStateValues.isGroupAdaptive && !this.parent.activeViewOptions.group.byDate) {
            for (var _i = 0, _a = this.colLevels[this.colLevels.length - 2]; _i < _a.length; _i++) {
                var res = _a[_i];
                var dates = res.renderDates.map(function (date) { return new Date(+date); });
                var count = this.parent.activeViewOptions.showWeekend ? WEEK_LENGTH : res.workDays.length;
                for (var i = 0; i < (res.renderDates.length / count); i++) {
                    var colDates = dates.splice(0, count);
                    for (var _b = 0, colDates_1 = colDates; _b < colDates_1.length; _b++) {
                        var colDate = colDates_1[_b];
                        prepareSlots(i, colDate, res);
                    }
                }
            }
        }
        else {
            var dates = this.renderDates.map(function (date) { return new Date(+date); });
            var count = this.parent.activeViewOptions.showWeekend ? WEEK_LENGTH :
                this.parent.activeViewOptions.workDays.length;
            var level = this.colLevels.slice(0, 1)[0];
            var startIndex = this.renderDates.map(Number).indexOf(+level[0].date);
            for (var i = 0; i < (this.renderDates.length / count); i++) {
                var colDates = dates.splice(0, count);
                var k = startIndex;
                var _loop_1 = function (j) {
                    var colDate = colDates[parseInt(k.toString(), 10)];
                    k++;
                    if (includeResource) {
                        var lastRow = this_1.colLevels[(this_1.colLevels.length - 1)];
                        var rowCount = lastRow.length / count;
                        var resourcesTd = lastRow.slice(0, rowCount);
                        if (this_1.parent.activeViewOptions.group.hideNonWorkingDays) {
                            resourcesTd = lastRow.filter(function (x) { return x.date.getDay() === colDate.getDay(); });
                            if (resourcesTd.length === 0) {
                                j = j - 1;
                                return out_j_1 = j, "continue";
                            }
                        }
                        for (var resIndex = 0; resIndex < resourcesTd.length; resIndex++) {
                            var clsList = void 0;
                            if (resIndex !== 0) {
                                clsList = [WORK_CELLS_CLASS, DISABLE_DATE];
                            }
                            prepareSlots(i, colDate, resourcesTd[parseInt(resIndex.toString(), 10)], clsList);
                        }
                    }
                    else {
                        prepareSlots(i, colDate, this_1.colLevels[this_1.colLevels.length - 1][0]);
                    }
                    out_j_1 = j;
                };
                var this_1 = this, out_j_1;
                for (var j = startIndex; j < (this.colLevels[0].length + startIndex) && j < colDates.length; j++) {
                    _loop_1(j);
                    j = out_j_1;
                }
            }
        }
        return slotDatas;
    };
    Month.prototype.updateClassList = function (data) {
        if (!this.isCustomMonth() && this.isOtherMonth(data.date)) {
            data.className.push(OTHERMONTH_CLASS);
        }
        if (!this.parent.isMinMaxDate(data.date)) {
            data.className.push(DISABLE_DATES);
        }
        this.updateSelectedCellClass(data);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Month.prototype.updateSelectedCellClass = function (data) {
        return;
    };
    Month.prototype.isOtherMonth = function (date) {
        return date.getTime() < this.monthDates.start.getTime() || date.getTime() > this.monthDates.end.getTime();
    };
    Month.prototype.renderContentArea = function () {
        var tbl = this.createTableLayout(CONTENT_TABLE_CLASS);
        this.setAriaAttributes(tbl);
        this.addAutoHeightClass(tbl);
        if (this.parent.currentView === 'TimelineMonth') {
            this.createColGroup(tbl, this.colLevels[this.colLevels.length - 1]);
        }
        var monthDate = new Date(this.parent.selectedDate.getTime());
        this.monthDates = {
            start: this.parent.calendarUtil.firstDateOfMonth(monthDate),
            end: this.parent.calendarUtil.lastDateOfMonth(addMonths(monthDate, this.parent.activeViewOptions.interval - 1))
        };
        var tBody = tbl.querySelector('tbody');
        append(this.getContentRows(), tBody);
        this.wireCellEvents(tBody);
        return tbl;
    };
    Month.prototype.getContentRows = function () {
        var trows = [];
        var tr = createElement('tr');
        var td = createElement('td', { attrs: { 'aria-selected': 'false' } });
        var slotDatas = this.getContentSlots();
        for (var row = 0; row < slotDatas.length; row++) {
            var ntr = tr.cloneNode();
            for (var col = 0; col < slotDatas[parseInt(row.toString(), 10)].length; col++) {
                var ntd = this.createContentTd(slotDatas[parseInt(row.toString(), 10)][parseInt(col.toString(), 10)], td);
                ntr.appendChild(ntd);
            }
            trows.push(ntr);
        }
        return trows;
    };
    Month.prototype.createContentTd = function (data, td) {
        var ntd = td.cloneNode();
        if (data.colSpan) {
            ntd.setAttribute('colspan', data.colSpan.toString());
        }
        this.updateClassList(data);
        var type = data.type;
        if (data.className.indexOf(RESOURCE_PARENT_CLASS) !== -1) {
            data.className.push(RESOURCE_GROUP_CELLS_CLASS);
            type = 'resourceGroupCells';
        }
        if (this.parent.workHours.highlight && this.isWorkDay(data.date, data.workDays)) {
            data.className.push(WORKDAY_CLASS);
        }
        if (this.isCurrentDate(data.date)) {
            data.className.push(CURRENTDATE_CLASS);
        }
        addClass([ntd], data.className);
        ntd.setAttribute('data-date', data.date.getTime().toString());
        if (!isNullOrUndefined(data.groupIndex) || this.parent.uiStateValues.isGroupAdaptive) {
            var groupIndex = this.parent.uiStateValues.isGroupAdaptive ? this.parent.uiStateValues.groupIndex :
                data.groupIndex;
            ntd.setAttribute('data-group-index', '' + groupIndex);
        }
        this.renderDateHeaderElement(data, ntd);
        if (this.parent.activeViewOptions.cellTemplate) {
            var args_1 = { date: data.date, type: type, groupIndex: data.groupIndex };
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.cellTemplateName;
            var templateId = scheduleId + viewName + 'cellTemplate';
            var cellTemplate = [].slice.call(this.parent.getCellTemplate()(args_1, this.parent, 'cellTemplate', templateId, false));
            append(cellTemplate, ntd);
        }
        var args = { elementType: type, element: ntd, date: data.date, groupIndex: data.groupIndex };
        this.parent.trigger(renderCell, args);
        return ntd;
    };
    Month.prototype.renderDateHeaderElement = function (data, ntd) {
        if (this.parent.currentView === 'TimelineMonth') {
            return;
        }
        var dateHeader = createElement('div', { className: DATE_HEADER_CLASS });
        if (this.parent.activeViewOptions.cellHeaderTemplate) {
            var args = { date: data.date, type: data.type, groupIndex: data.groupIndex };
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.cellHeaderTemplateName;
            var templateId = scheduleId + viewName + 'cellHeaderTemplate';
            var cellHeaderTemplate = [].slice.call(this.parent.getCellHeaderTemplate()(args, this.parent, 'cellHeaderTemplate', templateId, false));
            append(cellHeaderTemplate, dateHeader);
        }
        else {
            var innerText = (this.parent.calendarUtil.isMonthStart(data.date) && !this.isCurrentDate(data.date) && !this.parent.isAdaptive) ?
                this.parent.globalize.formatDate(data.date, { format: 'MMM d', calendar: this.parent.getCalendarMode() }) :
                this.parent.globalize.formatDate(data.date, { skeleton: 'd', calendar: this.parent.getCalendarMode() });
            dateHeader.innerHTML = capitalizeFirstWord(innerText, 'single');
        }
        ntd.appendChild(dateHeader);
        if (this.getModuleName() === 'month') {
            addClass([dateHeader], NAVIGATE_CLASS);
            var skeleton = 'full';
            var announcementText = this.parent.globalize.formatDate(data.date, { skeleton: skeleton, calendar: this.parent.getCalendarMode() });
            ntd.setAttribute('aria-label', announcementText);
        }
    };
    Month.prototype.getMonthStart = function (currentDate) {
        var useDisplayDate = this.parent.currentView === 'Month' && !isNullOrUndefined(this.parent.activeViewOptions.displayDate) && (this.parent.uiStateValues.isCustomMonth || this.isCustomRange());
        var date = useDisplayDate ? this.parent.activeViewOptions.displayDate : !(this.parent.uiStateValues.isCustomMonth ||
            this.isCustomRange()) && this.isCustomMonth() ? currentDate : this.parent.calendarUtil.firstDateOfMonth(currentDate);
        var monthStart = getWeekFirstDate(date, this.parent.activeViewOptions.firstDayOfWeek);
        return new Date(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate());
    };
    Month.prototype.getMonthEnd = function (currentDate) {
        if (this.isCustomMonth()) {
            var start = this.getMonthStart(currentDate);
            var numberOfDays = WEEK_LENGTH * (this.parent.activeViewOptions.numberOfWeeks > 0 ?
                this.parent.activeViewOptions.numberOfWeeks : DEFAULT_WEEKS);
            return addDays(start, (numberOfDays - 1));
        }
        else {
            var endDate = addMonths(currentDate, this.parent.activeViewOptions.interval - 1);
            var lastWeekOfMonth = getWeekFirstDate(this.parent.calendarUtil.lastDateOfMonth(endDate), this.parent.activeViewOptions.firstDayOfWeek);
            return addDays(lastWeekOfMonth, WEEK_LENGTH - 1);
        }
    };
    Month.prototype.isCustomRange = function () {
        var dates = this.parent.getCurrentViewDates();
        if (dates && dates.length > 0) {
            var selectedTime = resetTime(this.parent.selectedDate).getTime();
            var weekFirstDate = getWeekFirstDate(dates[dates.length - 1], this.parent.activeViewOptions.firstDayOfWeek);
            return !(selectedTime >= getWeekFirstDate(dates[0], this.parent.activeViewOptions.firstDayOfWeek).getTime() &&
                selectedTime <= addDays(weekFirstDate, 6).getTime());
        }
        return false;
    };
    Month.prototype.getRenderDates = function (workDays) {
        var renderDates = [];
        var currentDate = resetTime(this.parent.selectedDate);
        var start = this.getMonthStart(currentDate);
        var monthEnd = this.getMonthEnd(currentDate);
        do {
            if (this.parent.activeViewOptions.showWeekend) {
                renderDates.push(start);
            }
            else {
                if (this.isWorkDay(start, workDays)) {
                    renderDates.push(start);
                }
            }
            start = addDays(start, 1);
            if (start.getHours() > 0) {
                start = resetTime(start);
            }
        } while (start.getTime() <= monthEnd.getTime());
        if (!workDays) {
            this.renderDates = renderDates;
        }
        if (this.parent.headerModule) {
            this.parent.headerModule.previousNextIconHandler();
        }
        return renderDates;
    };
    Month.prototype.getNextPreviousDate = function (type) {
        if (this.isCustomMonth()) {
            var dates = this.parent.getCurrentViewDates();
            var date = getWeekFirstDate(type === 'next' ? dates[dates.length - 1]
                : dates[0], this.parent.activeViewOptions.firstDayOfWeek);
            return addDays(date, type === 'next' ? WEEK_LENGTH : -(this.parent.activeViewOptions.numberOfWeeks > 0 ?
                this.parent.activeViewOptions.numberOfWeeks : DEFAULT_WEEKS) * WEEK_LENGTH);
        }
        else {
            return addMonths(this.parent.selectedDate, ((type === 'next' ? 1 : -1) * this.parent.activeViewOptions.interval));
        }
    };
    Month.prototype.getStartDate = function () {
        return this.getMonthStart(this.parent.selectedDate);
    };
    Month.prototype.getEndDate = function () {
        return this.getMonthEnd(this.parent.selectedDate);
    };
    Month.prototype.getEndDateFromStartDate = function (start) {
        return addDays(new Date(start.getTime()), 1);
    };
    Month.prototype.getDateRangeText = function () {
        if (this.parent.isAdaptive || isNullOrUndefined(this.parent.activeViewOptions.dateFormat)) {
            var startDate = this.parent.selectedDate;
            var endDate = void 0;
            var updateCustomRange = false;
            if (this.isCustomMonth()) {
                var dates = this.parent.getCurrentViewDates();
                updateCustomRange = dates[0].getMonth() !== dates[dates.length - 1].getMonth() ||
                    dates[0].getFullYear() !== dates[dates.length - 1].getFullYear();
                if (updateCustomRange) {
                    startDate = dates[0];
                    endDate = dates[dates.length - 1];
                }
            }
            var isUpdateDateRange = (this.parent.currentView !== 'Month' || !this.isCustomMonth());
            if (this.parent.activeViewOptions.interval > 1 && isUpdateDateRange || updateCustomRange) {
                endDate = endDate ? endDate : addMonths(lastDateOfMonth(startDate), this.parent.activeViewOptions.interval - 1);
                if (startDate.getFullYear() === endDate.getFullYear()) {
                    var monthNames = (this.parent.globalize.formatDate(startDate, { format: 'MMMM', calendar: this.parent.getCalendarMode() })) + ' - ' +
                        (this.parent.globalize.formatDate(endDate, { format: 'MMMM ', calendar: this.parent.getCalendarMode() })) +
                        this.parent.globalize.formatDate(endDate, { skeleton: 'y', calendar: this.parent.getCalendarMode() });
                    return capitalizeFirstWord(monthNames, 'single');
                }
                var text = (this.parent.globalize.formatDate(startDate, { format: 'MMMM', calendar: this.parent.getCalendarMode() })) + ' ' +
                    startDate.getFullYear() + ' - ' +
                    this.parent.globalize.formatDate(endDate, { format: 'MMMM ', calendar: this.parent.getCalendarMode() }) +
                    this.parent.globalize.formatDate(endDate, { skeleton: 'y', calendar: this.parent.getCalendarMode() });
                return capitalizeFirstWord(text, 'single');
            }
            var format = (this.parent.activeViewOptions.dateFormat) ? this.parent.activeViewOptions.dateFormat : 'MMMM y';
            return capitalizeFirstWord(this.parent.globalize.formatDate(startDate, { format: format, calendar: this.parent.getCalendarMode() }), 'single');
        }
        return this.formatDateRange(this.parent.selectedDate);
    };
    Month.prototype.getLabelText = function (view) {
        return this.parent.localeObj.getConstant(view) + ' of ' + capitalizeFirstWord(this.parent.globalize.formatDate(this.parent.selectedDate, { format: 'MMMM y', calendar: this.parent.getCalendarMode() }), 'single');
    };
    Month.prototype.createWeekNumberElement = function (text) {
        var tr = createElement('tr');
        var td = createElement('td', {
            className: WEEK_NUMBER_CLASS,
            attrs: { 'title': (text ? this.parent.localeObj.getConstant('week') + ' ' + text : '') },
            innerHTML: (text || '')
        });
        tr.appendChild(td);
        var args = { elementType: 'weekNumberCell', element: td };
        this.parent.trigger(renderCell, args);
        return tr;
    };
    Month.prototype.unWireEvents = function () {
        var contentScrollableEle = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (contentScrollableEle) {
            EventHandler.remove(contentScrollableEle, 'scroll', this.onContentScroll);
        }
    };
    Month.prototype.isCustomMonth = function () {
        return this.parent.currentView === 'Month' &&
            (!isNullOrUndefined(this.parent.activeViewOptions.displayDate) || this.parent.activeViewOptions.numberOfWeeks > 0);
    };
    Month.prototype.getModuleName = function () {
        return 'month';
    };
    Month.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        if (this.element) {
            if (this.monthEvent) {
                this.monthEvent.destroy();
                this.monthEvent = null;
            }
            this.unWireEvents();
            if (this.parent.resourceBase) {
                this.parent.resourceBase.destroy();
            }
            if (this.parent.scheduleTouchModule) {
                this.parent.scheduleTouchModule.resetValues();
            }
            _super.prototype.destroy.call(this);
        }
    };
    return Month;
}(ViewBase));

var __extends$22 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * year view
 */
var Year = /** @__PURE__ @class */ (function (_super) {
    __extends$22(Year, _super);
    function Year(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-year-view';
        _this.isInverseTableSelect = false;
        _this.yearEventModule = null;
        return _this;
    }
    Year.prototype.getModuleName = function () {
        return 'year';
    };
    Year.prototype.renderLayout = function (className) {
        if (this.parent.resourceBase) {
            this.parent.resourceBase.generateResourceLevels([{ renderDates: this.parent.activeView.renderDates }]);
        }
        this.setPanel(createElement('div', { className: TABLE_WRAP_CLASS }));
        var viewTypeClass = this.parent.activeViewOptions.orientation === 'Horizontal' ? 'e-horizontal' : 'e-vertical';
        addClass([this.element], [this.viewClass, viewTypeClass, className]);
        this.renderPanel(className);
        if (this.parent.activeViewOptions.allowVirtualScrolling) {
            addClass([this.element], [VIRTUAL_SCROLL_CLASS]);
        }
        var calendarTable = this.createTableLayout(OUTER_TABLE_CLASS);
        this.element.appendChild(calendarTable);
        this.element.querySelector('table').setAttribute('role', 'presentation');
        var calendarTBody = calendarTable.querySelector('tbody');
        this.rowCount = this.getRowColumnCount('row');
        this.columnCount = this.getRowColumnCount('column');
        this.renderHeader(calendarTBody);
        this.renderContent(calendarTBody);
        if (this.parent.currentView !== 'Year' && this.parent.uiStateValues.isGroupAdaptive) {
            this.generateColumnLevels();
            this.renderResourceMobileLayout();
        }
        EventHandler.add(this.element.querySelector('.' + CONTENT_WRAP_CLASS), 'scroll', this.onContentScroll, this);
        this.parent.notify(contentReady, {});
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Year.prototype.renderHeader = function (headerWrapper) { };
    Year.prototype.renderContent = function (content) {
        var tr = createElement('tr');
        content.appendChild(tr);
        var td = createElement('td');
        tr.appendChild(td);
        this.element.querySelector('tbody').appendChild(tr);
        var contentWrapper = createElement('div', { className: CONTENT_WRAP_CLASS });
        td.appendChild(contentWrapper);
        var calendarTable = this.createTableLayout('e-calendar-table');
        contentWrapper.appendChild(calendarTable);
        var cTr = createElement('tr');
        calendarTable.querySelector('tbody').appendChild(cTr);
        var cTd = createElement('td');
        cTr.appendChild(cTd);
        var calendarWrapper = createElement('div', { className: 'e-calendar-wrapper' });
        cTd.appendChild(calendarWrapper);
        var months = this.getMonths();
        for (var _i = 0, months_1 = months; _i < months_1.length; _i++) {
            var month = months_1[_i];
            var currentMonth = new Date(this.parent.selectedDate.getFullYear(), month, 1);
            var calendarElement = createElement('div', {
                className: 'e-month-calendar e-calendar',
                attrs: { 'data-role': 'calendar' }
            });
            calendarElement.appendChild(this.renderCalendarHeader(currentMonth));
            calendarElement.appendChild(this.renderCalendarContent(currentMonth));
            calendarWrapper.appendChild(calendarElement);
        }
        this.renderDates.splice(0, 1);
    };
    Year.prototype.renderCalendarHeader = function (currentDate) {
        var headerWrapper = createElement('div', { className: 'e-header e-month' });
        var headerContent = createElement('div', { className: 'e-day e-title' });
        if (this.parent.activeViewOptions.monthHeaderTemplate) {
            var args = { date: currentDate, type: 'monthHeader' };
            this.renderTemplates(this.parent.getMonthHeaderTemplate(), args, 'monthHeaderTemplate', this.parent.activeViewOptions.monthHeaderTemplateName, headerContent);
        }
        else {
            headerContent.innerHTML = this.getMonthName(currentDate);
        }
        headerWrapper.appendChild(headerContent);
        this.parent.trigger(renderCell, { elementType: 'headerCells', element: headerContent, date: currentDate });
        return headerWrapper;
    };
    Year.prototype.renderCalendarContent = function (currentDate) {
        var dateCollection = this.getMonthDates(currentDate);
        var contentWrapper = createElement('div', { className: 'e-content e-month' });
        var contentTable = this.createTableLayout('e-calendar-table ' + CONTENT_TABLE_CLASS);
        contentWrapper.appendChild(contentTable);
        this.setAriaAttributes(contentTable);
        var thead = createElement('thead', { className: 'e-week-header' });
        var tr = createElement('tr');
        var currentWeek = getWeekFirstDate(firstDateOfMonth(currentDate), this.parent.firstDayOfWeek);
        if (this.parent.activeViewOptions.showWeekNumber) {
            tr.appendChild(createElement('th'));
        }
        for (var i = 0; i < WEEK_LENGTH; i++) {
            if (this.parent.activeViewOptions.dayHeaderTemplate) {
                var th = createElement('th');
                var args = { date: currentWeek, type: 'dayHeader' };
                this.renderTemplates(this.parent.getDayHeaderTemplate(), args, 'dayHeaderTemplate', this.parent.activeViewOptions.dayHeaderTemplateName, th);
                tr.appendChild(th);
            }
            else {
                tr.appendChild(createElement('th', { innerHTML: this.parent.getDayNames('narrow')[currentWeek.getDay()] }));
            }
            var nextDay = new Date(currentWeek.getTime() + MS_PER_DAY);
            currentWeek = nextDay.getDate() === currentWeek.getDate() ? addDays(nextDay, 1) : nextDay;
        }
        thead.appendChild(tr);
        prepend([thead], contentTable);
        var tbody = contentTable.querySelector('tbody');
        while (dateCollection.length > 0) {
            var weekDates = dateCollection.splice(0, WEEK_LENGTH);
            var tr_1 = createElement('tr');
            if (this.parent.activeViewOptions.showWeekNumber) {
                var weekNumber = this.parent.getWeekNumberContent(weekDates);
                var td = createElement('td', {
                    className: 'e-week-number',
                    attrs: { 'title': this.parent.localeObj.getConstant('week') + ' ' + weekNumber },
                    innerHTML: weekNumber
                });
                tr_1.appendChild(td);
                this.parent.trigger(renderCell, { elementType: 'weekNumberCells', element: td });
            }
            for (var _i = 0, weekDates_1 = weekDates; _i < weekDates_1.length; _i++) {
                var date = weekDates_1[_i];
                var td = createElement('td', {
                    className: 'e-cell ' + WORK_CELLS_CLASS,
                    attrs: { 'data-date': date.getTime().toString() }
                });
                if (this.parent.activeViewOptions.cellHeaderTemplate) {
                    var args = { date: date, type: 'monthCells' };
                    this.renderTemplates(this.parent.getCellHeaderTemplate(), args, 'cellHeaderTemplate', this.parent.activeViewOptions.cellHeaderTemplateName, td);
                }
                else {
                    var span = createElement('span', {
                        className: 'e-day', innerHTML: this.parent.globalize.formatDate(date, { skeleton: 'd', calendar: this.parent.getCalendarMode() }),
                        attrs: { title: this.parent.globalize.formatDate(date, { type: 'date', skeleton: 'full' }) }
                    });
                    td.appendChild(span);
                }
                if (this.parent.activeViewOptions.cellTemplate) {
                    var args = { date: date, type: 'monthCells' };
                    this.renderTemplates(this.parent.getCellTemplate(), args, 'cellTemplate', this.parent.activeViewOptions.cellTemplateName, td);
                }
                var classList$$1 = [];
                if (currentDate.getMonth() !== date.getMonth()) {
                    classList$$1.push(OTHERMONTH_CLASS);
                }
                if (this.isCurrentDate(date) && currentDate.getMonth() === date.getMonth()) {
                    classList$$1 = classList$$1.concat(['e-today', 'e-selected']);
                }
                if (classList$$1.length > 0) {
                    addClass([td], classList$$1);
                }
                tr_1.appendChild(td);
                if (currentDate.getMonth() === date.getMonth()) {
                    this.renderDates.push(new Date(date));
                }
                if (!this.parent.isMinMaxDate(date)) {
                    addClass([td], DISABLE_DATES);
                }
                else {
                    EventHandler.add(td, 'click', this.onCellClick, this);
                    if (!this.parent.isAdaptive) {
                        EventHandler.add(td, 'dblclick', this.parent.workCellAction.cellDblClick, this.parent.workCellAction);
                    }
                }
                this.parent.trigger(renderCell, { elementType: 'workCells', element: td, date: date });
            }
            tbody.appendChild(tr_1);
        }
        return contentWrapper;
    };
    Year.prototype.createTableColGroup = function (count) {
        var colGroupEle = createElement('colgroup');
        for (var i = 0; i < count; i++) {
            colGroupEle.appendChild(createElement('col'));
        }
        return colGroupEle;
    };
    Year.prototype.getMonthName = function (date) {
        var month = this.parent.globalize.formatDate(date, {
            format: this.parent.activeViewOptions.dateFormat || 'MMMM y',
            calendar: this.parent.getCalendarMode()
        });
        return capitalizeFirstWord(month, 'multiple');
    };
    Year.prototype.generateColumnLevels = function () {
        var colLevels = [];
        var level = this.getDateSlots([this.parent.selectedDate], this.parent.activeViewOptions.workDays);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            colLevels = this.parent.resourceBase.generateResourceLevels(level);
            if (this.parent.uiStateValues.isGroupAdaptive) {
                var resourceLevel = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
                colLevels = [this.getDateSlots([this.parent.selectedDate], resourceLevel.workDays)];
            }
        }
        else {
            colLevels.push(level);
        }
        colLevels.pop();
        this.colLevels = colLevels;
        return colLevels;
    };
    // eslint-disable-next-line max-len
    Year.prototype.getDateSlots = function (renderDates, workDays, startHour, endHour) {
        if (startHour === void 0) { startHour = this.parent.workHours.start; }
        if (endHour === void 0) { endHour = this.parent.workHours.end; }
        var dateCol = [{
                date: renderDates[0], type: 'dateHeader', className: [HEADER_CELLS_CLASS], colSpan: 1, workDays: workDays,
                startHour: new Date(+this.parent.globalize.parseDate(startHour, { skeleton: 'Hm' })),
                endHour: new Date(+this.parent.globalize.parseDate(endHour, { skeleton: 'Hm' }))
            }];
        return dateCol;
    };
    Year.prototype.getMonthDates = function (date) {
        var startDate = getWeekFirstDate(firstDateOfMonth(date), this.parent.firstDayOfWeek);
        var endDate = addDays(new Date(+startDate), (6 * WEEK_LENGTH));
        var dateCollection = [];
        for (var start = startDate; start.getTime() < endDate.getTime(); start = addDays(start, 1)) {
            dateCollection.push(resetTime(new Date(start)));
        }
        return dateCollection;
    };
    Year.prototype.getRowColumnCount = function (type) {
        var months = this.getMonths();
        var year = this.parent.selectedDate.getFullYear();
        var monthDaysCount = [];
        for (var _i = 0, months_2 = months; _i < months_2.length; _i++) {
            var month = months_2[_i];
            monthDaysCount.push(new Date(year, month, 1).getDay() + new Date(year, month + 1, 0).getDate());
        }
        var maxCount = Math.max.apply(Math, monthDaysCount);
        var count;
        if (type === 'row') {
            count = this.parent.activeViewOptions.orientation === 'Horizontal' ? months.length : maxCount;
        }
        else {
            count = this.parent.activeViewOptions.orientation === 'Horizontal' ? maxCount : months.length;
        }
        return count;
    };
    Year.prototype.isCurrentDate = function (date) {
        return resetTime(new Date()).getTime() === resetTime(new Date(date.getTime())).getTime();
    };
    Year.prototype.getMonths = function () {
        var _this = this;
        // eslint-disable-next-line prefer-spread
        return Array.apply(null, { length: this.parent.activeViewOptions.monthsCount }).map(function (value, index) {
            return _this.parent.firstMonthOfYear + index;
        });
    };
    Year.prototype.renderTemplates = function (fn, args, tName, vName, ele) {
        var templateId = this.parent.element.id + '_' + vName + tName;
        var template = [].slice.call(fn(args, this.parent, tName, templateId, false));
        append(template, ele);
    };
    Year.prototype.onCellClick = function (e) {
        var target = closest(e.target, '.' + WORK_CELLS_CLASS);
        var startDate = this.parent.getDateFromElement(target);
        this.parent.activeCellsData = this.parent.getCellDetails(target);
        var isPrevious = startDate.getTime() < this.getStartDate().getTime();
        if (isPrevious || startDate.getTime() > this.getEndDate().getTime()) {
            this.parent.changeDate(this.parent.activeView.getNextPreviousDate(isPrevious ? 'previous' : 'next'), e);
            var activeDate = this.parent.activeCellsData.startTime.getTime();
            var inRange = activeDate >= this.getStartDate().getTime() && activeDate <= this.getEndDate().getTime();
            var dateAttr = inRange ? activeDate : (isPrevious ? this.getEndDate() : this.getStartDate()).getTime();
            var selectedCell = this.parent.element.querySelector(':not(.' + OTHERMONTH_CLASS + ')[data-date="' + dateAttr + '"]');
            this.parent.selectCell(selectedCell);
            this.parent.activeCellsData = this.parent.getCellDetails(selectedCell);
        }
        else {
            var endDate = addDays(new Date(startDate.getTime()), 1);
            var filteredEvents = this.parent.eventBase.filterEvents(startDate, endDate);
            var moreEventArgs = { date: startDate, event: filteredEvents, element: e.target };
            if (target.classList.contains(OTHERMONTH_CLASS)) {
                target = this.parent.element.querySelector(':not(.' + OTHERMONTH_CLASS + ')[data-date="' + target.getAttribute('data-date') + '"]');
            }
            this.parent.activeCellsData = this.parent.getCellDetails(target);
            var args = extend(this.parent.activeCellsData, { cancel: false, event: e, name: 'cellClick' });
            this.parent.trigger(cellClick, args);
            this.parent.quickPopup.moreEventClick(moreEventArgs, endDate);
        }
    };
    Year.prototype.onContentScroll = function (e) {
        var target = e.target;
        var headerWrapper = this.getDatesHeaderElement();
        this.parent.notify(virtualScroll, e);
        if (headerWrapper) {
            headerWrapper.firstElementChild.scrollLeft = target.scrollLeft;
        }
        var scrollTopSelector = "." + MONTH_HEADER_WRAPPER + ",." + RESOURCE_COLUMN_WRAP_CLASS;
        var scrollTopElement = this.element.querySelector(scrollTopSelector);
        if (scrollTopElement) {
            scrollTopElement.scrollTop = target.scrollTop;
        }
        if (!this.parent.isAdaptive) {
            this.parent.uiStateValues.top = e.target.scrollTop;
        }
        this.parent.uiStateValues.left = e.target.scrollLeft;
        this.setPersistence();
    };
    Year.prototype.onScrollUiUpdate = function (args) {
        var height = this.parent.element.offsetHeight - this.getHeaderBarHeight();
        var headerWrapper = this.element.querySelector('.' + DATE_HEADER_CONTAINER_CLASS);
        if (headerWrapper) {
            height -= headerWrapper.offsetHeight;
        }
        var contentWrapper = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (contentWrapper) {
            contentWrapper.style.height = formatUnit(height);
        }
        if (!this.parent.isAdaptive && headerWrapper) {
            var scrollBarWidth = getScrollBarWidth();
            if (contentWrapper.offsetWidth - contentWrapper.clientWidth > 0) {
                headerWrapper.firstElementChild.style[args.cssProperties.border] = scrollBarWidth > 0 ? '1px' : '0px';
                headerWrapper.style[args.cssProperties.padding] = scrollBarWidth > 0 ? scrollBarWidth - 1 + 'px' : '0px';
            }
            else {
                headerWrapper.firstElementChild.style[args.cssProperties.border] = '';
                headerWrapper.style[args.cssProperties.padding] = '';
            }
        }
        this.setColWidth(this.getContentAreaElement());
        var leftPanelSelector = "." + MONTH_HEADER_WRAPPER + ",." + RESOURCE_COLUMN_WRAP_CLASS;
        var leftPanelElement = this.element.querySelector(leftPanelSelector);
        if (leftPanelElement) {
            var isYScroll = contentWrapper.scrollWidth > contentWrapper.clientWidth;
            leftPanelElement.style.height = formatUnit(height - (isYScroll ? 17 : 0));
        }
        if (!args.isPreventScrollUpdate) {
            if (this.parent.uiStateValues.isInitial) {
                this.parent.uiStateValues.isInitial = false;
                this.parent.uiStateValues.top = this.parent.uiStateValues.left = 0;
            }
            else {
                if (leftPanelElement) {
                    leftPanelElement.scrollTop = this.parent.uiStateValues.top;
                }
                contentWrapper.scrollTop = this.parent.uiStateValues.top;
                contentWrapper.scrollLeft = this.parent.uiStateValues.left;
            }
        }
        this.retainScrollPosition();
    };
    Year.prototype.getStartDate = function () {
        return new Date(this.parent.selectedDate.getFullYear(), this.parent.firstMonthOfYear % 12, 1);
    };
    Year.prototype.getEndDate = function () {
        return addDays(addMonths(this.getStartDate(), this.parent.monthsCount), -1);
    };
    Year.prototype.startDate = function () {
        return this.parent.currentView === 'Year' ? getWeekFirstDate(this.getStartDate(), this.parent.firstDayOfWeek) : this.getStartDate();
    };
    Year.prototype.endDate = function () {
        return this.parent.currentView === 'Year' ? addDays(getWeekLastDate(this.getEndDate(), this.parent.firstDayOfWeek), 1) :
            addDays(this.getEndDate(), 1);
    };
    Year.prototype.getEndDateFromStartDate = function (start) {
        var date = new Date(start.getTime());
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            date = lastDateOfMonth(date);
        }
        return addDays(new Date(date.getTime()), 1);
    };
    Year.prototype.getNextPreviousDate = function (type) {
        return addYears(this.parent.selectedDate, ((type === 'next') ? 1 : -1));
    };
    Year.prototype.getDateRangeText = function () {
        var startDate = this.getStartDate();
        var endDate = this.getEndDate();
        if (startDate.getFullYear() !== endDate.getFullYear()) {
            return this.parent.globalize.formatDate(startDate, { skeleton: 'yMMM' }) + ' - ' + this.parent.globalize.formatDate(endDate, { skeleton: 'yMMM' });
        }
        else {
            return this.parent.globalize.formatDate(this.parent.selectedDate, { skeleton: 'y' });
        }
    };
    Year.prototype.addEventListener = function () {
        this.parent.on(scrollUiUpdate, this.onScrollUiUpdate, this);
        this.parent.on(dataReady, this.onDataReady, this);
    };
    Year.prototype.removeEventListener = function () {
        if (this.parent) {
            this.parent.off(scrollUiUpdate, this.onScrollUiUpdate);
            this.parent.off(dataReady, this.onDataReady);
        }
    };
    Year.prototype.onDataReady = function (args) {
        this.yearEventModule = new YearEvent(this.parent);
        this.yearEventModule.renderAppointments();
        this.parent.notify(eventsLoaded, args);
    };
    Year.prototype.scrollToDate = function (scrollDate) {
        var date = +new Date(resetTime(scrollDate));
        var element = this.element.querySelector('.' + WORK_CELLS_CLASS + ':not(.' + OTHERMONTH_CLASS + ')[data-date="' + date + '"]');
        if (element) {
            element = closest(element, '.e-month-calendar');
            this.getContentAreaElement().scrollTop = element.offsetTop;
        }
    };
    Year.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        if (this.element) {
            var contentScroll = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
            if (contentScroll) {
                EventHandler.remove(contentScroll, 'scroll', this.onContentScroll);
            }
            if (this.yearEventModule) {
                this.yearEventModule.destroy();
                this.yearEventModule = null;
            }
            if (this.parent.resourceBase) {
                this.parent.resourceBase.destroy();
            }
            _super.prototype.destroy.call(this);
        }
    };
    return Year;
}(ViewBase));

var __extends$24 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
var AgendaBase = /** @__PURE__ @class */ (function (_super) {
    __extends$24(AgendaBase, _super);
    function AgendaBase(parent) {
        return _super.call(this, parent) || this;
    }
    // eslint-disable-next-line max-len
    AgendaBase.prototype.createAgendaContentElement = function (type, listData, aTd, groupOrder, groupIndex) {
        var _this = this;
        var listElement;
        var fieldMapping = this.parent.eventFields;
        if (type === 'noEvents') {
            var noEvents = [{ 'subject': this.parent.localeObj.getConstant('noEvents') }];
            listElement = ListBase.createList(this.parent.createElement, noEvents, {
                moduleName: 'agenda',
                listClass: this.parent.activeView.viewClass,
                itemClass: this.parent.activeView.viewClass,
                template: "<div class=\"" + AGENDA_NO_EVENT_CLASS + "\">" + this.parent.localeObj.getConstant('noEvents') + "</div>"
            });
            if (listElement.querySelector('.e-agenda-item').children.length === 0) {
                listElement.firstElementChild.appendChild(createElement('div', { className: AGENDA_NO_EVENT_CLASS, innerHTML: this.parent.localeObj.getConstant('noEvents') }));
            }
        }
        else {
            listElement = ListBase.createList(this.parent.createElement, listData, {
                moduleName: 'agenda',
                listClass: this.parent.activeView.viewClass,
                itemClass: this.parent.activeView.viewClass
            });
            var listElements = [].slice.call(listElement.children);
            listElements.forEach(function (element, li) {
                var appWrapper = createElement('div', {
                    className: APPOINTMENT_CLASS, attrs: {
                        'data-id': 'Appointment_' + listData[parseInt(li.toString(), 10)]["" + _this.parent.eventFields.id],
                        'data-guid': listData[parseInt(li.toString(), 10)].Guid,
                        'role': 'button',
                        'tabindex': '0',
                        'aria-disabled': _this.parent.eventBase.getReadonlyAttribute(listData[parseInt(li.toString(), 10)]),
                        'aria-label': _this.parent.getAnnouncementString(listData[parseInt(li.toString(), 10)])
                    }
                });
                if (!isNullOrUndefined(groupIndex)) {
                    appWrapper.setAttribute('data-group-index', groupIndex.toString());
                }
                _this.parent.eventBase.applyResourceColor(appWrapper, listData[parseInt(li.toString(), 10)], 'borderColor', groupOrder);
                var templateEle;
                if (!isNullOrUndefined(_this.parent.activeViewOptions.eventTemplate)) {
                    addClass([appWrapper], EVENT_TEMPLATE);
                    var scheduleId = _this.parent.element.id + '_';
                    var viewName = _this.parent.activeViewOptions.eventTemplateName;
                    var templateId = scheduleId + viewName + 'eventTemplate';
                    templateEle = _this.parent.getAppointmentTemplate()(listData[parseInt(li.toString(), 10)], _this.parent, 'eventTemplate', templateId, false);
                    if (!isNullOrUndefined(listData[parseInt(li.toString(), 10)][fieldMapping.recurrenceRule])) {
                        var iconClass = (listData[parseInt(li.toString(), 10)][fieldMapping.id] ===
                            listData[parseInt(li.toString(), 10)][fieldMapping.recurrenceID]) ?
                            EVENT_RECURRENCE_ICON_CLASS : EVENT_RECURRENCE_EDIT_ICON_CLASS;
                        appWrapper.appendChild(createElement('div', { className: ICON + ' ' + iconClass }));
                    }
                }
                else {
                    templateEle = _this.createAppointment(listData[parseInt(li.toString(), 10)]);
                }
                append([].slice.call(templateEle), appWrapper);
                removeChildren(element);
                element.appendChild(appWrapper);
                var args = {
                    data: extend({}, listData[parseInt(li.toString(), 10)], null, true),
                    element: element, cancel: false
                };
                _this.parent.trigger(eventRendered, args, function (eventArgs) {
                    if (eventArgs.cancel) {
                        remove(element);
                    }
                });
            });
        }
        aTd.appendChild(listElement);
        if ((this.parent.currentView === 'MonthAgenda' && this.parent.activeViewOptions.group.resources.length > 0)
            || this.parent.currentView === 'Agenda') {
            addClass([aTd], AGENDA_DAY_BORDER_CLASS);
        }
        this.parent.renderTemplates();
        return aTd;
    };
    AgendaBase.prototype.createAppointment = function (event) {
        var fieldMapping = this.parent.eventFields;
        var eventSubject = (event[fieldMapping.subject] || this.parent.eventSettings.fields.subject.default
            || this.parent.localeObj.getConstant('addTitle'));
        var eventLocation = (event[fieldMapping.location] || this.parent.eventSettings.fields.location.default);
        var appSubjectWrap = createElement('div', { className: SUBJECT_WRAP });
        if (!isNullOrUndefined(eventLocation) && eventLocation !== '') {
            eventSubject += ',';
        }
        var appSubjectText = createElement('div', { className: SUBJECT_CLASS });
        this.parent.sanitize(eventSubject, appSubjectText);
        appSubjectWrap.appendChild(appSubjectText);
        if (!isNullOrUndefined(eventLocation) && eventLocation !== '') {
            var appLocation = createElement('div', { className: LOCATION_CLASS });
            this.parent.sanitize(eventLocation, appLocation);
            appSubjectWrap.appendChild(appLocation);
        }
        if (!isNullOrUndefined(event[fieldMapping.recurrenceRule])) {
            var iconClass = (event[fieldMapping.id] === event[fieldMapping.recurrenceID]) ?
                EVENT_RECURRENCE_ICON_CLASS : EVENT_RECURRENCE_EDIT_ICON_CLASS;
            appSubjectWrap.appendChild(createElement('div', { className: ICON + ' ' + iconClass }));
        }
        var strDate = event[fieldMapping.startTime];
        var endDate = event[fieldMapping.endTime];
        var isAllDay = event[fieldMapping.isAllDay];
        var allDayStr = this.parent.localeObj.getConstant('allDay');
        var timeStr = this.parent.getTimeString(strDate) + ' - ' + this.parent.getTimeString(endDate);
        if (!isNullOrUndefined(event.data)) {
            var milliSeconds = (endDate.getTimezoneOffset() !== strDate.getTimezoneOffset()) ?
                (endDate.getTime() - strDate.getTime() + 3600000) : (endDate.getTime() - strDate.getTime());
            var eventString = (milliSeconds / MS_PER_DAY) >= 1 ? allDayStr : timeStr;
            allDayStr = eventString + ' (' + this.parent.localeObj.getConstant('day') + ' '
                + event.data.index + '/' + event.data.count + ')';
        }
        var displayStr = (!isNullOrUndefined(event.data) || isAllDay) ? allDayStr : timeStr;
        var appDateTime = createElement('div', { className: DATE_TIME_CLASS, innerHTML: displayStr });
        return [appSubjectWrap, appDateTime];
    };
    AgendaBase.prototype.processAgendaEvents = function (events) {
        var eventsProcessed = [];
        if (isNullOrUndefined(events)) {
            return eventsProcessed;
        }
        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
            var event_1 = events_1[_i];
            var spanned = this.parent.eventBase.splitEventByDay(event_1);
            eventsProcessed = eventsProcessed.concat(spanned.length > 1 ? spanned : event_1);
        }
        return eventsProcessed;
    };
    AgendaBase.prototype.wireEventActions = function () {
        var eventElement = [].slice.call(this.parent.element.querySelectorAll('.' + APPOINTMENT_CLASS));
        for (var _i = 0, eventElement_1 = eventElement; _i < eventElement_1.length; _i++) {
            var element = eventElement_1[_i];
            this.parent.eventBase.wireAppointmentEvents(element, this.parent.getEventDetails(element), true);
        }
        var dateHeaderElement = [].slice.call(this.parent.element.querySelectorAll('.e-m-date'));
        for (var _a = 0, dateHeaderElement_1 = dateHeaderElement; _a < dateHeaderElement_1.length; _a++) {
            var element = dateHeaderElement_1[_a];
            EventHandler.add(element, 'click', this.parent.agendaModule.dayNavigationClick, this);
        }
    };
    AgendaBase.prototype.calculateResourceTableElement = function (tBody, noOfDays, agendaDate) {
        if (isNullOrUndefined(this.parent.resourceBase.lastResourceLevel)) {
            var level = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
            this.parent.resourceBase.generateResourceLevels(level);
        }
        var agendaLastDate = addDays(new Date(agendaDate.getTime()), noOfDays);
        var days = (this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda') ? noOfDays : 1;
        var resColl = this.parent.resourceBase.resourceCollection;
        var resData = this.parent.resourceBase.lastResourceLevel;
        var initialDate = agendaDate;
        for (var i = 0; i < days; i++) {
            var lastLevelInfo = [];
            var tempLastLevelInfo = [];
            var tempIndex = 0;
            var eventObj = void 0;
            var dateObj = void 0;
            var firstDate = addDays(initialDate, i);
            var finalDate = (this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda')
                ? addDays(firstDate, 1) : agendaLastDate;
            var agendaCollection = this.parent.eventBase.filterEvents(firstDate, finalDate);
            if (agendaCollection.length > 0 || !this.parent.hideEmptyAgendaDays || this.parent.currentView === 'MonthAgenda') {
                for (var res = 0; res < resData.length; res++) {
                    noOfDays = (!this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda') ? noOfDays : 1;
                    var data = [];
                    agendaDate = firstDate;
                    // eslint-disable-next-line max-len
                    var resDataCollection = this.parent.eventBase.filterEvents(agendaDate, agendaLastDate, agendaCollection, resData[parseInt(res.toString(), 10)]);
                    if (resDataCollection.length > 0 || !this.parent.hideEmptyAgendaDays || this.parent.currentView === 'MonthAgenda') {
                        for (var r = 0; r < noOfDays; r++) {
                            // eslint-disable-next-line max-len
                            var resDayCollection = this.parent.eventBase.filterEvents(agendaDate, addDays(agendaDate, 1), resDataCollection, undefined);
                            if (resDayCollection.length > 0 || !this.parent.hideEmptyAgendaDays ||
                                this.parent.currentView === 'MonthAgenda') {
                                data.push(resDayCollection[0]);
                                eventObj = {
                                    rowSpan: 1, type: 'eventColumn', resource: resColl[resColl.length - 1],
                                    groupIndex: resData[parseInt(res.toString(), 10)].groupIndex,
                                    groupOrder: resData[parseInt(res.toString(), 10)].groupOrder,
                                    resourceData: resData[parseInt(res.toString(), 10)].resourceData,
                                    eventData: resDayCollection, date: agendaDate
                                };
                                dateObj = {
                                    rowSpan: 1, type: 'dateColumn', resource: resColl[parseInt((resColl.length - 1).toString(), 10)],
                                    groupOrder: resData[parseInt(res.toString(), 10)].groupOrder,
                                    resourceData: resData[parseInt(res.toString(), 10)].resourceData,
                                    date: agendaDate
                                };
                                if (!lastLevelInfo[parseInt(tempIndex.toString(), 10)]) {
                                    lastLevelInfo[parseInt(tempIndex.toString(), 10)] = [];
                                }
                                lastLevelInfo[parseInt(tempIndex.toString(), 10)].push(eventObj);
                                lastLevelInfo[parseInt(tempIndex.toString(), 10)].push(dateObj);
                                tempIndex++;
                            }
                            agendaDate = addDays(agendaDate, 1);
                            if (agendaDate.getTime() >= agendaLastDate.getTime() || this.parent.activeViewOptions.group.byDate
                                || this.parent.currentView === 'MonthAgenda') {
                                lastLevelInfo[lastLevelInfo.length - 1][1].cssClass = AGENDA_DAY_BORDER_CLASS;
                                var tempObj = {
                                    rowSpan: data.length, type: 'resourceColumn', resource: resColl[parseInt((resColl.length - 1).toString(), 10)],
                                    groupOrder: resData[parseInt(res.toString(), 10)].groupOrder.slice(0, -1),
                                    resourceData: resData[parseInt(res.toString(), 10)].resourceData,
                                    groupIndex: (lastLevelInfo.length - data.length), className: [RESOURCE_NAME],
                                    date: agendaDate
                                };
                                lastLevelInfo[parseInt((lastLevelInfo.length - data.length).toString(), 10)].push(tempObj);
                                tempLastLevelInfo.push(extend({}, tempObj, null, true));
                                break;
                            }
                        }
                    }
                }
                var topResources = resColl.slice(0, -1);
                var tempGroupedData = [];
                var totalRowSpan = 0;
                for (var y = 0; y < topResources.length; y++) {
                    var data = topResources[topResources.length - (y + 1)].dataSource;
                    for (var x = 0; x < data.length; x++) {
                        var z = 0;
                        for (var u = 0; u < tempLastLevelInfo.length; u++) {
                            // eslint-disable-next-line max-len
                            if (tempLastLevelInfo[parseInt(u.toString(), 10)].groupOrder[topResources.length - (y + 1)] === data[parseInt(x.toString(), 10)][topResources[topResources.length - (y + 1)].idField]) {
                                totalRowSpan = totalRowSpan + tempLastLevelInfo[parseInt(u.toString(), 10)].rowSpan;
                                tempGroupedData.push(extend({}, tempLastLevelInfo[parseInt(u.toString(), 10)], null, true));
                            }
                            if (++z === tempLastLevelInfo.length && tempGroupedData.length > 0) {
                                tempGroupedData[0].rowSpan = totalRowSpan;
                                tempGroupedData[0].type = 'parentColumnLevel_' + (y + 1);
                                tempGroupedData[0].resource = topResources[topResources.length - (y + 1)];
                                tempGroupedData[0].resourceData = data[parseInt(x.toString(), 10)];
                                tempGroupedData[0].date = agendaDate;
                                lastLevelInfo[tempGroupedData[0].groupIndex].push(tempGroupedData[0]);
                                tempGroupedData = [];
                                totalRowSpan = 0;
                            }
                        }
                    }
                }
                this.createResourceTableRow(lastLevelInfo, tBody);
            }
        }
        var totalCollection = this.parent.eventBase.filterEvents(initialDate, agendaLastDate);
        if (totalCollection.length === 0 && !this.parent.activeViewOptions.allowVirtualScrolling && this.parent.hideEmptyAgendaDays) {
            this.renderEmptyContent(tBody, initialDate);
        }
    };
    AgendaBase.prototype.createResourceTableRow = function (tContent, tBody) {
        var tr = createElement('tr');
        var ntr;
        var td = createElement('td', { attrs: { 'aria-selected': 'false' } });
        var tempData;
        var rowSpan = 0;
        var level;
        if (this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda') {
            var tContentCollection = [];
            var parentCollection = this.parent.resourceBase.resourceCollection.slice(0, -1);
            for (var w = 0; w < tContent.length; w++) {
                tContentCollection = tContentCollection.concat(tContent[parseInt(w.toString(), 10)]);
            }
            level = (parentCollection.length > 0) ? 'parentColumnLevel_' + parentCollection.length : 'resourceColumn';
            var rowSpanCollection = tContentCollection.filter(function (data) { return data.type === level; });
            for (var x = 0; x < rowSpanCollection.length; x++) {
                rowSpan = rowSpan + rowSpanCollection[parseInt(x.toString(), 10)].rowSpan;
            }
        }
        for (var row = 0; row < tContent.length; row++) {
            ntr = tr.cloneNode();
            for (var col = tContent[parseInt(row.toString(), 10)].length - 1; col >= 0; col--) {
                var data = tContent[parseInt(row.toString(), 10)][parseInt(col.toString(), 10)];
                var ntd = td.cloneNode();
                if (data.type === 'dateColumn') {
                    if (this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda') {
                        tempData = tContent[parseInt(row.toString(), 10)][parseInt(col.toString(), 10)];
                        continue;
                    }
                    ntd.setAttribute('data-date', data.date.getTime().toString());
                    ntd.appendChild(this.createDateHeaderElement(data.date));
                    var className = [AGENDA_CELLS_CLASS, AGENDA_DATE_CLASS];
                    if (data.cssClass) {
                        className.push(data.cssClass);
                    }
                    addClass([ntd], className);
                    if (this.isCurrentDate(new Date(data.date.getTime()))) {
                        addClass(ntd.childNodes, AGENDA_CURRENT_DAY_CLASS);
                    }
                    ntr.appendChild(ntd);
                }
                else if (data.type === 'eventColumn') {
                    var elementType = (data.eventData.length === 0) ? 'noEvents' : 'data';
                    ntd = this.createAgendaContentElement(elementType, data.eventData, ntd, data.groupOrder, data.groupIndex);
                    ntd.setAttribute('data-date', data.date.getTime().toString());
                    if (this.parent.activeViewOptions.group.byDate || this.parent.currentView === 'MonthAgenda') {
                        addClass([ntd], [AGENDA_CELLS_CLASS, AGENDA_DAY_PADDING_CLASS]);
                    }
                    ntr.appendChild(ntd);
                }
                else {
                    ntd.setAttribute('rowspan', data.rowSpan.toString());
                    addClass([ntd], AGENDA_RESOURCE_CLASS);
                    this.setResourceHeaderContent(ntd, data, data.className[0]);
                    ntr.appendChild(ntd);
                }
            }
            if (this.parent.activeViewOptions.group.byDate && row === 0 && this.parent.currentView !== 'MonthAgenda') {
                var ntd = td.cloneNode();
                ntd.setAttribute('data-date', tempData.date.getTime().toString());
                ntd.setAttribute('rowspan', rowSpan.toString());
                ntd.appendChild(this.createDateHeaderElement(tempData.date));
                addClass([ntd], [AGENDA_CELLS_CLASS, AGENDA_DATE_CLASS, DATE_BORDER_CLASS]);
                var daysCount = getDaysCount(this.parent.selectedDate.getTime(), tempData.date.getTime());
                ntr.setAttribute('data-row-index', daysCount.toString());
                if (this.parent.element.querySelector(".e-agenda-view tr[data-row-index=\"" + daysCount + "\"]")) {
                    break;
                }
                ntr.insertBefore(ntd, ntr.childNodes[0]);
            }
            tBody.appendChild(ntr);
        }
    };
    AgendaBase.prototype.createDateHeaderElement = function (date) {
        var dateHeader;
        if (this.parent.activeViewOptions.dateHeaderTemplate) {
            dateHeader = createElement('div', { className: AGENDA_HEADER_CLASS });
            var args = { date: date, type: 'dateHeader' };
            var scheduleId = this.parent.element.id + '_';
            var viewName = this.parent.activeViewOptions.dateHeaderTemplateName;
            var templateId = scheduleId + viewName + 'dateHeaderTemplate';
            var dateTemplate = [].slice.call(this.parent.getDateHeaderTemplate()(args, this.parent, 'dateHeaderTemplate', templateId, false));
            append(dateTemplate, dateHeader);
        }
        else {
            dateHeader = this.getMobileDateElement(date, AGENDA_HEADER_CLASS);
        }
        return dateHeader;
    };
    AgendaBase.prototype.renderEmptyContent = function (tBody, agendaDate) {
        var eTr = this.createTableRowElement(agendaDate, 'noEvents');
        var eTd = eTr.children[0];
        var noEvents = createElement('div', {
            className: AGENDA_EMPTY_EVENT_CLASS,
            innerHTML: this.parent.localeObj.getConstant('noEvents')
        });
        eTd.appendChild(noEvents);
        tBody.appendChild(eTr);
    };
    AgendaBase.prototype.createTableRowElement = function (date, type) {
        var daysCount = getDaysCount(this.parent.selectedDate.getTime(), date.getTime());
        var tr = createElement('tr', { attrs: { 'data-row-index': daysCount.toString() } });
        var td = createElement('td', {
            attrs: {
                'class': (type === 'monthHeader') ? MONTH_HEADER_CLASS : AGENDA_CELLS_CLASS,
                'aria-selected': 'false',
                'data-column-index': daysCount.toString(),
                'data-date': date.getTime().toString()
            }
        });
        var dTd = td.cloneNode();
        var aTd = td.cloneNode();
        tr.appendChild(dTd);
        if (type !== 'noEvents') {
            tr.appendChild(aTd);
        }
        return tr;
    };
    AgendaBase.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
    };
    return AgendaBase;
}(ViewBase));

var __extends$23 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * agenda view
 */
var Agenda = /** @__PURE__ @class */ (function (_super) {
    __extends$23(Agenda, _super);
    function Agenda(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-agenda-view';
        _this.isInverseTableSelect = false;
        _this.agendaDates = {};
        _this.virtualScrollTop = 1;
        return _this;
    }
    Agenda.prototype.getModuleName = function () {
        return 'agenda';
    };
    Agenda.prototype.renderLayout = function () {
        this.agendaDates = {};
        this.element = createElement('div', { className: TABLE_WRAP_CLASS });
        addClass([this.element], this.viewClass);
        this.element.appendChild(this.createTableLayout(OUTER_TABLE_CLASS));
        this.element.querySelector('table').setAttribute('role', 'presentation');
        this.parent.element.querySelector('.' + TABLE_CONTAINER_CLASS).appendChild(this.element);
        var eTr = createElement('tr');
        this.element.querySelector('tbody').appendChild(eTr);
        var workTd = createElement('td');
        eTr.appendChild(workTd);
        var wrap = createElement('div', { className: CONTENT_WRAP_CLASS });
        workTd.appendChild(wrap);
        var tbl = this.createTableLayout(CONTENT_TABLE_CLASS);
        this.setAriaAttributes(tbl);
        wrap.appendChild(tbl);
        var tBody = tbl.querySelector('tbody');
        var agendaDate = resetTime(this.parent.selectedDate);
        this.renderEmptyContent(tBody, agendaDate);
        this.wireEvents();
        if (this.parent.resourceBase) {
            this.parent.resourceBase.generateResourceLevels([{ renderDates: this.parent.activeView.renderDates }]);
        }
        if (this.parent.uiStateValues.isGroupAdaptive && !this.parent.element.querySelector('.' + RESOURCE_TOOLBAR_CONTAINER)) {
            this.renderResourceMobileLayout();
        }
        this.parent.notify(contentReady, {});
    };
    Agenda.prototype.eventLoad = function (args) {
        this.dataSource = extend([], this.parent.eventsData, null, true);
        for (var _i = 0, _a = this.parent.eventsData; _i < _a.length; _i++) {
            var event_1 = _a[_i];
            delete event_1.generatedDates;
        }
        var eventCollection = this.parent.activeViewOptions.allowVirtualScrolling ?
            args.processedData : this.parent.eventsProcessed;
        if (this.parent.uiStateValues.isGroupAdaptive) {
            var resource = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
            this.dataSource = this.parent.eventBase.filterEventsByResource(resource, this.dataSource);
            eventCollection = this.parent.eventBase.filterEventsByResource(resource, eventCollection);
        }
        this.parent.eventsProcessed = this.processAgendaEvents(eventCollection);
        var agendaDate = resetTime(this.parent.selectedDate);
        var tBody = this.element.querySelector('.' + CONTENT_TABLE_CLASS + ' tbody');
        removeChildren(tBody);
        this.renderInitialContent(tBody, agendaDate);
        this.wireEventActions();
        var contentArea = closest(tBody, '.' + CONTENT_WRAP_CLASS);
        contentArea.scrollTop = 1;
        this.parent.notify(eventsLoaded, {});
        if (!this.parent.activeViewOptions.allowVirtualScrolling) {
            this.retainScrollPosition();
        }
    };
    Agenda.prototype.refreshEvent = function (refreshDate) {
        var processedData = [];
        var fields = this.parent.eventFields;
        for (var _i = 0, _a = this.dataSource; _i < _a.length; _i++) {
            var data = _a[_i];
            if (isNullOrUndefined(data[fields.recurrenceID]) && !isNullOrUndefined(data[fields.recurrenceRule]) &&
                !isNullOrUndefined(data.generatedDates) && refreshDate >= data.generatedDates.end) {
                processedData = processedData.concat(this.parent.eventBase.generateOccurrence(data, refreshDate));
            }
        }
        this.parent.eventsProcessed = this.parent.eventsProcessed.concat(this.processAgendaEvents(processedData));
    };
    Agenda.prototype.refreshHeader = function () {
        var tBody = this.element.querySelector('.' + CONTENT_TABLE_CLASS + ' tbody');
        if (this.parent.activeViewOptions.group.byDate) {
            removeChildren(tBody);
        }
        else {
            remove(tBody.firstElementChild);
        }
        var agendaDate = resetTime(this.parent.selectedDate);
        var emptyTBody = createElement('tbody');
        var firstDate = new Date(agendaDate.getTime());
        var lastDate = (this.parent.activeViewOptions.allowVirtualScrolling && this.parent.hideEmptyAgendaDays) ?
            this.getEndDateFromStartDate(firstDate) : addDays(firstDate, this.parent.agendaDaysCount);
        this.renderContent(emptyTBody, firstDate, lastDate);
        append([].slice.call(emptyTBody.childNodes), tBody);
    };
    Agenda.prototype.renderInitialContent = function (tBody, agendaDate) {
        var emptyTBody = createElement('tbody');
        var firstDate = new Date(agendaDate.getTime());
        var lastDate = (this.parent.activeViewOptions.allowVirtualScrolling && this.parent.hideEmptyAgendaDays) ?
            this.getEndDateFromStartDate(firstDate) : addDays(firstDate, this.parent.agendaDaysCount);
        this.renderContent(emptyTBody, firstDate, lastDate);
        append([].slice.call(emptyTBody.childNodes), tBody);
        // Initial rendering, to load previous date events upto scroll bar enable
        if (this.parent.activeViewOptions.allowVirtualScrolling && this.parent.hideEmptyAgendaDays && this.parent.eventsData.length > 0) {
            var contentArea = this.getContentAreaElement();
            var contentChild = contentArea.querySelector('.e-content-table');
            while (contentArea.offsetWidth <= contentArea.clientWidth) {
                if (this.parent.isAdaptive && contentChild.offsetHeight >= contentArea.clientHeight) {
                    break;
                }
                var emptyTBody_1 = createElement('tbody');
                lastDate = firstDate;
                firstDate = addDays(lastDate, -this.parent.agendaDaysCount);
                this.renderContent(emptyTBody_1, firstDate, lastDate);
                prepend([].slice.call(emptyTBody_1.childNodes), tBody);
                if (firstDate <= this.parent.minDate) {
                    break;
                }
            }
        }
        if (tBody.childNodes.length <= 0) {
            this.renderEmptyContent(tBody, agendaDate);
        }
    };
    Agenda.prototype.renderContent = function (tBody, agendaDate, lastDate) {
        var fieldMapping = this.parent.eventFields;
        var firstDate = new Date(agendaDate.getTime());
        var isObject = this.appointmentFiltering(firstDate, lastDate);
        if (isObject.length > 0 && this.parent.activeViewOptions.allowVirtualScrolling && this.parent.hideEmptyAgendaDays) {
            agendaDate = isObject[0][fieldMapping.startTime];
            agendaDate = new Date(new Date(agendaDate.getTime()).setHours(0, 0, 0, 0));
            this.updateHeaderText(isObject[0][fieldMapping.startTime]);
        }
        var endDate;
        if (!this.parent.hideEmptyAgendaDays || (this.parent.agendaDaysCount > 0 && isObject.length > 0)) {
            if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                var date = agendaDate;
                if (!this.parent.activeViewOptions.group.byDate) {
                    this.parent.activeViewOptions.allowVirtualScrolling = false;
                    date = firstDate;
                    if (this.parent.headerModule) {
                        this.parent.headerModule.updateDateRange();
                        this.parent.headerModule.updateHeaderItems('remove');
                    }
                }
                this.calculateResourceTableElement(tBody, this.parent.agendaDaysCount, date);
            }
            else {
                for (var day = 0; day < this.parent.agendaDaysCount; day++) {
                    var filterData = this.appointmentFiltering(agendaDate);
                    var nTr = this.createTableRowElement(agendaDate, 'data');
                    if (this.element.querySelector('tr[data-row-index="' + parseInt(nTr.getAttribute('data-row-index'), 10) + '"]')) {
                        agendaDate = addDays(agendaDate, 1);
                        continue;
                    }
                    var dTd = nTr.children[0];
                    var aTd = nTr.children[1];
                    if (filterData.length > 0 || (!this.parent.hideEmptyAgendaDays && filterData.length === 0)) {
                        var elementType = (!this.parent.hideEmptyAgendaDays && filterData.length === 0) ? 'noEvents' : 'data';
                        dTd.appendChild(this.createDateHeaderElement(agendaDate));
                        nTr.appendChild(dTd);
                        var cTd = this.createAgendaContentElement(elementType, filterData, aTd);
                        nTr.appendChild(cTd);
                        if (cTd.querySelectorAll('li').length > 0) {
                            tBody.appendChild(nTr);
                        }
                    }
                    else if (this.parent.activeViewOptions.allowVirtualScrolling) {
                        day--;
                    }
                    if (this.isCurrentDate(new Date(agendaDate.getTime()))) {
                        addClass(dTd.childNodes, AGENDA_CURRENT_DAY_CLASS);
                    }
                    agendaDate = addDays(agendaDate, 1);
                    if (agendaDate.getTime() > lastDate.getTime()) {
                        break;
                    }
                }
            }
            endDate = new Date(agendaDate.getTime() - MS_PER_DAY);
        }
        this.agendaDates = { start: firstDate, end: endDate };
    };
    Agenda.prototype.agendaScrolling = function (event) {
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide();
        }
        if (this.parent.activeViewOptions.allowVirtualScrolling) {
            this.virtualScrolling(event);
        }
        if (!this.parent.activeViewOptions.allowVirtualScrolling) {
            this.setPersistence();
        }
    };
    Agenda.prototype.virtualScrolling = function (event) {
        var target = event.target;
        var scrollTop = target.scrollTop;
        var scrollHeight = target.scrollHeight;
        var offsetHeight = target.clientHeight;
        var totalHeight = scrollTop + offsetHeight;
        var direction = (this.virtualScrollTop < scrollTop) ? 'next' : 'previous';
        var tBody = target.querySelector('tbody');
        var emptyTBody = createElement('tbody');
        var topElement = this.getElementFromScrollerPosition(event);
        var scrollDate = this.parent.getDateFromElement(topElement);
        var filterDate;
        var filterData;
        if (scrollTop === 0) {
            filterDate = this.getPreviousNextDate(addDays(scrollDate, -1), direction);
            filterData = this.appointmentFiltering(filterDate.start, filterDate.end);
            if (filterData.length > 0 || !this.parent.hideEmptyAgendaDays) {
                this.renderContent(emptyTBody, filterDate.start, filterDate.end);
                prepend([].slice.call(emptyTBody.childNodes), tBody);
                this.wireEventActions();
                for (var s = 0, element = tBody.children; s < element.length; s++) {
                    if (element[parseInt(s.toString(), 10)].getAttribute('data-row-index') === topElement.getAttribute('data-column-index')) {
                        var scrollToValue = element[parseInt(s.toString(), 10)].offsetTop -
                            this.element.querySelector('.e-agenda-item').offsetHeight;
                        target.scrollTop = scrollToValue;
                        break;
                    }
                }
                this.updateHeaderText(scrollDate);
            }
        }
        else if (totalHeight >= (scrollHeight - 5)) {
            filterDate = this.getPreviousNextDate(addDays(scrollDate, 1), direction);
            filterData = this.appointmentFiltering(filterDate.start, filterDate.end);
            if (filterData.length > 0 || !this.parent.hideEmptyAgendaDays) {
                this.renderContent(emptyTBody, filterDate.start, filterDate.end);
                append([].slice.call(emptyTBody.childNodes), tBody);
                this.wireEventActions();
                this.updateHeaderText(scrollDate);
            }
        }
        else {
            this.updateHeaderText(scrollDate);
        }
        this.virtualScrollTop = scrollTop;
        var selectedElements = this.parent.eventBase.getSelectedAppointments();
        if (selectedElements.length > 0) {
            selectedElements[selectedElements.length - 1].focus();
        }
    };
    Agenda.prototype.getElementFromScrollerPosition = function (event) {
        var filterElement;
        var target = event.target;
        var scrollTop = target.scrollTop;
        var scrollHeight = target.scrollHeight;
        var offsetHeight = target.clientHeight;
        var totalHeight = scrollTop + offsetHeight;
        var liCollection = [].slice.call(target.querySelectorAll('.e-agenda-item'));
        var li;
        var liDetails;
        if (liCollection.length > 0) {
            if (scrollTop === 0) {
                li = liCollection[0];
                filterElement = closest(li, '.' + AGENDA_CELLS_CLASS);
            }
            else if (totalHeight === scrollHeight) {
                li = liCollection[liCollection.length - 1];
                filterElement = closest(li, '.' + AGENDA_CELLS_CLASS);
            }
            else {
                for (var a = 0, length_1 = liCollection.length; a < length_1; a++) {
                    li = liCollection[parseInt(a.toString(), 10)];
                    liDetails = li.getBoundingClientRect();
                    if (liDetails.top >= 0) {
                        filterElement = closest(li, '.' + AGENDA_CELLS_CLASS);
                        break;
                    }
                }
            }
        }
        return filterElement;
    };
    Agenda.prototype.updateHeaderText = function (date) {
        if (this.parent.showHeaderBar) {
            this.parent.headerModule.updateDateRange(date);
        }
    };
    Agenda.prototype.getPreviousNextDate = function (date, type) {
        var currentDate = new Date(date.getTime());
        var firstDate = this.getStartDateFromEndDate(date);
        var lastDate = this.getEndDateFromStartDate(date);
        var daysCount = 0;
        do {
            var filterData = this.appointmentFiltering(currentDate);
            if (filterData.length > 0 || !this.parent.hideEmptyAgendaDays) {
                daysCount++;
            }
            currentDate = addDays(currentDate, (type === 'next') ? 1 : -1);
            if (currentDate < firstDate || currentDate > lastDate) {
                break;
            }
        } while (daysCount !== this.parent.agendaDaysCount);
        var endDate = addDays(currentDate, (type === 'next') ? -1 : 1);
        return (type === 'next') ? { start: date, end: addDays(endDate, 1) } : { start: endDate, end: addDays(date, 1) };
    };
    Agenda.prototype.appointmentFiltering = function (startDate, endDate) {
        var dateStart;
        var dateEnd;
        if (!isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
            dateStart = resetTime(new Date(startDate.getTime()));
            dateEnd = setTime(new Date(dateStart.getTime()), MS_PER_DAY);
        }
        else {
            dateStart = new Date(startDate.getTime());
            dateEnd = new Date(endDate.getTime());
        }
        var filterData = this.parent.eventBase.filterEvents(dateStart, dateEnd);
        if (filterData.length === 0) {
            this.refreshEvent(startDate);
            filterData = this.parent.eventBase.filterEvents(dateStart, dateEnd);
        }
        return filterData;
    };
    Agenda.prototype.getStartDateFromEndDate = function (endDate) {
        var filterDate;
        var fields = this.parent.eventFields;
        if (this.parent.eventsProcessed.length > 0) {
            var firstDate = Math.min.apply(Math, this.parent.eventsProcessed.map(function (a) {
                return a[fields.startTime].getTime();
            }));
            filterDate = this.parent.hideEmptyAgendaDays ? new Date(firstDate) : this.parent.minDate;
        }
        else {
            filterDate = this.parent.hideEmptyAgendaDays ? addMonths(endDate, -1) : this.parent.minDate;
        }
        return resetTime(filterDate);
    };
    Agenda.prototype.getEndDateFromStartDate = function (startDate) {
        var filterDate;
        var fieldMapping = this.parent.eventFields;
        if (this.parent.eventsProcessed.length > 0) {
            var lastDate = Math.max.apply(Math, this.parent.eventsProcessed.map(function (a) {
                return a[fieldMapping.endTime].getTime();
            }));
            filterDate = this.parent.hideEmptyAgendaDays ? new Date(lastDate) : this.parent.maxDate;
        }
        else {
            filterDate = this.parent.hideEmptyAgendaDays ? addMonths(startDate, 1) : this.parent.maxDate;
        }
        return resetTime(addDays(filterDate, 1));
    };
    Agenda.prototype.getNextPreviousDate = function (type) {
        var noOfDays = (type === 'next') ? 1 : -1;
        return addDays(this.parent.selectedDate, noOfDays);
    };
    Agenda.prototype.startDate = function () {
        return resetTime(this.parent.selectedDate);
    };
    Agenda.prototype.endDate = function () {
        if (this.parent.activeViewOptions.allowVirtualScrolling) {
            return this.getEndDateFromStartDate(this.startDate());
        }
        else {
            return addDays(this.startDate(), this.parent.agendaDaysCount);
        }
    };
    Agenda.prototype.getDateRangeText = function (date) {
        var formatDate = (this.parent.activeViewOptions.dateFormat) ? this.parent.activeViewOptions.dateFormat : 'MMMM y';
        if (this.parent.activeViewOptions.allowVirtualScrolling || this.parent.isAdaptive) {
            var currentDate = isNullOrUndefined(date) ? this.parent.selectedDate : date;
            return capitalizeFirstWord(this.parent.globalize.formatDate(currentDate, { format: formatDate, calendar: this.parent.getCalendarMode() }), 'multiple');
        }
        else {
            var startDate = this.parent.selectedDate;
            var endDate = addDays(startDate, this.parent.agendaDaysCount - 1);
            return this.formatDateRange(startDate, endDate);
        }
    };
    Agenda.prototype.dayNavigationClick = function (e) {
        var element = closest(e.currentTarget, '.' + AGENDA_CELLS_CLASS);
        var date = this.parent.getDateFromElement(element);
        if (!isNullOrUndefined(date) && !this.parent.isAdaptive && this.parent.isMinMaxDate(date)) {
            this.parent.setProperties({ selectedDate: date }, true);
            this.parent.changeView('Day', e);
        }
    };
    Agenda.prototype.wireEvents = function () {
        EventHandler.add(this.element.querySelector('.' + CONTENT_WRAP_CLASS), scroll, this.agendaScrolling, this);
    };
    Agenda.prototype.unWireEvents = function () {
        EventHandler.remove(this.element.querySelector('.' + CONTENT_WRAP_CLASS), scroll, this.agendaScrolling);
        var dateHeaderElement = [].slice.call(this.element.querySelectorAll('.e-m-date'));
        for (var _i = 0, dateHeaderElement_1 = dateHeaderElement; _i < dateHeaderElement_1.length; _i++) {
            var element = dateHeaderElement_1[_i];
            EventHandler.remove(element, 'click', this.dayNavigationClick);
        }
    };
    Agenda.prototype.addEventListener = function () {
        this.parent.on(scrollUiUpdate, this.onAgendaScrollUiUpdate, this);
        this.parent.on(dataReady, this.eventLoad, this);
    };
    Agenda.prototype.removeEventListener = function () {
        if (this.parent) {
            this.parent.off(scrollUiUpdate, this.onAgendaScrollUiUpdate);
            this.parent.off(dataReady, this.eventLoad);
        }
    };
    Agenda.prototype.onAgendaScrollUiUpdate = function () {
        var headerHeight = this.getHeaderBarHeight();
        if (this.parent.headerModule) {
            if (this.parent.activeViewOptions.allowVirtualScrolling) {
                this.parent.headerModule.updateHeaderItems('add');
            }
            else {
                this.parent.headerModule.updateHeaderItems('remove');
            }
        }
        var contentArea = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
        if (this.parent.height !== 'auto') {
            contentArea.style.height = formatUnit(this.parent.element.offsetHeight - headerHeight);
        }
    };
    Agenda.prototype.scrollToDate = function (scrollDate) {
        var date = new Date(+resetTime(scrollDate));
        if (this.parent.activeViewOptions.allowVirtualScrolling) {
            if (!this.parent.hideEmptyAgendaDays || this.parent.getEvents(date, addDays(date, 1), true).length > 0) {
                this.parent.changeDate(date);
            }
        }
        else {
            var dateElement = this.element.querySelector('.' + AGENDA_CELLS_CLASS + '[data-date="' + date.getTime() + '"]');
            if (dateElement) {
                this.getContentAreaElement().scrollTop = dateElement.offsetTop;
            }
        }
    };
    Agenda.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        if (this.element) {
            this.unWireEvents();
            if (this.parent.resourceBase) {
                this.parent.resourceBase.destroy();
            }
            if (this.parent.headerModule && this.parent.activeViewOptions.allowVirtualScrolling) {
                this.parent.headerModule.updateHeaderItems('remove');
            }
            _super.prototype.destroy.call(this);
        }
    };
    return Agenda;
}(AgendaBase));

var __extends$25 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * month agenda view
 */
var MonthAgenda = /** @__PURE__ @class */ (function (_super) {
    __extends$25(MonthAgenda, _super);
    function MonthAgenda(parent) {
        var _this = _super.call(this, parent) || this;
        _this.dayNameFormat = 'narrow';
        _this.viewClass = 'e-month-agenda-view';
        _this.monthAgendaDate = new Date('' + parent.selectedDate);
        return _this;
    }
    MonthAgenda.prototype.getModuleName = function () {
        return 'monthAgenda';
    };
    MonthAgenda.prototype.renderAppointmentContainer = function () {
        var contentArea = this.getContentAreaElement();
        var wrapperContainer = createElement('div', { className: WRAPPER_CONTAINER_CLASS });
        contentArea.appendChild(wrapperContainer);
        var appWrap = createElement('div', { className: APPOINTMENT_WRAP_CLASS });
        wrapperContainer.appendChild(appWrap);
        this.appendAppContainer(appWrap);
        this.setEventWrapperHeight();
    };
    MonthAgenda.prototype.getDayNameFormat = function () {
        if (this.parent.isAdaptive) {
            return 'narrow';
        }
        return 'abbreviated';
    };
    MonthAgenda.prototype.updateSelectedCellClass = function (data) {
        if (resetTime(data.date).getTime() === resetTime(this.monthAgendaDate).getTime()) {
            data.className.push(SELECTED_CELL_CLASS);
        }
    };
    MonthAgenda.prototype.setEventWrapperHeight = function () {
        var headerHeight = (this.parent.headerModule ? this.parent.headerModule.getHeaderElement().offsetHeight : 0) + 2;
        var resourceWrapper = this.parent.element.querySelector('.' + RESOURCE_HEADER_TOOLBAR);
        if (resourceWrapper) {
            headerHeight += resourceWrapper.offsetHeight;
        }
        var contentArea = this.getContentAreaElement().firstElementChild;
        var dateHeader = this.element.querySelector('.' + DATE_HEADER_WRAP_CLASS);
        var availHeight = this.parent.element.offsetHeight - headerHeight - dateHeader.offsetHeight;
        var contentAreaHeight = (this.parent.activeViewOptions.interval > 1) ?
            Math.round(availHeight * 0.8) : contentArea.offsetHeight;
        var appContainerHeight = availHeight - contentAreaHeight;
        var wrapperContainer = this.element.querySelector('.' + WRAPPER_CONTAINER_CLASS);
        var eventWrapper = this.element.querySelector('.' + APPOINTMENT_WRAP_CLASS);
        if (this.parent.height !== 'auto') {
            if (this.parent.activeViewOptions.interval > 1) {
                contentArea.style.height = formatUnit(contentAreaHeight);
                EventHandler.add(contentArea, 'scroll', this.onContentScroll, this);
            }
            wrapperContainer.style.height = eventWrapper.style.height = formatUnit(appContainerHeight);
        }
    };
    MonthAgenda.prototype.onDataReady = function (args) {
        this.setEventWrapperHeight();
        this.clearElements();
        var eventCollection = args.processedData;
        if (this.parent.uiStateValues.isGroupAdaptive) {
            var resource = this.parent.resourceBase.lastResourceLevel[this.parent.uiStateValues.groupIndex];
            eventCollection = this.parent.eventBase.filterEventsByResource(resource, eventCollection);
        }
        this.agendaBase = this.getAgendaBase();
        this.parent.eventsProcessed = this.agendaBase.processAgendaEvents(eventCollection);
        var count = 0;
        for (var _i = 0, _a = this.renderDates; _i < _a.length; _i++) {
            var date = _a[_i];
            var filterData = this.appointmentFiltering(date);
            var workCell = this.element.querySelectorAll('.' + WORK_CELLS_CLASS)[parseInt(count.toString(), 10)];
            if (filterData.length > 0) {
                if (!workCell.querySelector('.' + APPOINTMENT_INDICATOR_CLASS)) {
                    workCell.appendChild(createElement('div', { className: APPOINTMENT_INDICATOR_CLASS }));
                }
                if (date.getTime() === resetTime(new Date(this.monthAgendaDate.getTime())).getTime()) {
                    this.onEventRender(filterData, date);
                }
            }
            count++;
        }
        this.parent.notify(eventsLoaded, {});
    };
    MonthAgenda.prototype.onCellClick = function (event) {
        if (this.parent.quickPopup) {
            this.parent.quickPopup.quickPopupHide();
        }
        var filterData = this.appointmentFiltering(event.startTime);
        this.onEventRender(filterData, event.startTime);
        this.parent.notify(eventsLoaded, {});
        this.monthAgendaDate = new Date('' + event.startTime);
    };
    MonthAgenda.prototype.onEventRender = function (events, date) {
        this.agendaBase = this.getAgendaBase();
        var appWrap = this.element.querySelector('.' + APPOINTMENT_WRAP_CLASS);
        removeChildren(appWrap);
        if (this.parent.activeViewOptions.group.resources.length === 0 || this.parent.uiStateValues.isGroupAdaptive) {
            if (events.length > 0) {
                var appContainer = createElement('div', { className: APPOINTMENT_CONTAINER_CLASS });
                appWrap.appendChild(this.agendaBase.createAgendaContentElement('data', events, appContainer));
            }
            else {
                this.appendAppContainer(appWrap);
            }
        }
        else {
            if (events.length > 0) {
                var table = this.createTableLayout();
                var tBody = table.querySelector('tbody');
                this.agendaBase.calculateResourceTableElement(tBody, 1, date);
                table.appendChild(tBody);
                appWrap.appendChild(table);
            }
            else {
                this.appendAppContainer(appWrap);
            }
        }
        this.agendaBase.wireEventActions();
    };
    MonthAgenda.prototype.appointmentFiltering = function (date) {
        var dateStart = resetTime(new Date(date.getTime()));
        var dateEnd = setTime(new Date(dateStart.getTime()), MS_PER_DAY);
        return this.parent.eventBase.filterEvents(dateStart, dateEnd);
    };
    MonthAgenda.prototype.clearElements = function () {
        var appointmentIndicators = [].slice.call(this.element.querySelectorAll('.' + APPOINTMENT_INDICATOR_CLASS));
        for (var _i = 0, appointmentIndicators_1 = appointmentIndicators; _i < appointmentIndicators_1.length; _i++) {
            var appointmentIndicator = appointmentIndicators_1[_i];
            remove(appointmentIndicator);
        }
        this.appendAppContainer(this.element.querySelector('.' + APPOINTMENT_WRAP_CLASS));
    };
    MonthAgenda.prototype.appendAppContainer = function (appWrap) {
        var app = createElement('div', { className: APPOINTMENT_CONTAINER_CLASS });
        addClass([app], AGENDA_NO_EVENT_CLASS);
        app.innerHTML = this.parent.localeObj.getConstant('noEvents');
        removeChildren(appWrap);
        appWrap.appendChild(app);
    };
    MonthAgenda.prototype.getNextPreviousDate = function (type) {
        var selectedDate = this.parent.selectedDate;
        var interval = (type === 'next') ? this.parent.activeViewOptions.interval : -this.parent.activeViewOptions.interval;
        var navigateDate = addMonths(this.parent.selectedDate, interval);
        var month = (type === 'next') ? 2 : 0;
        var lastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + month, 0).getDate();
        var date = (lastDate >= this.monthAgendaDate.getDate()) ? this.monthAgendaDate.getDate() : lastDate;
        this.monthAgendaDate = new Date(navigateDate.getFullYear(), navigateDate.getMonth(), date);
        return this.monthAgendaDate;
    };
    MonthAgenda.prototype.getAgendaBase = function () {
        return this.agendaBase || new AgendaBase(this.parent);
    };
    MonthAgenda.prototype.destroy = function () {
        if (this.agendaBase) {
            this.agendaBase.destroy();
            this.agendaBase = null;
        }
        _super.prototype.destroy.call(this);
    };
    return MonthAgenda;
}(Month));

/**
 * timeline header rows
 */
var TimelineHeaderRow = /** @__PURE__ @class */ (function () {
    function TimelineHeaderRow(parent, renderDates) {
        this.parent = parent;
        this.renderDates = renderDates;
    }
    TimelineHeaderRow.prototype.groupByYear = function (dates) {
        var result = {};
        for (var _i = 0, dates_1 = dates; _i < dates_1.length; _i++) {
            var d = dates_1[_i];
            var key = d.getFullYear();
            result[parseInt(key.toString(), 10)] = result[parseInt(key.toString(), 10)] || [];
            result[parseInt(key.toString(), 10)].push(d);
        }
        return result;
    };
    TimelineHeaderRow.prototype.groupByMonth = function (dates) {
        var result = {};
        for (var _i = 0, dates_2 = dates; _i < dates_2.length; _i++) {
            var d = dates_2[_i];
            var key = (d.getFullYear() - 1970) * 12 + d.getMonth();
            result[parseInt(key.toString(), 10)] = result[parseInt(key.toString(), 10)] || [];
            result[parseInt(key.toString(), 10)].push(d);
        }
        return result;
    };
    TimelineHeaderRow.prototype.groupByWeek = function (dates) {
        var result = {};
        for (var _i = 0, dates_3 = dates; _i < dates_3.length; _i++) {
            var d = dates_3[_i];
            var jsDate = +new Date(1970, 0, 1);
            var tzOffsetDiff = d.getTimezoneOffset() - new Date(1970, 0, 1).getTimezoneOffset();
            var key = Math.ceil(((((+d - jsDate) - (tzOffsetDiff * 60 * 1000)) / MS_PER_DAY) + new Date(jsDate).getDay() + 1) / 7);
            if (this.parent.firstDayOfWeek && this.parent.firstDayOfWeek > new Date(+d).getDay()) {
                key = key - 1;
            }
            result[parseInt(key.toString(), 10)] = result[parseInt(key.toString(), 10)] || [];
            result[parseInt(key.toString(), 10)].push(d);
        }
        return result;
    };
    TimelineHeaderRow.prototype.generateSlots = function (data, colspan, row, cls, type) {
        var _this = this;
        var dateParser = function (date, format) {
            return _this.parent.globalize.formatDate(date, { format: format, calendar: _this.parent.getCalendarMode() });
        };
        var tdDatas = [];
        var keys = Object.keys(data);
        for (var i = 0; i < keys.length; i++) {
            var dates = data[keys[parseInt(i.toString(), 10)]];
            var htmlCol = void 0;
            if (row.template) {
                var args = { date: dates[0], type: type };
                var templateId = this.parent.element.id + '_headerRowTemplate';
                htmlCol = [].slice.call(this.parent.templateParser(row.template)(args, this.parent, 'template', templateId, false));
            }
            else {
                var viewTemplate = void 0;
                switch (row.option) {
                    case 'Year':
                        viewTemplate = "<span class=\"e-header-year\">" + dateParser(dates[0], 'y') + "</span>";
                        break;
                    case 'Month':
                        viewTemplate = "<span class=\"e-header-month\">" + capitalizeFirstWord(dateParser(dates[0], 'MMMM'), 'single') + "</span>";
                        break;
                    case 'Week':
                        viewTemplate = "<span class=\"e-header-week\">" + this.parent.getWeekNumberContent(dates) + "</span>";
                }
                var headerWrapper = createElement('div', { innerHTML: viewTemplate });
                htmlCol = [].slice.call(headerWrapper.childNodes);
            }
            tdDatas.push({ date: dates[0], type: type, className: [cls], colSpan: dates.length * colspan, template: htmlCol });
        }
        return tdDatas;
    };
    TimelineHeaderRow.prototype.generateColumnLevels = function (dateSlots, hourSlots) {
        var levels = [];
        var rows = this.parent.activeViewOptions.headerRows;
        var lastLevelColspan = 1;
        if (rows[rows.length - 1].option === 'Hour' && hourSlots.length > 0) {
            lastLevelColspan = hourSlots.length / dateSlots.length;
        }
        var tdDatas = [];
        var byYear;
        var byMonth;
        var byWeek;
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            switch (row.option) {
                case 'Year':
                    byYear = this.groupByYear(this.renderDates);
                    tdDatas = this.generateSlots(byYear, lastLevelColspan, row, 'e-header-year-cell', 'yearHeader');
                    levels.push(tdDatas);
                    break;
                case 'Month':
                    byMonth = this.groupByMonth(this.renderDates);
                    tdDatas = this.generateSlots(byMonth, lastLevelColspan, row, 'e-header-month-cell', 'monthHeader');
                    levels.push(tdDatas);
                    break;
                case 'Week':
                    byWeek = this.groupByWeek(this.renderDates);
                    tdDatas = this.generateSlots(byWeek, lastLevelColspan, row, 'e-header-week-cell', 'weekHeader');
                    levels.push(tdDatas);
                    break;
                case 'Date':
                    tdDatas = dateSlots;
                    tdDatas = tdDatas.map(function (value) {
                        value.colSpan = lastLevelColspan;
                        return value;
                    });
                    levels.push(tdDatas);
                    break;
                case 'Hour':
                    if (hourSlots.length > 0) {
                        levels.push(hourSlots);
                    }
                    break;
            }
        }
        return levels;
    };
    return TimelineHeaderRow;
}());

var __extends$26 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * timeline views
 */
var TimelineViews = /** @__PURE__ @class */ (function (_super) {
    __extends$26(TimelineViews, _super);
    function TimelineViews(parent) {
        var _this = _super.call(this, parent) || this;
        _this.timelineAppointment = null;
        _this.baseCssClass = 'e-timeline-view';
        return _this;
    }
    TimelineViews.prototype.getModuleName = function () {
        return 'timelineViews';
    };
    TimelineViews.prototype.getLeftPanelElement = function () {
        return this.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
    };
    TimelineViews.prototype.scrollTopPanel = function (target) {
        _super.prototype.scrollTopPanel.call(this, target);
        this.scrollHeaderLabels(target);
    };
    TimelineViews.prototype.scrollToWorkHour = function () {
        var start = this.parent.getStartEndTime(this.parent.workHours.start);
        var currDateTime = this.isWorkDay(this.parent.selectedDate) && this.parent.workHours.highlight &&
            !isNullOrUndefined(start) ? new Date(+this.parent.selectedDate).setHours(start.getHours(), start.getMinutes(), 0, 0)
            : new Date(+this.parent.selectedDate).setHours(0, 0, 0, 0);
        var queryString = '[data-date="' + new Date(currDateTime).getTime().toString() + '"]';
        var firstWorkHourCell = this.element.querySelector(queryString);
        if (firstWorkHourCell) {
            this.getContentAreaElement().scrollLeft = !this.parent.enableRtl ? firstWorkHourCell.offsetLeft :
                -(this.parent.getContentTable().offsetWidth - firstWorkHourCell.offsetLeft - firstWorkHourCell.offsetWidth);
        }
    };
    TimelineViews.prototype.scrollToHour = function (hour, scrollDate) {
        var date;
        var index;
        if (scrollDate) {
            index = this.parent.getIndexOfDate(this.renderDates, resetTime(scrollDate));
            if (index >= 0) {
                date = scrollDate;
                if (!isNullOrUndefined(hour)) {
                    var timeString = hour.split(':');
                    if (timeString.length === 2) {
                        date = new Date(scrollDate.setHours(parseInt(timeString[0], 10), parseInt(timeString[1], 10), 0));
                    }
                }
            }
        }
        date = isNullOrUndefined(scrollDate) ? this.parent.getStartEndTime(hour) : date;
        if (isNullOrUndefined(date)) {
            return;
        }
        var scrollLeft;
        if (isNullOrUndefined(hour) || !this.parent.activeViewOptions.timeScale.enable) {
            scrollLeft = index * this.getWorkCellWidth();
        }
        else {
            scrollLeft = isNullOrUndefined(scrollDate) ? this.getLeftFromDateTime(null, date) :
                this.getLeftFromDateTime([index], date);
        }
        this.getScrollableElement().scrollLeft = !this.parent.enableRtl ? scrollLeft : -scrollLeft;
    };
    TimelineViews.prototype.generateColumnLevels = function () {
        var levels = [];
        var dateSlots = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
        levels.push(dateSlots);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.resourceBase.generateResourceLevels(dateSlots, !this.parent.uiStateValues.isGroupAdaptive);
        }
        var hourSlots = [];
        if (this.parent.activeViewOptions.timeScale.enable) {
            hourSlots = this.generateTimeSlots(levels[levels.length - 1]);
            levels.push(hourSlots);
        }
        if (this.parent.activeViewOptions.headerRows.length > 0) {
            var renderGn = new TimelineHeaderRow(this.parent, this.renderDates);
            levels = renderGn.generateColumnLevels(dateSlots, hourSlots);
        }
        return levels;
    };
    TimelineViews.prototype.generateTimeSlots = function (dateSlots) {
        var _this = this;
        var handler = function (r) {
            r.type = r.first ? 'majorSlot' : 'minorSlot';
            r.className = r.first ? [TIME_SLOT_CLASS] : [TIME_SLOT_CLASS, TIME_CELLS_CLASS];
            r.workDays = _this.parent.activeViewOptions.workDays;
            return r;
        };
        var timeSlotData = this.getTimeSlotRows(handler);
        var slots = [];
        for (var _i = 0, dateSlots_1 = dateSlots; _i < dateSlots_1.length; _i++) {
            var data = dateSlots_1[_i];
            data.colSpan = timeSlotData.length;
            var tempTimeSlots = extend([], timeSlotData, null, true);
            for (var _a = 0, tempTimeSlots_1 = tempTimeSlots; _a < tempTimeSlots_1.length; _a++) {
                var slot = tempTimeSlots_1[_a];
                var cellDate = resetTime(new Date('' + data.date));
                slot.date = setTime(cellDate, getDateInMs(slot.date));
                slots.push(slot);
            }
        }
        return slots;
    };
    TimelineViews.prototype.changeCurrentTimePosition = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.removeCurrentTimeIndicatorElements();
        var currentDateIndex = this.getCurrentTimeIndicatorIndex();
        var left = this.getLeftFromDateTime(currentDateIndex, this.parent.getCurrentTime());
        var height = this.element.querySelector('.' + CONTENT_TABLE_CLASS).offsetHeight;
        var headerWrap = this.element.querySelector('.' + DATE_HEADER_WRAP_CLASS);
        var contentWrap = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
        contentWrap.appendChild(createElement('div', {
            className: CURRENT_TIMELINE_CLASS,
            styles: (this.parent.enableRtl ? 'right' : 'left') + ':' + formatUnit(left) + '; height:' + formatUnit(height)
        }));
        if (this.parent.virtualScrollModule) {
            var timeIndicator = this.parent.element.querySelector('.' + CURRENT_TIMELINE_CLASS);
            var element = this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS + ' table');
            setStyleAttribute(timeIndicator, {
                transform: element.style.transform
            });
        }
        var currentTimeEle = createElement('div', {
            innerHTML: this.parent.getTimeString(this.parent.getCurrentTime()),
            className: CURRENT_TIME_CLASS
        });
        headerWrap.appendChild(currentTimeEle);
        currentTimeEle.style[this.parent.enableRtl ? 'right' : 'left'] = formatUnit(left - (currentTimeEle.offsetWidth / 2));
    };
    TimelineViews.prototype.getLeftFromDateTime = function (currentDateIndex, date) {
        var startHour = this.getStartHour();
        var diffInDates = 0;
        var diffInMinutes = ((date.getHours() - startHour.getHours()) * 60) + (date.getMinutes() - startHour.getMinutes());
        if (!isNullOrUndefined(currentDateIndex)) {
            if (currentDateIndex[0] !== 0) {
                var index = this.parent.activeView.colLevels.findIndex(function (level) { return level[0].type === 'dateHeader'; });
                if (this.parent.activeView.colLevels[parseInt(index.toString(), 10)] &&
                    this.parent.activeView.colLevels[parseInt(index.toString(), 10)][0].colSpan) {
                    diffInDates = currentDateIndex[0] * this.parent.activeView.colLevels[parseInt(index.toString(), 10)][0].colSpan *
                        this.getWorkCellWidth();
                }
                else {
                    var endHour = this.getEndHour();
                    var end = (endHour.getHours() === 0) ? 24 : endHour.getHours();
                    diffInMinutes = diffInMinutes + ((currentDateIndex[0]) * (((end - startHour.getHours()) * 60) +
                        (endHour.getMinutes() - startHour.getMinutes())));
                }
            }
        }
        return diffInDates + ((diffInMinutes * this.getWorkCellWidth() * this.parent.activeViewOptions.timeScale.slotCount) /
            this.parent.activeViewOptions.timeScale.interval);
    };
    TimelineViews.prototype.getWorkCellWidth = function () {
        return this.element.querySelector('.e-work-cells').getBoundingClientRect().width;
    };
    TimelineViews.prototype.renderHeader = function () {
        var tr = createElement('tr');
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            this.parent.resourceBase.renderResourceHeaderIndent(tr);
        }
        var dateTd = createElement('td');
        dateTd.appendChild(this.renderDatesHeader());
        tr.appendChild(dateTd);
        prepend([tr], this.element.querySelector('tbody'));
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TimelineViews.prototype.createAllDayRow = function (table, tdData) { };
    TimelineViews.prototype.getCurrentTimeIndicatorIndex = function () {
        var currentDateIndex = [];
        var index = this.parent.getIndexOfDate(this.renderDates, resetTime(this.parent.getCurrentTime()));
        if (index >= 0) {
            currentDateIndex.push(index);
        }
        return currentDateIndex;
    };
    TimelineViews.prototype.renderContent = function () {
        var tr = createElement('tr');
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            var resTd = createElement('td');
            resTd.appendChild(this.parent.resourceBase.createResourceColumn());
            tr.appendChild(resTd);
        }
        var workTd = createElement('td');
        var wrap = this.renderContentArea();
        wrap.appendChild(this.createEventTable(this.getRowCount()));
        this.collapseRows(wrap);
        workTd.appendChild(wrap);
        tr.appendChild(workTd);
        if (this.parent.virtualScrollModule) {
            this.parent.virtualScrollModule.renderVirtualTrack(wrap);
        }
        this.element.querySelector('tbody').appendChild(tr);
    };
    TimelineViews.prototype.getRowCount = function () {
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            return this.parent.resourceBase.renderedResources.length;
        }
        return 1;
    };
    TimelineViews.prototype.getResourceTdData = function (i, tdData) {
        var resLevel = this.parent.resourceBase.renderedResources[parseInt(i.toString(), 10)];
        var resSHr = resLevel.resourceData[resLevel.resource.startHourField] || this.parent.workHours.start;
        var resEHr = resLevel.resourceData[resLevel.resource.endHourField] || this.parent.workHours.end;
        tdData.startHour = this.parent.getStartEndTime(resSHr);
        tdData.endHour = this.parent.getStartEndTime(resEHr);
        tdData.workDays = resLevel.resourceData[resLevel.resource.workDaysField] || this.parent.workDays;
        tdData.className = resLevel.className;
        tdData.groupIndex = resLevel.groupIndex;
        tdData.groupOrder = resLevel.groupOrder;
        return tdData;
    };
    TimelineViews.prototype.renderContentTable = function (table) {
        var tBody = table.querySelector('tbody');
        append(this.getContentRows(), tBody);
    };
    TimelineViews.prototype.getContentRows = function () {
        var rows = [];
        var tr = createElement('tr');
        var td = createElement('td', { attrs: { 'aria-selected': 'false' } });
        var trCount = this.getRowCount();
        for (var i = 0; i < trCount; i++) {
            var ntr = tr.cloneNode();
            for (var _i = 0, _a = this.colLevels[this.colLevels.length - 1]; _i < _a.length; _i++) {
                var tdData = _a[_i];
                if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                    tdData = this.getResourceTdData(i, tdData);
                }
                var ntd = this.createContentTd(tdData, tdData, td);
                ntr.appendChild(ntd);
            }
            rows.push(ntr);
        }
        return rows;
    };
    TimelineViews.prototype.getContentTdClass = function (r) {
        return (r.first || !this.parent.activeViewOptions.timeScale.enable) ? [WORK_CELLS_CLASS] :
            [WORK_CELLS_CLASS, ALTERNATE_CELLS_CLASS];
    };
    TimelineViews.prototype.renderEvents = function () {
        this.timelineAppointment = new TimelineEvent(this.parent, this.parent.activeViewOptions.timeScale.enable ? 'hour' : 'day');
        this.timelineAppointment.renderAppointments();
        this.parent.notify(eventsLoaded, {});
    };
    TimelineViews.prototype.getAdjustedDate = function (date) {
        if (!this.parent.activeViewOptions.timeScale.enable) {
            return new Date(date.setHours(0, 0, 0, 0));
        }
        else {
            var timeSlots = this.colLevels[this.colLevels.length - 1];
            for (var i = 0; i < timeSlots.length; i++) {
                if (timeSlots[parseInt(i.toString(), 10)].date.getTime() > date.getTime()) {
                    return timeSlots[i - 1].date;
                }
            }
        }
        return null;
    };
    TimelineViews.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        if (this.timelineAppointment) {
            this.timelineAppointment.destroy();
            this.timelineAppointment = null;
        }
        _super.prototype.destroy.call(this);
    };
    return TimelineViews;
}(VerticalView));

var __extends$27 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/**
 * timeline month view
 */
var TimelineMonth = /** @__PURE__ @class */ (function (_super) {
    __extends$27(TimelineMonth, _super);
    function TimelineMonth(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-timeline-month-view';
        _this.isInverseTableSelect = true;
        _this.appointment = null;
        return _this;
    }
    TimelineMonth.prototype.getModuleName = function () {
        return 'timelineMonth';
    };
    TimelineMonth.prototype.onDataReady = function () {
        this.appointment = new TimelineEvent(this.parent, 'day');
        this.appointment.renderAppointments();
        this.parent.notify(eventsLoaded, {});
    };
    TimelineMonth.prototype.getLeftPanelElement = function () {
        return this.element.querySelector('.' + RESOURCE_COLUMN_WRAP_CLASS);
    };
    TimelineMonth.prototype.scrollTopPanel = function (target) {
        _super.prototype.scrollTopPanel.call(this, target);
        this.scrollHeaderLabels(target);
    };
    TimelineMonth.prototype.setContentHeight = function (content, leftPanelElement, height) {
        if (leftPanelElement) {
            leftPanelElement.style.height = formatUnit(height - this.getScrollXIndent(content));
        }
        content.style.height = formatUnit(height);
    };
    TimelineMonth.prototype.getDateSlots = function (renderDates, workDays) {
        var dateSlots = [];
        for (var _i = 0, renderDates_1 = renderDates; _i < renderDates_1.length; _i++) {
            var col = renderDates_1[_i];
            var classList$$1 = [HEADER_CELLS_CLASS];
            if (this.isCurrentDate(col)) {
                classList$$1.push(CURRENT_DAY_CLASS);
            }
            dateSlots.push({ date: col, type: 'dateHeader', className: classList$$1, colSpan: 1, workDays: workDays });
        }
        return dateSlots;
    };
    TimelineMonth.prototype.renderLeftIndent = function (tr) {
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            this.parent.resourceBase.renderResourceHeaderIndent(tr);
        }
    };
    TimelineMonth.prototype.renderContent = function () {
        var contentTr = createElement('tr');
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            var resTd = createElement('td');
            resTd.appendChild(this.parent.resourceBase.createResourceColumn());
            contentTr.appendChild(resTd);
        }
        var contentTd = createElement('td');
        this.element.querySelector('tbody').appendChild(contentTr);
        var wrap = createElement('div', { className: CONTENT_WRAP_CLASS });
        wrap.appendChild(this.renderContentArea());
        wrap.appendChild(this.createEventTable(this.getRowCount()));
        this.collapseRows(wrap);
        EventHandler.add(wrap, 'scroll', this.onContentScroll, this);
        contentTd.appendChild(wrap);
        if (this.parent.virtualScrollModule) {
            this.parent.virtualScrollModule.renderVirtualTrack(wrap);
        }
        contentTr.appendChild(contentTd);
    };
    TimelineMonth.prototype.getRowCount = function () {
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            return this.parent.resourceBase.renderedResources.length;
        }
        return 1;
    };
    TimelineMonth.prototype.getContentSlots = function () {
        var slotDatas = [];
        for (var row = 0; row < this.getRowCount(); row++) {
            for (var _i = 0, _a = this.colLevels[this.colLevels.length - 1]; _i < _a.length; _i++) {
                var data = _a[_i];
                data.className = [WORK_CELLS_CLASS];
                if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                    var resLevel = this.parent.resourceBase.renderedResources[parseInt(row.toString(), 10)];
                    data.workDays = resLevel.resourceData[resLevel.resource.workDaysField] || this.parent.workDays;
                    data.className = data.className.concat(resLevel.className);
                    data.groupIndex = resLevel.groupIndex;
                    data.groupOrder = resLevel.groupOrder;
                }
                var slotData = {
                    date: new Date(+data.date), colSpan: data.colSpan, groupIndex: data.groupIndex, workDays: data.workDays,
                    type: 'monthCells', className: data.className
                };
                if (!slotDatas[parseInt(row.toString(), 10)]) {
                    slotDatas[parseInt(row.toString(), 10)] = [];
                }
                slotDatas[parseInt(row.toString(), 10)].push(slotData);
            }
        }
        return slotDatas;
    };
    TimelineMonth.prototype.updateClassList = function (data) {
        if (!this.parent.isMinMaxDate(data.date)) {
            data.className.push(DISABLE_DATES);
        }
    };
    TimelineMonth.prototype.unWireEvents = function () {
        EventHandler.remove(this.getContentAreaElement(), 'scroll', this.onContentScroll);
    };
    TimelineMonth.prototype.getMonthStart = function (currentDate) {
        var monthStart = this.parent.calendarUtil.firstDateOfMonth(resetTime(currentDate));
        return new Date(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate());
    };
    TimelineMonth.prototype.getMonthEnd = function (currentDate) {
        var monthStart = this.parent.calendarUtil.firstDateOfMonth(resetTime(currentDate));
        return this.parent.calendarUtil.lastDateOfMonth(addMonths(new Date(+monthStart), this.parent.activeViewOptions.interval - 1));
    };
    TimelineMonth.prototype.generateColumnLevels = function () {
        var colLevels = [];
        var level = this.getDateSlots(this.renderDates, this.parent.activeViewOptions.workDays);
        colLevels.push(level);
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.parent.resourceBase.generateResourceLevels(level, !this.parent.uiStateValues.isGroupAdaptive);
        }
        var hourSlots = [];
        if (this.parent.activeViewOptions.headerRows.length > 0) {
            var renderGn = new TimelineHeaderRow(this.parent, this.renderDates);
            colLevels = renderGn.generateColumnLevels(level, hourSlots);
        }
        this.colLevels = colLevels;
        return colLevels;
    };
    TimelineMonth.prototype.getAdjustedDate = function (startTime) {
        var timeSlots = this.colLevels[this.colLevels.length - 1];
        for (var i = 0; i < timeSlots.length; i++) {
            if (timeSlots[parseInt(i.toString(), 10)].date.getTime() > startTime.getTime()) {
                return timeSlots[i - 1].date;
            }
        }
        return null;
    };
    TimelineMonth.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        if (this.element) {
            var contentScrollableEle = this.element.querySelector('.' + CONTENT_WRAP_CLASS);
            if (contentScrollableEle) {
                EventHandler.remove(contentScrollableEle, 'scroll', this.onContentScroll);
            }
        }
        if (this.appointment) {
            this.appointment.destroy();
            this.appointment = null;
        }
        _super.prototype.destroy.call(this);
    };
    return TimelineMonth;
}(Month));

var __extends$28 = (undefined && undefined.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * timeline year view
 */
var TimelineYear = /** @__PURE__ @class */ (function (_super) {
    __extends$28(TimelineYear, _super);
    function TimelineYear(parent) {
        var _this = _super.call(this, parent) || this;
        _this.viewClass = 'e-timeline-year-view';
        _this.isInverseTableSelect = true;
        return _this;
    }
    TimelineYear.prototype.getModuleName = function () {
        return 'timelineYear';
    };
    TimelineYear.prototype.renderHeader = function (headerWrapper) {
        var tr = createElement('tr');
        headerWrapper.appendChild(tr);
        if (this.parent.activeViewOptions.orientation === 'Vertical' && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.uiStateValues.isGroupAdaptive) {
            this.parent.resourceBase.renderResourceHeaderIndent(tr);
        }
        else {
            var leftHeaderCells = createElement('td', { className: LEFT_INDENT_CLASS });
            tr.appendChild(leftHeaderCells);
            leftHeaderCells.appendChild(this.renderResourceHeader(LEFT_INDENT_WRAP_CLASS));
        }
        var isHorizontal = this.parent.activeViewOptions.orientation === 'Horizontal';
        var isGroup = this.parent.activeViewOptions.group.resources.length > 0;
        this.isInverseTableSelect = isHorizontal && !isGroup ? false : true;
        var td = createElement('td');
        tr.appendChild(td);
        var container = createElement('div', { className: DATE_HEADER_CONTAINER_CLASS });
        td.appendChild(container);
        if (this.parent.activeViewOptions.orientation === 'Horizontal' && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.uiStateValues.isGroupAdaptive) {
            container.appendChild(this.renderResourceHeader(DATE_HEADER_WRAP_CLASS));
            this.columnCount = this.colLevels.slice(-1)[0].length;
        }
        else {
            var wrapper = createElement('div', { className: DATE_HEADER_WRAP_CLASS });
            container.appendChild(wrapper);
            var table = this.createTableLayout();
            wrapper.appendChild(table);
            table.appendChild(this.createTableColGroup(this.columnCount));
            var innerTr = createElement('tr');
            table.querySelector('tbody').appendChild(innerTr);
            var months = this.getMonths();
            var dayHeaderDates = this.getMonthDates(new Date(this.parent.selectedDate.getFullYear(), months[0], 1));
            for (var column = 0; column < this.columnCount; column++) {
                var date = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(column.toString(), 10)], 1);
                var innerTd = createElement('td', { className: HEADER_CELLS_CLASS });
                if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                    if (this.parent.dayHeaderTemplate) {
                        append(this.renderDayMonthHeaderTemplate(dayHeaderDates[parseInt(column.toString(), 10)], column, 'dayHeaderTemplate'), innerTd);
                    }
                    else {
                        innerTd.innerHTML = "<span>" + this.parent.getDayNames('abbreviated')[column % 7] + "</span>";
                    }
                }
                else {
                    if (this.parent.monthHeaderTemplate) {
                        append(this.renderDayMonthHeaderTemplate(date, months[parseInt(column.toString(), 10)], 'monthHeaderTemplate'), innerTd);
                    }
                    else {
                        innerTd.innerHTML = "<span>" + this.getMonthName(date) + "</span>";
                    }
                    innerTd.setAttribute('data-date', date.getTime().toString());
                }
                innerTr.appendChild(innerTd);
                this.parent.trigger(renderCell, { elementType: 'headerCells', element: innerTd });
            }
        }
    };
    TimelineYear.prototype.renderResourceHeader = function (className) {
        var wrap = createElement('div', { className: className });
        var tbl = this.createTableLayout();
        wrap.appendChild(tbl);
        var trEle = createElement('tr');
        if (this.parent.activeViewOptions.group.resources.length > 0) {
            this.colLevels = this.generateColumnLevels();
        }
        else {
            var colData = [{ className: [HEADER_CELLS_CLASS], type: 'headerCell' }];
            this.colLevels = [colData];
        }
        for (var _i = 0, _a = this.colLevels; _i < _a.length; _i++) {
            var col = _a[_i];
            var ntr = trEle.cloneNode();
            var count = className === DATE_HEADER_WRAP_CLASS ? col : [col[0]];
            for (var _b = 0, count_1 = count; _b < count_1.length; _b++) {
                var c = count_1[_b];
                var tdEle = createElement('td');
                if (c.className) {
                    addClass([tdEle], c.className);
                }
                if (className === DATE_HEADER_WRAP_CLASS) {
                    if (c.template) {
                        append(c.template, tdEle);
                    }
                    if (c.colSpan) {
                        tdEle.setAttribute('colspan', c.colSpan.toString());
                    }
                    if (c.groupIndex > -1) {
                        tdEle.setAttribute('data-group-index', c.groupIndex.toString());
                    }
                    this.setResourceHeaderContent(tdEle, c);
                }
                if (className === LEFT_INDENT_WRAP_CLASS) {
                    this.parent.renderHeaderIndentTemplate(c, tdEle);
                }
                var args = { elementType: c.type, element: tdEle, date: c.date, groupIndex: c.groupIndex };
                this.parent.trigger(renderCell, args);
                ntr.appendChild(tdEle);
            }
            tbl.querySelector('tbody').appendChild(ntr);
        }
        if (className === DATE_HEADER_WRAP_CLASS) {
            tbl.appendChild(this.createTableColGroup(this.colLevels.slice(-1)[0].length));
        }
        return wrap;
    };
    TimelineYear.prototype.renderContent = function (contentWrapper) {
        var tr = createElement('tr');
        contentWrapper.appendChild(tr);
        var firstTd = createElement('td');
        var lastTd = createElement('td');
        var tdCollection = [];
        var monthTBody;
        if (this.parent.activeViewOptions.orientation === 'Vertical' && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.uiStateValues.isGroupAdaptive) {
            tdCollection.push(firstTd);
            firstTd.appendChild(this.parent.resourceBase.createResourceColumn());
            this.rowCount = this.parent.resourceBase.renderedResources.length;
        }
        else {
            tdCollection.push(firstTd);
            var monthWrapper = createElement('div', { className: MONTH_HEADER_WRAPPER });
            firstTd.appendChild(monthWrapper);
            monthWrapper.appendChild(this.createTableLayout());
            monthTBody = monthWrapper.querySelector('tbody');
        }
        tdCollection.push(lastTd);
        append(tdCollection, tr);
        var content = createElement('div', { className: CONTENT_WRAP_CLASS });
        lastTd.appendChild(content);
        var contentTable = this.createTableLayout(CONTENT_TABLE_CLASS);
        this.setAriaAttributes(contentTable);
        content.appendChild(contentTable);
        var eventWrapper = createElement('div', { className: EVENT_TABLE_CLASS });
        content.appendChild(eventWrapper);
        if (this.parent.virtualScrollModule) {
            this.parent.virtualScrollModule.renderVirtualTrack(content);
        }
        var contentTBody = contentTable.querySelector('tbody');
        if (this.parent.rowAutoHeight) {
            var addClassTable = [contentTable];
            var monthHeader = this.parent.element.querySelector('.' + MONTH_HEADER_WRAPPER + ' .' + SCHEDULE_TABLE_CLASS);
            if (monthHeader) {
                addClassTable.push(monthHeader);
            }
            addClass(addClassTable, AUTO_HEIGHT);
        }
        if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
            var colCount = this.parent.activeViewOptions.orientation === 'Horizontal' ? this.colLevels.slice(-1)[0].length : this.columnCount;
            contentTable.appendChild(this.createTableColGroup(colCount));
            this.renderResourceContent(eventWrapper, monthTBody, contentTBody);
        }
        else {
            contentTable.appendChild(this.createTableColGroup(this.columnCount));
            this.renderDefaultContent(eventWrapper, monthTBody, contentTBody);
        }
    };
    TimelineYear.prototype.renderDefaultContent = function (wrapper, monthBody, contentBody) {
        var months = this.getMonths();
        var dayHeaderDates = this.getMonthDates(new Date(this.parent.selectedDate.getFullYear(), months[0], 1));
        for (var month = 0; month < this.rowCount; month++) {
            wrapper.appendChild(createElement('div', { className: APPOINTMENT_CONTAINER_CLASS }));
            var monthDate = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(month.toString(), 10)], 1);
            var monthStart = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
            var monthEnd = this.parent.calendarUtil.getMonthEndDate(new Date(monthDate.getTime()));
            var tr = createElement('tr');
            var monthTr = tr.cloneNode();
            monthBody.appendChild(monthTr);
            var contentTr = tr.cloneNode();
            contentBody.appendChild(contentTr);
            var monthTd = createElement('td', { className: MONTH_HEADER_CLASS });
            if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                if (this.parent.monthHeaderTemplate) {
                    append(this.renderDayMonthHeaderTemplate(monthStart, month, 'monthHeaderTemplate'), monthTd);
                }
                else {
                    monthTd.innerHTML = "<span>" + this.getMonthName(monthDate) + "</span>";
                }
                monthTd.setAttribute('data-date', monthDate.getTime().toString());
            }
            else {
                if (this.parent.dayHeaderTemplate) {
                    append(this.renderDayMonthHeaderTemplate(dayHeaderDates[parseInt(month.toString(), 10)], month, 'dayHeaderTemplate'), monthTd);
                }
                else {
                    monthTd.innerHTML = "<span>" + this.parent.getDayNames('abbreviated')[month % 7] + "</span>";
                }
            }
            monthTr.appendChild(monthTd);
            this.parent.trigger(renderCell, { elementType: 'leftHeaderCells', element: monthTd });
            var date = new Date(monthStart.getTime());
            for (var column = 0; column < this.columnCount; column++) {
                var isDateAvail = void 0;
                if (this.parent.activeViewOptions.orientation === 'Vertical') {
                    monthDate = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(column.toString(), 10)], 1);
                    monthStart = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
                    monthEnd = this.parent.calendarUtil.getMonthEndDate(new Date(monthDate.getTime()));
                    var dayDate = (month - monthStart.getDay()) + 1;
                    date = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(column.toString(), 10)], dayDate);
                    isDateAvail = dayDate > 0 && date.getTime() < monthEnd.getTime();
                }
                else {
                    isDateAvail = column >= monthStart.getDay() && date.getTime() < monthEnd.getTime();
                }
                var announcementText = this.parent.globalize.formatDate(date, {
                    skeleton: 'full', calendar: this.parent.getCalendarMode()
                });
                var td = createElement('td', {
                    className: WORK_CELLS_CLASS, attrs: { 'aria-selected': 'false', 'aria-label': announcementText }
                });
                contentTr.appendChild(td);
                var dateHeader = createElement('div', {
                    className: DATE_HEADER_CLASS + ' ' + NAVIGATE_CLASS,
                    innerHTML: (isDateAvail) ?
                        this.parent.globalize.formatDate(date, { skeleton: 'd', calendar: this.parent.getCalendarMode() }) : ''
                });
                if (isDateAvail) {
                    var tds = [td];
                    var classList$$1 = [];
                    if (this.parent.activeViewOptions.workDays.indexOf(date.getDay()) > -1) {
                        classList$$1.push(WORKDAY_CLASS);
                    }
                    if (!this.parent.isMinMaxDate(date)) {
                        addClass([td], DISABLE_DATES);
                    }
                    if (this.isCurrentDate(date)) {
                        classList$$1.push(CURRENT_DAY_CLASS);
                        if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                            tds.push(this.element.querySelector('.' + HEADER_CELLS_CLASS + (":nth-child(" + (column + 1) + ")")));
                        }
                        else {
                            tds.push(this.element.querySelectorAll('.' + MONTH_HEADER_CLASS).item(month));
                        }
                    }
                    if (classList$$1.length > 0) {
                        addClass(tds, classList$$1);
                    }
                }
                else {
                    addClass([td], OTHERMONTH_CLASS);
                    if (!this.parent.isMinMaxDate(date)) {
                        addClass([td], DISABLE_DATES);
                    }
                }
                if (td.classList.contains(OTHERMONTH_CLASS)) {
                    continue;
                }
                else {
                    this.renderDates.push(new Date(date));
                }
                td.appendChild(dateHeader);
                if (isDateAvail) {
                    td.setAttribute('data-date', date.getTime().toString());
                    this.wireEvents(td);
                }
                this.renderCellTemplate({ date: date, type: 'workCells' }, td);
                this.parent.trigger(renderCell, { elementType: 'workCells', element: td, date: date });
                if (isDateAvail) {
                    if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                        date = addDays(new Date(date.getTime()), 1);
                    }
                }
            }
        }
        this.renderDates.splice(0, 1);
    };
    TimelineYear.prototype.getContentRows = function () {
        var tRow = [];
        var monthCells = this.getMonths();
        for (var row = 0; row < this.parent.resourceBase.renderedResources.length; row++) {
            var tr = createElement('tr');
            tRow.push(tr);
            var resData = void 0;
            if (this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                resData = this.parent.resourceBase.renderedResources[parseInt(row.toString(), 10)];
            }
            var monthDate = new Date(this.parent.selectedDate.getFullYear(), monthCells[parseInt(row.toString(), 10)], 1);
            var date = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
            for (var month = 0; month < this.columnCount; month++) {
                var classList$$1 = [];
                var groupIndex = resData.groupIndex;
                classList$$1 = classList$$1.concat(resData.className);
                if (classList$$1.indexOf(RESOURCE_PARENT_CLASS) > -1) {
                    classList$$1.push(RESOURCE_GROUP_CELLS_CLASS);
                }
                else {
                    classList$$1.push(WORKDAY_CLASS);
                }
                monthDate = new Date(this.parent.selectedDate.getFullYear(), monthCells[parseInt(month.toString(), 10)], 1);
                date = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
                var tdELe = createElement('td', {
                    className: WORK_CELLS_CLASS,
                    attrs: {
                        'aria-selected': 'false',
                        'data-date': date.getTime().toString()
                    }
                });
                addClass([tdELe], classList$$1);
                tdELe.setAttribute('data-group-index', groupIndex.toString());
                this.renderCellTemplate({ date: date, type: 'resourceGroupCells', groupIndex: groupIndex }, tdELe);
                this.wireEvents(tdELe);
                this.parent.trigger(renderCell, { elementType: 'resourceGroupCells', element: tdELe, date: date });
                tr.appendChild(tdELe);
            }
        }
        return tRow;
    };
    TimelineYear.prototype.renderResourceContent = function (wrapper, monthBody, contentBody) {
        var months = this.getMonths();
        for (var row = 0; row < this.rowCount; row++) {
            wrapper.appendChild(createElement('div', { className: APPOINTMENT_CONTAINER_CLASS }));
            var tr = createElement('tr');
            contentBody.appendChild(tr);
            var resData = void 0;
            if (this.parent.activeViewOptions.orientation === 'Vertical' && this.parent.activeViewOptions.group.resources.length > 0 && !this.parent.uiStateValues.isGroupAdaptive) {
                resData = this.parent.resourceBase.renderedResources[parseInt(row.toString(), 10)];
            }
            var monthDate = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(row.toString(), 10)], 1);
            var date = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
            if (this.parent.activeViewOptions.orientation === 'Horizontal') {
                var monthTr = tr.cloneNode();
                monthBody.appendChild(monthTr);
                var monthTd = createElement('td', {
                    className: MONTH_HEADER_CLASS,
                    attrs: { 'data-date': date.getTime().toString() }
                });
                if (this.parent.monthHeaderTemplate) {
                    append(this.renderDayMonthHeaderTemplate(monthDate, row, 'monthHeaderTemplate'), monthTd);
                }
                else {
                    monthTd.innerHTML = "<span>" + this.getMonthName(monthDate) + "</span>";
                }
                monthTr.appendChild(monthTd);
            }
            for (var month = 0; month < this.columnCount; month++) {
                var classList$$1 = [];
                var groupIndex = void 0;
                if (this.parent.activeViewOptions.orientation === 'Vertical') {
                    groupIndex = resData.groupIndex;
                    classList$$1 = classList$$1.concat(resData.className);
                    if (classList$$1.indexOf(RESOURCE_PARENT_CLASS) > -1) {
                        classList$$1.push(RESOURCE_GROUP_CELLS_CLASS);
                    }
                    else {
                        classList$$1.push(WORKDAY_CLASS);
                    }
                    monthDate = new Date(this.parent.selectedDate.getFullYear(), months[parseInt(month.toString(), 10)], 1);
                    date = this.parent.calendarUtil.getMonthStartDate(new Date(monthDate.getTime()));
                }
                else {
                    groupIndex = this.colLevels.slice(-1)[0][parseInt(month.toString(), 10)].groupIndex;
                    classList$$1.push(WORKDAY_CLASS);
                }
                var td = createElement('td', {
                    className: WORK_CELLS_CLASS,
                    attrs: {
                        'aria-selected': 'false',
                        'data-date': date.getTime().toString()
                    }
                });
                addClass([td], classList$$1);
                td.setAttribute('data-group-index', groupIndex.toString());
                this.renderCellTemplate({ date: date, type: 'resourceGroupCells', groupIndex: groupIndex }, td);
                this.wireEvents(td);
                tr.appendChild(td);
                this.parent.trigger(renderCell, { elementType: 'resourceGroupCells', element: td, date: date });
            }
        }
        if (this.parent.activeViewOptions.orientation === 'Vertical') {
            this.collapseRows(this.parent.element.querySelector('.' + CONTENT_WRAP_CLASS));
        }
    };
    TimelineYear.prototype.renderDayMonthHeaderTemplate = function (date, column, type) {
        var args = { date: date, type: type === 'dayHeaderTemplate' ? 'dayHeader' : 'monthHeader' };
        var dayId = "schedule_" + this.parent.activeViewOptions.dayHeaderTemplateName + "dayHeaderTemplate";
        var monthId = "schedule_" + this.parent.activeViewOptions.dayHeaderTemplateName + "monthHeaderTemplate";
        if (type === 'dayHeaderTemplate') {
            args.day = this.parent.getDayNames('wide')[column % 7];
            return [].slice.call(this.parent.getDayHeaderTemplate()(args, this.parent, 'dayHeaderTemplate', dayId, false));
        }
        else {
            return [].slice.call(this.parent.getMonthHeaderTemplate()(args, this.parent, 'monthHeaderTemplate', monthId, false));
        }
    };
    TimelineYear.prototype.renderCellTemplate = function (data, td) {
        if (!this.parent.activeViewOptions.cellTemplate || td.classList.contains(OTHERMONTH_CLASS)) {
            return;
        }
        var args = { date: data.date, type: data.type };
        if (data.groupIndex) {
            args.groupIndex = data.groupIndex;
        }
        var scheduleId = this.parent.element.id + '_';
        var viewName = this.parent.activeViewOptions.cellTemplateName;
        var templateId = scheduleId + viewName + 'cellTemplate';
        var cellTemplate = [].slice.call(this.parent.getCellTemplate()(args, this.parent, 'cellTemplate', templateId, false));
        append(cellTemplate, td);
    };
    TimelineYear.prototype.scrollToDate = function (scrollDate) {
        var date;
        if (this.parent.activeViewOptions.group.resources !== null && this.parent.activeViewOptions.group.resources.length > 0 &&
            !this.parent.uiStateValues.isGroupAdaptive) {
            date = +new Date(resetTime(firstDateOfMonth(scrollDate)));
        }
        else {
            date = +new Date(resetTime(scrollDate));
        }
        var element = this.element.querySelector('[data-date="' + date + '"]');
        if (element) {
            var wrap = this.getScrollableElement();
            if (this.parent.enableRtl) {
                var conTable = this.element.querySelector('.' + CONTENT_TABLE_CLASS);
                wrap.scrollLeft = -(conTable.offsetWidth - element.offsetLeft - element.offsetWidth);
            }
            else {
                wrap.scrollLeft = element.offsetLeft;
            }
            wrap.scrollTop = element.offsetTop;
        }
    };
    TimelineYear.prototype.getScrollableElement = function () {
        if (this.parent.isAdaptive && !this.isTimelineView()) {
            return this.element.querySelector('.' + SCROLL_CONTAINER_CLASS);
        }
        else {
            return this.getContentAreaElement();
        }
    };
    TimelineYear.prototype.wireEvents = function (element) {
        EventHandler.add(element, 'mousedown', this.parent.workCellAction.cellMouseDown, this.parent.workCellAction);
        EventHandler.add(element, 'click', this.parent.workCellAction.cellClick, this.parent.workCellAction);
        if (!this.parent.isAdaptive) {
            EventHandler.add(element, 'dblclick', this.parent.workCellAction.cellDblClick, this.parent.workCellAction);
        }
    };
    return TimelineYear;
}(Year));

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * ICalendar Export Module
 */
var ICalendarExport = /** @__PURE__ @class */ (function () {
    function ICalendarExport(parent) {
        this.parent = parent;
    }
    ICalendarExport.prototype.initializeCalendarExport = function (fileName, customData) {
        var icsString = this.getCalendarString(fileName, customData);
        this.download(icsString, fileName);
    };
    ICalendarExport.prototype.getCalendarString = function (fileName, customData) {
        var _this = this;
        var eventsData = (customData) ? customData :
            extend([], this.parent.eventsData, null, true);
        eventsData = this.parent.eventBase.sortByTime(eventsData);
        var SEPARATOR = (navigator.appVersion.indexOf('Win') !== -1) ? '\r\n' : '\n';
        var iCalendarEvents = [];
        var filterCollection = [];
        var timeZone = this.parent.timezone || this.parent.tzModule.getLocalTimezoneName();
        var fields = this.parent.eventFields;
        eventsData.forEach(function (eventObj) {
            var uId = eventObj[fields.id] || eventObj.Guid || _this.parent.eventBase.generateGuid();
            var editedExDate = [];
            if (eventObj[fields.recurrenceID]) {
                var filter = _this.filterEvents(filterCollection, fields.id, eventObj[fields.recurrenceID]);
                uId = filter.length > 0 ? filter[0].UID : uId;
            }
            if (!eventObj[fields.recurrenceID] && eventObj[fields.recurrenceRule] && eventObj[fields.recurrenceException]) {
                var exDate = (eventObj[fields.recurrenceException]).split(',');
                var editedObj = _this.filterEvents(eventsData, fields.recurrenceID, eventObj[fields.id]);
                editedObj.forEach(function (edited) {
                    editedExDate.push(getRecurrenceStringFromDate(edited[fields.startTime]));
                });
                var exceptionDateList = exDate.filter(function (value) { return editedExDate.indexOf(value) === -1; });
                eventObj[fields.recurrenceException] = (exceptionDateList.length > 0) ? (exceptionDateList.join(',') + ',') : '';
            }
            var startZone = (eventObj[fields.startTimezone] || timeZone);
            var endZone = (eventObj[fields.endTimezone] || timeZone);
            var readonly = (eventObj[fields.isReadonly]) ? (eventObj[fields.isReadonly]) : false;
            var calendarEvent = [
                'BEGIN:VEVENT',
                'LOCATION:' + (eventObj[fields.location] || ''),
                'SUMMARY:' + (eventObj[fields.subject] || ''),
                'UID:' + uId,
                'DESCRIPTION:' + (eventObj[fields.description] || ''),
                'ISREADONLY:' + readonly,
                'END:VEVENT'
            ];
            if (eventObj[fields.isAllDay]) {
                calendarEvent.splice(4, 0, 'DTEND;VALUE=DATE:' + _this.convertDateToString(eventObj[fields.endTime], true));
                calendarEvent.splice(4, 0, 'DTSTART;VALUE=DATE:' + _this.convertDateToString(eventObj[fields.startTime], true));
            }
            else if (!eventObj[fields.isAllDay] && !eventObj[fields.recurrenceRule]) {
                calendarEvent.splice(4, 0, 'DTEND:' + _this.convertDateToString(eventObj[fields.endTime]));
                calendarEvent.splice(4, 0, 'DTSTART:' + _this.convertDateToString(eventObj[fields.startTime]));
            }
            else {
                calendarEvent.splice(4, 0, 'DTEND;TZID="' + endZone + '":' + _this.convertDateToString(eventObj[fields.endTime]));
                calendarEvent.splice(4, 0, 'DTSTART;TZID="' + startZone + '":'
                    + _this.convertDateToString(eventObj[fields.startTime]));
            }
            if (eventObj[fields.recurrenceRule]) {
                calendarEvent.splice(4, 0, 'RRULE:' + eventObj[fields.recurrenceRule]);
            }
            if (eventObj[fields.recurrenceException]) {
                var exDate = eventObj[fields.recurrenceException].split(',');
                for (var i = 0; i < exDate.length - 1; i++) {
                    calendarEvent.splice(5, 0, 'EXDATE:' +
                        _this.convertDateToString(getDateFromRecurrenceDateString(exDate[parseInt(i.toString(), 10)]), eventObj[fields.isAllDay]));
                }
            }
            if (eventObj[fields.recurrenceID]) {
                calendarEvent.splice(4, 0, 'RECURRENCE-ID;TZID="' + startZone + '":'
                    + _this.convertDateToString(eventObj[fields.startTime], eventObj[fields.isAllDay]));
            }
            var customFields = _this.customFieldFilter(eventObj, fields);
            if (customFields.length > 0) {
                customFields.forEach(function (customField) {
                    return calendarEvent.splice(4, 0, customField + ':' + (eventObj["" + customField] || ''));
                });
            }
            var app = extend({}, eventObj);
            app.UID = uId;
            filterCollection.push(app);
            iCalendarEvents.push(calendarEvent.join(SEPARATOR));
        });
        var iCalendar = [
            'BEGIN:VCALENDAR',
            'PRODID:-//Syncfusion Inc//Scheduler//EN',
            'VERSION:2.0',
            'CALSCALE:GREGORIAN',
            'METHOD:PUBLISH',
            'X-WR-CALNAME:' + (fileName || 'Calendar'),
            'X-WR-TIMEZONE:' + timeZone
        ].join(SEPARATOR);
        var icsString = iCalendar + SEPARATOR + iCalendarEvents.join(SEPARATOR) + SEPARATOR + 'END:VCALENDAR';
        return icsString;
    };
    ICalendarExport.prototype.customFieldFilter = function (eventObj, fields) {
        var defaultFields = Object.keys(fields).map(function (key) { return fields["" + key]; });
        var eventFields = Object.keys(eventObj);
        return eventFields.filter(function (value) { return (defaultFields.indexOf(value) === -1) && (value !== 'Guid'); });
    };
    ICalendarExport.prototype.convertDateToString = function (eventDate, allDay) {
        var year = ('0000' + (eventDate.getFullYear().toString())).slice(-4);
        var month = ('00' + ((eventDate.getMonth() + 1).toString())).slice(-2);
        var date = ('00' + ((eventDate.getDate()).toString())).slice(-2);
        var hours = ('00' + (eventDate.getHours().toString())).slice(-2);
        var minutes = ('00' + (eventDate.getMinutes().toString())).slice(-2);
        var seconds = ('00' + (eventDate.getSeconds().toString())).slice(-2);
        var timeString = (allDay) ? year + month + date : year + month + date + 'T' + hours + minutes + seconds;
        return timeString;
    };
    ICalendarExport.prototype.download = function (icsString, fileName) {
        var buffer = new Blob([icsString], { type: 'data:text/calendar;charset=utf8' });
        fileName = (fileName || 'Calendar') + '.ics';
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(buffer, fileName);
        }
        else {
            var downloadLink_1 = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
            downloadLink_1.download = fileName;
            downloadLink_1.href = URL.createObjectURL(buffer);
            var event_1 = document.createEvent('MouseEvent');
            event_1.initEvent('click', true, true);
            downloadLink_1.dispatchEvent(event_1);
            setTimeout(function () {
                URL.revokeObjectURL(downloadLink_1.href);
                downloadLink_1.href = undefined;
            });
        }
    };
    ICalendarExport.prototype.filterEvents = function (data, field, value) {
        return data.filter(function (e) { return e["" + field] === value; });
    };
    ICalendarExport.prototype.getModuleName = function () {
        return 'iCalendarExport';
    };
    ICalendarExport.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
        this.parent = null;
    };
    return ICalendarExport;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * ICalendar Import Module
 */
var ICalendarImport = /** @__PURE__ @class */ (function () {
    function ICalendarImport(parent) {
        this.allDay = false;
        this.parent = parent;
    }
    ICalendarImport.prototype.initializeCalendarImport = function (fileContent) {
        var _this = this;
        if (fileContent && fileContent instanceof Blob) {
            var fileReader_1 = new FileReader();
            fileReader_1.onload = function () {
                var iCalString = fileReader_1.result;
                _this.iCalendarParser(iCalString);
            };
            fileReader_1.readAsText(fileContent, 'UTF-8');
        }
        else if (fileContent && typeof fileContent === 'string') {
            this.iCalendarParser(fileContent);
        }
    };
    ICalendarImport.prototype.iCalendarParser = function (iCalString) {
        var iCalData = {
            isEvent: false,
            curEvent: null,
            id: this.parent.eventBase.getEventMaxID(),
            count: 0,
            events: [],
            key: null
        };
        var iStringLength = iCalString.length;
        var lastPosition = iCalString.search(/[^ \t]/);
        var position = lastPosition;
        var iString;
        var newlineOffset;
        do {
            position = iCalString.indexOf('\n', lastPosition) + 1;
            if (position === 0) {
                position = iStringLength;
                newlineOffset = 0;
            }
            else if (position > 1 && iCalString[position - 2] === '\r') {
                newlineOffset = 2;
            }
            else {
                newlineOffset = 1;
            }
            // eslint-disable-next-line security/detect-object-injection
            var firstChar = iCalString[lastPosition];
            if (firstChar === ' ' || firstChar === '\n' || firstChar === '\t') {
                iString += iCalString.slice(lastPosition + 1, position - newlineOffset);
            }
            else {
                if (iString) {
                    iCalData = this.updateEventData(iString, iCalData);
                }
                iString = iCalString.slice(lastPosition, position - newlineOffset);
            }
            lastPosition = position;
        } while (position !== iStringLength);
        iString = iString.trim();
        if (iString.length) {
            iCalData = this.updateEventData(iString, iCalData);
        }
        var app = extend([], iCalData.events, null, true);
        this.parent.addEvent(this.processOccurrence(app, iCalData.id));
    };
    ICalendarImport.prototype.updateEventData = function (iString, iCalData) {
        var fields = this.parent.eventFields;
        var SEPARATOR = '\r\n';
        var id = iCalData.id;
        var events = iCalData.events;
        var isEvent = iCalData.isEvent;
        var count = iCalData.count;
        var curEvent = iCalData.curEvent;
        var key = iCalData.key;
        if (!isEvent && iString === 'BEGIN:VEVENT') {
            isEvent = true;
            curEvent = {};
        }
        if (isEvent && iString === 'END:VEVENT') {
            isEvent = false;
            events.push(curEvent);
            curEvent = null;
        }
        if (isEvent) {
            var index = iString.indexOf(':');
            var type_1 = iString.substring(0, index).replace(/^\s\s*/, '').replace(/\s\s*$/, '');
            var value = iString.substring(index + 1, iString.length).replace(/^\s\s*/, '').replace(/\s\s*$/, '');
            if (iString.indexOf('SUMMARY') !== -1) {
                type_1 = 'SUMMARY';
            }
            if (iString.indexOf('DTSTART') !== -1) {
                curEvent[fields.startTime] = this.dateParsing(iString);
                curEvent[fields.isAllDay] = this.allDay;
                this.allDay = false;
            }
            else if (iString.indexOf('DTEND') !== -1) {
                curEvent[fields.endTime] = this.dateParsing(iString);
            }
            else if (iString.indexOf('EXDATE') !== -1) {
                value = getRecurrenceStringFromDate(this.dateParsing(iString));
                curEvent[fields.recurrenceException] = isNullOrUndefined(curEvent[fields.recurrenceException]) ?
                    value : curEvent[fields.recurrenceException] + ',' + value;
            }
            else if (iString.indexOf('RECURRENCE-ID') !== -1) {
                value = getRecurrenceStringFromDate(this.dateParsing(iString));
                curEvent[fields.recurrenceException] = value;
                curEvent[fields.recurrenceID] = value;
            }
            else {
                key = type_1 || key;
                switch (key) {
                    case 'BEGIN':
                        break;
                    case 'UID':
                        curEvent["" + type_1] = value;
                        if (typeof (id) == 'number') {
                            curEvent[fields.id] = parseInt(value, 10);
                            if (isNaN(curEvent[fields.id])) {
                                curEvent[fields.id] = id + count;
                                count++;
                            }
                        }
                        else {
                            curEvent[fields.id] = value;
                        }
                        break;
                    case 'SUMMARY':
                        curEvent[fields.subject] = this.getFormattedString(value);
                        break;
                    case 'LOCATION':
                        curEvent[fields.location] = this.getFormattedString(value);
                        break;
                    case 'DESCRIPTION':
                        if (curEvent[fields.description]) {
                            curEvent[fields.description] = this.getFormattedString(curEvent[fields.description] + SEPARATOR + value);
                        }
                        else {
                            curEvent[fields.description] = this.getFormattedString(value);
                        }
                        break;
                    case 'ISREADONLY':
                        curEvent[fields.isReadonly] = (value.indexOf('true') > -1);
                        break;
                    case 'RRULE':
                        curEvent[fields.recurrenceRule] = value;
                        break;
                    default:
                        if (this.parent.resourceCollection.length > 0) {
                            var resData = this.parent.resourceCollection.filter(function (data) { return data.field === type_1; });
                            curEvent["" + type_1] = (resData.length > 0 && (typeof (resData[0].dataSource[0][resData[0].idField]) == 'number')) ? parseInt(value, 10) : value;
                        }
                        else {
                            curEvent["" + type_1] = value;
                        }
                }
            }
        }
        return { isEvent: isEvent, curEvent: curEvent, id: id, count: count, events: events, key: key };
    };
    ICalendarImport.prototype.processOccurrence = function (app, maxId) {
        var _this = this;
        var appoint = [];
        var uId = 'UID';
        var fields = this.parent.eventFields;
        var appointmentIds = [];
        this.parent.eventsData.forEach(function (eventObj) {
            appointmentIds.push(eventObj[fields.id]);
        });
        app.forEach(function (eventObj) {
            var parentObj;
            var id;
            // eslint-disable-next-line no-prototype-builtins
            if (!eventObj.hasOwnProperty(fields.recurrenceID)) {
                parentObj = eventObj;
                id = eventObj[fields.id];
            }
            if (appointmentIds.indexOf(eventObj[fields.id]) < 0) {
                var data = app.filter(function (data) { return data.UID === eventObj["" + uId]; });
                if (data.length > 1 && isNullOrUndefined(eventObj[fields.recurrenceID])) {
                    id = typeof (maxId) === 'number' ? maxId++ : id;
                    for (var i = 0; i < data.length; i++) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (data[parseInt(i.toString(), 10)].hasOwnProperty(fields.recurrenceID)) {
                            var exdate = data[parseInt(i.toString(), 10)][fields.recurrenceID];
                            data[parseInt(i.toString(), 10)][fields.id] = typeof (maxId) === 'number' ? maxId++ : _this.parent.eventBase.generateGuid();
                            data[parseInt(i.toString(), 10)][fields.recurrenceID] = id;
                            data[parseInt(i.toString(), 10)][fields.recurrenceException] = null;
                            parentObj[fields.recurrenceException] =
                                _this.getExcludeDateString(parentObj[fields.recurrenceException], exdate);
                            delete data[parseInt(i.toString(), 10)]["" + uId];
                            appoint.push(data[parseInt(i.toString(), 10)]);
                        }
                    }
                    delete parentObj["" + uId];
                    parentObj[fields.id] = id;
                    appoint.push(parentObj);
                    // eslint-disable-next-line no-prototype-builtins
                }
                else if (!eventObj.hasOwnProperty(fields.recurrenceID)) {
                    delete eventObj["" + uId];
                    eventObj[fields.id] = typeof (maxId) === 'number' ? maxId++ : id;
                    appoint.push(eventObj);
                }
            }
        });
        return appoint;
    };
    ICalendarImport.prototype.getExcludeDateString = function (parentException, occurrenceException) {
        if (isNullOrUndefined(parentException)) {
            return occurrenceException;
        }
        else if (isNullOrUndefined(occurrenceException)) {
            return parentException;
        }
        var parentExDate = parentException.split(',').map(function (x) { return x.split('T')[0]; });
        var childExDate = occurrenceException.split(',').map(function (x) { return x.split('T')[0]; });
        var exDate = parentExDate.filter(function (x) { return childExDate.indexOf(x) > -1; });
        if (exDate.length > 0) {
            return parentException;
        }
        return parentException + ',' + occurrenceException;
    };
    ICalendarImport.prototype.getFormattedString = function (value) {
        value = value || '';
        // eslint-disable-next-line no-useless-escape
        return (value.replace(/\\\,/g, ',').replace(/\\\;/g, ';').replace(/\\[nN]/g, '\n').replace(/\\\\/g, '\\'));
    };
    ICalendarImport.prototype.dateParsing = function (element) {
        var split = element.split(':');
        var value = split[split.length - 1];
        var newDate = new Date(this.getFormattedString(value));
        if (element && (element.indexOf('VALUE=DATE') > -1 || element.indexOf('RECURRENCE-ID;TZID') > -1)) {
            var data_1 = /^(\d{4})(\d{2})(\d{2})$/.exec(value);
            if (data_1 !== null) {
                newDate = new Date(parseInt(data_1[1], 10), parseInt(data_1[2], 10) - 1, parseInt(data_1[3], 10));
            }
            if (element.indexOf('DTSTART') > -1) {
                this.allDay = true;
            }
        }
        var data = /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})(Z)?$/.exec(value);
        if (data !== null) {
            if (data[7] === 'Z') {
                newDate = new Date(Date.UTC(parseInt(data[1], 10), parseInt(data[2], 10) - 1, parseInt(data[3], 10), parseInt(data[4], 10), parseInt(data[5], 10), parseInt(data[6], 10)));
            }
            else {
                newDate = new Date(parseInt(data[1], 10), parseInt(data[2], 10) - 1, parseInt(data[3], 10), parseInt(data[4], 10), parseInt(data[5], 10), parseInt(data[6], 10));
            }
        }
        return newDate;
    };
    ICalendarImport.prototype.getModuleName = function () {
        return 'iCalendarImport';
    };
    ICalendarImport.prototype.destroy = function () {
        if (!this.parent || this.parent && this.parent.isDestroyed) {
            return;
        }
    };
    return ICalendarImport;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Excel Export Module
 */
var ExcelExport = /** @__PURE__ @class */ (function () {
    function ExcelExport(parent) {
        this.parent = parent;
    }
    ExcelExport.prototype.initializeExcelExport = function (excelExportOptions) {
        if (excelExportOptions === void 0) { excelExportOptions = {}; }
        var exportColumns = this.getExportColumns(excelExportOptions);
        var exportName = excelExportOptions.fileName || 'Schedule';
        var exportType = excelExportOptions.exportType || 'xlsx';
        var isIncludeOccurrences = excelExportOptions.includeOccurrences || false;
        var separator;
        if (!isNullOrUndefined(excelExportOptions.separator) && excelExportOptions.separator !== ',') {
            separator = excelExportOptions.separator;
        }
        var eventCollection;
        if (excelExportOptions.customData) {
            eventCollection = !isIncludeOccurrences ? excelExportOptions.customData :
                this.parent.eventBase.getProcessedEvents(excelExportOptions.customData);
        }
        else {
            eventCollection = !isIncludeOccurrences ? this.parent.eventsData : this.parent.eventsProcessed;
        }
        this.processWorkbook(exportColumns, exportName, exportType, eventCollection, separator);
    };
    ExcelExport.prototype.processWorkbook = function (fields, name, type, eventCollection, separator) {
        var _this = this;
        var columns = [];
        var rows = [];
        var columnHeader = [];
        fields.forEach(function (field, i) { columns.push({ index: i + 1, width: (field.name === 'Id' ? 50 : 150) }); });
        var style = { fontSize: 12, borders: { color: '#E0E0E0' }, bold: true };
        fields.forEach(function (field, i) { columnHeader.push({ index: i + 1, value: field.text, style: style }); });
        rows.push({ index: 1, cells: columnHeader });
        eventCollection.forEach(function (event, i) {
            var columnData = [];
            fields.forEach(function (field, n) {
                var columnRule = { index: n + 1, value: event[field.name] || '' };
                if (field.name === _this.parent.eventFields.startTime || field.name === _this.parent.eventFields.endTime) {
                    var styleRule = { fontSize: 12, numberFormat: 'm/d/yyyy h:mm AM/PM' };
                    columnRule = extend({}, columnRule, { style: styleRule }, true);
                }
                columnData.push(columnRule);
            });
            rows.push({ index: i + 2, cells: columnData });
        });
        var workSheet = [{ columns: columns, rows: rows }];
        var book = new Workbook({ worksheets: workSheet }, type, this.parent.locale, undefined, separator);
        book.save(name + '.' + type);
    };
    ExcelExport.prototype.getExportColumns = function (exportOptions) {
        var _this = this;
        var exportColumns = exportOptions.fieldsInfo || [];
        if (exportColumns.length === 0) {
            var fields = exportOptions.fields || Object.keys(this.parent.eventFields).map(function (field) {
                return _this.parent.eventFields["" + field];
            });
            fields.forEach(function (field) { exportColumns.push({ name: field, text: field }); });
        }
        return exportColumns;
    };
    ExcelExport.prototype.getModuleName = function () {
        return 'excelExport';
    };
    ExcelExport.prototype.destroy = function () {
        this.parent = null;
    };
    return ExcelExport;
}());

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Print Module
 */
var Print = /** @__PURE__ @class */ (function () {
    function Print(parent) {
        this.parent = parent;
    }
    Print.prototype.print = function (printOptions) {
        if (isNullOrUndefined(printOptions)) {
            this.printScheduler();
        }
        else {
            this.printSchedulerWithModel(printOptions);
        }
    };
    Print.prototype.printScheduler = function () {
        var clone = this.parent.element.cloneNode(true);
        clone.id = this.parent.element.id + '_print';
        document.body.appendChild(clone);
        var className = this.parent.currentView === 'MonthAgenda' ? '.e-appointment-wrap' : '.e-content-wrap';
        var scrollableEle = this.parent.element.querySelector(className);
        var links = [].slice.call(document.getElementsByTagName('head')[0].querySelectorAll('link, style'));
        var reference = '';
        for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
            var link = links_1[_i];
            reference += link.outerHTML;
        }
        var div = createElement('div');
        clone.style.width = this.parent.element.offsetWidth + 'px';
        var elementWidth = Math.round((parseInt(clone.style.width, 10)) / 100) * 100;
        div.appendChild(clone);
        var printWindow = window.open('', 'print', 'height=550,width=' + elementWidth + ',tabbar=no');
        printWindow.document.write('<!DOCTYPE html><html><head>' + reference + '</head><body>' + div.innerHTML +
            '<script>(function() { window.ready = true; })();</script></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
            if (printWindow.ready && scrollableEle) {
                // eslint-disable-next-line no-self-assign
                scrollableEle.scrollLeft = scrollableEle.scrollLeft;
                // eslint-disable-next-line no-self-assign
                scrollableEle.scrollTop = scrollableEle.scrollTop;
                var headerTimeCellsScroll = printWindow.document.querySelector('.e-date-header-wrap');
                if (headerTimeCellsScroll) {
                    headerTimeCellsScroll.scrollLeft = scrollableEle.scrollLeft;
                }
                var timeCellsScroll = printWindow.document.querySelector('.e-time-cells-wrap');
                if (timeCellsScroll) {
                    timeCellsScroll.scrollTop = scrollableEle.scrollTop;
                }
                var contentCellScroll = printWindow.document.querySelector(className);
                if (contentCellScroll) {
                    contentCellScroll.scrollLeft = scrollableEle.scrollLeft;
                    contentCellScroll.scrollTop = scrollableEle.scrollTop;
                }
                printWindow.print();
                printWindow.close();
            }
        }, 500);
    };
    Print.prototype.printSchedulerWithModel = function (printOptions) {
        var element = createElement('div', { id: this.parent.element.id + '_print', className: 'e-print-schedule' });
        document.body.appendChild(element);
        Schedule.Inject(Day, Week, WorkWeek, Month, Agenda, MonthAgenda, TimelineViews, TimelineMonth, Year, TimelineYear);
        this.printInstance = new Schedule(this.getPrintScheduleModel(printOptions));
        this.printInstance.isPrinting = true;
        this.printInstance.appendTo(element);
        this.printInstance.on(print$1, this.contentReady, this);
        this.printWindow = window.open('', 'print', 'height=' + window.outerHeight + ',width=' + window.outerWidth + ',tabbar=no');
        this.printWindow.moveTo(0, 0);
        this.printWindow.resizeTo(screen.availWidth, screen.availHeight);
    };
    Print.prototype.getPrintScheduleModel = function (printOptions) {
        var printModel = {};
        var scheduleProps = ['agendaDaysCount', 'calendarMode', 'cssClass', 'currentView',
            'dateFormat', 'enableRtl', 'endHour', 'eventSettings', 'firstDayOfWeek',
            'firstMonthOfYear', 'group', 'height', 'locale', 'maxDate', 'minDate', 'readonly',
            'resources', 'rowAutoHeight', 'selectedDate', 'showHeaderBar', 'showTimeIndicator', 'showWeekNumber',
            'showWeekend', 'startHour', 'timeFormat', 'timeScale', 'timezone', 'views', 'width', 'workDays', 'workHours',
            'dateHeaderTemplate', 'dateRangeTemplate', 'cellHeaderTemplate', 'dayHeaderTemplate', 'monthHeaderTemplate',
            'cellTemplate', 'resourceHeaderTemplate', 'headerIndentTemplate', 'actionBegin', 'actionComplete', 'actionFailure',
            'created', 'dataBinding', 'dataBound', 'destroyed', 'eventRendered', 'moreEventsClick', 'navigating', 'popupOpen', 'popupClose', 'renderCell'
        ];
        var scheduleTemplates = ['cellHeaderTemplate', 'dayHeaderTemplate', 'monthHeaderTemplate', 'cellTemplate',
            'dateHeaderTemplate', 'dateRangeTemplate', 'eventTemplate', 'resourceHeaderTemplate', 'headerIndentTemplate'];
        var scheduleEvents = ['actionBegin', 'actionComplete', 'actionFailure', 'created', 'dataBinding', 'dataBound',
            'destroyed', 'eventRendered', 'moreEventsClick', 'navigating', 'popupOpen', 'popupClose', 'renderCell'];
        var eventSettings;
        var group;
        var timeScale;
        var views;
        for (var _i = 0, scheduleProps_1 = scheduleProps; _i < scheduleProps_1.length; _i++) {
            var key = scheduleProps_1[_i];
            switch (key) {
                case 'eventSettings': {
                    eventSettings = Object.assign({}, this.parent.eventSettings.properties);
                    eventSettings.dataSource = this.parent.eventsData;
                    var eventTemplate = !isNullOrUndefined(printOptions.eventSettings) &&
                        !isNullOrUndefined(printOptions.eventSettings.template) ? printOptions.eventSettings.template : eventSettings.template;
                    eventSettings.template = typeof (eventTemplate) === 'function' ? null : eventTemplate;
                    printModel.eventSettings = eventSettings;
                    break;
                }
                case 'group':
                    group = isNullOrUndefined(printOptions.group) ? this.parent.group : printOptions.group;
                    group.headerTooltipTemplate = null;
                    printModel.group = group;
                    break;
                case 'timeScale':
                    timeScale = isNullOrUndefined(printOptions.timeScale) ? this.parent.timeScale : printOptions.timeScale;
                    timeScale.majorSlotTemplate = typeof (timeScale.majorSlotTemplate) === 'function' ? null : timeScale.majorSlotTemplate;
                    timeScale.minorSlotTemplate = typeof (timeScale.minorSlotTemplate) === 'function' ? null : timeScale.minorSlotTemplate;
                    printOptions.timeScale = timeScale;
                    break;
                case 'views':
                    views = isNullOrUndefined(printOptions.views) ? this.parent.views : printOptions.views;
                    if (views && views.length > 0 && typeof (views[0]) === 'object') {
                        var _loop_1 = function (view) {
                            scheduleTemplates.forEach(function (x) {
                                if (!isNullOrUndefined(view["" + x])) {
                                    view["" + x] = typeof (view["" + x]) === 'function' ? null : view["" + x];
                                }
                            });
                        };
                        for (var _a = 0, views_1 = views; _a < views_1.length; _a++) {
                            var view = views_1[_a];
                            _loop_1(view);
                        }
                    }
                    printModel.views = views;
                    break;
                default:
                    if (scheduleTemplates.indexOf(key) > -1) {
                        printModel["" + key] = isNullOrUndefined(printOptions["" + key]) ?
                            (typeof (this.parent["" + key]) === 'function' ? null : this.parent["" + key]) :
                            (typeof (printOptions["" + key]) === 'function' ? null : printOptions["" + key]);
                        break;
                    }
                    if (scheduleEvents.indexOf(key) > -1) {
                        printModel["" + key] = printOptions["" + key];
                        break;
                    }
                    printModel["" + key] = isNullOrUndefined(printOptions["" + key]) ?
                        this.parent["" + key] : printOptions["" + key];
                    break;
            }
        }
        return printModel;
    };
    Print.prototype.contentReady = function () {
        var _this = this;
        this.printWindow = print(this.printInstance.element, this.printWindow);
        this.printWindow.onbeforeunload = function () {
            _this.printInstance.off(print$1, _this.contentReady);
            _this.printInstance.element.remove();
            _this.printInstance.destroy();
            _this.printInstance = null;
            _this.printWindow = null;
        };
    };
    Print.prototype.getModuleName = function () {
        return 'print';
    };
    Print.prototype.destroy = function () {
        this.parent = null;
    };
    return Print;
}());

/**
 * Exporting modules
 */

/**
 * Schedule component exported items
 */

/**
 * Recurrence-Editor component exported items
 */

/**
 * Calendar util exported items
 */

/**
 * Export Schedule components
 */

export { Schedule, cellClick, cellDoubleClick, moreEventsClick, select, hover, actionBegin, actionComplete, actionFailure, navigating, renderCell, eventClick, eventDoubleClick, eventRendered, dataBinding, dataBound, popupOpen, popupClose, dragStart, drag, dragStop, resizeStart, resizing, resizeStop, inlineClick, cellSelect, virtualScrollStart, virtualScrollStop, initialLoad, initialEnd, print$1 as print, dataReady, eventsLoaded, contentReady, scroll, virtualScroll, scrollUiUpdate, uiUpdate, documentClick, cellMouseDown, WEEK_LENGTH, DEFAULT_WEEKS, MS_PER_DAY, MS_PER_MINUTE, getElementHeightFromClass, getElementWidthFromClass, getTranslateY, getTranslateX, getWeekFirstDate, getWeekLastDate, firstDateOfMonth, lastDateOfMonth, getWeekNumber, getWeekMiddleDate, setTime, resetTime, getDateInMs, getDateCount, addDays, addMonths, addYears, getStartEndHours, getMaxDays, getDaysCount, getDateFromString, getScrollBarWidth, resetScrollbarWidth, findIndexInData, getOuterHeight, removeChildren, isDaylightSavingTime, getUniversalTime, isMobile, isIPadDevice, capitalizeFirstWord, Resize, DragAndDrop, HeaderRenderer, ViewBase, Day, Week, WorkWeek, Month, Year, Agenda, MonthAgenda, TimelineViews, TimelineMonth, TimelineYear, Timezone, timezoneData, ICalendarExport, ICalendarImport, ExcelExport, Print, RecurrenceEditor, generateSummary, generate, getDateFromRecurrenceDateString, extractObjectFromRule, getCalendarUtil, getRecurrenceStringFromDate, Gregorian, Islamic };

