// @ts-nocheck
import {
  Component,
  OnInit,
  NgModule,
  ViewChild,
  Input,
  HostListener,
  ViewEncapsulation,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  inject,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserModule } from "@angular/platform-browser";
import { Query, Predicate } from "@syncfusion/ej2-data";
import {
  ScheduleAllModule,
  DayService,
  WeekService,
  MonthService,
  TimelineViewsService,
  TimelineMonthService,
  EventSettingsModel,
  ScheduleComponent,
  DragAndDropService,
  ResizeService,
  CellClickEventArgs,
  GroupModel,
  TimeScaleModel,
  ResourceDetails,
  RenderCellEventArgs,
} from "@syncfusion/ej2-angular-schedule";
import {
  MultiSelectModule,
  DropDownListModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { CheckBox, CheckBoxModule } from "@syncfusion/ej2-angular-buttons";
import { Internationalization } from "@syncfusion/ej2-base";
import { TextBoxAllModule } from "@syncfusion/ej2-angular-inputs";
import { TooltipAllModule } from "@syncfusion/ej2-angular-popups";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { NgxColorsModule } from "ngx-colors";
import { FilterOption, Event } from "./schedulerTypes";
import { MaterialModule } from "../../shared/material.module";

@Component({
  selector: "app-syncfusion-scheduler",
  providers: [
    DayService,
    WeekService,
    MonthService,
    DragAndDropService,
    ResizeService,
    TimelineViewsService,
    TimelineMonthService,
  ],
  templateUrl: "./syncfusion-scheduler.component.html",
  styleUrls: ["./syncfusion-scheduler.component.css"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    ScheduleAllModule,
    MultiSelectModule,
    DropDownListModule,
    CheckBoxModule,
    TextBoxAllModule,
    TooltipAllModule,
    NgxColorsModule,
  ],
})
export class SyncfusionSchedulerComponent implements OnInit, OnChanges {
  public selectedDate: Date = new Date();
  public viewArr: Array<any> = [
    "TimelineWorkWeek",
    "TimelineWeek",
    "TimelineMonth",
  ];

  @ViewChild("scheduleObj", { static: true }) scheduleObj: ScheduleComponent;
  @ViewChild("filterResources", { static: false }) filterResources: CheckBox;

  @Input() events: Object[];
  @Input() xsScreenHeightMod: number;
  @Input() groupsDataSource: Object[];
  @Input() resourcesDataSource: {}[];
  @Input() views: string[];
  @Input() allowResize: boolean;
  @Input() allowDragAndDrop: boolean;
  @Input() readonly: boolean;
  @Input() timeScale: TimeScaleModel;
  @Input() actions: any;
  @Input() fields: any;
  @Input() weekFirstDay: number;
  @Output() emitDeleteEvent = new EventEmitter<any>();

  eventSettings: EventSettingsModel;
  screenHeight: number = window.innerHeight;
  topNavHeight = 65;

  public groupModel: GroupModel;
  public allowMultipleCategory: Boolean = true;
  public weekInterval: number = 8;
  public instance: Internationalization = new Internationalization();
  public workWeekDays: number[] = [0, 1, 2, 3, 4, 5, 6];
  public headerOffset = 5;
  public currentEventColour = "#fff";

  public tooltipTemplate: string =
    '<div class="tooltip-wrap">' +
    '<div class="content-area"> ' +
    "<div>${EventName}</></div>" +
    "${if(ContactName !== null && ContactName !== undefined)}<div>${ContactName}</div>${/if}" +
    "${if(JobNumber !== null && JobNumber !== undefined)}<div>${JobNumber}</div>${/if}" +
    "${if(Location !== null && Location !== undefined)}<div>${Location}</div>${/if}" +
    "${if(ExtentOfWork !== null && ExtentOfWork !== undefined)}<br><div>${ExtentOfWork}</div>${/if}" +
    "${if(EventNotes !== null && EventNotes !== undefined)}<br><div>${EventNotes}</div>${/if}";

  public workWeekTemplate: string = "<div>${data.EventName}</></div>";

  constructor() {
    if (window.innerWidth < 600) {
      this.screenHeight =
        window.innerHeight -
        this.xsScreenHeightMod -
        this.headerOffset -
        this.topNavHeight;
    } else {
      this.screenHeight =
        window.innerHeight - this.headerOffset - this.topNavHeight;
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem(localStorageKeys.SCHEDULER_DATE)) {
      this.selectedDate = new Date(
        localStorage.getItem(localStorageKeys.SCHEDULER_DATE)
      );
    } else {
      this.selectedDate = new Date();
    }

    this.eventSettings = {
      dataSource: this.events,
      fields: this.fields,
      tooltipTemplate: this.tooltipTemplate,
      enableTooltip: true,
      ignoreWhitespace: true,
    };

    // set the groupModel if there was data passed in
    if (this.resourcesDataSource != null) {
      if (this.groupsDataSource != null) {
        this.groupModel = {
          byGroupID: false,
          resources: ["Resources", "Groups"],
        };
      } else {
        this.groupModel = {
          byGroupID: false,
          resources: ["Resources"],
        };
      }
    }

    // set the height mod for xs screens
    if (this.xsScreenHeightMod == null) {
      this.xsScreenHeightMod = 0;
    }
    // set the scheduler height
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      if (this.scheduleObj && changes.events.isFirstChange) {
        // this.events = changes.events.currentValue;
        this.eventSettings = {
          dataSource: this.events,
          fields: this.fields,
          tooltipTemplate: this.tooltipTemplate,
          enableTooltip: true,
        };
      }
    }
  }

  @HostListener("window:resize", [])
  onResize(event?) {
    this.screenHeight =
      window.innerHeight - this.headerOffset - this.topNavHeight;
    if (window.innerWidth < 600) {
      this.screenHeight =
        window.innerHeight -
        this.xsScreenHeightMod -
        this.headerOffset -
        this.topNavHeight;
    }
  }

  getElement(elementClass: string): HTMLElement {
    return this.scheduleObj.element.querySelector(elementClass);
  }

  getCellDetails(element: HTMLElement): CellClickEventArgs {
    return this.scheduleObj.getCellDetails(element);
  }

  getResourceByIndex(cellData: CellClickEventArgs): ResourceDetails {
    return this.scheduleObj.getResourcesByIndex(cellData.groupIndex);
  }

  openEditor(eventData, action) {
    this.scheduleObj.openEditor(eventData, action, true);
  }

  getCurrentViewDates() {
    try {
      return this.scheduleObj.getCurrentViewDates();
    } catch (error) {
      console.error(error.message);
      return false;
    }
  }

  onEventClick(event) {
    try {
      let popupInstance: any = (
        document.querySelector(".e-quick-popup-wrapper") as any
      ).ej2_instances[0];
      popupInstance.open = () => {
        popupInstance.refreshPosition();
      };
    } catch (error) {
      console.error(error.message);
    }
  }

  onRenderCell(args: RenderCellEventArgs) {
    try {
      if (args.elementType == "workCells" || args.elementType == "monthCells") {
        let weekEnds: number[] = [0, 6];
        if (weekEnds.indexOf(args.date.getDay()) >= 0) {
          (args.element as HTMLElement).style.background = "#fafafa";
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  onEventRendered(event) {
    // set the shadow of the event card
    event.element.classList.add("mat-elevation-z1");
    if (event.data.EventTypeID == 2) {
      try {
        if (event.data.ParentEventID) {
          //child because null if parent
          event.element.style.backgroundColor = this.actions.getResourceColour(
            event.data.ResourceID
          );
        } else {
          //parent
          event.element.style["background-image"] =
            "linear-gradient(to bottom right, black, " +
            this.actions.getResourceColour(event.data.ResourceID) +
            " 40%)";
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  }

  getDateHeaderText(value: Date): string {
    return this.instance.formatDate(value, { skeleton: "MMMEd" });
  }

  closeEditor() {
    this.scheduleObj.closeEditor();
  }

  closeQuickPopup() {
    this.scheduleObj.quickPopup.quickPopupHide();
  }

  addEvent(event: Event) {
    this.scheduleObj.addEvent(event);
  }

  deleteEvent(eventID: number) {
    this.emitDeleteEvent.emit(eventID);
  }

  deleteEvents(eventIDs: any[]) {
    try {
      this.scheduleObj.deleteEvent(eventIDs);
    } catch (error) {
      // console.log(error);
    }
  }

  getEvents() {
    let events: Object[] = this.scheduleObj.getEvents();
    return events;
  }

  refreshEvents() {
    if (this.scheduleObj) {
      this.scheduleObj.refreshEvents();
    }
  }

  refresh() {
    if (this.scheduleObj) {
      this.scheduleObj.refresh();
    }
  }

  scrollToResource(resourceID, wait = false) {
    //this is fix for now. Triggered after navigated to next page
    //no trigger for when completed loading resources currently
    if (wait) {
      setTimeout(() => {
        this.scheduleObj.scrollToResource(resourceID);
      }, 1000);
    } else {
      this.scheduleObj.scrollToResource(resourceID);
    }
  }

  /**
   * Sets the query for this syncfusion scheduler 
   *
   * @param {Query} query
   * @memberof SyncfusionSchedulerComponent
   */
  queryEvents(query: Query) {
    this.scheduleObj.resources[0].query = query;
  }

  /**
   * Sets the filter for this syncfusion scheduler
   *
   * @param {FilterOption[]} filters
   * @memberof SyncfusionSchedulerComponent
   */
  filterEvents(filters: FilterOption[]) {
    let predicate: Predicate;
    let peoplePredicate: Predicate;
    // console.log("Filters:", filters);
    // console.log("EVENT SETTINGS:", this.scheduleObj.eventSettings);

    if (filters.length == 0) {
      this.scheduleObj.eventSettings.query = new Query();
    } else {
      filters.forEach((filter) => {
        // console.log(filter);
        if (
          filter.field.toLowerCase().includes("peopleresourceids") ||
          filter.field.toLowerCase().includes("userresponsibleresourceid")
        ) {
          if (peoplePredicate) {
            peoplePredicate = peoplePredicate.or(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          } else {
            peoplePredicate = new Predicate(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          }
        } else {
          if (predicate) {
            predicate = predicate.and(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          } else {
            predicate = new Predicate(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          }
        }
      });

      if (peoplePredicate) {
        if (predicate) {
          predicate = predicate.and(peoplePredicate);
        } else {
          predicate = peoplePredicate;
        }
      }

      // console.log("predicate: ", predicate);
      this.scheduleObj.eventSettings.query = new Query().where(predicate);
    }
  }

  popupOpen(event) {
    //call action
    this.actions.popupOpen(event);

    try {
      // data.EventColour ? data.EventColour : clearEventColour(data.EventTypeID)
      //color picker defaults green so set to colour of event
      if (event.type == "Editor" && event.data.EventColour) {
        this.currentEventColour = event.data.EventColour;
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  colorPickerChange(event) {
    if (event) {
      this.currentEventColour = event;
    }
  }

  clearEventColour(eventTypeID) {
    let textColour = "#ffffff";
    if (eventTypeID == 3) {
      textColour = "#fff0ab";
    }
    return textColour;
  }

  getEventOpacity(data) {
    var opacity = 1;
    var today = new Date();
    try {
      if (today > data.EndTime) {
        opacity = 0.6;
      }
    } catch {
      opacity = 1;
    }
    return opacity;
  }

  getTextColour(colour): string {
    let textColour = "#000000";
    if (colour) {
      let r = parseInt(colour.substring(1, 3), 16);
      let g = parseInt(colour.substring(3, 5), 16) * 2;
      let b = parseInt(colour.substring(5, 7), 16);
      if (r + g + b <= 765 / 1.96) {
        textColour = "#ffffff";
      }
    }
    return textColour;
  }

  onCheckChange(args, event) {
    this.actions.filterResourcesOnChange(args, event);
  }
}

// @NgModule({
//   declarations: [SyncfusionSchedulerComponent],
//   imports: [
//     BrowserModule,
//     MaterialModule,
//     FlexLayoutModule,
//     ScheduleAllModule,
//     MultiSelectModule,
//     DropDownListModule,
//     CheckBoxModule,
//     TextBoxAllModule,
//     TooltipAllModule,
//     NgxColorsModule,
//   ],
//   exports: [SyncfusionSchedulerComponent],
// })
// export class SyncfusionSchedulerModule {}
