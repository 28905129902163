import { Injectable } from "@angular/core";
import axios from "axios";
import { from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { environment } from "src/environments/environment";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import { unzipFileIncludeName } from "../../core/services/document/zip-helper";
import { LoggerService } from "../../core/services/logger/logger.service";
import { UploadDocumentsService } from "../../shared/upload-documents/upload-documents.service";
import { UserService } from "../../core/services/user/user.service";

export enum DocToAngAPIAction {
  UPDATE = 1,
  SELECT = 2,
}

export enum DocToAngAPISubAction {
  VIEWS = 1,
  ACKNOWLEDGEMENT = 2,
  SIGNATURE = 3,
}

export enum AcknowledgementStatus {
  DECLINED = -1,
  ACKNOWLEDGED = 1,
}

@Injectable({
  providedIn: "root",
})
export class PdfAcknowledgementService {
  constructor(
    private logger$: LoggerService,
    private uploadDocumentService$: UploadDocumentsService,
    private utilocateApiService: ApiService,
    private userService: UserService,
  ) {
    this.logger$.log("Created PDFAcknowledgementService");
  }

  /*
    This whole service is almost identical to document viewer service, except this uses observables and that uses async/await
    These two services should probably be combined
  */

  retrieveDocFromS3(key: string) {
    let url = "/api/get/file"; // localhost

    // update url to include nodejs for non-localhost sites
    const isLiveSite = environment.production == true;
    const isDevSite =
      environment.production == false && environment.url == null;
    const isLocalHost = environment.localhost;

    if (!isLocalHost) {
      url = "/nodejs/api/get/file";
    }

    return axios.get(url, {
      // to send information in a get use
      params: {
        key: key,
        bucket: this.userService.getClientBucketFromSettings(),
      },
    });
  }

  downloadDocument(assignmentID, docID): Observable<any> {
    return new Observable((subscriber) => {
      const url = apiKeys.u2.retrieveDocumentsS3;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.retrieveDocumentsS3,
        API_TYPE: type,
        API_BODY: {
          AssignmentID: assignmentID,
        },
        API_URL_DATA_PARAMS: {
          DocumentID: docID,
        },
      };

      from(
        this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
      ).subscribe((apiResult) => {
        subscriber.next(apiResult);
        subscriber.complete();
      });
    });
  }

  getPDF() {
    var self = this;
    return new Promise(function (resolve, reject) {
      const aid = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.assignmentid
      );
      const docID = sessionStorage.getItem(
        localStorageKeys.URL_KEYS.documentid
      );
      // get the location of the doc in the bucket
      self.downloadDocument(aid, docID).subscribe((res) => {
        if (res && res.ok && res.body != null) {
          // parse out the location
          var key = res.body.value.split('"')[1];
          // get the doc from S3
          self
            .retrieveDocFromS3(key)
            .then(function (response) {
              if (response != null && response.data != null) {
                var data = response.data;
                if (data.file != null) {
                  // got the file need to unzip
                  return unzipFileIncludeName(data.file.data);
                } else if (data.error != null) {
                  throw data.error;
                } else {
                  throw Error("Error returned from retrieveDocFromS3");
                }
              } else {
                throw Error("retrieveDocFromS3 response data null");
              }
            })
            .then(function (value) {
              // handle unzip return
              if (value != null) {
                resolve(value);
              }
            })
            .catch(function (error) {
              reject(error);
            });
        } else {
          if (res.error == null) {
            reject("Could Not Retrieve Document Location");
          } else {
            reject(res.error);
          }
        }
      });
    });
  }

  uploadDocument(fileName: string, file: string): Observable<any> {
    var document = {
      file: file,
      name: fileName,
      docTypeID: 6,
    };
    var AssignmentID = Number.parseInt(sessionStorage.getItem("assignmentid"));

    var uploadDocInfo = JSON.stringify({ AssignmentID, document });
    return this.uploadDocumentService$.uploadDocument(uploadDocInfo);
  }

  updateViews() {
    return new Observable((subscriber) => {
      try {
        const rowID = Number.parseInt(
          sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
        );
        var data = {
          DocumentToAngularID: rowID,
          SubAction: DocToAngAPISubAction.VIEWS,
        };
        if (rowID) {
          const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
          this.callDocToAngularController(apiBody).subscribe((result) => {
            subscriber.next(result);
          });
        } else {
          throw new Error("Failed to GET rowID from storage");
        }
      } catch (ex) {
        this.logger$.error("updateViews: fail: ", ex);
      }
    });
  }

  updateAcknowledged() {
    return new Observable((subscriber) => {
      try {
        const rowID = Number.parseInt(
          sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
        );
        var data = {
          DocumentToAngularID: rowID,
          SubAction: DocToAngAPISubAction.ACKNOWLEDGEMENT,
          Acknowledgement: AcknowledgementStatus.ACKNOWLEDGED,
        };
        if (rowID) {
          const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
          this.callDocToAngularController(apiBody).subscribe((result) => {
            subscriber.next(result);
          });
        } else {
          throw new Error("Failed to GET rowID from storage");
        }
      } catch (ex) {
        this.logger$.error("updateAcknowledgemed: fail: ", ex);
      }
    });
  }

  updateDeclined() {
    return new Observable((subscriber) => {
      try {
        const rowID = Number.parseInt(
          sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
        );
        var data = {
          DocumentToAngularID: rowID,
          SubAction: DocToAngAPISubAction.ACKNOWLEDGEMENT,
          Acknowledgement: AcknowledgementStatus.DECLINED,
        };
        if (rowID) {
          const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };
          this.callDocToAngularController(apiBody).subscribe((result) => {
            subscriber.next(result);
          });
        } else {
          throw new Error("Failed to GET rowID from storage");
        }
      } catch (ex) {
        this.logger$.error("updateDeclined: fail: ", ex);
      }
    });
  }

  updateSignature(sigDocID, values) {
    return new Observable((subscriber) => {
      try {
        const rowID = Number.parseInt(
          sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
        );
        var data = {
          DocumentToAngularID: rowID,
          SubAction: DocToAngAPISubAction.SIGNATURE,
          SignatureDocumentID: sigDocID,
          FormValues: values,
          Acknowledgement: AcknowledgementStatus.ACKNOWLEDGED,
        };
        if (rowID) {
          const apiBody = { Action: DocToAngAPIAction.UPDATE, Data: data };

          this.callDocToAngularController(apiBody).subscribe((result) => {
            subscriber.next(result);
          });
        } else {
          throw new Error("Failed to GET rowID from storage");
        }
      } catch (ex) {
        this.logger$.error("updateSignature: fail: ", ex);
      }
    });
  }

  getDocumentToAngularRow() {
    return new Observable((subscriber) => {
      try {
        const rowID = Number.parseInt(
          sessionStorage.getItem(localStorageKeys.URL_KEYS.documenttoangularid)
        );
        var data = {
          DocumentToAngularID: rowID,
        };
        if (rowID) {
          const apiBody = { Action: DocToAngAPIAction.SELECT, Data: data };
          this.callDocToAngularController(apiBody).subscribe((result) => {
            subscriber.next(result);
          });
        } else {
          throw new Error("Failed to GET rowID from storage");
        }
      } catch (ex) {
        this.logger$.error("updateSignature: fail: ", ex);
      }
    });
  }

  callDocToAngularController(apiBody: any) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.documentToAngularController;
        const type = api[url].type;
        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.documentToAngularController,
          API_TYPE: type,
          API_BODY: apiBody,
        };

        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((response) => {
          if (response.ok) {
            subscriber.next(response.body);
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (ex) {
        this.logger$.error("callDocToAngularController: fail: ", ex);
      }
    });
  }
}
