import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";

@Component({
  selector: "app-ticket-protection-override-dialog",
  templateUrl: "./ticket-protection-override-dialog.component.html",
  styleUrls: ["./ticket-protection-override-dialog.component.css"],
})
export class TicketProtectionOverrideDialogComponent {
  message: string;
  protectedMessage: string =
    "Accessed for more than 1 hour. Would you like to override ticket protection to gain access?";
  ticketProtection: object;
  protectionExpired: boolean = false;

  date: Date;
  userID: number | string;
  name: string;

  cancel = "cancel";

  constructor(
    public dialogRef: MatDialogRef<TicketProtectionOverrideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datetime: DatetimeService
  ) {
    this.ticketProtection = data.ticketProtectionCheck;
    this.parseUserInfo();
    this.createMessage();
  }

  parseUserInfo() {
    try {
      if (this.ticketProtection) {
        this.date = this.datetime.dbDateToLocalDate(
          this.ticketProtection["date"]
        );

        this.userID = this.ticketProtection["userID"];
        this.name = `${this.ticketProtection["userFirstName"]} ${this.ticketProtection["userLastName"]}`;
      }
    } catch (error) {
      console.error(
        "TicketProtectionOverrideDialogComponent: parseUserInfo: ",
        error
      );
    }
  }

  createMessage() {
    try {
      let now = new Date();
      this.protectionExpired = now.getTime() - this.date.getTime() > 3600000; //1 hour

      this.message = `Operations limited to viewing as this ticket is currently being accessed by ${
        this.name
      }, since ${this.date.toLocaleString()}.`;
      // ${this.protectionExpired ? 'Accessed for more than 1 hour. Would you like to override ticket protection to gain access?' : ''}
    } catch (error) {
      console.error(
        "TicketProtectionOverrideDialogComponent: createMessage: ",
        error
      );
    }
  }

  clickOverride() {
    this.dialogRef.close("override");
  }
}
