import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  FilterOperator,
  FilterOption,
  Resource,
} from "../../syncfusion-scheduler/schedulerTypes";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import {
  DropDownListComponent,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";

@Component({
  selector: "app-scheduler-filter-view",
  templateUrl: "./scheduler-filter-view.component.html",
  styleUrls: ["./scheduler-filter-view.component.scss"],
  providers: [ComponentMessagingService],
  encapsulation: ViewEncapsulation.None,
})
export class SchedulerFilterViewComponent
  extends BaseViewComponent
  implements OnInit
{
  className = "SchedulerFilterViewComponent";
  public Modelvalue;
  @Input() props: any;
  @Input() rigResourceDateSource: Resource[];
  @Input() peopleResourceDataSource: Resource[];

  @ViewChild("rigFilter") public rigFilterDropDown: DropDownListComponent;
  @ViewChild("peopleFilter")
  public peopleFilterMultiSelect: MultiSelectComponent;

  rigResourceFields: any = { text: "ResourceName", value: "ResourceID" };
  peopleResourceFields: any = { text: "ResourceName", value: "ResourceID" };

  form: FormGroup;

  constructor(logger$: LoggerService) {
    super(logger$);
    this.form = new FormGroup({
      rigFilter: new FormControl(""),
      jobNumFilter: new FormControl(""),
      customerFilter: new FormControl(""),
      cityFilter: new FormControl(""),
      addressFilter: new FormControl(""),
      peopleFilter: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });
  }

  sendMessage(action: MessageAction, message: {}) {
    const messageToSend: ComponentMessage = {
      action: action,
      message: message,
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(messageToSend);
  }

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
    }
  }

  dropdownChange(form, value) {
    this.form.controls[form].setValue(value);
  }

  clearFilter() {
    this.rigFilterDropDown.value = null;
    this.peopleFilterMultiSelect.value = null;
    this.form.reset();
    this.sendMessage(MessageAction.LOADING, {
      filterEvents: true,
      filterOptions: [],
    });
  }

  applyFilter() {
    var filterOptions: FilterOption[] = [];

    if (
      this.form.get("rigFilter").value != null &&
      this.form.get("rigFilter").value != ""
    ) {
      filterOptions.push({
        operator: FilterOperator.equal,
        field: "ResourceID",
        value: this.form.get("rigFilter").value,
      });
    }
    if (
      this.form.get("peopleFilter").value != null &&
      this.form.get("peopleFilter").value != ""
    ) {
      var value: number[] = [];
      this.form.get("peopleFilter").value.forEach((id) => {
        filterOptions.push({
          operator: FilterOperator.contains,
          field: "PeopleResourceIDs",
          value: id,
        });
        filterOptions.push({
          operator: FilterOperator.contains,
          field: "UserResponsibleResourceID",
          value: id,
        });
      });
    }
    if (
      this.form.get("jobNumFilter").value != null &&
      this.form.get("jobNumFilter").value != ""
    ) {
      filterOptions.push({
        operator: FilterOperator.contains,
        field: "JobNumber",
        value: this.form.get("jobNumFilter").value,
      });
    }
    if (
      this.form.get("customerFilter").value != null &&
      this.form.get("customerFilter").value != ""
    ) {
      filterOptions.push({
        operator: FilterOperator.contains,
        field: "EventName",
        value: this.form.get("customerFilter").value,
      });
    }
    if (
      this.form.get("cityFilter").value != null &&
      this.form.get("cityFilter").value != ""
    ) {
      filterOptions.push({
        operator: FilterOperator.contains,
        field: "Location",
        value: this.form.get("cityFilter").value,
      });
    }
    if (
      this.form.get("addressFilter").value != null &&
      this.form.get("addressFilter").value != ""
    ) {
      filterOptions.push({
        operator: FilterOperator.contains,
        field: "Location",
        value: this.form.get("addressFilter").value,
      });
    }

    this.sendMessage(MessageAction.LOADING, {
      filterEvents: true,
      filterOptions: filterOptions,
    });
  }
}
