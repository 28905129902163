/**
 * Transforms a string with spaces to camelCase.
 */
export function spacedStringToCamelCase(str: string): string {
  return str
    .trim()
    .toLowerCase()
    .split(' ')
    .reduce((acc, curr, index) => {
      if (index !== 0) {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      } else {
        return acc + curr;
      }
    });
}

/**
 * Transforms a 'snake-cased' string to camelCase.
 */
export function snakeCaseToCamelCase(str: string): string {
  return str
    .trim()
    .toLowerCase()
    .split('_')
    .reduce((acc, curr, index) => {
      if (index !== 0) {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      } else {
        return acc + curr;
      }
    });
}
