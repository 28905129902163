export const EnglishCanadian = {
  "AcknowledgementViewComponent": {
    "header": "Do you acknowledge this message?",
    "body1a": "Grantee shall",
    "body1b": "be liable to Trans-Northern Pipelines Inc. for all loss. costs, damages and expenses whatsoever that Trans·Northern Pipelines Inc. may suffer. sustain, pay or incur: and",
    "body1c": "l indemnify and save harmless Trans-Northern Pipelines Inc. against all actions, proceedings, claims, demands. loss, costs. damages ant! expenses whatsoever that may be brought against or suffered by Trans-Northern Pipelines me. or which it may pay, sustain or incur: by reason of any matter. or thing or in any way attributable to the activities of Grantee, its affiliates. directors. officers. employees, agents, invitees, contractors or subcontractors, in executing work on the Trans-Northern Pipelines Inc. Right of Way, unless due to the negligence or willful misconduct of Trans-Northern Pipelines lnc., but neither Trans-Northern Pipelines Inc. or Grantee shall be liable whether in tort, contract or otherwise, for any indirect or consequential damages including, without restricting the generality of the forgoing, loss of profits. suffered by Trans-Northern Pipelines Inc. or Grantee and arising out of this agreement. For the purposes of this agreement. Trans- Northern Pipelines Inc. includes its affiliates. directors, Officers. employees and agents.",
    "body2":"Grantee agrees that it and its contractors have reviewed the terms and conditions of this agreement with the Trans·Northern Pipelines lnc. Representative and agree to them, agree that they fully understand all requirements and the serious safety, regulatory or pipeline integrity implications that could result from non-compliance, and they further agree to adhere to Trans-Northern Pipelines Inc.'s Ground Disturbance Policies.",
    "disclaimer":`
    <p>Grantee is liable to Trans-Northern Pipelines Inc. (the <b>"Grantor"</b>) for all losses, damages, expenses and costs whatsoever that the Grantor may suffer or incur by reason of any act or omission of the Grantee, its affiliates, directors, officers, employees, agents, invitees, contractors, or subcontractors (the <b>"Grantee Personnel"</b>) in executing the work contemplated by this form (the <b>"Work"</b>), except to the extent that such losses, damages, expenses and costs were caused by the Grantor's negligence or wilful misconduct.</p>
    <p>Grantee shall indemnify and save harmless Grantor, its affiliates, directors, officers, employees, agents, invitees, contractors and subcontractors (“Grantor Personnel”) for and against all losses, damages, expenses, actions, proceedings, claims, and costs which may be suffered by or brought against the Grantor and the Grantor Personnel in connection with the work contemplated by this form (the <b>"Work"</b>), including those caused or commenced by the Grantee, except to the extent that such losses, damages, expenses, actions, proceedings, claims, and costs were caused by the Grantor's negligence or wilful misconduct.</p>
    <p>Notwithstanding any of the foregoing, neither party shall be liable to the other party for any indirect or consequential damages, loss of profits, loss of opportunity, loss of business, loss of use, or loss of goodwill arising out of or related to the Work.</p>
    <p>The Grantee acknowledges that it has reviewed all applicable laws, including but not limited to the Canadian Energy Regulator Act and the Canadian Energy Regulator Pipeline Damage Prevention Regulations – Authorizations, and the Grantor's Crossing Guidelines. The Grantee agrees to adhere to, and cause the Grantee Personnel to adhere to all applicable laws, the Crossing Guidelines and the terms of this form.</p>
    <p>Should the Grantor and Grantee enter into a Crossing Agreement with respect to the Work, the terms of such Crossing Agreement shall supersede this agreement to the extent there is any conflict between the Crossing Agreement and this form.</p>
    `,
    "ackButton": "I ACKNOWLEDGE",
  },
  "PDFAcknowledgementPanelComponent": {
    "issueContact":"ISSUES VIEWING",
    "logo":"tn-logo-en",
    "pdfPath":"/assets/pdf/SignatureAccessTroubleshootManual.pdf",
    "errorHeaderH2":"Unable to Access Document",
    "errorHeaderH3":"Why do you not have access to view the document?",
    "errorBody1a":"If you are using",
    "errorBody1b":"this could have been caused by internet options.",
    "errorBody2a":"Please follow along with the",
    "errorBody2b":"Manual",
    "errorBody2c":"to resolve this issue caused by internet options.",
    "404ErrorHeader":"Unable to Find The Document"
  },
  "SignatureViewComponent": {
    "header":"Information and Signature Required to View Document",
    "firstName":"First Name",
    "lastName":"Last Name",
    "phoneNumber":"Phone Number",
    "readButton":"READ AGAIN",
    "clearButton":"CLEAR",
    "acceptButton":"I ACKNOWLEDGE",
    "signatureHeader":"Sign in box below",
    "signatureMissingResponse":"Signature Required",
  }
}

// 1:"Unable to Access Document",
// 2:"Why do you not have access to view the document?",
// 3:"If you <b> are </b> using",
// 4:"this could have been caused by internet options",
// 5:"Please follow along with the",
// 6:"Signature Access Troubleshoot Manual",
// 7:"to resolve this issue caused by internet options",
// 8:"Unable to Find The Document",
// 9:"Do you acknowledge this message?",
// 10:"Grantee shall",
// 11:"(a)",
// 12:"be liable to Trans-Northern Pipelines Inc. for all loss. costs, damages and expenses whatsoever that Trans·Northern Pipelines Inc. may suffer. sustain, pay or incur: and",
// 13:"(b)",
// 14:"l indemnify and save harmless Trans-Northern Pipelines Inc. against all actions, proceedings, claims, demands. loss, costs. damages ant! expenses whatsoever that may be brought against or suffered by Trans-Northern Pipelines me. or which it may pay, sustain or incur: by reason of any matter. or thing or in any way attributable to the activities of Grantee, its affiliates. directors. officers. employees, agents, invitees, contractors or subcontractors, in executing work on the Trans-Northern Pipelines Inc. Right of Way, unless due to the negligence or willful misconduct of Trans-Northern Pipelines lnc., but neither Trans-Northern Pipelines Inc. or Grantee shall be liable whether in tort, contract or otherwise, for any indirect or consequential damages including, without restricting the generality of the forgoing, loss of profits. suffered by Trans-Northern Pipelines Inc. or Grantee and arising out of this agreement. For the purposes of this agreement. Trans- Northern Pipelines Inc. includes its affiliates. directors, Officers. employees and agents.",
// 15:"Grantee agrees that it and its contractors have reviewed the terms and conditions of this agreement with the Trans·Northern Pipelines lnc. Representative and agree to them, agree that they fully understand all requirements and the serious safety, regulatory or pipeline integrity implications that could result from non-compliance, and they further agree to adhere to Trans-Northern Pipelines Inc.'s Ground Disturbance Policies.",
// 16:"I ACKNOWLEDGE",
// 17:""