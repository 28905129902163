import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatetimeService } from "../../core/services/datetime/datetime.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { TicketDetailInputType } from "../../create-ticket/create-ticket-component.service";
import { FormTemplateField } from "./form-input-template/form-input-template.component";
import { RecursiveFormField, RecursiveFormGroup } from "./recursiveFormOptions";

@Injectable({
  providedIn: "root",
})
export class FormBuilderService {
  className = "FormBuilderService";

  formsMap = {};

  constructor(
    private logger$: LoggerService,
    private formbuilder$: FormBuilder,
    private datetime$: DatetimeService
  ) {
    this.formbuilder$ = formbuilder$;
  }

  generateFormGroup(view) {
    try {
      let formGroup = {};
      let result = {};
      let filteredOptionsArr = [];
      let selectOptionsArr = [];
      let generatedGroup: FormGroup;

      if (view && view["groups"] && Object.keys(view["groups"]).length > 0) {
        let i: number = 1;

        for (let groupkey in view["groups"]) {
          if (
            view["groups"][groupkey] &&
            view["groups"][groupkey]["fields"] &&
            Object.keys(view["groups"][groupkey]["fields"]).length > 0
          ) {
            let fields: FormTemplateField = view["groups"][groupkey]["fields"];
            for (let fieldKey in fields) {
              // set tabindex
              view["groups"][groupkey]["fields"][fieldKey]["tabindex"] = i++;
              let controlKey: string = fields[fieldKey]["key"];
              let controlKeyToLower: string = controlKey.toLowerCase();
              let inputTypeID: number = fields[fieldKey]["inputTypeID"];
              // autocomplete 13
              if (inputTypeID == TicketDetailInputType.Autocomplete) {
                //add to controls that are added to.
                //add observable to auto complete.
                let path = {
                  controlKey: controlKey,
                  groupkey: groupkey,
                  fieldKey: fieldKey,
                };
                filteredOptionsArr.push(path);
              }
              if (
                inputTypeID == TicketDetailInputType.Multiselect ||
                inputTypeID == TicketDetailInputType.Singleselect
              ) {
                //add to controls that are added to.
                //add observable to auto complete.
                let path = {
                  controlKey: controlKey,
                  groupkey: groupkey,
                  fieldKey: fieldKey,
                };
                selectOptionsArr.push(path);
              }

              if (inputTypeID == TicketDetailInputType.DateTimePicker) {
                formGroup[controlKey + "Time_Ignore_"] = ["", []];
              }

              // create list of validators
              let validators = [];
              //custom based on key
              if (controlKeyToLower.indexOf("phone") > -1) {
                validators.push(Validators.minLength(10));
              } else if (controlKeyToLower.indexOf("email") > -1) {
                validators.push(Validators.email);
              }

              //generic
              if (fields[fieldKey]["isRequired"]) {
                validators.push(Validators.required);
                validators.push(this.noWhitespaceValidator);
              }
              //regex
              if (fields[fieldKey]["matches"]) {
                validators.push(
                  Validators.pattern('fields[fieldKey]["matches"]')
                );
              }
              formGroup[controlKey] = ["", validators];
            }
          } else {
            result["error"] = true;
            this.logger$.log("no fields");
          }
        }

        // result = this.formbuilder$.group(formGroup);
        generatedGroup = this.formbuilder$.group(formGroup);

        view["formGroup"] = generatedGroup;
      } else {
        result["error"] = true;
        this.logger$.log("no groups or no view");
      }

      return view;
    } catch (error) {
      this.logger$.error(error);
      return false;
    }
  }

  noWhitespaceValidator(noWhitespaceValidator: any) {
    let isWhitespace: boolean = false;
    try {
      if (noWhitespaceValidator && noWhitespaceValidator.value) {
        if (!Array.isArray(noWhitespaceValidator.value)) {
          if (
            typeof noWhitespaceValidator.value === "object" &&
            noWhitespaceValidator.value["text"]
          ) {
            isWhitespace =
              noWhitespaceValidator.value["text"].trim().length === 0;
          } else if (typeof noWhitespaceValidator.value === "string") {
            isWhitespace =
              (noWhitespaceValidator.value || "").trim().length === 0;
          }
        }
      }
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    } catch (error) {
      this.logger$.log("no white spce val");
      return false;
    }
  }
  addFilteredOptions(
    view: any,
    filteredOptionsArr: any[],
    generatedGroup: any
  ): any {
    throw new Error("Method not implemented.");
  }
  // async addselectOptions(view: any, selectOptionsArr: any[]): any {
  //   try {
  //     for (let control of selectOptionsArr) {
  //       let field: FormTemplateField =
  //         view["groups"][control["groupkey"]]["fields"][control["fieldKey"]];
  //       let options: any = await this.getFilterOptions(field);
  //       field["options"] = options;
  //     }
  //   } catch (error) {
  //     this.logger$.error("addSelectOptions: " + error.message);
  //   }
  //   return view;
  // }

  // get required db column names from formGroup.fields
  // look up db names in the data provided and apply them to the formGroup
  applyDataToForms(data, forms) {
    try {
      if (data && forms) {
        // iterate through views
        let views = Object.keys(forms);
        for (let i = 0; i < views.length; i++) {
          let view = views[i];
          let formGroup = forms[view]["formGroup"];
          let dbColumns = Object.keys(formGroup["value"]);

          let dataToApply = { ...formGroup["value"] };
          for (let j = 0; j < dbColumns.length; j++) {
            let key = dbColumns[j];
            let value = "";

            if (data[key]) {
              value = data[key];

              if (this.datetime$.isStringVaildDate(value)) {
                let localDateStr = this.datetime$.dbDateToFormattedLocalDate(
                  value,
                  "yyyy-MM-dd'T'HH:mm:ss"
                );
                value = localDateStr;
                //TODO REFACTOR
                if (key == "AppointmentDate") {
                  dataToApply["AppointmentDateTime_Ignore_"] =
                    this.datetime$.dbDateToFormattedLocalDate(value, "t");
                }
              }
              dataToApply[key] = value;
            }
          }
          formGroup.patchValue(dataToApply);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // in the event of a settings override where we need to make a field not readonly
  // update the template then reload the formGroups
  updateTemplate() {}

  // given a view, return all keys that are is
  getKeysThatCanBeEdited(view) {
    try {
      let editableKeys = [];

      let groups = view["groups"];
      let groupKeys = Object.keys(groups);

      // iterate through views
      for (let i = 0; i < groupKeys.length; i++) {
        let fields = groups[groupKeys[i]]["fields"];
        let fieldKeys = Object.keys(fields);

        // iterate through fields
        for (let j = 0; j < fieldKeys.length; j++) {
          let field = fields[fieldKeys[j]];

          if (field["isReadOnly"] === false) {
            editableKeys.push(field["key"]);
          }
        }
      }

      return editableKeys;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  generateFormGroupPreCompletions(
    group: RecursiveFormGroup
  ): RecursiveFormGroup {
    try {
      let formGroup = {};
      let result = {};
      let generatedGroup: FormGroup;

      if (group && group["fields"] && Object.keys(group["fields"]).length > 0) {
        let fields: { [key: string]: RecursiveFormField } = group.fields;
        for (let fieldKey in fields) {
          // set tabindex
          // group["fields"][fieldKey]["tabindex"] = i++;

          let controlKey: string = fields[fieldKey]["key"];
          let controlKeyToLower: string = controlKey.toLowerCase();

          // create list of validators
          let validators = [];

          //generic
          if (fields[fieldKey]["isRequired"]) {
            validators.push(Validators.required);
            validators.push(this.noWhitespaceValidator);
          }

          formGroup[controlKey] = ["", validators];
        }

        // result = this.formbuilder$.group(formGroup);
        generatedGroup = this.formbuilder$.group(formGroup);

        group["formGroup"] = generatedGroup;
      } else {
        result["error"] = true;
        this.logger$.log("no groups or no view");
      }

      return group;
    } catch (error) {
      this.logger$.error(error);
      return null;
    }
  }
}
