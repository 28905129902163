import { Collection, Feature } from "ol";
import { Geometry, GeometryCollection, SimpleGeometry } from "ol/geom";
import _ from "lodash-es";

export type FeatureOrCollection = Feature | Collection<Feature>;

export class CollectionFlattener {
  flattenFeature(feature: FeatureOrCollection): Feature[] {
    // recurse through and flatten the collection
    function helper(feat: FeatureOrCollection): Feature[] {
      if (feat instanceof Feature) {
        return [feat];
      } else if (feat instanceof Collection) {
        if (feat.getLength() > 0) {
          const [first, ...rest] = feat.getArray();
          return [...helper(first), ...helper(new Collection(rest))];
        } else {
          return [];
        }
      }
    }

    return feature instanceof Collection ? helper(feature) : [feature];
  }

  deepClone(feature: Collection<Feature>): Collection<Feature> {
    const clones = feature.getArray().map((f) => {
      const clone = f.clone();
      const id = f.getId();
      if (id !== undefined) {
        clone.setId(id);
      }
      return clone;
    });
    return new Collection(clones);
  }

  flattenGeometry(geom: Geometry): SimpleGeometry[] {
    // recurse through and flatten the collection
    function helper(geom: Geometry): SimpleGeometry[] {
      if (geom instanceof SimpleGeometry) {
        return [geom];
      } else if (geom instanceof GeometryCollection) {
        const arr = geom.getGeometriesArray();
        const len = arr.length;
        if (len > 0) {
          const [first, ...rest] = arr;
          return [...helper(first), ...helper(new GeometryCollection(rest))];
        } else {
          return [];
        }
      }
    }

    return geom instanceof SimpleGeometry ? [geom] : helper(geom);
  }
}
