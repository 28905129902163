import { inject, Injectable } from '@angular/core';
import { SnackbarType } from '../../../modules/shared/snackbar/snackbar/snackbar';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../../../modules/shared/snackbar/snackbar.service';


export type Position = [longitude: number, latitude: number];

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  // services
  private snackBarService = inject(SnackbarService);

  // observables
  private _userLocation$ = new BehaviorSubject<Position>(null);

  constructor() {
    this.updateUserLocation();
  }

  public async updateUserLocation() {
    if ('geolocation' in navigator) {
      return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition((position) => {
            const coords: Position = [position.coords.longitude, position.coords.latitude];
            this._userLocation$.next(coords);
            resolve(coords);
          }, () => {
            this._userLocation$.next(null);
            reject();
          });
        },
      );
    } else {
      this.snackBarService.openSnackbar('Geolocation is not available', SnackbarType.error, 'ERROR');
    }
  }

  get userLocation$() {
    return this._userLocation$.pipe();
  }

  get userLocation() {
    return this._userLocation$.value ?? [0, 0];
  }
}
