export const CreateTicketFormTypes = {
  excavator: 1,
  ticket: 2,
  digsite: 3,
  primaryDetails: 4,
  primaryDetailsPrefill: 5,
  utilities: 6,
};

export class CreateTicketModel {
  body = {};

  // body keys
  EXCAVATOR_DETAILS = "ExcavatorDetails";
  TICKET_DETAILS = "TicketDetails";
  UTILITY_DETAILS = "UtilityDetails";
  DIGSITE_DETAILS = "DigsiteDetails";
  PRIMARY_DETAILS = "PrimaryDetails";
  PRIMARY_DETAILS_PREFILL = "PrimaryDetailsPrefill";

  constructor() {
    this.resetBody();
  }

  setBody(body) {
    this.body = { ...body };
  }

  resetBody() {
    let newBody = {};

    // set body to empty values
    newBody[this.EXCAVATOR_DETAILS] = {};
    newBody[this.TICKET_DETAILS] = {};
    newBody[this.PRIMARY_DETAILS] = [];
    newBody[this.PRIMARY_DETAILS_PREFILL] = [];
    newBody[this.DIGSITE_DETAILS] = [];
    newBody[this.UTILITY_DETAILS] = {
      UtilityID: "1",
      UtilityType: "1",
      PolygonCode: "PRIVATE",
    };

    this.body = newBody;
  }

  resetBodyDefault() {
    let newBody = {};

    // set body to empty values
    newBody[this.EXCAVATOR_DETAILS] = {};
    newBody[this.TICKET_DETAILS] = {};
    newBody[this.PRIMARY_DETAILS] = [];
    newBody[this.PRIMARY_DETAILS_PREFILL] = [];
    newBody[this.DIGSITE_DETAILS] = [];
    newBody[this.UTILITY_DETAILS] = {};

    this.body = newBody;
  }

  updateBody(formType: number, details) {
    let tmpBody = { ...this.body };
    let tmpSubBody = null;

    switch (formType) {
      case CreateTicketFormTypes.excavator:
        tmpSubBody = tmpBody[this.EXCAVATOR_DETAILS];
        tmpSubBody = { ...details };
        tmpBody[this.EXCAVATOR_DETAILS] = { ...tmpSubBody };
        break;
      case CreateTicketFormTypes.ticket:
        tmpSubBody = tmpBody[this.TICKET_DETAILS];
        if (tmpSubBody) {
          tmpSubBody = { ...tmpSubBody, ...details };
        } else if (details) {
          tmpSubBody = { ...details };
        }
        tmpBody[this.TICKET_DETAILS] = { ...tmpSubBody };
        break;
      case CreateTicketFormTypes.digsite:
        tmpBody[this.DIGSITE_DETAILS] = details;
        break;
      case CreateTicketFormTypes.primaryDetails:
        tmpBody[this.PRIMARY_DETAILS] = details;
        break;
      case CreateTicketFormTypes.primaryDetailsPrefill:
        // tmpSubBody = tmpBody[this.PRIMARY_DETAILS_PREFILL];
        // if (tmpSubBody) {
        //   tmpSubBody = [...tmpSubBody, ...details ];
        // } else if (details) {
        //   tmpSubBody = details;
        // }
        tmpBody[this.PRIMARY_DETAILS_PREFILL] = details;
        break;
      case CreateTicketFormTypes.utilities:
        tmpBody[this.UTILITY_DETAILS] = details;
        break;
    }

    this.body = tmpBody;
  }

  getBody(section?: string) {
    if (section) {
      return this.body[section];
    }
    return this.body;
  }
}
