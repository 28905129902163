<ng-template [ngIf]="isBase64" [ngIfElse]="isSrc">
  <!-- <p>pdf-viewer Base!</p> -->
  <ngx-extended-pdf-viewer
    [base64Src]="data"
    useBrowserLocale="true"
    [height]="pdfHeight"
    [showHandToolButton]="showHandToolButton"
    [showOpenFileButton]="showOpenFileButton"
    [showSidebarButton]="showSidebarButton"
    [showFindButton]="showFindButton"
    mobileFriendlyZoom="125%"
    [language]="language"
    [showBookModeButton]="false"
    [showStampEditor]="false"
    [showPagingButtons]="true"
    [showRotateButton]="true"
    [showPrintButton]="true"
    [showDownloadButton]="true"
    [showSecondaryToolbarButton]="true"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="true"
    [showPropertiesButton]="true"
    [showInfiniteScrollButton]="false"
  ></ngx-extended-pdf-viewer>
</ng-template>
<ng-template #isSrc>
  <!-- <p>pdf-viewer Src!</p> -->
  <ngx-extended-pdf-viewer
    [src]="data"
    useBrowserLocale="true"
    [height]="pdfHeight"
    [showHandToolButton]="showHandToolButton"
    [showOpenFileButton]="showOpenFileButton"
    [showSidebarButton]="showSidebarButton"
    [showFindButton]="showFindButton"
    mobileFriendlyZoom="125%"
    [language]="language"
    [showBookModeButton]="false"
    [showStampEditor]="false"
    [showPagingButtons]="true"
    [showRotateButton]="true"
    [showPrintButton]="true"
    [showDownloadButton]="true"
    [showSecondaryToolbarButton]="true"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="true"
    [showPropertiesButton]="true"
    [showInfiniteScrollButton]="false"
  ></ngx-extended-pdf-viewer>
</ng-template>
