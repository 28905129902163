import { Component, inject, Input } from '@angular/core';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { first, tap } from 'rxjs/operators';
import { AbbreviatePipe, TruncatePipe } from '../search-drawer.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { ClickStopPropagationDirective } from '../../../../modules/core/directives/click-stop-propagation.directive';
import { ThrottleClickDirective } from '../../../../modules/core/directives/click-throttle.directive';
import {
  SavedSearch,
  TicketSearchService,
} from '../../../../shared/services/ticket-search/ticket-search.service';
import { firstValueFrom } from 'rxjs';
import { SnackbarType } from '../../../../modules/shared/snackbar/snackbar/snackbar';
import { SnackbarService } from '../../../../modules/shared/snackbar/snackbar.service';
import { ProgressBarService } from '../../../../modules/shared/progress-bar/progress-bar.service';

@Component({
  selector: 'app-saved-search-tile',
  templateUrl: './saved-search-tile.component.html',
  styleUrls: ['./saved-search-tile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    TruncatePipe,
    AbbreviatePipe,
    ThrottleClickDirective,
    ClickStopPropagationDirective,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
})
export class SavedSearchTileComponent {
  // IO
  @Input() collectionTitle;
  @Input() search;
  @Input() isFirstChild = false;
  @Input() isLastChild = false;
  @Input() showCount: boolean = false;
  @Input() showDelete: boolean = false;
  // services
  private snackBar = inject(SnackbarService);
  private progressBarService = inject(ProgressBarService);
  searchDialogOpen = false;
  // CDK overlay config
  positionPairs: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  constructor(public searchService: TicketSearchService) {
  }

  openSearchForm() {
    this.searchDialogOpen = true;
  }

  handleBackdropClick() {
    this.searchDialogOpen = false;
  }

  fireSearch({ SavedSearchCriteria, SearchName }: SavedSearch): void {
    this.progressBarService.start();
    this.searchService.fetchNewSearchResults(this, SavedSearchCriteria, undefined)
      .then(() => {
        this.snackBar.openSnackbar(`Got results for "${SearchName}"`, SnackbarType.success);
        this.progressBarService.stop();
      })
      .catch((err) => this.snackBar.openSnackbar(err, SnackbarType.error));
  }

  deleteClick(search: SavedSearch) {
    this.searchService
      .deleteSavedSearch(search)
      .pipe(
        first(),
        tap((newEvent) => {
          if (newEvent.statusText === 'OK') {
            firstValueFrom(this.searchService.refreshSavedSearches()).then(() => console.log('refreshed'));
          }
        }),
      )
      .subscribe();
  }

  editClick(search: SavedSearch) {
    this.searchService.prefillSearch = search;
    this.searchService.editingSearch = true;
    this.searchService.advancedSearchIsOpen = true;
  }
}
