<div
  style="height: 100%; width: 100%"
  *ngIf="isCameraExist; else noCameraExist"
>
  <div #flash class="wrapper">
    <webcam
      [ngStyle]="{ 'visibility: hidden': !active }"
      [trigger]="triggerObservable"
      [width]="width"
      [height]="height"
      (imageCapture)="handleImage($event)"
      (initError)="handleInitError($event)"
      [videoOptions]="videoOptions"
      [allowCameraSwitch]="true"
    >
    </webcam>
  </div>

  <div class="buttons" fxLayout="row" fxLayoutAlign="space-between center">
    <button
      class="camera-icons"
      color="primary"
      mat-icon-button
      (click)="onFinish()"
    >
      <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <span class="count">{{ count }}/{{ photoLimit }}</span>
    <button
      class="camera-btn"
      color="primary"
      mat-primary
      mat-fab
      (click)="onTakePicture()"
    >
      <mat-icon>circle</mat-icon>
    </button>

    <button
      class="camera-icons"
      mat-icon-button
      color="primary"
      (click)="onFinish()"
    >
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>

<ng-template #noCameraExist>
  <div style="height: 730px; width: 100%; background-color: grey"></div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="
      height: calc(100% - 730px);
      padding: 30px 0 0 30px;
      background-color: black;
    "
  >
    <button
      color="primary"
      mat-primary
      mat-fab
      style="position: relative !important"
    >
      <mat-icon>cancel</mat-icon>
    </button>

    <button
      color="primary"
      mat-primary
      mat-fab
      style="position: relative !important"
      (click)="onTakePicture()"
    >
      <mat-icon>camera</mat-icon>
    </button>

    <button
      color="primary"
      mat-primary
      mat-fab
      style="position: relative !important"
      (click)="onFinish()"
    >
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-template>

<!-- <button (click)="rotate()">Press me to rotate</button>2112151798 -->
