import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  FormControl,
  ControlContainer,
  FormGroupDirective,
  FormGroup,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-searchable-dropdown-old",
  templateUrl: "./searchable-dropdown.component.html",
  styleUrls: ["./searchable-dropdown.component.scss"],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class SearchableDropdownComponent implements OnInit {
  @ViewChild("search") searchTextBox: ElementRef;

  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues: { text: string; value: number }[] = [];

  @Input() field;
  @Input() parentFormGroup: FormGroup;
  @Input() compareOptions;
  @Output() selectValueChanged: EventEmitter<
    { text: string; value: number }[]
  > = new EventEmitter();

  data: any[];

  filteredOptions: Observable<any[]>;

  ngOnInit() {
    this.data = this.field.value.selectOptions?.filter((choice) => {
      return choice.text && choice.value;
    });

    this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
      startWith<string>(""),
      map((name) => this._filter(name))
    );
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    return this.data?.filter(
      (option) => option.text.toLowerCase().indexOf(filterValue) === 0
    );
  }

  selectionChange(event): void {
    const index = this.selectedValues.indexOf(event.source.value);
    if (event.source.selected === false && index >= 0) {
      this.selectedValues.splice(index, 1);
    } else if (event.source.selected === true && index < 0) {
      this.selectedValues.push(event.source.value);
    }
    if (event.isUserInput) {
      this.selectFormControl.patchValue(this.selectedValues);
      this.selectValueChanged.emit(this.selectedValues);
    }
  }

  openedChange(e) {
    this.searchTextboxControl.patchValue("");
    if (e === true) {
      this.searchTextBox.nativeElement.focus();
    } else {
      this.selectValueChanged.emit(this.selectedValues);
    }
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue("");
  }

  setSelectedValues() {
    if (this.selectFormControl.value) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) === -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}
