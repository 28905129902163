/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserModule, HammerGestureConfig } from "@angular/platform-browser";
import { NgModule, Injectable } from "@angular/core";
import { AppComponent } from "./app.component";

import { AppRoutingModule } from "./app-routing.module";
import { environment } from "src/environments/environment";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./modules/shared/material.module";
import { ProgressBarModule } from "./modules/shared/progress-bar/progress-bar.module";
import { LandingModule } from "./modules/landing/landing.module";
import { AppNavModule } from "./modules/app-nav/app-nav.module";
import { SecurityTroubleshootModule } from "./modules/security-troubleshoot/security-troubleshoot.module";
import { UrlInterceptor } from "./modules/core/interceptors/UrlInterceptor";
import { ConfirmDeactivateGuard } from "./modules/core/guards/confirm-deactivate.guard";
import { RoutingPresetModule } from "./modules/routing-preset/routing-preset.module";
import { DocumentViewerModule } from "./modules/document-viewer/document-viewer.module";
import { SchedulerTicketDialogModule } from "./modules/scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component";
import { EditTablesModule } from "./modules/edit-tables/edit-tables.module";
import { SystemMaintenanceHomeComponent } from "./modules/system-maintenance/system-maintenance-home/system-maintenance-home.component";
import { Error404Module } from "./modules/error404/error404.module";
import { RatesModule } from "./modules/rates/rates.module";
import { SearchableDropdownModule } from "./modules/shared/searchable-dropdown/searchable-dropdown.module";
import { FieldsideModule } from "./routes/fieldside/fieldside.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { PageNavigationModule } from "./modules/shared/page-navigation/page-navigation.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TableModule } from "./modules/shared/table/table.module";
import { VersionInformationModule } from "./modules/version-information/version-information.module";
import { AutomatedEngineModule } from "./modules/automated-engine/automated-engine.module";
import { DemoComponent } from "./shared/demo/demo.component";
import { AccountSettingsModule } from "./modules/general-settings/account-settings.module";
import { UserCategoryEditorModule } from "./modules/user-category-editor/user-category-editor.module";
import { InvoicingManagerModule } from "./modules/invoicing-manager/invoicing-manager.module";
import {ReportGeneratorModule} from "./modules/report-generator/report-generator.module";
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  } as any;
}

@NgModule({
  declarations: [AppComponent, SystemMaintenanceHomeComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    ConfirmDeactivateGuard,
  ],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    LandingModule,
    AppNavModule,
    SecurityTroubleshootModule,
    RoutingPresetModule,
    DocumentViewerModule,
    EditTablesModule,
    Error404Module,
    SchedulerTicketDialogModule,
    RatesModule,
    FieldsideModule,
    SearchableDropdownModule,
    VersionInformationModule,
    OverlayModule,
    PageNavigationModule,
    FlexLayoutModule,
    TableModule,
    AutomatedEngineModule,
    DemoComponent,
    AccountSettingsModule,
    UserCategoryEditorModule,
    InvoicingManagerModule,
    ReportGeneratorModule
  ],
})
export class AppModule {}
