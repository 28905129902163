import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "../../../../environments/environment";

import { RegistrationModalComponent } from "./registration-modal/registration-modal.component";
import {
  NavAction,
  SideNavRoute,
} from "../../../modules/system-maintenance/NavigationModels";
import { TicketListItemObject } from "../../../modules/shared/ticket-list/ticket-list-item/ticket-list-item";
import { MatDialog } from "@angular/material/dialog";
import { TicketSyncService } from "../../../modules/shared/ticket/services/ticket-sync/ticket-sync.service";
import { NavBarService } from "../../../modules/shared/ticket/services/router/nav-bar.service";
import { UserService } from "../../../modules/core/services/user/user.service";
import { takeUntil } from "rxjs/operators";
import { LoggerService } from "../../../modules/core/services/logger/logger.service";
import { SettingID } from "src/app/modules/core/services/user/setting";

@Component({
  selector: "app-fieldside",
  templateUrl: "./fieldside.component.html",
  styleUrls: ["./fieldside.component.scss"],
})
export class FieldsideComponent implements OnInit, OnDestroy {
  appVersion: string = environment.appVersion;
  FIELDSIDE_ROUTES: SideNavRoute[] = [
    // {
    //   title: 'Notifications',
    //   route: "",
    //   icon: "notifications"
    // },
    // {
    //   title: 'Dashboard',
    //   route: "",
    //   icon: "notifications"
    // },
    {
      title: "Ticket Summary",
      route: "ticket-summary",
      icon: "list",
      action: NavAction.ticketSummary,
    },

    // {
    //   title: 'Reset Application',
    //   route: "login",
    //   icon: "clear",
    // },
    {
      title: "Version Information",
      route: "version-information",
      icon: "info",
      action: NavAction.versionInformation,
    },
  ];
  ticketList: TicketListItemObject[] = [];
  onSyncClick: Subject<any>;
  firstSync: any;
  isValidDownload: boolean;
  public sidenavTitle = "TICKET SUMMARY";
  public sidenavSubTitle = "TICKET SUMMARY";
  myWorkRoutes = this.FIELDSIDE_ROUTES;
  @ViewChild("menuToggle") menuToggle;
  @ViewChild("sidenav") sidenav;

  @Input() sidenavState = "mini";
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private ticketSyncService: TicketSyncService,
    private router: Router,
    private route: ActivatedRoute,
    private navBarService: NavBarService,
    private userService: UserService,
    private loggerService: LoggerService
  ) {
    this.onSyncClick = new Subject<any>();
    this.firstSync = this.route.snapshot.queryParams.firstSync;
    this.isValidDownload = false;
  }

  async ngOnInit() {
    this.navBarService
      .getInnerNavChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe((nextVal) => {
        this.sidenavTitle = nextVal.Title;
        this.sidenavSubTitle = nextVal.SubTitle;
      });
      if(this.userService.isSettingActive(SettingID.SHOW_PUNCH_CLOCK)){
        this.FIELDSIDE_ROUTES.splice(1,0,{
          title: "Punch In/Out",
          route: "punch-clock",
          icon: "stopwatch",
          action: NavAction.punchClock,
        })
      }
  
  }

  openDialog() {
    const dialogRef = this.dialog.open(RegistrationModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resetLocalDatabase();
      }
    });
  }

  public handleRouteClick(item) {
    try {
      if (item && item.action) {
        switch (item.action) {
          case NavAction.ticketSummary:
            this.navigate(NavAction.ticketSummary);
            this.sidenav.toggle()
            break;
          case NavAction.punchClock:
            this.navigate(NavAction.punchClock);
            break;
          case NavAction.map:
            this.navigate(NavAction.map);
            this.sidenav.toggle()
            break;
          case NavAction.versionInformation:
            this.navigate(NavAction.versionInformation);
            this.sidenav.toggle()
            break;
            break;
          case NavAction.reset:
            this.openDialog();
            break;
          default:
            break;
        }
      }

      if (item.route) {
        // this.changeRoute(item.route);
      }
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  public navigate(navAction: NavAction) {
    try {
      switch (navAction) {
        case NavAction.ticketSummary:
          this.router.navigate(["ticket-summary"], { relativeTo: this.route });
          break;
        case NavAction.map:
          this.router.navigate(["map"], { relativeTo: this.route });
          break;
        case NavAction.punchClock:
          this.router.navigate(["punch-clock"], { relativeTo: this.route });
          break;
        case NavAction.versionInformation:
          this.router.navigate(["version-information"], { relativeTo: this.route });
          break;
        default:
          break;
      }
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  public resetLocalDatabase() {
    this.userService.logoutUserAndDeregister();
    this.router.navigate(["login"]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
