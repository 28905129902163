import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LonLat, OpenLayersService } from '../services/open-layers.service';
import { LayerManagerService } from '../services/layer-manager.service';
import { LayerQueryFilters, ShapeType } from '../utilities/types';
import { MapFeatureService } from '../services/map-feature.service';
import { FloatingActionMenuComponent } from '../../shared/fab/floating-action-menu/floating-action-menu.component';
import { combineLatestWith, firstValueFrom, from, fromEvent, Subject } from 'rxjs';
import { CaptureFrameComponent } from '../drawing-capture/capture-frame.component';
import { DrawingToolbarComponent } from '../drawing-toolbar/drawing-toolbar.component';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatIconButton } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchableDropdownComponent } from '../../../shared/components/inputs/searchable-dropdown/searchable-dropdown.component';
import { DrawingService } from '../drawing.service';
import { LocationService } from '../../../shared/services/location/location.service';
import { concatMap, filter, finalize, map, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { MapFeatureInspectorComponent } from '../../../shared/components/maps/open-layers/map-feature-inspector/map-feature-inspector.component';
import Map from 'ol/Map';
import { Collection, Feature, MapBrowserEvent, Overlay } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Circle, Geometry, Point } from 'ol/geom';
import _, { isEqual } from 'lodash-es';
import { InspectorContentComponent } from '../../../shared/components/maps/open-layers/map-feature-inspector/inspector-content/inspector-content.component';
import { fromLonLat } from 'ol/proj';
import { DrawingSettingsService, MapSettings } from '../drawing-settings.service';
import { GeoJSON } from 'ol/format';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import { DrawingCategory } from '../services/drawing-manager.service';
import { DigsiteAreaService } from '../../../shared/services/digsite-area/digsite-area.service';
import { MapOverlayContainerComponent } from '../../../shared/components/maps/open-layers/overlays/map-overlay-container/map-overlay-container.component';
import { MapInteractionService } from '../services/map-interaction.service';
import {
  ContextSelection,
  MapContextMenuComponent,
} from '../../../shared/components/maps/open-layers/overlays/map-context-menu/map-context-menu.component';
import {
  FeatureEditMenuComponent,
  FeatureEdits,
} from '../../../shared/components/maps/open-layers/overlays/feature-edit-menu/feature-edit-menu.component';

@Component({
  selector: 'app-drawing-map',
  standalone: true,
  imports: [
    CommonModule,
    CaptureFrameComponent,
    DrawingToolbarComponent,
    FloatingActionMenuComponent,
    MatDivider,
    MatIcon,
    MatIconButton,
    ReactiveFormsModule,
    FormsModule,
    SearchableDropdownComponent,
    MapFeatureInspectorComponent,
    InspectorContentComponent,
    MapContextMenuComponent,
    MapOverlayContainerComponent,
    FeatureEditMenuComponent,
  ],
  template: `
    <div class="relative flex h-full w-full">
      <div #map class="h-full w-full"></div>
    </div>
    <div #inspector class="size-fit">
      <app-map-feature-inspector>
        <app-inspector-content [data]="data"></app-inspector-content>
      </app-map-feature-inspector>
    </div>
    <div #contextMenu class="size-fit">
      <app-context-menu
        (optionSelected)="contextOptionSelected($event)"
        [contextEvent]="interactionService.contextEvent$ | async" />
    </div>
    <div #featureEditMenu class="size-fit">
      <app-feature-edit-menu
        (closed)="handleEditMenuClosed()"
        (featuresEdited)="handleFeaturesEdited($event)"
        [contextSelection]="contextSelection$$()" />
    </div>
  `,
})
export class DrawingMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('map') mapTarget: ElementRef;
  @ViewChild('inspector') inspector: ElementRef;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  // IO
  @Input() layerFilterParameters: Partial<LayerQueryFilters>;
  @Input() ticket: Record<string, unknown>;
  @Input() coordinates: LonLat;
  @Input() drawingCategory: DrawingCategory;
  @Input() shapeType: ShapeType;
  @Output() mapTargetRendered = new EventEmitter<HTMLDivElement>();
  // observables
  destroy$: Subject<void> = new Subject<void>();
  protected drawingService = inject(DrawingService);
  protected drawingSettingsService = inject(DrawingSettingsService);
  protected digsiteAreaService = inject(DigsiteAreaService);
  protected interactionService = inject(MapInteractionService);
  protected contextSelection$$ = signal<any>(null);
  protected data = [];
  // members
  protected inspectorOverlay: Overlay;
  protected contextMenuOverlay: Overlay;
  protected locatorPin: Feature<Point>;
  protected ticketPin: Feature<Point>;
  protected reader: GeoJSON = new GeoJSON();
  // services
  private locationService = inject(LocationService);
  private cdr = inject(ChangeDetectorRef);

  constructor(
    private layerService: LayerManagerService,
    private featureService: MapFeatureService,
    protected openLayersService: OpenLayersService
  ) {
    this.locatorPin = new Feature({
      geometry: new Point(fromLonLat([0, 0])),
      colour: '#2cd8f6',
    });
    this.ticketPin = new Feature({
      geometry: new Point(fromLonLat([0, 0])),
      colour: '#da2020',
    });
  }

  ngOnInit(): void {
    // watch for changes in the drawing mode and hide the inspector when not in inspection mode
    this.drawingService.inspectModeEnabled$.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
      if (!enabled) {
        this.inspectorOverlay?.setPosition(undefined);
        this.cdr.markForCheck();
      }
    });

    // watch for changes in the map and the layers and update the map
    this.openLayersService.map$
      .pipe(combineLatestWith(this.layerService.managedLayers$), takeUntil(this.destroy$))
      .subscribe(([olMap, layers]) => {
        if (olMap && layers) {
          olMap.setLayers(layers.map((layer) => layer.getLayer()));
          olMap.addLayer(this.layerService.miscLayer);
        }
      });

    // watch for changes in the map settings and update the pins
    this.drawingSettingsService.mapSettings$
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.layerService.miscLayer.getSource().removeFeature(this.locatorPin);
          this.layerService.miscLayer.getSource().removeFeature(this.ticketPin);
        })
      )
      .subscribe((settings: MapSettings) => {
        if (settings.pinUserLocation) {
          try {
            this.layerService.miscLayer.getSource().addFeature(this.locatorPin);
          } catch {
            console.log('Error: add UserLocation failed.');
          }
        } else {
          try {
            this.layerService.miscLayer.getSource().removeFeature(this.locatorPin);
          } catch {
            console.log('Error: remove UserLocation failed.');
          }
        }
        if (settings.pinTicketLocation) {
          try {
            this.layerService.miscLayer.getSource().addFeature(this.ticketPin);
          } catch {
            console.log('Error: add TicketLocation failed.');
          }
        } else {
          try {
            this.layerService.miscLayer.getSource().removeFeature(this.ticketPin);
          } catch {
            console.log('Error: remove TicketLocation failed.');
          }
        }
      });
    this.openLayersService.initMap();
    this.openLayersService.useMapView();
  }

  ngAfterViewInit() {
    this.mapTargetRendered.emit(this.mapTarget.nativeElement);
    this.inspectorOverlay = new Overlay({
      element: this.inspector.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
      position: undefined,
    });
    this.openLayersService.attachOverlay(this.inspectorOverlay);
    this.addClickListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coordinates) {
      if (
        changes.coordinates.currentValue &&
        Array.isArray(changes.coordinates.currentValue) &&
        changes.coordinates.currentValue.length === 2
      ) {
        this.ticketPin.getGeometry().setCoordinates(fromLonLat(changes.coordinates.currentValue));
      } else {
        this.ticketPin.getGeometry().setCoordinates(fromLonLat([0, 0]));
      }
    }

    if (
      changes.layerFilterParameters &&
      !isEqual(changes.layerFilterParameters.currentValue, changes.layerFilterParameters.previousValue)
    ) {
      this.layerService.filters = this.layerFilterParameters;
    }

    if (changes.ticket && !isEqual(changes.ticket.currentValue, changes.ticket.previousValue)) {
      const { AssignmentID } = this.ticket;
      this.featureService.getTicketSubject().next(this.ticket);
      firstValueFrom(this.digsiteAreaService.fetchDigSiteAreas([AssignmentID as number])).then((digSiteShapes) => {
        if (digSiteShapes instanceof Error) {
          this.layerService.digSites = [];
          return;
        }
        this.layerService.digSites = digSiteShapes;
      });
    }

    this.locationService.updateUserLocation().then((location: Array<number>) => {
      this.locatorPin.getGeometry().setCoordinates(fromLonLat(location));
    });
  }

  ngOnDestroy() {
    this.layerService.filters = null;
    this.layerService.digSites = [];
    this.openLayersService.removeOverlay(this.inspectorOverlay);
    this.openLayersService.removeOverlay(this.contextMenuOverlay);
    this.drawingService.drawingModeEnabled = false;
    this.destroy$.next();
  }

  contextOptionSelected(selection: ContextSelection) {
    switch (selection.value) {
      case 'delete':
        this.interactionService.deleteFeatures();
        break;
      case 'edit':
        this.contextSelection$$.set(selection);
        break;
    }
  }

  handleFeaturesEdited(changes: ContextSelection & FeatureEdits) {
    const context = { ...changes };
    this.contextSelection$$.set(null);
    const clone = context.feature.clone();
    clone.setProperties(context.edits);
    clone.setId(context.feature.getId());
    this.interactionService.featureUpdated(
      new Collection<Feature>([context.feature]),
      new Collection<Feature>([clone]),
      context.layer.getSource()
    );
  }

  handleEditMenuClosed() {
    console.log('closed');
    this.contextSelection$$.set(null);
  }

  addClickListeners() {
    this.openLayersService.map$
      .pipe(
        filter((olMap) => olMap instanceof Map),
        switchMap((olMap: Map) =>
          fromEvent(olMap, 'singleclick').pipe(
            filter(() => this.drawingService.inspectModeEnabled),
            tap(() => (this.data = [])),
            tap((evt: MapBrowserEvent<UIEvent>) => {
              const coordinate = evt.coordinate;
              this.inspectorOverlay.setPosition(coordinate);
            }),
            switchMap((evt: MapBrowserEvent<UIEvent>) =>
              from(olMap.getAllLayers()).pipe(
                filter((x: Layer) => x.isVisible()),
                map(async (layer: Layer) => {
                  // handle ESRI map server
                  if (layer.get('LayerType') === 1) {
                    return await this.getEsriMapServerFeatures(evt, layer);
                  } else if (layer.get('LayerType') === 2) {
                    return await this.getGeoserverWMSFeatures(evt, layer);
                  }
                  // anything other than vector layer we won't handle for now
                  if (!(layer instanceof VectorLayer)) {
                    return [];
                  }
                  // handle vector layer
                  return this.getVectorLayerFeatures(evt, layer);
                }),
                concatMap((x) => from(x)),
                filter((x) => x.length > 0),
                concatMap((features: Feature[]) =>
                  from(features).pipe(
                    map((feature) => feature.getProperties()),
                    map((props) => _.omit(props, ['geometry'])),
                    map((props) => _.pickBy(props, (x: any) => x !== null && x !== 'Null'))
                  )
                ),
                toArray(),
                tap((data) => {
                  this.data = data;
                  this.cdr.detectChanges();
                })
              )
            )
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {});
  }

  private getVectorLayerFeatures(evt: MapBrowserEvent<UIEvent>, layer: VectorLayer<VectorSource>) {
    const source = layer.getSource();
    const select = new Feature(new Circle(evt.coordinate, 0.5));
    source.addFeature(select);
    const extent = select.getGeometry().getExtent();
    source.removeFeature(select);
    return source.getFeaturesInExtent(extent);
  }

  private async getGeoserverWMSFeatures(evt: MapBrowserEvent<UIEvent>, layer: Layer) {
    const map = evt.map;
    const view = map.getView();
    const layerUrl = layer.get('LayerURL');
    if (!layerUrl) {
      return [];
    }
    const layerList = layer.get('sub_layer_list');
    const parsedUrl = new URL(layerUrl);
    const size = map.getSize();
    const pixelCoord = map.getPixelFromCoordinate(evt.coordinate).map((x) => Math.round(x));
    const url =
      parsedUrl.toString() +
      '?' +
      [
        ['INFO_FORMAT', 'application/json'],
        ['REQUEST', 'GetFeatureInfo'],
        ['SERVICE', 'WMS'],
        ['VERSION', '1.1.1'],
        ['WIDTH', size[0]],
        ['HEIGHT', size[1]],
        ['X', pixelCoord[0]],
        ['Y', pixelCoord[1]],
        ['BUFFER', (10).toString(10)],
        ['BBOX', view.calculateExtent(size)],
        ['SRS', view.getProjection().getCode()],
        ['LAYERS', layerList],
        ['QUERY_LAYERS', layerList],
        ['EXCEPTIONS', 'JSON'],
        ['FEATURE_COUNT', (1000).toString(10)],
      ]
        .map((x: [string, string]) => x.join('='))
        .join('&');

    try {
      const response = await (await fetch(url)).json();
      const { exceptions } = response;
      if (exceptions && exceptions.length > 0) {
        return [];
      }
      return this.reader.readFeatures(response);
    } catch (e) {
      // do nothing; non-vital
    }
    return [];
  }

  private async getEsriMapServerFeatures(evt: MapBrowserEvent<UIEvent>, layer: Layer) {
    const map = evt.map;
    const view = map.getView();
    const layerUrl = layer.get('LayerURL');
    if (!layerUrl) {
      return [];
    }
    const parsedUrl = new URL(layerUrl);
    const url =
      parsedUrl.toString() +
      '/identify?' +
      [
        ['f', 'json'],
        ['TOKEN', layer.get('Token')],
        ['geometryType', 'esriGeometryPoint'],
        ['geometry', evt.coordinate.join(',')],
        ['tolerance', Number(10).toString()],
        ['mapExtent', view.calculateExtent(map.getSize()).join(',')],
        ['imageDisplay', map.getSize().join(',') + ',96'],
        ['sr', view.getProjection().getCode().split(':')[1]],
      ]
        .map((x: [string, string]) => x.join('='))
        .join('&');

    try {
      const { results }: MapServiceIdentifyResult = await (await fetch(url)).json();
      return results.map((result) => {
        const feat = new Feature({
          geometry: new Geometry(),
        });
        feat.setProperties({ ...result.attributes });
        feat.set('layer name', result.layerName);
        return feat;
      });
    } catch (e) {
      // do nothing; non-vital
    }
    return [];
  }
}

export type MapServiceIdentifyResult = {
  results: Result[];
};

export type Result = {
  layerId: number;
  layerName: string;
  value: string;
  displayFieldName: string;
  attributes: Attributes;
  geometryType: string;
  hasZ: boolean;
  hasM: boolean;
  geometry: number;
};

export type Attributes = Record<string, any>;

export type GeoserverExceptionResponse = {
  version: string;
  exceptions: Exception[];
};
export type Exception = {
  code: string;
  locator: string;
  text: string;
};
