import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { ApiService, UtilocateApiRequest } from "../../api/baseapi.service";
import { LoggerService } from "../logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class CreateXMLService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {
    this.logger$.log("Created Upload Ticket Service");
  }

  createWellRecordXML_(primaryID: number | string): Observable<any> {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.xmlTemplateController;
        const type = api[url].type;

        let utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.xmlTemplateController,
          API_TYPE: type,
          API_BODY: {
            PrimaryID: primaryID,
            action: "1",
          },
        };

        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)
        ).subscribe((apiResult) => {
          console.log("here");

          subscriber.next(apiResult);
          subscriber.complete();
        });
      } catch (error) {
        console.log(error);

        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  createWellRecordXML(primaryID: number | string): Promise<any> {
    try {
      const url = apiKeys.u2.xmlTemplateController;
      const type = api[url].type;

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.xmlTemplateController,
        API_TYPE: type,
        API_BODY: {
          PrimaryID: primaryID,
          action: "1",
        },
      };

      return this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
