import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormHelperService {
  className = "FormHelperService";

  constructor() {}

  // given a view, return all keys that are not readonly
  getKeysThatCanBeEdited(view) {
    try {
      let editableKeys = [];

      let groups = view["groups"];
      let groupKeys = Object.keys(groups);

      // iterate through views
      for (let i = 0; i < groupKeys.length; i++) {
        let fields = groups[groupKeys[i]]["fields"];
        let fieldKeys = Object.keys(fields);

        // iterate through fields
        for (let j = 0; j < fieldKeys.length; j++) {
          let field = fields[fieldKeys[j]];

          if (field["isReadOnly"] === false) {
            editableKeys.push(field["key"]);
          }
        }
      }

      return editableKeys;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  // given the parsed json (views obj), return json with fields grouped by their db table
  getFieldsGroupedByDatabaseTables(views) {
    try {
      let fields = this.getAllFullFields(views);
      let groupedFields = fields.reduce((total, current) => {
        if (total[current["tableName"]] == null) {
          total[current["tableName"]] = {};
        }

        total[current["tableName"]][current["key"]] = "";
        return total;
      }, {});

      return groupedFields;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  // this returns all the fields in JSON form
  private getAllFullFields(view) {
    try {
      let allKeys = [];
      let groups = view;
      let groupKeys = Object.keys(groups);

      // iterate through views
      for (let i = 0; i < groupKeys.length; i++) {
        let fields = groups[groupKeys[i]]["fields"];
        let fieldKeys = Object.keys(fields);

        // iterate through fields
        for (let j = 0; j < fieldKeys.length; j++) {
          let field = fields[fieldKeys[j]];

          if (field["isReadOnly"] === false) {
            allKeys.push(field);
          }
        }
      }
      return allKeys;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getAllDateFields(groups) {
    try {
      let dateFieldKeys = [];

      // iterate through groups
      let groupKeys = Object.keys(groups);
      for (let i = 0; i < groupKeys.length; i++) {
        let groupFields = groups[groupKeys[i]]["fields"];
        let groupFieldKeys = Object.keys(groupFields);

        for (let j = 0; j < groupFieldKeys.length; j++) {
          let field = groupFields[groupFieldKeys[j]];

          if (field["inputTypeID"] == 10) {
            dateFieldKeys.push(field["key"]);
          }
        }
      }

      return dateFieldKeys;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
