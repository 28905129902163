import { Pipe, PipeTransform } from "@angular/core";
import { MenuItem } from "./drawing-tool-menus";

@Pipe({
  name: "MenuItemToRadioInput",
  standalone: true,
})
export class MenuItemToRadioInputPipe implements PipeTransform {
  transform(value: MenuItem[]): {
    value: number;
    altText: string;
    imgSource: string;
  }[] {
    return value.map((val) => {
      return {
        value: val.name,
        altText: val.altText,
        imgSource: val.imgSource,
      };
    });
  }
}

interface TrimmableFile {
  Name: string;
}
@Pipe({
  name: "TrimFileExtension",
  standalone: true,
})
export class TrimFileExtensionPipe implements PipeTransform {
  transform<T extends TrimmableFile>(value: T): string {
    return value.Name.replace(".jpg", "");
  }
}
