import { FormGroup } from "@angular/forms";

//! interfaces
export interface FormTemplateOptionPatchValue {
  viewKey: string;
  controlKeys: string[];
}
export interface FormTemplateOptionValues {
  text: string;
  value: string;
}
export interface FormTemplateOptions {
  hasValues: boolean;
  optionsFillID?: number;
  values?: FormTemplateOptionValues[];
  patchValues?: FormTemplateOptionPatchValue[];
}

export interface FormTemplateField {
  appearance: string;
  inputTypeID: number;
  label: string;
  key: string;
  placeholder: string;
  matches: string;
  isRequired: boolean;
  isReadOnly: boolean;
  width_xs: number | string;
  width_sm: number | string;
  width_md: number | string;
  width_lg: number | string;
  width_xl: number | string;
  fieldOrder: number;
  isVisible: boolean;
  isHideShow?: boolean;
  tableName?: string;
  options?: FormTemplateOptions;
}

export interface FormTemplateGroup {
  header: string;
  subtitle: string;
  key: string;
  groupOrder: number;
  hasHiddenValues?: boolean;
  fillStageID?: number;
  fillKey?: string;
  fields: {
    [key: string]: FormTemplateField;
  };
}

export interface FormTemplateView {
  header: string;
  key: string;
  groups: {
    [key: string]: FormTemplateGroup;
  };
  formGroup?: FormGroup;
  showHeader?: boolean;
}

export interface FormTemplateViews {
  views: {
    [key: string]: FormTemplateView;
  };
}

//! classes
export class FormTemplateField implements FormTemplateField {
  constructor(
    key?: string,
    label?: string,
    inputTypeID?: number,
    fieldOrder?: number,
    fieldsViewOnly: boolean = false
  ) {
    this.width_xs = "100";
    this.width_sm = "100";
    this.width_md = "100";
    this.width_lg = "100";
    this.width_xl = "100";
    this.appearance = "fill";
    this.isReadOnly = fieldsViewOnly;
    this.isRequired = false;
    this.isVisible = true;
    this.isHideShow = false;

    this.key = key;
    this.label = label;
    this.placeholder = label;
    this.inputTypeID = inputTypeID;
    this.fieldOrder = fieldOrder;
  }

  makeDefault() {
    this.key = "noFieldDefault";
    this.label = " ";
    this.placeholder = " ";
    this.inputTypeID = 0;
    this.fieldOrder = 1;
    this.matches = "";
  }
}

export class FormTemplateGroup implements FormTemplateGroup {
  constructor(header?: string, key?: string, groupOrder?: number, subtitle?: string) {
    this.header = header;
    this.key = key;
    this.groupOrder = groupOrder;
    this.subtitle = subtitle;
  }

  makeDefault() {
    this.header = "Nothing to show";
    this.key = "noGroupsDefault";
    this.groupOrder = 1;

    let defaultField: FormTemplateField = new FormTemplateField();
    defaultField.makeDefault();
    this.fields = {
      defaultField: defaultField,
    };
  }
}

export class FormTemplateView implements FormTemplateView {
  constructor(
    header?: string,
    key?: string,
    groups?: { [key: string]: FormTemplateGroup },
    showHeader: boolean = true,
  ) {
    this.header = header;
    this.key = key;
    this.groups = groups;
    this.showHeader = showHeader;
  }

  makeDefault() {
    this.header = "No Views";
    this.key = "noViews";
    this.showHeader = true;
    let defaultGroup: FormTemplateGroup = new FormTemplateGroup();
    defaultGroup.makeDefault();
    this.groups = {
      defaultGroup: defaultGroup,
    };
  }
}

export class FormTemplateViews implements FormTemplateViews {
  constructor() {}

  makeDefault() {
    let newView: FormTemplateView = new FormTemplateView();
    newView.makeDefault();
    this.views = {
      defaultView: newView,
    };
  }
}
