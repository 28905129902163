import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "DocumentTypeIDDesc",
})
export class DocumentTypeIDDescPipe implements PipeTransform {
  transform(value: any) {
    value = value.toString();

    switch (value) {
      case "1":
        value = "OOC Sketch";
        break;
      case "2":
        value = "Map Discrepancy";
        break;
      case "3":
        value = "PDF";
        break;
      case "4":
        value = "Internal";
        break;
      case "5":
        value = "Drawing";
        break;
      case "6":
        value = "Old Document";
        break;
      case "7":
        value = "DBF";
        break;
      case "8":
        value = "GML";
        break;
      case "9":
        value = "Blank Locate";
        break;
      case "10":
        value = "Office Internal";
        break;
      case "11":
        value = "Original Email";
        break;
      case "12":
        value = "Pre Completions PDF";
        break;
      case "13":
        value = "Well Record XML";
        break;
      case "14":
        value = "Sewer Lateral Auxiliary PDF";
        break;
      case "15":
        value = "Signature";
        break;
      case "16":
        value = "Generated Report";
        break;
      case "100":
        value = "Basemap Drawing";
        break;
      case "101":
        value = "Primary Drawing";
        break;
      case "102":
        value = "Aux Drawing";
        break;
      case "103":
        value = "Internal Drawing";
        break;
      case "104":
        value = "Presketch Drawing";
        break;
      case "105":
        value = "Cloud PDF";
        break;
      case "106":
        value = "Auxiliary Image";
        break;
      case "107":
        value = "Completion Document";
        break;
      case "108":
        value = "Primary Image";
        break;
    }

    return value;
  }
}
