import { Component, OnInit } from "@angular/core";
import { ActionID, RoutingPresetService } from "../routing-preset.service";
import { Algorithm, RuleType } from "../algorithm";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { SnackbarType } from "../../shared/snackbar/snackbar/snackbar";
import { ModalService } from "../../shared/modals/modal.service";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
@Component({
  selector: "app-routing-preset",
  templateUrl: "./routing-preset.component.html",
  styleUrls: ["./routing-preset.component.scss"],
})
export class RoutingPresetComponent implements OnInit {
  DELETE_MODAL_ID: string = "id_delete_algorithm_dialog";
  SAVE_MODAL_ID: string = "id_save_algorithm_dialog";

  algorithms: Algorithm[] = [];

  algorithmID: number;

  algorithm: Algorithm = {
    AlgorithmID: null,
    AlgorithmName: null,
    AlgorithmDesc: null,
    Rules: [],
    isDefault: 0,
    ActionID: 0,
    AlgorithmColour: null,
  };

  ruleTypes: RuleType[] = [];

  selectedColour: string = null;

  numUsersOnAlgorithmMessage: string;

  nextAlgorithmID: number;

  colorPalette: Array<any> = [
    "#666666",
    "#fc0303",
    "#188523",
    "#2e82ff",
    "#7f49b8",
    "#ff9500",
    "#ff57eb",
  ];

  constructor(
    private presetService: RoutingPresetService,
    private snackBarService: SnackbarService,
    private modalService: ModalService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.algorithmID = params["algorithmid"];
      this.changeAlgorithm(this.algorithmID);
    });

    await this.getAlgorithms();
  }

  /**
   * alert user of unsaved changes if any, otherwise canExit is true
   * @returns true if all changes have been saved
   */
  canExit(): boolean {
    return !this.hasChangesToSave();
  }

  /**
   * check algorithm and rules for unsaved changes
   * @returns boolean true if unsaved changes exist
   */
  hasChangesToSave() {
    let hasChanges = false;
    if (
      this.algorithm.ActionID == ActionID.UPDATE ||
      this.algorithm.ActionID == ActionID.CREATE
    ) {
      hasChanges = true;
    } else {
      for (let i in this.algorithm.Rules) {
        if (
          this.algorithm.Rules[i].ActionID == ActionID.CREATE ||
          this.algorithm.Rules[i].ActionID == ActionID.UPDATE ||
          this.algorithm.Rules[i].ActionID == ActionID.DELETE
        ) {
          hasChanges = true;
          break;
        }
      }
    }

    return hasChanges;
  }

  /**
   * call presetService getAlgorithms, set class variables and alert user of error if any
   */
  async getAlgorithms() {
    let result = await this.presetService.getAlgorithms().toPromise();
    if (result && result.body) {
      if (result.body.Error) {
        this.snackBarService.openSnackbar(
          result.body.Error,
          SnackbarType.error
        );
      } else if (result.body.Value) {
        this.ruleTypes = result.body.Value.RuleTypes;
        this.algorithms = result.body.Value.Algorithms;
      } else {
        this.snackBarService.openSnackbar(
          "Failed to get Algorithms",
          SnackbarType.error
        );
      }
    }
  }

  /**
   * get specific algorithm based on this.algorithmID value
   */
  async getAlgorithm() {
    if (this.algorithmID) {
      let result = await this.presetService
        .getAlgorithm(this.algorithmID)
        .toPromise();
      if (result && result.body) {
        if (result.body.Error) {
          this.snackBarService.openSnackbar(
            result.body.Error,
            SnackbarType.error
          );
        } else if (result.body.Value) {
          this.algorithm = this.formatAlgorithm(result.body.Value.Algorithm);
          this.orderRules();
          this.ruleTypes = result.body.Value.RuleTypes;
        } else {
          this.snackBarService.openSnackbar(
            "Failed to get Algorithm",
            SnackbarType.error
          );
        }
      }
    } else {
      // If no algorithm found open page with new algorithm
      this.algorithm.ActionID = 1;
    }
  }

  /**
   * copy algorithm and format parts of the data
   * @param algorithmData algorithm to be formatted
   * @returns formatted algorithm
   */
  formatAlgorithm(algorithmData: Algorithm) {
    let algorithm: Algorithm = { ...algorithmData, ActionID: 0 };
    if (algorithm.Rules && algorithm.Rules.length > 0) {
      algorithm.Rules.forEach((rule) => {
        if (rule.RuleType.RuleTypeInputID == 1) {
          rule.RuleValue = Number(rule.RuleValue);
        }
      });
    }
    return algorithm;
  }

  /**
   * loop algorithms and return default
   * @returns id of algorithm with isDefault=1
   */
  getDefaultAlgorithm(): number {
    if (this.algorithms.length > 0) {
      let defaultAlgoithmID = this.algorithms[0].AlgorithmID;
      for (let i in this.algorithms) {
        if (this.algorithms[i].isDefault) {
          defaultAlgoithmID = this.algorithms[i].AlgorithmID;
        }
      }
      return defaultAlgoithmID;
    }
    return null;
  }

  /**
   * check for unsaved changes and then call change algorithm
   * @param algorithmID id of algorithm selected to change to
   */
  algorithmSelected(algorithmID: number) {
    if (this.hasChangesToSave()) {
      this.nextAlgorithmID = algorithmID;
      this.modalService.open(this.SAVE_MODAL_ID);
    } else {
      this.changeAlgorithm(algorithmID);
    }
  }

  /**
   * change selected algorithm without saving
   */
  changeWithoutSaving() {
    this.modalService.close(this.SAVE_MODAL_ID);
    this.changeAlgorithm(this.nextAlgorithmID);
  }

  /**
   * call saveAlgorithm before switching selected algorithm
   */
  async changeWithSaving() {
    this.modalService.close(this.SAVE_MODAL_ID);
    await this.saveAlgorithm();
    this.changeAlgorithm(this.nextAlgorithmID);
  }

  /**
   * update selected algorithm id and call getAlgorithm
   * @param algorithmID id of newly selected algorithm
   */
  async changeAlgorithm(algorithmID: number) {
    this.selectedColour = null;
    this.algorithmID = algorithmID;
    await this.getAlgorithm();
  }

  /**
   * call preset service function to update isDefault to 1 for this algorithm (and remove old default)
   */
  setAsDefault() {
    this.presetService
      .setDefault(this.algorithm.AlgorithmID)
      .subscribe((result) => {
        if (result && result.body) {
          if (result.body.Error) {
            this.snackBarService.openSnackbar(
              result.body.Error,
              SnackbarType.error
            );
          } else {
            this.presetService.emitReloadAlgorithms(this.algorithm.AlgorithmID);
            this.snackBarService.openSnackbar(
              "Algorithm Set as Default",
              SnackbarType.success
            );
            this.algorithm.isDefault = 1;
          }
        }
      });
  }

  /**
   * call preset service setAll and alert user to error if any
   */
  setAll() {
    this.presetService
      .setAll(this.algorithm.AlgorithmID)
      .subscribe((result) => {
        if (result && result.body) {
          if (result.body.Error) {
            this.snackBarService.openSnackbar(
              result.body.Error,
              SnackbarType.error
            );
          } else {
            this.snackBarService.openSnackbar(
              "Users set to this Algorithm",
              SnackbarType.success
            );
          }
        }
      });
  }

  /**
   * call preset service modifyAlgorithm and alert user to error if any
   */
  async saveAlgorithm() {
    let result = await this.presetService
      .modifyAlgorithm(this.algorithm)
      .toPromise();
    if (result && result.body) {
      if (result.body.Error) {
        this.snackBarService.openSnackbar(
          result.body.Error,
          SnackbarType.error
        );
      } else {
        this.resetActionIDs();
        this.snackBarService.openSnackbar(
          "Algorithm Saved",
          SnackbarType.success
        );
      }
    }
  }

  /**
   * call presetService to delete algorithm from list of algorithms
   */
  async deleteAlgorithm() {
    this.algorithm.ActionID = ActionID.DELETE;
    this.modalService.close(this.DELETE_MODAL_ID);
    let result = await this.presetService
      .modifyAlgorithm(this.algorithm)
      .toPromise();
    if (result && result.body) {
      if (result.body.Error) {
        this.snackBarService.openSnackbar(
          result.body.Error,
          SnackbarType.error
        );
      } else {
        this.presetService.emitReloadAlgorithms(this.algorithm.AlgorithmID);
        this.resetActionIDs();
        this.snackBarService.openSnackbar(
          "Algorithm Deleted",
          SnackbarType.success
        );
      }
    }
  }

  resetActionIDs() {
    this.algorithm.ActionID = 0;
    if (this.algorithm.Rules.length > 0) {
      for (var i = this.algorithm.Rules.length - 1; i >= 0; i--) {
        let rule = this.algorithm.Rules[i];
        if (rule.ActionID == 3) {
          this.algorithm.Rules.splice(i, 1);
        }
        rule.ActionID = 0;
      }
    }
  }

  /**
   * check for default and number of users, alert user if unable to delete, otherwise open delete modal
   */
  async onDeleteClick() {
    if (!this.algorithm.isDefault) {
      this.numUsersOnAlgorithmMessage = "";
      // get number of users on algorithm
      let result = await this.presetService
        .getNumUsersOnAlgorithm(this.algorithmID)
        .toPromise();

      if (result && result.body) {
        if (result.body.Error) {
          this.snackBarService.openSnackbar(
            result.body.Error,
            SnackbarType.error
          );
        } else if (result.body.Value != null) {
          let numUsers = result.body.Value;
          if (numUsers == 1) {
            this.numUsersOnAlgorithmMessage =
              "There is 1 person using this algorithm," +
              " they will be moved to the default algorithm.";
          } else if (numUsers > 1) {
            this.numUsersOnAlgorithmMessage =
              "There are " +
              numUsers +
              " people using this algorithm, they will be moved to the default algorithm.";
          }
        } else {
          this.snackBarService.openSnackbar(
            "Failed to get Number of Users on Algorithm",
            SnackbarType.error
          );
        }
      }
      this.modalService.open(this.DELETE_MODAL_ID);
    } else {
      this.snackBarService.openSnackbar(
        "Unable to Delete Default Algorithm",
        SnackbarType.error
      );
    }
  }

  /**
   * update actionID to track changes
   */
  inputChanged() {
    if (this.algorithm.ActionID == ActionID.NOTHING) {
      this.algorithm.ActionID = ActionID.UPDATE;
    }
  }

  /**
   * update actionID if colour is different, update colour class variable
   */
  colourChanged() {
    if (
      this.selectedColour != null &&
      this.selectedColour != this.algorithm.AlgorithmColour
    ) {
      if (this.algorithm.ActionID == ActionID.NOTHING) {
        this.algorithm.ActionID = ActionID.UPDATE;
      }
    }
    this.selectedColour = this.algorithm.AlgorithmColour;
  }

  /**
   * sort list of rules bases on distance
   */
  orderRules() {
    if (this.algorithm.Rules.length > 0) {
      this.algorithm.Rules.sort((r1, r2) => {
        return r1.RuleDistance - r2.RuleDistance;
      });
    }
  }

  /**
   * add new rule object to list
   */
  addRule() {
    this.algorithm.Rules.push({
      RuleID: null,
      RuleType: this.ruleTypes[0],
      RuleValue: null,
      RuleValue2: null,
      RuleDistance: null,
      ActionID: ActionID.CREATE,
    });
  }
}
