import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  ColumnMode,
  DataTableBodyCellComponent,
  DatatableComponent,
  DataTableRowWrapperComponent,
  NgxDatatableModule,
  SelectionType,
} from '@swimlane/ngx-datatable';
import { TemplateColumn } from '../table.module';
import { TableService } from '../table.service';
import { Subject, Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { TableColumnFilterPipe, TableRowFilterPipe } from './table-filter.pipe';
import { CommonModule } from '@angular/common';
import { NgxDatatableDblClickDirective } from './row-double-click.directive';
import { takeUntil } from 'rxjs/operators';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-table-body',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgxDatatableModule,
    MatIconModule,
    TableColumnFilterPipe,
    TableRowFilterPipe,
    NgxDatatableDblClickDirective,
    ScrollingModule
  ],
})
export class TableComponent<T> implements OnInit, OnChanges, OnDestroy {
  // NXG_DATATABLE CONFIG
  ColumnMode = ColumnMode;
  @Input() selectionType: SelectionType;
  @Input() protected tableService: TableService<T>;
  @Input() rows: [];
  @Input() columns: TemplateColumn[];
  @Input() enableSelect = false;
  @Input() columnFilterEnabled = false;
  @Input() rowFilterEnabled = false;
  @Input() rowClickActionsEnabled = false;
  @Input() getCellClass: (cell: DataTableBodyCellComponent) => any;
  @Input() getRowClass: (row: DataTableRowWrapperComponent) => any;
  @Input() hasIcons = false;
  @Input() doubleClickCallBack: (rowData) => any = (e) => {
    console.log('double clicked');
  };
  @Input() resizeTrigger: Subject<void>;
  @Input() trackBy: string = '';

  @Input() selectedRows: Array<T> = [];
  @Output() selectedRowsChanged: EventEmitter<Array<T>> = new EventEmitter();
  @Output() changedTemplate: EventEmitter<TemplateColumn[]> = new EventEmitter<[]>();

  @ViewChild('table', { static: true }) table: DatatableComponent;

  displayedColumns: TemplateColumn[] = [];
  rowFilterString = '';
  private destroy$ = new Subject<void>();

  constructor() {}
  ngOnInit() {
    this.tableService.selectedRows$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.selectedRowsChanged.emit(res);
    });
    if (this.columnFilterEnabled) {
      this.tableService
        .getColumnUpdates()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.displayedColumns = res;
          this.changedTemplate.emit(this.columns);
        });
    }
    if (this.rowFilterEnabled) {
      this.tableService
        .getRowFilterString()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.rowFilterString = res;
          this.rows = [...this.rows];
        });
    }
    this.resizeTrigger?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.table.recalculate();
    });
  }

  ngOnChanges() {
    this.displayedColumns = this.columns;
    this.tableService.setColumns(this.columns);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.tableService.setColumns([]);
  }

  clearSelected() {
    this.tableService.selectedRows = [];
    this.selectedRowsChanged.emit([]);
  }

  selectionChanged(e) {
    this.tableService.selectedRows = e.selected;
  }

  onResize(e): void {
    const { column, newValue } = e;
    if (column && newValue) {
      const arr = this.columns;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].Title === e.column.name) {
          this.columns[i].Width = e.newValue;
          break;
        }
      }
      this.changedTemplate.emit(this.columns);
    }
  }

  onReorder(e): void {
    const { column, newValue, prevValue } = e;
    if (column && newValue) {
      const arr = this.columns.sort((a, b) => a.ColumnOrder - b.ColumnOrder);
      const movedItem = arr.splice(prevValue - 1, 1)[0];
      arr.splice(newValue, 0, movedItem);
      arr.map((col, index) => {
        col.ColumnOrder = index + 1;
      });
      this.changedTemplate.emit(this.columns);
    }
  }
}
