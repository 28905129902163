export class Rule {
  RuleID: number;
  RuleName: string;
  Description: string;
  RuleTypeID: number; 
  isActive: number;
  TriggerID: number;
  Criteria: Criteria[];
  Actions: Action[];

  delete? : boolean; //flag for deletion
}

export class Criteria {
  Rule2CriteriaID: number;
  RuleID: number;
  AdminCriteriaID: number;
  OperatorID: number;
  Values: string | string[];

  delete? : boolean; //flag for deletion
}

export class Action {
  Rule2ActionsID: number;
  RuleID: number;
  ActionID: number;
  OrderNumber: number;
  ActionValue: string | string[];
  ActionValueID: number;
  ActionValues?: ActionValue[];

  delete? : boolean; //flag for deletion
}

export class ActionValue {
  Rule2ActionsID: number;
  ActionValueID: number;
  Value: string | string[];
}

export class AdminCriteria {
  CriteriaID: number;
  CriteriaName: string;
  Description: string;
  CriteriaDataTypeID: number;
  CriteriaValueQuery: string;
  IDColumn: string;
  NameColumn: string;
}

export class AdminActions {
  ActionID: number;
  ActionName: string;
  Description: string;
  ActionValueQuery: string;
  ActionValueIDColumn: string;
  ActionValueNameColumn: string;
}

export class AdminOperators {
  OperatorID: number;
  OperatorName: string;
  Description: string;
}

export class AdminCriteria2Operators {
  Criteria2OperatorID: number;
  CriteriaID: number;
  OperatorID: number;
}

export class AdminRuleTypes {
  AdminRuleTypeID: number;
  RuleTypeName: string;
  Description: string;
}

export class AdminTriggers {
  TriggerID: number;
  TriggerName: string;
  Description: string;
}

export class AdminCriteriaValues {
  CriteriaID: number;
  Value: [];
}

export class AdminActionValues {
  ActionID: number;
  Value: [];
  ActionValueID: number;
}