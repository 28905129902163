import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-create-ticket-schedule-view",
  templateUrl: "./create-ticket-schedule-view.component.html",
  styleUrls: ["./create-ticket-schedule-view.component.css"],
})
export class CreateTicketScheduleViewComponent
  extends BaseViewComponent
  implements OnInit, OnDestroy
{
  className: string = "CreateTicketScheduleViewComponent";
  myViewName = "schedule";
  parentMsgService: any;
  view = {};
  formProps = {};

  constructor(logger$: LoggerService) {
    super(logger$);
    this.formProps = {
      // messageService: compMsg$,
      categoryLinks: [],
      view: this.view,
    };
  }

  ngOnInit(): void {
    this.logger$.log("CreateTicketScheduleViewComponent: init");
    // this.refresh();
    this.parentMsgService = this.props["messageService"]
      .getMessageStream()
      .subscribe((nextMsg: ComponentMessage) => {
        this.refresh();
      });
  }

  refresh() {
    if (this.props["views"] && this.props["views"][this.myViewName]) {
      this.formProps["view"] = this.props["views"][this.myViewName];
      this.formProps = { ...this.formProps };
    }
  }

  ngOnDestroy() {
    this.parentMsgService.unsubscribe();
    this.logger$.log("CreateTicketScheduleViewComponent: Destroyed");
  }
}
