<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="100" class="container">
    <h5 class="mat-headline-2">{{ locale.header }}</h5>
    <p class="mat-body" [innerHTML]="locale.disclaimer">

    </p>
    <!-- <p class="mat-body">
      {{ locale.body1a }} <b>(a)</b> {{ locale.body1b }} <b>(b)</b>
      {{ locale.body1c }}
    </p>
    <p class="mat-body">
      {{ locale.body2 }}
    </p> -->

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <!-- <button mat-button (click)="onClickDecline()">
        DECLINE
      </button> -->
      <button mat-raised-button color="primary" (click)="onClickAccept()">
        {{ locale.ackButton }}
      </button>
    </div>
  </div>
</div>
