import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChildren,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { EsriMapModule } from "../../esri-map/esri-map-set/esri-map/esri-map.component";
import { MaterialModule } from "../../material.module";

export interface FAMItem {
  label: string;
  icon: string;
  action: () => void;
  iconType?: "svg" | "mat";
}

export enum FAMType {
  Fab = "fab",
  MiniFab = "mini-fab",
  ExtendedFab = "extended-fab",
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "floating-action-menu",
  templateUrl: "floating-action-menu.component.html",
  styleUrls: ["floating-action-menu.component.scss"],
  standalone: true,
  imports: [MaterialModule, FlexLayoutModule, EsriMapModule, CommonModule],
})
export class FloatingActionMenuComponent implements OnInit {
  @Input() menuItems: FAMItem[];
  @Input() type: FAMType;
  @Input() extendedFabLabel: string;
  @Output() fabOnClick = new EventEmitter<MouseEvent>();
  @ViewChildren("fabItem") items: any;

  @Input() disabled = false;
  isTouchScreen = true;

  ngOnInit() {
    this.isTouchScreendevice();
  }

  isTouchScreendevice() {
    this.isTouchScreen =
      "ontouchstart" in window || navigator.maxTouchPoints ? true : false;
  }

  menuFabClicked(event: MouseEvent) {
    this.fabOnClick.emit(event);
  }

  menuItemClicked(item) {
    if (!this.disabled) {
      item.action();
    }
  }
}
