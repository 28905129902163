import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TicketListTagsComponent } from "./ticket-list-tags/ticket-list-tags.component";
import { MaterialModule } from "../material.module";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [TicketListTagsComponent],
  exports: [TicketListTagsComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule],
})
export class TicketTagsModule {}
