import { Injectable, OnDestroy } from '@angular/core';
import { LayerManagerService, LayerType } from '../../services/layer-manager.service';
import { BehaviorSubject, Subject, Observable, from } from 'rxjs';
import { finalize, switchMap, tap, map, filter, concatMap, reduce, takeUntil } from 'rxjs/operators';
import axios from 'axios';
@Injectable({
  providedIn: 'root',
})
export class MapLegendService implements OnDestroy {
  featureJsonStore = [];
  private featureJson$ = new BehaviorSubject<(LegendSymbol | LegendSymbol[])[]>([]);
  destroy$ = new Subject<void>();

  constructor(private layerService: LayerManagerService) {
    this.layerService.databaseLayerStream
      .pipe(
        tap(() => {
          this.featureJsonStore = [];
        }),
        switchMap((layers) =>
          from(layers).pipe(
            map((layer) => {
              switch (layer.LayerType) {
                case LayerType.EsriMapServer:
                  return layer.LayerURL + '/legend?f=json' + `${layer.Token ? '&TOKEN=' + layer.Token : ''}`;
                case LayerType.EsriFeatureServer:
                  return `${layer.LayerURL}?f=json${layer.Token ? '&TOKEN=' + layer.Token : ''}`;
                default:
                  return '';
              }
            }),
            filter((x) => x !== ''),
            concatMap((x) => from(axios.get(x))),
            map(({ data }) => data),
            filter((x) => !x.hasOwnProperty('error')),
            map((data) => {
              let objects: (LegendSymbol | LegendSymbol[])[] = [];
              let obj = {
                name: data['name'],
              } as LegendSymbol;
              if (data['drawingInfo']) {
                const { symbol, renderer } = data['drawingInfo'];
                if (renderer) {
                  const { type, symbol: rendererSymbol, uniqueValueInfos } = renderer;
                  switch (type) {
                    case 'simple':
                      obj = rendererSymbol['imageData']
                        ? ({
                            ...obj,
                            imageData: rendererSymbol['imageData'],
                            width: rendererSymbol['width'],
                            height: rendererSymbol['height'],
                            color: '',
                            type: 'icon',
                          } as LegendSymbol)
                        : ({
                            ...obj,
                            imageData: '',
                            width: rendererSymbol['width'],
                            height: rendererSymbol['height'],
                            color: rendererSymbol['color'].join(', '),
                            type: 'line',
                          } as LegendSymbol);
                      objects.push(obj);
                      break;
                    case 'uniqueValue':
                      objects.push(
                        uniqueValueInfos.map((val) => {
                          const { label, symbol: uniqeSymbol, value } = val;
                          if (uniqeSymbol.hasOwnProperty('type') && uniqeSymbol['type'] === 'esriPMS') {
                            return {
                              name: label,
                              imageData: uniqeSymbol['imageData'],
                              width: uniqeSymbol['width'],
                              height: uniqeSymbol['width'],
                              color: '',
                              type: 'icon',
                              groupName: data['name'],
                            } as LegendSymbol;
                          } else {
                            return {
                              name: label,
                              imageData: '',
                              width: uniqeSymbol['width'],
                              height: uniqeSymbol['width'],
                              color: uniqeSymbol['color'].join(', '),
                              type: 'line',
                              groupName: data['name'],
                            } as LegendSymbol;
                          }
                        })
                      );
                      break;
                  }
                } else if (symbol['type'] === 'esriPMS') {
                  obj = {
                    ...obj,
                    imageData: symbol['imageData'],
                    width: symbol['width'],
                    height: symbol['height'],
                    color: '',
                    type: 'icon',
                  } as LegendSymbol;
                }
              } else if (data['layers']) {
                data['layers'].forEach((layer) => {
                  const { layerName, legend } = layer;
                  objects.push(
                    legend.map((item) => {
                      return {
                        name: item.label,
                        color: '',
                        height: item.height,
                        width: item.width,
                        imageData: item.imageData,
                        type: item.imageData ? 'icon' : 'line',
                        groupName: layerName,
                      } as LegendSymbol;
                    })
                  );
                });
              }
              return objects;
            }),
            map((arr) => (this.featureJsonStore = [...this.featureJsonStore, ...arr])),
            finalize(() => {
              this.featureJson$.next(this.featureJsonStore);
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get featureJson(): Observable<(LegendSymbol | LegendSymbol[])[]> {
    return this.featureJson$.pipe();
  }
}
export type LegendSymbol = {
  name: string;
  imageData: string;
  width: number;
  height: number;
  color: string;
  type: string;
  groupName?: string;
};
