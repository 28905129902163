import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManagedLayer } from '../../classes/managedLayer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LayerSlotComponent } from '../layer-slot/layer-slot.component';
import { AsyncPipe, NgForOf } from '@angular/common';

@Component({
  selector: "app-layer-list",
  standalone: true,
  imports: [CdkAccordionModule, LayerSlotComponent, AsyncPipe, NgForOf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template:`
    <div>
      <cdk-accordion class="example-accordion">
        <ng-container
          *ngFor="let layer of layersToDisplay | async; let index = index"
        >
          <app-layer-slot [layer]="layer" [index]="index"
                          [selectedLayer]="selectedLayer"
                          (layerSelected)="layerSelectionChanged.emit($event)"></app-layer-slot>
        </ng-container>
      </cdk-accordion>
    </div>
  `
})
export class LayerListComponent implements OnInit {
  @Input() layers: BehaviorSubject<ManagedLayer[]>;
  @Input() selectedLayer: ManagedLayer;
  @Output() layerSelectionChanged = new EventEmitter<ManagedLayer>();
  layersToDisplay: Observable<ManagedLayer[]>;
  constructor() {}

  ngOnInit() {
    this.layersToDisplay = this.layers.pipe(
      map((layers: ManagedLayer[]) =>
        layers.filter(
          (layer: ManagedLayer) => layer.getProperties().bIsShownInApp !== -1,
        ),
      ),
    );
  }
}
