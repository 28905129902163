import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BaseTemplateFormComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { ProgressBarService } from "src/app/modules/shared/progress-bar/progress-bar.service";
import { SnackbarService } from "src/app/modules/shared/snackbar/snackbar.service";
import { CreateTicketComponentService } from "../../../create-ticket-component.service";
import { CreateTicketMessageActions } from "../../../CreateTicketInterface";
import {
  ExcavatorContactsCardComponent,
  ExcavatorContactsCardProps,
} from "../../../excavator-contacts-card/excavator-contacts-card.component";
import {
  AngularButtonTypes,
  SidePanelButtonAction,
  SidePanelProps,
} from "../../../slideover-side-panel/slideover-side-panel.component";
import { startWith } from "rxjs/operators";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { SnackbarType } from "src/app/modules/shared/snackbar/snackbar/snackbar";
import { CreateTicketFormTypes } from "../../../CreateTicketModel";
@Component({
  selector: "app-create-ticket-company-view",
  templateUrl: "./create-ticket-company-view.component.html",
  styleUrls: ["./create-ticket-company-view.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketCompanyViewComponent
  extends BaseTemplateFormComponent
  implements OnInit, OnChanges
{
  myControl = new FormControl();
  @ViewChild("excavatorContacts") contactList: ExcavatorContactsCardComponent;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  options: any = [];
  filteredOptions: Observable<any[]>;
  excavatorContactsProp: ExcavatorContactsCardProps = {
    AssignmentID: -1,
    ExcavatorID: -1,
  };
  dataReady = false;
  slideoverProps: SidePanelProps = {
    title: "Create",
    isFullscreen: true,
    width: "528px",
    isForm: true,
    // htmlContent?:string
    view: this.myView,
    actions: [],
  };
  multipleExcavatorContacts = false;
  traditionalCustomer = false;

  prefillChange: boolean = false;
  prefillFields: any;
  constructor(
    logger$: LoggerService,
    compMsg$: ComponentMessagingService,
    private ticket$: CreateTicketComponentService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    super(logger$, compMsg$);
  }

  init() {
    this.className = "CreateTicketCompanyViewComponent";
    this.categoryLinks = [];
    this.myViewName = "companyInfo";
    super.init();
  }

  ngOnInit() {
    this.setupExcavatorSearch();
    this.slideoverProps.actions = [];
    let cancelButton: SidePanelButtonAction = {
      text: "Cancel",
      buttonType: AngularButtonTypes.defaultButton,
      color: "",
      action: this.onCancel,
      width: 50,
      order: 1,
    };
    let saveCompanyButton: SidePanelButtonAction = {
      text: "Create",
      buttonType: AngularButtonTypes.raisedButton,
      color: "primary",
      action: this.onSave,
      width: 50,
      order: 2,
    };
    this.slideoverProps.actions.push(cancelButton);
    this.slideoverProps.actions.push(saveCompanyButton);

    if (this.props && this.props["messageService"]) {
      this.parentMsgSubscriber = this.props["messageService"]
        .getMessageStream()
        .subscribe((nextMsg: ComponentMessage) => {
          //event, field () event is option
          if (
            nextMsg.action == CreateTicketMessageActions.EXCAVATOR_NAME_UPDATED
          ) {
            this.myControl.setValue(nextMsg.message.event);
            this.onAutoCompanyClick(nextMsg.message.event);
          }
          if (
            nextMsg.action ==
            CreateTicketMessageActions.QUICK_EXCAVATOR_CONTACTS_UPDATE
          ) {
            this.contactList.updateSelectedContacts(nextMsg.message);
          }
          if (nextMsg.action == CreateTicketMessageActions.DATA_RESET) {
            this.resetCompanyData();
          }
        });
    }
  }

  resetCompanyData() {
    try {
      this.myControl.reset();
      this.contactList.reset();
      let excavatorContactsField =
        this.views["ticketSummary"]["groups"]["companyInfo"]["fields"][
          "ExcavatorContacts"
        ];
      if (excavatorContactsField) {
        excavatorContactsField["selectOptions"] = [];
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      super.ngOnChanges(changes);
      let currentValue = changes["props"]["currentValue"];
      if (currentValue["prefillFields"] && !this.prefillChange) {
        this.prefillFields = currentValue["prefillFields"];
        if (
          this.prefillFields &&
          this.prefillFields["AssignmentID"] &&
          this.prefillFields["ExcavatorID"]
        ) {
          this.prefillChange = true;
          this.excavatorContactsProp = {
            AssignmentID: -1,
            ExcavatorID: this.prefillFields["ExcavatorID"],
            prefillAssignmentID: this.prefillFields["AssignmentID"],
          };
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  refresh() {
    try {
      if (this.ticket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR]) {
        if (this.ticket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR] == true) {
          this.multipleExcavatorContacts = true;
          this.traditionalCustomer = false;
        } //no else needed
      } else if (
        this.ticket$.settings[SettingID.MULTI_CONTACT_EXCAVATOR] == false
      ) {
        this.multipleExcavatorContacts = false;
        this.traditionalCustomer = true;
      }

      this.formProps["views"] = this.views;
      this.formProps["view"] = this.myView;
      this.formProps = { ...this.formProps };

      this.slideoverProps["views"] = this.views;
      this.slideoverProps["view"] = this.myView;
      this.slideoverProps = { ...this.slideoverProps };

      this.dataReady = true;
    } catch (error) {
      this.logger$.error(this.className + " : refresh Error" + error.message);
    }
  }

  async setupExcavatorSearch() {
    try {
      let companies = await this.ticket$.getAllCompanyInfo();
      if (companies && companies.length > 0) {
        this.options = companies;
      }
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((value) =>
          typeof value === "string" ? value : value ? value.text : value
        ),
        map((text) => this._filter(text))
      );
    } catch (error) {
      console.error(error.message);
    }
  }

  handleExcavatorSaved(excavator) {
    try {
      let updateOption = {
        text: excavator["ExcavatorName"],
        value: excavator["ExcavatorID"],
      };
      this.updateExcavatorList();
      this.patchView("ticketSummary", { ExcavatorName: updateOption });
      this.myControl.setValue(updateOption);
      this.saveExcavatorContactsLocal(this.contactList.getExcavatorObject());
    } catch (error) {
      console.error(error.message);
    }
  }

  handleExcavatorDetailsChanged(excavatorObj) {
    try {
      this.saveExcavatorContactsLocal(excavatorObj);
    } catch (error) {
      console.error(error.message);
    }
  }

  handleExcavatorContactsChanged(excavatorObj) {
    try {
      let resetData = false;
      let action = Actions.UPDATE_EXCAVATOR_CONTACTS;
      this.saveExcavatorContactsLocal(excavatorObj, resetData, action);
    } catch (error) {
      console.error(error.message);
    }
  }

  async updateExcavatorList() {
    let isUpdated = false;
    try {
      if (this.views) {
        await this.setupExcavatorSearch();
        if (this.views["ticketSummary"]) {
          this.views["ticketSummary"] =
            await this.ticket$.refreshFilteredOptions(
              this.views["ticketSummary"],
              "companyInfo",
              "ExcavatorName"
            );
        }
      }
      isUpdated = true;
    } catch (error) {
      this.logger$.error(error.message);
    }
    return isUpdated;
  }

  //filter when type in autocomplete field
  private _filter(text: string): any {
    if (text) {
      const filterValue = text.toLowerCase();
      return this.options.filter(
        (option) => option.text.toLowerCase().indexOf(filterValue) > -1
      );
    } else {
      return this.options;
    }
  }

  onClearCompany() {
    try {
      if (this.myView && this.myView["formGroup"]) {
        let myFormGroup = this.myView["formGroup"];
        myFormGroup.reset();
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  onSaveCompany() {
    try {
      //get form info. update idb, update database.
      if (this.myView && this.myView["formGroup"]) {
        let myFormGroup = this.myView["formGroup"];
        if (myFormGroup.valid && myFormGroup.value) {
          let promiseArr = [];
          this.progressBarService.start();
          promiseArr.push(this.saveCompanyLocal(myFormGroup.value));
          promiseArr.push(this.saveCompanyServer(myFormGroup.value));
          Promise.all(promiseArr)
            .then((val) => {
              if (!val[0]) {
                this.snackBarService.openSnackbar(
                  "Saving Local Failed",
                  SnackbarType.error
                );
              }
              if (!val[1]) {
                this.snackBarService.openSnackbar(
                  "Saving Remote Failed",
                  SnackbarType.error
                );
              }
              if (val[0] && val[1]) {
                this.snackBarService.openSnackbar(
                  "Saved Company",
                  SnackbarType.success
                );
              }
              this.progressBarService.stop();
            })
            .catch((error) => {
              throw new Error(error.message);
            });
        } else {
          myFormGroup.markAllAsTouched();
          this.snackBarService.openSnackbar(
            "Missing required fields",
            SnackbarType.error
          );
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
      this.progressBarService.stop();
    }
  }

  private async saveExcavatorContactsLocal(
    excavatorObj,
    reset = false,
    action: Actions = Actions.UPDATE_EXCAVATOR_DATA
  ) {
    let result = false;
    try {
      let excavatorDetails = {};
      if (!reset) {
        //reset data
        excavatorDetails = excavatorObj["excavator"][0];
        // if (excavatorObj["selectedContacts"] && excavatorObj["selectedContacts"])
        excavatorDetails["contacts"] = excavatorObj["selectedContacts"];
      }
      result = await this.ticket$
        .updateTicketData(
          new ActionMessage(action, {
            formType: CreateTicketFormTypes.excavator,
            value: excavatorDetails,
          })
        )
        .toPromise();
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  private async saveCompanyLocal(formValues, reset = false) {
    let result = false;
    try {
      let excavatorDetails = {
        ExcavatorName: "",
        ExcavatorAddress: "",
        ExcavatorCity: "",
        ExcavatorPostalCode: "",
        Province: "ON",
        PhoneNumber: "",
        Email: "",
        ContactName: "",
        Code: 0,
      };
      if (!reset) {
        for (let key in formValues) {
          if (
            key.indexOf("ExcavatorName") == 0 &&
            formValues[key] &&
            typeof formValues[key] === "object" &&
            formValues[key]["value"]
          ) {
            excavatorDetails["ExcavatorName"] = formValues[key]["text"];
            excavatorDetails["ExcavatorID"] = formValues[key]["value"];
          } else if (
            typeof formValues[key] === "object" &&
            formValues[key] &&
            formValues[key]["value"]
          ) {
            excavatorDetails[key] = formValues[key]["value"];
          } else {
            excavatorDetails[key] = formValues[key];
          }
        }
      }
      result = await this.ticket$
        .updateTicketData(
          new ActionMessage(Actions.UPDATE_EXCAVATOR_DATA, {
            formType: CreateTicketFormTypes.excavator,
            value: excavatorDetails,
          })
        )
        .toPromise();
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  private async saveCompanyServer(formValues) {
    let result = false;
    try {
      let excavatorDetails = {
        ExcavatorName: "",
        ExcavatorAddress: "",
        ExcavatorCity: "",
        ExcavatorPostalCode: "",
        Province: "ON",
        PhoneNumber: "",
        Email: "",
        ContactName: "",
        Code: 0,
      };
      for (let key in formValues) {
        if (
          key.indexOf("ExcavatorName") == 0 &&
          typeof formValues[key] === "object"
        ) {
          excavatorDetails["ExcavatorName"] = formValues[key]["text"];
          excavatorDetails["ExcavatorID"] = formValues[key]["value"];
        } else if (
          typeof formValues[key] === "object" &&
          formValues[key]["value"]
        ) {
          excavatorDetails[key] = formValues[key]["value"];
        } else {
          excavatorDetails[key] = formValues[key];
        }
      }

      result = await this.ticket$.saveCompany(excavatorDetails);
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  onAutoCompanyClick(optionValue) {
    console.log(optionValue);
    try {
      this.excavatorContactsProp = {
        AssignmentID: -1,
        ExcavatorID: optionValue.value,
      };
      this.patchView("ticketSummary", {
        ExcavatorName: optionValue,
        SearchExcavatorName: optionValue,
      });
      this.excavatorContactsProp = { ...this.excavatorContactsProp };
    } catch (error) {}
  }

  private patchView(view, value) {
    try {
      if (this.views && this.views[view] && this.views[view]["formGroup"]) {
        this.views[view]["formGroup"].patchValue(value);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  //13
  displayFn(option): string {
    if (typeof option === "object") {
      return option && option.text ? option.text : "";
    } else {
      return option;
    }
  }

  onCancel = (panel) => {
    try {
      panel.close();
    } catch (error) {
      console.error(error.message);
    }
  };

  onSave = (panel) => {
    try {
      if (this.myView["formGroup"] && this.myView["formGroup"].valid) {
        panel.toggleLoading();
        panel.disableCurrentFormGroup();

        this.onSaveCompanyServer(this.myView["formGroup"].value).then(
          (saveResult) => {
            if (saveResult) {
              panel.toggleLoading();
              panel.close();
              this.snackBarService.openSnackbar(
                "Created",
                SnackbarType.success
              );
              this.excavatorContactsProp = {
                ExcavatorID: saveResult["value"],
                AssignmentID: -1,
              };
            } else {
              panel.toggleLoading();
              this.snackBarService.openSnackbar(
                "Failed to create company",
                SnackbarType.error
              );
              this.myView["formGroup"].markAllAsTouched();
              panel.enableCurrentFormGroup();
            }
          }
        );
      } else {
        this.myView["formGroup"].markAllAsTouched();
        this.snackBarService.openSnackbar(
          "Please fill in all the required fields",
          SnackbarType.error
        );
      }
    } catch (error) {
      console.error(error.message);
      panel.toggleLoading();
      panel.enableCurrentFormGroup();
    }
  };

  private async onSaveCompanyServer(formValues) {
    let result: any = false;
    try {
      let excavatorDetails = {
        ExcavatorName: "",
        ExcavatorAddress: "",
        ExcavatorCity: "",
        ExcavatorPostalCode: "",
        Province: "ON",
        PhoneNumber: "",
        Email: "",
        ContactName: "",
        Code: 0,
        FaxNumber: "",
        isManuallyEntered: 1,
      };
      for (let key in formValues) {
        //null is object? so check falsly first
        if (formValues[key]) {
          if (
            key.indexOf("ExcavatorName") == 0 &&
            typeof formValues[key] === "object"
          ) {
            excavatorDetails["ExcavatorName"] = formValues[key]["text"];
            excavatorDetails["ExcavatorID"] = formValues[key]["value"];
          } else if (
            typeof formValues[key] === "object" &&
            formValues[key]["value"]
          ) {
            excavatorDetails[key] = formValues[key]["value"];
          } else {
            excavatorDetails[key] = formValues[key];
          }
        }
      }
      result = await this.ticket$.saveCompany(excavatorDetails);
      // result = {"value":9476};
    } catch (error) {
      this.logger$.error(error);
    }
    return result;
  }

  createCompanyClick(event, panel) {
    try {
      panel.clearForm();
      panel.open();
    } catch (error) {
      console.error(error.message);
    }
  }

  getExcavatorContacts() {
    try {
      return this.contactList.getExcavatorObject();
    } catch (error) {
      console.error(error.message);
    }
  }
}
