import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs";

import { NavBarService } from "../../services/router/nav-bar.service";
import { TicketSyncService } from "../../services/ticket-sync/ticket-sync.service";
import { NavBarConfig } from "src/app/routes/fieldside/components/fieldside-navigation-bar/navigation-bar.component";

@Component({
  selector: "app-storage-modal",
  templateUrl: "./storage-modal.component.html",
  styleUrls: ["./storage-modal.component.scss"],
})
export class StorageModalComponent implements OnInit {
  onSyncClick: Subject<any>;

  constructor(
    public dialogRef: MatDialogRef<StorageModalComponent>,
    private navBarChanges: NavBarService,
    private ticketSync: TicketSyncService
  ) {}

  ngOnInit() {
    this.navBarChanges.getNavBarChanges().subscribe((nextVal: NavBarConfig) => {
      this.onSyncClick = nextVal.onSyncClick;
    });
  }

  onConfirm(event) {
    this.ticketSync.startSync(true, true).subscribe({
      complete: () => {
        this.ticketSync.closeModal();
      },
    });
    this.dialogRef.close({ event: "close", result: true });
  }
}
