import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { NgModule } from "@angular/core";
import { Component } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BaseComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { MaterialModule } from "../../../material.module";
import { RecursiveFormDialogComponent } from "../recursive-form-dialog/recursive-form-dialog.component";
import { RecursiveFormGroupTemplateComponent } from "../recursive-form-group-template/recursive-form-group-template.component";
import { RecursiveFormInputTemplateComponent } from "../recursive-form-input-template/recursive-form-input-template.component";

@Component({
  selector: "app-recursive-form-template",
  templateUrl: "./recursive-form-template.component.html",
  styleUrls: ["../recursive-form-template.scss"],
})
export class RecursiveFormTemplateComponent extends BaseComponent {
  @Input() readOnly: boolean = true;
  @Output() isHideShowToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCategories: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCategory: EventEmitter<any> = new EventEmitter<any>();

  isLoaded: boolean = false;
  groups: any;

  constructor(logger$: LoggerService) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["props"] && changes["props"]["currentValue"]) {
      // if(changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
      //   this.TICKET_PROTECTION_MODE = changes.props.currentValue.TICKET_PROTECTION_MODE;
      // }

      if (
        changes["props"]["currentValue"]["view"] &&
        changes["props"]["currentValue"]["view"]["groups"]
      ) {
        this.groups = changes["props"]["currentValue"]["view"]["groups"];

        this.isLoaded = true;
      }
    }
  }
}

@NgModule({
    declarations: [
        RecursiveFormTemplateComponent,
        RecursiveFormGroupTemplateComponent,
        RecursiveFormInputTemplateComponent,
        RecursiveFormDialogComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        BrowserModule,
        MaterialModule,
        FlexLayoutModule,
        ScrollingModule,
    ],
    exports: [RecursiveFormTemplateComponent]
})
export class RecursiveFormTemplateModule {}
