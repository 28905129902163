import { inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, EMPTY, filter, firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { apiKeys } from 'src/app/ENDPOINTS';
import { ApiService, UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { groupBy } from 'lodash-es';
import { catchError, tap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../modules/shared/snackbar/snackbar/snackbar';
import { RoutingPresetService } from '../../../modules/routing-preset/routing-preset.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { ProgressBarService } from '../../../modules/shared/progress-bar/progress-bar.service';
import { UserService } from '../../../modules/core/services/user/user.service';
import { SettingID } from '../../../modules/core/services/user/setting';

@Injectable({
  providedIn: 'root',
})
export class DispatchAreaService {
  //services
  private apiService: ApiService = inject(ApiService);
  private settingsService = inject(UserService);
  private snackBarService = inject(SnackbarService);
  private progressBarService = inject(ProgressBarService);
  protected routingPresetService = inject(RoutingPresetService);

  // members
  protected _userCanModifySetting$$: WritableSignal<[isActive, settingValue]> = signal([false, '0']);
  protected _clientRoutingSetting$$: WritableSignal<isActive> = signal(false);
  private _dispatchAreas$$ = signal<DispatchAreaMap>({});
  private _dispatchAreas$ = toObservable(this._dispatchAreas$$);
  private _dispatchLayers$: BehaviorSubject<DispatchLayers> = new BehaviorSubject([]);
  private _managerAreas$$ = signal<Array<ManagerArea>>([]);
  private _managerAreas$ = toObservable(this._managerAreas$$);
  private _routingAlgorithms$: BehaviorSubject<Array<RoutingAlgorithm>> = new BehaviorSubject([]);
  private _routingAlgorithms$$ = signal<Array<RoutingAlgorithm>>([]);

  constructor() {
    this._userCanModifySetting$$.set([
      this.settingsService.isSettingActive(SettingID.MODIFY_DISPATCH_AREAS),
      this.settingsService.getSettingValue(SettingID.MODIFY_DISPATCH_AREAS),
    ]);
    const routing = this.settingsService.isSettingActive(SettingID.DISPATCH_AREA_ROUTING_MANAGEMENT);
    this._clientRoutingSetting$$.set(routing);
    this.progressBarService.start();
    firstValueFrom(this.doFetch()).then(() => {
      firstValueFrom(this.routingPresetService.getAlgorithms()).then(({ ok, status, body }) => {
        if (ok && status === 200) {
          const { Error, Value } = body;

          if (Error) {
            this.snackBarService.openSnackbar(Error, SnackbarType.error, status);
          } else {
            const { Algorithms } = Value as { Algorithms: Array<RoutingAlgorithm> };
            this._routingAlgorithms$.next(Algorithms);
            this._routingAlgorithms$$.set(Algorithms);
          }
        } else {
          this.snackBarService.openSnackbar('Failed to load routing presets', SnackbarType.error, status);
        }
        this.progressBarService.stop();
      });
    });
  }

  private doFetch(): Observable<unknown> {
    // guard statements
    if (!this._userCanModifySetting$$()[0]) {
      return of({});
    }
    let withRouting = 1;
    if (!this._clientRoutingSetting$$()) {
      withRouting = 0;
    }
    // continue if guard statements pass
    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.dispatchAreaController,
      API_TYPE: 'PUT',
      API_BODY: {
        query: {
          area: {
            withCords: 1,
            withRouting: withRouting,
            whereClause: '1=1',
          },
          layer: {
            all: true,
          },
          managerAreas: {
            all: true,
          },
        },
      },
    };
    return from(this.apiService.invokeUtilocateApi(utilocateApiRequest)).pipe(
      tap((x) => {
        if (!x.ok) {
          if (x.status >= 500) {
            this.snackBarService.openSnackbar(
              'Failed to load dispatch areas: Server Error',
              SnackbarType.error,
              x.status
            );
          } else if (x.status >= 400) {
            this.snackBarService.openSnackbar(
              'Failed to load dispatch areas: Bad Request',
              SnackbarType.error,
              x.status
            );
          }
        }
      }),
      filter((x) => x.ok),
      map(({ body }) => body),
      catchError(() => of({ areas: null, layers: null, managerAreas: null })),
      tap(({ areas, layers, managerAreas }) => {
        try {
          if (areas) {
            const grouped = groupBy(areas, 'AreaID');
            this._dispatchAreas$$.set(grouped);
          } else {
            this._dispatchAreas$$.set({});
          }
          if (layers) {
            this._dispatchLayers$.next(layers);
          } else {
            this._dispatchLayers$.next([]);
          }
          if (managerAreas) {
            this._managerAreas$$.set(managerAreas);
          } else {
            this._managerAreas$$.set([]);
          }
        } catch (e) {
          console.error(e);
          this.snackBarService.openSnackbar('Failed to load dispatch areas', SnackbarType.error);
        }
      })
    );
  }

  deleteArea(areaID: number): Observable<unknown> {
    // guard statements
    if (!this._userCanModifySetting$$()[0] && this._userCanModifySetting$$()[1] !== '1') {
      return of({});
    }
    // continue if guard statements pass
    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.dispatchAreaController,
      API_TYPE: 'PUT',
      API_BODY: {
        mutation: {
          delete: {
            area: {
              curAreaID: areaID,
            },
          },
        },
      },
    };
    return from(this.apiService.invokeUtilocateApi(utilocateApiRequest)).pipe(
      tap((x) => {
        if (!x.ok) {
          if (x.status >= 500) {
            this.snackBarService.openSnackbar('Failed to load delete area: Server Error', SnackbarType.error, x.status);
          } else if (x.status >= 400) {
            this.snackBarService.openSnackbar('Failed to load delete area: Bad Request', SnackbarType.error, x.status);
          }
        }
      }),
      filter((x) => x.ok),
      catchError(() => {
        this.snackBarService.openSnackbar('Failed to delete area: Unknown', SnackbarType.error);
        return EMPTY;
      }),
      tap(() => {
        this.snackBarService.openSnackbar('Area Deleted', SnackbarType.success);
      }),
      switchMap(() => this.doFetch())
    );
  }

  mutateArea(
    name: string,
    user: string,
    managerArea: number,
    routing: string,
    layerID: number,
    coords: Array<string>,
    areaID: number = undefined,
    email: string = '',
    tagID: number = 0
  ): Observable<unknown> {
    // guard statements
    if (!this._userCanModifySetting$$()[0] && this._userCanModifySetting$$()[1] !== '1') {
      return of({});
    }
    // continue if guard statements pass
    const mutation: Record<string, any> = {};
    const val = {
      curAreaName: name,
      curAreaUserID: user,
      curManagerAreaID: managerArea,
      curEmail: email,
      curLayerID: layerID,
      curTagID: tagID,
      curAreaAlgoID: routing,
      PolygonCoords: coords,
    };

    if (areaID) {
      mutation.update = {
        area: {
          curAreaID: areaID,
          ...val,
        },
      };
    } else {
      mutation.create = {
        area: val,
      };
    }
    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.dispatchAreaController,
      API_TYPE: 'PUT',
      API_BODY: {
        mutation,
      },
    };
    return from(this.apiService.invokeUtilocateApi(utilocateApiRequest)).pipe(
      tap((x) => {
        if (!x.ok) {
          if (x.status >= 500) {
            this.snackBarService.openSnackbar(
              'Failed to load dispatch areas: Server Error',
              SnackbarType.error,
              x.status
            );
          } else if (x.status >= 400) {
            this.snackBarService.openSnackbar(
              'Failed to load dispatch areas: Bad Request',
              SnackbarType.error,
              x.status
            );
          }
        }
      }),
      filter((x) => x.ok),
      catchError(() => {
        this.snackBarService.openSnackbar('Failed to update area: Unknown', SnackbarType.error);
        return EMPTY;
      }),
      tap(() => {
        this.snackBarService.openSnackbar('Area Updated', SnackbarType.success);
      }),
      switchMap(() => this.doFetch())
    );
  }

  getAreaByID(areaID: number): DispatchArea {
    return this.dispatchAreas[areaID];
  }

  getManagerAreaByID(managerAreaID: number): ManagerArea {
    return this._managerAreas$$().find((x) => x.ManagerAreaID === managerAreaID);
  }

  getRoutingByID(routingID: number): RoutingAlgorithm {
    const emptyAlgo = {
      AlgorithmID: 0,
      AlgorithmName: '',
      AlgorithmDesc: '',
      isDefault: 0,
      AlgorithmColour: '#f8f8f8',
    };

    const algo: RoutingAlgorithm = this._routingAlgorithms$$().find((x) => x.AlgorithmID === routingID);

    return algo ? algo : emptyAlgo;
  }

  // getters and setters
  get dispatchAreas(): DispatchAreaMap {
    return this._dispatchAreas$$();
  }

  get dispatchAreas$(): Observable<DispatchAreaMap> {
    return this._dispatchAreas$.pipe();
  }

  get dispatchAreas$$(): Signal<DispatchAreaMap> {
    return this._dispatchAreas$$.asReadonly();
  }

  get dispatchLayers$(): Observable<DispatchLayers> {
    return this._dispatchLayers$.pipe();
  }

  get managerAreas$(): Observable<Array<ManagerArea>> {
    return this._managerAreas$.pipe();
  }

  get routingAlgorithms$(): Observable<Array<RoutingAlgorithm>> {
    return this._routingAlgorithms$.pipe();
  }

  get userCanModifySetting$$(): Signal<[isActive, settingValue]> {
    return this._userCanModifySetting$$.asReadonly();
  }

  get userCanModifySetting(): [isActive, settingValue] {
    return this._userCanModifySetting$$();
  }

  get defaultLayer() {
    for (let i = 0, len = this._dispatchLayers$.value.length; i < len; ++i) {
      if (this._dispatchLayers$.value[i] !== undefined && this._dispatchLayers$.value[i].isDefault) {
        return this._dispatchLayers$.value[i];
      }
    }
  }
}

export type DispatchAreaPoint = {
  AreaID: number;
  AreaNameShort: string;
  AreaName: string;
  LocatorID: number;
  ManagerAreaID: number;
  UnionGas3rdParty: string;
  LayerID: number;
  TagID: number | null;
  DispatchAreaID: number;
  Lat: number;
  Lng: number;
  AlgorithmColour: string;
  AlgorithmID: number;
  onRouting: number;
};

export type DispatchArea = Array<DispatchAreaPoint>;

export type DispatchAreaMap = Record<number, DispatchArea>;

export type DispatchLayer = {
  LayerTypeID: number;
  LayerID: number;
  Name: string;
  Description: string;
  isDefault: number;
};

export type DispatchLayers = DispatchLayer[];

export type ManagerArea = {
  ManagerAreaID: number;
  ManagerAreaName: string;
};

export type RoutingAlgorithm = {
  AlgorithmID: number;
  AlgorithmName: string;
  AlgorithmDesc: string;
  isDefault: number;
  AlgorithmColour: string;
};

export type MutationArea = {
  curAreaName: string;
  curAreaUserID: string;
  curManagerAreaID: number;
  curEmail: string;
  curLayerID: number;
  curTagID: number;
  curAreaAlgoID: '2';
  PolygonCoords: Array<string>; // lat,lon
};

export type isActive = boolean;
export type settingValue = string;
