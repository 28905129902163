import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Error404Component } from "./error404/error404.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../shared/material.module";

@NgModule({
  declarations: [Error404Component],
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [Error404Component],
})
export class Error404Module {}
