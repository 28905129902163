<ng-container *ngIf="this.loaded; else loading">
  <ng-container *ngIf="documentViewerTypeID == 0">
    <div style="height: 100%; width: 100%">
      <app-pdf-viewer [pdfOptions]="options"></app-pdf-viewer>
    </div>
  </ng-container>
  <ng-container *ngIf="documentViewerTypeID == 1">
    <div style="height: 100%; width: 100%">
      <app-pdf-acknowledgement></app-pdf-acknowledgement>
    </div>
  </ng-container>
  <ng-container *ngIf="documentViewerTypeID == 2">
    <app-base-modal [id]="SIGNATURE_MODAL_ID" maxwidth="960px">
      <ng-container class="competers-modal-content">
        <app-document-viewer-signature-form
          (successEmitter)="signatureSuccess()"
          [documentSignatureModalID]="SIGNATURE_MODAL_ID"
          [disclaimer]="rowDisclaimer"
          [isModal]="true"
          [modalOpened$]="modalOpened$"
        >
        </app-document-viewer-signature-form>
      </ng-container>
    </app-base-modal>

    <app-success-modal [id]="SUCCESS_MODAL_ID" maxwidth="360px">
      <ng-container class="competers-modal-content">
        The signature process was successful
      </ng-container>
    </app-success-modal>

    <div style="height: 100%; width: 100%">
      <app-pdf-viewer [pdfOptions]="options"></app-pdf-viewer>
      <button
        *ngIf="!acknowledged"
        class="extended-square-fab-button"
        mat-fab
        color="primary"
        aria-label="sign document"
        (click)="signHereClick()"
      >
        <mat-icon svgIcon="sign" />
        <span class="extended-square-fab-button__text">sign here</span>
      </button>
      <button
        *ngIf="acknowledged"
        class="extended-square-fab-button"
        mat-fab
        style="background-color: #2abc178e; color: white"
        aria-label="sign document"
        disabled
      >
        <mat-icon svgIcon="sign" />
        <span class="extended-square-fab-button__text"
          >Signed By {{ FirstName }} {{ LastName }}</span
        >
        <span></span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="documentViewerTypeID == 3">
    <div
      class="signAndViewContainer"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div
        *ngIf="signAndViewStage != SIGN_AND_VIEW_STAGES.PDF"
        class="signAndViewDisclaimer"
        style="padding-top: 16px; padding-bottom: 16px"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <img
          fxFlex="168px"
          fxFlex.lt-md="168px"
          [src]="
            sanitize('data:image/jpg;base64, ' + arrayBufferToBase64(logo))
          "
        />
        <div
          fxFlex
          fxLayout="column"
          fxLayoutAlign="space-between end"
          style="font-size: 15px; min-height: 65px"
        >
          <div fxLayout="row wrap" fxLayoutAlign="end start">
            <div
              fxLayout="column"
              fxLayoutAlign="start end"
              style="margin-left: 16px; text-align: right"
            >
              <h6 class="mat-headline-1">ISSUES VIEWING</h6>
              <h6 class="mat-headline-1">+1 877-395-1122</h6>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="signAndViewStage == SIGN_AND_VIEW_STAGES.Disclaimer">
        <div class="signAndViewDisclaimer" #DisclaimerElement></div>
        <app-document-viewer-signature-form
          class="signAndViewDisclaimer"
          [isModal]="false"
          (successEmitter)="signAndViewAcknowledged()"
        ></app-document-viewer-signature-form>
      </ng-container>
      <ng-container *ngIf="signAndViewStage == SIGN_AND_VIEW_STAGES.PDF">
        <app-pdf-viewer
          style="height: calc(100vh - 4px); width: 100vw"
          [pdfOptions]="options"
        >
        </app-pdf-viewer>
        <!-- <button *ngIf="acknowledged" class="extended-square-fab-button" mat-fab style="background-color: #2abc178e; color: white;" aria-label="sign document" disabled>
          <mat-icon><img id="signIcon"></mat-icon>
          <span class="extended-square-fab-button__text">Acknowledged By {{FirstName}} {{LastName}}</span>
          <span></span>
        </button> -->
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
    style="width: 100%; height: 100%"
  >
    <mat-spinner></mat-spinner>
    <p class="mat-subtitle-2">Numéro d'assistance / Support Number</p>
    <p class="mat-subtitle-2">1-877-395-1122</p>
  </div>
</ng-template>
