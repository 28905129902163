import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-pill-indicator",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      *ngIf="content !== ''"
      class="relative flex flex-row items-center justify-center z-10 bg-primary rounded-full px-4 shadow-2xl"
      [ngClass]="classOverride"
    >
      <div class="h-7 flex flex-row items-center justify-center uppercase font-semibold font-rajdhani text-md leading-4 text-white">
        {{ content }}
      </div>
    </div>
  `,
})
export class PillIndicatorComponent {
  @Input() content = "";
  @Input() classOverride = "";

  constructor() {}
}
