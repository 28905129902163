<div style="height: 100%; overflow: hidden" class="page-background">
  <div style="height: 100%">
    <app-ticket-details
      #appTicketDetails
      (modalClosed)="onModalClose()"
      (syncFinished)="onSyncFinished($event)"
      (formGroupChanged)="formGroupChanged($event)"
      [props]="childProps"
    ></app-ticket-details>
  </div>
</div>
