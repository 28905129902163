import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';
import { ClickStopPropagationDirective } from '../../../../modules/core/directives/click-stop-propagation.directive';
import { isEqual } from 'lodash-es';

export type Option<T> = { value: T; name: string };
export type Options<T> = Array<Option<T>>;

@Component({
  selector: 'app-searchable-dropdown',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchableDropdownComponent),
    },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ClickStopPropagationDirective,
  ],
  template: `
    <div
      class="box-border flex {{
        inlined ? 'flex-row justify-between items-start' : 'flex-col justify-start items-start'
      }} bg-transparent w-full  {{ fitParentContainer ? 'h-full' : '' }}">
      @if (title !== '' && title) {
        <div class="flex justify-start items-center h-8">
          <label for="me" class="font-rajdhani font-semibold text-md uppercase">
            {{ title }}
          </label>
        </div>
      }
      <div
        #container
        [tabIndex]="0"
        (click)="activateInput()"
        class="flex flex-col justify-start items-center relative {{ inlined ? 'w-[336px]' : 'w-full' }}  {{
          fitParentContainer ? 'h-full' : ''
        }} {{ disabled ? '' : 'hover:cursor-pointer' }}">
        <div
          class="relative {{
            _isActive$$() ? 'z-30' : ''
          }} flex flex-row justify-between items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-full {{
            fitParentContainer ? 'h-full' : 'h-8'
          }} px-2 pr-1 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent {{
            disabled ? 'bg-gray-100 bg-opacity-30' : 'bg-white'
          }}">
          <div class="flex flex-col h-full w-full justify-start items-start overflow-auto">
            @if (!_currentlySelected || _currentlySelected === '' || _currentlySelected === null) {
              <span
                class="flex items-center {{
                  _isActive$$() ? 'h-0' : 'h-full'
                }} truncate p-0 m-0 text-gray-400 font-montserrat text-sm w-full">
                {{ placeholder }}
              </span>
            } @else {
              <span
                class="flex items-center {{
                  _isActive$$() ? 'h-0' : 'h-full'
                }} truncate p-0 m-0 font-montserrat text-sm w-full max-w-full">
                {{ _currentlySelected }}
              </span>
            }
            <div
              class="w-full flex flex-row justify-start items-center {{
                _isActive$$() ? 'h-full w-full' : 'h-0 w-0'
              }} overflow-hidden">
              <mat-icon class="size-[14px] text-[14px]" svgIcon="search"></mat-icon>
              <input
                #input
                class="box-border flex-grow bg-transparent outline-none border-none font-montserrat"
                [id]="title"
                [name]="title"
                [disabled]="disabled"
                [ngModel]="_textValue$$()"
                (ngModelChange)="_textValue$$.set($event)" />
            </div>
          </div>
          @if (!disabled) {
            @if (_isActive$$()) {
              @if (input.value.length > 0) {
                <button
                  appClickStopPropagation
                  (click)="clearInput()"
                  class="flex justify-center items-center appearance-none border-none
                    bg-transparent hover:bg-gray-300 hover:bg-opacity-60 cursor-pointer p-0.5 rounded-full">
                  <mat-icon>close</mat-icon>
                </button>
              }
            } @else {
              @if (selection.hasValue()) {
                <button
                  appClickStopPropagation
                  (click)="selection.clear()"
                  class="flex justify-center items-center appearance-none border-none
                    bg-transparent hover:bg-gray-300 hover:bg-opacity-60 cursor-pointer p-0.5 rounded-full">
                  <mat-icon>close</mat-icon>
                </button>
              } @else {
                <mat-icon svgIcon="arrow_down" />
              }
            }
          }
        </div>
        @if (_isActive$$()) {
          <div
            class="flex w-full absolute top-6 overflow-hidden bg-white pb-2 pt-4 border-solid border-1 border-[#D1D3D4] rounded-b-md border-t-0 z-20 box-border ">
            <div
              #scroller
              class="flex flex-col justify-start items-start w-full bg-white max-h-40 overflow-y-auto scroll-auto">
              @if (_filteredOptions.length === 0) {
                <span
                  class="flex justify-center items-center box-border appearance-none border-none bg-transparent hover:bg-primary hover:text-white w-full h-8 text-md font-montserrat">
                  No results found...
                </span>
              } @else {
                @for (option of _filteredOptions; track $index) {
                  <button
                    appClickStopPropagation
                    tabindex="-1"
                    (click)="selection.toggle(option)"
                    class="flex justify-between items-center appearance-none border-none
                    bg-transparent hover:bg-primary cursor-pointer w-full pl-3 pr-8 py-2.5">
                    <span
                      class="hover:text-white text-md font-rajdhani uppercase font-semibold text-left max-w-[195px]">
                      {{ option.name }}
                    </span>
                    <span
                      class="flex justify-center items-center min-h-4 min-w-4 h-4 w-4 bg-gray-100 border-solid border-1 rounded-sm text-black">
                      @if (selection.isSelected(option)) {
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                        </svg>
                      }
                    </span>
                  </button>
                }
              }
            </div>
          </div>
        }
      </div>
    </div>
  `,
  styles: [
    `
      div {
        button {
          &:hover {
            span.font-rajdhani {
              color: #fff;
            }
          }
        }
      }
    `,
  ],
})
export class SearchableDropdownComponent<T> implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('container') container: ElementRef<HTMLDivElement>;
  @ViewChild('scroller') scroller: ElementRef<HTMLDivElement>;
  // I/O
  @Input() title: string;
  @Input() multiple: boolean = true;
  @Input() inlined: boolean = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() options: Options<T> = [];
  @Input() fitParentContainer = false;

  // services
  private renderer: Renderer2 = inject(Renderer2);
  private cdref = inject(ChangeDetectorRef);

  // signals and observables
  private destroy$ = new Subject<void>();
  private changed$: Subscription;
  protected _textValue$$ = signal<string>('');
  protected _options$$ = signal<Options<T>>([]);
  protected _isActive$$ = signal<boolean>(false);

  // members
  protected _filteredOptions: Options<T> = [];
  protected _currentlySelected = '';
  private listenerFn: () => void;
  private touched = false;
  private comparer = (a: Option<T>, b: Option<T>) => isEqual(a, b);
  protected selection: SelectionModel<Option<T>>;
  protected field = {
    title: 'test',
    type: 'text',
    name: 'formControl',
  };

  constructor() {
    effect(() => {
      const waste = this._isActive$$();
      if (waste) {
        this.input.nativeElement.focus();
      }
    });
    effect(() => {
      this._filteredOptions = this._options$$().filter(({ name }) =>
        (name ?? '').toLowerCase().includes(this._textValue$$().toLowerCase())
      );
    });
  }

  ngAfterViewInit() {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (this._isActive$$()) {
        if (e.composedPath().indexOf(this.container.nativeElement) === -1) {
          this._isActive$$.set(false);
          this.cdref.detectChanges();
        }
      }
    });
  }

  // only need the options from the change object
  ngOnChanges({ options, multiple }: SimpleChanges) {
    if (options) {
      this._options$$.set(options.currentValue);
    }
    if (multiple) {
      if (this.changed$ !== undefined) {
        this.changed$.unsubscribe();
      }
      this.selection = new SelectionModel<Option<T>>(this.multiple, [], true, this.comparer);
      this.selection.changed.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (!this.multiple) {
          this._isActive$$.set(false);
        }
        this.onChange(this.selection.selected);
        this.onTouched();
        this._currentlySelected = this.selection.selected.map((x) => x.name).join(', ') || '';
        this.cdref.detectChanges();
      });
    }
  }

  onChange: (data: Options<T>) => void = () => {};
  onTouched: () => void = () => {};

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  //For dropdown selected options
  writeValue(data: Options<T>) {
    this.markAsTouched();
    if (data) {
      this.selection.clear();
      this.selection.setSelection(...data);
    } else {
      this.selection.clear();
    }
  }

  registerOnChange(onChange: never) {
    this.onChange = onChange;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdref.detectChanges();
  }

  registerOnTouched(fn: never) {
    this.onTouched = fn;
  }

  activateInput() {
    if (!this.disabled) {
      this._isActive$$.set(true);
    }
  }

  //Clears only input text not selected fields
  clearInput() {
    this._textValue$$.set('');
    this.input.nativeElement.focus();
  }
}
