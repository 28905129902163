import { Component, Input, OnInit } from "@angular/core";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { LocaleService } from "src/app/modules/core/services/locale/locale.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Component({
  selector: "app-acknowledgement-view",
  templateUrl: "./acknowledgement-view.component.html",
  styleUrls: ["./acknowledgement-view.component.scss"],
})
export class AcknowledgementViewComponent implements OnInit {
  className: string = "AcknowledgementViewComponent";
  @Input() props: any;
  locale: any;

  constructor(
    // error$: MainErrorService,
    private logger$: LoggerService,
    // alert$: NotificationService,
    private locale$: LocaleService
  ) {}

  /**
   * setup locale subscription
   */
  ngOnInit(): void {
    this.locale$.getLocaleSubscription().subscribe((newLocale) => {
      if (newLocale.locale && newLocale.locale[this.className]) {
        this.locale = newLocale.locale[this.className];
      } else {
        //if reach then locale files setup incorrectly.
        this.logger$.log("Component language not supported");
      }
    });
  }

  /**
   * send a message to component message system with declined: true
   */
  onClickDecline() {
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { declined: true },
      senderID: "AcknowledgementViewComponent",
    };

    this.props.messageService.sendToMessageStream(message);
  }

  /**
   * send a message to message system with acknowledged: true
   */
  onClickAccept() {
    // check off the acknowledged row in  the table

    // show signature view
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { acknowledged: true },
      senderID: "AcknowledgementViewComponent",
    };

    this.props.messageService.sendToMessageStream(message);
  }
}
