<div class="h-full w-full">
  <div *ngIf="isNotListRoute()" class="scroll-px-40 h-[72px] flex flex-row items-center justify-start pl-4 bg-white border-bottom-thin-grey">
    <button mat-icon-button *ngIf="isNotListRoute(); else gear" (click)="navigateBack()"
      class="mr-2"><mat-icon>arrow_back</mat-icon></button>

    <div>
      <p class="mt-0 mb-0 font-rajdhani uppercase text-headline-5 font-semibold">{{routeTitle}}</p>
    </div>
  </div>

  <div class="w-full h-full overflow-auto" [ngClass]="{'max-h-[calc(100%-72px)]': isNotListRoute(), 'h-100': !isNotListRoute()}">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #gear>
  <button mat-icon-button disabled class="mr-2"><mat-icon>settings</mat-icon></button>
</ng-template>