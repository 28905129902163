import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  OnDestroy,
  Signal,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { distinctUntilChanged, filter, skip, Subject } from 'rxjs';
import { CompetersCheckboxComponent } from '../../../inputs/competers-checkbox/competers-checkbox.component';
import { SearchableDropdownComponent } from '../../../inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersInputComponent } from '../../../inputs/competers-input/competers-input.component';
import { VerticalCollapsibleComponent } from '../../../containers/vertical-collapsible/vertical-collapsible.component';
import { DispatchAreaService } from '../../../../services/dispatch-area/dispatch-area.service';
import { CommonModule } from '@angular/common';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { DispatchAreaEditorService } from './dispatch-area-editor.service';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { SnackbarType } from '../../../../../modules/shared/snackbar/snackbar/snackbar';
import { MapArrayToSelectionOptionPipe } from '../../../inputs/searchable-dropdown/ToSelectionOption.pipe';
import { takeUntil } from 'rxjs/operators';
import { TicketMapService } from '../ticket-map/ticket-map.service';
import { CompetersSlideToggleComponent } from '../../../inputs/competers-slide-toggle/competers-slide-toggle.component';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';

@Component({
  selector: 'app-dispatch-area-editor',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dispatch-area-editor.component.html',
  styleUrl: './dispatch-area-editor.component.scss',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIcon,
    CompetersCheckboxComponent,
    SearchableDropdownComponent,
    CompetersInputComponent,
    VerticalCollapsibleComponent,
    FormsModule,
    MapArrayToSelectionOptionPipe,
    CompetersSlideToggleComponent,
  ],
})
export class DispatchAreaEditorComponent implements OnDestroy {
  // services
  private cdr = inject(ChangeDetectorRef);
  private userService = inject(UsersService);
  private settingsService = inject(UserService);
  private snackBarService = inject(SnackbarService);
  protected dispatchAreaService = inject(DispatchAreaService);
  protected myService = inject(DispatchAreaEditorService);
  protected ticketMapService = inject(TicketMapService);

  // signals and observables
  private _destroy$ = new Subject<void>();
  private _showHideDispatchAreaColors: boolean = false;
  protected _showRouteEditingButtons: boolean = false;

  // members
  protected _formOptions$$: Signal<Record<string, Array<{ name: string; value: any }>>>;

  protected form = new FormGroup(
    {
      name: new FormControl(''),
      user: new FormControl([]),
      managerArea: new FormControl([]),
      routing: new FormControl([]),
    },
    ({ value }) => {
      let errors = null;
      const { name, user, managerArea, routing } = value;
      if (!name || name.trim() == '') {
        errors = { ...errors, name: 'Name is required' };
      }
      if (user?.length == 0) {
        errors = { ...errors, user: 'User is required' };
      }
      if (managerArea?.length == 0) {
        errors = { ...errors, managerArea: 'Manager Area is required' };
      }
      // if (routing?.length == 0) {
      //   errors = { ...errors, routing: 'Routing is required' };
      // }
      return errors;
    }
  );
  private toggleDisableOpts = { onlySelf: true, emitEvent: false };

  constructor() {
    // detect changes when form value changes
    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this._showRouteEditingButtons = this.settingsService.isSettingActive(SettingID.ROUTING_PRESETS);

    // computed signal for reacting to changes affecting the form options
    this._formOptions$$ = computed(() => {
      try {
        return {
          areas: this.userService
            .users$$()
            .sort(({ LastName: a }, { LastName: b }) => a.localeCompare(b))
            .map((x) => {
              return {
                name: `${x['LastName']}, ${x['FirstName']} (${
                  this.userService.getUserCategoriesByID(x.UserCategoryID).Title
                })`,
                value: x['UserID'],
              };
            }),
          users: this.userService
            .users$$()
            .map((x) => ({ name: `${x['LastName']}, ${x['FirstName']}`, value: x['UserID'] })),
        };
      } catch (e) {
        console.error(e);
        this.snackBarService.openSnackbar('Failed to load dispatch area editor options', SnackbarType.error);
        return {
          areas: [],
          users: [],
        };
      }
    });

    this.form.disable(this.toggleDisableOpts);

    // only enable form when user has permission to modify settings
    if (this.myService.userCanModifySetting[0] && this.myService.userCanModifySetting[1] == '1') {
      // enable form when adding or modifying area && reset form when adding or modifying status changes
      this.myService.addingArea$.pipe(skip(1), takeUntil(this._destroy$)).subscribe((val) => {
        if (val) {
          this.form.reset();
          this.form.enable(this.toggleDisableOpts);
        } else {
          this.form.disable(this.toggleDisableOpts);
          this.form.reset();
        }
      });
      this.myService.modifyingArea$.pipe(skip(1), takeUntil(this._destroy$)).subscribe((val) => {
        if (val) {
          this.form.enable(this.toggleDisableOpts);
        } else {
          this.form.disable(this.toggleDisableOpts);
          this.form.reset();
        }
      });

      // reset form when menu selection changes
      this.ticketMapService.menuSelection$
        .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
        .subscribe((menuSelection) => {
          if (!menuSelection.includes(0)) {
            this.form.reset();
          }
        });

      // subscribe to selected area changes
      this.myService.selectedArea$
        .pipe(
          skip(1),
          filter(() => !this.myService.addingArea && !this.myService.modifyingArea),
          takeUntil(this._destroy$)
        )
        .subscribe(([val]) => {
          if (!val) {
            this.form.reset();
            this.form.disable(this.toggleDisableOpts);
          } else {
            const { name, value } = val;
            const area = this.dispatchAreaService.getAreaByID(value);
            if (!area) {
              this.form.reset();
              return;
            }
            const { LocatorID, ManagerAreaID: maiVal, AlgorithmID: Algoid } = area[0];
            const user = this.userService.getUserByID(LocatorID);
            const { ManagerAreaID, ManagerAreaName } = this.dispatchAreaService.getManagerAreaByID(maiVal);
            const { AlgorithmID, AlgorithmName } = this.dispatchAreaService.getRoutingByID(Algoid);
            this.form.patchValue({
              name: name ?? '',
              user: [{ name: `${user['LastName']}, ${user['FirstName']}`, value: user['UserID'] }],
              managerArea: [{ name: ManagerAreaName, value: ManagerAreaID }],
              routing: [{ name: AlgorithmName, value: AlgorithmID }],
            });
          }
        });
    }
  }

  get showHideDispatchAreaColors(): boolean {
    return this._showHideDispatchAreaColors;
  }

  set showHideDispatchAreaColors(value: boolean) {
    this._showHideDispatchAreaColors = value;
    this.ticketMapService.toggleDispatchLayerColours(value);
  }

  ngOnDestroy() {
    this.myService.addingArea = false;
    this.myService.modifyingArea = false;
    this.myService.selectedArea = [];
    this._destroy$.next();
    this._destroy$.complete();
  }

  protected getSubControls(name: string): FormControl {
    return this.form['controls'][name];
  }

  submitForm() {
    if (this.myService.userCanModifySetting[0] && this.myService.userCanModifySetting[1] != '1') {
      this.snackBarService.openSnackbar('You do not have permission to modify dispatch areas', SnackbarType.error);
      throw new Error('You do not have permission to modify dispatch areas');
    }
    if (this.form.valid) {
      const { name, user, managerArea, routing } = this.form.value;
      const { value: areaID } = this.myService.selectedArea[0] ?? {};
      if (areaID) {
        this.myService.submitChanges({ name, user, managerArea, routing, areaID });
      } else {
        this.myService.submitChanges({ name, user, managerArea, routing });
      }
    } else {
      let errorMessage = 'Invalid Form';
      if (this.form.errors) {
        for (const key in this.form.errors) {
          errorMessage = errorMessage + `: ${this.form.errors[key]}`;
        }
      }
      this.snackBarService.openSnackbar(errorMessage, SnackbarType.error);
    }
  }

  protected cancelAction() {
    this.myService.modifyingArea = false;
    this.myService.addingArea = false;
  }
}
