import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, tap } from 'rxjs';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { ApiService, UtilocateApiRequest } from '../core/api/baseapi.service';
import { LoggerService } from '../core/services/logger/logger.service';
import { Algorithm } from './algorithm';

export enum ActionID {
  NOTHING = 0,
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
  GET_ALL = 4,
  GET_AGORITHM = 5,
  SET_DEFAULT = 6,
  SET_ALL = 7,
  GET_NUM_USERS = 8,
}

@Injectable({
  providedIn: 'root',
})
export class RoutingPresetService {
  constructor(
    private logger$: LoggerService,
    private utilocateApiService: ApiService
  ) {}

  reloadAlgorithms = new BehaviorSubject(0);

  getReloadAlgorithms() {
    return this.reloadAlgorithms.asObservable();
  }

  emitReloadAlgorithms(event: number) {
    this.reloadAlgorithms.next(event);
  }

  /**
   * make api call to get all algorithms from db
   * @returns api call result
   */
  getAlgorithms() {
    const url = apiKeys.u2.rouingPresetController;
    const type = api[url].type;

    let value = {
      ActionID: ActionID.GET_ALL,
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
  }

  /**
   * call api to get a specific algorithm from the db
   * @param algorithmID specific algorithm to get
   * @returns api call result (algorithm from db)
   */
  getAlgorithm(algorithmID: number): Observable<any> {
    if (algorithmID) {
      const url = apiKeys.u2.rouingPresetController;
      const type = api[url].type;

      let value = {
        AlgorithmID: algorithmID,
        ActionID: ActionID.GET_AGORITHM,
      };

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: value,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    }

    return of({ ok: false });
  }

  /**
   * call api to update the default algorithm for this client
   * @param algorithmID id of algorithm to be the new default
   * @returns api call result
   */
  setDefault(algorithmID: number): Observable<any> {
    if (algorithmID) {
      const url = apiKeys.u2.rouingPresetController;
      const type = api[url].type;

      let value = {
        AlgorithmID: algorithmID,
        ActionID: ActionID.SET_DEFAULT,
      };

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: value,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    }

    return of({ ok: false });
  }

  /**
   * call api to set every user to this algorithm
   * @param algorithmID id of algorithm to set for every user
   * @returns api call result
   */
  setAll(algorithmID: number): Observable<any> {
    if (algorithmID) {
      const url = apiKeys.u2.rouingPresetController;
      const type = api[url].type;

      let value = {
        AlgorithmID: algorithmID,
        ActionID: ActionID.SET_ALL,
      };

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: value,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    }

    return of({ ok: false });
  }

  /**
   * call api to insert a new algorithm into the database
   * @returns api call result
   */
  createAlgorithm() {
    const url = apiKeys.u2.rouingPresetController;
    const type = api[url].type;

    let value = {
      Algorithm: {
        AlgorithmID: null,
        AlgorithmName: 'New Algorithm',
        AlgorithmDesc: '',
        Rules: [],
        isDefault: 0,
        ActionID: 1,
        AlgorithmColour: '#666666',
      },
    };

    let utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
      API_BODY: value,
    };

    return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
  }

  /**
   * call api to update an algorithm in the db
   * @param algorithm the algorithm to modify
   * @returns api call result
   */
  modifyAlgorithm(algorithm: Algorithm) {
    if (algorithm) {
      const url = apiKeys.u2.rouingPresetController;
      const type = api[url].type;

      let value = {
        Algorithm: algorithm,
      };

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: value,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    }

    return of({ ok: false });
  }

  /**
   * call the api to determing how many users are assigned to this algorithm
   * @param algorithmID the algorithm in question
   * @returns api call result (number of users assigned to this algorithm)
   */
  getNumUsersOnAlgorithm(algorithmID: number) {
    if (algorithmID) {
      const url = apiKeys.u2.rouingPresetController;
      const type = api[url].type;

      let value = {
        AlgorithmID: algorithmID,
        ActionID: ActionID.GET_NUM_USERS,
      };

      let utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: url,
        API_TYPE: type,
        API_BODY: value,
      };

      return from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest));
    }

    return of({ ok: false });
  }
}
