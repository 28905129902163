<div fxLayout="row" [class.mat-elevation-z3]="true" class="bottom-nav">
  <div class="padding-div"></div>
  <div
    style="width: calc(100% - 8px)"
    fxLayout="row"
    fxLayoutAlign="space-evenly center"
  >
    <div
      *ngFor="let input of inputs; let i = index"
      (click)="getInput(i)"
      class="nav-button"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon
        id="bn-{{ input.id }}-icon"
        aria-hidden="false"
        class="bottom-nav-icon"
      >
        {{ input.icon }}
      </mat-icon>
      <div
        id="bn-{{ input.id }}-text"
        class="mat-body"
        class="bottom-nav-text mat-small"
      >
        {{ input.label }}
      </div>
    </div>
  </div>
  <div class="padding-div"></div>
</div>
