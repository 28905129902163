import { Injectable } from "@angular/core";
import { COMPLETION_TABLE_NAMES } from "src/app/modules/core/admin/tables";
import {
  BaseCompletionCacheService,
  BaseDocumentCacheService,
  BaseS3DocumentCacheService,
  CacheWhereClause,
} from "src/app/modules/core/cache/cache.interface";
import { DocumentService, DocumentTypeID } from "src/app/modules/core/services/document/document.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { ServerDocumentService } from "../documents/download-document.service";
import { NodeDocumentService } from "../documents/node-document.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";
import { UtilocateTokenPaths } from "src/app/modules/core/services/token/token.service";
import { environment } from "src/environments/environment";
import { CacheService, StoreType } from "src/app/modules/core/cache/cache.service";

@Injectable({
  providedIn: "root",
})
export class UtilocateDocumentsCacheService {
  IS_LIVE: boolean = false;
  IS_LOADING_FOR_S3_SETTING: boolean = false;
  localDocCount: number = 0;
  LOCAL_DOC_LIMIT: number = 100;
  warnedUserAboutDocumentLimit: boolean = false;

  constructor(
    private baseDocumentCacheService: BaseDocumentCacheService,
    private baseS3DocumentCacheService: BaseS3DocumentCacheService,
    private userService: UserService,
    private idb: CacheService,
    private completionsTableService: BaseCompletionCacheService,
    private nodeDocumentService: NodeDocumentService,
    private serverDocumentService: ServerDocumentService,
    private documentService: DocumentService,
    private snackBarService: SnackbarService
  ) {
    this.IS_LIVE = environment.production == true;
    // (async () => {
    //   let downloadFromS3Setting: boolean | Error = await this.userService.getSettings(SETTING_ID_LOADING_DOCUMENT_FROM_S3.toString());
    //   if (!(downloadFromS3Setting instanceof Error) && downloadFromS3Setting) {
    //     this.IS_LOADING_FOR_S3_SETTING = true;
    //   }
    // })();
  }

  async queryTicketDocumentIDs(assignmentID: string) {
    const result = await this.baseDocumentCacheService.queryTableData(
      assignmentID
    );
    if (result) {
      const keysWithObjects = Object.keys(result).filter(key => result[key] !== null);
      return keysWithObjects;
    }
    return [];
  }

  /**
   * Returns keys where it is NOT null 
   * @param assignmentID 
   * @returns 
   */
  async queryTicketS3DocumentIDs(assignmentID: string) {
    const result = await this.baseS3DocumentCacheService.queryTableData(
      assignmentID
    );
    if (result) {
      const keysWithObjects = Object.keys(result).filter(key => result[key] !== null);
      return keysWithObjects;
    }
    return [];
  }

  async clearTicketChanged() {
    try {
      const insertResult = await this.idb.insert(
        StoreType.TICKET_CHANGED,
        "ticketChanged",
        []
      );

      return insertResult;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async uploadDocument(data: any, zipname: string, metadata: any) {
    //aux photos upload *Directly* to s3, not through the bucket and UploadDocumentS3
    if (metadata && metadata["DocumentTypeID"] == DocumentTypeID["Auxiliary Image"]) {
      return this.nodeDocumentService.uploadAuxImageToS3(
        data,
        zipname,
        metadata
      );
    } else {
      return this.nodeDocumentService.uploadDocToS3(data, zipname, metadata);
    }
  }

  async getHighestLocalDocumentID(isS3Document: boolean = false, assignmentID: string) {
    try {
      const { primaryIndicator, tableToQuery } =
        isS3Document
          ? { primaryIndicator: "S3DocumentID", tableToQuery: COMPLETION_TABLE_NAMES.tbCompletions_S3Documents }
          : { primaryIndicator: "DocumentID", tableToQuery: COMPLETION_TABLE_NAMES.tbCompletions_Documents };


      const result = await this.completionsTableService.queryTableData(
        tableToQuery,
        [],
        false,
        assignmentID
      );

      if (!(result instanceof Error)) {
        const docIDs = result.reduce((total, current) => {
          if (current && current[primaryIndicator]) {
            total.push(current[primaryIndicator]);
          }
          return total;
        }, []);

        let highestDocID = -1;
        for (let i = 0; i < docIDs.length; i++) {
          if (parseInt(docIDs[i], 10) > highestDocID) {
            highestDocID = parseInt(docIDs[i], 10);
          }
        }
        return highestDocID + 1;
      } else {
        throw new Error("CANNOT FIND DOCUMENTS TABLE");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getDocumentBlob(assignmentID: any, documentID: any, isS3Document: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this.findLocalDocument(assignmentID, documentID, isS3Document)
        .then((file: any) => {
          if (file) {
            resolve(file);
          } else {
            reject("Document not found");
          }
        })
        .catch((error: any) => {
          reject(error); // Reject with the error from findLocalDocument
        });
    });
  }


  /**
   * 
   * @param assignmentID 
   * @param documentID   
   * @param {boolean} isS3Document 
   * @returns 
   */
  downloadDocument(assignmentID: any, documentID: any, isS3Document: boolean = false): Promise<any> {
    return new Promise(async (resolve) => {
      const local: any = await this.findLocalDocument(assignmentID, documentID, isS3Document);
      if (local) {
        let buffer = null;
        let type = null;

        if (local.type == "pdf") {
          buffer = URL.createObjectURL(local.Blob);
          type = "pdf";

          resolve({ buffer: buffer, FileName: local.FileName, type: type });
        } else if (
          local.type == "img" ||
          local.type == "png" ||
          local.type == "jpg" ||
          local.type == "jpeg" ||
          local.type == "txt" ||
          local.type == "dbf"
        ) {
          // concert blob to base64 for download and viewing
          const fileReader = new FileReader();
          fileReader.readAsDataURL(local.Blob);
          fileReader.onload = function () {
            buffer = fileReader.result;
            type = local.type == 'txt' ? "txt" : "image";
            resolve({ buffer: buffer, FileName: local.FileName, type: type });
          };
        } else {
          buffer = URL.createObjectURL(local.Blob);
          type = "other";
          resolve({ buffer: buffer, FileName: local.FileName, type: type });
        }
      } else {
        //get from db 
        let result: [string, object] | Error;
        let FileName: string;
        let imageObj: object;
        //if it is an s3 document
        if (this.IS_LOADING_FOR_S3_SETTING || isS3Document) {
          result = await this.downloadFromNodeS3(assignmentID, documentID);
        } else {
          result = await this.downloadFromServer(assignmentID, documentID);
        }

        if (!(result instanceof Error)) {
          FileName = result[0];
          imageObj = result[1];
        }

        let blob = null;
        let buffer = null;
        let type = null;
        const fileExt = this.documentService
          .getExtensionFromFilename(FileName)
          .toLowerCase();
        if (fileExt == "pdf") {
          blob = new Blob([new Uint8Array(imageObj["image"])], {
            type: "application/pdf",
          });
          buffer = URL.createObjectURL(blob);
          type = "pdf";
        } else if (fileExt == "png" || fileExt == "jpg" || fileExt == "jpeg") {
          blob = new Blob([new Uint8Array(imageObj["image"])]);
          buffer =
            "data:image/jpg;base64," +
            this.documentService.arrayBufferToBase64(imageObj["image"]);
          type = "img";
        } else if (fileExt == "txt" || fileExt == "dbf") {
          blob = new Blob([new Uint8Array(imageObj["image"])], {
            type: "text/plain",
          });
          buffer =
            "data:text/plain;base64," +
            this.documentService.arrayBufferToBase64(imageObj["image"]);
          type = "txt";
        } else {
          //handle other file types 
          blob = new Blob([new Uint8Array(imageObj["image"])], {
            type: "application/octet-stream",
          });
          buffer = URL.createObjectURL(blob);
          type = "other";
        }

        //add to local cache
        await this.addDocumentLocally(assignmentID, documentID, {
          Blob: blob,
          FileName: FileName,
          type: type,
          bFieldAdded: 0,
          isS3Document: isS3Document
        });
        resolve({ buffer: buffer, FileName: FileName, type });
      }
    });
  }

  private async downloadFromNodeS3(
    assignmentID: any,
    documentID: any
  ): Promise<[string, object] | Error> {
    const whereClause: CacheWhereClause = {
      Column: "S3DocumentID",
      Value: documentID,
    };
    const tbCompletions_S3Documents =
      await this.completionsTableService.queryTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
        [whereClause],
        false,
        assignmentID
      );
    if (!(tbCompletions_S3Documents instanceof Error)) {
      let S3Path = tbCompletions_S3Documents[0]["S3Path"];
      const S3Bucket = tbCompletions_S3Documents[0]["S3Bucket"];
      const FileName = tbCompletions_S3Documents[0]["FileName"];
      const ClientID = await this.userService.getUserValueFromToken(
        UtilocateTokenPaths.CLIENTID
      );

      //if the document row does NOT have the "S3Bucket" column, then we need to construct the path. Otherwise, use the S3Path and S3Bucket to get the file
      if (!S3Bucket) {
        //<STAGE>/<CLIENT_ID>/<ASSIGNMENT_ID>/<ZIP_FILENAME>
        S3Path = `${this.IS_LIVE.toString() == 'false' ? 'old' : 'live'}/${ClientID}/${assignmentID}/${S3Path}`;
      }
      const imageObj = await this.nodeDocumentService.getDocumentFromS3(S3Path, S3Bucket);
      return [FileName, imageObj];
    } else {
      throw new Error("Failed to find S3Path for DocumentID");
    }
  }

  private async downloadFromServer(
    assignmentID: string,
    documentID: string
  ): Promise<[string, object] | Error> {
    const whereClause: CacheWhereClause = {
      Column: "DocumentID",
      Value: documentID,
    };
    const tbCompletions_Documents =
      await this.completionsTableService.queryTableData(
        COMPLETION_TABLE_NAMES.tbCompletions_Documents,
        [whereClause],
        false, 
        assignmentID
      );
    if (!(tbCompletions_Documents instanceof Error)) {
      const FileName = tbCompletions_Documents[0]["FileName"];
      const imagePath =
        await this.serverDocumentService.downloadDocumentFromServer(
          assignmentID,
          documentID
        );
      if (imagePath) {
        const imageObj = await this.nodeDocumentService.getDocumentFromS3(
          imagePath[0]
        );
        return [FileName, imageObj];
      }
    } else {
      throw new Error("Failed to find S3Path for DocumentID");
    }
  }

  async updateDocumentMetadata(AssignmentID, DocumentID, isS3Document, arrayOfUpdates) {
    let success: boolean = false;
    console.log(AssignmentID, DocumentID, isS3Document, arrayOfUpdates);
    const localDoc = await this.findLocalDocument(AssignmentID, DocumentID, isS3Document);
    console.log(localDoc);

    //update the metadata 
    const metadata = localDoc['metadata'];
    arrayOfUpdates.forEach((update) => {
      metadata[update.key] = update.value;
    });

    try {
      //write back to the idb 
      await this.removeSingleDocumentLocally(AssignmentID, DocumentID, isS3Document);
      await this.addDocumentLocally(AssignmentID, DocumentID, {
        Blob: localDoc['Blob'],
        FileName: localDoc['FileName'],
        type: localDoc['type'],
        bFieldAdded: localDoc['bFieldAdded'],
        metadata: metadata,
        isS3Document: isS3Document
      });
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

  /**
   * Finds a document that may be on the machine 
   * @param assignmentID 
   * @param documentID 
   * @param {boolean} isS3Document 
   * @returns 
   */
  private async findLocalDocument(
    assignmentID: string,
    documentID: any,
    isS3Document: boolean
  ): Promise<object | null> {
    let document = null;
    let queryResult: object[] | Error = null;
    try {
      switch (isS3Document) {
        case true:    // get s3 document
          queryResult = await this.baseS3DocumentCacheService.queryTableData(assignmentID);
          break;
        case false:   // get tbCompletions document
          queryResult = await this.baseDocumentCacheService.queryTableData(assignmentID);
          break;
      }
      if (
        !(queryResult instanceof Error) &&
        queryResult &&
        queryResult[documentID]
      ) {
        document = queryResult[documentID];
      }
    } catch (error) {
      console.error(error);
      document = null;
    }
    return document;
  }

  async addDocumentLocally(assignmentID, documentID, docParams) {
    try {
      const doc = {
        DocumentID: documentID,
        bFieldAdded: docParams.bFieldAdded, // true when uploading new doc
        FileName: docParams.FileName,
        Blob: docParams.Blob,
        type: docParams.type,
        metadata: docParams.metadata,
      };

      let queryService;
      if (docParams.isS3Document) {
        queryService = this.baseS3DocumentCacheService;
      } else {
        queryService = this.baseDocumentCacheService;
      }

      let queryResult = await queryService.queryTableData(assignmentID);
      if (queryResult instanceof Error || queryResult == null) {
        queryResult = {};
      }

      queryResult[documentID] = doc;

      const insertResult = await queryService.insertTableData(assignmentID.toString(), queryResult);

      this.localDocCount += 1;
      if (this.localDocCount >= this.LOCAL_DOC_LIMIT && this.warnedUserAboutDocumentLimit === false) {
        this.snackBarService.openSnackbar(
          `${this.localDocCount} Documents ready to sync - please sync now`,
          SnackbarType.warning
        );
        this.warnedUserAboutDocumentLimit = true;
      }

      //if we're over the limit, warn every 5 documents 
      if (this.localDocCount > this.LOCAL_DOC_LIMIT && this.localDocCount%5 === 0) {
        this.snackBarService.openSnackbar(
          `${this.localDocCount} Documents ready to sync - please sync now`,
          SnackbarType.warning
        );
      }

      return insertResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async removeSingleDocumentLocally(assignmentID, documentID, isS3) {
    try {
      const promiseArr = [];
      let newAssignmentDocumentDB = {};
      let insertResult
      const cacheService = isS3 ? this.baseS3DocumentCacheService : this.baseDocumentCacheService;

        // update existing data
        let queryResult: any =
          await cacheService.queryTableData(assignmentID);
        if (!(queryResult instanceof Error)) {
          const key: any = Object.keys(queryResult);
          const value = Object.values(queryResult);
          const len = value.length;

          let IDToDelete: any;
          
          for (let j = 0; j < len; j++) {
            if(value[j]){
              if (value[j]["DocumentID"] == documentID) {
                IDToDelete = key[j];
              }
            }
            
          }
          queryResult = { ...queryResult, [IDToDelete]: null };
          newAssignmentDocumentDB = queryResult;
           insertResult = cacheService.insertTableData(
              assignmentID,
              newAssignmentDocumentDB
            )
        }

      return insertResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  async removeDocumentLocally() {
    try {
      const promiseArr = [];
      let newAssignmentDocumentDB = {};

      const assignmentIDs = await this.baseDocumentCacheService.getAllKeys();
      for (let i = 0; i < assignmentIDs.length; i++) {
        // update existing data
        let queryResult: any =
          await this.baseDocumentCacheService.queryTableData(assignmentIDs[i]);
        if (!(queryResult instanceof Error)) {
          const keys = Object.keys(queryResult);
          const values = Object.values(queryResult);
          const len = values.length;

          const IDsToDelete = [];
          for (let j = 0; j < len; j++) {
            if (values[j] && values[j]["bFieldAdded"] == 1) {
              IDsToDelete.push(keys[j]);
            }
          }

          for (let j = 0; j < IDsToDelete.length; j++) {
            queryResult = { ...queryResult, [IDsToDelete[j]]: null };
          }
          newAssignmentDocumentDB = queryResult;
          promiseArr.push(
            this.baseDocumentCacheService.insertTableData(
              assignmentIDs[i],
              newAssignmentDocumentDB
            )
          );
        }
      }

      const insertResult = await Promise.all(promiseArr);
      const removeS3Docs = await this.removeS3DocumentLocally();
      removeS3Docs.forEach((res) => {
        insertResult.push(res);
      });

      this.localDocCount = 0;

      return insertResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * Removes all S3 documents that are store locally 
   * This is a SEPERATE DB than the other documents 
   * @returns 
   */
  async removeS3DocumentLocally() {
    try {
      const promiseArr = [];
      let newAssignmentDocumentDB = {};

      const assignmentIDs = await this.baseS3DocumentCacheService.getAllKeys();
      for (let i = 0; i < assignmentIDs.length; i++) {
        // update existing data
        let queryResult: any =
          await this.baseS3DocumentCacheService.queryTableData(assignmentIDs[i]);
        if (!(queryResult instanceof Error)) {
          const keys = Object.keys(queryResult);
          const values = Object.values(queryResult);
          const len = values.length;

          const IDsToDelete = [];
          for (let j = 0; j < len; j++) {
            if (values[j] && values[j]["bFieldAdded"] == 1) {
              IDsToDelete.push(keys[j]);
            }
          }

          for (let j = 0; j < IDsToDelete.length; j++) {
            queryResult = { ...queryResult, [IDsToDelete[j]]: null };
          }
          newAssignmentDocumentDB = queryResult;
          promiseArr.push(
            this.baseS3DocumentCacheService.insertTableData(
              assignmentIDs[i],
              newAssignmentDocumentDB
            )
          );
        }
      }

      const insertResult = await Promise.all(promiseArr);

      this.localDocCount = 0;

      return insertResult;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async prepareDocumentsForUpload(userID: string): Promise<any> {
    try {
      const assignmentIDs = await this.baseDocumentCacheService.getAllKeys();
      const s3AssignmentIDs = await this.baseS3DocumentCacheService.getAllKeys();
      let documentIterator = 0;

      const prepareDocsPromises = [];

      // define a process documents function 
      const processDocuments = async (assignmentIDs, baseCacheService) => {
        for (let i = 0; i < assignmentIDs.length; i++) {
          const allTicketDocuments = await baseCacheService.queryTableData(assignmentIDs[i]);
          if (allTicketDocuments) {
            const values = Object.values(allTicketDocuments);

            for (let j = 0; j < values.length; j++) {
              if (values[j] && values[j]["bFieldAdded"] == 1) {
                const zipname =
                  this.documentService.formatDateForFileName(new Date()) +
                  "-" +
                  documentIterator +
                  "_" +
                  userID +
                  "_" +
                  assignmentIDs[i] +
                  ".zip";
                prepareDocsPromises.push(
                  this.uploadDocument(
                    values[j]["Blob"],
                    zipname,
                    values[j]["metadata"]
                  )
                );
                documentIterator++; //this variable prevents duplicate filenames 
              }
            }
          }
        }
      };

      await Promise.all([
        processDocuments(assignmentIDs, this.baseDocumentCacheService),
        processDocuments(s3AssignmentIDs, this.baseS3DocumentCacheService),
      ]);

      return prepareDocsPromises;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}
