import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { RouterModule } from "@angular/router";
import { ProgressBarModule } from "../shared/progress-bar/progress-bar.module";
import { SnackbarModule } from "../shared/snackbar/snackbar.module";
import { InvoicingManagerComponent } from "./invoicing-manager/invoicing-manager.component";
import { InvoiceReportComponent } from "./invoice-report/invoice-report.component";
import { MaterialModule } from "../shared/material.module";
import { TableModule } from "../shared/table/table.module";
import { TabulatorTableComponent } from "../shared/table/tabulator-table/tabulator-table.component";

@NgModule({
    declarations: [
        InvoicingManagerComponent,
        InvoiceReportComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,
        PageNavigationModule,
        ProgressBarModule,
        SnackbarModule,
        MaterialModule,
        TableModule,
        TabulatorTableComponent
    ]
})
export class InvoicingManagerModule { }
