<mat-card
  appearance="outlined"
  class="card"
  [fxFlex.xs]="width"
  fxLayout="column"
  fxLayoutAlign="space-between"
>
  <mat-card-title>{{ cardOptions.title }}</mat-card-title>
  <mat-card-content class="card-content">{{
    cardOptions.content
  }}</mat-card-content>
  <mat-card-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div *ngFor="let action of cardOptions.actions; let i = index">
          <button
            mat-button
            fxFlex
            *ngIf="i < 2"
            [disabled]="isActionDisabled(i)"
            (click)="actionOnClick(i, cardOptions.id)"
          >
            {{ action.name }}
          </button>

          <!-- for skeleton loading -->
          <!-- <div style="height: 36px; width: 64px">
            <ngx-content-loading [speed]="'1500ms'" [width]="100" [height]="56.25" [primaryColor]="'#f3f3f3'"
              [secondaryColor]="'#ecebeb'">
              <svg:g ngx-rect width="100" height="56.25" y="0" x="0" rx="0" ry="0"></svg:g>
            </ngx-content-loading>
          </div> -->
        </div>
      </div>
      <div fxLayout="row">
        <div *ngFor="let icon of cardOptions.icons; let i = index">
          <mat-icon
            class="card-icon"
            fxFlex
            *ngIf="i < 2"
            (click)="iconOnClick(i, cardOptions.id)"
            >{{ icon.name }}
          </mat-icon>
        </div>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
