import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-recursive-form-dialog",
  templateUrl: "./recursive-form-dialog.component.html",
  styleUrls: ["../recursive-form-template.scss"],
})
export class RecursiveFormDialogComponent {
  originalData: any;

  hasCategories: boolean = false;
  hasFields: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RecursiveFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.originalData = data;

    if (data.fields && Object.keys(data.fields).length > 0) {
      this.hasFields = true;
    }

    if (data.categories && Object.keys(data.categories).length > 0) {
      this.hasCategories = true;
    }
  }

  onFieldCheckChange(event) {
    if (this.data["fields"]) {
      if (this.data["fields"][event.source.id]["isVisible"]) {
        this.data["fields"][event.source.id]["isVisible"] = false;
      } else {
        this.data["fields"][event.source.id]["isVisible"] = true;
      }
    }
  }

  onCategoryCheckChange(event) {
    if (!this.data["categoriesToAdd"]) {
      this.data["categoriesToAdd"] = {};
    }
    if (event.checked) {
      this.data["categoriesToAdd"][event.source.id] =
        this.data["categories"][event.source.id];
    } else {
      delete this.data["categoriesToAdd"][event.source.id];
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
