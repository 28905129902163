<h1 mat-dialog-title class="mat-headline-1 blue font-rajdhani font-semibold">{{ header }}</h1>

<div
  mat-dialog-content
  style="height: 100%; width: 100%; overflow-x: hidden; background-color: white"
>
  <div
    *ngIf="!datetimeEditor"
    fxLayout="column"
    fxLayoutAlign="space-between center"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="height: 100%; width: 100%"
    >
      <span class="font-rajdhani font-semibold">Date</span>
      <p class="blue">{{ date | date: "mediumDate" }}</p>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="height: 100%; width: 100%; padding-bottom: 12px;"
    >
      <span class="font-rajdhani font-semibold">Start Time</span>
      <span [ngxTimepicker]="startTime" [(ngModel)]="newStartTime" [format]="24"
            class="cursor-pointer font-semibold ml-[150px] font-rajdhani"
            style="border-bottom: 1px solid #000;">{{ newStartTime }} </span>
      <ngx-material-timepicker [theme]="timePickerTheme" #startTime></ngx-material-timepicker>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      style="height: 100%; width: 100%"
    >
      <span class="font-rajdhani font-semibold">End Time</span>
      <span [ngxTimepicker]="endTime" [(ngModel)]="newEndTime" [format]="24"
            class="cursor-pointer font-semibold ml-[160px] font-rajdhani"
            style="border-bottom: 1px solid #000;">{{ newEndTime }} </span>
      <ngx-material-timepicker [theme]="timePickerTheme" #endTime></ngx-material-timepicker>
    </div>
  </div>

  <div *ngIf="datetimeEditor">
    <form [formGroup]="dateTimePickerFormGroup">
      <!-- DateTime In -->
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxLayout="row" style="width: 100%">
          <mat-form-field fxFlex="45" (click)="pickerTimeIn.open()">
            <mat-label class="font-rajdhani font-semibold">Time In</mat-label>
            <input
              matInput
              readonly="true"
              [matDatepicker]="pickerTimeIn"
              type="search"
              formControlName="dateIn"
              (dateChange)="dateTimePickerDateChanged('dateIn')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerTimeIn"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTimeIn></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="55">
            <input
              matInput
              [ngxTimepicker]="editableDialTimeIn"
              [readonly]="true"
              formControlName="timeIn"
            />
            <ngx-material-timepicker-toggle
              matSuffix
              [for]="editableDialTimeIn"
            >
              <mat-icon
                class="form-icon"
                style="line-height: 1.4"
                ngxMaterialTimepickerToggleIcon
              >access_time
              </mat-icon
              >
            </ngx-material-timepicker-toggle>
            <!-- <button matSuffix mat-icon-button aria-label="Clear" (click)="dateTimePickerClear('timeIn')">
              <mat-icon class='form-icon'>close</mat-icon>
            </button> -->
            <ngx-material-timepicker
              #editableDialTimeIn
              [theme]="timePickerTheme"
              [enableKeyboardInput]="true"
              (timeSet)="dateTimePickerTimeChanged($event, 'timeIn')"
            >
            </ngx-material-timepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" style="width: 100%">
          <!-- DateTime Out -->
          <mat-form-field fxFlex="45" (click)="picker.open()">
            <mat-label class="font-rajdhani font-semibold">Time Out</mat-label>
            <input
              matInput
              readonly="true"
              [matDatepicker]="picker"
              formControlName="dateOut"
              type="search"
              (dateChange)="dateTimePickerDateChanged('dateOut')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="55">
            <input
              matInput
              [ngxTimepicker]="editableDial"
              formControlName="timeOut"
              [readonly]="true"
            />
            <ngx-material-timepicker-toggle matSuffix [for]="editableDial">
              <mat-icon
                class="form-icon"
                style="line-height: 1.4"
                ngxMaterialTimepickerToggleIcon
              >access_time
              </mat-icon
              >
            </ngx-material-timepicker-toggle>
            <!-- <button matSuffix mat-icon-button aria-label="Clear" (click)="dateTimePickerClear('timeOut')">
              <mat-icon class='form-icon'>close</mat-icon>
            </button> -->
            <ngx-material-timepicker
              #editableDial
              [theme]="timePickerTheme"
              [enableKeyboardInput]="true"
              (timeSet)="dateTimePickerTimeChanged($event, 'timeOut')"
            >
            </ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="box-border w-full flex flex-row gap-1.5 justify-end items-center p-3">
  <button
    mat-dialog-close
    class="flex justify-center items-center appearance-none rounded border-solid border-2 border-white bg-white py-2 px-4 cursor-pointer hover:bg-warn hover:border-warn text-accent hover:text-white font-rajdhani font-semibold uppercase"
  >
    cancel
  </button>
  <button
    type="button"
    class="flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase"
    (click)="onConfirm($event)">
    confirm
  </button>

</div>
