import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appHideTabGroupHeader]',
  standalone: true,
})
export class HideTabGroupHeaderDirective implements OnChanges {
  @Input() appHideTabGroupHeader: boolean = false;

  constructor(private eleRef: ElementRef) {}

  ngOnChanges(): void {
    if (this.appHideTabGroupHeader) {
      this.eleRef.nativeElement.children[0].classList.add('hidden');
    } else {
      this.eleRef.nativeElement.children[0].classList.remove('hidden');
    }
  }
}
