import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DigsitePageComponent } from "./digsite/digsite.component";
import { DigsitePanelComponent } from "./digsite/panels/digsite-panel.component";
import { DigsiteViewComponent } from "./digsite/views/digsite-view.component";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../shared/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GoogleMapsSetModule } from "../shared/google-map/google-maps-set/google-maps-set.component";

export enum digsiteMessages {
  ON_MAP_READY = 1,
  SAVE_SHAPES = 2,
  LOAD_DIGSITES = 3,
  UPDATE_TICKET_DATA = 4,
  GET_DIGSITES = 5,
  FINISHED_DRAWING = 6,
  UPDATE_DRAWING_COMPLETE = 7,
  UPDATE_DRAWING_FAILED = 8,
  UPLOAD_DRAWING_COMPLETE = 9,
  UPLOAD_DRAWING_FAILED = 10,
}

@NgModule({
    declarations: [
        DigsitePageComponent,
        DigsitePanelComponent,
        DigsiteViewComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        BrowserAnimationsModule,
        GoogleMapsSetModule,
    ],
    exports: [DigsitePageComponent, DigsitePanelComponent, DigsiteViewComponent]
})
export class DigsiteModule {}
