import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from "src/app/modules/core/services/logger/notification.service";

@Component({
  selector: "app-ticket-details-sync-dialog",
  templateUrl: "./sync-modal.component.html",
  styleUrls: [],
})
export class TicketSyncModalComponent implements OnInit {
  isFailState: boolean = false;
  uploadStatuses: any;
  downloadStatuses: any;

  constructor(
    public dialogRef: MatDialogRef<TicketSyncModalComponent>,
    private notif$: NotificationService
  ) {
    this.uploadStatuses = {};
    this.downloadStatuses = {};
  }

  ngOnInit() {
    // start listening to upload and download msgs
    this.notif$.getSyncDownload().subscribe((event) => {
      let anyFailStatus = Object.values(event).find(
        (row) => row["status"] == 2
      );
      if (anyFailStatus) {
        this.isFailState = true;
      }

      this.updateDownloadProgress(event);
    });

    this.notif$.getSyncUpload().subscribe((event) => {
      let anyFailStatus = Object.values(event).find(
        (row) => row["status"] == 2
      );
      if (anyFailStatus) {
        this.isFailState = true;
      }
      this.updateUploadProgress(event);
    });
  }

  updateUploadProgress(update) {
    this.uploadStatuses = {
      ...this.uploadStatuses,
      ...update,
    };
  }

  updateDownloadProgress(update) {
    this.downloadStatuses = {
      ...this.downloadStatuses,
      ...update,
    };
  }
}
