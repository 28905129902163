import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ManagedLayer } from '../../classes/managedLayer';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-layer-slot',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CdkAccordionModule, MatIcon],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
  template: `
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="max-w-full"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-controls]="'accordion-body-' + index">
      <div
        class="layer-slot"
        [class.sub-layer]="isSubLayer"
        [class.expanded]="accordionItem.expanded || isHovered"
        (mouseenter)="isHovered = true"
        (mouseleave)="isHovered = false">
        <div class="left">
          <button class="flex flex-row justify-center items-center bg-transparent border-none outline-none w-fit h-fit">
            <mat-icon
              [ngStyle]="{
                color:
                  isVisible && !hiddenByParent
                    ? (accordionItem.expanded || isHovered) && !isSubLayer
                      ? 'white'
                      : '#008AFF'
                    : '#E5E5E5',
                'font-size': '20px',
                'text-align': 'center',
                width: '20px',
                height: '20px'
              }"
              (click)="handleClick()"
              svgIcon="visible" />
          </button>
        </div>

        <button
          class="right"
          [ngClass]="isSelectable || subLayers.length > 0 ? 'cursor-pointer' : ''"
          (click)="layerClick(accordionItem)">
          <span>
            {{ layerProps?.LayerName || '' }}
          </span>
          <div
            *ngIf="isSelectable"
            class="size-4 rounded-full {{
              isSelected ? 'bg-success' : 'bg-warn'
            }} transition-colors ease-in-out duration-300"></div>
          @if (!isSelectable && subLayers.length > 0) {
            @if (accordionItem.expanded) {
              <mat-icon
                [ngStyle]="{
                  color: (accordionItem.expanded || isHovered) && !isSubLayer ? 'white' : '#008AFF'
                }"
                class="arrow-icon">
                keyboard_arrow_down
              </mat-icon>
            } @else {
              <mat-icon
                [ngStyle]="{
                  color: (accordionItem.expanded || isHovered) && !isSubLayer ? 'white' : '#008AFF'
                }"
                class="arrow-icon">
                keyboard_arrow_right
              </mat-icon>
            }
          }
        </button>
      </div>
      @if (subLayers.length > 0) {
        <div
          class="accordion-item-body"
          role="region"
          [@collapse]="accordionItem.expanded"
          [style.overflow-y]="'hidden'"
          [style.overflow-x]="'hidden'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index">
          @for (subLayer of subLayers; track $index) {
            <app-layer-slot
              [layer]="subLayer"
              [isSubLayer]="true"
              [selectedLayer]="selectedLayer"
              [parentVisible]="isVisible"
              (visibilityChanged)="handleChildVisibleChange($event)"
              (layerSelected)="layerSelected.emit($event)"></app-layer-slot>
          }
        </div>
      }
    </cdk-accordion-item>
  `,
  styleUrls: ['./layer-slot.component.scss'],
})
export class LayerSlotComponent implements OnInit, OnChanges, OnDestroy {
  // IO
  @Input() layer: ManagedLayer;
  @Input() selectedLayer: ManagedLayer;
  @Input() parentVisible: IsVisible = true;
  @Input() accordionItem: CdkAccordionItem;
  @Input() index: number;
  @Input() isSubLayer = false;
  @Output() visibilityChanged: EventEmitter<IsVisible> = new EventEmitter<IsVisible>();
  @Output() layerSelected = new EventEmitter<ManagedLayer>();

  // services

  // observables
  private destroy$ = new Subject<void>();
  protected isVisible: IsVisible = true;
  protected myVisibility: IsVisible = true;
  protected isSelected = false;

  layerProps: Record<string, any>;
  subLayers: ManagedLayer[] = [];
  isSelectable = false;
  hiddenByParent = false;
  isHovered = false;
  protected readonly history = history;

  constructor() {}

  ngOnInit(): void {
    this.layerProps = this.layer.getProperties();
    this.subLayers = this.layer.getSubLayers();
    const { isDefaultOn, bIsEditable } = this.layerProps;

    const visVal = this.checkIfVisible();
    this.isVisible = visVal;
    this.layer.visible = visVal;

    this.isSelectable = this.subLayers.length === 0 && bIsEditable === 1;
    this.myVisibility = isDefaultOn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedLayer) {
      this.isSelected = changes.selectedLayer.currentValue === this.layer;
    }
    if (changes.parentVisible) {
      const val = changes.parentVisible.currentValue && this.myVisibility;
      this.isVisible = val;
      this.layer.visible = val;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkIfVisible() {
    let childVisible = false;
    if (this.subLayers.length > 0) {
      this.subLayers.forEach((layer) => {
        if (layer.getProperties().isDefaultOn === 1) {
          childVisible = true;
        }
      });
    }
    return (this.layerProps.isDefaultOn === 1 && this.parentVisible) || childVisible
  }

  handleClick() {
    this.toggleVisibility(!this.isVisible);
  }

  handleChildVisibleChange(evt: boolean) {
    if (evt) {
      this.toggleVisibility(evt);
    }
  }

  toggleVisibility(val: boolean) {
    this.myVisibility = val;
    this.isVisible = val;
    this.layer.visible = val;
    this.visibilityChanged.emit(val);
  }

  layerClick(e: CdkAccordionItem) {
    if (this.isSelectable) {
      this.layerSelected.emit(this.layer);
    } else if (this.subLayers.length > 0) {
      e.toggle();
    }
  }
}

export type IsVisible = boolean;
