import {
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { BaseTemplateFormComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  ActionMessage,
  Actions,
} from "src/app/modules/core/component-messaging/action-message";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { FormInputTemplateComponent } from "src/app/modules/shared/forms/form-input-template/form-input-template.component";
import { CreateTicketComponentService } from "../../../create-ticket-component.service";
import { CreateTicketMessageActions } from "../../../CreateTicketInterface";
import { CreateTicketFormTypes } from "../../../CreateTicketModel";

@Component({
  selector: "app-create-ticket-quick-view",
  templateUrl: "./create-ticket-quick-view.component.html",
  styleUrls: ["./create-ticket-quick-view.component.css"],
  providers: [ComponentMessagingService],
})
export class CreateTicketQuickViewComponent
  extends BaseTemplateFormComponent
  implements OnChanges, OnInit
{
  numAddedFiles = 0;
  documents = {};

  isCreateBtnDisabled: any;

  @Output() createTicket: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("quickView") quickViewContainer: ElementRef;
  @ViewChild("formInput") formInputTemplate: FormInputTemplateComponent;
  constructor(
    logger$: LoggerService,
    compMsg$: ComponentMessagingService,
    private ticket$: CreateTicketComponentService
  ) {
    super(logger$, compMsg$);
  }

  init() {
    this.className = "CreateTicketQuickViewComponent";
    this.categoryLinks = [
      "companyInfo",
      "worksite",
      "details",
      "docs",
      "schedule",
    ];
    this.myViewName = "ticketSummary";
    super.init();
  }

  ngOnInit(): void {
    try {
      if (this.props && this.props["messageService"]) {
        this.parentMsgSubscriber = this.props["messageService"]
          .getMessageStream()
          .subscribe((nextMsg: ComponentMessage) => {
            if (nextMsg.action == CreateTicketMessageActions.DATA_RESET) {
              if (this.quickViewContainer) {
                this.scrollToTop(this.quickViewContainer.nativeElement);
              }
            }
          });
      }
    } catch (error) {
      this.logger$.error(this.className + " : error" + error.message);
    }
  }

  scrollToTop(el) {
    try {
      el.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (
        changes["props"] &&
        changes["props"]["currentValue"] &&
        changes["props"]["currentValue"]["views"] &&
        changes["props"]["currentValue"]["views"][this.myViewName]
      ) {
        this.isCreateBtnDisabled =
          changes["props"]["currentValue"]["onCreateBtnDisabled"];
        this.myView =
          changes["props"]["currentValue"]["views"][this.myViewName];
        this.views = changes["props"]["currentValue"]["views"];
        if (changes["props"]["currentValue"]["documents"]) {
          this.documents = changes["props"]["currentValue"]["documents"];
          if (this.documents["DocumentsLen"]) {
            this.numAddedFiles = this.documents["DocumentsLen"];
          } else {
            this.numAddedFiles = 0;
          }
        }
        this.refresh(); //super function refreshes form
      }
    } catch (error) {
      this.logger$.error(error);
    }
  }

  onSubmitTicket() {
    try {
      this.createTicket.emit();
      this.isCreateBtnDisabled = true;
      setTimeout(() => {
        this.isCreateBtnDisabled = false;
      }, 3000);
    } catch (error) {
      this.logger$.error("onsubmiteTicket: " + error.message);
    }
  }

  //event is groupkey.
  //Pass message to content to switch view to the header selected.
  /**
   *
   * @param event {groupKey, togglePanel}
   */
  onFormHeaderClicked(event) {
    try {
      let message;
      if (event && event.groupKey) {
        message = event;
      } else {
        message = {
          groupKey: event,
          togglePanel: true,
        };
      }
      if (this.props["messageService"]) {
        let msg: ComponentMessage = {
          action: CreateTicketMessageActions.SWITCH_CONTENT_VIEW,
          senderID: this.className,
          message: message,
        };

        this.props["messageService"].sendToMessageStream(msg);
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  test() {
    this.views[this.myViewName]["groups"]["schedule"]["fields"][
      "AvailableRigs"
    ]["selectOptions"] = [
      {
        text: "test1",
        value: "1",
      },
      {
        text: "test2",
        value: "2",
      },
    ];
    this.myView = this.views[this.myViewName];
    this.formProps["view"] = this.myView;
    this.formProps["views"] = this.views;
    this.formProps = { ...this.formProps };
  }

  /**
   *
   * @param event {field, values}
   */
  onDropdownSelected(event) {
    try {
      if (
        event.field &&
        (event.field.key == "ExcavatorName" ||
          event.field.key == "SearchExcavatorName")
      ) {
        let msg: ComponentMessage = {
          action: CreateTicketMessageActions.EXCAVATOR_NAME_UPDATED,
          senderID: this.className,
          message: event,
        };
        this.props["messageService"].sendToMessageStream(msg);
      } else {
        this.ticket$.updateCompletionsData(event);
      }

      // this.ticket$.updateCompletionsData(event);
    } catch (error) {
      this.logger$.error("onDropdownSelected: " + error.message);
    }
  }

  /**
   *
   * @param event google place object
   */
  onAddressSelected(event) {
    console.log(event);

    try {
      if (event && event.place && event.place["address_components"]) {
        let place = event.place;
        let address = place["address_components"];
        let formattedAddress = this.parseAddressFromGoogleAddress(address);
        if (place.geometry && place.geometry.location) {
          formattedAddress["Latitude"] = place.geometry.location.lat();
          formattedAddress["Longitude"] = place.geometry.location.lng();
        }
        if (event.field) {
          this.patchViewValues(formattedAddress, event.field);
        }
        // Latitude: 43.671703184557614
        // LocateAddress: "Yorkville Avenue"
        // LocateSubRegionName: "Toronto"
        // Longitude: -79.39080562778624
        this.ticket$
          .updateTicketData(
            new ActionMessage(Actions.UPDATE_TICKET_ADDRESS_DATA, {
              formType: CreateTicketFormTypes.ticket,
              value: formattedAddress,
            })
          )
          .toPromise();
      }
    } catch (error) {
      this.logger$.error("onAddressSelected: " + error.message);
    }
  }

  /**
   *
   * @param values obj {formgroupKey:val}
   * @param field field in view
   */
  patchViewValues(values, field) {
    try {
      if (values && field && field.options && field.options.patchValues) {
        let patchValues = field.options.patchValues;
        patchValues.forEach((patchValue) => {
          if (
            this.views[patchValue.viewKey] &&
            this.views[patchValue.viewKey]["formGroup"]
          ) {
            this.views[patchValue.viewKey]["formGroup"].patchValue(values);
          }
        });
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
  }

  // parse address data
  private parseAddressFromGoogleAddress(address: any) {
    let addressInfo = {};
    try {
      let streetAddress = "";
      let city = "";
      let startHouseNumber = "";
      // let addressInfoKeys = ["street_number", "route", "locality", "intersection"];
      address.reduce((finalObj, curObj) => {
        if (curObj.types.indexOf("locality") > -1) {
          city = curObj.long_name;
        }
        if (
          curObj.types.indexOf("route") > -1 ||
          curObj.types.indexOf("intersection") > -1
        ) {
          streetAddress = curObj.long_name;
        }
        if (curObj.types.indexOf("street_number") > -1) {
          startHouseNumber = curObj.long_name;
        }
        if (curObj.types.indexOf("political") > -1 && city.length == 0) {
          city = curObj.long_name;
        }
        return finalObj;
      }, []);

      // update input fields
      addressInfo = {
        LocateSubRegionName: city,
        LocateAddress: streetAddress,
        StartHouseNumber: startHouseNumber,
      };
    } catch (error) {
      this.logger$.error("parseAddressFromGoogleAddress: " + error.message);
    }
    return addressInfo;
  }

  /**
   *
   * @param event {field, date}
   */
  onDateSelected(event) {
    try {
      if (event["field"]["key"] && event["date"]) {
        super.onDateSelected(event);
        this.ticket$.onDateSelected(event["field"]["key"], event["date"]);
      }
    } catch (error) {
      this.logger$.error("onDropdownSelected: " + error.message);
    }
  }

  private onReceiveParentMessage(nextMsg: ComponentMessage) {}

  private onReceiveChildMessage(nextMsg: ComponentMessage) {}
}
