import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../shared/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { RouterModule } from "@angular/router";
import { RateListComponent } from "./rate-list/rate-list.component";
import { RateComponent } from "./rate/rate.component";
import { RateNodeComponent } from "./rate-node/rate-node.component";
import { RateNodeEditorComponent } from "./rate-node-editor/rate-node-editor.component";
import { ProgressBarModule } from "../shared/progress-bar/progress-bar.module";
import { SnackbarModule } from "../shared/snackbar/snackbar.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseModalModule } from "../shared/modals/base-modal.module";

@NgModule({
  declarations: [
    RateListComponent,
    RateComponent,
    RateNodeComponent,
    RateNodeEditorComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    PageNavigationModule,
    ProgressBarModule,
    SnackbarModule,
    BaseModalModule,
    FormsModule,
  ],
})
export class RatesModule {}
