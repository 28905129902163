import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { UtilocateTokenPaths } from '../../core/services/token/token.service';
import { UserService } from '../../core/services/user/user.service';
import { SchedulerTicketDialogComponent } from '../../scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component';
import { MaterialModule } from '../../shared/material.module';
import { ProgressBarService } from '../../shared/progress-bar/progress-bar.service';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { TableModule, TemplateColumn } from '../../shared/table/table.module';
import { SystemMaintenanceService } from '../services/system-maintenance.service';
import { TableService } from '../../shared/table/table.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CompetersSearchBarComponent } from '../../../shared/components/inputs/competers-search-bar/competers-search-bar.component';
import { TabulatorTableComponent } from "../../shared/table/tabulator-table/tabulator-table.component";

@Component({
  selector: 'app-failed-third-party-jobs',
  templateUrl: 'failed-third-party-jobs.component.html',
  styleUrls: ['failed-third-party-jobs.component.scss'],
  providers: [TableService],
})
export class ThirdPartyJobsComponent implements OnInit {
  // table config
  selectionType: SelectionType = SelectionType.multi;
  searchText: string = '';
  jobs: any[] = [];
  displayedColumns: TemplateColumn[] = [];
  selected: any[] = [];

  loadedData: boolean = false;
  failedToLoadData: boolean = false;

  clientID: string;
  userID: string;
  constructor(
    private systemMaintenance$: SystemMaintenanceService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    public dialog: MatDialog,
    private userService: UserService,
    public tableService: TableService<any>
  ) {  }

  async ngOnInit() {
    this.clientID = await this.userService.getUserValueFromToken(UtilocateTokenPaths.CLIENTID);
    this.userID = await this.userService.getUserValueFromToken(UtilocateTokenPaths.USERID);
    this.refreshView();
  }

  async refreshView() {
    this.progressBarService.start();
    const result = await this.systemMaintenance$.getFailedThirdPartyJobs().toPromise();

    if (result.body.Result) {
      this.jobs = result.body.Result.Data;
      this.formatColumns(result.body.Result.Columns);
      this.loadedData = true;
    }

    if (result.body.FailMessage) {
      this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      this.failedToLoadData = true;
    }

    this.progressBarService.stop();
  }

  formatColumns(columns: string[]) {
    this.displayedColumns = columns
      .filter((col) => {
        if (col !== 'AssignmentID' && col !== 'AWSJobQueueID') {
          return true;
        } else {
          return false;
        }
      })
      .map((col, index) => {
        return {
          TemplateColumnID: 0,
          ColumnOrder: index,
          TemplateID: 0,
          Field: 0,
          Visible: 1,
          Width: 200,
          Title: col,
        } as TemplateColumn;
      });
  }

  openTicket(row) {
    const ticketData = {
      AssignmentID: row.AssignmentID,
      isModal: true,
    };

    const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
      width: window.innerWidth.toString() + 'px',
      maxWidth: '100%',
      height: window.innerHeight.toString() + 'px', // if small screen make full screen
      panelClass: 'margin-dialog-container',
      data: {
        ticketData,
      },
    });
  }

  async onRetry() {
    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.retryThirdPartyJobs(this.getSelectedIDs(this.selected)).toPromise();

      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Retried Jobs', SnackbarType.success);
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Retry Jobs', SnackbarType.error);
      }
      this.refreshView();
    }
  }

  async onAbondon() {
    console.log(this.selected);

    if (this.selected.length > 0) {
      const result = await this.systemMaintenance$.abandonThirdPartyJobs(this.selected).toPromise();
      console.log(result);

      if (result.body.Success) {
        this.snackBarService.openSnackbar('Successfully Abandoned Jobs', SnackbarType.success);
      } else if (result.body.FailMessage) {
        this.snackBarService.openSnackbar(result.body.FailMessage, SnackbarType.error);
      } else {
        this.snackBarService.openSnackbar('Failed to Abandon Jobs', SnackbarType.error);
      }
      this.refreshView();
    }
  }

  getSelectedIDs(selected: any[]): number[] {
    return selected.map((i) => {
      return i.AWSJobQueueID;
    });
  }

  async onSyncCustomers() {
    console.log('Sync Customers');
    if (this.clientID === '88') {
      this.progressBarService.start();
      const result = await this.systemMaintenance$.gatherCustomers(this.clientID, this.userID);
      if (result && result.body && result.body.body.Payload) {
        const lambdaResponse = JSON.parse(JSON.parse(result.body.body.Payload).body);
        if (lambdaResponse.Success) {
          this.snackBarService.openSnackbar(
            lambdaResponse.Result.CustomersAdded + ' Customer(s) Synced',
            SnackbarType.success
          );
        } else if (lambdaResponse.FailMessage) {
          this.snackBarService.openSnackbar(lambdaResponse.FailMessage, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Failed to Sync Customers', SnackbarType.error);
        }
      } else {
        this.snackBarService.openSnackbar('Failed to Sync Customers', SnackbarType.error);
      }
      this.progressBarService.stop();
    }
  }
  rowSelectionChanged(e) {
    console.log(e);
    this.selected = e;
  }
  templateChanged(e) {}

  onRowClicked(e) {
    this.openTicket(e);
  }
}

@NgModule({
    declarations: [ThirdPartyJobsComponent],
    exports: [ThirdPartyJobsComponent],
    imports: [CommonModule, MaterialModule, FlexLayoutModule, TableModule, CompetersSearchBarComponent, TabulatorTableComponent]
})
export class ThirdPartyJobsModule {}
