import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ContainerTabComponent } from "./container-tab/container-tab.component";
import { NgForOf } from "@angular/common";

@Component({
  selector: "app-tabbed-container",
  templateUrl: "./tabbed-container.component.html",
  styleUrls: ["./tabbed-container.component.scss"],
  standalone: true,
  imports: [NgForOf],
})
export class TabbedContainerComponent implements AfterViewInit {
  // input the contents of the as ngContent with the tab name as the attribute
  @Input() tabs: { title: string; index: number }[] = [];
  animationState: "void" | "enter" | "leave" = "enter";
  @ViewChild("tabContent") private tabContent: ElementRef<HTMLElement>;
  @ViewChildren("ref") private tabSelectors: QueryList<ElementRef<HTMLElement>>;
  @ContentChildren(ContainerTabComponent)
  tabElements: QueryList<ContainerTabComponent>;
  @Output() animationStateChanged: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  ngAfterViewInit() {
    this.changeTab(0);
  }

  changeTab(tabNum: number) {
    this.tabElements.forEach((node) => {
      if (tabNum !== node.getIndex()) {
        node.hide();
      } else {
        node.show();
      }
    });
    this.tabSelectors.forEach((tab) => {
      const element = tab.nativeElement;
      if (parseInt(element.id.slice(-1), 10) !== tabNum) {
        element.classList.remove("selected-tab");
      } else if (!element.className.includes("selected-tab")) {
        element.classList.add("selected-tab");
      }
    });
  }

  // container-tab-{{tab.id}}

  onTabClick(e: HTMLElement) {
    const targetTab = parseInt(e.id[e.id.length - 1], 10);
    this.changeTab(targetTab);
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = "leave";
  }
}
