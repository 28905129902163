import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { TicketMapComponent } from '../../../shared/components/maps/open-layers/ticket-map/ticket-map.component';
import { Observable, Subject } from 'rxjs';
import { TicketSearchService } from '../../../shared/services/ticket-search/ticket-search.service';
import { HomeWorkspaceService } from '../home-workspace.service';
import { DispatchAreaService } from '../../../shared/services/dispatch-area/dispatch-area.service';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { RouteUserEditorService } from 'src/app/shared/components/maps/open-layers/route-user-editor/route-user-editor.service';

@Component({
  selector: 'app-ticket-list-map',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TicketMapComponent, AsyncPipe],
  templateUrl: './ticket-list-map.component.html',
  styleUrls: ['./ticket-list-map.component.scss'],
})
export class TicketListMapComponent implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();
  protected _showRouting$: Observable<boolean>;

  // services
  protected homeWorkspaceService = inject(HomeWorkspaceService);
  protected dispatchAreaService = inject(DispatchAreaService);
  protected searchService = inject(TicketSearchService);
  protected routeEditorService = inject(RouteUserEditorService);

  constructor() {}

  ngOnInit() {
    //subscribe to the route order (for routing)
    this.routeEditorService.currentRoute$.subscribe((routeOrder) => {
      // Create a Set of routeOrder AssignmentIDs for quick lookup
      const routeOrderSet = new Set(routeOrder?.map((id) => parseInt(id)));

      //for each ticket in the stream
      this.searchService.searchResults$.pipe(takeUntil(this.destroy$)).subscribe((tickets) => {
        // Iterate over each ticket in the ticket stream
        tickets.forEach((ticket) => {
          const currentAssignmentID = ticket.AssignmentID;
          // Check if the ticket has a matching routeOrder
          if (routeOrderSet.has(currentAssignmentID)) {
            // If the ticket has a matching routeOrder, set its RouteOrder
            ticket.RouteOrder = routeOrder.indexOf(currentAssignmentID.toString());
          } else {
            // If there's no matching routeOrder, set RouteOrder to null
            ticket.RouteOrder = null;
          }
        });
      });
    });

    this._showRouting$ = this.routeEditorService.showRouting$;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
