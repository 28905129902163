import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "../../api/baseapi.service";

@Injectable({
  providedIn: "root",
})
export class UploadAutologService {
  IS_LIVE: boolean;
  isLocalHost: boolean;

  constructor(private utilocateApiService: ApiService) {
    this.IS_LIVE = environment.production == true;
    this.isLocalHost = environment.localhost;
  }

  async uploadAutolog(
    clientID: number,
    assignmentID: number | string,
    userID: number,
    descID: number,
    explanation: string
  ) {
    let autologUploaded = false;
    try {
      let url = "/api/upload/autolog"; // localhost

      if (!this.isLocalHost) {
        url = "/nodejs/api/upload/autolog";
      }
      let result = await this.utilocateApiService.invokeApi("PUT", url, {
        ClientID: clientID,
        isLive: this.IS_LIVE,
        AssignmentID: assignmentID,
        UserID: userID,
        DescID: descID,
        Explanation: explanation,
      });

      if (result && result.body && result.body.uploaded) {
        autologUploaded = true;
      }
    } catch (error) {
      console.log(error);
    }
    return autologUploaded;
  }
}
