<h2 mat-dialog-title>Units</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Select Unit</mat-label>
    <mat-select [(ngModel)]="selectedUnit" (selectionChange)="onUnitSelected()">
      <mat-option
        *ngFor="
          let unit of data.units ? unitOptionsMetric : unitOptionsImperial
        "
        [value]="unit"
        >{{ unit }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</button>
</div>
