// https://coryrylan.com/blog/creating-a-custom-debounce-click-directive-in-angular

// Example
// <button
//          appClickThrottle
//         [throttleTime]="5000" -- in milliseconds
//         (throttleClick)="func()"
//     >
//     Throttled button
// </button>

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { throttleTime } from "rxjs/operators";

@Directive({
  selector: "[appClickThrottle]",
  standalone: true,
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() throttleTime = 500;
  @Input() allowPropagation = false;
  @Output() throttleClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      .subscribe((e) => this.throttleClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("click", ["$event"])
  clickEvent(event) {
    event.preventDefault();
    if (!this.allowPropagation) {
      event.stopPropagation();
    }
    this.clicks.next(event);
  }
}
