import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CompetersInputComponent } from '../../../shared/components/inputs/competers-input/competers-input.component';
import { SearchableDropdownComponent } from '../../../shared/components/inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersCheckboxComponent } from '../../../shared/components/inputs/competers-checkbox/competers-checkbox.component';
import { CompetersDateRangePickerComponent } from '../../../shared/components/inputs/competers-date-range-picker/competers-date-range-picker.component';
import { MatDialogClose } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CompetersDatePickerComponent } from '../../../shared/components/inputs/competers-date-picker/competers-date-picker.component';
import { JsonFormControl } from '~lib/types/jsonForm';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { isJsonFormControl, isJsonFormGroup, JsonFormGroup, JsonFormRecursive } from '~lib/types/jsonFormRecursive';
import { JsonFormGroupComponent } from './json-form-group/json-form-group.component';
import { AppScrollerComponent } from '../../../shared/components/misc/scroller/scroller.component';

@Component({
  selector: 'app-drawing-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CompetersInputComponent,
    SearchableDropdownComponent,
    CompetersCheckboxComponent,
    CompetersDateRangePickerComponent,
    AsyncPipe,
    MatIcon,
    MatDialogClose,
    CompetersDatePickerComponent,
    JsonFormGroupComponent,
    AppScrollerComponent,
  ],
  template: `
    <div class="box-border pointer-events-auto size-fit">
      <app-scroller [height]="height + 'px'">
        <div [formGroup]="myForm">
          <app-json-form-group
            [controlGroup]="myForm"
            [group]="form.controls"
            [title]="form.name"></app-json-form-group>
        </div>
      </app-scroller>
    </div>
  `,
})
export class DrawingFormComponent implements OnInit, OnChanges, OnDestroy {
  // IO
  @Input() form: JsonFormRecursive;
  @Input() formValue: Record<string, string | number>;
  @Input() height: number;
  @Output() formValueChanged = new EventEmitter<Record<string, string | number>>();

  // Services
  private formBuilder = inject(FormBuilder);

  //members
  public myForm: FormGroup = this.formBuilder.group({});

  // observables
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.myForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => this.formValueChanged.emit(val));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && ![null, undefined].includes(changes.form.currentValue)) {
      this.myForm = this.createForm(this.form);
    }
    if (changes.formValue && !isEqual(changes.formValue.previousValue, changes.formValue.currentValue)) {
      if (this.formValue === null) {
        this.myForm.reset(undefined, {
          emitEvent: false,
        });
      } else {
        Object.keys(this.myForm.getRawValue()).forEach((key) => {
          this.myForm.patchValue(
            {
              [key]: this.formValue[key] ?? null,
            },
            {
              emitEvent: false,
            }
          );
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createForm(form: JsonFormRecursive) {
    const group = this.formBuilder.group({});
    form.controls.forEach((control) => {
      if (isJsonFormGroup(control)) {
        group.addControl(control.name, this.createFormGroup(control));
      }
      if (isJsonFormControl(control)) {
        group.addControl(control.name, this.createFormControl(control));
      }
    });
    return group;
  }

  createFormControl(control: JsonFormControl) {
    const opts = {
      value: null,
      disabled: false,
    };
    if (![null, undefined].includes(this.formValue)) {
      opts.value = this.formValue[control.name] ?? null;
    }
    return this.formBuilder.control(opts, this.addValidators(control));
  }

  createFormGroup(formGroup: JsonFormGroup) {
    const group = this.formBuilder.group({});
    formGroup.controls.forEach((control) => {
      if (isJsonFormGroup(control)) {
        group.addControl(control.name, this.createFormGroup(control));
      }
      if (isJsonFormControl(control)) {
        group.addControl(control.name, this.createFormControl(control));
      }
    });
    return group;
  }

  addValidators(control: JsonFormControl) {
    const validatorsToAdd = [];
    for (const [key, value] of Object.entries(control.validations)) {
      switch (key) {
        case 'min':
        case 'max':
          if (typeof value === 'number') {
            validatorsToAdd.push(key === 'min' ? Validators.min(value) : Validators.max(value));
          }
          break;
        case 'required':
          if (value) {
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'requiredTrue':
          if (value) {
            validatorsToAdd.push(Validators.requiredTrue);
          }
          break;
        case 'email':
          if (value) {
            validatorsToAdd.push(Validators.email);
          }
          break;
        case 'minLength':
        case 'maxLength':
          if (typeof value === 'number') {
            validatorsToAdd.push(key === 'minLength' ? Validators.minLength(value) : Validators.maxLength(value));
          }
          break;
        case 'pattern':
          if (typeof value === 'string') {
            validatorsToAdd.push(Validators.pattern(value));
          }
          break;
        case 'nullValidator':
          if (value) {
            validatorsToAdd.push(Validators.nullValidator);
          }
          break;
        default:
          break;
      }
    }
    return validatorsToAdd;
  }
}
