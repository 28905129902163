import { inject, Pipe, PipeTransform } from '@angular/core';
import { DatetimeService } from '../../modules/core/services/datetime/datetime.service';

@Pipe({
  name: 'popupDate',
  standalone: true,
})
export class PopupDatePipe implements PipeTransform {
  dateTime = inject(DatetimeService);

  transform(value: string): string {
    return this.dateTime.format(value.split('T')[0], { month: 'long', day: 'numeric' });
  }
}
