/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressBarService } from '../../../progress-bar/progress-bar.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-autolog-explanation-modal',
  standalone: true,
  templateUrl: 'autolog-explanation-modal.component.html',
  imports: [ReactiveFormsModule, MatTooltip],
})
export class AutologExplanationModalComponent implements OnInit {
  data: any;
  enableClose: boolean = true;
  explanation: FormControl;
  showRequiredError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public Data: any,
    private progressBarService: ProgressBarService,
    public dialogRef: MatDialogRef<AutologExplanationModalComponent>
  ) {
    this.data = Data;
  }

  ngOnInit(): void {
    this.explanation = new FormControl(this.data.value ?? '', [Validators.required]);
  }

  onConfirm(event) {
    if (this.explanation.valid) {
      this.showRequiredError = false;
      this.dialogRef.close({
        event: 'close',
        result: this.explanation.value,
      });
    } else {
      this.showRequiredError = true;
      this.explanation.markAsTouched();
    }
  }

  onClose(event) {
    this.progressBarService.stop();
    this.dialogRef.close();
  }

  textEntered() {
    this.enableClose = false;
  }
}
