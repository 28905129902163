import { Component, Inject, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CompetersInputComponent } from '../../inputs/competers-input/competers-input.component';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersCheckboxComponent } from '../../inputs/competers-checkbox/competers-checkbox.component';
import { CompetersDateRangePickerComponent } from '../../inputs/competers-date-range-picker/competers-date-range-picker.component';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CompetersDatePickerComponent } from '../../inputs/competers-date-picker/competers-date-picker.component';
import { JsonForm, JsonFormControl } from '~lib/types/jsonForm';

@Component({
  selector: 'app-json-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CompetersInputComponent,
    SearchableDropdownComponent,
    CompetersCheckboxComponent,
    CompetersDateRangePickerComponent,
    AsyncPipe,
    MatIcon,
    MatDialogClose,
    CompetersDatePickerComponent,
    CommonModule
  ],
  template: `
    <div class="relative size-fit p-5">
      <h2 class="text-headline-6 font-rajdhani font-semibold uppercase">{{ jsonFormData.name }}</h2>
      <div [formGroup]="myForm" class="relative flex flex-col gap-6 justify-center items-center">
        @for (control of jsonFormData?.controls; track $index) {
          <div class="flex flex-row gap-3 w-96 justify-start items-center">
            @if (['text', 'number', 'password', 'email', 'search', 'tel', 'url'].includes(control.type)) {
              <div class="flex flex-col w-full gap-3 justify-between">
                <label for="{{ control.name }}">{{ control.label }}</label>
                <div class="w-full h-8">
                  <app-competers-input
                    [fitParentContainer]="true"
                    [formControl]="getControl(control.name)"
                    [type]="control.type"></app-competers-input>
                </div>
                <span class="font-rajdhani text-warn" *ngIf="!myForm.valid && !myForm.pristine">
                    Reason <strong>required</strong>
                  </span>
              </div>
            } @else if (control.type === 'textarea') {
              <div class="flex flex-col w-full gap-3 justify-between">
                <label for="{{ control.name }}">{{ control.label }}</label>
                <div class="w-full h-8">
                  <textarea [formControl]="getControl(control.name)"></textarea>
                </div>
              </div>
            } @else if (control.type === 'checkbox') {
              <div class="flex flex-col w-full gap-3 justify-between">
                <label for="{{ control.name }}">{{ control.label }}</label>
                <div class="w-full h-8">
                  <app-competers-checkbox [checkStyle]="'checkmark'" [formControl]="getControl(control.name)" />
                </div>
              </div>
            } @else if (control.type === 'select') {
              <div class="relative flex flex-col w-full gap-3 justify-between">
                <label for="{{ control.name }}">{{ control.label }}</label>
                <div class="relative w-full h-8">
                  <app-searchable-dropdown
                    class="w-full"
                    [fitParentContainer]="true"
                    [multiple]="!!control.options['multiple']"
                    [formControl]="getControl(control.name)"
                    [options]="control.dataSource ?? []"></app-searchable-dropdown>
                </div>
              </div>
            } @else if (control.type === 'date') {
              <div class="flex flex-col w-full gap-3 justify-between">
                <label for="{{ control.name }}">{{ control.label }}</label>
                <div class="w-full h-8">
                  <app-competers-date-picker
                    [title]="control.label"
                    [formControl]="getControl(control.name)"></app-competers-date-picker>
                </div>
              </div>
            }
          </div>
        }
        
        <div class="w-full flex justify-end items-center">
          <button
            [mat-dialog-close]="false"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0
            cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold
            uppercase">
            cancel
          </button>
          <button
            (click)="submitForm()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
            confirm
          </button>
        </div>
      </div>
    </div>
  `,
})
export class JsonFormComponent implements OnInit, OnChanges {
  // IO
  @Input() jsonFormData: JsonForm;

  // Services
  private formBuilder = inject(FormBuilder);

  //members
  public myForm: FormGroup = this.formBuilder.group({});

  constructor(
    public dialogRef: MatDialogRef<JsonFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: JsonForm
  ) {}

  ngOnInit() {
    if (!this.jsonFormData && this.dialogData) {
      this.jsonFormData = this.dialogData;
      this.createForm(this.dialogData.controls);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.jsonFormData.firstChange) {
      this.createForm(this.jsonFormData.controls);
    }
  }

  createForm(controls: Array<JsonFormControl>) {
    controls.forEach((control) => {
      const validatorsToAdd = [];
      
      for (const [key, value] of Object.entries(control.validations)) {        
        switch (key) {
          case 'min':
          case 'max':
            if (typeof value === 'number') {
              validatorsToAdd.push(key === 'min' ? Validators.min(value) : Validators.max(value));
            }
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }
            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }
            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }
            break;
          case 'minLength':
          case 'maxLength':
            if (typeof value === 'number') {
              validatorsToAdd.push(key === 'minLength' ? Validators.minLength(1) : Validators.maxLength(1));
            }
            break;
          case 'pattern':
            if (typeof value === 'string') {
              validatorsToAdd.push(Validators.pattern(value));
            }
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }
            break;
          default:
            break;
        }
      }
      
      this.myForm.addControl(control.name, this.formBuilder.control(null, validatorsToAdd));
    });
  }

  getControl(name: string): FormControl {
    return this.myForm['controls'][name] as FormControl;
  }

  submitForm() {    
    if (this.myForm.valid) {
      if (this.dialogRef) {
        this.dialogRef.close(this.myForm.value);
      }
    }
  }
}
