<div class="quick-search-container flex flex-grow-0 flex-shrink-1 basis-[874px]" id="quick-search-root">
  @if (_initialized$ | async) {
    <button
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      class="quick-search-bar flex flex-row justify-between items-center"
      (click)="openSearchForm()">
      <div class="flex-left" style="color: #9d9d9d">
        <mat-icon style="margin-right: 24px">search</mat-icon>
        <p style="letter-spacing: 0; text-transform: none; font: normal normal normal 20px/24px Montserrat">Search</p>
      </div>
      <mat-icon style="color: #9d9d9d">filter_alt</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPanelClass]="'quick-search-panel'"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayWidth]="searchBarElementWidth"
      (backdropClick)="handleBackdropClick()">
      <form
        class="flex flex-row box-border w-full h-full min-h-full min-w-full overflow-auto"
        [formGroup]="quickSearchForm">
        <div class="quick-search-menu flex-grow-1 flex-shrink-1 basis-[100%] overflow-auto">
          <div class="quick-search-bar flex flex-row justify-between items-center" style="color: #000000">
            <div class="flex-left">
              <mat-icon style="margin-right: 24px">search</mat-icon>
              <p style="letter-spacing: 0; text-transform: none; font: normal normal normal 20px/24px Montserrat">
                Search
              </p>
            </div>
            <mat-icon style="margin-left: 24px">filter_alt</mat-icon>
          </div>
          <div
            class="search-menu-grids flex flex-col gap-4 lg:flex-row lg:gap-10 overflow-auto"
            style="overflow: auto; max-height: calc(100vh - 150px)">
            <div class="flex flex-col flex-grow-1 flex-shrink-1 basis-[55%]">
              <span class="mat-subtitle-1 text-primary">Quick Search</span>
              <div class="flex flex-col justify-start items-start gap-3 w-full sm:w-[433px] pb-3 overflow-auto">
                @for (field of searchFields; track $index) {
                  <!-- Text Input -->
                  @if (field.type === 'text' || field.type === 'number' || field.type === 'numbers') {
                    <app-competers-input
                      class="w-full"
                      [title]="field.title"
                      [type]="field.type"
                      formControlName="{{ field.name }}" />
                  } @else if (field.type === 'select') {
                    <app-searchable-dropdown
                      class="w-full"
                      [title]="field.title"
                      [inlined]="true"
                      [multiple]="true"
                      [formControlName]="field.name"
                      [options]="field.choices"></app-searchable-dropdown>
                  }
                }
              </div>
            </div>
            <div
              class="flex flex-col justify-between lg:items-center flex-grow-1 flex-shrink-1 md:gap-[80px] md:basis-[45%] gap-0 overflow-auto">
              <div class="flex flex-col flex-grow justify-start items-start">
                <div class="flex flex-row gap-3 mb-4" style="width: 100%">
                  <span class="w-1/2 mat-subtitle-1 text-primary m-0 p-0">outstanding</span>
                  <div class="flex justify-start flex-grow items-center">
                    <button
                      (click)="toggleOutstandingSearch()"
                      class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                      @if (searchOutstanding) {
                        <mat-icon class="text-success" style="width: 13px; height:13px" svgIcon="yes-filled" />
                      } @else {
                        <mat-icon svgIcon="toggle_off" style="width: 13px; height:13px" />
                      }
                    </button>
                  </div>
                </div>
                <div class="flex flex-col" style="width: 100%">
                  <span class="mat-subtitle-1 text-primary">Dates</span>
                  @for (field of dateFields; track $index) {
                    <app-competers-date-range-picker [title]="field.title" [formControlName]="field.name" />
                  }
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-border flex flex-row justify-between items-center w-full pb-[10px] lg:pb-[30px] px-[12px] sm:px-[25px]">
            <div class="flex flex-row justify-center items-center">
              <button
                (click)="handleAdvancedSearchClick($event)"
                class="flex flex-row gap-[10px] justify-start items-center appearance-none box-border border-solid border-1 border-gray-400 rounded h-[35px] w-[142px] sm:w-[164px] bg-white cursor-pointer">
                <mat-icon style="width: 24px; height: 24px" class="ml-3" svgIcon="advanced_search"></mat-icon>
                <span class="whitespace-nowrap font-rajdhani font-semibold text-md uppercase text-primary">
                adv. search
              </span>
              </button>
            </div>
            <div class="flex flex-row gap-3 sm:gap-6 flex-grow justify-end items-center">
              <button
                (click)="resetForm($event)"
                class="flex justify-center items-center sm:w-[132px] w-[112px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase">
                clear
              </button>
              <button
                (click)="submitSearch($event)"
                class="flex justify-center items-center sm:w-[132px] w-[112px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
                search
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  }
</div>
