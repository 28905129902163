<ng-template [ngIf]="isLoaded" [ngIfElse]="loading">
  <!-- <div fxLayout="column" fxLayoutAlign="space-between stretch"> -->
  <ng-container *ngFor="let category of categories">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4>{{ category.placeholder }}</h4>
      <ng-container *ngIf="category.canEdit && !isReadOnly">
        <mat-icon (click)="onEdit(category)">edit</mat-icon>
      </ng-container>
    </div>

    <form
      [formGroup]="infoFormGroup"
      class="form-container"
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px grid"
    >
      <ng-template [ngIf]="!category.isDropdown" [ngIfElse]="dropdown">
        <ng-container *ngFor="let input of category.options">
          <mat-form-field
            fxFlex="50"
            *ngIf="
              input.isVisible &&
              (input.type == ticketDetailInputType.Integer ||
                input.type == ticketDetailInputType.IntegerHS)
            "
            [appearance]="isReadOnly ? 'fill' : 'outline'"
          >
            <mat-label>{{ input.label }}</mat-label>
            <input
              id="{{ category.dbName }}.{{ input.key }}"
              matInput
              placeholder="0"
              required="{{ input.isRequired }}"
              formControlName="{{ category.dbName }}.{{ input.key }}"
              type="number"
              step="any"
            />
            <mat-hint>Must be an integer</mat-hint>
          </mat-form-field>

          <mat-form-field
            fxFlex="50"
            *ngIf="
              input.isVisible &&
              (input.type == ticketDetailInputType.Float ||
                input.type == ticketDetailInputType.FloatHS)
            "
            [appearance]="isReadOnly ? 'fill' : 'outline'"
          >
            <mat-label>{{ input.label }}</mat-label>
            <input
              id="{{ category.dbName }}.{{ input.key }}"
              matInput
              placeholder="0"
              required="{{ input.isRequired }}"
              formControlName="{{ category.dbName }}.{{ input.key }}"
              type="number"
              step="any"
            />
          </mat-form-field>

          <mat-form-field
            fxFlex="50"
            *ngIf="
              input.isVisible &&
              (input.type == ticketDetailInputType.String ||
                input.type == ticketDetailInputType.StringHS)
            "
            [appearance]="isReadOnly ? 'fill' : 'outline'"
          >
            <mat-label>{{ input.label }}</mat-label>
            <input
              id="{{ category.dbName }}.{{ input.key }}"
              matInput
              placeholder="{{ input.placeholder }}"
              required="{{ input.isRequired }}"
              formControlName="{{ category.dbName }}.{{ input.key }}"
              type="text"
            />
          </mat-form-field>

          <mat-checkbox
            fxFlex="50"
            *ngIf="
              input.isVisible &&
              (input.type == ticketDetailInputType.Checkbox ||
                input.type == ticketDetailInputType.CheckboxHS)
            "
            id="{{ category.dbName }}.{{ input.key }}"
            formControlName="{{ category.dbName }}.{{ input.key }}"
            >{{ input.label }}</mat-checkbox
          >

          <mat-form-field
            fxFlex="50"
            *ngIf="
              input.isVisible && input.type == ticketDetailInputType.Datepicker
            "
            [appearance]="isReadOnly ? 'fill' : 'outline'"
          >
            <mat-label>{{ input.label }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              id="{{ category.dbName }}.{{ input.key }}"
              formControlName="{{ category.dbName }}.{{ input.key }}"
              required="{{ input.isRequired }}"
              placeholder="{{ input.placeholder }}"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
      </ng-template>

      <ng-template #dropdown>
        <mat-form-field fxFlex="100" 
        [appearance]="isReadOnly ? 'fill' : 'outline'">
          <mat-label>{{ category.placeholder }}</mat-label>
          <mat-select
            placeholder="{{ category.placeholder }}"
            formControlName="{{ category.dbName }}"
            required="{{ category.isRequired }}"
            matTooltip="{{ category.placeholder }}"
            matTooltipPosition="above"
            multiple
          >
            <mat-option
              *ngFor="let input of category.options"
              [value]="input['key']"
              matTooltip="{{ input.label }}"
              matTooltipPosition="right"
              >{{ input.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </form>
  </ng-container>
  <!-- </div> -->
</ng-template>

<ng-template #loading>
  <!-- <h4>Loading...</h4> -->
  <!-- <mat-spinner [diameter]="30" color="accent"></mat-spinner> -->
</ng-template>
