import { Injectable } from "@angular/core";
import { Observable, from, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

import { localStorageKeys } from "src/app/LOCAL_STORAGE";
import { CompletionsStoreService } from "src/app/modules/core/admin/completions-store.service";
import { ActionMessage } from "src/app/modules/core/component-messaging/action-message";
import { zipFile } from "src/app/modules/core/services/document/zip-helper";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class UploadDocumentService {
  curUID: string;
  constructor(
    private logger$: LoggerService,
    private completions$: CompletionsStoreService
  ) {}

  getOnDocumentDataChange(): Observable<any> {
    return this.completions$.getOnDocumentDataChange();
  }

  prepareDocumentsForUpload(selectedFiles): Observable<any> {
    return new Observable((subscriber) => {
      try {
        if (selectedFiles && selectedFiles.length > 0) {
          // prepare files to be zipped
          const filesToZip = [];
          selectedFiles.forEach((file) => {
            filesToZip.push(this.prepareDocumentForUpload(file));
          });

          // perform parallel zip and completed
          forkJoin(filesToZip).subscribe((zippedFiles) => {
            subscriber.next(zippedFiles);
            subscriber.complete();
          });
        } else {
          subscriber.next(false);
          subscriber.complete();
        }
      } catch (error) {
        this.logger$.error(
          "UploadDocuemntsService: prepareDocumentsForUpload: function failed ",
          error
        );
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private prepareDocumentForUpload(document): Observable<any> {
    return new Observable((subscriber) => {
      from(zipFile(document["name"], document["file"]))
        .pipe(
          map((zippedFile) => {
            // location.href = "data:application/zip;base64," + zippedFile;
            let docTypeID = 6; //OLD Document
            if (document["docTypeID"]) {
              docTypeID = document["docTypeID"];
            }
            return {
              name: document["name"],
              file: zippedFile,
              uploaded: false,
              docTypeID: docTypeID,
            };
          })
        )
        .subscribe((zippedObj) => {
          subscriber.next(zippedObj);
          subscriber.complete();
        });
    });
  }

  updateDocumentData(message: ActionMessage): Observable<any> {
    this.curUID = sessionStorage.getItem(
      localStorageKeys.CURRENT_CREATE_TICKET_KEY
    );
    return this.completions$.updateDocumentData(
      new ActionMessage(message.action, {
        rowKey: this.curUID,
        ...message.data,
      })
    );
  }
}
