import { Component } from '@angular/core';
import { CompetersDateRangePickerComponent } from '../components/inputs/competers-date-range-picker/competers-date-range-picker.component';
import { AdvancedTicketSearchComponent } from '../components/misc/advanced-ticket-search/advanced-ticket-search.component';
import { SearchableDropdownComponent } from '../components/inputs/searchable-dropdown/searchable-dropdown.component';
import { CompetersInputComponent } from '../components/inputs/competers-input/competers-input.component';
import { CompetersSearchBarComponent } from '../components/inputs/competers-search-bar/competers-search-bar.component';
import { VerticalSplitComponent } from '../components/layouts/vertical-split/vertical-split.component';
import { CompetersCheckboxComponent } from '../components/inputs/competers-checkbox/competers-checkbox.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DrawingToolbarComponent } from '../../modules/drawing-module/drawing-toolbar/drawing-toolbar.component';
import { DrawingMapComponent } from '../../modules/drawing-module/drawing-map/drawing-map.component';
import { DrawingComponent } from '../../modules/drawing-module/drawing/drawing.component';

// TODO: git rid of this for production
@Component({
  selector: 'app-demo',
  standalone: true,
  imports: [
    CompetersDateRangePickerComponent,
    AdvancedTicketSearchComponent,
    SearchableDropdownComponent,
    CompetersInputComponent,
    CompetersSearchBarComponent,
    VerticalSplitComponent,
    CompetersCheckboxComponent,
    ReactiveFormsModule,
    DrawingToolbarComponent,
    DrawingMapComponent,
    DrawingComponent,
  ],
  template: `
    <div class="flex justify-center items-center h-full w-full bg-fuchsia-400">
      <app-drawing
        [formType]="6"
        class="flex-grow h-full"></app-drawing>
      <!--        <app-draw-map [isActive]="true" [ticket]="{}" [layerFilterParameters]="" [coordinates]="[0, 0]"-->
      <!--                      class="flex-grow h-full"></app-draw-map>-->
    </div>
  `,
})
export class DemoComponent {
  protected control = new FormControl([], {});
  protected form = new FormGroup({ name: this.control });
  protected _selection = [0];
  protected _tabs = [
    { index: 0, title: 'home', icon: 'home' },
    { index: 1, title: 'list', icon: 'list' },
  ];
  protected options = [
    { value: 1, name: 'one' },
    { value: 2, name: 'two' },
    { value: 3, name: 'three' },
    { value: 4, name: 'four' },
    { value: 5, name: 'five' },
    { value: 6, name: 'six' },
    { value: 7, name: 'seven' },
    { value: 8, name: 'eight' },
    { value: 9, name: 'nine' },
    { value: 10, name: 'ten' },
    { value: 11, name: 'eleven' },
    { value: 12, name: 'twelve' },
    { value: 13, name: 'thirteen' },
    { value: 14, name: 'fourteen' },
    { value: 15, name: 'fifteen' },
    { value: 16, name: 'sixteen' },
    { value: 17, name: 'seventeen' },
    { value: 18, name: 'eighteen' },
    { value: 19, name: 'nineteen' },
    { value: 20, name: 'twenty' },
    { value: 21, name: 'twenty-one' },
    { value: 22, name: 'twenty-two' },
    { value: 23, name: 'twenty-three' },
    { value: 24, name: 'twenty-four' },
    { value: 25, name: 'twenty-five' },
    { value: 26, name: 'twenty-six' },
    { value: 27, name: 'twenty-seven' },
    { value: 28, name: 'twenty-eight' },
    { value: 29, name: 'twenty-nine' },
    { value: 30, name: 'thirty' },
    { value: 31, name: 'thirty-one' },
    { value: 32, name: 'thirty-two' },
    { value: 33, name: 'thirty-three' },
    { value: 34, name: 'thirty-four' },
    { value: 35, name: 'thirty-five' },
    { value: 36, name: 'thirty' },
  ];

  constructor() {}

  onTabChange(event: Array<number>) {
    this._selection = event.sort((a, b) => a - b);
  }
}
