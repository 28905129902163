<h1 mat-dialog-title class="font-rajdhani uppercase font-semibold text-lg">{{ data.header }}</h1>

<div mat-dialog-content>
    <mat-form-field class="w-fll">
      <mat-label>Type a new password</mat-label>
      <input matInput [(ngModel)]="password">
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
  <button (click)="onClose()" class="btn-cancel">Cancel</button>
  <button (click)="onConfirm()" class="btn-save">
    Confirm
  </button>
</div>