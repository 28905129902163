<h1 class="mat-headline-1 blue" mat-dialog-title>{{ header }}</h1>

<div
  mat-dialog-content
  style="height: 100%; width: 100%; overflow-x: hidden; background-color: white"
>
  <div>
    <form [formGroup]="dateTimePickerFormGroup">
      <!-- DateTime In -->
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div fxLayout="row" style="width: 100%">
          <mat-form-field (click)="pickerTimeIn.open()" fxFlex="45">
            <mat-label>Date</mat-label>
            <input
              [matDatepicker]="pickerTimeIn"
              formControlName="dateIn"
              matInput
              readonly="true"
              required
              type="search"
            />
            <mat-datepicker-toggle
              [for]="pickerTimeIn"
              matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTimeIn touchUi></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="55">
            <input
              [ngxTimepicker]="editableDialTimeIn"
              [readonly]="true"
              formControlName="timeIn"
              matInput
              required
            />
            <ngx-material-timepicker-toggle
              [for]="editableDialTimeIn"
              matSuffix
            >
              <mat-icon
                class="form-icon"
                ngxMaterialTimepickerToggleIcon
                >access_time</mat-icon
              >
            </ngx-material-timepicker-toggle>
            <!-- <button matSuffix mat-icon-button aria-label="Clear" (click)="dateTimePickerClear('timeIn')">
              <mat-icon class='form-icon'>close</mat-icon>
            </button> -->
            <ngx-material-timepicker
              #editableDialTimeIn
              [theme]="timePickerTheme"
            >
            </ngx-material-timepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div align="end" mat-dialog-actions>
  <button color="primary" mat-button mat-dialog-close>Cancel</button>
  <button (click)="onConfirm()" color="primary" mat-flat-button>Confirm</button>
</div>
