import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import {
  DragAndDropEventArgs,
  TreeView,
  NodeKeyPressEventArgs,
  NodeClickEventArgs,
} from "@syncfusion/ej2-angular-navigations";
import { remove } from "@syncfusion/ej2-base";
import { MatDialog } from "@angular/material/dialog";
import { SchedulerAdvancedSearchDialogComponent } from "../../dialogs/scheduler-advanced-search-dialog/scheduler-advanced-search-dialog.component";
import { SchedulerTicketDialogComponent } from "../../dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component";
import { Predicate, DataManager, Query } from "@syncfusion/ej2-data";
import { BaseViewComponent } from "src/app/modules/core/base-set/base-set.component";
import {
  FilterOperator,
  FilterOption,
  Ticket,
} from "../../syncfusion-scheduler/schedulerTypes";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import {
  ComponentMessage,
  MessageAction,
} from "src/app/modules/core/component-messaging/component-message";
import { PreloadAllModules } from "@angular/router";

@Component({
  selector: "app-scheduler-ticket-view",
  templateUrl: "./scheduler-ticket-view.component.html",
  styleUrls: ["./scheduler-ticket-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SchedulerTicketViewComponent
  extends BaseViewComponent
  implements OnInit
{
  className = "SchedulerTicketViewComponent";

  @Input() props: any;
  @Input() tickets: Ticket[];
  @ViewChild("treeObject") treeObject: TreeView;

  searchValue = "";

  public field: Object;

  cssClass = "custom";

  onlyUnScheduled: boolean = true;

  constructor(logger$: LoggerService, public dialog: MatDialog) {
    super(logger$);
  }

  ngOnInit(): void {
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });
    this.setupDefaultList();
  }

  private setupDefaultList() {
    console.log(this.tickets);
    try {
      let filters: FilterOption[] = [];

      filters.push({
        field: "isScheduled",
        value: 0,
        operator: FilterOperator.equal,
      });

      /*filters.push({
        field: 'LocateStatusID',
        value: 20,
        operator: FilterOperator.notEqual,
      })*/

      filters.push({
        field: "LocateStatusID",
        value: 25,
        operator: FilterOperator.notEqual,
      });

      let predicate: Predicate;
      filters.forEach((filter) => {
        if (predicate) {
          predicate = predicate.and(
            filter.field,
            filter.operator,
            filter.value,
            true
          );
        } else {
          predicate = new Predicate(
            filter.field,
            filter.operator,
            filter.value,
            true
          );
        }
      });
      let filteredTickets = new DataManager(this.tickets).executeLocal(
        new Query().where(predicate)
      );

      this.field = {
        dataSource: filteredTickets,
        id: "AssignmentID",
        text: "ExcavatorCompany",
      };
      console.log(filteredTickets);
    } catch (error) {
      this.logger$.error(error);
      // this.field = {
      //   dataSource: this.tickets,
      //   id: 'AssignmentID',
      //   text: 'RequestNumber'
      // };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tickets) {
      this.field = {
        dataSource: this.tickets,
        id: "AssignmentID",
        text: "ExcavatorCompany",
      };
    }
  }

  sendMessage(action: MessageAction, message: {}) {
    const messageToSend: ComponentMessage = {
      action: action,
      message: message,
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(messageToSend);
  }

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.eventCreated) {
        // todo: uncomment if want to delete tickets from list have creating events
        // this.removeTicket(compMessage.message.assignmentID)
      }
    }
  }

  getTicketData = (assignmentID): Ticket => {
    var ticket: Ticket = null;
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i]["AssignmentID"] == assignmentID) {
        ticket = this.tickets[i];
        break;
      }
    }
    return ticket;
  };

  clear() {
    this.searchValue = "";
    this.field = {
      dataSource: this.tickets,
      id: "AssignmentID",
      text: "ExcavatorCompany",
    };
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SchedulerAdvancedSearchDialogComponent, {
      width: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.advancedSearch(result);
    });
  }

  search(excavatorCompany) {
    console.log(this.onlyUnScheduled);

    let predicate: Predicate = new Predicate(
      "ExcavatorCompany",
      "contains",
      excavatorCompany,
      true
    );
    if (this.onlyUnScheduled) {
      /*If the button is selected to unscheduled */
      predicate = predicate.and("isScheduled", FilterOperator.equal, 0); // and fiter to only include not scheduled
      predicate = predicate.and("LocateStatusID", FilterOperator.notEqual, 25); //then filter to only not office cancelled
    }

    let filteredTickets = new DataManager(this.tickets).executeLocal(
      new Query().where(predicate)
    );
    console.log(filteredTickets);

    this.field = {
      dataSource: filteredTickets,
      id: "AssignmentID",
      text: "ExcavatorCompany",
    };
  }

  advancedSearch(form: Object) {
    var filters: FilterOption[] = [];
    let predicate: Predicate;

    if (form) {
      Object.keys(form).forEach((field) => {
        if (
          field != "dateField" &&
          field != "startDate" &&
          field != "endDate" &&
          form[field] != ""
        ) {
          filters.push({
            field: field,
            operator: FilterOperator.contains,
            value: form[field],
          });
        } else if (field == "dateField" && form[field] != "") {
          if (form["startDate"] != "") {
            filters.push({
              field: form[field],
              operator: FilterOperator.greaterThanOrEqual,
              value: form["startDate"],
            });
          }
          if (form["endDate"] != "") {
            filters.push({
              field: form[field],
              operator: FilterOperator.lessThanOrEqual,
              value: form["endDate"],
            });
          }
        }
      });
      this.logger$.log(
        "SchedulerTicketViewComponent: Advanced Search: ",
        filters
      );
      var filteredTickets: Object[];
      if (filters.length == 0) {
        filteredTickets = new DataManager(this.tickets).executeLocal(
          new Query()
        );
      } else {
        filters.forEach((filter) => {
          if (predicate) {
            predicate = predicate.and(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          } else {
            predicate = new Predicate(
              filter.field,
              filter.operator,
              filter.value,
              true
            );
          }
        });
        filteredTickets = new DataManager(this.tickets).executeLocal(
          new Query().where(predicate)
        );
      }
      this.field = {
        dataSource: filteredTickets,
        id: "AssignmentID",
        text: "ExcavatorCompany",
      };
    }
  }

  drop(args: DragAndDropEventArgs) {
    let ticket = this.getTicketData(args.draggedNodeData.id);
    if (ticket != null && ticket.isScheduled == 0) {
      this.sendMessage(MessageAction.READY, {
        ticketDropped: true,
        onDropEvent: args,
        ticketData: ticket,
      });
    } else {
      this.logger$.warn(
        "SchedulerTicketViewComponent: No ticket date with assignmentID = ",
        args.draggedNodeData.id
      );
    }
  }

  removeTicket(assignmentID) {
    let treeViewdata: { [key: string]: Object }[] = this.treeObject.fields
      .dataSource as { [key: string]: Object }[];
    const filteredTickets: { [key: string]: Object }[] = treeViewdata.filter(
      (item: any) => item.AssignmentID !== parseInt(assignmentID, 10)
    );
    this.treeObject.fields.dataSource = filteredTickets;
    let elements: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".e-drag-item.treeview-external-drag"
    );
    for (let i: number = 0; i < elements.length; i++) {
      remove(elements[i]);
    }
  }

  ticketClicked(args: NodeKeyPressEventArgs | NodeClickEventArgs) {
    let assignmentID = args.node.dataset.uid;
    this.openTicketDialog(assignmentID);
  }

  openTicketDialog(assignmentID): void {
    const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
      width: window.innerWidth.toString() + "px",
      maxWidth: "100%",
      height: window.innerHeight.toString() + "px", // if small screen make full screen
      panelClass: "margin-dialog-container",
      data: {
        ticketData: this.getTicketData(assignmentID),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
