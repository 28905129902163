<div class="page-container-full flex flex-col">
  <app-esri-map-simple-drawing
    #esriDrawing
    class="basis-[100%]"
    [ngClass]="{ 'hidden-map ': !isEsriVisibile }"
    style="max-height: 100%"
    [graphics]="graphics"
    [mapOptions]="mapOptions"
    [layerOptions]="layerOptions"
    [markers]="markers"
  >
  </app-esri-map-simple-drawing>

  <app-google-maps-drawing
    #cmpGoogleMapsDrawing
    class="flex-1"
    [ngClass]="{ 'hidden-map ': isEsriVisibile }"
    [googleMapsOptions]="options"
    [graphics]="graphics"
    [props]="googleMapsProps"
    [viewOnly]="true"
    [markers]="markers"
  >
  </app-google-maps-drawing>

  @if (!esriDrawing.layersOpen && !esriDrawing.legendOpen) {
    <div
      class="bottom-bar min-[920px]:hidden flex flex-1 flex-row justify-start items-center gap-[16px] basis-[56px] max-h-[56px]"
      (click)="toggleBottomMenu()"
    >
      <mat-icon>expand_less</mat-icon>
      <span class="mat-subheading-s">Map Options</span>
    </div>
  }
</div>

<!-- Bottom Sheet -->
@if (bottomMenuOpen && !esriDrawing.layersOpen && !esriDrawing.legendOpen) {
  <div class="bottom-sheet md:hidden">
    <div
      class="bottom-bar flex flex-row justify-start items-center gap-[16px]"
      style="height: 56px"
      (click)="toggleBottomMenu()"
    >
      <mat-icon class="arrow-icon">expand_more</mat-icon>
      <span class="mat-subheading-s">Map Options</span>
    </div>

    <div class="bottom-sheet-menu flex flex-col">
      <div
        *ngIf="isEsriVisibile"
        class="bottom-sheet-row flex flex-row justify-start items-center gap-[16px]"
        (click)="toggleLayers()"
      >
        <mat-icon>layers</mat-icon>
        <span class="mat-subheading-xs blue">Layers</span>
      </div>

      <div
        *ngIf="isEsriVisibile"
        class="bottom-sheet-row flex flex-row justify-start items-center gap-[16px]"
        (click)="toggleLegend()"
      >
        <mat-icon>list</mat-icon>
        <span class="mat-subheading-xs blue">Legend</span>
      </div>

      <div
        *ngIf="isEsriVisibile"
        class="bottom-sheet-row flex flex-row justify-start items-center gap-[16px]"
        (click)="toggleIdentify()"
      >
        <mat-icon><img class="identifyIcon" id="identifyLayerBlue" /></mat-icon>
        <span class="mat-subheading-xs blue">Identify</span>
      </div>

      <div
        class="bottom-sheet-row flex flex-row justify-start items-center gap-[16px]"
        (click)="toggleCoordinates()"
      >
        <mat-icon>explore</mat-icon>
        <span class="mat-subheading-xs blue">Coordinates</span>
      </div>

      <div
        *ngIf="esriAvailableSetting"
        class="bottom-sheet-row flex flex-row justify-start items-center gap-[16px]"
        (click)="toggleMap()"
      >
        <mat-icon>map</mat-icon>
        <span class="mat-subheading-xs blue">{{
          isEsriVisibile ? "Google Maps" : "Esri Maps"
        }}</span>
      </div>
    </div>
  </div>
}

<!-- FAB -->
<floating-action-menu
  [hidden]="bottomMenuOpen"
  [menuItems]="isEsriVisibile ? esriMapFab : googleMapsFab"
>
</floating-action-menu>

<!-- Toolbar -->
<div
  class="absolute left-1/2 bottom-[24px] z-[2] -translate-x-1/2 mapToolbar hidden min-[920px]:flex"
>
  <mat-button-toggle-group multiple aria-label="Google Map Drawing Tools">
    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Layers"
      aria-label="Open Layers"
      (click)="toggleLayers()"
    >
      <div class="btn-contents flex flex-col justify-center items-center">
        <mat-icon>layers</mat-icon>
        <span class="label">Layers</span>
      </div>
    </button>

    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Legend"
      aria-label="Open Legend"
      (click)="toggleLegend()"
    >
      <div class="btn-contents flex flex-col justify-center items-center">
        <mat-icon>list</mat-icon>
        <span class="label">Legend</span>
      </div>
    </button>

    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="{{ identifyEnabled ? 'white' : 'primary' }}"
      class="tool-toggle btn"
      matTooltip="Identify"
      aria-label="Identify"
      (click)="toggleIdentify()"
    >
      <div class="btn-contents flex flex-col justify-center items-center">
        <mat-icon
          svgIcon="ESRI_IDENTIFY"
          class="{{ identifyEnabled ? 'text-primary' : 'text-white' }}"
        />
        <span class="label {{ identifyEnabled ? 'text-primary' : '' }}"
          >Identify</span
        >
      </div>
    </button>

    <button
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Toggle Coordinates"
      aria-label="Toggle Coordinates"
      (click)="toggleCoordinates()"
    >
      <div class="btn-contents flex flex-col justify-center items-center">
        <mat-icon>explore</mat-icon>
        <span class="label">Coordinates</span>
      </div>
    </button>

    <button
      mat-flat-button
      *ngIf="esriAvailableSetting"
      color="primary"
      class="tool-toggle btn"
      [matTooltip]="isEsriVisibile ? 'Google Maps' : 'Esri Maps'"
      aria-label="Switch to Google Maps"
      (click)="toggleMap()"
    >
      <div class="btn-contents flex flex-col justify-center items-center">
        <mat-icon>map</mat-icon>
        <span class="label">{{
          isEsriVisibile ? "Google Maps" : "Esri Maps"
        }}</span>
      </div>
    </button>
  </mat-button-toggle-group>
</div>
