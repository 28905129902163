<div
  fxLayout="column"
  fxLayoutAlign="start"
  class="menu"
  [@onSideNavChange]="sidenavState"
>
  <div fxFlex="8px" class="side-nav-full-width"></div>
  <div fxFlex class="hide-functional-scrollbar menu-item-list">
    <div fxLayout="column" class="menu-item-list">
      <ng-container *ngFor="let route of routes">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="menu-item"
          [ngClass]="{ 'menu-item-selected': route.selected }"
          (click)="handleRouteClick(route)"
        >
          <span>
            <mat-icon class="menu-item-icon" [svgIcon]="route.icon"></mat-icon>
          </span>

          <span
            *ngIf="sidenavState === 'open'"
            class="menu-item-text mat-subtitle-2"
            >{{ route.title }}</span
          >
        </div>
      </ng-container>
    </div>
  </div>
</div>
