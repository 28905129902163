/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, NgModule, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentMessagingService } from "../../core/component-messaging/component-messaging.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { MaterialModule } from "../../shared/material.module";
import { CreateTicketComponentService } from "../create-ticket-component.service";
import { CreateTicketComponentModule } from "../create-ticket/create-ticket.component";
import { TicketService } from "../../shared/ticket/ticket.service";
import { TicketDetailsService } from "../../shared/ticket-details/ticket-details.service";

@Component({
  selector: "app-create-ticket-dialog",
  templateUrl: "./create-ticket-dialog.component.html",
  styleUrls: ["./create-ticket-dialog.component.css"],
})
export class CreateTicketDialogComponent implements OnInit {
  views = {};
  createTicketOptions = {
    isMinified: false,
    prefillFields: {},
    documents: [],
  };

  childProps = {};

  assignmentID;
  primaryID;
  isOfficeSide: any;

  constructor(
    public dialogRef: MatDialogRef<CreateTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logger$: LoggerService,
    private compMsg$: ComponentMessagingService,
    private createTicket$: CreateTicketComponentService,
    private ticketDetailsService$: TicketService,
    private ticket$: TicketDetailsService,

  ) {
    this.childProps = {
      messageService: compMsg$,
      views: this.views,
      createTicketOptions: this.createTicketOptions,
    };

    this.assignmentID = data.AssignmentID.toString();
    this.primaryID = data.PrimaryID;
    this.isOfficeSide = data.Office;
  }

  ngOnInit(): void {
    try {
      this.setupCreateTicketSettings()
        .then(() => {
          if (this.assignmentID && this.primaryID) {            
            return this.isOfficeSide ? this.ticket$.getTicketData(this.assignmentID) :  this.ticketDetailsService$.getTicketData(this.assignmentID, this.primaryID)
          } else {
            return false;
          }
        })
        .then((result) => {
          if (result) {
            if (
              result["tbCompletions_Assignments"] &&
              result["tbCompletions_AuxiliaryDetails"]
            ) {
              
              const assignmentsRows = this.isOfficeSide ? result["tbCompletions_Assignments"]["Data"][0] :
                result["tbCompletions_Assignments"][0];                
              const keys = Object.keys(assignmentsRows);
              keys.forEach((key) => {                
                if (key == "CallTypeID") {
                  this.createTicketOptions.prefillFields["calltype"] = {
                    value: assignmentsRows[key],
                  };
                } else if (key == "RequestNumber") {
                  console.log("ignore", key);
                } else {
                  this.createTicketOptions.prefillFields[key] =
                    assignmentsRows[key];
                }
              });
              this.createTicketOptions.prefillFields["UpdateOf"] =
                assignmentsRows["RequestNumber"];
              this.createTicketOptions.prefillFields["OriginalExcavationDate"] =
                assignmentsRows["ExcavationDate"];
              this.createTicketOptions.prefillFields["worksiteAddress"] =
                assignmentsRows["StartHouseNumber"] +
                " " +
                assignmentsRows["LocateAddress"] +
                " " +
                assignmentsRows["LocateSubRegionName"];

              const auxRows = this.isOfficeSide ? result["tbCompletions_AuxiliaryDetails"]["Data"] :
              result["tbCompletions_AuxiliaryDetails"];
              
              const utilityIDs = [];
              auxRows.forEach((auxRow) => {
                utilityIDs.push({ value: auxRow["UtilityID"] });
              });

              this.createTicketOptions.prefillFields["utilities"] = utilityIDs;
              
            }
            if (
              result.tbCompletions_PrimaryDetails &&
              result.tbCompletions_PrimaryDetails &&
              result.tbCompletions_PrimaryDetails.length > 0
            ) {
              const primary =  this.isOfficeSide ? result.tbCompletions_PrimaryDetails.Data : result.tbCompletions_PrimaryDetails
              const preFillHideShow = [];
              primary.forEach((row) => {
                if (row["isUpdated"] == 1) {
                  preFillHideShow.push(String(row.PrimaryDetailsFieldID));
                }
              });
              this.createTicketOptions.prefillFields["primaryDetailsHideShow"] =
                preFillHideShow;
            }

            if (
              result.tbCompletions_Documents &&
              result.tbCompletions_Documents.length > 0
            ) {
              this.createTicketOptions.documents =
               this.isOfficeSide ?  result.tbCompletions_Documents.Data : result.tbCompletions_Documents
            }
          }

          return this.createTicket$.getCreateTicketTemplate().toPromise();
        })
        .then((msg) => {
          if (msg) {
            this.childProps["views"] = msg["views"];
            this.childProps["commonViews"] = msg["commonViews"];
            this.childProps = { ...this.childProps };
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    } catch (error) {
      this.logger$.log("ngOnInit1" + error);
    }
  }

  async setupCreateTicketSettings() {
    try {
      await this.createTicket$.setupCreateTicketSettings();
    } catch (error) {
      console.error(error.message);
    }
    return true;
  }

  onSyncFinished(synced) {
    if (synced) {
      this.dialogRef.close(true);
    }
  }
}

@NgModule({
  declarations: [CreateTicketDialogComponent],
  imports: [CreateTicketComponentModule, MaterialModule, FlexLayoutModule],
  exports: [],
  providers: [ComponentMessagingService],
})
export class CreateTicketDialogModule {}
