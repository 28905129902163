/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import {
  ADMIN_TABLE_NAMES,
  COMPLETION_TABLE_NAMES,
} from "../../core/admin/tables";
import {
  AssignmentIDCacheResult,
  CacheWhereClause,
  CacheWhereClauseType,
  Table
} from "../../core/cache/cache.interface";
import { UtilocateAdminCacheService } from "../../core/cache/utilocate-admin.service";
import { DatetimeService } from "../../core/services/datetime/datetime.service";
import { U2_USER_CATEGORY_ID } from "../../core/services/user/user";
import { UserService } from "../../core/services/user/user.service";
import { safeAwait } from "../../core/validators/validator";
import { LocateStatusID } from "../ticket-details/ticket-details.module";
import { AutologRow } from "../ticket-details/ticket-details/autologs/ticket-autolog";
import {
  TicketTag,
  TicketTagBuilderService,
} from "../ticket-tags/ticket-list-tags/ticket-tags-builder.service";
import {
  VerifyDetails,
  VerifyDetailsType,
} from "./services/cache/utilocate-completions-verify";
import { UtilocateCompletionCacheService } from "./services/cache/utilocate-completions.service";
import { UtilocateDocumentsCacheService } from "./services/cache/utilocate-documents.service";
import {
  FormTemplateView,
  FormTemplateViews,
} from "./services/form-template/form-template.interface";
import {
  FormTemplateService,
  TicketDetailInputType,
} from "./services/form-template/form-template.service";
import { TicketMapObject } from "./ticket-drawing/ticket-drawing.component";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { DownloadDocumentService } from "../../core/services/document/download-document.service";
import { UploadAutologService } from "../../core/services/logger/upload-autolog.service";
import { ProgressBarService } from "../progress-bar/progress-bar.service";
import { SnackbarService } from "../snackbar/snackbar.service";
import { SnackbarType } from "../snackbar/snackbar/snackbar";
import { AutologID, SettingID } from "../../core/services/user/setting";
import { BehaviorSubject, Observable, from } from "rxjs";
import { CacheService, StoreType } from "../../core/cache/cache.service";
import { MatDialog } from "@angular/material/dialog";
import { ApiService, UtilocateApiRequest } from "../../core/api/baseapi.service";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { CreateAuditModalComponent } from "./modals/create-audit-modal/create-audit-modal.component";
import { TicketSyncService } from "./services/ticket-sync/ticket-sync.service";
import { AdminLookupService } from "../../core/admin/admin-lookup.service";
import { UtilocateApiService } from "../../core/api/utilocateapi.service";
import { UtilocateTokenPaths } from "../../core/services/token/token.service";
import { environment } from "src/environments/environment";
import { TicketDocumentsService } from "./ticket-documents/ticket-documents.service";
import { FormControl } from "@angular/forms";

export const TicketProtectionID = {
  CHECK_TICKET_PROTECTION: 0,
  ADD_TICKET_PROTECTION: 1,
  REMOVE_TICKET_PROTECTION: 2,
};

export interface TicketProtectionResult {
  result: boolean;
  date: string;
  userID: string;
  userFirstName: string;
  userLastName: string;

}

export type AdditionalDetails = {
  AssignmentID: number;
  CalLCenterFieldID: number;
  ColumnName: string;
  DataType: number;
  DateValue?: string;
  DecimalValue?: number;
  ExtraFieldID: number;
  IntValue?: number;
  StringValue?: string;
}

export class AdditionalDetailsClass {
  AssignmentID: number;
  CalLCenterFieldID: number;
  ColumnName: string;
  DataType: number;
  DateValue?: string;
  DecimalValue?: number;
  ExtraFieldID: number;
  IntValue?: number;
  StringValue?: string;

  constructor(details: AdditionalDetails, private datetimeService: DatetimeService,) {
    this.AssignmentID = details.AssignmentID;
    this.CalLCenterFieldID = details.CalLCenterFieldID;
    this.ColumnName = details.ColumnName;
    this.DataType = details.DataType;
    this.DateValue = details.DateValue;
    this.DecimalValue = details.DecimalValue;
    this.ExtraFieldID = details.ExtraFieldID;
    this.IntValue = details.IntValue;
    this.StringValue = details.StringValue;
  }

  getValue(): string | number | undefined {

    if (this.DateValue) {
      let timeFormatted = this.datetimeService.dbDateToFormattedLocalDate(this.DateValue);
      timeFormatted = new Date(timeFormatted).toDateString() + ' ' + new Date(timeFormatted).toLocaleTimeString();
      return timeFormatted;
    }

    return this.DateValue ?? this.DecimalValue ?? this.IntValue ?? this.StringValue;
  }

  getMappedTypeID() {
    switch (this.DataType) {
      case 1:
        return TicketDetailInputType.Checkbox;
      case 2:
        return TicketDetailInputType.String;
      case 5: 
        return TicketDetailInputType.Datepicker;
      case 6: 
        return TicketDetailInputType.Integer;
      default:
        return TicketDetailInputType.String;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private ticket$: BehaviorSubject<Partial<Ticket> | null> = new BehaviorSubject(null);
  // private isHeldDownSubject = new BehaviorSubject<boolean>(false);
  // isHeldDown$: any = this.isHeldDownSubject.asObservable();

  READ_ONLY_FORM_TEMPLATE = 2;
  OFFICE_FORM_TEMPLATE = 3;
  FIELD_FORM_TEMPLATE = 4;
  PRIVATE_TICKET_EDIT_FORM_TEMPLATE = 5;

  billingCategories: any;
  unlocatableClicked: any;

  tickets: any;
  constructor(
    private utilocateAdminService: UtilocateAdminCacheService,
    private utilocateCompletionService: UtilocateCompletionCacheService,
    private documentsCacheService: UtilocateDocumentsCacheService,
    private formTemplateService: FormTemplateService,
    private userService: UserService,
    private datetimeService: DatetimeService,
    private idb: CacheService,
    private auth$: AuthenticationService,
    private downloadDoc$: DownloadDocumentService,
    private autolog$: UploadAutologService,
    private progressBarService: ProgressBarService,
    private snackbarService$: SnackbarService,
    private dialog: MatDialog,
    private utilocateApiService: ApiService,
    private ticketSyncService: TicketSyncService,
    private adminLookupService: AdminLookupService,
    private baseApiService: UtilocateApiService,
    private ticketDocumentService: TicketDocumentsService,


  ) { }

  async getFormTemplate() {
    let templateToLoad = this.READ_ONLY_FORM_TEMPLATE;
    if (this.userService.getUserCategoryID() == U2_USER_CATEGORY_ID.Locator.toString()) {
      templateToLoad = this.FIELD_FORM_TEMPLATE;
    } else if (this.userService.getUserCategoryID() == U2_USER_CATEGORY_ID.Manager.toString()) {
      templateToLoad = this.OFFICE_FORM_TEMPLATE;
    } else if (this.userService.getUserCategoryID() == U2_USER_CATEGORY_ID.OfficeDispatch.toString()) {
      templateToLoad = this.OFFICE_FORM_TEMPLATE;
    }

    await this.formTemplateService.getTemplateViews(
      templateToLoad
    );
  }

  async getTicketUtilities() {
    if (this.ticket$.value) {
      try {
        const utilitiesResult: object[] | Error = await this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_Utilities
        );
        const [primary]: any = await this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_Primary,
          [
            {
              Column: 'AssignmentID',
              Value: this.ticket$.value['AssignmentID'],
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          false,
          this.ticket$.value['AssignmentID'].toString()
        );

        if (primary) {
          const PrimaryID = primary['PrimaryID'];

          const auxs = await this.utilocateCompletionService.queryTable(
            COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
            [
              {
                Column: 'PrimaryID',
                Value: PrimaryID,
                ValueType: CacheWhereClauseType.NUMBER,
              },
            ],
            false,
            this.ticket$.value['AssignmentID'].toString()
          );
          return auxs.map((aux) => {
            return aux['UtilityID'];
          });
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      return [];
    }
  }

  async getTicketAssignment(assignmentID: string): Promise<unknown> {
    const whereClause: CacheWhereClause = {
      Column: 'AssignmentID',
      Value: assignmentID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const [data, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
        [whereClause],
        false,
        assignmentID.toString()
      )
    );
    if (!error && data[0]) {
      const ticket = data[0];
      this.ticket$.next(ticket);
      return ticket;
    } else {
      this.ticket$.next(null);
      throw new Error('Failed to find assignment with this ID: ' + assignmentID);
    }
  }

  async getTicketCacheResult(assignmentID: string): Promise<AssignmentIDCacheResult | null> {
    const ticket = await this.utilocateCompletionService.queryAssignmentID(assignmentID);
    const tables: Table[] = [];

    if (!ticket) return null; //if we didn't find a ticket, return 

    for (const table of ticket.tables) {
      tables.push(new Table(table.name, table.Columns, table.Data));
    }
    return new AssignmentIDCacheResult(ticket.assigned, ticket.ticketChanged, ticket.insertTime, tables);
  }

  async reassignTicketToUser(PrimaryID, userIdToAssign) {
    try {

      const apiKey = apiKeys.u2.reassignTicketAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      value[PrimaryID] = { Value: userIdToAssign };

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const resultObj = JSON.parse(result.body.value);

      return resultObj;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  /**
   * Removes a ticket utility from the ticket.
   * This function will remove the utility from the ticket, and also remove the utility from the ticket's billing.
   * @param {string} primaryID 
   * @param {string} assignmentID 
   */
  async removeTicketUtility(utilityID: number, primaryID: string, assignmentID: string) {
    this.progressBarService.start();
    if (!utilityID || !primaryID || !assignmentID) throw new Error("removeTicketUtility error");
    const bFieldAdded = 1;

    try {
      this.insertTicketChangedToIDB(assignmentID);
      //Step 1: get info from tbCompletions_AuxiliaryDetails 
      const auxiliaryDetails = await this.getAuxiliaryDetails(utilityID, primaryID, assignmentID);

      for (const auxiliaryDetail of auxiliaryDetails) {
        //Step 2: delete from tbCompletions_AuxiliaryDetails
        await this.utilocateCompletionService.removeAuxiliaryDetails(auxiliaryDetail, assignmentID.toString());
        //Step 3: Delete from tbCompletions_Billing
        await this.utilocateCompletionService.removeBilling(auxiliaryDetail, assignmentID);
      }

      const PolygonCode = (await this.adminLookupService.getLookupTableRows(["tbAdmin_UtilityPolygons"], { UtilityID: utilityID }))[0].rows[0].PolygonCode;
      await this.utilocateCompletionService.removeAssignmentPolygonRow(assignmentID, PolygonCode);
    } catch (error) {
      console.error(error);
      this.snackbarService$.openSnackbar(
        "Failed to add utility to ticket",
        SnackbarType.error
      );
    }
  }

  /**
   * Gets the auxiliary details 
   * @param utilityID 
   * @param primaryID 
   * @returns 
   */
  async getAuxiliaryDetails(utilityID, primaryID, assignmentID: string) {
    const auxiliaryDetails = await this.utilocateCompletionService
      .queryTable(COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        [{ Column: "UtilityID", Value: utilityID, ValueType: CacheWhereClauseType.NUMBER },
        { Column: "PrimaryID", Value: primaryID, ValueType: CacheWhereClauseType.STRING }], false, assignmentID);
    return auxiliaryDetails;
  }


  /**
   * Adds a utility to this ticket 
   * @param {string} utilityID 
   * @param {string} primaryID 
   * @param {string} assignmentID 
   * @returns 
   */
  async addTicketUtility(utilityID: number, primaryID: string, assignmentID: string) {
    const bFieldAdded = 1;
    try {
      this.insertTicketChangedToIDB(assignmentID);
      const utilityDetails = await this.getUtilityDetails(utilityID);

      const AuxiliaryDetailID = await this.insertAuxiliaryDetails(primaryID, utilityID, bFieldAdded, assignmentID);

      await this.createBillingRecords(utilityID, bFieldAdded, AuxiliaryDetailID, assignmentID);

      const count = await this.countUtilityAuxiliaryDetails(utilityDetails.UtilityType, primaryID, assignmentID);

      // Step 5: Check if count > 0
      if (count <= 0) {
        return;
      }

      // Step 6: Get primary detail fields and insert into PrimaryDetails table
      await this.insertPrimaryDetails(primaryID, utilityID, bFieldAdded, assignmentID);

      //step 7: Insert into tbCompletions_AssignmentPolygons
      await this.insertAssignmentPolygons(assignmentID, utilityID);
    } catch (error) {
      console.error(error);
      this.snackbarService$.openSnackbar(
        "Failed to add utility to ticket",
        SnackbarType.error
      );
    }
  }

  /**
   * Inserts an entry to tbCompletions_AssignmentPolygons
   * @param assignmentID 
   * @param utilityID 
   * @returns 
   */
  async insertAssignmentPolygons(assignmentID, utilityID) {
    if (!assignmentID || !utilityID) return;

    try {
      const PolygonCode = (await this.adminLookupService.getLookupTableRows(["tbAdmin_UtilityPolygons"], { UtilityID: utilityID }))[0].rows[0].PolygonCode;
      await this.utilocateCompletionService.addAssignmentPolygon(assignmentID, PolygonCode);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Gest the utility details from tbAdmin_Utilities
   * @param utilityID 
   * @returns 
   */
  async getUtilityDetails(utilityID: number) {
    const utilities = await this.adminLookupService.getLookupTableRows(["tbAdmin_Utilities"], { UtilityID: utilityID });
    return utilities[0].rows[0];
  }

  /**
   * Adds auxiliary details for a new utility 
   * @param primaryID 
   * @param utilityID 
   * @param bFieldAdded 
   * @returns 
   */
  async insertAuxiliaryDetails(primaryID: string, utilityID: number, bFieldAdded: number, assignmentID: string) {
    const [auxDetails] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        [],
        false,
        assignmentID
      )
    );

    let AuxiliaryDetailID = 0;
    //find the highest local auxID value
    for (const row of auxDetails) {
      AuxiliaryDetailID = Math.max(row.AuxiliaryDetailID, AuxiliaryDetailID);
    }
    AuxiliaryDetailID++;

    //add it 
    await this.utilocateCompletionService.addAuxiliaryDetails(primaryID, utilityID, bFieldAdded, AuxiliaryDetailID, assignmentID);
    return AuxiliaryDetailID;
  }

  /**
   * Adds billing fields to tbCompletions_billing for a given UtilityID and AuxiliaryDetailID 
   * @param utilityID 
   * @param bFieldAdded 
   * @param AuxiliaryDetailID 
   */
  async createBillingRecords(utilityID: number, bFieldAdded: number, AuxiliaryDetailID: number, assignmentID: string) {
    try {
      let utilityBillingDetails = (await this.adminLookupService.getLookupTableRows(["tbAdmin_UtilityBillingDetails"], { UtilityID: utilityID }))[0].rows;

      //if we didn't find the UtilityBillingDetails, get them from the server 
      if (utilityBillingDetails.length == 0) {
        await this.adminLookupService.getLookupTablesFromServer(["tbAdmin_UtilityBillingDetails"], { UtilityID: utilityID });
        utilityBillingDetails = (await this.adminLookupService.getLookupTableRows(["tbAdmin_UtilityBillingDetails"], { UtilityID: utilityID }))[0].rows;
      }


      const [billingDetails] = await safeAwait(this.utilocateCompletionService.queryTable(COMPLETION_TABLE_NAMES.tbCompletions_Billing, [], false, assignmentID));

      //find the highest billingID locally 
      let BillingID = 0;
      for (const row of billingDetails) {
        BillingID = Math.max(row.BillingID, BillingID);
      }
      BillingID++;

      //loop over the billing details and add them for this new utility 
      for (const billingDetail of utilityBillingDetails) {
        await this.utilocateCompletionService.addBilling(BillingID, AuxiliaryDetailID, billingDetail.UtilityBillingCatID, bFieldAdded, 0, assignmentID);
        BillingID++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async callTicketProtection(assignmentID: string, ActionTypeID: number, UserID: string): Promise<TicketProtectionResult> {
    const actionResult: TicketProtectionResult = {
      result: false,
      date: '',
      userID: '',
      userFirstName: '',
      userLastName: '',
    };
    try {
      const apiKey = apiKeys.u2.TicketProtection;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: {
          ActionTypeID: ActionTypeID,
          TicketProtectionData: {
            AssignmentID: assignmentID,
            UserID: UserID,
          },
        },
      };

      const result =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);

      const resultObj = JSON.parse(result.body.value);

      if (
        ActionTypeID == TicketProtectionID.CHECK_TICKET_PROTECTION
      ) {
        actionResult.result = resultObj["inTicketProtection"];
        actionResult.date = resultObj["date"];
        actionResult.userID = resultObj["userID"];
        actionResult.userFirstName = resultObj["userFirstName"];
        actionResult.userLastName = resultObj["userLastName"];
      } else if (
        ActionTypeID == TicketProtectionID.ADD_TICKET_PROTECTION
      ) {
        actionResult.result = resultObj["addedToTicketProtection"];
      } else if (
        ActionTypeID == TicketProtectionID.REMOVE_TICKET_PROTECTION
      ) {
        actionResult.result = resultObj["removedFromTicketProtection"];
      }

      if (resultObj["error"]) {
        console.error(resultObj["error"]);
      }
      return actionResult;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Adds primary details given a utility id 
   * @param primaryID 
   * @param utilityID 
   * @param bFieldAdded 
   * @returns 
   */
  async insertPrimaryDetails(primaryID: string, utilityID: number, bFieldAdded: number, assignmentID: string) {
    const utilities = await this.adminLookupService.getLookupTableRows(["tbAdmin_Utilities"], { UtilityID: utilityID });
    const utilityType = utilities[0].rows[0].UtilityType;

    const primaryDetailsCategories = await this.adminLookupService.getLookupTableRows(["tbAdmin_PrimaryDetailsCategories"], { UtilityType: utilityType });
    const primaryDetailCategoryID = primaryDetailsCategories[0].rows[0]?.PrimaryDetailCategoryID;

    if (!primaryDetailCategoryID || primaryDetailCategoryID.length <= 0) return;

    const primaryDetailFields = await this.adminLookupService.getLookupTableRows(["tbAdmin_PrimaryDetailFields"], { PrimaryDetailCategoryID: primaryDetailCategoryID });

    for (const row of primaryDetailFields[0].rows) {
      await this.utilocateCompletionService.addPrimaryDetails(primaryID, row.PrimaryDetailsFieldID, row.FieldValue, bFieldAdded, assignmentID);
    }
  }

  /**
   * Gets the count of the auxiliary details for a utility and primary 
   * @param utilityType 
   * @param primaryID 
   * @returns 
   */
  async countUtilityAuxiliaryDetails(utilityType: any, primaryID: string, assignmentID: string) {
    let count = 0;
    const [auxDetails, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        [
          {
            Column: "PrimaryID",
            Value: primaryID,
            ValueType: CacheWhereClauseType.NUMBER,
          }
        ],
        false,
        assignmentID
      )
    );

    for (const row of auxDetails) {
      const [utility, error2] = await safeAwait(
        this.adminLookupService.getLookupTableRows(["tbAdmin_Utilities"], { UtilityID: row.UtilityID })
      );
      if (error2) throw error2;
      if (utility[0].rows[0].UtilityType == utilityType) {
        count++;
      }
    }

    return count;
  }


  /**
   * Gets a list of the clear options for a primaryID and user category 
   * @param userCategoryID 
   * @param primaryID 
   * @returns 
   */
  async getClearOptions(userCategoryID: number | string, primaryID: string, assignmentID: string) {
    const clearOptions = [];
    try {
      const tables = await this.adminLookupService.getLookupTables(["tbAdmin_ClearOptions"]);
      const [utilityIDs, error2] = await safeAwait(
        this.utilocateCompletionService.queryTicketPrimaryUtilityIDs(primaryID, assignmentID.toString())
      );
      if (error2) throw error2;
      if (tables) {
        const tbAdmin_ClearOptions = tables[0].Data;
        for (const i in tbAdmin_ClearOptions) {
          const clearOption = tbAdmin_ClearOptions[i];

          // Check if the clear option can be used on any of the tickets utilities
          const allowedUtilityIDs = clearOption.UtilityIDs.split(",");
          let utilityAllowed = false;
          for (const i in utilityIDs) {
            if (allowedUtilityIDs.includes(utilityIDs[i].toString())) {
              utilityAllowed = true;
              break;
            }
          }

          // Add permitted clear options to list
          if (clearOption.UserCategoryID == userCategoryID && utilityAllowed) {
            clearOptions.push({
              ClearTypeID: clearOption.ClearTypeID,
              Description: clearOption.Description,
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return clearOptions;
  }

  /**
   * Clears a ticket based off a primaryID and clear type id
   * @param clearTypeID
   * @param primaryID
   * @returns
   */
  callClearTicket(clearTypeID: string, primaryID: string) {
    return new Observable((subscriber) => {
      try {
        // this.alert$.start();
        const url = apiKeys.u2[apiKeys.u2.clearTicket];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.clearTicket,
          API_TYPE: type,
          API_URL_DATA_PARAMS: {
            PrimaryID: primaryID,
            ClearTypeID: clearTypeID,
          },
        };

        // from is observable
        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest),
        ).subscribe((response) => {
          if (response.ok) {
            subscriber.next(response.body);
          } else {
            subscriber.next(false);
          }
          // this.alert$.stop();
          subscriber.complete();
        });
      } catch (error) {
        console.warn("TicketDetails$: callClearTicket: failed: ", error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  async getTicketData(assignmentID, primaryID) {
    let returnVal = null;
    try {
      returnVal = [];
      for (const table of Object.keys(COMPLETION_TABLE_NAMES)) {        
        const [data, error] = await safeAwait(
          this.utilocateCompletionService.queryTable(table, [], false, assignmentID)
        );
        if (error) throw error;
        if (data) {
          
          returnVal[table] = data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return returnVal;
  }


  async getTicketCallTypeDesc(assignmentID: string): Promise<string | Error> {
    const ticket = await this.getTicketAssignment(assignmentID);

    const CallTypeID = ticket["CallTypeID"];
    const whereClause: CacheWhereClause = {
      Column: "CallTypeID",
      Value: CallTypeID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const CallTypeDescResult: object[] | Error =
      await this.utilocateAdminService.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_CallType,
        [whereClause],
      );

    if (!(CallTypeDescResult instanceof Error)) {
      const CallTypeDesc = CallTypeDescResult[0]["CallTypeDesc"];
      return CallTypeDesc;
    } else {
      throw CallTypeDescResult;
    }
  }


  /**
   *Filters IDB call types to include only those with values over 1000
   * @returns filteredCallTypes
   */

  async getFilteredCallTypes() {

    let filteredCallTypes
    const CallTypeDescResult: object[] | Error = await this.utilocateAdminService.queryTable(
      ADMIN_TABLE_NAMES.tbAdmin_CallType,
    );
    if (!(CallTypeDescResult instanceof Error)) {

      filteredCallTypes = CallTypeDescResult.filter(callType => {
        const callTypeID = callType["CallTypeID"]
        return callTypeID > 1000
      });
    } else {
      throw CallTypeDescResult;
    }
    return filteredCallTypes
  }

  /**
   *Gets all utilites from IDB
   * @returns utilitiesResult
   */

  async getUtilitesTypes() {
    const utilitiesResult: object[] | Error =
      await this.utilocateAdminService.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_Utilities,
      );

    if (!(utilitiesResult instanceof Error)) {
      return utilitiesResult
    } else {
      throw utilitiesResult;
    }
  }

  /**
 *Api call to create ticket to create an audit ticket
 */

  async createAuditTicket(requestNumber, utilityID) {
    try {
      const apiKey = apiKeys.u2.createTicket;

      const apiValue = {
        ExcavatorDetails: [],
        TicketDetails: [],
        CreateFromRequestNumber: requestNumber,
        PrimaryDetails: [],
        PrimaryDetailsPrefill: [],
        DigsiteDetails: [],
        UtilityDetails: [{ 'UtilityID': utilityID, 'UtilityType': utilityID }]
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      const apiResult = await this.utilocateApiService.invokeUtilocateApi(
        utilocateApiRequest
      );
      const result = JSON.parse(apiResult["body"]);
      if (result.PossibleError) {
        this.snackbarService$.openSnackbar(
          result.PossibleError,
          SnackbarType.error
        );
      }
      else {
        this.snackbarService$.openSnackbar(
          "Ticket Created",
          SnackbarType.success
        );
        this.ticketSyncService.startSync(true, true).subscribe({
          complete: () => {
            this.ticketSyncService.closeModal();
          },
        });
      }
    } catch (error) {
      console.error(error);
      this.snackbarService$.openSnackbar(error, SnackbarType.error);
      return false;
    }
  }

  /**
   *Opens create audit ticket modal
   */

  async createAuditTicketModal(route) {
    const callTypes = await this.getFilteredCallTypes()
    const Utilites = await this.getUtilitesTypes()

    const dialogRef = this.dialog.open(CreateAuditModalComponent, {
      width: "250px",
      data: { type: callTypes, task: Utilites, showRequestField: route },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createAuditTicket(result.result.requestNumber, result.result.utilityID)
      }
    });
  }

  sortTickets() {
    const tickets = this.tickets;
    const isDescending = true; //set to false for ascending

    tickets.sort((a, b) =>
      isDescending
        ? new Date(b.DigDate).getTime() - new Date(a.DigDate).getTime()
        : new Date(a.DigDate).getTime() - new Date(b.DigDate).getTime()
    );
    this.tickets = tickets;
  }

  /**
   * Returns the result from tbMap_Routes for a given (optional) user ID 
   * Defaults to using the current user ID
   *
   * @return {*} 
   * @memberof TicketService
   */
  async getRouting(userID?: string) {
    const uID = userID ?? this.userService.getUserID();
    
    // If we're given a UserID, then pull the map route 
    // from the API 
    if (userID) {
      await this.adminLookupService.refreshLookupTables([
        ADMIN_TABLE_NAMES.tbMap_Routes
      ], {"UserID" : uID});
    }
    // If we are not given a userID, then we are looking for the 
    // local table value 

    //once we have it, then we can look locally. 
    const userResult: object[] | Error =
    await this.utilocateAdminService.queryTable(
      ADMIN_TABLE_NAMES.tbMap_Routes,
      [
        {
          Column: "UserID",
          Value: uID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ]
    );

    //if we errored, return 
    if (userResult instanceof Error) {
      return userResult;
    }

    return userResult;
  }

  setTickets(filter) {
    if (filter) {
      return true;
    }
    return false;
    // this.tickets = ticketList
  }
  // getFormattedTicket = memoize(this.getTicketAssignmentFormatted);
  async getTicketAssignmentFormatted(assignmentID: string) {
    const whereClause: CacheWhereClause = {
      Column: 'AssignmentID',
      Value: assignmentID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const [data, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
        [whereClause],
        false,
        assignmentID
      )
    );
    const [extraData, error4] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AssignmentExtraFields,
        [whereClause],
        false,
        assignmentID
      )
    );

    if (!error && data[0]) {
      const { CurrentLocatorID, LocateStatusID, LocateTypeID } = data[0];
      const [tbAdmin_LocateStatus, error] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_LocateStatus
        )
      );
      const [tbAdmin_LocateType, error2] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_LocateType
        )
      );
      const [tbLogin_Users, error3] = await safeAwait(
        this.utilocateAdminService.queryTable(ADMIN_TABLE_NAMES.tbLogin_Users, [
          { Column: 'UserID', Value: CurrentLocatorID },
        ])
      );

      const LocateStatusDesc = tbAdmin_LocateStatus.find(
        (row) => row["LocateStatusID"] == LocateStatusID
      )["LocateStatusDesc"];
      const LocateTypeDesc = tbAdmin_LocateType.find(
        (row) => row["LocateTypeID"] == LocateTypeID
      )["LocateTypeDesc"];

      let AssignedUser = CurrentLocatorID;
      if (tbLogin_Users.length > 0) {
        AssignedUser = tbLogin_Users[0]['FirstName'] + ' ' + tbLogin_Users[0]['LastName'];
      }

      data[0]['LocateStatusID'] = LocateStatusDesc;
      data[0]['LocateTypeID'] = LocateTypeDesc;
      data[0]['CurrentLocatorID'] = AssignedUser;
      data[0]['ExtraFields'] = extraData;

      const ticket = data[0];
      return ticket;
    } else {
      throw new Error('Failed to find assignment with this ID: ' + assignmentID);
    }
  }

  async getTicketPrimary(assignmentID: string): Promise<any> {
    const whereClause: CacheWhereClause = {
      Column: 'AssignmentID',
      Value: assignmentID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const [data, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Primary,
        [whereClause],
        false,
        assignmentID
      )
    );
    if (!error && data.length > 0) {
      const ticket = data[data.length - 1];
      return ticket;
    } else {
      throw new Error('Failed to find assignment with this ID: ' + assignmentID);
    }
  }

  async getTicketPrimaryTimeInOutStatus(assignmentID: string, primaryID: string): Promise<string | any> {
    const whereClause: CacheWhereClause = {
      Column: 'PrimaryID',
      Value: primaryID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const tbCompletions_Primary =
      await this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Primary,
        [whereClause],
        false,
        assignmentID
      );
    if (!(tbCompletions_Primary instanceof Error)) {
      if (tbCompletions_Primary[0]) {
        if (
          tbCompletions_Primary[0]['TimeIn'] != null &&
          tbCompletions_Primary[0]['TimeIn'] != '' &&
          tbCompletions_Primary[0]['TimeOut'] != null &&
          tbCompletions_Primary[0]['TimeOut'] != ''
        ) {
          return 'Edit Time';
        } else if (tbCompletions_Primary[0]['TimeIn'] == null && tbCompletions_Primary[0]['TimeOut'] == null) {
          return 'Start Time';
        } else {
          return 'End Time';
        }
      }
    }
  }

  /**
   * Gets document rows for tbCOmpletions_Documents (downloaded and on server)
   * @param assignmentID
   * @returns
   */
  async getTicketDocumentRows(assignmentID: string): Promise<object[]> {
    const whereClause: CacheWhereClause = {
      Column: "AssignmentID",
      Value: assignmentID,
      ValueType: CacheWhereClauseType.NUMBER,
    };
    const [ticketDocuments, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Documents,
        [whereClause],
        false,
        assignmentID
      )
    );

    const [downloadedDocIDs, error1] = await safeAwait(
      this.documentsCacheService.queryTicketDocumentIDs(assignmentID)
    );

    if (!error) {
      return [ticketDocuments, downloadedDocIDs];
    }
    return [];
  }

  /**
   * Gets document rows for S3 Documents (downloaded and on server)
   * @param assignmentID
   * @returns
   */
  async getTicketS3DocumentRows(assignmentID: string): Promise<object[]> {
    const whereClause: CacheWhereClause = {
      Column: "AssignmentID",
      Value: assignmentID,
      ValueType: CacheWhereClauseType.NUMBER,
    };

    const [ticketS3Documents, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
        [whereClause],
        false,
        assignmentID
      )
    );

    const [downloadedS3DocIDs, error2] = await safeAwait(
      this.documentsCacheService.queryTicketS3DocumentIDs(assignmentID)
    );

    if (!error) {
      return [ticketS3Documents, downloadedS3DocIDs];
    }
    return [];
  }

  async getTicketDocument(assignmentID: string, documentID: string, isS3Document: boolean = false) {
    return this.documentsCacheService.downloadDocument(
      assignmentID,
      documentID,
      isS3Document
    );
  }

  getTicketBillingCategories() {
    return this.billingCategories;
  }

  async uploadDocToS3(data, zipname, metadata) {
    return this.documentsCacheService.uploadDocument(data, zipname, metadata);
  }

  async queueDocumentToUpload(assignmentID: string, documentID: string, docParams: any) {
    return this.documentsCacheService.addDocumentLocally(assignmentID, documentID, docParams);
  }

  async deleteLocalDocument(assignmentID: string, documentID: string, isS3: any,) {
    return this.documentsCacheService.removeSingleDocumentLocally(assignmentID, documentID, isS3);
  }

  async getHighestLocalDocumentID(isS3Document = false, assignmentID: string) {
    return this.documentsCacheService.getHighestLocalDocumentID(isS3Document, assignmentID);
  }

  async getTicketAutologs(assignmentID: string): Promise<object[]> {
    const whereClause: CacheWhereClause = {
      Column: "AssignmentID",
      Value: assignmentID,
    };
    const [ticketAutologs, errors] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Autolog,
        [whereClause],
        false,
        assignmentID
      )
    );
    const [autologIDDescMap, adminError] = await safeAwait(
      this.utilocateAdminService.createAdminIDToDescMap(
        ADMIN_TABLE_NAMES.tbAdmin_AutologDesc,
        'AutologDescID',
        'AutologDesc'
      )
    );

    if (!errors) {
      for (let i = 0; i < ticketAutologs?.length; i++) {
        ticketAutologs[i]['DescIDDesc'] = !adminError
          ? autologIDDescMap[ticketAutologs[i]['DescID']]
          : ticketAutologs[i]['DescID'];
      }
      return ticketAutologs;
    }
    return [];
  }

  async getAssignmentToTags(assignment){
    const whereClause: CacheWhereClause = {
      Column: "AssignmentID",
      Value: assignment,
    };

  const tbCompletions_AssignmentToTags: any[] | Error =
  await this.utilocateCompletionService.queryTable(
    COMPLETION_TABLE_NAMES.tbCompletions_AssignmentToTags,
    [whereClause],
    false,
    assignment
  );
  return tbCompletions_AssignmentToTags
}

  async getTicketTags(
    assignment: string,
    LocateStatusIDs?,
    CallTypeId?
  ): Promise<TicketTag[] | any> {
    const whereClause: CacheWhereClause = {
      Column: "AssignmentID",
      Value: assignment,
    };

    const tbCompletions_Assignments: any[] | Error =
      await this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
        [whereClause],
        false,
        assignment
      );

    const tbCompletions_AssignmentToTags: any[] | Error =
      await this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AssignmentToTags,
        [whereClause],
        false,
        assignment
      );
    if (
      !(tbCompletions_AssignmentToTags instanceof Error) &&
      !(tbCompletions_Assignments instanceof Error) && tbCompletions_Assignments
    ) {
      const LocateStatusID = LocateStatusIDs
        ? LocateStatusIDs
        : tbCompletions_Assignments[0]["LocateStatusID"];
      // const CallTypeID = CallTypeId
      //   ? CallTypeId
      //   : tbCompletions_Assignments[0]["CallTypeID"];
      const TagIDs = CallTypeId
        ? null
        : tbCompletions_AssignmentToTags.reduce((total, value) => {          
          if (value && value["TagID"] != null && value['bFieldRemoved'] != 1) {
            total.push(value["TagID"]);
          }
          return total;
        }, []);
      let ticketTagsByTable: TicketTag[] = [];
      if (TagIDs) {
        const whereClause1: CacheWhereClause = {
          Column: "TagID",
          Value: TagIDs,
          ValueType: CacheWhereClauseType.ARRAY,
        };
        const tbAdmin_TicketTags: any[] | Error =
          await this.utilocateAdminService.queryTable(
            ADMIN_TABLE_NAMES.tbAdmin_TicketTags,
            [whereClause1]
          );

        if (!(tbAdmin_TicketTags instanceof Error)) {
          ticketTagsByTable =
            new TicketTagBuilderService().createTicketTagsFromTable(
              tbAdmin_TicketTags,
              TagIDs
            );
        }
      }
      const ticketTagsByCallType: TicketTag[] = new TicketTagBuilderService().createTagsFromCallTypeID(CallTypeId);

      const ticketTagsByLocateStatus: TicketTag[] = new TicketTagBuilderService().createTagsFromLocateStatusID(
        LocateStatusID
      );

      return [
        ...ticketTagsByTable,
        ...ticketTagsByLocateStatus,
      ];
    } else {
      return tbCompletions_AssignmentToTags;
    }
  }

  async updateTicketPrimary(setClause: any, primaryID: string, assignmentID: string) {
    return this.utilocateCompletionService.updateTableData(
      COMPLETION_TABLE_NAMES.tbCompletions_Primary,
      setClause,
      [
        {
          Column: 'PrimaryID',
          Value: primaryID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ], assignmentID);
  }

  async updateTicketDetails(setClause: any, assignmentID: string) {
    return this.utilocateCompletionService.updateTableData(
      COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
      setClause,
      [],
      assignmentID
    );
  }

  async updateS3TicketDocuments(setClause: any, documentID: string, assignmentID: string) {
    return this.utilocateCompletionService.updateTableData(
      COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
      setClause,
      [
        {
          Column: 'S3DocumentID',
          Value: documentID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ],
      assignmentID
    );
  }

  async updateTicketDocuments(setClause: any, documentID: string, assignmentID: string) {
    return this.utilocateCompletionService.updateTableData(
      COMPLETION_TABLE_NAMES.tbCompletions_Documents,
      setClause,
      [
        {
          Column: 'DocumentID',
          Value: documentID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ],
      assignmentID
    );
  }


  async updateTicketCompletionDetails(
    formValue: object,
    formGroups: any,
    tablename: string,
    tablePrimaryKey: string,
    PrimaryID?: string
  ) {
    const AssignmentID = sessionStorage.getItem("AssignmentID");
    this.insertTicketChangedToIDB(AssignmentID);
    const formValueKeys = Object.keys(formValue);
    for (let i = 0; i < formValueKeys.length; i++) {
      const formValueKey = formValueKeys[i];
      const formValueData = formValue[formValueKey];
      const formValueDataType = formGroups.find(
        (row) => row["key"] == formValueKey
      );

      if (formValueDataType['inputTypeID'] == TicketDetailInputType.Checkbox) {
        if (formValueData == true) {
          formValue[formValueKey] = 1;
        } else {
          formValue[formValueKey] = 0;
        }
      }

      const setClause = {
        FieldValue: formValue[formValueKey],
        bFieldAdded: 1,
        isUpdated: 1,
      };
      const whereClause: CacheWhereClause[] = [
        { Column: tablePrimaryKey, Value: formValueKey },
      ];
      if (PrimaryID) {
        whereClause.push({
          Column: 'PrimaryID',
          Value: PrimaryID,
          ValueType: CacheWhereClauseType.NUMBER,
        });
      }
      const result = await this.utilocateCompletionService.updateTableData(
        tablename,
        setClause,
        whereClause,
        AssignmentID
      );
    }

    return true;
  }

  /**
   * Inserts a ticket change boolean to the assignment object in the idb
   * @param assignmentID 
   * @returns 
   */
  async insertTicketChangedToIDB(assignmentID: string) {
    try {
      let insertResult: AssignmentIDCacheResult;

      const idbResult = await this.utilocateCompletionService.queryAssignmentID(assignmentID);
      if (idbResult) {
        idbResult['ticketChanged'] = true;
        insertResult = await this.utilocateCompletionService.setAssignmentID(assignmentID, idbResult);
      }

      return insertResult;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Removes the ticket object from the idb
   * @param {string} assignmentID 
   */
  async removeAssignment(assignmentID: string) {
    this.utilocateCompletionService.removeKey(assignmentID);
  }

  async listAssignments() {
    return await this.utilocateCompletionService.listKeys();
  }

  async addAutologToAssignment(autologRow: AutologRow, assignmentID: string) {
    return this.utilocateCompletionService.addAutologToAssignment(autologRow, assignmentID);
  }

  async removeDocumentToAssignment(event, assignmentID) {
    if (event.isS3Document) {
      return this.utilocateCompletionService.removeS3DocumentToAssignment(event, assignmentID);
    } else {
      return this.utilocateCompletionService.removeDocumentToAssignment(event, assignmentID);
    }
  }

  async addDocumentHashToExpectedDocHash(expectedDocumentHashRow: any, assignmentID: string) {
    return this.utilocateCompletionService.addDocumentHashToExpectedDocHash(expectedDocumentHashRow, assignmentID);
  }

  async removeDocumentHash(fileName: string, assignmentID: string) {
    return this.utilocateCompletionService.removeDocumentHash(fileName, assignmentID);
  }

  async addDocumentToAssignment(documentRow: any, isS3Document = false, assignmentID: string) {
    if (isS3Document) {
      return this.utilocateCompletionService.addS3DocumentToAssignment(documentRow, assignmentID);
    }
    return this.utilocateCompletionService.addDocumentToAssignment(documentRow, assignmentID);
  }

  //! functions to create ticket tabs
  // getCreateTicketInfo = memoize((assignmentID) => this.createTicketInfo(assignmentID));
  async createTicketInfo(assignmentID: string) {
    const [ticket, ticketError] = await safeAwait(
      this.getTicketAssignmentFormatted(assignmentID)
    );

    if (!ticketError) {
      const excavatorID = ticket["ExcavatorID"];
      const whereClause: CacheWhereClause = {
        Column: "ExcavatorID",
        Value: excavatorID,
        ValueType: CacheWhereClauseType.NUMBER,
      };
      const [excavator, excavatorError] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_Excavators,
          [whereClause]
        )
      );


      //TODO: here is where we want to set the ticket view based 
      //off SETTINGS, not user category id 
      let templateToLoad = this.READ_ONLY_FORM_TEMPLATE;
      if (
        this.userService.getUserCategoryID() ==
        U2_USER_CATEGORY_ID.Locator.toString()) {

        templateToLoad = this.FIELD_FORM_TEMPLATE;

      } else if (
        this.userService.getUserCategoryID() ==
        U2_USER_CATEGORY_ID.Manager.toString()) {

        templateToLoad = this.OFFICE_FORM_TEMPLATE;

      } else if (
        this.userService.getUserCategoryID() ==
        U2_USER_CATEGORY_ID.OfficeDispatch.toString()) {

        templateToLoad = this.OFFICE_FORM_TEMPLATE;

      }
      templateToLoad = this.OFFICE_FORM_TEMPLATE; //TODO: Take out

      const result = await this.formTemplateService.getTemplateViews(
        templateToLoad
      );
      const resultKeys = Object.keys(result);

      for (let i = 0; i < resultKeys.length; i++) {
        const key = resultKeys[i];
        const formTemplateViewGroups: any[] = Object.values(result[key].groups);
        const formTemplateViewFields = [];
        for (let j = 0; j < formTemplateViewGroups.length; j++) {
          const groupFields = Object.values(formTemplateViewGroups[j].fields);
          formTemplateViewFields.push(...groupFields);
        }

        for (let j = 0; j < formTemplateViewFields.length; j++) {
          const field = formTemplateViewFields[j];
          let value = null;

          if (ticket[field.key]) {
            value = ticket[field.key];
          } else if (excavator && excavator[0] && excavator[0][field.key]) {
            value = excavator[0][field.key];
          }

          if (value && field.inputTypeID == 10) {
            let timeFormatted = this.datetimeService.dbDateToFormattedLocalDate(value);
            timeFormatted = new Date(timeFormatted).toDateString() + ' ' + new Date(timeFormatted).toLocaleTimeString();
            value = timeFormatted;
          }

          result[key].formGroup.patchValue({ [field.key]: value });
        }
      }
      
      const additionalDetailsHeader = "ticketInfo";

      if (result[additionalDetailsHeader] && result[additionalDetailsHeader]["groups"] && result[additionalDetailsHeader]["groups"]["ticketDetails"] && ticket["ExtraFields"] &&ticket["ExtraFields"]) {
        const extraFields: AdditionalDetails[] = ticket["ExtraFields"];
        for (const field of extraFields) {
          const thisField = new AdditionalDetailsClass(field, this.datetimeService);
          
          // add a form control to show the value 
          result[additionalDetailsHeader].formGroup.addControl(thisField.ColumnName, new FormControl(thisField.getValue()));

          // add the column name to the fields object so you can see it in the form input template 
          result[additionalDetailsHeader]["groups"]["ticketDetails"]["fields"][thisField.ColumnName] = {
            appearance: "none",
            fieldOrder: 0,
            inputTypeID: thisField.getMappedTypeID(),
            isReadOnly: true,
            isRequired: false,
            isVisible: true,
            key: thisField.ColumnName,
            label: thisField.ColumnName,
            matches: '',
            options: {},
            placeholder: '',
            tabIndex: 0,
            tableName: 'tbCompletions_AssignmentExtraFields',
            width_lg: '33',
            width_md: '33',
            width_sm: '50',
            width_xl: '33',
            width_xs: '100',
          }
        }
      }

      return result;
    }
  }

  async createMapInfo(assignmentID: string) {
    const [ticket, ticketError] = await safeAwait(
      this.getTicketAssignment(assignmentID)
    );
    if (!ticketError) {
      const esriAvailableSetting = await this.userService.isSettingActive(181);
      const esriFirstSetting = await this.userService.isSettingActive(182);

      // summary panel
      // let address = ticket["LocateAddress"] ? ticket["LocateAddress"] + ", " + ticket["LocateSubRegionName"] : ticket["LocateSubRegionName"];
      let address = ticket.StartHouseNumber + ' ' + ticket.LocateAddress;
      if (ticket.LocateCrossStreet) {
        address = address + ', ' + ticket.LocateCrossStreet;
      }
      address = address + ', ' + ticket.LocateSubRegionName;

      const mapProps: TicketMapObject = {
        Longitude: Number(ticket["Longitude"]),
        Latitude: Number(ticket["Latitude"]),
        Address: address,
        EsriAvailable: esriAvailableSetting,
        EsriFirst: esriFirstSetting,
        RequestNumber: ticket['RequestNumber'],
        CallCenterID: ticket['CallCenterID'],
        AssignmentID: assignmentID,
      };
      return mapProps;
    } else {
      return null;
    }
  }

  //! functions to create completions tab
  async createCompletionsBillingDetails(primaryID: string, assignmentID: string, fieldsViewOnly: boolean = false): Promise<any> {
    const [primaryUtilityBillingCatIDs, error] = await safeAwait(
      this.utilocateCompletionService.queryTicketPrimaryBillingCategories(
        primaryID,
        assignmentID
      )
    );
    if (!error) {
      this.billingCategories = primaryUtilityBillingCatIDs;
      const [adminUtilityBillingCatIDs, error1] = await safeAwait(
        this.utilocateAdminService.getUtilityBillingCategoryIDs(
          primaryUtilityBillingCatIDs
        )
      );

      const [adminUtilityBillingDetails, error6] = await safeAwait(
        this.utilocateAdminService.getUtilityBillingDetails(
          primaryUtilityBillingCatIDs
        )
      );

      const [primaryUtilityIDs, error2] = await safeAwait(
        this.utilocateCompletionService.queryTicketPrimaryUtilityIDs(primaryID, assignmentID)
      );

      if (!error1 && !error2 && !error6) {
        const whereClause: CacheWhereClause = {
          Column: "UtilityID",
          Value: primaryUtilityIDs,
          ValueType: CacheWhereClauseType.ARRAY,
        };
        const [tbAdmin_Utilities, error3] = await safeAwait(
          this.utilocateAdminService.queryTable(
            ADMIN_TABLE_NAMES.tbAdmin_Utilities,
            [whereClause]
          )
        );
        const [tbCompletions_AuxiliaryDetails, error4] = await safeAwait(
          this.utilocateCompletionService.queryTable(
            COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
            [
              {
                Column: "PrimaryID",
                Value: primaryID,
                ValueType: CacheWhereClauseType.NUMBER,
              },
            ],
            false,
            assignmentID
          )
        );

        if (!error3 && !error4) {
          const AuxDetailIDs = tbCompletions_AuxiliaryDetails.reduce(
            (total, row) => {
              total.push(row["AuxiliaryDetailID"]);
              return total;
            },
            []
          );
          const whereClause1: CacheWhereClause = {
            Column: "AuxiliaryDetailID",
            Value: AuxDetailIDs,
            ValueType: CacheWhereClauseType.ARRAY,
          };

          const [tbCompletions_Billing, error5] = await safeAwait(
            this.utilocateCompletionService.queryTable(
              COMPLETION_TABLE_NAMES.tbCompletions_Billing,
              [whereClause1],
              false,
              assignmentID
            )
          );

          const [tbCompletions_Primary, error6] = await safeAwait(
            this.utilocateCompletionService.queryTable(
              COMPLETION_TABLE_NAMES.tbCompletions_Primary,
              [],
              false,
              assignmentID
            )
          );

          if (!error5) {
            this.checkUnlocatableClicked(tbCompletions_Billing);
            const billingFormTemplate: FormTemplateViews = this.formTemplateService.convertBillingDetailsToFormTemplate(
              tbAdmin_Utilities,
              adminUtilityBillingCatIDs,
              adminUtilityBillingDetails,
              tbCompletions_AuxiliaryDetails,
              tbCompletions_Billing,
              tbCompletions_Primary,
              fieldsViewOnly
            );
            const billingViewsPromiseArr = [];

            const billingFormTemplateKeys = Object.keys(
              billingFormTemplate.views
            );
            for (let i = 0; i < billingFormTemplateKeys.length; i++) {
              const viewKey = billingFormTemplateKeys[i];
              const view: FormTemplateView = billingFormTemplate.views[viewKey];
              billingViewsPromiseArr.push(
                this.formTemplateService.createFormGroupsFromView(view)
              );
            }

            const billingViewsResult = await Promise.all(billingViewsPromiseArr);
            const billingViewsFinal = {};
            if (!(billingViewsResult instanceof Error)) {
              const billingPatchValue = tbCompletions_Billing.reduce(
                (total, row) => {
                  total[row["BillingID"]] = row["FieldValue"];
                  return total;
                },
                {}
              );

              for (const index in billingViewsResult) {
                const curView: FormTemplateView = billingViewsResult[index];
                curView.formGroup.patchValue(billingPatchValue);
                billingViewsFinal[curView.key] = curView;
              }
              return billingViewsFinal;
            }
          } else {
            throw new Error('Failed to get ticket billing information');
          }
        } else {
          throw new Error('Failed to get related aux and utility');
        }
      } else {
        throw new Error('Failed to get admin billing categories');
      }
    } else {
      throw new Error("Failed to get ticket's billing categories");
    }
    return [];
  }

  async createCompletionsPrimaryDetails(primaryID: string, assignmentID: string, fieldsViewOnly: boolean): Promise<any> {
    const [primaryUtilityIDs, error1] = await safeAwait(
      this.utilocateCompletionService.queryTicketPrimaryUtilityIDs(primaryID, assignmentID)
    );

    const whereClause: CacheWhereClause = {
      Column: "PrimaryID",
      Value: primaryID,
    };
    const [tbCompletions_PrimaryDetails, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
        [whereClause],
        false,
        assignmentID
      )
    );

    if (!error && !error1) {
      const whereClause1: CacheWhereClause = {
        Column: "UtilityID",
        Value: primaryUtilityIDs,
        ValueType: CacheWhereClauseType.ARRAY,
      };
      const [tbAdmin_Utilities, error2] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_Utilities,
          [whereClause1]
        )
      );

      if (!error2) {
        const adminUtilityTypes: [] = tbAdmin_Utilities.reduce((total, cur) => {
          total.push(cur["UtilityType"]);
          return total;
        }, []);
        const [adminPrimaryDetailCatIDs, error3] = await safeAwait(
          this.utilocateAdminService.getPrimaryDetailsCategoryIDs(
            adminUtilityTypes
          )
        );

        if (!error3) {
          const whereClause2: CacheWhereClause = {
            Column: "PrimaryDetailCategoryID",
            Value: adminPrimaryDetailCatIDs,
            ValueType: CacheWhereClauseType.ARRAY,
          };
          const [tbAdmin_PrimaryDetailsCategories, error4] = await safeAwait(
            this.utilocateAdminService.queryTable(
              ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailsCategories,
              [whereClause2]
            )
          );
          const [tbAdmin_PrimaryDetailFields, error5] = await safeAwait(
            this.utilocateAdminService.queryTable(
              ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailFields,
              [whereClause2]
            )
          );

          if (!error4 && !error5) {
            const primaryDetailFormTemplate: FormTemplateViews =
              this.formTemplateService.convertUtilityToFormTemplate(
                COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
                tbAdmin_Utilities,
                tbAdmin_PrimaryDetailsCategories,
                tbAdmin_PrimaryDetailFields,
                [],
                tbCompletions_PrimaryDetails,
                fieldsViewOnly
              );

            const primaryDetailsViewsPromiseArr = [];
            const primaryDetailsFormTemplateViewKeys = Object.keys(
              primaryDetailFormTemplate.views
            );
            for (
              let i = 0;
              i < primaryDetailsFormTemplateViewKeys.length;
              i++
            ) {
              const viewKey = primaryDetailsFormTemplateViewKeys[i];
              
              const view: FormTemplateView =
                primaryDetailFormTemplate.views[viewKey];
              primaryDetailsViewsPromiseArr.push(
                this.formTemplateService.createFormGroupsFromView(view)
              );
            }

            const primaryDetailsViewsResult = await Promise.all(
              primaryDetailsViewsPromiseArr
            );
            const formValueDataType = primaryDetailsViewsResult.find(
              (row) => row["key"] == 11
            );
          
            
            const primaryDetailViewsFinal = {};
            if (!(primaryDetailsViewsResult instanceof Error)) {
              let fieldsArray = []
              if (primaryDetailsViewsResult.length > 0) {
              const groups = primaryDetailsViewsResult[0].groups
              const firstGroupKey = Object.keys(groups)[0];
              fieldsArray = groups[firstGroupKey].fields; 
            }
              const primaryPatchValue = tbCompletions_PrimaryDetails.reduce(
                (total, row) => {
                  
                  if (
                    row["FieldValue"] == null ||
                    row["FieldValue"] == "0" ||
                    row["FieldValue"] == "" 
                  ) {
                    total[row["PrimaryDetailsFieldID"]] = "";
                  }  else if (row["FieldValue"] == "1") {
                    if(total[row["PrimaryDetailsFieldID"]] && fieldsArray[total[row["PrimaryDetailsFieldID"]]].inputTypeID != 2){
                      total[row["PrimaryDetailsFieldID"]] = true;
                    }
                    else{
                      total[row["PrimaryDetailsFieldID"]] = row["FieldValue"]
                    }
                  } else {
                    total[row["PrimaryDetailsFieldID"]] = row["FieldValue"];
                  }
                  return total;
                },
                {}
              );

              for (const index in primaryDetailsViewsResult) {
                const curView: FormTemplateView =
                  primaryDetailsViewsResult[index];
                curView.formGroup.patchValue(primaryPatchValue);
                primaryDetailViewsFinal[curView.key] = curView;
              }

              return primaryDetailViewsFinal;
            }
          } else {
            throw new Error('Failed to gather admin primary details');
          }
        } else {
          throw new Error('Failed to gather admin primary category IDs');
        }
      } else {
        throw new Error('Failed to gather admin utility information');
      }
    } else {
      throw new Error("Failed to gather ticket's utility IDs");
    }
  }


  async getUtilityIDsFromAux(primaryID: string, assignmentID: string) {
    //auxData should be tbCompletions_AuxiliaryDetails.Data
    const response = [];
    const [auxData, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails, [], false, assignmentID)
    );
    try {
      if (error) throw new Error(error);
      if (auxData && auxData.length > 0) {
        auxData.forEach((auxRow) => {
          if (auxRow["PrimaryID"] == primaryID) {
            response.push(auxRow.UtilityID);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  async getLSPs(assignmentID: string, primaryID: string) {
    let lsps = {};
    
    try {
      const utilityIDs = await this.getUtilityIDsFromAux(primaryID, assignmentID);
      if(utilityIDs.length > 0){

      
      const apiKey = apiKeys.u2.LSPController;
      const apiValue = {
        query: {
          AssignmentID: assignmentID,
          PrimaryID: primaryID,
          utilityIDs: utilityIDs,
        },
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      // lsps = { "utilityLSPs": [{ 'LSPID': '1', 'Name': 'PVS' }, { 'LSPID': '2', 'Name': 'UnderPressure' }] }
      const apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      if (apiResult["body"] && apiResult["body"]["result"]) {
        lsps = apiResult["body"]["result"];
      }
    }
    } catch (error) {
      console.error(error);
    }
    return lsps;
  }

  async getAllLSPs() {
    let lsps = {};
    try {
      const apiKey = apiKeys.u2.LSPController;
      const apiValue = {
        query: {
          getAll: true
        },
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      const apiResult =
        await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);        
      if (apiResult["body"] && apiResult["body"]["result"]) {
        lsps = apiResult["body"]["result"];
      }
    } catch (error) {
      console.error(error);
    }
    return lsps;
  }


  async getActiveUtilities(primaryID: string, assignmentID: string): Promise<any> {
    return await this.getUtilityIDsFromAux(primaryID, assignmentID);
  }

  async createCompletionsCommonDetails(primaryID: string, assignmentID: string, fieldsViewOnly: boolean): Promise<any> {
    const tbAdmin_Utilities = [
      {
        Archived: 0,
        CompanyID: null,
        FooterText: '',
        HeaderText: '',
        LocatingForGroupID: 0,
        LogoFile: '',
        UtilityDesc: 'Ticket Details',
        UtilityID: 0,
        UtilityName: 'Ticket Details',
        UtilityType: 0,
        usesPrimary: 0,
      },
    ];

    const [primaryDetailCatIDs, error2] = await safeAwait(
      this.utilocateAdminService.getPrimaryDetailsCategoryIDs([0])
    );
    if (!error2) {
      const primaryDetailsFieldRowsWC: CacheWhereClause = {
        Column: "PrimaryDetailCategoryID",
        Value: primaryDetailCatIDs,
        ValueType: CacheWhereClauseType.ARRAY,
      };
      const [tbAdmin_PrimaryDetailsCategories, adminPrimaryCatError] =
        await safeAwait(
          this.utilocateAdminService.queryTable(
            ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailsCategories,
            [primaryDetailsFieldRowsWC]
          )
        );
      const [tbAdmin_PrimaryDetailFields, adminPrimaryDetailError] =
        await safeAwait(
          this.utilocateAdminService.queryTable(
            ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailFields,
            [primaryDetailsFieldRowsWC]
          )
        );
      const [tbCompletions_CommonDetails, error] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_CommonDetails,
          [
            {
              Column: "PrimaryID",
              Value: primaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          false,
          assignmentID
        )
      );

      if (!adminPrimaryCatError && !adminPrimaryDetailError) {
        const commonDetailFormTemplate: FormTemplateViews =
          this.formTemplateService.convertUtilityToFormTemplate(
            COMPLETION_TABLE_NAMES.tbCompletions_CommonDetails,
            tbAdmin_Utilities,
            tbAdmin_PrimaryDetailsCategories,
            tbAdmin_PrimaryDetailFields,
            [],
            tbCompletions_CommonDetails,
            fieldsViewOnly
          );

        const commonDetailsViewsPromiseArr = [];
        const commonDetailFormTemplateViewKeys = Object.keys(
          commonDetailFormTemplate.views
        );

        for (let i = 0; i < commonDetailFormTemplateViewKeys.length; i++) {
          const viewKey = commonDetailFormTemplateViewKeys[i];
          const view: FormTemplateView = commonDetailFormTemplate.views[viewKey];
          commonDetailsViewsPromiseArr.push(
            this.formTemplateService.createFormGroupsFromView(view)
          );
        }

        const commonDetailsViewsResult = await Promise.all(
          commonDetailsViewsPromiseArr
        );

        const commonDetailViewsFinal = {};
        if (!(commonDetailsViewsResult instanceof Error)) {
          const commonPatchValue = tbCompletions_CommonDetails.reduce(
            (total, row) => {
              if (
                row["FieldValue"] == null ||
                row["FieldValue"] == "0" ||
                row["FieldValue"] == ""
              ) {
                total[row["CommonDetailsFieldID"]] = "";
              } else if (row["FieldValue"] == "1") {
                total[row["CommonDetailsFieldID"]] = true;
              } else {
                total[row["CommonDetailsFieldID"]] = row["FieldValue"];
              }
              return total;
            },
            {}
          );

          for (const index in commonDetailsViewsResult) {
            const curView: FormTemplateView = commonDetailsViewsResult[index];
            curView.formGroup.patchValue(commonPatchValue);
            commonDetailViewsFinal[curView.key] = curView;
          }

          return commonDetailViewsFinal;
        }
      } else {
        throw new Error('Cannot get PrimaryDetails admin information');
      }
    } else {
      throw new Error('Cannot get PrimaryDetails admin information');
    }
  }

  //! verify
  async verifyTicketBeforeComplete(primaryID: string, assignmentID: string) {
    let photoError = false;
    const [tbCompletions_AuxiliaryDetails, error] = await safeAwait(
      this.utilocateCompletionService.queryTable(
        COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
        [
          {
            Column: "PrimaryID",
            Value: primaryID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ],
        false,
        assignmentID
      )
    );

    if (this.userService.isSettingActive(SettingID.PHOTO_REQUIRED)) {
      const [docs, downloadedDocs] = await this.getTicketDocumentRows(assignmentID);
      const [s3Docs, downloadedS3Docs] = await this.getTicketS3DocumentRows(assignmentID);

      //get the indicies of the docs
      const keys = Object.keys(docs);
      const s3Keys = Object.keys(s3Docs);

      //if the length of either docs or s3 docs is greater than 0, we have a photo
      if (keys.length == 0 && s3Keys.length == 0) {
        photoError = true;
      }
    }

    if (!error) {
      const AuxiliaryDetailsUtilityIDs = [];
      for (let i = 0; i < tbCompletions_AuxiliaryDetails.length; i++) {
        const AuxiliaryDetailRow = tbCompletions_AuxiliaryDetails[i];
        const { UtilityID } = AuxiliaryDetailRow;
        AuxiliaryDetailsUtilityIDs.push(UtilityID);
      }

      const [tbAdmin_Utilities, error1] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_Utilities,
          [
            {
              Column: "UtilityID",
              Value: AuxiliaryDetailsUtilityIDs,
              ValueType: CacheWhereClauseType.ARRAY,
            },
          ]
        )
      )

      if (!error1) {
        const [assignmentRow, error2] = await safeAwait(
          this.utilocateCompletionService.queryTableDataByColumns(
            COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
            ['CallTypeID'],
            [
              {
                Column: 'AssignmentID',
                Value: assignmentID,
                ValueType: CacheWhereClauseType.NUMBER,
              },
            ],
            assignmentID
          )
        );

        if (!error2) {
          const { CallTypeID } = assignmentRow[0];
          if (CallTypeID >= "2000") {
            const verifyResult =
              await this.utilocateCompletionService.verifyDamageInvestigationBeforeComplete(
                primaryID,
                tbAdmin_Utilities[0]["UtilityID"]
              );

            return ["For Damage Investigation Ticket", verifyResult];
          } else if (CallTypeID >= "1000") {
            const verifyResult: VerifyDetails[] | Error =
              await this.utilocateCompletionService.verifyAuditTicketBeforeComplete(
                primaryID,
                tbAdmin_Utilities[0]['UtilityID'],
                assignmentID
              );

            return ['For Audit Ticket', verifyResult];
          } else {
            const verifyResult: VerifyDetails[] | Error =
              await this.utilocateCompletionService.verifyTicketBeforeComplete(
                primaryID,
                tbAdmin_Utilities,
                assignmentID
              );

            if (Array.isArray(verifyResult) && photoError) {
              const newVerifyDetails: VerifyDetails = new VerifyDetails(
                VerifyDetailsType.Primary,
                {
                  [primaryID]: "Photo is required",
                }
              );
              verifyResult.push(newVerifyDetails);
            }

            return ['For Standard Ticket', verifyResult];
          }
        }
      }
    }
  }

  async markTicketAsComplete(assignmentID: string, locateStatusID: string, autolog?: string) {
    this.insertTicketChangedToIDB(assignmentID);
    if (LocateStatusID.LOCATE_COMPLETED.toString() == locateStatusID) {
      return this.utilocateCompletionService.markTicketAsLocateStatusID(assignmentID, LocateStatusID.LOCATE_COMPLETED);
    } else if (LocateStatusID.LOCATE_ON_GOING.toString() == locateStatusID) {
      return this.utilocateCompletionService.markTicketAsLocateStatusID(assignmentID, LocateStatusID.LOCATE_ON_GOING);
    } else if (LocateStatusID.LOCATE_UNLOCATABLE.toString() == locateStatusID) {
      return this.utilocateCompletionService.markTicketAsLocateStatusID(
        assignmentID,
        LocateStatusID.LOCATE_UNLOCATABLE,
        autolog
      );
    } else if (LocateStatusID.LOCATE_UNLOCATABLE_VERIFIED.toString() == locateStatusID) {
      return this.utilocateCompletionService.markTicketAsLocateStatusID(
        assignmentID,
        LocateStatusID.LOCATE_UNLOCATABLE_VERIFIED,
        autolog
      );
    } else if (LocateStatusID.ASSISTANCE_NEEDED.toString() == locateStatusID) {
      return this.utilocateCompletionService.markTicketAsAssistanceNeeded(assignmentID, autolog);
    }
  }

  async handleTakeAuxScreenshot(file: string) {
    const userID = this.auth$.getNestedValueFromPayload('USERID');
    const clientID = this.auth$.getNestedValueFromPayload('CLIENTID');
    const AssignmentID = sessionStorage.getItem('AssignmentID');
    const filename =
      AssignmentID +
      ' - ' +
      this.datetimeService.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-SSS') +
      '.jpg';
    const zipname =
      this.datetimeService.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-S') +
      '_' +
      userID +
      '_' +
      AssignmentID +
      '.zip';

    const screenshot = this.dataURLtoFile(file, filename);

    this.progressBarService.start();

    const date = this.datetimeService.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: AssignmentID.toString(),
      CreationDate: date,
      Description: 'Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '',
      isSendable: '' + 0,
    };

    await this.autolog$.uploadAutolog(clientID, AssignmentID, userID, 4, 'Added new document');

    const addDocResult = await this.downloadDoc$.uploadAuxiliaryImage(
      screenshot,
      zipname,
      metadata,
      clientID,
      AssignmentID
    );

    if (addDocResult) {
      this.snackbarService$.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackbarService$.openSnackbar('Failed to upload', SnackbarType.error);
    }

    this.progressBarService.stop();
  }

  async handleAuxDocument(file) {
    const userID = this.auth$.getNestedValueFromPayload('USERID');
    const clientID = this.auth$.getNestedValueFromPayload('CLIENTID');
    const AssignmentID = sessionStorage.getItem('AssignmentID');
    const filename =
     file.name;
     const zipname =
     AssignmentID +
     '_' +
     file.name+
     '.zip';

      const fileReader = new FileReader();
      let dataUrl
      const fileReadPromise = new Promise<void>((resolve, reject) => {
        fileReader.onload = (event) => {
          dataUrl = event.target.result;
          resolve(); // Resolve the Promise once the data URL is obtained
        };

        fileReader.onerror = (error) => {
          reject(error); // Reject the Promise if there's an error reading the file
        };
      });
      fileReader.readAsDataURL(file);
      await fileReadPromise;

    
    this.progressBarService.start();

    const date = this.datetimeService.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: AssignmentID.toString(),
      CreationDate: date,
      Description: 'Aux Document from U4',
      FileName: filename,
      DocumentTypeID: '106',
      RequestNumber: '',
      isSendable: '' + 0,
    };

    const newDocID = Math.floor(1000 + Math.random() * 9000);
    const isS3Document = false
    this.ticketDocumentService.updateDocList({
      CreationDate: date,
      DocumentID: newDocID,
      DocumentTypeID: '106',
      FileName: file.name,
      bFieldAdded: 1,
      file: dataUrl,
      isDownloaded: true,
      S3DocumentID: isS3Document ? newDocID : null,
      isS3Document: isS3Document,
      isSendable: false,
      selected: false,
    });

    await this.autolog$.uploadAutolog(clientID, AssignmentID, userID, 4, 'Added new document');

    const addDocResult = await this.downloadDoc$.uploadAuxiliaryImage(
      file,
      zipname,
      metadata,
      clientID,
      AssignmentID
    );

    if (addDocResult) {
      this.snackbarService$.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackbarService$.openSnackbar('Failed to upload', SnackbarType.error);
    }

    this.progressBarService.stop();
  }

  private dataURLtoFile(dataUrl, fileName) {
    // eslint-disable-next-line prefer-const
    let arr = dataUrl.split(","),
      // eslint-disable-next-line prefer-const
      mime = arr[0].match(/:(.*?);/)[1],
      // eslint-disable-next-line prefer-const
      bstr = atob(arr[1]),
      n = bstr.length,
      // eslint-disable-next-line prefer-const
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  checkUnlocatableClicked(tbCompletions_Billing) {

    const unlocatableArray = tbCompletions_Billing.filter(obj => obj.UtilityBillingCatID === 36);
    const unlocatableVerifiedArray = tbCompletions_Billing.filter(obj => obj.UtilityBillingCatID === 37);

    const unlocatable = unlocatableArray.some(obj => obj.FieldValue === 1);
    const unlocatableVerified = unlocatableVerifiedArray.some(obj => obj.FieldValue === 1);

    this.unlocatableClicked = { unlocatableVerified, unlocatable }
  }

  getUnlocatableValue() {
    return this.unlocatableClicked;
  }

  async checkUnlocatbaleVerifyAllowed() {
    const AssignmentID = sessionStorage.getItem("AssignmentID");
    const unlocatableSetting = this.userService.isSettingActive(
      SettingID.ALLOW_COMPLETE_UNLOCATABLE_VERIFY
    );
    const ticketTags = await this.getTicketTags(AssignmentID);
    let unlocatableFound = false;
    for (const i of ticketTags) {
      if (i.value == "Unlocatable") {
        unlocatableFound = true;
      }
    }
    if (unlocatableFound && !unlocatableSetting) {
      return true;
    }
    return false;
  }

  getMappingServiceSetting(): boolean {
    return this.userService.isSettingActive(SettingID.TICKET_MAP_SERVICE_OPENLAYERS);
  }

  get ticket(): BehaviorSubject<Partial<Ticket>> {
    return this.ticket$;
  }

  async completeTicketAs(
    locateStatusID: number,
    assignmentID: string,
    userID: string,
  ) {
    try {
      //get tbCompletions_Assignments
      // eslint-disable-next-line prefer-const
      let [completionsAssignments, error_completionsAssignments] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
          [
            {
              Column: "AssignmentID",
              Value: assignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          false,
          assignmentID
        )
      );
      if (error_completionsAssignments) throw error_completionsAssignments;
      if (completionsAssignments.length == 1) completionsAssignments = completionsAssignments[0];


      // eslint-disable-next-line prefer-const
      let [completionsPrimary, error_completionsPrimary] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_Primary,
          [
            {
              Column: "AssignmentID",
              Value: assignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          false,
          assignmentID
        )
      );
      if (error_completionsPrimary) throw error_completionsPrimary;
      if (completionsPrimary.length == 1) completionsPrimary = completionsPrimary[0];


      completionsAssignments["LocateStatusID"] = locateStatusID;
      await this.updateTicketDetails({ LocateStatusID: locateStatusID }, assignmentID);

      if (locateStatusID != LocateStatusID.OFFICE_CANCELLED) {
        await this.updateTicketDetails(
          {
            CompletingLocatorID: userID,
            DateComplete: this.datetimeService.localDateToDBDateStr(new Date()),
            TimeIn: this.datetimeService.localDateToDBDateStr(new Date()),
            TimeOut: this.datetimeService.localDateToDBDateStr(new Date()),
            Archived: 1
          },
          assignmentID);

        const autologExplaination: string =
          "Ticket completed using U4 Web App";
        const autologDescID: any = AutologID.MarkedAsCompleted;
        const autolog = new AutologRow(
          assignmentID,
          userID,
          autologDescID,
          autologExplaination,
          this.datetimeService.localDateToDBDateStr(new Date())
        );
        await this.addAutologToAssignment(autolog, assignmentID);
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }


  /**
   * Inserts an autolog directly to the server 
   * @param autolog 
   * @returns 
   */
  async insertAutologToServer(autolog: AutologRow) {
    try {
      let url = "/api/upload/autolog";
      if (!environment.localhost) {
        url = "/nodejs/api/upload/autolog";
      }
      const ClientID = this.userService.getUserValueFromToken(
        UtilocateTokenPaths.CLIENTID,
      );
      let isLive = false;
      if (environment.production == true) {
        isLive = true;
      }
      const body = {
        ClientID: ClientID,
        isLive: isLive,
        AssignmentID: autolog.AssignmentID,
        UserID: autolog.UserID,
        DescID: autolog.DescID,
        Explanation: autolog.Explaination,
      };

      const result = await this.baseApiService.invokeApi("PUT", url, body);
      return result;
    } catch (error) {
      console.error(error);
    }
  }


  /**
   * Uncompletes a ticket based off input value
   * @param apiValue 
   * @returns 
   */
  unCompleteTicket(apiValue: { [key: string]: { Check: boolean; }; }, assignmentID: string, U2UserID: string) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKeys.u2.uncompleteTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.uncompleteTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest),
        ).subscribe(async (response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
            const uncompleteAutolog = new AutologRow(
              assignmentID,
              U2UserID,
              AutologID.UnCompleted,
              "Uncompleted from U4 Web App",
              this.datetimeService.localDateToDBDateStr(new Date())
            );
            //insert autolog to the local tables 
            await this.insertAutologToServer(uncompleteAutolog);
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (error) {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  cancelTicket(apiValue): Observable<any> {
    return new Observable((subscriber) => {
      try {
        // this.alert$.start();
        const url = apiKeys.u2[apiKeys.u2.cancelTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.cancelTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(
          this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest),
        ).subscribe((response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
          } else {
            subscriber.next(false);
          }
          // this.alert$.stop();
          subscriber.complete();
        });
      } catch (error) {
        subscriber.next(false);
        // this.alert$.stop();
        subscriber.complete();
      }
    });
  }
}

export type Ticket = {
  SubNum: number;
  AssignmentID: number;
  AssignmentType: number;
  CallCenterID: number;
  CallCenter: string;
  RequestNumber: number;
  SubRequestNumber: number;
  UpdateOf: number;
  CurrentLocatorID: number;
  AreaID: number;
  EntryDate: string;
  CallDate: string;
  TransmitDate: string;
  ExcavationDate: string;
  OriginalExcavationDate: string;
  EstimateDuration: string;
  AppointmentArea: number;
  AppointmentDate: string;
  CallTypeID: number;
  LocateTypeID: number;
  LocateStatusID: number;
  SubStatusID: number;
  RegionID: number;
  RegionDesc: string
  SubRegionID: number;
  LocateSubRegionName: string;
  LocateSubDivision: string;
  Township: string;
  StartHouseNumber: string;
  EndHouseNumber: string;
  LocateAddress: string;
  UnitLot: string;
  Lot: string;
  Blk: string;
  Plan: string;
  ProjectNum: string;
  PermitNum: string;
  PONum: string;
  JobNumber: string;
  CostCode: string;
  Metrolinx: string;
  LocateCrossStreet: string;
  LocateSecondCrossStreet: string;
  Mileage: string;
  QTR_LSD: string;
  Latitude: string;
  Longitude: string;
  Latitude2: string;
  Longitude2: string;
  TypeOfWork: string;
  WorkDoneFor: string;
  DepthOfWork: number;
  LengthOfWork: string;
  WidthOfWork: string;
  DigArea: number;
  UrbanRural: number;
  ExtentOfWork: string;
  DispatcherRemarks: string;
  ExcavatorID: number;
  CustomerID: number;
  CallerName: string;
  AlternateContact: string;
  MapReference: string;
  InternalMapRef: string;
  RequesterTypeID: number;
  PhoneNumber: string;
  PhoneExtension: string;
  AlternativeNumber: string;
  CallBackTime: string;
  CellNumber: string;
  FaxNumber: string;
  email: string;
  email2: string;
  AcceptedBy: string;
  bCancelledCall: number;
  bMarked: number;
  bLighting: number;
  bNoHydro: number;
  bPublicProperty: number;
  bPrivateProperty: number;
  bAreaNotMarked: number;
  bMarkandFax: number;
  bDirectionalDrilling: number;
  bSiteMeetRequired: number;
  bPremarked: number;
  bMachineDig: number;
  bHandDig: number;
  bPrivateResidential: number;
  bPrivateCommercial: number;
  bVacantLot: number;
  bPremarkedPaint: number;
  bPremarkedStake: number;
  bPremarkedFlags: number;
  bRestrictedAccess: number;
  bBlasting: number;
  bBoring: number;
  bRailroad: number;
  bRevisionDenied: number;
  bVacuumGreatThan1500: number;
  bVacuumLessThan1500: number;
  UStatMemo: string;
  MeetLocation: string;
  NoSegments: number;
  Remarks: string;
  AutoLoggedComments: string;
  PrimaryReportTypeID: number;
  AuxiliaryReportTypeID: number;
  isMaster: number;
  ThirdPartyID: number;
  RevisionNumber: number;
  RouteOrder?: number;
};

export type TicketPrimary = {
  PrimaryID:              number;
  AssignmentID:           number;
  UserID:                 number;
  CompletingLocatorID:    null;
  DateCompleted:          null;
  TimeIn:                 null;
  TimeOut:                null;
  From1:                  string;
  To1:                    string;
  From2:                  string;
  To2:                    string;
  ExcavatorCopyStatusID:  number;
  CommentsToExcavator:    null;
  CommentsToDispatch:     string;
  AutoLoggedComments:     string;
  bDigComplete:           number;
  Archived:               number;
  bFieldAdded:            number;
  AtlasPlates:            null;
  FieldNotes:             null;
  Other:                  null;
  DiameterOfMain:         null;
  DatapackNum:            null;
  NetworkXNum:            null;
  DigAreaAlteredPerson:   string;
  DigAreaAlteredCheckbox: number;
  NumUtilities:           number;
  bTentativeCompletion:   number;
  PrimaryJobNumber:       string;
}
