export enum CreateTicketMessageActions {
  UPDATE_CONTENT_VIEW,
  SWITCH_CONTENT_VIEW,
  CREATE_TICKET,
  INPUT_SETUP_CREATED,
  POPULATE_VIEW,
  ADDRESS_UPDATED,
  ASSIGNMENT_CREATED,
  DATA_RESET,
  SWITCH_PANEL,
  EXCAVATOR_NAME_UPDATED,
  QUICK_EXCAVATOR_CONTACTS_UPDATE,
}
