import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import { FieldsideService } from "src/app/routes/fieldside/fieldside.service";

@Component({
  selector: "app-ticket-list-item",
  templateUrl: "./ticket-list-item.component.html",
  styleUrls: ["./ticket-list-item.component.scss"],
})
export class TicketListItemComponent implements OnInit {
  @Input() AssignmentID: string;
  @Input() Address: string;
  @Input() City: string;
  @Input() TypeOfWork: string;
  @Input() RequestNumber: string;
  @Input() NumberOfDocs: string;
  @Input() DistanceToSite: string;
  @Input() DigDate: string;
  @Input() PrimaryID: string;
  @Input() LocateStatusID: string;
  @Input() CallTypeID: string;
  @Input() LateColor: string;
  @Input() Proximity: any;
  @Input() Index: string;
  @Input() CallTypeDesc: any;
  @Input() IsSelected: boolean = false;




  @Output() ticketListItemClick = new EventEmitter<any>();

  timeFormatted: any;
  dig: any;
  prox: any = "0";
  constructor(private datetimeService: DatetimeService, private fieldsideService: FieldsideService,) { }

  ngOnInit() {
    this.getProximity()
  }

  getProximity() {
    this.fieldsideService.calculateProximity(this.Proximity.lat, this.Proximity.long)
      .then(distance => {
        this.prox = distance ?? "";
      })
      .catch(error => {
        console.error(error.message);
      });
  }


  @HostListener("click") onClick() {
    this.ticketListItemClick.emit({
      PrimaryID: this.PrimaryID,
      AssignmentID: this.AssignmentID,
      RequestNumber: this.RequestNumber,
    });
  }


}
