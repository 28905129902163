import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum BreakpointType {
  none = 0,
  xs = 1,
  sm = 2,
  md = 3,
  lg = 4,
  xl = 5,
}

@Injectable({
  providedIn: "root",
})
export class BreakpointService implements OnDestroy {
  breakpointMap = new Map([
    [Breakpoints.XSmall, BreakpointType.xs],
    [Breakpoints.Small, BreakpointType.sm],
    [Breakpoints.Medium, BreakpointType.md],
    [Breakpoints.Large, BreakpointType.lg],
    [Breakpoints.XLarge, BreakpointType.xl],
  ]);

  private subject = new BehaviorSubject<BreakpointType>(BreakpointType.none);
  destroyed = new Subject<void>();
  currentScreenSize: BreakpointType;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.breakpointMap.get(query) ?? BreakpointType.none;
            this.subject.next(this.currentScreenSize);
          }
        }
      });
  }

  breakpointChanges(): Observable<BreakpointType> {
    return this.subject.asObservable();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
