import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GraphicInfo } from 'src/app/modules/core/cache/GraphicsInterface';
import { UtilocateAdminCacheService } from 'src/app/modules/core/cache/utilocate-admin.service';
import { DocumentService } from 'src/app/modules/core/services/document/document.service';
import { NotificationService } from 'src/app/modules/core/services/logger/notification.service';
import { MapService } from 'src/app/modules/core/services/map/map.service';
import { EsriMapSimpleDrawingComponent } from '../../esri-map/esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component';
import { LayerOptions, MapOptions } from '../../esri-map/esri-map-set/EsriMapModels';
import { FAMItem } from '../../fab/floating-action-menu/floating-action-menu.component';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';
import { U4GoogleMapsDrawingComponent } from '../google-maps/google-maps-drawing.components';
import { MarkerObject } from '../models/google-maps/google-maps';
import { GoogleMapsDrawingComponent } from '../../google-map/google-maps-set/google-maps-set.component';
import { googleMapsOptions } from '../../google-map/google-maps-set/googleMapsOptions';
import {
  agmMapControlPosition as position,
  agmMapTypeControlStyle as style,
} from '../../google-map/google-maps-set/agmOptions';
import { AuthenticationService } from 'src/app/modules/core/authentication/authentication.service';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { EsriMapService } from '../../esri-map/esri-map.service';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { DownloadDocumentService } from 'src/app/modules/core/services/document/download-document.service';
import { UploadAutologService } from 'src/app/modules/core/services/logger/upload-autolog.service';
import { Ticket } from '../ticket.service';

export interface TicketMapObject {
  Latitude: number;
  Longitude: number;
  Address: string;
  EsriAvailable: boolean;
  EsriFirst: boolean;
  RequestNumber: number;
  CallCenterID: number;
  AssignmentID: string;
}

@Component({
  selector: 'app-ticket-map-viewer',
  templateUrl: './ticket-map-viewer.component.html',
  styleUrls: ['./ticket-map-viewer.component.scss'],
})
export class TicketMapViewerComponent implements OnInit, OnChanges {
  readonly DEFAULT_ZOOM: number = 18;

  @Input() TicketMap: TicketMapObject;
  @Input() layerOptions: LayerOptions;
  @Output() Swipe = new EventEmitter<any>();
  @Output() mapControlEnabled = new EventEmitter<any>();
  @Output() documentUpload = new EventEmitter<any>();
  @Output() OpenGoogleMapsDirections = new EventEmitter<any>();

  // Esri Maps
  @ViewChild('esriDrawing') esriDrawing: EsriMapSimpleDrawingComponent;

  mapOptions: MapOptions = {
    zoom: this.DEFAULT_ZOOM,
    center: null,
  };

  // Google Maps
  @ViewChild('cmpGoogleMapsDrawing') googleMaps: GoogleMapsDrawingComponent;
  googleMapsProps: {} = {};
  options: googleMapsOptions = {
    zoomControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: style.DROPDOWN_MENU,
      position: position.TOP_RIGHT,
    },
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
    draggable: false,
  };

  // Shared
  markers: MarkerObject[];
  graphics: GraphicInfo[];

  // Menus
  googleMapsFab: FAMItem[];
  esriMapFab: FAMItem[];

  // Other
  isEsriVisibile: boolean = false;
  bottomMenuOpen: boolean = false;

  identifyClicked: boolean = false
  esriAvailable: boolean = false;
  esriFirst: boolean = false;

  viewingAddress: boolean = false;
  ticketLatLng: MarkerObject;
  ticketAddressLatLng: MarkerObject;

  mapControlsEnabled: boolean = false;

  constructor(
    private alert$: NotificationService,
    private esriMap$: MapService,
    private admin$: UtilocateAdminCacheService,
    private snackbarService$: SnackbarService,
    private documentService: DocumentService,
    public esriMap: EsriMapService,
    private progressBarService: ProgressBarService,
    private auth$: AuthenticationService,
    private datetime$: DatetimeService,
    private downloadDoc$: DownloadDocumentService,
    private autolog$: UploadAutologService,
    private map$: MapService
  ) {}

  ngOnInit() {
    this.esriMapFab = [
      {
        label: 'Enable Map Controls',
        icon: 'zoom_out_map',
        action: this.enableMapControls,
      },
      {
        label: 'Take Screenshot',
        icon: 'photo_camera',
        action: this.takeScreenshot,
      },
      {
        label: 'Take Auxiliary Screenshot',
        icon: 'photo_camera',
        action: this.takeAuxiliaryScreenshot,
      },
    ];

    this.googleMapsFab = [
      {
        label: 'Enable Map Controls',
        icon: 'zoom_out_map',
        action: this.enableMapControls,
      },
      {
        label: 'Get Directions',
        icon: 'directions',
        action: this.getDirections,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (changes.TicketMap && changes.TicketMap.currentValue) {
      this.esriAvailable = this.TicketMap.EsriAvailable;
      this.esriFirst = this.TicketMap.EsriFirst;
      this.isEsriVisibile = this.esriAvailable && this.esriFirst;

      this.getDigsites();
      this.setupLocation();
    }
  }

  async getDigsites() {
    this.graphics = await this.admin$.getTicketDigsites(this.TicketMap.RequestNumber, this.TicketMap.CallCenterID);
    if(this.graphics.length == 0 || this.graphics == null){
    
      this.map$.gatherDigsites().subscribe((results) => {
        if (results && results['value']) {
          const digsites = JSON.parse(results['value']);
          const graphics: GraphicInfo[] = [];
  
          digsites.DigsiteData.forEach((digsite) => {
            const newGraphic: GraphicInfo = {
              graphicID: digsite['DigsiteShapeID'],
              type: digsite['DigsiteTypeID'],
              coordinates: JSON.parse(digsite['Coordinates']),
            };
            graphics.push(newGraphic);
          });
          this.graphics = graphics;
        }
      });
    }
  }


  async setupLocation() {
    if (this.TicketMap.Latitude && this.TicketMap.Longitude && this.TicketMap.Address) {
      // set the ticket lat/lng var
      this.ticketLatLng = {
        position: {
          lat: this.TicketMap.Latitude,
          lng: this.TicketMap.Longitude,
        },
      };
      // set the esri map center
      this.mapOptions = {
        ...this.mapOptions,
        center: [this.TicketMap.Longitude, this.TicketMap.Latitude],
      };
      // set the google map center
      this.googleMapsProps['TicketLatLng'] = {
        Latitude: this.TicketMap.Latitude,
        Longitude: this.TicketMap.Longitude,
      };
      this.googleMapsProps = { ...this.googleMapsProps };
      // set the marker
      this.markers = [this.ticketLatLng];

      // get the lat/lng for the address
      let addressLatLng: any = null;
      try {
        addressLatLng = await this.getLatLngFromAddress({
          address: this.TicketMap.Address,
        });
      } catch (error) {
        console.error('Failed to ge the lat long from the address: ', this.TicketMap.Address);
      }

      if (addressLatLng && addressLatLng.lat && addressLatLng.lng) {
        this.ticketAddressLatLng = {
          position: {
            lat: addressLatLng.lat,
            lng: addressLatLng.lng,
          },
        };
      } else {
        this.ticketAddressLatLng = this.ticketLatLng;
      }
    } else {
      // if the lat/lng and address not available then zoom to current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.mapOptions = {
            ...this.mapOptions,
            center: [position.coords.longitude, position.coords.latitude],
          };
          this.googleMapsProps['TicketLatLng'] = {
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude,
          };
          this.googleMapsProps = { ...this.googleMapsProps };
          this.snackbarService$.openSnackbar('Failed to get Ticket Location', SnackbarType.error);
        },
        (error) => {
          this.snackbarService$.openSnackbar('Failed to get Ticket Location and Current Location', SnackbarType.error);
        }
      );
    }
  }

  getLatLngFromAddress(address) {
    return new Promise((resolve, reject) => {
      try {
        if (address) {
          let geocoder = new google.maps.Geocoder();
          geocoder.geocode(address, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
              resolve({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              });
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async handleTakeScreenshot() {
    let fileName =
      this.TicketMap.AssignmentID + ' - ' + this.documentService.formatDateForFileName(new Date()) + '.jpg';
    let result = await this.esriDrawing.takeScreenshot();
    var file = this.dataURLtoFile(result.dataUrl, fileName);

    this.documentUpload.emit([{ file: file, documentTypeID: 4 }]);
  }

  async handleTakeAuxScreenshot() {
    let userID = this.auth$.getNestedValueFromPayload('USERID');
    let clientID = this.auth$.getNestedValueFromPayload('CLIENTID');

    let filename =
      this.TicketMap.AssignmentID +
      ' - ' +
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-SSS') +
      '.jpg';
    let zipname =
      this.datetime$.localDateToFormattedString(new Date(), 'yyyy-MM-dd-HH-mm-ss-S') +
      '_' +
      userID +
      '_' +
      this.TicketMap.AssignmentID +
      '.zip';

    let result = await this.esriDrawing.takeScreenshot();
    let screenshot = this.dataURLtoFile(result.dataUrl, filename);

    this.progressBarService.start();

    let date = this.datetime$.localDateToDBDateStr(new Date());

    // s3 object metadata
    const metadata = {
      AssignmentID: this.TicketMap.AssignmentID.toString(),
      CreationDate: date,
      Description: 'ESRI Maps Screenshot from U4',
      FileName: filename,
      DocumentTypeID: '4',
      RequestNumber: '',
      isSendable: '' + 0,
    };

    await this.autolog$.uploadAutolog(clientID, this.TicketMap.AssignmentID, userID, 4, 'Added new document');

    let addDocResult = await this.downloadDoc$.uploadAuxiliaryImage(
      screenshot,
      zipname,
      metadata,
      clientID,
      this.TicketMap.AssignmentID
    );

    if (addDocResult) {
      this.snackbarService$.openSnackbar('Uploaded', SnackbarType.success);
    } else {
      this.snackbarService$.openSnackbar('Failed to upload', SnackbarType.error);
    }

    this.progressBarService.stop();
  }

  private dataURLtoFile(dataUrl, fileName) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  onSwipe(event) {
    if (!this.mapControlsEnabled) {
      this.Swipe.emit(event);
    }
  }

  toggleBottomMenu() {
    this.bottomMenuOpen = !this.bottomMenuOpen;
  }

  toggleLayers() {
    this.esriDrawing.toggleLayers();
  }
  toggleMeasurement() {
    this.esriDrawing.toggleMeasurement();
  }

  toggleLegend() {
    this.esriDrawing.toggleLegend();
  }

  toggleBasemap() {
    this.esriDrawing.toggleBasemap();
  }

  toggleMap() {
    this.isEsriVisibile = !this.isEsriVisibile;
  }

  toggleCoordinates() {
    if (this.viewingAddress) {
      if (this.ticketLatLng) {
        this.markers = [this.ticketLatLng];
        this.esriDrawing.goTo(this.ticketLatLng.position.lat, this.ticketLatLng.position.lng);
        this.googleMaps.centerByGoogleLocation(this.ticketLatLng.position);
      }
    } else {
      if (this.ticketAddressLatLng) {
        this.markers = [this.ticketAddressLatLng];
        this.esriDrawing.goTo(this.ticketAddressLatLng.position.lat, this.ticketAddressLatLng.position.lng);
        this.googleMaps.centerByGoogleLocation(this.ticketAddressLatLng.position);
      }
    }
    this.viewingAddress = !this.viewingAddress;
  }

  toggleIdentify() {
    this.identifyClicked = !this.identifyClicked
    this.esriDrawing.toggleIdentify();
  }

  takeScreenshot = () => {
    this.handleTakeScreenshot();
  };

  takeAuxiliaryScreenshot = () => {
    this.handleTakeAuxScreenshot();
  };

  getDirections = () => {
    this.OpenGoogleMapsDirections.emit();
  };

  enableMapControls = () => {
    this.mapControlsEnabled = !this.mapControlsEnabled;
    this.mapControlEnabled.emit(this.mapControlsEnabled);
    this.mapControlsEnabled
      ? ((this.googleMapsFab[0].label = 'Disable Map Controls'), (this.esriMapFab[0].label = 'Disable Map Controls'))
      : ((this.googleMapsFab[0].label = 'Enable Map Controls'), (this.esriMapFab[0].label = 'Enable Map Controls'));
    this.options = { ...this.options, draggable: this.mapControlsEnabled };
    this.esriDrawing.enableMapControls(this.mapControlsEnabled);
  };
}
