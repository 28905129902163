import { Injectable } from "@angular/core";
import { from, Observable, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CREATE_TICKET_UID_IDENTIFIER } from "../../create-ticket/create-ticket-component.service";
import {
  CreateTicketFormTypes,
  CreateTicketModel,
} from "../../create-ticket/CreateTicketModel";
import { CacheService, StoreType } from "../cache/cache.service";
import { ActionMessage, Actions } from "../component-messaging/action-message";
import { LoggerService } from "../services/logger/logger.service";

@Injectable({
  providedIn: "root",
})
export class CompletionsStoreService {
  // only access the Completions store
  storeType: number = StoreType.COMPLETIONS;

  // emit every change to data to all listening components
  private onTicketDataChange: Subject<ActionMessage>;
  private onDocumentDataChange: Subject<ActionMessage>;
  private onScheduleDataChange: Subject<ActionMessage>;

  // models
  createTicketModel: CreateTicketModel;

  constructor(
    private cache$: CacheService,
    private loggerService: LoggerService
  ) {
    this.onTicketDataChange = new Subject<ActionMessage>();
    this.onDocumentDataChange = new Subject<ActionMessage>();
    this.onScheduleDataChange = new Subject<ActionMessage>();

    this.createTicketModel = new CreateTicketModel();
  }

  getOnTicketDataChange(): Observable<any> {
    return this.onTicketDataChange.asObservable();
  }

  getOnScheduleDataChange(): Observable<any> {
    return this.onScheduleDataChange.asObservable();
  }

  getOnDocumentDataChange(): Observable<any> {
    return this.onDocumentDataChange.asObservable();
  }

  private emitOnTicketDataChange(message: ActionMessage) {
    switch (message.action) {
      case Actions.CLEAR_POLYGON_DATA:
      case Actions.UPDATE_TICKET_ADDRESS_DATA:
        let { LocateAddress, LocateSubRegionName } =
          message.data["Ticket"]["TicketDetails"];
        let StartHouseNumber = "";
        if (message.data["Ticket"]["TicketDetails"]["StartHouseNumber"]) {
          StartHouseNumber =
            message.data["Ticket"]["TicketDetails"]["StartHouseNumber"];
        }
        this.onTicketDataChange.next(
          new ActionMessage(message.action, {
            LocateAddress,
            LocateSubRegionName,
            StartHouseNumber,
          })
        );
        break;
      default:
        this.onTicketDataChange.next(message);
        break;
    }
  }

  private emitOnDocumentDataChange(message: ActionMessage) {
    switch (message.action) {
      case Actions.UPDATE_DOCUMENT_DATA:
        let docsLen = message.data["Documents"].length;
        this.onDocumentDataChange.next(
          new ActionMessage(message.action, {
            DocumentsLen: docsLen,
            Documents: message.data["Documents"],
          })
        );
        break;
      default:
        this.onDocumentDataChange.next(message);
        break;
    }
  }

  private emitOnScheduleDataChange(message: ActionMessage) {
    switch (message.action) {
      case Actions.UPDATE_SCHEDULE_DATA:
        this.onScheduleDataChange.next(
          new ActionMessage(message.action, message.data["Schedule"])
        );
        break;
      default:
        this.onScheduleDataChange.next(message);
        break;
    }
  }

  resetBody() {
    this.createTicketModel.resetBody();
  }

  resetBodyDefault() {
    this.createTicketModel.resetBodyDefault();
  }

  updateTicketData(message: ActionMessage): Observable<any> {
    // update local body
    if (message.data["formType"] && message.data["value"]) {
      this.createTicketModel.updateBody(
        message.data["formType"],
        message.data["value"]
      );
      // upload data

      if (message.data["formType"] == CreateTicketFormTypes.utilities) {
        this.emitOnTicketDataChange(message);
      }
      return this.uploadTicketData(
        message.action,
        message.data["rowKey"],
        this.createTicketModel.getBody()
      );
    } else {
      // invalid message.data params
      return of(false);
    }
  }

  updateDocumentData(message: ActionMessage): Observable<any> {
    return this.uploadDocumentData(
      message.action,
      message.data["rowKey"],
      message.data["value"]
    );
  }

  updateScheduleData(message: ActionMessage): Observable<any> {
    return this.uploadScheduleData(
      message.action,
      message.data["rowKey"],
      message.data["value"]
    );
  }

  updateDocumentsUploadStatus(message: ActionMessage): Observable<any> {
    return new Observable((subscriber) => {
      let { rowKey, value } = message.data;

      from(this.getRow(rowKey))
        .pipe(
          switchMap((row) => {
            if (row) {
              let documents = row["Documents"];
              documents.forEach((documentRow) => {
                if (value.indexOf(documentRow["name"]) > -1) {
                  documentRow["uploaded"] = true;
                }
              });

              return this.updateDocumentData(
                new ActionMessage(message.action, { rowKey, value: documents })
              );
            } else {
              return of(false);
            }
          })
        )
        .subscribe((successfulSave) => {
          subscriber.next(successfulSave);
          subscriber.complete();
        });
    });
  }

  updateRowMetadata(message: ActionMessage): Observable<any> {
    return new Observable((subscriber) => {
      if (message && message.data) {
        let rowKey = message.data["rowKey"];

        from(this.getRow(rowKey))
          .pipe(
            switchMap((row) => {
              let nextMetadata = message.data["value"];
              let nextBody = { metadata: { ...nextMetadata } };

              if (row) {
                nextMetadata = row["metadata"]
                  ? { ...row["metadata"], ...nextMetadata }
                  : nextMetadata;
                nextBody = { ...row, metadata: { ...nextMetadata } };
              }

              return this.setRow(rowKey, nextBody);
            })
          )
          .subscribe((successfulSave: boolean) => {
            subscriber.next(successfulSave);
            subscriber.complete();
          });
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  removeRow(message: ActionMessage) {
    return new Observable((subscriber) => {
      if (message.action == Actions.RESET_DATA) {
        let rowKey = message.data["rowKey"];
        from(this.cache$.remove(this.storeType, rowKey)).subscribe(
          (successful) => {
            if (successful) {
              this.emitOnTicketDataChange(
                new ActionMessage(message.action, {})
              );
              this.emitOnDocumentDataChange(
                new ActionMessage(message.action, {})
              );
              subscriber.next(true);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          }
        );
      } else {
        // no
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private uploadTicketData(
    action: number,
    rowKey: string,
    nextTicketBody
  ): Observable<any> {
    return new Observable((subscriber) => {
      if (rowKey && nextTicketBody) {
        let nextBody = { Ticket: { ...nextTicketBody } };
        from(this.getRow(rowKey))
          .pipe(
            switchMap((row) => {
              if (row) {
                // update cur body
                nextTicketBody = row["Ticket"]
                  ? { ...row["Ticket"], ...nextTicketBody }
                  : nextTicketBody;
                nextBody = { ...row, Ticket: { ...nextTicketBody } };
              }
              return this.setRow(rowKey, nextBody);
            })
          )
          .subscribe((successfulSave: boolean) => {
            this.emitOnTicketDataChange(new ActionMessage(action, nextBody));
            subscriber.next(successfulSave);
            subscriber.complete();
          });
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private uploadDocumentData(
    action: number,
    rowKey: string,
    nextDocumentBody
  ): Observable<any> {
    return new Observable((subscriber) => {
      if (rowKey && nextDocumentBody) {
        let nextBody = { Documents: [...nextDocumentBody] };

        from(this.getRow(rowKey))
          .pipe(
            switchMap((row) => {
              if (row) {
                if (action == Actions.UPDATE_DOCUMENT_STATUS_DATA) {
                  // when updating statuses, replace existing documents
                  nextBody = { ...row, Documents: [...nextDocumentBody] };
                } else {
                  nextDocumentBody = row["Documents"]
                    ? [...row["Documents"], ...nextDocumentBody]
                    : nextDocumentBody;
                  nextBody = { ...row, Documents: [...nextDocumentBody] };
                }
              }
              return this.setRow(rowKey, nextBody);
            })
          )
          .subscribe((successfulSave: boolean) => {
            if (successfulSave) {
              this.emitOnDocumentDataChange(
                new ActionMessage(action, nextBody)
              );
              subscriber.next(successfulSave);
              subscriber.complete();
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          });
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  // file: "UEsDBAoAAAAIAMBwTlHC7crYhXMAAKaNAAAZAAAAVGlja2V0Vm"
  // name: "TicketView-Page-3 (1).png"
  // uploaded: false

  removeDocumentData(
    createTicketID: string,
    filenamesToDelete: string[]
  ): Observable<any> {
    return new Observable((subscriber) => {
      try {
        if (
          createTicketID &&
          filenamesToDelete &&
          filenamesToDelete.length > 0
        ) {
          from(this.getRow(createTicketID)).subscribe((row) => {
            if (row["Documents"] && row["Documents"].length > 0) {
              row["Documents"] = row["Documents"].filter((docItem) => {
                if (!filenamesToDelete.includes(docItem.name)) {
                  return docItem;
                }
              });
            }
            from(this.setRow(createTicketID, row)).subscribe((success) => {
              if (success) {
                subscriber.next(success);
                this.emitOnDocumentDataChange(
                  new ActionMessage(Actions.UPDATE_DOCUMENT_DATA, row)
                );
                subscriber.complete();
              } else {
                subscriber.next(false);
                subscriber.complete();
              }
            });
          });
        } else {
          subscriber.next(false);
          subscriber.complete();
        }
      } catch (error) {
        this.loggerService.error("removeDocumentData" + error.message);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private uploadScheduleData(
    action: number,
    rowKey: string,
    nextScheduleBody
  ): Observable<any> {
    return new Observable((subscriber) => {
      if (rowKey && nextScheduleBody) {
        let nextBody = { Schedule: nextScheduleBody };

        from(this.getRow(rowKey))
          .pipe(
            switchMap((row) => {
              if (row) {
                //do not replace before
                nextScheduleBody = row["Schedule"]
                  ? { ...row["Schedule"], ...nextScheduleBody }
                  : nextScheduleBody;
                nextBody = { ...row, Schedule: nextScheduleBody };
              }
              return this.setRow(rowKey, nextBody);
            })
          )
          .subscribe((successfulSave: boolean) => {
            if (successfulSave) {
              this.emitOnScheduleDataChange(
                new ActionMessage(Actions.UPDATE_SCHEDULE_DATA, nextBody)
              );
              subscriber.next(successfulSave);
              subscriber.complete();
            } else {
              subscriber.next(false);
              subscriber.complete();
            }
          });
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  private async setRow(rowKey, rowValue) {
    let success = false;
    try {
      let result = this.cache$.insert(StoreType.COMPLETIONS, rowKey, rowValue);
      if (result) {
        success = true;
      }
    } catch (error) {
      this.loggerService.error(
        "CompletionsLookup$: setRow: function fail: ",
        error.message
      );
    }
    return success;
  }

  async getRow(rowKey) {
    let row = null;
    try {
      const result = await this.cache$.query(this.storeType, rowKey);

      if (result) {
        row = result;
      } else {
        // row doesnt exist
      }
    } catch (error) {
      this.loggerService.error(
        "CompletionsLookup$: getRow: function failed: ",
        error.message
      );
    }
    return row;
  }

  async cleanRows() {
    let response = false;
    try {
      response = await this.cache$.clear(this.storeType);
    } catch (error) {
      this.loggerService.error(error.message);
    }
    return response;
  }

  async cleanCreateTicketRows() {
    let response = true;
    try {
      let keys = await this.cache$.getKeys(this.storeType);
      if (keys && keys.length > 0) {
        let ctRows = keys.filter((key) =>
          key.includes(CREATE_TICKET_UID_IDENTIFIER)
        );
        response = await this.cache$.clearKeys(this.storeType, ctRows);
      }
    } catch (error) {
      this.loggerService.error(error.message);
      response = false;
    }
    return response;
  }

  async hasCreateTicketRow(): Promise<boolean> {
    let hasRow = false;
    try {
      let keys = await this.cache$.getKeys(this.storeType);
      if (keys && keys.length > 0) {
        if (keys.find((key) => key.includes(CREATE_TICKET_UID_IDENTIFIER))) {
          hasRow = true;
        }
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
    return hasRow;
  }

  async getCreateTicketRow(): Promise<{}> {
    let row = {};
    try {
      let keys = await this.cache$.getKeys(this.storeType);
      if (keys && keys.length > 0) {
        let key = keys.find((key) =>
          key.includes(CREATE_TICKET_UID_IDENTIFIER)
        );
        let value = await this.cache$.query(this.storeType, key);
        row = {
          key: key,
          value: value,
        };
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
    return row;
  }

  async hasCreateTicketRowByKey(key): Promise<{}> {
    let row = false;
    try {
      row = await this.cache$.query(this.storeType, key);
    } catch (error) {
      this.loggerService.error(error.message);
    }
    return row;
  }
}
