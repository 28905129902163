import { Component, OnInit, Input } from "@angular/core";
import { BasePanelComponent } from "src/app/modules/core/base-set/base-set.component";
import { ComponentMessage } from "src/app/modules/core/component-messaging/component-message";
import { ComponentMessagingService } from "src/app/modules/core/component-messaging/component-messaging.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { Ticket } from "../../syncfusion-scheduler/schedulerTypes";

@Component({
  selector: "app-scheduler-ticket-panel",
  templateUrl: "./scheduler-ticket-panel.component.html",
  styleUrls: ["./scheduler-ticket-panel.component.css"],
  providers: [ComponentMessagingService],
})
export class SchedulerTicketPanelComponent
  extends BasePanelComponent
  implements OnInit
{
  className = "SchedulerTicketPanelComponent";
  @Input() props: any;
  @Input() tickets: Ticket[];

  ticketViewProps: any;

  constructor(
    logger$: LoggerService,
    private compMsg$: ComponentMessagingService
  ) {
    super(logger$);

    this.ticketViewProps = {
      messageService: compMsg$,
    };

    // from child
    compMsg$.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessageChild(nextMsg);
    });
  }

  ngOnInit(): void {
    // from page or other panel
    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });
  }

  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.eventCreated) {
        this.ticketViewProps.messageService.sendToMessageStream(compMessage);
      }
    }
  }

  onReceivedMessageChild(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.ticketDropped) {
        this.props.messageService.sendToMessageStream(compMessage);
      }
    }
  }
}
