import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { QuickTicketSearchComponent } from '../../../shared/components/misc/quick-ticket-search/quick-ticket-search.component';

import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchableDropdownModule } from '../searchable-dropdown/searchable-dropdown.module';

@NgModule({
  declarations: [NavigationBarComponent],
  imports: [
    CommonModule,
    QuickTicketSearchComponent,
    MaterialModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    SearchableDropdownModule,
  ],
  exports: [NavigationBarComponent],
})
export class NavigationBarModule {}
