import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Feature, MapBrowserEvent } from 'ol';
import { GeometryCollection, Point } from 'ol/geom';
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { FeatureOrCollection } from '../../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../../classes/map-feature-change';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';
import { Interaction } from 'ol/interaction';
import Styles from '../styles';
import { SvgSymbol } from '../../services/map-symbols.service';
import { ShapeType } from '../types';

export class SymbolTool extends Interaction {
  private readonly sourceRef: VectorSource;
  private readonly symbol: SvgSymbol;
  private readonly mapRef: Map;
  private featureChanges$: Subject<MapFeatureChange<FeatureOrCollection>>;
  private utilities: OpenLayersUtilities;
  private image: HTMLImageElement;

  cursor = 'pointer';
  previousCursor = undefined;

  interactionStart: () => void;
  interactionEnd: (p: { originalFeatures: any; features: any }) => void;

  constructor({
    mapRef,
    sourceRef,
    symbol,
    featureChanges,
  }: {
    mapRef: Map;
    sourceRef: VectorSource;
    symbol: SvgSymbol;
    featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
  }) {
    super({
      handleEvent,
    });
    this.utilities = new OpenLayersUtilities();
    this.sourceRef = sourceRef;
    this.symbol = symbol;
    this.featureChanges$ = featureChanges;
    this.mapRef = mapRef;
    this.image = new Image();
    this.image.src = this.symbol.src;
  }

  setOnInteractionStart(interactionStart: () => void) {
    this.interactionStart = interactionStart;
  }

  setOnInteractionEnd(interactionEnd: () => void) {
    this.interactionEnd = interactionEnd;
  }

  public addSymbol(coords: Coordinate) {
    const feature = new Feature();
    const featureStyle = Styles.svgSymbol(this.image, this.mapRef);
    feature.setStyle(featureStyle);
    feature.setGeometry(this.buildGeom(coords));
    feature.setProperties(
      {
        shape_type_id: ShapeType.SvgSymbol,
        symbol_id: this.symbol.id,
      },
      true
    );
    this.sourceRef.addFeature(feature);
    this.featureChanges$.next(new MapFeatureChange(FeatureChangeType.added, feature, undefined, this.sourceRef));
  }

  private buildGeom(coords: Coordinate): GeometryCollection {
    const poly = this.utilities.createRectangleGeometry(
      this.mapRef,
      coords,
      this.image.naturalWidth,
      this.image.naturalHeight
    );
    const point = new Point(coords);
    return new GeometryCollection([point, poly]);
  }
}

function handleEvent(evt: MapBrowserEvent<UIEvent>): boolean {
  if (evt.type === "singleclick") {
    this.addSymbol(evt.coordinate);
    return false;
  }
  return true;
}
