// @ts-nocheck
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BasePageComponent } from "src/app/modules/core/base-set/base-set.component";
import { DatetimeService } from "src/app/modules/core/services/datetime/datetime.service";
import {
  DocumentService,
  DocumentTypeID,
} from "src/app/modules/core/services/document/document.service";
import { DownloadDocumentService } from "src/app/modules/core/services/document/download-document.service";
import { EmailerService } from "src/app/modules/core/services/emailer/emailer.service";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { FAMItem } from "../../../fab/floating-action-menu/floating-action-menu.component";
import { ModalService } from "../../../modals/modal.service";
import { ProgressBarService } from "../../../progress-bar/progress-bar.service";
import { SnackbarService } from "../../../snackbar/snackbar.service";
import { SnackbarType } from "../../../snackbar/snackbar/snackbar";
import { EmailModalComponent } from "../../../ticket/modals/email-modal/email-modal.component";
import { TicketDetailsService } from "../../ticket-details.service";

export interface DialogData {
  email: string;
}

@Component({
  selector: "app-ticket-documents-details",
  templateUrl: "./ticket-documents-details.component.html",
  styleUrls: ["./ticket-documents-details.component.scss"],
})
export class TicketDocumentDetailsComponent
  extends BasePageComponent
  implements OnInit, OnChanges
{
  className = "TicketDocumentDetailsComponent";

  @Input() props;
  @Output() syncAfterUploadDocs = new EventEmitter<any>();
  @Output() newAutologEvent = new EventEmitter<any>();
  @ViewChild("documentsList") documentsList;

  @ViewChild("fileInputInternal") fileInputInternal: ElementRef;
  @ViewChild("fileInputOffice") fileInputOffice: ElementRef;

  tbCompletions_Documents: any;
  tbCompletions_S3Documents: any;
  CallTypeID: any;
  AssignmentID: any;
  PrimaryID: any;
  RequestNumber: any;
  U2UserID: any;
  ClientID: any;
  email: any;
  ticketEmail: any;
  formattedDocuments: any;
  multipleSelectModeID: number = 0; // 0 = no multi select, 1 = add move, 2 = delete mode
  selectModeID = {
    NO_SELECT: 0,
    ADD: 1,
    REMOVE: 2,
  };
  users: any;
  selectedDocs: any;
  emailObj: any;
  readOnly = true;
  fabMenu: FAMItem[];

  DELETE_DOC_MODAL_ID: string = "delete-doc-modal-id";

  fileToDelete: any;

  constructor(
    logger$: LoggerService,
    private downloadDoc$: DownloadDocumentService,
    private ticket$: TicketDetailsService,
    public dialog: MatDialog,
    private emailService: EmailerService,
    private progressBarService: ProgressBarService,
    private modalService: ModalService,
    private snackBarService: SnackbarService,
    private datetimeService: DatetimeService,
    private documentService: DocumentService,
    private userService: UserService,
  ) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.props.currentValue) {
      if (changes.props.currentValue.ticket != null) {
        this.email = changes.props.currentValue.ticket.email;
        this.ticketEmail = changes.props.currentValue.ticket.email;
        this.CallTypeID = changes.props.currentValue.ticket.CallTypeID;
      }

      if (changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
        this.TICKET_PROTECTION_MODE =
          changes.props.currentValue.TICKET_PROTECTION_MODE;
      }

      if (changes.props.currentValue.PrimaryID != null) {
        this.PrimaryID = changes.props.currentValue.PrimaryID;
      }

      if (changes.props.currentValue.ClientID != null) {
        this.ClientID = changes.props.currentValue.ClientID;
      }
      if (changes.props.currentValue.ClientID != null) {
        this.ClientID = changes.props.currentValue.ClientID;
      }

      if (
        changes.props.currentValue.tbCompletions_Documents &&
        changes.props.currentValue.AssignmentID &&
        changes.props.currentValue.RequestNumber &&
        changes.props.currentValue.U2UserID
      ) {
        this.tbCompletions_Documents =
          changes.props.currentValue.tbCompletions_Documents;
        this.tbCompletions_S3Documents =
          changes.props.currentValue.tbCompletions_S3Documents;
        this.AssignmentID = changes.props.currentValue.AssignmentID;
        this.RequestNumber = changes.props.currentValue.RequestNumber;
        this.U2UserID = changes.props.currentValue.U2UserID;
        this.createFormattedDocumentData([...this.tbCompletions_Documents, ...this.tbCompletions_S3Documents]);
      }
    }
  }

  ngOnInit() {
    this.fabMenu = [
      {
        label: "Add Internal Document",
        icon: "add",
        action: this.addInternalFileClicked,
      },
      {
        label: "Add Office Document",
        icon: "add",
        action: this.addOfficeFileClicked,
      },
      {
        label: "Download All",
        icon: "cloud_download",
        action: this.downloadAllClicked,
      },
    ];

    this.emailActive();
  }

  // product of this func is shown in html
  createFormattedDocumentData(documents) {
    try {
      if (documents) {
        const formattedDocuments = documents.reduce((total, current) => {
          let newDate = this.datetimeService.dbDateToFormattedLocalDate(
            current.CreationDate,
            "MMM d, yyyy HH:mm",
          );
          let documentTypeDesc = this.convertDocumentTypeToDesc(
            current["DocumentTypeID"],
          );

          let newDoc = {
            ...current,
            CreationDate: newDate,
            DocumentTypeDesc: current["S3DocumentID"] ? "S3 " + documentTypeDesc : documentTypeDesc,
            isS3Document: current["S3DocumentID"] ? true : false,
          };

          total.push(newDoc);

          return total;
        }, []);
        formattedDocuments.sort((a, b) => {
          const dateA = new Date(a.CreationDate).getTime();
          const dateB = new Date(b.CreationDate).getTime();
          return dateB - dateA;
        });        
        this.formattedDocuments = formattedDocuments;
      }
    } catch (error) {
      this.loggerService.error(error);
    }
  }
  emailActive = () => {
    if (this.userService.isSettingActive(154)) {
      this.fabMenu.push({
        label: "Email",
        icon: "email",
        action: this.emailClicked,
      });
    }
  };

  emailClicked = () => {
    const dialogRef = this.dialog.open(EmailModalComponent, {
      width: "600px",
      data: { email: this.email },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      if (result) {
        result.value == null
          ? (this.email = this.ticketEmail)
          : (this.email = result.value);

        const emailObj = {
          emailObjects: [
            {
              PrimaryID: this.PrimaryID,
              AssignmentID: this.AssignmentID,
              email: this.email,
              CallTypeID: this.CallTypeID,
              RegionID: 0,
              RequestNumber: this.RequestNumber,
              Priority: 0,
              FormatID: 1,
            },
          ],
        };
        this.emailService.sendEmail(emailObj);
        this.snackBarService.openSnackbar(
          "Email sent successfully",
          SnackbarType.success,
        );
      }
    });
  };

  private convertDocumentTypeToDesc(docTypeID) {
    try {
      const docTypeIDs = Object.keys(DocumentTypeID);
      for (let i = 0; i < docTypeIDs.length; i++) {
        if (DocumentTypeID[docTypeIDs[i]] == docTypeID) {
          return docTypeIDs[i];
        }
      }

      return docTypeID;
    } catch (error) {
      console.error(error);
      return docTypeID;
    }
  }

  async downloadClickedFile(file) {
    try {
      // prevent single downloads when user is selecting for multi download
      this.progressBarService.start();
      if (file) {
        const docId = file.DocumentID ? file.DocumentID : file.S3DocumentID; // get doc info from selection list

        // download
        const result = await this.downloadDoc$.getDocument(
          this.AssignmentID,
          docId, 
          file.isS3Document
        );

        if (result && result["buffer"] != null && result["FileName"]) {
          // *this only downloads the doc
          // *the user will open their doc in their default Windows application
          const linkSource = result["buffer"];
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = result["FileName"];
          downloadLink.click();

          // reset select mode
          this.progressBarService.stop();
        } else {
          this.progressBarService.stop();
          this.snackBarService.openSnackbar(
            "Failed to download document",
            SnackbarType.warning,
          );
        }
      } else {
        throw new Error("Invalid File");
      }
    } catch (error) {
      console.error(error);
      // this.documentsList.deselectAll();
      this.snackBarService.openSnackbar(
        "Failed to download document",
        SnackbarType.warning,
      );
      this.progressBarService.stop();
    }
  }

  async handleFileInput(event, documentTypeID) {
    // console.log(event);
    try {
      this.progressBarService.start();

      const filename =
        this.documentService.formatDateForFileName(new Date()) +
        "_" +
        event[0].name;
      const type = this.documentService.getExtensionFromFilename(filename);
      const zipname =
        this.documentService.formatDateForFileName(new Date()) +
        "_" +
        this.U2UserID +
        "_" +
        this.AssignmentID +
        ".zip";
      const date = this.datetimeService.localDateToDBDateStr(new Date());

      // s3 object metadata
      const metadata = {
        AssignmentID: this.AssignmentID.toString(),
        CreationDate: date,
        Description: "Added from U4 Documents",
        FileName: filename,
        DocumentTypeID: "" + documentTypeID,
        RequestNumber: "" + this.RequestNumber,
        isSendable: "" + 0,
      };

      // add new autolog for new doc
      const newAutolog = {
        AssignmentID: this.AssignmentID,
        DescID: 4, // custom autolog desc id
        UserID: this.U2UserID,
        Explaination: "Added new document",
        logDate: date,
        bFieldAdded: 1,
      };

      // add new TEMP doc row
      const tmpDocRowID = await this.downloadDoc$.getHighestLocalDocumentId(
        this.AssignmentID, 
      );
      const tmpDocRow = {
        DocumentID: tmpDocRowID,
        AssignmentID: this.AssignmentID.toString(),
        PrimaryID: this.PrimaryID,
        CreationDate: date,
        Description: "Added from U4 Documents",
        FileName: filename,
        DocumentTypeID: "" + documentTypeID,
        RequestNumber: "" + this.RequestNumber,
        isSendable: "" + 0,
      };

      //change filename by making new file
      const file = new File([event[0]], filename, { type: event[0].type });
      // console.log(file);
      // return;

      const addDocLocallyResult = await Promise.all([
        this.downloadDoc$.addDocumentLocally(this.AssignmentID, tmpDocRowID, {
          FileName: filename,
          Blob: new Blob([file], { type: file.type }),
          type: type,
        }),
        this.downloadDoc$.addLocalDocumentRow(this.AssignmentID, tmpDocRow),
        this.downloadDoc$.uploadDocument(
          file,
          zipname,
          metadata,
          this.ClientID,
        ),
      ]);
      this.tbCompletions_Documents.push(tmpDocRow);
      this.createFormattedDocumentData([...this.tbCompletions_Documents, ...this.tbCompletions_S3Documents]);

      // upload doc and update IDB
      this.newAutologEvent.emit({ data: newAutolog });

      if (addDocLocallyResult) {
        this.snackBarService.openSnackbar("Uploaded", SnackbarType.success);
        // this.syncAfterUploadDocs.emit();
      } else {
        this.snackBarService.openSnackbar(
          "Failed to upload",
          SnackbarType.success,
        );
      }

      this.progressBarService.stop();
    } catch (error) {
      console.error(error);
      this.progressBarService.stop();
      this.snackBarService.openSnackbar(
        "Failed to upload document - Unable to connect",
        SnackbarType.error,
      );
    }
  }

  async handleFileDelete(file) {
    if (file && file.DocumentID) {
      this.fileToDelete = file;
      this.modalService.open(this.DELETE_DOC_MODAL_ID);
    } else if (file.S3DocumentID) {
      //s3 document - user can't delete so just state they can't delete 
      // This should be taken out when full s3 functionality is added to office side 
      this.snackBarService.openSnackbar(
        "Unable to delete S3 documents",
        SnackbarType.warning
      );
    }
  }

  handleConfirmDelete() {
    this.modalService.close(this.DELETE_DOC_MODAL_ID);
    this.deleteFile(this.fileToDelete);
  }

  async deleteFile(file) {
    if (file) {
      const docId = file.DocumentID;

      // add new autolog to list then format
      const deleteDocAutolog = {
        AssignmentID: this.AssignmentID,
        DescID: 5, // custom autolog desc id
        UserID: this.U2UserID, //
        Explaination: "",
        logDate: this.datetimeService.localDateToDBDateStr(new Date()),
        bFieldAdded: 1,
      };
      try {
        await this.ticket$.addNewAutolog(this.AssignmentID, deleteDocAutolog);
        await this.downloadDoc$.removeDocumentRow(docId);

        this.syncAfterUploadDocs.emit();
      } catch (error) {
        console.error(error);
      }
    }
  }

  addInternalFileClicked = () => {
    this.fileInputInternal.nativeElement.click();
  };

  addOfficeFileClicked = () => {
    this.fileInputOffice.nativeElement.click();
  };

  downloadAllClicked = async () => {
    try {
      if (this.tbCompletions_Documents.length > 0) {
        // prevent single downloads when user is selecting for multi download
        this.progressBarService.start();

        // download all
        const result = await this.downloadDoc$.getDocumentAll(this.AssignmentID);
        if (result && result["buffer"] != null && result["FileName"]) {
          // *this only downloads the doc
          // *the user will open their doc in their default Windows application
          const linkSource = result["buffer"];
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = result["FileName"];
          downloadLink.click();

          this.progressBarService.stop();
        } else {
          this.progressBarService.stop();
          this.snackBarService.openSnackbar(
            "Failed to download all documents",
            SnackbarType.warning,
          );
        }
      }
    } catch (error) {
      console.error(error);
      this.snackBarService.openSnackbar(
        "Failed to download all documents",
        SnackbarType.warning,
      );
      this.progressBarService.stop();
    }
  };
}
