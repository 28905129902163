<mat-tab-group
  #matTabGroup
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  animationDuration="250ms"
  style="height: 100%"
  backgroundColor="primary"
  dynamicHeight
  [selectedIndex]="selected"
>
  <mat-tab label="PUNCH ClOCK">
    <ng-template matTabContent>
      <app-punch-clock-timer />
    </ng-template>
  </mat-tab>

  <mat-tab label="TIME SHEET">
    <ng-template matTabContent>
      <app-punch-clock-timesheet />
    </ng-template>
  </mat-tab>
</mat-tab-group>
