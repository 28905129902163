export enum MessageAction {
  READY = 1,
  LOADING = 2,
  UPDATE = 3,
}

export class ComponentMessage {
  public action: any = -1;
  public message: any;
  public senderID: string = "";
  public receiverID?: any = -1;
  constructor(compMsg: {
    action?: any;
    message: any;
    senderID?: string;
    receiverID?: any;
  }) {
    if (compMsg.action) {
      this.action = compMsg.action;
    }
    if (compMsg.senderID) {
      this.senderID = compMsg.senderID;
    }
    if (compMsg.receiverID) {
      this.receiverID = compMsg.receiverID;
    }
    this.message = compMsg.message;
  }
}
