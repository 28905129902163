import { CALL_TYPE, LOCATE_STATUS_STYPE } from "./ticket-tags-styles";
// import { memoize } from "lodash-es";
import { EventEmitter, Injectable } from "@angular/core";
import {
  CallTypeID,
  LocateStatusID,
} from "../../ticket-details/ticket-details.module";

export interface TicketTag {
  value: string;
  color: string;
  textColor: string;
}
@Injectable({
  providedIn: "root",
})
export class TicketTagBuilderService {
  // createTagsFromCallTypeID = memoize(this.memCreateTagsFromCallTypeID);
  tagChanged: EventEmitter<any> = new EventEmitter();

  createTagsFromCallTypeID(callTypeID) {
    const tags = [];
    try {
      if (callTypeID == CallTypeID.PRIVATE) {
        tags.push({
          value: "Private",
          color: CALL_TYPE.PRIVATE.color,
          textColor: CALL_TYPE.PRIVATE.textColor,
        });
      } else if (callTypeID == CallTypeID.STANDARD) {
        tags.push({
          value: "Standard",
          color: CALL_TYPE.STANDARD.color,
          textColor: CALL_TYPE.STANDARD.textColor,
        });
      } else if (callTypeID == CallTypeID.EMERGENCY) {
        tags.push({
          value: "Emergency",
          color: CALL_TYPE.EMERGENCY.color,
          textColor: CALL_TYPE.EMERGENCY.textColor,
        });
      } else if (callTypeID == CallTypeID.DAMAGE_INVESTIGATION) {
        tags.push({
          value: "Damage Investigation",
          color: CALL_TYPE.DAMAGE_INVESTIGATION.color,
          textColor: CALL_TYPE.DAMAGE_INVESTIGATION.textColor,
        });
      } else if (callTypeID == CallTypeID.PROJECT_WORK) {
        tags.push({
          value: "Project",
          color: CALL_TYPE.PROJECT.color,
          textColor: CALL_TYPE.PROJECT.textColor,
        });
      } else if (callTypeID == CallTypeID.PLANNING) {
        tags.push({
          value: "Planning",
          color: CALL_TYPE.PLANNING.color,
          textColor: CALL_TYPE.PLANNING.textColor,
        });
      } else if (callTypeID == CallTypeID.AUDIT) {
        tags.push({
          value: "Audit",
          color: CALL_TYPE.AUDIT.color,
          textColor: CALL_TYPE.AUDIT.textColor,
        });
      } else if (callTypeID == CallTypeID.TRAINING) {
        tags.push({
          value: "Training",
          color: CALL_TYPE.TRAINING.color,
          textColor: CALL_TYPE.TRAINING.textColor,
        });
      } else if (callTypeID == CallTypeID.PRIORITY0) {
        tags.push({
          value: "Priority 0",
          color: CALL_TYPE.PRIORITY0.color,
          textColor: CALL_TYPE.PRIORITY0.textColor,
        });
      } else if (callTypeID == CallTypeID.PRIORITY1) {
        tags.push({
          value: "Priority 1",
          color: CALL_TYPE.PRIORITY1.color,
          textColor: CALL_TYPE.PRIORITY1.textColor,
        });
      } else if (callTypeID == CallTypeID.PRIORITY2) {
        tags.push({
          value: "Priority 2",
          color: CALL_TYPE.PRIORITY2.color,
          textColor: CALL_TYPE.PRIORITY2.textColor,
        });
      } else if (callTypeID == CallTypeID.PRIORITY3) {
        tags.push({
          value: "Priority 3",
          color: CALL_TYPE.PRIORITY3.color,
          textColor: CALL_TYPE.PRIORITY3.textColor,
        });
      } else if (callTypeID == CallTypeID.PRIORITY4) {
        tags.push({
          value: "Priority 4",
          color: CALL_TYPE.PRIORITY4.color,
          textColor: CALL_TYPE.PRIORITY4.textColor,
        });
      }
    } catch (error) {
      console.log(error);
    }
    return tags;
  }

  createTicketTagsFromTable(tbAdmin_TicketTags, AssignmentTagIDs): TicketTag[] {
    try {
      const tags = [];
      for (let i = 0; i < tbAdmin_TicketTags.length; i++) {
        const curAdminTag = tbAdmin_TicketTags[i];

        if (AssignmentTagIDs.indexOf(curAdminTag.TagID) > -1) {
          const textColour = this.getTextColour(curAdminTag.HexColour);

          tags.push({
            value: curAdminTag.TagName,
            color: curAdminTag.HexColour,
            textColor: textColour,
          });
        }
      }

      return tags;
    } catch (error) {
      console.error(error);
    }
  }

  createCustomTicketTags(message) {
    const tags = [];
    try {
      tags.push({
        value: message,
        color: CALL_TYPE.AUDIT.color,
        textColor: CALL_TYPE.AUDIT.textColor,
      });
    } catch (error) {
      console.error(error);
    }

    return tags;
  }

  createTagsFromLocateStatusID(locateStatusID: any) {
    const tags = [];

    if (locateStatusID == LocateStatusID.ASSISTANCE_NEEDED) {
      tags.push({
        value: "Assistance Needed",
        color: LOCATE_STATUS_STYPE.ASSISTANCE_NEEDED.color,
        textColor: LOCATE_STATUS_STYPE.ASSISTANCE_NEEDED.textColor,
      });
    } else if (locateStatusID == LocateStatusID.LOCATE_COMPLETED) {
      tags.push({
        value: "Locate Completed",
        color: LOCATE_STATUS_STYPE.LOCATE_COMPLETED.color,
        textColor: LOCATE_STATUS_STYPE.LOCATE_COMPLETED.textColor,
      });
    } else if (locateStatusID == LocateStatusID.LOCATE_UNLOCATABLE) {
      tags.push({
        value: "Unlocatable",
        color: LOCATE_STATUS_STYPE.UNLOCATABLE.color,
        textColor: LOCATE_STATUS_STYPE.UNLOCATABLE.textColor,
      });
    } else if (
      locateStatusID == LocateStatusID.LOCATE_ON_GOING ||
      locateStatusID == LocateStatusID.PROJECT_ON_GOING
    ) {
      tags.push({
        value: "Ongoing",
        color: LOCATE_STATUS_STYPE.LOCATE_ONGOING.color,
        textColor: LOCATE_STATUS_STYPE.LOCATE_ONGOING.textColor,
      });
    } else if (locateStatusID == LocateStatusID.OFFICE_CANCELLED) {
      tags.push({
        value: "Cancelled",
        color: LOCATE_STATUS_STYPE.LOCATE_CANCELLED.color,
        textColor: LOCATE_STATUS_STYPE.LOCATE_CANCELLED.textColor,
      });
    }
    return tags;
  }

  getTextColour(colour): string {
    let textColour = "#000000";
    if (colour) {
      const r = parseInt(colour.substring(1, 3), 16);
      const g = parseInt(colour.substring(3, 5), 16) * 2;
      const b = parseInt(colour.substring(5, 7), 16);
      if (r + g + b <= 765 / 1.96) {
        textColour = "#ffffff";
      }
    }
    return textColour;
  }
}
