<div class="flex flex-col p-6 justify-start items-start m-0">
  <h2 class="text-headline-6 font-rajdhani font-semibold uppercase p-0 m-0 mb-9">{{ data.header }}</h2>
  <div class="size-full flex flex-col gap-14 justify-start items-start">
    <div class="size-full flex flex-col gap-6">
      <div class="flex flex-col gap-1.5">
        <div class="flex flex-row gap-4 justify-start items-center">
          <label for="explanation" class="block text-lg text-accent font-rajdhani font-semibold uppercase">
            explanation
          </label>
          @if (!explanation.valid && explanation.touched) {
            <span class="text-warn font-rajdhani text-sm">explanation is required</span>
          }
        </div>
        <textarea
          (keydown)="$event.stopPropagation()"
          [placeholder]="data.placeholder ?? 'Explanation'"
          [formControl]="explanation"
          id="explanation"
          class="h-44 w-full appearance-none box-border bg-white border-solid border-[#00000029] border-1 text-gray-900 text-sm rounded-md block p-2.5 m-0 {{
                  explanation.touched && !explanation.valid ? 'ring-2 ring-red-200' : ''
                }}"
        ></textarea>
      </div>


    </div>
    <div class="w-full flex flex-row gap-3 justify-end items-center">
      <button
        type="button"
        class="h-9 w-[104px] flex justify-center items-center appearance-none border-none bg-transparent py-2 px-4 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase"
        (click)="onClose($event)">
        cancel
      </button>
      <button
        type="button"
        class="h-9 w-[104px] flex justify-center items-center appearance-none rounded border-solid border-2 border-primary bg-primary py-2 px-4 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase disabled:cursor-not-allowed disabled:bg-gray-500 disabled:border-gray-500"
        (click)="onConfirm($event)"
        [disabled]="!explanation.valid || !explanation.valid"
        matTooltip="Explanation is required"
        [matTooltipDisabled]="explanation.valid">
        save
      </button>
    </div>
  </div>

</div>
