<app-fab-overlay
  [isOpen]="true"
  [classOverride]="'drawing-mode-enabled'"
  [hasBackdrop]="false"
  [panelClass]="'no-overlay-pane-interaction'"
  [positionPairs]="positionPairs"
  [realign$]="realignTrigger$">
  <mat-icon icon class="h-8 w-8" svgIcon="markup"></mat-icon>
  <div
    class="flex flex-row gap-3 justify-start items-start w-auto pointer-events-auto"
    menu>
    <div
      class="flex flex-col justify-start items-center overflow-hidden w-fit py-3 border-none rounded-lg shadow-2xl bg-[#3E454D]">
      @for (item of mainMenu$$(); track $index) {
        <div class="group relative flex flex-row justify-start items-center">
          <div
            class="absolute justify-self-start bg-white rounded-r-md {{
              interactionSelection$$() === item.type ? 'h-6 w-0.5' : 'h-0 w-0'
            }} transition-all ease-in-out group-hover:h-6 group-hover:w-0.5"></div>
          <button
            (click)="changePage(item.type)"
            class="cursor-pointer border-0 bg-transparent text-white p-3"
            [ngClass]="interactionSelection$$() === item.type ? 'selected' : ''">
            <mat-icon style="width: 24px; height: 24px; color: white" svgIcon="{{ item.iconName }}" />
          </button>
        </div>
      }
      <div class="relative flex flex-col justify-center items-center">
        <div
          #colourPreview
          class="absolute w-[26px] h-[26px] rounded-full"
          style="position: absolute; right: 0; left: 0; margin-left: auto; margin-right: auto; z-index: 1"></div>
        <img
          alt="layers"
          src="/assets/icons/drawing%20toolbar%20icons/Color%20Wheel.svg"
          style="width: 36px; height: 36px" />
      </div>
    </div>
    @if (interactionSelection$$() !== null && interactionSelection$$() !== undefined) {
      <div
        class="w-fit h-fit flex-col gap-1.5 justify-center items-center divide-solid divide-white/10 divide-y-[1px] divide-x-0 bg-primary shadow-2xl overflow-hidden rounded-lg py-1.5">
        @switch (interactionSelection$$()) {
          @case (toolType.selectionTool) {
            <div class="px-1.5 pb-1.5">
              <app-toolbar-radio-group
                [items]="selectionTools | MenuItemToRadioInput"
                [value]="myService.getToolbarStateValue('selectionTool')"
                (valueChange)="onSelectionToolChange($event)"
              ></app-toolbar-radio-group>
            </div>
            <div class="flex flex-col justify-center items-center gap-1.5 p-1.5">
              <app-toolbar-radio-group
                [items]="manipulationTools | MenuItemToRadioInput"
                [value]="myService.getToolbarStateValue('manipulationTool')"
                (valueChange)="onManipulationToolChange($event)"
              ></app-toolbar-radio-group>
            </div>
          }
          @case (toolType.lineTool) {
            <div class="px-1.5 pb-1.5">
              <app-toolbar-radio-group
                [items]="lineTools | MenuItemToRadioInput"
                [value]="myService.getToolbarStateValue('lineTool')"
                (valueChange)="onLineToolChange($event)"
              ></app-toolbar-radio-group>
            </div>
          }
          @case (toolType.shapeTool) {
            <div class="px-1.5 pb-1.5">
              <app-toolbar-radio-group
                [items]="shapeTools$$() | MenuItemToRadioInput"
                [value]="myService.getToolbarStateValue('shapeTool')"
                (valueChange)="onShapeToolChange($event)"
              ></app-toolbar-radio-group>
            </div>
          }
          @case (toolType.symbolTool) {
            <div class="flex flex-col gap-3 justify-center items-center px-1.5">
              <div class="flex flex-col justify-center items-center h-fit">
                <h6 class="text-md font-rajdhani font-semibold uppercase text-white m-0">SYMBOLS</h6>
                <app-scroller>
                  <div class="grid grid-cols-2 gap-1.5 w-fit h-fit max-h-16 scroll-auto">
                    @for (symbol of symbolService.svgSymbols$ | async; track $index) {
                      <button
                        (click)="myService.updateToolbarState({svgSymbol: symbol})"
                        class="cursor-pointer size-fit flex justify-center items-center {{
            myService.getToolbarStateValue('svgSymbol') === symbol ? 'bg-primary-hover' : 'bg-transparent  hover:bg-primary-hover'
          }} rounded-md text-white border-0 transition-all ease-in-out p-1.5 m-0">
                        <mat-icon [matTooltip]="symbol.name" class="flex justify-center items-center size-6 text-6">
                          <img class="size-6" alt="{{symbol.name}}"
                               [width]="24"
                               [height]="24"
                               [ngSrc]="symbol.src" />
                        </mat-icon>
                      </button>
                    }
                  </div>
                </app-scroller>
              </div>
            </div>
          }
          @case (toolType.stampTool) {
            <div class="flex flex-col gap-3 justify-center items-center px-1.5">
              <h6 class="text-md font-rajdhani font-semibold uppercase text-white m-0">STICKERS</h6>
              <div class="menu-item-list">
                <div style="font-size: 12px">
                  <app-toolbar-radio-group
                    [items]="stickers$ | async"
                    [value]="myService.getToolbarStateValue('sticker')"
                    (valueChange)="onStickerChange($event)"
                  ></app-toolbar-radio-group>
                </div>
              </div>
              <div class="mt-6" style="display: flex; justify-content: center; align-items: center">
                @if (stickerPreview$$() !== '') {
                  <img [src]="stickerPreview$$()" alt="sticker" class="h-[116px] w-9/12" />
                }
              </div>
            </div>
          }
          @case (toolType.imageTool) {
            <div class="flex flex-col gap-3 justify-center items-center py-1.5 px-1.5">
              <button
                (click)="openPhotoModal()"
                class="w-24 flex flex-row gap-[10px] justify-center items-center appearance-none box-border border-solid border-1 border-gray-400 rounded bg-white cursor-pointer py-1.5">
                <span class="whitespace-nowrap font-rajdhani font-semibold text-md uppercase text-[#707070]">
                  Take Photo
                </span>
              </button>
              <button
                (click)="fileInputInternal.click()"
                class="w-24 flex flex-row gap-[10px] justify-center items-center appearance-none box-border border-solid border-1 border-gray-400 rounded bg-white cursor-pointer py-1.5">
                <span class="whitespace-nowrap font-rajdhani font-semibold text-md uppercase text-[#707070]">
                  Upload Photo
                </span>
              </button>
              <input
                #fileInputInternal
                style="display: none"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                (click)="fileInputInternal.value = null"
                value=""
                [multiple]="false"
                (change)="handleNewFile(fileInputInternal)" />
              <button
                (click)="clearFileInput(fileInputInternal)"
                class="w-24 flex flex-row gap-[10px] justify-center items-center appearance-none box-border border-solid border-1 border-gray-400 rounded bg-white cursor-pointer py-1.5">
                <span class="whitespace-nowrap font-rajdhani font-semibold text-md uppercase text-[#707070]">
                  Blank Canvas
                </span>
              </button>
            </div>
          }
        }
        @if (interactionSelection$$() === toolType.selectionTool ||
        interactionSelection$$() === toolType.lineTool ||
        interactionSelection$$() === toolType.shapeTool) {
          <div class="flex flex-col justify-center items-center gap-1.5 p-1.5">
            <h6 class="text-sm font-rajdhani font-semibold uppercase text-white m-0">LINE TYPE</h6>
            <app-toolbar-radio-group
              [items]="lineTypes | MenuItemToRadioInput"
              [value]="myService.getToolbarStateValue('lineStyle')"
              (valueChange)="onLineStyleChange($event)"
            ></app-toolbar-radio-group>
          </div>
          <div class="flex flex-col justify-center items-center gap-1.5 p-1.5">
            <h6 class="text-sm font-rajdhani font-semibold uppercase text-white m-0">THICKNESS</h6>
            <app-toolbar-radio-group
              [items]="lineThicknesses | MenuItemToRadioInput"
              [value]="myService.getToolbarStateValue('lineThickness')"
              (valueChange)="onLineThicknessChange($event)"
            ></app-toolbar-radio-group>
          </div>
        }
        @if (interactionSelection$$() === toolType.lineTool) {
          <div class="relative flex flex-col justify-center items-center gap-1.5 p-1.5">
            <h6 class="text-sm font-rajdhani font-semibold uppercase text-white m-0">UTILITY LINES</h6>
            <div class="absolute bottom-8 left-2 overflow-hidden h-0">
              <mat-select class="w-48 h-auto" #utilityLineSelect
                          [value]="myService.getToolbarStateValue('utilityLine')"
                          (selectionChange)="onUtilityLineChange($event)"
              >
                @for (utility of UtilityLinesData$ | async; track $index) {
                  <mat-option [value]="utility">
                    {{ utility.LineType }}
                  </mat-option>
                }
              </mat-select>
            </div>
            <button
              (click)="utilityLineSelect.toggle()"
              class="appearance-none border-none cursor-pointer flex flex-row justify-between items-center h-6 w-[72px] bg-white rounded-md p-0 px-1.5">
              <span class="flex-grow font-rajdhani font-semibold tracking-normal text-md truncate">
                {{ utilityLineSelect?.value?.['LineType'] ? utilityLineSelect?.value?.['LineType'] : '' }}
              </span>
              <span class="size-6">
                <mat-icon style="rotate: 90deg; width: 24px; height: 24px; line-height: normal">chevron_right</mat-icon>
              </span>
            </button>
          </div>
        }
        @if (interactionSelection$$() === toolType.selectionTool ||
        interactionSelection$$() === toolType.lineTool ||
        interactionSelection$$() === toolType.shapeTool ||
        interactionSelection$$() === toolType.textTool) {
          <div class="flex flex-col justify-center items-center gap-3 pt-1.5 px-1.5">
            <div class="w-full flex flex-row justify-between items-center gap-1.5">
              <h6 class="text-sm font-rajdhani font-semibold uppercase text-white m-0">STROKE</h6>
              <div>
                <!--              colourPicker                                                                      -->
                <ng-container
                  [ngTemplateOutletContext]="{ control: 'strokeColour' }"
                  [ngTemplateOutlet]="colourPickerTemplate"></ng-container>
              </div>
            </div>
            @if (interactionSelection$$() !== toolType.lineTool) {
              <div class="w-full flex flex-row justify-between items-center gap-1.5">
                <h6 class="text-sm font-rajdhani font-semibold uppercase text-white m-0">FILL</h6>
                <div>
                  <!--              colourPicker                                                                      -->
                  <ng-container
                    [ngTemplateOutletContext]="{ control: 'fillColour' }"
                    [ngTemplateOutlet]="colourPickerTemplate"></ng-container>
                </div>
              </div>
            }
            @if (interactionSelection$$() === toolType.selectionTool ||
            interactionSelection$$() === toolType.lineTool ||
            interactionSelection$$() === toolType.shapeTool ||
            interactionSelection$$() === toolType.textTool) {
              <div class="w-full h-8 flex flex-col justify-start items-center gap-1.5">
                <div class="w-full flex flex-row justify-between items-center">
                  <span class="text-sm font-rajdhani font-semibold uppercase text-white m-0">OPACITY</span>
                  <span class="text-sm font-rajdhani font-semibold uppercase text-white m-0">
                    {{ myService.getToolbarStateValue('opacity') }}%
                  </span>
                </div>
                <input
                  #opacitySlider
                  type="range"
                  min="30"
                  max="100"
                  value="{{ myService.getToolbarStateValue('opacity') }}"
                  (change)="onOpacityChange(opacitySlider.value)"
                  class="w-[70px] bg-gray-200 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-runnable-track]:h-[2px] [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-runnable-track]:bg-white [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:size-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:-translate-y-[4.5px]" />
              </div>
            }
            @if (interactionSelection$$() === toolType.selectionTool) {
              <div
                style="
                  box-sizing: border-box;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                ">
                <button
                  class="appearance-none border-0 rounded-md w-full bg-warn cursor-pointer"
                  (click)="deleteClick()">
                  <mat-icon svgIcon="trash" style="color: white; font-size: 24px; height: 24px; width: 24px"></mat-icon>
                </button>
              </div>
            }
          </div>
        }
      </div>
    }
    <ng-template #colourPickerTemplate let-control="control">
      <div class="relative">
        <div class="absolute bottom-4 left-0 overflow-hidden w-0 h-0">
          <input
            #picker
            (beforeTileRender)="tileRender($event)"
            [noColor]="control === 'fillColour'"
            [columns]="colCount"
            [enableOpacity]="false"
            [inline]="false"
            [modeSwitcher]="false"
            [presetColors]="
              control === 'fillColour' ? { custom: customColors['fill'] } : { custom: customColors['stroke'] }
            "
            [showButtons]="false"
            [value]="myService.getToolbarStateValue(control)"
            (change)="
            control === 'fillColour'
              ? onFillColorChange($event)
              : onStrokeColorChange($event)"
            cssClass="e-hide-value"
            ejs-colorpicker
            mode="Palette"
            type="color" />
        </div>
        <button
          (click)="picker.toggle()"
          class="appearance-none border-none cursor-pointer p-0 size-fit rounded-md overflow-hidden">
          @if (myService.getToolbarStateValue(control)) {
            <div
              style="background-color: {{ myService.getToolbarStateValue(control) ?? '#000000' }}"
              class="appearance-none border-none cursor-pointer h-[20px] w-[25px]"></div>
          } @else {
            <div
              style="
                background: conic-gradient(
                  #ffffff 90deg,
                  #c2c2c2 90deg 180deg,
                  #ffffff 180deg 270deg,
                  #c2c2c2 270deg 360deg
                );
                background-repeat: repeat;
                background-size: 10px 10px;
                background-position: top left;
              "
              class="appearance-none border-none cursor-pointer h-[20px] w-[25px]"></div>
          }
        </button>
      </div>
    </ng-template>
  </div>
</app-fab-overlay>
