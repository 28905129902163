import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BasePageComponent } from "../../core/base-set/base-set.component";
import { ComponentMessagingService } from "../../core/component-messaging/component-messaging.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { UploadService } from "../upload.service";

// declare page component
@Component({
  selector: "app-digsite",
  template: `<app-digsite-panel
    [props]="props"
    [viewOnly]="viewOnly"
  ></app-digsite-panel>`,
  styles: [""],
})
export class DigsitePageComponent extends BasePageComponent implements OnInit {
  className = "DigsitePageComponent";
  props: {};
  @Input() viewOnly: boolean;
  @Input() markers: any;
  constructor(
    logger$: LoggerService,
    messaging$: ComponentMessagingService,
    private upload$: UploadService,
    private routerService: Router,
    private route: ActivatedRoute
  ) {
    super(logger$);

    this.props = {
      messageService: messaging$,
    };
  }

  ngOnInit() {
    this.upload$.generateCreateTicketSessionID();
    // if(this.route.snapshot.queryParams["Authorization"]) {
    //   this.routerService.navigate([this.route.snapshot.routeConfig.path], { queryParams: { ...this.route.snapshot.queryParams, "Authorization": null },  queryParamsHandling: 'merge', replaceUrl: true });
    // }
  }
}
