import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  CdkScrollable,
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
} from '@angular/cdk/overlay';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { OverlayService } from '../../../services/overlay/overlay.service';

@Component({
  selector: 'app-fab-overlay',
  standalone: true,
  imports: [CdkOverlayOrigin, NgClass, MatButtonModule, CdkConnectedOverlay, CdkScrollable],
  template: `
    <button
      #trigger="cdkOverlayOrigin"
      (click)="isOpen = !isOpen"
      class="{{ classOverride ?? 'bg-primary' }}"
      aria-label="layer-switcher"
      cdk-overlay-origin
      color="primary"
      mat-fab
      style="
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 60px;
    width: 60px;
  ">
      <ng-content select="[icon]"></ng-content>
    </button>
    <ng-template
      #fabOverlay="cdkConnectedOverlay"
      (attach)="onAttach()"
      (backdropClick)="handleBackdropClick()"
      (detach)="onDetach()"
      [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPanelClass]="panelClass"
      [cdkConnectedOverlayPositionStrategy]="positionStrategy"
      [cdkConnectedOverlayPositions]="positionPairs"
      cdkConnectedOverlay
      cdkScrollable>
      <div #innerElement class="flex-row justify-center items-center">
        <ng-content select="[menu]"></ng-content>
      </div>
    </ng-template>
  `,
})
export class FabOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
  // IO
  @ViewChild('fabOverlay', { static: true }) fabOverlay: CdkConnectedOverlay;
  @ViewChild('trigger', { static: true }) overlayOrigin: CdkOverlayOrigin;
  @ViewChild('innerElement') innerElement: ElementRef;
  @Input() positionPairs: ConnectedPosition[];
  @Input() hasBackdrop = false;
  @Input() classOverride: string;
  @Input() realign$: Subject<void>;
  @Input() panelClass = '';
  @Input() isOpen = false;
  @Output() stateChanged: EventEmitter<'attached' | 'detached'> = new EventEmitter();

  // services
  private overlayService = inject(OverlayService);

  // members
  positionStrategy: FlexibleConnectedPositionStrategy;
  elementPosition$: BehaviorSubject<ConnectedPosition[]>;
  private isRealigning = false;

  // observables
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.elementPosition$ = new BehaviorSubject<ConnectedPosition[]>(this.positionPairs);
    this.overlayService.resize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.fabOverlay.overlayRef) {
        this.fabOverlay.overlayRef.updatePosition();
      }
    });
  }

  ngAfterViewInit() {
    this.realign$
      .pipe(
        filter(() => this.isOpen),
        tap(() => {
          this.isOpen = false;
        }),
        // delay(10),
        // tap(() => (this.isOpen = true)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleBackdropClick() {
    this.isOpen = false;
  }

  onAttach() {
    this.stateChanged.emit('attached');
  }

  onDetach() {
    this.stateChanged.emit('detached');
  }
}
