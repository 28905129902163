import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

if (environment.production) {
  enableProdMode();
}

// Registering Syncfusion license key
// 20.2.0.45
// ORg4AjUWIQA/Gnt2VVhiQlFaclpJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hWn1ccXZVQWheUUE=

// 20.2.0.36
// ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hWn1ccHxWRGdVVEQ=

//20.2.45
//ORg4AjUWIQA/Gnt2VVhiQlFaclpJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiXX5dcXBUTmFeUkI=

//20.4.0.53
//ORg4AjUWIQA/Gnt2VVhkQlFac1xJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiXX5adHZWRWBfV0A=
//NPM Install might update version so need new key
//Check package.json of syncfusion node to find version using in node

//23.2.4
// ORg4AjUWIQA/Gnt2VVhkQlFac1xJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiXX5adHZWRWBfV0A=

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEZiW35bcXBQQGVbVkd3Xg==');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

