import {Component, Inject, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Observable, Subject} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {SettingID} from "src/app/modules/core/services/user/setting";
import {UserService} from "src/app/modules/core/services/user/user.service";
import {TicketActionIDs} from "src/app/shared/services/ticket-actions/TicketActionIDs";
import {TicketActionsService} from "src/app/shared/services/ticket-actions/ticket-actions.service";
/**
 *
 *
 * TicketAssignment
 *
 *
 */

@Component({
  selector: "app-assign-locator-modal",
  templateUrl: "./assign-locator-modal.component.html",
  styleUrls: ["./assign-locator-modal.component.scss"],
})
export class AssignLocatorModalComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  localUserList: any;

  lspList: any[];
  lspAreaList: any[];

  lspControl = new FormControl();
  lspAreaControl = new FormControl();

  // lspOptions: Observable<string[]>;
  // lspAreaOptions: Observable<string[]>;

  utilityHasLSP: boolean = false;
  showLSPButton: boolean = false;
  showAreaLSPs: boolean = false;
  ticketActions: any
  private destroy$ = new Subject<void>();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssignLocatorModalComponent>,
    private userService: UserService,
    private ticketActionService: TicketActionsService,
  ) {
    this.localUserList = data["dataKey"];

    const lsps = data["lsps"];
    console.log("lsps", lsps);

    if (lsps.utilityLSPs != null && lsps.utilityLSPs.length > 0) {
      console.log("utility ", lsps.utilityLSPs);
      this.utilityHasLSP = true;
      this.lspList = lsps.utilityLSPs;
    } else if (lsps.allLSPs != null && lsps.allLSPs.length > 0) {
      this.lspList = lsps.allLSPs;
      if (lsps.areaLSPs != null && lsps.areaLSPs.length > 0) {
        this.lspAreaList = lsps.areaLSPs;
      }
    }
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.ticketActionService.ticketActions$
    .pipe(takeUntil(this.destroy$))
    .subscribe((actions) => {
      this.ticketActions = actions  

    const clientHasTicketActionEnabled = this.ticketActions.find(
      (action) => action.id === TicketActionIDs.SEND_TO_LSP_ACTION_ID
    );

    if (this.userService.isSettingActive(SettingID.LSP_SETTING_ID) && clientHasTicketActionEnabled) {
      this.showLSPButton = true;
      if (this.utilityHasLSP) {
        this.lspControl.setValue(this.lspList[0]["LSPID"]);
        this.lspControl.disable();
      } else if (this.lspAreaList && this.lspAreaList.length > 0) {
        this.showAreaLSPs = true;
        this.lspAreaControl.setValue(this.lspAreaList[0]["LSPID"]);
        this.lspAreaControl.disable();
      }
    }
  });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.localUserList.filter(
      (user) => user["name"].toLowerCase().indexOf(filterValue) === 0
    );
  }

  onAssignConfirm() {
    const selectedUserID = this.getUserIdFromName(this.myControl.value);
    this.dialogRef.close({UserID: selectedUserID});
  }

  private getUserIdFromName(name) {
    try {
      for (let i = 0; i < this.localUserList.length; i++) {
        if (this.localUserList[i]["name"].indexOf(name) > -1) {
          return this.localUserList[i]["value"]; // UserID
        }
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  onLSPClick() {
    this.dialogRef.close({LSPID: this.lspControl.value});
  }

  onAreaLSPClick() {
    this.dialogRef.close({LSPID: this.lspAreaControl.value});
  }

  ngOnDestroy(): void {
    // Complete the destroy$ subject to signal component destruction
    this.destroy$.next();
    this.destroy$.complete();
  }
}
