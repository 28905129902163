import { inject, Injectable } from '@angular/core';
import { Observable, from, forkJoin, switchMap } from 'rxjs';
import { apiKeys, api } from 'src/app/ENDPOINTS';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { DefaultSubLayer, Group, LayerInfo } from 'src/app/modules/shared/esri-map/esri-map-set/EsriMapModels';
import { AdminLookupService } from '../../admin/admin-lookup.service';
import { ApiService, UtilocateApiRequest } from '../../api/baseapi.service';
import { LoggerService } from '../logger/logger.service';
import { EsriCredential } from 'src/app/modules/shared/esri-map/esri-map-set/EsriMapModels';
import { TicketService } from '../../../shared/ticket/ticket.service';
import { UserService } from '../user/user.service';
import { SettingID } from '../user/setting';
@Injectable({
  providedIn: 'root',
})
export class MapService {
  private ticketService = inject(TicketService);
  constructor(
    private logger$: LoggerService,
    private admin$: AdminLookupService,
    private utilocateApiService: ApiService,
    private userService: UserService,
  ) {}

  getEsriData(forTicket: boolean = false) {
    const obs = (utilities: Array<number>) =>
      new Observable((subscriber) => {
        forkJoin([
          this.getURLs(),
          this.getCredentials(),
          this.getLayers(),
          this.getSubLayers(),
          this.getLayerGroups(),
          this.getUtilityToEsriLayer(),
        ]).subscribe((results) => {

          let filterByUtilityGroup: Boolean = this.userService.isSettingActive(SettingID.UTILITIES_DETERMINE_ESRI_LAYERS);

          //If failed to get utilities treat as if setting is off
          if (utilities?.length == 0) {
            filterByUtilityGroup = false;
          }

          const groupIDs = results[5].reduce((acc, { UtilityID, EsriLayerGroupID }) => {
            if (utilities.includes(UtilityID) && !acc.includes(EsriLayerGroupID)) {
              acc.push(EsriLayerGroupID);
            }
            return acc;
          }, []);
          let credentials: EsriCredential[] = this.formatCredentials(results[0], results[1]);
          let groups: Group[] = this.formatGroups(results[4]);
          if (filterByUtilityGroup) {
            groups = groups.filter((group) => groupIDs.includes(group.groupID));
          }
          let layers: LayerInfo[] = this.formatLayers(results[2]).filter(({ groupID }) => {
            if (groupID) {
              if (filterByUtilityGroup) {
                return groupIDs.includes(groupID);
              } else {
                return true;
              }
            } else {
              return true;
            }
          });
          let defaultSubLayers: DefaultSubLayer[] = this.formatDefaultSubLayers(results[3]).filter(({ layerID }) => {
            if (layerID) {
              return layers.reduce((acc, { layerID: id }) => {
                if (id === layerID) {
                  acc = true;
                }
                return acc;
              }, false);
            } else {
              return true;
            }
          });

          let layerOptions = {
            layers: layers,
            defaultSubLayers: defaultSubLayers,
            credentials: credentials,
            groups: groups,
          };

          subscriber.next(layerOptions);
          subscriber.complete();
        });
      });
    if (forTicket) {
      return from(this.ticketService.getTicketUtilities()).pipe(switchMap((res) => obs(res)));
    } else {
      return obs([]);
    }
  }

  formatLayers(layerRows): LayerInfo[] {
    var layers: LayerInfo[] = [];
    if (layerRows != false) {
      layerRows.forEach((layerRow) => {
        var layer: LayerInfo = {
          layerID: layerRow.EsriLayerID,
          name: layerRow.LayerName,
          url: layerRow.LayerURL,
          layerTypeID: layerRow.LayerType,
          isVisible: layerRow.isDefaultOn,
        };
        if (layerRow.EsriLayerGroupID > 0) {
          layer.groupID = layerRow.EsriLayerGroupID;
        }
        layers.push(layer);
      });
    }
    return layers;
  }

  formatDefaultSubLayers(subLayerRows): DefaultSubLayer[] {
    var defaultSubLayers: DefaultSubLayer[] = [];
    if (subLayerRows != false) {
      subLayerRows.forEach((subLayerRow) => {
        var isVisible;
        if (window.innerWidth > 599 && subLayerRow.onForLargeScreen != null) {
          isVisible = subLayerRow.onForLargeScreen;
        } else {
          isVisible = subLayerRow.isDefaultOn;
        }

        defaultSubLayers.push({
          layerID: subLayerRow.EsriLayerID,
          subLayerID: subLayerRow.FeatureLayerID,
          isVisible: isVisible,
        });
      });
    }
    return defaultSubLayers;
  }

  formatGroups(groupRows): Group[] {
    var groups: Group[] = [];
    if (groupRows != false) {
      groupRows.forEach((groupRow) => {
        groups.push({
          groupID: groupRow.EsriLayerGroupID,
          name: groupRow.GroupName,
        });
      });
    }
    return groups;
  }

  formatCredentials(urlRows, credRows): EsriCredential[] {
    var credentials: EsriCredential[] = [];
    if (urlRows != false && credRows != false) {
      urlRows.forEach((urlRow) => {
        if (urlRow.EsriTokenGenerationURL != null && urlRow.EsriServicesURL != null) {
          credRows.forEach((credRow) => {
            if (credRow.EsriCredentialID == urlRow.EsriCredentialID) {
              credentials.push({
                username: credRow.Username,
                password: credRow.Password,
                serviceUrl: urlRow.EsriServicesURL,
                tokenGenerationUrl: urlRow.EsriTokenGenerationURL,
              });
            }
          });
        }
      });
    }
    return credentials;
  }

  // get all tables that will be used by service and component
  getRequiredTables() {
    return new Observable((subscriber) => {
      this.admin$
        .getLookupTables([
          'tbAdmin_EsriLayers',
          'tbAdmin_EsriCredentials',
          'tbAdmin_EsriService',
          'tbAdmin_EsriSubLayers',
          'tbAdmin_EsriLayerGroup',
        ])
        .then((result) => {
          subscriber.next(true);
          subscriber.complete();
        });
    });
  }

  getLayers() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriLayers'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getSubLayers() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriSubLayers'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getUtilityToEsriLayer(): Observable<Array<any>> {
    return new Observable((subscriber) => {
      try {
        
        if (this.userService.isSettingActive(SettingID.UTILITIES_DETERMINE_ESRI_LAYERS)) {
          this.admin$
            .getLookupTableRows(['tbAdmin_UtilityToEsriLayer'], {})
            .then((queryResult) => {
              subscriber.next(queryResult[0]['rows']);
              subscriber.complete();
            })
            .catch((error) => {
              subscriber.next([]);
              subscriber.complete();
            });            
        } else {
          subscriber.next([]);
          subscriber.complete();
        }
      } catch (error) {
        console.error(error);
        subscriber.next([]);
        subscriber.complete();
      }
    });
  }

  getURLs() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriService'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getCredentials() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriCredentials'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  // gatherTicketLatLng() {
  // 	return new Observable((subscriber) => {
  // 		try {
  // 			const url = apiKeys.u2.gatherTicketLatLng;
  // 			const type = api[url].type;
  // 			const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);

  // 			if (aid) {

  // 				let utilocateApiRequest: UtilocateApiRequest = {
  // 					API_KEY: apiKeys.u2.gatherTicketLatLng,
  // 					API_TYPE: type,
  // 					API_BODY: { "AssignmentID": aid }
  // 				}
  // 				from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
  // 					if (response.ok) {
  // 						subscriber.next(response.body);
  // 					} else {
  // 						subscriber.next(false);
  // 					}
  // 					subscriber.complete();
  // 				});
  // 			} else {
  // 				throw new Error("Failed to GET AssignmentID from storage");
  // 			}
  // 		} catch (error) {
  // 			this.logger$.warn("esrpMap$: applyDigsite: function failure: ", error);
  // 			subscriber.next(false);
  // 			subscriber.complete();
  // 		}
  // 	});
  // }

  getLayerGroups() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriLayerGroup'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  gatherDigsites() {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.gatherDigsites;
        const type = api[url].type;
        let aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);
        if(!aid){
          aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.AssignmentID);
        }

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.gatherDigsites,
            API_TYPE: type,
            API_BODY: { AssignmentIDs: [aid] },
          };
          from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error('Failed to GET AssignmentID from storage');
        }
      } catch (error) {
        this.logger$.warn('esrpMap$: gatherDigsites: function failure: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  saveDigsite(digsiteDetails) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.addDigsiteToTicket;
        const type = api[url].type;
        const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.addDigsiteToTicket,
            API_TYPE: type,
            API_BODY: { DigsiteDetails: digsiteDetails, AssignmentID: aid },
          };
          from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error('Failed to GET AssignmentID from storage');
        }
      } catch (error) {
        this.logger$.warn('esrpMap$: saveDigsite: function failure: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }
}
