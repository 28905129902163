import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-scheduler-cancel-ticket-dialog",
  templateUrl: "./scheduler-cancel-ticket-dialog.component.html",
  styleUrls: ["./scheduler-cancel-ticket-dialog.component.css"],
})
export class SchedulerCancelTicketDialogComponent {
  message: string;

  cancel = "cancel";
  noCancel = "noCancel";
  explanation: string = "";
  explanationError = false;

  constructor(
    public dialogRef: MatDialogRef<SchedulerCancelTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
  }

  clickCancel() {
    if (this.explanation.length > 0) {
      this.dialogRef.close(this.explanation);
    } else {
      this.explanationError = true;
    }
  }
}
