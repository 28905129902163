<mat-toolbar class="bg-white px-1 pl-[10px]">
  <div *ngIf="doesHaveReturnUrl; else doesNotHaveReturnUrl">
    <button mat-icon-button [routerLink]="returnUrl">
      <mat-icon class="text-black">arrow_back</mat-icon>
    </button>
  </div>
  <ng-template #doesNotHaveReturnUrl>
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon class="text-black">menu</mat-icon>
    </button>
  </ng-template>
  <span
    class="font-rajdhani uppercase font-semibold text-[20px] tracking-[0.0075em] text-black"
  >
    {{ appToolbarTitle }}</span
  >
  <span class="toolbar-spacer"></span>
  <div>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngIf="allowCreateAudit" (click)="createTicket()" class="" mat-menu-item>
        <span class="mat-subtitle-1">Create Audit</span>
      </button>
      <button (click)="openSelfAssign()" mat-menu-item >
        <span class="mat-subtitle-1">Self Assign</span>
      </button>
    </mat-menu>
  </div>
  <button *ngIf="disableSync" mat-icon-button class="px-0" (click)="onSync()">
    <mat-icon
      *ngIf="showSyncBadge; else defaultSyncIcon"
      matBadge="!"
      matBadgeSize="small"
      matBadgeColor="warn"
      >sync</mat-icon
    >
    <ng-template #defaultSyncIcon>
      <mat-icon>sync</mat-icon>
    </ng-template>
  </button>
  <div class="flex flex-row items-center justify-end" id="user-section-container">
    <button class="user-section flex items-center justify-center pt-8 pb-6" [matMenuTriggerFor]="userMenu">
        <div class="bg-primary rounded-full w-11 h-11 flex items-center justify-center">
          <span class="text-white text-xl uppercase font-rajdhani text-headline-5 font-semibold mt-[1px] select-none">{{ initials$ | async }}</span>
        </div>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="before">
      <div class="user-menu flex flex-col justify-start items-center">
        <div class="flex items-center justify-center pt-8 pb-6">
          <div class="bg-primary rounded-full w-20 h-20 flex items-center justify-center">
            <span class="text-white text-xl uppercase font-rajdhani text-headline-3 font-semibold mt-1 select-none">{{ initials$ | async }}</span>
          </div>
        </div>
        <span class="font-rajdhani uppercase font-semibold text-headline-6 select-none">{{ name$ | async }}</span>
        <span class="font-montserrat text-sm py-2 select-none">{{ userCategory$ | async }}</span>
        <span class="font-montserrat text-sm pt-2 pb-4 select-none">Version {{ appVersion }}</span>


        <div class="logout-menu flex flex-row justify-center items-center">
          <button class="btn-delete" (click)="logout()">Logout</button>
        </div>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
