<!-- <app-digsite [viewOnly]="true" (swiperight)="onSwipe($event)" (swipeleft)="onSwipe($event)"></app-digsite> -->
<div
  style="height: 100%; width: 100%"
  fxLayout="column"
  (swiperight)="onSwipe($event)"
  (swipeleft)="onSwipe($event)"
>
  <app-esri-map-simple-drawing
    #esriDrawing
    fxFlex="100"
    [ngClass]="{ 'hidden-map ': !isEsriVisibile }"
    style="max-height: 100%; width: 100%;"
    [graphics]="graphics"
    [mapOptions]="mapOptions"
    [layerOptions]="layerOptions"
    [markers]="markers"
    [disableMapControls]="true"
  >
  </app-esri-map-simple-drawing>

  <app-google-maps-drawing
    #cmpGoogleMapsDrawing
    fxFlex
    [ngClass]="{ 'hidden-map ': isEsriVisibile }"
    [googleMapsOptions]="options"
    [graphics]="graphics"
    [props]="googleMapsProps"
    [viewOnly]="true"
    [markers]="markers"
  >
  </app-google-maps-drawing>

  <div
    fxHide
    [fxShow.lt-md]="!esriDrawing.layersOpen && !esriDrawing.legendOpen"
    fxFlex.lt-md="56px"
    class="bottom-bar"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    (click)="toggleBottomMenu()"
  >
    <mat-icon>expand_less</mat-icon>
    <span class="mat-subheading-s">Map Options</span>
  </div>
</div>

<!-- Bottom Sheet -->
<div
  class="bottom-sheet"
  *ngIf="bottomMenuOpen && !esriDrawing.layersOpen && !esriDrawing.legendOpen"
>
  <div
    class="bottom-bar"
    style="height: 56px"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    (click)="toggleBottomMenu()"
  >
    <mat-icon class="arrow-icon">expand_more</mat-icon>
    <span class="mat-subheading-s">Map Options</span>
  </div>

  <div fxLayout="column" class="bottom-sheet-menu">
    <div
      *ngIf="isEsriVisibile"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleLayers()"
    >
      <mat-icon>layers</mat-icon>
      <span class="mat-subheading-xs blue">Layers</span>
    </div>

    <div
      *ngIf="isEsriVisibile"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleLegend()"
    >
      <mat-icon>list</mat-icon>
      <span class="mat-subheading-xs blue">Legend</span>
    </div>

    <div
      *ngIf="isEsriVisibile"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      [ngClass]="{'bottom-sheet-row-toggled' : identifyClicked}"
      (click)="toggleIdentify()"
    >
      <mat-icon><img [ngClass]="{'bottom-sheet-toggle' : identifyClicked}" class="identifyIcon" id="identifyLayerBlue" /></mat-icon>
      <span [ngClass]="{'text-white' : identifyClicked}" class="mat-subheading-xs blue">Identify</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleCoordinates()"
    >
      <mat-icon>explore</mat-icon>
      <span class="mat-subheading-xs blue">Coordinates</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleMeasurement()"
    >
      <mat-icon>architecture</mat-icon>
      <span class="mat-subheading-xs blue">Measurement</span>
    </div>

    <div
      *ngIf="esriAvailable"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="bottom-sheet-row"
      (click)="toggleMap()"
    >
      <mat-icon>map</mat-icon>
      <span class="mat-subheading-xs blue">{{
        isEsriVisibile ? "Google Maps" : "Esri Maps"
      }}</span>
    </div>
  </div>
</div>

<!-- FAB -->
<floating-action-menu
  [hidden]="bottomMenuOpen"
  [menuItems]="isEsriVisibile ? esriMapFab : googleMapsFab"
  type="fab"
>
</floating-action-menu>

<!-- Toolbar -->
<div class="mapToolbar" fxHide.lt-md="true">
  <mat-button-toggle-group
    multiple
    aria-label="Google Map Drawing Tools"
    variant="contained"
  >
    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Layers"
      aria-label="Open Layers"
      (click)="toggleLayers()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>layers</mat-icon>
        <span class="label">Layers</span>
      </div>
    </button>

    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Legend"
      aria-label="Open Legend"
      (click)="toggleLegend()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>list</mat-icon>
        <span class="label">Legend</span>
      </div>
    </button>

    <mat-button-toggle
      *ngIf="isEsriVisibile"
      class="tool-toggle"
      [ngClass]="{'identify-toggled' : identifyClicked}"
      (click)="toggleIdentify()"
      matTooltip="Identify"
      aria-label="Identify"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon color="white" svgIcon="ESRI_IDENTIFY"/>
        <span class="label">Identify</span>
      </div>
    </mat-button-toggle>

    <button
      *ngIf="isEsriVisibile"
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Measurement"
      aria-label="Measurement"
      (click)="toggleMeasurement()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>architecture</mat-icon>
        <span class="label">Measurement</span>
      </div>
    </button>

    <button
      mat-flat-button
      color="primary"
      class="tool-toggle btn"
      matTooltip="Toggle Coordinates"
      aria-label="Toggle Coordinates"
      (click)="toggleCoordinates()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>explore</mat-icon>
        <span class="label">Coordinates</span>
      </div>
    </button>

    <button
      mat-flat-button
      *ngIf="esriAvailable"
      color="primary"
      class="tool-toggle btn"
      [matTooltip]="isEsriVisibile ? 'Google Maps' : 'Esri Maps'"
      aria-label="Switch to Google Maps"
      (click)="toggleMap()"
    >
      <div class="btn-contents" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>map</mat-icon>
        <span class="label">{{
          isEsriVisibile ? "Google Maps" : "Esri Maps"
        }}</span>
      </div>
    </button>
  </mat-button-toggle-group>
</div>
