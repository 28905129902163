import { Injectable } from "@angular/core";
import { ADMIN_TABLE_NAMES } from "../admin/tables";
import { BaseAdminCacheService, CacheWhereClauseType } from "./cache.interface";

export interface Setting {
  SettingID: string | number;
  Active: boolean;
  Value: string;
}

export class EnabledSetting implements Setting {
  SettingID: string | number;
  Active = true;
  Value: string;

  constructor(settingID: string | number, value: string) {
    this.SettingID = settingID;
    this.Value = value;
  }
}

export class DisabledSetting implements Setting {
  SettingID: string | number;
  Active = false;
  Value: string;

  constructor(settingID: string | number, value: string) {
    this.SettingID = settingID;
    this.Value = value;
  }
}

@Injectable({
  providedIn: "root",
})
export class UtilocateSettingService extends BaseAdminCacheService {
  async getSettings(settingIDs: number[], userID?: string): Promise<Object> {
    let userSettings = null;
    let settings = await this.getGlobalSettings(settingIDs);

    if (userID) {
      userSettings = await this.getUserSettings(settingIDs, userID);
    }

    if (userSettings != null) {
      for (const settingID in settings) {
        if (userSettings[settingID]) {
          if (userSettings[settingID].Value == null) {
            userSettings[settingID].Value = settings[settingID].Value;
          }
          settings[settingID] = userSettings[settingID];
        }
      }
    }

    return settings;
  }

  async getSetting(
    settingID: string,
    userID?: string
  ): Promise<Setting | Error> {
    try {
      let isSettingOverrideActive = null;
      let setting: Setting | Error = await this.isGlobalSettingActive(
        settingID
      );

      // if the userID was provided then check the user override
      if (userID) {
        isSettingOverrideActive = await this.isSettingOverrideActive(
          settingID,
          userID
        );
      }

      if (
        isSettingOverrideActive != null &&
        !(isSettingOverrideActive instanceof Error)
      ) {
        if (
          isSettingOverrideActive instanceof EnabledSetting &&
          isSettingOverrideActive.Value == null &&
          !(setting instanceof Error)
        ) {
          isSettingOverrideActive.Value = setting.Value;
        }

        setting = isSettingOverrideActive;
      }

      return setting;
    } catch (error) {
      return error;
    }
  }

  async isGlobalSettingActive(settingID): Promise<Setting | Error> {
    try {
      let tableData: object[] | Error = await this.queryTableData(
        ADMIN_TABLE_NAMES.tbAdmin_Settings,
        [
          {
            Column: "SettingID",
            Value: settingID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ]
      );

      if (!(tableData instanceof Error)) {
        let setting: DisabledSetting | EnabledSetting = new DisabledSetting(
          settingID,
          null
        );

        if (tableData.length == 1) {
          let tableRow = tableData[0];

          if (tableRow["Active"] == "1") {
            setting = new EnabledSetting(settingID, tableRow["Value"]);
          } else {
            setting = new DisabledSetting(settingID, tableRow["Value"]);
          }
        }

        return setting;
      } else {
        throw tableData;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async isSettingOverrideActive(
    settingID: string,
    userID: string
  ): Promise<Setting | Error> {
    try {
      let tableData: object[] | Error = await this.queryTableData(
        ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
        [
          {
            Column: "SettingID",
            Value: settingID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
          {
            Column: "UserID",
            Value: userID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ]
      );

      if (!(tableData instanceof Error)) {
        // return null if SettingID could not be found in tbUser_SettingOverrides
        let setting: DisabledSetting | EnabledSetting = null;

        if (tableData.length == 1) {
          let tableRow = tableData[0];

          if (tableRow["ActiveOverride"] == "1") {
            setting = new EnabledSetting(settingID, tableRow["ValueOverride"]);
          } else {
            setting = new DisabledSetting(settingID, tableRow["ValueOverride"]);
          }
        }

        return setting;
      } else {
        throw tableData;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getGlobalSettings(settingIDs: number[]) {
    let settings = {};
    let tableData: object[] | Error = await this.queryTableData(
      ADMIN_TABLE_NAMES.tbAdmin_Settings,
      [
        {
          Column: "SettingID",
          Value: settingIDs,
          ValueType: CacheWhereClauseType.ARRAY,
        },
      ]
    );

    if (!(tableData instanceof Error)) {
      for (const row of tableData) {
        let curSetting: Setting;
        if (row["Active"] == 1) {
          curSetting = new EnabledSetting(row["SettingID"], row["Value"]);
        } else {
          curSetting = new DisabledSetting(row["SettingID"], row["Value"]);
        }
        settings[row["SettingID"]] = curSetting;
      }

      for (const settingID of settingIDs) {
        if (!settings[settingID]) {
          settings[settingID] = new DisabledSetting(settingID, null);
        }
      }
    }
    return settings;
  }

  async getUserSettings(settingIDs: number[], userID: string) {
    let settings = {};
    let tableData: object[] | Error = await this.queryTableData(
      ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
      [
        {
          Column: "SettingID",
          Value: settingIDs,
          ValueType: CacheWhereClauseType.ARRAY,
        },
        {
          Column: "UserID",
          Value: userID,
          ValueType: CacheWhereClauseType.NUMBER,
        },
      ]
    );

    if (!(tableData instanceof Error)) {
      for (const row of tableData) {
        let curSetting: Setting;
        if (row["ActiveOverride"] == 1) {
          curSetting = new EnabledSetting(
            row["SettingID"],
            row["ValueOverride"]
          );
        } else {
          curSetting = new DisabledSetting(
            row["SettingID"],
            row["ValueOverride"]
          );
        }
        settings[row["SettingID"]] = curSetting;
      }
    }
    return settings;
  }
}
