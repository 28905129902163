import { EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BaseComponent } from "src/app/modules/core/base-set/base-set.component";
import { LoggerService } from "src/app/modules/core/services/logger/logger.service";
import { TicketDetailsService } from "../../../ticket-details/ticket-details.service";
import { RecursiveFormDialogComponent } from "../recursive-form-dialog/recursive-form-dialog.component";

@Component({
  selector: "app-recursive-form-group-template",
  templateUrl: "./recursive-form-group-template.component.html",
  styleUrls: ["../recursive-form-template.scss"],
})
export class RecursiveFormGroupTemplateComponent extends BaseComponent {
  @Input() readOnly: boolean = true;
  @Output() isHideShowToggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() addCategories: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCategory: EventEmitter<any> = new EventEmitter<any>();

  hasFields: boolean = false;
  canRemove: boolean = false;
  isVisible: boolean = false;

  subGroups: any;
  fields: any;
  formGroup: FormGroup;

  fieldProps: any;

  constructor(
    logger$: LoggerService,
    public dialog: MatDialog,
    private ticketDetails$: TicketDetailsService
  ) {
    super(logger$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["props"] && changes["props"]["currentValue"]) {
      this.isVisible = changes["props"]["currentValue"]["hasBeenRemoved"] != 1;

      let min = changes["props"]["currentValue"]["minInstances"];
      let num = changes["props"]["currentValue"]["numInstances"];
      this.canRemove = min <= num - 1;

      this.fields = changes["props"]["currentValue"]["fields"];
      this.formGroup = changes["props"]["currentValue"]["formGroup"];
      this.subGroups = changes["props"]["currentValue"]["groups"];

      if (this.fields) {
        this.hasFields = true;
        this.fieldProps = {
          fields: this.fields,
          formGroup: this.formGroup,
        };
      }
    }
  }

  onRemove() {
    this.removeCategory.emit(this.props.key);
  }

  async onEdit(group) {
    try {
      this.openDialog(group);
    } catch (error) {
      this.logger$.error("onedit: " + error.message);
    }
  }

  async openDialog(group) {
    try {
      let hideShowFields = {};
      let addRemoveCategories = await this.ticketDetails$.getAddableCategories(
        group
      );

      if (group && group["fields"] && Object.keys(group["fields"]).length > 0) {
        hideShowFields = Object.values(group["fields"]).filter((field) => {
          return field ? field["isHideShow"] : false;
        });
      }

      const dialogRef = this.dialog.open(RecursiveFormDialogComponent, {
        data: {
          fields: hideShowFields,
          title: group.header,
          categories: addRemoveCategories,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result["fields"] && Object.keys(result["fields"]).length > 0) {
            let newStateOfGroup = result["fields"].reduce(
              (total, current) => {
                if (current["isVisible"]) {
                  total[0].push(Number.parseInt(current["key"]));
                } else {
                  total[1].push(Number.parseInt(current["key"]));
                }

                return total;
              },
              [[], []]
            );

            this.isHideShowToggle.emit([group["key"], newStateOfGroup]);
          }

          if (
            result["categoriesToAdd"] &&
            Object.keys(result["categoriesToAdd"]).length > 0
          ) {
            this.addCategories.emit([
              Number.parseInt(group["key"]),
              result["categoriesToAdd"],
            ]);
          }
        }

        this.logger$.log("The dialog was closed");
      });
    } catch (error) {
      this.logger$.error("openDialog: hiddenValues: ", error);
    }
  }
}
