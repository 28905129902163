<div mat-dialog-title>
  <h1 class="mat-headline-1">Assignment Selection</h1>
  <span class="mat-body-1">Please select someone to assign the ticket to</span>
</div>

<div mat-dialog-content>
  <form style="min-width: 150px; max-width: 500px; width: 100%">
    <div *ngIf="showLSPButton" fxLayout="row" fxLayoutGap="8px">
      <div fxLayoutAlign="start center" fxLayoutGap="8px">
        <button mat-stroked-button class="icon-btn" (click)="onLSPClick()">
          <mat-icon svgIcon="sendToLSP"></mat-icon>
        </button>
        <mat-form-field>
          <mat-label *ngIf="utilityHasLSP">To LSP By Station Code</mat-label>
          <mat-label *ngIf="!utilityHasLSP">Select LSP</mat-label>
          <mat-select [formControl]="lspControl">
            <mat-option *ngFor="let option of lspList" [value]="option.LSPID">
              {{ option.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="showAreaLSPs" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button mat-stroked-button class="icon-btn" (click)="onAreaLSPClick()">
          <mat-icon svgIcon="sendToLSP"></mat-icon>
        </button>
        <mat-form-field>
          <mat-label>To LSP By Area</mat-label>
          <mat-select [formControl]="lspAreaControl">
            <mat-option
              *ngFor="let option of lspAreaList"
              [value]="option.LSPID"
            >
              {{ option.Name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div fxLayoutAlign="start center" fxLayoutGap="8px" style="width: 50%;">
        <button class="mat-stroked-button">Auto</button>
        <span class="mat-subheading-1">Auto Dispatch</span>
      </div> -->
    </div>
    <span class="mat-subtitle-1">Assign To User</span>
    <mat-form-field style="width: 100%">
      <input
        type="text"
        placeholder="Search by first name or select from list"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" isOpen="false">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option.name"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="this.myControl.value == null"
    (click)="onAssignConfirm()"
  >
    Confirm
  </button>
</div>
