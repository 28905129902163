<div fxLayout="column" fxLayoutGap="24px" class="table-container bg-white">
  <!-- Header -->
  <div class="mat-headline-2">Edit Tables / {{ visibleName }}</div>
  <div>{{ tableDescription }}</div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="padding-right: 16px"
  >
    <button
      [style.visibility]="accessLevel.allowDeleting ? 'visible' : 'hidden'"
      mat-stroked-button
      color="primary"
      class="mat-subheading-xs"
      (click)="onDeleteRow()"
    >
      Delete Row(s)
    </button>
    <button
      [style.visibility]="accessLevel.allowAdding ? 'visible' : 'hidden'"
      mat-flat-button
      color="primary"
      class="mat-subheading-xs"
      (click)="onAddRow()"
    >
      Add Row
    </button>
  </div>
  <mat-divider></mat-divider>

  <!-- Table -->
  <app-editable-table
    #table
    style="height: 100%"
    [data]="formattedData"
    [columns]="tableDisplayColumns"
    [enableSelect]="accessLevel.allowDeleting"
    (rowClicked)="accessLevel.allowEditing ? onEditRow($event) : ''"
  >
  </app-editable-table>
</div>

<!-- Row Modal -->
<app-base-modal #userDialog [id]="ROW_MODAL_ID" maxwidth="50%">
  <ng-container class="competers-modal-content">
    <app-row-editor
      [columns]="tableColumns"
      [dataRow]="selectedRow"
      (cancel)="onCloseRowModal()"
      (save)="onSaveRow($event)"
    >
    </app-row-editor>
  </ng-container>
</app-base-modal>
